import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { DashboardShift, SagaAction, SagaActionFromCreator } from 'type';
import Api, {
  EmployeeDashboardCreateShiftTradeRequest,
  EmployeeDashboardGetMyRostersListResponse,
  EmployeeDashboardGetMyRostersRequest,
  OVERLAP_ERROR_STATUS,
  PunchClockClockOffRequest,
  PunchClockClockOnResponse,
  PunchClockGetActiveTimesheetResponse,
  PunchClockStartBreakRequest,
  WhoElseWorkingRequest,
} from 'lib/Api';
import { formatError, getErrorStatus } from 'state/helpers';
import { processApiRequest } from 'state/ProcessApiRequest';
import * as fetchPageData from 'state/FetchPageData';
import * as employeeDashboard from '../../EmployeeDashboard';
import { ClockOffPayload, ClockOnRequest, CreateTradePayload } from '../types';
import { isTimesheetInProgress } from '../helpers';
import {
  foreceCreatePayloadSelector,
  getMyRostersSelector,
  payloadSelector,
} from '../selectors';
import * as actions from '../actions';
import handleShiftChange from './handleShiftChange';
import handleShiftDeletion from './handleShiftDeletion';
import { hideOutdatedNotification } from './outdated-notification-handlers';
import moment from 'moment';
import { BOX_MY_ROSTERS_WHO_ELSE_WORKING_REQUEST } from '../actions';

const fetchActiveTimesheetRequest = function* () {
  const timesheetInProgress: PunchClockGetActiveTimesheetResponse = yield call(
    processApiRequest,
    Api.PunchClock.getActiveTimesheet
  );

  yield put(
    actions.BOX_MY_ROSTERS_ACTIVE_TIMESHEET_FETCHED(timesheetInProgress)
  );
};

export const fetchMyRostersList = function* (
  payload: EmployeeDashboardGetMyRostersRequest
) {
  const [shiftsResponse]: [EmployeeDashboardGetMyRostersListResponse] =
    yield all([
      call(processApiRequest, Api.EmployeeDashboard.getMyRosters, payload),
      call(fetchActiveTimesheetRequest),
    ]);

  return shiftsResponse;
};

export const getMyRostersListRequest = function* () {
  const payload: ReturnType<typeof payloadSelector> = yield select(
    payloadSelector
  );

  const results: EmployeeDashboardGetMyRostersListResponse = yield call(
    fetchMyRostersList,
    payload
  );

  yield put(actions.BOX_MY_ROSTERS_SHIFTS_FETCHED(results));
};

export const getData = function* (): SagaIterator {
  try {
    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_REQUEST(
        fetchPageData.EMPLOYEE_DASHBOARD_MY_ROSTERS
      )
    );
    yield call(getMyRostersListRequest);

    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_SUCCESS(
        fetchPageData.EMPLOYEE_DASHBOARD_MY_ROSTERS
      )
    );
  } catch (error) {
    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_FAILURE(
        fetchPageData.EMPLOYEE_DASHBOARD_MY_ROSTERS
      )
    );
  }
};

const reFetchData = function* (): SagaIterator {
  try {
    yield call(getMyRostersListRequest);

    yield put(actions.BOX_MY_ROSTERS_RE_FETCH_DATA_SUCCESS());
  } catch (error) {
    yield put(actions.BOX_MY_ROSTERS_RE_FETCH_DATA_FAILURE(formatError(error)));
  }
};

export const togglePeriod = function* ({
  payload: period = 'today',
}: SagaActionFromCreator<
  typeof actions.BOX_MY_ROSTERS_TOGGLE_PERIOD_TYPE_REQUEST
>): SagaIterator {
  try {
    const payload: ReturnType<typeof payloadSelector> = yield select(
      payloadSelector
    );

    const workingShifts: EmployeeDashboardGetMyRostersListResponse = yield call(
      fetchMyRostersList,
      {
        ...payload,
        period: {
          ...payload.period,
          period,
        },
        page: 1,
      }
    );
    yield put(
      actions.BOX_MY_ROSTERS_TOGGLE_PERIOD_TYPE_SUCCESS({
        period,
        workingShifts,
      })
    );
  } catch (error) {
    yield put(
      actions.BOX_MY_ROSTERS_TOGGLE_PERIOD_TYPE_FAILURE(formatError(error))
    );
  }
};

export const clockOn = function* ({
  payload,
}: SagaAction<ClockOnRequest>): SagaIterator {
  try {
    const activeTimesheet: PunchClockClockOnResponse = yield call(
      processApiRequest,
      Api.PunchClock.clockOn,
      payload
    );
    yield put(actions.BOX_MY_ROSTERS_CLOCK_ON_SUCCESS(activeTimesheet));
  } catch (error) {
    yield put(actions.BOX_MY_ROSTERS_CLOCK_ON_FAILURE(formatError(error)));
  }
};

export const clockOff = function* (
  action: SagaAction<ClockOffPayload>
): SagaIterator {
  try {
    const payload: PunchClockClockOffRequest = action.payload;
    yield call(processApiRequest, Api.PunchClock.clockOff, payload);
    yield put(actions.BOX_MY_ROSTERS_CLOCK_OFF_SUCCESS(payload.timesheet_id));

    // yield put(actions.BOX_MY_ROSTERS_TOGGLE_PERIOD_TYPE_REQUEST('next_7_days'));
  } catch (error) {
    yield put(actions.BOX_MY_ROSTERS_CLOCK_OFF_FAILURE(formatError(error)));
  }
};

export const startBreak = function* ({
  payload,
}: SagaAction<PunchClockStartBreakRequest>): SagaIterator {
  try {
    yield call(processApiRequest, Api.PunchClock.startBreak, payload);
    yield put(actions.BOX_MY_ROSTERS_START_BREAK_SUCCESS(payload.timesheet_id));
  } catch (error) {
    yield put(actions.BOX_MY_ROSTERS_START_BREAK_FAILURE(formatError(error)));
  }
};

export const stopBreak = function* ({
  payload: id,
}: SagaAction<string>): SagaIterator {
  try {
    yield call(processApiRequest, Api.PunchClock.stopBreak, {
      timesheet_id: id,
    });
    yield put(actions.BOX_MY_ROSTERS_STOP_BREAK_SUCCESS(id));
  } catch (error) {
    yield put(actions.BOX_MY_ROSTERS_STOP_BREAK_FAILURE(formatError(error)));
  }
};

export const applyFilters = function* (): SagaIterator {
  try {
    yield call(getMyRostersListRequest);

    yield put(actions.BOX_MY_ROSTERS_APPLY_FORM_FILTERS_SUCCESS());
  } catch (error) {
    yield put(
      actions.BOX_MY_ROSTERS_APPLY_FORM_FILTERS_FAILURE(formatError(error))
    );
  }
};

export const changePage = function* ({
  payload: page = 0,
}: SagaAction<number>): SagaIterator {
  try {
    const defaultPayload: ReturnType<typeof payloadSelector> = yield select(
      payloadSelector
    );

    const response: EmployeeDashboardGetMyRostersListResponse = yield call(
      fetchMyRostersList,
      {
        ...defaultPayload,
        page,
      }
    );
    yield put(actions.BOX_MY_ROSTERS_CHANGE_PAGE_SUCCESS(response));
  } catch (error) {
    yield put(actions.BOX_MY_ROSTERS_CHANGE_PAGE_FAILURE(formatError(error)));
  }
};

export const changePageSize = function* ({
  payload: pageSize,
}: SagaAction<number>): SagaIterator {
  try {
    const defaultPayload: ReturnType<typeof payloadSelector> = yield select(
      payloadSelector
    );
    const response: EmployeeDashboardGetMyRostersListResponse = yield call(
      fetchMyRostersList,
      {
        ...defaultPayload,
        page: 1,
        per_page: pageSize,
      }
    );
    yield put(actions.BOX_MY_ROSTERS_CHANGE_PAGE_SIZE_SUCCESS(response));
  } catch (error) {
    yield put(
      actions.BOX_MY_ROSTERS_CHANGE_PAGE_SIZE_FAILURE(formatError(error))
    );
  }
};

export const createTrade = function* ({
  payload,
}: SagaAction<CreateTradePayload>): SagaIterator {
  try {
    const apiPayload: EmployeeDashboardCreateShiftTradeRequest = payload;

    yield call(
      processApiRequest,
      Api.EmployeeDashboard.createShiftTrade,
      apiPayload
    );
    yield put(actions.BOX_MY_ROSTERS_TRADE_SUCCESS(payload.type));
    const shifts = yield select(getMyRostersSelector);
    const shift = shifts.filter(
      (s: DashboardShift) => s.id === apiPayload.shift_id
    );
  } catch (error) {
    if (getErrorStatus(error) === OVERLAP_ERROR_STATUS) {
      yield put(
        actions.BOX_MY_ROSTERS_CONFIRMATION_MODAL_OPENED({
          message: formatError(error)[0].split('\n')[0], // TODO: update getting confirmation error
        })
      );
    } else {
      yield put(actions.BOX_MY_ROSTERS_TRADE_FAILURE(formatError(error)));
    }
  }
};

const createTradeForce = function* (): SagaIterator {
  try {
    const createTradePayload: ReturnType<typeof foreceCreatePayloadSelector> =
      yield select(foreceCreatePayloadSelector);

    const apiRequest: EmployeeDashboardCreateShiftTradeRequest = {
      ...createTradePayload,
      ignore_errors: true,
    };

    yield call(
      processApiRequest,
      Api.EmployeeDashboard.createShiftTrade,
      apiRequest
    );

    yield put(actions.BOX_MY_ROSTERS_TRADE_FORCE_SUCCESS(createTradePayload));
  } catch (error) {
    yield put(actions.BOX_MY_ROSTERS_TRADE_FORCE_FAILURE(formatError(error)));
  }
};

export const acceptShift = function* ({ payload: id }: SagaAction<string>) {
  try {
    const shift = yield call(
      processApiRequest,
      Api.EmployeeDashboard.acceptShift,
      id
    );
    yield put(actions.BOX_MY_ROSTERS_ACCEPT_SHIFT_SUCCESS(shift));
  } catch (error) {
    yield put(actions.BOX_MY_ROSTERS_ACCEPT_SHIFT_FAILURE(formatError(error)));
  }
};

export const declineShift = function* ({ payload: id }: SagaAction<string>) {
  try {
    yield call(processApiRequest, Api.EmployeeDashboard.declineShift, id);
    yield put(actions.BOX_MY_ROSTERS_DECLINE_SHIFT_SUCCESS(id));
  } catch (error) {
    yield put(actions.BOX_MY_ROSTERS_DECLINE_SHIFT_FAILURE(formatError(error)));
  }
};

const handleTimesheetChange = function* ({
  payload,
}: SagaActionFromCreator<
  typeof employeeDashboard.BOX_EMPLOYEE_DASHBOARD_TIMESHEET_CHANGED
>) {
  if (isTimesheetInProgress(payload.timesheet)) {
    yield call(fetchActiveTimesheetRequest);
  }
};

export const whoElseWorking = function* ({
  payload,
}: SagaAction<WhoElseWorkingRequest>): SagaIterator {
  try {
    const response = yield call(
      processApiRequest,
      Api.RosteredShift.whoElseWorkingWithMe,
      payload
    );
    yield put(actions.BOX_MY_ROSTERS_WHO_ELSE_WORKING_SUCCESS(response));
  } catch (e) {
    yield put(actions.BOX_MY_ROSTERS_WHO_ELSE_WORKING_FAILURE(formatError(e)));
  }
};

export const watchEmployeeMyRosters = function* (): SagaIterator {
  yield takeLatest(actions.BOX_MY_ROSTERS_DATA_REQUEST, getData);
  yield takeLatest(actions.BOX_MY_ROSTERS_RE_FETCH_DATA_REQUEST, reFetchData);
  yield takeLatest(actions.BOX_MY_ROSTERS_CHANGE_PAGE_REQUEST, changePage);
  yield takeLatest(
    actions.BOX_MY_ROSTERS_CHANGE_PAGE_SIZE_REQUEST,
    changePageSize
  );
  yield takeLatest(
    actions.BOX_MY_ROSTERS_TOGGLE_PERIOD_TYPE_REQUEST,
    togglePeriod
  );
  yield takeLatest(
    actions.BOX_MY_ROSTERS_APPLY_FORM_FILTERS_REQUEST,
    applyFilters
  );
  yield takeLatest(actions.BOX_MY_ROSTERS_CLOCK_ON_REQUEST, clockOn);
  yield takeLatest(actions.BOX_MY_ROSTERS_CLOCK_OFF_REQUEST, clockOff);
  yield takeLatest(actions.BOX_MY_ROSTERS_START_BREAK_REQUEST, startBreak);
  yield takeLatest(actions.BOX_MY_ROSTERS_STOP_BREAK_REQUEST, stopBreak);
  yield takeLatest(actions.BOX_MY_ROSTERS_TRADE_REQUEST, createTrade);
  yield takeLatest(
    actions.BOX_MY_ROSTERS_TRADE_FORCE_REQUEST,
    createTradeForce
  );
  yield takeLatest(actions.BOX_MY_ROSTERS_ACCEPT_SHIFT_REQUEST, acceptShift);
  yield takeLatest(actions.BOX_MY_ROSTERS_DECLINE_SHIFT_REQUEST, declineShift);
  yield takeLatest(actions.BOX_MY_ROSTERS_PAGE_LEFT, hideOutdatedNotification);
  yield takeLatest(
    employeeDashboard.BOX_EMPLOYEE_DASHBOARD_SHIFT_CHANGED,
    handleShiftChange
  );
  yield takeLatest(
    employeeDashboard.BOX_EMPLOYEE_DASHBOARD_SHIFT_DELETED,
    handleShiftDeletion
  );
  yield takeLatest(
    employeeDashboard.BOX_EMPLOYEE_DASHBOARD_TIMESHEET_CHANGED,
    handleTimesheetChange
  );
  yield takeLatest(
    actions.BOX_MY_ROSTERS_WHO_ELSE_WORKING_REQUEST,
    whoElseWorking
  );
};
