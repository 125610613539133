import React from 'react';
import { NativeTableElement } from '../type';
import { getClass } from '../../_lib/helper';
import './NativeTableTh.scss';

type NativeTableThProps = NativeTableElement & {};

function NativeTableTh({
  children,
  className,
  colspan,
  rowspan,
  width,
  textAlign,
  scope,
}: NativeTableThProps) {
  return (
    <th
      className={getClass('elmo-nativetable-th', className)}
      colSpan={colspan}
      rowSpan={rowspan}
      style={{
        width: width,
        textAlign: textAlign,
      }}
      scope={scope}
    >
      {children}
    </th>
  );
}

export default NativeTableTh;
