import React from 'react';
import Modal from '../../Modal';
import Loader from '../../Loader';
import { PlaceholderModalProps } from './type';
import './PlaceholderModal.scss';

function Title() {
  return <Loader type="short-text" width="250px" />;
}

function PrimaryButton() {
  return (
    <div className="elmo-modal-btn--placeholder">
      <Loader type="button" width="280px" />
    </div>
  );
}

function PlaceholderModal({
  id,
  type,
  closeModal,
  hasPrimaryButton,
}: PlaceholderModalProps) {
  return (
    <Modal
      id={id as string}
      type={type}
      title={<Title />}
      isOpened={true}
      closeModal={closeModal as Function}
      hasCloseFooterButton={false}
      primaryButton={hasPrimaryButton && <PrimaryButton />}
    >
      <Loader type="narrow-content" />
    </Modal>
  );
}

PlaceholderModal.defaultProps = {
  type: null,
  hasPrimaryButton: true,
  id: 'elmo-placeholder-modal',
  closeModal: () => {},
};

export default PlaceholderModal;
