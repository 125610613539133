import React from 'react';
import { Popover } from 'elmo-elements';
import { InfoIcon, IsAppMarket } from 'element';

export const InfoIconButton = () => (
  <IsAppMarket market={'uk'} negate={true}>
    <Popover id="Switcher" mode="hover">
      <Popover.Target>
        <InfoIcon/>
      </Popover.Target>
      <Popover.Content>
        In this tab we have selected Rosters according to your main timezone
      </Popover.Content>
    </Popover>
  </IsAppMarket>
);
