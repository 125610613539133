import React from 'react';
import { useDispatch } from 'react-redux';
import { useBulkApproveContext } from 'contexts';
import { BOX_TIMESHEETS_WEEKLY_DIALOG_OPENED } from 'state/TimesheetsWeeklyPage';
import { ApproveActions, ApproveDialog } from './components';

export function BulkApproveActions() {
  const bulkApproveContext = useBulkApproveContext();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleDeleteBtnClick = () => {
    dispatch(BOX_TIMESHEETS_WEEKLY_DIALOG_OPENED());
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
    bulkApproveContext.disable();
  };

  return (
    <>
      <ApproveActions onApproveBtnClick={handleDeleteBtnClick} />

      <ApproveDialog open={open} onClose={handleDialogClose} />
    </>
  );
}
