import _ from 'lodash';
import { RoleFieldRole } from './RoleField';

export default roleFieldValidator;

export function roleFieldValidator(formFields: { role: RoleFieldRole }) {
  let errors: { role?: string } = {};

  if (_.values(formFields.role).some(_.negate(_.identity as any))) {
    errors.role = 'This field is required';
  }

  return errors;
}
