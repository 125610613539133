import {
  Skeleton,
  Typography,
  TypographyProps,
} from 'extended-oxygen-elements';
import React from 'react';

export type SkeletonTypographyProps = {
  variant?: TypographyProps['variant'];
  maxWidth?: number | string;
};

export default SkeletonTypography;

export function SkeletonTypography({
  variant,
  maxWidth,
}: SkeletonTypographyProps) {
  return (
    <Typography
      variant={variant}
      component="div"
      sx={{
        width: '100%',
        ...(typeof maxWidth === 'number' && {
          maxWidth: maxWidth + 'px',
        }),
        ...(typeof maxWidth === 'string' && {
          maxWidth,
        }),
      }}
    >
      <Skeleton width="100%" />
    </Typography>
  );
}
