import React, { useEffect } from 'react';
import { Input } from 'elmo-elements';
import { Minutes } from 'type';
import { useDurationField } from 'hooks';
import './DurationInput.scss';

type Props = {
  value: Minutes;
  name: string;
  onChangeHandler: (updatedMinutes: Minutes) => void;
  id?: string;
  ariaLabel?: string;
  tabIndex?: number;
};

export const DurationInputMinutes = (props: Props) => {
  const inputProps = useDurationField({
    value: props.value,
    onValueChange: props.onChangeHandler,
  });

  useEffect(() => {
    const input = document.querySelector('.duration-input-wrapper input');
    if (input !== null) {
      input.setAttribute('autocomplete', 'off');
    }
  }, []);

  const { id = 'duration-input', ariaLabel, tabIndex, name } = props;

  return (
    <div className={'duration-input-wrapper'}>
      <div className={'state-input'}>
        <Input
          {...inputProps}
          id={id}
          name={name}
          ariaLabel={ariaLabel}
          tabIndex={tabIndex}
        />
      </div>
    </div>
  );
};
