import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  ApplicationTheme,
  ThemeProvider as ElmoThemeProvider,
} from 'elmo-elements';
import AppLayout from 'layout/AppLayout';
import browserHistory from 'lib/browserHistory';
import { setLocale } from 'lib/helpers';
import { edsTheme, oxygenTheme } from 'lib/styles';
import {
  StyledEngineProvider,
  ThemeProvider as OxygenThemeProvider,
} from 'extended-oxygen-elements';
import * as React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import store from 'state/store';

if ((window as any).Cypress) {
  (window as any).store = store;
}

setLocale();

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <OxygenThemeProvider theme={oxygenTheme}>
        <Provider store={store}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <ElmoThemeProvider theme={edsTheme}>
              <React.Fragment>
                <Router history={browserHistory}>
                  <AppLayout />
                </Router>
                <ApplicationTheme />
              </React.Fragment>
            </ElmoThemeProvider>
          </LocalizationProvider>
        </Provider>
      </OxygenThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
