import React, {useEffect, useState} from 'react';
import { Checkbox, Radio, RadioButtons } from 'elmo-elements';
import moment, { Moment } from 'moment';
import { setLocale } from 'lib/helpers';
import { Typography } from 'oxygen-elements';
import { SERVER_DAY_FORMAT } from 'lib/config';
import { useSelector } from 'react-redux'
import { getFrom } from 'state/RosteredShiftsCollection';

type Props = {
  isChecked: boolean;
  hasSelectedTemplate: boolean;
  setOverride: (value: boolean) => void;
  setDate: (value: number) => void;
  overrideDate: number;
  setTemplateOptions: (start: string, end: string) => void;
};

export const OverrideDay = ({
  isChecked,
  hasSelectedTemplate,
  setOverride,
  setDate,
  overrideDate,
  setTemplateOptions,
}: Props) => {

  const from = useSelector(getFrom)
  const [start, setStart] = useState<Moment>(from.clone());

  useEffect(() => {
    setTemplateOptions(
      start.format(SERVER_DAY_FORMAT),
      start.add(6, 'days').format(SERVER_DAY_FORMAT)
    );
  }, [start]);

  setLocale();
  const getPeriod = () => {
    const date = moment().set({
      day: overrideDate,
    });
    return `${date.format('ddd')} - ${moment(date)
      .add(6, 'days')
      .format('ddd')}`;
  };

  const handleChange = (value: number) => {
    setDate(value);
    setStart(from.clone().add(value === 0 ? 6 : value - 1, 'days'));
  }

  return (
    <>
      <div className={'override-checkbox'}>
        <Checkbox
          id={'override-start-day'}
          label={'Override start day'}
          isDisabled={!hasSelectedTemplate}
          isChecked={isChecked}
          onChange={() => setOverride(!isChecked)}
        />
      </div>
      {isChecked && (
        <div style={{ paddingBottom: '15px', paddingLeft: '10px' }}>
          <Typography
            variant={'body2'}
            style={{
              paddingBottom: '20px',
              letterSpacing: 0.85,
              fontSize: '12px',
            }}
          >
            Select the day of the week you would like the template to start.
          </Typography>
          <RadioButtons
            id="week-days"
            direction={'horizontal'}
            type={'solid'}
            selected={overrideDate}
            className={'ml-2 mb-4'}
            onChange={(value: any) => {
              handleChange(value)
            }}
          >
            <Radio value={1}>M</Radio>
            <Radio value={2}>T</Radio>
            <Radio value={3}>W</Radio>
            <Radio value={4}>T</Radio>
            <Radio value={5}>F</Radio>
            <Radio value={6}>S</Radio>
            <Radio value={0}>S</Radio>
          </RadioButtons>
          <Typography
            variant={'body2'}
            style={{
              paddingLeft: '15px',
              letterSpacing: 0.85,
              fontSize: '12px',
              display: 'inline-block',
            }}
          >
            Currently: {getPeriod()}
          </Typography>
        </div>
      )}
    </>
  );
};
