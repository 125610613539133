import moment, { Moment } from 'moment';
import { ISO8601DateTime } from 'type';

export const isTimesheetPunchInViolated = (
  timesheet: { start: Moment | ISO8601DateTime },
  relatedShift: { start: Moment | ISO8601DateTime } | undefined,
  punchInViolationMinutes: number
) => {
  if (!punchInViolationMinutes || !relatedShift) {
    return false;
  }

  return getIsViolated(
    timesheet.start,
    relatedShift.start,
    punchInViolationMinutes
  );
};

export const isTimesheetPunchOutViolated = (
  timesheet: { end: Moment | ISO8601DateTime | null },
  relatedShift: { end: Moment | ISO8601DateTime } | undefined,
  punchOutViolationMinutes: number
) => {
  if (!punchOutViolationMinutes || !relatedShift || !timesheet.end) {
    return false;
  }

  return getIsViolated(
    timesheet.end,
    relatedShift.end,
    punchOutViolationMinutes
  );
};

function getIsViolated(
  timesheetTime: Moment | ISO8601DateTime,
  shiftTime: Moment | ISO8601DateTime,
  violationMinutes: number
) {
  const diffMinutes = getAbsoluteDiffMinutes(timesheetTime, shiftTime);

  return diffMinutes > violationMinutes;
}

function getAbsoluteDiffMinutes(
  firstTime: Moment | ISO8601DateTime,
  secondTime: Moment | ISO8601DateTime
) {
  const firstTimeMoment = getMomentTime(firstTime);
  const secondTimeMoment = getMomentTime(secondTime);

  return Math.abs(firstTimeMoment.diff(secondTimeMoment, 'minutes'));
}

function getMomentTime(time: Moment | ISO8601DateTime): Moment {
  if (typeof time === 'string') {
    return moment.parseZone(time);
  }

  return time;
}
