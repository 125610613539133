import React from 'react';
import { Col, Text } from 'elmo-elements';
import { TypographyColor } from 'elmo-elements/Typography';
import { TextSize } from 'elmo-elements/Typography/Text/type';

type Props = {
  children?: React.ReactNode,
  size: number,
  className?: string,
  color?: TypographyColor,
  fontSize?: TextSize,
};

export default  function Cell({children, size, className, color, fontSize}: Props) {
  return <Col
    sm={size}
    className={className ? className : ''}
  >
    <Text
      size={fontSize ? fontSize : 'xs'}
      color={color ? color : 'gray'}
    >
      {children}
    </Text>
  </Col>;
}