import React, { memo } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { useBreaksDuration } from 'hooks';
import { BreakIcon, FormattedDuration, NoBreakIcon } from 'element';

type Props = {
  breaks: {
    duration: number; // minutes
  }[];
};

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    icon: {
      fontSize: '17px',
      marginRight: '4px',
    },
    duration: {
      fontSize: '14px',
      lineHeight: '14px',
    },
  })
);

const NoBreaks = () => {
  const classes = useStyles();
  return (
    <span className={classes.wrapper}>
      <NoBreakIcon fontSize="inherit" className={classes.icon} />
    </span>
  );
};

const HasBreaks = ({ children }: { children: number }) => {
  const classes = useStyles();
  return (
    <span className={classes.wrapper}>
      <BreakIcon fontSize="inherit" className={classes.icon} />
      <span className={classes.duration}>
        <FormattedDuration>{children}</FormattedDuration>
      </span>
    </span>
  );
};

export const BreaksDuration = memo(function BreaksDuration({ breaks }: Props) {
  const breaksDuration = useBreaksDuration(breaks);

  if (breaksDuration === 0) {
    return <NoBreaks />;
  }

  return <HasBreaks>{breaksDuration}</HasBreaks>;
});
