import React from 'react';
import { Dictionary } from 'ts-essentials';
import { Route } from 'type';
import { BOX_SOCKETS_MANAGER_DASHBOARD } from 'state/Sockets';
import { useConnectAction } from 'hooks';
import { NestedRoutes } from 'element';
import NotificationsList from 'element/NotificationsList';
import { ManagerDashboardFilters } from './components';

type Props = {
  routes: Dictionary<Route>;
};

const ManagerDashboard = ({ routes }: Props) => {
  useConnectAction(BOX_SOCKETS_MANAGER_DASHBOARD, undefined);

  return (
    <>
      <NestedRoutes routes={routes} />
      <ManagerDashboardFilters />
      <NotificationsList type={'manager'} />
    </>
  );
};

export default ManagerDashboard;
