import React from 'react';
import { Col, Row, Text, withLayoutAware } from 'elmo-elements';
import { Props } from './type';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import {
  BOX_WHOS_WORKING_APPROVE_REQUEST,
  getWorkingShiftsSelector,
} from 'state/ManagerDashboard/WhosWorking';
import { CheckOutlinedIcon, DSTIcon, NotesIcon } from 'element';
import {
  getDatesDiff,
  getDateTimeFormatted,
  getPreferenceLabel,
  getTimeFormatted,
  getUserName,
  secondsToHours,
} from 'lib/helpers';
import { DashboardShift, DashboardShiftBreak, UserFields } from 'type/models';
import { userListSelector } from 'state/UsersCollection';
import { Moment } from 'moment';
import {
  getDateFormat,
  getLangPreferences,
  getTimeFormat,
} from 'state/Account';
import { getAreas, getRoles, getSites } from 'state/AccountTree';
import { hasPermissionSelector } from 'state/Auth/selectors';
import { isAppMarket } from '../../../../../../../../helpers';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Avatar,
} from 'oxygen-elements';
import BreakTimer from '../WorkingNowCard/components/BreakTimer';

export class JustFinishedCardComponent extends React.Component<Props> {
  render() {
    const { shift } = this.props;
    const userFrom: UserFields = this.props.userList[shift.user_id];
    return (
      <div
        className="working-just-finished-card"
        data-testid={shift.timesheet_id}
      >
        <Card className="who-is-working-card-item" role="row">
          <CardHeader
            className="card-header"
            avatar={
              <Avatar
                src={userFrom.avatar.src}
                alt={getUserName(userFrom)}
                size={'small'}
                light
              />
            }
            title={getUserName(userFrom)}
            titleTypographyProps={{
              fontSize: 16,
              fontWeight: 500,
            }}
          />
          <CardContent role="grid" className="card-content">
            <Row>
              <Col span={24 / 4}>
                <Text size={'xs'} color={'gray'}>
                  {this.positionAreLocation}
                </Text>
                <div data-testid="site-area-role">
                  {this.getLocation(shift)}
                </div>
              </Col>
              <Col span={24 / 4}>
                <Text size={'xs'} color={'gray'}>
                  Start - finish
                </Text>
                <div data-testid="start-finish">
                  {this.renderStartFinish(shift)}
                </div>
              </Col>
              <Col span={24 / 4}>
                <Text size={'xs'} color={'gray'}>
                  Hours
                </Text>
                <div data-testid="hours">
                  {getDatesDiff(
                    shift.timesheet_end as Moment,
                    shift.timesheet_start as Moment
                  )}
                </div>
              </Col>

              <Col span={24 / 4}>
                <Text size={'xs'} color={'gray'}>
                  {isAppMarket('uk') ? 'Lunch break' : 'Meal break'}
                </Text>
                <div data-testid="meal-break">
                  <BreakTimer shift={shift} paid={false} />
                </div>
              </Col>
              <Col span={24 / 4}>
                <Text size={'xs'} color={'gray'}>
                  {isAppMarket('uk') ? 'Break' : 'Rest break'}
                </Text>
                <div data-testid="rest-break">
                  <BreakTimer shift={shift} paid={true} />
                </div>
              </Col>
              {shift.event_name && (
                <Col span={24 / 4}>
                  <Text size={'xs'} color={'gray'}>
                    Event name:
                  </Text>
                  <div data-testid={'event-name'}>{shift.event_name}</div>
                </Col>
              )}
              <Col span={24 / 4}>
                <NotesIcon notes={shift.notes} customLabel={true} />
              </Col>
              <Col span={24 / 4}>
                <NotesIcon
                  notes={shift.timesheet_notes}
                  id={'timesheet-notes-icon'}
                />
              </Col>
            </Row>
          </CardContent>
          {this.actions && (
            <CardActions className="card-actions">
              {this.actions!.map((action: any) => (
                <Button
                  id={action.id}
                  key={`btn-${action.label}`}
                  size="large"
                  onClick={action.onClick}
                  disabled={action.isDisabled}
                >
                  {action.icon} {action.label}
                </Button>
              ))}
            </CardActions>
          )}
        </Card>
      </div>
    );
  }

  getLocation = (roster: DashboardShift) => {
    const { roles, areas, sites } = this.props;
    let response = '';

    if (roster.role_id && roles[roster.role_id]) {
      response = roles[roster.role_id].name;
    }

    if (roster.area_id && areas[roster.area_id]) {
      response += ` - ${areas[roster.area_id].name}`;
    }

    if (roster.site_id && sites[roster.site_id]) {
      response += `, ${sites[roster.site_id].name}`;
    }
    return response;
  };

  renderStartFinish = (shift: DashboardShift) => {
    const { dateFormat, timeFormat } = this.props;
    return (
      <>
        {getDateTimeFormatted(
          dateFormat,
          timeFormat,
          shift.timesheet_start as Moment
        )}
        , {getTimeFormatted(timeFormat, shift.timesheet_start as Moment)} -{' '}
        {getTimeFormatted(timeFormat, shift.timesheet_end as Moment)}
        {shift.is_dst_intersect && (
          <>
            {' '}
            <DSTIcon isDST={shift.is_dst_intersect} />
          </>
        )}
      </>
    );
  };

  get positionAreLocation() {
    const { langPreferences } = this.props;
    const position = getPreferenceLabel(
      langPreferences,
      'role',
      'singular',
      '',
      true
    );
    const area = getPreferenceLabel(
      langPreferences,
      'area',
      'singular',
      '',
      false
    );
    const location = getPreferenceLabel(
      langPreferences,
      'site',
      'singular',
      '',
      false
    );
    return `${position} - ${area}, ${location}`;
  }

  get actions() {
    const { shift } = this.props;
    return [
      {
        label: 'Approve',
        id: 'approve',
        onClick: () => {
          this.props.approve(shift.timesheet_id as string);
        },
        icon: <CheckOutlinedIcon />,
        isDisabled: shift.is_approved || !this.props.hasPermissionToApprove,
      },
    ];
  }
}

const mapStateToProps = (state: StoreState) => ({
  workingShifts: getWorkingShiftsSelector(state),
  userList: userListSelector(state),
  areas: getAreas(state),
  roles: getRoles(state),
  sites: getSites(state),
  langPreferences: getLangPreferences(state),
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
  hasPermissionToApprove: hasPermissionSelector(
    state,
    'roster.timesheet.edit.approve'
  ),
});

export const JustFinishedCard = connect(mapStateToProps, {
  approve: BOX_WHOS_WORKING_APPROVE_REQUEST,
})(withLayoutAware(JustFinishedCardComponent));
