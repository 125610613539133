import React, { Component } from 'react';
import { Row } from 'elmo-elements';
import Cell from '../Cell';
import { TextSize } from 'elmo-elements/Typography/Text/type';
import {
  PreferencesCurrencyCode,
  PreferencesCurrencyPlacement,
  PreferencesNumberFormat,
  TimesheetWithPayEntries
} from 'type/models';
import { StoreState } from 'state/types';
import { getCurrencyCode, getCurrencyPlacement, getNumberFormat } from 'state/Account';
import { connect } from 'react-redux';
import { getCurrencyFormatted, secondsToHours } from 'lib/helpers';

type OwnProps = {
  timesheet: TimesheetWithPayEntries
};

type StateProps = {
  currencyCode: PreferencesCurrencyCode;
  numberFormat: PreferencesNumberFormat;
  currencyPlacement: PreferencesCurrencyPlacement;
};

type Props = OwnProps & StateProps;

export class ShiftCost extends Component<Props> {
  fontSize: TextSize = 'sm';

  render() {
    const {timesheet: {rostered_shift_id, end}} = this.props;
    return <div>
      {rostered_shift_id && <>
        {this.renderRostered()}
        {end && this.renderVariance()}
      </>
      }

      {this.getTotalAllowancesCost().count ? this.renderAllowances() : <></>}
      {end && this.renderCost()}
    </div>;
  }

  renderRostered = () => {
    const {timesheet: {rostered_shift}} = this.props;
    return <div data-testid="rostered-cost">
        <Row wrap={'nowrap'}>
        <Cell size={9}>
          Rostered
        </Cell>
        <Cell size={7} fontSize={this.fontSize}>
          {getCurrencyFormatted(
            this.props.numberFormat,
            this.props.currencyCode,
            this.props.currencyPlacement,
            this.getRosteredCost()
          )}
        </Cell>
        <Cell size={8} fontSize={this.fontSize}>
          {secondsToHours(rostered_shift.duration)}
        </Cell>
      </Row>
    </div>;
  };

  renderVariance = () => {
    return <div data-testid="rostered-variance">
      <Row wrap={'nowrap'}>
        <Cell size={9} color="danger">
          Variance
        </Cell>
        <Cell size={7} color="danger" fontSize={this.fontSize}>
          {getCurrencyFormatted(
            this.props.numberFormat,
            this.props.currencyCode,
            this.props.currencyPlacement,
            this.getTimesheetCost() - this.getRosteredCost()
          )}
        </Cell>
        <Cell size={8} color="danger" fontSize={this.fontSize}>
          {this.getVariance().totalHrs}
        </Cell>
      </Row>
    </div>;
  };

  renderAllowances = () => {
    return <div data-testid="total-allowances">
      <Row wrap={'nowrap'}>
        <Cell size={9}>
          Allowances
        </Cell>
        <Cell size={7} fontSize={this.fontSize}>
          {getCurrencyFormatted(
            this.props.numberFormat,
            this.props.currencyCode,
            this.props.currencyPlacement,
            this.getTotalAllowancesCost().cost
          )}
        </Cell>
        <Cell size={8} fontSize={this.fontSize}>
          x {this.getTotalAllowancesCost().count}
        </Cell>
      </Row>
    </div>;
  };

  renderCost = () => {
    return <div data-testid="total-cost">
      <Row wrap={'nowrap'}>
        <Cell color={'black'} size={9} fontSize={this.fontSize}>
          Total cost
        </Cell>
        <Cell color={'black'} size={7} fontSize={this.fontSize}>
          {getCurrencyFormatted(
            this.props.numberFormat,
            this.props.currencyCode,
            this.props.currencyPlacement,
            this.getTimesheetCost()
          )}
        </Cell>
        <Cell size={8}/>
      </Row>
    </div>;
  };

  getRosteredCost = () => {
    const {timesheet: {rostered_shift: {pay_entries}}} = this.props;
    let cost: number = 0;
    if (pay_entries) {
      pay_entries.filter((entry: any) => entry.type === 'shift').map((entry: any) => {
        cost += this.getCost(entry);
      });
    }
    return cost;
  };

  getTimesheetCost = () => {
    const {timesheet: {pay_entries}} = this.props;
    let cost: number = 0;
    if (pay_entries) {
      pay_entries.filter((entry: any) => entry.type === 'shift').forEach((entry: any) => {
        cost += this.getCost(entry);
      });
    }
    return cost;
  };

  getTotalAllowancesCost = () => {
    const {timesheet: {pay_entries}} = this.props;
    let cost: number = 0;
    let count: number = 0;
    if (pay_entries) {
      pay_entries.filter(this.isAllowance).forEach((entry: any) => {
        cost += this.getCost(entry);
        count++;
      });
    }
    return {cost, count};
  };

  getCost = (entry: any) => {
    const {quantity, rate} = entry;
    return (+quantity * +rate) as number;
  };

  isAllowance = (entry: any) => {
    const {source, sub_type, manually_added} = entry;
    return source === 'RTA' && sub_type === 'allowance' && manually_added;
  };

  getVariance = () => {
    const {
      timesheet: {rostered_shift},
      timesheet
    } = this.props;
    const t = timesheet.duration * 60 - rostered_shift.duration;
    return {
      totalHrs:
        this.getSign(t) +
        secondsToHours(Math.abs(t), true)
    };
  };

  getSign = (result: number) => {
    if (result === 0) {
      return '';
    }
    return result < 0 ? '-' : '+';
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  numberFormat: getNumberFormat(state),
  currencyCode: getCurrencyCode(state),
  currencyPlacement: getCurrencyPlacement(state)
});

export default connect(
  mapStateToProps
)(ShiftCost);
