import * as React from 'react';
import { Heading } from 'elmo-elements';

interface OwnProps {
  title: string;
  className?: string;
}

function SectionHeading(props: OwnProps) {
  const {title, className} = props;
  return (
    <div className={className}>
      <Heading isBold={true}>
        {title}
      </Heading>
    </div>
  );
}

export default SectionHeading;