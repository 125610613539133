import React, { DragEvent, MouseEvent, ReactNode } from 'react';
import { connect } from 'react-redux';
import classNames from 'clsx';
import { times } from 'lodash';
import moment, { Duration } from 'moment';
import { MouseButton } from 'lib/consntants';
import { StoreState } from 'state/types';
import { getPreferencesTimeInterval } from 'state/Account';
import {
  dstFallBackHourIndexSelector,
  getDragAndDropOffset,
  numberOfHoursSelector,
  oneMinuteInPxSelector,
  timelineWidthSelector,
} from 'state/Roster/RosterDayView';
import { RosterCell, RosterCellType } from '../../../../components';
import './timeline.scss';

type MouseEventCallback = (
  duration: Duration,
  clientX: number,
  rowIndex: number
) => void;

export type TimelineRow = {
  type?: RosterCellType;
  content: ReactNode;
  onMouseDown?: MouseEventCallback;
  onDoubleClick?: MouseEventCallback;
  onDrop?: MouseEventCallback;
  validateDragOver?: () => boolean;
  isTall?: boolean;
  label?: string;
};

type OwnProps = {
  rows: TimelineRow[];
  className?: string;
};

type StateProps = {
  timeInterval: number;
  numberOfHours: number;
  oneMinInPx: number;
  timelineWidth: number;
  dstFallBackHourIndex: number;
  dragAndDropOffset: number;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

const elements = {
  root: 'timeline',
  rootImproved: 'timeline__improved',
  row: 'timeline__row',
  rowWithSpacing: 'timeline__row--with-spacing',
  rowDroppable: 'timeline__row--droppable',
  rowNotDroppable: 'timeline__row--not-droppable',
  background: 'timeline__background'
};

const dragAndDropHighlight = (element: HTMLDivElement, isValid: boolean) => {
  element.classList.add(
    isValid ? elements.rowDroppable : elements.rowNotDroppable
  );
};
const clearDragAndDropHighlighting = (element: HTMLDivElement) => {
  element.classList.remove(elements.rowDroppable);
  element.classList.remove(elements.rowNotDroppable);
};
const isInteractiveElementTriggeredEvent = (
  event: MouseEvent<HTMLDivElement>
): boolean =>
  event.target === event.currentTarget || // timeline node triggered the event
  !!(event.target as HTMLElement).closest(`.${elements.background}`); // empty placeholder node triggered the event

export const TimelineComponent = ({
  className,
  rows,
  timeInterval,
  numberOfHours,
  timelineWidth,
  oneMinInPx,
  dstFallBackHourIndex,
  dragAndDropOffset
}: Props) => {
  const onMouseDownCreator = (
    callBack: MouseEventCallback | undefined,
    rowIndex: number
  ) => {
    if (!callBack) {
      return;
    }

    return (event: MouseEvent<HTMLDivElement>) => {
      if (event.button !== MouseButton.Main) {
        return;
      }

      if (!isInteractiveElementTriggeredEvent(event)) {
        return;
      }

      const bounds = event.currentTarget.getBoundingClientRect();
      const leftPx = event.clientX - bounds.left;
      const startMins = leftPx / oneMinInPx;

      const duration = moment.duration(
        Math.round(startMins / timeInterval) * timeInterval,
        'minutes'
      );

      callBack(duration, event.clientX, rowIndex);
    };
  };

  // Drag and drop handlers
  const handleDragOverCreator = (
    validateDragOver: (() => boolean) | undefined
  ) => {
    return (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.dataTransfer.dropEffect = 'all' as any;  // TODO double-check

      if (validateDragOver) {
        const isValid = validateDragOver();
        dragAndDropHighlight(event.currentTarget, isValid);
      }
    };
  };
  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    clearDragAndDropHighlighting(event.currentTarget);
  };
  const handleDropCreator = (
    callBack: MouseEventCallback | undefined,
    rowIndex: number
  ) => {

    if (!callBack) {
      return;
    }

    return (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      clearDragAndDropHighlighting(event.currentTarget);

      const bounds = event.currentTarget.getBoundingClientRect();
      const timelineOffsetPx = event.clientX - bounds.left;
      const leftPx = timelineOffsetPx - dragAndDropOffset;
      const startMins = leftPx / oneMinInPx;
      const duration = moment.duration(
        Math.round(startMins / timeInterval) * timeInterval,
        'minutes'
      );
      callBack(duration, event.clientX, rowIndex);
    };
  };

  return (
    <div
      className={classNames(elements.root, className, {
        [elements.rootImproved]: true
      })}
      style={{
        width: timelineWidth
      }}
    >
      {rows.map(
        (
          {
            content,
            type = 'full',
            onMouseDown,
            isTall = false,
            onDoubleClick,
            label,
            onDrop,
            validateDragOver
          },
          rowIndex
        ) => {
          const hasPointerEvents = !!(onMouseDown || onDoubleClick);

          return (
            <div
              key={rowIndex}
              className={classNames(elements.row, {
                [elements.rowWithSpacing]: type !== 'top'
              })}
              onMouseDown={onMouseDownCreator(onMouseDown, rowIndex)}
              onDoubleClick={onMouseDownCreator(onDoubleClick, rowIndex)}
              onDragOver={handleDragOverCreator(validateDragOver)}
              onDragLeave={handleDragLeave}
              onDrop={handleDropCreator(onDrop, rowIndex)}
            >
              <div className={elements.background}>
                {times(numberOfHours, cellIndex => {
                  let cellLabel: string = hasPointerEvents ? '+ Add' : '';
                  return (
                    <RosterCell
                      key={cellIndex}
                      type={type}
                      isTall={isTall}
                      hasMinWidth={true}
                      label={cellLabel}
                      labelOnHover={true}
                      fontWeight={'normal'}
                    />
                  );
                })}
              </div>

              {content}
            </div>
          );
        }
      )}
    </div>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  timeInterval: getPreferencesTimeInterval(state),
  numberOfHours: numberOfHoursSelector(state),
  timelineWidth: timelineWidthSelector(state),
  oneMinInPx: oneMinuteInPxSelector(state),
  dstFallBackHourIndex: dstFallBackHourIndexSelector(state),
  dragAndDropOffset: getDragAndDropOffset(state)
});

// const mapDispatchToProps: DispatchProps = {};

export const Timeline = connect(mapStateToProps)(TimelineComponent);
