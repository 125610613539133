import React from 'react';
import { CircularProgress, Box } from 'extended-oxygen-elements';

export function CenteredCircularProgress() {
  return (
    <Box display="flex" justifyContent="center" py={2}>
      <CircularProgress />
    </Box>
  );
}
