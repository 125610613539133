import React, { Component } from 'react';
import { SelectList, Text } from 'elmo-elements';
import { StoreState } from 'state/types';
import { BOX_ROSTER_OFFERS_CHANGES } from 'state/Roster/Roster/actions';
import { connect } from 'react-redux';
import { RosterOffersModalPayload } from 'state/Roster/Roster/types';
import { Moment } from 'moment';
import {
  CallMadeIcon,
  CallMadeOutlinedIcon,
  PageDialog,
  PageDialogCancel,
  PageDialogCloseIconButton,
  PageDialogSubmit,
} from 'element';
import { StringMap } from 'type';
import { ShiftForOfferItem } from 'state/RosteredShifts/types';
import moment from 'moment-timezone';
import { getFrom, getSiteId, getTo } from 'state/RosteredShiftsCollection';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';

type DispatchProps = {
  offerShifts: (params: RosterOffersModalPayload) => void;
};

type OwnProps = {
  isOpened: boolean;
  closeModal: () => void;
  siteId: string;
  isOffering: boolean;
  from: Moment;
  to: Moment;
  offers: StringMap<ShiftForOfferItem>;
};

type State = {
  isCurrent: string;
};

type Props = OwnProps & DispatchProps;

export class OffersModal extends Component<Props, State> {
  state = {
    isCurrent: '',
  };

  render() {
    const { isOffering, offers } = this.props;
    const { isCurrent } = this.state;
    const total = Object.keys(offers).length;
    return (
      <PageDialog
        maxWidth={'xs'}
        open={this.props.isOpened}
        id={'offer-rostered-shifts'}
        onClose={this.props.closeModal}
      >
        <DialogTitle>
          Shifts offers
          <PageDialogCloseIconButton onClose={this.props.closeModal} />
        </DialogTitle>
        <DialogContent>{this.showListOfAvailableOffers()}</DialogContent>
        <DialogActions>
          <PageDialogSubmit
            onClick={this.offerAllShifts}
            disabled={isOffering || isCurrent !== '' || total === 0}
          >
            Offer all unassigned shifts
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  }

  showEmptyListMessage = () => {
    return <Text>Currently no shifts available for offering.</Text>;
  };

  showListOfAvailableOffers = () => {
    const { offers, isOffering } = this.props;
    const { isCurrent } = this.state;
    const getChangesNumber = (offer: any) => {
      const { shifts_count } = offer;
      return shifts_count > 1
        ? `${shifts_count} shifts`
        : `${shifts_count} shift`;
    };
    const getDate = (date: string) => {
      return moment.parseZone(date).format('MMM DD');
    };
    const total = Object.keys(offers).length;
    if (total === 0) {
      return this.showEmptyListMessage();
    }
    return (
      <SelectList>
        {Object.keys(offers).map((key: string, index: number) => (
          <SelectList.Option
            onToggle={() => this.offerItem(offers[key].id)}
            key={index}
            icon={
              isCurrent === offers[key].id ? (
                <CallMadeIcon />
              ) : (
                <CallMadeOutlinedIcon />
              )
            }
            isDisabled={isOffering}
          >
            <Text
              size={'xs'}
              className={
                isCurrent === offers[key].id ? 'publish-in-progress' : ''
              }
            >
              Offer {getChangesNumber(offers[key])} for{' '}
              {getDate(offers[key].start)} - {getDate(offers[key].end)}
            </Text>
          </SelectList.Option>
        ))}
      </SelectList>
    );
  };

  offerAllShifts = () => {
    this.props.offerShifts({
      site_id: this.props.siteId,
      from: this.props.from,
      to: this.props.to,
      closeModal: true,
    });
  };

  offerItem = (id: string) => {
    const { offers } = this.props;
    const close = Object.keys(offers).length > 1;
    this.setState({
      isCurrent: id,
    });
    this.props.offerShifts({
      site_id: this.props.siteId,
      roster_id: id,
      from: this.props.from,
      to: this.props.to,
      closeModal: !close,
    });
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.offers !== this.props.offers) {
      this.setState({
        isCurrent: '',
      });
    }
  }
}

const mapStateToProps = (state: StoreState) => ({
  offers: state.roster.availableOffers,
  isOffering: state.roster.isOffering,
  siteId: getSiteId(state),
  from: getFrom(state),
  to: getTo(state),
});

const mapToDispatchProps = {
  offerShifts: BOX_ROSTER_OFFERS_CHANGES,
};

export default connect(mapStateToProps, mapToDispatchProps)(OffersModal);
