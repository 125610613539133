import React from 'react';
import { DataTableElement } from '../type';
import { getClass } from '../../_lib/helper';
import { TextAlignProperty } from 'csstype';
import './DataTableTd.scss';

type DataTableTdProps = DataTableElement & {
  /** Colspan of the cell */
  colSpan?: number;
  /** Text alignment "center" | "end" | "justify" | "left" | "match-parent" | "right" | "start" */
  textAlign?: TextAlignProperty;
};

/**
 * The table cell component.
 * @param children
 * @param className
 * @param colSpan
 * @constructor
 */
function DataTableTd({
  children,
  className,
  colSpan,
  textAlign,
}: DataTableTdProps) {
  return (
    <td
      className={getClass('elmo-datatable__td', className)}
      colSpan={colSpan}
      style={{ textAlign: textAlign }}
    >
      {children}
    </td>
  );
}

export default DataTableTd;
