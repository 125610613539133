import {
  compact,
  every,
  flatten,
  get,
  groupBy,
  map,
  mapValues,
  reduce,
  uniq,
} from 'lodash';
import { AxiosError } from 'axios';
import moment, { Moment } from 'moment';
import qs from 'qs';
import { AuthError, FormattedErrors, RosterPageQuery, StringMap } from 'type';
import browserHistory from 'lib/browserHistory';
import { SERVER_DAY_FORMAT } from 'lib/config';

export * from './defaultBreaks';

export const getKeysWithTrueValues = (flags: StringMap<boolean>): string[] =>
  reduce(
    flags,
    (accumulator: string[], flag: boolean, key: string) => {
      if (flag) {
        accumulator.push(key);
      }

      return accumulator;
    },
    []
  );

export const nest = <T>(
  seq: T[],
  keys: (keyof T)[] | ((item: T) => string)[]
): any => {
  if (!keys.length) {
    return seq;
  }

  const first = keys[0];
  const rest = keys.slice(1);

  return mapValues(groupBy(seq, first), value => nest(value, rest)) as any;
};

export const groupById = <T>(
  id: string,
  item: T | T[],
  accumulator: StringMap<T[]> = {}
): StringMap<T[]> => {
  const items = Array.isArray(item) ? item : [item];

  if (accumulator[id]) {
    accumulator[id] = accumulator[id].concat(items);
  } else {
    accumulator[id] = [...items];
  }

  return accumulator;
};

export const checkNullFilter = <T>(
  filter: (collectionToBeFiltered: T[], filterIds: string[]) => T[]
) => (collectionToBeFiltered: T[], filterIdsOrEmpty: null | string[]): T[] => {
  if (!filterIdsOrEmpty) {
    return collectionToBeFiltered;
  }

  return filter(collectionToBeFiltered, filterIdsOrEmpty);
};

export const withoutAssignedDataCreator = <T extends { id: string }>(
  ...collections: StringMap<any[]>[]
) => ({ id }: T): boolean => {
  return every(
    collections.map((collection: StringMap<any[]>) => {
      const selectedById: any[] = collection[id] || [];

      return !selectedById.length;
    })
  );
};

// copied from lib/helpers in order to remove circular dependency
// TODO: remove state from lib/helpers
export const formatError = (error: AxiosError): FormattedErrors => {
  const errors = get(error, 'response.data.errors', []);
  const data: {
    error_message: '';
  } = get(error, 'response.data', []);
  let messages: any[] = compact([data.error_message]);
  if (map(errors).length || messages.length > 0) {
    map(errors, (k: any) => {
      messages.push(k instanceof Array ? flatten(k) : k);
    });
    // uniq is necessary because when the user creates custom fields with the same labels,
    // the server returns the same message for both fields
    return compact(uniq(flatten(messages)));
  } else {
    return [error.toString()];
  }
};
export const formatAuthError = (error: AxiosError) => {
  const { message }: AuthError = get(error, 'response.data', {
    message: error.toString()
  });
  return [message];
};

export const isCaptchaRequired = (error: AxiosError) => {
  const { recaptcha_required }: AuthError = get(error, 'response.data', {
    message: error.toString()
  });
  return recaptcha_required ? recaptcha_required : false;
};

export const getErrorStatus = (error: AxiosError): number =>
  get(error, 'response.status', 0);

export const compareValueWithSearchQuery = (
  value: string,
  searchQuery: string
): boolean =>
  value
    .toLowerCase()
    .trim()
    .includes(searchQuery.toLowerCase().trim());

export const relocateToShift = (start: Moment) => {
  const { search } = window.location;
  const { day }: Partial<RosterPageQuery> = qs.parse(search.substr(1));
  const startDay = moment(start)
    .startOf('isoWeek')
    .format(SERVER_DAY_FORMAT);
  const curDay = moment(day)
    .startOf('isoWeek')
    .format(SERVER_DAY_FORMAT);
  return curDay !== startDay;
};

export const archivedOrWithDataFilter = <I extends { id: string; archived: boolean }>(
  allInstances: I[],
  archivedIdsToBeShown: string[],
  selectedIds: string[]
) =>
  allInstances.filter(
    instance =>
      !instance.archived ||
      archivedIdsToBeShown.includes(instance.id) ||
      selectedIds.includes(instance.id)
  );
