import { createReducer } from 'lib/store-utils';
import { ShiftTimeRangeInputReducerState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';

export const shiftTimeRangeInput = createReducer<
  ShiftTimeRangeInputReducerState
>({}, getDefaultState());

shiftTimeRangeInput
  .on(
    actions.BOX_SHIFT_TIME_RANGE_INPUT_OPENED,
    (state, payload): ShiftTimeRangeInputReducerState => ({
      ...payload
    })
  )
  .on(actions.BOX_SHIFT_TIME_RANGE_INPUT_CLOSED, getDefaultState)
  .on(actions.BOX_SHIFT_TIME_RANGE_INPUT_CREATE_FINISHED, getDefaultState);
