import React, { Component } from 'react';
import {
  Col,
  LoadingOverlay,
  Pagination,
  Paragraph,
  Row,
  Text,
} from 'elmo-elements';
import {
  CallMadeIcon,
  CheckOutlinedIcon,
  HelpOutlineIcon,
  IsAppMarket,
  SwapHorizOutlinedIcon,
  NotesIcon,
  DSTIcon,
} from 'element';
import SwapShiftModal from '../../../SwapShiftModal';
import { connect } from 'react-redux';
import {
  BOX_SHIFT_TRADES_ACCEPT_SHIFT_REQUEST,
  BOX_SHIFT_TRADES_CHANGE_PAGE,
  BOX_SHIFT_TRADES_CHANGE_PAGE_SIZE,
  BOX_SHIFT_TRADES_GET_SWAP_PROPOSALS,
} from 'state/EmployeeDashboard/ShiftTrades';
import { ShiftTrade, ShiftTradeUser } from 'type';
import { StoreState } from 'state/types';
import {
  getSelectedFilter,
  shiftTradeAcceptingId,
  shiftTradesWithPagerSelector,
} from 'state/EmployeeDashboard/ShiftTrades/selectors';
import { getDateTimeFormatted, getPreferenceLabel } from 'lib/helpers';
import { DispatchProps, StateProps } from './types';
import {
  colProps,
  getFullName,
  getPlaceholderText,
  getPositionAreaLocation,
  getShiftTradeDuration,
  getTimezone,
} from '../../../../../../helpers';
import { hasPermissionSelector } from 'state/Auth';
import {
  getDateFormat,
  getLangPreferences,
  getTimeFormat,
} from 'state/Account';
import { EmptyMessage } from '../../../EmptyMessage';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from 'oxygen-elements';

type Props = DispatchProps & StateProps;

export class AvailableShifts extends Component<Props> {
  render() {
    const { shiftTrades, selectedFilter } = this.props;
    return (
      <div className={'available-shifts-wrapper'}>
        {shiftTrades.total > 0 ? (
          this.showShifts()
        ) : (
          <EmptyMessage message={getPlaceholderText(selectedFilter)} />
        )}
        <SwapShiftModal />
      </div>
    );
  }

  showShifts = () => {
    const {
      shiftTrades,
      isAccepting,
      langPreferences,
      dateFormat,
      timeFormat,
    } = this.props;
    return (
      <LoadingOverlay isLoading={isAccepting !== ''}>
        <div className={'available-shifts-list'}>
          {shiftTrades.page.map((shift: ShiftTrade, index: number) => (
            <Card className="shift-swap-list-item" key={index}>
              <CardHeader
                className="card-header"
                avatar={this.getHeaderByType(shift.type).icon}
                title={this.getHeaderByType(shift.type).title}
                titleTypographyProps={{
                  paddingLeft: '12px',
                }}
              />
              <CardHeader
                className="card-header"
                avatar={this.getUserAvatar(shift.rostered_shift.user)}
                title={getFullName(shift.rostered_shift.user)}
                titleTypographyProps={{
                  fontSize: 16,
                  fontWeight: 500,
                }}
              />
              <CardContent className="card-content" role="grid">
                <Row>
                  <Col {...colProps}>
                    <Text color="gray" size="sm">
                      {getPreferenceLabel(
                        langPreferences,
                        'role',
                        'singular',
                        '',
                        true
                      )}
                      -{' '}
                      {getPreferenceLabel(langPreferences, 'area', 'singular')},{' '}
                      {getPreferenceLabel(langPreferences, 'site', 'singular')}
                    </Text>
                    <Paragraph isTruncate={true}>
                      {getPositionAreaLocation(shift.rostered_shift)}
                    </Paragraph>
                  </Col>
                  <Col {...colProps}>
                    <Text color="gray" size="sm">
                      Date
                    </Text>
                    <div>
                      {getDateTimeFormatted(
                        dateFormat,
                        timeFormat,
                        shift.rostered_shift.start
                      )}
                    </div>
                  </Col>
                  <Col {...colProps}>
                    <Text color="gray" size="sm">
                      Start - finish (duration){' '}
                      <DSTIcon isDST={shift.rostered_shift.is_dst_intersect} />
                    </Text>
                    <div>
                      {getShiftTradeDuration(timeFormat, shift.rostered_shift)}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <IsAppMarket market={'uk'} negate={true}>
                    <Col
                      className={colProps.className}
                      xs={24}
                      sm={colProps.sm}
                    >
                      <Text color="gray" size="sm">
                        Timezone
                      </Text>
                      <Paragraph isTruncate={true}>
                        {getTimezone(shift.rostered_shift)}
                      </Paragraph>
                    </Col>
                  </IsAppMarket>
                  <Col
                    className={colProps.className}
                    xs={24}
                    sm={colProps.sm}
                    span={3}
                  >
                    <Text color="gray" size="sm">
                      Note
                    </Text>
                    <Paragraph>
                      {shift.note && shift.note !== '' ? shift.note : '-'}
                    </Paragraph>
                  </Col>
                  <Col
                    className={colProps.className}
                    xs={24}
                    sm={(24 / 6) * 2}
                    span={24 / 6}
                  >
                    <NotesIcon notes={shift.rostered_shift.notes} />
                  </Col>
                </Row>
              </CardContent>
              <CardActions className="card-actions">
                {this.getActionsByType(shift.type, shift).map(
                  (action, index) => (
                    <Button
                      key={`btn-${action.label}`}
                      size="large"
                      onClick={action.onClick}
                    >
                      {action.icon} {action.label}
                    </Button>
                  )
                )}
              </CardActions>
            </Card>
          ))}
        </div>
        {this.showPagination()}
      </LoadingOverlay>
    );
  };

  showPagination = () => {
    const { shiftTrades } = this.props;
    return (
      <Pagination
        totalResults={shiftTrades.total}
        currentPage={shiftTrades.currentPage}
        pageSize={shiftTrades.pageSize}
        pageSizeOptions={[10, 20, 30]}
        onPageChange={this.props.changePage}
        onPageSizeChange={this.props.changePageSize}
      />
    );
  };

  getUserAvatar = (user: ShiftTradeUser) => {
    return user !== null ? (
      <Avatar
        size={'small'}
        alt={getFullName(user)}
        src={user.avatar_url}
        light
      />
    ) : (
      <Text color={'warning'}>
        <HelpOutlineIcon />
      </Text>
    );
  };

  getHeaderByType = (type: 'offer' | 'swap') => {
    return type === 'offer' ? this.getOfferHeader() : this.getSwapHeader();
  };

  getOfferHeader = () => {
    return {
      icon: <CallMadeIcon />,
      title: 'Shift offer',
      titleAddOn: ' ',
    };
  };

  getSwapHeader = () => {
    return {
      icon: <SwapHorizOutlinedIcon />,
      title: 'Shift swap',
      titleAddOn: ' ',
    };
  };

  getActionsByType = (type: 'offer' | 'swap', trade: ShiftTrade) => {
    return type === 'offer'
      ? this.getOfferCardActions(trade)
      : this.getSwapCardActions(trade);
  };

  getOfferCardActions = (trade: ShiftTrade) => {
    return [
      {
        icon: <CheckOutlinedIcon />,
        onClick: () => {
          this.props.acceptTrade({
            shift_trade_id: trade.id,
          });
        },
        label: 'Accept',
        isLoading: this.props.isAccepting === trade.id,
        isDisabled: this.props.isAccepting === trade.id,
      },
    ];
  };

  getSwapCardActions = (trade: ShiftTrade) => {
    return [
      {
        icon: <SwapHorizOutlinedIcon />,
        onClick: () => {
          this.props.getProposals({
            id: trade.id,
          });
        },
        label: 'Swap',
      },
    ];
  };
}

const mapToDispatchProps: DispatchProps = {
  getProposals: BOX_SHIFT_TRADES_GET_SWAP_PROPOSALS,
  changePage: BOX_SHIFT_TRADES_CHANGE_PAGE,
  changePageSize: BOX_SHIFT_TRADES_CHANGE_PAGE_SIZE,
  acceptTrade: BOX_SHIFT_TRADES_ACCEPT_SHIFT_REQUEST,
};

const mapStateToProps = (state: StoreState): StateProps => ({
  shiftTrades: shiftTradesWithPagerSelector(state),
  isAccepting: shiftTradeAcceptingId(state),
  selectedFilter: getSelectedFilter(state),
  hasPermissions: hasPermissionSelector(
    state,
    'employeedashboard.viewshifttrades'
  ),
  langPreferences: getLangPreferences(state),
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
});

export default connect(mapStateToProps, mapToDispatchProps)(AvailableShifts);
