import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compact, filter } from 'lodash';
import { HeaderTabLinkProps } from 'element/Header/type';
import { LanguagePreferences, PermissionName } from 'type';
import { privateRoutes } from 'routes';
import { getPreferenceLabel, hasPermission } from 'lib/helpers';
import { StoreState } from 'state/types';
import {
  getCurrentUserPermissions,
  getEmployeeType,
  hasPermissionSelector,
} from 'state/Auth';
import {
  getCanViewForecastsAndEvents,
  getCanViewShiftOffers,
  getCanViewShiftSwaps,
  getCanViewUnavailability,
  getLangPreferences,
} from 'state/Account';
import { isAppMarket } from '../../../../helpers';

type MenuItem = HeaderTabLinkProps & {
  permission?: PermissionName;
};

type OwnProps = {
  children: (menu: MenuItem[]) => ReactNode;
};

type StateProps = {
  currentUserPermissions: PermissionName[];
  canViewShiftOffers: boolean;
  canViewShiftSwaps: boolean;
  canViewForecastsAndEvents: boolean;
  employeeType: string;
  canSeeUnavailabilityTab: boolean;
  canViewShiftTrades: boolean;
  langPreferences: LanguagePreferences;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps;

export class WithEmployeeDashboardMenuComponent extends Component<Props> {

  render() {
    return this.props.children(this.menu);
  }

  get menu(): MenuItem[] {
    const {
      currentUserPermissions,
      canViewForecastsAndEvents,
      canViewShiftTrades,
      canViewShiftOffers,
      canViewShiftSwaps,
      match: { path },
      canSeeUnavailabilityTab
    } = this.props;
    const { routes } = privateRoutes.employeeDashboard;

    const rosterLabel = isAppMarket('uk') ? 'rota' : 'roster';
    const allItems: (MenuItem | false)[] = [
      {
        label: 'Overview',
        onClick: this.goTo(routes.overview.path),
        active: routes.overview.path === path,
        permission: (routes.overview as any).permission
      },
      {
        label: `My ${getPreferenceLabel(this.props.langPreferences, rosterLabel, 'plural', rosterLabel, true)}`,
        onClick: this.goTo(routes.rosters.path),
        active: routes.rosters.path === path,
        permission: (routes.rosters as any).permission
      },
      {
        label: `My Timesheets`,
        onClick: this.goTo(routes.timesheets.path),
        active: routes.timesheets.path === path,
        permission: (routes.timesheets as any).permission
      },
      canViewShiftTrades && (canViewShiftOffers || canViewShiftSwaps) && {
        label: `Shift Trades`,
        onClick: this.goTo(routes.trades.path),
        active: routes.trades.path === path,
        permission: (routes.trades as any).permission
      },
      canViewForecastsAndEvents && {
        label: getPreferenceLabel(this.props.langPreferences, 'event', 'plural', '', true),
        onClick: this.goTo(routes.events.path),
        active: routes.events.path === path,
        permission: (routes.events as any).permission
      }
    ];

    if ( canSeeUnavailabilityTab ) {
      allItems.push({
        label: 'Availability',
        onClick: this.goTo(routes.unavailability.path),
        active: routes.unavailability.path === path,
        permission: (routes.unavailability as any).permission
      });
    }

    return filter(compact(allItems), ({ permission }) =>
      hasPermission(currentUserPermissions, permission)
    );
  }

  private getLabelForLeave = () => {
    const { employeeType } = this.props;
    if ( employeeType === 'Full time' || employeeType === 'Part time' ) {
      document.title = 'Leave';
      return 'Leave';
    }
    document.title = 'Unavailability';
    return 'Unavailability';
  }

  private goTo = (path: string) => () => {
    this.props.history.push(path);
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  currentUserPermissions: getCurrentUserPermissions(state),
  employeeType: getEmployeeType(state),
  canViewShiftOffers: getCanViewShiftOffers(state),
  canViewShiftSwaps: getCanViewShiftSwaps(state),
  canViewForecastsAndEvents: getCanViewForecastsAndEvents(state),
  canSeeUnavailabilityTab: getCanViewUnavailability(state),
  canViewShiftTrades: hasPermissionSelector(state, 'employeedashboard.viewshifttrades'),
  langPreferences: getLangPreferences(state)
});

// const mapDispatchToProps: DispatchProps = {};

export const WithEmployeeDashboardMenu = withRouter(
  connect(mapStateToProps)(WithEmployeeDashboardMenuComponent)
);
