import React, { useState } from 'react';
import {
  Box,
  Button,
  Drawer,
  FormControlLabel,
  IconButton,
  Typography,
  Checkbox,
} from 'oxygen-elements';
import { CloseOutlinedIcon } from 'element';
import { useSelector } from 'react-redux';
import { getLangPreferences } from 'state/Account';
import { getIsArchivedFromURL } from './helpers';

type Props = {
  isOpened: boolean;
  onClose: () => void;
};

export const Filters = ({ isOpened, onClose }: Props) => {
  const langPreferences = useSelector(getLangPreferences);
  const [archived, setArchived] = useState(getIsArchivedFromURL());

  const onSubmit = () => {
    const url = new URL(window.location.href);
    url.searchParams.set('archived', `${archived}`);
    window.history.pushState(null, '', url.toString());
    onClose();
  };

  return (
    <Drawer
      open={isOpened}
      onClose={onClose}
      anchor={'right'}
      id={'account-tree-filters-modal'}
    >
      <Box sx={{ overflow: 'hidden', minWidth: '300px', maxWidth: '300px' }}>
        <Box
          padding={3}
          sx={{ height: 'calc(100vh - 80px)', overflowY: 'auto' }}
        >
          <Typography
            variant={'h3'}
            paddingBottom={3}
            sx={{ position: 'relative' }}
          >
            Filter
            <IconButton
              data-testid={'close-filters-btn'}
              onClick={onClose}
              sx={{ position: 'absolute', right: '-7px', top: '-7px' }}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Typography>

          <Box paddingTop={3} paddingBottom={3}>
            <FormControlLabel
              sx={{
                marginLeft: '-10px',
                alignItems: 'flex-start',
                '& .MuiCheckbox-root': { marginTop: '-5px' },
              }}
              control={
                <Checkbox
                  data-testid={'archived-chkbox'}
                  checked={archived}
                  onChange={(e) => setArchived(e.target.checked)}
                />
              }
              label={`Show archived ${langPreferences.site.plural} and ${langPreferences.area.plural}`}
            />
          </Box>
        </Box>

        <Box
          paddingLeft={3}
          paddingRight={3}
          paddingTop={2}
          paddingBottom={2}
          sx={{
            background: 'white',
            position: 'absolute',
            bottom: '0',
            left: '0',
            right: '0',
            boxShadow: '0 0 6px 2px #ccc',
            zIndex: 3,
          }}
        >
          <Button variant={'contained'} onClick={onSubmit} fullWidth={false}>
            Apply
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};
