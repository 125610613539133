import React, { Component, ReactElement } from 'react';
import { FileUploadProps, FileUploadState, UploadedFile } from './type';
import { ButtonUpload } from './component/ButtonUpload';
import { FileSuccess } from './component/FileSuccess';
import { FileReject } from './component/FileReject';
import { DropArea } from './component/DropArea';
import Dropzone from 'react-dropzone';
import './FileUpload.scss';

class FileUpload extends Component<FileUploadProps, FileUploadState> {
  static defaultProps = {
    displayDropArea: true,
    displayFiles: true,
    displayRejectedFiles: true,
    isMulti: true,
    disabled: false,
    fileMaxSize: 2,
    acceptedFiles: ['image/*', '.pdf'],
    dropareaText: <p>Drop files here, or click to select files to upload.</p>,
    draggingText: 'Drop files here',
  };

  private _maxSize = 2;

  constructor(props: FileUploadProps) {
    super(props);
    const { fileMaxSize } = this.props;
    if (fileMaxSize) {
      this.maxSize = fileMaxSize;
    }

    this.state = {
      files: [],
      rejectFiles: [],
      displayError: false,
    };
  }

  set maxSize(value: number) {
    this._maxSize = value;
  }

  get maxSize(): number {
    return 1024 * 1024 * this._maxSize;
  }

  onDrop = (acceptedFiles: any, rejectedFiles: any): void => {
    const { isMulti } = this.props;

    const data = {};
    let files: any[] = [];
    let rejected: any[] = [];
    if (isMulti) {
      files = [...this.state.files];
      rejected = [...this.state.rejectFiles];
    }

    if (acceptedFiles && acceptedFiles.length) {
      Object.assign(data, {
        files: acceptedFiles.concat(files),
      });
    }

    if (rejectedFiles && rejectedFiles.length) {
      Object.assign(data, {
        rejectFiles: rejectedFiles.concat(rejected),
      });
    }

    this.setState(data);
    const { onUploadStart } = this.props;
    if (onUploadStart) {
      onUploadStart(acceptedFiles);
    }
  };

  onCancel(): void {
    this.setState({
      files: [],
    });
  }

  onDragEnter = (): void => {
    const { onDragEnter } = this.props;
    if (onDragEnter) {
      onDragEnter();
    }
  };

  onDragLeave = (): void => {
    const { onDragLeave } = this.props;
    if (onDragLeave) {
      onDragLeave();
    }
  };

  onDragOver = (): void => {
    const { onDragOver } = this.props;
    if (onDragOver) {
      onDragOver();
    }
  };

  onFileDialogCancel = (): void => {
    const { onFileDialogCancel } = this.props;
    if (onFileDialogCancel) {
      onFileDialogCancel();
    }
  };

  onDropAccepted = (files: any): void => {
    const { onDropAccepted } = this.props;
    if (onDropAccepted) {
      onDropAccepted(files);
    }
  };

  onDropRejected = (files: any): void => {
    const { onDropRejected } = this.props;
    if (onDropRejected) {
      onDropRejected(files);
    }
    this.setState({
      displayError: true,
    });
  };

  // onKeyDown = (): void => {
  //   const { onKeyDown } = this.props;
  //   if (onKeyDown) {
  //     onKeyDown();
  //   }
  // };

  preventDropOnDocument = (): void => {
    const { preventDropOnDocument } = this.props;
    if (preventDropOnDocument) {
      preventDropOnDocument();
    }
  };

  handleDeleteFile(file: UploadedFile): void {
    const files = [...this.state.files];
    this.setState({
      files: files.filter((f) => f.name !== file.name),
    });

    const { handleFileDelete } = this.props;
    if (handleFileDelete) {
      handleFileDelete(file);
    }
  }

  hadnleDeleteRejectedFile(file: UploadedFile): void {
    const rejectFiles = [...this.state.rejectFiles];
    this.setState({
      rejectFiles: rejectFiles.filter((f) => f.name !== file.name),
    });

    const { handleFileDelete } = this.props;
    if (handleFileDelete) {
      handleFileDelete(file);
    }
  }

  render() {
    const { files, rejectFiles } = this.state;
    const {
      isMulti,
      displayFiles,
      displayRejectedFiles,
      displayDropArea,
      acceptedFiles,
      dropareaText,
      draggingText,
      id,
    } = this.props;
    return (
      <div
        id={id}
        data-testid={`elmo-file-upload-${id || 'default'}`}
        className="elmo-file-upload-wrapper"
      >
        <Dropzone
          onDrop={this.onDrop}
          maxSize={this.maxSize}
          multiple={isMulti}
          accept={acceptedFiles.join(', ')}
          onDragEnter={this.onDragEnter}
          onDragLeave={this.onDragLeave}
          onDragOver={this.onDragOver}
          onFileDialogCancel={this.onFileDialogCancel}
          onDropAccepted={this.onDropAccepted}
          onDropRejected={this.onDropRejected}
        >
          {({ getRootProps, getInputProps, isDragActive, open }) => {
            return (
              <>
                {rejectFiles && displayFiles && displayRejectedFiles && (
                  <div className="elmo-fileupload-files">
                    {rejectFiles.map(
                      (file: any, i: number): ReactElement<File> => (
                        <FileReject
                          key={`reject-file-${i}`}
                          name={file.name}
                          handleDelete={this.hadnleDeleteRejectedFile.bind(
                            this,
                            file
                          )}
                        />
                      )
                    )}
                  </div>
                )}
                {files && displayFiles && (
                  <div className="elmo-fileupload-files">
                    {files.map(
                      (file: any, i: number): ReactElement<File> => (
                        <FileSuccess
                          key={`file-${i}`}
                          name={file.name}
                          handleDelete={this.handleDeleteFile.bind(this, file)}
                        />
                      )
                    )}
                  </div>
                )}

                {/* Use droparea */}
                {displayDropArea && (
                  <DropArea
                    isDragActive={isDragActive}
                    getInputProps={getInputProps}
                    getRootProps={getRootProps}
                    dropareaText={dropareaText}
                    draggingText={draggingText}
                  />
                )}
                {/* Show upload suing button only */}
                {!displayDropArea && (
                  <ButtonUpload
                    getInputProps={getInputProps}
                    getRootProps={getRootProps}
                    open={open}
                  />
                )}
              </>
            );
          }}
        </Dropzone>
      </div>
    );
  }
}

export default FileUpload;
