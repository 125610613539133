import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import {
  BOX_UNAVAILABILITY_UPDATE_FILTER_FORM,
  getFiltersForm
} from 'state/ManagerDashboard/Unavailability';
import View, { Props as ViewProps } from './view';

type StateProps = Pick<ViewProps, 'formData'>;

type DispatchProps = Pick<ViewProps, 'onChange'>;

type Props = StateProps & DispatchProps;

const CustomFiltersComponent = (props: Props) => <View {...props} />;

const mapStateToProps = (state: StoreState): StateProps => ({
  formData: getFiltersForm(state)
});

const mapDispatchToProps: DispatchProps = {
  onChange: BOX_UNAVAILABILITY_UPDATE_FILTER_FORM
};

export const CustomFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomFiltersComponent);
