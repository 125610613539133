import { createAction } from 'lib/store-utils';
import {
  ChangePagePayload,
  ChangePageSizePayload,
  CreateSelfAssignedShiftPayload,
  FetchMyTimesheetSuccessPayload,
  MyTimesheetPredefinedFilter,
  MyTimesheetSelectedFilter,
  SetErrorsPayload,
  SortPayload,
  UpdateFilterFormPayload
} from './types';
import { Timesheet, UpdateFormPayload } from '../../../type';

export const BOX_MY_TIMESHEET_FETCH_PAGE_DATA_REQUEST = createAction('My Timesheet / fetch page data');
export const BOX_MY_TIMESHEET_FETCH_MY_TIMESHEET_SUCCESS = createAction<FetchMyTimesheetSuccessPayload>('My Timesheet / fetch my timesheet success');

export const BOX_MY_TIMESHEET_CHANGE_PAGE_REQUEST = createAction<ChangePagePayload>('My Timesheet / change page');
export const BOX_MY_TIMESHEET_CHANGE_PAGE_SIZE_REQUEST = createAction<ChangePageSizePayload>('My Timesheet / change page size');
export const BOX_MY_TIMESHEET_PREDEFINED_FILTER_REQUEST = createAction<MyTimesheetPredefinedFilter>('My Timesheet / fetch predefined filter');
export const BOX_MY_TIMESHEET_CUSTOM_FILTER_REQUEST = createAction('My Timesheet / fetch custom filters');
export const BOX_MY_TIMESHEET_SORT_REQUEST = createAction<SortPayload>('My Timesheet / sort request');

export const BOX_MY_TIMESHEET_CREATE_SELF_ASSIGNED_SHIFT_REQUEST = createAction<CreateSelfAssignedShiftPayload>('My Timesheet / create self assigned shift request');

export const BOX_MY_TIMESHEET_SET_SELECTED_FILTER = createAction<MyTimesheetSelectedFilter>('My Timesheet / set selected filter');

export const BOX_MY_TIMESHEET_CUSTOM_FILTERS_MODAL_OPEN = createAction('My Timesheet / open custom filters');
export const BOX_MY_TIMESHEET_CUSTOM_FILTERS_MODAL_CLOSE = createAction('My Timesheet / close custom filters');

export const BOX_MY_TIMESHEET_UPDATE_CUSTOM_FILTERS = createAction('My Timesheet / Update custom filters');
export const BOX_MY_TIMESHEET_UPDATE_FILTER_FORM = createAction<UpdateFilterFormPayload>('My Timesheet / update filter form field');

export const BOX_MY_TIMESHEET_UPDATE_FILTER_FORM_FIELD = createAction<UpdateFormPayload>('My Timesheet / update filter form field');
export const BOX_MY_TIMESHEET_CLEAR_FILTER_FORM = createAction('My Timesheet / clear filter form');
export const BOX_MY_TIMESHEET_UPDATE_FILTER_FORM_FIELD_PERIOD = createAction<UpdateFormPayload>('My Timesheet / update filter form field period');

export const BOX_MY_TIMESHEET_SET_ERRORS = createAction<SetErrorsPayload>('My Timesheet / set errors');

export const BOX_MY_TIMESHEET_SET_HASH = createAction<string>('My Timesheet / set hash for newly created timesheet');
export const BOX_MY_TIMESHEET_UNSET_HASH = createAction('My Timesheet / unset hash');
