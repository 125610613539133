import React from 'react';
import { useActions, useSelectorWithProps } from 'hooks';
import {
  BOX_ROSTER_CLOSE_APPLY_CHANGES_MODAL,
  getIsModalOpen,
} from 'state/Roster/Roster';
import OffersModal from '../OffersModal';

function RosterOffersModal() {
  const isOpen = useSelectorWithProps(getIsModalOpen, 'offers');
  const handleCloseModal = useActions(BOX_ROSTER_CLOSE_APPLY_CHANGES_MODAL);

  return <OffersModal isOpened={isOpen} closeModal={handleCloseModal} />;
}

export default RosterOffersModal;
