import { createReducer } from 'lib/store-utils';
import { WhosWorkingReducerState } from './types';
import { defaultFilters, getDefaultState } from './state';
import * as actions from './actions';
import { cloneDeep } from 'lodash';
import moment from 'moment';

export const whosWorking = createReducer<WhosWorkingReducerState>(
  {},
  getDefaultState()
);

whosWorking.on(actions.BOX_WHOS_WORKING_DATA_REQUEST, getDefaultState);

whosWorking.on(
  actions.BOX_WHOS_WORKING_GET_LIST_SUCCESS,
  (state, workingShifts) => ({
    ...state,
    workingShifts,
  })
);

whosWorking.on(
  actions.BOX_WHOS_WORKING_TOGGLE_PERIOD_TYPE_REQUEST,
  (state): WhosWorkingReducerState => ({
    ...state,
    isFetching: true,
  })
);

whosWorking.on(
  actions.BOX_WHOS_WORKING_TOGGLE_PERIOD_TYPE_SUCCESS,
  (state, { workingShifts, period }): WhosWorkingReducerState => ({
    ...state,
    isFetching: false,
    isCustomFilter: false,
    workingShifts,
    period,
    filters: {
      ...cloneDeep(defaultFilters),
      customPeriod: period,
    },
    filtersForm: cloneDeep(defaultFilters),
  })
);

whosWorking.on(
  actions.BOX_WHOS_WORKING_TOGGLE_PERIOD_TYPE_FAILURE,
  (state, errors): WhosWorkingReducerState => ({
    ...state,
    isFetching: false,
    errors,
  })
);

whosWorking.on(actions.BOX_WHOS_WORKING_CLOCK_ON_REQUEST, (state) => ({
  ...state,
  isFetching: true,
}));

whosWorking.on(
  actions.BOX_WHOS_WORKING_CLOCK_ON_SUCCESS,
  (state, id): WhosWorkingReducerState => ({
    ...state,
    isFetching: false,
    workingShifts: state.workingShifts.filter((shift) => {
      return shift.id !== id;
    }),
  })
);

whosWorking.on(
  actions.BOX_WHOS_WORKING_CLOCK_ON_FAILURE,
  (state, errors): WhosWorkingReducerState => ({
    ...state,
    isFetching: false,
    errors,
  })
);

whosWorking.on(actions.BOX_WHOS_WORKING_CLOCK_OFF_REQUEST, (state) => ({
  ...state,
  isFetching: true,
}));

whosWorking.on(
  actions.BOX_WHOS_WORKING_CLOCK_OFF_SUCCESS,
  (state, id): WhosWorkingReducerState => ({
    ...state,
    isFetching: false,
    workingShifts: state.workingShifts.filter((shift) => {
      return shift.timesheet_id !== id;
    }),
  })
);

whosWorking.on(
  actions.BOX_WHOS_WORKING_CLOCK_OFF_FAILURE,
  (state, errors): WhosWorkingReducerState => ({
    ...state,
    isFetching: false,
    errors,
  })
);

whosWorking.on(
  actions.BOX_WHOS_WORKING_START_BREAK_REQUEST,
  (state, shiftBreak): WhosWorkingReducerState => {
    return {
      ...state,
      isFetching: true,
      workingShifts: state.workingShifts.map((shift) => {
        if (shift.timesheet_id === shiftBreak.timesheet_id) {
          let isUpdated = false;
          if (shift.breaks.length) {
            shift.breaks.forEach((br) => {
              if (!isUpdated && br.paid === shiftBreak.paid) {
                br.active = true;
                isUpdated = false;
              }
            });
          } else {
            shift.breaks.push({
              paid: shiftBreak.paid,
              active: true,
              duration: 0,
              start: moment(),
            });
          }
        }
        return shift;
      }),
    };
  }
);

whosWorking.on(
  actions.BOX_WHOS_WORKING_START_BREAK_SUCCESS,
  (state, newShift): WhosWorkingReducerState => ({
    ...state,
    isFetching: false,
    workingShifts: state.workingShifts.map((shift) => {
      return shift.id === newShift.id ? newShift : shift;
    }),
  })
);

whosWorking.on(
  actions.BOX_WHOS_WORKING_START_BREAK_FAILURE,
  (state, errors): WhosWorkingReducerState => ({
    ...state,
    errors,
  })
);

whosWorking.on(
  actions.BOX_WHOS_WORKING_STOP_BREAK_REQUEST,
  (state, id): WhosWorkingReducerState => {
    return {
      ...state,
      isFetching: true,
      workingShifts: state.workingShifts.map((shift) => {
        if (shift.timesheet_id === id) {
          shift.breaks.forEach((br) => {
            br.active = false;
          });
        }
        return shift;
      }),
    };
  }
);

whosWorking.on(
  actions.BOX_WHOS_WORKING_STOP_BREAK_SUCCESS,
  (state, newShift): WhosWorkingReducerState => ({
    ...state,
    isFetching: false,
    workingShifts: state.workingShifts.map((shift) => {
      return shift.id === newShift.id ? newShift : shift;
    }),
  })
);

whosWorking.on(
  actions.BOX_WHOS_WORKING_STOP_BREAK_FAILURE,
  (state, errors): WhosWorkingReducerState => ({
    ...state,
    isFetching: false,
    errors,
  })
);

whosWorking.on(
  actions.BOX_WHOS_WORKING_APPROVE_REQUEST,
  (state, id): WhosWorkingReducerState => ({
    ...state,
    isFetching: true,
  })
);

whosWorking.on(
  actions.BOX_WHOS_WORKING_APPROVE_SUCCESS,
  (state, id): WhosWorkingReducerState => ({
    ...state,
    isFetching: false,
    workingShifts: state.workingShifts.map((shift) => {
      if (shift.timesheet_id === id) {
        shift.is_approved = true;
      }
      return shift;
    }),
  })
);

whosWorking.on(
  actions.BOX_WHOS_WORKING_UPDATE_FORM_FILTERS,
  (state, { name, value }): WhosWorkingReducerState => ({
    ...state,
    filtersForm: {
      ...state.filtersForm,
      [name]: value,
    },
  })
);

whosWorking.on(
  actions.BOX_WHOS_WORKING_RESET_FORM_FILTERS,
  (state): WhosWorkingReducerState => ({
    ...state,
    filters: cloneDeep(defaultFilters),
    filtersForm: cloneDeep(defaultFilters),
  })
);

whosWorking.on(
  actions.BOX_WHOS_WORKING_APPLY_FORM_FILTERS_REQUEST,
  (state): WhosWorkingReducerState => ({
    ...state,
    isFetching: true,
    isCustomFilter: true,
  })
);

whosWorking.on(
  actions.BOX_WHOS_WORKING_APPLY_FORM_FILTERS_SUCCESS,
  (state, { workingShifts }): WhosWorkingReducerState => ({
    ...state,
    isFetching: false,
    workingShifts,
    filters: cloneDeep(state.filtersForm),
  })
);

whosWorking.on(
  actions.BOX_WHOS_WORKING_BREAKS_LIMIT_CONFIRMATION_OPEN,
  (state, errors): WhosWorkingReducerState => {
    return {
      ...state,
      isFetching: false,
      modal: {
        ...state.modal,
        isOpen: true,
        errors,
      },
    };
  }
);

whosWorking.on(
  actions.BOX_WHOS_WORKING_BREAKS_LIMIT_CONFIRMATION_CLOSE,
  (state): WhosWorkingReducerState => {
    return {
      ...state,
      modal: {
        ...state.modal,
        isOpen: false,
        errors: [],
      },
    };
  }
);

whosWorking.on(
  actions.BOX_WHOS_WORKING_CLEAR_ERRORS,
  (state): WhosWorkingReducerState => ({
    ...state,
    errors: [],
  })
);

whosWorking.on(actions.BOX_WHOS_WORKING_SHIFT_DELETED, (state, { id }) => ({
  ...state,
  workingShifts: state.workingShifts.filter((shift) => {
    return shift.id !== id;
  }),
}));
whosWorking.on(actions.BOX_WHOS_WORKING_TIMESHEET_DELETED, (state, { id }) => ({
  ...state,
  workingShifts: state.workingShifts.filter((shift) => {
    return shift.timesheet_id !== id;
  }),
}));

whosWorking.on(actions.BOX_WHOS_WORKING_UPDATE_SHIFT, (state, shift) => {
  const index = state.workingShifts.findIndex((item) => {
    if (item.timesheet_id) {
      return item.timesheet_id === shift.id;
    } else {
      return item.id === shift.id;
    }
  });
  const workingShifts = cloneDeep(state.workingShifts);
  if (index > -1) {
    workingShifts[index] = shift;
  } else {
    workingShifts.push(shift);
  }

  return {
    ...state,
    workingShifts,
  };
});
