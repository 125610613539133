import { watchMainActions } from './mainActions';
import { watchSocketUpdates } from './socketUpdates';
import { SagaIterator } from 'redux-saga';
import { fork } from 'redux-saga/effects';

export { getTimeOffsWithPageCheckRequest } from './mainActions';
export const watchUnavailability = function *(): SagaIterator {
  yield fork(watchMainActions);
  yield fork(watchSocketUpdates);
};
