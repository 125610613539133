import React from 'react';
import { useSelector } from 'react-redux';
import { LoadingOverlay as OverlayComponent } from 'elmo-elements';
import { getPageUpdating } from 'state/TimesheetsWeeklyPage';

type LoadingOverlayProps = {
  children: React.ReactNode;
};

export function LoadingOverlay({ children }: LoadingOverlayProps) {
  const isLoading = useSelector(getPageUpdating);

  return <OverlayComponent isLoading={isLoading}>{children}</OverlayComponent>;
}
