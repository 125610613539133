import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { MapTupleToOption } from 'type';
import { Select } from 'elmo-elements';
import {
  BOX_COVID_TRACING_MODE_SET_PERIOD,
  BOX_COVID_TRACING_MODE_UNSET_SHIFT,
  CovidTracingPeriodTuple,
  getCovidPeriod,
} from 'state/Roster/CovidTracing';

const styles = () =>
  createStyles({
    root: {
      minWidth: 156,
      marginBottom: 0,
    },
  });

type Props = WithStyles<typeof styles> & {
  className?: string;
};

type PeriodOptions = MapTupleToOption<CovidTracingPeriodTuple>;
type PeriodOption = PeriodOptions[number];

const options: PeriodOptions = [
  {
    label: 'Last 7 days',
    value: '7',
  },
  {
    label: 'Last 14 days',
    value: '14',
  },
];

export const PeriodSelect = withStyles(styles)(
  ({ classes, className }: Props) => {
    const period = useSelector(getCovidPeriod);
    const selectedOption = options.find((option) => option.value === period);
    const dispatch = useDispatch();

    const handleChange = useCallback(
      ({ value }: PeriodOption) => {
        dispatch(BOX_COVID_TRACING_MODE_SET_PERIOD(value));
        dispatch(BOX_COVID_TRACING_MODE_UNSET_SHIFT());
      },
      [dispatch]
    );

    return (
      <Select
        className={clsx(classes.root, className)}
        value={selectedOption}
        options={options}
        onChange={handleChange}
        isInline={false}
      />
    );
  }
);
