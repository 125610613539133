import { useConnectActionMemoized } from 'hooks';
import { SERVER_DAY_FORMAT } from 'lib/config';
import React from 'react';
import { useSelector } from 'react-redux';
import { BOX_SOCKETS_TIMESHEETS_DAILY } from 'state/Sockets';
import {
  fromBySiteTimezoneSelector,
  getSiteId,
} from 'state/TimesheetsCollection';

export function TimesheetsSockets() {
  const site_id = useSelector(getSiteId);
  const from = useSelector(fromBySiteTimezoneSelector);
  const day = from.format(SERVER_DAY_FORMAT);

  useConnectActionMemoized(
    BOX_SOCKETS_TIMESHEETS_DAILY,
    () => ({ site_id, day }),
    [site_id, day]
  );

  return null;
}
