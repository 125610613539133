import React from 'react';
import { NativeTableElement } from '../type';
import { getClass } from '../../_lib/helper';
import './NativeTableHeader.scss';

type NativeTableHeaderProps = NativeTableElement & {};

function NativeTableHeader({ children, className }: NativeTableHeaderProps) {
  return (
    <thead className={getClass('elmo-nativetable-head', className)}>
      {children}
    </thead>
  );
}

export default NativeTableHeader;
