import React, {
  Children,
  cloneElement,
  Component,
  ReactChild,
  ReactElement,
} from 'react';
import {
  RadioButtonsDirections,
  RadioButtonsProps,
  RadioButtonsTypes,
} from './type';
import { getClass, isElementOfType, uniqueId } from '../_lib/helper';
import Radio, { RadioProps } from '../Radio';
import './RadioButtons.scss';

type RadioButtonsState = {
  selectedValue: string | number | null;
};

class RadioButtons extends Component<RadioButtonsProps, RadioButtonsState> {
  protected radioInputName: string = uniqueId('elmo-radio-buttons');

  constructor(props: RadioButtonsProps) {
    super(props);

    this.state = {
      selectedValue: props.selected,
    };
  }

  componentDidUpdate(prevProps: RadioButtonsProps) {
    if (this.props.selected !== prevProps.selected) {
      this.setState({
        selectedValue: this.props.selected,
      });
    }
  }

  onRadioButtonClick = (value: string | number) => {
    this.setState({
      selectedValue: value,
    });

    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  isSelected(value: string | number): boolean {
    return this.state.selectedValue === value;
  }

  renderRadioButtons() {
    const { id } = this.props;

    return Children.map(
      this.props.children as ReactElement<any>,
      (child: ReactChild, index: number) => {
        if (!isElementOfType(child, Radio)) {
          return null;
        }

        const radioChild = child as unknown as ReactElement<RadioProps>;
        let childId: string | undefined;
        if (id) {
          childId = `${id}-${index}`;
        }
        return cloneElement(radioChild, {
          onChange: this.onRadioButtonClick,
          checked: this.isSelected(radioChild.props.value),
          disabled: this.props.disabled || radioChild.props.disabled,
          id: childId,
          name: this.radioInputName,
        });
      }
    );
  }

  render() {
    const { id, className, type, direction, ariaLabelledBy } = this.props;
    let classes = getClass(
      'elmo-radio-buttons',
      [className, 'row flex-inline no-gutters'],
      {
        col: direction === RadioButtonsDirections.vertical,
        solid: type === RadioButtonsTypes.solid,
        outline: type === RadioButtonsTypes.outlined,
        grouped: type === RadioButtonsTypes.grouped,
      }
    );

    return (
      <div
        id={id}
        className={classes}
        role="radiogroup"
        aria-labelledby={ariaLabelledBy}
        data-testid={`elmo-radio-bar-${id || 'default'}`}
      >
        {this.renderRadioButtons()}
      </div>
    );
  }
}

export default RadioButtons;
