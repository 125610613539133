import { HelpOutlineIcon } from 'element';
import {
  Box,
  Button,
  IconButton,
  Popover,
  Typography,
} from 'extended-oxygen-elements';
import { bindPopover, bindTrigger, usePopupState } from 'hooks';
import marketLabels from 'marketLabels';
import React from 'react';

export default function IconButtonSnapToRoster() {
  const popupState = usePopupState();

  const handleCloseBtnClick = () => {
    popupState.close();
  };

  return (
    <>
      <IconButton {...bindTrigger(popupState)}>
        <HelpOutlineIcon />
      </IconButton>

      <Popover {...bindPopover(popupState)}>
        <Box p={3} maxWidth={540}>
          <Typography variant="h6">Snap to {marketLabels.roster}</Typography>

          <Typography>
            When the start or finish time of a shift captured from the Time
            Clock is within the defined number of minutes, the timesheet time
            will be automatically adjusted to the scheduled start or finish
            time.
          </Typography>

          <br />

          <Typography variant="h6">
            Example: Snap to {marketLabels.roster} = 5 minutes
          </Typography>

          <Typography>
            The shift start time is 9 AM and the employee clocks on at 8:56 AM,
            so the timesheet start time is automatically rounded to 9 AM.
          </Typography>

          <Box display="flex" justifyContent="flex-end" pt={3}>
            <Button variant="contained" onClick={handleCloseBtnClick}>
              Close
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
}
