import { createReducer } from 'lib/store-utils';
import { ShiftTradesReducerState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';
import { cloneDeep, filter, chunk } from 'lodash';

export const shiftTrades = createReducer<ShiftTradesReducerState>(
  {},
  getDefaultState()
);

const getCurrentPageNumber = (data: any, currentPage: number, size: number) => {
  let curPage = currentPage;
  const pages = chunk(data, size);
  if (
    pages[curPage - 1] &&
    pages[curPage - 1].length === 0 &&
    curPage - 1 > 0
  ) {
    curPage -= 1;
  }
  if (!pages[curPage - 1] && curPage - 1 > 0) {
    curPage -= 1;
  }
  return curPage;
};

shiftTrades.on(actions.BOX_SHIFT_TRADES_GET_DATA, getDefaultState);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_GET_DATA_SUCCESS,
  (state, results): ShiftTradesReducerState => ({
    ...state,
    shifts: results,
    errors: [],
    pager: {
      ...state.pager,
      currentPage: 1
    },
    isTabChangedFromCustomFilters: false
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_GET_DATA_FAILURE,
  (state, errors): ShiftTradesReducerState => ({
    ...state,
    errors: errors
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_TOGGLE_TRADES_TYPE_REQUEST,
  (state, type): ShiftTradesReducerState => ({
    ...state,
    selectedFilter: type,
    isTabChangedFromCustomFilters: false,
    pager: {
      ...state.pager,
      currentPage: 1
    },
    filtersForm: {
      ...getDefaultState().filtersForm,
      selectedFilter: type
    },
    filters: {
      ...getDefaultState().filters,
      selectedFilter: type
    },
    errors: []
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_SWAP_MODAL_CLOSE,
  (state): ShiftTradesReducerState => ({
    ...state,
    swapModal: {
      ...state.swapModal,
      isOpened: false,
      isLoading: false,
      tradeId: null,
      proposals: [],
      errors: []
    }
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_SWAP_MODAL_OPEN,
  (state, tradeId): ShiftTradesReducerState => ({
    ...state,
    swapModal: {
      ...state.swapModal,
      isOpened: true,
      isLoading: true,
      tradeId: tradeId,
      errors: []
    }
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_GET_SWAP_PROPOSALS,
  (state, params): ShiftTradesReducerState => ({
    ...state,
    swapModal: {
      ...state.swapModal,
      isLoading: true
    }
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_GET_SWAP_PROPOSALS_SUCCESS,
  (state, response): ShiftTradesReducerState => ({
    ...state,
    swapModal: {
      ...state.swapModal,
      isLoading: false,
      proposals: response
    }
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_GET_SWAP_PROPOSALS_FAILURE,
  (state, errors): ShiftTradesReducerState => ({
    ...state,
    swapModal: {
      ...state.swapModal,
      isLoading: false,
      errors: errors
    }
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_CHANGE_PAGE,
  (state, currentPage): ShiftTradesReducerState => ({
    ...state,
    errors: [],
    pager: {
      ...state.pager,
      currentPage
    }
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_CHANGE_PAGE_SIZE,
  (state, pageSize): ShiftTradesReducerState => ({
    ...state,
    errors: [],
    pager: {
      ...state.pager,
      currentPage: 1,
      pageSize
    }
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_UPDATE_FORM_FILTERS,
  (state, { name, value }): ShiftTradesReducerState => ({
    ...state,
    filtersForm: {
      ...state.filtersForm,
      [name]: value
    }
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_TAB_CHANGED_FROM_CUSTOM_FILTERS,
  (state): ShiftTradesReducerState => ({
    ...state,
    isTabChangedFromCustomFilters: true
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_UPDATE_CUSTOM_FILTERS,
  (state): ShiftTradesReducerState => ({
    ...state,
    filters: cloneDeep(state.filtersForm),
    selectedFilter: 'custom',
    pager: getDefaultState().pager
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_CLEAR_FILTER_FORM,
  (state): ShiftTradesReducerState => ({
    ...state,
    filtersForm: cloneDeep(state.filters),
    isTabChangedFromCustomFilters: false,
    errors: []
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_REMOVE_ERRORS,
  (state): ShiftTradesReducerState => ({
    ...state,
    errors: []
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_ACCEPT_SHIFT_REQUEST,
  (state, { shift_trade_id }): ShiftTradesReducerState => ({
    ...state,
    isAccepting: shift_trade_id,
    changedShiftTradeId: shift_trade_id,
    errors: []
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_ACCEPT_SHIFT_SUCCESS,
  (state, tradedId): ShiftTradesReducerState => {
    const filteredShifts = filter(state.shifts, shift => shift.id !== tradedId);
    return {
      ...state,
      isAccepting: '',
      shifts: filteredShifts,
      pager: {
        ...state.pager,
        currentPage: getCurrentPageNumber(
          filteredShifts,
          state.pager.currentPage,
          state.pager.pageSize
        )
      },
      errors: []
    };
  }
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_ACCEPT_SHIFT_FAILURE,
  (state, errors): ShiftTradesReducerState => ({
    ...state,
    isAccepting: '',
    changedShiftTradeId: null,
    errors: errors
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_SWAP_REQUEST,
  (state, { trade_id }): ShiftTradesReducerState => ({
    ...state,
    changedShiftTradeId: trade_id,
    swapModal: {
      ...state.swapModal,
      isUpdating: true,
      errors: []
    }
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_SWAP_SUCCESS,
  (state, tradeId): ShiftTradesReducerState => {
    const filteredShifts = filter(state.shifts, shift => shift.id !== tradeId);
    return {
      ...state,
      swapModal: {
        ...state.swapModal,
        isUpdating: false,
        errors: []
      },
      shifts: filteredShifts,
      pager: {
        ...state.pager,
        currentPage: getCurrentPageNumber(
          filteredShifts,
          state.pager.currentPage,
          state.pager.pageSize
        )
      }
    };
  }
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_SWAP_FAILURE,
  (state, errors): ShiftTradesReducerState => ({
    ...state,
    changedShiftTradeId: null,
    swapModal: {
      ...state.swapModal,
      isUpdating: false,
      errors: errors
    }
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_REMOVE_SWAP_MODAL_ERRORS,
  (state): ShiftTradesReducerState => ({
    ...state,
    swapModal: {
      ...state.swapModal,
      errors: []
    }
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_DELETE_SHIFT,
  (state, tradedId): ShiftTradesReducerState => ({
    ...state,
    changedShiftTradeId: tradedId,
    isDeleting: true,
    errors: []
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_DELETE_SHIFT_SUCCESS,
  (state, tradedId): ShiftTradesReducerState => {
    const filteredShifts = filter(state.shifts, shift => shift.id !== tradedId);
    return {
      ...state,
      shifts: filteredShifts,
      pager: {
        ...state.pager,
        currentPage: getCurrentPageNumber(
          filteredShifts,
          state.pager.currentPage,
          state.pager.pageSize
        )
      },
      isDeleting: false,
      errors: []
    };
  }
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_DELETE_SHIFT_FAILURE,
  (state, errors): ShiftTradesReducerState => ({
    ...state,
    changedShiftTradeId: null,
    errors: errors,
    isDeleting: false
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_SET_SHIFT_TO_SWAP,
  (state, offeredShiftToSwap): ShiftTradesReducerState => ({
    ...state,
    currentShiftId: offeredShiftToSwap.id,
    offeredShiftToSwap: offeredShiftToSwap
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_SET_CURRENT_SHIFT_ID,
  (state, id): ShiftTradesReducerState => ({
    ...state,
    currentShiftId: id,
    offeredShiftToSwap: id === null ? null : state.offeredShiftToSwap
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_RESET_CURRENT_SHIFT,
  (state, id): ShiftTradesReducerState => ({
    ...state,
    currentShiftId: null,
    offeredShiftToSwap: null,
    errors: []
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_GET_SHIFT_PROPOSALS_SUCCESS,
  (state, response): ShiftTradesReducerState => ({
    ...state,
    offeredShiftToSwap: response,
    errors: []
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_GET_SHIFT_PROPOSALS_FAILURE,
  (state, errors): ShiftTradesReducerState => ({
    ...state,
    errors: errors
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_SELECT_PROPOSAL,
  (state, id): ShiftTradesReducerState => ({
    ...state,
    isSelecting: id,
    changedShiftTradeId: state.currentShiftId
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_SELECT_PROPOSAL_SUCCESS,
  (state, response): ShiftTradesReducerState => ({
    ...state,
    isSelecting: '',
    offeredShiftToSwap: response
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_SELECT_PROPOSAL_FAILURE,
  (state, errors): ShiftTradesReducerState => ({
    ...state,
    isSelecting: '',
    errors: errors,
    changedShiftTradeId: null
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_DECLINE_PROPOSAL,
  (state, id): ShiftTradesReducerState => ({
    ...state,
    isDeclining: id,
    changedShiftTradeId: state.currentShiftId
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_DECLINE_PROPOSAL_SUCCESS,
  (state, response): ShiftTradesReducerState => ({
    ...state,
    isDeclining: '',
    offeredShiftToSwap: response
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_DECLINE_PROPOSAL_FAILURE,
  (state, errors): ShiftTradesReducerState => ({
    ...state,
    isDeclining: '',
    errors: errors,
    changedShiftTradeId: null
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_CHANGE_SORT,
  (state, params): ShiftTradesReducerState => ({
    ...state,
    filters: {
      ...state.filtersForm,
      sort: {
        direction: params.direction,
        column: params.column
      }
    }
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_DECLINE_SHIFT_BY_PROPOSAL,
  (state, params): ShiftTradesReducerState => ({
    ...state,
    changedShiftTradeId: params.trade_id,
    isDeclining: params.proposal_id,
    errors: []
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_DECLINE_SHIFT_BY_PROPOSAL_SUCCESS,
  (state, tradeId): ShiftTradesReducerState => ({
    ...state,
    isDeclining: '',
    shifts: filter(state.shifts, shift => shift.id !== tradeId)
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_DECLINE_SHIFT_BY_PROPOSAL_FAILURE,
  (state, errors): ShiftTradesReducerState => ({
    ...state,
    changedShiftTradeId: null,
    isDeclining: '',
    errors: errors
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_CONFIRMATION_MODAL_OPEN,
  (state, message): ShiftTradesReducerState => ({
    ...state,
    confirmationModal: {
      ...state.confirmationModal,
      isOpened: true,
      errors: message
    }
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_CONFIRMATION_MODAL_CLOSE,
  (state, isUpdating): ShiftTradesReducerState => ({
    ...state,
    swapModal: {
      ...state.swapModal,
      isUpdating: isUpdating
    },
    confirmationModal: {
      ...state.confirmationModal,
      isOpened: false,
      errors: []
    }
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_SET_OUTDATED,
  (state, { page, value }): ShiftTradesReducerState => ({
    ...state,
    isOutdated: {
      ...state.isOutdated,
      [page]: value
    }
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_UPDATE_REQUEST,
  (state): ShiftTradesReducerState => ({
    ...state,
    errors: []
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_UPDATE_SUCCESS,
  (state, shifts): ShiftTradesReducerState => ({
    ...state,
    shifts,
    pager: {
      ...state.pager,
      currentPage: 1
    }
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_UPDATE_FAILURE,
  (state, errors): ShiftTradesReducerState => ({
    ...state,
    errors
  })
);

shiftTrades.on(
  actions.BOX_SHIFT_TRADES_SOCKET_UPDATE_CANCELLED,
  (state): ShiftTradesReducerState => ({
    ...state,
    changedShiftTradeId: null
  })
);
