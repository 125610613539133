import React from 'react';
import { LayoutBackgroundVariant, useLayoutContext } from './layout-context';

type Props = {
  background?: LayoutBackgroundVariant;
  disableScroll?: boolean;
};

export default function LayoutStyling({ background, disableScroll }: Props) {
  const {
    setBackground,
    unsetBackground,
    setDisableScroll,
    unsetDisableScroll,
  } = useLayoutContext();

  React.useEffect(() => {
    if (background) {
      setBackground(background);
    }

    return () => {
      unsetBackground();
    };
  }, [setBackground, background, unsetBackground]);

  React.useEffect(() => {
    if (disableScroll) {
      setDisableScroll(disableScroll);
    }

    return () => {
      unsetDisableScroll();
    };
  }, [disableScroll, setDisableScroll, unsetDisableScroll]);

  return null;
}
