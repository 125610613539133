import { Dialog } from 'extended-oxygen-elements';
import { useActions, useNonNullableStaticState } from 'hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  BOX_CREATE_TIMESHEET_DIALOG_CLOSED,
  getCreateTimesheetParams,
} from 'state/CreateTimesheetDialog';
import { getDialogUpdating } from 'state/TimesheetsWeeklyPage';
import CreateDialogForm from './CreateDialogForm';

export default CreateDialog;

export function CreateDialog() {
  const dialogClosed = useActions(BOX_CREATE_TIMESHEET_DIALOG_CLOSED);
  const loading = useSelector(getDialogUpdating);
  const createStateParams = useSelector(getCreateTimesheetParams);
  const createParams = useNonNullableStaticState(createStateParams);

  return (
    <Dialog
      open={!!(createStateParams && createParams)}
      onClose={dialogClosed}
      loading={loading}
    >
      {createParams && <CreateDialogForm params={createParams} />}
    </Dialog>
  );
}
