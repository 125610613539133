import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import {
  getCreatedShiftData,
  RosterDayViewCreatedShiftData,
  RosterDayViewCreatedShiftType
} from 'state/Roster/RosterDayView';
import { RosterCellType } from '../../../../../../../components';
import { DayCreatedShift } from '../../../../../components';

type OwnProps = {
  cellType: RosterCellType;
  shiftType: RosterDayViewCreatedShiftType;
  areaId: string;
  roleId: string;
  rowIndex: number;
};

type StateProps = {
  createdShiftData: RosterDayViewCreatedShiftData;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

const DaySiteCreatedShiftComponent = ({
  cellType,
  shiftType,
  createdShiftData,
  rowIndex,
  areaId,
  roleId
}: Props) => {
  const {
    shift,
    pairIndex,
    type: createdShiftType,
    clientX
  } = createdShiftData;

  if (
    !shift ||
    areaId !== shift.area_id ||
    roleId !== shift.role_id ||
    shiftType !== createdShiftType ||
    pairIndex !== rowIndex
  ) {
    return null;
  }

  return (
    <DayCreatedShift
      shift={shift}
      cellType={cellType}
      clientX={clientX}
    />
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  createdShiftData: getCreatedShiftData(state)
});

const mapDispatchToProps: DispatchProps = {};

export const DaySiteCreatedShift = connect(
  mapStateToProps,
  mapDispatchToProps
)(DaySiteCreatedShiftComponent);
