import React, { Component } from 'react';
import { FormItem, Radio, RadioGroup, Select } from 'elmo-elements';
import {
  AccountTreeRole,
  LanguagePreferences,
  StringMap,
  UpdateFormPayload,
} from 'type';
import { capitalize, keyMirror } from 'lib/helpers';
import { SortDirectionRadioButtons, SortFieldRadioButtons } from 'element';
import { WorkingFilters } from 'state/ManagerDashboard/WhosWorking/types';

export type Props = {
  formData: WorkingFilters;
  onChange: (payload: UpdateFormPayload) => void;
  dateFormat: string;
  roles: StringMap<AccountTreeRole>;
  langPreferences: LanguagePreferences;
};

export default class CustomFiltersView extends Component<Props> {
  readonly inputNames = keyMirror(this.props.formData);

  sortFields: {
    label: string;
    value: string;
  }[] = [];

  statusFields = [
    {
      value: 'now',
      label: 'Working now'
    },
    {
      value: 'soon',
      label: 'Starting soon'
    },
    {
      value: 'just-finished',
      label: 'Just finished'
    },
    {
      value: 'tomorrow',
      label: 'Tomorrow'
    }
  ];

  breaksOptions: {
    label: string;
    value: string;
  }[] = [];

  readonly options: {
    label: string;
    value: string;
  }[] = Object.keys(this.props.roles).map(key => {
    return {
      value: this.props.roles[key].id,
      label: this.props.roles[key].name
    };
  });

  render() {
    const {
      props: { formData, langPreferences }
    } = this;

    this.getBreaksOptions();

    this.getSortFields();

    return (
      <form>
        <FormItem
          label="Status"
          isDivider={true}
          id="working-filters-status"
        >
          <RadioGroup
            selected={formData.customPeriod}
            onChange={value => {
              this.onChangeRadio('customPeriod', value as string);
            }}
            direction="vertical"
          >
            {this.statusFields.map((option, key) => {
              return (
                <Radio value={option.value} key={key}>
                  {option.label}
                </Radio>
              );
            })}
          </RadioGroup>
        </FormItem>

        {this.breaksOptions.length ? (
          <FormItem
            label="Shifts"
            isDivider={true}
            id="working-filters-shifts"
          >
            <RadioGroup
              selected={formData.breaks}
              onChange={value => {
                this.onChangeRadio('breaks', value as string);
              }}
              direction="vertical"
            >
              {this.breaksOptions.map((option, key) => {
                return (
                  <Radio value={option.value} key={key}>
                    {option.label}
                  </Radio>
                );
              })}
            </RadioGroup>
          </FormItem>
        ) : (
          <></>
        )}

        <FormItem label={capitalize(langPreferences.role.singular)}>
          <Select
            options={this.options}
            defaultValue={this.getSelectedPosition()}
            onChange={this.onChangePosition}
            name="testing name attr"
            isClearable={true}
            id="working-filters-position"
            ariaLabel="Position"
          />
        </FormItem>

        <FormItem
          label="Sort by"
          id="working-filters-sort-by"
        >
          <SortFieldRadioButtons
            selected={formData.sort.column}
            onChange={this.onChangeSortField}
            sortFields={this.sortFields}
          />
        </FormItem>

        <FormItem id="working-filters-sort-direction">
          <SortDirectionRadioButtons
            selected={formData.sort.direction}
            onChange={this.onChangeSortDirection}
          />
        </FormItem>
      </form>
    );
  }

  getBreaksOptions = () => {
    const {
      props: { formData }
    } = this;

    this.breaksOptions = [];

    if (
      formData.customPeriod === 'now' ||
      formData.customPeriod === 'just-finished'
    ) {
      this.breaksOptions.push({
        label: 'All shifts',
        value: 'all'
      });
      this.breaksOptions.push({
        label: 'Shifts without breaks',
        value: 'without'
      });
    }
    if (formData.customPeriod === 'now') {
      this.breaksOptions.push({
        label: 'Staff on break',
        value: 'on_break'
      });
    }
  };

  getSortFields = () => {
    const {
      props: { formData }
    } = this;

    this.sortFields = [
      {
        label: 'Name',
        value: 'name'
      }
    ];

    if (formData.customPeriod === 'tomorrow') {
      this.sortFields.push({
        label: 'Start date',
        value: 'start'
      });
    }
    if (formData.customPeriod === 'just-finished') {
      this.sortFields.push({
        label: 'Finish date',
        value: 'finish'
      });
    }

    if (
      formData.customPeriod === 'now' ||
      formData.customPeriod === 'just-finished'
    ) {
      this.sortFields.push({
        label: 'Hours',
        value: 'hours'
      });
    }
  };

  onChangeRadio = (name: string, value: string) => {
    this.props.onChange({
      name,
      value
    });
  };

  onChangeSortField = (column: string | number) => {
    this.props.onChange({
      name: this.inputNames.sort,
      value: {
        column,
        direction: this.props.formData.sort.direction
      }
    });
  };

  onChangeSortDirection = (direction: string | number) => {
    this.props.onChange({
      name: this.inputNames.sort,
      value: {
        column: this.props.formData.sort.column,
        direction
      }
    });
  };

  getSelectedPosition = () => {
    const { formData, roles } = this.props;
    if (formData.position) {
      return {
        value: formData.position,
        label: roles[formData.position].name
      };
    }
    return { value: '', label: '' };
  };

  onChangePosition = (data: { label: string; value: string } | null) => {
    const value = data ? data.value : null;
    const name = 'position';
    this.props.onChange({
      name,
      value
    });
  };
}
