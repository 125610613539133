import React from 'react';
import { LoadingOverlay, Pagination } from 'elmo-elements';
import { PAGE_SIZE } from 'lib/config';
import { WithPagerSelector } from 'type';

type Props<T> = {
  data: WithPagerSelector<T>;
  changePage: (pageNumber: number) => void;
  changePageSize: (pageSize: number) => void;
  isUpdating?: boolean;
};

export const Pager = <T extends object>({
  data,
  isUpdating = false,
  changePage,
  changePageSize
}: Props<T>) => {
  return data.total ? (
    <LoadingOverlay isLoading={isUpdating} showSpinner={false}>
      <Pagination
        pageSize={data.pageSize}
        pageSizeOptions={PAGE_SIZE}
        currentPage={data.currentPage}
        totalResults={data.total}
        onPageChange={changePage}
        onPageSizeChange={changePageSize}
      />
    </LoadingOverlay>
  ) : null;
};

export default Pager;
