import React from 'react';
import { Redirect as RouterRedirect, RedirectProps } from 'react-router-dom';
import { Milliseconds } from 'type';
import { CenteredCircularProgress } from '../CenteredCircularProgress';

type DelayedRedirectProps = RedirectProps & {
  delayed?: boolean;
  delay?: Milliseconds;
};

/**
 * Feature flags initial state is empty
 * This Component extends functionality of the React router Redirect component
 * additional delay with preloader is implemented in order to redirect once
 * the flags are received if the expected flag has disabled state.
 *
 * Haven't found another way to determine whether flags are received or not
 * */
export function Redirect({
  delayed = false,
  delay = 1000,
  ...restProps
}: DelayedRedirectProps) {
  const [loading, setLoading] = React.useState(delayed);

  React.useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (delayed) {
      timeoutId = setTimeout(() => {
        setLoading(false)
      }, delay);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [delayed]);

  if (loading) {
    return (
      <CenteredCircularProgress />
    );
  }

  return <RouterRedirect {...restProps} />;
}
