import { PickersDay, StaticDatePicker } from '@mui/x-date-pickers';
import { Box, TextField } from 'extended-oxygen-elements';
import moment, { Moment } from 'moment';
import React, { ChangeEvent, Component, KeyboardEvent } from 'react';
import { PopoverBase } from '../_lib/component';
import { getClass, isMobileDevice } from '../_lib/helper';
import { CancelIcon as ClearIcon } from '../icons';
import Input from '../Input';
import './Datepicker.scss';
import { DatepickerProps, DEFAULT_DATE_FORMAT } from './type';

type DatepickerState = {
  /** The open state of the datepicker */
  isOpen: boolean;
  /** The currently selected date */
  selectedDate: moment.Moment | undefined;
  /** The string of the currently selected date */
  selectedDateString: string;
  isMobile: boolean;
};

/**
 * Datepicker Component
 * Wraps the rc-calendar library.
 */
class Datepicker extends Component<DatepickerProps, DatepickerState> {
  static defaultProps = {
    format: DEFAULT_DATE_FORMAT,
    isInline: true,
  };

  calendarRef = React.createRef<HTMLDivElement>();
  todayBtn: HTMLElement | null = null;

  constructor(props: DatepickerProps) {
    super(props);
    let selectedDate = undefined;
    let selectedDateString = '';

    // if there is a selected date passed in already, cast it using moment.
    if (props.value) {
      ({ selectedDate, selectedDateString } = this.updateSelectedDate(
        this.dateToMoment(props.value),
        props.format
      ));
    }

    this.state = {
      isOpen: false,
      selectedDate: selectedDate,
      selectedDateString: selectedDateString,
      isMobile: isMobileDevice(),
    };
  }

  componentDidUpdate(prevProps: DatepickerProps) {
    const { value, format } = this.props;
    if (prevProps.value !== value) {
      const newDate = value ? this.dateToMoment(value) : null;
      this.setState(this.updateSelectedDate(newDate, format));
    }
  }

  dateToMoment(d?: Date | null) {
    if (!d) {
      return null;
    }
    this.setFirstDayOfWeek();
    const date = moment(d);
    return date.isValid() ? date : null;
  }

  updateSelectedDate(date: moment.Moment | null, format: string) {
    if (date === null) {
      return {
        selectedDate: undefined,
        selectedDateString: '',
      };
    }

    return {
      selectedDate: date,
      selectedDateString: date.format(format),
    };
  }

  setFirstDayOfWeek() {
    const { firstDayOfWeek } = this.props;
    moment.updateLocale('en', {
      week: {
        dow: !!firstDayOfWeek ? firstDayOfWeek : 0,
        doy: 0,
      },
    });
  }

  /**
   * Toggles the open state of datepicker
   */
  toggleDatepicker = () => {
    const { isDisabled, clickOnTodayButton } = this.props;
    if (isDisabled) {
      return;
    }
    if (!this.state.isOpen) {
      this.removeListenerFromTodayBtn();
    }
    this.setFirstDayOfWeek();
    this.setState(
      ({ isOpen }) => ({
        isOpen: !isOpen,
      }),
      () => {
        if (clickOnTodayButton && this.state.isOpen) {
          this.handleClickOnTodayButton();
        }
      }
    );
  };

  todayBtnHandler = () => {
    const { clickOnTodayButton } = this.props;
    if (clickOnTodayButton) {
      setTimeout(() => {
        clickOnTodayButton();
      }, 0);
    }
  };

  handleClickOnTodayButton = () => {
    const { current } = this.calendarRef;
    this.todayBtn = current
      ? current.querySelector('.rc-calendar-today-btn')
      : null;

    if (this.todayBtn) {
      this.todayBtn.addEventListener('click', this.todayBtnHandler);
    }
  };

  removeListenerFromTodayBtn = () => {
    if (this.todayBtn) {
      this.todayBtn.removeEventListener('click', this.todayBtnHandler);
      this.todayBtn = null;
    }
  };

  /**
   * Called when date selected
   * @param date
   */
  onDateSelect = (date: moment.Moment | null) => {
    const { onChange, name, format } = this.props;

    let nextDate = date;
    // if the back/forward button is clicked, rc-calendar selects the date
    // without checking if it is disabled. Check if the date is disabled, if it is, don't update anything
    const isDateDisabled = this.disabledDate(date);
    if (isDateDisabled) {
      return;
    }

    this.setState({
      isOpen: false,
      ...this.updateSelectedDate(nextDate, format),
    });

    // convert to JS date object
    const nextDateNative = nextDate ? nextDate.toDate() : null;
    onChange(nextDateNative, name);
  };

  /**
   * Callback when the Clear button is clicked
   */
  clear = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    this.clearDate();
  };

  clearDate = () => {
    const { onChange, name } = this.props;
    this.setState(this.updateSelectedDate(null, ''));
    onChange(null, name);
  };

  setIsOpen = (isOpen: boolean) => {
    if (!isOpen) {
      this.removeListenerFromTodayBtn();
    }
    this.setState({
      isOpen: isOpen,
    });
  };

  onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { onChange, name, format, disabledDate } = this.props;
    const val = e.target.value;

    if (val.trim().length === 0) {
      this.clearDate();
      return;
    }

    const date = moment(val, format, true);
    const nextState: any = {
      selectedDateString: val,
    };

    const dateIsDisabled = disabledDate && disabledDate(date.toDate());

    if (date.isValid() && !dateIsDisabled) {
      nextState.selectedDate = date;
      // convert to JS date object
      onChange(date.toDate(), name);
    }

    this.setState(nextState);
  };

  disabledDate = (d?: Moment | null) => {
    const { disabledDate } = this.props;
    const date = d ? d.toDate() : undefined;
    return disabledDate ? disabledDate(date) : false;
  };

  /**
   * When keyup events occur within the component
   * @param e
   */
  onKeyUp = (e: KeyboardEvent) => {
    const { isOpen } = this.state;
    if (!isOpen && e.key === 'Enter') {
      this.setIsOpen(true);
      return;
    } else if (isOpen && e.key === 'Escape') {
      this.setIsOpen(false);
    }
  };

  render() {
    const {
      placeholder,
      id,
      className,
      isClearable = true,
      isDisabled,
      name,
      format,
      isInline,
      isReadOnly,
      ariaLabel,
    } = this.props;
    const { selectedDateString, selectedDate, isOpen, isMobile } = this.state;

    return (
      <div onKeyUp={this.onKeyUp}>
        <PopoverBase
          id={id}
          testId={`elmo-datepicker-${id || 'default'}`}
          className={getClass('elmo-datepicker', className)}
          isOpen={isOpen}
          setIsOpen={this.setIsOpen}
          position="bottom-start"
          mode="click"
          isInline={isInline}
          tabIndex={-1}
        >
          <PopoverBase.Target className="elmo-datepicker__input-wrapper">
            <Input
              name={name}
              value={selectedDateString}
              onChange={this.onChange}
              isDisabled={isDisabled}
              placeholder={placeholder}
              onClick={this.toggleDatepicker}
              isReadOnly={isReadOnly || isMobile}
              ariaLabel={ariaLabel}
            />
            {isClearable && !isDisabled && selectedDate && (
              <button
                type="button"
                className="btn-clear"
                onClick={this.clear}
                aria-label="Clear"
              >
                <ClearIcon />
              </button>
            )}
          </PopoverBase.Target>
          <PopoverBase.Content className="elmo-datepicker-calendar">
            <div ref={this.calendarRef}>
              <Box boxShadow={3}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  value={selectedDate}
                  onAccept={this.onDateSelect}
                  onChange={(e) => true}
                  renderInput={(params) => <TextField {...params} />}
                  renderDay={(day, selectedDays, pickersDayProps) => {
                    const formattedDay = moment(pickersDayProps.day).format(
                      'MMM D, YYYY'
                    );
                    return (
                      <PickersDay
                        {...pickersDayProps}
                        aria-label={formattedDay}
                      />
                    );
                  }}
                />
              </Box>
            </div>
          </PopoverBase.Content>
        </PopoverBase>
      </div>
    );
  }
}

export default Datepicker;
