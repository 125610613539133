import { Moment } from 'moment';
import React from 'react';
import { BOX_SOCKETS_ROSTER_WEEK } from 'state/Sockets';
import { useConnectActionMemoized } from '../hooks';

export type SocketRosterWeekConnectionProps = {
  site_id: string;
  from: Moment;
};

export default SocketRosterWeekConnection;

export function SocketRosterWeekConnection({
  site_id,
  from,
}: SocketRosterWeekConnectionProps) {
  const day = from.format();

  useConnectActionMemoized(BOX_SOCKETS_ROSTER_WEEK, () => ({ site_id, day }), [
    site_id,
    day,
  ]);

  return null;
}
