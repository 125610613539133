import React  from 'react';
import { Box, Checkbox } from 'extended-oxygen-elements';
import { createStyles, makeStyles } from '@mui/styles';
import { Moment } from 'moment';
import { TimeField } from './TimeField';
import { DurationInput, DeleteForeverOutlinedIcon } from 'element';
import { TimesheetBreak } from 'type';
import { IconButton } from 'extended-oxygen-elements';

const useBreaksStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '&:first-child': {
        paddingTop: '10px'
      }
    },
    input: {
      '& input': {
        padding: '3px',
        borderRadius: theme.shape.borderRadius,
        fontSize: 14,
        textAlign: 'center',
        height: 34,
        maxWidth: '50px',
        marginLeft: '12px'
      }
    }
  })
);

type Props = {
  breaks: TimesheetBreak[];
  onChangeBreak: (value: number | null | Moment | boolean, key: string, index: number) => void;
  removeBreakItem: (index: number) => void;
  canRemoveBreak: boolean; // set to false in case we don't need the Delete button
};

export const Breaks = ({ breaks, onChangeBreak, removeBreakItem, canRemoveBreak }: Props) => {
  const styles = useBreaksStyles();
  return (
    <Box>
      {
        breaks.map((breakItem, index) => (
          <Box key={index} className={styles.wrapper} id={`break-box-${index}`} title={'Break starts at'}>
            <TimeField
              value={breakItem.start}
              name="break_start"
              onChange={value => onChangeBreak(value, 'start', index)}
              placeholder={'8:00'}
              autoFocus={breaks.length === index + 1}
              testid={'break-start-wrapper'}
            />
            <Box className={styles.input} title={'Break duration'}>
              <DurationInput
                name={`duration_${index}`}
                value={breakItem.duration}
                onChangeHandler={event => onChangeBreak(parseInt(event.currentTarget.value), 'duration', index)}
              />
            </Box>
            <Checkbox
              title={'Paid break'}
              checked={breakItem.paid}
              onChange={event => onChangeBreak(event.currentTarget.checked, 'paid', index)}
            />
            {
              canRemoveBreak && <Box color={'text.disabled'}>
                <IconButton
                  data-testid={'delete-break-btn'}
                  title={'Delete break'}
                  color="inherit"
                  onClick={(e: any) => removeBreakItem(index)}
                >
                  <DeleteForeverOutlinedIcon />
                </IconButton>
              </Box>
            }
          </Box>
        ))
      }
    </Box>
  );
};
