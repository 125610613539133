import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import { isCurrentDaySelector } from 'state/Roster/RosterDayView';

type OwnProps = {
  children: ReactNode;
};

type StateProps = {
  isCurrentDay: boolean;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

const IsCurrentDayComponent = ({ isCurrentDay, children }: Props) =>
  isCurrentDay ? <>{children}</> : null;

const mapStateToProps = (state: StoreState): StateProps => ({
  isCurrentDay: isCurrentDaySelector(state)
});

// const mapDispatchToProps: DispatchProps = {};

export const IsCurrentDay = connect(mapStateToProps)(IsCurrentDayComponent);
