import React from 'react';
import { Box } from 'extended-oxygen-elements';
import { InfoOutlinedIcon, PopperBox } from 'element';
import { bindHoverPopover, bindHoverTrigger, useHoverPopupState } from 'hooks';
import { IconButton } from 'extended-oxygen-elements';

export type PopoverWrapperProps = {
  children: React.ReactNode;
};

export default PopoverWrapper;

export function PopoverWrapper({ children }: PopoverWrapperProps) {
  const hoverPopupState =
    useHoverPopupState<HTMLButtonElement>();

  return (
    <>
      <IconButton {...bindHoverTrigger(hoverPopupState)}>
        <InfoOutlinedIcon />
      </IconButton>

      <PopperBox
        {...bindHoverPopover(hoverPopupState)}
        placement="bottom"
      >
        <Box p={2}>{children}</Box>
      </PopperBox>
    </>
  );
}
