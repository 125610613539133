import React, { Component } from 'react';
import './Pagination.scss';
import PaginationRange from './component/PaginationRange';
import PaginationItem from './component/PaginationItem';
import {
  ChevronLeftIcon as PrevPageIcon,
  ChevronRightIcon as NextPageIcon,
  FirstPageIcon,
  LastPageIcon,
} from '../icons';
import PaginationDropdown from './component/PaginationDropdown';
import { PAGINATION_FIRST_PAGE } from '../_lib/const';
import { getClass } from '../_lib/helper';
import { PaginationProps } from './type';

class Pagination extends Component<PaginationProps> {
  countNumPages(totalResults: number, pageSize: number) {
    return pageSize > 0 ? Math.ceil(totalResults / pageSize) : 0;
  }

  getStartEndItem(pageNumber: number, pageSize: number, totalResults: number) {
    if (pageNumber <= 0 || pageSize <= 0 || totalResults <= 0) {
      return {
        startItem: 0,
        endItem: 0,
      };
    }

    const startItem: number = 1 + (pageNumber - 1) * pageSize;
    const endItem: number = Math.min(startItem - 1 + pageSize, totalResults);
    return {
      startItem: startItem,
      endItem: endItem,
    };
  }

  onPageChange = (pageNumber: number) => (e: React.MouseEvent<any>) => {
    e.preventDefault();

    const { pageSize, totalResults, onPageChange } = this.props;
    const pageCount = this.countNumPages(totalResults, pageSize);

    if (pageNumber > pageCount || pageNumber < PAGINATION_FIRST_PAGE) {
      return;
    }

    let { startItem, endItem } = this.getStartEndItem(
      pageNumber,
      pageSize,
      totalResults
    );

    onPageChange(pageNumber, startItem, endItem);
  };

  render() {
    const {
      pageSize,
      pageSizeOptions,
      totalResults,
      currentPage,
      hideDropdown,
      hideRangeText,
      id,
      className,
      onPageSizeChange,
    } = this.props;
    const { startItem, endItem } = this.getStartEndItem(
      currentPage,
      pageSize,
      totalResults
    );
    const pageCount = this.countNumPages(totalResults, pageSize);

    return (
      <nav
        className={getClass('elmo-pagination', className)}
        role="navigation"
        aria-label="Pagination"
        tabIndex={0}
        id={id}
        data-testid={`elmo-pagination-${id || 'default'}`}
      >
        {!hideRangeText && (
          <PaginationRange
            firstResult={startItem}
            lastResult={endItem}
            totalResults={totalResults}
          />
        )}
        {!hideDropdown && (
          <PaginationDropdown
            pageSize={pageSize}
            pageSizeOptions={pageSizeOptions}
            onPageSizeChange={onPageSizeChange}
          />
        )}
        <div className="elmo-pagination__wrapper">
          <PaginationItem
            ariaLabel="Go to First Page"
            onClick={this.onPageChange(PAGINATION_FIRST_PAGE)}
            isDisabled={currentPage === 1}
            icon={<FirstPageIcon />}
          />
          <PaginationItem
            ariaLabel="Go to Previous Page"
            onClick={this.onPageChange(currentPage - 1)}
            isDisabled={currentPage === 1}
            icon={<PrevPageIcon />}
          />
          <PaginationItem
            ariaLabel="Go to Next Page"
            onClick={this.onPageChange(currentPage + 1)}
            isDisabled={currentPage === pageCount}
            icon={<NextPageIcon />}
          />
          <PaginationItem
            ariaLabel="Go to Last Page"
            onClick={this.onPageChange(pageCount)}
            isDisabled={currentPage === pageCount}
            icon={<LastPageIcon />}
          />
        </div>
      </nav>
    );
  }
}

export default Pagination;
