import React, { MouseEventHandler } from 'react';
import Button from '../../Button';
import { BulkActionPropsAction } from '../type';
import './BulkactionButtons.scss';

export type BulkactionButtonsProps = {
  actions: Array<BulkActionPropsAction>;
  isDisabled?: boolean;
};

function BulkactionButtons({ actions, isDisabled }: BulkactionButtonsProps) {
  return (
    <div className="elmo-bulk-actions__buttons">
      {actions.map((action, index) => {
        return (
          <Button
            isText={true}
            icon={action.icon}
            key={index}
            isDisabled={isDisabled}
            onClick={action.onClick as MouseEventHandler<HTMLButtonElement>}
          >
            {action.label}
          </Button>
        );
      })}
    </div>
  );
}

export default BulkactionButtons;
