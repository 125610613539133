import { StoreState } from '../types';
import { FormattedErrors, Pager } from '../../type/models';
import { createSelector } from 'reselect';
import { AuditBackendPager, AuditFilters } from './state';

export const getIsSettingsOpened = (store: StoreState): boolean => store.auditTrail.filtersModal.isOpened;
export const getAuditDataSelector = (store: StoreState) => store.auditTrail.auditTrail;
export const getAuditPagerSelector = (store: StoreState): Pager => store.auditTrail.pager;
export const getAuditFiltersSelector = (store: StoreState): AuditFilters => store.auditTrail.filters;

export const getIsAuditFetched = (store: StoreState) => store.auditTrail.isFetched;
export const getIsAuditFetching = (store: StoreState) => store.auditTrail.isFetching;

export const getAuditErrors = (store: StoreState): FormattedErrors => store.auditTrail.errors;

export const getAuditFiltersPagesSelector = createSelector<StoreState, Pager, AuditFilters, AuditBackendPager>
(getAuditPagerSelector, getAuditFiltersSelector, (pager, filters) => {
    return {
      ...filters,
      page: pager.currentPage,
      per_page: pager.pageSize
    };
});
