import React, { ReactNode } from 'react';
import { State } from '../../page/EmployeeDashboard/page/MyRosters/components/MyRostersList/types';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from 'extended-oxygen-elements';
import { PageDialog, PageDialogSubmit } from 'element/index';

type Props = {
  isOpened: boolean;
  onClose: () => void;
  children: ReactNode;
  contentTestId?: string;
  modalTestId?: string;
};

export class ErrorsModal extends React.Component<Props, State> {
  render() {
    const { isOpened, modalTestId, contentTestId, onClose, children } =
      this.props;
    return (
      <PageDialog
        maxWidth={'xs'}
        open={isOpened}
        data-testid={modalTestId ? modalTestId : 'errors-modal-wrapper'}
        id={modalTestId ? modalTestId : 'errors-modal-wrapper'}
      >
        <DialogTitle>Error</DialogTitle>
        <DialogContent
          data-testId={contentTestId ? contentTestId : 'errors-modal-content'}
        >
          <Typography variant={'body1'}>{children}</Typography>
        </DialogContent>
        <DialogActions>
          <PageDialogSubmit onClick={onClose}>OK</PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  }
}
