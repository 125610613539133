import { ErrorsHandler } from 'state/Errors';
import { StatusHandler } from 'state/Status';
import { LoadingHandler } from 'state/Loading';
import * as actions from './actions';

export const getDialogUpdating = LoadingHandler()
  .handleAsyncLoading(actions.BOX_CREATE_TIMESHEET_DIALOG_SUBMIT)
  .makeSelector();

export const getDialogErrors = ErrorsHandler()
  .clearErrors(actions.BOX_CREATE_TIMESHEET_DIALOG_OPENED)
  .clearErrors(actions.BOX_CREATE_TIMESHEET_DIALOG_ERRORS_CLOSED)
  .handleAsyncErrors(actions.BOX_CREATE_TIMESHEET_DIALOG_SUBMIT)
  .makeSelector();

export const getDialogRequestStatus = StatusHandler()
  .handleAsyncStatus(actions.BOX_CREATE_TIMESHEET_DIALOG_SUBMIT)
  .makeSelector();


