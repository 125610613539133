import { updateRosteredShift } from 'lib/Api/helpers';
import { BOX_ROSTERED_SHIFTS_ADD_BY_SOCKET } from 'state/RosteredShifts/actions';
import { Dictionary } from 'ts-essentials';
import { RosteredShift, ServerRosteredShift } from 'type';
import { Listener } from './types';
import makeDelayedHandler from './utils/makeDelayedHandler';

export const rosteredShiftChanged: Listener = (
  channel,
  emit
) => {
  const itemHandler = (serverRosteredShift: ServerRosteredShift) =>
    updateRosteredShift(serverRosteredShift);

  const handler = (shifts: Dictionary<RosteredShift>) => {
    emit(BOX_ROSTERED_SHIFTS_ADD_BY_SOCKET(shifts));
  };

  channel.onRosteredShiftChanged(
    makeDelayedHandler({
      itemHandler,
      handler,
    })
  );
};
