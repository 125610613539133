import React from 'react';
import ErrorModal from '../ErrorModal';
import ConfirmDeletionModal from '../ConfirmDeletionModal';
import { ShortcutsModal } from '../ShortcutsModal';
import ConfirmBulkDeletionModal from '../BulkDelete/ConfirmBulkDeletionModal';

import RosterApproveModal from './RosterApproveModal';
import RosterOffersModal from './RosterOffersModal';
import RosterCreateUserModal from './RosterCreateUserModal';
import RosterShiftModal from './RosterShiftModal';
import RosterTimesheetModal from './RosterTimesheetModal';
import RosterEventsModal from './RosterEventsModal';
import PublishModal from '../PublishModal';
import DownloadCSVModal from '../DownloadCSVModal';
import GenerateTimesheetsModal from '../GenerateTimesheetsModal';
import { useSelector } from 'react-redux';
import { getTemplateId } from 'state/Roster/EditTemplate';
import { PrintModal } from './PrintModal';

function RosterPageModals() {
  const templateId = useSelector(getTemplateId);
  return (
    <>
      <PublishModal />
      <RosterApproveModal />
      <RosterOffersModal />
      <ErrorModal />
      <ConfirmBulkDeletionModal editTemplate={!!templateId} />
      <RosterShiftModal />
      <RosterTimesheetModal />
      <RosterEventsModal />
      <ConfirmDeletionModal />
      <ShortcutsModal />
      <DownloadCSVModal />
      <GenerateTimesheetsModal />
      <PrintModal />
    </>
  );
}

export default RosterPageModals;
