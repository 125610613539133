import React from 'react';
import { MoreHorizIcon } from '../icons';
import { Button } from '../Button';
import {
  CardHeaderOptionsProps,
  HeaderOptions,
  HeaderOptionsCustom,
} from './type';
import CardHeaderOptionsDropdown from './CardHeaderOptionsDropdown';

const isCustomOptions = (
  props: HeaderOptions
): props is HeaderOptionsCustom => {
  const { onClick } = props as HeaderOptionsCustom;

  return !!onClick;
};

function CardHeaderOptions({ options }: CardHeaderOptionsProps) {
  const { icon = <MoreHorizIcon /> } = options;
  let content;

  if (isCustomOptions(options)) {
    content = <Button {...options} icon={icon} />;
  } else {
    if (options.dropdownOptions.length) {
      content = <CardHeaderOptionsDropdown {...options} icon={icon} />;
    }
  }

  if (content) {
    return <div className="elmo-actioncard__header-options">{content}</div>;
  }

  return null;
}

export default CardHeaderOptions;
