import { select } from 'redux-saga/effects';
import {
  currentTimeInMainTimezoneSelector,
  getFiltersFrom,
  getFiltersTo,
  getSelectedPeriod,
} from '../../../selectors';
import { rangeFromFromMaker, rangeFromPeriodMakers } from './range-makers';

const makeSelectedRange = function* () {
  const from: ReturnType<typeof getFiltersFrom> = yield select(getFiltersFrom);

  if (from) {
    const to: ReturnType<typeof getFiltersTo> = yield select(getFiltersTo);

    return rangeFromFromMaker(from, to);
  }

  const period: ReturnType<typeof getSelectedPeriod> = yield select(
    getSelectedPeriod
  );
  const currentTimeInMainTimezone: ReturnType<
    typeof currentTimeInMainTimezoneSelector
  > = yield select(currentTimeInMainTimezoneSelector);

  return rangeFromPeriodMakers[period](currentTimeInMainTimezone);
};

export default makeSelectedRange;
