import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getCurrentUser } from 'state/Auth';
import { getAccountExternalId } from 'state/Account';
import { StoreState } from 'state/types';

type Props = {
  currentUserId: string;
  accountExternalId: string | null;
};

function InitialisePendo({ currentUserId, accountExternalId }: Props) {
  useEffect(() => {
    let environment;
    if (process.env.REACT_APP_ENV === 'production') {
      environment = 'prod-';
    } else if (process.env.REACT_APP_ENV === 'staging') {
      environment = 'staging-';
    } else {
      environment = 'test-';
    }

    if ((window as any).pendo && currentUserId && accountExternalId) {
      (window as any).pendo.initialize({
        visitor: {
          id: `${environment}${currentUserId}`,
        },
        account: {
          id: `${environment}${accountExternalId}`,
        },
      });
    }
  }, [currentUserId, accountExternalId]);
  return null;
}

const mapStateToProps = (state: StoreState): Props => ({
  currentUserId: getCurrentUser(state).hr_uuid,
  accountExternalId: getAccountExternalId(state),
});

export default connect(mapStateToProps)(InitialisePendo);
