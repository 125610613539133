import { ErrorsHandler } from 'state/Errors';
import { LoadingHandler } from 'state/Loading';
import { StatusHandler } from 'state/Status';
import * as actions from './actions';

/**
 * New Timesheets Page
 *
 * Loading state that is used for showing skeleton on initial fetch
 * */
export const getPageFetching = LoadingHandler()
  .handleAsyncLoading(actions.BOX_TIMESHEETS_WEEKLY_PAGE_DATA_GET)
  .makeSelector();

/**
 * New Timesheets Page
 *
 * Loading state that is used for showing loading overlay
 * when the page is already loaded and some request is executing
 *
 * Approve timesheet or similar
 * */
export const getPageUpdating = LoadingHandler()
  .handleAsyncLoading(actions.BOX_TIMESHEETS_WEEKLY_PAGE_DATA_UPDATE)
  .handleAsyncLoading(actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_DELETE)
  .handleAsyncLoading(actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_APPROVE)
  .handleAsyncLoading(actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_UNAPPROVE)
  .handleAsyncLoading(actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_CREATE)
  .makeSelector();

/**
 * Loading state that is used in dialogs on the Timesheets page
 * */
export const getDialogUpdating = LoadingHandler()
  .handleAsyncLoading(actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_STOP)
  .handleAsyncLoading(actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_UPDATE)
  .handleAsyncLoading(actions.BOX_TIMESHEETS_WEEKLY_TIMESHEETS_DELETE)
  .handleAsyncLoading(actions.BOX_TIMESHEETS_WEEKLY_TIMESHEETS_APPROVE)
  .makeSelector();

/**
 * Summary loading state
 * */
export const getSummaryUpdating = LoadingHandler()
  .handleAsyncLoading(actions.BOX_TIMESHEETS_WEEKLY_SUMMARY_UPDATE)
  .makeSelector();

/**
 * edit timesheet modal / assigned shift field loading
 * */
export const getAllowedToAssignShiftsFetching = LoadingHandler()
  .handleAsyncLoading(actions.BOX_TIMESHEETS_WEEKLY_ALLOWED_TO_ASSIGN_SHIFTS_GET)
  .makeSelector();

export const getAllowedToAssignShiftsUpdating = LoadingHandler()
  .handleAsyncLoading(actions.BOX_TIMESHEETS_WEEKLY_ALLOWED_TO_ASSIGN_SHIFTS_UPDATE)
  .makeSelector();

/**
 * Errors caught on initial fetch
 * */
export const getPageFetchErrors = ErrorsHandler()
  .handleAsyncErrors(actions.BOX_TIMESHEETS_WEEKLY_PAGE_DATA_GET)
  .makeSelector();

/**
 * Errors to be shown on a page after failed requests
 * */
export const getPageErrors = ErrorsHandler()
  .clearErrors(actions.BOX_TIMESHEETS_WEEKLY_ERRORS_CLOSED)
  .clearErrors(actions.BOX_TIMESHEETS_WEEKLY_DIALOG_OPENED)
  .handleAsyncErrors(actions.BOX_TIMESHEETS_WEEKLY_PAGE_DATA_UPDATE)
  .handleAsyncErrors(actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_DELETE)
  .handleAsyncErrors(actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_APPROVE)
  .handleAsyncErrors(actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_UNAPPROVE)
  .handleAsyncErrors(actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_CREATE)
  .makeSelector();

/**
 * Errors to be shown in a dialogs after failed requests
 * */
export const getDialogErrors = ErrorsHandler()
  .clearErrors(actions.BOX_TIMESHEETS_WEEKLY_DIALOG_ERRORS_CLOSED)
  .clearErrors(actions.BOX_TIMESHEETS_WEEKLY_DIALOG_OPENED)
  .handleAsyncErrors(actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_STOP)
  .handleAsyncErrors(actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_UPDATE)
  .handleAsyncErrors(actions.BOX_TIMESHEETS_WEEKLY_TIMESHEETS_DELETE)
  .handleAsyncErrors(actions.BOX_TIMESHEETS_WEEKLY_TIMESHEETS_APPROVE)
  .makeSelector();

export const getSummaryErrors = ErrorsHandler()
  .handleAsyncErrors(actions.BOX_TIMESHEETS_WEEKLY_SUMMARY_UPDATE)
  .makeSelector();

export const getAllowedToAssignShiftsFetchErrors = ErrorsHandler()
  .handleAsyncErrors(actions.BOX_TIMESHEETS_WEEKLY_ALLOWED_TO_ASSIGN_SHIFTS_GET)
  .makeSelector();

export const getAllowedToAssignShiftsErrors = ErrorsHandler()
  .handleAsyncErrors(actions.BOX_TIMESHEETS_WEEKLY_ALLOWED_TO_ASSIGN_SHIFTS_UPDATE)
  .makeSelector();

/**
 * Request status for performing actions from views after success/failed/canceled request
 * */
export const getDialogRequestStatus = StatusHandler()
  .handleAsyncStatus(actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_STOP)
  .handleAsyncStatus(actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_UPDATE)
  .handleAsyncStatus(actions.BOX_TIMESHEETS_WEEKLY_TIMESHEETS_DELETE)
  .handleAsyncStatus(actions.BOX_TIMESHEETS_WEEKLY_TIMESHEETS_APPROVE)
  .makeSelector();
