import { ErrorsHandler } from 'state/Errors';
import { LoadingHandler } from 'state/Loading';
import { StatusHandler } from 'state/Status';
import * as actions from './actions';

/**
 * Page fetching
 * */
export const getPageFetching = LoadingHandler()
  .handleAsyncLoading(actions.BOX_ACCOUNT_TREE_PAGE_DATA_GET)
  .makeSelector();

export const getFetchErrors = ErrorsHandler()
  .handleAsyncErrors(actions.BOX_ACCOUNT_TREE_PAGE_DATA_GET)
  .makeSelector();

/**
 * Dialog
 */
export const getDialogUpdating = LoadingHandler()
  .handleAsyncLoading(actions.BOX_ACCOUNT_TREE_ROLES_LINK)
  .handleAsyncLoading(actions.BOX_ACCOUNT_TREE_ROLES_UNLINK)
  .handleAsyncLoading(actions.BOX_ACCOUNT_TREE_AREAS_LINK)
  .handleAsyncLoading(actions.BOX_ACCOUNT_TREE_UPDATE_AREA_STATUS)
  .handleAsyncLoading(actions.BOX_ACCOUNT_TREE_UPDATE_SITE_STATUS)
  .makeSelector();

export const getDialogErrors = ErrorsHandler()
  .clearErrors(actions.BOX_ACCOUNT_TREE_DIALOG_OPENED)
  .handleAsyncErrors(actions.BOX_ACCOUNT_TREE_ROLES_LINK)
  .handleAsyncErrors(actions.BOX_ACCOUNT_TREE_ROLES_UNLINK)
  .handleAsyncErrors(actions.BOX_ACCOUNT_TREE_AREAS_LINK)
  .handleAsyncErrors(actions.BOX_ACCOUNT_TREE_UPDATE_AREA_STATUS)
  .handleAsyncErrors(actions.BOX_ACCOUNT_TREE_UPDATE_SITE_STATUS)
  .clearErrors(actions.BOX_ACCOUNT_TREE_DIALOG_ERRORS_CLEARED)
  .makeSelector();

export const getDialogStatus = StatusHandler()
  .handleAsyncStatus(actions.BOX_ACCOUNT_TREE_ROLES_LINK)
  .handleAsyncStatus(actions.BOX_ACCOUNT_TREE_ROLES_UNLINK)
  .handleAsyncStatus(actions.BOX_ACCOUNT_TREE_AREAS_LINK)
  .handleAsyncStatus(actions.BOX_ACCOUNT_TREE_UPDATE_AREA_STATUS)
  .handleAsyncStatus(actions.BOX_ACCOUNT_TREE_UPDATE_SITE_STATUS)
  .makeSelector();
