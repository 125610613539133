import { ISO8601DateTime } from 'type';
import { getMinutesFromDayStart } from 'lib/helpers';
import dateTimeHelper from './dateTimeHelper';
import dateFieldValueHelper from './dateFieldValueHelper';

export const getDateFieldValue = (dateTime: ISO8601DateTime) => {
  const dateFieldValueMoment = dateTimeHelper.parse(dateTime).startOf('day');

  return dateFieldValueHelper.stringify(dateFieldValueMoment);
};

export const getTimeFieldValue = (dateTime: ISO8601DateTime): number => {
  const dateTimeMoment = dateTimeHelper.parse(dateTime);

  return getMinutesFromDayStart(dateTimeMoment);
};
