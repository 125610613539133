import { TextField } from 'extended-oxygen-elements';
import { makeStyles } from '@mui/styles';
import React, { ChangeEvent, useRef, useState } from 'react';
import { AccountTreeSite } from '../../../../../../type';

type Props = {
  location: AccountTreeSite;
  setSettings: (id: string, options: any) => void;
  isDisabled: boolean;
};

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      background: '#ffffff',
    },
    '& .Mui-disabled': {
      background: '#F4F5F6',
    },
  },
}));

const min = 50;

const RadiusInput = ({ location, setSettings, isDisabled }: Props) => {
  const classes = useStyles();
  const ref = useRef(null);
  const [radius, setRadius] = useState(location.address_radius);

  const setLocationRadius = (value: number) => {
    setRadius(value);
    setSettings(location.id, {
      address_radius: value,
    });
  };

  const changeHandler = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.currentTarget;
    setLocationRadius(+value);
  };

  const blurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    let newValue = parseInt(value, 10) < min ? min : parseInt(value, 10);
    e.target.value = newValue.toString(10);
    setLocationRadius(newValue);
  };

  return (
    <div className={'geofencing-radius-field-wrapper'}>
      <TextField
        type={'number'}
        ref={ref}
        name={`radius__${location.id}`}
        value={isDisabled ? '' : radius}
        onChange={changeHandler}
        disabled={isDisabled}
        placeholder={'Radius distance'}
        label={'Radius distance'}
        inputProps={{ min }}
        className={classes.root}
        onBlur={blurHandler}
      />
      <span>mts</span>
    </div>
  );
};

export default RadiusInput;
