import { createReducer } from 'lib/store-utils';
import { SummaryReducerState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';

export const summary = createReducer<SummaryReducerState>(
  {},
  getDefaultState()
);

summary.on(
  actions.BOX_SUMMARY_GET_DATA_REQUEST,
  (state): SummaryReducerState => ({
    ...state,
    isLoading: true,
    errors: [],
    hasErrors: false
  })
);

summary.on(
  actions.BOX_SUMMARY_GET_DATA_SUCCESS,
  (state, response): SummaryReducerState => ({
    ...state,
    isLoading: false,
    summary: response
  })
);

summary.on(
  actions.BOX_SUMMARY_GET_DATA_FAILURE,
  (state, errors): SummaryReducerState => ({
    ...state,
    isLoading: false,
    errors: errors,
    hasErrors: true
  })
);

summary.on(
  actions.BOX_SUMMARY_REMOVE_ERRORS,
  (state): SummaryReducerState => ({
    ...state,
    errors: []
  })
);

summary.on(
  actions.BOX_SUMMARY_SET_STATUS,
  (state, status): SummaryReducerState => ({
    ...state,
    status
  })
);
