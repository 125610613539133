import React, { useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { isAndroid, isIOS, isTablet, MobileView } from 'react-device-detect';
import { isAppMarket } from '../helpers';
import { staticFlags } from '../feature-flags';

const market = isAppMarket('au') ? 'au' : 'gb';

type BannerConfig = {
  imageUrl: string;
  name: string;
  publisher: string;
  infoText: string;
  linkButtonText: string;
  linkUrl: string;
};

const appIOSInfo: BannerConfig = {
  imageUrl:
    'https://play-lh.googleusercontent.com/LyUta2BHbSaSK5RVp2E4iJ4XNaIT18lM3Kqdn5ieNNRLn9oGix1dXhP3Wlj7nyF9Urcf=w240-h480-rw',
  name: 'Breathe - People Portal',
  publisher: 'Breathe Software Pty Ltd',
  infoText: 'FREE - On App Store',
  linkButtonText: 'View',
  linkUrl: `https://apps.apple.com/${market}/app/breathe-people-portal/id1577351838`,
};

const appAndroidInfo: BannerConfig = {
  imageUrl:
    'https://play-lh.googleusercontent.com/LyUta2BHbSaSK5RVp2E4iJ4XNaIT18lM3Kqdn5ieNNRLn9oGix1dXhP3Wlj7nyF9Urcf=w240-h480-rw',
  name: 'Breathe - People Portal',
  publisher: 'Breathe Software Pty Ltd',
  infoText: 'FREE - In the Google Play',
  linkButtonText: 'View',
  linkUrl: 'https://play.google.com/store/apps/details?id=com.elmo.rta.breathe',
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    bannerWrapper: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: '79px',
      zIndex: 100,
      position: 'relative',
      fontFamily: 'Rubik',
      background: '#f2f1f6',
      boxShadow:
        '0px 1px 0px rgba(0, 0, 0, 0.05), 0px 0.5px 0px rgba(0, 0, 0, 0.15)',
    },
    bannerBtn: {
      width: '40px',
      textAlign: 'center',
      fontSize: 18,
      fontWeight: 100,
      color: '#ccc',
      '& span': {
        cursor: 'pointer',
      },
    },
    bannerImg: {
      width: '64px',
      '& img': {
        maxWidth: '100%',
        borderRadius: '15px',
      },
    },
    bannerContent: {
      width: 'calc(100% - 200px)',
      paddingLeft: '10px',
    },
    bannerTitle: {
      fontSize: '14px',
      lineHeight: '14px',
      color: '#090b0a',
      marginBottom: 3,
      fontWeight: 400,
    },
    bannerPublisher: {
      fontSize: '11px',
      lineHeight: '14px',
      color: '#848489',
      marginBottom: 3,
      fontWeight: 400,
    },
    bannerInfo: {
      fontSize: '12px',
      lineHeight: '14px',
      color: '#848489',
      marginBottom: 0,
      fontWeight: 400,
    },
    bannerLink: {
      width: '140px',
      paddingRight: '20px',
      textAlign: 'right',
      '& a': {
        textDecoration: 'none',
        color: '#367af6',
        fontSize: 18,
        lineHeight: '18px',
        fontWeight: 400,
      },
    },
  })
);

const AppBanner = ({ config }: { config: BannerConfig }) => {
  const styles = useStyles();
  const isClosed = window.localStorage.getItem('smartBannerClosed');
  const [isClosedBanner, setIsClosed] = useState(!!isClosed);
  const onClose = () => {
    setIsClosed(true);
    window.localStorage.setItem('smartBannerClosed', '1');
  };
  if (isClosedBanner) {
    return null;
  }
  return (
    <div className={styles.bannerWrapper}>
      <div className={styles.bannerBtn}>
        <span onClick={onClose}>&times;</span>
      </div>
      <div className={styles.bannerImg}>
        <img src={config.imageUrl} alt={'Breathe img'} />
      </div>
      <div className={styles.bannerContent}>
        <div className={styles.bannerTitle}>{config.name}</div>
        <div className={styles.bannerPublisher}>{config.publisher}</div>
        <div className={styles.bannerInfo}>{config.infoText}</div>
      </div>
      <div className={styles.bannerLink}>
        <a href={config.linkUrl} target="_blank" rel="noreferrer">
          View
        </a>
      </div>
    </div>
  );
};

const IOSBanner = () => {
  return isIOS && !isTablet ? <AppBanner config={appIOSInfo} /> : null;
};

const AndroidBanner = () => {
  return isAndroid ? <AppBanner config={appAndroidInfo} /> : null;
};

export default function SmartAppBanner() {
  if (!staticFlags.smartBanner) {
    return null;
  }
  return (
    <MobileView>
      <IOSBanner />
      <AndroidBanner />
    </MobileView>
  );
}
