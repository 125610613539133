import { createSelector } from 'reselect';
import { pickBy } from 'lodash';
import { UserFields } from 'type';
import { StoreState } from 'state/types';
import { userListSelector } from 'state/UsersCollection';
import { getSiteId } from 'state/RosteredShifts';
import { userRoleFilter } from '../../helpers';
import { filtersSelector } from '../common';

export const usersFilteredBySiteIdSelector = createSelector(
  userListSelector,
  getSiteId,
  (userList, siteId) =>
    pickBy(userList, (user) =>
      user.user_roles.some((userRole) => userRole.site_id === siteId)
    )
);

const usersFilteredBySiteIdArraySelector = createSelector(
  usersFilteredBySiteIdSelector,
  Object.values
);

export const usersFilteredArraySelector = createSelector<
  StoreState,
  ReturnType<typeof filtersSelector>,
  ReturnType<typeof usersFilteredBySiteIdArraySelector>,
  ReturnType<typeof getSiteId>,
  UserFields[]
>(
  filtersSelector,
  usersFilteredBySiteIdArraySelector,
  getSiteId,
  userRoleFilter
);

export const userIdsFilteredSelector = createSelector(
  usersFilteredArraySelector,
  (users) => users.map((user) => user.id)
);
