import { createAction } from 'lib/store-utils';
import { FormattedErrors, Overview } from '../../../type/models';
import { EmployeeDashboardOverviewGetShiftsResponse } from 'lib/Api/type';

export const BOX_EMPLOYEE_OVERVIEW_DATA_REQUEST = createAction('Employee dashboard Overview / get page data request');
export const BOX_EMPLOYEE_OVERVIEW_GET_OVERVIEW_SUCCESS = createAction<Overview>('Employee dashboard Overview / Get overview data success');

export const BOX_EMPLOYEE_OVERVIEW_GET_SHIFTS_REQUEST = createAction('Employee dashboard / Get get overview shifts request');
export const BOX_EMPLOYEE_OVERVIEW_GET_SHIFTS_SUCCESS = createAction<EmployeeDashboardOverviewGetShiftsResponse>('Employee dashboard / Get get overview shifts success');
export const BOX_EMPLOYEE_OVERVIEW_GET_SHIFTS_FAILURE = createAction<FormattedErrors>('Employee dashboard / Get get overview shifts failed');

export const BOX_EMPLOYEE_OVERVIEW_GET_REQUEST = createAction('Employee dashboard overview / get request');
export const BOX_EMPLOYEE_OVERVIEW_GET_SUCCESS = createAction<Overview>('Employee dashboard overview / get success');
