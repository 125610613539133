import { ReactNode } from 'react';
import './main.scss';

type MainProps = {
  children?: ReactNode;
};

export default function Main({ children }: MainProps) {
  return <main className="main">{children}</main>;
}
