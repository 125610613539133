import {
  Minutes,
  RosterTimesheetListedStoppedWithPayEntries,
  RosterTimesheetListedWithPayEntries,
} from 'type';

export const minutesDurationGuard = (
  value: number | Minutes
): value is Minutes => !(isNaN(value) || value < 0);

export const isStoppedTimesheetWithPayEntriesGuard = (
  maybeTimesheet:
    | RosterTimesheetListedWithPayEntries
    | RosterTimesheetListedStoppedWithPayEntries
    | undefined
): maybeTimesheet is RosterTimesheetListedStoppedWithPayEntries =>
  !!maybeTimesheet?.end;
