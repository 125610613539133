import nanoid from 'nanoid';
import { BreaksFieldGroupBreak } from '../types';

export default makeFormBreak;

export function makeFormBreak({
  start = null,
  duration = null,
  paid = false,
}: Partial<Omit<BreaksFieldGroupBreak, 'id'>> = {}): BreaksFieldGroupBreak {
  return {
    start,
    duration,
    paid,
    id: nanoid(),
  };
}
