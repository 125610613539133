import { createReducer } from 'lib/store-utils';
import { TimesheetModalReducerState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';

export const timesheetModal = createReducer<TimesheetModalReducerState>(
  {},
  getDefaultState()
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_GET_BY_ID,
  (state): TimesheetModalReducerState => ({
    ...state,
    timesheetModal: {
      ...state.timesheetModal,
      isOpened: true,
      isLoading: true
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_OPEN,
  (state): TimesheetModalReducerState => ({
    ...state,
    timesheetModal: {
      ...state.timesheetModal,
      isOpened: true,
      isLoading: false
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_CLOSE,
  (state): TimesheetModalReducerState => ({
    ...state,
    timesheetModal: {
      ...state.timesheetModal,
      isOpened: false,
      isUpdating: false,
      allowedShifts: {},
      errors: []
    },
    currentTimesheet: getDefaultState().currentTimesheet,
    initialPayEntries: [],
    recalculated: false
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_SET_SHIFT_USER,
  (state, userId): TimesheetModalReducerState => ({
        ...state,
        currentTimesheet: {
          ...state.currentTimesheet,
          user_id: userId
        },
        timesheetPayload: {
          ...state.timesheetPayload,
          user_id: userId
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_SET_SHIFT,
  (state, { isCurrentUser = false, ...shiftParams }): TimesheetModalReducerState => ({
    ...state,
    currentTimesheet: {
      ...state.currentTimesheet,
      ...shiftParams
    },
    timesheetPayload: {
      ...state.currentTimesheet,
      ...shiftParams
    },
    timesheetModal: {
      ...state.timesheetModal,
      isLoading: false,
      errors: [],
      isCurrentUser
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_SET_HIGHER_DUTY,
  (state, higherDuty): TimesheetModalReducerState => ({
    ...state,
    currentTimesheet: {
      ...state.currentTimesheet,
      higher_duty: higherDuty
    },
    timesheetPayload: {
      ...state.currentTimesheet,
      higher_duty: higherDuty
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_GET_BY_ID_SUCCESS,
  (state, response): TimesheetModalReducerState => {
    return {
      ...state,
      currentTimesheet: response,
      timesheetPayload: response,
      timesheetModal: {
        ...state.timesheetModal,
        isLoading: false
      },
      initialDuty: {
        higher_duty: response.higher_duty,
        higher_duty_start: response.higher_duty_start,
        higher_duty_end: response.higher_duty_end
      },
      initialPayEntries: response.pay_entries || []
    };
  }
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_GET_BY_ID_FAILURE,
  (state, errors): TimesheetModalReducerState => {
    return {
      ...state,
      timesheetModal: {
        ...state.timesheetModal,
        isLoading: false,
        errors: errors
      }
    };
  }
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_CREATE_SHIFT,
  (state): TimesheetModalReducerState => ({
    ...state,
    timesheetModal: {
      ...state.timesheetModal,
      isUpdating: true,
      errors: []
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_CREATE_SELF_ASSIGNED_SHIFT,
  (state): TimesheetModalReducerState => ({
    ...state,
    timesheetModal: {
      ...state.timesheetModal,
      isUpdating: true,
      errors: []
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_CREATE_SHIFT_CANCEL,
  (state): TimesheetModalReducerState => ({
    ...state,
    timesheetModal: {
      ...state.timesheetModal,
      isUpdating: false,
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_CREATE_SHIFT_SUCCESS,
  (state): TimesheetModalReducerState => {
    return {
      ...state,
      timesheetModal: {
        ...state.timesheetModal,
        isOpened: false,
        isUpdating: false
      }
    };
  }
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_CREATE_SHIFT_FAILURE,
  (state, errors): TimesheetModalReducerState => ({
    ...state,
    timesheetModal: {
      ...state.timesheetModal,
      isUpdating: false,
      errors: errors
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_CLEAR_ERRORS,
  (state): TimesheetModalReducerState => ({
    ...state,
    errors: [],
    timesheetModal: {
      ...state.timesheetModal,
      errors: []
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_UPDATE,
  (state): TimesheetModalReducerState => ({
    ...state,
    timesheetModal: {
      ...state.timesheetModal,
      isUpdating: true,
      errors: []
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_UPDATE_SHIFT_SUCCESS,
  (state): TimesheetModalReducerState => {
    return {
      ...state,
      timesheetModal: {
        ...state.timesheetModal,
        isOpened: false,
        isUpdating: false,
        errors: []
      },
      initialPayEntries: [],
      recalculated: false
    }
  }
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_UPDATE_SHIFT_FAILURE,
  (state, errors): TimesheetModalReducerState => ({
    ...state,
    timesheetModal: {
      ...state.timesheetModal,
      isUpdating: false,
      errors: errors
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_DELETE_SHIFT,
  (state): TimesheetModalReducerState => ({
    ...state,
    timesheetModal: {
      ...state.timesheetModal,
      isUpdating: true
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_DELETE_SHIFT_SUCCESS,
  (state): TimesheetModalReducerState => ({
    ...state,
    timesheetModal: {
      ...state.timesheetModal,
      isOpened: false,
      isUpdating: false,
      errors: []
    },
    overlapShiftModal: {
      ...state.overlapShiftModal,
      ignoreErrors: false
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_DELETE_SHIFT_FAILURE,
  (state, errors): TimesheetModalReducerState => ({
    ...state,
    timesheetModal: {
      ...state.timesheetModal,
      isUpdating: false,
      errors: errors
    },
    overlapShiftModal: {
      ...state.overlapShiftModal,
      ignoreErrors: false
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_SET_PAYLOAD_PROPS,
  (state, payload): TimesheetModalReducerState => ({
      ...state,
      timesheetPayload: payload,
      currentTimesheet: payload
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_APPROVE_TIMESHEET,
  (state): TimesheetModalReducerState => ({
    ...state,
    timesheetModal: {
      ...state.timesheetModal,
      isUpdating: true,
      errors: []
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_TOGGLE_APPROVE,
  (state, value): TimesheetModalReducerState => ({
    ...state,
    timesheetPayload: {
      ...state.timesheetPayload,
      is_approved: value
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_APPROVE_SUCCESS,
  (state): TimesheetModalReducerState => ({
    ...state,
    timesheetModal: {
      ...state.timesheetModal,
      isUpdating: false,
      errors: []
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_APPROVE_FAILURE,
  (state, errors): TimesheetModalReducerState => ({
    ...state,
    timesheetModal: {
      ...state.timesheetModal,
      isUpdating: false,
      errors: errors
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_OVERLAP_CONFIRMATION_SET_DELETING,
  (state, errors): TimesheetModalReducerState => ({
    ...state,
    overlapShiftModal: {
      ...state.overlapShiftModal,
      isDeleting: true,
      isOpened: true
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_OVERLAP_CONFIRMATION_OPEN,
  (state, errors): TimesheetModalReducerState => ({
    ...state,
    timesheetModal: {
      ...state.timesheetModal,
      isUpdating: false
    },
    overlapShiftModal: {
      ...state.overlapShiftModal,
      errors: errors,
      isOpened: true
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_OVERLAP_CONFIRMATION_CLOSE,
  (state, isUpdating): TimesheetModalReducerState => ({
    ...state,
    timesheetModal: {
      ...state.timesheetModal,
      isUpdating: isUpdating
    },
    overlapShiftModal: {
      ...state.overlapShiftModal,
      isApproveOverlap: false,
      isOpened: false,
      isDeleting: false,
      errors: [],
      ignoreErrors: isUpdating
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_GET_LIST_OF_SHIFTS,
  (state, response): TimesheetModalReducerState => ({
    ...state,
    timesheetModal: {
      ...state.timesheetModal,
      isLoadingShifts: true
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_GET_LIST_OF_SHIFTS_SUCCESS,
  (state, response): TimesheetModalReducerState => ({
    ...state,
    timesheetModal: {
      ...state.timesheetModal,
      allowedShifts: response,
      isLoadingShifts: false
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_GET_LIST_OF_SHIFTS_FAILURE,
  (state, errors): TimesheetModalReducerState => ({
    ...state,
    timesheetModal: {
      ...state.timesheetModal,
      isLoadingShifts: false,
      errors: errors
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_OVERLAP_CONFIRMATION_APPROVE,
  (state): TimesheetModalReducerState => ({
    ...state,
    overlapShiftModal: {
      ...state.overlapShiftModal,
      isApproveOverlap: true
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_RECALCULATE,
  (state, payload): TimesheetModalReducerState => ({
    ...state,
    isRecalculating: true,
    timesheetPayload: {
      ...state.timesheetPayload,
      higher_duty: payload.higher_duty,
      higher_duty_start: payload.higher_duty_start,
      higher_duty_end: payload.higher_duty_end
    },
    currentTimesheet: {
      ...state.currentTimesheet,
      higher_duty: payload.higher_duty,
      higher_duty_start: payload.higher_duty_start,
      higher_duty_end: payload.higher_duty_end
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_RECALCULATE_SUCCESS,
  (state, data): TimesheetModalReducerState => ({
    ...state,
    isRecalculating: false,
    currentTimesheet: {
      ...state.currentTimesheet,
      pay_entries: data
    },
    timesheetPayload: {
      ...state.currentTimesheet,
      pay_entries: data
    },
    recalculated: true,
    timesheetModal: {
      ...state.timesheetModal,
      errors: []
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_AFFECT_SAVE_AWARD_BUTTON,
  (state, value): TimesheetModalReducerState => ({
    ...state,
    recalculated: value === '',
    timesheetPayload: {
      ...state.timesheetPayload,
      higher_duty: value === '' ? null : state.timesheetPayload.higher_duty,
      higher_duty_start: value === '' ? null : state.timesheetPayload.higher_duty_start,
      higher_duty_end: value === '' ? null : state.timesheetPayload.higher_duty_end
    },
    currentTimesheet: {
      ...state.currentTimesheet,
      higher_duty: value === '' ? null : state.currentTimesheet.higher_duty,
      higher_duty_start: value === '' ? null : state.currentTimesheet.higher_duty_start,
      higher_duty_end: value === '' ? null : state.currentTimesheet.higher_duty_end
    },
    timesheetModal: {
      ...state.timesheetModal,
      errors: []
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_RECALCULATE_FAILURE,
  (state, errors): TimesheetModalReducerState => ({
    ...state,
    isRecalculating: false,
    timesheetModal: {
      ...state.timesheetModal,
      errors: errors
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_RESTORE_AWARDS,
  (state): TimesheetModalReducerState => ({
    ...state,
    currentTimesheet: {
      ...state.currentTimesheet,
      pay_entries: state.initialPayEntries,
      higher_duty: state.initialDuty.higher_duty,
      higher_duty_start: state.initialDuty.higher_duty_start,
      higher_duty_end: state.initialDuty.higher_duty_end
    },
    timesheetPayload: {
      ...state.currentTimesheet,
      pay_entries: state.initialPayEntries,
      higher_duty: state.initialDuty.higher_duty,
      higher_duty_start: state.initialDuty.higher_duty_start,
      higher_duty_end: state.initialDuty.higher_duty_end
    },
    recalculated: false,
    timesheetModal: {
      ...state.timesheetModal,
      errors: []
    },
    errors: []
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_GET_HIGHER_DUTIES,
  (state): TimesheetModalReducerState => ({
    ...state,
    dutiesListIsLoading: true
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_GET_HIGHER_DUTIES_SUCCESS,
  (state, data): TimesheetModalReducerState => ({
    ...state,
    dutiesListIsLoading: false,
    dutiesList: data
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_GET_HIGHER_DUTIES_FAILURE,
  (state, errors): TimesheetModalReducerState => ({
    ...state,
    dutiesListIsLoading: false,
    timesheetModal: {
      ...state.timesheetModal,
      errors: errors
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_GET_ALLOWANCES,
  (state): TimesheetModalReducerState => ({
    ...state,
    allowancesIsLoading: true
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_GET_ALLOWANCES_SUCCESS,
  (state, data): TimesheetModalReducerState => ({
    ...state,
    allowancesIsLoading: false,
    allowancesList: data
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_GET_ALLOWANCES_FAILURE,
  (state, errors): TimesheetModalReducerState => ({
    ...state,
    allowancesIsLoading: false,
    timesheetModal: {
      ...state.timesheetModal,
      errors: errors
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_ADD_ALLOWANCE,
  (state): TimesheetModalReducerState => ({
    ...state,
    allowancesIsAdding: true
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_ADD_ALLOWANCE_SUCCESS,
  (state, timesheet): TimesheetModalReducerState => ({
    ...state,
    allowancesIsAdding: false,
    currentTimesheet: {
      ...state.currentTimesheet,
      is_approved: timesheet.is_approved,
      pay_entries: timesheet.pay_entries
    },
    timesheetPayload: {
      ...state.timesheetPayload,
      is_approved: timesheet.is_approved,
      pay_entries: timesheet.pay_entries
    },
    initialPayEntries: timesheet.pay_entries || []
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_ADD_ALLOWANCE_FAILURE,
  (state, errors): TimesheetModalReducerState => ({
    ...state,
    allowancesIsAdding: false,
    timesheetModal: {
      ...state.timesheetModal,
      errors: errors
    }
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_DELETE_ALLOWANCE,
  (state): TimesheetModalReducerState => ({
    ...state,
    allowanceIsDeleting: true
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_DELETE_ALLOWANCE_SUCCESS,
  (state, response): TimesheetModalReducerState => ({
    ...state,
    allowanceIsDeleting: false,
    currentTimesheet: {
      ...state.currentTimesheet,
      ...response
    },
    timesheetPayload: {
      ...state.timesheetPayload,
      ...response
    },
    initialPayEntries: response.pay_entries || []
  })
);

timesheetModal.on(
  actions.BOX_TIMESHEET_SHIFT_MODAL_DELETE_ALLOWANCE_FAILURE,
  (state, errors): TimesheetModalReducerState => ({
    ...state,
    allowanceIsDeleting: false,
    timesheetModal: {
      ...state.timesheetModal,
      errors: errors
    }
  })
);
