import { Header } from 'element';
import { useRoute } from 'hooks';
import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { privateRoutes } from 'routes';
import { getFlag } from 'state/Flags';

type PayHeaderProps = {
  activeTab: 'elements' | 'conditions';
};

export function PayHeader({ activeTab }: PayHeaderProps) {
  const route = useRoute();
  const isShiftOvertimeRulesEnabled = useSelector(
    getFlag.weekendShiftOvertimeRules
  );
  const { settings: settingsRoute } = privateRoutes;
  const { routes: payRoutes } = settingsRoute.routes.pay;

  return (
    <Header
      title="Pay Settings"
      backButton={{
        title: 'Back to Settings',
        onClick: () => {
          route.goTo(settingsRoute.path);
        },
      }}
      tabs={_.compact([
        {
          active: activeTab === 'elements',
          label: 'Pay elements',
          onClick: () => {
            route.goTo(payRoutes.payElements.path);
          },
        },
        isShiftOvertimeRulesEnabled && {
          active: activeTab === 'conditions',
          label: 'Payment conditions',
          onClick: () => {
            route.goTo(payRoutes.paymentConditions.path);
          },
        },
      ])}
    />
  );
}

export default PayHeader;
