import React from 'react';
import { TableChartSharpIcon } from 'element';
import { useSelectorWithProps } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { hasPermissionSelector } from 'state/Auth';
import {
  BOX_ROSTER_OPEN_APPLY_CHANGES_MODAL,
  getIsApprovalModeEnabled,
} from 'state/Roster/Roster';
import { getTimesheetsFilter } from 'state/Roster/RosterFilters';

export default useBtnAutoGenerateTimesheets;

export function useBtnAutoGenerateTimesheets() {
  const dispatch = useDispatch();
  const canEditRosteredShifts = useSelectorWithProps(
    hasPermissionSelector,
    'roster.rosteredshift.edit'
  );
  const canApproveTimesheets = useSelectorWithProps(
    hasPermissionSelector,
    'roster.timesheet.edit.approve'
  );
  const isTimesheetsFilterEnabled = useSelector(getTimesheetsFilter);
  const isApprovalModeEnabled = useSelector(getIsApprovalModeEnabled);

  return (
    canEditRosteredShifts &&
    canApproveTimesheets &&
    isTimesheetsFilterEnabled && {
      id: 'autogenerate-timesheets-btn',
      icon: <TableChartSharpIcon color="inherit" />,
      onClick: () => {
        dispatch(BOX_ROSTER_OPEN_APPLY_CHANGES_MODAL('generate'));
      },
      label: 'Open autogenerate timesheets modal',
      isDisabled: isApprovalModeEnabled,
      tooltip: 'Open autogenerate timesheets modal',
    }
  );
}
