import React, { Component, SyntheticEvent } from 'react';
import { getClass } from '../_lib/helper';
import CheckboxInput from './component/CheckboxInput';
import CheckboxLabel from './component/CheckboxLabel';
import { CheckboxProps, CheckboxState } from './type';
import './Checkbox.scss';

class Checkbox extends Component<CheckboxProps, CheckboxState> {
  constructor(props: CheckboxProps) {
    super(props);

    let defaultChecked: boolean = !!(props.isChecked || props.isDefaultChecked);
    this.state = {
      isChecked: defaultChecked ? defaultChecked : false,
    };
  }

  componentDidUpdate(prevProps: CheckboxProps) {
    if (prevProps.isChecked !== this.props.isChecked) {
      this.setState({
        isChecked: !!this.props.isChecked,
      });
    }
  }

  toggle = () => {
    if (this.props.isChecked !== undefined) {
      return;
    }

    this.setState({
      isChecked: !this.state.isChecked,
    });
  };

  onChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { isDisabled, onChange } = this.props;
    if (isDisabled) {
      return;
    }

    this.toggle();
    if (onChange) {
      onChange(e);
    }
  };

  render() {
    const {
      id,
      name,
      label,
      value,
      className,
      isDisabled,
      isInvalid,
      ariaLabel,
      isRequired,
      isIndeterminate,
      tabIndex,
    } = this.props;
    return (
      <span
        id={id}
        className={getClass('elmo-checkbox', className, {
          checked: this.state.isChecked && !isIndeterminate,
          disabled: isDisabled,
          invalid: isInvalid,
          indeterminate: isIndeterminate,
        })}
        data-testid={`elmo-checkbox-${id || 'default'}`}
      >
        <CheckboxInput
          id={id}
          name={name}
          value={value}
          ariaLabel={ariaLabel}
          onChange={this.onChange}
          isChecked={this.state.isChecked}
          isDisabled={isDisabled}
          isRequired={isRequired}
          tabIndex={!!tabIndex ? tabIndex : 0}
        />
        {label && <CheckboxLabel id={id} label={label} />}
      </span>
    );
  }
}

export default Checkbox;
