import moment, { Moment } from 'moment';

export const disabledFutureDate = (end: Moment, current?: Date): boolean => {
  if (current) {
    // It's necessary to use the same offset to compare two dates correctly
    return moment(current).utcOffset(0, true) > moment(end).utcOffset(0, true);
  }
  return false;
};

export const disabledPastDate = (start: Moment, current?: Date): boolean => {
  if (current) {
    // It's necessary to use the same offset to compare two dates correctly
    return moment(current).utcOffset(0, true) < moment(start).utcOffset(0, true);
  }
  return false;
};