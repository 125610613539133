import * as React from 'react';
import { ButtonUploadProps } from '../type';
import Button from '../../Button';

export const ButtonUpload = ({
  getRootProps,
  getInputProps,
  open,
}: ButtonUploadProps) => {
  const rootProps = getRootProps ? getRootProps() : null;
  return (
    <div
      className="elmo-fileupload-input"
      {...rootProps}
      onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();
      }}
    >
      {getInputProps && <input {...getInputProps()} />}
      <Button
        type="primary"
        onClick={() => {
          open();
        }}
      >
        Upload
      </Button>
    </div>
  );
};
