import {
  AppBar as MuiAppBar,
  styled,
  Theme,
  Toolbar,
} from 'extended-oxygen-elements';
import { useResizeObserver } from 'hooks';
import React from 'react';
import { WithChildren } from 'type';
import { useLayoutContext } from './layout-context';
import SmartAppBanner from '../SmartAppBanner';

const AppBarRoot = styled(MuiAppBar)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: 'initial',
}));

export default function AppBar({ children }: WithChildren) {
  const { setAppBarHeight } = useLayoutContext();
  const appBarRefCallback = useResizeObserver((appBarNode) => {
    setAppBarHeight(appBarNode.offsetHeight);
  });
  return (
    <AppBarRoot position="fixed" ref={appBarRefCallback}>
      <SmartAppBanner />
      <Toolbar>{children}</Toolbar>
    </AppBarRoot>
  );
}
