import React from 'react';

type Props<Fields, Payload, Errors extends object> = {
  loading?: boolean;
  validate: (fields: Fields) => Errors;
  makePayload: (fields: Fields) => Payload | undefined;
  onSubmit: (payload: Payload) => void;
};

export default useFormValidator;

export function useFormValidator<Fields, Payload, Errors extends object>(
  fields: Fields,
  { loading, validate, makePayload, onSubmit }: Props<Fields, Payload, Errors>
) {
  const [formErrors, setFormErrors] = React.useState({} as Errors);

  const submitForm = () => {
    if (loading) {
      return;
    }

    const validationErrors = validate(fields);
    setFormErrors(validationErrors);

    if (Object.keys(validationErrors).length) {
      return;
    }

    const payload = makePayload(fields);

    if (!payload) {
      return;
    }

    onSubmit(payload);
  };

  return { formErrors, submitForm };
}
