import React, { Component, ReactChild } from 'react';
import { ListTable as ElmoListTable, Text } from 'elmo-elements';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import {
  EmployeeTimesheetsWithPagerSelector,
  MyTimesheetSort,
  MyTimesheetSortFields,
  SortPayload,
} from 'state/EmployeeDashboard/MyTimesheet/types';
import {
  employeeTimesheetsWithPagerSelector,
  getSort,
} from 'state/EmployeeDashboard/MyTimesheet/selectors';
import {
  AccountTreeArea,
  AccountTreeRole,
  AccountTreeSelector,
  AccountTreeSite,
  EmployeeTimesheet,
  LanguagePreferences,
  TimesheetBreak,
} from 'type';
import { getAccountTree } from 'state/AccountTree';
import { getLangPreferences, timeFormatSelector } from 'state/Account';
import {
  capitalize,
  formatMinutesAsHoursMinutes,
  onSortCreator,
} from 'lib/helpers';
import { sum } from 'lodash';
import {
  AssignmentLateOutlinedIcon,
  CheckOutlinedIcon,
  NotesIcon,
  SimpleBadge,
} from 'element';
import { BOX_MY_TIMESHEET_SORT_REQUEST } from 'state/EmployeeDashboard/MyTimesheet/actions';
import WithAccountPreferences from 'element/WithAccountPreferences';
import { DSTIcon } from 'element/DSTIcon';
import { isAppMarket } from 'helpers';

type OwnProps = {};

type StateProps = {
  data: EmployeeTimesheetsWithPagerSelector;
  tree: AccountTreeSelector;
  langPreferences: LanguagePreferences;
  timeFormat: string;
  sort: MyTimesheetSort;
};

type DispatchProps = {
  onSort: (payload: SortPayload) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const COLUMNS: { [key in MyTimesheetSortFields]: number } = {
  start: 0,
  duration: 4,
};

export class ListTableComponent extends Component<Props> {
  render() {
    let { data, sort } = this.props;
    return (
      <WithAccountPreferences>
        <ElmoListTable
          data={data.page}
          sortColumn={COLUMNS[sort.column]}
          sortDirection={sort.direction}
          onSort={this.onSort}
        >
          <ElmoListTable.Column
            label="Shift"
            render={this.renderShiftColumn}
            sortable={true}
          />
          <ElmoListTable.Column
            label="Status"
            render={this.renderStatusColumn}
          />
          <ElmoListTable.Column
            label={isAppMarket('uk') ? 'Lunch break' : 'Meal break'}
            render={this.renderMealBreakColumn}
          />
          <ElmoListTable.Column
            label={isAppMarket('uk') ? 'Break' : 'Rest break'}
            render={this.renderRestBreak}
          />
          <ElmoListTable.Column
            label="Hours"
            render={this.renderHours}
            sortable={true}
          />
          <ElmoListTable.Column
            label={this.getRoleAreaLabels()}
            render={this.renderRoleAreaSite}
          />
        </ElmoListTable>
      </WithAccountPreferences>
    );
  }

  getRoleAreaLabels = () => {
    const { langPreferences } = this.props;
    return `${capitalize(langPreferences.role.singular)} - ${
      langPreferences.area.singular
    }, ${langPreferences.site.singular}`;
  };

  private renderShiftColumn = ({
    start,
    end,
    is_dst_intersect,
    notes,
  }: EmployeeTimesheet): ReactChild => {
    const { timeFormat } = this.props;

    return (
      <div>
        <div>{start.format('ddd D MMMM YYYY')}</div>
        <div>
          {start.format(timeFormat)} - {!end ? '...' : end.format(timeFormat)}
          <DSTIcon isDST={is_dst_intersect} offsetLeft={2} />
        </div>
        {!end && (
          <Text size="sm" color="gray">
            Currently in progress
          </Text>
        )}
        <NotesIcon
          notes={notes}
          id={'timesheet-notes-icon'}
        />
      </div>
    );
  };

  private renderStatusColumn = ({
    is_approved,
  }: EmployeeTimesheet): ReactChild => {
    return is_approved ? (
      <SimpleBadge
        type="success"
        icon={<CheckOutlinedIcon />}
        label={'Approved'}
      />
    ) : (
      <SimpleBadge
        type="warning"
        icon={<AssignmentLateOutlinedIcon />}
        label={'Pending'}
      />
    );
  };

  private renderMealBreakColumn = ({ breaks }: EmployeeTimesheet): ReactChild =>
    this.formatDuration('unpaid', breaks);

  private renderRestBreak = ({ breaks }: EmployeeTimesheet): ReactChild =>
    this.formatDuration('paid', breaks);

  private formatDuration = (
    type: 'paid' | 'unpaid',
    breaks: TimesheetBreak[]
  ): string => {
    const paidValueShouldBe: boolean = type === 'paid';

    const durations: number[] = breaks
      .filter(({ paid }: TimesheetBreak) => paidValueShouldBe === paid)
      .map(({ duration }: TimesheetBreak) => duration);

    const durationSum = sum(durations);

    return formatMinutesAsHoursMinutes(durationSum);
  };

  private renderHours = ({ duration }: EmployeeTimesheet) =>
    formatMinutesAsHoursMinutes(duration);

  private renderRoleAreaSite = ({
    role_id,
    area_id,
    site_id,
  }: EmployeeTimesheet): ReactChild => {
    const {
      tree: { roles, areas, sites },
    } = this.props;

    const role: AccountTreeRole | undefined = roles[role_id];
    const area: AccountTreeArea | undefined = areas[area_id];
    const site: AccountTreeSite | undefined = sites[site_id];

    if (role && area && site) {
      return `${role.name} - ${area.name}, ${site.name}`;
    }

    return '';
  };

  private onSort = onSortCreator(this.props.onSort, COLUMNS);
}

const mapStateToProps = (state: StoreState): StateProps => ({
  data: employeeTimesheetsWithPagerSelector(state),
  tree: getAccountTree(state),
  langPreferences: getLangPreferences(state),
  timeFormat: timeFormatSelector(state),
  sort: getSort(state),
});

const mapDispatchToProps: DispatchProps = {
  onSort: BOX_MY_TIMESHEET_SORT_REQUEST,
};

export const ListTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListTableComponent);
