import React, { ReactText } from 'react';
import {
  Select,
  FormContainer,
  FormItem,
  RadioButtons,
  Radio,
  Row,
  Col,
} from 'elmo-elements';
import { Data, DropDownProps } from '../type';
import { connect, useSelector } from 'react-redux';
import {
  orderDropDownOptionsRoster,
  groupDropDownOptionsRoster,
} from 'state/Account/selectors';
import { getSelectedValue } from '../helpers';

export type DataProps = {
  handleOnChangeSelect: (e: DropDownProps, name: string) => void;
  handleOnChangeTimeFormat: (value: ReactText) => void;
  data: Data;
};

const RosterExtraGroup = ({
  data,
  handleOnChangeTimeFormat,
  handleOnChangeSelect,
}: DataProps) => {
  const orderDropDown = useSelector(orderDropDownOptionsRoster);
  const groupDropDown = useSelector(groupDropDownOptionsRoster);

  return (
    <FormContainer maxColumns={3} className="flex-start-align">
      <FormItem label={'Grouped by'} isFluid={false}>
        <Select
          name={'group_by'}
          options={groupDropDown}
          onChange={(e) => handleOnChangeSelect(e, 'group_by')}
          defaultValue={getSelectedValue(groupDropDown, data.group_by)}
          value={getSelectedValue(groupDropDown, data.group_by)}
          ariaLabel="Group by"
        />
      </FormItem>
      <FormItem label={'Ordered by'} isFluid={false}>
        <Select
          name={'order_by'}
          options={orderDropDown}
          onChange={(e) => handleOnChangeSelect(e, 'order_by')}
          defaultValue={getSelectedValue(orderDropDown, data.order_by)}
          value={getSelectedValue(orderDropDown, data.order_by)}
          ariaLabel="Ordered by"
        />
      </FormItem>
      <FormItem isFluid={false}>
        <Row isNoGutters={true} role="presentation" className="row-two-cols">
          <Col span={12} role="presentation" className={'form-item-small'}>
            <FormItem label={'Time format'} isFluid={false} isInline={true}>
              <RadioButtons
                selected={data.time_format}
                onChange={handleOnChangeTimeFormat}
                type={'outlined'}
                direction={'horizontal'}
              >
                <Radio value={'HH:mm'}>HH:MM</Radio>
                <Radio value={'Decimal'}>Decimal</Radio>
              </RadioButtons>
            </FormItem>
          </Col>
        </Row>
      </FormItem>
    </FormContainer>
  );
};

export default RosterExtraGroup;
