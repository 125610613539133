import React from 'react';

import './style.scss';

type Props = {
  children: React.ReactChild,
};

export default function SummaryDays({children}: Props) {
  return <div className="summary-days">
      <div className="summary-days__content">
        {children}
      </div>
  </div>;
}
