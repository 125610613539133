import { SagaIterator } from 'redux-saga';
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { DeleteInstancePayload, SagaAction, ShiftSwap } from 'type';
import {
  shiftSwapProposalApproved,
  shiftSwapProposalApproveError,
  shiftSwapProposalDeclined,
  shiftSwapProposalDeclineError,
} from 'messages';
import { Api } from 'lib/Api';
import {
  ManagerDashboardApproveOrDeclineSwapProposalRequest,
  ManagerDashboardGetShiftSwapsResponse,
} from 'lib/Api/type';
import { getRotaUserListRequest } from 'state/UsersCollection';
import {
  BOX_TOAST_NOTIFIER_MESSAGE_SHOW,
  showUndoNotification,
} from 'state/ToastNotifier';
import {
  BOX_FETCH_PAGE_DATA_FAILURE,
  BOX_FETCH_PAGE_DATA_REQUEST,
  BOX_FETCH_PAGE_DATA_SUCCESS,
  getFetchFlags,
  MANAGER_DASHBOARD_SHIFT_SWAPS,
} from 'state/FetchPageData';
import { processApiRequest } from 'state/ProcessApiRequest/sagas';
import {
  managerDashboardFiltersSelector,
  ManagerDashboardFilters,
  BOX_MANAGER_DASHBOARD_CLEAR_ERRORS,
} from '../../ManagerDashboard';
import { ApproveDeclinePayload } from '../type';
import * as actions from '../actions';
import { getSwapById } from '../selectors';
import moment from 'moment';

export const getShiftSwapsRequest = function* (): SagaIterator {
  yield put(BOX_MANAGER_DASHBOARD_CLEAR_ERRORS());

  const filters: ManagerDashboardFilters = yield select(
    managerDashboardFiltersSelector
  );

  const response: ManagerDashboardGetShiftSwapsResponse = yield call(
    processApiRequest,
    Api.ManagerDashboard.getShiftSwaps,
    filters
  );

  yield put(actions.BOX_SHIFT_SWAPS_GET_SHIFT_SWAPS_SUCCESS(response));
};

const getPageData = function* (): SagaIterator {
  yield put(BOX_FETCH_PAGE_DATA_REQUEST(MANAGER_DASHBOARD_SHIFT_SWAPS));
  try {
    yield all([call(getShiftSwapsRequest), call(getRotaUserListRequest)]);
    yield put(BOX_FETCH_PAGE_DATA_SUCCESS(MANAGER_DASHBOARD_SHIFT_SWAPS));
  } catch (error) {
    yield put(BOX_FETCH_PAGE_DATA_FAILURE(MANAGER_DASHBOARD_SHIFT_SWAPS));
  }
};

const declineShiftSwapProposal = function* ({
  payload,
}: SagaAction<ApproveDeclinePayload>): SagaIterator {
  const { hide } = yield call(showUndoNotification, shiftSwapProposalDeclined);

  if (hide) {
    try {
      const apiPayload: ManagerDashboardApproveOrDeclineSwapProposalRequest =
        payload;

      yield call(
        processApiRequest,
        Api.ManagerDashboard.declineShiftSwapProposal,
        apiPayload
      );
      yield put(actions.BOX_SHIFT_SWAPS_DECLINE_SUCCESS(payload));
    } catch (error) {
      yield put(
        BOX_TOAST_NOTIFIER_MESSAGE_SHOW({
          message: shiftSwapProposalDeclineError,
          type: 'danger',
        })
      );
    }
  }

  yield put(actions.BOX_SHIFT_SWAPS_DECLINE_FINISHED(payload));
};

const approveShiftSwapProposal = function* ({
  payload,
}: SagaAction<ApproveDeclinePayload>): SagaIterator {
  const { hide } = yield call(showUndoNotification, shiftSwapProposalApproved);
  if (hide) {
    try {
      const swap = yield select(getSwapById, payload.id);
      const apiPayload: ManagerDashboardApproveOrDeclineSwapProposalRequest =
        payload;
      yield call(
        processApiRequest,
        Api.ManagerDashboard.approveShiftSwapProposal,
        apiPayload
      );

      yield put(actions.BOX_SHIFT_SWAPS_APPROVE_SUCCESS(payload));
    } catch (error) {
      yield put(
        BOX_TOAST_NOTIFIER_MESSAGE_SHOW({
          message: shiftSwapProposalApproveError,
          type: 'danger',
        })
      );
    }
  }

  yield put(actions.BOX_SHIFT_SWAPS_APPROVE_FINISHED(payload));
};

const updateSwap = function* ({
  payload,
}: SagaAction<ShiftSwap>): SagaIterator {
  const { isFetching, isFetched }: ReturnType<typeof getFetchFlags> =
    yield select(getFetchFlags, 'MANAGER_DASHBOARD_SHIFT_SWAPS');

  if (!isFetching && isFetched) {
    yield put(actions.BOX_SHIFT_SWAPS_UPDATE_SWAP(payload));
  }
};

const deleteSwap = function* ({
  payload,
}: SagaAction<DeleteInstancePayload>): SagaIterator {
  const { isFetching, isFetched }: ReturnType<typeof getFetchFlags> =
    yield select(getFetchFlags, 'MANAGER_DASHBOARD_SHIFT_SWAPS');

  if (!isFetching && isFetched) {
    yield put(actions.BOX_SHIFT_SWAPS_DELETE_SWAP(payload));
  }
};

export const watchShiftSwaps = function* (): SagaIterator {
  yield takeLatest(actions.BOX_SHIFT_SWAPS_DATA_REQUEST, getPageData);
  yield takeLatest(actions.BOX_SHIFT_SWAPS_SWAP_UPDATED, updateSwap);
  yield takeLatest(actions.BOX_SHIFT_SWAPS_SWAP_DELETED, deleteSwap);
  yield takeEvery(
    actions.BOX_SHIFT_SWAPS_DECLINE_REQUEST,
    declineShiftSwapProposal
  );
  yield takeEvery(
    actions.BOX_SHIFT_SWAPS_APPROVE_REQUEST,
    approveShiftSwapProposal
  );
};
