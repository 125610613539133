import React, { Component, FormEvent, MouseEvent, ReactFragment } from 'react';
import { first, intersection, uniq } from 'lodash';
import moment, { Moment } from 'moment';
import { Col, FormItem, Row, TextArea } from 'elmo-elements';
import { SelectPropsOption } from 'elmo-elements/Select';
import {
  AccessTimeOutlinedIcon,
  CachedIcon,
  CalendarTodayOutlinedIcon,
  CreateOutlinedIcon,
  DateInputMoment,
  ErrorBox,
  PageDialog,
  PageDialogCancel,
  PageDialogCloseIconButton,
  PageDialogSubmit,
  PersonOutlineIcon,
  StatusIcon,
  TimeInput,
  TodayOutlinedIcon,
} from 'element';
import { AccountTreeSite, UserFields, UserRole } from 'type';
import { keyMirror, capitalize } from 'lib/helpers';
import { OnChangeSelectEvent } from 'element/Select/types';
import { CreateTimeOffPayload } from 'state/ManagerDashboard/Unavailability';
import { siteIdsToSelectOptionsCombiner } from 'state/AccountTree';
import { Props, ViewState } from './types';
import { durationOptions, repeatOptions, statusOptions } from './config';
import { createUntilDate, getDefaultDates } from './helpers';
import { getState, getSubmitPayload } from './transformData';
import { ApplicableUsersLoader } from './ApplicableUsersLoader';
import { disableDatesBeforeCurrent } from 'helpers';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem
} from 'extended-oxygen-elements';

class UnavailabilityCreateEditModalView extends Component<Props, ViewState> {
  readonly state = getState(this.props);
  readonly names = keyMirror(this.state.data);

  render() {
    const {
      state: { data },
      names,
    } = this;

    return (
      <PageDialog
        maxWidth={'xs'}
        open={this.props.isOpened}
        id="add-unavailability-modal"
        onClose={this.onCloseModal}
      >
        <DialogTitle>
          {this.props.title}
          <PageDialogCloseIconButton onClose={this.onCloseModal} />
        </DialogTitle>
        <DialogContent>
          <ApplicableUsersLoader>
            <form onSubmit={this.onSubmit}>
              <ErrorBox
                errors={this.props.errors}
                clearErrors={() => this.props.clearErrors()}
              />

              <FormItem icon={<PersonOutlineIcon />}>
                <Select
                  id="create-edit-modal-user-id-select"
                  fullWidth
                  name={names.user_id}
                  onChange={(e) => {
                    return this.onUserSelectChange(e.target)
                  }}
                  value={data.user_id}
                  aria-label={'User name'}
                  displayEmpty={true}
                  renderValue={() => data.user_name === '' ? 'Username' : data.user_name}
                  className={data.user_name ? '' : 'select-placeholder'}
                >
                  {this.props.userOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>)
                  )}
                </Select>
              </FormItem>

              <FormItem icon={<CalendarTodayOutlinedIcon />} isDivider={true}>
                <Select
                  id="create-edit-modal-duration-select"
                  fullWidth
                  name={names.duration}
                  onChange={e => {this.onDurationChange(e.target)}}
                  value={data.duration}
                  aria-label={'Duration'}
                >
                  {durationOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>)
                    )
                  }
                </Select>
              </FormItem>

              {this.durationMarkup}

              <FormItem icon={<CachedIcon />}>
                <Select
                  id="create-edit-modal-repeat-select"
                  fullWidth
                  name={names.repeat}
                  onChange={e => {this.onRepeatChange(e.target)}}
                  value={data.repeat}
                  aria-label={'Repeat'}
                >
                  {repeatOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>)
                    )
                  }
                </Select>
              </FormItem>

              <FormItem icon={<></>} label="Until" isDivider={true}>
                <DateInputMoment
                  id="create-edit-modal-until-input"
                  name={names.until}
                  value={data.until}
                  onChange={this.onChangeDateInput}
                  isClearable={false}
                  disabledDate={this.disabledUntilDates}
                  isDisabled={data.repeat === 'once'}
                  ariaLabel="Until"
                />
              </FormItem>

              <FormItem icon={<AccessTimeOutlinedIcon />}>
                <Select
                  id="create-edit-modal-site-id-select"
                  fullWidth
                  name={names.site_id}
                  onChange={e => {this.onSiteSelectChange(e.target)}}
                  value={data.site_id}
                  aria-label={'Site id'}
                  disabled={!data.user_id.length}
                  displayEmpty={true}
                  renderValue={() => data.site_name === '' ? capitalize(this.props.langPreferences.site.singular) : data.site_name}
                  className={data.site_name ? '' : 'select-placeholder'}
                >
                  {this.userSitesOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>)
                    )
                  }
                </Select>
              </FormItem>

              <FormItem icon={<StatusIcon />}>
                <Select
                  id="create-edit-modal-status"
                  fullWidth
                  name={names.status}
                  onChange={e => {this.onSelectChange(e.target)}}
                  value={data.status}
                  aria-label={'Status'}
                  placeholder={this.props.langPreferences.site.singular}
                >
                  {statusOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>)
                    )
                  }
                </Select>
              </FormItem>

              <FormItem icon={<CreateOutlinedIcon />}>
                <TextArea
                  id="create-edit-modal-description"
                  name={names.description}
                  value={data.description}
                  onChange={this.onInputChange}
                  placeholder="Description"
                  ariaLabel="Description"
                />
              </FormItem>
            </form>
          </ApplicableUsersLoader>
        </DialogContent>
        <DialogActions sx={{ boxShadow: '0 0 6px 2px #ccc', zIndex: 3 }}>
          <PageDialogCancel onClick={this.onCloseModal}>
            Cancel
          </PageDialogCancel>
          <PageDialogSubmit
            onClick={this.onSubmit}
            loading={this.props.isUpdating}
            disabled={!this.submitPayload}
            id={'submit-btn'}
          >
            {this.props.submitLabel}
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<ViewState>,
    snapshot?: any
  ): void {
    if (this.props.isOpened && prevProps.isOpened !== this.props.isOpened) {
      this.props.getApplicableUsers();
      this.setState(getState(this.props));
    }
  }

  private get durationMarkup(): ReactFragment {
    switch (this.state.data.duration) {
      case 'all_day':
        return this.allDayDurationMarkup;
      case 'few_hours':
        return this.fewHoursDurationMarkup;
      case 'multiple_days':
        return this.multipleDaysDurationMarkup;
    }
  }

  private get allDayDurationMarkup(): ReactFragment {
    const {
      names,
      state: { data },
    } = this;

    return (
      <>
        <FormItem icon={<TodayOutlinedIcon />} label="Start date">
          <DateInputMoment
            id="create-edit-modal-all-day-start-date"
            name={names.start_date}
            value={data.start_date}
            onChange={this.onChangeStartEndDateTogether}
            isClearable={false}
            isReadOnly={true}
            disabledDate={disableDatesBeforeCurrent}
            ariaLabel="Start date"
          />
        </FormItem>

        <FormItem icon={<></>} label="End date" isDivider={true}>
          <DateInputMoment
            id="create-edit-modal-all-day-end-date"
            name={names.end_date}
            value={data.end_date}
            onChange={this.doNothing}
            isClearable={false}
            isDisabled={true}
            ariaLabel="End date"
          />
        </FormItem>
      </>
    );
  }

  private get fewHoursDurationMarkup(): ReactFragment {
    const {
      names,
      state: { data },
    } = this;
    return (
      <>
        <FormItem icon={<TodayOutlinedIcon />} label="Start date">
          <Row role="presentation">
            <Col span={24 / 2} role="presentation">
              <DateInputMoment
                id="create-edit-modal-few-hours-start-date"
                name={names.start_date}
                value={data.start_date}
                onChange={this.onChangeStartEndDateTogether}
                isClearable={false}
                disabledDate={disableDatesBeforeCurrent}
                ariaLabel="Start date"
                isReadOnly={true}
              />
            </Col>

            <Col span={24 / 2} role="presentation">
              <div id="create-edit-modal-few-hours-start-time">
                <TimeInput
                  name={names.start_time}
                  value={data.start_time}
                  onChange={this.onChangeTimeInput}
                  id="start-time"
                  label="Start time"
                />
              </div>
            </Col>
          </Row>
        </FormItem>

        <FormItem icon={<></>} label="End date" isDivider={true}>
          <Row role="presentation">
            <Col span={24 / 2} role="presentation">
              <DateInputMoment
                id="create-edit-modal-few-hours-end-date"
                name={names.end_date}
                value={data.end_date}
                onChange={this.doNothing}
                isClearable={false}
                disabledDate={disableDatesBeforeCurrent}
                isReadOnly={true}
                isDisabled={true}
                ariaLabel="End date"
              />
            </Col>

            <Col span={24 / 2} role="presentation">
              <div id="create-edit-modal-few-hours-end-time">
                <TimeInput
                  name={names.end_time}
                  value={data.end_time}
                  onChange={this.onChangeTimeInput}
                  id="end-time"
                  label="End time"
                />
              </div>
            </Col>
          </Row>
        </FormItem>
      </>
    );
  }

  private get multipleDaysDurationMarkup(): ReactFragment {
    const {
      names,
      state: { data },
    } = this;
    return (
      <>
        <FormItem icon={<TodayOutlinedIcon />} label="Start date">
          <DateInputMoment
            id="create-edit-modal-multiple-days-start-date"
            name={names.start_date}
            value={data.start_date}
            onChange={this.onChangeDateInput}
            isClearable={false}
            disabledDate={this.disabledStartDates && disableDatesBeforeCurrent}
            ariaLabel="Start date"
          />
        </FormItem>

        <FormItem icon={<></>} label="End date" isDivider={true}>
          <DateInputMoment
            id="create-edit-modal-multiple-days-end-date"
            name={names.end_date}
            value={data.end_date}
            onChange={this.onChangeEndDateInput}
            isClearable={false}
            disabledDate={this.disabledEndDates}
            ariaLabel="End date"
          />
        </FormItem>
      </>
    );
  }

  private get userSitesOptions(): SelectPropsOption[] {
    const { currentUserSiteIds, canViewAllSites, users, sites } = this.props;

    const selectedUser: UserFields | undefined = users[this.state.data.user_id];

    if (!selectedUser) {
      return [];
    }

    const userSiteIds: string[] = uniq(
      selectedUser.user_roles.map((userRole) => userRole.site_id)
    );

    const filteredSiteIds = canViewAllSites
      ? userSiteIds
      : intersection(currentUserSiteIds, userSiteIds);

    return siteIdsToSelectOptionsCombiner(filteredSiteIds, sites);
  }

  private get selectedSiteTimezone(): string {
    const {
      props: { sites },
      state: {
        data: { site_id },
      },
    } = this;

    const site: AccountTreeSite | undefined = sites[site_id];

    if (site) {
      return site.timezone_id;
    }

    return moment.tz.guess();
  }

  private get currentTimeInSelectedSiteTimezone(): Moment {
    const {
      props: { currentTime },
    } = this;

    return currentTime.clone().tz(this.selectedSiteTimezone);
  }

  private get submitPayload(): CreateTimeOffPayload | undefined {
    return getSubmitPayload(this.state.data);
  }

  private onSubmit = (
    event: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    if (this.submitPayload) {
      this.props.onFormSubmit(this.submitPayload);
    }
  };

  private onCloseModal = () => {
    if (!this.props.isUpdating) {
      this.props.onCloseModal();
    }
  };

  private getUntilDate = (endDate: Moment): Moment =>
    createUntilDate(endDate, this.state.data.until);

  private doNothing = () => void 0;

  private onSelectChange = ({ name, value }: OnChangeSelectEvent) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  };

  private onRepeatChange = ({ name, value }: OnChangeSelectEvent) => {
    const { until } = this.state.data;

    const newState: ViewState = {
      data: {
        ...this.state.data,
        [name]: value,
      },
    };

    if (until.isSameOrBefore(this.currentTimeInSelectedSiteTimezone, 'day')) {
      newState.data.until = this.currentTimeInSelectedSiteTimezone
        .clone()
        .startOf('day')
        .add(1, 'day');
    }

    this.setState(newState);
  };

  private onUserSelectChange = ({ name, value }: OnChangeSelectEvent) => {
    const { users } = this.props;
    const user = users[value];

    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
        user_name: user.username,
        site_id: this.getMainOrAvailableSiteId(value),
      },
    });
  };

  private getMainOrAvailableSiteId = (userId: string): string => {
    const { users, currentUserSiteIds } = this.props;

    const user: UserFields = users[userId];
    const mainRoleSiteId: string = user.main_role.site_id;

    if (currentUserSiteIds.includes(mainRoleSiteId)) {
      return mainRoleSiteId;
    }

    const userSiteIds: string[] = user.user_roles.map(
      ({ site_id }: UserRole): string => site_id
    );

    const availableSiteIds: string[] = intersection(
      currentUserSiteIds,
      userSiteIds
    );

    return first(availableSiteIds) || '';
  };

  private onDurationChange = ({ name, value }: OnChangeSelectEvent) => {
    this.setState({
      data: {
        ...this.state.data,
        ...getDefaultDates(),
        [name]: value,
      },
    });
  };

  private onInputChange = ({
    currentTarget: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  };

  private onChangeDateInput = (value: Moment | null, name: string) => {
    const { end_date } = this.state.data;
    let endDateValue = end_date.clone();
    if (name === 'start_date' && value && value.isAfter(end_date)) {
      endDateValue = value.clone().add(1, 'day');
    }
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
        end_date: endDateValue,
      },
    });
  };

  private onChangeEndDateInput = (value: Moment | null, name: string) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
        until: this.getUntilDate(value!),
      },
    });
  };

  private onChangeTimeInput = (value: Moment, name: string) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  };

  private onChangeStartEndDateTogether = (
    value: Moment | null,
    name: string
  ) => {
    const { data } = this.state;
    const newDate: Moment = value!.tz(this.selectedSiteTimezone, true);
    this.setState({
      data: {
        ...data,
        start_date: moment(newDate),
        end_date: moment(newDate),
        until: this.getUntilDate(newDate),
        start_time: newDate.clone().set({
          hour: data.start_time?.hour(),
          minute: data.start_time?.minute(),
        }),
        end_time: newDate.clone().set({
          hour: data.end_time?.hour(),
          minute: data.end_time?.minute(),
        }),
      },
    });
  };

  private disabledStartDates = (current?: Date): boolean => {
    if (current) {
      return this.state.data.end_date.isBefore(
        moment(current).startOf('day').toDate()
      );
    }

    return true;
  };

  private disabledEndDates = (current?: Date): boolean => {
    if (current) {
      return this.state.data.start_date.isAfter(current);
    }

    return true;
  };

  private disabledUntilDates = (currentValue?: Date): boolean => {
    const currentValueMoment = moment(currentValue).tz(
      this.selectedSiteTimezone,
      true
    );

    return currentValueMoment
      .startOf('day')
      .isBefore(this.currentTimeInSelectedSiteTimezone.endOf('day'));
  };

  private onSiteSelectChange = ({ name, value }: OnChangeSelectEvent) => {
    const { sites } = this.props;
    const site = sites[value];

    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
        site_name: site.name
      },
    });
  };
}

export default UnavailabilityCreateEditModalView;
