import { EnvTuple, MarketTuple } from './type';

export default function envValidator() {
  [
    () => {
      const envs: EnvTuple = [
        'development',
        'staging',
        'pentest',
        'production',
      ];
      return envs.includes(process.env.REACT_APP_ENV as any);
    },
    () => {
      const markets: MarketTuple = ['au', 'uk'];
      return markets.includes(process.env.REACT_APP_MARKET as any);
    },
    // logout api call
    () => !!process.env.REACT_APP_SSO_API_URL,
    // () => !!process.env.REACT_APP_SBS_URL,
    // app switcher / redirect to breathe hr app
    () => !!process.env.REACT_APP_BREATHE_HR_URL,
    // app switcher / redirect to punchclock app
    () => !!process.env.REACT_APP_PUNCHCLOCK_URL,
    // deleting domain specific cookies on log out
    () => !!process.env.REACT_APP_DOMAIN,
    // main menu / redirect to help pages
    () => !!process.env.REACT_APP_HELP_URL,
  ].forEach((validator) => {
    if (!validator()) {
      throw new Error('required env is not provided');
    }
  });
}
