import { NotificationGroup } from 'type';
import { StoreState } from 'state/types';
import { EditModal, SingleNotification, TimeModal } from './type';

export const getIsFetching = (store: StoreState): boolean => store.notification.isFetching;
export const getIsFetched = (store: StoreState): boolean => store.notification.isFetched;
export const getNotificationErrors = (store: StoreState): string[] => store.notification.errors;
export const getEditModal = (store: StoreState): EditModal => store.notification.editModal;
export const getTimeModal = (store: StoreState): TimeModal => store.notification.timeModal;
export const getNotification = (store: StoreState): SingleNotification => store.notification.notification;
export const getGroup = (store: StoreState): NotificationGroup => store.notification.notification.group;
export const getNotificationsSelector = (store: StoreState) => store.notification.notification.notifications;
export const getMessageSelector = (store: StoreState) => store.notification.message;
export const getHoursSelector = (store: StoreState) => store.notification.notification.group.hours as number;
