import React from 'react';
import { AutoApproveShifts, FieldGroupProps, TimesheetSettings } from 'type';
import {
  Box,
  MenuItem,
  TextField,
  TextFieldProps,
  Typography,
} from 'extended-oxygen-elements';
import { BreakRow } from '../../../components';
import useSetFormField from 'hooks/form-hooks/useSetFormField';

type AddingTimesheetsFieldGroupValue = Pick<
  TimesheetSettings,
  'auto_approve_shifts'
>;
type AddingTimesheetsFieldGroupErrors = {};

type Props = FieldGroupProps<
  AddingTimesheetsFieldGroupValue,
  AddingTimesheetsFieldGroupErrors
>;

const autoApproveOptions = [
  {
    label: 'Auto-approve all timesheets',
    value: 'all-shifts',
  },
  {
    label: 'Auto-approve all timesheets without violations',
    value: 'without-violation',
  },
  {
    label:
      'Auto-approve all timesheets without violations (excluding timesheets not linked to a shift)',
    value: 'except-self-assigned',
  },
  {
    label: 'Manager must approve all timesheets',
    value: 'none',
  },
];

export default function ApprovingTimesheetsFieldGroup({
  onChange,
  values,
}: Props) {
  const { setFormField } = useSetFormField(onChange);
  const handleAutoApproveChange: TextFieldProps['onChange'] = (event) => {
    setFormField(
      'auto_approve_shifts',
      event.target.value as AutoApproveShifts
    );
  };

  return (
    <>
      <Typography variant="h4" component="h2">
        Approving timesheets
      </Typography>

      <Box maxWidth={550}>
        <BreakRow>
          <BreakRow.Label id={'auto-approve'} noWrap={false}>
            Choose how you would like timesheets to be approved:
          </BreakRow.Label>

          <BreakRow.Field>
            <TextField
              select
              margin="none"
              value={values.auto_approve_shifts}
              onChange={handleAutoApproveChange}
              inputProps={{
                'aria-labelledby': 'Auto approve timesheets',
              }}
            >
              {autoApproveOptions.map((autoApproveOption) => (
                <MenuItem
                  value={autoApproveOption.value}
                  key={autoApproveOption.value}
                >
                  {autoApproveOption.label}
                </MenuItem>
              ))}
            </TextField>
          </BreakRow.Field>
        </BreakRow>
      </Box>
    </>
  );
}
