import React from 'react';
import clsx from 'clsx';
import {
  ButtonBase,
  ButtonBaseProps,
  lighten,
  Theme,
} from 'extended-oxygen-elements';
import { makeStyles, createStyles } from '@mui/styles';
import { DefaultDivProps } from 'type';
import { capitalize } from 'lib/helpers';

type TimesheetCardWrapperProps = DefaultDivProps & {
  children: React.ReactNode;
  status: 'pending' | 'approved' | 'pendingViolated' | 'inProgress' | undefined;
  interactive?: boolean;
  highlighted?: boolean;
};

const useStyles = makeStyles((theme: Theme) => {
  const borderWidth = 1;

  return createStyles({
    root: {
      height: '100%',
      borderRadius: theme.shape.borderRadiusLarge,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth,
      borderStyle: 'solid',
      position: 'relative',
      '&::after': {
        content: '""',
        borderRadius: theme.shape.borderRadiusLarge,
        boxShadow: theme.shadows[10],
        pointerEvents: 'none',
        position: 'absolute',
        top: -borderWidth,
        right: -borderWidth,
        bottom: -borderWidth,
        left: -borderWidth,
        opacity: 0,
        transition: theme.transitions.create('opacity', {
          duration: theme.transitions.duration.short,
        }),
      },
    },
    highlighted: {
      '&::after': {
        opacity: 1,
      },
    },
    inner: {
      width: '90%',
    },
    statusPending: {
      backgroundColor: lighten(theme.palette.warning.main, 0.8),
      borderColor: lighten(theme.palette.warning.main, 0.7),
      '&$highlighted': {
        backgroundColor: lighten(theme.palette.warning.main, 0.5),
        borderColor: lighten(theme.palette.warning.main, 0.4),
      },
    },
    statusApproved: {
      backgroundColor: lighten(theme.palette.success.main, 0.8),
      borderColor: lighten(theme.palette.success.main, 0.7),
      '&$highlighted': {
        backgroundColor: lighten(theme.palette.success.main, 0.5),
        borderColor: lighten(theme.palette.success.main, 0.4),
      },
    },
    statusPendingViolated: {
      backgroundColor: lighten(theme.palette.error.main, 0.8),
      borderColor: lighten(theme.palette.error.dark, 0.8),
      '&$highlighted': {
        backgroundColor: lighten(theme.palette.error.main, 0.5),
        borderColor: lighten(theme.palette.error.dark, 0.5),
      },
    },
    statusInProgress: {
      backgroundColor: lighten(theme.palette.info.main, 0.8),
      borderColor: lighten(theme.palette.info.main, 0.7),
      '&$highlighted': {
        backgroundColor: lighten(theme.palette.info.main, 0.5),
        borderColor: lighten(theme.palette.info.dark, 0.4),
      },
    },
  });
});

const ButtonDiv = React.forwardRef(function ButtonWrapper(
  props: ButtonBaseProps<'div'>,
  ref: React.Ref<HTMLDivElement>
) {
  return <ButtonBase {...props} component="div" ref={ref} />;
});

export const TimesheetCardWrapper = React.forwardRef(
  function TimesheetCardWrapper(
    {
      children,
      status,
      interactive = false,
      highlighted = false,
      ...restProps
    }: TimesheetCardWrapperProps,
    ref: React.Ref<HTMLDivElement>
  ) {
    const classes = useStyles();
    const Component = interactive ? ButtonDiv : 'div';

    return (
      <Component
        {...restProps}
        className={clsx([
          classes.root,
          status && classes[`status${capitalize(status)}` as const],
          highlighted && classes.highlighted,
          restProps.className,
        ])}
        ref={ref}
      >
        <div className={classes.inner}>{children}</div>
      </Component>
    );
  }
);
