import React, { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { IconButton } from 'extended-oxygen-elements';
import {
  CancelOutlinedIcon,
  CheckCircleOutlinedIcon,
  UserLogIcon
} from 'element';
import { Box } from 'extended-oxygen-elements';

type Option = {
  label: string;
  value: string;
};

type Props = {
  roles: Option[];
  handleAnchorClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleClickCancel: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export type DefaultButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
  >;

const SubmitButton = React.forwardRef<HTMLButtonElement, DefaultButtonProps>(
  (props, ref) => <button ref={ref} {...props} type="submit" />
);

export const Controls = ({ roles, handleAnchorClick, handleClickCancel }: Props) => {
  return (
    <Box textAlign="center">
      {
        roles.length && roles.length > 1
          ? <IconButton onClick={handleAnchorClick}>
            <UserLogIcon />
          </IconButton>
          : null
      }
      <Box component="span" color="success.main">
        <IconButton color="inherit" component={SubmitButton}>
          <CheckCircleOutlinedIcon />
        </IconButton>
      </Box>
      <Box component="span" color="error.main">
        <IconButton color="inherit" onClick={handleClickCancel}>
          <CancelOutlinedIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
