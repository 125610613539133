import React, { Component } from 'react';
import { Loader } from 'elmo-elements';

type Props = {
  isFetching: boolean;
  fetchData: (params?: any) => void;
  params?: any;
};

export class WithPreload extends Component<Props> {
  render(): React.ReactNode {
    const { isFetching, children } = this.props;

    return (
      <Loader type="spinner" isLoading={isFetching}>
        {children}
      </Loader>
    );
  }

  componentDidMount(): void {
    this.props.fetchData(this.props.params);
  }
}
