import { EditTemplateReducerState } from './types';

export const getDefaultState = (): EditTemplateReducerState => ({
  templateId: '',
  shifts: {},
  errors: [],
  siteId: '',
  title: '',
  editModal: {
    isOpened: false,
    errors: [],
    isDeleting: false,
    isLoading: false,
    isUpdating: false,
    payload: {
      id: null,
      day: 1,
      area_id: null,
      role_id: null,
      breaks: [],
      start: '00:00',
      end: '23:59',
      user_id: null,
      tags: [],
      notes: null,
    },
  },
  confirmDeletionModal: {
    isOpened: false,
    errors: [],
    isUpdating: false,
  },
  confirmDeletionTemplateModal: {
    isOpened: false,
    errors: [],
    isUpdating: false,
  },
  prevPage: null,
  draggableShift: null,
  dropInProgress: false,
  dropError: [],
  droppedShift: {},
  dropCanceled: false,
  confirmUpdateTemplateModal: {
    isOpened: false,
    errors: [],
    isUpdating: false,
  },
  confirmSaveAsTemplateModal: {
    isOpened: false,
    errors: [],
    isUpdating: false,
  },
  initialOwner: null,
});
