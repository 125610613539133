import { NOTES_MAX_LENGTH } from 'lib/config';

type NotesFieldValidatorErrors = { notes?: string };

export default notesFieldValidator;

export function notesFieldValidator(formFields: {
  notes: string | null;
}): NotesFieldValidatorErrors {
  let errors: { notes?: string } = {};

  if ((formFields.notes || '').length > NOTES_MAX_LENGTH) {
    errors.notes = `Must not be greater than ${NOTES_MAX_LENGTH} characters.`;
  }

  return errors;
}
