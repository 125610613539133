import { UserApprovalsReducerState } from './types';

export const getDefaultState = (): UserApprovalsReducerState => ({
  approvers: {
    isFetched: false,
    isFetching: false,
    errors: [],
    userIds: [],
    approvers: {
      leave: [],
      offer: [],
      swap: []
    }
  }
});
