import React from 'react';
import { Button, FormContainer, FormItem, Input, Select } from 'elmo-elements';
import { MoreHorizOutlinedIcon } from 'element/icons';
import { SelectPropsOption } from '../../type/models';
import { CreateUserPayload } from '../../state/Users/Users/types';

type Props = {
  isExpanded: boolean;
  handleFieldChange: (name: keyof CreateUserPayload, value: string | number) => void;
  handleAreaRoleChange: (option: SelectPropsOption) => void;
  toggleDetails: () => void;
  availableRoles: SelectPropsOption[];
  selectedRole: SelectPropsOption;
  selectedGroup: SelectPropsOption;
  defaultGroup: SelectPropsOption;
  userGroupsOptions: SelectPropsOption[];
};

export const View = ({
  isExpanded,
  handleFieldChange,
  toggleDetails,
  handleAreaRoleChange,
  availableRoles,
  selectedRole,
  selectedGroup,
  defaultGroup,
  userGroupsOptions
}: Props) => {
  const moreFields = () => {
    return (
      <>
        <FormItem label={'Position'}>
          <Select
            name={'position'}
            onChange={handleAreaRoleChange}
            options={availableRoles}
            defaultValue={availableRoles[0]}
            value={selectedRole}
          />
        </FormItem>
        <FormItem label={'Permissions'}>
          <Select
            name={'user_group_id'}
            onChange={e => handleFieldChange('user_group_id', +e.value)}
            options={userGroupsOptions}
            defaultValue={defaultGroup}
            value={selectedGroup}
          />
        </FormItem>
      </>
    );
  };

  const formContainer = () => {
    return (
      <FormContainer>
        <FormItem label={'First name'}>
          <Input
            name={'first_name'}
            onChange={e => handleFieldChange('first_name', e.target.value.trim())}
          />
        </FormItem>
        <FormItem label={'Last name'}>
          <Input
            name={'last_name'}
            onChange={e => handleFieldChange('last_name', e.target.value.trim())}
          />
        </FormItem>
        <FormItem label={'Email'}>
          <Input
            name={'email'}
            htmlType={'email'}
            onChange={e => handleFieldChange('email', e.target.value.trim())}
          />
        </FormItem>
        {
          !isExpanded &&
          <FormItem>
            <Button
              icon={<MoreHorizOutlinedIcon />}
              isText={true}
              ariaLabel={'More options'}
              onClick={toggleDetails}
            />
          </FormItem>
        }
        {
          isExpanded && moreFields()
        }
      </FormContainer>
    );
  };
  return formContainer();
}
