import React from 'react';
import { Box } from 'extended-oxygen-elements';
import { PopperBox, PopperBoxProps } from 'element';

export type ActionsWrapperProps = PopperBoxProps;

export const ActionsWrapper = React.forwardRef<
  HTMLDivElement,
  ActionsWrapperProps
>(function ActionsWrapper({ children, ...restProps }, ref) {
  return (
    <PopperBox {...restProps} ref={ref}>
      <Box py={0.5} px={1}>
        {children}
      </Box>
    </PopperBox>
  );
});
