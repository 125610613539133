import React from 'react';
import LoaderHolder from './LoaderHolder';
import { LoaderHolderProps } from '../type';
import './NarrowBulletListStyle.scss';

function NarrowBulletListStyle(props: LoaderHolderProps) {
  return (
    <LoaderHolder
      viewBoxHeight={300}
      className="elmo-loader-narrow-bullet"
      {...props}
    >
      <circle cx="14" cy="25" r="12" />
      <rect x="40" y="15" rx="5" ry="5" width="180" height="20" />
      <circle cx="14" cy="70" r="12" />
      <rect x="40" y="60" rx="5" ry="5" width="180" height="20" />
      <circle cx="14" cy="115" r="12" />
      <rect x="40" y="105" rx="5" ry="5" width="180" height="20" />
      <circle cx="14" cy="160" r="12" />
      <rect x="40" y="150" rx="5" ry="5" width="180" height="20" />
    </LoaderHolder>
  );
}

export default NarrowBulletListStyle;
