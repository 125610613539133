import React from 'react';
import { Select, FormContainer, Divider, FormItem } from 'elmo-elements';
import { Data, DropDownProps } from '../type';
import { getSelectedGroupedValues, getSelectedValues } from '../helpers';
import { getPreferenceLabel } from 'lib/helpers';
import { useSelector } from 'react-redux';
import { getLangPreferences } from 'state/Account';

export type DataProps = {
  handleOnChangeSelect2: (e: DropDownProps[], name: string) => void;
  data: Data;
  areasAsSelectOptionsArray: any;
  sitesAsSelectOptionsArray: any;
  handleOnChangeSites: (e: DropDownProps[]) => void;
};

const LocationAreaFilters = ({
  data,
  areasAsSelectOptionsArray,
  sitesAsSelectOptionsArray,
  handleOnChangeSites,
  handleOnChangeSelect2,
}: DataProps) => {
  const langPreferences = useSelector(getLangPreferences);

  const getSelectedOptions = (selected: string[], key: string) => {
    const getArray = () => {
      switch (key) {
        case 'sites':
          return sitesAsSelectOptionsArray;
        case 'areas':
          return areasAsSelectOptionsArray;
        default:
          break;
      }
      return [];
    };
    const params = getArray();
    if (key === 'areas') {
      return getSelectedGroupedValues(params, selected);
    }
    return getSelectedValues(params, selected);
  };

  return (
    <>
      <Divider />
      <br />
      <h3>Data</h3>
      <FormContainer maxColumns={3}>
        <FormItem
          label={getPreferenceLabel(
            langPreferences,
            'site',
            'plural',
            '',
            true
          )}
        >
          <Select
            options={sitesAsSelectOptionsArray}
            isMulti={true}
            isCreatable={false}
            hideSelectedOptions={true}
            onChange={(e) => handleOnChangeSites(e)}
            defaultValue={getSelectedOptions(data.sites, 'sites')}
            ariaLabel={getPreferenceLabel(
              langPreferences,
              'site',
              'plural',
              '',
              true
            )}
          />
        </FormItem>
        <FormItem
          label={getPreferenceLabel(
            langPreferences,
            'area',
            'plural',
            '',
            true
          )}
        >
          <Select
            options={areasAsSelectOptionsArray}
            isMulti={true}
            isCreatable={false}
            hideSelectedOptions={true}
            onChange={(e) => handleOnChangeSelect2(e, 'areas')}
            defaultValue={getSelectedOptions(data.areas, 'areas')}
            value={getSelectedOptions(data.areas, 'areas')}
            ariaLabel={getPreferenceLabel(
              langPreferences,
              'area',
              'plural',
              '',
              true
            )}
          />
        </FormItem>
      </FormContainer>
    </>
  );
};

export default LocationAreaFilters;
