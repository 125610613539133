import React from 'react';
import { useSelector } from 'react-redux';
import { Event } from 'type';
import { fromBySiteTimezoneSelector } from 'state/RosteredShifts';
import { EventCard } from 'page/Roster/components';
import { ResizableWrapper } from 'page/Roster/page/RosterDayView/components';

type Props = {
  event: Event;
};

export const DayEvent = ({ event }: Props) => {
  const from = useSelector(fromBySiteTimezoneSelector);
  return (
    <ResizableWrapper
      id={event.id}
      start={event.start}
      end={event.end}
      day={from}
      isResizable={false}
    >
      <EventCard eventId={event.id} hasAddButton={false} />
    </ResizableWrapper>
  );
};
