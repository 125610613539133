import _ from 'lodash';
import { BulkCreateActionData } from 'state/RosteredShifts/types';
import { RosteredShift } from 'type';
import { Moment } from 'moment';
import { updateRosterShiftBreaksOnDateChange } from 'lib/helpers';
import moment from 'moment-timezone';

// highlight cells from left to right and fill the object
export const fillFutureShifts = (rowGroup: Element, dayNum: number, numberOfSelectedDays: number) => {
  const filledCells: any = {};
  if ( rowGroup ) {
    const allCellsInGroup = rowGroup.querySelectorAll('.elmo-roster-cell');
    for (let index = dayNum; index < allCellsInGroup.length; index++) {
      const currentCell = allCellsInGroup[index];
      if ( index > dayNum && index < numberOfSelectedDays + dayNum ) {
        currentCell.classList.add('reserved-for-copy');
        filledCells[index] = index;
      } else {
        currentCell.classList.remove('reserved-for-copy');
        filledCells[index] = false;
      }
    }
  }
  return filledCells;
};

// highlight cells from right to left and fill the object
export const fillPrevShifts = (rowGroup: Element, dayNum: number, numberOfSelectedDays: number) => {
  const filledCells: any = {};
  if ( rowGroup ) {
    const allCellsInGroup = rowGroup.querySelectorAll('.elmo-roster-cell');
    for (let index = dayNum - 1; index >= 0; index--) {
      const currentCell = allCellsInGroup[index];
      if ( dayNum - index < numberOfSelectedDays ) {
        currentCell.classList.add('reserved-for-copy');
        filledCells[index] = index;
      } else {
        currentCell.classList.remove('reserved-for-copy');
        filledCells[index] = false;
      }
    }
  }
  return filledCells;
};

export const initResizer = (
  ele: HTMLDivElement,
  dispatchBulkCreate: (params: Partial<BulkCreateActionData>) => void,
  isHoldResizer: boolean,
  setStretchingShift: () => void
) => {
  let cellsArray: {} = {};
  const resizer: HTMLDivElement = ele;
  const parentCell: null | Element = resizer ? resizer.closest('.elmo-roster-cell') : null;
  const rowGroup = parentCell ? parentCell.closest('.week-rosters-grid__group--roster') : null;
  const dayNum = +(resizer as any).dataset.dayNum;

  let oneCellWidth = parentCell ? parentCell.getBoundingClientRect().width + 1 : -1;
  let resizerLeft: null | Element = null;
  let resizerRight: null | Element = null;

  // current position of cursor
  let x = 0;
  let y = 0;

  // The dimension of the element
  let w = 0;
  let h = 0;

  const mouseMoveHandler = (e: any) => {
    // How far the mouse has been moved
    const dx = e.clientX - x;

    if (resizer && parentCell) {
      const maxResizerWidth = oneCellWidth * 7 - dayNum * oneCellWidth;
      const resizeWidth = w + dx;

      // highlight cell from left to right and stop on the end of the last cell
      if ( resizerRight && resizeWidth <= maxResizerWidth + 8 && rowGroup ) {
        if (e.clientX > x) {
          const numberOfSelectedDays = Math.floor(resizeWidth / oneCellWidth);
          resizer.style.width = `${resizeWidth}px`;
          cellsArray = fillFutureShifts(rowGroup, dayNum, numberOfSelectedDays);
        }
      }

      if ( resizerLeft && rowGroup ) {
        const dX = e.clientX - x;
        const elementNewX = e.clientX - x - dayNum * 2 + 3;
        const resizeWidthLeft = dX - w;
        const widthWithLeftOffset = Math.abs(resizeWidthLeft - (w - oneCellWidth + dayNum * 2));
        const maxResizerWidthWithOffset = (dayNum + 1) * oneCellWidth;

        if (widthWithLeftOffset <= maxResizerWidthWithOffset + dayNum * 2 + 5) {
          if (e.clientX <= x) {
            resizer.style.left = `${elementNewX}px`;
            resizer.style.width = `${widthWithLeftOffset}px`;
            const numberOfSelectedDays = Math.floor((resizer.getBoundingClientRect().width + 5) / oneCellWidth);
            cellsArray = fillPrevShifts(rowGroup, dayNum, numberOfSelectedDays);
          }
        }
      }
    }
  };

  const restoreResizer = () => {
    w = 0;
    h = 0;
    x = 0;
    y = 0;
    resizer.style.width = `${w}px`;
    resizer.style.left = '0px';
    resizer.classList.remove('is-resizing');
    const selected = document.querySelectorAll('.reserved-for-copy');
    selected.forEach(c => c.classList.remove('reserved-for-copy'));
    isHoldResizer = false;
    resizerLeft = null;
    resizerRight = null;
    if ( rowGroup ) {
      const cells = rowGroup.querySelectorAll('.elmo-roster-cell');
      cells.forEach((c: any) => {
        c.style.removeProperty('pointer-events');
      });
    }
    resizer.removeEventListener('mousedown', mouseDownHandler);
  };

  const mouseUpHandler = () => {
    dispatchBulkCreate({
      cellsArray: cellsArray,
      resizerLeft: !!resizerLeft
    });
    removeListeners();
    restoreResizer();
  };

  const resizeHandler = (e: any) => {
    oneCellWidth = parentCell ? parentCell.getBoundingClientRect().width + 1 : -1;
  };

  function removeListeners() {
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);
    window.removeEventListener('resize', resizeHandler);
  }

  // Handle the mousedown event
  // that's triggered when user drags the resizer
  function mouseDownHandler(e: any) {
    // Get the current mouse position
    x = e.clientX;
    y = e.clientY;

    oneCellWidth = parentCell ? parentCell.getBoundingClientRect().width + 1 : -1;
    resizer.classList.add('is-resizing');

    if ( rowGroup ) {
      const cells = rowGroup.querySelectorAll('.elmo-roster-cell');
      cells.forEach((c: any) => {
        c.style.pointerEvents = 'none';
      });
    }

    // Calculate the dimension of element
    const styles = window.getComputedStyle(resizer);
    w = parseInt((styles as any).width, 10);
    h = parseInt((styles as any).height, 10) - 10;

    isHoldResizer = true;
    cellsArray = {};

    resizerLeft = e.target.classList.contains('resizer-l');
    resizerRight = e.target.classList.contains('resizer-r');
    setStretchingShift();

    // Attach the listeners to `document`
    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
    window.addEventListener('resize', _.debounce(resizeHandler, 250));
  }

  resizer.addEventListener('mousedown', mouseDownHandler);
};

export const enableResizers = () => {
  setTimeout(() => {
    const resizers = document.querySelectorAll('.no-resizer');
    resizers.forEach(c => c.classList.remove('no-resizer'));
  }, 0);
};

export const getUserViewParams = (
  shift: Partial<RosteredShift>,
  dataset: any,
  start: Moment,
  end: Moment,
  siteId: string,
  action: 'copy' | 'move'
) => {
  const { user_id, area_id, role_id } = shift;
  return {
    id: shift.id,
    area_id: dataset.areaId ? dataset.areaId : area_id,
    role_id: dataset.roleId ? dataset.roleId : role_id,
    user_id: dataset.userId
      ? dataset.userId === 'null'
        ? null
        : dataset.userId
      : user_id,
    site_id: siteId,
    start: start,
    end: end,
    breaks: updateRosterShiftBreaksOnDateChange(start, shift.breaks!),
    tags: shift.tags,
    event_id: shift.event_id,
    notes: action === 'move' ? shift.notes : null
  };
};

export const getSiteViewParams = (
  shift: Partial<RosteredShift>,
  dataset: any,
  start: Moment,
  end: Moment,
  siteId: string,
  action: 'copy' | 'move'
) => {
  const { user_id, area_id, role_id } = shift;
  return {
    id: shift.id,
    area_id: dataset.areaId ? dataset.areaId : area_id,
    role_id: dataset.roleId ? dataset.roleId : role_id,
    user_id: user_id,
    site_id: siteId,
    start: start,
    end: end,
    breaks: updateRosterShiftBreaksOnDateChange(start, shift.breaks!),
    tags: shift.tags,
    event_id: shift.event_id,
    notes: action === 'move' ? shift.notes : null
  };
};

export const restoreHiddenEls = () => {
  const els = document.querySelectorAll('.hidden-card');
  els.forEach(e => e.classList.remove('hidden-card'));
  const dragging = document.querySelectorAll('.dragging-to-remove');
  dragging.forEach(d => d.remove());
};

export const updatedDate = (date: Moment, day: string, tz: string) => {
  return date.set({
    date: +moment(+day)
      .tz(tz)
      .format('D'),
    year: +moment(+day)
      .tz(tz)
      .format('YYYY'),
    month:
      +moment(+day)
        .tz(tz)
        .format('M') - 1
  });
};

export const getValidDate = (start: Moment, end: Moment, dataset: any, tz: string) => {
  const startDate = updatedDate(moment.parseZone(start), dataset.day!, tz);
  const endDate = updatedDate(moment.parseZone(end), dataset.day!, tz);
  const f = 'YYYY-MM-DD HH:mm';
  if (startDate.format(f) >= endDate.format(f)) {
    endDate.add(1, 'day');
  }
  return { startDate, endDate };
};

export const hideCardOnCopy = (action: 'copy' | 'move') => {
  if ( action === 'copy' ) {
    const hidden = document.querySelector('.hidden-card');
    if ( hidden ) {
      hidden.classList.remove('hidden-card');
    }
  }
};

export const styleDragEl = (dropCell: Element) => {
  const parent = dropCell.closest('.elmo-roster-cell');
  if ( parent ) {
    const cell = parent.querySelector('.droppable-wrapper');
    const draggingEl = document.querySelector('.dragging');
    if ( cell && draggingEl) {
      draggingEl.classList.remove('dragging');
      (draggingEl as any).style.position = 'absolute';
      (draggingEl as any).style.top = '0';
      (draggingEl as any).style.left = '0';
      cell.append(draggingEl);
    }
  }
};

export const setDragPlaceholder = (element: HTMLDivElement) => {
  const dragPlaceholder = element.cloneNode(true) as HTMLDivElement;
  if ( dragPlaceholder ) {
    dragPlaceholder.classList.add('dragging');
    dragPlaceholder.classList.add('dragging-to-remove');
    dragPlaceholder.style.width = element.getBoundingClientRect().width + 'px';
    document.body.append(dragPlaceholder);
  }
  return dragPlaceholder;
};

export const unsetCopyShift = () => {
  const e = document.querySelectorAll('.show-resizer');
  e.forEach(c => {
    if (!c.querySelector('.is-resizing')) {
      c.classList.remove('show-resizer')
    }
  });
  const el = document.querySelector('#resizer');
  if ( el && !el.classList.contains('is-resizing') ) {
    el.removeAttribute('id');
  }
};
