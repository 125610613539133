import { StoreState } from 'state/types';

const getState = (state: StoreState) => state.assignments;
const getAssignments = (state: StoreState) => getState(state).assignments;

type SiteAssignmentProps = { site_id: string };
type AreaAssignmentProps = SiteAssignmentProps & { area_id: string };
type RoleAssignmentProps = AreaAssignmentProps & { role_id: string };

export const getAssignmentSite = (
  state: StoreState,
  { site_id }: SiteAssignmentProps
) => getAssignments(state).sites[site_id];

export const getAssignmentSiteUsersCount = (
  state: StoreState,
  props: SiteAssignmentProps
) => getAssignmentSite(state, props).users_count;

export const getAssignmentArea = (
  state: StoreState,
  { site_id, area_id }: AreaAssignmentProps
) => getAssignmentSite(state, { site_id }).areas[area_id];

export const getAssignmentAreaUsersCount = (
  state: StoreState,
  props: AreaAssignmentProps
) => getAssignmentArea(state, props).users_count;

export const getAssignmentAreaRoles = (
  state: StoreState,
  props: AreaAssignmentProps
) => getAssignmentArea(state, props).roles;

export const getAssignmentAreaRoleIds = (
  state: StoreState,
  props: AreaAssignmentProps
) => getAssignmentArea(state, props).role_ids;

export const getAssignmentRole = (
  state: StoreState,
  { role_id, ...restProps }: RoleAssignmentProps
) => getAssignmentAreaRoles(state, restProps)[role_id];
