import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import classNames from 'clsx';
import { MoreHorizOutlinedIcon } from 'element';
import { createStyles, makeStyles } from '@mui/styles';

type DefaultSpanProps = DetailedHTMLProps<
  HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
>;

type Props = DefaultSpanProps & {
  onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
};

const useMenuIconStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      display: 'inline-block',
      verticalAlign: 'middle',
      height: '20px',
      width: '20px',
      position: 'relative',
      right: '-4px',
      '&:hover': {
        background: '#fff',
        borderRadius: '2px',
        boxShadow:
          '0 1px 1px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31)',
      },
      '& svg': {
        fontSize: '20px',
      },
    },
  })
);

export const ShiftCardContextMenuIcon = ({
  className,
  ...restProps
}: Props) => {
  const styles = useMenuIconStyles();
  return (
    <span
      {...restProps}
      className={classNames(className, styles.icon, 'toggle-contextual-menu')}
    >
      <MoreHorizOutlinedIcon />
    </span>
  );
};
