import {
  Collection,
  DateTimeSecondsType,
  DateTimeType,
  DateType,
  MonthDays,
  PreferencesCurrencyCode,
  PreferencesNumberFormat,
  ReportExportFormat,
} from 'type';

export const HOUR_IN_MIN = 60;
export const DAY_IN_MIN = 24 * HOUR_IN_MIN;
export const TIME_FORMAT = {
  hours_12: 'hh:mm a',
  hours_24: 'HH:mm',
};
export const SHORTENED_DAY_FORMAT = 'MMM DD';
export const SERVER_TIME_FORMAT = 'HH:mm';
export const SERVER_TIME_S_FORMAT = 'HH:mm:ss';
export const SERVER_DAY_FORMAT: DateType = 'YYYY-MM-DD';
export const SERVER_DATE_TIME_FORMAT: DateTimeType = 'YYYY-MM-DD HH:mm';
export const SERVER_DATE_TIME_S_FORMAT: DateTimeSecondsType =
  'YYYY-MM-DD HH:mm:ss';
export const DATE_PICKER_FIELD_FORMAT: DateType = 'YYYY-MM-DD';

export const SIDE_MENU_WIDTH = 272;
export const PAGE_SIZE = [10, 20, 30];
export const BREAK_DURATION_LIMIT_MIN = 480;
export const TIMESHEETS_CELL_WIDTH_PX = 150;
export const DAY_VIEW_HOUR_WIDTH_PX = 60; // copied from ./vars.scss
export const DAY_VIEW_X_GAP_PX = 4; // copied from ./vars.scss
export const TIMESHEET_MAX_BREAKS_NUMBER = 3;
export const NOTES_MAX_LENGTH = 500;
export const SHIFT_DURATION_LIMIT_MIN = DAY_IN_MIN;

export const DATE_LABELS: {
  [key in MonthDays]: {
    label: string;
  };
} = {
  '1': {
    label: '1st',
  },
  '2': {
    label: '2nd',
  },
  '3': {
    label: '3rd',
  },
  '4': {
    label: '4th',
  },
  '5': {
    label: '5th',
  },
  '6': {
    label: '6th',
  },
  '7': {
    label: '7th',
  },
  '8': {
    label: '8th',
  },
  '9': {
    label: '9th',
  },
  '10': {
    label: '10th',
  },
  '11': {
    label: '11th',
  },
  '12': {
    label: '12th',
  },
  '13': {
    label: '13th',
  },
  '14': {
    label: '14th',
  },
  '15': {
    label: '15th',
  },
  '16': {
    label: '16th',
  },
  '17': {
    label: '17th',
  },
  '18': {
    label: '18th',
  },
  '19': {
    label: '19th',
  },
  '20': {
    label: '20th',
  },
  '21': {
    label: '21st',
  },
  '22': {
    label: '22nd',
  },
  '23': {
    label: '23rd',
  },
  '24': {
    label: '24th',
  },
  '25': {
    label: '25th',
  },
  '26': {
    label: '26th',
  },
  '27': {
    label: '27th',
  },
  last_day: {
    label: 'Last day',
  },
};

export const FORMAT_LABELS: {
  [key in ReportExportFormat]: {
    label: string;
  };
} = {
  pdf: {
    label: 'PDF',
  },
  csv: {
    label: 'CSV',
  },
};

export const CURRENCY_SIGNS: Collection<PreferencesCurrencyCode, string> = {
  USD: '$',
  NZD: '$',
  GBP: '£',
  EUR: '€',
  CAD: '$',
  AUD: '$',
};

export const NUMBER_FORMATS: Collection<PreferencesNumberFormat, string> = {
  '1.234,56': 'de-DE',
  '1,234.56': 'en-EN',
  '1 234,56': 'ru-RU',
};

export const ROLE_COLORS: {
  [key in number]: {
    primaryColor: string;
    secondaryColor: string;
  };
} = {
  // 0 - is default
  20: {
    primaryColor: 'ddd',
    secondaryColor: 'ccc',
  },
  0: {
    primaryColor: 'BDD289',
    secondaryColor: 'eef4e2',
  },
  1: {
    primaryColor: 'A8CDF0',
    secondaryColor: 'F3F9FF',
  },
  2: {
    primaryColor: 'FFC2DD',
    secondaryColor: 'FFF2F9',
  },
  3: {
    primaryColor: 'BFEEC9',
    secondaryColor: 'EFFBF2',
  },
  4: {
    primaryColor: 'D2CDF4',
    secondaryColor: 'f0eefb',
  },
  5: {
    primaryColor: 'F3ECB9',
    secondaryColor: 'FCFAEE',
  },
  6: {
    primaryColor: 'D8C0C0',
    secondaryColor: 'F7F2F2',
  },
  7: {
    primaryColor: 'BDEFE9',
    secondaryColor: 'EFFBFA',
  },
  8: {
    primaryColor: 'FFCFAD',
    secondaryColor: 'FFF3EB',
  },
  9: {
    primaryColor: 'E4D7C4',
    secondaryColor: 'F9F6F1',
  },
  10: {
    primaryColor: 'CBC8B9',
    secondaryColor: 'EEEDE8',
  },
  11: {
    primaryColor: 'BBCFDD',
    secondaryColor: 'E4ECF1',
  },
  12: {
    primaryColor: 'FFE799',
    secondaryColor: 'FFF5D6',
  },
  13: {
    primaryColor: 'F2D0B7',
    secondaryColor: 'F9E8DC',
  },
  14: {
    primaryColor: '91CAB8',
    secondaryColor: 'E4F2ED',
  },
  15: {
    primaryColor: 'DA95AB',
    secondaryColor: 'F4E1E7',
  },
  16: {
    primaryColor: 'D6AF99',
    secondaryColor: 'F3E8E2',
  },
  17: {
    primaryColor: 'B1D2D1',
    secondaryColor: 'E5F0F0',
  },
  18: {
    primaryColor: 'E4B4E0',
    secondaryColor: 'F4E1F3',
  },
  19: {
    primaryColor: 'BAC9C4',
    secondaryColor: 'E0EAE6',
  },
};
