import { useSelectorWithProps } from 'hooks';
import { getAreaSafe } from 'state/AccountTree';

type Props = {
  area_id: string;
};

export const useAreaName = ({ area_id }: Props) => {
  const area = useSelectorWithProps(getAreaSafe, area_id);
  return area?.name;
};
