import { DatePicker } from '@mui/x-date-pickers';
import { TimeField } from 'element';
import { useDateField, useFormControlSpacing } from 'hooks';
import moment from 'moment';
import { Box, FormHelperText, TextField } from 'extended-oxygen-elements';
import React from 'react';
import { useSelector } from 'react-redux';
import { getDateFormat } from 'state/Account';
import { DateType, FieldGroupProps, Minutes } from 'type';
import {
  DateRangeFieldGroupErrors,
  DateRangeFieldGroupValue,
  RequiredDateRangeFieldGroupValue,
} from './types';
import {
  dateTimeHelper,
  getDateFieldValue,
  getDateRangeDurationMinutes,
  getDateValueSafe,
  getTimeFieldValue,
  isValidDateRangeGuard,
} from './utils';

type Props = FieldGroupProps<
  DateRangeFieldGroupValue,
  DateRangeFieldGroupErrors,
  RequiredDateRangeFieldGroupValue
>;

export default DateRangeFieldGroup;

export function DateRangeFieldGroup({ values, errors, onChange }: Props) {
  const dateFormat = useSelector(getDateFormat);
  const formControlSpacing = useFormControlSpacing('normal');

  /* TODO implement as a form field? */
  const startDateField = useDateField({
    value: values.startDate,
    onValueChange: (startDate) => {
      onChange({
        ...getUpdatedEndFields(values, {
          startDate,
          startTime: values.startTime,
        }),
        startDate,
      });
    },
  });

  const handleStartTimeChange = (startTime: number | null) => {
    if (startTime === null) {
      return;
    }

    onChange({
      startTime,
    });
  };

  /* TODO implement as a form field? */
  const endDateField = useDateField({
    value: values.endDate,
    onValueChange: (updatedDate) => {
      onChange({
        endDate: updatedDate,
      });
    },
  });

  const handleEndTimeChange = (updatedTime: number | null) => {
    if (updatedTime === null) {
      return;
    }

    onChange({
      endTime: updatedTime,
    });
  };

  return (
    <>
      <Box {...formControlSpacing}>
        <Box display="flex">
          <Box flex="0 0 70%" pr={1}>
            <DatePicker
              label="Start date"
              inputFormat={dateFormat}
              value={startDateField.value}
              onChange={startDateField.onChange}
              renderInput={({ error, ...restInputProps }) => (
                <TextField
                  {...restInputProps}
                  onBlur={startDateField.onBlur}
                  inputProps={{
                    ...restInputProps.inputProps,
                    size: 1,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="none"
                  error={!!errors.startDate}
                  data-testid="time-range-start-picker"
                />
              )}
            />
          </Box>

          <TimeField
            inputProps={{ size: 1 }}
            margin="none"
            label="Time"
            value={values.startTime}
            onValueChange={handleStartTimeChange}
            error={!!errors.startTime}
            data-testid={'time-range-start'}
          />
        </Box>

        {errors.startDate && (
          <FormHelperText error={true}>{errors.startDate}</FormHelperText>
        )}

        {errors.startTime && (
          <FormHelperText error={true}>{errors.startTime}</FormHelperText>
        )}
      </Box>

      <Box {...formControlSpacing}>
        <Box display="flex">
          <Box flex="0 0 70%" pr={1}>
            <DatePicker
              label="End date"
              inputFormat={dateFormat}
              value={endDateField.value}
              onChange={endDateField.onChange}
              renderInput={({ error, ...restInputProps }) => (
                <TextField
                  {...restInputProps}
                  onBlur={endDateField.onBlur}
                  inputProps={{
                    ...restInputProps.inputProps,
                    size: 1,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="none"
                  error={!!(errors.endDate || errors.end)}
                  data-testid="time-range-end-picker"
                />
              )}
            />
          </Box>

          <TimeField
            inputProps={{ size: 1 }}
            margin="none"
            label="Time"
            value={values.endTime}
            onValueChange={handleEndTimeChange}
            error={!!(errors.endTime || errors.end)}
            data-testid={'time-range-end'}
          />
        </Box>

        {errors.endDate && (
          <FormHelperText error={true}>{errors.endDate}</FormHelperText>
        )}

        {errors.endTime && (
          <FormHelperText error={true}>{errors.endTime}</FormHelperText>
        )}

        {errors.end && (
          <FormHelperText error={true}>{errors.end}</FormHelperText>
        )}
      </Box>
    </>
  );
}

function getUpdatedEndFields(
  values: DateRangeFieldGroupValue,
  updatedStart: { startDate: DateType | null; startTime: Minutes | null }
) {
  if (!isValidDateRangeGuard(values)) {
    return;
  }

  const durationMinutes = getDateRangeDurationMinutes(values);

  const updatedStartMoment = moment(
    getDateValueSafe(updatedStart.startDate, updatedStart.startTime)
  );
  const updatedEndMoment = updatedStartMoment
    .clone()
    .add(durationMinutes, 'minutes');
  const updatedEnd = dateTimeHelper.stringify(updatedEndMoment);

  return {
    endDate: getDateFieldValue(updatedEnd),
    endTime: getTimeFieldValue(updatedEnd),
  };
}
