import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { Api } from 'lib/Api';
import { getRotaUserListRequest } from 'state/UsersCollection';
import * as fetchActions from 'state/FetchPageData';
import {
  BOX_MANAGER_DASHBOARD_CLEAR_ERRORS,
  ManagerDashboardFilters,
  managerDashboardFiltersSelector,
} from '../../../ManagerDashboard';
import {
  BOX_SHIFT_OFFERS_CHANGE_PAGE,
  BOX_SHIFT_OFFERS_GET_OFFERS_LIST_SUCCESS,
  BOX_SHIFT_OFFERS_GET_PAGE_DATA_REQUEST,
  BOX_SHIFT_OFFERS_OMIT_SHIFT_OFFER,
} from '../actions';
import { ManagerDashboardGetShiftOffersResponse } from 'lib/Api/type';
import { processApiRequest } from '../../../../ProcessApiRequest/sagas';
import { BOX_SHIFT_OFFERS_DELETE_ONE } from '../../actions';
import {
  DeleteInstancePayload,
  NotFirstAndEmptyPageSelector,
  SagaAction,
} from '../../../../../type';
import { notFirstAndEmptyPageSelector } from '../selectors';

export const getShiftOffersListRequest = function* (): SagaIterator {
  yield put(BOX_MANAGER_DASHBOARD_CLEAR_ERRORS());

  const filters: ManagerDashboardFilters = yield select(
    managerDashboardFiltersSelector
  );

  const response: ManagerDashboardGetShiftOffersResponse = yield call(
    processApiRequest,
    Api.ManagerDashboard.getShiftOffers,
    filters
  );

  yield put(BOX_SHIFT_OFFERS_GET_OFFERS_LIST_SUCCESS(response));
};

const getPageData = function* (): SagaIterator {
  yield put(
    fetchActions.BOX_FETCH_PAGE_DATA_REQUEST(
      fetchActions.MANAGER_DASHBOARD_SHIFT_OFFERS
    )
  );

  try {
    yield all([
      call(getShiftOffersListRequest),
      // call(getAccountTreeRequest), // fetched in BOX_MANAGER_DASHBOARD_FETCH_PAGE_DATA_REQUEST
      call(getRotaUserListRequest),
    ]);

    yield put(
      fetchActions.BOX_FETCH_PAGE_DATA_SUCCESS(
        fetchActions.MANAGER_DASHBOARD_SHIFT_OFFERS
      )
    );
  } catch (error) {
    yield put(
      fetchActions.BOX_FETCH_PAGE_DATA_FAILURE(
        fetchActions.MANAGER_DASHBOARD_SHIFT_OFFERS
      )
    );
  }
};

const deleteShiftOffer = function* ({
  payload,
}: SagaAction<DeleteInstancePayload>): SagaIterator {
  yield put(BOX_SHIFT_OFFERS_OMIT_SHIFT_OFFER(payload));

  const { notFirstAndEmptyPage, lastPageNumber }: NotFirstAndEmptyPageSelector =
    yield select(notFirstAndEmptyPageSelector);

  if (notFirstAndEmptyPage) {
    yield put(BOX_SHIFT_OFFERS_CHANGE_PAGE(lastPageNumber));
  }
};

export const watchShiftOffers = function* (): SagaIterator {
  yield takeLatest(BOX_SHIFT_OFFERS_GET_PAGE_DATA_REQUEST, getPageData);
  yield takeLatest(BOX_SHIFT_OFFERS_DELETE_ONE, deleteShiftOffer);
};
