import { Event, StringMap } from 'type';
import { StoreState } from 'state/types';
import { createSelector } from 'reselect';
import { reduce, values } from 'lodash';
import { EventsDurationSelector } from './types';
import { Moment } from 'moment';
import { fromToBySiteTimezoneSelectorsCreator } from '../selectors';
import { calculateEventDaysQuantity } from './helpers';
import { getItemSafeCreator } from '../combiners';

const getState = (state: StoreState) => state.events;
export const getEvents = (state: StoreState) => getState(state).events;
export const getEvent = (state: StoreState, id: string) => getEvents(state)[id];
export const getEventSafe = getItemSafeCreator(getEvents);

export const { fromBySiteTimezoneSelector, toBySiteTimezoneSelector } =
  fromToBySiteTimezoneSelectorsCreator(getState);

export const eventsArraySelector = createSelector<
  StoreState,
  StringMap<Event>,
  Event[]
>(getEvents, values);

export const eventsDurationSelector = createSelector(
  getEvents,
  fromBySiteTimezoneSelector,
  toBySiteTimezoneSelector,
  (
    events: StringMap<Event>,
    from: Moment,
    to: Moment
  ): EventsDurationSelector =>
    reduce(
      events,
      (
        duration: EventsDurationSelector,
        event: Event
      ): EventsDurationSelector => {
        const { type, day_cost, actual_day_cost } = event;

        const dayQuantity = calculateEventDaysQuantity(event, { from, to });

        const eventCost = dayQuantity * day_cost;
        const eventCostActual = dayQuantity * actual_day_cost;

        return {
          total: duration.total + eventCost,
          forecasts: duration.forecasts + (type === 'forecast' ? eventCost : 0),
          events: duration.events + (type === 'event' ? eventCost : 0),
          actual: duration.actual + eventCostActual,
        };
      },
      {
        total: 0,
        forecasts: 0,
        events: 0,
        actual: 0,
      }
    )
);

export const getEventByIdSelector = (state: StoreState, id: string): Event => {
  const events = getEvents(state);
  return events[id] || null;
};
