import { Box, Alert } from 'extended-oxygen-elements';
import { commonError } from 'messages';
import React from 'react';

export function CommonError() {
  return (
    <Box p={3}>
      <Alert severity="error">{commonError}</Alert>
    </Box>
  );
}
