import React, { Component } from 'react';
import { FormItem, Input, FormContainer } from 'elmo-elements';
import { FormattedErrors, UserProfileFields } from 'type/models';
import ErrorBox from 'element/ErrorBox';
import _ from 'lodash';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';
import { PageDialog, PageDialogCancel, PageDialogSubmit } from 'element';

type Props = {
  isOpened: boolean;
  closeModal: () => void;
  profile: Partial<UserProfileFields>;
  changeInput: (
    name: string,
    key: 'roster_rules' | 'ordinary_hours_of_work',
    value: string
  ) => void;
  saveProfile: () => void;
  saveDisabled: boolean;
  isLoading: boolean;
  errors: FormattedErrors;
  clearErrors: () => void;
  rosterLabel: string;
};

export class RosterRulesModal extends Component<Props, any> {
  render() {
    const { roster_rules, ordinary_hours_of_work } = this.props.profile;
    const { errors, changeInput, rosterLabel } = this.props;
    return (
      <PageDialog
        maxWidth={'xs'}
        open={this.props.isOpened}
        id={'roster-rules-modal'}
        onClose={this.props.closeModal}
      >
        <DialogTitle>{`${_.capitalize(
          rosterLabel
        )} rules / ordinary hours`}</DialogTitle>
        <DialogContent>
          {errors.length > 0 && (
            <ErrorBox
              errors={this.props.errors}
              clearErrors={this.props.clearErrors}
            />
          )}
          <FormContainer maxColumns={1}>
            <FormItem label={`Max hrs / per ${rosterLabel} period`}>
              <Input
                name={'max_hrs_per_roster_period'}
                value={ordinary_hours_of_work!.max_hrs_per_roster_period}
                htmlType={'number'}
                onChange={(e) =>
                  changeInput(
                    'max_hrs_per_roster_period',
                    'ordinary_hours_of_work',
                    e.target.value
                  )
                }
              />
            </FormItem>
            <FormItem label={`Max hrs / day`}>
              <Input
                name={'max_hrs_per_day'}
                value={ordinary_hours_of_work!.max_hrs_per_day}
                htmlType={'number'}
                onChange={(e) =>
                  changeInput(
                    'max_hrs_per_day',
                    'ordinary_hours_of_work',
                    e.target.value
                  )
                }
              />
            </FormItem>
            <FormItem label={`Max shift duration`}>
              <Input
                name={'max_shift_duration'}
                value={roster_rules!.max_shift_duration}
                htmlType={'number'}
                onChange={(e) =>
                  changeInput(
                    'max_shift_duration',
                    'roster_rules',
                    e.target.value
                  )
                }
              />
            </FormItem>
            <FormItem label={`Min break between 2 shifts`}>
              <Input
                name={'min_break_between_shifts'}
                value={roster_rules!.min_break_between_shifts}
                htmlType={'number'}
                onChange={(e) =>
                  changeInput(
                    'min_break_between_shifts',
                    'roster_rules',
                    e.target.value
                  )
                }
              />
            </FormItem>
            <FormItem label={`Max. long-hr shifts / ${rosterLabel} period`}>
              <Input
                name={'max_long_hour_shifts_per_roster_period'}
                value={roster_rules!.max_long_hour_shifts_per_roster_period}
                htmlType={'number'}
                onChange={(e) =>
                  changeInput(
                    'max_long_hour_shifts_per_roster_period',
                    'roster_rules',
                    e.target.value
                  )
                }
              />
            </FormItem>
            <FormItem label={`Min hrs / per ${rosterLabel} period`}>
              <Input
                name={'min_hrs_per_roster_period'}
                value={ordinary_hours_of_work!.min_hrs_per_roster_period}
                htmlType={'number'}
                onChange={(e) =>
                  changeInput(
                    'min_hrs_per_roster_period',
                    'ordinary_hours_of_work',
                    e.target.value
                  )
                }
              />
            </FormItem>
            <FormItem label={`Min hrs / day`}>
              <Input
                name={'min_hrs_per_day'}
                value={ordinary_hours_of_work!.min_hrs_per_day}
                htmlType={'number'}
                onChange={(e) =>
                  changeInput(
                    'min_hrs_per_day',
                    'ordinary_hours_of_work',
                    e.target.value
                  )
                }
              />
            </FormItem>
            <FormItem label={`Min shift duration`}>
              <Input
                name={'min_shift_duration'}
                value={roster_rules!.min_shift_duration}
                htmlType={'number'}
                onChange={(e) =>
                  changeInput(
                    'min_shift_duration',
                    'roster_rules',
                    e.target.value
                  )
                }
              />
            </FormItem>
            <FormItem label={`Max consecutive long-hr shifts`}>
              <Input
                name={'max_consecutive_long_hour_shifts'}
                value={roster_rules!.max_consecutive_long_hour_shifts}
                htmlType={'number'}
                onChange={(e) =>
                  changeInput(
                    'max_consecutive_long_hour_shifts',
                    'roster_rules',
                    e.target.value
                  )
                }
              />
            </FormItem>
          </FormContainer>
        </DialogContent>
        <DialogActions>
          <PageDialogCancel onClick={this.props.closeModal} fullWidth={false}>
            Cancel
          </PageDialogCancel>
          <PageDialogSubmit
            onClick={this.props.saveProfile}
            disabled={this.props.saveDisabled}
            loading={this.props.isLoading}
            fullWidth={false}
          >
            Save
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  }
}
