import React from 'react';
import { connect } from 'react-redux';
import { Heading, Paragraph } from 'elmo-elements';
import { StoreState } from 'state/types';
import {
  BOX_SHIFT_TIME_RANGE_INPUT_LEGEND_CLOSED,
  getIsOpened,
} from 'state/Roster/RangeInput/ShiftTimeRangeInputLegend';
import './formats.scss';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';
import { PageDialog, PageDialogSubmit } from 'element';

export type OwnProps = {};

export type StateProps = {
  isOpened: boolean;
};

export type DispatchProps = {
  handleClose: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const InputWithLegendModalComponent = ({ handleClose, isOpened }: Props) => (
  <PageDialog
    maxWidth={'xs'}
    open={isOpened}
    id={'supported-time-formats-modal'}
    onClose={handleClose}
  >
    <DialogTitle>Supported time formats</DialogTitle>
    <DialogContent>
      <Heading size="sm" color={'black'}>
        AM/PM, 24 hours or military formats can be used:
      </Heading>
      <div className="legend-modal-formats">
        <Paragraph color="gray" size={'sm'}>
          &bull; 8am - 5pm
        </Paragraph>
        <Paragraph color="gray" size={'sm'}>
          &bull; 8 am - 5 pm
        </Paragraph>
        <Paragraph color="gray" size={'sm'}>
          &bull; 8a - 5p
        </Paragraph>
        <Paragraph color="gray" size={'sm'}>
          &bull; 8 a.m - 5 p.m
        </Paragraph>
        <Paragraph color="gray" size={'sm'}>
          &bull; 8 a.m. - 5 p.m.
        </Paragraph>
        <Paragraph color="gray" size={'sm'}>
          &bull; 8 a m - 5 p m
        </Paragraph>
        <Paragraph color="gray" size={'sm'}>
          &bull; 8 a. m - 5 p. m
        </Paragraph>
        <Paragraph color="gray" size={'sm'}>
          &bull; 8 a. m. - 5 p. m.
        </Paragraph>
        <Paragraph color="gray" size={'sm'}>
          &bull; 0800 - 1700
        </Paragraph>
      </div>
      <br />

      <Heading size="sm" color={'black'}>
        Different formats can be used for start/end:
      </Heading>
      <div className="legend-modal-formats">
        <Paragraph color="gray" size={'sm'}>
          &bull; 8am - 17
        </Paragraph>
      </div>

      <br />

      <Heading size="sm" color={'black'}>
        Hours/minutes separators:
      </Heading>
      <div className="legend-modal-formats">
        <Paragraph color="gray" size={'sm'}>
          &bull; 8.30 - 17.05
        </Paragraph>
        <Paragraph color="gray" size={'sm'}>
          &bull; 8,30 - 17,05
        </Paragraph>
        <Paragraph color="gray" size={'sm'}>
          &bull; 8:30 - 17:05
        </Paragraph>
        <Paragraph color="gray" size={'sm'}>
          &bull; 8_30 - 17_05
        </Paragraph>
        <Paragraph color="gray" size={'sm'}>
          &bull; 8 30 - 17 05
        </Paragraph>
      </div>
    </DialogContent>
    <DialogActions>
      <PageDialogSubmit onClick={handleClose}>Got it</PageDialogSubmit>
    </DialogActions>
  </PageDialog>
);

const mapStateToProps = (state: StoreState): StateProps => ({
  isOpened: getIsOpened(state),
});

const mapDispatchToProps: DispatchProps = {
  handleClose: BOX_SHIFT_TIME_RANGE_INPUT_LEGEND_CLOSED,
};

export const InputWithLegendModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(InputWithLegendModalComponent);
