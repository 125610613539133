import { Layout } from 'element';
import { EditTemplateHeader } from 'page/Roster/components';
import { Dictionary } from 'ts-essentials';
import { Route } from 'type';
import View from './view';
import { BulkDeleteContextProvider } from 'contexts';

type Props = {
  routes: Dictionary<Route>;
};

export default EditTemplate;

function EditTemplate({ routes }: Props) {
  return (
    <BulkDeleteContextProvider>
      <Layout.Header>
        <EditTemplateHeader />
      </Layout.Header>

      <Layout.Content>
        <View routes={routes} />
      </Layout.Content>
    </BulkDeleteContextProvider>
  );
}
