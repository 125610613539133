import nanoid from 'nanoid';

export default function makeUniqIdProvider() {
  const savedIds: string[] = [];

  return (id: string = nanoid()) => {
    if (savedIds.includes(id)) {
      throw new Error(`makeIdsProvider / id must be unique: ${id}`);
    }

    savedIds.push(id);

    return id;
  }
}
