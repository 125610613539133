import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { DashboardShift, SagaAction, Timesheet } from 'type';
import { formatError } from 'state/helpers';
import * as fetchPageData from 'state/FetchPageData';
import {
  getFetchFlags,
  MANAGER_DASHBOARD_OVERVIEW,
  PageId,
} from 'state/FetchPageData';
import { getOverviewRequest } from '../../Overview/sagas';
import { getWhosWorkingListRequest } from '../../WhosWorking/sagas';
import { getShiftOffersListRequest } from '../../ShiftOffers/ShiftOffers/sagas';
import { getShiftSwapsRequest } from '../../ShiftSwaps/sagas';
import { getEventsListRequest } from '../../Events/sagas';
import { getTimeOffsWithPageCheckRequest } from '../../Unavailability/sagas';
import { BOX_OVERVIEW_FETCH_DATA_REQUEST } from '../../Overview';
import {
  BOX_WHOS_WORKING_SHIFT_CHANGED,
  BOX_WHOS_WORKING_SHIFT_DELETED,
  BOX_WHOS_WORKING_TIMESHEET_CHANGED,
  BOX_WHOS_WORKING_TIMESHEET_DELETED,
} from '../../WhosWorking';
import { DeleteResponse } from '../../WhosWorking/types';
import * as actions from '../actions';
import { CallEffect } from '@redux-saga/core/effects';
import { getSites } from '../../../AccountTree';

const config = new Map<PageId, () => SagaIterator>([
  [fetchPageData.MANAGER_DASHBOARD_OVERVIEW, getOverviewRequest],
  [fetchPageData.MANAGER_DASHBOARD_WHOS_WORKING, getWhosWorkingListRequest],
  [fetchPageData.MANAGER_DASHBOARD_SHIFT_OFFERS, getShiftOffersListRequest],
  [fetchPageData.MANAGER_DASHBOARD_SHIFT_SWAPS, getShiftSwapsRequest],
  [fetchPageData.MANAGER_DASHBOARD_EVENTS, getEventsListRequest],
  [
    fetchPageData.MANAGER_DASHBOARD_UNAVAILABILITY,
    getTimeOffsWithPageCheckRequest,
  ],
]);

export const updateFilters = function* (): SagaIterator {
  yield put(actions.BOX_MANAGER_DASHBOARD_CLEAR_ERRORS());

  const fetchedPageIds: ReturnType<typeof fetchPageData.fetchedPagesSelector> =
    yield select(fetchPageData.fetchedPagesSelector);

  try {
    const fetchSagas = fetchedPageIds.reduce<CallEffect[]>(
      (accumulator, pageId) => {
        const fetchSaga = config.get(pageId as any);

        if (fetchSaga) {
          accumulator.push(call(fetchSaga));
        }

        return accumulator;
      },
      []
    );

    yield all(fetchSagas);

    yield put(actions.BOX_MANAGER_DASHBOARD_UPDATE_FILTERS_SUCCESS());
  } catch (error) {
    yield put(
      actions.BOX_MANAGER_DASHBOARD_UPDATE_FILTERS_FAILURE(formatError(error))
    );
  }
};

const isFetched = (params: ReturnType<typeof getFetchFlags>): boolean => {
  return params.isFetched && !params.isFetching;
};

export const getPage = function* (): SagaIterator {
  const overview: ReturnType<typeof getFetchFlags> = yield select(
    getFetchFlags,
    'MANAGER_DASHBOARD_OVERVIEW'
  );

  const whosworking: ReturnType<typeof getFetchFlags> = yield select(
    getFetchFlags,
    'MANAGER_DASHBOARD_WHOS_WORKING'
  );

  if (isFetched(overview)) {
    yield put(BOX_OVERVIEW_FETCH_DATA_REQUEST());
  }

  return { overview, whosworking };
};

const triggerManagerDashboardChangeShift = function* ({
  payload,
}: SagaAction<DashboardShift>): SagaIterator {
  const { whosworking } = yield call(getPage);
  if (isFetched(whosworking)) {
    const sites = yield select(getSites);
    payload.timezone_id = sites[payload.site_id].timezone_id;
    yield put(BOX_WHOS_WORKING_SHIFT_CHANGED(payload));
  }
};

const triggerManagerDashboardDeleteShift = function* ({
  payload,
}: SagaAction<DeleteResponse>): SagaIterator {
  const { whosworking } = yield call(getPage);
  if (isFetched(whosworking)) {
    yield put(BOX_WHOS_WORKING_SHIFT_DELETED(payload));
  }
};

const triggerManagerDashboardChangeTimesheet = function* ({
  payload,
}: SagaAction<DashboardShift | Timesheet>): SagaIterator {
  const { whosworking } = yield call(getPage);
  if (isFetched(whosworking)) {
    yield put(BOX_WHOS_WORKING_TIMESHEET_CHANGED(payload as DashboardShift));
  }
};

const triggerManagerDashboardDeleteTimesheet = function* ({
  payload,
}: SagaAction<DeleteResponse>): SagaIterator {
  const { whosworking } = yield call(getPage);
  if (isFetched(whosworking)) {
    yield put(BOX_WHOS_WORKING_TIMESHEET_DELETED(payload));
  }
};

export const watchManagerDashboard = function* (): SagaIterator {
  yield takeLatest(
    actions.BOX_MANAGER_DASHBOARD_UPDATE_FILTERS_REQUEST,
    updateFilters
  );

  yield takeLatest(
    actions.BOX_MANAGER_DASHBOARD_SHIFT_CHANGED,
    triggerManagerDashboardChangeShift
  );
  yield takeLatest(
    actions.BOX_MANAGER_DASHBOARD_SHIFT_DELETED,
    triggerManagerDashboardDeleteShift
  );

  yield takeLatest(
    actions.BOX_MANAGER_DASHBOARD_TIMESHEET_CHANGED,
    triggerManagerDashboardChangeTimesheet
  );
  yield takeLatest(
    actions.BOX_MANAGER_DASHBOARD_TIMESHEET_DELETED,
    triggerManagerDashboardDeleteTimesheet
  );
};
