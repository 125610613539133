import { StoreState } from '../../types';
import {
  EmployeeTimesheetsWithPagerSelector,
  MyTimesheetFilters,
  MyTimesheetSelectedFilter,
  MyTimesheetSort
} from './types';
import { createSelector } from 'reselect';
import {
  BackendPager,
  EmployeeTimesheet,
  FormattedErrors
} from '../../../type/models';
import _ from 'lodash';
import { createTimesheetHash } from './helpers';

export const getFiltersForm = (state: StoreState): MyTimesheetFilters =>
  state.employeeDashboard.myTimesheet.filtersForm;
export const getSelectedFilter = (
  state: StoreState
): MyTimesheetSelectedFilter =>
  state.employeeDashboard.myTimesheet.selectedFilter;
export const getPager = (state: StoreState): BackendPager =>
  state.employeeDashboard.myTimesheet.pager;
export const getCurrentPage = (state: StoreState): number =>
  state.employeeDashboard.myTimesheet.pager.currentPage;
export const getPageSize = (state: StoreState): number =>
  state.employeeDashboard.myTimesheet.pager.pageSize;
const getEmployeeTimesheets = (state: StoreState): EmployeeTimesheet[] =>
  state.employeeDashboard.myTimesheet.data;
export const getErrors = (state: StoreState): FormattedErrors =>
  state.employeeDashboard.myTimesheet.errors;
export const getPagerTotal = (state: StoreState): number =>
  state.employeeDashboard.myTimesheet.pager.total;
export const getSort = (state: StoreState): MyTimesheetSort =>
  state.employeeDashboard.myTimesheet.filters.sort;
export const getTotalDuration = (state: StoreState): number =>
  state.employeeDashboard.myTimesheet.totalDuration;
export const getIsCurrentlyCreated = (state: StoreState): string | null =>
  state.employeeDashboard.myTimesheet.currentlyCreated;

export const isFiltersFormDisabledSelector = createSelector(
  getFiltersForm,
  ({ period, status }: MyTimesheetFilters): boolean => {
    return (status && !status.length) || (period.period === 'custom' && (!period.from && !period.to));
  }
);

export const employeeTimesheetsWithPagerSelector = createSelector(
  getEmployeeTimesheets,
  getPager,
  (
    page: EmployeeTimesheet[],
    pager: BackendPager
  ): EmployeeTimesheetsWithPagerSelector => ({
    page,
    ...pager
  })
);

export const isTimesheetExists = createSelector<
  StoreState,
  EmployeeTimesheet[],
  string | null,
  boolean
>(getEmployeeTimesheets, getIsCurrentlyCreated, (list: EmployeeTimesheet[], id: string | null) => {
  let found = false;
  if ( id !== null ) {
      list.forEach((l: EmployeeTimesheet) => {
        const hash = createTimesheetHash(l);
        if ( hash === id ) {
          found = true;
        }
      });
  }
  return found;
});
