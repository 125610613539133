import React, { useEffect, useState } from 'react';
import {
  AccountTreeSite,
  RosteredShift,
  ServerUserFields,
  Timesheet,
} from 'type/models';
import { StringMap } from 'type';
import { getDurationsInfo } from '../../../../helpers';
import { StoreState } from 'state/types';
import { getAccountTree } from 'state/AccountTree';
import { getUserListResponse } from 'state/UsersCollection';
import {
  filtersByTypeSelector,
  RosterFiltersByType,
} from 'state/Roster/RosterFilters';
import { connect, useSelector } from 'react-redux';
import { RosterTimeOffsByUserIdAndDate } from 'state/Roster/RosterWeekView/types';
import { getUserName } from 'lib/helpers';
import {
  UnavailabilityCard,
  UserRosterWeekCard,
  UserTimesheetCard,
} from 'page/Roster/components';
import { LegendDurationHours } from '../LegendDurationHours';
import { PrintWeekHeader } from '../PrintWeekHeader';
import { getWeekDays } from 'state/Roster/RosterWeekView';
import { getIsPrinting, getPrintOptions } from 'state/Roster/Print';
import { getRosteredShifts } from 'state/RosteredShiftsCollection';
import { EventsTable } from '../Events/EventsTable';

type Day = {
  rosteredShiftId?: string;
  timesheetId?: string;
  leaveId?: string;
  timeOffIds?: string[];
};

type StateProps = {
  sites: StringMap<AccountTreeSite>;
  users: StringMap<ServerUserFields>;
  filters: RosterFiltersByType;
  weekDays: string[];
};

type OwnProps = {
  groupedRosters: any;
  timeOffs: RosterTimeOffsByUserIdAndDate;
  filteredRosters: RosteredShift[];
  filteredTimesheets: Timesheet[];
};

type Props = OwnProps & StateProps;

export function UserView({
  groupedRosters,
  filters,
  timeOffs,
  filteredTimesheets,
  filteredRosters,
  users,
  weekDays,
}: Props) {
  const [rostersWeek, setRostersWeek]: any = useState(groupedRosters);
  const rosteredShifts = useSelector(getRosteredShifts);
  const printOptions = useSelector(getPrintOptions);
  const isPrinting = useSelector(getIsPrinting);

  useEffect(() => {
    setRostersWeek(groupedRosters);
  }, [groupedRosters, isPrinting, printOptions]);

  function renderLegend(userId: string, index: number) {
    const durations = getDurationsInfo(
      groupedRosters[index].rosters,
      filteredRosters.filter((roster) => roster.is_published),
      filteredTimesheets,
      timeOffs
    );
    return (
      <>
        <div className={'roleName'}>{getUserName(users[userId])}</div>
        {durations.map((duration, key) => (
          <React.Fragment key={key}>
            {filters.roster && duration.type === 'roster' && (
              <LegendDurationHours time={duration.time} type={'roster'} />
            )}
            {filters.timesheets && duration.type === 'timesheet' && (
              <LegendDurationHours time={duration.time} type={'timesheet'} />
            )}
          </React.Fragment>
        ))}
      </>
    );
  }

  function renderUnassignedLegend() {
    const durations = getDurationsInfo(
      groupedRosters[groupedRosters.length - 1].rosters,
      filteredRosters.filter((roster) => roster.is_published),
      filteredTimesheets,
      timeOffs
    );
    return (
      <>
        <div className={'roleName'}>Unassigned</div>
        {durations.map((duration, key) => (
          <React.Fragment key={key}>
            {filters.roster && duration.type === 'roster' && (
              <LegendDurationHours time={duration.time} type={'roster'} />
            )}
          </React.Fragment>
        ))}
      </>
    );
  }

  function renderRow(data: Day[], index: number) {
    return (
      <table cellSpacing="0" key={index}>
        <tbody>
          {filters.roster && (
            <tr>
              {data.map((day: Day, dayIndex: number) => {
                return renderRosterCell(day, `${index}-${dayIndex}`, dayIndex);
              })}
            </tr>
          )}
          {filters.timesheets && (
            <tr>
              {data.map((day: Day, dayIndex: number) => {
                return renderTimesheetCell(day, `${index}-${dayIndex}`);
              })}
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  function renderTimeOffs(
    leaveId: string,
    timeOffIds: string[],
    dayIndex: number
  ) {
    return (
      <div className="shiftWrapper timeOffWrapper">
        <UnavailabilityCard
          leaveId={leaveId}
          timeOffIds={timeOffIds}
          day={weekDays[dayIndex]}
        />
      </div>
    );
  }

  function renderUserCardBasedOnPrintOptions(id?: string) {
    if (!id) {
      return null;
    }
    const rosteredShift = rosteredShifts[id];
    if (!printOptions.assigned && rosteredShift && rosteredShift.user_id) {
      return null;
    }
    return <UserRosterWeekCard rosteredShiftId={id} />;
  }

  function renderRosterCell(day: Day, key: string, dayIndex: number) {
    if (day.leaveId || day.timeOffIds) {
      return (
        <td className="dayCell" key={key}>
          {renderTimeOffs(day.leaveId!, day.timeOffIds!, dayIndex)}
        </td>
      );
    }

    return (
      <td className="dayCell" key={key}>
        <div className="wrap">
          <div className="shiftWrapper">
            {renderUserCardBasedOnPrintOptions(day.rosteredShiftId)}
          </div>
        </div>
      </td>
    );
  }

  function renderTimesheetCell(day: Day, key: string) {
    return (
      <td className="dayCell" key={key}>
        <div className="wrap">
          <div className="timesheetWrapper">
            {day.timesheetId && (
              <UserTimesheetCard timesheetId={day.timesheetId} />
            )}
          </div>
        </div>
      </td>
    );
  }

  function getUsersRows() {
    const rosters: any = [];
    for (let i = 0; i < rostersWeek.length - 1; i += 1) {
      rosters.push(rostersWeek[i]);
    }
    if (printOptions.unassigned) {
      return [rostersWeek[rostersWeek.length - 1], ...rosters];
    }
    return rosters;
  }

  if (!isPrinting || getUsersRows().length === 0) {
    return null;
  }

  return (
    <>
      {printOptions.events && <EventsTable />}

      {printOptions.unassigned && (
        <table cellSpacing="0" className="area-table unassigned-table">
          <PrintWeekHeader showDays={true} />
          <tbody>
            <tr key="unassigned-row">
              <td className="firstCell border-cell" key={'cell-legend'}>
                <div className="firstCellContent">
                  {renderUnassignedLegend()}
                </div>
              </td>
              <td className="border-cell" key={'cell-data'}>
                <table>
                  <tbody>
                    {getUsersRows()[0].rosters.map(
                      (data: any, rowNumber: number) => (
                        <tr key={`unassigned-row-${rowNumber}`}>
                          {data.map((day: Day, dayIndex: number) => (
                            <td
                              className="dayCell"
                              key={`unassigned-cell-${dayIndex}-0`}
                            >
                              <div className="wrap">
                                <div className="shiftWrapper">
                                  {renderUserCardBasedOnPrintOptions(
                                    day.rosteredShiftId
                                  )}
                                </div>
                              </div>
                            </td>
                          ))}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      )}

      <table cellSpacing="0" className="area-table users-table">
        <PrintWeekHeader showDays={true} />
        <tbody>
          {getUsersRows().map((user: any, key: number) => {
            if (user.user_id) {
              return (
                <tr key={key}>
                  <td className="firstCell border-cell" key={'cell-legend'}>
                    <div className="firstCellContent">
                      {renderLegend(user.user_id, key++)}
                    </div>
                  </td>
                  <td className="border-cell" key={'cell-data'}>
                    {user.rosters.map((days: Day[], dayIndex: number) => {
                      return renderRow(days, dayIndex);
                    })}
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    </>
  );
}

const mapStateToProps = (state: StoreState): StateProps => ({
  ...getAccountTree(state),
  users: getUserListResponse(state),
  filters: filtersByTypeSelector(state),
  weekDays: getWeekDays(state),
});

export default connect(mapStateToProps)(UserView);
