import React, { Component, ComponentProps } from 'react';
import { connect } from 'react-redux';
import { Duration, Moment } from 'moment';
import { rosterPageLabels } from 'messages';
import { StoreState } from 'state/types';
import { fromBySiteTimezoneSelector } from 'state/RosteredShifts';
import {
  filtersByTypeSelector,
  RosterFiltersByType,
} from 'state/Roster/RosterFilters';
import {
  BOX_ROSTER_DAY_VIEW_CREATE_SHIFT_START,
  BOX_ROSTER_DAY_VIEW_DRAG_AND_DROP_SITE_VIEW_FINISHED,
  BOX_ROSTER_DAY_VIEW_SITE_VIEW_ROW_ADD,
  BOX_ROSTER_DAY_VIEW_UPDATE_ROSTERED_SHIFT_TIME_REQUEST,
  BOX_ROSTER_DAY_VIEW_UPDATE_TIMESHEET_TIME_REQUEST,
  DragAndDropSiteViewFinishedPayload,
  isSiteRowDroppableSelectorCreator,
} from 'state/Roster/RosterDayView';
import {
  AreasRosterData,
  CreateShiftStartPayload,
  RosterDayViewCreatedShiftType,
  SiteViewAddRowPayload,
  UpdateShiftTimePayload,
} from 'state/Roster/RosterDayView/types';
import { BOX_ROSTER_SHIFT_MODAL_GET_BY_ID } from 'state/Roster/RosterShiftModal';
import { GetRosterByIDPayload } from 'state/Roster/RosterShiftModal/types';
import { BOX_TIMESHEET_SHIFT_MODAL_GET_BY_ID } from 'state/TimesheetModal';
import { GetTimesheetByIDPayload } from 'state/TimesheetModal/types';
import {
  RosterAreaLegend,
  RosterCellType,
  RosterRow,
} from '../../../../../../components';
import {
  DaySiteRosteredShift,
  Timeline,
  WithLegendData,
  DaySiteTimesheet,
} from '../../../../components';
import { DaySiteCreatedShift } from './DaySiteCreatedShift';
import { calculateStart } from '../../../../helpers';

type TimelineRow = ComponentProps<typeof Timeline>['rows'][number];

type OwnProps = {
  rosterData: AreasRosterData;
};

type StateProps = {
  filtersByType: RosterFiltersByType;
  from: Moment;
  isRowDroppable: boolean;
};

type DispatchProps = {
  updateRosteredShiftTime: (payload: UpdateShiftTimePayload) => void;
  updateTimesheetTime: (payload: UpdateShiftTimePayload) => void;
  getRosterById: (payload: GetRosterByIDPayload) => void;
  getTimesheetById: (payload: GetTimesheetByIDPayload) => void;
  createShift: (payload: CreateShiftStartPayload) => void;
  addRow: (payload: SiteViewAddRowPayload) => void;
  dragAndDropFinished: (payload: DragAndDropSiteViewFinishedPayload) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

class DaySiteRowComponent extends Component<Props> {
  render() {
    const {
      rosterData: { role, duration, isArchived },
    } = this.props;

    return (
      <RosterRow isDisabled={isArchived}>
        {{
          sticky: (
            <WithLegendData duration={duration}>
              {({ durations }) => (
                <RosterAreaLegend
                  type={this.rows.length > 1 ? 'multi-row' : 'single-row'}
                  label={role.name}
                  icon={''}
                  durations={durations}
                  colour={''}
                  onClick={this.onLegendButtonClick}
                  pageType={'day'}
                />
              )}
            </WithLegendData>
          ),
          content: <Timeline rows={this.rows} />,
        }}
      </RosterRow>
    );
  }

  private onLegendButtonClick = () => {
    const {
      addRow,
      rosterData: { area, role, isArchived },
    } = this.props;

    if (!isArchived) {
      addRow({
        area_id: area.id,
        role_id: role.id,
      });
    }
  };

  private get rows(): TimelineRow[] {
    const rows = this.getPairRows();

    if (!rows.length) {
      return [
        {
          type: 'full',
          content: null,
        },
      ];
    }

    return rows;
  }

  private getPairRows(): TimelineRow[] {
    const { pairs, isArchived, area, role } = this.props.rosterData;
    return pairs.flatMap((pair, pairIndex) => {
      const { timesheets, rosteredShift } = pair;

      const { filtersByType, from } = this.props;
      const pairRows: TimelineRow[] = [];

      if (filtersByType.roster) {
        const { roster: type } = this.cellTypes;

        const rosteredShiftRow: TimelineRow = {
          type,
          label: rosterPageLabels.rosteredShift,
          content: (
            <>
              {!!rosteredShift.data && (
                <DaySiteRosteredShift
                  key={rosteredShift.data.id}
                  rosteredShift={rosteredShift.data}
                  day={from}
                  type={this.cellTypes.roster}
                  onResizeEnd={this.props.updateRosteredShiftTime}
                  onClick={this.props.getRosterById}
                  isEditable={!isArchived}
                />
              )}

              <DaySiteCreatedShift
                cellType={type}
                shiftType={'rosteredShift'}
                rowIndex={pairIndex}
                areaId={area.id}
                roleId={role.id}
              />
            </>
          ),
          onMouseDown: this.onMouseDownCreator({
            type: 'rosteredShift',
            pairIndex,
          }),
          onDrop: this.onDrop,
          validateDragOver: this.validateDragOver,
          isTall: rosteredShift.shiftWithEvent,
        };

        pairRows.push(rosteredShiftRow);
      }

      if (filtersByType.timesheets) {
        const { timesheets: type } = this.cellTypes;

        const timesheetRow: TimelineRow = {
          type,
          label: rosterPageLabels.timesheet,
          content: (
            <>
              {!!timesheets.data && (
                <DaySiteTimesheet
                  key={timesheets.data.id}
                  timesheet={timesheets.data}
                  type={this.cellTypes.timesheets}
                  onResizeEnd={this.props.updateTimesheetTime}
                  onClick={this.props.getTimesheetById}
                  isEditable={!isArchived}
                  day={from}
                />
              )}

              <DaySiteCreatedShift
                cellType={type}
                shiftType={'timesheet'}
                rowIndex={pairIndex}
                areaId={area.id}
                roleId={role.id}
              />
            </>
          ),
          onMouseDown: this.onMouseDownCreator({
            type: 'timesheet',
            pairIndex,
          }),
        };

        pairRows.push(timesheetRow);
      }

      return pairRows;
    });
  }

  private get cellTypes(): {
    roster: RosterCellType;
    timesheets: RosterCellType;
  } {
    const {
      filtersByType: { roster, timesheets },
    } = this.props;

    return roster && timesheets
      ? {
          roster: 'top',
          timesheets: 'bottom',
        }
      : {
          roster: 'full',
          timesheets: 'full',
        };
  }

  private onMouseDownCreator =
    ({
      type,
      pairIndex,
    }: {
      type: RosterDayViewCreatedShiftType;
      pairIndex: number;
    }) =>
    (duration: Duration, clientX: number) => {
      const {
        createShift,
        rosterData: { role, area, isArchived },
        from,
      } = this.props;

      if (isArchived) {
        return;
      }

      const payload: CreateShiftStartPayload = {
        shift: {
          start: calculateStart(from, duration),
          role_id: role.id,
          area_id: area.id,
        },
        clientX,
        pairIndex,
        type,
      };

      createShift(payload);
    };

  private onDrop = (duration: Duration) => {
    if (!this.validateDragOver()) {
      return;
    }

    const { isArchived } = this.props.rosterData;

    if (isArchived) {
      return;
    }

    const {
      rosterData: {
        role: { id: roleId },
        area: { id: areaId },
      },
      from,
      dragAndDropFinished,
    } = this.props;

    const newStartTime = calculateStart(from, duration);

    dragAndDropFinished({
      newStartTime,
      areaId,
      roleId,
    });
  };

  private validateDragOver = (): boolean => this.props.isRowDroppable;
}

const makeMapStateToProps = (_: StoreState, ownProps: OwnProps) => {
  const {
    area: { id: areaId },
    role: { id: roleId },
  } = ownProps.rosterData;

  const isSiteRowDroppableSelector = isSiteRowDroppableSelectorCreator({
    areaId,
    roleId,
  });

  return (state: StoreState): StateProps => ({
    filtersByType: filtersByTypeSelector(state),
    from: fromBySiteTimezoneSelector(state),
    isRowDroppable: isSiteRowDroppableSelector(state),
  });
};

const mapDispatchToProps: DispatchProps = {
  updateRosteredShiftTime:
    BOX_ROSTER_DAY_VIEW_UPDATE_ROSTERED_SHIFT_TIME_REQUEST,
  updateTimesheetTime: BOX_ROSTER_DAY_VIEW_UPDATE_TIMESHEET_TIME_REQUEST,
  getRosterById: BOX_ROSTER_SHIFT_MODAL_GET_BY_ID,
  getTimesheetById: BOX_TIMESHEET_SHIFT_MODAL_GET_BY_ID,
  createShift: BOX_ROSTER_DAY_VIEW_CREATE_SHIFT_START,
  addRow: BOX_ROSTER_DAY_VIEW_SITE_VIEW_ROW_ADD,
  dragAndDropFinished: BOX_ROSTER_DAY_VIEW_DRAG_AND_DROP_SITE_VIEW_FINISHED,
};

export const DaySiteRow = connect(
  makeMapStateToProps,
  mapDispatchToProps
)(DaySiteRowComponent);
