import { useSelector } from 'react-redux';
import { getCurrentTimesheet } from 'state/TimesheetModal';
import { useSelectorWithProps } from 'hooks';
import { getGeoFencingSettingsBySiteId } from 'state/AccountTree';

export const useFencingSettings = (): Partial<google.maps.CircleOptions> | undefined => {
  const { site_id } = useSelector(getCurrentTimesheet);
  const geoSettings = useSelectorWithProps(getGeoFencingSettingsBySiteId, site_id);
  if (geoSettings.geo_enabled && geoSettings.address_lat && geoSettings.address_lng) {
    return {
      center: {
        lat: geoSettings.address_lat,
        lng: geoSettings.address_lng
      },
      radius: geoSettings.address_radius
    }
  }

  return undefined;
};