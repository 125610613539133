import React, { Component } from 'react';
import { getClass } from '../_lib/helper';
import { BulkActionProps } from './type';
import Button from '../Button';
import BulkactionButtons from './component/BulkactionButtons';
import './BulkAction.scss';

class BulkAction extends Component<BulkActionProps> {
  static displayName = 'BulkAction';

  handleClose = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  };

  render() {
    const { id, isVisible, actions, isDisabled } = this.props;

    return (
      <div
        id={id}
        data-testid={`elmo-bulk-actions-${id || 'default'}`}
        className={getClass('elmo-bulk-actions', {}, { open: isVisible })}
      >
        <div className="elmo-bulk-actions__wrapper">
          <BulkactionButtons actions={actions} isDisabled={isDisabled} />
          <div className="elmo-bulk-actions__close">
            <Button type="primary" isText={true} onClick={this.handleClose}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default BulkAction;
