import { createReducer } from 'lib/store-utils';
import { FlatAreaRoleModalReducerState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';

export const flatAreaRoleModal = createReducer<FlatAreaRoleModalReducerState>(
  {},
  getDefaultState()
);

flatAreaRoleModal.on(
  actions.BOX_SITE_FLAT_AREA_ROLE_MODAL_OPEN,
  (state, payload): FlatAreaRoleModalReducerState => {
    const hasSelectedAreaIds = !!(
      payload.selectedAreaIds && payload.selectedAreaIds.length
    );
    const hasSelectedRoleIds = !!(
      payload.selectedRoleIds && payload.selectedRoleIds.length
    );

    return {
      ...state,
      isResetButtonEnabled: hasSelectedAreaIds || hasSelectedRoleIds,
      isOpened: true,
      ...payload
    };
  }
);

const closeHandler = (
  state: FlatAreaRoleModalReducerState
): FlatAreaRoleModalReducerState => ({
  ...state,
  isOpened: false
});

flatAreaRoleModal.on(
  actions.BOX_SITE_FLAT_AREA_ROLE_MODAL_SUBMIT,
  closeHandler
);
flatAreaRoleModal.on(actions.BOX_SITE_FLAT_AREA_ROLE_MODAL_CLOSE, closeHandler);
flatAreaRoleModal.on(
  actions.BOX_SITE_FLAT_AREA_ROLE_MODAL_CHANGE_ROLES,
  (state, selectedRoleIds): FlatAreaRoleModalReducerState => ({
    ...state,
    selectedRoleIds
  })
);
flatAreaRoleModal.on(
  actions.BOX_SITE_FLAT_AREA_ROLE_MODAL_UPDATE_SELECTED,
  (state, partialState): FlatAreaRoleModalReducerState => ({
    ...state,
    ...partialState
  })
);
