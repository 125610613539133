import { createAction } from 'lib/store-utils';
import { ToastNotifierMessageOpenPayload, ToastNotifierMessageShowPayload } from './types';

export const BOX_TOAST_NOTIFIER_MESSAGE_SHOW = createAction<ToastNotifierMessageShowPayload>('Toast notifier / show message');

export const BOX_TOAST_NOTIFIER_UNDO = createAction<string>('Toast notifier / undo');
export const BOX_TOAST_NOTIFIER_CLOSE = createAction<string>('Toast notifier / close');

export const BOX_TOAST_NOTIFIER_MESSAGE_ADD = createAction<ToastNotifierMessageOpenPayload>('Toast notifier / internal action / add message');
export const BOX_TOAST_NOTIFIER_MESSAGE_OPEN = createAction<string>('Toast notifier / internal action / open message');
export const BOX_TOAST_NOTIFIER_MESSAGE_HIDE = createAction<string>('Toast notifier / internal action / hide message');
export const BOX_TOAST_NOTIFIER_MESSAGE_REMOVE = createAction<string>('Toast notifier / internal action / remove message');
