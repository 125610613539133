import { WorkingFilters, WhosWorkingReducerState } from './types';
import { cloneDeep } from 'lodash';

export const defaultFilters: WorkingFilters = {
  customPeriod: 'now',
  breaks: 'all',
  sort: {
    column: 'name',
    direction: 'asc'
  }
};

export const getDefaultState = (): WhosWorkingReducerState => ({
  isFetching: false,
  workingShifts: [],
  errors: [],
  period: 'now',
  filtersForm: cloneDeep(defaultFilters),
  filters: cloneDeep(defaultFilters),
  isCustomFilter: false,
  modal: {
    isOpen: false,
    errors: []
  }
});
