import { createReducer } from 'lib/store-utils';
import moment from 'moment';
import { RosteredShiftsReducerState } from './types';
import { defaultState, selectedDayFormat } from './state';
import * as actions from './actions';

export const rosteredShifts = createReducer<RosteredShiftsReducerState>(
  {},
  defaultState
);

rosteredShifts.on(
  actions.BOX_ROSTERED_SHIFTS_RESET_DAY_TO_CURRENT,
  (state): RosteredShiftsReducerState => ({
    ...state,
    day: moment().format(selectedDayFormat)
  })
);

rosteredShifts.on(
  actions.BOX_ROSTERED_SHIFTS_ADD,
  (state): RosteredShiftsReducerState => ({
    ...state,
    overlapModal: {
      ...state.overlapModal,
      ignoreErrors: false,
      isUpdating: false,
      errors: []
    }
  })
);

rosteredShifts.on(
  actions.BOX_ROSTERED_SHIFTS_ADD_ONE,
  (state): RosteredShiftsReducerState => ({
    ...state,
    overlapModal: {
      ...state.overlapModal,
      ignoreErrors: false,
      isUpdating: false,
      errors: []
    }
  })
);

rosteredShifts.on(
  actions.BOX_ROSTERED_SHIFTS_UNDO_LIST,
  (state): RosteredShiftsReducerState => ({
    ...state,
    undoIsUpdating: true,
    errors: []
  })
);

rosteredShifts.on(
  actions.BOX_ROSTERED_SHIFTS_UNDO_LIST_SUCCESS,
  (state, response): RosteredShiftsReducerState => ({
    ...state,
    undoList: response,
    undoIsUpdating: false,
    errors: []
  })
);

rosteredShifts.on(
  actions.BOX_ROSTERED_SHIFTS_UNDO_LIST_FAILURE,
  (state, errors): RosteredShiftsReducerState => ({
    ...state,
    undoIsUpdating: false,
    errors: errors
  })
);

rosteredShifts.on(
  actions.BOX_ROSTERED_SHIFT_GLOBAL_UNDO_SHIFT,
  (state): RosteredShiftsReducerState => ({
    ...state,
    undoIsUpdating: true,
    errors: []
  })
);

rosteredShifts.on(
  actions.BOX_ROSTERED_SHIFT_GLOBAL_UNDO_SHIFT_SUCCESS,
  (state): RosteredShiftsReducerState => ({
    ...state,
    undoIsUpdating: false,
    errors: []
  })
);

rosteredShifts.on(
  actions.BOX_ROSTERED_SHIFT_GLOBAL_UNDO_SHIFT_FAILURE,
  (state, errors): RosteredShiftsReducerState => ({
    ...state,
    undoIsUpdating: false,
    errors: errors
  })
);

rosteredShifts.on(
  actions.BOX_ROSTERED_SHIFTS_SET_DAY,
  (state, day): RosteredShiftsReducerState => ({
    ...state,
    day: day
  })
);

rosteredShifts.on(
  actions.BOX_ROSTERED_SHIFTS_UNDO_MODAL_CLEAR_ERRORS,
  (state): RosteredShiftsReducerState => ({
    ...state,
    errors: []
  })
);

rosteredShifts.on(
  actions.BOX_ROSTERED_SHIFT_DRAG_N_DROP,
  (state, payload): RosteredShiftsReducerState => {
    return {
      ...state,
      dropInProgress: true,
      dropCanceled: false,
      droppedShift: payload,
      dropError: []
    };
  }
);

rosteredShifts.on(
  actions.BOX_ROSTERED_SHIFT_DRAG_N_DROP_SUCCESS,
  (state): RosteredShiftsReducerState => {
    return {
      ...state,
      dropInProgress: false,
      dropError: [],
      dropCanceled: false,
      overlapModal: {
        ...state.overlapModal,
        errors: [],
        ignoreErrors: false,
        isUpdating: false,
        isOpened: false
      }
    }
  }
);

rosteredShifts.on(
  actions.BOX_ROSTERED_SHIFT_DRAG_N_DROP_SUCCESS_COPY,
  (state): RosteredShiftsReducerState => {
    return {
      ...state,
      dropError: [],
      dropCanceled: false,
      overlapModal: {
        ...state.overlapModal,
        errors: [],
        ignoreErrors: false,
        isUpdating: false,
        isOpened: false
      }
    }
  }
);

rosteredShifts.on(
  actions.BOX_ROSTERED_SHIFT_DRAG_N_DROP_FAILURE,
  (state, error): RosteredShiftsReducerState => ({
    ...state,
    dropInProgress: false,
    dropError: error,
    dropCanceled: false,
    overlapModal: {
      ...state.overlapModal,
      errors: [],
      ignoreErrors: false,
      isUpdating: false,
      isOpened: false
    },
  })
);

rosteredShifts.on(
  actions.BOX_ROSTERED_SHIFT_OPEN_OVERLAP_MODAL,
  (state, errors): RosteredShiftsReducerState => ({
    ...state,
    dropCanceled: false,
    overlapModal: {
      ...state.overlapModal,
      errors: errors,
      isOpened: true
    }
  })
);

rosteredShifts.on(
  actions.BOX_ROSTERED_SHIFT_SET_DROP_CANCELED,
  (state, value): RosteredShiftsReducerState => ({
    ...state,
    dropCanceled: value
  })
);

rosteredShifts.on(
  actions.BOX_ROSTERED_SHIFT_CLOSE_OVERLAP_MODAL,
  (state, isUpdating ): RosteredShiftsReducerState => ({
    ...state,
    overlapModal: {
      ...state.overlapModal,
      errors: isUpdating ? state.overlapModal.errors : [],
      isOpened: false
    }
  })
);

rosteredShifts.on(
  actions.BOX_ROSTERED_SHIFT_CONFIRM_OVERLAP,
  (state): RosteredShiftsReducerState => ({
    ...state,
    overlapModal: {
      ...state.overlapModal,
      isOpened: true,
      isUpdating: true,
      ignoreErrors: true
    }
  })
);

rosteredShifts.on(
  actions.BOX_ROSTERED_SHIFT_SET_DRAGGABLE_SHIFT,
  (state, params): RosteredShiftsReducerState => ({
    ...state,
    draggableShift: params
  })
);

rosteredShifts.on(
  actions.BOX_ROSTERED_SHIFT_SET_STRETCHING_SHIFT,
  (state, params): RosteredShiftsReducerState => ({
    ...state,
    stretchingShift: params
  })
);

rosteredShifts.on(
  actions.BOX_ROSTERED_SHIFT_SET_DROP_INPROGRESS,
  (state, value): RosteredShiftsReducerState => ({
    ...state,
    dropInProgress: value
  })
);

rosteredShifts.on(
  actions.BOX_ROSTERED_SHIFTS_SET_BULK_PROPS,
  (state, { drop, isUpdating }): RosteredShiftsReducerState => ({
    ...state,
    dropInProgress: drop,
    overlapModal: {
      ...state.overlapModal,
      errors: isUpdating ? state.overlapModal.errors : [],
      isOpened: false
    }
  })
);

rosteredShifts.on(
  actions.BOX_ROSTERED_SHIFTS_BULK_CREATE,
  (state): RosteredShiftsReducerState => ({
    ...state,
    bulkCreateInProgress: true
  })
);

rosteredShifts.on(
  actions.BOX_ROSTERED_SHIFTS_BULK_CREATE_SUCCESS,
  (state): RosteredShiftsReducerState => ({
    ...state,
    bulkCreateInProgress: false,
    stretchingShift: null
  })
);

rosteredShifts.on(
  actions.BOX_ROSTERED_SHIFTS_BULK_CREATE_FAILURE,
  (state): RosteredShiftsReducerState => ({
    ...state,
    bulkCreateInProgress: false,
    stretchingShift: null
  })
);
