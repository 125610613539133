import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { BackendPager, SagaAction } from 'type';
import {
  Api,
  ManagerDashboardGetApplicableUsersResponse,
  ManagerDashboardGetTimeOffsResponse,
} from 'lib/Api';
import { formatError, getErrorStatus } from 'state/helpers';
import { notFirstAndEmptyPageCombiner } from 'state/combiners';
import { processApiRequest } from 'state/ProcessApiRequest';
import * as fetchPageData from 'state/FetchPageData';
import { getRotaUserListRequest } from 'state/UsersCollection';
import { getAccountTreeRequest } from 'state/AccountTree';
import { BOX_TOAST_NOTIFIER_MESSAGE_SHOW } from 'state/ToastNotifier';
import { getTimeOffsWithDashboardFilters } from 'state/ManagerDashboard/sagas';
import {
  ChangeTimeOffPayload,
  CreateTimeOffPayload,
  EditTimeOffPayload,
  PredefinedFilters,
  UnavailabilityConfirmApprovalModal,
  UnavailabilityConfirmCreationModal,
  UnavailabilityConfirmEditModal,
  UnavailabilityFilters,
} from '../types';
import { filterTypes } from '../constants';
import { createTimeOffHash, filtersToPayload } from '../helpers';
import {
  BOX_UNAVAILABILITY_APPROVE_FAILURE,
  BOX_UNAVAILABILITY_APPROVE_REQUEST,
  BOX_UNAVAILABILITY_APPROVE_SUCCESS,
  BOX_UNAVAILABILITY_CHANGE_PAGE_FAILURE,
  BOX_UNAVAILABILITY_CHANGE_PAGE_REQUEST,
  BOX_UNAVAILABILITY_CHANGE_PAGE_SIZE_FAILURE,
  BOX_UNAVAILABILITY_CHANGE_PAGE_SIZE_REQUEST,
  BOX_UNAVAILABILITY_CHANGE_PAGE_SIZE_SUCCESS,
  BOX_UNAVAILABILITY_CHANGE_PAGE_SUCCESS,
  BOX_UNAVAILABILITY_CONFIRM_APPROVAL_MODAL_OPEN,
  BOX_UNAVAILABILITY_CONFIRM_CREATION_MODAL_OPEN,
  BOX_UNAVAILABILITY_CONFIRM_EDIT_MODAL_OPEN,
  BOX_UNAVAILABILITY_CREATE_UNAVAILABILITY_FAILURE,
  BOX_UNAVAILABILITY_CREATE_UNAVAILABILITY_REQUEST,
  BOX_UNAVAILABILITY_CREATE_UNAVAILABILITY_SUCCESS,
  BOX_UNAVAILABILITY_DECLINE_FAILURE,
  BOX_UNAVAILABILITY_DECLINE_REQUEST,
  BOX_UNAVAILABILITY_DECLINE_SUCCESS,
  BOX_UNAVAILABILITY_DELETE_FAILURE,
  BOX_UNAVAILABILITY_DELETE_REQUEST,
  BOX_UNAVAILABILITY_DELETE_SUCCESS,
  BOX_UNAVAILABILITY_EDIT_UNAVAILABILITY_FAILURE,
  BOX_UNAVAILABILITY_EDIT_UNAVAILABILITY_REQUEST,
  BOX_UNAVAILABILITY_EDIT_UNAVAILABILITY_SUCCESS,
  BOX_UNAVAILABILITY_FORCE_APPROVE_FAILURE,
  BOX_UNAVAILABILITY_FORCE_APPROVE_REQUEST,
  BOX_UNAVAILABILITY_FORCE_APPROVE_SUCCESS,
  BOX_UNAVAILABILITY_FORCE_CREATE_UNAVAILABILITY_FAILURE,
  BOX_UNAVAILABILITY_FORCE_CREATE_UNAVAILABILITY_REQUEST,
  BOX_UNAVAILABILITY_FORCE_CREATE_UNAVAILABILITY_SUCCESS,
  BOX_UNAVAILABILITY_FORCE_EDIT_UNAVAILABILITY_FAILURE,
  BOX_UNAVAILABILITY_FORCE_EDIT_UNAVAILABILITY_REQUEST,
  BOX_UNAVAILABILITY_FORCE_EDIT_UNAVAILABILITY_SUCCESS,
  BOX_UNAVAILABILITY_GET_APPLICABLE_USERS,
  BOX_UNAVAILABILITY_GET_APPLICABLE_USERS_FAILURE,
  BOX_UNAVAILABILITY_GET_APPLICABLE_USERS_REQUEST,
  BOX_UNAVAILABILITY_GET_APPLICABLE_USERS_SUCCESS,
  BOX_UNAVAILABILITY_GET_PAGE_DATA_REQUEST,
  BOX_UNAVAILABILITY_GET_UNAVAILABILITY_LIST_SUCCESS,
  BOX_UNAVAILABILITY_SET_IS_CURRENTLY_UPDATING,
  BOX_UNAVAILABILITY_TOGGLE_FILTER_TYPE_FAILURE,
  BOX_UNAVAILABILITY_TOGGLE_FILTER_TYPE_REQUEST,
  BOX_UNAVAILABILITY_TOGGLE_FILTER_TYPE_SUCCESS,
  BOX_UNAVAILABILITY_UNAPPROVE_FAILURE,
  BOX_UNAVAILABILITY_UNAPPROVE_REQUEST,
  BOX_UNAVAILABILITY_UNAPPROVE_SUCCESS,
  BOX_UNAVAILABILITY_UNSET_IS_CURRENTLY_UPDATING,
  BOX_UNAVAILABILITY_UPDATE_CUSTOM_FILTERS_FAILURE,
  BOX_UNAVAILABILITY_UPDATE_CUSTOM_FILTERS_REQUEST,
  BOX_UNAVAILABILITY_UPDATE_CUSTOM_FILTERS_SUCCESS,
} from '../actions';
import {
  getApplicableUsers,
  getApproveModal,
  getConfirmCreationModal,
  getConfirmEditModal,
  getFilters,
  getFiltersForm,
  getPager,
} from '../selectors';

const SUCCESS_MESSAGES = {
  approve: 'Request approved',
  decline: 'Request declined',
  unapprove: 'Request unapproved',
  create: 'Request created',
  edit: 'Request updated',
  remove: 'Request deleted',
};

export const fetchFilteredTimeOffs = function* () {
  const filters: UnavailabilityFilters = yield select(getFilters);
  const { currentPage, pageSize }: BackendPager = yield select(getPager);

  const payload = filtersToPayload(filters, {
    page: currentPage,
    pageSize,
  });

  return yield call(getTimeOffsWithDashboardFilters, payload);
};

export const fetchPage = function* (page: number) {
  const filters: UnavailabilityFilters = yield select(getFilters);
  const { pageSize }: BackendPager = yield select(getPager);
  const payload = filtersToPayload(filters, { page, pageSize });

  return yield call(getTimeOffsWithDashboardFilters, payload);
};

export const getTimeOffsRequest = function* (): SagaIterator {
  const response: ManagerDashboardGetTimeOffsResponse = yield call(
    fetchFilteredTimeOffs
  );

  yield put(BOX_UNAVAILABILITY_GET_UNAVAILABILITY_LIST_SUCCESS(response));
};

export const getTimeOffsWithPageCheckRequest = function* () {
  let response: ManagerDashboardGetTimeOffsResponse;

  const currentPageResponse: ManagerDashboardGetTimeOffsResponse = yield call(
    fetchFilteredTimeOffs
  );

  const { pager } = currentPageResponse;
  const { notFirstAndEmptyPage, lastPageNumber } =
    notFirstAndEmptyPageCombiner(pager);

  if (notFirstAndEmptyPage) {
    response = yield call(fetchPage, lastPageNumber);
  } else {
    response = currentPageResponse;
  }

  yield put(BOX_UNAVAILABILITY_GET_UNAVAILABILITY_LIST_SUCCESS(response));
};

export const getPageData = function* (): SagaIterator {
  yield put(BOX_UNAVAILABILITY_UNSET_IS_CURRENTLY_UPDATING());
  yield put(
    fetchPageData.BOX_FETCH_PAGE_DATA_REQUEST(
      fetchPageData.MANAGER_DASHBOARD_UNAVAILABILITY
    )
  );
  try {
    yield all([
      call(getTimeOffsRequest),
      call(getRotaUserListRequest),
      call(getAccountTreeRequest),
    ]);

    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_SUCCESS(
        fetchPageData.MANAGER_DASHBOARD_UNAVAILABILITY
      )
    );
  } catch (error) {
    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_FAILURE(
        fetchPageData.MANAGER_DASHBOARD_UNAVAILABILITY
      )
    );
  }
};

export const togglePredefinedFilter = function* ({
  payload: selectedFilter,
}: SagaAction<PredefinedFilters>): SagaIterator {
  yield put(BOX_UNAVAILABILITY_UNSET_IS_CURRENTLY_UPDATING());
  try {
    const filters: UnavailabilityFilters = filterTypes[selectedFilter];
    const { pageSize }: BackendPager = yield select(getPager);

    const payload = filtersToPayload(filters, { page: 1, pageSize });

    const response: ManagerDashboardGetTimeOffsResponse = yield call(
      getTimeOffsWithDashboardFilters,
      payload
    );

    yield put(
      BOX_UNAVAILABILITY_TOGGLE_FILTER_TYPE_SUCCESS({
        filters,
        ...response,
        selectedFilter,
      })
    );
  } catch (error) {
    yield put(
      BOX_UNAVAILABILITY_TOGGLE_FILTER_TYPE_FAILURE(formatError(error))
    );
  }
};

export const changeCustomFilters = function* (): SagaIterator {
  yield put(BOX_UNAVAILABILITY_UNSET_IS_CURRENTLY_UPDATING());
  try {
    const filters: UnavailabilityFilters = yield select(getFiltersForm);
    const { pageSize }: BackendPager = yield select(getPager);

    const payload = filtersToPayload(filters, { page: 1, pageSize });

    const response: ManagerDashboardGetTimeOffsResponse = yield call(
      getTimeOffsWithDashboardFilters,
      payload
    );

    yield put(
      BOX_UNAVAILABILITY_UPDATE_CUSTOM_FILTERS_SUCCESS({
        filters,
        ...response,
        selectedFilter: 'custom',
      })
    );
  } catch (error) {
    yield put(
      BOX_UNAVAILABILITY_UPDATE_CUSTOM_FILTERS_FAILURE(formatError(error))
    );
  }
};

export const changePage = function* ({
  payload,
}: SagaAction<number>): SagaIterator {
  yield put(BOX_UNAVAILABILITY_UNSET_IS_CURRENTLY_UPDATING());
  try {
    const response: ManagerDashboardGetTimeOffsResponse = yield call(
      fetchPage,
      payload
    );

    yield put(BOX_UNAVAILABILITY_CHANGE_PAGE_SUCCESS(response));
  } catch (error) {
    yield put(BOX_UNAVAILABILITY_CHANGE_PAGE_FAILURE(formatError(error)));
  }
};

export const changePageSize = function* ({
  payload: pageSize,
}: SagaAction<number>): SagaIterator {
  yield put(BOX_UNAVAILABILITY_UNSET_IS_CURRENTLY_UPDATING());
  try {
    const filters: UnavailabilityFilters = yield select(getFilters);
    const payload = filtersToPayload(filters, { page: 1, pageSize });

    const response: ManagerDashboardGetTimeOffsResponse = yield call(
      getTimeOffsWithDashboardFilters,
      payload
    );

    yield put(BOX_UNAVAILABILITY_CHANGE_PAGE_SIZE_SUCCESS(response));
  } catch (error) {
    yield put(BOX_UNAVAILABILITY_CHANGE_PAGE_SIZE_FAILURE(formatError(error)));
  }
};

export const approveTimeOff = function* ({
  payload,
}: SagaAction<ChangeTimeOffPayload>): SagaIterator {
  const { request, OVERLAP_ERROR_STATUS } = Api.Timeoffs.approveTimeOff;

  try {
    yield put(BOX_UNAVAILABILITY_SET_IS_CURRENTLY_UPDATING(payload.timeOffId));

    yield call(processApiRequest, request, payload);

    yield call(getTimeOffsWithPageCheckRequest);

    yield put(BOX_UNAVAILABILITY_APPROVE_SUCCESS());

    yield put(BOX_TOAST_NOTIFIER_MESSAGE_SHOW(SUCCESS_MESSAGES.approve));
  } catch (error) {
    const status: number = getErrorStatus(error);

    if (status === OVERLAP_ERROR_STATUS) {
      yield put(
        BOX_UNAVAILABILITY_CONFIRM_APPROVAL_MODAL_OPEN({
          payload: payload,
          message: formatError(error)[0].split('\n'),
        })
      );
    } else {
      yield put(BOX_UNAVAILABILITY_APPROVE_FAILURE(formatError(error)));
    }
  }
};

export const approveTimeOffForce = function* (): SagaIterator {
  try {
    const { timeOffId }: UnavailabilityConfirmApprovalModal = yield select(
      getApproveModal
    );

    yield put(BOX_UNAVAILABILITY_SET_IS_CURRENTLY_UPDATING(timeOffId));

    yield call(processApiRequest, Api.Timeoffs.approveTimeOff.request, {
      timeOffId,
      ignore_errors: true,
    });

    yield call(getTimeOffsWithPageCheckRequest);

    yield put(BOX_UNAVAILABILITY_FORCE_APPROVE_SUCCESS());

    yield put(BOX_TOAST_NOTIFIER_MESSAGE_SHOW(SUCCESS_MESSAGES.approve));
  } catch (error) {
    yield put(BOX_UNAVAILABILITY_FORCE_APPROVE_FAILURE(formatError(error)));
  }
};

export const declineTimeOff = function* ({
  payload,
}: SagaAction<ChangeTimeOffPayload>): SagaIterator {
  try {
    yield put(BOX_UNAVAILABILITY_SET_IS_CURRENTLY_UPDATING(payload.timeOffId));
    yield call(processApiRequest, Api.Timeoffs.declineTimeOff, payload);
    yield call(getTimeOffsWithPageCheckRequest);

    yield put(BOX_UNAVAILABILITY_DECLINE_SUCCESS());
    yield put(BOX_TOAST_NOTIFIER_MESSAGE_SHOW(SUCCESS_MESSAGES.decline));
  } catch (error) {
    yield put(BOX_UNAVAILABILITY_DECLINE_FAILURE(formatError(error)));
  }
};

export const unapproveTimeOff = function* ({
  payload,
}: SagaAction<ChangeTimeOffPayload>): SagaIterator {
  try {
    yield put(BOX_UNAVAILABILITY_SET_IS_CURRENTLY_UPDATING(payload.timeOffId));
    yield call(processApiRequest, Api.Timeoffs.unapproveTimeOff, payload);
    yield call(getTimeOffsWithPageCheckRequest);

    yield put(BOX_UNAVAILABILITY_UNAPPROVE_SUCCESS());
    yield put(BOX_TOAST_NOTIFIER_MESSAGE_SHOW(SUCCESS_MESSAGES.unapprove));
  } catch (error) {
    yield put(BOX_UNAVAILABILITY_UNAPPROVE_FAILURE(formatError(error)));
  }
};

export const deleteTimeOff = function* ({
  payload,
}: SagaAction<ChangeTimeOffPayload>): SagaIterator {
  try {
    yield put(BOX_UNAVAILABILITY_SET_IS_CURRENTLY_UPDATING(payload.timeOffId));
    yield call(processApiRequest, Api.Timeoffs.deleteTimeOff, payload);
    yield call(getTimeOffsWithPageCheckRequest);

    yield put(BOX_UNAVAILABILITY_DELETE_SUCCESS());
    yield put(BOX_TOAST_NOTIFIER_MESSAGE_SHOW(SUCCESS_MESSAGES.remove));
  } catch (error) {
    yield put(BOX_UNAVAILABILITY_DELETE_FAILURE(formatError(error)));
  }
};

export const createTimeOff = function* ({
  payload,
}: SagaAction<CreateTimeOffPayload>): SagaIterator {
  const { request, OVERLAP_ERROR_STATUS } = Api.Timeoffs.createTimeOff;

  try {
    const hash = createTimeOffHash(payload);
    yield put(BOX_UNAVAILABILITY_SET_IS_CURRENTLY_UPDATING(hash));
    yield call(processApiRequest, request, payload);
    yield call(getTimeOffsRequest);

    yield put(BOX_UNAVAILABILITY_CREATE_UNAVAILABILITY_SUCCESS());
    yield put(BOX_TOAST_NOTIFIER_MESSAGE_SHOW(SUCCESS_MESSAGES.create));
  } catch (error) {
    const status: number = getErrorStatus(error);
    if (status === OVERLAP_ERROR_STATUS) {
      yield put(
        BOX_UNAVAILABILITY_CONFIRM_CREATION_MODAL_OPEN({
          payload: payload,
          message: formatError(error)[0].split('\n'),
        })
      );
    } else {
      yield put(
        BOX_UNAVAILABILITY_CREATE_UNAVAILABILITY_FAILURE(formatError(error))
      );
    }
  }
};

export const createTimeOffForce = function* (): SagaIterator {
  try {
    const { payload }: UnavailabilityConfirmCreationModal = yield select(
      getConfirmCreationModal
    );

    const hash = createTimeOffHash(payload);
    yield put(BOX_UNAVAILABILITY_SET_IS_CURRENTLY_UPDATING(hash));

    yield call(processApiRequest, Api.Timeoffs.createTimeOff.request, {
      ...payload,
      ignore_errors: true,
    });
    yield call(getTimeOffsRequest);

    yield put(BOX_UNAVAILABILITY_FORCE_CREATE_UNAVAILABILITY_SUCCESS());
    yield put(BOX_TOAST_NOTIFIER_MESSAGE_SHOW(SUCCESS_MESSAGES.create));
  } catch (error) {
    yield put(
      BOX_UNAVAILABILITY_FORCE_CREATE_UNAVAILABILITY_FAILURE(formatError(error))
    );
  }
};

export const editTimeOff = function* ({
  payload,
}: SagaAction<EditTimeOffPayload>): SagaIterator {
  const { request, OVERLAP_ERROR_STATUS } = Api.Timeoffs.editTimeOff;

  try {
    yield put(BOX_UNAVAILABILITY_SET_IS_CURRENTLY_UPDATING(payload.timeOffId));
    yield call(processApiRequest, request, payload);
    yield call(getTimeOffsRequest);

    yield put(BOX_UNAVAILABILITY_EDIT_UNAVAILABILITY_SUCCESS());
    yield put(BOX_TOAST_NOTIFIER_MESSAGE_SHOW(SUCCESS_MESSAGES.edit));
  } catch (error) {
    const status: number = getErrorStatus(error);
    if (status === OVERLAP_ERROR_STATUS) {
      yield put(
        BOX_UNAVAILABILITY_CONFIRM_EDIT_MODAL_OPEN({
          payload: payload,
          message: formatError(error)[0].split('\n'),
        })
      );
    } else {
      yield put(
        BOX_UNAVAILABILITY_EDIT_UNAVAILABILITY_FAILURE(formatError(error))
      );
    }
  }
};

export const editTimeOffForce = function* (): SagaIterator {
  try {
    const { payload }: UnavailabilityConfirmEditModal = yield select(
      getConfirmEditModal
    );
    yield put(BOX_UNAVAILABILITY_SET_IS_CURRENTLY_UPDATING(payload.timeOffId));
    yield call(processApiRequest, Api.Timeoffs.editTimeOff.request, {
      ...payload,
      ignore_errors: true,
    });
    yield call(getTimeOffsRequest);

    yield put(BOX_UNAVAILABILITY_FORCE_EDIT_UNAVAILABILITY_SUCCESS());
    yield put(BOX_TOAST_NOTIFIER_MESSAGE_SHOW(SUCCESS_MESSAGES.edit));
  } catch (error) {
    yield put(
      BOX_UNAVAILABILITY_FORCE_EDIT_UNAVAILABILITY_FAILURE(formatError(error))
    );
  }
};

const fetchApplicableUsers = function* (): SagaIterator {
  const { isFetched, isFetching }: ReturnType<typeof getApplicableUsers> =
    yield select(getApplicableUsers);

  if (!isFetched && !isFetching) {
    yield put(BOX_UNAVAILABILITY_GET_APPLICABLE_USERS_REQUEST());
    try {
      const userIds: ManagerDashboardGetApplicableUsersResponse = yield call(
        processApiRequest,
        Api.ManagerDashboard.getApplicableUsers
      );

      yield put(
        BOX_UNAVAILABILITY_GET_APPLICABLE_USERS_SUCCESS({
          userIds,
        })
      );
    } catch (error) {
      yield put(
        BOX_UNAVAILABILITY_GET_APPLICABLE_USERS_FAILURE(formatError(error))
      );
    }
  }
};

export const watchMainActions = function* (): SagaIterator {
  yield takeLatest(BOX_UNAVAILABILITY_GET_PAGE_DATA_REQUEST, getPageData);
  yield takeLatest(
    BOX_UNAVAILABILITY_TOGGLE_FILTER_TYPE_REQUEST,
    togglePredefinedFilter
  );
  yield takeLatest(
    BOX_UNAVAILABILITY_UPDATE_CUSTOM_FILTERS_REQUEST,
    changeCustomFilters
  );
  yield takeLatest(BOX_UNAVAILABILITY_CHANGE_PAGE_REQUEST, changePage);
  yield takeLatest(BOX_UNAVAILABILITY_CHANGE_PAGE_SIZE_REQUEST, changePageSize);
  yield takeLatest(BOX_UNAVAILABILITY_APPROVE_REQUEST, approveTimeOff);
  yield takeLatest(
    BOX_UNAVAILABILITY_FORCE_APPROVE_REQUEST,
    approveTimeOffForce
  );
  yield takeLatest(BOX_UNAVAILABILITY_DECLINE_REQUEST, declineTimeOff);
  yield takeLatest(BOX_UNAVAILABILITY_UNAPPROVE_REQUEST, unapproveTimeOff);
  yield takeLatest(BOX_UNAVAILABILITY_DELETE_REQUEST, deleteTimeOff);
  yield takeLatest(
    BOX_UNAVAILABILITY_CREATE_UNAVAILABILITY_REQUEST,
    createTimeOff
  );
  yield takeLatest(
    BOX_UNAVAILABILITY_FORCE_CREATE_UNAVAILABILITY_REQUEST,
    createTimeOffForce
  );
  yield takeLatest(BOX_UNAVAILABILITY_EDIT_UNAVAILABILITY_REQUEST, editTimeOff);
  yield takeLatest(
    BOX_UNAVAILABILITY_FORCE_EDIT_UNAVAILABILITY_REQUEST,
    editTimeOffForce
  );
  yield takeLatest(
    BOX_UNAVAILABILITY_GET_APPLICABLE_USERS,
    fetchApplicableUsers
  );
};
