import { useEditDialogContext } from 'contexts';
import {
  AlertErrorBoxDialog,
  BreaksFieldGroup,
  CloseIcon,
  DateRangeFieldGroup,
  dateRangeFieldGroupUtils,
  DialogContentNarrow,
  NotesField,
  RoleField,
  ShiftSummaryBlock,
} from 'element';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from 'extended-oxygen-elements';
import {
  useActions,
  useFormFields,
  useFormValidator,
  useSelectorWithProps,
  useSetFormField,
  useUpdateStatusEffect,
} from 'hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  BOX_TIMESHEETS_WEEKLY_DIALOG_ERRORS_CLOSED,
  BOX_TIMESHEETS_WEEKLY_TIMESHEET_UPDATE,
  getDialogErrors,
  getDialogRequestStatus,
  getDialogUpdating,
} from 'state/TimesheetsWeeklyPage';
import { userRolesBySiteSelector, userSelector } from 'state/UsersCollection';
import { RosterTimesheetListedStoppedWithPayEntries } from 'type';
import { ConnectedShiftField, ConnectedShiftFieldWrapper } from './components';
import createFormFields from './createFormFields';
import makePayload from './makePayload';
import validate from './validate';

type TimesheetDialogFormProps = {
  timesheet: RosterTimesheetListedStoppedWithPayEntries;
};

export default EditDialogForm;

export function EditDialogForm({ timesheet }: TimesheetDialogFormProps) {
  const [timesheetUpdate, dialogErrorsClosed] = useActions([
    BOX_TIMESHEETS_WEEKLY_TIMESHEET_UPDATE.request,
    BOX_TIMESHEETS_WEEKLY_DIALOG_ERRORS_CLOSED,
  ]);

  const loading = useSelector(getDialogUpdating);
  const errors = useSelector(getDialogErrors);
  const roleOptions = useSelectorWithProps(userRolesBySiteSelector, timesheet);
  const user = useSelectorWithProps(userSelector, timesheet.user_id);

  const { closeDialog } = useEditDialogContext();

  const { formFields, setFormFields } = useFormFields(() =>
    createFormFields(timesheet)
  );
  const { makeSetFormField } = useSetFormField(setFormFields);

  const { formErrors, submitForm } = useFormValidator(formFields, {
    loading,
    validate,
    makePayload,
    onSubmit: (payload) => {
      timesheetUpdate({
        ...payload,
        id: timesheet.id,
        user_id: timesheet.user_id,
      });
    },
  });

  useUpdateStatusEffect(getDialogRequestStatus, {
    success: closeDialog,
    cancel: closeDialog,
  });

  return (
    <>
      <DialogTitle
        endAdornment={
          <IconButton disabled={loading} onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        }
      >
        Edit timesheet - {user.prefered_or_full_name}
      </DialogTitle>

      <DialogContent data-testid="timesheet-modal-content">
        <AlertErrorBoxDialog
          BoxProps={{
            mb: 2,
          }}
          errors={errors}
          onClose={dialogErrorsClosed}
          data-testid="timesheet-modal-error"
        />

        <DialogContentNarrow>
          <RoleField
            options={roleOptions}
            value={formFields.role}
            error={formErrors.role}
            onChange={makeSetFormField('role')}
          />

          <ConnectedShiftFieldWrapper
            {...formFields}
            timesheet_id={timesheet.id}
            user_id={timesheet.user_id}
            site_id={timesheet.site_id}
          >
            <ConnectedShiftField
              value={formFields.rostered_shift_id}
              onChange={makeSetFormField('rostered_shift_id')}
            />
          </ConnectedShiftFieldWrapper>

          <Divider />

          <DateRangeFieldGroup
            values={formFields}
            onChange={setFormFields}
            errors={formErrors}
          />

          <Divider />

          <BreaksFieldGroup
            value={formFields.breaks}
            error={formErrors.breaks}
            onChange={makeSetFormField('breaks')}
          />
        </DialogContentNarrow>

        <Divider />

        <NotesField
          value={formFields.notes}
          error={formErrors.notes}
          onChange={makeSetFormField('notes')}
        />

        <ShiftSummaryBlock
          breaks={formFields.breaks}
          duration={dateRangeFieldGroupUtils.getDateRangeDurationMinutes(
            formFields
          )}
        />
      </DialogContent>

      <DialogActions>
        <Button
          disabled={loading}
          onClick={closeDialog}
          data-testid={'timesheet-modal-cancel'}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          loading={loading}
          onClick={submitForm}
          data-testid={'timesheet-modal-submit'}
        >
          Save Timesheet
        </Button>
      </DialogActions>
    </>
  );
}
