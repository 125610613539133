import React from 'react';
import { Moment } from 'moment';
import { WorkOffOutlinedIcon, CalendarIcon } from 'element';
import { getTimeFormattedShortenedWithoutZeros } from 'lib/helpers';
import { useSelector } from 'react-redux';
import { timeFormatSelector } from 'state/Account';
import { createStyles, makeStyles } from '@mui/styles';
import { ServerRosterRepeatableTimeOff, TimeOffType } from 'type';
import { TIME_FORMAT } from 'lib/config';
import { shortenTimeFormat } from '../../../helpers';

type Props = {
  start: Moment;
  end: Moment;
  type: TimeOffType | ServerRosterRepeatableTimeOff['type'];
  showIcon?: boolean;
};

const useTimeDetailsStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 12,
      fontWeight: 500,
      letterSpacing: 0.5,
      '& svg': {
        marginRight: 6,
      },
    },
  })
);
export const TimeDetails = ({ start, end, type, showIcon = true }: Props) => {
  const format = useSelector(timeFormatSelector);
  const styles = useTimeDetailsStyles();
  const formatTime = (time: Moment) => {
    if (format === TIME_FORMAT.hours_12) {
      const format = 'h:mm a';
      return getTimeFormattedShortenedWithoutZeros(time.format(format));
    }
    return shortenTimeFormat(time.format(format));
  };

  const icon = () => {
    return type === 'rostered_shift' ? (
      <CalendarIcon data-testid={'roster-icon'} />
    ) : (
      <WorkOffOutlinedIcon data-testid={'time-off-icon'} />
    );
  };

  return (
    <div className={styles.root}>
      {showIcon && icon()}
      <span>
        {formatTime(start)} - {formatTime(end)}
      </span>
    </div>
  );
};
