import React, { Component } from 'react';
import { getClass, isElementOfType } from '../_lib/helper';
import { PopoverBase } from '../_lib/component';
import { PopoverProps } from './type';
import './Popover.scss';

const { Content: PopoverContent, Target: PopoverTarget } = PopoverBase;

export class Popover extends Component<PopoverProps> {
  static Content = PopoverContent;
  static Target = PopoverTarget;
  static defaultProps = {
    tabIndex: 0,
  };

  getSubComponents() {
    const { children } = this.props;
    const subComponents: any = {};

    React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) {
        return;
      }

      if (isElementOfType(child, PopoverTarget)) {
        subComponents.PopoverTarget = child;
      } else if (isElementOfType(child, PopoverContent)) {
        subComponents.PopoverContent = child;
      }
    });

    return subComponents;
  }

  isPopperModeHover = () => {
    const { mode = 'hover' } = this.props;

    return mode === 'hover';
  };

  render() {
    const {
      id,
      className,
      position,
      mode,
      isInline,
      isAutoWidth,
      isNoPadding,
      tabIndex
    } = this.props;
    const subComponents = this.getSubComponents();
    const popoverContentId = `${id}-content`;

    return (
      <PopoverBase
        id={id}
        testId={`elmo-popover-${id || 'default'}`}
        className={getClass('elmo-popover', className, {
          hover: this.isPopperModeHover(),
        })}
        position={position}
        mode={mode}
        isInline={isInline}
        tabIndex={tabIndex}
        ariaDescribedby={popoverContentId}
        isSupportedFocusAndBlur={true}
      >
        <PopoverBase.Target className="elmo-popover__target">
          {subComponents.PopoverTarget}
        </PopoverBase.Target>
        <PopoverBase.Content
          className={getClass('elmo-popover__content', '', {
            'auto-width': isAutoWidth,
            'no-padding': isNoPadding,
          })}
          id={popoverContentId}
        >
          {subComponents.PopoverContent}
        </PopoverBase.Content>
      </PopoverBase>
    );
  }
}
