import {
  Box,
  Hidden,
  OutlinedInput,
  Typography,
  Visible,
} from 'extended-oxygen-elements';
import React from 'react';
import { WithChildren } from 'type';

type Props = WithChildren & {
  /** required for labeling field */
  id: string;
  noWrap?: boolean;
};

export default function Label(props: Props) {
  return (
    <>
      <Hidden up="sm">
        <LabelMobile {...props} />
      </Hidden>

      <Visible up="sm">
        <LabelDesktop {...props} />
      </Visible>
    </>
  );
}

function LabelMobile({ children, id }: Props) {
  return (
    <Typography variant="body2" color="textSecondary" id={id}>
      {children}
    </Typography>
  );
}

function LabelDesktop({ children, id, noWrap = true }: Props) {
  return (
    <Box
      pr={2}
      mr="auto"
      minWidth={0}
      display="flex"
      alignItems="center"
      id={id}
    >
      <Box width={0} overflow="hidden">
        <OutlinedInput disabled aria-hidden="true" />
      </Box>

      <Typography noWrap={noWrap}>{children}</Typography>
    </Box>
  );
}
