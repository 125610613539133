import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { Api } from 'lib/Api';
import { UserBulkAddRequest, UserUpdateResponse } from 'lib/Api/type';
import { SagaAction, ServerUserFields } from 'type';
import { formatError } from 'state/helpers';
import { getUserGroupsRequest } from 'state/UserGroups/sagas';
import { BOX_TOAST_NOTIFIER_MESSAGE_SHOW } from 'state/ToastNotifier';
import * as fetchActions from 'state/FetchPageData';
import {
  processApiRequest,
  processApiRequestWithConfirm,
} from 'state/ProcessApiRequest/sagas';
import {
  BOX_USERS_COLLECTION_SET_OR_DELETE_ONE,
  BOX_USERS_COLLECTION_USER_CHANGED,
  getUserListRequest,
} from 'state/UsersCollection';
import {
  BOX_BULK_CLEAR_SELECTED_IF_ALL_ITEMS_CHANGED,
  selectedIdsSelector,
} from 'state/Bulk';
import * as actions from '../actions';
import { getAddRoleModalUserId, getEditApproversUserId } from '../selectors';
import {
  AddRolePayload,
  CreateUserPayload,
  EditApproversPayload,
  SendWelcomeNotificationPayload,
} from '../types';
import { editUserRequest } from '../../sagas';
import { getLangPreferences } from 'state/Account';
import { capitalize } from 'lib/helpers';

export const getUserListData = function* (): SagaIterator {
  yield put(fetchActions.BOX_FETCH_PAGE_DATA_REQUEST(fetchActions.USERS));
  try {
    yield all([
      call(getUserListRequest),
      call(getUserGroupsRequest),
      // call(getPermissionsRequest),
    ]);
    yield put(fetchActions.BOX_FETCH_PAGE_DATA_SUCCESS(fetchActions.USERS));
  } catch (error) {
    yield put(fetchActions.BOX_FETCH_PAGE_DATA_FAILURE(fetchActions.USERS));
  }
};

const addUserRole = function* ({
  payload,
}: SagaAction<AddRolePayload>): SagaIterator {
  try {
    const { user_roles } = payload;
    const id: string = yield select(getAddRoleModalUserId);

    const user: UserUpdateResponse = yield call(editUserRequest, {
      id,
      user_roles,
    });

    yield put(BOX_USERS_COLLECTION_SET_OR_DELETE_ONE({ id, user }));

    yield put(actions.BOX_USERS_ADD_ROLE_SUCCESS());
    yield put(BOX_TOAST_NOTIFIER_MESSAGE_SHOW('Positions updated'));
  } catch (error) {
    yield put(actions.BOX_USERS_ADD_ROLE_FAILURE(formatError(error)));
  }
};

const addUserRoleBulk = function* ({
  payload: { user_roles },
}: SagaAction<AddRolePayload>): SagaIterator {
  try {
    const ids: string[] = yield select(selectedIdsSelector);

    const apiPayload: UserBulkAddRequest = {
      user_ids: ids,
      data: {
        user_roles,
      },
    };
    yield call(processApiRequest, Api.User.bulkAdd, apiPayload);
    yield call(getUserListRequest);
    yield put(BOX_BULK_CLEAR_SELECTED_IF_ALL_ITEMS_CHANGED());
    yield put(actions.BOX_USERS_ADD_ROLE_BULK_SUCCESS());
    yield put(BOX_TOAST_NOTIFIER_MESSAGE_SHOW('Positions updated'));
  } catch (error) {
    yield put(actions.BOX_USERS_ADD_ROLE_BULK_FAILURE(formatError(error)));
  }
};

const editApprovals = function* ({
  payload,
}: SagaAction<EditApproversPayload>): SagaIterator {
  try {
    const { managers } = payload;
    const id = yield select(getEditApproversUserId);

    const user: UserUpdateResponse = yield call(editUserRequest, {
      id,
      managers,
    });

    yield put(BOX_USERS_COLLECTION_SET_OR_DELETE_ONE({ id, user }));

    yield put(actions.BOX_USERS_EDIT_APPROVALS_SUCCESS(payload));
    yield put(BOX_TOAST_NOTIFIER_MESSAGE_SHOW('Approvers updated'));
  } catch (error) {
    yield put(actions.BOX_USERS_EDIT_APPROVALS_FAILURE(formatError(error)));
  }
};

const editApprovalsBulk = function* ({
  payload: { managers },
}: SagaAction<EditApproversPayload>): SagaIterator {
  try {
    const ids: ReturnType<typeof selectedIdsSelector> = yield select(
      selectedIdsSelector
    );

    yield call(processApiRequest, Api.User.bulkSet, {
      user_ids: ids,
      data: {
        managers,
      },
    });

    yield call(getUserListRequest);
    yield put(BOX_BULK_CLEAR_SELECTED_IF_ALL_ITEMS_CHANGED());
    yield put(actions.BOX_USERS_EDIT_APPROVALS_BULK_SUCCESS());
    yield put(BOX_TOAST_NOTIFIER_MESSAGE_SHOW('Approvers updated'));
  } catch (error) {
    yield put(
      actions.BOX_USERS_EDIT_APPROVALS_BULK_FAILURE(formatError(error))
    );
  }
};

export const sendWelcomeNotificationsBulk = function* (): SagaIterator {
  try {
    const ids: ReturnType<typeof selectedIdsSelector> = yield select(
      selectedIdsSelector
    );
    yield call(processApiRequest, Api.User.sendWelcomeNotifications, {
      user_ids: ids,
    });
    yield put(actions.BOX_USERS_SEND_WELCOME_NOTIFICATIONS_SUCCESS());
    yield put(
      BOX_TOAST_NOTIFIER_MESSAGE_SHOW(`Welcome notification has been sent.`)
    );
  } catch (error) {
    yield put(
      actions.BOX_USERS_SEND_WELCOME_NOTIFICATIONS_FAILURE(formatError(error))
    );
  }
};

export const sendWelcomeNotifications = function* ({
  payload: { userId },
}: SagaAction<SendWelcomeNotificationPayload>): SagaIterator {
  try {
    yield call(processApiRequest, Api.User.sendWelcomeNotifications, {
      user_ids: [userId],
    });
    yield put(actions.BOX_USERS_SEND_WELCOME_NOTIFICATIONS_SUCCESS());
    yield put(
      BOX_TOAST_NOTIFIER_MESSAGE_SHOW(`Welcome notification has been sent.`)
    );
  } catch (error) {
    yield put(
      actions.BOX_USERS_SEND_WELCOME_NOTIFICATIONS_FAILURE(formatError(error))
    );
  }
};

const createUser = function* ({
  payload,
}: SagaAction<CreateUserPayload>): SagaIterator {
  try {
    const response: ServerUserFields = yield call(
      processApiRequest,
      Api.Standalone.User.create,
      payload
    );
    yield put(actions.BOX_USERS_CREATE_USER_SUCCESS(response));
    yield put(BOX_USERS_COLLECTION_USER_CHANGED(response));
  } catch (e) {
    yield put(actions.BOX_USERS_CREATE_USER_FAILURE(formatError(e)));
  }
};

const bulkAssignLocation = function* ({
  payload,
}: SagaAction<string>): SagaIterator {
  try {
    const langPreferences = yield select(getLangPreferences);
    const user_ids: ReturnType<typeof selectedIdsSelector> = yield select(
      selectedIdsSelector
    );
    const response: any = yield call(
      processApiRequestWithConfirm,
      Api.User.bulkAssignLocation,
      {
        user_ids,
        site_id: payload,
      }
    );
    yield put(actions.BOX_USERS_ASSIGN_LOCATION_BULK_MODAL_SUCCESS(response));
    yield call(getUserListRequest);
    yield put(
      BOX_TOAST_NOTIFIER_MESSAGE_SHOW(
        `${capitalize(langPreferences.site.singular)} assigned`
      )
    );
  } catch (e) {
    yield put(
      actions.BOX_USERS_ASSIGN_LOCATION_BULK_MODAL_FAILURE(formatError(e))
    );
  }
};

export const watchUsers = function* (): SagaIterator {
  yield takeLatest(
    actions.BOX_USERS_GET_USERS_PAGE_DATA_REQUEST,
    getUserListData
  );
  yield takeLatest(actions.BOX_USERS_ADD_ROLE_REQUEST, addUserRole);
  yield takeLatest(actions.BOX_USERS_ADD_ROLE_BULK_REQUEST, addUserRoleBulk);

  // Approvals:
  yield takeLatest(actions.BOX_USERS_EDIT_APPROVALS_REQUEST, editApprovals);
  yield takeLatest(
    actions.BOX_USERS_EDIT_APPROVALS_BULK_REQUEST,
    editApprovalsBulk
  );

  // Welcome notifications
  yield takeLatest(
    actions.BOX_USERS_BULK_SEND_WELCOME_NOTIFICATIONS_REQUEST,
    sendWelcomeNotificationsBulk
  );
  yield takeLatest(
    actions.BOX_USERS_SEND_WELCOME_NOTIFICATIONS_REQUEST,
    sendWelcomeNotifications
  );

  // Create user
  yield takeLatest(actions.BOX_USERS_CREATE_USER, createUser);

  yield takeLatest(
    actions.BOX_USERS_ASSIGN_LOCATION_BULK_MODAL_REQUEST,
    bulkAssignLocation
  );
};
