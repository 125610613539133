import React, { ReactNode } from 'react';
import { CircularProgress } from 'extended-oxygen-elements';
import clsx from 'clsx';
import './style.scss';

type Props = {
  children: ReactNode;
  isLoading: boolean;
  showSpinner?: boolean;
  /** tabIndex is for WCAG accessibility compliance (optional).
   * By default, `tabIndex` defaults to zero, which means the element
   * should be focusable in sequential keyboard navigation, in the
   * order defined by the document's source order.
   * It is recommended that you avoid using tabIndex values greater than 0.
   * Doing so makes it difficult for people who rely on assistive technology
   * to navigate and operate page content. Instead, write the document with
   * the elements in a logical sequence. See here for more info:
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex
   */
  tabIndex?: number;
  /** ariaLabel is for WCAG accessibility compliance, defaults to "Loading overlay" if omitted, (optional) */
  ariaLabel?: string;
  /** ariaLive is for WCAG accessibility compliance, defaults to "assertive" if omitted, (optional)
   * For more information see: https://www.w3.org/WAI/WCAG21/Techniques/aria/ARIA23
   */
  ariaLive?: 'assertive' | 'polite' | 'off';
};
const LoadingOverlay = ({
  isLoading,
  children,
  showSpinner,
  ariaLabel,
  ariaLive,
  tabIndex,
}: Props) => (
  <div
    className="elmo-loadingoverlay"
    role={isLoading ? 'progressbar' : undefined}
    aria-busy={isLoading}
    aria-label={ariaLabel}
    aria-live={ariaLive}
    tabIndex={tabIndex}
  >
    {showSpinner && isLoading && (
      <div
        className={clsx('elmo-loadingoverlay__overlay', {
          'is-loading': isLoading,
        })}
      >
        <CircularProgress />
      </div>
    )}

    <div
      className={clsx('elmo-loadingoverlay__content', {
        'is-loading': isLoading,
      })}
    >
      {children}
    </div>
  </div>
);

LoadingOverlay.defaultProps = {
  showSpinner: true,
  ariaLabel: 'Loading overlay',
  ariaLive: 'assertive',
  tabIndex: 0,
};

export default LoadingOverlay;
