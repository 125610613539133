import React from 'react';
import { useSelector } from 'react-redux';
import { Minutes } from 'type';
import { formatMinutesToTime, formatTimeToMinutes } from 'lib/helpers';
import { timeFormatSelector } from 'state/Account';
import { TextFieldProps } from 'extended-oxygen-elements';

type UseTimeFieldProps = {
  value: Minutes | null;
  onValueChange: (newValue: Minutes | null) => void;
} & Pick<TextFieldProps, 'onFocus' | 'onKeyDown' | 'onChange' | 'onBlur'>;

export function useTimeField(props: UseTimeFieldProps) {
  const [userInput, setUserInput] = React.useState('');
  const [isFocused, setIsFocused] = React.useState(false);
  const timeFormat = useSelector(timeFormatSelector);

  const formattedValue = props.value !== null ? formatMinutesToTime(props.value, timeFormat) : '';

  React.useEffect(() => {
    setUserInput(formattedValue);
  }, [formattedValue]);

  const triggerTimeChange = (updatedValue: number | null) => {
    if (updatedValue !== props.value) {
      props.onValueChange(updatedValue);
    }

    if (userInput !== formattedValue) {
      setUserInput(formattedValue);
    }
  };

  const formatInput = () => {
    if (!userInput) {
      triggerTimeChange(null);
    }

    try {
      const minutes = formatTimeToMinutes(userInput);

      triggerTimeChange(minutes);
    } catch (error) {
      triggerTimeChange(null);
    }
  };

  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.currentTarget?.select();
    setIsFocused(true);
    props.onFocus?.(event);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      formatInput();
    }
    props.onKeyDown?.(event);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(event.target.value);
    props.onChange?.(event);
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    formatInput();
    props.onBlur?.(event);
  };

  return {
    value: isFocused ? userInput : formattedValue,
    onFocus,
    onKeyDown,
    onChange,
    onBlur,
  };
}

export default useTimeField;
