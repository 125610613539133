import createAction from './createAction';
import { EmptyActionCreator, SimpleActionCreator } from './types';

export type AsyncActionCreator<PR, PS, PF, PC> = {
  request: PR extends void ? EmptyActionCreator : SimpleActionCreator<PR>;
  success: PS extends void ? EmptyActionCreator : SimpleActionCreator<PS>;
  failure: PF extends void ? EmptyActionCreator : SimpleActionCreator<PF>;
  cancel: PC extends void ? EmptyActionCreator : SimpleActionCreator<PC>;
};

export default createAsyncAction;

export function createAsyncAction<
  PR = void,
  PS = void,
  PF = void,
  PC = void
>(description: string): AsyncActionCreator<PR, PS, PF, PC> {
  return {
    request: createAction<PR>(description + '/ request'),
    success: createAction<PS>(description + '/ success'),
    failure: createAction<PF>(description + '/ failure'),
    cancel: createAction<PC>(description + '/ cancel'),
  } as any;
}
