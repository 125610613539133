import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LoadingOverlay } from 'elmo-elements';
import { StoreState } from 'state/types';
import { ListTable, EmptyMessage } from './components';
import { getPagerTotal } from '../../../../../../state/EmployeeDashboard/MyTimesheet/selectors';
import { getIsLoading } from '../../../../../../state/IsLoading';

type OwnProps = {};

type StateProps = {
  total: number;
  isUpdating: boolean;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

class MyTimesheetListComponent extends Component<Props> {
  render() {
    const { total, isUpdating } = this.props;

    return total ? (
      <LoadingOverlay isLoading={isUpdating}>
        <ListTable />
      </LoadingOverlay>
    ) : (
      <EmptyMessage />
    );
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  total: getPagerTotal(state),
  isUpdating: getIsLoading(state, 'EMPLOYEE_DASHBOARD_MY_TIMESHEET')
});

const mapDispatchToProps: DispatchProps = {};

export const MyTimesheetList = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyTimesheetListComponent);
