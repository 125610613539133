import React from 'react';
import { MessageBlock } from 'elmo-elements';

export const NotFoundMessage = () => {
  return (
    <MessageBlock>
      <MessageBlock.Header>No shifts were found matching your filter.</MessageBlock.Header>
    </MessageBlock>
  );
};
