import React from 'react';
import { LayoutContext } from 'elmo-elements';
import { getScrollY, scrollLock, setScrollY } from 'elmo-elements/_lib/helper';

function LegacyContextProvider({ children }: { children: React.ReactNode }) {
  const [isBulkActionOpen, setIsBulkActionOpen] = React.useState(false);
  const [isBulkActionDisabled, setIsBulkActionDisabled] = React.useState(true);
  const [isSearchOpen, setIsSearchOpen] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const toggleBulkActionOpen = () => {
    setIsBulkActionOpen(!isBulkActionOpen);
  };

  const toggleBulkActionDisabled = () => {
    setIsBulkActionDisabled(!isBulkActionDisabled);
  };

  const toggleSearchOpen = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const toggleModalOpen = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [bodyScrollTop, setBodyScrollTop] = React.useState(0);
  React.useEffect(() => {
    if (isModalOpen) {
      setBodyScrollTop(getScrollY());
      scrollLock(true);
    } else {
      scrollLock(false);
      setScrollY(bodyScrollTop);
    }
  }, [isModalOpen, bodyScrollTop]);

  // Legacy
  const isSidePanelOverlay = false;
  const toggleSidePanelOverlay = () => void 0;

  return (
    <LayoutContext.Provider
      value={{
        isSidePanelOverlay,
        isBulkActionOpen,
        isBulkActionDisabled,
        isSearchOpen,
        isModalOpen,
        toggleSidePanelOverlay,
        toggleBulkActionOpen,
        toggleBulkActionDisabled,
        toggleSearchOpen,
        toggleModalOpen,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
}

export default LegacyContextProvider;
