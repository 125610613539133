import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import Api, {
  BulkRosteredShiftsRequest,
  RosteredShiftCreateResponse,
} from 'lib/Api';
import { processApiRequestWithConfirm } from 'state/ProcessApiRequest';
import { RosteredShiftsGetPayload } from './types';
import {
  BOX_ROSTERED_SHIFTS_ADD,
  BOX_ROSTERED_SHIFTS_GET_SUCCESS,
} from './actions';
import { fetchRosteredShiftsList } from '../RosteredShiftsCollection';
import { SagaReturnType } from 'type';

export const getRosteredShiftsListRequest = function* (
  payload: RosteredShiftsGetPayload
): SagaIterator {
  const rosteredShifts: SagaReturnType<typeof fetchRosteredShiftsList> =
    yield call(fetchRosteredShiftsList, payload);

  yield put(
    BOX_ROSTERED_SHIFTS_GET_SUCCESS({
      rosteredShifts,
      ...payload,
    })
  );
};

export const createRosteredShiftRequest = function* (
  payload: BulkRosteredShiftsRequest
): SagaIterator {
  const response: RosteredShiftCreateResponse | undefined = yield call(
    processApiRequestWithConfirm,
    Api.RosteredShift.bulkCreate,
    payload
  );

  if (response) {
    yield put(BOX_ROSTERED_SHIFTS_ADD(response));
  }
};
