import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlinedIcon } from 'element';
import {
  BOX_ROSTER_BULK_DELETION_MODAL_OPEN,
  BOX_ROSTER_BULK_DELETION_MODE_DEACTIVATE,
} from 'state/Roster/BulkActions/actions';
import {
  bulkDeleteErrors,
  bulkDeleteIdsArraySelector,
  getBulkDeleteRosteredShiftsIdsArray,
  getIsBulkDeleteOpened,
  isBulkDeleting,
} from 'state/Roster/BulkActions/selectors';
import { Box, Button } from 'oxygen-elements';
import { useBulkDeleteContext } from 'contexts';
import { useUpdateEffect } from '../../../../hooks';

export default function BulkDeleteActions() {
  const selectedIds = useSelector(bulkDeleteIdsArraySelector);
  const selectedRosterIds = useSelector(getBulkDeleteRosteredShiftsIdsArray);
  const bulkDeleteContext = useBulkDeleteContext();
  const isDeleting = useSelector(isBulkDeleting);
  const errors = useSelector(bulkDeleteErrors);
  const dispatch = useDispatch();

  const labelText = selectedIds.length
    ? `Delete shifts (${selectedIds.length})`
    : 'Delete shifts';

  useUpdateEffect(() => {
    if (!isDeleting && !errors.length && !bulkDeleteContext.isEnabled) {
      bulkDeleteContext.disable();
    }
  }, [isDeleting, errors]);

  const handleCloseDeleteMode = () => {
    bulkDeleteContext.disable();
    dispatch(BOX_ROSTER_BULK_DELETION_MODE_DEACTIVATE());
  };

  const handleDeleteBtnClick = () => {
    dispatch(BOX_ROSTER_BULK_DELETION_MODAL_OPEN());
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        paddingLeft: '0',
        paddingRight: '0',
        '@media(min-width: 600px)': {
          paddingLeft: '20px',
          paddingRight: '20px',
        },
      }}
      width="100%"
    >
      <Button
        onClick={handleDeleteBtnClick}
        disabled={selectedRosterIds.length === 0}
        data-testid="bulk-delete-button"
        size={'medium'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <span style={{ display: 'inline-block', marginRight: '5px' }}>
          <DeleteOutlinedIcon />
        </span>
        {labelText}
      </Button>
      <Button onClick={handleCloseDeleteMode} size={'medium'}>
        Cancel
      </Button>
    </Box>
  );
}
