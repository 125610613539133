import React from 'react';

export function useBodyClassName(className: string) {
  React.useEffect(() => {
    const classNames = className.split(' ');

    document.body.classList.add(...classNames);

    return () => {
      document.body.classList.remove(...classNames);
    };
  }, [className])
}

export default useBodyClassName;
