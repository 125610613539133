import { AuditTrail, FormattedErrors, Pager } from '../../type';
import { MyRostersReducerState } from '../EmployeeDashboard/MyRosters/types';

export type AuditFilters = {
  action?: string;
  user_id?: string;
  from?: string;
  to?: string;
  shift_user_id?: string;
  shift_from?: string;
  shift_top?: string;
  id?: string;
}

export type AuditTrailReducerState = {
  isFetched: boolean;
  isFetching: boolean;
  auditTrail: AuditTrail;
  errors: FormattedErrors;
  users: any;
  filtersModal: {
    isOpened: boolean;
    isUpdating: boolean;
  };
  filters: AuditFilters,
  pager: Pager
}
export const getDefaultState = (): AuditTrailReducerState  => ({
  isFetched: false,
  isFetching: false,
  users: [],
  auditTrail: {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 0,
    last_page: 0,
    last_page_url: '',
    next_page_url: '',
    path: '',
    per_page: 0,
    prev_page_url: null,
    to: 0,
    total: 0
  },
  errors: [],
  filtersModal: {
    isOpened: false,
    isUpdating: false,
  },
  filters: {},
  pager: {
    pageSize: 30,
    currentPage: 1
  }
});

export type AuditBackendPager = AuditFilters & {
  page: number;
  per_page: number;
}