import React from 'react';
import { useDispatch } from 'react-redux';
import { useBulkDeleteContext } from 'contexts';
import {
  BOX_TIMESHEETS_WEEKLY_DIALOG_OPENED
} from 'state/TimesheetsWeeklyPage';
import { DeleteActions, DeleteDialog } from './components';

export function BulkDeleteActions() {
  const bulkContext = useBulkDeleteContext();
  const [ open, setOpen ] = React.useState(false);
  const dispatch = useDispatch();

  const handleDeleteBtnClick = () => {
    dispatch(BOX_TIMESHEETS_WEEKLY_DIALOG_OPENED());
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
    bulkContext.disable();
  }

  return (
    <>
      <DeleteActions onDeleteBtnClick={handleDeleteBtnClick} />

      <DeleteDialog open={open} onClose={handleDialogClose} />
    </>
  );
}
