import React from 'react';
import { Layout } from 'element';
import {
  UsersAddRole,
  UsersBulkAddRole,
  UsersBulkEditApprovers,
  UsersEditApprovers,
  UsersErrorBox,
  UsersFilter,
  UsersHeader,
  UsersListTable,
} from './components';
import {
  BulkSearchContextProvider,
  BulkUsersActionsContextProvider,
} from 'contexts';
import { AssignLocationsModal } from '../../components/AssignLocationsModal';

const UsersView = () => (
  <BulkSearchContextProvider>
    <BulkUsersActionsContextProvider>
      <Layout.Header>
        <UsersHeader />
      </Layout.Header>

      <Layout.Content>
        <UsersErrorBox />

        <UsersFilter />

        <UsersListTable />
      </Layout.Content>

      {/* Modals: */}
      <UsersAddRole />
      <UsersBulkAddRole />
      <UsersEditApprovers />
      <UsersBulkEditApprovers />
      <AssignLocationsModal />
    </BulkUsersActionsContextProvider>
  </BulkSearchContextProvider>
);

export default UsersView;
