import React from 'react';
import { getClass } from '../_lib/helper';
import { ExpandMoreIcon as KeyboardArrowDownIcon } from '../icons';
import { BadgeProps } from './type';
import './Badge.scss';

export function Badge({
  id,
  icon,
  role,
  type,
  children,
  className,
  hasPopoverIcon,
  isCircular,
}: BadgeProps) {
  return (
    <span
      id={id}
      className={getClass('elmo-badge', className, {
        [type]: type,
        lighter: children === '0' || children === 0,
        'is-circular': isCircular,
        [`role-${role}`]: role,
      })}
      data-testid={`elmo-badge-${id || 'default'}`}
    >
      {icon && <span className="elmo-badge__icon">{icon}</span>}
      {children && <span className="elmo-badge__label">{children}</span>}
      {hasPopoverIcon && (
        <span className="elmo-badge__popover-icon">
          <KeyboardArrowDownIcon />
        </span>
      )}
    </span>
  );
}

Badge.defaultProps = {
  role: 'status',
  type: 'grey',
};
