import { useCallback } from 'react';
import { BOX_ROSTER_BULK_DELETION_MODE_TOGGLE_SELECTED_SHIFT } from 'state/Roster/BulkActions';
import { useDispatch } from 'react-redux';
import { useSelectorWithProps } from 'hooks';
import { getTimesheet } from 'state/TimesheetsCollection';

type Props = {
  id: string;
};

export const useHandleClickOnBulk = ({ id }: Props) => {
  const dispatch = useDispatch();
  const timesheet = useSelectorWithProps(getTimesheet, id);
  const { user_id, area_id, role_id } = timesheet;
  return useCallback(() => {
     dispatch(
        BOX_ROSTER_BULK_DELETION_MODE_TOGGLE_SELECTED_SHIFT({
          id,
          user_id,
          type: 'timesheet',
          area_id,
          role_id,
        })
      );
    },
    [dispatch, id, user_id, area_id, role_id]
  )
};
