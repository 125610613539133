import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment';
import { isNaN } from 'lodash';
import {
  ButtonCard,
  Checkbox,
  FormItem,
  LoadingOverlay,
  Select,
} from 'elmo-elements';
import { getAreas, getRoles } from 'state/AccountTree';
import {
  AccountTreeArea,
  AccountTreeRole,
  EventSettings,
  LanguagePreferences,
  PreferencesDateFormat,
  PreferencesTimeFormat,
  RosteredShift,
  SelectPropsOption,
  ServerUserFields,
  StringMap,
} from 'type';
import {
  capitalize,
  formatStringDurationToMinutes,
  getArchivedOptions,
  getAreaPositionLabel,
  getEventsOptions,
  getSelectedAreaRole,
  getSelectedValue,
  getUserName,
} from 'lib/helpers';
import { StoreState } from 'state/types';
import { getUserListResponse } from 'state/UsersCollection';
import { hasPermissionSelector } from 'state/Auth';
import {
  getDateFormat,
  getEventSettings,
  getLangPreferences,
  getTimeFormat,
} from 'state/Account';
import DateTimeGroupImproved, {
  StartEndPeriod,
} from '../../../components/DateTimeGroupImproved';
import { isSiteView } from 'helpers/helpers';
import { BreakGroup as BreaksImproved } from '../../../components/BreakGroup';
import { RepeatShift } from '../RepeatShift';
import { RepeatShiftItem } from '../../types';
import { withFeatureFlags } from 'flags';
import { NotesFieldExpandable } from '../../../../NotesFieldExpandable';
import { getRosteredShiftModal } from 'state/Roster/RosterShiftModal';
import { RosteredShiftModalProps } from 'state/Roster/RosterShiftModal/types';

export type RosterEditFormOwnProps = {
  shift: RosteredShift;
  siteId: string;
  eventsList: any;
  openSubModal: (name: string) => void;
  setShiftProps: (name: string, value: any) => void;
  onChangeStartEnd: (period: StartEndPeriod, key: 'date' | 'time') => void;
  onChangeAreaRolePicker: (e: any) => void;
  areaRolesOptions: SelectPropsOption[];
  usersIsLoading: boolean;
  increaseTabIndex: () => number;
  onChangeBreaks: () => void;
  displayUsersAndPositionsFields?: boolean;
  repeatShift: RepeatShiftItem[];
  onChangeRepeatShift: (v: any) => void;
  disabledState?: {
    notify_user?: boolean;
  };
};

type StateProps = {
  users: StringMap<ServerUserFields>;
  canViewEvents: boolean;
  canEditEvents: boolean;
  langPreferences: LanguagePreferences;
  dateFormat: PreferencesDateFormat;
  timeFormat: PreferencesTimeFormat;
  eventSettings: EventSettings;
  roles: StringMap<AccountTreeRole>;
  areas: StringMap<AccountTreeArea>;
  modal: RosteredShiftModalProps;
};

type Props = RosterEditFormOwnProps & StateProps;

export type State = {
  shift: RosteredShift;
};

export class RosterEditFormComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      shift: props.shift,
    };
  }

  render() {
    const {
      canEditEvents,
      langPreferences,
      areaRolesOptions,
      displayUsersAndPositionsFields,
      disabledState = {},
    } = this.props;
    return (
      <>
        {(isSiteView() || displayUsersAndPositionsFields) && (
          <div className="shift-modal-inner">
            <FormItem label={'User'}>
              <LoadingOverlay
                isLoading={this.props.usersIsLoading}
                tabIndex={-1}
              >
                <ButtonCard
                  id={'choose-user-btn'}
                  className={'user-btn'}
                  title={this.getUserName()}
                  isDisabled={this.props.usersIsLoading}
                  onClick={() => this.props.openSubModal('users')}
                  tabIndex={0}
                />
              </LoadingOverlay>
            </FormItem>
          </div>
        )}

        {(!isSiteView() || displayUsersAndPositionsFields) && (
          <div className="shift-modal-inner spacing-bottom-extra-large">
            <FormItem label={getAreaPositionLabel(langPreferences)}>
              <Select
                id={'area-role-dropdown'}
                name={'area_role'}
                options={areaRolesOptions}
                defaultValue={getSelectedAreaRole(
                  areaRolesOptions,
                  this.props.shift
                )}
                onChange={this.props.onChangeAreaRolePicker}
                value={this.getAreaRoleValue()}
                ariaLabel={getAreaPositionLabel(langPreferences)}
                isDisabled={this.isAreaDisabled()}
              />
            </FormItem>
          </div>
        )}

        <DateTimeGroupImproved
          onChangePeriod={this.props.onChangeStartEnd}
          params={this.props.shift}
          siteId={this.props.siteId}
        />

        <BreaksImproved
          breaks={this.props.shift.breaks}
          onChangePicker={this.onChangeBreakPicker}
          onChangeDuration={this.onChangeBreakDuration}
          onChangeCheckbox={this.onChangePaidCheckbox}
          addBreak={this.addBreak}
          timeFormat={this.props.timeFormat}
        />

        <div className="shift-modal-inner spacing-bottom-medium">
          <NotesFieldExpandable
            value={this.props.shift.notes}
            setNotesValue={this.props.setShiftProps}
            tabIndex={0}
            maxTextLength={1000}
          />
        </div>
        <div className="shift-modal-inner spacing-bottom-extra-large">
          {!this.props.shift.id && (
            <RepeatShift
              selectedDays={this.props.repeatShift}
              onDayClick={this.props.onChangeRepeatShift}
              tabIndex={0}
            />
          )}
        </div>

        <div className="shift-modal-inner spacing-bottom-extra-large">
          {this.canSeeEventsDropDown() && (
            <FormItem label={capitalize(langPreferences.event.singular)}>
              <Select
                id={'event-dropdown'}
                name="event_id"
                options={getEventsOptions(this.props.eventsList)}
                defaultValue={getSelectedValue(
                  getEventsOptions(this.props.eventsList),
                  this.props.shift.event_id
                )}
                onChange={(e) => this.handleOnChangeSelect(e, 'event_id')}
                value={getSelectedValue(
                  getEventsOptions(this.props.eventsList),
                  this.props.shift.event_id
                )}
                isDisabled={!canEditEvents}
                ariaLabel={capitalize(langPreferences.event.singular)}
              />
            </FormItem>
          )}
        </div>
        <FormItem>
          <Checkbox
            name={'notify_now'}
            id={'notify-now-checkbox'}
            label={'Notify the user now and share shift'}
            isChecked={this.props.shift.notify_user}
            onChange={this.onChangeNotifyCheckbox}
            isDisabled={disabledState.notify_user}
            tabIndex={0}
            ariaLabel={'Notify the user now and share shift'}
          />
        </FormItem>
      </>
    );
  }

  canSeeEventsDropDown = () => {
    const { eventSettings, canViewEvents } = this.props;
    return canViewEvents && eventSettings.enable_events;
  };

  componentDidMount(): void {
    const usersBtn: null | HTMLButtonElement =
      document.querySelector('#choose-user-btn');
    if (usersBtn) {
      usersBtn.addEventListener('keypress', () => {
        this.props.openSubModal('users');
      });
      usersBtn.focus();
    }

    setTimeout(() => {
      this.setDefaultPosition();
    });
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.shift !== this.props.shift) {
      this.setState({
        shift: this.props.shift,
      });
    }
  }

  setDefaultPosition = () => {
    const { areaRolesOptions, shift, onChangeAreaRolePicker } = this.props;
    if (
      areaRolesOptions.length === 2 &&
      !shift.role_id.length &&
      !shift.area_id.length
    ) {
      onChangeAreaRolePicker(areaRolesOptions[1]);
    }
  };

  handleOnChangeSelect2 = (e: any[], name: string) => {
    this.props.setShiftProps(name, this.getIdsFromDropDown(e));
  };

  getIdsFromDropDown = (options: any[]) => {
    const ids: string[] = [];
    options.forEach((item: any) => {
      ids.push(item.value);
    });
    return ids;
  };

  onChangeNotifyCheckbox = (e: any) => {
    const { checked } = e.target;
    this.props.setShiftProps('notify_user', checked);
  };

  addBreak = () => {
    const {
      shift: { breaks, start },
    } = this.state;
    const startDate = moment(start).format('YYYY-MM-DD');
    breaks.push({
      start: moment(`${startDate} 12:00`),
      duration: 0,
      paid: false,
    });
    this.props.setShiftProps('breaks', breaks);
    this.props.onChangeBreaks();
  };

  onChangePaidCheckbox = (e: any, index: number) => {
    const {
      shift: { breaks },
    } = this.props;
    const {
      target: { checked },
    } = e;
    breaks[index].paid = checked;
    this.props.setShiftProps('breaks', breaks);
    this.props.onChangeBreaks();
  };

  onChangeBreakPicker = (e: Moment, name: string, index: number) => {
    const {
      shift: { breaks, start },
    } = this.state;
    const time = moment(e).format('HH:mm');
    const startDate = moment(start).format('YYYY-MM-DD');
    breaks[index].start = moment(`${startDate} ${time}`);
    this.props.setShiftProps('breaks', breaks);
    this.props.onChangeBreaks();
  };

  onChangeBreakDuration = (e: any, name: string, index: number) => {
    const {
      shift: { breaks, start, end },
    } = this.props;
    const {
      target: { value },
    } = e;
    const duration = formatStringDurationToMinutes(
      value.toString().replace(/-/i, '')
    );
    breaks[index].duration = isNaN(parseFloat(duration))
      ? 0
      : Math.abs(parseFloat(duration));
    this.props.setShiftProps('breaks', breaks);
    this.props.onChangeBreaks();
  };

  handleOnChangeSelect = (e: any, name: string) => {
    this.props.setShiftProps(name, e.value === 'null' ? null : e.value);
  };

  getUserName = () => {
    const { users } = this.props;
    const { user_id } = this.props.shift;
    return user_id ? getUserName(users[user_id]) : 'Unassigned';
  };

  isAreaDisabled = () => {
    const { areaRolesOptions } = this.props;
    const selectedRole = getSelectedAreaRole(
      areaRolesOptions,
      this.props.shift
    );
    const value = Array.isArray(selectedRole)
      ? selectedRole[0].value
      : selectedRole.value;
    return areaRolesOptions.length === 2 && value !== '__';
  };

  getAreaRoleValue = () => {
    const { areas, roles, areaRolesOptions } = this.props;
    const value = getSelectedAreaRole(areaRolesOptions, this.props.shift);
    return !Array.isArray(value)
      ? value
      : getSelectedAreaRole(getArchivedOptions(areas, roles), this.props.shift);
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  users: getUserListResponse(state),
  canViewEvents: hasPermissionSelector(state, 'roster.events.view'),
  canEditEvents: hasPermissionSelector(state, 'roster.events.edit'),
  langPreferences: getLangPreferences(state),
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
  eventSettings: getEventSettings(state),
  areas: getAreas(state),
  roles: getRoles(state),
  modal: getRosteredShiftModal(state),
});

export const RosterEditForm = connect(mapStateToProps)(
  withFeatureFlags(RosterEditFormComponent)
);
