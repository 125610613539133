import { createSelector } from 'reselect';
import { keys, pickBy } from 'lodash';
import { StoreState } from 'state/types';
import { FetchFlags, PageId } from './types';

const getPages = (state: StoreState) => state.fetchPageData.pages;
export const getFetchFlags = (state: StoreState, pageId: PageId): FetchFlags =>
  state.fetchPageData.pages[pageId] || { isFetched: false, isFetching: false };

export const fetchedPagesSelector = createSelector(getPages, (pages) =>
  keys(pickBy(pages, (page) => page!.isFetched)) as PageId[]
);

export const isPageFetchedSelector = createSelector(
  fetchedPagesSelector,
  (state: StoreState, pageId: PageId) => pageId,
  (fetchedPages, pageId) => fetchedPages.includes(pageId)
);
