import { createReducer } from 'lib/store-utils';
import { cloneDeep } from 'lodash';
import { RosterFiltersReducerState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';
import { getDefaultCustomFilters } from './helpers/helpers';

export const rosterFilters = createReducer<RosterFiltersReducerState>(
  {},
  getDefaultState()
);

rosterFilters.on(
  actions.BOX_ROSTER_FILTERS_TOGGLE_TIMESHEETS_FILTER,
  (state, value) => ({
    ...state,
    filters: {
      ...state.filters,
      timesheets: value,
    },
    filtersForm: {
      ...state.filtersForm,
      timesheets: value,
    },
  })
);

rosterFilters.on(actions.BOX_ROSTER_FILTERS_RESET_SHOW_PEOPLE, (state) => ({
  ...state,
  filtersForm: {
    ...state.filtersForm,
    showPeople: 'all',
  },
}));

rosterFilters.on(actions.BOX_ROSTER_FILTERS_FILTER_BY_TYPE_OPENED, (state) => ({
  ...state,
  isFilterByTypeOpened: true,
}));

rosterFilters.on(actions.BOX_ROSTER_FILTERS_FILTER_BY_TYPE_CLOSED, (state) => ({
  ...state,
  isFilterByTypeOpened: false,
}));

rosterFilters.on(
  actions.BOX_ROSTER_FILTERS_UPDATE_FILTERS_BY_TYPE,
  (state, partialFiltersByType): RosterFiltersReducerState => ({
    ...state,
    filters: {
      ...state.filters,
      ...partialFiltersByType,
    },
    filtersForm: {
      ...state.filtersForm,
      ...partialFiltersByType,
    },
  })
);

rosterFilters.on(
  actions.BOX_ROSTER_FILTERS_UPDATE_FILTER_FORM,
  (state, partialFilterForm): RosterFiltersReducerState => ({
    ...state,
    filtersForm: {
      ...state.filtersForm,
      ...partialFilterForm,
    },
  })
);

rosterFilters.on(
  actions.BOX_ROSTER_FILTERS_UPDATE_FILTER_FORM_FIELD,
  (state, { name, value }): RosterFiltersReducerState => ({
    ...state,
    filtersForm: {
      ...state.filtersForm,
      [name]: value,
    },
  })
);

rosterFilters.on(
  actions.BOX_ROSTER_FILTERS_CLEAR_FILTER_FORM,
  (state): RosterFiltersReducerState => ({
    ...state,
    filtersForm: cloneDeep(state.filters),
  })
);

rosterFilters.on(
  actions.BOX_ROSTER_FILTERS_SUBMIT_FILTER_FORM,
  (state): RosterFiltersReducerState => ({
    ...state,
    filters: cloneDeep(state.filtersForm),
  })
);

rosterFilters.on(actions.BOX_ROSTER_FILTERS_RESET_ALL_FILTERS, getDefaultState);

rosterFilters.on(
  actions.BOX_ROSTER_FILTERS_RESET_CUSTOM_FILTERS,
  (state): RosterFiltersReducerState => ({
    ...state,
    filters: {
      ...state.filters,
      ...getDefaultCustomFilters(),
    },
    filtersForm: {
      ...state.filtersForm,
      ...getDefaultCustomFilters(),
    },
  })
);
