import { createReducer } from 'lib/store-utils';
import moment from 'moment';
import * as timesheetsWeeklyPage from 'state/TimesheetsWeeklyPage';
import { TimesheetsWeekSummaryReducerState } from './types';

export const timesheetsWeekSummary =
  createReducer<TimesheetsWeekSummaryReducerState>(
    {},
    {
      payload: {
        site_id: '',
        from: moment(),
        status: 'all',
      },
      timesheetsWeekSummary: {
        days: {},
        total: {
          timesheet_cost: 0,
          timesheet_count: 0,
          timesheet_duration: 0,
          timesheet_percent: 0,
          timesheet_user_count: 0,
        },
      },
    }
  );

const updateHandler = (
  state: any,
  { payload, timesheetsWeekSummary }: TimesheetsWeekSummaryReducerState
): TimesheetsWeekSummaryReducerState => ({ payload, timesheetsWeekSummary });

timesheetsWeekSummary
  .on(
    timesheetsWeeklyPage.BOX_TIMESHEETS_WEEKLY_PAGE_DATA_GET.success,
    updateHandler
  )
  .on(
    timesheetsWeeklyPage.BOX_TIMESHEETS_WEEKLY_SUMMARY_UPDATE.success,
    updateHandler
  );
