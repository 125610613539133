import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import { selectedIdsSelector } from 'state/Bulk/selectors';
import { EditApproversPayload } from 'state/Users/Users/types';
import { getBulkEditApproversModal } from 'state/Users/Users/selectors';
import {
  BOX_USERS_EDIT_APPROVALS_BULK_MODAL_CLOSE,
  BOX_USERS_EDIT_APPROVALS_BULK_REQUEST
} from 'state/Users/Users/actions';
import { ApprovalsModal, ApprovalsModalProps } from '../../../../components';

type StateProps = Pick<
  ApprovalsModalProps,
  'isOpened' | 'isUpdating' | 'errors' | 'userIds'
>;

type DispatchProps = Pick<ApprovalsModalProps, 'onCloseModal'> & {
  onFormSubmit: (data: EditApproversPayload) => void;
};

export type BulkProps = StateProps & DispatchProps;

export const UsersBulkEditApproversComponent = (props: BulkProps) => (
  <ApprovalsModal {...props} />
);

export const mapStateToPropsBulk = (state: StoreState): StateProps => ({
  ...getBulkEditApproversModal(state),
  userIds: selectedIdsSelector(state)
});

const mapDispatchToProps: DispatchProps = {
  onCloseModal: BOX_USERS_EDIT_APPROVALS_BULK_MODAL_CLOSE,
  onFormSubmit: BOX_USERS_EDIT_APPROVALS_BULK_REQUEST
};

export const UsersBulkEditApprovers = connect(
  mapStateToPropsBulk,
  mapDispatchToProps
)(UsersBulkEditApproversComponent);
