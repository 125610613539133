import React, { Children, Component, ReactNode } from 'react';
import Row from '../Grid/Row/Row';
import Col from '../Grid/Col/Col';
import { DateRangeProps } from './type';
import { getClass, isElementOfType } from '../_lib/helper';
import './DateRange.scss';
import DateRangeInput from './component/DateRangeInput';
import DateRangeDatepicker from './component/DateRangeDatepicker';

type DateRangeState = {
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
};

class DateRange extends Component<DateRangeProps, DateRangeState> {
  static Start = DateRangeInput;
  static End = DateRangeInput;

  readonly state: DateRangeState = {
    startDate: null,
    endDate: null,
  };

  /**
   * Called when a date is selected in the datepicker
   * @param isStartDate
   * @param childProps
   */
  onChange = (isStartDate: boolean, childProps: any) => (d: Date | null) => {
    const { name, onChange } = childProps;

    const currentValue: DateRangeState = {
      startDate: isStartDate ? d : this.state.startDate,
      endDate: !isStartDate ? d : this.state.endDate,
    };

    this.setState({
      ...currentValue,
    });

    onChange(d, name);
  };

  /**
   * Returns true if the date passed in is disabled
   * @param current
   */
  disabledDateStart = (current?: Date): boolean => {
    const { endDate } = this.state;
    return current && endDate ? endDate < current : false;
  };

  /**
   * Returns true if the date passed in is disabled
   * @param current
   */
  disabledDateEnd = (current?: Date): boolean => {
    const { startDate } = this.state;
    return current && startDate ? startDate > current : false;
  };

  /**
   * Checks if the date is disabled
   * @param isStartDate
   * @param childProps
   */
  disabledDate = (isStartDate: boolean, childProps: any) => (value?: Date) => {
    const { disabledDate } = childProps;
    const isDisabledInvalidRange = isStartDate
      ? this.disabledDateStart(value)
      : this.disabledDateEnd(value);
    const isDisabled = disabledDate ? disabledDate(value) : false;

    return isDisabled || isDisabledInvalidRange;
  };

  /**
   * Called by child components (DateRangeDatepicker) to update the startDate and endDate in state,
   * this is required when the startDate and endDate are actually managed outside the component
   * @param isStartDate
   */
  updateDateInState = (isStartDate: boolean) => (value?: Date | null) => {
    const nextState = this.state;

    if (isStartDate) {
      nextState.startDate = value;
    } else {
      nextState.endDate = value;
    }

    this.setState(nextState);
  };

  /**
   * Returns the placeholder for the datepicker
   * @param isStartDate
   */
  getPlaceholder = (isStartDate: boolean) => {
    return isStartDate ? 'Start Date' : 'End Date';
  };

  /**
   * Render a datepicker
   * @param childProps
   * @param isStartDate
   */
  renderDatepicker(childProps: any, isStartDate: boolean) {
    const className = 'elmo-date-range__' + (isStartDate ? 'start' : 'end');
    const { format, isClearable, isDisabled, isInline } = this.props;
    const {
      placeholder: childPlaceholder,
      label,
      value,
      ariaLabel,
    } = childProps;
    const placeholder = childPlaceholder
      ? childPlaceholder
      : this.getPlaceholder(isStartDate);

    return (
      <Col md={12} role="presentation">
        <DateRangeDatepicker
          className={className}
          onChange={this.onChange(isStartDate, childProps)}
          disabledDate={this.disabledDate(isStartDate, childProps)}
          format={format}
          placeholder={placeholder}
          label={label}
          ariaLabel={ariaLabel}
          value={value}
          updateParentDate={this.updateDateInState(isStartDate)}
          isClearable={isClearable}
          isDisabled={isDisabled}
          isInline={isInline}
        />
      </Col>
    );
  }

  checkValidChildren = () => {
    const { children } = this.props;
    if (Children.count(children) !== 2) {
      return false;
    }

    return Children.toArray(children).every((child: ReactNode) => {
      return isElementOfType(child, DateRangeInput);
    });
  };

  render() {
    const { id, isVertical, children, className } = this.props;
    const validChildren = this.checkValidChildren();

    if (!validChildren) {
      return null;
    }

    const classNames = getClass('elmo-date-range', className, {
      vertical: isVertical,
    });

    return (
      <div
        id={id}
        data-testid={`elmo-date-range-${id || 'default'}`}
        className={classNames}
      >
        <Row role="presentation">
          {Children.map(children, (child: any, index: number) => {
            return this.renderDatepicker(child.props, index === 0);
          })}
        </Row>
      </div>
    );
  }
}

export default DateRange;
