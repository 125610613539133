import { createSelector } from 'reselect';
import {
  ShiftOfferProposal,
  ShiftOfferWithProposals,
  StringMap
} from 'type';
import { StoreState } from '../../../../types';
import { withoutCombiner } from '../../../../combiners';
import { declineProposalQueueByShiftOfferIdSelector } from '../../ShiftOffersUndo';
import { values } from 'lodash';

export const getErrors = (state: StoreState) =>
  state.managerDashboard.shiftOffers.shiftOffer.errors;
export const getShiftOfferWithProposals = (
  state: StoreState
): ShiftOfferWithProposals =>
  state.managerDashboard.shiftOffers.shiftOffer.shiftOfferWithProposals;
export const getShiftOfferWithProposalsId = (state: StoreState): string =>
  state.managerDashboard.shiftOffers.shiftOffer.shiftOfferWithProposals.id;
const getShiftOfferProposals = (
  state: StoreState
): StringMap<ShiftOfferProposal> =>
  state.managerDashboard.shiftOffers.shiftOffer.shiftOfferWithProposals
    .proposals;
export const getIsOutdated = (state: StoreState) =>
  state.managerDashboard.shiftOffers.shiftOffer.isOutdated;
export const getIsOutdatedValue = (state: StoreState) =>
  getIsOutdated(state).value;

export const declinedProposalIdsSelector = createSelector<
  StoreState,
  string,
  StringMap<string[]>,
  string[]
>(
  getShiftOfferWithProposalsId,
  declineProposalQueueByShiftOfferIdSelector,
  (shiftOfferWithProposalsId, declineProposalQueueByShiftOffer) =>
    declineProposalQueueByShiftOffer[shiftOfferWithProposalsId] || []
);

const shiftOfferProposalsWithoutDeclineQueueSelector = createSelector<
  StoreState,
  StringMap<ShiftOfferProposal>,
  string[],
  StringMap<ShiftOfferProposal>
>(getShiftOfferProposals, declinedProposalIdsSelector, withoutCombiner);

export const shiftOfferProposalsArraySelector = createSelector<
  StoreState,
  StringMap<ShiftOfferProposal>,
  ShiftOfferProposal[]
>(shiftOfferProposalsWithoutDeclineQueueSelector, values);
