import React from 'react';
import { Heading } from '../../Typography';

function ModalTitle({ icon, title, titleSize }: any) {
  if (title) {
    return (
      <div className="elmo-modal__header-title">
        <Heading size={titleSize} type="title">
          {icon} {title}
        </Heading>
      </div>
    );
  }

  return null;
}

export default ModalTitle;
