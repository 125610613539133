import React from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'extended-oxygen-elements';
import { IconButton } from 'extended-oxygen-elements';
import { BOX_TIMESHEETS_WEEKLY_DIALOG_OPENED } from 'state/TimesheetsWeeklyPage';
import { StopRoundedIcon } from 'element';
import { ActionButtonProps } from '../types';
import { StopDialog } from './components/StopDialog';

export const StopButton = ({
  closeActionsPopover,
  timesheetId,
}: ActionButtonProps) => {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleStopButtonClick = () => {
    dispatch(BOX_TIMESHEETS_WEEKLY_DIALOG_OPENED());
    setOpen(true);
  };

  const HandleStopDialogClose = () => {
    setOpen(false);
    closeActionsPopover();
  };

  return (
    <>
      <Tooltip title="Stop timesheet">
        <IconButton onClick={handleStopButtonClick} data-testid={'hover-action-stop'}>
          <StopRoundedIcon />
        </IconButton>
      </Tooltip>

      <StopDialog
        timesheetId={timesheetId}
        open={open}
        onClose={HandleStopDialogClose}
      />
    </>
  );
};
