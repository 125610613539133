import { createAction } from 'lib/store-utils';
import {
  FormattedErrors, TimeOff,
  UpdateFormPayload
} from 'type';
import { ManagerDashboardGetTimeOffsResponse } from 'lib/Api';
import {
  ApproveModalPayload,
  ChangeTimeOffPayload,
  CreateApproveModalPayload,
  CreateTimeOffPayload,
  EditTimeOffPayload,
  PredefinedFilters,
  ToggleFilterSuccessPayload,
  EditApproveModalPayload,
  GetApplicableUsersSuccessPayload,
  DeleteTimeOffPayload,
} from './types';

export const BOX_MANAGER_TIMEOFF_CHANGED = createAction<TimeOff>('TimeOffs / timeOff changed');
export const BOX_MANAGER_TIMEOFF_DELETED = createAction<DeleteTimeOffPayload>('TimeOffs / timeOff deleted');

export const BOX_UNAVAILABILITY_GET_PAGE_DATA_REQUEST = createAction('Unavailability / Get page data request');

export const BOX_UNAVAILABILITY_CLEAR_ERRORS = createAction('Unavailability / clear errors');

export const BOX_UNAVAILABILITY_GET_UNAVAILABILITY_LIST_SUCCESS = createAction<ManagerDashboardGetTimeOffsResponse>('Unavailability / Get unavailability list success');

export const BOX_UNAVAILABILITY_TOGGLE_FILTER_TYPE_REQUEST = createAction<PredefinedFilters>('Unavailability / toggle filter type');
export const BOX_UNAVAILABILITY_TOGGLE_FILTER_TYPE_SUCCESS = createAction<ToggleFilterSuccessPayload>('Unavailability / toggle filter type success');
export const BOX_UNAVAILABILITY_TOGGLE_FILTER_TYPE_FAILURE = createAction<FormattedErrors>('Unavailability / toggle filter type failure');

export const BOX_UNAVAILABILITY_UPDATE_CUSTOM_FILTERS_REQUEST = createAction('Unavailability / Update custom filters request');
export const BOX_UNAVAILABILITY_UPDATE_CUSTOM_FILTERS_SUCCESS = createAction<ToggleFilterSuccessPayload>('Unavailability / Update custom filters success');
export const BOX_UNAVAILABILITY_UPDATE_CUSTOM_FILTERS_FAILURE = createAction<FormattedErrors>('Unavailability / Update custom filters failure');

export const BOX_UNAVAILABILITY_UPDATE_FILTER_FORM = createAction<UpdateFormPayload>('Unavailability / update filter form');
export const BOX_UNAVAILABILITY_CLEAR_FILTER_FORM = createAction('Unavailability / clear filter form');

export const BOX_UNAVAILABILITY_CHANGE_PAGE_REQUEST = createAction<number>('Unavailability / Change page request');
export const BOX_UNAVAILABILITY_CHANGE_PAGE_SUCCESS = createAction<ManagerDashboardGetTimeOffsResponse>('Unavailability / Change page success');
export const BOX_UNAVAILABILITY_CHANGE_PAGE_FAILURE = createAction<FormattedErrors>('Unavailability / Change page failure');

export const BOX_UNAVAILABILITY_CHANGE_PAGE_SIZE_REQUEST = createAction<number>('Unavailability / Change page size request');
export const BOX_UNAVAILABILITY_CHANGE_PAGE_SIZE_SUCCESS = createAction<ManagerDashboardGetTimeOffsResponse>('Unavailability / Change page size success');
export const BOX_UNAVAILABILITY_CHANGE_PAGE_SIZE_FAILURE = createAction<FormattedErrors>('Unavailability / Change page size failure');

export const BOX_UNAVAILABILITY_APPROVE_REQUEST = createAction<ChangeTimeOffPayload>('Unavailability / approve request');
export const BOX_UNAVAILABILITY_APPROVE_SUCCESS = createAction('Unavailability / approve success');
export const BOX_UNAVAILABILITY_APPROVE_FAILURE = createAction<FormattedErrors>('Unavailability / approve failure');

export const BOX_UNAVAILABILITY_CONFIRM_APPROVAL_MODAL_OPEN = createAction<ApproveModalPayload>('Unavailability / confirm approval modal open');
export const BOX_UNAVAILABILITY_CONFIRM_APPROVAL_MODAL_CLOSE = createAction('Unavailability / confirm approval modal close');

export const BOX_UNAVAILABILITY_FORCE_APPROVE_REQUEST = createAction('Unavailability / force approve request');
export const BOX_UNAVAILABILITY_FORCE_APPROVE_SUCCESS = createAction('Unavailability / force approve success');
export const BOX_UNAVAILABILITY_FORCE_APPROVE_FAILURE = createAction<FormattedErrors>('Unavailability / force approve failure');

export const BOX_UNAVAILABILITY_DECLINE_REQUEST = createAction<ChangeTimeOffPayload>('Unavailability / decline request');
export const BOX_UNAVAILABILITY_DECLINE_SUCCESS = createAction('Unavailability / decline success');
export const BOX_UNAVAILABILITY_DECLINE_FAILURE = createAction<FormattedErrors>('Unavailability / decline failure');

export const BOX_UNAVAILABILITY_UNAPPROVE_REQUEST = createAction<ChangeTimeOffPayload>('Unavailability / unapprove request');
export const BOX_UNAVAILABILITY_UNAPPROVE_SUCCESS = createAction('Unavailability / unapprove success');
export const BOX_UNAVAILABILITY_UNAPPROVE_FAILURE = createAction<FormattedErrors>('Unavailability / unapprove failure');

export const BOX_UNAVAILABILITY_DELETE_MODAL_OPEN = createAction<ChangeTimeOffPayload>('Unavailability / delete modal open');
export const BOX_UNAVAILABILITY_DELETE_MODAL_CLOSE = createAction('Unavailability / delete modal close');

export const BOX_UNAVAILABILITY_DELETE_REQUEST = createAction<ChangeTimeOffPayload>('Unavailability / delete  request');
export const BOX_UNAVAILABILITY_DELETE_SUCCESS = createAction('Unavailability / delete  success');
export const BOX_UNAVAILABILITY_DELETE_FAILURE = createAction<FormattedErrors>('Unavailability / delete  failure');

export const BOX_UNAVAILABILITY_CREATE_MODAL_OPEN = createAction('Unavailability / create modal open');
export const BOX_UNAVAILABILITY_CREATE_MODAL_CLOSE = createAction('Unavailability / create modal close');

export const BOX_UNAVAILABILITY_CREATE_UNAVAILABILITY_REQUEST = createAction<CreateTimeOffPayload>('Unavailability / create unavailability request');
export const BOX_UNAVAILABILITY_CREATE_UNAVAILABILITY_SUCCESS = createAction('Unavailability / create unavailability success');
export const BOX_UNAVAILABILITY_CREATE_UNAVAILABILITY_FAILURE = createAction<FormattedErrors>('Unavailability / create unavailability failure');

export const BOX_UNAVAILABILITY_CONFIRM_CREATION_MODAL_OPEN = createAction<CreateApproveModalPayload>('Unavailability / confirm creation modal open');
export const BOX_UNAVAILABILITY_CONFIRM_CREATION_MODAL_CLOSE = createAction('Unavailability / confirm creation modal close');

export const BOX_UNAVAILABILITY_FORCE_CREATE_UNAVAILABILITY_REQUEST = createAction('Unavailability / force create unavailability request');
export const BOX_UNAVAILABILITY_FORCE_CREATE_UNAVAILABILITY_SUCCESS = createAction('Unavailability / force create unavailability success');
export const BOX_UNAVAILABILITY_FORCE_CREATE_UNAVAILABILITY_FAILURE = createAction<FormattedErrors>('Unavailability / force create unavailability failure');

export const BOX_UNAVAILABILITY_EDIT_MODAL_OPEN = createAction<ChangeTimeOffPayload>('Unavailability / edit modal open');
export const BOX_UNAVAILABILITY_EDIT_MODAL_CLOSE = createAction('Unavailability / edit modal close');

export const BOX_UNAVAILABILITY_EDIT_UNAVAILABILITY_REQUEST = createAction<EditTimeOffPayload>('Unavailability / edit unavailability request');
export const BOX_UNAVAILABILITY_EDIT_UNAVAILABILITY_SUCCESS = createAction('Unavailability / edit unavailability success');
export const BOX_UNAVAILABILITY_EDIT_UNAVAILABILITY_FAILURE = createAction<FormattedErrors>('Unavailability / edit unavailability failure');

export const BOX_UNAVAILABILITY_CONFIRM_EDIT_MODAL_OPEN = createAction<EditApproveModalPayload>('Unavailability / confirm edit modal open');
export const BOX_UNAVAILABILITY_CONFIRM_EDIT_MODAL_CLOSE = createAction('Unavailability / confirm edit modal close');

export const BOX_UNAVAILABILITY_FORCE_EDIT_UNAVAILABILITY_REQUEST = createAction('Unavailability / force edit unavailability request');
export const BOX_UNAVAILABILITY_FORCE_EDIT_UNAVAILABILITY_SUCCESS = createAction('Unavailability / force edit unavailability success');
export const BOX_UNAVAILABILITY_FORCE_EDIT_UNAVAILABILITY_FAILURE = createAction<FormattedErrors>('Unavailability / force edit unavailability failure');

export const BOX_UNAVAILABILITY_SET_IS_CURRENTLY_UPDATING = createAction<string>('Unavailability / set is updating');
export const BOX_UNAVAILABILITY_UNSET_IS_CURRENTLY_UPDATING = createAction('Unavailability / unset is updating');

export const BOX_UNAVAILABILITY_GET_APPLICABLE_USERS = createAction('Unavailability / Get Applicable Users');
export const BOX_UNAVAILABILITY_GET_APPLICABLE_USERS_REQUEST = createAction('Unavailability / Get Applicable Users Request');
export const BOX_UNAVAILABILITY_GET_APPLICABLE_USERS_SUCCESS = createAction<GetApplicableUsersSuccessPayload>('Unavailability / Get Applicable Users Success');
export const BOX_UNAVAILABILITY_GET_APPLICABLE_USERS_FAILURE = createAction<FormattedErrors>('Unavailability / Get Applicable Users Failure');

export const BOX_UNAVAILABILITY_MODAL_CLEAR_ERRORS = createAction('Unavailability / modal clear errors');
