import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import { StoreState } from '../../../../state/types';

import SiteView from './components/SiteView';
import UserView from './components/UserView';
import { BOX_ROSTERS_PRINT, getIsPrinting } from 'state/Roster/Print';
import { getSiteId } from 'state/RosteredShifts';
import WeekView from './WeekView';
import { useReactToPrint } from 'react-to-print';
import { isSiteView } from '../../../../helpers';

type StateProps = {
  isPrinting: boolean;
  siteId: string;
};

type DispatchProps = {
  startPrinting: (v: boolean) => void;
};

type OwnProps = {
  type?: 'weekView';
};

type Props = StateProps & OwnProps & DispatchProps;

export function PrintRosters(props: Props) {
  const [view, setView] = useState('user');
  const [isPrinting, setIsPrinting] = useState(true);
  const componentRef: any = useRef();

  const handlePrint = useReactToPrint({
    content: () => (componentRef as any).current,
    suppressErrors: true,
  });

  useEffect(() => {
    if (props.isPrinting) {
      setIsPrinting(true);
      setTimeout(() => {
        if (handlePrint) {
          handlePrint();
        }
      }, 100);
      setTimeout(() => {
        props.startPrinting(false);
      }, 500);
    } else {
      setIsPrinting(false);
    }
  }, [props.isPrinting]);

  useEffect(() => {
    setView(!isSiteView() ? 'user' : 'site');
  });

  useEffect(() => {
    window.addEventListener('beforeprint', beforeprint);
    window.addEventListener('afterprint', afterprint);
    window.addEventListener('keydown', onCtrlPHandler);

    window.onbeforeprint = beforeprint;
    window.onafterprint = afterprint;

    return () => {
      window.removeEventListener('keydown', onCtrlPHandler);
      window.removeEventListener('beforeprint', beforeprint);
      window.removeEventListener('afterprint', afterprint);
    };
  }, []);

  function beforeprint() {
    setIsPrinting(true);
  }
  function afterprint() {
    setIsPrinting(false);
  }

  function onCtrlPHandler(e: any) {
    if (e.ctrlKey && e.keyCode === 80) {
      e.preventDefault();
      e.stopPropagation();
      props.startPrinting(true);
    }
  }

  return (
    <div className="printing-content" ref={componentRef}>
      {isPrinting && (
        <WeekView>
          {({ groupedRosters, groupedRostersByUsers, ...rest }) => {
            return view === 'user' ? (
              <UserView {...rest} groupedRosters={groupedRostersByUsers} />
            ) : (
              <SiteView {...rest} groupedRosters={groupedRosters} />
            );
          }}
        </WeekView>
      )}
    </div>
  );
}

const mapStateToProps = (state: StoreState): StateProps => ({
  isPrinting: getIsPrinting(state),
  siteId: getSiteId(state),
});

const mapDispatchProps: DispatchProps = {
  startPrinting: BOX_ROSTERS_PRINT,
};

export default connect(mapStateToProps, mapDispatchProps)(PrintRosters);
