import {
  FetchWrapper,
  Layout,
  NestedRoutes,
  SocketGlobalConnection,
} from 'element';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BOX_AUTH_GET_GLOBAL_DATA_REQUEST } from 'state/Auth';
import { PRIVATE_LAYOUT } from 'state/FetchPageData';
import { Route, StringMap } from 'type';
import HelpMenuItemConfirmationDialog from './HelpMenuItemConfirmationDialog';
import RosterHeader from './RosterHeader';
import RosterMenu from './RosterMenu';
import ActivateTimeClockMenuItemConfirmationDialog from './ActivateTimeClockMenuItemConfirmationDialog';
import { WithFeatureFlagServiceProvider } from 'flags/WithFeatureFlagServiceProvider';
import { FeatureFlag } from 'element/feature-flags-components';
import NewNavigationLayout from './NewNavigation/NewNavigationLayout';

type Props = {
  routes: StringMap<Route>;
  location: String;
};

export default function PrivateLayout({ routes: nestedRoutes }: Props) {
  const dispatch = useDispatch();
  const fetchData = useCallback(() => {
    dispatch(BOX_AUTH_GET_GLOBAL_DATA_REQUEST());
  }, [dispatch]);

  const showNewNavigation: boolean = true;

  const pageContent = (
    <>
      <NestedRoutes routes={nestedRoutes} />
      <HelpMenuItemConfirmationDialog />
      <ActivateTimeClockMenuItemConfirmationDialog />
    </>
  );

  return (
    <FetchWrapper fetchData={fetchData} pageId={PRIVATE_LAYOUT}>
      <WithFeatureFlagServiceProvider>
        <SocketGlobalConnection />

        {showNewNavigation ? (
          /* New navigation layout */
          <NewNavigationLayout>{pageContent}</NewNavigationLayout>
        ) : (
          /* Old navigation layout */
          <Layout header={<RosterHeader />} menu={<RosterMenu />}>
            {pageContent}
          </Layout>
        )}
      </WithFeatureFlagServiceProvider>
    </FetchWrapper>
  );
}
