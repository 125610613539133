import {
  AccountTreeArea,
  AccountTreeRole,
  AccountTreeSite,
  RoleTriple,
  StringMap
} from 'type';
import {
  compact,
  differenceWith,
  filter,
  isEqual,
  map,
  unionWith
} from 'lodash';
import { Option, OptionWithoutCheck } from './types';
import { archivedOrWithDataFilter } from '../helpers';

export const createRoleTriples = (
  siteId: string,
  areaId: string,
  roleIds: string[]
): RoleTriple[] =>
  map(
    roleIds,
    (roleId: string): RoleTriple => ({
      siteId,
      areaId,
      roleId
    })
  );
export const mergeRoles = (
  roles: RoleTriple[] | null,
  additionalRoles: RoleTriple[],
  isChecked: boolean
): RoleTriple[] =>
  isChecked
    ? unionWith(roles, additionalRoles, isEqual)
    : differenceWith(roles, additionalRoles, isEqual);
export const hasRoleCreator = (roles: RoleTriple[] | null) => (
  roleToBeFound: RoleTriple
): boolean =>
  !!filter(roles, selectedRole => isEqual(selectedRole, roleToBeFound)).length;
export const getCheckedState = ({
  options,
  allItemsQuantity
}: {
  options?: Option[];
  allItemsQuantity: number;
}): { isChecked: boolean; isIndeterminate: boolean } => {
  const checkedItems = filter(options, ({ isChecked }: Option) => isChecked);
  const indeterminateItems = filter(
    options,
    ({ isIndeterminate }: Option) => isIndeterminate
  );

  const isAllItemsChecked: boolean = checkedItems.length === allItemsQuantity;

  return {
    isChecked: isAllItemsChecked,
    isIndeterminate:
      !!indeterminateItems.length ||
      (!isAllItemsChecked && !!checkedItems.length)
  };
};

const createRoleOptionsCreator = (
  hasRole: (roleTriple: RoleTriple) => boolean
) => (
  roles: AccountTreeRole[],
  site: AccountTreeSite,
  area: AccountTreeArea
): Option[] =>
  roles.map(
    (role): Option => {
      return {
        id: role.id,
        label: role.name,
        isChecked: hasRole({
          siteId: site.id,
          areaId: area.id,
          roleId: role.id
        }),
        isIndeterminate: false
      };
    }
  );

export const createAreaOptionsCreator = (
  roles: StringMap<AccountTreeRole>,
  hasRole: (roleTriple: RoleTriple) => boolean,
  archivedRoleIdsToBeShown: string[],
  selectedRoleIds: string[]
) => (areas: AccountTreeArea[], site: AccountTreeSite): Option[] =>
  areas.map(
    (area: AccountTreeArea): Option => {
      const areaRoles = compact(area.role_ids.map(roleId => roles[roleId]));
      const filteredAreaRoles = archivedOrWithDataFilter(
        areaRoles,
        archivedRoleIdsToBeShown,
        selectedRoleIds
      );

      const createRoleOptions = createRoleOptionsCreator(hasRole);

      const areaOption: OptionWithoutCheck = {
        id: area.id,
        label: area.name,
        options: createRoleOptions(filteredAreaRoles, site, area)
      };

      return {
        ...areaOption,
        ...getCheckedState({
          options: areaOption.options,
          allItemsQuantity: areaRoles.length
        })
      };
    }
  );
