import { ReactNode } from 'react';

export type TypeOfColumns = 1 | 2 | 3;

export type FormContainerProps = {
  /** FormContainer id attribute, optional id */
  id?: string;
  /** Content */
  children?: ReactNode;
  /** custom classname */
  className?: string;
  /** Max Columns */
  maxColumns: TypeOfColumns;
};

export type FormContainerState = {
  maxColumns: TypeOfColumns;
};

export const FormContainerBreakpoints = {
  '2': 760,
  '3': 1152,
};
