export const CONFIRMATION_MODAL = 'CONFIRMATION_MODAL';
export const MANAGER_DASHBOARD_SHIFT_OFFER = 'MANAGER_DASHBOARD_SHIFT_OFFER';
export const MANAGER_DASHBOARD_TIMESHEETS = 'MANAGER_DASHBOARD_TIMESHEETS';
export const MANAGER_DASHBOARD_TIMESHEETS_AWARDS_MODAL_HIGHER_DUTIES = 'MANAGER_DASHBOARD_TIMESHEETS_AWARDS_MODAL_HIGHER_DUTIES';
export const MANAGER_DASHBOARD_TIMESHEETS_AWARDS_MODAL = 'MANAGER_DASHBOARD_TIMESHEETS_AWARDS_MODAL';
export const MANAGER_DASHBOARD_TIMESHEETS_ALLOWANCES_MODAL = 'MANAGER_DASHBOARD_TIMESHEETS_ALLOWANCES_MODAL';
export const EMPLOYEE_DASHBOARD_MY_TIMESHEET = 'EMPLOYEE_DASHBOARD_MY_TIMESHEET';
export const EMPLOYEE_DASHBOARD_SHIFT_TRADES = 'EMPLOYEE_DASHBOARD_SHIFT_TRADES';
export const ROSTER_CONFIRM_OVERLAP_MODAL = 'ROSTER_CONFIRM_OVERLAP_MODAL';
export const ROSTER_SHIFT_INPUT = 'ROSTER_SHIFT_INPUT';
export const ROSTER_COPY_PASTE_CONFIRMATION_MODAL = 'ROSTER_COPY_PASTE_CONFIRMATION_MODAL';
export const ROSTER_CREATE_UPDATE_EVENT_MODAL = 'ROSTER_CREATE_UPDATE_EVENT_MODAL';
export const ROSTER_SHIFT_TEMPLATE = 'ROSTER_SHIFT_TEMPLATE';
export const ROSTER_EDIT_TEMPLATE_SHIFT_CONFIRMATION = 'ROSTER_EDIT_TEMPLATE_SHIFT_CONFIRMATION';
export const PAY = 'PAY';
export const PAY_CONFIRM_MODAL = 'PAY_CONFIRM_MODAL';
export const USERS = 'USERS';
export const SETTINGS_RESET_BREAKS_TO_DEFAULTS = 'SETTINGS_RESET_BREAKS_TO_DEFAULTS';
export const ROSTERED_SHIFTS_BULK_CREATE = 'ROSTERED_SHIFTS_BULK_CREATE';
