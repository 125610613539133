import Row from '../../Grid/Row';
import React, { ReactElement, ReactNode } from 'react';
import { getClass, isElementOfType } from '../../_lib/helper';
import ListTable from '../ListTable';
import { ListTableColumnProps } from './ListTableColumn';
import ListTableTh from './ListTableTh';
import { withListTableContext } from '../ListTableContextConsumer';

export type ListTableHeaderProps = {
  /** Contains an array of ListTableColumn elements */
  children?: ReactNode;
  /** @Ignore From ListTableContext */
  hasActions?: boolean;
  /** @Ignore From ListTableContext */
  isBulkActionsEnabled?: boolean;
  /** @Ignore From ListTableContext */
  columns?: ReactNode[];
};

function ListTableHeader({
  isBulkActionsEnabled,
  columns,
  hasActions,
}: ListTableHeaderProps) {
  const colsClassNames = getClass('elmo-listtable__cols', '', {
    actions: hasActions && !isBulkActionsEnabled,
  });

  if (!columns) {
    return null;
  }

  return (
    <div className={colsClassNames} role="row">
      <Row className="elmo-listtable__header">
        {columns.map((child: any, index: number) => {
          if (!isElementOfType(child, ListTable.Column)) {
            return null;
          }

          let column = child as ReactElement<ListTableColumnProps>;
          return (
            <ListTableTh
              index={index}
              label={column.props.label}
              sortable={!!column.props.sortable}
              key={index}
              isPlaceholder={column.props.isPlaceholder}
            />
          );
        })}
      </Row>
    </div>
  );
}

ListTableHeader.displayName = 'ListTableHeader';

export default withListTableContext(ListTableHeader);
