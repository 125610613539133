import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BOX_EDIT_TEMPLATE_DELETE_CONFIRM_SHIFT_ITEM } from 'state/Roster/EditTemplate/actions';
import { getIsBulkDeleteOpened } from 'state/Roster/BulkActions';

type Props = {
  id: string;
};
export const useHandleClickDeleteIcon = ({
  id,
}: Props): React.MouseEventHandler<HTMLElement> | undefined => {
  const isBulkDelete = useSelector(getIsBulkDeleteOpened);
  const dispatch = useDispatch();
  const canDeleteShift = !isBulkDelete;

  const handleClickDeleteIcon = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();

      dispatch(BOX_EDIT_TEMPLATE_DELETE_CONFIRM_SHIFT_ITEM(id));
    },
    [dispatch, id]
  );

  if (canDeleteShift) {
    return handleClickDeleteIcon;
  }
};
