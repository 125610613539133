import React from 'react';
import { Radio, RadioGroup } from 'elmo-elements';

type Props<T> = {
  selected: T;
  sortFields: {
    label: string;
    value: T;
  }[];
  onChange: (value: string | number) => void;
  id?: string;
};

export const SortFieldRadioButtons = <T extends string>({
  selected,
  onChange,
  sortFields,
  id
}: Props<T>) => (
  <RadioGroup id={id} selected={selected} onChange={onChange} direction="vertical">
    {sortFields.map(sortField => (
      <Radio value={sortField.value} key={sortField.value}>
        {sortField.label}
      </Radio>
    ))}
  </RadioGroup>
);
