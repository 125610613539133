import React, { ReactChild } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dictionary } from 'ts-essentials';
import moment from 'moment';
import { Box, DialogContent, Tooltip } from 'extended-oxygen-elements';
import { createStyles, makeStyles } from '@mui/styles';
import {
  CardHeaderOptions,
  Col,
  FormItem,
  ListTable,
  LoadingOverlay,
  Paragraph,
  Row,
  Text,
  TextArea,
} from 'elmo-elements';
import { Alert } from 'extended-oxygen-elements';
import { EmployeeRosteredShift } from 'type';
import { privateRoutes } from 'routes';
import {
  capitalize,
  getDatesDiff,
  getDateTimeFormatted,
  getPreferenceLabel,
  getTimeFormatted,
  secondsToHours,
} from 'lib/helpers';
import { getCurrentUserPermissions } from 'state/Auth';
import { getAreas, getRoles, getSites } from 'state/AccountTree';
import {
  canClockOn,
  canViewBreaks,
  getAcceptShiftTimeLimit,
  getCanViewShiftOffers,
  getCanViewShiftSwaps,
  getDateFormat,
  getLangPreferences,
  getRequestGPSLocation,
  getRosteredShiftsAcceptShifts,
  getTimeFormat,
  getTimeToStartShift,
  getUserSwapHours,
} from 'state/Account';
import {
  BOX_MY_ROSTERS_ACCEPT_SHIFT_REQUEST,
  BOX_MY_ROSTERS_BREAKS_LIMIT_CONFIRMATION_MODAL_CLOSED,
  BOX_MY_ROSTERS_CHANGE_PAGE_REQUEST,
  BOX_MY_ROSTERS_CHANGE_PAGE_SIZE_REQUEST,
  BOX_MY_ROSTERS_CLOCK_OFF_REQUEST,
  BOX_MY_ROSTERS_CLOCK_ON_REQUEST,
  BOX_MY_ROSTERS_DECLINE_SHIFT_REQUEST,
  BOX_MY_ROSTERS_DECLINE_SHIFT_SUCCESS,
  BOX_MY_ROSTERS_ERRORS_CLEARED,
  BOX_MY_ROSTERS_START_BREAK_REQUEST,
  BOX_MY_ROSTERS_STOP_BREAK_REQUEST,
  BOX_MY_ROSTERS_TRADE_MODAL_CLOSED,
  BOX_MY_ROSTERS_TRADE_MODAL_ERRORS_CLEARED,
  BOX_MY_ROSTERS_TRADE_MODAL_OPENED,
  BOX_MY_ROSTERS_TRADE_NOTE_SET,
  BOX_MY_ROSTERS_TRADE_REQUEST,
  getHasTimesheetInProgress,
  getModals,
  getMyRostersSelector,
  myRostersWithPagerSelector,
  getLoading,
} from 'state/EmployeeDashboard/MyRosters';
import { BOX_SHIFT_TRADES_SET_CURRENT_SHIFT_ID } from 'state/EmployeeDashboard/ShiftTrades';
import {
  CallMadeIcon,
  CallMadeOutlinedIcon,
  CheckOutlinedIcon,
  CloseOutlinedIcon,
  DSTIcon,
  ErrorBox,
  LocalCafeOutlinedIcon,
  NotesIcon,
  PageDialog,
  PageDialogCancel,
  PageDialogSubmit,
  RestaurantOutlinedIcon,
  SimpleActionCardFooterLabel,
  SimpleBadge,
  SwapHorizOutlinedIcon,
  TimerOffOutlinedIcon,
  TimerOutlinedIcon,
  TodayOutlinedIcon,
} from 'element';
import { Timer } from '../Timer';
import { ConfirmationModal, EmptyMessage, ErrorsModal } from './components';
import { marketLabels } from 'marketLabels';
import { getErrors } from 'state/EmployeeDashboard/MyRosters';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Button,
} from 'oxygen-elements';
import './style.scss';
import { BadgeWithDropDown } from 'element';
import { DialogActions, DialogTitle } from 'extended-oxygen-elements';
import { WhoElseWorking } from './components/WhoElseWorking';

type Coords = {
  lat: number;
  lng: number;
};

const geoErrorMessageCommon = 'We could not get your position';
type GeolocationErrorKey = keyof Omit<
  GeolocationPositionError,
  'message' | 'code'
>;
const geoTrackingMessages: Dictionary<
  string,
  GeolocationErrorKey | 'IN_PROGREESS'
> = {
  PERMISSION_DENIED: 'User denied geolocation prompt',
  POSITION_UNAVAILABLE: geoErrorMessageCommon,
  TIMEOUT: geoErrorMessageCommon,
  IN_PROGREESS: 'Please wait while we are tracking your location...',
};
const errorTitles = {
  clockOn: 'To clock on please allow this page to track your location',
  clockOff: 'To clock off please allow this page to track your location',
};

const getColWidth = (allColsQuantity: number, thisColWidth = 1) =>
  (24 / allColsQuantity) * thisColWidth;

const useIsGeolocationEnabled = () => {
  const settingsRequestGPSLocation = useSelector(getRequestGPSLocation);
  const settingsCanClockOn = useSelector(canClockOn);

  return settingsRequestGPSLocation && settingsCanClockOn;
};

const useGeolocation = () => {
  const [coords, setCoords] = React.useState<Coords | null>(null);
  const [geoTrackingMessage, setGeoTrackingMessage] = React.useState<
    string | null
  >(null);
  const isGeolocationEnabled = useIsGeolocationEnabled();

  React.useEffect(() => {
    let watchId: number | null = null;

    if (isGeolocationEnabled) {
      setGeoTrackingMessage(geoTrackingMessages.IN_PROGREESS);

      watchId = navigator.geolocation.watchPosition(
        ({ coords: { latitude: lat, longitude: lng } }) => {
          setCoords({ lat, lng });
          setGeoTrackingMessage(null);
        },
        (error) => {
          // TODO refactor
          if (error.code === error.PERMISSION_DENIED) {
            setGeoTrackingMessage(geoTrackingMessages.PERMISSION_DENIED);
          }
          if (error.code === error.POSITION_UNAVAILABLE) {
            setGeoTrackingMessage(geoTrackingMessages.POSITION_UNAVAILABLE);
          }
          if (error.code === error.TIMEOUT) {
            setGeoTrackingMessage(geoTrackingMessages.TIMEOUT);
          }
        }
      );
    }

    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
      if (geoTrackingMessage) {
        setGeoTrackingMessage(null);
      }
    };
  }, [isGeolocationEnabled]);

  return { coords, geoTrackingMessage };
};

const useIsClockOnEnabled = (
  coords: Coords | null
): { isEnabled: boolean; message?: string } => {
  const hasTimesheetInProgress = useSelector(getHasTimesheetInProgress);
  const isGeolocationEnabled = useIsGeolocationEnabled();

  if (isGeolocationEnabled) {
    let isEnabled = true;
    let message: string | undefined;

    if (!coords) {
      isEnabled = false;
      message = errorTitles.clockOn;
    }
    if (hasTimesheetInProgress) {
      isEnabled = false;
    }

    return { isEnabled, message };
  }

  let message: string | undefined;

  if (hasTimesheetInProgress) {
    message = errorTitles.clockOn;
  }

  return { isEnabled: !message, message };
};

const useIsClockOffEnabled = (
  coords: Coords | null
): { isEnabled: boolean; message?: string } => {
  const isGeolocationEnabled = useIsGeolocationEnabled();

  if (isGeolocationEnabled) {
    let message;
    if (!coords) {
      message = errorTitles.clockOff;
    }

    return { isEnabled: !message, message };
  }

  return { isEnabled: true };
};

const useStyles = makeStyles((theme) =>
  createStyles({
    contentWrapper: {
      display: 'flex',
    },
    content: {
      width: '100%',
    },
    iconWrapper: {
      minWidth: theme.componentsConfig.svgIcon.size.medium + theme.gap(2),
    },
    badgeWrapper: {
      lineHeight: '24px',
      paddingRight: '7px',
    },
    footerContentWrapper: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    message: {
      justifyContent: 'center',
      textAlign: 'center',
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        textAlign: 'start',
        justifyContent: 'flex-start',
      },
      [theme.breakpoints.up('md')]: {
        marginBottom: 0,
      },
    },
    actionsWrapper: {
      flexShrink: 0,
      marginLeft: 'auto',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
    action: {
      width: '100%',
      marginBottom: theme.spacing(0.5),
      [theme.breakpoints.up('sm')]: {
        width: 'auto',
        marginRight: theme.spacing(1),
        marginBottom: 0,
      },
    },
    swapResponses: {
      marginTop: 10,
    },
    swapResponsesBadge: {
      marginRight: 10,
    },
  })
);

const TooltipWrapper = ({
  title,
  children,
}: {
  title: string | undefined;
  children: React.ReactElement<any, any>;
}) => {
  if (!title) {
    return children;
  }

  return (
    <Tooltip title={title}>
      <span>{children}</span>
    </Tooltip>
  );
};

// TODO split to multiple simple components
export const MyRostersList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isUpdatingProp = useSelector(getLoading);
  const rosters = useSelector(getMyRostersSelector);
  const modals = useSelector(getModals);
  const errors = useSelector(getErrors);
  const dateFormat = useSelector(getDateFormat);
  const timeFormat = useSelector(getTimeFormat);
  const langPreferences = useSelector(getLangPreferences);
  const roles = useSelector(getRoles);
  const areas = useSelector(getAreas);
  const sites = useSelector(getSites);
  const settingsCanViewBreaks = useSelector(canViewBreaks);
  const pager = useSelector(myRostersWithPagerSelector);
  const isApproveSwap = useSelector(getCanViewShiftSwaps);
  const userSwapHours = useSelector(getUserSwapHours);
  const permissions = useSelector(getCurrentUserPermissions);
  const isApproveOffer = useSelector(getCanViewShiftOffers);
  const needAcceptShift = useSelector(getRosteredShiftsAcceptShifts);
  const acceptShiftTimeLimit = useSelector(getAcceptShiftTimeLimit);
  const canClockOnProp = useSelector(canClockOn);
  const timeToStartShift = useSelector(getTimeToStartShift);

  const { coords, geoTrackingMessage } = useGeolocation();
  const clockOnState = useIsClockOnEnabled(coords);
  const clockOffState = useIsClockOffEnabled(coords);

  const getShiftStatus = (roster: EmployeeRosteredShift) => {
    let status = 'unaccepted';

    if (!roster.is_accepted && needAcceptShift) {
      status = 'unaccepted';
    } else {
      if (!roster.timesheet) {
        status = 'not-started';
      }
    }

    if (roster.timesheet) {
      status = 'started';
    }

    if (roster.is_on_break) {
      status = 'on-break';
    }

    if (roster.is_finished) {
      status = 'finished';
    }

    return status;
  };

  const calculateCanClockOn = (roster: EmployeeRosteredShift) => {
    const startMoment = moment.parseZone(roster.start);
    const startOffset = startMoment.utcOffset();
    const diffs = Math.abs(
      getDatesDiff(
        startMoment,
        moment().utcOffset(startOffset),
        false
      ) as number
    );
    return Number(timeToStartShift) > diffs / 60;
  };

  const getActions = (roster: EmployeeRosteredShift) => {
    const { id: timesheet_id = '' } = roster.timesheet || {};

    let actions: ReactChild[] = [];

    const clockOffBtn = (
      <TooltipWrapper title={clockOffState.message}>
        <Button
          className={classes.action}
          id="clock-off-btn"
          disabled={!clockOffState.isEnabled}
          onClick={() => {
            dispatch(
              BOX_MY_ROSTERS_CLOCK_OFF_REQUEST({ timesheet_id, ...coords })
            );
          }}
        >
          <TimerOffOutlinedIcon /> Clock off
        </Button>
      </TooltipWrapper>
    );

    switch (getShiftStatus(roster)) {
      case 'unaccepted':
        const publishAt = moment(roster.published_at);
        const time = publishAt.clone();
        time.add(acceptShiftTimeLimit, 'hours');

        const diffs = getDatesDiff(time, moment(), false) as number;

        if (diffs > 0) {
          actions.push(
            <Button className={classes.action}>
              <Timer
                time={diffs}
                onTimeEnd={() => {
                  dispatch(BOX_MY_ROSTERS_DECLINE_SHIFT_SUCCESS(roster.id));
                }}
              />
            </Button>
          );
        }

        actions.push(
          <Button
            className={classes.action}
            id="accept-btn"
            onClick={() => {
              dispatch(BOX_MY_ROSTERS_ACCEPT_SHIFT_REQUEST(roster.id));
            }}
          >
            <CheckOutlinedIcon /> Accept
          </Button>
        );

        actions.push(
          <Button
            className={classes.action}
            id="decline-btn"
            onClick={() => {
              dispatch(BOX_MY_ROSTERS_DECLINE_SHIFT_REQUEST(roster.id));
            }}
          >
            <CloseOutlinedIcon /> Decline
          </Button>
        );
        break;
      case 'not-started':
        if (canClockOnProp && calculateCanClockOn(roster)) {
          actions.push(
            <TooltipWrapper title={clockOnState.message}>
              <Button
                className={classes.action}
                id="clock-on-btn"
                disabled={!clockOnState.isEnabled}
                onClick={() => {
                  dispatch(
                    BOX_MY_ROSTERS_CLOCK_ON_REQUEST({
                      rostered_shift_id: roster.id,
                      ...(coords as Coords),
                    })
                  );
                }}
              >
                <TimerOutlinedIcon /> Clock on
              </Button>
            </TooltipWrapper>
          );
        } else {
          actions = [];
        }
        break;

      case 'started':
        actions.push(clockOffBtn);
        actions.push(
          <Button
            className={classes.action}
            id="rest-break-btn"
            onClick={() => {
              dispatch(
                BOX_MY_ROSTERS_START_BREAK_REQUEST({
                  timesheet_id,
                  paid: true,
                })
              );
            }}
          >
            <LocalCafeOutlinedIcon /> {capitalize(marketLabels.restBreak)}
          </Button>
        );

        actions.push(
          <Button
            className={classes.action}
            id="meal-break-btn"
            onClick={() => {
              dispatch(
                BOX_MY_ROSTERS_START_BREAK_REQUEST({
                  timesheet_id,
                  paid: false,
                })
              );
            }}
          >
            <RestaurantOutlinedIcon /> {capitalize(marketLabels.mealBreak)}
          </Button>
        );
        break;

      case 'on-break':
        actions.push(clockOffBtn);

        actions.push(
          <Button
            className={classes.action}
            id="finish-break-btn"
            onClick={() => {
              dispatch(BOX_MY_ROSTERS_STOP_BREAK_REQUEST(timesheet_id));
            }}
          >
            <LocalCafeOutlinedIcon /> Finish break
          </Button>
        );
        break;
      default:
        actions = [];
    }

    return actions;
  };

  const getSwapPopover = (shift: EmployeeRosteredShift) => {
    if (shift.proposals_count === 0) {
      return (
        <div id="shift-swap-title-no-responses">
          <Paragraph size="sm">Shift Swap</Paragraph>
          <Paragraph>
            You want to swap this shift, no one wants it yet
          </Paragraph>
        </div>
      );
    } else {
      return (
        <div id="shift-swap-title">
          <Paragraph size="sm">Shift Swap</Paragraph>
          <Paragraph>You want to swap this shift. You have</Paragraph>
          <Link
            to={privateRoutes.employeeDashboard.routes.trades.routes.shiftSwap.path(
              shift.trade_id || ''
            )}
            onClick={() => {
              dispatch(
                BOX_SHIFT_TRADES_SET_CURRENT_SHIFT_ID(shift.trade_id || '')
              );
            }}
          >
            <Paragraph className={classes.swapResponses} color="information">
              <SimpleBadge
                className={classes.swapResponsesBadge}
                isCircular={true}
                type="info"
                label={shift.proposals_count.toString(10)}
              />
              Responses
            </Paragraph>
          </Link>
        </div>
      );
    }
  };

  const getOfferPopover = (shift: EmployeeRosteredShift) => {
    if (shift.proposals_count === 0) {
      return (
        <div id="shift-offer-title-no-response">
          <Paragraph size="sm">Shift Offer</Paragraph>
          <Paragraph>
            You have offered this shift, no one wants it yet
          </Paragraph>
        </div>
      );
    } else {
      return (
        <div id="shift-offer-title">
          <Paragraph size="sm">Shift Offer</Paragraph>
          <Paragraph>You have offered this shift. You have</Paragraph>
          <Paragraph className={classes.swapResponses} color="information">
            <SimpleBadge
              className={classes.swapResponsesBadge}
              isCircular={true}
              type="info"
              label={shift.proposals_count.toString(10)}
            />
            Responses
          </Paragraph>
        </div>
      );
    }
  };

  const getBadge = (shift: EmployeeRosteredShift): any => {
    switch (shift.status) {
      case 'swap':
        return {
          label: 'Swapped',
          type: 'success',
          icon: <SwapHorizOutlinedIcon />,
          content: getSwapPopover(shift),
        };
      case 'offer':
        return {
          label: 'Offered',
          type: 'info',
          icon: <CallMadeIcon />,
          content: getOfferPopover(shift),
        };
      case 'none':
        return undefined;
      default:
        return undefined;
    }
  };

  const canTradeShift = (roster: EmployeeRosteredShift) => {
    const startMoment = moment.parseZone(roster.start);
    const startOffset = startMoment.utcOffset();

    const diffStart = getDatesDiff(
      startMoment,
      moment().utcOffset(startOffset),
      false
    ) as number;
    return diffStart > 0;
  };

  const getTradeOptions = (roster: EmployeeRosteredShift) => {
    const { id, status } = roster;
    const startMoment = moment.parseZone(roster.start);
    const startOffset = startMoment.utcOffset();

    const diffSwapHours = getDatesDiff(
      startMoment,
      moment().utcOffset(startOffset),
      false
    ) as number;

    let options = [];

    if (
      status !== 'none' ||
      !canTradeShift(roster) ||
      getShiftStatus(roster) !== 'not-started'
    ) {
      return [];
    }

    if (
      isApproveSwap &&
      diffSwapHours / 3600 > +userSwapHours &&
      permissions.indexOf('employeedashboard.shiftswaps') !== -1
    ) {
      options.push({
        id: 'swap-shift-btn',
        icon: <SwapHorizOutlinedIcon />,
        label: 'Swap shift',
        onClick: () => {
          dispatch(
            BOX_MY_ROSTERS_TRADE_MODAL_OPENED({ shiftId: id, type: 'swap' })
          );
        },
      });
    }
    if (
      isApproveOffer &&
      permissions.indexOf('employeedashboard.shiftoffers') !== -1
    ) {
      options.push({
        id: 'offer-shift-btn',
        icon: <CallMadeOutlinedIcon />,
        label: 'Drop shift',
        onClick: () => {
          dispatch(
            BOX_MY_ROSTERS_TRADE_MODAL_OPENED({ shiftId: id, type: 'offer' })
          );
        },
      });
    }

    return options;
  };

  const positionAreLocation = React.useMemo(() => {
    const position = getPreferenceLabel(
      langPreferences,
      'role',
      'singular',
      '',
      true
    );
    const area = getPreferenceLabel(
      langPreferences,
      'area',
      'singular',
      '',
      false
    );
    const location = getPreferenceLabel(
      langPreferences,
      'site',
      'singular',
      '',
      false
    );
    return `${position} - ${area}, ${location}`;
  }, [langPreferences]);

  const getLocation = (roster: EmployeeRosteredShift) => {
    let response = '';

    if (roster.role_id && roles[roster.role_id]) {
      response = roles[roster.role_id].name;
    }

    if (roster.area_id && areas[roster.area_id]) {
      response += ` - ${areas[roster.area_id].name}`;
    }

    if (roster.site_id && sites[roster.site_id]) {
      response += `, ${sites[roster.site_id].name}`;
    }
    return response;
  };

  return (
    <div>
      <LoadingOverlay isLoading={isUpdatingProp} showSpinner={true}>
        <>
          <ErrorBox
            errors={errors}
            clearErrors={() => {
              dispatch(BOX_MY_ROSTERS_ERRORS_CLEARED());
            }}
          />

          {rosters.length ? (
            <>
              {rosters.map((roster, key) => {
                const actions = getActions(roster);
                const badge = getBadge(roster);
                const tradeOptions = getTradeOptions(roster);

                return (
                  <Card key={key} className="my-rosters-list-item">
                    <CardHeader
                      className="card-header"
                      avatar={<TodayOutlinedIcon />}
                      title={getDateTimeFormatted(
                        dateFormat,
                        timeFormat,
                        roster.start
                      )}
                      titleTypographyProps={{
                        fontWeight: 500,
                        fontSize: '16px',
                      }}
                      action={
                        <>
                          {!!badge && (
                            <div className={classes.badgeWrapper}>
                              <BadgeWithDropDown {...badge} />
                            </div>
                          )}
                          {!!tradeOptions && (
                            <CardHeaderOptions
                              options={{ dropdownOptions: tradeOptions }}
                            />
                          )}
                        </>
                      }
                    />
                    <CardContent className="card-content">
                      <div className={classes.contentWrapper}>
                        <div className={classes.iconWrapper} />

                        <div className={classes.content}>
                          <Row>
                            <Col
                              span={getColWidth(1)}
                              sm={getColWidth(2)}
                              md={getColWidth(4)}
                            >
                              <Box mb={2}>
                                <Text size={'xs'} color={'gray'}>
                                  {positionAreLocation}
                                </Text>

                                <div>{getLocation(roster)}</div>
                              </Box>
                            </Col>

                            <Col
                              span={getColWidth(1)}
                              sm={getColWidth(2)}
                              md={getColWidth(4)}
                            >
                              <Box mb={2}>
                                <Text size={'xs'} color={'gray'}>
                                  Start - finish{' '}
                                  <DSTIcon isDST={roster.is_dst_intersect} />
                                </Text>

                                <div>
                                  {getTimeFormatted(timeFormat, roster.start)} -{' '}
                                  {getTimeFormatted(timeFormat, roster.end)}
                                  {settingsCanViewBreaks ? (
                                    <>
                                      {roster.breaks.map((shiftBreak, key) => {
                                        return (
                                          <div id={`brake-${key}`} key={key}>
                                            <Text size={'xs'} color={'gray'}>
                                              {getTimeFormatted(
                                                timeFormat,
                                                shiftBreak.start
                                              )}{' '}
                                              -&nbsp;
                                              {getTimeFormatted(
                                                timeFormat,
                                                moment(shiftBreak.start).add(
                                                  shiftBreak.duration,
                                                  'minutes'
                                                )
                                              )}
                                              {shiftBreak.paid ? '($)' : <></>}
                                            </Text>
                                          </div>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </Box>
                            </Col>

                            <Col
                              span={getColWidth(1)}
                              sm={getColWidth(2)}
                              md={getColWidth(4)}
                            >
                              <Box mb={2}>
                                <Text size={'xs'} color={'gray'}>
                                  Hours
                                </Text>

                                <div>
                                  {secondsToHours(
                                    (roster.duration * 60) as number
                                  )}
                                </div>
                              </Box>
                            </Col>

                            <Col
                              span={getColWidth(1)}
                              sm={getColWidth(2)}
                              md={getColWidth(4)}
                            >
                              <Box mb={2}>
                                <Text size={'xs'} color={'gray'}>
                                  Event
                                </Text>

                                <div>
                                  {roster.event_title
                                    ? roster.event_title
                                    : '-'}
                                </div>
                              </Box>
                            </Col>
                          </Row>

                          <Row>
                            <Col
                              span={getColWidth(1)}
                              sm={getColWidth(2)}
                              md={getColWidth(4)}
                            >
                              <NotesIcon
                                notes={roster.notes}
                                styles={{ padding: 0 }}
                              />
                            </Col>
                            <Col
                              span={getColWidth(1)}
                              sm={getColWidth(2)}
                              md={getColWidth(4)}
                            >
                              <WhoElseWorking date={roster.start} />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </CardContent>

                    {!!actions.length && (
                      <CardActions className="card-actions">
                        <div className={classes.footerContentWrapper}>
                          {geoTrackingMessage && (
                            <SimpleActionCardFooterLabel
                              className={classes.message}
                            >
                              {geoTrackingMessage}
                            </SimpleActionCardFooterLabel>
                          )}

                          <div className={classes.actionsWrapper}>
                            {actions.reverse().map((action, actionIndex) => (
                              <React.Fragment key={actionIndex}>
                                {action}
                              </React.Fragment>
                            ))}
                          </div>
                        </div>
                      </CardActions>
                    )}
                  </Card>
                );
              })}

              <ListTable.Pagination
                pageSize={pager.pageSize}
                currentPage={pager.currentPage}
                onPageChange={(pageNumber: number) => {
                  dispatch(BOX_MY_ROSTERS_CHANGE_PAGE_REQUEST(pageNumber));
                }}
                onPageSizeChange={(pageSize: number) => {
                  dispatch(BOX_MY_ROSTERS_CHANGE_PAGE_SIZE_REQUEST(pageSize));
                }}
                totalResults={pager.total}
              />
            </>
          ) : (
            <EmptyMessage />
          )}
        </>
      </LoadingOverlay>

      <>
        <PageDialog
          data-testid="swap-shift-dialog"
          maxWidth={'xs'}
          open={modals.type === 'swap' && modals.isTradeOpen}
          id="ModalDialog"
          onClose={() => {
            dispatch(BOX_MY_ROSTERS_TRADE_MODAL_CLOSED());
          }}
        >
          <DialogTitle>Swap shift</DialogTitle>
          <DialogContent>
            <ErrorBox
              errors={modals.errors}
              clearErrors={() => {
                dispatch(BOX_MY_ROSTERS_TRADE_MODAL_ERRORS_CLEARED());
              }}
            />
            <FormItem label={'Swap note'} message="">
              <TextArea
                id="swap-not-text-area"
                name="textArea"
                onChange={(e: any) => {
                  dispatch(BOX_MY_ROSTERS_TRADE_NOTE_SET(e.target.value));
                }}
              />
            </FormItem>
          </DialogContent>
          <DialogActions>
            <PageDialogCancel
              data-testid="swap-shift-dialog-cancel"
              onClick={() => {
                dispatch(BOX_MY_ROSTERS_TRADE_MODAL_CLOSED());
              }}
            >
              Cancel
            </PageDialogCancel>
            <PageDialogSubmit
              data-testid="swap-shift-dialog-submit"
              loading={modals.isUpdating}
              disabled={modals.isUpdating}
              onClick={() => {
                dispatch(
                  BOX_MY_ROSTERS_TRADE_REQUEST({
                    shift_id: modals.shiftId,
                    type: 'swap',
                    note: modals.note,
                  })
                );
              }}
              id="swap-done-button"
            >
              Done
            </PageDialogSubmit>
          </DialogActions>
        </PageDialog>

        <PageDialog
          data-testid="offer-shift-dialog"
          maxWidth={'xs'}
          open={modals.type === 'offer' && modals.isTradeOpen}
          id="ModalDialog"
          onClose={() => {
            dispatch(BOX_MY_ROSTERS_TRADE_MODAL_CLOSED());
          }}
        >
          <DialogTitle>Drop shift</DialogTitle>
          <DialogContent>
            <ErrorBox
              errors={modals.errors}
              clearErrors={() => {
                dispatch(BOX_MY_ROSTERS_TRADE_MODAL_ERRORS_CLEARED());
              }}
            />
            <Alert className={'mb-5'} severity="warning">
              Note that until your offer is approved (and the shift is assigned
              to another) it remains your responsibility to work this shift
            </Alert>
            <br />
            <FormItem label={'Offer note'} message="">
              <TextArea
                id="offer-note-text-area"
                name="textArea"
                onChange={(e: any) => {
                  dispatch(BOX_MY_ROSTERS_TRADE_NOTE_SET(e.target.value));
                }}
              />
            </FormItem>
          </DialogContent>
          <DialogActions>
            <PageDialogCancel
              data-testid="offer-shift-dialog-cancel"
              onClick={() => {
                dispatch(BOX_MY_ROSTERS_TRADE_MODAL_CLOSED());
              }}
            >
              Cancel
            </PageDialogCancel>
            <PageDialogSubmit
              data-testid="offer-shift-dialog-submit"
              id="offer-done-button"
              loading={modals.isUpdating}
              disabled={modals.isUpdating}
              onClick={() => {
                dispatch(
                  BOX_MY_ROSTERS_TRADE_REQUEST({
                    shift_id: modals.shiftId,
                    type: 'offer',
                    note: modals.note,
                  })
                );
              }}
            >
              Done
            </PageDialogSubmit>
          </DialogActions>
        </PageDialog>

        <ConfirmationModal />
      </>

      <ErrorsModal
        isOpened={modals.breakLimit}
        onClose={() => {
          dispatch(BOX_MY_ROSTERS_BREAKS_LIMIT_CONFIRMATION_MODAL_CLOSED());
        }}
        modalTestId={'ModalLarge'}
      >
        {modals.errors.join(', ')}
      </ErrorsModal>
    </div>
  );
};
