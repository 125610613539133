import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaAction } from 'type';
import { Api } from 'lib/Api';
import { formatError } from 'state/helpers';
import { processApiRequest } from 'state/ProcessApiRequest';
import {
  GetNotificationPayload,
  UpdateHoursPayload,
  UpdateNotificationPayload,
} from './type';
import * as actions from './actions';

export const getNotificationRequest = function* (
  group: string,
  id: string
): SagaIterator {
  const notification: any = yield call(
    processApiRequest,
    Api.Notifications.getNotification,
    group,
    id
  );
  yield put(actions.BOX_NOTIFICATION_SUCCESS(notification));
};

export const getNotification = function*(
  action: SagaAction<GetNotificationPayload>
): SagaIterator {
  try {
    const { group, id } = action.payload;
    yield call(getNotificationRequest, group, id);
  } catch (error) {
    yield put(actions.BOX_NOTIFICATION_FAILURE(formatError(error)));
  }
};

export const updateNotification = function*({
  payload
}: SagaAction<UpdateNotificationPayload>): SagaIterator {
  try {
    yield call(
      processApiRequest,
      Api.Notifications.updateNotification,
      payload.message,
      payload.params
    );
    yield put(actions.BOX_UPDATE_NOTIFICATION_SUCCESS());

    let url = payload.params.split('/');

    yield put(actions.BOX_NOTIFICATION_REQUEST({ group: url[0], id: url[1] }));
  } catch (error) {
    yield put(actions.BOX_UPDATE_NOTIFICATION_FAILURE(formatError(error)));
  }
};

export const updateNotificationHours = function*({
  payload
}: SagaAction<UpdateHoursPayload>): SagaIterator {
  try {
    yield call(
      processApiRequest,
      Api.Notifications.updateNotification,
      payload.message,
      payload.params
    );
    yield put(actions.BOX_UPDATE_NOTIFICATION_HOURS_SUCCESS());

    let url = payload.params.split('/');

    yield put(actions.BOX_NOTIFICATION_REQUEST({ group: url[0], id: url[1] }));
  } catch (error) {
    yield put(
      actions.BOX_UPDATE_NOTIFICATION_HOURS_FAILURE(formatError(error))
    );
  }
};

export const uploadImage = function*(action: SagaAction<any>): SagaIterator {
  try {
    const { filepath } = yield call(
      processApiRequest,
      Api.Notifications.upload,
      action.payload
    );
    yield put(actions.BOX_NOTIFICATION_UPLOAD_SUCCESS(filepath));
  } catch (error) {
    yield put(actions.BOX_NOTIFICATION_UPLOAD_FAILURE(formatError(error)));
  }
};

export const watchNotificationSettings = function*(): SagaIterator {
  yield takeLatest(actions.BOX_NOTIFICATION_REQUEST, getNotification);
  yield takeLatest(actions.BOX_UPDATE_NOTIFICATION_REQUEST, updateNotification);
  yield takeLatest(
    actions.BOX_UPDATE_NOTIFICATION_HOURS_REQUEST,
    updateNotificationHours
  );
  yield takeLatest(actions.BOX_NOTIFICATION_UPLOAD_REQUEST, uploadImage);
};
