import React from 'react';
import { ListTableProps } from './type';
import LayoutContext from '../LayoutContext';
import ListTable from './ListTable';

class LayoutAwareListTable extends React.Component<ListTableProps> {
  render() {
    return (
      <LayoutContext.Consumer>
        {(layoutProps: any) => {
          return (
            <ListTable
              {...this.props}
              hasLayout={true}
              hasBulkActionsButton={true}
              isBulkActionOpen={layoutProps.isBulkActionOpen}
              toggleBulkActionOpen={layoutProps.toggleBulkActionOpen}
              toggleBulkActionDisabled={layoutProps.toggleBulkActionDisabled}
            />
          );
        }}
      </LayoutContext.Consumer>
    );
  }
}

export default LayoutAwareListTable;
