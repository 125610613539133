import { SagaIterator } from 'redux-saga';
import { call, delay, put } from 'redux-saga/effects';
import { Api } from 'lib/Api';
import { formatError } from '../helpers';
import * as actions from './actions';

import { processApiRequest } from '../ProcessApiRequest/sagas';

export const getData = function* (): SagaIterator {
  try {
    const profile = yield call(processApiRequest, Api.Preferences.getProfile);
    yield put(actions.BOX_PREFERENCES_PROFILE_SUCCESS(profile));
  } catch (error) {
    yield put(actions.BOX_PREFERENCES_PROFILE_FAILURE(formatError(error)));
  }
};

export const updateProfile = function*(action: any): SagaIterator {
  try {
    yield call(processApiRequest, Api.Preferences.update, action.payload);
    yield put(actions.BOX_PREFERENCES_UPDATE_PROFILE_SUCCESS());
  } catch (error) {
    yield put(actions.BOX_PREFERENCES_UPDATE_PROFILE_FAILURE(formatError(error)));
  }
};

export const updatePin = function*(action: any): SagaIterator {
  try {

    yield call(processApiRequest, Api.Preferences.pin, action.payload);
    yield put(actions.BOX_PREFERENCES_RESET_PIN_SUCCESS());

    yield put(actions.BOX_PREFERENCES_SHOW_NOTIFICATION());
    yield delay(5000);
    yield put(actions.BOX_PREFERENCES_HIDE_NOTIFICATION());

  } catch (error) {
    yield put(actions.BOX_PREFERENCES_RESET_PIN_FAILURE(formatError(error)));
  }
};
