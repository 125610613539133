import React from 'react';
import { connect } from 'react-redux';
import { map } from 'lodash';
import { Card, NativeTable, Paragraph, Text } from 'elmo-elements';
import {
  AccountTreeSelector,
  FormattedErrors,
  LanguagePreferences,
  UserProfileFields,
  UserRole,
} from 'type';
import { capitalize, getRoleName } from 'lib/helpers';
import { StoreState } from 'state/types';
import { getAccountTree } from 'state/AccountTree';
import { getLangPreferences } from 'state/Account';
import {
  BOX_USER_PROFILE_ADD_ROLE_REQUEST,
  BOX_USER_PROFILE_ROLES_MODAL_CLOSE,
  BOX_USER_PROFILE_ROLES_MODAL_OPEN,
  getErrors,
  getIsOpened,
  getIsUpdating,
  getUserProfile,
  getUserRoles,
} from 'state/Users/UserProfile';
import {
  RoleModal,
  RoleModalSubmitPayload,
} from '../../../../../../components';
import { EditButton } from '../';

type OwnProps = {
  initRoles?: UserRole[];
};

type StateProps = {
  roles: UserRole[];
  tree: AccountTreeSelector;
  isOpened: boolean;
  isUpdating: boolean;
  errors: FormattedErrors;
  langPreferences: LanguagePreferences;
  userProfile: UserProfileFields;
};

type DispatchProps = {
  closeModal: () => void;
  openModal: () => void;
  onFormSubmit: (data: RoleModalSubmitPayload) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

export const PositionComponent = ({
  langPreferences,
  openModal,
  isOpened,
  isUpdating,
  closeModal,
  onFormSubmit,
  errors,
  roles,
  tree,
  userProfile
}: Props) => (
  <Card
    isFullHeight={true}
    heading={capitalize(langPreferences.role.singular)}
    option={userProfile.is_active && <EditButton onClick={openModal} ariaLabel="Edit" />}
  >
    <NativeTable isFullWidth={true}>
      <NativeTable.Header>
        <NativeTable.Tr>
          <NativeTable.Th width={'60%'}>
            <Text size={'sm'}>{capitalize(langPreferences.role.singular)}</Text>
          </NativeTable.Th>
          <NativeTable.Th width={'40%'}>
            <Text size={'sm'}>{`${capitalize(
              langPreferences.area.singular
            )}, ${capitalize(langPreferences.site.singular)}`}</Text>
          </NativeTable.Th>
        </NativeTable.Tr>
      </NativeTable.Header>
      <NativeTable.Body>
        {map(roles, (userRole: UserRole, index) => {
          const roleName = getRoleName(userRole, tree.roles, 'role');
          const areaName = getRoleName(userRole, tree.areas, 'area');
          const siteName = getRoleName(userRole, tree.sites, 'site');

          return (
            <NativeTable.Tr
              key={userRole.role_id + userRole.area_id + userRole.site_id}
            >
              <NativeTable.Td>
                <Paragraph className={`position-value position-value-${index}`}>
                  {roleName} {userRole.is_main && (
                    <Text size={'sm'} className={'label-primary'}>
                      (Primary)
                    </Text>
                  )}
                </Paragraph>
              </NativeTable.Td>
              <NativeTable.Td>
                <Paragraph
                  className={`area-site-value area-site-value-${index}`}
                >
                  {`${areaName}, ${siteName}`}
                </Paragraph>
              </NativeTable.Td>
            </NativeTable.Tr>
          );
        })}
      </NativeTable.Body>
    </NativeTable>
    <RoleModal
      isOpened={isOpened}
      isUpdating={isUpdating}
      onCloseModal={closeModal}
      onFormSubmit={onFormSubmit}
      errors={errors}
      initRoles={roles}
    />
  </Card>
);

const mapStateToProps = (state: StoreState): StateProps => ({
  roles: getUserRoles(state),
  tree: getAccountTree(state),
  isOpened: getIsOpened(state, 'rolesModal'),
  isUpdating: getIsUpdating(state),
  errors: getErrors(state),
  langPreferences: getLangPreferences(state),
  userProfile: getUserProfile(state)
});

const mapDispatchToProps: DispatchProps = {
  closeModal: BOX_USER_PROFILE_ROLES_MODAL_CLOSE,
  openModal: BOX_USER_PROFILE_ROLES_MODAL_OPEN,
  onFormSubmit: BOX_USER_PROFILE_ADD_ROLE_REQUEST
};
export const Position = connect(
  mapStateToProps,
  mapDispatchToProps
)(PositionComponent);
