import { createAction } from 'lib/store-utils';
import { FormattedErrors } from '../../type/models';
import {
  NotificationListType,
  NotificationsListRequestPayload,
  NotificationsListResponse,
  NotificationMarkReadPayload,
  NotificationMarkReadResult,
  NotificationDeletePayload, NotificationsListItem
} from './types';

export const BOX_NOTIFICATIONS_LIST_GET_REQUEST = createAction<NotificationsListRequestPayload>('Notifications list / get list');
export const BOX_NOTIFICATIONS_LIST_GET_REQUEST_SUCCESS = createAction<NotificationsListResponse>('Notifications list / get list success');
export const BOX_NOTIFICATIONS_LIST_GET_REQUEST_FAILURE = createAction<FormattedErrors>('Notifications list / get list failure');

export const BOX_NOTIFICATIONS_LIST_OPEN_MODAL = createAction<NotificationListType>('Notifications list / open modal');
export const BOX_NOTIFICATIONS_LIST_CLOSE_MODAL = createAction<NotificationListType>('Notifications list / close modal');

export const BOX_NOTIFICATIONS_LIST_CLEAR_ERRORS = createAction('Notifications list / remove errors');

export const BOX_NOTIFICATIONS_LIST_MARK_READ = createAction<NotificationMarkReadPayload>('Notifications list / mark as read request');
export const BOX_NOTIFICATIONS_LIST_MARK_READ_SUCCESS = createAction<NotificationMarkReadResult>('Notifications list / mark as read request success');
export const BOX_NOTIFICATIONS_LIST_MARK_READ_FAILURE = createAction<FormattedErrors>('Notifications list / mark as read request failure');

export const BOX_NOTIFICATIONS_LIST_DELETE_ITEM = createAction<NotificationDeletePayload>('Notifications list / delete item request');
export const BOX_NOTIFICATIONS_LIST_DELETE_ITEM_SUCCESS = createAction<NotificationDeletePayload>('Notifications list / delete item request success');
export const BOX_NOTIFICATIONS_LIST_DELETE_ITEM_FAILURE = createAction<FormattedErrors>('Notifications list / delete item request failure');

export const BOX_NOTIFICATIONS_LIST_APPEND_ONE = createAction<NotificationsListItem>('Notifications list / append one');

export const BOX_NOTIFICATIONS_LIST_READ_ALL = createAction<NotificationListType>('Notifications list / mark all as read');
export const BOX_NOTIFICATIONS_LIST_READ_ALL_SUCCESS = createAction<NotificationListType>('Notifications list / mark all as read success');

export const BOX_NOTIFICATIONS_LIST_DELETE_ALL = createAction<NotificationListType>('Notifications list / delete all');
export const BOX_NOTIFICATIONS_LIST_DELETE_ALL_SUCCESS = createAction<NotificationListType>('Notifications list / delete all success');

export const BOX_NOTIFICATIONS_LIST_BULK_SUCCESS = createAction('Notifications list / bulk action success');
export const BOX_NOTIFICATIONS_LIST_BULK_FAILURE = createAction<FormattedErrors>('Notifications list / bulk action failure');
