import React from 'react';
import { Box, useTheme } from 'extended-oxygen-elements';

type TimesheetCardHeaderIconProps = {
  children: React.ReactNode;
  edge: 'start' | 'end';
};

export function TimesheetCardHeaderIcon({
  children,
  edge,
}: TimesheetCardHeaderIconProps) {
  const theme = useTheme();
  const buttonPadding = theme.componentsConfig.iconButton.small.padding;

  return (
    <Box
      position="absolute"
      lineHeight={1}
      top={0}
      left={edge === 'start' ? -buttonPadding : 'auto'}
      right={edge === 'end' ? -buttonPadding : 'auto'}
    >
      {children}
    </Box>
  );
}
