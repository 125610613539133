import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import { DispatchProps, StateProps } from './types';
import WithArrowsNav from '../../../components/WithArrowsNav';
import {
  BOX_SHIFT_TIME_RANGE_INPUT_CLOSED,
  getIsLoading
} from 'state/Roster/RangeInput/ShiftTimeRangeInput';
import { times } from 'lodash';
import { siteRowsNumberSelector } from 'state/Roster/RosterWeekViewSiteView';
import { WeekViewSiteViewSiteRow } from './WeekViewSiteViewCashed/WeekViewSiteViewSiteRow/SiteRows';
import { AreaHeading } from './WeekViewSiteViewCashed/WeekViewSiteViewSiteRow/SiteRows/components/AreaHeading';

type Props = StateProps & DispatchProps;

class View extends React.Component<Props, {}> {
  render() {
    const {
      isLoading
    } = this.props;
    return (
      <div className={'week-view-container'}>
        <WithArrowsNav
          renderIndex={0}
          closeInput={this.props.closeInput}
          inlineIsLoading={isLoading}
        />
        {
          times(this.props.siteRowsNumber, (rowIndex) => (
            <React.Fragment key={rowIndex}>
              <AreaHeading siteRowIndex={rowIndex} />
              <WeekViewSiteViewSiteRow siteRowIndex={rowIndex} />
            </React.Fragment>
          ))
        }
      </div>
    );
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  isLoading: getIsLoading(state),
  siteRowsNumber: siteRowsNumberSelector(state)
});

const mapToDispatchProps: DispatchProps = {
  closeInput: BOX_SHIFT_TIME_RANGE_INPUT_CLOSED
};

export default connect(
  mapStateToProps,
  mapToDispatchProps
)(View);