import { FeatureFlag, Redirect } from 'element';
import React from 'react';
import { privateRoutes } from 'routes';

export type FeatureWrapperProps = {
  children: React.ReactNode;
};

export default FeatureWrapper;

export function FeatureWrapper({ children }: FeatureWrapperProps) {
  return (
    <FeatureFlag name="weekendShiftOvertimeRules">
      <FeatureFlag.On>{children}</FeatureFlag.On>

      <FeatureFlag.Off>
        <Redirect delayed to={privateRoutes.settings.routes.pay.path} />
      </FeatureFlag.Off>
    </FeatureFlag>
  );
}
