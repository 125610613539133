import { makeDefaultBreakRules } from 'page/Settings/components';
import { FormFields } from './makeFormFields';

export default function makePayload(formFields: FormFields) {
  return {
    ...formFields,
    default_breaks_rules: makeDefaultBreakRules(
      formFields.default_breaks_rules
    ),
  };
}
