import { HeaderButtonProp, PrimaryButtonType } from 'element';
import _ from 'lodash';
import * as rosterHooks from 'page/Roster/hooks';
import React from 'react';

type RenderParams = {
  buttons: HeaderButtonProp[];
  primaryButton: PrimaryButtonType | undefined;
};

export type WithHeaderButtonsProps = {
  children: (params: RenderParams) => React.ReactElement | null;
};

export default WithHeaderButtons;

export function WithHeaderButtons({ children }: WithHeaderButtonsProps) {
  const buttons = useHeaderButtons();
  const primaryButton = rosterHooks.useHeaderPrimaryButton();

  return children({ buttons, primaryButton });
}

function useHeaderButtons() {
  const btnExportTimesheets = rosterHooks.useBtnExportTimesheets();
  const btnAutoGenerateTimesheets = rosterHooks.useBtnAutoGenerateTimesheets();
  const btnOfferRosteredShifts = rosterHooks.useBtnOfferRosteredShifts();
  const btnApproveTimesheets = rosterHooks.useBtnApproveTimesheets();
  const btnPrint = rosterHooks.useBtnPrint();

  return _.compact([
    btnExportTimesheets,
    btnAutoGenerateTimesheets,
    btnOfferRosteredShifts,
    btnApproveTimesheets,
    btnPrint,
  ]);
}
