import { createSelector } from 'reselect';
import { uniq } from 'lodash';
import { AccountTreeArea, AccountTreeRole, AccountTreeSelector } from 'type';
import { getAccountTree } from 'state/AccountTree';
import { rosteredShiftsArraySelector } from 'state/RosteredShifts';
import { timesheetsArraySelector } from 'state/TimesheetsCollection';

type ArchivedAreaRoleIdsSelector = {
  archivedAreaIdsToBeShown: string[];
  archivedRoleIdsToBeShown: string[];
};

const archivedAreaRoleIdsCombiner = <
  Shift extends {
    area_id: string;
    role_id: string;
  }
>(
  rosteredShiftsArray: Shift[],
  accountTree: AccountTreeSelector
): ArchivedAreaRoleIdsSelector => {
  const initialAreaIdsRoleIds: ArchivedAreaRoleIdsSelector = {
    archivedAreaIdsToBeShown: [],
    archivedRoleIdsToBeShown: []
  };

  return rosteredShiftsArray.reduce(
    (accumulator, { area_id, role_id }): ArchivedAreaRoleIdsSelector => {
      const area = accountTree.areas[area_id] as AccountTreeArea | undefined;
      const role = accountTree.roles[role_id] as AccountTreeRole | undefined;

      if (area && area.archived) {
        accumulator.archivedAreaIdsToBeShown.push(area.id);
      }

      if (role && role.archived) {
        accumulator.archivedRoleIdsToBeShown.push(role.id);
      }

      return accumulator;
    },
    initialAreaIdsRoleIds
  );
};

const rosteredShiftsArchivedAreaRoleIdsSelector = createSelector(
  rosteredShiftsArraySelector,
  getAccountTree,
  archivedAreaRoleIdsCombiner
);

const timesheetsArchivedAreaRoleIdsSelector = createSelector(
  timesheetsArraySelector,
  getAccountTree,
  archivedAreaRoleIdsCombiner
);

export const archivedAreaRoleIdsToBeShownSelector = createSelector(
  rosteredShiftsArchivedAreaRoleIdsSelector,
  timesheetsArchivedAreaRoleIdsSelector,
  (
    rosteredShiftsArchivedAreaRoleIds,
    timesheetsArchivedAreaRoleIds
  ): ArchivedAreaRoleIdsSelector => ({
    archivedAreaIdsToBeShown: uniq([
      ...rosteredShiftsArchivedAreaRoleIds.archivedAreaIdsToBeShown,
      ...timesheetsArchivedAreaRoleIds.archivedAreaIdsToBeShown
    ]),
    archivedRoleIdsToBeShown: uniq([
      ...rosteredShiftsArchivedAreaRoleIds.archivedRoleIdsToBeShown,
      ...timesheetsArchivedAreaRoleIds.archivedRoleIdsToBeShown
    ])
  })
);
