import { eventChannel } from 'redux-saga';
import { call, put, take } from 'redux-saga/effects';
import { Action } from './types';

export default makeChannelListener;

export function makeChannelListener(
  subscribe: (emit: (action: Action<any>) => void) => void
) {
  const makeEchoChannel = () =>
    eventChannel((emit) => {
      subscribe(emit);
      return () => {};
    });

  return function* () {
    const echoChannel = yield call(makeEchoChannel);

    while (true) {
      const action = yield take(echoChannel);
      yield put(action);
    }
  };
}
