import { SagaIterator } from 'redux-saga';
import { fork } from 'redux-saga/effects';
import * as TimesheetsSagas from './Timesheets/sagas';
import { watchTimesheetsAwards } from './AwardsModal/sagas';
import { watchTimesheetsAllowances } from './AllowancesModal/sagas';

export const watchTimesheets = function*(): SagaIterator {
  yield fork(TimesheetsSagas.watchTimesheets);
  yield fork(watchTimesheetsAwards);
  yield fork(watchTimesheetsAllowances);
}
