import React from 'react';
import { IconButtonProps } from 'extended-oxygen-elements';
import { CheckBoxOutlineBlankOutlinedIcon } from 'element';
import { IconWrapper } from './components';

export function UncheckedIcon(props: IconButtonProps) {
  return (
    <IconWrapper {...props}>
      <CheckBoxOutlineBlankOutlinedIcon />
    </IconWrapper>
  );
}
