import * as React from 'react';
import { secondsToHours } from 'lib/helpers';
import { DashboardShift, DashboardShiftBreak } from 'type/models';

type Props = {
  shift: DashboardShift;
  paid: boolean;
};

class BreakTimer extends React.Component <Props> {

  render() {
    return secondsToHours(this.getCurrentDuration(this.props.paid), false);
  }

  getMealBreaks = (shift: DashboardShift) => {
    let breaks = 0;
    shift.breaks.forEach((shiftBreak: DashboardShiftBreak) => {
      if (shiftBreak.paid) {
        breaks += shiftBreak.duration;
      }
    });
    return breaks * 60;
  }

  getRestBreaks = (shift: DashboardShift) => {
    let breaks = 0;
    shift.breaks.forEach((shiftBreak: DashboardShiftBreak) => {
      if (!shiftBreak.paid) {
        breaks += shiftBreak.duration;
      }
    });
    return breaks * 60;
  }

  getCurrentDuration = (isPaid: boolean) => {

    let duration = 0;

    return isPaid ?
      (duration + this.getMealBreaks(this.props.shift)) : (duration + this.getRestBreaks(this.props.shift));
  }
}

export default BreakTimer;
