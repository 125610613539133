import { useDispatch } from 'react-redux';
import { useSelectorWithProps } from 'hooks';
import {
  getTimesheetEnd,
  getTimesheetIsApproved,
} from 'state/TimesheetsCollection';
import { useCallback } from 'react';
import { BOX_ROSTER_GLOBAL_APPROVE_TIMESHEET } from 'state/Roster/Roster';

type Props = {
  id: string;
};

export const useHandleApprovalClick = ({ id }: Props) => {
  const dispatch = useDispatch();
  const isApproved = useSelectorWithProps(getTimesheetIsApproved, id);
  const end = useSelectorWithProps(getTimesheetEnd, id);

  const canBeApproved = !isApproved && !!end;
  return useCallback(() => {
    if (!canBeApproved) {
      return;
    }

    dispatch(
      BOX_ROSTER_GLOBAL_APPROVE_TIMESHEET({
        id,
        is_approved: false,
      })
    );
  }, [dispatch, canBeApproved, id]);
};
