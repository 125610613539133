import React, { Component } from 'react';
import { Heading, NativeTable, Paragraph, Text } from 'elmo-elements';
import {
  MergedAwardsList
} from 'type/models';
import _ from 'lodash';

type PayElement = {
  name: string,
  id: string,
  payslip_name: string
};

type AwardRule = {
  id: string;
  name: string;
  category: string;
  pay_elements: PayElement[];
  description: string;
};

type OwnProps = {
  award: MergedAwardsList;
};

type Props = OwnProps;

class AwardDetailsView extends Component<Props> {

  showHeader = () => {
    return (
      <NativeTable.Header className={'table-header'}>
        <NativeTable.Tr>
          <NativeTable.Th width={'70%'} className={'td-border-right'}>
            <Paragraph size={'rg'}>Rule</Paragraph>
          </NativeTable.Th>
          <NativeTable.Th width={'30%'}>
            <Paragraph size={'rg'}>Pay element</Paragraph>
          </NativeTable.Th>
        </NativeTable.Tr>
      </NativeTable.Header>
    );
  }

  getCategoryDetails = (id: string, rules: any[]) => {
    const awardRules: any[] = [];
    for (let rule of rules) {
      if ( rule.category === id ) {
        awardRules.push(rule);
      }
    }
    return awardRules.length ? awardRules : null;
  }

  getPayElements = (elements: PayElement[]) => {
    const divideBlocks = (payEl: PayElement, ind: number) => {
      return (
        <div key={`p-${ind}`} className={'pay-element'}>
          <Paragraph>
            {payEl.name}
          </Paragraph>
          <Paragraph size={'sm'} className={'text-color-gray'}>
            ({payEl.payslip_name})
          </Paragraph>
        </div>
      );
    };
    if ( elements.length === 0 ) {
      return '-';
    }
     return (
      elements.map((element: any, ind: number) => (
        divideBlocks(element, ind)
      ))
    );
  };

  showRules = (rules: AwardRule[] | null) => {

    if ( !rules ) {
      return <Text size={'sm'} color={'gray'}>No rules for the current category</Text>;
    }

    return (
      <NativeTable className={'mt-2 mb-4'} isFullWidth={true}>
        {this.showHeader()}
        <NativeTable.Body>
          {
            rules.map((rule: AwardRule, index: number) => (
              <NativeTable.Tr key={`tr-${index}`} valign={'top'}>
                <NativeTable.Td width={'70%'} valign={'top'} className={'td-border-right'}>
                  <Paragraph className={'mb-2'}>{rule.name}</Paragraph>
                  <Paragraph size={'sm'} className={'text-color-gray'}>{rule.description}</Paragraph>
                </NativeTable.Td>
                <NativeTable.Td valign={'top'} className={'pl-0 pr-0 pt-0 pb-0'}>
                  {
                    this.getPayElements(rule.pay_elements)
                  }
                </NativeTable.Td>
              </NativeTable.Tr>
            ))
          }
        </NativeTable.Body>
      </NativeTable>
    );
  };

  render() {
    const { rules, award, categories } = this.props.award;

    if ( !award ) {
      return <Paragraph className={'pt-4 pb-4'}>Employee doesn't have assigned award</Paragraph>;
    }

    const categoriesById: any = _.keyBy(categories, 'id');

    for (let key in categoriesById) {
      categoriesById[key].rules = this.getCategoryDetails(key, rules);
    }

    return (
      <div className={'award-content'}>
        {
          categories.map((category, index) => (
            <React.Fragment key={index}>
              <Heading htmlTag={'h2'} size={'sm'} color={'secondary'}>
                {category.name}
              </Heading>
              {
                this.showRules(categoriesById[category.id].rules)
              }
            </React.Fragment>
          ))
        }
      </div>
    );
  }
}

export default AwardDetailsView;