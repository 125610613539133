import { ConnectActionCreator, createReducer } from 'lib/store-utils';
import _ from 'lodash';
import channelActions, { Channel } from './channelActions';

type SocketsReducerState = {
  channels: Channel[];
};

export const sockets = createReducer<SocketsReducerState>(
  {},
  {
    channels: [],
  }
);

_.mapValues(
  channelActions,
  (action: ConnectActionCreator<any>, channelName: Channel) => {
    const connectionHandler = makeConnectionHandler(channelName);

    sockets
      .on(action.connected, connectionHandler)
      .on(action.disconnected, connectionHandler);
  }
);

function makeConnectionHandler(channelName: Channel) {
  return (state: SocketsReducerState): SocketsReducerState => ({
    ...state,
    channels: _.xor(state.channels, [channelName]),
  });
}
