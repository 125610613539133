import React from 'react';
import { Loader } from 'elmo-elements';
import { useTheme } from 'extended-oxygen-elements';
import './style.scss';

export const FullScreenLoadingOverlay = () => {
  const zIndex = useTheme().zIndex.modal;

  return (
    <div className={'full-screen-loading-overlay'} style={{ zIndex }}>
      <Loader isLoading={true} type={'spinner'} className={'position-center'} />
    </div>
  );
};
