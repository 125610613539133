import React from 'react';
import { MenuProps, PopoverProps } from 'extended-oxygen-elements';

type PopupState = {
  popupId?: string;
  anchorEl: null | HTMLElement;
  open: (event: React.MouseEvent<HTMLElement>) => void;
  close: () => void;
};
type UsePopupStateProps = {
  popupId?: string;
};
export const usePopupState = ({
  popupId,
}: UsePopupStateProps = {}): PopupState => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const close = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  return {
    popupId,
    anchorEl,
    open,
    close,
  };
};
export const bindTrigger = ({ popupId, open }: PopupState) => ({
  'aria-controls': popupId,
  onClick: open,
});
export const bindMenu = ({
  popupId,
  anchorEl,
  close,
}: PopupState): MenuProps => ({
  id: popupId,
  anchorEl,
  open: !!anchorEl,
  onClose: close,
});
export const bindPopover: (popupState: PopupState) => PopoverProps = bindMenu;
