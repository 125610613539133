import { useBulkApproveContext, useBulkDeleteContext } from 'contexts';
import {
  CheckCircleOutlinedIcon,
  DeleteSweepOutlinedIcon,
  NewHeader,
  NewHeaderAction,
  NewHeaderTitle,
  NewHeaderWrapper,
} from 'element';
import {
  Box,
  Button,
  Hidden,
  IconButton,
  Visible,
} from 'extended-oxygen-elements';
import { useSelectorWithProps } from 'hooks';
import React from 'react';
import { hasPermissionSelector } from 'state/Auth';
import { BulkApproveActions, BulkDeleteActions } from './components';

export function PageHeader() {
  const bulkDeleteContext = useBulkDeleteContext();
  const bulkApproveContext = useBulkApproveContext();
  const hasRosterTimesheetEditPermission = useSelectorWithProps(
    hasPermissionSelector,
    'roster.timesheet.edit'
  );
  const hasRosterTimesheetEditApprovePermission = useSelectorWithProps(
    hasPermissionSelector,
    'roster.timesheet.edit.approve'
  );

  const isBulkActionOpen =
    bulkDeleteContext.isEnabled || bulkApproveContext.isEnabled;

  const handleBulkDeleteBtnClick = () => {
    return bulkDeleteContext.enable();
  };

  const handleBulkApproveBtnClick = () => {
    return bulkApproveContext.enable();
  };

  return (
    <NewHeaderWrapper>
      <NewHeaderAction visible={bulkDeleteContext.isEnabled}>
        <BulkDeleteActions />
      </NewHeaderAction>

      <NewHeaderAction visible={bulkApproveContext.isEnabled}>
        <BulkApproveActions />
      </NewHeaderAction>

      <NewHeader hidden={isBulkActionOpen}>
        <NewHeaderTitle>Timesheets - Weekly</NewHeaderTitle>

        <Box ml="auto" mr={-1} display="flex">
          {hasRosterTimesheetEditPermission && (
            <Box mr={1}>
              <Hidden up="sm">
                <IconButton onClick={handleBulkDeleteBtnClick}>
                  <DeleteSweepOutlinedIcon />
                </IconButton>
              </Hidden>

              <Visible up="sm">
                <Button
                  color="grey"
                  size="medium"
                  startIcon={<DeleteSweepOutlinedIcon />}
                  onClick={handleBulkDeleteBtnClick}
                  data-testid="bulk-delete-mode-btn"
                >
                  Bulk delete
                </Button>
              </Visible>
            </Box>
          )}

          {hasRosterTimesheetEditPermission &&
            hasRosterTimesheetEditApprovePermission && (
              <Box mr={1}>
                <Hidden up="sm">
                  <IconButton
                    color="secondary"
                    onClick={handleBulkApproveBtnClick}
                  >
                    <CheckCircleOutlinedIcon />
                  </IconButton>
                </Hidden>

                <Visible up="sm">
                  <Button
                    size="medium"
                    color="secondary"
                    variant="contained"
                    startIcon={<CheckCircleOutlinedIcon />}
                    onClick={handleBulkApproveBtnClick}
                    data-testid={'bulk-approve-mode-btn'}
                  >
                    Bulk approve
                  </Button>
                </Visible>
              </Box>
            )}
        </Box>
      </NewHeader>
    </NewHeaderWrapper>
  );
}
