import React from 'react';
import { connect } from 'react-redux';
import { WithPreload } from 'element';
import { withRouter, RouteComponentProps } from 'react-router';
import { BOX_REPORT_GENERATE_EXIST_REPORT_REQUEST, BOX_REPORT_GET_REPORT_REQUEST } from 'state/Report';
import { StoreState } from 'state/types';
import View from '../../components/ReportView';
import { GeneratedReport, Report } from 'type/models';
import EditReport from '../../components/EditReportModal';
import SaveAs from '../../components/SaveAs';
import SaveReportNotification from '../../components/SaveReportNotification';

type StateProps = {
  isFetching: boolean;
  report: Report;
  fields: any;
  generatedReport: GeneratedReport;
  errors: string[];
};
type DispatchProps = {
  getReport: (id: string) => void;
  generateExistedReport: (id: string) => void;
};
type Props = {
  report: Report;
  generatedReport: GeneratedReport;
  isFetching: boolean;
  fields: any;
};

type TParams = {
  reportId: string;
};

type CurrentProps = Props & DispatchProps & RouteComponentProps<TParams> & StateProps;

type State = {
  isFetching: boolean;
};

class ReportView extends React.Component<CurrentProps, State> {

  constructor(props: CurrentProps) {
    super(props);
    this.state = {
      isFetching: true
    };
  }

  componentDidUpdate(
    prevProps: Readonly<CurrentProps>,
    prevState: Readonly<StateProps>,
    snapshot?: any): void {
    const { reportId } = this.props.match.params;
    if ( prevProps.report !== this.props.report && (!this.props.errors || this.props.errors.length === 0) ) {
      this.setState({
        isFetching: false
      });
      if ( this.props.generatedReport.isFetched === false ) {
        this.props.generateExistedReport(reportId);
      }
    }
    if ( this.props.errors && this.state.isFetching ) {
      this.setState({
        isFetching: false
      });
    }
  }

  render() {
    const { params } = this.props.match;
    return (
      <WithPreload isFetching={this.state.isFetching} fetchData={this.props.getReport} params={params}>
        <View title={this.props.report.name} isEdit={true} />
        <SaveAs redirectOnSuccess={false} />
        <EditReport />
        <SaveReportNotification />
      </WithPreload>
    );
  }

}

const mapStateToProps = ({
  report: { isFetching, report, generatedReport, fields, errors }
}: StoreState): StateProps => ({
  isFetching, report, generatedReport, fields, errors
});

const mapDispatchToProps: DispatchProps = {
  getReport: BOX_REPORT_GET_REPORT_REQUEST,
  generateExistedReport: BOX_REPORT_GENERATE_EXIST_REPORT_REQUEST
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportView));
