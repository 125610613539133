import React, { SyntheticEvent } from 'react';
import classNames from 'clsx';
import { Checkbox } from 'elmo-elements';
import { Option } from 'state/SiteAreRoleModal';
import './NestedCheckbox.scss';

type Props = {
  data: Option[];
  isIndented?: boolean;
  parentIds?: string[];
  onChange: (ids: string[], isChecked: boolean) => void;
};

export const NestedCheckbox = ({
  data,
  isIndented = true,
  parentIds = [],
  onChange
}: Props) => (
  <>
    {data.map(({ id, isChecked, isIndeterminate, label, options }) => {
      const parentIdsWithCurrentId = [...parentIds, id];

      return (
        <div
          key={id}
          className={classNames('nested-checkbox', {
            'is-indented': isIndented
          })}
        >
          <Checkbox
            id={parentIdsWithCurrentId.join('---')}
            label={label}
            name={id}
            isChecked={isChecked}
            isIndeterminate={isIndeterminate}
            onChange={(event: SyntheticEvent<HTMLInputElement>) => {
              onChange(parentIdsWithCurrentId, event.currentTarget.checked);
            }}
          />

          {!!options && (
            <NestedCheckbox
              data={options}
              parentIds={parentIdsWithCurrentId}
              onChange={onChange}
            />
          )}
        </div>
      );
    })}
  </>
);
