export const convertDecimalToFormattedTime = (
  v: string,
  withMinutes: boolean = false
) => {
  const d = parseFloat(v);

  if (isNaN(d)) {
    return '0h';
  }

  const hours = parseInt(v, 10);
  const mins = d - hours;

  let h = '';
  let m = '';

  if (hours > 0) {
    h = `${hours}h`;
  }

  if (mins > 0) {
    m = (mins * 60).toFixed(0) + 'm';
  }

  if (withMinutes && mins === 0) {
    m = '0m';
  }

  return h + (h.length && m.length ? ' ' : '') + m;
};
export const convertFormattedTimeToDecimal = (value: string) => {
  const time = value.replace(/ /i, '').split('h');
  let val = 0;

  if (value.indexOf('m') !== -1 && value.indexOf('h') !== -1) {
    if (time.length) {
      const h = parseInt(time[0], 10);
      const m = parseInt(time[1], 10);
      val = h + m / 60;
    }
  }

  if (value.indexOf('m') === -1 && value.indexOf('h') !== -1) {
    val = parseFloat(value);
  }

  if (value.indexOf('m') !== -1 && value.indexOf('h') === -1) {
    const m = parseInt(value, 10);
    val = m / 60;
  }

  return val;
};
