import React from 'react';
import { NativeTable, Pagination, Heading } from 'elmo-elements';
import { ColumnsProps, DispatchProps } from '../../type';
import { getCurrencyFormatted, getDateTimeFormatted, getFormattedNumber } from 'lib/helpers';
import {
  getCurrencyCode,
  getCurrencyPlacement, getDateFormat, getNumberFormat, getTimeFormat
} from 'state/Account/selectors';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import { ReportPagination } from 'state/Report/state';
import {
  getCurrentPageRows,
  getGroups,
  getReportPagination,
  getReportTotals,
  getRows
} from 'state/Report/selectors';
import _ from 'lodash';
import ReportTableHeader from '../ReportTableHeader';
import moment from 'moment';
import {
  PreferencesCurrencyCode, PreferencesCurrencyPlacement,
  PreferencesDateFormat,
  PreferencesNumberFormat,
  PreferencesTimeFormat
} from 'type/models';

type CellData = any;

type Props = {
  rows: any[];
  pageRows: any[];
  visibleColumns: ColumnsProps[];
  groups: any[];
  pagination: ReportPagination;
  dateFormat: PreferencesDateFormat;
  timeFormat: PreferencesTimeFormat;
  numberFormat: PreferencesNumberFormat;
  currencyCode: PreferencesCurrencyCode;
  currencyPlacement: PreferencesCurrencyPlacement;
};

type OwnProps = {
  changePage: (page: number, start: number, end: number) => void;
  changePageSize: (size: number) => void;
  report: any;
  openModal: () => void;
};

let showGroupCell = true;

export class SaleGroupedByRows extends React.Component<Props & OwnProps, {}> {

  getValueDescription = ( key: string, value: number ) => {
    const { numberFormat, currencyCode, currencyPlacement } = this.props;
    if ( key === 'percent' ) {
      return `${value}%`;
    }
    return getCurrencyFormatted(numberFormat, currencyCode, currencyPlacement, value);
  }

  showCellData = (key: string, data: CellData) => {
    const outputString = (v: string) => v.length ? v : '-';
    const { dateFormat, timeFormat, numberFormat } = this.props;
    if ( key === 'date' ) {
      if ( typeof data['start'] === 'object' ) {
        return getDateTimeFormatted(dateFormat, timeFormat, data['start'].date);
      } else {
        const convertedDate = moment(data['date'], 'YYYY-MM-DD').format('YYYY-MM-DD');
        return data['date'].indexOf('Week') === -1 ?
          getDateTimeFormatted(dateFormat, timeFormat, convertedDate) :
          data['date'];
      }
    }
    if ( key === 'forecast_revenue' || key === 'actual_revenue' || key === 'variance_revenue' ) {
      return this.getValueDescription(key, data[key]);
    }
    if ( (typeof data[key] === 'object' && Object.keys(data[key]).length) ) {
      return (
        <div className={'inner-columns'}>
          {Object.keys(data[key]).map((columnName: string, index: number) => {
            return <div key={index} className={'inner-column'}>
              {this.getValueDescription(columnName, data[key][columnName])}
            </div>;
          })}
        </div>
      );
    } else {
      let val = typeof data[key] === 'string' ? outputString(data[key]) : getFormattedNumber(numberFormat, +data[key]);
      return key === 'start' || key === 'end'
        ? getDateTimeFormatted(dateFormat, timeFormat, data[key])
        : val;
    }
  }

  render() {

    const {
      pageRows,
      visibleColumns,
      report,
      groups
    } = this.props;

    const filtered: any = _.groupBy(pageRows, row => row.group_id);

    return (
      <>
        <div className={'report-scroll-container'}>
        {
          Object.keys(filtered).map((k: any, i: number) => (
            <NativeTable key={`table-${k}-${i}`} isFullWidth={true} theme={'striped'}>
              <ReportTableHeader
                report={report}
                selectedColumns={visibleColumns}
                openModal={this.props.openModal}
                subHeading={groups[filtered[k][0].group_id].name}
              />
              <NativeTable.Body>
              {filtered[k].map((currentReport: any, trIndex: number) => (
                <NativeTable.Tr key={`row-${trIndex}`}>
                  {visibleColumns.map((column: ColumnsProps, key: number) => (
                    <NativeTable.Td key={`${trIndex}-${key + 1}`}>
                      {this.showCellData(column.name, currentReport)}
                    </NativeTable.Td>
                  ))}
                </NativeTable.Tr>
              ))
              }
              </NativeTable.Body>
            </NativeTable>
          ))
        }
        </div>
        <Pagination
          pageSize={this.props.pagination.pageSize}
          pageSizeOptions={this.props.pagination.pageSizeOptions}
          currentPage={this.props.pagination.currentPage}
          onPageChange={this.props.changePage}
          onPageSizeChange={this.props.changePageSize}
          totalResults={this.props.pagination.totalResults}
        />
      </>
    );
  }
}
const mapStateToProps = (state: StoreState) => ({
  pageRows: getCurrentPageRows(state),
  totals: getReportTotals(state),
  pagination: getReportPagination(state),
  groups: getGroups(state),
  rows: getRows(state),
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
  numberFormat: getNumberFormat(state),
  currencyCode: getCurrencyCode(state),
  currencyPlacement: getCurrencyPlacement(state)
});

export default connect(
  mapStateToProps
)(SaleGroupedByRows);
