import { createReducer } from 'lib/store-utils';
import * as actions from './actions';
import { RosterCopyPastReducerState } from './type';
import { getDefaultState } from './state';
import _ from 'lodash';

export const rosterCopyPast = createReducer<RosterCopyPastReducerState>(
  {},
  getDefaultState()
);

rosterCopyPast.on(
  actions.BOX_ROSTER_COPY_WEEK,
  (state, { range, site_id }): RosterCopyPastReducerState => ({
    ...state,
    range,
    site_id,
  })
);

rosterCopyPast.on(actions.BOX_ROSTER_COPY_WEEK_CLEAR, getDefaultState);

rosterCopyPast.on(
  actions.BOX_ROSTER_PAST_WEEK_SUCCESS,
  (state): RosterCopyPastReducerState => ({
    ...state,
    modal: {
      isOpened: false,
    },
  })
);

rosterCopyPast.on(
  actions.BOX_ROSTER_PAST_WEEK_FAILED,
  (state): RosterCopyPastReducerState => ({
    ...state,
    modal: {
      isOpened: false,
    },
  })
);

rosterCopyPast.on(
  actions.BOX_ROSTER_PAST_CONFIRMATION_MODAL_OPEN,
  (state): RosterCopyPastReducerState => ({
    ...state,
    modal: {
      isOpened: true,
    },
  })
);

rosterCopyPast.on(
  actions.BOX_ROSTER_PAST_CONFIRMATION_MODAL_CLOSE,
  (state): RosterCopyPastReducerState => ({
    ...state,
    modal: {
      isOpened: false,
    },
  })
);

rosterCopyPast.on(
  actions.BOX_ROSTER_PAST_JOBS_RECEIVED,
  (state, ids): RosterCopyPastReducerState => ({
    ...state,
    jobs: ids,
  })
);

rosterCopyPast.on(
  actions.BOX_ROSTER_PAST_JOB_ADD,
  (state, id): RosterCopyPastReducerState => ({
    ...state,
    jobs: _.uniq([...state.jobs, id]),
  })
);

rosterCopyPast.on(
  actions.BOX_ROSTER_PAST_JOB_DELETE,
  (state, id): RosterCopyPastReducerState => ({
    ...state,
    jobs: [...state.jobs.filter((jobId) => jobId !== id)],
  })
);

rosterCopyPast.on(
  actions.BOX_ROSTER_PAST_ALERT_STATUS_HIDE,
  (state): RosterCopyPastReducerState => ({
    ...state,
    jobs: [],
  })
);

rosterCopyPast.on(
  actions.BOX_ROSTER_OPEN_SAVE_TEMPLATE_MODAL,
  (state): RosterCopyPastReducerState => ({
    ...state,
    createRosterTemplateModal: {
      ...state.createRosterTemplateModal,
      isOpened: true,
      errors: [],
    },
  })
);

rosterCopyPast.on(
  actions.BOX_ROSTER_CLOSE_SAVE_TEMPLATE_MODAL,
  (state): RosterCopyPastReducerState => ({
    ...state,
    createRosterTemplateModal: {
      ...state.createRosterTemplateModal,
      isOpened: false,
      errors: [],
    },
  })
);

rosterCopyPast.on(
  actions.BOX_ROSTER_SAVE_TEMPLATE_FAILURE,
  (state, errors): RosterCopyPastReducerState => ({
    ...state,
    createRosterTemplateModal: {
      ...state.createRosterTemplateModal,
      errors: errors,
      isUpdating: false,
    },
  })
);

rosterCopyPast.on(
  actions.BOX_ROSTER_SAVE_TEMPLATE,
  (state): RosterCopyPastReducerState => ({
    ...state,
    createRosterTemplateModal: {
      ...state.createRosterTemplateModal,
      isUpdating: true,
      errors: [],
    },
  })
);

rosterCopyPast.on(
  actions.BOX_ROSTER_SAVE_TEMPLATE_SUCCESS,
  (state): RosterCopyPastReducerState => ({
    ...state,
    createRosterTemplateModal: {
      ...state.createRosterTemplateModal,
      isUpdating: false,
    },
  })
);

rosterCopyPast.on(
  actions.BOX_ROSTER_SAVE_TEMPLATE_CLEAR_ERRORS,
  (state): RosterCopyPastReducerState => ({
    ...state,
    createRosterTemplateModal: {
      ...state.createRosterTemplateModal,
      errors: [],
    },
  })
);

rosterCopyPast.on(
  actions.BOX_ROSTER_OPEN_TEMPLATES_MODAL,
  (state): RosterCopyPastReducerState => ({
    ...state,
    templatesModal: {
      ...state.templatesModal,
      isOpened: true,
    },
  })
);

rosterCopyPast.on(
  actions.BOX_ROSTER_CLOSE_TEMPLATES_MODAL,
  (state): RosterCopyPastReducerState => ({
    ...state,
    templatesModal: {
      ...state.templatesModal,
      isOpened: false,
      errors: [],
    },
  })
);

rosterCopyPast.on(
  actions.BOX_ROSTER_GET_TEMPLATES_LIST,
  (state): RosterCopyPastReducerState => ({
    ...state,
    templatesModal: {
      ...state.templatesModal,
      isLoading: true,
    },
  })
);

rosterCopyPast.on(
  actions.BOX_ROSTER_GET_TEMPLATES_LIST_SUCCESS,
  (state, response): RosterCopyPastReducerState => ({
    ...state,
    templatesModal: {
      ...state.templatesModal,
      isLoading: false,
      templates: response,
    },
  })
);

rosterCopyPast.on(
  actions.BOX_ROSTER_GET_TEMPLATES_LIST_FAILURE,
  (state, errors): RosterCopyPastReducerState => ({
    ...state,
    templatesModal: {
      ...state.templatesModal,
      isLoading: false,
      errors: errors,
    },
  })
);

rosterCopyPast.on(
  actions.BOX_ROSTER_DELETE_TEMPLATE,
  (state, { id }): RosterCopyPastReducerState => ({
    ...state,
    templatesModal: {
      ...state.templatesModal,
      isUpdating: true,
      errors: [],
    },
  })
);

rosterCopyPast.on(
  actions.BOX_ROSTER_DELETE_TEMPLATE_SUCCESS,
  (state, { id }): RosterCopyPastReducerState => ({
    ...state,
    templatesModal: {
      ...state.templatesModal,
      isUpdating: false,
      templates: state.templatesModal.templates.filter((t) => t.id !== id),
    },
  })
);

rosterCopyPast.on(
  actions.BOX_ROSTER_DELETE_TEMPLATE_FAILURE,
  (state, errors): RosterCopyPastReducerState => ({
    ...state,
    templatesModal: {
      ...state.templatesModal,
      isUpdating: false,
      errors: errors,
    },
  })
);

rosterCopyPast.on(
  actions.BOX_ROSTER_TEMPLATES_MODAL_CLEAR_ERRORS,
  (state, errors): RosterCopyPastReducerState => ({
    ...state,
    templatesModal: {
      ...state.templatesModal,
      errors: [],
    },
  })
);

rosterCopyPast.on(
  actions.BOX_ROSTER_APPLY_TEMPLATE,
  (state, payload): RosterCopyPastReducerState => ({
    ...state,
    templatesModal: {
      ...state.templatesModal,
      isUpdating: true,
      errors: [],
    },
  })
);

rosterCopyPast.on(
  actions.BOX_ROSTER_APPLY_TEMPLATE_SUCCESS,
  (state, response): RosterCopyPastReducerState => ({
    ...state,
    templatesModal: {
      ...state.templatesModal,
      isUpdating: false,
      isOpened: false,
    },
  })
);

rosterCopyPast.on(
  actions.BOX_ROSTER_APPLY_TEMPLATE_FAILURE,
  (state, errors): RosterCopyPastReducerState => ({
    ...state,
    templatesModal: {
      ...state.templatesModal,
      isUpdating: false,
      errors: errors,
    },
  })
);
