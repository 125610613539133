import { createReducer } from 'lib/store-utils';
import { omit } from 'lodash';
import { ShiftOfferReducerState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';

export const shiftOffer = createReducer<ShiftOfferReducerState>(
  {},
  getDefaultState()
);

shiftOffer.on(actions.BOX_SHIFT_OFFER_SET_DEFAULT_STATE, getDefaultState);

shiftOffer.on(
  actions.BOX_SHIFT_OFFER_UPDATE_SHIFT_OFFER,
  (state, shiftOfferWithProposals): ShiftOfferReducerState => ({
    ...state,
    shiftOfferWithProposals
  })
);

shiftOffer.on(
  actions.BOX_SHIFT_OFFER_DECLINE_SHIFT_OFFER_PROPOSAL_SUCCESS,
  (state, { proposalId }): ShiftOfferReducerState => ({
    ...state,
    shiftOfferWithProposals: {
      ...state.shiftOfferWithProposals,
      proposals: omit(state.shiftOfferWithProposals.proposals, proposalId)
    }
  })
);

shiftOffer.on(
  actions.BOX_SHIFT_OFFER_SET_OUTDATED_STATE,
  (state, isOutdated): ShiftOfferReducerState => ({
    ...state,
    isOutdated
  })
);

shiftOffer.on(
  actions.BOX_SHIFT_OFFER_SET_ERRORS,
  (state, errors): ShiftOfferReducerState => ({
    ...state,
    errors
  })
);

// shiftOffer.on(actions., (state, payload): ShiftOfferReducerState => ({...state}));
