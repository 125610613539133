import React from 'react';
import { SERVER_DATE_TIME_FORMAT } from 'lib/config';
import {
  BOX_TIMESHEETS_WEEKLY_PAGE_DATA_GET,
  getPageFetchErrors,
  getPageFetching,
} from 'state/TimesheetsWeeklyPage';
import { FetchContainer } from 'element';
import { useTimesheetsNewQuery } from 'hooks';
import { useDateRange } from '../hooks';

type FetchPageDataProps = {
  children: React.ReactNode;
};

export function FetchPageData({ children }: FetchPageDataProps) {
  const fetchPayload = usePagePayload();

  const fetchAction = React.useMemo(
    () => BOX_TIMESHEETS_WEEKLY_PAGE_DATA_GET.request(fetchPayload),
    []
  );

  return (
    <FetchContainer
      action={fetchAction}
      loadingSelector={getPageFetching}
      errorsSelector={getPageFetchErrors}
    >
      {children}
    </FetchContainer>
  );
}

function usePagePayload() {
  const {
    query: { day, site_id, status },
  } = useTimesheetsNewQuery();
  const {
    dateRange: { from, to },
  } = useDateRange({ day });

  return React.useMemo(
    () => ({
      site_id,
      from,
      to,
      status,
    }),
    [
      site_id,
      from.format(SERVER_DATE_TIME_FORMAT),
      to.format(SERVER_DATE_TIME_FORMAT),
      status,
    ]
  );
}
