import { SagaIterator } from 'redux-saga';
import { call, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { SagaAction, SagaActionFromCreator } from 'type';
import { outDatedDataMessage } from 'messages';
import Api, {
  EmployeeDashboardGetMyTimesheetPayload,
  EmployeeDashboardGetMyTimesheetResponse,
} from 'lib/Api';
import {
  BOX_FETCH_PAGE_DATA_FAILURE,
  BOX_FETCH_PAGE_DATA_REQUEST,
  BOX_FETCH_PAGE_DATA_SUCCESS,
  EMPLOYEE_DASHBOARD_MY_TIMESHEET as FETCH_PAGE_NAME,
  isPageFetchedSelector,
} from 'state/FetchPageData';
import { formatError } from 'state/helpers';
import {
  BOX_IS_LOADING_OFF,
  BOX_IS_LOADING_ON,
  EMPLOYEE_DASHBOARD_MY_TIMESHEET as LOADING_PAGE_NAME,
} from 'state/IsLoading';
import { processApiRequest } from 'state/ProcessApiRequest';
import { showOutDatedNotification } from 'state/ToastNotifier';
import {
  BOX_TIMESHEET_SHIFT_MODAL_CREATE_SHIFT_FAILURE,
  BOX_TIMESHEET_SHIFT_MODAL_CREATE_SHIFT_SUCCESS,
  BOX_TIMESHEET_SHIFT_MODAL_GET_BY_ID,
} from 'state/TimesheetModal';
import {
  BOX_EMPLOYEE_DASHBOARD_TIMESHEET_CHANGED,
  BOX_EMPLOYEE_DASHBOARD_TIMESHEET_DELETED,
} from '../EmployeeDashboard';
import {
  ChangePagePayload,
  ChangePageSizePayload,
  CreateSelfAssignedShiftPayload,
  FetchMyTimesheetPayload,
  MyTimesheetFilters,
  MyTimesheetPredefinedFilter,
  SortPayload,
} from './types';
import {
  BOX_MY_TIMESHEET_CHANGE_PAGE_REQUEST,
  BOX_MY_TIMESHEET_CHANGE_PAGE_SIZE_REQUEST,
  BOX_MY_TIMESHEET_CLEAR_FILTER_FORM,
  BOX_MY_TIMESHEET_CREATE_SELF_ASSIGNED_SHIFT_REQUEST,
  BOX_MY_TIMESHEET_CUSTOM_FILTER_REQUEST,
  BOX_MY_TIMESHEET_CUSTOM_FILTERS_MODAL_CLOSE,
  BOX_MY_TIMESHEET_FETCH_MY_TIMESHEET_SUCCESS,
  BOX_MY_TIMESHEET_FETCH_PAGE_DATA_REQUEST,
  BOX_MY_TIMESHEET_PREDEFINED_FILTER_REQUEST,
  BOX_MY_TIMESHEET_SET_ERRORS,
  BOX_MY_TIMESHEET_SET_SELECTED_FILTER,
  BOX_MY_TIMESHEET_SORT_REQUEST,
  BOX_MY_TIMESHEET_UNSET_HASH,
  BOX_MY_TIMESHEET_UPDATE_CUSTOM_FILTERS,
  BOX_MY_TIMESHEET_UPDATE_FILTER_FORM,
} from './actions';
import {
  createTimesheetHash,
  getApiPayload,
  getPredefinedFilters,
} from './helpers';
import {
  getCurrentPage,
  getFiltersForm,
  getIsCurrentlyCreated,
  getPageSize,
} from './selectors';
import { updateTimesheet } from 'lib/Api/helpers';

export const fetchMyTimesheetRequest = function* (
  payload: FetchMyTimesheetPayload = {}
): SagaIterator {
  const filtersForm: MyTimesheetFilters = yield select(getFiltersForm);
  const currentPage: number = yield select(getCurrentPage);
  const currentPageSize: number = yield select(getPageSize);

  const apiPayload: EmployeeDashboardGetMyTimesheetPayload = getApiPayload({
    filtersForm,
    page: payload.page || currentPage,
    pageSize: payload.pageSize || currentPageSize,
  });

  const response: EmployeeDashboardGetMyTimesheetResponse = yield call(
    processApiRequest,
    Api.EmployeeDashboard.getMyTimesheet,
    apiPayload
  );

  yield put(BOX_MY_TIMESHEET_FETCH_MY_TIMESHEET_SUCCESS(response));
  yield put(BOX_MY_TIMESHEET_UPDATE_CUSTOM_FILTERS());
};

const fetchPageData = function* (): SagaIterator {
  yield put(BOX_FETCH_PAGE_DATA_REQUEST(FETCH_PAGE_NAME));
  try {
    yield call(fetchMyTimesheetRequest);
    yield put(BOX_FETCH_PAGE_DATA_SUCCESS(FETCH_PAGE_NAME));
  } catch (error) {
    yield put(BOX_FETCH_PAGE_DATA_FAILURE(FETCH_PAGE_NAME));
  }
};

const changePage = function* ({
  payload: page,
}: SagaAction<ChangePagePayload>): SagaIterator {
  yield put(BOX_IS_LOADING_ON(LOADING_PAGE_NAME));
  yield put(BOX_MY_TIMESHEET_SET_ERRORS([]));

  try {
    yield call(fetchMyTimesheetRequest, {
      page,
    });
  } catch (error) {
    yield put(BOX_MY_TIMESHEET_SET_ERRORS(formatError(error)));
  }

  yield put(BOX_IS_LOADING_OFF(LOADING_PAGE_NAME));
};

const changePageSize = function* ({
  payload: pageSize,
}: SagaAction<ChangePageSizePayload>): SagaIterator {
  yield put(BOX_IS_LOADING_ON(LOADING_PAGE_NAME));
  yield put(BOX_MY_TIMESHEET_SET_ERRORS([]));

  try {
    yield call(fetchMyTimesheetRequest, {
      page: 1,
      pageSize,
    });
  } catch (error) {
    yield put(BOX_MY_TIMESHEET_SET_ERRORS(formatError(error)));
  }

  yield put(BOX_IS_LOADING_OFF(LOADING_PAGE_NAME));
};

const fetchPredefinedFilter = function* ({
  payload: predefinedFilter,
}: SagaAction<MyTimesheetPredefinedFilter>): SagaIterator {
  yield put(BOX_IS_LOADING_ON(LOADING_PAGE_NAME));
  yield put(BOX_MY_TIMESHEET_SET_ERRORS([]));

  const newFiltersForm: MyTimesheetFilters =
    getPredefinedFilters(predefinedFilter);
  yield put(BOX_MY_TIMESHEET_UPDATE_FILTER_FORM(newFiltersForm));

  try {
    yield call(fetchMyTimesheetRequest, {
      page: 1,
    });
    yield put(BOX_MY_TIMESHEET_SET_SELECTED_FILTER(predefinedFilter));
  } catch (error) {
    yield put(BOX_MY_TIMESHEET_SET_ERRORS(formatError(error)));
    yield put(BOX_MY_TIMESHEET_CLEAR_FILTER_FORM());
  }

  yield put(BOX_IS_LOADING_OFF(LOADING_PAGE_NAME));
};

const fetchCustomFilter = function* (): SagaIterator {
  yield put(BOX_IS_LOADING_ON(LOADING_PAGE_NAME));
  yield put(BOX_MY_TIMESHEET_SET_ERRORS([]));

  try {
    yield call(fetchMyTimesheetRequest, {
      page: 1,
    });
    yield put(BOX_MY_TIMESHEET_SET_SELECTED_FILTER('custom'));
  } catch (error) {
    yield put(BOX_MY_TIMESHEET_SET_ERRORS(formatError(error)));
    yield put(BOX_MY_TIMESHEET_CLEAR_FILTER_FORM());
  }

  yield put(BOX_MY_TIMESHEET_CUSTOM_FILTERS_MODAL_CLOSE());
  yield put(BOX_IS_LOADING_OFF(LOADING_PAGE_NAME));
};

const sort = function* ({ payload }: SagaAction<SortPayload>): SagaIterator {
  yield put(BOX_IS_LOADING_ON(LOADING_PAGE_NAME));
  yield put(BOX_MY_TIMESHEET_SET_ERRORS([]));

  const filtersForm: MyTimesheetFilters = yield select(getFiltersForm);

  yield put(
    BOX_MY_TIMESHEET_UPDATE_FILTER_FORM({
      ...filtersForm,
      sort: payload,
    })
  );

  try {
    yield call(fetchMyTimesheetRequest);
  } catch (error) {
    yield put(BOX_MY_TIMESHEET_SET_ERRORS(formatError(error)));
    yield put(BOX_MY_TIMESHEET_CLEAR_FILTER_FORM());
  }

  yield put(BOX_IS_LOADING_OFF(LOADING_PAGE_NAME));
};

export const updatePageData = function* (): SagaIterator {
  yield put(BOX_IS_LOADING_ON(LOADING_PAGE_NAME));
  yield put(BOX_MY_TIMESHEET_SET_ERRORS([]));

  try {
    yield call(fetchMyTimesheetRequest);
  } catch (error) {
    yield put(BOX_MY_TIMESHEET_SET_ERRORS(formatError(error)));
  }

  yield put(BOX_IS_LOADING_OFF(LOADING_PAGE_NAME));
};

const createSelfAssignedShift = function* ({
  payload,
}: SagaAction<CreateSelfAssignedShiftPayload>): SagaIterator {
  yield put(BOX_TIMESHEET_SHIFT_MODAL_GET_BY_ID(payload));

  const { success } = yield race({
    success: take(BOX_TIMESHEET_SHIFT_MODAL_CREATE_SHIFT_SUCCESS),
    failure: take(BOX_TIMESHEET_SHIFT_MODAL_CREATE_SHIFT_FAILURE),
  });

  if (success) {
    yield call(updatePageData);
  }

  yield put(BOX_MY_TIMESHEET_UNSET_HASH());
};

const showReloadNotification = function* (): SagaIterator {
  const notificationId = 'myTimesheetReload';

  const { undo } = yield call(showOutDatedNotification, {
    notificationId,
    message: outDatedDataMessage,
    undoText: 'Reload',
  });

  if (undo) {
    yield put(BOX_MY_TIMESHEET_FETCH_PAGE_DATA_REQUEST());
  }
};

const triggerEmployeeDashboardChangeTimesheet = function* ({
  payload: { timesheet, rostered_shift },
}: SagaActionFromCreator<
  typeof BOX_EMPLOYEE_DASHBOARD_TIMESHEET_CHANGED
>): SagaIterator {
  const isTimesheetFetched: ReturnType<typeof isPageFetchedSelector> =
    yield select(isPageFetchedSelector, FETCH_PAGE_NAME);

  // copied logic from Sockets TODO double-check why it was done
  if (rostered_shift) {
    timesheet.periods = rostered_shift.periods;
  }

  const hash = createTimesheetHash(updateTimesheet(timesheet));
  const current = yield select(getIsCurrentlyCreated);

  if (isTimesheetFetched && current !== hash) {
    yield call(showReloadNotification);
    yield put(BOX_MY_TIMESHEET_UNSET_HASH());
  }
};

const triggerEmployeeDashboardDeleteTimesheet = function* (): SagaIterator {
  const isTimesheetFetched: ReturnType<typeof isPageFetchedSelector> =
    yield select(isPageFetchedSelector, FETCH_PAGE_NAME);

  if (isTimesheetFetched) {
    yield call(showReloadNotification);
  }
};

export const watchMyTimesheet = function* () {
  yield takeLatest(BOX_MY_TIMESHEET_FETCH_PAGE_DATA_REQUEST, fetchPageData);
  yield takeLatest(
    BOX_MY_TIMESHEET_CREATE_SELF_ASSIGNED_SHIFT_REQUEST,
    createSelfAssignedShift
  );
  yield takeLatest(BOX_MY_TIMESHEET_CHANGE_PAGE_REQUEST, changePage);
  yield takeLatest(BOX_MY_TIMESHEET_CHANGE_PAGE_SIZE_REQUEST, changePageSize);
  yield takeLatest(
    BOX_MY_TIMESHEET_PREDEFINED_FILTER_REQUEST,
    fetchPredefinedFilter
  );
  yield takeLatest(BOX_MY_TIMESHEET_CUSTOM_FILTER_REQUEST, fetchCustomFilter);
  yield takeLatest(BOX_MY_TIMESHEET_SORT_REQUEST, sort);

  yield takeLatest(
    BOX_EMPLOYEE_DASHBOARD_TIMESHEET_CHANGED,
    triggerEmployeeDashboardChangeTimesheet
  );
  yield takeLatest(
    BOX_EMPLOYEE_DASHBOARD_TIMESHEET_DELETED,
    triggerEmployeeDashboardDeleteTimesheet
  );
};
