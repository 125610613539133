import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Tooltip } from 'extended-oxygen-elements';
import { IconButton } from 'extended-oxygen-elements';
import { BOX_TIMESHEETS_WEEKLY_TIMESHEET_APPROVE } from 'state/TimesheetsWeeklyPage';
import { CheckCircleOutlinedIcon } from 'element';
import { ActionButtonProps } from './types';

export const ApproveButton = ({
  closeActionsPopover,
  timesheetId,
}: ActionButtonProps) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(BOX_TIMESHEETS_WEEKLY_TIMESHEET_APPROVE.request({ timesheetId }));
    closeActionsPopover();
  };

  return (
    <Tooltip title="Approve timesheet">
      <Box component="span" color="success.main">
        <IconButton color="inherit" onClick={handleClick} data-testid={'hover-action-approve'}>
          <CheckCircleOutlinedIcon />
        </IconButton>
      </Box>
    </Tooltip>
  );
};
