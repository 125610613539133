import * as React from 'react';
import { connect } from 'react-redux';
import ErrorBox from 'element/ErrorBox';
import { StoreState } from 'state/types';
import {
  BOX_EDIT_TEMPLATE_CLOSE_DELETE_TEMPLATE_MODAL,
  BOX_EDIT_TEMPLATE_CONFIRM_DELETE_TEMPLATE_MODAL,
  BOX_EDIT_TEMPLATE_DELETE_CLEAR_ERRORS,
} from 'state/Roster/EditTemplate/actions';
import { getDeleteTemplateModal } from 'state/Roster/EditTemplate/selectors';
import { ConfirmModalProps } from 'state/Roster/EditTemplate/types';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';
import { PageDialog, PageDialogCancel, PageDialogSubmit } from 'element';
import { Typography } from 'oxygen-elements';

type OwnProps = {};

type DispatchProps = {
  closeModal: () => void;
  confirmDeletion: () => void;
  clearErrors: () => void;
};

type StateProps = {
  modal: ConfirmModalProps;
};

type Props = OwnProps & DispatchProps & StateProps;

export function DeleteTemplateModal(props: Props) {
  const {
    modal: { isOpened, isUpdating, errors },
    closeModal,
    confirmDeletion,
    clearErrors,
  } = props;

  return (
    <PageDialog
      maxWidth={'xs'}
      open={isOpened}
      id="delete-template"
      onClose={closeModal}
    >
      <DialogTitle>Delete template</DialogTitle>
      <DialogContent>
        {errors.length > 0 && (
          <ErrorBox errors={errors} clearErrors={clearErrors} />
        )}
        <Typography variant={'body1'}>
          Are you sure you want to delete this template?
        </Typography>
      </DialogContent>
      <DialogActions>
        <PageDialogCancel onClick={closeModal}>Cancel</PageDialogCancel>
        <PageDialogSubmit
          id="confirm-button"
          onClick={confirmDeletion}
          loading={isUpdating}
          disabled={isUpdating}
        >
          Delete
        </PageDialogSubmit>
      </DialogActions>
    </PageDialog>
  );
}

const mapToStateProps = (state: StoreState): StateProps => ({
  modal: getDeleteTemplateModal(state),
});

const mapToDispatchProps: DispatchProps = {
  closeModal: BOX_EDIT_TEMPLATE_CLOSE_DELETE_TEMPLATE_MODAL,
  confirmDeletion: BOX_EDIT_TEMPLATE_CONFIRM_DELETE_TEMPLATE_MODAL,
  clearErrors: BOX_EDIT_TEMPLATE_DELETE_CLEAR_ERRORS,
};

export default connect(
  mapToStateProps,
  mapToDispatchProps
)(DeleteTemplateModal);
