import { createSelector } from 'reselect';
import { intersection } from 'lodash';
import { StoreState } from '../types';

export const getAllIds = (state: StoreState) =>
  state.bulk.allIds;
export const getPageIds = (state: StoreState) =>
  state.bulk.pageIds;
export const getIsAllSelected = (state: StoreState) =>
  state.bulk.isAllSelected;
const getSelectedIds = (state: StoreState) =>
  state.bulk.selectedIds;

export const pageSelectedIdsSelector = createSelector(
  getPageIds,
  getSelectedIds,
  (pageIds: string[], selectedIds: string[]): string[] =>
    intersection(pageIds, selectedIds)
);

export const selectedIdsSelector = createSelector(
  getIsAllSelected,
  getAllIds,
  pageSelectedIdsSelector,
  (
    isAllSelected: boolean,
    allIds: string[],
    pageSelectedIds: string[]
  ): string[] => (isAllSelected ? allIds : pageSelectedIds)
);
