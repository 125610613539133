import React from 'react';
import LoaderHolder from './LoaderHolder';
import { LoaderHolderProps } from '../type';
import './IconStyle.scss';

function IconStyle(props: LoaderHolderProps) {
  return (
    <LoaderHolder
      className="elmo-loader-icon"
      viewBoxHeight={60}
      viewBoxWidth={200}
      width="50px"
      {...props}
    >
      <rect x="0" y="10" rx="4" ry="4" width="100" height="50" />
    </LoaderHolder>
  );
}

export default IconStyle;
