import {
  FetchWrapper,
  Layout,
  SocketRosterConnection,
  SocketRosterWeekConnection,
} from 'element';
import { useRosterQuery, useSelectorWithProps, useUpdateEffect } from 'hooks';
import { SERVER_DAY_FORMAT } from 'lib/config';
import { getDayRange } from 'lib/helpers';
import { moment } from 'lib/momentWithRange';
import { Alert } from 'extended-oxygen-elements';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSite } from 'state/AccountTree';
import { ROSTERS } from 'state/FetchPageData/constants';
import {
  activeAreasForCurrentSiteId,
  BOX_ROSTER_GET_INITIAL_PAGE_DATA_REQUEST,
  BOX_ROSTER_GET_PAGE_DATA_REQUEST,
} from 'state/Roster/Roster';
import { Dictionary } from 'ts-essentials';
import { Route } from 'type';
import { NotEnoughDataMsg } from '../../constants';
import { DayHeader } from './components';
import View from './view';

type WithRoutesProps = {
  routes: Dictionary<Route>;
};

export default RosterDayView;

function RosterDayView(props: WithRoutesProps) {
  return (
    <>
      <Layout.Header>
        <DayHeader />
      </Layout.Header>

      <Layout.Content>
        <UpdatePageData />

        <Fetch {...props} />
      </Layout.Content>
    </>
  );
}

function useDayPayload() {
  const {
    query: { site_id, day },
  } = useRosterQuery();
  const { timezone_id } = useSelectorWithProps(getSite, site_id);
  const dayMoment = moment(day, SERVER_DAY_FORMAT).tz(timezone_id, true);

  return {
    site_id,
    ...getDayRange(dayMoment),
  };
}

type DayPayload = ReturnType<typeof useDayPayload>;

function Fetch({ routes }: WithRoutesProps) {
  const dispatch = useDispatch();
  const payload = useDayPayload();

  return (
    <FetchWrapper
      fetchData={() =>
        dispatch(BOX_ROSTER_GET_INITIAL_PAGE_DATA_REQUEST(payload))
      }
      pageId={ROSTERS}
    >
      <Content {...payload} routes={routes} />
    </FetchWrapper>
  );
}

function UpdatePageData() {
  const dispatch = useDispatch();
  const { site_id, from, to } = useDayPayload();

  useUpdateEffect(() => {
    dispatch(
      BOX_ROSTER_GET_PAGE_DATA_REQUEST({
        site_id,
        from,
        to,
      })
    );
  }, [
    dispatch,
    site_id,
    from.format(SERVER_DAY_FORMAT),
    to.format(SERVER_DAY_FORMAT),
  ]);

  return null;
}

function Content({ routes, ...payload }: DayPayload & WithRoutesProps) {
  const activeAreasBySiteId = useSelector(activeAreasForCurrentSiteId);

  if (!activeAreasBySiteId.length) {
    return (
      <Alert color="warning" severity="warning">
        {NotEnoughDataMsg}
      </Alert>
    );
  }

  return (
    <>
      <SocketRosterConnection {...payload} />
      <SocketRosterWeekConnection {...payload} />
      <View routes={routes} />
    </>
  );
}
