import { StringMap } from 'type';

export const getGroupColumnParams = (
  type: string,
  extraGroupBY: string,
  fields?: any[]
): { name: string; title: string } => {
  let l = '';
  const long = extraGroupBY + '_name';
  if (fields) {
    fields.forEach((field: any) => {
      if (field.name === extraGroupBY || field.name === long) {
        l = field.label;
      }
    });
  }

  if (type === 'coverage') {
    if (extraGroupBY === 'date') {
      return {
        name: 'date',
        title: 'Date'
      };
    }
    if (extraGroupBY === 'role') {
      return {
        name: 'role',
        title: l !== '' ? l : 'Role name'
      };
    }
  }

  const labels: StringMap<{ name: string; title: string }> = {
    date: {
      name: 'start',
      title: 'Date start'
    },
    user: {
      name: 'user_name',
      title: 'Username'
    },
    site: {
      name: 'site_name',
      title: 'Site name'
    },
    role: {
      name: 'role_name',
      title: 'Role name'
    },
    area: {
      name: 'area_name',
      title: 'Area name'
    }
  };

  if (l !== '') {
    labels[extraGroupBY].title = l;
  }

  return labels[extraGroupBY];
};
