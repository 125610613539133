import React from 'react';
import { useDispatch } from 'react-redux';
import { createStyles, makeStyles } from '@mui/styles';
import { alpha, Button } from 'extended-oxygen-elements';
import { BOX_COVID_TRACING_MODE_ENABLE } from 'state/Roster/CovidTracing/actions';
import { CoronavirusOutlinedIcon } from 'element';
import RosterCopyPast from '../RosterCopyPast';
import { SwitcherAndFilters } from './partials';
import { HelpIcon } from '../../../../components/HelpIcon';
import { BOX_ROSTER_FILTERS_RESET_CUSTOM_FILTERS } from '../../../../../../state/Roster/RosterFilters';
import { BOX_SHIFT_TIME_RANGE_INPUT_CLOSED } from '../../../../../../state/Roster/RangeInput/ShiftTimeRangeInput';

const useStyles = makeStyles((theme) => {
  const bottomSpacing = theme.spacing(3);

  const customBreakpoints = {
    ...theme.breakpoints.values,
    xl: 1075,
    xxl: theme.breakpoints.values.xl,
    xxxl: 1348,
  };

  return createStyles({
    root: {
      [theme.breakpoints.up(customBreakpoints.xxl)]: {
        display: 'flex',
      },
    },
    switcherAndFilters: {
      marginRight: 'auto',
    },
    covidAndCopy: {
      display: 'flex',
    },
    covidWrapper: {
      display: 'flex',
      marginRight: 'auto',
      [theme.breakpoints.up(customBreakpoints.xxl)]: {
        marginRight: theme.spacing(1),
      },
      marginBottom: bottomSpacing,
    },
    covid: {
      paddingLeft: '10px',
      paddingRight: '10px',
      '&:focus': {
        outline: 'none',
      },
      whiteSpace: 'nowrap',
    },
    helpIcon: {
      display: 'flex',
      alignItems: 'center',
    },
    helpIconBtn: {
      '&:focus': {
        outline: 'none',
      },
    },
    copyPaste: {
      marginBottom: bottomSpacing,
    },
    copyPasteBtn: {
      '& .elmo-btn__label': {
        [theme.breakpoints.up(customBreakpoints.xxl)]: {
          display: 'none !important',
        },
        [theme.breakpoints.up(customBreakpoints.xxxl)]: {
          display: 'inline-flex !important',
        },
      },
    },
  });
});

export const RosterWeekViewControls = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleCovidTracingClick = () => {
    dispatch(BOX_COVID_TRACING_MODE_ENABLE());
    dispatch(BOX_ROSTER_FILTERS_RESET_CUSTOM_FILTERS());
    dispatch(BOX_SHIFT_TIME_RANGE_INPUT_CLOSED());
  };

  return (
    <div className={classes.root}>
      <SwitcherAndFilters className={classes.switcherAndFilters} />

      <div className={classes.covidAndCopy}>
        <div className={classes.covidWrapper}>
          <Button
            data-test-id={'covid-tracing-button'}
            className={classes.covid}
            color="grey"
            onClick={handleCovidTracingClick}
            startIcon={<CoronavirusOutlinedIcon />}
          >
            COVID-19 tracing
          </Button>

          <div
            className={classes.helpIcon}
            data-test-id={'covid-help-icon-wrapper'}
          >
            <HelpIcon className={classes.helpIconBtn} />
          </div>
        </div>

        <RosterCopyPast
          classes={{
            root: classes.copyPaste,
            button: classes.copyPasteBtn,
          }}
        />
      </div>
    </div>
  );
};
