import moment, { Moment } from 'moment';

export const getClickBounds = (event: {
  currentTarget: HTMLDivElement;
  clientX: number;
  clientY: number;
}): { offsetY: number; offsetX: number; width: number } => {
  const bounds = event.currentTarget.getBoundingClientRect();
  const offsetX = event.clientX - bounds.left;
  const offsetY = event.clientY - bounds.top;
  const width = bounds.right - bounds.left;

  return {
    offsetY,
    offsetX,
    width
  };
};

export const createClonedTile = (
  event: { currentTarget: HTMLDivElement },
  tileBounds: { width: number }
): HTMLDivElement => {
  const cloneNode = event.currentTarget.cloneNode(true) as HTMLDivElement;
  cloneNode.classList.add('draggable-placeholder');
  cloneNode.style.width = `${tileBounds.width}px`;

  return cloneNode;
};

export const createGhostPlaceholder = (): HTMLDivElement => {
  const createdNode = document.createElement<'div'>('div');
  createdNode.style.opacity = '0';
  createdNode.style.pointerEvents = 'none';
  createdNode.style.width = '1px';
  createdNode.style.height = '1px';

  return createdNode;
};

export const getMidnightShiftNegativeOffset = (payload: {
  day: Moment;
  rosteredShiftStart: Moment;
  oneMinuteInPx: number;
}): number => {
  const isMidnightBefore = !payload.day.isSame(
    payload.rosteredShiftStart,
    'day'
  );

  if (isMidnightBefore) {
    const negativeOffsetDuration = moment.duration(
      payload.day.diff(payload.rosteredShiftStart)
    );
    const negativeOffsetMinutes = negativeOffsetDuration.asMinutes();
    return negativeOffsetMinutes * payload.oneMinuteInPx;
  }

  return 0;
};

export const removeNode = (domNodeToRemove: HTMLElement) => {
  if (domNodeToRemove.parentNode) {
    domNodeToRemove.parentNode.removeChild(domNodeToRemove);
  }
}
