type ValueType<C> = C extends (u: Partial<infer V>) => void ? V : never;

export default useSetFormField;

export function useSetFormField<
  ChangeHandler extends (u: Partial<any>) => void
>(onChange: ChangeHandler) {
  type Value = ValueType<ChangeHandler>;

  const setFormField = <K extends keyof Value>(
    fieldName: K,
    fieldValue: Value[K]
  ) => {
    onChange({ [fieldName]: fieldValue });
  };

  const makeSetFormField =
    <K extends keyof Value>(fieldName: K) =>
    (fieldValue: Value[K]) => {
      setFormField(fieldName, fieldValue);
    };

  return { setFormField, makeSetFormField };
}
