import React from 'react';
import { Box } from 'extended-oxygen-elements';

export type HelperTextWrapperProps = {
  children: React.ReactNode;
};

export default HelperTextWrapper;

export function HelperTextWrapper({ children }: HelperTextWrapperProps) {
  return (
    <Box maxWidth={400}>{children}</Box>
  );
}
