import { Dictionary } from 'ts-essentials';
import { createSelector } from 'reselect';
import { each, mapValues, pickBy, union } from 'lodash';
import { StoreState } from 'state/types';
import { getAreas } from 'state/AccountTree';
import { getSiteId } from 'state/RosteredShifts';
import { shiftRoleFilter } from '../helpers';
import { timesheetsFilteredSelector } from './timesheets';
import { rosteredShiftsFilteredSelector } from './rosteredShifts';
import { filtersSelector } from './common';

/**
 * Role can be unassigned from Area and Area can be unassigned from Site
 * In this case we will receive shifts/timesheets with invalid area/role pairs
 * but they still need to be shown on the week view
 *
 * So in this selector we collect all the area/role pairs from the shifts/timesheets
 * to be merged with actual account tree
 *
 * */
type ShiftAreaRoleIds = Dictionary<{ id: string; role_ids: string[] }>;
const shiftsAreaRoleIdsSelector = createSelector(
  rosteredShiftsFilteredSelector,
  timesheetsFilteredSelector,
  (rosteredShifts, timesheets) =>
    [...rosteredShifts, ...timesheets].reduce<ShiftAreaRoleIds>(
      (accumulator, { area_id, role_id }) => {
        const role_ids = accumulator[area_id]?.role_ids || [];

        if (!role_ids.includes(role_id)) {
          role_ids.push(role_id);
        }

        accumulator[area_id] = {
          id: area_id,
          role_ids,
        };

        return accumulator;
      },
      {}
    )
);

/**
 * areas of selected site
 * */
export const siteAreasSelector = createSelector(
  getAreas,
  getSiteId,
  (areas, siteId) => pickBy(areas, (area) => area.site_id === siteId)
);

/**
 * area/role from shifts/timesheets merged with actual account tree area/role ids
 * */
const mergedAreaRoleIdsSelector = createSelector(
  shiftsAreaRoleIdsSelector,
  siteAreasSelector,
  (shiftsAreaIds, actualAreas) => {
    const mergedAreaIds = mapValues(actualAreas, ({ id, role_ids }) => ({
      id,
      role_ids: [...role_ids],
    }));

    each(shiftsAreaIds, (shiftArea, areaId) => {
      const actualRoleIds = actualAreas[areaId]?.role_ids || [];

      mergedAreaIds[areaId] = {
        id: areaId,
        role_ids: union(actualRoleIds, shiftArea.role_ids),
      };
    });

    return mergedAreaIds;
  }
);

const flatAreasSelector = createSelector(mergedAreaRoleIdsSelector, (areas) =>
  Object.values(areas).flatMap((area) =>
    area.role_ids.map((role_id) => ({ area_id: area.id, role_id }))
  )
);

export const filteredAreasSelector = createSelector<
  StoreState,
  ReturnType<typeof filtersSelector>,
  ReturnType<typeof flatAreasSelector>,
  ReturnType<typeof getSiteId>,
  { area_id: string; role_id: string }[]
>(filtersSelector, flatAreasSelector, getSiteId, shiftRoleFilter);
