import React from 'react';
import { ListItemText, Typography } from 'oxygen-elements';
import { getChangesNumberText, getFormattedDate } from './helpers';
import { useModalStyles } from './hooks/publishModalStyles';
import { useSelector } from 'react-redux';
import { getLangPreferences } from 'state/Account';
import { RosterPublishWeekItem } from '../../../../state/Roster/Roster';

type Props = {
  data: RosterPublishWeekItem;
  isOpened: boolean;
  selectedAreasNumber: number;
  showSelectedNumber: boolean;
};

export default function PublishWeekTitleItem({
  data,
  isOpened,
  selectedAreasNumber,
  showSelectedNumber,
}: Props) {
  const classes = useModalStyles();
  const langPreferences = useSelector(getLangPreferences);
  const key = selectedAreasNumber > 1 ? 'plural' : 'singular';
  const areaTitle = langPreferences.area[key];
  const from = getFormattedDate(data.start);
  const to = getFormattedDate(data.end);
  const changesNumber = getChangesNumberText(data.unpublished_shifts_count);
  return (
    <>
      <ListItemText
        data-test-id={'publish-list-week-label'}
        className={classes.weekTitle}
        disableTypography
      >
        <Typography
          color={isOpened ? 'primary' : 'default'}
          component="p"
          fontWeight="medium"
        >
          Share {changesNumber} for {from} - {to}
        </Typography>
        {showSelectedNumber && !isOpened && (
          <span className={classes.areasNumber}>
            {selectedAreasNumber} {areaTitle} selected
          </span>
        )}
      </ListItemText>
    </>
  );
}
