import React, { Component } from 'react';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import {
  activeAreasBySiteIdSelector,
  getAreas,
  getRoles,
  getSites,
} from 'state/AccountTree';
import { getLangPreferences } from 'state/Account';
import {
  AccountTreeArea,
  AccountTreeRole,
  AccountTreeSite,
  LanguagePreferences,
  ServerUserFields,
  Timesheet,
  UserFields,
} from 'type/models';
import { StringMap } from 'type';
import {
  getAreaPositionLabel,
  getOptionsForCurrentUser,
  getOptionsForUnassignedRoster,
  getPreferenceLabel,
  getSelectedAreaRole,
} from 'lib/helpers';
import { Select, Text } from 'elmo-elements';
import { getUserListResponse, userListSelector } from 'state/UsersCollection';
import { getSiteId } from 'state/TimesheetsCollection';

type Props = {
  langPreferences: LanguagePreferences;
  areas: StringMap<AccountTreeArea>;
  roles: StringMap<AccountTreeRole>;
  sites: StringMap<AccountTreeSite>;
  timesheet: Timesheet;
  onChange: (e: { label: string; value: string }) => void;
  users: StringMap<ServerUserFields>;
  userList: StringMap<UserFields>;
  areasBySideId: any;
  isEditable: boolean;
  isEditing: boolean;
  isDisabled: boolean;
  siteId: string;
  onClick?: () => void;
};
type State = {
  isEditMode: boolean
};

export class ShiftLocation extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isEditMode: false
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.isEditing !== this.props.isEditing) {
      this.setState({
        isEditMode: this.props.isEditing
      })
    }
  }

  render() {
    const {isEditing, isEditable, isDisabled, onClick} = this.props;
    return <div
      className={!isEditing ? 'isEditable' : ''}
      onClick={() => {
        if (isEditable && !isDisabled) {
          this.setState({
            isEditMode: true
          });
          if (typeof onClick === 'function') {
            onClick();
          }
        }
      }}
    >
      <Text
        size={'xs'}
        color={'gray'}
        id={'location-label'}
      >
        {this.positionAreLocation}
      </Text>
      {isEditing ? this.renderSelect() : <>
        {this.state.isEditMode ? this.renderSelect() : this.renderLocation()}
      </>}
    </div>;
  }

  renderLocation = () => {
    return <div data-testid="site-area-role">
        {this.getLocation()}
      </div>;
  };

  renderSelect = () => {
    const {langPreferences, timesheet, onChange} = this.props;
    return <Select
      id={'area-role-dropdown'}
      name={'area_role'}
      options={this.getAreaRoleOptions()}
      defaultValue={getSelectedAreaRole(this.getAreaRoleOptions(), timesheet)}
      onChange={(e) => {
        onChange(e);
        this.setState({
          isEditMode: false
        });
      }}
      value={getSelectedAreaRole(this.getAreaRoleOptions(), timesheet)}
      ariaLabel={getAreaPositionLabel(langPreferences)}
      isDisabled={!timesheet.user_id}
    />;
  };

  get positionAreLocation() {
    const {langPreferences} = this.props;
    const position = getPreferenceLabel(langPreferences, 'role', 'singular', '', true);
    const area = getPreferenceLabel(langPreferences, 'area', 'singular', '', false);
    return `${position} - ${area}`;
  }

  getLocation = () => {
    const {roles, areas, timesheet} = this.props;
    let response = ' - ';
    if (timesheet.role_id && roles[timesheet.role_id]) {
      response = roles[timesheet.role_id].name;
    }
    if (timesheet.area_id && areas[timesheet.area_id]) {
      response += ` - ${areas[timesheet.area_id].name}`;
    }
    return response;
  };

  getAreaRoleOptions = () => {
    const {areas, roles, users, areasBySideId, siteId} = this.props;
    const {
      timesheet: {user_id}
    } = this.props;
    const areasBySite = areasBySideId[siteId] || [];
    return !!user_id
      ? getOptionsForCurrentUser(areasBySite, areas, roles, users[user_id])
      : getOptionsForUnassignedRoster(areasBySite, roles);
  };

}

const mapStateToProps = (state: StoreState) => ({
  langPreferences: getLangPreferences(state),
  areas: getAreas(state),
  roles: getRoles(state),
  sites: getSites(state),
  users: getUserListResponse(state),
  userList: userListSelector(state),
  areasBySideId: activeAreasBySiteIdSelector(state),
  siteId: getSiteId(state)
});

export default connect(mapStateToProps)(
  ShiftLocation
);
