import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { StoreState } from '../../../../state/types';
import { HeaderButtonProp } from 'element/Header';
import { LayoutContextProps, withLayoutAware } from 'elmo-elements';
import { SearchIcon } from 'element';
import { Search } from '../../../../element/Search';

export type EmployeeDashboardSearchPayload = {
  searchButton: HeaderButtonProp;
  isSearchOpen: boolean;
  employeeSearch: ReactNode;
};

type OwnProps = {
  children: (payload: EmployeeDashboardSearchPayload) => ReactNode;
};

type StateProps = {
  searchQuery: string;
};

type DispatchProps = {
  updateQuery: (query: string) => void;
};

type Props = OwnProps & StateProps & DispatchProps & LayoutContextProps;

const WithEmployeeDashboardSearchComponent = ({
  children,
  isSearchOpen,
  searchQuery,
  updateQuery,
  toggleSearchOpen
}: Props) => {
  const searchProps = {
    searchQuery,
    updateQuery,
    isSearchOpen,
    toggleSearchOpen
  };

  const payload: EmployeeDashboardSearchPayload = {
    searchButton: {
      icon: <SearchIcon />,
      label: 'Search',
      onClick: toggleSearchOpen
    },
    employeeSearch: <Search {...searchProps} />,
    isSearchOpen
  };

  return <>{children(payload)}</>;
};

const mapStateToProps = (state: StoreState): StateProps => ({
  searchQuery: '' // TODO
});

const mapDispatchToProps: DispatchProps = {
  updateQuery: () => ({ type: '' }) // TODO
};

export const WithEmployeeDashboardSearch = withLayoutAware(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WithEmployeeDashboardSearchComponent)
);
