import React, { Component, MouseEvent } from 'react';
import { getClass } from '../_lib/helper';
import { ChevronRightIcon } from '../icons';
import { ButtonCardProps } from './type';
import './ButtonCard.scss';

class ButtonCard extends Component<ButtonCardProps> {
  render(): React.ReactNode {
    const {
      id,
      icon,
      title,
      description,
      className,
      isDisabled,
      href,
      tabIndex,
    } = this.props;

    return (
      <a
        id={id}
        className={getClass('elmo-buttoncard', [
          className,
          {
            'is-disabled': isDisabled,
          },
        ])}
        {...(href ? { href: href } : {})}
        onClick={this.onClick}
        title={title}
        data-testid={`elmo-buttoncard-${id || 'default'}`}
        tabIndex={tabIndex || 0}
      >
        {icon && <div className="elmo-buttoncard__icon">{icon}</div>}
        <div className="elmo-buttoncard__content">
          <div className="elmo-buttoncard__title">{title}</div>
          {description && (
            <div className="elmo-buttoncard__description">{description}</div>
          )}
        </div>
        <div className="elmo-buttoncard__arrow">
          <ChevronRightIcon />
        </div>
      </a>
    );
  }

  private onClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (!this.props.isDisabled && this.props.onClick) {
      event.preventDefault();
      this.props.onClick(event);
    }
  };
}

export default ButtonCard;
