import { Box } from 'extended-oxygen-elements';
import React from 'react';

export type SpacingProps = { children?: React.ReactNode };

export default Spacing;

export function Spacing({ children }: SpacingProps) {
  return (
    <Box maxWidth={520} py={3}>
      {children}
    </Box>
  );
}
