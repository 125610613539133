import Api from 'lib/Api';
import _ from 'lodash';
import { SagaIterator } from 'redux-saga';
import { put, select, takeLatest } from 'redux-saga/effects';
import { formatError } from 'state/helpers';
import { apiCall } from 'state/ProcessApiRequest';
import { ApiReturnType, SagaActionFromCreator } from 'type';
import * as actions from '../actions';
import { getShiftOvertimeDayRule, getShiftOvertimeRules } from '../selectors';

export const updateWeekendLoadingsToggle = function* ({
  payload: { isEnabled },
}: SagaActionFromCreator<
  typeof actions.BOX_ACCOUNT_PAY_CONDITIONS_WEEKEND_LOADINGS_TOGGLE.request
>): SagaIterator {
  try {
    const response: ApiReturnType<typeof Api.Account.update> = yield apiCall(
      Api.Account.update,
      {
        payment_settings: {
          is_weekend_loadings_enabled: isEnabled,
        },
      }
    );

    yield put(
      actions.BOX_ACCOUNT_PAY_CONDITIONS_WEEKEND_LOADINGS_TOGGLE.success(
        response
      )
    );
  } catch (error) {
    yield put(
      actions.BOX_ACCOUNT_PAY_CONDITIONS_WEEKEND_LOADINGS_TOGGLE.failure(
        formatError(error)
      )
    );
  }
};

const overtimeRuleToPayload = ({
  id,
  pay_element_id,
  from_minutes,
  to_minutes,
}: {
  id: string;
  pay_element_id: string;
  from_minutes: number;
  to_minutes: number;
}) => ({ id, pay_element_id, from_minutes, to_minutes } as const);

export const createPayCondition = function* ({
  payload: { day, ...createdPayCondition },
}: SagaActionFromCreator<
  typeof actions.BOX_ACCOUNT_PAY_CONDITIONS_RULE_CREATE.request
>): SagaIterator {
  try {
    const shiftOvertimeDayRule: ReturnType<typeof getShiftOvertimeDayRule> =
      yield select(getShiftOvertimeDayRule, day);

    const response: ApiReturnType<typeof Api.Account.update> = yield apiCall(
      Api.Account.update,
      {
        payment_settings: {
          shift_overtime_rules: {
            [day]: [
              ...shiftOvertimeDayRule.map(overtimeRuleToPayload),
              createdPayCondition,
            ],
          },
        },
      }
    );
    yield put(actions.BOX_ACCOUNT_PAY_CONDITIONS_RULE_CREATE.success(response));
  } catch (error) {
    yield put(
      actions.BOX_ACCOUNT_PAY_CONDITIONS_RULE_CREATE.failure(formatError(error))
    );
  }
};

export const deletePayCondition = function* ({
  payload: { id, day },
}: SagaActionFromCreator<
  typeof actions.BOX_ACCOUNT_PAY_CONDITIONS_RULE_DELETE.request
>): SagaIterator {
  try {
    const shiftOvertimeDayRule: ReturnType<typeof getShiftOvertimeDayRule> =
      yield select(getShiftOvertimeDayRule, day);

    const response: ApiReturnType<typeof Api.Account.update> = yield apiCall(
      Api.Account.update,
      {
        payment_settings: {
          shift_overtime_rules: {
            [day]: shiftOvertimeDayRule
              .filter((shiftOvertimeRule) => shiftOvertimeRule.id !== id)
              .map(overtimeRuleToPayload),
          },
        },
      }
    );

    yield put(actions.BOX_ACCOUNT_PAY_CONDITIONS_RULE_DELETE.success(response));
  } catch (error) {
    yield put(
      actions.BOX_ACCOUNT_PAY_CONDITIONS_RULE_DELETE.failure(formatError(error))
    );
  }
};

export const getUpdatedConditionsConfig = (
  shiftOvertimeDayRules: ReturnType<typeof getShiftOvertimeRules>,
  updatedPayCondition: any
) => {
  return _.mapValues(shiftOvertimeDayRules, (dayRules) =>
    dayRules
      .map((dayRule) => {
        if (dayRule.id === updatedPayCondition.id) {
          dayRule = updatedPayCondition;
        }
        return dayRule;
      })
      .map(overtimeRuleToPayload)
  );
};

export const updatePayCondition = function* ({
  payload: { day, ...updatedPayCondition },
}: SagaActionFromCreator<
  typeof actions.BOX_ACCOUNT_PAY_CONDITIONS_RULE_UPDATE.request
>): SagaIterator {
  try {
    const shiftOvertimeDayRules: ReturnType<typeof getShiftOvertimeRules> =
      yield select(getShiftOvertimeRules);

    if (day === 'everyday') {
      const shiftOvertimeDayRulesWithUpdated = getUpdatedConditionsConfig(
        shiftOvertimeDayRules,
        updatedPayCondition
      );
      const response: ApiReturnType<typeof Api.Account.update> = yield apiCall(
        Api.Account.update,
        {
          payment_settings: {
            shift_overtime_rules: {
              ...shiftOvertimeDayRulesWithUpdated,
            },
          },
        }
      );
      yield put(
        actions.BOX_ACCOUNT_PAY_CONDITIONS_RULE_UPDATE.success(response)
      );
    } else {
      const shiftOvertimeDayRulesWithoutUpdated = _.mapValues(
        shiftOvertimeDayRules,
        (dayRules) =>
          dayRules
            .filter((dayRule) => dayRule.id !== updatedPayCondition.id)
            .map(overtimeRuleToPayload)
      );

      const withUpdatedCondition = {
        ...shiftOvertimeDayRulesWithoutUpdated,
        [day]: [
          ...shiftOvertimeDayRulesWithoutUpdated[day],
          updatedPayCondition,
        ],
      };

      const saturday = _.sortBy(
        [...withUpdatedCondition['saturday']],
        ({ from_minutes }) => from_minutes
      );

      const sunday = _.sortBy(
        [...withUpdatedCondition['sunday']],
        ({ from_minutes }) => from_minutes
      );

      const response: ApiReturnType<typeof Api.Account.update> = yield apiCall(
        Api.Account.update,
        {
          payment_settings: {
            shift_overtime_rules: {
              ...shiftOvertimeDayRulesWithoutUpdated,
              saturday,
              sunday,
            },
          },
        }
      );
      yield put(
        actions.BOX_ACCOUNT_PAY_CONDITIONS_RULE_UPDATE.success(response)
      );
    }
  } catch (error) {
    yield put(
      actions.BOX_ACCOUNT_PAY_CONDITIONS_RULE_UPDATE.failure(formatError(error))
    );
  }
};

export default function* (): SagaIterator {
  yield takeLatest(
    actions.BOX_ACCOUNT_PAY_CONDITIONS_WEEKEND_LOADINGS_TOGGLE.request,
    updateWeekendLoadingsToggle
  );
  yield takeLatest(
    actions.BOX_ACCOUNT_PAY_CONDITIONS_RULE_CREATE.request,
    createPayCondition
  );
  yield takeLatest(
    actions.BOX_ACCOUNT_PAY_CONDITIONS_RULE_DELETE.request,
    deletePayCondition
  );
  yield takeLatest(
    actions.BOX_ACCOUNT_PAY_CONDITIONS_RULE_UPDATE.request,
    updatePayCondition
  );
}
