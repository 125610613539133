import { useSelector } from 'react-redux';
import { Timesheet } from 'type';
import { getIsCovidModeEnabled } from 'state/Roster/CovidTracing';
import {
  useColorId,
  useCovidClassName,
  useElementId,
  useFormatTime,
  useHandleClickContextMenu,
  useIsBulkHighlighted,
  useIsBulkSelected,
} from './hooks';
import {
  useHandleClick,
  useHandleClickAddButton,
  useIcons,
  useIsLocationShown,
  useTimeColor,
} from './timesheet-hooks';

export const useTimesheetCard = (timesheet: Timesheet) => {
  const colorId = useColorId(timesheet);
  const icons = useIcons(timesheet);
  const isSelected = useIsBulkSelected(timesheet);
  const isCovidMode = useSelector(getIsCovidModeEnabled);
  const isHighlighted = useIsBulkHighlighted(timesheet);
  const from = useFormatTime(timesheet.start);
  const to = useFormatTime(timesheet.end);
  const className = useCovidClassName(timesheet);

  const onClickAddButton = useHandleClickAddButton(timesheet);
  const handleClickContextMenu = useHandleClickContextMenu();
  const onClick = useHandleClick(timesheet);
  const timeColor = useTimeColor(timesheet);
  const isLocationShown = useIsLocationShown(timesheet);
  const id = useElementId(timesheet);

  const viewProps = {
    from,
    to,
    colorId,
    coloredBg: true,
    isSelected,
    isHighlighted,
    onClickAddButton,
    onClick,
    timeColor,
    isLocationShown,
    shape: 'bottom' as const,
    className: `timesheet-card ${className}`,
    id,
  };

  return {
    viewProps,
    icons,
    onClickContextMenu:
      !!timesheet.end && !isCovidMode && handleClickContextMenu,
    breaks: timesheet.breaks,
  };
};
