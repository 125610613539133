import React, { Component, ReactNode } from 'react';
import { UserFields } from 'type';
import {
  CalendarTodayIcon,
  TableChartOutlinedIcon,
  HelpOutlineIcon,
} from 'element';
import {
  RosterUserLegend,
  RosterAreaLegend,
  RosterEventsLegend,
  RosterRow,
} from '../../../index';
import './RosterRowGroup.scss';
import { Text } from 'elmo-elements';
import { RosterFiltersByType } from 'state/Roster/RosterFilters';
import { getUserName } from '../../../../../../lib/helpers';
import { Avatar } from 'oxygen-elements';

type InfoType = {
  quantity: number;
  time: string;
  type: string;
};

type Duration = {
  quantity: number;
  time: string;
  icon: ReactNode;
  isVisible: boolean;
};

type AreaInfoType = {
  area_id: string;
  role_id: string;
  name: string;
  role: string;
  isDisabled?: boolean;
};

interface Props {
  data: {
    user: UserFields | null;
    area: AreaInfoType | null;
  };
  colour?: string;
  actions: () => void;
  toggleRow?: () => void;
  isCollapsed?: boolean;
  children: React.ReactNode;
  legendType: 'single-row' | 'multi-row';
  duration: InfoType[];
  type: 'area' | 'user' | 'unassigned' | 'events';
  selectedFilter: RosterFiltersByType;
  bulkSelect?: 'user' | 'area';
  isSelected?: boolean;
  rowIndex?: number;
  pageType: 'week' | 'day';
}

export default class RosterRowGroup extends Component<Props> {
  render() {
    const { children, rowIndex, isCollapsed } = this.props;
    return (
      <RosterRow
        isCollapsed={isCollapsed}
        isSelected={this.props.isSelected}
        rowIndex={rowIndex}
      >
        {{
          sticky: this.getLegend(),
          content: (
            <div className={`week-rosters-grid spec-cell-height`}>
              {children}
            </div>
          ),
        }}
      </RosterRow>
    );
  }

  getEventLegend = () => {
    const { actions, toggleRow, isCollapsed } = this.props;
    return (
      <RosterEventsLegend
        onClick={actions}
        toggleRow={toggleRow}
        isCollapsable={typeof isCollapsed !== 'undefined'}
        pageType={'week'}
      />
    );
  };

  getUserLegend = () => {
    const {
      legendType,
      actions,
      type,
      data: { user },
      pageType,
    } = this.props;
    const data = this.getData();
    const durations = this.appendIcon();
    return (
      <RosterUserLegend
        type={type === 'unassigned' ? 'always-single-row' : legendType}
        label={data.label}
        icon={data.icon}
        durations={durations}
        onClick={actions}
        isDisabled={user !== null && !user.is_active}
        bulkSelect={this.props.bulkSelect === 'user'}
        userId={user ? user.id : null}
        pageType={pageType}
      />
    );
  };

  getAreaLegend = () => {
    const {
      legendType,
      actions,
      colour,
      data: { area },
      pageType,
    } = this.props;
    const durations = this.appendIcon();
    const data = this.getData();

    const getLegendState = () => {
      return area !== null ? area.isDisabled : false;
    };

    return (
      <RosterAreaLegend
        type={legendType}
        label={data.label}
        icon={area ? area.name.replace(/ /i, '-') : ''}
        durations={durations}
        onClick={actions}
        colour={colour ? colour : ''}
        isDisabled={getLegendState()}
        bulkSelect={this.props.bulkSelect === 'area'}
        areaId={area && area.area_id ? area.area_id : ''}
        roleId={area && area.role_id ? area.role_id : ''}
        pageType={pageType}
      />
    );
  };

  appendIcon = () => {
    const { duration, selectedFilter, type } = this.props;
    const getVisibilityProp = (legendType: string) => {
      if (legendType === 'roster' && selectedFilter.roster) {
        return true;
      }
      return (
        legendType === 'timesheet' &&
        selectedFilter.timesheets &&
        type !== 'unassigned'
      );
    };
    const r: Duration[] = [];
    duration.forEach((d: InfoType) => {
      r.push({
        quantity: d.quantity,
        time: d.time,
        icon:
          d.type === 'roster' ? (
            <CalendarTodayIcon />
          ) : (
            <TableChartOutlinedIcon />
          ),
        isVisible: getVisibilityProp(d.type),
      });
    });
    return r;
  };

  getLegend = () => {
    const { type } = this.props;
    if (type === 'events') {
      return this.getEventLegend();
    }
    return type === 'user' || type === 'unassigned'
      ? this.getUserLegend()
      : this.getAreaLegend();
  };

  getData = () => {
    const { type } = this.props;
    if (type === 'unassigned') {
      return this.getUnassignedLegendData();
    }
    return type === 'area'
      ? this.getAreaLegendData()
      : this.getUserLegendData();
  };

  getUnassignedLegendData = () => {
    return {
      icon: (
        <Text color={'warning'}>
          <HelpOutlineIcon />
        </Text>
      ),
      label: 'Unassigned',
    };
  };

  getUserLegendData = () => {
    const {
      data: { user },
    } = this.props;
    let label = '';
    let src: string | undefined = '';
    if (user) {
      label = getUserName(user);
      src = user.avatar.src;
    }
    return {
      icon: <Avatar size={'small'} alt={label} src={src} light />,
      label: label,
    };
  };

  getAreaLegendData = () => {
    const {
      data: { area },
    } = this.props;
    return {
      icon: area !== null ? area.name : '',
      label: area !== null ? area.role : '',
    };
  };
}
