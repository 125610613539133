import { createReducer } from 'lib/store-utils';
import _ from 'lodash';
import moment from 'moment';
import * as rosteredShiftsActions from 'state/RosteredShifts/actions';
import * as timesheetsWeeklyPage from 'state/TimesheetsWeeklyPage';
import { RosteredShift } from 'type';
import { getDefaultState } from './state';
import { RosteredShiftsCollectionReducerState } from './types';

export const rosteredShiftsCollection =
  createReducer<RosteredShiftsCollectionReducerState>({}, getDefaultState());

rosteredShiftsCollection.on(
  timesheetsWeeklyPage.BOX_TIMESHEETS_WEEKLY_PAGE_DATA_GET.success,
  (
    state,
    { payload, rosteredShifts }
  ): RosteredShiftsCollectionReducerState => ({
    ...payload,
    rosteredShifts,
  })
);

rosteredShiftsCollection.on(
  timesheetsWeeklyPage.BOX_TIMESHEETS_WEEKLY_PAGE_DATA_UPDATE.success,
  (
    state,
    { payload, rosteredShifts }
  ): RosteredShiftsCollectionReducerState => ({
    ...payload,
    rosteredShifts,
  })
);

rosteredShiftsCollection.on(
  rosteredShiftsActions.BOX_ROSTERED_SHIFTS_GET_SUCCESS,
  (state, newState): RosteredShiftsCollectionReducerState => newState
);

rosteredShiftsCollection.on(
  rosteredShiftsActions.BOX_ROSTERED_SHIFTS_ADD,
  (state, newRosteredShifts): RosteredShiftsCollectionReducerState => ({
    ...state,
    rosteredShifts: {
      ...state.rosteredShifts,
      ...newRosteredShifts,
    },
  })
);

rosteredShiftsCollection.on(
  rosteredShiftsActions.BOX_ROSTERED_SHIFTS_ADD_BY_SOCKET,
  (state, newRosteredShifts): RosteredShiftsCollectionReducerState => {
    /*
     * This filtering is required to update state
     * when socket was triggered by another user (when current user triggered the request, state is updated on success)
     * socket was triggered by finishing the copy week job
     * */
    const filteredRosteredShifts = _.pickBy(
      newRosteredShifts,
      (newRosteredShift) => {
        const oldRosteredShift = state.rosteredShifts[newRosteredShift.id] as
          | RosteredShift
          | undefined;
        if (oldRosteredShift) {
          return (
            moment(newRosteredShift.updated_at).isAfter(
              oldRosteredShift.updated_at
            ) || newRosteredShift.timesheet_id !== oldRosteredShift.timesheet_id
          );
        } else {
          return true;
        }
      }
    );
    return {
      ...state,
      rosteredShifts: {
        ...state.rosteredShifts,
        ...filteredRosteredShifts,
      },
    };
  }
);

rosteredShiftsCollection.on(
  rosteredShiftsActions.BOX_ROSTERED_SHIFTS_ADD_ONE,
  (state, shift): RosteredShiftsCollectionReducerState => ({
    ...state,
    rosteredShifts: {
      ...state.rosteredShifts,
      [shift.id]: shift,
    },
  })
);

rosteredShiftsCollection.on(
  rosteredShiftsActions.BOX_ROSTERED_SHIFTS_UPDATE_CONNECTED_TIMESHEET,
  (state, timesheet): RosteredShiftsCollectionReducerState => {
    let shifts = state.rosteredShifts;
    if (timesheet.rostered_shift_id !== null) {
      shifts = {
        ...shifts,
        [`${timesheet.rostered_shift_id}`]: {
          ...shifts[`${timesheet.rostered_shift_id}`],
          timesheet_id: timesheet.id,
        },
      };
    }
    return {
      ...state,
      rosteredShifts: shifts,
    };
  }
);

rosteredShiftsCollection.on(
  rosteredShiftsActions.BOX_ROSTERED_SHIFTS_REMOVE_CONNECTED_TIMESHEET,
  (state, id): RosteredShiftsCollectionReducerState => ({
    ...state,
    rosteredShifts: {
      ...state.rosteredShifts,
      [id]: {
        ...state.rosteredShifts[id],
        timesheet_id: null,
      },
    },
  })
);

rosteredShiftsCollection.on(
  rosteredShiftsActions.BOX_ROSTERED_SHIFTS_DELETE,
  (state, { id }): RosteredShiftsCollectionReducerState => ({
    ...state,
    rosteredShifts: _.omit(state.rosteredShifts, id),
  })
);

rosteredShiftsCollection.on(
  rosteredShiftsActions.BOX_ROSTERED_SHIFTS_DELETE_SHIFTS,
  (state, toDelete): RosteredShiftsCollectionReducerState => ({
    ...state,
    rosteredShifts: _.omit(state.rosteredShifts, toDelete),
  })
);

rosteredShiftsCollection.on(
  rosteredShiftsActions.BOX_ROSTERED_SHIFT_DRAG_N_DROP_SUCCESS,
  (state, params): RosteredShiftsCollectionReducerState => ({
    ...state,
    rosteredShifts: {
      ..._.omit(state.rosteredShifts, params.removeId),
      ...params.updateShifts,
    },
  })
);

rosteredShiftsCollection.on(
  rosteredShiftsActions.BOX_ROSTERED_SHIFT_DRAG_N_DROP_SUCCESS_COPY,
  (state, data): RosteredShiftsCollectionReducerState => ({
    ...state,
    rosteredShifts: {
      ...state.rosteredShifts,
      ...data,
    },
  })
);
