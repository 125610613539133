import React, { Component } from 'react';
import { CheckOutlinedIcon, CloseOutlinedIcon, SimpleBadge } from 'element';
import { Col, Row } from 'elmo-elements';
import {
  AutoApproveShifts,
  Timesheet,
  TimesheetWithPayEntries,
  UserFields,
} from 'type/models';
import moment, { Moment } from 'moment';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import { StringMap } from 'type';
import { userListSelector } from 'state/UsersCollection';
import ShiftTime from './components/ShiftTime';
import Breaks from './components/Breaks';
import ShiftLocation from './components/ShiftLocation';
import _ from 'lodash';
import { AwardsModalOpenedPayload } from 'state/Timesheets/AwardsModal/types';
import ErrorBox from 'element/ErrorBox';
import { BOX_TIMESHEETS_AWARDS_MODAL_OPENED } from 'state/Timesheets/AwardsModal';
import ShiftCost from './components/ShiftCost';
import { hasPermissionSelector } from 'state/Auth/selectors';
import { BOX_TIMESHEETS_ALLOWANCES_MODAL_OPENED } from 'state/Timesheets/AllowancesModal';
import { AllowancesModalOpenedPayload } from 'state/Timesheets/AllowancesModal/types';
import { DSTIcon } from 'element/DSTIcon';
import { TimesheetUpdateRequest } from 'lib/Api';
import { getUserName, updateTimesheetBreaksOnDateChange } from 'lib/helpers';
import { BadgeType } from '../../../../../EmployeeDashboard/page/MyRosters/components/MyRostersList/types';
import { getEndDate } from 'state/Timesheets/Timesheets/helpers';
import { getAutoApproveTimesheets } from '../../../../../../state/Account';
import { doesTimesheetNeedApproval } from 'element/shiftModals/TimesheetModal/helpers';
import { TodayShift } from '../../../../../../state/Timesheets/Timesheets/types';
import {
  BOX_TIMESHEETS_APPROVE_REQUEST,
  BOX_TIMESHEETS_DELETE_REQUEST,
  BOX_TIMESHEETS_ERROR_CLEARED,
  BOX_TIMESHEETS_UPDATE_REQUEST,
  BOX_TIMESHEETS_UPDATE_WITH_APPROVAL,
  getShifts,
} from 'state/Timesheets/Timesheets';
import { TimesheetNotesField } from '../TimesheetNotesField';
import {
  IconButton,
  Card,
  CardHeader,
  CardContent,
  CardActions,
} from '@mui/material';
import { Button, Avatar } from 'oxygen-elements';
import MoreBtnOptions from './components/MoreBtnOptions/MoreBtnOptions';

type StateProps = {
  userList: StringMap<UserFields>;
  todayShifts: StringMap<TodayShift>;
  canEditTimesheet: boolean;
  canEditTimesheetApproved: boolean;
  canApprove: boolean;
  canViewCostings: boolean;
  autoApproveTimesheets: AutoApproveShifts;
};

type DispatchProps = {
  awardsButtonClicked: (payload: AwardsModalOpenedPayload) => void;
  updateTimesheet: (data: TimesheetUpdateRequest) => void;
  clearErrors: (id: string) => void;
  approve: (id: string) => void;
  deleteShift: (payload: { id: string }) => void;
  openAllowancesModal: (payload: AllowancesModalOpenedPayload) => void;
  openApprovalModal: (data: TimesheetUpdateRequest) => void;
};

type ActionsCardActionProp = {
  label: string;
  icon: React.ReactNode;
  id: string;
  isDisabled: boolean;
  isLoading?: boolean;
  onClick: () => void;
};

type OwnProps = {
  timesheet: Timesheet;
};

type Props = StateProps & OwnProps & DispatchProps;

type State = {
  timesheet: Timesheet;
  isEditing: boolean;
  isExpanded: boolean;
};

export class ShiftCardComponent extends Component<Props, State> {
  private colProps = {
    xs: 24 / 2,
    sm: 24 / 4,
    className: 'mb-1 mb-xs-2 mb-md-4',
  };

  state = {
    timesheet: _.cloneDeep(this.props.timesheet),
    isEditing: false,
    isExpanded: false,
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { todayShifts, timesheet } = this.props;
    if (!_.isMatch(this.props.timesheet, prevProps.timesheet)) {
      this.setState({
        timesheet: _.cloneDeep(this.props.timesheet),
      });
    }
    if (
      todayShifts[timesheet.id] &&
      todayShifts[timesheet.id].errors &&
      todayShifts[timesheet.id].errors.length &&
      !this.state.isEditing &&
      this.isChanged
    ) {
      this.setState({
        isEditing: true,
      });
    }

    if (
      prevProps.todayShifts[timesheet.id] &&
      prevProps.todayShifts[timesheet.id].isLoading &&
      !todayShifts[timesheet.id].isLoading
    ) {
      this.setState({
        timesheet: _.cloneDeep(this.props.timesheet),
      });
    }
  }

  renderUserName = () => {
    const { timesheet, userList } = this.props;
    const userFrom: UserFields = userList[timesheet.user_id as string];
    return getUserName(userFrom);
  };

  componentWillUnmount() {
    this.props.clearErrors(this.props.timesheet.id);
  }

  editShift = () => {
    this.setState((prevState) => ({
      ...prevState,
      isEditing: true,
    }));
  };

  render() {
    const { timesheet, isExpanded } = this.state;
    const { todayShifts, deleteShift } = this.props;
    const userFrom: UserFields = this.props.userList[timesheet.user_id];

    return (
      <Card
        className="action-shift-card"
        data-testid={`timesheet-action-card-${timesheet.id}`}
      >
        <CardHeader
          avatar={
            userFrom && (
              <Avatar
                light
                src={userFrom.avatar.src}
                alt={userFrom.avatar.label}
              />
            )
          }
          action={
            <div className="card-header-options">
              <SimpleBadge className="badge" {...(this.badge as any)} />
              <MoreBtnOptions
                onDeleteClick={() => deleteShift(timesheet)}
                onEditClick={this.editShift}
                disabled={!this.isEditable}
              />
            </div>
          }
          titleTypographyProps={{
            fontSize: 16,
            fontWeight: 600,
          }}
          title={this.renderUserName()}
          subheader={
            timesheet.is_dst_intersect && (
              <DSTIcon isDST={timesheet.is_dst_intersect} />
            )
          }
        />
        <CardContent className="action-shift-card__content">
          {todayShifts[timesheet.id] && todayShifts[timesheet.id].errors && (
            <ErrorBox
              dontWatchGlobalErrors={true}
              errors={todayShifts[timesheet.id].errors}
              clearErrors={() => {
                this.props.clearErrors(timesheet.id);
              }}
            />
          )}
          <Row>
            <Col {...{ ...this.colProps, sm: 7 }}>
              <ShiftLocation
                timesheet={timesheet}
                onChange={this.handleOnChangeAreaRole}
                isEditable={this.isEditable}
                isDisabled={!timesheet.user_id}
                isEditing={this.state.isEditing}
                onClick={() => {
                  this.setState({
                    isEditing: true,
                  });
                }}
              />
            </Col>
            <Col {...{ ...this.colProps, sm: 5 }}>
              <ShiftTime
                displayDate={true}
                isEditable={this.isEditable}
                isEditing={this.state.isEditing}
                label={'Start'}
                time={timesheet.start}
                rosterTime={
                  timesheet.rostered_shift
                    ? timesheet.rostered_shift.start
                    : undefined
                }
                onChange={this.onStartTimeChangeHandler}
                onClick={() => {
                  this.setState({
                    isEditing: true,
                  });
                }}
              />
            </Col>
            <Col {...{ ...this.colProps, sm: 5 }}>
              <ShiftTime
                displayDate={true}
                isEditable={this.isEditable}
                isEditing={this.state.isEditing}
                label={'End'}
                time={timesheet.end}
                rosterTime={
                  timesheet.rostered_shift
                    ? timesheet.rostered_shift.end
                    : undefined
                }
                onChange={this.onEndTimeChangeHandler}
                onClick={() => {
                  this.setState({
                    isEditing: true,
                  });
                }}
              />
            </Col>
            <Col {...{ ...this.colProps, sm: 7 }}>
              {this.canViewCost && (
                <ShiftCost timesheet={timesheet as TimesheetWithPayEntries} />
              )}
            </Col>
          </Row>
          <Row>
            <Col {...{ ...this.colProps, sm: 7 }}>
              <Breaks
                onChangeDate={this.onChangeDate}
                onChangeDuration={this.onChangeDuration}
                onChangePaid={this.onChangePaid}
                onClear={this.onClear}
                breaks={timesheet.breaks}
                isEditable={this.isEditable}
                isEditing={this.state.isEditing}
                onClick={() => {
                  this.setState({
                    isEditing: true,
                  });
                }}
                maxBreaks={this.getMaxBreaksCount()}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <TimesheetNotesField
                value={timesheet.notes}
                setNotesValue={this.onChangeNotes}
                onClick={() => {
                  this.setState({
                    ...this.state,
                    isEditing: !!timesheet.end,
                  });
                }}
                isEditing={this.state.isEditing}
              />
            </Col>
          </Row>
        </CardContent>
        {this.actions && (
          <CardActions className="action-shift-card__actions">
            {this.actions.map((action: any, index: number) => (
              <Button
                key={`btn-${action.label}`}
                size="large"
                onClick={action.onClick}
                disabled={action.isDisabled}
                loading={action.isLoading}
              >
                {action.icon} {action.label}
              </Button>
            ))}
          </CardActions>
        )}
      </Card>
    );
  }

  onChangeNotes = (value: string | null) => {
    this.setState({
      ...this.state,
      timesheet: {
        ...this.state.timesheet,
        notes: value,
      },
      isEditing: true,
    });
  };

  getMaxBreaksCount = () => {
    const { rostered_shift } = this.state.timesheet;
    return rostered_shift ? rostered_shift.breaks.length : 5;
  };

  onStartTimeChangeHandler = (start: Moment) => {
    const {
      timesheet: { end, breaks },
    } = this.state;
    if (end) {
      this.setState({
        timesheet: {
          ...this.state.timesheet,
          start,
          end: getEndDate(start, end),
          breaks: updateTimesheetBreaksOnDateChange(start, breaks),
        },
      });
    }
  };

  onEndTimeChangeHandler = (end: Moment) => {
    const {
      timesheet: { start },
    } = this.state;
    this.setState({
      timesheet: {
        ...this.state.timesheet,
        end: getEndDate(start, end),
      },
    });
  };

  get showExpand() {
    const {
      timesheet: { pay_entries },
    } = this.state;
    let showExpand = false;
    if (pay_entries) {
      pay_entries.forEach((entry: any) => {
        const { source, sub_type, manually_added } = entry;
        if (source === 'RTA' && sub_type === 'allowance' && manually_added) {
          showExpand = true;
        }
      });
    }
    return showExpand;
  }

  onChangeDate = (e: Moment, index: number) => {
    const {
      timesheet: { breaks, start },
    } = this.state;
    const time = moment(e).format('HH:mm');
    const startDate = moment(start).format('YYYY-MM-DD');

    if (!breaks[index]) {
      breaks[index] = this.emptyBreak;
    }
    breaks[index].start = moment(`${startDate} ${time}`);

    this.setState({
      timesheet: {
        ...this.state.timesheet,
        breaks: updateTimesheetBreaksOnDateChange(start, breaks),
      },
      isEditing: true,
    });
  };

  onChangeDuration = (duration: number, index: number) => {
    const {
      timesheet: { breaks },
    } = this.state;
    if (!breaks[index]) {
      breaks[index] = this.emptyBreak;
    }
    breaks[index].duration = duration;
    this.setState({
      timesheet: {
        ...this.state.timesheet,
        breaks: breaks,
      },
      isEditing: true,
    });
  };

  onChangePaid = (paid: boolean, index: number) => {
    const {
      timesheet: { breaks },
    } = this.state;
    if (!breaks[index]) {
      breaks[index] = this.emptyBreak;
    }
    breaks[index].paid = paid;
    this.setState({
      timesheet: {
        ...this.state.timesheet,
        breaks: breaks,
      },
      isEditing: true,
    });
  };

  onClear = (index: number) => {
    let breaks = this.state.timesheet.breaks;
    delete breaks[index];
    this.setState({
      timesheet: {
        ...this.state.timesheet,
        breaks: breaks,
      },
      isEditing: true,
    });
  };

  handleOnChangeAreaRole = (e: { value: string; label: string }) => {
    const { value } = e;
    const parsed = value.split('__');
    this.setState({
      ...this.state,
      timesheet: {
        ...this.state.timesheet,
        area_id: parsed[0],
        role_id: parsed[1],
      },
    });
  };

  get emptyBreak() {
    const {
      timesheet: { start },
    } = this.state;
    return {
      start: start.clone().set({ hours: 12, minutes: 0 }),
      duration: 0,
      paid: false,
      active: false,
    };
  }

  get isEditable() {
    const { timesheet } = this.state;
    if (this.status !== 'approved') {
      return !!timesheet.end && this.props.canEditTimesheet;
    }
    return (
      !!timesheet.end &&
      this.props.canEditTimesheet &&
      this.props.canEditTimesheetApproved
    );
  }

  get status() {
    const { timesheet } = this.state;
    let status = 'inprogress';

    if (timesheet.end !== null && !timesheet.is_approved) {
      status = 'pending';
    }

    if (timesheet.end !== null && timesheet.is_approved) {
      status = 'approved';
    }

    return status;
  }

  get badge() {
    switch (this.status) {
      case 'inprogress':
        return {
          label: 'In progress',
          icon: <></>,
          type: 'info',
        };

      case 'pending':
        return {
          label: 'Pending',
          icon: <></>,
          type: 'warning',
        };

      case 'approved':
        return {
          label: 'Approved',
          icon: <></>,
          type: 'success',
        };
      default:
        return {
          label: 'Inprogress',
          icon: <></>,
          type: 'info',
        };
    }
  }

  get actions() {
    const { todayShifts } = this.props;
    const { timesheet, isEditing } = this.state;
    let actions: ActionsCardActionProp[] = [];

    if (timesheet.end) {
      if (this.isChanged || isEditing) {
        actions = this.changedActions;
      } else {
        if (!timesheet.is_approved) {
          actions.unshift({
            label: 'Approve',
            id: 'approve',
            onClick: () => {
              this.props.approve(timesheet.id);
            },
            icon: <CheckOutlinedIcon />,
            isDisabled: !this.props.canEditTimesheet || !this.props.canApprove,
            isLoading: todayShifts[timesheet.id]
              ? todayShifts[timesheet.id].isLoading
              : false,
          });
        }
      }
    }

    const { end, pay_entries = [], ...restTimesheet } = timesheet;

    return actions.length ? actions : undefined;
  }

  get isChanged() {
    return !_.isMatch(this.props.timesheet, this.state.timesheet);
  }

  get changedActions() {
    const { todayShifts, autoApproveTimesheets } = this.props;
    const { timesheet } = this.state;
    return [
      {
        label: 'Save',
        id: 'save',
        onClick: () => {
          this.setState({
            isEditing: false,
          });
          if (doesTimesheetNeedApproval({ timesheet, autoApproveTimesheets })) {
            this.props.openApprovalModal(this.payload);
          } else {
            this.props.updateTimesheet(this.payload);
          }
        },
        icon: <CheckOutlinedIcon />,
        isDisabled: !this.isChanged || !timesheet.area_id,
        isLoading: todayShifts[timesheet.id]
          ? todayShifts[timesheet.id].isLoading
          : false,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        onClick: () => {
          this.setState({
            timesheet: _.cloneDeep(this.props.timesheet),
            isEditing: false,
          });
        },
        icon: <CloseOutlinedIcon />,
        isDisabled: false,
      },
    ];
  }

  get canViewCost() {
    return this.props.canViewCostings;
  }

  get payload(): TimesheetUpdateRequest {
    const {
      id,
      area_id,
      site_id,
      role_id,
      start,
      end,
      breaks,
      user_id,
      is_approved,
      rostered_shift_id,
      notes,
    } = this.state.timesheet;
    return {
      id,
      area_id,
      site_id,
      role_id,
      start,
      end: end as Moment,
      breaks,
      user_id,
      is_approved,
      rostered_shift_id,
      notes,
    };
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  userList: userListSelector(state),
  todayShifts: getShifts(state),
  canEditTimesheet: hasPermissionSelector(state, 'roster.timesheet.edit'),
  canEditTimesheetApproved: hasPermissionSelector(
    state,
    'roster.timesheet.edit.onceapproved'
  ),
  canApprove: hasPermissionSelector(state, 'roster.timesheet.edit.approve'),
  canViewCostings: hasPermissionSelector(
    state,
    'roster.timesheet.view.labourcostings'
  ),
  autoApproveTimesheets: getAutoApproveTimesheets(state),
});

const mapDispatchToProps: DispatchProps = {
  awardsButtonClicked: BOX_TIMESHEETS_AWARDS_MODAL_OPENED,
  updateTimesheet: BOX_TIMESHEETS_UPDATE_REQUEST,
  clearErrors: BOX_TIMESHEETS_ERROR_CLEARED,
  approve: BOX_TIMESHEETS_APPROVE_REQUEST,
  deleteShift: BOX_TIMESHEETS_DELETE_REQUEST,
  openAllowancesModal: BOX_TIMESHEETS_ALLOWANCES_MODAL_OPENED,
  openApprovalModal: BOX_TIMESHEETS_UPDATE_WITH_APPROVAL,
};

export const ShiftCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShiftCardComponent);
