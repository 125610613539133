import React, { Component } from 'react';
import { Button, Row, Col, Divider } from 'elmo-elements';
import { DeleteOutlinedIcon, PersonOutlinedIcon } from 'element/icons';
import { Event } from 'type/models';

type Props = {
  event: Event;
  isUpdating: boolean;
  openSubModal: (name: string) => void;
  deleteEvent: () => void;
};

export default class ModalActions extends Component<Props> {
  render() {
    const {
      event: { id },
      isUpdating,
    } = this.props;
    if (id === null || id === '') {
      return null;
    }
    return (
      <div className="shift-modal-event-actions">
        <Row role="presentation">
          <Col isAuto={true} role="presentation">
            {this.props.event.type === 'event' && (
              <Button
                id="event-edit-form-btn-roles"
                isText={true}
                icon={<PersonOutlinedIcon />}
                onClick={(e) => this.props.openSubModal('positions')}
                ariaLabel="Positions"
                tabIndex={0}
              />
            )}
          </Col>
          {id !== null && (
            <Col isAuto={true} className={'text-right'} role="presentation">
              <Button
                id="event-edit-form-btn-delete"
                isText={true}
                icon={<DeleteOutlinedIcon />}
                onClick={this.props.deleteEvent}
                isDisabled={isUpdating}
                ariaLabel="Delete"
                tabIndex={0}
              />
            </Col>
          )}
        </Row>
        <Divider />
      </div>
    );
  }
}
