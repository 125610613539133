import * as React from 'react';
import classNames from 'clsx';
import { Alert, AlertColor } from 'extended-oxygen-elements';

type Props = {
  errors: string[];
  watchFormErrors?: boolean;
  clearErrors?: () => void;
  className?: string;
  dontWatchGlobalErrors?: boolean;
  type?: AlertColor;
};
export class ErrorBox extends React.Component<Props> {
  readonly errorSelector = '.elmo-formitem__control__help';

  removeErrors = () => {
    if (this.props.clearErrors) {
      this.props.clearErrors();
    }
  };

  componentDidUpdate() {
    const { dontWatchGlobalErrors } = this.props;
    if (
      this.props.errors.length &&
      (typeof dontWatchGlobalErrors === 'undefined' ||
        dontWatchGlobalErrors === false)
    ) {
      this.scrollToGlobalErrors();
    }
    if (
      this.props.watchFormErrors &&
      document.querySelector(this.errorSelector) !== null
    ) {
      this.scrollToFormErrors();
    }
  }

  scrollToGlobalErrors = () => {
    const wrapperSelector = this.isMobile()
      ? 'html'
      : '.elmo-layout__main-content-wrapper';
    const wrapper = document.querySelector(wrapperSelector);
    const errors = document.querySelector('.global-errors-alert');

    if (wrapper !== null && errors !== null) {
      if (typeof wrapper.scrollTo !== 'function') {
        wrapper.scrollTop = (errors as HTMLElement).offsetTop - 25;
      } else {
        wrapper.scrollTo(0, (errors as HTMLElement).offsetTop - 25);
      }
    }
  };

  isMobile = () => {
    return window.innerWidth < 1280;
  };

  getFirstVisibleError = (errors: NodeList) => {
    for (const error of errors as any) {
      if (error.innerText !== '' && error.offsetTop > 0) {
        return error;
      }
    }
    return null;
  };

  scrollToFormErrors = () => {
    const wrapperSelector = this.isMobile()
      ? 'html'
      : '.elmo-layout__main-content-wrapper';
    const wrapper = document.querySelector(wrapperSelector);
    const formErrors = document.querySelectorAll(this.errorSelector);

    if (wrapper !== null && formErrors.length) {
      const error = this.getFirstVisibleError(formErrors);
      if (error !== null) {
        error.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'end',
        });
      }
    }
  };

  render() {
    if (!this.props.errors || this.props.errors.length === 0) {
      return null;
    }

    return (
      <div
        style={{ marginBottom: '20px' }}
        data-testid={'global-errors-alert-box'}
      >
        <Alert
          color={this.props.type ? this.props.type : 'error'}
          severity={this.props.type ? this.props.type : 'error'}
          className={classNames('global-errors-alert', this.props.className)}
          onClose={this.removeErrors}
        >
          {this.props.errors.map((error: string, index: number) => (
            <div key={index}>{error}</div>
          ))}
        </Alert>
      </div>
    );
  }
}

export default ErrorBox;
