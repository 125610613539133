import React from 'react';
import { connect } from 'react-redux';
import { intersection, map, uniq } from 'lodash';
import { StoreState } from 'state/types';
import { currentUserSiteIdsSelector, hasPermissionSelector } from 'state/Auth';
import { SubmitPayload } from './types';
import {
  RoleModalView,
  RoleModalViewProps,
  ConfirmationModal
} from './components';

type OwnProps = RoleModalViewProps;

type StateProps = {
  currentUserSiteIds: string[];
  canViewAllSites: boolean;
};

type DispatchProps = {};

export type Props = OwnProps & StateProps & DispatchProps;

type State = {
  payload: SubmitPayload | null;
};

const getState = (props: Props): State => ({
  payload: null
});

export class RoleModalComponent extends React.Component<Props, State> {
  readonly state = getState(this.props);

  render() {
    const { onFormSubmit, ...restProps } = this.props;

    return (
      <>
        <RoleModalView {...restProps} onFormSubmit={this.onFormSubmit} />
        <ConfirmationModal
          isOpened={this.isConfirmationModalOpened}
          onConfirmBtnClick={this.onSubmitConfirmationModal}
          onCloseBtnClick={this.closeConfirmationModal}
        />
      </>
    );
  }

  private get isConfirmationModalOpened() {
    return !!this.state.payload;
  }

  private onFormSubmit = (payload: SubmitPayload) => {
    if (this.userDeletedAllAccessibleRoles(payload)) {
      this.setState({
        payload
      });
    } else {
      this.props.onFormSubmit(payload);
    }
  };

  private closeConfirmationModal = () => {
    this.setState({
      payload: null
    });
  };

  private onSubmitConfirmationModal = () => {
    this.props.onFormSubmit(this.state.payload!);

    this.setState({
      payload: null
    });
  };

  private userDeletedAllAccessibleRoles = ({
    user_roles
  }: SubmitPayload): boolean => {
    const { currentUserSiteIds, canViewAllSites } = this.props;

    if (canViewAllSites) {
      return false;
    }

    const selectedRolesSiteIds = uniq(
      map(user_roles, ({ site_id }) => site_id)
    );

    return !intersection(currentUserSiteIds, selectedRolesSiteIds).length;
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  currentUserSiteIds: currentUserSiteIdsSelector(state),
  canViewAllSites: hasPermissionSelector(state, 'account.viewallsites')
});

// const mapDispatchToProps: DispatchProps = {};

export const RoleModal = connect(mapStateToProps)(RoleModalComponent);
