import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment';
import { Col, FormItem, Input, Row, Text } from 'elmo-elements';
import {
  CalendarIcon,
  PageDialog,
  PageDialogCancel,
  PageDialogSubmit,
  PeopleOutlinedIcon,
} from 'element';
import {
  LanguagePreferences,
  PreferencesDateFormat,
  PreferencesTimeFormat,
  RosteredShift,
  StringMap,
} from 'type';
import { SERVER_DAY_FORMAT } from 'lib/config';
import { getDateTimeFormatted, getPreferenceLabel } from 'lib/helpers';
import { StoreState } from 'state/types';
import {
  BOX_ROSTER_CLOSE_SAVE_TEMPLATE_MODAL,
  BOX_ROSTER_SAVE_TEMPLATE,
  BOX_ROSTER_SAVE_TEMPLATE_CLEAR_ERRORS,
  CreateRosterTemplateModal as CreateRosterTemplateModalType,
  getCreateRosterTemplateModal,
  SaveTemplatePayload,
} from 'state/Roster/RosterCopyPast';
import {
  getFrom,
  getRosteredShifts,
  getSiteId,
  getTo,
} from 'state/RosteredShifts/selectors';
import {
  getDateFormat,
  getLangPreferences,
  getTimeFormat,
} from 'state/Account';
import { ErrorBox } from 'element';
import { isAppMarket } from '../../../../../../../../helpers';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';

type OwnProps = {};
type DispatchProps = {
  onClose: () => void;
  saveTemplate: (payload: SaveTemplatePayload) => void;
  clearErrors: () => void;
};
type StateProps = {
  createRosterTemplateModal: CreateRosterTemplateModalType;
  rosteredShifts: StringMap<RosteredShift>;
  from: Moment;
  to: Moment;
  dateFormat: PreferencesDateFormat;
  timeFormat: PreferencesTimeFormat;
  siteId: string;
  langPreferences: LanguagePreferences;
};
type Props = OwnProps & DispatchProps & StateProps;

export const CreateRosterTemplateModalComponent = ({
  createRosterTemplateModal: { isOpened, isUpdating, errors },
  onClose,
  rosteredShifts,
  from,
  to,
  dateFormat,
  timeFormat,
  saveTemplate,
  siteId,
  clearErrors,
  langPreferences,
}: Props) => {
  const [templateName, setTemplateName] = useState('');
  const shifts: RosteredShift[] = [];

  useEffect(() => {
    if (!isOpened) {
      setTemplateName('');
    }
  }, [isOpened]);

  const usersNumber = () => {
    let u: string[] = [];
    shifts.forEach((shift) => {
      if (shift.user_id && u.indexOf(shift.user_id!) === -1) {
        u.push(shift.user_id!);
      }
    });
    return u.length;
  };

  const filterShiftsByStart = () => {
    Object.keys(rosteredShifts).forEach((key: string) => {
      if (
        moment.parseZone(rosteredShifts[key].start).format(SERVER_DAY_FORMAT) >=
        from.format(SERVER_DAY_FORMAT)
      ) {
        shifts.push(rosteredShifts[key]);
      }
    });
    return shifts;
  };

  const getTitle = () => {
    const labelText = isAppMarket('uk') ? 'rota' : 'roster';
    return getPreferenceLabel(
      langPreferences,
      labelText,
      'singular',
      labelText,
      true
    );
  };

  const infoBlockDate = () => (
    <>
      <Text className={'info-title'}>{getTitle()} information</Text>
      <br />
      <Text id="template-dates" color={'primary'} className={'mb-3'}>
        {`Week ${from.isoWeek()}, 
            ${getDateTimeFormatted(
              dateFormat,
              timeFormat,
              from
            )} - ${getDateTimeFormatted(dateFormat, timeFormat, to)}`}
      </Text>
    </>
  );

  const infoBlockIcons = () => (
    <div style={{ padding: '10px 0' }}>
      <Row className={'mt-2 mb-4'}>
        <Col span={5}>
          <Text color={'gray'} size={'xs'}>
            <CalendarIcon />{' '}
            <span data-testid="template-shifts-count">
              {filterShiftsByStart().length}
            </span>
          </Text>
        </Col>
        <Col span={5}>
          <Text color={'gray'} size={'xs'}>
            <PeopleOutlinedIcon />{' '}
            <span data-testid="template-users-count">{usersNumber()}</span>
          </Text>
        </Col>
      </Row>
    </div>
  );

  const onSaveTemplate = () => {
    saveTemplate({
      title: templateName,
      from: from,
      to: to,
      site_id: siteId,
    });
  };

  return (
    <PageDialog maxWidth={'xs'} open={isOpened} id="save-template-modal">
      <DialogTitle>Save as template</DialogTitle>
      <DialogContent>
        {errors.length > 0 && (
          <ErrorBox errors={errors} clearErrors={clearErrors} />
        )}
        {infoBlockDate()}
        {infoBlockIcons()}
        <FormItem label={'Template name'}>
          <Input
            id="template-name"
            name="templateName"
            value={templateName}
            onChange={(e) => {
              setTemplateName(e.target.value);
            }}
          />
        </FormItem>
      </DialogContent>
      <DialogActions>
        <PageDialogCancel onClick={onClose}>Cancel</PageDialogCancel>
        <PageDialogSubmit
          onClick={onSaveTemplate}
          id="save-template-btn"
          loading={isUpdating}
          disabled={
            !templateName || templateName.trim().length === 0 || isUpdating
          }
        >
          Save
        </PageDialogSubmit>
      </DialogActions>
    </PageDialog>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  createRosterTemplateModal: getCreateRosterTemplateModal(state),
  rosteredShifts: getRosteredShifts(state),
  from: getFrom(state),
  to: getTo(state),
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
  siteId: getSiteId(state),
  langPreferences: getLangPreferences(state),
});

const mapDispatchToProps: DispatchProps = {
  onClose: BOX_ROSTER_CLOSE_SAVE_TEMPLATE_MODAL,
  saveTemplate: BOX_ROSTER_SAVE_TEMPLATE,
  clearErrors: BOX_ROSTER_SAVE_TEMPLATE_CLEAR_ERRORS,
};
export const CreateRosterTemplateModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRosterTemplateModalComponent);
