import { useMemo } from 'react';
import { StoreState } from 'state/types';

export const useMakeSelector = <
  Selector extends (state: StoreState, ...args: any[]) => any
>(
  makeSelector: () => Selector
) => useMemo(makeSelector, []);

export default useMakeSelector;
