import { createSelector } from 'reselect';
import { Moment } from 'moment';
import { AccountTreeArea, StringMap } from 'type';
import { StoreState } from 'state/types';
import { bySiteTimezoneCombinerCreator } from 'state/combiners';
import {
  currentSiteTimezoneSelector,
  getSiteId,
  selectedDaySelector,
} from 'state/RosteredShifts/selectors';
import { ShiftForOfferItem } from 'state/RosteredShifts/types';
import { getCurrentTime } from 'state/CurrentTime';
import { ErrorModalProps, RosterOpenModal } from './types';
import { activeAreasBySiteIdSelector } from '../../AccountTree';

const getState = (state: StoreState) => state.roster;
const getOpenedModal = (state: StoreState) => getState(state).openedModal;
export const getIsPublishing = (state: StoreState) => getState(state).isPublishing;
export const getIsModalOpen = (state: StoreState, modalName: RosterOpenModal) =>
  getOpenedModal(state) === modalName;
export const getUnpublishedRosters = (state: StoreState): any =>
  state.roster.unpublishedRosters;
export const getUnpublishedRostersRefactored = (state: StoreState): any =>
  state.roster.unpublishedRosters;
export const getUnpublishedRostersNumber = createSelector(
  getUnpublishedRosters,
  (unpublished) => {
    let num: number = 0;
    Object.keys(unpublished).forEach((key: string) => {
      num += unpublished[key].unpublished_shifts_count;
    });
    return num;
  }
);
export const getTotalUnpublishedNumber = (state: StoreState) => {
  if ( getState(state).unpublishedRosters && getState(state).unpublishedRosters.total ) {
    return getState(state).unpublishedRosters.total.unpublished_shifts_count;
  }
  return 0;
}
export const getShiftsForOffer = (
  state: StoreState
): StringMap<ShiftForOfferItem> => state.roster.availableOffers;
export const getIsApprovalModeEnabled = (state: StoreState) =>
  state.roster.isApproveMode;
export const getRosterErrorModal = (state: StoreState): ErrorModalProps =>
  state.roster.errorModal;

export const selectedDayBySiteTimezoneSelector = createSelector(
  selectedDaySelector,
  currentSiteTimezoneSelector,
  (selectedDay: Moment, timezone: string) =>
    selectedDay.clone().tz(timezone, true)
);

export const currentTimeBySiteTimezoneSelector = createSelector<
  StoreState,
  Moment,
  string,
  Moment
>(getCurrentTime, currentSiteTimezoneSelector, bySiteTimezoneCombinerCreator());

export const shiftsForOfferNumberSelector = createSelector<
  StoreState,
  StringMap<ShiftForOfferItem>,
  number
>(getShiftsForOffer, (list) => {
  let num = 0;
  Object.keys(list).forEach((key: string) => {
    num += list[key].shifts_count;
  });
  return num;
});

export const activeAreasForCurrentSiteId = createSelector<
  StoreState,
  StringMap<AccountTreeArea[]>,
  string,
  AccountTreeArea[]
>(activeAreasBySiteIdSelector, getSiteId, (areasBySite, selectedSiteId) => {
  const areas = areasBySite[selectedSiteId] || [];
  let hasAvailableRoles = false;
  if (!areas || areas.length === 0) {
    return areas;
  }
  areas.forEach((area) => {
    if (area.role_ids && area.role_ids.length > 0) {
      hasAvailableRoles = true;
    }
  });
  return areas;
});

export const getExportResponse = (state: StoreState) => getState(state).downloadCsvModal;
export const getIsGenerating = (state: StoreState) => getState(state).isGenerating;
export const getErrors = (state: StoreState) => getState(state).errors;
export const getSuccessMsg = (state: StoreState) => getState(state).generateSuccessMsg;
