import { createAction } from 'lib/store-utils';
import {
  DeleteShiftItem,
  BulkDeletePayload,
  ToggleSelectedShiftsByUserPayload,
  AreaRolePayload,
  ToggleSelectedShiftsByAreaRole
} from './types';
import { FormattedErrors, ConfirmationsWithTitle } from '../../../type/models';

export const BOX_ROSTER_BULK_DELETION_MODE_ACTIVATE = createAction('Roster bulk deletion / activate mode');
export const BOX_ROSTER_BULK_DELETION_MODE_DEACTIVATE = createAction('Roster bulk deletion / deactivate mode');

export const BOX_ROSTER_BULK_DELETION_MODE_TOGGLE_SELECTED_SHIFT = createAction<DeleteShiftItem>('Roster bulk deletion / toggle selected id');

export const BOX_ROSTER_BULK_DELETION_SEND_SHIFTS_REQUEST = createAction<BulkDeletePayload>('Roster bulk deletion / send shifts request');
export const BOX_ROSTER_BULK_DELETION_SEND_SHIFTS_SUCCESS = createAction<string[]>('Roster bulk deletion / send shifts request success');
export const BOX_ROSTER_BULK_DELETION_SEND_SHIFTS_FAILURE = createAction<FormattedErrors>('Roster bulk deletion / send shifts request failure');

export const BOX_ROSTER_BULK_DELETION_SEND_TEMPLATED_SHIFTS_REQUEST = createAction<BulkDeletePayload>('Roster bulk deletion / send templated shifts request');
export const BOX_ROSTER_BULK_DELETION_SEND_TEMPLATED_SHIFTS_SUCCESS = createAction('Roster bulk deletion / send templated shifts request success');
export const BOX_ROSTER_BULK_DELETION_SEND_TEMPLATED_SHIFTS_FAILURE = createAction<FormattedErrors>('Roster bulk deletion / send templated shifts request failure');

export const BOX_ROSTER_BULK_DELETION_MODAL_OPEN = createAction('Roster bulk deletion / open modal');
export const BOX_ROSTER_BULK_DELETION_MODAL_CLOSE = createAction('Roster bulk deletion / close modal');
export const BOX_ROSTER_BULK_DELETION_MODAL_CONFIRMATION = createAction<ConfirmationsWithTitle>('Roster bulk deletion / set confirmation message');
export const BOX_ROSTER_BULK_DELETION_MODAL_CLEAR_ERRORS = createAction('Roster bulk deletion / clear errors');

export const BOX_ROSTER_BULK_TOGGLE_SELECTION_BY_USER = createAction<string | null>('Roster bulk deletion / toggle selection by user');
export const BOX_ROSTER_BULK_TOGGLE_SELECTED_USER = createAction<string | null>('Roster bulk deletion / toggle selected user');
export const BOX_ROSTER_BULK_TOGGLE_SELECTED_SHIFTS = createAction<ToggleSelectedShiftsByUserPayload>('Roster bulk deletion / toggle selected ids');

export const BOX_ROSTER_BULK_TOGGLE_SELECTION_BY_AREA_ROLE = createAction<AreaRolePayload>('Roster bulk deletion / toggle selection by area-role');
export const BOX_ROSTER_BULK_TOGGLE_SELECTED_AREA_ROLE = createAction<AreaRolePayload>('Roster bulk deletion / toggle selected area-role');
export const BOX_ROSTER_BULK_TOGGLE_SELECTED_SHIFTS_AREA_ROLE = createAction<ToggleSelectedShiftsByAreaRole>('Roster bulk deletion / toggle selected ids');

export const BOX_ROSTER_BULK_REMOVE_SHIFT_ID_BY_SOCKET_EVENT = createAction<string>('Roster bulk deletion / deselect shift by socket event');
export const BOX_ROSTER_BULK_REMOVE_SHIFT_IDS_BY_SOCKET_EVENT = createAction<string[]>('Roster bulk deletion / deselect shift ids by socket event');
