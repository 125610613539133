import _ from 'lodash';
import React from 'react';
import * as rosterHooks from 'page/Roster/hooks';

export default function useHeaderButtons() {
  const btnExportTimesheets = rosterHooks.useBtnExportTimesheets();
  const btnAutoGenerateTimesheets =
    rosterHooks.useBtnAutoGenerateTimesheets();
  const btnOfferRosteredShifts = rosterHooks.useBtnOfferRosteredShifts();
  const btnApproveTimesheets = rosterHooks.useBtnApproveTimesheets();

  return _.compact([
    btnExportTimesheets,
    btnAutoGenerateTimesheets,
    btnOfferRosteredShifts,
    btnApproveTimesheets
  ]);
}
