import React, { Component, ReactChild } from 'react';
import { connect } from 'react-redux';
import { Filter, LayoutContextProps, withLayoutAware } from 'elmo-elements';
import { marketLabels } from 'marketLabels';
import { capitalize } from 'lib/helpers';
import { StoreState } from 'state/types';
import {
  SelectedFilter,
  UserToggleFilterTypePayload,
} from 'state/Users/Users/types';
import {
  BOX_USERS_CLEAR_FILTER_FORM,
  BOX_USERS_TOGGLE_FILTER_TYPE,
  BOX_USERS_UPDATE_CUSTOM_FILTERS,
  getSearchQuery,
  getSelectedFilter,
  isFiltersFormDisabledSelector,
} from 'state/Users/Users';
import { CustomFilters } from './components/CustomFilters';

type OwnProps = {};

type StateProps = {
  selectedFilter: SelectedFilter;
  searchQuery: string;
  isFiltersFormDisabled: boolean;
};

type DispatchProps = {
  updateCustomFilters: () => void;
  clearCustomFilters: () => void;
  togglePredefinedFilter: (filterType: UserToggleFilterTypePayload) => void;
};

type Props = OwnProps & StateProps & DispatchProps & LayoutContextProps;

export class UserFilterComponent extends Component<Props> {
  statuses: {
    label: string;
    value: UserToggleFilterTypePayload;
  }[] = [
    {
      label: 'Current',
      value: 'active',
    },
    {
      label: capitalize(marketLabels.userArchived),
      value: 'archived',
    },
  ];

  render() {
    const {
      updateCustomFilters,
      selectedFilter,
      searchQuery,
      isBulkActionOpen,
      isFiltersFormDisabled,
    } = this.props;

    return !searchQuery.length && !isBulkActionOpen ? (
      <Filter
        onStatusChange={this.onStatusChange}
        statuses={this.statuses}
        renderCustomFilters={this.renderCustomFilters}
        onApplyCustomFilters={updateCustomFilters}
        onToggleCustomFilters={this.onToggleCustomFilters}
        selectedValue={selectedFilter}
        isApplyBtnDisabled={isFiltersFormDisabled}
      />
    ) : null;
  }

  private onStatusChange = (filterType: string) => {
    this.props.togglePredefinedFilter(
      filterType as UserToggleFilterTypePayload
    );
  };

  private renderCustomFilters = (): ReactChild => <CustomFilters />;

  private onToggleCustomFilters = (isOpen: boolean) => {
    if (isOpen) {
      this.props.clearCustomFilters();
    }
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  selectedFilter: getSelectedFilter(state),
  searchQuery: getSearchQuery(state),
  isFiltersFormDisabled: isFiltersFormDisabledSelector(state),
});

const mapDispatchToProps: DispatchProps = {
  updateCustomFilters: BOX_USERS_UPDATE_CUSTOM_FILTERS,
  clearCustomFilters: BOX_USERS_CLEAR_FILTER_FORM,
  togglePredefinedFilter: BOX_USERS_TOGGLE_FILTER_TYPE,
};
export const UsersFilter = withLayoutAware(
  connect(mapStateToProps, mapDispatchToProps)(UserFilterComponent)
);
