import React from 'react';
import { useSelector } from 'react-redux';
import { getIsApprovalModeEnabled } from 'state/Roster/Roster';
import { EventsModal } from 'element';

function RosterEventsModal() {
  const isApprovalModeEnabled = useSelector(getIsApprovalModeEnabled);

  if (isApprovalModeEnabled) {
    return null;
  }

  return <EventsModal />;
}

export default RosterEventsModal;
