import React from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from 'state/types';
import { CenteredCircularProgress } from '../CenteredCircularProgress';

export type PreloadingHandlerProps = {
  loadingSelector: (state: StoreState) => boolean;
  children: React.ReactNode;
  skeletonComponent?: React.ElementType;
};

export function PreloadingHandler({
  loadingSelector,
  children,
  skeletonComponent: Skeleton = CenteredCircularProgress,
}: PreloadingHandlerProps) {
  const fetching = useSelector(loadingSelector);

  if (fetching) {
    return <Skeleton />;
  }

  return <>{children}</>;
}

export default PreloadingHandler;
