import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { pick } from 'lodash';
import { Moment } from 'moment-timezone';
import {
  RosteredShift,
  SagaActionFromCreator,
  SagaReturnType,
  ShiftBreak,
} from 'type';
import {
  Api,
  RosteredShiftCreateResponse,
  RosteredShiftUpdateRequest,
} from 'lib/Api';
import { formatError } from 'state/helpers';
import * as isLoading from 'state/IsLoading';
import { processApiRequestWithConfirm } from 'state/ProcessApiRequest';
import {
  BOX_ROSTERED_SHIFTS_ADD,
  getRosteredShiftSafe,
} from 'state/RosteredShifts';
import { BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN } from '../../../Roster';
import { getDragAndDropShiftId } from '../../selectors';
import {
  BOX_ROSTER_DAY_VIEW_DRAG_AND_DROP_SITE_VIEW_FINISHED,
  BOX_ROSTER_DAY_VIEW_DRAG_AND_DROP_USER_VIEW_FINISHED,
} from '../../actions';

export const updateShift = function* (
  payload: RosteredShiftUpdateRequest
): SagaIterator {
  yield put(isLoading.BOX_IS_LOADING_GLOBAL_ON());

  try {
    const response: RosteredShiftCreateResponse | undefined = yield call(
      processApiRequestWithConfirm,
      Api.RosteredShift.updateRosteredShift,
      payload
    );

    if (response) {
      yield put(BOX_ROSTERED_SHIFTS_ADD(response));
    }
  } catch (error) {
    yield put(BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN(formatError(error)));
  }

  yield put(isLoading.BOX_IS_LOADING_GLOBAL_OFF());
};

export const calculateEndTime = ({
  rosteredShift,
  newStartTime,
}: {
  rosteredShift: RosteredShift;
  newStartTime: Moment;
}): Moment => {
  const shiftDuration = rosteredShift.end.diff(rosteredShift.start);
  return newStartTime.clone().add(shiftDuration);
};

export const updateBreakTime = ({
  breaks,
  start,
  newStartTime,
}: {
  breaks: ShiftBreak[];
  start: Moment;
  newStartTime: Moment;
}): ShiftBreak[] =>
  breaks.map((shiftBreak) => {
    const breakOffset = shiftBreak.start.diff(start);
    const newBreakStartTime = newStartTime.clone().add(breakOffset);

    return {
      ...shiftBreak,
      start: newBreakStartTime,
    };
  });

const getBaseUpdatePayload = function* ({
  newStartTime,
}: {
  newStartTime: Moment;
}): SagaIterator<RosteredShiftUpdateRequest | null> {
  const shiftId: ReturnType<typeof getDragAndDropShiftId> = yield select(
    getDragAndDropShiftId
  );

  const rosteredShift: ReturnType<typeof getRosteredShiftSafe> = yield select(
    getRosteredShiftSafe,
    shiftId
  );

  if (!rosteredShift) {
    /**
     * some cases have been found when the shift
     * is not present in the store and this breaks
     * the day view page functionality
     * */
    return null;
  }

  const newEndTime = calculateEndTime({ rosteredShift, newStartTime });

  const updatedBreaks = updateBreakTime({
    breaks: rosteredShift.breaks,
    start: rosteredShift.start,
    newStartTime,
  });

  const partialPayloadFromShift = pick<
    typeof rosteredShift,
    keyof typeof rosteredShift
  >(rosteredShift, [
    'user_id',
    'role_id',
    'area_id',
    'template_id',
    'is_hidden_end_time',
    'event_id',
    'tags',
    'notify_user',
    'id',
    'notes',
  ]);

  return {
    ...partialPayloadFromShift,
    breaks: updatedBreaks,
    start: newStartTime,
    end: newEndTime,
    need_re_accept: rosteredShift.need_re_accept || false,
  };
};

export const dragAndDropCopyUserView = function* ({
  payload: { userId, newStartTime },
}: SagaActionFromCreator<
  typeof BOX_ROSTER_DAY_VIEW_DRAG_AND_DROP_USER_VIEW_FINISHED
>): SagaIterator {
  const baseUpdatePayload: SagaReturnType<typeof getBaseUpdatePayload> =
    yield call(getBaseUpdatePayload, { newStartTime });

  if (!baseUpdatePayload) {
    return;
  }

  const updatePayload: RosteredShiftUpdateRequest = {
    ...baseUpdatePayload,
    user_id: userId,
  };

  yield call(updateShift, updatePayload);
};

export const dragAndDropCopySiteView = function* ({
  payload: { newStartTime, areaId, roleId },
}: SagaActionFromCreator<
  typeof BOX_ROSTER_DAY_VIEW_DRAG_AND_DROP_SITE_VIEW_FINISHED
>): SagaIterator {
  const baseUpdatePayload: SagaReturnType<typeof getBaseUpdatePayload> =
    yield call(getBaseUpdatePayload, { newStartTime });

  if (!baseUpdatePayload) {
    return;
  }

  const updatePayload: RosteredShiftUpdateRequest = {
    ...baseUpdatePayload,
    area_id: areaId,
    role_id: roleId,
  };

  yield call(updateShift, updatePayload);
};
