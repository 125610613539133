import {
  AccountTreeLinkAreasRequest,
  AccountTreeLinkRolesRequest,
  AccountTreeUnLinkRoleRequest,
} from 'lib/Api';
import { createAction, createAsyncAction } from 'lib/store-utils';
import {
  GetPageDataSuccessPayload,
  LinkAreaSuccessPayload,
  LinkRoleSuccessPayload,
  UnLinkRoleSuccessPayload,
  UpdateAreaStatusPayload,
  UpdateAreaStatusSuccessPayload,
  UpdateSiteStatusPayload,
  UpdateSiteStatusSuccessPayload,
} from './types';
import { AccountTree, FormattedErrors } from 'type';

export const BOX_ACCOUNT_TREE_PAGE_DATA_GET = createAsyncAction<
  void,
  GetPageDataSuccessPayload,
  FormattedErrors
>('Account tree / fetch page data');
export const BOX_ACCOUNT_TREE_AREAS_LINK = createAsyncAction<
  AccountTreeLinkAreasRequest,
  LinkAreaSuccessPayload,
  FormattedErrors
>('Account tree / link areas');
export const BOX_ACCOUNT_TREE_ROLES_LINK = createAsyncAction<
  AccountTreeLinkRolesRequest,
  LinkRoleSuccessPayload,
  FormattedErrors
>('Account tree / link roles');
export const BOX_ACCOUNT_TREE_ROLES_UNLINK = createAsyncAction<
  AccountTreeUnLinkRoleRequest,
  UnLinkRoleSuccessPayload,
  FormattedErrors
>('Account tree / unlink role');
export const BOX_ACCOUNT_TREE_UPDATE_AREA_STATUS = createAsyncAction<
  UpdateAreaStatusPayload,
  UpdateAreaStatusSuccessPayload,
  FormattedErrors
>('Account tree / update area status');

export const BOX_ACCOUNT_TREE_UPDATE_SITE_STATUS = createAsyncAction<
  UpdateSiteStatusPayload,
  UpdateSiteStatusSuccessPayload,
  FormattedErrors
>('Account tree / update site status');

export const BOX_ACCOUNT_TREE_ACCOUNT_TREE_FETCHED = createAction<AccountTree>(
  'Account tree / account tree fetched'
);
export const BOX_ACCOUNT_TREE_DIALOG_ERRORS_CLEARED = createAction(
  'Account tree / dialog / errors cleared'
);
export const BOX_ACCOUNT_TREE_DIALOG_OPENED = createAction(
  'Account tree / dialog / opened'
);
