import { useSelectorWithProps } from 'hooks';
import { userSafeSelector } from 'state/UsersCollection';

type Props = {
  user_id: string | null;
};

export const useUserName = ({ user_id }: Props) => {
  const user = useSelectorWithProps(userSafeSelector, user_id);

  return user?.prefered_or_full_name || 'Unassigned';
};
