import React, { Component } from 'react';
import {
  Col,
  LoadingOverlay,
  Pagination,
  Paragraph,
  Row,
  Text,
} from 'elmo-elements';
import {
  CallMadeIcon,
  CloseIcon,
  HelpOutlineIcon,
  InfoOutlinedIcon,
  IsAppMarket,
  SwapHorizOutlinedIcon,
  DSTIcon,
  NotesIcon,
  SimpleBadge,
} from 'element';
import { connect } from 'react-redux';
import {
  BOX_SHIFT_TRADES_CHANGE_PAGE,
  BOX_SHIFT_TRADES_CHANGE_PAGE_SIZE,
  BOX_SHIFT_TRADES_DECLINE_SHIFT_BY_PROPOSAL,
  BOX_SHIFT_TRADES_GET_SWAP_PROPOSALS,
} from 'state/EmployeeDashboard/ShiftTrades';
import { ShiftTrade, ShiftTradeProposal, ShiftTradeUser } from 'type';
import { StoreState } from 'state/types';
import {
  getSelectedFilter,
  proposalDecliningId,
  shiftTradesWithPagerSelector,
} from 'state/EmployeeDashboard/ShiftTrades/selectors';
import { getDateTimeFormatted, getPreferenceLabel } from 'lib/helpers';
import { DispatchProps, StateProps } from './types';
import {
  colProps,
  getFullName,
  getPlaceholderText,
  getPositionAreaLocation,
  getShiftTradeDuration,
  getTimezone,
} from '../../../../../../helpers';
import { hasPermissionSelector } from 'state/Auth';
import {
  getDateFormat,
  getLangPreferences,
  getTimeFormat,
} from 'state/Account';
import { EmptyMessage } from '../../../EmptyMessage';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from 'oxygen-elements';

type Props = DispatchProps & StateProps;

export class AppliedShifts extends Component<Props> {
  render() {
    const { shiftTrades, selectedFilter } = this.props;
    return (
      <div className={'applied-shifts-wrapper'}>
        {shiftTrades.total > 0 ? (
          this.showShifts()
        ) : (
          <EmptyMessage message={getPlaceholderText(selectedFilter)} />
        )}
      </div>
    );
  }

  showShifts = () => {
    const { shiftTrades, isDeclining } = this.props;
    return (
      <LoadingOverlay isLoading={isDeclining !== ''}>
        <div className={'applied-shifts-list'}>
          {shiftTrades.page.map((shift: ShiftTrade, index: number) => (
            <Card className="shift-swap-list-item" key={index}>
              <CardHeader
                className="card-header"
                avatar={this.getHeaderByType(shift).icon}
                title={this.getHeaderByType(shift).title}
                titleTypographyProps={{
                  paddingLeft: '12px',
                }}
                action={
                  <SimpleBadge
                    className={this.getHeaderByType(shift).badge.className}
                    icon={this.getHeaderByType(shift).badge.icon}
                    type={this.getHeaderByType(shift).badge.type}
                    label={this.getHeaderByType(shift).badge.label}
                  />
                }
              />
              {this.getCardContent(shift)}
              {shift.shift_trade_proposal_id !== null &&
                shift.type === 'swap' &&
                this.showProposal(shift)}
              <CardActions className="card-actions">
                {this.getCardActions(shift).map((action, key) => (
                  <Button
                    key={`btn-${action.label}`}
                    size="large"
                    onClick={action.onClick}
                  >
                    {action.icon} {action.label}
                  </Button>
                ))}
              </CardActions>
            </Card>
          ))}
        </div>
        {this.showPagination()}
      </LoadingOverlay>
    );
  };

  showProposal = (shift: ShiftTrade) => {
    const { shift_trade_proposal_id, proposals } = shift;
    let selectedProposal: ShiftTradeProposal | null = null;
    if (proposals) {
      proposals.forEach((p: ShiftTradeProposal) => {
        if (p.id === shift_trade_proposal_id) {
          selectedProposal = p;
        }
      });
    }
    return selectedProposal !== null
      ? this.getCardContent(selectedProposal)
      : null;
  };

  getStatus = (item: ShiftTrade) => {
    const status = () => {
      return item.type === 'swap' && item.status === 'new'
        ? 'AWAITING SELECTION'
        : 'AWAITING APPROVAL';
    };
    return {
      label: item.status === 'approved' ? 'APPROVED' : status(),
      type: 'warning' as any,
      className: 'shift-status',
      icon: <InfoOutlinedIcon />,
    };
  };

  getCardContent = (shift: ShiftTrade) => {
    if (shift === null || shift.rostered_shift === null) {
      return null;
    }
    const { langPreferences, dateFormat, timeFormat } = this.props;
    return (
      <>
        <CardHeader
          className="card-header"
          avatar={this.getUserAvatar(shift.rostered_shift.user)}
          title={getFullName(shift.rostered_shift.user)}
          titleTypographyProps={{
            fontSize: 16,
            fontWeight: 500,
          }}
        />
        <CardContent className="card-content">
          <Row>
            <Col {...colProps}>
              <Text color="gray" size="sm">
                {getPreferenceLabel(
                  langPreferences,
                  'role',
                  'singular',
                  '',
                  true
                )}
                - {getPreferenceLabel(langPreferences, 'area', 'singular')},{' '}
                {getPreferenceLabel(langPreferences, 'site', 'singular')}
              </Text>
              <Paragraph isTruncate={true}>
                {getPositionAreaLocation(shift.rostered_shift)}
              </Paragraph>
            </Col>
            <Col {...colProps}>
              <Text color="gray" size="sm">
                Date
              </Text>
              <div>
                {getDateTimeFormatted(
                  dateFormat,
                  timeFormat,
                  shift.rostered_shift.start
                )}
              </div>
            </Col>
            <Col {...colProps}>
              <Text color="gray" size="sm">
                Start - finish (duration){' '}
                <DSTIcon isDST={shift.rostered_shift.is_dst_intersect} />
              </Text>
              <div>
                {getShiftTradeDuration(timeFormat, shift.rostered_shift)}
              </div>
            </Col>
          </Row>
          <Row>
            <IsAppMarket market={'uk'} negate={true}>
              <Col className={colProps.className} xs={24} sm={colProps.sm}>
                <Text color="gray" size="sm">
                  Timezone
                </Text>
                <Paragraph isTruncate={true}>
                  {getTimezone(shift.rostered_shift)}
                </Paragraph>
              </Col>
            </IsAppMarket>
            <Col
              className={colProps.className}
              xs={24}
              sm={colProps.sm}
              span={3}
            >
              <Text color="gray" size="sm">
                Note
              </Text>
              <Paragraph>
                {shift.note && shift.note !== '' ? shift.note : '-'}
              </Paragraph>
            </Col>
            <Col
              className={colProps.className}
              xs={24}
              sm={(24 / 6) * 2}
              span={24 / 6}
            >
              <NotesIcon notes={shift.rostered_shift.notes} />
            </Col>
          </Row>
        </CardContent>
      </>
    );
  };

  showPagination = () => {
    const { shiftTrades } = this.props;
    return (
      <Pagination
        totalResults={shiftTrades.total}
        currentPage={shiftTrades.currentPage}
        pageSize={shiftTrades.pageSize}
        pageSizeOptions={[10, 20, 30]}
        onPageChange={this.props.changePage}
        onPageSizeChange={this.props.changePageSize}
      />
    );
  };

  getUserAvatar = (user: ShiftTradeUser) => {
    return user !== null ? (
      <Avatar
        alt={getFullName(user)}
        size={'small'}
        src={user.avatar_url}
        light
      />
    ) : (
      <Text color={'warning'}>
        <HelpOutlineIcon />
      </Text>
    );
  };

  getHeaderByType = (shift: ShiftTrade) => {
    return shift.type === 'offer'
      ? this.getOfferHeader(shift)
      : this.getSwapHeader(shift);
  };

  getOfferHeader = (shift: ShiftTrade) => {
    return {
      icon: <CallMadeIcon />,
      title: 'Shift offer',
      titleAddOn: ' ',
      badge: this.getStatus(shift),
    };
  };

  getSwapHeader = (shift: ShiftTrade) => {
    return {
      icon: <SwapHorizOutlinedIcon />,
      title: 'Shift swap',
      titleAddOn: ' ',
      badge: this.getStatus(shift),
    };
  };

  getCardActions = (trade: ShiftTrade) => {
    return [
      {
        icon: <CloseIcon />,
        onClick: () => {
          const { proposals } = trade;
          if (proposals && proposals.length) {
            this.props.cancelTradeByProposal({
              proposal_id: proposals[0].id,
              trade_id: trade.id,
            });
          }
        },
        label: 'Cancel',
      },
    ];
  };
}

const mapToDispatchProps: DispatchProps = {
  getProposals: BOX_SHIFT_TRADES_GET_SWAP_PROPOSALS,
  changePage: BOX_SHIFT_TRADES_CHANGE_PAGE,
  changePageSize: BOX_SHIFT_TRADES_CHANGE_PAGE_SIZE,
  cancelTradeByProposal: BOX_SHIFT_TRADES_DECLINE_SHIFT_BY_PROPOSAL,
};

const mapStateToProps = (state: StoreState): StateProps => ({
  shiftTrades: shiftTradesWithPagerSelector(state),
  isDeclining: proposalDecliningId(state),
  selectedFilter: getSelectedFilter(state),
  hasPermissions: hasPermissionSelector(
    state,
    'employeedashboard.viewshifttrades'
  ),
  langPreferences: getLangPreferences(state),
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
});

export default connect(mapStateToProps, mapToDispatchProps)(AppliedShifts);
