import React, { useCallback } from 'react';
import { PrintOutlinedIcon, SwitcherButton, PictureAsPdfOutlinedIcon, FileCopyOutlinedIcon } from 'element';
import { Button } from 'elmo-elements';
import { bindPopover, bindTrigger, usePopupState } from '../../../../../../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  BOX_COVID_TRACING_MODE_DOWNLOAD_REPORT,
  getCovidPointedShiftId, getPrintData
} from '../../../../../../../state/Roster/CovidTracing';
import { PrintTable } from './PrintTable';

type Props = {
  className?: string;
};

export const PrintButton = ({ className }: Props) => {
  const dispatch = useDispatch();
  const popupState = usePopupState({
    popupId: 'CovidTracingPrintPopover',
  });
  const pointedId = useSelector(getCovidPointedShiftId);

  const handleClick = useCallback((e: any, format: 'csv' | 'pdf' | 'json') => {
    dispatch(BOX_COVID_TRACING_MODE_DOWNLOAD_REPORT(format));
    popupState.close();
  }, [dispatch, pointedId]);

  return (
    <>
      <SwitcherButton
        icon={<PrintOutlinedIcon />}
        disableElevation
        bordered
        isUppercase={false}
        isDisabled={!pointedId}
        className={className}
        onClick={e => handleClick(e, 'json')}
        testId={'covid-print-report-button'}
      >
        Print report
      </SwitcherButton>

      <PrintTable />
    </>
  );
};
