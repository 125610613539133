import nanoid from 'nanoid';
import { DefaultBreakItem } from 'type';
import { DefaultBreaksFieldGroupBreak } from './types';

const makeDefaultBreakRuleField = (
  breakRule: DefaultBreakItem
): DefaultBreaksFieldGroupBreak => ({
  ...breakRule,
  id: nanoid(),
});

const makeDefaultBreakRule = ({
  id,
  ...breakRule
}: DefaultBreaksFieldGroupBreak): DefaultBreakItem => breakRule;

export const makeDefaultBreakRuleFields = (rules: DefaultBreakItem[]) =>
  rules.map(makeDefaultBreakRuleField);

export const makeDefaultBreakRules = (ruleFields: DefaultBreaksFieldGroupBreak[]) =>
  ruleFields.map(makeDefaultBreakRule);
