import { createSelector } from 'reselect';
import { Moment } from 'moment';
import {
  compact,
  filter,
  find,
  get,
  merge,
  partition,
  reduce,
  sortBy,
  times,
  values,
} from 'lodash';
import { RosteredShift, StringMap, Timesheet, UserFields } from 'type';
import { StoreState } from 'state/types';
import { nest } from 'state/helpers';
import { getAreas, getRoles } from 'state/AccountTree';
import { fromBySiteTimezoneSelector } from 'state/RosteredShifts';
import { userListSelector } from 'state/UsersCollection';
import {
  rosteredShiftsFilteredSelector,
  timesheetsFilteredSelector,
  filteredAreasSelector,
} from 'state/Roster/RosterFilters';
import {
  AreaRolePair,
  AreaRoleRosterTimesheet,
  AreaRoleWithCombinedShifts,
  AreaRoleWithShiftsWithCreated,
  AreasDuration,
  AreasRosterData,
  RosterDayViewCreatedShiftData,
  RosterDayViewCreatedShiftType,
  RosterPair,
  RosterPairWithCreatedShift,
  SiteViewData,
} from '../types';
import { calculateDuration, sortPair } from './helpers';
import { getCreatedShiftData, getSiteViewAdditionalRows } from './';
import _ from 'lodash';

const filteredRosteredShiftsByAreaIdByRoleId = createSelector<
  StoreState,
  RosteredShift[],
  StringMap<StringMap<RosteredShift[]>>
>(rosteredShiftsFilteredSelector, (rosteredShifts) =>
  nest(rosteredShifts, ['area_id', 'role_id'])
);

const filteredTimesheetsByAreaIdByRoleId = createSelector<
  StoreState,
  Timesheet[],
  StringMap<StringMap<Timesheet[]>>
>(timesheetsFilteredSelector, (timesheets) =>
  nest(timesheets, ['area_id', 'role_id'])
);

const areaRolePairsSelector = createSelector(
  filteredAreasSelector,
  getAreas,
  getRoles,
  (areaRolePairs, areas, roles) =>
    areaRolePairs.map((areaRole) => ({
      area: areas[areaRole.area_id],
      role: roles[areaRole.role_id],
    }))
);

export const areaRoleWithShifts = createSelector(
  areaRolePairsSelector,
  filteredRosteredShiftsByAreaIdByRoleId,
  filteredTimesheetsByAreaIdByRoleId,
  (
    areaRoleArray: AreaRolePair[],
    rosteredShiftsByAreaIdByRoleId: StringMap<StringMap<RosteredShift[]>>,
    timesheetsByAreaIdByRoleId: StringMap<StringMap<Timesheet[]>>
  ): AreaRoleRosterTimesheet[] =>
    areaRoleArray.map((areaRole) => {
      const areaId = areaRole.area.id;
      const roleId = areaRole.role.id;

      return {
        ...areaRole,
        data: {
          roster: get(rosteredShiftsByAreaIdByRoleId, [areaId, roleId], []),
          timesheets: get(timesheetsByAreaIdByRoleId, [areaId, roleId], []),
        },
      };
    })
);

export const areaRoleWithCombinedShifts = createSelector(
  areaRoleWithShifts,
  userListSelector,
  (
    data: AreaRoleRosterTimesheet[],
    users: StringMap<UserFields>
  ): AreaRoleWithCombinedShifts[] => {
    const config: AreaRoleWithCombinedShifts[] = data.map(
      ({ data: { roster, timesheets }, ...rest }) => {
        const [assignedRosteredShifts, unassignedRosteredShifts] = partition(
          roster,
          ({ user_id }) => !!user_id
        );

        const [assignedTimesheets, selfAssignedTimesheets] = partition(
          timesheets,
          ({ rostered_shift_id }) => !!rostered_shift_id
        );

        const fullPairs: RosterPair[] = reduce(
          assignedTimesheets,
          (accumulator: RosterPair[], timesheet: Timesheet): RosterPair[] => {
            const user = users[timesheet.user_id];
            const rosteredShift = find(
              assignedRosteredShifts,
              ({ id }) => id === timesheet.rostered_shift_id
            );

            return [
              ...accumulator,
              {
                timesheets: timesheet,
                rosteredShift,
                user,
              },
            ];
          },
          []
        );

        const selfAssignedPairs: RosterPair[] = selfAssignedTimesheets.map(
          (timesheet): RosterPair => {
            const user = users[timesheet.user_id];

            return {
              timesheets: timesheet,
              user,
            };
          }
        );

        const rosteredShiftIdsFromTimesheets: string[] = compact(
          assignedTimesheets.map(({ rostered_shift_id }) => rostered_shift_id)
        );

        const rosteredShiftWithoutTimesheets: RosteredShift[] = filter(
          assignedRosteredShifts,
          ({ id }) => !rosteredShiftIdsFromTimesheets.includes(id)
        );

        const rosteredShiftsPairs: RosterPair[] =
          rosteredShiftWithoutTimesheets.map((rosteredShift) => {
            const user = users[rosteredShift.user_id!];

            return {
              user,
              rosteredShift,
            };
          });

        const assignedPairs: RosterPair[] = [
          ...fullPairs,
          ...selfAssignedPairs,
          ...rosteredShiftsPairs,
        ];

        const sortedAssignedPairs: RosterPair[] = sortBy(
          assignedPairs,
          sortPair
        );

        const unassignedRosteredShiftPairs: RosterPair[] =
          unassignedRosteredShifts.map((rosteredShift) => ({ rosteredShift }));

        const sortedUnassignedShiftPairs: RosterPair[] = sortBy(
          unassignedRosteredShiftPairs,
          sortPair
        );

        const pairs: RosterPair[] = [
          ...sortedAssignedPairs,
          ...sortedUnassignedShiftPairs,
        ];

        return {
          ...rest,
          roleName: rest.role.name,
          isArchived:
            rest.area.archived ||
            rest.role.archived ||
            !rest.area.role_ids.includes(rest.role.id),
          pairs,
        };
      }
    );

    return _.sortBy(config, ['roleName']);
  }
);

export const areaRoleWithShiftsWithAdditionalRows = createSelector(
  areaRoleWithCombinedShifts,
  getSiteViewAdditionalRows,
  (
    shiftsData: AreaRoleWithCombinedShifts[],
    additionalRows: StringMap<StringMap<number>>
  ): AreaRoleWithCombinedShifts[] => {
    return shiftsData.map((areaRoleData) => {
      const areaId = areaRoleData.area.id;
      const roleId = areaRoleData.role.id;

      const additionalRowsQuantity = get(additionalRows, [areaId, roleId], 0);

      const notEmptyPairsAreaRoleData: AreaRoleWithCombinedShifts = {
        ...areaRoleData,
        pairs: areaRoleData.pairs.length ? areaRoleData.pairs : [{}],
      };

      if (additionalRowsQuantity) {
        return {
          ...notEmptyPairsAreaRoleData,
          pairs: [
            ...notEmptyPairsAreaRoleData.pairs,
            ...times(additionalRowsQuantity, (): RosterPair => ({})),
          ],
        };
      } else {
        return notEmptyPairsAreaRoleData;
      }
    });
  }
);

const getEmptyCreatedShift = (
  type: RosterDayViewCreatedShiftType
): RosterDayViewCreatedShiftData => ({
  shift: null,
  clientX: NaN,
  pairIndex: NaN,
  type,
});

export const areaRoleWithShiftsWithAdditionalRowsWithCreated = createSelector(
  areaRoleWithShiftsWithAdditionalRows,
  getCreatedShiftData,
  (
    shiftsData: AreaRoleWithCombinedShifts[],
    createdShiftData: RosterDayViewCreatedShiftData
  ): AreaRoleWithShiftsWithCreated[] => {
    return shiftsData.map(
      ({ pairs, ...rest }): AreaRoleWithShiftsWithCreated => {
        return {
          ...rest,
          pairs: pairs.map(
            (
              { rosteredShift, timesheets },
              pairIndex
            ): RosterPairWithCreatedShift => {
              const created: {
                [key in RosterDayViewCreatedShiftType]: RosterDayViewCreatedShiftData;
              } = {
                rosteredShift: getEmptyCreatedShift('rosteredShift'),
                timesheet: getEmptyCreatedShift('timesheet'),
              };

              const { shift } = createdShiftData;
              if (shift) {
                if (
                  shift.area_id === rest.area.id &&
                  shift.role_id === rest.role.id &&
                  createdShiftData.pairIndex === pairIndex
                ) {
                  created[createdShiftData.type] = createdShiftData;
                }
              }

              return {
                rosteredShift: !!rosteredShift
                  ? {
                      data: rosteredShift,
                      created: created.rosteredShift,
                      shiftWithEvent: !!rosteredShift.event_id,
                    }
                  : {
                      data: undefined,
                      shiftWithEvent: false,
                      created: created.rosteredShift,
                    },
                timesheets: !!timesheets
                  ? {
                      data: timesheets,
                      created: created.timesheet,
                    }
                  : {
                      data: undefined,
                      created: created.timesheet,
                    },
              };
            }
          ),
        };
      }
    );
  }
);

const roleDurations = createSelector(
  areaRoleWithShifts,
  fromBySiteTimezoneSelector,
  (data: AreaRoleRosterTimesheet[], from: Moment): AreasDuration[] =>
    data.map(({ data: { roster, timesheets } }) => ({
      duration: {
        roster: calculateDuration(roster, from),
        timesheets: calculateDuration(timesheets, from),
      },
    }))
);

export const areaRoleWithShiftsWithDuration = createSelector<
  StoreState,
  AreaRoleWithCombinedShifts[],
  AreasDuration[],
  AreasRosterData[]
>(
  areaRoleWithShiftsWithAdditionalRowsWithCreated as any, // TODO double-check
  roleDurations,
  merge
);

export const siteViewDataSelector = createSelector(
  areaRoleWithShiftsWithDuration,
  (areasRosterDataArray): SiteViewData[] => {
    const siteViewDataCollection: StringMap<SiteViewData> =
      areasRosterDataArray.reduce(
        (
          accumulator: StringMap<SiteViewData>,
          areasRosterData
        ): StringMap<SiteViewData> => {
          if (accumulator[areasRosterData.area.id]) {
            accumulator[areasRosterData.area.id].areasRosterData.push(
              areasRosterData
            );
          } else {
            accumulator[areasRosterData.area.id] = {
              area: areasRosterData.area,
              areasRosterData: [areasRosterData],
              areaName: areasRosterData.area.name,
            };
          }

          return accumulator;
        },
        {}
      );

    return _.sortBy(values(siteViewDataCollection), ['areaName']);
  }
);
