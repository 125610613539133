import React from 'react';

type Props = {
  placeholder: React.ReactNode;
  options: React.ReactNode;
  expanded: boolean;
  target: string;
  id?: string;
};

const GroupOptionsWrapper = ({
  expanded,
  placeholder,
  options,
  target,
  id
}: Props) => {
  return (
    <div
      className={'group-options-wrapper' + (expanded ? ' expanded' : '')}
      data-target={target}
      data-testid={id}
    >
      <div
        className={'group-options-wrapper__placeholder'}
        data-testid={id ? `${id}-placeholder` : undefined}
      >
        {placeholder}
      </div>
      <div className={'group-options-wrapper__options'}>{options}</div>
    </div>
  );
};

export const GroupOptions = GroupOptionsWrapper;
