import React from 'react';
import { LoadingOverlay } from 'elmo-elements';
import { UserRole } from 'type';
import { CustomDropdownOptionType, UserRoleFormatted } from 'element';
import { OnSelect, InlineInputWithLegend } from '../InputWithLegend';
import { Props } from './types';

export const UserShiftTimeRangeInputComponent = ({
  userRoles,
  roleSelected,
  day,
  user_id,
  isLoading,
  langPreferences
}: Props) => {
  const handleSelect: OnSelect<UserRole> = ({
    range,
    value: { area_id, role_id }
  }) => {
    roleSelected({
      user_id,
      area_id,
      role_id,
      range
    });
  };

  const userRolesOptions = userRoles.map(
    (userRole): CustomDropdownOptionType<UserRole> => ({
      value: userRole,
      label: <UserRoleFormatted role={userRole} />
    })
  );

  return (
    <LoadingOverlay isLoading={isLoading}>
      <InlineInputWithLegend
        options={
          userRolesOptions.length
            ? [
                {
                  label: `Select ${langPreferences.role.singular}`
                },
                ...userRolesOptions
              ]
            : []
        }
        day={day}
        onSelect={handleSelect as any}  // TODO double-check
      />
    </LoadingOverlay>
  );
};
