import React, { Children, ReactElement, ReactNode } from 'react';
import { isElementOfType } from '../../_lib/helper';
import ListTableTr from './ListTableTr';

export type ListTableBodyProps = {
  /** Contains an array of ListTableTr elements */
  children?: ReactNode;
};

function ListTableBody({ children }: ListTableBodyProps) {
  return (
    <>
      {Children.map(
        children as ReactElement<any>[],
        (child: ReactElement<any>, rowIndex: number) => {
          if (
            !React.isValidElement(child) ||
            !isElementOfType(child, ListTableTr)
          ) {
            return null;
          }

          return React.cloneElement(child as ReactElement<any>, {
            rowIndex: rowIndex,
          });
        }
      )}
    </>
  );
}

ListTableBody.displayName = 'ListTableBody';

export default ListTableBody;
