import React from 'react';
import { MessageBlock } from 'elmo-elements';

export const NotFoundMessage = () => {
  return (
    <MessageBlock>
      <MessageBlock.Header>There are no shifts offered</MessageBlock.Header>
    </MessageBlock>
  );
};
