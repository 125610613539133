import * as React from 'react';
import { CancelIcon } from '../../icons';
import { FileProps } from '../type';
import './File.scss';
import Button from '../../Button';

export const File: React.SFC<FileProps> = ({
  name,
  handleDelete,
  className,
}) => (
  <div className={`elmo-uploaded-file ${className}`}>
    <span>{name}</span>
    <Button icon={<CancelIcon />} onClick={handleDelete} />
  </div>
);
