import React from 'react';

type ContextValue = {
  isCreateOpen: boolean;
  isEditOpen: boolean;
  isOpen: boolean;
  openCreate: () => void;
  openEdit: (id: string) => void;
  getIsEditing: (id: string) => boolean;
  close: () => void;
};

const Context = React.createContext<ContextValue>({
  isCreateOpen: false,
  isEditOpen: false,
  isOpen: false,
  openCreate: () => {},
  openEdit: () => {},
  getIsEditing: () => false,
  close: () => {}
});

type Props = {
  children: React.ReactNode;
};

export function PayElementsListContextProvider({ children }: Props) {
  const [openForm, setOpenForm] =
    React.useState<'create' | 'edit' | null>(null);
  const [editId, setEditId] = React.useState('');

  const isCreateOpen = openForm === 'create';
  const isEditOpen = openForm === 'edit';
  const isOpen = isCreateOpen || isEditOpen;

  const openCreate = () => {
    setOpenForm('create');
  };

  const openEdit = (id: string) => {
    setOpenForm('edit');
    setEditId(id);
  };

  const getIsEditing = (id: string) => {
    return isEditOpen && editId === id;
  };

  const close = () => {
    setOpenForm(null);
  };

  return (
    <Context.Provider
      value={{
        isCreateOpen,
        isEditOpen,
        isOpen,
        openCreate,
        openEdit,
        close,
        getIsEditing,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function usePayElementsListContext() {
  return React.useContext(Context);
}
