import { SagaIterator } from 'redux-saga';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { CopyPasteJob, SagaAction } from 'type';
import { assertUnreachable, isAppMarket } from 'helpers/helpers';
import { Api, OVERLAP_ERROR_STATUS } from 'lib/Api';
import { formatError, getErrorStatus } from 'state/helpers';
import {
  processApiRequest,
  processApiRequestWithConfirm,
} from 'state/ProcessApiRequest';
import { hasPermissionSelector } from 'state/Auth';
import { BOX_TOAST_NOTIFIER_MESSAGE_SHOW } from 'state/ToastNotifier';
import { getDateFormat } from 'state/Account';
import {
  BOX_IS_LOADING_OFF,
  BOX_IS_LOADING_ON,
  ROSTER_COPY_PASTE_CONFIRMATION_MODAL,
  ROSTER_SHIFT_TEMPLATE,
} from 'state/IsLoading';
import {
  BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN,
  BOX_ROSTER_OVERLAP_CONFIRMATION_OPEN,
} from '../Roster';
import {
  BOX_ROSTER_PAST_RELOAD_NOTIFICATION_HIDE,
  watchReloadNotification,
} from './ReloadNotification';
import {
  ApplyRosterTemplatePayload,
  ApplyRosterTemplateResponse,
  CheckPastingStatusPayload,
  CopyPayload,
  DeleteTemplatePayload,
  GetTemplatesListPayload,
  PastRostersType,
  SaveTemplatePayload,
} from './type';
import { getCopyRosterData } from './selectors';
import * as actions from './actions';

export const copyWeek = function* ({
  payload: { range },
}: SagaAction<Required<CopyPayload>>): SagaIterator {
  const format = yield select(getDateFormat);
  yield put(
    BOX_TOAST_NOTIFIER_MESSAGE_SHOW(
      `Copied ${
        isAppMarket('uk') ? 'scheduled' : 'rostered'
      } week: ${range.from.format(format)} - ${range.to.format(format)}`
    )
  );
};

export const pasteWeek = function* ({
  payload,
}: SagaAction<PastRostersType>): SagaIterator {
  yield put(BOX_IS_LOADING_ON(ROSTER_COPY_PASTE_CONFIRMATION_MODAL));

  try {
    const data = yield select(getCopyRosterData);
    yield call(processApiRequest, Api.Roster.pasteWeek, {
      ...data,
      ...payload,
    });
    yield put(actions.BOX_ROSTER_PAST_WEEK_SUCCESS());
  } catch (error) {
    yield put(BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN(formatError(error)));
    yield put(actions.BOX_ROSTER_PAST_WEEK_FAILED());
  }

  yield put(BOX_IS_LOADING_OFF(ROSTER_COPY_PASTE_CONFIRMATION_MODAL));
};

export const getPastingStatusRequest = function* (
  payload: CheckPastingStatusPayload
): SagaIterator {
  yield put(BOX_ROSTER_PAST_RELOAD_NOTIFICATION_HIDE());

  const canEditRosteredShifts: ReturnType<typeof hasPermissionSelector> =
    yield select(hasPermissionSelector, 'roster.rosteredshift.edit');

  if (canEditRosteredShifts) {
    const response = yield call(
      processApiRequest,
      Api.Roster.checkPastingStatus,
      payload
    );

    yield put(actions.BOX_ROSTER_PAST_JOBS_RECEIVED(response));
  }
};

export const saveTemplate = function* ({
  payload,
}: SagaAction<SaveTemplatePayload>): SagaIterator {
  yield put(BOX_IS_LOADING_ON(ROSTER_SHIFT_TEMPLATE));
  try {
    const response = yield call(
      processApiRequestWithConfirm,
      Api.Roster.saveTemplate,
      payload
    );
    yield put(actions.BOX_ROSTER_CLOSE_SAVE_TEMPLATE_MODAL());
    yield put(actions.BOX_ROSTER_SAVE_TEMPLATE_SUCCESS());
    yield put(BOX_IS_LOADING_OFF(ROSTER_SHIFT_TEMPLATE));
  } catch (e) {
    const errorStatus = getErrorStatus(e);
    if (errorStatus === OVERLAP_ERROR_STATUS) {
      yield put(
        BOX_ROSTER_OVERLAP_CONFIRMATION_OPEN(formatError(e)[0].split('\n'))
      );
    } else {
      yield put(actions.BOX_ROSTER_SAVE_TEMPLATE_FAILURE(formatError(e)));
    }
  }
};

export const getTemplatesList = function* ({
  payload,
}: SagaAction<GetTemplatesListPayload>): SagaIterator {
  try {
    const response = yield call(
      processApiRequest,
      Api.Roster.getTemplatesList,
      payload
    );
    yield put(actions.BOX_ROSTER_GET_TEMPLATES_LIST_SUCCESS(response));
  } catch (e) {
    yield put(actions.BOX_ROSTER_GET_TEMPLATES_LIST_FAILURE(formatError(e)));
  }
};

export const updatingJobs = function* ({
  payload: { status, task_id },
}: SagaAction<CopyPasteJob>) {
  switch (status) {
    case 'processing':
      yield put(actions.BOX_ROSTER_PAST_JOB_ADD(task_id));
      break;
    case 'completed':
    case 'failed':
      // yield put(BOX_ROSTER_PAST_RELOAD_NOTIFICATION_SHOW());
      yield put(actions.BOX_ROSTER_PAST_JOB_DELETE(task_id));
      // yield put(BOX_ROSTER_UPDATE_ROSTER_DATA_REQUEST());
      break;
    default:
      assertUnreachable(status);
  }
};

export const deleteTemplate = function* ({
  payload,
}: SagaAction<DeleteTemplatePayload>): SagaIterator {
  try {
    yield call(processApiRequest, Api.Roster.deleteTemplate, payload);
    yield put(actions.BOX_ROSTER_DELETE_TEMPLATE_SUCCESS(payload));
  } catch (e) {
    yield put(actions.BOX_ROSTER_DELETE_TEMPLATE_FAILURE(formatError(e)));
  }
};

export const applyTemplate = function* ({
  payload,
}: SagaAction<ApplyRosterTemplatePayload>) {
  try {
    const response: ApplyRosterTemplateResponse = yield call(
      processApiRequest,
      Api.Roster.applyTemplate,
      payload
    );
    yield put(actions.BOX_ROSTER_APPLY_TEMPLATE_SUCCESS(response));
    yield put(
      actions.BOX_ROSTER_PAST_JOB_RECEIVED({
        status: response.status,
        task_id: response.task_id,
      })
    );
  } catch (e) {
    yield put(actions.BOX_ROSTER_APPLY_TEMPLATE_FAILURE(formatError(e)));
  }
};

export const watchRosterCopyPast = function* (): SagaIterator {
  yield fork(watchReloadNotification);
  yield takeLatest(actions.BOX_ROSTER_COPY_WEEK, copyWeek as any); // TODO double-check
  yield takeLatest(actions.BOX_ROSTER_PAST_WEEK_REQUEST, pasteWeek);
  yield takeLatest(actions.BOX_ROSTER_SAVE_TEMPLATE as any, saveTemplate); // TODO double-check
  yield takeLatest(actions.BOX_ROSTER_PAST_JOB_RECEIVED, updatingJobs);
  yield takeLatest(actions.BOX_ROSTER_GET_TEMPLATES_LIST, getTemplatesList);
  yield takeLatest(actions.BOX_ROSTER_DELETE_TEMPLATE, deleteTemplate);
  yield takeLatest(actions.BOX_ROSTER_APPLY_TEMPLATE, applyTemplate);
};
