import { FetchContainer } from 'element';
import React from 'react';
import {
  BOX_PAY_CONDITIONS_PAGE_DATA_GET,
  getPageFetchErrors,
  getPageFetching,
} from 'state/PayConditionsPage';

type Props = {
  children: React.ReactNode;
};

export default FetchPageData;

export function FetchPageData({ children }: Props) {
  const action = React.useMemo(
    () => BOX_PAY_CONDITIONS_PAGE_DATA_GET.request(),
    []
  );

  return (
    <FetchContainer
      action={action}
      loadingSelector={getPageFetching}
      errorsSelector={getPageFetchErrors}
    >
      {children}
    </FetchContainer>
  );
}
