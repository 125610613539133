import React, { Component, ComponentProps } from 'react';
import { connect } from 'react-redux';
import { map } from 'lodash';
import { Duration, Moment } from 'moment';
import { Event } from 'type';
import { StoreState } from 'state/types';
import { hasPermissionSelector } from 'state/Auth';
import {
  fromBySiteTimezoneSelector,
  getSiteId,
  toBySiteTimezoneSelector,
} from 'state/RosteredShifts';
import {
  BOX_EVENT_MODAL_OPEN,
  GetEventByIdPayload,
} from 'state/Roster/EventModal';
import { DayViewEventRow, eventRowsSelector } from 'state/Roster/RosterDayView';
import { Timeline } from 'page/Roster/page/RosterDayView/components/Timeline';
import { DayEvent } from './components/DayEvent';

type OwnProps = {};

type StateProps = {
  eventRows: DayViewEventRow[];
  siteId: string;
  hasEditPermission: boolean;
  from: Moment;
  to: Moment;
};

type DispatchProps = {
  openEventModal: (payload: GetEventByIdPayload) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

class DayEventsTimelineComponent extends Component<Props> {
  render() {
    return <Timeline rows={this.rows} className={'events-timeline'} />;
  }

  private get rows(): ComponentProps<typeof Timeline>['rows'] {
    const { eventRows } = this.props;

    return map(
      eventRows,
      ({
        events,
      }: DayViewEventRow): ComponentProps<typeof Timeline>['rows'][number] => ({
        content: events.length
          ? events.map((event: Event) => (
              <DayEvent key={event.id} event={event} />
            ))
          : null,
        type: 'full',
        onDoubleClick: this.addNewEvent,
        isTall: false,
      })
    );
  }

  private addNewEvent = (duration: Duration) => {
    const { hasEditPermission, siteId, openEventModal, from, to } = this.props;

    if (hasEditPermission) {
      openEventModal({
        siteId,
        start: from.clone().set({ hour: duration.hours() }),
        end: to.clone().set({ hour: duration.hours() + 1, minute: 0 }),
      });
    }
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  eventRows: eventRowsSelector(state),
  siteId: getSiteId(state),
  hasEditPermission: hasPermissionSelector(state, 'roster.events.edit'),
  from: fromBySiteTimezoneSelector(state),
  to: toBySiteTimezoneSelector(state),
});

const mapDispatchToProps: DispatchProps = {
  openEventModal: BOX_EVENT_MODAL_OPEN,
};

export const DayEventsTimeline = connect(
  mapStateToProps,
  mapDispatchToProps
)(DayEventsTimelineComponent);
