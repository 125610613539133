import React from 'react';
import ContextualMenu from '../../ContextualMenu';

type Props = {
  templateShiftId: string;
  elementId: string;
};

export const TemplateContextualMenu = (props: Props) => (
  <ContextualMenu
    elementId={props.elementId}
    objectId={props.templateShiftId}
    type="edit"
    shiftType="shift_item"
    currentCellData={null}
  />
);
