import React, { Component } from 'react';
import { Button, Row, Col, Divider, Tooltip } from 'elmo-elements';
import {
  MonetizationOnOutlinedIcon,
  UndoOutlinedIcon,
  DeleteOutlinedIcon,
  CheckOutlinedIcon,
  AlarmOutlinedIcon,
  CallMadeIcon,
  MoreHorizOutlinedIcon,
} from 'element/icons';
import { RosteredShiftSettings } from 'type/models';
import moment, { Moment } from 'moment';
import ContextualMenu from '../../../../../page/Roster/components/ContextualMenu';
import { CurrentRosteredShift } from 'state/Roster/RosterShiftModal/types';
import { convertDecimalToFormattedTime } from 'lib/helpers';

export type Props = {
  shift: CurrentRosteredShift;
  isUpdating: boolean;
  openSubModal: (name: string) => void;
  deleteRosteredShift: () => void;
  undoRosteredShift: () => void;
  settings: RosteredShiftSettings;
  acceptShift: () => void;
  notifyUser: () => void;
  offerShift: () => void;
  currentTimeBySiteTimezone: Moment;
  shiftCosting: boolean;
};

type State = {
  acceptenceTime: string;
};

export class ModalActions extends Component<Props, State> {
  interval: any = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      acceptenceTime: `Remaining time: 0h 0m`,
    };
  }

  render() {
    const {
      shift: { id, user_id, is_published, is_accepted, status, is_pasted },
      isUpdating,
      settings,
      shiftCosting,
    } = this.props;
    return (
      <div className="rostered-shift-modal__actions">
        {this.isAvailableAction() &&
          user_id !== null &&
          is_published &&
          !is_pasted &&
          !is_accepted && (
            <Button
              id={'notify-tab-btn'}
              isText={true}
              icon={<AlarmOutlinedIcon />}
              isDisabled={isUpdating}
              onClick={this.props.notifyUser}
              className={'rostered-shift-modal__actions--notify'}
              ariaLabel="Notify user"
              tabIndex={0}
            />
          )}
        {this.isAvailableAction() &&
          is_published &&
          !is_pasted &&
          user_id !== null &&
          !is_accepted &&
          this.getAcceptenceBtn()}
        {this.isAvailableAction() &&
          user_id === null &&
          is_published &&
          settings.shift_offers &&
          status !== 'offer' &&
          status !== 'offer awaiting approval' &&
          this.props.currentTimeBySiteTimezone.isBefore(
            this.props.shift.start
          ) && (
            <Button
              id={'offer-tab-btn'}
              isText={true}
              icon={<CallMadeIcon />}
              isDisabled={isUpdating}
              onClick={this.props.offerShift}
              className={'rostered-shift-modal__actions--offer'}
              ariaLabel="Offer"
              tabIndex={0}
            />
          )}
        {this.isAvailableAction() && !is_published && !is_pasted && (
          <Button
            id={'undo-tab-btn'}
            isText={true}
            icon={<UndoOutlinedIcon />}
            isDisabled={isUpdating}
            onClick={this.props.undoRosteredShift}
            className={'rostered-shift-modal__actions--undo'}
            ariaLabel="Undo"
            tabIndex={0}
          />
        )}
        {this.isAvailableAction() && !is_pasted && (
          <Button
            id={'delete-tab-btn'}
            isText={true}
            icon={<DeleteOutlinedIcon />}
            onClick={this.props.deleteRosteredShift}
            isDisabled={isUpdating || id === null || id === ''}
            className={'rostered-shift-modal__actions--delete'}
            ariaLabel="Remove"
            tabIndex={0}
          />
        )}
        {
          <div className={'mode-options'}>
            <ContextualMenu
              asButton={true}
              objectId={id}
              elementId={`card-${id}`}
              currentCellData={this.props.shift}
              type={'edit'}
              shiftType={'rostered_shift'}
              icon={<MoreHorizOutlinedIcon />}
            />
          </div>
        }
        <Divider />
      </div>
    );
  }

  isAvailableAction = () => {
    const { id } = this.props.shift;
    return id !== null && id !== '';
  };

  componentDidMount(): void {
    this.interval = setInterval(this.showTimer, 1000);
  }

  componentWillUnmount(): void {
    if (this.interval !== null) {
      clearInterval(this.interval);
    }
  }

  showTimer = () => {
    const { settings, shift } = this.props;
    const { updated_at } = shift;
    let time = '00:00';
    const timeTo = moment
      .parseZone(updated_at)
      .add(+settings.accept_shifts_limit, 'hours');
    const mins = timeTo.diff(moment.parseZone(), 'minutes');
    if (
      settings.accept_shifts &&
      settings.accept_shifts_limit > 0 &&
      mins > 0
    ) {
      time = convertDecimalToFormattedTime((mins / 60).toString(), true);
      this.setState({
        acceptenceTime: `Remaining time: ${time}`,
      });
    } else {
      this.setState({
        acceptenceTime: `Remaining time: 0h 0m`,
      });
    }
  };

  getAcceptenceBtn = () => {
    const { isUpdating } = this.props;
    return (
      <Tooltip title={this.state.acceptenceTime} id={'accept-tab-btn-tooltip'}>
        <Button
          id={'accept-tab-btn'}
          isText={true}
          icon={<CheckOutlinedIcon />}
          isDisabled={isUpdating}
          onClick={this.props.acceptShift}
          className={'rostered-shift-modal__actions--accept'}
          ariaLabel="Accept"
          tabIndex={0}
        />
      </Tooltip>
    );
  };
}
