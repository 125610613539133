import _ from 'lodash';
import { RostersToPublishPayload } from 'state/Roster/Roster';
import { PublishWeek } from '../types';

export const getRostersToPublish = (weeks: PublishWeek) => {
  const payload: RostersToPublishPayload[] = [];
  let totalRostersNumber = 0;

  for (let weekId in weeks) {
    const week = weeks[weekId];
    const roster: any = {
      roster_id: weekId,
      areas: [],
    };
    let publishAll = false;

    for (let areaId in week.areas) {
      let uncheckedAreasNumber = 0;
      _.forEach(week.areas, (value, key) => {
        if (!week.areas[key]) {
          uncheckedAreasNumber++;
        }
      });
      if (uncheckedAreasNumber === 0) {
        totalRostersNumber += 1;
        publishAll = true;
        break;
      }
      if (uncheckedAreasNumber > 0 && week.areas[areaId]) {
        roster.areas.push(areaId);
      }
    }

    if (publishAll || roster.areas.length) {
      payload.push(roster);
    }
  }

  if (totalRostersNumber === Object.keys(weeks).length) {
    return null;
  }

  return payload;
};
