import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import { siteViewDataSelector } from 'state/Roster/RosterDayView';
import { SiteViewData } from 'state/Roster/RosterDayView/types';
import { DayDivider, DayForecastsAndEvents } from '../../components';
import { DaySiteRow } from './components';
import RosterSiteViewHeading from 'page/Roster/components/RosterSiteViewHeading';

type OwnProps = {};

type StateProps = {
  siteViewData: SiteViewData[];
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

const RosterDayViewSiteViewView = ({ siteViewData }: Props) => {
  return (
    <DayDivider>
      <DayForecastsAndEvents />

      {siteViewData.map(({ area, areasRosterData }) => (
        <Fragment key={area.id}>
          <RosterSiteViewHeading title={area.name} show={true} />

          {areasRosterData.map(rosterData => (
            <DaySiteRow
              key={rosterData.area.id + rosterData.role.id}
              rosterData={rosterData}
            />
          ))}
        </Fragment>
      ))}
    </DayDivider>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  siteViewData: siteViewDataSelector(state)
});

// const mapDispatchToProps: DispatchProps = {};

export default connect(mapStateToProps)(RosterDayViewSiteViewView);
