import React, { memo } from 'react';
import { useMakeSelector, useSelectorWithProps } from 'hooks';
import { RosterCell } from '../../../../../../../../../../components';
import {
  makeItemAreaIdRoleIdSelector,
  makeSitePairTimesheetIdSelector,
} from 'state/Roster/RosterWeekViewSiteView';
import { useSelector } from 'react-redux';
import { getSiteTimesheetUserId } from 'state/TimesheetsCollection';
import { TimesheetWeekCellBlock } from '../../../../../../../../components/WeekCellBlock';

type Props = {
  siteRowIndex: number;
  pairRowIndex: number;
  weekDayIndex: number;
};

export const TimesheetCell = memo(function TimesheetCell(props: Props) {
  const pairTimesheetIdSelector = useMakeSelector(
    makeSitePairTimesheetIdSelector
  );

  const timesheetId = useSelectorWithProps(pairTimesheetIdSelector, props);
  const itemAreaIdRoleIdSelector = useSelector(makeItemAreaIdRoleIdSelector);
  const itemAreaIdRoleId = useSelectorWithProps(itemAreaIdRoleIdSelector, props);
  const itemUserId = useSelectorWithProps(getSiteTimesheetUserId, timesheetId);

  return (
    <RosterCell
      type={'bottom'}
      label={'+ Add Timesheet'}
      labelOnHover={true}
      fontWeight={'normal'}
    >
      <TimesheetWeekCellBlock
        rowNumber={props.pairRowIndex}
        weekDayIndex={props.weekDayIndex}
        userId={itemUserId}
        menuType={'create'}
        timesheetId={timesheetId}
        viewType={'site'}
        areaId={itemAreaIdRoleId.area_id}
        roleId={itemAreaIdRoleId.role_id}
      />
    </RosterCell>
  );
});
