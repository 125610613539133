import { Moment } from 'moment';
import React from 'react';
import { usePlaceholderCard } from './hooks';
import { ShiftCardView, ShiftCardViewProps } from './views';

type PlaceholderCardProps = Pick<Required<ShiftCardViewProps>, 'shape'> & {
  from: Moment;
};

export function PlaceholderCard(props: PlaceholderCardProps) {
  const viewProps = usePlaceholderCard(props);

  return <ShiftCardView {...viewProps} />;
}
