import React, { Children, Component, ReactElement } from 'react';
import { DataTableElement } from '../type';
import { childrenIsEqual, getClass } from '../../_lib/helper';
import { withDataTableContext } from '../DataTableContextConsumer';
import './DataTableBody.scss';

type DataTableBodyProps = DataTableElement & {
  /** @ignore From DataTable Context. */
  setContextState?: (state: any) => void;
  /** @Ignore From DataTable Context */
  updateSelectAllCheckboxState: (isSelected?: boolean) => void;
};

/**
 * DataTableBody should contain the DataTable.Tr Row components
 */
class DataTableBody extends Component<DataTableBodyProps> {
  static displayName = 'DataTableBody';

  componentDidMount() {
    const { setContextState, children } = this.props;

    if (setContextState) {
      setContextState({
        rowCount: Children.count(children),
      });
    }
  }

  componentDidUpdate(prevProps: DataTableBodyProps) {
    const { children } = this.props;
    if (!childrenIsEqual(children, prevProps.children)) {
      this.props.updateSelectAllCheckboxState();
    }
  }

  renderContent() {
    const { children } = this.props;

    return Children.map(
      children as React.ReactElement<any>[],
      (child: ReactElement<any>, rowIndex: number) => {
        return this.addRowProps(child, rowIndex);
      }
    );
  }

  /**
   * Attaches props that the body knows about to the row components
   * @param child
   * @param rowIndex
   */
  addRowProps(child: ReactElement<any>, rowIndex: number) {
    return React.cloneElement(child, {
      rowIndex: rowIndex,
    });
  }

  render() {
    const { className } = this.props;

    return (
      <tbody className={getClass('elmo-datatable__body', className)}>
        {this.renderContent()}
      </tbody>
    );
  }
}

export default withDataTableContext(DataTableBody);
