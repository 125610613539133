import { AccountTreeArea, AccountTreeRole, StringMap } from '../../../../type';
import { UnavailabilityData } from './types';
import { isAppMarket } from '../../../../helpers';

export const getUnavailabilityData = (params: {
  tree: {
    areas: StringMap<AccountTreeArea>;
    roles: StringMap<AccountTreeRole>;
  };
  area_id: string;
  role_id: string;
}): UnavailabilityData => {
  const area: AccountTreeArea | undefined = params.tree.areas[params.area_id];
  const role: AccountTreeRole | undefined = params.tree.roles[params.role_id];

  const rosteredLabel = isAppMarket('uk') ? 'Scheduled' : 'Rostered';
  const noPermissions: boolean = !area || !role;
  const title = noPermissions ? rosteredLabel : role.name;
  const areaName = noPermissions ? '' : area.name;

  return {
    title,
    areaName,
    noPermissions
  };
};
