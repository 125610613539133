import { call } from 'redux-saga/effects';
import { Handler } from './types';

const shiftNotCreatedHandler = <
  P extends {
    rostered_shift: { action: 'create' | string };
    timesheet: object | null;
  }
>(
  next: Handler<P>
): Handler<P> =>
  function* (payload) {
    if (payload.rostered_shift.action !== 'create' || payload.timesheet) {
      return;
    }

    yield call(next, payload);
  };

export default shiftNotCreatedHandler;
