import {
  IconButton as OxygenIconButton,
  IconButtonProps,
} from 'oxygen-elements';
import React from 'react';

export type { IconButtonProps };

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  function IconButton(props, ref) {
    const handleClick: IconButtonProps['onClick'] = (event) => {
      if (!props.loading) {
        props.onClick?.(event);
      }
    };

    return <OxygenIconButton {...props} onClick={handleClick} ref={ref} />;
  }
) as typeof OxygenIconButton;

export default IconButton;
