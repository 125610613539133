import React from 'react';
import { Dropdown } from '../../Dropdown';
import './PaginationDropdown.scss';
import { PAGINATION_OPTIONS } from '../../_lib/const';

export type PaginationDropdownProps = {
  /** Number of items per page */
  pageSize: number;
  /** Options for the dropdown to change the number of items per page */
  pageSizeOptions?: number[];
  /** Callback for when the number of items per page is changed */
  onPageSizeChange: (pageSize: number) => void;
};

function PaginationDropdown({
  pageSize,
  pageSizeOptions,
  onPageSizeChange,
}: PaginationDropdownProps) {
  return (
    <Dropdown
      text={pageSize}
      isInline={true}
      ariaLabel="Menu of the number of displayed records"
    >
      {pageSizeOptions &&
        pageSizeOptions.map((pageSizeOption: number, key: number) => {
          return (
            <Dropdown.Item
              key={key}
              onClick={onPageSizeChange}
              value={pageSizeOption}
            >
              {pageSizeOption}
            </Dropdown.Item>
          );
        })}
    </Dropdown>
  );
}

PaginationDropdown.defaultProps = {
  pageSizeOptions: PAGINATION_OPTIONS,
};

export default PaginationDropdown;
