import { createAction } from 'lib/store-utils';
import {
  BulkCreateActionData,
  DragDropSuccess,
  RosteredShiftsDeletePayload,
  RosteredShiftsGetSuccessPayload,
  UndoListPayload,
  UndoRostersPayload
} from './types';
import { FormattedErrors, RosteredShift, Timesheet } from '../../type/models';
import { StringMap } from 'type';
import { UpdatePayload } from '../Roster/RosterShiftModal/types';
import { BulkRosteredShiftsRequest, RosteredShiftCreateRequest, RosteredShiftUpdateRequest } from 'lib/Api/type';

export const BOX_ROSTERED_SHIFTS_GET_SUCCESS = createAction<RosteredShiftsGetSuccessPayload>('Rostered shifts / get success');
export const BOX_ROSTERED_SHIFTS_ADD = createAction<StringMap<RosteredShift>>('Rostered shifts / add(or replace) shifts');
export const BOX_ROSTERED_SHIFTS_ADD_BY_SOCKET = createAction<StringMap<RosteredShift>>('Rostered shifts / add(or replace) shifts');
export const BOX_ROSTERED_SHIFTS_ADD_ONE = createAction<RosteredShift>('Rostered shifts / add(or replace) shift');
export const BOX_ROSTERED_SHIFTS_DELETE = createAction<RosteredShiftsDeletePayload>('Rostered shifts / delete shift');
export const BOX_ROSTERED_SHIFTS_DELETE_SHIFTS = createAction<string[]>('Rostered shifts / delete shifts');

export const BOX_ROSTERED_SHIFTS_UPDATE_CONNECTED_TIMESHEET = createAction<Timesheet>('Rostered shifts / update roster with connected timesheet id');
export const BOX_ROSTERED_SHIFTS_REMOVE_CONNECTED_TIMESHEET = createAction<string>('Rostered shifts / remove timesheet from rostered shift');

export const BOX_ROSTERED_SHIFT_GLOBAL_UNDO_SHIFT = createAction<UndoRostersPayload>('Rostered shifts / global undo action');
export const BOX_ROSTERED_SHIFT_GLOBAL_UNDO_SHIFT_SUCCESS = createAction('Rostered shifts / global undo action success');
export const BOX_ROSTERED_SHIFT_GLOBAL_UNDO_SHIFT_FAILURE = createAction<FormattedErrors>('Rostered shifts / global undo action failure');

export const BOX_ROSTERED_SHIFTS_UNDO_LIST = createAction<UndoListPayload>('Rostered shifts / get undo list');
export const BOX_ROSTERED_SHIFTS_UNDO_LIST_SUCCESS = createAction<any>('Rostered shifts / get undo list success');
export const BOX_ROSTERED_SHIFTS_UNDO_LIST_FAILURE = createAction<FormattedErrors>('Rostered shifts / get undo list failure');

export const BOX_ROSTERED_SHIFTS_UNDO_MODAL_CLEAR_ERRORS = createAction('Rostered shifts undo / remove all errors');

export const BOX_ROSTERED_SHIFTS_SET_DAY = createAction<string>('Rostered shifts / set day of calendar');

export const BOX_ROSTERED_SHIFT_SET_DRAGGABLE_SHIFT = createAction<null | Partial<RosteredShift>>('Rostered shifts / set draggable shift');
export const BOX_ROSTERED_SHIFT_SET_DRAGGABLE_SHIFT_BY_ID = createAction<null | { id: string }>('Rostered shifts / set draggable shift by id');
export const BOX_ROSTERED_SHIFT_SET_STRETCHING_SHIFT_BY_ID = createAction<null | { id: string }>('Rostered shifts / set stretching shift by id');
export const BOX_ROSTERED_SHIFT_SET_STRETCHING_SHIFT = createAction<null | Partial<RosteredShift>>('Rostered shifts / set stretching shift');

export const BOX_ROSTERED_SHIFT_DRAG_N_DROP = createAction<Partial<UpdatePayload>>('Rostered shifts / drag n drop  apply');
export const BOX_ROSTERED_SHIFT_DRAG_N_DROP_SUCCESS = createAction<DragDropSuccess>('Rostered shifts / drag n drop apply success');
export const BOX_ROSTERED_SHIFT_DRAG_N_DROP_SUCCESS_COPY = createAction<StringMap<RosteredShift>>('Rostered shifts / drag n drop apply success copy');
export const BOX_ROSTERED_SHIFT_DRAG_N_DROP_FAILURE = createAction<FormattedErrors>('Rostered shifts / drag n drop apply failure');

export const BOX_ROSTERED_SHIFT_CONFIRM_OVERLAP = createAction('Rostered shifts / confirm overlap');
export const BOX_ROSTERED_SHIFT_OPEN_OVERLAP_MODAL = createAction<FormattedErrors>('Rostered shifts / open overlap');
export const BOX_ROSTERED_SHIFT_CLOSE_OVERLAP_MODAL = createAction<boolean>('Rostered shifts / close overlap');

export const BOX_ROSTERED_SHIFT_CREATE = createAction<RosteredShiftCreateRequest>('Rostered shifts / create shift');
export const BOX_ROSTERED_SHIFT_UPDATE = createAction<RosteredShiftUpdateRequest>('Rostered shifts / update shift');

export const BOX_ROSTERED_SHIFT_SET_DROP_INPROGRESS = createAction<boolean>('Rostered shifts / set drop in progress state');
export const BOX_ROSTERED_SHIFT_SET_DROP_CANCELED = createAction<boolean>('Rostered shifts / set drop canceled state');

export const BOX_ROSTERED_SHIFTS_RESET_DAY_TO_CURRENT = createAction('Rostered shifts / reset day');

export const BOX_ROSTERED_SHIFTS_SET_BULK_PROPS = createAction<{ drop: boolean, isUpdating: boolean}>('Rostered shifts / set multiple props');

export const BOX_ROSTERED_SHIFTS_BULK_CREATE = createAction<BulkRosteredShiftsRequest>('Rostered shifts / bulk create');
export const BOX_ROSTERED_SHIFTS_BULK_CREATE_SUCCESS = createAction('Rostered shifts / bulk create success');
export const BOX_ROSTERED_SHIFTS_BULK_CREATE_FAILURE = createAction<FormattedErrors>('Rostered shifts / bulk create failure');

export const BOX_ROSTERED_SHIFT_BULK_ON_HOLD_RESIZER = createAction<BulkCreateActionData>('Rostered shifts / set shift data when hold and move resizer');
