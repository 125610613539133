import React from 'react';
import moment, { Moment } from 'moment';
import { DateType } from 'type';
import { SERVER_DAY_FORMAT } from 'lib/config';

export const parseDay = (day: DateType) => moment.utc(day, SERVER_DAY_FORMAT);
export const formatDay = (dayMoment: Moment) =>
  dayMoment.format(SERVER_DAY_FORMAT) as DateType;

const rangeDuration = {
  week: 1,
};

const rangeTypes = {
  next: 'add',
  prev: 'subtract',
} as const;

export const useDateRange = ({ day }: { day: DateType }) =>
  React.useMemo(() => {
    const dayMomentUtc = parseDay(day);
    const from = dayMomentUtc.clone().startOf('isoWeek');
    const to = from.clone().add(rangeDuration).subtract(1, 'minute');

    const dateRange = { from, to };

    const getRange = (rangeType: keyof typeof rangeTypes) =>
      dayMomentUtc.clone()[rangeTypes[rangeType]](rangeDuration);

    return { dateRange, getRange };
  }, [day]);
