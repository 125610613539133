import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fromBySiteTimezoneSelector } from 'state/RosteredShifts';
import {
  BOX_ROSTER_DAY_VIEW_CREATE_SHIFT_END,
  CreateShiftEndPayload,
  RosterDayViewCreatedShift,
} from 'state/Roster/RosterDayView';
import { PlaceholderCard, RosterCellType } from 'page/Roster/components';
import { ResizableWrapper } from '../ResizableWrapper';

type Props = {
  cellType: RosterCellType;
  shift: RosterDayViewCreatedShift;
  clientX: number;
};

export function DayCreatedShift({ cellType, shift, clientX }: Props) {
  const dispatch = useDispatch();
  const handleResizeEnd = React.useCallback(
    (payload: CreateShiftEndPayload) => {
      dispatch(BOX_ROSTER_DAY_VIEW_CREATE_SHIFT_END(payload));
    },
    [dispatch]
  );
  const day = useSelector(fromBySiteTimezoneSelector);

  return (
    <ResizableWrapper
      start={shift.start}
      end={shift.start}
      day={day}
      id={shift.id}
      clientX={clientX}
      onResizeEnd={handleResizeEnd}
    >
      <PlaceholderCard shape={cellType} from={shift.start} />
    </ResizableWrapper>
  );
}
