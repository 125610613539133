import React, { Component } from 'react';
import { connect } from 'react-redux';
import { map, mapValues } from 'lodash';
import { Dictionary } from 'ts-essentials';
import { Col, Row, Text } from 'elmo-elements';
import {
  LanguagePreferences,
  StringMap,
  TimeOff,
  TimeOffType,
  UserFields,
} from 'type';
import { StoreState } from 'state/types';
import { currentUserSiteIdsSelector, hasPermissionSelector } from 'state/Auth';
import {
  getDateFormat,
  getLangPreferences,
  timeFormatSelector,
} from 'state/Account';
import {
  BOX_UNAVAILABILITY_APPROVE_REQUEST,
  BOX_UNAVAILABILITY_DECLINE_REQUEST,
  BOX_UNAVAILABILITY_DELETE_MODAL_OPEN,
  BOX_UNAVAILABILITY_EDIT_MODAL_OPEN,
  BOX_UNAVAILABILITY_UNAPPROVE_REQUEST,
  ChangeTimeOffPayload,
} from 'state/ManagerDashboard/Unavailability';
import {
  AssignmentLateOutlinedIcon,
  BlockOutlinedIcon,
  CheckIcon,
  CheckOutlinedIcon,
  CloseOutlinedIcon,
  DeleteOutlinedIcon,
  EditIcon,
  IsAppMarket,
  SimpleBadge,
  WarningOutlinedIcon,
  WorkOffOutlinedIcon,
} from 'element';
import { DropdownOptions, TimeOffStatusMap } from './types';
import { TIME_OFF_REPEATS } from './config';
import { NotesIconButton } from 'element/LeaveNotes/NotesIconButton';
import { getLeaveSubtypeLabel, getUserName } from 'lib/helpers';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Avatar,
} from 'oxygen-elements';
import MoreBtnOptions from '../MoreBtnOptions/MoreBtnOptions';
import {
  DropdownOption,
  HeaderOptionsDropdown,
} from 'elmo-elements/CardPopoverBadge/type';

type ActionsCardActionProp = {
  icon: React.ReactNode;
  onClick: () => void;
  label: string;
  isDisabled: boolean;
};

type OwnProps = {
  timeOff: TimeOff;
  user: UserFields;
};

type StateProps = {
  timeFormat: string;
  dateFormat: string;
  currentUserSiteIds: string[];
  canViewAllSites: boolean;
  langPreferences: LanguagePreferences;
};

type DispatchProps = {
  approve: (payload: ChangeTimeOffPayload) => void;
  decline: (payload: ChangeTimeOffPayload) => void;
  unapprove: (payload: ChangeTimeOffPayload) => void;
  delete: (payload: ChangeTimeOffPayload) => void;
  edit: (payload: ChangeTimeOffPayload) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

class UnavailabilityCardComponent extends Component<Props> {
  private colProps = {
    xs: 24 / 2,
    sm: 24 / 4,
    className: 'mb-1 mb-xs-2 mb-md-4',
  };

  render() {
    let { timeOff, user, dateFormat, langPreferences } = this.props;

    return (
      <Card
        key={timeOff.id}
        style={{ marginBottom: '10px', boxShadow: '0 4px 6px #d6d6d6' }}
        className="unavailability-card-item"
        role="row"
      >
        <CardHeader
          className="card-header"
          avatar={
            <div className="avatar-wrapper">
              <WorkOffOutlinedIcon />
              <span style={{ padding: '0 5px 0 28px' }}>
                {this.headerTitle[timeOff.type]}
              </span>
              <span style={{ color: 'rgba(0,0,0, 0.67)' }}>
                {timeOff.created_at.fromNow()}
              </span>
            </div>
          }
          action={
            <div className="card-header-options">
              <SimpleBadge
                className="badge"
                {...(this.badges[timeOff.status] as any)}
                label={this.badges[timeOff.status].label}
              />
              <MoreBtnOptions
                disabled={false}
                options={this.options[timeOff.type]}
              />
            </div>
          }
        />
        <CardHeader
          className="card-subheader"
          avatar={
            <Avatar
              src={user.avatar.src}
              alt={getUserName(user)}
              size={'small'}
              light
            />
          }
          titleTypographyProps={{
            fontSize: 16,
            fontWeight: 500,
          }}
          title={getUserName(user)}
          action={this.notesButton(timeOff)}
        />
        <CardContent className="card-content">
          <Row>
            <Col {...this.colProps}>
              <Text color="gray" size="sm">
                Applied on
              </Text>
              <div data-testid="applied-date">
                {timeOff.created_at.format(`ddd, ${dateFormat}`)}
              </div>
            </Col>

            <Col {...this.colProps}>
              <Text color="gray" size="sm">
                Start date
              </Text>
              <div data-testid="start-date">
                {timeOff.start.format(this.startEndDateFormat)}
              </div>
            </Col>

            <Col {...this.colProps}>
              <Text color="gray" size="sm">
                End date
              </Text>
              <div data-testid="end-date">
                {timeOff.end.format(this.startEndDateFormat)}
              </div>
            </Col>

            <Col {...this.colProps}>
              <Text color="gray" size="sm">
                Repeats
              </Text>
              <div data-testid="repeats">
                {TIME_OFF_REPEATS[timeOff.repeat].label}
              </div>
            </Col>
          </Row>

          <Row>
            <IsAppMarket market={'uk'} negate={true}>
              <Col sm={24 / 4} className={this.colProps.className}>
                <Text color="gray" size="sm">
                  Timezone
                </Text>

                <div data-testid="timezone">{timeOff.site.timezone_id}</div>
              </Col>
            </IsAppMarket>
            {!!timeOff.description && (
              <Col sm={(24 / 4) * 3} className={this.colProps.className}>
                <Text color="gray" size="sm">
                  Note
                </Text>

                <div data-testid="note">{timeOff.description}</div>
              </Col>
            )}
            {!this.canEdit && (
              <Col sm={(24 / 4) * 3} className={this.colProps.className}>
                <div>
                  <Text color="warning">
                    <WarningOutlinedIcon />
                  </Text>
                  <Text color="gray" size="sm">
                    &nbsp;Warning
                  </Text>
                </div>

                <div>
                  You can't edit unavailability on locations where you don't
                  have an assigned {langPreferences.role.singular}
                </div>
              </Col>
            )}
          </Row>
        </CardContent>
        {this.actions[timeOff.status] && (
          <CardActions className="card-actions">
            {this.actions[timeOff.status]!.map((action: any, index: number) => (
              <Button
                key={`btn-${action.label}`}
                size="large"
                onClick={action.onClick}
                disabled={action.isDisabled}
                loading={action.isLoading}
              >
                {action.icon} {action.label}
              </Button>
            ))}
          </CardActions>
        )}
      </Card>
    );
  }

  private get canEdit(): boolean {
    const {
      currentUserSiteIds,
      canViewAllSites,
      timeOff: { site_id },
    } = this.props;

    return canViewAllSites || currentUserSiteIds.includes(site_id);
  }

  private get startEndDateFormat() {
    const { dateFormat, timeFormat } = this.props;

    return `ddd, ${dateFormat}, ${timeFormat}`;
  }

  private get timeOffId() {
    return this.props.timeOff.id;
  }

  private notesButton = (timeOff: TimeOff) => {
    return (
      timeOff.type === 'leave' &&
      this.canEdit && (
        <div style={{ marginLeft: 'auto' }}>
          <NotesIconButton
            notes={timeOff.notes}
            id={timeOff.id}
            label={getLeaveSubtypeLabel(timeOff.subtype, false)}
          />
        </div>
      )
    );
  };
  private getHandler =
    (
      type: keyof Pick<
        DispatchProps,
        'approve' | 'decline' | 'delete' | 'unapprove'
      >
    ) =>
    () => {
      const {
        timeOffId,
        props: { [type]: action },
      } = this;

      action({
        timeOffId,
      });
    };

  private editRequest = () => {
    const {
      props: { edit },
      timeOffId,
    } = this;

    edit({
      timeOffId,
    });
  };

  private get headerTitle(): Dictionary<string, TimeOffType> {
    return {
      unavailability: 'Unavailability',
      leave: getLeaveSubtypeLabel(this.props.timeOff.subtype, true),
    };
  }

  private get allDropdownOptions(): StringMap<DropdownOption> {
    const options: { [key in DropdownOptions]: DropdownOption } = {
      edit: {
        icon: <EditIcon />,
        label: 'Edit',
        onClick: this.editRequest,
      },
      unapprove: {
        icon: <BlockOutlinedIcon />,
        label: 'Unapprove',
        onClick: this.getHandler('unapprove'),
      },
      decline: {
        icon: <CloseOutlinedIcon />,
        label: 'Decline',
        onClick: this.getHandler('decline'),
      },
      approve: {
        icon: <CheckOutlinedIcon />,
        label: 'Approve',
        onClick: this.getHandler('approve'),
      },
      delete: {
        icon: <DeleteOutlinedIcon />,
        label: 'Delete',
        onClick: this.getHandler('delete'),
      },
    };

    return mapValues(
      options,
      (option: DropdownOption): DropdownOption => ({
        ...option,
        isDisabled: !this.canEdit,
      })
    );
  }

  private get dropdownOptions(): TimeOffStatusMap<DropdownOption[]> {
    const { allDropdownOptions } = this;

    return {
      pending: [allDropdownOptions.edit, allDropdownOptions.delete],
      approved: [
        allDropdownOptions.unapprove,
        allDropdownOptions.edit,
        allDropdownOptions.decline,
        allDropdownOptions.delete,
      ],
      declined: [
        allDropdownOptions.approve,
        allDropdownOptions.edit,
        allDropdownOptions.delete,
      ],
    };
  }

  private get options(): Dictionary<
    HeaderOptionsDropdown | undefined,
    TimeOffType
  > {
    const { timeOff } = this.props;

    return {
      unavailability: {
        ariaLabel: 'Options',
        dropdownOptions: this.dropdownOptions[timeOff.status],
      },
      leave: undefined,
    };
  }

  private get badges(): TimeOffStatusMap<{
    label: string;
    type: string;
    icon: React.ReactNode;
  }> {
    return {
      pending: {
        label: 'Pending',
        type: 'warning',
        icon: <AssignmentLateOutlinedIcon />,
      },
      approved: {
        label: 'Approved',
        type: 'success',
        icon: <CheckIcon />,
      },
      declined: {
        label: 'Declined',
        type: 'danger',
        icon: <CloseOutlinedIcon />,
      },
    };
  }

  private get actions(): Partial<TimeOffStatusMap<ActionsCardActionProp[]>> {
    const statuses: Partial<TimeOffStatusMap<ActionsCardActionProp[]>> = {
      pending: [
        {
          icon: <CloseOutlinedIcon />,
          label: 'Decline',
          onClick: this.getHandler('decline'),
          isDisabled: !this.canEdit,
        },
        {
          icon: <CheckOutlinedIcon />,
          label: 'Approve',
          onClick: this.getHandler('approve'),
          isDisabled: !this.canEdit,
        },
      ],
    };

    return mapValues(
      statuses,
      (statusButton: ActionsCardActionProp[]): ActionsCardActionProp[] =>
        map(
          statusButton,
          (button: ActionsCardActionProp): ActionsCardActionProp => ({
            ...button,
            isDisabled: !this.canEdit,
          })
        )
    );
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  timeFormat: timeFormatSelector(state),
  dateFormat: getDateFormat(state),
  currentUserSiteIds: currentUserSiteIdsSelector(state),
  canViewAllSites: hasPermissionSelector(state, 'account.viewallsites'),
  langPreferences: getLangPreferences(state),
});

const mapDispatchToProps: DispatchProps = {
  approve: BOX_UNAVAILABILITY_APPROVE_REQUEST,
  decline: BOX_UNAVAILABILITY_DECLINE_REQUEST,
  unapprove: BOX_UNAVAILABILITY_UNAPPROVE_REQUEST,
  delete: BOX_UNAVAILABILITY_DELETE_MODAL_OPEN,
  edit: BOX_UNAVAILABILITY_EDIT_MODAL_OPEN,
};

export const UnavailabilityCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnavailabilityCardComponent);
