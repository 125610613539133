import { TIME_FORMAT } from 'lib/config';
import { capitalize } from 'lib/helpers';
import _ from 'lodash';
import { createSelector } from 'reselect';
import {
  EmployeeSettingsSorting,
  LanguagePreferences,
  PaymentSettingsOvertimeRuleDays,
  PreferencesDateFormat,
  PreferencesTimeFormat,
  ReportTypesSelector,
} from 'type';
import { isAppMarket, isHrService } from '../../helpers';
import { StoreState } from '../types';
import { ShiftOvertimeRuleWithDay } from './types';

const getState = (state: StoreState) => state.account;
export const getIsUpdating = (state: StoreState): boolean =>
  getState(state).isUpdating;
export const getAccountErrors = (state: StoreState) => getState(state).errors;

const getAccount = (state: StoreState) => getState(state).account;
export const getAccountName = (state: StoreState): string =>
  getAccount(state).name;
export const getAccountSubscription = (state: StoreState) =>
  getAccount(state).subscription;
export const getAccountExternalId = (state: StoreState) =>
  getAccount(state).external_id;

// rostered shift settings
export const getRosteredShiftSettings = (state: StoreState) =>
  getAccount(state).rostered_shift_settings;
export const getCanViewUnavailability = (state: StoreState) =>
  getRosteredShiftSettings(state).unavailability_module;
export const getCanViewShiftOffers = (state: StoreState): boolean =>
  getRosteredShiftSettings(state).shift_offers;
export const getCanViewShiftSwaps = (state: StoreState): boolean =>
  getRosteredShiftSettings(state).shift_swaps;
export const canViewBreaks = (state: StoreState) =>
  getRosteredShiftSettings(state).view_shifts_breaks;
export const getAcceptShiftTimeLimit = (state: StoreState): number =>
  getRosteredShiftSettings(state).accept_shifts_limit;
export const getUserSwapHours = (state: StoreState): number =>
  getRosteredShiftSettings(state).users_swap_hours;
export const getRosteredShiftsAcceptShifts = (state: StoreState): boolean =>
  getRosteredShiftSettings(state).accept_shifts;
export const getDefaultBreaksRules = (state: StoreState) =>
  getRosteredShiftSettings(state).default_breaks_rules;
export const getIsDefaultBreaksApplied = (state: StoreState) =>
  getRosteredShiftSettings(state).apply_default_breaks;
export const getAutoPublish = (state: StoreState) =>
  getRosteredShiftSettings(state).autopublish_changes;

// timesheet settings
export const getTimesheetSettings = (state: StoreState) =>
  getAccount(state).timesheet_settings;
export const getAutoApproveTimesheets = (state: StoreState) =>
  getTimesheetSettings(state).auto_approve_shifts;
export const getCanManuallyAddTimesheets = (state: StoreState) =>
  getTimesheetSettings(state).manually_add_timesheets;
export const getTimeToStartShift = (state: StoreState) =>
  getTimesheetSettings(state).punchclock_diff;
export const canClockOn = (state: StoreState) =>
  getTimesheetSettings(state).time_tracking;
export const getRequestGPSLocation = (state: StoreState) =>
  getTimesheetSettings(state).request_gps;
const getTimesheetSettingsPunchInViolation = (state: StoreState) =>
  getTimesheetSettings(state).punch_in_violation;
const getTimesheetSettingsPunchOutViolation = (state: StoreState) =>
  getTimesheetSettings(state).punch_out_violation;

export const getLangPreferences = (state: StoreState): LanguagePreferences =>
  getAccount(state).language_preferences;
export const getDateFormat = (state: StoreState): PreferencesDateFormat =>
  getAccount(state).preferences.date_format;
export const getTimeFormat = (state: StoreState) =>
  getAccount(state).preferences.time_format;
export const getCurrencyPlacement = (state: StoreState) =>
  getAccount(state).preferences.currency_placement;
export const getNumberFormat = (state: StoreState) =>
  getAccount(state).preferences.number_format;
export const getCurrencyCode = (state: StoreState) =>
  getAccount(state).preferences.currency_code;
export const getPreferencesTimeInterval = (state: StoreState): number =>
  parseInt(getAccount(state).preferences.time_intervals, 10);

export const getCanViewForecastsAndEvents = (state: StoreState): boolean =>
  getAccount(state).event_settings.enable_events;
export const getEventSettings = (state: StoreState) =>
  getAccount(state).event_settings;

export const getCanEditProfile = (state: StoreState): boolean =>
  getAccount(state).employee_settings.edit_profile;

export const getEmployeeCanEnablePunchClock = (state: StoreState): boolean =>
  !getAccount(state).punchclock_settings.only_managers_can_activate;

export const getTimeClockSettings = (state: StoreState) =>
  getAccount(state).punchclock_settings;

export const getEmployeeSettingsSorting = (
  state: StoreState
): EmployeeSettingsSorting => getAccount(state).employee_settings.sorting;

export const getAccountTimezone = (state: StoreState): string =>
  getAccount(state).preferences.timezone;
export const getAccountId = (state: StoreState): string => getAccount(state).id;
export const getUsePaySauceFormat = (state: StoreState) =>
  getAccount(state).preferences.pay_sauce_format;

export const getAppSwitcher = (state: StoreState) =>
  getAccount(state).app_switcher;

export const getAccountPaymentSettings = (state: StoreState) =>
  getAccount(state).payment_settings;
export const getShiftOvertimeRules = (state: StoreState) =>
  getAccountPaymentSettings(state).shift_overtime_rules;
export const getShiftOvertimeDayRule = (
  state: StoreState,
  day: PaymentSettingsOvertimeRuleDays
) => getShiftOvertimeRules(state)[day];
export const getIsWeekendLoadingsEnabled = (state: StoreState) =>
  getAccountPaymentSettings(state).is_weekend_loadings_enabled;

export const weekendShiftOvertimeRulesSelector = createSelector(
  (state: StoreState) => getShiftOvertimeDayRule(state, 'saturday'),
  (state: StoreState) => getShiftOvertimeDayRule(state, 'sunday'),
  (saturdayRules, sundayRules): ShiftOvertimeRuleWithDay[] => {
    const saturdayRulesWithDay = saturdayRules.map((rule) => ({
      ...rule,
      day: 'saturday' as const,
    }));
    const sundayRulesWithDay = sundayRules.map((rule) => ({
      ...rule,
      day: 'sunday' as const,
    }));

    return _.sortBy(
      [...saturdayRulesWithDay, ...sundayRulesWithDay],
      ({ from_minutes }) => from_minutes
    );
  }
);

export const timesheetSettingsPunchInViolationMinutesSelector = createSelector(
  getTimesheetSettingsPunchInViolation,
  (violation) => _.toNumber(violation)
);

export const timesheetSettingsPunchOutViolationMinutesSelector = createSelector(
  getTimesheetSettingsPunchOutViolation,
  (violation) => _.toNumber(violation)
);

export const reportTypesSelector = createSelector<
  StoreState,
  LanguagePreferences,
  ReportTypesSelector
>(getLangPreferences, (langPreferences) => {
  const eventsLabel = capitalize(langPreferences.event.plural);

  const types = {
    rostered_shift: {
      label: isAppMarket('uk') ? 'Rota shifts' : 'Rostered shifts',
      modalTitle: isAppMarket('uk')
        ? 'Rota shifts report'
        : 'Rostered shifts report',
    },
    timesheet: {
      label: 'Timesheets',
      modalTitle: 'Timesheets report',
    },
    payroll: {
      label: 'Payroll',
      modalTitle: 'Payroll report',
    },
    sale: {
      label: 'Sales report',
      modalTitle: 'Sales report',
    },
    late: {
      label: 'Late report',
      modalTitle: 'Late report',
    },
    no_show: {
      label: 'No show report',
      modalTitle: 'No show report',
    },
    event: {
      label: eventsLabel,
      modalTitle: `${eventsLabel} report`,
    },
    coverage: {
      label: 'Coverage report',
      modalTitle: 'Coverage report',
    },
    transfer: {
      label: 'Site transfer report',
      modalTitle: 'Site transfer report',
    },
    tag: {
      label: 'Tags report',
      modalTitle: 'Tags report',
    },
    roster: {
      label: isAppMarket('uk')
        ? 'Rotas'
        : capitalize(langPreferences.roster.plural),
      modalTitle: `${
        isAppMarket('uk') ? 'Rotas' : capitalize(langPreferences.roster.plural)
      } report`,
    },
  };

  if (isHrService('bravo')) {
    const { payroll, transfer, tag, ...rest } = types;
    return rest as ReportTypesSelector;
  }
  return types;
});

export const timeFormatSelector = createSelector(
  getTimeFormat,
  (timeFormat: PreferencesTimeFormat): string =>
    +timeFormat === 12 ? TIME_FORMAT.hours_12 : TIME_FORMAT.hours_24
);

export const orderDropDownOptions = createSelector(
  getLangPreferences,
  (languagePreferences: LanguagePreferences) => {
    return [
      { value: '', label: 'Not ordered' },
      { value: 'user_name', label: 'User name' },
      {
        value: 'site_name',
        label: capitalize(languagePreferences.site.singular),
      },
      {
        value: 'area_name',
        label: capitalize(languagePreferences.area.singular),
      },
      {
        value: 'role_name',
        label: capitalize(languagePreferences.role.singular),
      },
      { value: 'start', label: 'Start Date' },
      { value: 'end', label: 'End Date' },
    ];
  }
);

//orderby drowndown for rota
export const orderDropDownOptionsRoster = createSelector(
  getLangPreferences,
  (languagePreferences: LanguagePreferences) => {
    return [
      {
        value: 'site_name',
        label: capitalize(languagePreferences.site.singular),
      },
      { value: 'start', label: 'Start Date' },
      { value: 'end', label: 'End Date' },
    ];
  }
);

export const groupDropDownOptions = createSelector(
  getLangPreferences,
  (languagePreferences: LanguagePreferences) => {
    return [
      { value: '', label: 'Not grouped' },
      { value: 'user', label: 'User name' },
      {
        value: 'site',
        label: capitalize(languagePreferences.site.singular),
      },
      {
        value: 'role',
        label: capitalize(languagePreferences.role.singular),
      },
      { value: 'date', label: 'Date' },
    ];
  }
);

//group by dropdown for rota
export const groupDropDownOptionsRoster = createSelector(
  getLangPreferences,
  (languagePreferences: LanguagePreferences) => {
    return [
      { value: '', label: 'Not grouped' },
      {
        value: 'site',
        label: capitalize(languagePreferences.site.singular),
      },
      { value: 'date', label: 'Date' },
    ];
  }
);

export const tagsGroupDropDownOptions = createSelector(
  getLangPreferences,
  (languagePreferences: LanguagePreferences) => {
    return [
      { value: '', label: 'Not grouped' },
      {
        value: 'site',
        label: capitalize(languagePreferences.site.singular),
      },
      {
        value: 'role',
        label: capitalize(languagePreferences.role.singular),
      },
    ];
  }
);

export const rosterOrderDropDownOptions = createSelector(
  getLangPreferences,
  (languagePreferences: LanguagePreferences) => {
    return [
      { value: '', label: 'Not ordered' },
      {
        value: 'site_name',
        label: capitalize(languagePreferences.site.singular),
      },
      { value: 'start', label: 'Start Date' },
      { value: 'end', label: 'End Date' },
    ];
  }
);

export const rosterGroupDropDownOptions = createSelector(
  getLangPreferences,
  (languagePreferences: LanguagePreferences) => {
    return [
      { value: '', label: 'Not grouped' },
      {
        value: 'site',
        label: capitalize(languagePreferences.site.singular),
      },
      { value: 'date', label: 'Date' },
    ];
  }
);

export const showDropDownOptions = createSelector(
  getLangPreferences,
  (languagePreferences: LanguagePreferences) => {
    return [
      { value: 'all', label: 'All' },
      { value: 'summary', label: 'Summary' },
      {
        value: 'areas',
        label: capitalize(languagePreferences.area.plural),
      },
      {
        value: 'events',
        label: capitalize(languagePreferences.event.plural),
      },
    ];
  }
);

export const unlinkedRoleLabelSelector = createSelector(
  getLangPreferences,
  ({ role }): string => `Unlinked ${role.singular}`
);

export const getTimezoneList = (state: StoreState) =>
  state.account.timezonesList;

export const getIsApplyingBreaks = (state: StoreState) =>
  state.account.isApplyingCustomBreaks;
