import { Dictionary } from 'ts-essentials';

export const isNotEmptyRow = (rows: any) => {
  for (let row of rows) {
    for (let col of row) {
      if (col.hasOwnProperty('rosteredShiftId')) {
        return true;
      }
    }
  }
  return false;
};

export const getKey = (shift: { area_id: string; role_id: string }) =>
  [shift.area_id, shift.role_id].join('/');

export const groupByRolePair = <
  Item extends { area_id: string; role_id: string }
>(
  shifts: Item[]
) =>
  shifts.reduce<Dictionary<Item[]>>((accumulator, shift) => {
    const shiftKey = getKey(shift);
    const prevValues = accumulator[shiftKey] || [];
    prevValues.push(shift);
    accumulator[shiftKey] = prevValues;
    return accumulator;
  }, {});
