import React, { ReactText } from 'react';
import { Data, DropDownProps } from '../type';
import GroupByOrderByTimeFormatGroup from './GroupByOrderByTimeFormatGroup';
import TimesheetCheckboxGroup from './TimesheetCheckboxGroup';
import { Divider } from 'elmo-elements';

export type DataProps = {
  handleOnChangeSelect: (e: DropDownProps, name: string) => void;
  handleOnChangeCheckbox: (e: any) => void;
  handleOnChangeTimeFormat: (value: ReactText) => void;
  handleOnChangeStatus: (e: DropDownProps) => void;
  data: Data;
};

const TimesheetViewGroup = ({
  handleOnChangeCheckbox,
  handleOnChangeTimeFormat,
  handleOnChangeSelect,
  handleOnChangeStatus,
  data,
}: DataProps) => {
  return (
    <>
      <Divider />
      <br />
      <h3>View</h3>
      <GroupByOrderByTimeFormatGroup
        data={data}
        handleOnChangeSelect={handleOnChangeSelect}
        handleOnChangeTimeFormat={handleOnChangeTimeFormat}
        handleOnChangeStatus={handleOnChangeStatus}
      />
      <TimesheetCheckboxGroup
        data={data}
        handleOnChangeCheckbox={handleOnChangeCheckbox}
      />
    </>
  );
};

export default TimesheetViewGroup;
