import React, { ReactNode } from 'react';

type PopoverTargetProps = {
  /** Content of the target which the user clicks/hovers */
  children: ReactNode;
  /** Id of the component */
  id?: string;
  /** Class name for styling */
  className?: string;
};

function PopoverTarget({ children, id, className }: PopoverTargetProps) {
  return (
    <div id={id} className={className}>
      {children}
    </div>
  );
}

PopoverTarget.displayName = 'PopoverTarget';

export default PopoverTarget;
