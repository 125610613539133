import React, { Component, createRef } from 'react';
import clsx from 'clsx';

type ChangeShadow = (hasShadow: boolean) => void;
type ModalBodyProps = {
  changeShadow: ChangeShadow;
  isBackgroundGrey?: boolean;
};

class ModalBody extends Component<ModalBodyProps> {
  private wrapperRef = createRef<HTMLDivElement>();
  private innerWrapperRef = createRef<HTMLDivElement>();

  private getBodyHeight = (): number => {
    const { current } = this.wrapperRef;
    if (current) {
      return current.clientHeight;
    }
    return 0;
  };

  private doShadow = () => {
    const bodyHeight = this.getBodyHeight();
    const { current } = this.innerWrapperRef;

    if (current) {
      this.props.changeShadow(current.clientHeight >= bodyHeight);
    }
  };

  private modalBodyObserver = new ResizeObserver(this.doShadow);

  componentDidMount() {
    const { current } = this.wrapperRef;
    if (current) {
      this.modalBodyObserver.observe(current);
      this.doShadow();
    }
  }

  render() {
    const { isBackgroundGrey, children } = this.props;

    return (
      <div
        className={clsx('elmo-modal__body', {
          'elmo-modal__body--grey': isBackgroundGrey,
        })}
        ref={this.wrapperRef}
      >
        <div ref={this.innerWrapperRef}>
          {children}
        </div>
      </div>
    );
  }
}

export default ModalBody;
