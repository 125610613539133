import { Header, RosterHeaderNavigation } from 'element';
import { LoadingOverlay } from 'elmo-elements';
import { Moment } from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { getIsLoading, MANAGER_DASHBOARD_TIMESHEETS } from 'state/IsLoading';
import {
  BOX_TIMESHEETS_GET_DAY_REQUEST,
  BOX_TIMESHEETS_GET_NEXT_DAY_REQUEST,
  BOX_TIMESHEETS_GET_PREV_DAY_REQUEST,
  BOX_TIMESHEETS_GET_SITE_REQUEST,
} from 'state/Timesheets/Timesheets/actions';
import {
  GetDayPayload,
  GetSitePayload,
} from 'state/Timesheets/Timesheets/types';
import {
  fromBySiteTimezoneSelector,
  getSiteId,
} from 'state/TimesheetsCollection';
import { StoreState } from 'state/types';

type OwnProps = {};

type StateProps = {
  from: Moment;
  siteId: string;
  isLoading: boolean;
};

type DispatchProps = {
  changeSite: (payload: GetSitePayload) => void;
  changeDay: (payload: GetDayPayload) => void;
  changeDayToPrev: () => void;
  changeDayToNext: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

export const TimesheetsHeaderComponent = ({
  from,
  siteId,
  changeSite,
  changeDay,
  changeDayToNext,
  changeDayToPrev,
  isLoading,
}: Props) => (
  <div className={'rosters-header-wrapper-improved'}>
    <LoadingOverlay isLoading={isLoading} showSpinner={false}>
      <Header
        title={
          <RosterHeaderNavigation
            dateRange={{ from }}
            siteId={siteId}
            selectedDay={from.clone()}
            onPrevClicked={changeDayToPrev}
            onNextClicked={changeDayToNext}
            onSiteChange={changeSite}
            onDateChange={changeDay}
          />
        }
      />
    </LoadingOverlay>
  </div>
);

const mapStateToProps = (state: StoreState): StateProps => ({
  from: fromBySiteTimezoneSelector(state),
  siteId: getSiteId(state),
  isLoading: getIsLoading(state, MANAGER_DASHBOARD_TIMESHEETS),
});

const mapDispatchToProps: DispatchProps = {
  changeSite: BOX_TIMESHEETS_GET_SITE_REQUEST,
  changeDay: BOX_TIMESHEETS_GET_DAY_REQUEST,
  changeDayToPrev: BOX_TIMESHEETS_GET_PREV_DAY_REQUEST,
  changeDayToNext: BOX_TIMESHEETS_GET_NEXT_DAY_REQUEST,
};

export const TimesheetsHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(TimesheetsHeaderComponent);
