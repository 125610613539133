import React from 'react';
import { connect } from 'react-redux';
import { FetchWrapper } from 'element';
import { USERS } from 'state/FetchPageData/constants';
import {
  BOX_USERS_GET_USERS_PAGE_DATA_REQUEST,
} from 'state/Users/Users';
import View from './view';

type DispatchProps = {
  fetchData: () => void;
};

type Props = DispatchProps;

const Users = (props: Props) => (
  <FetchWrapper {...props} pageId={USERS}>
    <View />
  </FetchWrapper>
);

const mapDispatchToProps: DispatchProps = {
  fetchData: BOX_USERS_GET_USERS_PAGE_DATA_REQUEST
};

export default connect(
  null,
  mapDispatchToProps
)(Users);
