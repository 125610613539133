import { DeleteShiftItem, RosterBulkActionsReducerState } from './types';
import { createReducer } from 'lib/store-utils';
import * as actions from './actions';
import { getDefaultState } from './state';
import _ from 'lodash';

export const rosterBulkActions = createReducer<RosterBulkActionsReducerState>({}, getDefaultState());

rosterBulkActions.on(actions.BOX_ROSTER_BULK_DELETION_MODE_ACTIVATE, getDefaultState);

rosterBulkActions.on(
  actions.BOX_ROSTER_BULK_DELETION_MODE_ACTIVATE,
  (state): RosterBulkActionsReducerState => ({
    ...state,
    bulkDelete: {
      ...state.bulkDelete,
      isOpened: true
    }
  })
);

rosterBulkActions.on(
  actions.BOX_ROSTER_BULK_DELETION_MODE_DEACTIVATE,
  (state): RosterBulkActionsReducerState => ({
    ...state,
    bulkDelete: {
      ...state.bulkDelete,
      notDeleted: [],
      isOpened: false,
      shifts: [],
      selectionBy: {
        users: {},
        areas: {}
      }
    }
  })
);

rosterBulkActions.on(
  actions.BOX_ROSTER_BULK_DELETION_MODE_TOGGLE_SELECTED_SHIFT,
  (state, shift): RosterBulkActionsReducerState => ({
    ...state,
    bulkDelete: {
      ...state.bulkDelete,
      shifts: _.xorBy(state.bulkDelete.shifts, [shift], 'id')
    }
  })
);

rosterBulkActions.on(
  actions.BOX_ROSTER_BULK_TOGGLE_SELECTED_SHIFTS,
  (state, payload): RosterBulkActionsReducerState => {
    const { user_id, shifts } = payload;
    const { users } = state.bulkDelete.selectionBy;
    const toSelectShifts = users[`${user_id}`];
    const alreadyAddedItems: DeleteShiftItem[] = [];
    let shiftsToDelete: DeleteShiftItem[] = [];

    for (let shift of shifts) {
      for (let stateShift of state.bulkDelete.shifts) {
        if ( shift.id === stateShift.id && stateShift.user_id === user_id ) {
          alreadyAddedItems.push(shift);
        }
      }
    }

    if ( toSelectShifts ) {
      const withoutAddedItems: DeleteShiftItem[] = _.xorBy(state.bulkDelete.shifts, alreadyAddedItems, 'id');
      shiftsToDelete = _.xorBy(withoutAddedItems, shifts, 'id');
    } else {
      shiftsToDelete = _.xorBy(state.bulkDelete.shifts, alreadyAddedItems, 'id');
    }

    return {
      ...state,
      bulkDelete: {
        ...state.bulkDelete,
        shifts: shiftsToDelete
      }
    };
  }
);

rosterBulkActions.on(
  actions.BOX_ROSTER_BULK_TOGGLE_SELECTED_USER,
  (state, id): RosterBulkActionsReducerState => {
    return {
      ...state,
      bulkDelete: {
        ...state.bulkDelete,
        selectionBy: {
          ...state.bulkDelete.selectionBy,
          users: {
            ...state.bulkDelete.selectionBy.users,
            [`${id}`]: !state.bulkDelete.selectionBy.users[`${id}`]
          }
        }
      }
    }
  }
);

rosterBulkActions.on(
  actions.BOX_ROSTER_BULK_DELETION_MODAL_OPEN,
  (state): RosterBulkActionsReducerState => ({
    ...state,
    bulkDelete: {
      ...state.bulkDelete,
      confirmDeletionModal: {
        ...state.bulkDelete.confirmDeletionModal,
        isOpened: true,
        title: '',
        errors: []
      }
    }
  })
);

rosterBulkActions.on(
  actions.BOX_ROSTER_BULK_DELETION_MODAL_CLOSE,
  (state): RosterBulkActionsReducerState => ({
    ...state,
    bulkDelete: {
      ...state.bulkDelete,
      confirmDeletionModal: {
        ...state.bulkDelete.confirmDeletionModal,
        isOpened: false,
        isDeleting: false,
        isConfirmation: false,
        title: '',
        errors: []
      }
    }
  })
);

rosterBulkActions.on(
  actions.BOX_ROSTER_BULK_DELETION_MODAL_CLEAR_ERRORS,
  (state): RosterBulkActionsReducerState => ({
    ...state,
    bulkDelete: {
      ...state.bulkDelete,
      confirmDeletionModal: {
        ...state.bulkDelete.confirmDeletionModal,
        errors: []
      }
    }
  })
);

rosterBulkActions.on(
  actions.BOX_ROSTER_BULK_DELETION_SEND_SHIFTS_REQUEST,
  (state): RosterBulkActionsReducerState => ({
    ...state,
    bulkDelete: {
      ...state.bulkDelete,
      confirmDeletionModal: {
        ...state.bulkDelete.confirmDeletionModal,
        isDeleting: true
      }
    }
  })
);

rosterBulkActions.on(
  actions.BOX_ROSTER_BULK_DELETION_SEND_SHIFTS_SUCCESS,
  (state, notDeleted): RosterBulkActionsReducerState => {
    const copiedTimesheets = state.bulkDelete.shifts.map(item => item.type === 'timesheet' ? item.id : '');
    return {
      ...state,
      bulkDelete: {
        ...state.bulkDelete,
        shifts: [],
        selectionBy: {
          users: {},
          areas: {}
        },
        notDeleted: [...copiedTimesheets, ...notDeleted],
        confirmDeletionModal: {
          ...state.bulkDelete.confirmDeletionModal,
          isDeleting: false,
          isOpened: false,
          isConfirmation: false,
          title: '',
          errors: []
        }
      }
    }
    }

);

rosterBulkActions.on(
  actions.BOX_ROSTER_BULK_DELETION_SEND_SHIFTS_FAILURE,
  (state, errors): RosterBulkActionsReducerState => ({
    ...state,
    bulkDelete: {
      ...state.bulkDelete,
      confirmDeletionModal: {
        ...state.bulkDelete.confirmDeletionModal,
        isDeleting: false,
        errors: errors
      }
    }
  })
);

rosterBulkActions.on(
  actions.BOX_ROSTER_BULK_DELETION_SEND_TEMPLATED_SHIFTS_REQUEST,
  (state): RosterBulkActionsReducerState => ({
    ...state,
    bulkDelete: {
      ...state.bulkDelete,
      confirmDeletionModal: {
        ...state.bulkDelete.confirmDeletionModal,
        isDeleting: true
      }
    }
  })
);

rosterBulkActions.on(
  actions.BOX_ROSTER_BULK_DELETION_SEND_TEMPLATED_SHIFTS_SUCCESS,
  (state): RosterBulkActionsReducerState => {
    return {
      ...state,
      bulkDelete: {
        ...state.bulkDelete,
        shifts: [],
        selectionBy: {
          users: {},
          areas: {}
        },
        notDeleted: [],
        confirmDeletionModal: {
          ...state.bulkDelete.confirmDeletionModal,
          isDeleting: false,
          isOpened: false,
          isConfirmation: false,
          title: '',
          errors: []
        }
      }
    }
  }

);

rosterBulkActions.on(
  actions.BOX_ROSTER_BULK_DELETION_SEND_TEMPLATED_SHIFTS_FAILURE,
  (state, errors): RosterBulkActionsReducerState => ({
    ...state,
    bulkDelete: {
      ...state.bulkDelete,
      confirmDeletionModal: {
        ...state.bulkDelete.confirmDeletionModal,
        isDeleting: false,
        errors: errors
      }
    }
  })
);

rosterBulkActions.on(
  actions.BOX_ROSTER_BULK_DELETION_MODAL_CONFIRMATION,
  (state, confirmationMsg): RosterBulkActionsReducerState => ({
    ...state,
    bulkDelete: {
      ...state.bulkDelete,
      confirmDeletionModal: {
        ...state.bulkDelete.confirmDeletionModal,
        isDeleting: false,
        errors: confirmationMsg.errors,
        title: confirmationMsg.title,
        isConfirmation: true
      }
    }
  })
);

rosterBulkActions.on(
  actions.BOX_ROSTER_BULK_TOGGLE_SELECTED_AREA_ROLE,
  (state, payload): RosterBulkActionsReducerState => {
    const { areas } = state.bulkDelete.selectionBy;
    return {
      ...state,
      bulkDelete: {
        ...state.bulkDelete,
        selectionBy: {
          ...state.bulkDelete.selectionBy,
          areas: {
            ...areas,
            [`${payload.area_id}_${payload.role_id}`]: !areas[`${payload.area_id}_${payload.role_id}`]
          }
        }
      }
    }
  }
);

rosterBulkActions.on(
  actions.BOX_ROSTER_BULK_TOGGLE_SELECTED_SHIFTS_AREA_ROLE,
  (state, payload): RosterBulkActionsReducerState => {
    const { area_id, role_id, shifts } = payload;
    const { areas } = state.bulkDelete.selectionBy;
    const toSelectShifts = areas[`${area_id}_${role_id}`];
    const alreadyAddedItems: DeleteShiftItem[] = [];
    let shiftsToDelete: DeleteShiftItem[] = [];

    for (let shift of shifts) {
      for (let stateShift of state.bulkDelete.shifts) {
        const areaId = stateShift.area_id;
        const roleId = stateShift.role_id;
        const id = stateShift.id;
        if ( shift.id === id && areaId === area_id && roleId === role_id ) {
          alreadyAddedItems.push(shift);
        }
      }
    }

    if ( toSelectShifts ) {
      const withoutAddedItems: DeleteShiftItem[] = _.xorBy(state.bulkDelete.shifts, alreadyAddedItems, 'id');
      shiftsToDelete = _.xorBy(withoutAddedItems, shifts, 'id');
    } else {
      shiftsToDelete = _.xorBy(state.bulkDelete.shifts, alreadyAddedItems, 'id');
    }

    return {
      ...state,
      bulkDelete: {
        ...state.bulkDelete,
        shifts: shiftsToDelete
      }
    };
  }
);

rosterBulkActions.on(
  actions.BOX_ROSTER_BULK_REMOVE_SHIFT_ID_BY_SOCKET_EVENT,
  (state, id): RosterBulkActionsReducerState => {
    return {
      ...state,
      bulkDelete: {
        ...state.bulkDelete,
        shifts: _.filter(state.bulkDelete.shifts, s => s.id !== id)
      }
    }
  }
);

rosterBulkActions.on(
  actions.BOX_ROSTER_BULK_REMOVE_SHIFT_IDS_BY_SOCKET_EVENT,
  (state, ids): RosterBulkActionsReducerState => {
    return {
      ...state,
      bulkDelete: {
        ...state.bulkDelete,
        shifts: _.filter(state.bulkDelete.shifts, shift => !ids.includes(shift.id))
      }
    }
  }
);
