import React from 'react';
import {
  ListTable,
  LoadingOverlay,
  Paragraph,
  withLayoutAware,
} from 'elmo-elements';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import { Props, State } from './type';
import {
  getCurrencyFormatted,
  getDateTimeFormatted,
  getPreferenceLabel,
} from 'lib/helpers';
import { Event } from 'type/models';
import {
  BOX_EMPLOYEE_DASHBOARD_EVENTS_CHANGE_PAGE_REQUEST,
  BOX_EMPLOYEE_DASHBOARD_EVENTS_CHANGE_PAGE_SIZE_REQUEST,
  BOX_EMPLOYEE_DASHBOARD_EVENTS_DATA_REQUEST,
  BOX_EMPLOYEE_DASHBOARD_EVENTS_OPEN_DETAILS_MODAL,
  BOX_EMPLOYEE_DASHBOARD_EVENTS_UPDATE_ORDERING_REQUEST,
  eventsWithPagerSelector,
  getDetailsModal,
  getEventsList,
  getFilters,
} from 'state/EmployeeDashboard/Events';
import moment from 'moment';
import {
  getCurrencyCode,
  getCurrencyPlacement,
  getDateFormat,
  getEventSettings,
  getLangPreferences,
  getNumberFormat,
  getTimeFormat,
} from 'state/Account';
import { EventDetailsModal } from './components/EventDetailsModal';
import Timer from 'element/Timer';
import { getSites } from 'state/AccountTree';
import { EmptyMessage } from './components/EmptyMessage';
import WithAccountPreferences from 'element/WithAccountPreferences';
import { PAGE_SIZE } from 'lib/config';
import { IsAppMarket, SimpleBadge } from 'element';

export class EventsListComponent extends React.Component<Props, State> {
  columns = ['title', 'status'];

  constructor(props: Props) {
    super(props);
    this.state = {
      sortDirection: 'desc',
      sortColumn: 0,
      data: this.props.data,
      currentPage: this.props.pager.currentPage,
      totalResults: this.props.pager.total,
      isAllAvailableSelected: false,
    };

    const { eventSettings } = props;

    if (eventSettings.show_event_address) {
      this.columns.push('location');
    }

    if (eventSettings.show_event_start) {
      this.columns.push('start');
    }

    if (eventSettings.show_event_finish) {
      this.columns.push('end');
    }
  }

  onSort = (column: number, direction: string) => {
    this.setState({
      sortColumn: column,
    });

    this.props.changeOrdering({
      name: 'sort',
      value: {
        column: this.columns[column],
        direction,
      },
    });
  };

  onPageChange = (pageNumber: number) => {
    this.props.changePage(pageNumber);
  };

  onPageSizeChange = (pageSize: number) => {
    this.props.changePageSize(pageSize);
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.setState({
        data: this.props.data,
        totalResults: this.props.total,
      });

      this.setState({
        sortColumn: this.columns.indexOf(this.props.filters.sort.column),
      });
    }
  }

  renderListTableFlexible = () => {
    const {
      eventSettings,
      langPreferences,
      dateFormat,
      timeFormat,
      numberFormat,
      currencyCode,
      currencyPlacement,
    } = this.props;
    return (
      <div>
        <LoadingOverlay isLoading={this.props.isFetching} showSpinner={true}>
          {this.state.data.length ? (
            <WithAccountPreferences>
              <ListTable
                className={'events-list-table'}
                data={this.state.data}
                onSort={this.onSort}
                ariaLabel="Events"
                sortDirection={this.props.filters.sort.direction}
                sortColumn={this.state.sortColumn}
                onRowClick={this.props.openEventModal}
              >
                <ListTable.Column
                  label={getPreferenceLabel(
                    langPreferences,
                    'event',
                    'singular',
                    '',
                    true
                  )}
                  render={(data) => {
                    return (
                      <>
                        {data.name}
                        {eventSettings.show_event_item && (
                          <Paragraph size="xs">{data.item}</Paragraph>
                        )}
                        {this.renderTimezone(data)}
                      </>
                    );
                  }}
                  sortable={true}
                />
                <ListTable.Column
                  label={'Status'}
                  render={this.renderStatus}
                  property={'status'}
                  sortable={true}
                />
                {eventSettings.show_event_address && (
                  <ListTable.Column
                    label={'Address'}
                    render={(data) => data.address}
                    sortable={true}
                  />
                )}
                {eventSettings.show_event_start && (
                  <ListTable.Column
                    label={'Start'}
                    render={(data) =>
                      getDateTimeFormatted(
                        dateFormat,
                        timeFormat,
                        data.start,
                        true
                      )
                    }
                    sortable={true}
                  />
                )}
                {eventSettings.show_event_finish && (
                  <ListTable.Column
                    label={'Finish'}
                    render={(data) =>
                      getDateTimeFormatted(
                        dateFormat,
                        timeFormat,
                        data.end,
                        true
                      )
                    }
                    sortable={true}
                  />
                )}
                {eventSettings.show_value && (
                  <ListTable.Column
                    label={'Value'}
                    render={(data) =>
                      getCurrencyFormatted(
                        numberFormat,
                        currencyCode,
                        currencyPlacement,
                        +data.value
                      )
                    }
                    sortable={false}
                  />
                )}
              </ListTable>
              <ListTable.Pagination
                pageSizeOptions={PAGE_SIZE}
                pageSize={this.props.pager.pageSize}
                currentPage={this.props.pager.currentPage}
                onPageChange={this.onPageChange}
                onPageSizeChange={this.onPageSizeChange}
                totalResults={this.props.pager.total}
              />
            </WithAccountPreferences>
          ) : (
            <EmptyMessage />
          )}

          <EventDetailsModal />
        </LoadingOverlay>
      </div>
    );
  };

  render() {
    return <div>{this.renderListTableFlexible()}</div>;
  }

  renderTimezone = (data: Event) => {
    const { site_id } = data;
    const { sites } = this.props;

    return (
      <IsAppMarket market={'uk'} negate={true}>
        <Paragraph size="xs">{sites[site_id].timezone_id}</Paragraph>
      </IsAppMarket>
    );
  };

  renderStatus = (event: Event) => {
    if (event.start < moment() && event.end > moment()) {
      return <SimpleBadge label={'now'} type="info" />;
    }

    if (event.start > moment()) {
      return (
        <>
          <SimpleBadge label={'upcoming'} type="warning" />
          <Timer date={event.start} />
        </>
      );
    }

    if (event.end < moment()) {
      return <SimpleBadge label={'finished'} type="success" />;
    }

    return '';
  };
}

const mapStateToProps = (state: StoreState) => ({
  isFetching: state.employeeDashboard.events.isFetching,
  data: getEventsList(state),
  filters: getFilters(state),
  pager: eventsWithPagerSelector(state),
  detailsModal: getDetailsModal(state),
  eventSettings: getEventSettings(state),
  sites: getSites(state),
  langPreferences: getLangPreferences(state),
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
  numberFormat: getNumberFormat(state),
  currencyCode: getCurrencyCode(state),
  currencyPlacement: getCurrencyPlacement(state),
});

export const EventsList = connect(mapStateToProps, {
  getData: BOX_EMPLOYEE_DASHBOARD_EVENTS_DATA_REQUEST,
  changePage: BOX_EMPLOYEE_DASHBOARD_EVENTS_CHANGE_PAGE_REQUEST,
  changePageSize: BOX_EMPLOYEE_DASHBOARD_EVENTS_CHANGE_PAGE_SIZE_REQUEST,
  changeOrdering: BOX_EMPLOYEE_DASHBOARD_EVENTS_UPDATE_ORDERING_REQUEST,
  openEventModal: BOX_EMPLOYEE_DASHBOARD_EVENTS_OPEN_DETAILS_MODAL,
})(withLayoutAware(EventsListComponent));
