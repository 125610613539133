import { createSelector } from 'reselect';
import { intersection } from 'lodash';
import { RosteredShift, StringMap } from 'type';
import { StoreState } from 'state/types';
import { checkNullFilter } from 'state/helpers';
import { groupedByFieldCombinerCreator } from 'state/combiners';
import { getSiteId, rosteredShiftsArraySelector } from 'state/RosteredShifts';
import { ROSTER_FILTER_WITHOUT } from '../constants';
import { shiftRoleFilter } from '../helpers';
import { getFiltersEvents, getFiltersTags } from './simpleSelectors';
import { filtersSelector } from './common';

export const rosteredShiftsFilteredByTagId = createSelector<
  StoreState,
  RosteredShift[],
  null | string[],
  RosteredShift[]
>(
  rosteredShiftsArraySelector,
  getFiltersTags,
  checkNullFilter(
    (shifts: RosteredShift[], eventIdsFilter: string[]): RosteredShift[] => {
      const showWithoutEvents: boolean = eventIdsFilter.includes(
        ROSTER_FILTER_WITHOUT
      );

      return shifts.filter(({ tags }: RosteredShift): boolean => {
        if (!tags.length) {
          return showWithoutEvents;
        }

        return !!intersection(eventIdsFilter, tags).length;
      });
    }
  )
);
export const rosteredShiftsFilteredByEventId = createSelector<
  StoreState,
  RosteredShift[],
  null | string[],
  RosteredShift[]
>(
  rosteredShiftsFilteredByTagId,
  getFiltersEvents,
  checkNullFilter(
    (shifts: RosteredShift[], eventIdsFilter: string[]): RosteredShift[] => {
      const showWithoutEvents: boolean = eventIdsFilter.includes(
        ROSTER_FILTER_WITHOUT
      );

      return shifts.filter(({ event_id }: RosteredShift): boolean => {
        if (!event_id) {
          return showWithoutEvents;
        }

        return eventIdsFilter.includes(event_id);
      });
    }
  )
);

export const rosteredShiftsFilteredSelector = createSelector<
  StoreState,
  ReturnType<typeof filtersSelector>,
  ReturnType<typeof rosteredShiftsFilteredByEventId>,
  ReturnType<typeof getSiteId>,
  ReturnType<typeof rosteredShiftsFilteredByEventId>
>(filtersSelector, rosteredShiftsFilteredByEventId, getSiteId, shiftRoleFilter);

export const filteredRosteredShiftsByUserIdSelector = createSelector<
  StoreState,
  RosteredShift[],
  StringMap<RosteredShift[]>
>(rosteredShiftsFilteredSelector, groupedByFieldCombinerCreator('user_id'));
