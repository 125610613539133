import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AddOutlinedIcon, FetchWrapper } from 'element';
import { privateRoutes } from 'routes';
import { EMPLOYEE_DASHBOARD_UNAVAILABILITY } from 'state/FetchPageData/constants';
import {
  BOX_EMPLOYEE_GET_UNAVAILABILITY,
  BOX_EMPLOYEE_UNAVAILABILITY_MODAL_OPEN,
} from 'state/EmployeeDashboard/Unavailability';
import { getCanViewUnavailability } from 'state/Account';
import { StoreState } from 'state/types';
import { EmployeeDashboardLayout } from '../../components';
import View from './view';
import { OpenModalProps } from '../../../../state/EmployeeDashboard/Unavailability/types';
import { UnavailabilityHeader } from './components/UnavailabilityHeader';

type OwnProps = {};

type StateProps = {
  canViewUnavailability: boolean;
};

type DispatchProps = {
  fetchData: () => void;
  openModal: (props: OpenModalProps) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const Unavailability = ({
  canViewUnavailability,
  openModal,
  ...restProps
}: Props) => {
  if (!canViewUnavailability) {
    return <Redirect to={privateRoutes.employeeDashboard.path} />;
  }

  const addButton = {
    id: "add-unavailability",
    icon: <AddOutlinedIcon />,
    label: 'Add Unavailability',
    onClick: () => {
      openModal({type: 'create'});
    }
  };

  return (
    <EmployeeDashboardLayout header={<UnavailabilityHeader
      primaryButton={addButton}/>
    }>
      <FetchWrapper {...restProps} pageId={EMPLOYEE_DASHBOARD_UNAVAILABILITY}>
        <View />
      </FetchWrapper>
    </EmployeeDashboardLayout>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  canViewUnavailability: getCanViewUnavailability(state)
});

const mapDispatchToProps: DispatchProps = {
  fetchData: BOX_EMPLOYEE_GET_UNAVAILABILITY,
  openModal: BOX_EMPLOYEE_UNAVAILABILITY_MODAL_OPEN,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Unavailability);
