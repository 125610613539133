import React, { Component } from 'react';
import {
  DayDetails, EmployeeSpreadOfHoursResponse,
  EmployeeUnavailabilityProps,
  OpenModalProps,
  TimeOffRosteredShift
} from 'state/EmployeeDashboard/Unavailability/types';
import { Text, Heading, Paragraph } from 'elmo-elements';
import './CalendarCard.scss';
import moment, { Moment } from 'moment';
import {
  CheckIcon,
  CancelOutlinedIcon,
  WorkOutlineIcon,
  WorkOffOutlinedIcon,
  AssignmentLateOutlinedIcon
} from 'element';
import { connect } from 'react-redux';
import {
  BOX_EMPLOYEE_GET_UNAVAILABILITY_BY_ID,
  BOX_EMPLOYEE_UNAVAILABILITY_MODAL_OPEN
} from 'state/EmployeeDashboard/Unavailability';
import { getTimeFormatted } from 'lib/helpers';
import { getWorkingHours } from 'state/EmployeeDashboard/Unavailability/selectors';
import { StoreState } from 'state/types';
import { CalendarPopover } from '../CalendarPopover';
import {
  divideByType,
  isCasualContractor,
  showOriginalStatus,
  userCanSubmitUnavailability,
  getDefaultStatuses,
  getStatusClassName,
  getLeaveCardLabel
} from '../../../../helpers';
import { getCurrentUser, getEmployeeType, hasPermissionSelector } from 'state/Auth';
import { PreferencesDateFormat, PreferencesTimeFormat, ServerUserCurrent } from 'type/models';
import { getDateFormat, getTimeFormat } from 'state/Account';
import { isAppMarket } from 'helpers';

type DispatchProps = {
  openModal: (props: OpenModalProps) => void;
  getById: (id: string) => void;
};

type StateProps = {
  workingHours: EmployeeSpreadOfHoursResponse[];
  currentUser: ServerUserCurrent;
  canEditUnavailability: boolean;
  employeeType: string;
  dateFormat: PreferencesDateFormat;
  timeFormat: PreferencesTimeFormat;
};

type Props = {
  data: DayDetails;
} & DispatchProps & StateProps;

export class CalendarCard extends Component<Props> {

  render() {
    return this.showCardContent();
  }

  showCardContent = () => {
    const { period } = this.props.data;
    return period === 'current' ? this.currentMonthCard() : this.otherMonthCard();
  }

  currentMonthCard = () => {
    return (
      <div className="calendar-card calendar-card--current">
        {this.props.data.details === null ? this.getCardContent() : this.getCardContentWithPopover()}
      </div>
    );
  }

  getCardContentWithPopover = () => {
    return (
      <CalendarPopover
        data={this.props.data}
        position={'top-end'}
      >
        {this.getCardContent()}
      </CalendarPopover>
    );
  }

  otherMonthCard = () => {
    const { day, month, period, year } = this.props.data;
    const monthShortName = moment(`${year}-${month}`, 'Y-M').format('MMM');
    return (
      <div className="calendar-card calendar-card--other">
        <Text size={'lg'} className={'day'}>{day}</Text><br/>
        {period === 'future' && day === 1 && <Text size={'lg'} className={'day month'}>{monthShortName}</Text>}
      </div>
    );
  }

  getIcon = (d: EmployeeUnavailabilityProps | null) => {
    const status = this.getStatusOfDetails(d);
    const type = this.getTypeOfDetails(d);
    if ( type === 'unavailability' && status === 'approved' ) {
      return <WorkOffOutlinedIcon />;
    }
    if ( status === 'approved' ) {
      return <CheckIcon />;
    }
    if ( status === 'pending' ) {
      return <AssignmentLateOutlinedIcon />;
    }
    if ( status === 'declined' ) {
      return <CancelOutlinedIcon />;
    }
    return <WorkOutlineIcon />;
  }

  getTypeOfDetails = (details: EmployeeUnavailabilityProps | null) => {
    return details !== null && details.type ? details.type : 'work';
  }

  getStatus = (data: EmployeeUnavailabilityProps[]) => {
    let status = 'work';
    data.forEach((d: EmployeeUnavailabilityProps) => {
      if ( status === 'work' || status === 'approved' ) {
        status = d.status;
      }
    });
    return status;
  }

  getStatusOfDetails = (d: EmployeeUnavailabilityProps | null) => {
    let status = 'work';
    if (d !== null && (d.type === 'unavailability' || d.type === 'leave') ) {
      status = d.status;
    }
    return status;
  }

  statusColorClassName = (timeOffs: EmployeeUnavailabilityProps[]) => {
    let statuses = getDefaultStatuses();

    timeOffs.forEach((d: EmployeeUnavailabilityProps) => {
      const { status, type } = d;
      if (  statuses[type].hasOwnProperty(`${status}`) ) {
        statuses[type][status] = true;
      }
    });

    return getStatusClassName(statuses);
  }

  isOverlapped = (timeOffs: EmployeeUnavailabilityProps[], shifts: TimeOffRosteredShift[]) => {
    let result = false;
    for (let shift of shifts) {
      if ( shift.is_overlap_error ) {
        result = true;
        break;
      }
    }
    if ( result ) {
      for (let timeOff of timeOffs) {
        if ( timeOff.status === 'approved' && timeOff.type === 'unavailability' ) {
          return true;
        }
      }
    }
    return false;
  }

  getCardContent = () => {
    const { day, isToday, details } = this.props.data;
    const dayData = divideByType(details);
    const hasTimeOffs = dayData.timeOffs.length > 0;
    const hasShifts = dayData.shift.length > 0;
    const hasOverlappedShift = hasTimeOffs && hasShifts && this.isOverlapped(dayData.timeOffs, dayData.shift);
    const className = hasOverlappedShift ? 'overlapped' : this.statusColorClassName(dayData.timeOffs);
    const availability = this.showWorkingHours();

    return (

      <div className="calendar-card__content-wrapper" onClick={e => this.openModalIfEmptyCell(dayData.timeOffs.length)}>

        <div className={`calendar-card--status ${className}`}>
          {details === null && availability !== '' && <div className="status-icon"><WorkOutlineIcon /></div>}
          {dayData.timeOffs.map((d: EmployeeUnavailabilityProps, index: number) => (
            index < 2
              ? <div className={`status-icon ${index > 0 ? 'second-icon' : ''}`} key={index}>{this.getIcon(d)}</div>
              : null
          ))}
          {
            hasTimeOffs && dayData.timeOffs.length < 2 && hasShifts
              && <div className="status-icon second-icon"><WorkOutlineIcon /></div>
          }
          { !hasTimeOffs && hasShifts && dayData.shift.map((d: TimeOffRosteredShift, index: number) => (
            index < 2
              ? <div className={`status-icon ${index > 0 ? 'second-icon' : ''}`} key={index}><WorkOutlineIcon /></div>
              : null
          ))}
        </div>

        <div className="calendar-card--content">
          <Heading size={'sm'} isBold={true} className={isToday ? 'is-today day' : 'day'}>{day}</Heading>

          {
            details === null
              && <div className="card-time">{
                  <Text
                    className={'availability-time'}
                    isTruncate={true}
                    size={'xs'}
                    id={availability ? 'card-time' : `card-time-empty-${day}`}
                  >
                    {availability}
                  </Text>
                }</div>
          }

          {dayData.timeOffs.map((d: EmployeeUnavailabilityProps, index: number) => (
            index < 2
              ? this.getTimeOffDescriptionText(d, index)
              : null
          ))}
          {
            hasTimeOffs && dayData.timeOffs.length < 2 && hasShifts
              && this.getRosterDescriptionText(dayData.shift[0], 0, true)
          }
          { !hasTimeOffs && hasShifts && dayData.shift.map((d: TimeOffRosteredShift, index: number) => (
            index < 2
              ? this.getRosterDescriptionText(d, index)
              : null
          ))}

          { dayData.timeOffs.length === 1 && !hasShifts && this.showOriginalStatus(dayData.timeOffs[0]) }

        </div>
      </div>

    );
  }

  showOriginalStatus = (d: EmployeeUnavailabilityProps) => {
    return showOriginalStatus(d.original_status, d.type)
      ? <Paragraph size={'xs'} color={'gray'} className={'original-status'}>{d.original_status}</Paragraph>
      : null;
  }

  getTimeOffDescriptionText = (d: EmployeeUnavailabilityProps, index: number) => {
    return d.type === 'leave'
      ? this.getLeaveDescriptionText(d, index)
      : this.getShiftUnavailabilityDescription(d, index);
  }

  getShiftUnavailabilityDescription = (d: EmployeeUnavailabilityProps, index: number) => {
    const { timeFormat } = this.props;
    const className = index > 0 ? 'second-description' : '';
    const timeText = d.duration === 'few_hours'
      ? `${getTimeFormatted(timeFormat, d.start)} - ${getTimeFormatted(timeFormat, d.end)}`
      : '';
    return (
      <div className={`card-time timeoff-time ${className}`} key={index} onClick={e => this.openModal(d.id, d.type)}>
        <Paragraph className={'card-time__title'} isTruncate={true} size={'xs'}>
          Unavailable
        </Paragraph>
        <Paragraph className={'card-time__time'} isTruncate={true} size={'xs'} color={'gray'}>
          {timeText}
        </Paragraph>
      </div>
    );
  };

  getLeaveDescriptionText = (d: EmployeeUnavailabilityProps, index: number) => {
    const { data } = this.props;
    const className = index > 0 ? 'second-description' : '';
    const label = getLeaveCardLabel(d, data);
    return (
      <div className={`card-time timeoff-time ${className}`} key={index}>
        <Paragraph className={'card-time__title'} isTruncate={true} size={'xs'}>
          {label.title}
        </Paragraph>
        <Paragraph className={'card-time__time'} isTruncate={true} size={'xs'} color={'gray'}>
          {label.subTitle}
        </Paragraph>
      </div>
    );
  }

  getRosterDescriptionText = (d: TimeOffRosteredShift, index: number, isSecond: boolean = false) => {
    const { timeFormat } = this.props;
    const className = index > 0 || isSecond ? 'second-description' : '';
    const timeText = `${getTimeFormatted(timeFormat, d.start)} - ${getTimeFormatted(timeFormat, d.end)}`;
    return (
      <div className={`card-time roster-time ${className}`} key={index}>
        <Paragraph className={'card-time__title'} isTruncate={true} size={'xs'}>
          {isAppMarket('uk') ? 'Scheduled' : 'Rostered'}
        </Paragraph>
        <Paragraph className={'card-time__time'} isTruncate={true} size={'xs'} color={'gray'}>{timeText}</Paragraph>
      </div>
    );
  }

  canCreateEditTimeOffs = () => {
    const { day, month, year } = this.props.data;
    const { canEditUnavailability, employeeType } = this.props;
    return userCanSubmitUnavailability({
      day: day,
      month: month,
      year: year,
      canEditUnavailability: canEditUnavailability,
      employeeType: employeeType
    });
  }

  openModalIfEmptyCell = (timeOffsLength: number) => {
    const { day, month, year } = this.props.data;
    const date = moment(`${year}-${month}-${day}`, 'Y-M-DD');
    if ( timeOffsLength === 0 && this.canCreateEditTimeOffs() ) {
      this.props.openModal({
        type: 'create',
        initialDate: date
      });
    }
  }

  openModal = (id: string, timeOffType: string) => {
    const type = this.canCreateEditTimeOffs() ? 'edit' : 'view';
    if (timeOffType === 'leave') {
      return false;
    } else {
      this.props.openModal({ type: type });
      this.props.getById(id);
    }
  }

  showWorkingHours = () => {
    const { workingHours, data, timeFormat } = this.props;
    const { day, month, year } = data;
    const date = `${year}-${(month < 10 ? ('0' + month) : month)}-${(day < 10 ? ('0' + day) : day)}`;
    for ( let workItem of workingHours ) {
      if ( workItem.date === date && workItem.start ) {
        const start = `${workItem.date} ${workItem.start}`;
        const end = `${workItem.date} ${workItem.end}`;
        return `${getTimeFormatted(timeFormat, start)} - ${getTimeFormatted(timeFormat, end)}`;
      }
    }
    return '';
  }

}

const mapStateToProps = (state: StoreState): StateProps => ({
  workingHours: getWorkingHours(state),
  currentUser: getCurrentUser(state),
  employeeType: getEmployeeType(state),
  canEditUnavailability: hasPermissionSelector(state, 'employeedashboard.submitunavailability'),
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state)
});

const mapToDispatchProps: DispatchProps = {
  openModal: BOX_EMPLOYEE_UNAVAILABILITY_MODAL_OPEN,
  getById: BOX_EMPLOYEE_GET_UNAVAILABILITY_BY_ID
};

export default connect(mapStateToProps, mapToDispatchProps)(CalendarCard);
