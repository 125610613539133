import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import {
  getIsOpened,
  BOX_SHORTCUTS_MODAL_CLOSE,
} from 'state/Roster/ShortcutsModal';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';
import { PageDialog, PageDialogCancel } from 'element';
import { Typography } from 'oxygen-elements';

type OwnProps = {};

type StateProps = {
  isOpened: boolean;
};

type DispatchProps = {
  closeModal: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

export const ShortcutsModalComponent = ({ isOpened, closeModal }: Props) => {
  const handleClose = () => {
    closeModal();
  };

  return (
    <PageDialog
      maxWidth={'xs'}
      open={isOpened}
      id={'roster-shortcuts'}
      onClose={handleClose}
    >
      <DialogTitle>Shortcuts</DialogTitle>
      <DialogContent>
        <Typography variant={'body1'} sx={{ paddingBottom: '10px' }}>
          <strong>Arrows</strong> - Navigate between cells
        </Typography>

        <Typography variant={'body1'} sx={{ paddingBottom: '10px' }}>
          <strong>Space</strong> - Add new row
        </Typography>

        <Typography variant={'body1'} sx={{ paddingBottom: '10px' }}>
          <strong>Enter</strong> - Create shift with inline input functionality
        </Typography>

        <Typography variant={'body1'} sx={{ paddingBottom: '10px' }}>
          <strong>Shift + Enter</strong> - Create shift with modal
        </Typography>

        <Typography variant={'body1'} sx={{ paddingBottom: '10px' }}>
          <strong>Esc</strong> - Close inline input
        </Typography>
      </DialogContent>
      <DialogActions>
        <PageDialogCancel onClick={handleClose}>Close</PageDialogCancel>
      </DialogActions>
    </PageDialog>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  isOpened: getIsOpened(state),
});

const mapDispatchToProps: DispatchProps = {
  closeModal: BOX_SHORTCUTS_MODAL_CLOSE,
};

export const ShortcutsModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShortcutsModalComponent);
