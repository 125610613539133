import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  ListItemText,
} from 'oxygen-elements';
import { useDispatch, useSelector } from 'react-redux';
import {
  BOX_ROSTERS_PRINT,
  BOX_ROSTERS_PRINT_OPEN,
  BOX_ROSTERS_SET_PRINT_OPTIONS,
  getIsModalOpened,
  getPrintOptions,
} from 'state/Roster/Print';
import { DialogContent, Checkbox } from 'extended-oxygen-elements';
import { isAppMarket } from 'helpers';
import { ListItemButton } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { getEventSettings } from 'state/Account';

const labels = {
  assigned: 'Print Assigned shifts',
  unassigned: 'Print Unassigned shifts',
  unpublished: 'Print unpublished shifts',
  events: 'Print Events & Forecasts',
};

const useModalStyles = makeStyles((theme) =>
  createStyles({
    item: {
      borderBottom: '1px solid #ddd',
      borderRadius: 0,
    },
  })
);

export const PrintModal = () => {
  const styles = useModalStyles();
  const isOpened = useSelector(getIsModalOpened);
  const options = useSelector(getPrintOptions);
  const eventsSettings = useSelector(getEventSettings);
  const isEventsVisible = eventsSettings.enable_events;

  const [checkboxes, setCheckbox] = useState({
    ...options,
    events: eventsSettings.enable_events,
  });

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(BOX_ROSTERS_PRINT_OPEN(false));
  };

  const restoreOptions = () => {
    const restoredOptions = {
      assigned: true,
      unassigned: true,
      unpublished: true,
      events: eventsSettings.enable_events,
    };
    BOX_ROSTERS_SET_PRINT_OPTIONS(restoredOptions);
    setCheckbox(restoredOptions);
  };

  useEffect(() => {
    return () => {
      restoreOptions();
    };
  }, [isOpened]);

  const onPrint = () => {
    dispatch(BOX_ROSTERS_SET_PRINT_OPTIONS(checkboxes));
    dispatch(BOX_ROSTERS_PRINT(true));
    dispatch(BOX_ROSTERS_PRINT_OPEN(false));
    setTimeout(() => {
      restoreOptions();
    }, 3000);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, checked },
    } = e;
    setCheckbox((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const setCheckboxFromListItem = (name: string) => {
    if (
      name === 'unpublished' &&
      !checkboxes.assigned &&
      !checkboxes.unassigned
    ) {
      return false;
    }
    setCheckbox((prevState) => ({
      ...prevState,
      [name]: !(checkboxes as any)[name],
    }));
  };

  const getListItem = (name: string, key: number) => {
    if (name === 'events' && !isEventsVisible) {
      return null;
    }
    return (
      <ListItemButton
        className={styles.item}
        key={`item-key-${key}-${name}`}
        onClick={(e) => setCheckboxFromListItem(name)}
      >
        <Checkbox
          name={name}
          checked={(checkboxes as any)[name]}
          onChange={onChange}
          data-testId={`print-${name}`}
          disabled={
            name === 'unpublished'
              ? !checkboxes.assigned && !checkboxes.unassigned
              : false
          }
        />
        <ListItemText disableTypography>{(labels as any)[name]}</ListItemText>
      </ListItemButton>
    );
  };

  return (
    <Dialog
      id="print-modal"
      open={isOpened}
      maxWidth={'xs'}
      keepMounted={false}
      transitionDuration={50}
    >
      <DialogTitle>Print {isAppMarket('uk') ? 'rota' : 'rosters'}</DialogTitle>
      <DialogContent>
        {Object.keys(checkboxes).map((name: string, key: number) =>
          getListItem(name, key)
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} id="close-print-modal">
          Cancel
        </Button>
        <Button
          onClick={onPrint}
          data-testid={'print-btn'}
          variant="contained"
          id="print-shifts-btn"
        >
          Print
        </Button>
      </DialogActions>
    </Dialog>
  );
};
