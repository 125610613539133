import { cloneDeep } from 'lodash';
import { DashboardShift } from 'type';
import moment from 'moment';
import { getDatesDiff } from 'lib/helpers';

export const shiftPeriod = (sh: DashboardShift) => {
  const shift = cloneDeep(sh);

  const hours = (getDatesDiff(shift.start, moment(), false) as number) / 3600;
  const isTomorrow =
    (getDatesDiff(
      shift.start.endOf('day'),
      moment().endOf('day'),
      false
    ) as number) /
      3600 <=
    48;

  let periods = [];

  if (-12 <= hours && hours <= 12) {
    periods.push('soon');
  }

  if (isTomorrow) {
    periods.push('tomorrow');
  }

  return periods;
};

export const timeSheetPeriod = (shift: DashboardShift) => {
  let period = '';

  if (!shift.end) {
    period = 'now';
  } else {
    period = 'just-finished';
  }

  return period;
};
