import { createAction } from 'lib/store-utils';
import { FormattedErrors, CopyPasteJob } from 'type';
import {
  CopyPayload,
  PastRostersType,
  TemplatesListResponse,
  GetTemplatesListPayload,
  DeleteTemplatePayload,
  ApplyRosterTemplatePayload,
  ApplyRosterTemplateResponse
} from './type';
import { JobIds } from './type';

export const BOX_ROSTER_COPY_WEEK = createAction<CopyPayload>('Roster copy / copy week');
export const BOX_ROSTER_COPY_WEEK_CLEAR = createAction('Roster copy / copy week clear');

export const BOX_ROSTER_PAST_WEEK_REQUEST = createAction<PastRostersType>('Roster copy / past week request');
export const BOX_ROSTER_PAST_WEEK_SUCCESS = createAction('Roster copy / past week success');
export const BOX_ROSTER_PAST_WEEK_FAILED = createAction('Roster copy / past week failed');

export const BOX_ROSTER_PAST_CONFIRMATION_MODAL_OPEN = createAction('Roster copy / open past confirmation modal');
export const BOX_ROSTER_PAST_CONFIRMATION_MODAL_CLOSE = createAction('Roster copy / close past confirmation modal');

export const BOX_ROSTER_PAST_ALERT_STATUS_HIDE = createAction('Roster copy / past alert hide');

export const BOX_ROSTER_PAST_JOB_RECEIVED = createAction<CopyPasteJob>('Roster copy / job received');
export const BOX_ROSTER_PAST_JOBS_RECEIVED = createAction<JobIds>('Roster copy / Jobs updated on load page');
export const BOX_ROSTER_PAST_JOB_ADD = createAction<string>('Roster copy / save job');
export const BOX_ROSTER_PAST_JOB_DELETE = createAction<string>('Roster copy / delete job');

export const BOX_ROSTER_OPEN_SAVE_TEMPLATE_MODAL = createAction('Roster copy / save template modal open');
export const BOX_ROSTER_CLOSE_SAVE_TEMPLATE_MODAL = createAction('Roster copy / save template modal close');

export const BOX_ROSTER_SAVE_TEMPLATE = createAction('Roster copy / save template by name');
export const BOX_ROSTER_SAVE_TEMPLATE_SUCCESS = createAction('Roster copy / save template by name success');
export const BOX_ROSTER_SAVE_TEMPLATE_FAILURE = createAction<FormattedErrors>('Roster copy / save template by name failure');

export const BOX_ROSTER_SAVE_TEMPLATE_CLEAR_ERRORS = createAction('Roster copy / save template / clear errors');

export const BOX_ROSTER_OPEN_TEMPLATES_MODAL = createAction('Roster copy / open templates modal');
export const BOX_ROSTER_CLOSE_TEMPLATES_MODAL = createAction('Roster copy / close templates modal');

export const BOX_ROSTER_GET_TEMPLATES_LIST = createAction<GetTemplatesListPayload>('Roster copy / get list templates');
export const BOX_ROSTER_GET_TEMPLATES_LIST_SUCCESS = createAction<TemplatesListResponse>('Roster copy / get list templates success');
export const BOX_ROSTER_GET_TEMPLATES_LIST_FAILURE = createAction<FormattedErrors>('Roster copy / get list templates failure');

export const BOX_ROSTER_DELETE_TEMPLATE = createAction<DeleteTemplatePayload>('Roster copy / delete template request');
export const BOX_ROSTER_DELETE_TEMPLATE_SUCCESS = createAction<DeleteTemplatePayload>('Roster copy / delete template success');
export const BOX_ROSTER_DELETE_TEMPLATE_FAILURE = createAction<FormattedErrors>('Roster copy / delete template failure');
export const BOX_ROSTER_TEMPLATES_MODAL_CLEAR_ERRORS = createAction('Roster copy / clear errors in templates modal');

export const BOX_ROSTER_APPLY_TEMPLATE = createAction<ApplyRosterTemplatePayload>('Roster apply / apply template');
export const BOX_ROSTER_APPLY_TEMPLATE_SUCCESS = createAction<ApplyRosterTemplateResponse>('Roster apply / apply template success');
export const BOX_ROSTER_APPLY_TEMPLATE_FAILURE = createAction<FormattedErrors>('Roster apply / apply template failure');
