import { useRouteQuery, useSelectorWithProps } from 'hooks';
import { SERVER_DAY_FORMAT } from 'lib/config';
import { dayValidator } from 'lib/validators';
import moment from 'moment';
import React from 'react';
import { activeSiteSafeSelector } from 'state/AccountTree';
import QueryItemProvider from './QueryItemProvider';

type Props = {
  children: React.ReactNode;
};

export default QueryDayProvider;

export function QueryDayProvider({ children }: Props) {
  const {
    query: { site_id },
  } = useRouteQuery();

  const defaultDay = useDefaultDay(site_id);

  return (
    <QueryItemProvider name="day" default={defaultDay} validator={dayValidator}>
      {children}
    </QueryItemProvider>
  );
}

function useDefaultDay(site_id: string | undefined) {
  const site = useSelectorWithProps(activeSiteSafeSelector, site_id);
  const timezone = site?.timezone_id || moment.tz.guess();

  return moment().tz(timezone).format(SERVER_DAY_FORMAT);
}
