import moment from 'moment';
import { DateTimeType } from 'type';

type DateRange = { start: DateTimeType; end: DateTimeType };

export default getBreakStartMoment;

export function getBreakStartMoment(breakStartMinutes: number, dateRange: DateRange) {
  const breakStart = moment(dateRange.start)
    .startOf('day')
    .add(breakStartMinutes, 'minutes');

  const nextDayBreakStart = breakStart.clone().add(1, 'day');

  if (
    getIsMidnightShift(dateRange) &&
    nextDayBreakStart.isBetween(dateRange.start, dateRange.end)
  ) {
    return nextDayBreakStart;
  }

  return breakStart;
}

function getIsMidnightShift(dateRange: DateRange) {
  const startDay = moment(dateRange.start).startOf('day');
  const endDay = moment(dateRange.end).startOf('day');
  const diffDays = endDay.diff(startDay, 'days');
  return !!Math.max(diffDays, 0);
}
