import { LoadingHandler } from 'state/Loading';
import { ErrorsHandler } from 'state/Errors';
import { StatusHandler } from 'state/Status';
import * as Account from 'state/Account';
import * as actions from './actions';

/**
 * Fetching
 * ===
 * */
export const getPageFetching = LoadingHandler()
  .handleAsyncLoading(actions.BOX_PAY_CONDITIONS_PAGE_DATA_GET)
  .makeSelector();

export const getPageFetchErrors = ErrorsHandler()
  .handleAsyncErrors(actions.BOX_PAY_CONDITIONS_PAGE_DATA_GET)
  .makeSelector();

/**
 * Updating
 * ===
 * */
export const getConditionsPageUpdating = LoadingHandler()
  .handleAsyncLoading(Account.BOX_ACCOUNT_PAY_CONDITIONS_WEEKEND_LOADINGS_TOGGLE)
  .makeSelector();

export const getConditionsModalUpdating = LoadingHandler()
  .handleAsyncLoading(Account.BOX_ACCOUNT_PAY_CONDITIONS_RULE_DELETE)
  .makeSelector();

export const getPayConditionFormUpdating = LoadingHandler()
  .handleAsyncLoading(Account.BOX_ACCOUNT_PAY_CONDITIONS_RULE_CREATE)
  .handleAsyncLoading(Account.BOX_ACCOUNT_PAY_CONDITIONS_RULE_UPDATE)
  .makeSelector();

/**
 * Errors
 * ===
 * */
export const getPageErrors = ErrorsHandler()
  .clearErrors(actions.BOX_PAY_CONDITIONS_PAGE_ERRORS_CLOSED)
  .clearErrors(actions.BOX_PAY_CONDITIONS_PAGE_DIALOG_OPENED)
  .handleAsyncErrors(Account.BOX_ACCOUNT_PAY_CONDITIONS_WEEKEND_LOADINGS_TOGGLE)
  .handleAsyncErrors(Account.BOX_ACCOUNT_PAY_CONDITIONS_RULE_CREATE)
  .handleAsyncErrors(Account.BOX_ACCOUNT_PAY_CONDITIONS_RULE_UPDATE)
  .makeSelector();

export const getModalErrors = ErrorsHandler()
  .clearErrors(actions.BOX_PAY_CONDITIONS_PAGE_DIALOG_ERRORS_CLOSED)
  .clearErrors(actions.BOX_PAY_CONDITIONS_PAGE_DIALOG_OPENED)
  .handleAsyncErrors(Account.BOX_ACCOUNT_PAY_CONDITIONS_RULE_DELETE)
  .makeSelector();

/**
 * Statuses
 * ===
 * */
export const getPayConditionsFormRequestStatus = StatusHandler()
  .handleAsyncStatus(Account.BOX_ACCOUNT_PAY_CONDITIONS_RULE_CREATE)
  .handleAsyncStatus(Account.BOX_ACCOUNT_PAY_CONDITIONS_RULE_UPDATE)
  .makeSelector();

export const getConditionsModalRequestStatus = StatusHandler()
  .handleAsyncStatus(Account.BOX_ACCOUNT_PAY_CONDITIONS_RULE_DELETE)
  .makeSelector();
