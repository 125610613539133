import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Timesheet } from 'type';
import { useSelectorWithProps } from 'hooks';
import { hasPermissionSelector } from 'state/Auth';
import { getIsApprovalModeEnabled } from 'state/Roster/Roster/selectors';
import { getIsBulkDeleteOpened } from 'state/Roster/BulkActions/selectors';
import { BOX_TIMESHEET_SHIFT_MODAL_GET_BY_ID } from 'state/TimesheetModal';
import { getIsCovidModeEnabled } from 'state/Roster/CovidTracing';

export const useHandleClickAddButton = ({
  start: timesheetStart,
  user_id,
  area_id,
  role_id,
}: Timesheet): React.MouseEventHandler<HTMLElement> | undefined => {
  const dispatch = useDispatch();
  const canEditShift = useSelectorWithProps(
    hasPermissionSelector,
    'roster.timesheet.edit'
  );
  const isApprovalMode = useSelector(getIsApprovalModeEnabled);
  const isBulkDelete = useSelector(getIsBulkDeleteOpened);
  const isCovidMode = useSelector(getIsCovidModeEnabled);
  const canAddShift =
    canEditShift && !isBulkDelete && !isApprovalMode && !isCovidMode;

  const start = timesheetStart.clone().startOf('day');
  const end = start.clone().add(1, 'day').startOf('day');

  const handleClickAddButton = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      dispatch(
        BOX_TIMESHEET_SHIFT_MODAL_GET_BY_ID({
          id: '',
          start,
          end,
          role_id,
          area_id,
          user_id,
        })
      );
    },
    [dispatch, start.format(), end.format(), role_id, area_id, user_id]
  );

  if (canAddShift) {
    return handleClickAddButton;
  }
};
