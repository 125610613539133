import React, { Component } from 'react';
import { FormItem, Radio, Select } from 'elmo-elements';
import {
  AccountTreeSite,
  LanguagePreferences,
  StringMap,
  UpdateFormPayload,
} from 'type';
import { getPreferenceLabel, keyMirror } from 'lib/helpers';
import {
  CheckboxesCollection,
  FromToFilters,
  SortDirectionRadioButtons,
  SortFieldRadioButtons,
} from 'element';

import { ShiftTradesCustomFilter } from 'state/EmployeeDashboard/ShiftTrades/types';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import { getLangPreferences } from 'state/Account';

export type Props = {
  formData: ShiftTradesCustomFilter;
  onChange: (payload: UpdateFormPayload) => void;
  onTabChange: () => void;
  sites: StringMap<AccountTreeSite>;
};

export type StateProps = {
  langPreferences: LanguagePreferences;
};

const getSortByPositionLabel = (langPreferences: LanguagePreferences) => {
  const pos = getPreferenceLabel(langPreferences, 'role', 'singular', '', true);
  const area = getPreferenceLabel(langPreferences, 'area', 'singular');
  const site = getPreferenceLabel(langPreferences, 'site', 'singular');
  return `${pos} - ${area}, ${site}`;
};

class CustomFiltersView extends Component<Props & StateProps> {
  readonly inputNames = keyMirror(this.props.formData);

  readonly sortFields: {
    label: string;
    value: string;
  }[] = [
    {
      label: 'Date',
      value: 'start'
    },
    {
      label: getSortByPositionLabel(this.props.langPreferences),
      value: 'role'
    }
  ];

  readonly types: {
    label: string;
    value: string;
  }[] = [
    {
      label: 'Swap',
      value: 'swap'
    },
    {
      label: 'Offer',
      value: 'offer'
    }
  ];

  render() {
    const {
      inputNames,
      props: { formData }
    } = this;

    return (
      <form autoComplete={'off'}>
        <FormItem label={'Show'} isDivider={true}>
          <Radio
            value={'available'}
            checked={formData.selectedFilter === 'available'}
            onChange={this.changeActiveTab}
          >
            Available shifts
          </Radio>
          <Radio
            value={'offered'}
            checked={formData.selectedFilter === 'offered'}
            onChange={this.changeActiveTab}
          >
            Shifts you've offered
          </Radio>
          <Radio
            value={'applied'}
            checked={formData.selectedFilter === 'applied'}
            onChange={this.changeActiveTab}
          >
            Shifts you've applied
          </Radio>
        </FormItem>
        <CheckboxesCollection
          formItem={{
            label: 'Type',
            isDivider: true
          }}
          name={inputNames.types}
          value={formData.types}
          onChange={this.props.onChange}
          options={this.types}
          hideAllCheckbox={true}
        />

        <FormItem
          label={getPreferenceLabel(
            this.props.langPreferences,
            'site',
            'singular',
            '',
            true
          )}
        >
          <Select
            options={this.options}
            defaultValue={this.getSelectedPosition()}
            onChange={this.onChangePosition}
            name="testing name attr"
            ariaLabel={getPreferenceLabel(
              this.props.langPreferences,
              'site',
              'singular',
              '',
              true
            )}
          />
        </FormItem>

        <FromToFilters
          from={{
            name: inputNames.from,
            value: formData.from
          }}
          to={{ name: inputNames.to, value: formData.to }}
          onChange={this.props.onChange}
        />

        <FormItem label="Sort by">
          <SortFieldRadioButtons
            selected={formData.sort.column}
            onChange={this.onChangeSortField}
            sortFields={this.sortFields}
          />
        </FormItem>

        <FormItem>
          <SortDirectionRadioButtons
            selected={formData.sort.direction}
            onChange={this.onChangeSortDirection}
          />
        </FormItem>
      </form>
    );
  }

  readonly options: {
    label: string;
    value: string;
  }[] = [
    { value: '', label: 'All' },
    ...Object.keys(this.props.sites).map(key => {
      return {
        value: this.props.sites[key].id,
        label: this.props.sites[key].name
      };
    })
  ];

  private changeActiveTab = (value: any) => {
    this.props.onTabChange();
    this.props.onChange({
      name: 'selectedFilter',
      value: value
    });
  };

  private onChangeSortField = (column: string | number) => {
    this.props.onChange({
      name: this.inputNames.sort,
      value: {
        column,
        direction: this.props.formData.sort.direction
      }
    });
  };

  private onChangeSortDirection = (direction: string | number) => {
    this.props.onChange({
      name: this.inputNames.sort,
      value: {
        column: this.props.formData.sort.column,
        direction
      }
    });
  };

  private getSelectedPosition = () => {
    const { formData, sites } = this.props;
    if (formData.site && formData.site !== null) {
      return {
        value: formData.site,
        label: sites[formData.site].name
      };
    }
    return {
      value: '',
      label: 'All'
    };
  };

  private onChangePosition = (data: { label: string; value: string }) => {
    const { value } = data;
    const name = 'site';
    this.props.onChange({
      name,
      value
    });
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  langPreferences: getLangPreferences(state)
});

export default connect(mapStateToProps)(CustomFiltersView);
