import React from 'react';
import LoaderHolder from './LoaderHolder';
import { LoaderHolderProps } from '../type';

function ContentStyle(props: LoaderHolderProps) {
  return (
    <LoaderHolder {...props}>
      <rect x="50" y="15" rx="4" ry="4" width="117" height="10" />
      <rect x="50" y="35" rx="3" ry="3" width="85" height="10" />
      <rect x="0" y="80" rx="3" ry="3" width="350" height="10" />
      <rect x="0" y="100" rx="3" ry="3" width="380" height="10" />
      <rect x="0" y="120" rx="3" ry="3" width="201" height="10" />
      <circle cx="20" cy="30" r="20" />
    </LoaderHolder>
  );
}

export default ContentStyle;
