import React, { Component, SyntheticEvent } from 'react';
import { without } from 'lodash';
import nanoid from 'nanoid';
import { Checkbox, FormItem } from 'elmo-elements';
import { FormItemProps } from 'elmo-elements/FormItem';
import { SelectPropsOption } from 'type';

type Value = null | string[];

type Props = {
  options: SelectPropsOption[];
  name: string;
  value: Value;
  onChange: (payload: { name: string; value: Value }) => void;
  formItem: Pick<FormItemProps, 'icon' | 'isDivider' | 'label' | 'id'>;
  hideAllCheckbox?: boolean;
};

export class CheckboxesCollection extends Component<Props> {
  readonly randomId: string = nanoid();

  render() {
    const { options, name, formItem, hideAllCheckbox } = this.props;
    return (
      <FormItem {...formItem}>
        {!hideAllCheckbox && (
          <Checkbox
            id={this.randomId}
            label="All"
            name={name}
            isChecked={this.selectedOptionIds.length === this.optionIds.length}
            onChange={this.onChangeAllCheckbox}
          />
        )}

        {options.map(({ label, value }) => (
          <Checkbox
            key={value}
            id={value}
            label={label}
            name={name}
            value={value}
            isChecked={this.selectedOptionIds.includes(value)}
            onChange={this.onChangeCombinedCheckbox}
          />
        ))}
      </FormItem>
    );
  }

  private get optionIds(): string[] {
    return this.props.options.map(option => option.value);
  }

  private get selectedOptionIds(): string[] {
    const { value } = this.props;

    return value ? value : this.optionIds;
  }

  private onChangeAllCheckbox = ({
    currentTarget: { name, checked }
  }: SyntheticEvent<HTMLInputElement>) => {
    this.props.onChange({
      name,
      value: checked ? null : []
    });
  };

  private onChangeCombinedCheckbox = ({
    currentTarget: { checked, value: checkboxValue }
  }: SyntheticEvent<HTMLInputElement>) => {
    const selectedOptionIds: string[] = checked
      ? [...this.selectedOptionIds, checkboxValue]
      : without(this.selectedOptionIds, checkboxValue);

    const { name } = this.props;
    const value: Value = this.updateValue(selectedOptionIds);

    this.props.onChange({
      name,
      value
    });
  };

  private updateValue(optionIds: string[]): Value {
    return optionIds.length === this.optionIds.length ? null : optionIds;
  }
}
