import React, { Component, ReactChild } from 'react';
import { connect } from 'react-redux';
import { Filter } from 'elmo-elements';
import { MapTupleToOption } from 'type';
import { StoreState } from 'state/types';
import { CustomFilters } from './components/CustomFilters';
import { LanguagePreferences } from 'type/models';
import { isFiltersFormDisabledSelector } from 'state/EmployeeDashboard/Events';
import {
  BOX_MY_ROSTERS_APPLY_FORM_FILTERS_REQUEST,
  BOX_MY_ROSTERS_TOGGLE_PERIOD_TYPE_REQUEST,
  EmployeeShiftPeriodShortTuple,
  getSelectedPeriod,
  TogglePeriodRequestPayload,
} from 'state/EmployeeDashboard/MyRosters';
import { getLangPreferences } from 'state/Account';

type PeriodOptions = MapTupleToOption<EmployeeShiftPeriodShortTuple>;

type StateProps = {
  selectedFilter: TogglePeriodRequestPayload;
  isFiltersFormDisabled: boolean;
  langPreferences: LanguagePreferences;
};

type DispatchProps = {
  updateCustomFilters: () => void;
  togglePeriodFilter: (period: TogglePeriodRequestPayload) => void;
};

type Props = StateProps & DispatchProps;

export class MyRostersFiltersComponent extends Component<Props> {
  render() {
    const {
      updateCustomFilters,
      selectedFilter,
      isFiltersFormDisabled
    } = this.props;

    return (
      <Filter
        id="my-rosters-filters"
        onStatusChange={this.onStatusChange}
        statuses={this.statuses}
        renderCustomFilters={this.renderCustomFilters}
        onApplyCustomFilters={updateCustomFilters}
        selectedValue={selectedFilter}
        isApplyBtnDisabled={isFiltersFormDisabled}
      />
    );
  }

  get statuses(): PeriodOptions {
    return [
      {
        label: 'Today',
        value: 'today'
      },
      {
        label: 'Next 7 days',
        value: 'next_7_days'
      },
      {
        label: 'Next 30 days',
        value: 'next_30_days'
      },
      {
        label: `All future ${this.props.langPreferences.roster.plural}`,
        value: 'all_future'
      },
      {
        label: 'Past 7 days',
        value: 'last_7_days'
      },
      {
        label: 'Past 30 days',
        value: 'last_30_days'
      }
    ];
  }

  onStatusChange = (period: string) => {
    this.props.togglePeriodFilter(period as TogglePeriodRequestPayload);
  };

  renderCustomFilters = (): ReactChild => <CustomFilters />;
}

const mapStateToProps = (state: StoreState): StateProps => ({
  selectedFilter: getSelectedPeriod(state),
  isFiltersFormDisabled: isFiltersFormDisabledSelector(state),
  langPreferences: getLangPreferences(state)
});

const mapDispatchToProps: DispatchProps = {
  updateCustomFilters: BOX_MY_ROSTERS_APPLY_FORM_FILTERS_REQUEST,
  togglePeriodFilter: BOX_MY_ROSTERS_TOGGLE_PERIOD_TYPE_REQUEST
};
export const MyRostersFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyRostersFiltersComponent);
