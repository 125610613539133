import React from 'react';
import { Alert } from 'extended-oxygen-elements';
import { FlagWrapper } from '../feature-flags-components';
import { useSelector } from 'react-redux';
import { getTimesheetSettings } from 'state/Account';

export const DefaultTimesheetBreaksAlert = () => {
  const { auto_breaks, breaks_from_rules, breaks_from_shift } =
    useSelector(getTimesheetSettings);
  if (!auto_breaks) {
    return null;
  }
  return (
    <>
      {breaks_from_rules && (
        <Alert severity="info">
          Please note: if breaks are not added manually they will be
          automatically applied from the Default break rules
        </Alert>
      )}
      {breaks_from_shift && (
        <Alert severity="info">
          Please note: if breaks are not added manually they will be
          automatically applied from the connected shift in case it is exists
        </Alert>
      )}
    </>
  );
};
