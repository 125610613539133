import {
  DeleteOutlinedIcon,
  EditOutlinedIcon,
  MoreHorizOutlinedIcon,
} from 'element';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from 'extended-oxygen-elements';
import { bindMenu, bindTrigger, usePopupState } from 'hooks';
import React from 'react';

type Props = {
  onDeleteClick: () => void;
  onEditClick: () => void;
  disabled: boolean;
};

export default function MoreBtnOptions({
  onDeleteClick,
  onEditClick,
  disabled,
}: Props) {
  const menuPopupState = usePopupState();

  const makeHandleMenuDeleteClick = () => () => {
    onDeleteClick();
    menuPopupState.close();
  };

  const makeHandleMenuEditClick = () => () => {
    onEditClick();
    menuPopupState.close();
  };

  return (
    <>
      <IconButton
        {...bindTrigger(menuPopupState)}
        aria-label="More options"
        disabled={disabled}
      >
        <MoreHorizOutlinedIcon />
      </IconButton>
      <Menu {...bindMenu(menuPopupState)}>
        <li>
          <MenuItem component="div" onClick={makeHandleMenuEditClick()}>
            <ListItemIcon>
              <EditOutlinedIcon />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        </li>
        <li>
          <MenuItem component="div" onClick={makeHandleMenuDeleteClick()}>
            <ListItemIcon>
              <DeleteOutlinedIcon />
            </ListItemIcon>
            <ListItemText>Remove</ListItemText>
          </MenuItem>
        </li>
      </Menu>
    </>
  );
}
