import React, { Component }  from 'react';
import { Notification } from 'elmo-elements';
import { StoreState } from 'state/types';
import {
  BOX_REPORTS_DELETE_REQUEST,
  BOX_RESTORE_DELETED_REPORT
} from 'state/Reports';
import { connect } from 'react-redux';
import { Report } from 'type/models/report';

type NotificationType = {
  text: string,
  undo?: () => void;
};

type StateProps = {
  queue: any[];
  reports: any;
  report: Report;
};

type DispatchProps = {
  deleteReports: (params: any) => void;
  restoreReport: (id: string) => void;
};

type State = {
  notification: NotificationType;
  isActive: boolean;
  didUndo: boolean;
  loadingOverlay: boolean;
};

type Props = DispatchProps & StateProps;

class DeleteReportNotification extends Component<Props, State> {

  state = {
    isActive: false,
    notification: {
      text: '',
      undo: () => { console.log('empty'); }
    },
    didUndo: false,
    loadingOverlay: false
  };

  deleteTimeouts: any = {};
  undoneTimeouts: any = {};
  timeout: any;
  unloadFunc: any;

  showDeleteNotification = () => {
    const { reports } = this.props;

    if ( this.props.queue.length ) {

      this.clearDeleteTimeouts();

      if ( this.props.queue.length > 1 ) {
        const x = this.props.queue[0];
        this.props.deleteReports({
          id: x
        });
        return false;
      }

      let id = this.props.queue[this.props.queue.length - 1];
      if ( id && reports[id] ) {
        this.setState(
          {
            isActive: true,
            didUndo: false,
            notification: this.getNotificationMsg(reports[id].name)
          },
          () => {
            this.timeout = setTimeout(
              () => {
                this.setState({
                  isActive: false
                });
                this.props.deleteReports({
                  id: id
                });
              },
              7000
            );
            this.deleteTimeouts[this.timeout] = {
              timeout: this.timeout,
              id: id
            };
          }
        );
      }
    }
  }

  getNotificationMsg = (reportTitle: string, isUndone = false) => {
    if ( isUndone ) {
      return {
        text: `Undone`
      };
    }
    return {
      text: `Report deleted`,
      undo: this.revertDeleted
    };
  }

  showUndoneNotification = (name: string) => {
    this.setState(
      {
        isActive: true,
        didUndo: false,
        notification: this.getNotificationMsg(name, true)
      },
      () => {
        this.timeout = setTimeout(
          () => {
            this.setState({
              isActive: false
            });
          },
          3000
        );
        this.undoneTimeouts[this.timeout] = {
          id: '',
          timeout: this.timeout
        };
      }
    );
  }

  revertDeleted = () => {
    const {id, timeout} = this.deleteTimeouts[this.timeout];
    this.setState(
      {
        isActive: false,
        didUndo: true
      }
    );
    clearTimeout(timeout);
    this.props.restoreReport(id);
    setTimeout(
      () => {
        this.showUndoneNotification(this.props.reports[id].name);
      },
      200
    );
  }

  clearUndoneTimeout = () => {
    const timeout = this.timeout && this.undoneTimeouts[this.timeout];
    if ( timeout ) {
      clearTimeout(this.timeout);
    }
  }

  showNewDeleteNotification = () => {
    setTimeout(
      () => {
        this.showDeleteNotification();
      },
      200
    );
  }

  clearDeleteTimeouts = () => {
    Object.keys(this.deleteTimeouts).forEach((key: any) => {
      if ( this.deleteTimeouts[key].timeout !== 'undefined' ) {
        clearTimeout(this.deleteTimeouts[key].timeout);
      }
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {

    const { queue, reports: { errors } } = this.props;
    const { didUndo } = this.state;

    if ( prevProps !== this.props && queue.length > 0 && !didUndo && !errors ) {
      this.clearUndoneTimeout();
      this.hideNotification();
      this.showNewDeleteNotification();
    }

    if ( queue.length === 0 ) {
      this.clearDeleteTimeouts();
    }

    if ( errors && errors.length ) {
      this.clearDeleteTimeouts();
    }

  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  hideNotification = () => {
    this.setState({
      isActive: false
    });
  }

  render() {
    const { notification } = this.state;
    const hasUndo = !!notification.undo;
    return (
      <>
        {notification && hasUndo && <Notification
          isActive={this.state.isActive}
          message={notification.text}
          onClose={this.hideNotification}
          onUndo={this.revertDeleted}
        />}
        { notification && !hasUndo && <Notification
            isActive={this.state.isActive}
            message={notification.text}
            onClose={this.hideNotification}
        />}
      </>
    );
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  queue: state.reports.deleteQueue,
  reports: state.reports.reports,
  report: state.report.report,
});

const mapDispatchToProps: DispatchProps = {
  deleteReports: BOX_REPORTS_DELETE_REQUEST,
  restoreReport: BOX_RESTORE_DELETED_REPORT
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteReportNotification);
