import React from 'react';
import {
  bindHoverTrigger,
  useHoverPopupState,
  useSelectorWithProps,
} from 'hooks';
import { timeOffById } from 'state/TimeOffs';
import { WorkOffOutlinedIcon, NotesIconButton, IsAppMarket } from 'element';
import { createStyles, makeStyles } from '@mui/styles';
import { TimeDetails } from './components';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { getSiteId } from 'state/RosteredShiftsCollection';
import { getSites } from 'state/AccountTree';
import { isMobileDevice, getLeaveSubtypeLabel } from 'lib/helpers';
import { Moment } from 'moment';
import { PartDayText } from './components/PartDayText';
import { SERVER_DAY_FORMAT } from '../../../../lib/config';

type Props = {
  id: string;
  day: string;
};

const useTimeOffStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: 'inherit',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      padding: '5px 8px',
    },
    btn: {},
    icon: {
      position: 'absolute',
      top: 5,
      right: 6,
      fontSize: 18,
    },
    notes: {
      position: 'absolute',
      bottom: 0,
      right: 0,
    },
    content: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 0.5,
      margin: '0 auto',
      textAlign: 'center',
    },
    info: {
      fontSize: 12,
      fontWeight: 500,
      letterSpacing: 0.5,
    },
  })
);

export const DayViewLeaveCard = ({ id, day }: Props) => {
  const classes = useTimeOffStyles();
  const timeOff = useSelectorWithProps(timeOffById, id);
  const siteId = useSelector(getSiteId);
  const sites = useSelector(getSites);
  const timeZoneId = sites[siteId].timezone_id;
  const hoverPopupState = useHoverPopupState<HTMLDivElement>();

  const limits = {
    start: moment(+day)
      .tz(timeZoneId)
      .clone()
      .startOf('day'),
    end: moment(+day)
      .tz(timeZoneId)
      .clone()
      .hours(23)
      .minutes(59),
  };

  const limitByTime = (value: Moment) => {
    if (value.isBefore(limits.start)) {
      return limits.start.clone();
    } else if (value.isAfter(limits.end)) {
      return limits.end.clone();
    } else {
      // is between
      return value;
    }
  };

  const leaveDuration = (): undefined | { from: Moment; to: Moment } => {
    const from = limitByTime(timeOff.start);
    const to = limitByTime(timeOff.end);

    if (from.isSame(limits.start) && to.isSame(limits.end)) {
      return;
    }

    return {
      from,
      to,
    };
  };

  const timeInfo = () => {
    const { options, start, end } = timeOff;
    const formattedDate = moment(+day)
      .tz(timeZoneId)
      .format(SERVER_DAY_FORMAT);
    const duration = leaveDuration();
    const isFirstDay = start.format(SERVER_DAY_FORMAT) === formattedDate;
    const isLastDay = end.format(SERVER_DAY_FORMAT) === formattedDate;
    if (options) {
      if (duration) {
        return (
          <TimeDetails
            start={duration.from}
            end={duration.to}
            type={timeOff.type}
            showIcon={false}
          />
        );
      }
      if (
        (isFirstDay && options.half_start) ||
        (isLastDay && options.half_end)
      ) {
        return (
          <IsAppMarket market={'au'}>
            <PartDayText />
          </IsAppMarket>
        );
      }
    }
    return null;
  };

  return (
    <div
      onDoubleClick={(e) => e.stopPropagation()}
      data-testid={`leave-card leave-card-${id}`}
      className={classes.root}
      {...bindHoverTrigger(hoverPopupState)}
      onClick={isMobileDevice() ? hoverPopupState.open : () => false}
    >
      <div className={classes.content}>
        <WorkOffOutlinedIcon className={classes.icon} />
        <NotesIconButton
          className={classes.notes}
          notes={timeOff.notes}
          label={getLeaveSubtypeLabel(timeOff.subtype, false)}
          id={id}
          onClick={(e) => {
            e.stopPropagation();
            hoverPopupState.close();
          }}
        />
        <div className={classes.info}>
          {getLeaveSubtypeLabel(timeOff.subtype, true)}
        </div>
        <div>{timeInfo()}</div>
      </div>
    </div>
  );
};
