import { ShiftOvertimeRuleRequestEntityDay } from 'lib/Api';
import React from 'react';
import { useDispatch } from 'react-redux';
import { BOX_ACCOUNT_PAY_CONDITIONS_RULE_CREATE } from 'state/Account';
import { usePayConditionsListContext } from '../pay-conditions-list-context';
import PayConditionForm, {
  PayConditionFormProps,
} from './components/PayConditionForm';

type Props = {
  day: ShiftOvertimeRuleRequestEntityDay;
};

export default function CreatePayCondition({ day }: Props) {
  const dispatch = useDispatch();
  const { isCreateOpen, close } = usePayConditionsListContext();

  React.useEffect(() => {
    close();
  }, [day, close]);

  const handleSubmit: PayConditionFormProps['onSubmit'] = (payload) => {
    dispatch(
      BOX_ACCOUNT_PAY_CONDITIONS_RULE_CREATE.request({
        ...payload,
        day,
      })
    );
  };

  return <>{isCreateOpen && <PayConditionForm onSubmit={handleSubmit} />}</>;
}
