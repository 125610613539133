import { LoadingOverlay } from 'elmo-elements';
import {
  Box,
  Checkbox,
  FormControlLabel,
  useTheme,
} from 'extended-oxygen-elements';
import { PayHeader, PayLayout } from 'page/Settings/page/Pay/components';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BOX_ACCOUNT_PAY_CONDITIONS_WEEKEND_LOADINGS_TOGGLE,
  getIsWeekendLoadingsEnabled,
} from 'state/Account';
import { getConditionsPageUpdating } from 'state/PayConditionsPage';
import FeatureWrapper from './FeatureWrapper';
import FetchPageData from './FetchPageData';
import PageErrors from './PageErrors';
import PayConditionsTable from './PayConditionsTable';
import ViewAndAssignEmployeesSection from './ViewAndAssignEmployeesSection';
import WeekendLoadingsTable from './WeekendLoadingsTable';

function PaymentConditions() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isWeekendLoadingsEnabled = useSelector(getIsWeekendLoadingsEnabled);
  const isLoading = useSelector(getConditionsPageUpdating);

  const handleIsWeekendLoadingsEnabledChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      BOX_ACCOUNT_PAY_CONDITIONS_WEEKEND_LOADINGS_TOGGLE.request({
        isEnabled: event.target.checked,
      })
    );
  };

  return (
    <FeatureWrapper>
      <PayLayout header={<PayHeader activeTab="conditions" />}>
        <FetchPageData>
          <LoadingOverlay isLoading={isLoading}>
            <PageErrors />

            <ViewAndAssignEmployeesSection disabled={isLoading} />

            <PayConditionsTable />

            <Box mt={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isWeekendLoadingsEnabled}
                    onChange={handleIsWeekendLoadingsEnabledChange}
                  />
                }
                label="Enable weekend loadings"
              />
            </Box>

            {isWeekendLoadingsEnabled && <WeekendLoadingsTable />}
          </LoadingOverlay>
        </FetchPageData>
      </PayLayout>
    </FeatureWrapper>
  );
}

export default PaymentConditions;
