import React from 'react';
import { PrintOutlinedIcon } from 'element';
import marketLabels from 'marketLabels';
import { useDispatch } from 'react-redux';
import { BOX_ROSTERS_PRINT, BOX_ROSTERS_PRINT_OPEN } from 'state/Roster/Print';

export default useBtnPrint;

export function useBtnPrint() {
  const dispatch = useDispatch();

  return {
    id: 'print-btn',
    icon: <PrintOutlinedIcon />,
    label: 'Print',
    tooltip: `Print your ${marketLabels.roster}`,
    onClick: () => {
      dispatch(BOX_ROSTERS_PRINT_OPEN(true));
    },
  };
}
