import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { times } from 'lodash';
import { unassignedRostersRowNumberSelector } from 'state/Roster/RosterWeekViewUserView';
import UnassignedRowWithLegend from 'page/Roster/page/RosterWeekView/components/UnassignedRowWithLegend';
import { UnassignedShiftRow } from './UnassignedShiftRow';

export const WeekViewUserViewUnassignedRow = memo(
  function WeekViewUserViewUnassignedRow() {
    const rowsNumber = useSelector(unassignedRostersRowNumberSelector);
    return (
      <UnassignedRowWithLegend rowIndex={0}>
        <div className="week-rosters-grid__blocks unassigned">
          {times(rowsNumber, (rowNumber) => (
            <UnassignedShiftRow rowNumber={rowNumber} key={rowNumber} />
          ))}
        </div>
      </UnassignedRowWithLegend>
    );
  }
);
