import React from 'react';
import { Box, Grid, Typography } from 'extended-oxygen-elements';
import { Minutes } from 'type';
import {
  useFormControlSpacing,
  usePaidBreaksDuration,
  useUnpaidBreaksDuration,
} from 'hooks';
import { breaksFieldGroupUtils } from './form-fields';
import { FormattedDuration } from './formatted-components';

type ShiftBreak = {
  start: Minutes | null;
  duration: Minutes | null;
  paid: boolean;
};

type SummaryProps = {
  duration: number;
  breaks: ShiftBreak[];
};

export default ShiftSummaryBlock;

export function ShiftSummaryBlock({ duration, breaks }: SummaryProps) {
  const filteredBreaks = breaks.filter(
    breaksFieldGroupUtils.filterValidBreakPredicate
  );
  const paidBreaksDuration = usePaidBreaksDuration(filteredBreaks);
  const unpaidBreaksDuration = useUnpaidBreaksDuration(filteredBreaks);

  const paidDuration = Math.max(duration - unpaidBreaksDuration, 0);

  const formControlSpacing = useFormControlSpacing('normal');
  return (
    <Box {...formControlSpacing} data-testid={'shift-summary-block'}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary">
            Paid
          </Typography>

          <Typography variant="h4">
            <FormattedDuration>{paidBreaksDuration}</FormattedDuration>
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary">
            Unpaid
          </Typography>

          <Typography variant="h4">
            <FormattedDuration>{unpaidBreaksDuration}</FormattedDuration>
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary">
            Total hours
          </Typography>

          <Typography variant="h4">
            {paidDuration ? <FormattedDuration>{paidDuration}</FormattedDuration> : '-'}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
