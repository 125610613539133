import React, { memo } from 'react';
import { SiteRosterWeekCard, UserRosterWeekCard } from 'page/Roster/components';
import { isMobileDevice } from 'lib/helpers';
import { getIsBulkDeleteOpened } from 'state/Roster/BulkActions';
import { useSelector } from 'react-redux';
import { getIsApprovalModeEnabled } from 'state/Roster/Roster';
import { getIsCovidModeEnabled } from 'state/Roster/CovidTracing/selectors';

type Props = {
  rosteredShiftId: string;
  canEditRosteredShift: boolean;
  onDragStart: (e: React.DragEvent<HTMLDivElement>) => void;
  restorePointerEvents: () => void;
  type: 'user' | 'site';
};

export const DraggableShiftCard = memo(function DraggableShiftCard(props: Props) {
  const { restorePointerEvents, rosteredShiftId, canEditRosteredShift, onDragStart } = props;
  const isBulkDelete = useSelector(getIsBulkDeleteOpened);
  const isApprovalMode = useSelector(getIsApprovalModeEnabled);
  const isCovidMode = useSelector(getIsCovidModeEnabled);

  const isDraggable = () => {
    return canEditRosteredShift && !isMobileDevice() && !isBulkDelete && !isApprovalMode && !isCovidMode;
  };

  return (
    <div
      id={`drag-${rosteredShiftId}`}
      className={'draggable-wrapper'}
      onDragStart={onDragStart}
      draggable={isDraggable()}
      onDragEnd={restorePointerEvents}
    >
      {
        props.type === 'user'
          ? <UserRosterWeekCard
              rosteredShiftId={rosteredShiftId}
            />
          : <SiteRosterWeekCard
            rosteredShiftId={rosteredShiftId}
          />
      }

    </div>
  );
})
