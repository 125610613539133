import { createAction, createAsyncAction } from 'lib/store-utils';
import { FormattedErrors } from 'type';
import {
  DialogOpenSuccessPayload,
  UsersChangeRequestPayload,
  UsersChangeSuccessPayload,
} from './types';

export const BOX_PAYMENT_ASSIGNED_USERS_DIALOG_OPEN = createAsyncAction<
  void,
  DialogOpenSuccessPayload,
  FormattedErrors
>('payment assigned users dialog / open');
export const BOX_PAYMENT_ASSIGNED_USERS_DIALOG_USERS_CHANGE = createAsyncAction<
  UsersChangeRequestPayload,
  UsersChangeSuccessPayload,
  FormattedErrors
>('payment assigned users dialog / users change');

export const BOX_PAYMENT_ASSIGNED_USERS_DIALOG_ERRORS_CLOSED = createAction(
  'payment assigned users dialog / errors closed'
);
