import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { Overview } from 'type';
import { Api } from 'lib/Api';
import * as fetchPageData from 'state/FetchPageData';
import { getFetchFlags } from 'state/FetchPageData';
import {
  BOX_MANAGER_DASHBOARD_CLEAR_ERRORS,
  ManagerDashboardFilters,
  managerDashboardFiltersSelector,
} from '../ManagerDashboard';
import { processApiRequest } from '../../ProcessApiRequest/sagas';
import * as actions from './actions';

export const getOverviewRequest = function* (): SagaIterator {
  yield put(BOX_MANAGER_DASHBOARD_CLEAR_ERRORS());

  const filters: ManagerDashboardFilters = yield select(
    managerDashboardFiltersSelector
  );

  const overview: Overview = yield call(
    processApiRequest,
    Api.ManagerDashboard.getOverview,
    filters
  );
  yield put(actions.BOX_OVERVIEW_GET_OVERVIEW_SUCCESS(overview));
};

export const getPageData = function*(): SagaIterator {
  yield put(fetchPageData.BOX_FETCH_PAGE_DATA_REQUEST(fetchPageData.MANAGER_DASHBOARD_OVERVIEW));
  try {
    yield call(getOverviewRequest);
    yield put(fetchPageData.BOX_FETCH_PAGE_DATA_SUCCESS(fetchPageData.MANAGER_DASHBOARD_OVERVIEW));
  } catch (error) {
    yield put(fetchPageData.BOX_FETCH_PAGE_DATA_FAILURE(fetchPageData.MANAGER_DASHBOARD_OVERVIEW));
  }
};

const isFetched = (params: ReturnType<typeof getFetchFlags>): boolean => {
  return params.isFetched && !params.isFetching;
};

export const updateOverviewData = function*(): SagaIterator {
  try {
    const overviewFlag: ReturnType<typeof getFetchFlags> = yield select(
      getFetchFlags,
      'MANAGER_DASHBOARD_OVERVIEW'
    );

    if (isFetched(overviewFlag)) {
      const filters: ManagerDashboardFilters = yield select(
        managerDashboardFiltersSelector
      );

      const overview: Overview = yield call(
        processApiRequest,
        Api.ManagerDashboard.getOverview,
        filters
      );
      yield put(actions.BOX_OVERVIEW_FETCH_DATA_SUCCESS(overview));
    }

  } catch (error) {
    //
  }
};

export const watchManagerDashboardOverview = function*(): SagaIterator {
  yield takeLatest(actions.BOX_OVERVIEW_DATA_REQUEST, getPageData);
  yield takeLatest(actions.BOX_OVERVIEW_FETCH_DATA_REQUEST, updateOverviewData);
};
