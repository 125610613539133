import React from 'react';
import { periods } from '../../../../../state/Report/reportModalValues';
import {
  Select,
  FormItem,
  FormContainer,
  Heading,
  Row,
  Col,
} from 'elmo-elements';
import { Data, DropDownProps } from '../type';
import { DateInputMoment, TimeInput } from 'element';
import { getSelectedValue } from '../helpers';
import moment from 'moment';
import { PreferencesDateFormat, PreferencesTimeFormat } from 'type/models';
import { connect } from 'react-redux';
import { StoreState } from '../../../../../state/types';
import { getDateFormat, getTimeFormat } from 'state/Account';

export type PeriodProps = {
  handleOnChangeSelect: (e: DropDownProps, name: string) => void;
  handleChangePickers: (e: any, name: string) => void;
  data: Data;
};

export type StateProps = {
  dateFormat: PreferencesDateFormat;
  timeFormat: PreferencesTimeFormat;
};

export class PeriodGroup extends React.Component<PeriodProps & StateProps> {
  render() {
    return (
      <div>
        <Heading htmlTag={'h3'} type={'heading'} className={'mb-4'}>
          Date range
        </Heading>
        <FormContainer maxColumns={3} className="flex-start-align">
          <FormItem label={'Period'} isFluid={false} isBlock={true}>
            <Select
              name="period"
              options={periods}
              defaultValue={getSelectedValue(periods, this.props.data.period)}
              onChange={(e) => this.handleOnChangeSelect(e, 'period')}
              value={getSelectedValue(periods, this.props.data.period)}
              ariaLabel="Period"
            />
          </FormItem>
          <FormItem label="From" isFluid={false}>
            <Row
              isNoGutters={true}
              role="presentation"
              className="row-two-cols"
            >
              <Col span={12} role="presentation">
                <DateInputMoment
                  id={'start-date'}
                  className={'bg-white'}
                  name={'date_from'}
                  onChange={(e: any) =>
                    this.handleChangePickers(e, 'date_from')
                  }
                  value={moment(this.props.data.date_from)}
                  placeholder={this.props.dateFormat}
                  disabledDate={this.disabledDatesFrom}
                  isClearable={false}
                  ariaLabel="Date from"
                />
              </Col>
              <Col span={12} role="presentation">
                <TimeInput
                  name={'time_from'}
                  value={this.props.data.time_from}
                  onChange={(e) =>
                    this.props.handleChangePickers(e, 'time_from')
                  }
                  id="time-from"
                  label="Time from"
                />
              </Col>
            </Row>
          </FormItem>
          <FormItem label="To" isFluid={false}>
            <Row
              isNoGutters={true}
              role="presentation"
              className="row-two-cols"
            >
              <Col span={12} className="pr-2" role="presentation">
                <DateInputMoment
                  id={'end-date'}
                  className={'bg-white'}
                  name={'date_to'}
                  onChange={(e: any) => this.handleChangePickers(e, 'date_to')}
                  value={moment(this.props.data.date_to)}
                  placeholder={this.props.dateFormat}
                  disabledDate={this.disabledDatesTo}
                  isClearable={false}
                  ariaLabel="Date to"
                />
              </Col>
              <Col span={12} className="pr-2" role="presentation">
                <TimeInput
                  name={'time_to'}
                  value={this.props.data.time_to}
                  onChange={(e) => this.handleChangePickers(e, 'time_to')}
                  id="time-to"
                  label="Time to"
                />
              </Col>
            </Row>
          </FormItem>
        </FormContainer>
      </div>
    );
  }

  private handleOnChangeSelect = (e: DropDownProps, name: string) => {
    this.props.handleOnChangeSelect(e, name);
  };

  private handleChangePickers = (e: any, name: string) => {
    this.props.handleChangePickers(e, name);
  };

  private disabledDatesTo = (current?: Date): boolean => {
    if (
      current &&
      this.props.data.date_from !== null &&
      this.props.data.date_from
    ) {
      return moment(current) < moment(this.props.data.date_from);
    }

    return false;
  };

  private disabledDatesFrom = (current?: Date): boolean => {
    if (
      current &&
      this.props.data.date_to !== null &&
      this.props.data.date_to
    ) {
      return moment(current) > moment(this.props.data.date_to);
    }

    return false;
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
});

export default connect(mapStateToProps)(PeriodGroup);
