import { Moment } from 'moment';

export const getEndDate = (start: Moment, end: Moment) => {
  const startDate = start.clone();
  const endDate = end.clone();
  endDate.set({
    'date': startDate.date(),
    'month': startDate.month(),
    'year': startDate.year()
  });

  if (startDate >= endDate) {
    endDate.add(1, 'day');
  }
  return endDate;
}