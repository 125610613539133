import { useSiteIdValidator } from 'hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import { firstSiteIdSelector } from 'state/AccountTree';
import { currentUserMainSiteIdSelector } from 'state/Auth';
import QueryItemProvider from './QueryItemProvider';

type Props = {
  children: React.ReactNode;
};

export default QuerySiteIdProvider;

export function QuerySiteIdProvider({ children }: Props) {
  const siteIdValidator = useSiteIdValidator();

  const currentUserMainSiteId = useSelector(currentUserMainSiteIdSelector);
  const firstSiteId = useSelector(firstSiteIdSelector);

  const defaultSiteId = currentUserMainSiteId || firstSiteId;

  return (
    <QueryItemProvider
      name="site_id"
      default={defaultSiteId}
      validator={siteIdValidator}
    >
      {children}
    </QueryItemProvider>
  );
}
