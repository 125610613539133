import { createAction } from 'lib/store-utils';
import { FormattedErrors, WorkingPeriod, DashboardShift } from 'type/models';
import {
  UpdateFormPayload
} from 'type';
import { DeleteResponse, ToggleFilterSuccessPayload } from './types';

export const BOX_WHOS_WORKING_DATA_REQUEST = createAction('Who\'s working / get data request');
export const BOX_WHOS_WORKING_GET_LIST_SUCCESS = createAction<DashboardShift[]>('Who\'s working / get data success');

export const BOX_WHOS_WORKING_TOGGLE_PERIOD_TYPE_REQUEST = createAction<WorkingPeriod>('Who\'s working / Change period request');
export const BOX_WHOS_WORKING_TOGGLE_PERIOD_TYPE_SUCCESS = createAction<ToggleFilterSuccessPayload>('Who\'s working / Change period success');
export const BOX_WHOS_WORKING_TOGGLE_PERIOD_TYPE_FAILURE = createAction<FormattedErrors>('Who\'s working / Change period failed');

export const BOX_WHOS_WORKING_CLOCK_ON_REQUEST = createAction<string>('Who\'s working / Clock on request');
export const BOX_WHOS_WORKING_CLOCK_ON_SUCCESS = createAction<string>('Who\'s working / Clock on success');
export const BOX_WHOS_WORKING_CLOCK_ON_FAILURE = createAction<FormattedErrors>('Who\'s working / Clock on failed');
export const BOX_WHOS_WORKING_CLEAR_ERRORS = createAction('Who\'s working / Clear errors');

export const BOX_WHOS_WORKING_CLOCK_OFF_REQUEST = createAction<string>('Who\'s working / Clock off request');
export const BOX_WHOS_WORKING_CLOCK_OFF_SUCCESS = createAction<string>('Who\'s working / Clock off success');
export const BOX_WHOS_WORKING_CLOCK_OFF_FAILURE = createAction<FormattedErrors>('Who\'s working / Clock off failed');

export const BOX_WHOS_WORKING_START_BREAK_REQUEST = createAction<any>('Who\'s working / Start break request');
export const BOX_WHOS_WORKING_START_BREAK_SUCCESS = createAction<DashboardShift>('Who\'s working / Start break success');
export const BOX_WHOS_WORKING_START_BREAK_FAILURE = createAction<FormattedErrors>('Who\'s working / Start break failed');

export const BOX_WHOS_WORKING_STOP_BREAK_REQUEST = createAction<string>('Who\'s working / Stop break request');
export const BOX_WHOS_WORKING_STOP_BREAK_SUCCESS = createAction<DashboardShift>('Who\'s working / Stop break success');
export const BOX_WHOS_WORKING_STOP_BREAK_FAILURE = createAction<FormattedErrors>('Who\'s working / Stop break failed');

export const BOX_WHOS_WORKING_UPDATE_FORM_FILTERS = createAction<UpdateFormPayload>('Who\'s working / Change filters request');
export const BOX_WHOS_WORKING_RESET_FORM_FILTERS = createAction('Who\'s working / Reset filters request');
export const BOX_WHOS_WORKING_APPLY_FORM_FILTERS_REQUEST = createAction<WorkingPeriod>('Who\'s working / Apply filters request');
export const BOX_WHOS_WORKING_APPLY_FORM_FILTERS_SUCCESS = createAction<ToggleFilterSuccessPayload>('Who\'s working / Apply filters success');
export const BOX_WHOS_WORKING_APPLY_FORM_FILTERS_FAILURE = createAction<FormattedErrors>('Who\'s working / Apply filters failure');

export const BOX_WHOS_WORKING_APPROVE_REQUEST = createAction<string>('Who\'s working / Approve request');
export const BOX_WHOS_WORKING_APPROVE_SUCCESS = createAction<string>('Who\'s working / Approve success');
export const BOX_WHOS_WORKING_APPROVE_FAILURE = createAction<FormattedErrors>('Who\'s working / Approve failed');

export const BOX_WHOS_WORKING_BREAKS_LIMIT_CONFIRMATION_OPEN = createAction<FormattedErrors>('Who\'s working/ Breaks limit modal open');
export const BOX_WHOS_WORKING_BREAKS_LIMIT_CONFIRMATION_CLOSE = createAction('Who\'s working / Breaks limit modal open');

export const BOX_WHOS_WORKING_UPDATE_SHIFT = createAction<DashboardShift>('Whos working / update shift');

export const BOX_WHOS_WORKING_SHIFT_CHANGED = createAction<DashboardShift>('Whos working / shift changed');
export const BOX_WHOS_WORKING_SHIFT_DELETED = createAction<DeleteResponse>('Whos working / shift/timesheet deleted');

export const BOX_WHOS_WORKING_TIMESHEET_CHANGED = createAction<DashboardShift>('Whos working / shift changed');
export const BOX_WHOS_WORKING_TIMESHEET_DELETED = createAction<DeleteResponse>('Whos working / shift/timesheet deleted');

export const BOX_WHOS_WORKING_SOCKET_UPDATE_DATA_FAILED =  createAction<FormattedErrors>('Who\'s working / Update data failed');
