import { SortUser } from 'type';
import { UserFilters, UserToggleFilterTypePayload } from './types';
import { cloneDeep } from 'lodash';

export const sort: SortUser = {
  column: 'full_name',
  direction: 'asc'
};

const partialFilter = {
  role: [],
  area: [],
  userGroup: [],
  tag: []
};

export const filterTypes: {
  readonly [key in UserToggleFilterTypePayload]: Readonly<UserFilters>
} = {
  active: {
    activity: {
      active: true,
      archived: false
    },
    ...cloneDeep(partialFilter)
  },
  archived: {
    activity: {
      active: false,
      archived: true
    },
    ...cloneDeep(partialFilter)
  }
};
