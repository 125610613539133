import * as React from 'react';
import {
  Button,
  Dropdown,
  Heading,
  ListTable,
  Paragraph
} from 'elmo-elements';
import {
  CheckCircleOutlinedIcon,
  HighlightOffOutlinedIcon,
  AccountCircleOutlinedIcon,
  ChevronRightIcon
} from 'element';
import { NavLink } from 'react-router-dom';
import { isHrService } from '../../../../../../../../helpers';

type Props = {
  notifications: any,
  onChange: Function,
  disableSMS: boolean,
  disableEmail: boolean,
  group: string;
};

export class NotificationsTable extends React.Component <Props> {

  constructor(props: Props) {
    super(props);
  }

  renderNotification = (notification: any) => {
    return (
      <>
        <Heading size="sm" isBold={true}>{notification.label}</Heading>
        <Paragraph size="sm">{notification.tooltip}</Paragraph>
      </>
    );
  }

  renderDropDown = (id: string, type: string, isDisable: boolean) => {
    const {onChange, group} = this.props;
    return (
      <Dropdown
        text={this.renderDropDownOption(id, type)}
        ariaLabel="My Dropdown menu"
        className="dropdown"
        isDisabled={isDisable}
        isBordered={true}
        isFullWidth={true}
        isButtonBlock={true}
      >
        <Dropdown.Item
          ariaLabel="Do not notify"
          value={null}
          onClick={() => {
            onChange({group, id, type, value: 'off'});
          }}
          isDisabled={false}
        >
          {this.getIcon('off')}
          {this.renderValue('off')}
        </Dropdown.Item>

        <Dropdown.Item
          ariaLabel="Notify"
          value={null}
          onClick={() => {
            onChange({group, id, type, value: 'notify'});
          }}
          isDisabled={false}
        >
          {this.getIcon('notify')}
          {this.renderValue('notify')}
        </Dropdown.Item>

        <Dropdown.Item
          ariaLabel="User Preferences"
          value={null}
          onClick={() => {
            onChange({group, id, type, value: 'user_input'});
          }}
          isDisabled={false}
        >
          {this.getIcon('user_input')}
          {this.renderValue('user_input')}
        </Dropdown.Item>

      </Dropdown>
    );
  }

  render() {
    const {notifications, disableEmail, disableSMS} = this.props;

    return (
      <ListTable
        ariaLabel="Notifications"
        className="notificationsTable"
      >
        <ListTable.Header>
          <ListTable.Column
            label={'Notification'}
          />
          <ListTable.Column
            label={'Email'}
          />
          {!isHrService('bravo') && <ListTable.Column
            label={'SMS'}
          />}
        </ListTable.Header>
        <ListTable.Body>
          {
            Object.keys(notifications).map((id: string, index: number) => {
              return (
                <ListTable.Tr
                  key={index}
                  icon={this.renderControl(id)}
                >
                  <ListTable.Td>
                    {this.renderNotification(notifications[id])}
                  </ListTable.Td>
                  <ListTable.Td>
                    {this.renderDropDown(id, 'email', disableEmail)}
                  </ListTable.Td>
                  {!isHrService('bravo') && <ListTable.Td>
                    {this.renderDropDown(id, 'sms', disableSMS)}
                  </ListTable.Td>
                  }
                </ListTable.Tr>
              );
            })
          }
        </ListTable.Body>
      </ListTable>
    );
  }

  renderDropDownOption = (id: string, type: string) => {
    const value = type === 'sms' ? this.props.notifications[id].sms : this.props.notifications[id].email;
    return (
      <> {this.getIcon(value)} {this.renderValue(value)}
      </>
    );
  }

  renderValue = (value: string) => {
    switch (value) {
      case 'notify':
        return 'Notify';
      case 'off':
        return 'Do not notify';
      case 'user_input':
        return 'User Preferences';
      default:
        return 'Notify';
    }
  }

  getIcon = (type: 'notify' | 'off' | 'user_input') => {
    switch (type) {
      case 'off':
        return <HighlightOffOutlinedIcon className="text-color-danger"/>;
      case 'user_input':
        return <AccountCircleOutlinedIcon className="text-color-primary"/>;
      default:
        return <CheckCircleOutlinedIcon className="text-color-success"/>;
    }
  }

  renderControl = (id: string) => {
    const {group} = this.props;
    return (
      <NavLink to={`/app/settings/notifications/${group}/${id}`}>
        <Button ariaLabel="Action">
          <ChevronRightIcon/>
        </Button>
      </NavLink>
    );
  }
}
