import React from 'react';
import { CheckButton, Row } from 'elmo-elements';
import { CommonAttributes } from 'elmo-elements/CheckButton';
import { dayButtons } from '../../config';

type Props = {
  onStatusChange: (payload: CommonAttributes) => void;
  checked: string[];
  isDisabled?: boolean;
};

export const ReportScheduleCheckButtons = ({
  checked,
  onStatusChange,
  isDisabled
}: Props) => {
  return (
    <Row isNoGutters={true} wrap={'nowrap'} role="presentation">
      {dayButtons.map((button, buttonIndex) => (
        <CheckButton
          key={buttonIndex}
          isChecked={checked.includes(button.value)}
          id={button.value}
          onStatusChange={onStatusChange}
          isDisabled={isDisabled}
        >
          {button.label}
        </CheckButton>
      ))}
    </Row>
  );
};
