import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BOX_FLAGS_GET } from 'state/Flags';

type Props = {
  children: React.ReactNode;
};

export const WithFeatureFlagServiceProvider = ({ children }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(BOX_FLAGS_GET());
  }, []);

  return <React.Fragment>{children}</React.Fragment>;
};
