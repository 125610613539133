import { ShiftOvertimeRuleRequestEntityDay } from 'lib/Api';
import React from 'react';
import { ShiftOvertimeRule } from 'type';
import PayCondition from './payConditionRow/PayCondition';
import EditPayCondition from './payConditionRow/EditPayCondition';
import { usePayConditionsListContext } from '../pay-conditions-list-context';

type PayConditionRowProps = {
  overtimeRule: ShiftOvertimeRule;
  day: ShiftOvertimeRuleRequestEntityDay;
};

function PayConditionRow({ overtimeRule, day }: PayConditionRowProps) {
  const { getIsEditing } = usePayConditionsListContext();

  if (getIsEditing(overtimeRule.id)) {
    return <EditPayCondition overtimeRule={overtimeRule} day={day} />;
  }

  return <PayCondition overtimeRule={overtimeRule} />;
}

export default PayConditionRow;
