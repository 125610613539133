import React, { useEffect, useState } from 'react';
import {
  AccountTreeArea,
  AccountTreeRole,
  AccountTreeSite,
  RosteredShift,
  ServerUserFields,
  Timesheet,
} from 'type/models';
import { StringMap } from 'type';
import { getDurationsInfo } from '../../../../helpers';
import { StoreState } from 'state/types';
import { getAccountTree, activeAreasBySiteIdSelector } from 'state/AccountTree';
import { getUserListResponse } from 'state/UsersCollection';
import {
  filtersByTypeSelector,
  RosterFiltersByType,
} from 'state/Roster/RosterFilters';
import { connect, useSelector } from 'react-redux';
import { RosterTimeOffsByUserIdAndDate } from 'state/Roster/RosterWeekView/types';
import { SiteRosterWeekCard, SiteTimesheetCard } from '../../../shiftCards';
import { LegendDurationHours } from '../LegendDurationHours';
import { PrintWeekHeader } from '../PrintWeekHeader';
import { getIsPrinting, getPrintOptions } from 'state/Roster/Print';
import { EventsTable } from '../Events/EventsTable';

type Day = { rosteredShiftId?: string; timesheetId?: string };

type StateProps = {
  roles: StringMap<AccountTreeRole>;
  areas: StringMap<AccountTreeArea>;
  sites: StringMap<AccountTreeSite>;
  users: StringMap<ServerUserFields>;
  areasBySideId: StringMap<AccountTreeArea[]>;
  filters: RosterFiltersByType;
};

type OwnProps = {
  groupedRosters: any;
  rosteredShifts: StringMap<RosteredShift>;
  timeOffs: RosterTimeOffsByUserIdAndDate;
  filteredRosters: RosteredShift[];
  filteredTimesheets: Timesheet[];
};

type Props = OwnProps & StateProps;

export function SiteView({
  rosteredShifts,
  groupedRosters,
  areas,
  roles,
  filters,
  timeOffs,
  filteredTimesheets,
  filteredRosters,
}: Props) {
  let iterations = 0;
  const printOptions = useSelector(getPrintOptions);
  const isPrinting = useSelector(getIsPrinting);
  const [grupedAreas, setAreas]: any = useState(groupByArea(groupedRosters));

  useEffect(() => {
    setAreas(groupByArea(groupedRosters));
  }, [groupedRosters, printOptions, isPrinting]);

  function groupByArea(data: any) {
    return data.reduce(function (result: any, row: any) {
      (result[row.area_id] = result[row.area_id] || []).push(row);
      return result;
    }, {});
  }

  function renderLegend(roleId: string, index: number) {
    const durations = getDurationsInfo(
      groupedRosters[index].rosters,
      filteredRosters.filter((roster) => roster.is_published && roster.user_id),
      filteredTimesheets,
      timeOffs
    );

    return (
      <>
        <div className={'roleName'}>{roles[roleId].name}</div>
        {durations.map((duration, key) => (
          <React.Fragment key={key}>
            {filters.roster && duration.type === 'roster' && (
              <LegendDurationHours time={duration.time} type={'roster'} />
            )}
            {filters.timesheets && duration.type === 'timesheet' && (
              <LegendDurationHours time={duration.time} type={'timesheet'} />
            )}
          </React.Fragment>
        ))}
      </>
    );
  }

  function renderRow(data: Day[], index: number) {
    return (
      <table cellSpacing="0" key={index}>
        <tbody>
          {filters.roster && (
            <tr>
              {data.map((day: Day, dayIndex: number) => {
                return renderRosterCell(day, `${index}-${dayIndex}`);
              })}
            </tr>
          )}
          {filters.timesheets && (
            <tr>
              {data.map((day: Day, dayIndex: number) => {
                return renderTimesheetCell(day, `${index}-${dayIndex}`);
              })}
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  function renderCardBasedOnPrintOptions(id?: string) {
    if (!id) {
      return null;
    }
    const rosteredShift = rosteredShifts[id];
    if (!printOptions.assigned && rosteredShift && rosteredShift.user_id) {
      return null;
    }
    return <SiteRosterWeekCard rosteredShiftId={id} />;
  }

  function renderRosterCell(day: Day, key: string) {
    return (
      <td className="dayCell" key={key}>
        <div className="wrap">
          <div className="shiftWrapper">
            {renderCardBasedOnPrintOptions(day.rosteredShiftId)}
          </div>
        </div>
      </td>
    );
  }

  function renderTimesheetCell(day: Day, key: string) {
    return (
      <td className="dayCell" key={key}>
        <div className="wrap">
          <div className="timesheetWrapper">
            {day.timesheetId && (
              <SiteTimesheetCard timesheetId={day.timesheetId} />
            )}
          </div>
        </div>
      </td>
    );
  }

  if (!isPrinting) {
    return null;
  }

  return (
    <>
      {printOptions.events && <EventsTable />}
      {Object.keys(grupedAreas).map((areaId: string, index) => {
        return (
          <table cellSpacing="0" className="area-table" key={areaId}>
            <PrintWeekHeader showDays={index === 0} areaId={areaId} />
            <tbody>
              {grupedAreas[areaId].map((groupedRoles: any) => {
                return (
                  <tr key={groupedRoles.role_id}>
                    <td
                      width="200px"
                      className={'firstCell border-cell'}
                      key={`role-name-${groupedRoles.role_id}`}
                    >
                      <div className="firstCellContent">
                        {renderLegend(groupedRoles.role_id, iterations++)}
                      </div>
                    </td>
                    <td
                      className="border-cell"
                      key={`role-value-${groupedRoles.role_id}`}
                    >
                      {groupedRoles.rosters.map((days: any, key: number) => {
                        return renderRow(groupedRoles.rosters[key], key);
                      })}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      })}
    </>
  );
}

const mapStateToProps = (state: StoreState): StateProps => ({
  ...getAccountTree(state),
  users: getUserListResponse(state),
  areasBySideId: activeAreasBySiteIdSelector(state),
  filters: filtersByTypeSelector(state),
});

export default connect(mapStateToProps)(SiteView);
