import React from 'react';
import { IconButtonProps } from 'extended-oxygen-elements';
import { RosterTimesheetListedWithPayEntries } from 'type';
import {
  getIsTimesheetApproved,
  getIsTimesheetInProgress,
  typedCond,
} from 'lib/helpers';
import { useGetIsTimesheetViolated } from 'page/TimesheetsWeekly/hooks';
import {
  ApprovedIcon,
  InProgressIcon,
  ViolationIconButton,
} from './card-icons';

type StatusIconProps = IconButtonProps & {
  timesheet: RosterTimesheetListedWithPayEntries;
};

export const StatusIcon = (props: StatusIconProps) => {
  const { timesheet } = props;
  const getIsTimesheetViolated = useGetIsTimesheetViolated(timesheet);

  const getIcon = typedCond([
    [getIsTimesheetInProgress, () => InProgressIcon],
    [getIsTimesheetViolated, () => ViolationIconButton],
    [getIsTimesheetApproved, () => ApprovedIcon],
  ]);

  const IconComponent = getIcon(timesheet) || (() => null);

  return <IconComponent {...props} />;
};
