import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { InputAdornment, MenuItem, TextField } from 'extended-oxygen-elements';
import { LoadingOverlay } from 'elmo-elements';
import marketLabels from 'marketLabels';
import { capitalize } from 'lib/helpers';
import { getDateFormat, timeFormatSelector } from 'state/Account';
import { getAllowedToAssignShiftsArray } from 'state/AllowedToAssignShiftsResponse';
import { getAllowedToAssignShiftsUpdating } from 'state/TimesheetsWeeklyPage';
import { TextFieldProgressAdornment } from 'element';

type Props = {
  value: string | null;
  onChange: (updatedValue: string | null) => void;
};

export default ConnectedShiftField;

export function ConnectedShiftField({ value, onChange }: Props) {
  const loading = useSelector(getAllowedToAssignShiftsUpdating);
  const allowedOptions = useAllowedOptions();

  const handleChange = (event: React.ChangeEvent<{ value: string }>) => {
    onChange(event.target.value || null);
  };

  return (
    <LoadingOverlay showSpinner={false} isLoading={loading}>
      <TextField
        select
        margin="none"
        label={capitalize(marketLabels.rosteredShift)}
        value={value || ''}
        onChange={handleChange}
        InputLabelProps={{
          shrink: true,
        }}
        SelectProps={{
          displayEmpty: true,
          readOnly: loading,
          IconComponent: loading ? () => null : undefined,
          endAdornment: loading && (
            <InputAdornment position="end">
              <TextFieldProgressAdornment />
            </InputAdornment>
          ),
        }}
        data-testid={'timesheet-modal-connected-shift'}
      >
        {value && !hasOption(allowedOptions, value) && (
          <MenuItem value={value} disabled>
            {capitalize(marketLabels.rosteredShift)} unavailable
          </MenuItem>
        )}

        <MenuItem value="">
          {capitalize(marketLabels.rosteredShift)} undetected
        </MenuItem>

        {allowedOptions.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </TextField>
    </LoadingOverlay>
  );
}

function useAllowedOptions() {
  const allowedToAssignShiftsArray = useSelector(getAllowedToAssignShiftsArray);
  const dateFormat = useSelector(getDateFormat);
  const timeFormat = useSelector(timeFormatSelector);

  return allowedToAssignShiftsArray.map(({ id, start, end }) => {
    const startFormatted = moment
      .parseZone(start)
      .format(`${dateFormat}, ${timeFormat}`);
    const endFormatted = moment.parseZone(end).format(timeFormat);

    return {
      label: `${startFormatted} - ${endFormatted}`,
      value: id,
    };
  });
}

function hasOption(options: { label: string; value: string }[], value: string) {
  return !!options.find((option) => option.value === value);
}
