import { RosteredShift } from 'type';
import useRosterCard, {
  useHandleClick,
  useHandleClickDeleteIcon,
} from '../useRosterCard';

export const useRosterDayCard = (rosteredShift: RosteredShift) => {
  const onClick = useHandleClick(rosteredShift);
  const onClickDeleteIcon = useHandleClickDeleteIcon(rosteredShift);

  const { breaks, icons, ...rest } = useRosterCard(rosteredShift);

  const viewProps = {
    ...rest,
    onClick,
    onClickDeleteIcon,
    isLocationShown: true,
    fullHeight: true,
    className: 'rostered-shift-card',
  };

  return {
    viewProps,
    icons,
    breaks,
  };
};
