import React from 'react';
import { Button, Text } from 'elmo-elements';
import { getById } from '../../helpers';
import { HighlightOffOutlinedIcon } from 'element';
import { getPreferenceLabel } from 'lib/helpers';
import { LanguagePreferences } from 'type/models';
import { isAppMarket } from '../../../../../../../../../../helpers';

type Props = {
  templateId: string;
  templates: any;
  setCurrentTemplate: (id: string) => void;
  getTemplateContent: (t: any) => void;
  langPreferences: LanguagePreferences
};

export const SelectedTemplate = ({
  templateId, templates, setCurrentTemplate, getTemplateContent, langPreferences
}: Props) => {
  const labelText = isAppMarket('uk') ? 'rota' : 'roster';
  return (
    <>
      <div className="templates-list__empty-item" style={{display: templateId !== '' ? 'none' : 'block'}}>
        <Text className={'mt-3 mb-3'} color={'gray'} isBold={true}>
          No {getPreferenceLabel(langPreferences, labelText, 'singular', labelText, true)} Template selected
        </Text>
      </div>
      {
        templateId && <div className="templates-list__group current-item selected">
          <div className={'templates-list__group--content'}>
            {getTemplateContent(getById(templateId, templates))}
            <Button
              id="remove-selected-template"
              isText={true}
              icon={<HighlightOffOutlinedIcon />}
              onClick={() => setCurrentTemplate('')}
              ariaLabel="Unselect template"
              tabIndex={0}
              className={'pin-r'}
            />
          </div>
        </div>
      }
    </>
  );
};
