import { createReducer } from 'lib/store-utils';
import { UserGroupsReducerState } from './types';
import * as actions from './actions';
import { defaultState } from './state';

export const userGroups = createReducer<UserGroupsReducerState>({}, defaultState);

// Get user groups
userGroups.on(actions.BOX_USER_GROUPS_REQUEST, state => ({
  ...state,
  isLoading: true,
  isFetching: true,
  errors: []
}));

userGroups.on(actions.BOX_USER_GROUPS_SUCCESS, (state, payload) => ({
  ...state,
  isLoading: false,
  isFetching: false,
  isFetched: true,
  userGroups: payload,
  errors: []
}));

userGroups.on(actions.BOX_USER_GROUPS_FAILURE, (state, errors) => ({
  ...state,
  isUpdating: false,
  isFetching: false,
  errors
}));

// Add Modal
userGroups.on(actions.BOX_USER_GROUPS_ADD_MODAL_OPEN, state => ({
  ...state,
  addModal: {
    ...state.addModal,
    isOpened: true,
    errors: []
  }
}));

userGroups.on(actions.BOX_USER_GROUPS_ADD_MODAL_CLOSE, state => ({
  ...state,
  addModal: {
    ...state.addModal,
    isOpened: false,
    errors: []
  },
  nameError: false
}));

// Update Modal
userGroups.on(actions.BOX_USER_GROUPS_UPDATE_MODAL_OPEN, state => ({
  ...state,
  updateModal: {
    ...state.updateModal,
    isOpened: true,
    errors: []
  }
}));

userGroups.on(actions.BOX_USER_GROUPS_UPDATE_MODAL_CLOSE, state => ({
  ...state,
  updateModal: {
    ...state.updateModal,
    isOpened: false,
    errors: []
  },
  nameError: false
}));

// Delete Modal
userGroups.on(actions.BOX_USER_GROUPS_DELETE_MODAL_OPEN, state => ({
  ...state,
  deleteModal: {
    ...state.deleteModal,
    isOpened: true,
    errors: []
  }
}));

userGroups.on(actions.BOX_USER_GROUPS_DELETE_MODAL_CLOSE, state => ({
  ...state,
  deleteModal: {
    ...state.deleteModal,
    isOpened: false,
    errors: []
  }
}));

// Add user group
userGroups.on(actions.BOX_ADD_USER_GROUP_REQUEST, state => ({
  ...state,
  addModal: {
    ...state.addModal,
    isUpdating: true,
  },
}));

userGroups.on(actions.BOX_ADD_USER_GROUP_SUCCESS, (state, payload) => ({
  ...state,
  userGroups: {
    ...state.userGroups,
    [payload.id]: {
      ...payload
    }
  },
  addModal: {
    ...state.addModal,
    isUpdating: false,
    isOpened: false,
  },
  errors: []
}));

userGroups.on(actions.BOX_ADD_USER_GROUP_FAILURE, (state, errors) => ({
  ...state,
  isLoading: false,
  addModal: {
    ...state.addModal,
    isUpdating: false,
    isOpened: true,
    errors
  },
  errors: []
}));

// Update User group
userGroups.on(actions.BOX_UPDATE_USER_GROUP_REQUEST, state => ({
  ...state,
  updateModal: {
    ...state.updateModal,
    isUpdating: true,
    errors: []
  },
  errors: []
}));

userGroups.on(actions.BOX_UPDATE_USER_GROUP_SUCCESS, state => ({
  ...state,
  updateModal: {
    ...state.updateModal,
    isUpdating: false,
    isOpened: false,
    errors: []
  },
  errors: []
}));

userGroups.on(actions.BOX_UPDATE_USER_GROUP_DATA, (state, payload) => ({
  ...state,
  userGroups: {
    ...state.userGroups,
    [payload.id]: {
      ...payload
    }
  },
  errors: []
}));

userGroups.on(actions.BOX_UPDATE_USER_GROUP_FAILURE, (state, errors) => ({
  ...state,
  updateModal: {
    ...state.updateModal,
    isUpdating: false,
    isOpened: true,
    errors
  },
  errors: []
}));

// Update Permissions
userGroups.on(actions.BOX_UPDATE_USER_GROUP_PERMISSIONS_REQUEST, state => ({
  ...state,
  isLoading: true,
  isPermissionsSaved: false,
  errors: []
}));

userGroups.on(actions.BOX_UPDATE_USER_GROUP_PERMISSIONS_SUCCESS, state => ({
  ...state,
  isLoading: false,
  isPermissionsSaved: true,
  errors: []
}));

userGroups.on(actions.BOX_UPDATE_USER_GROUP_PERMISSIONS_FAILURE, state => ({
  ...state,
  isLoading: false,
  isPermissionsSaved: false,
  errors: []
}));

userGroups.on(actions.BOX_UPDATE_USER_GROUP_PERMISSIONS_RESET, state => ({
  ...state,
  isPermissionsSaved: false,
}));

// Delete User group
userGroups.on(actions.BOX_DELETE_USER_GROUP_REQUEST, state => ({
  ...state,
  deleteModal: {
    ...state.deleteModal,
    isUpdating: true,
    errors: []
  },
  errors: []
}));

userGroups.on(actions.BOX_DELETE_USER_GROUP_SUCCESS, state => ({
  ...state,
  deleteModal: {
    ...state.deleteModal,
    isUpdating: false,
    isOpened: false,
    errors: []
  },
  errors: []
}));

userGroups.on(actions.BOX_DELETE_USER_GROUP_FAILURE, (state, errors) => ({
  ...state,
  deleteModal: {
    ...state.deleteModal,
    isUpdating: false,
    errors
  },
  errors: []
}));

userGroups.on(actions.BOX_USER_GROUP_SHOW_NAME_ERROR_REQUEST, state => ({
  ...state,
  nameError: true
}));

userGroups.on(actions.BOX_USER_GROUP_HIDE_NAME_ERROR_REQUEST, state => ({
  ...state,
  nameError: false
}));

userGroups.on(actions.BOX_USER_GROUP_SELECT_REQUEST, (state, payload) => ({
  ...state,
  group: {
    ...state.group,
    ...payload
  }
}));

userGroups.on(actions.BOX_USER_GROUP_CLEAR_ERRORS, state => ({
  ...state,
 errors: []
}));
