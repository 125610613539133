import routeQuery from 'lib/routeQuery';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Dictionary, Merge } from 'ts-essentials';

type RouteQuery<Query> = {
  query: Partial<Query>;
  changeQueryItems: (query: Partial<Query>) => void;
  replaceQueryItems: (query: Partial<Query>) => void;
};

type RequiredRouteQuery<Query> = Merge<RouteQuery<Query>, { query: Query }>;
export type UseRouteQuery<Query> = () => RequiredRouteQuery<Query>;

export default useRouteQuery;

export function useRouteQuery<
  Query extends Dictionary<string>
>(): RouteQuery<Query> {
  const { push, replace } = useHistory();
  const { search } = useLocation();

  const query: RouteQuery<Query>['query'] = React.useMemo(
    () => routeQuery.parse(search) as Partial<Query>,
    [search]
  );

  const changeQueryItems: RouteQuery<Query>['changeQueryItems'] =
    React.useCallback(
      (partialNewQuery) => {
        push({
          search: routeQuery.stringify({ ...query, ...partialNewQuery }),
        });
      },
      [query]
    );

  const replaceQueryItems: RouteQuery<Query>['replaceQueryItems'] =
    React.useCallback(
      (partialNewQuery) => {
        replace({
          search: routeQuery.stringify({ ...query, ...partialNewQuery }),
        });
      },
      [query]
    );

  return {
    query,
    changeQueryItems,
    replaceQueryItems,
  };
}
