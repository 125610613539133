import {
  TimeOffDuration,
  TimeOffRepeat,
  TimeOffStatus,
  Collection
} from 'type';
import { createSelectPropsOptions } from 'helpers/helpers';

type Label = {
  label: string;
};

const durationLabels: Collection<TimeOffDuration, Label> = {
  all_day: {
    label: 'All day'
  },
  few_hours: {
    label: 'A few hours'
  },
  multiple_days: {
    label: 'Multiple days'
  }
};
export const durationOptions = createSelectPropsOptions(durationLabels);

const repeatLabels: Collection<TimeOffRepeat, Label> = {
  once: {
    label: 'Once'
  },
  daily: {
    label: 'Daily'
  },
  weekly: {
    label: 'Weekly'
  },
  fortnightly: {
    label: 'Fortnightly'
  },
  four_weekly: {
    label: '4-weekly'
  }
};
export const repeatOptions = createSelectPropsOptions(repeatLabels);

const statusLabels: Collection<TimeOffStatus, Label> = {
  pending: {
    label: 'Pending'
  },
  declined: {
    label: 'Declined'
  },
  approved: {
    label: 'Approved'
  }
};
export const statusOptions = createSelectPropsOptions(statusLabels);
