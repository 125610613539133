import {
  Layout,
  NewHeader,
  NewHeaderWrapper,
  SkeletonIconButton,
  SkeletonTypography,
} from 'element';
import { Box, Grid, Skeleton } from 'extended-oxygen-elements';
import _ from 'lodash';
import React from 'react';

export default function PageSkeleton() {
  return (
    <>
      <Layout.Styling disableScroll />

      <Layout.Header>
        <NewHeaderWrapper>
          <NewHeader>
            <SkeletonIconButton sx={{ mr: 1 }} />

            <SkeletonTypography variant="h1" maxWidth={300} />
          </NewHeader>
        </NewHeaderWrapper>
      </Layout.Header>
      <Layout.Content>
        {_.times(2).map((siteIndex) => (
          <React.Fragment key={siteIndex}>
            <Box display="flex" mb={1}>
              <SkeletonIconButton sx={{ mr: 1 }} />

              <SkeletonTypography variant="h2" maxWidth={300} />
            </Box>

            <Grid container spacing={2} sx={{ mb: 2 }}>
              {_.times(5).map((areaIndex) => (
                <Grid item xs={12} sm={6} md={4} key={areaIndex}>
                  <Skeleton variant="rectangular" height={220} />
                </Grid>
              ))}
            </Grid>
          </React.Fragment>
        ))}
      </Layout.Content>
    </>
  );
}
