import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import { getDateFormat, getLangPreferences } from 'state/Account';
import View, { Props as ViewProps } from './view';
import { BOX_WHOS_WORKING_UPDATE_FORM_FILTERS, getFiltersForm } from 'state/ManagerDashboard/WhosWorking';
import { getRoles } from 'state/AccountTree';
import { AccountTreeRole, LanguagePreferences, StringMap } from 'type';

type StateProps = Pick<ViewProps, 'formData' | 'dateFormat'>;

type DispatchProps = Pick<ViewProps, 'onChange'>;

type RolesProps = {
  roles: StringMap<AccountTreeRole>;
  langPreferences: LanguagePreferences;
}

type Props = StateProps & DispatchProps & RolesProps;

type StateRoleProps = StateProps & RolesProps;

const CustomFiltersComponent = (props: Props) => <View {...props} />;

const mapStateToProps = (state: StoreState): StateRoleProps => ({
  formData: getFiltersForm(state),
  dateFormat: getDateFormat(state),
  roles: getRoles(state),
  langPreferences: getLangPreferences(state),
});

const mapDispatchToProps: DispatchProps = {
  onChange: BOX_WHOS_WORKING_UPDATE_FORM_FILTERS
};

export const CustomFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomFiltersComponent);
