import { CovidTracingState } from './types';

export const getDefaultState = (): CovidTracingState => ({
  isEnabled: false,
  isLoading: false,
  shiftId: null,
  entity: null,
  period: '14',
  shifts: {
    rostered_shift_ids: [],
    timesheet_ids: [],
    all: []
  },
  printData: [],
  isPrinting: false
})
