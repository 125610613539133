import { createAction } from 'lib/store-utils';
import { FormattedErrors, RosterTimesheetListedWithPayEntries } from 'type';
import { TimesheetSimpleCreateRequest, TimesheetSimpleUpdateRequest } from 'lib/Api';
import { createAsyncAction } from 'lib/store-utils';
import {
  GetPageDataPayload,
  GetPageDataSuccessPayload,
  StopTimesheetPayload,
  StopTimesheetSuccessPayload,
  TimesheetsApprovePayload,
  TimesheetsApproveSuccessPayload,
  TimesheetsDeletePayload,
  TimesheetsDeleteSuccessPayload,
  UpdatePageDataPayload,
  UpdatePageDataSuccessPayload,
  UpdateSummaryPayload,
  UpdateSummarySuccessPayload,
  ChangeApproveTimesheetPayload,
  GetAllowedToAssignShiftsPayload,
  GetAllowedToAssignShiftsSuccessPayload
} from './types';

export const BOX_TIMESHEETS_WEEKLY_PAGE_DATA_GET = createAsyncAction<GetPageDataPayload, GetPageDataSuccessPayload, FormattedErrors>('Timesheets weekly / fetch page data');
export const BOX_TIMESHEETS_WEEKLY_PAGE_DATA_UPDATE = createAsyncAction<UpdatePageDataPayload, UpdatePageDataSuccessPayload, FormattedErrors>('Timesheets weekly / update page data');
export const BOX_TIMESHEETS_WEEKLY_SUMMARY_UPDATE = createAsyncAction<UpdateSummaryPayload, UpdateSummarySuccessPayload, FormattedErrors>('Timesheets weekly / update summary');
export const BOX_TIMESHEETS_WEEKLY_TIMESHEET_DELETE = createAsyncAction<ChangeApproveTimesheetPayload, { id: string }, FormattedErrors>('Timesheets weekly / delete timesheet');
export const BOX_TIMESHEETS_WEEKLY_TIMESHEET_APPROVE = createAsyncAction<ChangeApproveTimesheetPayload, RosterTimesheetListedWithPayEntries, FormattedErrors>('Timesheets weekly / approve timesheet');
export const BOX_TIMESHEETS_WEEKLY_TIMESHEET_UNAPPROVE = createAsyncAction<ChangeApproveTimesheetPayload, RosterTimesheetListedWithPayEntries, FormattedErrors>('Timesheets weekly / unapprove timesheet');
export const BOX_TIMESHEETS_WEEKLY_TIMESHEET_CREATE = createAsyncAction<TimesheetSimpleCreateRequest, RosterTimesheetListedWithPayEntries, FormattedErrors>('Timesheets weekly / create timesheet');
export const BOX_TIMESHEETS_WEEKLY_TIMESHEET_STOP = createAsyncAction<StopTimesheetPayload, StopTimesheetSuccessPayload, FormattedErrors>('Timesheets weekly / stop timesheet');
export const BOX_TIMESHEETS_WEEKLY_TIMESHEET_UPDATE = createAsyncAction<TimesheetSimpleUpdateRequest, RosterTimesheetListedWithPayEntries, FormattedErrors>('Timesheets weekly / update timesheet');
export const BOX_TIMESHEETS_WEEKLY_TIMESHEETS_DELETE = createAsyncAction<TimesheetsDeletePayload, TimesheetsDeleteSuccessPayload, FormattedErrors>('Timesheets weekly / delete timesheets');
export const BOX_TIMESHEETS_WEEKLY_TIMESHEETS_APPROVE = createAsyncAction<TimesheetsApprovePayload, TimesheetsApproveSuccessPayload, FormattedErrors>('Timesheets weekly / approve timesheets');
export const BOX_TIMESHEETS_WEEKLY_ALLOWED_TO_ASSIGN_SHIFTS_GET = createAsyncAction<GetAllowedToAssignShiftsPayload, GetAllowedToAssignShiftsSuccessPayload, FormattedErrors>('Timesheets weekly / get allowed to assign shifts');
export const BOX_TIMESHEETS_WEEKLY_ALLOWED_TO_ASSIGN_SHIFTS_UPDATE = createAsyncAction<GetAllowedToAssignShiftsPayload, GetAllowedToAssignShiftsSuccessPayload, FormattedErrors>('Timesheets weekly / update allowed to assign shifts');

export const BOX_TIMESHEETS_WEEKLY_ERRORS_CLOSED = createAction('Timesheets weekly / errors cleared');
export const BOX_TIMESHEETS_WEEKLY_DIALOG_ERRORS_CLOSED = createAction('Timesheets weekly / dialog / errors cleared');
export const BOX_TIMESHEETS_WEEKLY_DIALOG_OPENED = createAction('Timesheets weekly / dialog / opened');

