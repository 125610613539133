import React, { ComponentType, memo } from 'react';
import { SvgIconProps } from 'extended-oxygen-elements';
import { ShiftCardIcon } from './ShiftCardIcon';

export type CardIconsPropsIcon = {
  title: string;
  color: string;
  Icon: ComponentType<SvgIconProps>;
};

type CardIconsProps = {
  icons: CardIconsPropsIcon[];
};

export const ShiftCardIcons = memo(function CardIcons({ icons }: CardIconsProps) {
  return (
    <>
      {icons.map(({ Icon, ...restIcon }, iconIndex) => (
        <ShiftCardIcon key={iconIndex} {...restIcon} icon={<Icon />} />
      ))}
    </>
  );
});
