import React, { Component, KeyboardEvent } from 'react';
import RadioWrapper from './component/RadioWrapper';
import RadioLabel from './component/RadioLabel';
import RadioElement from './component/RadioElement';
import { RadioKeyConstants, RadioProps } from './type';
import Tooltip from '../Tooltip';

class Radio extends Component<RadioProps> {
  static displayName: string = 'Radio';

  handleOnChange = () => {
    const { disabled, onChange, value } = this.props;

    if (disabled) {
      return;
    }

    if (onChange) {
      onChange(value);
    }
  };

  /**
   * When keyup events occur within the component
   * @param e
   */
  onKeyUp = (e: KeyboardEvent) => {
    e.preventDefault();

    if (e.key === RadioKeyConstants.enterKey) {
      this.handleOnChange();
    }
  };

  render() {
    const { id, name, value, disabled, checked, children, className, tooltip } =
      this.props;

    const radioWrapper = (
      <RadioWrapper
        disabled={disabled}
        checked={checked}
        onKeyUp={this.onKeyUp}
        id={id}
        className={className}
      >
        <RadioElement
          name={name}
          value={value}
          disabled={disabled}
          onChange={this.handleOnChange}
          checked={checked}
        />
        <RadioLabel>{children}</RadioLabel>
      </RadioWrapper>
    );

    if (tooltip) {
      return (
        <Tooltip tabIndex={-1} title={tooltip} placement="bottom">
          {radioWrapper}
        </Tooltip>
      );
    }
    return radioWrapper;
  }
}

export default Radio;
