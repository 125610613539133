import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { Api } from 'lib/Api';
import { formatError } from 'state/helpers';
import {
  BOX_SUMMARY_GET_DATA,
  BOX_SUMMARY_GET_DATA_FAILURE,
  BOX_SUMMARY_GET_DATA_ON_ROSTER_UPDATES,
  BOX_SUMMARY_GET_DATA_REQUEST,
  BOX_SUMMARY_GET_DATA_SUCCESS,
  BOX_SUMMARY_SET_STATUS,
} from './actions';
import { getFrom, getSiteId } from 'state/RosteredShifts/selectors';
import { Moment } from 'moment';
import {
  RosterWeekSummaryRequest,
  RosterWeekSummaryResponse,
} from 'lib/Api/type';
import {
  filtersSelector,
  getFiltersShowArchivedUsers,
  ROSTER_FILTER_WITHOUT,
  RosterFilters,
} from '../RosterFilters';
import { includes, isNull, without } from 'lodash';
import { processApiRequest } from 'state/ProcessApiRequest';
import { hasPermissionSelector } from 'state/Auth';
import { getChannelIsActive } from 'state/Sockets';
import { getSummaryStatus } from './selectors';
import { SagaAction } from '../../../type';
import { SummaryStatus } from './types';

const getSummaryPayload = function* (): SagaIterator {
  const siteId: string = yield select(getSiteId);
  const date: Moment = yield select(getFrom);
  const { events, tags, areas, roles }: RosterFilters = yield select(
    filtersSelector
  );

  const payload: RosterWeekSummaryRequest = {
    site_id: siteId,
    date,
    area_ids: areas,
    role_ids: roles,
    event_ids: null,
    include_shifts_without_events: null,
    tag_ids: null,
    include_shifts_without_tags: null,
  };

  if (!isNull(events)) {
    const includeShiftsWithoutEvents: boolean = includes(
      events,
      ROSTER_FILTER_WITHOUT
    );
    payload.include_shifts_without_events = includeShiftsWithoutEvents;
    payload.event_ids = includeShiftsWithoutEvents
      ? without(events, ROSTER_FILTER_WITHOUT)
      : events;
  }

  if (!isNull(tags)) {
    const includeShiftsWithoutTags: boolean = includes(
      tags,
      ROSTER_FILTER_WITHOUT
    );
    payload.include_shifts_without_tags = includeShiftsWithoutTags;
    payload.tag_ids = includeShiftsWithoutTags
      ? without(tags, ROSTER_FILTER_WITHOUT)
      : tags;
  }

  return payload;
};

// TODO types
const filtersHaveNonEmptyValues = (payload: any) => {
  const {
    area_ids,
    role_ids,
    event_ids,
    include_shifts_without_events,
    tag_ids,
    include_shifts_without_tags,
  } = payload;
  return (
    area_ids ||
    role_ids ||
    event_ids ||
    include_shifts_without_events ||
    tag_ids ||
    include_shifts_without_tags
  );
};

export const getSummaryData = function* (): SagaIterator {
  const canViewRosteredShifts = yield select(
    hasPermissionSelector,
    'roster.rosteredshift.view'
  );
  const canViewTimesheets = yield select(
    hasPermissionSelector,
    'roster.timesheet.view'
  );
  const summaryStatus = yield select(getSummaryStatus);

  if (!(canViewRosteredShifts || canViewTimesheets)) {
    return;
  }

  if (summaryStatus === 'hidden') {
    return;
  }

  yield put(BOX_SUMMARY_GET_DATA_REQUEST());

  try {
    const payload = yield call(getSummaryPayload);
    const showArchived = yield select(getFiltersShowArchivedUsers);

    const response: RosterWeekSummaryResponse = yield call(
      processApiRequest,
      Api.Roster.weekSummary,
      {
        ...payload,
        archive_users: showArchived ? 1 : 0,
      }
    );

    yield put(BOX_SUMMARY_GET_DATA_SUCCESS(response));
  } catch (errors) {
    yield put(BOX_SUMMARY_GET_DATA_FAILURE(formatError(errors)));
  }
};

const getSummaryDataOnUpdate = function* (): SagaIterator {
  const isChannelActive = yield select(getChannelIsActive, 'roster_week');
  const payload = yield call(getSummaryPayload);
  if (!isChannelActive || filtersHaveNonEmptyValues(payload)) {
    yield put(BOX_SUMMARY_GET_DATA());
  }
};

export const watchSummary = function* (): SagaIterator {
  yield takeLatest(BOX_SUMMARY_GET_DATA, getSummaryData);
  yield takeLatest(
    BOX_SUMMARY_GET_DATA_ON_ROSTER_UPDATES,
    getSummaryDataOnUpdate
  );
};
