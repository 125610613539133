import { CheckboxProps } from 'extended-oxygen-elements';
import { PickKeys } from 'ts-essentials';

type ValueType<C> = C extends (u: Partial<infer V>) => void ? V : never;

export default useSetCheckbox;

export function useSetCheckbox<ChangeHandler extends (u: Partial<any>) => void>(
  onChange: ChangeHandler
) {
  type Name = PickKeys<ValueType<ChangeHandler>, boolean>;

  const setCheckbox = (name: Name, checked: boolean) => {
    onChange({
      [name]: checked,
    });
  };

  const makeSetCheckbox =
    (name: Name): CheckboxProps['onChange'] =>
    (event, checked) => {
      setCheckbox(name, checked);
    };

  return { setCheckbox, makeSetCheckbox };
}
