import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { Api } from 'lib/Api';
import { BOX_TIMEZONES_FAILURE, BOX_TIMEZONES_SUCCESS } from './actions';
import { processApiRequest } from '../ProcessApiRequest/sagas';
import { formatError } from '../helpers';

export const getTimezonesRequest = function* (): SagaIterator {
  const timezones: string[] = yield call(processApiRequest, Api.Misc.timezones);
  yield put(BOX_TIMEZONES_SUCCESS(timezones));
};

export const getTimezones = function*(): SagaIterator {
  try {
    yield call(getTimezonesRequest);
  } catch (error) {
    yield put(BOX_TIMEZONES_FAILURE(formatError(error)));
  }
};
