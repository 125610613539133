import React from 'react';
import { useDispatch } from 'react-redux';
import { Parameter } from 'type';
import { BOX_PAY_ELEMENTS_PAY_ELEMENT_CREATE } from 'state/PayElementsCollection';
import PayElementForm from './components/PayElementForm';
import { usePayElementsListContext } from './pay-elements-list-context';

export default function CreatePayElement() {
  const dispatch = useDispatch();
  const { isCreateOpen } = usePayElementsListContext();

  const handleSubmit = (
    payload: Parameter<typeof BOX_PAY_ELEMENTS_PAY_ELEMENT_CREATE.request>
  ) => {
    dispatch(BOX_PAY_ELEMENTS_PAY_ELEMENT_CREATE.request(payload));
  };

  return <>{isCreateOpen && <PayElementForm onSubmit={handleSubmit} />}</>;
}
