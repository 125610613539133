import React, { Component } from 'react';
import { getClass } from '../_lib/helper';
import FormItemLabel from './component/FormItemLabel';
import FormItemControl from './component/FormItemControl';
import FormItemMessage from './component/FormItemMessage';
import FormItemIcon from './component/FormItemIcon';
import { FormItemProps } from './type';
import './FormItem.scss';

const FormItemDefaultProps = {
  status: 'default',
  labelAddon: null,
  message: '',
  isFluid: true,
};

class FormItem extends Component<FormItemProps> {
  static Label = FormItemLabel;
  static defaultProps = FormItemDefaultProps;

  getClassname = () => {
    const status = this.getStatus();
    const { isFluid, icon, isDivider, isInline, size, className, isBlock } =
      this.props;

    return getClass('elmo-formitem', className, {
      [`on-${status}`]: status,
      [`size-${size}`]: size,
      [`is-fluid`]: isFluid && !size,
      [`is-inline`]: isInline,
      [`is-icon`]: icon,
      [`is-divider`]: isDivider !== undefined,
      [`is-block-max2`]: isBlock,
    });
  };

  getStatus() {
    // if error returns any, set the status as error
    const { error, status, isTouched } = this.props;
    return error && isTouched ? 'error' : status;
  }

  getMessage() {
    // if error returns any, overwrite the message with error
    const { error, message, isTouched } = this.props;
    return error && isTouched ? error : message;
  }

  render() {
    const { label, icon, labelAddon, children, id } = this.props;

    return (
      <div
        id={id}
        className={this.getClassname()}
        data-testid={`elmo-form-item-${id || 'default'}`}
      >
        {icon && <FormItemIcon icon={icon} />}
        <div className="elmo-formitem__body">
          {(label || labelAddon) && (
            <FormItemLabel label={label} labelAddon={labelAddon} />
          )}
          <div className="elmo-formitem__control">
            <FormItemControl children={children} />
            <FormItemMessage message={this.getMessage()} />
          </div>
        </div>
      </div>
    );
  }
}

export default FormItem;
