import { StoreState } from 'state/types';
import { WarningsReducerState } from './types';

const getState = (state: StoreState): WarningsReducerState =>
  state.errorsProcessing.warnings;

export const getIsOpened = (state: StoreState): boolean =>
  getState(state).isOpened;
export const getMessages = (state: StoreState): string[] =>
  getState(state).messages;
