import React from 'react';
import LoaderHolder from './LoaderHolder';
import { LoaderHolderProps } from '../type';

function ListStyle(props: LoaderHolderProps) {
  return (
    <LoaderHolder {...props}>
      <rect x="0" y="10" rx="3" ry="3" width="250" height="10" />
      <rect x="20" y="30" rx="3" ry="3" width="220" height="10" />
      <rect x="20" y="50" rx="3" ry="3" width="170" height="10" />
      <rect x="0" y="70" rx="3" ry="3" width="250" height="10" />
      <rect x="20" y="90" rx="3" ry="3" width="200" height="10" />
      <rect x="20" y="110" rx="3" ry="3" width="80" height="10" />
    </LoaderHolder>
  );
}

export default ListStyle;
