import React from 'react';
import { useSelectorWithProps } from 'hooks';
import { getTemplateShift } from 'state/Roster/EditTemplate';
import { useAreaName, useTemplateCard, useUserName } from './hooks';
import { TemplateContextualMenu } from './contextualMenus';
import { ShiftCardBreaksDuration, ShiftCardContextMenuIcon } from './views';
import { ShiftCardViewRefactored } from './views/ShiftCardViewRefactored';
import { getUserIsArchived } from '../../../../state/UsersCollection';

type Props = {
  templateShiftId: string;
};

export const SiteTemplateCard = React.memo(function SiteTemplateCard(props: Props) {
  const templateShift = useSelectorWithProps(
    getTemplateShift,
    props.templateShiftId
  );
  const { viewProps, onClickContextualMenu, breaks } =
    useTemplateCard(templateShift);
  const userName = useUserName(templateShift);
  const areaName = useAreaName(templateShift);
  const isUserArchived = useSelectorWithProps(
    getUserIsArchived,
    templateShift.user_id
  );

  return (
    <ShiftCardViewRefactored
      {...viewProps}
      beforeContent={
        <TemplateContextualMenu
          elementId={viewProps.id}
          templateShiftId={props.templateShiftId}
        />
      }
      icons={
        viewProps.onClick && (
          <ShiftCardContextMenuIcon onClick={onClickContextualMenu} />
        )
      }
      breaks={<ShiftCardBreaksDuration breaks={breaks} />}
      title={userName}
      spotTitle={areaName}
      className={isUserArchived ? 'is-disabled' : ''}
    />
  );
});
