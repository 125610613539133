import React, { Component } from 'react';
import { NoteTypes, NotificationProps } from './type';
import { getClass } from '../_lib/helper';
import Button from '../Button';
import { CloseIcon } from '../icons';
import './Notification.scss';

export default class Notification extends Component<NotificationProps> {
  static defaultProps = {
    type: NoteTypes.dark,
    undoBtnText: 'Undo',
  };

  onMouseOver = () => {
    const { onmouserover } = this.props;
    if (onmouserover) {
      onmouserover();
    }
  };

  onMouseOut = () => {
    const { onmouseout } = this.props;
    if (onmouseout) {
      onmouseout();
    }
  };

  render() {
    const { type, icon, message, isActive, onClose, onUndo, id, undoBtnText } =
      this.props;
    const classIsActive = isActive ? 'elmo-notification__item--active' : '';
    const customClass = getClass('elmo-notification__item', classIsActive, {
      [`${type}`]: type,
    });
    const textClass = getClass('elmo-notification__text', {
      'elmo-notification__text--icon': icon,
    });
    return (
      <>
        <div
          id={id}
          data-testid={`elmo-notification-${id || 'default'}`}
          className="elmo-notification"
          role="alert"
        >
          <div className="elmo-notification__wrapper">
            <div
              onMouseOver={this.onMouseOver}
              onMouseOut={this.onMouseOut}
              className={customClass}
            >
              <div className="elmo-notification__flex">
                {icon && <div className="elmo-notification__icon">{icon}</div>}
                <div className={textClass}>{message}</div>
                {onUndo && (
                  <Button
                    data-testid="elmo-notification-undo-btn"
                    isText={true}
                    className="elmo-notification__action undo_action"
                    onClick={onUndo}
                    ariaLabel={undoBtnText}
                  >
                    {undoBtnText}
                  </Button>
                )}
                <Button
                  icon={<CloseIcon />}
                  isText={true}
                  onClick={onClose}
                  className="elmo-notification__close"
                  ariaLabel="Close"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
