import { FormItem } from 'elmo-elements';
import React, { useEffect, useState } from 'react';
import { useSelectorWithProps } from 'hooks';
import {
  BOX_ROSTER_CLOSE_APPLY_CHANGES_MODAL,
  BOX_ROSTER_DELETE_CLEAR_ERRORS,
  BOX_ROSTER_TIMESHEETS_AUTOGENERATE_REQUEST,
  getErrors,
  getIsGenerating,
  getIsModalOpen,
  getSuccessMsg,
} from 'state/Roster/Roster';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { SERVER_DAY_FORMAT } from 'lib/config';
import { getFrom, getSiteId, getTo } from 'state/RosteredShiftsCollection';
import {
  DateInputMoment,
  ErrorBox,
  PageDialog,
  PageDialogCancel,
  PageDialogSubmit,
} from 'element';
import { Alert, DialogContent, DialogTitle } from 'extended-oxygen-elements';
import { DialogActions } from 'extended-oxygen-elements';
import { Typography } from 'oxygen-elements';

const GenerateTimesheetsModal = () => {
  const isOpened = useSelectorWithProps(getIsModalOpen, 'generate');
  const from = useSelector(getFrom);
  const to = useSelector(getTo);
  const siteId = useSelector(getSiteId);
  const dispatch = useDispatch();
  const isGenerating = useSelector(getIsGenerating);
  const [start, setStart] = useState<Moment>(from.clone());
  const [end, setEnd] = useState<Moment>(to.clone());
  const errors = useSelector(getErrors);
  const successMsg = useSelector(getSuccessMsg);
  const [successAlert, setSuccess] = useState(false);

  useEffect(() => {
    setSuccess(!!successMsg);
  }, [successMsg]);

  useEffect(() => {
    setSuccess(false);
    setStart(from);
    setEnd(to);
  }, [isOpened]);

  const closeModal = () => {
    dispatch(BOX_ROSTER_CLOSE_APPLY_CHANGES_MODAL());
  };

  const onDateRangeChange = (value: Moment | null, name: string) => {
    setSuccess(false);
    if (name === 'start') {
      setStart(moment(value));
    } else {
      setEnd(moment(value));
    }
    return;
  };

  const generateTimesheets = () => {
    setSuccess(false);
    dispatch(
      BOX_ROSTER_TIMESHEETS_AUTOGENERATE_REQUEST({
        site_id: siteId,
        period: {
          from: start.format(SERVER_DAY_FORMAT),
          to: end.format(SERVER_DAY_FORMAT),
        },
      })
    );
  };

  const disabledDatesTo = (current?: Date): boolean => {
    if (current && start !== null) {
      return moment(current) < start;
    }
    return false;
  };

  const disabledDatesFrom = (current?: Date): boolean => {
    if (current && end !== null) {
      return moment(current) > end;
    }
    return false;
  };

  return (
    <PageDialog
      maxWidth={'xs'}
      open={isOpened}
      id={'autogenerate-modal'}
      onClose={closeModal}
    >
      <DialogTitle>Autogenerate timesheets</DialogTitle>
      <DialogContent>
        {errors.length > 0 && (
          <ErrorBox
            errors={errors}
            clearErrors={() => dispatch(BOX_ROSTER_DELETE_CLEAR_ERRORS())}
          />
        )}
        <Typography variant={'body1'} sx={{ paddingBottom: '15px' }}>
          Please specify period when timesheets should be created
        </Typography>
        <FormItem label={'From'}>
          <DateInputMoment
            name={'start'}
            onChange={(e: Moment | null) => onDateRangeChange(e, 'start')}
            value={start}
            placeholder={SERVER_DAY_FORMAT}
            disabledDate={disabledDatesFrom}
            isInline={false}
            isReadOnly={true}
            className={'bg-white'}
            ariaLabel="Date to"
          />
        </FormItem>
        <FormItem label={'To'}>
          <DateInputMoment
            name={'end'}
            onChange={(e: Moment | null) => onDateRangeChange(e, 'end')}
            value={end}
            placeholder={SERVER_DAY_FORMAT}
            disabledDate={disabledDatesTo}
            isInline={false}
            isReadOnly={true}
            className={'bg-white'}
            ariaLabel="Date to"
          />
        </FormItem>
        {successAlert && (
          <Alert severity="success" onClose={() => setSuccess(false)}>
            {successMsg}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <PageDialogCancel onClick={closeModal}>Cancel</PageDialogCancel>
        <PageDialogSubmit
          disabled={!start || !end || isGenerating}
          onClick={generateTimesheets}
          loading={isGenerating}
        >
          Generate
        </PageDialogSubmit>
      </DialogActions>
    </PageDialog>
  );
};

export default GenerateTimesheetsModal;
