import { createAction } from 'lib/store-utils';
import {
  FormattedErrors,
  HigherDutiesItem,
  RecalculatePayload,
  RecalculateResponse,
  RosteredShift,
  StringMap,
  Timesheet,
  TimesheetPayload
} from '../../type';
import {
  AddAllowanceResponse,
  AllowancePayload,
  AllowancesResponse,
  CreateSelfAssignedShiftPayload,
  DeleteAllowancePayload,
  DeleteAllowanceResponse,
  GetAllowancesPayload,
  GetTimesheetByIDPayload,
  HigherDutiesPayload,
  HigherDutiesResponse,
  ListOfAllowedShiftsPayload
} from './types';
import {
  ApproveTimesheetPayload,
  TimesheetCreateRequest,
  TimesheetDeleteRequest,
  TimesheetUpdateRequest
} from 'lib/Api/type';

export const BOX_TIMESHEET_SHIFT_MODAL_OPEN = createAction('Timesheet modal / open');
export const BOX_TIMESHEET_SHIFT_MODAL_CLOSE = createAction('Timesheet modal / close');
export const BOX_TIMESHEET_SHIFT_MODAL_GET_BY_ID = createAction<GetTimesheetByIDPayload>('Timesheet modal / get by id');
export const BOX_TIMESHEET_SHIFT_MODAL_GET_BY_ID_SUCCESS = createAction<Timesheet>('Timesheet modal / get by id success');
export const BOX_TIMESHEET_SHIFT_MODAL_GET_BY_ID_FAILURE = createAction<FormattedErrors>('Timesheet modal / get by id failure');

export const BOX_TIMESHEET_SHIFT_MODAL_SET_SHIFT = createAction<GetTimesheetByIDPayload>('Timesheet modal / set current');

export const BOX_TIMESHEET_SHIFT_MODAL_CREATE_SHIFT = createAction<TimesheetCreateRequest>('Timesheet modal / create shift request');
export const BOX_TIMESHEET_SHIFT_MODAL_CREATE_SELF_ASSIGNED_SHIFT = createAction<CreateSelfAssignedShiftPayload>('Timesheet modal / create self assigned shift request');
export const BOX_TIMESHEET_SHIFT_MODAL_CREATE_SHIFT_CANCEL = createAction('Timesheet modal / create cancel');
export const BOX_TIMESHEET_SHIFT_MODAL_CREATE_SHIFT_SUCCESS = createAction('Timesheet modal / create success');
export const BOX_TIMESHEET_SHIFT_MODAL_CREATE_SHIFT_FAILURE = createAction<FormattedErrors>('Timesheet modal / create failure');

export const BOX_TIMESHEET_SHIFT_MODAL_UPDATE = createAction<TimesheetUpdateRequest | null>('Timesheet modal / update request');
export const BOX_TIMESHEET_SHIFT_MODAL_UPDATE_SHIFT_SUCCESS = createAction('Timesheet modal / update success');
export const BOX_TIMESHEET_SHIFT_MODAL_UPDATE_SHIFT_FAILURE = createAction<FormattedErrors>('Timesheet modal / update failure');

export const BOX_TIMESHEET_SHIFT_MODAL_CLEAR_ERRORS = createAction('Timesheet modal / clear all errors');

export const BOX_TIMESHEET_SHIFT_MODAL_DELETE_SHIFT = createAction<TimesheetDeleteRequest>('Timesheet modal / delete request');
export const BOX_TIMESHEET_SHIFT_MODAL_DELETE_SHIFT_SUCCESS = createAction('Timesheet modal / delete success');
export const BOX_TIMESHEET_SHIFT_MODAL_DELETE_SHIFT_FAILURE = createAction<FormattedErrors>('Timesheet modal / delete failure');

export const BOX_TIMESHEET_SHIFT_MODAL_SET_SHIFT_USER = createAction<string | null>('Timesheet modal / set current shift user');
export const BOX_TIMESHEET_SHIFT_MODAL_SET_PAYLOAD_PROPS = createAction<TimesheetPayload>('Timesheet modal / set props for request');

export const BOX_TIMESHEET_SHIFT_MODAL_APPROVE_TIMESHEET = createAction<ApproveTimesheetPayload>('Timesheet modal / approve timesheet');
export const BOX_TIMESHEET_SHIFT_MODAL_APPROVE_SUCCESS = createAction('Timesheet modal / approve timesheet success');
export const BOX_TIMESHEET_SHIFT_MODAL_APPROVE_FAILURE = createAction<FormattedErrors>('Timesheet modal / approve timesheet failure');
export const BOX_TIMESHEET_SHIFT_MODAL_OVERLAP_CONFIRMATION_SET_DELETING = createAction('Timesheet modal / set confirmation modal prop isDeleting');
export const BOX_TIMESHEET_SHIFT_MODAL_OVERLAP_CONFIRMATION_OPEN = createAction<FormattedErrors>('Timesheet modal / open confirmation modal'); // TODO double check whether this action is needed
export const BOX_TIMESHEET_SHIFT_MODAL_OVERLAP_CONFIRMATION_CLOSE = createAction<boolean>('Timesheet modal / close confirmation modal');
export const BOX_TIMESHEET_SHIFT_MODAL_OVERLAP_CONFIRMATION_APPROVE = createAction('Timesheet modal / change status overlap message');

export const BOX_TIMESHEET_SHIFT_MODAL_GET_LIST_OF_SHIFTS = createAction<ListOfAllowedShiftsPayload>('Timesheet modal / get list of allowed to connect shifts ');
export const BOX_TIMESHEET_SHIFT_MODAL_GET_LIST_OF_SHIFTS_SUCCESS = createAction<StringMap<RosteredShift>>('Timesheet modal / get list of allowed to connect shifts success');
export const BOX_TIMESHEET_SHIFT_MODAL_GET_LIST_OF_SHIFTS_FAILURE = createAction<FormattedErrors>('Timesheet modal / get list of allowed to connect shifts failure');

export const BOX_TIMESHEET_SHIFT_MODAL_TOGGLE_APPROVE = createAction<boolean>('Timesheet modal / update property');

export const BOX_TIMESHEET_SHIFT_MODAL_RECALCULATE = createAction<RecalculatePayload>('Timesheet modal / recalculate request');
export const BOX_TIMESHEET_SHIFT_MODAL_RECALCULATE_SUCCESS = createAction<RecalculateResponse>('Timesheet modal / recalculate request success');
export const BOX_TIMESHEET_SHIFT_MODAL_RECALCULATE_FAILURE = createAction<FormattedErrors>('Timesheet modal / recalculate request failure');

export const BOX_TIMESHEET_SHIFT_MODAL_SET_HIGHER_DUTY = createAction<HigherDutiesItem | null>('Timesheet modal / set higher_duty field');
export const BOX_TIMESHEET_SHIFT_MODAL_RESTORE_AWARDS = createAction('Timesheet modal / restore awards');

export const BOX_TIMESHEET_SHIFT_MODAL_GET_HIGHER_DUTIES = createAction<HigherDutiesPayload>('Timesheet modal / get duties');
export const BOX_TIMESHEET_SHIFT_MODAL_GET_HIGHER_DUTIES_SUCCESS = createAction<HigherDutiesResponse>('Timesheet modal / get duties success');
export const BOX_TIMESHEET_SHIFT_MODAL_GET_HIGHER_DUTIES_FAILURE = createAction<FormattedErrors>('Timesheet modal / get duties failure');

export const BOX_TIMESHEET_SHIFT_MODAL_AFFECT_SAVE_AWARD_BUTTON = createAction<string>('TImesheet modal / set save award button state');

export const BOX_TIMESHEET_SHIFT_MODAL_GET_ALLOWANCES = createAction<GetAllowancesPayload>('Timesheet modal / get list of allowances');
export const BOX_TIMESHEET_SHIFT_MODAL_GET_ALLOWANCES_SUCCESS = createAction<AllowancesResponse>('Timesheet modal / get list of allowances success');
export const BOX_TIMESHEET_SHIFT_MODAL_GET_ALLOWANCES_FAILURE = createAction<FormattedErrors>('Timesheet modal / get list of allowances failure');

export const BOX_TIMESHEET_SHIFT_MODAL_ADD_ALLOWANCE = createAction<AllowancePayload>('Timesheet modal / set allowance manually');
export const BOX_TIMESHEET_SHIFT_MODAL_ADD_ALLOWANCE_SUCCESS = createAction<AddAllowanceResponse>('Timesheet modal / set allowance manually success');
export const BOX_TIMESHEET_SHIFT_MODAL_ADD_ALLOWANCE_FAILURE = createAction<FormattedErrors>('Timesheet modal / set allowance manually failure');

export const BOX_TIMESHEET_SHIFT_MODAL_DELETE_ALLOWANCE = createAction<DeleteAllowancePayload>('Timesheet modal / delete allowance manually');
export const BOX_TIMESHEET_SHIFT_MODAL_DELETE_ALLOWANCE_SUCCESS = createAction<DeleteAllowanceResponse>('Timesheet modal / delete allowance manually success');
export const BOX_TIMESHEET_SHIFT_MODAL_DELETE_ALLOWANCE_FAILURE = createAction<FormattedErrors>('Timesheet modal / delete allowance manually failure');

export const BOX_TIMESHEET_SHIFT_MODAL_DELETE_SHIFT_CONTEXT = createAction('Timesheet modal / delete request context');
