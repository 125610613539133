import { EventsFilters } from './types';
import { GetEventsRequest } from 'lib/Api/type';
import { EventPeriodGrouped } from '../../../type/models';

export const filtersToPayload = (
  filters: EventsFilters,
  period: string,
  { page, pageSize }: { page: number; pageSize: number }
): GetEventsRequest => {
  const {
    sort: { column, direction },
    statuses,
  } = filters;
  let { start, end } = filters;

  let periodObj: EventPeriodGrouped = {
    period: null,
    from: null,
    to: null,
  };

  if (period !== 'all') {
    periodObj = {
      period: period,
      from: null,
      to: null,
    };
  }

  if (start || end) {
    periodObj = {
      period: 'custom',
      from: start,
      to: end,
    };
  }
  return {
    page,
    per_page: pageSize,
    statuses,
    order_by: column,
    order_dir: direction,
    period: periodObj,
  };
};
