import React from 'react';
import { KeyboardArrowDownIcon, PopperBox } from 'element';
import { Chip } from 'oxygen-elements';
import { makeStyles, createStyles } from '@mui/styles';
import { bindHoverPopover, bindHoverTrigger, useHoverPopupState } from 'hooks';
import clsx from 'clsx';

const useChipStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: '#fff',
      backgroundColor: '#225990',
      textTransform: 'uppercase',
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      maxWidth: '123px',
      padding: '2px',
      fontSize: '12px',
      height: '24px',
      cursor: 'pointer',
      '& svg.MuiChip-icon': {
        color: 'inherit',
      },
      '& svg.MuiChip-deleteIcon': {
        color: 'inherit',
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        '& svg.MuiChip-deleteIcon': {
          color: 'inherit',
        },
      },
    },
    content: {
      maxWidth: '240px',
      width: '100%',
      padding: '24px',
    },
    popper: {
      paddingTop: '5px',
    },
  })
);

type Props = {
  content: React.ReactNode;
  label: string;
  icon: React.ReactElement;
  type: 'info' | 'warning' | 'success' | 'grey' | 'danger' | 'light';
};

const colors = {
  warning: {
    bg: '#ffc53d',
    text: '#222',
  },
  info: {
    bg: '#19579f',
    text: '#fff',
  },
  success: {
    bg: '#006631',
    text: '#fff',
  },
  grey: {
    bg: 'grey',
    text: '#fff',
  },
  danger: {
    bg: '#b1101e',
    text: '#fff',
  },
  light: {
    bg: '#e6f1ff',
    text: '#222',
  },
};

export const BadgeWithDropDown = ({ content, label, icon, type }: Props) => {
  const styles = useChipStyles();
  const hoverPopupState = useHoverPopupState<HTMLDivElement>();

  return (
    <>
      <Chip
        data-testid={`badge-with-dropdown-${type}`}
        label={label}
        icon={icon}
        deleteIcon={<KeyboardArrowDownIcon />}
        onDelete={hoverPopupState.open}
        style={{
          backgroundColor: `${colors[type].bg}`,
          color: `${colors[type].text}`,
        }}
        className={clsx(styles.root, `badge-with-dropdown-type-${type}`)}
        {...bindHoverTrigger(hoverPopupState)}
      />
      <PopperBox
        className={styles.popper}
        disablePortal={true}
        {...bindHoverPopover(hoverPopupState)}
        data-testid="badge-with-popover"
        placement="bottom-start"
      >
        <div className={styles.content}>{content}</div>
      </PopperBox>
    </>
  );
};
