import { QueryDayProvider, QuerySiteIdProvider } from 'element';
import React from 'react';

export type QueryProviderProps = {
  children: React.ReactNode;
};

export default QueryProvider;

export function QueryProvider({ children }: QueryProviderProps) {
  return (
    <QuerySiteIdProvider>
      <QueryDayProvider>{children}</QueryDayProvider>
    </QuerySiteIdProvider>
  );
}
