import React from 'react';
import { CheckButton, FormItem, Row } from 'elmo-elements';
import { RepeatShiftItem } from '../../types';

type Props = {
  selectedDays: RepeatShiftItem[];
  onDayClick: (day: number) => void;
  tabIndex?: number;
};

export const RepeatShift = ({
  selectedDays,
  onDayClick,
  tabIndex = -1,
}: Props) => {
  const days = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
  return (
    <FormItem label={'Repeat shift'}>
      <Row isNoGutters={true} wrap={'nowrap'}>
        {selectedDays.map((day, i) => {
          return (
            <CheckButton
              key={i}
              isChecked={day.isChecked}
              id={`day-${i + 1}`}
              onStatusChange={onDayClick as any} // TODO double-check
              className={'repeat-shift-btn'}
              isDisabled={day.isDisabled}
              tabIndex={tabIndex}
              data-testid={`repeat-shift-btn-${i + 1}`}
            >
              {days[i]}
            </CheckButton>
          );
        })}
      </Row>
    </FormItem>
  );
};
