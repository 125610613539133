import _, { findKey, get, map } from 'lodash';
import {
  AccountTreeArea,
  AccountTreeRole,
  AccountTreeSite,
  Collection,
  FormattedErrors,
  LanguagePreferences,
  LanguagePreferencesField,
  PermissionName,
  PreferencesCurrencyCode,
  PreferencesCurrencyPlacement,
  PreferencesDateFormat,
  PreferencesNumberFormat,
  PreferencesTimeFormat,
  ShiftBreak,
  Sort,
  StringMap,
  TimesheetBreak,
  TimesheetPayload,
  UserFields,
} from 'type';
import moment, { Duration, Moment } from 'moment-timezone';
import {
  CURRENCY_SIGNS,
  NUMBER_FORMATS,
  SERVER_DATE_TIME_FORMAT,
  SERVER_DAY_FORMAT,
  SERVER_TIME_FORMAT,
} from '../config';
import { convertDecimalToFormattedTime } from './duration-helpers';

const timeFormatWithS = 'HH:mm:ss';
const yearFormat = 'YYYY-MM-DD';

export * from './Duration';
export * from './typedCond';
export * from './createCurrentUser';
export * from './createTimesheet';
export * from './closestParentById';
export * from './isElementOfType';
export * from './debounce';
export * from './uniqueId';
export * from './getClass';
export * from './roster';
export * from './getLinkedRoleLabel';
export * from './duration-helpers';
export * from './timesheet-predicates';
export * from './formatTimeTo12Hours';
export * from './formatTimeToMinutes';
export * from './formatMinutesToTime';
export * from './getMinutesFromDayStart';

export const isIE = () => {
  return (
    !!(window as any).MSInputMethodContext && !!(document as any).documentMode
  );
};

export const replaceErrorText = (e: string[]): FormattedErrors => {
  const errs: string[] = [];
  map(e, (error: string) => {
    errs.push(error.replace(/Error: /i, ''));
  });
  return errs;
};

type KeyMirrorObject<T> = { [K in keyof T]: K };
export const keyMirror = <T extends object>(obj: T): KeyMirrorObject<T> => {
  const res = {} as KeyMirrorObject<T>;
  let key;

  function isKey(k: any | keyof T): k is keyof T {
    return obj.hasOwnProperty(k);
  }

  for (key in obj) {
    if (isKey(key)) {
      res[key] = key;
    }
  }

  return res;
};

export const getFormattedNumber = (
  numberFormat: PreferencesNumberFormat,
  n: number,
  options: {
    maximumFractionDigits: number;
    minimumFractionDigits: number;
  } = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }
) => {
  const locale = NUMBER_FORMATS[numberFormat];
  const num = isNaN(n) ? 0 : n;
  return new Intl.NumberFormat(locale, options).format(num);
};

export const capitalize = <P extends string>(label: P): Capitalize<P> =>
  (label.charAt(0).toUpperCase() + label.slice(1)) as Capitalize<P>;

export const capitalizeEach = (string: string) =>
  string.split(' ').map(capitalize).join(' ');

export const getPreferenceLabel = (
  langPreferences: LanguagePreferences,
  type: keyof LanguagePreferences | string,
  count: keyof LanguagePreferencesField,
  defaultVal: string = '',
  capitalizeFirstLetter: boolean = false
) => {
  const label: string = get(
    langPreferences,
    [type.toLowerCase(), count],
    defaultVal
  );
  return capitalizeFirstLetter ? capitalize(label) : label;
};

export const getDateTimeFormatted = (
  dateFormat: PreferencesDateFormat,
  timeFormat: PreferencesTimeFormat,
  date: Date | string | Moment,
  showTime: boolean = false,
  withDateFormat: boolean = false
) => {
  const time = showTime
    ? ', ' + (+timeFormat === 12 ? 'hh:mm a' : 'HH:mm')
    : '';
  let format = `${dateFormat}${time}`;
  return withDateFormat
    ? moment.parseZone(date).format(dateFormat)
    : moment.parseZone(date).format(format);
};

export const getTimeFormattedShortened = (time: string) =>
  time.replace(/ pm/i, 'p').replace(/ am/i, 'a');

export const getTimeFormattedShortenedWithoutZeros = (time: string) =>
  time.replace(/:00/i, '').replace(/ pm/i, 'pm').replace(/ am/i, 'am');

export const getTimeFormatted = (
  timeFormat: PreferencesTimeFormat,
  date: Date | string | Moment,
  shortenedVersion: boolean = false
) => {
  const timeF = +timeFormat === 12 ? 'hh:mm a' : 'HH:mm';
  const formattedTime: string = moment.parseZone(date).format(timeF);

  return shortenedVersion
    ? getTimeFormattedShortened(formattedTime)
    : formattedTime;
};

export const getCurrencyFormatted = (
  numberFormat: PreferencesNumberFormat,
  code: PreferencesCurrencyCode,
  placement: PreferencesCurrencyPlacement,
  value: number,
  withoutSpaceBetween: boolean = false
) => {
  const val = getFormattedNumber(numberFormat, value);
  const divider = withoutSpaceBetween ? '' : ' ';

  if (placement === 'before') {
    return `${CURRENCY_SIGNS[code]}${divider}${val}`;
  }
  return `${val}${divider}${CURRENCY_SIGNS[code]}`;
};

export const hasPermission = (
  currentUserPermissions: PermissionName[],
  permission: PermissionName | undefined
): boolean => {
  if (permission) {
    return currentUserPermissions.includes(permission);
  }

  return true;
};

export const onSortCreator =
  <SortFields extends string, SortType extends Sort<SortFields>>(
    changeOrder: (payload: SortType) => void,
    columns: { [key in SortFields]: number | undefined }
  ) =>
  (sortedColumn: number, direction: string) => {
    const column = findKey(
      columns,
      (columnNumber) => columnNumber === sortedColumn
    );

    changeOrder({
      column,
      direction,
    } as SortType);
  };

export const convertHex = (hex: string, opacity: number) => {
  const H = hex.replace('#', '');
  const r = parseInt(H.substring(0, 2), 16);
  const g = parseInt(H.substring(2, 4), 16);
  const b = parseInt(H.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity / 100})`;
};

export const secondsToHours = (
  seconds: number,
  hideEmptyHours = false,
  showSeconds = false
) => {
  const momentTime = moment('2019-01-01').startOf('day').seconds(seconds);

  let format = 'mm\\m';

  if (showSeconds) {
    format += ' ss\\s';
  }

  let hours: string | number = Math.abs(Math.floor(seconds / 3600));
  hours = hours < 10 ? '0' + hours : hours;

  let result = momentTime.format(format);

  if (hideEmptyHours) {
    if (seconds > 3600) {
      result = hours + 'h ' + result;
    }
  } else {
    result = hours + 'h ' + result;
  }

  return result;
};

export const getDatesDiff = (start: Moment, end: Moment, formatted = true) => {
  const time = (start.toDate().getTime() - end.toDate().getTime()) / 1000;
  if (time > 0) {
    return formatted ? secondsToHours(time) : time;
  } else {
    return formatted ? '00h 00m' : time;
  }
};

export const getDateTimePlaceholder = ({
  start,
  end,
  dateFormat,
  timeFormat,
  hideEndDate,
}: {
  start: Moment;
  end: Moment | null;
  dateFormat: string;
  timeFormat: string;
  hideEndDate?: boolean;
}) => {
  const date = start.format(dateFormat);
  const startTime = start.format(timeFormat);

  if (end) {
    const endTime = end.format(timeFormat);

    const startDay = moment.parseZone(start).startOf('day');
    const endDay = moment.parseZone(end).startOf('day');

    if (endDay.isAfter(startDay) && !hideEndDate) {
      const endDate = end.format(dateFormat);
      return `${date}, ${startTime} - ${endTime}, ${endDate}`;
    }
    return `${date}, ${startTime} - ${endTime}`;
  }
  return `${date}, ${startTime}`;
};

const getTimeDiff = (s: Moment, e: Moment, timezoneId: string) => {
  const startTZ = moment(s).tz(timezoneId, true);
  const endTZ = moment(e).tz(timezoneId, true);
  const ss = moment.tz(timezoneId).set({
    year: +startTZ.format('YYYY'),
    month: +startTZ.format('M') - 1,
    date: +startTZ.format('D'),
    hours: +startTZ.format('HH'),
    minutes: +startTZ.format('mm'),
  });
  const ee = moment.tz(timezoneId).set({
    year: +endTZ.format('YYYY'),
    month: +endTZ.format('M') - 1,
    date: +endTZ.format('D'),
    hours: +endTZ.format('HH'),
    minutes: +endTZ.format('mm'),
  });
  return ee.diff(ss, 'minutes');
};

export const getShiftDuration = (
  start: Moment,
  end: Moment | null,
  breaks: ShiftBreak[],
  timezoneId: string = 'Europe/Kiev'
) => {
  const getTotalHours = (s: Moment, e: Moment | null) => {
    if (e === null || !e) {
      return 0;
    }
    if (
      moment(s).format('Y-MM-DD HH:mm') === moment(e).format('Y-MM-DD HH:mm')
    ) {
      return getTimeDiff(s, moment(e).add(1, 'day'), timezoneId);
    }
    return getTimeDiff(s, e, timezoneId);
  };

  const details = {
    meal_break: 0,
    rest_break: 0,
    total_hrs: getTotalHours(
      moment(start).utc(true),
      end !== null ? moment(end).utc(true) : null
    ),
  };

  breaks.forEach((b: ShiftBreak) => {
    if (b.paid) {
      details.rest_break += isNaN(b.duration) ? 0 : b.duration;
    } else {
      details.meal_break += isNaN(b.duration) ? 0 : b.duration;
    }
  });

  details.rest_break = details.rest_break / 60;
  details.meal_break = details.meal_break / 60;
  details.total_hrs = details.total_hrs / 60;
  details.total_hrs -= details.meal_break;

  return details;
};

const minTwoDigits = (num: number): string => {
  const addOn: string = num < 10 ? '0' : '';
  return addOn + num;
};

export const formatMinutesAsHours = (
  minutes: number,
  showPositiveSign: boolean = false
): string => {
  const duration: Duration = moment.duration(minutes, 'minutes');
  const durationInMs = duration.asMilliseconds();
  const sign: string = durationInMs < 0 ? '-' : showPositiveSign ? '+' : '';

  duration.abs();

  const hoursQty: number = Math.floor(duration.asHours());
  const lastHourMinutes: number = duration.minutes();

  return sign + minTwoDigits(hoursQty) + ':' + minTwoDigits(lastHourMinutes);
};

export const formatMinutesAsHoursMinutes = (
  durationInMinutes: number
): string => {
  const duration = moment.duration(durationInMinutes, 'minutes');

  const formattedDuration: string[] = [];

  const hours = Math.floor(duration.asHours());
  if (hours) {
    formattedDuration.push(`${hours}h`);
  }

  const minutes = duration.minutes();
  if (minutes) {
    formattedDuration.push(`${minutes}m`);
  }

  if (!formattedDuration.length) {
    formattedDuration.push('0m');
  }

  return formattedDuration.join(' ');
};

type AccountTreeItem = { name: string };
type Type = 'site' | 'area' | 'role';
export const getRoleName = (
  role: {
    site_id: string;
    area_id: string;
    role_id: string;
    site_name: string;
    area_name: string;
    role_name: string;
  },
  collection: StringMap<AccountTreeItem>,
  type: Type
): string => {
  const roleData: Collection<Type, { id: string; name: string }> = {
    site: {
      id: role.site_id,
      name: role.site_name,
    },
    area: {
      id: role.area_id,
      name: role.area_name,
    },
    role: {
      id: role.role_id,
      name: role.role_name,
    },
  };

  const { id, name } = roleData[type];

  const accountTreeItem: AccountTreeItem | undefined = collection[id];

  return accountTreeItem ? accountTreeItem.name : name;
};

export const getFullUserRole = (params: {
  role: {
    site_id: string;
    area_id: string;
    role_id: string;
    is_main?: boolean;
    site_name: string;
    area_name: string;
    role_name: string;
  };
  showPrimaryLabel?: boolean;
  sites: StringMap<AccountTreeSite>;
  areas: StringMap<AccountTreeArea>;
  roles: StringMap<AccountTreeRole>;
}): string => {
  const roleName = getRoleName(params.role, params.roles, 'role');
  const areaName = getRoleName(params.role, params.areas, 'area');
  const siteName = getRoleName(params.role, params.sites, 'site');
  const isMainText =
    params.showPrimaryLabel && params.role.is_main ? ' (Primary)' : '';

  return `${roleName} - ${areaName}, ${siteName}${isMainText}`;
};
export const makeRoleOptionLabel = (area_name: string, role_name: string) =>
  `${area_name} - ${role_name}`;
export const ROLE_OPTIONS_DIVIDER = '__';
export const makeRoleOptionValue = ({
  area_id,
  role_id,
}: {
  area_id: string;
  role_id: string;
}) => [area_id, role_id].join(ROLE_OPTIONS_DIVIDER);

export const parseRoleOptionValue = (value: string) => {
  const [area_id, role_id] = value.split(ROLE_OPTIONS_DIVIDER);
  return { area_id, role_id };
};
export const makeRoleOptionValueSafe = (params: {
  area_id: string;
  role_id: string;
}) => {
  if (Object.values(params).every((id) => !!id)) {
    return makeRoleOptionValue(params);
  }

  return '';
};
export const getOptionsForCurrentUserWithoutPlaceholder = (
  areasBySite: any,
  areas: any,
  roles: any,
  user: any
) => {
  const { user_roles } = user;
  const dropDownOptions: { label: string; value: string }[] = [];
  user_roles.forEach((role: any) => {
    areasBySite.map((area: any) => {
      if (
        area.role_ids &&
        area.role_ids.indexOf(role.role_id) !== -1 &&
        area.id === role.area_id &&
        !area.archived &&
        !roles[role.role_id].archived
      ) {
        dropDownOptions.push({
          label: `${areas[role.area_id].name} - ${roles[role.role_id].name}`,
          value: makeRoleOptionValue(role),
        });
      }
    });
  });
  return dropDownOptions;
};

export const getOptionsForCurrentUser = (...args: [any, any, any, any]) => {
  return [
    {
      label: 'Please select',
      value: ROLE_OPTIONS_DIVIDER,
    },
    ...getOptionsForCurrentUserWithoutPlaceholder(...args),
  ];
};

export const getArchivedOptions = (areas: any, roles: any) => {
  const dropDownOptions: any[] = [
    {
      label: 'Please select',
      value: '__',
    },
  ];
  Object.values(areas).forEach((area: any) => {
    area.role_ids.forEach((roleId: string) => {
      if (area.role_ids && area.archived) {
        dropDownOptions.push({
          label: `${area.name} - ${roles[roleId].name}`,
          value: `${area.id}__${roleId}`,
        });
      }
    });
  });

  return dropDownOptions;
};

export const getTimesheetOptionsForCurrentUser = (
  areas: StringMap<AccountTreeArea>,
  roles: StringMap<AccountTreeRole>,
  timesheetAreaId: string,
  timesheetRoleId: string,
  availableOptions: { label: string; value: string }[]
) => {
  let added = false;
  if (areas[timesheetAreaId] && roles[timesheetRoleId]) {
    for (let option of availableOptions) {
      if (
        option.value.indexOf(`${timesheetAreaId}__${timesheetRoleId}`) !== -1
      ) {
        added = true;
        break;
      }
    }
    if (!added) {
      return [
        {
          label: `${areas[timesheetAreaId].name} - ${roles[timesheetRoleId].name}`,
          value: `${timesheetAreaId}__${timesheetRoleId}`,
        },
      ];
    }
  }
  return [];
};
export const getOptionsForUnassignedRoster = (areasBySite: any, roles: any) => {
  const dropDownOptions: any[] = [
    {
      label: 'Please select',
      value: '__',
    },
  ];
  areasBySite.map((area: any) => {
    area.role_ids.map((roleId: string) => {
      if (
        area.role_ids.indexOf(roleId) !== -1 &&
        !area.archived &&
        !roles[roleId].archived
      ) {
        dropDownOptions.push({
          label: `${area.name} - ${roles[roleId].name}`,
          value: `${area.id}__${roleId}`,
        });
      }
    });
  });
  return dropDownOptions;
};
export const getSelectedValue = (
  params: { value: string; label: string }[],
  current: string | null
) => {
  for (let param of params) {
    if (param.value === current) {
      return param;
    }
  }
  return [params[0]];
};
export const getSelectedAreaRole = (
  params: { value: string; label: string }[],
  shift: { role_id: string | null; area_id: string | null }
) => {
  const { role_id, area_id } = shift;
  const current = `${area_id}__${role_id}`;
  for (let param of params) {
    if (param.value === current) {
      return param;
    }
  }
  return [params[0]];
};
export const getSelectedSite = (
  params: { value: string; label: string }[],
  shift: TimesheetPayload,
  siteId: string
) => {
  const selectedSiteId =
    shift.site_id && shift.site_id !== '' ? shift.site_id : siteId;
  for (let param of params) {
    if (param.value === selectedSiteId) {
      return param;
    }
  }
  return [params[0]];
};
export const getEventsOptions = (eventsList: any) => {
  const options: { value: string; label: string }[] = [
    {
      label: 'Please select',
      value: '',
    },
  ];

  Object.keys(eventsList).forEach((eventId: string) => {
    if (eventsList[eventId].type === 'event') {
      options.push({
        label: eventsList[eventId].title,
        value: eventsList[eventId].id,
      });
    }
  });
  return options;
};
const isNextDay = (startTime: string, endTime: string) => {
  const f =
    `${yearFormat} ` + (startTime.indexOf('m') !== -1 ? 'hh:mm a' : 'HH:mm');
  return {
    isNext:
      moment(`2003-01-01 ${startTime}`, f) >=
      moment(`2003-01-01 ${endTime}`, f),
    format: f,
  };
};
export const getEndDate = (
  timeFormat: PreferencesTimeFormat,
  startDate: Moment,
  endDate: string
) => {
  const dateStart = startDate.format(yearFormat);
  const dateEnd = moment(startDate).add(1, 'day').format(yearFormat);
  const startTime = getTimeFormatted(timeFormat, startDate.toDate());
  const endTime = getTimeFormatted(
    timeFormat,
    moment(endDate, `${yearFormat} ${timeFormatWithS}`).toDate()
  );
  const { isNext, format } = isNextDay(startTime, endTime);
  return isNext
    ? moment(`${dateEnd} ${endTime}`, format)
    : moment(`${dateStart} ${endTime}`, format);
};
export const getUserName = (
  user: Pick<UserFields, 'prefered_name' | 'last_name' | 'first_name'>
) => {
  const { prefered_name, last_name, first_name } = user;
  return prefered_name !== ''
    ? `${prefered_name} ${last_name}`
    : `${first_name} ${last_name}`;
};
export const getAreaPositionLabel = (langPreferences: LanguagePreferences) => {
  const area = getPreferenceLabel(
    langPreferences,
    'area',
    'singular',
    '',
    true
  );
  const position = getPreferenceLabel(langPreferences, 'role', 'singular', '');
  return `${area} - ${position}`;
};
export const getBreaksInfo = (
  timeFormat: PreferencesTimeFormat,
  breaks: ShiftBreak[],
  emptyResult: string = ''
) => {
  if (breaks.length > 0) {
    let placeholder = '';
    breaks.forEach((b: ShiftBreak) => {
      if (b.duration > 0) {
        const start = getTimeFormatted(timeFormat, b.start);
        const d = b.duration / 60;
        const duration = convertDecimalToFormattedTime(d.toFixed(2));
        const part = duration.length
          ? `${start} (${duration}${b.paid ? ' $' : ''}), `
          : '';
        placeholder += part;
      }
    });
    return placeholder.slice(0, -2);
  }
  return emptyResult;
};
// is used to scroll to the ErrorBox
export const scrollToErrors = (errors: FormattedErrors) => {
  const modal = document.querySelector('.shift-modal');
  setTimeout(() => {
    if (modal !== null && errors && errors.length) {
      const inner = modal.querySelector('.global-errors-alert');
      if (inner !== null) {
        inner.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'end',
        });
      }
    }
  }, 500);
};
// is used for TimePicker
export const getTimeRangeStrings = (
  start: Moment,
  end: Moment | null,
  current: Moment | Date,
  name: string
) => {
  const time = moment(current).format(timeFormatWithS);
  const startTime =
    name === 'start' ? time : moment(start).format(timeFormatWithS);
  const endTime =
    name === 'end'
      ? time
      : moment(end !== null ? end : undefined).format(timeFormatWithS);
  const startDate = moment(start).format(yearFormat);
  const endDate = moment(end !== null ? end : undefined).format(yearFormat);
  return {
    start: `${startDate} ${startTime}`,
    end: `${endDate} ${endTime}`,
  };
};
export const getPeriodPlaceholder = (
  dateFormat: PreferencesDateFormat,
  timeFormat: PreferencesTimeFormat,
  start: Moment,
  end: Moment
) => {
  const dateStart = getDateTimeFormatted(
    dateFormat,
    timeFormat,
    start,
    false,
    true
  );
  const dateEnd = getDateTimeFormatted(
    dateFormat,
    timeFormat,
    end,
    false,
    true
  );
  const startTime = getTimeFormatted(timeFormat, start);
  const endTime = getTimeFormatted(timeFormat, end);

  return `${dateStart}, ${startTime} - ${dateEnd}, ${endTime}`;
};
const updateBreakWithStartDate = (
  b: ShiftBreak | TimesheetBreak,
  startDate: Moment
) => {
  const time = moment(b.start).format(SERVER_TIME_FORMAT);
  const breakStartDate = moment(startDate).format(SERVER_DAY_FORMAT);
  const breakDateMoment = moment(`${breakStartDate} ${time}`);
  if (
    breakDateMoment.format(SERVER_DATE_TIME_FORMAT) <
    moment(startDate).format(SERVER_DATE_TIME_FORMAT)
  ) {
    breakDateMoment.add(1, 'day');
  }
  b.start = breakDateMoment;
  return b;
};
export const updateTimesheetBreaksOnDateChange = (
  startDate: Moment,
  breaks: TimesheetBreak[]
) => {
  if (breaks.length) {
    breaks.forEach((b: TimesheetBreak) => {
      updateBreakWithStartDate(b, startDate);
    });
  }
  return breaks;
};
export const updateRosterShiftBreaksOnDateChange = (
  startDate: Moment,
  breaks: ShiftBreak[]
) => {
  const breaksClone = _.cloneDeep(breaks);
  if (breaksClone.length) {
    breaksClone.forEach((b: ShiftBreak) => {
      updateBreakWithStartDate(b, startDate);
    });
  }
  return breaksClone;
};

export function isMobileDevice() {
  try {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  } catch (e) {
    return false;
  }
}

export function setLocale() {
  moment.updateLocale('en', {
    week: {
      dow: 1,
      doy: 1,
    },
  });
}

export function updateDatesOfBreaksForMidnightShifts<T extends ShiftBreak>(
  breaks: T[],
  shiftStart: Moment
) {
  return breaks.map((b) => {
    const formattedBreakStart = b.start.format(SERVER_DATE_TIME_FORMAT);
    const formattedStart = moment(shiftStart).format(SERVER_DATE_TIME_FORMAT);
    if (formattedBreakStart < formattedStart) {
      b.start.add(1, 'day');
    }
    return b;
  });
}

export const getLeaveSubtypeLabel = (
  subtype: null | undefined | 'absence' | 'sickness',
  withOn: boolean
) => {
  if (subtype === 'sickness') {
    return 'Sickness';
  }
  return withOn ? 'On Leave' : 'Leave';
};
