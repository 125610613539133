import { createAction } from 'lib/store-utils';
import { ManagerDashboardFilters } from './types';
import { DashboardShift, FormattedErrors } from '../../../type/models';
import { DeleteResponse } from '../WhosWorking/types';

export const BOX_MANAGER_DASHBOARD_FILTERS_MODAL_OPEN = createAction('Manager dashboard / Open filters modal');
export const BOX_MANAGER_DASHBOARD_FILTERS_MODAL_CLOSE = createAction('Manager dashboard / Close filters modal');
export const BOX_MANAGER_DASHBOARD_UPDATE_SEARCH_QUERY = createAction<string>('Manager dashboard / update search query');

export const BOX_MANAGER_DASHBOARD_UPDATE_FILTERS_REQUEST = createAction<ManagerDashboardFilters>('Manager dashboard / update filters request');
export const BOX_MANAGER_DASHBOARD_UPDATE_FILTERS_SUCCESS = createAction('Manager dashboard / update filters success');
export const BOX_MANAGER_DASHBOARD_UPDATE_FILTERS_FAILURE = createAction<FormattedErrors>('Manager dashboard / update filters failure');

export const BOX_MANAGER_DASHBOARD_CLEAR_ERRORS = createAction('Manager dashboard / clear errors');

export const BOX_MANAGER_DASHBOARD_SHIFT_CHANGED = createAction<DashboardShift>('Manager dashboard / shift changed');
export const BOX_MANAGER_DASHBOARD_SHIFT_DELETED = createAction<DeleteResponse>('Manager dashboard / shift deleted');

export const BOX_MANAGER_DASHBOARD_TIMESHEET_CHANGED = createAction<DashboardShift>('Manager dashboard / timesheet changed');
export const BOX_MANAGER_DASHBOARD_TIMESHEET_DELETED = createAction<DeleteResponse>('Manager dashboard / timesheet deleted');
