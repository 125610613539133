import { Box } from 'extended-oxygen-elements';
import React from 'react';
import AppBar from './AppBar';
import AppBarHamburger from './AppBarHamburger';
import AppBarShape from './AppBarShape';
import ContextStyling from './ContextStyling';
import { LayoutContextProvider } from './layout-context';
import LayoutContent from './LayoutContent';
import LayoutHeader from './LayoutHeader';
import LayoutStyling from './LayoutStyling';
import LegacyContextProvider from './LegacyContextProvider';
import Sidebar from './Sidebar';

type LayoutProps = {
  header: React.ReactNode;
  menu: React.ReactNode;
  children: React.ReactNode;
};

function Layout({ header, menu, children }: LayoutProps) {
  return (
    <LegacyContextProvider>
      <LayoutContextProvider>
        <ContextStyling />

        {/* Legacy className elmo-elements helpers do not work without this wrapper class */}
        <Box display="flex" className="elmo-elements">
          <AppBar>
            <AppBarHamburger />

            {header}
          </AppBar>

          <Sidebar component="nav" flexShrink={0}>
            {menu}
          </Sidebar>

          <Box flexGrow={1} minWidth={0} component="main">
            <AppBarShape />

            {children}
          </Box>
        </Box>
      </LayoutContextProvider>
    </LegacyContextProvider>
  );
}

Layout.Styling = LayoutStyling;
Layout.Header = LayoutHeader;
Layout.Content = LayoutContent;

export default Layout;
