import { createReducer } from 'lib/store-utils';
import * as actions from './actions';
import { PrintRostersReducerState } from './types';
import { getDefaultState } from './state';

export const printRosters = createReducer<PrintRostersReducerState>(
  {},
  getDefaultState()
);

printRosters.on(actions.BOX_ROSTERS_PRINT, (state, isPrinting) => ({
  ...state,
  isPrinting,
}));

printRosters.on(actions.BOX_ROSTERS_PRINT_OPEN, (state, isModalOpened) => ({
  ...state,
  isModalOpened,
  options: isModalOpened ? getDefaultState().options : state.options,
}));

printRosters.on(actions.BOX_ROSTERS_SET_PRINT_OPTIONS, (state, options) => ({
  ...state,
  options,
}));
