import { minutesDurationGuard } from 'lib/guards';
import Duration, { DurationFormatOptions, DurationFormatUnit } from '../Duration';
import _ from 'lodash';

export type FormatDurationOptions = Partial<DurationFormatOptions> & {
  formatUnit?: DurationFormatUnit;
};

export default formatDuration;

export function formatDuration(
  minutesDuration: number,
  { formatUnit = 'hours', ...restOptions }: FormatDurationOptions = {}
) {
  const integerMinutesDuration = _.toInteger(minutesDuration);
  const validMinutesDuration = minutesDurationGuard(integerMinutesDuration)
    ? integerMinutesDuration
    : 0;

  return new Duration({ minutes: validMinutesDuration })
    .setFormatOptions(restOptions)
    .formatTo(formatUnit);
}
