import { createStyles, makeStyles } from '@mui/styles';

export const useModalStyles = makeStyles((theme) =>
  createStyles({
    weekTitle: {
      fontWeight: 500,
      paddingLeft: 5,
    },
    areaTitle: {
      paddingLeft: 5,
    },
    list: {
      padding: 0,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: '#ccc',
      margin: '0 -10px',
    },
    listItem: {
      borderRadius: 0,
      padding: '7px 10px 7px 0',
      margin: 0,
    },
    listItemActive: {
      background: theme.palette.primary.light,
    },
    subList: {
      padding: 0,
      borderRadius: 0,
    },
    subListItem: {
      padding: '7px 0',
      borderRadius: 0,
      margin: 0,
    },
    areasNumber: {
      color: theme.palette.primary.main,
      fontSize: 14,
      fontWeight: 400,
    },
    weekText: {
      padding: 0,
      margin: 0,
      '&:hover': {
        background: theme.palette.background.default,
      },
    },
  })
);
