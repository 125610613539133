import React, { Component } from 'react';
import { ListTable, LoadingOverlay, Paragraph, Text } from 'elmo-elements';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  BOX_SHIFT_TRADES_ACCEPT_SHIFT_REQUEST,
  BOX_SHIFT_TRADES_CHANGE_PAGE,
  BOX_SHIFT_TRADES_CHANGE_PAGE_SIZE,
  BOX_SHIFT_TRADES_CHANGE_SORT,
  BOX_SHIFT_TRADES_DELETE_SHIFT,
  BOX_SHIFT_TRADES_SET_CURRENT_SHIFT_ID,
} from 'state/EmployeeDashboard/ShiftTrades';
import { ShiftTrade } from 'type';
import { StoreState } from 'state/types';
import {
  getSelectedFilter,
  getShiftTradesCustomFilter,
  shiftTradeIsDeleting,
  shiftTradesWithPagerSelector,
} from 'state/EmployeeDashboard/ShiftTrades/selectors';
import { getDateTimeFormatted, getPreferenceLabel } from 'lib/helpers';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { privateRoutes } from 'routes';
import { DispatchProps, StateProps } from './types';
import {
  getPlaceholderText,
  getShiftTradeDuration,
  getTimezone,
} from '../../../../../../helpers';
import { hasPermissionSelector } from 'state/Auth';
import {
  getDateFormat,
  getLangPreferences,
  getTimeFormat,
} from 'state/Account';
import { DSTIcon } from 'element/DSTIcon';
import { IsAppMarket, SimpleBadge, NotesIcon } from 'element';
import { EmptyMessage } from '../../../EmptyMessage';

type Props = DispatchProps & StateProps & RouteComponentProps;

export class OfferedShifts extends Component<Props> {
  render() {
    const { shiftTrades, selectedFilter } = this.props;

    return (
      <div className={'offered-shifts-wrapper'}>
        {shiftTrades.total > 0 ? (
          this.showShifts()
        ) : (
          <EmptyMessage message={getPlaceholderText(selectedFilter)} />
        )}
      </div>
    );
  }

  showShifts = () => {
    const { shiftTrades, isDeleting } = this.props;
    return (
      <LoadingOverlay isLoading={isDeleting}>
        <ListTable
          data={shiftTrades.page}
          actions={this.getActions}
          sortColumn={this.getSortColumn()}
          sortDirection={this.props.filters.sort.direction}
          onSort={this.changeOrder}
          onRowClick={this.navigateToSwap}
          className={'offered-list-table'}
        >
          <ListTable.Column
            label={'Date'}
            render={this.renderDate}
            sortable={true}
          />
          <ListTable.Column label={'Status'} render={this.renderStatus} />
          <ListTable.Column
            label={this.getPreferenceLabels()}
            render={this.getPositionAreaLocation}
            sortable={true}
          />
          <ListTable.Column label={'Responses'} render={this.renderResponse} />
          <ListTable.Pagination
            totalResults={shiftTrades.total}
            currentPage={shiftTrades.currentPage}
            pageSize={shiftTrades.pageSize}
            pageSizeOptions={[10, 20, 30]}
            onPageChange={this.props.changePage}
            onPageSizeChange={this.props.changePageSize}
          />
        </ListTable>
      </LoadingOverlay>
    );
  };

  getSortColumn = () => {
    return this.props.filters.sort.column === 'start' ? 0 : 2;
  };

  changeOrder = (column: number, direction: string) => {
    this.props.applyOrder({
      column: column === 0 ? 'start' : 'role',
      direction: direction,
    });
  };

  renderDate = (item: ShiftTrade) => {
    const { dateFormat, timeFormat } = this.props;
    return (
      <>
        <Paragraph isTruncate={true}>
          {getDateTimeFormatted(
            dateFormat,
            timeFormat,
            item.rostered_shift.start
          )}
          , {getShiftTradeDuration(timeFormat, item.rostered_shift, false)}
        </Paragraph>
        <Paragraph size={'xs'} color={'gray'}>
          Shift {_.capitalize(item.type)}{' '}
          <DSTIcon isDST={item.rostered_shift.is_dst_intersect} />
        </Paragraph>
        <IsAppMarket market={'uk'} negate={true}>
          <>
            <br />
            <Text color="gray" size="sm">
              Timezone
            </Text>
            <Paragraph isTruncate={true}>
              {getTimezone(item.rostered_shift)}
            </Paragraph>
          </>
        </IsAppMarket>
      </>
    );
  };

  renderStatus = (item: ShiftTrade) => {
    return <Text>{this.getStatus(item)}</Text>;
  };

  getStatus = (item: ShiftTrade) => {
    if (typeof item.proposals_count !== 'undefined') {
      if (item.type !== 'swap') {
        if (item.proposals_count === 0) {
          return (
            <SimpleBadge
              type={'info'}
              className={'shift-status'}
              label={'AWAITING RESPONSES'}
            />
          );
        }
        if (item.proposals_count > 0 && item.status === 'new') {
          return (
            <SimpleBadge
              type={'warning'}
              className={'shift-status'}
              label={'AWAITING APPROVAL'}
            />
          );
        }
      } else {
        if (item.status === 'selected') {
          return (
            <SimpleBadge
              type={'warning'}
              className={'shift-status'}
              label={'AWAITING APPROVAL'}
            />
          );
        }
        if (item.proposals_count === 0) {
          return (
            <SimpleBadge
              type={'info'}
              className={'shift-status'}
              label={'AWAITING RESPONSES'}
            />
          );
        }
        if (item.proposals_count > 0 && item.status === 'new') {
          return (
            <SimpleBadge
              type={'warning'}
              className={'shift-status'}
              label={'RESPONSES RECEIVED'}
            />
          );
        }
        if (item.status === 'approved') {
          return (
            <SimpleBadge
              type={'success'}
              className={'shift-status'}
              label={'APPROVED'}
            />
          );
        }
      }
    }
    return <span />;
  };

  renderResponse = (item: ShiftTrade) => {
    const count = item.proposals_count ? item.proposals_count : 0;
    const type = count === 0 ? 'light' : 'info';
    return (
      <>
        <SimpleBadge type={type} label={count.toString(10)} />
        <NotesIcon
          styles={{
            padding: '0',
            float: 'right',
          }}
          notes={item.rostered_shift.notes}
        />
      </>
    );
  };

  getPositionAreaLocation = (shift: ShiftTrade) => {
    const { rostered_shift } = shift;
    return `${rostered_shift.role.name} - ${rostered_shift.area.name}, ${rostered_shift.area.site.name}`;
  };

  getPreferenceLabels = () => {
    const { langPreferences } = this.props;
    const role = getPreferenceLabel(
      langPreferences,
      'role',
      'singular',
      '',
      true
    );
    const area = getPreferenceLabel(langPreferences, 'area', 'singular');
    const site = getPreferenceLabel(langPreferences, 'site', 'singular');
    return `${role} - ${area}, ${site}`;
  };

  navigateToSwap = (data: ShiftTrade) => {
    if (data.type === 'swap') {
      this.props.setCurrentShiftId(data.id);
      this.props.history.push(
        privateRoutes.employeeDashboard.routes.trades.routes.shiftSwap.path(
          data.id
        )
      );
    }
  };

  getActions = (data: ShiftTrade) => {
    return [
      {
        label: 'Cancel',
        onClick: () => {
          this.props.deleteShiftTrade(data.id);
        },
      },
    ];
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  shiftTrades: shiftTradesWithPagerSelector(state),
  isDeleting: shiftTradeIsDeleting(state),
  filters: getShiftTradesCustomFilter(state),
  selectedFilter: getSelectedFilter(state),
  hasPermissions: hasPermissionSelector(
    state,
    'employeedashboard.viewshifttrades'
  ),
  langPreferences: getLangPreferences(state),
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
});

const mapToDispatchProps: DispatchProps = {
  changePage: BOX_SHIFT_TRADES_CHANGE_PAGE,
  changePageSize: BOX_SHIFT_TRADES_CHANGE_PAGE_SIZE,
  acceptTrade: BOX_SHIFT_TRADES_ACCEPT_SHIFT_REQUEST,
  deleteShiftTrade: BOX_SHIFT_TRADES_DELETE_SHIFT,
  setCurrentShiftId: BOX_SHIFT_TRADES_SET_CURRENT_SHIFT_ID,
  applyOrder: BOX_SHIFT_TRADES_CHANGE_SORT,
};

export default withRouter(
  connect(mapStateToProps, mapToDispatchProps)(OfferedShifts)
);
