import React, { Component, createRef } from 'react';
import FocusLock from 'react-focus-lock';
import { getClass } from '../../_lib/helper';
import ModalHeader from './ModalHeader';
import ModalFooter from './ModalFooter';
import ModalBody from './ModalBody';
import { ModalProps } from '../type';
import '../Modal.scss';

type ModalState = {
  hasShadow: boolean;
};
type ModalWrapperProps = ModalProps & {
  /** Layout Context to update the status of isModalOpened */
  toggleModalOpen?: any;
};

class ModalWrapper extends Component<ModalWrapperProps, ModalState> {
  static Header = ModalHeader;
  static Body = ModalBody;
  static Footer = ModalFooter;

  static defaultProps: Partial<ModalWrapperProps> = {
    type: 'medium',
    isOpened: false,
    hasCloseFooterButton: true,
  };

  state: ModalState = {
    hasShadow: false,
  };

  private setHasShadow = (hasShadow: boolean) => {
    if (this.state.hasShadow !== hasShadow) {
      this.setState({
        hasShadow,
      });
    }
  };

  private contentRef = createRef<HTMLDivElement>();

  /**
   * Update Layout Context (isModalOpen) by triggering toggleModalOpen
   * if modal is opened on loading
   */
  componentDidMount() {
    if (this.props.toggleModalOpen && this.props.isOpened) {
      this.props.toggleModalOpen();
    }
  }

  componentWillUnmount() {
    if (this.props.toggleModalOpen && this.props.isOpened) {
      this.props.toggleModalOpen();
    }
  }

  /**
   * Update Layout Context (isModalOpen) by triggering toggleModalOpen
   * if it was closed before and is updated to True on isOpened
   */
  UNSAFE_componentWillUpdate(nextProps: any) {
    if (
      this.props.isOpened === false &&
      nextProps.isOpened === true &&
      this.props.toggleModalOpen
    ) {
      this.props.toggleModalOpen();
    }
    if (
      this.props.isOpened === true &&
      nextProps.isOpened === false &&
      this.props.toggleModalOpen
    ) {
      this.props.toggleModalOpen();
    }
  }

  /**
   * Handle close modal event
   */
  handleCloseModal = () => {
    const { closeModal } = this.props;
    if (closeModal) {
      closeModal();
    }
  };

  /**
   * Handle press escape key and close modal
   */
  escFunction = (event: any) => {
    if (event.keyCode === 27) {
      this.handleCloseModal();
    }
  };

  /**
   * Render Modal
   */
  render() {
    const {
      id,
      icon,
      title,
      primaryButton,
      type,
      children,
      closeLabel,
      hasCloseFooterButton,
      className,
      isOpened,
      backButton,
      ariaLabel,
      isBackgroundGrey,
    } = this.props;

    return (
      isOpened && (
        <FocusLock
          disabled={!isOpened}
          returnFocus={true}
          noFocusGuards={true}
          className="d-inline"
          whiteList={(node) => {
            const root = document.getElementById('root');
            return root ? root.contains(node) : false;
          }}
        >
          <div
            className={getClass('elmo-modal', className, {
              open: !backButton ? isOpened : true,
              [`${type}`]: type,
            })}
            id={this.props.id}
            data-testid={`elmo-modal-${id || 'default'}`}
            tabIndex={-1}
          >
            {!backButton && <div className="elmo-modal__bg" tabIndex={-1} />}
            <div className="elmo-modal__content-wrapper">
              <div
                className="elmo-modal__content"
                ref={this.contentRef}
                role="dialog"
                tabIndex={0}
                aria-modal={true}
                aria-label={ariaLabel}
                onKeyDown={this.escFunction}
              >
                <ModalHeader
                  icon={icon}
                  title={title}
                  titleSize="sm"
                  closeModal={this.handleCloseModal}
                  backButton={backButton}
                  primaryButton={type !== 'dialog' ? primaryButton : ''}
                  hasShadow={this.state.hasShadow}
                />
                <ModalBody
                  isBackgroundGrey={isBackgroundGrey}
                  changeShadow={this.setHasShadow}
                >
                  {children}
                </ModalBody>
                <ModalFooter
                  hasCloseFooterButton={hasCloseFooterButton}
                  closeModal={this.handleCloseModal}
                  primaryButton={primaryButton}
                  closeLabel={closeLabel}
                  hasShadow={this.state.hasShadow}
                />
              </div>
            </div>

          </div>
        </FocusLock>
      )
    );
  }
}

export default ModalWrapper;
