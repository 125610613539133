import { CovidTracingResponse } from 'lib/Api/type';
import { createAction } from 'lib/store-utils';
import { FormattedErrors } from 'type';
import { CovidShiftData, CovidTracingPeriod, CovidReportRequest } from './types';

export const BOX_COVID_TRACING_MODE_ENABLE = createAction('Covid Tracing / set enabled');
export const BOX_COVID_TRACING_MODE_DISABLE = createAction('Covid Tracing / set disabled');
export const BOX_COVID_TRACING_MODE_TOGGLE_SHIFT = createAction<CovidShiftData>('Covid Tracing / toggle shift');
export const BOX_COVID_TRACING_MODE_UNSET_SHIFT = createAction('Covid Tracing / unset shift');
export const BOX_COVID_TRACING_MODE_SET_SHIFT_FAILURE = createAction<FormattedErrors>('Covid Tracing / set shift failure');
export const BOX_COVID_TRACING_MODE_SET_SHIFT_SUCCESS = createAction<CovidTracingResponse>('Covid Tracing / set shift success');
export const BOX_COVID_TRACING_MODE_SET_PERIOD = createAction<CovidTracingPeriod>('Covid Tracing / set tracing period');

export const BOX_COVID_TRACING_MODE_DOWNLOAD_REPORT = createAction<'csv' | 'pdf' | 'json'>('Covid Tracing / download report');
export const BOX_COVID_TRACING_MODE_DOWNLOAD_REPORT_SUCCESS = createAction<any[]>('Covid Tracing / download report');
export const BOX_COVID_TRACING_MODE_DOWNLOAD_REPORT_FAILURE = createAction<FormattedErrors>('Covid Tracing / download report');
export const BOX_COVID_TRACING_MODE_DOWNLOAD_REPORT_STATE = createAction<boolean>('Covid Tracing / download report state');
