import React from 'react';
import { connect } from 'react-redux';
import { FormattedErrors } from 'type/models';
import { ErrorBox } from 'element';
import { StoreState } from 'state/types';
import {
  BOX_SHIFT_OFFER_SET_ERRORS,
  getErrors
} from 'state/ManagerDashboard/ShiftOffers/ShiftOffer';

type OwnProps = {};

type StateProps = {
  errors: FormattedErrors;
};

type DispatchProps = {
  clearErrors: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const ShiftOfferErrorBoxComponent = (props: Props) => <ErrorBox {...props} />;

const mapStateToProps = (state: StoreState): StateProps => ({
  errors: getErrors(state)
});

const mapDispatchToProps: DispatchProps = {
  clearErrors: () => BOX_SHIFT_OFFER_SET_ERRORS([])
};

export const ShiftOfferErrorBox = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShiftOfferErrorBoxComponent);
