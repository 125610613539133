import classNames from 'clsx';

/**
 * Make a BEM string based on props
 * @param  {string} name Base class.
 * @param  {Object<string, any>} classes Component classes.
 * @param  {Object<string, any>} modifiers Component extra class modifiers passing as props.
 * @return {string} BEM class string.
 */
export function getClass(name: string, classes: any, modifiers?: any): string {
  const modifierClasses: any = {};
  for (const key in modifiers) {
    if (key === 'className') {
      modifierClasses[`${modifiers[key]}`] = true;
    } else if (modifiers[key]) {
      modifierClasses[`${name}--${key}`] = modifiers[key];
    }
  }

  return classNames(name, classes, modifierClasses);
}
