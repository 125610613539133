import React from 'react';
import Heading from '../../Typography/Heading';

function DataTableEmptyMessage() {
  return (
    <div className="elmo-datatable__empty">
      <Heading>No data found.</Heading>
    </div>
  );
}

export default DataTableEmptyMessage;
