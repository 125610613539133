import { createReducer } from 'lib/store-utils';
import * as actions from './actions';

import { getDefaultState, AuditTrailReducerState } from './state';

export const auditTrail = createReducer<AuditTrailReducerState>({}, getDefaultState());

auditTrail.on(actions.BOX_AUDIT_TRAIL_FILTERS_MODAL_OPEN, (state, payload) => ({
  ...state,
  message: payload as any,
  filtersModal: {
    ...state.filtersModal,
    isOpened: true,
  }
}));

auditTrail.on(actions.BOX_AUDIT_TRAIL_FILTERS_MODAL_CLOSE, state => ({
  ...state,
  filtersModal: {
    ...state.filtersModal,
    isOpened: false,
    initEditor: false,
  }
}));

auditTrail.on(actions.BOX_AUDIT_TRAIL_REQUEST, state => ({
  ...state,
  isFetching: true,
  errors: []
}));

auditTrail.on(actions.BOX_AUDIT_TRAIL_SUCCESS, (state, payload) => ({
  ...state,
  isFetching: false,
  isFetched: true,
  auditTrail: payload,
  errors: []
}));

auditTrail.on(actions.BOX_AUDIT_TRAIL_FAILURE, (state, errors) => ({
  ...state,
  isFetching: false,
  isFetched: true,
  errors
}));

auditTrail.on(actions.BOX_AUDIT_TRAIL_FILTERS_UPDATE, (state, filters) => ({
  ...state,
  filters,
  pager: {
    ...state.pager,
    currentPage: 1
  },
  filtersModal: {
    ...state.filtersModal,
    isOpened: false
  },
  isFetching: true,
}));

auditTrail.on(actions.BOX_AUDIT_TRAIL_PAGE_UPDATE, (state, pager) => ({
  ...state,
  pager,
  isFetching: true,
}));

auditTrail.on(actions.BOX_AUDIT_TRAIL_CLEAR_ERRORS, state => ({
  ...state,
  errors: []
}));
