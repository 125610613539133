import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StringMap, TimeOff } from 'type';
import { StoreState } from 'state/types';
import {
  BOX_UNAVAILABILITY_EDIT_MODAL_CLOSE,
  BOX_UNAVAILABILITY_EDIT_UNAVAILABILITY_REQUEST,
  EditTimeOffPayload,
  getEditModal,
  timeOffsCollectionSelector
} from 'state/ManagerDashboard/Unavailability';
import {
  UnavailabilityCreateEditModal,
  UnavailabilityCreateEditModalProps,
  UnavailabilityCreateEditModalSubmitPayload
} from '../UnavailabilityCreateEditModal';

type OwnProps = {};

type StateProps = Pick<
  UnavailabilityCreateEditModalProps,
  'isOpened' | 'isUpdating' | 'errors'
> & {
  timeOffId: string;
  timeOffs: StringMap<TimeOff>;
};

type DispatchProps = Pick<
  UnavailabilityCreateEditModalProps,
  'onCloseModal'
> & {
  onFormSubmit: (payload: EditTimeOffPayload) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

class UnavailabilityEditComponent extends Component<Props> {
  render() {
    const { onFormSubmit, timeOffId, timeOffs, ...restProps } = this.props;

    return (
      <UnavailabilityCreateEditModal
        {...restProps}
        onFormSubmit={this.onFormSubmit}
        title="Edit unavailability"
        submitLabel="Edit"
        timeOff={this.timeOff}
      />
    );
  }

  private get timeOff() {
    const { timeOffs, timeOffId } = this.props;

    return timeOffs[timeOffId];
  }

  private onFormSubmit = (
    payload: UnavailabilityCreateEditModalSubmitPayload
  ) => {
    const { onFormSubmit, timeOffId } = this.props;

    onFormSubmit({
      ...payload,
      timeOffId
    });
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  ...getEditModal(state),
  timeOffs: timeOffsCollectionSelector(state)
});

const mapDispatchToProps: DispatchProps = {
  onCloseModal: BOX_UNAVAILABILITY_EDIT_MODAL_CLOSE,
  onFormSubmit: BOX_UNAVAILABILITY_EDIT_UNAVAILABILITY_REQUEST
};

export const UnavailabilityEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnavailabilityEditComponent);
