import React from 'react';
import { connect } from 'react-redux';
import { FetchWrapper } from 'element';
import { EMPLOYEE_DASHBOARD_SHIFT_TRADES } from 'state/FetchPageData/constants';
import {
  EmployeeDashboardHeader,
  EmployeeDashboardLayout,
} from '../../../../components';
import View from './view';
import {
  BOX_SHIFT_TRADES_CLEAR_OUTDATED_STATE,
  BOX_SHIFT_TRADES_GET_DATA,
} from 'state/EmployeeDashboard/ShiftTrades';

type OwnProps = {};

type StateProps = {};

type DispatchProps = {
  fetchData: () => void;
  clearState: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const ShiftTradesList = (props: Props) => (
  <EmployeeDashboardLayout header={<EmployeeDashboardHeader />}>
    <FetchWrapper {...props} pageId={EMPLOYEE_DASHBOARD_SHIFT_TRADES}>
      <View />
    </FetchWrapper>
  </EmployeeDashboardLayout>
);

const mapDispatchToProps: DispatchProps = {
  fetchData: BOX_SHIFT_TRADES_GET_DATA,
  clearState: BOX_SHIFT_TRADES_CLEAR_OUTDATED_STATE
};

export default connect(
  null,
  mapDispatchToProps
)(ShiftTradesList);
