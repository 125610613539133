import React from 'react';
import { Moment } from 'moment';
import _ from 'lodash';
import { createStyles, makeStyles } from '@mui/styles';
import { ClearableTimeInput } from 'element';

const useTimeInputStyles = makeStyles((theme) =>
  createStyles({
    input: {
      width: '130px',
      '& input': {
        padding: '3px',
        borderRadius: theme.shape.borderRadius,
        fontSize: 14,
        textAlign: 'center',
        height: 34,
      },
    },
  })
);

export const TimeField = (actualProps: {
  value: Moment | null;
  onChange: (date: Moment | null) => void;
  name?: string;
  placeholder?: string;
  autoFocus?: boolean;
  submitOnEnter?: boolean;
  testid?: string;
}) => {

  const props = _.defaults({}, actualProps, {
    name: ''
  });

  const classes = useTimeInputStyles();
  const boxRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const { current } = boxRef;
    if (current && props.autoFocus) {
      const input = current.querySelector('input');
      input?.focus();
    }
  }, []);
  return (
    <div className={classes.input} ref={boxRef} data-testid={props.testid}>
      <ClearableTimeInput {...props} />
    </div>
  );
};
