import React from 'react';
import _ from 'lodash';
import { Checkbox, Collapse, List } from 'extended-oxygen-elements';
import { ListItemButton } from 'oxygen-elements';
import PublishWeekTitleItem from './PublishWeekTitleItem';
import PublishAreaItem from './PublishAreaItem';
import { useModalStyles } from './hooks/publishModalStyles';
import { useSelector } from 'react-redux';
import { getAreas } from 'state/AccountTree';
import { useSelectorWithProps } from 'hooks';
import { getIsModalOpen } from 'state/Roster/Roster';
import clsx from 'clsx';
import { ExpandLessOutlinedIcon, ExpandMoreOutlinedIcon } from 'element';
import { getChangesNumberText } from './helpers';

export default function UnpublishedList({
  unpublishedRosters,
  weeks,
  setWeekCheckedState,
  setWeekOpenedState,
  setWeekAreaCheckedState,
}: any) {
  const classes = useModalStyles();
  const areas = useSelector(getAreas);
  const isOpened = useSelectorWithProps(getIsModalOpen, 'publish');

  const checkBoxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    weekId: string
  ) => {
    e.stopPropagation();
    setWeekCheckedState(weekId);
  };

  if (!unpublishedRosters.total || !isOpened || !weeks) {
    return null;
  }

  return (
    <>
      {_.map(
        unpublishedRosters.weeks,
        (week, index) =>
          weeks[week.id] && (
            <List
              data-test-id={'publish-list'}
              className={classes.list}
              component="div"
              key={`week-list-${week.id}`}
            >
              <ListItemButton
                onClick={() => setWeekOpenedState(week.id)}
                className={clsx(
                  classes.listItem,
                  weeks[week.id].isOpened && classes.listItemActive
                )}
              >
                <Checkbox
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => checkBoxChange(e, week.id)}
                  checked={weeks[week.id].isChecked}
                  data-test-id={'publish-list-week-checkbox'}
                />
                <PublishWeekTitleItem
                  data={week}
                  selectedAreasNumber={weeks[week.id].selectedAreasNumber}
                  showSelectedNumber={
                    weeks[week.id].showSelectedNumber &&
                    weeks[week.id].isChecked
                  }
                  isOpened={weeks[week.id].isOpened}
                />
                {weeks[week.id].isOpened ? (
                  <ExpandLessOutlinedIcon />
                ) : (
                  <ExpandMoreOutlinedIcon />
                )}
              </ListItemButton>
              <Collapse
                data-test-id={'publish-list-collapse'}
                in={weeks[week.id].isOpened}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  className={classes.subList}
                  data-test-id={'publish-list-collapse-sublist'}
                >
                  {_.map(
                    week.areas,
                    (area, key) =>
                      areas[area.id] && (
                        <PublishAreaItem
                          key={key}
                          unpublishedChangesNumber={getChangesNumberText(
                            area.unpublished_shifts_count
                          )}
                          areaName={areas[area.id].name}
                          areaId={area.id}
                          weekId={week.id}
                          isChecked={weeks[week.id].areas[area.id]}
                          setSelectedState={setWeekAreaCheckedState}
                        />
                      )
                  )}
                </List>
              </Collapse>
            </List>
          )
      )}
    </>
  );
}
