import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import { getFiltersForm } from 'state/EmployeeDashboard/MyTimesheet/selectors';
import {
  BOX_MY_TIMESHEET_UPDATE_FILTER_FORM_FIELD,
  BOX_MY_TIMESHEET_UPDATE_FILTER_FORM_FIELD_PERIOD
} from 'state/EmployeeDashboard/MyTimesheet/actions';
import View, { Props as ViewProps } from './view';
import { getLangPreferences } from '../../../../../../../../state/Account';
import { SelectPropsOption } from 'elmo-elements/Select';
import { AccountTreeSite, StringMap } from '../../../../../../../../type';
import { asSelectOptionCombiner } from '../../../../../../../../state/combiners';
import { compact } from 'lodash';
import { currentUserSiteIdsSelector } from '../../../../../../../../state/Auth';
import { getSites } from '../../../../../../../../state/AccountTree';

type StateProps = Pick<ViewProps, 'formData' | 'langPreferences'> & {
  currentUserSiteIds: string[];
  sites: StringMap<AccountTreeSite>;
};

type DispatchProps = Pick<ViewProps, 'onChange' | 'onChangePeriod'>;

type Props = StateProps & DispatchProps;

class CustomFiltersComponent extends Component<Props> {
  render() {
    const { formData, langPreferences, onChange, onChangePeriod} = this.props;
    const props: ViewProps = {
      formData,
      langPreferences,
      onChange,
      onChangePeriod,
      currentUserSites: this.currentUserSites
    };

    return <View {...props} />;
  }

  private get currentUserSites(): SelectPropsOption[] {
    const { currentUserSiteIds, sites } = this.props;

    return compact(currentUserSiteIds.map(siteId => sites[siteId])).map(
      asSelectOptionCombiner
    );
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  formData: getFiltersForm(state),
  langPreferences: getLangPreferences(state),
  currentUserSiteIds: currentUserSiteIdsSelector(state),
  sites: getSites(state)
});

const mapDispatchToProps: DispatchProps = {
  onChange: BOX_MY_TIMESHEET_UPDATE_FILTER_FORM_FIELD,
  onChangePeriod: BOX_MY_TIMESHEET_UPDATE_FILTER_FORM_FIELD_PERIOD,
};

export const CustomFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomFiltersComponent);
