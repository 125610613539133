import { createReducer } from 'lib/store-utils';
import * as actions from './actions';

import { defaultState } from './state';
import { NotificationReducerState } from './type';

export const notification = createReducer<NotificationReducerState>({}, defaultState);

// Edit Modal
notification.on(actions.BOX_NOTIFICATION_EDIT_MODAL_OPEN, (state) => ({
  ...state,
  editModal: {
    ...state.editModal,
    isOpened: true,
    errors: []
  }
}));

notification.on(actions.BOX_NOTIFICATION_EDIT_MODAL_CLOSE, state => ({
  ...state,
  editModal: {
    ...state.editModal,
    isOpened: false,
    initEditor: false,
    errors: []
  }
}));

notification.on(actions.BOX_NOTIFICATION_INIT_EDITOR_REQUEST, state => ({
  ...state,
  editModal: {
    ...state.editModal,
    initEditor: true,
    errors: []
  }
}));

// Time Modal
notification.on(actions.BOX_NOTIFICATION_TIME_MODAL_OPEN, state => ({
  ...state,
  timeModal: {
    ...state.timeModal,
    isOpened: true,
    errors: []
  }
}));

notification.on(actions.BOX_NOTIFICATION_TIME_MODAL_CLOSE, state => ({
  ...state,
  timeModal: {
    ...state.timeModal,
    isOpened: false,
    errors: []
  }
}));

notification.on(actions.BOX_UPDATE_NOTIFICATION_HOURS_REQUEST, state => ({
  ...state,
  timeModal: {
    ...state.timeModal,
      isUpdating: true,
    errors: []
  }
}));

notification.on(actions.BOX_UPDATE_NOTIFICATION_HOURS_SUCCESS, state => ({
  ...state,
  timeModal: {
    ...state.timeModal,
      isUpdating: false,
      isOpened: false,
    errors: []
  }
}));

notification.on(actions.BOX_UPDATE_NOTIFICATION_HOURS_FAILURE, (state, payload) => ({
  ...state,
  timeModal: {
    ...state.timeModal,
    isOpened: true,
    isUpdating: false,
    errors: payload
  }
}));

notification.on(actions.BOX_NOTIFICATION_REQUEST, state => ({
  ...state,
  isFetching: true,
  errors: []
}));

notification.on(actions.BOX_NOTIFICATION_SUCCESS, (state, payload) => ({
  ...state,
  isFetching: false,
  isFetched: true,
  notification: payload,
  errors: []
}));

notification.on(actions.BOX_NOTIFICATION_FAILURE, (state, errors) => ({
  ...state,
  isFetching: false,
  isFetched: true,
  errors
}));

notification.on(actions.BOX_UPDATE_NOTIFICATION_REQUEST, state => ({
  ...state,
  editModal: {
    ...state.editModal,
    isOpened: true,
    isUpdating: true,
    errors: []
  },
  errors: []
}));

notification.on(actions.BOX_UPDATE_NOTIFICATION_SUCCESS, state => ({
  ...state,
  editModal: {
    ...state.editModal,
    isOpened: false,
    isUpdating: false,
  },
  errors: []
}));

notification.on(actions.BOX_UPDATE_NOTIFICATION_FAILURE, (state, errors) => ({
  ...state,
  editModal: {
      ...state.editModal,
      isOpened: true,
      isUpdating: false,
      errors
  },
  errors: []
}));

notification.on(actions.BOX_NOTIFICATION_UPLOAD_FAILURE, (state, errors) => ({
  ...state,
  editModal: {
      ...state.editModal,
      isOpened: true,
      isUpdating: false,
      errors
  },
  errors: []
}));

notification.on(actions.BOX_NOTIFICATION_DESTROY_REQUEST, state => ({
  ...state,
  isFetched: false,
  errors: []
}));

notification.on(actions.BOX_NOTIFICATION_UPLOAD_SUCCESS, (state, payload) => ({
  ...state,
  editModal: {
    ...state.editModal,
    uploadedFile: payload
  },
}));

notification.on(actions.BOX_NOTIFICATION_SELECT_MESSAGE, (state, message) => ({
  ...state,
 message: {
    ...message
 }
}));

notification.on(actions.BOX_NOTIFICATION_TIME_MODAL_CLEAR_ERRORS, (state) => ({
  ...state,
  timeModal: {
    ...state.timeModal,
    errors: []
  }
}));

notification.on(actions.BOX_NOTIFICATION_EDIT_MODAL_CLEAR_ERRORS, (state) => ({
  ...state,
  editModal: {
    ...state.editModal,
    errors: []
  }
}));
