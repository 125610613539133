import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compact, filter } from 'lodash';
import { HeaderTabLinkProps } from 'element/Header/type';
import { LanguagePreferences, PermissionName } from 'type';
import { privateRoutes } from 'routes';
import { getPreferenceLabel, hasPermission } from 'lib/helpers';
import { StoreState } from 'state/types';
import { getCurrentUserPermissions, hasPermissionSelector } from 'state/Auth';
import {
  getCanViewForecastsAndEvents,
  getCanViewShiftOffers,
  getCanViewShiftSwaps,
  getCanViewUnavailability,
  getLangPreferences,
} from 'state/Account';

type MenuItem = HeaderTabLinkProps & {
  permission?: PermissionName;
};

type OwnProps = {
  children: (menu: MenuItem[]) => ReactNode;
};

type StateProps = {
  currentUserPermissions: PermissionName[];
  canViewShiftOffers: boolean;
  canViewShiftSwaps: boolean;
  canViewUnavailability: boolean;
  canViewForecastsAndEvents: boolean;
  hasPermissionToViewTimesheet: boolean;
  langPreferences: LanguagePreferences;
  hasPermissionToViewEvents: boolean;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps;

class WithManagerDashboardMenuComponent extends Component<Props> {
  render() {
    return this.props.children(this.menu());
  }

  private menu(): MenuItem[] {
    const {
      currentUserPermissions,
      canViewShiftOffers,
      canViewShiftSwaps,
      canViewUnavailability,
      canViewForecastsAndEvents,
      hasPermissionToViewEvents,
      match: { path }
    } = this.props;
    const { routes } = privateRoutes.managerDashboard;
    const allItems: (MenuItem | false)[] = [
      {
        label: 'Overview',
        onClick: this.goTo(routes.overview.path),
        active: routes.overview.path === path,
        permission: (routes.overview as any).permission
      },
      {
        label: 'Who\'s working',
        onClick: this.goTo(routes.whosWorking.path),
        active: routes.whosWorking.path === path,
        permission: (routes.whosWorking as any).permission
      },
      canViewShiftOffers && {
        label: 'Shift offers',
        onClick: this.goTo(routes.shiftOffers.path),
        active: routes.shiftOffers.path === path,
        permission: (routes.shiftOffers as any).permission
      },
      canViewShiftSwaps && {
        label: 'Shift swaps',
        onClick: this.goTo(routes.shiftSwaps.path),
        active: routes.shiftSwaps.path === path,
        permission: (routes.shiftSwaps as any).permission
      },
      canViewForecastsAndEvents &&
        hasPermissionToViewEvents && {
          label: getPreferenceLabel(
            this.props.langPreferences,
            'event',
            'plural',
            '',
            true
          ),
          onClick: this.goTo(routes.events.path),
          active: routes.events.path === path,
          permission: (routes.events as any).permission
        },
      canViewUnavailability && {
        label: 'Availability',
        onClick: this.goTo(routes.unavailability.path),
        active: routes.unavailability.path === path,
        permission: (routes.unavailability as any).permission
      }
    ];

    return filter(compact(allItems), ({ permission }) =>
      hasPermission(currentUserPermissions, permission)
    );
  }

  private goTo = (path: string) => () => {
    this.props.history.push(path);
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  currentUserPermissions: getCurrentUserPermissions(state),
  canViewShiftOffers: getCanViewShiftOffers(state),
  canViewShiftSwaps: getCanViewShiftSwaps(state),
  canViewUnavailability: getCanViewUnavailability(state),
  canViewForecastsAndEvents: getCanViewForecastsAndEvents(state),
  hasPermissionToViewTimesheet: hasPermissionSelector(
    state,
    'roster.timesheet.view'
  ),
  hasPermissionToViewEvents: hasPermissionSelector(state, 'roster.events.view'),
  langPreferences: getLangPreferences(state)
});

export const WithManagerDashboardMenu = withRouter(
  connect(mapStateToProps)(WithManagerDashboardMenuComponent)
);
