import React from 'react';
import Button from '../../Button';
import clsx from 'clsx';

function ModalFooter({
  closeModal,
  primaryButton,
  closeLabel,
  hasCloseFooterButton,
  hasShadow,
}: {
  closeModal: any;
  primaryButton: any;
  closeLabel: any;
  hasCloseFooterButton: any;
  hasShadow: boolean;
}) {
  if (!(primaryButton || hasCloseFooterButton)) {
    return null;
  }

  return (
    <div
      className={clsx('elmo-modal__footer', {
        'elmo-modal__footer--has-shadow': hasShadow,
      })}
    >
      {hasCloseFooterButton && (
        <div className="elmo-modal__footer-close">
          <Button
            isText={true}
            isBlock={true}
            onClick={closeModal}
            className="elmo-modal__footer-close-btn"
          >
            {closeLabel ? closeLabel : 'Cancel'}
          </Button>
        </div>
      )}
      {primaryButton && (
        <div className="elmo-modal__footer-primary-btn">{primaryButton}</div>
      )}
    </div>
  );
}

export default ModalFooter;
