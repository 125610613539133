import { DialogSubTitle, Form } from 'element';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';
import { useActions, useSelectorWithProps } from 'hooks';
import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLangPreferences } from 'state/Account';
import {
  activeRolesSelector,
  BOX_ACCOUNT_TREE_ROLES_LINK,
  getAreaName,
  getAreaSiteId,
} from 'state/AccountTree';
import { getAssignmentAreaRoleIds } from 'state/Assignments';
import LinkSelect from './LinkSelect';
import {
  PageDialog,
  PageDialogCancel,
  PageDialogErrors,
  PageDialogSubmit,
  usePageDialogStatusEffect,
} from '../../../../../element/page-dialog-components';

type Props = {
  open: boolean;
  onClose: () => void;
  area_id: string;
};

export default function DialogAddRole({ area_id, open, onClose }: Props) {
  return (
    <PageDialog maxWidth="xs" open={open} onClose={onClose}>
      <AddRoleDialogContent area_id={area_id} onClose={onClose} />
    </PageDialog>
  );
}

type ContentProps = {
  area_id: string;
  onClose: () => void;
};

function AddRoleDialogContent({ area_id, onClose }: ContentProps) {
  const rolesLinkRequest = useActions(BOX_ACCOUNT_TREE_ROLES_LINK.request);

  const areaName = useSelectorWithProps(getAreaName, area_id);
  const assignableRoles = useAssignableRoles(area_id);
  const langPreferences = useSelector(getLangPreferences);

  const [selectedRoleIds, setSelectedRoleIds] = React.useState<string[]>([]);
  const [roleOptions] = React.useState(
    assignableRoles.map((role) => ({
      value: role.id,
      label: role.name,
    }))
  );

  usePageDialogStatusEffect({
    success: onClose,
  });

  const linkRoles = () => {
    rolesLinkRequest({
      area_id,
      role_ids: selectedRoleIds,
    });
  };

  return (
    <>
      <DialogTitle>
        Add {langPreferences.role.plural} to {areaName}
      </DialogTitle>
      <DialogContent>
        <DialogSubTitle>
          Select one or multiple {langPreferences.role.plural}. These will be
          added to the selected {langPreferences.area.singular}.
        </DialogSubTitle>
        <PageDialogErrors />
        <Form onSubmit={linkRoles}>
          <LinkSelect
            value={selectedRoleIds}
            onChange={setSelectedRoleIds}
            options={roleOptions}
            optionName={langPreferences.role}
          />
        </Form>
      </DialogContent>
      <DialogActions>
        <PageDialogCancel onClick={onClose}>Cancel</PageDialogCancel>
        <PageDialogSubmit
          disabled={!selectedRoleIds.length}
          onClick={linkRoles}
        >
          Add {langPreferences.role.singular}
        </PageDialogSubmit>
      </DialogActions>
    </>
  );
}

export function useAssignableRoles(area_id: string) {
  const site_id = useSelectorWithProps(getAreaSiteId, area_id);
  const assignmentRoleIds = useSelectorWithProps(getAssignmentAreaRoleIds, {
    site_id,
    area_id,
  });
  const activeRoles = useSelector(activeRolesSelector);
  return _.filter(
    activeRoles,
    (activeRole) => !assignmentRoleIds.includes(activeRole.id)
  );
}
