import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import { getIsLoading } from 'state/IsLoading';
import {
  BOX_ROSTER_DAY_VIEW_CONFIRM_FORCE_MODAL_CLOSE,
  BOX_ROSTER_DAY_VIEW_CONFIRM_FORCE_MODAL_SUBMIT,
  getConfirmModal,
} from 'state/Roster/RosterDayView';
import { replaceErrorText } from 'lib/helpers';
import { isAppMarket } from '../../../../../../helpers';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from 'extended-oxygen-elements';
import { PageDialog, PageDialogCancel, PageDialogSubmit } from 'element';

type OwnProps = {};

type StateProps = {
  isOpened: boolean;
  isUpdating: boolean;
  errors: string[];
};

type DispatchProps = {
  submitModal: () => void;
  closeModal: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const DayConfirmModalComponent = ({
  isOpened,
  isUpdating,
  errors,
  submitModal,
  closeModal,
}: Props) => (
  <PageDialog maxWidth={'xs'} open={isOpened} id="day-view-confirm-modal">
    <DialogTitle>
      {isAppMarket('uk') ? 'Update shift?' : 'Update Rostered shift?'}
    </DialogTitle>
    <DialogContent>
      {replaceErrorText(errors).map((error, errorIndex) => (
        <Typography variant={'body1'} key={errorIndex}>
          {error}
        </Typography>
      ))}
    </DialogContent>
    <DialogActions>
      <PageDialogCancel onClick={closeModal}>Cancel</PageDialogCancel>
      <PageDialogSubmit
        onClick={submitModal}
        loading={isUpdating}
        disabled={isUpdating}
      >
        Yes, update
      </PageDialogSubmit>
    </DialogActions>
  </PageDialog>
);

const mapStateToProps = (state: StoreState): StateProps => ({
  ...getConfirmModal(state),
  isUpdating: getIsLoading(state, 'ROSTER_CONFIRM_OVERLAP_MODAL'),
});

const mapDispatchToProps: DispatchProps = {
  closeModal: BOX_ROSTER_DAY_VIEW_CONFIRM_FORCE_MODAL_CLOSE,
  submitModal: BOX_ROSTER_DAY_VIEW_CONFIRM_FORCE_MODAL_SUBMIT,
};

export const DayConfirmModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(DayConfirmModalComponent);
