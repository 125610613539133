import makeUniqIdProvider from 'lib/makeUniqIdProvider';
import {
  AsyncActionCreator,
  createReducer,
  EmptyActionCreator,
  SimpleActionCreator,
} from 'lib/store-utils';
import { StoreState } from 'state/types';
import { SafeDictionary } from 'ts-essentials';
import { FormattedErrors } from 'type';

type ErrorsReducerState = {
  errors: SafeDictionary<FormattedErrors>;
};

export const errors = createReducer<ErrorsReducerState>({}, { errors: {} });

const getState = (state: StoreState): ErrorsReducerState => state.errors;
export const getErrors = (state: StoreState, id: string) =>
  getState(state).errors[id] || [];

const uniqIdProvider = makeUniqIdProvider();

export function ErrorsHandler(optionalId?: string) {
  const id = uniqIdProvider(optionalId);

  const onSaveErrors = (
    state: ErrorsReducerState,
    errors: FormattedErrors
  ): ErrorsReducerState => ({
    ...state,
    errors: {
      ...state.errors,
      [id]: errors,
    },
  });

  const onClearErrors = (state: ErrorsReducerState): ErrorsReducerState => {
    const { [id]: removedErrors, ...errors } = state.errors;

    return {
      ...state,
      errors,
    };
  };

  return {
    saveErrors(action: SimpleActionCreator<FormattedErrors>) {
      errors.on(action, onSaveErrors);

      return this;
    },

    clearErrors(action: EmptyActionCreator | SimpleActionCreator<any>) {
      errors.on(action, onClearErrors);
      return this;
    },

    handleAsyncErrors(
      action: AsyncActionCreator<any, any, FormattedErrors, any>
    ) {
      return this.clearErrors(action.request).saveErrors(action.failure);
    },

    makeSelector() {
      return (state: StoreState) => getErrors(state, id);
    },
  };
}
