import React from 'react';
import { isNumber } from 'lodash';
import { Col, Paragraph, Row } from 'elmo-elements';
import { MapWithFencing, Marker } from 'element';
import { getCurrentTimesheet } from 'state/TimesheetModal';
import { useSelector } from 'react-redux';
import { useFencingSettings } from './hooks/useFencingSettings';

type Coords = {
  lat: number;
  lng: number;
};

export const TimesheetInfoMap = () => {
  const { lat, lng, clock_off_lat, clock_off_lng } =
    useSelector(getCurrentTimesheet);

  if (!isNumber(lat) || !isNumber(lng)) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(
        'TimesheetInfoMap is used with a timesheet without coordinates'
      );
    }

    return null;
  }

  return (
    <Row className={'timesheet-info-map map'} role="presentation">
      <Col span={24} role="presentation">
        <Paragraph size={'sm'}>Clocked on location</Paragraph>

        <CoordsMap {...{ lat, lng, clock_off_lat, clock_off_lng }} />
      </Col>
    </Row>
  );
};

function CoordsMap({
  clock_off_lat,
  clock_off_lng,
  ...clockOnCoords
}: {
  lat: number;
  lng: number;
  clock_off_lat: number | null;
  clock_off_lng: number | null;
}) {
  if (!isNumber(clock_off_lng) || !isNumber(clock_off_lat)) {
    return <SingleCoordsMap coords={clockOnCoords} />;
  }

  const clockOffCoords = {
    lat: clock_off_lat,
    lng: clock_off_lng,
  };

  if (
    clockOnCoords.lat === clockOffCoords.lat &&
    clockOnCoords.lng === clockOffCoords.lng
  ) {
    return <SingleCoordsMap coords={clockOnCoords} />;
  }

  return <MultiCoordsMap coords={[clockOnCoords, clockOffCoords]} />;
}

function SingleCoordsMap({ coords }: { coords: Coords }) {
  const fencing = useFencingSettings();
  return (
    <MapWithFencing center={coords} fencing={fencing}>
      <Marker position={coords} />
    </MapWithFencing>
  );
}

function MultiCoordsMap({ coords }: { coords: [Coords, ...Coords[]] }) {
  const [firstCoords] = coords;
  const labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const fencing = useFencingSettings();
  return (
    <MapWithFencing center={firstCoords} fencing={fencing}>
      {coords.map((position, coordsIndex) => (
        <Marker
          key={coordsIndex}
          position={position}
          label={labels[coordsIndex % labels.length]}
        />
      ))}
    </MapWithFencing>
  );
}
