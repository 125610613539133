import { Moment } from 'moment';
import { last, reduce, sortBy } from 'lodash';

type GroupedItem = {
  start: Moment;
  end: Moment | null;
};

const groupByRowCreator = (currentTime: Moment) => {
  const groupByRow = <Item extends GroupedItem>(
    accumulator: Item[][],
    item: Item,
    rowNumber: number
  ): Item[][] => {
    const row: Item[] = accumulator[rowNumber] || [];
    const lastItemInRow: Item | undefined = last(row);

    if (!lastItemInRow) {
      accumulator[rowNumber] = [item];
    } else {
      const { end } = lastItemInRow;

      if (item.start.isBefore(end ? end : currentTime)) {
        return groupByRow(accumulator, item, rowNumber + 1);
      }

      accumulator[rowNumber] = [...row, item];
    }

    return accumulator;
  };

  return groupByRow;
};

export const groupedByRowsCombiner = <Item extends GroupedItem>(
  itemsToBeGrouped: Item[],
  currentTime: Moment
): Item[][] => {
  const itemsSortedByStart: Item[] = sortBy(
    itemsToBeGrouped,
    (itemToBeGrouped: Item): number => itemToBeGrouped.start.unix()
  );

  const groupByRow = groupByRowCreator(currentTime);

  return reduce(
    itemsSortedByStart,
    (accumulator: Item[][], item: Item) => groupByRow(accumulator, item, 0),
    []
  );
};
