import React from 'react';
import { connect } from 'react-redux';
import { AddOutlinedIcon, Header } from 'element';
import { BOX_UNAVAILABILITY_CREATE_MODAL_OPEN } from 'state/ManagerDashboard/Unavailability';
import { WithManagerDashboardMenu } from '../../../../components';
import { WithNotificationsListButton } from 'element/NotificationsList/components/WithNotificationsListButton';

type OwnProps = {
  isFetching: boolean;
};

type StateProps = {};

type DispatchProps = {
  onCreate: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const UnavailabilityHeaderComponent = ({ onCreate, isFetching }: Props) => (
  <WithManagerDashboardMenu>
    {menu => (
      <WithNotificationsListButton type={'manager'}>
        {({
            notificationButton
          }) => (
            <Header
              title="Manager Dashboard"
              tabs={menu}
              buttons={[notificationButton]}
              primaryButton={{
                id: "add-unavailability",
                icon: <AddOutlinedIcon />,
                label: 'Add Unavailability',
                isDisabled: isFetching,
                ariaLabel: 'Add',
                onClick: () => onCreate()
              }}
            />
        )}
      </WithNotificationsListButton>
    )}
  </WithManagerDashboardMenu>
);

// const mapStateToProps = (state: StoreState): StateProps => ({});

const mapDispatchToProps: DispatchProps = {
  onCreate: BOX_UNAVAILABILITY_CREATE_MODAL_OPEN
};

export const UnavailabilityHeader = connect(
  null,
  mapDispatchToProps
)(UnavailabilityHeaderComponent);
