import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import {
  BOX_UNAVAILABILITY_FORCE_APPROVE_REQUEST,
  BOX_UNAVAILABILITY_CONFIRM_APPROVAL_MODAL_CLOSE,
  getApproveModal
} from 'state/ManagerDashboard/Unavailability';
import {
  UnavailabilityConfirmationModal,
  ConfirmationModalProps
} from '../UnavailabilityConfirmationModal';

type OwnProps = {};

type StateProps = Pick<ConfirmationModalProps, 'isUpdating' | 'isOpened' | 'message'>;

type DispatchProps = Pick<ConfirmationModalProps, 'onClose' | 'onSubmit'>;

type Props = OwnProps & StateProps & DispatchProps;

const UnavailabilityConfirmApprovalComponent = ({ message, ...rest }: Props) => (
  <UnavailabilityConfirmationModal
    {...rest}
    message={message}
  />
);

const mapStateToProps = (state: StoreState): StateProps => ({
  ...getApproveModal(state)
});

const mapDispatchToProps: DispatchProps = {
  onClose: BOX_UNAVAILABILITY_CONFIRM_APPROVAL_MODAL_CLOSE,
  onSubmit: BOX_UNAVAILABILITY_FORCE_APPROVE_REQUEST
};

export const UnavailabilityConfirmApproval = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnavailabilityConfirmApprovalComponent);
