import React from 'react';
import _ from 'lodash';
import { Box } from 'extended-oxygen-elements';
import { Button } from 'extended-oxygen-elements';
import { useBulkDeleteContext } from 'contexts';

type Props = {
  onDeleteBtnClick: () => void;
};

export function DeleteActions({ onDeleteBtnClick }: Props) {
  const bulkContext = useBulkDeleteContext();
  const selectedQuantity = bulkContext.selectedIds.length;
  const isDeleteBtnDisabled = !selectedQuantity;

  const deleteBtnLabel = _.compact([
    'Delete timesheets',
    !!selectedQuantity && `(${selectedQuantity})`,
  ]).join(' ');

  return (
    <>
      <Button
        size="medium"
        disabled={isDeleteBtnDisabled}
        onClick={onDeleteBtnClick}
        data-testid="bulk-delete-confirm-btn"
      >
        {deleteBtnLabel}
      </Button>

      <Box ml="auto">
        <Button
          onClick={bulkContext.disable}
          data-testid={'bulk-delete-mode-cancel-btn'}
        >
          Cancel
        </Button>
      </Box>
    </>
  );
}
