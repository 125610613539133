import {
  DialogContent as MuiDialogContent,
  DialogContentProps,
} from 'oxygen-elements';
import React from 'react';

export type { DialogContentProps };

const DialogContentContext = React.createContext<
  React.RefObject<HTMLDivElement>
>({ current: null });

const DialogContent = React.forwardRef<HTMLDivElement, DialogContentProps>(
  function DialogContent(props, forwardedRef) {
    const ref = React.useRef<HTMLDivElement | null>(null);

    return (
      <DialogContentContext.Provider value={ref}>
        <MuiDialogContent {...props} ref={(node: HTMLDivElement) => {
          ref.current = node;

          if (forwardedRef) {
            if (typeof forwardedRef === 'function') {
              forwardedRef(node);
            } else {
              forwardedRef.current = node;
            }
          }
        }} />
      </DialogContentContext.Provider>
    );
  }
);

export const useDialogContentRef = () => React.useContext(DialogContentContext);

export default DialogContent;
