import * as React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'extended-oxygen-elements';
import { StoreState } from 'state/types';
import {
  getIsPastingInProgress,
  BOX_ROSTER_PAST_ALERT_STATUS_HIDE,
} from 'state/Roster/RosterCopyPast';

type StateProps = {
  isPastingInProgress: boolean;
};
type DispatchProps = {
  hideAlert: () => void;
};
type Props = StateProps & DispatchProps;

export const RosterCopyAlert = ({ isPastingInProgress, hideAlert }: Props) => (
  <>
    {isPastingInProgress && (
      <Alert
        id="copy-paste-alert"
        className={'mb-5'}
        color="info"
        severity="info"
        onClose={hideAlert}
      >
        Pasting is in progress
      </Alert>
    )}
  </>
);

const mapStateToProps = (state: StoreState): StateProps => ({
  isPastingInProgress: getIsPastingInProgress(state),
});

const mapDispatchToProps: DispatchProps = {
  hideAlert: BOX_ROSTER_PAST_ALERT_STATUS_HIDE,
};

export default connect(mapStateToProps, mapDispatchToProps)(RosterCopyAlert);
