import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { bulkDeleteIdsArraySelector } from 'state/Roster/BulkActions/selectors';

type Props = {
  id: string;
};
export const useIsBulkSelected = ({ id }: Props) => {
  const selectedIds = useSelector(bulkDeleteIdsArraySelector);
  return useMemo(
    () => selectedIds.includes(id),
    [selectedIds, id]
  );
};
