import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelectorWithProps } from 'hooks';
import { getPayElement } from 'state/PayElementsCollection';
import { BOX_PAY_ELEMENTS_PAY_ELEMENT_UPDATE } from 'state/PayElementsCollection';
import PayElementForm, {
  PayElementFormPayload,
} from '../components/PayElementForm';

type EditPayElementProps = {
  id: string;
};

function EditPayElement({ id }: EditPayElementProps) {
  const dispatch = useDispatch();
  const payElement = useSelectorWithProps(getPayElement, id);

  const handleSubmit = (payload: PayElementFormPayload) => {
    dispatch(
      BOX_PAY_ELEMENTS_PAY_ELEMENT_UPDATE.request({
        ...payload,
        id,
      })
    );
  };

  return <PayElementForm onSubmit={handleSubmit} initialState={payElement} />;
}

export default EditPayElement;
