import React, { Children, Component, createRef } from 'react';
import { DataTableColumn, DataTableContextProps, DataTableProps } from './type';
import {
  getClass,
  isElementOfType,
  isScreenDesktop,
  noop,
} from '../_lib/helper';
import DataTableContext from './DataTableContext';
import DataTableHeader from './component/DataTableHeader';
import DataTableBody from './component/DataTableBody';
import DataTableTd from './component/DataTableTd';
import DataTableTr from './component/DataTableTr';
import DataTableWrapper from './component/DataTableWrapper';
import DataTableEmptyMessage from './component/DataTableEmptyMessage';
import DataTableCustomiseModal from './component/DataTableCustomiseModal';
import DataTableEmptyColumnsMessage from './component/DataTableEmptyColumnsMessage';
import DataTablePagination from './component/DataTablePagination';
import './DataTable.scss';

class DataTable extends Component<DataTableProps, DataTableContextProps> {
  // Compound Components
  static Body = DataTableBody;
  static Header = DataTableHeader;
  static Td = DataTableTd;
  static Tr = DataTableTr;
  static Pagination = DataTablePagination;

  protected subcomponents: any;
  protected layoutHeader: any;
  protected headerObserver: any;

  private wrapperRef = createRef<HTMLDivElement>();
  /**
   * Exposes setState to DataTableContext consumers.
   * @param state
   */
  setContextState = (state: any) => {
    this.setState(state);
  };

  handleResize = () => {
    if (
      this.wrapperRef &&
      this.wrapperRef.current &&
      this.layoutHeader &&
      isScreenDesktop() === false
    ) {
      const headerHeight = this.layoutHeader.offsetHeight;
      const headColumns: any = this.wrapperRef.current.querySelectorAll(
        '.elmo-datatable__head th, .elmo-datatable__head td'
      );
      headColumns.forEach((column: any) => {
        column.style.top = headerHeight + 'px';
      });
    }
  };

  /**
   * Called when a checkbox is toggled to update the status of the Select All checkbox and the "Select all
   * available items" component in the header.
   * @param isSelect
   */
  updateSelectAllCheckboxState = (isSelect?: boolean) => {
    if (!this.subcomponents.Body) {
      return;
    }

    let countVisibleSelected = 0;
    if (isSelect !== undefined) {
      countVisibleSelected = isSelect ? 1 : -1;
    }

    let countVisibleSelectable = 0;

    const bodyProps: any = this.subcomponents.Body.props;
    const rows = Children.toArray(bodyProps.children);
    rows.forEach((row: any) => {
      if (!React.isValidElement(row) || !isElementOfType(row, DataTableTr)) {
        return;
      }

      const rowProps: any = row.props;
      countVisibleSelected += rowProps.isSelected ? 1 : 0;
      countVisibleSelectable +=
        !rowProps.isDisabled || rowProps.isSelected ? 1 : 0;
    });

    const isIndeterminate =
      !(countVisibleSelected === countVisibleSelectable) &&
      countVisibleSelected !== 0;
    const isChecked = countVisibleSelected !== 0;

    this.setContextState({
      isSelectAllChecked: isChecked,
      isSelectAllIndeterminate: isIndeterminate,
      showSelectAllComponent: isChecked && !isIndeterminate,
    });
  };

  /**
   * Clears the select all checkbox
   */
  clearSelectAllCheckbox = () => {
    this.setContextState({
      showSelectAllComponent: false,
      isSelectAllChecked: false,
      isSelectAllIndeterminate: false,
    });
  };

  /**
   * The initial state of the DataTable context
   */
  state: DataTableContextProps = {
    showSelectAllComponent: false,
    isSelectAllChecked: false,
    isSelectAllIndeterminate: false,
    hasBulkActions: false,
    countSelectedItems: 0,
    hasRowActions: false,
    countItemsAvailable: 0,
    onSelectAllAvailableToggle: noop,
    isAllAvailableItemsSelected: false,
    countSelectable: 0,
    setContextState: this.setContextState,
    isCustomiseModalOpen: false,
    headerColumns: [],
    showCustomiseModal: false,
    rowCount: 0,
    isResponsive: false,
    updateSelectAllCheckboxState: this.updateSelectAllCheckboxState,
  };

  /**
   * Initialises the DataTable Context with the initial props.
   */
  componentDidMount() {
    const { isHeaderSticky } = this.props;
    // No previous props so pass in empty object.
    this.updateContext({});
    if (isHeaderSticky) {
      this.layoutHeader = document.querySelector('.elmo-layout__main-header');

      if (this.layoutHeader) {
        this.headerObserver = new ResizeObserver((entries) => {
          this.handleResize();
        });
        this.headerObserver.observe(this.layoutHeader);
      }
    }
  }

  componentWillUnmount() {
    const { isHeaderSticky } = this.props;
    if (isHeaderSticky && this.headerObserver) {
      this.headerObserver.disconnect();
    }
  }

  componentDidUpdate(prevProps: DataTableProps) {
    const { countSelectedItems, toggleBulkActionDisabled, isBulkActionOpen } =
      this.props;

    /**
     * Update the data in the context with the props that come through
     */
    this.updateContext(prevProps);

    /**
     * Update the bulk actions disable state when the number of items selected has changed.
     * When the first item has been selected, then bulk actions should be enabled.
     * When the last item has been deselected, disable bulk actions.
     */
    if (
      toggleBulkActionDisabled &&
      this.shouldToggleBulkActionsDisabled(
        prevProps.countSelectedItems,
        countSelectedItems
      )
    ) {
      toggleBulkActionDisabled();
    }

    /**
     * If the bulk actions has been closed, hide the select all component in the header, and uncheck the Select All
     * checkbox.
     */
    if (!isBulkActionOpen && isBulkActionOpen !== prevProps.isBulkActionOpen) {
      this.clearSelectAllCheckbox();
    }
  }

  shouldToggleBulkActionsDisabled(
    prevCountSelectedItems?: number,
    currCountSelectedItems?: number
  ) {
    const prevSelected: number = prevCountSelectedItems
      ? prevCountSelectedItems
      : 0;
    const currSelected: number = currCountSelectedItems
      ? currCountSelectedItems
      : 0;

    // no change in number of selected items - return false
    if (prevSelected === currSelected) {
      return false;
    }

    const firstSelected = currSelected > 0 && prevSelected === 0;
    const lastDeselected = currSelected === 0 && prevSelected > 0;

    // if either first item selected, or last item selected, toggle the bulk actions disable state.
    return firstSelected || lastDeselected;
  }

  /**
   * Update the data in the context from the props that come through.
   */
  updateContext = (prevProps: any) => {
    const props: any = this.props;
    const propsToUpdate: (keyof DataTableContextProps)[] = [
      'hasBulkActions',
      'countItemsAvailable',
      'onSelectAllAvailableToggle',
      'isAllAvailableItemsSelected',
      'countSelectable',
      'countSelectedItems',
      'isResponsive',
    ];

    const newState: Partial<DataTableContextProps> = {};

    // Check if the props have changed, if they have, add it to the new state to be updated
    propsToUpdate.forEach((key) => {
      if (prevProps[key] !== props[key]) {
        newState[key] = props[key];
      }
    });

    if (Object.keys(newState).length !== 0) {
      this.setContextState(newState);
    }
  };

  showEmptyColumnsMessage = () => {
    const { headerColumns } = this.state;
    const countVisibleColumns = headerColumns.filter((c: DataTableColumn) => {
      return !c.isHidden;
    }).length;

    return countVisibleColumns === 0;
  };

  /**
   * Called when the page is changed or page size is changed.
   */
  onPageUpdate = () => {
    this.clearSelectAllCheckbox();
  };

  getSubComponents() {
    const { children } = this.props;

    let subComponents: any = {};

    React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) {
        return;
      }

      if (isElementOfType(child, DataTablePagination)) {
        subComponents.Pagination = child;
      } else if (isElementOfType(child, DataTableHeader)) {
        subComponents.Header = child;
      } else if (isElementOfType(child, DataTableBody)) {
        subComponents.Body = child;
      }
    });

    return subComponents;
  }

  render() {
    const {
      id,
      className,
      isFullWidth,
      isResponsive,
      isHeaderSticky,
      isFirstColumnSticky,
    } = this.props;
    this.subcomponents = this.getSubComponents();

    return (
      <DataTableContext.Provider value={this.state}>
        <div
          id={id}
          className={getClass('elmo-datatable', className, {
            scroll: isResponsive,
            'is-header-sticky': isHeaderSticky,
            'is-first-column-sticky': isFirstColumnSticky,
          })}
          data-testid={`elmo-datatable-${id || 'default'}`}
          ref={this.wrapperRef}
        >
          <DataTableWrapper isFullWidth={isFullWidth}>
            {this.subcomponents.Header}
            {this.subcomponents.Body}
          </DataTableWrapper>
          {this.showEmptyColumnsMessage() && <DataTableEmptyColumnsMessage />}
          {this.state.rowCount === 0 && <DataTableEmptyMessage />}
          {this.subcomponents.Pagination &&
            React.cloneElement(this.subcomponents.Pagination, {
              onPageUpdate: this.onPageUpdate,
            })}
          <DataTableCustomiseModal />
        </div>
      </DataTableContext.Provider>
    );
  }
}

export default DataTable;
