import { StoreState } from '../../types';
import { ShiftSwap, StringMap } from '../../../type';
import { createSelector } from 'reselect';
import { withoutCombiner } from '../../combiners';

const getState = (state: StoreState) => state.managerDashboard.shiftSwaps;
const getSwaps = (state: StoreState) => getState(state).swaps;
const getUndoQueue = (state: StoreState) => getState(state).undoQueue;

export const swapsWithoutUndoSelector = createSelector(
  getSwaps,
  getUndoQueue,
  withoutCombiner
);

export const getSwapById = (state: StoreState, id: string) => getState(state).swaps[id];
