import { WS } from '../SocketConnection';

export type ChannelHandler<Payload> = (payload: Payload) => void;

export default abstract class BaseChannel {
  private readonly channel: ReturnType<WS['private']>;

  protected constructor(
    private readonly ws: WS,
    private readonly channelId: string
  ) {
    this.channel = ws.private(this.channelId);
  }

  on(eventName: string, handler: ChannelHandler<any>) {
    this.channel.listen(eventName, handler);

    return this;
  }

  leave() {
    this.ws.leave(this.channelId);
  }
}
