import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  TextField,
  TextFieldProps,
  Typography,
} from 'extended-oxygen-elements';
import marketLabels from 'marketLabels';
import FormControlSpacing from 'element/FormControlSpacing';
import { useSelector } from 'react-redux';
import { getLangPreferences } from 'state/Account';
import { useSetCheckbox, useSetFormField } from 'hooks';
import { AllowStartWithin, FieldGroupProps, PunchclockSettings } from 'type';
import { InlineInputWrapper } from 'page/Settings/components';

type TimeClockFieldGroupValue = Pick<
  PunchclockSettings,
  'allow_start_within' | 'self_assigned_shifts' | 'only_managers_can_activate'
>;
type TimeClockFieldGroupErrors = {};

type Props = FieldGroupProps<
  TimeClockFieldGroupValue,
  TimeClockFieldGroupErrors
>;

const allowStartWithinOptions: {
  label: string;
  value: AllowStartWithin;
}[] = [
  {
    label: 'anytime',
    value: '0',
  },
  {
    label: '5 minutes',
    value: '5',
  },
  {
    label: '10 minutes',
    value: '10',
  },
  {
    label: '15 minutes',
    value: '15',
  },
  {
    label: '30 minutes',
    value: '30',
  },
  {
    label: '60 minutes',
    value: '60',
  },
  {
    label: '90 minutes',
    value: '90',
  },
  {
    label: '180 minutes',
    value: '180',
  },
];

export default function TimeClockFieldGroup({ onChange, values }: Props) {
  const langPreferences = useSelector(getLangPreferences);
  const { makeSetCheckbox } = useSetCheckbox(onChange);
  const { setFormField } = useSetFormField(onChange);

  const handleAllowChange: TextFieldProps['onChange'] = (event) => {
    setFormField('allow_start_within', event.target.value as AllowStartWithin);
  };

  return (
    <>
      <FormControlSpacing flexDirection="column">
        <Typography variant="h6" component="h3">
          Clock on window
        </Typography>
        <Typography variant="body1" component="h6">
          Only allow {langPreferences.employee.plural} to clock on within
          <InlineInputWrapper>
            <TextField
              select
              margin="none"
              value={values.allow_start_within}
              onChange={handleAllowChange}
              inputProps={{
                'aria-labelledby': 'Allow start within',
              }}
            >
              {allowStartWithinOptions.map((option) => (
                <MenuItem value={option.value} key={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </InlineInputWrapper>
          of a scheduled start time.
        </Typography>
      </FormControlSpacing>

      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.self_assigned_shifts}
              onChange={makeSetCheckbox('self_assigned_shifts')}
            />
          }
          label="Allow people to clock on without an assigned shift"
        />
      </FormControl>

      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.only_managers_can_activate}
              onChange={makeSetCheckbox('only_managers_can_activate')}
            />
          }
          label="Only managers/admins can activate the Time Clock"
        />
      </FormControl>
    </>
  );
}
