import { isAppMarket } from '../../helpers';

export const locations = [
  { value: 'Melbourne', label: 'Melbourne' },
  { value: 'Sydney', label: 'Sydney' }
];

export const users = [
  { value: 'Alex Lee', label: 'Alex Lee' },
  { value: 'Dan Newson', label: 'Dan Newson' },
  { value: 'Mary Ann', label: 'Mary Ann' }
];

export const positions = [
  { value: 'Store Manager', label: 'Store Manager' },
  { value: 'Sales', label: 'Sales' }
];

export const tags = [
  { value: 'Senior', label: 'Senior' }
];

export const periods = [
  { value: 'custom', label: 'Custom' },
  { value: 'today', label: 'Today' },
  { value: 'this_week', label: 'This week' },
  { value: 'yesterday', label: 'Yesterday' },
  { value: 'tomorrow', label: 'Tomorrow' },
  { value: 'last_week', label: 'Last week' },
  { value: 'next_week', label: 'Next week' },
  { value: 'last_2_weeks', label: 'Last 2 weeks' },
  { value: 'last_7_days', label: 'Last 7 days' },
  { value: 'this_month', label: 'This month' },
  { value: 'last_month', label: 'Last month' },
  { value: 'last_30_days', label: 'Last 30 days' },
  { value: 'next_30_days', label: 'Next 30 days' },
  { value: 'this_year', label: 'This year' }
];

export const include = [
  { value: 'all', label: 'All' },
  { value: 'revenue', label: 'Revenue' },
  { value: 'hours', label: 'Hours' },
  { value: 'cost', label: 'Cost' },
];

export const groupByColumns = [
  { value: 'role', label: 'Rows as roles' },
  { value: 'date', label: 'Rows as days' }
];

export const timeIntervals = [
  { value: '60', label: '60 min' },
  { value: '30', label: '30 min' }
];

export const columnTypes = [
  { value: 'rostered_shift', label: isAppMarket('uk') ? 'Rota shifts' : 'Rostered shifts' },
  { value: 'timesheet', label: 'Timesheet' }
];

export const resultTypes = [
  { value: 'summary', label: 'Summary' },
  { value: 'detailed', label: 'Detailed' }
];

export const shiftsTypes = [
  { value: 'published', label: 'Published shifts' },
  { value: 'all', label: 'All shifts showed in the roster' }
];

export const dateFields = [
  { value: 'all', label: 'All' },
  { value: 'created_at', label: 'User created' },
  { value: 'updated_at', label: 'User modified' },
  { value: 'date_of_birth', label: 'Date of birth' }
];

export const usersIs = [
  { value: 'all', label: 'All' },
  { value: 'active_users', label: 'Current users' },
  { value: 'archived_users', label: 'Ex-employees' },
];

export const on = [
  { value: 'on', label: 'On' },
  { value: 'after', label: 'After' },
  { value: 'before', label: 'Before' },
];
