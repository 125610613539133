import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RosteredShift } from 'type';
import {
  BOX_ROSTER_BULK_DELETION_MODE_TOGGLE_SELECTED_SHIFT,
  getIsBulkDeleteOpened,
} from 'state/Roster/BulkActions';
import {
  BOX_COVID_TRACING_MODE_TOGGLE_SHIFT,
  getCovidPointedShiftId,
  getIsCovidModeEnabled,
} from 'state/Roster/CovidTracing';
import { ShiftCardViewProps } from '../../views';
import { useHandleClick as useRosterHandleClick } from '../useRosterCard';

const useHandleClick = (
  rosteredShift: RosteredShift
): ShiftCardViewProps['onClick'] => {
  const dispatch = useDispatch();

  const handleClick = useRosterHandleClick(rosteredShift);
  const isBulkDelete = useSelector(getIsBulkDeleteOpened);
  const isCovidMode = useSelector(getIsCovidModeEnabled);
  const pointedId = useSelector(getCovidPointedShiftId);

  const { id, user_id, area_id, role_id } = rosteredShift;

  const handleCovidModeClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      if (user_id && (!pointedId || pointedId === id)) {
        dispatch(
          BOX_COVID_TRACING_MODE_TOGGLE_SHIFT({
            id: id,
            entity: 'rostered_shift',
          })
        );
      }
    },
    [dispatch, id, user_id, pointedId]
  );

  const handleClickOnBulk = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      dispatch(
        BOX_ROSTER_BULK_DELETION_MODE_TOGGLE_SELECTED_SHIFT({
          id,
          user_id,
          type: 'rostered_shift',
          area_id,
          role_id,
        })
      );
    },
    [dispatch, id, user_id, area_id, role_id]
  );

  if (isCovidMode) {
    return handleCovidModeClick;
  }

  if (isBulkDelete) {
    return handleClickOnBulk;
  }

  return handleClick;
};

export default useHandleClick;
