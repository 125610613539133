import AllReports from 'page/Reports/page/AllReports';
import Report from 'page/Reports/page/Report';
import Generate from 'page/Reports/page/Generate';
import ReportsWrapper from 'page/Reports';
import withHeading from 'element/withHeading';

export const reports = {
  path: '/app/reports',
  component: ReportsWrapper,
  permission: 'reports.access' as 'reports.access',
  routes: {
    generate: {
      path: '/app/reports/generate',
      component: withHeading(Generate, {
        title: 'Generate report'
      })
    },
    report: {
      path: (reportId = ':reportId') => `/app/reports/${reportId}`,
      component: withHeading(Report, {
        title: 'Report details'
      }),
      params: {
        reportId: ''
      }
    },
    allReports: {
      path: '/app/reports',
      component: withHeading(AllReports, {
        title: 'All reports'
      })
    }
  }
};
