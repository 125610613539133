import { NotificationsListReducerState } from './types';

export const defaultState: NotificationsListReducerState = {
  isFetching: false,
  errors: [],
  isDeleting: false,
  isUpdating: false,
  employee: {
    isOpened: false,
    data: {},
    meta: {
      current_page: 1,
      per_page: 20,
      last_page: 0,
      from: 0,
      to: 0,
      total: 0,
      unread_count: 0
    }
  },
  manager: {
    isOpened: false,
    data: {},
    meta: {
      current_page: 1,
      per_page: 20,
      last_page: 0,
      from: 0,
      to: 0,
      total: 0,
      unread_count: 0
    }
  }
};
