import React from 'react';
import { BulkActionIcon as SelectAllIcon } from '../../icons';
import Button from '../../Button';
import './DataTableBulkActionsButton.scss';

type DataTableBulkActionsButtonProps = {
  /** Called when the button is clicked, enables the bulk actions actions in the header */
  toggleBulkActionOpen?: React.MouseEventHandler;
};

/**
 * Wraps the Bulk Actions button
 * @param onBulkActionsClick
 * @constructor
 */
function DataTableBulkActionsButton({
  toggleBulkActionOpen,
}: DataTableBulkActionsButtonProps) {
  return (
    <Button
      className="elmo-datatable__bulkactions-btn"
      onClick={toggleBulkActionOpen}
      isText={true}
      icon={<SelectAllIcon />}
      ariaLabel="Select all"
    />
  );
}

export default DataTableBulkActionsButton;
