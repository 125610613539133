import React from 'react';
import { connect } from 'react-redux';
import { FetchWrapper } from 'element';
import { MANAGER_DASHBOARD_EVENTS } from 'state/FetchPageData/constants';
import {
  BOX_EVENTS_DATA_REQUEST,
} from 'state/ManagerDashboard/Events';
import {
  ManagerDashboardHeader,
  ManagerDashboardLayout
} from '../../components';
import { EventsFilters, EventsList } from './components';
import { StoreState } from '../../../../state/types';
import { getCanViewForecastsAndEvents } from '../../../../state/Account';
import { Redirect } from 'react-router';
import { privateRoutes } from '../../../../routes';
import { hasPermissionSelector } from '../../../../state/Auth/selectors';

type OwnProps = {};

type StateProps = {
  canViewForecastsAndEvents: boolean;
  hasPermissionToViewEvents: boolean;
};

type DispatchProps = {
  fetchData: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const Events = (props: Props) => {

  if (!props.canViewForecastsAndEvents || ! props.hasPermissionToViewEvents) {
    return <Redirect to={privateRoutes.roster.path} />
  }
  return <ManagerDashboardLayout header={<ManagerDashboardHeader noFiltersBtn={false} />}>
    <FetchWrapper {...props} pageId={MANAGER_DASHBOARD_EVENTS}>
      <EventsFilters />
      <EventsList />
    </FetchWrapper>
  </ManagerDashboardLayout>
}

const mapStateToProps = (state: StoreState): StateProps => ({
  canViewForecastsAndEvents: getCanViewForecastsAndEvents(state),
  hasPermissionToViewEvents: hasPermissionSelector(
    state,
    'roster.events.view'
  )
});

const mapDispatchToProps: DispatchProps = {
  fetchData: BOX_EVENTS_DATA_REQUEST
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Events);
