import React, { Component, ReactNode } from 'react';
import { FormItemProps } from 'elmo-elements/FormItem';
import { Moment } from 'moment';
import { getDateFormat, timeFormatSelector } from 'state/Account/selectors';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import { Alert } from 'extended-oxygen-elements';

type WarningMessage = Pick<FormItemProps, 'message' | 'status'>;

export type ChildrenPayload = {
  alert: ReactNode;
  inputWarning: WarningMessage;
};
type OwnProps = {
  children: (payload: ChildrenPayload) => ReactNode;
  params: { start: Moment; end: Moment };
  isDirty: boolean;
};

type StateProps = {
  dateFormat: string;
  timeFormat: string;
};

type DispatchProps = {};

export type Props = OwnProps & StateProps & DispatchProps;

export class WithWarningMessageComponent extends Component<Props> {
  render() {
    const { inputWarning, alert } = this;
    return this.props.children({ alert, inputWarning });
  }

  private get isMidnightWarningVisible(): boolean {
    if (!this.props.isDirty) {
      return false;
    }

    const {
      params: { start, end },
    } = this.props;

    const endHours: number = end!.hours();
    const endMinutes: number = end!.minutes();

    return start.date() !== end!.date() && (!!endHours || !!endMinutes); // ongoing shift can't be edited;
  }

  private get warningMessage(): string {
    const {
      params: { start, end },
      dateFormat,
      timeFormat,
    } = this.props;
    const dateTimeFormat: string = `${dateFormat} ${timeFormat}`;

    const formattedStart = start.format(dateTimeFormat);
    const forattedEnd = end!.format(dateTimeFormat); // ongoing shift can't be edited;

    return `Shift will become an Overnight Shift starting from ${formattedStart} and ending at ${forattedEnd}`;
  }

  private get inputWarning(): WarningMessage {
    if (!this.isMidnightWarningVisible) {
      return {};
    }

    return {
      message: this.warningMessage,
      status: 'warning',
    };
  }

  private get alert(): ReactNode {
    return (
      this.isMidnightWarningVisible && (
        <Alert color="warning" severity="warning">
          {this.warningMessage}
        </Alert>
      )
    );
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  timeFormat: timeFormatSelector(state),
  dateFormat: getDateFormat(state),
});

const mapDispatchToProps: DispatchProps = {};

export const WithWarningMessage = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithWarningMessageComponent);
