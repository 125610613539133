import { createReducer } from 'lib/store-utils';
import { AccountTree } from 'type';
import * as actions from './actions';
import { defaultState } from './state';
import { AccountTreeReducerState } from './types';

export const accountTree = createReducer<AccountTreeReducerState>(
  {},
  defaultState
);

const handleAccountTreeUpdated = (
  state: AccountTreeReducerState,
  { accountTree }: { accountTree: AccountTree }
): AccountTreeReducerState => ({
  ...state,
  accountTree,
});

accountTree
  .on(
    actions.BOX_ACCOUNT_TREE_ACCOUNT_TREE_FETCHED,
    (state, accountTree): AccountTreeReducerState => ({
      ...state,
      accountTree,
    })
  )
  .on(actions.BOX_ACCOUNT_TREE_AREAS_LINK.success, handleAccountTreeUpdated)
  .on(actions.BOX_ACCOUNT_TREE_ROLES_LINK.success, handleAccountTreeUpdated)
  .on(actions.BOX_ACCOUNT_TREE_ROLES_UNLINK.success, handleAccountTreeUpdated)
  .on(
    actions.BOX_ACCOUNT_TREE_UPDATE_AREA_STATUS.success,
    handleAccountTreeUpdated
  )
  .on(
    actions.BOX_ACCOUNT_TREE_UPDATE_SITE_STATUS.success,
    handleAccountTreeUpdated
  );
