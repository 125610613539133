import { AccountTreeArea, AccountTreeRole } from 'type';
import { GetLinkedRolePayload } from './types';

export function getLinkedRole({
  areas,
  roles,
  area_id,
  role_id
}: GetLinkedRolePayload): AccountTreeRole | undefined {
  const area = areas[area_id] as AccountTreeArea | undefined;

  if (area && area.role_ids.includes(role_id)) {
    return roles[role_id] as AccountTreeRole | undefined;
  }
}
