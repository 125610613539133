import { useEffect, useRef, useCallback } from 'react';

const useClickOutside = (initRef: any, handler: any) => {
  const internalRef = useRef(null);
  const ref = initRef || internalRef;
  const triggerEvent = useCallback(
    (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    },
    [ref, handler]
  );

  useEffect(() => {
    document.addEventListener('mousedown', triggerEvent);
    document.addEventListener('touchstart', triggerEvent);

    return () => {
      document.removeEventListener('mousedown', triggerEvent);
      document.removeEventListener('touchstart', triggerEvent);
    };
  }, [ref, handler, triggerEvent]);

  const setRef = (node: any) => {
    ref.current = node;
  };

  return [setRef];
};

export default useClickOutside;
