import { ShiftTimeRangeInputReducerState } from './types';

export const getDefaultState = (): ShiftTimeRangeInputReducerState => ({
  type: 'rosteredShift',
  dayTimestamp: '',
  rowIndex: -1,
  site_id: '',
  user_id: null,
  area_id: null,
  role_id: null,
});
