import { Alert } from 'extended-oxygen-elements';
import { useSelectorWithProps } from 'hooks';
import { emptyFilterResults } from 'messages';
import { useTimesheetsNewQueryStatus } from 'page/TimesheetsWeekly/hooks';
import React from 'react';
import { filteredTimesheetsSelector } from 'state/TimesheetsWeeklyPage';
import { getTimesheetsArray } from 'state/TimesheetResponse';

export function TimesheetsInfoAlert() {
  const status = useTimesheetsNewQueryStatus();
  const timesheets = useSelectorWithProps(getTimesheetsArray);
  const filteredTimesheets = useSelectorWithProps(filteredTimesheetsSelector, {
    status,
  });

  if (!timesheets.length) {
    return <NoTimesheetsAlert />;
  }

  if (!filteredTimesheets.length) {
    return <EmptyFilterResultsAlert />;
  }

  return null;
}

function NoTimesheetsAlert() {
  return (
    <Alert severity="info">
      You don’t have timesheets to review and approve.
      <br />
      Add timesheets by clicking on an empty week day.
    </Alert>
  );
}

function EmptyFilterResultsAlert() {
  return <Alert severity="info">{emptyFilterResults('timesheets')}</Alert>;
}
