import React from 'react';
import { abbreviateNumber } from '../../_lib/helper';
import { Badge } from '../../Badge';
import { ButtonBadgeProps } from '../type';
import './ButtonBadge.scss';

function ButtonBadge({ badge, id }: ButtonBadgeProps) {
  let returnButtonBadge = <></>;

  if (
    (typeof badge === 'number' && badge === 0) ||
    (typeof badge === 'object' && badge.children && badge.children === 0)
  ) {
    return null;
  }
  if (typeof badge === 'number' || typeof badge === 'string') {
    if (typeof badge === 'number') {
      badge = abbreviateNumber(badge);
    }
    returnButtonBadge = <Badge type={'info'}>{badge}</Badge>;
  } else {
    if (badge.children && typeof badge.children === 'number') {
      badge.children = abbreviateNumber(badge.children);
    }
    returnButtonBadge = <Badge {...badge} />;
  }

  return (
    <div
      className="elmo-btn__badge"
      data-testid={`elmo-btn__badge-${id || 'default'}`}
    >
      {returnButtonBadge}
    </div>
  );
}

export default ButtonBadge;
