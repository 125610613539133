import { eventChannel } from 'redux-saga';
import { ServerRosterRepeatableTimeOff } from 'type';
import { WS } from 'lib/SocketConnection';
import { updateRosterTimeOff } from 'lib/Api/helpers';
import { BOX_ROSTERED_SHIFTS_UNDO_LIST_SUCCESS } from 'state/RosteredShifts/actions';
import {
  BOX_ROSTER_GET_OFFERS_SUCCESS,
  BOX_ROSTER_GET_UNPUBLISHED_SUCCESS,
} from 'state/Roster/Roster';
import {
  BOX_TIME_OFFS_DELETE_ONE,
  BOX_TIME_OFFS_UPDATE_ONE,
} from 'state/TimeOffs/actions';

type Props = {
  site_id: string;
};

type WithId = {
  id: string;
};

export function* makeRosterChannel(ws: WS, { site_id }: Props) {
  return yield eventChannel((emit) => {
    const channelId = `roster.${site_id}`;

    ws.private(channelId)
      .listen('.roster.undo', (data: any) => {
        emit(BOX_ROSTERED_SHIFTS_UNDO_LIST_SUCCESS(data));
      })
      .listen('.roster.published', (data: any) => {
        emit(BOX_ROSTER_GET_UNPUBLISHED_SUCCESS(data));
      })
      .listen('.roster.list_for_offer', (data: any) => {
        emit(BOX_ROSTER_GET_OFFERS_SUCCESS(data));
      })
      .listen('.time_off.changed', (data: ServerRosterRepeatableTimeOff) => {
        emit(BOX_TIME_OFFS_UPDATE_ONE(updateRosterTimeOff(data)));
      })
      .listen('.time_off.deleted', (data: WithId) => {
        emit(BOX_TIME_OFFS_DELETE_ONE(data));
      });

    return () => {
      ws.leave(channelId);
    };
  });
}
