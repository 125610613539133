import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { FetchWrapper } from 'element';
import { privateRoutes } from 'routes';
import { StoreState } from 'state/types';
import { MANAGER_DASHBOARD_UNAVAILABILITY } from 'state/FetchPageData/constants';
import { BOX_UNAVAILABILITY_GET_PAGE_DATA_REQUEST } from 'state/ManagerDashboard/Unavailability';
import { getCanViewUnavailability } from 'state/Account';
import { ManagerDashboardLayout } from '../../components/ManagerDashboardLayout';
import { UnavailabilityHeader } from './components';
import View from './view';
import { getFetchFlags } from 'state/FetchPageData';
import './style.scss';

type OwnProps = {};

type StateProps = {
  isFetching: boolean;
  canViewUnavailability: boolean;
};

type DispatchProps = {
  fetchData: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const Unavailability = ({
  canViewUnavailability,
  isFetching,
  ...fetchWrapperProps
}: Props) => {
  if (!canViewUnavailability) {
    return <Redirect to={privateRoutes.roster.path} />;
  }

  return (
    <ManagerDashboardLayout
      header={<UnavailabilityHeader isFetching={isFetching} />}
    >
      <FetchWrapper
        {...fetchWrapperProps}
        pageId={MANAGER_DASHBOARD_UNAVAILABILITY}
      >
        <View />
      </FetchWrapper>
    </ManagerDashboardLayout>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  canViewUnavailability: getCanViewUnavailability(state),
  ...getFetchFlags(state, MANAGER_DASHBOARD_UNAVAILABILITY),
});

const mapDispatchToProps: DispatchProps = {
  fetchData: BOX_UNAVAILABILITY_GET_PAGE_DATA_REQUEST,
};

export default connect(mapStateToProps, mapDispatchToProps)(Unavailability);
