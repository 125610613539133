import React, { Component } from 'react';
import { RoleModal, RoleModalSubmitPayload } from '../../../../components';
import { connect } from 'react-redux';
import { StoreState } from '../../../../../../state/types';
import {
  BOX_USERS_ADD_ROLE_REQUEST,
  BOX_USERS_ROLE_MODAL_CLOSE
} from '../../../../../../state/Users/Users';
import { StringMap, UserFields, UserRole } from '../../../../../../type';
import { userListSelector } from '../../../../../../state/UsersCollection';

type StateProps = {
  isOpened: boolean;
  isUpdating: boolean;
  errors: string[];
  userId: string;
  userList: StringMap<UserFields>;
};

type DispatchProps = {
  onCloseModal: () => void;
  onFormSubmit: (data: RoleModalSubmitPayload) => void;
};

type Props = StateProps & DispatchProps;
class UsersAddRoleComponent extends Component<Props> {
  render() {
    const { userId, userList, ...rest } = this.props;

    return <RoleModal {...rest} initRoles={this.initRoles} />;
  }

  get initRoles(): UserRole[] | undefined {
    const user: UserFields | undefined = this.props.userList[this.props.userId];

    if (user) {
      return user.user_roles;
    }
  }
}

const mapStateToProps = (state: StoreState): StateProps => {
  const {
    user: {
      addRoleModal: { isOpened, isUpdating, errors, userId }
    }
  } = state;

  return {
    isOpened,
    isUpdating,
    errors,
    userId,
    userList: userListSelector(state)
  };
};

const mapDispatchToProps: DispatchProps = {
  onCloseModal: BOX_USERS_ROLE_MODAL_CLOSE,
  onFormSubmit: BOX_USERS_ADD_ROLE_REQUEST
};
export const UsersAddRole = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersAddRoleComponent);
