import { StoreState } from '../types';
import { createSelector } from 'reselect';
import moment, { Moment } from 'moment';
import { filter } from 'lodash';
import { RosteredShift, StringMap } from 'type';
import { groupedByFieldCombinerCreator } from 'state/combiners';
import { UndoItem } from './types';
import { nest } from '../helpers';
import { selectedDayFormat } from './state';
import {
  getRosteredShiftSafe,
  rosteredShiftsArraySelector,
} from '../RosteredShiftsCollection';

export * from '../RosteredShiftsCollection/selectors';

const getState = (state: StoreState) => state.rosteredShifts;
export const getRosteredShiftUserId = (
  state: StoreState,
  id: string | undefined
): string | null => {
  if (id) {
    const shift = getRosteredShiftSafe(state, id);
    return shift ? shift.user_id : null;
  }
  return null;
};
export const getUndoList = (state: StoreState) => getState(state).undoList;
const getSelectedDay = (state: StoreState) => getState(state).day;
export const getDroppedShiftPayload = (state: StoreState) =>
  getState(state).droppedShift;
export const getOverlapModalProps = (state: StoreState) =>
  getState(state).overlapModal;
export const getDropInProgress = (state: StoreState): boolean =>
  getState(state).dropInProgress;
export const getBulkCreateShiftsInProgress = (state: StoreState): boolean =>
  getState(state).bulkCreateInProgress;

export const selectedDaySelector = createSelector(
  getSelectedDay,
  (selectedDay: string): Moment =>
    moment(selectedDay, selectedDayFormat).tz('UTC', true)
);

export const rosteredShiftsByUserIdSelector = createSelector<
  StoreState,
  RosteredShift[],
  StringMap<RosteredShift[]>
>(rosteredShiftsArraySelector, groupedByFieldCombinerCreator('user_id'));

export const rosteredShiftsByAreaIdByRoleIdSelector = createSelector<
  StoreState,
  RosteredShift[],
  StringMap<StringMap<RosteredShift[]>>
>(rosteredShiftsArraySelector, (rosteredShifts) =>
  nest(rosteredShifts, ['area_id', 'role_id'])
);

export const rosteredShiftsByAreaIdSelector = createSelector<
  StoreState,
  RosteredShift[],
  StringMap<RosteredShift[]>
>(rosteredShiftsArraySelector, groupedByFieldCombinerCreator('area_id'));

export const rosteredShiftsByRoleIdSelector = createSelector<
  StoreState,
  RosteredShift[],
  StringMap<RosteredShift[]>
>(rosteredShiftsArraySelector, groupedByFieldCombinerCreator('role_id'));

export const getUndoListItemsNumberSelector = createSelector<
  StoreState,
  StringMap<UndoItem>,
  number
>(getUndoList, (undoList) => {
  let num = 0;
  Object.keys(undoList).forEach((key: string) => {
    if (key && undoList[key] !== null) {
      num += 1;
    }
  });
  return num;
});

export const getUndoListSelector = createSelector<
  StoreState,
  StringMap<UndoItem>,
  UndoItem[]
>(getUndoList, (undoList) => filter(undoList, (undo) => undo !== null));

export const getDraggableShift = (
  state: StoreState
): null | Partial<RosteredShift> => getState(state).draggableShift;

export const getStretchingShift = (
  state: StoreState
): null | Partial<RosteredShift> => getState(state).stretchingShift;

export const getCompareDraggableShift = (
  state: StoreState,
  shiftId: string | undefined | null
): boolean => {
  const shift = getDraggableShift(state);
  const dropInProgress = getDropInProgress(state);
  return !!(shift && shiftId && shift.id === shiftId && dropInProgress);
};
