import React from 'react';
import { InputWithIcon } from '../InputWithIcon';
import { ClearableTimeInputProps } from './type';
import { useTimeFieldMoment } from './useTimeFieldMoment';

export function ClearableTimeInput(props: ClearableTimeInputProps) {
  const inputProps = useTimeFieldMoment(props);
  const {
    placeholder,
    tabIndex = 0,
    isReadOnly,
    label,
    disabled,
    id,
    icon,
    onIconClick,
  } = props;

  return (
    <InputWithIcon
      id={id}
      {...inputProps}
      placeholder={placeholder}
      tabIndex={tabIndex}
      isReadOnly={isReadOnly}
      ariaLabel={label}
      isDisabled={disabled}
      icon={icon}
      onIconClick={onIconClick}
    />
  );
}
