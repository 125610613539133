import {
  QueryDayProvider,
  QueryItemProvider,
  QuerySiteIdProvider,
} from 'element';
import React from 'react';
import {
  FetchPageData,
  SocketConnection,
  PageView,
  UpdatePageData,
} from './components';
import { statusValidator } from './query-validators';

export default TimesheetsWeekly;

function TimesheetsWeekly() {
  return (
    <QuerySiteIdProvider>
      <QueryDayProvider>
        <QueryItemProvider
          name="status"
          default="all"
          validator={statusValidator}
        >
          <FetchPageData>
            <SocketConnection />

            <UpdatePageData />

            <PageView />
          </FetchPageData>
        </QueryItemProvider>
      </QueryDayProvider>
    </QuerySiteIdProvider>
  );
}
