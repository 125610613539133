import { createAction } from 'lib/store-utils';
import { StringMap } from 'type';

export const BOX_CURRENCIES_REQUEST = createAction('Get currencies');
export const BOX_CURRENCIES_SUCCESS = createAction<any[]>('Get currencies success');
export const BOX_CURRENCIES_FAILURE = createAction<string[]>('Get currencies failed');

export const BOX_TIMEZONES_REQUEST = createAction('Get timezones');
export const BOX_TIMEZONES_SUCCESS = createAction<string[]>('Get timezones success');
export const BOX_TIMEZONES_FAILURE = createAction<string[]>('Get timezones failed');
