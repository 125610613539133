import React from 'react';
import { useResizableEventListeners } from './index';

export function useResizing(props: {
  wrapper: React.RefObject<HTMLDivElement>;
  position: { left: number; right: number };
  setLeft: (newValue: number) => void;
  setRight: (newValue: number) => void;
  isResizing: boolean;
  onResizeEnd?: () => void;
}) {
  const [frozenState, setFrozenState] = React.useState(props.position);

  function onResizeStart() {
    setFrozenState(props.position);
  }

  function onResizeLeft(x: number) {
    const { current } = props.wrapper;

    if (!current) {
      return;
    }

    const { right } = current.getBoundingClientRect();
    const newWidthPx = right - x;
    const newLeft = frozenState.right - newWidthPx;

    props.setLeft(newLeft);
  }

  function onResizeRight(x: number) {
    const { current } = props.wrapper;

    if (!current) {
      return;
    }

    const { left } = current.getBoundingClientRect();
    const newWidthPx = x - left;
    const newRight = frozenState.left + newWidthPx;

    props.setRight(newRight);
  }

  function onResizeEnd() {
    if (props.onResizeEnd) {
      props.onResizeEnd();
    }
  }

  const [isResizingRight, startResizeRight] = useResizableEventListeners(
    onResizeStart,
    onResizeRight,
    onResizeEnd,
    props.isResizing
  );

  const [isResizingLeft, startResizeLeft] = useResizableEventListeners(
    onResizeStart,
    onResizeLeft,
    onResizeEnd
  );

  const isResizing = isResizingRight || isResizingLeft;

  return { startResizeLeft, startResizeRight, isResizing };
}
