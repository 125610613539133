import React, { Children, Component } from 'react';
import ListTableColumn from './component/ListTableColumn';
import {
  ClassNamesForCol,
  ListTableContextProps,
  ListTableProps,
  ResponsiveSize,
} from './type';
import ListTableHeaderWrapper from './component/ListTableHeaderWrapper';
import { TOTAL_GRID_COLS } from '../_lib/const';
import ListTableTr from './component/ListTableTr';
import { getClass, isElementOfType, noop } from '../_lib/helper';
import './ListTable.scss';
import ListTableContext from './ListTableContext';
import ListTableHeader, {
  ListTableHeaderProps,
} from './component/ListTableHeader';
import ListTableBody from './component/ListTableBody';
import ListTableTd from './component/ListTableTd';
import ListTablePagination from './component/ListTablePagination';

const maxColsForBreakpoints: ResponsiveSize = {
  xs: 2,
  sm: 4,
  md: 6,
  lg: 6,
  xl: 6,
  xxl: 8,
};

class ListTable extends Component<ListTableProps, ListTableContextProps> {
  static Column = ListTableColumn;
  static Header = ListTableHeader;
  static Body = ListTableBody;
  static Tr = ListTableTr;
  static Td = ListTableTd;
  static Pagination = ListTablePagination;
  protected subcomponents: any;

  constructor(props: ListTableProps) {
    super(props);
    // run this in the constructor so we save on rendering
    const { sortColumn, sortDirection, actions, avatarType, icon } = this.props;
    // get subcomponents from children
    this.subcomponents = this.getSubComponents();
    // update ListTable context state from props
    const contextState = this.getContextStateUpdate({});
    const rowsHasActions = this.checkRowsHaveActions(this.subcomponents);

    this.state = {
      selectAllIsChecked: false,
      selectAllIsIndeterminate: false,
      showSelectAllAvailableHeader: false,
      countSelectable: 0,
      setContextState: this.setContextState,
      hasBulkActionsButton: false,
      countSelectedItems: 0,
      countItemsAvailable: 0,
      isAllAvailableSelected: false,
      onSort: noop,
      avatarType: avatarType,
      classNamesForCols: this.calculateClassNames(
        this.subcomponents.columns.length
      ),
      colSpanForCols: this.calculateColSpan(this.subcomponents.columns.length),
      sort: {
        column: sortColumn,
        direction: sortDirection,
      },
      columns: this.subcomponents.columns,
      hasActions: actions || icon || rowsHasActions,
      updateSelectAllCheckboxState: this.updateSelectAllCheckboxState,
      ...contextState,
    };
  }

  /**
   * Exposes setState to DataTableContext consumers.
   * @param state
   */
  setContextState = (state: any) => {
    this.setState(state);
  };

  updateSelectAllCheckboxState = (isSelect: boolean) => {
    const { data, isItemSelected, isItemDisabled } = this.props;

    let countVisibleSelected = isSelect ? 1 : -1;
    let countVisibleSelectable = 0;
    // For the case where data is passed in through the ListTable.
    if (data) {
      data.forEach((rowData: any) => {
        const isSelected: boolean = !!(
          isItemSelected && isItemSelected(rowData)
        );
        const isDisabled: boolean = !!(
          isItemDisabled && isItemDisabled(rowData)
        );

        countVisibleSelected += isSelected ? 1 : 0;
        countVisibleSelectable += !isDisabled || isSelected ? 1 : 0;
      });
    }

    // For the case where the rows are rendered with <ListTableTr>
    if (this.subcomponents.body) {
      const bodyProps: any = this.subcomponents.body.props;
      const rows = Children.toArray(bodyProps.children);
      rows.forEach((row: any) => {
        if (!React.isValidElement(row) || !isElementOfType(row, ListTableTr)) {
          return;
        }

        const rowProps: any = row.props;
        countVisibleSelected += rowProps.isSelected ? 1 : 0;
        countVisibleSelectable +=
          !rowProps.isDisabled || rowProps.isSelected ? 1 : 0;
      });
    }

    const isIndeterminate =
      !(countVisibleSelected === countVisibleSelectable) &&
      countVisibleSelected !== 0;
    const isChecked = countVisibleSelected !== 0;

    this.setContextState({
      selectAllIsChecked: isChecked,
      selectAllIsIndeterminate: isIndeterminate,
      showSelectAllAvailableHeader: isChecked && !isIndeterminate,
    });
  };

  componentDidUpdate(prevProps: ListTableProps) {
    const nextContextState = this.getContextStateUpdate(prevProps);

    if (Object.keys(nextContextState).length !== 0) {
      this.setContextState(nextContextState);
    }

    const {
      sortColumn,
      sortDirection,
      countSelectedItems,
      toggleBulkActionDisabled,
      data,
      isAllAvailableSelected,
      isBulkActionOpen,
    } = this.props;

    /**
     * Update the sort column and direction
     */
    if (
      prevProps.sortColumn !== sortColumn ||
      prevProps.sortDirection !== sortDirection
    ) {
      this.setState({
        sort: {
          column: sortColumn,
          direction: sortDirection,
        },
      });
    }

    /**
     * When the data in the list table changes, uncheck the "Select all" checkbox
     */
    if (prevProps.data !== data) {
      this.setState({
        selectAllIsChecked: !!isAllAvailableSelected,
        selectAllIsIndeterminate: false,
        showSelectAllAvailableHeader: false,
      });
    }

    /**
     * If the bulk actions has been closed, hide the select all component in the header, and uncheck the Select All
     * checkbox.
     */
    if (!isBulkActionOpen && isBulkActionOpen !== prevProps.isBulkActionOpen) {
      this.setContextState({
        showSelectAllAvailableHeader: false,
        selectAllIsChecked: false,
        selectAllIsIndeterminate: false,
      });
    }

    /**
     * Enable or disable the bulk actions buttons depending if there are any selected
     * items
     */
    if (
      countSelectedItems !== undefined &&
      prevProps.countSelectedItems !== undefined &&
      prevProps.countSelectedItems !== countSelectedItems
    ) {
      const firstSelected =
        countSelectedItems > 0 && prevProps.countSelectedItems === 0;
      const lastDeselected =
        countSelectedItems === 0 && prevProps.countSelectedItems > 0;

      if (firstSelected || lastDeselected) {
        toggleBulkActionDisabled();
      }
    }
  }

  /**
   * Get the next context state with the values from props
   * Does not update the state
   * @param prevProps
   */
  getContextStateUpdate = (prevProps: any) => {
    const props: any = this.props;
    const propsToUpdate = [
      'hasBulkActionsButton',
      'countSelectedItems',
      'countItemsAvailable',
      'isAllAvailableSelected',
      'onSort',
      'onSelectAllToggle',
      'onSelectAllAvailableToggle',
      'countSelectable',
      'hasLayout',
    ];

    const newState: any = {};

    // Check if the props have changed, if they have, add it to the new state to be updated
    propsToUpdate.forEach((key: string) => {
      if (prevProps[key] !== props[key]) {
        newState[key] = props[key];
      }
    });

    return newState;
  };

  /**
   * Calculate the subcomponents in the ListTable children. Used to render in a specific order
   */
  getSubComponents() {
    const { children } = this.props;

    const subComponents: any = {
      columns: [],
      body: null,
      pagination: null,
    };

    Children.map(children, (child) => {
      if (!React.isValidElement(child)) {
        return false;
      }

      if (isElementOfType(child, ListTableColumn)) {
        subComponents.columns.push(child);
      } else if (isElementOfType(child, ListTableHeader)) {
        const headerProps: ListTableHeaderProps = child.props;
        subComponents.columns = Children.toArray(headerProps.children).filter(
          (column) => {
            return isElementOfType(column, ListTableColumn);
          }
        );
      } else if (isElementOfType(child, ListTableBody)) {
        subComponents.body = child;
      } else if (isElementOfType(child, ListTablePagination)) {
        subComponents.pagination = child;
      }
    });

    return subComponents;
  }

  /**
   * Checks if the ListTableTr components contain actions
   * @param subComponents
   */
  checkRowsHaveActions(subComponents: any) {
    if (!subComponents.body) {
      return false;
    }

    const bodyProps: any = subComponents.body.props;

    const rows = Children.toArray(bodyProps.children);
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      if (!React.isValidElement(row) || !isElementOfType(row, ListTableTr)) {
        continue;
      }

      const rowProps: any = row.props;
      // If the row has actions or icon
      if (!!rowProps.actions || !!rowProps.icon) {
        return true; // stop looping!
      }
    }
  }

  /**
   * Called when a row is clicked.
   * Wraps the function passed into ListTable to provide the row information
   * @param rowData
   * @param rowIndex
   */
  onRowClick = (rowData: any, rowIndex: number) => () => {
    const { onRowClick } = this.props;

    if (!onRowClick) {
      return false;
    }

    return onRowClick(rowData, rowIndex);
  };

  /**
   * Called when item selected/deselected
   * Wraps the function passed into ListTable to provide the row information
   * @param rowData
   */
  onItemToggle = (rowData: any) => (isSelect: boolean) => {
    const { onItemToggle } = this.props;

    if (!onItemToggle) {
      return false;
    }

    return onItemToggle(rowData, isSelect);
  };

  renderBody(subcomponents: any) {
    const {
      id,
      data,
      isItemDisabled,
      isItemSelected,
      actions,
      icon,
      href,
      avatarType,
      testId,
    } = this.props;
    const { hasActions } = this.state;

    if (data) {
      return data.map((rowData: any, rowIndex: number) => {
        const isSelected: boolean = !!(
          isItemSelected && isItemSelected(rowData)
        );
        const isDisabled: boolean = !!(
          isItemDisabled && isItemDisabled(rowData)
        );
        const rowActions = actions ? actions(rowData, rowIndex) : undefined;
        const rowIcon = icon ? icon(rowData, rowIndex) : undefined;
        const rowHref = href ? href(rowData, rowIndex) : undefined;
        const rowTestId = testId ? testId(rowData, rowIndex) : undefined;

        return (
          <ListTableTr
            testId={rowTestId}
            rowData={rowData}
            rowIndex={rowIndex}
            onRowClick={this.onRowClick(rowData, rowIndex)}
            onItemToggle={this.onItemToggle(rowData)}
            isSelected={isSelected}
            isDisabled={isDisabled}
            actions={rowActions}
            icon={rowIcon}
            key={rowIndex}
            id={(id || 'default') + '-' + rowIndex}
            href={rowHref}
            avatarType={avatarType}
            hasActions={hasActions}
          />
        );
      });
    }

    return subcomponents.body;
  }

  /**
   * Called when the page is changed or page size is changed.
   */
  onPageUpdate = () => {
    this.setContextState({
      showSelectAllAvailableHeader: false,
      selectAllIsChecked: false,
      selectAllIsIndeterminate: false,
    });
  };

  render() {
    const { isBulkActionOpen, ariaLabel, id, className, avatarType,  } =
      this.props;

    const { sort, hasActions } = this.state;

    /** Store the re-rendered subcomponents as it gets used in other functions */
    this.subcomponents = this.getSubComponents();

    const renderTable = (
      <ListTableContext.Provider value={this.state}>
        <div
          id={id}
          className={getClass('elmo-listtable', className, {
            'bulkactions-open': isBulkActionOpen,
            [`avatar-type--${avatarType}`]: avatarType,
            'has-row-actions': hasActions,
          })}
          role="table"
          aria-label={ariaLabel}
          data-testid={`elmo-listtable-${id || 'default'}`}
          data-sort-order={sort.direction}
          data-sort-by={sort.column}
        >
          <ListTableHeaderWrapper id={id} />
          {this.renderBody(this.subcomponents)}
          {this.subcomponents.pagination &&
            React.cloneElement(this.subcomponents.pagination, {
              onPageUpdate: this.onPageUpdate,
            })}
        </div>
      </ListTableContext.Provider>
    );

    return renderTable;
  }

  /**
   * Calculate the width of each column and whether an offset needs to be applied.
   * @param numColumns
   */
  calculateColSpan(numColumns: number): ResponsiveSize[] {
    let result: ResponsiveSize[] = [];

    Object.entries(maxColsForBreakpoints).forEach(
      ([size, maxCols], sizeIndex: number) => {
        let numCols: number = Math.min(numColumns, maxCols);
        let remainder: number = TOTAL_GRID_COLS % numCols;
        let currentColCount: number = 0; // index of the column in the current row
        let isFirstRow: boolean = true;

        Array(numColumns)
          .fill(0)
          .forEach((child: any, colIndex: number) => {
            // initialise the results array
            if (sizeIndex === 0) {
              result[colIndex] = {
                xs: {},
                sm: {},
                md: {},
                lg: {},
                xl: {},
                xxl: {},
              };
            }

            // calculate how many cols each column takes up.
            let span: number =
              Math.floor(TOTAL_GRID_COLS / numCols) +
              (colIndex < remainder ? 1 : 0);

            // Offset required because in the design from the second row onwards, the columns start
            // from the second column.
            let offset: number = 0;
            if (!isFirstRow && currentColCount === 0) {
              offset = span;
            }

            currentColCount++;
            if (
              (isFirstRow && currentColCount === maxCols) ||
              (!isFirstRow && currentColCount === maxCols - 1)
            ) {
              currentColCount = 0;
              if (isFirstRow) {
                isFirstRow = false;
              }
            }

            result[colIndex][size] = {
              span: span,
              offset: offset,
            };
          });
      }
    );

    return result;
  }

  /**
   * Decides whether or not the header should be displayed on specific breakpoints
   * @param numColumns
   */
  calculateClassNames(numColumns: number): ClassNamesForCol[] {
    return Array(numColumns)
      .fill(0)
      .map((data: any, colIndex: number) => {
        let headerClassNames = 'd-none '; // don't display the headers by default
        let labelClassNames = colIndex === 0 ? 'd-none' : ''; // don't display the label for the first column by default

        Object.entries(maxColsForBreakpoints).forEach(([size, maxCols]) => {
          if (colIndex < maxCols) {
            labelClassNames += ' d-' + size + '-none';
            headerClassNames += ' d-' + size + '-block';
            return;
          }

          labelClassNames += ' d-' + size + '-block';
          headerClassNames += ' d-' + size + '-none';
        });

        return {
          header: headerClassNames,
          label: labelClassNames,
        };
      });
  }
}

export default ListTable;
