import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Heading } from 'elmo-elements';
import { BOX_COVID_TRACING_MODE_DISABLE } from 'state/Roster/CovidTracing/actions';
import './style.scss';
import { Header } from 'element';
import { CoronavirusOutlinedIcon } from 'element/icons';
import ErrorModal from '../ErrorModal';
import { HelpIcon } from '../HelpIcon';

export const CovidTracingHeader = () => {
  const dispatch = useDispatch();

  const closeCovidMode = () => {
    dispatch(BOX_COVID_TRACING_MODE_DISABLE());
  };

  useEffect(() => {
    return function cleanUp() {
      closeCovidMode();
    };
  }, [dispatch]);

  return (
    <div className={'covid-tracing-header'}>
      <Header
        title={
          <div className={'covid-tracing-header__left'}>
            <CoronavirusOutlinedIcon />
            <Heading>COVID-19 tracing</Heading>
            <HelpIcon size="large" />
          </div>
        }
        closeButton={
          <Button
            testId={'covid-close-button'}
            isText={true}
            onClick={closeCovidMode}
            isUppercase={false}
            type={'primary'}
          >
            Close
          </Button>
        }
      />
      <ErrorModal />
    </div>
  );
};
