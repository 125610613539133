import { userUnassignedRosterDataSelector } from '../../RosterWeekView';
import { createSelector } from 'reselect';
import { StoreState } from '../../../types';

export const unassignedRostersRowNumberSelector = createSelector(
  userUnassignedRosterDataSelector,
  (unassignedData) => unassignedData.rostersRowNumber
);

const getRowNumberProp = (state: StoreState, props: { rowNumber: number }) =>
  props.rowNumber;
const getWeekDayIndexProp = (
  state: StoreState,
  props: { weekDayIndex: number }
) => props.weekDayIndex;

export const makeRosteredShiftIdSelector = () =>
  createSelector(
    userUnassignedRosterDataSelector,
    getRowNumberProp,
    getWeekDayIndexProp,
    (unassignedData, rowNumber, weekDayIndex) =>
      unassignedData.rosters[rowNumber][weekDayIndex].rosteredShiftId
  );

export const getUnassignedRowByIndex = (rowNumber: number) =>
  createSelector(
    userUnassignedRosterDataSelector,
    (unassignedData) => {
      return unassignedData.rosters[rowNumber];
    }
  );