import {
  Button as OxygenButton,
  ButtonProps,
} from 'oxygen-elements';
import React from 'react';

export type { ButtonProps };

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  props,
  ref
) {
  const handleClick: ButtonProps['onClick'] = (event) => {
    if (!props.loading) {
      props.onClick?.(event);
    }
  };

  return <OxygenButton {...props} onClick={handleClick} ref={ref} />;
}) as typeof OxygenButton;

export default Button;
