import * as React from 'react';
import { connect } from 'react-redux';
import { FormItem, Input } from 'elmo-elements';
import {
  ErrorBox,
  PageDialog,
  PageDialogCancel,
  PageDialogSubmit,
} from 'element';
import { StoreState } from 'state/types';
import {
  BOX_NOTIFICATION_TIME_MODAL_CLEAR_ERRORS,
  BOX_NOTIFICATION_TIME_MODAL_CLOSE,
  BOX_UPDATE_NOTIFICATION_HOURS_REQUEST,
  getHoursSelector,
  getTimeModal,
  TimeModal,
} from 'state/Settings/Notification';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';

type Props = {
  updateHours: (data: {
    params: string;
    message: { hours: number | undefined };
  }) => void;
  groupId: string;
  notificationId: string;
  timeModal: TimeModal;
  closeTimeModal: () => void;
  clearErrors: () => void;
  hours: number;
};
type State = {
  hours: number;
};

export class HoursModalComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hours: props.hours,
    };
  }

  render() {
    return (
      <PageDialog
        maxWidth={'sm'}
        open={this.props.timeModal.isOpened}
        id="timeModal"
        onClose={this.props.closeTimeModal}
      >
        <DialogTitle>Notification schedule</DialogTitle>
        <DialogContent>
          <ErrorBox
            errors={this.props.timeModal.errors}
            clearErrors={this.props.clearErrors}
            watchFormErrors={true}
          />
          <FormItem label="Notify prior to shift starts by hours">
            <Input
              htmlType="number"
              name="time"
              value={this.state.hours}
              onChange={this.onChangeHours}
            />
          </FormItem>
        </DialogContent>
        <DialogActions>
          <PageDialogCancel
            onClick={this.props.closeTimeModal}
            fullWidth={false}
          >
            Cancel
          </PageDialogCancel>
          <PageDialogSubmit
            onClick={this.saveHours}
            loading={this.props.timeModal.isUpdating}
            id={'save-hours-settings-btn'}
            fullWidth={false}
          >
            Save
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  }

  saveHours = () => {
    const { groupId, notificationId } = this.props;
    const data = {
      params: `${groupId}/${notificationId}`,
      message: {
        hours: this.state.hours,
      },
    };
    this.props.updateHours(data);
  };

  onChangeHours = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState({
      hours: Number(value) >= 0 ? Number(value) : this.state.hours,
    });
  };
}

const mapStateToProps = (state: StoreState) => ({
  timeModal: getTimeModal(state),
  hours: getHoursSelector(state),
});
export const HoursModal = connect(mapStateToProps, {
  updateHours: BOX_UPDATE_NOTIFICATION_HOURS_REQUEST,
  closeTimeModal: BOX_NOTIFICATION_TIME_MODAL_CLOSE,
  clearErrors: BOX_NOTIFICATION_TIME_MODAL_CLEAR_ERRORS,
})(HoursModalComponent);
