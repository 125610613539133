import { createSelector } from 'reselect';
import _ from 'lodash';
import { Moment } from 'moment';
import { RosteredShift, StringMap } from 'type';
import {
  fromBySiteTimezoneSelector,
  getRosteredShifts,
} from 'state/RosteredShifts';

/*
 this selector removes midnight shifts that start on previous sunday
 because on the week view those shifts are rendered in a start day only
*/
export const rosteredShiftsArrayFilteredByFromSelector = createSelector(
  getRosteredShifts,
  fromBySiteTimezoneSelector,
  (rosteredShifts: StringMap<RosteredShift>, from: Moment): RosteredShift[] =>
    _.filter(rosteredShifts, (rosteredShift: RosteredShift): boolean => {
      return rosteredShift.start && rosteredShift.start.isSameOrAfter(from);
    })
);
