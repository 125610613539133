import React from 'react';
import { RosterAreaLegend, RosterRow } from '../../../../components';
import {
  filtersByTypeSelector,
  rosteredShiftsFilteredSelector,
  timesheetsFilteredSelector,
} from 'state/Roster/RosterFilters';
import { getDurationLabels, getDurationsInfo } from '../../../../helpers';
import { useSelector } from 'react-redux';
import { timeOffsByUserIdByDaySelector } from 'state/Roster/RosterWeekView';
import {
  getCovidPointedShiftId,
  getIsCovidModeEnabled,
  isAreaRoleRowHasCovidShifts,
} from 'state/Roster/CovidTracing/selectors';
import { getAreas, getRoles } from 'state/AccountTree';
import { getLinkedRole } from 'lib/helpers';
import { getSelectedByAreaRole } from 'state/Roster/BulkActions';
import { areaRoleByIdRosterDataSelector } from 'state/Roster/RosterWeekViewSiteView';
import _ from 'lodash';

type Props = {
  children: React.ReactNode;
  bulkSelect: 'area';
  rowIndex: number;
  legendType: 'single-row' | 'multi-row';
  areaId: string;
  roleId: string;
};

export const SiteRowWithLegendRefactored = ({
  children,
  rowIndex,
  areaId,
  roleId,
  bulkSelect,
  legendType,
}: Props) => {
  const timeOffs = useSelector(timeOffsByUserIdByDaySelector);
  const filteredRosters = useSelector(rosteredShiftsFilteredSelector);
  const filteredTimesheets = useSelector(timesheetsFilteredSelector);
  const selectedFilter = useSelector(filtersByTypeSelector);
  const isCovidMode = useSelector(getIsCovidModeEnabled);
  const pointedId = useSelector(getCovidPointedShiftId);
  const hasContactedShifts = useSelector(
    isAreaRoleRowHasCovidShifts(areaId, roleId)
  );
  const areas = useSelector(getAreas);
  const roles = useSelector(getRoles);
  const rowData = useSelector((state) =>
    areaRoleByIdRosterDataSelector(state, areaId, roleId)
  );
  const selectedByAreaRole = useSelector(getSelectedByAreaRole);
  const className =
    isCovidMode && pointedId && !hasContactedShifts
      ? 'no-contacted-shifts'
      : '';

  const getDurations = () => {
    const duration = getDurationsInfo(
      rowData!.rosters,
      filteredRosters,
      filteredTimesheets,
      timeOffs
    );
    return getDurationLabels(duration, selectedFilter);
  };

  const getRole = () => {
    return getLinkedRole({
      areas,
      roles,
      area_id: areaId,
      role_id: roleId,
    });
  };

  const getIsDisabled = (): boolean => {
    const area = areas[areaId];
    const role = getRole();
    return area.archived || !!(role && role.archived);
  };

  const getAreaLegendData = () => {
    return {
      areaName: areas[areaId]
        ? _.replace(areas[areaId].name, new RegExp(' ', 'g'), '-')
        : '',
      label: roles[roleId] ? roles[roleId].name : 'Unlinked role',
    };
  };

  const getAreaLegend = () => {
    const durations = getDurations();
    const data = getAreaLegendData();

    const getLegendState = () => {
      return areas[areaId] !== null ? getIsDisabled() : false;
    };

    return (
      <RosterAreaLegend
        type={legendType}
        label={data.label}
        icon={data.areaName as string}
        durations={durations}
        onClick={() => false}
        colour={''}
        isDisabled={getLegendState()}
        bulkSelect={bulkSelect === 'area'}
        areaId={areaId}
        roleId={roleId}
        pageType={'week'}
      />
    );
  };

  return (
    <RosterRow
      isCollapsed={false}
      isSelected={selectedByAreaRole[`${areaId}_${roleId}`]}
      rowIndex={rowIndex}
      className={className}
    >
      {{
        sticky: getAreaLegend(),
        content: (
          <div className={`week-rosters-grid spec-cell-height`}>{children}</div>
        ),
      }}
    </RosterRow>
  );
};

export default SiteRowWithLegendRefactored;
