import React from 'react';
import { MessageBlock } from 'elmo-elements';

type OwnProps = {};

type StateProps = {};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

const EmptyMessageComponent = () => (
  <MessageBlock>
    <MessageBlock.Header>
      No unavailability requests were found matching your filter.
    </MessageBlock.Header>
  </MessageBlock>
);

export const EmptyMessage = EmptyMessageComponent;

/*const mapStateToProps = (state: StoreState): StateProps => ({});

export const EmptyMessage = connect(
  mapStateToProps
)(EmptyMessageComponent);*/
