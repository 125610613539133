import React from 'react';
import { DefaultDivProps, DefaultSpanProps } from 'type';
import clsx from 'clsx';
import './SimpleActionCard.scss';

export const SimpleActionCardHeaderContent = ({
  children,
  className,
  ...restProps
}: DefaultDivProps) => {
  return (
    <div
      {...restProps}
      className={clsx('simple-action-card__header-content', className)}
    >
      {children}
    </div>
  );
};

export const SimpleActionCardHeader = ({
  children,
  className,
  ...restProps
}: DefaultDivProps) => {
  return (
    <div {...restProps} className={clsx('simple-action-card__header', className)}>
      {children}
    </div>
  );
};

export const SimpleActionCardContent = ({
  children,
  className,
  ...restDivProps
}: DefaultDivProps) => {
  return (
    <div
      {...restDivProps}
      className={clsx('simple-action-card__content-wrapper', className)}
    >
      <div className="simple-action-card__content-inner-wrapper">
        <div className="simple-action-card__content">{children}</div>
      </div>
    </div>
  );
};

export const SimpleActionCardFooter = ({
  children,
  className,
  ...restProps
}: DefaultDivProps) => {
  return (
    <div
      {...restProps}
      className={clsx('simple-action-card__footer', className)}
    >
      {children}
    </div>
  );
};

export const SimpleActionCardFooterLabel = ({
  children,
  className,
  ...restProps
}: DefaultSpanProps) => {
  return (
    <span
      {...restProps}
      className={clsx('simple-action-card__footer-label', className)}
    >
      {children}
    </span>
  );
};

export const SimpleActionCard = ({
  children,
  className,
  ...restDivProps
}: DefaultDivProps) => {
  return (
    <div {...restDivProps} className={clsx('simple-action-card', className)}>
      {children}
    </div>
  );
};
