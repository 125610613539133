import { Box } from 'extended-oxygen-elements';
import { createStyles, makeStyles } from '@mui/styles';
import {
  BulkApproveContextProvider,
  BulkDeleteContextProvider,
  DeleteDialogContextProvider,
  EditDialogContextProvider,
} from 'contexts';
import { Layout } from 'element';
import React from 'react';
import {
  CreateDialog,
  DateRangeSelect,
  Days,
  DeleteDialog,
  EditDialog,
  ErrorBox,
  LoadingOverlay,
  PageHeader,
  SiteSelect,
  StatusSelect,
  Summary,
  TimesheetsInfoAlert,
  UserRows,
} from './components';

const useStyles = makeStyles((theme) =>
  createStyles({
    userRows: {
      overflowX: 'auto',
      [theme.breakpoints.up('md')]: {
        overflowX: 'none',
      },
    },
  })
);

export function PageView() {
  const classes = useStyles();

  return (
    <BulkApproveContextProvider>
      <BulkDeleteContextProvider>
        <EditDialogContextProvider>
          <DeleteDialogContextProvider>
            <Layout.Styling background="white" />

            <Layout.Header>
              <PageHeader />
            </Layout.Header>

            <Layout.Content>
              <LoadingOverlay>
                <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }}>
                  <Box width={300} mb={2} mr={{ lg: 2 }}>
                    <SiteSelect />
                  </Box>

                  <Box width={300} mb={2} mr={{ lg: 2 }}>
                    <DateRangeSelect />
                  </Box>

                  <Box width={300} mb={2}>
                    <StatusSelect />
                  </Box>
                </Box>

                <ErrorBox mb={2} />

                <Box mb={3}>
                  <TimesheetsInfoAlert />
                </Box>

                <Box className={classes.userRows} mb={2}>
                  <Box mb={2}>
                    <Days />
                  </Box>

                  <UserRows />
                </Box>

                <Box display="flex">
                  <Summary />
                </Box>

                <CreateDialog />

                <EditDialog />

                <DeleteDialog />
              </LoadingOverlay>
            </Layout.Content>
          </DeleteDialogContextProvider>
        </EditDialogContextProvider>
      </BulkDeleteContextProvider>
    </BulkApproveContextProvider>
  );
}
