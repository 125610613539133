import { AlertErrorBoxLayout } from 'element';
import {
  Checkbox,
  FormItem,
  LayoutContextProps,
  Radio,
  RadioGroup,
  withLayoutAware,
} from 'elmo-elements';
import { getPreferenceLabel } from 'lib/helpers';
import { map } from 'lodash';
import nanoid from 'nanoid';
import {
  SettingsLayoutComponent,
  HelperTextWrapper,
} from 'page/Settings/components';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  BOX_ACCOUNT_CLEAR_ERRORS,
  BOX_ACCOUNT_UPDATE_REQUEST,
  getLangPreferences,
} from 'state/Account';
import { StoreState } from 'state/types';
import {
  CustomField,
  EventSettings,
  LanguagePreferences,
  Omit,
  StringMap,
} from 'type';
import { isAppMarket } from '../../../../helpers';
import { FormHelperText } from 'extended-oxygen-elements';

const weekviewSortByOptions = [
  {
    label: 'day',
    value: 'day',
  },
  {
    label: 'week',
    value: 'week',
  },
];

type Props = {
  events: EventSettings;
  updateAccount: (params: any) => void;
  errors: [];
  isUpdating: boolean;
  clearErrors: () => void;
  langPreferences: LanguagePreferences;
};

interface EventsState {
  isLogin: boolean;
  events: EventSettings & { custom_fields: []; weekview_sort_by: string };
  errors: {};
  isUpdating: boolean;
  watchFormErrors: boolean;
}

export class Events extends React.Component<
  LayoutContextProps & Props,
  EventsState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      isLogin: true,
      events: props.events,
      errors: {},
      isUpdating: false,
      watchFormErrors: false,
    };
  }

  componentWillUnmount(): void {
    this.props.clearErrors();
  }

  componentDidUpdate(
    prevProps: Readonly<LayoutContextProps & Props>,
    prevState: Readonly<EventsState>,
    snapshot?: any
  ): void {
    if (prevProps !== this.props) {
      if (this.props.errors.length) {
        this.setState({
          isUpdating: false,
        });
      }
    }
  }

  onChangeRadio = (value: string | number) => {
    this.setState((prevState) => ({
      ...prevState,
      events: {
        ...this.state.events,
      },
    }));
  };

  onChangeCheckbox = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { name, checked } = e.currentTarget;
    this.setState((prevState) => ({
      ...prevState,
      events: {
        ...this.state.events,
        [name]: checked,
      },
    }));
  };

  saveSettings = () => {
    this.setState({
      isUpdating: true,
    });
    this.props.updateAccount(this.serverRequestData);
  };

  render() {
    const { langPreferences } = this.props;
    const rosterLabel = isAppMarket('uk') ? 'rota' : 'roster';
    return (
      <SettingsLayoutComponent
        loading={this.state.isUpdating}
        onSubmit={this.saveSettings}
        title={getPreferenceLabel(langPreferences, 'event', 'plural', '', true)}
      >
        <AlertErrorBoxLayout
          errors={this.props.errors}
          onClose={this.props.clearErrors}
          BoxProps={{
            mb: 2,
          }}
        />

        <div>
          <FormItem isFluid={false}>
            <Checkbox
              name="enable_events"
              id="enable_events"
              label={`Enable forecasts & ${getPreferenceLabel(
                langPreferences,
                'event',
                'plural',
                '',
                false
              )}`}
              ariaLabel={`Enable forecasts & ${getPreferenceLabel(
                langPreferences,
                'event',
                'plural',
                '',
                false
              )}`}
              isChecked={this.state.events.enable_events}
              onChange={this.onChangeCheckbox}
            />
            <HelperTextWrapper>
              <FormHelperText fontSize="medium" sx={{ paddingLeft: '42px' }}>
                {`Forecasts and 
                ${getPreferenceLabel(
                  langPreferences,
                  'event',
                  'plural',
                  '',
                  false
                )} can be created within ${rosterLabel}.`}
              </FormHelperText>
            </HelperTextWrapper>
          </FormItem>
        </div>
        <div>
          <FormItem isFluid={false}>
            <Checkbox
              name="show_employee_associated_to"
              id="show_employee_associated_to"
              label={`Show people any ${getPreferenceLabel(
                langPreferences,
                'event',
                'plural',
                '',
                false
              )} they are assigned to`}
              ariaLabel={`Show people any ${getPreferenceLabel(
                langPreferences,
                'event',
                'plural',
                '',
                false
              )} they are assigned to`}
              isChecked={this.state.events.show_employee_associated_to}
              onChange={this.onChangeCheckbox}
            />
          </FormItem>
        </div>
        <div>
          <FormItem isFluid={false}>
            <Checkbox
              name="show_event_name_boxes"
              id="show_event_name_boxes"
              label={`Show
                  ${getPreferenceLabel(
                    langPreferences,
                    'event',
                    'singular',
                    '',
                    false
                  )}
                  name in ${getPreferenceLabel(
                    langPreferences,
                    rosterLabel,
                    'singular',
                    rosterLabel,
                    false
                  )}`}
              ariaLabel={`Show
                  ${getPreferenceLabel(
                    langPreferences,
                    'event',
                    'singular',
                    '',
                    false
                  )}
                  name in ${getPreferenceLabel(
                    langPreferences,
                    rosterLabel,
                    'singular',
                    rosterLabel,
                    false
                  )}`}
              isChecked={this.state.events.show_event_name_boxes}
              onChange={this.onChangeCheckbox}
            />
          </FormItem>
        </div>

        <div>
          <FormItem isFluid={false}>
            <Checkbox
              name="show_value"
              id="show_value"
              label={`Show ${getPreferenceLabel(
                langPreferences,
                'event',
                'singular',
                '',
                false
              )} value`}
              ariaLabel={`Show ${getPreferenceLabel(
                langPreferences,
                'event',
                'singular',
                '',
                false
              )} value`}
              isChecked={this.state.events.show_value}
              onChange={this.onChangeCheckbox}
            />
          </FormItem>
        </div>
        <div>
          <FormItem isFluid={false}>
            <Checkbox
              name="show_event_notes"
              id="show_event_notes"
              label={`Show ${getPreferenceLabel(
                langPreferences,
                'event',
                'singular',
                '',
                false
              )} notes`}
              ariaLabel={`Show ${getPreferenceLabel(
                langPreferences,
                'event',
                'singular',
                '',
                false
              )} notes`}
              isChecked={this.state.events.show_event_notes}
              onChange={this.onChangeCheckbox}
            />
          </FormItem>
        </div>
        <div>
          <FormItem isFluid={false}>
            <Checkbox
              name="show_event_start"
              id="show_event_start"
              label={`Show ${getPreferenceLabel(
                langPreferences,
                'event',
                'singular',
                '',
                false
              )} start time`}
              ariaLabel={`Show ${getPreferenceLabel(
                langPreferences,
                'event',
                'singular',
                '',
                false
              )} start time`}
              isChecked={this.state.events.show_event_start}
              onChange={this.onChangeCheckbox}
            />
          </FormItem>
        </div>
        <div>
          <FormItem isFluid={false}>
            <Checkbox
              name="show_event_finish"
              id="show_event_finish"
              label={`Show ${getPreferenceLabel(
                langPreferences,
                'event',
                'singular',
                '',
                false
              )} end time`}
              ariaLabel={`Show ${getPreferenceLabel(
                langPreferences,
                'event',
                'singular',
                '',
                false
              )} end time`}
              isChecked={this.state.events.show_event_finish}
              onChange={this.onChangeCheckbox}
            />
          </FormItem>
        </div>
        <div>
          <FormItem isFluid={false}>
            <Checkbox
              name="show_event_item"
              id="show_event_item"
              label={`Show ${getPreferenceLabel(
                langPreferences,
                'event',
                'singular',
                '',
                false
              )} #`}
              ariaLabel={`Show ${getPreferenceLabel(
                langPreferences,
                'event',
                'singular',
                '',
                false
              )} #`}
              isChecked={this.state.events.show_event_item}
              onChange={this.onChangeCheckbox}
            />
          </FormItem>
        </div>
        <div>
          <FormItem isFluid={false}>
            <Checkbox
              name="show_event_address"
              id="show_event_address"
              label={`Show ${getPreferenceLabel(
                langPreferences,
                'event',
                'singular',
                '',
                false
              )} address`}
              ariaLabel={`Show ${getPreferenceLabel(
                langPreferences,
                'event',
                'singular',
                '',
                false
              )} address`}
              isChecked={this.state.events.show_event_address}
              onChange={this.onChangeCheckbox}
            />
          </FormItem>
        </div>
        <div>
          <FormItem isFluid={false}>
            <Checkbox
              name="show_event_address_name"
              id="show_event_address_name"
              label={`Show ${getPreferenceLabel(
                langPreferences,
                'event',
                'singular',
                '',
                false
              )} address name`}
              ariaLabel={`Show ${getPreferenceLabel(
                langPreferences,
                'event',
                'singular',
                '',
                false
              )} address name`}
              isChecked={this.state.events.show_event_address_name}
              onChange={this.onChangeCheckbox}
            />
          </FormItem>
        </div>
        <div>
          <FormItem isFluid={false}>
            <Checkbox
              name="show_event_report_to"
              id="show_event_report_to"
              label={`Show ${getPreferenceLabel(
                langPreferences,
                'event',
                'singular',
                '',
                false
              )} report`}
              ariaLabel={`Show ${getPreferenceLabel(
                langPreferences,
                'event',
                'singular',
                '',
                false
              )} report`}
              isChecked={this.state.events.show_event_report_to}
              onChange={this.onChangeCheckbox}
            />
          </FormItem>
        </div>
      </SettingsLayoutComponent>
    );
  }

  private get serverRequestData() {
    const { custom_fields, weekview_sort_by, ...rest } = this.state.events;
    return {
      event_settings: {
        ...rest,
      },
    };
  }
}

const mapStateToProps = (state: StoreState) => ({
  events: state.account.account.event_settings,
  errors: state.account.errors,
  isUpdating: state.account.isUpdating,
  langPreferences: getLangPreferences(state),
});

export default connect(mapStateToProps, {
  updateAccount: BOX_ACCOUNT_UPDATE_REQUEST,
  clearErrors: BOX_ACCOUNT_CLEAR_ERRORS,
})(withLayoutAware(Events));
