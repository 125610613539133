import React, { ReactNode } from 'react';
import { getClass } from '../../_lib/helper';
import { ExpandMoreIcon } from '../../icons';
import { Button, Tooltip } from '../../main';

type DropdownButtonProps = {
  toggleMenu: () => void;
  ariaLabel?: string;
  text?: ReactNode;
  icon?: ReactNode;
  isDisabled?: boolean;
  id?: string;
  isBlock?: boolean;
  isFullWidth?: boolean;
  isBordered?: boolean;
  isOpenDropdown?: boolean;
  hasTooltip?: boolean;
};

export const DropdownButton = ({
  toggleMenu,
  ariaLabel,
  text,
  icon,
  isDisabled,
  id,
  isBlock,
  isFullWidth,
  isBordered,
  isOpenDropdown,
  hasTooltip,
}: DropdownButtonProps) => {
  const renderedIcon = icon ? icon : <ExpandMoreIcon />;

  const button = (
    <Button
      isText={!text}
      icon={text ? null : renderedIcon}
      className={getClass('elmo-dropdown__button', '', {
        open: isOpenDropdown,
        disabled: isDisabled,
        'full-width': isFullWidth,
        'has-border': isBordered,
      })}
      onClick={toggleMenu}
      isDisabled={isDisabled}
      isUppercase={false}
      data-testid={`elmo-dropdown-button-${id || 'default'}`}
      children={
        text ? (
          <>
            {text}
            <span className={'elmo-dropdown__button__icon'}>
              {renderedIcon}
            </span>
          </>
        ) : null
      }
      isBlock={isBlock}
      ariaHaspopup={true}
      ariaExpanded={isOpenDropdown}
      ariaLabel={ariaLabel}
    />
  );
  if (hasTooltip) {
    return (
      <Tooltip title={ariaLabel ? ariaLabel : ''} placement="bottom">
        {button}
      </Tooltip>
    );
  }
  return button;
};
