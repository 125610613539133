import React from 'react';

export function useNonNullableStaticState<S>(state: S) {
  const [staticState, setStaticState] = React.useState(state);

  const nonNullableState = !!state;
  React.useEffect(() => {
    if (nonNullableState) {
      setStaticState(state);
    }
  }, [nonNullableState]);

  return staticState;
}

export default useNonNullableStaticState;
