import { NotificationReducerState } from './type';

export const defaultState: NotificationReducerState = {
  isFetched: false,
  isFetching: false,
  notification: {
    group: {
      email: 'notify',
      label: '',
      sms: 'notify',
      tooltip: '',
    },
    notifications: {}
  },
  errors: [],
  editModal: {
    isOpened: false,
    isUpdating: false,
    errors: [],
    initEditor: false,
    uploadedFile: '',
    title: '',

  },
  timeModal: {
    isOpened: false,
    isUpdating: false,
    errors: []
  },
  message: {
    variables: [],
    email: '',
    sms: '',
    subject: '',
    editable: true,
    key: ''
  }
};
