import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  BOX_USER_APPROVALS_MODAL_GET_APPROVALS,
  BOX_USER_APPROVALS_MODAL_GET_APPROVALS_FAILURE,
  BOX_USER_APPROVALS_MODAL_GET_APPROVALS_REQUEST,
  BOX_USER_APPROVALS_MODAL_GET_APPROVALS_SUCCESS,
} from './actions';
import { SagaAction } from '../../../type';
import { isEqual, sortBy } from 'lodash';
import {
  UserGetApprovalsRequest,
  UserGetApprovalsResponse,
} from 'lib/Api/type';
import { processApiRequest } from '../../ProcessApiRequest/sagas';
import { Api } from 'lib/Api';
import { formatError } from '../../helpers';
import { getApprovalsIsFetched, getApprovalsUserIds } from './selectors';
import { GetApprovalsPayload } from './types';

const getApprovals = function* ({
  payload: { userIds },
}: SagaAction<GetApprovalsPayload>): SagaIterator {
  const isFetched: ReturnType<typeof getApprovalsIsFetched> = yield select(
    getApprovalsIsFetched
  );

  const sortedRequestedUserIds = sortBy(userIds);

  const fetchedUserIds: ReturnType<typeof getApprovalsUserIds> = yield select(
    getApprovalsUserIds
  );
  const sortedFetchedUserIds: string[] = sortBy(fetchedUserIds);

  if (!(isFetched && isEqual(sortedRequestedUserIds, sortedFetchedUserIds))) {
    yield put(BOX_USER_APPROVALS_MODAL_GET_APPROVALS_REQUEST());
    try {
      const apiPayload: UserGetApprovalsRequest = {
        user_ids: userIds,
      };

      const approvers: UserGetApprovalsResponse = yield call(
        processApiRequest,
        Api.User.getApprovals,
        apiPayload
      );

      yield put(
        BOX_USER_APPROVALS_MODAL_GET_APPROVALS_SUCCESS({
          userIds,
          approvers,
        })
      );
    } catch (error) {
      yield put(
        BOX_USER_APPROVALS_MODAL_GET_APPROVALS_FAILURE(formatError(error))
      );
    }
  }
};

export const watchUserApprovals = function*(): SagaIterator {
  yield takeLatest(BOX_USER_APPROVALS_MODAL_GET_APPROVALS, getApprovals);
};
