import { TextFieldProps } from 'extended-oxygen-elements';
import { minutesDurationGuard } from 'lib/guards';
import {
  DefaultUnit,
  formatDuration,
  FormatDurationOptions,
  parseValidStringToMinutes,
} from 'lib/helpers';
import React from 'react';
import { Minutes } from 'type';

type TextFieldPickedProps = Pick<
  TextFieldProps,
  'onChange' | 'onBlur' | 'onFocus' | 'onKeyDown'
>;

type OwnProps = {
  defaultUnit?: DefaultUnit;
};

type DurationFieldBaseProps = TextFieldPickedProps &
  FormatDurationOptions &
  OwnProps;

export type UseClearableDurationFieldProps = DurationFieldBaseProps & {
  value: Minutes | null;
  onValueChange: (value: Minutes | null) => void;
};

export function useClearableDurationField<
  P extends UseClearableDurationFieldProps
>({
  defaultUnit = 'minutes',
  formatUnit = 'minutes',
  hoursPrefix,
  minutesPrefix,
  value,
  onValueChange,
  ...restProps
}: P) {
  const [userInput, setUserInput] = React.useState('');
  const [isFocused, setIsFocused] = React.useState(false);

  const formattedValue = formatValue(value, {
    formatUnit,
    hoursPrefix,
    minutesPrefix,
  });

  React.useEffect(() => {
    setUserInput(formattedValue);
  }, [formattedValue]);

  const triggerValueChange = (updatedValue: Minutes | null) => {
    if (updatedValue !== value) {
      onValueChange(updatedValue);
    }

    if (userInput !== formattedValue) {
      setUserInput(formattedValue);
    }
  };

  const formatInput = () => {
    if (!userInput) {
      triggerValueChange(null);
    }

    try {
      const minutes = parseValidStringToMinutes(userInput, defaultUnit);

      triggerValueChange(minutes);
    } catch (error) {
      triggerValueChange(null);
    }
  };

  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.currentTarget?.select();
    setIsFocused(true);
    restProps.onFocus?.(event);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      formatInput();
    }
    restProps.onKeyDown?.(event);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(event.target.value);
    restProps.onChange?.(event);
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    formatInput();
    restProps.onBlur?.(event);
  };

  return {
    ...restProps,
    value: isFocused ? userInput : formattedValue,
    onFocus,
    onKeyDown,
    onChange,
    onBlur,
  };
}

export type UseDurationFieldProps = DurationFieldBaseProps & {
  value: Minutes;
  onValueChange: (value: Minutes) => void;
};

export function useDurationField({
  onValueChange,
  ...restProps
}: UseDurationFieldProps) {
  return useClearableDurationField({
    ...restProps,
    onValueChange: (value) => onValueChange(value !== null ? value : 0),
  });
}

function formatValue(value: Minutes | null, options: FormatDurationOptions) {
  if (value === null || !minutesDurationGuard(value)) {
    return '';
  }

  return formatDuration(value, options);
}

