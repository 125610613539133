import React from 'react';
import { connect } from 'react-redux';
import { AccountTreeSelector, UserRole } from 'type';
import { getFullUserRole } from 'lib/helpers';
import { StoreState } from 'state/types';
import { getAccountTree } from 'state/AccountTree';

type OwnProps = {
  role: UserRole;
};

type StateProps = {
  accountTree: AccountTreeSelector;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

const UserRoleFormattedComponent = ({ accountTree, role }: Props) => (
  <>
    {getFullUserRole({
      role,
      ...accountTree
    })}
  </>
);

const mapStateToProps = (state: StoreState): StateProps => ({
  accountTree: getAccountTree(state)
});

// const mapDispatchToProps: DispatchProps = {};

export const UserRoleFormatted = connect(mapStateToProps)(
  UserRoleFormattedComponent
);
