import { SagaIterator } from 'redux-saga';
import { outDatedDataMessage } from 'messages';
import {
  BackendPager,
  EventsPeriod,
  SagaAction,
  WorkingPeriod,
} from '../../../../type';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getFilters,
  getPager,
  getSelectedPeriod,
  payloadSelector,
} from '../selectors';
import {
  Api,
  EmployeeDashboardGetEventsResponse,
  GetEventsRequest,
} from 'lib/Api';
import * as actions from '../actions';
import * as fetchPageData from 'state/FetchPageData';
import { getFetchFlags } from 'state/FetchPageData';
import { EventsFilters } from '../types';
import { filtersToPayload } from '../helpers';
import { processApiRequest } from 'state/ProcessApiRequest';
import { formatError } from '../../../helpers';
import nanoid from 'nanoid';
import { showOutDatedNotification } from 'state/ToastNotifier';

export const fetchEvents = function* (payload: GetEventsRequest) {
  return yield call(
    processApiRequest,
    Api.EmployeeDashboard.getEvents,
    payload
  );
};

export const getEventsListRequest = function*(
  payload?: GetEventsRequest
): SagaIterator {
  const defaultPayload: GetEventsRequest = yield select(
    payloadSelector
  );

  const data: EmployeeDashboardGetEventsResponse = yield call(fetchEvents, {
    ...defaultPayload,
    ...payload
  });

  yield put(actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_GET_EVENTS_SUCCESS(data));
};

export const getData = function*(): SagaIterator {
  yield put(
    fetchPageData.BOX_FETCH_PAGE_DATA_REQUEST(
      fetchPageData.EMPLOYEE_DASHBOARD_EVENTS
    )
  );
  try {
    const filters: EventsFilters = yield select(getFilters);
    const { pageSize }: BackendPager = yield select(getPager);
    const period: WorkingPeriod = yield select(getSelectedPeriod);
    const payload = filtersToPayload(
      filters,
      period,
      { page: 1, pageSize },
    );

    yield all([call(getEventsListRequest, payload)]);

    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_SUCCESS(
        fetchPageData.EMPLOYEE_DASHBOARD_EVENTS
      )
    );
  } catch (error) {
    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_FAILURE(
        fetchPageData.EMPLOYEE_DASHBOARD_EVENTS
      )
    );
  }
};

export const changePage = function*({
  payload: page = 1
}: SagaAction<number>): SagaIterator {
  try {
    const defaultPayload: GetEventsRequest = yield select(
      payloadSelector
    );
    const filters: EventsFilters = yield select(getFilters);
    const period: string = yield select(getSelectedPeriod);
    const { pageSize }: BackendPager = yield select(getPager);
    const payload = filtersToPayload(
      filters,
      period,
      { page: 1, pageSize: pageSize },
    );

    const response: EmployeeDashboardGetEventsResponse = yield call(
      fetchEvents,
      {
        ...defaultPayload,
        ...payload,
        page
      }
    );
    yield put(
      actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_CHANGE_PAGE_SUCCESS(response)
    );
  } catch (error) {
    yield put(
      actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_CHANGE_PAGE_FAILURE(
        formatError(error)
      )
    );
  }
};

export const changePageSize = function*({
  payload: pageSize
}: SagaAction<number>): SagaIterator {
  try {
    const filters: EventsFilters = yield select(getFilters);
    const period: string = yield select(getSelectedPeriod);
    const payload = filtersToPayload(
      filters,
      period,
      { page: 1, pageSize }
    );

    const defaultPayload: GetEventsRequest = yield select(
      payloadSelector
    );

    const response: EmployeeDashboardGetEventsResponse = yield call(
      fetchEvents,
      {
        ...defaultPayload,
        ...payload,
        page: 1,
        per_page: pageSize
      }
    );

    yield put(
      actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_CHANGE_PAGE_SIZE_SUCCESS(response)
    );

  } catch (error) {
    yield put(
      actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_CHANGE_PAGE_SIZE_FAILURE(
        formatError(error)
      )
    );
  }
};

export const updateOrdering = function*(): SagaIterator {
  try {
    const filters: EventsFilters = yield select(getFilters);
    const { pageSize }: BackendPager = yield select(getPager);
    const period: string = yield select(getSelectedPeriod);
    const payload = filtersToPayload(
      filters,
      period,
      { page: 1, pageSize }
    );

    const response: EmployeeDashboardGetEventsResponse = yield call(fetchEvents, payload);

    yield put(
      actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_UPDATE_ORDERING_SUCCESS({
        filters,
        ...response
      })
    );
  } catch (error) {
   yield put(actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_UPDATE_ORDERING_FAILED(formatError(error)))
  }
};

export const togglePeriod = function*(): SagaIterator {
  try {
    const filters: EventsFilters = yield select(getFilters);
    const { pageSize }: BackendPager = yield select(getPager);
    const period: string = yield select(getSelectedPeriod);
    const payload = filtersToPayload(
      filters,
      period,
      { page: 1, pageSize }
    );

    const response: EmployeeDashboardGetEventsResponse = yield call(fetchEvents, payload);
    yield put(actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_RESET_FILTERS_FORM());
    yield put(
      actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_TOGGLE_PERIOD_TYPE_SUCCESS({
        filters,
        ...response
      })
    );
  } catch (error) {
    yield put(actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_TOGGLE_PERIOD_TYPE_FAILURE(formatError(error)))
  }
};

export const changeFilters = function*(): SagaIterator {
  try {
    const filters: EventsFilters = yield select(getFilters);
    const { pageSize }: BackendPager = yield select(getPager);
    const period: string = yield select(getSelectedPeriod);
    const payload = filtersToPayload(
      filters,
      period,
      { page: 1, pageSize }
    );

    const response: EmployeeDashboardGetEventsResponse = yield call(fetchEvents, payload);

    yield put(
      actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_UPDATE_FILTERS_SUCCESS({
        filters,
        ...response
      })
    );
  } catch (error) {
    yield put(
      actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_UPDATE_FILTERS_FAILURE(
        formatError(error)
      )
    );
  }
};

const isFetched = (params: ReturnType<typeof getFetchFlags>): boolean => {
  return params.isFetched && !params.isFetching;
};

export const showOutOfDateNotification = function* (): SagaIterator {

  const eventFlag: ReturnType<typeof getFetchFlags> = yield select(
    getFetchFlags,
    'EMPLOYEE_DASHBOARD_EVENTS'
  );

  if (isFetched(eventFlag)) {
    const notificationId = nanoid();
    const {undo} = yield call(showOutDatedNotification, {
      notificationId,
      message: outDatedDataMessage,
      undoText: 'Reload'
    });
    if (undo) {
      const period: EventsPeriod = yield select(getSelectedPeriod);
      yield put(actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_TOGGLE_PERIOD_TYPE_REQUEST(period));
    }
  }

};

export const watchEmployeeEvents = function*(): SagaIterator {
  yield takeLatest(actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_DATA_REQUEST, getData);
  yield takeLatest(
    actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_CHANGE_PAGE_REQUEST,
    changePage
  );
  yield takeLatest(
    actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_CHANGE_PAGE_SIZE_REQUEST,
    changePageSize
  );
  yield takeLatest(
    actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_TOGGLE_PERIOD_TYPE_REQUEST,
    togglePeriod
  );
  yield takeLatest(
    actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_UPDATE_FILTERS_REQUEST,
    changeFilters
  );
  yield takeLatest(
    actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_UPDATE_ORDERING_REQUEST,
    updateOrdering
  );

  yield takeLatest(
    actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_UPDATE,
    showOutOfDateNotification
  );
};
