import { useTimesheetsNewQuery } from 'hooks';
import { inputBaseClasses, MenuItem, TextField } from 'extended-oxygen-elements';
import React from 'react';
import {
  MapTupleToOption,
  TimesheetsNewStatus,
  TimesheetsNewStatusTuple,
} from 'type';

type TimesheetStatusOptions = MapTupleToOption<TimesheetsNewStatusTuple>;

const timesheetStatusOptions: TimesheetStatusOptions = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'approved',
    label: 'Approved',
  },
  {
    value: 'pending',
    label: 'Pending',
  },
  {
    value: 'in_progress',
    label: 'In progress',
  },
  {
    value: 'violated',
    label: 'With violations',
  },
];

export function StatusSelect() {
  const {
    query: { status },
    changeQueryItems,
  } = useTimesheetsNewQuery();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    changeQueryItems({ status: event.target.value as TimesheetsNewStatus });
  };

  return (
    <TextField
      label="Filter by status"
      select
      margin="none"
      value={status}
      onChange={handleChange}
      name="status_select"
      sx={(theme) => {
        const { font, padding } = theme.componentsConfig.button.large;
        return {
          [`& .${inputBaseClasses.input}`]: {
            lineHeight: font.lineHeight + 'px',
            paddingTop: padding.topBottom + 'px',
            paddingBottom: padding.topBottom + 'px',
          },
        };
      }}
    >
      {timesheetStatusOptions.map((statusOption) => (
        <MenuItem key={statusOption.value} value={statusOption.value}>
          {statusOption.label}
        </MenuItem>
      ))}
    </TextField>
  );
}
