import { useCallback, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { marketLabels } from 'marketLabels';
import { capitalize } from 'lib/helpers';
import { useActions } from 'hooks';
import { hasPermissionSelector } from 'state/Auth';
import {
  BOX_ROSTER_FILTERS_UPDATE_FILTERS_BY_TYPE,
  filtersByTypeSelector,
  RosterFiltersByType,
} from 'state/Roster/RosterFilters';
import { Option, Options } from '../types';

const filterOptions = (options: Option[], filters: RosterFiltersByType) =>
  options.filter((option) => filters[option.value]);

const optionsToFilters = (selectedOptions: Option[], value: boolean) =>
  selectedOptions.reduce<Partial<RosterFiltersByType>>(
    (accumulator, selectedOption) => {
      accumulator[selectedOption.value] = value;

      return accumulator;
    },
    {}
  );

const allOptions: Options = [
  {
    value: 'unavailability',
    label: 'Unavailability',
  },
  {
    value: 'roster',
    label: capitalize(marketLabels.roster),
  },
  {
    value: 'timesheets',
    label: 'Timesheets',
  },
];

const disabledFilters: RosterFiltersByType = {
  unavailability: false,
  roster: false,
  timesheets: false,
};

export const useOptions = () => {
  const updateFilters = useActions(BOX_ROSTER_FILTERS_UPDATE_FILTERS_BY_TYPE);
  const filtersByType = useSelector(filtersByTypeSelector);

  const permissions = useSelector((state) => {
    const roster = hasPermissionSelector(state, 'roster.rosteredshift.view');
    const timesheets = hasPermissionSelector(state, 'roster.timesheet.view');

    return { unavailability: true, roster, timesheets };
  }, shallowEqual);

  const options = useMemo(
    () => filterOptions(allOptions, permissions),
    [permissions.roster, permissions.timesheets]
  );
  const selectedOptions = useMemo(
    () => filterOptions(options, filtersByType),
    [options, filtersByType]
  );

  const handleOptionsChange = useCallback((changedOptions: Option[]) => {
    const updatedFilters = {
      ...disabledFilters,
      ...optionsToFilters(changedOptions, true),
    };
    updateFilters(updatedFilters);
  }, []);

  return { options, selectedOptions, handleOptionsChange };
};
