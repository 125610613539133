import React from 'react';
import { connect } from 'react-redux';
import { BOX_SHIFT_TIME_RANGE_INPUT_LEGEND_OPENED } from 'state/Roster/RangeInput/ShiftTimeRangeInputLegend';
import { Omit } from 'type';
import {
  InputWithLegendModal,
  ShiftTimeRangeInput,
  ShiftTimeRangeInputProps,
} from './components';

export type OwnProps<P> = Omit<ShiftTimeRangeInputProps<P>, 'onIconClick' | 'id'>;

export type StateProps = {};

export type DispatchProps = Required<
  Pick<ShiftTimeRangeInputProps<any>, 'onIconClick'>
  >;

type Props<P> = OwnProps<P> & StateProps & DispatchProps;

export const InlineInputWithLegendComponent = <P extends any>(props: Props<P>) => (
  <>
    <ShiftTimeRangeInput {...props} id="roster-time-range-input" />
    {/*<InputWithLegendModal />*/}
  </>
);

// const mapStateToProps = (state: StoreState): StateProps => ({});

const mapDispatchToProps: DispatchProps = {
  onIconClick: BOX_SHIFT_TIME_RANGE_INPUT_LEGEND_OPENED
};

export const InlineInputWithLegend = connect(
  null,
  mapDispatchToProps
)(InlineInputWithLegendComponent);
