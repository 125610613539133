import React from 'react';
import { useTheme } from 'extended-oxygen-elements';

type WithThemeBackgroundProps = {
  children: (backgroundColor: string | undefined) => React.ReactElement;
};

function WithThemeBackground({ children }: WithThemeBackgroundProps) {
  const backgroundColor = useTheme().palette.background.default;
  return children(backgroundColor);
}

export default WithThemeBackground;
