import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Api from 'lib/Api';
import { processApiRequest } from '../ProcessApiRequest/sagas';
import { SagaAction } from '../../type';
import {
  BOX_NOTIFICATIONS_LIST_BULK_FAILURE,
  BOX_NOTIFICATIONS_LIST_BULK_SUCCESS,
  BOX_NOTIFICATIONS_LIST_DELETE_ALL,
  BOX_NOTIFICATIONS_LIST_DELETE_ALL_SUCCESS,
  BOX_NOTIFICATIONS_LIST_DELETE_ITEM,
  BOX_NOTIFICATIONS_LIST_DELETE_ITEM_FAILURE,
  BOX_NOTIFICATIONS_LIST_DELETE_ITEM_SUCCESS,
  BOX_NOTIFICATIONS_LIST_GET_REQUEST,
  BOX_NOTIFICATIONS_LIST_GET_REQUEST_FAILURE,
  BOX_NOTIFICATIONS_LIST_GET_REQUEST_SUCCESS,
  BOX_NOTIFICATIONS_LIST_MARK_READ,
  BOX_NOTIFICATIONS_LIST_MARK_READ_FAILURE,
  BOX_NOTIFICATIONS_LIST_MARK_READ_SUCCESS,
  BOX_NOTIFICATIONS_LIST_READ_ALL,
  BOX_NOTIFICATIONS_LIST_READ_ALL_SUCCESS,
} from './actions';
import { formatError } from '../helpers';
import {
  NotificationDeletePayload,
  NotificationListType,
  NotificationMarkReadPayload,
  NotificationsListRequestPayload,
  NotificationsListResponse,
} from './types';
import {
  getEmployeeNotificationsMeta,
  getManagerNotificationsMeta,
} from './selectors';

export const getNotificationsList = function* ({
  payload,
}: SagaAction<NotificationsListRequestPayload>): SagaIterator {
  try {
    const response: NotificationsListResponse = yield call(
      processApiRequest,
      Api.NotificationsList.getList,
      payload
    );
    yield put(
      BOX_NOTIFICATIONS_LIST_GET_REQUEST_SUCCESS({
        data: response.data,
        meta: response.meta,
        type: payload.type,
      })
    );
  } catch (e) {
    yield put(BOX_NOTIFICATIONS_LIST_GET_REQUEST_FAILURE(formatError(e)));
  }
};

export const markNotificationAsRead = function*({
  payload
}: SagaAction<NotificationMarkReadPayload>): SagaIterator {
  try {
    const response: any = yield call(processApiRequest, Api.NotificationsList.markNotification, payload.id);
    yield put(BOX_NOTIFICATIONS_LIST_MARK_READ_SUCCESS({
      id: payload.id,
      type: payload.type,
      read_at: response.read_at
    }));
  } catch (e) {
    yield put(BOX_NOTIFICATIONS_LIST_MARK_READ_FAILURE(formatError(e)));
  }
};

export const deleteNotificationItem = function*({
  payload
}: SagaAction<NotificationDeletePayload>): SagaIterator {
  try {

    yield call(processApiRequest, Api.NotificationsList.deleteNotification, payload.id);

    const meta = yield payload.type === 'employee'
      ? select(getEmployeeNotificationsMeta)
      : select(getManagerNotificationsMeta);

    yield put(BOX_NOTIFICATIONS_LIST_DELETE_ITEM_SUCCESS({
      id: payload.id,
      type: payload.type
    }));

    yield put(BOX_NOTIFICATIONS_LIST_GET_REQUEST({
      page: meta.current_page,
      perPage: meta.per_page,
      type: payload.type
    }));

  } catch (e) {
    yield put(BOX_NOTIFICATIONS_LIST_DELETE_ITEM_FAILURE(formatError(e)));
  }
};

const readAll = function*({
  payload
}: SagaAction<NotificationListType>) {
  try {
    yield call(processApiRequest, Api.NotificationsList.readAll, payload);
    yield put(BOX_NOTIFICATIONS_LIST_READ_ALL_SUCCESS(payload));
    yield put(BOX_NOTIFICATIONS_LIST_BULK_SUCCESS());
  } catch (errors) {
    yield put(BOX_NOTIFICATIONS_LIST_BULK_FAILURE(formatError(errors)));
  }
};

const deleteAll = function*({
  payload
}: SagaAction<NotificationListType>) {
  try {
    yield call(processApiRequest, Api.NotificationsList.deleteAll, payload);
    yield put(BOX_NOTIFICATIONS_LIST_DELETE_ALL_SUCCESS(payload));
    yield put(BOX_NOTIFICATIONS_LIST_BULK_SUCCESS());
  } catch (errors) {
    yield put(BOX_NOTIFICATIONS_LIST_BULK_FAILURE(formatError(errors)));
  }
};

export const watchNotificationsList = function*(): SagaIterator {
  yield takeLatest(BOX_NOTIFICATIONS_LIST_GET_REQUEST, getNotificationsList);
  yield takeLatest(BOX_NOTIFICATIONS_LIST_MARK_READ, markNotificationAsRead);
  yield takeLatest(BOX_NOTIFICATIONS_LIST_DELETE_ITEM, deleteNotificationItem);
  yield takeLatest(BOX_NOTIFICATIONS_LIST_READ_ALL, readAll);
  yield takeLatest(BOX_NOTIFICATIONS_LIST_DELETE_ALL, deleteAll);
};
