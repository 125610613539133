import React from 'react';
import { ReportType } from 'type';
import { Dictionary } from 'ts-essentials';
import {
  AttachMoneyIcon,
  BubbleChartOutlinedIcon,
  CompareArrowsIcon,
  DateRangeIcon,
  EventSeatOutlinedIcon,
  HelpOutlineIcon,
  LabelOutlinedIcon,
  MonetizationOnOutlinedIcon,
  SnoozeIcon,
  TableChartOutlinedIcon,
} from 'element';

type Props = {
  reportType: ReportType;
};

const reportIcons: Dictionary<React.ComponentType, ReportType> = {
  rostered_shift: DateRangeIcon,
  timesheet: TableChartOutlinedIcon,
  payroll: MonetizationOnOutlinedIcon,
  sale: AttachMoneyIcon,
  late: SnoozeIcon,
  no_show: HelpOutlineIcon,
  event: EventSeatOutlinedIcon,
  coverage: BubbleChartOutlinedIcon,
  transfer: CompareArrowsIcon,
  tag: LabelOutlinedIcon,
  roster: DateRangeIcon
};

export const ReportIcon = ({ reportType }: Props) => {
  const IconComponent = reportIcons[reportType];
  return <IconComponent />;
};

export default ReportIcon;
