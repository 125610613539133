import { Duration } from 'lib/helpers';
import { Dictionary } from 'ts-essentials';

export type DefaultUnit = 'minutes' | 'hours';

const parseByUnit: Dictionary<(value: number) => number> = {
  minutes: (minutes: number) => minutes,
  hours: (hours: number) => new Duration({ hours }).asMinutes,
};

export function parseValidStringToMinutes(
  value: string,
  defaultUnit: DefaultUnit = 'minutes'
): number | never {
  const time = value.replace(/ /i, '').split('h');
  let valueInMin;

  if (value.indexOf('m') !== -1 && value.indexOf('h') !== -1) {
    if (time.length) {
      const hours = parseInt(time[0], 10);
      const minutes = parseInt(time[1], 10);

      valueInMin = new Duration({ hours, minutes }).asMinutes;
    }
  } else if (value.indexOf('m') === -1 && value.indexOf('h') !== -1) {
    valueInMin = new Duration({ hours: parseFloat(value) }).asMinutes;
  } else if (value.indexOf('m') !== -1 && value.indexOf('h') === -1) {
    valueInMin = parseInt(value, 10);
  } else {
    const parsedDuration = parseInt(value, 10);
    if (!isNaN(parsedDuration)) {
      valueInMin = parseByUnit[defaultUnit](parsedDuration);
    }
  }

  if (typeof valueInMin === 'number' && valueInMin >= 0) {
    return valueInMin;
  }

  throw new Error('parseValidStringToMinutes/invalid input');
}

export function parseToMinutes(value: string): number {
  try {
    return parseValidStringToMinutes(value);
  } catch (error) {
    return 0;
  }
}
