import { createReducer } from 'lib/store-utils';
import { omit, without } from 'lodash';
import { ApproveDeclinePayload, ShiftSwapsReducerState } from './type';
import { getDefaultState } from './state';
import * as actions from './actions';

export const shiftSwaps = createReducer<ShiftSwapsReducerState>(
  {},
  getDefaultState()
);

shiftSwaps.on(actions.BOX_SHIFT_SWAPS_DATA_REQUEST, getDefaultState);

shiftSwaps.on(
  actions.BOX_SHIFT_SWAPS_GET_SHIFT_SWAPS_SUCCESS,
  (state, swaps): ShiftSwapsReducerState => ({
    ...state,
    swaps
  })
);

shiftSwaps.on(
  actions.BOX_SHIFT_SWAPS_UPDATE_SWAP,
  (state, swap): ShiftSwapsReducerState => ({
    ...state,
    swaps: {
      ...state.swaps,
      [swap.id]: swap
    }
  })
);

shiftSwaps.on(
  actions.BOX_SHIFT_SWAPS_DELETE_SWAP,
  (state, { id }): ShiftSwapsReducerState => ({
    ...state,
    swaps: omit(state.swaps, id)
  })
);

const requestHandler = (
  state: ShiftSwapsReducerState,
  { id }: ApproveDeclinePayload
): ShiftSwapsReducerState => ({
  ...state,
  undoQueue: [...state.undoQueue, id]
});

const successHandler = (
  state: ShiftSwapsReducerState,
  { id }: ApproveDeclinePayload
): ShiftSwapsReducerState => ({
  ...state,
  undoQueue: without(state.undoQueue, id),
  swaps: omit(state.swaps, id)
});

const finishedHandler = (
  state: ShiftSwapsReducerState,
  { id }: ApproveDeclinePayload
): ShiftSwapsReducerState => ({
  ...state,
  undoQueue: without(state.undoQueue, id)
});

shiftSwaps.on(actions.BOX_SHIFT_SWAPS_APPROVE_REQUEST, requestHandler);
shiftSwaps.on(actions.BOX_SHIFT_SWAPS_APPROVE_SUCCESS, successHandler);
shiftSwaps.on(actions.BOX_SHIFT_SWAPS_APPROVE_FINISHED, finishedHandler);
shiftSwaps.on(actions.BOX_SHIFT_SWAPS_DECLINE_REQUEST, requestHandler);
shiftSwaps.on(actions.BOX_SHIFT_SWAPS_DECLINE_SUCCESS, successHandler);
shiftSwaps.on(actions.BOX_SHIFT_SWAPS_DECLINE_FINISHED, finishedHandler);
