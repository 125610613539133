import { fill, slice } from 'lodash';

export const fillTolMinLength = <T>(
  initArray: T[],
  defaultItem: T,
  minLength: number
): T[] => {
  if (initArray.length < minLength) {
    const defaultItems: T[] = fill(Array(minLength), defaultItem);

    return slice([...initArray, ...defaultItems], 0, minLength);
  }

  return [...initArray];
};
