import React, { Component }  from 'react';
import { Notification } from 'elmo-elements';
import { StoreState } from 'state/types';
import {
  BOX_REPORT_SAVE_AS_REPORT_RESTORE
} from 'state/Report';
import { connect } from 'react-redux';
import { Report } from 'type/models/report';

type NotificationType = {
  text: string
};

type StateProps = {
  report: Report;
  isSavedAsSuccess: boolean;
  isSaveSuccess: boolean;
};

type DispatchProps = {
  restoreIsSaveAs: () => void;
};

type State = {
  notification: NotificationType;
  isActive: boolean;
};

type Props = DispatchProps & StateProps;

class SaveReportNotification extends Component<Props, State> {
  timeout: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      isActive: props.isSaveSuccess || props.isSavedAsSuccess,
      notification: this.getNotificationMsg()
    };
  }

  getNotificationMsg = () => {
    return {
      text: `Report saved.`
    };
  }

  showSaveSuccessNotification = () => {
    this.timeout = setTimeout(
      () => {
        this.setState(
          {
            isActive: false
          },
          () => {
            this.props.restoreIsSaveAs();
          }
        );
      },
      3000
    );
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    const isVisible = this.props.isSavedAsSuccess || this.props.isSaveSuccess;
    const { isActive } = this.state;
    if ( prevProps !== this.props && isVisible !== isActive ) {
      this.setState(
        {
          isActive: isVisible
        },
        () => {
          if ( isVisible ) {
            this.showSaveSuccessNotification();
          }
        }
      );
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  hideNotification = () => {
    this.setState({
      isActive: false
    });
  }

  render() {
    const { notification, isActive } = this.state;
    return (
      <Notification
        isActive={isActive}
        message={notification.text}
        onClose={this.hideNotification}
      />
    );
  }
}

const mapStateToProps = (state: StoreState) => ({
  report: state.report.report,
  isSavedAsSuccess: state.report.isSavedAsSuccess,
  isSaveSuccess: state.report.isSavedSuccess
});

const mapDispatchToProps: DispatchProps = {
  restoreIsSaveAs: BOX_REPORT_SAVE_AS_REPORT_RESTORE
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SaveReportNotification);