import moment from 'moment';
import { MonthDays, Omit, ReportScheduleDay } from 'type';
import { Props, State, FormData } from '../types';
import { roundTimeToMin } from '../config';

export const getState = (props: Props): State => {
  const today = moment();
  // round minutes:
  const diff = roundTimeToMin - (today.minute() % roundTimeToMin);
  today.add(diff, 'minute');

  const days = [today.format('E') as ReportScheduleDay];
  const todayMonthDay = today.date();
  const dailyDay = [
    '1' as '1',
    '2' as '2',
    '3' as '3',
    '4' as '4',
    '5' as '5',
    '6' as '6',
    '7' as '7'
  ];

  const todayMonthDayValue: MonthDays =
    todayMonthDay > 27 ? 'last_day' : (`${todayMonthDay}` as MonthDays);

  const partialState: Omit<State, 'data'> = {
    isValid: false,
    errors: {}
  };

  const { defaultValues } = props;
  if (defaultValues) {
    const { schedule, to, time, ...rest } = defaultValues;

    const partialData: Omit<
      FormData,
      'weekly_day' | 'fortnightly_day' | 'monthly_day'
    > = {
      ...rest,
      time: time ? time : today,
      to: to,
      ongoing: !to,
      schedule: schedule.period,
      daily_day: dailyDay,
    };

    if (schedule.period === 'daily') {
      return {
        data: {
          ...partialData,
          weekly_day: days,
          fortnightly_day: days,
          monthly_day: [todayMonthDayValue]
        },
        ...partialState
      };
    } else if (schedule.period === 'weekly') {
      return {
        data: {
          ...partialData,
          weekly_day: schedule.day,
          fortnightly_day: days,
          monthly_day: [todayMonthDayValue]
        },
        ...partialState
      };
    } else if (schedule.period === 'fortnightly') {
      return {
        data: {
          ...partialData,
          weekly_day: days,
          fortnightly_day: schedule.day,
          monthly_day: [todayMonthDayValue]
        },
        ...partialState
      };
    }

    return {
      data: {
        ...partialData,
        weekly_day: days,
        fortnightly_day: days,
        monthly_day: schedule.day
      },
      ...partialState
    };
  } else {
    return {
      data: {
        is_enabled: false,
        schedule: 'daily',
        ongoing: true,
        daily_day: dailyDay,
        weekly_day: days,
        fortnightly_day: days,
        monthly_day: [todayMonthDayValue],
        time: today,
        format: 'pdf',
        from: today,
        to: null,
        recipients: [],
        sub_format: null
      },
      ...partialState
    };
  }
};
