import React from 'react';
import { MessageBlock } from 'elmo-elements';

export const ShiftOfferEmptyMessage = () => (
  <MessageBlock>
    <MessageBlock.Header>There are no responses</MessageBlock.Header>
  </MessageBlock>
);

ShiftOfferEmptyMessage.displayName = 'ShiftOfferEmptyMessage';
