import { useBulkApproveContext } from 'contexts';
import { AlertErrorBoxDialog } from 'element';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'extended-oxygen-elements';
import { useSelectorWithProps, useUpdateEffect } from 'hooks';
import {
  altConfirmationModalTitle,
  approveTimesheets,
  confirmationModalBtnLabel,
} from 'messages';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BOX_TIMESHEETS_WEEKLY_DIALOG_ERRORS_CLOSED,
  BOX_TIMESHEETS_WEEKLY_TIMESHEETS_APPROVE,
  getDialogErrors,
  getDialogRequestStatus,
  getDialogUpdating,
} from 'state/TimesheetsWeeklyPage';

type DeleteDialogProps = {
  open: boolean;
  onClose: () => void;
};

export function ApproveDialog({ open, onClose }: DeleteDialogProps) {
  const { selectedIds } = useBulkApproveContext();
  const dispatch = useDispatch();
  const loading = useSelectorWithProps(getDialogUpdating);
  const errors = useSelector(getDialogErrors);
  const selectedQuantity = selectedIds.length;
  const requestStatus = useSelector(getDialogRequestStatus);

  useUpdateEffect(() => {
    if (requestStatus === 'success' || requestStatus === 'cancel') {
      onClose();
    }
  }, [requestStatus]);

  const closeDialog = () => {
    if (loading) {
      return;
    }

    onClose();
  };

  const handleErrorsClose = () => {
    dispatch(BOX_TIMESHEETS_WEEKLY_DIALOG_ERRORS_CLOSED());
  };

  const handleConfirmButtonCLick = () => {
    if (loading) {
      return;
    }

    dispatch(BOX_TIMESHEETS_WEEKLY_TIMESHEETS_APPROVE.request({ selectedIds }));
  };

  return (
    <Dialog open={open} onClose={closeDialog} data-testid="bulk-approve-modal">
      <DialogTitle>{altConfirmationModalTitle}</DialogTitle>

      <DialogContent>
        <AlertErrorBoxDialog
          BoxProps={{
            mb: 2,
          }}
          errors={errors}
          onClose={handleErrorsClose}
        />

        <DialogContentText data-testid={'bulk-approve-modal-content'}>
          {approveTimesheets(selectedQuantity)}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          disabled={loading}
          onClick={closeDialog}
          data-testid={'bulk-approve-modal-cancel-btn'}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          loading={loading}
          disableRipple={loading}
          onClick={handleConfirmButtonCLick}
          data-testid={'bulk-approve-modal-confirm-btn'}
        >
          {confirmationModalBtnLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
