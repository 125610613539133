import { ServerUserCurrent } from 'type';

export const createCurrentUser = (): ServerUserCurrent => ({
  id: '',
  permissions: [],
  managers: {
    leave: null,
    shift_offer: null,
    shift_swap: null,
  },
  user_roles: [],
  avatar: null,
  avatar_url: null,
  casual_loading: '',
  created_at: '',
  date_of_birth: null,
  deactivated_at: null,
  email: '',
  employment_type: 'Full time',
  end_date: null,
  external_id: null,
  first_name: '',
  last_name: '',
  mobile: '',
  payroll_number: '',
  prefered_name: '',
  gender: '',
  is_active: true,
  rate: '',
  roster_period: '',
  hr_uuid: '',
  roster_rules: {
    max_long_hour_shifts_per_roster_period: 0,
    max_consecutive_long_hour_shifts: 0,
    max_shift_duration: 0,
    min_break_between_shifts: 0,
    min_shift_duration: 0,
  },
  ordinary_hours_of_work: {
    max_hrs_per_day: 0,
    max_hrs_per_roster_period: 0,
    min_hrs_per_day: 0,
    min_hrs_per_roster_period: 0,
  },
  shift_worker: false,
  start_date: '',
  updated_at: '',
  tags: [],
  username: '',
  user_group_id: 0,
  rating: 0,
  payroll_site_id: null,
  payroll_area_id: null,
  abbreviation: null,
  notification_settings: [],
  higher_duties: [],
  preferences: {
    roster_view_settings: {},
  },
  is_payroll: false,
});
