import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Status } from 'state/Status';
import { StoreState } from 'state/types';
import { SafeDictionary } from 'ts-essentials';
import useUpdateEffect from './useUpdateEffect';

type StatusSelector = (state: StoreState) => Status | undefined;
type HandlersKeys = NonNullable<ReturnType<StatusSelector>>;

export type UpdateStatusEffectHandlers = SafeDictionary<() => void, HandlersKeys>;

export default useUpdateStatusEffect;

export function useUpdateStatusEffect(
  statusSelector: StatusSelector,
  handlers: UpdateStatusEffectHandlers
) {
  const status = useSelector(statusSelector);

  useUpdateEffect(() => {
    _.forEach(handlers, (handler, handlerKey) => {
      if ((handlerKey as HandlersKeys) === status) {
        handler!();
      }
    });
  }, [status]);
}
