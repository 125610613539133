import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Moment } from 'moment';
import {
  Button,
  ButtonCard,
  Col,
  FormContainer,
  FormItem,
  Input,
  LoadingOverlay,
  Radio,
  RadioButtons,
  Row,
  Select,
  Text,
  TextArea,
} from 'elmo-elements';
import { LocationOnOutlinedIcon, MoreHorizOutlinedIcon } from 'element/icons';
import { getSites } from 'state/AccountTree';
import {
  AccountTreeSite,
  Event,
  LanguagePreferences,
  PreferencesCurrencyCode,
  PreferencesCurrencyPlacement,
  PreferencesDateFormat,
  PreferencesNumberFormat,
  PreferencesTimeFormat,
  RosteredShift,
  ServerUserFields,
  StringMap,
} from 'type';
import {
  getCurrencyFormatted,
  getPreferenceLabel,
  getUserName,
} from 'lib/helpers';
import { StoreState } from 'state/types';
import { getUserListResponse } from 'state/UsersCollection';
import {
  getEventModalShifts,
  getIsLoadingShifts,
} from 'state/Roster/EventModal';
import {
  getCurrencyCode,
  getCurrencyPlacement,
  getDateFormat,
  getLangPreferences,
  getNumberFormat,
  getTimeFormat,
} from 'state/Account';
import WithAccountPreferences from '../../../../WithAccountPreferences';
import { Location } from '../../types';
import EventAddress from '../EventAddress';
import EventPeriod from '../EventPeriod/EventPeriod';
import ForecastPeriod from '../EventPeriod/ForecastPeriod';

type Props = {
  event: Event;
  users: StringMap<ServerUserFields>;
  openSubModal: (name: string) => void;
  setEventProps: (name: string, value: any) => void;
  onChangePeriod: (payload: { start: Moment; end: Moment }) => void;
  shifts: StringMap<RosteredShift>;
  setEventLocation: (location: Location) => void;
  langPreferences: LanguagePreferences;
  dateFormat: PreferencesDateFormat;
  timeFormat: PreferencesTimeFormat;
  numberFormat: PreferencesNumberFormat;
  currencyCode: PreferencesCurrencyCode;
  currencyPlacement: PreferencesCurrencyPlacement;
  sites: StringMap<AccountTreeSite>;
  isLoadingShifts: boolean;
};

export type State = {
  dateGroup: boolean;
  breakGroup: boolean;
  moreOptionsVisible: boolean;
  forecastValue: string;
  actualValue: string;
};

export class EventEditForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dateGroup: false,
      breakGroup: false,
      moreOptionsVisible: false,
      forecastValue: getCurrencyFormatted(
        props.numberFormat,
        props.currencyCode,
        props.currencyPlacement,
        +props.event.value
      ),
      actualValue: getCurrencyFormatted(
        props.numberFormat,
        props.currencyCode,
        props.currencyPlacement,
        +props.event.actual_value
      ),
    };
  }

  componentDidUpdate(prevProps: Props): void {
    if (
      this.props.event.id !== prevProps.event.id ||
      prevProps.timeFormat !== this.props.timeFormat ||
      prevProps.currencyPlacement !== this.props.currencyPlacement ||
      prevProps.currencyCode !== this.props.currencyCode ||
      prevProps.numberFormat !== this.props.numberFormat
    ) {
      this.setState({
        ...this.state,
        forecastValue: getCurrencyFormatted(
          this.props.numberFormat,
          this.props.currencyCode,
          this.props.currencyPlacement,
          +this.props.event.value
        ),
        actualValue: getCurrencyFormatted(
          this.props.numberFormat,
          this.props.currencyCode,
          this.props.currencyPlacement,
          +this.props.event.actual_value
        ),
      });
    }
  }
  render() {
    const {
      event,
      langPreferences,
      numberFormat,
      currencyCode,
      currencyPlacement,
    } = this.props;

    return (
      <WithAccountPreferences>
        <div className="shift-modal-inner spacing-top-extra-large spacing-bottom-large">
          <FormItem>
            <RadioButtons
              id="event-edit-form-event-type"
              selected={event.type || 'forecast'}
              onChange={(value) => {
                this.props.setEventProps('type', value);
              }}
              type="outlined"
            >
              <Radio value="forecast" id="event-edit-form-event-type-forecast">
                Forecast
              </Radio>
              <Radio value="event" id="event-edit-form-event-type-event">
                {getPreferenceLabel(
                  langPreferences,
                  'event',
                  'singular',
                  '',
                  true
                )}
              </Radio>
            </RadioButtons>
          </FormItem>
        </div>
        <div className="shift-modal-inner spacing-bottom-large">
          <FormItem label="Title">
            <Input
              id="event-edit-form-event-title"
              value={event.name || ''}
              ariaLabel="Title"
              onChange={(e) => {
                this.props.setEventProps('name', e.currentTarget.value);
              }}
            />
          </FormItem>
        </div>
        <div className="shift-modal-inner spacing-bottom-large">
          {event.type === 'event' && (
            <FormItem label={'User'}>
              <LoadingOverlay isLoading={this.props.isLoadingShifts}>
                <ButtonCard
                  id="event-edit-form-btn-user"
                  className={'user-btn mb-0'}
                  title={this.getUserName()}
                  onClick={() => this.props.openSubModal('users')}
                  tabIndex={0}
                />
              </LoadingOverlay>
            </FormItem>
          )}
        </div>

        {this.dateGroup()}

        <div className="rows-container">
          <div className="shift-modal-inner spacing-bottom-large">
            <Row role="presentation">
              <Col
                isAuto={true}
                span={12}
                className={'pl-4'}
                role="presentation"
              >
                <FormItem label="Forecast">
                  <Input
                    id="event-edit-form-input-forecast-value"
                    htmlType="text"
                    name={'forecast_value'}
                    value={this.state.forecastValue}
                    ariaLabel="Forecast"
                    onFocus={() => {
                      this.setState({
                        forecastValue: event.value,
                      });
                    }}
                    onBlur={() => {
                      this.setState({
                        forecastValue: getCurrencyFormatted(
                          numberFormat,
                          currencyCode,
                          currencyPlacement,
                          +event.value
                        ),
                      });
                    }}
                    onChange={(e) => {
                      let value = this.numberFormat(e.currentTarget.value);
                      this.setState({
                        forecastValue: value,
                      });
                      this.props.setEventProps('value', +value);
                    }}
                  />
                </FormItem>
              </Col>
              <Col
                isAuto={true}
                span={12}
                className={'pr-4'}
                role="presentation"
              >
                <FormItem label="Actual">
                  <Input
                    name={'actual_value'}
                    id="event-edit-form-input-actual-value"
                    htmlType="text"
                    value={this.state.actualValue}
                    ariaLabel="Actual"
                    onFocus={() => {
                      this.setState({
                        actualValue: event.actual_value,
                      });
                    }}
                    onBlur={() => {
                      this.setState({
                        actualValue: getCurrencyFormatted(
                          numberFormat,
                          currencyCode,
                          currencyPlacement,
                          +event.actual_value
                        ),
                      });
                    }}
                    onChange={(e) => {
                      let value = this.numberFormat(e.currentTarget.value);
                      this.setState({
                        actualValue: value,
                      });
                      this.props.setEventProps('actual_value', +value);
                    }}
                  />
                </FormItem>
              </Col>
            </Row>
          </div>
        </div>

        {!this.state.moreOptionsVisible && (
          <div className="shift-modal-inner spacing-bottom-large">
            <FormItem>
              <Button
                className={'pointer primary-color'}
                onClick={this.showMore}
                id="event-edit-form-link-more-options"
                icon={<MoreHorizOutlinedIcon />}
                isText={true}
                isUppercase={false}
              >
                <Text color={'primary'}>
                  <span className={'more-options'}>More options</span>
                </Text>
              </Button>
            </FormItem>
          </div>
        )}
        {this.state.moreOptionsVisible && (
          <div className="shift-modal-inner">
            {event.type === 'event' && (
              <>
                <div className="shift-modal-inner spacing-bottom-large">
                  <FormItem
                    id="event-edit-form-form-item-event-item"
                    label={`${getPreferenceLabel(
                      langPreferences,
                      'event',
                      'singular',
                      '',
                      true
                    )} #`}
                  >
                    <Input
                      id="event-edit-form-input-event-item"
                      value={event.item || ''}
                      ariaLabel={`${getPreferenceLabel(
                        langPreferences,
                        'event',
                        'singular',
                        '',
                        true
                      )} #`}
                      onChange={(e) => {
                        this.props.setEventProps('item', e.currentTarget.value);
                      }}
                    />
                  </FormItem>
                </div>
                <div className="shift-modal-inner spacing-bottom-large">
                  <FormItem label="Address name">
                    <Input
                      id="event-edit-form-input-address-name"
                      value={event.address_name || ''}
                      ariaLabel="Address name"
                      onChange={(e) => {
                        this.props.setEventProps(
                          'address_name',
                          e.currentTarget.value
                        );
                      }}
                    />
                  </FormItem>
                </div>
                <div className="shift-modal-inner spacing-bottom-large">
                  <FormItem label="Address to navigate">
                    <EventAddress
                      id="event-edit-form-input-address-to-navigate"
                      onChange={this.props.setEventLocation}
                      event={event}
                    />
                  </FormItem>
                </div>
                <div className="shift-modal-inner spacing-bottom-large">
                  <FormItem label="Report to">
                    <Select
                      id="event-edit-form-select-report-to"
                      options={this.reportToOptions()}
                      value={this.getSelectedReportTo()}
                      defaultValue={this.getSelectedReportTo()}
                      isClearable={true}
                      onChange={(e) => {
                        const label = e ? e.label : null;
                        this.props.setEventProps('report_to', label);
                      }}
                      ariaLabel="Report to"
                    />
                  </FormItem>
                </div>
              </>
            )}

            {event.type === 'event' && event.lat !== 0 && (
              <div className="shift-modal-inner spacing-bottom-large">
                <FormItem label="">
                  <Button
                    id="event-edit-form-btn-view-map"
                    type="primary"
                    onClick={() => this.props.openSubModal('map')}
                    icon={<LocationOnOutlinedIcon />}
                  >
                    View Map
                  </Button>
                </FormItem>
              </div>
            )}

            <FormItem label={'Description'}>
              <TextArea
                id="event-edit-form-textarea-description"
                value={event.description || ''}
                name="textArea"
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  this.props.setEventProps('description', e.target.value);
                }}
                ariaLabel="Description"
              />
            </FormItem>
          </div>
        )}
      </WithAccountPreferences>
    );
  }

  dateGroup = () => {
    const { event } = this.props;
    if (event.type === 'event') {
      return (
        <EventPeriod
          onChangePeriod={this.props.onChangePeriod}
          params={event}
          dateFormat={this.props.dateFormat}
          timeFormat={this.props.timeFormat}
          sites={this.props.sites}
        />
      );
    }
    return (
      <ForecastPeriod
        onChangePeriod={this.props.onChangePeriod}
        params={event}
        dateFormat={this.props.dateFormat}
        timeFormat={this.props.timeFormat}
        sites={this.props.sites}
      />
    );
  };

  reportToOptions = () => {
    const { event } = this.props;
    let users = Object.values(this.props.users);
    users = users.filter((user) => {
      let showUser = false;
      user.user_roles.forEach((role) => {
        if (role.site_id === event.site_id) {
          showUser = true;
        }
      });
      return showUser;
    });

    return users.map((user) => {
      return { value: user.id, label: getUserName(user) };
    });
  };

  getSelectedReportTo = () => {
    const { event, users } = this.props;
    const user = Object.values(users).find((u) => {
      return getUserName(u) === event.report_to;
    });

    if (user) {
      return {
        value: user.id,
        label: getUserName(user),
      };
    }
  };

  componentDidMount(): void {
    this.bindFocusOutOptions();
    const btns = document.querySelectorAll('#event-edit-form-event-type label');
    const users = document.querySelector('#event-edit-form-btn-user');
    if (btns) {
      btns.forEach((item: any, i: number) => {
        item.addEventListener('keypress', (e: any) => {
          this.props.setEventProps('type', i === 0 ? 'forecast' : 'event');
          if (i === 0) {
            item.focus();
          }
        });
      });
    }
    if (users) {
      users.addEventListener('keypress', (e: any) => {
        this.props.openSubModal('users');
      });
    }
  }

  bindFocusOutOptions = () => {
    const modal = document.querySelector('#event-modal');
    if (modal !== null) {
      modal.addEventListener('click', (e: any) => {
        const t = e.target;
        const isPlaceholder: HTMLElement = t.closest('.group-placeholder');
        const isPicker: HTMLElement =
          t.closest('.elmo-datepicker-calendar') ||
          t.closest('.rc-time-picker-panel');
        const isParentExpanded: HTMLElement = t.closest(
          '.group-options-wrapper.expanded'
        );
        this.closeGroupOptions(isParentExpanded, isPicker, isPlaceholder);
      });
    }
  };

  closeGroupOptions = (
    isParentExpanded: HTMLElement,
    isPicker: HTMLElement,
    isPlaceholder: HTMLElement
  ) => {
    if (
      isParentExpanded === null &&
      isPicker === null &&
      isPlaceholder === null
    ) {
      const { dateGroup, breakGroup } = this.state;
      if (dateGroup) {
        this.setState({
          ...this.state,
          dateGroup: false,
        });
      }
      if (breakGroup) {
        this.setState({
          ...this.state,
          breakGroup: false,
        });
      }
    }
  };

  expandOptions = (target: string) => {
    setTimeout(() => {
      if (target === 'dateGroup') {
        this.setState({
          ...this.state,
          dateGroup: true,
          breakGroup: false,
        });
      }
      if (target === 'breakGroup') {
        this.setState({
          ...this.state,
          breakGroup: true,
          dateGroup: false,
        });
      }
    }, 200);
  };

  getUserName = () => {
    const { rostered_shift_ids } = this.props.event;
    const { shifts, users } = this.props;
    let usersNames: string[] = [];

    if (Object.keys(shifts).length) {
      rostered_shift_ids.forEach((shiftId: string) => {
        const shift = shifts[shiftId];
        if (shift) {
          const user = users[shift.user_id as string];
          if (user) {
            usersNames.push(getUserName(user));
          } else {
            usersNames.push('Unassigned');
          }
        }
      });
    }

    return usersNames.length ? usersNames.join(', ') : '';
  };

  showMore = () => {
    this.setState({
      ...this.state,
      moreOptionsVisible: true,
    });
  };

  numberFormat = (value: string) => {
    return value
      .replace(/[^0123456789.\-_]/g, '')
      .replace(/ +/g, '.')
      .replace(/(\..*)\./g, '$1');
  };
}

const mapStateToProps = (state: StoreState) => ({
  users: getUserListResponse(state),
  shifts: getEventModalShifts(state),
  langPreferences: getLangPreferences(state),
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
  numberFormat: getNumberFormat(state),
  currencyCode: getCurrencyCode(state),
  currencyPlacement: getCurrencyPlacement(state),
  sites: getSites(state),
  isLoadingShifts: getIsLoadingShifts(state),
});

export default connect(mapStateToProps)(EventEditForm);
