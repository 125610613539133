import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LayoutContextProps, withLayoutAware } from 'elmo-elements';
import {
  AddIcon,
  BulkActionIcon,
  EmailOutlinedIcon,
  NewHeader,
  NewHeaderAction,
  NewHeaderTitle,
  NewHeaderWrapper,
  PersonOutlineIcon,
  Search,
  SearchIcon,
  SupervisedUserCircleOutlinedIcon,
  DomainAddOutlinedIcon,
} from 'element';
import {
  BOX_USERS_ASSIGN_LOCATION_BULK_MODAL_OPEN,
  BOX_USERS_BULK_SEND_WELCOME_NOTIFICATIONS_REQUEST,
  BOX_USERS_CREATE_MODAL_TOGGLE,
  BOX_USERS_EDIT_APPROVALS_BULK_MODAL_OPEN,
  BOX_USERS_ROLE_BULK_MODAL_OPEN,
  BOX_USERS_UPDATE_SEARCH_QUERY,
  getSearchQuery,
} from 'state/Users/Users';
import { getLangPreferences } from 'state/Account';
import { getIsLoading } from 'state/IsLoading';
import { selectedIdsSelector } from 'state/Bulk/selectors';
import { BOX_BULK_CLEAR_SELECTED_IDS } from 'state/Bulk/actions';
import { isHrService } from 'helpers';
import { useSelectorWithProps } from 'hooks';
import { useBulkSearchContext } from 'contexts';
import { Box, IconButton, Tooltip } from 'extended-oxygen-elements';
import { BulkActions } from '../BulkActions';
import { getFlag } from 'state/Flags';

export const UsersHeader = withLayoutAware((props: LayoutContextProps) => {
  const dispatch = useDispatch();
  const bulkSearchContext = useBulkSearchContext();

  const langPreferences = useSelector(getLangPreferences);
  const isUpdating = useSelectorWithProps(getIsLoading, 'USERS');
  const selectedIds = useSelector(selectedIdsSelector);
  const searchTerm = useSelector(getSearchQuery);

  const isMassAssignLocation = useSelector(getFlag.massAssignLocation);

  const clearSelected = () => {
    dispatch(BOX_BULK_CLEAR_SELECTED_IDS());
  };

  const openEditApprovalsModal = () => {
    dispatch(BOX_USERS_EDIT_APPROVALS_BULK_MODAL_OPEN());
  };

  const openAddPositionModal = () => {
    dispatch(BOX_USERS_ROLE_BULK_MODAL_OPEN());
  };
  const sendWelcomeNotifications = () => {
    dispatch(BOX_USERS_BULK_SEND_WELCOME_NOTIFICATIONS_REQUEST());
    props.toggleBulkActionOpen();
    clearSelected();
  };

  const openCreateUserModal = () => {
    dispatch(BOX_USERS_CREATE_MODAL_TOGGLE(true));
  };

  const openBulkAssignLocation = () => {
    dispatch(BOX_USERS_ASSIGN_LOCATION_BULK_MODAL_OPEN());
  };

  const closeBulkActions = () => {
    props.toggleBulkActionOpen();
  };

  const openBulkActions = () => {
    props.toggleBulkActionOpen();
  };

  const actions = () => {
    const buttons = [
      {
        icon: <SupervisedUserCircleOutlinedIcon />,
        label: 'Edit approvers',
        onClick: openEditApprovalsModal,
        isDisabled: isUpdating || !selectedIds.length,
      },
      {
        icon: <PersonOutlineIcon />,
        label: `Add ${langPreferences.role.singular}`,
        onClick: openAddPositionModal,
        isDisabled: isUpdating || !selectedIds.length,
      },
      {
        icon: <DomainAddOutlinedIcon />,
        label: `Assign ${langPreferences.site.singular}`,
        onClick: openBulkAssignLocation,
        isDisabled: isUpdating || !selectedIds.length,
      },
    ];

    if (!isMassAssignLocation) {
      buttons.pop();
    }

    if (!isHrService('bravo')) {
      buttons.push({
        icon: <EmailOutlinedIcon />,
        label: `Send welcome emails`,
        onClick: sendWelcomeNotifications,
        isDisabled: false,
      });
    }
    return buttons;
  };

  const handleSearchClick = () => {
    bulkSearchContext.enable();
  };

  const handleSearchChange = (value: string) => {
    dispatch(BOX_USERS_UPDATE_SEARCH_QUERY(value));
  };

  const handleAddUserClick = () => {
    openCreateUserModal();
  };

  const handleBulkLocation = () => {
    openBulkAssignLocation();
  };

  const handleCloseSearch = () => {
    dispatch(BOX_USERS_UPDATE_SEARCH_QUERY(''));
  };

  return (
    <NewHeaderWrapper>
      <NewHeaderAction
        visible={bulkSearchContext.isEnabled}
        style={{ padding: 0, boxShadow: '0 0 4px #ccc' }}
      >
        <Search
          searchQuery={searchTerm}
          updateQuery={handleSearchChange}
          onCloseSearch={handleCloseSearch}
        />
      </NewHeaderAction>
      <NewHeaderAction
        visible={props.isBulkActionOpen}
        style={{ padding: 0, width: '100%', background: 'white' }}
      >
        <BulkActions actions={actions()} onClose={closeBulkActions} />
      </NewHeaderAction>
      <NewHeader hidden={bulkSearchContext.isEnabled || props.isBulkActionOpen}>
        <NewHeaderTitle>People</NewHeaderTitle>
        <Box ml="auto" display="flex" alignItems={'center'}>
          <Tooltip title={'Search'}>
            <IconButton
              aria-label="open search"
              onClick={handleSearchClick}
              sx={{ marginRight: '10px' }}
            >
              <SearchIcon style={{ fill: 'gray' }} />
            </IconButton>
          </Tooltip>
          {isHrService('standalone') && (
            <IconButton
              aria-label="add user"
              onClick={handleAddUserClick}
              sx={{ marginRight: '10px' }}
            >
              <AddIcon style={{ fill: 'gray' }} />
            </IconButton>
          )}
          <Tooltip title={'Update roles, approvers and locations'}>
            <IconButton
              aria-label="bulk actions"
              onClick={openBulkActions}
              sx={{ marginRight: '10px' }}
            >
              <BulkActionIcon style={{ fill: 'gray' }} />
            </IconButton>
          </Tooltip>
        </Box>
      </NewHeader>
    </NewHeaderWrapper>
  );
});
