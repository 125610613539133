import React from 'react';
import { useDispatch } from 'react-redux';
import { SERVER_DATE_TIME_FORMAT } from 'lib/config';
import { useUpdateEffect, useTimesheetsNewQuery } from 'hooks';
import { BOX_TIMESHEETS_WEEKLY_PAGE_DATA_UPDATE } from 'state/TimesheetsWeeklyPage';
import { useDateRange } from '../hooks';

export function UpdatePageData() {
  const dispatch = useDispatch();
  const fetchPayload = usePagePayload();

  useUpdateEffect(() => {
    dispatch(BOX_TIMESHEETS_WEEKLY_PAGE_DATA_UPDATE.request(fetchPayload));
  }, [dispatch, fetchPayload]);

  return null;
}

function usePagePayload() {
  const {
    query: { day, site_id },
  } = useTimesheetsNewQuery();
  const {
    dateRange: { from, to },
  } = useDateRange({ day });

  return React.useMemo(
    () => ({
      site_id,
      from,
      to,
    }),
    [
      site_id,
      from.format(SERVER_DATE_TIME_FORMAT),
      to.format(SERVER_DATE_TIME_FORMAT),
    ]
  );
}
