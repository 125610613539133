import React from 'react';
import Spinner from '../../Spinner';
import { LoaderHolderProps } from '../type';
import './SpinnerStyle.scss';

function SpinnerStyle(props: LoaderHolderProps) {
  return (
    <div className="elmo-spinner-container">
      <Spinner />
    </div>
  );
}

export default SpinnerStyle;
