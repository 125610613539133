import { ClearAllIcon, FormControlSpacing, FeatureFlag } from 'element';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
} from 'extended-oxygen-elements';
import {
  useActions,
  useSelectorWithProps,
  useSetCheckbox,
  useSetFormField,
  useUpdateStatusEffect,
} from 'hooks';
import {
  HelperTextWrapper,
  DefaultBreakRules,
  EditDefaultBreakRules,
  EnableBreaksCustomisationCheckbox,
} from 'page/Settings/components';
import React, { useEffect, useState } from 'react';
import {
  BOX_ACCOUNT_ROSTERED_SHIFT_SETTINGS_DEFAULT_BREAKS_RESET,
  getDefaultBreaksLoading,
  BOX_ACCOUNT_ROSTERED_SHIFT_APPLY_CUSTOMISED_BREAKS,
  getCustomBreaksLoading,
  getCustomBreaksStatus,
} from 'state/Account';
import { DefaultBreakItem, FieldGroupProps } from 'type';
import {
  DefaultBreaksFieldGroupErrors,
  DefaultBreaksFieldGroupValue,
} from './types';
import nanoid from 'nanoid';
import { useDispatch, useSelector } from 'react-redux';
import { CustomiseBreaksForm } from '../../../components/CustomiseBreaksForm';
import defaultBreaksFieldGroupValidator from './defaultBreaksFieldGroupValidator';

type Props = FieldGroupProps<
  DefaultBreaksFieldGroupValue,
  DefaultBreaksFieldGroupErrors
>;

export default DefaultBreaksFieldGroup;

export function DefaultBreaksFieldGroup({ values, onChange, errors }: Props) {
  const dispatch = useDispatch();

  const [customiseBreaks, setCustomiseBreaks] = useState(false);
  const [defaultBreaks, setDefaultBreaks] = useState<any[]>(
    values.default_breaks_rules
  );
  const [editErrors, setEditErrors] = useState(errors.default_breaks_rules);

  const isLoading = useSelectorWithProps(getDefaultBreaksLoading);
  const isApplyingCustomBreaks = useSelector(getCustomBreaksLoading);

  const { makeSetFormField } = useSetFormField(onChange);
  const { makeSetCheckbox } = useSetCheckbox(onChange);

  useEffect(() => {
    if (!values.apply_default_breaks) {
      setCustomiseBreaks(false);
    }
  }, [values.apply_default_breaks]);

  useUpdateStatusEffect(getCustomBreaksStatus, {
    success: () => {
      customiseBreaks && setCustomiseBreaks(false);
    },
  });

  const resetBreaks = useActions(
    BOX_ACCOUNT_ROSTERED_SHIFT_SETTINGS_DEFAULT_BREAKS_RESET.request
  );

  const handleResetToDefaultsBtnClick = () => {
    const { apply_default_breaks } = values;
    resetBreaks({ apply_default_breaks });
  };

  const handleChangeBreakDuration = (value: any) => {
    setDefaultBreaks(value);
  };

  const applyBreaks = () => {
    const e = defaultBreaksFieldGroupValidator({
      apply_default_breaks: true,
      view_shifts_breaks: false,
      default_breaks_rules: defaultBreaks,
    });

    setEditErrors(e.default_breaks_rules);

    if (typeof e.default_breaks_rules === 'undefined') {
      const payload: DefaultBreakItem[] = defaultBreaks.map((b) => ({
        break_duration: b.break_duration,
        shift_duration: b.shift_duration,
        condition: b.condition,
      }));

      dispatch(
        BOX_ACCOUNT_ROSTERED_SHIFT_APPLY_CUSTOMISED_BREAKS.request(payload)
      );
    }
  };

  const pushBreakIntoSettings = (breakItem: DefaultBreakItem) => {
    setDefaultBreaks((prevState) =>
      [
        ...prevState,
        {
          ...breakItem,
          condition: '<=',
          id: nanoid(),
        },
      ].sort((a, b) => a.shift_duration - b.shift_duration)
    );
  };

  const handleCustomiseBreaks = () => {
    const breaks = values.default_breaks_rules.filter(
      (b) => b.condition !== '>'
    );
    setDefaultBreaks(breaks);
    setCustomiseBreaks(!customiseBreaks);
  };

  return (
    <>
      <Typography variant="h4" component="h2">
        Set up your breaks
      </Typography>

      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.apply_default_breaks}
              onChange={makeSetCheckbox('apply_default_breaks')}
            />
          }
          label="Enable breaks"
        />

        <HelperTextWrapper>
          <FormHelperText fontSize="medium">
            Use Breathe’s default breaks.
          </FormHelperText>
        </HelperTextWrapper>

        <FeatureFlag name={'customiseBreaks'}>
          <FeatureFlag.On>
            <FormControl>
              <EnableBreaksCustomisationCheckbox
                handleCustomiseBreaks={handleCustomiseBreaks}
                isEnabled={values.apply_default_breaks}
                modeEnabled={customiseBreaks}
              />
            </FormControl>
          </FeatureFlag.On>
        </FeatureFlag>
      </FormControl>

      <Typography
        variant="h6"
        component="h3"
        sx={
          !values.apply_default_breaks || isLoading
            ? { color: 'rgba(0, 0, 0, 0.38)' }
            : {}
        }
        mt="20px"
      >
        Set break duration based on shift length
      </Typography>

      {!customiseBreaks && (
        <Box maxWidth={440}>
          <DefaultBreakRules
            value={values.default_breaks_rules}
            onChange={makeSetFormField('default_breaks_rules')}
            errors={errors.default_breaks_rules}
            disabled={!values.apply_default_breaks || isLoading}
          />
        </Box>
      )}

      <FeatureFlag name={'customiseBreaks'}>
        <FeatureFlag.On>
          {customiseBreaks && (
            <>
              <Box sx={{ '@media (min-width: 600px)': { maxWidth: '440px' } }}>
                <EditDefaultBreakRules
                  value={defaultBreaks}
                  onChange={handleChangeBreakDuration}
                  errors={editErrors}
                />
              </Box>

              <Box sx={{ '@media (min-width: 600px)': { maxWidth: '440px' } }}>
                <CustomiseBreaksForm
                  applyBreaks={applyBreaks}
                  handleCustomiseBreaks={handleCustomiseBreaks}
                  customiseBreaks={customiseBreaks}
                  defaultBreaks={defaultBreaks}
                  pushBreakIntoSettings={pushBreakIntoSettings}
                  isLoading={isApplyingCustomBreaks}
                />
              </Box>
            </>
          )}
        </FeatureFlag.On>
      </FeatureFlag>

      <FormControlSpacing>
        {!customiseBreaks && (
          <Button
            startIcon={<ClearAllIcon />}
            loading={isLoading}
            onClick={handleResetToDefaultsBtnClick}
            disabled={!values.apply_default_breaks}
          >
            Reset to default
          </Button>
        )}
      </FormControlSpacing>

      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.view_shifts_breaks}
              onChange={makeSetCheckbox('view_shifts_breaks')}
            />
          }
          label="People can view breaks"
        />

        <HelperTextWrapper>
          <FormHelperText fontSize="medium">
            People can see the start & end times of their break within shifts.
          </FormHelperText>
        </HelperTextWrapper>
      </FormControl>
    </>
  );
}
