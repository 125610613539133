import { Minutes } from 'type';

export const commonError = 'Something went wrong.';
export const withoutPermissionsWarning =
  'You don’t have enough permissions to view any pages.';
export const confirmationModalTitle = 'Heads up!';
export const altConfirmationModalTitle = 'Do you want to approve this?';
export const confirmationModalBtnLabel = 'Yes, approve it';
export const confirmationModalBtnOpenLabel = 'Yes, open link';
export const warningsModalBtnLabel = 'Ok';

export const deletedInstanceMessage = ({
  instance,
  goToPage,
}: {
  instance: string;
  goToPage: string;
}) =>
  `We couldn\'t find this ${instance} in system, please go to ${goToPage} page`;

export const outDatedDataMessage = 'Please, reload page to view latest updates';

export const shiftSwapProposalDeclined = 'Shift swap proposal declined';
export const shiftSwapProposalDeclineError =
  'Shift swap decline proposal error';
export const shiftSwapProposalApproved = 'Shift swap proposal approved';
export const shiftSwapProposalApproveError =
  'Shift swap approve proposal error';

export const resetPasswordSuccess =
  'If your email address is registered with this account, ' +
  'you will receive an email with details on resetting your password.';

export const rosterPageLabels = {
  unavailability: 'Unavailability',
  rosteredShift: 'Shift',
  timesheet: 'Timesheet',
};

export const getTrialMessage = (days: number) =>
  `Your account trial expires in ${days} ${days > 1 ? 'days' : 'day'}`;

export const withinRange = (min: number,  max: number, unit: string) =>
  `Must be between ${min} and ${max} ${unit}`;

export const settingsRosteredShiftsInvalidBreakLess =
  'Break duration must be less than the duration of the shift';
export const settingsRosteredShiftsInvalidBreak = (limit: Minutes) =>
  `Break duration must be less than ${limit} minutes`;

export const btnLabelLogout = 'Logout';

export const deleteTitle = (what: string) => `Delete the ${what}?`;
export const deleteOneDescription = (what: string) =>
  `Once deleted, this ${what} will be permanently removed.`;
export const deleteManyDescription = (quantity: number, what: string) =>
  `You are about to delete ${quantity} ${what}. Once deleted, ${what} will be permanently removed.`;
export const deleteConfirmLabel = 'Delete';

export const deleteTimesheet = {
  title: deleteTitle('timesheet'),
  description: deleteOneDescription('timesheet'),
};

export const deleteTimesheets = {
  title: deleteTitle('timesheets'),
  description: (quantity: number) => {
    const what = quantity > 1 ? 'timesheets' : 'timesheet';

    return deleteManyDescription(quantity, what);
  },
};

export const approveTimesheets = (quantity: number) => {
  const what = quantity > 1 ? 'timesheets' : 'timesheet';

  return `${quantity} ${what} will be approved.`;
};

export const emptySearchResults = (what: string) =>
  `Your search did not match any ${what}.`;
export const emptyFilterResults = (what: string) =>
  `No ${what} were found matching your filter.`;
