import React, { Component } from 'react';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import {
  BOX_USER_PROFILE_AWARDS_REQUEST,
  BOX_USER_PROFILE_REQUEST,
} from 'state/Users/UserProfile/actions';
import { Layout, WithPreload } from 'element';
import ErrorBox from 'element/ErrorBox';
import { RouteComponentProps, withRouter } from 'react-router';
import { View } from './view';
import { UserProfileFields } from 'type/models';
import { PinCodeModal, UserHeader } from '../../components';
import {
  getUserProfile,
  getUsersAwardsState,
} from '../../../../../../state/Users/UserProfile';
import { UserAwardsState } from '../../../../../../state/Users/UserProfile/types';
import { hasPermissionSelector } from '../../../../../../state/Auth';
import { privateRoutes } from '../../../../../../routes';
import { AwardModal } from './components/AwardModal';
import { DeleteConfirmation } from './components/DeleteConfirmation';
import { getUserName } from 'lib/helpers';

type StateProps = {
  userProfile: UserProfileFields;
  awards: UserAwardsState;
  hasPermission: boolean;
};
type DispatchProps = {
  getUserAwards: (params: any) => void;
  getUserProfile: (params: any) => void;
};

type TParams = {
  userId: string;
};

type Props = StateProps & DispatchProps & RouteComponentProps<TParams>;

class Entitlements extends Component<Props> {
  componentDidMount(): void {
    const { params } = this.props.match;
    const { userProfile, hasPermission } = this.props;
    if (!hasPermission) {
      this.props.history.push(privateRoutes.roster.path);
    }
    if (userProfile.id === '') {
      this.props.getUserProfile(params);
    }
  }

  render() {
    const { params } = this.props.match;
    const userName = getUserName(this.props.userProfile);
    return (
      <>
        <Layout.Header>
          <UserHeader title={userName} />
        </Layout.Header>
        <Layout.Content>
          {this.props.awards.errors && this.props.awards.errors.length > 0 && (
            <ErrorBox errors={this.props.awards.errors} />
          )}
          <WithPreload
            isFetching={this.props.awards.isFetching}
            fetchData={this.props.getUserAwards}
            params={params}
          >
            <View />
          </WithPreload>
          <PinCodeModal />
          {this.props.awards.modal.isOpened && <AwardModal />}
          <DeleteConfirmation />
        </Layout.Content>
      </>
    );
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  awards: getUsersAwardsState(state),
  userProfile: getUserProfile(state),
  hasPermission: hasPermissionSelector(state, 'awards.view'),
});

const mapDispatchToProps: DispatchProps = {
  getUserAwards: BOX_USER_PROFILE_AWARDS_REQUEST,
  getUserProfile: BOX_USER_PROFILE_REQUEST,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Entitlements)
);
