import { createAction } from 'lib/store-utils';
import { FormattedErrors } from 'type';
import {
  EditableNotificationMessage,
  GetNotificationPayload,
  SingleNotification,
  UpdateHoursPayload,
  UpdateNotificationPayload
} from './type';

export const BOX_NOTIFICATION_REQUEST = createAction<GetNotificationPayload>('Get notification request');
export const BOX_NOTIFICATION_SUCCESS = createAction<SingleNotification>('Get notification success');
export const BOX_NOTIFICATION_FAILURE = createAction<string[]>('Get notification failed');

export const BOX_NOTIFICATION_EDIT_MODAL_OPEN = createAction('Notification / Open edit modal');
export const BOX_NOTIFICATION_EDIT_MODAL_CLOSE = createAction('Notification / Close edit modal');
export const BOX_NOTIFICATION_EDIT_MODAL_CLEAR_ERRORS = createAction('Notification / Clear edit error');

export const BOX_NOTIFICATION_TIME_MODAL_OPEN = createAction('Notification / Open time modal');
export const BOX_NOTIFICATION_TIME_MODAL_CLOSE = createAction('Notification / Close time modal');
export const BOX_NOTIFICATION_TIME_MODAL_CLEAR_ERRORS = createAction('Notification / Clear time error');

export const BOX_UPDATE_NOTIFICATION_REQUEST = createAction<UpdateNotificationPayload>('Update notification request');
export const BOX_UPDATE_NOTIFICATION_SUCCESS = createAction('Update notification success');
export const BOX_UPDATE_NOTIFICATION_FAILURE = createAction<string[]>('Update notification failed');

export const BOX_NOTIFICATION_DESTROY_REQUEST = createAction('Destroy notification request');
export const BOX_NOTIFICATION_INIT_EDITOR_REQUEST = createAction('Notification init ckeditor');

export const BOX_UPDATE_NOTIFICATION_HOURS_REQUEST = createAction<UpdateHoursPayload>('Update notification hours request');
export const BOX_UPDATE_NOTIFICATION_HOURS_SUCCESS = createAction('Update notification hours success');
export const BOX_UPDATE_NOTIFICATION_HOURS_FAILURE = createAction<string[]>('Update notification hours failed');

export const BOX_NOTIFICATION_UPLOAD_REQUEST = createAction('Notification upload request');
export const BOX_NOTIFICATION_UPLOAD_SUCCESS = createAction<string>('Notification upload success');
export const BOX_NOTIFICATION_UPLOAD_FAILURE = createAction<FormattedErrors>('Notification upload failure');

export const BOX_NOTIFICATION_SELECT_MESSAGE = createAction<EditableNotificationMessage>('Notification/ Select message');
