import React, { ReactNode } from 'react';

export type FormItemControlProps = {
  children?: ReactNode;
};

function FormItemControl({ children }: FormItemControlProps) {
  return (
    <div className="elmo-formitem__control__item-group">
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          return (
            <div className={'elmo-formitem__control__item'} key={index}>
              {child}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}

export default FormItemControl;
