import moment from 'moment';
import { ShiftOfferReducerState } from './types';

const today = moment();

export const getDefaultState = (): ShiftOfferReducerState => ({
  errors: [],
  isOutdated: {
    value: false,
    notificationId: ''
  },
  shiftOfferWithProposals: {
    id: '',
    shift_start: today,
    shift_end: today,
    offer_start: today,
    proposal_ids: [],
    site_id: '',
    area_id: '',
    role_id: '',
    proposals: {},
    timezone: 'UTC',
    is_dst_intersect: false,
    notes: null
  }
});
