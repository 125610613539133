import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Api } from 'lib/Api';
import { processApiRequest } from 'state/ProcessApiRequest';
import * as fetchPageData from 'state/FetchPageData';
import { Account } from '../types';
import * as actions from '../actions';
import * as impersonate from './impersonate';

export const getAccountsRequest = function*(): SagaIterator {
  const accounts: Account[] = yield call(
    processApiRequest,
    Api.Accounts.getAccounts
  );

  yield put(actions.BOX_ACCOUNTS_GET_ACCOUNTS_LIST_SUCCESS(accounts));
};

export const getData = function*(): SagaIterator {
  try {
    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_REQUEST(fetchPageData.ACCOUNTS)
    );

    yield call(getAccountsRequest);

    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_SUCCESS(fetchPageData.ACCOUNTS)
    );
  } catch (error) {
    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_FAILURE(fetchPageData.ACCOUNTS)
    );
  }
};

export const watchAccounts = function*(): SagaIterator {
  yield takeLatest(actions.BOX_ACCOUNTS_REQUEST, getData);
  yield takeLatest(
    actions.BOX_ACCOUNTS_LOGIN_REQUEST,
    impersonate.accountLogin
  );
  yield takeLatest(
    actions.BOX_ACCOUNTS_ADMIN_LOGIN_REQUEST,
    impersonate.adminLogin
  );
};
