import { Moment } from 'moment';

export const getUpdatedValue = (prevValue: Moment | null, inputValue: Moment): Moment => {
  if (!prevValue) {
    return inputValue;
  }

  return prevValue
    .clone()
    .startOf('day')
    .set({
      hours: inputValue.hours(),
      minutes: inputValue.minutes()
    });
};
