import { createSelector } from 'reselect';
import { map, cloneDeep, values } from 'lodash';
import {
  AccountTreeArea,
  AccountTreeRole,
  EmployeeSettingsSorting,
  StringMap,
  UserFields,
  SelectPropsOption,
  ShiftTemplateItem,
} from 'type';
import { StoreState } from 'state/types';
import { userListSelector } from 'state/UsersCollection';
import { getEmployeeSettingsSorting } from 'state/Account';
import { areasBySiteIdSelector, getRoles } from 'state/AccountTree';
import { ConfirmModalProps, EditModalProps } from './types';
import { sortUserBy } from '../helpers';
import {
  buildShiftItemsRows,
  filterShiftItemsByAreaRoleId,
  isNotEmptyRow,
  filterShiftItemsByUserId,
} from './helpers';
import _ from 'lodash';

export const isEditTemplateMode = (state: StoreState): boolean =>
  !!state.editTemplate.templateId;
export const getTemplateId = (state: StoreState): string =>
  state.editTemplate.templateId;
export const getTemplateShifts = (
  state: StoreState
): StringMap<ShiftTemplateItem> => state.editTemplate.shifts;
export const getTemplateShiftsArray = (
  state: StoreState
): ShiftTemplateItem[] => values(state.editTemplate.shifts);
export const getTemplateShift = (state: StoreState, id: string) =>
  getTemplateShifts(state)[id];
export const getTemplateSiteId = (state: StoreState): string =>
  state.editTemplate.siteId;
export const getEditModal = (state: StoreState): EditModalProps =>
  state.editTemplate.editModal;
export const getConfirmationDeleteModal = (
  state: StoreState
): ConfirmModalProps => state.editTemplate.confirmDeletionModal;
export const getTemplateTitle = (state: StoreState): string =>
  state.editTemplate.title;
export const getPrevPageValue = (state: StoreState): string | null =>
  state.editTemplate.prevPage;
export const getDeleteTemplateModal = (state: StoreState) =>
  state.editTemplate.confirmDeletionTemplateModal;
export const getSaveAsTemplateModal = (state: StoreState) =>
  state.editTemplate.confirmSaveAsTemplateModal;
export const getUpdateTemplateModal = (state: StoreState) =>
  state.editTemplate.confirmUpdateTemplateModal;
export const getShiftItemById = (state: StoreState, id: string) =>
  state.editTemplate.shifts[id];

export const getDraggableTemplatedShift = (
  state: StoreState
): null | Partial<ShiftTemplateItem> => state.editTemplate.draggableShift;
export const getDroppedShiftPayload = (state: StoreState) =>
  state.editTemplate.droppedShift;
export const getDropInProgress = (state: StoreState): boolean =>
  state.editTemplate.dropInProgress;
export const getDropCancelled = (state: StoreState): boolean =>
  state.editTemplate.dropCanceled;

const getUnassignedShiftsData = (shifts: ShiftTemplateItem[]) => {
  const unassignedRosters = filterShiftItemsByUserId(null, shifts);
  const { rows, rowsNumber } = buildShiftItemsRows(unassignedRosters, shifts);
  return {
    rows: rows,
    rowsNumber: rowsNumber,
  };
};

export const getUsersBySite = createSelector<
  StoreState,
  StringMap<UserFields>,
  string,
  StringMap<UserFields>
>(userListSelector, getTemplateSiteId, (users, siteId) => {
  let usersList: StringMap<UserFields> = {};
  Object.keys(users).forEach((id: string) => {
    for (let role of users[id].user_roles) {
      if (role.site_id === siteId && users[id].is_active) {
        usersList[id] = users[id];
        break;
      }
    }
  });
  return usersList;
});

export const activeUsersAsSelectOptionsArraySelectorBySiteTemplate =
  createSelector<StoreState, StringMap<UserFields>, SelectPropsOption[]>(
    getUsersBySite,
    (users) =>
      map(users, (user) => ({
        label: user.prefered_or_full_name,
        value: user.id,
      }))
  );

export const getUserIdsBySite = createSelector<
  StoreState,
  StringMap<UserFields>,
  string,
  string[]
>(userListSelector, getTemplateSiteId, (users, siteId) => {
  let usersIds: string[] = [];
  Object.keys(users).forEach((id: string) => {
    for (let role of users[id].user_roles) {
      if (role.site_id === siteId) {
        usersIds.push(id);
        break;
      }
    }
  });
  return usersIds;
});

const getSortedUsersBy = createSelector<
  StoreState,
  string[],
  StringMap<UserFields>,
  EmployeeSettingsSorting,
  string[]
>(getUserIdsBySite, userListSelector, getEmployeeSettingsSorting, sortUserBy);

export const groupShiftItemsByUsersSelector = createSelector<
  StoreState,
  string[],
  ShiftTemplateItem[],
  StringMap<UserFields>,
  any
>(
  getSortedUsersBy,
  getTemplateShiftsArray,
  userListSelector,
  (usersIds, rosters, allUsers) => {
    const config = [];
    usersIds.forEach((userId: string) => {
      const userRosters = filterShiftItemsByUserId(userId, rosters);
      const rosterData = buildShiftItemsRows(userRosters, rosters);

      const user = allUsers[userId];
      const toShow =
        (user && user.is_active) ||
        (user && !user.is_active && userRosters.length > 0);

      if (toShow) {
        const byUser: any = {
          user_id: userId,
          rosters: rosterData.rows,
          rostersRowNumber: rosterData.rowsNumber,
        };
        config.push(byUser);
      }
    });

    const { rows, rowsNumber } = getUnassignedShiftsData(rosters);

    config.push({
      user_id: null,
      rosters: rows,
      rostersRowNumber: rowsNumber,
    });
    return config;
  }
);

export const groupShiftItemsByAreas = createSelector<
  StoreState,
  StringMap<AccountTreeArea[]>,
  ShiftTemplateItem[],
  StringMap<AccountTreeRole>,
  string,
  any
>(
  areasBySiteIdSelector,
  getTemplateShiftsArray,
  getRoles,
  getTemplateSiteId,
  (areasBySiteId, rosters, roles, siteId) => {
    const config: any = [];
    const allAreas: any = {};
    const areas = areasBySiteId[siteId];
    areas.forEach((area: AccountTreeArea) => {
      allAreas[area.id] = cloneDeep(area);
    });
    rosters.forEach((data: any) => {
      const roleId = data.role_id;
      const areaId = data.area_id;
      if (allAreas[areaId]) {
        const area: AccountTreeArea = allAreas[areaId];
        if (area.role_ids && area.role_ids.indexOf(roleId) === -1) {
          area.role_ids.push(roleId);
        }
      }
    });

    Object.keys(allAreas).forEach((key: string) => {
      const area: AccountTreeArea = allAreas[key];
      area.role_ids.forEach((roleId: string) => {
        const roleRosters = filterShiftItemsByAreaRoleId(
          area.id,
          roleId,
          rosters
        );
        const rosterData = buildShiftItemsRows(roleRosters, rosters);
        let areaNotEmpty = true;
        if (area.archived || (roles[roleId] && roles[roleId].archived)) {
          areaNotEmpty = isNotEmptyRow(rosterData.rows);
        }
        if (areaNotEmpty) {
          config.push({
            role_id: roleId,
            area_id: area.id,
            rosters: rosterData.rows,
            rowsNumber: rosterData.rowsNumber,
            areaName: area.name,
            roleName: roles[roleId].name,
          });
        }
      });
    });
    return _.sortBy(config, ['areaName', 'roleName']);
  }
);
