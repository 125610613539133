import React, { Component } from 'react';
import { FormattedErrors } from 'type/models';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from 'extended-oxygen-elements';
import { replaceErrorText } from 'lib/helpers';
import { PageDialog, PageDialogCancel, PageDialogSubmit } from 'element';

type Props = {
  onSubmit: () => void;
  isOpened: boolean;
  onClose: () => void;
  errors: FormattedErrors;
};

export default class ConfirmationModal extends Component<Props> {
  render() {
    const { isOpened, onClose, errors } = this.props;

    return (
      <PageDialog
        maxWidth={'xs'}
        open={isOpened}
        id="confirmation-todays-shift-modal-dialog"
      >
        <DialogTitle>Do you want to approve this?</DialogTitle>
        <DialogContent>
          {replaceErrorText(errors).map((error, errorIndex) => (
            <Typography variant={'body1'} key={errorIndex}>
              {error}
            </Typography>
          ))}
        </DialogContent>
        <DialogActions>
          <PageDialogCancel onClick={onClose}>Cancel</PageDialogCancel>
          <PageDialogSubmit onClick={this.onSubmit}>
            Yes, proceed
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  }

  onSubmit = () => {
    const { onSubmit } = this.props;
    onSubmit();
  };
}
