import { useTheme } from 'extended-oxygen-elements';
import React from 'react';
import { Dictionary } from 'ts-essentials';

export type LayoutBackgroundVariant = 'default' | 'white';

type LayoutContextValue = ReturnType<typeof useAppBarHeight> &
  ReturnType<typeof useHeaderHeight> &
  ReturnType<typeof useBackground> &
  ReturnType<typeof useDisableScroll> &
  ReturnType<typeof useMobileOpen>;

const Context = React.createContext({} as LayoutContextValue);

type Props = {
  children: React.ReactNode;
};

export function LayoutContextProvider({ children }: Props) {
  const appBarHeight = useAppBarHeight();
  const headerHeight = useHeaderHeight();
  const background = useBackground();
  const disableScroll = useDisableScroll();
  const mobileOpen = useMobileOpen();

  return (
    <Context.Provider
      value={{
        ...appBarHeight,
        ...headerHeight,
        ...background,
        ...disableScroll,
        ...mobileOpen
      }}
    >
      {children}
    </Context.Provider>
  );
}

function useAppBarHeight() {
  const [appBarHeight, setAppBarHeight] = React.useState(0);

  return { appBarHeight, setAppBarHeight };
}

function useHeaderHeight() {
  const [headerHeight, setHeaderHeight] = React.useState(0);

  return { headerHeight, setHeaderHeight };
}

function useBackground() {
  const [backgroundName, setBackgroundName] =
    React.useState<LayoutBackgroundVariant>('default');
  const theme = useTheme();

  const backgroundCollection: Dictionary<string, LayoutBackgroundVariant> = {
    default: theme.palette.background.default,
    white: theme.palette.common.white,
  };

  const background = backgroundCollection[backgroundName];

  const unsetBackground = React.useCallback(() => {
    setBackgroundName('default');
  }, []);

  return {
    background,
    setBackground: setBackgroundName,
    unsetBackground,
  };
}

function useDisableScroll() {
  const [disableScroll, setDisableScroll] = React.useState(false);

  const unsetDisableScroll = React.useCallback(() => {
    setDisableScroll(false);
  }, []);

  return {
    disableScroll,
    setDisableScroll,
    unsetDisableScroll,
  };
}

function useMobileOpen() {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  return { mobileOpen, setMobileOpen };
}

export function useLayoutContext() {
  return React.useContext(Context);
}
