import React, { Component } from 'react';
import './CalendarHeader.scss';
import { Text } from 'elmo-elements';

export default class CalendarHeader extends Component {
  render() {
    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    return (
      <div className="calendar-header">
        {days.map((day: any, key: number) => (
          <Text className="calendar-header__day" size={'sm'} key={key}>{day}</Text>
        ))}
      </div>
    );
  }
}