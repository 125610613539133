import { defaultTheme } from 'elmo-elements';

export const edsTheme = {
  ...defaultTheme,
  font: 'Rubik',
  menu: {
    font: 'Rubik',
    fontColor: '#fff',
    hoverFontColor: '#fff',
    backgroundColor: 'rgba(0,119,200,1)',
    backgroundColorHover: 'rgba(34,89,144,1)',
  },
  button: {
    background: 'rgba(0,119,200,1)',
    color: '#fff',
  },
  link: {
    color: 'rgba(0,119,200,1)',
  },
};

export default edsTheme;
