import React from 'react';
import { Card, Col, Paragraph, Row, Text } from 'elmo-elements';
import { StoreState } from 'state/types';
import { UserFields, UserProfileFields } from 'type/models';
import { connect } from 'react-redux';
import { userListSelector } from 'state/UsersCollection';
import { EditButton } from '../EditButton';
import { StringMap } from '../../../../../../../../type';
import { BOX_USER_PROFILE_EDIT_APPROVALS_MODAL_OPEN } from '../../../../../../../../state/Users/UserProfile/actions';
import { UserProfileApprovalsModal } from './components/UserProfileApprovalsModal';
import { getUserProfile } from '../../../../../../../../state/Users/UserProfile/selectors';

type StateProps = {
  userProfile: UserProfileFields;
  userList: StringMap<UserFields>;
};

type DispatchProps = {
  openApproversModal: (id: string) => void;
};

type Props = StateProps & DispatchProps;

export class ApprovalsComponent extends React.Component<Props> {
  render() {
    const { id } = this.props.userProfile;
    if (!this.props.userList[id]) {
      return null;
    }
    const { leave, shift_offer, shift_swap } = this.props.userList[id].managers;

    return (
      <Card
        isFullHeight={true}
        heading="Approvals"
        option={this.props.userProfile.is_active && <EditButton onClick={this.onEditClick} ariaLabel="Edit" />}
        role="grid"
      >
        <Row>
          <Col isAuto={true} span={12}>
            <Text size={'sm'}>Unavailability</Text>
            <Paragraph className={'unavailability-value'}>
              {this.getFieldValue(leave)}
            </Paragraph>
            <br />
            <Text size={'sm'}>Shift swaps</Text>
            <Paragraph className={'swaps-value'}>
              {this.getFieldValue(shift_swap)}
            </Paragraph>
          </Col>
          <br />
          <Col isAuto={true} span={12}>
            <Text size={'sm'}>Shift offers</Text>
            <Paragraph className={'offers-value'}>
              {this.getFieldValue(shift_offer)}
            </Paragraph>
          </Col>
        </Row>

        <UserProfileApprovalsModal />
      </Card>
    );
  }

  onEditClick = () => {
    const {
      openApproversModal,
      userProfile: { id }
    } = this.props;
    openApproversModal(id);
  };

  private getFieldValue = (
    field: null | string | { id: string; name: string }
  ) => {
    if (field !== null) {
      const id = typeof field === 'string' ? field : field.id;
      const approval: UserFields | undefined = this.props.userList[id];
      if (approval) {
        return approval.prefered_or_full_name;
      }
      return typeof field !== 'string' ? field.name : '';
    }
    return 'Default approver';
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  userProfile: getUserProfile(state),
  userList: userListSelector(state)
});

const mapDispatchToProps: DispatchProps = {
  openApproversModal: BOX_USER_PROFILE_EDIT_APPROVALS_MODAL_OPEN
};

export const Approvals = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApprovalsComponent);
