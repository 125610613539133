import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'elmo-elements';
import { FormattedErrors } from '../../../../../../type/models';
import ErrorBox from '../../../../../../element/ErrorBox';
import { getApplicableUsers } from '../../../../../../state/ManagerDashboard/Unavailability';
import { StoreState } from '../../../../../../state/types';

type OwnProps = {
  children: ReactNode;
};

type StateProps = {
  isFetching: boolean;
  isFetched: boolean;
  errors: FormattedErrors;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

const ApplicableUsersLoaderComponent = ({
  children,
  isFetching,
  isFetched,
  errors
}: Props) => (
  <Loader type="spinner" isLoading={isFetching}>
    {isFetched ? children : <ErrorBox errors={errors} />}
  </Loader>
);

const mapStateToProps = (state: StoreState): StateProps =>
  getApplicableUsers(state);

const mapDispatchToProps: DispatchProps = {};

export const ApplicableUsersLoader = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicableUsersLoaderComponent);
