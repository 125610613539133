import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import {
  BOX_MY_ROSTERS_CONFIRMATION_MODAL_CLOSED,
  BOX_MY_ROSTERS_TRADE_FORCE_REQUEST,
  getForceModalIsOpen,
  getForceModalMessage,
} from 'state/EmployeeDashboard/MyRosters';
import { confirmationModalTitle } from '../../../../../../../../messages';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from 'extended-oxygen-elements';
import { PageDialog, PageDialogCancel, PageDialogSubmit } from 'element';

type OwnProps = {};

type StateProps = {
  isOpened: boolean;
  message: string;
};

type DispatchProps = {
  onSubmit: () => void;
  onClose: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const ConfirmationModalComponent = ({
  isOpened,
  onClose,
  onSubmit,
  message,
}: Props) => {
  return (
    <PageDialog
      maxWidth={'xs'}
      open={isOpened}
      data-testid="ed-confirmation-dialog"
    >
      <DialogTitle>{confirmationModalTitle}</DialogTitle>
      <DialogContent>
        <Typography variant={'body1'}>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <PageDialogCancel
          onClick={onClose}
          data-testid="ed-confirmation-dialog-cancel"
        >
          No
        </PageDialogCancel>
        <PageDialogSubmit
          onClick={onSubmit}
          data-testid="ed-confirmation-dialog-submit"
        >
          Yes
        </PageDialogSubmit>
      </DialogActions>
    </PageDialog>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  isOpened: getForceModalIsOpen(state),
  message: getForceModalMessage(state),
});

const mapDispatchToProps: DispatchProps = {
  onSubmit: BOX_MY_ROSTERS_TRADE_FORCE_REQUEST,
  onClose: BOX_MY_ROSTERS_CONFIRMATION_MODAL_CLOSED,
};

export const ConfirmationModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationModalComponent);
