import React from 'react';
import classNames from 'clsx';
import { LegendPlaceholder } from '../../../../../components';

type Props = {
  width: number;
  color: 'primary' | 'gray-500';
};

export const Divider = ({ width, color }: Props) => (
  <div className="day-divider__wrapper">
    <LegendPlaceholder />
    <div
      className="day-divider__positioner"
      style={{
        width
      }}
    >
      <div
        className={classNames(
          'day-divider__divider',
          `day-divider__divider--${color}`
        )}
      />
    </div>
  </div>
);
