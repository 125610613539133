import React from 'react';
import { connect } from 'react-redux';
import { FetchWrapper } from 'element';
import { EMPLOYEE_DASHBOARD_OVERVIEW } from 'state/FetchPageData/constants';
import {
  EmployeeDashboardHeader,
  EmployeeDashboardLayout,
} from '../../components';
import View from './view';
import { BOX_EMPLOYEE_OVERVIEW_DATA_REQUEST } from '../../../../state/EmployeeDashboard/Overview';

type OwnProps = {};

type StateProps = {};

type DispatchProps = {
  fetchData: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const Overview = (props: Props) => (
  <EmployeeDashboardLayout header={<EmployeeDashboardHeader />}>
    <FetchWrapper {...props} pageId={EMPLOYEE_DASHBOARD_OVERVIEW}>
      <View />
    </FetchWrapper>
  </EmployeeDashboardLayout>
);

const mapDispatchToProps: DispatchProps = {
  fetchData: BOX_EMPLOYEE_OVERVIEW_DATA_REQUEST
};

export default connect(
  null,
  mapDispatchToProps
)(Overview);
