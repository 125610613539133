import { UserReducerState } from './types';
import { PAGE_SIZE } from 'lib/config';
import { getFilterForm } from './helpers';
import { filterTypes, sort } from './constants';
import { cloneDeep } from 'lodash';

export const getDefaultState = (): UserReducerState => ({
  pager: {
    currentPage: 1,
    pageSize: PAGE_SIZE[0],
  },
  storedPage: 1,
  storedPageSize: 10,
  sort,
  filters: cloneDeep(filterTypes.active),
  filtersForm: getFilterForm(filterTypes.active, sort),
  selectedFilter: 'active',
  errors: [],
  search: {
    query: '',
  },
  createUserModal: {
    isOpened: false,
    isUpdating: false,
    errors: [],
  },
  addRoleModal: {
    isOpened: false,
    isUpdating: false,
    errors: [],
    userId: '',
  },
  bulkAddRoleModal: {
    isOpened: false,
    isUpdating: false,
    errors: [],
  },
  editApproversModal: {
    isOpened: false,
    errors: [],
    isUpdating: false,
    userId: '',
  },
  bulkEditApproversModal: {
    isOpened: false,
    errors: [],
    isUpdating: false,
  },
  bulkAssignLocation: {
    isOpened: false,
    errors: [],
    isUpdating: false,
  },
});
