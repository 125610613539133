import React from 'react';
import {
  NativeTable,
  Pagination,
  Heading,
  Paragraph,
  Divider,
} from 'elmo-elements';
import { ColumnsProps, DispatchProps } from '../../type';
import {
  getCurrencyFormatted,
  getDateTimeFormatted,
  getFormattedNumber,
  getTimeFormatted,
} from 'lib/helpers';
import {
  getCurrencyCode,
  getCurrencyPlacement,
  getDateFormat,
  getNumberFormat,
  getTimeFormat,
} from 'state/Account/selectors';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import { ReportPagination } from 'state/Report/state';
import {
  getCurrentPageRows,
  getGroups,
  getReportPagination,
  getReportTotals,
  getRows,
} from 'state/Report/selectors';
import _ from 'lodash';
import ReportTableHeader from '../ReportTableHeader';
import {
  PreferencesCurrencyCode,
  PreferencesCurrencyPlacement,
  PreferencesDateFormat,
  PreferencesNumberFormat,
  PreferencesTimeFormat,
} from 'type/models';
import { withFeatureFlags } from 'flags';
import { ExpectedFlag } from '../../../../../../feature-flags';

type CellData = any;

type Props = {
  rows: any[];
  pageRows: any[];
  visibleColumns: ColumnsProps[];
  groups: any[];
  pagination: ReportPagination;
  dateFormat: PreferencesDateFormat;
  timeFormat: PreferencesTimeFormat;
  numberFormat: PreferencesNumberFormat;
  currencyCode: PreferencesCurrencyCode;
  currencyPlacement: PreferencesCurrencyPlacement;
};

type OwnProps = {
  changePage: (page: number, start: number, end: number) => void;
  changePageSize: (size: number) => void;
  report: any;
  openModal: () => void;
} & {
  flags: {
    reportDatetime: ExpectedFlag;
  };
};

export class EventRows extends React.Component<Props & OwnProps, {}> {
  getValueDescription = (key: string, value: number) => {
    const { currencyPlacement, currencyCode, numberFormat } = this.props;
    if (key === 'percent') {
      return `${value}%`;
    }
    return getCurrencyFormatted(
      numberFormat,
      currencyCode,
      currencyPlacement,
      value
    );
  };

  showCellData = (key: string, data: CellData) => {
    const outputString = (v: string) => (v.length ? v : '-');
    const {
      dateFormat,
      timeFormat,
      numberFormat,
      currencyCode,
      currencyPlacement,
    } = this.props;
    if (this.isMoney(key)) {
      return getCurrencyFormatted(
        numberFormat,
        currencyCode,
        currencyPlacement,
        data
      );
    }
    if (key === 'date') {
      if (typeof data['start'] === 'object') {
        if (this.props.flags.reportDatetime) {
          return data['start'].date;
        }
        return getDateTimeFormatted(dateFormat, timeFormat, data['start'].date);
      } else {
        if (this.props.flags.reportDatetime) {
          return data['start'];
        }
        return getDateTimeFormatted(dateFormat, timeFormat, data['start']);
      }
    }
    if (key === 'timesheet_start' || key === 'timesheet_end') {
      if (data[key]) {
        if (this.props.flags.reportDatetime) {
          return data[key];
        }
        return getDateTimeFormatted(dateFormat, timeFormat, data[key], true);
      }
      return '-';
    }
    if (
      data[key] !== null &&
      typeof data[key] === 'object' &&
      Object.keys(data[key]).length
    ) {
      return (
        <div className={'inner-columns'}>
          {Object.keys(data[key]).map((columnName: string, index: number) => {
            return (
              <div key={index} className={'inner-column'}>
                {this.getValueDescription(columnName, data[key][columnName])}
              </div>
            );
          })}
        </div>
      );
    } else {
      let val =
        typeof data[key] === 'string'
          ? outputString(data[key])
          : getFormattedNumber(numberFormat, +data[key]);
      return key === 'start' || key === 'end'
        ? getTimeFormatted(timeFormat, data[key])
        : val;
    }
  };

  render() {
    const { pageRows, visibleColumns, report, groups } = this.props;

    const filtered: any = _.groupBy(pageRows, (row) => row.group_id);

    return (
      <>
        <div className={'report-scroll-container'}>
          {Object.keys(filtered).map((k: any, i: number) => (
            <React.Fragment key={`fragment-${k}-${i}`}>
              {i > 0 && (
                <>
                  <Divider />
                  <br />
                  <br />
                </>
              )}
              {this.getEventInfo(groups[filtered[k][0].group_id])}
              <br />
              <NativeTable
                key={`table-${k}-${i}`}
                isFullWidth={true}
                theme={'striped'}
              >
                <ReportTableHeader
                  report={report}
                  selectedColumns={visibleColumns}
                  openModal={this.props.openModal}
                />
                <NativeTable.Body>
                  {filtered[k].map((currentReport: any, trIndex: number) => (
                    <NativeTable.Tr key={`row-${trIndex}`}>
                      {visibleColumns.map(
                        (column: ColumnsProps, key: number) => (
                          <NativeTable.Td key={`${trIndex}-${key + 1}`}>
                            {this.showCellData(column.name, currentReport)}
                          </NativeTable.Td>
                        )
                      )}
                    </NativeTable.Tr>
                  ))}
                </NativeTable.Body>
              </NativeTable>
            </React.Fragment>
          ))}
        </div>
        <Pagination
          pageSize={this.props.pagination.pageSize}
          pageSizeOptions={this.props.pagination.pageSizeOptions}
          currentPage={this.props.pagination.currentPage}
          onPageChange={this.props.changePage}
          onPageSizeChange={this.props.changePageSize}
          totalResults={this.props.pagination.totalResults}
        />
      </>
    );
  }

  private getEventInfo = (event: any) => {
    const { totals } = event;
    const { dateFormat, timeFormat } = this.props;
    const size = 'sm';
    return (
      <>
        <Heading>Title: {totals.event_title || '-'}</Heading>
        <Paragraph size={size}>
          Dates:{' '}
          {getDateTimeFormatted(
            dateFormat,
            timeFormat,
            totals.event_start,
            false
          )}{' '}
          -{' '}
          {getDateTimeFormatted(
            dateFormat,
            timeFormat,
            totals.event_end,
            false
          )}
        </Paragraph>
        <Paragraph size={size}>Event {totals.event_item}</Paragraph>
        <Paragraph size={size}>
          Description: {totals.event_description || '-'}
        </Paragraph>
        <Paragraph size={size}>
          Address: {totals.event_address || '-'}
        </Paragraph>
        <Paragraph size={size}>
          Address name: {totals.event_address_name || '-'}
        </Paragraph>
        <Paragraph size={size}>
          Report: {totals.event_report_to || '-'}
        </Paragraph>
      </>
    );
  };

  private isMoney = (name: string) => {
    const { visibleColumns } = this.props;
    for (let column of visibleColumns) {
      if (column.name === name && column.is_money) {
        return true;
      }
    }
  };
}
const mapStateToProps = (state: StoreState) => ({
  pageRows: getCurrentPageRows(state),
  totals: getReportTotals(state),
  pagination: getReportPagination(state),
  groups: getGroups(state),
  rows: getRows(state),
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
  numberFormat: getNumberFormat(state),
  currencyCode: getCurrencyCode(state),
  currencyPlacement: getCurrencyPlacement(state),
});

export default connect(mapStateToProps)(withFeatureFlags(EventRows));
