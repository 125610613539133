import { SkeletonIconButton, SkeletonTypography } from 'element';
import {
  Box,
  DialogContent,
  DialogTitle,
  Skeleton,
} from 'extended-oxygen-elements';
import _ from 'lodash';
import React from 'react';

export default function ViewAndAssignEmployeesDialogSkeleton() {
  return (
    <>
      <DialogTitle endAdornment={<SkeletonIconButton />}>
        <SkeletonIconButton />
      </DialogTitle>
      <DialogContent>
        <SkeletonTypography variant="h5" />
        <SkeletonTypography />
        <Box mb={2}>
          <SkeletonTypography maxWidth="50%" />
        </Box>
        <Skeleton variant="rectangular" height={45} sx={{ mb: 3 }} />
        {_.times(5).map((n) => (
          <Box display="flex" alignItems="center" key={n} mb={0.5}>
            <Skeleton
              variant="circular"
              width={32}
              height={32}
              sx={{ mr: 1 }}
            />
            <SkeletonTypography maxWidth={120} />
            <Skeleton
              variant="rectangular"
              width="100%"
              height={20}
              sx={{ ml: 'auto', maxWidth: '80px' }}
            />
          </Box>
        ))}
      </DialogContent>
    </>
  );
}
