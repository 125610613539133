import moment, { Moment } from 'moment';
import { FormData } from './types';

export const getDefaultDates = (startDate: Moment): Pick<
  FormData,
  'start_date' | 'start_time' | 'end_date' | 'end_time' | 'until'
> => {
  return {
    start_date: moment(startDate),
    start_time: moment(startDate),
    end_date: moment(startDate),
    end_time: moment(startDate),
    until: moment(startDate)
  };
};

export const createUntilDate = (endDate: Moment, until: Moment = moment()) =>
  until.isBefore(endDate) ? moment(endDate) : until;