import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StoreState } from '../../state/types';
import { getCreateUserModal } from 'state/Users/Users/selectors';
import { CreateUserPayload } from 'state/Users/Users/types';
import {
  BOX_USERS_CREATE_MODAL_TOGGLE,
  BOX_USERS_CREATE_USER,
  BOX_USERS_CREATE_MODAL_CLEAR_ERRORS,
} from 'state/Users/Users';
import { SelectPropsOption } from 'type/models';
import { activeAreasBySiteIdSelector, getRoles } from 'state/AccountTree';
import {
  BOX_USER_GROUPS_REQUEST,
  userGroupsAsSelectOptionsArraySelector,
} from 'state/UserGroups';
import ErrorBox from '../ErrorBox';
import { State, DispatchProps, StateProps, Props } from './types';
import { availableUserRoles, isValid } from './helpers';
import { View } from './view';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';
import { PageDialog, PageDialogCancel, PageDialogSubmit } from 'element';

export class CreateUserModalComponent extends Component<Props, State> {
  areaId: string = '';
  roleId: string = '';

  state = {
    isExpanded: false,
    user: {
      first_name: '',
      last_name: '',
      email: '',
      role_id: this.roleId,
      area_id: this.areaId,
      user_group_id: 0,
    },
  };

  toggleDetails = () => {
    const { isExpanded } = this.state;
    this.setState({
      isExpanded: !isExpanded,
    });
  };

  componentDidMount() {
    this.props.getUserGroups();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (this.props.modal.isOpened !== prevProps.modal.isOpened) {
      this.setAreaRole();
      this.setState({
        isExpanded: false,
        user: {
          first_name: '',
          last_name: '',
          email: '',
          user_group_id: +this.defaultGroup.value,
          area_id: this.areaId,
          role_id: this.roleId,
        },
      });
    }
  }

  render() {
    const {
      modal: { errors },
    } = this.props;
    return (
      <PageDialog
        maxWidth={'xs'}
        open={this.props.modal.isOpened}
        id={'create-user-modal'}
        onClose={this.closeModal}
      >
        <DialogTitle>Create user</DialogTitle>
        <DialogContent>
          {errors.length > 0 && (
            <ErrorBox errors={errors} clearErrors={this.props.clearErrors} />
          )}
          <View
            availableRoles={this.availableRoles}
            defaultGroup={this.defaultGroup}
            toggleDetails={this.toggleDetails}
            handleAreaRoleChange={this.handleAreaRoleChange}
            handleFieldChange={this.handleFieldChange}
            isExpanded={this.state.isExpanded}
            userGroupsOptions={this.filteredOptions}
            selectedGroup={this.selectedGroup}
            selectedRole={this.selectedRole}
          />
        </DialogContent>
        <DialogActions>
          <PageDialogCancel onClick={this.closeModal}>Cancel</PageDialogCancel>
          <PageDialogSubmit
            onClick={this.createUser}
            loading={this.props.modal.isUpdating}
            disabled={!isValid(this.state.user) || this.props.modal.isUpdating}
          >
            Create
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  }

  closeModal = () => this.props.toggle(false);

  createUser = () => {
    const { user } = this.state;
    this.props.createUser(user);
  };

  handleFieldChange = (
    name: keyof CreateUserPayload,
    value: string | number
  ) => {
    this.setState({
      ...this.state,
      user: {
        ...this.state.user,
        [name]: value,
      },
    });
  };

  get filteredOptions(): SelectPropsOption[] {
    const { userGroupsOptions } = this.props;
    const options: SelectPropsOption[] = [];
    for (let groupOption of userGroupsOptions) {
      const current = groupOption.label.toLowerCase();
      if (current !== 'super admin' && current !== 'account admin') {
        options.push(groupOption);
      }
    }
    return options;
  }

  handleAreaRoleChange = (option: SelectPropsOption) => {
    const values = option.value.split('--');
    this.setState({
      ...this.state,
      user: {
        ...this.state.user,
        area_id: values[0],
        role_id: values[1],
      },
    });
  };

  get availableRoles() {
    const { areasBySite, roles, siteId } = this.props;
    return availableUserRoles(areasBySite, roles, siteId);
  }

  get selectedRole() {
    const options = this.availableRoles;
    const {
      user: { area_id, role_id },
    } = this.state;
    for (let option of options) {
      const value = `${area_id}--${role_id}`;
      if (option.value === value) {
        return option;
      }
    }
    return this.availableRoles[0];
  }

  setAreaRole = () => {
    const values = this.availableRoles[0]
      ? this.availableRoles[0].value.split('--')
      : ['', ''];
    this.areaId = values[0];
    this.roleId = values[1];
  };

  get defaultGroup(): SelectPropsOption {
    const options: SelectPropsOption[] = this.filteredOptions;
    for (let option of options) {
      if (option.label === 'Employee') {
        return option;
      }
    }
    return {
      label: 'No permissions',
      value: '',
    };
  }

  get selectedGroup(): SelectPropsOption {
    const options: SelectPropsOption[] = this.filteredOptions;
    const {
      user: { user_group_id },
    } = this.state;
    for (let option of options) {
      if (+option.value === +user_group_id) {
        return option;
      }
    }
    return this.defaultGroup;
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  modal: getCreateUserModal(state),
  areasBySite: activeAreasBySiteIdSelector(state),
  roles: getRoles(state),
  userGroupsOptions: userGroupsAsSelectOptionsArraySelector(state),
});

const mapDispatchToProps: DispatchProps = {
  toggle: BOX_USERS_CREATE_MODAL_TOGGLE,
  getUserGroups: BOX_USER_GROUPS_REQUEST,
  createUser: BOX_USERS_CREATE_USER,
  clearErrors: BOX_USERS_CREATE_MODAL_CLEAR_ERRORS,
};

export const CreateUserModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateUserModalComponent);
