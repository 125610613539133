import React from 'react';
import LoaderHolder from './LoaderHolder';
import { LoaderHolderProps } from '../type';

function CodeStyle(props: LoaderHolderProps) {
  return (
    <LoaderHolder {...props}>
      <rect x="0" y="10" rx="3" ry="3" width="70" height="10" />
      <rect x="80" y="10" rx="3" ry="3" width="100" height="10" />
      <rect x="190" y="10" rx="3" ry="3" width="10" height="10" />

      <rect x="15" y="30" rx="3" ry="3" width="130" height="10" />
      <rect x="155" y="30" rx="3" ry="3" width="130" height="10" />

      <rect x="15" y="50" rx="3" ry="3" width="90" height="10" />
      <rect x="115" y="50" rx="3" ry="3" width="60" height="10" />
      <rect x="185" y="50" rx="3" ry="3" width="60" height="10" />

      <rect x="0" y="70" rx="3" ry="3" width="30" height="10" />
    </LoaderHolder>
  );
}

export default CodeStyle;
