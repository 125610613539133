import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compact, flatten, map, uniq } from 'lodash';
import { Button, FormItem, Modal } from 'elmo-elements';
import {
  AccountTreeArea,
  AccountTreeRole,
  LanguagePreferences,
  StringMap,
} from 'type';
import { capitalize, keyMirror } from 'lib/helpers';
import { Select } from 'element';
import { SelectPropsOption } from 'elmo-elements/Select';
import { OnChangeMultiSelectEvent } from 'element/Select/types';
import { StoreState } from 'state/types';
import { asSelectOptionCombiner } from 'state/combiners';
import { getLangPreferences } from 'state/Account';
import {
  BOX_MANAGER_DASHBOARD_FILTERS_MODAL_CLOSE,
  BOX_MANAGER_DASHBOARD_UPDATE_FILTERS_REQUEST,
  getFetchedFilters,
  getIsOpened,
  getIsUpdating,
} from 'state/ManagerDashboard/ManagerDashboard';
import {
  activeAreasBySiteIdAsSelectOptionsSelector,
  activeAreasSelector,
  activeRolesSelector,
  activeSitesAsSelectOptionsArraySelector,
} from 'state/AccountTree';

type OwnProps = {};

type StateProps = {
  isOpened: boolean;
  isUpdating: boolean;
  initState: FormData;
  langPreferences: LanguagePreferences;
  activeSitesAsSelectOptions: SelectPropsOption[];
  activeAreasAsSelectOptionsBySite: StringMap<SelectPropsOption[]>;
  activeAreas: StringMap<AccountTreeArea>;
  activeRoles: StringMap<AccountTreeRole>;
};

type DispatchProps = {
  onClose: () => void;
  onSubmit: (payload: FormData) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

type FormData = {
  site_ids: string[];
  area_ids: string[];
  role_ids: string[];
};

type State = {
  formData: FormData;
  isDirty: boolean;
};

const getState = ({ initState }: Props): State => ({
  formData: initState,
  isDirty: false
});

export class ManagerDashboardFiltersComponent extends Component<Props, State> {
  readonly state = getState(this.props);
  readonly inputNames = keyMirror(this.state.formData);

  render() {
    const {
      props: {
        isOpened,
        isUpdating,
        onClose,
        langPreferences,
        activeSitesAsSelectOptions
      },
      state: { formData, isDirty },
      inputNames
    } = this;
    return (
      <Modal
        id="FilterCustomModal"
        type="side"
        isOpened={isOpened}
        closeModal={onClose}
        title="Filter"
        primaryButton={
          <Button
            type="primary"
            onClick={this.onSubmit}
            isLoading={isUpdating}
            isDisabled={!isDirty}
          >
            Apply
          </Button>
        }
      >
        <FormItem label={capitalize(langPreferences.site.plural)} id={'filter-sites'}>
          <Select
            name={inputNames.site_ids}
            value={formData.site_ids}
            options={activeSitesAsSelectOptions}
            onChange={this.onChangeSelect}
            isMulti={true}
            ariaLabel={capitalize(langPreferences.site.plural)}
          />
        </FormItem>

        <FormItem label={capitalize(langPreferences.area.plural)} id={'filter-areas'}>
          <Select
            name={inputNames.area_ids}
            value={formData.area_ids}
            options={this.areaOptions}
            onChange={this.onChangeSelect}
            isDisabled={!formData.site_ids.length}
            isMulti={true}
            ariaLabel={capitalize(langPreferences.area.plural)}
          />
        </FormItem>

        <FormItem label={capitalize(langPreferences.role.plural)} id={'filter-roles'}>
          <Select
            name={inputNames.role_ids}
            value={formData.role_ids}
            options={this.roleOptions}
            onChange={this.onChangeSelect}
            isDisabled={!formData.area_ids.length}
            isMulti={true}
            ariaLabel={capitalize(langPreferences.role.plural)}
          />
        </FormItem>
      </Modal>
    );
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (this.props.isOpened && prevProps.isOpened !== this.props.isOpened) {
      this.setState(getState(this.props));
    }
  }

  private onChangeSelect = ({ name, value }: OnChangeMultiSelectEvent) => {
    const stateChanges: { [key in keyof FormData]: Partial<FormData> } = {
      site_ids: {
        site_ids: value,
        area_ids: [],
        role_ids: []
      },
      area_ids: {
        area_ids: value,
        role_ids: []
      },
      role_ids: {
        role_ids: value
      }
    };

    this.setState({
      formData: {
        ...this.state.formData,
        ...stateChanges[name as keyof FormData]
      },
      isDirty: true
    });
  };

  private onSubmit = () => {
    this.props.onSubmit(this.state.formData);
  };

  private get areaOptions(): SelectPropsOption[] {
    const { site_ids } = this.state.formData;

    return compact(
      flatten(
        map(
          site_ids,
          siteId => this.props.activeAreasAsSelectOptionsBySite[siteId]
        )
      )
    );
  }

  private get selectedAreas(): AccountTreeArea[] {
    return map(
      this.state.formData.area_ids,
      areaId => this.props.activeAreas[areaId]
    );
  }

  private get roleOptions(): SelectPropsOption[] {
    const roleIds: string[] = uniq(
      flatten(map(this.selectedAreas, area => area.role_ids))
    );

    const roles: AccountTreeRole[] = compact(
      map(roleIds, roleId => this.props.activeRoles[roleId])
    );

    return map(roles, asSelectOptionCombiner);
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  isOpened: getIsOpened(state),
  isUpdating: getIsUpdating(state),
  initState: getFetchedFilters(state),
  langPreferences: getLangPreferences(state),
  activeSitesAsSelectOptions: activeSitesAsSelectOptionsArraySelector(state),
  activeAreasAsSelectOptionsBySite: activeAreasBySiteIdAsSelectOptionsSelector(
    state
  ),
  activeAreas: activeAreasSelector(state),
  activeRoles: activeRolesSelector(state)
});

const mapDispatchToProps: DispatchProps = {
  onClose: BOX_MANAGER_DASHBOARD_FILTERS_MODAL_CLOSE,
  onSubmit: BOX_MANAGER_DASHBOARD_UPDATE_FILTERS_REQUEST
};

export const ManagerDashboardFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagerDashboardFiltersComponent);
