import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BOX_PAY_ELEMENTS_PAGE_ERRORS_CLOSED,
  getPageErrors,
} from 'state/PayElementsPage';
import { AlertErrorBoxLayout } from 'element';

function PageErrors() {
  const dispatch = useDispatch();
  const errors = useSelector(getPageErrors);

  const handleErrorsClose = () => {
    dispatch(BOX_PAY_ELEMENTS_PAGE_ERRORS_CLOSED());
  };

  return (
    <AlertErrorBoxLayout
      BoxProps={{
        mb: 2
      }}
      errors={errors}
      onClose={handleErrorsClose}
    />
  );
}

export default PageErrors;
