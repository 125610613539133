import { Moment } from 'moment';
import { getShiftDuration } from 'lib/helpers';
import { DefaultBreakItem, ShiftTemplateBreak, ShiftTemplateItem } from 'type';
import { conditions } from '../../helpers';

export const getDaysArray = function() {
  let arr: string[] = [];
  for (let i = 1; i <= 7; i += 1) {
    arr.push(i.toString(10));
  }
  return arr;
};

export const getScheduleConfig = () => {
  const weekDays: string[] = getDaysArray();
  let dayNames: any = {};
  for (let day of weekDays) {
    dayNames[day] = [];
  }
  return {
    dayNames: dayNames,
    weekDays: weekDays
  };
};

const filterId = (
  shiftId: string | null,
  acceptableIds: string[]
): string | null => {
  if (!shiftId) {
    return null;
  }

  if (acceptableIds.includes(shiftId)) {
    return shiftId;
  }

  return null;
};

export const getRosterCell = (
  list: any,
  currentDay: number,
  filteredRosteredShiftIds: string[],
) => {
  if (list.length && list[currentDay]) {
    const shift: ShiftTemplateItem = list[currentDay];
    return {
      rosteredShiftId: filterId(shift.id, filteredRosteredShiftIds),
      timesheetId: null
    };
  }
  return {};
};

export const filterShiftItemsByUserId = (
  userID: string | null,
  data: ShiftTemplateItem[]
): ShiftTemplateItem[] => {
  return data.filter((rData: { user_id: string | null }) => rData.user_id === userID);
};

export const filterShiftItemsByAreaRoleId = (
  areaId: string,
  roleId: string,
  data: ShiftTemplateItem[]
): ShiftTemplateItem[] => {
  return data.filter((rData: { role_id: string; area_id: string }) => {
    return rData.role_id === roleId && rData.area_id === areaId;
  });
};

export const buildShiftItemsRows = (
  data: any[],
  filteredRosteredShifts: ShiftTemplateItem[]
): {
  rows: any;
  rowsNumber: number;
} => {
  const filteredRosteredShiftIds: string[] = filteredRosteredShifts.map(
    ({ id }: ShiftTemplateItem) => id
  );
  let maxRostersInDay = 0;
  const matrixArr: any[] = [];

  const { weekDays, dayNames } = getScheduleConfig();
  for (let item of data) {
    const date = item.day;
    if (dayNames[date]) {
      dayNames[date].push(item);
      if (dayNames[date].length > maxRostersInDay) {
        maxRostersInDay += 1;
      }
    }
  }

  if (maxRostersInDay === 0) {
    maxRostersInDay = 1;
  }

  for (let i = 0; i < maxRostersInDay; i += 1) {
    matrixArr.push(new Array(weekDays.length));
    for (let j = 0; j < weekDays.length; j += 1) {
      matrixArr[i][j] = getRosterCell(
        dayNames[weekDays[j]],
        i,
        filteredRosteredShiftIds
      );
    }
  }

  return {
    rowsNumber: maxRostersInDay,
    rows: matrixArr
  };
};

export const isNotEmptyRow = (rowsItems: any) => {
  for (let row of rowsItems) {
    for (let col of row) {
      if (col.hasOwnProperty('rosteredShiftId')) {
        return true;
      }
    }
  }
  return false;
};

export const getDefaultBreak = (
  start: Moment,
  end: Moment,
  rules: DefaultBreakItem[],
  timezoneId: string = 'Europe/Kiev'
) => {
  const shiftDuration = getShiftDuration(
    start,
    end,
    [],
    timezoneId
  ).total_hrs * 60;

  let defaultBreak: ShiftTemplateBreak = {
    duration: 0,
    paid: false,
    start_diff: 0
  }

  let result = false;
  let i = 0;

  do {
    const rule = rules[i];
    result = conditions[rule.condition](shiftDuration, rule.shift_duration);

    defaultBreak = {
      ...defaultBreak,
      duration: rule.break_duration,
      start_diff: ((shiftDuration / 2) - (rule.break_duration) / 2) * 60
    }
    i++;
  } while (!result);

  if (defaultBreak.duration >= shiftDuration) {
    defaultBreak = {
      duration: 0,
      paid: false,
      start_diff: 0
    }
  }

  return defaultBreak;
}
