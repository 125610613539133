import React, { Component } from 'react';
import {
  CloseIcon,
  DoneAllIcon,
  DoneIcon,
  PageDialog,
  PageDialogCancel,
} from 'element';
import { SelectList, Text } from 'elmo-elements';
import { BOX_ROSTER_GLOBAL_APPROVE_TIMESHEET_ALL } from 'state/Roster/Roster/actions';
import { connect } from 'react-redux';
import { getFrom, getSiteId, getTo } from 'state/RosteredShiftsCollection';
import { StoreState } from 'state/types';
import { Moment } from 'moment';
import { ApproveTimesheetAllModalPayload } from 'lib/Api/type';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';

type OwnProps = {
  isOpened: boolean;
  closeModal: () => void;
  toggleMode: () => void;
  isApprovalMode: boolean;
  siteId: string;
  from: Moment;
  to: Moment;
  isApproving: boolean;
};

type DispatchProps = {
  approveAllTimesheets: (params: ApproveTimesheetAllModalPayload) => void;
};

type Props = OwnProps & DispatchProps;

export class ApproveModal extends Component<Props> {
  render() {
    const { isApproving } = this.props;
    return (
      <PageDialog
        maxWidth={'xs'}
        open={this.props.isOpened}
        id={'approve-shifts-changes'}
        onClose={this.props.closeModal}
      >
        <DialogTitle>Approve changes</DialogTitle>
        <DialogContent>
          <SelectList>
            <SelectList.Option
              onToggle={this.toggleMode}
              key={1}
              icon={this.props.isApprovalMode ? <CloseIcon /> : <DoneIcon />}
              isDisabled={isApproving}
            >
              <Text
                size={'xs'}
                className={isApproving ? 'publish-in-progress' : ''}
              >
                Enable approval mode
              </Text>
            </SelectList.Option>
            <SelectList.Option
              onToggle={this.approveAll}
              key={2}
              icon={<DoneAllIcon />}
              isDisabled={isApproving}
            >
              <Text
                size={'xs'}
                className={isApproving ? 'publish-in-progress' : ''}
              >
                Approve all timesheets without violations
              </Text>
            </SelectList.Option>
          </SelectList>
        </DialogContent>
        <DialogActions>
          <PageDialogCancel onClick={this.props.closeModal}>
            Close
          </PageDialogCancel>
        </DialogActions>
      </PageDialog>
    );
  }

  toggleMode = () => {
    this.props.toggleMode();
    this.props.closeModal();
  };

  approveAll = () => {
    const params = {
      site_id: this.props.siteId,
      date: this.props.from.format('YYYY-MM-DD'),
      from: this.props.from,
      to: this.props.to,
    };
    this.props.approveAllTimesheets(params);
  };
}

const mapStateToProps = (state: StoreState) => ({
  siteId: getSiteId(state),
  from: getFrom(state),
  to: getTo(state),
  isApproving: state.roster.isApproving,
});

const mapToDispatchProps: DispatchProps = {
  approveAllTimesheets: BOX_ROSTER_GLOBAL_APPROVE_TIMESHEET_ALL,
};

export default connect(mapStateToProps, mapToDispatchProps)(ApproveModal);
