import { createAction } from 'lib/store-utils';
import { FormattedErrors, ShiftOfferWithProposals } from 'type';
import { ApproveOrDeclineShiftOfferPayload, GetPageDataPayload, UpdateOutdatedStatePayload } from './types';

export const BOX_SHIFT_OFFER_GET_PAGE_DATA_REQUEST = createAction<GetPageDataPayload>('Shift offer / Get page data request');
export const BOX_SHIFT_OFFER_CLEAR_STATE = createAction('Shift offer / clear state');
export const BOX_SHIFT_OFFER_DELETE_SHIFT_OFFER = createAction('Shift offer / delete shift offer');
export const BOX_SHIFT_OFFER_DECLINE_SHIFT_OFFER_PROPOSAL = createAction<ApproveOrDeclineShiftOfferPayload>('Shift offer / decline shift offer proposal');
export const BOX_SHIFT_OFFER_APPROVE_SHIFT_OFFER_PROPOSAL = createAction<ApproveOrDeclineShiftOfferPayload>('Shift offer / approve shift offer proposal');
export const BOX_SHIFT_OFFER_SET_DEFAULT_STATE = createAction('Shift offer / set default state');
export const BOX_SHIFT_OFFER_UPDATE_SHIFT_OFFER = createAction<ShiftOfferWithProposals>('Shift offer / update shift offer');
export const BOX_SHIFT_OFFER_DECLINE_SHIFT_OFFER_PROPOSAL_SUCCESS = createAction<ApproveOrDeclineShiftOfferPayload>('Shift offer / decline shift offer proposal success');
export const BOX_SHIFT_OFFER_SET_OUTDATED_STATE = createAction<UpdateOutdatedStatePayload>('Shift offer / set outdated state');
export const BOX_SHIFT_OFFER_SET_ERRORS = createAction<FormattedErrors>('Shift offer / set errors');
