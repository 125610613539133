import { createReducer } from 'lib/store-utils';
import { FormattedErrors } from 'type';
import { TimesheetsReducerState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';

export const Timesheets = createReducer<TimesheetsReducerState>(
  {},
  getDefaultState()
);

const requestHandler = (
  state: TimesheetsReducerState
): TimesheetsReducerState => ({
  ...state,
  errors: [],
  newShift: {
    ...state.newShift,
    isOpened: false
  }
});

const failureHandler = (
  state: TimesheetsReducerState,
  errors: FormattedErrors
): TimesheetsReducerState => ({
  ...state,
  newShift: {
    ...state.newShift,
    isOpened: false
  },
  errors
});

Timesheets.on(actions.BOX_TIMESHEETS_GET_SITE_REQUEST, requestHandler);
Timesheets.on(actions.BOX_TIMESHEETS_GET_SITE_FAILURE, failureHandler);

Timesheets.on(actions.BOX_TIMESHEETS_GET_DAY_REQUEST, requestHandler);
Timesheets.on(actions.BOX_TIMESHEETS_GET_DAY_FAILURE, failureHandler);

Timesheets.on(actions.BOX_TIMESHEETS_GET_PREV_DAY_REQUEST, requestHandler);
Timesheets.on(actions.BOX_TIMESHEETS_GET_NEXT_DAY_REQUEST, requestHandler);
Timesheets.on(
  actions.BOX_TIMESHEETS_GET_PREV_NEXT_DAY_FAILURE,
  failureHandler
);

Timesheets.on(actions.BOX_TIMESHEETS_ERRORS_CLEARED, requestHandler);

Timesheets.on(
  actions.BOX_TIMESHEETS_TOGGLE_FILTER_TYPE,
  (state, selectedFilter): TimesheetsReducerState => ({
    ...state,
    selectedFilter
  })
);

Timesheets.on(
  actions.BOX_TIMESHEETS_SHOW_FORM,
  (state): TimesheetsReducerState => ({
    ...state,
    newShift: {
      ...state.newShift,
      isOpened: true
    }
  })
);

Timesheets.on(
  actions.BOX_TIMESHEETS_HIDE_FORM,
  (state): TimesheetsReducerState => ({
    ...state,
    newShift: {
      ...state.newShift,
      isOpened: false,
      errors: []
    },
    shifts: {}
  })
);

Timesheets.on(
  actions.BOX_TIMESHEETS_CREATE_REQUEST,
  (state): TimesheetsReducerState => ({
    ...state,
    newShift: {
      ...state.newShift,
      isLoading: true
    }
  })
);

Timesheets.on(
  actions.BOX_TIMESHEETS_CREATE_SUCCESS,
  (state): TimesheetsReducerState => ({
    ...state,
    shifts: {},
    newShift: {
      ...state.newShift,
      isLoading: false,
      isOpened: false,
      errors: []
    }
  })
);

Timesheets.on(
  actions.BOX_TIMESHEETS_CREATE_FAILURE,
  (state, errors): TimesheetsReducerState => ({
    ...state,
    newShift: {
      ...state.newShift,
      isLoading: false,
      isOpened: true,
      errors
    }
  })
);

Timesheets.on(
  actions.BOX_TIMESHEETS_UPDATE_REQUEST,
  (state, timesheet): TimesheetsReducerState => ({
    ...state,
    shifts: {
      ...state.shifts,
      [timesheet.id]: {
        ...state.shifts[timesheet.id],
        isLoading: true,
        errors: [],
      }
    },
    approvalModal: {
      isOpened: false
    }
  })
);

Timesheets.on(
  actions.BOX_TIMESHEETS_UPDATE_SUCCESS,
  (state, id): TimesheetsReducerState => ({
    ...state,
    shifts: {
      ...state.shifts,
      [id]: {
        ...state.shifts[id],
        isLoading: false
      }
    }
  })
);

Timesheets.on(
  actions.BOX_TIMESHEETS_UPDATE_FAILURE,
  (state, payload): TimesheetsReducerState => ({
    ...state,
    shifts: {
      ...state.shifts,
      [payload.id]: {
        ...state.shifts[payload.id],
        errors: payload.errors,
        isLoading: false
      }
    }
  })
);

Timesheets.on(
  actions.BOX_TIMESHEETS_ERROR_CLEARED,
  (state, id): TimesheetsReducerState => ({
    ...state,
    shifts: {
      ...state.shifts,
      [id]: {
        ...state.shifts[id],
        errors: []
      }
    }
  })
);

Timesheets.on(
  actions.BOX_TIMESHEETS_ERROR_CLEARED,
  (state, id): TimesheetsReducerState => ({
    ...state,
    newShift: {
      ...state.newShift,
      errors: []
    }
  })
);

Timesheets.on(
  actions.BOX_TIMESHEETS_APPROVE_REQUEST,
  (state, id): TimesheetsReducerState => ({
    ...state,
    shifts: {
      ...state.shifts,
      [id]: {
        ...state.shifts[id],
        isLoading: true
      }
    }
  })
);
Timesheets.on(
  actions.BOX_TIMESHEETS_APPROVE_CANCEL,
  (state, id): TimesheetsReducerState => ({
    ...state,
    shifts: {
      ...state.shifts,
      [id]: {
        ...state.shifts[id],
        isLoading: false
      }
    }
  })
);
Timesheets.on(
  actions.BOX_TIMESHEETS_APPROVE_SUCCESS,
  (state, id): TimesheetsReducerState => ({
    ...state,
    shifts: {
      ...state.shifts,
      [id]: {
        ...state.shifts[id],
        isLoading: false
      }
    }
  })
);

Timesheets.on(
  actions.BOX_TIMESHEETS_APPROVE_FAILURE,
  (state, { id, errors }): TimesheetsReducerState => ({
    ...state,
    shifts: {
      ...state.shifts,
      [id]: {
        ...state.shifts[id],
        errors,
        isLoading: false
      }
    }
  })
);

Timesheets.on(
  actions.BOX_TIMESHEETS_DELETE_MODAL_OPEN,
  (state): TimesheetsReducerState => ({
    ...state,
    deleteModal: {
      ...state.deleteModal,
      isOpened: true
    }
  })
);

Timesheets.on(
  actions.BOX_TIMESHEETS_DELETE_MODAL_SUBMIT,
  (state): TimesheetsReducerState => ({
    ...state,
    deleteModal: {
      ...state.deleteModal,
      isUpdating: true
    }
  })
);

Timesheets.on(
  actions.BOX_TIMESHEETS_DELETE_MODAL_CLOSE,
  (state): TimesheetsReducerState => ({
    ...state,
    deleteModal: {
      ...state.deleteModal,
      isOpened: false,
      isUpdating: false,
      errors: []
    }
  })
);

Timesheets.on(
  actions.BOX_TIMESHEETS_DELETE_FAILURE,
  (state, errors): TimesheetsReducerState => ({
    ...state,
    deleteModal: {
      ...state.deleteModal,
      isUpdating: false,
      errors
    }
  })
);

Timesheets.on(
  actions.BOX_TIMESHEETS_CONFIRMATION_MODAL_OPEN,
  (state, errors): TimesheetsReducerState => ({
    ...state,
    confirmationModal: {
      ...state.confirmationModal,
      isOpened: true,
      errors
    }
  })
);

Timesheets.on(
  actions.BOX_TIMESHEETS_CONFORMATION_MODAL_CLOSE,
  (state): TimesheetsReducerState => ({
    ...state,
    confirmationModal: {
      ...state.confirmationModal,
      isOpened: false,
      errors: []
    },
    newShift: {
      ...state.newShift,
      isLoading: false,
    }
  })
);

Timesheets.on(
  actions.BOX_TIMESHEETS_CONFORMATION_MODAL_SUBMIT,
  (state): TimesheetsReducerState => ({
    ...state,
    confirmationModal: {
      ...state.confirmationModal,
      isOpened: false,
      errors: []
    }
  })
);

Timesheets.on(
  actions.BOX_TIMESHEETS_APPROVAL_MODAL_OPEN,
  (state, timesheet): TimesheetsReducerState => ({
    ...state,
    approvalModal: {
      ...state.approvalModal,
      isOpened: true
    }
  })
);

Timesheets.on(
  actions.BOX_TIMESHEETS_APPROVAL_MODAL_CLOSE,
  (state): TimesheetsReducerState => ({
    ...state,
    approvalModal: {
      isOpened: false,
    }
  })
);

Timesheets.on(
  actions.BOX_TIMESHEETS_LOAD_ROSTERED_SHIFTS_REQUEST,
  (state): TimesheetsReducerState => ({
    ...state,
    newShift: {
      ...state.newShift,
      isLoadingShifts: true
    }
  })
);

Timesheets.on(
  actions.BOX_TIMESHEETS_LOAD_ROSTERED_SHIFTS_SUCCESS,
  (state, rosteredShifts): TimesheetsReducerState => ({
    ...state,
    newShift: {
      ...state.newShift,
      rosteredShifts,
      isLoadingShifts: false
    }
  })
);

Timesheets.on(
  actions.BOX_TIMESHEETS_LOAD_ROSTERED_SHIFTS_FAILED,
  (state, errors): TimesheetsReducerState => ({
    ...state,
    newShift: {
      ...state.newShift,
      errors,
      isLoadingShifts: false
    }
  })
);

Timesheets.on(
  actions.BOX_TIMESHEETS_CLEAR_ROSTERED_SHIFTS,
  (state): TimesheetsReducerState => ({
    ...state,
    newShift: {
      ...state.newShift,
      rosteredShifts: {}
    }
  })
);
