import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'elmo-elements';
import { ArrowBackIcon } from 'element/icons';
import { Event } from 'type';
import { StoreState } from 'state/types';
import { getSiteId } from 'state/RosteredShifts';
import { getUserListResponse } from 'state/UsersCollection';
import {
  Map,
  Marker,
  PageDialog,
  PageDialogBackIconButton,
  PageDialogCancel,
} from 'element';
import { DialogActions, DialogTitle } from 'oxygen-elements';
import DialogContent from 'extended-oxygen-elements/DialogContent';

type OwnProp = {
  isOpened: boolean;
  closeModal: () => void;
  event: Event;
  initMap: boolean;
};

type Props = OwnProp;

export class MapModal extends Component<Props> {
  render() {
    const { lat, lng } = this.props.event;

    const markerCoords = { lat, lng };

    return (
      <PageDialog
        id={'event-map-modal'}
        maxWidth={'sm'}
        open={this.props.isOpened}
        className={'event-map-modal'}
      >
        <DialogActions
          className={'shift-modal-header'}
          sx={{ boxShadow: '0 0 6px 2px #ccc', zIndex: 3, padding: 0 }}
        >
          <DialogTitle>
            <PageDialogBackIconButton onClose={this.props.closeModal} />
            Map
          </DialogTitle>
        </DialogActions>
        <DialogContent className={'shift-modal-content'}>
          {this.props.initMap && (
            <Map center={markerCoords}>
              <Marker position={markerCoords} />
            </Map>
          )}
        </DialogContent>
        <DialogActions sx={{ boxShadow: '0 0 6px 2px #ccc', zIndex: 3 }}>
          <PageDialogCancel
            onClick={this.props.closeModal}
            fullWidth={false}
            size={'large'}
          >
            Cancel
          </PageDialogCancel>
        </DialogActions>
      </PageDialog>
    );
  }
}

const mapStateToProps = (state: StoreState) => ({
  usersList: state.rosterShiftModal.usersList,
  shift: state.rosterShiftModal.currentRosteredShift,
  siteId: getSiteId(state),
  users: getUserListResponse(state),
});

export default connect(mapStateToProps)(MapModal);
