import React from 'react';
import { connect } from 'react-redux';
import { ErrorBox } from 'element';
import { StoreState } from 'state/types';
import { getErrors } from 'state/Users/Users';

type OwnProps = {};

type StateProps = {
  errors: string[];
};

type Props = OwnProps & StateProps;

const UsersErrorBoxComponent = (props: Props) => <ErrorBox {...props} />;

const mapStateToProps = (state: StoreState): StateProps => ({
  errors: getErrors(state)
});

export const UsersErrorBox = connect(mapStateToProps)(UsersErrorBoxComponent);
