import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { some } from 'lodash';
import { SagaAction, TimeOff } from 'type';
import { outDatedDataMessage } from 'messages';
import { showOutDatedNotification } from 'state/ToastNotifier';
import {
  getFetchFlags,
  MANAGER_DASHBOARD_UNAVAILABILITY,
} from 'state/FetchPageData';
import * as actions from '../actions';
import { DeleteTimeOffPayload } from '../types';
import { createTimeOffHash } from '../helpers';
import { getIsCurrentlyUpdating } from '../selectors';

const isFetched = (params: ReturnType<typeof getFetchFlags>): boolean => {
  return params.isFetched && !params.isFetching;
};

export const showUnavailabilityMessage = function*(): SagaIterator {
  const notificationId = 'managerTimeOffsOutdated';

  const { undo } = yield call(showOutDatedNotification, {
    notificationId,
    message: outDatedDataMessage,
    undoText: 'Reload'
  });

  if (undo) {
    yield put(actions.BOX_UNAVAILABILITY_GET_PAGE_DATA_REQUEST());
  }
};

const processTimeOffChanges = function*(
  { payload }: SagaAction<TimeOff | DeleteTimeOffPayload>
): SagaIterator {
  const unavailabilities: ReturnType<typeof getFetchFlags> = yield select(
    getFetchFlags,
    MANAGER_DASHBOARD_UNAVAILABILITY
  );
  const isUpdating = yield select(getIsCurrentlyUpdating);
  const hash = createTimeOffHash(payload);

  if (isFetched(unavailabilities) && some([payload.id, hash], isUpdating)) {
    yield call(showUnavailabilityMessage);
    yield put(actions.BOX_UNAVAILABILITY_UNSET_IS_CURRENTLY_UPDATING());
  }
};

export const watchSocketUpdates = function*(): SagaIterator {
  yield takeLatest(actions.BOX_MANAGER_TIMEOFF_CHANGED, processTimeOffChanges);
  yield takeLatest(actions.BOX_MANAGER_TIMEOFF_DELETED, processTimeOffChanges);
};
