import React, { Component } from 'react';
import './NotificationItem.scss';
import { Button, Heading, Paragraph, Text } from 'elmo-elements';
import { AssignmentLateOutlinedIcon, DeleteOutlinedIcon } from 'element/icons';
import {
  NotificationListType,
  NotificationsListItem,
} from 'state/NotificationsList/types';
import moment from 'moment';
import { Avatar } from 'oxygen-elements';

type Props = {
  index: number;
  markAsRead: (id: string) => void;
  deleteItem: (id: string) => void;
  item: NotificationsListItem;
  isDeleting: boolean;
  type: NotificationListType;
};

type State = {
  message: number;
  deleting: number;
};

export class NotificationItem extends Component<Props, State> {
  state = {
    message: -1,
    deleting: -1,
  };

  render() {
    const { item, type, index } = this.props;
    return (
      <div className={`notifications-list__item ${this.isReadClassName}`}>
        <div className={'notifications-list__avatar'}>
          {type === 'manager' ? (
            <Avatar
              className={'user-avatar'}
              light
              alt={item.user_prefered_name_or_full_name}
              src={
                item.user_avatar_url !== null ? item.user_avatar_url : undefined
              }
            />
          ) : (
            <AssignmentLateOutlinedIcon className={'user-avatar'} />
          )}
        </div>
        <div
          className={'notifications-list__content'}
          onClick={this.toggleMessage}
        >
          <Heading
            isBold={true}
            isTruncate={true}
            className={'notifications-list__title'}
          >
            {item.user_prefered_name_or_full_name &&
            item.user_prefered_name_or_full_name !== '' ? (
              item.user_prefered_name_or_full_name
            ) : (
              <span className={'info-placeholder'} />
            )}
          </Heading>
          <Text size={'sm'} color={'gray'} className={'time-info'}>
            {this.timeReceived}
          </Text>
          <Paragraph
            className={'notifications-list__location'}
            size={'sm'}
            color={'gray'}
            isTruncate={true}
          >
            {item.user_location_title && item.user_location_title !== '' ? (
              item.user_location_title
            ) : (
              <span className={'info-placeholder small'} />
            )}
          </Paragraph>
          <div className={'notifications-list__content-message'}>
            <Paragraph
              className={'notifications-list__content-text'}
              isTruncate={this.state.message !== this.props.index}
            >
              {item.body}
            </Paragraph>
          </div>
        </div>
        <div className={'notifications-list__actions'}>
          <Button
            icon={<DeleteOutlinedIcon />}
            isText={true}
            onClick={this.deleteItem}
            className={'delete-btn'}
            isDisabled={this.state.deleting === index && this.props.isDeleting}
            isLoading={this.state.deleting === index && this.props.isDeleting}
            ariaLabel="Delete"
          />
        </div>
      </div>
    );
  }

  deleteItem = () => {
    const { item, index } = this.props;
    this.setState(
      {
        ...this.state,
        deleting: index,
      },
      () => {
        this.props.deleteItem(item.id);
      }
    );
  };

  toggleMessage = () => {
    const { message } = this.state;
    const { index, item } = this.props;
    this.setState({
      message: message === -1 ? index : -1,
    });
    if (item.read_at === null) {
      this.props.markAsRead(item.id);
    }
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (!this.props.isDeleting && this.state.deleting > -1) {
      this.setState({
        ...this.state,
        deleting: -1,
      });
    }
  }

  get timeReceived() {
    const { item } = this.props;
    let message = moment(item.notification_received_at).utc(true).fromNow();
    return message === 'a few seconds ago' ? 'just now' : message;
  }

  get isReadClassName() {
    const { item, index } = this.props;
    if (this.state.message === index) {
      return '';
    }
    return item.read_at !== null ? 'is-read' : '';
  }
}

export default NotificationItem;
