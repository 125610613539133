import React from 'react';
import { Typography } from 'extended-oxygen-elements';
import { createStyles, makeStyles } from '@mui/styles';

type Props = {
  children: React.ReactNode;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      ...theme.typography.h4,
      [theme.breakpoints.up('sm')]: {
        ...theme.typography.h3,
      },
      [theme.breakpoints.up('md')]: {
        ...theme.typography.h2,
      },
    },
  })
);

export function NewHeaderTitle({ children }: Props) {
  const classes = useStyles();
  return (
    <Typography component="h1" className={classes.root}>
      {children}
    </Typography>
  );
}
