import React, { ReactNode } from 'react';
import { Moment } from 'moment';
import { OnResizeEndPayload } from './types';
import { ResizableComponent } from './components/ResizableWrapper';
import { DayLimit } from './components/DayLimit/DayLimit';

type Props = {
  id: string;
  start: Moment;
  end: Moment;
  /* Day for calculation the limits of the timeline */
  day: Moment;
  children: ReactNode;
  onResizeEnd?: (payload: OnResizeEndPayload) => void;
  isResizable?: boolean;
  clientX?: number;
};

export const ResizableWrapper = ({
  start,
  end,
  day,
  onResizeEnd,
  isResizable,
  ...rest
}: Props) => (
  <DayLimit
    start={start}
    end={end}
    day={day}
    onResizeEnd={onResizeEnd}
    isResizable={isResizable}
  >
    {durations => <ResizableComponent {...durations} {...rest} day={day} />}
  </DayLimit>
);

export type ResizableWrapperOnResizeEndPayload = OnResizeEndPayload;
