import {
  AddIcon,
  DeleteForeverOutlinedIcon,
  DurationField,
  TimeField,
} from 'element';
import { useArrayHandler, useFormControlSpacing } from 'hooks';
import { TIMESHEET_MAX_BREAKS_NUMBER } from 'lib/config';
import _ from 'lodash';
import {
  Hidden,
  Box,
  Button,
  Divider,
  FormHelperText,
  IconButton,
  Tooltip,
} from 'extended-oxygen-elements';
import React from 'react';
import { FieldProps } from 'type';
import {
  BreakFieldGroupBreakErrorsCollection,
  BreaksFieldGroupBreak,
} from './types';
import { makeFormBreak } from './utils';

type Props = FieldProps<
  BreaksFieldGroupBreak[],
  BreakFieldGroupBreakErrorsCollection | undefined
>;

export default BreaksFieldGroup;

export function BreaksFieldGroup({ value, error = {}, onChange }: Props) {
  const formControlSpacing = useFormControlSpacing('normal');

  const breaksHandler = useArrayHandler(value, onChange);

  const lastIndex = _.findLastIndex(value);
  return (
    <>
      {value.map(({ id, start, duration, paid }, breakIndex) => {
        const breakHandler = breaksHandler.makeItemHandler(id);

        const handlePaidBtnClick = () => {
          breakHandler.changeField('paid', true);
        };
        const handleUnpaidBtnClick = () => {
          breakHandler.changeField('paid', false);
        };

        return (
          <React.Fragment key={id}>
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
              flexWrap={{
                xs: 'wrap',
                sm: 'nowrap',
              }}
            >
              <Box {...formControlSpacing} flex="100%">
                <Box display="flex">
                  <Box
                    minWidth={0}
                    flex={{
                      xs: '0 0 50%',
                      sm: '1 0 auto',
                    }}
                    pr={1 / 2}
                  >
                    <TimeField
                      inputProps={{ size: 1 }}
                      margin="none"
                      label="Break start"
                      value={start}
                      onValueChange={breakHandler.makeChangeField('start')}
                      error={!!error[id]?.start}
                      autoFocus={start === null}
                      data-testid={'break-time'}
                    />
                  </Box>

                  <Box
                    minWidth={0}
                    flex={{
                      xs: '0 0 50%',
                      sm: '1 0 auto',
                    }}
                    pl={1 / 2}
                  >
                    <DurationField
                      inputProps={{ size: 1 }}
                      margin="none"
                      label="Duration"
                      value={duration}
                      onValueChange={breakHandler.makeChangeField('duration')}
                      error={!!error[id]?.duration}
                      data-testid={'break-duration'}
                    />
                  </Box>
                </Box>

                {error[id]?.start && (
                  <FormHelperText error={true}>
                    {error[id]?.start}
                  </FormHelperText>
                )}

                {error[id]?.duration && (
                  <FormHelperText error={true}>
                    {error[id]?.duration}
                  </FormHelperText>
                )}
              </Box>

              <Box
                flex="none"
                display="flex"
                mt={{ sm: formControlSpacing.mt }}
                mb={formControlSpacing.mb}
                pl={{ sm: 1 }}
              >
                <Box mr={1}>
                  <Button
                    size="medium"
                    variant={paid ? 'contained' : 'text'}
                    onClick={handlePaidBtnClick}
                    data-testid={'break-paid-btn'}
                  >
                    Paid
                  </Button>
                </Box>

                <Button
                  size="medium"
                  variant={!paid ? 'contained' : 'text'}
                  onClick={handleUnpaidBtnClick}
                  data-testid={'break-unpaid-btn'}
                >
                  Unpaid
                </Button>
              </Box>

              <Box
                flex="none"
                mt={{ sm: formControlSpacing.mt }}
                mb={formControlSpacing.mb}
                pl={{ sm: 1 }}
              >
                <Tooltip title="Delete break">
                  <IconButton
                    onClick={breakHandler.remove}
                    data-testid={'break-remove-btn'}
                  >
                    <DeleteForeverOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>

            {breakIndex !== lastIndex && (
              <Hidden up="sm">
                <Divider />
              </Hidden>
            )}
          </React.Fragment>
        );
      })}

      {value.length < TIMESHEET_MAX_BREAKS_NUMBER && (
        <Box mt={{ sm: formControlSpacing.mt }} mb={formControlSpacing.mb}>
          <Button
            size="medium"
            startIcon={<AddIcon />}
            onClick={breaksHandler.makeAddItem(makeFormBreak)}
            data-testid={'break-add-btn'}
          >
            Add break
          </Button>
        </Box>
      )}
    </>
  );
}
