import React from 'react';
import { Box, CircularProgress, useTheme } from 'extended-oxygen-elements';

export default TextFieldProgressAdornment;

export function TextFieldProgressAdornment() {
  const theme = useTheme();

  return (
    <Box color={theme.palette.text.secondary} display="inline-flex">
      <CircularProgress
        color="inherit"
        size={theme.componentsConfig.svgIcon.size.medium}
      />
    </Box>
  );
}
