import React from 'react';
import { IconButton, Popover } from 'extended-oxygen-elements';
import { createStyles, makeStyles } from '@mui/styles';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
  useSelectorWithProps,
} from 'hooks';
import { DefaultDivProps } from 'type';
import { useSelector } from 'react-redux';
import { getIsCovidModeEnabled } from 'state/Roster/CovidTracing';
import { getIsApprovalModeEnabled } from 'state/Roster/Roster';
import { getIsBulkDeleteOpened } from 'state/Roster/BulkActions';
import { NotesForm } from './NotesForm';
import { QuestionAnswerOutlinedIcon } from 'element/icons';

const useNotesIconStyles = makeStyles(() =>
  createStyles({
    print: {
      '@media print': {
        visibility: 'hidden',
      },
    },
  })
);

const usePopoverStyles = makeStyles(() =>
  createStyles({
    paper: {
      marginTop: -40,
    },
  })
);

type Props = DefaultDivProps & {
  notes: string | null;
  id: string;
  label: string;
};

export const NotesIconButton = ({ notes, id, label, ...rest }: Props) => {
  const popoverClasses = usePopoverStyles();
  const styles = useNotesIconStyles();

  const popupState = usePopupState();
  const isCovidMode = useSelector(getIsCovidModeEnabled);
  const isApprovalMode = useSelector(getIsApprovalModeEnabled);
  const isBulkDeleteMode = useSelector(getIsBulkDeleteOpened);

  const closePopup = () => {
    popupState.close();
  };

  if (isCovidMode || isApprovalMode || isBulkDeleteMode) {
    return null;
  }

  return (
    <div {...rest} data-testid={'notes-button'}>
      <IconButton
        {...bindTrigger(popupState)}
        className={styles.print}
        size={'medium'}
        role={'notes-button'}
      >
        <QuestionAnswerOutlinedIcon fontSize={'inherit'} color={'primary'} />
      </IconButton>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={popoverClasses}
        keepMounted={false}
      >
        <NotesForm
          notes={notes}
          id={id}
          closeNotes={closePopup}
          label={label}
        />
      </Popover>
    </div>
  );
};
