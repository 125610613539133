import { ErrorsHandler } from 'state/Errors';
import { LoadingHandler } from 'state/Loading';
import { StatusHandler } from 'state/Status';
import * as actions from './actions';

export const getIsFetching = LoadingHandler()
  .handleAsyncLoading(actions.BOX_PAYMENT_ASSIGNED_USERS_DIALOG_OPEN)
  .makeSelector();

export const getFetchingErrors = ErrorsHandler()
  .handleAsyncErrors(actions.BOX_PAYMENT_ASSIGNED_USERS_DIALOG_OPEN)
  .makeSelector();

export const getIsLoading = LoadingHandler()
  .handleAsyncLoading(actions.BOX_PAYMENT_ASSIGNED_USERS_DIALOG_USERS_CHANGE)
  .makeSelector();

export const getErrors = ErrorsHandler()
  .clearErrors(actions.BOX_PAYMENT_ASSIGNED_USERS_DIALOG_OPEN.request)
  .clearErrors(actions.BOX_PAYMENT_ASSIGNED_USERS_DIALOG_ERRORS_CLOSED)
  .handleAsyncErrors(actions.BOX_PAYMENT_ASSIGNED_USERS_DIALOG_USERS_CHANGE)
  .makeSelector();

export const getStatus = StatusHandler()
  .handleAsyncStatus(actions.BOX_PAYMENT_ASSIGNED_USERS_DIALOG_USERS_CHANGE)
  .makeSelector();
