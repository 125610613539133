import React, { Component } from 'react';
import { FormItem, Select } from 'elmo-elements';
import {
  AccountTreeSite,
  LanguagePreferences,
  StringMap,
  UpdateFormPayload,
} from 'type';
import { capitalize, keyMirror } from 'lib/helpers';
import {
  CheckboxesCollection,
  FromToFilters,
  SortDirectionRadioButtons,
  SortFieldRadioButtons,
} from 'element';

import { MyRostersFilters } from 'state/EmployeeDashboard/MyRosters/types';

export type Props = {
  formData: MyRostersFilters;
  onChange: (payload: UpdateFormPayload) => void;
  sites: StringMap<AccountTreeSite>;
  langPreferences: LanguagePreferences;
};

export default class CustomFiltersView extends Component<Props> {
  readonly inputNames = keyMirror(this.props.formData);

  readonly statuses: {
    label: string;
    value: string;
  }[] = [
    {
      label: 'Offer',
      value: 'offer'
    },
    {
      label: 'Swap',
      value: 'swap'
    },
    {
      label: 'Regular',
      value: 'none'
    }
  ];

  render() {
    const {
      inputNames,
      props: { formData, langPreferences }
    } = this;

    return (
      <form autoComplete={'off'}>
        <CheckboxesCollection
          formItem={{
            label: 'Status',
            isDivider: true
          }}
          name={inputNames.statuses}
          value={formData.statuses}
          onChange={this.props.onChange}
          options={this.statuses}
          hideAllCheckbox={true}
        />

        <FormItem label={capitalize(langPreferences.site.singular)}>
          <Select
            id="select-sites"
            options={this.options}
            defaultValue={this.getSelectedSite()}
            onChange={this.onChangeSite}
            ariaLabel={capitalize(langPreferences.site.singular)}
          />
        </FormItem>

        <FromToFilters
          from={{
            name: inputNames.from,
            value: formData.from
          }}
          to={{ name: inputNames.to, value: formData.to }}
          onChange={this.props.onChange}
        />

        <FormItem label="Sort by">
          <SortFieldRadioButtons
            id="sort-by"
            selected={formData.sort.column}
            onChange={this.onChangeSortField}
            sortFields={this.sortFields}
          />
        </FormItem>

        <FormItem>
          <SortDirectionRadioButtons
            id="sort-direction"
            selected={formData.sort.direction}
            onChange={this.onChangeSortDirection}
          />
        </FormItem>
      </form>
    );
  }

  get sortFields(): {
    label: string;
    value: string;
  }[] {
    const { role, area, site } = this.props.langPreferences;

    return [
      {
        label: 'Start time',
        value: 'start'
      },
      {
        label: 'Shift duration',
        value: 'duration'
      },
      {
        label: `By ${role.singular} - ${area.singular}, ${site.singular}`,
        value: 'position'
      }
    ];
  }

  readonly options: {
    label: string;
    value: string;
  }[] = Object.keys(this.props.sites).map(key => {
    return {
      value: this.props.sites[key].id,
      label: this.props.sites[key].name
    };
  });

  onChangeSortField = (column: string | number) => {
    this.props.onChange({
      name: this.inputNames.sort,
      value: {
        column,
        direction: this.props.formData.sort.direction
      }
    });
  };

  onChangeSortDirection = (direction: string | number) => {
    this.props.onChange({
      name: this.inputNames.sort,
      value: {
        column: this.props.formData.sort.column,
        direction
      }
    });
  };

  getSelectedSite = () => {
    const { formData, sites } = this.props;
    if (formData.location) {
      return {
        value: formData.location,
        label: sites[formData.location].name
      };
    }
    return { value: '', label: '' };
  };

  onChangeSite = (data: { label: string; value: string }) => {
    const { value } = data;
    const name = 'location';
    this.props.onChange({
      name,
      value
    });
  };
}
