import { DialogSubTitle, Form } from 'element';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';
import { useActions, useSelectorWithProps } from 'hooks';
import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLangPreferences } from 'state/Account';
import {
  BOX_ACCOUNT_TREE_AREAS_LINK,
  getAreas,
  getSiteName,
} from 'state/AccountTree';
import LinkSelect from './LinkSelect';
import {
  PageDialog,
  PageDialogCancel,
  PageDialogErrors,
  PageDialogSubmit,
  usePageDialogStatusEffect,
} from '../../../../../element/page-dialog-components';

type Props = {
  open: boolean;
  onClose: () => void;
  site_id: string;
};

export default function DialogAddArea({ site_id, open, onClose }: Props) {
  return (
    <PageDialog maxWidth="xs" open={open} onClose={onClose}>
      <AddAreaDialogContent site_id={site_id} onClose={onClose} />
    </PageDialog>
  );
}

type ContentProps = {
  site_id: string;
  onClose: () => void;
};

function AddAreaDialogContent({ site_id, onClose }: ContentProps) {
  const areasLinkRequest = useActions(BOX_ACCOUNT_TREE_AREAS_LINK.request);
  const langPreferences = useSelector(getLangPreferences);
  const siteName = useSelectorWithProps(getSiteName, site_id);

  const [selectedAreaIds, setSelectedAreaIds] = React.useState<string[]>([]);

  const assignableAreas = useAssignableAreas(site_id);
  const [areaOptions] = React.useState(
    assignableAreas.map((area) => ({
      value: area.id,
      label: area.name,
    }))
  );

  usePageDialogStatusEffect({
    success: onClose,
  });

  const linkAreas = () => {
    areasLinkRequest({
      site_id,
      area_ids: selectedAreaIds,
    });
  };

  return (
    <>
      <DialogTitle>
        Add {langPreferences.area.plural} to {siteName}
      </DialogTitle>
      <DialogContent>
        <DialogSubTitle>
          Select one or multiple {langPreferences.area.plural}. These will be
          added to the selected {langPreferences.site.singular}.
        </DialogSubTitle>
        <PageDialogErrors />
        <Form onSubmit={linkAreas}>
          <LinkSelect
            value={selectedAreaIds}
            onChange={setSelectedAreaIds}
            options={areaOptions}
            optionName={langPreferences.area}
          />
        </Form>
      </DialogContent>
      <DialogActions>
        <PageDialogCancel onClick={onClose}>Cancel</PageDialogCancel>
        <PageDialogSubmit
          disabled={!selectedAreaIds.length}
          onClick={linkAreas}
        >
          Add {langPreferences.area.singular}
        </PageDialogSubmit>
      </DialogActions>
    </>
  );
}

export function useAssignableAreas(site_id: string) {
  const areas = useSelector(getAreas);

  const uniqBreatheAreas = _.chain(areas)
    .filter((area) => area.is_breathe)
    .uniqBy((area) => area.external_id)
    .value();
  const assignedAreaNames = _.chain(areas)
    .filter((area) => area.site_id === site_id)
    .map((area) => area.name)
    .value();

  return uniqBreatheAreas.filter(
    (area) => !assignedAreaNames.includes(area.name)
  );
}
