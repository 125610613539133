import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LayoutContextProps, withLayoutAware } from 'elmo-elements';
import {
  NewHeader,
  NewHeaderAction,
  NewHeaderTitle,
  NewHeaderWrapper,
  Search,
  SearchIcon,
} from 'element';

import {
  BOX_ACCOUNTS_UPDATE_SEARCH_QUERY,
  getSearchQuery,
} from 'state/Accounts';
import { useBulkSearchContext } from 'contexts';
import { Box, IconButton } from 'extended-oxygen-elements';

export const AccountsHeader = withLayoutAware((props: LayoutContextProps) => {
  const dispatch = useDispatch();
  const bulkSearchContext = useBulkSearchContext();
  const searchQuery = useSelector(getSearchQuery);

  const updateQuery = (searchTerm: string) => {
    dispatch(BOX_ACCOUNTS_UPDATE_SEARCH_QUERY(searchTerm));
  };

  const handleCloseSearch = () => {
    updateQuery('');
  };

  const handleSearchClick = () => {
    bulkSearchContext.enable();
  };

  const handleSearchChange = (value: string) => {
    updateQuery(value);
  };

  return (
    <NewHeaderWrapper>
      <NewHeaderAction
        visible={bulkSearchContext.isEnabled}
        style={{ padding: 0, boxShadow: '0 0 4px #ccc' }}
      >
        <Search
          searchQuery={searchQuery}
          updateQuery={handleSearchChange}
          onCloseSearch={handleCloseSearch}
        />
      </NewHeaderAction>
      <NewHeader hidden={bulkSearchContext.isEnabled}>
        <NewHeaderTitle>Accounts</NewHeaderTitle>
        <Box ml="auto" display="flex" alignItems={'center'}>
          <IconButton
            aria-label="open search"
            onClick={handleSearchClick}
            sx={{ marginRight: '10px' }}
          >
            <SearchIcon style={{ fill: 'gray' }} />
          </IconButton>
        </Box>
      </NewHeader>
    </NewHeaderWrapper>
  );
});
