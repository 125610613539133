import moment, { Duration, Moment } from 'moment';

const getDuration = (time: Moment, startsFrom: Moment): Duration =>
  moment.duration(time.diff(startsFrom));

export const getDurations = (
  props: {
    start: Moment;
    end: Moment;
  },
  startsFrom: Moment
): {
  start: Duration;
  end: Duration;
} => ({
  start: getDuration(props.start, startsFrom),
  end: getDuration(props.end, startsFrom)
});
