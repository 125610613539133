import React from 'react';
import { Button } from 'extended-oxygen-elements';
import { Box, BoxProps } from 'extended-oxygen-elements';
import { AddIcon } from 'element';
import { usePayElementsListContext } from './pay-elements-list-context';

export default function AddPayElementButton(props: BoxProps) {
  const { isOpen, openCreate } = usePayElementsListContext();

  if (isOpen) {
    return null;
  }

  return (
    <Box {...props}>
      <Button
        variant="text"
        startIcon={<AddIcon />}
        onClick={openCreate}
        data-testid={'add-pay-element-btn'}
      >
        Add Pay element
      </Button>
    </Box>
  );
}
