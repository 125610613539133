import { SelectPropsOption } from 'elmo-elements/Select';
import { Moment } from 'moment';
import React from 'react';
import { HeaderNavigation } from '../HeaderNavigation';
import LocationDropDown from './components/LocationDropDown';
import './style.scss';

type OnSiteChangePayload = { site_id: string };
type OnDateChangePayload = { date: Moment };

export type RosterHeaderNavigationProps = {
  dateRange: {
    from: Moment;
    to?: Moment;
  };
  siteId: string;
  selectedDay: Moment;
  // callbacks:
  onPrevClicked: () => void;
  onNextClicked: () => void;
  onSiteChange: (payload: OnSiteChangePayload) => void;
  onDateChange: (payload: OnDateChangePayload) => void;
};

export const RosterHeaderNavigation = (props: RosterHeaderNavigationProps) => {
  const onSiteChange = ({ value }: SelectPropsOption) => {
    props.onSiteChange({ site_id: value });
  };

  const onDateChange = (date: Moment) => {
    props.onDateChange({ date });
  };

  return (
    <div className="roster-header-navigation">
      <LocationDropDown onChange={onSiteChange} siteId={props.siteId} />
      <HeaderNavigation
        className="roster-header-navigation__navigation"
        onNextClicked={props.onNextClicked}
        onPrevClicked={props.onPrevClicked}
        dateRange={props.dateRange}
        selectedDate={props.selectedDay}
        navigateTo={onDateChange}
      />
    </div>
  );
};
