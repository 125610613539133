import React, { ReactChild, ReactNode } from 'react';
import { getClass } from '../../_lib/helper';
import Button from '../../Button';
import './PaginationItem.scss';

export type PaginationItemProps = {
  /** aria-label for accessibility */
  ariaLabel?: string;
  /** Click event handler */
  onClick: React.MouseEventHandler;
  /** Whether the component is disabled */
  isDisabled?: boolean;
  /** React children */
  children?: ReactChild;
  /** Icon */
  icon: ReactNode;
};

function PaginationItem({
  ariaLabel,
  onClick,
  isDisabled,
  icon,
}: PaginationItemProps) {
  let className = getClass('elmo-pagination__item', { disabled: isDisabled });

  return (
    <Button
      type="default"
      isDisabled={isDisabled}
      onClick={isDisabled ? undefined : onClick}
      icon={icon}
      className={className}
      ariaLabel={ariaLabel}
      isText={true}
      isCircular={true}
    />
  );
}

export default PaginationItem;
