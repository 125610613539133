import { createSelector } from 'reselect';
import { Moment } from 'moment';
import { AccountTreeSite, StringMap } from 'type';
import { StoreState } from 'state/types';
import { getFrom, getSiteId } from 'state/RosteredShifts';
import { getSites } from 'state/AccountTree';
import { GetWeekNavParamsPayload } from '../types';
import { getScheduleConfig } from '../helpers';

export const weekNavParamsPayloadSelector = createSelector<
  StoreState,
  string,
  StringMap<AccountTreeSite>,
  Moment,
  GetWeekNavParamsPayload
  >(getSiteId, getSites, getFrom, (siteId, sites, from) => ({
  storedSiteId: siteId,
  sites: sites,
  from: from,
}));

export const getWeekDays = createSelector<
  StoreState,
  string,
  StringMap<AccountTreeSite>,
  Moment,
  string[]
  >(getSiteId, getSites, getFrom, (siteId, sites, from) => {
  const { weekDays } = getScheduleConfig({
    storedSiteId: siteId,
    sites: sites,
    from: from,
  });
  return weekDays;
});

