import { RosterReducerState } from './types';

export const getDefaultState = (): RosterReducerState => ({
  unpublishedRosters: [],
  availableOffers: {},
  isOffering: false,
  isApproving: false,
  isGenerating: false,
  errors: [],
  isPublishing: false,
  isLoading: false,
  openedModal: null,
  isApproveMode: false,
  downloadCsvModal: {
    info: '',
    downLoadLink: '',
    message: ''
  },
  overlapModal: {
    content: [],
    isOpened: false,
  },
  errorModal: {
    isOpened: false,
    errors: [],
  },
  confirmDeletionModal: {
    isOpened: false,
    isDeleting: false,
    id: '',
    errors: [],
    type: 'rostered_shift',
  },
  generateSuccessMsg: null
});
