import React from 'react';
import { useSelectorWithProps } from 'hooks';
import { getRosteredShift } from 'state/RosteredShifts';
import { useAreaName, useLinkedRole, useRosterDayCard } from './hooks';
import { ShiftCardBreaksDuration, ShiftCardIcons } from './views';
import { ShiftCardViewRefactored } from './views/ShiftCardViewRefactored';

type Props = {
  rosteredShiftId: string;
};

export const UserRosterDayCard = React.memo(function UserRosterDayCard({
  rosteredShiftId,
}: Props) {
  const rosteredShift = useSelectorWithProps(getRosteredShift, rosteredShiftId);
  const { viewProps, icons, breaks } = useRosterDayCard(rosteredShift);
  const linkedRole = useLinkedRole(rosteredShift);
  const areaName = useAreaName(rosteredShift);

  return (
    <ShiftCardViewRefactored
      {...viewProps}
      data-shift-id={rosteredShiftId}
      icons={<ShiftCardIcons icons={icons} />}
      breaks={<ShiftCardBreaksDuration breaks={breaks} />}
      title={linkedRole}
      spotTitle={areaName}
    />
  );
});
