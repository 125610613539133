import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { FetchWrapper } from 'element';
import { MANAGER_DASHBOARD_SHIFT_OFFER } from 'state/FetchPageData/constants';
import {
  BOX_SHIFT_OFFER_CLEAR_STATE,
  BOX_SHIFT_OFFER_GET_PAGE_DATA_REQUEST,
  GetPageDataPayload,
} from 'state/ManagerDashboard/ShiftOffers/ShiftOffer';
import View from './view';

type GetParams = {
  shiftOfferId: string;
};

type StateProps = {};

type DispatchProps = {
  fetchData: (payload: GetPageDataPayload) => void;
  clearState: () => void;
};

type Props = StateProps & DispatchProps & RouteComponentProps<GetParams>;

class ShiftOffer extends Component<Props> {
  render() {
    return (
      <FetchWrapper
        fetchData={this.fetchData}
        clearState={this.props.clearState}
        pageId={MANAGER_DASHBOARD_SHIFT_OFFER}
      >
        <View />
      </FetchWrapper>
    );
  }

  private fetchData = () => {
    const { shiftOfferId } = this.props.match.params;

    this.props.fetchData({
      shiftOfferId,
    });
  };
}

const mapDispatchToProps: DispatchProps = {
  fetchData: BOX_SHIFT_OFFER_GET_PAGE_DATA_REQUEST,
  clearState: BOX_SHIFT_OFFER_CLEAR_STATE,
};

export default withRouter(connect(null, mapDispatchToProps)(ShiftOffer));
