import { EmptyActionCreator, Action } from './types';
import { ConnectActionCreator } from './createConnectAction';
import { call, put, race, take, takeLatest } from 'redux-saga/effects';

type MakeChannel<WebSocket, ConnectPayload> = (
  ws: WebSocket,
  payload: ConnectPayload
) => void;

export default takeLatestConnect;

export function takeLatestConnect<WebSocket, ConnectPayload>(
  connectAction: ConnectActionCreator<ConnectPayload>,
  makeChannel: MakeChannel<WebSocket, ConnectPayload>,
  ws: WebSocket
) {
  return takeLatest(
    connectAction.connected,
    makeConnectHandler<WebSocket, ConnectPayload>(
      connectAction.disconnected,
      makeChannel,
      ws
    )
  );
}

function makeConnectHandler<WebSocket, ConnectPayload>(
  stopAction: EmptyActionCreator,
  makeChannel: MakeChannel<WebSocket, ConnectPayload>,
  ws: WebSocket
) {
  return function* ({ payload }: Action<ConnectPayload>) {
    const channel = yield call(makeChannel, ws, payload);

    while (true) {
      const { action, stop } = yield race({
        action: take(channel),
        stop: take(stopAction),
      });

      if (action) {
        yield put(action);
      }

      if (stop) {
        yield channel.close();
      }
    }
  };
}
