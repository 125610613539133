import { SagaIterator } from 'redux-saga';
import { EventResponse, EventsGetPayload } from './types';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Api from 'lib/Api';
import {
  BOX_EVENTS_GET_SUCCESS,
  BOX_EVENTS_UPDATE_ADD_ONE,
  BOX_EVENTS_UPDATED,
} from './actions/actions';
import { EventsGetResponse } from 'lib/Api/type';
import { processApiRequest } from '../ProcessApiRequest';
import { hasPermissionSelector } from '../Auth';
import { SagaAction } from '../../type';
import {
  fromBySiteTimezoneSelector,
  toBySiteTimezoneSelector,
} from './selectors';
import { getEventSettings } from '../Account';

export const getEventsRequest = function* (
  payload: EventsGetPayload
): SagaIterator {
  const permission = yield select(hasPermissionSelector, 'roster.events.view');
  const eventsSettings = yield select(getEventSettings);

  if (permission && eventsSettings.enable_events) {
    const events: EventsGetResponse = yield call(
      processApiRequest,
      Api.Events.get,
      payload
    );
    yield put(
      BOX_EVENTS_GET_SUCCESS({
        events,
        ...payload,
      })
    );
  }
};

const eventUpdated = function* ({
  payload: updatedEvent,
}: SagaAction<EventResponse>): SagaIterator {
  const from: ReturnType<typeof fromBySiteTimezoneSelector> = yield select(
    fromBySiteTimezoneSelector
  );
  const to: ReturnType<typeof toBySiteTimezoneSelector> = yield select(
    toBySiteTimezoneSelector
  );

  if (
    !(updatedEvent.start.isAfter(to) || updatedEvent.end.isSameOrBefore(from))
  ) {
    yield put(BOX_EVENTS_UPDATE_ADD_ONE(updatedEvent));
  }
};

export const watchEvents = function* (): SagaIterator {
  yield takeLatest(BOX_EVENTS_UPDATED, eventUpdated);
};
