import { RosteredShift } from 'type';
import {
  useCovidClassName,
  useIsBulkHighlighted,
  useIsBulkSelected,
} from '../hooks';
import useRosterCard from '../useRosterCard';
import useHandleClick from './useHandleClick';
import useHandleClickAddButton from './useHandleClickAddButton';
import useHandleClickDeleteIcon from './useHandleClickDeleteIcon';
import useHandleClickContextMenu from './useHandleClickContextMenu';

export const useRosterWeekCard = (rosteredShift: RosteredShift) => {
  const className = useCovidClassName(rosteredShift);
  const isHighlighted = useIsBulkHighlighted(rosteredShift);
  const isSelected = useIsBulkSelected(rosteredShift);
  const onClickContextualMenu = useHandleClickContextMenu();

  const onClick = useHandleClick(rosteredShift);
  const onClickAddButton = useHandleClickAddButton(rosteredShift);
  const onClickDeleteIcon = useHandleClickDeleteIcon(rosteredShift);

  const { breaks, icons, ...rest } = useRosterCard(rosteredShift);

  const viewProps = {
    ...rest,
    onClick,
    onClickDeleteIcon,
    onClickAddButton,
    isSelected,
    isHighlighted,
    isLocationShown: true,
    className: `rostered-shift-card ${className}`,
  };

  return {
    viewProps,
    icons,
    breaks,
    onClickContextualMenu,
  };
};
