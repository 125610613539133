import { call, put } from 'redux-saga/effects';
import { outDatedDataMessage } from 'messages';
import {
  BOX_TOAST_NOTIFIER_CLOSE,
  showOutDatedNotification as notifierShowOutdatedNotification,
} from 'state/ToastNotifier';
import * as actions from '../actions';
import nanoid from 'nanoid';

const outDatedNotificationId = nanoid();
export const showOutDatedNotification = function* () {
  const { undo } = yield call(notifierShowOutdatedNotification, {
    notificationId: outDatedNotificationId,
    message: outDatedDataMessage,
    undoText: 'Reload',
  });

  if (undo) {
    yield put(actions.BOX_MY_ROSTERS_RE_FETCH_DATA_REQUEST());
  }
};

export const hideOutdatedNotification = function* () {
  yield put(BOX_TOAST_NOTIFIER_CLOSE(outDatedNotificationId));
};

