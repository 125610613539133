import { createReducer } from 'lib/store-utils';
import {
  BOX_PREFERENCES_HIDE_NOTIFICATION,
  BOX_PREFERENCES_PROFILE_FAILURE,
  BOX_PREFERENCES_PROFILE_REQUEST,
  BOX_PREFERENCES_PROFILE_SUCCESS,
  BOX_PREFERENCES_RESET_PIN_FAILURE,
  BOX_PREFERENCES_RESET_PIN_REQUEST,
  BOX_PREFERENCES_RESET_PIN_SUCCESS,
  BOX_PREFERENCES_SHOW_NOTIFICATION,
  BOX_PREFERENCES_UPDATE_PROFILE_FAILURE,
  BOX_PREFERENCES_UPDATE_PROFILE_REQUEST,
  BOX_PREFERENCES_UPDATE_PROFILE_SUCCESS
} from './actions';

import { defaultState, PreferencesReducerState } from './state';

export const preferences = createReducer<PreferencesReducerState>({}, defaultState);

preferences.on(BOX_PREFERENCES_PROFILE_REQUEST, (state, payload) => ({
  ...state,
  isFetching: true,
  errors: []
}));

preferences.on(BOX_PREFERENCES_PROFILE_SUCCESS, (state, payload) => ({
  ...state,
  isFetched: true,
  isFetching: false,
  profile: payload,
  errors: []
}));

preferences.on(BOX_PREFERENCES_PROFILE_FAILURE, (state, payload) => ({
  ...state,
  errors: payload
}));

preferences.on(BOX_PREFERENCES_UPDATE_PROFILE_REQUEST, (state, payload) => ({
  ...state,
  isLoading: true,
  errors: []
}));

preferences.on(BOX_PREFERENCES_UPDATE_PROFILE_SUCCESS, (state, payload) => ({
  ...state,
  isLoading: false,
  errors: []
}));

preferences.on(BOX_PREFERENCES_UPDATE_PROFILE_FAILURE, (state, payload) => ({
  ...state,
  errors: payload,
  isLoading: false,
}));

preferences.on(BOX_PREFERENCES_SHOW_NOTIFICATION, (state, payload) => ({
  ...state,
  showNotification: true
}));

preferences.on(BOX_PREFERENCES_HIDE_NOTIFICATION, (state, payload) => ({
  ...state,
  showNotification: false
}));

preferences.on(BOX_PREFERENCES_RESET_PIN_REQUEST, (state, payload) => ({
  ...state,
  isLoading: true,
  errors: []
}));

preferences.on(BOX_PREFERENCES_RESET_PIN_SUCCESS, (state, payload) => ({
  ...state,
  isLoading: false,
  errors: []
}));

preferences.on(BOX_PREFERENCES_RESET_PIN_FAILURE, (state, payload) => ({
  ...state,
  errors: payload,
  isLoading: false,
}));
