import React from 'react';
import { FormItem, Checkbox } from 'elmo-elements';
import { Data } from '../type';
import { FeatureFlag, FlagWrapper } from 'element/feature-flags-components';

export type DataProps = {
  handleOnChangeCheckbox: (e: any) => void;
  data: Data;
};

const TimesheetCheckboxGroup = ({
  data,
  handleOnChangeCheckbox,
}: DataProps) => {
  return (
    <FormItem isInline={true}>
      <Checkbox
        name={'details'}
        id={'show-details'}
        label={'Show details'}
        isChecked={data.details}
        onChange={handleOnChangeCheckbox}
      />
      <FeatureFlag name="timesheetsReportFilter">
        <FeatureFlag.On>
          <Checkbox
            name={'with_violations'}
            id={'with_violations'}
            label={'With violations'}
            isChecked={data.with_violations}
            onChange={handleOnChangeCheckbox}
          />
        </FeatureFlag.On>
        <FeatureFlag.Off>
          <Checkbox
            name={'show_approved_shifts'}
            id={'show_approved_shifts'}
            label={'Only show approved shifts'}
            isChecked={data.show_approved_shifts}
            onChange={handleOnChangeCheckbox}
          />
        </FeatureFlag.Off>
      </FeatureFlag>

      <Checkbox
        name={'show_leave'}
        id={'show_leave'}
        label={'Show leave/sickness'}
        isChecked={data.show_leave}
        onChange={handleOnChangeCheckbox}
      />
    </FormItem>
  );
};

export default TimesheetCheckboxGroup;
