import React from 'react';
import { Button, Checkbox, Col, Row, Text } from 'elmo-elements';
import { TimeInput, DurationInputMinutes } from 'element';
import moment, { Moment } from 'moment';
import { AddIcon, ClearOutlinedIcon } from 'element';
import { TimesheetBreak } from 'type/models';
import {
  secondsToHours,
  convertDecimalToFormattedTime,
  convertFormattedTimeToDecimal,
} from 'lib/helpers';
import _ from 'lodash';

type Props = {
  breaks: TimesheetBreak[];
  onChangeDate: (date: Moment, index: number) => void;
  onChangeDuration: (minutes: number, index: number) => void;
  onChangePaid: (isPaid: boolean, index: number) => void;
  onClear: (index: number) => void;
  isEditable: boolean;
  isEditing: boolean;
  onClick?: () => void;
  maxBreaks: number;
};

type State = {
  isEditing: boolean;
  breaksCount: number;
};

export default class Breaks extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isEditing: this.props.isEditing,
      breaksCount: 1,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.isEditing !== prevProps.isEditing) {
      this.setState({
        isEditing: this.props.isEditing,
      });
    }

    const length = this.props.breaks.length;
    if (length && length > this.state.breaksCount) {
      this.setState({
        breaksCount: length,
      });
    }
  }

  render() {
    const { isEditing } = this.state;
    const { isEditable, onClick } = this.props;
    return (
      <div
        className={!isEditing ? 'isEditable' : ''}
        onClick={() => {
          if (isEditable) {
            this.setState({
              isEditing: true,
            });
            if (typeof onClick === 'function') {
              onClick();
            }
          }
        }}
      >
        <Text size={'xs'} color={'gray'}>
          Breaks
        </Text>
        {isEditing ? this.renderForm() : this.renderBreaks()}
      </div>
    );
  }

  renderBreaks() {
    const { breaks } = this.props;
    return (
      <div id="breaks-container">
        {breaks.length
          ? breaks.map((b, key) => (
              <div key={key}>
                <span className="mr-2">
                  {secondsToHours(+b.duration * 60, false)}
                </span>
                {b.paid ? 'paid' : 'unpaid'}
              </div>
            ))
          : '-'}
      </div>
    );
  }

  renderForm() {
    const { breaks, maxBreaks } = this.props;
    return (
      <div>
        {_.times(this.state.breaksCount).map((index: number) => (
          <div key={index} data-testid={`single-break-element-${index}`}>
            <Row isNoGutters={true} alignItems={'end'} role="presentation">
              <Col span={10} className={'breaks-space'} role="presentation">
                <TimeInput
                  id={`break-start-input-${index}`}
                  onChange={(e) => {
                    this.props.onChangeDate(e, index);
                  }}
                  name={`start_${index}`}
                  value={
                    breaks[index]
                      ? breaks[index].start
                      : moment('12:00 PM', ['h:mm A'])
                  }
                  label="Break time"
                />
              </Col>
              <Col span={6} role="presentation" className={'breaks-space'}>
                <DurationInputMinutes
                  id={`break-duration-input-${index}`}
                  onChangeHandler={(updatedMinutes) => {
                    this.props.onChangeDuration(updatedMinutes, index);
                  }}
                  name={`duration_${index}`}
                  value={breaks[index] ? breaks[index].duration : 0}
                  ariaLabel="Duration"
                />
              </Col>
              <Col span={1} className={'breaks-space pb-1'} role="presentation">
                {index === 0 && (
                  <Text
                    size={'xs'}
                    color={'gray'}
                    className={'break-paid-label'}
                  >
                    Paid
                  </Text>
                )}
                <Checkbox
                  id={`break-paid-input-${index}`}
                  name={`paid_${index}`}
                  isChecked={breaks[index] ? breaks[index].paid : false}
                  onChange={(e) => {
                    this.props.onChangePaid(e.currentTarget.checked, index);
                  }}
                />
              </Col>
              {breaks[index] && (
                <Col
                  span={2}
                  className={'breaks-space ml-2'}
                  role="presentation"
                >
                  <Button
                    id={`clear-btn-${index}`}
                    icon={<ClearOutlinedIcon />}
                    onClick={() => {
                      this.props.onClear(index);
                    }}
                  />
                </Col>
              )}
            </Row>
          </div>
        ))}

        {this.state.breaksCount < maxBreaks && (
          <Button
            className="ml-0 mb-4"
            id={'add-break-btn'}
            type="primary"
            onClick={this.addEmptyBreak}
            isText={true}
            isLeftIndent={true}
            isUppercase={false}
            icon={<AddIcon />}
            tabIndex={0}
          >
            Add break
          </Button>
        )}
      </div>
    );
  }

  convertValue = (value: string) => {
    if (value.indexOf('m') !== -1 || value.indexOf('h') !== -1) {
      const val = convertFormattedTimeToDecimal(value);
      return convertDecimalToFormattedTime(val.toFixed(2));
    } else {
      return convertDecimalToFormattedTime(value);
    }
  };

  addEmptyBreak = () => {
    this.setState({
      breaksCount: this.state.breaksCount + 1,
    });
  };
}
