import { SelectPropsOption } from 'elmo-elements/Select';
import { ReportScheduleDay, ReportScheduleSchedulePeriod } from 'type';
import { createSelectPropsOptions } from 'helpers';
import { DATE_LABELS, FORMAT_LABELS } from 'lib/config';

export const roundTimeToMin = 10;

export const scheduleOptions: {
  label: string;
  value: ReportScheduleSchedulePeriod;
}[] = [
  {
    label: 'Daily',
    value: 'daily'
  },
  {
    label: 'Weekly',
    value: 'weekly'
  },
  {
    label: 'Fortnightly',
    value: 'fortnightly'
  },
  {
    label: 'Monthly',
    value: 'monthly'
  }
];

export const dayButtons: {
  label: string;
  value: ReportScheduleDay;
}[] = [
  {
    label: 'M',
    value: '1'
  },
  {
    label: 'T',
    value: '2'
  },
  {
    label: 'W',
    value: '3'
  },
  {
    label: 'T',
    value: '4'
  },
  {
    label: 'F',
    value: '5'
  },
  {
    label: 'S',
    value: '6'
  },
  {
    label: 'S',
    value: '7'
  }
];

export const DATE_OPTIONS: SelectPropsOption[] = createSelectPropsOptions(
  DATE_LABELS
);

export const FORMAT_OPTIONS: SelectPropsOption[] = createSelectPropsOptions(
  FORMAT_LABELS
);
