import { RosterTimeOffsReducerState } from './types';
import moment from 'moment';

export const defaultState: RosterTimeOffsReducerState = {
  timeOffs: {},
  site_id: '',
  from: moment(),
  to: moment(),
  isUpdating: false,
  errors: []
};
