import { StoreState } from '../../types';
import moment, { Moment } from 'moment';
import {
  EmployeeSpreadOfHoursResponse,
  EmployeeUnavailabilityProps,
  NotesModalProps,
  TimeOffRosteredShift,
  UnavailabilityModalProps,
} from './types';
import { FormattedErrors, TimeOffRepeat } from '../../../type';
import { moment as momentRange } from 'lib/momentWithRange';
import { createSelector } from 'reselect';
import { flatMap, orderBy } from 'lodash';
import { currentUserMainTimezoneSelector } from 'state/Auth';

export const getUnavailabilityModalProps = (
  state: StoreState
): UnavailabilityModalProps =>
  state.employeeDashboard.employeeUnavailability.modal;

export const getUnavailabilities = (
  state: StoreState
): EmployeeUnavailabilityProps[] => {
  const data = state.employeeDashboard.employeeUnavailability.data;
  return orderBy(data, [(v) => moment(v.start).format('x')], ['asc']);
};

export const getRosteredAvailabilities = (
  state: StoreState
): TimeOffRosteredShift[] =>
  state.employeeDashboard.employeeUnavailability.shifts;

export const getCalendarDate = (state: StoreState): Moment =>
  state.employeeDashboard.employeeUnavailability.date;

export const getCalendarDateByMainTimezone = createSelector<
  StoreState,
  Moment,
  string,
  Moment
>(
  getCalendarDate,
  currentUserMainTimezoneSelector,
  (date: Moment, timezoneId: string) => {
    return date.clone().tz(timezoneId);
  }
);

export const getIsFetching = (state: StoreState): boolean =>
  state.employeeDashboard.employeeUnavailability.isFetching;

export const getErrors = (state: StoreState): FormattedErrors =>
  state.employeeDashboard.employeeUnavailability.errors;

export const getNotesModal = (state: StoreState): NotesModalProps =>
  state.employeeDashboard.employeeUnavailability.notesModal;

export const getWorkingHours = (
  state: StoreState
): EmployeeSpreadOfHoursResponse[] =>
  state.employeeDashboard.employeeUnavailability.spreadOfHours;

export const getLeaveSubTypeById = (state: StoreState, id: string | null) => {
  if (id) {
    const item = state.employeeDashboard.employeeUnavailability.data.filter(
      (item) => item.id === id
    );
    if (item.length) {
      return item[0].subtype;
    }
  }
  return null;
};

const repeatDurationsMs: { [key in TimeOffRepeat]: number } = {
  once: 0,
  daily: moment.duration(1, 'day').asMilliseconds(),
  weekly: moment.duration(1, 'week').asMilliseconds(),
  fortnightly: moment.duration(2, 'weeks').asMilliseconds(),
  four_weekly: moment.duration(4, 'weeks').asMilliseconds(),
};

export const timeOffsArraySelector = createSelector<
  StoreState,
  EmployeeUnavailabilityProps[],
  Moment,
  EmployeeUnavailabilityProps[]
>(
  getUnavailabilities,
  getCalendarDateByMainTimezone,
  (repeatableTimeOffs: EmployeeUnavailabilityProps[], date: Moment) => {
    const from = moment(date).startOf('month');
    const to = moment(date).endOf('month');

    const timeOffsWithoutRepeat = flatMap(
      repeatableTimeOffs,
      (timeOff: EmployeeUnavailabilityProps): EmployeeUnavailabilityProps[] => {
        const timeOffDurationMs: number = timeOff.end.diff(timeOff.start);

        if (timeOff.repeat === 'once') {
          return [timeOff];
        }

        const repeatDurationMs: number = repeatDurationsMs[timeOff.repeat];

        if (timeOffDurationMs < repeatDurationMs) {
          const startFromDiff = from.diff(timeOff.start);
          const repeatsQuantityBeforeFrom = Math.floor(
            startFromDiff / repeatDurationMs
          );

          const timeOffs: EmployeeUnavailabilityProps[] = [];
          let start: Moment = timeOff.start
            .clone()
            .add(repeatsQuantityBeforeFrom * repeatDurationMs);
          let end: Moment = timeOff.end
            .clone()
            .add(repeatsQuantityBeforeFrom * repeatDurationMs);

          while (
            start.isBefore(to) &&
            (end.isBefore(timeOff.until!) || end.isSame(timeOff.until!))
          ) {
            timeOffs.push({
              ...timeOff,
              start,
              end,
            });

            start = start.clone().add(repeatDurationMs);
            end = end.clone().add(repeatDurationMs);
          }

          return timeOffs;
        }

        return [
          {
            ...timeOff,
            end: timeOff.until!.clone(),
          },
        ];
      }
    );

    return timeOffsWithoutRepeat.filter((timeOff) => {
      for (let serverTimeOff of repeatableTimeOffs) {
        if (serverTimeOff.id === timeOff.id) {
          const fromToRange = momentRange.range(serverTimeOff.start, to);
          const timeOffRange = momentRange.range(timeOff.start, timeOff.end);
          return fromToRange.overlaps(timeOffRange);
        }
      }
    });
  }
);
