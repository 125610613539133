import {
  DialogActions,
  DialogContent,
  DialogContentText,
  useTheme,
  DialogTitle,
} from 'extended-oxygen-elements';
import { useSelectorWithProps } from 'hooks';
import { altConfirmationModalTitle, confirmationModalBtnLabel } from 'messages';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  BOX_CONFIRMATION_CLOSE_MODAL,
  BOX_CONFIRMATION_CONFIRM_MODAL_REQUEST,
  getIsLoading,
  getIsOpened,
  getMessages,
} from 'state/ErrorsProcessing/Confirmation';
import { PageDialog, PageDialogCancel, PageDialogSubmit } from 'element';

export function ConfirmationModal() {
  const confirmBtnRef = React.useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();
  const isOpened = useSelectorWithProps(getIsOpened);
  const messages = useSelectorWithProps(getMessages);
  const isLoading = useSelectorWithProps(getIsLoading);
  const theme = useTheme();

  React.useEffect(() => {
    const { current } = confirmBtnRef;
    if (isOpened && current) {
      current.focus();
    }
  }, [isOpened]);

  const handleConfirmBtnClick = () => {
    if (isLoading) {
      return;
    }

    dispatch(BOX_CONFIRMATION_CONFIRM_MODAL_REQUEST());
  };

  const closeDialog = () => {
    if (isLoading) {
      return;
    }

    dispatch(BOX_CONFIRMATION_CLOSE_MODAL());
  };

  return (
    <PageDialog
      maxWidth="xs"
      open={isOpened}
      onClose={closeDialog}
      style={{ zIndex: theme.zIndex.globalOverlay }}
      data-testid="elmo-modal-global-confirmation-modal"
    >
      <DialogTitle>{altConfirmationModalTitle}</DialogTitle>

      <DialogContent>
        {messages.map((message, index) => (
          <DialogContentText key={index}>{message}</DialogContentText>
        ))}
      </DialogContent>

      <DialogActions>
        <PageDialogCancel
          disabled={isLoading}
          onClick={closeDialog}
          data-testid="elmo-btn-global-confirmation-modal-cancel"
        >
          Cancel
        </PageDialogCancel>

        <PageDialogSubmit
          ref={confirmBtnRef}
          variant="contained"
          loading={isLoading}
          disableRipple={isLoading}
          onClick={handleConfirmBtnClick}
          data-testid="elmo-btn-global-confirmation-modal-approve"
        >
          {confirmationModalBtnLabel}
        </PageDialogSubmit>
      </DialogActions>
    </PageDialog>
  );
}
