import { StoreState } from '../../../types';
import { IsOutdatedPage, ShiftTradesCustomFilter, ShiftTradesFilter, SwapProposalsResponse } from '../types';
import { FormattedErrors, Pager, ShiftTrade, ShiftTradesList } from '../../../../type/models';

const getState = (state: StoreState) => state.employeeDashboard.shiftTrades;
export const getSelectedFilter = (state: StoreState): ShiftTradesFilter =>
  getState(state).selectedFilter;
export const getShiftTradesList = (state: StoreState): ShiftTradesList =>
  getState(state).shifts;
export const getShiftTradesCustomFilter = (
  state: StoreState
): ShiftTradesCustomFilter => getState(state).filters;
export const getShiftTradesCustomFormFilter = (
  state: StoreState
): ShiftTradesCustomFilter => getState(state).filtersForm;
export const isTabChangedFromCustomFilters = (state: StoreState): boolean =>
  getState(state).isTabChangedFromCustomFilters;
export const getErrors = (state: StoreState): FormattedErrors =>
  getState(state).errors;
export const shiftTradeAcceptingId = (state: StoreState): string =>
  getState(state).isAccepting;
export const proposalSelectingId = (state: StoreState): string =>
  getState(state).isSelecting;
export const proposalDecliningId = (state: StoreState): string =>
  getState(state).isDeclining;
export const getSwapProposalsSelector = (
  state: StoreState
): SwapProposalsResponse =>
  getState(state).swapModal.proposals;
export const getSwapProposalsLoadingState = (state: StoreState): boolean =>
  getState(state).swapModal.isLoading;
export const getSwapProposalsUpdatingSelector = (state: StoreState): boolean =>
  getState(state).swapModal.isUpdating;
export const shiftTradeIsDeleting = (state: StoreState): boolean =>
  getState(state).isDeleting;
export const getCurrentShiftId = (state: StoreState): string | null =>
  getState(state).currentShiftId;
export const getCurrentOfferedShift = (state: StoreState): ShiftTrade | null =>
  getState(state).offeredShiftToSwap;
export const getFiltersSelectedFilter = (state: StoreState) =>
  getState(state).filters.selectedFilter;
export const getPager = (state: StoreState): Pager =>
  getState(state).pager;
export const getIsOutdated = (state: StoreState) =>
  getState(state).isOutdated;
export const getIsOutdatedValue = (state: StoreState, page: IsOutdatedPage) =>
  getIsOutdated(state)[page].value;
export const getChangedShiftTradeId = (state: StoreState) =>
  getState(state).changedShiftTradeId;
export const getTradeById = (state: StoreState, tradeId: string): ShiftTrade | null => {
  const filtered = getState(state).shifts.filter(s => s.id === tradeId);
  return filtered && filtered.length ? filtered[0] : null;
}
