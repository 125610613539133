import { createSelector } from 'reselect';
import { Event } from 'type';
import { StoreState } from 'state/types';
import { filteredEventsSelector } from 'state/Roster/RosterFilters';
import { GetWeekNavParamsPayload } from '../types';
import { buildEventsRows } from '../helpers';
import { weekNavParamsPayloadSelector } from './weekNavSelectors';

export const groupEvents = createSelector<
  StoreState,
  Event[],
  GetWeekNavParamsPayload,
  any
  >(
  filteredEventsSelector,
  weekNavParamsPayloadSelector,
  (events, getWeekNavParamsPayload) => {
    const { rows, rowsNumber } = buildEventsRows(
      events,
      getWeekNavParamsPayload
    );
    return {
      rosters: rows,
      rostersRowNumber: rowsNumber,
    };
  }
);
