import React from 'react';
import {
  FormControl,
  FormHelperText,
  Input,
  InputProps,
} from 'extended-oxygen-elements';

export type TableCellInputProps = InputProps & { helperText?: string };

export default function TableCellInput({
  helperText,
  ...restProps
}: TableCellInputProps) {
  return (
    <FormControl margin="none">
      <Input {...restProps} />

      <FormHelperText error={restProps.error}>{helperText}</FormHelperText>
    </FormControl>
  );
};
