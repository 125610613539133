import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSelectorWithProps } from 'hooks';
import { RosteredShift, RosterRepeatableTimeOff } from 'type';
import { hasPermissionSelector } from 'state/Auth';
import { getSiteId } from 'state/RosteredShifts';
import { getIsApprovalModeEnabled } from 'state/Roster/Roster';
import { getIsCovidModeEnabled } from 'state/Roster/CovidTracing';
import { getIsBulkDeleteOpened } from 'state/Roster/BulkActions';
import { BOX_ROSTER_SHIFT_MODAL_GET_BY_ID } from 'state/Roster/RosterShiftModal';
import {
  userListSelector,
  userRolesBySiteSelector,
} from '../../../../../state/UsersCollection';
import moment from 'moment-timezone';
import { getSites } from 'state/AccountTree';

type Props = Pick<RosterRepeatableTimeOff, 'user_id'>;
const useHandleClickAddButton = (
  { user_id }: Props,
  day: string
): React.MouseEventHandler<HTMLElement> | undefined => {
  const canEditShift = useSelectorWithProps(
    hasPermissionSelector,
    'roster.rosteredshift.edit'
  );
  const isApprovalMode = useSelector(getIsApprovalModeEnabled);
  const isBulkDelete = useSelector(getIsBulkDeleteOpened);
  const siteId = useSelector(getSiteId);
  const sites = useSelector(getSites);
  const isCovidMode = useSelector(getIsCovidModeEnabled);
  const users = useSelector(userListSelector);
  const isArchived = users[user_id] && !users[user_id].is_active;
  const canAddShift =
    canEditShift &&
    !isBulkDelete &&
    !isApprovalMode &&
    !isCovidMode &&
    !isArchived;
  const userRoles = useSelectorWithProps(userRolesBySiteSelector, {
    user_id,
    site_id: siteId,
  });
  const dispatch = useDispatch();

  const handleClickAddButton = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      const timeZoneId = sites[siteId].timezone_id;
      const start = moment(+day)
        .tz(timeZoneId)
        .clone()
        .startOf('day');
      const end = start.clone().add(1, 'day').startOf('day');
      dispatch(
        BOX_ROSTER_SHIFT_MODAL_GET_BY_ID({
          id: '',
          start,
          end,
          role_id: userRoles[0].role_id,
          area_id: userRoles[0].area_id,
          site_id: siteId,
          user_id,
        })
      );
    },
    [user_id, isCovidMode, isBulkDelete, isApprovalMode, day, users]
  );

  if (canAddShift && userRoles.length) {
    return handleClickAddButton;
  }
};

export default useHandleClickAddButton;
