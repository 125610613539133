import { first, keys, mapValues, values } from 'lodash';
import { createSelector } from 'reselect';
import { AccountTreeReducerState } from 'state/AccountTree/types';
import {
  asSelectOptionCombiner,
  asSelectOptionsCombiner,
  getItemSafeCreator,
  getItemsSafeCreator,
  groupedByFieldCombinerCreator,
  idsCombiner,
} from 'state/combiners';
import { StoreState } from 'state/types';
import { Dictionary, SafeDictionary } from 'ts-essentials';
import {
  AccountTreeArea,
  AccountTreeRole,
  AccountTreeSite,
  GeoFencingOptions,
  StringMap,
} from 'type';
import {
  activeCombiner,
  areasGroupedAsSelectOptionsCombiner,
} from './combiners';

const getState = (state: StoreState): AccountTreeReducerState =>
  state.accountTree;

export const getAccountTree = (state: StoreState) =>
  getState(state).accountTree;

export const getSites = (state: StoreState) => getAccountTree(state).sites;
export const getAreas = (state: StoreState) => getAccountTree(state).areas;
export const getRoles = (state: StoreState) => getAccountTree(state).roles;

export const getAreasSafe = getItemsSafeCreator(getAreas);
export const getRolesSafe = getItemsSafeCreator(getRoles);

export const getSite = (state: StoreState, id: string) => getSites(state)[id];
export const getArea = (state: StoreState, id: string) => getAreas(state)[id];
export const getRole = (state: StoreState, id: string) => getRoles(state)[id];

export const getSiteSafe = getItemSafeCreator(getSites);
export const getAreaSafe = getItemSafeCreator(getAreas);
export const getRoleSafe = getItemSafeCreator(getRoles);

export const getSiteName = (state: StoreState, id: string) =>
  getSite(state, id).name;
export const getSiteIsBreathe = (state: StoreState, id: string) =>
  getSite(state, id).is_breathe;
export const getSiteArchived = (state: StoreState, id: string) =>
  getSite(state, id).archived;
export const getAreaName = (state: StoreState, id: string) =>
  getArea(state, id).name;
export const getAreaSiteId = (state: StoreState, id: string) =>
  getArea(state, id).site_id;
export const getAreaLockedRoleIds = (state: StoreState, id: string) =>
  getArea(state, id).locked_role_ids;
export const getRoleName = (state: StoreState, id: string) =>
  getRole(state, id).name;

export const siteIdsSelector = createSelector(getSites, idsCombiner);

export const activeSitesSelector = createSelector(getSites, activeCombiner);
export const activeSiteSafeSelector = getItemSafeCreator(activeSitesSelector);
export const activeRolesSelector = createSelector(getRoles, activeCombiner);

export const activeAreasWithoutRolesSelector = createSelector(
  getAreas,
  activeCombiner
);

export const activeAreasSelector = createSelector(
  getAreas,
  getRoles,
  (
    areas: Dictionary<AccountTreeArea>,
    roles: SafeDictionary<AccountTreeRole>
  ): Dictionary<AccountTreeArea> =>
    mapValues(activeCombiner(areas), (area) => ({
      ...area,
      role_ids: area.role_ids.filter((roleId) => {
        const role = roles[roleId];
        return role && !role.archived;
      }),
    }))
);

export const firstSiteIdSelector = createSelector<
  StoreState,
  StringMap<AccountTreeSite>,
  string
>(activeSitesSelector, (activeSites) => {
  const ids: string[] = keys(activeSites);

  return first(ids) || '';
});

// sorted by site id:
export const areasBySiteIdSelector = createSelector(
  getAreas,
  groupedByFieldCombinerCreator('site_id')
);

export const activeAreasBySiteIdSelector = createSelector(
  activeAreasSelector,
  groupedByFieldCombinerCreator('site_id')
);

export const activeAreasBySiteIdAsSelectOptionsSelector = createSelector(
  activeAreasBySiteIdSelector,
  (activeAreasBySiteId) =>
    mapValues(activeAreasBySiteId, (areasOfTheSite) =>
      areasOfTheSite.map(asSelectOptionCombiner)
    )
);

// Select options collections:
export const activeSitesAsSelectOptionsSelector = createSelector(
  activeSitesSelector,
  asSelectOptionsCombiner
);

export const activeAreasAsSelectOptionsSelector = createSelector(
  activeAreasWithoutRolesSelector,
  asSelectOptionsCombiner
);

export const activeRolesAsSelectOptionsSelector = createSelector(
  activeRolesSelector,
  asSelectOptionsCombiner
);

// Select options arrays:
export const activeSitesAsSelectOptionsArraySelector = createSelector(
  activeSitesAsSelectOptionsSelector,
  values
);

export const activeRolesAsSelectOptionsArraySelector = createSelector(
  activeRolesAsSelectOptionsSelector,
  values
);

export const activeAreasWithSiteNameAsSelectOptionsSelector = createSelector(
  activeAreasBySiteIdSelector,
  getSites,
  areasGroupedAsSelectOptionsCombiner
);

export const activeAreasAsSelectOptionsArraySelector = createSelector(
  activeAreasWithSiteNameAsSelectOptionsSelector,
  values
);

export const getGeoFencingSettingsBySiteId = (
  state: StoreState,
  siteId: string
): GeoFencingOptions => {
  const sites = activeSitesSelector(state);
  const {
    geo_enabled,
    address_lng,
    address_lat,
    address_radius,
    formatted_address,
  } = sites[siteId];
  return {
    geo_enabled,
    address_lng,
    address_lat,
    address_radius,
    formatted_address,
  };
};
