import { Market } from 'type';

// please use not capitalized labels, exception: names
const allMarketLabels = {
  au: {
    rta: 'Roster, Time & Attendance',
    rtaShort: 'Roster / T & A',
    roster: 'roster',
    userArchived: 'terminated',
    restBreak: 'rest break',
    mealBreak: 'meal break',
    rosteredShift: 'rostered shift',
    rosteredShifts: 'rostered shifts',
  },
  uk: {
    rta: 'Rota, Time & Attendance',
    rtaShort: 'Rota / T & A',
    roster: 'rota',
    userArchived: 'left',
    restBreak: 'break',
    mealBreak: 'lunch break',
    rosteredShift: 'shift',
    rosteredShifts: 'shifts',
  },
};

export const marketLabels =
  allMarketLabels[process.env.REACT_APP_MARKET as Market];

export default marketLabels;
