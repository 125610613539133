import React, { Component } from 'react';
import moment, { Moment } from 'moment';
import { Checkbox, Col, FormItem, Row } from 'elmo-elements';
import { AddIcon } from 'element/icons';
import { PreferencesTimeFormat, ShiftBreak } from 'type';
import { DurationInput, TimeInput } from 'element/index';
import { Button } from 'oxygen-elements';

type Props = {
  onChangePicker: (e: Moment, name: string, index: number) => void;
  onChangeDuration: (e: any, name: string, index: number) => void;
  onChangeCheckbox: (e: any, index: number) => void;
  className?: string;
  addBreak: () => void;
  breaks: ShiftBreak[];
  placeholder?: string;
  maxBreaksNumber?: number;
  timeFormat: PreferencesTimeFormat;
};

export class BreakGroup extends Component<Props, {}> {
  count: number = 0;

  render() {
    return this.getOptions();
  }

  componentDidMount(): void {
    const input = document.querySelector('#break-placeholder');
    if (input !== null) {
      input.setAttribute('autocomplete', 'off');
    }
  }

  setFocusOnLatestAddedField = () => {
    setTimeout(() => {
      const latestIndex = this.props.breaks.length
        ? this.props.breaks.length - 1
        : 0;
      const input: HTMLInputElement | null = document.querySelector(
        `#break-start-input-${latestIndex}`
      );
      if (input) {
        input.click();
        input.focus();
      }
    }, 200);
  };

  focusOnAddButton = () => {
    const { breaks } = this.props;
    setTimeout(() => {
      const btn: HTMLButtonElement | null =
        document.querySelector(`#add-break-btn`);
      if (btn && btn.offsetTop) {
        btn.focus();
        if (breaks.length === 0) {
          btn.click();
        }
      } else {
        this.setFocusOnLatestAddedField();
      }
    }, 200);
  };

  getOptions = () => {
    const { breaks, maxBreaksNumber } = this.props;
    const breaksNumber = !maxBreaksNumber ? 3 : maxBreaksNumber;
    return (
      <div className={`rostered-shift-modal__hidden-options rows-container`}>
        {breaks.map((b: ShiftBreak, index: number) => (
          <Row key={index} role="presentation" alignItems="center">
            <Col span={9} className={'ml-3'} role="presentation">
              <FormItem label={'Break time'} isFluid={false}>
                <TimeInput
                  id={`break-start-input-${index}`}
                  onChange={(e) => this.props.onChangePicker(e, `start`, index)}
                  name={`start_${index}`}
                  value={moment(b.start)}
                  label="Break time"
                  tabIndex={0}
                />
              </FormItem>
            </Col>
            <Col span={9} role="presentation">
              <FormItem label={'Duration'} isFluid={false}>
                <DurationInput
                  id={`break-duration-input-${index}`}
                  onChangeHandler={(e) =>
                    this.props.onChangeDuration(e, 'duration', index)
                  }
                  name={`duration_${index}`}
                  value={b.duration}
                  ariaLabel="Duration"
                  tabIndex={0}
                />
              </FormItem>
            </Col>
            <Col span={4} xs={5} role="presentation">
              <Checkbox
                className="padding-top-medium"
                id={`break-paid-input-${index}`}
                label={'Paid'}
                name={`paid_${index}`}
                isChecked={b.paid}
                onChange={(e) => this.props.onChangeCheckbox(e, index)}
                tabIndex={0}
              />
            </Col>
          </Row>
        ))}

        {breaks.length < breaksNumber && (
          <Button
            id={'add-break-btn'}
            data-testid={'add-break-btn'}
            onClick={this.handleAddBreaks}
            tabIndex={0}
            className={'add-break-button'}
          >
            <AddIcon /> {breaks.length ? 'Add another break' : 'Add break'}
          </Button>
        )}
      </div>
    );
  };

  handleAddBreaks = () => {
    this.props.addBreak();
    this.setFocusOnLatestAddedField();
  };

  handleFocus = () => {
    this.focusOnAddButton();
  };
}
