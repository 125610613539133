import { HelpOutlineIcon } from 'element';
import {
  Box,
  Button,
  IconButton,
  Popover,
  Typography,
} from 'extended-oxygen-elements';
import { bindPopover, bindTrigger, usePopupState } from 'hooks';
import React from 'react';

export default function IconButtonClockOutViolation() {
  const popupState = usePopupState();

  const handleCloseBtnClick = () => {
    popupState.close();
  };

  return (
    <>
      <IconButton {...bindTrigger(popupState)}>
        <HelpOutlineIcon />
      </IconButton>

      <Popover {...bindPopover(popupState)}>
        <Box p={3} maxWidth={540}>
          <Typography variant="h6">Clock off violation</Typography>

          <Typography>
            A violation is used to show the manager something unexpected has
            happened with the timesheet. A clock off violation can be configured
            to flag a timesheet for review if the employee has clocked off
            outside of the defined grace period.
          </Typography>

          <br />

          <Typography variant="h6">
            Example: Clock off violation = 5 minutes
          </Typography>

          <Typography>
            The shift end time is 5PM and the employee clocks off at 5:06PM. The
            shift is automatically flagged as a violation so the manager can
            review it.
          </Typography>

          <Box display="flex" justifyContent="flex-end" pt={3}>
            <Button variant="contained" onClick={handleCloseBtnClick}>
              Close
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
}
