import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment';
import { Col, FormItem, Row } from 'elmo-elements';
import { StoreState } from 'state/types';
import { getDateFormat } from 'state/Account';
import { DateInputMoment } from '../';

type Field = {
  name: string;
  value: Moment | null;
};

type OwnProps = {
  onChange: (payload: { name: string; value: Moment | null }) => void;
  from: Field;
  to: Field;
  label?: string;
  isDisabled?: boolean;
};

type StateProps = {
  dateFormat: string;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

class FromToFiltersComponent extends Component<Props> {
  render() {
    const { from, to, dateFormat, label = 'Date', isDisabled = false } = this.props;
    return (
      <FormItem label={label} isDivider={true}>
        <Row isNoGutters={true} role="presentation">
          <Col span={24 / 2} role="presentation">
            <div className="mr-1">
              <FormItem.Label label="From" />
              <DateInputMoment
                id={`${label}-from`}
                onChange={this.onChangeDateInput}
                value={from.value}
                name={from.name}
                placeholder={dateFormat}
                disabledDate={this.disabledFrom}
                isDisabled={isDisabled}
                ariaLabel="From"
              />
            </div>
          </Col>

          <Col span={24 / 2} role="presentation">
            <div className="ml-1">
              <FormItem.Label label="To" />
              <DateInputMoment
                id={`${label}-to`}
                onChange={this.onChangeDateInput}
                value={to.value}
                name={to.name}
                placeholder={dateFormat}
                disabledDate={this.disabledTo}
                isDisabled={isDisabled}
                ariaLabel="To"
              />
            </div>
          </Col>
        </Row>
      </FormItem>
    );
  }

  private onChangeDateInput = (value: Moment | null, name: string) => {
    this.props.onChange({
      name,
      value
    });
  };

  private disabledFrom = this.disabledFromTo('to', 'isSameOrAfter');
  private disabledTo = this.disabledFromTo('from', 'isSameOrBefore');

  private disabledFromTo(
    field: keyof Pick<OwnProps, 'from' | 'to'>,
    condition: keyof Pick<Moment, 'isSameOrAfter' | 'isSameOrBefore'>
  ) {
    return (currentValue?: Date): boolean => {
      const dateToCompare: Moment | null = this.props[field].value;

      if (!dateToCompare) {
        return false;
      }

      const currentValueMomentDay = moment(currentValue).startOf('day');
      const dateToCompareDay = dateToCompare.clone().startOf('day');

      return currentValueMomentDay[condition](dateToCompareDay);
    };
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  dateFormat: getDateFormat(state)
});

const mapDispatchToProps: DispatchProps = {};

export const FromToFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(FromToFiltersComponent);
