import React, { ReactNode } from 'react';
import { Col, Row, Text, withLayoutAware, Button } from 'elmo-elements';
import { DispatchProps, Props, StateProps } from './type';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import { getWorkingShiftsSelector } from 'state/ManagerDashboard/WhosWorking';
import { EditIcon, DSTIcon, NotesIcon } from 'element';
import { capitalize, getFullUserRole, getUserName } from 'lib/helpers';
import { DashboardShift, UserFields } from 'type/models';
import { userListSelector } from 'state/UsersCollection';
import {
  BOX_ROSTER_SHIFT_MODAL_GET_BY_ID,
  getRosteredShiftModal,
} from 'state/Roster/RosterShiftModal';
import {
  getDateFormat,
  getLangPreferences,
  timeFormatSelector,
} from 'state/Account';
import { hasPermissionSelector } from 'state/Auth';
import { getAreas, getRoles, getSites } from 'state/AccountTree';
import { Avatar, Card, CardContent, CardHeader } from 'oxygen-elements';

export class TomorrowCardComponent extends React.Component<Props> {
  render() {
    const { shift, langPreferences } = this.props;
    const userFrom: UserFields = this.props.userList[shift.user_id];
    return (
      <div className="working-tomorrow-card" data-testid={shift.id}>
        <Card className="who-is-working-card-item" role="row" id={shift.id}>
          <CardHeader
            className="card-header"
            avatar={
              <Avatar
                src={userFrom.avatar.src}
                alt={userFrom.avatar.label}
                size={'small'}
                light
              />
            }
            title={getUserName(userFrom)}
            titleTypographyProps={{
              fontSize: 16,
              fontWeight: 500,
            }}
            action={this.option}
          />
          <CardContent role="grid" className="card-content">
            <Row>
              <Col span={24 / 3}>
                <Text size={'xs'} color={'gray'}>
                  {capitalize(langPreferences.role.singular)} -{' '}
                  {langPreferences.area.singular},{' '}
                  {langPreferences.site.singular}
                </Text>
                <div data-testid="site-area-role">
                  {this.getLocation(shift)}
                </div>
              </Col>
              <Col span={24 / 3}>
                <Text size={'xs'} color={'gray'}>
                  Start - finish
                </Text>
                <div data-testid="start-finish">
                  {this.renderStartFinish(shift)}
                </div>
              </Col>
              {shift.event_name && (
                <Col span={24 / 3}>
                  <Text size={'xs'} color={'gray'}>
                    Event name:
                  </Text>
                  <div data-testid={'event-name'}>{shift.event_name}</div>
                </Col>
              )}
              <Col span={24 / 3}>
                <NotesIcon notes={shift.notes} />
              </Col>
            </Row>
          </CardContent>
        </Card>
      </div>
    );
  }

  get option(): ReactNode {
    const {
      canEditRosteredShifts,
      shift: { id },
    } = this.props;

    if (!canEditRosteredShifts) {
      return null;
    }

    return (
      <Button
        className="on-edit-button"
        id={`edit-${id}`}
        icon={<EditIcon />}
        onClick={this.onEditClick}
        ariaLabel="Edit"
      />
    );
  }

  get userId(): string | undefined {
    const { userList, shift } = this.props;

    const userFrom: UserFields | undefined = userList[shift.user_id];

    if (userFrom) {
      return userFrom.id;
    }
  }

  onEditClick = () => {
    const { onChangeSiteID, shift, getRosterById } = this.props;

    if (onChangeSiteID) {
      onChangeSiteID(shift.site_id);
    }
    getRosterById({
      id: shift.id,
      start: shift.start,
      end: shift.end,
      user_id: this.userId,
      area_id: shift.area_id,
      role_id: shift.role_id,
    });
  };

  getLocation = (roster: DashboardShift) => {
    const { roles, areas, sites } = this.props;

    return getFullUserRole({
      sites,
      areas,
      roles,
      role: roster as unknown as {
        site_id: string;
        area_id: string;
        role_id: string;
        is_main?: boolean;
        site_name: string;
        area_name: string;
        role_name: string;
      }, // TODO: discuss with BE, update BE model, check other cards
    });
  };

  renderStartFinish = (shift: DashboardShift) => {
    const { dateFormat, timeFormat } = this.props;

    return (
      <>
        {shift.start.format(dateFormat)}, {shift.start.format(timeFormat)} -{' '}
        {shift.end.format(timeFormat)}
        {shift.is_dst_intersect && (
          <>
            {' '}
            <DSTIcon isDST={shift.is_dst_intersect} />
          </>
        )}
      </>
    );
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  workingShifts: getWorkingShiftsSelector(state),
  userList: userListSelector(state),
  areas: getAreas(state),
  roles: getRoles(state),
  sites: getSites(state),
  rosteredShiftModal: getRosteredShiftModal(state),
  langPreferences: getLangPreferences(state),
  dateFormat: getDateFormat(state),
  timeFormat: timeFormatSelector(state),
  canEditRosteredShifts: hasPermissionSelector(
    state,
    'roster.rosteredshift.edit'
  ),
});

const mapDispatchToProps: DispatchProps = {
  getRosterById: BOX_ROSTER_SHIFT_MODAL_GET_BY_ID,
};

export const TomorrowCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(withLayoutAware(TomorrowCardComponent));
