import Col from '../../Grid/Col';
import React, { ReactChild, ReactNode } from 'react';
import { ClassNamesForCol, ResponsiveSize } from '../type';
import './ListTableTd.scss';
import { withListTableContext } from '../ListTableContextConsumer';

export type ListTableTdProps = {
  /** The content of the cell */
  children?: ReactNode;
  /** @Ignore Passed from ListTableTr */
  data?: any;
  /** @Ignore Passed from ListTableTr */
  rowIndex?: number;
  /** @Ignore Passed from ListTableTr */
  colIndex?: number;
  /** @Ignore Passed from ListTableTr */
  label?: string;
  /** @Ignore Passed from ListTableTr */
  property?: string;
  /** @Ignore Passed from ListTableTr */
  render?: (rowData: any, rowIndex: number) => string | ReactChild;
  /** @Ignore From DataTableContext */
  classNamesForCols?: ClassNamesForCol[];
  /** @Ignore From DataTableContext */
  colSpanForCols?: ResponsiveSize[];
};

function ListTableTd({
  data,
  rowIndex,
  colIndex,
  label,
  property,
  classNamesForCols,
  colSpanForCols,
  render,
  children,
}: ListTableTdProps) {
  if (colIndex === undefined || rowIndex === undefined) {
    return null;
  }

  const classNamesForIndex = classNamesForCols
    ? classNamesForCols[colIndex]
    : '';
  const colSpanForIndex = colSpanForCols ? colSpanForCols[colIndex] : {};

  const labelClassNames = classNamesForIndex ? classNamesForIndex.label : '';

  return (
    <Col key={colIndex} className="elmo-listtable__col" {...colSpanForIndex}>
      <>
        <div className={'elmo-listtable__data-label ' + labelClassNames}>
          {label}
        </div>
        <div className="elmo-listtable__data">
          {children}
          {render && render(data, rowIndex)}
          {!render && property && data[property]}
        </div>
      </>
    </Col>
  );
}

export default withListTableContext(ListTableTd);
