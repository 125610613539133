import React from 'react';
import { getClass, noop } from '../../_lib/helper';
import { DropdownBaseDividerType } from '../type';

type DropdownBaseDividerProps = {
  type?: DropdownBaseDividerType;
};

export const DropdownBaseDivider = ({ type }: DropdownBaseDividerProps) => (
  <hr
    className={getClass('oxygen-dropdown-base__divider', '', {
      short: type === 'short',
    })}
    // noop required to prevent DropdownItem click event from triggering on Chrome device emulator
    onClick={noop}
  />
);
