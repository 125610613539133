import React from 'react';
import clsx from 'clsx';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { DefaultDivProps } from 'type';
import { capitalize } from 'lib/helpers';
import { blue100, blue50, green100, green50 } from '../../constants';
import useShiftCardEventStyles from './useShiftCardEventStyles';

const styles = () =>
  createStyles({
    root: {
      height: 56,
      borderRadius: '3px 3px 0 0',
      cursor: 'pointer',
      transition: 'background .2s ease',
    },
    notClickable: {
      cursor: 'default',
    },
    transparent: {
      opacity: 0.5,
    },
    colorBlue: {
      backgroundColor: blue50,
      boxShadow: `inset 0 0 1px 1.5px ${blue100}`,
      '&:hover': {
        backgroundColor: blue100,
      },
    },
    colorGreen: {
      backgroundColor: green50,
      boxShadow: `inset 0 0 1px 1.5px ${green100}`,
      '&:hover': {
        backgroundColor: green100,
      },
    },
  });

type StyleProps = WithStyles<typeof styles>;
type ShiftCardEventProps = StyleProps &
  DefaultDivProps & {
    color?: 'blue' | 'green';
    notClickable?: boolean;
    transparent?: boolean;
  };

export const ShiftCardEvent = withStyles(styles)(function ShiftCardEvent({
  classes,
  className,
  children,
  color = 'blue',
  notClickable = false,
  transparent = false,
  ...restProps
}: ShiftCardEventProps) {
  const eventCardClasses = useShiftCardEventStyles();

  return (
    <div
      {...restProps}
      className={clsx([
        classes.root,
        eventCardClasses.root,
        notClickable && classes.notClickable,
        transparent && classes.transparent,
        classes[`color${capitalize(color)}` as const],
        className,
        'event-shift-card',
      ])}
    >
      {children}
    </div>
  );
});
