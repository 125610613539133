import { createReducer } from 'lib/store-utils';
import { ManagerDashboardReducerState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';
import { cloneDeep } from 'lodash';

export const managerDashboard = createReducer<ManagerDashboardReducerState>(
  {},
  getDefaultState()
);

managerDashboard.on(
  actions.BOX_MANAGER_DASHBOARD_FILTERS_MODAL_OPEN,
  (state): ManagerDashboardReducerState => ({
    ...state,
    isOpened: true
  })
);

managerDashboard.on(
  actions.BOX_MANAGER_DASHBOARD_FILTERS_MODAL_CLOSE,
  (state): ManagerDashboardReducerState => ({
    ...state,
    isOpened: false
  })
);

managerDashboard.on(
  actions.BOX_MANAGER_DASHBOARD_UPDATE_SEARCH_QUERY,
  (state, query): ManagerDashboardReducerState => ({
    ...state,
    search: {
      query
    }
  })
);

managerDashboard.on(
  actions.BOX_MANAGER_DASHBOARD_UPDATE_FILTERS_REQUEST,
  (state, requestedFilters): ManagerDashboardReducerState => ({
    ...state,
    isUpdating: true,
    requestedFilters
  })
);

managerDashboard.on(
  actions.BOX_MANAGER_DASHBOARD_UPDATE_FILTERS_SUCCESS,
  (state): ManagerDashboardReducerState => ({
    ...state,
    isUpdating: false,
    isOpened: false,
    fetchedFilters: cloneDeep(state.requestedFilters)
  })
);

managerDashboard.on(
  actions.BOX_MANAGER_DASHBOARD_UPDATE_FILTERS_FAILURE,
  (state, errors): ManagerDashboardReducerState => ({
    ...state,
    errors,
    isUpdating: false,
    isOpened: false
  })
);

managerDashboard.on(
  actions.BOX_MANAGER_DASHBOARD_CLEAR_ERRORS,
  (state): ManagerDashboardReducerState => ({
    ...state,
    errors: []
  })
);
