import { StoreState } from '../../../types';
import { Pager, SortUser } from '../../../../type';
import {
  SelectedFilter,
  UserFiltersActivity,
  UserFiltersForm,
  CreateUserModalType,
} from '../types';

export const getErrors = (state: StoreState) => state.user.errors;
export const getUserFiltersActivity = (
  state: StoreState
): UserFiltersActivity => state.user.filters.activity;
export const getUserFiltersRole = (state: StoreState): string[] =>
  state.user.filters.role;
export const getUserFiltersArea = (state: StoreState): string[] =>
  state.user.filters.area;
export const getUserFiltersUserGroup = (state: StoreState): string[] =>
  state.user.filters.userGroup;
export const getUserFiltersTag = (state: StoreState): string[] =>
  state.user.filters.tag;
export const getUserFiltersForm = (state: StoreState): UserFiltersForm =>
  state.user.filtersForm;
export const getSort = (state: StoreState): SortUser => state.user.sort;
export const getSearchQuery = (state: StoreState): string =>
  state.user.search.query;
export const getPager = (state: StoreState): Pager => state.user.pager;
export const getAddRoleModalUserId = (state: StoreState): string =>
  state.user.addRoleModal.userId;
export const getEditApproversModal = (state: StoreState) =>
  state.user.editApproversModal;
export const getEditApproversUserId = (state: StoreState) =>
  state.user.editApproversModal.userId;
export const getBulkEditApproversModal = (state: StoreState) =>
  state.user.bulkEditApproversModal;
export const getSelectedFilter = (state: StoreState): SelectedFilter =>
  state.user.selectedFilter;
export const getCreateUserModal = (state: StoreState): CreateUserModalType =>
  state.user.createUserModal;
export const getBulkAssignLocationModal = (state: StoreState) =>
  state.user.bulkAssignLocation;
