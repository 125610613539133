import React, { ReactNode } from 'react';
import { NotificationsOutlinedIcon } from 'element/icons';
import { connect } from 'react-redux';
import { HeaderButtonProp } from 'element/Header';
import { BOX_NOTIFICATIONS_LIST_OPEN_MODAL } from 'state/NotificationsList/actions';
import { StoreState } from 'state/types';
import {
  getEmployeeNotificationsUnreadCount,
  getManagerNotificationsUnreadCount,
} from 'state/NotificationsList/selectors';
import { NotificationListType } from 'state/NotificationsList/types';

type OwnProps = {
  children: (payload: { notificationButton: HeaderButtonProp }) => ReactNode;
  type: NotificationListType;
};

type StateProps = {
  managerUnreadCount: number;
  employeeUnreadCount: number;
};

type DispatchProps = {
  openNotificationsList: (type: NotificationListType) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const getBadgeConfig = (type: NotificationListType, messagesCount: number) => {
  const badgeConfig = {
    children: messagesCount,
    type: 'info' as any,
  };
  return messagesCount > 0 ? badgeConfig : undefined;
};

export const WithNotificationsListButtonComponent = ({
  children,
  openNotificationsList,
  type,
  employeeUnreadCount,
  managerUnreadCount,
}: Props) => {
  const messageCount =
    type === 'employee' ? employeeUnreadCount : managerUnreadCount;
  return (
    <>
      {children({
        notificationButton: {
          icon: <NotificationsOutlinedIcon />,
          label: 'Notifications',
          onClick: () => openNotificationsList(type),
          badge: getBadgeConfig(type, messageCount),
          tooltip: 'Notifications',
        },
      })}
    </>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  managerUnreadCount: getManagerNotificationsUnreadCount(state),
  employeeUnreadCount: getEmployeeNotificationsUnreadCount(state),
});

const mapDispatchToProps: DispatchProps = {
  openNotificationsList: BOX_NOTIFICATIONS_LIST_OPEN_MODAL,
};

export const WithNotificationsListButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithNotificationsListButtonComponent);
