import React from 'react';
import { getClass } from '../_lib/helper';
import { TextAreaProps } from './type';
import './TextArea.scss';

const TextArea = <C extends React.ElementType = 'textarea'>(
  props: TextAreaProps<C> &
    Omit<React.ComponentPropsWithoutRef<C>, keyof TextAreaProps<C>>
) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { isDisabled, onChange } = props;
    if (e === undefined) {
      return;
    }

    if (isDisabled) {
      e.stopPropagation();
      return;
    }

    if (onChange) {
      onChange(e);
    }
  };

  const handleOnKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const { isDisabled, onEnterPress, onKeyPress } = props;
    if (e === undefined) {
      return;
    }

    if (isDisabled) {
      e.stopPropagation();
      return;
    }

    if (e.charCode === 13 && onEnterPress) {
      onEnterPress(e);
    }

    if (onKeyPress) {
      onKeyPress(e);
    }
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    const { onBlur } = props;
    if (onBlur) {
      onBlur(e);
    }
  };

  const handleOnFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    const { onFocus } = props;
    if (onFocus) {
      onFocus(e);
    }
  };

  const {
    className,
    name,
    placeholder,
    isDisabled,
    isHidden,
    id,
    rows,
    value,
    ariaLabel,
    onKeyUp,
    component: Component = 'textarea',
    ...rest
  } = props;

  return (
    <Component
      {...rest}
      id={id}
      data-testid={`elmo-textarea-${id || 'default'}`}
      className={getClass('elmo-textarea', [className])}
      name={name}
      placeholder={placeholder}
      onKeyUp={onKeyUp}
      onChange={handleOnChange}
      onKeyPress={handleOnKeyPress}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      disabled={isDisabled}
      hidden={isHidden}
      rows={rows}
      value={value}
      aria-label={ariaLabel}
    />
  );
};

export default TextArea;
