import React from 'react';
import { useSelectorWithProps } from 'hooks';
import { getTimesheet } from 'state/TimesheetsCollection';
import { useAreaName, useLinkedRole, useTimesheetCard } from './hooks';
import { TimesheetContextualMenu } from './contextualMenus';
import {
  ShiftCardBreaksDuration,
  ShiftCardContextMenuIcon,
  ShiftCardIcons,
} from './views';
import { ShiftCardViewRefactored } from './views/ShiftCardViewRefactored';

type Props = {
  timesheetId: string;
};

export const UserTimesheetCard = React.memo(function UserTimesheetCard({
  timesheetId,
}: Props) {
  const timesheet = useSelectorWithProps(getTimesheet, timesheetId);
  const { viewProps, icons, breaks, onClickContextMenu } =
    useTimesheetCard(timesheet);
  const linkedRole = useLinkedRole(timesheet);
  const areaName = useAreaName(timesheet);

  return (
    <ShiftCardViewRefactored
      {...viewProps}
      data-timesheet-id={timesheetId}
      beforeContent={
        <TimesheetContextualMenu
          elementId={viewProps.id}
          timesheetId={timesheetId}
        />
      }
      icons={
        <>
          <ShiftCardIcons icons={icons} />
          {onClickContextMenu && (
            <ShiftCardContextMenuIcon onClick={onClickContextMenu} />
          )}
        </>
      }
      breaks={<ShiftCardBreaksDuration breaks={breaks} />}
      title={linkedRole}
      spotTitle={areaName}
    />
  );
});
