import React, { Component, FormEvent, MouseEvent } from 'react';
import { FormItem } from 'elmo-elements';
import {
  ErrorBox,
  PageDialog,
  PageDialogCancel,
  PageDialogSubmit
} from 'element';
import { keyMirror } from 'lib/helpers';
import { OnChangeSelectEvent } from '../../../../element/Select/types';
import { SelectPropsOption } from 'elmo-elements/Select';
import { DEFAULT } from './constants';
import {
  DispatchProps,
  OwnProps,
  Placeholders,
  Props,
  State,
  StateProps,
} from './types';
import { getPlaceholders, getState, getSubmitPayload } from './helpers';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import {
  leaveApproversSelectOptionsSelector,
  offerApproversSelectOptionsSelector,
  swapApproversSelectOptionsSelector,
} from 'state/Users/UserApprovalsModal/selectors';
import { ApprovalsModalLoader } from './components';
import { BOX_USER_APPROVALS_MODAL_GET_APPROVALS } from 'state/Users/UserApprovalsModal/actions';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
} from 'extended-oxygen-elements';

const defaultApproverOption: SelectPropsOption = {
  label: 'Default approver',
  value: DEFAULT,
};

export type ApprovalsModalProps = OwnProps;

export class ApprovalsModalComponent extends Component<Props, State> {
  state = getState(this.props);
  names = keyMirror(this.state.data);

  render() {
    const {
      state: { data },
      names,
      props: { errors, isOpened, isUpdating },
      placeholders,
    } = this;

    return (
      <PageDialog
        maxWidth={'xs'}
        open={isOpened}
        id="user-update-approvals-modal"
        onClose={this.onCloseModal}
      >
        <DialogTitle>Approvals</DialogTitle>
        <DialogContent>
          <ApprovalsModalLoader>
            <form onSubmit={this.onSubmit}>
              <ErrorBox errors={errors} className="mb-6" />

              <FormItem label="Unavailability">
                <Select
                  fullWidth
                  name={names.leave}
                  onChange={e => {this.onSelectChange(e.target)}}
                  value={data.leave}
                  aria-label={'Unavailability'}
                  className={'leave-dropdown'}
                >
                  {this.optionsLeave.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>)
                    )
                  }
                </Select>
              </FormItem>

              <FormItem label="Shift offers">
                <Select
                  fullWidth
                  name={names.shift_offer}
                  onChange={e => {this.onSelectChange(e.target)}}
                  value={data.shift_offer}
                  aria-label={'Shift offers'}
                  className={'offers-dropdown'}
                >
                  {this.optionsShiftOffer.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>)
                    )
                  }
                </Select>
              </FormItem>

              <FormItem label="Shift swaps">
                <Select
                  fullWidth
                  name={names.shift_swap}
                  onChange={e => {this.onSelectChange(e.target)}}
                  defaultValue={''}
                  value={data.shift_swap}
                  aria-label={'Shift swaps'}
                  className={'swaps-dropdown'}
                >
                  {this.optionsShiftSwap.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>)
                    )
                  }
                </Select>
              </FormItem>
            </form>
          </ApprovalsModalLoader>
        </DialogContent>
        <DialogActions>
          <PageDialogCancel onClick={this.onCloseModal} fullWidth={false}>
            Cancel
          </PageDialogCancel>
          <PageDialogSubmit
            onClick={this.onSubmit}
            loading={isUpdating}
            disabled={!this.state.isValid}
            fullWidth={false}
          >
            Save
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.isOpened !== this.props.isOpened) {
      if (this.props.isOpened) {
        // onOpen
        this.setState(getState(this.props));
        this.getApprovals();
      } else {
        // onClose
        this.setState({
          isValid: false,
        });
      }
    }
  }

  getApprovals() {
    const { userIds, getApprovals } = this.props;

    getApprovals({ userIds });
  }

  get placeholders(): Placeholders {
    return getPlaceholders(this.props.managers);
  }

  get optionsLeave(): SelectPropsOption[] {
    return [defaultApproverOption, ...this.props.leaveApprovals];
  }

  get optionsShiftOffer(): SelectPropsOption[] {
    return [defaultApproverOption, ...this.props.shiftOfferApprovals];
  }

  get optionsShiftSwap(): SelectPropsOption[] {
    return [defaultApproverOption, ...this.props.shirtSwapApprovals];
  }

  onSelectChange = ({ name, value }: OnChangeSelectEvent) => {
    this.setState(
      {
        data: {
          ...this.state.data,
          [name]: value,
        },
      },
      this.validate
    );
  };

  onSubmit = (
    event: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    if (this.state.isValid) {
      this.props.onFormSubmit(getSubmitPayload(this.state.data));
    }
  };

  validate = () => {
    this.setState({
      isValid: true,
    });
  };

  onCloseModal = () => {
    if (!this.props.isUpdating) {
      this.props.onCloseModal();
    }
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  leaveApprovals: leaveApproversSelectOptionsSelector(state),
  shiftOfferApprovals: offerApproversSelectOptionsSelector(state),
  shirtSwapApprovals: swapApproversSelectOptionsSelector(state),
});

const mapDispatchToProps: DispatchProps = {
  getApprovals: BOX_USER_APPROVALS_MODAL_GET_APPROVALS,
};

export const ApprovalsModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApprovalsModalComponent);
