import React from 'react';
import { MyRostersFilters } from './components/MyRostersFilters';
import { MyRostersList } from './components/MyRostersList';
import { InfoIconButton } from './components/InfoIconButton';
import './view.scss';

const MyRostersView = () => {
  return (
   <div>
     <div className="my-rosters-filters-wrapper">
       <MyRostersFilters />
       <InfoIconButton />
     </div>
     <MyRostersList />
   </div>
  );
};

export default MyRostersView;
