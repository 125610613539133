import { createAction } from 'lib/store-utils';
import {
  OpenPayload,
  SubmitPayload,
  TogglePayload,
  UpdateSelectedRoleTriplesPayload
} from './types';

export const BOX_SITE_AREA_ROLE_MODAL_OPEN = createAction<OpenPayload>('Site Area Role Modal / open');
export const BOX_SITE_AREA_ROLE_MODAL_SUBMIT = createAction<SubmitPayload>('Site Area Role Modal / submit');
export const BOX_SITE_AREA_ROLE_MODAL_CLOSE = createAction('Site Area Role Modal / close');

export const BOX_SITE_AREA_ROLE_TOGGLE = createAction<TogglePayload>('Site Area Role Modal / toggle site/area/role');
export const BOX_SITE_AREA_ROLE_UPDATE_SELECTED_ROLE_TRIPLES = createAction<UpdateSelectedRoleTriplesPayload>('Site Area Role Modal / update payload');
