import React from 'react';
import {
  Button,
  ListTable,
  LoadingOverlay,
  Paragraph,
  withLayoutAware,
} from 'elmo-elements';
import {
  EditOutlinedIcon,
  EventsModal,
  IsAppMarket,
  Map,
  Marker,
  PageDialog,
  PageDialogCancel,
  SimpleBadge,
  Timer,
  WithAccountPreferences,
} from 'element';
import { connect } from 'react-redux';
import moment from 'moment';
import { Event } from 'type';
import {
  getCurrencyFormatted,
  getDateTimeFormatted,
  getPreferenceLabel,
} from 'lib/helpers';
import { StoreState } from 'state/types';
import * as events from 'state/ManagerDashboard/Events';
import * as account from 'state/Account';
import { hasPermissionSelector } from 'state/Auth';
import { getSites } from 'state/AccountTree';
import { Props, State } from './type';
import { EmptyMessage } from './components';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';

const columns = ['title', 'status', 'location', 'start', 'end'];

export class EventsListComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      sortDirection: 'desc',
      sortColumn: 0,
      data: this.props.data,
      currentPage: this.props.pager.currentPage,
      totalResults: this.props.pager.total,
      isAllAvailableSelected: false,
    };
  }

  onSort = (column: number, direction: string) => {
    this.setState({
      sortColumn: column,
    });

    this.props.changeOrdering({
      name: 'sort',
      value: {
        column: columns[column],
        direction,
      },
    });
  };

  onPageChange = (pageNumber: number) => {
    this.props.changePage(pageNumber);
  };

  onPageSizeChange = (pageSize: number) => {
    this.props.changePageSize(pageSize);
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.setState({
        data: this.props.data,
        totalResults: this.props.total,
      });

      this.setState({
        sortColumn: columns.indexOf(this.props.filters.sort.column),
      });
    }
  }

  renderListTableFlexible = () => {
    const {
      eventSettings,
      langPreferences,
      dateFormat,
      timeFormat,
      numberFormat,
      currencyCode,
      currencyPlacement,
    } = this.props;
    return (
      <div>
        <LoadingOverlay isLoading={this.props.isFetching} showSpinner={true}>
          {this.state.data.length ? (
            <WithAccountPreferences>
              <ListTable
                id="manager-dashboard-events-list-table"
                data={this.state.data}
                onSort={this.onSort}
                ariaLabel="Events"
                sortDirection={this.props.filters.sort.direction}
                sortColumn={this.state.sortColumn}
                testId={(event: Event) => event.id}
                icon={(event) => {
                  return this.props.hasPermissionToViewEvents ? (
                    <Button
                      onClick={() => {
                        this.editEvent(event);
                      }}
                      icon={<EditOutlinedIcon />}
                      ariaLabel="Edit"
                    />
                  ) : (
                    <></>
                  );
                }}
              >
                <ListTable.Column
                  label={getPreferenceLabel(
                    langPreferences,
                    'event',
                    'singular',
                    '',
                    true
                  )}
                  render={(data) => {
                    return (
                      <>
                        <div data-testid="event-name">{data.name}</div>
                        {eventSettings.show_event_item && (
                          <Paragraph size="xs">
                            <span data-testid="event-item">{data.item}</span>
                          </Paragraph>
                        )}
                        {this.renderTimezone(data)}
                      </>
                    );
                  }}
                  sortable={true}
                />
                <ListTable.Column
                  label={'Status'}
                  render={this.renderStatus}
                  property={'status'}
                  sortable={true}
                />
                {eventSettings.show_event_address && (
                  <ListTable.Column
                    label={'Address'}
                    render={this.renderEventAddress}
                    sortable={false}
                  />
                )}
                {eventSettings.show_event_start && (
                  <ListTable.Column
                    label={'Start'}
                    render={(data) => (
                      <div data-testid="event-start">
                        {getDateTimeFormatted(
                          dateFormat,
                          timeFormat,
                          data.start,
                          true
                        )}
                      </div>
                    )}
                    sortable={true}
                  />
                )}
                {eventSettings.show_event_finish && (
                  <ListTable.Column
                    label={'Finish'}
                    render={(data) => (
                      <div data-testid="event-finish">
                        {getDateTimeFormatted(
                          dateFormat,
                          timeFormat,
                          data.end,
                          true
                        )}
                      </div>
                    )}
                    sortable={true}
                  />
                )}
                {eventSettings.show_value && (
                  <ListTable.Column
                    label={'Value'}
                    render={(data) => (
                      <div data-testid="event-value">
                        {getCurrencyFormatted(
                          numberFormat,
                          currencyCode,
                          currencyPlacement,
                          +data.value
                        )}
                      </div>
                    )}
                    sortable={false}
                  />
                )}
              </ListTable>
              <ListTable.Pagination
                id="manager-dashboard-events-list-table-pagination"
                pageSize={this.props.pager.pageSize}
                currentPage={this.props.pager.currentPage}
                onPageChange={this.onPageChange}
                onPageSizeChange={this.onPageSizeChange}
                totalResults={this.props.pager.total}
              />
            </WithAccountPreferences>
          ) : (
            <EmptyMessage />
          )}

          <EventsModal />
        </LoadingOverlay>
      </div>
    );
  };

  private renderEventAddress = (event: Event) => {
    const { lat, lng } = event;

    if (lat === 0 && lng === 0) {
      return <span data-testid="event-address">{event.address}</span>;
    }

    return (
      <a
        data-testid="event-address"
        href=""
        onClick={(e) => {
          this.openModal(e, event);
        }}
      >
        {event.address}
      </a>
    );
  };

  render() {
    return (
      <div>
        {this.renderListTableFlexible()}
        {this.props.mapModal.isOpen ? this.renderModal() : <></>}
      </div>
    );
  }

  renderTimezone = (data: Event) => {
    const { site_id } = data;
    const { sites } = this.props;

    return (
      <IsAppMarket market={'uk'} negate={true}>
        <div className="timezone-wrapper" data-testid="event-timezone">
          <Paragraph size="xs">{sites[site_id].timezone_id}</Paragraph>
        </div>
      </IsAppMarket>
    );
  };

  renderModal = () => {
    const { lng, lat } = this.props.mapModal.marker;

    if (lat === null || lng === null) {
      return;
    }

    const coords = { lat, lng };

    return (
      <div className="map-modal">
        <PageDialog
          maxWidth={'sm'}
          open={this.props.mapModal.isOpen}
          onClose={this.props.closeMap}
          id="event-map-modal"
        >
          <DialogTitle>Map</DialogTitle>
          <DialogContent>
            <Map center={coords}>
              <Marker position={coords} />
            </Map>
          </DialogContent>
          <DialogActions>
            <PageDialogCancel
              onClick={this.props.closeMap}
              variant={'outlined'}
            >
              Close
            </PageDialogCancel>
          </DialogActions>
        </PageDialog>
      </div>
    );
  };

  renderStatus = (event: Event) => {
    const { start, end } = event;

    if (start < moment() && end > moment()) {
      return (
        <div data-testid="event-status-badge">
          <SimpleBadge type="info" label={'now'} />
        </div>
      );
    }

    if (moment.parseZone(start) > moment()) {
      return (
        <div className="badge-container">
          <div data-testid="event-status-badge">
            <SimpleBadge type="warning" label={'upcoming'} />
          </div>
          <div data-testid="event-status-timer">
            <Timer date={event.start} />
          </div>
        </div>
      );
    }

    if (moment.parseZone(end) < moment()) {
      return (
        <div data-testid="event-status-badge">
          <SimpleBadge type="success" label={'finished'} />
        </div>
      );
    }

    return '';
  };

  openModal = (e: any, event: Event) => {
    e.preventDefault();
    this.props.openMap({ lat: event.lat, lng: event.lng });
  };

  editEvent = (event: Event) => {
    this.props.openEventModal({ id: event.id });
  };
}

const mapStateToProps = (state: StoreState) => ({
  isFetching: state.managerDashboard.events.isFetching,
  data: events.getEventsList(state),
  filters: events.getFilters(state),
  pager: events.eventsWithPagerSelector(state),
  mapModal: events.getMapModal(state),
  eventSettings: account.getEventSettings(state),
  sites: getSites(state),
  langPreferences: account.getLangPreferences(state),
  dateFormat: account.getDateFormat(state),
  timeFormat: account.getTimeFormat(state),
  numberFormat: account.getNumberFormat(state),
  currencyCode: account.getCurrencyCode(state),
  currencyPlacement: account.getCurrencyPlacement(state),
  hasPermissionToViewEvents: hasPermissionSelector(state, 'roster.events.edit'),
});

export const EventsList = connect(mapStateToProps, {
  getData: events.BOX_EVENTS_DATA_REQUEST,
  changePage: events.BOX_EVENTS_CHANGE_PAGE_REQUEST,
  changePageSize: events.BOX_EVENTS_CHANGE_PAGE_SIZE_REQUEST,
  changeOrdering: events.BOX_EVENTS_UPDATE_ORDERING_REQUEST,
  openMap: events.BOX_EVENTS_OPEN_MAP,
  closeMap: events.BOX_EVENTS_CLOSE_MAP,
  openEventModal: events.BOX_EVENTS_OPEN_EVENTS_MODAL,
})(withLayoutAware(EventsListComponent));
