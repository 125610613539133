import { AlertErrorBoxLayout } from 'element';
import { BoxProps } from 'extended-oxygen-elements';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BOX_TIMESHEETS_WEEKLY_ERRORS_CLOSED,
  getPageErrors,
} from 'state/TimesheetsWeeklyPage';

export function ErrorBox(boxProps: BoxProps) {
  const dispatch = useDispatch();
  const errors = useSelector(getPageErrors);

  const handleClose = () => {
    dispatch(BOX_TIMESHEETS_WEEKLY_ERRORS_CLOSED());
  };

  return (
    <AlertErrorBoxLayout
      BoxProps={boxProps}
      errors={errors}
      onClose={handleClose}
    />
  );
}
