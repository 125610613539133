import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSelectorWithProps } from 'hooks';
import { hasPermissionSelector } from 'state/Auth';
import {
  BOX_ROSTER_OPEN_DELETE_CONFIRMATION_MODAL,
  getIsApprovalModeEnabled,
} from 'state/Roster/Roster';
import { getIsBulkDeleteOpened } from 'state/Roster/BulkActions';
import { BOX_EVENT_MODAL_OPEN } from 'state/Roster/EventModal';
import { RosterEventUpdated } from './types';

function useEventCard({ id, site_id, type, start, end }: RosterEventUpdated) {
  const dispatch = useDispatch();
  const isApprovalModeEnabled = useSelector(getIsApprovalModeEnabled);
  const canEditEvents = useSelectorWithProps(
    hasPermissionSelector,
    'roster.events.edit'
  );
  const isBulkDeleteOpened = useSelector(getIsBulkDeleteOpened);
  const isEditBlocked = isApprovalModeEnabled || isBulkDeleteOpened;
  const isEventEditable = canEditEvents && !isEditBlocked;

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      if (isEventEditable) {
        dispatch(
          BOX_EVENT_MODAL_OPEN({
            id,
          })
        );
      }
    },
    [isEventEditable, id]
  );

  const handleDoubleClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
    },
    []
  );

  const handleDeleteBtnClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      dispatch(
        BOX_ROSTER_OPEN_DELETE_CONFIRMATION_MODAL({ id, type: 'event' })
      );
    },
    [id]
  );

  const handleAddBtnClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();

      dispatch(
        BOX_EVENT_MODAL_OPEN({
          siteId: site_id,
          type,
          start: start.clone(),
          end: end.clone(),
        })
      );
    },
    [site_id, type, start.format(), end.format()]
  );

  return {
    isEditBlocked,
    isEventEditable,
    handleClick,
    handleDoubleClick,
    handleDeleteBtnClick,
    handleAddBtnClick,
  };
}

export default useEventCard;
