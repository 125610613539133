import React from 'react';
import { MessageBlock } from 'elmo-elements';

export const EmptyMessage = () => (
  <MessageBlock>
    <MessageBlock.Header>
      No timesheets matching your filter criteria
    </MessageBlock.Header>
  </MessageBlock>
);
