import {
  RosterFilters,
  RosterFiltersByType,
  RosterFiltersCustom,
} from '../types';

export const getDefaultCustomFilters = (): RosterFiltersCustom => ({
  roleFilterType: 'flat',
  roleTriples: null,
  areas: null,
  roles: null,
  tags: null,
  events: null,
  showArchivedUsers: false,
  showPeople: 'all',
});

export const getDefaultFilters = (): RosterFilters => ({
  unavailability: true,
  roster: true,
  timesheets: false,
  ...getDefaultCustomFilters(),
});

export const getDefaultTypeFiltersByPermissions = (permissions: {
  roster: boolean;
  timesheets: boolean;
}): RosterFiltersByType => ({
  unavailability: !permissions.roster && !permissions.timesheets,
  roster: permissions.roster,
  timesheets: !permissions.roster && permissions.timesheets,
});

export const convertEmptyArrayToNull = (value: string[]): string[] | null => {
  if (!value.length) {
    return null;
  }

  return value;
};

export const convertNullToEmptyArray = (value: string[] | null): string[] => {
  if (!value) {
    return [];
  }

  return value;
};
