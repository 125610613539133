import { HOUR_IN_MIN } from 'lib/config';
import { capitalize } from 'lib/helpers';
import _ from 'lodash';

export type DurationFormatUnit = 'hours' | 'minutes';
export type DurationFormatOptions = { hoursPrefix: string; minutesPrefix: string };

export class Duration {
  readonly asMinutes: number;
  private formatOptions: DurationFormatOptions = {
    hoursPrefix: 'h',
    minutesPrefix: 'm',
  };

  constructor({ hours = 0, minutes = 0 }) {
    this.asMinutes = hours * HOUR_IN_MIN + minutes;
  }

  get minutes() {
    return this.asMinutes % HOUR_IN_MIN;
  }

  get hours() {
    return (this.asMinutes - this.minutes) / HOUR_IN_MIN;
  }

  setFormatOptions(options: Partial<DurationFormatOptions>) {
    this.formatOptions = _.defaults(options, this.formatOptions);

    return this;
  }

  formatTo(unit: DurationFormatUnit): string {
    return this[`formatTo${capitalize(unit)}` as const]();
  }

  formatToMinutes(): string {
    return this.formatMinutes(this.asMinutes);
  }

  formatToHours(): string {
    if (!this.asMinutes) {
      return this.formatHours(0);
    }

    const { hours, minutes } = this;

    return _.compact([
      hours && this.formatHours(hours),
      minutes && this.formatMinutes(minutes),
    ]).join(' ');
  }

  private formatHours(hours: number) {
    return hours + this.formatOptions.hoursPrefix;
  }

  private formatMinutes(
    minutes: number
  ) {
    return minutes + this.formatOptions.minutesPrefix;
  }
}

export default Duration;
