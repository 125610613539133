import React from 'react';
import LoaderHolder from './LoaderHolder';
import { LoaderHolderProps } from '../type';

function CardContentStyle(props: LoaderHolderProps) {
  return (
    <LoaderHolder viewBoxHeight={153} {...props}>
      <rect x="0" y="0" rx="3" ry="3" width="138" height="6" />
      <rect x="0" y="17" rx="3" ry="3" width="296" height="16.5" />
      <rect x="0" y="76" rx="3" ry="3" width="118" height="21" />
      <rect x="0" y="120" rx="3" ry="3" width="71" height="6" />
      <rect x="0" y="132" rx="3" ry="3" width="90" height="21" />
    </LoaderHolder>
  );
}

export default CardContentStyle;
