import React from 'react';
import {
  UnavailabilityFilters,
  UnavailabilityList,
  UnavailabilityPager,
  UnavailabilityCreate,
  UnavailabilityEdit,
  UnavailabilityDelete,
  UnavailabilityErrorBox,
  UnavailabilityConfirmApproval,
  UnavailabilityConfirmCreation,
  UnavailabilityConfirmEdit
} from './components';

const UnavailabilityView = () => (
  <div data-testid="unavailability">
    <UnavailabilityErrorBox />

    <UnavailabilityFilters />

    <UnavailabilityList />

    <UnavailabilityPager />

    <UnavailabilityCreate />

    <UnavailabilityEdit />

    <UnavailabilityDelete />

    <UnavailabilityConfirmApproval />

    <UnavailabilityConfirmCreation />

    <UnavailabilityConfirmEdit />
  </div>
);

export default UnavailabilityView;
