import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ArrowBackIcon as HeaderBackIcon } from 'elmo-elements/icons';
import Button from 'elmo-elements/Button';
import './HeaderBack.scss';
import { HeaderBackProps } from '../type';

export const HeaderBackPropTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  onClick: PropTypes.func,
  ariaLabel: PropTypes.string,
};

export const HeaderBackDefaultProps = {
  ariaLabel: 'Back to previous screen',
};

type HeaderBackState = {};

// TODO: Fix me, if we dont need state and we dont need handleOnClick convert me to a simple function
class HeaderBack extends Component<HeaderBackProps, HeaderBackState> {
  constructor(props: HeaderBackProps) {
    super(props);

    // TODO: Fix me, please remove this state if we dont need it
    this.state = {
      isMinimized: false,
    };
  }

  static defaultProps = HeaderBackDefaultProps;

  // TODO: Fix me, we dont need this extra handleOnClick
  handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    this.props.onClick(e);
  };

  // TODO: Fix me, if posible please remove {' '} */
  render() {
    const { title, ariaLabel, id } = this.props;
    return (
      <div className="elmo-header__back-btn-wrapper">
        <Button
          {...(id ? { id } : null)}
          isText={true}
          onClick={this.handleOnClick}
          icon={<HeaderBackIcon />}
          ariaLabel={ariaLabel}
        >
          {' '}
          {title}
        </Button>
      </div>
    );
  }
}

export default HeaderBack;
