import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { privateRoutes } from 'routes';
import { RosterViewControls } from 'page/Roster/components';

type Props = {
  className?: string;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    switcher: {
      marginRight: 'auto',
    },
    filtersCheckboxSelect: {
      [theme.breakpoints.between('sm', 'md')]: {
        minWidth: 250,
      },
    },
  })
);

export const SwitcherAndFilters = (props: Props) => {
  const classes = useStyles();
  const { rosterWeekViewSiteView, rosterWeekViewUserView } =
    privateRoutes.roster.routes.rosterWeekView.routes;

  return (
    <RosterViewControls
      {...props}
      paths={{
        userView: rosterWeekViewUserView.path,
        siteView: rosterWeekViewSiteView.path,
      }}
      pageType={'week'}
      hidePageTypeSwitcher={false}
      classes={classes}
    />
  );
};
