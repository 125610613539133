import { createFinishedTimesheet } from 'lib/helpers';
import { TimesheetsAwardsReducerState } from './types';

export const getDefaultState = (): TimesheetsAwardsReducerState => ({
  isAwardsModalOpened: false,
  currentTimesheet: createFinishedTimesheet(),
  isCurrentTimesheetChanged: false,
  higherDuties: [],
  selectedHigherDutyId: '',
  errors: []
});
