import React, { Ref } from 'react';
import { Modal, FileUpload, Button } from 'elmo-elements';
import { StoreState } from 'state/types';
import { UserProfileFields } from 'type/models';
import { connect } from 'react-redux';

import {
  BOX_USER_PROFILE_REMOVE_ERRORS,
  BOX_USER_PROFILE_UPDATE_REQUEST,
  BOX_USER_PROFILE_UPLOAD_APPEND,
  BOX_USER_PROFILE_UPLOAD_REMOVE,
  BOX_USER_PROFILE_UPLOAD_REQUEST,
} from 'state/Users/UserProfile/actions';

import {
  CancelOutlinedIcon,
  PageDialog,
  PageDialogCancel,
  PageDialogSubmit,
} from 'element';
import { ErrorBox } from 'element/ErrorBox';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from '../../../../../../../../../../extended-oxygen-elements';

type ImageProps = {
  imageUrl: string;
  id?: string;
};

export const ImagePlaceholder = ({ imageUrl, id }: ImageProps) => (
  <div
    data-testid={id}
    style={{
      height: '140px',
      marginBottom: 15,
      width: '100%',
      backgroundImage: `url('${imageUrl}')`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    }}
  />
);

type OwnProps = {
  isOpened: boolean;
  closeModal: () => void;
  userProfile: UserProfileFields;
};

type StateProps = {
  userProfile: UserProfileFields;
  errors: string[];
};

type State = {
  userProfile: UserProfileFields;
  preview: any;
  uploads: boolean;
  droppedFile: any;
  isUpdating: boolean;
  rejected: boolean;
  isEdited: boolean;
};

type DispatchProps = {
  uploadFile: (params: any) => void;
  removeFile: (name: string) => void;
  appendFile: (name: string) => void;
  saveProfile: (params: any) => void;
  removeErrors: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

export class ProfileImageModal extends React.Component<Props, State> {
  readonly acceptedFiles = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/gif',
  ];
  readonly fileSize = 5;
  readonly errors = {
    fileMaxSize: `Max file size upload ${this.fileSize}MB`,
    fileType: 'Current format is not acceptable',
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      userProfile: props.userProfile,
      uploads: false,
      preview: this.getPreview(true),
      droppedFile: false,
      isUpdating: false,
      rejected: false,
      isEdited: false,
    };
  }

  setInputName = () => {
    const file: any = document.querySelector('#profile-image [type="file"]');
    if (file !== null) {
      file.name = 'avatar';
    }
  };

  componentDidMount(): void {
    this.setInputName();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps !== this.props) {
      this.setState({
        userProfile: {
          ...this.props.userProfile,
          fileUploaded: false,
        },
        preview: this.getPreview(this.props.isOpened),
      });
      if (this.props.userProfile.fileUploaded === true) {
        setTimeout(() => {
          this.updateSettings();
        }, 10);
      }
      if (this.props.isOpened === false || this.props.errors.length > 0) {
        this.setState({
          isUpdating: false,
          isEdited: false,
          preview: this.getPreview(),
        });
      }
      this.setInputName();
    }
  }

  getPreview = (isInit = false) => {
    const { avatar, avatar_url } = this.props.userProfile;
    if (isInit) {
      return avatar !== '' && avatar !== null ? avatar_url : '';
    } else {
      return avatar !== '' && avatar !== null ? this.state.preview : '';
    }
  };

  render() {
    const { userProfile, rejected, isEdited } = this.state;
    return (
      <PageDialog
        maxWidth={'xs'}
        open={this.props.isOpened}
        id={'profile-image'}
        onClose={this.props.closeModal}
      >
        <DialogTitle>Profile image</DialogTitle>
        <DialogContent>
          {this.props.errors.length > 0 && (
            <ErrorBox
              errors={this.props.errors}
              clearErrors={this.props.removeErrors}
            />
          )}
          <div className={'file-uploader'} data-name="avatar">
            {this.state.preview !== '' && (
              <>
                <ImagePlaceholder
                  imageUrl={this.state.preview}
                  id="user-profile-avatar-preview"
                />
                <span className={'remove-img'} onClick={this.removeFile}>
                  <CancelOutlinedIcon />
                </span>
              </>
            )}
            <div
              style={{ display: this.state.preview === '' ? 'block' : 'none' }}
            >
              <FileUpload
                name={'avatar'}
                fileMaxSize={5}
                isMulti={false}
                displayFiles={false}
                handleFileDelete={(e) => {
                  return false;
                }}
                onUploadStart={this.handleUploadStart}
                onDropAccepted={this.handleDrop}
                onDropRejected={this.handleRejected}
                dropareaText={
                  <div>
                    Click to choose a file or drag it here
                    <br />
                    <small>Upload jpg, jpeg or png under 5MB</small>
                  </div>
                }
                acceptedFiles={[
                  'image/png',
                  'image/jpg',
                  'image/jpeg',
                  'image/gif',
                ]}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <PageDialogCancel onClick={this.props.closeModal} fullWidth={false}>
            Cancel
          </PageDialogCancel>
          <PageDialogSubmit
            onClick={this.onSubmit}
            loading={this.state.isUpdating}
            disabled={
              userProfile.avatar === null ||
              (rejected && !isEdited) ||
              !isEdited
            }
            id={'save-hours-settings-btn'}
            fullWidth={false}
          >
            Save
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  }

  updateSettings = () => {
    this.props.closeModal();
    const options = {
      avatar: this.state.userProfile.avatar,
      id: this.props.userProfile.id,
      message: 'Avatar updated',
    };
    this.props.saveProfile(options);
  };

  onSubmit = () => {
    this.setState({
      isUpdating: true,
    });
    let files: any = [];
    const current = this.state.uploads;
    if (current === true) {
      const file = document.querySelector('[type="file"]');
      if (file !== null) {
        let formData = new FormData();
        let uploadedFile: any = null;
        if ((file as HTMLFormElement).files.length === 0) {
          uploadedFile = this.state.droppedFile;
        } else {
          uploadedFile = (file as HTMLFormElement).files[0];
        }

        formData.set('name', 'avatar');
        formData.set('data', uploadedFile);
        files.push(formData);
      }
    }

    if (files.length > 0) {
      this.props.uploadFile({ id: this.props.userProfile.id, files: files });
    } else {
      this.updateSettings();
    }
  };

  removeFile = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      userProfile: {
        ...this.state.userProfile,
        avatar: '',
      },
      preview: '',
      uploads: false,
      droppedFile: false,
      rejected: false,
      isEdited: true,
    });
  };

  handleUploadStart = (files: any) => {
    if (files.length === 0) {
      return false;
    }
    const file: any = document.querySelector('[type="file"]');
    if (file !== null) {
      const f = file.files;
      if (f.length) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.setState({
            preview: e.target.result,
            uploads: true,
            rejected: false,
            isEdited: true,
          });
        };
        reader.readAsDataURL(f[0]);
        this.props.appendFile('avatar');
      }
    }
  };

  handleDrop = (accepted: any) => {
    if (accepted.length === 0) {
      return false;
    }
    const file: any = document.querySelector(`[type="file"]`);
    if (file !== null) {
      const f = accepted;
      if (f.length) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.setState({
            preview: e.target.result,
            uploads: true,
          });
        };
        reader.readAsDataURL(f[0]);
        this.setState({
          droppedFile: accepted[0],
          rejected: false,
          isEdited: true,
        });
        this.props.appendFile('avatar');
      }
    }
  };

  validate = (files: any) => {
    if (files && files.length) {
      for (let file of files) {
        if (this.acceptedFiles.indexOf(file.type) === -1) {
          alert(this.errors['fileType']);
          return false;
        } else if (file.size > this.fileSize * 1024 * 1024) {
          alert(this.errors['fileMaxSize']);
          return false;
        }
      }
    }
    return true;
  };

  handleRejected = (files: any) => {
    this.validate(files);
    this.setState({
      droppedFile: [],
      uploads: false,
      preview: '',
      rejected: true,
      isEdited: true,
    });
    return false;
  };
}

const mapStateToProps = ({
  userProfile: { userProfile, errors },
}: StoreState): StateProps => ({
  userProfile,
  errors,
});

export default connect(mapStateToProps, {
  uploadFile: BOX_USER_PROFILE_UPLOAD_REQUEST,
  removeFile: BOX_USER_PROFILE_UPLOAD_REMOVE,
  appendFile: BOX_USER_PROFILE_UPLOAD_APPEND,
  saveProfile: BOX_USER_PROFILE_UPDATE_REQUEST,
  removeErrors: BOX_USER_PROFILE_REMOVE_ERRORS,
})(ProfileImageModal);
