import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { FormItem, Paragraph, TextArea } from 'elmo-elements';
import './styles.scss';

type Props = {
  value: null | string;
  setNotesValue: (value: string) => void;
  onClick: () => void;
  isEditing: boolean;
};

export const TimesheetNotesField = ({ value, setNotesValue, onClick, isEditing }: Props) => {

  const maxTextLength = 500;
  const diff = !value ? maxTextLength : (maxTextLength - value.length);
  const [remainingNumber, setRemainingNumber] = useState(diff);

  const setNotesHeightOnLoad = () => {
    const element = document.querySelector('[name="notes"]');
    if (element) {
      applyDynamicHeight(element as HTMLTextAreaElement);
    }
  };

  const onChangeValue = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { currentTarget } = e;
    setNotesValue(currentTarget.value.substring(0, maxTextLength));
  };

  const applyDynamicHeight = (element: HTMLTextAreaElement) => {
    element.style.height = '1px';
    element.style.height = element.scrollHeight + 'px';
  };

  const setTextLength = (e: SyntheticEvent<HTMLTextAreaElement>) => {
    const { value } = e.currentTarget;
    const diff = maxTextLength - value.length;
    setRemainingNumber(diff);
    applyDynamicHeight(e.currentTarget);
    if ( value.length >= maxTextLength ) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (isEditing) {
      setNotesHeightOnLoad();
      setRemainingNumber(!value ? maxTextLength : (maxTextLength - value.length));
    }
  }, [isEditing]);

  const validateField = (e: any) => {
    const { currentTarget } = e;
    setNotesValue(currentTarget.value.trim().substring(0, maxTextLength));
  };

  const showPlaceholder = () => {
    return (
      <FormItem label={'Notes'}>
        <div className={'timesheet-notes-placeholder'}>
          <Paragraph size={'sm'} color={'gray'}>
            {!value ? 'Click to add notes ...' : value}
          </Paragraph>
        </div>
      </FormItem>
    );
  };

  const showNotesField = () => {
    return (
      <FormItem label={`Notes (${remainingNumber})`}>
        <TextArea
          name={'notes'}
          className={'timesheet-notes-field'}
          onChange={onChangeValue}
          onKeyUp={setTextLength}
          value={!value ? '' : value}
          onBlur={validateField}
        />
      </FormItem>
    );
  };

  return (
    <div className={`editable-wrapper ${isEditing ? 'is-opened' : ''}`} onClick={onClick}>
      {
        !isEditing
          ? showPlaceholder()
          : showNotesField()
      }
    </div>
  );
};