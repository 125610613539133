import React from 'react';
import {
  EmployeeDashboardSearchPayload,
  WithEmployeeDashboardMenu,
  WithEmployeeDashboardSearch,
} from '../../../../components';
import { AddOutlinedIcon, Header } from 'element';
import { connect } from 'react-redux';
import { StoreState } from '../../../../../../state/types';
import { getCanManuallyAddTimesheets } from '../../../../../../state/Account';
import moment from 'moment';
import {
  currentUserMainSiteIdSelector,
  getCurrentUserId,
  hasPermissionSelector,
} from '../../../../../../state/Auth';
import { BOX_MY_TIMESHEET_CREATE_SELF_ASSIGNED_SHIFT_REQUEST } from 'state/EmployeeDashboard/MyTimesheet/actions';
import { CreateSelfAssignedShiftPayload } from 'state/EmployeeDashboard/MyTimesheet/types';
import { WithNotificationsListButton } from 'element/NotificationsList/components/WithNotificationsListButton';

type OwnProps = {};

type StateProps = {
  canManuallyAddTimesheets: boolean;
  currentUserId: string;
  currentUserMainSiteId: string | undefined;
  isSuperAdmin: boolean
};

type DispatchProps = {
  openCreateModal: (payload: CreateSelfAssignedShiftPayload) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

export const MyTimesheetHeaderComponent = ({
  canManuallyAddTimesheets,
  openCreateModal,
  currentUserId,
  currentUserMainSiteId,
  isSuperAdmin
}: Props) => (
  <WithEmployeeDashboardMenu>
    {menu => (
      <WithEmployeeDashboardSearch>
        {({
          isSearchOpen
        }: EmployeeDashboardSearchPayload) => (
          <WithNotificationsListButton type={'employee'}>
            {({ notificationButton }) => (
              <Header
                title="Employee Dashboard"
                tabs={menu}
                buttons={[notificationButton]}
                isSearchOpen={isSearchOpen}
                primaryButton={
                  canManuallyAddTimesheets
                    ? {
                      id: 'createTimesheetBtn',
                      icon: <AddOutlinedIcon />,
                      ariaLabel: 'Add new timesheet',
                      label: 'Add new timesheet',
                      isDisabled: !currentUserMainSiteId || isSuperAdmin,
                      onClick: () =>
                        openCreateModal({
                          id: '',
                          start: moment().startOf('day'),
                          end: moment().startOf('day'),
                          user_id: currentUserId,
                          isCurrentUser: true
                        })
                    }
                    : undefined
                }
              >
                {/*{employeeSearch}*/}
              </Header>
            )}
          </WithNotificationsListButton>
        )}
      </WithEmployeeDashboardSearch>
    )}
  </WithEmployeeDashboardMenu>
);

const mapStateToProps = (state: StoreState): StateProps => ({
  canManuallyAddTimesheets: getCanManuallyAddTimesheets(state),
  currentUserId: getCurrentUserId(state),
  currentUserMainSiteId: currentUserMainSiteIdSelector(state),
  isSuperAdmin: hasPermissionSelector(state, 'passive'),
});

const mapDispatchToProps: DispatchProps = {
  openCreateModal: BOX_MY_TIMESHEET_CREATE_SELF_ASSIGNED_SHIFT_REQUEST
};

export const MyTimesheetHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyTimesheetHeaderComponent);
