import React, { Component } from 'react';
import { Route, StringMap } from 'type';
import { LoadingOverlay } from 'elmo-elements';
import { NestedRoutes } from 'element/routes';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import './view.scss';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getIsBulkDeleteOpened } from 'state/Roster/BulkActions/selectors';
import RosterCopyAlert from './components/RosterCopyAlert';
import PrintRosters from '../../components/PrintRosters';
import { Alert } from 'extended-oxygen-elements';

import { InputWithLegendModal } from '../../components/RosterShiftTimeRangeInput/components/InputWithLegend/components/InlineInputLegendModal';
import { getIsCovidModeEnabled } from 'state/Roster/CovidTracing/selectors';
import { ApprovalModeAlert } from 'page/Roster/components';
import { CovidTracingPanel, RosterWeekViewControls } from './components';

type OwnProps = {
  routes: StringMap<Route>;
};

type StateProps = {
  isBulkDeleteMode: boolean;
  isCovidMode: boolean;
};

type State = {
  bulkAlertClosed: boolean;
};

type Props = OwnProps & StateProps & RouteComponentProps;

class RosterWeekViewView extends Component<Props, State> {
  state = {
    bulkAlertClosed: false,
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (
      prevProps.isBulkDeleteMode !== this.props.isBulkDeleteMode &&
      this.props.isBulkDeleteMode
    ) {
      this.setState({
        bulkAlertClosed: false,
      });
    }
  }

  closeAlert = () => {
    this.setState({
      bulkAlertClosed: true,
    });
  };

  render() {
    const { isBulkDeleteMode, routes, isCovidMode } = this.props;
    const { bulkAlertClosed } = this.state;

    return (
      <div className={isCovidMode ? 'covid-tracing-mode-active' : ''}>
        <ApprovalModeAlert />

        {isBulkDeleteMode && !bulkAlertClosed && (
          <Alert
            color="info"
            severity="info"
            className={'mb-4'}
            onClose={this.closeAlert}
          >
            Timesheets can't be deleted in bulk edit mode
          </Alert>
        )}

        <RosterCopyAlert />
        <InputWithLegendModal />

        {!isCovidMode && <RosterWeekViewControls />}

        {isCovidMode && <CovidTracingPanel />}

        <PrintRosters />
        <NestedRoutes routes={routes} />
      </div>
    );
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  isBulkDeleteMode: getIsBulkDeleteOpened(state),
  isCovidMode: getIsCovidModeEnabled(state),
});

export default withRouter(connect(mapStateToProps)(RosterWeekViewView));
