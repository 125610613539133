import { createAction } from 'lib/store-utils';
import { DeleteInstancePayload, ShiftSwap, StringMap } from '../../../type';
import { ApproveDeclinePayload, UndoQueueAddRemovePayload } from './type';

export const BOX_SHIFT_SWAPS_DATA_REQUEST = createAction('Shift Swaps / Get shift swaps data request');

export const BOX_SHIFT_SWAPS_GET_SHIFT_SWAPS_SUCCESS = createAction<StringMap<ShiftSwap>>('Shift swaps / Get shift swaps success');

export const BOX_SHIFT_SWAPS_SWAP_UPDATED = createAction<ShiftSwap>('Shift swaps / swap updated');
export const BOX_SHIFT_SWAPS_UPDATE_SWAP = createAction<ShiftSwap>('Shift swaps / swap updated');
export const BOX_SHIFT_SWAPS_SWAP_DELETED = createAction<DeleteInstancePayload>('Shift swaps / swap deleted');
export const BOX_SHIFT_SWAPS_DELETE_SWAP = createAction<DeleteInstancePayload>('Shift swaps / swap deleted');

export const BOX_SHIFT_SWAPS_APPROVE_REQUEST = createAction<ApproveDeclinePayload>('Shift swaps / approve request');
export const BOX_SHIFT_SWAPS_APPROVE_SUCCESS = createAction<ApproveDeclinePayload>('Shift swaps / approve success');
export const BOX_SHIFT_SWAPS_APPROVE_FINISHED = createAction<ApproveDeclinePayload>('Shift swaps / approve finished');

export const BOX_SHIFT_SWAPS_DECLINE_REQUEST = createAction<ApproveDeclinePayload>('Shift swaps / decline request');
export const BOX_SHIFT_SWAPS_DECLINE_SUCCESS = createAction<ApproveDeclinePayload>('Shift swaps / decline success');
export const BOX_SHIFT_SWAPS_DECLINE_FINISHED = createAction<ApproveDeclinePayload>('Shift swaps / decline finished');
