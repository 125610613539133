import { createSelector } from 'reselect';
import { Moment } from 'moment';
import {
  fromBySiteTimezoneSelector,
  toBySiteTimezoneSelector
} from 'state/RosteredShifts';
import { currentTimeBySiteTimezoneSelector } from '../../Roster/selectors';

export const isCurrentDaySelector = createSelector(
  currentTimeBySiteTimezoneSelector,
  fromBySiteTimezoneSelector,
  toBySiteTimezoneSelector,
  (currentTime: Moment, from: Moment, to: Moment): boolean =>
    currentTime.isBetween(from, to, undefined, '[]')
);
