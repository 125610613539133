import React, { ReactNode } from 'react';
import { Badge, BadgeType } from '../../Badge';

export type FilterBadgeProps = {
  children: ReactNode;
  colour?: BadgeType;
};

function FilterBadge({ children, colour = 'grey' }: FilterBadgeProps) {
  return <Badge type={colour}>{children}</Badge>;
}

export default FilterBadge;
