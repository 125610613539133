import { SafeDictionary } from 'ts-essentials';
import moment, { Moment } from 'moment';
import { ISO8601DateTime, DateType } from 'type';
import { SERVER_DAY_FORMAT } from 'lib/config';

export const formatDay = (dayMoment: Moment | ISO8601DateTime) =>
  moment.parseZone(dayMoment).format(SERVER_DAY_FORMAT) as DateType;

export const isArchivedWithData = (
  user: { is_active: boolean; id: string },
  dataByUserId: SafeDictionary<any[]>
): boolean => {
  const isArchived = !user.is_active;
  const userData = dataByUserId[user.id] || [];
  const hasData = !!userData.length;

  return isArchived && hasData;
};
