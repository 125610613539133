import React from 'react';
import { useArrayHandler } from 'hooks';
import { SafeDictionary } from 'ts-essentials';
import DefaultBreakRule from './DefaultBreakRule';
import { DefaultBreaksFieldGroupBreak } from './types';

type Props = {
  value: DefaultBreaksFieldGroupBreak[];
  onChange: (rules: DefaultBreaksFieldGroupBreak[]) => void;
  disabled: boolean;
  errors: SafeDictionary<string> | undefined;
};

export default DefaultBreakRules;

export function DefaultBreakRules({
  value,
  onChange,
  disabled,
  errors,
}: Props) {
  const rulesHandler = useArrayHandler(value, onChange);
  return (
    <>
      {value.map((defaultBreakRule, defaultBreakRuleIndex) => (
        <DefaultBreakRule
          key={defaultBreakRule.id}
          value={defaultBreakRule}
          onChange={rulesHandler.updateItem}
          error={errors?.[defaultBreakRule.id]}
          disabled={disabled}
          labelVisible={defaultBreakRuleIndex === 0}
        />
      ))}
    </>
  );
}
