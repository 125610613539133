import React from 'react';
import { useSelector } from 'react-redux';
import { Dictionary } from 'ts-essentials';
import { Tooltip } from 'extended-oxygen-elements';
import { DefaultDivProps, RosterEventStatus } from 'type';
import { getDateFormat, timeFormatSelector } from 'state/Account';
import { CalendarTodayIcon, FormattedCurrency } from 'element';
import { green200, orange, red } from '../constants';
import { CardProps } from './types';
import {
  ShiftCardEvent,
  ShiftCardEventBtnDelete,
  ShiftCardEventPopperAdd,
  ShiftCardEventRow,
  ShiftCardEventRowTitle,
} from './components';
import useEventCard from './useEventCard';

const statusIcons: Dictionary<
  React.ComponentType<DefaultDivProps>,
  RosterEventStatus | 'none'
> = {
  all: (props) => (
    <div {...props} style={{ ...props?.style, color: green200 }}>
      <Tooltip title="All shifts confirmed">
        <CalendarTodayIcon fontSize="inherit" />
      </Tooltip>
    </div>
  ),
  not_all: (props) => (
    <div {...props} style={{ ...props?.style, color: orange }}>
      <Tooltip title="Not all shifts confirmed">
        <CalendarTodayIcon fontSize="inherit" />
      </Tooltip>
    </div>
  ),
  all_not_confirmed: (props) => (
    <div {...props} style={{ ...props?.style, color: red }}>
      <Tooltip title="All shifts are not confirmed">
        <CalendarTodayIcon fontSize="inherit" />
      </Tooltip>
    </div>
  ),
  none: () => null,
};

const EventTypeCard = ({ event, hasAddButton }: CardProps) => {
  const dateFormat = useSelector(getDateFormat);
  const timeFormat = useSelector(timeFormatSelector);

  const {
    isEditBlocked,
    isEventEditable,
    handleClick,
    handleDoubleClick,
    handleDeleteBtnClick,
    handleAddBtnClick,
  } = useEventCard(event);

  const StatusIcon = statusIcons[event.status];

  return (
    <ShiftCardEvent
      data-testid="roster-event-card roster-event-card--event"
      data-test-eventid={event.id}
      notClickable={!isEventEditable}
      transparent={isEditBlocked}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
    >
      <ShiftCardEventRowTitle
        icon={<StatusIcon />}
        title={event.name}
        secondaryAction={
          isEventEditable && (
            <ShiftCardEventBtnDelete onClick={handleDeleteBtnClick} />
          )
        }
      />

      <ShiftCardEventRow>
        {event.start.format(dateFormat)}, {event.start.format(timeFormat)} -{' '}
        {event.end.format(dateFormat)}, {event.end.format(timeFormat)}
      </ShiftCardEventRow>

      <ShiftCardEventRow weight="bold">
        <FormattedCurrency>{event.value}</FormattedCurrency>
      </ShiftCardEventRow>

      {isEventEditable && hasAddButton && (
        <ShiftCardEventPopperAdd onClick={handleAddBtnClick} />
      )}
    </ShiftCardEvent>
  );
};

export default EventTypeCard;
