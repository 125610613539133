import React, { Component } from 'react';
import Modal from '../../Modal';
import Button from '../../Button';
import { withDataTableContext } from '../DataTableContextConsumer';
import { DataTableColumn } from '../type';
import SelectList from '../../SelectList';

export type Props = {
  /** @ignore Comes from DataTable Context. */
  setContextState: (state: any) => void;

  /** @ignore Comes from DataTable Context. */
  isCustomiseModalOpen: boolean;

  /** @ignore Comes from DataTable Context. */
  headerColumns: DataTableColumn[];
};

type State = {
  headerColumns: DataTableColumn[];
};

const getState = ({ headerColumns }: Props): State => ({
  headerColumns: headerColumns.map((column) => ({ ...column })),
});

export class DataTableCustomiseModal extends Component<Props, State> {
  readonly state = getState(this.props);

  onClose = () => {
    const { setContextState } = this.props;

    setContextState({
      isCustomiseModalOpen: false,
    });
  };

  onSubmit = () => {
    const { setContextState } = this.props;

    setContextState({
      isCustomiseModalOpen: false,
      headerColumns: this.state.headerColumns,
    });
  };

  onSelectAllToggle = (isSelect: boolean) => {
    const { headerColumns } = this.props;

    const newHeaderColumns = headerColumns.map(
      ({ isHidden, ...restColumn }: DataTableColumn): DataTableColumn => ({
        ...restColumn,
        isHidden: restColumn.isDisabled ? isHidden : !isSelect,
      })
    );

    this.setState({
      headerColumns: newHeaderColumns,
    });
  };

  onItemToggle =
    ({ isDisabled }: DataTableColumn, index: number) =>
    () => {
      if (isDisabled) {
        return;
      }

      const { headerColumns } = this.state;

      const newHeaderColumns = headerColumns.map((column, columnIndex) =>
        index === columnIndex
          ? {
              ...column,
              isHidden: !column.isHidden,
            }
          : column
      );

      this.setState({
        headerColumns: newHeaderColumns,
      });
    };

  renderContent() {
    const { headerColumns } = this.state;

    return (
      <SelectList onSelectAllToggle={this.onSelectAllToggle}>
        {headerColumns.map((column: DataTableColumn, index: number) => {
          return (
            <SelectList.Option
              key={index}
              isSelected={!headerColumns[index].isHidden}
              onToggle={this.onItemToggle(column, index)}
              isDisabled={column.isDisabled}
            >
              {column.title}
            </SelectList.Option>
          );
        })}
      </SelectList>
    );
  }

  render() {
    const { isCustomiseModalOpen } = this.props;
    return (
      <Modal
        id="OptionsDialog"
        type="medium"
        isOpened={isCustomiseModalOpen}
        closeModal={this.onClose}
        title="Customise Columns"
        primaryButton={
          <Button type="primary" isBlock={true} onClick={this.onSubmit}>
            Ok
          </Button>
        }
        closeLabel="Close"
      >
        {this.renderContent()}
      </Modal>
    );
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    const { isCustomiseModalOpen } = this.props;

    if (
      isCustomiseModalOpen &&
      isCustomiseModalOpen !== prevProps.isCustomiseModalOpen
    ) {
      this.setState(getState(this.props));
    }
  }
}

export default withDataTableContext(DataTableCustomiseModal);
