import { DurationField, FormattedDuration } from 'element';
import _ from 'lodash';
import { Dictionary } from 'ts-essentials';
import { DefaultBreakItemCondition, Minutes } from 'type';
import BreakRow from '../BreakRow';
import { DefaultBreaksFieldGroupBreak } from './types';
import { Typography } from '../../../../extended-oxygen-elements';

type Props = {
  value: DefaultBreaksFieldGroupBreak;
  onChange: (rule: DefaultBreaksFieldGroupBreak) => void;
  disabled: boolean;
  labelVisible: boolean;
  error: string | undefined;
};

const conditionLabels: Dictionary<string, DefaultBreakItemCondition> = {
  '<=': 'up to',
  '>': 'over',
};

export default function DefaultBreakRule({
  value,
  onChange,
  disabled,
  labelVisible,
  error,
}: Props) {
  const handleValueChange = (break_duration: Minutes | null) => {
    if (break_duration !== null) {
      onChange({
        ...value,
        break_duration,
      });
    }
  };

  const breakRowId = _.kebabCase(
    `default break rule ${conditionLabels[value.condition]} ${
      value.shift_duration
    }`
  );

  return (
    <BreakRow>
      <BreakRow.Label id={breakRowId}>
        <span style={disabled ? { color: 'rgba(0, 0, 0, 0.38)' } : {}}>
          Shifts {conditionLabels[value.condition]}{' '}
          <FormattedDuration hoursPrefix="hrs" minutesPrefix="min">
            {value.shift_duration}
          </FormattedDuration>{' '}
          long
        </span>
      </BreakRow.Label>

      <BreakRow.Field label={labelVisible ? 'Break duration' : undefined}>
        <DurationField
          margin="none"
          disabled={disabled}
          value={value.break_duration}
          onValueChange={handleValueChange}
          error={!!error}
          helperText={error}
          inputProps={{
            'aria-labelledby': breakRowId,
          }}
        />
      </BreakRow.Field>
    </BreakRow>
  );
}
