import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import browserHistory from 'lib/browserHistory';
import { privateRoutes } from 'routes';
import {
  ArchiveOutlinedIcon,
  ArrowDropDownOutlinedIcon,
  DialpadIcon,
  Header,
  UserLogIcon,
} from 'element';
import { connect } from 'react-redux';
import {
  BOX_USER_PROFILE_OPEN_AWARDS_MODAL,
  BOX_USER_PROFILE_PIN_OPEN_MODAL,
  BOX_USER_PROFILE_UPDATE_REQUEST,
} from 'state/Users/UserProfile/actions';
import { StoreState } from 'state/types';
import { isAppMarket, isHrService } from '../../../../../../helpers';
import {
  getIsUpdating,
  getUserProfile,
} from '../../../../../../state/Users/UserProfile';
import { UserProfileFields } from '../../../../../../type/models';
import { HeaderButtonProp } from 'element/Header';
import { hasPermissionSelector } from '../../../../../../state/Auth';
import {
  getStoredPage,
  getStoredPageSize,
} from '../../../../../../state/Users/Users/selectors/userListPageSelectors/pager';
import { AwardsModalPayload } from '../../../../../../state/Users/UserProfile/types';

type TParams = {
  userId: string;
};

type DispatchProps = {
  openPinModal: () => void;
  updateUserStatus: (payload: Partial<UserProfileFields>) => void;
  openAwardModal: (params: AwardsModalPayload | null) => void;
};

type StateProps = {
  isUserFetching: boolean;
  isUpdating: boolean;
  user: UserProfileFields;
  hasPermission: boolean;
  storedPageNum: number;
  storedPageSize: number;
};

type Props = {
  title: string;
} & RouteComponentProps<TParams> &
  StateProps &
  DispatchProps;

class UserHeaderComponent extends React.Component<Props, {}> {
  get allButtons() {
    const { user, isUpdating } = this.props;
    const { routes } = privateRoutes.users.routes.user;
    const currentRoute = routes.entitlements.path(user.id);

    const backButton = {
      title: 'Return to users',
      onClick: () => {
        const { storedPageNum, storedPageSize } = this.props;
        browserHistory.push(
          privateRoutes.users.path +
            `?pageNum=${storedPageNum}&pageSize=${storedPageSize}`
        );
      },
    };

    const buttons: Array<HeaderButtonProp> = [
      {
        icon: <DialpadIcon />,
        label: 'Change pin',
        isDisabled: !user.is_active,
        tooltip: 'Update the user PIN',
        onClick: () => {
          this.props.openPinModal();
        },
      },
    ];

    if (isHrService('standalone')) {
      const toggleUserStatus = () => {
        this.props.updateUserStatus({
          id: user.id,
          is_active: !user.is_active,
        });
      };
      buttons.unshift({
        icon: user.is_active ? <ArchiveOutlinedIcon /> : <UserLogIcon />,
        label: user.is_active ? `Ex-employee` : `Activate`,
        isDisabled: isUpdating,
        onClick: toggleUserStatus,
      });
    }

    if (isAppMarket('au') && this.getActiveTab(currentRoute)) {
      buttons.push({
        icon: <ArrowDropDownOutlinedIcon />,
        label: 'Add an award',
        onClick: () => this.props.openAwardModal(null),
      });
    }

    return {
      backButton,
      buttons,
    };
  }

  render() {
    const { title } = this.props;
    const { backButton, buttons } = this.allButtons;
    return (
      <Header
        tabs={this.headerTabLinks()}
        title={title}
        backButton={backButton}
        buttons={buttons}
      />
    );
  }

  private headerTabLinks = () => {
    const { userId } = this.props.match.params;
    const { routes } = privateRoutes.users.routes.user;
    const profileUrl = routes.profile.path(userId);
    const entitlementsUrl = routes.entitlements.path(userId);

    return [
      {
        label: 'Profile',
        onClick: () => browserHistory.push(profileUrl),
        active: this.getActiveTab(profileUrl),
      },
    ];
  };

  private getActiveTab = (currentLocation: string) => {
    return this.props.match.url === currentLocation;
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  isUserFetching: state.userProfile.isFetching,
  user: getUserProfile(state),
  isUpdating: getIsUpdating(state),
  hasPermission: hasPermissionSelector(state, 'awards.view'),
  storedPageNum: getStoredPage(state),
  storedPageSize: getStoredPageSize(state),
});

export const UserHeader = withRouter(
  connect(mapStateToProps, {
    openPinModal: BOX_USER_PROFILE_PIN_OPEN_MODAL,
    updateUserStatus: BOX_USER_PROFILE_UPDATE_REQUEST,
    openAwardModal: BOX_USER_PROFILE_OPEN_AWARDS_MODAL,
  })(UserHeaderComponent)
);
