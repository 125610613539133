import React from 'react';
import { Switch } from '../../_lib/component';
import ToggleContext from './ToggleContext';

function ToggleButton(props: any) {
  return (
    <ToggleContext.Consumer>
      {({ id, isOn, toggle }: any) => (
        <Switch isOn={isOn} id={id} onClick={toggle} {...props} />
      )}
    </ToggleContext.Consumer>
  );
}

export default ToggleButton;
