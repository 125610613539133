import { TimesheetsNewStatus, TimesheetsNewStatusTuple } from 'type';

const timesheetStatuses: TimesheetsNewStatusTuple = [
  'all',
  'approved',
  'pending',
  'in_progress',
  'violated',
];

export const statusValidator = (
  status: TimesheetsNewStatus | string | undefined
) => timesheetStatuses.includes(status as TimesheetsNewStatus);
