import React from 'react';
import clsx from 'clsx';
import { Theme } from 'extended-oxygen-elements';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { Container } from '../Container';

type OwnProps = {
  children: React.ReactNode;
  visible: boolean;
  style?: {};
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      transform: 'translateY(-100%)',
      opacity: 0,
      transition: theme.transitions.create(['transform', 'opacity']),
      display: 'flex',
      alignItems: 'center',
    },
    visible: {
      transform: 'none',
      opacity: 1,
    },
  });

type StyleProps = WithStyles<typeof styles>;

type Props = OwnProps & StyleProps;

export const NewHeaderAction = withStyles(styles)(function NewHeaderAction({
  children,
  classes,
  visible,
  style,
}: Props) {
  return (
    <Container
      className={clsx([classes.root, visible && classes.visible])}
      style={style}
    >
      {children}
    </Container>
  );
});
