import React, { Component } from 'react';
import { filter } from 'lodash';
import { Select as ElmoSelect } from 'elmo-elements';
import { SelectPropsOption } from 'elmo-elements/Select';
import { MultiSelectProps } from '../types';
import { isGroupedOptions, ungroupOptions } from './helpers';

export class MultiSelect extends Component<MultiSelectProps> {
  render() {
    return (
      <ElmoSelect
        id={this.props.id}
        isClearable={this.props.isClearable}
        isMulti={this.props.isMulti}
        value={this.getValue}
        options={this.props.options}
        onChange={this.onChange}
        className={this.props.className}
        placeholder={this.props.placeholder}
        isDisabled={this.props.isDisabled}
        ariaLabel={this.props.ariaLabel}
      />
    );
  }

  private get getValue(): SelectPropsOption[] {
    const { options } = this.props;

    if (isGroupedOptions(options)) {
      const ungroupedOptions = ungroupOptions(options);

      return filter(ungroupedOptions, this.filterSelectedPredicate);
    } else {
      return filter(options, this.filterSelectedPredicate);
    }
  }

  private filterSelectedPredicate = (option: SelectPropsOption) =>
    this.props.value.includes(option.value)

  private onChange = (selectedOptions: SelectPropsOption[]) => {
    const { name } = this.props;

    this.props.onChange({
      name,
      value: selectedOptions.map(selectedOption => selectedOption.value)
    });
  }
}
