import React from 'react';
import { FilterStatus } from '../type';
import './FilterDropdown.scss';
import FilterBadge from './FilterBadge';
import { Dropdown } from '../../Dropdown';

export type FilterDropdownProps = {
  /** Items to show in the dropdown */
  statuses: FilterStatus[];
  /** Callback when an item from the dropdown is clicked */
  onChange: (status: any) => void;
};

function FilterDropdown({ statuses, onChange }: FilterDropdownProps) {
  return (
    <Dropdown
      className="elmo-filter__dropdown"
      position="bottom-end"
      ariaLabel="Dropdown"
    >
      {statuses.map((status: FilterStatus, index: number) => {
        return (
          <Dropdown.Item onClick={onChange} value={status.value} key={index}>
            <span className="label">{status.label}</span>
            {status.count !== undefined && (
              <FilterBadge colour={status.colour}>{status.count}</FilterBadge>
            )}
          </Dropdown.Item>
        );
      })}
    </Dropdown>
  );
}

export default FilterDropdown;
