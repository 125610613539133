import { StoreState } from '../types';
import { NotificationItemMeta, NotificationsListItem } from './types';
import { FormattedErrors, StringMap } from 'type';

export const getManagerNotificationsUnreadCount = (state: StoreState): number =>
  state.notificationsList.manager.meta.unread_count;

export const getEmployeeNotificationsUnreadCount = (state: StoreState): number =>
  state.notificationsList.employee.meta.unread_count;

export const getManagerNotificationsListItems = (state: StoreState): StringMap<NotificationsListItem> =>
  state.notificationsList.manager.data;

export const getEmployeeNotificationsListItems = (state: StoreState): StringMap<NotificationsListItem> =>
  state.notificationsList.employee.data;

export const getNotificationsListErrors = (state: StoreState): FormattedErrors =>
  state.notificationsList.errors;

export const getEmployeeNotificationsMeta = (state: StoreState): NotificationItemMeta =>
  state.notificationsList.employee.meta;

export const getManagerNotificationsMeta = (state: StoreState): NotificationItemMeta =>
  state.notificationsList.manager.meta;

export const getNotificationsIsFetching = (state: StoreState): boolean => state.notificationsList.isFetching;