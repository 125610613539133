import React, { ReactNode } from 'react';
import { StoreState } from '../../../../../../state/types';
import { connect } from 'react-redux';
import { hasPermissionSelector } from '../../../../../../state/Auth';
import { FormattedErrors } from '../../../../../../type/models';
import {
  getSummaryErrors,
  getSummaryHasErrors,
  getSummaryLoading,
} from '../../../../../../state/Roster/Summary/selectors';
import { BOX_SUMMARY_REMOVE_ERRORS } from '../../../../../../state/Roster/Summary/actions';
import { LoadingOverlay } from 'elmo-elements';
import ErrorBox from '../../../../../../element/ErrorBox';

type OwnProps = {
  children: ReactNode;
};

type StateProps = {
  canViewRosteredShift: boolean;
  canViewTimesheet: boolean;
  isLoading: boolean;
  hasErrors: boolean;
  errors: FormattedErrors;
};

type DispatchProps = {
  clearErrors: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const RosterSummaryComponent = ({
  canViewRosteredShift,
  canViewTimesheet,
  isLoading,
  errors,
  hasErrors,
  clearErrors,
  children,
}: Props) => {
  if (!(canViewRosteredShift || canViewTimesheet)) {
    return null;
  }

  if (hasErrors && !errors.length) {
    return null;
  }

  return <>{children}</>;

  // <LoadingOverlay isLoading={isLoading}>
  //   {hasErrors ? (
  //     <ErrorBox errors={errors} clearErrors={clearErrors} />
  //   ) : (
  //     children
  //   )}
  // </LoadingOverlay>
};

const mapStateToProps = (state: StoreState): StateProps => ({
  canViewRosteredShift: hasPermissionSelector(
    state,
    'roster.rosteredshift.view'
  ),
  canViewTimesheet: hasPermissionSelector(state, 'roster.timesheet.view'),
  isLoading: getSummaryLoading(state),
  hasErrors: getSummaryHasErrors(state),
  errors: getSummaryErrors(state),
});

const mapDispatchToProps: DispatchProps = {
  clearErrors: BOX_SUMMARY_REMOVE_ERRORS,
};

export const RosterSummary = connect(
  mapStateToProps,
  mapDispatchToProps
)(RosterSummaryComponent);
