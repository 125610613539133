import React, { SyntheticEvent } from 'react';
import { Checkbox, FormItem, Input } from 'elmo-elements';
import { LanguagePreferences, UserProfileFields } from 'type/models';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import {
  BOX_USER_PROFILE_REMOVE_ERRORS,
  BOX_USER_PROFILE_UPDATE_REQUEST,
} from 'state/Users/UserProfile/actions';
import {
  ErrorBox,
  PageDialog,
  PageDialogCancel,
  PageDialogSubmit,
} from 'element';
import { getPreferenceLabel } from 'lib/helpers';
import { getLangPreferences } from 'state/Account';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
} from 'extended-oxygen-elements';

type OwnProps = {
  isOpened: boolean;
  closeModal: () => void;
  userGroupsDropDown: any[];
};

type State = {
  prefered_name: string;
  payroll_number: string;
  abbreviation: string | null;
  user_group_id: number;
  isUpdating: boolean;
  tags: any[];
  rating: number;
  isEdited: boolean;
  custom_user_group: boolean;
};

type StateProps = {
  userProfile: UserProfileFields;
  errors: string[];
  langPreferences: LanguagePreferences;
};

type DispatchProps = {
  updateProfile: (params: any) => void;
  removeErrors: () => void;
};

type Props = OwnProps & DispatchProps & StateProps;

export class ProfileDetailsModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.getProfileParts(),
      isUpdating: false,
      isEdited: false,
    };
  }

  getProfileParts = () => {
    return {
      prefered_name: this.props.userProfile.prefered_name,
      payroll_number: this.props.userProfile.payroll_number,
      user_group_id: this.props.userProfile.user_group_id,
      tags: this.props.userProfile.tags,
      abbreviation: this.props.userProfile.abbreviation,
      rating: this.props.userProfile.rating,
      custom_user_group: this.props.userProfile.settings.custom_user_group,
    };
  };

  componentDidUpdate(
    prevProps: Readonly<Props & DispatchProps>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps !== this.props) {
      if (this.props.isOpened === false || this.props.errors.length > 0) {
        this.setState({
          isUpdating: false,
          isEdited: false,
        });
        if (this.props.isOpened === false) {
          this.setState({
            ...this.getProfileParts(),
          });
        }
      }
    }
  }

  render() {
    const { langPreferences } = this.props;
    return (
      <PageDialog
        maxWidth={'xs'}
        open={this.props.isOpened}
        id={'profile-details-modal'}
        onClose={this.props.closeModal}
      >
        <DialogTitle>Profile details</DialogTitle>
        <DialogContent>
          {this.props.errors.length > 0 && (
            <ErrorBox
              errors={this.props.errors}
              clearErrors={this.props.removeErrors}
            />
          )}
          <FormItem label={'Known As'}>
            <Input
              name={'prefered_name'}
              onChange={this.inputOnChange}
              value={this.state.prefered_name}
              ariaLabel="Known As"
              isDisabled={true}
            />
          </FormItem>
          <FormItem
            label={`${getPreferenceLabel(
              langPreferences,
              'employee',
              'singular',
              '',
              true
            )} number`}
          >
            <Input
              name={'employment_type'}
              isReadOnly={true}
              onChange={this.inputOnChange}
              value={this.state.payroll_number}
              ariaLabel={`${getPreferenceLabel(
                langPreferences,
                'employee',
                'singular',
                '',
                true
              )} number`}
            />
          </FormItem>

          <FormItem>
            <Checkbox
              id={'custom-user-group'}
              label={'Do not sync permissions with Breathe HR'}
              name={'custom_user_group'}
              isChecked={this.state.custom_user_group}
              onChange={this.handleChangeCheckbox}
            />
          </FormItem>

          <FormItem label={'Permissions'}>
            <Select
              fullWidth
              disabled={!this.state.custom_user_group}
              name={'user_group_id'}
              onChange={(e) => this.handleOnChangeSelect(e, 'user_group_id')}
              defaultValue={''}
              value={this.getSelectedValue().value}
              aria-label={'Permissions'}
            >
              {this.props.userGroupsDropDown.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>)
              )}
            </Select>
          </FormItem>
        </DialogContent>
        <DialogActions>
          <PageDialogCancel onClick={this.props.closeModal} fullWidth={false}>
            Cancel
          </PageDialogCancel>
          <PageDialogSubmit
            onClick={this.submitForm}
            loading={this.state.isUpdating}
            disabled={!this.state.isEdited}
            fullWidth={false}
          >
            Save
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  }

  inputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    this.setState({
      ...this.state,
      isEdited: true,
      [name]: value,
    });
  };

  handleChangeCheckbox = ({
    currentTarget: { name, checked },
  }: SyntheticEvent<HTMLInputElement>) => {
    this.setState((prevState) => ({
      ...prevState,
      custom_user_group: checked,
      isEdited: true,
    }));
  };

  handleOnChangeSelect = (e: any, name: string) => {
    this.setState({
      ...this.state,
      isEdited: true,
      [name]: e.target.value,
    });
  };

  handleOnChangeSelect2 = (e: any[], name: string) => {
    this.setState({
      ...this.state,
      isEdited: true,
      [name]: this.getIdsFromDropDown(e),
    });
  };

  getSelectedValue = () => {
    const { user_group_id } = this.state;
    const { userGroupsDropDown } = this.props;
    for (let option of userGroupsDropDown) {
      if (option.value === user_group_id.toString()) {
        return option;
      }
    }
    return {
      label: '',
      value: '',
    };
  };

  getIdsFromDropDown = (options: any[]) => {
    const ids: string[] = [];
    options.forEach((item: any) => {
      ids.push(item.value);
    });
    return ids;
  };

  submitForm = () => {
    this.setState({
      isUpdating: true,
    });
    this.props.updateProfile({
      id: this.props.userProfile.id,
      prefered_name: this.state.prefered_name,
      payroll_number: this.state.payroll_number,
      user_group_id: +this.state.user_group_id,
      settings: {
        custom_user_group: this.state.custom_user_group,
      },
      tags: this.state.tags,
      rating: this.state.rating,
      message: 'Profile details updated',
    });
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  userProfile: state.userProfile.userProfile,
  errors: state.userProfile.errors,
  langPreferences: getLangPreferences(state),
});

export default connect(mapStateToProps, {
  updateProfile: BOX_USER_PROFILE_UPDATE_REQUEST,
  removeErrors: BOX_USER_PROFILE_REMOVE_ERRORS,
})(ProfileDetailsModal);
