import { Select, MenuItem, Chip } from 'extended-oxygen-elements';

export type MultiSelectProps = {
  id: string;
  name: string;
  selectedIds: string[];
  ariaLabel: string;
  selectOptions: { label: string; value: string }[];
  handleSelectChange: Function;
};

const MultiSelect = ({
  id,
  name,
  selectedIds,
  ariaLabel,
  selectOptions,
  handleSelectChange,
}: MultiSelectProps) => {
  const selectedOptions: { label: string; value: string }[] = [];
  for (let i = 0; i < selectOptions.length; i++) {
    for (let j = 0; j < selectedIds.length; j++) {
      if (selectOptions[i].value === selectedIds[j]) {
        selectedOptions.push(selectOptions[i]);
      }
    }
  }

  return (
    <Select
      fullWidth
      multiple
      id={id}
      name={name}
      value={selectedIds || []}
      aria-label={ariaLabel}
      className={
        selectedIds.length
          ? 'mui-multiple-select'
          : 'mui-multiple-select select-placeholder'
      }
      displayEmpty={true}
      renderValue={() => {
        return selectedOptions.length === 0 ? (
          'Select...'
        ) : (
          <div className="selected-options-container">
            {selectedOptions.map((item, i) => (
              <Chip
                key={`selected-label-${i}`}
                label={item.label}
                onDelete={() => {
                  const updatedSelectedOptions = selectedOptions
                    .filter(
                      (selectedOption) => selectedOption.value !== item.value
                    )
                    .map((selectedOption) => selectedOption.value);
                  handleSelectChange({
                    name: name,
                    value: updatedSelectedOptions as any,
                  });
                }}
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
              />
            ))}
          </div>
        );
      }}
      onChange={(e) => {
        handleSelectChange({
          name: e.target.name,
          value: e.target.value as string[],
        });
      }}
    >
      {selectOptions.map((option: any) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default MultiSelect;
