import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment';
import { Loader } from 'elmo-elements';
import {
  getOptionsForCurrentUser,
  getOptionsForUnassignedRoster,
  getSelectedAreaRole,
  getUserName,
  scrollToErrors,
  updateDatesOfBreaksForMidnightShifts,
  updateRosterShiftBreaksOnDateChange,
} from 'lib/helpers';
import { StoreState } from 'state/types';
import {
  BOX_ROSTER_SHIFT_MODAL_ACCEPT_SHIFT,
  BOX_ROSTER_SHIFT_MODAL_CLEAR_ERRORS,
  BOX_ROSTER_SHIFT_MODAL_CLOSE,
  BOX_ROSTER_SHIFT_MODAL_CREATE_SHIFT,
  BOX_ROSTER_SHIFT_MODAL_DELETE_SHIFT,
  BOX_ROSTER_SHIFT_MODAL_GET_EVENTS,
  BOX_ROSTER_SHIFT_MODAL_GET_SHIFT_USERS,
  BOX_ROSTER_SHIFT_MODAL_NOTIFY,
  BOX_ROSTER_SHIFT_MODAL_OFFER_SHIFT,
  BOX_ROSTER_SHIFT_MODAL_OVERLAP_CONFIRMATION_CLOSE,
  BOX_ROSTER_SHIFT_MODAL_UNDO_SHIFT,
  BOX_ROSTER_SHIFT_MODAL_UPDATE,
  BOX_ROSTER_SHIFT_MODAL_UPDATE_SHIFT_PROPS,
  getInitialOwner,
  getUsersIsLoading,
} from 'state/Roster/RosterShiftModal';
import {
  activeAreasBySiteIdSelector,
  getAreas,
  getRoles,
  getSites,
} from 'state/AccountTree';
import ShiftOverlapConfirmationModal from '../ShiftOverlapConfirmationModal';
import { hasPermissionSelector } from 'state/Auth';
import {
  getCurrencyCode,
  getCurrencyPlacement,
  getDateFormat,
  getIsDefaultBreaksApplied,
  getNumberFormat,
  getTimeFormat,
} from 'state/Account';
import { getUserListResponse } from 'state/UsersCollection';
import {
  bySiteTimezoneCombinerCreator,
  currentSiteTimezoneCombiner,
} from 'state/combiners';
import { getCurrentTime } from 'state/CurrentTime';
import {
  ErrorBox,
  PageDialog,
  PageDialogCancel,
  PageDialogCloseIconButton,
  PageDialogSubmit,
} from 'element';
import { UserModal } from '../components';
import {
  DispatchProps,
  OwnProps,
  RepeatShiftItem,
  RosterShiftActionPayload,
  State,
  StateProps,
} from './types';
import { ConfirmationModal, ModalActions, RosterEditForm } from './components';
import '../ShiftModals.scss';
import { isSiteView } from '../../../helpers';
import { DialogActions, DialogTitle } from 'oxygen-elements';
import { DialogContent } from '../../../extended-oxygen-elements';

type Props = OwnProps & StateProps & DispatchProps;

export class RosteredShiftModal extends Component<Props, State> {
  shiftPayload: RosterShiftActionPayload | null = null;
  dateFormat: string = 'YYYY-MM-DD HH:mm:ss';
  isDirty: boolean = false;
  tabIndex: number = 0;

  constructor(props: Props) {
    super(props);
    this.state = {
      activeSubModal: '',
      shift: props.rosteredShift,
      watchErrors: false,
      isValid: false,
      deleteConfirmationOpened: false,
      isBreaksEdited: false,
      repeatShift: this.getRepeatShiftData(props.rosteredShift.start),
    };
  }

  getRepeatShiftData = (start: Moment): RepeatShiftItem[] => {
    const days: RepeatShiftItem[] = [];
    const dayNum = +moment(start).isoWeekday();
    for (let i = 1; i <= 7; i++) {
      days.push({
        id: `day-${i}`,
        isChecked: dayNum === i,
        isoDayNumber: i,
        isDisabled: dayNum === i,
      });
    }

    return days;
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    const {
      rosteredShiftModal: { isLoading, isOpened },
      rosteredShift: { is_pasted },
    } = prevProps;

    const props = this.props.rosteredShiftModal;

    if (isLoading && !props.isLoading) {
      this.setPropsAndRequests();
      this.isDirty = false;
    }

    if (
      !isLoading &&
      !props.isLoading &&
      isOpened !== props.isOpened &&
      props.isOpened
    ) {
      this.setPropsAndRequests();
      this.isDirty = false;
      this.canCreateShiftWithDefaults();
      this.setRepeatShiftData();
    }

    if (
      this.props.errors &&
      this.props.errors.length &&
      this.state.watchErrors
    ) {
      this.triggerScrollToErrors();
    }

    if (
      this.props.rosteredShift.is_pasted !== is_pasted &&
      this.props.rosteredShift.is_pasted
    ) {
      this.setPropsAndRequests();
      this.isDirty = true;
    }

    if (isOpened !== props.isOpened && !props.isOpened) {
      this.setState({
        ...this.state,
        isBreaksEdited: false,
      });
      this.setRepeatShiftData();
    }
  }

  canCreateShiftWithDefaults = () => {
    const { shift } = this.state;
    if (shift.area_id !== null && shift.role_id !== null && !shift.id) {
      this.setDirty();
    }
  };

  setDirty = () => {
    this.isDirty = true;
  };

  increaseTabIndex = () => {
    return ++this.tabIndex;
  };

  setPropsAndRequests = () => {
    const { start, end, id } = this.props.rosteredShift;
    const { isBreaksEdited } = this.state;
    const { defaultValues } = this.props;

    this.setState(
      {
        shift: {
          ...this.props.rosteredShift,
          ...defaultValues,
        },
      },
      () => {
        this.validateForm();
        this.getUsersList();
        this.getEventsList();
      }
    );
  };

  setShiftPayload = (ignoreErrors: boolean = false) => {
    const {
      id,
      user_id,
      role_id,
      area_id,
      breaks,
      start,
      end,
      notify_user,
      template_id,
      tags,
      event_id,
      notes,
    } = this.state.shift;
    const { events } = this.props;

    const getEventId = (eventId: string | null) => {
      if (eventId === null) {
        return null;
      }
      return events[eventId] ? eventId : null;
    };

    this.shiftPayload = {
      id: id,
      user_id: user_id,
      role_id: role_id,
      area_id: area_id,
      event_id: getEventId(event_id),
      breaks: updateDatesOfBreaksForMidnightShifts(breaks, start),
      start: start,
      end: end,
      notify_user: notify_user,
      template_id: template_id,
      tags: tags,
      ignore_errors: ignoreErrors,
      repeatShift: this.getRepeatShiftPayload(),
      notes: !notes ? null : notes.trim(),
    };
  };

  getRepeatShiftPayload = () => {
    const { repeatShift } = this.state;
    return repeatShift.filter((s) => s.isChecked);
  };

  getAreaRoleOptions = () => {
    const { areas, roles, users, areasBySideId, siteId } = this.props;
    const {
      shift: { user_id },
    } = this.state;
    const areasBySite = areasBySideId[siteId] || [];
    return user_id !== null
      ? getOptionsForCurrentUser(areasBySite, areas, roles, users[user_id])
      : getOptionsForUnassignedRoster(areasBySite, roles);
  };

  validateForm = () => {
    const { role_id, area_id } = this.state.shift;
    const option = getSelectedAreaRole(
      this.getAreaRoleOptions(),
      this.state.shift
    );
    if (option && (option as any)[0]) {
      this.setState({
        ...this.state,
        isValid: (option as any)[0].value !== '__',
      });
    } else {
      this.setState({
        ...this.state,
        isValid: role_id !== '' && area_id !== '',
      });
    }
  };

  setRepeatShiftData = () => {
    setTimeout(() => {
      this.setState((prevState) => ({
        ...prevState,
        repeatShift: this.getRepeatShiftData(prevState.shift.start),
      }));
    }, 0);
  };

  render() {
    const {
      rosteredShiftModal: { isOpened },
    } = this.props;

    return isOpened ? (
      <>
        {this.mainModal()}
        {this.subModals()}

        <ShiftOverlapConfirmationModal
          overlapModal={this.props.overlapModal}
          confirmOverlap={this.submitOverlappedShift}
          closeModal={this.props.closeOverlapModal}
        />

        <ConfirmationModal
          closeModal={this.closeConfirmationModal}
          isOpened={this.state.deleteConfirmationOpened}
          confirmAction={this.doDeletionOfRosteredShift}
        />
      </>
    ) : null;
  }

  closeConfirmationModal = () => {
    this.setState({
      deleteConfirmationOpened: false,
    });
  };

  subModals = () => {
    return (
      <UserModal
        isOpened={this.state.activeSubModal === 'users'}
        isActive={this.state.activeSubModal === 'users'}
        closeModal={this.closeSubModal}
        user_id={this.state.shift.user_id}
        setUser={this.setUser}
        preselectedAreaRole={{
          area_id: this.state.shift.area_id,
          role_id: this.state.shift.role_id,
          site_id: this.props.siteId,
        }}
        type={'rostered_shift'}
        owner={this.props.initialOwner}
      />
    );
  };

  mainModal = () => {
    const { isOpened, isLoading } = this.props.rosteredShiftModal;
    return (
      <PageDialog
        id={'rostered-shift-modal'}
        maxWidth={'sm'}
        open={isOpened}
        className={'shift-modal'}
      >
        <DialogActions
          className={'shift-modal-header'}
          sx={{ boxShadow: '0 0 6px 2px #ccc', zIndex: 3, padding: 0 }}
        >
          <DialogTitle>
            {this.getModalTitle()}{' '}
            <PageDialogCloseIconButton onClose={this.props.closeModal} />
          </DialogTitle>
        </DialogActions>
        <DialogContent className={'shift-modal-content'}>
          {isLoading ? <Loader type="spinner" /> : this.modalContent()}
        </DialogContent>
        {!isLoading && (
          <DialogActions sx={{ boxShadow: '0 0 6px 2px #ccc', zIndex: 3 }}>
            <PageDialogCancel
              onClick={this.props.closeModal}
              fullWidth={false}
              size={'large'}
            >
              Cancel
            </PageDialogCancel>
            {this.getButton()}
          </DialogActions>
        )}
      </PageDialog>
    );
  };

  getModalTitle = () => {
    const { isLoading } = this.props.rosteredShiftModal;
    const { id } = this.state.shift;
    const title = id ? 'Edit Shift' : 'Add Shift';
    return !isLoading ? (
      <div className="long-text">{`${title} ${this.getUserNameOrRoleName()}`}</div>
    ) : (
      <div className="long-text">Receiving data...</div>
    );
  };

  getUserNameOrRoleName = () => {
    const { users, roles } = this.props;
    const { role_id, user_id } = this.state.shift;
    if (isSiteView() && role_id) {
      return `- ${roles[role_id].name}`;
    }
    if (user_id) {
      return `- ${getUserName(users[user_id])}`;
    }
    return '';
  };

  setUser = (userId: string | null) => {
    this.setShiftProps('user_id', userId);
  };

  closeSubModal = () => {
    this.setState({
      ...this.state,
      activeSubModal: '',
    });
  };

  openSubModal = (name: string) => {
    this.setState({
      ...this.state,
      activeSubModal: name,
    });
  };

  getButton = () => {
    const { isUpdating } = this.props.rosteredShiftModal;
    const { isValid } = this.state;
    return (
      <PageDialogSubmit
        id={'submit-rostered-shift-btn'}
        tabIndex={0}
        onClick={this.submitModal}
        loading={isUpdating}
        disabled={isUpdating || !isValid}
        fullWidth={false}
        size={'large'}
      >
        Save
      </PageDialogSubmit>
    );
  };

  modalContent = () => {
    const { displayUsersAndPositionsFields, disabledState } = this.props;
    return (
      <>
        <ErrorBox
          errors={this.props.errors}
          clearErrors={this.props.clearErrors}
          dontWatchGlobalErrors={true}
        />
        <ModalActions
          isUpdating={this.props.rosteredShiftModal.isUpdating}
          shift={this.state.shift}
          openSubModal={this.openSubModal}
          deleteRosteredShift={this.deleteRosteredShift}
          undoRosteredShift={this.undoRosteredShift}
          settings={this.props.settings}
          acceptShift={this.acceptShift}
          notifyUser={this.notifyUser}
          offerShift={this.offerShift}
          currentTimeBySiteTimezone={this.currentTimeBySiteTimezone}
          shiftCosting={this.props.shiftCosting}
        />
        <RosterEditForm
          shift={this.state.shift}
          siteId={this.props.siteId}
          openSubModal={this.openSubModal}
          setShiftProps={this.setShiftProps}
          onChangeStartEnd={this.onChangePeriod}
          onChangeAreaRolePicker={this.handleOnChangeAreaRole}
          eventsList={this.props.events}
          usersIsLoading={this.props.usersIsLoading}
          areaRolesOptions={this.getAreaRoleOptions()}
          increaseTabIndex={this.increaseTabIndex}
          repeatShift={this.state.repeatShift}
          onChangeRepeatShift={this.onChangeRepeatShift}
          onChangeBreaks={() => {
            this.setState({
              isBreaksEdited: true,
            });
          }}
          displayUsersAndPositionsFields={displayUsersAndPositionsFields}
          disabledState={disabledState}
        />
      </>
    );
  };

  onChangeRepeatShift = (dayParams: any) => {
    const { repeatShift } = this.state;
    const repeats = repeatShift.map((shift) => {
      if (shift.id === dayParams.id) {
        shift.isChecked = dayParams.isChecked;
      }
      return shift;
    });
    this.setState((prevState) => ({
      ...prevState,
      repeatShift: repeats,
    }));
  };

  private get currentTimeBySiteTimezone() {
    const { sites, siteId, currentTime } = this.props;

    const timezone = currentSiteTimezoneCombiner(siteId, sites);
    return bySiteTimezoneCombinerCreator()(currentTime, timezone);
  }

  acceptShift = () => {
    this.props.acceptShift(this.state.shift.id);
  };

  notifyUser = () => {
    this.props.notifyUser(this.state.shift.id);
  };

  offerShift = () => {
    this.props.offerShift(this.state.shift.id);
  };

  deleteRosteredShift = () => {
    if (this.state.shift.timesheet_id !== null) {
      this.setState({
        deleteConfirmationOpened: true,
      });
    } else {
      this.doDeletionOfRosteredShift();
    }
  };

  doDeletionOfRosteredShift = () => {
    this.closeConfirmationModal();
    if (this.state.shift !== null && this.state.shift.id !== null) {
      const {
        deleteRosteredShift,
        deleteShiftAdditionalPayload = {},
        updateRelatedData,
      } = this.props;
      const {
        shift: { id },
      } = this.state;

      deleteRosteredShift({
        id,
        updateRelatedData,
        ...deleteShiftAdditionalPayload,
      });
    }
  };

  undoRosteredShift = () => {
    if (this.state.shift !== null && this.state.shift.id !== null) {
      this.props.undoRosteredShift({
        rostered_shift_id: this.state.shift.id,
        site_id: this.props.siteId,
      });
    }
  };

  onChangePeriod = (
    { end, start }: { start: Moment; end: Moment },
    key: 'date' | 'time'
  ) => {
    const {
      shift: { breaks },
    } = this.state;

    if (end.clone().diff(start.clone(), 'minutes') > 1440) {
      end.subtract(1, 'day');
    }

    this.setState(
      {
        ...this.state,
        shift: {
          ...this.state.shift,
          start,
          end,
          breaks: updateRosterShiftBreaksOnDateChange(start, breaks),
        },
      },
      () => {
        this.getUsersList();
        this.getEventsList();
        if (key === 'date') {
          this.setRepeatShiftData();
        }
      }
    );
    this.setDirty();
  };

  handleOnChangeAreaRole = (e: any) => {
    const { value } = e;
    const parsed = value.split('__');
    this.setState(
      {
        ...this.state,
        shift: {
          ...this.state.shift,
          area_id: parsed[0],
          role_id: parsed[1],
        },
      },
      () => {
        this.validateForm();
        this.setDirty();
      }
    );
  };

  saveRosteredShift = () => {
    const { shiftPayload } = this;
    const { updateRelatedData, isDefaultBreaks } = this.props;
    const { isBreaksEdited } = this.state;
    if (shiftPayload) {
      const { id, ...rest } = shiftPayload;
      if (id) {
        this.props.updateRosteredShift({
          ...rest,
          id,
          updateRelatedData,
        });
      } else {
        this.props.createRosteredShift({
          ...rest,
          hasDefaultBreaks: isDefaultBreaks && !isBreaksEdited,
        });
      }
    }
  };

  submitModal = () => {
    this.canCreateShiftWithDefaults();
    if (this.isDirty) {
      this.setShiftPayload();
      this.saveRosteredShift();
      this.watchErrors(true);
    } else {
      this.props.closeModal();
    }
  };

  submitOverlappedShift = () => {
    this.setShiftPayload(true);
    this.saveRosteredShift();
    this.props.closeOverlapModal(true);
    this.watchErrors(true);
  };

  triggerScrollToErrors = () => {
    scrollToErrors(this.props.errors);
    this.watchErrors(false);
  };

  setShiftProps = (name: string, value: any) => {
    this.setState(
      {
        ...this.state,
        shift: {
          ...this.state.shift,
          [`${name}`]: value,
        },
      },
      () => {
        this.validateForm();
        this.setDirty();
      }
    );
  };

  watchErrors = (value: boolean) => {
    this.setState({
      watchErrors: value,
    });
  };

  getFromToParams = () => {
    const { start, end } = this.state.shift;
    return {
      start: start,
      end: end,
      site_id: this.props.siteId,
    };
  };

  getUsersList = () => {
    const params = this.getFromToParams();
    const { id } = this.state.shift;
    this.props.getUsersList({
      ...params,
      type: 'rostered_shifts',
      rostered_shift_id: id && id !== '' ? id : null,
    });
  };

  getEventsList = () => {
    if (this.props.hasEventPermission) {
      const params = this.getFromToParams();
      this.props.getEventsList(params);
    }
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  errors: state.rosterShiftModal.rosteredShiftModal.errors,
  rosteredShiftModal: state.rosterShiftModal.rosteredShiftModal,
  users: getUserListResponse(state),
  rosteredShift: state.rosterShiftModal.currentRosteredShift,
  events: state.rosterShiftModal.eventsList,
  areasBySideId: activeAreasBySiteIdSelector(state),
  sites: getSites(state),
  areas: getAreas(state),
  roles: getRoles(state),
  settings: state.account.account.rostered_shift_settings,
  hasEventPermission: hasPermissionSelector(state, 'roster.events.edit'),
  shiftCosting: hasPermissionSelector(
    state,
    'roster.rosteredshift.view.labourcostings'
  ),
  usersIsLoading: getUsersIsLoading(state),
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
  numberFormat: getNumberFormat(state),
  currencyCode: getCurrencyCode(state),
  currencyPlacement: getCurrencyPlacement(state),
  currentTime: getCurrentTime(state),
  initialOwner: getInitialOwner(state),
  overlapModal: state.rosterShiftModal.overlapShiftModal,
  clipboardAction: state.rosterContextualMenu.action,
  isDefaultBreaks: getIsDefaultBreaksApplied(state),
});

const mapToDispatchProps: DispatchProps = {
  updateCurrentRoster: BOX_ROSTER_SHIFT_MODAL_UPDATE_SHIFT_PROPS,
  closeModal: BOX_ROSTER_SHIFT_MODAL_CLOSE,
  clearErrors: BOX_ROSTER_SHIFT_MODAL_CLEAR_ERRORS,
  createRosteredShift: BOX_ROSTER_SHIFT_MODAL_CREATE_SHIFT,
  updateRosteredShift: BOX_ROSTER_SHIFT_MODAL_UPDATE,
  deleteRosteredShift: BOX_ROSTER_SHIFT_MODAL_DELETE_SHIFT,
  getUsersList: BOX_ROSTER_SHIFT_MODAL_GET_SHIFT_USERS,
  undoRosteredShift: BOX_ROSTER_SHIFT_MODAL_UNDO_SHIFT,
  getEventsList: BOX_ROSTER_SHIFT_MODAL_GET_EVENTS,
  acceptShift: BOX_ROSTER_SHIFT_MODAL_ACCEPT_SHIFT,
  notifyUser: BOX_ROSTER_SHIFT_MODAL_NOTIFY,
  offerShift: BOX_ROSTER_SHIFT_MODAL_OFFER_SHIFT,
  closeOverlapModal: BOX_ROSTER_SHIFT_MODAL_OVERLAP_CONFIRMATION_CLOSE,
};

export default connect(mapStateToProps, mapToDispatchProps)(RosteredShiftModal);
