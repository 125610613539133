import { SagaIterator } from 'redux-saga';
import {
  all,
  call,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import * as actions from './actions';
import {
  BOX_EDIT_TEMPLATE_DRAG_N_DROP_FAILURE,
  BOX_EDIT_TEMPLATE_DRAG_N_DROP_SUCCESS,
} from './actions';
import { formatError } from '../../helpers';
import * as fetchPageData from '../../FetchPageData';
import { getRotaUserListRequest } from '../../UsersCollection';
import {
  CreateShiftItemPayload,
  EditShiftItemPayload,
  EditShiftItemResponse,
} from './types';
import { SagaAction } from 'type';
import Api from 'lib/Api';
import {
  processApiRequest,
  processApiRequestWithConfirm,
} from '../../ProcessApiRequest';
import {
  BOX_IS_LOADING_OFF,
  BOX_IS_LOADING_ON,
  ROSTER_EDIT_TEMPLATE_SHIFT_CONFIRMATION,
} from '../../IsLoading';
import {
  getDroppedShiftPayload,
  getPrevPageValue,
  getTemplateId,
} from './selectors';
import browserHistory from 'lib/browserHistory';
import { privateRoutes } from '../../../routes';
import { BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN } from '../Roster/actions';
import {
  BOX_ROSTER_CONTEXTUAL_MENU_CLOSE_AND_SET_PROPS,
  BOX_ROSTER_CONTEXTUAL_MENU_SET_INPROGRESS,
} from '../ContextualMenu/actions';
import { getLastAction } from '../ContextualMenu/selectors';
import { createShiftItemRequest } from './requestSagas';

const getTemplateData = function* ({
  payload,
}: SagaAction<string>): SagaIterator {
  try {
    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_REQUEST(
        fetchPageData.ROSTER_EDIT_TEMPLATE
      )
    );
    let response = yield all([
      call(processApiRequest, Api.EditTemplate.getShiftsByTemplateId, payload),
      call(getRotaUserListRequest),
    ]);
    yield put(actions.BOX_EDIT_TEMPLATE_GET_DATA_SUCCESS(response[0]));
    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_SUCCESS(
        fetchPageData.ROSTER_EDIT_TEMPLATE
      )
    );
  } catch (e) {
    yield put(actions.BOX_EDIT_TEMPLATE_GET_DATA_FAILURE(formatError(e)));
    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_FAILURE(
        fetchPageData.ROSTER_EDIT_TEMPLATE
      )
    );
  }
};

const createShiftItem = function* ({
  payload,
}: SagaAction<CreateShiftItemPayload>): SagaIterator {
  yield put(BOX_IS_LOADING_ON(ROSTER_EDIT_TEMPLATE_SHIFT_CONFIRMATION));
  try {
    yield call(createShiftItemRequest, payload);
    yield put(actions.BOX_EDIT_TEMPLATE_CREATE_SHIFT_ITEM_SUCCESS());
    yield put(actions.BOX_EDIT_TEMPLATE_CLOSE_EDIT_MODAL());
    yield put(BOX_ROSTER_CONTEXTUAL_MENU_SET_INPROGRESS(false));
  } catch (e) {
    if (typeof payload.is_pasted !== 'undefined' && payload.is_pasted) {
      yield put(BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN(formatError(e)));
    } else {
      yield put(
        actions.BOX_EDIT_TEMPLATE_CREATE_SHIFT_ITEM_FAILURE(formatError(e))
      );
    }
    yield put(BOX_ROSTER_CONTEXTUAL_MENU_SET_INPROGRESS(false));
  }
  yield put(BOX_IS_LOADING_OFF(ROSTER_EDIT_TEMPLATE_SHIFT_CONFIRMATION));
};

const editShiftItem = function* ({
  payload,
}: SagaAction<EditShiftItemPayload>): SagaIterator {
  yield put(BOX_IS_LOADING_ON(ROSTER_EDIT_TEMPLATE_SHIFT_CONFIRMATION));
  try {
    const response: EditShiftItemResponse = yield call(
      processApiRequestWithConfirm,
      Api.EditTemplate.editShiftItem,
      payload
    );
    yield put(actions.BOX_EDIT_TEMPLATE_EDIT_SHIFT_ITEM_SUCCESS(response));
    yield put(actions.BOX_EDIT_TEMPLATE_CLOSE_EDIT_MODAL());
    const action = yield select(getLastAction);
    yield put(BOX_ROSTER_CONTEXTUAL_MENU_SET_INPROGRESS(false));
    if (action === 'cut') {
      yield put(
        BOX_ROSTER_CONTEXTUAL_MENU_CLOSE_AND_SET_PROPS({
          clipboard: null,
          action: 'none',
        })
      );
    }
  } catch (e) {
    if (
      typeof payload.is_pasted !== 'undefined' &&
      payload.is_pasted &&
      !payload.is_modal
    ) {
      yield put(BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN(formatError(e)));
    } else {
      yield put(
        actions.BOX_EDIT_TEMPLATE_EDIT_SHIFT_ITEM_FAILURE(formatError(e))
      );
    }
    yield put(BOX_ROSTER_CONTEXTUAL_MENU_SET_INPROGRESS(false));
  }
  yield put(BOX_IS_LOADING_OFF(ROSTER_EDIT_TEMPLATE_SHIFT_CONFIRMATION));
};

const deleteItem = function* (shiftId: string): SagaIterator {
  const templateId = yield select(getTemplateId);
  yield call(processApiRequest, Api.EditTemplate.deleteShiftItem, {
    template_id: templateId,
    shift_id: shiftId,
  });
  yield put(actions.BOX_EDIT_TEMPLATE_DELETE_SHIFT_ITEM_SUCCESS(shiftId));
};

function* deleteShiftItemWithConfirmation({
  payload,
}: SagaAction<string>): SagaIterator {
  yield put(actions.BOX_EDIT_TEMPLATE_OPEN_DELETE_MODAL());
  const { confirm } = yield race({
    confirm: take(actions.BOX_EDIT_TEMPLATE_CONFIRM_DELETE_MODAL),
    close: take(actions.BOX_EDIT_TEMPLATE_CLOSE_DELETE_MODAL),
  });
  if (confirm) {
    try {
      yield call(deleteItem, payload);
      yield put(actions.BOX_EDIT_TEMPLATE_DELETE_SHIFT_ITEM_SUCCESS(payload));
      yield put(actions.BOX_EDIT_TEMPLATE_CLOSE_DELETE_MODAL());
    } catch (e) {
      yield put(
        actions.BOX_EDIT_TEMPLATE_DELETE_SHIFT_ITEM_FAILURE_CONFIRMATION(
          formatError(e)
        )
      );
    }
  }
}

function* deleteShiftItem({ payload }: SagaAction<string>): SagaIterator {
  try {
    yield call(deleteItem, payload);
    yield put(actions.BOX_EDIT_TEMPLATE_DELETE_SHIFT_ITEM_SUCCESS(payload));
  } catch (e) {
    yield put(
      actions.BOX_EDIT_TEMPLATE_DELETE_SHIFT_ITEM_FAILURE(formatError(e))
    );
  }
}

function* deleteTemplate(): SagaIterator {
  try {
    const id = yield select(getTemplateId);
    yield call(processApiRequestWithConfirm, Api.Roster.deleteTemplate, { id });
    yield put(actions.BOX_EDIT_TEMPLATE_DELETE_SUCCESS());

    let redirectUrl = yield select(getPrevPageValue);
    redirectUrl =
      redirectUrl === null ? privateRoutes.roster.path : redirectUrl;

    browserHistory.push(redirectUrl);
  } catch (e) {
    yield put(actions.BOX_EDIT_TEMPLATE_DELETE_FAILURE(formatError(e)));
  }
}

const setDropCancelled = function* (): SagaIterator {
  yield put(actions.BOX_EDIT_TEMPLATE_SET_DROP_INPROGRESS(false));
  yield put(actions.BOX_EDIT_TEMPLATE_SET_DROP_CANCELED(true));
};

const applyDragNDrop = function* (): SagaIterator {
  yield put(BOX_IS_LOADING_ON(ROSTER_EDIT_TEMPLATE_SHIFT_CONFIRMATION));
  try {
    const shiftPayload = yield select(getDroppedShiftPayload);
    const templateId = yield select(getTemplateId);
    const response: any | undefined = yield call(
      processApiRequestWithConfirm,
      Api.EditTemplate.editShiftItem,
      {
        ...shiftPayload,
        templateId: templateId,
        day: +shiftPayload.day + 1,
      }
    );

    if (response) {
      yield put(
        BOX_EDIT_TEMPLATE_DRAG_N_DROP_SUCCESS({
          removeId: shiftPayload.id,
          updateShifts: response,
        })
      );
    } else {
      yield call(setDropCancelled);
    }
  } catch (e) {
    yield put(BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN(formatError(e)));
    yield put(BOX_EDIT_TEMPLATE_DRAG_N_DROP_FAILURE(formatError(e)));
  }
  yield put(BOX_IS_LOADING_OFF(ROSTER_EDIT_TEMPLATE_SHIFT_CONFIRMATION));
};

function* updateTemplate({ payload }: SagaAction<string>): SagaIterator {
  try {
    const templateId = yield select(getTemplateId);
    yield call(processApiRequestWithConfirm, Api.EditTemplate.updateTemplate, {
      templateId,
      title: payload,
    });
    yield put(actions.BOX_EDIT_TEMPLATE_UPDATE_SUCCESS(payload));
  } catch (e) {
    yield put(actions.BOX_EDIT_TEMPLATE_UPDATE_FAILURE(formatError(e)));
  }
}

function* saveAsTemplate({ payload }: SagaAction<string>): SagaIterator {
  try {
    const id = yield select(getTemplateId);
    const response = yield call(
      processApiRequestWithConfirm,
      Api.EditTemplate.saveAs,
      { id, title: payload }
    );
    if (response) {
      yield put(actions.BOX_EDIT_TEMPLATE_SAVE_AS_SUCCESS(response));
      const view = browserHistory.location.pathname.includes('user-view')
        ? 'rosterWeekViewEditUserView'
        : 'rosterWeekViewEditSiteView';

      browserHistory.push(
        privateRoutes.roster.routes.rosterEditTemplate.routes[view].path(
          response.id
        )
      );
    }
  } catch (e) {
    yield put(actions.BOX_EDIT_TEMPLATE_SAVE_AS_FAILURE(formatError(e)));
  }
}

export const watchEditTemplate = function* (): SagaIterator {
  yield takeLatest(actions.BOX_EDIT_TEMPLATE_GET_DATA, getTemplateData);
  yield takeLatest(
    actions.BOX_EDIT_TEMPLATE_CREATE_SHIFT_ITEM,
    createShiftItem
  );
  yield takeLatest(actions.BOX_EDIT_TEMPLATE_EDIT_SHIFT_ITEM, editShiftItem);
  yield takeLatest(
    actions.BOX_EDIT_TEMPLATE_DELETE_SHIFT_ITEM,
    deleteShiftItem
  );
  yield takeLatest(
    actions.BOX_EDIT_TEMPLATE_DELETE_CONFIRM_SHIFT_ITEM,
    deleteShiftItemWithConfirmation
  );
  yield takeLatest(
    actions.BOX_EDIT_TEMPLATE_CONFIRM_DELETE_TEMPLATE_MODAL,
    deleteTemplate
  );
  yield takeLatest(
    actions.BOX_EDIT_TEMPLATE_CONFIRM_SAVE_AS_TEMPLATE_MODAL,
    saveAsTemplate
  );
  yield takeLatest(actions.BOX_EDIT_TEMPLATE_DRAG_N_DROP, applyDragNDrop);
  yield takeLatest(
    actions.BOX_EDIT_TEMPLATE_CONFIRM_UPDATE_TEMPLATE_MODAL,
    updateTemplate
  );
};
