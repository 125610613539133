import {
  EmployeeUnavailability,
  ShiftTrade,
  SocketEmployeeShiftRosteredShift,
  SocketEmployeeShiftTimesheet,
  SocketEmployeeTimesheetRosteredShift,
  SocketEmployeeTimesheetTimesheet,
} from 'type';
import { WS } from '../SocketConnection';
import BaseChannel, { ChannelHandler } from './BaseChannel';

type Params = {
  user_id: string;
};

function getChannelId({ user_id }: Params) {
  return `employee_dashboard.${user_id}`;
}

export default class EmployeeDashboardChannel extends BaseChannel {
  constructor(ws: WS, params: Params) {
    super(ws, getChannelId(params));
  }

  onShiftTradeShiftOfferEmployeeChanged(handler: ChannelHandler<ShiftTrade>) {
    return this.on('.shift_trade.shift_offer.employee.changed', handler);
  }

  onShiftTradeShiftOfferEmployeeDeleted(
    handler: ChannelHandler<{ id: string }>
  ) {
    return this.on('.shift_trade.shift_offer.employee.deleted', handler);
  }

  onShiftTradeShiftSwapEmployeeChanged(handler: ChannelHandler<ShiftTrade>) {
    return this.on('.shift_trade.shift_swap.employee.changed', handler);
  }

  onShiftTradeShiftSwapEmployeeDeleted(
    handler: ChannelHandler<{ id: string }>
  ) {
    return this.on('.shift_trade.shift_swap.employee.deleted', handler);
  }

  onRosteredShiftChanged(
    handler: ChannelHandler<{
      rostered_shift: SocketEmployeeShiftRosteredShift;
      timesheet: SocketEmployeeShiftTimesheet | null;
    }>
  ) {
    return this.on('.rostered_shift.changed', handler);
  }

  onRosteredShiftDeleted(handler: ChannelHandler<{ id: string }>) {
    return this.on('.rostered_shift.deleted', handler);
  }

  onTimesheetChanged(
    handler: ChannelHandler<{
      timesheet: SocketEmployeeTimesheetTimesheet;
      rostered_shift: SocketEmployeeTimesheetRosteredShift | null;
    }>
  ) {
    return this.on('.timesheet.changed', handler);
  }

  onTimesheetDeleted(handler: ChannelHandler<{ id: string }>) {
    return this.on('.timesheet.deleted', handler);
  }

  onTimeOffChanged(handler: ChannelHandler<EmployeeUnavailability>) {
    return this.on('.time_off.changed', handler);
  }

  onTimeOffDeleted(handler: ChannelHandler<{ id: string }>) {
    return this.on('.time_off.deleted', handler);
  }

  onTimeOffRosteredShiftChanged(handler: ChannelHandler<any>) {
    return this.on('.time_off.rostered_shift.changed', handler);
  }

  onTimeOffRosteredShiftDeleted(handler: ChannelHandler<any>) {
    return this.on('.time_off.rostered_shift.deleted', handler);
  }

  onForecastEventChanged(handler: ChannelHandler<any>) {
    return this.on('.forecast_event.changed', handler);
  }

  onForecastEventDeleted(handler: ChannelHandler<any>) {
    return this.on('.forecast_event.deleted', handler);
  }
}
