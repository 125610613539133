import React from 'react';
import classNames from 'clsx';
import { createStyles, makeStyles } from '@mui/styles';

type Props = {
  timeColor?: 'default' | 'error';
  from: string;
  to: string;
};

const useTimeStyles = makeStyles((theme) => createStyles({
  errorColor: {
    color: 'rgb(177,16,30)'
  },
  defaultColor: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  time: {
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: 400,
    letterSpacing: '0.2px',
    position: 'relative',
    whiteSpace: 'nowrap'
  }
}));

export const ShiftCardTimeInfo = ({ timeColor, from, to }: Props) => {
  const styles = useTimeStyles();
  return (
    <div className="shift-card-view__title">
      <div
        className={classNames('shift-card-view__time', [
          styles.time,
          timeColor !== 'default' && styles.errorColor,
          timeColor !== 'error' && styles.defaultColor
        ])}
      >
        {from}-{to}
      </div>
    </div>
  )
};
