import React from 'react';
import { RosterViewScrollableWrapper } from '../../../../components';
import { WeekNavigation } from '../../components';
import EditView from './view';

const EditTemplateUserView = () => (
  <RosterViewScrollableWrapper header={<WeekNavigation/>}>
    <EditView/>
  </RosterViewScrollableWrapper>
);

export default EditTemplateUserView;
