import React, { Component } from 'react';
import { ListTable, LoadingOverlay, Text } from 'elmo-elements';
import { connect } from 'react-redux';
import {
  AccountTreeArea,
  AccountTreeRole,
  AccountTreeSite,
  ShiftOffer,
  ShiftOffersWithPagerSelector,
  SortShiftOffers,
  SortShiftOffersFields,
  StringMap,
  UserFields,
} from 'type';
import { privateRoutes } from 'routes';
import { getUserName, onSortCreator } from 'lib/helpers';
import { StoreState } from 'state/types';
import {
  BOX_SHIFT_OFFERS_CHANGE_ORDER,
  getIsUpdating,
  getSort,
  shiftOffersWithPagerSelector,
} from 'state/ManagerDashboard/ShiftOffers/ShiftOffers';
import { getDateFormat, timeFormatSelector } from 'state/Account';
import { userListSelector } from 'state/UsersCollection';
import { getAccountTree } from 'state/AccountTree';
import { NotFoundMessage } from './components/NotFoundMessage';
import { RouteComponentProps, withRouter } from 'react-router';
import { DSTIcon } from 'element/DSTIcon';
import { IsAppMarket, SimpleBadge } from 'element';
import { NotesIcon } from '../../../../../../../../element/NotesIcon';
import { Avatar } from 'oxygen-elements';

type StateProps = {
  pager: ShiftOffersWithPagerSelector;
  sort: SortShiftOffers;
  isUpdating: boolean;
  dateFormat: string;
  timeFormat: string;
  userList: StringMap<UserFields>;
  sites: StringMap<AccountTreeSite>;
  areas: StringMap<AccountTreeArea>;
  roles: StringMap<AccountTreeRole>;
};

type DispatchProps = {
  changeOrder: (payload: SortShiftOffers) => void;
};

type Props = StateProps & DispatchProps & RouteComponentProps;

const COLUMNS: { [key in SortShiftOffersFields]: number } = {
  shift_start: 0,
  offer_start: 2,
};

class ShiftOffersListTableComponent extends Component<Props> {
  render() {
    const { sort, pager, isUpdating } = this.props;

    return (
      <LoadingOverlay isLoading={isUpdating}>
        {pager.total ? (
          <ListTable
            data={pager.page}
            onSort={this.onSort}
            sortColumn={COLUMNS[sort.column]}
            sortDirection={sort.direction}
            icon={this.renderIcon}
            onRowClick={this.onRowClick}
          >
            <ListTable.Column
              label="Shift"
              render={this.renderShift}
              sortable={true}
            />

            <ListTable.Column
              label="Assigned to"
              render={this.renderAssignedTo}
              sortable={false}
            />

            <ListTable.Column
              label="Offered date"
              render={this.renderOfferedDate}
              sortable={true}
            />
          </ListTable>
        ) : (
          <NotFoundMessage />
        )}
      </LoadingOverlay>
    );
  }

  private onSort = onSortCreator(this.props.changeOrder, COLUMNS);

  private renderShift = (
    {
      site_id,
      area_id,
      role_id,
      shift_start,
      shift_end,
      timezone,
      is_dst_intersect,
      notes,
    }: ShiftOffer,
    rowIndex: number
  ) => {
    const { sites, areas, roles, dateFormat, timeFormat } = this.props;
    const roleName = roles[role_id].name;
    const areaName = areas[area_id].name;
    const siteName = sites[site_id].name;

    return (
      <>
        <div>{`${roleName} - ${areaName}, ${siteName}`}</div>
        <div data-testid="offer-start-date">
          {shift_start.format(`ddd, ${dateFormat}`)}
        </div>
        <div data-testid="offer-shift-time">
          {shift_start.format(timeFormat)} - {shift_end.format(timeFormat)}
          <DSTIcon isDST={is_dst_intersect} offsetLeft={2} />
        </div>
        <IsAppMarket market={'uk'} negate={true}>
          <Text id={`timezone${rowIndex}`} color="gray" size="sm">
            {timezone}
          </Text>
        </IsAppMarket>
        <div>
          <NotesIcon notes={notes} />
        </div>
      </>
    );
  };

  private renderAssignedTo = ({ user_id }: ShiftOffer) => {
    if (!user_id) {
      return <div data-testid="assigned-to">Unassigned</div>;
    }

    const user: UserFields = this.props.userList[user_id];

    return (
      <div data-testid="assigned-to">
        <Avatar
          src={user.avatar.src}
          alt={getUserName(user)}
          size={'small'}
          light
        />
      </div>
    );
  };

  private renderOfferedDate = ({ offer_start }: ShiftOffer) => {
    return (
      <>
        <div data-testid="offered-date">
          {offer_start.format(`ddd, ${this.props.dateFormat}`)}
        </div>
        <div data-testid="offered-time">
          {offer_start.format(this.props.timeFormat)}
        </div>
      </>
    );
  };

  private renderIcon = (shiftOffer: ShiftOffer, rowIndex: number) => {
    const usersCount = shiftOffer.proposal_ids.length;

    return (
      <SimpleBadge
        id={`responses${rowIndex}`}
        type={usersCount ? 'info' : 'grey'}
        label={usersCount.toString(10)}
      />
    );
  };

  private onRowClick = ({ id }: ShiftOffer) => {
    this.props.history.push(
      privateRoutes.managerDashboard.routes.shiftOffers.routes.shiftOffer.path(
        id
      )
    );
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  pager: shiftOffersWithPagerSelector(state),
  sort: getSort(state),
  isUpdating: getIsUpdating(state),
  dateFormat: getDateFormat(state),
  timeFormat: timeFormatSelector(state),
  userList: userListSelector(state),
  ...getAccountTree(state),
});

const mapDispatchToProps: DispatchProps = {
  changeOrder: BOX_SHIFT_OFFERS_CHANGE_ORDER,
};

export const ShiftOffersListTable = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShiftOffersListTableComponent)
);
