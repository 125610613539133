import React from 'react';
import Radio from '../../Radio';
import RadioButtons from '../../RadioButtons';
import { FilterStatus } from '../type';
import FilterBadge from './FilterBadge';
import './FilterButtons.scss';

export type FilterButtonsProps = {
  /** The statuses to show in the buttons */
  statuses: FilterStatus[];
  /** Callback when a new button is clicked */
  onChange: (status: string | number) => void;
  /** The currently selected status */
  selected: string | number | null;
};

function FilterButtons({ statuses, onChange, selected }: FilterButtonsProps) {
  return (
    <RadioButtons
      className="elmo-filter__buttons"
      onChange={onChange}
      selected={selected}
      type="solid"
    >
      {statuses.map((status: FilterStatus, index: number) => {
        return (
          <Radio value={status.value} key={index}>
            <span className="label">{status.label}</span>
            {status.count !== undefined && (
              <FilterBadge colour={status.colour}>{status.count}</FilterBadge>
            )}
          </Radio>
        );
      })}
    </RadioButtons>
  );
}

export default FilterButtons;
