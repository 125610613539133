import { withHeading } from 'element';
import UsersList from 'page/Users/page/Users';
import Profile from 'page/Users/page/User/page/Profile';
import Entitlements from 'page/Users/page/User/page/Entitlements';

export const users = {
  path: '/app/users',
  permission: 'users' as 'users',
  routes: {
    user: {
      path: (userId: string = ':userId') => `/app/users/${userId}`,
      routes: {
        entitlements: {
          path: (userId: string = ':userId') =>
            `/app/users/${userId}/entitlements`,
          component: withHeading(Entitlements, {
            title: 'User profile | Entitlements',
          }),
        },
        profile: {
          path: (userId: string = ':userId') => `/app/users/${userId}`,
          component: withHeading(Profile, {
            title: 'User profile',
          }),
        },
      },
    },
    users: {
      path: '/app/users',
      component: withHeading(UsersList, {
        title: 'People',
      }),
    },
  },
};
