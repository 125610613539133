import { Api } from 'lib/Api';
import { SagaIterator } from 'redux-saga';
import { put, select } from 'redux-saga/effects';
import { hasPermissionSelector } from 'state/Auth';
import { apiCall, apiWithConfirmCall } from 'state/ProcessApiRequest';
import { ApiReturnType, ApiWithConfirmReturnType } from 'type';
import { BOX_ROSTERED_SHIFTS_UPDATE_CONNECTED_TIMESHEET } from '../RosteredShifts/actions';
import {
  BOX_TIMESHEET_GET_LIST_SUCCESS,
  BOX_TIMESHEET_UPDATE_ONE,
} from './actions';
import {
  CreateTimesheetRequestPayload,
  GetTimesheetListRequestPayload,
} from './types';

export const getTimesheetListRequest = function* (
  payload: GetTimesheetListRequestPayload
): SagaIterator {
  const canViewTimesheets: boolean = yield select(
    hasPermissionSelector,
    'roster.timesheet.view'
  );

  const timesheets: ApiReturnType<typeof Api.Timesheet.getList> =
    canViewTimesheets ? yield apiCall(Api.Timesheet.getList, payload) : {};

  yield put(
    BOX_TIMESHEET_GET_LIST_SUCCESS({
      payload,
      timesheets,
    })
  );
};

export const createTimesheetRequest = function* (
  payload: CreateTimesheetRequestPayload
): SagaIterator<
  ApiWithConfirmReturnType<typeof Api.Timesheet.createTimesheet>
> {
  const response: ApiWithConfirmReturnType<
    typeof Api.Timesheet.createTimesheet
  > = yield apiWithConfirmCall(Api.Timesheet.createTimesheet, payload);

  if (response) {
    yield put(BOX_TIMESHEET_UPDATE_ONE(response));
    if (response.rostered_shift_id) {
      yield put(BOX_ROSTERED_SHIFTS_UPDATE_CONNECTED_TIMESHEET(response));
    }
  }

  return response;
};
