import { StoreState } from 'state/types';
import { createSelector } from 'reselect';
import { RosteredShift, ShiftTemplateItem, Timesheet } from 'type';
import { StringMap } from '../../../type';
import { BulkSelectionPropsItem, DeleteShiftItem } from './types';
import { timesheetsByAreaIdByRoleIdSelector } from '../../TimesheetsCollection';
import {
  getFrom,
  rosteredShiftsByAreaIdByRoleIdSelector,
} from '../../RosteredShifts';
import moment, { Moment } from 'moment';
import { getTemplateShiftsArray } from '../EditTemplate/selectors';
import {
  filteredRosteredShiftsByUserIdSelector,
  filteredTimesheetsByUserIdSelector,
  filtersByTypeSelector,
  RosterFiltersByType,
} from 'state/Roster/RosterFilters';

export const getIsBulkDeleteOpened = (state: StoreState): boolean =>
  state.rosterBulkActions.bulkDelete.isOpened;
const getShifts = (state: StoreState) =>
  state.rosterBulkActions.bulkDelete.shifts;
export const isBulkDeleting = (state: StoreState) =>
  state.rosterBulkActions.bulkDelete.confirmDeletionModal.isDeleting;
export const bulkDeleteErrors = (state: StoreState) =>
  state.rosterBulkActions.bulkDelete.confirmDeletionModal.errors;

export const bulkDeleteIdsArraySelector = createSelector(getShifts, (shifts) =>
  shifts.map((item) => item.id)
);
export const getBulkDeleteRosteredShiftsIdsArray = (
  state: StoreState
): string[] => {
  const shifts = state.rosterBulkActions.bulkDelete.shifts.filter(
    (item) => item.type === 'rostered_shift'
  );
  return shifts.length ? shifts.map((item) => item.id) : [];
};

export const getNotDeletedShifts = (state: StoreState): string[] =>
  state.rosterBulkActions.bulkDelete.notDeleted;

export const getSelectedByUser = (state: StoreState): BulkSelectionPropsItem =>
  state.rosterBulkActions.bulkDelete.selectionBy.users;

export const getSelectedByAreaRole = (
  state: StoreState
): BulkSelectionPropsItem =>
  state.rosterBulkActions.bulkDelete.selectionBy.areas;

export const getSelectionByUser = (userId: string | null) =>
  createSelector<
    StoreState,
    StringMap<RosteredShift[]>,
    StringMap<Timesheet[]>,
    Moment,
    RosterFiltersByType,
    DeleteShiftItem[]
  >(
    filteredRosteredShiftsByUserIdSelector,
    filteredTimesheetsByUserIdSelector,
    getFrom,
    filtersByTypeSelector,
    (r, t, weekFrom, filters) => {
      const shifts = filters.roster && r[`${userId}`] ? r[`${userId}`] : [];
      const timesheets =
        filters.timesheets && userId && t[userId] ? t[userId] : [];
      const all = [...shifts, ...timesheets];
      const weekStart = moment
        .parseZone(weekFrom)
        .startOf('isoWeek')
        .startOf('day');
      const result: DeleteShiftItem[] = [];
      all.forEach((item) => {
        if (
          item.start.format('YYYY-MM-DD HH:mm') >=
          weekStart.format('YYYY-MM-DD HH:mm')
        ) {
          result.push({
            id: item.id,
            type: item.hasOwnProperty('timesheet_id')
              ? 'rostered_shift'
              : 'timesheet',
            user_id: item.user_id,
            area_id: item.area_id,
            role_id: item.role_id,
          });
        }
      });
      return result;
    }
  );

export const getSelectionByAreaRole = (areaId: string, roleId: string) =>
  createSelector<
    StoreState,
    StringMap<StringMap<RosteredShift[]>>,
    StringMap<StringMap<Timesheet[]>>,
    Moment,
    RosterFiltersByType,
    DeleteShiftItem[]
  >(
    rosteredShiftsByAreaIdByRoleIdSelector,
    timesheetsByAreaIdByRoleIdSelector,
    getFrom,
    filtersByTypeSelector,
    (r, t, weekFrom, filters) => {
      const shifts =
        filters.roster && r[areaId] && r[areaId][roleId]
          ? r[areaId][roleId]
          : [];
      const timesheets =
        filters.timesheets && t[areaId] && t[areaId][roleId]
          ? t[areaId][roleId]
          : [];
      const all = [...shifts, ...timesheets];
      const weekStart = moment
        .parseZone(weekFrom)
        .startOf('isoWeek')
        .startOf('day');
      const result: DeleteShiftItem[] = [];
      all.forEach((item) => {
        if (
          item.start.format('YYYY-MM-DD HH:mm') >=
          weekStart.format('YYYY-MM-DD HH:mm')
        ) {
          result.push({
            id: item.id,
            type: item.hasOwnProperty('timesheet_id')
              ? 'rostered_shift'
              : 'timesheet',
            user_id: item.user_id,
            area_id: item.area_id,
            role_id: item.role_id,
          });
        }
      });
      return result;
    }
  );

export const getTemplatedSelectionByUser = (userId: string | null) =>
  createSelector<StoreState, ShiftTemplateItem[], DeleteShiftItem[]>(
    getTemplateShiftsArray,
    (r) => {
      const result: DeleteShiftItem[] = [];
      r.forEach((item) => {
        if (item.user_id === userId) {
          result.push({
            id: item.id,
            type: 'rostered_shift',
            user_id: item.user_id,
            area_id: item.area_id,
            role_id: item.role_id,
          });
        }
      });
      return result;
    }
  );

export const getTemplatedSelectionByAreaRole = (
  areaId: string,
  roleId: string
) =>
  createSelector<StoreState, ShiftTemplateItem[], DeleteShiftItem[]>(
    getTemplateShiftsArray,
    (r) => {
      const result: DeleteShiftItem[] = [];
      r.forEach((item) => {
        if (item.area_id === areaId && item.role_id === roleId) {
          result.push({
            id: item.id,
            type: 'rostered_shift',
            user_id: item.user_id,
            area_id: item.area_id,
            role_id: item.role_id,
          });
        }
      });
      return result;
    }
  );
