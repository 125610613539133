import { useSelector } from 'react-redux';
import { useSelectorWithProps } from 'hooks';
import { hasPermissionSelector } from 'state/Auth';
import { getIsLoading } from 'state/Roster/RangeInput/ShiftTimeRangeInput';
import { getIsBulkDeleteOpened } from 'state/Roster/BulkActions';
import { getTimesheetUserId } from 'state/TimesheetsCollection';
import { getUserIsActiveSafe } from 'state/UsersCollection';

type Props = {
  id: string;
};

export const useCanEditTimesheet = ({ id }: Props) => {
  const canEditTimesheet = useSelectorWithProps(
    hasPermissionSelector,
    'roster.timesheet.edit'
  );
  const inlineIsLoading = useSelector(getIsLoading);
  const isBulkDelete = useSelector(getIsBulkDeleteOpened);
  const userId = useSelectorWithProps(getTimesheetUserId, id);
  const isUserActive = useSelectorWithProps(getUserIsActiveSafe, userId);
  // whether the timesheet is in progress is checked later in order for the type checking to work correctly.

  return (
    canEditTimesheet && !inlineIsLoading && !isBulkDelete && !!isUserActive
  );
};
