import { DateRangeFieldGroupErrors, DateRangeFieldGroupValue } from '../types';
import { getDateRangeMoment, isValidDateRangeGuard } from '../utils';
import startEndFieldsValidator from './startEndFieldsValidator';
import dateRangeValidator from './dateRangeValidator';

export default dateRangeFieldGroupValidator;

export function dateRangeFieldGroupValidator(
  formFields: DateRangeFieldGroupValue
): DateRangeFieldGroupErrors {
  return {
    ...startEndFieldsValidator(formFields),
    ...(isValidDateRangeGuard(formFields) &&
      dateRangeValidator(getDateRangeMoment(formFields))),
  };
}
