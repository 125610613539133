import React from 'react';
import classNames from 'clsx';
import { DefaultDivProps } from 'type';
import './refactored-styles.scss';
import { ShiftCardButtonDelete } from './ShiftCardButtonDelete';
import { ShiftCardPopperAdd } from './ShiftCardPopperAdd';
import ShiftCardLocation from './components/ShiftCardLocation';
import { ShiftCardTimeInfo } from './components/ShiftCardTimeInfo';
import { ShiftCardEventName } from './components/ShiftCardEventName';
import { ShiftCardTitle } from './components/ShiftCardTitle';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';

export const shiftCardViewClass = 'js-shift-card-view';

export type ShiftCardViewProps = DefaultDivProps & {
  colorId: number | undefined;
  coloredBg: boolean;
  from: string;
  to: string;
  isSelected?: boolean;
  isHighlighted?: boolean;
  icons: React.ReactNode;
  breaks: React.ReactNode;
  isLocationShown: boolean;
  timeColor?: 'default' | 'error';
  beforeContent?: React.ReactNode;
  title: string;
  spotTitle?: string;
  shape?: 'full' | 'top' | 'bottom';
  fullHeight?: boolean;
  withoutShadow?: boolean;
  onClickAddButton?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onClickDeleteIcon?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  eventName?: false | string;
};

const useContentStyles = makeStyles((theme) => createStyles({
  card: {
    boxShadow: '1px 1px 1px #dbdbdb',
    height: 'inherit',
    userSelect: 'none',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: '#f5f5f5',
      '& .shift-card-view__button-delete': {
        opacity: 1,
        visibility: 'visible'
      },
      '& .shift-card-view__add-btn': {
        opacity: 1,
        visibility: 'visible'
      }
    }
  },
  contentWrapper: {
    minHeight: '56px',
    display: 'flex',
    padding: 0,
    minWidth: '60px',
    height: 'inherit'
  },
  description: {
    lineHeight: 1,
    flex: '1 1 0%',
    minWidth: 0,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '4px 4px 1px',
    '& p': {
      color: '#000'
    }
  },
  row: {
    display: 'flex',
    alignItems: 'baseline',
    minHeight: '33%',
    position: 'relative'
  },
  middleRow: {
    minHeight: '33%',
    position: 'relative'
  },
  customHeight: {
    minHeight: '25%'
  }
}));

export const ShiftCardViewRefactored = ({
  colorId,
  coloredBg,
  shape = 'full',
  fullHeight = false,
  title,
  icons,
  from,
  to,
  withoutShadow,
  isSelected,
  isHighlighted,
  spotTitle,
  breaks,
  onClickAddButton,
  onClickDeleteIcon,
  eventName,
  isLocationShown,
  timeColor,
  beforeContent,
  ...divProps
}: ShiftCardViewProps) => {
  const styles =  useContentStyles();
  return (
    <div
      {...divProps}
      className={classNames(
        divProps.className,
        shiftCardViewClass,
        'shift-card-view',
        `shift-card-view--${shape}`,
        `role-color-${colorId}-primary`,
        styles.card,
        {
          'shift-card-view--clickable': divProps.onClick,
          'shift-card-view--colored': typeof colorId === 'number',
          'shift-card-view--filled-bg': coloredBg,
          'shift-card-view--without-shadow': withoutShadow,
          'shift-card-view--full-height': fullHeight,
          'shift-card-view--selected': isSelected,
          'shift-card-view--highlighted': isHighlighted,
        }
      )}
    >
      {beforeContent}
      <div className={clsx(styles.contentWrapper, 'shift-card-view__content')}>
        <ShiftCardLocation transparent={!isLocationShown} />
        <div className={clsx(styles.description, 'shift-card-view__descr')}>
          <div
            className={classNames(styles.row, 'shift-card-view__row', {
              [styles.customHeight]: eventName,
            })}
          >
            <ShiftCardTimeInfo
              timeColor={timeColor}
              from={from}
              to={to}
            />
            {
              !!onClickDeleteIcon && <ShiftCardButtonDelete
                className="shift-card-view__button-delete"
                onClick={onClickDeleteIcon}
              />
            }
          </div>
          <>
            <div
              className={classNames(styles.middleRow, 'shift-card-view__row', {
                [styles.customHeight]: eventName,
              })}
            >
              <ShiftCardTitle title={title} spotTitle={spotTitle} />
            </div>
            {
              eventName && <div
                className={classNames(
                  styles.middleRow,
                  'shift-card-view__row',
                  styles.customHeight
                )}
              >
                <ShiftCardEventName eventName={eventName} />
              </div>
            }
            <div
              className={classNames(
                styles.row,
                'shift-card-view__row',
                'justify-content-between',
                'align-items-center',
                {
                  [styles.customHeight]: eventName,
                }
              )}
            >
              {breaks}
              <div className="shift-card-view__icons">{icons}</div>
            </div>
          </>
        </div>
      </div>
      {
        onClickAddButton && <ShiftCardPopperAdd
            onClick={onClickAddButton}
            className="shift-card-view__add-btn"
          >
            + Add another shift
          </ShiftCardPopperAdd>
      }
    </div>
  );
};
