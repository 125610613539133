import {
  useActions,
  useFormFields,
  useFormValidator,
  useUpdateStatusEffect,
} from 'hooks';
import useRoute from 'hooks/useRoute';
import { capitalize } from 'lib/helpers';
import marketLabels from 'marketLabels';
import {
  Divider,
  makeDefaultBreakRuleFields,
  SettingsLayout,
  Spacing,
} from 'page/Settings/components';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { privateRoutes } from 'routes';
import {
  BOX_ACCOUNT_ROSTERED_SHIFT_SETTINGS_UPDATE,
  getCustomBreaksStatus,
  getDefaultBreaksStatus,
  getRosteredShiftSettings,
} from 'state/Account';
import DefaultBreaksFieldGroup from './DefaultBreaksFieldGroup';
import makeFormFields from './makeFormFields';
import makePayload from './makePayload';
import OtherRosterSettingsFieldGroup from './OtherRosterSettingsFieldGroup';
import ShiftOffersFieldGroup from './ShiftOffersFieldGroup';
import ShiftSwapsFieldGroup from './ShiftSwapsFieldGroup';
import validate from './validate';

export default function RosterSettings() {
  const settingsUpdate = useActions(
    BOX_ACCOUNT_ROSTERED_SHIFT_SETTINGS_UPDATE.request
  );

  const rosteredShiftSettings = useSelector(getRosteredShiftSettings);
  const route = useRoute();

  const { formFields, setFormFields } = useFormFields(() =>
    makeFormFields(rosteredShiftSettings)
  );

  const { formErrors, submitForm } = useFormValidator(formFields, {
    validate,
    makePayload,
    onSubmit: settingsUpdate,
  });

  useUpdateStatusEffect(getDefaultBreaksStatus, {
    success: () => {
      setFormFields({
        default_breaks_rules: makeDefaultBreakRuleFields(
          rosteredShiftSettings.default_breaks_rules
        ),
      });
    },
  });

  useUpdateStatusEffect(getCustomBreaksStatus, {
    success: () => {
      setFormFields({
        default_breaks_rules: makeDefaultBreakRuleFields(
          rosteredShiftSettings.default_breaks_rules
        ),
      });
    },
  });

  const handleSuccess = () => {
    route.goTo(privateRoutes.settings.path);
  };

  return (
    <SettingsLayout
      title={`${capitalize(marketLabels.roster)} Settings`}
      onSubmit={submitForm}
      onSuccess={handleSuccess}
    >
      <DefaultBreaksFieldGroup
        values={formFields}
        onChange={setFormFields}
        errors={formErrors}
      />

      <Divider />

      <ShiftSwapsFieldGroup
        values={formFields}
        onChange={setFormFields}
        errors={formErrors}
      />

      <Divider />

      <ShiftOffersFieldGroup
        values={formFields}
        onChange={setFormFields}
        errors={formErrors}
      />

      <Divider />

      <OtherRosterSettingsFieldGroup
        values={formFields}
        onChange={setFormFields}
        errors={formErrors}
      />
    </SettingsLayout>
  );
}
