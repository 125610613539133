import React, { Component } from 'react';
import { Button, DataTable as Table, Modal, Search } from 'elmo-elements';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import {
  AccountTreeRole,
  Event,
  LanguagePreferences,
  RosteredShift,
} from 'type/models';
import { StringMap } from 'type';
import { getSiteId } from 'state/RosteredShifts';
import { ArrowBackIcon } from 'element/icons';
import { getEventModalShifts } from 'state/Roster/EventModal';
import { getRoles } from 'state/AccountTree';
import { capitalize } from 'lib/helpers';
import { getLangPreferences } from 'state/Account';
import { compareValueWithSearchQuery } from '../../../../../state/helpers';
import { DialogActions, DialogTitle } from 'oxygen-elements';
import {
  PageDialog,
  PageDialogBackIconButton,
  PageDialogCancel,
} from '../../../../page-dialog-components';
import { DialogContent } from 'extended-oxygen-elements';

type Position = {
  role: string;
  shifts: number;
  users: number;
  usersIds: (string | null)[];
};

type OwnProps = {
  isOpened: boolean;
  closeModal: () => void;
  event: Event;
  showSearch: boolean;
};

type StateProps = {
  shift: RosteredShift;
  siteId: string;
  shifts: StringMap<RosteredShift>;
  langPreferences: LanguagePreferences;
  roles: StringMap<AccountTreeRole>;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

type State = {
  searchTerm: string;
};

export class PositionsModal extends Component<Props, State> {
  readonly state: State = {
    searchTerm: '',
  };

  render() {
    const {
      filteredPositions,
      props: { isOpened, langPreferences },
    } = this;
    return (
      <PageDialog
        id={'event-position-modal'}
        maxWidth={'sm'}
        open={isOpened}
        className={'event-position-modal'}
      >
        <DialogActions
          className={'shift-modal-header'}
          sx={{ boxShadow: '0 0 6px 2px #ccc', zIndex: 3, padding: 0 }}
        >
          <DialogTitle>
            <PageDialogBackIconButton onClose={this.closeModal} />
            {`${capitalize(langPreferences.role.plural)}`}
          </DialogTitle>
        </DialogActions>
        <DialogContent className={'shift-modal-content'}>
          {this.renderSearch()}

          {filteredPositions.length ? (
            this.renderPositionsList(filteredPositions)
          ) : (
            <div data-testid="event-position-modal-no-roles-message">
              No {langPreferences.role.plural} available
            </div>
          )}
        </DialogContent>
        <DialogActions sx={{ boxShadow: '0 0 6px 2px #ccc', zIndex: 3 }}>
          <PageDialogCancel
            onClick={this.closeModal}
            fullWidth={false}
            size={'large'}
          >
            Cancel
          </PageDialogCancel>
        </DialogActions>
      </PageDialog>
    );
  }

  closeModal = () => {
    this.setState(
      (prevState) => ({
        ...prevState,
        searchTerm: '',
      }),
      () => {
        this.props.closeModal();
      }
    );
  };

  get columns() {
    const { role } = this.props.langPreferences;

    return [
      {
        title: capitalize(role.plural),
        width: '60%',
        textAlign: 'left',
      },
      {
        title: 'Shifts',
        width: '20%',
        textAlign: 'right',
      },
      {
        title: 'Users',
        width: '20%',
        textAlign: 'right',
      },
    ];
  }

  get positions(): Position[] {
    const {
      event: { rostered_shift_ids },
      shifts,
      roles,
    } = this.props;

    const positions: StringMap<Position> = {};

    if (Object.keys(shifts).length) {
      rostered_shift_ids.forEach((shiftId) => {
        const shift = shifts[shiftId];

        if (shift) {
          const role = roles[shift.role_id];

          if (typeof positions[role.name] === 'undefined') {
            positions[role.name] = {
              role: role.name,
              shifts: 1,
              users: 1,
              usersIds: [shift.user_id],
            };
          } else {
            positions[role.name].shifts += 1;

            if (positions[role.name].usersIds.indexOf(shift.user_id) === -1) {
              positions[role.name].usersIds.push(shift.user_id);
              positions[role.name].users += 1;
            }
          }
        }
      });
    }
    return Object.values(positions);
  }

  get filteredPositions() {
    const { searchTerm } = this.state;

    return this.positions.filter((position: Position) =>
      compareValueWithSearchQuery(position.role, searchTerm)
    );
  }

  onSearch = (searchTerm: string) => {
    this.setState(() => ({
      searchTerm,
    }));
  };

  renderSearch = () => {
    return (
      <div className={'users-modal-search'}>
        <Search
          isVisible={this.props.showSearch}
          onChange={this.onSearch}
          value={this.state.searchTerm}
          onSubmit={this.onSearchSubmit}
        />
      </div>
    );
  };

  onSearchSubmit = () => false;

  renderPositionsList = (positions: Position[]) => {
    return (
      <Table id="t1" isFullWidth={true}>
        <Table.Header columns={this.columns} />
        <Table.Body>
          {positions.map((position, key) => {
            return (
              <Table.Tr key={key}>
                <Table.Td>{position.role}</Table.Td>
                <Table.Td textAlign="right">{position.shifts}</Table.Td>
                <Table.Td textAlign="right">{position.users}</Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Body>
      </Table>
    );
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  shift: state.rosterShiftModal.currentRosteredShift,
  siteId: getSiteId(state),
  shifts: getEventModalShifts(state),
  roles: getRoles(state),
  langPreferences: getLangPreferences(state),
});

export default connect(mapStateToProps)(PositionsModal);
