import { useSelector } from 'react-redux';
import {
  getContactedShifts,
  getCovidPointedShiftId,
  getIsCovidModeEnabled,
} from 'state/Roster/CovidTracing';

type Props = {
  id: string;
};

export const useCovidClassName = ({ id }: Props) => {
  const isCovidMode = useSelector(getIsCovidModeEnabled);
  const covidPointedId = useSelector(getCovidPointedShiftId);
  const contactedShifts = useSelector(getContactedShifts);
  const isCloseContacted = contactedShifts.all.includes(id);
  if (isCovidMode && covidPointedId === id) {
    return 'marked-covid';
  }
  if (isCovidMode && isCloseContacted) {
    return 'marked-contacted';
  }
  return '';
};
