import { createReducer } from 'lib/store-utils';
import { omit } from 'lodash';
import * as timesheetsWeeklyPage from 'state/TimesheetsWeeklyPage';
import * as actions from './actions';
import { getDefaultState } from './state';
import { UsersCollectionReducerState } from './types';

export const usersCollection = createReducer<UsersCollectionReducerState>(
  {},
  getDefaultState()
);

usersCollection.on(
  actions.BOX_USERS_COLLECTION_SET_LIST,
  (state, userListResponse): UsersCollectionReducerState => ({
    ...state,
    userListResponse,
  })
);

usersCollection.on(
  actions.BOX_USERS_COLLECTION_USER_CHANGED,
  (state, user): UsersCollectionReducerState => {
    if (user.is_passive) {
      // remove user or do not add him
      return {
        ...state,
        userListResponse: state.userListResponse[user.id]
          ? omit(state.userListResponse, user.id)
          : state.userListResponse,
      };
    }
    return {
      ...state,
      userListResponse: {
        ...state.userListResponse,
        [user.id]: user,
      },
    };
  }
);

usersCollection.on(
  actions.BOX_USERS_COLLECTION_SET_OR_DELETE_ONE,
  (state, { id, user }): UsersCollectionReducerState => ({
    ...state,
    userListResponse: user
      ? {
          ...state.userListResponse,
          [user.id]: user,
        }
      : omit(state.userListResponse, id),
  })
);

usersCollection.on(
  timesheetsWeeklyPage.BOX_TIMESHEETS_WEEKLY_PAGE_DATA_GET.success,
  (state, { users }): UsersCollectionReducerState => ({
    ...state,
    userListResponse: users,
  })
);

usersCollection.on(
  timesheetsWeeklyPage.BOX_TIMESHEETS_WEEKLY_PAGE_DATA_UPDATE.success,
  (state, { users }): UsersCollectionReducerState => ({
    ...state,
    userListResponse: users,
  })
);
