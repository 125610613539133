import { createReducer } from 'lib/store-utils';
import {
  BOX_PERMISSIONS_REQUEST,
  BOX_PERMISSIONS_SUCCESS,
  BOX_PERMISSIONS_FAILURE
} from './actions';
import { defaultState, PermissionsReducerState } from './state';

export const permissions = createReducer<PermissionsReducerState>({}, defaultState);

permissions.on(BOX_PERMISSIONS_REQUEST, state => ({
  ...state,
  isLoading: true,
  errors: []
}));

permissions.on(BOX_PERMISSIONS_SUCCESS, (state, payload) => ({
  ...state,
  isLoading: false,
  isFetched: true,
  permissions: payload,
  errors: []
}));

permissions.on(BOX_PERMISSIONS_FAILURE, state => ({
  ...state,
  isLoading: false
}));
