import React from 'react';
import DispatchBeforeHandler, {
  DispatchBeforeHandlerProps,
} from './DispatchBeforeHandler';
import PreloadingHandler, {
  PreloadingHandlerProps,
} from '../PreloadingHandler';
import FetchErrorsHandler, {
  FetchErrorsHandlerProps,
} from '../FetchErrorsHandler';

type FetchContainerProps = DispatchBeforeHandlerProps &
  PreloadingHandlerProps &
  FetchErrorsHandlerProps;

export function FetchContainer({
  children,
  ...restProps
}: FetchContainerProps) {
  return (
    <DispatchBeforeHandler {...restProps}>
      <PreloadingHandler {...restProps}>
        <FetchErrorsHandler {...restProps}>{children}</FetchErrorsHandler>
      </PreloadingHandler>
    </DispatchBeforeHandler>
  );
}
