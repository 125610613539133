import React from 'react';
import { Col, Row } from 'elmo-elements';
import { Hours, UpcomingShifts } from './components';

const OverviewView = () => {
  return (
    <Row isNoGutters={false} role="presentation">
      <Col className="demo-gird-col" md={16} role="presentation">
        <Hours type="day" />
        <Hours type="week" />
      </Col>
      <Col className="demo-gird-col" md={8} role="presentation">
        <UpcomingShifts />
      </Col>
    </Row>
  );
};

export default OverviewView;
