import React, { ReactNode } from 'react';
import { Duration, Moment } from 'moment';
import { IsResizable, OnResizeEndPayload } from '../../types';
import { limitByDay, getOnResizeEnd, getDurations } from './hooks';

type Props = {
  start: Moment;
  end: Moment;
  day: Moment;
  onResizeEnd?: (payload: OnResizeEndPayload) => void;
  isResizable?: boolean;
  children: (
    payload: {
      start: Duration;
      end: Duration;
      onResizeEnd: (
        payload: { id: string; start: Duration; end: Duration }
      ) => void;
      isResizable: IsResizable;
    }
  ) => ReactNode;
};

export const DayLimit = (props: Props) => {
  const { start, end, isResizable } = limitByDay({
    start: props.start,
    end: props.end,
    day: props.day
  });
  const onResizeEnd = getOnResizeEnd({
    day: props.day,
    onResizeEnd: props.onResizeEnd,
    initialStartEnd: {
      start: props.start,
      end: props.end
    },
    isResizable
  });
  const durationsStartEnd = getDurations({ start, end }, props.day);
  const { isResizable: initIsResizable = true } = props;

  return (
    <>
      {props.children({
        ...durationsStartEnd,
        onResizeEnd,
        isResizable: initIsResizable
          ? isResizable
          : {
              right: false,
              left: false
            }
      })}
    </>
  );
};
