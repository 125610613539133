import React, { Component } from 'react';
import { privateRoutes } from 'routes';
import { RosterViewScrollableWrapper } from '../../../../components';
import { WeekNavigation, WithShiftRangeInput } from '../../components';
import View from './view';
import { FullScreenLoadingOverlay } from 'element/FullScreenLoadingOverlay';
import ShiftOverlapConfirmationModal from 'element/shiftModals/ShiftOverlapConfirmationModal';
import TimesheetOverlapConfirmationModal from 'element/shiftModals/TimesheetOverlapConfirmationModal';
import { getBulkCreateShiftsInProgress } from 'state/RosteredShifts';
import { contextInProgress } from 'state/Roster/ContextualMenu/selectors';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import { getTimesheetModal } from 'state/TimesheetModal';
import { TimesheetModalProps } from 'state/TimesheetModal/types';
import {
  BOX_ROSTERED_SHIFT_CLOSE_OVERLAP_MODAL,
  BOX_ROSTERED_SHIFT_CONFIRM_OVERLAP,
} from 'state/RosteredShifts/actions';
import { OverlapShiftProps } from 'state/RosteredShifts/types';
import { WeekEventsPanel } from '../../components/WeekEventsPanel';
import { getCurrentUserPermissions } from 'state/Auth';
import { PermissionName } from '../../../../../../type';
import { getIsBulkDeleteOpened } from 'state/Roster/BulkActions/selectors';
import {
  filtersByTypeSelector,
  RosterFiltersByType,
} from 'state/Roster/RosterFilters';
import { getIsLoadingCovidShifts } from 'state/Roster/CovidTracing';
import WeekSummary from '../../components/WeekSummary';

type DispatchProps = {
  submitOverlappedShift: () => void;
  closeOverlapModal: (isUpdating: boolean) => void;
};

type StateProps = {
  filters: RosterFiltersByType;
  overlapModal: OverlapShiftProps;
  dropInProgress: boolean;
  contextMenuPasteInProgress: boolean;
  timesheetModal: TimesheetModalProps;
  isBulkCreate: boolean;
  eventsEnabled: boolean;
  permissions: PermissionName[];
  isBulkDelete: boolean;
  isCovidLoading: boolean;
};

type Props = StateProps & DispatchProps;

class RosterWeekViewUserView extends Component<Props> {
  canViewEvents = () => {
    const { permissions, eventsEnabled } = this.props;
    return permissions.indexOf('roster.events.view') !== -1 && eventsEnabled;
  };

  render() {
    const {
      dropInProgress,
      contextMenuPasteInProgress,
      timesheetModal,
      isBulkCreate,
      isBulkDelete,
      filters,
      isCovidLoading,
    } = this.props;
    return (
      <RosterViewScrollableWrapper
        header={
          <WeekNavigation
            dayViewPath={
              privateRoutes.roster.routes.rosterDayView.routes
                .rosterDayViewUserView.path
            }
          />
        }
        scrollableAddonSelector={'.summary-days__content'}
      >
        <WeekSummary />
        {this.canViewEvents() && (
          <WeekEventsPanel isBulkDelete={isBulkDelete} filters={filters} />
        )}

        <WithShiftRangeInput>
          <View />
        </WithShiftRangeInput>

        {(dropInProgress ||
          contextMenuPasteInProgress ||
          isBulkCreate ||
          isCovidLoading) && <FullScreenLoadingOverlay />}

        <ShiftOverlapConfirmationModal
          overlapModal={this.props.overlapModal}
          confirmOverlap={this.props.submitOverlappedShift}
          closeModal={this.props.closeOverlapModal}
        />

        {!timesheetModal.isOpened && (
          <TimesheetOverlapConfirmationModal
            confirmOverlap={() => false}
            confirmApproveOverlap={() => false}
            confirmDeletion={() => false}
          />
        )}
      </RosterViewScrollableWrapper>
    );
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  dropInProgress: state.rosteredShifts.dropInProgress,
  isBulkCreate: getBulkCreateShiftsInProgress(state),
  contextMenuPasteInProgress: contextInProgress(state),
  timesheetModal: getTimesheetModal(state),
  overlapModal: state.rosteredShifts.overlapModal,
  eventsEnabled: state.account.account.event_settings.enable_events,
  permissions: getCurrentUserPermissions(state),
  isBulkDelete: getIsBulkDeleteOpened(state),
  filters: filtersByTypeSelector(state),
  isCovidLoading: getIsLoadingCovidShifts(state),
});

const mapDispatchToProps: DispatchProps = {
  submitOverlappedShift: BOX_ROSTERED_SHIFT_CONFIRM_OVERLAP,
  closeOverlapModal: BOX_ROSTERED_SHIFT_CLOSE_OVERLAP_MODAL,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RosterWeekViewUserView);
