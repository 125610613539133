import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';
import * as actions from '../actions';
import { createShiftPayloadWithDragging } from './createShiftWithDragging';
import { siteViewAddRow } from './siteViewAddRow';
import { updateRosterTime } from './updateRosterTime';
import { updateTimesheetTime } from './updateTimesheetTime';
import { eventsAddRow } from './eventsAddRow';
import { unassignedAddRow } from './unassignedAddRow';
import {
  dragAndDropCopySiteView,
  dragAndDropCopyUserView,
} from './dragAndDrop';

export const watchRosterDayView = function* (): SagaIterator {
  yield takeLatest(
    actions.BOX_ROSTER_DAY_VIEW_CREATE_SHIFT_START,
    createShiftPayloadWithDragging
  );
  yield takeLatest(
    actions.BOX_ROSTER_DAY_VIEW_SITE_VIEW_ROW_ADD,
    siteViewAddRow
  );
  yield takeLatest(
    actions.BOX_ROSTER_DAY_VIEW_UPDATE_ROSTERED_SHIFT_TIME_REQUEST,
    updateRosterTime
  );
  yield takeLatest(
    actions.BOX_ROSTER_DAY_VIEW_UPDATE_TIMESHEET_TIME_REQUEST,
    updateTimesheetTime
  );
  yield takeLatest(actions.BOX_ROSTER_DAY_VIEW_EVENTS_ROW_ADD, eventsAddRow);
  yield takeLatest(
    actions.BOX_ROSTER_DAY_VIEW_UNASSIGNED_ROW_ADD,
    unassignedAddRow
  );
  yield takeLatest(
    actions.BOX_ROSTER_DAY_VIEW_DRAG_AND_DROP_USER_VIEW_FINISHED,
    dragAndDropCopyUserView
  );
  yield takeLatest(
    actions.BOX_ROSTER_DAY_VIEW_DRAG_AND_DROP_SITE_VIEW_FINISHED,
    dragAndDropCopySiteView
  );
};
