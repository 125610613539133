import React from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from 'state/types';

export function useSelectorWithProps<RType extends any, Args extends any[]>(
  selector: (state: StoreState, ...args: Args) => RType,
  ...args: Args
): RType {
  const memoizedSelector = React.useCallback(
    (state: StoreState) => selector(state, ...args),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    args
  );

  return useSelector(memoizedSelector);
}

export default useSelectorWithProps;
