import React from 'react';
import { CheckCircleOutlinedIcon } from 'element';
import { IconButtonProps } from 'extended-oxygen-elements';
import { IconWrapper } from './components';

export function ApprovedIcon(props: IconButtonProps) {
  return (
    <IconWrapper {...props}>
      <CheckCircleOutlinedIcon />
    </IconWrapper>
  );
}
