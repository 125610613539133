import React from 'react';
import { Radio, RadioButtons } from 'elmo-elements';
import { SortIcon, useIconStyles } from 'element/icons';
import { SortDirection } from 'type';
import clsx from 'clsx';

type Props = {
  selected: SortDirection;
  onChange: (value: string | number) => void;
  id?: string;
};

const sortDirections: {
  flipped?: boolean;
  value: SortDirection;
}[] = [
  {
    flipped: true,
    value: 'asc',
  },
  {
    value: 'desc',
  },
];

export const SortDirectionRadioButtons = (props: Props) => {
  const classes = useIconStyles();

  return (
    <RadioButtons {...props} type="outlined">
      {sortDirections.map((sortField) => (
        <Radio value={sortField.value} key={sortField.value}>
          <SortIcon
            className={clsx({
              [classes.flippedY]: sortField.flipped,
            })}
          />
        </Radio>
      ))}
    </RadioButtons>
  );
};
