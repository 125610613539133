import React from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'extended-oxygen-elements';
import { IconButton } from 'extended-oxygen-elements';
import { EditOutlinedIcon } from 'element';
import { useEditDialogContext } from 'contexts';
import { BOX_TIMESHEETS_WEEKLY_DIALOG_OPENED } from 'state/TimesheetsWeeklyPage';

type Props = {
  timesheetId: string;
};

export const EditButton = ({ timesheetId }: Props) => {
  const { openDialog } = useEditDialogContext();
  const dispatch = useDispatch();

  const handleEditButtonClick = () => {
    dispatch(BOX_TIMESHEETS_WEEKLY_DIALOG_OPENED());
    openDialog(timesheetId);
  };

  return (
    <Tooltip title="Edit timesheet">
      <IconButton
        data-testid="hover-action-edit"
        onClick={handleEditButtonClick}
      >
        <EditOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
};
