import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RosteredShift } from 'type';
import { getIsApprovalModeEnabled } from 'state/Roster/Roster';
import { BOX_ROSTER_SHIFT_MODAL_GET_BY_ID } from 'state/Roster/RosterShiftModal';

const useHandleClick = (
  rosteredShift: RosteredShift
): React.MouseEventHandler<HTMLElement> | undefined => {
  const dispatch = useDispatch();

  const isApprovalMode = useSelector(getIsApprovalModeEnabled);
  const isClickable = !isApprovalMode;

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      const target = event.target as HTMLElement;
      if (
        !target.closest('.delete-shift-icon') &&
        !target.classList.contains('oxygen-dropdown-base__menu-item') // TODO refactor
      ) {
        dispatch(BOX_ROSTER_SHIFT_MODAL_GET_BY_ID(rosteredShift));
      }
    },
    [dispatch, rosteredShift]
  );

  if (isClickable) {
    return handleClick;
  }
};

export default useHandleClick;
