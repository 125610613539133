import { AxiosResponse } from 'axios';
import { ApiClient, SsoApiClient } from 'lib/api-clients';
import { SERVER_DATE_TIME_FORMAT, SERVER_DAY_FORMAT } from 'lib/config';
import _ from 'lodash';
import qs from 'qs';
import {
  EmployeeSpreadOfHoursResponse,
  EmployeeUnavailabilityProps,
  EmployeeUnavailabilityRequestPayload,
  EmployeeUnavailabilityResponse,
} from 'state/EmployeeDashboard/Unavailability/types';
import {
  GetOffersPayload,
  GetUnpublishedRostersListPayload,
  PublishRostersPayload,
  RefactoredPublishRostersPayload,
  RosterOffersPayload,
} from 'state/Roster/Roster/types';
import { UndoItem, UndoListPayload } from 'state/RosteredShifts/types';
import { Dictionary } from 'ts-essentials';
import {
  Account,
  AccountTree,
  AllowedToAssignShift,
  ApiClientResponse,
  Assignments,
  AuditTrail,
  AwardsList,
  AwardsSearchList,
  DateTimeType,
  EmployeeRosteredShift,
  EmployeeTimesheet,
  GeneratedReport,
  Notifications,
  Overview,
  PayElement,
  Profile,
  Report,
  RosteredShift,
  RosterTimesheetListed,
  RosterTimesheetListedWithPayEntries,
  RosterUser,
  ServerEmployeeTimesheet,
  ServerEvent,
  ServerPayEntry,
  ServerReport,
  ServerRosteredShift,
  ServerRosterRepeatableTimeOff,
  ServerShift,
  ServerShiftOffer,
  ServerTimeOff,
  ServerTimesheet,
  ServerUserFields,
  ServerUserGroup,
  ShiftOfferWithProposals,
  ShiftTradesList,
  StringMap,
  Timesheet,
  UpdateCurrentUserPayload,
  UserGroup,
  UserProfileFields,
} from 'type';
import {
  AcceptTradePayload,
  GetSwapProposalsPayload,
  ShiftSwapPayload,
  ShiftTradesRequestPayload,
  SwapProposalsResponse,
} from '../../state/EmployeeDashboard/ShiftTrades/types';
import {
  NotificationListType,
  NotificationsListRequestPayload,
  NotificationsListResponse,
} from '../../state/NotificationsList/types';
import {
  CreatePasswordPayload,
  ResetPasswordPayload,
} from '../../state/ResetPassword/types';
import {
  BulkDeletePayload,
  BulkTemplatedDeletePayload,
} from '../../state/Roster/BulkActions/types';
import {
  CovidReportRequest,
  CovidShiftData,
} from '../../state/Roster/CovidTracing';
import {
  CreateShiftItemPayload,
  DeleteShiftItemPayload,
  EditShiftItemPayload,
  UpdateTemplatePayload,
} from '../../state/Roster/EditTemplate/types';
import {
  ApplyRosterTemplatePayload,
  DeleteTemplatePayload,
} from '../../state/Roster/RosterCopyPast/type';
import {
  AssignAwardPayload,
  AssignedAward,
  UpdateAwardPayload,
} from '../../state/Users/UserProfile/types';
import { CreateUserPayload } from '../../state/Users/Users/types';
import * as helpers from './helpers';
import {
  AccountLoginRequest,
  AccountTreeLinkAreasRequest,
  AccountTreeLinkRolesRequest,
  AccountTreeUnLinkRoleRequest,
  AccountTreeUpdateAreaRequest,
  AccountTreeUpdateSiteRequest,
  AccountUpdateRequest,
  AllowancesRequest,
  AllowancesResponse,
  ApproveTimesheetAllPayload,
  ApproveTimesheetPayload,
  AuthLoginRequest,
  AuthRegisterRequest,
  AutoGenerateTimesheetsPayload,
  BulkRosteredShiftsRequest,
  CovidTracingResponse,
  CreateEventResponse,
  deleteClientParams,
  EmployeeDashboardCreateShiftTradeRequest,
  EmployeeDashboardCreateTimesheetRequest,
  EmployeeDashboardGetEventsResponse,
  EmployeeDashboardGetMyRostersListResponse,
  EmployeeDashboardGetMyRostersRequest,
  EmployeeDashboardGetMyTimesheetPayload,
  EmployeeDashboardGetMyTimesheetResponse,
  EmployeeDashboardGetShiftTradesResponse,
  EventCreateRequest,
  EventDeleteRequest,
  EventsGetRequest,
  EventsGetResponse,
  EventUpdateRequest,
  GenerateCSVReportPayload,
  GetEventByIdRequest,
  GetEventByIdResponse,
  GetEventsRequest,
  GetRosterByIDRequest,
  HigherDutiesRequest,
  HigherDutiesResponse,
  ManagerDashboardApproveOrDeclineShiftOfferProposalRequest,
  ManagerDashboardApproveOrDeclineSwapProposalRequest,
  ManagerDashboardFilteredRequest,
  ManagerDashboardGetApplicableUsersResponse,
  ManagerDashboardGetEventsResponse,
  ManagerDashboardGetOrDeleteShiftOffer,
  ManagerDashboardGetShiftOffersResponse,
  ManagerDashboardGetShiftSwapsResponse,
  ManagerDashboardGetTimeOffsRequest,
  ManagerDashboardGetTimeOffsResponse,
  ManagerDashboardGetWhosWorkingListResponse,
  ManagerDashboardGetWhosWorkingRequest,
  OAuthResponse,
  PayElementsCreateRequest,
  PayElementsDeleteRequest,
  PayElementsUpdateRequest,
  PayEntriesConfirmRevertRequest,
  PayEntriesGetListRequest,
  PayEntriesGetListResponse,
  PaymentGetAssignedUsersResponse,
  PaymentPutAssignedUsersRequest,
  PaymentPutAssignedUsersResponse,
  PermissionsGetAllResponse,
  PunchClockClockOffRequest,
  PunchClockClockOnRequest,
  PunchClockClockOnResponse,
  PunchClockForceStopTimesheetRequest,
  PunchClockGetActiveTimesheetResponse,
  PunchClockStartBreakRequest,
  PunchClockStopBreakRequest,
  ReportDeleteRequest,
  ReportRequest,
  ReportScheduleRequest,
  ReportsGenerateFileRequest,
  ReportsGetAllResponse,
  RosterCheckPastingStatusRequest,
  RosterDeleteRequest,
  RosteredShiftGetListOfAllowedShiftsRequest,
  RosteredShiftGetListResponse,
  RosteredShiftUpdateRequest,
  RosteredShiftUpdateResponse,
  RosteredShiftUsersListRequest,
  RosterGetTemplatesListRequest,
  RosterPasteWeekRequest,
  RosterSaveTemplateRequest,
  RostersDataRequest,
  RosterTimesheetWeekSummaryRequest,
  RosterTimesheetWeekSummaryResponse,
  RosterWeekSummaryRequest,
  RosterWeekSummaryResponse,
  SuccessResponse,
  TagsCreateRequest,
  TagsDeleteRequest,
  TagsGetAllResponse,
  TagsGetTagRequest,
  TagsGetTagResponse,
  TagsLinkOrUnLinkUsersRequest,
  TagsUpdateRequest,
  TimeOffGetListResponse,
  TimeOffsApproveTimeOffRequest,
  TimeOffsChangeTimeOffRequest,
  TimeOffsCreateTimeOffRequest,
  TimeOffsEditTimeOffRequest,
  TimeOffUpdateNotes,
  TimesheetAllowanceRequest,
  TimesheetAllowanceResponse,
  TimesheetBulkApproveRequest,
  TimesheetBulkApproveResponse,
  TimesheetBulkDeleteRequest,
  TimesheetBulkDeleteResponse,
  TimesheetCreateRequest,
  TimesheetDeleteAllowanceRequest,
  TimesheetDeleteAllowanceResponse,
  TimesheetDeleteRequest,
  TimesheetGetByIdResponse,
  TimesheetGetListRequest,
  TimesheetGetListResponse,
  TimesheetRecalculateRequest,
  TimesheetRecalculateResponse,
  TimesheetsAutoGenerateResponse,
  TimesheetsCSVResponse,
  TimesheetSimpleCreateRequest,
  TimesheetSimpleUpdateRequest,
  TimesheetUpdateRequest,
  updateClientParams,
  UpdateEventResponse,
  UserBulkAddRequest,
  UserBulkSetRequest,
  UserGetApprovalsRequest,
  UserGetApprovalsResponse,
  UserGetCurrentResponse,
  UserGroupsAllResponse,
  UserListResponse,
  UserUpdateRequest,
  UserUpdateResponse,
  WhoElseWorkingRequest,
  WithPagerResponse,
  WithPagerServerResponse,
} from './type';

export const OVERLAP_ERROR_STATUS = 202;

export const Api = {
  Sso: {
    users: {
      signOut: () => SsoApiClient.post('/users/sign_out'),
    },
  },
  Standalone: {
    Account: {
      create: ({
        account,
        email,
        password,
      }: AuthRegisterRequest): Promise<OAuthResponse> =>
        ApiClient.request({
          url: `/api/standalone/account`,
          method: 'post',
          data: {
            name: account,
            email,
            password,
          },
        }).then((response: AxiosResponse<OAuthResponse>) => response.data),
    },
    User: {
      create: (
        payload: CreateUserPayload
      ): Promise<ApiClientResponse<ServerUserFields>> =>
        ApiClient.post(`api/standalone/users`, payload),
      update: ({
        id,
        ...payload
      }: UserUpdateRequest): Promise<ApiClientResponse<UserUpdateResponse>> =>
        ApiClient.put(`/api/standalone/users/${id}`, payload),
    },
  },
  Auth: {
    login: ({
      account,
      username,
      password,
      grant_type = 'password',
      scope = '',
      captcha,
    }: AuthLoginRequest): Promise<OAuthResponse> =>
      ApiClient.request({
        url: `oauth/token`,
        method: 'post',
        data: {
          account,
          grant_type,
          username,
          password,
          scope,
          'g-recaptcha-response': captcha,
          // ...oAuthCredentials
        },
      }).then((response: AxiosResponse<OAuthResponse>) => response.data),
    test: () => ApiClient.get('test'),
    Password: {
      send: ({ account, email }: ResetPasswordPayload) =>
        ApiClient.request({
          url: `/api/auth/password/send`,
          method: 'post',
          data: {
            account,
            email,
          },
        }).then((response: AxiosResponse<OAuthResponse>) => {
          return response.data;
        }),
      reset: ({
        token,
        password,
        password_confirmation,
      }: CreatePasswordPayload) =>
        ApiClient.request({
          url: `/api/auth/password/reset`,
          method: 'post',
          data: {
            token,
            password,
            password_confirmation,
          },
        }).then((response: AxiosResponse<OAuthResponse>) => {
          return response.data;
        }),
    },
  },
  Account: {
    account: (): Promise<ApiClientResponse<Account>> =>
      ApiClient.get('/api/account'),
    timezones: (): Promise<ApiClientResponse<any>> =>
      ApiClient.get('/api/account/timezones'),
    update: (
      payload: AccountUpdateRequest
    ): Promise<ApiClientResponse<Account>> =>
      ApiClient.put('/api/account', payload),
    file: (payload: any): Promise<ApiClientResponse<any>> =>
      ApiClient.post('/api/account/file', payload),
    accountOauthClient: (): Promise<ApiClientResponse<any>> =>
      ApiClient.get('/api/account/oauth-clients'),
    accountOauthClientCreate: (
      payload: any
    ): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.post('/api/account/oauth-clients', payload),
    accountOauthClientUpdate: (
      payload: updateClientParams
    ): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.put(`api/account/oauth-clients/${payload.id}`, {
        name: payload.name,
      }),
    accountOauthClientDelete: (
      payload: deleteClientParams
    ): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.delete(`api/account/oauth-clients/${payload.id}`),
  },
  AccountTree: {
    getAccountTree: (): Promise<ApiClientResponse<AccountTree>> =>
      ApiClient.get('/api/account/tree'),
    linkAreas: ({
      site_id,
      ...restPayload
    }: AccountTreeLinkAreasRequest): Promise<
      ApiClientResponse<SuccessResponse>
    > =>
      ApiClient.put(
        `/api/account/tree/sites/${site_id}/link_areas`,
        restPayload
      ),
    linkRoles: ({
      area_id,
      ...restPayload
    }: AccountTreeLinkRolesRequest): Promise<
      ApiClientResponse<SuccessResponse>
    > =>
      ApiClient.put(
        `/api/account/tree/areas/${area_id}/link_roles`,
        restPayload
      ),
    getAssignments: (): Promise<ApiClientResponse<Assignments>> =>
      ApiClient.get('/api/account/tree/assignments'),
    unlinkRole: ({
      area_id,
      role_id,
    }: AccountTreeUnLinkRoleRequest): Promise<
      ApiClientResponse<SuccessResponse>
    > =>
      ApiClient.post(`/api/account/tree/roles/${role_id}/unlink_from_area`, {
        area_id,
      }),
    updateArea: ({
      id,
      ...restPayload
    }: AccountTreeUpdateAreaRequest): Promise<
      ApiClientResponse<SuccessResponse>
    > => ApiClient.put(`/api/account/tree/areas/${id}`, restPayload),
    updateSite: ({
      id,
      ...restPayload
    }: AccountTreeUpdateSiteRequest): Promise<
      ApiClientResponse<SuccessResponse>
    > => ApiClient.put(`/api/account/tree/sites/${id}`, restPayload),
  },
  Reports: {
    getAll: (): Promise<ApiClientResponse<ReportsGetAllResponse>> =>
      ApiClient.get('/api/reports').then(
        helpers.updateResponse((data: StringMap<ServerReport>) =>
          _.mapValues(data, helpers.updateReport)
        )
      ),
    get: (payload: ReportRequest): Promise<ApiClientResponse<Report>> =>
      ApiClient.get(`/api/reports/${payload.reportId}`).then(
        helpers.updateResponse(helpers.updateReport)
      ),
    save: (payload: Report): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.post(`/api/reports`, payload),
    update: (payload: Report): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.put(`/api/reports/${payload.id}`, payload),
    generate: (payload: any): Promise<ApiClientResponse<GeneratedReport>> =>
      ApiClient.post(`/api/reports/generate`, payload),
    generateFileDownloadUrl: ({
      reportId,
      ...queryObject
    }: ReportsGenerateFileRequest): string =>
      `${
        process.env.REACT_APP_API_URL
      }/api/reports/${reportId}/generate?${qs.stringify(queryObject, {
        skipNulls: true,
      })}`,
    generateExistReport: (
      payload: any
    ): Promise<ApiClientResponse<GeneratedReport>> =>
      ApiClient.get(`/api/reports/${payload}/generate`),
    putSchedule: ({
      id,
      schedule,
    }: ReportScheduleRequest): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.put(
        `/api/reports/${id}/schedule`,
        helpers.updateReportSchedulePayload(schedule)
      ),
    deleteReport: (
      payload: ReportDeleteRequest
    ): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.delete(`/api/reports/${payload.id}`),
  },
  Awards: {
    getAssignedAwards: (id: string): Promise<ApiClientResponse<AwardsList>> =>
      ApiClient.get(`/api/award-assignment/users/${id}`).then(
        helpers.updateResponse(helpers.updateEffectiveDate)
      ),
    getGlobalAwardsList: (): Promise<ApiClientResponse<AwardsSearchList>> =>
      ApiClient.get(`/api/global-awards/search`),
    assignAward: (
      payload: AssignAwardPayload
    ): Promise<ApiClientResponse<AssignedAward>> =>
      ApiClient.post('/api/award-assignment/awards', payload).then(
        helpers.updateResponse(helpers.updateAwardEffectiveDate)
      ),
    deleteAward: (
      awardId: string
    ): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.delete(`/api/award-assignment/awards/${awardId}`),
    updateAward: ({
      id,
      ...rest
    }: UpdateAwardPayload): Promise<ApiClientResponse<AssignedAward>> =>
      ApiClient.put(`/api/award-assignment/awards/${id}`, rest).then(
        helpers.updateResponse(helpers.updateAwardEffectiveDate)
      ),
  },
  User: {
    bulkAssignLocation: (payload: any): Promise<ApiClientResponse<any>> =>
      ApiClient.put(`api/users/bulk-add-locations`, payload),
    getCurrent: (): Promise<ApiClientResponse<UserGetCurrentResponse>> =>
      ApiClient.get('/api/users/current'),
    list: (): Promise<ApiClientResponse<UserListResponse>> =>
      ApiClient.get('/api/users'),
    rotaList: (): Promise<ApiClientResponse<UserListResponse>> =>
      ApiClient.get('/api/users/rota-list'),
    getApprovals: (
      payload: UserGetApprovalsRequest
    ): Promise<ApiClientResponse<UserGetApprovalsResponse>> =>
      ApiClient.get('/api/users/approvals', payload),
    bulkAdd: (
      payload: UserBulkAddRequest
    ): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.put('/api/users/bulk-add-data', payload),
    bulkSet: (
      payload: UserBulkSetRequest
    ): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.put('/api/users/bulk-set-data', payload),
    update: ({
      id,
      ...payload
    }: UserUpdateRequest): Promise<ApiClientResponse<UserUpdateResponse>> =>
      ApiClient.put(`/api/users/${id}`, payload),
    get: (id: string): Promise<ApiClientResponse<UserProfileFields>> =>
      ApiClient.get(`/api/users/${id}`),
    entitlements: (id: string): Promise<ApiClientResponse<any>> =>
      ApiClient.get(`/api/users/${id}/entitlements`),
    file: (payload: {
      id: string;
      file: any;
    }): Promise<ApiClientResponse<any>> =>
      ApiClient.post(`api/users/${payload.id}/file`, payload.file),
    sendWelcomeNotifications: (payload: string[]) =>
      ApiClient.post(`api/auth/password/resend`, payload),
    updateCurrent: (
      payload: UpdateCurrentUserPayload
    ): Promise<ApiClientResponse<ServerUserFields>> =>
      ApiClient.put(`/api/users/profile`, payload),
  },
  Misc: {
    timezones: (): Promise<ApiClientResponse<string[]>> =>
      ApiClient.get('/api/misc/timezones'),
  },
  UserGroups: {
    all: (): Promise<ApiClientResponse<UserGroupsAllResponse>> =>
      ApiClient.get('/api/user-groups').then(
        helpers.updateResponse((data: StringMap<ServerUserGroup>) =>
          _.mapValues(
            data,
            (serverUserGroup: ServerUserGroup): UserGroup => ({
              ...serverUserGroup,
              id: serverUserGroup.id + '',
            })
          )
        )
      ),
    add: (payload: any): Promise<ApiClientResponse<any>> =>
      ApiClient.post('/api/user-groups', payload),
    update: (
      payload: any,
      id: string
    ): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.put(`api/user-groups/${id}`, payload),
    updatePermissions: (
      payload: any,
      id: string
    ): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.put(`api/user-groups/${id}/permissions`, payload),
    delete: (payload: any): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.delete(`api/user-groups/${payload.id}`, payload),
  },
  Permissions: {
    all: (): Promise<ApiClientResponse<PermissionsGetAllResponse>> =>
      ApiClient.get('/api/permissions'),
  },
  Tags: {
    getAll: (): Promise<ApiClientResponse<TagsGetAllResponse>> =>
      ApiClient.get('/api/tags'),
    create: (
      payload: TagsCreateRequest
    ): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.post('/api/tags', payload),
    getTag: ({
      id,
    }: TagsGetTagRequest): Promise<ApiClientResponse<TagsGetTagResponse>> =>
      ApiClient.get(`/api/tags/${id}`),
    update: ({
      id,
      ...payload
    }: TagsUpdateRequest): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.put(`/api/tags/${id}`, payload),
    delete: (
      payload: TagsDeleteRequest
    ): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.post('/api/tags/bulk-delete', payload),
    linkUsers: ({
      id,
      ...payload
    }: TagsLinkOrUnLinkUsersRequest): Promise<
      ApiClientResponse<SuccessResponse>
    > => ApiClient.post(`/api/tags/${id}/link_users`, payload),
    unlinkUsers: ({
      id,
      ...payload
    }: TagsLinkOrUnLinkUsersRequest): Promise<
      ApiClientResponse<SuccessResponse>
    > => ApiClient.post(`/api/tags/${id}/unlink_users`, payload),
  },
  Notifications: {
    getAll: (): Promise<ApiClientResponse<Notifications>> =>
      ApiClient.get('/api/notifications'),
    update: (
      payload: Notifications
    ): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.put(`/api/notifications`, payload),
    getNotification: (
      group: string,
      id: string
    ): Promise<ApiClientResponse<SuccessResponse>> => {
      return ApiClient.get(`/api/notifications/${group}/${id}`);
    },
    updateNotification: (
      payload: Notifications,
      params: string
    ): Promise<ApiClientResponse<SuccessResponse>> => {
      return ApiClient.put(`/api/notifications/${params}`, payload);
    },
    upload: (payload: any): Promise<ApiClientResponse<any>> => {
      return ApiClient.post('/api/file/upload', payload);
    },
  },
  AuditTrail: {
    getAll: (payload: any): Promise<ApiClientResponse<AuditTrail>> =>
      ApiClient.get('/api/audit-trail', payload),
  },
  Preferences: {
    getProfile: (): Promise<ApiClientResponse<Profile>> =>
      ApiClient.get('/api/users/preferences/notification-settings'),
    update: (payload: Profile): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.put(`/api/users/preferences/notification-settings`, payload),
    pin: (payload: Profile): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.put(`/api/users/preferences/pin`, {
        pin: payload.pin,
        pin_confirmation: payload.pin_confirmation,
      }),
  },
  Accounts: {
    getAccounts: (): Promise<ApiClientResponse<Account>> =>
      ApiClient.get('/api/accounts'),
    login: (payload: AccountLoginRequest) => {
      const data = {
        ...payload,
        // ...oAuthCredentials
      };
      return ApiClient.request({
        url: `oauth/token`,
        method: 'post',
        data: {
          ...data,
        },
      }).then((response: AxiosResponse<OAuthResponse>) => {
        return response.data;
      });
    },
  },
  File: {
    upload: (payload: any): Promise<ApiClientResponse<any>> =>
      ApiClient.post('/api/file/upload', payload),
  },
  RosteredShift: {
    OVERLAP_ERROR_STATUS,
    getList: (
      payload: RostersDataRequest
    ): Promise<ApiClientResponse<RosteredShiftGetListResponse>> =>
      ApiClient.get(
        '/api/rostered-shifts',
        helpers.updateGetRosterDataPayload(payload)
      ).then(
        helpers.updateResponse((response: StringMap<ServerRosteredShift>) =>
          _.mapValues(response, helpers.updateRosteredShift)
        )
      ),
    getById: ({
      id,
    }: GetRosterByIDRequest): Promise<ApiClientResponse<RosteredShift>> =>
      ApiClient.get(`api/rostered-shifts/${id}`).then(
        helpers.updateResponse(helpers.updateRosteredShift)
      ),
    delete: ({
      id,
      ...restRequest
    }: RosterDeleteRequest): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.delete(`api/rostered-shifts/${id}`, restRequest),
    bulkDelete: (
      payload: BulkDeletePayload
    ): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.delete(`api/rostered-shifts/bulk-delete`, payload),
    updateRosteredShift: ({ id, ...rest }: RosteredShiftUpdateRequest): any =>
      ApiClient.put(
        `/api/rostered-shifts/${id}`,
        helpers.updateCreateRosteredShiftPayload(rest)
      ).then(
        (response: { data: StringMap<ServerRosteredShift>; warnings?: [] }) => {
          return {
            warnings: response.warnings || [],
            data: _.mapValues(
              response.data ? response.data : response,
              helpers.updateRosteredShift
            ),
          };
        }
      ),
    usersList: (
      payload: RosteredShiftUsersListRequest
    ): Promise<ApiClientResponse<StringMap<RosterUser>>> => {
      return ApiClient.get(`/api/roster/users`, {
        site_id: payload.site_id,
        type: payload.type,
        start: helpers.dateInServerFormat(payload.start),
        end: helpers.isNextDay(payload.start, payload.end)
          ? helpers.dateInServerFormat(payload.end, true)
          : helpers.dateInServerFormat(payload.end),
        rostered_shift_id: payload.rostered_shift_id
          ? payload.rostered_shift_id
          : undefined,
      });
    },
    eventsList: (payload: any): Promise<ApiClientResponse<any>> => {
      return ApiClient.get(`api/events`, {
        site_id: payload.site_id,
        from: helpers.dateInServerFormat(payload.start),
        to: helpers.isNextDay(payload.start, payload.end)
          ? helpers.dateInServerFormat(payload.end, true)
          : helpers.dateInServerFormat(payload.end),
      });
    },
    undo: (payload: any): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.post('/api/rostered-shifts/undo', payload),
    undoList: (
      payload: UndoListPayload
    ): Promise<ApiClientResponse<StringMap<UndoItem>>> =>
      ApiClient.get('/api/rostered-shifts/undo', payload),
    acceptShift: (id: string): Promise<ApiClientResponse<RosteredShift>> =>
      ApiClient.post(`api/rostered-shifts/${id}/accept`).then(
        helpers.updateResponse(helpers.updateRosteredShift)
      ),
    notifyUser: (id: string): Promise<ApiClientResponse<RosteredShift>> =>
      ApiClient.post(`api/rostered-shifts/${id}/notify`),
    offerShift: (id: string): Promise<ApiClientResponse<RosteredShift>> =>
      ApiClient.post(`api/rostered-shifts/${id}/offer`).then(
        helpers.updateResponse(helpers.updateRosteredShift)
      ),
    getListOfAllowedShifts: (
      payload: RosteredShiftGetListOfAllowedShiftsRequest
    ): Promise<ApiClientResponse<Dictionary<AllowedToAssignShift>>> =>
      ApiClient.get(
        '/api/rostered-shifts/allowed-to-assign-to-timesheet',
        payload
      ),
    bulkCreate: (
      payload: BulkRosteredShiftsRequest
    ): Promise<ApiClientResponse<any>> => {
      const data = payload.shifts.map((p) =>
        helpers.updateCreateRosteredShiftPayload(p)
      );
      const payloadData = {
        ignore_errors: payload.ignore_errors,
        shifts: data,
      };
      return ApiClient.post('/api/rostered-shifts/bulk', payloadData).then(
        helpers.updateResponse((response: StringMap<ServerRosteredShift>) =>
          _.forEach(_.mapValues(response, helpers.updateRosteredShift))
        )
      );
    },
    whoElseWorkingWithMe: (
      payload: WhoElseWorkingRequest
    ): Promise<ApiClientResponse<any>> =>
      ApiClient.get('/api/rostered-shifts/who-else-working', payload),
  },
  Roster: {
    publish: (
      payload: PublishRostersPayload
    ): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.post('/api/roster/publish', payload),
    refactoredPublish: (
      payload: RefactoredPublishRostersPayload
    ): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.post('/api/roster/publish', payload),
    list: (
      payload: GetUnpublishedRostersListPayload
    ): Promise<ApiClientResponse<any[]>> =>
      ApiClient.get('/api/roster', payload),
    shiftsForOffer: (
      payload: GetOffersPayload
    ): Promise<ApiClientResponse<any[]>> =>
      ApiClient.get('/api/roster/shifts-for-offer', payload),
    offer: (
      payload: RosterOffersPayload
    ): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.post('/api/roster/offer', payload),
    timesheetWeekSummary: (
      payload: RosterTimesheetWeekSummaryRequest
    ): Promise<ApiClientResponse<RosterTimesheetWeekSummaryResponse>> =>
      ApiClient.get('/api/roster/timesheet-week-summary', payload),
    weekSummary: ({
      date,
      ...rest
    }: RosterWeekSummaryRequest): Promise<
      ApiClientResponse<RosterWeekSummaryResponse>
    > =>
      ApiClient.get('/api/roster/week-summary', {
        ...rest,
        date: date.format(SERVER_DAY_FORMAT),
      }),
    pasteWeek: (payload: RosterPasteWeekRequest) =>
      ApiClient.post(
        '/api/roster/copy-range',
        helpers.updatePostRosterPayload(payload)
      ),
    checkPastingStatus: (payload: RosterCheckPastingStatusRequest) =>
      ApiClient.get(
        '/api/roster/jobs-statuses',
        helpers.updateCheckPastingStatus(payload)
      ).then(helpers.updateResponse(helpers.updateCheckPastingResponse)),
    saveTemplate: ({ from, to, ...restPayload }: RosterSaveTemplateRequest) =>
      ApiClient.post('/api/roster-templates', {
        ...restPayload,
        from: from.format(SERVER_DAY_FORMAT),
        to: to.format(SERVER_DAY_FORMAT),
      }),
    getTemplatesList: (payload: RosterGetTemplatesListRequest) =>
      ApiClient.get('/api/roster-templates', payload),
    deleteTemplate: (payload: DeleteTemplatePayload) =>
      ApiClient.delete(`api/roster-templates/${payload.id}`),
    applyTemplate: (payload: ApplyRosterTemplatePayload) =>
      ApiClient.post(`api/roster-templates/${payload.id}/apply`, {
        target: payload.target,
        from: payload.from,
        to: payload.to,
      }),
  },
  EditTemplate: {
    getShiftsByTemplateId: (id: string) =>
      ApiClient.get(`api/roster-templates/${id}`),
    createShiftItem: ({ templateId, ...rest }: CreateShiftItemPayload) =>
      ApiClient.post(`api/roster-templates/${templateId}/shifts`, {
        ...rest,
        start: rest.start.substr(0, 5),
        end: rest.end.substr(0, 5),
      }),
    editShiftItem: ({ templateId, id, ...rest }: EditShiftItemPayload) =>
      ApiClient.put(`api/roster-templates/${templateId}/shifts/${id}`, {
        ...rest,
        start: rest.start.substr(0, 5),
        end: rest.end.substr(0, 5),
        id,
      }),
    deleteShiftItem: ({ template_id, shift_id }: DeleteShiftItemPayload) =>
      ApiClient.delete(
        `api/roster-templates/${template_id}/shifts/${shift_id}`
      ),
    bulkDelete: ({
      templateId,
      ...rest
    }: BulkTemplatedDeletePayload): Promise<
      ApiClientResponse<SuccessResponse>
    > =>
      ApiClient.delete(
        `api/roster-templates/${templateId}/shifts/bulk-delete`,
        rest
      ),
    updateTemplate: ({ templateId, ...rest }: UpdateTemplatePayload) =>
      ApiClient.put(`api/roster-templates/${templateId}`, rest),
    saveAs: ({ id, ...rest }: { id: string; name: string }) =>
      ApiClient.post(`api/roster-templates/${id}/copy`, rest),
  },
  CovidTracing: {
    getShifts: (
      payload: CovidShiftData
    ): Promise<ApiClientResponse<CovidTracingResponse>> =>
      ApiClient.post('/api/manager/covid-trace', payload),
    printReport: (
      payload: CovidReportRequest
    ): Promise<ApiClientResponse<CovidTracingResponse>> =>
      ApiClient.post('/api/manager/covid-report', payload),
  },
  Timesheet: {
    OVERLAP_ERROR_STATUS,

    export: (
      payload: GenerateCSVReportPayload
    ): Promise<ApiClientResponse<TimesheetsCSVResponse>> =>
      ApiClient.post('/api/timesheets/export', payload),

    generate: (
      payload: AutoGenerateTimesheetsPayload
    ): Promise<ApiClientResponse<TimesheetsAutoGenerateResponse>> =>
      ApiClient.post('/api/timesheets/generate', payload),

    getList: ({
      with_pay_entries = false,
      ...restPayload
    }: TimesheetGetListRequest): Promise<
      ApiClientResponse<TimesheetGetListResponse>
    > =>
      ApiClient.get('/api/timesheets', {
        ...helpers.updateGetRosterDataPayload({
          ...restPayload,
        }),
        with_pay_entries: with_pay_entries ? 1 : 0, // TODO transform somewhere in ApiClient
      }).then(
        helpers.updateResponse((response: StringMap<ServerTimesheet>) =>
          _.mapValues<StringMap<ServerTimesheet>, Timesheet>(
            response,
            helpers.updateTimesheet
          )
        )
      ),
    getListResponse: (payload: {
      site_id: string;
      from: DateTimeType;
      to: DateTimeType;
    }): Promise<ApiClientResponse<Dictionary<RosterTimesheetListed>>> =>
      ApiClient.get('/api/timesheets', payload),
    getListResponseWithPayEntries: (payload: {
      site_id: string;
      from: DateTimeType;
      to: DateTimeType;
    }): Promise<
      ApiClientResponse<Dictionary<RosterTimesheetListedWithPayEntries>>
    > =>
      ApiClient.get('/api/timesheets', {
        ...payload,
        with_pay_entries: 1,
      }),
    getById: (
      id: string
    ): Promise<ApiClientResponse<TimesheetGetByIdResponse>> =>
      ApiClient.get(`/api/timesheets/${id}`).then(
        helpers.updateResponse((response: ServerTimesheet) =>
          helpers.updateTimesheet(response)
        )
      ),
    recalculate: (
      payload: TimesheetRecalculateRequest
    ): Promise<ApiClientResponse<TimesheetRecalculateResponse>> =>
      ApiClient.get(`/api/timesheets/${payload.id}/recalculate`, {
        higher_duty: payload.higher_duty.id,
        higher_duty_start: payload.higher_duty_start.format(
          SERVER_DATE_TIME_FORMAT
        ),
        higher_duty_end: payload.higher_duty_end.format(
          SERVER_DATE_TIME_FORMAT
        ),
      }),
    allowance: ({
      id,
      ...apiPayload
    }: TimesheetAllowanceRequest): Promise<
      ApiClientResponse<TimesheetAllowanceResponse>
    > =>
      ApiClient.post(`/api/timesheets/${id}/allowance`, apiPayload).then(
        helpers.updateResponse<ServerTimesheet, Timesheet>(
          helpers.updateTimesheet
        )
      ),
    deleteAllowance: (
      payload: TimesheetDeleteAllowanceRequest
    ): Promise<ApiClientResponse<TimesheetDeleteAllowanceResponse>> =>
      ApiClient.delete(`/api/timesheets/${payload.id}/allowance`, {
        allowance_id: payload.allowance_id,
      }).then(
        helpers.updateResponse<ServerTimesheet, Timesheet>(
          helpers.updateTimesheet
        )
      ),
    createTimesheetResponse: (
      payload: TimesheetSimpleCreateRequest
    ): Promise<ApiClientResponse<RosterTimesheetListedWithPayEntries>> =>
      ApiClient.post('/api/timesheets', payload),
    updateTimesheetResponse: ({
      id,
      ...restPayload
    }: TimesheetSimpleUpdateRequest): Promise<
      ApiClientResponse<RosterTimesheetListedWithPayEntries>
    > => ApiClient.put(`/api/timesheets/${id}`, restPayload),
    createTimesheet: (
      payload: TimesheetCreateRequest
    ): Promise<ApiClientResponse<Timesheet>> =>
      ApiClient.post(
        '/api/timesheets',
        helpers.updateCreateTimesheetPayload(payload)
      ).then((response: { data: Timesheet; warnings?: [] }) => {
        const r: any = response.data ? response.data : response; // TODO double-check why we need this
        return {
          warnings: response.warnings || [],
          data: helpers.updateTimesheet(r as ServerTimesheet),
        };
      }),
    updateTimesheet: ({
      id,
      ...rest
    }: TimesheetUpdateRequest): any => // TODO double-check
      ApiClient.put(
        `/api/timesheets/${id}`,
        helpers.updateTimesheetPayload(rest)
      ).then((response: { data: Timesheet; warnings?: [] }) => {
        const r: any = response.data ? response.data : response;
        return {
          warnings: response.warnings || [],
          data: helpers.updateTimesheet(r),
        };
      }),
    delete: ({
      id,
      ...restPayload
    }: TimesheetDeleteRequest): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.delete(`/api/timesheets/${id}`, restPayload),
    bulkDelete: (
      props: TimesheetBulkDeleteRequest
    ): Promise<ApiClientResponse<TimesheetBulkDeleteResponse>> =>
      ApiClient.delete('/api/timesheets/bulk-delete', props),
    bulkApprove: (
      props: TimesheetBulkApproveRequest
    ): Promise<ApiClientResponse<TimesheetBulkApproveResponse>> =>
      ApiClient.post('/api/timesheets/bulk-approve', props),
    changeApproveResponse: ({
      id,
      ...restPayload
    }: ApproveTimesheetPayload): Promise<
      ApiClientResponse<RosterTimesheetListedWithPayEntries>
    > => ApiClient.post(`/api/timesheets/${id}/change-approve`, restPayload),
    changeApprove: ({
      id,
      ...restPayload
    }: ApproveTimesheetPayload): Promise<ApiClientResponse<Timesheet>> =>
      ApiClient.post(`/api/timesheets/${id}/change-approve`, restPayload).then(
        helpers.updateResponse((response: ServerTimesheet) =>
          helpers.updateTimesheet(response)
        )
      ),
    // TODO: remove duplicates
    approve: (
      payload: ApproveTimesheetPayload
    ): Promise<ApiClientResponse<Timesheet>> =>
      ApiClient.post(`api/timesheets/${payload.id}/change-approve`, {
        is_approved: !payload.is_approved,
        ignore_errors: payload.ignore_errors ? payload.ignore_errors : false,
      }).then(
        helpers.updateResponse((response: ServerTimesheet) =>
          helpers.updateTimesheet(response)
        )
      ),
    approveAll: (
      payload: ApproveTimesheetAllPayload
    ): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.post(`api/timesheets/approve-without-violation`, payload),
  },
  Timeoffs: {
    getList: (
      payload: RostersDataRequest
    ): Promise<ApiClientResponse<TimeOffGetListResponse>> =>
      ApiClient.get(
        '/api/time-offs',
        helpers.updateGetRosterDataPayload(payload)
      ).then(
        helpers.updateResponse(
          (response: StringMap<ServerRosterRepeatableTimeOff>) =>
            _.mapValues(_.keyBy(response, 'id'), helpers.updateRosterTimeOff)
        )
      ),
    createTimeOff: {
      OVERLAP_ERROR_STATUS,
      request: (
        payload: TimeOffsCreateTimeOffRequest
      ): Promise<ApiClientResponse<SuccessResponse>> =>
        ApiClient.post(
          '/api/manager/time-offs/unavailability',
          helpers.updateCreateTimeOffPayload(payload)
        ),
    },
    editTimeOff: {
      OVERLAP_ERROR_STATUS,
      request: ({
        timeOffId,
        ...payload
      }: TimeOffsEditTimeOffRequest): Promise<
        ApiClientResponse<SuccessResponse>
      > =>
        ApiClient.put(
          `api/manager/time-offs/unavailability/${timeOffId}`,
          helpers.updateCreateTimeOffPayload(payload)
        ),
    },
    deleteTimeOff: ({
      timeOffId,
    }: TimeOffsChangeTimeOffRequest): Promise<
      ApiClientResponse<SuccessResponse>
    > => ApiClient.delete(`api/manager/time-offs/unavailability/${timeOffId}`),
    approveTimeOff: {
      OVERLAP_ERROR_STATUS,
      request: ({
        timeOffId,
        ignore_errors = false,
      }: TimeOffsApproveTimeOffRequest): Promise<
        ApiClientResponse<SuccessResponse>
      > =>
        ApiClient.post(
          `api/manager/time-offs/unavailability/${timeOffId}/approve`,
          {
            ignore_errors,
          }
        ),
    },
    unapproveTimeOff: ({
      timeOffId,
    }: TimeOffsChangeTimeOffRequest): Promise<
      ApiClientResponse<SuccessResponse>
    > =>
      ApiClient.post(
        `api/manager/time-offs/unavailability/${timeOffId}/unapprove`
      ),
    declineTimeOff: ({
      timeOffId,
    }: TimeOffsChangeTimeOffRequest): Promise<
      ApiClientResponse<SuccessResponse>
    > =>
      ApiClient.post(
        `api/manager/time-offs/unavailability/${timeOffId}/decline`
      ),
    notes: ({
      notes,
      id,
    }: TimeOffUpdateNotes): Promise<ApiClientResponse<any>> =>
      ApiClient.put(`api/time-offs/leaves/${id}/notes`, {
        notes,
      }).then(helpers.updateResponse(helpers.updateRosterTimeOff)),
  },
  ManagerDashboard: {
    getOverview: (
      payload: ManagerDashboardFilteredRequest
    ): Promise<ApiClientResponse<Overview>> =>
      ApiClient.get('/api/manager/overview', payload),
    getShiftOffers: (
      payload: ManagerDashboardFilteredRequest
    ): Promise<ApiClientResponse<ManagerDashboardGetShiftOffersResponse>> =>
      ApiClient.get('/api/manager/shift-offers', payload).then(
        helpers.updateResponse((response: StringMap<ServerShiftOffer>) =>
          _.mapValues(response, helpers.updateShiftOffer)
        )
      ),
    getShiftOffer: ({
      shiftOfferId,
    }: ManagerDashboardGetOrDeleteShiftOffer): Promise<
      ApiClientResponse<ShiftOfferWithProposals>
    > =>
      ApiClient.get(`/api/manager/shift-offers/${shiftOfferId}`).then(
        helpers.updateResponse(helpers.updateShiftOfferWithProposals)
      ),
    deleteShiftOffer: ({
      shiftOfferId,
    }: ManagerDashboardGetOrDeleteShiftOffer): Promise<
      ApiClientResponse<SuccessResponse>
    > => ApiClient.delete(`/api/manager/shift-offers/${shiftOfferId}`),
    approveShiftOfferProposal: ({
      shiftOfferId,
      ...payload
    }: ManagerDashboardApproveOrDeclineShiftOfferProposalRequest): Promise<
      ApiClientResponse<SuccessResponse>
    > =>
      ApiClient.post(
        `/api/manager/shift-offers/${shiftOfferId}/approve`,
        payload
      ),
    declineShiftOfferProposal: ({
      shiftOfferId,
      ...payload
    }: ManagerDashboardApproveOrDeclineShiftOfferProposalRequest): Promise<
      ApiClientResponse<SuccessResponse>
    > =>
      ApiClient.post(
        `/api/manager/shift-offers/${shiftOfferId}/decline`,
        payload
      ),
    getShiftSwaps: (
      payload: ManagerDashboardFilteredRequest
    ): Promise<ApiClientResponse<ManagerDashboardGetShiftSwapsResponse>> =>
      ApiClient.get('/api/manager/shift-swaps', payload),
    approveShiftSwapProposal: ({
      id,
    }: ManagerDashboardApproveOrDeclineSwapProposalRequest): Promise<
      ApiClientResponse<SuccessResponse>
    > => ApiClient.post(`/api/manager/shift-swaps/${id}/approve`),
    declineShiftSwapProposal: ({
      id,
    }: ManagerDashboardApproveOrDeclineSwapProposalRequest): Promise<
      ApiClientResponse<SuccessResponse>
    > => ApiClient.post(`/api/manager/shift-swaps/${id}/decline`),
    getTimeOffs: (
      { from, to, ...rest }: ManagerDashboardGetTimeOffsRequest,
      globalFilters: ManagerDashboardFilteredRequest
    ): Promise<ApiClientResponse<ManagerDashboardGetTimeOffsResponse>> => {
      return ApiClient.get('/api/manager/time-offs', {
        from: from ? from.format(SERVER_DAY_FORMAT) : from,
        to: to ? to.format(SERVER_DAY_FORMAT) : to,
        ...rest,
        ...globalFilters,
      }).then(
        helpers.updateResponse((data: WithPagerServerResponse<ServerTimeOff>) =>
          helpers.updateWithPagerResponse(helpers.updateTimeOff)(data)
        )
      );
    },
    getApplicableUsers: (): Promise<
      ApiClientResponse<ManagerDashboardGetApplicableUsersResponse>
    > => ApiClient.get('/api/manager/time-offs/applicable-users'),
    getEvents: (
      {
        period = { period: null, from: null, to: null },
        ...rest
      }: GetEventsRequest,
      globalFilters: ManagerDashboardFilteredRequest
    ): Promise<ApiClientResponse<ManagerDashboardGetEventsResponse>> =>
      ApiClient.get('/api/manager/events', {
        period: {
          ...period,
          from: period.from
            ? period.from.format(SERVER_DAY_FORMAT)
            : period.from,
          to: period.to ? period.to.format(SERVER_DAY_FORMAT) : period.to,
        },
        ...rest,
        ...globalFilters,
      }).then(
        helpers.updateResponse(
          helpers.updateWithPagerResponse(helpers.updateEvent)
        )
      ),
    getWhosWorking: ({
      period,
      ...globalFilters
    }: ManagerDashboardGetWhosWorkingRequest): Promise<
      ApiClientResponse<ManagerDashboardGetWhosWorkingListResponse>
    > => {
      return ApiClient.get(
        `/api/manager/who-working/${period}`,
        globalFilters
      ).then(
        helpers.updateResponse((response: ServerShift[]) =>
          response.map(helpers.updateWorkingShift)
        )
      );
    },
  },
  PunchClock: {
    clockOn: (
      payload: PunchClockClockOnRequest
    ): Promise<ApiClientResponse<PunchClockClockOnResponse>> =>
      ApiClient.post('/api/punch-clock/start', payload),
    clockOff: (
      payload: PunchClockClockOffRequest
    ): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.post('/api/punch-clock/stop', payload),
    startBreak: (
      payload: PunchClockStartBreakRequest
    ): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.post('/api/punch-clock/start-break', payload),
    stopBreak: (
      payload: PunchClockStopBreakRequest
    ): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.post('/api/punch-clock/stop-break', payload),
    getActiveTimesheet: (): Promise<
      ApiClientResponse<PunchClockGetActiveTimesheetResponse>
    > => ApiClient.get('/api/punch-clock/timesheets/active'),
    forceStopTimesheet: ({
      id,
      ...restProps
    }: PunchClockForceStopTimesheetRequest): Promise<
      ApiClientResponse<RosterTimesheetListedWithPayEntries | undefined>
    > =>
      ApiClient.post(
        `api/punch-clock/timesheets/${id}/force-stop`,
        restProps
      ).then(
        helpers.updateResponse(
          (maybeTimesheet: RosterTimesheetListedWithPayEntries | []) => {
            if (!!(maybeTimesheet as RosterTimesheetListedWithPayEntries)?.id) {
              return maybeTimesheet as RosterTimesheetListedWithPayEntries;
            }
          }
        )
      ),
    approve: (
      // TODO remove duplicate
      id: string,
      payload: { is_approved: boolean }
    ): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.post(`/api/timesheets/${id}/change-approve`, payload),
  },
  Events: {
    get: (
      payload: EventsGetRequest
    ): Promise<ApiClientResponse<EventsGetResponse>> =>
      ApiClient.get(
        '/api/events',
        helpers.updateEventsGetPayload(payload)
      ).then(
        helpers.updateResponse((response: StringMap<ServerEvent>) =>
          _.mapValues(response, helpers.updateEvent)
        )
      ),
    getById: ({
      id,
    }: GetEventByIdRequest): Promise<ApiClientResponse<GetEventByIdResponse>> =>
      ApiClient.get(`api/events/${id}`).then(
        helpers.updateResponse(helpers.updateEvent)
      ),
    create: ({
      ...rest
    }: EventCreateRequest): Promise<ApiClientResponse<CreateEventResponse>> =>
      ApiClient.post(
        `/api/events`,
        helpers.updateCreateEventShiftPayload(rest)
      ).then(helpers.updateResponse(helpers.updateEvent)),
    update: ({
      id,
      ...rest
    }: EventUpdateRequest): Promise<ApiClientResponse<UpdateEventResponse>> =>
      ApiClient.put(
        `/api/events/${id}`,
        helpers.updateCreateEventShiftPayload(rest)
      ).then(helpers.updateResponse(helpers.updateEvent)),
    delete: (
      id: EventDeleteRequest
    ): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.delete(`/api/events/${id}`),
  },
  EmployeeDashboard: {
    getHigherDuties: ({
      user_id,
      date,
      ...restPayload
    }: HigherDutiesRequest): Promise<ApiClientResponse<HigherDutiesResponse>> =>
      ApiClient.get(`/api/employee/higher-duties/${user_id}`, {
        ...restPayload,
        date: date.format(SERVER_DAY_FORMAT),
      }),
    getAllowancesList: ({
      user_id,
      date,
      ...restPayload
    }: AllowancesRequest): Promise<ApiClientResponse<AllowancesResponse>> =>
      ApiClient.get(`/api/employee/allowances/${user_id}`, {
        ...restPayload,
        date: date.format(SERVER_DAY_FORMAT),
      }),
    getOverview: (): Promise<ApiClientResponse<Overview>> =>
      ApiClient.get('/api/employee/overview'),
    getEvents: ({
      period = { period: null, from: null, to: null },
      ...rest
    }: GetEventsRequest): Promise<
      ApiClientResponse<EmployeeDashboardGetEventsResponse>
    > => {
      return ApiClient.get('/api/employee/events', {
        period: {
          ...period,
          from: period.from
            ? period.from.format(SERVER_DAY_FORMAT)
            : period.from,
          to: period.to ? period.to.format(SERVER_DAY_FORMAT) : period.to,
        },
        ...rest,
      }).then(
        helpers.updateResponse(
          helpers.updateWithPagerResponse(helpers.updateEvent)
        )
      );
    },
    getMyRosters: (
      payload: EmployeeDashboardGetMyRostersRequest
    ): Promise<ApiClientResponse<EmployeeDashboardGetMyRostersListResponse>> =>
      /* TODO how to make it readable? */
      ApiClient.get(`/api/employee/shifts`, payload).then(
        ({
          data: { data, ...rest },
          warnings,
        }: ApiClientResponse<
          WithPagerServerResponse<EmployeeRosteredShift>
        >) => ({
          warnings,
          data: {
            data,
            pager: helpers.makeBackendPager(rest),
          },
        })
      ),
    getShiftTrades: (
      payload: ShiftTradesRequestPayload
    ): Promise<ApiClientResponse<EmployeeDashboardGetShiftTradesResponse>> => {
      return ApiClient.get('/api/employee/shift-trades', payload).then(
        helpers.updateResponse(helpers.updateShiftTrades)
      );
    },
    acceptShiftTrade: (
      payload: AcceptTradePayload
    ): Promise<ApiClientResponse<SuccessResponse>> => {
      return ApiClient.post(
        `api/employee/shift-trades/${payload.shift_trade_id}/accept`,
        payload
      );
    },
    createShiftTrade: ({
      shift_id,
      ...rest
    }: EmployeeDashboardCreateShiftTradeRequest): Promise<
      ApiClientResponse<SuccessResponse>
    > => {
      return ApiClient.post(`/api/employee/shifts/${shift_id}/trade`, rest);
    },
    acceptShift: (id: string): Promise<ApiClientResponse<SuccessResponse>> => {
      return ApiClient.post(`/api/rostered-shifts/${id}/accept`);
    },
    declineShift: (id: string): Promise<ApiClientResponse<SuccessResponse>> => {
      return ApiClient.post(`/api/rostered-shifts/${id}/decline`);
    },
    getSwapProposals: (
      payload: GetSwapProposalsPayload
    ): Promise<ApiClientResponse<SwapProposalsResponse>> =>
      ApiClient.get(`/api/employee/shift-trades/${payload.id}/my-options`),
    swapShifts: ({
      trade_id,
      proposal_id,
      ignore_errors,
    }: ShiftSwapPayload): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.put(`/api/employee/shift-trades/${trade_id}/proposals`, {
        rostered_shift_id: proposal_id,
        ignore_errors: ignore_errors,
      }),
    deleteShiftTrade: (
      id: string
    ): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.delete(`/api/employee/shift-trades/${id}`),
    getShiftProposals: (
      id: string
    ): Promise<ApiClientResponse<ShiftTradesList>> =>
      ApiClient.get(`/api/employee/shift-trades/${id}/proposals`).then(
        helpers.updateResponse(helpers.updateShiftTrades)
      ),
    select: (proposalId: string): Promise<ApiClientResponse<ShiftTradesList>> =>
      ApiClient.put(
        `/api/employee/shift-trades/proposals/${proposalId}/select`
      ).then(helpers.updateResponse(helpers.updateShiftTrades)),
    declineProposal: (
      proposalId: string
    ): Promise<ApiClientResponse<ShiftTradesList>> =>
      ApiClient.delete(
        `/api/employee/shift-trades/proposals/${proposalId}`
      ).then(helpers.updateResponse(helpers.updateShiftTrades)),
    getMyTimesheet: (
      payload: EmployeeDashboardGetMyTimesheetPayload
    ): Promise<ApiClientResponse<EmployeeDashboardGetMyTimesheetResponse>> =>
      ApiClient.get(
        '/api/employee/timesheets',
        helpers.updateEmployeeDashboardGetMyTimesheetPayload(payload)
      ).then(
        helpers.updateResponse(
          ({
            total_duration,
            ...restResponse
          }: WithPagerServerResponse<ServerEmployeeTimesheet> & {
            total_duration: number;
          }): WithPagerResponse<EmployeeTimesheet> & {
            totalDuration: number;
          } => {
            const updateWithPagerResponse = helpers.updateWithPagerResponse<
              ServerEmployeeTimesheet,
              EmployeeTimesheet
            >(helpers.updateTimesheet);

            return {
              totalDuration: total_duration,
              ...updateWithPagerResponse(restResponse),
            };
          }
        )
      ),
    createTimesheet: (
      payload: EmployeeDashboardCreateTimesheetRequest
    ): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.post(
        '/api/employee/timesheets',
        helpers.updateCreateTimesheetPayload(payload)
      ),
    getUnavailabilityList: (
      payload: string
    ): Promise<ApiClientResponse<EmployeeUnavailabilityResponse>> =>
      ApiClient.get(
        `/api/employee/time-offs/unavailability?date=${payload}`
      ).then(helpers.updateResponse(helpers.updateEmployeeUnavailabilities)),
    getSpreadOfHours: (
      payload: any
    ): Promise<ApiClientResponse<EmployeeSpreadOfHoursResponse[]>> =>
      ApiClient.get(`api/employee/spread-of-hours`, payload),
    getUnavailabilityById: (
      payload: string
    ): Promise<ApiClientResponse<EmployeeUnavailabilityProps>> =>
      ApiClient.get(`/api/employee/time-offs/unavailability/${payload}`).then(
        helpers.updateResponse(helpers.updateEmployeeUnavailability)
      ),
    createUnavailability: (
      payload: EmployeeUnavailabilityRequestPayload
    ): Promise<ApiClientResponse<EmployeeUnavailabilityProps>> =>
      ApiClient.post('/api/employee/time-offs/unavailability', payload).then(
        helpers.updateResponse(helpers.updateEmployeeUnavailabilityOnce)
      ),
    updateUnavailability: (
      payload: EmployeeUnavailabilityRequestPayload
    ): Promise<ApiClientResponse<EmployeeUnavailabilityProps>> =>
      ApiClient.put(
        `/api/employee/time-offs/unavailability/${payload.id}`,
        payload
      ).then(helpers.updateResponse(helpers.updateEmployeeUnavailabilityOnce)),
    deleteUnavailability: (
      payload: string
    ): Promise<ApiClientResponse<EmployeeUnavailabilityProps>> =>
      ApiClient.delete(`/api/employee/time-offs/unavailability/${payload}`),
  },
  PayEntries: {
    getList: (
      payload: PayEntriesGetListRequest
    ): Promise<ApiClientResponse<PayEntriesGetListResponse>> =>
      ApiClient.get(
        '/api/pay-entries',
        helpers.updatePayEntriesGetListPayload(payload)
      ).then(
        helpers.updateResponse((data: StringMap<ServerPayEntry>) =>
          _.mapValues(data, helpers.updatePayEntry)
        )
      ),
    confirm: ({
      payEntryIds,
    }: PayEntriesConfirmRevertRequest): Promise<
      ApiClientResponse<SuccessResponse>
    > => ApiClient.put('/api/pay-entries/confirm', payEntryIds),
    revert: ({
      payEntryIds,
    }: PayEntriesConfirmRevertRequest): Promise<
      ApiClientResponse<SuccessResponse>
    > => ApiClient.put('/api/pay-entries/confirm/revert', payEntryIds),
    regenerateCostCentre: (): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.put('/api/pay-entries/regenerate-cost-centre'),
  },
  NotificationsList: {
    getList: (
      payload: NotificationsListRequestPayload
    ): Promise<ApiClientResponse<NotificationsListResponse>> =>
      ApiClient.get('/api/notifications/index', payload),
    markNotification: (id: string): Promise<ApiClientResponse<any>> =>
      ApiClient.put(`/api/notifications/${id}/read`),
    deleteNotification: (
      id: string
    ): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.delete(`/api/notifications/${id}/delete`),
    readAll: (
      type: NotificationListType
    ): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.put(`/api/notifications/read-all`, {
        type: type,
      }),
    deleteAll: (
      type: NotificationListType
    ): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.delete(`/api/notifications/delete-all`, {
        type: type,
      }),
  },
  PayElements: {
    getAll: (): Promise<ApiClientResponse<PayElement[]>> =>
      ApiClient.get('/api/pay-elements'),
    create: (
      payload: PayElementsCreateRequest
    ): Promise<ApiClientResponse<PayElement>> =>
      ApiClient.post('/api/pay-elements', payload),
    update: ({
      id,
      ...restPayload
    }: PayElementsUpdateRequest): Promise<ApiClientResponse<PayElement>> =>
      ApiClient.put(`/api/pay-elements/${id}`, restPayload),
    delete: ({
      id,
    }: PayElementsDeleteRequest): Promise<ApiClientResponse<SuccessResponse>> =>
      ApiClient.delete(`/api/pay-elements/${id}`),
  },
  Payment: {
    getAssignedUsers: (): Promise<
      ApiClientResponse<PaymentGetAssignedUsersResponse>
    > => ApiClient.get('/api/payments/assigned-users'),
    putAssignedUsers: (
      payload: PaymentPutAssignedUsersRequest
    ): Promise<ApiClientResponse<PaymentPutAssignedUsersResponse>> =>
      ApiClient.put('/api/payments/assigned-users', payload),
  },
  FeatureFlags: {
    get: (): Promise<ApiClientResponse<any>> =>
      ApiClient.get('/api/feature-flags'),
  },
};

export * from './type';
export default Api;
