import React from 'react';
import { Radio, RadioButtons } from 'elmo-elements';
import { InfoOutlinedIcon } from 'element/icons';
import './style.scss';

export const InfoIcon = () => {
  return (
    <RadioButtons
      selected={0}
      type={'solid'}
      className={'standalone-info-icon'}
      disabled={true}
    >
      <Radio value={'info'}>
        <InfoOutlinedIcon />
      </Radio>
    </RadioButtons>
  );
};
