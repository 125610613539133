import { TimeOffRepeat } from 'type';

export const TIME_OFF_REPEATS: {
  [key in TimeOffRepeat]: {
    label: string;
  }
} = {
  once: {
    label: 'Once'
  },
  daily: {
    label: 'Daily'
  },
  weekly: {
    label: 'Weekly'
  },
  fortnightly: {
    label: 'Fortnightly'
  },
  four_weekly: {
    label: '4-weekly'
  }
};
