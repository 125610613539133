import { SafeDictionary } from 'ts-essentials';
import { flatten } from 'lodash';
import { ApiClientResponse, ServerResponse } from 'type';

type StatusHandlers = SafeDictionary<
  (
    response: ServerResponse<any>
  ) => ApiClientResponse<any> | Promise<ApiClientResponse<any>> | void,
  number
>;

class ShiftOverlapError extends Error {
  response = {
    status: 202,
  };

  constructor(message: string, title: string) {
    super(message);
    this.name = title;
  }
}

export const defaultResponseTransformer = ({
  warnings,
  data,
}: ServerResponse<any>): ApiClientResponse<any> => ({
  data,
  warnings,
});

const responseTransformers: StatusHandlers = {
  202: (response) => {
    const { confirmations, error_message } = response;

    const confirmationMsg = flatten(confirmations).join('\n');
    if (error_message && confirmations && confirmations.length) {
      throw new ShiftOverlapError(confirmationMsg, error_message);
    }
    throw new ShiftOverlapError(confirmationMsg, '');
  },
};

export default responseTransformers;
