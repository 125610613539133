import React, { Component } from 'react';
import { NativeTable } from 'elmo-elements';
import {
  getFormattedNumber,
  getDateTimeFormatted,
  getCurrencyFormatted,
} from 'lib/helpers';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import {
  getCurrentPageRows,
  getReportTotals,
  getReportPagination,
} from 'state/Report/selectors';
import { ReportPagination } from 'state/Report/state';
import {
  PreferencesCurrencyCode,
  PreferencesCurrencyPlacement,
  PreferencesDateFormat,
  PreferencesNumberFormat,
  PreferencesTimeFormat,
  ReportType,
} from 'type/models';
import {
  getCurrencyCode,
  getCurrencyPlacement,
  getDateFormat,
  getNumberFormat,
  getTimeFormat,
} from 'state/Account';
import { withFeatureFlags } from 'flags';
import { ExpectedFlag } from 'feature-flags';

type Props = {
  visibleColumns: any[];
  pageRows: any[];
  totals: any;
  showTotalRow?: boolean;
  pagination: ReportPagination;
  type: ReportType;
  dateFormat: PreferencesDateFormat;
  timeFormat: PreferencesTimeFormat;
  numberFormat: PreferencesNumberFormat;
  currencyCode: PreferencesCurrencyCode;
  currencyPlacement: PreferencesCurrencyPlacement;
} & {
  flags: {
    reportDatetime: ExpectedFlag;
  };
};

export class CommonUngroupedRows extends Component<Props> {
  render() {
    const { pageRows, visibleColumns } = this.props;

    return (
      <>
        {pageRows.map((reportRow: any, reportRowIndex: number) => (
          <NativeTable.Tr key={`row-${reportRowIndex}`}>
            {visibleColumns.map((column: any, rowKey: number) => (
              <NativeTable.Td key={`${reportRowIndex}-${rowKey}`}>
                {this.showCellData(column.name, reportRow[column.name])}
              </NativeTable.Td>
            ))}
          </NativeTable.Tr>
        ))}
        {this.showTotal() && this.getTotalHoursView()}
      </>
    );
  }

  isMoney = (name: string) => {
    const { visibleColumns } = this.props;
    for (let column of visibleColumns) {
      if (column.name === name && column.is_money) {
        return true;
      }
    }
  };

  showTotal = () => {
    const {
      showTotalRow,
      pagination: { endItem, totalResults },
      type,
    } = this.props;
    return showTotalRow && totalResults <= endItem && type !== 'payroll';
  };

  showCellData = (name: string, data: any) => {
    const {
      type,
      dateFormat,
      timeFormat,
      numberFormat,
      currencyCode,
      currencyPlacement,
    } = this.props;
    if (this.isMoney(name)) {
      return getCurrencyFormatted(
        numberFormat,
        currencyCode,
        currencyPlacement,
        data
      );
    }
    const outputString = (v: string) => (v.length ? v : '-');
    let val =
      typeof data === 'string'
        ? outputString(data)
        : getFormattedNumber(numberFormat, +data);
    if (
      name === 'created_at' ||
      name === 'updated_at' ||
      name === 'date_of_birth'
    ) {
      if (name === 'date_of_birth' && data === '') {
        return '-';
      }
      if (name === 'date_of_birth') {
        return getDateTimeFormatted(dateFormat, timeFormat, data, false);
      }
      return getDateTimeFormatted(dateFormat, timeFormat, data, true);
    }
    if (this.props.flags.reportDatetime) {
      return val;
    } else {
      if (type === 'timesheet' && (name === 'start' || name === 'end')) {
        return data;
      }
      return name === 'start' || name === 'end'
        ? getDateTimeFormatted(dateFormat, timeFormat, data, true)
        : val;
    }
  };

  getTotalHoursView = () => {
    const { visibleColumns, numberFormat } = this.props;
    const hours = this.props.totals.hours || [];
    const colspan = visibleColumns.length;
    return (
      <NativeTable.Tr>
        <NativeTable.Td
          className={'total-hours'}
          colspan={colspan}
          textAlign={'right'}
        >
          Total hours:{' '}
          {hours.indexOf(':') !== -1
            ? hours
            : getFormattedNumber(numberFormat, +hours)}
        </NativeTable.Td>
      </NativeTable.Tr>
    );
  };
}

const mapToStateProps = (state: StoreState) => ({
  pageRows: getCurrentPageRows(state),
  totals: getReportTotals(state),
  pagination: getReportPagination(state),
  type: state.report.report.type,
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
  numberFormat: getNumberFormat(state),
  currencyCode: getCurrencyCode(state),
  currencyPlacement: getCurrencyPlacement(state),
});

export default connect(mapToStateProps)(withFeatureFlags(CommonUngroupedRows));
