import { Moment } from 'moment';
import { IsResizable } from '../../../types';

const getStartEndDayLimit = (day: Moment) => ({
  start: day.clone().startOf('day'),
  end: day
    .clone()
    .startOf('day')
    .add(1, 'day')
});

const limitByTime = (
  value: Moment,
  limits: {
    start: Moment;
    end: Moment;
  }
): { value: Moment; wasLimited: boolean } => {
  if (value.isBefore(limits.start)) {
    return {
      value: limits.start.clone(),
      wasLimited: true
    };
  } else if (value.isAfter(limits.end)) {
    return {
      value: limits.end.clone(),
      wasLimited: true
    };
  } else {
    // is between
    return {
      value,
      wasLimited: false
    };
  }
};

export const limitByDay = (props: {
  start: Moment;
  end: Moment;
  day: Moment;
}) => {
  const limit = getStartEndDayLimit(props.day);

  const limitedStart = limitByTime(props.start, limit);
  const limitedEnd = limitByTime(props.end, limit);

  const limitedStartEnd = {
    start: limitedStart.value,
    end: limitedEnd.value
  };

  const isResizable: IsResizable = {
    left: !limitedStart.wasLimited,
    right: !limitedEnd.wasLimited
  };

  return { ...limitedStartEnd, isResizable };
};
