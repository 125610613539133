import React from 'react';
import { useSelectorWithProps } from 'hooks';
import { timeOffById } from 'state/TimeOffs';
import { createStyles, makeStyles } from '@mui/styles';
import useHandleClickAddButton from './hooks/useHandleClickAddButton';
import { TimeDetails, TimeOffCardPopperAdd } from './components';
import clsx from 'clsx';

type Props = {
  id: string;
  day: string;
};

const useTimeOffStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: 'inherit',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      '&:hover $btn': {
        opacity: 1,
        visibility: 'visible',
      },
    },
    btn: {},
  })
);

export const SingleTimeOffCard = ({ id, day }: Props) => {
  const styles = useTimeOffStyles();
  const timeOff = useSelectorWithProps(timeOffById, id);
  const onClickAddBtn = useHandleClickAddButton(timeOff, day);
  return (
    <div
      onDoubleClick={(e) => e.stopPropagation()}
      data-testid={`single-time-off single-time-off-${id}`}
      className={clsx(styles.root, 'single-time-off')}
    >
      <TimeDetails
        start={timeOff.start}
        end={timeOff.end}
        type={timeOff.type}
      />
      <TimeOffCardPopperAdd onClick={onClickAddBtn} className={styles.btn} />
    </div>
  );
};
