import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button } from 'elmo-elements';
import './style.scss';
import {
  ContentCopyOutlinedIcon,
  MoreHorizIcon,
  PasteIcon,
  SaveAsIcon,
  FileCopyOutlinedIcon,
} from 'element';
import {
  CustomDropdown,
  CustomDropdownOptionType,
} from 'element/CustomDropdown';
import { connect } from 'react-redux';
import { Moment } from 'moment';
import { StoreState } from 'state/types';
import { LanguagePreferences, RosteredShift } from 'type';
import { hasPermissionSelector } from 'state/Auth';
import { getFrom, getSiteId, getTo } from 'state/RosteredShifts';
import { rosteredShiftsArrayFilteredByFromSelector } from 'state/Roster/RosterWeekView';
import {
  BOX_ROSTER_COPY_WEEK,
  BOX_ROSTER_COPY_WEEK_CLEAR,
  BOX_ROSTER_OPEN_SAVE_TEMPLATE_MODAL,
  BOX_ROSTER_OPEN_TEMPLATES_MODAL,
  BOX_ROSTER_PAST_CONFIRMATION_MODAL_CLOSE,
  BOX_ROSTER_PAST_CONFIRMATION_MODAL_OPEN,
  BOX_ROSTER_PAST_WEEK_REQUEST,
  CopyPayload,
  copyRosterSiteId,
  getCopyRosterConfirmationModal,
  getIsLoading,
  isSelectedCurrentWeek,
  PastModal,
  PastRostersType,
} from 'state/Roster/RosterCopyPast';
import {
  CreateRosterTemplateModal,
  PastConfirmationModal,
  TemplatesModal,
} from './components';
import { getPreferenceLabel } from 'lib/helpers';
import { getLangPreferences } from '../../../../../../state/Account';
import { getIsBulkDeleteOpened } from '../../../../../../state/Roster/BulkActions/selectors';
import { isAppMarket, isHrService } from '../../../../../../helpers';
import clsx from 'clsx';
import { SafeDictionary } from 'ts-essentials';

type OwnProps = {
  className?: string;
  classes?: SafeDictionary<string, 'root' | 'button'>
};

type DispatchProps = {
  copyWeek: (data: CopyPayload) => void;
  pastWeek: (data: PastRostersType) => void;
  openPasConfirmation: () => void;
  closePasConfirmation: () => void;
  clear: () => void;
  saveWeek: () => void;
  openModal: () => void;
};

type StateProps = {
  shifts: RosteredShift[];
  canEditRosteredShifts: boolean;
  site_id: string;
  from: Moment;
  to: Moment;
  selectedSite: string | null;
  modal: PastModal;
  isSelectedCurrent: boolean;
  isLoading: boolean;
  langPreferences: LanguagePreferences;
  isBulkMode: boolean;
};

type Props = OwnProps & DispatchProps & StateProps;

type Action = 'copy-week' | 'past-week' | 'past-unassigned' | 'save-week';

export function RosterCopyPast(props: Props) {
  const {
    site_id,
    from,
    to,
    copyWeek,
    pastWeek,
    saveWeek,
    shifts,
    canEditRosteredShifts,
    selectedSite,
    modal,
    openPasConfirmation,
    closePasConfirmation,
    clear,
    isSelectedCurrent,
    openModal,
    isLoading,
    isBulkMode,
    className,
    classes,
  } = props;

  const isBravo = isHrService('bravo');
  const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false);
  const [isCopyDisabled, setIsCopyDisabled] = useState<boolean>(true);
  const [unassign, setUnassign] = useState<boolean>(false);
  const [isCreateDisabled, setCreateDisabled] = useState<boolean>(true);

  const onEsc = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      clear();
    }
  };

  const getTitle = (plural = false) => {
    const labelText = isAppMarket('uk') ? 'rota' : 'roster';

    const label = getPreferenceLabel(
      props.langPreferences,
      labelText,
      plural ? 'plural' : 'singular',
      labelText,
      true
    );

    return `${label}`;
  };

  useEffect(() => {
    setCreateDisabled(shifts.length === 0);
    setIsCopyDisabled(!shifts.length);
    document.addEventListener('keydown', onEsc, false);
    return () => document.removeEventListener('keydown', onEsc, false);
  }, [shifts, onEsc]);

  const getActions = () => {
    return {
      'save-week': () => saveWeek(),
      'copy-week': () => copyWeek({ site_id, range: { from, to } }),
      'past-week': () => {
        setUnassign(false);
        openPasConfirmation();
      },
      'past-unassigned': () => {
        setUnassign(true);
        openPasConfirmation();
      },
    };
  };
  const getOptions = (): CustomDropdownOptionType<Action>[] => {
    return [
      {
        label: (
          <div data-testid="save-roster-template-btn">
            <SaveAsIcon /> Save Weekly {getTitle()} Template
          </div>
        ),
        value: 'save-week',
        isDisabled: isCreateDisabled || isBulkMode,
      },
      {
        label: (
          <div data-testid="copy-week-btn">
            <ContentCopyOutlinedIcon /> Copy week
          </div>
        ),
        value: 'copy-week',
        isDisabled: isCopyDisabled || isBulkMode,
      },
      {
        label: (
          <div data-testid="past-week-btn">
            <PasteIcon /> Paste
          </div>
        ),
        value: 'past-week',
        isDisabled: !selectedSite || isSelectedCurrent || isBulkMode,
      },
      {
        label: (
          <div data-testid="past-and-unassign-btn">
            <PasteIcon /> Paste & Unassign
          </div>
        ),
        value: 'past-unassigned',
        isDisabled: !selectedSite || isSelectedCurrent || isBulkMode,
      },
    ];
  };
  return (
    <>
      {canEditRosteredShifts && (
        <div className={clsx('roster-copy-paste', classes?.root, className)}>
          <Button
            icon={<FileCopyOutlinedIcon />}
            className={clsx(
              'light-button',
              'roster-copy-paste__copy-btn',
              classes?.button
            )}
            id="roster-template"
            type="default"
            onClick={openModal}
            isDisabled={isBulkMode}
            isUppercase={!isBravo}
          >
            {getTitle()} Template
          </Button>
          <div>
            <CustomDropdown
              isOpened={isDropdownOpened}
              options={getOptions()}
              onClose={() => {
                setIsDropdownOpened(false);
              }}
              onSelect={(value: Action) => {
                setIsDropdownOpened(false);
                getActions()[value]();
              }}
            >
              <Button
                className="roster-copy-paste__options-btn light-button"
                id="copy-week-actions"
                type="default"
                icon={<MoreHorizIcon />}
                onClick={() => {
                  setIsDropdownOpened(true);
                }}
              />
            </CustomDropdown>
          </div>
        </div>
      )}
      <PastConfirmationModal
        isLoading={isLoading}
        isOpened={modal.isOpened}
        onClose={closePasConfirmation}
        onApprove={() => {
          pastWeek({
            unassign,
            target: from,
          });
        }}
      />
      <CreateRosterTemplateModal />
      <TemplatesModal />
    </>
  );
}

const mapStateToProps = (state: StoreState): StateProps => ({
  shifts: rosteredShiftsArrayFilteredByFromSelector(state),
  canEditRosteredShifts: hasPermissionSelector(
    state,
    'roster.rosteredshift.edit'
  ),
  site_id: getSiteId(state),
  from: getFrom(state),
  to: getTo(state),
  selectedSite: copyRosterSiteId(state),
  modal: getCopyRosterConfirmationModal(state),
  isSelectedCurrent: isSelectedCurrentWeek(state),
  isLoading: getIsLoading(state),
  langPreferences: getLangPreferences(state),
  isBulkMode: getIsBulkDeleteOpened(state),
});

const mapDispatchToProps: DispatchProps = {
  copyWeek: BOX_ROSTER_COPY_WEEK,
  pastWeek: BOX_ROSTER_PAST_WEEK_REQUEST,
  openPasConfirmation: BOX_ROSTER_PAST_CONFIRMATION_MODAL_OPEN,
  closePasConfirmation: BOX_ROSTER_PAST_CONFIRMATION_MODAL_CLOSE,
  clear: BOX_ROSTER_COPY_WEEK_CLEAR,
  saveWeek: BOX_ROSTER_OPEN_SAVE_TEMPLATE_MODAL,
  openModal: BOX_ROSTER_OPEN_TEMPLATES_MODAL,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RosterCopyPast);
