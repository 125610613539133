import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import View, { Props as ViewProps } from './view';
import { getSites } from 'state/AccountTree';
import { AccountTreeSite, StringMap } from 'type';
import { getShiftTradesCustomFormFilter } from 'state/EmployeeDashboard/ShiftTrades/selectors';
import {
  BOX_SHIFT_TRADES_TAB_CHANGED_FROM_CUSTOM_FILTERS,
  BOX_SHIFT_TRADES_UPDATE_FORM_FILTERS,
} from 'state/EmployeeDashboard/ShiftTrades';

type StateProps = Pick<ViewProps, 'formData'>;
type DispatchProps = Pick<ViewProps, 'onChange'> & Pick<ViewProps,  'onTabChange'>;
type SitesProps = {
  sites: StringMap<AccountTreeSite>;
}

type Props = StateProps & DispatchProps & SitesProps;

type StateSiteProps = StateProps & SitesProps;

const CustomFiltersComponent = (props: Props) => <View {...props} />;

const mapStateToProps = (state: StoreState): StateSiteProps => ({
  formData: getShiftTradesCustomFormFilter(state),
  sites: getSites(state),
});

const mapDispatchToProps: DispatchProps = {
  onChange: BOX_SHIFT_TRADES_UPDATE_FORM_FILTERS,
  onTabChange: BOX_SHIFT_TRADES_TAB_CHANGED_FROM_CUSTOM_FILTERS
};

export const CustomFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomFiltersComponent);
