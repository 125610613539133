import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'elmo-elements';
import { NotificationGroup } from 'type';
import {
  ErrorBox,
  PageDialog,
  PageDialogCancel,
  PageDialogSubmit,
} from 'element';
import { StoreState } from 'state/types';
import {
  BOX_NOTIFICATION_EDIT_MODAL_CLEAR_ERRORS,
  BOX_NOTIFICATION_EDIT_MODAL_CLOSE,
  BOX_UPDATE_NOTIFICATION_REQUEST,
  EditableNotificationMessage,
  EditModal as EditModalType,
  getEditModal,
  getGroup,
  getIsFetched,
  getMessageSelector,
} from 'state/Settings/Notification';
import Ckeditor from '../ckeditor';
import { isHrService } from '../../../../../../../../helpers';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from '../../../../../../../../extended-oxygen-elements';

type Props = {
  group: NotificationGroup;
  groupId: string;
  notificationId: string;
  editModal: EditModalType;
  clearErrors: () => void;
  closeEditModal: () => void;
  updateNotification: Function;
  isFetched: boolean;
  message: EditableNotificationMessage;
};

type State = {
  group: NotificationGroup;
  message: EditableNotificationMessage;
};

export class EditModalComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      group: props.group,
      message: props.message,
    };
  }

  componentDidUpdate(prevProps: Props): void {
    if (!this.props.editModal.isOpened && prevProps !== this.props) {
      this.setState({
        group: this.props.group,
        message: this.props.message,
      });
    }
  }

  render() {
    return (
      <div className="notification-modals">
        <PageDialog
          maxWidth={'sm'}
          open={this.props.editModal.isOpened}
          id="ModalFull"
          onClose={this.props.closeEditModal}
          disableEnforceFocus={true}
        >
          <DialogTitle>{this.props.message.subject}</DialogTitle>
          <DialogContent>
            <ErrorBox
              errors={this.props.editModal.errors}
              clearErrors={this.props.clearErrors}
              watchFormErrors={true}
            />
            {this.props.isFetched && this.props.editModal.initEditor ? (
              <>
                <Ckeditor
                  title="Email message"
                  type="email"
                  content={this.state.message.email}
                  variables={this.state.message.variables}
                  classNames="ck-editor"
                  onChange={(e: string) => {
                    this.onCkEditorChange(e, 'email');
                  }}
                />
                {!isHrService('bravo') && (
                  <Ckeditor
                    title="SMS message"
                    type="sms"
                    content={this.state.message.sms}
                    variables={this.state.message.variables}
                    classNames="ck-editor"
                    onChange={(e: string) => {
                      this.onCkEditorChange(e, 'sms');
                    }}
                  />
                )}
              </>
            ) : (
              ''
            )}
          </DialogContent>
          <DialogActions>
            <PageDialogCancel
              onClick={this.props.closeEditModal}
              fullWidth={false}
            >
              Cancel
            </PageDialogCancel>
            <PageDialogSubmit
              onClick={this.saveMessage}
              loading={this.props.editModal.isUpdating}
              id={'save-notification-settings-btn'}
              fullWidth={false}
            >
              Save
            </PageDialogSubmit>
          </DialogActions>
        </PageDialog>
      </div>
    );
  }

  onCkEditorChange = (message: string, field: string) => {
    this.setState({
      message: {
        ...this.state.message,
        [field]: message,
      },
    });
  };

  saveMessage = () => {
    const { groupId, notificationId } = this.props;
    const data = {
      params: `${groupId}/${notificationId}/${this.state.message.key}`,
      message: this.state.message,
    };
    this.props.updateNotification(data);
  };
}

const mapStateToProps = (state: StoreState) => ({
  group: getGroup(state),
  editModal: getEditModal(state),
  isFetched: getIsFetched(state),
  message: getMessageSelector(state),
});

export const EditModal = connect(mapStateToProps, {
  clearErrors: BOX_NOTIFICATION_EDIT_MODAL_CLEAR_ERRORS,
  closeEditModal: BOX_NOTIFICATION_EDIT_MODAL_CLOSE,
  updateNotification: BOX_UPDATE_NOTIFICATION_REQUEST,
})(EditModalComponent);
