import { Box, IconButton } from 'extended-oxygen-elements';
import { ArrowBackIcon } from 'element';
import { useRoute } from 'hooks';
import React from 'react';
import { privateRoutes } from 'routes';

export type SettingsHeaderBackBtnProps = {
  onClick?: () => void;
};

export default SettingsHeaderBackBtn;

export function SettingsHeaderBackBtn(props: SettingsHeaderBackBtnProps) {
  const route = useRoute();

  const handleBackButtonClick = () => {
    if (props.onClick) {
      props.onClick();
    }
    route.goTo(privateRoutes.settings.routes.menu.path);
  };

  return (
    <Box mr={1}>
      <IconButton onClick={handleBackButtonClick} edge="start">
        <ArrowBackIcon />
      </IconButton>
    </Box>
  );
}
