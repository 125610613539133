import React from 'react';
import { Card, Col, Paragraph, Row, Text } from 'elmo-elements';
import { StoreState } from 'state/types';
import {
  FormattedErrors,
  LanguagePreferences,
  PreferencesCurrencyCode,
  PreferencesCurrencyPlacement,
  PreferencesNumberFormat,
  UserProfileFields,
} from 'type/models';
import { connect } from 'react-redux';
import { getFormattedNumber, getPreferenceLabel } from 'lib/helpers';
import {
  getCurrencyCode,
  getCurrencyPlacement,
  getLangPreferences,
  getNumberFormat,
} from 'state/Account';
import { isAppMarket, isHrService } from '../../../../../../../../helpers';
import { EditButton } from '../EditButton';
import {
  BOX_USER_PROFILE_REMOVE_ERRORS,
  BOX_USER_PROFILE_RULES_MODAL_CLOSE,
  BOX_USER_PROFILE_RULES_MODAL_OPEN,
  BOX_USER_PROFILE_UPDATE_REQUEST,
} from 'state/Users/UserProfile/actions';
import { RosterRulesModal } from './components/RosterRulesModal';
import { fieldsToUpdate, hasDifference } from '../../helpers';

type StateProps = {
  userProfile: UserProfileFields;
  langPreferences: LanguagePreferences;
  numberFormat: PreferencesNumberFormat;
  currencyCode: PreferencesCurrencyCode;
  currencyPlacement: PreferencesCurrencyPlacement;
  isOpened: boolean;
  isUpdating: boolean;
  errors: FormattedErrors;
};

type DispatchProps = {
  openModal: () => void;
  closeModal: () => void;
  updateProfile: (params: Partial<UserProfileFields>) => void;
  clearErrors: () => void;
};

type Props = StateProps & DispatchProps;

type State = {
  profile: UserProfileFields;
};

export class RosterDetailsComponent extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      profile: props.userProfile
    }
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if ( this.props.isOpened !== prevProps.isOpened && !this.props.isOpened ) {
      this.setState({
        ...this.state,
        profile: this.props.userProfile
      });
    }
  }

  render() {
    const { roster_settings } = this.props.userProfile;
    const { langPreferences, numberFormat } = this.props;
    const rosterLabel = isAppMarket('uk') ? 'rota' : 'roster';
    const option = isHrService('standalone') && this.props.userProfile.is_active
      ? <EditButton onClick={this.props.openModal} ariaLabel="Edit"/>
      : undefined;
    return (
      <>
        <Card
          isFullHeight={true}
          heading={`${this.blockTitle}`}
          role="grid"
          option={option}
          id={'roster-details-card'}
        >
          <Row>
            <Col xs={12} span={24}>
              <Text size={'sm'}>
                Max hrs /{' '}
                {getPreferenceLabel(langPreferences, rosterLabel, 'singular', rosterLabel)} period
              </Text>
              <Paragraph className={'max-hrs-period'}>
                {getFormattedNumber(
                  numberFormat,
                  roster_settings.max_hrs_per_roster_period
                )}
              </Paragraph>
              <br />
              <Text size={'sm'}>Max hrs / day</Text>
              <Paragraph className={'max-hrs-day'}>
                {getFormattedNumber(
                  numberFormat,
                  roster_settings.max_hrs_per_day
                )}
              </Paragraph>
              <br />
              <Text size={'sm'}>Max shift duration</Text>
              <Paragraph className={'max-shift-duration'}>
                {getFormattedNumber(
                  numberFormat,
                  roster_settings.max_shift_duration
                )}
              </Paragraph>
              <br />
              <Text size={'sm'}>Min break between 2 shift</Text>
              <Paragraph className={'min-break-between'}>
                {getFormattedNumber(
                  numberFormat,
                  roster_settings.min_break_between_shifts
                )}
              </Paragraph>
              <br />
              <Text size={'sm'}>
                Max. long-hr shifts /{' '}
                {getPreferenceLabel(langPreferences, rosterLabel, 'singular', rosterLabel)} period
              </Text>
              <Paragraph className={'max-long-hrs'}>
                {getFormattedNumber(
                  numberFormat,
                  roster_settings.max_long_hour_shifts_per_roster_period
                )}
              </Paragraph>
            </Col>
            <br />
            <Col xs={12} span={24}>
              <Text size={'sm'}>
                Min hrs /{' '}
                {getPreferenceLabel(langPreferences, rosterLabel, 'singular', rosterLabel)} period
              </Text>
              <Paragraph className={'min-hrs-period'}>
                {getFormattedNumber(
                  numberFormat,
                  roster_settings.min_hrs_per_roster_period
                )}
              </Paragraph>
              <br />
              <Text size={'sm'}>Min hrs / day</Text>
              <Paragraph className={'min-hrs-day'}>
                {getFormattedNumber(
                  numberFormat,
                  roster_settings.min_hrs_per_day
                )}
              </Paragraph>
              <br />
              <Text size={'sm'}>Min shift duration</Text>
              <Paragraph className={'min-shift-duration'}>
                {getFormattedNumber(
                  numberFormat,
                  roster_settings.min_shift_duration
                )}
              </Paragraph>
              <br />
              <Text size={'sm'}>Max consecutive long-hr shifts</Text>
              <Paragraph className={'max-consecutive-hrs'}>
                {getFormattedNumber(
                  numberFormat,
                  roster_settings.max_consecutive_long_hour_shifts
                )}
              </Paragraph>
              <br />
            </Col>
          </Row>
        </Card>
        <RosterRulesModal
          isOpened={this.props.isOpened}
          closeModal={this.props.closeModal}
          profile={this.state.profile}
          changeInput={this.changeInput}
          saveProfile={this.updateProfile}
          saveDisabled={this.props.isUpdating || !hasDifference(this.state.profile, this.props.userProfile)}
          isLoading={this.props.isUpdating}
          errors={this.props.errors}
          clearErrors={this.props.clearErrors}
          rosterLabel={getPreferenceLabel(langPreferences, rosterLabel, 'singular', rosterLabel)}
        />
      </>
    );
  }

  changeInput = (name: string, key: 'roster_rules' | 'ordinary_hours_of_work', value: string | number) => {
    this.setState({
      ...this.state,
      profile: {
        ...this.state.profile,
        [key]: {
          ...this.state.profile[key],
          [name]: value
        }
      }
    });
  };

  fixValue = (data: any) => {
    if ( data ) {
      const keys = Object.keys(data);
      for (let key of keys) {
        data[key] = isNaN(parseFloat(data[key])) || (parseFloat(data[key]) < 0) ? 0 : parseFloat(data[key]);
      }
      return data;
    }
    return {};
  };

  updateProfile = () => {
    const { id } = this.props.userProfile;
    const data = fieldsToUpdate(this.state.profile, this.props.userProfile);
    const rules = this.fixValue(data['roster_rules']);
    const hours = this.fixValue(data['ordinary_hours_of_work']);
    this.props.updateProfile({
      id: id,
      roster_rules: Object.keys(rules).length ? rules : this.props.userProfile.roster_rules,
      ordinary_hours_of_work: Object.keys(hours).length ? hours : this.props.userProfile.ordinary_hours_of_work,
      message: `${this.blockTitle} updated`
    });
  };

  get blockTitle() {
    const rosterLabel = isAppMarket('uk') ? 'rota' : 'roster';
    return `${getPreferenceLabel(
      this.props.langPreferences,
      rosterLabel,
      'singular',
      rosterLabel,
      true
    )} rules / ordinary hours`;
  };

}

const mapStateToProps = (state: StoreState): StateProps => ({
  userProfile: state.userProfile.userProfile,
  langPreferences: getLangPreferences(state),
  numberFormat: getNumberFormat(state),
  currencyCode: getCurrencyCode(state),
  currencyPlacement: getCurrencyPlacement(state),
  isOpened: state.userProfile.rulesModal.isOpened,
  isUpdating: state.userProfile.isUpdating,
  errors: state.userProfile.errors
});

const mapDispatchToProps: DispatchProps = {
  openModal: BOX_USER_PROFILE_RULES_MODAL_OPEN,
  closeModal: BOX_USER_PROFILE_RULES_MODAL_CLOSE,
  updateProfile: BOX_USER_PROFILE_UPDATE_REQUEST,
  clearErrors: BOX_USER_PROFILE_REMOVE_ERRORS
};

export const RosterDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(RosterDetailsComponent);
