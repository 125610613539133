import React from 'react';

type Props = {
  title: string;
};

export function withHeading(WrappedComponent: React.ComponentType<any>, props: Props) {

  class WithHeading extends React.Component {

    componentDidMount() {
      document.title = props.title;
    }

    componentWillUnmount() {
    }

    render() {
      const { ...rest } = this.props;

      return (
        <WrappedComponent {...rest} />
      );
    }

  }

  return WithHeading;
}

export default withHeading;
