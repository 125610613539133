import { every, identity, negate } from 'lodash';
import { createSelector } from 'reselect';
import { getCurrentUserPermissions } from 'state/Auth';
import { hasPermissionCombinerCreator } from 'state/combiners';
import { getIsApprovalModeEnabled } from '../../Roster';

const isEditableSelector = createSelector(
  getIsApprovalModeEnabled,
  negate(identity as any) as (val: boolean) => boolean
);

const hasTimesheetEditPermissionSelector = createSelector(
  getCurrentUserPermissions,
  hasPermissionCombinerCreator('roster.timesheet.edit')
);

const hasShiftEditPermissionSelector = createSelector(
  getCurrentUserPermissions,
  hasPermissionCombinerCreator('roster.rosteredshift.edit')
);

const isEditableCombiner = (...args: [boolean, boolean]) =>
  every(args, identity);

const isTimesheetsEditableSelector = createSelector(
  isEditableSelector,
  hasTimesheetEditPermissionSelector,
  isEditableCombiner
);

const isRosteredShiftsEditableSelector = createSelector(
  isEditableSelector,
  hasShiftEditPermissionSelector,
  isEditableCombiner
);

export { isRosteredShiftsEditableSelector, isTimesheetsEditableSelector };
