import { SERVER_DAY_FORMAT } from 'lib/config';
import routeQuery from 'lib/routeQuery';
import { times } from 'lodash';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { getSites } from 'state/AccountTree';
import { getIsBulkDeleteOpened } from 'state/Roster/BulkActions/selectors';
import { currentTimeBySiteTimezoneSelector } from 'state/Roster/Roster/selectors';
import { fromBySiteTimezoneSelector, getSiteId } from 'state/RosteredShifts';
import { StoreState } from 'state/types';
import { AccountTreeSite, StringMap } from 'type';
import { getIsCovidModeEnabled } from '../../../../../../state/Roster/CovidTracing';
import {
  RosterWeekNavigation,
  RosterWeekNavigationDay,
} from '../../../../components';
import './WeekNavigation.scss';

export type OwnProps = {
  dayViewPath: string;
};

export type StateProps = {
  site_id: string;
  startDate: Moment;
  sites: StringMap<AccountTreeSite>;
  isBulkDelete: boolean;
  currentTimeBySiteTimezone: Moment;
  isCovidMode: boolean;
};

export type DispatchProps = {};

export type Props = OwnProps & StateProps & DispatchProps;

export const WeekNavigationComponent = ({
  currentTimeBySiteTimezone,
  isBulkDelete,
  startDate,
  site_id,
  dayViewPath,
  isCovidMode,
}: Props) => {
  return <RosterWeekNavigation days={createDateDays()} />;

  function createDateDays(): RosterWeekNavigationDay[] {
    return times(7, (n): RosterWeekNavigationDay => {
      const day = moment.parseZone(startDate).add(n, 'day');

      const title = day.format('ddd DD');
      const current = currentTimeBySiteTimezone.isSame(day, 'day');
      const path =
        !isBulkDelete && !isCovidMode ? getDayPagePath(day) : undefined;
        
      return {
        title,
        current,
        path,
      };
    });
  }


  function getDayPagePath(dayMoment: Moment) {
    return (
      dayViewPath +
      routeQuery.stringify({
        site_id,
        day: dayMoment.format(SERVER_DAY_FORMAT),
      })
    );
  }
};

const mapStateToProps = (state: StoreState): StateProps => ({
  site_id: getSiteId(state),
  startDate: fromBySiteTimezoneSelector(state),
  sites: getSites(state),
  isBulkDelete: getIsBulkDeleteOpened(state),
  currentTimeBySiteTimezone: currentTimeBySiteTimezoneSelector(state),
  isCovidMode: getIsCovidModeEnabled(state),
});

export const WeekNavigation = connect(mapStateToProps)(WeekNavigationComponent);

export default WeekNavigation;
