import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { intersection } from 'lodash';
import { CalendarTodayIcon, TableChartOutlinedIcon } from 'element';
import { StringMap } from 'type';
import { StoreState } from 'state/types';
import { shiftTypesToBeShownSelector } from 'state/Roster/RosterFilters';
import { UserRosterDataDuration } from 'state/Roster/RosterDayView/types';

type Duration = {
  icon: ReactNode;
  quantity: number;
  time: string;
  isVisible: boolean;
};

type OwnProps = {
  duration: {
    roster: UserRosterDataDuration;
    timesheets?: UserRosterDataDuration;
  };
  children: (
    data: {
      durations: Duration[];
    }
  ) => ReactNode;
};

type StateProps = {
  shiftTypesToBeShown: string[];
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

class WithLegendDataComponent extends Component<Props> {
  private icons = {
    roster: <CalendarTodayIcon />,
    timesheets: <TableChartOutlinedIcon />
  };

  render() {
    const { durations } = this;

    return this.props.children({
      durations
    });
  }

  private get durations(): Duration[] {
    const { shiftTypesToBeShown, duration } = this.props;

    return intersection(shiftTypesToBeShown, ['roster', 'timesheets']).flatMap(
      shiftType => {
        const typeDuration:
          | UserRosterDataDuration
          | undefined = (duration as StringMap<UserRosterDataDuration>)[
          shiftType
        ];

        if (!typeDuration) {
          return [];
        }

        const { time, quantity } = typeDuration;

        return [
          {
            icon: (this.icons as StringMap<ReactNode>)[shiftType],
            time,
            quantity,
            isVisible: true
          }
        ];
      }
    );
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  shiftTypesToBeShown: shiftTypesToBeShownSelector(state)
});

// const mapDispatchToProps: DispatchProps = {};

export const WithLegendData = connect(mapStateToProps)(WithLegendDataComponent);
