import React from 'react';
import { CheckCircleOutlinedIcon } from 'element';
import { useSelectorWithProps } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { hasPermissionSelector } from 'state/Auth';
import { BOX_ROSTER_OPEN_APPLY_CHANGES_MODAL } from 'state/Roster/Roster';
import { getTimesheetsFilter } from 'state/Roster/RosterFilters';

export default useBtnApproveTimesheets;

export function useBtnApproveTimesheets() {
  const dispatch = useDispatch();
  const canApproveTimesheets = useSelectorWithProps(
    hasPermissionSelector,
    'roster.timesheet.edit.approve'
  );
  const isTimesheetsFilterEnabled = useSelector(getTimesheetsFilter);

  return (
    canApproveTimesheets &&
    isTimesheetsFilterEnabled && {
      id: 'approve-btn',
      icon: <CheckCircleOutlinedIcon />,
      label: 'Approve...',
      onClick: () => {
        dispatch(BOX_ROSTER_OPEN_APPLY_CHANGES_MODAL('approve'));
      },
    }
  );
}
