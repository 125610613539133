import { createSelector } from 'reselect';
import {
  BackendPager,
  EventsPeriod,
  EventsWithPagerSelector,
  FormattedErrors,
} from 'type';
import { StoreState } from '../../types';
import { Moment } from 'moment';
import {
  EventsFilters,
  EventsFiltersStatuses,
  MapModal,
  SortEventsList,
} from './types';
import { Event } from 'type/models';
import { GetEventsRequest } from 'lib/Api/type';

export const getErrors = (state: StoreState): FormattedErrors =>
  state.managerDashboard.events.errors;
export const getPager = (state: StoreState): BackendPager =>
  state.managerDashboard.events.pager;
export const getFiltersForm = (state: StoreState): EventsFilters =>
  state.managerDashboard.events.filtersForm;
export const getFilters = (state: StoreState): EventsFilters =>
  state.managerDashboard.events.filters;
export const getFiltersStatuses = (state: StoreState): EventsFiltersStatuses =>
  state.managerDashboard.events.filters.statuses;
export const getFiltersStart = (state: StoreState): Moment | null =>
  state.managerDashboard.events.filters.start;
export const getFiltersEnd = (state: StoreState): Moment | null =>
  state.managerDashboard.events.filters.end;
export const getFiltersSort = (state: StoreState): SortEventsList =>
  state.managerDashboard.events.filters.sort;
export const getEventsList = (state: StoreState): Event[] =>
  state.managerDashboard.events.events;
export const getSelectedPeriod = (state: StoreState): EventsPeriod =>
  state.managerDashboard.events.period;
export const getMapModal = (state: StoreState): MapModal =>
  state.managerDashboard.events.mapModal;

export const getUpdatedId = (state: StoreState) => state.managerDashboard.events.editedEventId;

export const eventsWithPagerSelector = createSelector<
  StoreState,
  Event[],
  BackendPager,
  EventsWithPagerSelector
>(getEventsList, getPager, (page, pager) => ({
  page,
  ...pager
}));

export const payloadSelector = createSelector<
  StoreState,
  BackendPager,
  EventsFiltersStatuses,
  Moment | null,
  Moment | null,
  SortEventsList,
  GetEventsRequest
>(
  getPager,
  getFiltersStatuses,
  getFiltersStart,
  getFiltersEnd,
  getFiltersSort,
  ({ currentPage, pageSize }, statuses, start, end, { column, direction }) => {
    return {
      page: currentPage,
      per_page: pageSize,
      start,
      end,
      statuses,
      order_by: column,
      order_dir: direction
    };
  }
);

export const isFiltersFormDisabledSelector = createSelector<
  StoreState,
  EventsFilters,
  boolean
>(getFiltersForm, ({ statuses }) => !!statuses && !statuses.length);
