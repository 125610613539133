import React, { Component, ReactChild } from 'react';
import { connect } from 'react-redux';
import { Filter, LoadingOverlay } from 'elmo-elements';
import { StoreState } from 'state/types';

import { CustomFilters } from './components/CustomFilters';

import {
  BOX_SHIFT_TRADES_CLEAR_FILTER_FORM,
  BOX_SHIFT_TRADES_TOGGLE_TRADES_TYPE_CUSTOM_REQUEST,
  BOX_SHIFT_TRADES_TOGGLE_TRADES_TYPE_REQUEST,
  BOX_SHIFT_TRADES_UPDATE_CUSTOM_FILTERS,
} from '../../../../../../../../state/EmployeeDashboard/ShiftTrades';
import { ShiftTradesFilter } from 'state/EmployeeDashboard/ShiftTrades/types';
import {
  getIsFiltersFormDisabled,
  isTabChangedFromCustomFilters,
} from 'state/EmployeeDashboard/ShiftTrades/selectors';
import { getIsLoading } from '../../../../../../../../state/IsLoading';

type StateProps = {
  selectedFilter: ShiftTradesFilter;
  selectedCustomFilter: ShiftTradesFilter;
  isFiltersFormDisabled: boolean;
  isTabChangedFromCustomFilters: boolean;
  isLoading: boolean;
};

type DispatchProps = {
  updateCustomFilters: () => void;
  clearCustomFilters: () => void;
  toggleFilter: (params: ShiftTradesFilter) => void;
  toggleCustomFilter: (params: ShiftTradesFilter) => void;
};

type Props = StateProps & DispatchProps;

class ShiftTradesFiltersComponent extends Component<Props> {
  statuses: {
    label: string;
    value: string;
  }[] = [
    {
      label: 'Available shifts',
      value: 'available'
    },
    {
      label: 'Shifts you\'ve offered',
      value: 'offered'
    },
    {
      label: 'Shifts you\'ve applied for',
      value: 'applied'
    }
  ];

  render() {
    const {
      updateCustomFilters,
      selectedFilter,
      isFiltersFormDisabled,
      isLoading
    } = this.props;

    return (
      <LoadingOverlay isLoading={isLoading} showSpinner={false}>
        <Filter
          onStatusChange={this.onStatusChange}
          statuses={this.statuses}
          renderCustomFilters={this.renderCustomFilters}
          onApplyCustomFilters={this.applyCustomFilters}
          onToggleCustomFilters={this.onToggleCustomFilters}
          selectedValue={selectedFilter}
          isApplyBtnDisabled={isFiltersFormDisabled}
        />
      </LoadingOverlay>
    );
  }

  private onStatusChange = (filter: string) => {
    this.props.toggleFilter(filter as ShiftTradesFilter);
  };

  private renderCustomFilters = (): ReactChild => <CustomFilters />;

  private onToggleCustomFilters = (isOpen: boolean) => {
    if (isOpen) {
      this.props.clearCustomFilters();
    }
  };

  private applyCustomFilters = () => {
    if (this.props.isTabChangedFromCustomFilters) {
      this.props.toggleCustomFilter(this.props.selectedCustomFilter);
    }
    this.props.updateCustomFilters();
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  selectedFilter: state.employeeDashboard.shiftTrades.selectedFilter,
  selectedCustomFilter:
    state.employeeDashboard.shiftTrades.filtersForm.selectedFilter,
  isTabChangedFromCustomFilters: isTabChangedFromCustomFilters(state),
  isFiltersFormDisabled: getIsFiltersFormDisabled(state),
  isLoading: getIsLoading(state, 'EMPLOYEE_DASHBOARD_SHIFT_TRADES')
});

const mapDispatchToProps: DispatchProps = {
  updateCustomFilters: BOX_SHIFT_TRADES_UPDATE_CUSTOM_FILTERS,
  clearCustomFilters: BOX_SHIFT_TRADES_CLEAR_FILTER_FORM,
  toggleFilter: BOX_SHIFT_TRADES_TOGGLE_TRADES_TYPE_REQUEST,
  toggleCustomFilter: BOX_SHIFT_TRADES_TOGGLE_TRADES_TYPE_CUSTOM_REQUEST
};
export const ShiftTradesFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShiftTradesFiltersComponent);
