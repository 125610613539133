import clsx from 'clsx';
import { css } from 'styled-components';
import { BreakpointNames, breakpoints } from '../style/config';
import { Children, ReactNode } from 'react';

// legacy, please use src/_lib/helpers

// Omit taken from https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-8.html
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

/**
 * Make a BEM string based on props
 * @param  {string} name Base class.
 * @param  {Object<string, any>} classes Component classes.
 * @param  {Object<string, any>} modifiers Component extra class modifiers passing as props.
 * @return {string} BEM class string.
 */
export function getClass(name: string, classes: any, modifiers?: any): string {
  const modifierClasses: any = {};
  for (const key in modifiers) {
    if (key === 'className') {
      modifierClasses[`${modifiers[key]}`] = true;
    } else if (modifiers[key]) {
      modifierClasses[`${name}--${key}`] = modifiers[key];
    }
  }

  return clsx(name, classes, modifierClasses);
}

/**
 * Get a maped value from map object
 * @param {*} key
 * @param {*} defaultKey
 * @param {*} map
 */
export function mapProp(map: any, key: string, defaultKey: string) {
  return map[key] || map[defaultKey];
}

/**
 *
 * Media query function for styled-components
 */
export const mediaBreakpointUp = {
  xs: getSize(breakpoints.xs.size),
  sm: getSize(breakpoints.sm.size),
  md: getSize(breakpoints.md.size),
  lg: getSize(breakpoints.lg.size),
  xl: getSize(breakpoints.xl.size),
  xxl: getSize(breakpoints.xxl.size),
};

/**
 * @param size
 * @api
 */
export function getSize(size: number): any {
  return (arg: any) => css`
    @media (min-width: ${size}px) {
      ${arg}
    }
  `;
}

export function removeArrayItem(items: Array<string>, value: string) {
  const index = items.indexOf(value);
  if (index === -1) {
    return;
  }
  items.splice(index, 1);
}

/**
 * Default noop function
 * @param e
 */
export function noop(e: any): any {
  return;
}

/** Used to generate unique IDs. */
const idCounter: any = {};

/**
 * Source: Lodash
 *
 * Generates a unique ID. If `prefix` is given, the ID is appended to it.
 *
 * @since 0.1.0
 * @category Util
 * @param {string} [prefix=''] The value to prefix the ID with.
 * @returns {string} Returns the unique ID.
 * @see random
 * @example
 *
 * uniqueId('contact_')
 * // => 'contact_104'
 *
 * uniqueId()
 * // => '105'
 */
export function uniqueId(prefix: string = '$elmo$') {
  if (!idCounter[prefix]) {
    idCounter[prefix] = 0;
  }

  const id = ++idCounter[prefix];
  if (prefix === '$elmo$') {
    return `${id}`;
  }

  return `${prefix + id}`;
}

/**
 * Source: https://medium.com/@TCAS3/debounce-deep-dive-javascript-es6-e6f8d983b7a1
 * @param delay
 * @param fn should be only an sync function
 */
export function debounce(fn: Function, delay: number) {
  let timerId: any;

  return function (...args: any[]) {
    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      fn(...args);
      timerId = null;
    }, delay);
  };
}

export function isElementOfType(element: any, component: any) {
  return (
    element &&
    element.type &&
    component &&
    element.type.displayName === component.displayName
  );
}

export function screensizeToBreakpoint() {
  // Please check all components that is related to this function
  // before making any change.
  const screenSize = window.innerWidth;
  if (screenSize < breakpoints.xs.size) {
    return BreakpointNames.xs;
  } else if (screenSize < breakpoints.sm.size) {
    return BreakpointNames.sm;
  } else if (screenSize < breakpoints.md.size) {
    return BreakpointNames.md;
  } else if (screenSize < breakpoints.lg.size) {
    return BreakpointNames.lg;
  }

  return BreakpointNames.xxl;
}

export function isScreenDesktop() {
  const screenSize = window.innerWidth;
  if (screenSize < breakpoints.lg.size) {
    return false;
  }
  return true;
}

/** function that locks scroll by adding a classname in HTML tag */
export function scrollLock(isLock: boolean) {
  const htmlTag = document.querySelector('html');

  if (htmlTag) {
    if (isLock) {
      htmlTag.classList.add('elmo-noscroll');
    } else {
      htmlTag.classList.remove('elmo-noscroll');
    }
  }
}

/** function that returns current scroll position */
export function getScrollY() {
  return window.pageYOffset;
}

/** function that sets scroll position manually */
export function setScrollY(scrollY: number | undefined) {
  if (document.scrollingElement) {
    document.scrollingElement.scrollTop = scrollY !== undefined ? scrollY : 0;
  }
}

/**
 * Get a random item from the given array
 *
 * @param items
 */
export function getRandomItem(items: Array<any>): any {
  const index = Math.floor(Math.random() * items.length);
  return items[index];
}

export function isMobileDevice() {
  try {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  } catch (e) {
    return false;
  }
}

/**
 * Number formatter for Badge on the Button
 */
export function abbreviateNumber(badgeNumber: number) {
  const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

  const tier = (Math.log10(badgeNumber) / 3) | 0;

  if (badgeNumber < 10000) {
    return badgeNumber.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);

  const scaled = badgeNumber / scale;
  let str = scaled.toString().substr(0, 3);
  if (str.substr(str.length - 1, 1) === '.') {
    str = str.substr(0, str.length - 1);
  }

  return str + suffix + '+';
}

/**
 * Check if two arrays are equal based on key comparison
 * @param children1
 * @param children2
 */
export function childrenIsEqual(children1: ReactNode, children2: ReactNode) {
  if (Children.count(children1) !== Children.count(children2)) {
    return false;
  }

  const children1Arr: any[] = Children.toArray(children1);
  const children2Arr: any[] = Children.toArray(children2);

  for (let i = 0; i < children1Arr.length; i++) {
    if (children1Arr[i].key !== children2Arr[i].key) {
      return false;
    }
  }

  return true;
}

/**
 * Find Closest Element by ID
 */

export function closestParentById(el: Element, id: string) {
  let element: (Node & ParentNode) | null = el;
  while ((element as Element).id !== id) {
    element = element.parentNode;
    if (!element) {
      return null;
    }
  }
  return element;
}
