import React, { ComponentType } from 'react';
import { commonError } from 'messages';
import { Alert } from 'extended-oxygen-elements';

type State = {
  caughtError: Error | null;
};

export default withCatchErrors;

export function withCatchErrors<WrappedComponentProps extends object>(
  WrappedComponent: ComponentType<WrappedComponentProps>
): ComponentType<WrappedComponentProps> {
  return class CatchErrorWrapper extends React.Component<
    WrappedComponentProps,
    State
  > {
    readonly state: State = {
      caughtError: null,
    };

    render() {
      const {
        props,
        state: { caughtError },
      } = this;

      if (caughtError) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('withCatchErrors/error: ', caughtError);
        }

        return (
          <Alert color="error" severity="error">
            {commonError}
          </Alert>
        );
      }

      return <WrappedComponent {...props} />;
    }

    componentDidCatch(error: Error) {
      this.setState({
        caughtError: error,
      });
    }
  };
}
