import React, { useEffect, useState } from 'react';
import { Heading, Text, Divider, Row, Col } from 'elmo-elements';
import './TemplatesListExpander.scss';
import { CalendarIcon, FeatureFlag, PeopleOutlinedIcon } from 'element';
import { OverrideDay } from '../OverrideDay';
import { SelectedTemplate } from '../SelectedTemplate';
import AvailableTemplates from '../AvailableTemplates';
import { LanguagePreferences } from 'type/models';
import {
  DeleteTemplatePayload,
  TemplatesListResponse,
} from 'state/Roster/RosterCopyPast/type';
import { TemplateRangePicker } from '../TemplateRangePicker';

type OwnProps = {
  templates: TemplatesListResponse;
  langPreferences: LanguagePreferences;
  selected: string;
  setSelectedTemplate: (value: string) => void;
  deleteTemplateById: (params: DeleteTemplatePayload) => void;
  setDate: (day: number) => void;
  overrideDate: number;
  navigateToTemplate: (value: string) => void;
  setTemplateOptions: (start: string, end: string) => void;
};
type Props = OwnProps;

export const TemplatesList = ({
  templates,
  langPreferences,
  selected,
  setSelectedTemplate,
  deleteTemplateById,
  setDate,
  overrideDate,
  navigateToTemplate,
  setTemplateOptions,
}: Props) => {
  const [isOverride, setOverride] = useState<boolean>(false);

  useEffect(() => {
    if (selected === '') {
      setOverride(false);
      setDate(1);
    }
    if (!isOverride) {
      setDate(1);
    }
  });

  const getTemplateContent = (t: any) =>
    t ? (
      <>
        <Text id={`template-name`} size={'rg'} isTruncate={true}>
          {t.title}
        </Text>
        <Row className={'templates-list__group--item'}>
          <Col span={4} align={'start'}>
            <Text color={'gray'} size={'xs'}>
              <CalendarIcon />{' '}
              <span data-testid="template-shifts">
                {t.templated_rostered_shifts_count}
              </span>
            </Text>
          </Col>
          <Col span={4}>
            <Text color={'gray'} size={'xs'}>
              <PeopleOutlinedIcon />{' '}
              <span data-testid="template-users">
                {t.templated_shifts_users_count}
              </span>
            </Text>
          </Col>
          <Col span={16}>
            <Text color={'gray'} size={'xs'}>
              7 days spread / Mon - Sun
            </Text>
          </Col>
        </Row>
      </>
    ) : null;

  const showTemplates = () => (
    <div className={'templates-list'}>
      <div className={'templates-list__block'}>
        <Heading size={'xxs'} className={'mb-2'}>
          Current
        </Heading>
        <Divider />
        <SelectedTemplate
          langPreferences={langPreferences}
          getTemplateContent={getTemplateContent}
          setCurrentTemplate={setSelectedTemplate}
          templateId={selected}
          templates={templates}
        />

        <FeatureFlag name={'dateFilterRotaTemplate'}>
          <FeatureFlag.On>
            {selected && (
              <TemplateRangePicker setTemplateOptions={setTemplateOptions} />
            )}
          </FeatureFlag.On>
          <FeatureFlag.Off>
            <OverrideDay
              isChecked={isOverride}
              hasSelectedTemplate={!!selected}
              setOverride={setOverride}
              setDate={setDate}
              overrideDate={overrideDate}
              setTemplateOptions={setTemplateOptions}
            />
          </FeatureFlag.Off>
        </FeatureFlag>
      </div>

      <div className={'templates-list__block'}>
        <Heading size={'xxs'} className={'mb-2'}>
          Templates
        </Heading>
        <Divider />
        <AvailableTemplates
          getTemplateContent={getTemplateContent}
          setCurrentTemplate={setSelectedTemplate}
          templateId={selected}
          templates={templates}
          deleteTemplateById={deleteTemplateById}
          navigateToTemplate={navigateToTemplate}
        />
      </div>
    </div>
  );

  const showPlaceholder = () => (
    <Text color={'gray'}>
      There are no templates available. Please create one first.
    </Text>
  );

  return templates.length === 0 ? showPlaceholder() : showTemplates();
};
