import { ConnectActionCreator } from 'lib/store-utils';
import React from 'react';
import { useDispatch } from 'react-redux';

export function useConnectAction<P>(
  action: ConnectActionCreator<P>,
  payload: P
) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(action.connected(payload));

    return () => {
      dispatch(action.disconnected());
    };
  }, [payload]);
}

export default useConnectAction;
