import { StoreState } from '../../types';
import { createSelector } from 'reselect';
import { rosteredShiftsFilteredSelector, timesheetsFilteredSelector } from '../RosterFilters';
import { RosteredShift, Timesheet } from '../../../type';

const getState = (state: StoreState) => state.covidTracing;

export const getIsCovidModeEnabled = (state: StoreState) => getState(state).isEnabled;
export const getCovidPointedShiftId = (state: StoreState) => getState(state).shiftId;
export const getCovidPointedShiftType = (state: StoreState) => getState(state).entity;
export const getIsLoadingCovidShifts = (state: StoreState) => getState(state).isLoading;
export const getCovidShiftData = createSelector(
  getCovidPointedShiftId,
  getCovidPointedShiftType,
  (shiftId, entity) => {
  if ( shiftId && entity ) {
    return {
      id: shiftId,
      entity: entity
    }
  }
  return null;
});

export const isCovidPointedShift = (state: StoreState, id: string): boolean =>
  getState(state).shiftId === id;
export const getCovidPeriod = (state: StoreState) => getState(state).period;
export const getContactedShifts = (state: StoreState) => getState(state).shifts;
export const isContactedShift = (id: string) => createSelector(
  getContactedShifts,
  (shifts) => {
    return shifts.all.includes(id);
  });
export const getPrintData = (state: StoreState): any[] => getState(state).printData;

export const isUserHasCovidShifts = (userId: string | null) => createSelector(
  rosteredShiftsFilteredSelector,
  timesheetsFilteredSelector,
  getContactedShifts,
  getCovidPointedShiftId,
  (shifts, timesheets, contacted, pointedId) => {
    const includesContacted = (array: RosteredShift[] | Timesheet[]) => {
      for (let i = 0; i < array.length; i++) {
        if (array[i].user_id === userId) {
          if ( contacted.all.includes(array[i].id) || pointedId === array[i].id ) {
            return true;
          }
        }
      }
      return false;
    };
    if ( userId ) {
      return includesContacted(shifts) || includesContacted(timesheets);
    }
    return false;
  });

export const isAreaRoleRowHasCovidShifts = (areaId: string, roleId: string) => createSelector(
  rosteredShiftsFilteredSelector,
  timesheetsFilteredSelector,
  getContactedShifts,
  getCovidPointedShiftId,
  (shifts, timesheets, contacted, pointedId) => {
    const includesContacted = (array: RosteredShift[] | Timesheet[]) => {
      for (let i = 0; i < array.length; i++) {
        if (array[i].area_id === areaId && array[i].role_id === roleId) {
          if ( contacted.all.includes(array[i].id) || pointedId === array[i].id ) {
            return true;
          }
        }
      }
      return false;
    };
    return includesContacted(shifts) || includesContacted(timesheets);
  });
export const getIsPrinting = (state: StoreState): boolean => getState(state).isPrinting;
