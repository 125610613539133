import React from 'react';
import { connect } from 'react-redux';
import { RoleTripleTable } from 'element';
import { RoleTriple } from 'type';
import { StoreState } from 'state/types';
import {
  getFiltersFormRoleTriples,
  BOX_ROSTER_FILTERS_OPEN_AREA_ROLE_TRIPLE_FILTERS
} from 'state/Roster/RosterFilters';

type OwnProps = {};

type StateProps = {
  roleTriples: RoleTriple[] | null;
};

type DispatchProps = {
  openRoleTripleFiltersModal: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const RosterRoleTripleTableComponent = ({
  roleTriples,
  openRoleTripleFiltersModal
}: Props) => (
  <RoleTripleTable
    id="roster-custom-filters-triple-table"
    selectedRoleTriples={roleTriples}
    isSiteNameShown={false}
    openFilterByRoleModal={openRoleTripleFiltersModal}
  />
);

const mapStateToProps = (state: StoreState): StateProps => ({
  roleTriples: getFiltersFormRoleTriples(state)
});

const mapDispatchToProps: DispatchProps = {
  openRoleTripleFiltersModal: BOX_ROSTER_FILTERS_OPEN_AREA_ROLE_TRIPLE_FILTERS
};

export const RosterRoleTripleTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(RosterRoleTripleTableComponent);
