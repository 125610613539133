import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SelectPropsOption } from 'elmo-elements/Select';
import {
  Report,
  ReportSchedule,
  StringMap,
  UserFields
} from 'type';
import { StoreState } from 'state/types';
import {
  BOX_REPORTS_SCHEDULE_MODAL_CLOSE,
  BOX_REPORTS_SCHEDULE_UPDATE_REQUEST
} from 'state/Reports';
import { userListSelector, activeUsersAsSelectOptionsArraySelector } from 'state/UsersCollection';
import ReportScheduleModal from '../../../../../../components/ReportScheduleModal';
import { ReportScheduleRequest } from 'lib/Api/type';
import { getUsePaySauceFormat } from '../../../../../../../../state/Account';

type StateProps = {
  isOpened: boolean;
  isLoading: boolean;
  errors: string[];
  report: Report;
  timezone: string;
  users: StringMap<UserFields>;
  usersOptions: SelectPropsOption[];
  usePaySauceFormat: boolean;
};
type DispatchProps = {
  onCloseModal: () => void;
  updateSchedule: (report: ReportScheduleRequest) => void;
};
type Props = StateProps & DispatchProps;
export class Schedule extends Component<Props> {
  render() {
    const { report, updateSchedule, usePaySauceFormat,  ...rest } = this.props;
    return (
      <ReportScheduleModal
        {...rest}
        onFormSubmit={this.onFormSubmit}
        defaultValues={report.schedule}
        type={report.type}
        usePaySauceFormat={usePaySauceFormat}
      />
    );
  }

  onFormSubmit = (schedule: ReportSchedule) => {
    const { id } = this.props.report;
    this.props.updateSchedule({
      schedule,
      id
    });
  }
}

export const mapStateToProps = (state: StoreState): StateProps => {
  const {
    reports: {
      isLoading,
      scheduleModal: { isOpened, report, errors }
    },
    account: {
      account: {
        preferences: { timezone }
      }
    }
  } = state;

  return {
    isOpened,
    isLoading,
    errors,
    report,
    timezone,
    users: userListSelector(state),
    usersOptions: activeUsersAsSelectOptionsArraySelector(state),
    usePaySauceFormat: getUsePaySauceFormat(state)
  };
};

const mapDispatchToProps: DispatchProps = {
  onCloseModal: BOX_REPORTS_SCHEDULE_MODAL_CLOSE,
  updateSchedule: BOX_REPORTS_SCHEDULE_UPDATE_REQUEST
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Schedule);
