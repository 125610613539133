import { StoreState } from './types';
import { Moment } from 'moment';
import { createSelector } from 'reselect';
import { getSites } from './AccountTree';
import {
  bySiteTimezoneCombinerCreator,
  currentSiteTimezoneCombiner
} from './combiners';

export const fromToBySiteTimezoneSelectorsCreator = (
  simpleSelector: (
    state: StoreState
  ) => { site_id: string; from: Moment; to: Moment }
) => {
  const getSiteId = (state: StoreState) => simpleSelector(state).site_id;
  const getFrom = (state: StoreState) => simpleSelector(state).from;
  const getTo = (state: StoreState) => simpleSelector(state).to;

  const currentSiteTimezoneSelector = createSelector(
    getSiteId,
    getSites,
    currentSiteTimezoneCombiner
  );

  const fromBySiteTimezoneSelector = createSelector<
    StoreState,
    Moment,
    string,
    Moment
  >(
    getFrom,
    currentSiteTimezoneSelector,
    bySiteTimezoneCombinerCreator(true)
  );

  const toBySiteTimezoneSelector = createSelector<
    StoreState,
    Moment,
    string,
    Moment
  >(
    getTo,
    currentSiteTimezoneSelector,
    bySiteTimezoneCombinerCreator(true)
  );

  return {
    currentSiteTimezoneSelector,
    fromBySiteTimezoneSelector,
    toBySiteTimezoneSelector
  };
};
