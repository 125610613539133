import React, { Component } from 'react';
import { FormattedErrors } from 'type/models';
import {
  Alert,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
} from 'extended-oxygen-elements';
import { PageDialog, PageDialogCancel, PageDialogSubmit } from 'element';

type Props = {
  onSubmit: () => void;
  isOpened: boolean;
  onClose: () => void;
  isUpdating: boolean;
  errors: FormattedErrors;
};

export default class DeleteModal extends Component<Props> {
  render() {
    const { isOpened, onClose, isUpdating, errors } = this.props;

    return (
      <PageDialog
        maxWidth={'xs'}
        open={isOpened}
        id="delete-todays-shift-modal-dialog"
      >
        <DialogTitle>Delete timesheet</DialogTitle>
        <DialogContent>
          {errors.length ? (
            <>
              {errors.map((error, key) => {
                return (
                  <Alert
                    color="error"
                    severity="error"
                    key={key}
                    className="mb-2"
                  >
                    {error}
                  </Alert>
                );
              })}
            </>
          ) : (
            <></>
          )}
          <Typography variant={'body1'}>
            Are you sure you want to delete the timesheet?
          </Typography>
        </DialogContent>
        <DialogActions>
          <PageDialogCancel onClick={onClose}>Cancel</PageDialogCancel>
          <PageDialogSubmit
            onClick={this.onSubmit}
            loading={isUpdating}
            disabled={isUpdating}
          >
            Yes, delete
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  }

  onSubmit = () => {
    const { onSubmit } = this.props;
    onSubmit();
  };
}
