import React, { useState } from 'react';
import { EditNotesForm, NotesText } from 'element/LeaveNotes/NotesForm';
import { Button, Dialog } from 'extended-oxygen-elements';
import { useDispatch, useSelector } from 'react-redux';
import {
  BOX_EMPLOYEE_UNAVAILABILITY_NOTES_MODAL_TOGGLE,
  getLeaveSubTypeById,
  getNotesModal,
} from 'state/EmployeeDashboard/Unavailability';
import { Box, Typography } from 'extended-oxygen-elements';
import { createStyles, makeStyles } from '@mui/styles';
import { isTimeoffUpdating } from 'state/TimeOffs';
import { AddOutlinedIcon } from 'element';
import { useSelectorWithProps, useUpdateEffect } from 'hooks';
import { getLeaveSubtypeLabel } from 'lib/helpers';

const useFormStyles = makeStyles((theme) =>
  createStyles({
    btns: {
      paddingTop: 10,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    cancelBtn: {
      marginRight: 10,
    },
  })
);

export const NotesDialog = () => {
  const styles = useFormStyles();
  const notesModal = useSelector(getNotesModal);
  const isUpdating = useSelector(isTimeoffUpdating);
  const subType = useSelectorWithProps(getLeaveSubTypeById, notesModal.id);

  const [isEditing, toggleEditing] = useState(false);
  const dispatch = useDispatch();

  useUpdateEffect(() => {
    if (!isUpdating && notesModal.isOpened) {
      toggleEditing(!notesModal.notes);
    }
  }, [notesModal.isOpened, isUpdating]);

  const onClose = () => {
    dispatch(
      BOX_EMPLOYEE_UNAVAILABILITY_NOTES_MODAL_TOGGLE({
        isOpened: false,
        notes: null,
        id: null,
      })
    );
  };

  return (
    <Dialog open={notesModal.isOpened} onClose={onClose}>
      <Box data-testid={'leave-notes-form'} p={3}>
        <Typography variant="h3">
          {getLeaveSubtypeLabel(subType, false)} note
        </Typography>
        {!isEditing ? (
          <NotesText
            notes={notesModal.notes}
            toggleEditing={toggleEditing}
            showEditButton={false}
          />
        ) : (
          <EditNotesForm
            closeNotes={onClose}
            notes={notesModal.notes}
            id={notesModal.id!}
          />
        )}
        {!isEditing && (
          <Box className={styles.btns}>
            <Button className={styles.cancelBtn} onClick={onClose}>
              Cancel
            </Button>
            <Button
              startIcon={<AddOutlinedIcon />}
              data-testid={'toggle-leave-notes-button'}
              onClick={(e: React.MouseEvent) => toggleEditing(true)}
              role={'button'}
              variant={'contained'}
            >
              Edit note
            </Button>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};
