import { createAction } from 'lib/store-utils';
import { StringMap, UserGroup } from 'type';
import { UpdateUserGroupPermissionsPayload, SelectedGroup } from './types';

export const BOX_USER_GROUPS_REQUEST = createAction('Get user groups');
export const BOX_USER_GROUPS_SUCCESS = createAction<StringMap<UserGroup>>('Get user groups success');
export const BOX_USER_GROUPS_FAILURE = createAction<string[]>('Get user groups failed');

export const BOX_USER_GROUPS_ADD_MODAL_OPEN = createAction('Use Group / Open add modal');
export const BOX_USER_GROUPS_ADD_MODAL_CLOSE = createAction('Use Group / Close add modal');

export const BOX_USER_GROUPS_UPDATE_MODAL_OPEN = createAction('Use Group / Open update modal');
export const BOX_USER_GROUPS_UPDATE_MODAL_CLOSE = createAction('Use Group / Close update modal');

export const BOX_USER_GROUPS_DELETE_MODAL_OPEN = createAction('Use Group / Open delete modal');
export const BOX_USER_GROUPS_DELETE_MODAL_CLOSE = createAction('Use Group / Close delete modal');

export const BOX_ADD_USER_GROUP_REQUEST = createAction<any>('Add user groups'); // TODO double-check
export const BOX_ADD_USER_GROUP_SUCCESS = createAction<UserGroup>('Add user groups success');
export const BOX_ADD_USER_GROUP_FAILURE = createAction<string[]>('Add user groups failed');

export const BOX_UPDATE_USER_GROUP_REQUEST = createAction<any>('Update user groups request'); // TODO double-check
export const BOX_UPDATE_USER_GROUP_SUCCESS = createAction('Update user groups success');
export const BOX_UPDATE_USER_GROUP_DATA = createAction<UserGroup>('Update user group data');
export const BOX_UPDATE_USER_GROUP_FAILURE = createAction<string[]>('Update user groups failed');

export const BOX_UPDATE_USER_GROUP_PERMISSIONS_REQUEST = createAction<UpdateUserGroupPermissionsPayload>('Update user groups permissions');
export const BOX_UPDATE_USER_GROUP_PERMISSIONS_SUCCESS =
  createAction('Update user groups permissions success');
export const BOX_UPDATE_USER_GROUP_PERMISSIONS_FAILURE =
  createAction<string[]>('Update user groups permissions failed');
export const BOX_UPDATE_USER_GROUP_PERMISSIONS_RESET = createAction('Update user groups permissions reset');

export const BOX_DELETE_USER_GROUP_REQUEST = createAction<any>('Delete user groups request'); // TODO double-check
export const BOX_DELETE_USER_GROUP_SUCCESS = createAction('Delete user groups success');
export const BOX_DELETE_USER_GROUP_FAILURE = createAction<string[]>('Delete user groups failed');

export const BOX_USER_GROUP_SHOW_NAME_ERROR_REQUEST = createAction('User groups show name error request');
export const BOX_USER_GROUP_HIDE_NAME_ERROR_REQUEST = createAction('User groups hide name error request');

export const BOX_USER_GROUP_SELECT_REQUEST = createAction<SelectedGroup>('User update selected group');

export const BOX_USER_GROUP_CLEAR_ERRORS = createAction('User groups / clear errors');
