import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'elmo-elements';
import ErrorBox from '../../ErrorBox';
import ModalActions from './components/ModalActions';
import EventEditForm from './components/EventEditForm';
import UsersModal from './components/UsersModal';
import PositionsModal from './components/PositionsModal';
import MapModal from './components/MapModal';
import PublishedShiftsModal from './components/PublishedShiftsModal';
import { DispatchProps, Location, OwnProps, State } from './types';
import { StoreState } from 'state/types';
import {
  BOX_EVENT_MODAL_ASSIGN_SHIFT,
  BOX_EVENT_MODAL_CLEAR_ERRORS,
  BOX_EVENT_MODAL_CLOSE,
  BOX_EVENT_MODAL_CREATE_EVENT,
  BOX_EVENT_MODAL_GET_BY_ID_REQUEST,
  BOX_EVENT_MODAL_GET_SHIFTS_REQUEST,
  BOX_EVENT_MODAL_PUBLISHED_SHIFTS_MODAL_CLOSE,
  BOX_EVENT_MODAL_PUBLISHED_SHIFTS_MODAL_OPEN,
  BOX_EVENT_MODAL_REMOVE_EVENT_REQUEST,
  BOX_EVENT_MODAL_UNASSIGN_SHIFT,
  BOX_EVENT_MODAL_UPDATE_EVENT,
  getEventModal,
  getEventModalErrors,
  getEventModalEvent,
  getEventModalShifts,
} from '../../../state/Roster/EventModal';
import { getUserListResponse } from 'state/UsersCollection';
import { Moment } from 'moment';
import { getLangPreferences } from 'state/Account';
import _ from 'lodash';
import { getPreferenceLabel } from 'lib/helpers';
import { DialogActions, DialogTitle } from 'oxygen-elements';
import {
  PageDialog,
  PageDialogCancel,
  PageDialogCloseIconButton,
  PageDialogSubmit,
} from '../../page-dialog-components';
import { DialogContent } from 'extended-oxygen-elements';

type Props = OwnProps & DispatchProps;

export class EventModalComponent extends Component<Props, State> {
  dateFormat: string = 'YYYY-MM-DD HH:mm:ss';

  constructor(props: Props) {
    super(props);
    this.state = {
      activeSubModal: '',
      event: props.event,
      watchErrors: false,
      isValid: false,
      initMap: false,
      showSearch: false,
    };
  }

  componentDidUpdate(prevProps: Props): void {
    if (
      prevProps.eventModal.isLoaded !== this.props.eventModal.isLoaded ||
      prevProps.event !== this.props.event
    ) {
      this.setState(
        {
          event: this.props.event,
        },
        () => {
          this.validateForm();
          this.getShifts();
        }
      );
    }

    if (!_.isEqual(prevProps.shifts, this.props.shifts)) {
      const { shifts } = this.props;
      const { rostered_shift_ids } = this.state.event;

      this.setState({
        ...this.state,
        event: {
          ...this.state.event,
          rostered_shift_ids: rostered_shift_ids.filter(
            (id) => Object.keys(shifts).indexOf(id) !== -1
          ),
        },
      });
    }
  }

  render() {
    return (
      <>
        {this.props.eventModal.isOpened && (
          <>
            {this.mainModal()}
            {this.subModals()}

            <PublishedShiftsModal
              saveWithoutPublished={() => {
                this.saveEvent();
              }}
              saveWithPublished={() => {
                this.setState(
                  {
                    event: {
                      ...this.state.event,
                      publish: true,
                    },
                  },
                  this.saveEvent
                );
              }}
            />
          </>
        )}
      </>
    );
  }

  private submitModal = () => {
    if (this.hasUnpublishedShifts) {
      this.props.openPublishedShiftsModal();
    } else {
      this.saveEvent();
    }
  };

  private saveEvent = () => {
    const { event } = this.state;
    const { period, updateEvent, createEvent } = this.props;

    const { id, ...rest } = event;

    if (id) {
      if (period) {
        updateEvent({
          ...event,
          ...period,
        });
      } else {
        updateEvent(event);
      }
    } else {
      const {
        publish,
        account_id,
        actual_day_cost,
        day_cost,
        ...createPayload
      } = rest;
      createEvent(createPayload);
    }
    this.setState({
      watchErrors: true,
    });

    this.props.closePublishedShiftsModal();
  };

  private get hasUnpublishedShifts() {
    let hasUnPublished = false;
    this.state.event.rostered_shift_ids.forEach((id) => {
      if (this.props.shifts[id] && !this.props.shifts[id].is_published) {
        hasUnPublished = true;
      }
    });
    return hasUnPublished;
  }

  private mainModal = () => {
    const {
      eventModal: { isOpened, isLoading },
      langPreferences,
    } = this.props;
    const { type } = this.state.event;
    return (
      <PageDialog
        id={'event-modal'}
        maxWidth={'sm'}
        open={isOpened}
        className={'shift-modal'}
      >
        <DialogActions
          className={'shift-modal-header'}
          sx={{ boxShadow: '0 0 6px 2px #ccc', zIndex: 3, padding: 0 }}
        >
          <DialogTitle>
            {type === 'event'
              ? getPreferenceLabel(
                  langPreferences,
                  'event',
                  'singular',
                  '',
                  true
                )
              : 'Forecast'}
            <PageDialogCloseIconButton onClose={this.props.closeModal} />
          </DialogTitle>
        </DialogActions>
        <DialogContent className={'shift-modal-content'}>
          {isLoading ? <Loader type="spinner" /> : this.modalContent()}
        </DialogContent>
        {!isLoading && (
          <DialogActions sx={{ boxShadow: '0 0 6px 2px #ccc', zIndex: 3 }}>
            <PageDialogCancel
              onClick={this.props.closeModal}
              fullWidth={false}
              size={'large'}
            >
              Cancel
            </PageDialogCancel>
            {this.getButton()}
          </DialogActions>
        )}
      </PageDialog>
    );
  };

  private getButton = () => {
    const { isUpdating, isLoadingShifts } = this.props.eventModal;
    const { isValid } = this.state;
    return (
      <PageDialogSubmit
        onClick={this.submitModal}
        loading={isUpdating}
        disabled={isUpdating || !isValid || isLoadingShifts}
        fullWidth={false}
        tabIndex={0}
        size={'large'}
      >
        Save
      </PageDialogSubmit>
    );
  };

  private modalContent = () => {
    return (
      <>
        <ErrorBox
          errors={this.props.errors}
          clearErrors={this.props.clearErrors}
          dontWatchGlobalErrors={true}
        />
        <ModalActions
          isUpdating={this.props.eventModal.isUpdating}
          event={this.state.event}
          openSubModal={this.openSubModal}
          deleteEvent={this.deleteEvent}
        />
        <EventEditForm
          event={this.state.event}
          openSubModal={this.openSubModal}
          setEventProps={this.setEventProps}
          onChangePeriod={this.onChangePeriod}
          setEventLocation={this.setEventLocation}
        />
      </>
    );
  };

  private setEventProps = (name: string, value: string) => {
    this.setState(
      {
        ...this.state,
        event: {
          ...this.state.event,
          [`${name}`]: value,
        },
      },
      () => {
        this.validateForm();
      }
    );
  };

  private setEventCustomField = (e: {
    name: string;
    value: string | null | string[] | Date;
  }) => {
    const { name, value } = e;
    this.setState(
      {
        ...this.state,
        event: {
          ...this.state.event,
          custom_fields: {
            ...this.state.event.custom_fields,
            [`${name}`]: value,
          },
        },
      },
      () => {
        this.validateForm();
      }
    );
  };

  private setEventLocation = (location: Location) => {
    const { lat, lng, name } = location;
    this.setState({
      event: {
        ...this.state.event,
        lat,
        lng,
        address: name,
      },
    });
  };

  private validateForm = () => {
    const { event } = this.state;

    let isValid = true;

    if (!event.name) {
      isValid = false;
    }

    this.setState({
      ...this.state,
      isValid,
    });
  };

  private onChangePeriod = (updatedStartEnd: {
    start: Moment;
    end: Moment;
  }) => {
    const { site_id } = this.state.event;

    this.setState({
      ...this.state,
      event: {
        ...this.state.event,
        ...updatedStartEnd,
      },
    });

    if (this.state.event.type === 'event') {
      this.props.getShifts({
        site_id,
        from: updatedStartEnd.start,
        to: updatedStartEnd.end,
      });
    }
  };

  private getShifts = () => {
    const { start, end, site_id, type, id } = this.state.event;
    if (!site_id || id.length) {
      return;
    }

    const payload = { from: start, to: end, site_id };
    if (type === 'event') {
      this.props.getShifts(payload);
    }
  };

  private subModals = () => {
    return (
      <>
        <UsersModal
          isOpened={this.state.activeSubModal === 'users'}
          closeModal={this.closeSubModal}
          event={this.state.event}
          assignShift={this.assignShift}
          shifts={this.props.shifts}
          showSearch={this.state.showSearch}
        />
        <PositionsModal
          isOpened={this.state.activeSubModal === 'positions'}
          closeModal={this.closeSubModal}
          event={this.state.event}
          showSearch={this.state.showSearch}
        />

        <MapModal
          isOpened={this.state.activeSubModal === 'map'}
          closeModal={this.closeSubModal}
          event={this.state.event}
          initMap={this.state.initMap}
        />
      </>
    );
  };

  private assignShift = (id: string) => {
    const assignedShifts = this.state.event.rostered_shift_ids;

    if (assignedShifts.indexOf(id) !== -1) {
      this.setState({
        event: {
          ...this.state.event,
          rostered_shift_ids: this.state.event.rostered_shift_ids.filter(
            (shiftId) => shiftId !== id
          ),
        },
      });
    } else {
      this.setState({
        event: {
          ...this.state.event,
          rostered_shift_ids: [...this.state.event.rostered_shift_ids, id],
        },
      });
    }
  };

  private closeSubModal = () => {
    this.setState({
      ...this.state,
      activeSubModal: '',
      initMap: false,
      showSearch: false,
    });
  };

  private openSubModal = (name: string) => {
    this.setState({
      ...this.state,
      activeSubModal: name,
      initMap: name === 'map',
      showSearch: name === 'users' || name === 'positions',
    });
  };

  private deleteEvent = () => {
    if (this.state.event !== null && this.state.event.id !== null) {
      this.props.deleteEvent(this.state.event.id);
    }
  };
}

const mapStateToProps = (state: StoreState) => ({
  eventModal: getEventModal(state),
  users: getUserListResponse(state),
  event: getEventModalEvent(state),
  errors: getEventModalErrors(state),
  shifts: getEventModalShifts(state),
  langPreferences: getLangPreferences(state),
});

const mapToDispatchProps: DispatchProps = {
  closeModal: BOX_EVENT_MODAL_CLOSE,
  clearErrors: BOX_EVENT_MODAL_CLEAR_ERRORS,
  createEvent: BOX_EVENT_MODAL_CREATE_EVENT,
  updateEvent: BOX_EVENT_MODAL_UPDATE_EVENT,
  deleteEvent: BOX_EVENT_MODAL_REMOVE_EVENT_REQUEST,
  getEvent: BOX_EVENT_MODAL_GET_BY_ID_REQUEST,
  assignShift: BOX_EVENT_MODAL_ASSIGN_SHIFT,
  unAssignShift: BOX_EVENT_MODAL_UNASSIGN_SHIFT,
  getShifts: BOX_EVENT_MODAL_GET_SHIFTS_REQUEST,
  openPublishedShiftsModal: BOX_EVENT_MODAL_PUBLISHED_SHIFTS_MODAL_OPEN,
  closePublishedShiftsModal: BOX_EVENT_MODAL_PUBLISHED_SHIFTS_MODAL_CLOSE,
};

export const EventsModal = connect(
  mapStateToProps,
  mapToDispatchProps
)(EventModalComponent);

export default EventsModal;
