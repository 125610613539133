import React from 'react';
import { Moment } from 'moment';
import { RosterTimeOff } from 'type';
import { RosterCell } from '../../../../../../components';
import { ResizableWrapper } from '../../../../components';
import { DayUserUnavailabilityCell } from './components/DayUserUnavailabilityCell';

type Props = {
  rosterTimeOff: RosterTimeOff;
  day: Moment;
};

export const DayUserUnavailability = ({ rosterTimeOff, day }: Props) =>
  (
    <ResizableWrapper
      id={rosterTimeOff.id}
      start={rosterTimeOff.start}
      end={rosterTimeOff.end}
      day={day}
      isResizable={false}
    >
      <RosterCell>
        <DayUserUnavailabilityCell data={rosterTimeOff} day={day}/>
      </RosterCell>
    </ResizableWrapper>
  );
