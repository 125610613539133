import { createReducer } from 'lib/store-utils';
import { Account } from 'type';
import * as actions from './actions';
import { AccountReducerState, defaultState } from './state';
import { TimezonesList } from './types';

export const account = createReducer<AccountReducerState>({}, defaultState);

account.on(actions.BOX_ACCOUNT_FETCHED, (state, fetchedAccount) => ({
  ...state,
  account: fetchedAccount,
}));

account.on(
  actions.BOX_ACCOUNT_UPDATE_REQUEST,
  (state): AccountReducerState => ({
    ...state,
    isUpdating: true,
    account: {
      ...state.account,
      files_uploaded: false,
    },
    errors: [],
  })
);

account.on(
  actions.BOX_ACCOUNT_UPDATE_CANCEL,
  (state): AccountReducerState => ({
    ...state,
    isUpdating: false,
  })
);

account.on(
  actions.BOX_ACCOUNT_GEOFENCING_UPDATE_REQUEST,
  (state): AccountReducerState => ({
    ...state,
    isUpdating: true,
    errors: [],
  })
);

const updateHandler = (
  state: AccountReducerState,
  partialAccount: Partial<Account>
): AccountReducerState => ({
  ...state,
  isUpdating: false,
  account: {
    ...state.account,
    ...partialAccount,
    files_uploaded: false,
  },
  errors: [],
});

const updateRosteredBreaksHandler = (
  state: AccountReducerState,
  partialAccount: Partial<Account>
): AccountReducerState => ({
  ...state,
  isUpdating: false,
  account: {
    ...state.account,
    rostered_shift_settings: {
      ...state.account.rostered_shift_settings,
      default_breaks_rules:
        partialAccount.rostered_shift_settings!.default_breaks_rules,
    },
    files_uploaded: false,
  },
  errors: [],
});

const updateTimesheetBreaksHandler = (
  state: AccountReducerState,
  partialAccount: Partial<Account>
): AccountReducerState => ({
  ...state,
  isUpdating: false,
  account: {
    ...state.account,
    timesheet_settings: {
      ...state.account.timesheet_settings,
      default_breaks_rules:
        partialAccount.timesheet_settings!.default_breaks_rules,
    },
    files_uploaded: false,
  },
  errors: [],
});

const updateTimezonesHandler = (
  state: AccountReducerState,
  timezonesList: TimezonesList
): AccountReducerState => ({
  ...state,
  timezonesList,
  errors: [],
});

account
  .on(actions.BOX_ACCOUNT_UPDATE_SUCCESS, updateHandler)
  .on(
    actions.BOX_ACCOUNT_PAY_CONDITIONS_WEEKEND_LOADINGS_TOGGLE.success,
    updateHandler
  )
  .on(actions.BOX_ACCOUNT_PAY_CONDITIONS_RULE_CREATE.success, updateHandler)
  .on(actions.BOX_ACCOUNT_PAY_CONDITIONS_RULE_UPDATE.success, updateHandler)
  .on(actions.BOX_ACCOUNT_PAY_CONDITIONS_RULE_DELETE.success, updateHandler)
  .on(actions.BOX_ACCOUNT_ROSTERED_SHIFT_SETTINGS_UPDATE.success, updateHandler)
  .on(actions.BOX_ACCOUNT_TIMESHEET_SETTINGS_UPDATE.success, updateHandler)
  .on(actions.BOX_ACCOUNT_TIMECLOCK_SETTINGS_UPDATE.success, updateHandler)
  .on(
    actions.BOX_ACCOUNT_ROSTERED_SHIFT_APPLY_CUSTOMISED_BREAKS.success,
    updateRosteredBreaksHandler
  )
  .on(
    actions.BOX_ACCOUNT_TIMESHEET_APPLY_CUSTOMISED_BREAKS.success,
    updateTimesheetBreaksHandler
  )
  .on(
    actions.BOX_ACCOUNT_ROSTERED_SHIFT_SETTINGS_DEFAULT_BREAKS_RESET.success,
    updateHandler
  )
  .on(actions.BOX_ACCOUNT_TIMESHEET_DEFAULT_BREAKS_RESET.success, updateHandler)
  .on(actions.BOX_ACCOUNT_TIMEZONES.success, updateTimezonesHandler)
  .on(actions.BOX_ACCOUNT_PREFERENCES_SETTINGS_UPDATE.success, updateHandler);
account.on(
  actions.BOX_ACCOUNT_UPDATE_FAILURE,
  (state, errors): AccountReducerState => ({
    ...state,
    isUpdating: false,
    account: {
      ...state.account,
      files_uploaded: false,
    },
    errors,
  })
);

account.on(
  actions.BOX_ACCOUNT_CLEAR_ERRORS,
  (state): AccountReducerState => ({
    ...state,
    isUpdating: false,
    errors: [],
  })
);

export default account;
