import React from 'react';
import { RadioLabelProps } from '../type';
import './RadioLabel.scss';

function RadioLabel({ children }: RadioLabelProps) {
  if (children) {
    return <span className="elmo-radio__label">{children}</span>;
  }

  return null;
}

export default RadioLabel;
