import React, { memo, MouseEvent } from 'react';
import {
  DelayedClickDiv,
  RosterShiftTimeRangeInput,
} from '../../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import {
  BOX_SHIFT_TIME_RANGE_INPUT_CLOSED,
  getIsLoading,
} from 'state/Roster/RangeInput/ShiftTimeRangeInput';
import { getWeekDays } from 'state/Roster/RosterWeekView';
import { getSiteId } from 'state/RosteredShifts';
import { ContextualMenuBlock } from '../../ContextualMenuBlock';
import { addFutureClassName, getRowId } from '../../../../../helpers';
import {
  BOX_ROSTER_RANGE_INPUT_OPEN,
  currentTimeBySiteTimezoneSelector,
} from 'state/Roster/Roster';
import { getSites } from 'state/AccountTree';
import moment from 'moment-timezone';
import { hasPermissionSelector } from 'state/Auth';
import {
  SiteTimesheetCard,
  UserTimesheetCard,
} from '../../../../../components/shiftCards';
import { BOX_TIMESHEET_SHIFT_MODAL_GET_BY_ID } from 'state/TimesheetModal';
import { useCanShowModal } from '../hooks/useCanShowModal';
import { useSelectorWithProps } from 'hooks';
import { getUserListResponse } from 'state/UsersCollection';

type Props = {
  rowNumber: number;
  weekDayIndex: number;
  userId: string | null;
  areaId?: string;
  roleId?: string;
  menuType: 'create' | 'edit';
  timesheetId: string | null | undefined;
  viewType: 'site' | 'user';
};

export const TimesheetWeekCellBlock = memo(function TimesheetWeekCellBlock(
  props: Props
) {
  const {
    weekDayIndex,
    userId,
    rowNumber,
    areaId,
    roleId,
    menuType,
    timesheetId,
    viewType,
  } = props;
  const inlineIsLoading = useSelector(getIsLoading);
  const weekDays = useSelector(getWeekDays);
  const siteId = useSelector(getSiteId);
  const currentTimeBySiteTimezone = useSelector(
    currentTimeBySiteTimezoneSelector
  );
  const users = useSelector(getUserListResponse);
  const dispatch = useDispatch();
  const sites = useSelector(getSites);
  const canEditTimesheet = useSelectorWithProps(
    hasPermissionSelector,
    'roster.timesheet.edit'
  );
  const canShowModal = useCanShowModal({
    type: 'timesheet',
    userId,
    areaId,
    roleId,
  });

  const setId = () => {
    return getRowId('timesheet-' + weekDayIndex, {
      viewType: viewType,
      user_id: userId,
      area_id: areaId,
      role_id: roleId,
      rowIndex: rowNumber,
    });
  };

  const rowId = setId();

  const getRangeInputPayload = () => {
    return {
      dayTimestamp: weekDays[weekDayIndex],
      type: 'timesheet' as any,
      rowIndex: rowNumber,
      user_id: userId,
      site_id: siteId,
      area_id: areaId,
      role_id: roleId,
    };
  };

  const isArchivedUser = () => {
    return userId && users[userId] && !users[userId].is_active;
  };

  const openTimeRangeInput = (target: MouseEvent<HTMLDivElement>) => {
    if (!target || isArchivedUser()) {
      return;
    }
    if (
      !(target as any).target.classList ||
      !(target as any).target.classList.contains('week-rosters-grid__cell')
    ) {
      return;
    }
    dispatch(BOX_ROSTER_RANGE_INPUT_OPEN(getRangeInputPayload()));
  };

  const cellRelatedProps = () => {
    const timeZoneId = sites[siteId].timezone_id;
    return {
      id: '',
      start: moment(+weekDays[weekDayIndex]).tz(timeZoneId),
      end: moment(+weekDays[weekDayIndex]).tz(timeZoneId),
      user_id: userId,
      area_id: areaId ? areaId : '',
      role_id: roleId ? roleId : '',
    };
  };

  const onDoubleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    dispatch(BOX_SHIFT_TIME_RANGE_INPUT_CLOSED());

    if (!canShowModal || !canEditTimesheet || inlineIsLoading || timesheetId) {
      return false;
    }

    dispatch(BOX_TIMESHEET_SHIFT_MODAL_GET_BY_ID(cellRelatedProps()));
  };

  const covidFutureCellClassName = () => {
    return addFutureClassName(
      currentTimeBySiteTimezone,
      moment(weekDays[weekDayIndex], 'x')
    );
  };

  const showTimesheetCard = (id: string) => {
    return viewType === 'user' ? (
      <UserTimesheetCard timesheetId={id} />
    ) : (
      <SiteTimesheetCard timesheetId={id} />
    );
  };

  const disabledClassName = () => {
    return isArchivedUser() ? 'is-disabled' : '';
  };

  return (
    <DelayedClickDiv
      onClick={openTimeRangeInput}
      id={rowId}
      onDoubleClick={onDoubleClick}
      className={
        'week-rosters-grid__cell timesheet-empty-cell' +
        covidFutureCellClassName() +
        ' ' +
        disabledClassName()
      }
    >
      {!!timesheetId ? (
        showTimesheetCard(timesheetId)
      ) : (
        <>
          <RosterShiftTimeRangeInput {...getRangeInputPayload()} />
          {!isArchivedUser() && (
            <ContextualMenuBlock
              id={rowId}
              cellData={cellRelatedProps()}
              shiftType={'timesheet'}
              menuType={menuType}
            />
          )}
        </>
      )}
    </DelayedClickDiv>
  );
});
