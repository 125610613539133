import React from 'react';

export function useFormFields<S>(state: S | (() => S)) {
  const [formFields, setFormFields] = React.useState(state);

  const setPartialFormFields = <K extends keyof S>(
    updatedFields: Partial<S>
  ) => {
    setFormFields((prevState) => ({ ...prevState, ...updatedFields }));
  };

  return {
    formFields,
    setFormFields: setPartialFormFields,
  };
}

export default useFormFields;
