import React from 'react';
import LoaderHolder from './LoaderHolder';
import { LoaderHolderProps } from '../type';
import './ButtonStyle.scss';

function ButtonStyle(props: LoaderHolderProps) {
  return (
    <LoaderHolder
      className="elmo-loader-button"
      viewBoxHeight={60}
      viewBoxWidth={300}
      {...props}
    >
      <rect x="0" y="10" rx="4" ry="4" width="100" height="50" />
    </LoaderHolder>
  );
}

export default ButtonStyle;
