import { DurationHoursRequiredField } from 'element';
import FormControlSpacing from 'element/FormControlSpacing';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
} from 'extended-oxygen-elements';
import { useSetCheckbox, useSetFormField } from 'hooks';
import { capitalize } from 'lib/helpers';
import marketLabels from 'marketLabels';
import React from 'react';
import { FieldGroupProps } from 'type';
import {
  HelperTextWrapper,
  InlineInputWrapper,
  Divider,
} from 'page/Settings/components';
import InfoIconButton from './InfoIconButton';
import {
  OtherRosterSettingsFieldGroupErrors,
  OtherRosterSettingsFieldGroupValue,
} from './types';

type Props = FieldGroupProps<
  OtherRosterSettingsFieldGroupValue,
  OtherRosterSettingsFieldGroupErrors
>;

export default OtherRosterSettingsFieldGroup;

export function OtherRosterSettingsFieldGroup({
  values,
  onChange,
  errors,
}: Props) {
  const { makeSetCheckbox } = useSetCheckbox(onChange);
  const { makeSetFormField } = useSetFormField(onChange);

  return (
    <>
      <Typography variant="h4" component="h2">
        Share {marketLabels.roster}
      </Typography>

      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.autopublish_changes}
              onChange={makeSetCheckbox('autopublish_changes')}
            />
          }
          label={`Automatically share ${marketLabels.roster}`}
        />

        <HelperTextWrapper>
          <FormHelperText fontSize="medium">
            Automatically share changes to an existing, new or deleted shift,
            with no need to click the ‘Share {marketLabels.roster}’ button on
            the {marketLabels.roster} page.
          </FormHelperText>
        </HelperTextWrapper>
      </FormControl>

      <Divider />

      <Typography variant="h4" component="h2">
        Unavailability
      </Typography>

      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.unavailability_must_be_approved}
              onChange={makeSetCheckbox('unavailability_must_be_approved')}
            />
          }
          label="Approve unavailability"
        />

        <HelperTextWrapper>
          <FormHelperText fontSize="medium">
            Manager is required to approve unavailability.
          </FormHelperText>
        </HelperTextWrapper>
      </FormControl>

      <Divider />

      <Typography variant="h4" component="h2">
        Timesheet threshold
      </Typography>

      <FormControlSpacing>
        <Typography variant="body1" component="h6">
          Allow
          <InlineInputWrapper>
            <DurationHoursRequiredField
              inputProps={{
                size: 4,
              }}
              margin="none"
              fullWidth={false}
              formatUnit="hours"
              defaultUnit="hours"
              hoursPrefix="hrs"
              value={values.allow_assign_timesheet_within}
              onValueChange={makeSetFormField('allow_assign_timesheet_within')}
              error={!!errors.allow_assign_timesheet_within}
            />
          </InlineInputWrapper>
          for {marketLabels.roster} to link a timesheet to a shift.
          <InfoIconButton />
        </Typography>
        {errors.allow_assign_timesheet_within && (
          <FormHelperText error>
            {errors.allow_assign_timesheet_within}
          </FormHelperText>
        )}
      </FormControlSpacing>

      <Divider />

      <Typography variant="h4" component="h2">
        {capitalize(marketLabels.roster)} visibility
      </Typography>

      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.shift_who_else_working}
              onChange={makeSetCheckbox('shift_who_else_working')}
            />
          }
          label="Who is working with me?"
        />

        <HelperTextWrapper>
          <FormHelperText fontSize="medium">
            Allow people to see who is working with them during their shift,
            providing they are working at the same location.
          </FormHelperText>
        </HelperTextWrapper>
      </FormControl>
    </>
  );
}
