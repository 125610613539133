import React, { useEffect, useState } from 'react';
import { PlaceholderModal, LoadingOverlay } from 'elmo-elements';
import { connect } from 'react-redux';
import { getTemplatesModal } from 'state/Roster/RosterCopyPast/selectors';
import { StoreState } from 'state/types';
import {
  ApplyRosterTemplatePayload,
  DeleteTemplatePayload,
  GetTemplatesListPayload,
  ListOfTemplatesModal,
} from 'state/Roster/RosterCopyPast/type';
import {
  BOX_ROSTER_CLOSE_TEMPLATES_MODAL,
  BOX_ROSTER_GET_TEMPLATES_LIST,
  BOX_ROSTER_DELETE_TEMPLATE,
  BOX_ROSTER_TEMPLATES_MODAL_CLEAR_ERRORS,
  BOX_ROSTER_APPLY_TEMPLATE,
} from 'state/Roster/RosterCopyPast/actions';
import { TemplatesList } from './components/TemplatesList';
import { getLangPreferences } from 'state/Account';
import { LanguagePreferences, RosteredShift, Timesheet } from 'type/models';
import { getPreferenceLabel } from 'lib/helpers';
import { getSiteId } from 'state/RosteredShifts';
import { ErrorBox } from 'element/ErrorBox';
import { getById } from './helpers';
import { PastConfirmationModal } from '../PastConfirmationModal';
import { DeleteConfirmationModal } from '../DeleteConfirmationModal';
import { privateRoutes } from 'routes';
import { withRouter, RouteComponentProps } from 'react-router';
import { BOX_EDIT_TEMPLATE_SET_PREVIOUS_PAGE } from 'state/Roster/EditTemplate/actions';
import { isAppMarket } from 'helpers';
import { rosteredShiftsArrayFilteredByFromSelector } from 'state/Roster/RosterWeekView';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';
import { PageDialog, PageDialogCancel, PageDialogSubmit } from 'element';

type StateProps = {
  modal: ListOfTemplatesModal;
  langPreferences: LanguagePreferences;
  siteId: string;
  rosteredShifts: RosteredShift[];
};

type DispatchProps = {
  closeModal: () => void;
  getTemplatesList: (params: GetTemplatesListPayload) => void;
  deleteTemplateById: (params: DeleteTemplatePayload) => void;
  clearErrors: () => void;
  applyTemplate: (params: ApplyRosterTemplatePayload) => void;
  setPrevPage: (params: null | string) => void;
};

type Props = StateProps & DispatchProps & RouteComponentProps;

export const TemplatesModalComponent = ({
  modal: { isOpened, errors, templates, isLoading, isUpdating },
  closeModal,
  langPreferences,
  getTemplatesList,
  siteId,
  deleteTemplateById,
  clearErrors,
  applyTemplate,
  history,
  setPrevPage,
  rosteredShifts,
}: Props) => {
  const [templateId, setCurrentTemplate] = useState<any>('');
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>('');
  const [overrideDate, setDate] = useState<number>(1);
  const [confirmApply, setConfirmApply] = useState<boolean>(false);
  const [range, setRange] = useState({
    start: '',
    end: '',
  });

  const getTitle = () => {
    const labelText = isAppMarket('uk') ? 'rota' : 'roster';
    const label = getPreferenceLabel(
      langPreferences,
      labelText,
      'singular',
      labelText,
      true
    );
    return `${label} templates`;
  };

  useEffect(() => {
    if (isOpened) {
      getTemplatesList({
        site_id: siteId,
      });
    } else {
      setCurrentTemplate('');
    }
  }, [isOpened]);

  const openDeleteModal = (params: DeleteTemplatePayload) => {
    setDeleteModal(true);
    setDeleteId(params.id);
  };

  const confirmDelete = () => {
    setDeleteModal(false);
    deleteTemplateById({ id: deleteId });
  };

  const getTemplateParams = () => {
    const template = getById(templateId, templates);
    return {
      id: template.id,
      from: range.start,
      to: range.end,
    };
  };

  const triggerApplyTemplate = () => {
    applyTemplate(getTemplateParams());
    setConfirmApply(false);
  };

  const setTemplateOptions = (start: string, end: string) => {
    setRange({
      start,
      end,
    });
  };

  const navigateToTemplate = (id: string) => {
    const view = history.location.pathname.includes('user-view')
      ? 'rosterWeekViewEditUserView'
      : 'rosterWeekViewEditSiteView';
    setPrevPage(history.location.pathname + history.location.search);
    history.push(
      privateRoutes.roster.routes.rosterEditTemplate.routes[view].path(id)
    );
    closeModal();
  };

  const onClickApplyTemplateInMainModal = () => {
    if (rosteredShifts.length === 0) {
      triggerApplyTemplate();
      return;
    }
    setConfirmApply(true);
  };

  return isLoading ? (
    <PlaceholderModal type={'medium'} closeModal={closeModal} />
  ) : (
    <>
      <PageDialog
        maxWidth={'sm'}
        open={isOpened}
        id={'templates-modal'}
        onClose={closeModal}
      >
        <DialogTitle>{getTitle()}</DialogTitle>
        <DialogContent>
          <LoadingOverlay isLoading={isUpdating}>
            {errors.length > 0 && (
              <ErrorBox errors={errors} clearErrors={clearErrors} />
            )}
            <TemplatesList
              setTemplateOptions={setTemplateOptions}
              templates={templates}
              langPreferences={langPreferences}
              selected={templateId}
              setSelectedTemplate={setCurrentTemplate}
              deleteTemplateById={openDeleteModal}
              setDate={setDate}
              overrideDate={overrideDate}
              navigateToTemplate={navigateToTemplate}
            />
          </LoadingOverlay>
        </DialogContent>
        <DialogActions sx={{ boxShadow: '0 0 6px 2px #ccc', zIndex: 3 }}>
          <PageDialogCancel onClick={closeModal} fullWidth={false}>
            Cancel
          </PageDialogCancel>
          <PageDialogSubmit
            id="apply-template"
            disabled={!templateId || isUpdating}
            onClick={onClickApplyTemplateInMainModal}
            fullWidth={false}
          >
            Apply
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
      <DeleteConfirmationModal
        isOpened={deleteModal}
        onClose={() => setDeleteModal(false)}
        onApprove={confirmDelete}
      />
      <PastConfirmationModal
        isLoading={isUpdating}
        isOpened={confirmApply}
        onClose={() => setConfirmApply(false)}
        onApprove={triggerApplyTemplate}
        title={'Confirmation'}
      />
    </>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  modal: getTemplatesModal(state),
  langPreferences: getLangPreferences(state),
  siteId: getSiteId(state),
  rosteredShifts: rosteredShiftsArrayFilteredByFromSelector(state),
});

const mapDispatchToProps: DispatchProps = {
  closeModal: BOX_ROSTER_CLOSE_TEMPLATES_MODAL,
  getTemplatesList: BOX_ROSTER_GET_TEMPLATES_LIST,
  deleteTemplateById: BOX_ROSTER_DELETE_TEMPLATE,
  clearErrors: BOX_ROSTER_TEMPLATES_MODAL_CLEAR_ERRORS,
  applyTemplate: BOX_ROSTER_APPLY_TEMPLATE,
  setPrevPage: BOX_EDIT_TEMPLATE_SET_PREVIOUS_PAGE,
};

export const TemplatesModal = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TemplatesModalComponent)
);
