import {
  ReportScheduleDay,
  ReportScheduleSchedulePeriod
} from 'type';

export const WEEKDAYS_LABELS: {
  [key in ReportScheduleDay]: {
    label: string;
  }
} = {
  '1': {
    label: 'Monday'
  },
  '2': {
    label: 'Tuesday'
  },
  '3': {
    label: 'Wednesday'
  },
  '4': {
    label: 'Thursday'
  },
  '5': {
    label: 'Friday'
  },
  '6': {
    label: 'Saturday'
  },
  '7': {
    label: 'Sunday'
  }
};

export const SCHEDULE_LABELS: {
  [key in ReportScheduleSchedulePeriod]: {
    label: string;
  }
} = {
  daily: {
    label: 'Daily'
  },
  weekly: {
    label: 'Weekly'
  },
  fortnightly: {
    label: 'Fortnightly'
  },
  monthly: {
    label: 'Monthly'
  }
};
