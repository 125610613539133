import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@mui/styles';
import { DefaultDivProps } from 'type';
import { useLayoutContext } from './Layout';

type Props = DefaultDivProps & {
  children: React.ReactNode;
};

type StylesProps = {
  top: number;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: ({ top }: StylesProps) => ({
      position: 'sticky',
      top,
      backgroundColor: theme.palette.background.default
    }),
    shadow: {
      pointerEvents: 'none',
      position: 'absolute',
      top: 0,
      right: 0,
      height: '100%',
      width: '100%',
      opacity: 0,
      boxShadow: theme.shadows[2],
      transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    shadowVisible: {
      opacity: 1,
    },
  })
);

export default Sticky;

export function Sticky({ children, ...divProps }: Props) {
  const { appBarHeight, headerHeight } = useLayoutContext();
  const top = appBarHeight + headerHeight;
  const classes = useStyles({ top });
  const [isSticky, setIsSticky] = React.useState(false);
  const boxRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const { current } = boxRef;
    if (!current) {
      return;
    }
    if (!IntersectionObserver) {
      return;
    }

    const stickyObserver = new IntersectionObserver(
      ([{ isIntersecting }]) => {
        setIsSticky(!isIntersecting);
      },
      {
        rootMargin: `${-top - 1}px 0px 0px 0px`,
        threshold: [1],
      }
    );

    stickyObserver.observe(current);

    return () => {
      stickyObserver.disconnect();
    };
  }, [top]);

  return (
    <div
      {...divProps}
      className={clsx([classes.root, divProps.className])}
      ref={boxRef}
    >
      <div
        className={clsx([classes.shadow, isSticky && classes.shadowVisible])}
      />

      {children}
    </div>
  );
}
