import * as React from 'react';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import * as actions from 'state/UserGroups';
import {
  Button,
  FormItem,
  Input,
  Select,
  withLayoutAware,
} from 'elmo-elements';
import ErrorBox from 'element/ErrorBox';
import {
  DeleteForeverOutlinedIcon,
  PageDialog,
  PageDialogCancel,
  PageDialogSubmit,
} from 'element';
import { SelectProps } from '../../type';

import { Props } from './type';
import {
  getAddModal,
  getDeleteModal,
  getGroup,
  getIsLoading,
  getNameError,
  getUpdateModal,
  getUserGroups,
  getUserGroupsErrors,
} from 'state/UserGroups';
import { DialogContent, MenuItem, TextField } from 'extended-oxygen-elements';
import {
  DialogActions,
  DialogTitle,
  Typography,
} from '../../../../../../extended-oxygen-elements';
import { isAppMarket } from '../../../../../../helpers';

export class GroupsModals extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  renderAddGroupModal = () => {
    return (
      <PageDialog
        maxWidth={'xs'}
        open={this.props.addModal.isOpened}
        id="AddGroup"
      >
        <DialogTitle>Permissions</DialogTitle>
        <DialogContent>
          <ErrorBox
            errors={this.props.addModal.errors}
            clearErrors={this.props.clearErrors}
            watchFormErrors={true}
          />

          <FormItem
            label="Name"
            message={this.props.nameError ? 'Name is required' : ''}
            status={this.props.nameError ? 'error' : 'default'}
          >
            <Input
              value={this.props.group.title}
              onChange={this.getName}
              status={this.props.nameError ? 'error' : undefined}
              ariaLabel="Name"
            />
          </FormItem>
          <FormItem label="Template">
            <Select
              options={this.templateOptions}
              onChange={this.getTemplate}
              defaultValue={this.templateOptions[0]}
              ariaLabel="Template"
            />
            <div style={{ height: '60px' }} />
          </FormItem>
        </DialogContent>
        <DialogActions>
          <PageDialogCancel onClick={() => this.props.closeAddModal()}>
            Cancel
          </PageDialogCancel>
          <PageDialogSubmit
            onClick={this.addGroup}
            disabled={!this.props.group.title}
            loading={this.props.addModal.isUpdating}
            id="add-group-btn"
          >
            Add
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  };

  renderUpdateGroupModal = () => {
    return (
      <PageDialog
        maxWidth={'xs'}
        open={this.props.updateModal.isOpened}
        id="UpdateGroup"
      >
        <DialogTitle>Permissions</DialogTitle>
        <DialogContent>
          <ErrorBox
            errors={this.props.updateModal.errors}
            clearErrors={this.props.clearErrors}
            watchFormErrors={true}
          />
          <FormItem
            label="Name"
            message={this.props.nameError ? 'Name is required' : ''}
            status={this.props.nameError ? 'error' : 'default'}
          >
            <Input
              value={this.props.group.title}
              onChange={this.getName}
              status={this.props.nameError ? 'error' : undefined}
              ariaLabel="Name"
            />
          </FormItem>
          <FormItem label="Template">
            <Select
              options={this.templateOptions}
              onChange={this.getTemplate}
              defaultValue={this.selectedOption}
              ariaLabel="Template"
            />
          </FormItem>
          <Button
            type="danger"
            isLeftIndent={true}
            isText={true}
            isUppercase={false}
            className={'update-group-modal-btn'}
            onClick={() => {
              this.props.closeUpdateModal();
              this.props.openDeleteModal();
            }}
            icon={<DeleteForeverOutlinedIcon />}
          >
            Delete
          </Button>
        </DialogContent>
        <DialogActions>
          <PageDialogCancel onClick={() => this.props.closeUpdateModal()}>
            Cancel
          </PageDialogCancel>
          <PageDialogSubmit
            onClick={this.updateGroup}
            disabled={!this.props.group.title}
            loading={this.props.updateModal.isUpdating}
            id="update-group-btn"
          >
            Save
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  };

  getSelectedReceiver = () => {
    const { receiver_id } = this.props.group;
    const receiver = this.groupsOptions.filter(
      (option) => option.value === receiver_id
    );
    if (!receiver.length) {
      return this.groupsOptions.length ? this.groupsOptions[0].value : '';
    }
    return receiver[0].value;
  };

  renderDeleteGroupModal = () => {
    return (
      <PageDialog
        maxWidth={'xs'}
        open={this.props.deleteModal.isOpened}
        id="DeleteGroup"
      >
        <DialogTitle>Delete permissions</DialogTitle>
        <DialogContent>
          <ErrorBox
            errors={this.props.deleteModal.errors}
            clearErrors={this.props.clearErrors}
            watchFormErrors={true}
          />
          <p className="user-groups__deleteMessage">
            Before deleting this permissions, please assign permissions you
            would like the users to go to.
          </p>

          <FormItem label="Assign Users to">
            <TextField
              select
              onChange={this.getReceivedId}
              value={this.getSelectedReceiver()}
              aria-label="Assign Users to"
            >
              {this.groupsOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </FormItem>
        </DialogContent>
        <DialogActions>
          <PageDialogCancel onClick={() => this.props.closeDeleteModal()}>
            Cancel
          </PageDialogCancel>
          <PageDialogSubmit
            className={'delete-group-button'}
            onClick={this.deleteGroup}
            loading={this.props.deleteModal.isUpdating}
            disabled={this.props.deleteModal.isUpdating}
          >
            Delete permissions
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  };

  render() {
    return (
      <>
        {this.renderAddGroupModal()}

        {this.props.updateModal.isOpened ? this.renderUpdateGroupModal() : ''}

        {this.props.deleteModal.isOpened ? this.renderDeleteGroupModal() : ''}
      </>
    );
  }

  getTemplate = (e: SelectProps) => {
    this.props.selectGroup({
      ...this.props.group,
      template: e.value,
      template_updated: true,
    });
  };

  getReceivedId = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    this.props.selectGroup({
      ...this.props.group,
      receiver_id: target.value,
    });
  };

  getName = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    this.props.selectGroup({
      ...this.props.group,
      title: value,
    });

    !value ? this.props.showNameError() : this.props.hideNameError();
  };

  deleteGroup = () => {
    const { receiver_id } = this.props.group;
    this.props.deleteGroup({
      id: +this.props.group.id,
      receiver_id: receiver_id ? +receiver_id : +this.groupsOptions[0].value,
    });

    const groups = this.props.groups;
    delete groups[this.props.group.id];
    this.setState({
      groups: {
        ...groups,
      },
    });
  };

  addGroup = () => {
    this.props.addGroup(this.props.group);
  };

  updateGroup = () => {
    const { id } = this.props.group;
    const groups = this.props.groups;

    this.props.updateGroup(this.props.group);

    groups[id].title = this.props.group.title;
    this.setState({
      groups: {
        ...groups,
      },
    });
  };

  get groupsOptions() {
    let options: {
      label: string;
      value: string;
    }[] = [];

    Object.keys(this.props.groups).forEach((id) => {
      if (this.props.group.id !== id) {
        options.push({
          label: this.props.groups[id].title,
          value: id,
        });
      }
    });
    return options;
  }

  get selectedOption() {
    let label = this.props.group.template || '';
    label = label === 'manager' ? 'line manager' : label;
    label = label.charAt(0).toUpperCase() + label.slice(1);

    if (this.props.group.template) {
      return {
        label,
        value: this.props.group.template,
      };
    } else {
      return null;
    }
  }

  get templateOptions() {
    return [
      {
        label: 'Employee',
        value: 'employee',
      },
      {
        label: 'Line manager',
        value: 'manager',
      },
    ];
  }
}

export const mapStateToProps = (state: StoreState) => ({
  isLoading: getIsLoading(state),
  errors: getUserGroupsErrors(state),
  addModal: getAddModal(state),
  updateModal: getUpdateModal(state),
  deleteModal: getDeleteModal(state),
  nameError: getNameError(state),
  group: getGroup(state),
  groups: getUserGroups(state),
});

export default connect(mapStateToProps, {
  getGroups: actions.BOX_USER_GROUPS_REQUEST,
  addGroup: actions.BOX_ADD_USER_GROUP_REQUEST,
  updateGroup: actions.BOX_UPDATE_USER_GROUP_REQUEST,
  deleteGroup: actions.BOX_DELETE_USER_GROUP_REQUEST,
  openAddModal: actions.BOX_USER_GROUPS_ADD_MODAL_OPEN,
  closeAddModal: actions.BOX_USER_GROUPS_ADD_MODAL_CLOSE,
  openUpdateModal: actions.BOX_USER_GROUPS_UPDATE_MODAL_OPEN,
  closeUpdateModal: actions.BOX_USER_GROUPS_UPDATE_MODAL_CLOSE,
  openDeleteModal: actions.BOX_USER_GROUPS_DELETE_MODAL_OPEN,
  closeDeleteModal: actions.BOX_USER_GROUPS_DELETE_MODAL_CLOSE,
  showNameError: actions.BOX_USER_GROUP_SHOW_NAME_ERROR_REQUEST,
  hideNameError: actions.BOX_USER_GROUP_HIDE_NAME_ERROR_REQUEST,
  selectGroup: actions.BOX_USER_GROUP_SELECT_REQUEST,
})(withLayoutAware(GroupsModals));
