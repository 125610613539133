import { GetLinkedRoleLabelPayload } from './types';
import { getLinkedRoleName } from './getLinkedRoleName';
import { getLinkedRole } from './getLinkedRole';

export * from './getLinkedRole';
export * from './getLinkedRoleName';

export function getLinkedRoleLabel({
  fallBack,
  ...getLinkedRolePayload
}: GetLinkedRoleLabelPayload): string {
  const role = getLinkedRole(getLinkedRolePayload);
  return getLinkedRoleName(role, fallBack);
}
