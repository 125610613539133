import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import { StringMap, UserFields, UserFieldsManagers } from 'type';
import { userListSelector } from 'state/UsersCollection';
import { EditApproversPayload } from 'state/Users/Users/types';
import { getEditApproversModal } from 'state/Users/Users/selectors';
import { ApprovalsModal, ApprovalsModalProps } from '../../../../components';
import {
  BOX_USERS_EDIT_APPROVALS_MODAL_CLOSE,
  BOX_USERS_EDIT_APPROVALS_REQUEST
} from '../../../../../../state/Users/Users';

type StateProps = Pick<
  ApprovalsModalProps,
  'isOpened' | 'isUpdating' | 'errors'
> & {
  userId: string;
  userList: StringMap<UserFields>;
};

type DispatchProps = Pick<ApprovalsModalProps, 'onCloseModal'> & {
  onFormSubmit: (data: EditApproversPayload) => void;
};

export type Props = StateProps & DispatchProps;
export class UsersEditApproversComponent extends Component<Props> {
  render() {
    const { userList, userId, ...restProps } = this.props;

    return (
      <ApprovalsModal
        {...restProps}
        managers={this.initManagers}
        userIds={this.userIds}
      />
    );
  }

  private get initManagers(): UserFieldsManagers | undefined {
    const user: UserFields | undefined = this.props.userList[this.props.userId];

    // TODO find out why do we need this check
    if (user) {
      return user.managers;
    }
  }

  private get userIds(): string[] {
    const { userId } = this.props;

    return [userId];
  }
}

export const mapStateToProps = (state: StoreState): StateProps => ({
  ...getEditApproversModal(state),
  userList: userListSelector(state)
});

const mapDispatchToProps: DispatchProps = {
  onCloseModal: BOX_USERS_EDIT_APPROVALS_MODAL_CLOSE,
  onFormSubmit: BOX_USERS_EDIT_APPROVALS_REQUEST
};
export const UsersEditApprovers = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersEditApproversComponent);
