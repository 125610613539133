import * as React from 'react';
import { connect } from 'react-redux';
import { Moment } from 'moment';
import { StoreState } from 'state/types';
import { timeFormatSelector } from 'state/Account';
import { currentTimeBySiteTimezoneSelector } from 'state/Roster/Roster/selectors';
import {
  currentTimeWidthSelector,
  dstOffsetWidthSelector,
  dstSpringForwardHourSelector,
  formattedHoursSelector,
  numberOfHoursSelector,
  timelineWidthSelector
} from 'state/Roster/RosterDayView';
import { RosterNavWrapper } from '../../../../components';
import { IsDstSpringForward } from '../IsDstSpringForward';
import { IsCurrentDay } from '../IsCurrentDay';
import { DayNavigationBadge } from './DayNavigationBadge';
import './DayNavigation.scss';

type OwnProps = {};

type StateProps = {
  hours: string[];
  numberOfHours: number;
  timelineWidth: number;
  timeFormat: string;
  currentTime: Moment;
  currentTimeWidth: number;
  dstSpringForwardHour: Moment | undefined;
  dstSpringForwardWidth: number;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

const DayNavigation = ({
  hours,
  numberOfHours,
  timelineWidth,
  timeFormat,
  currentTime,
  currentTimeWidth,
  dstSpringForwardHour,
  dstSpringForwardWidth
}: Props) => (
  <RosterNavWrapper>
    <div
      className={`roster-day-navigation improved`}
      style={{
        width: timelineWidth
      }}
    >
      {hours.map((label: string, index, array) => {
        const isLast = array.length === index + 1;
        const showTimeLabel = () => {
          return (
            <div
              key={label + index}
              className="roster-day-navigation__col"
              style={{
                width: isLast ? 0 : timelineWidth / numberOfHours
              }}
            >
              <span className="roster-day-navigation__label">{label}</span>
            </div>
          );
        };
        return !isLast ? showTimeLabel() : null;
      })}

      <IsDstSpringForward>
        {/*TODO refactor*/}
        {!!dstSpringForwardHour && (
          <DayNavigationBadge
            label={dstSpringForwardHour.format(timeFormat)}
            leftPosition={dstSpringForwardWidth}
            color="gray-500"
            tooltip="DST"
          />
        )}
      </IsDstSpringForward>

      <IsCurrentDay>
        <DayNavigationBadge
          label={currentTime.format(timeFormat)}
          leftPosition={currentTimeWidth}
          color="primary"
        />
      </IsCurrentDay>
    </div>
  </RosterNavWrapper>
);

const mapStateToProps = (state: StoreState): StateProps => ({
  hours: formattedHoursSelector(state),
  numberOfHours: numberOfHoursSelector(state),
  timelineWidth: timelineWidthSelector(state),
  timeFormat: timeFormatSelector(state),
  currentTime: currentTimeBySiteTimezoneSelector(state),
  currentTimeWidth: currentTimeWidthSelector(state),
  dstSpringForwardHour: dstSpringForwardHourSelector(state),
  dstSpringForwardWidth: dstOffsetWidthSelector(state)
});

// const mapDispatchToProps: DispatchProps = {};

export default connect(mapStateToProps)(DayNavigation);
