import React from 'react';
import { LoaderHolderProps, LoaderProps } from './type';
import IconStyle from './component/IconStyle';
import MenuStyle from './component/MenuStyle';
import AvatarStyle from './component/AvatarStyle';
import BulletListStyle from './component/BulletListStyle';
import CodeStyle from './component/CodeStyle';
import ContentStyle from './component/ContentStyle';
import LineStyle from './component/LineStyle';
import WordStyle from './component/WordStyle';
import TextStyle from './component/TextStyle';
import ShortTextStyle from './component/ShortTextStyle';
import ListStyle from './component/ListStyle';
import ButtonsStyle from './component/ButtonsStyle';
import ButtonStyle from './component/ButtonStyle';
import SpinnerStyle from './component/SpinnerStyle';
import NarrowContentStyle from './component/NarrowContentStyle';
import NarrowBulletListStyle from './component/NarrowBulletListStyle';
import CardContentStyle from './component/CardContentStyle';
import CardImageStyle from './component/CardImageStyle';

function Loader(props: LoaderProps) {
  const { type, children, isLoading } = props;
  const loaderProps = { ...props };
  delete loaderProps.type;
  delete loaderProps.children;
  delete loaderProps.isLoading;

  if (!isLoading) {
    return <>{children}</>;
  }

  switch (type) {
    case 'menu':
      return (
        <MenuStyle
          {...(loaderProps as LoaderHolderProps)}
          ariaLabel="Loading menu..."
        />
      );
    case 'icon':
      return (
        <IconStyle
          {...(loaderProps as LoaderHolderProps)}
          ariaLabel="Loading icon..."
        />
      );
    case 'avatar':
      return (
        <AvatarStyle
          {...(loaderProps as LoaderHolderProps)}
          ariaLabel="Loading avatar..."
        />
      );
    case 'button':
      return (
        <ButtonStyle
          {...(loaderProps as LoaderHolderProps)}
          ariaLabel="Loading button..."
        />
      );
    case 'buttons':
      return (
        <ButtonsStyle
          {...(loaderProps as LoaderHolderProps)}
          ariaLabel="Loading buttons..."
        />
      );
    case 'word':
      return (
        <WordStyle
          {...(loaderProps as LoaderHolderProps)}
          ariaLabel="Loading word..."
        />
      );
    case 'text':
      return (
        <TextStyle
          {...(loaderProps as LoaderHolderProps)}
          ariaLabel="Loading text..."
        />
      );
    case 'short-text':
      return (
        <ShortTextStyle
          {...(loaderProps as LoaderHolderProps)}
          ariaLabel="Loading short text..."
        />
      );
    case 'line':
      return (
        <LineStyle
          {...(loaderProps as LoaderHolderProps)}
          ariaLabel="Loading line..."
        />
      );
    case 'bullet':
      return (
        <BulletListStyle
          {...(loaderProps as LoaderHolderProps)}
          ariaLabel="Loading bullet..."
        />
      );
    case 'narrow-bullet':
      return (
        <NarrowBulletListStyle
          {...(loaderProps as LoaderHolderProps)}
          ariaLabel="Loading narrow bullet..."
        />
      );
    case 'code':
      return (
        <CodeStyle
          {...(loaderProps as LoaderHolderProps)}
          ariaLabel="Loading code..."
        />
      );
    case 'list':
      return (
        <ListStyle
          {...(loaderProps as LoaderHolderProps)}
          ariaLabel="Loading list..."
        />
      );
    case 'spinner':
      return (
        <SpinnerStyle
          {...(loaderProps as LoaderHolderProps)}
          ariaLabel="Loading spinner..."
        />
      );
    case 'narrow-content':
      return (
        <NarrowContentStyle
          {...(loaderProps as LoaderHolderProps)}
          ariaLabel="Loading narrow content..."
        />
      );
    case 'card-content':
      return (
        <CardContentStyle
          {...(loaderProps as LoaderHolderProps)}
          ariaLabel="Loading card content..."
        />
      );
    case 'card-image':
      return (
        <CardImageStyle
          {...(loaderProps as LoaderHolderProps)}
          ariaLabel="Loading card image..."
        />
      );
    case 'content':
    default:
      return <ContentStyle {...(loaderProps as LoaderHolderProps)} />;
  }
}

Loader.defaultProps = {
  type: 'content',
  isLoading: true,
};

export default Loader;
