import React, { Component } from 'react';
import './UsersListExpander.scss';
import { ServerUserFields, StringMap } from 'type';
import { Text, Paragraph, Button, Search } from 'elmo-elements';
import { HelpOutlineIcon, HighlightOffOutlinedIcon } from 'element/icons';
import { PreselectedAreaRole } from '../../types';
import { isAppMarket } from '../../../../helpers';
import { getUserName } from '../../../../lib/helpers';
import { Avatar } from 'oxygen-elements';

type Props = {
  availableUsers: StringMap<any>;
  users: StringMap<ServerUserFields>;
  selectedUser: string | null;
  setUser: (userId: string | null) => void;
  preselectedAreaRole: PreselectedAreaRole;
  isOpened: boolean;
  type: 'timesheet' | 'rostered_shift';
  owner: string | null;
};

type State = {
  list: {
    [key: string]: {
      label: string;
      items: object[];
    };
  };
  selected_id: null | string;
  searchTerm: string;
  availableUsers: StringMap<any>;
};

export class UsersListExpander extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      list: {},
      selected_id: props.selectedUser,
      searchTerm: '',
      availableUsers: props.availableUsers,
    };
  }

  render() {
    return (
      <>
        {this.showSearch()}
        {this.showExpander()}
      </>
    );
  }

  componentDidMount(): void {
    this.filterUsersBySearchTerm();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.availableUsers !== this.props.availableUsers) {
      this.filterUsersBySearchTerm();
    }
  }

  groupUsers = () => {
    const { availableUsers } =
      this.state.searchTerm !== '' ? this.state : this.props;
    const { owner } = this.props;
    const groups: any = {
      able_to_work: {
        label: 'Able to work',
        items: [],
      },
      declined: {
        label: 'Declined',
        items: [],
      },
      overlapping: {
        label: 'Overlapping shift',
        items: [],
      },
      rostered_today: {
        label: isAppMarket('uk') ? 'Scheduled today' : 'Rostered today',
        items: [],
      },
      unable_to_work: {
        label: 'Unable to work',
        items: [],
      },
    };

    Object.keys(availableUsers).forEach((userId: string) => {
      if (availableUsers[userId].is_on_time_off === true) {
        groups.unable_to_work.items.push(availableUsers[userId]);
      } else if (availableUsers[userId].is_overlapped === true) {
        groups.overlapping.items.push(availableUsers[userId]);
      } else if (availableUsers[userId].is_rostered_today === true) {
        groups.rostered_today.items.push(availableUsers[userId]);
      } else if (availableUsers[userId].is_declined === true) {
        groups.declined.items.push(availableUsers[userId]);
      } else if (availableUsers[userId].is_able_to_work === true) {
        groups.able_to_work.items.push(availableUsers[userId]);
      }
    });

    this.setState({
      list: groups,
    });
  };

  searchUser = (val: any) => {
    this.setState(
      {
        searchTerm: val,
      },
      () => {
        this.filterUsersBySearchTerm();
      }
    );
  };

  filterUsersBySearchTerm = () => {
    const { availableUsers, users } = this.props;
    const { searchTerm } = this.state;
    let searchResults: any = {};
    Object.keys(availableUsers).forEach((userId: string) => {
      const user = users[userId];
      const searchField = getUserName(user);
      if (
        searchTerm !== '' &&
        searchField.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
      ) {
        searchResults[userId] = availableUsers[userId];
      }
    });
    this.setState(
      {
        availableUsers: searchTerm !== '' ? searchResults : availableUsers,
      },
      () => {
        this.groupUsers();
      }
    );
  };

  showSearch = () => {
    return (
      <div
        className={'users-modal-search'}
        style={{ display: this.props.isOpened ? 'block' : 'none' }}
      >
        <Search
          isVisible={true}
          onChange={this.searchUser}
          value={this.state.searchTerm}
          onSubmit={() => {
            return false;
          }}
        />
      </div>
    );
  };

  showExpander = () => {
    const { list, availableUsers } = this.state;
    const { selectedUser, owner } = this.props;
    return (
      <div className="list">
        <div
          className="list__unassigned-item selected"
          style={{ display: selectedUser !== null ? 'none' : 'block' }}
        >
          <HelpOutlineIcon />
          <Text>Unassigned</Text>
        </div>
        {selectedUser && availableUsers[selectedUser] && (
          <div className="list__group">
            <div className={'list__group--item selected'}>
              {this.getUserItem(availableUsers[selectedUser])}
              <Button
                isText={true}
                icon={<HighlightOffOutlinedIcon />}
                onClick={() => this.props.setUser(null)}
                ariaLabel="Remove user"
                tabIndex={0}
              />
            </div>
          </div>
        )}
        {owner && owner !== selectedUser && availableUsers[owner] && (
          <div className="list__group">
            <div className="list__group--toggle">
              <Text size={'sm'}>Current owner</Text>
            </div>
            <div
              className={'list__group--item'}
              onClick={() => this.onSelectUser(owner)}
            >
              {this.getUserItem(availableUsers[owner])}
            </div>
          </div>
        )}
        <div className={'list-scroller'}>
          {Object.keys(list).map((groupKey: string, index: number) => (
            <div className="list__group" key={index}>
              <div className="list__group--toggle">
                <Text size={'sm'}>{(list as any)[groupKey].label}</Text>
              </div>
              <div className="list__group--items">
                {(list as any)[groupKey].items.map(
                  (user: any, key: number) =>
                    selectedUser !== user.user_id &&
                    owner !== user.user_id && (
                      <div
                        key={key}
                        className={'list__group--item'}
                        onClick={() => this.onSelectUser(user.user_id)}
                        tabIndex={0}
                        onKeyPress={() => this.onSelectUser(user.user_id)}
                      >
                        {this.getUserItem(user)}
                      </div>
                    )
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  isUserHasRole = (user: ServerUserFields) => {
    const {
      preselectedAreaRole: { area_id, role_id, site_id },
    } = this.props;
    let hasRole = false;
    if (user && user.user_roles) {
      user.user_roles.forEach(
        (role: { role_id: string; area_id: string; site_id: string }) => {
          if (
            role.role_id === role_id &&
            role.area_id === area_id &&
            role.site_id === site_id
          ) {
            hasRole = true;
          }
        }
      );
    }
    if (area_id === '' && role_id === '') {
      return true;
    }
    return hasRole;
  };

  getUserItem = (user: any) => {
    const { users, type } = this.props;
    if (!users[user.user_id]) {
      return null;
    }
    const roleInfo = this.isUserHasRole(users[user.user_id])
      ? ''
      : "(user doesn't have pre-selected role)";
    const userName = getUserName(users[user.user_id]);
    const url = users[user.user_id].avatar_url;
    const src = url && url !== null ? url : undefined;
    const rosterLabel = isAppMarket('uk') ? ' scheduled' : 'rostered';
    return (
      <>
        <span className={'user-status ' + this.getStatus(user)} />
        <Avatar size={'small'} alt={`${userName}`} src={src} light />
        <div className="list__group--user-info">
          <Text>
            {userName}{' '}
            <Text size={'xs'} color={'danger'}>
              {roleInfo}
            </Text>
          </Text>
          <Paragraph size={'sm'}>
            Today: {user.today_shift_hours},
            {type === 'timesheet' ? ' total hours worked' : rosterLabel}:{' '}
            {user.total_shift_hours}
          </Paragraph>
          <div className={'list__group--additional-info'}>
            <Paragraph size={'xs'}>
              Prev. shift:{' '}
              {user.prev_shift_hours === null ? 0 : user.prev_shift_hours}, next
              shift:{' '}
              {user.next_shift_hours === null ? 0 : user.next_shift_hours}
            </Paragraph>
            <Paragraph size={'xs'}>
              Total / min roster: {user.total_shift_hours}/
              {user.min_hours_per_roster}
            </Paragraph>
          </div>
        </div>
      </>
    );
  };

  getStatus = (user: any) => {
    return user.is_able_to_work ? 'green' : 'red';
  };

  getUserInfo = () => {
    const { users, selectedUser } = this.props;
    if (selectedUser !== null) {
      return getUserName(users[selectedUser]);
    }
  };

  onSelectUser = (userId: string) => {
    this.props.setUser(userId);
    this.setState({
      searchTerm: '',
    });
  };
}
