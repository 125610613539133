import * as React from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import {
  Button,
  DateRange,
  FormContainer,
  FormItem,
  Heading,
  Input,
  Modal,
  Select,
} from 'elmo-elements';
import {
  getDateTimeFormatted,
  getPreferenceLabel,
  getUserName,
} from 'lib/helpers';
import { getIsSettingsOpened } from 'state/AuditTrail/selectors';
import {
  BOX_AUDIT_TRAIL_FILTERS_MODAL_CLOSE,
  BOX_AUDIT_TRAIL_FILTERS_UPDATE,
} from 'state/AuditTrail';
import { AuditFilters } from '../../../../../../state/AuditTrail/state';
import { userListSelector } from '../../../../../../state/UsersCollection';
import {
  LanguagePreferences,
  PreferencesDateFormat,
  PreferencesTimeFormat,
  StringMap,
  UserFields,
} from '../../../../../../type';
import {
  getDateFormat,
  getLangPreferences,
  getTimeFormat,
} from 'state/Account';
import { isAppMarket } from '../../../../../../helpers';
import {
  AppBar,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Toolbar,
} from '../../../../../../extended-oxygen-elements';
import {
  CloseIcon,
  PageDialog,
  PageDialogCancel,
  PageDialogSubmit,
} from '../../../../../../element';
import {
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  TextField,
} from 'oxygen-elements';

type Props = {
  isOpened: boolean;
  closeModal: () => {};
  updateFilters: (filters: AuditFilters) => void;
  users: StringMap<UserFields>;
  langPreferences: LanguagePreferences;
  dateFormat: PreferencesDateFormat;
  timeFormat: PreferencesTimeFormat;
};
type State = {
  isOpenedSettings: boolean;
  filters: {
    action?: string;
    user_id?: string;
    from?: string;
    to?: string;
    shift_user_id?: string;
    shift_from?: string;
    shift_to?: string;
    id?: string;
  };
};

const dateActions = ['rosteredshift', 'timesheet', 'offer', 'swap'];

export class AuditForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpenedSettings: false,
      filters: {},
    };
  }

  render() {
    return (
      <>
        <PageDialog
          fullScreen
          open={this.props.isOpened}
          id="ModalFull"
          onClose={this.props.closeModal}
        >
          <AppBar
            sx={{ position: 'relative', background: '#fff', color: '#000' }}
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.props.closeModal}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <div style={{ color: '#000', width: '100%' }}>
                <DialogTitle>Audit Trail settings</DialogTitle>
              </div>
              <PageDialogSubmit
                onClick={this.applySettings}
                id={'generate-audit-trail-settings-btn'}
                fullWidth={false}
              >
                Generate
              </PageDialogSubmit>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ paddingTop: '20px' }}>
            <Heading>Date</Heading>
            <FormContainer maxColumns={3}>
              <FormItem isFluid={false}>
                <DateRange format={this.props.dateFormat} isVertical={false}>
                  <DateRange.Start
                    onChange={this.onDateRangeChange(true)}
                    label="From"
                    placeholder={this.props.dateFormat}
                  />

                  <DateRange.End
                    onChange={this.onDateRangeChange(false)}
                    label="To"
                    placeholder={this.props.dateFormat}
                  />
                </DateRange>
              </FormItem>

              <hr />
            </FormContainer>

            <Heading>Filter</Heading>
            <FormContainer maxColumns={3}>
              <FormItem label="ID" isFluid={false}>
                <Input
                  htmlType="text"
                  name="id"
                  onChange={this.onIdChange}
                  ariaLabel="ID"
                />
              </FormItem>

              <FormItem label="User" isFluid={false}>
                <Select
                  options={this.users}
                  defaultValue={{ label: 'All', value: 'all' }}
                  onChange={(e) => {
                    this.setState({
                      filters: {
                        ...this.state.filters,
                        user_id: e.value,
                      },
                    });
                  }}
                  ariaLabel="User"
                />
              </FormItem>

              <FormItem label="Action" isFluid={false}>
                <Select
                  id="audit-action"
                  options={this.actions}
                  defaultValue={{ label: 'All', value: 'all' }}
                  onChange={(e) => {
                    this.setState({
                      filters: {
                        ...this.state.filters,
                        action: e.value,
                      },
                    });
                  }}
                  ariaLabel="Action"
                />
              </FormItem>

              <hr />
            </FormContainer>

            {this.showDate && (
              <div id="audit-shift-details">
                <Heading>Shift date</Heading>

                <FormContainer>
                  <FormItem isFluid={false}>
                    <DateRange
                      format={this.props.dateFormat}
                      isVertical={false}
                    >
                      <DateRange.Start
                        onChange={this.onShiftDateRangeChange(true)}
                        label="From"
                        placeholder={this.props.dateFormat}
                      />
                      <DateRange.End
                        onChange={this.onShiftDateRangeChange(false)}
                        label="To"
                        placeholder={this.props.dateFormat}
                      />
                    </DateRange>
                  </FormItem>
                </FormContainer>

                <Heading>Shift details</Heading>

                <FormItem label="Shift user" isFluid={false}>
                  <Select
                    options={this.users}
                    defaultValue={{ label: 'All', value: 'all' }}
                    onChange={(e) => {
                      this.setState({
                        filters: {
                          ...this.state.filters,
                          shift_user_id: e.value,
                        },
                      });
                    }}
                    ariaLabel="Shift user"
                  />
                </FormItem>
              </div>
            )}
          </DialogContent>
        </PageDialog>
      </>
    );
  }

  onIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
  };

  onDateRangeChange = (isStartDate: boolean) => (value: Date | null) => {
    const { dateFormat, timeFormat } = this.props;
    const dateString = value
      ? getDateTimeFormatted(dateFormat, timeFormat, value)
      : undefined;
    if (isStartDate) {
      this.setState({
        filters: {
          ...this.state.filters,
          from: dateString,
        },
      });
    } else {
      this.setState({
        filters: {
          ...this.state.filters,
          to: dateString,
        },
      });
    }
  };

  onShiftDateRangeChange = (isStartDate: boolean) => (value: Date | null) => {
    const { dateFormat, timeFormat } = this.props;
    const dateString = value
      ? getDateTimeFormatted(dateFormat, timeFormat, value)
      : undefined;
    if (isStartDate) {
      this.setState({
        filters: {
          ...this.state.filters,
          shift_from: dateString,
        },
      });
    } else {
      this.setState({
        filters: {
          ...this.state.filters,
          shift_to: dateString,
        },
      });
    }
  };

  applySettings = () => {
    let filters = {
      ...this.filters,
      action:
        this.state.filters.action === 'all'
          ? undefined
          : this.state.filters.action,
      user_id:
        this.state.filters.user_id === 'all'
          ? undefined
          : this.state.filters.user_id,
      shift_user_id:
        this.state.filters.shift_user_id === 'all'
          ? undefined
          : this.state.filters.shift_user_id,
    };

    if (this.state.filters.action === 'all') {
      filters = {
        ...filters,
        shift_user_id: undefined,
        shift_from: undefined,
        shift_to: undefined,
      };
    }

    this.props.updateFilters(filters);

    this.setState({
      filters: {},
    });
  };

  get actions() {
    const actions = [
      'rosteredshift',
      'timesheet',
      'unavailability',
      'roster',
      'forecastevent',
      'user',
      'offer',
      'swap',
      'settings',
      'report',
      'message',
      'tree',
      'notifications',
      'tag',
      'usergroup',
      'notification_settings',
      'sms_inbound',
      'sms_accept',
      'pay_entries',
      'payroll_export',
      'pay_entries_status',
      'set_employee_lock_date',
      'user_awards',
    ];

    return [
      { label: 'All', value: 'all' },
      ...actions.map((action) => {
        return this.createSelectOptionsFromActions(action);
      }),
    ];
  }

  createSelectOptionsFromActions = (action: string) => {
    if (isAppMarket('uk')) {
      if (action === 'rosteredshift') {
        return { label: 'shift', value: action };
      }
      if (action === 'roster') {
        return { label: 'rota', value: action };
      }
    }
    return { label: action, value: action };
  };

  get users() {
    return [
      { label: 'All', value: 'all' },
      ...Object.keys(this.props.users).map((id) => {
        const label = getUserName(this.props.users[id]);
        return { label, value: id };
      }),
    ];
  }

  get showDate() {
    const action = this.state.filters.action ? this.state.filters.action : '';
    return dateActions.indexOf(action) !== -1;
  }

  get filters() {
    return {
      ...this.state.filters,
    };
  }
}

const mapStateToProps = (state: StoreState) => ({
  isOpened: getIsSettingsOpened(state),
  users: userListSelector(state),
  langPreferences: getLangPreferences(state),
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
});

const mapDispatchToProps = {
  closeModal: BOX_AUDIT_TRAIL_FILTERS_MODAL_CLOSE,
  updateFilters: BOX_AUDIT_TRAIL_FILTERS_UPDATE,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditForm);
