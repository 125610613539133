import React from 'react';
import { DataTableColumn } from '../type';
import { getClass } from '../../_lib/helper';
import DataTableTh from './DataTableTh';
import DataTableBulkActionsButton from './DataTableBulkActionsButton';
import DataTableSort from './DataTableSort';
import { withDataTableContext } from '../DataTableContextConsumer';
import DataTableSelect from './DataTableSelect';
import DataTableCustomiseButton from './DataTableCustomiseButton';
import DataTableSelectAllCheckbox from './DataTableSelectAllCheckbox';
import './DataTableHeader.scss';

type DataTableHeaderProps = {
  /** Id of the component */
  id?: string;
  /** Custom class name */
  className?: string;
  /** The header columns */
  columns: DataTableColumn[];
  /** When a column is sorted */
  onSort?: (column: number, direction: string) => void;
  /** The column currently sorting on */
  sortColumn?: number;
  /** The current sort direction */
  sortDirection?: string;
  /** Called when Customise button clicked */
  hasCustomise?: boolean;
  /** Called when the Select All Checkbox is toggled */
  onSelectAllVisibleToggle?: (isSelect: boolean) => void;

  /** @ignore Comes from DataTable Context. */
  setContextState: (state: any) => void;
  /** @ignore Comes from DataTable Context. */
  hasBulkActions?: boolean;
  /** @ignore Comes from DataTable Context. */
  hasRowActions?: boolean;
  /** @ignore Comes from DataTable Context. */
  showSelectAllComponent?: boolean;
  /** @ignore Comes from Layout Context. */
  toggleBulkActionOpen?: any;
  /** @ignore Comes from Layout Context. */
  isBulkActionOpen?: boolean;
  /** @ignore Comes from DataTable Context. */
  showCustomiseModal: boolean;
  /** @ignore Comes from DataTable Context. */
  headerColumns: DataTableColumn[];
};

type DataTableHeaderState = {
  /** Columns to display in the header */
  displayColumns: DataTableColumn[];
};

class DataTableHeader extends React.Component<
  DataTableHeaderProps,
  DataTableHeaderState
> {
  static displayName = 'DataTableHeader';

  readonly state = {
    displayColumns: [],
  };

  componentDidMount() {
    this.updateContext();
    this.updateDisplayColumns();
  }

  componentDidUpdate(prevProps: DataTableHeaderProps) {
    const { columns, setContextState } = this.props;

    // Update the columns in the DataTable context if columns have changed.
    if (prevProps.columns !== this.props.columns) {
      setContextState({
        headerColumns: columns,
      });
    }

    if (prevProps.headerColumns !== this.props.headerColumns) {
      this.updateDisplayColumns();
    }
  }

  updateDisplayColumns = () => {
    const displayColumns = this.props.headerColumns.filter(
      (c: DataTableColumn) => {
        return !c.isHidden;
      }
    );

    // get only the columns which should be displayed
    this.setState({
      displayColumns: displayColumns,
    });
  };

  updateContext = () => {
    const { columns, setContextState, hasCustomise } = this.props;

    setContextState({
      headerColumns: columns ? columns : [],
      showCustomiseModal: hasCustomise,
    });
  };

  /**
   * Called when Sort is clicked.
   * @param column
   */
  handleSort = (column: number) => () => {
    const { sortDirection, sortColumn, onSort } = this.props;

    const nextSortDirection =
      column !== sortColumn || !sortDirection || sortDirection === 'desc'
        ? 'asc'
        : 'desc';

    if (onSort) {
      onSort(column, nextSortDirection);
    }
  };

  /**
   * Renders the "Select All Items" Component
   */
  renderSelectHeader() {
    const { headerColumns } = this.props;

    return (
      <DataTableTh colSpan={headerColumns.length}>
        <DataTableSelect />
      </DataTableTh>
    );
  }

  /**
   * Renders the Header Columns
   */
  renderColumns() {
    const { sortDirection, onSort, toggleBulkActionOpen, showCustomiseModal } =
      this.props;

    const { displayColumns } = this.state;

    if (!displayColumns) {
      return null;
    }

    return displayColumns.map((column: DataTableColumn, index: number) => {
      const shouldShowBulkActions = this.shouldShowBulkActionsButton(index);
      const shouldShowCustomiseButton =
        index === displayColumns.length - 1 && showCustomiseModal;

      const colSpan = this.computeColSpan(index);
      return (
        <DataTableTh
          key={index}
          width={column.width}
          colSpan={colSpan}
          textAlign={column.textAlign}
          className={getClass('', {
            'bulk-actions': shouldShowBulkActions,
            customise: shouldShowCustomiseButton,
          })}
        >
          {shouldShowBulkActions && (
            <DataTableBulkActionsButton
              toggleBulkActionOpen={toggleBulkActionOpen}
            />
          )}
          {shouldShowCustomiseButton && <DataTableCustomiseButton />}
          <div
            className={getClass('elmo-datatable__sort-wrapper', '', {
              sortable: column.sortable,
            })}
            {...(column.sortable && onSort
              ? { onClick: this.handleSort(index) }
              : {})}
          >
            {column.title}
            <DataTableSort
              direction={sortDirection}
              isHidden={!this.shouldShowSortIcon(index)}
            />
          </div>
        </DataTableTh>
      );
    });
  }

  shouldShowSortIcon = (columnIndex: number) => {
    const { sortColumn } = this.props;
    return sortColumn === columnIndex;
  };

  shouldShowBulkActionsButton = (columnIndex: number) => {
    const { isBulkActionOpen, hasBulkActions } = this.props;
    return hasBulkActions && columnIndex === 0 && !isBulkActionOpen;
  };

  /**
   * Compute the colspan for each header column.
   * @param columnIndex
   */
  computeColSpan = (columnIndex: number) => {
    const colSpanWithActions = 2;
    const colSpanNormal = 1;

    const { hasRowActions } = this.props;

    const { displayColumns } = this.state;

    /**
     * If rows have an action column, then the colspan for the last column should be 2
     */
    const isLastColumn: boolean = columnIndex === displayColumns.length - 1;
    return hasRowActions && isLastColumn ? colSpanWithActions : colSpanNormal;
  };

  render() {
    const {
      id,
      className,
      isBulkActionOpen,
      hasBulkActions,
      showSelectAllComponent,
      onSelectAllVisibleToggle,
    } = this.props;

    const { displayColumns } = this.state;

    if (!displayColumns) {
      return null;
    }

    return (
      <thead id={id} className={getClass('elmo-datatable__head', className)}>
        <tr>
          {hasBulkActions && isBulkActionOpen && (
            <DataTableSelectAllCheckbox
              onSelectAllVisibleToggle={onSelectAllVisibleToggle}
            />
          )}
          {showSelectAllComponent && this.renderSelectHeader()}
          {!showSelectAllComponent && this.renderColumns()}
        </tr>
      </thead>
    );
  }
}

export default withDataTableContext(DataTableHeader);
