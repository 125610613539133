import { DeleteDialogContextProvider } from 'contexts';
import { useTheme } from 'extended-oxygen-elements';
import { useSelectorWithProps } from 'hooks';
import React from 'react';
import { getShiftOvertimeDayRule } from 'state/Account';
import { Dictionary } from 'ts-essentials';
import LoadingsTable from './components/LoadingsTable';
import LoadingsTableCellHeading from './components/LoadingsTableCellHeading';
import LoadingsTableRow from './components/LoadingsTableRow';
import { PayConditionsListContextProvider } from './pay-conditions-list-context';
import AddPayConditionButton from './payConditionsTable/AddPayConditionButton';
import CreatePayCondition from './payConditionsTable/CreatePayCondition';
import DeleteConfirmationDialog from './payConditionsTable/DeleteConfirmationDialog';
import NoRulesMessage from './payConditionsTable/NoRulesMessage';
import PayConditionRow from './payConditionsTable/PayConditionRow';

export default PayConditionsTable;

const flexes: Dictionary<string> = {
  0: '0 0 70px',
  1: '0 0 70px',
  2: 'auto',
  3: '0 0 50px',
  4: '0 0 50px',
};

export function PayConditionsTable() {
  const theme = useTheme();
  const overtimeRules = useSelectorWithProps(
    getShiftOvertimeDayRule,
    'everyday'
  );
  const day = 'everyday' as const;

  return (
    <PayConditionsListContextProvider>
      <DeleteDialogContextProvider>
        <>
          <LoadingsTable flexes={flexes} minWidth="sm" maxWidth="sm">
            <LoadingsTableRow>
              <LoadingsTableCellHeading>From</LoadingsTableCellHeading>

              <LoadingsTableCellHeading>To</LoadingsTableCellHeading>

              <LoadingsTableCellHeading>Pay Element</LoadingsTableCellHeading>
            </LoadingsTableRow>

            {overtimeRules.map((overtimeRule) => (
              <PayConditionRow
                overtimeRule={overtimeRule}
                day={day}
                key={overtimeRule.id}
              />
            ))}

            <CreatePayCondition day={day} />
          </LoadingsTable>

          <NoRulesMessage rulesQuantity={overtimeRules.length} />
        </>

        <AddPayConditionButton p={2} />

        <DeleteConfirmationDialog day={day} />
      </DeleteDialogContextProvider>
    </PayConditionsListContextProvider>
  );
}
