export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';

export type DatepickerProps = {
  /** Id of the component */
  id?: string;
  /** Class name for styling */
  className?: string;
  /** The format the datepicker should use */
  format: string;
  /** name attribute */
  name?: string;
  /** The value of the datepicker */
  value: Date | null | undefined;
  /** Callback for when a date has been selected */
  onChange: (value: Date | null, name?: string) => void;
  /** Placeholder text of the datepicker input box */
  placeholder?: string;
  /** Whether you can clear the date or not */
  isClearable?: boolean;
  /** This function is called for every rendered date, the date will be disabled if the function returns true */
  disabledDate?: (current?: Date) => boolean;
  /** Disabled state */
  isDisabled?: boolean;
  /** False if the datepicker is attached to the <body> */
  isInline?: boolean;
  /** Read only state */
  isReadOnly?: boolean;
  /** Add ariaLabel for WCAG accessibility compliance, (optional) */
  ariaLabel?: string;
  /** Set first day of week: 0 - Sun, 1 - Mon, 2 - Tue and etc., (optional) */
  firstDayOfWeek?: number;
  clickOnTodayButton?: () => void;
};
