import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSelectorWithProps } from 'hooks';
import { RosteredShift } from 'type';
import { hasPermissionSelector } from 'state/Auth';
import { getSiteId } from 'state/RosteredShifts';
import { getIsApprovalModeEnabled } from 'state/Roster/Roster';
import { getIsCovidModeEnabled } from 'state/Roster/CovidTracing';
import { getIsBulkDeleteOpened } from 'state/Roster/BulkActions';
import { BOX_ROSTER_SHIFT_MODAL_GET_BY_ID } from 'state/Roster/RosterShiftModal';

type Props = Pick<RosteredShift, 'start' | 'role_id' | 'area_id' | 'user_id'>;
const useHandleClickAddButton = ({
  start: originalShiftStart,
  role_id,
  area_id,
  user_id,
}: Props): React.MouseEventHandler<HTMLElement> | undefined => {
  const canEditShift = useSelectorWithProps(
    hasPermissionSelector,
    'roster.rosteredshift.edit'
  );
  const isApprovalMode = useSelector(getIsApprovalModeEnabled);
  const isBulkDelete = useSelector(getIsBulkDeleteOpened);
  const siteId = useSelector(getSiteId);
  const isCovidMode = useSelector(getIsCovidModeEnabled);
  const canAddShift =
    canEditShift && !isBulkDelete && !isApprovalMode && !isCovidMode;

  const dispatch = useDispatch();

  const handleClickAddButton = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      const start = originalShiftStart.clone().startOf('day');
      const end = start.clone().add(1, 'day').startOf('day');
      dispatch(
        BOX_ROSTER_SHIFT_MODAL_GET_BY_ID({
          id: '',
          start,
          end,
          role_id,
          area_id,
          site_id: siteId,
          user_id,
        })
      );
    },
    [originalShiftStart, role_id, area_id, user_id, isCovidMode]
  );

  if (canAddShift) {
    return handleClickAddButton;
  }
};

export default useHandleClickAddButton;
