import React from 'react';
import { Button, Text } from 'elmo-elements';
import { DeleteOutlinedIcon, EditOutlinedIcon } from 'element';
import { DeleteTemplatePayload } from 'state/Roster/RosterCopyPast/type';
import { getById } from '../../helpers';
import { IconButton } from 'oxygen-elements';

type Props = {
  templates: any;
  templateId: string;
  setCurrentTemplate: (value: string) => void;
  getTemplateContent: (value: string) => void;
  deleteTemplateById: (params: DeleteTemplatePayload) => void;
  navigateToTemplate: (value: string) => void;
};

const AvailableTemplates = ({
  templates,
  templateId,
  setCurrentTemplate,
  getTemplateContent,
  deleteTemplateById,
  navigateToTemplate,
}: Props) => {
  const deleteTemplate = (e: any, id: string) => {
    e.preventDefault();
    e.stopPropagation();
    deleteTemplateById({ id: id });
  };
  const setCurrent = (e: any, id: string) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrentTemplate(id);
  };
  const navigateToEditTemplate = (e: any, id: string) => {
    e.preventDefault();
    e.stopPropagation();
    navigateToTemplate(id);
  };
  const style = {
    position: 'absolute',
    right: 0,
    top: '3px',
    borderRadius: '3px',
  };
  return (
    <div className={'list-scroller'}>
      {templates.length === 0 ||
      (templates.length === 1 && templateId === templates[0].id) ? (
        <Text className={'mt-2 mb-2'} size={'xs'}>
          No available templates
        </Text>
      ) : (
        templates.map(
          (t: any, key: number) =>
            t.id !== templateId && (
              <div key={key} className="templates-list__group">
                <div
                  className="templates-list__group--toggle"
                  onClick={(e) => setCurrent(e, t.id)}
                >
                  {getTemplateContent(getById(t.id, templates))}
                  <IconButton
                    id={`edit-template-${key}`}
                    className={'mr-5 edit-btn'}
                    onClick={(e) => navigateToEditTemplate(e, t.id)}
                    sx={{ ...style, right: '50px' }}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                  <IconButton
                    id={`delete-template-${key}`}
                    className={'delete-btn'}
                    onClick={(e) => deleteTemplate(e, t.id)}
                    sx={style}
                  >
                    <DeleteOutlinedIcon />
                  </IconButton>
                </div>
              </div>
            )
        )
      )}
    </div>
  );
};

export default AvailableTemplates;
