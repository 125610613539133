import React from 'react';
import { CloseIcon } from '../icons';
import { IconButton } from '../../extended-oxygen-elements';

type Props = {
  onClose: () => void;
};

export const PageDialogCloseIconButton = ({ onClose }: Props) => (
  <IconButton
    aria-label="close"
    onClick={onClose}
    sx={{
      position: 'absolute',
      right: 8,
      top: 8,
      color: (theme) => theme.palette.grey[500],
    }}
  >
    <CloseIcon />
  </IconButton>
);
