import React from 'react';
import { Skeleton } from 'extended-oxygen-elements';
import {
  SummaryHeader,
  SummaryLabel,
  SummaryValue,
  SummaryWrapper,
} from './summary-view-components';

export function SummarySkeleton() {
  return (
    <SummaryWrapper>
      <SummaryHeader>
        <Skeleton width={100} />
      </SummaryHeader>

      <SummaryLabel>
        <Skeleton width={100} />
      </SummaryLabel>

      <SummaryValue>
        <Skeleton width={100} />
      </SummaryValue>
    </SummaryWrapper>
  );
}
