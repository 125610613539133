import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { getAreas } from 'state/AccountTree';
import { useMakeSelector, useSelectorWithProps } from 'hooks';
import {
  makeItemAreaIdRoleIdSelector
} from 'state/Roster/RosterWeekViewSiteView';
import RosterSiteViewHeading from 'page/Roster/components/RosterSiteViewHeading';

type Props = { siteRowIndex: number };

export const AreaHeading = memo(function AreaHeading(props: Props) {
  const areas = useSelector(getAreas);
  const itemAreaIdRoleIdSelector = useMakeSelector(makeItemAreaIdRoleIdSelector);
  const itemAreaIdRoleId = useSelectorWithProps(itemAreaIdRoleIdSelector, props);
  const prevItemIdRoleId = useSelectorWithProps(itemAreaIdRoleIdSelector, {
    siteRowIndex: props.siteRowIndex > 0 ? props.siteRowIndex - 1 : 0
  });
  const renderAreaHeading = () => {
    const { area_id } = itemAreaIdRoleId;
    if ( areas[area_id] && (!props.siteRowIndex || prevItemIdRoleId.area_id !== area_id) ) {
      return <RosterSiteViewHeading title={areas[area_id].name} show={true} />;
    }
    return null;
  };

  return renderAreaHeading();
});