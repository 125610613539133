import * as React from 'react';
import { times } from 'lodash';
import {
  RosterWeekNavigation,
  RosterWeekNavigationDay
} from '../../../../components';

export const WeekNavigation = () => {
  const createDays = (): RosterWeekNavigationDay[] =>
    times(7, index => ({
      title: (index + 1).toString(10),
      current: false
    }));

  return <RosterWeekNavigation days={createDays()} />;
};
