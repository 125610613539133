import moment, { Moment } from 'moment-timezone';
import {
  RosteredShift,
  RosterTimeOff,
  RosterTimeOffLeave,
  Timesheet,
} from '../../../type';
import _ from 'lodash';
import { SERVER_DAY_FORMAT, SERVER_TIME_FORMAT } from 'lib/config';
import {
  GetWeekNavParamsPayload,
  RosterWeekViewTimeOff,
  RosterWeekViewTimeOffLeave,
} from './types';
import { isAppMarket } from '../../../helpers';
import { Dictionary } from 'ts-essentials/dist/types';

export const getDaysArray = function (start: any) {
  let arr: string[] = [];
  for (let i = 0; i < 7; i += 1) {
    arr.push(moment(start).add(i, 'day').format('x'));
  }
  return arr;
};

const getShiftByUserId = (shifts: RosteredShift[], t: Timesheet) => {
  let result = false;
  shifts.map((s: RosteredShift) => {
    if (t.rostered_shift_id === s.id && t.user_id !== s.user_id) {
      result = true;
    }
  });
  return result;
};

const getTimesheetByUserId = (timesheets: Timesheet[], s: RosteredShift) => {
  let result = false;
  timesheets.map((t: Timesheet) => {
    if (s.timesheet_id === t.id && t.user_id !== s.user_id) {
      result = true;
    }
  });
  return result;
};

export const dateByTimezone = (date: Moment, tz: string) => {
  return moment.tz(tz).set({
    year: +date.clone().format('YYYY'),
    month: +date.clone().format('MM') - 1,
    date: +date.clone().format('DD'),
  });
};

export const getWeekNavParams = ({
  storedSiteId,
  sites,
  from,
}: GetWeekNavParamsPayload) => {
  const dayMoment = moment(from);
  const queryDay = dayMoment.isValid()
    ? dayMoment.parseZone()
    : moment.parseZone();
  let tz = '';

  if (storedSiteId) {
    tz = sites[storedSiteId].timezone_id;
  }

  return {
    from:
      tz !== ''
        ? dateByTimezone(queryDay, tz).startOf('isoWeek')
        : moment(queryDay).startOf('isoWeek'),
    to:
      tz !== ''
        ? dateByTimezone(queryDay, tz).endOf('isoWeek')
        : moment(queryDay).endOf('isoWeek'),
  };
};

export const getScheduleConfig = (payload: GetWeekNavParamsPayload) => {
  const { from } = getWeekNavParams(payload);
  const startDate: number = +from.format('x');
  const weekDays: string[] = getDaysArray(startDate);
  let dayNames: any = {};
  for (let day of weekDays) {
    dayNames[day] = [];
  }
  return {
    dayNames: dayNames,
    weekDays: weekDays,
  };
};

const isRosteredShift = (
  shift: Timesheet | RosteredShift
): shift is RosteredShift =>
  typeof (shift as RosteredShift).timesheet_id !== 'undefined';

const filterId = (
  shiftId: string | null,
  acceptableIds: string[]
): string | null => {
  if (!shiftId) {
    return null;
  }

  if (acceptableIds.includes(shiftId)) {
    return shiftId;
  }

  return null;
};

export const shiftsInDifferentDays = (
  timesheet: Timesheet | null | undefined,
  shift: RosteredShift | null | undefined
) => {
  if (!timesheet || !shift) {
    return false;
  }
  const timesheetDay = moment(timesheet.start).format('d');
  const rosterDay = moment(shift.start).format('d');
  return timesheetDay !== rosterDay;
};

const getTimesheetById = (
  timesheets: Timesheet[],
  timesheetId: string | null
) => {
  return timesheetId ? timesheets.filter((t) => t.id === timesheetId)[0] : null;
};

const getRosteredShiftById = (
  shifts: RosteredShift[],
  shiftId: string | null
) => {
  return shiftId ? shifts.filter((r) => r.id === shiftId)[0] : null;
};

type TimeOffsArr = {
  timeOffs: RosterWeekViewTimeOff[];
};

export const getRosterCellWeekTimeOffData = (
  item: RosterWeekViewTimeOff | TimeOffsArr
) => {
  // all timeoffs in one card
  if (item.hasOwnProperty('timeOffs')) {
    const ids: string[] = [];
    (item as TimeOffsArr).timeOffs.forEach((timeOff: RosterWeekViewTimeOff) =>
      ids.push(timeOff.id)
    );
    return {
      timeOffIds: ids,
      leaveId: null,
      rosteredShiftId: null,
      timesheetId: null,
    };
  }

  // leave
  return {
    rosteredShiftId: null,
    timesheetId: null,
    timeOffIds: null,
    leaveId: (item as RosterWeekViewTimeOff).id,
  };
};

const getRosterCellRosteredShiftData = (
  item: RosteredShift,
  timesheets: Timesheet[],
  filteredRosteredShiftIds: string[],
  filteredTimesheetIds: string[]
) => {
  const isDisconnectedByUser = getTimesheetByUserId(timesheets, item);
  const timesheet: Timesheet | null = getTimesheetById(
    timesheets,
    item.timesheet_id
  );
  const timesheetId =
    isDisconnectedByUser || shiftsInDifferentDays(timesheet, item)
      ? null
      : item.timesheet_id;
  return {
    rosteredShiftId: filterId(item.id, filteredRosteredShiftIds),
    timesheetId: filterId(timesheetId, filteredTimesheetIds),
    timeOffIds: null,
    leaveId: null,
  };
};

const getRosterCellTimesheetData = (
  item: Timesheet,
  rosteredShifts: RosteredShift[],
  filteredRosteredShiftIds: string[],
  filteredTimesheetIds: string[]
) => {
  const rosteredShift: RosteredShift | null = getRosteredShiftById(
    rosteredShifts,
    item.rostered_shift_id
  );
  const isDisconnectedByUser = getShiftByUserId(rosteredShifts, item);
  const rosteredShiftId =
    isDisconnectedByUser || shiftsInDifferentDays(item, rosteredShift)
      ? null
      : item.rostered_shift_id;
  return {
    rosteredShiftId: filterId(rosteredShiftId, filteredRosteredShiftIds),
    timesheetId: filterId(item.id, filteredTimesheetIds),
    timeOffIds: null,
    leaveId: null,
  };
};

export const getRosterCellData = (
  weekData: (RosteredShift | Timesheet | RosterWeekViewTimeOff | TimeOffsArr)[],
  dayIndex: number,
  rosteredShiftIds: string[],
  timesheetIds: string[],
  rosteredShifts: RosteredShift[],
  timesheets: Timesheet[]
) => {
  if (weekData.length && weekData[dayIndex]) {
    const item = weekData[dayIndex];

    // time-offs
    if (item.hasOwnProperty('timeOffs') || item.hasOwnProperty('type')) {
      const timeOffs: RosterWeekViewTimeOff | TimeOffsArr = item as any;
      return getRosterCellWeekTimeOffData(timeOffs);
    }

    // rostered shift / timesheet
    if (isRosteredShift(item as RosteredShift | Timesheet)) {
      return getRosterCellRosteredShiftData(
        item as RosteredShift,
        timesheets,
        rosteredShiftIds,
        timesheetIds
      );
    }

    return getRosterCellTimesheetData(
      item as Timesheet,
      rosteredShifts,
      rosteredShiftIds,
      timesheetIds
    );
  }

  return {};
};

export const filterRostersByUserId = (
  userID: string | null,
  data: RosteredShift[]
): RosteredShift[] => {
  return data.filter(
    (rData: { user_id: string | null }) => rData.user_id === userID
  );
};

export const filterTimesheetsByUserId = (
  userID: string | null,
  data: Timesheet[]
): Timesheet[] => {
  return data.filter(
    (rData: { user_id: string | null }) => rData.user_id === userID
  );
};

export const getTimesheetsWithoutRosters = (
  timesheets: Timesheet[],
  shifts: RosteredShift[]
) => {
  const aloneTimsheets: Timesheet[] = [];
  const keys: string[] = [];
  shifts.forEach((item: RosteredShift) => {
    keys.push(item.id);
  });
  const datesAreDifferent = (timesheet: Timesheet) => {
    for (let shift of shifts) {
      if (shift.id === timesheet.rostered_shift_id) {
        return shiftsInDifferentDays(timesheet, shift);
      }
    }
    return false;
  };
  timesheets.forEach((t: Timesheet) => {
    if (t.rostered_shift_id === null) {
      aloneTimsheets.push(t);
    }
    if (
      t.rostered_shift_id !== null &&
      keys.indexOf(t.rostered_shift_id) === -1
    ) {
      aloneTimsheets.push(t);
    }
    if (
      t.rostered_shift_id !== null &&
      keys.indexOf(t.rostered_shift_id) !== -1 &&
      datesAreDifferent(t)
    ) {
      t.rostered_shift_id = null; // do not show shift from another week on page
      aloneTimsheets.push(t);
    }
  });
  return aloneTimsheets;
};

export const setStartDateTime = (
  start: Moment | string,
  { storedSiteId, sites }: GetWeekNavParamsPayload
) => {
  const dateFormat = 'YYYY-MM-DD';
  let tz = '';
  if (storedSiteId) {
    tz = sites[storedSiteId].timezone_id;
  }
  const formattedDate =
    tz !== ''
      ? moment(start).tz(tz, true).startOf('day').format(dateFormat)
      : moment(start).startOf('day').format(dateFormat);
  return moment(formattedDate).tz(tz, true).startOf('day');
};

const pushTimeOffsIntoWeek = (
  timeOffs: Dictionary<{
    leaves: RosterWeekViewTimeOff[];
    timeoffs: RosterWeekViewTimeOff[];
  }>,
  weekParams: {
    maxRostersInDay: number;
    days: any;
  }
) => {
  for (let day in timeOffs) {
    if (weekParams.days[day]) {
      for (let i = 0; i < timeOffs[day].leaves.length; i++) {
        const item = timeOffs[day].leaves[i];
        weekParams.days[day].push(item);
        if (weekParams.days[day].length > weekParams.maxRostersInDay) {
          weekParams.maxRostersInDay += 1;
        }
      }

      if (timeOffs[day].timeoffs.length) {
        const item = timeOffs[day].timeoffs;
        weekParams.days[day].push({
          timeOffs: item,
        });
        if (weekParams.days[day].length > weekParams.maxRostersInDay) {
          weekParams.maxRostersInDay += 1;
        }
      }
    }
  }
};

const pushShiftsIntoWeek = (
  data: (RosteredShift | Timesheet)[],
  weekParams: {
    days: any;
    maxRostersInDay: number;
  },
  weekNavParamsPayload: GetWeekNavParamsPayload
) => {
  for (let item of data) {
    const date = +setStartDateTime(item.start, weekNavParamsPayload).format(
      'x'
    );
    if (weekParams.days[date]) {
      weekParams.days[date].push(item);
      if (weekParams.days[date].length > weekParams.maxRostersInDay) {
        weekParams.maxRostersInDay += 1;
      }
    }
  }

  if (weekParams.maxRostersInDay === 0) {
    weekParams.maxRostersInDay = 1;
  }
};

export const buildRosterRowsWithTimeOffs = (
  data: (RosteredShift | Timesheet)[],
  filteredRosteredShifts: RosteredShift[],
  filteredTimesheets: Timesheet[],
  weekNavParamsPayload: GetWeekNavParamsPayload,
  timeOffs: Dictionary<{
    leaves: RosterWeekViewTimeOff[];
    timeoffs: RosterWeekViewTimeOff[];
  }>
): {
  rows: any;
  rowsNumber: number;
} => {
  const rosteredShiftIds: string[] = filteredRosteredShifts.map(
    ({ id }: RosteredShift) => id
  );

  const timesheetIds: string[] = filteredTimesheets.map(
    ({ id }: Timesheet) => id
  );

  const weekMatrix: any[] = [];

  const { weekDays, dayNames } = getScheduleConfig(weekNavParamsPayload);

  const weekParams = {
    maxRostersInDay: 0,
    days: dayNames,
  };

  pushTimeOffsIntoWeek(timeOffs, weekParams);
  pushShiftsIntoWeek(data, weekParams, weekNavParamsPayload);

  for (let rowIndex = 0; rowIndex < weekParams.maxRostersInDay; rowIndex += 1) {
    weekMatrix.push(new Array(weekDays.length));
    for (let columnIndex = 0; columnIndex < weekDays.length; columnIndex += 1) {
      const currentDayData = weekParams.days[weekDays[columnIndex]];
      weekMatrix[rowIndex][columnIndex] = getRosterCellData(
        currentDayData,
        rowIndex,
        rosteredShiftIds,
        timesheetIds,
        filteredRosteredShifts,
        filteredTimesheets
      );
    }
  }

  return {
    rowsNumber: weekParams.maxRostersInDay,
    rows: weekMatrix,
  };
};

export const buildEventsRows = (
  data: any[],
  getWeekNavParamsPayload: GetWeekNavParamsPayload
): {
  rows: any;
  rowsNumber: number;
} => {
  const matrixArr: any[] = [];
  const matrixB: any[] = [];
  const { weekDays, dayNames } = getScheduleConfig(getWeekNavParamsPayload);
  let events: any[] = _.orderBy(_.toArray(data), 'start');

  const keys = Object.keys(dayNames);
  const s = +keys[0];
  const e = +keys[keys.length - 1];
  const isBetween = (t: number) => {
    const current = moment(t).utc().format('L');
    const start = moment(s).utc().format('L');
    const end = moment(e).utc().format('L');
    return current >= start && current <= end;
  };

  const getDaysNumber = (start: Moment, end: Moment) => {
    let num = 0;
    const diff = end.diff(start, 'days');
    for (let k = 0; k <= diff; k += 1) {
      const d = +moment(start).add(k, 'day').startOf('day').format('x');
      if (dayNames[d] || isBetween(d)) {
        num += 1;
      }
    }
    return num;
  };

  const getStartDateOfEventInCurrentWeek = (start: Moment, end: Moment) => {
    const diff = end.diff(start, 'days');
    for (let k = 0; k <= diff; k += 1) {
      const d = +setStartDateTime(start, getWeekNavParamsPayload)
        .add(k, 'day')
        .startOf('day')
        .format('x');
      if (dayNames[d]) {
        return d;
      }
      if (isBetween(d)) {
        return s;
      }
    }
    return +setStartDateTime(start, getWeekNavParamsPayload)
      .startOf('day')
      .format('x');
  };

  for (let i = 0; i < events.length; i += 1) {
    matrixArr.push(new Array(7));
    for (let j = 0; j < 7; j += 1) {
      matrixArr[i][j] = weekDays[j];
    }
  }

  const fillNextDays = (
    row: number,
    day: number,
    duration: number,
    id: string
  ) => {
    for (let i = 1; i < duration; i += 1) {
      matrixArr[row][day + i] = {
        isFilledBy: id,
      };
    }
  };

  let rowsNum = 0;

  events.forEach((event: any) => {
    const dateStart = moment.parseZone(event.start).startOf('day');
    const dateEndShifted =
      moment.parseZone(event.end).format('HH:mm') === '00:00'
        ? moment.parseZone(event.end).subtract(1, 'minute')
        : moment.parseZone(event.end);
    const dateEnd = dateEndShifted.startOf('day');
    const d = getStartDateOfEventInCurrentWeek(dateStart, dateEnd);
    let toRemove = '';

    for (let i = 0; i < data.length; i += 1) {
      for (let j = 0; j < 7; j += 1) {
        if (+matrixArr[i][j] === d) {
          const duration = getDaysNumber(dateStart, dateEnd);

          matrixArr[i][j] = {
            id: event.id,
            title: event.name,
            duration: duration,
          };

          if (duration > 1) {
            fillNextDays(i, j, duration, event.id);
          }

          toRemove = event.id;

          if (i >= rowsNum) {
            rowsNum = i;
          }

          break;
        }
      }

      if (toRemove !== '') {
        break;
      }
    }
  });

  if (rowsNum === 0) {
    matrixB.push([{}, {}, {}, {}, {}, {}, {}]);
    matrixB.push([{}, {}, {}, {}, {}, {}, {}]);
    rowsNum = 2;
  }

  for (let i = 0; i <= rowsNum; i += 1) {
    matrixB.push(new Array(7));
    for (let j = 0; j < 7; j += 1) {
      if (matrixArr[i]) {
        matrixB[i][j] = matrixArr[i][j];
      }
    }
  }

  if (matrixB.length < 2) {
    matrixB.push([{}, {}, {}, {}, {}, {}, {}]);
  }

  return {
    rowsNumber: rowsNum,
    rows: matrixB,
  };
};
export const enumerateDaysBetweenDates = (
  startDate: Moment,
  endDate: Moment
) => {
  const from = startDate.clone().startOf('day');
  const to = endDate.clone().startOf('day').utcOffset(from.utcOffset(), true);
  const dates: string[] = [];
  const now = from.clone();

  while (now.isSameOrBefore(to, 'day')) {
    dates.push(now.format(SERVER_DAY_FORMAT));
    now.add(1, 'days');
  }

  return dates;
};
export const getWeekViewTimeOffDueToRosteredShift = ({
  start,
  end,
  user_id,
  ...rest
}: RosterTimeOff): RosterWeekViewTimeOff => {
  return {
    ...rest,
    duration: 'few_hours',
    start_time: start.clone(),
    end_time: end.clone(),
  };
};

export const getWeekViewTimeOffLeave = (
  rosterTimeOff: RosterTimeOffLeave,
  day: string
): RosterWeekViewTimeOffLeave => {
  let base = {
    id: rosterTimeOff.id,
    type: rosterTimeOff.type,
    status: rosterTimeOff.status,
    subtype: rosterTimeOff.subtype,
  };

  const getAllDay = (): RosterWeekViewTimeOffLeave => {
    if (isAppMarket('au')) {
      const options = {
        half_start: false,
        half_end: false,
      };
      if (rosterTimeOff.options) {
        const start = rosterTimeOff.start.clone();
        const end = rosterTimeOff.end.clone();
        const momentDate = moment(day, SERVER_DAY_FORMAT);
        if (
          end.format(SERVER_TIME_FORMAT) === '23:59' &&
          end.format(SERVER_DAY_FORMAT) === momentDate.format(SERVER_DAY_FORMAT)
        ) {
          options.half_end = rosterTimeOff.options.half_end;
        }
        if (
          start.format(SERVER_TIME_FORMAT) === '00:00' &&
          start.format(SERVER_DAY_FORMAT) ===
            momentDate.format(SERVER_DAY_FORMAT)
        ) {
          options.half_start = rosterTimeOff.options.half_start;
        }
      }
      return {
        ...base,
        duration: 'all_day',
        options: options,
      };
    }

    return {
      ...base,
      duration: 'all_day',
      options: rosterTimeOff.options,
    };
  };

  const utcOffset = rosterTimeOff.start.utcOffset();
  const currentDayStart = moment
    .utc(day, SERVER_DAY_FORMAT)
    .utcOffset(utcOffset, true);
  const currentDayEnd = currentDayStart.clone().endOf('day');

  const dayTimeOffStart = rosterTimeOff.start.isBefore(currentDayStart)
    ? currentDayStart.clone()
    : rosterTimeOff.start.clone();
  const dayTimeOffEnd = rosterTimeOff.end.isAfter(currentDayEnd)
    ? currentDayEnd.clone()
    : rosterTimeOff.end.clone();

  if (
    dayTimeOffStart.isSame(currentDayStart, 'minute') &&
    dayTimeOffEnd.isSame(currentDayEnd, 'minute')
  ) {
    return getAllDay();
  }

  return {
    ...base,
    duration: 'few_hours',
    start_time: dayTimeOffStart,
    end_time: dayTimeOffEnd,
  };
};
