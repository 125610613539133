import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ListItem,
  ListItemText,
  MenuItem,
  MenuListProps as MuiMenuListProps,
  Typography,
} from 'extended-oxygen-elements';
import { createStyles, makeStyles } from '@mui/styles';
import {
  Avatar,
  Button,
  IconButton,
  ListItemIcon,
  MenuDivider,
  Menu,
  Hidden,
  HiddenPropsBreakpoint,
  Visible,
} from 'extended-oxygen-elements';
import { btnLabelLogout } from 'messages';
import { bindMenu, bindTrigger, usePopupState } from 'hooks';
import { BOX_AUTH_LOGOUT_REQUEST, currentUserSelector } from 'state/Auth';
import {
  ExpandMoreIcon,
  LogOutIcon,
} from 'element';

type Props = {
  desktopBreakpoint: HiddenPropsBreakpoint;
};

interface MenuListProps extends Partial<MuiMenuListProps> {
  'data-testid': string;
}

// Mui Button left medium icon default size
const iconSize = 20;

const useStyles = makeStyles((theme) => {
  const { small: avatarSize } = theme.componentsConfig.avatar.size;
  const avatarIconDiff = (iconSize - avatarSize) / 2;

  return createStyles({
    button: {},
    buttonContent: {
      display: 'flex',
      alignItems: 'center',
    },
    buttonAvatar: {
      margin: `${avatarIconDiff}px 8px ${avatarIconDiff}px -4px`,
    },
    menuList: {
      minWidth: 250,
    },
    details: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    detailsAvatar: {
      marginBottom: theme.spacing(1),
    },
  });
});

function UserMenu({ desktopBreakpoint }: Props) {
  const classes = useStyles();
  const popupState = usePopupState();
  const dispatch = useDispatch();
  const {
    prefered_or_full_name,
    avatar: { src },
  } = useSelector(currentUserSelector);

  const handleLogoutMenuItemClick = () => {
    dispatch(BOX_AUTH_LOGOUT_REQUEST());
    popupState.close();
  };

  return (
    <>
      <Hidden up={desktopBreakpoint}>
        <IconButton
          {...bindTrigger(popupState)}
          color="primary"
          data-testid="rosterHeader_userMenu_iconButton"
        >
          <Avatar size="large" alt={prefered_or_full_name} src={src} />
        </IconButton>
      </Hidden>

      <Visible up={desktopBreakpoint}>
        <Button
          {...bindTrigger(popupState)}
          className={classes.button}
          size="medium"
          color="grey"
          endIcon={<ExpandMoreIcon />}
          data-testid="rosterHeader_userMenu_button"
        >
          <span className={classes.buttonContent}>
            <Avatar
              className={classes.buttonAvatar}
              component="span"
              alt={prefered_or_full_name}
              src={src}
            />

            <span>{prefered_or_full_name}</span>
          </span>
        </Button>
      </Visible>

      <Menu
        {...bindMenu(popupState)}
        classes={{
          list: classes.menuList,
        }}
        placement="bottom-end"
        MenuListProps={
          {
            'data-testid': 'rosterHeader_userMenu_menu',
          } as MenuListProps
        }
      >
        <ListItem
          className={classes.details}
          data-testid="rosterHeader_userMenu_detailsItem"
        >
          <Avatar
            className={classes.detailsAvatar}
            alt={prefered_or_full_name}
            src={src}
            data-testid="rosterHeader_userMenu_detailsItem_avatar"
          />

          <Typography
            variant="h6"
            data-testid="rosterHeader_userMenu_detailsItem_name"
          >
            {prefered_or_full_name}
          </Typography>
        </ListItem>

        <MenuDivider />

        <MenuItem
          onClick={handleLogoutMenuItemClick}
          data-testid="rosterHeader_userMenu_logoutItem"
        >
          <ListItemIcon>
            <LogOutIcon data-testid="rosterHeader_userMenu_logoutItem_icon" />
          </ListItemIcon>

          <ListItemText
            primary={btnLabelLogout}
            data-testid="rosterHeader_userMenu_logoutItem_text"
          />
        </MenuItem>
      </Menu>
    </>
  );
}

export default UserMenu;
