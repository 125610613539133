import { FormControlSpacing } from 'element';
import React from 'react';
import { WithChildren } from 'type';
import Field from './Field';
import Label from './Label';

export default BreakRow;

export function BreakRow({ children }: WithChildren) {
  return (
    <FormControlSpacing
      alignItems="start"
      display={{ xs: 'block', sm: 'flex' }}
    >
      {children}
    </FormControlSpacing>
  );
}

BreakRow.Label = Label;
BreakRow.Field = Field;
