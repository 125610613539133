import React from 'react';
import ToggleContext from './ToggleContext';
import { ToggleOnProps } from '../type';

function ToggleOn({ children }: ToggleOnProps) {
  return (
    <ToggleContext.Consumer>
      {({ isOn }: any) => (isOn ? children : null)}
    </ToggleContext.Consumer>
  );
}

export default ToggleOn;
