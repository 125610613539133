import { ReactNode } from 'react';
import { FeatureFlag } from 'element/feature-flags-components';
import SideNavMenu from '../SideNavMenu';
import HeaderMenu from '../HeaderMenu';
import { LayoutContextProvider } from '../../../../element/Layout/layout-context';
import LegacyContextProvider from '../../../../element/Layout/LegacyContextProvider';
import Main from '../Main';
import './new-navigation-layout.scss';

type NewNavigationLayoutProps = {
  children?: ReactNode;
};

export default function NewNavigationLayout({
  children,
}: NewNavigationLayoutProps) {
  return (
    <LegacyContextProvider>
      <LayoutContextProvider>
        <div className="new-navigation">
          <HeaderMenu />
          <SideNavMenu />
          <Main>{children}</Main>
        </div>
        {/* <HelpMenuItemConfirmationDialog />
          <ActivateTimeClockMenuItemConfirmationDialog /> */}
      </LayoutContextProvider>
    </LegacyContextProvider>
  );
}
