import React, { useState } from 'react';
import { Box } from 'oxygen-elements';
import BreakRow from './BreakRow';
import {
  CheckIcon,
  DurationField,
  DurationHoursRequiredField,
} from '../../../element';
import InlineInputWrapper from './InlineInputWrapper';
import { IconButton } from 'extended-oxygen-elements';
import { Minutes } from 'type';

type Props = {
  pushBreak: (BreakItem: any) => void;
  allBreaks: any[];
};

export const CreateDefaultBreakRule = ({ pushBreak, allBreaks }: Props) => {
  const [shiftLength, setShiftLength] = useState(2);
  const [breakDuration, setBreakDuration] = useState(30);
  const [showError, setShowError] = useState(false);

  const handleChangeBreakDuration = (value: Minutes | null) => {
    if (value !== null) {
      setBreakDuration(value);
    }
  };

  const handlePushBreak = () => {
    const hasTheSameShiftDuration = allBreaks.filter(
      (b) => b.shift_duration === shiftLength * 60
    );
    if (
      shiftLength === 0 ||
      shiftLength > 24 ||
      hasTheSameShiftDuration.length
    ) {
      setShowError(true);
    } else {
      pushBreak({
        shift_duration: shiftLength * 60,
        break_duration: breakDuration,
      });
    }
  };

  return (
    <Box
      paddingTop={2}
      paddingBottom={2}
      sx={{
        position: 'relative',
        borderTop: '1px solid #ccc',
        marginBottom: '10px',
        marginTop: '25px',
      }}
    >
      <BreakRow>
        <BreakRow.Label id="create-break-label">Shifts up to </BreakRow.Label>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <InlineInputWrapper>
            <DurationHoursRequiredField
              inputProps={{
                size: 6,
              }}
              margin="none"
              fullWidth={false}
              formatUnit="hours"
              defaultUnit="hours"
              hoursPrefix="hrs"
              value={shiftLength}
              onInput={(e) => setShowError(false)}
              onValueChange={(value) => {
                setShiftLength(value);
              }}
              error={showError}
            />
          </InlineInputWrapper>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              position: 'relative',
              marginLeft: '10px',
              width: '100%',
              '& > div': {
                width: '100%',
              },
              '@media (min-width: 600px)': {
                maxWidth: '240px',
                '& > div': {
                  width: '246px',
                },
              },
            }}
          >
            <BreakRow.Field label={'Break duration'}>
              <DurationField
                sx={{
                  width: '100%',
                  '@media (min-width: 600px)': {
                    maxWidth: '200px',
                    width: 'auto',
                  },
                }}
                margin="none"
                value={breakDuration}
                onValueChange={handleChangeBreakDuration}
              />
            </BreakRow.Field>
            <IconButton
              sx={{
                marginLeft: '10px',
                marginTop: '3px',
                position: 'absolute',
              }}
              color={'success'}
              onClick={handlePushBreak}
            >
              <CheckIcon />
            </IconButton>
          </Box>
        </Box>
      </BreakRow>
    </Box>
  );
};
