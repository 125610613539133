import React from 'react';
import { Merge } from 'ts-essentials';
import { Hours } from 'type';
import DurationHoursField, {
  DurationHoursFieldProps,
} from './DurationHoursField';

export type DurationHoursRequiredFieldProps = Merge<
  DurationHoursFieldProps,
  { onValueChange: (value: Hours) => void }
>;

export default DurationHoursRequiredField;

export function DurationHoursRequiredField({
  onValueChange,
  ...durationHoursFieldProps
}: DurationHoursRequiredFieldProps) {
  const handleValueChange: DurationHoursFieldProps['onValueChange'] = (
    hours
  ) => {
    if (hours !== null) {
      onValueChange(hours);
    }
  };

  return (
    <DurationHoursField
      {...durationHoursFieldProps}
      onValueChange={handleValueChange}
    />
  );
}
