import { SagaIterator } from 'redux-saga';
import { put, select } from 'redux-saga/effects';
import { eventRowsWithAdditionalRowsSelector, getEventsAdditionalRows } from '../selectors';
import { BOX_ROSTER_DAY_VIEW_EVENTS_ROW_SET_QUANTITY } from '../actions';
import { MIN_EVENTS_ROWS_QUANTITY } from '../constants';
import { calculateAdditionalRowsQuantity } from './helpers';

export const eventsAddRow = function* (): SagaIterator {
  const eventRows: ReturnType<typeof eventRowsWithAdditionalRowsSelector> = yield select(
    eventRowsWithAdditionalRowsSelector
  );
  const additionalRowsQuantity: ReturnType<typeof getEventsAdditionalRows> = yield select(
    getEventsAdditionalRows
  );

  const additionalRows = calculateAdditionalRowsQuantity({
    currentRowsQuantity: eventRows.length,
    additionalRowsQuantity,
    minRowsQuantity: MIN_EVENTS_ROWS_QUANTITY
  });

  yield put(
    BOX_ROSTER_DAY_VIEW_EVENTS_ROW_SET_QUANTITY({
      additionalRows
    })
  );
};
