import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LoadingOverlay } from 'elmo-elements';
import { StringMap, TimeOffsWithPagerSelector, UserFields } from 'type';
import { StoreState } from 'state/types';
import { userListSelector } from 'state/UsersCollection';
import {
  getIsUpdating,
  timeOffsWithPagerSelector
} from 'state/ManagerDashboard/Unavailability';
import { EmptyMessage, UnavailabilityCard } from './components';

type OwnProps = {};

type StateProps = {
  pager: TimeOffsWithPagerSelector;
  userList: StringMap<UserFields>;
  isUpdating: boolean;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

class UnavailabilityListComponent extends Component<Props> {
  render() {
    let {
      pager: { total, page },
      isUpdating
    } = this.props;
    return total ? (
      <LoadingOverlay isLoading={isUpdating}>
        {page.map(timeOff => {
          const { userList } = this.props;
          const user: UserFields = userList[timeOff.user_id];

          return (
            user ? <UnavailabilityCard
              key={timeOff.id}
              timeOff={timeOff}
              user={user}
            /> : null
          );
        })}
      </LoadingOverlay>
    ) : (
      <EmptyMessage />
    );
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  pager: timeOffsWithPagerSelector(state),
  userList: userListSelector(state),
  isUpdating: getIsUpdating(state)
});

const mapDispatchToProps: DispatchProps = {};

export const UnavailabilityList = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnavailabilityListComponent);
