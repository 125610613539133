import React from 'react';
import { MessageBlock } from 'elmo-elements';
import { isAppMarket } from '../../../../../../../../helpers';

const EmptyMessageComponent = () => (
  <MessageBlock>
    <MessageBlock.Header>
      No shifts have been {isAppMarket('uk') ? 'scheduled' : 'rostered'}.
    </MessageBlock.Header>
  </MessageBlock>
);

export const EmptyMessage = EmptyMessageComponent;
