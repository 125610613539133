import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Api, TimeOffUpdateNotes } from 'lib/Api';
import {
  BOX_TIME_OFFS_GET_LIST_SUCCESS,
  BOX_TIME_OFFS_UPDATE_LEAVE_NOTES,
  BOX_TIME_OFFS_UPDATE_LEAVE_NOTES_FAILURE, BOX_TIME_OFFS_UPDATE_LEAVE_NOTES_SUCCESS
} from './actions';
import { RostersDataRequest, TimeOffGetListResponse } from 'lib/Api/type';
import { processApiRequest, processApiRequestWithConfirm } from '../ProcessApiRequest/sagas';
import { formatError } from '../helpers';
import { SagaAction } from '../../type';

export const getTimeOffsListRequest = function* (
  payload: RostersDataRequest
): SagaIterator {
  const timeOffs: TimeOffGetListResponse = yield call(
    processApiRequest,
    Api.Timeoffs.getList,
    payload
  );

  yield put(
    BOX_TIME_OFFS_GET_LIST_SUCCESS({
      timeOffs,
      isUpdating: false,
      errors: [],
      ...payload,
    })
  );
};

export const updateLeaveNotes = function * ({
  payload
}: SagaAction<TimeOffUpdateNotes>): SagaIterator {
  try {
    const response = yield call(processApiRequestWithConfirm, Api.Timeoffs.notes, payload);
    yield put(BOX_TIME_OFFS_UPDATE_LEAVE_NOTES_SUCCESS(response));
  } catch (error) {
    yield put(BOX_TIME_OFFS_UPDATE_LEAVE_NOTES_FAILURE(formatError(error)));
  }
}

export const watchTimeOffs = function*(): SagaIterator {
  yield takeLatest(BOX_TIME_OFFS_UPDATE_LEAVE_NOTES, updateLeaveNotes);
};