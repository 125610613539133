import { NewHeader, NewHeaderTitle, NewHeaderWrapper } from 'element';
import React from 'react';
import { WithChildren } from 'type';
import SettingsHeaderBackBtn from '../SettingsHeaderBackBtn';

export default SettingsHeader;

export function SettingsHeader({ children }: WithChildren) {
  return (
    <NewHeaderWrapper>
      <NewHeader>
        <SettingsHeaderBackBtn />

        <NewHeaderTitle>{children}</NewHeaderTitle>
      </NewHeader>
    </NewHeaderWrapper>
  );
}
