import { SagaIterator } from 'redux-saga';
import { CallEffect } from '@redux-saga/core/effects';
import { call, put, race, take } from 'redux-saga/effects';
import { ApiClientResponse, ApiReturnType, Parameter } from 'type';
import { OVERLAP_ERROR_STATUS } from 'lib/Api';
import { replaceErrorText } from 'lib/helpers';
import { formatError, getErrorStatus } from 'state/helpers';
import * as isLoadingModule from 'state/IsLoading';
import { BOX_WARNINGS_OPEN_MODAL } from 'state/ErrorsProcessing/Warnings';
import {
  BOX_CONFIRMATION_CLOSE_MODAL,
  BOX_CONFIRMATION_CONFIRM_MODAL_REQUEST,
  BOX_CONFIRMATION_CONFIRM_MODAL_SUCCESS,
  BOX_CONFIRMATION_OPEN_MODAL,
} from 'state/ErrorsProcessing/Confirmation';

type ApiFn = (...args: any[]) => Promise<ApiClientResponse<any>>;

export const processApiRequest = function* <Fn extends ApiFn>(
  api: Fn,
  ...payload: Parameters<Fn>
): SagaIterator {
  const { data, warnings }: ApiClientResponse<any> = yield call(
    api,
    ...payload
  );

  if (warnings.length) {
    yield put(BOX_WARNINGS_OPEN_MODAL(warnings));
  }

  return data;
};

export const apiCall = <Fn extends ApiFn>(
  api: Fn,
  ...payload: Parameters<Fn>
): CallEffect<ApiReturnType<Fn>> => call(processApiRequest, api, ...payload);

export const processApiRequestWithConfirm = function* <Fn extends ApiFn>(
  api: Fn,
  payload: Parameter<Fn>
) {
  try {
    return yield call(processApiRequest, api, payload);
  } catch (error) {
    const errorStatus = getErrorStatus(error);

    if (errorStatus !== OVERLAP_ERROR_STATUS) {
      throw error;
    }

    // TODO: update formatting
    const messagesFromError = formatError(error)[0].split('\n');
    const messages = replaceErrorText(messagesFromError);

    yield put(
      BOX_CONFIRMATION_OPEN_MODAL({
        messages,
      })
    );

    const { confirmed } = yield race({
      closed: take(BOX_CONFIRMATION_CLOSE_MODAL),
      confirmed: take(BOX_CONFIRMATION_CONFIRM_MODAL_REQUEST),
    });

    if (!confirmed) {
      return;
    }

    yield put(
      isLoadingModule.BOX_IS_LOADING_ON(isLoadingModule.CONFIRMATION_MODAL)
    );

    let response;
    try {
      response = yield call(processApiRequest, api, {
        ...payload,
        ignore_errors: true,
      });
    } catch (error) {
      throw error;
    } finally {
      yield put(
        isLoadingModule.BOX_IS_LOADING_OFF(isLoadingModule.CONFIRMATION_MODAL)
      );
      yield put(BOX_CONFIRMATION_CONFIRM_MODAL_SUCCESS());
    }

    return response;
  }
};

export const apiWithConfirmCall = <Fn extends ApiFn>(
  api: Fn,
  payload: Parameter<Fn>
): CallEffect<ApiReturnType<Fn> | undefined> =>
  call(processApiRequestWithConfirm, api, payload);
