import { createAction } from 'lib/store-utils';
import { DialogHandler } from 'state/Dialog';

export const BOX_MAIN_MENU_HELP_ITEM_CONFIRMATION_OPENED = createAction(
  'Main menu help item / confirmation / opened'
);
export const BOX_MAIN_MENU_HELP_ITEM_CONFIRMATION_CLOSED = createAction(
  'Main menu help item / confirmation / closed'
);

export const getIsConfirmationOpen = DialogHandler(
  'MainMenuHelpItem / confirmation'
)
  .open(BOX_MAIN_MENU_HELP_ITEM_CONFIRMATION_OPENED)
  .close(BOX_MAIN_MENU_HELP_ITEM_CONFIRMATION_CLOSED)
  .makeSelector();

export const BOX_MAIN_MENU_TIMECLOCK_ITEM_CONFIRMATION_OPENED = createAction(
  'Main menu timeclock item / confirmation / opened'
);
export const BOX_MAIN_MENU_TIMECLOCK_ITEM_CONFIRMATION_CLOSED = createAction(
  'Main menu timeclock item / confirmation / closed'
);

export const getIsTimeClockConfirmationOpen = DialogHandler(
  'MainMenuActivateTimeClockItem / confirmation'
)
  .open(BOX_MAIN_MENU_TIMECLOCK_ITEM_CONFIRMATION_OPENED)
  .close(BOX_MAIN_MENU_TIMECLOCK_ITEM_CONFIRMATION_CLOSED)
  .makeSelector();
