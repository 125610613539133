import { orderBy } from 'lodash';
import moment from 'moment-timezone';
import { Collection, EmployeeSettingsSorting, ShiftBreak, UserFields } from 'type';

export const sortUserBy = (
  usersIds: string[],
  users: Collection<string, UserFields>,
  order: EmployeeSettingsSorting
) => {
  const u: UserFields[] = [];
  const ids: string[] = [];
  usersIds.forEach((id: string) => {
    if (users[id]) {
      u.push(users[id]);
    }
  });

  const ordered =
    order === 'alphabetical'
      ? orderBy(u, ['prefered_or_full_name'], ['asc'])
      : orderBy(u, ['rating'], ['asc']);

  ordered.forEach((p: UserFields) => {
    ids.push(p.id);
  });

  return ids;
};

const shiftBreaksDate = (toLeft: boolean, breaks: ShiftBreak[], day: number) => {
  return breaks.map((shiftBreak: any) => {
    return {
      ...shiftBreak,
      start: toLeft
        ? shiftBreak.start.clone().subtract(day, 'days')
        : shiftBreak.start.clone().add(day, 'days')
    }
  })
};

export const fillShifts = (data: any, cellsArray: any, isPrev: any) => {
  const shiftsToCopy: any = [];
  const { start, end, breaks } = data;
  const keys: any[] = [];

  Object.keys(cellsArray).forEach(k => {
    if (cellsArray[k] || cellsArray[k] === 0) {
      keys.push(cellsArray[k]);
    }
  });

  for (let i = 1; i <= keys.length; i++) {
    shiftsToCopy.push({
      start: isPrev ? moment(start).subtract(i, 'days') : moment(start).add(i, 'days'),
      end: isPrev ? moment(end).subtract(i, 'days') : moment(end).add(i, 'days'),
      event_id: data.event_id,
      user_id: data.user_id,
      role_id: data.role_id,
      area_id: data.area_id,
      tags: data.tags,
      template_id: null,
      breaks: shiftBreaksDate(!!isPrev, breaks, i)
    });
  }

  return shiftsToCopy;
};
