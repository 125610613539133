import { RosterCopyPastReducerState } from './type';

export const getDefaultState = (): RosterCopyPastReducerState => ({
  site_id: null,
  modal: {
    isOpened: false
  },
  createRosterTemplateModal: {
    isOpened: false,
    errors: [],
    isUpdating: false
  },
  jobs: [],
  templatesModal: {
    isOpened: false,
    errors: [],
    isLoading: false,
    isUpdating: false,
    templates: []
  }
});
