import React from 'react';
import { RosterViewScrollableWrapper } from '../../../../components';
import { WeekNavigation } from '../../components';
import EditView from './view';

const EditTemplateSiteView = () => (
  <RosterViewScrollableWrapper header={<WeekNavigation/>}>
    <EditView/>
  </RosterViewScrollableWrapper>
);

export default EditTemplateSiteView;
