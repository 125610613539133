import { createReducer } from 'lib/store-utils';
import { set as fpSet } from 'lodash/fp';
import { BOX_EVENT_MODAL_CREATE_EVENT_SUCCESS } from '../EventModal';
import { getDefaultState } from './state';
import { RosterDayViewReducerState } from './types';
import * as actions from './actions';

export const rosterDayView = createReducer<RosterDayViewReducerState>(
  {},
  getDefaultState()
);

rosterDayView.on(
  actions.BOX_ROSTER_DAY_VIEW_CREATE_SHIFT_SET_DATA,
  (state, partialData): RosterDayViewReducerState => ({
    ...state,
    createdShiftData: {
      ...state.createdShiftData,
      ...partialData,
    },
  })
);

rosterDayView.on(
  actions.BOX_ROSTER_DAY_VIEW_SITE_VIEW_ROW_SET,
  (state, { area_id, role_id, rowsQuantity }): RosterDayViewReducerState => ({
    ...state,
    siteViewAdditionalRows: fpSet(
      [area_id, role_id],
      rowsQuantity,
      state.siteViewAdditionalRows
    ),
  })
);

rosterDayView.on(
  actions.BOX_ROSTER_DAY_VIEW_SITE_VIEW_ROW_CLEAR,
  (state): RosterDayViewReducerState => ({
    ...state,
    siteViewAdditionalRows: {},
  })
);

rosterDayView.on(
  actions.BOX_ROSTER_DAY_VIEW_ERROR_MODAL_OPEN,
  (state, errors): RosterDayViewReducerState => ({
    ...state,
    errorModal: {
      ...state.errorModal,
      isOpened: true,
      errors,
    },
  })
);

rosterDayView.on(
  actions.BOX_ROSTER_DAY_VIEW_ERROR_MODAL_CLOSE,
  (state): RosterDayViewReducerState => ({
    ...state,
    errorModal: {
      ...state.errorModal,
      isOpened: false,
    },
  })
);

rosterDayView.on(
  actions.BOX_ROSTER_DAY_VIEW_CONFIRM_FORCE_MODAL_OPEN,
  (state, { errors }): RosterDayViewReducerState => ({
    ...state,
    confirmModal: {
      ...state.confirmModal,
      isOpened: true,
      errors,
    },
  })
);

rosterDayView.on(
  actions.BOX_ROSTER_DAY_VIEW_CONFIRM_FORCE_MODAL_CLOSE,
  (state): RosterDayViewReducerState => ({
    ...state,
    confirmModal: {
      ...state.confirmModal,
      isOpened: false,
    },
  })
);

rosterDayView.on(
  actions.BOX_ROSTER_DAY_VIEW_EVENTS_ROW_SET_QUANTITY,
  (state, { additionalRows }): RosterDayViewReducerState => ({
    ...state,
    eventsAdditionalRows: additionalRows,
  })
);

const handlerClearEventRows = (
  state: RosterDayViewReducerState
): RosterDayViewReducerState => ({
  ...state,
  eventsAdditionalRows: 0,
});

rosterDayView.on(
  actions.BOX_ROSTER_DAY_VIEW_EVENTS_ROW_CLEAR,
  handlerClearEventRows
);
rosterDayView.on(BOX_EVENT_MODAL_CREATE_EVENT_SUCCESS, handlerClearEventRows);

rosterDayView.on(
  actions.BOX_ROSTER_DAY_VIEW_UNASSIGNED_ROW_SET_QUANTITY,
  (state, { additionalRows }): RosterDayViewReducerState => ({
    ...state,
    unassignedAdditionalRows: additionalRows,
  })
);

rosterDayView.on(
  actions.BOX_ROSTER_DAY_VIEW_UNASSIGNED_ROW_CLEAR,
  (state): RosterDayViewReducerState => ({
    ...state,
    unassignedAdditionalRows: 0,
  })
);

rosterDayView.on(
  actions.BOX_ROSTER_DAY_VIEW_DRAG_AND_DROP_STARTED,
  (state, payload) => ({
    ...state,
    dragAndDrop: {
      ...state.dragAndDrop,
      ...payload,
    },
  })
);
