import { Moment } from 'moment';
import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { ApiReturnType, Parameter } from 'type';
import { SERVER_DATE_TIME_FORMAT } from 'lib/config';
import Api from 'lib/Api';
import { hasPermissionSelector } from 'state/Auth';
import { processApiRequest } from 'state/ProcessApiRequest';

export const fetchTimesheetListWithPayEntities = function* (payload: {
  site_id: string;
  from: Moment;
  to: Moment;
}): SagaIterator<
  ApiReturnType<typeof Api.Timesheet.getListResponseWithPayEntries>
  > {
  const canViewTimesheets: boolean = yield select(
    hasPermissionSelector,
    'roster.timesheet.view'
  );

  if (!canViewTimesheets) {
    return {};
  }

  const apiPayload: Parameter<
    typeof Api.Timesheet.getListResponseWithPayEntries
    > = {
    site_id: payload.site_id,
    from: payload.from.format(SERVER_DATE_TIME_FORMAT),
    to: payload.to.format(SERVER_DATE_TIME_FORMAT),
  };

  return yield call(
    processApiRequest,
    Api.Timesheet.getListResponseWithPayEntries,
    apiPayload
  );
};
