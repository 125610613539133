import React from 'react';
import { MoreHorizIcon as ActionsIcon } from '../../icons';
import DataTableTd from './DataTableTd';
import { Dropdown } from '../../Dropdown';
import { DataTableAction, DataTableDropdownDivider } from '../type';
import './DataTableActions.scss';

type DataTableActionsProps = {
  /** The array of actions / divider to show in the Dropdown */
  actions?: (DataTableAction | DataTableDropdownDivider)[];
  /** Style parameter. Display the menu using position fixed or position absolute */
  isInline?: boolean;
};

/**
 * Wraps Dropdown to render the actions available for each row in the DataTable
 * @constructor
 */
function DataTableActions({ actions, isInline }: DataTableActionsProps) {
  if (!actions) {
    return null;
  }

  return (
    <DataTableTd className="elmo-datatable__row-actions">
      <Dropdown
        icon={<ActionsIcon />}
        isInline={isInline}
        position={'bottom-end'}
        ariaLabel="Actions"
      >
        {actions &&
          actions.map(
            (
              action: DataTableAction | DataTableDropdownDivider,
              index: number
            ) => {
              if ((action as DataTableDropdownDivider).isDivider) {
                return <Dropdown.Divider key={index} />;
              }
              const dropdownAction = action as DataTableAction;
              return (
                <Dropdown.Item
                  key={index}
                  ariaLabel={dropdownAction.ariaLabel}
                  onClick={dropdownAction.onClick}
                  value={index}
                  isDisabled={dropdownAction.isDisabled}
                >
                  {dropdownAction.icon} {dropdownAction.label}
                </Dropdown.Item>
              );
            }
          )}
      </Dropdown>
    </DataTableTd>
  );
}

export default DataTableActions;
