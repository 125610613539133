import { CloseIcon, WarningAmberOutlinedIcon } from 'element';
import {
  Avatar,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from 'extended-oxygen-elements';
import { useActions, useSelectorWithProps } from 'hooks';
import { capitalize } from 'lib/helpers';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLangPreferences } from 'state/Account';
import {
  BOX_ACCOUNT_TREE_UPDATE_SITE_STATUS,
  getSite,
} from 'state/AccountTree';
import {
  PageDialog,
  PageDialogCancel,
  PageDialogErrors,
  PageDialogSubmit,
  usePageDialogStatusEffect,
} from '../../../../../element/page-dialog-components';

type Props = {
  site_id: string;
  open: boolean;
  closeDialog: () => void;
};

export default function DialogUpdateSiteStatus({
  closeDialog,
  site_id,
  open,
}: Props) {
  const updateSiteStatusRequest = useActions(
    BOX_ACCOUNT_TREE_UPDATE_SITE_STATUS.request
  );

  const langPreferences = useSelector(getLangPreferences);
  const { name, archived } = useSelectorWithProps(getSite, site_id);

  const action = archived ? 'unarchive' : 'archive';
  const title = `${capitalize(action)} ${langPreferences.site.singular}`;

  usePageDialogStatusEffect({
    success: closeDialog,
  });

  const handleSubmit = () => {
    updateSiteStatusRequest({
      site_id,
      archived: !archived,
    });
  };

  return (
    <PageDialog open={open} maxWidth="xs">
      <DialogTitle
        endAdornment={
          <IconButton onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        }
      >
        <Avatar size="large" color="warning" light aria-label={title}>
          <WarningAmberOutlinedIcon />
        </Avatar>
      </DialogTitle>
      <DialogContent>
        <PageDialogErrors />
        <Typography variant="h5" gutterBottom>
          {title}?
        </Typography>
        <Typography>
          This will {action} {langPreferences.site.singular} {name}, please
          confirm that you want to {action} this {langPreferences.site.singular}
          .
        </Typography>
      </DialogContent>
      <DialogActions>
        <PageDialogCancel
          variant="outlined"
          size="medium"
          onClick={closeDialog}
          fullWidth
        >
          Cancel
        </PageDialogCancel>
        <PageDialogSubmit fullWidth variant="contained" onClick={handleSubmit}>
          {capitalize(action)}
        </PageDialogSubmit>
      </DialogActions>
    </PageDialog>
  );
}
