import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Tooltip } from 'extended-oxygen-elements';
import { IconButton } from 'extended-oxygen-elements';
import { BOX_TIMESHEETS_WEEKLY_TIMESHEET_UNAPPROVE } from 'state/TimesheetsWeeklyPage';
import { CancelOutlinedIcon } from 'element';
import { ActionButtonProps } from './types';

export const UnapproveButton = ({
  closeActionsPopover,
  timesheetId,
}: ActionButtonProps) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(BOX_TIMESHEETS_WEEKLY_TIMESHEET_UNAPPROVE.request({ timesheetId }));
    closeActionsPopover();
  };

  return (
    <Tooltip title="Unapprove timesheet">
      <Box component="span" color="warning.main">
        <IconButton
          color="inherit"
          onClick={handleClick}
          data-testid="hover-action-unapprove"
        >
          <CancelOutlinedIcon />
        </IconButton>
      </Box>
    </Tooltip>
  );
};
