import { SagaIterator } from 'redux-saga';
import {
  DeleteInstancePayload,
  SagaAction,
  ShiftOffer,
  ShiftOfferWithProposals,
} from 'type';
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { Api } from 'lib/Api';
import browserHistory from 'lib/browserHistory';
import { privateRoutes } from 'routes';
import * as fetchActions from 'state/FetchPageData/actions';
import * as loadingActions from 'state/IsLoading/actions';
import { getRotaUserListRequest } from 'state/UsersCollection';
import {
  BOX_TOAST_NOTIFIER_CLOSE,
  BOX_TOAST_NOTIFIER_MESSAGE_SHOW,
  showOutDatedNotification,
  showUndoNotification,
} from 'state/ToastNotifier';
import {
  BOX_SHIFT_OFFERS_UNDO_ADD_TO_APPROVE_PROPOSAL_QUEUE,
  BOX_SHIFT_OFFERS_UNDO_ADD_TO_DECLINE_PROPOSAL_QUEUE,
  BOX_SHIFT_OFFERS_UNDO_ADD_TO_DELETE_QUEUE,
  BOX_SHIFT_OFFERS_UNDO_REMOVE_FROM_APPROVE_PROPOSAL_QUEUE,
  BOX_SHIFT_OFFERS_UNDO_REMOVE_FROM_DECLINE_PROPOSAL_QUEUE,
  BOX_SHIFT_OFFERS_UNDO_REMOVE_FROM_DELETE_QUEUE,
  getApproveQueue,
  getDeleteQueue,
} from '../../ShiftOffersUndo';
import { BOX_SHIFT_OFFERS_DELETE_OFFER_FROM_LIST } from '../../ShiftOffers';
import {
  BOX_SHIFT_OFFER_APPROVE_SHIFT_OFFER_PROPOSAL,
  BOX_SHIFT_OFFER_CLEAR_STATE,
  BOX_SHIFT_OFFER_DECLINE_SHIFT_OFFER_PROPOSAL,
  BOX_SHIFT_OFFER_DECLINE_SHIFT_OFFER_PROPOSAL_SUCCESS,
  BOX_SHIFT_OFFER_DELETE_SHIFT_OFFER,
  BOX_SHIFT_OFFER_GET_PAGE_DATA_REQUEST,
  BOX_SHIFT_OFFER_SET_DEFAULT_STATE,
  BOX_SHIFT_OFFER_SET_ERRORS,
  BOX_SHIFT_OFFER_SET_OUTDATED_STATE,
  BOX_SHIFT_OFFER_UPDATE_SHIFT_OFFER,
} from '../actions';
import {
  getIsOutdated,
  getShiftOfferWithProposals,
  getShiftOfferWithProposalsId,
} from '../selectors';
import {
  ApproveOrDeclineShiftOfferPayload,
  GetPageDataPayload,
} from '../types';
import { processApiRequest } from 'state/ProcessApiRequest';
import {
  BOX_SHIFT_OFFERS_DELETE_ONE,
  BOX_SHIFT_OFFERS_UPDATE_ONE,
} from '../../actions';
import nanoid from 'nanoid';
import { deletedInstanceMessage, outDatedDataMessage } from 'messages';
import { formatError } from '../../../../helpers';

export const getShiftOfferRequest = function* (
  payload: GetPageDataPayload
): SagaIterator {
  const { shiftOfferId } = payload;
  const deleteQueue: string[] = yield select(getDeleteQueue);
  const approveQueue: string[] = yield select(getApproveQueue);

  if (
    deleteQueue.includes(shiftOfferId) ||
    approveQueue.includes(shiftOfferId)
  ) {
    throw new Error('Shift offer is in the delete/approve queue');
  }

  const response: ShiftOfferWithProposals = yield call(
    processApiRequest,
    Api.ManagerDashboard.getShiftOffer,
    payload
  );

  yield put(BOX_SHIFT_OFFER_UPDATE_SHIFT_OFFER(response));
};

const getPageData = function* ({
  payload,
}: SagaAction<GetPageDataPayload>): SagaIterator {
  yield put(BOX_SHIFT_OFFER_SET_DEFAULT_STATE());
  yield put(
    fetchActions.BOX_FETCH_PAGE_DATA_REQUEST('MANAGER_DASHBOARD_SHIFT_OFFER')
  );

  try {
    yield all([
      call(getShiftOfferRequest, payload),
      call(getRotaUserListRequest),
    ]);

    yield put(
      fetchActions.BOX_FETCH_PAGE_DATA_SUCCESS('MANAGER_DASHBOARD_SHIFT_OFFER')
    );
  } catch (error) {
    yield put(
      fetchActions.BOX_FETCH_PAGE_DATA_FAILURE('MANAGER_DASHBOARD_SHIFT_OFFER')
    );
  }
};

const deleteShiftOffer = function* (): SagaIterator {
  const { id: shiftOfferId }: ShiftOfferWithProposals = yield select(
    getShiftOfferWithProposals
  );

  const payload = { shiftOfferId };

  browserHistory.push(
    privateRoutes.managerDashboard.routes.shiftOffers.routes.shiftOffers.path
  );

  yield put(BOX_SHIFT_OFFERS_UNDO_ADD_TO_DELETE_QUEUE(payload));

  const { hide, undo } = yield call(
    showUndoNotification,
    'Shift offer deleted'
  );

  if (hide) {
    try {
      yield call(
        processApiRequest,
        Api.ManagerDashboard.deleteShiftOffer,
        payload
      );
      yield put(BOX_SHIFT_OFFERS_DELETE_OFFER_FROM_LIST(shiftOfferId));
    } catch (error) {
      yield put(
        BOX_TOAST_NOTIFIER_MESSAGE_SHOW({
          message: 'Shift offer delete error',
          type: 'danger',
        })
      );
    }
  }

  if (undo) {
    yield put(BOX_TOAST_NOTIFIER_MESSAGE_SHOW('Shift offer restored'));
  }

  yield put(BOX_SHIFT_OFFERS_UNDO_REMOVE_FROM_DELETE_QUEUE(payload));
};

const declineShiftOfferProposal = function* ({
  payload: { proposalId },
}: SagaAction<ApproveOrDeclineShiftOfferPayload>): SagaIterator {
  const { id: shiftOfferId }: ShiftOfferWithProposals = yield select(
    getShiftOfferWithProposals
  );

  const idsOfProposal = {
    proposalId,
    shiftOfferId,
  };

  yield put(BOX_SHIFT_OFFERS_UNDO_ADD_TO_DECLINE_PROPOSAL_QUEUE(idsOfProposal));

  const { hide, undo } = yield call(
    showUndoNotification,
    'Shift offer proposal declined'
  );

  if (hide) {
    try {
      yield call(
        processApiRequest,
        Api.ManagerDashboard.declineShiftOfferProposal,
        {
          shiftOfferId,
          proposal_id: proposalId,
        }
      );

      yield put(
        BOX_SHIFT_OFFER_DECLINE_SHIFT_OFFER_PROPOSAL_SUCCESS({
          proposalId,
        })
      );
    } catch (error) {
      yield put(
        BOX_TOAST_NOTIFIER_MESSAGE_SHOW({
          message: 'Shift offer decline proposal error',
          type: 'danger',
        })
      );
    }
  }

  if (undo) {
    yield put(BOX_TOAST_NOTIFIER_MESSAGE_SHOW('Decline proposal cancelled'));
  }

  yield put(
    BOX_SHIFT_OFFERS_UNDO_REMOVE_FROM_DECLINE_PROPOSAL_QUEUE(idsOfProposal)
  );
};

const approveShiftOfferProposal = function* ({
  payload: { proposalId },
}: SagaAction<ApproveOrDeclineShiftOfferPayload>): SagaIterator {
  const { id: shiftOfferId, shift_start }: ShiftOfferWithProposals =
    yield select(getShiftOfferWithProposals);

  browserHistory.push(
    privateRoutes.managerDashboard.routes.shiftOffers.routes.shiftOffers.path
  );

  yield put(
    BOX_SHIFT_OFFERS_UNDO_ADD_TO_APPROVE_PROPOSAL_QUEUE({ shiftOfferId })
  );

  const { hide, undo } = yield call(
    showUndoNotification,
    'Shift offer proposal approved'
  );

  if (hide) {
    try {
      yield call(
        processApiRequest,
        Api.ManagerDashboard.approveShiftOfferProposal,
        {
          shiftOfferId,
          proposal_id: proposalId,
        }
      );
      yield put(BOX_SHIFT_OFFERS_DELETE_OFFER_FROM_LIST(shiftOfferId));
    } catch (error) {
      yield put(
        BOX_TOAST_NOTIFIER_MESSAGE_SHOW({
          message: 'Shift offer approve proposal error',
          type: 'danger',
        })
      );
    }
  }

  if (undo) {
    yield put(BOX_TOAST_NOTIFIER_MESSAGE_SHOW('Approve proposal cancelled'));
  }

  yield put(
    BOX_SHIFT_OFFERS_UNDO_REMOVE_FROM_APPROVE_PROPOSAL_QUEUE({
      shiftOfferId,
    })
  );
};

const showOutdatedPageNotifier = function* ({
  payload: { id },
}: SagaAction<ShiftOffer>): SagaIterator {
  const currentShiftOfferId: ReturnType<typeof getShiftOfferWithProposalsId> =
    yield select(getShiftOfferWithProposalsId);

  if (currentShiftOfferId === id) {
    const notificationId = nanoid();

    yield put(
      BOX_SHIFT_OFFER_SET_OUTDATED_STATE({
        value: true,
        notificationId,
      })
    );

    const { undo } = yield call(showOutDatedNotification, {
      message: outDatedDataMessage,
      notificationId,
      undoText: 'Reload',
    });

    if (undo) {
      yield call(updateOutdatedData);
    }
  }
};

export function* updateOutdatedData(): SagaIterator {
  yield put(loadingActions.BOX_IS_LOADING_ON('MANAGER_DASHBOARD_SHIFT_OFFER'));
  yield put(BOX_SHIFT_OFFER_SET_ERRORS([]));

  try {
    const shiftOfferId: ReturnType<typeof getShiftOfferWithProposalsId> =
      yield select(getShiftOfferWithProposalsId);

    yield call(getShiftOfferRequest, {
      shiftOfferId,
    });
    yield put(
      BOX_SHIFT_OFFER_SET_OUTDATED_STATE({
        value: false,
        notificationId: '',
      })
    );
  } catch (error) {
    yield put(BOX_SHIFT_OFFER_SET_ERRORS(formatError(error)));
  }

  yield put(loadingActions.BOX_IS_LOADING_OFF('MANAGER_DASHBOARD_SHIFT_OFFER'));
}

const showOfferDeletedNotifier = function* ({
  payload: { id },
}: SagaAction<DeleteInstancePayload>): SagaIterator {
  const currentShiftOfferId: ReturnType<typeof getShiftOfferWithProposalsId> =
    yield select(getShiftOfferWithProposalsId);

  if (currentShiftOfferId === id) {
    const notificationId = nanoid();

    yield put(
      BOX_SHIFT_OFFER_SET_OUTDATED_STATE({
        value: true,
        notificationId,
      })
    );

    const { undo } = yield call(showOutDatedNotification, {
      message: deletedInstanceMessage({
        instance: 'shift offer',
        goToPage: 'shift offers',
      }),
      notificationId,
      undoText: 'Go to Shift offers',
    });

    if (undo) {
      browserHistory.push(
        privateRoutes.managerDashboard.routes.shiftOffers.routes.shiftOffers
          .path
      );
    }
  }
};

const clearShiftOfferState = function* (): SagaIterator {
  const { value, notificationId }: ReturnType<typeof getIsOutdated> =
    yield select(getIsOutdated);

  if (value) {
    // close notification if shown
    yield put(BOX_TOAST_NOTIFIER_CLOSE(notificationId));
  }

  yield put(BOX_SHIFT_OFFER_SET_DEFAULT_STATE());
};

export const watchShiftOffer = function* (): SagaIterator {
  yield takeLatest(BOX_SHIFT_OFFER_GET_PAGE_DATA_REQUEST, getPageData);
  yield takeEvery(BOX_SHIFT_OFFER_DELETE_SHIFT_OFFER, deleteShiftOffer);
  yield takeEvery(
    BOX_SHIFT_OFFER_DECLINE_SHIFT_OFFER_PROPOSAL,
    declineShiftOfferProposal
  );
  yield takeEvery(
    BOX_SHIFT_OFFER_APPROVE_SHIFT_OFFER_PROPOSAL,
    approveShiftOfferProposal
  );
  yield takeLatest(BOX_SHIFT_OFFERS_UPDATE_ONE, showOutdatedPageNotifier);
  yield takeLatest(BOX_SHIFT_OFFERS_DELETE_ONE, showOfferDeletedNotifier);
  yield takeLatest(BOX_SHIFT_OFFER_CLEAR_STATE, clearShiftOfferState);
};
