import { CURRENCY_SIGNS } from 'lib/config';
import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrencyCode, getCurrencyPlacement } from 'state/Account';
import FormattedNumber from './FormattedNumber';

type Props = {
  children: number;
};

export function FormattedCurrency({ children: currencyQuantity }: Props) {
  const currencyCode = useSelector(getCurrencyCode);
  const currencyPlacement = useSelector(getCurrencyPlacement);

  const sign: string = CURRENCY_SIGNS[currencyCode];

  return (
    <>
      {currencyPlacement === 'before' && sign}
      <FormattedNumber>{currencyQuantity}</FormattedNumber>
      {currencyPlacement === 'after' && sign}
    </>
  );
}
