import React, { useState } from 'react';
import { Box, Typography } from 'extended-oxygen-elements';
import { createStyles, makeStyles } from '@mui/styles';
import { Button } from 'extended-oxygen-elements';
import { AddOutlinedIcon } from '../icons';
import { NotesField } from '../form-fields/NotesField';
import { useDispatch, useSelector } from 'react-redux';
import { BOX_TIME_OFFS_UPDATE_LEAVE_NOTES } from 'state/TimeOffs/actions';
import { getTimeoffErrors, isTimeoffUpdating } from 'state/TimeOffs';
import { useUpdateEffect } from 'hooks';

const useFormStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: 350,
      padding: theme.spacing(3, 2, 2),
      [theme.breakpoints.up(480)]: {
        width: 400,
      },
    },
    scrollWrapper: {
      maxHeight: '235px',
      overflowY: 'auto',
    },
    title: {
      fontWeight: 500,
      fontSize: 24,
      lineHeight: '32px',
      letterSpacing: '-0.3px',
      padding: 0,
    },
    notes: {
      padding: 8,
      border: '1px solid #dde0e3',
      borderRadius: 6,
      margin: '24px 0 16px',
      backgroundColor: '#f4f5f6',
    },
    btns: {
      paddingTop: 10,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    cancelBtn: {
      marginRight: 10,
    },
  })
);

type Props = {
  notes: string | null;
  id: string;
  closeNotes: () => void;
  noFixedSize?: boolean;
  hasCloseButton?: boolean;
  label: string;
};

export const NotesText = ({
  notes,
  toggleEditing,
  showEditButton = true,
}: {
  notes: string | null;
  toggleEditing: (value: boolean) => void;
  showEditButton?: boolean;
}) => {
  const styles = useFormStyles();
  return (
    <>
      {notes && (
        <Box className={styles.notes} data-testid={'leave-notes-message'}>
          {notes}
        </Box>
      )}
      {showEditButton && (
        <Button
          startIcon={<AddOutlinedIcon />}
          data-testid={'toggle-leave-notes-button'}
          onClick={(e: React.MouseEvent) => toggleEditing(true)}
          role={'button'}
        >
          Edit note
        </Button>
      )}
    </>
  );
};

export const EditNotesForm = ({
  notes,
  id,
  closeNotes,
}: {
  notes: string | null;
  closeNotes: () => void;
  id: string;
}) => {
  const [leaveNotes, setNotes] = useState<string | null>(notes);
  const [errorMsg, setError] = useState<string>('');

  const isUpdating = useSelector(isTimeoffUpdating);
  const errors = useSelector(getTimeoffErrors);
  const styles = useFormStyles();
  const dispatch = useDispatch();

  const updateNotesField = () => {
    dispatch(
      BOX_TIME_OFFS_UPDATE_LEAVE_NOTES({
        notes: leaveNotes,
        id,
      })
    );
  };

  const changeNotesHandler = (value: string | null) => {
    setNotes(value);
  };

  const handleCancelClick = (e: React.MouseEvent) => {
    closeNotes();
  };

  useUpdateEffect(() => {
    if (!isUpdating && !errors.length) {
      closeNotes();
    }
    if (errors.length) {
      setError(errors.join(', '));
    }
  }, [isUpdating, errors]);

  return (
    <>
      <NotesField
        value={leaveNotes}
        onChange={changeNotesHandler}
        error={errorMsg}
      />
      <Box className={styles.btns}>
        <Button className={styles.cancelBtn} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button
          variant={'contained'}
          onClick={updateNotesField}
          loading={isUpdating}
          disabled={isUpdating}
        >
          Save
        </Button>
      </Box>
    </>
  );
};

export const NotesForm = ({ notes, id, closeNotes, label }: Props) => {
  const styles = useFormStyles();
  const [isEditing, toggleEditing] = useState(!notes);
  return (
    <Box className={styles.root} data-testid={'leave-notes-form'}>
      <Box className={styles.scrollWrapper}>
        <Typography variant="h3">{label} note</Typography>
        {!isEditing ? (
          <NotesText notes={notes} toggleEditing={toggleEditing} />
        ) : (
          <EditNotesForm closeNotes={closeNotes} notes={notes} id={id} />
        )}
      </Box>
    </Box>
  );
};
