import { useTimesheetsNewQuery } from 'hooks/route-query-hooks/page-query-hooks';

export default useTimesheetsNewQueryStatus;

export function useTimesheetsNewQueryStatus() {
  const {
    query: { status },
  } = useTimesheetsNewQuery();

  return status;
}
