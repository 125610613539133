import { StoreState } from '../../types';
import { usersSelectOptionsCombiner } from 'state/combiners';
import { createSelector } from 'reselect';
import { userListSelector } from '../../UsersCollection';

export const getApprovalsUserIds = (state: StoreState) =>
  state.userApprovals.approvers.userIds;
export const getApprovalsIsFetched = (state: StoreState) =>
  state.userApprovals.approvers.isFetched;
export const getApprovalsIsFetching = (state: StoreState) =>
  state.userApprovals.approvers.isFetching;
export const getApprovalsLeaveIds = (state: StoreState) =>
  state.userApprovals.approvers.approvers.leave;
export const getApprovalsSwapIds = (state: StoreState) =>
  state.userApprovals.approvers.approvers.swap;
export const getApprovalsOfferIds = (state: StoreState) =>
  state.userApprovals.approvers.approvers.offer;
export const getApprovalsErrors = (state: StoreState) =>
  state.userApprovals.approvers.errors;

export const leaveApproversSelectOptionsSelector = createSelector(
  getApprovalsLeaveIds,
  userListSelector,
  usersSelectOptionsCombiner
);

export const swapApproversSelectOptionsSelector = createSelector(
  getApprovalsSwapIds,
  userListSelector,
  usersSelectOptionsCombiner
);

export const offerApproversSelectOptionsSelector = createSelector(
  getApprovalsOfferIds,
  userListSelector,
  usersSelectOptionsCombiner
);
