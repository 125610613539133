import React, { useEffect } from 'react';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import { RosteredShift, StringMap, Timesheet } from 'type';
import { RosterTimeOffsByUserIdAndDate } from 'state/Roster/RosterWeekView/types';
import {
  groupPrintableRostersByUsersSelector,
  timeOffsByUserIdByDaySelector,
} from 'state/Roster/RosterWeekView';
import {
  rosteredShiftsFilteredSelector,
  timesheetsFilteredSelector,
} from 'state/Roster/RosterFilters';
import { getRosteredShifts } from 'state/RosteredShifts';
import { getTimesheets } from 'state/TimesheetsCollection';
import { getIsPrinting } from 'state/Roster/Print';
import { getWeekRage } from 'lib/helpers';
import moment from 'moment';
import qs from 'qs';
import { groupPrintableRostersByAreas } from 'state/Roster/RosterWeekView/selectors/siteViewSelectors/withRowsFiltering/groupRostersByAreas';

type StateProps = {
  timesheets: StringMap<Timesheet>;
  timeOffs: RosterTimeOffsByUserIdAndDate;
  filteredRosters: RosteredShift[];
  filteredTimesheets: Timesheet[];
  rosteredShifts: StringMap<RosteredShift>;
  groupedRosters: any;
  groupedRostersByUsers: any;
  isPrinting: boolean;
};

type OwnProps = {
  children: (payload: any) => any;
};

type Props = OwnProps & StateProps;

export function WeekView(props: Props) {
  const data = {
    timesheets: props.timesheets,
    timeOffs: props.timeOffs,
    filteredRosters: props.filteredRosters,
    filteredTimesheets: props.filteredTimesheets,
    rosteredShifts: props.rosteredShifts,
    groupedRosters: props.groupedRosters,
    groupedRostersByUsers: props.groupedRostersByUsers,
  };

  return props.children(data);
}

const mapStateToProps = (state: StoreState): StateProps => ({
  timeOffs: timeOffsByUserIdByDaySelector(state),
  filteredTimesheets: timesheetsFilteredSelector(state),
  filteredRosters: rosteredShiftsFilteredSelector(state),
  rosteredShifts: getRosteredShifts(state),
  timesheets: getTimesheets(state),
  groupedRosters: groupPrintableRostersByAreas(state),
  groupedRostersByUsers: groupPrintableRostersByUsersSelector(state),
  isPrinting: getIsPrinting(state),
});

export default connect(mapStateToProps)(WeekView);
