import React from 'react';
import { connect } from 'react-redux';
import { FetchWrapper } from 'element';
import { NestedRoutes } from 'element/routes';
import { Route, StringMap } from 'type';
import { BOX_REPORTS_GET_PAGE_DATA_REQUEST } from '../../state/Reports';

type OwnProps = {
  routes: StringMap<Route>;
};

type StateProps = {};

type DispatchProps = {
  fetchData: () => void;
};

const ReportsWrapper = ({
  routes,
  ...fetchWrapperProps
}: StateProps & DispatchProps & OwnProps) => (
  <FetchWrapper {...fetchWrapperProps} pageId="REPORTS">
    <NestedRoutes routes={routes} />
  </FetchWrapper>
);

// const mapStateToProps = (state: StoreState): StateProps => ({});

const mapDispatchToProps: DispatchProps = {
  fetchData: BOX_REPORTS_GET_PAGE_DATA_REQUEST
};

export default connect(
  null,
  mapDispatchToProps
)(ReportsWrapper);
