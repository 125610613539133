import { createAction } from 'lib/store-utils';
import { FormattedErrors } from 'type';
import {
  AwardsModalOpenedPayload,
  AwardsModalRecalculateRequestPayload,
  AwardsModalRecalculateSuccessPayload,
  AwardsModalSelectedHigherDutyIdChanged,
  AwardsModalSuccessPayload
} from './types';

export const BOX_TIMESHEETS_AWARDS_MODAL_OPENED = createAction<AwardsModalOpenedPayload>('Timesheets / Awards modal opened');

export const BOX_TIMESHEETS_AWARDS_MODAL_REQUEST = createAction('Timesheets / Awards modal request');
export const BOX_TIMESHEETS_AWARDS_MODAL_SUCCESS = createAction<AwardsModalSuccessPayload>('Timesheets / Awards modal success');

export const BOX_TIMESHEETS_AWARDS_MODAL_CLOSED = createAction('Timesheets / Awards modal closed');

export const BOX_TIMESHEETS_AWARDS_MODAL_RECALCULATE_REQUEST = createAction<AwardsModalRecalculateRequestPayload>('Timesheets / Awards modal / recalculate request');
export const BOX_TIMESHEETS_AWARDS_MODAL_RECALCULATE_SUCCESS = createAction<AwardsModalRecalculateSuccessPayload>('Timesheets / Awards modal / recalculate success');
export const BOX_TIMESHEETS_AWARDS_MODAL_RECALCULATE_FAILURE = createAction<FormattedErrors>('Timesheets / Awards modal / recalculate failure');

export const BOX_TIMESHEETS_AWARDS_MODAL_ERRORS_CLEARED = createAction('Timesheets / Awards modal / errors cleared');

export const BOX_TIMESHEETS_AWARDS_MODAL_SELECTED_HIGHER_DUTY_ID_CHANGED = createAction<AwardsModalSelectedHigherDutyIdChanged>('Timesheets / Awards modal / selected higher duty id changed');

export const BOX_TIMESHEETS_AWARDS_MODAL_UPDATE_REQUEST = createAction('Timesheets / Awards modal / update request');
export const BOX_TIMESHEETS_AWARDS_MODAL_UPDATE_SUCCESS = createAction('Timesheets / Awards modal / update success');
export const BOX_TIMESHEETS_AWARDS_MODAL_UPDATE_FAILURE = createAction<FormattedErrors>('Timesheets / Awards modal / update failure');
