import React from 'react';
import {
  Avatar,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '../../../extended-oxygen-elements';
import {
  CloseIcon,
  PageDialog,
  PageDialogCancel,
  PageDialogErrors,
  PageDialogSubmit,
  WarningAmberOutlinedIcon,
} from '../../../element';

type Props = {
  isOpened: boolean;
  onClose: () => void;
  handleSubmit: () => void;
};

export const DiscardBreaksCustomisationDialog = ({
  isOpened,
  onClose,
  handleSubmit,
}: Props) => {
  return (
    <PageDialog open={isOpened} maxWidth="xs">
      <DialogTitle>
        <Avatar size="large" color="warning" light aria-label={'warning'}>
          <WarningAmberOutlinedIcon />
        </Avatar>
      </DialogTitle>
      <DialogContent>
        <PageDialogErrors />
        <Typography variant="h5" gutterBottom>
          Discard unsaved changes?
        </Typography>
        <Typography>
          You have not yet saved your custom breaks, are you sure you want to
          remove them and go back to previously saved?
        </Typography>
      </DialogContent>
      <DialogActions>
        <PageDialogCancel
          variant="outlined"
          size="medium"
          onClick={onClose}
          fullWidth
        >
          Cancel
        </PageDialogCancel>
        <PageDialogSubmit fullWidth variant="contained" onClick={handleSubmit}>
          Discard
        </PageDialogSubmit>
      </DialogActions>
    </PageDialog>
  );
};
