import { createSelector } from 'reselect';
import moment from 'moment';
import _ from 'lodash';
import {
  AccountTreeSite,
  EmployeeSettingsSorting,
  RosteredShift,
  RosterTimeOff,
  StringMap,
  Timesheet,
  UserFields,
} from 'type';
import { SERVER_DAY_FORMAT } from 'lib/config';
import { StoreState } from 'state/types';
import { groupById } from 'state/helpers';
import { getEmployeeSettingsSorting } from 'state/Account';
import { getRosteredShifts, getSiteId } from 'state/RosteredShifts';
import { userListSelector } from 'state/UsersCollection';
import { timeOffsByUserIdSelector } from 'state/TimeOffs';
import {
  filtersByTypeSelector,
  getFilters,
  rosteredShiftsFilteredSelector,
  timesheetsFilteredSelector,
  userIdsFilteredSelector,
} from 'state/Roster/RosterFilters';
import { sortUserBy } from '../../helpers';
import {
  GetWeekNavParamsPayload,
  GroupedData,
  GroupedDataUnassigned,
  RosterWeekViewTimeOff,
} from '../types';
import {
  buildRosterRowsWithTimeOffs,
  enumerateDaysBetweenDates,
  filterRostersByUserId,
  filterTimesheetsByUserId,
  getScheduleConfig,
  getTimesheetsWithoutRosters,
  getWeekViewTimeOffDueToRosteredShift,
  getWeekViewTimeOffLeave,
} from '../helpers';
import { weekNavParamsPayloadSelector } from './weekNavSelectors';
import { Dictionary } from 'ts-essentials';
import { getPrintOptions } from '../../Print';

const getUnassignedRostersData = (
  filteredRosteredShifts: RosteredShift[],
  filteredTimesheets: Timesheet[],
  getWeekNavParamsPayload: GetWeekNavParamsPayload
) => {
  const unassignedRosters = filterRostersByUserId(null, filteredRosteredShifts);
  const { rows, rowsNumber } = buildRosterRowsWithTimeOffs(
    unassignedRosters,
    filteredRosteredShifts,
    filteredTimesheets,
    getWeekNavParamsPayload,
    {}
  );
  return {
    rows: rows,
    rowsNumber: rowsNumber,
  };
};

const getTimeOffsWeekConfig = (
  timeOffs: StringMap<RosterWeekViewTimeOff[]>,
  timeZone: string,
  getWeekNavParamsPayload: GetWeekNavParamsPayload
) => {
  const { dayNames } = getScheduleConfig(getWeekNavParamsPayload);

  Object.keys(timeOffs).forEach((key: string) => {
    const currentItems = timeOffs[key];
    for (let currentItem of currentItems) {
      const date = +moment(key, 'YYYY-MM-DD').tz(timeZone, true).startOf('day');
      if (dayNames[date]) {
        dayNames[date].push(currentItem);
      }
    }
  });

  return dayNames;
};

export const timeOffsByUserIdByDaySelector = createSelector<
  StoreState,
  StringMap<RosterTimeOff[]>,
  StringMap<StringMap<RosterWeekViewTimeOff[]>>
>(timeOffsByUserIdSelector, (timeOffsByUserId: StringMap<RosterTimeOff[]>) =>
  _.mapValues(timeOffsByUserId, (timeOffsOfUser: RosterTimeOff[]) =>
    _.reduce(
      timeOffsOfUser,
      (
        timeOffsOfUserByDate: StringMap<RosterWeekViewTimeOff[]>,
        rosterTimeOff: RosterTimeOff
      ) => {
        if (rosterTimeOff.type === 'rostered_shift') {
          const date: string = rosterTimeOff.start.format(SERVER_DAY_FORMAT);

          return groupById(
            date,
            getWeekViewTimeOffDueToRosteredShift(rosterTimeOff),
            timeOffsOfUserByDate
          );
        }

        const timeOffDates = enumerateDaysBetweenDates(
          rosterTimeOff.start,
          rosterTimeOff.end
        );

        _.each(timeOffDates, (day: string) => {
          const weekViewTimeOff: RosterWeekViewTimeOff =
            getWeekViewTimeOffLeave(rosterTimeOff, day);

          if (timeOffsOfUserByDate[day]) {
            timeOffsOfUserByDate[day].push(weekViewTimeOff);
          } else {
            timeOffsOfUserByDate[day] = [weekViewTimeOff];
          }
        });

        return timeOffsOfUserByDate;
      },
      {}
    )
  )
);

export const getSortedUsersBy = createSelector<
  StoreState,
  string[],
  StringMap<UserFields>,
  EmployeeSettingsSorting,
  string[]
>(
  userIdsFilteredSelector,
  userListSelector,
  getEmployeeSettingsSorting,
  sortUserBy
);

const userHasRosterData = (data: any[]) => {
  let hasRosteredShifts = false;
  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < data[i].length; j++) {
      if (data[i][j].rosteredShiftId) {
        hasRosteredShifts = true;
        break;
      }
    }
    if (hasRosteredShifts) {
      break;
    }
  }
  return hasRosteredShifts;
};

const pushUserData = (
  config: any[],
  showPeopleFilter: 'all' | 'with_shifts' | 'without_shifts',
  data: any,
  byUser: any
) => {
  const hasRosteredShifts = userHasRosterData(data);
  if (showPeopleFilter === 'all') {
    config.push(byUser);
  }

  if (showPeopleFilter === 'with_shifts' && hasRosteredShifts) {
    config.push(byUser);
  }

  if (showPeopleFilter === 'without_shifts' && !hasRosteredShifts) {
    config.push(byUser);
  }

  return config;
};

export const userRosterDataSelector = createSelector(
  getSortedUsersBy,
  userListSelector,
  rosteredShiftsFilteredSelector,
  timesheetsFilteredSelector,
  timeOffsByUserIdByDaySelector,
  getSiteId,
  weekNavParamsPayloadSelector,
  filtersByTypeSelector,
  getFilters,
  (
    usersIds,
    userList,
    rosters,
    timesheets,
    timeOffs,
    siteId,
    weekNavParamsPayload,
    filters,
    allFilters
  ) => {
    const config: GroupedData[] = [];

    if (!filters.timesheets) {
      timesheets = [];
    }
    if (!filters.roster) {
      rosters = [];
    }

    usersIds.forEach((userId: string) => {
      const userRosters = filterRostersByUserId(userId, rosters);
      const userTimesheets = filterTimesheetsByUserId(userId, timesheets);
      const filteredTimesheets = getTimesheetsWithoutRosters(
        userTimesheets,
        userRosters
      );

      const weekData: (RosteredShift | Timesheet)[] = [
        ...userRosters,
        ...filteredTimesheets,
      ];
      const site: AccountTreeSite | undefined =
        weekNavParamsPayload.sites[siteId];
      const timeZone: string = site ? site.timezone_id : '';
      const userTimeOffs = timeOffs[userId] ? timeOffs[userId] : {};
      const timeOffsData = splitTimeOffsByType(
        userTimeOffs,
        timeZone,
        weekNavParamsPayload
      );

      const userIsVisible: boolean =
        allFilters.showArchivedUsers ||
        (userList[userId] && userList[userId].is_active);

      if (userIsVisible) {
        const rosterData = buildRosterRowsWithTimeOffs(
          weekData,
          rosters,
          timesheets,
          weekNavParamsPayload,
          timeOffsData
        );

        const byUser: any = {
          user_id: userId,
          rosters: rosterData.rows,
          rostersRowNumber: rosterData.rowsNumber,
        };

        pushUserData(config, allFilters.showPeople, rosterData.rows, byUser);
      }
    });

    return config;
  }
);

export const userPrintableRosterDataSelector = createSelector(
  getSortedUsersBy,
  userListSelector,
  rosteredShiftsFilteredSelector,
  timesheetsFilteredSelector,
  timeOffsByUserIdByDaySelector,
  getSiteId,
  weekNavParamsPayloadSelector,
  filtersByTypeSelector,
  getFilters,
  getPrintOptions,
  (
    usersIds,
    userList,
    rosters,
    timesheets,
    timeOffs,
    siteId,
    weekNavParamsPayload,
    filters,
    allFilters,
    printOptions
  ) => {
    const config: GroupedData[] = [];

    if (!filters.timesheets) {
      timesheets = [];
    }
    if (!filters.roster) {
      rosters = [];
    }

    const filteredRostersByPrintOptionsUnpublished = printOptions.unpublished
      ? rosters
      : rosters.filter((roster) => roster.is_published);

    const rostersBasedOnPrintOptions = printOptions.assigned
      ? filteredRostersByPrintOptionsUnpublished
      : filteredRostersByPrintOptionsUnpublished.filter(
          (roster) => roster.user_id
        );

    usersIds.forEach((userId: string) => {
      const userRosters = filterRostersByUserId(
        userId,
        rostersBasedOnPrintOptions
      );
      const userTimesheets = filterTimesheetsByUserId(userId, timesheets);
      const filteredTimesheets = getTimesheetsWithoutRosters(
        userTimesheets,
        userRosters
      );

      const weekData: (RosteredShift | Timesheet)[] = [
        ...userRosters,
        ...filteredTimesheets,
      ];
      const site: AccountTreeSite | undefined =
        weekNavParamsPayload.sites[siteId];
      const timeZone: string = site ? site.timezone_id : '';
      const userTimeOffs = timeOffs[userId] ? timeOffs[userId] : {};
      const timeOffsData = splitTimeOffsByType(
        userTimeOffs,
        timeZone,
        weekNavParamsPayload
      );

      const userIsVisible: boolean =
        allFilters.showArchivedUsers ||
        (userList[userId] && userList[userId].is_active);

      if (userIsVisible) {
        const rosterData = buildRosterRowsWithTimeOffs(
          weekData,
          rostersBasedOnPrintOptions,
          timesheets,
          weekNavParamsPayload,
          timeOffsData
        );

        const byUser: any = {
          user_id: userId,
          rosters: rosterData.rows,
          rostersRowNumber: rosterData.rowsNumber,
        };

        pushUserData(config, allFilters.showPeople, rosterData.rows, byUser);
      }
    });

    return config;
  }
);

const splitTimeOffsByType = (
  userTimeOffs: StringMap<RosterWeekViewTimeOff[]>,
  timeZone: string,
  getWeekNavParamsPayload: GetWeekNavParamsPayload
) => {
  const timeOffsByDateAndType: Dictionary<{
    leaves: RosterWeekViewTimeOff[];
    timeoffs: RosterWeekViewTimeOff[];
  }> = {};
  const timeOffs = getTimeOffsWeekConfig(
    userTimeOffs,
    timeZone,
    getWeekNavParamsPayload
  );
  for (let timestamp in timeOffs) {
    timeOffsByDateAndType[timestamp] = {
      leaves: timeOffs[timestamp].filter(
        (t: RosterWeekViewTimeOff) => t.type === 'leave'
      ),
      timeoffs: timeOffs[timestamp].filter(
        (t: RosterWeekViewTimeOff) => t.type !== 'leave'
      ),
    };
  }
  return timeOffsByDateAndType;
};

export const userByIdRosterDataSelector = (
  state: StoreState,
  userId: string
): GroupedData | undefined => {
  const data = userRosterDataSelector(state);
  return data.filter((d) => d.user_id === userId)[0];
};

export const userUnassignedRosterDataSelector = createSelector(
  rosteredShiftsFilteredSelector,
  timesheetsFilteredSelector,
  weekNavParamsPayloadSelector,
  (rosters, timesheets, getWeekNavParamsPayload): GroupedDataUnassigned => {
    const { rows, rowsNumber } = getUnassignedRostersData(
      rosters,
      timesheets,
      getWeekNavParamsPayload
    );

    return {
      user_id: null,
      rosters: rows,
      rostersRowNumber: rowsNumber,
    };
  }
);

export const userPrintableUnassignedRosterDataSelector = createSelector(
  rosteredShiftsFilteredSelector,
  timesheetsFilteredSelector,
  weekNavParamsPayloadSelector,
  getPrintOptions,
  (
    rosters,
    timesheets,
    getWeekNavParamsPayload,
    printOptions
  ): GroupedDataUnassigned => {
    const { rows, rowsNumber } = getUnassignedRostersData(
      printOptions.unpublished
        ? rosters
        : rosters.filter((roster) => roster.is_published),
      timesheets,
      getWeekNavParamsPayload
    );

    return {
      user_id: null,
      rosters: rows,
      rostersRowNumber: rowsNumber,
    };
  }
);

export const groupRostersByUsersSelector = createSelector(
  userRosterDataSelector,
  userUnassignedRosterDataSelector,
  (userRosterData, userUnassignedRosterData) => {
    return [...userRosterData, userUnassignedRosterData];
  }
);

export const groupPrintableRostersByUsersSelector = createSelector(
  userPrintableRosterDataSelector,
  userPrintableUnassignedRosterDataSelector,
  getPrintOptions,
  getRosteredShifts,
  (userRosterData, userUnassignedRosterData, printOptions, rosteredShifts) => {
    return [...userRosterData, userUnassignedRosterData];
  }
);
