import { UserProfileReducerState } from './types';
import moment from 'moment-timezone';

export const getDefaultState = (): UserProfileReducerState => ({
  isFetching: false,
  isUpdating: false,
  isUploading: false,
  entitlements: {},
  awards: {
    isFetching: false,
    globalAwardsList: [],
    assignedAwards: [],
    errors: [],
    modal: {
      isOpened: false,
      isSaving: false,
      errors: [],
      payload: {
        id: '',
        awardId: null,
        effectiveDate: moment().add(1, 'day'),
        type: 'create'
      }
    },
    deleteModal: {
      isOpened: false,
      isDeleting: false,
      awardId: null,
      errors: []
    }
  },
  userProfile: {
    avatar: null,
    avatar_url: null,
    casual_loading: '',
    created_at: '',
    date_of_birth: null,
    deactivated_at: null,
    email: '',
    employment_type: 'Casual',
    end_date: null,
    external_id: null,
    abbreviation: '',
    first_name: '',
    gender: '',
    id: '',
    is_active: true,
    rating: 0,
    last_name: '',
    managers: {
      leave: null,
      shift_offer: null,
      shift_swap: null
    },
    mobile: '',
    notification_settings: [],
    roster_rules: {
      max_long_hour_shifts_per_roster_period: 0,
      max_consecutive_long_hour_shifts: 0,
      max_shift_duration: 0,
      min_break_between_shifts: 0,
      min_shift_duration: 0
    },
    ordinary_hours_of_work: {
      max_hrs_per_day: 0,
      max_hrs_per_roster_period: 0,
      min_hrs_per_day: 0,
      min_hrs_per_roster_period: 0
    },
    payroll_number: '',
    prefered_name: '',
    rate: '0.5',
    roster_period: '',
    shift_worker: false,
    start_date: null,
    tags: [],
    updated_at: '',
    user_roles: [],
    username: '',
    user_group_id: 0,
    fileUploaded: false,
    custom_fields: [],
    payroll_site_id: null,
    settings: {
      custom_user_group: false
    },
    roster_settings: {
      max_consecutive_long_hour_shifts: 0,
      max_hrs_per_day: 0,
      max_hrs_per_roster_period: 0,
      max_long_hour_shifts_per_roster_period: 0,
      max_shift_duration: 0,
      min_break_between_shifts: 0,
      min_hrs_per_day: 0,
      min_hrs_per_roster_period: 0,
      min_shift_duration: 0,
      roster_period_date_start: '2019-05-13',
      roster_period_length: 7
    },
    higher_duties: [],
    payroll_area_id: null,
    preferences: {
      roster_view_settings: {}
    }
  },
  errors: [],
  customFieldsModal: {
    isOpened: false,
    custom_fields: {}
  },
  uploadModal: {
    isOpened: false
  },
  profileModal: {
    isOpened: false
  },
  rolesModal: {
    isOpened: false
  },
  paymentModal: {
    isOpened: false
  },
  pinModal: {
    isOpened: false
  },
  editApproversModal: {
    isOpened: false,
    userId: ''
  },
  userDetails: {
    isOpened: false
  },
  rulesModal: {
    isOpened: false
  }
});
