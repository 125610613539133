import { fork } from 'redux-saga/effects';
import account from './account';
import paymentConditions from './paymentConditions';
import rosteredShiftSettings from './rosteredShiftSettings';
import timesheetSettings from './timesheetSettings';
import timeClockSettings from './timeClockSettings';
import accountPreferences from './accountPreferences';

export function* watchAccount() {
  yield fork(account);
  yield fork(paymentConditions);
  yield fork(rosteredShiftSettings);
  yield fork(timesheetSettings);
  yield fork(timeClockSettings);
  yield fork(accountPreferences);
}
