import React, { ReactNode } from 'react';
import { Layout } from 'element';
import { EmployeeDashboardErrors } from './components/EmployeeDashboardErrors';

type Props = {
  header: ReactNode;
  children: ReactNode;
};

export const EmployeeDashboardLayout = ({ header, children }: Props) => {
  return (
    <>
      <Layout.Header>{header}</Layout.Header>

      <Layout.Content>
        <EmployeeDashboardErrors />
        {children}
      </Layout.Content>
    </>
  );
};
