import React, { Component } from 'react';
import { Moment } from 'moment';
import { BaseOnChange, BaseProps } from '../types';
import { ClearableTimeInput } from '../ClearableTimeInput';

type Props = BaseProps & {
  value: Moment | null;
  onChange: BaseOnChange<Moment>;
};

export class TimeInput extends Component<Props> {
  render() {
    const { onChange, ...restProps } = this.props;
    return <ClearableTimeInput {...restProps} onChange={this.onChange} />;
  }

  private onChange = (updatedValue: Moment | null) => {
    if (updatedValue) {
      const { onChange, name } = this.props;
      onChange(updatedValue, name);
    }
  };
}
