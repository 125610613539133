import React from 'react';
import { Chip, TableCell, TableRow } from 'extended-oxygen-elements';
import { IconButton } from 'extended-oxygen-elements';
import { useSelectorWithProps } from 'hooks';
import {
  DeleteOutlinedIcon,
  EditOutlinedIcon,
} from 'element';
import { getPayElement } from 'state/PayElementsCollection';
import { usePayElementsListContext } from '../pay-elements-list-context';
import { useDeleteContext } from '../delete-context';

type PayElementProps = {
  id: string;
};

export default function PayElement({ id }: PayElementProps) {
  const { name, multiplier, export_code, is_default } = useSelectorWithProps(
    getPayElement,
    id
  );
  const { openEdit, isEditOpen } = usePayElementsListContext();
  const { openConfirm: openDeleteConfirm } = useDeleteContext();

  const handleEditBtnClick = () => {
    openEdit(id);
  };

  const handleDeleteBtnClick = () => {
    openDeleteConfirm(id);
  };

  return (
    <TableRow data-testid={'pay-element-row'}>
      <TableCell data-testid={'pay-element-name'}>
        {name} {is_default && <Chip component="span" label="Default" />}
      </TableCell>

      <TableCell data-testid={'pay-element-multiplier'}>{multiplier}</TableCell>

      <TableCell data-testid={'pay-element-code'}>{export_code}</TableCell>

      <TableCell padding="checkbox">
        {!isEditOpen && (
          <IconButton
            onClick={handleEditBtnClick}
            data-testid={'pay-element-edit-btn'}
          >
            <EditOutlinedIcon />
          </IconButton>
        )}
      </TableCell>

      <TableCell padding="checkbox">
        {!isEditOpen && (
          <IconButton
            size="small"
            disabled={is_default}
            onClick={handleDeleteBtnClick}
            data-testid={'pay-element-delete-btn'}
          >
            <DeleteOutlinedIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
}
