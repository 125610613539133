import React from 'react';
import LayoutContext from '../LayoutContext';
import DataTable from './DataTable';
import { DataTableProps } from './type';

/**
 * Wraps the DataTable and provides it with the Layout props required.
 */
class LayoutAwareDataTable extends React.Component<DataTableProps> {
  render() {
    const { children } = this.props;

    return (
      <LayoutContext.Consumer>
        {(layoutProps: any) => {
          return (
            <DataTable
              {...this.props}
              // bulk actions props
              hasBulkActions={true}
              isBulkActionOpen={layoutProps.isBulkActionOpen}
              toggleBulkActionDisabled={layoutProps.toggleBulkActionDisabled}
            >
              {children}
            </DataTable>
          );
        }}
      </LayoutContext.Consumer>
    );
  }
}

export default LayoutAwareDataTable;
