import React, { Component, FormEvent, MouseEvent } from 'react';
import { connect } from 'react-redux';
import { filter, find, map, values } from 'lodash';
import { FormItem, Link, SelectList } from 'elmo-elements';
import {
  SelectPropsOption,
  SelectPropsOptionGrouped,
} from 'elmo-elements/Select';
import {
  CheckIcon,
  ErrorBox,
  HighlightOffOutlinedIcon,
  PageDialog,
  PageDialogCancel,
  PageDialogSubmit,
} from 'element';
import { OnChangeSelectEvent } from 'element/Select/types';
import {
  AccountTreeArea,
  AccountTreeRole,
  AccountTreeSelector,
  AccountTreeSite,
  LanguagePreferences,
  StringMap,
  UserRole,
  UserRoleSimple,
} from 'type';
import { capitalize, getFullUserRole, keyMirror } from 'lib/helpers';
import { StoreState } from 'state/types';
import { asSelectOptionsCombiner } from 'state/combiners';
import { getLangPreferences } from 'state/Account';
import {
  activeAreasBySiteIdCombiner,
  areasGroupedAsSelectOptionsCombiner,
} from 'state/AccountTree/combiners';
import { getAccountTree } from 'state/AccountTree';
import { currentUserSiteIdsSelector, hasPermissionSelector } from 'state/Auth';
import { SubmitPayload } from '../../types';
import './RoleModalWithMainRoleEditView.scss';
import { RoleModalViewProps } from './types';
import {
  DialogContent,
  ListSubheader,
  MenuItem,
  Select,
} from 'extended-oxygen-elements';
import { SelectChangeEvent } from '@mui/material';
import _ from 'lodash';
import { DialogActions, DialogTitle } from 'extended-oxygen-elements';

type UserRoleSimplified = Pick<
  UserRole,
  'site_id' | 'area_id' | 'role_id' | 'is_main'
>;

type OwnProps = RoleModalViewProps;

type StateProps = {
  langPreferences: LanguagePreferences;
  tree: AccountTreeSelector;
  currentUserSiteIds: string[];
  canViewAllSites: boolean;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

type FormData = {
  role_id: string | null;
  user_roles: UserRoleSimplified[];
};

type State = {
  data: FormData;
  isValid: boolean;
  isEditMainRoleEnabled: boolean;
};

const getState = ({ initRoles = [] }: Props): State => ({
  data: {
    role_id: null,
    user_roles: initRoles,
  },
  isValid: false,
  isEditMainRoleEnabled: false,
});

export class RoleModalViewComponent extends Component<Props, State> {
  state = getState(this.props);
  names = keyMirror(this.state.data);

  render() {
    const {
      state: { data, isEditMainRoleEnabled },
      names,
      labels,
    } = this;

    return (
      <PageDialog
        maxWidth={'xs'}
        open={this.props.isOpened}
        id="user-add-roles-modal"
        onClose={this.onCloseModal}
      >
        <DialogTitle>{capitalize(labels.role)}</DialogTitle>
        <DialogContent>
          <form onSubmit={this.onSubmit}>
            <ErrorBox errors={this.props.errors} className="mb-6" />

            <FormItem label={`Add ${labels.role}`}>
              <Select
                fullWidth
                disabled={isEditMainRoleEnabled}
                id="role"
                name={names.role_id}
                onChange={this.onSelectChanged}
                value={data.role_id || ''}
                aria-label={`Add ${labels.role}`}
              >
                {this.roleOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormItem>

            <FormItem label={capitalize(`${labels.area}, ${labels.site}`)}>
              <Select
                disabled={isEditMainRoleEnabled || !data.role_id}
                fullWidth
                id="area"
                name={'areaId'}
                aria-label={capitalize(`${labels.area}, ${labels.site}`)}
                onChange={this.handleChangeArea}
                value={''}
              >
                {this.areaOptions.map((option) =>
                  this.renderSelectGroup(option)
                )}
              </Select>
            </FormItem>

            <SelectList>
              <SelectList.OptionHeader>
                <div className="role-modal-view-with-edit-roles-table__header-wrapper">
                  <span>
                    {capitalize(
                      `${labels.role} - ${labels.area}, ${labels.site}`
                    )}
                  </span>

                  <Link
                    onClick={this.toggleEditState}
                    className={
                      'role-modal-view-with-edit-roles-table__change-main-btn'
                    }
                  >
                    {this.changeMainRoleBtnLabel}
                  </Link>
                </div>
              </SelectList.OptionHeader>
              <div data-testid="roles_list">
                {map(data.user_roles, this.renderUserRole)}
              </div>
            </SelectList>
          </form>
        </DialogContent>
        <DialogActions>
          <PageDialogCancel onClick={this.onCloseModal} fullWidth={false}>
            Cancel
          </PageDialogCancel>
          <PageDialogSubmit
            onClick={this.onSubmit}
            loading={this.props.isUpdating}
            disabled={this.isSaveBtnDisabled}
            fullWidth={false}
          >
            Save
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  }

  handleChangeArea = (e: SelectChangeEvent<string>, child: React.ReactNode) => {
    const { value, name } = e.target;
    this.onSelectChangeArea({ value, name });
  };

  renderSelectGroup = (group: SelectPropsOptionGrouped) => {
    const items = group.options.map(
      (option: SelectPropsOption, index: number) => {
        return (
          <MenuItem key={`${option.label}-${index}`} value={option.value}>
            {option.label}
          </MenuItem>
        );
      }
    );
    return [<ListSubheader>{group.label}</ListSubheader>, items];
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.isOpened !== this.props.isOpened) {
      if (this.props.isOpened) {
        // onOpen
        this.setState(getState(this.props));
      } else {
        // onClose
        this.setState({
          isValid: false,
        });
      }
    }
  }

  private get isSaveBtnDisabled(): boolean {
    const { isValid, isEditMainRoleEnabled } = this.state;

    return isEditMainRoleEnabled || !isValid;
  }

  private get changeMainRoleBtnLabel(): string {
    const {
      labels,
      state: { isEditMainRoleEnabled },
    } = this;

    return isEditMainRoleEnabled ? 'Submit' : `Change primary ${labels.role}`;
  }

  private get labels() {
    const {
      langPreferences: { role, area, site },
    } = this.props;

    return {
      role: role.singular,
      area: area.singular,
      site: site.singular,
    };
  }

  private get roleOptions(): SelectPropsOption[] {
    let roles: StringMap<AccountTreeRole> = {};
    Object.keys(this.props.tree.roles).forEach((key) => {
      if (!this.props.tree.roles[key].archived) {
        roles[key] = this.props.tree.roles[key];
      }
    });
    return _.sortBy(values(asSelectOptionsCombiner(roles)), 'label');
  }

  private get areaOptions(): SelectPropsOptionGrouped[] {
    const { role_id } = this.state.data;

    if (role_id === null) {
      return [];
    }

    const { areas, sites } = this.props.tree;

    const filteredAreas: AccountTreeArea[] = filter(
      areas,
      (area: AccountTreeArea) =>
        area.role_ids.includes(role_id) && !area.archived
    );

    const areasBySiteId = activeAreasBySiteIdCombiner(filteredAreas);
    const filteredSites = filter(sites, (site) => !site.archived);
    const areasByActiveSiteId: StringMap<AccountTreeArea[]> = {};
    filteredSites.forEach((site) => {
      if (areasBySiteId[site.id]) {
        areasByActiveSiteId[site.id] = areasBySiteId[site.id];
      }
    });

    return areasGroupedAsSelectOptionsCombiner(
      areasByActiveSiteId,
      getActiveSites(sites)
    );
  }

  private get submitPayload(): SubmitPayload {
    const { user_roles } = this.state.data;

    return { user_roles };
  }

  private toggleEditState = () => {
    this.setState((prevState) => ({
      isEditMainRoleEnabled: !prevState.isEditMainRoleEnabled,
    }));
  };

  private renderUserRole = (role: UserRole) => {
    const { role_id, area_id, site_id } = role;

    const { tree } = this.props;
    const isArchived = tree.roles[role_id] && tree.roles[role_id].archived;
    return !isArchived ? (
      <SelectList.Option
        key={role_id + area_id + site_id}
        isDisabled={this.getIsDisabledRoleState(role)}
        icon={this.renderRoleIcon(role)}
        onToggle={this.handleRoleToggleCreator(role)}
      >
        {getFullUserRole({
          ...tree,
          role,
          showPrimaryLabel: true,
        })}
      </SelectList.Option>
    ) : null;
  };

  private getIsDisabledRoleState = ({ is_main, site_id }: UserRole) => {
    const { isEditMainRoleEnabled } = this.state;

    if (isEditMainRoleEnabled) {
      return false;
    }

    const { canViewAllSites, currentUserSiteIds } = this.props;

    const hasAccessToSiteOfRole: boolean =
      canViewAllSites || currentUserSiteIds.includes(site_id);

    return is_main || !hasAccessToSiteOfRole;
  };

  private renderRoleIcon = (role: UserRole) => {
    const { isEditMainRoleEnabled } = this.state;

    if (!isEditMainRoleEnabled) {
      return <HighlightOffOutlinedIcon className="remove-icon" />;
    }

    if (role.is_main) {
      return <CheckIcon />;
    }
  };

  private handleRoleToggleCreator = (role: UserRole) => () => {
    const { isEditMainRoleEnabled } = this.state;
    if (isEditMainRoleEnabled) {
      this.selectRoleAsPrimary(role);
    } else {
      this.removeUserRoleFromList(role);
    }
  };

  private selectRoleAsPrimary = (selectedUserRole: UserRole) => {
    const { user_roles } = this.state.data;

    const rolesWithoutMain = map(
      user_roles,
      ({ is_main, ...restRole }) => restRole
    );

    const updatedRoles = map(
      rolesWithoutMain,
      (roleWithoutMain): UserRoleSimplified => ({
        ...roleWithoutMain,
        is_main: isEqualRoles(roleWithoutMain, selectedUserRole),
      })
    );

    this.updateRoles(updatedRoles);
  };

  private removeUserRoleFromList = (selectedUserRole: UserRole) => {
    const updatedRoles = filter(
      this.state.data.user_roles,
      (userRole) => !isEqualRoles(userRole, selectedUserRole)
    );

    this.updateRoles(updatedRoles);
  };

  private updateRoles = (updatedRoles: UserRoleSimplified[]) => {
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          user_roles: updatedRoles,
        },
      }),
      this.validate
    );
  };

  private onSelectChanged = (
    e: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    const { name, value } = e.target;
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  };

  private onSelectChangeArea = ({ value: areaId }: OnChangeSelectEvent) => {
    const siteId = this.props.tree.areas[areaId].site_id;

    const partialNewRole = {
      role_id: this.state.data.role_id!,
      area_id: areaId,
      site_id: siteId,
      is_main: false,
    };

    const userHadNewRole = !!find(
      this.state.data.user_roles,
      (existingRole: UserRoleSimplified) =>
        existingRole.site_id === partialNewRole.site_id &&
        existingRole.area_id === partialNewRole.area_id &&
        existingRole.role_id === partialNewRole.role_id
    );

    if (!userHadNewRole) {
      this.setState(
        {
          data: {
            role_id: null,
            user_roles: [...this.state.data.user_roles, partialNewRole],
          },
        },
        this.validate
      );
    } else {
      this.setState({
        data: {
          ...this.state.data,
          role_id: null,
        },
      });
    }
  };

  private onSubmit = (
    event: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    if (this.state.isValid) {
      this.props.onFormSubmit(this.submitPayload);
    }
  };

  private validate = () => {
    this.setState({
      isValid: !!this.state.data.user_roles.length,
    });
  };

  private onCloseModal = () => {
    if (!this.props.isUpdating) {
      this.props.onCloseModal();
    }
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  langPreferences: getLangPreferences(state),
  tree: getAccountTree(state),
  currentUserSiteIds: currentUserSiteIdsSelector(state),
  canViewAllSites: hasPermissionSelector(state, 'account.viewallsites'),
});

// const mapDispatchToProps: DispatchProps = {};

export const RoleModalView = connect(mapStateToProps)(RoleModalViewComponent);

function isEqualRoles(
  role: UserRoleSimple,
  anotherRole: UserRoleSimple
): boolean {
  return (
    role.site_id === anotherRole.site_id &&
    role.area_id === anotherRole.area_id &&
    role.role_id === anotherRole.role_id
  );
}

export function getActiveSites(sites: StringMap<AccountTreeSite>) {
  const filteredSites = filter(
    sites,
    (site: AccountTreeSite) => !site.archived
  );
  const activeSites: StringMap<AccountTreeSite> = {};

  filteredSites.forEach((site) => {
    activeSites[site.id] = site;
  });

  return activeSites;
}
