import React, { Component, MouseEvent } from 'react';
import {
  DelayedClickDiv,
  RosterCell,
  RosterShiftTimeRangeInput,
} from '../../../../components';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import { getAccountTree, areasBySiteIdSelector } from 'state/AccountTree';
import { DispatchProps, OwnProps, StateProps } from './types';
import { getUserListResponse } from 'state/UsersCollection';
import {
  getDraggableTemplatedShift,
  getDropCancelled,
  getTemplateShifts,
  getTemplateSiteId,
} from 'state/Roster/EditTemplate';
import {
  BOX_EDIT_TEMPLATE_DELETE_CONFIRM_SHIFT_ITEM,
  BOX_EDIT_TEMPLATE_DRAG_N_DROP,
  BOX_EDIT_TEMPLATE_OPEN_EDIT_MODAL,
  BOX_EDIT_TEMPLATE_SET_DRAGGABLE_SHIFT,
} from 'state/Roster/EditTemplate/actions';
import { BOX_ROSTER_BULK_DELETION_MODE_TOGGLE_SELECTED_SHIFT } from 'state/Roster/BulkActions/actions';
import { getIsBulkDeleteOpened } from 'state/Roster/BulkActions';
import { getLangPreferences } from 'state/Account';
import {
  compareProps,
  disableTooltip,
  dropDenied,
  enableTooltip,
  getRowId,
  handleDragEnd,
  removeDragOverClassName,
  removeDropPlaceholders,
  removeHiddenClassName,
  RowCellsProps,
  swapShifts,
  validateByAvailableAreaRoles,
  validateByAvailableRoles,
  validateDropCell,
} from '../../../../helpers';
import ContextualMenu from '../../../../components/ContextualMenu';
import {
  BOX_SHIFT_TIME_RANGE_INPUT_OPENED,
  ShiftTimeRangeInputType,
} from 'state/Roster/RangeInput/ShiftTimeRangeInput';
import { ShiftTemplateItem } from '../../../../../../type';
import { SiteTemplateCard, UserTemplateCard } from 'page/Roster/components';

type Props = OwnProps & StateProps & DispatchProps;

type State = {
  data: RowCellsProps[];
};

export class ShiftTemplateRowClass extends Component<Props, State> {
  oldState: RowCellsProps[] = [];
  dragEl: null | HTMLDivElement = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      data: props.data,
    };
  }

  updateRowData = (source: RowCellsProps[]) => {
    if (source.length) {
      this.setState(
        {
          ...this.state,
          data: source,
        },
        () => {
          if (!this.props.dropInProgress) {
            this.dragEl = null;
            removeHiddenClassName();
            enableTooltip();
          }
        }
      );
    }
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    const { dropInProgress, dropError, dropCanceled, draggableShift } =
      this.props;
    if (
      draggableShift &&
      dropInProgress !== prevProps.dropInProgress &&
      !dropInProgress
    ) {
      if (dropError.length || dropCanceled) {
        this.updateRowData(this.oldState);
      }
      this.props.setDraggableShift(null);
    } else if (prevProps.data !== this.props.data) {
      if (!dropInProgress) {
        this.oldState = [];
        for (let dataItem of this.props.data) {
          this.oldState.push(Object.assign({}, dataItem));
        }
        this.updateRowData(this.props.data);
      }
    }
  }

  shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>,
    nextContext: any
  ): boolean {
    return (
      compareProps(nextState, this.state, []) ||
      compareProps(nextProps, this.props, ['weekDays'])
    );
  }

  render() {
    return this.showRostersRow();
  }

  showRostersRow = () => {
    const { data } = this.state;
    return (
      <div className="week-rosters-grid__group week-rosters-grid__group--roster cell-height-v2">
        {data.map((cell: RowCellsProps, dayIndex: number) => (
          <div className="week-rosters-grid__group-day-block" key={dayIndex}>
            {this.getRosteredShiftCell(dayIndex)}
          </div>
        ))}
      </div>
    );
  };

  getLabel = (dayIndex: number, roster: any) => {
    return !roster ? `+ Add Shifts` : '';
  };

  getRosteredShiftCell = (dNum: number) => {
    const cellType = 'top';
    const { data } = this.state;
    const { shifts } = this.props;
    const roster = this.getData(shifts, data[dNum].rosteredShiftId);
    return (
      <RosterCell
        type={cellType}
        label={this.getLabel(dNum, roster)}
        labelOnHover={true}
        fontWeight={'normal'}
      >
        <DelayedClickDiv
          id={getRowId(`roster-${dNum}`, {
            viewType: this.props.viewType,
            user_id: this.props.user_id,
            area_id: this.props.area_id,
            role_id: this.props.role_id,
            rowIndex: this.props.rowIndex,
          })}
          className="week-rosters-grid__cell droppable-wrapper roster-empty-cell"
          onDragOver={this.handleDragOver}
          onDrop={this.handleOnDrop}
          onDragEnd={() => {
            handleDragEnd(this.props.dropInProgress);
            this.dragEl = null;
          }}
          onDragLeave={removeDragOverClassName}
          data-day={dNum}
          data-user-id={
            this.props.user_id === null ? 'null' : this.props.user_id
          }
          data-area-id={this.props.area_id}
          data-role-id={this.props.role_id}
          onDoubleClick={() => this.openCreateModal(dNum)}
          onClick={this.openTimeRangeInput('templateShift', dNum)}
        >
          {this.showRosteredShiftCard(dNum)}
        </DelayedClickDiv>
      </RosterCell>
    );
  };

  openTimeRangeInput =
    (type: ShiftTimeRangeInputType, dayNum: number) =>
    ({ target }: MouseEvent<HTMLDivElement>) => {
      const { isBulkDelete } = this.props;
      // TODO refactor, find out why this handler is triggered on contextual menu click and card click
      if (!target || isBulkDelete) {
        return;
      }

      if (
        !(target as HTMLDivElement).classList.contains(
          'week-rosters-grid__cell'
        )
      ) {
        return;
      }

      const {
        user_id,
        siteId: site_id,
        area_id,
        role_id,
        openRangeInput,
        rowIndex,
      } = this.props;

      openRangeInput({
        dayTimestamp: dayNum + '',
        type,
        rowIndex,
        user_id,
        site_id,
        area_id,
        role_id,
      });
    };

  cellRelatedProps = (dayIndex: number) => {
    return {
      day: dayIndex + 1,
      id: '',
      user_id: this.props.user_id,
      area_id: this.props.area_id ? this.props.area_id : null,
      end: '00:00',
      start: '00:00',
      breaks: [],
      role_id: this.props.role_id ? this.props.role_id : null,
      tags: [],
      notes: null,
    };
  };

  openCreateModal = (dayIndex: number) => {
    const { isBulkDelete } = this.props;
    if (!isBulkDelete) {
      this.props.openModal(this.cellRelatedProps(dayIndex));
    }
  };

  openEditModal = (roster: ShiftTemplateItem) => {
    const { isBulkDelete } = this.props;
    isBulkDelete ? this.toggleSelection(roster) : this.props.openModal(roster);
  };

  isDraggable = (user_id: null | string) => {
    const { users } = this.props;
    if (!user_id) {
      return true;
    }
    return users[user_id].is_active;
  };

  showRosteredShiftCard = (dayIndex: number) => {
    const { shifts, viewType, users } = this.props;
    const { data } = this.state;
    const roster = this.getData(shifts, data[dayIndex].rosteredShiftId);
    return roster ? (
      <div
        id={`drag-${roster.id}`}
        className={'draggable-wrapper'}
        onDragStart={(e: React.DragEvent<HTMLDivElement>) =>
          this.handleDragStart(e, roster)
        }
        draggable={this.isDraggable(roster.user_id)}
      >
        {viewType === 'user' ? (
          <UserTemplateCard templateShiftId={roster.id} />
        ) : (
          <SiteTemplateCard templateShiftId={roster.id} />
        )}
      </div>
    ) : (
      <>
        <RosterShiftTimeRangeInput
          {...this.props}
          dayTimestamp={dayIndex + ''}
          type="templateShift"
        />
        {this.contextMenuBlock(
          getRowId(`roster-${dayIndex}`, this.props as any),
          dayIndex
        )}
      </>
    );
  };

  getData = (data: any, key: null | undefined | string) => {
    return typeof key !== 'undefined' && key !== null ? data[key] : null;
  };

  onDeleteClickHandler = (roster: ShiftTemplateItem) => {
    const { deleteShift } = this.props;
    deleteShift(roster.id);
  };

  toggleSelection = (data: ShiftTemplateItem) => {
    this.props.toggleSelectedId({
      id: data.id,
      type: 'rostered_shift',
      user_id: data.user_id,
      area_id: data.area_id,
      role_id: data.role_id,
    });
  };

  addDragOverClassName = (e: React.DragEvent<HTMLDivElement>) => {
    const { currentTarget } = e;
    if (currentTarget) {
      currentTarget.classList.add('drag-over-card');
      if (
        !validateDropCell(
          currentTarget.dataset,
          this.validateByUser,
          this.validateByAreaRole
        )
      ) {
        currentTarget.classList.add('drop-denied');
      }
    }
  };

  handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = 'all' as any; // TODO double-check
    this.addDragOverClassName(e);
  };

  validateByUser = (userId: string) => {
    const { draggableShift } = this.props;
    const userID = userId === 'null' ? null : userId;
    return userID === draggableShift!.user_id || userID === null
      ? true
      : validateByAvailableRoles(userID, this.props);
  };

  validateByAreaRole = (
    areaId: string | undefined,
    roleId: string | undefined
  ) => {
    const { draggableShift } = this.props;
    const { user_id } = draggableShift!;
    if (!areaId || !roleId) {
      return false;
    }
    return validateByAvailableAreaRoles(user_id, areaId, roleId, this.props);
  };

  handleOnDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const { draggableShift } = this.props;
    const { currentTarget } = e;
    if (dropDenied(currentTarget, draggableShift) || !draggableShift) {
      enableTooltip();
      return false;
    }
    this.updateShiftToDrop(currentTarget, draggableShift);
  };

  updateShiftToDrop = (
    dropCell: HTMLDivElement,
    draggableShift: Partial<ShiftTemplateItem>
  ) => {
    const { dataset } = dropCell;
    const { viewType } = this.props;
    const stateToModify: RowCellsProps[] = [];
    this.oldState = [];

    for (let i = 0; i < this.state.data.length; i += 1) {
      this.oldState.push(Object.assign({}, this.props.data[i]));
      stateToModify.push(Object.assign({}, this.state.data[i]));
    }

    this.setState(
      {
        ...this.state,
        data: swapShifts(stateToModify, draggableShift, +dataset.day!),
      },
      () => {
        const params: Partial<ShiftTemplateItem> =
          viewType === 'user'
            ? this.getUserViewParams(draggableShift!, dataset)
            : this.getSiteViewParams(draggableShift!, dataset);
        this.props.updateRosteredShift(params);
        removeDropPlaceholders();
      }
    );
  };

  getUserViewParams = (shift: Partial<ShiftTemplateItem>, dataset: any) => {
    const { user_id, area_id, role_id } = shift;
    return {
      id: shift.id,
      area_id: dataset.areaId ? dataset.areaId : area_id,
      role_id: dataset.roleId ? dataset.roleId : role_id,
      user_id: dataset.userId
        ? dataset.userId === 'null'
          ? null
          : dataset.userId
        : user_id,
      site_id: this.props.siteId,
      day: dataset.day,
      start: shift.start,
      end: shift.end,
      breaks: shift.breaks,
      tags: shift.tags,
      notes: shift.notes,
    };
  };

  contextMenuBlock = (id: string, dayIndex: number) => {
    return (
      <ContextualMenu
        className={'full-cell-menu'}
        elementId={id}
        type={'create'}
        shiftType={'shift_item'}
        objectId={null}
        currentCellData={{
          day: (dayIndex + 1).toString() as any,
          id: '',
          user_id: this.props.user_id,
          area_id: this.props.area_id ? this.props.area_id : '',
          end: '00:00',
          start: '00:00',
          breaks: [],
          role_id: this.props.role_id ? this.props.role_id : '',
          tags: [],
        }}
      />
    );
  };

  getSiteViewParams = (shift: Partial<ShiftTemplateItem>, dataset: any) => {
    const { user_id, area_id, role_id } = shift;
    return {
      id: shift.id,
      area_id: dataset.areaId ? dataset.areaId : area_id,
      role_id: dataset.roleId ? dataset.roleId : role_id,
      user_id: user_id,
      site_id: this.props.siteId,
      day: dataset.day,
      start: shift.start,
      end: shift.end,
      breaks: shift.breaks,
      tags: shift.tags,
      notes: shift.notes,
    };
  };

  handleDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    shift: Partial<ShiftTemplateItem>
  ) => {
    e.stopPropagation();
    this.dragEl = e.currentTarget;
    setTimeout(() => {
      if (this.dragEl) {
        this.dragEl.classList.add('hidden-card');
      }
    }, 0);
    e.dataTransfer.effectAllowed = 'move';
    this.props.setDraggableShift(shift);
    disableTooltip();
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  ...getAccountTree(state),
  shifts: getTemplateShifts(state),
  users: getUserListResponse(state),
  isBulkDelete: getIsBulkDeleteOpened(state),
  langPreferences: getLangPreferences(state),
  draggableShift: getDraggableTemplatedShift(state),
  dropInProgress: state.editTemplate.dropInProgress,
  dropError: state.editTemplate.dropError,
  dropCanceled: getDropCancelled(state),
  siteId: getTemplateSiteId(state),
  areasBySideId: areasBySiteIdSelector(state),
});

const mapToDispatchProps: DispatchProps = {
  updateRosteredShift: BOX_EDIT_TEMPLATE_DRAG_N_DROP,
  setDraggableShift: BOX_EDIT_TEMPLATE_SET_DRAGGABLE_SHIFT,
  openModal: BOX_EDIT_TEMPLATE_OPEN_EDIT_MODAL,
  deleteShift: BOX_EDIT_TEMPLATE_DELETE_CONFIRM_SHIFT_ITEM,
  toggleSelectedId: BOX_ROSTER_BULK_DELETION_MODE_TOGGLE_SELECTED_SHIFT,
  openRangeInput: BOX_SHIFT_TIME_RANGE_INPUT_OPENED,
};

export const ShiftTemplateRow = connect(
  mapStateToProps,
  mapToDispatchProps
)(ShiftTemplateRowClass);
