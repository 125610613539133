import {
  BravoTheme,
  ConfirmationModal,
  FlatAreaRoleModal,
  GlobalAlert,
  GlobalLoadingOverlay,
  NestedRoutes,
  ReAcceptConfirmationModal,
  ScrollToTop,
  SiteAreaRoleModal,
  ToastNotifier,
  WarningsModal,
  InitialisePendo,
} from 'element';
import { triggerTour } from 'helpers';
import { setLocale } from 'lib/helpers';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { routes } from 'routes';

class AppLayout extends Component<RouteComponentProps> {
  componentDidUpdate(prevProps: Readonly<RouteComponentProps>) {
    setLocale();
    triggerTour();
    if ((window as any).Appcues) {
      const {
        location: { pathname },
      } = this.props;
      const previousLocation = prevProps.location.pathname;
      if (pathname !== previousLocation) {
        (window as any).Appcues.page();
      }
    }
  }

  componentDidMount() {
    triggerTour();
  }

  render() {
    return (
      <>
        <InitialisePendo />
        <ScrollToTop />
        <BravoTheme />
        <GlobalAlert />
        <NestedRoutes routes={routes} />
        <ToastNotifier />
        <SiteAreaRoleModal />
        <FlatAreaRoleModal />
        <ReAcceptConfirmationModal />
        <WarningsModal />
        <ConfirmationModal />
        <GlobalLoadingOverlay />
      </>
    );
  }
}

export default withRouter(AppLayout);
