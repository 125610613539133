import React from 'react';
import { Card, Text } from 'elmo-elements';
import {
  AlternateEmailIcon,
  CakeOutlinedIcon,
  PhoneAndroidIcon,
} from 'element';
import ProfileImageModal from './components/ProfileImageModal';
import { StoreState } from 'state/types';
import {
  FormattedErrors,
  PreferencesDateFormat,
  PreferencesTimeFormat,
  UserProfileFields,
} from 'type/models';
import { connect } from 'react-redux';
import {
  BOX_USER_PROFILE_DETAILS_MODAL_CLOSE,
  BOX_USER_PROFILE_DETAILS_MODAL_OPEN,
  BOX_USER_PROFILE_REMOVE_ERRORS,
  BOX_USER_PROFILE_UPDATE_REQUEST,
  BOX_USER_PROFILE_UPLOAD_MODAL_CLOSE,
  BOX_USER_PROFILE_UPLOAD_MODAL_OPEN,
} from 'state/Users/UserProfile/actions';
import { getDateTimeFormatted } from 'lib/helpers';
import moment from 'moment';
import { getDateFormat, getTimeFormat } from 'state/Account';
import './details.scss';
import { EditButton } from '../EditButton';
import { DetailsModal } from './components/DetailsModal';
import { isHrService } from 'helpers/helpers';
import { fieldsToUpdate, hasDifference } from '../../helpers';
import { CardDetails } from './components/CardDetails';

type State = {
  avatar_url: string | null;
  profile: Partial<UserProfileFields>;
};

type StateProps = {
  userProfile: UserProfileFields;
  isOpened: boolean;
  isOpenedDetails: boolean;
  dateFormat: PreferencesDateFormat;
  timeFormat: PreferencesTimeFormat;
  isUpdating: boolean;
  errors: FormattedErrors;
};

type DispatchProps = {
  openUploadModal: () => void;
  closeUploadModal: () => void;
  openModal: () => void;
  closeModal: () => void;
  updateProfile: (details: Partial<UserProfileFields>) => void;
  clearErrors: () => void;
};

type Props = StateProps & DispatchProps;

export class UserDetailsComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { date_of_birth, gender, email, mobile, avatar_url } =
      props.userProfile;
    this.state = {
      avatar_url: avatar_url,
      profile: {
        date_of_birth: date_of_birth,
        gender: gender,
        email: email,
        mobile: mobile,
      },
    };
  }

  setDefaultState = (props: Props) => {
    const { date_of_birth, gender, email, mobile } = props.userProfile;
    this.setState({
      ...this.state,
      profile: {
        date_of_birth: date_of_birth,
        gender: gender,
        email: email,
        mobile: mobile,
      },
    });
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (this.props !== prevProps) {
      this.setState({
        ...this.state,
        avatar_url: this.props.userProfile.avatar_url,
      });
      if (
        prevProps.isOpenedDetails !== this.props.isOpenedDetails &&
        !this.props.isOpenedDetails
      ) {
        this.setDefaultState(this.props);
      }
    }
  }

  getUserInfo = () => {
    const {
      first_name,
      last_name,
      mobile,
      email,
      date_of_birth,
      gender,
      prefered_name,
    } = this.props.userProfile;

    const { dateFormat, timeFormat } = this.props;

    const { avatar_url } = this.state;

    return {
      fullName:
        prefered_name !== '' ? prefered_name : first_name + ' ' + last_name,
      mobile: mobile,
      email: email,
      dateOfBirth:
        date_of_birth !== null && date_of_birth
          ? getDateTimeFormatted(dateFormat, timeFormat, date_of_birth)
          : '',
      gender: gender,
      avatar: avatar_url !== null ? avatar_url : '',
      information: [
        {
          icon: <PhoneAndroidIcon />,
          data: <Text>{mobile !== '' ? mobile : '-'}</Text>,
        },
        {
          icon: <AlternateEmailIcon />,
          data: <a href={`mailto:${email}`}>{email}</a>,
        },
        {
          icon: <CakeOutlinedIcon />,
          data: (
            <>
              <Text>{this.getFieldValue(date_of_birth, true)}</Text>
              <br />
              <Text size={'sm'}>{this.getNumberOfYears()}</Text>
            </>
          ),
        },
        {
          icon: <div />,
          data: <Text>{gender}</Text>,
        },
      ],
    };
  };

  render() {
    const userInfo = this.getUserInfo();
    const option =
      isHrService('standalone') && this.props.userProfile.is_active ? (
        <EditButton onClick={this.props.openModal} ariaLabel="Edit" />
      ) : undefined;
    return (
      <Card
        className={'user-details-card'}
        id={'user-details-card'}
        isFullHeight={true}
        option={option}
      >
        <CardDetails
          fullName={userInfo.fullName}
          avatar={userInfo.avatar}
          information={userInfo.information}
          onAvatarClick={this.openProfileImageModal}
          editButton={this.props.userProfile.is_active}
        />
        <ProfileImageModal
          isOpened={this.props.isOpened}
          closeModal={this.closeModal}
        />
        <DetailsModal
          isOpened={this.props.isOpenedDetails}
          closeModal={this.props.closeModal}
          profile={this.state.profile}
          onDropDownChange={this.changeGenderValue}
          changeInput={this.changeInput}
          saveProfile={this.updateProfile}
          saveDisabled={
            !hasDifference(this.state.profile, this.props.userProfile)
          }
          isLoading={this.props.isUpdating}
          errors={this.props.errors}
          clearErrors={this.props.clearErrors}
        />
      </Card>
    );
  }

  changeGenderValue = (params: any) => {
    this.setState({
      ...this.state,
      profile: {
        ...this.state.profile,
        gender: params.value,
      },
    });
  };

  changeInput = (name: string, value: string | null) => {
    this.setState({
      ...this.state,
      profile: {
        ...this.state.profile,
        [name]: value,
      },
    });
  };

  updateProfile = () => {
    const { id } = this.props.userProfile;
    this.props.updateProfile({
      id: id,
      ...fieldsToUpdate(this.state.profile, this.props.userProfile),
    });
  };

  openProfileImageModal = () => {
    this.props.openUploadModal();
  };

  closeModal = () => {
    this.props.closeUploadModal();
  };

  getFieldValue = (value: string | null | boolean, isDate = false) => {
    const { dateFormat, timeFormat } = this.props;
    if (value === false) {
      return null;
    }
    if (isDate && value !== null && typeof value === 'string') {
      return getDateTimeFormatted(dateFormat, timeFormat, value);
    }
    return value !== null ? value : '-';
  };

  getNumberOfYears = () => {
    const dateOfBirth = this.props.userProfile.date_of_birth;
    const date = dateOfBirth !== null ? dateOfBirth : moment();
    return moment().diff(moment(date), 'years') + ' years old';
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  userProfile: state.userProfile.userProfile,
  isOpened: state.userProfile.uploadModal.isOpened,
  isOpenedDetails: state.userProfile.userDetails.isOpened,
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
  isUpdating: state.userProfile.isUpdating,
  errors: state.userProfile.errors,
});

const mapDispatchToProps: DispatchProps = {
  openUploadModal: BOX_USER_PROFILE_UPLOAD_MODAL_OPEN,
  closeUploadModal: BOX_USER_PROFILE_UPLOAD_MODAL_CLOSE,
  openModal: BOX_USER_PROFILE_DETAILS_MODAL_OPEN,
  closeModal: BOX_USER_PROFILE_DETAILS_MODAL_CLOSE,
  updateProfile: BOX_USER_PROFILE_UPDATE_REQUEST,
  clearErrors: BOX_USER_PROFILE_REMOVE_ERRORS,
};

export const UserDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDetailsComponent);
