import { SagaIterator } from 'redux-saga';
import {
  all,
  call,
  fork,
  put,
  PutEffect,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { flatMap } from 'lodash';
import nanoid from 'nanoid';
import {
  DeleteInstancePayload,
  SagaAction,
  SagaActionFromCreator,
  ShiftTrade,
  ShiftTradesList,
} from 'type';
import { deletedInstanceMessage, outDatedDataMessage } from 'messages';
import { privateRoutes } from 'routes';
import { Api } from 'lib/Api';
import browserHistory from 'lib/browserHistory';
import { formatError, getErrorStatus } from 'state/helpers';
import * as fetchPageData from 'state/FetchPageData';
import {
  BOX_TOAST_NOTIFIER_CLOSE,
  BOX_TOAST_NOTIFIER_MESSAGE_SHOW,
  showOutDatedNotification,
} from 'state/ToastNotifier';
import { processApiRequest } from 'state/ProcessApiRequest';
import * as isLoading from 'state/IsLoading';
import {
  AcceptTradePayload,
  DeclineByProposalPayload,
  GetSwapProposalsPayload,
  ShiftSwapPayload,
  ShiftTradesFilterPayload,
  SwapProposalsResponse,
} from '../types';
import * as actions from '../actions';
import {
  getChangedShiftTradeId,
  getCurrentShiftId,
  getFiltersSelectedFilter,
  getIsOutdated,
  getIsOutdatedValue,
  getSelectedFilter,
  getTradeById,
} from '../selectors';

const getShiftTrades = function* (): SagaIterator {
  try {
    const filter = yield select(getSelectedFilter);
    if (filter !== 'custom') {
      const response: ShiftTradesList = yield call(
        processApiRequest,
        Api.EmployeeDashboard.getShiftTrades,
        {
          view: filter,
        }
      );
      yield put(actions.BOX_SHIFT_TRADES_CLEAR_OUTDATED_STATE());
      yield put(actions.BOX_SHIFT_TRADES_GET_DATA_SUCCESS(response));
    }
  } catch (errors) {
    yield put(actions.BOX_SHIFT_TRADES_GET_DATA_FAILURE(formatError(errors)));

    throw errors;
  }
};

const getShiftTradesCustom = function*(
  payload: ShiftTradesFilterPayload
): SagaIterator {
  try {
    const response: ShiftTradesList = yield call(
      processApiRequest,
      Api.EmployeeDashboard.getShiftTrades,
      {
        view: payload
      }
    );
    yield put(actions.BOX_SHIFT_TRADES_CLEAR_OUTDATED_STATE());
    yield put(actions.BOX_SHIFT_TRADES_GET_DATA_SUCCESS(response));
  } catch (errors) {
    yield put(actions.BOX_SHIFT_TRADES_GET_DATA_FAILURE(formatError(errors)));
  }
};

const updateData = function*(): SagaIterator {
  yield put(
    isLoading.BOX_IS_LOADING_ON(isLoading.EMPLOYEE_DASHBOARD_SHIFT_TRADES)
  );

  try {
    type Filter = ReturnType<typeof getSelectedFilter>;
    type View = Exclude<Filter, 'custom'>;

    const filter: ReturnType<typeof getSelectedFilter> = yield select(
      getSelectedFilter
    );

    const view: View =
      filter === 'custom' ? yield select(getFiltersSelectedFilter) : filter;

    const response: ShiftTradesList = yield call(
      processApiRequest,
      Api.EmployeeDashboard.getShiftTrades,
      {
        view
      }
    );
    yield put(actions.BOX_SHIFT_TRADES_CLEAR_OUTDATED_STATE());
    yield put(actions.BOX_SHIFT_TRADES_UPDATE_SUCCESS(response));
  } catch (errors) {
    yield put(actions.BOX_SHIFT_TRADES_UPDATE_FAILURE(formatError(errors)));
  }

  yield put(
    isLoading.BOX_IS_LOADING_OFF(isLoading.EMPLOYEE_DASHBOARD_SHIFT_TRADES)
  );
};

export const getData = function*() {
  try {
    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_REQUEST(
        fetchPageData.EMPLOYEE_DASHBOARD_SHIFT_TRADES
      )
    );
    yield call(getShiftTrades);
    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_SUCCESS(
        fetchPageData.EMPLOYEE_DASHBOARD_SHIFT_TRADES
      )
    );
  } catch (error) {
    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_FAILURE(
        fetchPageData.EMPLOYEE_DASHBOARD_SHIFT_TRADES
      )
    );
  }
};

export const getDataCustom = function*(
  action: SagaActionFromCreator<typeof actions.BOX_SHIFT_TRADES_TOGGLE_TRADES_TYPE_CUSTOM_REQUEST>
) {
  try {
    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_REQUEST(
        fetchPageData.EMPLOYEE_DASHBOARD_SHIFT_TRADES
      )
    );
    yield call(getShiftTradesCustom, action.payload as any); // TODO double-check this functionality
    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_SUCCESS(
        fetchPageData.EMPLOYEE_DASHBOARD_SHIFT_TRADES
      )
    );
  } catch (error) {
    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_FAILURE(
        fetchPageData.EMPLOYEE_DASHBOARD_SHIFT_TRADES
      )
    );
  }
};

export const acceptTrade = function*({
  payload
}: SagaAction<AcceptTradePayload>): SagaIterator {
  try {
    const shift = yield select(getTradeById, payload.shift_trade_id);
    yield call(
      processApiRequest,
      Api.EmployeeDashboard.acceptShiftTrade,
      payload
    );
    yield put(
      actions.BOX_SHIFT_TRADES_ACCEPT_SHIFT_SUCCESS(payload.shift_trade_id)
    );
    yield put(BOX_TOAST_NOTIFIER_MESSAGE_SHOW('Accepted successfully'));
  } catch (errors) {
    yield put(
      actions.BOX_SHIFT_TRADES_ACCEPT_SHIFT_FAILURE(formatError(errors))
    );
  }
};

export const getSwapProposals = function*({
  payload
}: SagaAction<GetSwapProposalsPayload>): SagaIterator {
  try {
    yield put(actions.BOX_SHIFT_TRADES_SWAP_MODAL_OPEN(payload.id));
    const response: SwapProposalsResponse = yield call(
      processApiRequest,
      Api.EmployeeDashboard.getSwapProposals,
      payload
    );
    yield put(actions.BOX_SHIFT_TRADES_GET_SWAP_PROPOSALS_SUCCESS(response));
  } catch (e) {
    yield put(
      actions.BOX_SHIFT_TRADES_GET_SWAP_PROPOSALS_FAILURE(formatError(e))
    );
  }
};

export const swapShifts = function*({
  payload
}: SagaAction<ShiftSwapPayload>): SagaIterator {
  const { OVERLAP_ERROR_STATUS } = Api.RosteredShift;
  try {
    const response: SwapProposalsResponse = yield call(
      processApiRequest,
      Api.EmployeeDashboard.swapShifts,
      payload
    );
    yield put(actions.BOX_SHIFT_TRADES_SWAP_SUCCESS(payload.trade_id));
    yield put(actions.BOX_SHIFT_TRADES_SWAP_MODAL_CLOSE());
    yield put(BOX_TOAST_NOTIFIER_MESSAGE_SHOW('Swapped successfully'));
  } catch (e) {
    const errorStatus = getErrorStatus(e);
    if (errorStatus === OVERLAP_ERROR_STATUS) {
      yield put(
        actions.BOX_SHIFT_TRADES_CONFIRMATION_MODAL_OPEN(
          formatError(e)[0].split('\n')
        )
      );
    } else {
      yield put(actions.BOX_SHIFT_TRADES_SWAP_FAILURE(formatError(e)));
    }
  }
};

export const deleteShiftTrade = function*({
  payload
}: SagaAction<string>): SagaIterator {
  try {
    yield call(
      processApiRequest,
      Api.EmployeeDashboard.deleteShiftTrade,
      payload
    );
    yield put(actions.BOX_SHIFT_TRADES_DELETE_SHIFT_SUCCESS(payload));
    browserHistory.push(
      privateRoutes.employeeDashboard.routes.trades.routes.shiftTrades.path
    );
  } catch (e) {
    yield put(actions.BOX_SHIFT_TRADES_DELETE_SHIFT_FAILURE(formatError(e)));
  }
};

export const getShiftProposals = function*(): SagaIterator {
  try {
    let shiftID = yield select(getCurrentShiftId);
    const response: ShiftTradesList = yield call(
      processApiRequest,
      Api.EmployeeDashboard.getShiftProposals,
      shiftID
    );
    yield put(
      actions.BOX_SHIFT_TRADES_GET_SHIFT_PROPOSALS_SUCCESS(response[0])
    );
  } catch (errors) {
    yield put(
      actions.BOX_SHIFT_TRADES_GET_SHIFT_PROPOSALS_FAILURE(formatError(errors))
    );
  }
};

export const getListOfProposals = function*(): SagaIterator {
  try {
    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_REQUEST(
        fetchPageData.EMPLOYEE_DASHBOARD_SHIFT_TRADES_SWAP_PAGE
      )
    );
    yield call(getShiftProposals);
    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_SUCCESS(
        fetchPageData.EMPLOYEE_DASHBOARD_SHIFT_TRADES_SWAP_PAGE
      )
    );
  } catch (error) {
    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_FAILURE(
        fetchPageData.EMPLOYEE_DASHBOARD_SHIFT_TRADES_SWAP_PAGE
      )
    );
  }
};

export const selectProposal = function*({
  payload
}: SagaAction<string>): SagaIterator {
  try {
    const response: ShiftTradesList = yield call(
      processApiRequest,
      Api.EmployeeDashboard.select,
      payload
    );
    yield put(actions.BOX_SHIFT_TRADES_SELECT_PROPOSAL_SUCCESS(response[0]));
  } catch (errors) {
    yield put(
      actions.BOX_SHIFT_TRADES_SELECT_PROPOSAL_FAILURE(formatError(errors))
    );
  }
};

export const declineProposal = function*({
  payload
}: SagaAction<string>): SagaIterator {
  try {
    const response: ShiftTradesList = yield call(
      processApiRequest,
      Api.EmployeeDashboard.declineProposal,
      payload
    );
    yield put(actions.BOX_SHIFT_TRADES_DECLINE_PROPOSAL_SUCCESS(response[0]));
  } catch (errors) {
    yield put(
      actions.BOX_SHIFT_TRADES_DECLINE_PROPOSAL_FAILURE(formatError(errors))
    );
  }
};

export const declineShiftByProposal = function*({
  payload
}: SagaAction<DeclineByProposalPayload>): SagaIterator {
  try {
    yield call(
      processApiRequest,
      Api.EmployeeDashboard.declineProposal,
      payload.proposal_id
    );
    yield put(
      actions.BOX_SHIFT_TRADES_DECLINE_SHIFT_BY_PROPOSAL_SUCCESS(
        payload.trade_id
      )
    );
  } catch (errors) {
    yield put(
      actions.BOX_SHIFT_TRADES_DECLINE_SHIFT_BY_PROPOSAL_FAILURE(
        formatError(errors)
      )
    );
  }
};

const tradesListTradeChangedOrDeleted = function*(
  action: SagaAction<DeleteInstancePayload | ShiftTrade>
): SagaIterator {
  const shiftTradeIdChangedByCurrentUser: ReturnType<
    typeof getChangedShiftTradeId
  > = yield select(getChangedShiftTradeId);

  if (shiftTradeIdChangedByCurrentUser === action.payload.id) {
    yield put(actions.BOX_SHIFT_TRADES_SOCKET_UPDATE_CANCELLED());
    return;
  }

  const isOutdated: ReturnType<typeof getIsOutdatedValue> = yield select(
    getIsOutdatedValue,
    'list'
  );

  if (isOutdated) {
    return;
  }

  const {
    isFetching,
    isFetched
  }: ReturnType<typeof fetchPageData.getFetchFlags> = yield select(
    fetchPageData.getFetchFlags,
    fetchPageData.EMPLOYEE_DASHBOARD_SHIFT_TRADES
  );

  if (isFetched && !isFetching) {
    const notificationId = nanoid();

    yield put(
      actions.BOX_SHIFT_TRADES_SET_OUTDATED({
        page: 'list',
        value: {
          value: true,
          notificationId
        }
      })
    );

    const { undo } = yield call(showOutDatedNotification, {
      notificationId,
      message: outDatedDataMessage,
      undoText: 'Reload'
    });

    if (undo) {
      yield put(actions.BOX_SHIFT_TRADES_UPDATE_REQUEST());
    }
  }
};

const shiftSwapTradeChanged = function*({
  payload: shiftTrade
}: SagaAction<ShiftTrade>): SagaIterator {
  const currentShiftId: ReturnType<typeof getCurrentShiftId> = yield select(
    getCurrentShiftId
  );

  if (currentShiftId === shiftTrade.id) {
    yield put(actions.BOX_SHIFT_TRADES_SET_SHIFT_TO_SWAP(shiftTrade));
  }
};

const shiftSwapTradeDeleted = function*({
  payload: { id }
}: SagaAction<DeleteInstancePayload>): SagaIterator {
  const isOutdated: ReturnType<typeof getIsOutdatedValue> = yield select(
    getIsOutdatedValue,
    'shiftSwap'
  );

  if (isOutdated) {
    return;
  }

  const currentShiftId: ReturnType<typeof getCurrentShiftId> = yield select(
    getCurrentShiftId
  );

  if (currentShiftId === id) {
    const notificationId = nanoid();

    yield put(
      actions.BOX_SHIFT_TRADES_SET_OUTDATED({
        page: 'shiftSwap',
        value: {
          value: true,
          notificationId
        }
      })
    );

    const { undo } = yield call(showOutDatedNotification, {
      notificationId,
      message: deletedInstanceMessage({
        instance: 'shift swap',
        goToPage: 'shift trades'
      }),
      undoText: 'Go to Shift trades'
    });

    if (undo) {
      browserHistory.push(
        privateRoutes.employeeDashboard.routes.trades.routes.shiftTrades.path
      );
    }
  }
};

const clearOutdatedState = function*(): SagaIterator {
  type IsOutDated = ReturnType<typeof getIsOutdated>;

  const isOutDated: IsOutDated = yield select(getIsOutdated);

  yield all(
    flatMap(isOutDated, ({ notificationId, value }, page) => {
      const clearActions: PutEffect<any>[] = [
        put(
          actions.BOX_SHIFT_TRADES_SET_OUTDATED({
            page: page as keyof IsOutDated,
            value: {
              value: false,
              notificationId: ''
            }
          })
        )
      ];

      if (value) {
        clearActions.push(put(BOX_TOAST_NOTIFIER_CLOSE(notificationId)));
      }

      return clearActions;
    })
  );
};

export const watchShiftTradesList = function*(): SagaIterator {
  yield takeEvery(
    actions.BOX_SHIFT_TRADES_TRADE_CHANGED,
    tradesListTradeChangedOrDeleted
  );
  yield takeEvery(
    actions.BOX_SHIFT_TRADES_TRADE_DELETED,
    tradesListTradeChangedOrDeleted
  );
};

export const watchShiftSwap = function*(): SagaIterator {
  yield takeEvery(
    actions.BOX_SHIFT_TRADES_TRADE_CHANGED,
    shiftSwapTradeChanged
  );
  yield takeEvery(
    actions.BOX_SHIFT_TRADES_TRADE_DELETED,
    shiftSwapTradeDeleted
  );
};

export const watchOutdatedState = function*(): SagaIterator {
  yield takeLatest(
    actions.BOX_SHIFT_TRADES_CLEAR_OUTDATED_STATE,
    clearOutdatedState
  );
};

export const watchEmployeeShiftTrades = function*(): SagaIterator {
  yield fork(watchShiftTradesList);
  yield fork(watchShiftSwap);
  yield fork(watchOutdatedState);

  yield takeLatest(actions.BOX_SHIFT_TRADES_GET_DATA, getData);
  yield takeLatest(
    actions.BOX_SHIFT_TRADES_TOGGLE_TRADES_TYPE_REQUEST,
    getData
  );
  yield takeLatest(
    actions.BOX_SHIFT_TRADES_TOGGLE_TRADES_TYPE_CUSTOM_REQUEST,
    getDataCustom
  );
  yield takeLatest(actions.BOX_SHIFT_TRADES_ACCEPT_SHIFT_REQUEST, acceptTrade);
  yield takeLatest(
    actions.BOX_SHIFT_TRADES_GET_SWAP_PROPOSALS,
    getSwapProposals
  );
  yield takeLatest(actions.BOX_SHIFT_TRADES_SWAP_REQUEST, swapShifts);
  yield takeLatest(actions.BOX_SHIFT_TRADES_DELETE_SHIFT, deleteShiftTrade);
  yield takeLatest(
    actions.BOX_SHIFT_TRADES_GET_SHIFT_PROPOSALS,
    getListOfProposals
  );
  yield takeLatest(actions.BOX_SHIFT_TRADES_SELECT_PROPOSAL, selectProposal);
  yield takeLatest(actions.BOX_SHIFT_TRADES_DECLINE_PROPOSAL, declineProposal);
  yield takeLatest(
    actions.BOX_SHIFT_TRADES_DECLINE_SHIFT_BY_PROPOSAL,
    declineShiftByProposal
  );

  yield takeLatest(actions.BOX_SHIFT_TRADES_UPDATE_REQUEST, updateData);
};
