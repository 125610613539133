import React, { Component, ReactChild } from 'react';
import { connect } from 'react-redux';
import { Filter, LoadingOverlay } from 'elmo-elements';
import { StoreState } from 'state/types';
import {
  BOX_UNAVAILABILITY_CLEAR_FILTER_FORM,
  BOX_UNAVAILABILITY_TOGGLE_FILTER_TYPE_REQUEST,
  BOX_UNAVAILABILITY_UPDATE_CUSTOM_FILTERS_REQUEST,
  getIsUpdating,
  getSelectedFilter,
  isFiltersFormDisabledSelector,
  PredefinedFilters,
  SelectedFilter
} from 'state/ManagerDashboard/Unavailability';
import { CustomFilters } from './components/CustomFilters';

type StateProps = {
  selectedFilter: SelectedFilter;
  isUpdating: boolean;
  isFiltersFormDisabled: boolean;
};

type DispatchProps = {
  updateCustomFilters: () => void;
  clearCustomFilters: () => void;
  togglePredefinedFilter: (filterType: PredefinedFilters) => void;
};

type Props = StateProps & DispatchProps;

class UnavailabilityFiltersComponent extends Component<Props> {
  statuses: {
    label: string;
    value: PredefinedFilters;
  }[] = [
    {
      label: 'Pending',
      value: 'pending'
    },
    {
      label: 'Approved',
      value: 'approved'
    },
    {
      label: 'Declined',
      value: 'declined'
    },
    {
      label: 'All',
      value: 'all'
    }
  ];

  render() {
    const {
      updateCustomFilters,
      selectedFilter,
      isUpdating,
      isFiltersFormDisabled
    } = this.props;

    return (
      <LoadingOverlay isLoading={isUpdating} showSpinner={false}>
        <Filter
          onStatusChange={this.onStatusChange}
          statuses={this.statuses}
          renderCustomFilters={this.renderCustomFilters}
          onApplyCustomFilters={updateCustomFilters}
          onToggleCustomFilters={this.onToggleCustomFilters}
          selectedValue={selectedFilter}
          isApplyBtnDisabled={isFiltersFormDisabled}
        />
      </LoadingOverlay>
    );
  }

  private onStatusChange = (filterType: string) => {
    this.props.togglePredefinedFilter(filterType as PredefinedFilters);
  };

  private renderCustomFilters = (): ReactChild => <CustomFilters />;

  private onToggleCustomFilters = (isOpen: boolean) => {
    if (isOpen) {
      this.props.clearCustomFilters();
    }
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  selectedFilter: getSelectedFilter(state),
  isUpdating: getIsUpdating(state),
  isFiltersFormDisabled: isFiltersFormDisabledSelector(state)
});

const mapDispatchToProps: DispatchProps = {
  updateCustomFilters: BOX_UNAVAILABILITY_UPDATE_CUSTOM_FILTERS_REQUEST,
  clearCustomFilters: BOX_UNAVAILABILITY_CLEAR_FILTER_FORM,
  togglePredefinedFilter: BOX_UNAVAILABILITY_TOGGLE_FILTER_TYPE_REQUEST
};
export const UnavailabilityFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnavailabilityFiltersComponent);
