import React from 'react';
import { useSelector } from 'react-redux';
import { getIsApprovalModeEnabled } from 'state/Roster/Roster';
import { getSiteId } from 'state/RosteredShifts';
import { RosteredShiftModal } from 'element';

function RosterShiftModal() {
  const isApprovalModeEnabled = useSelector(getIsApprovalModeEnabled);
  const siteId = useSelector(getSiteId);

  if (isApprovalModeEnabled) {
    return null;
  }

  return <RosteredShiftModal siteId={siteId} updateRelatedData={true} />;
}

export default RosterShiftModal;
