import React, { Component } from 'react';
import { Modal, Button } from 'elmo-elements';
import { StoreState } from '../../state/types';
import {
  BOX_NOTIFICATIONS_LIST_CLEAR_ERRORS,
  BOX_NOTIFICATIONS_LIST_CLOSE_MODAL, BOX_NOTIFICATIONS_LIST_DELETE_ITEM,
  BOX_NOTIFICATIONS_LIST_GET_REQUEST, BOX_NOTIFICATIONS_LIST_MARK_READ
} from '../../state/NotificationsList/actions';
import { connect } from 'react-redux';
import {
  EmployeeNotificationsProps, NotificationDeletePayload,
  NotificationListType, NotificationMarkReadPayload,
  NotificationsListItem, NotificationsListRequestPayload
} from 'state/NotificationsList/types';
import NotificationsListView from './view';
import {
  getEmployeeNotificationsListItems,
  getManagerNotificationsListItems,
  getNotificationsIsFetching, getNotificationsListErrors
} from 'state/NotificationsList/selectors';
import { FormattedErrors, StringMap } from 'type';
import ErrorBox from 'element/ErrorBox';
import BulkActions from './components/BulkActions';

type StateProps = {
  employeeItems: StringMap<NotificationsListItem>;
  managerItems: StringMap<NotificationsListItem>;
  employee: EmployeeNotificationsProps;
  manager: EmployeeNotificationsProps;
  isFetching: boolean;
  errors: FormattedErrors;
  isDeleting: boolean;
};

type DispatchProps = {
  onClose: (type: NotificationListType) => void;
  getNotificationsList: (params: NotificationsListRequestPayload) => void;
  clearErrors: () => void;
  markAsRead: (params: NotificationMarkReadPayload) => void;
  deleteItem: (params: NotificationDeletePayload) => void;
};

type Props = {
  type: NotificationListType;
} & StateProps & DispatchProps;

export class NotificationsList extends Component<Props> {

  componentDidMount(): void {
    this.notificationsRequest();
  }

  render() {
    const { onClose, type, errors } = this.props;
    const { meta: { current_page, last_page } } = this.props[type];
    return (
      <Modal
        id="NotificationsCustomModal"
        type="side"
        isOpened={this.props[type].isOpened}
        closeModal={() => onClose(type)}
        title="Notifications"
      >
        <ErrorBox errors={errors} clearErrors={this.props.clearErrors} />
        {this.notificationView()}
        {current_page < last_page ? this.getButton() : null}
      </Modal>
    );
  }

  getButton = () => {
    const { isFetching } = this.props;
    return <Button
      isText={true}
      onClick={this.loadMore}
      isBlock={true}
      isDisabled={isFetching}
      isLoading={isFetching}
      className={'mt-2 load-more-btn'}
    >
      Load more
    </Button>;
  }

  notificationView = () => {
    const { type, employeeItems, managerItems } = this.props;
    const items = type === 'employee' ? employeeItems : managerItems;
    const unreadCount = this.props[type].meta.unread_count;
    const total = this.props[type].meta.total;
    return <div className={'notifications-list'}>
      <BulkActions type={type} unreadCount={unreadCount} total={total} />
      <NotificationsListView
        loadMore={this.loadMore}
        items={items}
        meta={this.props[type].meta}
        markAsRead={this.markAsRead}
        deleteItem={this.deleteItem}
        isDeleting={this.props.isDeleting}
        type={type}
      />
    </div>;
  }

  deleteItem = (id: string) => {
    this.props.deleteItem({
      id: id,
      type: this.props.type
    });
  }

  markAsRead = (id: string) => {
    this.props.markAsRead({
      id: id,
      type: this.props.type
    });
  }

  loadMore = () => {
    const { type } = this.props;
    const { meta } = this.props[type];
    this.notificationsRequest(meta.current_page + 1);
  }

  notificationsRequest = (page: number = 1) => {
    const { type } = this.props;
    const { meta } = this.props[type];
    this.props.getNotificationsList({
      type: type,
      perPage: meta.per_page,
      page: page
    });
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  employeeItems: getEmployeeNotificationsListItems(state),
  managerItems: getManagerNotificationsListItems(state),
  employee: state.notificationsList.employee,
  manager: state.notificationsList.manager,
  isFetching: getNotificationsIsFetching(state),
  errors: getNotificationsListErrors(state),
  isDeleting: state.notificationsList.isDeleting
});

const mapToDispatchProps: DispatchProps = {
  onClose: BOX_NOTIFICATIONS_LIST_CLOSE_MODAL,
  getNotificationsList: BOX_NOTIFICATIONS_LIST_GET_REQUEST,
  clearErrors: BOX_NOTIFICATIONS_LIST_CLEAR_ERRORS,
  markAsRead: BOX_NOTIFICATIONS_LIST_MARK_READ,
  deleteItem: BOX_NOTIFICATIONS_LIST_DELETE_ITEM
};

export default connect(mapStateToProps, mapToDispatchProps)(NotificationsList);