import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import { timeFormatSelector } from 'state/Account';
import { BaseOnChange, BaseProps } from '../types';
import { TimeInputBase } from '../TimeInputBase';
import { formatMoment } from '../formatMoment';
import { TimeRange } from './types';
import { BOX_SHIFT_TIME_RANGE_INPUT_LEGEND_OPENED } from 'state/Roster/RangeInput/ShiftTimeRangeInputLegend/actions';
import './style.scss';

export type OwnProps = BaseProps & {
  value: TimeRange | null;
  onChange: BaseOnChange<TimeRange>;
  onFocus?: (e: any) => void;
};

export type StateProps = {
  timeFormat: string;
};

export type DispatchProps = {
  openModal: () => void;
};

export type Props = OwnProps & StateProps & DispatchProps;
type State = {
  showInfo: boolean;
};

export class TimeRangeInputComponent extends React.Component<Props, State> {
  private baseRef = createRef<TimeInputBase<TimeRange | null>>();
  state = {
    showInfo: false
  };

  render() {
    let { onChange, ...restProps } = this.props;
    const { showInfo } = this.state;
    return (
      <>
        <TimeInputBase
          ref={this.baseRef}
          {...restProps}
          onChange={this.onChange}
          valueComparator={this.valueComparator}
          valueFormatter={this.valueFormatter}
          removeHint={this.hideTooltip}
          onKeyDown={this.onKeyDownHandler}
        />
        {
          <div
            className={`time-input-helper ${showInfo ? 'visible-hint' : ''}`}
            onClick={this.onClick}
          >
            <span>Please enter a valid format. See formats</span>
          </div>
        }
      </>
    );
  }

  onKeyDownHandler = (value: string) => {
    if (!value.length) {
      this.hideTooltip();
    }
  };

  hideTooltip = () => {
    this.setState({
      showInfo: false
    });
  };

  onClick = (e: any) => {
    this.hideTooltip();
    this.props.openModal();
  };

  focus() {
    const { current } = this.baseRef;

    if (current) {
      current.focus();
    }
  }

  private onChange = (userInput: string) => {
    const [startInput, endInput] = userInput.split('-');

    try {
      const { value, onChange, name, initialDayValue } = this.props;
      const { start: prevStart, end: prevEnd } = value || {
        start: null,
        end: null
      };

      const dividers = { hyphen: false };
      const start = formatMoment({
        userInput: startInput,
        prevValue: prevStart,
        initialDayValue,
        dividers
      });
      const end = formatMoment({
        userInput: endInput,
        prevValue: prevEnd,
        initialDayValue,
        dividers
      });

      onChange(
        {
          start,
          end
        },
        name
      );
    } catch (e) {
      this.setState(
        {
          showInfo: true
        },
        () => {
          this.focus();
        }
      );
    }
  };

  private valueComparator = (
    prevValue: TimeRange | null,
    value: TimeRange | null
  ) => {
    if (prevValue) {
      if (!value) {
        return false;
      }

      return this.compareValues(prevValue, value);
    }

    return !(value && !prevValue);
  };

  private compareValues = (prevValue: TimeRange, value: TimeRange): boolean =>
    prevValue.start.isSame(value.start) && prevValue.end.isSame(value.end);

  private valueFormatter = (value: TimeRange | null) => {
    const { timeFormat } = this.props;

    if (!value) {
      return '';
    }

    return `${value.start.format(timeFormat)} - ${value.end.format(
      timeFormat
    )}`;
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  timeFormat: timeFormatSelector(state)
});

const mapDispatchToProps: DispatchProps = {
  openModal: BOX_SHIFT_TIME_RANGE_INPUT_LEGEND_OPENED
};

export const TimeRangeInput = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(TimeRangeInputComponent);
