import React from 'react';
import { WorkOffOutlinedIcon, WorkOutlineIcon } from 'element';
import { Text, Tooltip } from 'elmo-elements';
import { ShiftCard } from '../ShiftCard/components/ShiftCard';
import './RosterUnavailabilityCell.scss';
import { isAppMarket } from '../../../../helpers';
import { TIME_FORMAT } from '../../../../lib/config';

type UnavailabilityProps = {
  type: 'leave' | 'unavailability';
  duration?: {
    from: string;
    to: string;
  };
  noPermissions?: boolean;
  options: {
    half_start: boolean;
    half_end: boolean;
  } | null;
  timeFormat?: string;
};

type RosteredShiftProps = {
  type: 'rostered_shift';
  duration: {
    from: string;
    to: string;
  };
  title: string;
  areaName: string;
  noPermissions?: boolean;
  timeFormat?: string;
};

type Props = UnavailabilityProps | RosteredShiftProps;

const isRosteredShiftProps = (props: Props): props is RosteredShiftProps =>
  props.type === 'rostered_shift';

export const RosterUnavailabilityCell = (props: Props) => {
  const unavailableMessage = `Employee is unavailable due to a 
  ${isAppMarket('uk') ? 'shift' : 'rostered shift'} on another site`;

  const tooltip = props.noPermissions ? unavailableMessage : undefined;

  const timeOffCard = () => {
    const time =
      props.timeFormat === TIME_FORMAT.hours_12
        ? '12:00 am - 11:59pm'
        : '00:00 - 23:59';
    return (
      <Text className={'timeoff-time'}>
        <WorkOffOutlinedIcon /> {time}
      </Text>
    );
  };

  return (
    <div className="elmo-roster-unavailability-cell">
      {isRosteredShiftProps(props) ? (
        <ShiftCard
          title={props.title}
          from={props.duration.from}
          to={props.duration.to}
          withoutShadow={true}
          tooltip={tooltip}
          id={'unknown-shift'}
          isApprovalMode={false}
          isBulkDelete={false}
          spotTitle={props.areaName}
          colorId={null}
          noContextualMenu={true}
        />
      ) : (
        <div className="elmo-roster-unavailability-cell__card">
          {props.noPermissions && props.duration && (
            <Text className={'timeoff-time'}>
              <WorkOutlineIcon />
              {props.duration.from}-{props.duration.to}
            </Text>
          )}
          {!props.noPermissions &&
            (props.duration ? (
              <Text className={'timeoff-time'}>
                <WorkOffOutlinedIcon />
                {props.duration.from}-{props.duration.to}
              </Text>
            ) : (
              timeOffCard()
            ))}
        </div>
      )}
    </div>
  );
};
