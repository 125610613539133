import { LoadingOverlay, Select, Text } from 'elmo-elements';
import React, { useEffect, useState } from 'react';
import {
  BOX_TIMESHEETS_CLEAR_ROSTERED_SHIFTS,
  BOX_TIMESHEETS_LOAD_ROSTERED_SHIFTS_REQUEST, getIsLoadingShifts, getRosteredShiftsWithoutTimesheets
} from '../../../../../../../../state/Timesheets/Timesheets';
import { useDispatch, useSelector } from 'react-redux';
import { RosteredShift } from '../../../../../../../../type';
import { SelectPropsOption } from 'elmo-elements/Select/type';
import useRosteredShiftOptions from './hooks/useRosteredShiftOptions';

type OwnProps = {
  userId: string,
  onChange: (shift: RosteredShift | null) => void,
  isEditing: boolean
};

type Props = OwnProps;

export default function RosteredShiftsSelector(props: Props) {
  const dispatch = useDispatch();
  const rosteredShifts = useSelector(getRosteredShiftsWithoutTimesheets);
  const isLoadingShifts = useSelector(getIsLoadingShifts);
  const {
    userId,
    onChange,
    isEditing
  } = props;

  const defaultOption = {
    label: 'Available shifts',
    value: ''
  };
  const shiftsOptions = useRosteredShiftOptions(rosteredShifts);
  const [selectedShift, setSelectedShift] = useState<SelectPropsOption>(defaultOption);

  useEffect(() => {
    if (!!userId) {
      dispatch(BOX_TIMESHEETS_LOAD_ROSTERED_SHIFTS_REQUEST(userId));
    }
    return () => {
      dispatch(BOX_TIMESHEETS_CLEAR_ROSTERED_SHIFTS());
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (rosteredShifts.length === 1) {
      setSelectedShift(shiftsOptions[1]);
      onChange(rosteredShifts[0]);
    } else {
      setSelectedShift(defaultOption);
    }
  }, [rosteredShifts]);

  useEffect(() => {
      const shift = rosteredShifts.find((shift) =>
        shift.id === selectedShift.value) as RosteredShift;
      onChange(shift ? shift : null);
  }, [selectedShift]);

  useEffect(() => {
    if (isEditing && !!selectedShift.value.length) {
      setSelectedShift(defaultOption);
    }
  }, [isEditing]);

  return <>
    <Text
      size={'xs'}
      color={'gray'}
      id={'rostered-shifts-label'}
    >
      Fill timesheet using Roster data
    </Text>
    <LoadingOverlay isLoading={isLoadingShifts} tabIndex={-1}>
      <Select
        id={'rostered-shifts-dropdown'}
        name={'rostered_shifts-dropdown'}
        options={shiftsOptions}
        onChange={setSelectedShift}
        ariaLabel={'Rostered shifts'}
        isDisabled={!userId || shiftsOptions.length <= 1}
        placeholder="Please select"
        value={selectedShift}
      />
    </LoadingOverlay>

  </>
}