import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { StoreState } from '../../../../state/types';
import { AccountTreeSelector } from '../../../../type';
import { getAccountTree } from '../../../../state/AccountTree';
import { getUnavailabilityData } from './helpers';
import { UnavailabilityData } from './types';

type OwnProps = {
  children: (payload: UnavailabilityData) => ReactNode;
  area_id: string;
  role_id: string;
};

type StateProps = {
  tree: AccountTreeSelector;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

const WithRosterUnavailabilityDataComponent = (props: Props) => (
  <>{props.children(getUnavailabilityData(props))}</>
);

const mapStateToProps = (state: StoreState): StateProps => ({
  tree: getAccountTree(state)
});

// const mapDispatchToProps: DispatchProps = {};

export const WithRosterUnavailabilityData = connect(mapStateToProps)(
  WithRosterUnavailabilityDataComponent
);
