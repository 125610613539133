import React from 'react';
import { periods } from 'state/Report/reportModalValues';
import { Select, FormItem, FormContainer } from 'elmo-elements';
import { Data, DropDownProps } from '../type';
import { DateInputMoment } from 'element';
import { getSelectedValue } from '../helpers';
import moment from 'moment';
import { PreferencesDateFormat } from 'type/models';
import { getDateFormat } from 'state/Account';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';

export type PeriodProps = {
  handleOnChangeSelect: (e: DropDownProps, name: string) => void;
  handleChangePickers: (e: any, name: string) => void;
  data: Data;
};
type StateProps = {
  dateFormat: PreferencesDateFormat;
};

export class PeriodGroupRoster extends React.Component<
  PeriodProps & StateProps
> {
  periods = periods.filter(
    ({ value }) =>
      value !== 'today' && value !== 'tomorrow' && value !== 'yesterday'
  );

  render() {
    return (
      <>
        <h3>Date range</h3>
        <FormContainer maxColumns={3} className="flex-start-align">
          <FormItem label={'Period'} isFluid={false} isBlock={true}>
            <Select
              name="period"
              options={this.periods}
              defaultValue={getSelectedValue(
                this.periods,
                this.props.data.period
              )}
              onChange={(e) => this.handleOnChangeSelect(e, 'period')}
              value={getSelectedValue(this.periods, this.props.data.period)}
              ariaLabel="Period"
            />
          </FormItem>

          <FormItem label={'From'} isFluid={false}>
            <DateInputMoment
              id={'start-date'}
              className={'bg-white'}
              name={'date_from'}
              onChange={(e: any) => this.handleChangePickers(e, 'date_from')}
              value={moment(this.props.data.date_from)}
              placeholder={this.props.dateFormat}
              disabledDate={this.disabledDatesFrom}
              isClearable={false}
              ariaLabel="Date from"
            />
          </FormItem>

          <FormItem label={'To'} isFluid={false}>
            <DateInputMoment
              id={'end-date'}
              className={'bg-white'}
              name={'date_to'}
              onChange={(e: any) => this.handleChangePickers(e, 'date_to')}
              value={moment(this.props.data.date_to)}
              placeholder={this.props.dateFormat}
              disabledDate={this.disabledDatesTo}
              isClearable={false}
              ariaLabel="Date to"
            />
          </FormItem>
        </FormContainer>
      </>
    );
  }

  private handleOnChangeSelect = (e: DropDownProps, name: string) => {
    this.props.handleOnChangeSelect(e, name);
  };

  private handleChangePickers = (e: any, name: string) => {
    this.props.handleChangePickers(e, name);
  };

  private disabledDatesTo = (current?: Date): boolean => {
    if (
      current &&
      this.props.data.date_from !== null &&
      this.props.data.date_from
    ) {
      return moment(current) < moment(this.props.data.date_from);
    }

    return false;
  };

  private disabledDatesFrom = (current?: Date): boolean => {
    if (
      current &&
      this.props.data.date_to !== null &&
      this.props.data.date_to
    ) {
      return moment(current) > moment(this.props.data.date_to);
    }

    return false;
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  dateFormat: getDateFormat(state),
});

export default connect(mapStateToProps)(PeriodGroupRoster);
