import { SagaAction } from '../../type';
import { SagaIterator } from 'redux-saga';
import { CreatePasswordPayload, ResetPasswordPayload } from './types';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import Api from 'lib/Api';
import { formatError } from '../helpers';
import browserHistory from 'lib/browserHistory';

export const resetPassword = function* ({
  payload,
}: SagaAction<ResetPasswordPayload>): SagaIterator {
  try {
    yield call(Api.Auth.Password.send, payload);
    yield put(actions.BOX_RESET_PASSWORD_SUCCESS());
  } catch (error) {
    yield put(actions.BOX_RESET_PASSWORD_FAILURE(formatError(error)));
  }
};

export const createPassword = function* ({payload}: SagaAction<CreatePasswordPayload>): SagaIterator {
  try {
    yield call(Api.Auth.Password.reset, payload);
    yield put(actions.BOX_RESET_CREATE_PASSWORD_SUCCESS());
    browserHistory.push('/login');
  } catch (error) {
    yield put(actions.BOX_RESET_CREATE_PASSWORD_FAILURE(formatError(error)))
  }
};

export const watchReset = function*(): SagaIterator {
  yield takeLatest(actions.BOX_RESET_PASSWORD_REQUEST, resetPassword);
  yield takeLatest(actions.BOX_RESET_CREATE_PASSWORD_REQUEST, createPassword);
};
