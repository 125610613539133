import { HelpOutlineIcon } from 'element';
import { bindPopover, bindTrigger, usePopupState } from 'hooks';
import { capitalize } from 'lib/helpers';
import marketLabels from 'marketLabels';
import {
  Box,
  Button,
  IconButton,
  Popover,
  Typography,
} from 'extended-oxygen-elements';
import React from 'react';

export default InfoIconButton;

export function InfoIconButton() {
  const popupState = usePopupState();

  const handleCloseBtnClick = () => {
    popupState.close();
  };

  return (
    <>
      <IconButton {...bindTrigger(popupState)}>
        <HelpOutlineIcon />
      </IconButton>

      <Popover {...bindPopover(popupState)} placement="top">
        <Box p={3} maxWidth={540}>
          <Typography variant="h6">
            Example 1: Employee missing the shift start time
          </Typography>

          <Typography>
            John, is scheduled for 9 am {marketLabels.rosteredShift}. He arrives
            at 9:15am to the workplace. John is required to manually add a
            timesheet. By having 30 min gap in Breathe{' '}
            {capitalize(marketLabels.roster)}, John is giving until 9:30 am to
            enter a timesheet manually. Breathe{' '}
            {capitalize(marketLabels.roster)} will automatically associate any
            timesheet entered between 9 am to 9:30am to John’s 9am{' '}
            {marketLabels.rosteredShift}.
          </Typography>

          <br />

          <Typography variant="h6">
            Example 2: Not having a {marketLabels.rosteredShift} assigned
          </Typography>

          <Typography>
            Barbara does not have an assigned {marketLabels.rosteredShift},
            however, last minute she’s required to work. Barbara clocks-in using
            the Breathe companion mobile app or the Time Clock feature in
            Breathe {capitalize(marketLabels.roster)}, which automatically
            creates a timesheet in Breathe {capitalize(marketLabels.roster)}.
          </Typography>

          <br />

          <Typography>
            Barbara’s manager has the option to create a{' '}
            {marketLabels.rosteredShift} so that the timesheet is associated to
            it. If Breathe {capitalize(marketLabels.roster)} is set to have a 30
            min threshold, the system will associate a newly entered{' '}
            {marketLabels.rosteredShift} to Barbara’s clock on time.
          </Typography>

          <Box display="flex" justifyContent="flex-end" pt={3}>
            <Button variant="contained" onClick={handleCloseBtnClick}>
              Close
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
}
