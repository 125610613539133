import { AlertErrorBoxDialog } from 'element/index';
import { useActions } from 'hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  BOX_ACCOUNT_TREE_DIALOG_ERRORS_CLEARED,
  getDialogErrors,
} from 'state/AccountTree';

export function PageDialogErrors() {
  const dialogErrorsCleared = useActions(
    BOX_ACCOUNT_TREE_DIALOG_ERRORS_CLEARED
  );
  const errors = useSelector(getDialogErrors);

  const handleErrorsClose = () => {
    dialogErrorsCleared();
  };

  return (
    <AlertErrorBoxDialog
      BoxProps={{
        mb: 2,
      }}
      errors={errors}
      onClose={handleErrorsClose}
    />
  );
}
