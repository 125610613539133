import React from 'react';
import { replaceErrorText } from 'lib/helpers';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from 'extended-oxygen-elements';
import { PageDialog, PageDialogCancel, PageDialogSubmit } from 'element';

export type ConfirmationModalProps = {
  isUpdating: boolean;
  isOpened: boolean;
  onSubmit: () => void;
  onClose: () => void;
  message: string[];
};

export const UnavailabilityConfirmationModal = ({
  isOpened,
  isUpdating,
  onClose,
  onSubmit,
  message,
}: ConfirmationModalProps) => (
  <PageDialog
    maxWidth={'xs'}
    open={isOpened}
    id="unavailability-confirmation-modal"
  >
    <DialogTitle>Approve request?</DialogTitle>
    <DialogContent>
      {replaceErrorText(message).map((error: string, index: number) => (
        <Typography variant={'body1'} key={index}>
          {error}
        </Typography>
      ))}
    </DialogContent>
    <DialogActions>
      <PageDialogCancel onClick={onClose}>Cancel</PageDialogCancel>
      <PageDialogSubmit
        onClick={onSubmit}
        loading={isUpdating}
        disabled={isUpdating}
      >
        Yes, approve
      </PageDialogSubmit>
    </DialogActions>
  </PageDialog>
);
