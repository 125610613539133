import React from 'react';
import { Minutes } from 'type';

type ShiftBreak = { paid: boolean | null; duration: Minutes };

export const useUnpaidBreaksDuration = (breaks: ShiftBreak[]) =>
  React.useMemo(
    () => breaks.filter(({ paid }) => !paid).reduce(sumCallback, 0),
    [breaks]
  );

export const usePaidBreaksDuration = (breaks: ShiftBreak[]) =>
  React.useMemo(
    () => breaks.filter(({ paid }) => paid).reduce(sumCallback, 0),
    [breaks]
  );

export const useBreaksDuration = (
  breaks: {
    duration: Minutes;
  }[]
) => React.useMemo(() => breaks.reduce(sumCallback, 0), [breaks]);

function sumCallback(sum: number, { duration }: { duration: number }): number {
  return sum + duration;
}
