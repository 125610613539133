import { SagaIterator } from 'redux-saga';
import { all, fork, takeLatest } from 'redux-saga/effects';
import * as watchSagas from './sagas';

import { BOX_TIMEZONES_REQUEST } from './Misc';
import * as misc from './Misc/sagas';

import {
  BOX_PREFERENCES_PROFILE_REQUEST,
  BOX_PREFERENCES_RESET_PIN_REQUEST,
  BOX_PREFERENCES_UPDATE_PROFILE_REQUEST,
} from './Preferences';
import * as preferences from './Preferences/sagas';

/**
 * Root saga that connect sagas with actions.
 */
export default function* rootSaga(): SagaIterator {
  yield all(Object.values(watchSagas).map((sagaToFork) => fork(sagaToFork)));
  yield takeLatest(BOX_TIMEZONES_REQUEST, misc.getTimezones);
  yield takeLatest(BOX_PREFERENCES_PROFILE_REQUEST, preferences.getData);
  yield takeLatest(
    BOX_PREFERENCES_UPDATE_PROFILE_REQUEST,
    preferences.updateProfile
  );
  yield takeLatest(BOX_PREFERENCES_RESET_PIN_REQUEST, preferences.updatePin);
}
