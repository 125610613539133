import React from 'react';
import { connect } from 'react-redux';
import { LanguagePreferences } from 'type';
import { capitalize } from 'lib/helpers';
import { StoreState } from 'state/types';
import { getLangPreferences } from 'state/Account';
import {
  BOX_SITE_AREA_ROLE_MODAL_CLOSE,
  BOX_SITE_AREA_ROLE_MODAL_SUBMIT,
  BOX_SITE_AREA_ROLE_TOGGLE,
  getIsOpened,
  getIsResetButtonEnabled,
  getSelectedRoleTriples,
  getSiteId,
  roleTriplesSelector,
  RoleTriplesSelector,
  SelectedRoleTriples,
  SubmitPayload,
  TogglePayload,
} from 'state/SiteAreRoleModal';
import { NestedCheckbox } from './components';
import { DialogTitle } from 'oxygen-elements';
import { PageDialog, PageDialogCancel, PageDialogSubmit } from 'element';
import { DialogContent, Button, DialogActions } from 'extended-oxygen-elements';

type OwnProps = {};

type StateProps = {
  isOpened: boolean;
  isResetButtonEnabled: boolean;
  selectedRoleTriples: SelectedRoleTriples;
  langPreferences: LanguagePreferences;
  triples: RoleTriplesSelector;
  isSingleSiteShown: boolean;
};

type DispatchProps = {
  closeModal: () => void;
  onSubmit: (payload: SubmitPayload) => void;
  onChange: (payload: TogglePayload) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const SiteAreaRoleModalComponent = ({
  isOpened,
  isResetButtonEnabled,
  closeModal,
  langPreferences,
  triples,
  onSubmit,
  onChange,
  selectedRoleTriples,
  isSingleSiteShown,
}: Props) => {
  const onNestedCheckboxChange = (ids: string[], isChecked: boolean) => {
    onChange({ ids, isChecked });
  };

  const handleSubmit = () => {
    onSubmit(selectedRoleTriples);
  };

  const handleReset = () => {
    onSubmit(null);
  };

  const siteLabel = capitalize(langPreferences.site.singular) + '/';
  const areaLabel = capitalize(langPreferences.area.singular) + '/';
  const roleLabel = capitalize(langPreferences.role.singular);
  const title = `Filter by ${
    isSingleSiteShown ? '' : siteLabel
  }${areaLabel}${roleLabel}`;

  return (
    <PageDialog
      id="site-area-role-filter-modal"
      maxWidth="xs"
      open={isOpened}
      onClose={closeModal}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <NestedCheckbox
          data={triples}
          isIndented={false}
          onChange={onNestedCheckboxChange}
        />

        <Button
          disabled={!isResetButtonEnabled}
          onClick={handleReset}
          id="site-area-role-filter-modal-reset-btn"
        >
          Reset
        </Button>
      </DialogContent>
      <DialogActions>
        <PageDialogCancel onClick={closeModal}>Cancel</PageDialogCancel>
        <PageDialogSubmit onClick={handleSubmit}>Ok</PageDialogSubmit>
      </DialogActions>
    </PageDialog>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  isOpened: getIsOpened(state),
  isResetButtonEnabled: getIsResetButtonEnabled(state),
  selectedRoleTriples: getSelectedRoleTriples(state),
  langPreferences: getLangPreferences(state),
  triples: roleTriplesSelector(state),
  isSingleSiteShown: !!getSiteId(state),
});

const mapDispatchToProps: DispatchProps = {
  onSubmit: BOX_SITE_AREA_ROLE_MODAL_SUBMIT,
  closeModal: BOX_SITE_AREA_ROLE_MODAL_CLOSE,
  onChange: BOX_SITE_AREA_ROLE_TOGGLE,
};

export const SiteAreaRoleModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(SiteAreaRoleModalComponent);
