import {
  Box,
  BoxProps,
  Divider,
  Drawer,
  drawerClasses,
  Hidden,
  styled,
  Theme,
  Visible,
} from 'extended-oxygen-elements';
import { SIDE_MENU_WIDTH } from 'lib/config';
import React from 'react';
import AppBarShape from './AppBarShape';
import { DESKTOP_BREAKPOINT } from './config';
import { useLayoutContext } from './layout-context';

const SidebarRoot = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up(DESKTOP_BREAKPOINT)]: {
    width: SIDE_MENU_WIDTH,
  },
}));

const DrawerMobile = styled(Drawer)(({ theme }) => ({
  [`& .${drawerClasses.paper}`]: {
    width: SIDE_MENU_WIDTH,
    backgroundColor: theme.palette.primary.main,
  },
}));

const DrawerDesktop = styled(DrawerMobile)(({ theme }) => ({
  [`& .${drawerClasses.paper}`]: {
    zIndex: theme.zIndex.appBar - 1,
  },
}));

export type SidebarProps = BoxProps;

export default function Sidebar({ children, classes, ...boxProps }: SidebarProps) {
  const { mobileOpen, setMobileOpen } = useLayoutContext();

  const handleMobileDrawerClose = () => {
    setMobileOpen(false);
  };

  const drawer = (
    <div>
      <AppBarShape />

      <Divider />

      {children}
    </div>
  );

  return (
    <SidebarRoot {...boxProps}>
      <Hidden up={DESKTOP_BREAKPOINT}>
        <DrawerMobile
          variant="temporary"
          open={mobileOpen}
          onClose={handleMobileDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </DrawerMobile>
      </Hidden>

      <Visible up={DESKTOP_BREAKPOINT}>
        <DrawerDesktop variant="permanent" open>
          {drawer}
        </DrawerDesktop>
      </Visible>
    </SidebarRoot>
  );
}
