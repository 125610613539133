import { createReducer } from 'lib/store-utils';
import { WarningsReducerState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';

export const warnings = createReducer<WarningsReducerState>(
  {},
  getDefaultState()
);

warnings.on(
  actions.BOX_WARNINGS_OPEN_MODAL,
  (state, messages): WarningsReducerState => ({
    ...state,
    isOpened: true,
    messages
  })
);

warnings.on(
  actions.BOX_WARNINGS_CLOSE_MODAL,
  (state): WarningsReducerState => ({
    ...state,
    isOpened: false
  })
);
