import React from 'react';
import { hasPermissionSelector } from 'state/Auth';
import { useSelectorWithProps } from 'hooks';
import {
  ActionButtonProps,
  CreateButton,
  DeleteButton,
  EditButton,
  UnapproveButton,
} from './components';

export function ApprovedActions(props: ActionButtonProps) {
  const approvePermission = useSelectorWithProps(
    hasPermissionSelector,
    'roster.timesheet.edit.approve'
  );
  const editOnceApprovedPermission = useSelectorWithProps(
    hasPermissionSelector,
    'roster.timesheet.edit.onceapproved'
  );

  return (
    <>
      {editOnceApprovedPermission && <DeleteButton {...props} />}

      {editOnceApprovedPermission && <EditButton {...props} />}

      {approvePermission && <UnapproveButton {...props} />}

      <CreateButton {...props} />
    </>
  );
}
