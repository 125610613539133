import { Moment } from 'moment';

const formatStartEndCreator = ({
  day,
  timeFormat
}: {
  day: Moment;
  timeFormat: string;
}) => (minutes: number) =>
  day
    .clone()
    .startOf('day')
    .add(minutes, 'minutes')
    .format(timeFormat);

export function useMarkers(props: {
  startTimeMins: number;
  endTimeMins: number;
  timeFormat: string;
  day: Moment;
}) {
  const formatStartEnd = formatStartEndCreator(props);

  const left = formatStartEnd(props.startTimeMins);
  const right = formatStartEnd(props.endTimeMins);

  return { left, right };
}
