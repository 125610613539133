import React, { Component, ReactNode } from 'react';
import clsx from 'clsx';
import { Button } from 'elmo-elements';
import { PlaylistAddIcon } from 'element';
import './RosterUserLegend.scss';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import { BOX_ROSTER_BULK_TOGGLE_SELECTION_BY_USER } from 'state/Roster/BulkActions/actions';

export type Duration = {
  icon: ReactNode;
  quantity: number;
  time: string;
  isVisible: boolean;
};

type StateProps = {
  bulkDeleteIsActive: boolean;
};

type DispatchProps = {
  toggleSelectionByUser: (userId: string | null) => void;
};

type Props = {
  type?: 'single-row' | 'multi-row' | 'always-single-row';
  icon: ReactNode;
  label: string;
  durations: Duration[];
  onClick?: () => void;
  isDisabled?: boolean;
  bulkSelect?: boolean;
  userId?: string | null;
  pageType: 'week' | 'day';
} & StateProps &
  DispatchProps;

type State = {
  isOpened: boolean;
};

export class RosterUserLegendComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpened: false,
    };
  }

  toggleFullInfo = () => {
    this.setState({
      isOpened: !this.state.isOpened,
    });
  };

  onClickHandler = () => {
    const { bulkSelect, bulkDeleteIsActive, userId } = this.props;
    if (bulkSelect && bulkDeleteIsActive && typeof userId !== 'undefined') {
      this.props.toggleSelectionByUser(userId);
    }
  };

  render() {
    const {
      durations,
      type = 'single-row',
      icon,
      onClick,
      label,
      isDisabled,
    } = this.props;
    const { isOpened } = this.state;

    const visibleDurations = durations.filter((duration) => duration.isVisible);

    return (
      <div
        onClick={this.onClickHandler}
        className={clsx([
          'elmo-roster-user-legend',
          'ui-roster-legend',
          `elmo-roster-user-legend--${type}`,
          isDisabled && 'is-disabled',
          !visibleDurations.length && 'elmo-roster-user-legend--no-label',
        ])}
      >
        <div
          className="elmo-roster-user-legend__mobile-card-placeholder"
          onClick={this.toggleFullInfo}
        >
          <div className="elmo-roster-user-legend__icon-wrapper">{icon}</div>
        </div>

        <div
          className={clsx([
            'elmo-roster-user-legend__desktop-card-placeholder',
            isOpened && 'is-opened',
            isDisabled && 'is-disabled',
          ])}
          onClick={this.toggleFullInfo}
        >
          <div className="elmo-roster-user-legend__icon-wrapper">{icon}</div>

          <div className="elmo-roster-user-legend__content-wrapper">
            <div className="elmo-roster-user-legend__label" title={label}>
              {label}
            </div>
            {durations.map(
              ({ time, quantity, icon: durationIcon, isVisible }, key) => (
                <div
                  className="elmo-roster-user-legend__duration"
                  key={key}
                  style={{
                    display: isVisible ? 'flex' : 'none',
                  }}
                >
                  <div>{durationIcon}</div>

                  <div className="elmo-roster-user-legend__duration-quantity">
                    {quantity}
                  </div>

                  <div className="elmo-roster-user-legend__duration-time">
                    {time}
                  </div>
                </div>
              )
            )}
          </div>

          {this.props.pageType === 'day' && !!onClick && (
            <Button
              className="add-empty-roster-row-button"
              icon={<PlaylistAddIcon />}
              isText={true}
              onClick={onClick}
              ariaLabel="Add row"
              tooltip={{
                title: 'Add row',
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  bulkDeleteIsActive: state.rosterBulkActions.bulkDelete.isOpened,
});

const mapToDispatchProps: DispatchProps = {
  toggleSelectionByUser: BOX_ROSTER_BULK_TOGGLE_SELECTION_BY_USER,
};

export const RosterUserLegend = connect(
  mapStateToProps,
  mapToDispatchProps
)(RosterUserLegendComponent);
