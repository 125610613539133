import { useSelector } from 'react-redux';
import { useSelectorWithProps } from 'hooks';
import { getEventSafe } from 'state/Events';
import { getEventSettings } from 'state/Account';

type Props = {
  event_id: string | null;
};

const useEventName = ({ event_id }: Props): string | undefined => {
  const event = useSelectorWithProps(getEventSafe, event_id);
  const { show_event_name_boxes, enable_events } =
    useSelector(getEventSettings);

  if (show_event_name_boxes && enable_events) {
    return event?.name;
  }
};

export default useEventName;
