import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RosteredShift } from 'type/models';
import { InfoOutlinedIcon } from 'element';
import { BOX_ROSTER_CONTEXTUAL_MENU_SET_CLIPBOARD } from 'state/Roster/ContextualMenu/actions';

type StateProps = {};

type DispatchProps = {
  clearClipboard: (p: RosteredShift | null) => void;
}

type Props = StateProps & DispatchProps;

export const ClipboardIcon = ({ clearClipboard }: Props) => {

  const handleKeyDow = (e: KeyboardEvent) => {
    const { key } = e;
    if (key === 'Escape') {
      clearClipboard(null);
    }
  };

  const addEscKeyListener = () => {
    document.addEventListener('keydown', handleKeyDow);
  }

  useEffect(() => {
    addEscKeyListener();
    return () => {
      document.removeEventListener('keydown', handleKeyDow, true);
    }
  });

  return (
    <div className={'clipboard-icon'}><InfoOutlinedIcon /></div>
  );

};

const mapToDispatchProps: DispatchProps = {
  clearClipboard: BOX_ROSTER_CONTEXTUAL_MENU_SET_CLIPBOARD
};

export default connect(null, mapToDispatchProps)(ClipboardIcon)
