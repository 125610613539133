import { createSelector } from 'reselect';
import { RosteredShift, Timesheet, ShiftTemplateItem } from 'type';
import { StoreState } from 'state/types';
import { activeAreasBySiteIdSelector, getAreas, getRoles, getSites } from 'state/AccountTree';
import { getUserListResponse } from 'state/UsersCollection';
import { getOverlapModalProps, getSiteId } from 'state/RosteredShifts';
import { ContextualMenuAction } from './types';
import { getTemplateSiteId } from '../EditTemplate/selectors';
import { getDateFormat, getTimeFormat } from '../../Account';
import moment from 'moment-timezone';

export const getObjectId = (state: StoreState): string | null | undefined =>
  state.rosterContextualMenu.objectId;

export const getClipboard = (state: StoreState): RosteredShift | ShiftTemplateItem | null =>
  state.rosterContextualMenu.clipboard;

export const getLastAction = (state: StoreState): ContextualMenuAction =>
  state.rosterContextualMenu.action;

export const getCellData = (state: StoreState):
  Partial<RosteredShift> | Partial<ShiftTemplateItem> | Partial<Timesheet> =>
  state.rosterContextualMenu.cellData;

export const contextInProgress = (state: StoreState): boolean => state.rosterContextualMenu.inProgress;

export const getIgnoreValue = (state: StoreState): boolean => state.rosterContextualMenu.ignoreError;

export const getMenuId = (state: StoreState): string | null | undefined => state.rosterContextualMenu.id;

export const getAllSelectors = createSelector(
  getClipboard,
  getCellData,
  getObjectId,
  getOverlapModalProps,
  (shiftData, cellData, objectId, overlapPayload) => {
    return {
      shiftData, cellData, objectId, overlapPayload
    }
  }
);

export const getCombinedValidationData = createSelector(
  getRoles,
  getAreas,
  activeAreasBySiteIdSelector,
  getSiteId,
  getUserListResponse,
  (roles, areas, areasBySiteId, siteId, users) => {
    return {
      roles, areas, areasBySiteId, siteId, users
    }
  }
);

export const getCombinedTemplateData = createSelector(
  getRoles,
  getAreas,
  activeAreasBySiteIdSelector,
  getTemplateSiteId,
  getUserListResponse,
  (roles, areas, areasBySiteId, siteId, users) => {
    return {
      roles, areas, areasBySiteId, siteId, users
    }
  }
);

export const getClipboardMsg = createSelector(
  getClipboard,
  getLastAction,
  getTimeFormat,
  getSites,
  (clipboard, action, timeFormat, sites) => {
    let msg = '';
    if ( clipboard ) {
      const format = timeFormat === '24' ? 'HH:mm' : 'hh:mm a';
      const start = moment(`2010-01-01 ${clipboard.start}`).format(format);
      const end = moment(`2010-01-01 ${clipboard.end}`).format(format);
      msg = action === 'copy' ? 'Copied item: ' : 'Cut item: ';
      msg += `day ${(clipboard as ShiftTemplateItem).day}, ${start} - ${end}`;
    }
    return msg;
  }
)
