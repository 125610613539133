import {
  Form,
  GroupAddOutlinedIcon,
  Layout,
  AlertErrorBoxLayout,
  NewHeader,
  NewHeaderTitle,
  NewHeaderWrapper,
  WithPreload,
} from 'element';
import { withLayoutAware } from 'elmo-elements';
import { Box, IconButton, Tooltip } from 'extended-oxygen-elements';
import {
  SettingsHeaderBackBtn,
  SettingsSaveBtn,
} from 'page/Settings/components';
import * as React from 'react';
import { connect } from 'react-redux';
import { privateRoutes } from 'routes';
import { BOX_PERMISSIONS_REQUEST, getPermissions } from 'state/Permissions';
import { StoreState } from 'state/types';
import {
  BOX_UPDATE_USER_GROUP_PERMISSIONS_REQUEST,
  BOX_UPDATE_USER_GROUP_PERMISSIONS_RESET,
  BOX_USER_GROUP_CLEAR_ERRORS,
  BOX_USER_GROUP_SELECT_REQUEST,
  BOX_USER_GROUPS_ADD_MODAL_OPEN,
  BOX_USER_GROUPS_REQUEST,
  BOX_USER_GROUPS_UPDATE_MODAL_OPEN,
  getGroup,
  getIsFetching,
  getIsLoading,
  getIsPermissionsSaved,
  getUserGroups,
  getUserGroupsErrors,
} from 'state/UserGroups';
import { StringMap, UserGroup } from 'type';
import GroupsModals from './components/GroupsModals';
import InfoBox from './components/InfoBox';
import { TableGroups } from './components/TableGroups';
import { PermissionCombine, Props, State } from './type';

export class UserGroups extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      groups: props.groups,
      permissions: props.permissions,
      errors: {},
      permissionsCombine: {},
      updatedGroups: [],
    };
  }

  get templateOptions() {
    return [
      {
        label: 'Employee',
        value: 'employee',
      },
      {
        label: 'Line manager',
        value: 'manager',
      },
    ];
  }

  componentWillUnmount(): void {
    this.props.clearErrors();
  }

  componentDidMount = () => {
    this.setState((prevState) => ({
      ...prevState,
      permissionsCombine: this.combinePermissions(),
    }));
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps !== this.props) {
      this.setState({
        groups: this.props.groups,
        permissions: this.props.permissions,
        permissionsCombine: this.combinePermissions(this.props.permissions),
      });

      if (this.props.isPermissionsSaved) {
        this.props.history.push(privateRoutes.settings.routes.menu.path);
        this.props.resetPermissions();
      }
    }
  }

  render() {
    const totalGroups = Object.keys(this.state.permissionsCombine).length;

    return (
      <WithPreload isFetching={this.props.isFetching} fetchData={this.getData}>
        <Layout.Header>
          <NewHeaderWrapper>
            <NewHeader>
              <SettingsHeaderBackBtn />

              <NewHeaderTitle>Permissions</NewHeaderTitle>

              <Box ml="auto">
                <Tooltip title={'Create new user permission'}>
                  <IconButton onClick={this.onGroupHeaderClick}>
                    <GroupAddOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </NewHeader>
          </NewHeaderWrapper>
        </Layout.Header>
        <Layout.Content>
          <Form onSubmit={this.savePermissions}>
            <div className="user-groups-wrap">
              <InfoBox mb={1} />

              <AlertErrorBoxLayout
                BoxProps={{
                  mb: 1,
                }}
                errors={this.props.errors}
                onClose={this.props.clearErrors}
              />

              <Box overflow="auto">
                {Object.keys(this.state.permissionsCombine).map((id, key) => {
                  return (
                    <TableGroups
                      key={key}
                      combineGroup={this.state.permissionsCombine[id]}
                      combineGroupId={id}
                      groups={this.props.groups}
                      isFirst={key === 0}
                      isLast={key === totalGroups - 1}
                      index={key}
                      onChange={this.onChangeCheckbox}
                      onSelectGroup={this.selectGroup}
                    />
                  );
                })}
              </Box>
              <GroupsModals />
            </div>

            <SettingsSaveBtn loading={this.props.isLoading} />
          </Form>
        </Layout.Content>
      </WithPreload>
    );
  }

  onGroupHeaderClick = () => {
    this.clearGroup();
    this.props.openAddModal();
  };

  combinePermissions = (permissions = this.props.permissions) => {
    const combine: StringMap<PermissionCombine> = {};

    Object.keys(permissions).forEach((id) => {
      const groupName = permissions[id].group;
      if (!combine[groupName]) {
        combine[groupName] = {
          id: permissions[id].id,
          name: groupName,
          permissions: {},
        };
      }
      combine[groupName].permissions[permissions[id].id] = permissions[id];
    });

    return combine;
  };

  onChangeCheckbox = (
    e: React.SyntheticEvent<HTMLInputElement>,
    permissionId: number,
    groupId: number
  ) => {
    const { checked } = e.currentTarget;
    const updatedGroups = this.state.updatedGroups;

    if (updatedGroups.indexOf(groupId) === -1) {
      updatedGroups.push(groupId);
    }

    if (!checked) {
      // Uncheck child groups permissions
      Object.keys(this.props.groups).forEach((id) => {
        if (
          this.props.groups[id].template === this.props.groups[groupId].name
        ) {
          this.uncheckPermissions(String(id), permissionId);
        }
      });
      this.uncheckPermissions(String(groupId), permissionId);
    } else {
      this.checkPermissions(String(groupId), permissionId);
    }

    this.setState((prevState) => ({
      ...prevState,
      permissionsCombine: this.combinePermissions(),
      updatedGroups: updatedGroups,
    }));
  };

  checkPermissions = (groupId: string, permissionId: number) => {
    const permission = this.state.permissions[permissionId];
    let groupPermissions = this.state.groups[groupId].permission_ids;

    groupPermissions.push(permissionId);

    if (permission.parent_id !== null) {
      const parentIds = this.getAllParentPermissionsIds(permission.parent_id);
      groupPermissions = [...groupPermissions, ...parentIds];
    }

    this.state.groups[groupId].permission_ids = groupPermissions;
  };

  uncheckPermissions = (groupId: string, permissionId: number) => {
    let groupPermissions = this.state.groups[groupId].permission_ids;
    const childIds = this.getAllChildPermissionsIds(permissionId);

    groupPermissions = groupPermissions.filter((id) => {
      return childIds.indexOf(id) === -1;
    });

    this.state.groups[groupId].permission_ids = groupPermissions;
  };

  getAllParentPermissionsIds = (id: number, ids: number[] = []): number[] => {
    ids.push(id);
    if (
      this.state.permissions[id] &&
      this.state.permissions[id].parent_id !== null
    ) {
      return this.getAllParentPermissionsIds(
        this.state.permissions[id].parent_id as number,
        ids
      );
    } else {
      return ids;
    }
  };

  getAllChildPermissionsIds = (id: number, ids: number[] = []): number[] => {
    let childIds: number[] = [];
    ids.push(id);

    Object.keys(this.state.permissions).forEach((_id) => {
      if (this.state.permissions[_id].parent_id === id) {
        childIds.push(+_id);
      }
    });

    ids = [...ids, ...childIds];

    if (childIds.length > 0) {
      childIds.forEach((childId) => {
        ids = [...ids, ...this.getAllChildPermissionsIds(childId, ids)];
      });
    }
    return Array.from(new Set(ids));
  };

  getData = () => {
    this.props.getGroups();
    this.props.getPermissions();
  };

  clearGroup = () => {
    this.props.selectGroup({
      ...this.props.group,
      title: '',
      id: '',
      template: this.templateOptions[0].value,
      receiver_id: '',
      template_updated: false,
    });
  };

  selectGroup = (id: string) => {
    this.props.selectGroup({
      ...this.props.group,
      title: this.state.groups[id].title,
      id: id,
      template: this.state.groups[id].template,
    });

    this.props.openUpdateModal();
  };

  savePermissions = () => {
    if (this.state.updatedGroups.length) {
      const groups: UserGroup[] = [];
      this.state.updatedGroups.forEach((id) => {
        groups.push(this.state.groups[id]);
      });

      this.props.updateGroupPermissions(groups);
    }
    this.setState({
      updatedGroups: [],
    });
  };
}

export const mapStateToProps = (state: StoreState) => ({
  isFetching: getIsFetching(state),
  isLoading: getIsLoading(state),
  isPermissionsSaved: getIsPermissionsSaved(state),
  groups: getUserGroups(state),
  permissions: getPermissions(state),
  errors: getUserGroupsErrors(state),
  group: getGroup(state),
});

export default connect(mapStateToProps, {
  getGroups: BOX_USER_GROUPS_REQUEST,
  getPermissions: BOX_PERMISSIONS_REQUEST,
  updateGroupPermissions: BOX_UPDATE_USER_GROUP_PERMISSIONS_REQUEST,
  resetPermissions: BOX_UPDATE_USER_GROUP_PERMISSIONS_RESET,
  selectGroup: BOX_USER_GROUP_SELECT_REQUEST,
  openAddModal: BOX_USER_GROUPS_ADD_MODAL_OPEN,
  openUpdateModal: BOX_USER_GROUPS_UPDATE_MODAL_OPEN,
  clearErrors: BOX_USER_GROUP_CLEAR_ERRORS,
})(withLayoutAware(UserGroups));
