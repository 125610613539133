import React, { forwardRef, ReactNode, useEffect } from 'react';
import { get } from 'lodash';
import { Merge } from 'ts-essentials';
import { Input } from 'elmo-elements';
import { InputProps } from 'elmo-elements/Input/type';
import './InputWithIcon.scss';

type Props = Merge<
  InputProps,
  {
    icon?: ReactNode;
    onIconClick?: () => void;
  }
>;

export const InputWithIcon = forwardRef<HTMLInputElement, Props>(
  ({ icon, onIconClick, ...inputProps }, forwardedRef) => {
    useEffect(() => {
      const inputElement = get(forwardedRef, 'current');
      if (inputElement) {
        inputElement.setAttribute('autocomplete', 'off');
      }
    }, []);

    return (
      <div className="input-with-icon">
        <Input {...inputProps} ref={forwardedRef} />
        {!!icon && (
          <span
            className={`input-with-icon__icon-wrapper`}
            onClick={() => {
              if (onIconClick) {
                onIconClick();
              }
            }}
          >
            {icon}
          </span>
        )}
      </div>
    );
  }
);
