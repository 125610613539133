import React from 'react';
import moment, { Moment } from 'moment';
import { TextFieldProps } from 'extended-oxygen-elements';
import { DateType } from 'type';
import { SERVER_DAY_FORMAT } from 'lib/config';
import useUpdateEffect from './useUpdateEffect';

type UseDateFieldProps = {
  value: DateType | null;
  onValueChange: (newValue: DateType) => void;
  onBlur?: TextFieldProps['onBlur'];
  clearable?: false;
};

export default useDateField;

/**
 * this component can't be clearable because of internal state
 * the state is required to fix issues with animations on value change
 * */
export function useDateField<P extends UseDateFieldProps>({
  value,
  onValueChange,
  onBlur,
}: UseDateFieldProps) {
  const [formValue, setFormValue] = React.useState<null | Moment>(
    getFormValue(value)
  );

  useUpdateEffect(() => {
    setFormValue(getFormValue(value));
  }, [value]);

  useUpdateEffect(() => {
    if (isValidFormValue(formValue)) {
      onValueChange(getValue(formValue));
    }
  }, [formValue]);

  const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!isValidFormValue(formValue)) {
      setFormValue(getFormValue(value));
    }
    onBlur?.(event);
  };

  return {
    value: formValue,
    onChange: setFormValue,
    onBlur: handleBlur,
  };
}

function getFormValue(value: DateType | null): Moment | null {
  return value ? moment(value) : null;
}

function isValidFormValue(formValue: Moment | null): formValue is Moment {
  return !!formValue?.isValid();
}

function getValue(formValue: Moment): DateType {
  return formValue.format(SERVER_DAY_FORMAT);
}
