import React from 'react';
import { useSelectorWithProps } from 'hooks';
import { getRosteredShift } from 'state/RosteredShifts';
import { useAreaName, useRosterWeekCard, useUserName } from './hooks';
import { RosterContextualMenu } from './contextualMenus';
import {
  ShiftCardBreaksDuration,
  ShiftCardContextMenuIcon,
  ShiftCardIcons,
} from './views';
import { ShiftCardViewRefactored } from './views/ShiftCardViewRefactored';

type Props = {
  rosteredShiftId: string;
};

export const SiteRosterWeekCard = React.memo(function SiteRosterWeekCard(
  props: Props
) {
  const rosteredShift = useSelectorWithProps(
    getRosteredShift,
    props.rosteredShiftId
  );

  const { viewProps, icons, breaks, onClickContextualMenu } =
    useRosterWeekCard(rosteredShift);
  const userName = useUserName(rosteredShift);
  const areaName = useAreaName(rosteredShift);

  return (
    <ShiftCardViewRefactored
      {...viewProps}
      data-shift-id={props.rosteredShiftId}
      beforeContent={
        <RosterContextualMenu
          elementId={viewProps.id}
          rosteredShiftId={props.rosteredShiftId}
        />
      }
      icons={
        <>
          <ShiftCardIcons icons={icons} />
          {onClickContextualMenu && (
            <ShiftCardContextMenuIcon onClick={onClickContextualMenu} />
          )}
        </>
      }
      breaks={<ShiftCardBreaksDuration breaks={breaks} />}
      title={userName}
      spotTitle={areaName}
    />
  );
});
