import { EventModalReducerState } from './types';
import moment from 'moment';
import { Event } from '../../../type/models';

export const emptyEvent: Event = {
  id: '',
  type: 'event',
  name: '',
  value: '',
  actual_value: '',
  description: '',
  item: '',
  address: '',
  address_name: '',
  report_to: '',
  lat:  0,
  lng: 0,
  start: moment().startOf('day'),
  end: moment().endOf('day'),
  site_id: '',
  account_id: '',
  tags: [],
  rostered_shift_ids: [],
  custom_fields: [],
  publish: false,
  actual_day_cost: 0,
  day_cost: 0,
  status: 'none'
};

export const getDefaultState = (): EventModalReducerState => ({
  isUpdating: false,
  errors: [],
  eventModal: {
    isLoading: false,
    isOpened: false,
    isUpdating: false,
    errors: [],
    isLoaded: false,
    isLoadingShifts: false
  },
  usersList: [],
  currentEvent: emptyEvent,
  shifts: {},
  publishedShiftsModal: {
    isOpened: false
  }
});
