import React from 'react';
import { Typography } from 'extended-oxygen-elements';

type Props = {
  children: React.ReactNode;
};

export function SummaryHeader({ children }: Props) {
  return (
    <Typography variant="h5" gutterBottom>
      {children}
    </Typography>
  );
}
