import { createAction } from 'lib/store-utils';
import { PageId } from './types';

export const BOX_IS_LOADING_GLOBAL_ON = createAction('Is loading / global on');
export const BOX_IS_LOADING_GLOBAL_OFF = createAction(
  'Is loading / global off'
);

export const BOX_IS_LOADING_ON = createAction<PageId>('Is loading / on');
export const BOX_IS_LOADING_OFF = createAction<PageId>('Is loading / off');
