import { createAction } from 'lib/store-utils';
import { Profile } from '../../type/models/preferences';

export const BOX_PREFERENCES_PROFILE_REQUEST =
  createAction('Get preferences profile request');
export const BOX_PREFERENCES_PROFILE_SUCCESS =
  createAction<Profile>('Get preferences profile success');
export const BOX_PREFERENCES_PROFILE_FAILURE =
  createAction<string[]>('Get preferences profile failed');

export const BOX_PREFERENCES_UPDATE_PROFILE_REQUEST =
  createAction('Update preferences profile request');
export const BOX_PREFERENCES_UPDATE_PROFILE_SUCCESS =
  createAction('Update preferences profile success');
export const BOX_PREFERENCES_UPDATE_PROFILE_FAILURE =
  createAction<string[]>('Update preferences profile failed');

export const BOX_PREFERENCES_RESET_PIN_REQUEST =
  createAction('Update preferences reset pin request');
export const BOX_PREFERENCES_RESET_PIN_SUCCESS =
  createAction('Update preferences reset pin success');
export const BOX_PREFERENCES_RESET_PIN_FAILURE =
  createAction<string[]>('Update preferences reset pin failed');

export const BOX_PREFERENCES_SHOW_NOTIFICATION = createAction('Preferences / Show notification');
export const BOX_PREFERENCES_HIDE_NOTIFICATION = createAction('Preferences / Hide notification');
