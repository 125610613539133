import React from 'react';
import { StoreState } from 'state/types';
import {
  getDeleteTemplateModal,
  getPrevPageValue,
  getTemplateTitle,
} from 'state/Roster/EditTemplate/selectors';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getIsBulkDeleteOpened } from 'state/Roster/BulkActions/selectors';
import BulkDeleteActions from '../BulkDelete';
import {
  BulkActionIcon,
  ContentCopyOutlinedIcon,
  DeleteOutlinedIcon,
  DeleteSweepOutlinedIcon,
  EditOutlinedIcon,
  Header,
  InfoOutlinedIcon,
  MoreHorizIcon,
  NewHeaderAction,
  NewHeaderWrapper,
} from 'element';
import { BOX_ROSTER_BULK_DELETION_MODE_ACTIVATE } from 'state/Roster/BulkActions/actions';
import { RouteComponentProps, withRouter } from 'react-router';
import { privateRoutes } from 'routes';
import { HeaderButtonProp } from 'element/Header';
import {
  BOX_EDIT_TEMPLATE_OPEN_DELETE_TEMPLATE_MODAL,
  BOX_EDIT_TEMPLATE_OPEN_SAVE_AS_TEMPLATE_MODAL,
  BOX_EDIT_TEMPLATE_OPEN_UPDATE_TEMPLATE_MODAL,
} from 'state/Roster/EditTemplate/actions';
import { ConfirmModalProps } from 'state/Roster/EditTemplate/types';
import ClipboardIcon from '../ContextualMenu/componenets/ClipboardIcon';
import {
  getClipboard,
  getClipboardMsg,
} from 'state/Roster/ContextualMenu/selectors';
import { RosteredShift, ShiftTemplateItem } from 'type';
import { BOX_SHORTCUTS_MODAL_OPEN } from 'state/Roster/ShortcutsModal';
import { useBulkDeleteContext } from '../../../../contexts';
import { Box } from '../../../../extended-oxygen-elements';

type StateProps = {
  title: string;
  bulkDeletionIsOpened: boolean;
  prevPage: null | string;
  deleteTemplateModal: ConfirmModalProps;
  shiftMsg: string;
  clipboard: RosteredShift | ShiftTemplateItem | null;
};

type DispatchProps = {
  activateBulkDeleteMode: () => void;
  deleteTemplate: () => void;
  updateTemplate: () => void;
  saveAsTemplate: () => void;
  openShortcutsModal: () => void;
};

type Props = StateProps & DispatchProps & RouteComponentProps;

export const EditTemplateHeaderComponent = ({
  title,
  bulkDeletionIsOpened,
  activateBulkDeleteMode,
  prevPage,
  history,
  deleteTemplate,
  updateTemplate,
  saveAsTemplate,
  clipboard,
  shiftMsg,
  openShortcutsModal,
}: Props) => {
  const bulkDeleteContext = useBulkDeleteContext();
  const isBulkDeleteOpened = useSelector(getIsBulkDeleteOpened);
  const dispatch = useDispatch();
  const showMoreBtns = [
    {
      id: 'delete-template',
      icon: <DeleteOutlinedIcon />,
      label: 'Delete template',
      onClick: () => {
        deleteTemplate();
      },
    },
    {
      id: 'save-as-template',
      icon: <ContentCopyOutlinedIcon />,
      label: 'Save as',
      onClick: () => {
        saveAsTemplate();
      },
    },
    {
      id: 'update-template',
      icon: <EditOutlinedIcon />,
      label: 'Rename template',
      onClick: () => {
        updateTemplate();
      },
    },
    {
      id: 'shortcuts-btn',
      icon: <InfoOutlinedIcon />,
      label: 'Shortcuts',
      onClick: () => openShortcutsModal(),
    },
  ];
  const getButtons = () => {
    const buttons: HeaderButtonProp[] = [
      {
        icon: (
          <Box component="span" color="error.dark">
            <DeleteSweepOutlinedIcon color="inherit" />
          </Box>
        ),
        onClick: () => {
          activateBulkDeleteMode();
          bulkDeleteContext.enable();
        },
        label: 'Open bulk delete mode',
        tooltip: 'Open bulk delete mode',
      },
    ];

    if (clipboard) {
      buttons.unshift({
        className: 'desktop-only',
        icon: <ClipboardIcon />,
        tooltip: shiftMsg,
        label: '',
        onClick: (): void => void 0,
      });
    }

    buttons.push({
      id: 'show-more',
      icon: <MoreHorizIcon />,
      label: 'Show more',
      onClick: (): void => void 0,
      children: showMoreBtns,
    });

    return buttons;
  };

  const backAction = () => {
    if (prevPage) {
      history.push(prevPage);
    } else {
      history.push(privateRoutes.roster.path);
    }
  };
  return (
    <NewHeaderWrapper style={{ height: '64px', overflow: 'none' }}>
      <NewHeaderAction
        visible={!bulkDeleteContext.isEnabled || !isBulkDeleteOpened}
        style={{ padding: 0 }}
      >
        <Header
          title={title}
          isBulkActionOpen={bulkDeletionIsOpened}
          buttons={getButtons()}
          backButton={{
            title: 'Back',
            onClick: backAction,
          }}
        />
      </NewHeaderAction>
      <NewHeaderAction
        visible={bulkDeleteContext.isEnabled && isBulkDeleteOpened}
        style={{
          padding: 0,
          boxShadow: '0 0 4px #ccc',
          background: 'white',
        }}
      >
        <BulkDeleteActions />
      </NewHeaderAction>
    </NewHeaderWrapper>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  title: getTemplateTitle(state),
  bulkDeletionIsOpened: getIsBulkDeleteOpened(state),
  prevPage: getPrevPageValue(state),
  deleteTemplateModal: getDeleteTemplateModal(state),
  clipboard: getClipboard(state),
  shiftMsg: getClipboardMsg(state),
});

const mapDispatchToProps: DispatchProps = {
  activateBulkDeleteMode: BOX_ROSTER_BULK_DELETION_MODE_ACTIVATE,
  deleteTemplate: BOX_EDIT_TEMPLATE_OPEN_DELETE_TEMPLATE_MODAL,
  updateTemplate: BOX_EDIT_TEMPLATE_OPEN_UPDATE_TEMPLATE_MODAL,
  saveAsTemplate: BOX_EDIT_TEMPLATE_OPEN_SAVE_AS_TEMPLATE_MODAL,
  openShortcutsModal: BOX_SHORTCUTS_MODAL_OPEN,
};

export const EditTemplateHeader = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditTemplateHeaderComponent)
);
