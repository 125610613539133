import { createAction } from 'lib/store-utils';
import { Event, EventsPeriod, FormattedErrors } from 'type/models';
import { UpdateFormPayload } from 'type';
import { Marker, ToggleFilterSuccessPayload } from './types';
import { ManagerDashboardGetEventsResponse } from 'lib/Api/type';

export const BOX_EVENTS_DATA_REQUEST = createAction('Events / get data request');
export const BOX_EVENTS_GET_EVENTS_SUCCESS = createAction<ManagerDashboardGetEventsResponse>('Events / get events list success');
export const BOX_EVENTS_CHANGE_PAGE_REQUEST = createAction<number>('Events / Change page request');
export const BOX_EVENTS_CHANGE_PAGE_SUCCESS = createAction<ManagerDashboardGetEventsResponse>('Events / Change page success');
export const BOX_EVENTS_CHANGE_PAGE_FAILURE = createAction<FormattedErrors>('Events / Change page failed');
export const BOX_EVENTS_CHANGE_PAGE_SIZE_REQUEST = createAction<number>('Events / Change page size request');
export const BOX_EVENTS_CHANGE_PAGE_SIZE_SUCCESS = createAction<ManagerDashboardGetEventsResponse>('Events / Change page size success');
export const BOX_EVENTS_CHANGE_PAGE_SIZE_FAILURE = createAction<FormattedErrors>('Events / Change page size failed');
export const BOX_EVENTS_UPDATE_ORDERING_REQUEST = createAction<UpdateFormPayload>('Events / update ordering request');
export const BOX_EVENTS_UPDATE_ORDERING_SUCCESS = createAction<ToggleFilterSuccessPayload>('Events / update ordering success');
export const BOX_EVENTS_TOGGLE_PERIOD_TYPE_REQUEST = createAction<EventsPeriod>('Events / Change period request');
export const BOX_EVENTS_TOGGLE_PERIOD_TYPE_SUCCESS = createAction<ToggleFilterSuccessPayload>('Events / Change period success');
export const BOX_EVENTS_TOGGLE_PERIOD_TYPE_FAILURE = createAction<FormattedErrors>('Events / Change period failed');
export const BOX_EVENTS_UPDATE_FORM_FILTERS = createAction<UpdateFormPayload>('Events / Change filters request');
export const BOX_EVENTS_UPDATE_FILTERS_REQUEST = createAction('Events / Change filters request');
export const BOX_EVENTS_UPDATE_FILTERS_SUCCESS = createAction<ToggleFilterSuccessPayload>('Events / Change filters success');
export const BOX_EVENTS_UPDATE_FILTERS_FAILURE = createAction<FormattedErrors>('Events / Change filters failed');
export const BOX_EVENTS_RESET_FILTERS_FORM = createAction('Events / Reset filters request');
export const BOX_EVENTS_OPEN_MAP = createAction<Marker>('Events / open map');
export const BOX_EVENTS_CLOSE_MAP = createAction('Events / open map');
export const BOX_EVENTS_OPEN_EVENTS_MODAL = createAction<any>('Events / open events modal wrappers');
export const BOX_MANAGER_EVENTS_SHOW_UPDATE_NOTIFICATION = createAction<Event>('Manager events / show update notification');
export const BOX_MANAGER_EVENT_SET_EDITED_ID = createAction<string>('Manager events / set edited event id');
export const BOX_MANAGER_EVENT_DELETE_EDITED_ID = createAction('Manager events / remove edited event id');
export const BOX_MANAGER_EVENT_UPDATE = createAction<Event>('Manager events /update event');
export const BOX_MANAGER_EVENTS_DELETED = createAction<string>('Manager events / delete event');
export const BOX_MANAGER_EVENTS_TOGGLE_NOTIFICATION = createAction('Manager events / toggle notification');
