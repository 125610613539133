import { Button, ButtonProps } from 'extended-oxygen-elements';
import { PaymentSettingsOvertimeRuleDays } from 'type';

export type DayButtonProps = {
  label: string;
  value: PaymentSettingsOvertimeRuleDays;
  selectedValue: PaymentSettingsOvertimeRuleDays | null;
  onSelect: (newValue: PaymentSettingsOvertimeRuleDays) => void;
  readonly?: boolean;
};

export default function DayButton({
  label,
  value,
  selectedValue,
  onSelect,
  readonly,
}: DayButtonProps) {
  const selected = value === selectedValue;
  const isInteractive = !readonly && !selected;

  const handleClick: ButtonProps['onClick'] = () => {
    if (!isInteractive) {
      return;
    }

    onSelect(value);
  };

  return (
    <Button
      variant={selected ? 'contained' : 'outlined'}
      disableRipple={selected}
      disableElevation
      onClick={handleClick}
      sx={{
        ...(!isInteractive && {
          pointerEvents: 'none',
        }),
      }}
      {...{
        ...(!isInteractive && {
          role: 'presentation',
          tabIndex: -1
        })
      }}
    >
      {label}
    </Button>
  );
}
