import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { SagaReturnType } from 'type';
import Api, { UserListResponse } from 'lib/Api';
import { processApiRequest } from 'state/ProcessApiRequest';
import { BOX_USERS_COLLECTION_SET_LIST } from './actions';

export const fetchUserList = function* (): SagaIterator<UserListResponse> {
  return yield call(processApiRequest, Api.User.list);
};

export const fetchRotaUserList = function* (): SagaIterator<UserListResponse> {
  return yield call(processApiRequest, Api.User.rotaList);
};

export const getUserListRequest = function* (): SagaIterator {
  const response: SagaReturnType<typeof fetchUserList> = yield call(
    fetchUserList
  );
  yield put(BOX_USERS_COLLECTION_SET_LIST(response));
};

export const getRotaUserListRequest = function* (): SagaIterator {
  const response: SagaReturnType<typeof fetchUserList> = yield call(
    fetchRotaUserList
  );
  yield put(BOX_USERS_COLLECTION_SET_LIST(response));
};
