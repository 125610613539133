import { BulkDeleteContextProvider } from 'contexts';
import {
  FetchWrapper,
  Layout,
  SocketRosterConnection,
  SocketRosterWeekConnection,
} from 'element';
import { Alert } from 'extended-oxygen-elements';
import { useSelectorWithProps, useUpdateEffect, useRosterQuery } from 'hooks';
import { SERVER_DAY_FORMAT } from 'lib/config';
import { getWeekRage } from 'lib/helpers';
import { moment } from 'lib/momentWithRange';
import { CovidTracingHeader } from 'page/Roster/components';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSite } from 'state/AccountTree';
import { ROSTERS } from 'state/FetchPageData/constants';
import { BOX_ROSTER_BULK_DELETION_MODE_DEACTIVATE } from 'state/Roster/BulkActions/actions';
import { getIsCovidModeEnabled } from 'state/Roster/CovidTracing';
import {
  activeAreasForCurrentSiteId,
  BOX_ROSTER_GET_INITIAL_PAGE_DATA_REQUEST,
  BOX_ROSTER_GET_PAGE_DATA_REQUEST,
} from 'state/Roster/Roster';
import { Dictionary } from 'ts-essentials';
import { Route } from 'type';
import { NotEnoughDataMsg } from '../../constants';
import { WeekHeader } from './components';
import View from './view';

type WithRoutesProps = {
  routes: Dictionary<Route>;
};

export default RosterWeekView;

function RosterWeekView(props: WithRoutesProps) {
  const isCovidModeEnabled = useSelector(getIsCovidModeEnabled);

  return (
    <BulkDeleteContextProvider>
      <Layout.Header>
        {isCovidModeEnabled ? <CovidTracingHeader /> : <WeekHeader />}
      </Layout.Header>

      <Layout.Content>
        <UpdatePageData />

        <Fetch {...props} />
      </Layout.Content>
    </BulkDeleteContextProvider>
  );
}

function useWeekPayload() {
  const {
    query: { site_id, day },
  } = useRosterQuery();
  const { timezone_id } = useSelectorWithProps(getSite, site_id);
  const dayMoment = moment(day, SERVER_DAY_FORMAT).tz(timezone_id, true);

  return {
    site_id,
    ...getWeekRage(dayMoment),
  };
}

type WeekPayload = ReturnType<typeof useWeekPayload>;

function Fetch(props: WithRoutesProps) {
  const dispatch = useDispatch();
  const payload = useWeekPayload();

  React.useEffect(
    () => () => {
      dispatch(BOX_ROSTER_BULK_DELETION_MODE_DEACTIVATE());
    },
    []
  );

  return (
    <FetchWrapper
      fetchData={() => {
        dispatch(BOX_ROSTER_GET_INITIAL_PAGE_DATA_REQUEST(payload));
      }}
      pageId={ROSTERS}
    >
      <Content {...payload} {...props} />
    </FetchWrapper>
  );
}

function UpdatePageData() {
  const dispatch = useDispatch();
  const { site_id, from, to } = useWeekPayload();

  useUpdateEffect(() => {
    dispatch(
      BOX_ROSTER_GET_PAGE_DATA_REQUEST({
        site_id,
        from,
        to,
      })
    );
  }, [
    dispatch,
    site_id,
    from.format(SERVER_DAY_FORMAT),
    to.format(SERVER_DAY_FORMAT),
  ]);

  return null;
}

function Content({ routes, ...payload }: WeekPayload & WithRoutesProps) {
  const activeAreasBySiteId = useSelector(activeAreasForCurrentSiteId);

  if (!activeAreasBySiteId.length) {
    return (
      <Alert color="warning" severity="warning">
        {NotEnoughDataMsg}
      </Alert>
    );
  }

  return (
    <>
      <SocketRosterConnection {...payload} />
      <SocketRosterWeekConnection {...payload} />
      <View routes={routes} />
    </>
  );
}
