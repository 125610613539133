import React, { Component } from 'react';
import { BOX_TIMESHEET_SHIFT_MODAL_OVERLAP_CONFIRMATION_CLOSE } from 'state/TimesheetModal';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import { OverlapShiftModalProps } from 'state/TimesheetModal/types';
import { replaceErrorText } from 'lib/helpers';
import { BOX_ROSTER_CONTEXTUAL_MENU_SET_IGNORE_ERROR } from 'state/Roster/ContextualMenu/actions';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from 'extended-oxygen-elements';
import { PageDialog, PageDialogCancel, PageDialogSubmit } from 'element';

type OwnProps = {
  overlapModal: OverlapShiftModalProps;
  confirmOverlap: () => void;
  confirmApproveOverlap: () => void;
  confirmDeletion: () => void;
};

type DispatchProps = {
  closeModal: (isUpdating: boolean) => void;
  setIgnoreValue: (v: boolean) => void;
};

type Props = OwnProps & DispatchProps;

export class TimesheetOverlapConfirmationModal extends Component<Props> {
  render() {
    return (
      <PageDialog
        id={'timesheet-overlap-modal'}
        maxWidth={'xs'}
        open={this.props.overlapModal.isOpened}
        onClose={() => this.props.closeModal(false)}
      >
        <DialogTitle>Do you want to approve this?</DialogTitle>
        <DialogContent>
          {replaceErrorText(this.props.overlapModal.errors).map(
            (error: string, index: number) => (
              <Typography
                variant={'body1'}
                className={'confirmation-text'}
                key={index}
                sx={{ padding: '10px 0' }}
              >
                {error}
              </Typography>
            )
          )}
        </DialogContent>
        <DialogActions>
          <PageDialogCancel onClick={() => this.props.closeModal(false)}>
            Cancel
          </PageDialogCancel>
          <PageDialogSubmit
            id={'approve-overlap-btn'}
            onClick={this.onClickHandler}
          >
            Yes, approve it
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<{}>,
    snapshot?: any
  ) {
    const btn: null | HTMLButtonElement = document.querySelector(
      '#timesheet-overlap-modal #approve-overlap-btn'
    );
    if (btn && btn.offsetTop) {
      btn.focus();
    }
  }

  onClickHandler = () => {
    const { overlapModal } = this.props;
    if (overlapModal.isDeleting) {
      this.props.confirmDeletion();
    } else if (overlapModal.isApproveOverlap) {
      this.props.confirmApproveOverlap();
    } else {
      this.props.confirmOverlap();
    }
    this.props.setIgnoreValue(true);
    this.props.closeModal(true);
  };
}

const mapStateToProps = (state: StoreState) => ({
  overlapModal: state.timesheetModal.overlapShiftModal,
});

const mapDispatchProps: DispatchProps = {
  closeModal: BOX_TIMESHEET_SHIFT_MODAL_OVERLAP_CONFIRMATION_CLOSE,
  setIgnoreValue: BOX_ROSTER_CONTEXTUAL_MENU_SET_IGNORE_ERROR,
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(TimesheetOverlapConfirmationModal);
