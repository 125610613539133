import React from 'react';
import { ChartBarItem } from './type';
import { createStyles, makeStyles } from '@mui/styles';
import { DefaultDivProps } from '../../type';

type Props = {
  data: ChartBarItem[];
} & DefaultDivProps;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginBottom: 40,
    },
    item: {
      overflow: 'hidden',
      borderRadius: theme.shape.borderRadius,
    },
    title: {
      paddingBottom: 10,
      fontWeight: 500,
      fontSize: '13px',
      letterSpacing: '0.5px',
    },
    barWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    barFilled: {
      height: '50px',
      borderRadius: '3px',
      transition: 'width .45s ease-out',
      marginRight: '10px',
    },
    barInfo: {
      minWidth: '70px',
      textAlign: 'center',
      fontSize: '18px',
      fontWeight: 500,
    },
  })
);

export const HorizontalBar = ({ data, ...rest }: Props) => {
  const values = data.map((d) => d.y);
  const max = Math.max(...values);
  const classes = useStyles();
  const drawHorizontalBar = (data: ChartBarItem) => {
    const width = Math.round((data.y / max) * 100);
    const resultWidth = width > 0 ? `${width}%` : '5px';
    return (
      <div className={classes.item} data-testid="bar-item">
        <div className={classes.title} data-testid="bar-name">
          {data.name}
        </div>
        <div className={classes.barWrapper} data-testid="bar-wrapper">
          <div
            data-testid="bar-filled"
            className={classes.barFilled}
            style={{
              backgroundColor: data.color,
              width: resultWidth,
            }}
          />
          <span className={classes.barInfo} data-testid="bar-percentage">
            {data.y}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div {...rest}>
      {data.map((d: ChartBarItem, i: number) => (
        <div className={classes.root} key={i}>
          {drawHorizontalBar(d)}
        </div>
      ))}
    </div>
  );
};
