import React from 'react';
import { Dropdown, Paragraph } from 'elmo-elements';
import { CustomDropdownLabelType } from '../types';

export const CustomDropdownLabel = <Value extends any>({
  label
}: CustomDropdownLabelType) => (
  <Dropdown.Label>
    <Paragraph size="sm" color="gray" className="pl-3 pt-3">
      {label}
    </Paragraph>
  </Dropdown.Label>
);
