import * as React from 'react';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import {
  Col,
  FormItem,
  Input,
  Loader,
  Notification,
  Row,
  withLayoutAware,
} from 'elmo-elements';
import { ErrorBox, Header, Layout, WithPreload } from 'element';
import { NotificationsTable, SectionHeading } from './components';

import { Props, State } from './type';
import {
  BOX_PREFERENCES_HIDE_NOTIFICATION,
  BOX_PREFERENCES_PROFILE_REQUEST,
  BOX_PREFERENCES_RESET_PIN_REQUEST,
  BOX_PREFERENCES_UPDATE_PROFILE_REQUEST,
} from 'state/Preferences';
import { Profile } from '../../type/models/preferences';
import { StringMap } from '../../type';
import { getPreferenceLabel } from 'lib/helpers';
import browserHistory from 'lib/browserHistory';
import { privateRoutes } from 'routes';
import { getShowNotification } from '../../state/Preferences/selectors';
import { getCanEditProfile, getLangPreferences } from '../../state/Account';
import { HeaderTabLinkProps } from 'element/Header/type';

export class Preferences extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    if (!this.props.canEditProfile) {
      browserHistory.push(privateRoutes.roster.path);
    }

    this.state = {
      profile: props.profile,
      errors: {},
      isLoading: props.isLoading,
      isFetching: props.isFetching,
      isFetched: props.isFetched,
      pin: '',
      confirmationPin: '',
      watchErrors: false,
      isPinUpdated: false,
      activeTab: 'pin',
    };
  }

  componentDidUpdate(prevProps: Props): void {
    if (prevProps !== this.props) {
      if (this.props.errors.length) {
        this.setState({
          isLoading: false,
        });
      }
      this.setState({
        profile: this.props.profile,
        isFetched: this.props.isFetched,
        isFetching: this.props.isFetching,
        isLoading: this.props.isLoading,
      });
    }
  }

  onChange = (e: {
    group: string;
    id: string;
    type: string;
    value: string;
  }) => {
    const { profile } = this.state;

    profile[e.group][e.id][e.type] = e.value;

    this.setState({
      ...this.state,
      profile: profile,
    });
  };

  onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  render() {
    const { manager, employee } = this.state.profile;
    const { activeTab } = this.state;

    return (
      <WithPreload
        isFetching={this.props.isFetching}
        fetchData={this.getProfile}
      >
        <Layout.Header>
          <Header
            title="Preferences"
            primaryButton={this.saveButtonConfig}
            tabs={this.getTabs()}
          />
        </Layout.Header>
        <Layout.Content>
          <div className="preferences-content">
            <ErrorBox
              className="mb-4"
              errors={this.props.errors}
              clearErrors={this.props.clearErrors}
              watchFormErrors={this.state.watchErrors}
            />

            {activeTab === 'pin' && (
              <SectionHeading title="Reset PIN Code" className="mb-8" />
            )}

            {activeTab === 'pin' && (
              <Row isNoGutters={true} role="presentation">
                <Col span={10} md={8} role="presentation">
                  <FormItem
                    label="Pin"
                    message={this.state.errors.pin}
                    status={this.state.errors.pin ? 'error' : undefined}
                  >
                    <Input
                      htmlType="password"
                      name="pin"
                      value={this.state.pin}
                      onChange={this.onChangeInput}
                      ariaLabel="Pin"
                    />
                  </FormItem>
                </Col>
              </Row>
            )}

            {activeTab === 'pin' && (
              <Row isNoGutters={true} role="presentation">
                <Col span={10} md={8} role="presentation">
                  <FormItem
                    label="Confirm Pin"
                    message={this.state.errors.confirmationPin}
                    status={
                      this.state.errors.confirmationPin ? 'error' : undefined
                    }
                  >
                    <Input
                      htmlType="password"
                      name="confirmationPin"
                      value={this.state.confirmationPin}
                      onChange={this.onChangeInput}
                      ariaLabel="Confirm Pin"
                    />
                  </FormItem>
                </Col>
              </Row>
            )}

            {manager &&
              Object.keys(manager).length &&
              activeTab === 'settings' &&
              this.renderNotificationsManager()}

            {employee &&
              Object.keys(employee).length &&
              activeTab === 'settings' &&
              this.renderNotificationsEmployee()}

            <Notification
              isActive={this.props.showNotification}
              message={'Pin updated'}
              onClose={this.props.hideNotification}
            />
          </div>
        </Layout.Content>
      </WithPreload>
    );
  }

  hasSettings = () => {
    const { manager, employee } = this.state.profile;
    return (
      (manager && Object.keys(manager).length !== 0) ||
      (employee && Object.keys(employee).length !== 0)
    );
  };

  getTabs = (): HeaderTabLinkProps[] => {
    const { activeTab } = this.state;
    const tabs: HeaderTabLinkProps[] = [
      {
        label: 'Reset Pin',
        onClick: (e) => this.setTab('pin'),
        active: activeTab === 'pin',
      },
    ];
    if (this.hasSettings()) {
      tabs.push({
        label: 'Notification settings',
        onClick: (e) => this.setTab('settings'),
        active: activeTab === 'settings',
      });
    }
    return tabs;
  };

  setTab = (current: 'settings' | 'pin') => {
    this.setState({
      ...this.state,
      activeTab: current,
    });
  };

  readonly validate = (
    pin: string,
    confirmationPin: string
  ): { isValid: boolean; errors: StringMap<string> } => {
    const errors: StringMap<string> = {};

    if (pin.length > 0 && (pin.length !== 4 || !/^\d+$/.test(pin))) {
      errors['pin'] = 'The pin must be 4 digits.';
    }

    if (pin !== confirmationPin) {
      errors['confirmationPin'] = 'The pin confirmation and pin must match.';
    }

    return {
      isValid: !Object.keys(errors).length,
      errors,
    };
  };

  renderNotificationsManager = () => {
    return (
      <>
        <SectionHeading
          title="Notification preferences (Manager)"
          className="mt-8"
        />

        <NotificationsTable
          notifications={this.props.profile.manager}
          onChange={this.onChange}
          group="manager"
        />
      </>
    );
  };

  renderNotificationsEmployee = () => {
    const { langPreferences } = this.props;
    return (
      <>
        <SectionHeading
          title={`Notification preferences (${getPreferenceLabel(
            langPreferences,
            'employee',
            'singular',
            '',
            true
          )})`}
          className="mt-5"
        />

        <NotificationsTable
          notifications={this.props.profile.employee}
          onChange={this.onChange}
          group="employee"
        />
      </>
    );
  };

  getProfile = () => {
    this.props.getProfile();
  };

  savePreferences = () => {
    const { activeTab } = this.state;

    if (activeTab === 'pin') {
      const { isValid, errors } = this.validate(
        this.state.pin,
        this.state.confirmationPin
      );

      this.setState(
        {
          errors,
          watchErrors: true,
        },
        () => {
          this.setState({
            watchErrors: false,
          });
        }
      );

      if (isValid) {
        const data: {
          pin?: string;
          pin_confirmation?: string;
        } = {};

        if (this.state.pin.length) {
          data['pin'] = this.state.pin;
          data['pin_confirmation'] = this.state.confirmationPin;
          this.props.resetPin(data);
        }
        this.setState({
          pin: '',
          confirmationPin: '',
        });
      }
    } else {
      const notificationData: { notification_settings: Profile } = {
        notification_settings: this.state.profile,
      };

      this.props.savePreferences(notificationData);
    }
  };

  get saveButtonConfig() {
    const { isLoading } = this.props;

    return {
      label: 'Save',
      isText: true,
      icon: <Loader type="spinner" isLoading={isLoading} />,
      onClick: this.savePreferences,
    };
  }
}

const mapStateToProps = (state: StoreState) => ({
  isFetched: state.preferences.isFetched,
  isLoading: state.preferences.isLoading,
  isFetching: state.preferences.isFetching,
  errors: state.preferences.errors,
  profile: state.preferences.profile,
  showNotification: getShowNotification(state),
  canEditProfile: getCanEditProfile(state),
  langPreferences: getLangPreferences(state)
});

export default connect(
  mapStateToProps,
  {
    savePreferences: BOX_PREFERENCES_UPDATE_PROFILE_REQUEST,
    resetPin: BOX_PREFERENCES_RESET_PIN_REQUEST,
    getProfile: BOX_PREFERENCES_PROFILE_REQUEST,
    hideNotification: BOX_PREFERENCES_HIDE_NOTIFICATION
  })(withLayoutAware(Preferences));
