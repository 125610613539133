import { useBulkApproveContext, useBulkDeleteContext } from 'contexts';
import { useSelectorWithProps } from 'hooks';
import { hasPermissionSelector } from 'state/Auth';
import { getUserIsActiveSafe } from 'state/UsersCollection';

type Props = {
  userId: string;
}

export function useIsEditableView({ userId }: Props) {
  const isUserActive = !!useSelectorWithProps(getUserIsActiveSafe, userId);
  const { isEnabled: isBulkDeleteEnabled } = useBulkDeleteContext();
  const { isEnabled: isBulkApproveEnabled } = useBulkApproveContext();

  const hasRosterTimesheetEditPermission = useSelectorWithProps(
    hasPermissionSelector,
    'roster.timesheet.edit'
  );

  return (
    isUserActive &&
    !isBulkDeleteEnabled &&
    !isBulkApproveEnabled &&
    hasRosterTimesheetEditPermission
  );
}

export default useIsEditableView;
