import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Box, Tooltip } from 'extended-oxygen-elements';
import { IconButton } from 'extended-oxygen-elements';
import { SERVER_DAY_FORMAT } from 'lib/config';
import { useSelectorWithProps } from 'hooks';
import { AddOutlinedIcon } from 'element';
import { getTimesheet } from 'state/TimesheetResponse';
import { BOX_CREATE_TIMESHEET_DIALOG_OPENED } from 'state/CreateTimesheetDialog';

type Props = {
  timesheetId: string;
};

export const CreateButton = ({ timesheetId }: Props) => {
  const dispatch = useDispatch();
  const { user_id, start } = useSelectorWithProps(getTimesheet, timesheetId);

  const handleClick = () => {
    dispatch(
      BOX_CREATE_TIMESHEET_DIALOG_OPENED({
        user_id,
        date: moment.parseZone(start).format(SERVER_DAY_FORMAT),
      })
    );
  };

  return (
    <Tooltip title="Add timesheet">
      <Box component="span" color="info.main">
        <IconButton
          color="inherit"
          onClick={handleClick}
          data-testid={'hover-action-create'}
        >
          <AddOutlinedIcon />
        </IconButton>
      </Box>
    </Tooltip>
  );
};
