import { Api } from 'lib/Api';
import { SagaIterator } from 'redux-saga';
import { put } from 'redux-saga/effects';
import { apiCall } from 'state/ProcessApiRequest';
import { ApiReturnType } from 'type';
import { BOX_ACCOUNT_TREE_ACCOUNT_TREE_FETCHED } from './actions';

export const getAccountTreeRequest = function* (): SagaIterator {
  const accountTree: ApiReturnType<typeof Api.AccountTree.getAccountTree> =
    yield apiCall(Api.AccountTree.getAccountTree);

  yield put(BOX_ACCOUNT_TREE_ACCOUNT_TREE_FETCHED(accountTree));
};
