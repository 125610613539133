import React from 'react';
import { connect } from 'react-redux';
import './style.scss';
import { StoreState } from 'state/types';
import { getSummaryStatus } from 'state/Roster/Summary/selectors';
import { BOX_SUMMARY_SET_STATUS } from 'state/Roster/Summary';
import { SummaryStatus } from 'state/Roster/Summary/types';
import { getClasses } from '../../../../index';

type StateProps = {
  status: SummaryStatus,
};

type DispatchProps = {
  setStatus: (status: SummaryStatus) => void;
}

type Props = DispatchProps & StateProps;

export function SummaryTabs({status, setStatus }: Props) {

  return <div className="summary-tabs">
    <div className="summary-tabs__wrap">
      <div
        id="summary-total"
        onClick={(e) => {
          e.stopPropagation();
          setStatus('minimized')
        }}
        className={
          getClasses('summary-tabs__tab', {
            'summary-tabs__tab--active': status === 'minimized'
          })
        }>Total hours</div>
      <div
        id="summary-full"
        onClick={(e) => {
          e.stopPropagation();
          setStatus('visible')
        }}
        className={
          getClasses('summary-tabs__tab', {
            'summary-tabs__tab--active': status === 'visible'
          })
        }>Full Summary</div>
    </div>

  </div>
}

const mapStateToProps = (state: StoreState): StateProps => ({
  status: getSummaryStatus(state)
});

const mapDispatchToProps: DispatchProps = {
  setStatus: BOX_SUMMARY_SET_STATUS
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryTabs);
