import * as actions from './actions';

const channelActions = {
  global: actions.BOX_SOCKETS_GLOBAL_CHANNELS, // account, user, notifications, feature_service
  roster: actions.BOX_SOCKETS_ROSTER,
  roster_week: actions.BOX_SOCKETS_ROSTER_WEEK,
  manager_dashboard: actions.BOX_SOCKETS_MANAGER_DASHBOARD,
  employee_dashboard: actions.BOX_SOCKETS_EMPLOYEE_DASHBOARD,
  timesheets_weekly: actions.BOX_SOCKETS_TIMESHEETS_WEEKLY,
  timesheets_daily: actions.BOX_SOCKETS_TIMESHEETS_DAILY,
} as const;

export type Channel = keyof typeof channelActions;

export default channelActions;
