import * as React from 'react';
import { Button, Checkbox, DataTable as Table } from 'elmo-elements';
import { TableProps } from './type';
import { CreateOutlinedIcon } from 'element';
import { UserGroup } from 'type/models';
import './style.scss';

export class TableGroups extends React.Component<TableProps> {
  headerColumns: any = [];

  constructor(props: TableProps) {
    super(props);
    this.prepareHeaders();
  }

  componentDidUpdate(prevProps: TableProps) {
    this.prepareHeaders(this.props);
  }

  prepareHeaders = (props?: TableProps) => {
    const { groups, isFirst, combineGroup } = props ? props : this.props;

    this.headerColumns = [];

    this.headerColumns.push({
      title: combineGroup.name,
      textAlign: 'left',
      isHidden: false,
    });

    Object.keys(groups).forEach((id) => {
      this.headerColumns.push({
        title: this.getTitle(groups[id]),
        textAlign: 'center',
        isHidden: false,
        width: '10%',
      });
    });
  };

  renderPermission = (groupId: number, pId: number, ariaLabel: string) => {
    const { groups, onChange, combineGroupId } = this.props;
    let parentPermissions: number[] = [];
    const isChecked = groups[groupId].permission_ids.indexOf(pId) !== -1;
    const template =
      groups[groupId].type === 'custom' &&
      groups[groupId].template === 'line manager'
        ? 'manager'
        : groups[groupId].template;
    if (groups[groupId].template) {
      parentPermissions = this.parentGroup(template).permission_ids;
    }

    return (
      <Table.Td
        textAlign="center"
        key={groupId}
        className="user-groups__columns"
      >
        <Checkbox
          className={'permission-checkbox'}
          id={`permission-checkbox-${groupId}-${pId}`}
          isChecked={isChecked}
          isDisabled={
            groups[groupId].readonly ||
            groups[groupId].readonly_permission_ids.indexOf(pId) !== -1 ||
            (!!groups[groupId].template &&
              parentPermissions.indexOf(pId) === -1)
          }
          onChange={(e) => {
            onChange(e, pId, groupId, combineGroupId);
          }}
          ariaLabel={ariaLabel}
        />
      </Table.Td>
    );
  };

  getTitle(group: UserGroup) {
    const template =
      group.template === 'manager' ? 'Line manager' : group.template;
    return (
      <>
        {group.title}
        <div style={{ whiteSpace: 'nowrap', fontSize: '10px' }}>
          {template
            ? `(${template.replace(/^\w/, (c) => c.toUpperCase())})`
            : ''}
        </div>
      </>
    );
  }

  renderControls = () => {
    const { groups } = this.props;
    return (
      <Table.Tr headerColumns={this.headerColumns}>
        <Table.Td className="user-groups__columns">&nbsp;</Table.Td>
        {Object.keys(groups).map((id, key) => {
          return (
            <Table.Td
              textAlign="center"
              className="user-groups__columns"
              key={key}
            >
              {!groups[id].readonly && groups[id].template ? (
                <Button
                  className={'edit-group-btn'}
                  icon={<CreateOutlinedIcon />}
                  isText={true}
                  onClick={() => {
                    this.onEditButtonClick(id);
                  }}
                  ariaLabel="Edit permissions"
                />
              ) : (
                ''
              )}
            </Table.Td>
          );
        })}
      </Table.Tr>
    );
  };

  render() {
    const { index, combineGroup, groups, isLast } = this.props;
    return (
      <Table
        id={`permission-table-${index}`}
        key={index}
        className="user-groups"
        isFirstColumnSticky={true}
        isHeaderSticky={true}
        isFullWidth={true}
      >
        <Table.Header
          columns={this.headerColumns}
          headerColumns={this.headerColumns}
        />
        <Table.Body>
          {Object.keys(combineGroup.permissions).map((id, key) => {
            return (
              <Table.Tr headerColumns={this.headerColumns} key={key}>
                <Table.Td className="user-groups__columns">
                  {combineGroup.permissions[id].title}
                </Table.Td>
                {Object.keys(groups).map((groupId) => {
                  const pId = combineGroup.permissions[id].id;
                  const ariaLabel = combineGroup.permissions[id].title;
                  return this.renderPermission(+groupId, pId, ariaLabel);
                })}
              </Table.Tr>
            );
          })}

          {isLast ? this.renderControls() : <></>}
        </Table.Body>
      </Table>
    );
  }

  onEditButtonClick = (id: string) => {
    const { onSelectGroup } = this.props;
    onSelectGroup(id);
  };

  parentGroup = (name: string) => {
    const { groups } = this.props;
    const groupId = Object.keys(groups).find((id) => {
      return groups[id].name === name;
    });
    return groups[groupId as string];
  };
}
