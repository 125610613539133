import { createReducer } from 'lib/store-utils';
import { CovidTracingState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';

export const covidTracing = createReducer<CovidTracingState>({}, getDefaultState());

covidTracing.on(
  actions.BOX_COVID_TRACING_MODE_TOGGLE_SHIFT,
  (state, data): CovidTracingState => {
    if ( data.id === state.shiftId ) {
      return {
        ...state,
        shiftId: null,
        entity: null,
        shifts: {
          rostered_shift_ids: [],
          timesheet_ids: [],
          all: []
        }
      }
    }

    if ( data.id !== state.shiftId && state.shiftId !== null ) {
      return state;
    }

    return {
      ...state,
      isLoading: true,
      shiftId: data.id,
      entity: data.entity
    };
  }
);

covidTracing.on(
  actions.BOX_COVID_TRACING_MODE_ENABLE,
  (state): CovidTracingState => ({
    ...state,
    isEnabled: true
  })
);


covidTracing.on(
  actions.BOX_COVID_TRACING_MODE_DISABLE,
  (state): CovidTracingState => ({
    ...state,
    isEnabled: false,
    shiftId: null,
    entity: null,
    shifts: {
      rostered_shift_ids: [],
      timesheet_ids: [],
      all: []
    }
  })
);

covidTracing.on(
  actions.BOX_COVID_TRACING_MODE_SET_SHIFT_SUCCESS,
  (state, data): CovidTracingState => ({
    ...state,
    isLoading: false,
    shifts: {
      rostered_shift_ids: data.rostered_shift_ids,
      timesheet_ids: data.timesheet_ids,
      all: [...data.rostered_shift_ids, ...data.timesheet_ids]
    }
  })
);

covidTracing.on(
  actions.BOX_COVID_TRACING_MODE_SET_SHIFT_FAILURE,
  (state, errors): CovidTracingState => ({
    ...state,
    isLoading: false
  })
);

covidTracing.on(
  actions.BOX_COVID_TRACING_MODE_SET_PERIOD,
  (state, period): CovidTracingState => ({
    ...state,
    period
  })
);

covidTracing.on(
  actions.BOX_COVID_TRACING_MODE_UNSET_SHIFT,
  (state): CovidTracingState => ({
    ...state,
    shiftId: null,
    entity: null,
    shifts: {
      rostered_shift_ids: [],
      timesheet_ids: [],
      all: []
    }
  })
);

covidTracing.on(
  actions.BOX_COVID_TRACING_MODE_DOWNLOAD_REPORT,
  (state, data): CovidTracingState => ({
      ...state,
      isLoading: true,
      printData: []
    }
  )
);

covidTracing.on(
  actions.BOX_COVID_TRACING_MODE_DOWNLOAD_REPORT_SUCCESS,
  (state, data): CovidTracingState => ({
      ...state,
      isLoading: false,
      printData: data,
      isPrinting: true
    }
  )
);

covidTracing.on(
  actions.BOX_COVID_TRACING_MODE_DOWNLOAD_REPORT_FAILURE,
  (state, data): CovidTracingState => ({
      ...state,
      isLoading: false
    }
  )
);

covidTracing.on(
  actions.BOX_COVID_TRACING_MODE_DOWNLOAD_REPORT_STATE,
  (state, value): CovidTracingState => ({
      ...state,
      isPrinting: value
    }
  )
);
