import React, { Component, ComponentProps, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Duration, Moment } from 'moment';
import { Timesheet } from 'type';
import { rosterPageLabels } from 'messages';
import { SERVER_DAY_FORMAT } from 'lib/config';
import { StoreState } from 'state/types';
import { fromBySiteTimezoneSelector } from 'state/RosteredShifts';
import {
  RosterFilterByType,
  shiftTypesToBeShownSelector,
} from 'state/Roster/RosterFilters';
import {
  BOX_ROSTER_DAY_VIEW_CREATE_SHIFT_START,
  BOX_ROSTER_DAY_VIEW_DRAG_AND_DROP_USER_VIEW_FINISHED,
  BOX_ROSTER_DAY_VIEW_UPDATE_ROSTERED_SHIFT_TIME_REQUEST,
  BOX_ROSTER_DAY_VIEW_UPDATE_TIMESHEET_TIME_REQUEST,
  CreateShiftStartPayload,
  DragAndDropUserViewFinishedPayload,
  draggableShiftAreaIdRoleIdSelector,
  DraggableShiftRoleSelector,
  RosterDayViewCreatedShiftType,
  UpdateShiftTimePayload,
  UserRosterData,
} from 'state/Roster/RosterDayView';
import { BOX_ROSTER_SHIFT_MODAL_GET_BY_ID } from 'state/Roster/RosterShiftModal';
import { GetRosterByIDPayload } from 'state/Roster/RosterShiftModal/types';
import { GetTimesheetByIDPayload } from 'state/TimesheetModal/types';
import { BOX_TIMESHEET_SHIFT_MODAL_GET_BY_ID } from 'state/TimesheetModal';
import { RosterRow, RosterUserLegend } from '../../../../../../components';
import {
  DayUserRosteredShift,
  DayUserTimesheet,
  Timeline,
  WithLegendData,
} from '../../../../components';
import { DayUserUnavailability } from '../';
import { DayUserCreatedShift } from './DayUserCreatedShift';
import { calculateStart } from '../../../../helpers';
import { Avatar } from 'oxygen-elements';

type OwnProps = {
  userRosterData: UserRosterData;
};

type StateProps = {
  shiftTypesToBeShown: string[];
  from: Moment;
  draggableShiftRole: DraggableShiftRoleSelector;
};

type DispatchProps = {
  updateRosteredShiftTime: (payload: UpdateShiftTimePayload) => void;
  updateTimesheetTime: (payload: UpdateShiftTimePayload) => void;
  getRosterById: (payload: GetRosterByIDPayload) => void;
  getTimesheetById: (payload: GetTimesheetByIDPayload) => void;
  createShift: (payload: CreateShiftStartPayload) => void;
  dragAndDropFinished: (payload: DragAndDropUserViewFinishedPayload) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

class DayUserRowComponent extends Component<Props> {
  private readonly cellType: 'full' = 'full';

  render() {
    const {
      userRosterData: { user, duration },
    } = this.props;

    return (
      <RosterRow isDisabled={!user.is_active}>
        {{
          sticky: (
            <WithLegendData duration={duration}>
              {({ durations }) => (
                <RosterUserLegend
                  type={this.rows.length > 1 ? 'multi-row' : 'single-row'}
                  icon={
                    <Avatar
                      size="small"
                      src={user.avatar.src}
                      alt={user.prefered_or_full_name}
                      light
                    />
                  }
                  label={user.prefered_or_full_name}
                  durations={durations}
                  pageType={'day'}
                />
              )}
            </WithLegendData>
          ),
          content: <Timeline rows={this.rows} />,
        }}
      </RosterRow>
    );
  }

  // TODO refactor
  private get rows(): ComponentProps<typeof Timeline>['rows'] {
    const { shiftTypesToBeShown } = this.props;

    if (!shiftTypesToBeShown.length) {
      return [
        {
          content: null,
        },
      ];
    }

    const { data, user } = this.props.userRosterData;
    const rows: ComponentProps<typeof Timeline>['rows'] = [];

    const typedShiftTipesToBeShown =
      shiftTypesToBeShown as RosterFilterByType[];
    if (typedShiftTipesToBeShown.includes('unavailability')) {
      rows.push({
        content: this.renderUnavailability(),
        label: rosterPageLabels.unavailability,
      });
    }

    if (typedShiftTipesToBeShown.includes('roster')) {
      const { hasShiftsWithEvents } = data.roster;

      rows.push({
        isTall: hasShiftsWithEvents,
        content: this.renderRosteredShifts(rows.length),
        label: rosterPageLabels.rosteredShift,
        onMouseDown: this.mouseDownCallBackRosteredShift,
        onDrop: this.dropCallBack,
        validateDragOver: this.validateDragOver,
      });
    }

    if (typedShiftTipesToBeShown.includes('timesheets')) {
      const { timesheets } = data;

      if (!timesheets.length) {
        rows.push({
          label: rosterPageLabels.timesheet,
          content: (
            <DayUserCreatedShift
              cellType={this.cellType}
              shiftType="timesheet"
              userId={user.id}
              rowIndex={rows.length}
            />
          ),
          onMouseDown: this.mouseDownCallBackTimesheet,
        });
      }

      timesheets.forEach((timesheetsRow) => {
        rows.push({
          label: rosterPageLabels.timesheet,
          content: (
            <>
              {this.renderTimesheets(timesheetsRow)}
              <DayUserCreatedShift
                cellType={this.cellType}
                shiftType="timesheet"
                userId={user.id}
                rowIndex={rows.length}
              />
            </>
          ),
          onMouseDown: this.mouseDownCallBackTimesheet,
        });
      });
    }

    return rows;
  }

  private renderUnavailability = (): ReactNode => {
    const { userRosterData, from } = this.props;

    return (
      <>
        {userRosterData.data.unavailability.map((rosterTimeOff) => (
          <DayUserUnavailability
            key={`${rosterTimeOff.start.format(SERVER_DAY_FORMAT)}_${
              rosterTimeOff.id
            }`}
            rosterTimeOff={rosterTimeOff}
            day={from}
          />
        ))}
      </>
    );
  };

  private renderRosteredShifts = (rowIndex: number): ReactNode => {
    const {
      userRosterData: {
        user,
        data: {
          roster: { data },
        },
      },
      from,
    } = this.props;

    return (
      <>
        {data.map((rosteredShift) => (
          <DayUserRosteredShift
            key={rosteredShift.id}
            rosteredShift={rosteredShift}
            day={from}
            type={this.cellType}
            onResizeEnd={this.props.updateRosteredShiftTime}
            isEditable={user.is_active}
          />
        ))}
        <DayUserCreatedShift
          cellType={this.cellType}
          shiftType="rosteredShift"
          userId={user.id}
          rowIndex={rowIndex}
        />
      </>
    );
  };

  private renderTimesheets = (timesheetsRow: Timesheet[]): ReactNode => {
    const {
      userRosterData: { user },
      from,
    } = this.props;

    return timesheetsRow.map((timesheet) => (
      <DayUserTimesheet
        key={timesheet.id}
        timesheet={timesheet}
        day={from}
        type={this.cellType}
        onResizeEnd={this.props.updateTimesheetTime}
        onClick={this.props.getTimesheetById}
        isEditable={user.is_active}
      />
    ));
  };

  private getUserId = (): string => {
    const { user } = this.props.userRosterData;

    return user.id;
  };

  private dropCallBack = (duration: Duration) => {
    if (!this.validateDragOver()) {
      return;
    }

    const { from, dragAndDropFinished } = this.props;

    const newStartTime = calculateStart(from, duration);
    const userId = this.getUserId();

    dragAndDropFinished({
      newStartTime,
      userId,
    });
  };

  private validateDragOver = (): boolean => {
    const {
      draggableShiftRole,
      userRosterData: {
        user: { user_roles },
      },
    } = this.props;

    return user_roles.some(
      (userRole) =>
        userRole.area_id === draggableShiftRole.area_id &&
        userRole.role_id === draggableShiftRole.role_id
    );
  };

  private mouseDownCallBackCreator =
    (type: RosterDayViewCreatedShiftType) =>
    (duration: Duration, clientX: number, rowIndex: number) => {
      const { userRosterData, createShift, from } = this.props;

      if (userRosterData.user.is_active) {
        const payload: CreateShiftStartPayload = {
          shift: {
            start: calculateStart(from, duration),
            user_id: this.getUserId(),
          },
          clientX,
          type,
          pairIndex: rowIndex,
        };

        createShift(payload);
      }
    };

  private mouseDownCallBackRosteredShift =
    this.mouseDownCallBackCreator('rosteredShift');
  private mouseDownCallBackTimesheet =
    this.mouseDownCallBackCreator('timesheet');
}

const mapStateToProps = (state: StoreState): StateProps => ({
  shiftTypesToBeShown: shiftTypesToBeShownSelector(state),
  from: fromBySiteTimezoneSelector(state),
  draggableShiftRole: draggableShiftAreaIdRoleIdSelector(state),
});

const mapDispatchToProps: DispatchProps = {
  updateRosteredShiftTime:
    BOX_ROSTER_DAY_VIEW_UPDATE_ROSTERED_SHIFT_TIME_REQUEST,
  updateTimesheetTime: BOX_ROSTER_DAY_VIEW_UPDATE_TIMESHEET_TIME_REQUEST,
  getRosterById: BOX_ROSTER_SHIFT_MODAL_GET_BY_ID,
  getTimesheetById: BOX_TIMESHEET_SHIFT_MODAL_GET_BY_ID,
  createShift: BOX_ROSTER_DAY_VIEW_CREATE_SHIFT_START,
  dragAndDropFinished: BOX_ROSTER_DAY_VIEW_DRAG_AND_DROP_USER_VIEW_FINISHED,
};

export const DayUserRow = connect(
  mapStateToProps,
  mapDispatchToProps
)(DayUserRowComponent);
