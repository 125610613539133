import React from 'react';
import classNames from 'clsx';
import { Paragraph } from 'elmo-elements';
import { DefaultDivProps } from 'type';
import './styles.scss';
import { ShiftCardButtonDelete } from './ShiftCardButtonDelete';
import { ShiftCardPopperAdd } from './ShiftCardPopperAdd';

export const shiftCardViewClass = 'js-shift-card-view';

export type ShiftCardViewProps = DefaultDivProps & {
  colorId: number | undefined;
  coloredBg: boolean;
  from: string;
  to: string;
  isSelected?: boolean;
  isHighlighted?: boolean;
  icons: React.ReactNode;
  breaks: React.ReactNode;
  isLocationShown: boolean;
  timeColor?: 'default' | 'error';
  beforeContent?: React.ReactNode;
  title: string;
  spotTitle?: string;
  shape?: 'full' | 'top' | 'bottom';
  fullHeight?: boolean;
  withoutShadow?: boolean;
  onClickAddButton?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onClickDeleteIcon?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  eventName?: false | string;
};

export const ShiftCardView = ({
  colorId,
  coloredBg,
  shape = 'full',
  fullHeight = false,
  title,
  icons,
  from,
  to,
  withoutShadow,
  isSelected,
  isHighlighted,
  spotTitle,
  breaks,
  onClickAddButton,
  onClickDeleteIcon,
  eventName,
  isLocationShown,
  timeColor,
  beforeContent,
  ...divProps
}: ShiftCardViewProps) => {
  const specHeightClassName = 'shift-card-view__row--spec-height';
  return (
    <div
      {...divProps}
      className={classNames(
        divProps.className,
        shiftCardViewClass,
        'shift-card-view',
        `shift-card-view--${shape}`,
        `role-color-${colorId}-primary`,
        {
          'shift-card-view--clickable': divProps.onClick,
          'shift-card-view--colored': typeof colorId === 'number',
          'shift-card-view--filled-bg': coloredBg,
          'shift-card-view--without-shadow': withoutShadow,
          'shift-card-view--full-height': fullHeight,
          'shift-card-view--selected': isSelected,
          'shift-card-view--highlighted': isHighlighted,
        }
      )}
    >
      {beforeContent}
      <div className="shift-card-view__content shift-card-view__content-improved shift-card-view__content-improved-v2">
        <div
          className={classNames('shift-card-view__location', {
            'shift-card-view__location--transparent': !isLocationShown,
          })}
        />
        <div className="shift-card-view__descr">
          <div
            className={classNames('shift-card-view__row', {
              [specHeightClassName]: eventName,
            })}
          >
            <div className="shift-card-view__title">
              <div
                className={classNames('shift-card-view__time', [
                  timeColor !== 'default' &&
                    `shift-card-view__time--${timeColor}`,
                ])}
              >
                {from}-{to}
              </div>
            </div>
            {!!onClickDeleteIcon && (
              <ShiftCardButtonDelete
                className="shift-card-view__button-delete"
                onClick={onClickDeleteIcon}
              />
            )}
          </div>

          <>
            <div
              className={classNames('shift-card-view__row', {
                [specHeightClassName]: eventName,
              })}
            >
              <div className="shift-card-view__title">
                <h3>
                  {title} - {spotTitle}
                </h3>
              </div>
            </div>
            {eventName && (
              <div
                className={classNames(
                  'shift-card-view__row',
                  specHeightClassName
                )}
              >
                <div
                  className="shift-card-view__title"
                  data-testid="shift-event-name"
                >
                  <Paragraph
                    color={'black'}
                    size={'xs'}
                    isTruncate={true}
                    isBold={true}
                  >
                    {eventName}
                  </Paragraph>
                </div>
              </div>
            )}
            <div
              className={classNames(
                'shift-card-view__row',
                'justify-content-between',
                'align-items-center',
                {
                  [specHeightClassName]: eventName,
                }
              )}
            >
              {breaks}
              <div className="shift-card-view__icons">{icons}</div>
            </div>
          </>
        </div>
      </div>
      {onClickAddButton && (
        <ShiftCardPopperAdd
          onClick={onClickAddButton}
          className="shift-card-view__add-btn"
        >
          + Add another shift
        </ShiftCardPopperAdd>
      )}
    </div>
  );
};
