import React, { ReactText } from 'react';
import {
  Select,
  FormContainer,
  FormItem,
  RadioButtons,
  Radio,
  Row,
  Col,
} from 'elmo-elements';
import { Data, DropDownProps } from '../type';
import { useSelector } from 'react-redux';
import {
  orderDropDownOptions,
  groupDropDownOptions,
} from 'state/Account/selectors';
import { getSelectedValue } from '../helpers';
import { FlagWrapper } from 'element';

export type DataProps = {
  handleOnChangeSelect: (e: DropDownProps, name: string) => void;
  handleOnChangeTimeFormat: (value: ReactText) => void;
  handleOnChangeStatus?: (e: DropDownProps) => void;
  data: Data;
};

const options = [
  {
    label: 'All statuses',
    value: 'all',
  },
  {
    label: 'Approved',
    value: 'approved',
  },
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'In progress',
    value: 'in_progress',
  },
];

const GroupByOrderByTimeFormatGroup = ({
  data,
  handleOnChangeTimeFormat,
  handleOnChangeSelect,
  handleOnChangeStatus,
}: DataProps) => {
  const orderDropDown = useSelector(orderDropDownOptions);
  const groupDropDown = useSelector(groupDropDownOptions);

  return (
    <FormContainer maxColumns={3} className="flex-start-align">
      <FormItem label={'Grouped by'} isFluid={false} isBlock={true}>
        <Select
          name={'group_by'}
          options={groupDropDown}
          onChange={(e) => handleOnChangeSelect(e, 'group_by')}
          defaultValue={getSelectedValue(groupDropDown, data.group_by)}
          value={getSelectedValue(groupDropDown, data.group_by)}
          ariaLabel="Grouped by"
        />
      </FormItem>
      <FormItem label={'Ordered by'} isFluid={false}>
        <Select
          name={'order_by'}
          options={orderDropDown}
          onChange={(e) => handleOnChangeSelect(e, 'order_by')}
          defaultValue={getSelectedValue(orderDropDown, data.order_by)}
          value={getSelectedValue(orderDropDown, data.order_by)}
          ariaLabel="Ordered by"
        />
      </FormItem>
      <FormItem isFluid={false}>
        <Row isNoGutters={true} role="presentation" className="row-two-cols">
          <Col span={12} role="presentation" className={'form-item-small'}>
            <FormItem label={'Time format'} isFluid={false} isInline={true}>
              <RadioButtons
                selected={data.time_format}
                onChange={handleOnChangeTimeFormat}
                type={'outlined'}
                direction={'horizontal'}
              >
                <Radio value={'HH:mm'}>HH:MM</Radio>
                <Radio value={'Decimal'}>Decimal</Radio>
              </RadioButtons>
            </FormItem>
          </Col>
          <Col span={12} role="presentation" className={'form-item-small'}>
            {handleOnChangeStatus && (
              <FlagWrapper.timesheetsReportFilter>
                <FormItem label={'Status'} isFluid={false} isInline={true}>
                  <Select
                    name={'status'}
                    options={options}
                    onChange={handleOnChangeStatus}
                    defaultValue={[]}
                    value={getSelectedValue(options, data.status)}
                    ariaLabel="Status"
                  />
                </FormItem>
              </FlagWrapper.timesheetsReportFilter>
            )}
          </Col>
        </Row>
      </FormItem>
    </FormContainer>
  );
};

export default GroupByOrderByTimeFormatGroup;
