import React from 'react';
import { connect } from 'react-redux';
import { FlatAreaRoleFilter } from 'element';
import { StoreState } from 'state/types';
import {
  BOX_ROSTER_FILTERS_OPEN_AREA_ROLE_FLAT_FILTERS,
  getFiltersFormAreas,
  getFiltersFormRoles
} from 'state/Roster/RosterFilters';

type OwnProps = {};

type StateProps = {
  areaIds: string[] | null;
  roleIds: string[] | null;
};

type DispatchProps = {
  openFiltersModal: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const RosterFlatRoleTableComponent = (props: Props) => (
  <FlatAreaRoleFilter {...props} id="roster-custom-filters-flat-table" />
);

const mapStateToProps = (state: StoreState): StateProps => ({
  areaIds: getFiltersFormAreas(state),
  roleIds: getFiltersFormRoles(state)
});

const mapDispatchToProps: DispatchProps = {
  openFiltersModal: BOX_ROSTER_FILTERS_OPEN_AREA_ROLE_FLAT_FILTERS
};

export const RosterFlatRoleTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(RosterFlatRoleTableComponent);
