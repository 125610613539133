import { StoreState } from '../types';
import {
  PreferencesDateFormat,
  PreferencesTimeFormat,
  RosteredShift,
  TimesheetPayload,
} from 'type';
import {
  AllowancesOption,
  HigherDutiesOption,
  TimesheetModalProps,
} from './types';
import { createSelector } from 'reselect';
import _ from 'lodash';
import {
  getCurrencyFormatted,
  getDateTimeFormatted,
  getTimeFormatted,
} from 'lib/helpers';
import {
  getCurrencyCode,
  getCurrencyPlacement,
  getDateFormat,
  getNumberFormat,
  getTimeFormat,
} from '../Account';
import { isAppMarket } from 'helpers';

const getState = (state: StoreState) => state.timesheetModal;

export const getIsUpdating = (state: StoreState): boolean =>
  getState(state).isUpdating;
export const getCurrentTimesheet = (state: StoreState): TimesheetPayload =>
  getState(state).currentTimesheet;
export const getTimesheetPayload = (state: StoreState): TimesheetPayload =>
  getState(state).timesheetPayload;
export const getTimesheetModal = (state: StoreState): TimesheetModalProps =>
  getState(state).timesheetModal;

export const getTimesheetModalErrors = (state: StoreState) =>
  getTimesheetModal(state).errors;

export const getTimesheetIgnoreErrors = (state: StoreState): boolean =>
  getState(state).overlapShiftModal.ignoreErrors;

export const getIsLoadingShifts = (state: StoreState): boolean =>
  getState(state).timesheetModal.isLoadingShifts;

export const getAllowedShifts = (state: StoreState): RosteredShift[] =>
  _.toArray(state.timesheetModal.timesheetModal.allowedShifts);

export const getAllowedShiftsDropDownProps = createSelector(
  getAllowedShifts,
  getCurrentTimesheet,
  getDateFormat,
  getTimeFormat,
  (
    shifts: RosteredShift[],
    currentTimesheet: TimesheetPayload,
    dateFormat: PreferencesDateFormat,
    timeFormat: PreferencesTimeFormat
  ) => {
    const { id } = currentTimesheet;
    const length = shifts.length;
    let label = 'Searching...';
    if (length > 0) {
      label = isAppMarket('uk')
        ? 'Shift Available'
        : 'Rostered Shift Available';
    }
    if (length === 0) {
      label = isAppMarket('uk')
        ? 'Shift Undetected'
        : 'Rostered Shift Undetected';
    }
    const emptyValue = { label: label, value: '' };
    const dropDown: { label: string; value: string }[] =
      length > 0 ? [emptyValue] : [];
    if (id) {
      _.map(shifts, (shift: RosteredShift) => {
        const start = getDateTimeFormatted(
          dateFormat,
          timeFormat,
          shift.start,
          true
        );
        const end = getTimeFormatted(timeFormat, shift.end);
        dropDown.push({
          label: `${start} - ${end}`,
          value: shift.id,
        });
      });
    }
    return dropDown;
  }
);

export const getHigherDutiesOptions = (
  state: StoreState
): HigherDutiesOption[] => {
  const { dutiesList } = state.timesheetModal;
  const options: HigherDutiesOption[] = [
    {
      label: 'No higher duty selected',
      value: '',
    },
  ];
  const currencyCode = getCurrencyCode(state);
  const currencyPlacement = getCurrencyPlacement(state);
  const numberFormat = getNumberFormat(state);
  if (dutiesList) {
    _.map(dutiesList, (item) => {
      const value = getCurrencyFormatted(
        numberFormat,
        currencyCode,
        currencyPlacement,
        item.rate
      );
      options.push({
        label: `${item.name} - ${value} (${item.stream_name})`,
        value: item.id,
      });
    });
  }
  return options;
};

export const canSaveAward = (state: StoreState): boolean => {
  const { recalculated, timesheetPayload, initialDuty } = state.timesheetModal;
  const { start, end, higher_duty_end, higher_duty_start, higher_duty } =
    timesheetPayload;
  if (
    recalculated &&
    higher_duty === null &&
    initialDuty.higher_duty !== higher_duty
  ) {
    return true;
  }
  if (recalculated && higher_duty_start && higher_duty_end) {
    const f = 'YYYY-MM-DD HH:mm';
    const startF = start.format('x');
    const higherStartF = higher_duty_start.format('x');
    const endF = end!.format(f);
    const higherEndF = higher_duty_end.format(f);
    return startF <= higherStartF && higherEndF <= endF;
  }
  return false;
};

export const getAllowancesOptions = (state: StoreState): AllowancesOption[] => {
  const { allowancesList } = state.timesheetModal;
  const options: AllowancesOption[] = [
    {
      label: 'Select an allowance',
      value: '',
    },
  ];
  _.map(allowancesList, (item) => {
    options.push({
      label: item.name,
      value: item.id,
    });
  });
  return options;
};
