import { Grid } from 'extended-oxygen-elements';
import React from 'react';
import { useSelector } from 'react-redux';
import { areasBySiteIdSelector } from 'state/AccountTree';
import TileArea from './TileArea';
import TileAreaCreate from './TileAreaCreate';
import { getIsArchivedFromURL } from './helpers';

type Props = {
  site_id: string;
  disabled: boolean;
};

export default function GroupAreas({ site_id, disabled }: Props) {
  const areas = useSelector(areasBySiteIdSelector);

  const renderAreaBox = (area: any) => {
    if (!getIsArchivedFromURL() && area.archived) {
      return null;
    }
    return (
      <Grid item xs={12} sm={6} md={4} key={area.id}>
        <TileArea disabled={disabled} id={area.id} />
      </Grid>
    );
  };

  return (
    <Grid container spacing={2}>
      {areas[site_id].map((area) => renderAreaBox(area))}
      {!disabled && (
        <Grid item xs={12} sm={6} md={4}>
          <TileAreaCreate site_id={site_id} />
        </Grid>
      )}
    </Grid>
  );
}
