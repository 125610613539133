import React, { ReactNode } from 'react';
import { isHrService } from 'helpers/helpers';

type Props = {
  serviceName: any;
  children: ReactNode;
  negate?: boolean
};

// TODO remove this wrapper
export const IsHrService = ({
  children,
  serviceName,
  negate = false
}: Props) => {
  const isService = isHrService(serviceName);
  const condition = negate ? !isService : isService;
  return condition ? <>{children}</> : null;
};
