import { createAction } from 'lib/store-utils';
import { UpdateFormPayload } from 'type';
import { UpdateFilterFormPayload, UpdateFiltersByTypePayload } from './types';

export const BOX_ROSTER_FILTERS_UPDATE_FILTERS_BY_TYPE =
  createAction<UpdateFiltersByTypePayload>(
    'Roster filters / update filters by type'
  );
export const BOX_ROSTER_FILTERS_FILTER_BY_TYPE_OPENED = createAction(
  'Roster filters / filter by type / opened'
);
export const BOX_ROSTER_FILTERS_FILTER_BY_TYPE_CLOSED = createAction(
  'Roster filters / filter by type / closed'
);

export const BOX_ROSTER_FILTERS_UPDATE_AREA_ROLE_CHECKBOXES =
  createAction<UpdateFormPayload>(
    'Roster filters / update area role checkboxes'
  );
export const BOX_ROSTER_FILTERS_UPDATE_FILTER_FORM_FIELD =
  createAction<UpdateFormPayload>('Roster filters / update filter form field');
export const BOX_ROSTER_FILTERS_UPDATE_FILTER_FORM =
  createAction<UpdateFilterFormPayload>('Roster filters / update filter form');
export const BOX_ROSTER_FILTERS_CLEAR_FILTER_FORM = createAction(
  'Roster filters / clear filter form'
);
export const BOX_ROSTER_FILTERS_SUBMIT_FILTER_FORM = createAction(
  'Roster filters / Update custom filters'
);
export const BOX_ROSTER_FILTERS_RESET_ALL_FILTERS = createAction(
  'Roster filters / Reset all filters'
);
export const BOX_ROSTER_FILTERS_RESET_CUSTOM_FILTERS = createAction(
  'Roster filters / Reset custom filters'
);

export const BOX_ROSTER_FILTERS_OPEN_AREA_ROLE_TRIPLE_FILTERS = createAction(
  'Roster filters / open area role triple filters'
);
export const BOX_ROSTER_FILTERS_OPEN_AREA_ROLE_FLAT_FILTERS = createAction(
  'Roster filters / open area role flat filters'
);

export const BOX_ROSTER_FILTERS_TOGGLE_TIMESHEETS_FILTER =
  createAction<boolean>('Roster filters / toggle timesheets filter');
export const BOX_ROSTER_FILTERS_RESET_SHOW_PEOPLE = createAction(
  'Roster filters / reset show people filter'
);
