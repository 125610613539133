import _ from 'lodash';
import { CreateUserPayload } from '../../state/Users/Users/types';
import { AccountTreeArea, AccountTreeRole, SelectPropsOption } from '../../type/models';
import { StringMap } from '../../type';

export const isValid = (user: CreateUserPayload) => {
  for (let key in user) {
    if ( key !== 'user_group_id' ) {
      if ( user.hasOwnProperty(key) && _.isEmpty(user[key as keyof Partial<CreateUserPayload>]) ) {
        return false;
      }
    }
  }
  return true;
};

const getOptionsForSelectedSite = (currentSiteAreas: AccountTreeArea[], roles: StringMap<AccountTreeRole>) => {
  const options: SelectPropsOption[] = [];
  if ( currentSiteAreas ) {
    currentSiteAreas.forEach((area: AccountTreeArea) => {
      const { role_ids } = area;
      if ( role_ids.length ) {
        for (let roleId of role_ids) {
          const role = roles[roleId];
          if ( role ) {
            options.push({
              label: `${area.name} - ${role.name}`,
              value: `${area.id}--${role.id}`
            });
          }
        }
      }
    });
  }
  return options;
}

export const availableUserRoles = (
  areasBySite: StringMap<AccountTreeArea[]>,
  roles: StringMap<AccountTreeRole>,
  siteId?: string
) => {
  if ( siteId ) {
    return getOptionsForSelectedSite(areasBySite[siteId], roles);
  }
  const options: SelectPropsOption[] = [];
  const keys = Object.keys(areasBySite);
  keys.forEach(siteID => {
    const areas = areasBySite[siteID];
    options.push(...getOptionsForSelectedSite(areas, roles));
  })
  return _.uniqBy(options, 'value');
};