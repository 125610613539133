import React from 'react';
import './style.scss';
import { Col, Row, Tooltip } from 'elmo-elements';
import { StoreState } from 'state/types';
import { getSummaryStatus } from 'state/Roster/Summary/selectors';
import { connect } from 'react-redux';
import { SummaryStatus } from 'state/Roster/Summary/types';

type StateProps = {
  status: SummaryStatus
};

type OwnProps = {
  title: string;
  shiftCount: number;
  userCount: number;
  currency: string | null;
  duration: string | null;
  className?: string
};

type Props = OwnProps & StateProps;

export function SummaryInfo(props: Props) {

  const {
    title,
    shiftCount,
    userCount,
    currency,
    duration,
    status,
    className
  } = props;

  return <div className={`summary-info ${className ? className : ''}`}>
    <Row isNoGutters={true} className="summary-info__raw">
      <Col className="summary-info__label summary-info__title"  span={11}>
        {title}
      </Col>
      <Col className="summary-info__value"  span={13}>
        <Tooltip
          title={`${duration} ${currency ? `(${currency})` : '' }`}
          placement={'left-start'}
        >
          <div
            data-testid="durations"
            className="summary-info__value-long"
            onClick={(e) => e.stopPropagation()}
          >
            {duration} {currency && <span className="summary-info__currency">({currency})</span>}
          </div>
        </Tooltip>
      </Col>
    </Row>

    {status === 'visible' && <>
      <Row isNoGutters={true} className="summary-info__raw">
        <Col className="summary-info__label" span={11}>
          Shifts
        </Col>
        <Col className="summary-info__value" span={13}>
          <span data-testid="shifts-count">{shiftCount}</span>
        </Col>
      </Row>

      <Row isNoGutters={true} className="summary-info__raw">
        <Col className="summary-info__label" span={11}>
          Staff
        </Col>
        <Col className="summary-info__value" span={13}>
          <span data-testid="users-count">{userCount}</span>
        </Col>
      </Row>
    </>}
  </div>
}

const mapStateToProps = (state: StoreState): StateProps => ({
  status: getSummaryStatus(state)
});

export default connect(mapStateToProps)(SummaryInfo)
