import React, { Component } from 'react';
import moment, { Moment } from 'moment-timezone';
import { Omit } from 'type';
import { DateInput, DateInputOwnProps } from '../';

type ClearableProps = Omit<
  DateInputOwnProps,
  'value' | 'onChange' | 'isClearable'
> & {
  value: Moment | null;
  onChange: (value: Moment | null, name: string) => void;
  isClearable?: true;
  onTodayButton?: () => void;
};

type NotClearableProps = Omit<
  DateInputOwnProps,
  'value' | 'onChange' | 'isClearable'
> & {
  value: Moment | null;
  onChange: (value: Moment, name: string) => void;
  isClearable: false;
  tabIndex?: number;
  onTodayButton?: () => void;
};

type Props = ClearableProps | NotClearableProps;

export class DateInputMoment extends Component<Props> {
  render() {
    const { value, onChange, ...restProps } = this.props;

    return (
      <DateInput
        {...restProps}
        value={this.value}
        onChange={this.onChange}
        tabIndex={this.props.tabIndex || 0}
      />
    );
  }

  private get value() {
    const { value } = this.props;

    if (!value) {
      return null;
    }

    return value
      .clone()
      .local(true)
      .toDate();
  }

  private onChange = (value: Date | null, name: string) => {
    (this.props.onChange as ClearableProps['onChange'])(
      value ? moment(value) : value,
      name
    );
  };
}
