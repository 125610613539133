import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { SagaAction } from 'type';
import {
  HigherDutiesRequest,
  HigherDutiesResponse,
  TimesheetRecalculateRequest,
  TimesheetRecalculateResponse,
  TimesheetUpdateRequest,
  TimesheetUpdateResponse,
} from 'lib/Api/type';
import Api from 'lib/Api';
import { formatError } from 'state/helpers';
import { processApiRequest } from 'state/ProcessApiRequest';
import * as fetchActions from 'state/FetchPageData';
import {
  BOX_IS_LOADING_OFF,
  BOX_IS_LOADING_ON,
  MANAGER_DASHBOARD_TIMESHEETS_AWARDS_MODAL as IS_LOADING,
  MANAGER_DASHBOARD_TIMESHEETS_AWARDS_MODAL_HIGHER_DUTIES as IS_LOADING_HIGHER_DUTIES,
} from 'state/IsLoading';
import { BOX_TIMESHEET_UPDATE_ONE } from 'state/TimesheetsCollection';
import { AwardsModalRecalculateRequestPayload } from '../types';
import * as actions from '../actions';
import { getCurrentTimesheet, updatePayloadSelector } from '../selectors';

const fetchModalData = function* (): SagaIterator {
  yield put(
    fetchActions.BOX_FETCH_PAGE_DATA_REQUEST(
      fetchActions.MANAGER_DASHBOARD_TIMESHEETS_AWARDS_MODAL
    )
  );

  const currentTimesheet: ReturnType<typeof getCurrentTimesheet> = yield select(
    getCurrentTimesheet
  );

  try {
    const higherDutiesRequest: HigherDutiesRequest = {
      user_id: currentTimesheet.user_id,
      role_id: currentTimesheet.role_id,
      date: currentTimesheet.start.clone(),
    };

    const higherDuties: HigherDutiesResponse = yield call(
      processApiRequest,
      Api.EmployeeDashboard.getHigherDuties,
      higherDutiesRequest
    );

    yield put(
      actions.BOX_TIMESHEETS_AWARDS_MODAL_SUCCESS({
        higherDuties,
      })
    );
    yield put(
      fetchActions.BOX_FETCH_PAGE_DATA_SUCCESS(
        fetchActions.MANAGER_DASHBOARD_TIMESHEETS_AWARDS_MODAL
      )
    );
  } catch (error) {
    yield put(
      fetchActions.BOX_FETCH_PAGE_DATA_FAILURE(
        fetchActions.MANAGER_DASHBOARD_TIMESHEETS_AWARDS_MODAL
      )
    );
  }
};

const recalculate = function*({
  payload
}: SagaAction<AwardsModalRecalculateRequestPayload>): SagaIterator {
  yield put(BOX_IS_LOADING_ON(IS_LOADING_HIGHER_DUTIES));

  try {
    const apiPayload: TimesheetRecalculateRequest = payload;

    const pay_entries: TimesheetRecalculateResponse = yield call(
      processApiRequest,
      Api.Timesheet.recalculate,
      apiPayload
    );

    const { higher_duty, higher_duty_start, higher_duty_end } = payload;

    yield put(
      actions.BOX_TIMESHEETS_AWARDS_MODAL_RECALCULATE_SUCCESS({
        pay_entries,
        higher_duty,
        higher_duty_start,
        higher_duty_end
      })
    );
  } catch (error) {
    yield put(
      actions.BOX_TIMESHEETS_AWARDS_MODAL_RECALCULATE_FAILURE(
        formatError(error)
      )
    );
  }

  yield put(BOX_IS_LOADING_OFF(IS_LOADING_HIGHER_DUTIES));
};

const updateTimesheet = function*(): SagaIterator {
  yield put(BOX_IS_LOADING_ON(IS_LOADING));

  const updatePayload: ReturnType<typeof updatePayloadSelector> = yield select(
    updatePayloadSelector
  );

  try {
    const apiPayload: TimesheetUpdateRequest = updatePayload;

    const updatedTimesheet: TimesheetUpdateResponse = yield call(
      processApiRequest,
      Api.Timesheet.updateTimesheet,
      apiPayload
    );

    yield put(BOX_TIMESHEET_UPDATE_ONE(updatedTimesheet));
    yield put(actions.BOX_TIMESHEETS_AWARDS_MODAL_UPDATE_SUCCESS());
  } catch (error) {
    yield put(
      actions.BOX_TIMESHEETS_AWARDS_MODAL_UPDATE_FAILURE(formatError(error))
    );
  }

  yield put(BOX_IS_LOADING_OFF(IS_LOADING));
};

export const watchTimesheetsAwards = function*(): SagaIterator {
  yield takeLatest(
    actions.BOX_TIMESHEETS_AWARDS_MODAL_REQUEST,
    fetchModalData
  );
  yield takeLatest(
    actions.BOX_TIMESHEETS_AWARDS_MODAL_RECALCULATE_REQUEST,
    recalculate
  );
  yield takeLatest(
    actions.BOX_TIMESHEETS_AWARDS_MODAL_UPDATE_REQUEST,
    updateTimesheet
  );
};
