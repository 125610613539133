import React, { Component } from 'react';
import { SelectList } from 'elmo-elements';

import {
  DoneIcon,
  PageDialog,
  PageDialogCloseIconButton,
  PageDialogSubmit,
} from 'element';
import { DialogActions, DialogTitle } from 'extended-oxygen-elements';
import { DialogContent } from 'oxygen-elements';

type Props = {
  isModalOpened: boolean;
  fields: any;
  toggleCheckbox: (index: number) => void;
  toggleAll: (isSelect: boolean) => void;
  applyColumns: () => void;
  restoreFields: () => void;
};

interface State {
  isModalOpened: boolean;
  fields: any;
  markAllVisible: boolean;
}

class CustomizeColumnsModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isModalOpened: props.isModalOpened,
      fields: props.fields,
      markAllVisible: false,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps !== this.props) {
      this.setState({
        isModalOpened: this.props.isModalOpened,
      });
    }
  }

  closeModal = () => {
    this.setState({
      isModalOpened: false,
      markAllVisible: false,
    });
  };

  handleClick = () => {
    this.closeModal();
    this.props.applyColumns();
  };

  clickOnClose = () => {
    this.props.restoreFields();
    this.closeModal();
  };

  render() {
    const { fields } = this.props;
    return (
      <PageDialog
        maxWidth={'xs'}
        open={this.state.isModalOpened}
        id={'customize-columns'}
        onClose={this.clickOnClose}
      >
        <DialogTitle>
          Customise columns
          <PageDialogCloseIconButton onClose={this.clickOnClose} />
        </DialogTitle>
        <DialogContent>
          <SelectList onSelectAllToggle={this.props.toggleAll}>
            {fields.map((option: any, index: number) => {
              return (
                <SelectList.Option
                  key={index}
                  isSelected={option.isChecked}
                  onToggle={this.onItemToggle(index)}
                  icon={
                    option.disabled ? (
                      <span style={{ opacity: 0.5 }}>
                        <DoneIcon />
                      </span>
                    ) : (
                      <DoneIcon />
                    )
                  }
                >
                  {option.label}
                </SelectList.Option>
              );
            })}
          </SelectList>
        </DialogContent>
        <DialogActions sx={{ boxShadow: '0 0 6px 2px #ccc', zIndex: 3 }}>
          <PageDialogSubmit onClick={this.handleClick} id={'submit-btn'}>
            Done
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  }

  onItemToggle = (index: number) => () => {
    this.props.toggleCheckbox(index);
  };
}

export default CustomizeColumnsModal;
