import { createAction } from 'lib/store-utils';
import { FormattedErrors, SummaryData } from 'type/models';
import { SummaryStatus } from './types';

export const BOX_SUMMARY_GET_DATA = createAction('Summary / get data');
export const BOX_SUMMARY_GET_DATA_ON_ROSTER_UPDATES = createAction('Summary / trigger get data');

export const BOX_SUMMARY_GET_DATA_REQUEST = createAction('Summary / get data request');
export const BOX_SUMMARY_GET_DATA_SUCCESS = createAction<SummaryData>('Summary / get data success');
export const BOX_SUMMARY_GET_DATA_FAILURE = createAction<FormattedErrors>('Summary / get data failure');

export const BOX_SUMMARY_REMOVE_ERRORS = createAction('Summary / remove errors');

export const BOX_SUMMARY_SET_STATUS = createAction<SummaryStatus>('Summary / set status');
