import { SagaIterator } from 'redux-saga';
import nanoid from 'nanoid';
import { defaults } from 'lodash';
import { call, put, race, take } from 'redux-saga/effects';
import { ToastNotifierMessageShowConfigPayload } from './types';
import {
  BOX_TOAST_NOTIFIER_CLOSE,
  BOX_TOAST_NOTIFIER_MESSAGE_SHOW,
  BOX_TOAST_NOTIFIER_UNDO,
} from './actions';

export const showNotification = function* (
  actualPayload: ToastNotifierMessageShowConfigPayload
) {
  const payload = defaults({}, actualPayload, {
    id: nanoid(),
  });

  yield put(BOX_TOAST_NOTIFIER_MESSAGE_SHOW(payload));

  const undoType = BOX_TOAST_NOTIFIER_UNDO.getType();
  const hideType = BOX_TOAST_NOTIFIER_CLOSE.getType();
  return yield race({
    undo: take(
      (action: any): boolean =>
        action.type === undoType && action.payload === payload.id
    ),
    hide: take(
      (action: any): boolean =>
        action.type === hideType && action.payload === payload.id
    ),
  });
};

export const showUndoNotification = function* (
  payload:
    | {
        message: string;
        notificationId: string;
      }
    | string
): SagaIterator {
  const showPayload: ToastNotifierMessageShowConfigPayload =
    typeof payload === 'string'
      ? { message: payload }
      : {
          ...payload,
          showUndo: true,
        };

  return yield call(showNotification, showPayload);
};

export const showOutDatedNotification = function* ({
  message,
  notificationId,
  undoText,
}: {
  message: string;
  notificationId: string;
  undoText?: string;
}): SagaIterator {
  return yield call(showNotification, {
    message,
    isEndless: true,
    id: notificationId,
    showUndo: true,
    undoText,
  });
};
