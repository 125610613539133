import { createSelector } from 'reselect';
import { some } from 'lodash';
import { StoreState } from '../../../types';
import { UserFiltersForm } from '../types';
import { getUserFiltersForm } from './index';

export const isFiltersFormDisabledSelector = createSelector<
  StoreState,
  UserFiltersForm,
  boolean
>(getUserFiltersForm, ({ active, archived }) => {
  const filtersByType: boolean[] = [active, archived];

  return !some(filtersByType);
});
