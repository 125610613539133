import React, { memo } from 'react';
import { times } from 'lodash';
import { useSelector } from 'react-redux';
import { weekDaysNumberSelector } from 'state/Roster/RosterWeekViewUserView';
import { TimesheetCell } from './TimesheetCell';

type Props = { userRowIndex: number; pairRowIndex: number };

export const TimesheetRow = memo(function TimesheetRow(props: Props) {
  const weekDaysNumber = useSelector(weekDaysNumberSelector);

  return (
    <div className={`week-rosters-grid__group week-rosters-grid__group--timesheet cell-height-v2`}>
      {times(weekDaysNumber, (weekDayIndex) => (
        <div
          className="week-rosters-grid__group-day-block"
          key={weekDayIndex}
        >
          <TimesheetCell
            key={weekDayIndex}
            {...props}
            weekDayIndex={weekDayIndex}
          />
        </div>
      ))}
    </div>
  );
});
