import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { isHrService } from 'helpers/helpers';
import { Api, UserUpdateRequest } from 'lib/Api';
import { processApiRequest } from 'state/ProcessApiRequest';

export const editUserRequest = function* (
  payload: UserUpdateRequest
): SagaIterator {
  return isHrService('standalone')
    ? yield call(processApiRequest, Api.Standalone.User.update, payload)
    : yield call(processApiRequest, Api.User.update, payload);
};
