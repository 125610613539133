import { Breakpoint, styled } from 'extended-oxygen-elements';
import React from 'react';
import {
  LoadingsTableContext,
  LoadingsTableContextValue,
} from './loadings-table-context';

export type LoadingsTableProps = InnerContainerProps & {
  children: React.ReactNode;
  flexes: LoadingsTableContextValue;
};

export default LoadingsTable;

const Root = styled('div')(() => ({
  overflow: 'auto',
}));

type InnerContainerProps = {
  minWidth: Breakpoint;
  maxWidth: Breakpoint;
};

const InnerContainer = styled('div', {
  shouldForwardProp: (propName) =>
    propName !== 'minWidth' && propName !== 'maxWidth',
})<InnerContainerProps>(({ theme, minWidth, maxWidth }) => ({
  maxWidth: theme.breakpoints.values[maxWidth],
  minWidth: theme.breakpoints.values[minWidth],
}));

export function LoadingsTable({
  children,
  flexes,
  ...innerContainerProps
}: LoadingsTableProps) {
  return (
    <LoadingsTableContext.Provider value={flexes}>
      <Root>
        <InnerContainer {...innerContainerProps}>{children}</InnerContainer>
      </Root>
    </LoadingsTableContext.Provider>
  );
}
