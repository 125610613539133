import React, { ReactText } from 'react';
import { Data, DropDownProps } from '../type';
import GroupByOrderByTimeFormatGroup from './GroupByOrderByTimeFormatGroup';
import LateReportCheckboxGroup from './LateReportCheckboxGroup';
import { Divider } from 'elmo-elements';

export type DataProps = {
  handleOnChangeSelect: (e: DropDownProps, name: string) => void;
  handleOnChangeCheckbox: (e: any) => void;
  handleOnChangeTimeFormat: (value: ReactText) => void;
  data: Data;
};

const LateExtraGroup = ({
  data,
  handleOnChangeCheckbox,
  handleOnChangeSelect,
  handleOnChangeTimeFormat,
}: DataProps) => {
  return (
    <>
      <Divider />
      <br />
      <h3>View</h3>
      <GroupByOrderByTimeFormatGroup
        data={data}
        handleOnChangeSelect={handleOnChangeSelect}
        handleOnChangeTimeFormat={handleOnChangeTimeFormat}
      />
      <LateReportCheckboxGroup
        data={data}
        handleOnChangeCheckbox={handleOnChangeCheckbox}
      />
    </>
  );
};

export default LateExtraGroup;
