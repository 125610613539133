import { createSelector } from 'reselect';
import { orderBy } from 'natural-orderby';
import { Collection, DashboardShift, DashboardShiftBreak } from 'type';
import { getUserName } from 'lib/helpers';
import { userListSelector } from 'state/UsersCollection';
import { getCurrentTime } from 'state/CurrentTime';
import { SortListFields, WorkingFilters } from '../types';
import { getFilters, getWorkingShiftsSelector } from './simpleSelectors';

const isAllBreaksIsOngoing = (breaks: DashboardShiftBreak[]) => {
  const filteredBreaks = breaks.filter((item) => item.duration === 0);
  return filteredBreaks.length > 0;
};

export const getWhosWorkingShiftsFilterByBreaks = createSelector(
  getWorkingShiftsSelector,
  getFilters,
  (collection: DashboardShift[], filters: WorkingFilters) => {
    if (filters.breaks === 'all') {
      return collection;
    }
    return collection.filter((shift: DashboardShift) => {
      if (filters.breaks === 'without') {
        return !shift.breaks.length || isAllBreaksIsOngoing(shift.breaks);
      }
      if (filters.breaks === 'on_break') {
        return shift.is_on_break;
      }
      return true;
    });
  }
);

export const getWhosWorkingShiftsFilterByPosition = createSelector(
  getWhosWorkingShiftsFilterByBreaks,
  getFilters,
  (collection: DashboardShift[], filters: WorkingFilters) => {
    if (!filters.position) {
      return collection;
    }

    return collection.filter((shift: DashboardShift) => {
      return filters.position === shift.role_id;
    });
  }
);

type SortIdentifier = (dashboardShift: DashboardShift) => unknown;
export const sortByFieldIdentifiersSelector = createSelector(
  userListSelector,
  getCurrentTime,
  (userList, currentTime): Collection<SortListFields, SortIdentifier> => ({
    start: (dashboardShift) => dashboardShift.start.unix(),
    finish: (dashboardShift) =>
      dashboardShift.timesheet_end && dashboardShift.timesheet_end.unix(),
    hours: ({ timesheet_start, timesheet_end, timezone_id }) => {
      if (timesheet_start) {
        const end = timesheet_end || currentTime.clone().tz(timezone_id);

        return end.diff(timesheet_start);
      }
    },
    name: (dashboardShift) => getUserName(userList[dashboardShift.user_id]),
  })
);

export const getWhosWorkingShiftsFilter = createSelector(
  getWhosWorkingShiftsFilterByPosition,
  getFilters,
  sortByFieldIdentifiersSelector,
  (
    collection: DashboardShift[],
    filters: WorkingFilters,
    sortByFieldIdentifiers
  ) => {
    const sortIdentifiers: Collection<
      SortListFields,
      SortIdentifier | SortIdentifier[]
    > = {
      start: [sortByFieldIdentifiers.start, sortByFieldIdentifiers.name],
      finish: [sortByFieldIdentifiers.finish, sortByFieldIdentifiers.name],
      hours: [sortByFieldIdentifiers.hours, sortByFieldIdentifiers.name],
      name: sortByFieldIdentifiers.name,
    };

    return orderBy(
      collection,
      sortIdentifiers[filters.sort.column],
      filters.sort.direction
    );
  }
);
