import React from 'react';
import { useSelector } from 'react-redux';
import { Moment } from 'moment';
import { RosteredShift } from 'type';
import { isRosteredShiftsEditableSelector } from 'state/Roster/RosterDayView';
import { GetRosterByIDPayload } from 'state/Roster/RosterShiftModal/types';
import { RosterCellType, SiteRosterDayCard } from 'page/Roster/components';
import {
  DayDraggableWrapper,
  ResizableWrapper,
  ResizableWrapperOnResizeEndPayload,
  ShiftBreak,
} from '../';

type Props = {
  day: Moment;
  rosteredShift: RosteredShift;
  onResizeEnd: (payload: ResizableWrapperOnResizeEndPayload) => void;
  type: RosterCellType;
  onClick: (payload: GetRosterByIDPayload) => void;
  isEditable: boolean;
};

export const DaySiteRosteredShift = ({
  rosteredShift,
  onResizeEnd,
  type,
  isEditable,
  day,
  onClick,
}: Props) => {
  const isRosteredShiftsEditable = useSelector(
    isRosteredShiftsEditableSelector
  );
  const isThisShiftEditable = isRosteredShiftsEditable && isEditable;

  return (
    <>
      <ResizableWrapper
        id={rosteredShift.id}
        start={rosteredShift.start}
        end={rosteredShift.end}
        day={day}
        onResizeEnd={onResizeEnd}
        isResizable={isThisShiftEditable}
      >
        <DayDraggableWrapper day={day} rosteredShift={rosteredShift}>
          <SiteRosterDayCard rosteredShiftId={rosteredShift.id} shape={type} />
        </DayDraggableWrapper>
      </ResizableWrapper>

      {rosteredShift.breaks.map((shiftBreak) => (
        <ShiftBreak
          key={rosteredShift.id + shiftBreak.start.format()}
          shiftStart={rosteredShift.start}
          {...shiftBreak}
        />
      ))}
    </>
  );
};
