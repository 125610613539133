import { createSelector } from 'reselect';
import moment, { Moment } from 'moment';
import {
  DAY_VIEW_HOUR_WIDTH_PX,
  DAY_VIEW_X_GAP_PX,
  HOUR_IN_MIN,
} from 'lib/config';
import { fromBySiteTimezoneSelector } from 'state/RosteredShifts';
import { timeFormatSelector } from 'state/Account';
import { currentTimeBySiteTimezoneSelector } from '../../Roster/selectors';
import { findIndex } from 'lodash';

const dayStartEndSelector = createSelector(
  fromBySiteTimezoneSelector,
  (from) => ({
    dayStart: from.clone(),
    dayEnd: from.clone().add(1, 'day'),
  })
);

export const isDstSpringForwardSelector = createSelector(
  dayStartEndSelector,
  ({ dayStart, dayEnd }) => dayStart.utcOffset() < dayEnd.utcOffset()
);

const hoursSelector = createSelector(
  dayStartEndSelector,
  ({ dayStart, dayEnd }) => {
    const hours: Moment[] = [dayStart.clone()];

    let nextHour = dayStart.clone().add(1, 'hour');
    while (nextHour.isBefore(dayEnd)) {
      hours.push(nextHour);
      nextHour = nextHour.clone().add(1, 'hour');
    }

    hours.push(dayEnd);

    return hours;
  }
);

export const formattedHoursSelector = createSelector(
  hoursSelector,
  timeFormatSelector,
  (hours, timeFormat) => {
    const outputFormat = timeFormat === 'hh:mm a' ? 'h a' : 'HH';
    return hours.map(hour => hour.format(outputFormat).replace(/ /i, ''));
  }
);

export const numberOfHoursSelector = createSelector(
  hoursSelector,
  hours => hours.length - 1
);

export const timelineWidthSelector = createSelector(
  numberOfHoursSelector,
  numberOfHours => (DAY_VIEW_HOUR_WIDTH_PX + DAY_VIEW_X_GAP_PX) * numberOfHours
);

export const dayInMinutesSelector = createSelector(
  numberOfHoursSelector,
  numberOfHours => HOUR_IN_MIN * numberOfHours
);

export const oneMinuteInPxSelector = createSelector(
  timelineWidthSelector,
  dayInMinutesSelector,
  (timelineWidth, dayInMinutes) => timelineWidth / dayInMinutes
);

export const currentTimeWidthSelector = createSelector(
  currentTimeBySiteTimezoneSelector,
  oneMinuteInPxSelector,
  (currentTimeBySiteTimezone, oneMinuteInPx) => {
    const dayStart = currentTimeBySiteTimezone.clone().startOf('day');
    const duration = moment.duration(currentTimeBySiteTimezone.diff(dayStart));

    return duration.asMinutes() * oneMinuteInPx;
  }
);

export const dstSpringForwardHourSelector = createSelector(
  hoursSelector,
  hours =>
    hours.find((hour, index, array) => {
      const prevHour: Moment | undefined = array[index - 1];

      if (!prevHour) {
        return false;
      }

      const prevHourUtcOffset = prevHour.utcOffset();
      const currentUtcOffset = hour.utcOffset();

      return prevHourUtcOffset < currentUtcOffset;
    })
);

export const dstFallBackHourIndexSelector = createSelector(
  hoursSelector,
  hours => {
    return findIndex(hours, (hour, index, array) => {
      const prevHour: Moment | undefined = array[index - 1];

      if (!prevHour) {
        return false;
      }

      const prevHourUtcOffset = prevHour.utcOffset();
      const currentUtcOffset = hour.utcOffset();

      return prevHourUtcOffset > currentUtcOffset;
    });
  }
);

export const dstFallBackRangeSelector = createSelector(
  hoursSelector,
  dstFallBackHourIndexSelector,
  (hours, fallBackHourIndex) => {
    const fallBackHour: Moment | undefined = hours[fallBackHourIndex];

    if (fallBackHour) {
      const prevHour = hours[fallBackHourIndex - 1];

      return {
        start: prevHour.clone(),
        end: fallBackHour.clone()
      };
    }
  }
);

export const dstOffsetWidthSelector = createSelector(
  dstSpringForwardHourSelector,
  fromBySiteTimezoneSelector,
  oneMinuteInPxSelector,
  (dstSpringForwardHour, from, oneMinuteInPx) => {
    if (!dstSpringForwardHour) {
      return 0;
    }

    const duration = moment.duration(dstSpringForwardHour.diff(from));

    return duration.asMinutes() * oneMinuteInPx;
  }
);
