import React from 'react';
import { useSelector } from 'react-redux';
import { useActions } from 'hooks';
import { getIsApprovalModeEnabled } from 'state/Roster/Roster';
import { getSiteId } from 'state/RosteredShifts';
import { BOX_TIMESHEET_SHIFT_MODAL_CREATE_SHIFT } from 'state/TimesheetModal';
import { TimesheetModal } from 'element';

function RosterTimesheetModal() {
  const isApprovalModeEnabled = useSelector(getIsApprovalModeEnabled);
  const siteId = useSelector(getSiteId);
  const handleCreateTimesheet = useActions(
    BOX_TIMESHEET_SHIFT_MODAL_CREATE_SHIFT
  );

  if (isApprovalModeEnabled) {
    return null;
  }

  return (
    <TimesheetModal siteId={siteId} createTimesheet={handleCreateTimesheet} />
  );
}

export default RosterTimesheetModal;
