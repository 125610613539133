import React, { useEffect } from 'react';
import {
  useActions,
  useFormFields,
  useFormValidator,
  useRoute,
  useUpdateStatusEffect,
} from 'hooks';
import {
  Divider,
  makeDefaultBreakRuleFields,
  SettingsLayout,
} from 'page/Settings/components';
import { useSelector } from 'react-redux';
import { privateRoutes } from 'routes';
import {
  BOX_ACCOUNT_TIMESHEET_SETTINGS_UPDATE,
  getCustomBreaksStatus,
  getDefaultBreaksStatus,
  getTimesheetSettings,
} from 'state/Account';
import AddingTimesheetsFieldGroup from './AddingTimesheetsFieldGroup';
import DefaultBreaksFieldGroup from './DefaultBreaksFieldGroup';
import makeFormFields from './makeFormFields';
import makePayload from './makePayload';
import validate from './validate';
import EnablingTimesheetsFieldGroup from './EnablingTimesheetsFieldGroup';
import ApprovingTimesheetsFieldGroup from './ApprovingTimesheetsFieldGroup';
import MobileTimesheetsFieldGroup from './MobileTimesheetsFieldGroup';

export default function TimesheetSettings() {
  const settingsUpdate = useActions(
    BOX_ACCOUNT_TIMESHEET_SETTINGS_UPDATE.request
  );
  const route = useRoute();

  const settings = useSelector(getTimesheetSettings);

  const { formFields, setFormFields } = useFormFields(() =>
    makeFormFields(settings)
  );

  const { formErrors, submitForm } = useFormValidator(formFields, {
    validate,
    makePayload,
    onSubmit: settingsUpdate,
  });

  useUpdateStatusEffect(getDefaultBreaksStatus, {
    success: () => {
      setFormFields({
        default_breaks_rules: makeDefaultBreakRuleFields(
          settings.default_breaks_rules
        ),
      });
    },
  });

  useUpdateStatusEffect(getCustomBreaksStatus, {
    success: () => {
      setFormFields({
        default_breaks_rules: makeDefaultBreakRuleFields(
          settings.default_breaks_rules
        ),
      });
    },
  });

  const handleSuccess = () => {
    route.goTo(privateRoutes.settings.path);
  };

  return (
    <SettingsLayout
      title="Timesheet Settings"
      onSubmit={submitForm}
      onSuccess={handleSuccess}
    >
      <AddingTimesheetsFieldGroup
        values={formFields}
        onChange={setFormFields}
        errors={formErrors}
      />

      <Divider />
      <ApprovingTimesheetsFieldGroup
        values={formFields}
        onChange={setFormFields}
        errors={formErrors}
      />

      <Divider />
      <EnablingTimesheetsFieldGroup
        values={formFields}
        onChange={setFormFields}
        errors={formErrors}
      />

      <Divider />

      <DefaultBreaksFieldGroup
        values={formFields}
        onChange={setFormFields}
        errors={formErrors}
        isDisabled={formFields.breaks_from_shift}
      />

      <Divider />
      <MobileTimesheetsFieldGroup
        values={formFields}
        onChange={setFormFields}
        errors={formErrors}
      />
    </SettingsLayout>
  );
}
