import React from 'react';
import { useSelector } from 'react-redux';
import { getFlags } from '../state/Flags';

export const withFeatureFlags = (Component: any) => {
  return (props: any) => {
    const flags = useSelector(getFlags);
    return <Component flags={flags} {...props} />;
  };
};
