import React from 'react';
import { FormItem, Input, Select, FileUpload } from 'elmo-elements';
import {
  DeleteOutlinedIcon,
  PageDialog,
  PageDialogCancel,
  PageDialogSubmit,
} from 'element';
import _ from 'lodash';
import {
  CustomField,
  PreferencesDateFormat,
  PreferencesTimeFormat,
  UserProfileFields,
} from 'type/models';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import {
  BOX_USER_PROFILE_CUSTOM_FILE_REQUEST,
  BOX_USER_PROFILE_REMOVE_ERRORS,
  BOX_USER_PROFILE_UPDATE_REQUEST,
} from 'state/Users/UserProfile/actions';
import { ErrorBox, DateInput } from 'element';
import { getDateFormat, getTimeFormat } from 'state/Account';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';

type OwnProps = {
  closeModal: () => void;
  isOpened: boolean;
  customFields: CustomField[];
  userCustomFields: any;
};

type DispatchProps = {
  updateProfile: (params: any) => void;
  removeErrors: () => void;
  uploadFile: (params: any) => void;
};

type State = {
  userCustomFields: any;
  isValid: boolean;
  isUpdating: boolean;
  isUploading: boolean;
  isSaveDisabled: boolean;
  currentFile: string;
  isEdited: boolean;
};

type StateProps = {
  userProfile: UserProfileFields;
  errors: string[];
  isUpdating: boolean;
  isUploading: boolean;
  dateFormat: PreferencesDateFormat;
  timeFormat: PreferencesTimeFormat;
};

export type Props = OwnProps & DispatchProps & StateProps;

export class CustomFieldsModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userCustomFields: this.getFields(
        this.props.userCustomFields,
        this.props.customFields,
        true
      ),
      isValid: this.validateFields(true),
      isUpdating: false,
      isUploading: props.isUploading,
      isSaveDisabled: this.validateFields(true),
      currentFile: '',
      isEdited: false,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps !== this.props) {
      if (
        this.props.isUpdating !== prevProps.isUpdating &&
        this.props.isUpdating === false
      ) {
        this.setState({
          isUpdating: this.props.isUpdating,
          userCustomFields: this.getFields(
            this.props.userCustomFields,
            this.props.customFields
          ),
        });
      }
      if (
        this.props.isUploading !== prevProps.isUploading &&
        this.props.isUploading === false
      ) {
        this.setState(
          {
            isUploading: this.props.isUploading,
            userCustomFields: this.getFields(
              this.props.userCustomFields,
              this.props.customFields
            ),
            isSaveDisabled: this.validateFields(),
          },
          () => {
            this.setState({
              currentFile: '',
            });
          }
        );
      }
      if (this.props.errors.length > 0) {
        this.scrollToErrors();
      }
      if (this.props.isOpened === false || this.props.errors.length > 0) {
        this.setState({
          isUpdating: false,
          isEdited: this.state.isEdited,
        });
      }
    }
  }

  scrollToErrors = () => {
    const m = document.querySelector('#custom-fields-modal .elmo-modal__body');
    if (m !== null) {
      m.scrollTo(0, 0);
    }
  };

  render() {
    const { isValid, isSaveDisabled, isUploading, isEdited } = this.state;
    return (
      <PageDialog
        maxWidth={'xs'}
        open={this.props.isOpened}
        id={'custom-fields-modal'}
        onClose={this.props.closeModal}
      >
        <DialogTitle>Custom fields</DialogTitle>
        <DialogContent>
          {this.props.errors.length > 0 && (
            <ErrorBox
              errors={this.props.errors}
              clearErrors={this.props.removeErrors}
            />
          )}
          {this.renderCustomFields()}
        </DialogContent>
        <DialogActions>
          <PageDialogCancel onClick={this.props.closeModal} fullWidth={false}>
            Cancel
          </PageDialogCancel>
          <PageDialogSubmit
            disabled={!isValid || (isSaveDisabled && isUploading) || !isEdited}
            onClick={this.saveCustomFields}
            loading={this.state.isUpdating}
            fullWidth={false}
          >
            Save
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  }

  getFields = (fields: any[], customFields: CustomField[], isInit = false) => {
    let f: any = {};
    _.map(customFields, (field: any) => {
      f[field.name] = this.getFieldValue(field, isInit);
    });
    return f;
  };

  renderCustomFields = () => {
    return _.map(this.props.customFields, (field: any, index: number) => (
      <FormItem
        label={field.label}
        labelAddon={field.mandatory ? '' : '(Optional)'}
        key={index}
      >
        {this.renderFieldType(field)}
      </FormItem>
    ));
  };

  renderFieldType = (field: any) => {
    switch (field.type) {
      case 'date':
        return (
          <DateInput
            name={field.name}
            value={this.state.userCustomFields[field.name]}
            onChange={(e) => this.changeDatePicker(e, field.name)}
            ariaLabel={field.label}
          />
        );
      case 'multiple_select':
        return (
          <Select
            name={field.name}
            options={this.getFieldOptions(field)}
            onChange={(e) => this.handleOnChangeMultiple(e, field.name)}
            defaultValue={this.getSelectedMultipleValues(field)}
            value={this.getSelectedMultipleValues(field)}
            isMulti={true}
            ariaLabel={field.label}
          />
        );
      case 'select':
        return (
          <Select
            name={field.name}
            options={this.getFieldOptions(field)}
            onChange={(e) => this.handleOnChangeSelect(e, field.name)}
            defaultValue={this.getSelectedValue(field)}
            value={this.getSelectedValue(field)}
            ariaLabel={field.label}
          />
        );
      case 'file':
        return (
          <div data-file={field.name}>
            {this.state.isUploading &&
            this.state.userCustomFields[field.name] === 'uploading'
              ? this.uploadPlaceholder()
              : this.fileUploader(field)}
          </div>
        );
      default:
        return (
          <Input
            value={this.state.userCustomFields[field.name]}
            onChange={this.onChange}
            name={field.name}
            ariaLabel={field.label}
          />
        );
    }
  };

  fileUploader = (field: any) => {
    return this.isFileUploaded(field.name) ? (
      this.showDeleteFile(field)
    ) : (
      <FileUpload
        name={field.name}
        fileMaxSize={5}
        isMulti={false}
        displayFiles={true}
        displayDropArea={false}
        handleFileDelete={(e) => {
          return false;
        }}
        onUploadStart={(f) => this.handleUploadStart(f, field.name)}
        onReject={this.handleRejected}
        acceptedFiles={[
          'image/png',
          'image/jpg',
          'image/jpeg',
          'image/gif',
          '.pdf',
        ]}
        disabled={this.isFileUploaded(field.name)}
      />
    );
  };

  uploadPlaceholder = () => {
    return (
      <div className="uploading-placeholder">Uploading... Please wait...</div>
    );
  };

  showDeleteFile = (field: any) => {
    const value = this.getFieldValue(field);
    return (
      <div>
        <a
          href={value.filepath}
          download={value.original_filename}
          target="_blank"
          rel="noreferrer"
        >
          {value.original_filename}
        </a>
        <span
          className={'pin-r'}
          onClick={(e) => this.deleteFile(e, field.name)}
        >
          <DeleteOutlinedIcon />
        </span>
      </div>
    );
  };

  deleteFile = (e: any, name: string) => {
    this.setState(
      {
        ...this.state,
        isEdited: true,
        userCustomFields: {
          ...this.state.userCustomFields,
          [name]: null,
        },
      },
      () => {
        this.setState({
          ...this.state,
          isValid: this.validateFields(),
        });
      }
    );
  };

  isFileUploaded = (name: string) => {
    const { userCustomFields } = this.state;
    return (
      userCustomFields[name] &&
      userCustomFields[name] !== null &&
      userCustomFields[name].filename
    );
  };

  handleUploadStart = (f: any, name: string) => {
    let formData = new FormData();
    if (f !== null) {
      formData.set('name', name);
      formData.set('data', f[0]);
      this.setState({
        ...this.state,
        isEdited: true,
        isUploading: true,
        isSaveDisabled: true,
        userCustomFields: {
          ...this.state.userCustomFields,
          [name]: 'uploading',
        },
        currentFile: name,
      });
      this.props.uploadFile({
        name: name,
        data: formData,
        original_filename: f[0].name,
      });
    }
  };

  handleRejected = () => {
    console.log('reject');
  };

  getFieldValue = (field: any, isInit = false) => {
    const { userCustomFields } = this.props;
    const stateFields = !isInit
      ? this.state.userCustomFields
      : userCustomFields;
    const customFields = Object.keys(stateFields);
    const currentFile = !isInit ? this.state.currentFile : '';
    for (let key of customFields) {
      if (key === field.name) {
        if (!isInit) {
          if (field.type !== 'date') {
            // update values from Store only for file type and only which was uploaded
            if (
              field.type === 'file' &&
              currentFile !== '' &&
              key === currentFile
            ) {
              return userCustomFields[currentFile];
            }
            return stateFields[key];
          }
          return stateFields[key] === null ? '' : userCustomFields[key];
        } else {
          if (field.type !== 'date') {
            return userCustomFields[key];
          }
          return stateFields[key] === null ? '' : userCustomFields[key];
        }
      }
    }
    if (field.type === 'date') {
      return null;
    }
    return '';
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    this.setState(
      {
        ...this.state,
        isEdited: true,
        userCustomFields: {
          ...this.state.userCustomFields,
          [name]: value,
        },
      },
      () => {
        this.setState({
          ...this.state,
          isValid: this.validateFields(),
        });
      }
    );
  };

  changeDatePicker = (e: any, name: string) => {
    this.setState(
      {
        ...this.state,
        isEdited: true,
        userCustomFields: {
          ...this.state.userCustomFields,
          [name]: e,
        },
      },
      () => {
        this.setState({
          ...this.state,
          isValid: this.validateFields(),
        });
      }
    );
  };

  handleOnChangeSelect = (e: any, name: string) => {
    const { userCustomFields } = this.state;
    this.setState(
      {
        ...this.state,
        isEdited: true,
        userCustomFields: {
          ...userCustomFields,
          [name]: e.value,
        },
      },
      () => {
        this.setState({
          ...this.state,
          isValid: this.validateFields(),
        });
      }
    );
  };

  handleOnChangeMultiple = (e: any, name: string) => {
    const { userCustomFields } = this.state;
    this.setState(
      {
        ...this.state,
        isEdited: true,
        userCustomFields: {
          ...userCustomFields,
          [name]: this.getIdsFromDropDown(e),
        },
      },
      () => {
        this.setState({
          ...this.state,
          isValid: this.validateFields(),
        });
      }
    );
  };

  getFieldOptions = (field: any) => {
    const dropDown = [];
    if (field.options) {
      const options = field.options.split(',');
      for (let option of options) {
        dropDown.push({
          label: option.trim(),
          value: option.trim(),
        });
      }
    }
    return dropDown;
  };

  getSelectedValue = (field: any, isInit = false) => {
    const stateFields = isInit
      ? this.props.userCustomFields
      : this.state.userCustomFields;
    if (stateFields[field.name]) {
      return {
        label: stateFields[field.name],
        value: stateFields[field.name],
      };
    }
    return {
      label: '',
      value: '',
    };
  };

  getSelectedMultipleValues = (field: any, isInit = false) => {
    const current = isInit
      ? this.props.userCustomFields
      : this.state.userCustomFields[field.name];
    const options = this.getFieldOptions(field);
    const values: any[] = [];
    for (let option of options) {
      for (let currentItem of current) {
        if (option.value === currentItem) {
          values.push(option);
        }
      }
    }
    return values;
  };

  getIdsFromDropDown = (options: any[]) => {
    const ids: string[] = [];
    options.forEach((item: any) => {
      ids.push(item.value);
    });
    return ids;
  };

  saveCustomFields = (e: any) => {
    const { userCustomFields } = this.state;
    this.setState({
      isUpdating: true,
    });
    this.props.updateProfile({
      id: this.props.userProfile.id,
      custom_fields: userCustomFields,
      message: 'Custom fields updated',
    });
  };

  private validateFields = (isInit = false) => {
    const { customFields } = this.props;
    const customFieldsValues = isInit
      ? this.props.userCustomFields
      : this.state.userCustomFields;
    let isValid = true;
    _.map(customFields, (field: any) => {
      if (field.mandatory) {
        const f = customFieldsValues[field.name];
        if (!f || (f && f.length === 0) || f === null) {
          isValid = false;
        }
      }
    });
    return isValid;
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  userProfile: state.userProfile.userProfile,
  errors: state.userProfile.errors,
  isUpdating: state.userProfile.isUpdating,
  isUploading: state.userProfile.isUploading,
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
});

export default connect(mapStateToProps, {
  updateProfile: BOX_USER_PROFILE_UPDATE_REQUEST,
  removeErrors: BOX_USER_PROFILE_REMOVE_ERRORS,
  uploadFile: BOX_USER_PROFILE_CUSTOM_FILE_REQUEST,
})(CustomFieldsModal);
