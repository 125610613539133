import { useSelectorWithProps } from 'hooks';
import { getRoleSafe } from 'state/AccountTree';

type Props = {
  role_id: string;
};
export const useColorId = ({ role_id }: Props) => {
  const role = useSelectorWithProps(getRoleSafe, role_id);

  return role?.color_id;
};
