import React, { ReactNode } from 'react';
import { MoreHorizIcon as ActionsIcon } from '../../icons';
import { ListTableAction } from '../type';
import { Dropdown } from '../../Dropdown';
import { Button } from '../../Button';

export type ListTableActionsProps = {
  /** Row index */
  rowIndex: number;
  /** render placeholder when icon and actions are not provided */
  isPlaceholderRendered: boolean;
  /** The icon to show */
  icon?: ReactNode;
  /** The array of actions for the dropdown */
  actions?: ListTableAction[];
};

class ListTableActions extends React.Component<ListTableActionsProps> {
  render() {
    return (
      <div
        className="elmo-listtable__row-actions"
        role="cell"
        onClick={this.onClick}
      >
        {this.renderContent()}
      </div>
    );
  }

  private onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const { actions } = this.props;
    if (actions && actions.length) {
      event.stopPropagation(); // prevent triggering onRowClick in the parent component (ListTableRow);
    }
  };

  private renderContent() {
    const { actions, icon, isPlaceholderRendered } = this.props;

    if (actions && actions.length) {
      return this.renderDropdown(actions, icon || <ActionsIcon />);
    }

    if (icon) {
      return this.renderIcon(icon);
    }

    if (isPlaceholderRendered) {
      return this.renderPlaceholder();
    }

    return null;
  }

  private renderDropdown(actions: ListTableAction[], icon: ReactNode) {
    const { rowIndex } = this.props;

    return (
      <Dropdown icon={icon} position={'bottom-end'} ariaLabel="Actions">
        {actions.map((action: ListTableAction, index: number) => {
          let dropdownElem = [];
          dropdownElem.push(
            <Dropdown.Item
              ariaLabel={action.ariaLabel}
              onClick={action.onClick}
              value={rowIndex}
              isDisabled={action.isDisabled}
              key={'dropdown-item-' + index}
            >
              {action.icon} {action.label}
            </Dropdown.Item>
          );

          if (action.withDivider) {
            dropdownElem.push(
              <Dropdown.Divider
                type="short"
                key={'dropdown-divider-' + index}
              />
            );
          }

          return dropdownElem;
        })}
      </Dropdown>
    );
  }

  private renderIcon(icon: ReactNode) {
    return <div className="elmo-listtable__actions-icon">{icon}</div>;
  }

  private renderPlaceholder() {
    return (
      <Button
        icon={<ActionsIcon />}
        className="elmo-listtable__row-actions-placeholder"
      />
    );
  }
}

export default ListTableActions;
