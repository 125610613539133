import { Box, BoxProps } from 'extended-oxygen-elements';
import { AddIcon } from 'element';
import { Button } from 'extended-oxygen-elements';
import React from 'react';
import { usePayConditionsListContext } from '../pay-conditions-list-context';

export default function AddPayConditionButton(props: BoxProps) {
  const { isOpen, openCreate } = usePayConditionsListContext();

  if (isOpen) {
    return null;
  }

  return (
    <Box {...props}>
      <Button
        variant="text"
        startIcon={<AddIcon />}
        onClick={openCreate}
        data-testid={'add-pay-condition-btn'}
      >
        Add Pay condition
      </Button>
    </Box>
  );
}
