import React, { Component, SyntheticEvent } from 'react';
import { Checkbox, FormItem } from 'elmo-elements';
import { SelectPropsOptions } from 'elmo-elements/Select';
import { LanguagePreferences, SortUser, UpdateFormPayload } from 'type';
import { marketLabels } from 'marketLabels';
import { capitalize, keyMirror } from 'lib/helpers';
import {
  Select,
  SortDirectionRadioButtons,
  SortFieldRadioButtons,
} from 'element';
import { OnChangeMultiSelectEvent } from 'element/Select/types';
import { UserFiltersForm } from 'state/Users/Users/types';

export type Props = {
  formData: UserFiltersForm;
  roles: SelectPropsOptions;
  areasWithSiteName: SelectPropsOptions;
  userGroups: SelectPropsOptions;
  langPreferences: LanguagePreferences;
  onChange: (payload: UpdateFormPayload) => void;
};

export default class CustomFiltersView extends Component<Props> {
  readonly inputNames = keyMirror(this.props.formData);
  readonly labels = {
    role: capitalize(this.props.langPreferences.role.singular),
    area: capitalize(this.props.langPreferences.area.singular),
    site: capitalize(this.props.langPreferences.site.singular),
  };

  readonly sortFields: {
    label: string;
    value: SortUser['column'];
  }[] = [
    {
      label: 'Name',
      value: 'full_name',
    },
    {
      label: this.labels.role,
      value: 'role_id',
    },
    {
      label: this.labels.area,
      value: 'area_id',
    },
    {
      label: this.labels.site,
      value: 'site_id',
    },
    {
      label: 'Permissions',
      value: 'user_group_id',
    },
  ];

  render() {
    const {
      inputNames,
      props: { formData },
    } = this;

    return (
      <form>
        <FormItem label="Status" isDivider={true}>
          <Checkbox
            id={inputNames.active}
            name={inputNames.active}
            label="Current"
            isChecked={formData.active}
            onChange={this.onChangeCheckbox}
          />

          <Checkbox
            id={inputNames.archived}
            name={inputNames.archived}
            label={capitalize(marketLabels.userArchived)}
            isChecked={formData.archived}
            onChange={this.onChangeCheckbox}
          />
        </FormItem>

        <FormItem label={this.labels.role} isDivider={true}>
          <Select
            id="roles"
            isMulti={true}
            value={formData.role}
            name={inputNames.role}
            options={this.props.roles}
            onChange={this.onChangeSelect}
            placeholder=""
            ariaLabel={this.labels.role}
          />
        </FormItem>

        <FormItem
          label={`${this.labels.area}, ${this.labels.site}`}
          isDivider={true}
        >
          <Select
            id="areas"
            isMulti={true}
            value={formData.area}
            name={inputNames.area}
            options={this.props.areasWithSiteName}
            onChange={this.onChangeSelect}
            placeholder=""
            ariaLabel={`${this.labels.area}, ${this.labels.site}`}
          />
        </FormItem>

        <FormItem label="Permissions" isDivider={true}>
          <Select
            id="user-group"
            isMulti={true}
            value={formData.userGroup}
            name={inputNames.userGroup}
            options={this.props.userGroups}
            onChange={this.onChangeSelect}
            placeholder=""
            ariaLabel="Permissions"
          />
        </FormItem>

        <FormItem label="Sort by">
          <SortFieldRadioButtons
            id="sort-by"
            selected={formData.sort.column}
            onChange={this.onChangeSortField}
            sortFields={this.sortFields}
          />
        </FormItem>

        <FormItem>
          <SortDirectionRadioButtons
            id="sort-direction"
            selected={formData.sort.direction}
            onChange={this.onChangeSortDirection}
          />
        </FormItem>
      </form>
    );
  }

  private onChangeCheckbox = ({
    currentTarget: { name, checked },
  }: SyntheticEvent<HTMLInputElement>) => {
    this.props.onChange({
      name,
      value: checked,
    });
  };

  private onChangeSelect = ({ name, value }: OnChangeMultiSelectEvent) => {
    this.props.onChange({
      name,
      value,
    });
  };

  private onChangeSortField = (column: string | number) => {
    this.props.onChange({
      name: this.inputNames.sort,
      value: {
        column,
        direction: this.props.formData.sort.direction,
      },
    });
  };

  private onChangeSortDirection = (direction: string | number) => {
    this.props.onChange({
      name: this.inputNames.sort,
      value: {
        column: this.props.formData.sort.column,
        direction,
      },
    });
  };
}
