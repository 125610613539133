import React from 'react';
import _ from 'lodash';
import { Merge } from 'ts-essentials';
import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from 'oxygen-elements';

export type TooltipProps = Merge<
  MuiTooltipProps,
  {
    title: React.ReactNode;
  }
>;

export default function Tooltip({ title, children, ...restProps }: TooltipProps) {
  if (!_.isNull(title) && !_.isUndefined(title) && !_.isBoolean(title)) {
    return (
      <MuiTooltip {...restProps} title={title}>
        {children}
      </MuiTooltip>
    );
  }

  return children;
}
