import React from 'react';
import { useSelector } from 'react-redux';
import { useActions, useSelectorWithProps } from 'hooks';
import { hasPermissionSelector } from 'state/Auth';
import {
  BOX_ROSTER_APPROVE_MODE_TOGGLE,
  BOX_ROSTER_CLOSE_APPLY_CHANGES_MODAL,
  getIsApprovalModeEnabled,
  getIsModalOpen,
} from 'state/Roster/Roster';
import ApproveModal from '../ApproveModal';

function RosterApproveModal() {
  const canApproveTimesheet = useSelectorWithProps(
    hasPermissionSelector,
    'roster.timesheet.edit.approve'
  );
  const isOpen = useSelectorWithProps(getIsModalOpen, 'approve');
  const isApprovalModeEnabled = useSelector(getIsApprovalModeEnabled);

  const [handleCloseModal, handleToggleMode] = useActions([
    BOX_ROSTER_CLOSE_APPLY_CHANGES_MODAL,
    BOX_ROSTER_APPROVE_MODE_TOGGLE,
  ]);

  if (!canApproveTimesheet) {
    return null;
  }

  return (
    <ApproveModal
      isOpened={isOpen}
      closeModal={handleCloseModal}
      toggleMode={handleToggleMode}
      isApprovalMode={isApprovalModeEnabled}
    />
  );
}

export default RosterApproveModal;
