import React, { useState } from 'react';
import { useArrayHandler } from 'hooks';
import { SafeDictionary } from 'ts-essentials';
import EditableDefaultBreakRule from './EditableDefaultBreakRule';
import { DefaultBreaksFieldGroupBreak } from './types';
import { RemoveCustomBreakRuleDialog } from '../RemoveCustomBreakRuleDialog';
import { formatDuration } from 'lib/helpers';

type Props = {
  value: DefaultBreaksFieldGroupBreak[];
  onChange: (rules: DefaultBreaksFieldGroupBreak[]) => void;
  errors: SafeDictionary<string> | undefined;
};

export default EditDefaultBreakRules;

export function EditDefaultBreakRules({ value, onChange, errors }: Props) {
  const rulesHandler = useArrayHandler(value, onChange);
  const [isOpened, setIsOpened] = useState(false);
  const [ruleToRemove, setRuleToRemove] =
    useState<DefaultBreaksFieldGroupBreak | null>(null);
  const onClose = () => setIsOpened(false);
  const removeBreak = (index: number) => {
    setRuleToRemove(value[index]);
    setIsOpened(true);
  };
  const handleRemove = () => {
    if (ruleToRemove) {
      rulesHandler.removeItem(ruleToRemove.id);
    }
    setIsOpened(false);
  };
  const getDetails = () => {
    let details: any = '';
    if (ruleToRemove) {
      details = `Shifts up to ${formatDuration(ruleToRemove.shift_duration, {
        hoursPrefix: 'hrs',
      })} long, ${ruleToRemove.break_duration}m Break Duration`;
    }
    return details;
  };
  return (
    <>
      {value.map((defaultBreakRule, defaultBreakRuleIndex) => (
        <EditableDefaultBreakRule
          key={defaultBreakRule.id}
          value={defaultBreakRule}
          onChange={rulesHandler.updateItem}
          error={errors?.[defaultBreakRule.id]}
          labelVisible={defaultBreakRuleIndex === 0}
          index={defaultBreakRuleIndex}
          removeBreak={(e) => removeBreak(defaultBreakRuleIndex)}
        />
      ))}
      <RemoveCustomBreakRuleDialog
        isOpened={isOpened}
        onClose={onClose}
        handleSubmit={handleRemove}
        details={getDetails()}
      />
    </>
  );
}
