import React from 'react';
import { NativeTableElement } from '../type';
import { getClass } from '../../_lib/helper';
import './NativeTableTd.scss';

type NativeTableTdProps = NativeTableElement & {};

function NativeTableTd({
  children,
  className,
  colspan,
  rowspan,
  width,
  textAlign,
}: NativeTableTdProps) {
  return (
    <td
      className={getClass('elmo-nativetable-td', className)}
      colSpan={colspan}
      rowSpan={rowspan}
      style={{
        width: width,
      }}
      align={textAlign}
    >
      {children}
    </td>
  );
}

export default NativeTableTd;
