import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { RosterViewFilters } from 'page/Roster/components';
import { CovidNavigation, PeriodSelect, PrintButton } from './partials';

const useStyles = makeStyles((theme) => {
  const breakpointSmUp = theme.breakpoints.up('sm');
  const breakpointLgUp = theme.breakpoints.up('lg');
  const rowYGutter = theme.spacing(3);

  return createStyles({
    root: {
      paddingBottom: rowYGutter,
      position: 'relative',
      [breakpointLgUp]: {
        display: 'flex',
      },
      '.elmo-layout--is-modal-open &': {
        zIndex: 6
      }
    },
    firstRow: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: rowYGutter,
      [breakpointSmUp]: {
        justifyContent: 'initial',
      },
    },
    navigation: {
      marginRight: theme.spacing(1),
    },
    period: {
      [breakpointSmUp]: {
        marginRight: theme.spacing(2),
      },
    },
    secondRow: {
      [breakpointSmUp]: {
        display: 'flex',
      },
      [breakpointLgUp]: {
        flexGrow: 1,
        justifyContent: 'space-between',
      },
    },
    filters: {
      paddingBottom: rowYGutter,
      [breakpointSmUp]: {
        marginRight: theme.spacing(1),
      },
    },
    print: {},
  });
});

export const CovidTracingPanel = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.firstRow}>
        <CovidNavigation className={classes.navigation} />

        <PeriodSelect className={classes.period} />
      </div>

      <div className={classes.secondRow}>
        <RosterViewFilters className={classes.filters} />

        <PrintButton className={classes.print} />
      </div>
    </div>
  );
};
