import React from 'react';
import { ButtonCard } from 'elmo-elements';
import { useRoute } from 'hooks';
import { MenuItem } from './types';

export default function MenuButton({ label, to, icon }: MenuItem) {
  const route = useRoute();

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    route.goTo(to);
  };

  return (
    <ButtonCard
      className="mb-2"
      title={label}
      onClick={handleClick}
      href={to}
      icon={icon}
    />
  );
}
