import React from 'react';
import { useSelector } from 'react-redux';
import { ExpectedFlag } from 'feature-flags';
import { getFlag } from 'state/Flags';
import FeatureFlagContext from './FeatureFlagContext';
import On from './On';
import Off from './Off';

type Props = {
  children: React.ReactNode;
  name: ExpectedFlag;
};

export function FeatureFlag({ children, name }: Props) {
  const isEnabled = useSelector(getFlag[name]);

  return (
    <FeatureFlagContext.Provider value={{ isEnabled }}>
      {children}
    </FeatureFlagContext.Provider>
  );
}

FeatureFlag.On = On;
FeatureFlag.Off = Off;

export default FeatureFlag;
