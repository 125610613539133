import { createReducer } from 'lib/store-utils';
import _ from 'lodash';
import * as PayConditionsPage from 'state/PayConditionsPage';
import * as PayElementsPage from 'state/PayElementsPage';
import { PayElement } from 'type';
import * as actions from './actions';
import { PayElementsCollectionReducerState } from './types';

export const payElementsCollection =
  createReducer<PayElementsCollectionReducerState>(
    {},
    {
      byId: {},
      allIds: [],
    }
  );

const fetchHandler = (
  state: PayElementsCollectionReducerState,
  { payElements }: { payElements: PayElement[] }
): PayElementsCollectionReducerState => ({
  byId: _.keyBy(payElements, ({ id }) => id),
  allIds: payElements.map(({ id }) => id),
});

payElementsCollection
  .on(PayElementsPage.BOX_PAY_ELEMENTS_PAGE_DATA_GET.success, fetchHandler)
  .on(PayConditionsPage.BOX_PAY_CONDITIONS_PAGE_DATA_GET.success, fetchHandler)
  .on(
    actions.BOX_PAY_ELEMENTS_PAY_ELEMENT_CREATE.success,
    (state, payElement): PayElementsCollectionReducerState => ({
      byId: {
        ...state.byId,
        [payElement.id]: payElement,
      },
      allIds: [...state.allIds, payElement.id],
    })
  )
  .on(
    actions.BOX_PAY_ELEMENTS_PAY_ELEMENT_UPDATE.success,
    ({ byId, allIds }, payElement): PayElementsCollectionReducerState => ({
      byId: {
        ...byId,
        [payElement.id]: payElement,
      },
      allIds,
    })
  )
  .on(
    actions.BOX_PAY_ELEMENTS_PAY_ELEMENT_DELETE.success,
    (state, { id }): PayElementsCollectionReducerState => {
      const { [id]: removed, ...byId } = state.byId;

      return {
        byId,
        allIds: _.without(state.allIds, id),
      };
    }
  );
