import {
  DialogContent,
  DialogTitle,
  Typography,
} from 'extended-oxygen-elements';
import { commonError } from 'messages';
import { FormattedErrors } from 'type';

type Props = {
  errors: FormattedErrors;
};

export default function ViewAndAssignEmployeesDialogError({
  errors,
}: Props) {
  return (
    <>
      <DialogTitle>{commonError}</DialogTitle>
      <DialogContent>
        {errors.map((error, errorIndex) => (
          <Typography key={errorIndex}>{error}</Typography>
        ))}
      </DialogContent>
    </>
  );
}
