import Api from 'lib/Api';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { formatError } from 'state/helpers';
import { processApiRequest } from 'state/ProcessApiRequest';
import { getRotaUserListRequest } from 'state/UsersCollection';
import { AuditTrail } from 'type';
import * as actions from './actions';
import { getAuditFiltersPagesSelector } from './selectors';

export const getAuditTrail = function* (): SagaIterator {
  const payload = yield select(getAuditFiltersPagesSelector);
  try {
    const [auditTrail]: [AuditTrail] = yield all([
      call(processApiRequest, Api.AuditTrail.getAll, payload),
      call(getRotaUserListRequest),
    ]);
    yield put(actions.BOX_AUDIT_TRAIL_SUCCESS(auditTrail));
  } catch (error) {
    yield put(actions.BOX_AUDIT_TRAIL_FAILURE(formatError(error)));
  }
};

export const getAuditTrailFiltered = function* (): SagaIterator {
  const payload = yield select(getAuditFiltersPagesSelector);
  try {
    const [auditTrail]: [AuditTrail] = yield all([
      call(processApiRequest, Api.AuditTrail.getAll, payload),
    ]);
    yield put(actions.BOX_AUDIT_TRAIL_SUCCESS(auditTrail));
  } catch (error) {
    yield put(actions.BOX_AUDIT_TRAIL_FAILURE(formatError(error)));
  }
};

export const watchAuditTrials = function* (): SagaIterator {
  yield takeLatest(actions.BOX_AUDIT_TRAIL_REQUEST, getAuditTrail);
  yield takeLatest(
    actions.BOX_AUDIT_TRAIL_FILTERS_UPDATE,
    getAuditTrailFiltered
  );
  yield takeLatest(actions.BOX_AUDIT_TRAIL_PAGE_UPDATE, getAuditTrailFiltered);
};
