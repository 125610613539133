import { useDeleteDialogContext } from 'contexts';
import {
  DeleteOutlinedIcon,
  EditOutlinedIcon,
  FormattedDuration,
} from 'element';
import { ButtonGroup, IconButton } from 'extended-oxygen-elements';
import { useActions, useSelectorWithProps } from 'hooks';
import React from 'react';
import { BOX_ACCOUNT_PAY_CONDITIONS_RULE_UPDATE } from 'state/Account';
import { ShiftOvertimeRuleWithDay } from 'state/Account/types';
import { BOX_PAY_CONDITIONS_PAGE_DIALOG_OPENED } from 'state/PayConditionsPage';
import { getPayElement } from 'state/PayElementsCollection';
import { PaymentSettingsOvertimeRuleDays } from 'type';
import LoadingsTableCell from '../../components/LoadingsTableCell';
import LoadingsTableRow from '../../components/LoadingsTableRow';
import { usePayConditionsListContext } from '../../pay-conditions-list-context';
import DayButton from '../components/DayButton';

type WeekendLoadingProps = {
  overtimeRule: ShiftOvertimeRuleWithDay;
};

export default function WeekendLoading({ overtimeRule }: WeekendLoadingProps) {
  const [dialogOpened, ruleUpdateRequest] = useActions([
    BOX_PAY_CONDITIONS_PAGE_DIALOG_OPENED,
    BOX_ACCOUNT_PAY_CONDITIONS_RULE_UPDATE.request,
  ]);
  const payElement = useSelectorWithProps(
    getPayElement,
    overtimeRule.pay_element_id
  );
  const { openEdit, isEditOpen } = usePayConditionsListContext();
  const { openDialog } = useDeleteDialogContext();

  const handleEditBtnClick = () => {
    openEdit(overtimeRule.id);
  };

  const handleDeleteBtnClick = () => {
    dialogOpened();
    openDialog(overtimeRule.id);
  };

  const handleDaySelect = (day: PaymentSettingsOvertimeRuleDays) => {
    ruleUpdateRequest({
      ...overtimeRule,
      day,
    });
  };

  return (
    <LoadingsTableRow data-testid="pay-condition-row">
      <LoadingsTableCell data-testid="pay-condition-day" padding="button">
        <ButtonGroup>
          <DayButton
            label="Saturday"
            value="saturday"
            selectedValue={overtimeRule.day}
            onSelect={handleDaySelect}
            readonly
          />
          <DayButton
            label="Sunday"
            value="sunday"
            selectedValue={overtimeRule.day}
            onSelect={handleDaySelect}
            readonly
          />
        </ButtonGroup>
      </LoadingsTableCell>

      <LoadingsTableCell data-testid="pay-condition-from">
        <FormattedDuration>{overtimeRule.from_minutes}</FormattedDuration>
      </LoadingsTableCell>

      <LoadingsTableCell data-testid="pay-condition-to">
        <FormattedDuration>{overtimeRule.to_minutes}</FormattedDuration>
      </LoadingsTableCell>

      <LoadingsTableCell data-testid="pay-condition-element-name">
        {payElement.name}
      </LoadingsTableCell>

      <LoadingsTableCell padding="checkbox">
        {!isEditOpen && (
          <IconButton
            onClick={handleEditBtnClick}
            data-testid="pay-condition-edit-btn"
          >
            <EditOutlinedIcon />
          </IconButton>
        )}
      </LoadingsTableCell>

      <LoadingsTableCell padding="checkbox">
        {!isEditOpen && (
          <IconButton
            disabled={payElement.is_default}
            onClick={handleDeleteBtnClick}
            data-testid="pay-condition-delete-btn"
          >
            <DeleteOutlinedIcon />
          </IconButton>
        )}
      </LoadingsTableCell>
    </LoadingsTableRow>
  );
}
