import { OptionalReturn, RosteredShift, StringMap } from 'type';
import { StoreState } from 'state/types';
import { fromToBySiteTimezoneSelectorsCreator } from 'state/selectors';
import { createSelector } from 'reselect';
import _ from 'lodash';

const getState = (state: StoreState) => state.rosteredShiftsCollection;

export const getSiteId = (state: StoreState) => getState(state).site_id;
export const getRosteredShifts = (state: StoreState) =>
  getState(state).rosteredShifts;
export const getRosteredShift = (state: StoreState, id: string) =>
  getState(state).rosteredShifts[id];
export const getRosteredShiftSafe = getRosteredShift as OptionalReturn<typeof getRosteredShift>;
export const getRosteredShiftBreaks = (state: StoreState, id: string) =>
  getRosteredShift(state, id).breaks;
export const getFrom = (state: StoreState) => getState(state).from;
export const getTo = (state: StoreState) => getState(state).to;

export const {
  currentSiteTimezoneSelector,
  fromBySiteTimezoneSelector,
  toBySiteTimezoneSelector,
} = fromToBySiteTimezoneSelectorsCreator(getState);

export const rosteredShiftsArraySelector = createSelector<
  StoreState,
  StringMap<RosteredShift>,
  RosteredShift[]
  >(getRosteredShifts, _.values);
