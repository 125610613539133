import React from 'react';
import { Box } from 'extended-oxygen-elements';

type TimesheetCardHeaderProps = {
  children: React.ReactNode;
};

export function TimesheetCardHeader({ children }: TimesheetCardHeaderProps) {
  return <Box position="relative">{children}</Box>;
}
