import { createAction } from 'lib/store-utils';
import { DeleteInstancePayload, FormattedErrors, ShiftTrade, ShiftTradesList, UpdateFormPayload } from '../../../type';
import {
  AcceptTradePayload,
  GetSwapProposalsPayload,
  ShiftTradesFilter,
  SwapProposalsResponse,
  TradeAcceptedResponse,
  ShiftSwapPayload,
  DeclineByProposalPayload,
  SetOutDatedPayload,
} from './types';

export const BOX_SHIFT_TRADES_GET_DATA = createAction('Shift trades / get data request');
export const BOX_SHIFT_TRADES_GET_DATA_SUCCESS = createAction<any>('Shift trades / get data request success');
export const BOX_SHIFT_TRADES_GET_DATA_FAILURE = createAction<FormattedErrors>('Shift trades / get data request failure');

export const BOX_SHIFT_TRADES_SET_OUTDATED = createAction<SetOutDatedPayload>('Shift trades / set outdated');
export const BOX_SHIFT_TRADES_CLEAR_OUTDATED_STATE = createAction('Shift trades / clear state');

export const BOX_SHIFT_TRADES_TRADE_CHANGED = createAction<ShiftTrade>('Shift trades / trade changed');
export const BOX_SHIFT_TRADES_TRADE_DELETED = createAction<DeleteInstancePayload>('Shift trades / trade deleted');

export const BOX_SHIFT_TRADES_UPDATE_REQUEST = createAction('Shift trades / update request');
export const BOX_SHIFT_TRADES_UPDATE_SUCCESS = createAction<ShiftTradesList>('Shift trades / update success');
export const BOX_SHIFT_TRADES_UPDATE_FAILURE = createAction<FormattedErrors>('Shift trades / update failure');

export const BOX_SHIFT_TRADES_REMOVE_ERRORS = createAction('Shift trades / remove errors');

export const BOX_SHIFT_TRADES_UPDATE_FORM_FILTERS = createAction<UpdateFormPayload>('Shift trades / Change filters request');
export const BOX_SHIFT_TRADES_UPDATE_CUSTOM_FILTERS = createAction('Shift trades / Change custom filters request');
export const BOX_SHIFT_TRADES_TAB_CHANGED_FROM_CUSTOM_FILTERS = createAction('Shift trades / tab changed from custom filters');

export const BOX_SHIFT_TRADES_CLEAR_FILTER_FORM = createAction('Shift trades / Reset filters');

export const BOX_SHIFT_TRADES_TOGGLE_TRADES_TYPE_REQUEST = createAction<ShiftTradesFilter>('Shift trades / Change trades type request');
export const BOX_SHIFT_TRADES_TOGGLE_TRADES_TYPE_CUSTOM_REQUEST = createAction<ShiftTradesFilter>('Shift trades / Change trades type request');

export const BOX_SHIFT_TRADES_SWAP_MODAL_CLOSE = createAction('Shift trades / swap modal close');
export const BOX_SHIFT_TRADES_SWAP_MODAL_OPEN = createAction<string>('Shift trades / swap modal open');

export const BOX_SHIFT_TRADES_CHANGE_PAGE_SIZE = createAction<number>('Shift trades / change page size');
export const BOX_SHIFT_TRADES_CHANGE_PAGE = createAction<number>('Shift trades / change page');

export const BOX_SHIFT_TRADES_ACCEPT_SHIFT_REQUEST = createAction<AcceptTradePayload>('Shift trades / accept request');
export const BOX_SHIFT_TRADES_ACCEPT_SHIFT_SUCCESS = createAction<TradeAcceptedResponse>('Shift trades / accept request success');
export const BOX_SHIFT_TRADES_ACCEPT_SHIFT_FAILURE = createAction<FormattedErrors>('Shift trades / accept request failure');

export const BOX_SHIFT_TRADES_GET_SWAP_PROPOSALS = createAction<GetSwapProposalsPayload>('Shift trades / get swap proposals');
export const BOX_SHIFT_TRADES_GET_SWAP_PROPOSALS_SUCCESS = createAction<SwapProposalsResponse>('Shift trade / get proposals success');
export const BOX_SHIFT_TRADES_GET_SWAP_PROPOSALS_FAILURE = createAction<FormattedErrors>('Shift trade / get proposals failure');

export const BOX_SHIFT_TRADES_SWAP_REQUEST = createAction<ShiftSwapPayload>('Shift trade / swap request');
export const BOX_SHIFT_TRADES_SWAP_SUCCESS = createAction<string>('Shift trade / swap success');
export const BOX_SHIFT_TRADES_SWAP_FAILURE = createAction<FormattedErrors>('Shift trade / swap failure');

export const BOX_SHIFT_TRADES_REMOVE_SWAP_MODAL_ERRORS = createAction('Shift trade / clear modal errors');

export const BOX_SHIFT_TRADES_DELETE_SHIFT = createAction<string>('Shift trade / delete shift from list');
export const BOX_SHIFT_TRADES_DELETE_SHIFT_SUCCESS = createAction<string>('Shift trade / delete shift from list success');
export const BOX_SHIFT_TRADES_DELETE_SHIFT_FAILURE = createAction<FormattedErrors>('Shift trade / delete shift from list failure');

export const BOX_SHIFT_TRADES_GET_SHIFT_PROPOSALS = createAction('Shift trade / get list of proposals');
export const BOX_SHIFT_TRADES_GET_SHIFT_PROPOSALS_SUCCESS = createAction<ShiftTrade>('Shift trade / get list of proposals success');
export const BOX_SHIFT_TRADES_GET_SHIFT_PROPOSALS_FAILURE = createAction<FormattedErrors>('Shift trade / get list of proposals failure');

export const BOX_SHIFT_TRADES_SET_SHIFT_TO_SWAP = createAction<ShiftTrade>('Shift trade / set shift to swap');
export const BOX_SHIFT_TRADES_SET_CURRENT_SHIFT_ID = createAction<string | null>('Shift trade / set current shift id');
export const BOX_SHIFT_TRADES_RESET_CURRENT_SHIFT = createAction('Shift trade / reset current offered shift');

export const BOX_SHIFT_TRADES_SELECT_PROPOSAL = createAction<string>('Shift trade / select proposal');
export const BOX_SHIFT_TRADES_SELECT_PROPOSAL_SUCCESS = createAction<ShiftTrade>('Shift trade / select proposal success');
export const BOX_SHIFT_TRADES_SELECT_PROPOSAL_FAILURE = createAction<FormattedErrors>('Shift trade / select proposal failure');

export const BOX_SHIFT_TRADES_DECLINE_PROPOSAL = createAction<string>('Shift trade / decline proposal');
export const BOX_SHIFT_TRADES_DECLINE_PROPOSAL_SUCCESS = createAction<ShiftTrade>('Shift trade / decline proposal success');
export const BOX_SHIFT_TRADES_DECLINE_PROPOSAL_FAILURE = createAction<FormattedErrors>('Shift trade / decline proposal failure');

export const BOX_SHIFT_TRADES_CHANGE_SORT = createAction<any>('Shift trade / change sort params');

export const BOX_SHIFT_TRADES_DECLINE_SHIFT_BY_PROPOSAL = createAction<DeclineByProposalPayload>('Shift trade / decline shift by proposal');
export const BOX_SHIFT_TRADES_DECLINE_SHIFT_BY_PROPOSAL_SUCCESS = createAction<string>('Shift trade /decline shift byproposal success');
export const BOX_SHIFT_TRADES_DECLINE_SHIFT_BY_PROPOSAL_FAILURE = createAction<FormattedErrors>('Shift trade / decline shift by proposal failure');

export const BOX_SHIFT_TRADES_CONFIRMATION_MODAL_OPEN = createAction<FormattedErrors>('Trades / confirmation modal open');
export const BOX_SHIFT_TRADES_CONFIRMATION_MODAL_CLOSE = createAction<boolean>('Trades / confirmation modal close');

export const BOX_SHIFT_TRADES_SOCKET_UPDATE_CANCELLED = createAction('Trades / socket update cancelled');
