import React, { ChangeEventHandler } from 'react';
import './RadioElement.scss';

type RadioElementProps = {
  name?: string;
  value: string | number;
  disabled?: boolean;
  checked?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

function RadioElement({
  name,
  value,
  disabled,
  onChange,
  checked,
}: RadioElementProps) {
  return (
    <span className="elmo-radio__element">
      <input
        type="radio"
        name={name}
        value={value}
        disabled={disabled}
        tabIndex={-1}
        onChange={onChange}
        checked={checked}
      />
      <span className="elmo-radio__icon" />
    </span>
  );
}

export default RadioElement;
