import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from '../../../../../../state/types';
import { getCanViewForecastsAndEvents } from '../../../../../../state/Account';
import { DayForecastsAndEventsRow } from './components/DayForecastsAndEventsRow';
import { hasPermissionSelector } from '../../../../../../state/Auth';

type OwnProps = {};

type StateProps = {
  isEventsDataShown: boolean;
  hasPermissionToView: boolean;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

const DayForecastsAndEventsComponent = ({ isEventsDataShown, hasPermissionToView }: Props) => {
  if (isEventsDataShown && hasPermissionToView) {
    return <DayForecastsAndEventsRow />;
  }

  return null;
};

const mapStateToProps = (state: StoreState): StateProps => ({
  isEventsDataShown: getCanViewForecastsAndEvents(state),
  hasPermissionToView: hasPermissionSelector(state, 'roster.events.view')
});

// const mapDispatchToProps: DispatchProps = {};

export const DayForecastsAndEvents = connect(
  mapStateToProps
)(DayForecastsAndEventsComponent);
