import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BOX_USER_PROFILE_AWARDS_DELETE_MODAL_CLOSE,
  BOX_USER_PROFILE_AWARDS_DELETE_REQUEST,
  BOX_USER_PROFILE_AWARDS_MODALS_CLEAR_ERRORS,
  getAwardDeleteModal,
  getAwardIdToDelete,
} from 'state/Users/UserProfile';
import {
  ErrorBox,
  PageDialog,
  PageDialogCancel,
  PageDialogSubmit,
} from 'element';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';
import { Typography } from 'oxygen-elements';

export const DeleteConfirmation = () => {
  const awardId = useSelector(getAwardIdToDelete);
  const deleteModal = useSelector(getAwardDeleteModal);
  const dispatch = useDispatch();

  const deleteAward = () => {
    if (awardId) {
      dispatch(BOX_USER_PROFILE_AWARDS_DELETE_REQUEST(awardId));
    }
  };

  const closeModal = () => {
    dispatch(BOX_USER_PROFILE_AWARDS_DELETE_MODAL_CLOSE());
  };

  const clearErrors = () => {
    dispatch(BOX_USER_PROFILE_AWARDS_MODALS_CLEAR_ERRORS());
  };

  return (
    <PageDialog
      maxWidth={'xs'}
      open={deleteModal.isOpened}
      onClose={closeModal}
    >
      <DialogTitle>Remove the award?</DialogTitle>
      <DialogContent>
        <ErrorBox
          errors={deleteModal.errors}
          watchFormErrors={false}
          clearErrors={clearErrors}
        />
        <Typography variant={'body1'}>
          Once deleted, this award will be permanently removed.
        </Typography>
      </DialogContent>
      <DialogActions>
        <PageDialogCancel onClick={closeModal}>Cancel</PageDialogCancel>
        <PageDialogSubmit
          onClick={deleteAward}
          disabled={deleteModal.isDeleting}
          loading={deleteModal.isDeleting}
        >
          Delete
        </PageDialogSubmit>
      </DialogActions>
    </PageDialog>
  );
};
