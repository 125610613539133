import moment from 'moment';
import { RosterTimesheetListedStoppedWithPayEntries } from 'type';
import { getMinutesFromDayStart } from 'lib/helpers';
import { breaksFieldGroupUtils, dateRangeFieldGroupUtils } from 'element';

export const createFormFields = ({
  area_id,
  role_id,
  rostered_shift_id,
  start,
  end,
  notes,
  breaks,
}: RosterTimesheetListedStoppedWithPayEntries) => ({
  role: {
    area_id,
    role_id,
  },
  rostered_shift_id,
  startDate: dateRangeFieldGroupUtils.getDateFieldValue(start),
  startTime: dateRangeFieldGroupUtils.getTimeFieldValue(start),
  endDate: dateRangeFieldGroupUtils.getDateFieldValue(end),
  endTime: dateRangeFieldGroupUtils.getTimeFieldValue(end),
  notes,
  breaks: breaks.map(({ start: breakStartTime, ...restBreak }) =>
    breaksFieldGroupUtils.makeFormBreak({
      ...restBreak,
      start: getMinutesFromDayStart(moment.parseZone(breakStartTime)),
    })
  ),
});

export type EditDialogFormFields = ReturnType<typeof createFormFields>;

export default createFormFields;
