import { DateType } from 'type';
import { BreaksFieldGroupBreak } from 'element';

type Props = {
  date: DateType;
  roleOptions: { area_id: string; role_id: string }[];
};

export type CreateFormFields = ReturnType<typeof createFormFields>;

export default createFormFields;

export function createFormFields({ date, roleOptions }: Props) {
  const role =
    roleOptions.length === 1 ? roleOptions[0] : { area_id: '', role_id: '' };

  return {
    role,
    startDate: date,
    startTime: null as number | null,
    endDate: date,
    endTime: null as number | null,
    notes: null as string | null,
    breaks: [] as BreaksFieldGroupBreak[],
  };
}
