import React from 'react';

export function useUpdateEffect(
  effectCallback: React.EffectCallback,
  deps?: React.DependencyList
) {
  const didMountRef = React.useRef(false);

  React.useEffect(() => {
    if (didMountRef.current) {
      return effectCallback();
    }
    didMountRef.current = true;
  }, deps);
}

export default useUpdateEffect;
