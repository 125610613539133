import moment from 'moment';
import { createEmptyTimesheet } from 'lib/helpers';
import { TimesheetModalReducerState } from './types';

const createTimesheetPayload = () => ({
  ...createEmptyTimesheet(),
  end: moment(),
  user_id: null
});

export const getDefaultState = (): TimesheetModalReducerState => ({
  isUpdating: false,
  errors: [],
  timesheetModal: {
    isLoading: false,
    isOpened: false,
    isUpdating: false,
    errors: [],
    isCurrentUser: false,
    allowedShifts: {},
    isLoadingShifts: false
  },
  usersList: [],
  overlapShiftModal: {
    isOpened: false,
    isUpdating: false,
    isApproveOverlap: false,
    isDeleting: false,
    errors: [],
    ignoreErrors: false
  },
  timesheetPayload: createTimesheetPayload(),
  currentTimesheet: createTimesheetPayload(),
  initialPayEntries: [],
  initialDuty: {
    higher_duty_end: null,
    higher_duty_start: null,
    higher_duty: null
  },
  isRecalculating: false,
  recalculated: false,
  dutiesList: [],
  dutiesListIsLoading: false,
  allowancesIsLoading: false,
  allowancesList: [],
  allowancesIsAdding: false,
  allowanceIsDeleting: false
});
