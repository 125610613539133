import { createAction } from 'lib/store-utils';
import { SortShiftOffers } from 'type';
import { OmitShiftOfferPayload, ShiftOffersFilterTypes } from './types';
import { ManagerDashboardGetShiftOffersResponse } from 'lib/Api/type';

export const BOX_SHIFT_OFFERS_GET_PAGE_DATA_REQUEST = createAction('Shift offers / Get page data request');

export const BOX_SHIFT_OFFERS_GET_OFFERS_LIST_SUCCESS = createAction<ManagerDashboardGetShiftOffersResponse>('Shift offers / Get offers list success');
export const BOX_SHIFT_OFFERS_OMIT_SHIFT_OFFER = createAction<OmitShiftOfferPayload>('Shift offers / omit shift offer');

export const BOX_SHIFT_OFFERS_TOGGLE_FILTER_TYPE = createAction<ShiftOffersFilterTypes>('Shift offers / Toggle filter type');

export const BOX_SHIFT_OFFERS_CHANGE_ORDER = createAction<SortShiftOffers>('Shift offers / Change reports order');

export const BOX_SHIFT_OFFERS_CHANGE_PAGE = createAction<number>('Shift offers / Change page');
export const BOX_SHIFT_OFFERS_CHANGE_PAGE_SIZE = createAction<number>('Shift offers / Change page size');

export const BOX_SHIFT_OFFERS_DELETE_OFFER_FROM_LIST = createAction<string>('Shift offers / delete offer from list');
