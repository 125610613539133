import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

export const DayWrapper = ({
  path,
  children,
  className,
  onClick
}: {
  path?: string;
  children: ReactNode;
  className: string;
  onClick?: () => void;
}) =>
  path ? (
    <Link to={path} className={className} onClick={onClick}>
      {children}
    </Link>
  ) : (
    <div className={className}>{children}</div>
  );
