import React, { Component } from 'react';
import { Modal, Button, Loader } from 'elmo-elements';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import { getUnavailabilityModalProps } from 'state/EmployeeDashboard/Unavailability/selectors';
import {
  UnavailabilityModalProps,
  EmployeeUnavailabilityRequestPayload
} from 'state/EmployeeDashboard/Unavailability/types';
import {
  BOX_EMPLOYEE_CREATE_UNAVAILABILITY,
  BOX_EMPLOYEE_UNAVAILABILITY_MODAL_CLOSE,
  BOX_EMPLOYEE_UPDATE_UNAVAILABILITY,
  BOX_EMPLOYEE_DELETE_UNAVAILABILITY,
  BOX_EMPLOYEE_UNAVAILABILITY_CLEAR_ERRORS
} from 'state/EmployeeDashboard/Unavailability';
import CreateEditModal from './components/CreateEditModal';

type StateProps = {
  modal: UnavailabilityModalProps;
};

type DispatchProps = {
  closeModal: () => void;
  clearErrors: () => void;
  createUnavailability: (payload: EmployeeUnavailabilityRequestPayload) => void;
  updateUnavailability: (payload: EmployeeUnavailabilityRequestPayload) => void;
  deleteUnavailability: (payload: string) => void;
};

type Props = StateProps & DispatchProps;

class UnavailabilityModal extends Component<Props> {

  render() {
    const { type, data } = this.props.modal;
    if ( data.status === 'approved' || type === 'view' ) {
      return (
        <CreateEditModal
          closeModal={this.props.closeModal}
          title={'Unavailability'}
          submitLabel={'Close'}
          clearErrors={this.props.clearErrors}
          delete={this.props.deleteUnavailability}
        />
      );
    }
    switch (type) {
      case 'create':
        return (
          <CreateEditModal
            closeModal={this.props.closeModal}
            title={'Add unavailability'}
            submitLabel={'Add'}
            create={this.props.createUnavailability}
            clearErrors={this.props.clearErrors}
          />
        );
      default:
        return (
          <CreateEditModal
            closeModal={this.props.closeModal}
            title={'Edit unavailability'}
            submitLabel={'Save'}
            edit={this.props.updateUnavailability}
            delete={this.props.deleteUnavailability}
            clearErrors={this.props.clearErrors}
          />
        );
    }
  }

}

const mapStateToProps = (state: StoreState): StateProps => ({
  modal: getUnavailabilityModalProps(state)
});

const mapToDispatchProps: DispatchProps = {
  closeModal: BOX_EMPLOYEE_UNAVAILABILITY_MODAL_CLOSE,
  createUnavailability: BOX_EMPLOYEE_CREATE_UNAVAILABILITY,
  updateUnavailability: BOX_EMPLOYEE_UPDATE_UNAVAILABILITY,
  deleteUnavailability: BOX_EMPLOYEE_DELETE_UNAVAILABILITY,
  clearErrors: BOX_EMPLOYEE_UNAVAILABILITY_CLEAR_ERRORS
};

export default connect(mapStateToProps, mapToDispatchProps)(UnavailabilityModal);