import { Timesheet } from 'type';
import { useColorId, useElementId, useFormatTime } from './hooks';
import {
  useHandleClick,
  useHandleClickAddButton,
  useIcons,
  useIsLocationShown,
  useTimeColor,
} from './timesheet-hooks';

export const useTimesheetDayCard = (timesheet: Timesheet) => {
  const colorId = useColorId(timesheet);
  const icons = useIcons(timesheet);
  const from = useFormatTime(timesheet.start);
  const to = useFormatTime(timesheet.end);
  const onClickAddButton = useHandleClickAddButton(timesheet);
  const onClick = useHandleClick(timesheet);
  const timeColor = useTimeColor(timesheet);
  const isLocationShown = useIsLocationShown(timesheet);
  const id = useElementId(timesheet);

  const viewProps = {
    from,
    to,
    colorId,
    coloredBg: true,
    onClickAddButton,
    onClick,
    timeColor,
    isLocationShown,
    shape: 'bottom' as const,
    className: 'timesheet-card',
    id,
  };

  return {
    viewProps,
    icons,
    breaks: timesheet.breaks,
  };
};
