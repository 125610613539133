import { createOxygenTheme, lighten } from 'extended-oxygen-elements';

export const oxygenTheme = createOxygenTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 840,
      lg: 1024,
      xl: 1280,
    },
  },
  palette: {
    text: {
      primary: '#212121',
      secondary: '#545454',
    },
    info: {
      light: '#EBF7FF',
      semiLight: lighten('#0062A3', 0.1),
      main: '#0062A3',
      dark: '#225990',
    },
  },
  zIndex: {
    globalOverlay: 1600,
  },
});

oxygenTheme.gap = (value: number) => parseInt(oxygenTheme.spacing(value), 10);

oxygenTheme.components = oxygenTheme.components || {};
oxygenTheme.components.MuiSkeleton = {
  styleOverrides: {
    rectangular: {
      borderRadius: oxygenTheme.shape.borderRadiusLarge,
    },
  },
};

export default oxygenTheme;
