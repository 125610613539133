import { createSelector } from 'reselect';
import {
  NotFirstAndEmptyPageSelector,
  Pager,
  Report,
  SortReports,
  StringMap
} from 'type';
import { StoreState } from '../types';
import { ReportsWithPagerSelector } from '../../type';
import {
  arraySortedCombiner,
  notFirstAndEmptyPageCombiner,
  searchCombinerCreator,
  withoutCombiner,
  withPagerCombiner
} from '../combiners';

export const getReports = (state: StoreState): StringMap<Report> =>
  state.reports.reports;
export const getPager = (state: StoreState): Pager => state.reports.pager;
export const getSearchTerm = (state: StoreState): string => state.reports.searchTerm;
export const getSort = (state: StoreState): SortReports => state.reports.sort;
export const getDeleteQueue = (state: StoreState): string[] =>
  state.reports.deleteQueue;

export const getReportById = (state: StoreState, id: string): Report | null =>
  state.reports.reports[id] || null;

const reportsWithoutDeleteQueue = createSelector<
  StoreState,
  StringMap<Report>,
  string[],
  StringMap<Report>
>(getReports, getDeleteQueue, withoutCombiner);

const filteredReportsSelector = createSelector<
  StoreState,
  StringMap<Report>,
  string,
  Report[]
>(reportsWithoutDeleteQueue, getSearchTerm, searchCombinerCreator('name'));

const sortedReportsSelector = createSelector<
  StoreState,
  Report[],
  SortReports,
  Report[]
>(filteredReportsSelector, getSort, arraySortedCombiner);

export const reportsWithPagerSelector = createSelector<
  StoreState,
  Report[],
  Pager,
  ReportsWithPagerSelector
>(sortedReportsSelector, getPager, withPagerCombiner);

export const notFirstAndEmptyPageSelector = createSelector<
  StoreState,
  ReportsWithPagerSelector,
  NotFirstAndEmptyPageSelector
>(reportsWithPagerSelector, notFirstAndEmptyPageCombiner);
