import { SortAscIcon, SortDescIcon } from '../../icons';
import React from 'react';
import { SORT, SORT_ASC, SORT_DESC } from '../../_lib/const';
import { getClass } from '../../_lib/helper';

export type ListTableSortIconProps = {
  /** The sort direction. 'asc' | 'desc' */
  direction?: SORT;
};

function ListTableSortIcon({ direction }: ListTableSortIconProps) {
  let classes = getClass('elmo-listtable__sort-icon', '', {
    [direction as string]: direction !== undefined,
  });

  let icon = null;

  switch (direction) {
    case SORT_ASC: {
      icon = <SortAscIcon />;
      break;
    }
    case SORT_DESC: {
      icon = <SortDescIcon />;
      break;
    }

    default: {
      return null;
    }
  }

  return (
    <span className={classes} data-sort={direction}>
      {icon}
    </span>
  );
}

export default ListTableSortIcon;
