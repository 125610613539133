import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import { ErrorModalProps } from 'state/Roster/Roster/types';
import { BOX_ROSTER_GLOBAL_ERROR_MODAL_CLOSE } from 'state/Roster/Roster/actions';
import { getRosterErrorModal } from 'state/Roster/Roster/selectors';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';
import { PageDialog, PageDialogCancel } from 'element';
import { Typography } from 'oxygen-elements';

type OwnProps = {};

type StateProps = ErrorModalProps;

type DispatchProps = {
  closeModal: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const ErrorModal = (props: Props) => (
  <PageDialog
    maxWidth={'xs'}
    open={props.isOpened}
    id={'error-modal'}
    onClose={props.closeModal}
  >
    <DialogTitle>Error</DialogTitle>
    <DialogContent>
      {props.errors.map((error: string, index: number) => (
        <Typography key={index} variant={'body1'} sx={{ padding: '10px 0' }}>
          {error}
        </Typography>
      ))}
    </DialogContent>
    <DialogActions>
      <PageDialogCancel onClick={props.closeModal}>Close</PageDialogCancel>
    </DialogActions>
  </PageDialog>
);

const mapStateToProps: (state: StoreState) => StateProps = getRosterErrorModal;

export default connect(mapStateToProps, {
  closeModal: BOX_ROSTER_GLOBAL_ERROR_MODAL_CLOSE,
})(ErrorModal);
