import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { StoreState } from 'state/types';
import View from '../../components/ReportView';
import {
  BOX_REPORT_GENERATE_REPORT_REQUEST,
  BOX_REPORT_GET_REPORT_SUCCESS,
} from '../../../../state/Report';
import { GeneratedReport, Report, ReportType } from 'type';
import { BOX_REPORTS_CREATE_MODAL_CLOSE } from '../../../../state/Reports';
import EditReport from '../../components/EditReportModal';
import SaveAs from '../../components/SaveAs';
import { privateRoutes } from 'routes';
import { get } from 'lodash';
import { ReportTypesSelector } from 'type';
import { reportTypesSelector } from 'state/Account';

type StateProps = {
  generated: GeneratedReport;
  isModalRedirect: boolean;
  reportTypes: ReportTypesSelector;
};

type DispatchProps = {
  generate: (params: Report) => void;
  setReport: (params: Report) => void;
  closeCreateModal: () => void;
};

type Props = StateProps &
  DispatchProps &
  RouteComponentProps & {
    location: {
      state?: {
        filters: Report;
      };
    };
  };

class Generate extends React.Component<Props> {
  componentDidMount() {
    // if user reloaded a page: regenerate report or redirect to 'All reports'
    const { location, generated } = this.props;
    if (
      location.state &&
      location.state.filters &&
      location.state.filters.type
    ) {
      if (this.props.isModalRedirect) {
        this.props.closeCreateModal();
      } else if (generated.rows.length === 0 && location.state.filters) {
        this.props.setReport(location.state.filters);
        this.props.generate(location.state.filters);
      }
    } else {
      this.props.history.push(privateRoutes.reports.path);
    }
  }

  render() {
    return (
      <>
        <View title={this.title} isEdit={false} />
        <SaveAs redirectOnSuccess={true} />
        <EditReport />
      </>
    );
  }

  private get title(): string {
    const type: ReportType | undefined = get(
      this,
      'props.location.state.filters.type',
      ''
    );
    if (type) {
      const reportTypeLabel = this.props.reportTypes[type].label;
      if (
        ['event', 'payroll', 'roster', 'rostered_shift', 'timesheet'].indexOf(
          type
        ) !== -1
      ) {
        return `New ${reportTypeLabel} report`;
      }
      return `New ${reportTypeLabel}`;
    }

    return 'New generated report';
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  generated: state.report.generatedReport,
  isModalRedirect: state.reports.createModal.redirectOnSuccess,
  reportTypes: reportTypesSelector(state),
});

const mapDispatchToProps: DispatchProps = {
  generate: BOX_REPORT_GENERATE_REPORT_REQUEST,
  setReport: BOX_REPORT_GET_REPORT_SUCCESS,
  closeCreateModal: BOX_REPORTS_CREATE_MODAL_CLOSE,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Generate)
);
