import React from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from 'extended-oxygen-elements';
import { getPayElementIds } from 'state/PayElementsCollection';
import { PayElementsListContextProvider } from './payElementsList/pay-elements-list-context';
import CreatePayElement from './payElementsList/CreatePayElement';
import PayElementRow from './payElementsList/PayElementRow';
import AddPayElementButton from './payElementsList/AddPayElementButton';
import { DeleteContextProvider } from './payElementsList/delete-context';
import DeleteConfirmationDialog from './payElementsList/DeleteConfirmationDialog';

function PayElementsList() {
  const payElementIds = useSelector(getPayElementIds);
  const theme = useTheme();

  return (
    <PayElementsListContextProvider>
      <DeleteContextProvider>
        <Box minWidth={theme.breakpoints.values.sm} overflow="auto">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="40%">Name</TableCell>

                <TableCell width="30%">Multiplier</TableCell>

                <TableCell>Export code</TableCell>

                <TableCell />

                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {payElementIds.map((payElementId) => (
                <PayElementRow id={payElementId} key={payElementId} />
              ))}

              <CreatePayElement />
            </TableBody>
          </Table>
        </Box>

        <AddPayElementButton p={2} />

        <DeleteConfirmationDialog />
      </DeleteContextProvider>
    </PayElementsListContextProvider>
  );
}

export default PayElementsList;
