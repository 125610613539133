import { defaultBreaksFieldGroupValidator } from './DefaultBreaksFieldGroup';
import { FormFields } from './makeFormFields';
import { otherRosterSettingsFieldGroupValidator } from './OtherRosterSettingsFieldGroup';
import { shiftOffersFieldGroupValidator } from './ShiftOffersFieldGroup';
import { shiftSwapsFieldGroupValidator } from './ShiftSwapsFieldGroup';

export default function validate(formFields: FormFields) {
  return {
    ...defaultBreaksFieldGroupValidator(formFields),
    ...shiftSwapsFieldGroupValidator(formFields),
    ...shiftOffersFieldGroupValidator(formFields),
    ...otherRosterSettingsFieldGroupValidator(formFields),
  };
}
