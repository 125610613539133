import * as React from 'react';
import { connect } from 'react-redux';
import { TimerOutlinedIcon } from 'element';
import { Col, Row } from 'elmo-elements';
import { getDatesDiff, secondsToHours } from 'lib/helpers';
import moment, { Moment } from 'moment';

type Props = {
  time: number;
  onTimeEnd: () => void;
};

type State = {
  time: number;
  startTime: Moment
};

export class TimerComponent extends React.Component <Props, State> {

  timer: any = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      time: props.time,
      startTime: moment()
    }
  }

  componentDidMount(): void {
    this.start();
  }

  componentWillUnmount(): void {
    clearTimeout(this.timer);
  }

  render() {
    return <>
      <Row>
        <Col span={4}>
          <TimerOutlinedIcon/>
        </Col>
        <Col span={18}>
          <span>{secondsToHours(this.state.time, true, true)}</span>
        </Col>
      </Row>
    </>
  }

  start = () => {
    const sec = Math.round(getDatesDiff(moment(), this.state.startTime, false) as number);
    this.setState({
      time: this.props.time - sec
    });

    if (this.state.time > 0) {
      this.timer = setTimeout(() => {
        this.start();
      }, 1000);
    } else {
      this.props.onTimeEnd();
    }
  }
}

export const Timer = connect(
  null,
  null
)(TimerComponent);
