import { eventChannel } from 'redux-saga';
import { select } from 'redux-saga/effects';
import {
  DashboardShift,
  DeleteInstancePayload,
  ServerEvent,
  ServerShiftOfferWithProposals,
  ServerTimeOff,
  ShiftSwap,
} from 'type';
import { WS } from 'lib/SocketConnection';
import {
  updateEvent,
  updateShiftOfferWithProposals,
  updateTimeOff,
  updateTimeSheet,
  updateWorkingShift,
} from 'lib/Api/helpers';
import { getCurrentUserId } from 'state/Auth';
import {
  BOX_SHIFT_OFFERS_DELETE_ONE,
  BOX_SHIFT_OFFERS_UPDATE_ONE,
} from 'state/ManagerDashboard/ShiftOffers/actions';
import {
  BOX_SHIFT_SWAPS_SWAP_DELETED,
  BOX_SHIFT_SWAPS_SWAP_UPDATED,
} from 'state/ManagerDashboard/ShiftSwaps';
import {
  BOX_MANAGER_DASHBOARD_SHIFT_CHANGED,
  BOX_MANAGER_DASHBOARD_SHIFT_DELETED,
  BOX_MANAGER_DASHBOARD_TIMESHEET_CHANGED,
  BOX_MANAGER_DASHBOARD_TIMESHEET_DELETED,
} from 'state/ManagerDashboard/ManagerDashboard';
import {
  BOX_MANAGER_EVENTS_DELETED,
  BOX_MANAGER_EVENTS_SHOW_UPDATE_NOTIFICATION,
} from 'state/ManagerDashboard/Events';
import {
  BOX_MANAGER_TIMEOFF_CHANGED,
  BOX_MANAGER_TIMEOFF_DELETED,
  DeleteTimeOffPayload,
} from 'state/ManagerDashboard/Unavailability';

export function* makeManagerChannel(ws: WS) {
  const user_id = yield select(getCurrentUserId);

  return eventChannel((emit) => {
    const channelId = `manager_dashboard.${user_id}`;

    ws.private(channelId)
      .listen(
        '.shift_trade.shift_offer.manager.changed',
        (data: ServerShiftOfferWithProposals) => {
          emit(
            BOX_SHIFT_OFFERS_UPDATE_ONE(updateShiftOfferWithProposals(data))
          );
        }
      )
      .listen(
        '.shift_trade.shift_offer.manager.deleted',
        (data: DeleteInstancePayload) => {
          emit(BOX_SHIFT_OFFERS_DELETE_ONE(data));
        }
      )
      .listen('.shift_trade.shift_swap.manager.changed', (data: ShiftSwap) => {
        emit(BOX_SHIFT_SWAPS_SWAP_UPDATED(data));
      })
      .listen(
        '.shift_trade.shift_swap.manager.deleted',
        (data: DeleteInstancePayload) => {
          emit(BOX_SHIFT_SWAPS_SWAP_DELETED(data));
        }
      )
      .listen('.rostered_shift.changed', (data: any) => {
        emit(
          BOX_MANAGER_DASHBOARD_SHIFT_CHANGED(
            updateWorkingShift(data.rostered_shift)
          )
        );
      })
      .listen('.rostered_shift.deleted', (data: DeleteInstancePayload) => {
        emit(BOX_MANAGER_DASHBOARD_SHIFT_DELETED(data));
      })
      .listen('.timesheet.changed', (data: any) => {
        const { timesheet, rostered_shift } = data;

        let payload: DashboardShift;
        if (window.location.href.indexOf('timesheets') !== -1) {
          payload = updateTimeSheet(timesheet);
        } else {
          payload = updateTimeSheet({
            ...timesheet,
            timesheet_notes: timesheet.notes,
            notes: rostered_shift ? rostered_shift.notes : null,
          });
        }

        emit(BOX_MANAGER_DASHBOARD_TIMESHEET_CHANGED(payload));
      })
      .listen('.timesheet.deleted', (data: DeleteInstancePayload) => {
        emit(BOX_MANAGER_DASHBOARD_TIMESHEET_DELETED(data));
      })
      .listen('.forecast_event.changed', (data: ServerEvent) => {
        emit(BOX_MANAGER_EVENTS_SHOW_UPDATE_NOTIFICATION(updateEvent(data)));
      })
      .listen('.forecast_event.deleted', (data: DeleteInstancePayload) => {
        emit(BOX_MANAGER_EVENTS_DELETED(data.id));
      })
      .listen('.time_off.changed', (data: ServerTimeOff) => {
        emit(BOX_MANAGER_TIMEOFF_CHANGED(updateTimeOff(data)));
      })
      .listen('.time_off.deleted', (data: DeleteTimeOffPayload) => {
        emit(BOX_MANAGER_TIMEOFF_DELETED(data));
      });

    return () => {
      ws.leave(channelId);
    };
  });
}
