import React from 'react';
import _ from 'lodash';
import { TextFieldProps } from 'extended-oxygen-elements';
import { SafeDictionary } from 'ts-essentials';

export type UseNumberInputProps = {
  value: number | null;
  onValueChange: (newValue: number | null) => void;
  precision?: number;
  canBeNegative?: boolean;
  step?: number;
} & Pick<TextFieldProps, 'onChange' | 'onKeyDown'>;

const FLOAT_PART_REGEX = /(\.\d*)$/g;

// TODO refactor
export function useNumberInput({
  value,
  onValueChange,
  precision = 0,
  canBeNegative = false,
  step = 1,
  onKeyDown,
  onChange,
}: UseNumberInputProps) {
  const { floatPrefix, updateFloatPrefix } = useFloatPrefix(precision);
  const inputValue = makeInputValue(value, floatPrefix);
  const floor = makeFloor(precision);
  const checkNegative = makeCheckNegative(canBeNegative);


  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const numericValue = value || 0;

    const handlers: SafeDictionary<() => number> = {
      ArrowUp: () => numericValue + step,
      ArrowDown: () => numericValue - step,
    };

    const handler = handlers[event.key];

    if (handler) {
      const newValue = checkNegative(floor(handler()));
      if (_.isNumber(newValue)) {
        updateFloatPrefix(newValue);
        onValueChange(newValue);
      }
    }

    onKeyDown?.(event);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value: newValue },
    } = event;
    const updatedValue = checkNegative(floor(parse(newValue))) ?? null;

    updateFloatPrefix(updatedValue, newValue);

    if (value !== updatedValue) {
      onValueChange(updatedValue);
    }

    onChange?.(event);
  };

  return {
    value: inputValue,
    onKeyDown: handleKeyDown,
    onChange: handleChange,
  };
}

function useFloatPrefix(precision: number) {
  const [floatPrefix, setFloatPrefix] = React.useState('');

  const updateFloatPrefix = (updatedValue: number | null, newValue: string = '') => {
    const found = newValue.match(FLOAT_PART_REGEX);

    if (found) {
      const cropped = found[0].substr(0, precision + 1);
      setFloatPrefix(cropped);
    } else if (floatPrefix) {
      setFloatPrefix('');
    }

    if (isFloat(updatedValue)) {
      setFloatPrefix('');
    }
  }

  return {
    floatPrefix,
    updateFloatPrefix
  }
}


function makeInputValue(value: number | null, floatPrefix: string) {
  if (!_.isNumber(value)) {
    return '';
  }

  if (floatPrefix) {
    return `${value}${floatPrefix}`;
  }

  return value + '';
}

function makeFloor(precision: number) {
  return (value: number | undefined) => {
    if (_.isNumber(value)) {
      return _.floor(value, precision);
    }
  };
}

function makeCheckNegative(canBeNegative: boolean) {
  return (value: number | undefined) => {
    if (_.isNumber(value)) {
      if (value < 0) {
        if (canBeNegative) {
          return value;
        }
      } else {
        return value;
      }
    }
  };
}

function parse(value: string) {
  const parsedValue = parseFloat(value);
  if (!_.isNaN(parsedValue)) {
    return parsedValue;
  }
}

function isFloat(n: any) {
  return Number(n) === n && n % 1 !== 0;
}
