import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { times } from 'lodash';
import { weekDaysNumberSelector } from 'state/Roster/RosterWeekViewUserView';
import { UnassignedShiftCell } from './UnassignedShiftCell';

type Props = {
  rowNumber: number;
};

export const UnassignedShiftRow = memo(function UnassignedShiftRow(
  props: Props
) {
  const weekDaysNumber = useSelector(weekDaysNumberSelector);
  return (
    <div className={`week-rosters-grid__group week-rosters-grid__group--roster cell-height-v2`}>
      {times(weekDaysNumber, (weekDayIndex) => (
        <UnassignedShiftCell
          key={weekDayIndex}
          {...props}
          weekDayIndex={weekDayIndex}
        />
      ))}
    </div>
  );
});
