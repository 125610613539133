import React, { memo, MouseEvent, useEffect, useState } from 'react';
import {
  DelayedClickDiv,
  RosterShiftTimeRangeInput,
  UnavailabilityCard,
} from '../../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { getIsBulkDeleteOpened } from 'state/Roster/BulkActions/selectors';
import { BOX_SHIFT_TIME_RANGE_INPUT_CLOSED } from 'state/Roster/RangeInput/ShiftTimeRangeInput';
import { getWeekDays } from 'state/Roster/RosterWeekView';
import { getCompareDraggableShift, getSiteId } from 'state/RosteredShifts';
import { ContextualMenuBlock } from '../../ContextualMenuBlock';
import {
  addFutureClassName,
  dropDenied,
  getRowId,
  handleDragEnd,
  removeAllPlaceholders,
  removeDragOverClassName,
  removeDropPlaceholders,
  validateByAvailableAreaRoles,
  validateByAvailableRoles,
} from '../../../../../helpers';
import {
  BOX_ROSTER_RANGE_INPUT_OPEN,
  currentTimeBySiteTimezoneSelector,
  getIsApprovalModeEnabled,
} from 'state/Roster/Roster';
import { BOX_ROSTER_SHIFT_MODAL_GET_BY_ID } from 'state/Roster/RosterShiftModal';
import {
  areasBySiteIdSelector,
  getAreas,
  getRoles,
  getSites,
} from 'state/AccountTree';
import moment from 'moment-timezone';
import { hasPermissionSelector } from 'state/Auth';
import { DraggableShiftCard } from '../../DraggableShiftCard';
import { CopyMovePlaceholder } from '../../CopyMovePlaceholder';
import { RosteredShift } from 'type';
import {
  BOX_ROSTERED_SHIFT_BULK_ON_HOLD_RESIZER,
  BOX_ROSTERED_SHIFT_DRAG_N_DROP,
  BOX_ROSTERED_SHIFT_SET_DRAGGABLE_SHIFT_BY_ID,
  BOX_ROSTERED_SHIFT_SET_STRETCHING_SHIFT_BY_ID,
} from 'state/RosteredShifts/actions';
import { getUserListResponse } from 'state/UsersCollection';
import { CopyShift } from '../../CopyShift';
import { BulkCreateActionData } from 'state/RosteredShifts/types';
import {
  initResizer,
  enableResizers,
  getUserViewParams,
  restoreHiddenEls,
  getValidDate,
  styleDragEl,
  hideCardOnCopy,
  setDragPlaceholder,
  getSiteViewParams,
  unsetCopyShift,
} from '../helpers';

import { Props } from '../types';
import { useCanShowModal } from '../hooks/useCanShowModal';

export const WeekCellBlock = memo(function WeekCellBlock(props: Props) {
  const {
    weekDayIndex,
    userId,
    rowNumber,
    areaId,
    roleId,
    shiftType,
    menuType,
    rosteredShiftId,
    viewType,
    leaveId,
    timeOffIds,
  } = props;

  const isBulkDelete = useSelector(getIsBulkDeleteOpened);
  const isApproveMode = useSelector(getIsApprovalModeEnabled);
  const weekDays = useSelector(getWeekDays);
  const siteId = useSelector(getSiteId);

  const dispatch = useDispatch();
  const sites = useSelector(getSites);
  const users = useSelector(getUserListResponse);
  const areas = useSelector(getAreas);
  const roles = useSelector(getRoles);
  const areasBySideId = useSelector(areasBySiteIdSelector);
  const dropInProgress = useSelector((state) =>
    getCompareDraggableShift(state, rosteredShiftId)
  );
  const canEditRosteredShift = useSelector((state) =>
    hasPermissionSelector(state, 'roster.rosteredshift.edit')
  );
  const currentTimeBySiteTimezone = useSelector(
    currentTimeBySiteTimezoneSelector
  );
  const canShowModal = useCanShowModal({
    type: 'rostered_shift',
    userId,
    areaId,
    roleId,
  });

  let draggableShift = (window as any).draggableShift;
  let dragEl: any = null;
  let dropZone: any = null;
  let dragPlaceholder: any = null;
  let isHoldResizer = false;

  const [hideResizer, toggleResizer] = useState(false);

  useEffect(() => {
    if (!dropInProgress && rosteredShiftId) {
      restoreHiddenEls();
      unsetCopyShift();
    }
  }, [dropInProgress, rosteredShiftId]);

  const toggleBodyClass = (value: boolean) => {
    if (value) {
      document.querySelector('body')!.classList.add('drag-in-progress');
    } else {
      document.querySelector('body')!.classList.remove('drag-in-progress');
    }
  };

  const setId = () => {
    return getRowId('roster-' + weekDayIndex, {
      viewType: viewType,
      user_id: userId,
      area_id: areaId,
      role_id: roleId,
      rowIndex: rowNumber,
    });
  };

  const rowId = setId();

  const getRangeInputPayload = () => {
    return {
      dayTimestamp: weekDays[weekDayIndex],
      type: 'rosteredShift' as any,
      rowIndex: rowNumber,
      user_id: userId,
      site_id: siteId,
      area_id: areaId,
      role_id: roleId,
    };
  };

  const isArchivedUser = () => {
    return userId && users[userId] && !users[userId].is_active;
  };

  const openTimeRangeInput = (target: MouseEvent<HTMLDivElement>) => {
    if (!target || isArchivedUser()) {
      return;
    }
    if (
      !(target as any).target.classList ||
      !(target as any).target.classList.contains('week-rosters-grid__cell')
    ) {
      return;
    }
    dispatch(BOX_ROSTER_RANGE_INPUT_OPEN(getRangeInputPayload()));
  };

  const restoreBackground = (e: any) => {
    const nonPointerElems = document.querySelectorAll('.no-pointer-events');
    nonPointerElems.forEach((element) => {
      (element as HTMLDivElement).style.removeProperty('background-size');
      (element as HTMLDivElement).style.removeProperty('background-color');
      element.classList.remove('no-pointer-events');
    });
    dropZone = null;
    document.removeEventListener('mousemove', restoreBackground);
  };

  const restorePointerEvents = () => {
    setTimeout(() => {
      document.addEventListener('mousemove', restoreBackground);
      enableResizers();
    }, 100);
  };

  const cellRelatedProps = () => {
    const timeZoneId = sites[siteId].timezone_id;
    return {
      id: '',
      start: moment(+weekDays[weekDayIndex]).tz(timeZoneId),
      end: moment(+weekDays[weekDayIndex]).tz(timeZoneId),
      user_id: userId,
      area_id: areaId ? areaId : '',
      role_id: roleId ? roleId : '',
    };
  };

  const getDragNDropAttrs = () => {
    return {
      'data-day-num': weekDayIndex,
      'data-day': weekDays[weekDayIndex],
      'data-user-id': userId ? userId : 'null',
      'data-area-id': areaId,
      'data-role-id': roleId,
    };
  };

  const onDoubleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    dispatch(BOX_SHIFT_TIME_RANGE_INPUT_CLOSED());

    if (
      isApproveMode ||
      (window as any).inlineIsLoading ||
      !canEditRosteredShift
    ) {
      return false;
    }

    if (!canShowModal || !canEditRosteredShift) {
      return false;
    }

    dispatch(BOX_ROSTER_SHIFT_MODAL_GET_BY_ID(cellRelatedProps()));
  };

  const updateShiftToDrop = (
    dropCell: HTMLDivElement,
    draggableShift: Partial<RosteredShift>,
    action: 'move' | 'copy'
  ) => {
    const { dataset } = dropCell;
    const { start, end } = draggableShift;
    const { startDate, endDate } = getValidDate(
      start!,
      end!,
      dataset,
      sites[siteId].timezone_id
    );

    hideCardOnCopy(action);

    const params: Partial<RosteredShift> =
      props.viewType === 'user'
        ? getUserViewParams(
            draggableShift!,
            dataset,
            startDate,
            endDate,
            siteId,
            action
          )
        : getSiteViewParams(
            draggableShift!,
            dataset,
            startDate,
            endDate,
            siteId,
            action
          );

    styleDragEl(dropCell);

    dispatch(
      BOX_ROSTERED_SHIFT_DRAG_N_DROP({
        ...params,
        action,
      })
    );

    removeDropPlaceholders();
    removeAllPlaceholders();
  };

  const validateByUser = (userId: string) => {
    const userID = userId === 'null' ? null : userId;
    return userID === draggableShift!.user_id || userID === null
      ? true
      : validateByAvailableRoles(userID, {
          areas,
          roles,
          draggableShift,
          areasBySideId,
          siteId,
          users,
        });
  };

  const validateByAreaRole = (
    areaId: string | undefined,
    roleId: string | undefined
  ) => {
    const { user_id } = draggableShift!;
    if (!areaId || !roleId) {
      return false;
    }
    return validateByAvailableAreaRoles(user_id, areaId, roleId, {
      roles,
      areas,
      areasBySideId,
      users,
      siteId,
      draggableShift,
    });
  };

  const isValidDropCell = (dataset: DOMStringMap) => {
    const { userId, areaId, roleId } = dataset;
    return userId && !areaId && !roleId
      ? validateByUser(userId)
      : validateByAreaRole(areaId, roleId);
  };

  const addDragOverClassName = (e: React.DragEvent<HTMLDivElement>) => {
    const { currentTarget } = e;
    if (currentTarget && draggableShift) {
      currentTarget.classList.add('drag-over-card');
      if (!isValidDropCell(currentTarget.dataset)) {
        currentTarget.classList.add('drop-denied');
        removeAllPlaceholders();
      }
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    addDragOverClassName(e);
  };

  const onDragEnter = (e: any) => {
    const { currentTarget } = e;
    draggableShift = (window as any).draggableShift;
    if (currentTarget) {
      removeAllPlaceholders();
      currentTarget.classList.add('drag-over-split-placeholder');
    }
  };

  const handleOnDrop = (
    e: React.DragEvent<HTMLDivElement>,
    action: 'move' | 'copy'
  ) => {
    e.preventDefault();
    e.stopPropagation();

    const { currentTarget } = e;
    removeAllPlaceholders();
    toggleBodyClass(false);

    if (dropDenied(currentTarget, draggableShift) || !draggableShift) {
      return false;
    }
    // remove hover above the drop cell
    if (dropZone) {
      (dropZone as HTMLDivElement).style.backgroundSize = '0';
    }

    updateShiftToDrop(currentTarget, draggableShift, action);
    toggleResizer(false);
  };

  const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if (isHoldResizer || isBulkDelete) {
      return false;
    }

    dragEl = e.currentTarget;
    toggleBodyClass(true);
    setTimeout(() => {
      if (dragEl) {
        dragEl.classList.add('hidden-card');
        dropZone = dragEl.closest('.roster-focusable');
        if (dropZone) {
          dropZone.classList.add('no-pointer-events');
        }
      }
    }, 0);

    if (rosteredShiftId) {
      dispatch(
        BOX_ROSTERED_SHIFT_SET_DRAGGABLE_SHIFT_BY_ID({
          id: rosteredShiftId,
        })
      );
    }

    dragPlaceholder = setDragPlaceholder(e.currentTarget);
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setDragImage(dragPlaceholder, 0, 0);

    toggleResizer(true);
  };

  const sendBulkCreate = (params: Partial<BulkCreateActionData>) => {
    if (rosteredShiftId) {
      dispatch(
        BOX_ROSTERED_SHIFT_BULK_ON_HOLD_RESIZER({
          ...params,
          id: rosteredShiftId,
        } as BulkCreateActionData)
      );
    }
  };

  const setStretchingShift = () => {
    if (rosteredShiftId) {
      dispatch(
        BOX_ROSTERED_SHIFT_SET_STRETCHING_SHIFT_BY_ID({
          id: rosteredShiftId,
        })
      );
    }
  };

  const setCopyShift = (e: any) => {
    if (isBulkDelete || isApproveMode || !rosteredShiftId) {
      return;
    }
    if (!isHoldResizer && !e.currentTarget.classList.contains('show-resizer')) {
      e.preventDefault();
      e.stopPropagation();
      e.currentTarget.classList.add('show-resizer');
      const el = e.currentTarget.querySelector('.resizable');
      if (el) {
        el.setAttribute('id', 'resizer');
        initResizer(el, sendBulkCreate, isHoldResizer, setStretchingShift);
      }
    }
  };

  const onDragEnd = () => {
    handleDragEnd(dropInProgress);
    dragEl = null;
    toggleResizer(false);

    restorePointerEvents();
    toggleBodyClass(false);
  };

  const cellClassName = () => {
    return addFutureClassName(
      currentTimeBySiteTimezone,
      moment(weekDays[weekDayIndex], 'x')
    );
  };

  const getNonShiftCellComponents = () => {
    return (
      <>
        {!isArchivedUser() && (
          <ContextualMenuBlock
            id={rowId}
            cellData={cellRelatedProps()}
            shiftType={shiftType}
            menuType={menuType}
          />
        )}
        {timeOffIds || leaveId ? (
          <UnavailabilityCard
            leaveId={leaveId}
            timeOffIds={timeOffIds}
            day={weekDays[weekDayIndex]}
          />
        ) : (
          <RosterShiftTimeRangeInput {...getRangeInputPayload()} />
        )}
      </>
    );
  };

  const getShiftCellComponents = (rosteredShiftId: string) => {
    return (
      <>
        {!hideResizer && <CopyShift dayIndex={weekDayIndex} />}
        <DraggableShiftCard
          rosteredShiftId={rosteredShiftId}
          canEditRosteredShift={canEditRosteredShift}
          onDragStart={onDragStart}
          restorePointerEvents={restorePointerEvents}
          type={viewType}
        />
      </>
    );
  };

  const disabledClassName = () => {
    return isArchivedUser() ? 'is-disabled' : '';
  };

  return (
    <>
      <DelayedClickDiv
        {...getDragNDropAttrs()}
        onClick={openTimeRangeInput}
        id={rowId}
        onDoubleClick={onDoubleClick}
        className={
          'week-rosters-grid__cell droppable-wrapper roster-empty-cell' +
          cellClassName() +
          ' ' +
          disabledClassName()
        }
        onDragEnter={onDragEnter}
        onDragOver={handleDragOver}
        onDrop={(e) => handleOnDrop(e, 'move')}
        onDragLeave={removeDragOverClassName}
        onDragEnd={onDragEnd}
        onMouseEnter={setCopyShift}
        onMouseLeave={(e) => {
          if (!isHoldResizer) {
            unsetCopyShift();
          }
        }}
      >
        {!!rosteredShiftId
          ? getShiftCellComponents(rosteredShiftId)
          : getNonShiftCellComponents()}
      </DelayedClickDiv>
      <CopyMovePlaceholder
        rowId={rowId}
        dragAndDropAttrs={getDragNDropAttrs()}
        handleOnDrop={handleOnDrop}
        handleDragOver={handleDragOver}
        handleDragEnd={onDragEnd}
      />
    </>
  );
});
