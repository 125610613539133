import * as React from 'react';
import { orderBy } from 'natural-orderby';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import {
  BOX_ACCOUNTS_LOGIN_REQUEST,
  BOX_ACCOUNTS_REQUEST,
  foundAccountsSelector,
  getAccountsErrors,
  getCurrentAccountId,
  getSearchQuery,
} from 'state/Accounts';
import { Heading, ListTable } from 'elmo-elements';
import { ExitToAppOutlinedIcon, SimpleBadge } from 'element';
import { ListTableAction } from 'elmo-elements/ListTable/type';
import { getDateTimeFormatted } from 'lib/helpers';
import { DispatchProps, Props, State, StateProps } from './type';
import { findKey } from 'lodash';
import { Account } from 'state/Accounts/types';
import { getDateFormat, getTimeFormat } from 'state/Account';
import { PAGE_SIZE } from 'lib/config';
import { Collection, SortDirection } from '../../../../type';
import moment from 'moment';
import { pageCombiner } from 'state/combiners';
import oxygenTheme from 'lib/styles/oxygenTheme';

type SortableFields = keyof Pick<Account, 'name' | 'domain' | 'created_at'>;
const sortColumns: Collection<SortableFields, number> = {
  name: 0,
  domain: 1,
  created_at: 2,
};

export class AccountsListTableComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      pageSize: PAGE_SIZE[0],
      currentPage: 1,
      sortDirection: 'asc',
      sortColumn: 0,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.searchQuery !== prevProps.searchQuery) {
      this.setState({
        currentPage: 1,
      });
    }
  }

  onPageChange = (pageNumber: number) => {
    this.setState({
      currentPage: pageNumber,
    });
  };

  onPageSizeChange = (pageSize: number) => {
    this.setState({
      pageSize: pageSize,
      currentPage: 1,
    });
  };

  onSort = (column: number, direction: string) => {
    this.setState({
      sortDirection: direction as SortDirection,
      sortColumn: column,
    });
  };

  private get sortedData() {
    const { sortDirection, sortColumn } = this.state;

    const sortField = findKey(
      sortColumns,
      (columnIndex) => columnIndex === sortColumn
    );

    let sortedData;
    switch (sortField) {
      case 'name':
      case 'domain':
        sortedData = orderBy(
          this.props.accounts,
          [(account) => account.name],
          [sortDirection]
        );
        break;
      case 'created_at':
        sortedData = orderBy(
          this.props.accounts,
          [(account) => +moment.parseZone(account.created_at)],
          [sortDirection]
        );
        break;
      default:
        throw new Error('invalid sort keys config');
    }

    return sortedData;
  }

  private get page() {
    const {
      sortedData,
      state: { pageSize, currentPage },
    } = this;

    return pageCombiner(sortedData, {
      currentPage,
      pageSize,
    });
  }

  render() {
    return (
      <>
        {this.page.length ? (
          <>
            <div className="account-list-table">
              <ListTable
                onSort={this.onSort}
                sortDirection={this.state.sortDirection}
                sortColumn={this.state.sortColumn}
                ariaLabel="List of accounts"
                actions={this.getActions}
              >
                <ListTable.Header>
                  <ListTable.Column label={'Name'} sortable={true} />
                  <ListTable.Column label={'Domain'} sortable={true} />
                  <ListTable.Column label={'Start date'} sortable={true} />
                  <ListTable.Column label="Status" />
                  <ListTable.Column label="Active users" />
                </ListTable.Header>
                <ListTable.Body>
                  {this.page.map((data: Account, index: number) => {
                    return (
                      <ListTable.Tr key={index} actions={this.getActions(data)}>
                        <ListTable.Td>{data.name}</ListTable.Td>
                        <ListTable.Td>{data.domain}</ListTable.Td>
                        <ListTable.Td>{this.renderDate(data)}</ListTable.Td>
                        <ListTable.Td>{this.renderStatus(data)}</ListTable.Td>
                        <ListTable.Td>{data.users_count}</ListTable.Td>
                      </ListTable.Tr>
                    );
                  })}
                </ListTable.Body>
              </ListTable>
              <ListTable.Pagination
                pageSize={this.state.pageSize}
                currentPage={this.state.currentPage}
                onPageChange={this.onPageChange}
                onPageSizeChange={this.onPageSizeChange}
                totalResults={this.sortedData.length}
              />
            </div>
          </>
        ) : (
          <Heading id="no-search-results-text">
            Your search did not match any account names.
          </Heading>
        )}
      </>
    );
  }

  renderDate = (account: Account) => {
    return getDateTimeFormatted(
      this.props.dateFormat,
      this.props.timeFormat,
      account.created_at,
      true
    );
  };

  renderStatus = (account: Account) =>
    this.statusBadge[account.is_active ? 'active' : 'archived'];

  getActions = (account: Account): ListTableAction[] | undefined => {
    if (this.props.currentAccountId === account.id) {
      return;
    }

    return [
      {
        icon: <ExitToAppOutlinedIcon />,
        label: `Login`,
        onClick: () => {
          this.props.login(account);
        },
      },
    ];
  };

  get statusBadge() {
    return {
      active: <SimpleBadge label={'Active'} type={'info'} />,
      archived: <SimpleBadge label={'Archived'} type={'grey'} />,
    };
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  errors: getAccountsErrors(state),
  searchQuery: getSearchQuery(state),
  accounts: foundAccountsSelector(state),
  currentAccountId: getCurrentAccountId(state),
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
});

const mapDispatchToProps: DispatchProps = {
  getAccounts: BOX_ACCOUNTS_REQUEST,
  login: BOX_ACCOUNTS_LOGIN_REQUEST,
};

export const AccountsListTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountsListTableComponent);
