import { PublishArea } from '../types';

export const getSelectedAreasNumber = (areas: PublishArea) => {
  let selectedAreasNumber = Object.keys(areas).length;
  Object.keys(areas).forEach((areaId) => {
    if (!areas[areaId]) {
      selectedAreasNumber -= 1;
    }
  });
  return selectedAreasNumber;
};
