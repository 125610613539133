import { StoreState } from '../types';
import { Account } from './types';
import { createSelector } from 'reselect';
import { compareValueWithSearchQuery } from '../helpers';
import { filter } from 'lodash';

const getState = (state: StoreState) => state.accounts;

export const getIsUpdating = (state: StoreState): boolean =>
  getState(state).isUpdating;
export const getSearch = (state: StoreState) => getState(state).search;
export const getSearchQuery = (state: StoreState): string =>
  getState(state).search.query;
export const getAccounts = (state: StoreState): Account[] =>
  getState(state).accounts;
export const getCurrentAccountId = (state: StoreState) =>
  state.account.account.id;
export const getAccountsErrors = (state: StoreState) => getState(state).errors;

export const foundAccountsSelector = createSelector(
  getAccounts,
  getSearchQuery,
  (accounts: Account[], searchQuery: string) =>
    filter(accounts, (account: Account) =>
      compareValueWithSearchQuery(account.name, searchQuery)
    )
);
