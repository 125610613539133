import React from 'react';
import { Merge } from 'ts-essentials';
import { DefaultDivProps } from 'type';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';

type Props = Merge<
  DefaultDivProps,
  {
    onClick: ((event: React.MouseEvent<HTMLDivElement>) => void) | undefined;
    isVisible?: boolean;
  }
>;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'absolute',
      left: 0,
      right: 0,
      zIndex: 1,
      backgroundColor: theme.palette.common.white,
      textAlign: 'center',
      boxShadow: theme.shadows[7],
      borderRadius: '0 0 2px 2px',
      fontSize: theme.typography.caption.fontSize,
      lineHeight: 18 / 12,
      letterSpacing: '0.5px',
      color: theme.palette.text.primary,
      opacity: 0,
      visibility: 'hidden',
      transition: theme.transitions.create([
        'opacity',
        'visibility',
        'backgroundColor',
      ]),
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
      },
      bottom: '-19px',
    },
    visible: {
      opacity: 1,
      visibility: 'visible'
    }
  })
);

export const TimeOffCardPopperAdd = React.memo(function PopperAdd({
  onClick,
  isVisible,
  className,
  ...restDivProps
}: Props) {
  const classes = useStyles();
  if (!onClick) {
    return null;
  }
  return (
    <div
      {...restDivProps}
      onClick={onClick}
      className={clsx(classes.root, isVisible && classes.visible, className)}
    >
      + Add shift
    </div>
  );
});
