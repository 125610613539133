import { AlertErrorBoxLayout, ErrorBox } from 'element';
import _ from 'lodash';
import { SettingsLayoutComponent } from 'page/Settings/components';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Col, FormItem, Row } from '../../../../elmo-elements';
import {
  BOX_ACCOUNT_CLEAR_ERRORS,
  BOX_ACCOUNT_GEOFENCING_UPDATE_REQUEST,
  getAccountErrors,
  getIsUpdating,
  getRequestGPSLocation,
} from '../../../../state/Account';
import { AccountGeoFencingSettings } from '../../../../state/Account/types';
import { activeSitesSelector } from '../../../../state/AccountTree';
import { AccountTreeSite } from '../../../../type';
import LocationCard from './components/LocationCard';
import './styles.scss';

const emptyLocationDetailsMsg =
  'Please select the address and radius distance before saving Geo Fencing for selected location';
const radiusValidationMsg = 'Min radius value should be 50 mts';
let msg: string[] = [];

const GeoFencing = () => {
  const dispatch = useDispatch();
  const gpsLocationTracking = useSelector(getRequestGPSLocation);
  const isUpdating = useSelector(getIsUpdating);
  const [requestGps, setRequestGps] = useState(gpsLocationTracking);
  const sites = useSelector(activeSitesSelector);
  const [geoFencingSettings, setGeoFencingSettings] = useState(sites);
  const [isValid, setIsValid] = useState(true);
  const errors = useSelector(getAccountErrors);

  const setSiteGeoFencing = (id: string, options: any) => {
    setGeoFencingSettings((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        ...options,
      },
    }));
  };

  const onChangeRequestGps = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    setRequestGps(checked);
  };

  const isValidSettings = () => {
    let valid = true;
    msg = [];
    _.map(geoFencingSettings, (site: AccountTreeSite) => {
      if (site.geo_enabled) {
        if (
          !site.formatted_address ||
          !site.address_radius ||
          !site.address_lat ||
          !site.address_lng
        ) {
          valid = false;
          msg.push(emptyLocationDetailsMsg);
        }
        if (site.address_radius < 50) {
          valid = false;
          msg.push(radiusValidationMsg);
        }
      }
    });
    setIsValid(valid);
    return valid;
  };

  const getGeoSettings = () => {
    const settings: AccountGeoFencingSettings[] = [];

    _.map(geoFencingSettings, (site) => {
      settings.push({
        geo_enabled: site.geo_enabled,
        site_id: site.id,
        address_radius: site.address_radius,
        address_lng: site.address_lng,
        address_lat: site.address_lat,
        formatted_address: site.formatted_address,
      });
    });
    return settings;
  };

  const saveSettings = () => {
    setIsValid(true);
    if (isValidSettings()) {
      dispatch(
        BOX_ACCOUNT_GEOFENCING_UPDATE_REQUEST({
          timesheet_settings: {
            request_gps: requestGps,
          },
          geofencing_settings: getGeoSettings(),
        })
      );
    }
  };

  const findAddressByCoords = (id: string, options: any) => {
    if (typeof google !== 'undefined') {
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          location: options,
        },
        (results: any) => {
          if (
            typeof results !== 'undefined' &&
            results !== null &&
            results.length
          ) {
            setSiteGeoFencing(id, {
              formatted_address: results[0].formatted_address,
              address_lat: results[0].geometry.location.lat(),
              address_lng: results[0].geometry.location.lng(),
            });
          }
        }
      );
    }
  };

  return (
    <SettingsLayoutComponent
      loading={isUpdating}
      onSubmit={saveSettings}
      title="Geo Fencing Settings"
    >
      <FormItem>
        <Checkbox
          name="request_gps"
          id="request_gps"
          label="Request GPS location"
          ariaLabel="Request GPS location"
          isChecked={requestGps}
          onChange={onChangeRequestGps}
        />
      </FormItem>
      <AlertErrorBoxLayout
        errors={errors}
        onClose={() => dispatch(BOX_ACCOUNT_CLEAR_ERRORS())}
        BoxProps={{
          mb: 2,
        }}
      />
      {!isValid && (
        <ErrorBox
          errors={msg}
          type={'warning'}
          clearErrors={() => setIsValid(true)}
        />
      )}
      <Row>
        {_.map(geoFencingSettings, (site: AccountTreeSite, index: number) => (
          <Col key={index} className={'pb-5 pt-4 geofencing-list-item'}>
            <LocationCard
              site={site}
              isDisabled={!requestGps}
              setSettings={setSiteGeoFencing}
              findAddressByCoords={findAddressByCoords}
            />
          </Col>
        ))}
      </Row>
    </SettingsLayoutComponent>
  );
};

export default GeoFencing;
