import React from 'react';
import { Merge } from 'ts-essentials';
import { useNumberInput, UseNumberInputProps } from 'hooks';
import TableCellInput, { TableCellInputProps } from './TableCellInput';

export default function TableCellNumberInput(
  props: Merge<TableCellInputProps, UseNumberInputProps>
) {
  const inputProps = useNumberInput(props);
  const { onValueChange, ...restProps } = props;

  return <TableCellInput {...restProps} {...inputProps} />;
}
