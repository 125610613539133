import React from 'react';
import { useTheme } from 'extended-oxygen-elements';
import './RosterSiteViewHeading.scss';

type Props = {
  title: string;
  show: boolean;
};

const RosterSiteViewHeading = ({ title, show }: Props) => {
  const backgroundColor = useTheme().palette.background.default;

  return (
    <>
      {show ? (
        <div className="roster-site-view-heading">
          <div
            className="roster-site-view-heading__inner-wrapper"
            style={{ backgroundColor }}
          >
            <div className="roster-site-view-heading__title">{title}</div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default RosterSiteViewHeading;
