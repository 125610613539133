import { mapValues, omitBy } from 'lodash';
import { ApprovalData, UserFieldsManagers } from 'type';
import {
  Props,
  FormFields,
  State,
  EditApproversPayload,
  Placeholders
} from '../types';
import { DEFAULT, NOT_CHANGE } from '../constants';

export const getState = ({ managers }: Props): State => {
  let data: FormFields;

  if (managers) {
    data = mapValues<
      UserFieldsManagers,
      string | typeof DEFAULT | typeof NOT_CHANGE
    >(
      managers,
      (value: ApprovalData | null) => (value === null ? DEFAULT : value.id)
    );
  } else {
    data = {
      leave: NOT_CHANGE,
      shift_swap: NOT_CHANGE,
      shift_offer: NOT_CHANGE
    };
  }

  return {
    data,
    isValid: false
  };
};

export const getSubmitPayload = (data: FormFields): EditApproversPayload => {
  const withoutDefault = mapValues(
    data,
    value => (value === DEFAULT ? null : value)
  );
  return {
    managers: omitBy(withoutDefault, value => value === NOT_CHANGE)
  };
};

const getManagerName = (
  managers: UserFieldsManagers | undefined,
  managerType: keyof UserFieldsManagers
): string => {
  if (managers) {
    const manager: ApprovalData | null = managers[managerType];

    if (manager) {
      return manager.name;
    }
  }

  return '';
};

export const getPlaceholders = (
  managers: UserFieldsManagers | undefined
): Placeholders => ({
  leave: getManagerName(managers, 'leave'),
  shift_offer: getManagerName(managers, 'shift_offer'),
  shift_swap: getManagerName(managers, 'shift_swap')
});
