import React, { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';
import {
  BOX_COVID_TRACING_MODE_DOWNLOAD_REPORT_STATE,
  getIsPrinting,
  getPrintData
} from '../../../../../../../state/Roster/CovidTracing';
import './styles.scss';

export const PrintTable = () => {
  const dispatch = useDispatch();
  const isPrinting = useSelector(getIsPrinting);
  const printData = useSelector(getPrintData);
  const componentRef: any = useRef();

  const handlePrint = useReactToPrint({
    content: () => (componentRef as any).current,
    suppressErrors: true
  });

  useEffect(() => {
    if (isPrinting) {
      setTimeout(() => {
        if (handlePrint) {
          handlePrint()
        }
      }, 100);
      setTimeout(() => {
        dispatch(BOX_COVID_TRACING_MODE_DOWNLOAD_REPORT_STATE(false))
      }, 500);
    }
  }, [isPrinting]);

  return (
    <div className={'covid-print-report-wrapper'}>
      <div className="covid-printing-content" ref={componentRef}>
        <h3>COVID-19 contact tracing report</h3>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Mobile</th>
              <th>Start</th>
              <th>End</th>
            </tr>
          </thead>
          <tbody>
            {
              printData.map((data: any, index: number) => (
                <tr key={`key-${index}`}>
                  <td>{data.name}</td>
                  <td>{data.mobile || '-'}</td>
                  <td>{data.start}</td>
                  <td>{data.end}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
    );
}