import * as React from 'react';
import { connect } from 'react-redux';
import { FormItem, Input } from 'elmo-elements';
import ErrorBox from 'element/ErrorBox';
import { StoreState } from 'state/types';
import {
  BOX_EDIT_TEMPLATE_CLOSE_UPDATE_TEMPLATE_MODAL,
  BOX_EDIT_TEMPLATE_CONFIRM_UPDATE_TEMPLATE_MODAL,
  BOX_EDIT_TEMPLATE_UPDATE_CLEAR_ERRORS,
} from 'state/Roster/EditTemplate/actions';
import {
  getTemplateTitle,
  getUpdateTemplateModal,
} from 'state/Roster/EditTemplate/selectors';
import { ConfirmModalProps } from 'state/Roster/EditTemplate/types';
import { useEffect, useState } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';
import { PageDialog, PageDialogCancel, PageDialogSubmit } from 'element';

type OwnProps = {};

type DispatchProps = {
  closeModal: () => void;
  confirmUpdate: (name: string) => void;
  clearErrors: () => void;
};

type StateProps = {
  modal: ConfirmModalProps;
  title: string;
};

type Props = OwnProps & DispatchProps & StateProps;

export function UpdateTemplateModal(props: Props) {
  const [name, setName] = useState(props.title);

  useEffect(() => {
    setName(props.title);
  }, [props.modal.isOpened]);

  const {
    modal: { isOpened, isUpdating, errors },
    closeModal,
    confirmUpdate,
    clearErrors,
  } = props;

  return (
    <PageDialog
      maxWidth={'xs'}
      open={isOpened}
      id="update-template"
      onClose={closeModal}
    >
      <DialogTitle>Update template</DialogTitle>
      <DialogContent>
        {errors.length > 0 && (
          <ErrorBox errors={errors} clearErrors={clearErrors} />
        )}
        <FormItem label="Template name">
          <Input
            id="template-name"
            name="name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </FormItem>
      </DialogContent>
      <DialogActions>
        <PageDialogCancel onClick={closeModal}>Cancel</PageDialogCancel>
        <PageDialogSubmit
          id="save-template"
          onClick={() => {
            confirmUpdate(name);
          }}
          loading={isUpdating}
          disabled={!name.length}
        >
          Save
        </PageDialogSubmit>
      </DialogActions>
    </PageDialog>
  );
}

const mapToStateProps = (state: StoreState): StateProps => ({
  modal: getUpdateTemplateModal(state),
  title: getTemplateTitle(state),
});

const mapToDispatchProps: DispatchProps = {
  closeModal: BOX_EDIT_TEMPLATE_CLOSE_UPDATE_TEMPLATE_MODAL,
  confirmUpdate: BOX_EDIT_TEMPLATE_CONFIRM_UPDATE_TEMPLATE_MODAL,
  clearErrors: BOX_EDIT_TEMPLATE_UPDATE_CLEAR_ERRORS,
};

export default connect(
  mapToStateProps,
  mapToDispatchProps
)(UpdateTemplateModal);
