import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import { filtersSelector } from 'state/Roster/RosterFilters';
import { rosterDataByUserSelector } from 'state/Roster/RosterDayView';
import { UserRosterData } from 'state/Roster/RosterDayView/types';
import { DayDivider, DayForecastsAndEvents } from '../../components';
import { DayUserRow, DayUserUnassignedRow } from './components';

type OwnProps = {};

type StateProps = {
  userRosterDataArray: UserRosterData[];
  isRostersDataShown: boolean;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

const RosterDayViewUserViewView = ({
  isRostersDataShown,
  userRosterDataArray
}: Props) => (
  <DayDivider>
    <DayForecastsAndEvents />

    {isRostersDataShown && <DayUserUnassignedRow />}

    {userRosterDataArray.map(userRosterData => (
      <DayUserRow
        key={userRosterData.user.id}
        userRosterData={userRosterData}
      />
    ))}
  </DayDivider>
);

const mapStateToProps = (state: StoreState): StateProps => ({
  userRosterDataArray: rosterDataByUserSelector(state),
  isRostersDataShown: filtersSelector(state).roster
});

// const mapDispatchToProps: DispatchProps = {};

export default connect(mapStateToProps)(RosterDayViewUserViewView);
