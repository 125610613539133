import { createSelector } from 'reselect';
import { intersection } from 'lodash';
import { StringMap, Timesheet } from 'type';
import { StoreState } from 'state/types';
import { groupedByFieldCombinerCreator } from 'state/combiners';
import { checkNullFilter } from 'state/helpers';
import { timesheetsArraySelector } from 'state/TimesheetsCollection/selectors';
import { ROSTER_FILTER_WITHOUT } from '../constants';
import { shiftRoleFilter } from '../helpers';
import { getFiltersTags } from './simpleSelectors';
import { filtersSelector } from './common';
import { getSiteId } from '../../../RosteredShiftsCollection';

export const timesheetsFilteredByTags = createSelector(
  timesheetsArraySelector,
  getFiltersTags,
  checkNullFilter(
    (timesheets: Timesheet[], filteredTags: string[]): Timesheet[] => {
      const showWithoutTags: boolean = filteredTags.includes(
        ROSTER_FILTER_WITHOUT
      );
      return timesheets.filter(({ tags }: Timesheet): boolean => {
        if (!tags.length) {
          return showWithoutTags;
        }
        return !!intersection(filteredTags, tags).length;
      });
    }
  )
);

export const timesheetsFilteredSelector = createSelector<
  StoreState,
  ReturnType<typeof filtersSelector>,
  ReturnType<typeof timesheetsFilteredByTags>,
  ReturnType<typeof getSiteId>,
  ReturnType<typeof timesheetsFilteredByTags>
>(filtersSelector, timesheetsFilteredByTags, getSiteId, shiftRoleFilter);

export const filteredTimesheetsByUserIdSelector = createSelector<
  StoreState,
  Timesheet[],
  StringMap<Timesheet[]>
>(timesheetsFilteredSelector, groupedByFieldCombinerCreator('user_id'));
