import React from 'react';
import { Card, Divider, Paragraph } from 'elmo-elements';
import './style.scss';
import { EmptyMessage } from '../EmptyMessage';
import { connect } from 'react-redux';
import { getOverviewShifts } from 'state/EmployeeDashboard/Overview';
import { StoreState } from 'state/types';
import {
  AccountTreeArea,
  AccountTreeRole,
  AccountTreeSite,
  EmployeeRosteredShift,
  PreferencesTimeFormat,
  StringMap,
} from 'type';
import { getTimeFormatted } from 'lib/helpers';
import { getTimeFormat } from 'state/Account';
import { getAreas, getRoles, getSites } from 'state/AccountTree';
import { DSTIcon } from '../../../../../../element/DSTIcon';
import moment from 'moment';

type Props = {
  shifts: EmployeeRosteredShift[];
  areas: StringMap<AccountTreeArea>;
  roles: StringMap<AccountTreeRole>;
  sites: StringMap<AccountTreeSite>;
  timeFormat: PreferencesTimeFormat;
};

export class UpcomingShiftsComponent extends React.Component <Props > {

  render() {
    const {shifts} = this.props;
    return(
      <Card
        heading={<p>Upcoming Shifts</p>}
      >
        {shifts.length ? shifts.map( (shift, key) => {
          return <div key={key}>{this.renderShift(shift)}</div>
        }) : <div className={'empty-message'}><EmptyMessage /></div> }

      </Card>
    );
  }

  renderShift = (shift: EmployeeRosteredShift) => {
    const { timeFormat } = this.props;
    return <div className="upcoming-shift">
      <div className="upcoming-shift__date">
        <div className="upcoming-shift__container">
          <div className="upcoming-shift__day">{moment.parseZone(shift.start).format('DD')}</div>
          <div className="upcoming-shift__month">{moment.parseZone(shift.start).format('MMM')}</div>
        </div>
      </div>
      <div className="upcoming-shift__content">
        <Paragraph isBold={true}>
          {getTimeFormatted(timeFormat, shift.start)} - {getTimeFormatted(timeFormat, shift.end)}
          <DSTIcon isDST={shift.is_dst_intersect} offsetLeft={2} />
        </Paragraph>
        {!shift.is_accepted && <div className={'not-accepted'}>
          <Paragraph size="sm">(Not accepted yet)</Paragraph>
        </div>}
        <Paragraph size="sm" className="mb-4">{this.getLocation(shift)}</Paragraph>
        <div className="mb-4">
          <Divider id="upcoming-shift-divider" />
        </div>
      </div>
    </div>
  }

  getLocation = (roster: EmployeeRosteredShift) => {
    const { roles, areas, sites } = this.props;
    let response = '';

    if (roster.role_id && roles[roster.role_id]) {
      response = roles[roster.role_id].name;
    }

    if (roster.area_id && areas[roster.area_id]) {
      response += ` - ${areas[roster.area_id].name}`;
    }

    if (roster.site_id && sites[roster.site_id]) {
      response += `, ${sites[roster.site_id].name}`;
    }
    return response;
  };
}

const mapStateToProps = (state: StoreState) => ({
  shifts: getOverviewShifts(state),
  areas: getAreas(state),
  roles: getRoles(state),
  sites: getSites(state),
  timeFormat: getTimeFormat(state)
});

export const UpcomingShifts = connect(
  mapStateToProps
)(UpcomingShiftsComponent);
