import React from 'react';
import { useSelector } from 'react-redux';
import { Dictionary } from 'ts-essentials';
import { isFunction, mapValues } from 'lodash';
import { ExpectedFlag, expectedFlags, ExpectedFlagsKeys } from 'feature-flags';
import { getFlag } from 'state/Flags';

type ReactNodeOrFunction = React.ReactNode | ((isEnabled: boolean) => React.ReactNode);

export const FlagWrapper = mapValues(expectedFlags, (expectedFlag) =>
  makeFlagComponent(expectedFlag)
) as Dictionary<ReturnType<typeof makeFlagComponent>, ExpectedFlagsKeys>;


function makeFlagComponent(flagName: ExpectedFlag) {
  const FlagComponent = (props: { children: ReactNodeOrFunction }) => {
    // switched to selector to use single state everywhere
    const flagValue = useSelector(getFlag[flagName]);

    return (
      <BaseChildrenRenderer value={flagValue}>
        {props.children}
      </BaseChildrenRenderer>
    );
  };

  (FlagComponent as React.FunctionComponent).displayName = `Flag${flagName}`;

  return FlagComponent;
}

function BaseChildrenRenderer({
  children,
  value,
}: {
  children: ReactNodeOrFunction;
  value: boolean;
}) {
  if (isFunction(children)) {
    return <>{children(value)}</>;
  }

  if (!value) {
    return null;
  }

  return <>{children}</>;
}
