import { createAction } from 'lib/store-utils';
import { FormattedErrors, RosteredShift } from '../../../type';
import {
  CurrentRosteredShift,
  DeleteRosteredShiftPayload,
  GetRosterByIDPayload,
  GetShiftUsersPayload,
  UpdatePayload
} from './types';
import { RosteredShiftCreateRequest, UndoRequestPayload } from 'lib/Api';

export const BOX_ROSTER_SHIFT_MODAL_OPEN = createAction('Roster shift modal / open');
export const BOX_ROSTER_SHIFT_MODAL_OPEN_WITH_LOADER = createAction('Roster shift modal / open with Loader');
export const BOX_ROSTER_SHIFT_MODAL_CLOSE = createAction('Roster shift modal / close');
export const BOX_ROSTER_SHIFT_MODAL_GET_BY_ID = createAction<GetRosterByIDPayload>('Roster shift modal / get by id');
export const BOX_ROSTER_SHIFT_MODAL_GET_BY_ID_SUCCESS = createAction<RosteredShift>('Roster shift modal / get by id success');
export const BOX_ROSTER_SHIFT_MODAL_GET_BY_ID_FAILURE = createAction<FormattedErrors>('Roster shift modal / get by id failure');

export const BOX_ROSTER_SHIFT_MODAL_PASTE_DATA = createAction<Partial<CurrentRosteredShift>>('Roster shift modal / paste data inside modal');

export const BOX_ROSTER_SHIFT_MODAL_SET_SHIFT = createAction<GetRosterByIDPayload>('Roster shift modal / set current');
export const BOX_ROSTER_SHIFT_MODAL_UPDATE_SHIFT_PROPS = createAction<RosteredShift>('Roster shift modal / update current');

export const BOX_ROSTER_SHIFT_MODAL_CREATE_SHIFT = createAction<RosteredShiftCreateRequest>('Roster shift modal / create request');
export const BOX_ROSTER_SHIFT_MODAL_CREATE_SHIFT_SUCCESS = createAction('Roster shift modal / create success');
export const BOX_ROSTER_SHIFT_MODAL_CREATE_SHIFT_FAILURE = createAction<FormattedErrors>('Roster shift modal / create failure');

export const BOX_ROSTER_SHIFT_MODAL_UPDATE = createAction<UpdatePayload>('Roster shift modal / update request');
export const BOX_ROSTER_SHIFT_MODAL_UPDATE_SHIFT_SUCCESS = createAction('Roster shift modal / update success');
export const BOX_ROSTER_SHIFT_MODAL_UPDATE_SHIFT_FAILURE = createAction<FormattedErrors>('Roster shift modal / update failure');

// TODO replace with global confirmation functionality
export const BOX_ROSTER_SHIFT_MODAL_OVERLAP_CONFIRMATION_OPEN = createAction<FormattedErrors>('Roster shift modal / open confirmation modal');
export const BOX_ROSTER_SHIFT_MODAL_OVERLAP_CONFIRMATION_CLOSE = createAction<boolean>('Roster shift modal / close confirmation modal');
export const BOX_ROSTER_SHIFT_MODAL_CLEAR_ERRORS = createAction('Roster shift modal / clear all errors');

export const BOX_ROSTER_SHIFT_MODAL_DELETE_SHIFT = createAction<DeleteRosteredShiftPayload>('Roster shift modal / delete request');
export const BOX_ROSTER_SHIFT_MODAL_DELETE_SHIFT_SUCCESS = createAction('Roster shift modal / delete success');
export const BOX_ROSTER_SHIFT_MODAL_DELETE_SHIFT_FAILURE = createAction<FormattedErrors>('Roster shift modal / delete failure');

export const BOX_ROSTER_SHIFT_MODAL_GET_SHIFT_USERS = createAction<GetShiftUsersPayload>('Roster shift modal / get list of users request');
export const BOX_ROSTER_SHIFT_MODAL_GET_SHIFT_USERS_SUCCESS = createAction<any>('Roster shift modal / get list of users success');
export const BOX_ROSTER_SHIFT_MODAL_GET_SHIFT_USERS_FAILURE = createAction<FormattedErrors>('Roster shift modal / get list of users failure');

export const BOX_ROSTER_SHIFT_MODAL_UNDO_SHIFT = createAction<UndoRequestPayload>('Roster shift modal / send undo request');
export const BOX_ROSTER_SHIFT_MODAL_UNDO_SHIFT_SUCCESS = createAction('Roster shift modal / send undo success');
export const BOX_ROSTER_SHIFT_MODAL_UNDO_SHIFT_FAILURE = createAction<FormattedErrors>('Roster shift modal / send undo failure');

export const BOX_ROSTER_SHIFT_MODAL_GET_EVENTS = createAction<any>('Roster shift modal / get list of events');
export const BOX_ROSTER_SHIFT_MODAL_GET_EVENTS_SUCCESS = createAction<any>('Roster shift modal / get list of events success');
export const BOX_ROSTER_SHIFT_MODAL_GET_EVENTS_FAILURE = createAction<FormattedErrors>('Roster shift modal / get list of events failure');

export const BOX_ROSTER_SHIFT_MODAL_ACCEPT_SHIFT = createAction<string>('Roster shift modal / accept shift');
export const BOX_ROSTER_SHIFT_MODAL_ACCEPT_SHIFT_SUCCESS = createAction('Roster shift modal / accept shift success');
export const BOX_ROSTER_SHIFT_MODAL_ACCEPT_SHIFT_FAILURE = createAction<FormattedErrors>('Roster shift modal / accept shift failure');

export const BOX_ROSTER_SHIFT_MODAL_NOTIFY = createAction<string>('Roster shift modal / notify user');
export const BOX_ROSTER_SHIFT_MODAL_NOTIFY_SUCCESS = createAction('Roster shift modal / notify user success');
export const BOX_ROSTER_SHIFT_MODAL_NOTIFY_FAILURE = createAction<FormattedErrors>('Roster shift modal / notify user failure');

export const BOX_ROSTER_SHIFT_MODAL_OFFER_SHIFT = createAction<string>('Roster shift modal / offer shift');
export const BOX_ROSTER_SHIFT_MODAL_OFFER_SHIFT_SUCCESS = createAction('Roster shift modal / offer shift success');
export const BOX_ROSTER_SHIFT_MODAL_OFFER_SHIFT_FAILURE = createAction<FormattedErrors>('Roster shift modal / offer shift failure');

export const BOX_ROSTER_SHIFT_MODAL_SET_REACCEPT_VALUE = createAction<boolean>('Roster shift modal / set re-accept value');
