import { LoadingHandler } from 'state/Loading';
import { ErrorsHandler } from 'state/Errors';
import { StatusHandler } from 'state/Status';
import * as payElements from 'state/PayElementsCollection';
import * as actions from './actions';

/**
 * Fetching
 * ===
 * */
export const getPageFetching = LoadingHandler()
  .handleAsyncLoading(actions.BOX_PAY_ELEMENTS_PAGE_DATA_GET)
  .makeSelector();

export const getPageFetchErrors = ErrorsHandler()
  .handleAsyncErrors(actions.BOX_PAY_ELEMENTS_PAGE_DATA_GET)
  .makeSelector();

/**
 * Updating
 * ===
 * */
export const getModalUpdating = LoadingHandler()
  .handleAsyncLoading(payElements.BOX_PAY_ELEMENTS_PAY_ELEMENT_DELETE)
  .makeSelector();

export const getPayElementFormUpdating = LoadingHandler()
  .handleAsyncLoading(payElements.BOX_PAY_ELEMENTS_PAY_ELEMENT_CREATE)
  .handleAsyncLoading(payElements.BOX_PAY_ELEMENTS_PAY_ELEMENT_UPDATE)
  .makeSelector();

/**
 * Errors
 * ===
 * */
export const getPageErrors = ErrorsHandler()
  .clearErrors(actions.BOX_PAY_ELEMENTS_PAGE_ERRORS_CLOSED)
  .clearErrors(actions.BOX_PAY_ELEMENTS_PAGE_DIALOG_OPENED)
  .handleAsyncErrors(payElements.BOX_PAY_ELEMENTS_PAY_ELEMENT_CREATE)
  .handleAsyncErrors(payElements.BOX_PAY_ELEMENTS_PAY_ELEMENT_UPDATE)
  .makeSelector();

export const getModalErrors = ErrorsHandler()
  .clearErrors(actions.BOX_PAY_ELEMENTS_PAGE_DIALOG_ERRORS_CLOSED)
  .clearErrors(actions.BOX_PAY_ELEMENTS_PAGE_DIALOG_OPENED)
  .handleAsyncErrors(payElements.BOX_PAY_ELEMENTS_PAY_ELEMENT_DELETE)
  .makeSelector();

/**
 * Statuses
 * ===
 * */
export const getPayElementFormRequestStatus = StatusHandler()
  .handleAsyncStatus(payElements.BOX_PAY_ELEMENTS_PAY_ELEMENT_CREATE)
  .handleAsyncStatus(payElements.BOX_PAY_ELEMENTS_PAY_ELEMENT_UPDATE)
  .makeSelector();

export const getModalRequestStatus = StatusHandler()
  .handleAsyncStatus(payElements.BOX_PAY_ELEMENTS_PAY_ELEMENT_DELETE)
  .makeSelector();
