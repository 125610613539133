import React, {
  ChangeEventHandler,
  createRef,
  KeyboardEventHandler
} from 'react';
import { BaseProps } from './types';
import { InputWithIcon } from './InputWithIcon';

export type Props<Value> = BaseProps & {
  value: Value;
  valueComparator: (prevValue: Value, nextValue: Value) => boolean;
  valueFormatter: (value: Value) => string;
  onChange: (updatedValue: string) => void;
  removeHint?: () => void;
  onKeyDown?: (value: string) => void;
  submitOnEnter?: boolean;
};

export type State = {
  userInput: string;
  prevInput: string;
  isFocused: boolean;
};

const getState = <Value extends any>({
  value,
  valueFormatter
}: Props<Value>): State => {
  const formattedValue = valueFormatter(value);

  return {
    userInput: formattedValue,
    prevInput: formattedValue,
    isFocused: false
  };
};

export class TimeInputBase<Value> extends React.Component<Props<Value>, State> {
  readonly state = getState(this.props);
  private htmlInputRef = createRef<HTMLInputElement>();

  render() {
    let {
      placeholder,
      tabIndex = 0,
      isReadOnly,
      label,
      disabled,
      id,
      icon,
      onIconClick
    } = this.props;
    return (
      <InputWithIcon
        ref={this.htmlInputRef}
        id={id}
        value={this.value}
        placeholder={placeholder}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        tabIndex={tabIndex}
        isReadOnly={isReadOnly}
        ariaLabel={label}
        isDisabled={disabled}
        icon={icon}
        onIconClick={onIconClick}
        onClick={this.hideTooltip}
      />
    );
  }

  componentDidUpdate(
    prevProps: Readonly<Props<Value>>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    const { value, valueComparator, valueFormatter } = this.props;
    if (!valueComparator(prevProps.value, value)) {
      this.setState({
        userInput: valueFormatter(value)
      });
    }
  }

  focus() {
    const { current } = this.htmlInputRef;

    if (current) {
      current.focus();
    }
  }

  // getters
  private get value() {
    const {
      props: { value, valueFormatter },
      state: { isFocused, userInput }
    } = this;

    return isFocused ? userInput : valueFormatter(value);
  }

  hideTooltip = () => {
    const { removeHint } = this.props;
    if (removeHint) {
      removeHint();
    }
  }

  // handlers
  private handleFocus = () => {
    // this.hideTooltip();
    this.setState(
      prevState => ({
        isFocused: true,
        prevInput: prevState.userInput
      }),
      this.selectContent
    );
  };

  private formatTimeInInput = (isFocused = false) => {
    const {
      props: { onChange, value, valueFormatter },
      state: { userInput, prevInput }
    } = this;

    if (userInput !== prevInput) {
      onChange(userInput);
    }

    const valueFormatted = valueFormatter(value);
    this.setState({
      userInput: valueFormatted,
      prevInput: valueFormatted,
      isFocused: isFocused
    });
  };

  private handleBlur = () => {
    this.formatTimeInInput();
  };

  private handleChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { value }
  }) => {
    this.setState({
      userInput: value
    });
  };

  private handleKeyDown: KeyboardEventHandler<HTMLInputElement> = event => {
    const {onKeyDown, submitOnEnter} = this.props;
    if (event.key === 'Enter' && !submitOnEnter) {
      this.blurInput();
    }

    if (submitOnEnter && event.key === 'Enter') {
      this.formatTimeInInput(true);
    }

    if (typeof onKeyDown === 'function') {
      onKeyDown(event.currentTarget.value);
    }
  };

  // html input interactions
  private selectContent() {
    const { current } = this.htmlInputRef;

    if (current) {
      current.select();
    }
  }
  private blurInput() {
    const { current } = this.htmlInputRef;
    this.hideTooltip();
    if (current) {
      current.blur();
    }
  }
}
