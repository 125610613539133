import React from 'react';
import { ConnectActionCreatorPayload } from 'type';
import { BOX_SOCKETS_ROSTER } from 'state/Sockets';
import { useConnectActionMemoized } from 'hooks';

export type SocketRosterConnectionProps = ConnectActionCreatorPayload<
  typeof BOX_SOCKETS_ROSTER
>;

export default SocketRosterConnection;

export function SocketRosterConnection({
  site_id,
}: SocketRosterConnectionProps) {
  useConnectActionMemoized(BOX_SOCKETS_ROSTER, () => ({ site_id }), [site_id]);

  return null;
}
