import React from 'react';
import { Merge } from 'ts-essentials';
import { Minutes } from 'type';
import DurationField, { DurationFieldProps } from './DurationField';

export type DurationMinutesRequiredFieldProps = Merge<
  DurationFieldProps,
  { onValueChange: (value: Minutes) => void }
>;

export default DurationMinutesRequiredField;

export function DurationMinutesRequiredField({
  onValueChange,
  ...durationHoursFieldProps
}: DurationMinutesRequiredFieldProps) {
  const handleValueChange: DurationFieldProps['onValueChange'] = (minutes) => {
    if (minutes !== null) {
      onValueChange(minutes);
    }
  };

  return (
    <DurationField
      {...durationHoursFieldProps}
      onValueChange={handleValueChange}
    />
  );
}
