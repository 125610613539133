import { createAction } from 'lib/store-utils';
import { Event, EventsPeriod, FormattedErrors } from 'type/models';
import { UpdateFormPayload } from 'type';
import { ToggleFilterSuccessPayload } from './types';
import { EmployeeDashboardGetEventsResponse } from 'lib/Api/type';

export const BOX_EMPLOYEE_DASHBOARD_EVENTS_DATA_REQUEST = createAction('Events / get data request');

export const BOX_EMPLOYEE_DASHBOARD_EVENTS_GET_EVENTS_SUCCESS = createAction<EmployeeDashboardGetEventsResponse>('Events / get events list success');

export const BOX_EMPLOYEE_DASHBOARD_EVENTS_CHANGE_PAGE_REQUEST = createAction<number>('Events / Change page request');
export const BOX_EMPLOYEE_DASHBOARD_EVENTS_CHANGE_PAGE_SUCCESS = createAction<EmployeeDashboardGetEventsResponse>('Events / Change page success');
export const BOX_EMPLOYEE_DASHBOARD_EVENTS_CHANGE_PAGE_FAILURE = createAction<FormattedErrors>('Events / Change page failed');

export const BOX_EMPLOYEE_DASHBOARD_EVENTS_CHANGE_PAGE_SIZE_REQUEST = createAction<number>('Events / Change page size request');
export const BOX_EMPLOYEE_DASHBOARD_EVENTS_CHANGE_PAGE_SIZE_SUCCESS = createAction<EmployeeDashboardGetEventsResponse>('Events / Change page size success');
export const BOX_EMPLOYEE_DASHBOARD_EVENTS_CHANGE_PAGE_SIZE_FAILURE = createAction<FormattedErrors>('Events / Change page size failed');

export const BOX_EMPLOYEE_DASHBOARD_EVENTS_UPDATE_ORDERING_REQUEST = createAction<UpdateFormPayload>('Events / update ordering request');
export const BOX_EMPLOYEE_DASHBOARD_EVENTS_UPDATE_ORDERING_SUCCESS = createAction<ToggleFilterSuccessPayload>('Events / update ordering success');
export const BOX_EMPLOYEE_DASHBOARD_EVENTS_UPDATE_ORDERING_FAILED = createAction<FormattedErrors>('Events / update ordering failed');

export const BOX_EMPLOYEE_DASHBOARD_EVENTS_TOGGLE_PERIOD_TYPE_REQUEST = createAction<EventsPeriod>('Events / Change period request');
export const BOX_EMPLOYEE_DASHBOARD_EVENTS_TOGGLE_PERIOD_TYPE_SUCCESS = createAction<ToggleFilterSuccessPayload>('Events / Change period success');
export const BOX_EMPLOYEE_DASHBOARD_EVENTS_TOGGLE_PERIOD_TYPE_FAILURE = createAction<FormattedErrors>('Events / Change period failed');

export const BOX_EMPLOYEE_DASHBOARD_EVENTS_UPDATE_FORM_FILTERS = createAction<UpdateFormPayload>('Events / Change filters request');

export const BOX_EMPLOYEE_DASHBOARD_EVENTS_UPDATE_FILTERS_REQUEST = createAction('Events / Change filters request');
export const BOX_EMPLOYEE_DASHBOARD_EVENTS_UPDATE_FILTERS_SUCCESS = createAction<ToggleFilterSuccessPayload>('Events / Change filters success');
export const BOX_EMPLOYEE_DASHBOARD_EVENTS_UPDATE_FILTERS_FAILURE = createAction<FormattedErrors>('Events / Change filters failed');

export const BOX_EMPLOYEE_DASHBOARD_EVENTS_RESET_FILTERS_FORM = createAction('Events / Reset filters request');

export const BOX_EMPLOYEE_DASHBOARD_EVENTS_OPEN_DETAILS_MODAL = createAction<Event>('Events / open details modal');
export const BOX_EMPLOYEE_DASHBOARD_EVENTS_CLOSE_DETAILS_MODAL = createAction('Events / close details modal');

export const BOX_EMPLOYEE_DASHBOARD_EVENTS_UPDATE = createAction('Employee events / update');
