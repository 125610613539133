import { cloneDeep } from 'lodash';
import { createReducer } from 'lib/store-utils';
import * as employeeDashboard from '../EmployeeDashboard';
import { MyRostersReducerState } from './types';
import { getIsFinished, getIsOnBreak } from './helpers';
import { defaultFilters, getDefaultState } from './state';
import * as actions from './actions';
import ChainHandler from 'lib/ChainHandler';
import {
  HandlerArgs,
  HandlerResult,
  ShiftInProgressHandler,
  ShiftsArrayHandler,
} from './employee-timesheet-changed-handlers';

export const myRosters = createReducer<MyRostersReducerState>(
  {},
  getDefaultState()
);

myRosters.on(actions.BOX_MY_ROSTERS_DATA_REQUEST, getDefaultState);

myRosters.on(
  actions.BOX_MY_ROSTERS_SHIFTS_FETCHED,
  (state, { data, pager }): MyRostersReducerState => ({
    ...state,
    workingShifts: data,
    pager,
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_ACTIVE_TIMESHEET_FETCHED,
  (state, timesheetInProgress): MyRostersReducerState => ({
    ...state,
    timesheetInProgress,
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_TOGGLE_PERIOD_TYPE_REQUEST,
  (state): MyRostersReducerState => ({
    ...state,
    filters: cloneDeep(defaultFilters),
    filtersForm: cloneDeep(defaultFilters),
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_TOGGLE_PERIOD_TYPE_SUCCESS,
  (
    state,
    { workingShifts: { data, pager }, period }
  ): MyRostersReducerState => ({
    ...state,
    isCustomFilter: false,
    workingShifts: data,
    pager: pager,
    period,
    filters: cloneDeep(defaultFilters),
    filtersForm: cloneDeep(defaultFilters),
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_CLOCK_ON_SUCCESS,
  (state, timesheetInProgress): MyRostersReducerState => ({
    ...state,
    timesheetInProgress,
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_CLOCK_OFF_SUCCESS,
  (state, id): MyRostersReducerState => ({
    ...state,
    timesheetInProgress: null,
    workingShifts: state.workingShifts.map((shift) => {
      if (shift.timesheet && shift.timesheet.id === id) {
        shift.is_finished = true;
      }
      return shift;
    }),
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_START_BREAK_SUCCESS,
  (state, id): MyRostersReducerState => ({
    ...state,
    workingShifts: state.workingShifts.map((shift) => {
      if (shift.timesheet && shift.timesheet.id === id) {
        shift.is_on_break = true;
      }
      return shift;
    }),
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_START_BREAK_FAILURE,
  (state, errors): MyRostersReducerState => ({
    ...state,
    modals: {
      ...state.modals,
      breakLimit: true,
      errors,
    },
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_STOP_BREAK_SUCCESS,
  (state, id): MyRostersReducerState => ({
    ...state,
    workingShifts: state.workingShifts.map((shift) => {
      if (shift.timesheet && shift.timesheet.id === id) {
        shift.is_on_break = false;
      }
      return shift;
    }),
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_FORM_FILTER_UPDATED,
  (state, { name, value }): MyRostersReducerState => ({
    ...state,
    filtersForm: {
      ...state.filtersForm,
      [name]: value,
    },
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_FORM_FILTERS_RESET,
  (state): MyRostersReducerState => ({
    ...state,
    filters: cloneDeep(defaultFilters),
    filtersForm: cloneDeep(defaultFilters),
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_APPLY_FORM_FILTERS_REQUEST,
  (state): MyRostersReducerState => ({
    ...state,
    isCustomFilter: true,
    filters: cloneDeep(state.filtersForm),
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_CHANGE_PAGE_SUCCESS,
  (state, { data, pager }): MyRostersReducerState => ({
    ...state,
    workingShifts: data,
    pager: pager,
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_CHANGE_PAGE_SIZE_SUCCESS,
  (state, { data, pager }): MyRostersReducerState => ({
    ...state,
    workingShifts: data,
    pager: pager,
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_TRADE_MODAL_OPENED,
  (state, { shiftId, type }): MyRostersReducerState => ({
    ...state,
    modals: {
      ...state.modals,
      isTradeOpen: true,
      isUpdating: false,
      shiftId,
      type,
      errors: [],
    },
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_TRADE_MODAL_CLOSED,
  (state): MyRostersReducerState => ({
    ...state,
    modals: {
      ...state.modals,
      isTradeOpen: false,
      shiftId: '',
      note: '',
    },
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_TRADE_NOTE_SET,
  (state, note): MyRostersReducerState => ({
    ...state,
    modals: {
      ...state.modals,
      note,
    },
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_BREAKS_LIMIT_CONFIRMATION_MODAL_CLOSED,
  (state): MyRostersReducerState => ({
    ...state,
    modals: {
      ...state.modals,
      breakLimit: false,
    },
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_TRADE_MODAL_ERRORS_CLEARED,
  (state): MyRostersReducerState => ({
    ...state,
    modals: {
      ...state.modals,
      errors: [],
    },
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_TRADE_REQUEST,
  (state): MyRostersReducerState => ({
    ...state,
    modals: {
      ...state.modals,
      isUpdating: true,
      errors: [],
    },
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_TRADE_SUCCESS,
  (state, status): MyRostersReducerState => ({
    ...state,
    workingShifts: state.workingShifts.map((shift) =>
      shift.id === state.modals.shiftId
        ? {
            ...shift,
            status,
          }
        : shift
    ),
    modals: {
      ...state.modals,
      isUpdating: false,
      isTradeOpen: false,
    },
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_TRADE_FAILURE,
  (state, errors): MyRostersReducerState => ({
    ...state,
    modals: {
      ...state.modals,
      isUpdating: false,
      errors: errors,
    },
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_CONFIRMATION_MODAL_OPENED,
  (state, { message }): MyRostersReducerState => ({
    ...state,
    forceModal: {
      ...state.forceModal,
      isOpen: true,
      message,
    },
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_CONFIRMATION_MODAL_CLOSED,
  (state): MyRostersReducerState => ({
    ...state,
    modals: {
      ...state.modals,
      isTradeOpen: false,
      note: '',
    },
    forceModal: {
      ...state.forceModal,
      isOpen: false,
    },
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_TRADE_FORCE_REQUEST,
  (state): MyRostersReducerState => ({
    ...state,
    forceModal: {
      ...state.forceModal,
      isOpen: false,
    },
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_TRADE_FORCE_SUCCESS,
  (state, { shift_id, type }): MyRostersReducerState => ({
    ...state,
    workingShifts: state.workingShifts.map((workingShift) => {
      if (workingShift.id === shift_id) {
        return {
          ...workingShift,
          status: type,
        };
      }

      return workingShift;
    }),
    modals: {
      ...state.modals,
      isTradeOpen: false,
    },
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_TRADE_FORCE_FAILURE,
  (state, errors): MyRostersReducerState => ({
    ...state,
    modals: {
      ...state.modals,
      isUpdating: false,
      errors,
    },
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_ACCEPT_SHIFT_SUCCESS,
  (state, newShift): MyRostersReducerState => {
    return {
      ...state,
      workingShifts: state.workingShifts.map((shift) => {
        if (shift.id === newShift.id) {
          return newShift;
        }

        return shift;
      }),
    };
  }
);

myRosters.on(
  actions.BOX_MY_ROSTERS_DECLINE_SHIFT_SUCCESS,
  (state, id): MyRostersReducerState => ({
    ...state,
    workingShifts: state.workingShifts.filter((shift) => shift.id !== id),
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_WHO_ELSE_WORKING_REQUEST,
  (state, payload): MyRostersReducerState => ({
    ...state,
    whoElseWorkingIsLoading: true,
    whoElseWorking: [],
    whoElseWorkingErrors: [],
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_WHO_ELSE_WORKING_SUCCESS,
  (state, response): MyRostersReducerState => ({
    ...state,
    whoElseWorkingIsLoading: false,
    whoElseWorking: response,
  })
);

myRosters.on(
  actions.BOX_MY_ROSTERS_WHO_ELSE_WORKING_FAILURE,
  (state, errors): MyRostersReducerState => ({
    ...state,
    whoElseWorkingIsLoading: false,
    whoElseWorkingErrors: errors,
  })
);

myRosters.on(
  employeeDashboard.BOX_EMPLOYEE_DASHBOARD_SHIFT_CHANGED,
  (state, { rostered_shift, timesheet }): MyRostersReducerState => ({
    ...state,
    workingShifts: state.workingShifts.map((shift) => {
      if (shift.id === rostered_shift.id) {
        const is_on_break = getIsOnBreak(timesheet);
        const is_finished = getIsFinished(timesheet);

        return {
          ...rostered_shift,
          shift_id: rostered_shift.id,
          timesheet,
          is_on_break,
          is_finished,
        };
      }

      return shift;
    }),
  })
);

const timesheetChangedHandler = new ChainHandler<HandlerResult, HandlerArgs>();
const shiftsArrayHandler = new ShiftsArrayHandler();
const shiftInProgressHandler = new ShiftInProgressHandler();

timesheetChangedHandler
  .setNext(shiftsArrayHandler)
  .setNext(shiftInProgressHandler);

myRosters.on(
  employeeDashboard.BOX_EMPLOYEE_DASHBOARD_TIMESHEET_CHANGED,
  timesheetChangedHandler.handle
);

myRosters.on(
  employeeDashboard.BOX_EMPLOYEE_DASHBOARD_TIMESHEET_DELETED,
  (state, payload): MyRostersReducerState => {
    let { timesheetInProgress } = state;
    if (timesheetInProgress?.timesheet_id === payload.id) {
      timesheetInProgress = null;
    }
    return {
      ...state,
      timesheetInProgress,
      workingShifts: state.workingShifts.map((shift) => {
        if (shift.timesheet?.id === payload.id) {
          return {
            ...shift,
            timesheet: null,
          };
        }

        return shift;
      }),
    };
  }
);
