import { createReducer } from 'lib/store-utils';
import { getDefaultState } from './state';
import { UserProfileReducerState } from './types';
import * as actions from './actions';
import { merge } from 'lodash';
import moment from 'moment-timezone';

export const userProfile = createReducer<UserProfileReducerState>(
  {},
  getDefaultState()
);

userProfile.on(
  actions.BOX_USER_PROFILE_REQUEST,
  (state): UserProfileReducerState => ({
    ...state,
    isFetching: true
  })
);

userProfile.on(
  actions.BOX_USER_PROFILE_SUCCESS,
  (state, data): UserProfileReducerState => ({
    ...state,
    isFetching: false,
    userProfile: {
      ...data,
      settings: {
        custom_user_group: data.settings ? data.settings.custom_user_group : false
      }
    },
    customFieldsModal: {
      isOpened: false,
      custom_fields: data.custom_fields
    },
    profileModal: {
      isOpened: false
    },
    rolesModal: {
      isOpened: false
    },
    paymentModal: {
      isOpened: false
    },
    userDetails: {
      isOpened: false
    }
  })
);

userProfile.on(
  actions.BOX_USER_PROFILE_FAILURE,
  (state, errors): UserProfileReducerState => ({
    ...state,
    isFetching: false,
    errors: errors
  })
);

userProfile.on(
  actions.BOX_USER_PROFILE_ENTITLEMENTS_REQUEST,
  (state): UserProfileReducerState => ({
    ...state,
    isFetching: true,
    errors: []
  })
);

userProfile.on(
  actions.BOX_USER_PROFILE_ENTITLEMENTS_SUCCESS,
  (state, data): UserProfileReducerState => ({
    ...state,
    isFetching: false,
    entitlements: data,
    errors: []
  })
);

userProfile.on(
  actions.BOX_USER_PROFILE_ENTITLEMENTS_FAILURE,
  (state, errors): UserProfileReducerState => ({
    ...state,
    isFetching: false,
    errors: errors
  })
);

userProfile.on(actions.BOX_USER_PROFILE_UPDATE_REQUEST, state => ({
  ...state,
  isUpdating: true,
  userProfile: {
    ...state.userProfile,
    fileUploaded: false
  },
  errors: []
}));

userProfile.on(actions.BOX_USER_PROFILE_UPDATE_SUCCESS,
  (state, profile) => ({
  ...state,
  isUpdating: false,
  userProfile: {
    ...profile,
    settings: {
      custom_user_group: profile.settings ? profile.settings.custom_user_group : false
    }
  },
  errors: [],
  customFieldsModal: {
    custom_fields: profile.custom_fields,
    isOpened: false
  },
  profileModal: {
    isOpened: false
  },
  approversModal: {
    isOpened: false
  },
  paymentModal: {
    isOpened: false
  },
  rolesModal: {
    isOpened: false
  },
  pinModal: {
    isOpened: false
  },
  userDetails: {
    isOpened: false
  },
  rulesModal: {
    isOpened: false
  }
}));

userProfile.on(actions.BOX_USER_PROFILE_UPDATE_FAILURE, (state, errors) => {
  return {
    ...state,
    isUpdating: false,
    userProfile: {
      ...state.userProfile,
      fileUploaded: false
    },
    errors: errors
  };
});

userProfile.on(actions.BOX_USER_PROFILE_UPLOAD_REMOVE, (state, name) => {
  return {
    ...state,
    userProfile: {
      ...state.userProfile,
      avatar: ''
    },
    errors: []
  };
});

userProfile.on(actions.BOX_USER_PROFILE_UPLOAD_APPEND, (state, name) => {
  return {
    ...state,
    userProfile: {
      ...state.userProfile,
      avatar: 'avatar'
    },
    errors: []
  };
});

userProfile.on(actions.BOX_USER_PROFILE_UPLOAD_SUCCESS, (state, data) => {
  return {
    ...state,
    userProfile: {
      ...state.userProfile,
      avatar: data.filename,
      fileUploaded: true
    },
    isUpdating: true,
    errors: []
  };
});

userProfile.on(actions.BOX_USER_PROFILE_UPLOAD_FAILURE, (state, errors) => ({
  ...state,
  isUpdating: false,
  errors
}));

userProfile.on(actions.BOX_USER_PROFILE_CUSTOM_MODAL_CLOSE, state => ({
  ...state,
  isUpdating: false,
  customFieldsModal: {
    ...state.customFieldsModal,
    isOpened: false
  },
  errors: []
}));

userProfile.on(actions.BOX_USER_PROFILE_CUSTOM_MODAL_OPEN, state => ({
  ...state,
  isUpdating: false,
  customFieldsModal: {
    ...state.customFieldsModal,
    isOpened: true
  },
  errors: []
}));

userProfile.on(actions.BOX_USER_PROFILE_UPLOAD_MODAL_OPEN, state => {
  const profile = state.userProfile;
  let avatar = profile.avatar;
  if (
    profile.avatar === '' &&
    profile.avatar_url !== null &&
    profile.avatar_url !== ''
  ) {
    const arr = profile.avatar_url.split('/');
    avatar = arr[arr.length - 1];
  }
  return {
    ...state,
    isUpdating: false,
    uploadModal: {
      isOpened: true
    },
    userProfile: {
      ...state.userProfile,
      avatar: avatar
    },
    errors: []
  };
});

userProfile.on(actions.BOX_USER_PROFILE_UPLOAD_MODAL_CLOSE, state => ({
  ...state,
  isUpdating: false,
  uploadModal: {
    isOpened: false
  },
  errors: []
}));

userProfile.on(actions.BOX_USER_PROFILE_PROFILE_MODAL_CLOSE, state => ({
  ...state,
  isUpdating: false,
  profileModal: {
    isOpened: false
  },
  errors: []
}));

userProfile.on(actions.BOX_USER_PROFILE_PROFILE_MODAL_OPEN, state => ({
  ...state,
  isUpdating: false,
  profileModal: {
    isOpened: true
  },
  errors: []
}));

userProfile.on(actions.BOX_USER_PROFILE_ROLES_MODAL_CLOSE, state => ({
  ...state,
  isUpdating: false,
  rolesModal: {
    isOpened: false
  },
  errors: []
}));

userProfile.on(actions.BOX_USER_PROFILE_ROLES_MODAL_OPEN, state => ({
  ...state,
  isUpdating: false,
  rolesModal: {
    isOpened: true
  },
  errors: []
}));

userProfile.on(actions.BOX_USER_PROFILE_PAYMENT_MODAL_CLOSE, state => ({
  ...state,
  isUpdating: false,
  paymentModal: {
    isOpened: false
  },
  errors: []
}));

userProfile.on(actions.BOX_USER_PROFILE_PAYMENT_MODAL_OPEN, state => ({
  ...state,
  isUpdating: false,
  paymentModal: {
    isOpened: true
  },
  errors: []
}));

userProfile.on(actions.BOX_USER_PROFILE_REMOVE_ERRORS, state => ({
  ...state,
  errors: []
}));

userProfile.on(
  actions.BOX_USER_PROFILE_ADD_ROLE_REQUEST,
  (state): UserProfileReducerState => ({
    ...state,
    isUpdating: true,
    errors: []
  })
);

userProfile.on(
  actions.BOX_USER_PROFILE_ADD_ROLE_UPDATE_USER_PROFILE,
  (state, { payroll_site_id, user_roles, payroll_area_id }): UserProfileReducerState => ({
    ...state,
    userProfile: {
      ...state.userProfile,
      payroll_site_id,
      payroll_area_id,
      user_roles
    }
  })
);

userProfile.on(
  actions.BOX_USER_PROFILE_ADD_ROLE_SUCCESS,
  (state): UserProfileReducerState => ({
    ...state,
    rolesModal: {
      isOpened: false
    },
    isUpdating: false
  })
);

userProfile.on(
  actions.BOX_USER_PROFILE_ADD_ROLE_FAILURE,
  (state, errors): UserProfileReducerState => ({
    ...state,
    isUpdating: false,
    errors: errors
  })
);

userProfile.on(
  actions.BOX_USER_PROFILE_EDIT_APPROVALS_MODAL_OPEN,
  (state, userId): UserProfileReducerState => ({
    ...state,
    errors: [],
    editApproversModal: {
      ...state.editApproversModal,
      isOpened: true,
      userId
    }
  })
);

userProfile.on(
  actions.BOX_USER_PROFILE_EDIT_APPROVALS_MODAL_CLOSE,
  (state): UserProfileReducerState => ({
    ...state,
    editApproversModal: {
      ...state.editApproversModal,
      isOpened: false
    }
  })
);

userProfile.on(
  actions.BOX_USER_PROFILE_EDIT_APPROVALS_REQUEST,
  (state): UserProfileReducerState => ({
    ...state,
    isUpdating: true
  })
);

userProfile.on(
  actions.BOX_USER_PROFILE_EDIT_APPROVALS_SUCCESS,
  (state, partialUserProfile): UserProfileReducerState => ({
    ...state,
    isUpdating: false,
    userProfile: merge({}, state.userProfile, partialUserProfile),
    editApproversModal: {
      ...state.editApproversModal,
      isOpened: false
    }
  })
);

userProfile.on(
  actions.BOX_USER_PROFILE_EDIT_APPROVALS_FAILURE,
  (state, errors): UserProfileReducerState => ({
    ...state,
    errors,
    isUpdating: false
  })
);

userProfile.on(actions.BOX_USER_PROFILE_CUSTOM_FILE_REQUEST, (state, file) => ({
  ...state,
  isUploading: true
}));

userProfile.on(actions.BOX_USER_PROFILE_CUSTOM_FILE_SUCCESS, (state, file) => ({
  ...state,
  isUploading: false,
  userProfile: {
    ...state.userProfile
  },
  customFieldsModal: {
    ...state.customFieldsModal,
    custom_fields: {
      ...state.customFieldsModal.custom_fields,
      [file.name]: {
        filename: file.data.filename,
        filepath: file.data.filepath,
        original_filename: file.original_filename
      }
    }
  }
}));

userProfile.on(actions.BOX_USER_PROFILE_CUSTOM_FILE_FAILURE, (state, file) => ({
  ...state,
  isUploading: false,
  userProfile: {
    ...state.userProfile
  },
  customFieldsModal: {
    ...state.customFieldsModal,
    custom_fields: {
      ...state.customFieldsModal.custom_fields,
      [file.name]: null
    }
  },
  errors: file.data
}));

userProfile.on(actions.BOX_USER_PROFILE_PIN_OPEN_MODAL, state => ({
  ...state,
  pinModal: {
    isOpened: true
  }
}));

userProfile.on(actions.BOX_USER_PROFILE_PIN_CLOSE_MODAL, state => ({
  ...state,
  pinModal: {
    isOpened: false
  },
  errors: []
}));

userProfile.on(actions.BOX_USER_PROFILE_DETAILS_MODAL_OPEN, state => ({
  ...state,
  userDetails: {
    isOpened: true
  }
}));

userProfile.on(actions.BOX_USER_PROFILE_DETAILS_MODAL_CLOSE, state => ({
  ...state,
  userDetails: {
    isOpened: false
  }
}));

userProfile.on(actions.BOX_USER_PROFILE_RULES_MODAL_OPEN, state => ({
  ...state,
  rulesModal: {
    isOpened: true
  }
}));

userProfile.on(actions.BOX_USER_PROFILE_RULES_MODAL_CLOSE, state => ({
  ...state,
  rulesModal: {
    isOpened: false
  }
}));

userProfile.on(
  actions.BOX_USER_PROFILE_AWARDS_REQUEST,
  (state): UserProfileReducerState => ({
    ...state,
    awards: {
      ...state.awards,
      isFetching: true,
      errors: []
    }
  })
);

userProfile.on(
  actions.BOX_USER_PROFILE_AWARDS_SUCCESS,
  (state, data): UserProfileReducerState => ({
    ...state,
    awards: {
      ...state.awards,
      isFetching: false,
      globalAwardsList: data[0],
      assignedAwards: data[1]
    }
  })
);

userProfile.on(
  actions.BOX_USER_PROFILE_AWARDS_FAILURE,
  (state, errors): UserProfileReducerState => ({
    ...state,
    awards: {
      ...state.awards,
      isFetching: false,
      errors: errors
    }
  })
);

userProfile.on(
  actions.BOX_USER_PROFILE_OPEN_AWARDS_MODAL,
  (state, payload): UserProfileReducerState => ({
    ...state,
    awards: {
      ...state.awards,
      modal: {
        ...state.awards.modal,
        isOpened: true,
        errors: [],
        payload: payload ? payload : state.awards.modal.payload,
      }
    }
  })
);

userProfile.on(
  actions.BOX_USER_PROFILE_CLOSE_AWARDS_MODAL,
  (state): UserProfileReducerState => ({
    ...state,
    awards: {
      ...state.awards,
      modal: {
        ...state.awards.modal,
        isOpened: false,
        payload: {
          id: '',
          awardId: null,
          effectiveDate: moment().add(1, 'day'),
          type: 'create'
        }
      }
    }
  })
);

userProfile.on(
  actions.BOX_USER_PROFILE_AWARDS_SAVE,
  (state): UserProfileReducerState => ({
    ...state,
    awards: {
      ...state.awards,
      modal: {
        ...state.awards.modal,
        errors: [],
        isSaving: true
      }
    }
  })
);

userProfile.on(
  actions.BOX_USER_PROFILE_AWARDS_SAVE_SUCCESS,
  (state, assignedAward): UserProfileReducerState => ({
    ...state,
    awards: {
      ...state.awards,
      modal: {
        ...state.awards.modal,
        isSaving: false,
        isOpened: false,
        payload: {
          ...state.awards.modal.payload,
          awardId: null,
          type: 'create'
        }
      },
      assignedAwards: [...state.awards.assignedAwards, {
        ...assignedAward,
        award: assignedAward.award_id ? {
          id: assignedAward.award_id
        } : null
      }]
    }
  })
);


userProfile.on(
  actions.BOX_USER_PROFILE_AWARDS_SAVE_FAILURE,
  (state, errors): UserProfileReducerState => ({
    ...state,
    awards: {
      ...state.awards,
      modal: {
        ...state.awards.modal,
        isSaving: false,
        errors: errors
      }
    }
  })
);

userProfile.on(
  actions.BOX_USER_PROFILE_AWARDS_MODALS_CLEAR_ERRORS,
  (state): UserProfileReducerState => ({
    ...state,
    awards: {
      ...state.awards,
      modal: {
        ...state.awards.modal,
        errors: []
      },
      deleteModal: {
        ...state.awards.deleteModal,
        errors: []
      }
    }
  })
);

userProfile.on(
  actions.BOX_USER_PROFILE_AWARDS_DELETE_MODAL_OPEN,
  (state, id): UserProfileReducerState => ({
    ...state,
    awards: {
      ...state.awards,
      deleteModal: {
        ...state.awards.deleteModal,
        awardId: id,
        isOpened: true
      }
    }
  })
);

userProfile.on(
  actions.BOX_USER_PROFILE_AWARDS_DELETE_MODAL_CLOSE,
  (state, id): UserProfileReducerState => ({
    ...state,
    awards: {
      ...state.awards,
      deleteModal: {
        ...state.awards.deleteModal,
        awardId: null,
        isOpened: false,
        errors: []
      }
    }
  })
);

userProfile.on(
  actions.BOX_USER_PROFILE_AWARDS_DELETE_REQUEST,
  (state, id): UserProfileReducerState => ({
    ...state,
    awards: {
      ...state.awards,
      deleteModal: {
        ...state.awards.deleteModal,
        isDeleting: true,
        errors: []
      }
    }
  })
);

userProfile.on(
  actions.BOX_USER_PROFILE_AWARDS_DELETE_SUCCESS,
  (state, id): UserProfileReducerState => ({
    ...state,
    awards: {
      ...state.awards,
      deleteModal: {
        ...state.awards.deleteModal,
        isDeleting: false
      },
      assignedAwards: state.awards.assignedAwards.filter(award => award.id !== id)
    }
  })
);

userProfile.on(
  actions.BOX_USER_PROFILE_AWARDS_DELETE_FAILURE,
  (state, errors): UserProfileReducerState => ({
    ...state,
    awards: {
      ...state.awards,
      deleteModal: {
        ...state.awards.deleteModal,
        isDeleting: false,
        errors: errors
      }
    }
  })
);

userProfile.on(
  actions.BOX_USER_PROFILE_AWARDS_UPDATE_AWARD,
  (state): UserProfileReducerState => ({
    ...state,
    awards: {
      ...state.awards,
      modal: {
        ...state.awards.modal,
        errors: [],
        isSaving: true
      }
    }
  })
);

userProfile.on(
  actions.BOX_USER_PROFILE_AWARDS_UPDATE_AWARD_SUCCESS,
  (state, assignedAward): UserProfileReducerState => ({
    ...state,
    awards: {
      ...state.awards,
      modal: {
        ...state.awards.modal,
        isSaving: false,
        isOpened: false,
        payload: {
          ...state.awards.modal.payload,
          awardId: null,
          type: 'create'
        }
      },
      assignedAwards: [...state.awards.assignedAwards.filter(award => award.id !== assignedAward.id), {
        ...assignedAward,
        award: assignedAward.award_id ? {
          id: assignedAward.award_id
        } : null
      }]
    }
  })
);


userProfile.on(
  actions.BOX_USER_PROFILE_AWARDS_UPDATE_AWARD_FAILURE,
  (state, errors): UserProfileReducerState => ({
    ...state,
    awards: {
      ...state.awards,
      modal: {
        ...state.awards.modal,
        isSaving: false,
        errors: errors
      }
    }
  })
);

export default userProfile;
