import React, { Component } from 'react';
import { Button, Row, Col, Divider } from 'elmo-elements';
import {
  EmailOutlinedIcon,
  MonetizationOnOutlinedIcon,
  EditOutlinedIcon,
  DeleteOutlinedIcon,
} from 'element/icons';
import { Timesheet, TimesheetPayload } from 'type/models';
import { connect } from 'react-redux';
import { StoreState } from '../../../../../state/types';
import { hasPermissionSelector } from '../../../../../state/Auth';

type OwnProps = {
  shift: TimesheetPayload;
  isUpdating: boolean;
  openSubModal: (name: string) => void;
  deleteShift: () => void;
};

type StateProps = {
  canEdit: boolean;
  canEditOnceApproved: boolean;
  timesheetCosting: boolean;
};

type DispatchProps = {};

export type Props = OwnProps & StateProps & DispatchProps;

export class ModalActions extends Component<Props> {
  render() {
    const {
      shift: { id },
      timesheetCosting,
    } = this.props;
    if (id === null || id === '') {
      return null;
    }
    return (
      <div className={'timesheet-modal__actions'}>
        <Row>
          <Col isAuto={true}>
            {/*<Button*/}
            {/*  id={'messages-tab'}*/}
            {/*  isText={true}*/}
            {/*  className={'timesheet-modal__actions--messages'}*/}
            {/*  icon={<EmailOutlinedIcon />}*/}
            {/*  onClick={e => this.props.openSubModal('messages')}*/}
            {/*  tabIndex={0}*/}
            {/*/>*/}
            {/*{timesheetCosting && <Button*/}
            {/*  id={'awards-tab'}*/}
            {/*  isText={true}*/}
            {/*  className={'timesheet-modal__actions--awards'}*/}
            {/*  icon={<MonetizationOnOutlinedIcon />}*/}
            {/*  onClick={e => this.props.openSubModal('awards')}*/}
            {/*  tabIndex={0}*/}
            {/*/>}*/}
          </Col>
          {id !== null && this.canBeEdited && (
            <Col isAuto={true} className={'text-right'}>
              <Button
                id={'edit-tab'}
                isText={true}
                icon={<EditOutlinedIcon />}
                className={'timesheet-modal__actions--edit'}
                isDisabled={this.props.isUpdating}
                onClick={() => this.props.openSubModal('edit')}
                tabIndex={0}
              />
              <Button
                id={'delete-tab-btn'}
                isText={true}
                icon={<DeleteOutlinedIcon />}
                className={'timesheet-modal__actions--delete'}
                onClick={this.props.deleteShift}
                isDisabled={this.props.isUpdating}
                tabIndex={0}
              />
            </Col>
          )}
        </Row>
        <Divider />
      </div>
    );
  }

  get canBeEdited(): boolean {
    const {
      canEditOnceApproved,
      canEdit,
      shift: { end, is_approved },
      isUpdating,
    } = this.props;

    if (!end) {
      // timesheet is in progress
      return false;
    }

    if (!canEdit) {
      // the user can't edit timesheets
      return false;
    }

    // timesheet is approved and the user can't edit approved timesheets
    return !(is_approved && !canEditOnceApproved);
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  canEditOnceApproved: hasPermissionSelector(
    state,
    'roster.timesheet.edit.onceapproved'
  ),
  canEdit: hasPermissionSelector(state, 'roster.timesheet.edit'),
  timesheetCosting: hasPermissionSelector(
    state,
    'roster.timesheet.view.labourcostings'
  ),
});

const mapDispatchToProps: DispatchProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalActions);
