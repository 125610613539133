import {
  DialogActions,
  DialogContent,
  DialogContentText,
  useTheme,
} from 'extended-oxygen-elements';
import { useSelectorWithProps } from 'hooks';
import { confirmationModalTitle, warningsModalBtnLabel } from 'messages';
import { Button, Dialog, DialogTitle } from 'extended-oxygen-elements';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  BOX_WARNINGS_CLOSE_MODAL,
  getIsOpened,
  getMessages,
} from 'state/ErrorsProcessing/Warnings';

export function WarningsModal() {
  const btnRef = React.useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();
  const isOpened = useSelectorWithProps(getIsOpened);
  const messages = useSelectorWithProps(getMessages);
  const theme = useTheme();

  React.useEffect(() => {
    const { current } = btnRef;
    if (isOpened && current) {
      current.focus();
    }
  }, [isOpened]);

  const closeDialog = () => {
    dispatch(BOX_WARNINGS_CLOSE_MODAL());
  };

  return (
    <Dialog
      maxWidth="xs"
      open={isOpened}
      onClose={closeDialog}
      style={{ zIndex: theme.zIndex.globalOverlay }}
    >
      <DialogTitle>{confirmationModalTitle}</DialogTitle>

      <DialogContent>
        {messages.map((message, index) => (
          <DialogContentText key={index}>{message}</DialogContentText>
        ))}
      </DialogContent>

      <DialogActions>
        <Button ref={btnRef} variant="contained" onClick={closeDialog}>
          {warningsModalBtnLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
