import { RosterPair } from '../../types';

export const filterRoles = (
  idsToBeFiltered: string[],
  filterIds: null | string[]
): string[] => {
  return !filterIds
    ? idsToBeFiltered
    : idsToBeFiltered.filter(id => filterIds.includes(id));
};
export const sortPair = ({ rosteredShift, timesheets }: RosterPair) => {
  if (rosteredShift) {
    return rosteredShift.start.unix();
  }

  return timesheets!.start.unix();
};
