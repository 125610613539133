import React from 'react';
import { Dropdown } from 'elmo-elements';
import { CustomDropdownOptionType } from '../types';

type Props<Value> = CustomDropdownOptionType<Value> & {
  onSelect: ((value: Value) => void) | undefined;
};

export const CustomDropdownOption = <Value extends any>({
  value,
  isDisabled,
  label,
  onSelect
}: Props<Value>) => (
  <Dropdown.Item value={value} onClick={onSelect} isDisabled={isDisabled}>
    {label}
  </Dropdown.Item>
);
