import { createAction } from 'lib/store-utils';
import {
  ChangeSelectedPayload,
  OpenPayload,
  SubmitPayload,
  UpdateSelectedPayload
} from './types';

export const BOX_SITE_FLAT_AREA_ROLE_MODAL_OPEN = createAction<OpenPayload>('Flat Area Role Modal / open');
export const BOX_SITE_FLAT_AREA_ROLE_MODAL_SUBMIT = createAction<SubmitPayload>('Flat Area Role Modal / submit');
export const BOX_SITE_FLAT_AREA_ROLE_MODAL_CLOSE = createAction('Flat Area Role Modal / close');

export const BOX_SITE_FLAT_AREA_ROLE_MODAL_CHANGE_AREAS = createAction<ChangeSelectedPayload>('Flat Area Role Modal / change areas');
export const BOX_SITE_FLAT_AREA_ROLE_MODAL_CHANGE_ROLES = createAction<ChangeSelectedPayload>('Flat Area Role Modal / change roles');

export const BOX_SITE_FLAT_AREA_ROLE_MODAL_UPDATE_SELECTED = createAction<UpdateSelectedPayload>('Flat Area Role Modal / change roles');
