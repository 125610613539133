import React, { Children, ReactElement, ReactNode } from 'react';
import Checkbox from '../../Checkbox';
import Row from '../../Grid/Row';
import { ListTableColumnProps } from './ListTableColumn';
import ListTableTd, { ListTableTdProps } from './ListTableTd';
import { ListTableAction, ListTableAvatarType } from '../type';
import './ListTableTr.scss';
import ListTableActions from './ListTableActions';
import { getClass, isElementOfType } from '../../_lib/helper';
import { withListTableContext } from '../ListTableContextConsumer';
import { Avatar } from 'oxygen-elements';

export type ListTableTrProps = {
  /** The Id of this row */
  id?: string;
  /** Unique test id for the row, used instead of row index */
  testId?: string;
  /** An array of ListTableTds */
  children?: ReactNode;
  /** The actions available in this row. If set, will show a dropdown */
  actions?: ListTableAction[];
  /** The icon used for showing the actions in the row. If no actions set, will show an icon */
  icon?: ReactNode;
  /** True if row is selected */
  isSelected?: boolean;
  /** True if row is disabled */
  isDisabled?: boolean;
  /** Called when row clicked */
  onRowClick?: (rowIndex: number) => void;
  /** The url to go to when clicked */
  href?: string;
  /** custom classname */
  className?: string;
  /** Called when row selected/deselected */
  onItemToggle?: (isSelect: boolean) => void;
  /** @Ignore from ListTable */
  rowData?: any;
  /** @Ignore from ListTable or ListTableBody */
  rowIndex: number;
  /** @Ignore From ListTable. */
  columns?: any;
  /** @Ignore From ListTable */
  countSelectedItems?: number;
  /** @Ignore From ListTable */
  selectAllIsChecked: boolean;
  /** @Ignore From ListTable */
  setContextState: (state: any) => void;
  /** @Ignore From ListTable */
  updateSelectAllCheckboxState: (isSelected: boolean) => void;
  /** @Ignore From LayoutContext */
  isBulkActionOpen?: boolean;
  /** @Ignore From Avatar Type */
  avatarType?: ListTableAvatarType;
  hasActions: boolean;
};

class ListTableTr extends React.Component<ListTableTrProps> {
  toggleCheckbox = (isSelected: boolean) => {
    const { onItemToggle, updateSelectAllCheckboxState } = this.props;

    updateSelectAllCheckboxState(isSelected);
    if (onItemToggle) {
      onItemToggle(isSelected);
    }
  };

  handleRowClick = (isSelected: boolean) => () => {
    const { isBulkActionOpen, onRowClick, rowIndex, href } = this.props;

    if (isBulkActionOpen) {
      this.toggleCheckbox(isSelected);
      return;
    }

    if (href) {
      window.location.href = href;
      return;
    }

    if (onRowClick) {
      onRowClick(rowIndex);
    }
  };

  handleRowKeyPress = (isSelected: boolean) => (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      this.handleRowClick(isSelected)();
    }
    return false;
  };

  renderAvatar() {
    const { avatarType, rowData } = this.props;
    const rowDataHasAvatar = rowData && rowData.avatar && rowData.avatar.label;

    if (avatarType === 'avatar' && rowDataHasAvatar) {
      return (
        <Avatar
          src={rowData.avatar.src}
          alt={rowData.avatar.label}
          light
          sx={{ marginRight: '10px' }}
        />
      );
    }

    if (avatarType === 'thumbnail' && rowDataHasAvatar) {
      const backgroundImage = rowData.avatar.src
        ? { backgroundImage: 'url(' + rowData.avatar.src + ')' }
        : undefined;
      return (
        <div
          className="elmo-listtable__thumbnail"
          title={rowData.avatar.label}
          style={{ ...backgroundImage }}
          role="img"
          aria-label={rowData.avatar.label}
        />
      );
    }
  }

  renderActions() {
    const { actions, icon, isBulkActionOpen, rowIndex, hasActions } =
      this.props;

    if (isBulkActionOpen) {
      return null;
    }

    return (
      <ListTableActions
        actions={actions}
        icon={icon}
        rowIndex={rowIndex}
        isPlaceholderRendered={hasActions}
      />
    );
  }

  renderColumns() {
    const { children, columns, rowData, rowIndex } = this.props;

    if (children) {
      return Children.map(
        children as React.ReactElement<any>[],
        (child: ReactElement<any>, colIndex: number) => {
          const column = columns[colIndex];
          const label = column ? column.props.label : '';

          if (
            !React.isValidElement(child) ||
            !isElementOfType(child, ListTableTd)
          ) {
            return null;
          }

          return React.cloneElement(child as ReactElement<ListTableTdProps>, {
            rowIndex: rowIndex,
            colIndex: colIndex,
            label: label,
          });
        }
      );
    } else if (columns) {
      return columns.map((child: any, colIndex: number) => {
        const column = child as ReactElement<ListTableColumnProps>;
        return (
          <ListTableTd
            data={rowData}
            rowIndex={rowIndex}
            colIndex={colIndex}
            label={column.props.label}
            property={column.props.property}
            render={column.props.render}
            key={colIndex}
          />
        );
      });
    }
  }

  render() {
    const {
      rowIndex,
      isSelected,
      isDisabled,
      isBulkActionOpen,
      id,
      testId,
      href,
      onRowClick,
      className,
    } = this.props;

    const isClickable = !!(onRowClick || href) && !isDisabled;

    return (
      <div
        key={rowIndex}
        role="row"
        className={getClass('elmo-listtable__row-wrapper', className, {
          selected: isSelected,
          clickable: isClickable,
        })}
        data-testid={`elmo-listtable-row-${testId || id || 'default'}`}
        {...(!isDisabled && {
          onClick: this.handleRowClick(!isSelected),
          onKeyPress: this.handleRowKeyPress(!isSelected),
        })}
        tabIndex={isClickable ? 0 : -1}
      >
        {isBulkActionOpen && (
          <div className="elmo-listtable__row-checkbox" role="cell">
            <Checkbox
              id={'cb-' + rowIndex}
              isChecked={isSelected}
              isDisabled={isDisabled}
              ariaLabel="Select row"
            />
          </div>
        )}
        {this.renderAvatar()}
        <div className="elmo-listtable__cols">
          <Row className="elmo-listtable__row">{this.renderColumns()}</Row>
        </div>
        {this.renderActions()}
      </div>
    );
  }
}

export { ListTableTr as ListTableTrNoContext };
export default withListTableContext(ListTableTr);
