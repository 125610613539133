import React from 'react';
import { useTheme } from 'extended-oxygen-elements';
import {
  getIsTimesheetApproved,
  getIsTimesheetInProgress,
  getIsTimesheetPending,
  typedCond,
} from 'lib/helpers';
import {
  bindHoverPopover,
  bindHoverTrigger,
  useHoverPopupState,
  useSelectorWithProps,
} from 'hooks';
import { getTimesheet } from 'state/TimesheetResponse';
import { useIsEditableView } from 'page/TimesheetsWeekly/hooks';
import {
  ActionsWrapper,
  ApprovedActions,
  InProgressActions,
  PendingActions,
  ShadowWrapper,
  TimesheetCard,
} from './components';

type TimesheetTileProps = {
  timesheetId: string;
};

const getTileActions = typedCond([
  [getIsTimesheetInProgress, () => InProgressActions],
  [getIsTimesheetApproved, () => ApprovedActions],
  [getIsTimesheetPending, () => PendingActions],
]);

export function TimesheetTile({ timesheetId }: TimesheetTileProps) {
  const hoverPopupState = useHoverPopupState<HTMLDivElement>();
  const timesheet = useSelectorWithProps(getTimesheet, timesheetId);
  const theme = useTheme();
  const isEditableView = useIsEditableView({ userId: timesheet.user_id });

  const TileActions = getTileActions(timesheet);

  return (
    <ShadowWrapper
      visible={isEditableView && TileActions && hoverPopupState.isOpened}
    >
      <TimesheetCard
        timesheetId={timesheetId}
        {...bindHoverTrigger(hoverPopupState)}
      />

      {isEditableView && TileActions && (
        <ActionsWrapper
          {...bindHoverPopover(hoverPopupState)}
          placement="bottom-end"
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [35, theme.gap(1 / 4)]
              }
            }
          ]}
        >
          <TileActions
            timesheetId={timesheetId}
            closeActionsPopover={hoverPopupState.close}
          />
        </ActionsWrapper>
      )}
    </ShadowWrapper>
  );
}
