import { map, reject, sum } from 'lodash';
import { formatMinutesAsHours } from 'lib/helpers';
import { Moment } from 'moment';

export const calculateDuration = <
  T extends { duration: number; start: Moment }
>(
  collection: T[],
  dayLimit: Moment
): { time: string; quantity: number } => {
  const filteredCollection = reject(collection, ({ start }) =>
    start.isBefore(dayLimit, 'day')
  );
  const durations: number[] = map(
    filteredCollection,
    ({ duration }: T) => duration
  );
  const durationSum = sum(durations);
  return {
    time: formatMinutesAsHours(durationSum),
    quantity: filteredCollection.length,
  };
};
