import React from 'react';
import { Theme } from 'extended-oxygen-elements';
import {
  createStyles,
  WithStyles,
  withStyles,
} from '@mui/styles';
import clsx from 'clsx';
import { RosterViewFilters } from '../RosterViewFilters';
import { RosterToggleView } from '../RosterToggleView';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    switcher: {
      display: 'flex',
      marginRight: theme.spacing(2),
      // TODO needs to be implemented in child component
      '& .toggle-view__wrapper': {
        [theme.breakpoints.up('md')]: {
          marginRight: theme.spacing(1),
        },
      },
      '& #toggle-roster': {
        marginRight: '0!important',
        '& label': {
          width: 73,
        },
      },
      '& #Switcher': {
        '& label': {
          width: 120,
        },
      },
      '& .toggle-view--radio-buttons': {
        marginRight: 0,
      },
      '& .elmo-radio:last-child': {
        marginRight: '0!important',
      },
    },
    filters: {
      marginBottom: theme.spacing(3),
    },
    filtersCheckboxSelect: {}
  });

type RosterViewControlsProps = React.ComponentProps<typeof RosterToggleView> &
  WithStyles<typeof styles> & {
    className?: string;
  };

export const RosterViewControls = withStyles(styles)(
  ({ className, classes, ...toggleViewProps }: RosterViewControlsProps) => (
    <div className={clsx(classes.root, className)}>
      <div className={classes.switcher}>
        <RosterToggleView {...toggleViewProps} />
      </div>

      <RosterViewFilters
        classes={{
          root: classes.filters,
          checkboxSelect: classes.filtersCheckboxSelect
        }}
      />
    </div>
  )
);
