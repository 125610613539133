import { createAction } from 'lib/store-utils';
import { FormattedErrors } from 'type';
import {
  ConfirmForceModalPayload,
  CreateShiftEndPayload,
  CreateShiftStartPayload,
  DragAndDropSiteViewFinishedPayload,
  DragAndDropStartedPayload,
  DragAndDropUserViewFinishedPayload,
  RosterDayViewCreatedShiftData,
  RowSetQuantityPayload,
  SiteViewAddRowPayload,
  SiteViewSetRowPayload,
  UpdateShiftTimePayload
} from './types';

export const BOX_ROSTER_DAY_VIEW_CREATE_SHIFT_START = createAction<CreateShiftStartPayload>('Roster day view / create shift start');
export const BOX_ROSTER_DAY_VIEW_CREATE_SHIFT_END = createAction<CreateShiftEndPayload>('Roster day view / create shift end');
export const BOX_ROSTER_DAY_VIEW_CREATE_SHIFT_SET_DATA = createAction<Partial<RosterDayViewCreatedShiftData>>('Roster day view / create shift set data');

export const BOX_ROSTER_DAY_VIEW_SITE_VIEW_ROW_ADD = createAction<SiteViewAddRowPayload>('Roster day view / site view add row');
export const BOX_ROSTER_DAY_VIEW_SITE_VIEW_ROW_SET = createAction<SiteViewSetRowPayload>('Roster day view / site view set row');
export const BOX_ROSTER_DAY_VIEW_SITE_VIEW_ROW_CLEAR = createAction('Roster day view / site view clear rows');

export const BOX_ROSTER_DAY_VIEW_EVENTS_ROW_ADD = createAction('Roster day view / events / add row');
export const BOX_ROSTER_DAY_VIEW_EVENTS_ROW_SET_QUANTITY = createAction<RowSetQuantityPayload>('Roster day view / events / set additional rows quantity');
export const BOX_ROSTER_DAY_VIEW_EVENTS_ROW_CLEAR = createAction('Roster day view / events / clear additional rows');

export const BOX_ROSTER_DAY_VIEW_UNASSIGNED_ROW_ADD = createAction('Roster day view / unassigned / add row');
export const BOX_ROSTER_DAY_VIEW_UNASSIGNED_ROW_SET_QUANTITY = createAction<RowSetQuantityPayload>('Roster day view / unassigned / set additional rows quantity');
export const BOX_ROSTER_DAY_VIEW_UNASSIGNED_ROW_CLEAR = createAction('Roster day view / unassigned / clear additional rows');

export const BOX_ROSTER_DAY_VIEW_UPDATE_ROSTERED_SHIFT_TIME_REQUEST = createAction<UpdateShiftTimePayload>('Roster day view / update rostered shift time request');
export const BOX_ROSTER_DAY_VIEW_UPDATE_TIMESHEET_TIME_REQUEST = createAction<UpdateShiftTimePayload>('Roster day view / update timesheet time request');

export const BOX_ROSTER_DAY_VIEW_ERROR_MODAL_OPEN = createAction<FormattedErrors>('Roster day view / error modal open');
export const BOX_ROSTER_DAY_VIEW_ERROR_MODAL_CLOSE = createAction('Roster day view / error modal close');

export const BOX_ROSTER_DAY_VIEW_CONFIRM_FORCE_MODAL_OPEN = createAction<ConfirmForceModalPayload>('Roster day view / confirm force modal open');
export const BOX_ROSTER_DAY_VIEW_CONFIRM_FORCE_MODAL_SUBMIT = createAction('Roster day view / confirm force modal submit');
export const BOX_ROSTER_DAY_VIEW_CONFIRM_FORCE_MODAL_CLOSE = createAction('Roster day view / confirm force modal close');

export const BOX_ROSTER_DAY_VIEW_DRAG_AND_DROP_STARTED = createAction<DragAndDropStartedPayload>('Roster day view / drag and drop started');
export const BOX_ROSTER_DAY_VIEW_DRAG_AND_DROP_USER_VIEW_FINISHED = createAction<DragAndDropUserViewFinishedPayload>('Roster day view / drag and drop finished / user view');
export const BOX_ROSTER_DAY_VIEW_DRAG_AND_DROP_SITE_VIEW_FINISHED = createAction<DragAndDropSiteViewFinishedPayload>('Roster day view / drag and drop finished / site view');
