import React, { ReactNode } from 'react';
import MessageBlockHeader from './component/MessageBlockHeader';
import MessageBlockBody from './component/MessageBlockBody';
import './MessageBlock.scss';

export type MessageBlockProps = {
  /** The content of the message block. Should contain a Header and/or a Body. */
  children: ReactNode;
  id?: string;
};

function MessageBlock({ children, id }: MessageBlockProps) {
  return (
    <div
      className="elmo-message-block"
      data-testid={`elmo-message-block-${id || 'default'}`}
    >
      {children}
    </div>
  );
}

MessageBlock.Header = MessageBlockHeader;
MessageBlock.Body = MessageBlockBody;

export default MessageBlock;
