import { useSiteIdValidator } from 'hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import { firstSiteIdSelector } from 'state/AccountTree';
import { getUserSettingsDefaultSiteId } from 'state/Auth';
import QueryItemProvider from './QueryItemProvider';

type Props = {
  children: React.ReactNode;
};

export default QueryRosterSiteIdProvider;

export function QueryRosterSiteIdProvider({
  children,
}: Props) {
  const siteIdValidator = useSiteIdValidator();

  const firstSiteId = useSelector(firstSiteIdSelector);
  const userDefaultSiteId = useSelector(getUserSettingsDefaultSiteId);
  const defaultSiteId = siteIdValidator(userDefaultSiteId)
    ? userDefaultSiteId
    : firstSiteId;

  return (
    <QueryItemProvider
      name="site_id"
      default={defaultSiteId}
      validator={siteIdValidator}
    >
      {children}
    </QueryItemProvider>
  );
}
