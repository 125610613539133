import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSelectorWithProps } from 'hooks';
import { hasPermissionSelector } from 'state/Auth';
import {
  BOX_ROSTER_OPEN_DELETE_CONFIRMATION_MODAL,
  getIsApprovalModeEnabled,
} from 'state/Roster/Roster';

type Props = {
  id: string;
};
const useHandleClickDeleteIcon = ({
  id,
}: Props): React.MouseEventHandler<HTMLElement> | undefined => {
  const dispatch = useDispatch();
  const canEditShift = useSelectorWithProps(
    hasPermissionSelector,
    'roster.rosteredshift.edit'
  );
  const isApprovalMode = useSelector(getIsApprovalModeEnabled);

  const canDeleteShift = canEditShift && !isApprovalMode;

  const handleClickDeleteIcon = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();

      dispatch(
        BOX_ROSTER_OPEN_DELETE_CONFIRMATION_MODAL({
          id,
          type: 'rostered_shift',
        })
      );
    },
    [dispatch, id]
  );

  if (canDeleteShift) {
    return handleClickDeleteIcon;
  }
};

export default useHandleClickDeleteIcon;
