import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import { getCurrentTime } from 'state/CurrentTime';
import { userListSelector } from 'state/UsersCollection';
import { getSites } from 'state/AccountTree';
import { getLangPreferences } from 'state/Account';
import {
  currentUserSiteIdsSelector,
  getCurrentUserId,
  hasPermissionSelector
} from 'state/Auth';
import {
  applicableUsersSelectOptionsSelector,
  BOX_UNAVAILABILITY_GET_APPLICABLE_USERS, BOX_UNAVAILABILITY_MODAL_CLEAR_ERRORS
} from 'state/ManagerDashboard/Unavailability';
import {
  DispatchProps,
  OwnProps,
  Props,
  StateProps,
  SubmitPayload
} from './types';
import View from './view';

const UnavailabilityCreateEditModalComponent = (props: Props) => (
  <View {...props} />
);

const mapStateToProps = (state: StoreState): StateProps => ({
  currentUserId: getCurrentUserId(state),
  currentUserSiteIds: currentUserSiteIdsSelector(state),
  sites: getSites(state),
  users: userListSelector(state),
  langPreferences: getLangPreferences(state),
  currentTime: getCurrentTime(state),
  canViewAllSites: hasPermissionSelector(state, 'account.viewallsites'),
  userOptions: applicableUsersSelectOptionsSelector(state)
});

const mapDispatchToProps: DispatchProps = {
  getApplicableUsers: BOX_UNAVAILABILITY_GET_APPLICABLE_USERS,
  clearErrors: BOX_UNAVAILABILITY_MODAL_CLEAR_ERRORS
};

export const UnavailabilityCreateEditModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnavailabilityCreateEditModalComponent);

export type UnavailabilityCreateEditModalProps = OwnProps;
export type UnavailabilityCreateEditModalSubmitPayload = SubmitPayload;
