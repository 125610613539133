import {
  CheckIcon,
  DeleteOutlinedIcon,
  DurationField,
  FormattedDuration,
} from 'element';
import _ from 'lodash';
import { Dictionary } from 'ts-essentials';
import { DefaultBreakItemCondition, Minutes } from 'type';
import BreakRow from '../BreakRow';
import { DefaultBreaksFieldGroupBreak } from './types';
import { IconButton, Box } from 'extended-oxygen-elements';
import React from 'react';

type Props = {
  value: DefaultBreaksFieldGroupBreak;
  onChange: (rule: DefaultBreaksFieldGroupBreak) => void;
  labelVisible: boolean;
  error: string | undefined;
  index: number;
  removeBreak: (rule: DefaultBreaksFieldGroupBreak) => void;
};

const conditionLabels: Dictionary<string, DefaultBreakItemCondition> = {
  '<=': 'up to',
  '>': 'over',
};

export default function EditableDefaultBreakRule({
  value,
  onChange,
  labelVisible,
  error,
  index,
  removeBreak,
}: Props) {
  const handleValueChange = (break_duration: Minutes | null) => {
    if (break_duration !== null) {
      onChange({
        ...value,
        break_duration,
      });
    }
  };

  const breakRowId = _.kebabCase(
    `default break rule ${conditionLabels[value.condition]} ${
      value.shift_duration
    }`
  );

  return (
    <BreakRow>
      <BreakRow.Label id={breakRowId}>
        Shifts {conditionLabels[value.condition]}{' '}
        <FormattedDuration hoursPrefix="hrs" minutesPrefix="min">
          {value.shift_duration}
        </FormattedDuration>{' '}
        long
      </BreakRow.Label>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: '100%',
          position: 'relative',
          '& > div': {
            width: '100%',
          },
          '@media (min-width: 600px)': {
            maxWidth: '240px',
            marginLeft: '20px',
            '& > div': {
              width: '246px',
            },
          },
        }}
      >
        <BreakRow.Field label={labelVisible ? 'Break duration' : undefined}>
          <DurationField
            sx={{
              '@media (min-width: 600px)': {
                maxWidth: '200px',
              },
            }}
            margin="none"
            value={value.break_duration}
            onValueChange={handleValueChange}
            error={!!error}
            helperText={error}
            inputProps={{
              'aria-labelledby': breakRowId,
            }}
          />
        </BreakRow.Field>
        <IconButton
          data-testid={'remove-break-button'}
          sx={{
            marginLeft: '10px',
            marginTop: '3px',
            position: 'absolute',
            color: 'rgb(178, 61, 65)',
          }}
          onClick={(e) => removeBreak(value)}
        >
          <DeleteOutlinedIcon />
        </IconButton>
      </Box>
    </BreakRow>
  );
}
