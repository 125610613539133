import { StoreState } from '../types';
import { createSelector } from 'reselect';
import { flatMap, map } from 'lodash';
import { groupBy as fpGroupBy } from 'lodash/fp';
import {
  RosterRepeatableTimeOff,
  RosterTimeOff,
  StringMap,
  TimeOffRepeat,
} from 'type';
import { Moment } from 'moment';
import { moment } from 'lib/momentWithRange';
import { fromToBySiteTimezoneSelectorsCreator } from '../selectors';

const getState = (state: StoreState) => state.timeOffs;
export const getRepeatableTimeOffs = (state: StoreState) =>
  getState(state).timeOffs;
export const getSiteId = (state: StoreState) => getState(state).site_id;
export const getFrom = (state: StoreState) => getState(state).from;
export const getTo = (state: StoreState) => getState(state).to;

const { fromBySiteTimezoneSelector, toBySiteTimezoneSelector } =
  fromToBySiteTimezoneSelectorsCreator(getState);

const repeatDurationsMs: { [key in TimeOffRepeat]: number } = {
  once: 0,
  daily: moment.duration(1, 'day').asMilliseconds(),
  weekly: moment.duration(1, 'week').asMilliseconds(),
  fortnightly: moment.duration(2, 'weeks').asMilliseconds(),
  four_weekly: moment.duration(4, 'weeks').asMilliseconds(),
};

export const timeOffsArraySelector = createSelector(
  getRepeatableTimeOffs,
  fromBySiteTimezoneSelector,
  toBySiteTimezoneSelector,
  (
    repeatableTimeOffs: StringMap<RosterRepeatableTimeOff>,
    from: Moment,
    to: Moment
  ): RosterTimeOff[] => {
    const timeOffsWithoutRepeat = flatMap(
      repeatableTimeOffs,
      ({
        repeat,
        until,
        ...restTimeOff
      }: RosterRepeatableTimeOff): RosterTimeOff[] => {
        const timeOffDurationMs: number = restTimeOff.end.diff(
          restTimeOff.start
        );

        if (restTimeOff.type === 'rostered_shift') {
          return [restTimeOff];
        }

        if (repeat === 'once') {
          return [restTimeOff];
        }

        const repeatDurationMs: number = repeatDurationsMs[repeat];
        if (timeOffDurationMs < repeatDurationMs) {
          const startFromDiff = from.diff(restTimeOff.start);
          const repeatNum = Math.floor(startFromDiff / repeatDurationMs);
          const repeatsQuantityBeforeFrom = repeatNum < 0 ? 0 : repeatNum;

          const timeOffs: RosterTimeOff[] = [];

          let start: Moment = restTimeOff.start
            .clone()
            .add(repeatsQuantityBeforeFrom * repeatDurationMs);
          let end: Moment = restTimeOff.end
            .clone()
            .add(repeatsQuantityBeforeFrom * repeatDurationMs);

          while (start.isSameOrBefore(to) && end.isSameOrBefore(until!)) {
            timeOffs.push({
              ...restTimeOff,
              start,
              end,
            });

            start = start.clone().add(repeatDurationMs);
            end = end.clone().add(repeatDurationMs);
          }

          return timeOffs;
        }

        return [
          {
            ...restTimeOff,
            end: until!.clone(),
          },
        ];
      }
    );

    const fromToRange = moment.range(from, to);
    return timeOffsWithoutRepeat.filter((timeOff) => {
      const timeOffRange = moment.range(timeOff.start, timeOff.end);

      return fromToRange.overlaps(timeOffRange);
    });
  }
);

export const timeOffsByUserIdSelector = createSelector<
  StoreState,
  RosterTimeOff[],
  StringMap<RosterTimeOff[]>
>(
  timeOffsArraySelector,
  fpGroupBy((timeOff: RosterTimeOff) => timeOff.user_id)
);

export const timeOffById = (state: StoreState, id: string) =>
  getState(state).timeOffs[id];
export const timeOffsArrayById = (state: StoreState, ids: string[]) => {
  const timeOffs = [];
  for (let i = 0; i < ids.length; i++) {
    const timeOff = getState(state).timeOffs[ids[i]];
    if (timeOff) {
      timeOffs.push(timeOff);
    }
  }
  return timeOffs;
};

export const isTimeoffUpdating = (state: StoreState) =>
  getState(state).isUpdating;
export const getTimeoffErrors = (state: StoreState) => getState(state).errors;
