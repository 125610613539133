import { withHeading } from 'element';
import Preferences from 'page/Preferences';

export const preferences = {
  path: '/app/preferences',
  permission: 'user.preferences' as 'user.preferences',
  routes: {
    preferences: {
      path: '/app/preferences',
      component: withHeading(Preferences, {
        title: 'Preferences'
      })
    }
  }
};
