import { Button, Box } from 'extended-oxygen-elements';
import React from 'react';

export type SettingsSaveBtnProps = {
  loading: boolean;
};

export default SettingsSaveBtn;

export function SettingsSaveBtn({ loading }: SettingsSaveBtnProps) {
  return (
    <Box mt={4}>
      <Button type="submit" variant="contained" loading={loading}>
        Save
      </Button>
    </Box>
  );
}
