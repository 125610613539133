import React, { Component, ReactNode } from 'react';
import { RosteredShift } from 'type';
import {
  CalendarTodayIcon,
  TableChartOutlinedIcon,
  HelpOutlineIcon,
} from 'element';
import { RosterUserLegend, RosterRow } from '../../../../components';
import { Text } from 'elmo-elements';
import {
  filtersByTypeSelector,
  rosteredShiftsFilteredSelector,
  RosterFiltersByType
} from 'state/Roster/RosterFilters';
import { StoreState } from '../../../../../../state/types';
import { connect } from 'react-redux';
import { getDurationsInfo } from '../../../../helpers';
import { makeDataItemSelector } from '../../../../../../state/Roster/RosterWeekViewUserView';
import { userUnassignedRosterDataSelector } from '../../../../../../state/Roster/RosterWeekView';
import { getSelectedByUser } from '../../../../../../state/Roster/BulkActions/selectors';
import { BulkSelectionPropsItem } from '../../../../../../state/Roster/BulkActions/types';
import { getCovidPointedShiftId, getIsCovidModeEnabled } from '../../../../../../state/Roster/CovidTracing/selectors';

type InfoType = {
  quantity: number;
  time: string;
  type: string;
};

type Duration = {
  quantity: number;
  time: string;
  icon: ReactNode;
  isVisible: boolean;
};

type OwnProps = {
  isCollapsed?: boolean;
  children: React.ReactNode;
  isSelected?: boolean;
  rowIndex?: number;
};

type StateProps = {
  filteredRosters: RosteredShift[];
  selectedFilter: RosterFiltersByType;
  rowData: any;
  selectedByUser: BulkSelectionPropsItem;
  isCovidMode: boolean;
  pointedId: string | null;
};

type Props = OwnProps & StateProps;

export class UnassignedRowWithLegend extends Component<Props> {

  render() {
    const { children, rowIndex, isCollapsed, selectedFilter, selectedByUser, isCovidMode, pointedId } = this.props;
    if ( !selectedFilter.roster ) {
      return null;
    }
    return (
      <RosterRow
        isCollapsed={ isCollapsed }
        isSelected={ selectedByUser['null'] === true }
        rowIndex={ rowIndex }
        className={isCovidMode && pointedId ? 'no-contacted-shifts' : ''}
      >
        {{
          sticky: this.getUserLegend(),
          content: (
            <div className={`week-rosters-grid spec-cell-height`}>
              {children}
            </div>
          )
        }}
      </RosterRow>
    );
  }

  getUserLegend = () => {
    const data = this.getUnassignedLegendData();
    const durations = this.getDurations();
    return (
      <RosterUserLegend
        type={'always-single-row'}
        label={data.label}
        icon={data.icon}
        durations={durations}
        onClick={() => false}
        isDisabled={false}
        bulkSelect={true}
        userId={null}
        pageType={'week'}
      />
    );
  }

  getDurations = () => {
    const { selectedFilter, rowData, filteredRosters } = this.props;
    const duration =  getDurationsInfo(rowData.rosters, filteredRosters, [], []);
    const getVisibilityProp = (legendType: string) => {
      return legendType === 'roster' && selectedFilter.roster;
    };
    const r: Duration[] = [];
    duration.forEach((d: InfoType) => {
      r.push({
        quantity: d.quantity,
        time: d.time,
        icon: d.type === 'roster' ? <CalendarTodayIcon/> : <TableChartOutlinedIcon/>,
        isVisible: getVisibilityProp(d.type)
      });
    });
    return r;
  }

  getUnassignedLegendData = () => {
    return {
      icon: <Text color={'warning'}><HelpOutlineIcon /></Text>,
      label: 'Unassigned'
    };
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  filteredRosters: rosteredShiftsFilteredSelector(state),
  selectedFilter: filtersByTypeSelector(state),
  rowData: userUnassignedRosterDataSelector(state),
  selectedByUser: getSelectedByUser(state),
  isCovidMode: getIsCovidModeEnabled(state),
  pointedId: getCovidPointedShiftId(state)
});

export default connect(mapStateToProps)(UnassignedRowWithLegend);
