import React from 'react';
import { Card, withLayoutAware } from 'elmo-elements';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import moment from 'moment';
import { Props, State } from './type';
import { getDateTimeFormatted } from 'lib/helpers';
import { HorizontalBar } from 'element';
import { getDateFormat, getTimeFormat } from 'state/Account';
import { PreferencesDateFormat } from 'type/models';
import { getOverview } from 'state/EmployeeDashboard/Overview';
import { currentUserMainTimezoneSelector } from 'state/Auth';
import { isAppMarket } from '../../../../../../helpers';

export class HoursComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isDay: true,
      series: this.series[props.type],
      title: props.type === 'day' ? "Today's Hours" : 'This week Hours',
      subTitle: this.subTitle(props.dateFormat),
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.dateFormat !== prevProps.dateFormat) {
      this.setState({
        ...this.state,
        subTitle: this.subTitle(this.props.dateFormat),
      });
    }
    if (
      this.props.overview !== prevProps.overview ||
      this.props.type !== prevProps.type
    ) {
      this.setState({
        isDay: this.props.type === 'day',
        series: this.series[this.props.type],
        title: this.props.type === 'day' ? "Today's Hours" : 'This week Hours',
        subTitle: this.subTitle(this.props.dateFormat),
      });
    }
  }

  subTitle = (dateFormat: PreferencesDateFormat) => {
    return this.props.type === 'day'
      ? getDateTimeFormatted(
          dateFormat,
          this.props.timeFormat,
          moment().tz(this.props.timezone),
          false
        )
      : this.weekDays;
  };

  render() {
    const chartId = this.props.type
      ? `overview-hours-chart-${this.props.type}`
      : 'overview-hours-chart';

    return (
      <Card
        heading={
          <>
            <p>{this.state.title}</p>
            <span>{this.state.subTitle}</span>
          </>
        }
      >
        <HorizontalBar id={chartId} data={this.state.series} />
      </Card>
    );
  }

  get weekDays() {
    const { dateFormat, timeFormat } = this.props;
    const start = getDateTimeFormatted(
      dateFormat,
      timeFormat,
      moment().startOf('isoWeek'),
      false
    );
    const end = getDateTimeFormatted(
      dateFormat,
      timeFormat,
      moment().endOf('isoWeek'),
      false
    );
    return `${start} - ${end}`;
  }

  get series() {
    const { today, week } = this.props.overview;
    return {
      day: [
        {
          y: today.planned,
          name: isAppMarket('uk') ? 'Scheduled' : 'Rostered',
          color: '#236fc8',
        },
        {
          y: today.timesheet,
          name: 'Timesheet',
          color: '#063f79',
          inprogress: !today.is_complete,
          className: !today.is_complete ? 'chart-incomplete' : '',
        },
      ],
      week: [
        {
          y: week.planned,
          name: isAppMarket('uk') ? 'Scheduled' : 'Rostered',
          color: '#236fc8',
        },
        {
          y: week.timesheet,
          name: 'Timesheet',
          color: '#063f79',
          inprogress: !week.is_complete,
          className: !week.is_complete ? 'chart-incomplete' : '',
        },
      ],
    };
  }
}

const mapStateToProps = (state: StoreState) => ({
  overview: getOverview(state),
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
  timezone: currentUserMainTimezoneSelector(state),
});

export const Hours = connect(mapStateToProps)(withLayoutAware(HoursComponent));
