import * as React from 'react';
import { Heading } from 'elmo-elements';

type Props = {
  title: string;
  className?: string;
};

export const SectionHeading = ({ title, className }: Props) => (
  <div className={className}>
    <Heading isBold={true}>{title}</Heading>
  </div>
);
