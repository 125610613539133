import React from 'react';
import DataTableTh from './DataTableTh';
import Checkbox from '../../Checkbox';
import { withDataTableContext } from '../DataTableContextConsumer';

type DataTableSelectAllCheckboxProps = {
  /** Called when the Select All Checkbox is toggled */
  onSelectAllVisibleToggle: (isSelect: boolean) => void;
  /** @ignore Comes from DataTable Context. */
  setContextState?: (state: any) => void;
  /** @ignore Comes from DataTable Context. */
  isSelectAllChecked?: boolean;
  /** @ignore Comes from DataTable Context. */
  isSelectAllIndeterminate?: boolean;
};

class DataTableSelectAllCheckbox extends React.Component<DataTableSelectAllCheckboxProps> {
  /**
   * Called when Select All checkbox is toggled
   */
  handleSelectAllToggle = () => {
    const { isSelectAllChecked, setContextState, onSelectAllVisibleToggle } =
      this.props;

    let isChecked = !isSelectAllChecked;

    if (setContextState) {
      setContextState({
        isSelectAllChecked: isChecked,
        isSelectAllIndeterminate: false,
        showSelectAllComponent: isChecked,
      });
    }

    if (onSelectAllVisibleToggle) {
      onSelectAllVisibleToggle(isChecked);
    }
  };

  render() {
    const { isSelectAllChecked, isSelectAllIndeterminate } = this.props;

    return (
      <DataTableTh className="elmo-datatable__row-checkbox">
        <Checkbox
          id="datatableSelectAll"
          isChecked={isSelectAllChecked}
          isIndeterminate={isSelectAllIndeterminate}
          onChange={this.handleSelectAllToggle}
          ariaLabel="Select row"
        />
      </DataTableTh>
    );
  }
}

export default withDataTableContext(DataTableSelectAllCheckbox);
