import React from 'react';
import { connect } from 'react-redux';
import { TimeOffsWithPagerSelector } from 'type';
import { Pager } from 'element';
import { StoreState } from 'state/types';
import {
  BOX_UNAVAILABILITY_CHANGE_PAGE_REQUEST,
  BOX_UNAVAILABILITY_CHANGE_PAGE_SIZE_REQUEST,
  getIsUpdating,
  timeOffsWithPagerSelector
} from 'state/ManagerDashboard/Unavailability';

type StateProps = {
  data: TimeOffsWithPagerSelector;
  isUpdating: boolean;
};
type DispatchProps = {
  changePage: (pageNumber: number) => void;
  changePageSize: (pageSize: number) => void;
};
type Props = StateProps & DispatchProps;
const UnavailabilityPagerComponent = (props: Props) => <Pager {...props} />;

const mapStateToProps = (state: StoreState): StateProps => ({
  data: timeOffsWithPagerSelector(state),
  isUpdating: getIsUpdating(state)
});

const mapDispatchToProps: DispatchProps = {
  changePage: BOX_UNAVAILABILITY_CHANGE_PAGE_REQUEST,
  changePageSize: BOX_UNAVAILABILITY_CHANGE_PAGE_SIZE_REQUEST
};

export const UnavailabilityPager = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnavailabilityPagerComponent);
