import React from 'react';
import { Merge } from 'ts-essentials';
import { SvgIconProps } from 'extended-oxygen-elements';
import { createStyles, makeStyles } from '@mui/styles';
import { IconButton, IconButtonProps } from 'extended-oxygen-elements';

type IconWrapperProps = Merge<
  IconButtonProps,
  {
    children: React.ReactElement<SvgIconProps>;
  }
>;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      cursor: 'default',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  })
);

export function IconWrapper({ children, ...restProps }: IconWrapperProps) {
  const classes = useStyles();

  return (
    <IconButton
      component="span"
      disableRipple
      classes={classes}
      {...restProps}
    >
      {React.isValidElement(children) &&
        React.cloneElement<SvgIconProps>(children, { fontSize: 'inherit' })}
    </IconButton>
  );
}
