import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { Overview } from 'type';
import Api, {
  EmployeeDashboardGetMyRostersListResponse,
  EmployeeDashboardGetMyRostersRequest,
} from 'lib/Api';
import { SERVER_DAY_FORMAT } from 'lib/config';
import * as fetchPageData from 'state/FetchPageData';
import { getFetchFlags } from 'state/FetchPageData';
import * as actions from './actions';
import moment from 'moment';
import { processApiRequest } from 'state/ProcessApiRequest';

export const getOverviewShifts = function* (): SagaIterator {
  try {
    const apiPayload: EmployeeDashboardGetMyRostersRequest = {
      period: {
        period: 'custom' as const,
        from: moment().format(SERVER_DAY_FORMAT),
        to: moment().add(7, 'days').format(SERVER_DAY_FORMAT),
      },
      page: 1,
      per_page: 1000,
      order_by: 'start',
      order_dir: 'asc',
      location: null,
      statuses: null,
    };

    const shifts: EmployeeDashboardGetMyRostersListResponse = yield call(
      processApiRequest,
      Api.EmployeeDashboard.getMyRosters,
      apiPayload
    );

    yield put(actions.BOX_EMPLOYEE_OVERVIEW_GET_SHIFTS_SUCCESS(shifts));
  } catch (error) {
    yield put(actions.BOX_EMPLOYEE_OVERVIEW_GET_SHIFTS_FAILURE(error));
  }
};

export const getOverviewRequest = function*(): SagaIterator {
  yield put(fetchPageData.BOX_FETCH_PAGE_DATA_REQUEST(fetchPageData.EMPLOYEE_DASHBOARD_OVERVIEW));
  const [overview]: [Overview] = yield all([
    call(
      processApiRequest,
      Api.EmployeeDashboard.getOverview
    ),
    call(getOverviewShifts)
  ]);

  yield put(actions.BOX_EMPLOYEE_OVERVIEW_GET_OVERVIEW_SUCCESS(overview));
  yield put(fetchPageData.BOX_FETCH_PAGE_DATA_SUCCESS(fetchPageData.EMPLOYEE_DASHBOARD_OVERVIEW));
};

const isFetched = (params: ReturnType<typeof getFetchFlags>): boolean => {
  return params.isFetched && !params.isFetching;
};

export const getOverviewData = function*(): SagaIterator {

  const overviewFlag: ReturnType<typeof getFetchFlags> = yield select(
    getFetchFlags,
    'EMPLOYEE_DASHBOARD_OVERVIEW'
  );

  if (isFetched(overviewFlag)) {
    const [overview]: [Overview] = yield all([
      call(
        processApiRequest,
        Api.EmployeeDashboard.getOverview
      ),
      call(getOverviewShifts)
    ]);
    yield put(actions.BOX_EMPLOYEE_OVERVIEW_GET_SUCCESS(overview));
  }
};

export const watchEmployeeOverview = function*(): SagaIterator {
  yield takeLatest(actions.BOX_EMPLOYEE_OVERVIEW_DATA_REQUEST, getOverviewRequest);
  yield takeLatest(actions.BOX_EMPLOYEE_OVERVIEW_GET_SHIFTS_REQUEST, getOverviewShifts);
  yield takeLatest(actions.BOX_EMPLOYEE_OVERVIEW_GET_REQUEST, getOverviewData);
};
