import {
  DialogActions,
  DialogContent,
  DialogContentText,
} from 'extended-oxygen-elements';
import { useRoute } from 'hooks';
import { capitalize } from 'lib/helpers';
import marketLabels from 'marketLabels';
import { confirmationModalBtnOpenLabel } from 'messages';
import { Button, Dialog, DialogTitle } from 'extended-oxygen-elements';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BOX_MAIN_MENU_HELP_ITEM_CONFIRMATION_CLOSED,
  getIsConfirmationOpen,
} from 'state/MainMenuHelpItem';

export default HelpMenuItemConfirmationDialog;

export function HelpMenuItemConfirmationDialog() {
  const dispatch = useDispatch();
  const isDialogOpen = useSelector(getIsConfirmationOpen);
  const route = useRoute();

  const closeDialog = () => {
    dispatch(BOX_MAIN_MENU_HELP_ITEM_CONFIRMATION_CLOSED());
  };

  const handleConfirmButtonCLick = () => {
    route.openInNewTab(process.env.REACT_APP_HELP_URL as string)?.focus();

    closeDialog();
  };

  return (
    <Dialog maxWidth="xs" open={isDialogOpen} onClose={closeDialog}>
      <DialogTitle>Opening help?</DialogTitle>

      <DialogContent>
        <DialogContentText>
          You can find a number of help articles in how to use Breathe{' '}
          {capitalize(marketLabels.roster)}. This link will open the knowledge
          base in a new browser tab.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeDialog}>
          Cancel
        </Button>

        <Button variant="contained" onClick={handleConfirmButtonCLick}>
          {confirmationModalBtnOpenLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
