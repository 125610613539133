import { createReducer } from 'lib/store-utils';
import { EventsReducerState } from './types';
import { defaultFilters, getDefaultState } from './state';
import * as actions from './actions';
import { cloneDeep } from 'lodash';
import { BOX_EVENTS_UPDATED, BOX_EVENTS_DELETE_ONE } from '../../Events/actions';
import { Event } from 'type/models';

export const events = createReducer<EventsReducerState>({}, getDefaultState());

events.on(actions.BOX_EVENTS_DATA_REQUEST, getDefaultState);

events.on(actions.BOX_EVENTS_GET_EVENTS_SUCCESS,  (state,  { pager, data }) => ({
  ...state,
  events: data,
  isFetched: true,
  pager
}));

events.on(BOX_EVENTS_UPDATED, (state, event): EventsReducerState => {
  return {
    ...state,
    events: state.events.map( (ev: Event) => {
      return ev.id === event.id ? event : ev;
    }).filter( ev => ev.type === 'event'),
    pager: {
      ...state.pager,
      total: event.type === 'event' ? state.pager.total : state.pager.total - 1
    }
  }
});

events.on(BOX_EVENTS_DELETE_ONE, (state, eventId): EventsReducerState => {
  return {
    ...state,
    events: state.events.filter( event => event.id !== eventId),
    pager: {
      ...state.pager,
      total: state.pager.total - 1
    }
  }
});

events.on(
  actions.BOX_EVENTS_UPDATE_ORDERING_REQUEST,
  (state, { name, value }): EventsReducerState => ({
    ...state,
    isFetching: true,
    filters: {
      ...state.filters,
      [name]: value
    }
  })
);

events.on(
  actions.BOX_EVENTS_UPDATE_ORDERING_SUCCESS,
  (state, { data, pager }): EventsReducerState => ({
    ...state,
    isFetching: false,
    events: data,
    pager,
  })
);

events.on(
  actions.BOX_EVENTS_CHANGE_PAGE_REQUEST,
  (state): EventsReducerState => ({
    ...state,
    isFetching: true,
  })
);

events.on(
  actions.BOX_EVENTS_CHANGE_PAGE_SUCCESS,
  (state, { data, pager }): EventsReducerState => ({
    ...state,
    isFetching: false,
    events: data,
    pager
  })
);

events.on(
  actions.BOX_EVENTS_CHANGE_PAGE_SIZE_REQUEST,
  (state): EventsReducerState => ({
    ...state,
    isFetching: true,
  })
);

events.on(
  actions.BOX_EVENTS_CHANGE_PAGE_SIZE_SUCCESS,
  (state, { data, pager }): EventsReducerState => ({
    ...state,
    isFetching: false,
    events: data,
    pager
  })
);

events.on(
  actions.BOX_EVENTS_TOGGLE_PERIOD_TYPE_REQUEST,
  (state, period): EventsReducerState => ({
    ...state,
    isFetching: true,
    filters: {
      ...cloneDeep(defaultFilters)
    },
    period
  })
);

events.on(
  actions.BOX_EVENTS_TOGGLE_PERIOD_TYPE_SUCCESS,
  (state, { data, filters, pager }): EventsReducerState => ({
    ...state,
    isFetching: false,
    filters,
    pager,
    events: data,
  })
);

events.on(
  actions.BOX_EVENTS_TOGGLE_PERIOD_TYPE_FAILURE,
  (state): EventsReducerState => ({
    ...state,
    isFetching: false,
  })
);

events.on(
  actions.BOX_EVENTS_UPDATE_FORM_FILTERS,
  (state,  { name, value }): EventsReducerState => ({
    ...state,
    isFetching: false,
    filtersForm: {
      ...state.filtersForm,
      [name]: value
    }
  })
);

events.on(
  actions.BOX_EVENTS_UPDATE_FILTERS_REQUEST,
  (state): EventsReducerState => ({
    ...state,
    isFetching: true,
    filters: {
      ...state.filtersForm,
    },
    period: 'all'
  })
);

events.on(
  actions.BOX_EVENTS_UPDATE_FILTERS_SUCCESS,
  (state, {data, filters, pager}): EventsReducerState => ({
    ...state,
    isFetching: false,
    filters,
    pager,
    events: data,
  })
);

events.on(
  actions.BOX_EVENTS_UPDATE_FILTERS_FAILURE,
  (state, payload): EventsReducerState => ({
    ...state,
    isFetching: false,
    errors: payload
  })
);
events.on(
  actions.BOX_EVENTS_RESET_FILTERS_FORM,
  (state): EventsReducerState => ({
    ...state,
    filtersForm: cloneDeep(defaultFilters)
  })
);

events.on(
  actions.BOX_EVENTS_OPEN_MAP,
  (state, marker): EventsReducerState => ({
    ...state,
    mapModal: {
      isOpen: true,
      marker
    }
  })
);

events.on(
  actions.BOX_EVENTS_CLOSE_MAP,
  (state): EventsReducerState => ({
    ...state,
    mapModal: {
      ...state.mapModal,
      isOpen: false,
    }
  })
);

events.on(actions.BOX_MANAGER_EVENT_SET_EDITED_ID, (state, id): EventsReducerState => ({
  ...state,
  editedEventId: id
}));

events.on(actions.BOX_MANAGER_EVENT_DELETE_EDITED_ID, (state, id): EventsReducerState => ({
  ...state,
  editedEventId: null
}));

events.on(actions.BOX_MANAGER_EVENT_UPDATE, (state, event): EventsReducerState => ({
  ...state,
  events: state.events.map( ev => {
    return ev.id === event.id ? event : ev;
  })
}));
