import { createStyles, makeStyles } from '@mui/styles';
import {
  AppSwitcherIcon,
  ExpandMoreIcon,
  HrCoreIcon,
  TimesheetIcon,
} from 'element';
import {
  Button,
  Hidden,
  HiddenPropsBreakpoint,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuListProps as MuiMenuListProps,
  Visible,
} from 'extended-oxygen-elements';
import {
  bindMenu,
  bindTrigger,
  usePopupState,
  useSelectorWithProps,
} from 'hooks';
import marketLabels from 'marketLabels';
import React from 'react';
import { useSelector } from 'react-redux';
import { getAppSwitcher, getEmployeeCanEnablePunchClock } from 'state/Account';
import { hasPermissionSelector } from 'state/Auth';
import { getFlag } from '../../../../state/Flags';

export type AppSwitcherProps = {
  desktopBreakpoint: HiddenPropsBreakpoint;
};

interface MenuListProps extends Partial<MuiMenuListProps> {
  'data-testid': string;
}

const useButtonStyles = makeStyles(() =>
  createStyles({
    root: {
      minWidth: 250,
    },
    endIcon: {
      marginLeft: 'auto',
    },
  })
);

const useMenuStyles = makeStyles(() =>
  createStyles({
    list: {
      minWidth: 315,
    },
  })
);

export default function AppSwitcher({ desktopBreakpoint }: AppSwitcherProps) {
  const buttonClasses = useButtonStyles();
  const menuClasses = useMenuStyles();
  const popupState = usePopupState();

  const { using_rta } = useSelector(getAppSwitcher);

  const handleAnchorClick = () => {
    popupState.close();
  };

  return (
    <>
      <Visible up={desktopBreakpoint}>
        <Button
          {...bindTrigger(popupState)}
          classes={buttonClasses}
          size="medium"
          variant="outlined"
          startIcon={<HrCoreIcon />}
          endIcon={<ExpandMoreIcon />}
          data-testid="rosterHeader-appSwitcher-button"
        >
          {marketLabels.rta}
        </Button>
      </Visible>

      <Hidden up={desktopBreakpoint}>
        <IconButton
          {...bindTrigger(popupState)}
          color="primary"
          data-testid="rosterHeader-appSwitcher-iconButton"
        >
          <AppSwitcherIcon />
        </IconButton>
      </Hidden>

      <Menu
        {...bindMenu(popupState)}
        classes={menuClasses}
        placement="bottom-start"
        MenuListProps={
          {
            'data-testid': 'rosterHeader_appSwitcher_menu',
          } as MenuListProps
        }
      >
        <MenuItem
          component="a"
          href={process.env.REACT_APP_BREATHE_HR_URL}
          onClick={handleAnchorClick}
          data-testid="rosterHeader_appSwitcher_hrBtn"
        >
          <ListItemIcon>
            <HrCoreIcon color="primary" />
          </ListItemIcon>

          <ListItemText primary="HR" />
        </MenuItem>

        {using_rta && (
          <MenuItem
            selected
            disableRipple
            data-testid="rosterHeader_appSwitcher_rtaBtn"
          >
            <ListItemIcon>
              <TimesheetIcon color="primary" />
            </ListItemIcon>

            <ListItemText primary={marketLabels.rta} />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
