import { createReducer } from 'lib/store-utils';
import { UnavailabilityReducerState } from '../types';
import { getDefaultState } from '../state';
import * as actions from '../actions';
import { cloneDeep } from 'lodash';
import { FormattedErrors } from 'type';
import {
  closeModalHandlerCreator,
  failureHandler,
  modalFailureHandlerCreator,
  formModalRequestHandlerCreator,
  modalSuccessHandlerCreator,
  openConfirmModalHandlerCreator,
  requestHandler,
  successHandler,
  confirmModalRequestHandlerCreator,
  confirmModalFailureHandlerCreator
} from './handlers';

export const unavailability = createReducer<UnavailabilityReducerState>(
  {},
  getDefaultState()
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_GET_PAGE_DATA_REQUEST,
  getDefaultState
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_GET_UNAVAILABILITY_LIST_SUCCESS,
  (state, { data, pager }): UnavailabilityReducerState => ({
    ...state,
    timeOffsList: data,
    pager
  })
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_CLEAR_ERRORS,
  (state): UnavailabilityReducerState => ({
    ...state,
    errors: []
  })
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_TOGGLE_FILTER_TYPE_REQUEST,
  requestHandler
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_TOGGLE_FILTER_TYPE_SUCCESS,
  (
    state,
    { data, filters, pager, selectedFilter }
  ): UnavailabilityReducerState => ({
    ...state,
    isUpdating: false,
    filters,
    filtersForm: {
      ...filters
    },
    pager,
    selectedFilter,
    timeOffsList: data
  })
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_TOGGLE_FILTER_TYPE_FAILURE,
  failureHandler
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_UPDATE_FILTER_FORM,
  (state, { name, value }): UnavailabilityReducerState => ({
    ...state,
    filtersForm: {
      ...state.filtersForm,
      [name]: value
    }
  })
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_CLEAR_FILTER_FORM,
  (state): UnavailabilityReducerState => ({
    ...state,
    filtersForm: cloneDeep(state.filters)
  })
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_UPDATE_CUSTOM_FILTERS_REQUEST,
  requestHandler
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_UPDATE_CUSTOM_FILTERS_SUCCESS,
  (
    state,
    { selectedFilter, filters, pager, data }
  ): UnavailabilityReducerState => ({
    ...state,
    isUpdating: false,
    selectedFilter,
    filters,
    pager,
    timeOffsList: data
  })
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_UPDATE_CUSTOM_FILTERS_FAILURE,
  (state): UnavailabilityReducerState => ({
    ...state,
    isUpdating: false,
    filtersForm: cloneDeep(state.filters)
  })
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_CHANGE_PAGE_REQUEST,
  requestHandler
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_CHANGE_PAGE_SUCCESS,
  (state, { data, pager }): UnavailabilityReducerState => ({
    ...state,
    isUpdating: false,
    pager,
    timeOffsList: data
  })
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_CHANGE_PAGE_FAILURE,
  failureHandler
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_CHANGE_PAGE_SIZE_REQUEST,
  requestHandler
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_CHANGE_PAGE_SIZE_SUCCESS,
  (state, { data, pager }): UnavailabilityReducerState => ({
    ...state,
    isUpdating: false,
    pager,
    timeOffsList: data
  })
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_CHANGE_PAGE_SIZE_FAILURE,
  failureHandler
);

unavailability.on(actions.BOX_UNAVAILABILITY_APPROVE_REQUEST, requestHandler);
unavailability.on(actions.BOX_UNAVAILABILITY_APPROVE_SUCCESS, successHandler);
unavailability.on(actions.BOX_UNAVAILABILITY_APPROVE_FAILURE, failureHandler);

unavailability.on(actions.BOX_UNAVAILABILITY_DECLINE_REQUEST, requestHandler);
unavailability.on(actions.BOX_UNAVAILABILITY_DECLINE_SUCCESS, successHandler);
unavailability.on(actions.BOX_UNAVAILABILITY_DECLINE_FAILURE, failureHandler);

unavailability.on(actions.BOX_UNAVAILABILITY_UNAPPROVE_REQUEST, requestHandler);
unavailability.on(actions.BOX_UNAVAILABILITY_UNAPPROVE_SUCCESS, successHandler);
unavailability.on(actions.BOX_UNAVAILABILITY_UNAPPROVE_FAILURE, failureHandler);

unavailability.on(
  actions.BOX_UNAVAILABILITY_DELETE_MODAL_OPEN,
  openConfirmModalHandlerCreator('deleteModal')
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_DELETE_MODAL_CLOSE,
  closeModalHandlerCreator('deleteModal')
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_DELETE_REQUEST,
  confirmModalRequestHandlerCreator('deleteModal')
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_DELETE_SUCCESS,
  modalSuccessHandlerCreator('deleteModal')
);
unavailability.on(
  actions.BOX_UNAVAILABILITY_DELETE_FAILURE,
  confirmModalFailureHandlerCreator('deleteModal')
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_CREATE_MODAL_OPEN,
  (state): UnavailabilityReducerState => ({
    ...state,
    createModal: {
      ...state.createModal,
      isOpened: true,
      errors: []
    },
    applicableUsers: {
      ...state.applicableUsers,
      errors: []
    }
  })
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_CREATE_MODAL_CLOSE,
  closeModalHandlerCreator('createModal')
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_CREATE_UNAVAILABILITY_REQUEST,
  formModalRequestHandlerCreator('createModal')
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_CREATE_UNAVAILABILITY_SUCCESS,
  modalSuccessHandlerCreator('createModal')
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_CREATE_UNAVAILABILITY_FAILURE,
  modalFailureHandlerCreator('createModal')
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_EDIT_MODAL_OPEN,
  (state, { timeOffId }): UnavailabilityReducerState => ({
    ...state,
    editModal: {
      ...state.editModal,
      isOpened: true,
      errors: [],
      timeOffId
    }
  })
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_EDIT_MODAL_CLOSE,
  closeModalHandlerCreator('editModal')
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_EDIT_UNAVAILABILITY_REQUEST,
  formModalRequestHandlerCreator('editModal')
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_EDIT_UNAVAILABILITY_SUCCESS,
  modalSuccessHandlerCreator('editModal')
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_EDIT_UNAVAILABILITY_FAILURE,
  modalFailureHandlerCreator('editModal')
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_CONFIRM_APPROVAL_MODAL_OPEN,
  (state, { message, payload: { timeOffId } }): UnavailabilityReducerState => ({
    ...state,
    isUpdating: false,
    confirmApprovalModal: {
      ...state.confirmApprovalModal,
      isOpened: true,
      timeOffId,
      message: message
    }
  })
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_CONFIRM_APPROVAL_MODAL_CLOSE,
  closeModalHandlerCreator('confirmApprovalModal')
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_FORCE_APPROVE_REQUEST,
  confirmModalRequestHandlerCreator('confirmApprovalModal')
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_FORCE_APPROVE_SUCCESS,
  modalSuccessHandlerCreator('confirmApprovalModal')
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_FORCE_APPROVE_FAILURE,
  confirmModalFailureHandlerCreator('confirmApprovalModal')
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_CONFIRM_CREATION_MODAL_OPEN,
  (state, payload): UnavailabilityReducerState => ({
    ...state,
    createModal: {
      ...state.createModal,
      errors: [],
      isUpdating: false
    },
    confirmCreationModal: {
      ...state.confirmCreationModal,
      isOpened: true,
      payload: payload.payload,
      message: payload.message
    }
  })
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_CONFIRM_CREATION_MODAL_CLOSE,
  closeModalHandlerCreator('confirmCreationModal')
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_FORCE_CREATE_UNAVAILABILITY_REQUEST,
  confirmModalRequestHandlerCreator('confirmCreationModal')
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_FORCE_CREATE_UNAVAILABILITY_SUCCESS,
  (state): UnavailabilityReducerState => ({
    ...state,
    createModal: {
      ...state.createModal,
      isOpened: false
    },
    confirmCreationModal: {
      ...state.confirmCreationModal,
      isUpdating: false,
      isOpened: false
    }
  })
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_FORCE_CREATE_UNAVAILABILITY_FAILURE,
  (
    state: UnavailabilityReducerState,
    errors: FormattedErrors
  ): UnavailabilityReducerState => ({
    ...state,
    createModal: {
      ...state.createModal,
      errors
    },
    confirmCreationModal: {
      ...state.confirmCreationModal,
      isUpdating: false,
      isOpened: false
    }
  })
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_CONFIRM_EDIT_MODAL_OPEN,
  (state, payload): UnavailabilityReducerState => ({
    ...state,
    editModal: {
      ...state.editModal,
      errors: [],
      isUpdating: false
    },
    confirmEditModal: {
      ...state.confirmEditModal,
      isOpened: true,
      payload: payload.payload,
      message: payload.message
    }
  })
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_CONFIRM_EDIT_MODAL_CLOSE,
  closeModalHandlerCreator('confirmEditModal')
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_FORCE_EDIT_UNAVAILABILITY_REQUEST,
  confirmModalRequestHandlerCreator('confirmEditModal')
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_FORCE_EDIT_UNAVAILABILITY_SUCCESS,
  (state): UnavailabilityReducerState => ({
    ...state,
    editModal: {
      ...state.editModal,
      isOpened: false
    },
    confirmEditModal: {
      ...state.confirmEditModal,
      isUpdating: false,
      isOpened: false
    }
  })
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_FORCE_EDIT_UNAVAILABILITY_FAILURE,
  (state, errors): UnavailabilityReducerState => ({
    ...state,
    editModal: {
      ...state.editModal,
      errors
    },
    confirmEditModal: {
      ...state.confirmEditModal,
      isUpdating: false,
      isOpened: false
    }
  })
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_SET_IS_CURRENTLY_UPDATING,
  (state, id): UnavailabilityReducerState => ({
    ...state,
    isCurrentlyUpdating: id
  })
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_UNSET_IS_CURRENTLY_UPDATING,
  (state): UnavailabilityReducerState => ({
    ...state,
    isCurrentlyUpdating: null
  })
);
unavailability.on(
  actions.BOX_UNAVAILABILITY_GET_APPLICABLE_USERS_REQUEST,
  (state): UnavailabilityReducerState => ({
    ...state,
    applicableUsers: {
      ...state.applicableUsers,
      isFetching: true
    }
  })
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_GET_APPLICABLE_USERS_SUCCESS,
  (state, { userIds }): UnavailabilityReducerState => ({
    ...state,
    applicableUsers: {
      ...state.applicableUsers,
      isFetching: false,
      isFetched: true,
      userIds
    }
  })
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_GET_APPLICABLE_USERS_FAILURE,
  (state, errors): UnavailabilityReducerState => ({
    ...state,
    applicableUsers: {
      ...state.applicableUsers,
      isFetching: false,
      isFetched: false,
      errors
    }
  })
);

unavailability.on(
  actions.BOX_UNAVAILABILITY_MODAL_CLEAR_ERRORS,
  (state): UnavailabilityReducerState => ({
    ...state,
    createModal: {
      ...state.createModal,
      errors: []
    }
  })
);
