import React from 'react';
import { MessageBlock } from 'elmo-elements';
import { getPreferenceLabel } from 'lib/helpers';
import { getLangPreferences } from 'state/Account';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import { LanguagePreferences } from 'type';

type OwnProps = {};

type StateProps = {
  langPreferences: LanguagePreferences;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

const EmptyMessageComponent = ({ langPreferences }: Props) => (
  <MessageBlock id="manager-events-empty-message">
    <MessageBlock.Header>
      There are no {getPreferenceLabel(langPreferences, 'event', 'plural', '', true)}.
    </MessageBlock.Header>
  </MessageBlock>
);

const mapStateToProps = (state: StoreState): StateProps => ({
  langPreferences: getLangPreferences(state)
});

export const EmptyMessage = connect(mapStateToProps)(EmptyMessageComponent);
