import Api from 'lib/Api';
import { getTrialMessage } from 'messages';
import moment from 'moment-timezone';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { BOX_GLOBAL_ALERT_OPEN } from 'state/GlobalAlert';
import { processApiRequest } from 'state/ProcessApiRequest';
import { Account } from 'type';
import { BOX_ACCOUNT_FETCHED } from './actions';

export const getAccountRequest = function* (): SagaIterator {
  const account: Account = yield call(processApiRequest, Api.Account.account);
  yield put(BOX_ACCOUNT_FETCHED(account));

  if (account.is_trial) {
    const days = moment.parseZone(account.trial_ends_at).diff(
      moment.tz(account.preferences.timezone).startOf('day'),
      'days'
    );
    yield put(BOX_GLOBAL_ALERT_OPEN(getTrialMessage(days)));
  }
};
