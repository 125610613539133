import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import {
  BOX_UNAVAILABILITY_CONFIRM_EDIT_MODAL_CLOSE,
  BOX_UNAVAILABILITY_FORCE_EDIT_UNAVAILABILITY_REQUEST,
  getConfirmEditModal
} from 'state/ManagerDashboard/Unavailability';
import {
  ConfirmationModalProps,
  UnavailabilityConfirmationModal
} from '../UnavailabilityConfirmationModal';

type OwnProps = {};

type StateProps = Pick<ConfirmationModalProps, 'isUpdating' | 'isOpened' | 'message'>;

type DispatchProps = Pick<ConfirmationModalProps, 'onClose' | 'onSubmit'>;

type Props = OwnProps & StateProps & DispatchProps;

const UnavailabilityConfirmEditComponent = ({ message, ...rest }: Props) => (
  <UnavailabilityConfirmationModal
    {...rest} message={message}
  />
);

const mapStateToProps = (state: StoreState): StateProps => ({
  ...getConfirmEditModal(state)
});

const mapDispatchToProps: DispatchProps = {
  onClose: BOX_UNAVAILABILITY_CONFIRM_EDIT_MODAL_CLOSE,
  onSubmit: BOX_UNAVAILABILITY_FORCE_EDIT_UNAVAILABILITY_REQUEST
};

export const UnavailabilityConfirmEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnavailabilityConfirmEditComponent);
