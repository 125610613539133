import React from 'react';
import clsx from 'clsx';
import { Theme } from 'extended-oxygen-elements';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { DefaultDivProps } from 'type';
import { capitalize } from 'lib/helpers';
import { blackAlpha70 } from '../../constants';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      color: blackAlpha70,
      fontSize: '11px',
      lineHeight: 18 / 12,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '93%',
      marginLeft: 7,
    },
    weightNormal: {},
    weightBold: {
      fontWeight: 500,
    },
  });

type StyleProps = WithStyles<typeof styles>;
type ShiftCardEventRowProps = StyleProps &
  DefaultDivProps & {
    weight?: 'normal' | 'bold';
  };

export const ShiftCardEventRow = withStyles(styles)(function ShiftCardEventRow({
  classes,
  className,
  children,
  weight = 'normal',
  ...restProps
}: ShiftCardEventRowProps) {
  return (
    <div
      {...restProps}
      className={clsx([
        classes.root,
        classes[`weight${capitalize(weight)}` as const],
        className,
      ])}
    >
      {children}
    </div>
  );
});
