import React, { Component } from 'react';
import {
  Checkbox,
  DescriptionList,
  Loader,
  Paragraph,
  SelectList,
} from 'elmo-elements';
import {
  DSTIcon,
  IsAppMarket,
  PageDialog,
  PageDialogCancel, PageDialogCloseIconButton,
  PageDialogSubmit,
  TodayOutlinedIcon,
} from 'element';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import {
  BOX_SHIFT_TRADES_REMOVE_SWAP_MODAL_ERRORS,
  BOX_SHIFT_TRADES_SWAP_MODAL_CLOSE,
  BOX_SHIFT_TRADES_SWAP_REQUEST,
} from 'state/EmployeeDashboard/ShiftTrades';
import './SwapShiftModal.scss';
import {
  ShiftSwapPayload,
  SwapProposalsResponse,
} from 'state/EmployeeDashboard/ShiftTrades/types';
import {
  getSwapProposalsLoadingState,
  getSwapProposalsSelector,
  getSwapProposalsUpdatingSelector,
  getTheirShiftSelector,
} from 'state/EmployeeDashboard/ShiftTrades/selectors';
import {
  getDateTimeFormatted,
  getTimeFormatted,
  getUserName,
} from 'lib/helpers';
import ErrorBox from 'element/ErrorBox';
import {
  FormattedErrors,
  PreferencesDateFormat,
  PreferencesTimeFormat,
  ShiftTrade,
  ShiftTradeRosteredShift,
  ShiftTradeUser,
} from 'type/models';
import { getTimezone } from '../../../../helpers';
import TradeConfirmationModal from '../TradeConfirmationModal';
import { getDateFormat, getTimeFormat } from 'state/Account';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';

type StateProps = {
  isOpened: boolean;
  isLoading: boolean;
  proposals: SwapProposalsResponse;
  theirShift: ShiftTrade;
  isUpdating: boolean;
  errors: FormattedErrors;
  dateFormat: PreferencesDateFormat;
  timeFormat: PreferencesTimeFormat;
};

type State = {
  shiftToSwap: string;
};

type DispatchProps = {
  closeModal: () => void;
  swapShifts: (params: ShiftSwapPayload) => void;
  removeErrors: () => void;
};

type Props = StateProps & DispatchProps;

export class SwapShiftModal extends Component<Props, State> {
  state = {
    shiftToSwap: '',
  };

  render() {
    return (
      <>
        <PageDialog
          maxWidth={'sm'}
          open={this.props.isOpened}
          id={'swap-shift-modal'}
          onClose={this.props.closeModal}
          className={'shift-swap-modal'}
        >
          <DialogTitle>Swap shift</DialogTitle>
          <PageDialogCloseIconButton data-testid="swap-modal-close-btn" onClose={this.props.closeModal} />
          <DialogContent>
            <ErrorBox
              errors={this.props.errors}
              clearErrors={this.props.removeErrors}
              watchFormErrors={false}
            />
            {this.props.isLoading ? (
              <Loader isLoading={this.props.isLoading} />
            ) : (
              this.showProposals()
            )}
          </DialogContent>
          <DialogActions>
            <PageDialogCancel onClick={this.props.closeModal} data-testid="swap-modal-cancel-btn">
              Cancel
            </PageDialogCancel>
            <PageDialogSubmit
              onClick={() => this.swapShifts(false)}
              disabled={this.state.shiftToSwap === '' || this.props.isUpdating}
              loading={this.props.isUpdating}
              id={'swap-btn'}
            >
              Swap shift
            </PageDialogSubmit>
          </DialogActions>
        </PageDialog>
        <TradeConfirmationModal confirmOverlap={this.swapShifts} />
      </>
    );
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.isOpened !== this.props.isOpened && this.props.isOpened) {
      this.setState({
        ...this.state,
        shiftToSwap: '',
      });
    }
  }

  showProposals = () => {
    const { proposals, theirShift } = this.props;

    if (proposals.length === 0) {
      return (
        <Paragraph className={'no-data-placeholder'}>
          There're no shifts available for exchange
        </Paragraph>
      );
    }

    if (!theirShift || theirShift === null) {
      return null;
    }

    return (
      <>
        <Paragraph size={'xs'}>Their shift</Paragraph>
        <div className={'their-shift-wrap'}>
          <SelectList>
            <SelectList.Option>{this.getShiftSwapCard()}</SelectList.Option>
          </SelectList>
        </div>

        <Paragraph size={'xs'} className={'mb-2'}>
          Shifts available for exchange
        </Paragraph>

        <SelectList>
          {proposals.map((option: ShiftTradeRosteredShift, index: number) => {
            return (
              <SelectList.Option
                key={index}
                icon={
                  <Checkbox
                    id={`d-${index}`}
                    isChecked={this.state.shiftToSwap === option.id}
                  />
                }
                onToggle={() => this.setShiftToSwap(option.id)}
              >
                {this.getAvailableShiftSwapCard(option)}
              </SelectList.Option>
            );
          })}
        </SelectList>
      </>
    );
  };

  getPositionAreaLocation = (shift: ShiftTradeRosteredShift) => {
    return `${shift.role.name} - ${shift.area.name}, ${shift.area.site.name}`;
  };

  getShiftSwapCard = () => {
    const {
      theirShift: { rostered_shift },
      timeFormat,
      dateFormat,
    } = this.props;
    const start = getTimeFormatted(timeFormat, rostered_shift.start);
    const end = getTimeFormatted(timeFormat, rostered_shift.end);
    return (
      <DescriptionList style="align-icons">
        <DescriptionList.Item label={<TodayOutlinedIcon />}>
          <Paragraph>
            {getDateTimeFormatted(dateFormat, timeFormat, rostered_shift.start)}
          </Paragraph>
          <Paragraph size={'xs'}>
            {start} - {end} ({getTimezone(rostered_shift)}){' '}
            <DSTIcon isDST={rostered_shift.is_dst_intersect} />
          </Paragraph>
          <Paragraph size={'xs'}>
            {this.getFullName(rostered_shift.user)}
          </Paragraph>
          <Paragraph size={'xs'} color={'gray'}>
            {this.getPositionAreaLocation(rostered_shift)}
          </Paragraph>
        </DescriptionList.Item>
      </DescriptionList>
    );
  };

  getAvailableShiftSwapCard = (shift: ShiftTradeRosteredShift) => {
    const { dateFormat, timeFormat } = this.props;
    const start = getTimeFormatted(timeFormat, shift.start);
    const end = getTimeFormatted(timeFormat, shift.end);
    return (
      <DescriptionList style="align-icons">
        <DescriptionList.Item label={<TodayOutlinedIcon />}>
          <Paragraph>
            {getDateTimeFormatted(dateFormat, timeFormat, shift.start)}
          </Paragraph>
          <Paragraph size={'xs'}>
            <IsAppMarket market={'uk'} negate={true}>
              {(isMarket) => {
                return (
                  <>
                    {start} - {end}
                    {isMarket && ` (${getTimezone(shift)})`}
                    <DSTIcon isDST={shift.is_dst_intersect} />
                  </>
                );
              }}
            </IsAppMarket>
          </Paragraph>
          <Paragraph size={'xs'} color={'gray'}>
            {this.getPositionAreaLocation(shift)}
          </Paragraph>
        </DescriptionList.Item>
      </DescriptionList>
    );
  };

  setShiftToSwap = (id: string) => {
    this.setState({
      shiftToSwap: id,
    });
  };

  getFullName = (user: ShiftTradeUser) => {
    return user === null ? 'Unassigned' : getUserName(user);
  };

  swapShifts = (ignoreErrors: boolean = false) => {
    const { shiftToSwap } = this.state;
    const {
      theirShift: { id },
    } = this.props;
    this.props.swapShifts({
      trade_id: id,
      proposal_id: shiftToSwap,
      ignore_errors: ignoreErrors,
    });
  };
}

const mapToStateProps = (state: StoreState): StateProps => ({
  isOpened: state.employeeDashboard.shiftTrades.swapModal.isOpened,
  proposals: getSwapProposalsSelector(state),
  isLoading: getSwapProposalsLoadingState(state),
  theirShift: getTheirShiftSelector(state),
  isUpdating: getSwapProposalsUpdatingSelector(state),
  errors: state.employeeDashboard.shiftTrades.swapModal.errors,
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
});

const mapToDispatchProps: DispatchProps = {
  closeModal: BOX_SHIFT_TRADES_SWAP_MODAL_CLOSE,
  swapShifts: BOX_SHIFT_TRADES_SWAP_REQUEST,
  removeErrors: BOX_SHIFT_TRADES_REMOVE_SWAP_MODAL_ERRORS,
};

export default connect(mapToStateProps, mapToDispatchProps)(SwapShiftModal);
