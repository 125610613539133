import React from 'react';
import _ from 'lodash';
import { useTheme } from 'extended-oxygen-elements';
import HeaderButtons from './component/HeaderButtons';
import HeaderBack from './component/HeaderBack';
import HeaderTitle from './component/HeaderTitle';
import HeaderTitleAddon from './component/HeaderTitleAddon';
import HeaderTabs from './component/HeaderTabs';
import { getClass } from 'elmo-elements/_lib/helper';
import { HeaderProps } from './type';
import './Header.scss';
import { ThemeContextProvider } from './component/ThemeContext';
import { HeaderTabsStyled } from './Styles';

export function Header({
  children,
  backButton,
  buttons,
  primaryButton,
  tabs,
  subHeading,
  titleAddon,
  title,
  isSearchOpen,
  isBulkActionOpen,
  theme,
  closeButton,
  style
}: HeaderProps) {
  const backgroundColor = useTheme().palette.background.default;

  const headerStyle = _.defaults({}, style, { backgroundColor })

  return (
    <ThemeContextProvider value={theme}>
      <div
        className={getClass(
          'elmo-header-wrapper',
          [],
          {
            search: isSearchOpen,
            'bulk-actions': isBulkActionOpen,
            'has-buttons': buttons,
            'has-back': backButton,
            'has-tabs': tabs,
            'has-subheading': subHeading,
          }
        )}
      >
        <div
          className={getClass(
            'elmo-header',
            { subpage: backButton }
          )}
          style={headerStyle}
        >
          {children}
          <div className="elmo-header__base">
            <div className="elmo-header__top">
              {(buttons || primaryButton) && (
                <HeaderButtons
                  buttons={buttons}
                  primaryButton={primaryButton}
                />
              )}
              {closeButton && (
                <div className={'elmo-header__close-btn'}>{closeButton}</div>
              )}
              <div className="elmo-header__title-wrapper">
                {backButton && (
                  <HeaderBack
                    {...(backButton.id ? { id: backButton.id } : null)}
                    onClick={backButton.onClick}
                    title={backButton.title}
                    ariaLabel={backButton.ariaLabel}
                  />
                )}
                <div className="elmo-header__title-block-wrapper">
                  <div className="elmo-header__title-block">
                    <HeaderTitle subHeading={subHeading}>{title}</HeaderTitle>
                    <HeaderTitleAddon>{titleAddon}</HeaderTitleAddon>
                  </div>
                  {!!subHeading && (
                    <h2 className="elmo-header__sub-heading">{subHeading}</h2>
                  )}
                </div>
              </div>
            </div>
            {tabs && (
              <HeaderTabsStyled primaryColor={theme && theme.primaryColor}>
                <HeaderTabs tabs={tabs} />
              </HeaderTabsStyled>
            )}
          </div>
        </div>
      </div>
      <div className="elmo-header-wrapper__spacing" />
    </ThemeContextProvider>
  );
}
