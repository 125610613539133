import { createSelector } from 'reselect';
import { filteredRosteredShiftsByUserIdSelector } from '../../RosterFilters';
import { RosteredShift, StringMap } from '../../../../type';
import { StoreState } from '../../../types';
import moment, { Moment } from 'moment';
import { sortBy, times } from 'lodash';
import { UnassignedRosterData, UnassignedShiftsAndDuration } from '../types';
import { calculateDuration } from './helpers';
import { getUnassignedAdditionalRows } from './';
import { fromBySiteTimezoneSelector } from 'state/RosteredShifts/selectors';

const unassignedRosteredShiftsSelector = createSelector(
  filteredRosteredShiftsByUserIdSelector,
  (shiftsByUserId: StringMap<RosteredShift[]>): RosteredShift[] =>
    shiftsByUserId['null'] || []
);

const sortedUnassignedRosteredShiftsSelector = createSelector<
  StoreState,
  RosteredShift[],
  RosteredShift[]
>(
  unassignedRosteredShiftsSelector,
  (unassignedRosteredShifts: RosteredShift[]): RosteredShift[] =>
    sortBy(
      unassignedRosteredShifts,
      (rosteredShift: RosteredShift): number =>
        moment.duration(rosteredShift.start.valueOf()).asMinutes(),
      (rosteredShift: RosteredShift): number =>
        moment.duration(rosteredShift.end.valueOf()).asMinutes()
    )
);

const rosteredShiftsAndDurationSelector = createSelector(
  sortedUnassignedRosteredShiftsSelector,
  fromBySiteTimezoneSelector,
  (
    rosteredShifts: RosteredShift[],
    from: Moment
  ): UnassignedShiftsAndDuration => ({
    rosteredShifts,
    duration: calculateDuration(rosteredShifts, from)
  })
);

export const unassignedRosterDataSelector = createSelector(
  rosteredShiftsAndDurationSelector,
  getUnassignedAdditionalRows,
  (
    { rosteredShifts, duration }: UnassignedShiftsAndDuration,
    additionalRows: number
  ): UnassignedRosterData => {
    const emptyRows: null[] = times(additionalRows, () => null);
    const rows: (RosteredShift | null)[] = [...rosteredShifts, ...emptyRows];

    return {
      rows,
      duration
    };
  }
);
