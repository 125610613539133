import { createReducer } from 'lib/store-utils';
import { ShortcutsModalReducerState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';

export const shortcutsModal = createReducer<ShortcutsModalReducerState>(
  {},
  getDefaultState()
);

shortcutsModal
  .on(
    actions.BOX_SHORTCUTS_MODAL_OPEN,
    (): ShortcutsModalReducerState => ({
      isOpen: true
    })
  )
  .on(
    actions.BOX_SHORTCUTS_MODAL_CLOSE,
    (): ShortcutsModalReducerState => ({
      isOpen: false
    })
  );
