import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { SIDE_MENU_WIDTH } from 'lib/config';
import AppSwitcher from './AppSwitcher';
import Logo from './Logo';
import UserMenu from './UserMenu';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        justifyContent: 'flex-start',
      },
    },
    logo: {
      position: 'fixed',
      left: '50%',
      transform: 'translateX(-50%)',
      [theme.breakpoints.up('lg')]: {
        position: 'static',
        transform: 'none',
        marginRight: theme.spacing(3),
      },
      [theme.breakpoints.up('xl')]: {
        width: SIDE_MENU_WIDTH,
        marginLeft: -theme.spacing(3),
        display: 'flex',
        justifyContent: 'center',
      },
    },
    appSwitcher: {
      marginRight: theme.spacing(1 / 2),
      [theme.breakpoints.up('lg')]: {
        marginRight: 'auto',
      },
    },
    userMenu: {
      marginRight: -theme.componentsConfig.iconButton.medium.padding,
      [theme.breakpoints.up('lg')]: {
        marginRight: 0,
      },
    },
  })
);

function RosterHeader() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.logo}>
        <Logo desktopBreakpoint="md" />
      </div>

      <div className={classes.appSwitcher}>
        <AppSwitcher desktopBreakpoint="md" />
      </div>

      <div className={classes.userMenu}>
        <UserMenu desktopBreakpoint="lg" />
      </div>
    </div>
  );
}

export default RosterHeader;
