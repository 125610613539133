import { every, identity, negate } from 'lodash';
import { assertUnreachable } from 'helpers';
import {
  RosterFilters,
  RosterFiltersByType,
  RosterFiltersCustom,
  RosterFiltersRoleFilterType,
  ViewModulesMyPermissionSelector,
} from './types';
import { getDefaultTypeFiltersByPermissions } from './helpers';

export const filtersByTypeCombiner = ({
  unavailability,
  roster,
  timesheets,
}: RosterFilters): RosterFiltersByType => ({
  unavailability,
  roster,
  timesheets,
});

export const filterByTypeBasedOnPermissionsCombiner = (
  filtersByType: RosterFiltersByType,
  modulePermissions: ViewModulesMyPermissionSelector
) => {
  const filtersByTypeBasedOnPermissions = {
    ...filtersByType,
    roster: modulePermissions.roster && filtersByType.roster,
    timesheets: modulePermissions.timesheets && filtersByType.timesheets,
  };

  const isAllFiltersByTypeDisabled = every(
    filtersByTypeBasedOnPermissions,
    negate(identity as any)
  );

  if (!isAllFiltersByTypeDisabled) {
    return filtersByTypeBasedOnPermissions;
  }

  return getDefaultTypeFiltersByPermissions(modulePermissions);
};

export const customFiltersCombiner = ({
  unavailability,
  roster,
  timesheets,
  ...restFilters
}: RosterFilters): RosterFiltersCustom => restFilters;

export const filtersCombiner = (
  filtersByType: RosterFiltersByType,
  customFilters: RosterFiltersCustom
): RosterFilters => ({
  ...filtersByType,
  ...customFilters,
});

export const filtersAreasOrRolesCombiner = (
  roleFilterType: RosterFiltersRoleFilterType,
  values: null | string[],
  flattenedValues: null | string[]
) => {
  switch (roleFilterType) {
    case 'flat':
      return values;
    case 'tree':
      return flattenedValues;
    default:
      assertUnreachable(roleFilterType);
  }

  return null;
};
