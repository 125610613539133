import React from 'react';
import qs from 'qs';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getAreas } from 'state/AccountTree';

type Props = {
  areaId?: string;
  showDays: boolean;
};

export const PrintWeekHeader = ({ showDays, areaId }: Props) => {
  const areas = useSelector(getAreas);
  const d = qs.parse(window.location.search).day;
  const startDate = d ? moment.utc(d as string) : moment();
  return (
    <thead>
      <tr>
        <th key={'area-title'}>
          {areaId && areas[areaId] ? areas[areaId].name : ''} &nbsp;
        </th>
        <th key="days-titles">
          <table>
            <tbody>
            <tr>
              {[1, 2, 3, 4, 5, 6, 7].map((n, key) => {
                const day = moment.parseZone(startDate.startOf('week'))
                  .add(key, 'day');
                const title = day.format('ddd DD');
                return <td className="header-day" key={key}>{showDays ? title : ''}</td>
              })}
            </tr>
            </tbody>
          </table>
        </th>
      </tr>
    </thead>
  );
};