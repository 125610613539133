import Api from 'lib/Api';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest } from 'redux-saga/effects';
import { formatError } from 'state/helpers';
import { apiWithConfirmCall } from 'state/ProcessApiRequest';
import { ApiWithConfirmReturnType, SagaActionFromCreator } from 'type';
import * as actions from './actions';

const createPayElement = function* ({
  payload,
}: SagaActionFromCreator<
  typeof actions.BOX_PAY_ELEMENTS_PAY_ELEMENT_CREATE.request
>): SagaIterator {
  try {
    const createdPayElement: ApiWithConfirmReturnType<
      typeof Api.PayElements.create
    > = yield apiWithConfirmCall(Api.PayElements.create, payload);

    if (createdPayElement) {
      yield put(
        actions.BOX_PAY_ELEMENTS_PAY_ELEMENT_CREATE.success(createdPayElement)
      );
    } else {
      yield put(actions.BOX_PAY_ELEMENTS_PAY_ELEMENT_CREATE.cancel());
    }
  } catch (error) {
    yield put(
      actions.BOX_PAY_ELEMENTS_PAY_ELEMENT_CREATE.failure(formatError(error))
    );
  }
};

const updatePayElement = function* ({
  payload,
}: SagaActionFromCreator<
  typeof actions.BOX_PAY_ELEMENTS_PAY_ELEMENT_UPDATE.request
>): SagaIterator {
  try {
    const payElement: ApiWithConfirmReturnType<typeof Api.PayElements.update> =
      yield apiWithConfirmCall(Api.PayElements.update, payload);

    if (payElement) {
      yield put(
        actions.BOX_PAY_ELEMENTS_PAY_ELEMENT_UPDATE.success(payElement)
      );
    } else {
      yield put(actions.BOX_PAY_ELEMENTS_PAY_ELEMENT_UPDATE.cancel());
    }
  } catch (error) {
    yield put(
      actions.BOX_PAY_ELEMENTS_PAY_ELEMENT_UPDATE.failure(formatError(error))
    );
  }
};

const deletePayElement = function* ({
  payload,
}: SagaActionFromCreator<
  typeof actions.BOX_PAY_ELEMENTS_PAY_ELEMENT_DELETE.request
>): SagaIterator {
  try {
    const response: ApiWithConfirmReturnType<typeof Api.PayElements.delete> =
      yield apiWithConfirmCall(Api.PayElements.delete, payload);

    if (response) {
      yield put(actions.BOX_PAY_ELEMENTS_PAY_ELEMENT_DELETE.success(payload));
    } else {
      yield put(actions.BOX_PAY_ELEMENTS_PAY_ELEMENT_DELETE.cancel());
    }
  } catch (error) {
    yield put(
      actions.BOX_PAY_ELEMENTS_PAY_ELEMENT_DELETE.failure(formatError(error))
    );
  }
};

export const watchPayElements = function* (): SagaIterator {
  yield takeLatest(
    actions.BOX_PAY_ELEMENTS_PAY_ELEMENT_CREATE.request,
    createPayElement
  );
  yield takeLatest(
    actions.BOX_PAY_ELEMENTS_PAY_ELEMENT_UPDATE.request,
    updatePayElement
  );
  yield takeLatest(
    actions.BOX_PAY_ELEMENTS_PAY_ELEMENT_DELETE.request,
    deletePayElement
  );
};
