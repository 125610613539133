import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Typography,
} from 'extended-oxygen-elements';
import { capitalize } from 'lib/helpers';
import marketLabels from 'marketLabels';
import React from 'react';
import { isAppMarket } from '../../../../../helpers';

type FromValues = {
  breaks_from_shift: boolean;
  breaks_from_rules: boolean;
};

export type DefaultBreaksFromRadioGroupProps = {
  values: FromValues;
  onChange: (updatedValues: FromValues) => void;
  disabled: boolean;
};

enum BreaksFieldFromState {
  breaks_from_shift = 'breaks_from_shift',
  breaks_from_rules = 'breaks_from_rules',
}

export default function DefaultBreaksFromRadioGroup({
  values,
  onChange,
  disabled,
}: DefaultBreaksFromRadioGroupProps) {
  const handleFromChange: RadioGroupProps['onChange'] = (event, value) => {
    onChange(getFromValues(value as BreaksFieldFromState));
  };

  const label = isAppMarket('uk') ? 'shift' : 'rostered shift';

  return (
    <FormControl>
      <Typography
        variant="h5"
        component="h3"
        sx={disabled ? { color: 'rgba(0, 0, 0, 0.38)' } : {}}
        mb="15px"
      >
        Enable break customisation
      </Typography>

      <RadioGroup value={getFromState(values)} onChange={handleFromChange}>
        <FormControlLabel
          disabled={disabled}
          value={BreaksFieldFromState.breaks_from_shift}
          control={<Radio />}
          label={`Use break duration set in ${label}`}
        />

        <FormControlLabel
          disabled={disabled}
          value={BreaksFieldFromState.breaks_from_rules}
          control={<Radio />}
          label="Set break duration based on the following rules:"
        />
      </RadioGroup>
    </FormControl>
  );
}

function getFromState(values: FromValues): BreaksFieldFromState {
  if (values.breaks_from_shift) {
    return BreaksFieldFromState.breaks_from_shift;
  }

  if (values.breaks_from_rules) {
    return BreaksFieldFromState.breaks_from_rules;
  }

  return BreaksFieldFromState.breaks_from_shift;
}

function getFromValues(state: BreaksFieldFromState): FromValues {
  return {
    breaks_from_shift: state === BreaksFieldFromState.breaks_from_shift,
    breaks_from_rules: state === BreaksFieldFromState.breaks_from_rules,
  };
}
