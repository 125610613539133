import { createAction } from 'lib/store-utils';
import { RosteredShift, Timesheet } from 'type/models';

export const BOX_ROSTER_CONTEXTUAL_MENU_OPEN = createAction<{id: string, objectId: string | null, cellData: any}>('Contextual menu / open menu');
export const BOX_ROSTER_CONTEXTUAL_MENU_CLOSE = createAction('Contextual menu / close menu');

export const BOX_ROSTER_CONTEXTUAL_MENU_COPY_ROSTERED_SHIFT = createAction('Contextual menu / copy shift data');
export const BOX_ROSTER_CONTEXTUAL_MENU_CUT_ROSTERED_SHIFT = createAction('Contextual menu / cut shift data');
export const BOX_ROSTER_CONTEXTUAL_MENU_PASTE_ROSTERED_SHIFT = createAction('Contextual menu / paste shift data');
export const BOX_ROSTER_CONTEXTUAL_MENU_PASTE_AND_OPEN = createAction('Contextual menu / paste and open');

export const BOX_ROSTER_CONTEXTUAL_MENU_COPY_SHIFT_ITEM = createAction('Contextual menu / copy shift item');
export const BOX_ROSTER_CONTEXTUAL_MENU_PASTE_SHIFT_ITEM = createAction('Contextual menu / paste shift item');
export const BOX_ROSTER_CONTEXTUAL_MENU_CUT_SHIFT_ITEM = createAction('Contextual menu / cut shift item');
export const BOX_ROSTER_CONTEXTUAL_MENU_PASTE_ITEM_AND_OPEN = createAction('Contextual menu / paste item and open');

export const BOX_ROSTER_CONTEXTUAL_MENU_SET_CLIPBOARD = createAction<RosteredShift | null>('Contextual menu / set clipboard data');
export const BOX_ROSTER_CONTEXTUAL_MENU_SET_EMPTY_CELL = createAction<RosteredShift | Timesheet>('Contextual menu / set empty cell data');
export const BOX_ROSTER_CONTEXTUAL_MENU_SET_ACTION = createAction<'copy' | 'cut' | 'none' | 'paste'>('Contextual menu / set action type');

export const BOX_ROSTER_CONTEXTUAL_MENU_SET_INPROGRESS = createAction<boolean>('Contextual menu / set preloader state');

export const BOX_ROSTER_CONTEXTUAL_MENU_SET_IGNORE_ERROR = createAction<boolean>('Contextual menu / set ignore value');

export const BOX_ROSTER_CONTEXTUAL_MENU_REPLACE_IN_MODAL = createAction('Contextual menu / update data inside modal');
export const BOX_ROSTER_CONTEXTUAL_MENU_REPLACE_ITEM_IN_MODAL = createAction('Contextual menu / update item inside modal');

export const BOX_ROSTER_CONTEXTUAL_MENU_BULK_SET_PROPS = createAction<{ inProgress: boolean, clipboard: any }>('Contextual menu / update bulk data');
export const BOX_ROSTER_CONTEXTUAL_MENU_CLOSE_AND_SET_PROPS = createAction<{ clipboard: RosteredShift | null, action: 'copy' | 'cut' | 'paste' | 'none' }>('Contextual menu / close and set props');
export const BOX_ROSTER_CONTEXTUAL_MENU_CLOSE_WHEN_DELETED = createAction<string[]>('Contextual menu / close when shifts deleted');
