import { GlobalStyles } from 'extended-oxygen-elements';
import React from 'react';
import { useLayoutContext } from './layout-context';

type ContextStylingComponentProps = {
  background: string;
  disableScroll: boolean;
};

const ContextStylingComponent = React.memo(
  ({ background, disableScroll }: ContextStylingComponentProps) => (
    <GlobalStyles
      styles={{
        body: {
          backgroundColor: background,
          ...(disableScroll && {
            overflow: 'hidden',
          }),
        },
      }}
    />
  )
);

export default function ContextStyling() {
  const { background, disableScroll } = useLayoutContext();

  return <ContextStylingComponent {...{ background, disableScroll }} />;
}
