import React from 'react';
import Hidden, { HiddenPropsBreakpoint } from './Hidden';

export type VisibleProps = {
  up: HiddenPropsBreakpoint;
  children: React.ReactNode;
};

export default function Visible({ children, up }: VisibleProps) {
  return (
    <Hidden downBefore={up}>{children}</Hidden>
  );
}
