import { Box, Hidden, TextFieldProps, Visible } from 'extended-oxygen-elements';
import React from 'react';
import { WithChildren } from 'type';

export default function Field({
  children,
  label,
}: {
  children: React.ReactElement<TextFieldProps>;
  label?: string;
}) {
  if (label) {
    return (
      <Wrapper>
        <FieldWithLabel label={label}>{children}</FieldWithLabel>
      </Wrapper>
    );
  }

  return <Wrapper>{children}</Wrapper>;
}

function Wrapper({ children }: WithChildren) {
  return (
    <Box flexShrink={0} width={{ sm: 256 }}>
      {children}
    </Box>
  );
}

function FieldWithLabel({
  children,
  label,
}: {
  children: React.ReactElement<TextFieldProps>;
  label: string;
}) {
  return (
    <>
      <Hidden up="sm">{children}</Hidden>

      <Visible up="sm">
        {React.cloneElement(children, {
          label,
          InputLabelProps: { shrink: true },
        })}
      </Visible>
    </>
  );
}
