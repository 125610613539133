import React from 'react';
import { useSelector } from 'react-redux';
import { getWeekDays, groupEvents } from 'state/Roster/RosterWeekView';
import {
  getCurrencyCode,
  getCurrencyPlacement,
  getLangPreferences,
  getNumberFormat,
} from 'state/Account';
import { currentTimeBySiteTimezoneSelector } from 'state/Roster/Roster';
import { getEvents } from 'state/Events';
import { EventCard } from '../../../shiftCards';
import { EventsRow } from './EventsRow';

export const EventsView = () => {
  const data = useSelector(groupEvents);
  const events = useSelector(getEvents);
  const weekDays = useSelector(getWeekDays);
  const langPreferences = useSelector(getLangPreferences);
  const currencyCode = useSelector(getCurrencyCode);
  const currencyPlacement = useSelector(getCurrencyPlacement);
  const numberFormat = useSelector(getNumberFormat);
  const currentTimeBySiteTimezone = useSelector(
    currentTimeBySiteTimezoneSelector
  );
  const getEventDataByFilledId = (
    data: any,
    key: null | undefined | string
  ) => {
    if (typeof key !== 'undefined' && key !== null) {
      return data[key];
    }
    return null;
  };
  const showEventCard = (rowNumber: number, dayIndex: number) => {
    const { rosters } = data;
    const currentEvent = rosters[rowNumber][dayIndex];
    const event =
      currentEvent && currentEvent.id
        ? getEventDataByFilledId(events, currentEvent.id)
        : null;

    if (event) {
      return <EventCard eventId={event.id} hasAddButton={false} />;
    }

    return null;
  };
  return (
    <EventsRow
      data={data}
      events={events}
      weekDays={weekDays}
      langPreferences={langPreferences}
      currencyCode={currencyCode}
      currencyPlacement={currencyPlacement}
      getData={getEventDataByFilledId}
      numberFormat={numberFormat}
      showEventCard={showEventCard}
      currentTimeBySiteTimezone={currentTimeBySiteTimezone}
    />
  );
};
