import React from 'react';
import { NativeTableElement } from '../type';
import { getClass } from '../../_lib/helper';
import './NativeTableTr.scss';

type NativeTableTrProps = NativeTableElement & {};

function NativeTableTr({ children, className, valign }: NativeTableTrProps) {
  return (
    <tr
      className={getClass('elmo-nativetable-tr', {
        className,
        [`elmo-nativetable-tr__valign-${valign}`]: true,
      })}
    >
      {children}
    </tr>
  );
}

NativeTableTr.defaultProps = {
  valign: 'top',
};

export default NativeTableTr;
