import React from 'react';
import { ArrowBackIcon, CloseIcon } from '../icons';
import { IconButton } from '../../extended-oxygen-elements';

type Props = {
  onClose: () => void;
};

export const PageDialogBackIconButton = ({ onClose }: Props) => (
  <IconButton
    aria-label="back"
    onClick={onClose}
    sx={{
      color: (theme) => theme.palette.grey[500],
      marginRight: '15px',
      marginTop: '-3px',
      marginLeft: '-10px',
    }}
    id={'back-to-main-modal-btn'}
  >
    <ArrowBackIcon />
  </IconButton>
);
