import { createStyles, makeStyles } from '@mui/styles';

const useShiftCardEventStyles = makeStyles(() =>
  createStyles({
    root: {},
    btnDelete: {
      opacity: 0,
      visibility: 'hidden',
      '$root:hover &': {
        opacity: 1,
        visibility: 'visible',
      },
    },
    btnAdd: {
      opacity: 0,
      visibility: 'hidden',
      '$root:hover &': {
        opacity: 1,
        visibility: 'visible',
      },
    },
  })
);

export default useShiftCardEventStyles;
