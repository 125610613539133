import { Api, OVERLAP_ERROR_STATUS } from 'lib/Api';
import {
  AllowancesRequest,
  AllowancesResponse,
  ApproveTimesheetPayload,
  TimesheetAllowanceRequest,
  TimesheetAllowanceResponse,
  TimesheetCreateRequest,
  TimesheetDeleteAllowanceResponse,
  TimesheetDeleteRequest,
  TimesheetRecalculateRequest,
  TimesheetUpdateRequest,
  TimesheetUpdateResponse,
} from 'lib/Api/type';
import browserHistory from 'lib/browserHistory';
import { SERVER_DAY_FORMAT } from 'lib/config';
import routeQuery from 'lib/routeQuery';
import moment from 'moment';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { formatError, getErrorStatus, relocateToShift } from 'state/helpers';
import {
  RecalculatePayload,
  RecalculateResponse,
  RosteredShift,
  RosterPageQuery,
  SagaAction,
  SagaReturnType,
  StringMap,
  Timesheet,
} from 'type';
import { isSiteView } from '../../helpers';
import { hasPermissionsSelector } from '../Auth';
import { BOX_MY_TIMESHEET_SET_HASH } from '../EmployeeDashboard/MyTimesheet/actions';
import { createTimesheetHash } from '../EmployeeDashboard/MyTimesheet/helpers';
import { processApiRequest } from '../ProcessApiRequest';
import {
  BOX_ROSTER_CONTEXTUAL_MENU_SET_IGNORE_ERROR,
  BOX_ROSTER_CONTEXTUAL_MENU_SET_INPROGRESS,
} from '../Roster/ContextualMenu/actions';
import {
  contextInProgress,
  getIgnoreValue,
  getObjectId,
} from '../Roster/ContextualMenu/selectors';
import { BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN } from '../Roster/Roster/actions';
import { BOX_SUMMARY_GET_DATA_ON_ROSTER_UPDATES } from '../Roster/Summary/actions';
import {
  BOX_ROSTERED_SHIFTS_REMOVE_CONNECTED_TIMESHEET,
  BOX_ROSTERED_SHIFTS_UPDATE_CONNECTED_TIMESHEET,
} from '../RosteredShifts/actions';
import {
  BOX_TIMESHEET_DELETE,
  BOX_TIMESHEET_UPDATE_ONE,
  createTimesheetRequest,
  getTimesheet,
} from '../TimesheetsCollection';
import {
  BOX_TIMESHEET_SHIFT_MODAL_ADD_ALLOWANCE,
  BOX_TIMESHEET_SHIFT_MODAL_ADD_ALLOWANCE_FAILURE,
  BOX_TIMESHEET_SHIFT_MODAL_ADD_ALLOWANCE_SUCCESS,
  BOX_TIMESHEET_SHIFT_MODAL_APPROVE_FAILURE,
  BOX_TIMESHEET_SHIFT_MODAL_APPROVE_SUCCESS,
  BOX_TIMESHEET_SHIFT_MODAL_APPROVE_TIMESHEET,
  BOX_TIMESHEET_SHIFT_MODAL_CLOSE,
  BOX_TIMESHEET_SHIFT_MODAL_CREATE_SELF_ASSIGNED_SHIFT,
  BOX_TIMESHEET_SHIFT_MODAL_CREATE_SHIFT,
  BOX_TIMESHEET_SHIFT_MODAL_CREATE_SHIFT_CANCEL,
  BOX_TIMESHEET_SHIFT_MODAL_CREATE_SHIFT_FAILURE,
  BOX_TIMESHEET_SHIFT_MODAL_CREATE_SHIFT_SUCCESS,
  BOX_TIMESHEET_SHIFT_MODAL_DELETE_ALLOWANCE,
  BOX_TIMESHEET_SHIFT_MODAL_DELETE_ALLOWANCE_FAILURE,
  BOX_TIMESHEET_SHIFT_MODAL_DELETE_ALLOWANCE_SUCCESS,
  BOX_TIMESHEET_SHIFT_MODAL_DELETE_SHIFT,
  BOX_TIMESHEET_SHIFT_MODAL_DELETE_SHIFT_CONTEXT,
  BOX_TIMESHEET_SHIFT_MODAL_DELETE_SHIFT_FAILURE,
  BOX_TIMESHEET_SHIFT_MODAL_DELETE_SHIFT_SUCCESS,
  BOX_TIMESHEET_SHIFT_MODAL_GET_ALLOWANCES,
  BOX_TIMESHEET_SHIFT_MODAL_GET_ALLOWANCES_FAILURE,
  BOX_TIMESHEET_SHIFT_MODAL_GET_ALLOWANCES_SUCCESS,
  BOX_TIMESHEET_SHIFT_MODAL_GET_BY_ID,
  BOX_TIMESHEET_SHIFT_MODAL_GET_BY_ID_FAILURE,
  BOX_TIMESHEET_SHIFT_MODAL_GET_BY_ID_SUCCESS,
  BOX_TIMESHEET_SHIFT_MODAL_GET_HIGHER_DUTIES,
  BOX_TIMESHEET_SHIFT_MODAL_GET_HIGHER_DUTIES_FAILURE,
  BOX_TIMESHEET_SHIFT_MODAL_GET_HIGHER_DUTIES_SUCCESS,
  BOX_TIMESHEET_SHIFT_MODAL_GET_LIST_OF_SHIFTS,
  BOX_TIMESHEET_SHIFT_MODAL_GET_LIST_OF_SHIFTS_FAILURE,
  BOX_TIMESHEET_SHIFT_MODAL_GET_LIST_OF_SHIFTS_SUCCESS,
  BOX_TIMESHEET_SHIFT_MODAL_OVERLAP_CONFIRMATION_APPROVE,
  BOX_TIMESHEET_SHIFT_MODAL_OVERLAP_CONFIRMATION_CLOSE,
  BOX_TIMESHEET_SHIFT_MODAL_OVERLAP_CONFIRMATION_OPEN,
  BOX_TIMESHEET_SHIFT_MODAL_OVERLAP_CONFIRMATION_SET_DELETING,
  BOX_TIMESHEET_SHIFT_MODAL_RECALCULATE,
  BOX_TIMESHEET_SHIFT_MODAL_RECALCULATE_FAILURE,
  BOX_TIMESHEET_SHIFT_MODAL_RECALCULATE_SUCCESS,
  BOX_TIMESHEET_SHIFT_MODAL_SET_SHIFT,
  BOX_TIMESHEET_SHIFT_MODAL_UPDATE,
  BOX_TIMESHEET_SHIFT_MODAL_UPDATE_SHIFT_FAILURE,
  BOX_TIMESHEET_SHIFT_MODAL_UPDATE_SHIFT_SUCCESS
} from './actions';
import {
  AllowancePayload,
  CreateSelfAssignedShiftPayload,
  DeleteAllowancePayload,
  GetAllowancesPayload,
  GetTimesheetByIDPayload,
  HigherDutiesPayload,
  HigherDutiesResponse,
  ListOfAllowedShiftsPayload,
} from './types';

const getTimesheetById = function* ({
  payload,
}: SagaAction<GetTimesheetByIDPayload>): SagaIterator {
  if (payload.id === '') {
    yield put(
      BOX_TIMESHEET_SHIFT_MODAL_SET_SHIFT({
        ...payload,
        end: payload.end.isSameOrBefore(payload.start)
          ? payload.end.clone().add(1, 'day')
          : payload.end,
      })
    );
  } else {
    try {
      const response: Timesheet = yield call(
        processApiRequest,
        Api.Timesheet.getById,
        payload.id
      );
      yield put(BOX_TIMESHEET_SHIFT_MODAL_GET_BY_ID_SUCCESS(response));
    } catch (error) {
      yield put(
        BOX_TIMESHEET_SHIFT_MODAL_GET_BY_ID_FAILURE(formatError(error))
      );
    }
  }
};

export const getTimesheetRelatedData = function* (
  timesheet?: Timesheet
): SagaIterator {
  if (timesheet && timesheet.rostered_shift_id) {
    yield put(BOX_ROSTERED_SHIFTS_UPDATE_CONNECTED_TIMESHEET(timesheet));
  }
  yield put(BOX_SUMMARY_GET_DATA_ON_ROSTER_UPDATES());
};

const createTimesheet = function* (
  action: SagaAction<TimesheetCreateRequest>
): SagaIterator {
  try {
    const response: SagaReturnType<typeof createTimesheetRequest> = yield call(
      createTimesheetRequest,
      action.payload
    );

    if (response) {
      yield put(BOX_TIMESHEET_SHIFT_MODAL_CREATE_SHIFT_SUCCESS());
      yield put(BOX_TIMESHEET_SHIFT_MODAL_CLOSE());

      if (response && relocateToShift(response.start)) {
        const siteId = action.payload.site_id
          ? action.payload.site_id
          : response.site_id;

        const newPageQuery: RosterPageQuery = {
          site_id: siteId,
          day: action.payload.start.format(SERVER_DAY_FORMAT),
        };

        browserHistory.push({
          search: routeQuery.stringify(newPageQuery),
        });
      }

      yield call(getTimesheetRelatedData, response);

    } else {
      yield put(BOX_TIMESHEET_SHIFT_MODAL_CREATE_SHIFT_CANCEL());
    }
  } catch (error) {
    yield put(
      BOX_TIMESHEET_SHIFT_MODAL_CREATE_SHIFT_FAILURE(formatError(error))
    );
  }
};

const createSelfAssignedShift = function* ({
  payload,
}: SagaAction<CreateSelfAssignedShiftPayload>): SagaIterator {
  try {
    const hash = createTimesheetHash(payload as Timesheet);
    yield put(BOX_MY_TIMESHEET_SET_HASH(hash));

    const response = yield call(
      processApiRequest,
      Api.EmployeeDashboard.createTimesheet,
      payload
    );

    yield put(BOX_TIMESHEET_SHIFT_MODAL_CREATE_SHIFT_SUCCESS());
    yield put(BOX_TIMESHEET_SHIFT_MODAL_CLOSE());

  } catch (error) {
    const errorStatus = getErrorStatus(error);
    if (errorStatus === OVERLAP_ERROR_STATUS) {
      yield put(
        BOX_TIMESHEET_SHIFT_MODAL_OVERLAP_CONFIRMATION_OPEN(
          formatError(error)[0].split('\n')
        )
      );
    } else {
      yield put(
        BOX_TIMESHEET_SHIFT_MODAL_CREATE_SHIFT_FAILURE(formatError(error))
      );
    }
  }
};

const updateTimesheet = function* (
  action: SagaAction<TimesheetUpdateRequest>
): SagaIterator {
  try {
    const oldTimesheet = yield select(getTimesheet, action.payload.id);
    const response: TimesheetUpdateResponse = yield call(
      processApiRequest,
      Api.Timesheet.updateTimesheet,
      action.payload
    );

    yield put(BOX_TIMESHEET_UPDATE_ONE(response));

    yield put(BOX_TIMESHEET_SHIFT_MODAL_UPDATE_SHIFT_SUCCESS());
    yield put(BOX_TIMESHEET_SHIFT_MODAL_CLOSE());

    if (
      oldTimesheet.rostered_shift_id !== response.rostered_shift_id &&
      oldTimesheet.rostered_shift_id
    ) {
      yield put(
        BOX_ROSTERED_SHIFTS_REMOVE_CONNECTED_TIMESHEET(
          oldTimesheet.rostered_shift_id
        )
      );
    }

    if (response.rostered_shift_id) {
      yield put(BOX_ROSTERED_SHIFTS_UPDATE_CONNECTED_TIMESHEET(response));
    }

    if (relocateToShift(response.start)) {
      const siteId = action.payload.site_id
        ? action.payload.site_id
        : response.site_id;

      const newPageQuery: RosterPageQuery = {
        site_id: siteId,
        day: action.payload.start.format(SERVER_DAY_FORMAT),
      };

      browserHistory.push({
        search: routeQuery.stringify(newPageQuery),
      });
    }

    yield call(getTimesheetRelatedData, response);
  } catch (error) {
    const errorStatus = getErrorStatus(error);
    if (errorStatus === OVERLAP_ERROR_STATUS) {
      yield put(
        BOX_TIMESHEET_SHIFT_MODAL_OVERLAP_CONFIRMATION_OPEN(
          formatError(error)[0].split('\n')
        )
      );
    } else {
      yield put(
        BOX_TIMESHEET_SHIFT_MODAL_UPDATE_SHIFT_FAILURE(formatError(error))
      );
    }
  }
};

const deleteTimesheet = function* (
  action: SagaAction<TimesheetDeleteRequest>
): SagaIterator {
  try {
    yield call(processApiRequest, Api.Timesheet.delete, action.payload);

    yield put(BOX_TIMESHEET_SHIFT_MODAL_DELETE_SHIFT_SUCCESS());
    yield put(BOX_TIMESHEET_SHIFT_MODAL_CLOSE());
    yield put(BOX_TIMESHEET_DELETE(action.payload));
    yield put(BOX_SUMMARY_GET_DATA_ON_ROSTER_UPDATES());
  } catch (error) {
    const errorStatus = getErrorStatus(error);
    if (errorStatus === OVERLAP_ERROR_STATUS) {
      yield put(BOX_TIMESHEET_SHIFT_MODAL_OVERLAP_CONFIRMATION_SET_DELETING());
      yield put(
        BOX_TIMESHEET_SHIFT_MODAL_OVERLAP_CONFIRMATION_OPEN(
          formatError(error)[0].split('\n')
        )
      );
    } else {
      yield put(
        BOX_TIMESHEET_SHIFT_MODAL_DELETE_SHIFT_FAILURE(formatError(error))
      );
    }
  }
};

export const deleteTimesheetFromContext = function* (): SagaIterator {
  try {
    const id = yield select(getObjectId);
    const ignoreError = yield select(getIgnoreValue);

    yield put(BOX_ROSTER_CONTEXTUAL_MENU_SET_INPROGRESS(true));

    yield call(processApiRequest, Api.Timesheet.delete, {
      id: id,
      ignore_errors: ignoreError,
    });

    yield put(
      BOX_TIMESHEET_DELETE({
        id: id,
      })
    );

    yield put(BOX_TIMESHEET_SHIFT_MODAL_OVERLAP_CONFIRMATION_CLOSE(false));
    yield put(BOX_ROSTER_CONTEXTUAL_MENU_SET_INPROGRESS(false));
    yield put(BOX_ROSTER_CONTEXTUAL_MENU_SET_IGNORE_ERROR(false));
  } catch (error) {
    const errorStatus = getErrorStatus(error);
    if (errorStatus === OVERLAP_ERROR_STATUS) {
      yield put(BOX_TIMESHEET_SHIFT_MODAL_OVERLAP_CONFIRMATION_SET_DELETING());
      yield put(
        BOX_TIMESHEET_SHIFT_MODAL_OVERLAP_CONFIRMATION_OPEN(
          formatError(error)[0].split('\n')
        )
      );
    } else {
      yield put(BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN(formatError(error)));
      yield put(BOX_ROSTER_CONTEXTUAL_MENU_SET_INPROGRESS(false));
    }
  }
};

const approveTimesheet = function* (
  action: SagaAction<ApproveTimesheetPayload>
): SagaIterator {
  try {
    const response: Timesheet = yield call(
      processApiRequest,
      Api.Timesheet.approve,
      action.payload
    );

    yield put(BOX_TIMESHEET_UPDATE_ONE(response));

    yield put(BOX_TIMESHEET_SHIFT_MODAL_CLOSE());
    yield put(BOX_TIMESHEET_SHIFT_MODAL_APPROVE_SUCCESS());
    yield put(BOX_TIMESHEET_SHIFT_MODAL_CLOSE());

    yield call(getTimesheetRelatedData, response);
  } catch (error) {
    const errorStatus = getErrorStatus(error);
    if (errorStatus === OVERLAP_ERROR_STATUS) {
      yield put(
        BOX_TIMESHEET_SHIFT_MODAL_OVERLAP_CONFIRMATION_OPEN(
          formatError(error)[0].split('\n')
        )
      );
      yield put(BOX_TIMESHEET_SHIFT_MODAL_OVERLAP_CONFIRMATION_APPROVE());
    } else {
      yield put(BOX_TIMESHEET_SHIFT_MODAL_APPROVE_FAILURE(formatError(error)));
    }
  }
};

export const getListOfShifts = function* (
  action: SagaAction<ListOfAllowedShiftsPayload>
): SagaIterator {
  const canEditRosterAndTimesheet = yield select(hasPermissionsSelector, [
    'roster.rosteredshift.edit',
    'roster.timesheet.edit',
  ]);

  if (!canEditRosterAndTimesheet) {
    return;
  }

  try {
    const response: StringMap<RosteredShift> = yield call(
      processApiRequest,
      Api.RosteredShift.getListOfAllowedShifts,
      action.payload
    );
    yield put(BOX_TIMESHEET_SHIFT_MODAL_GET_LIST_OF_SHIFTS_SUCCESS(response));
  } catch (error) {
    yield put(
      BOX_TIMESHEET_SHIFT_MODAL_GET_LIST_OF_SHIFTS_FAILURE(formatError(error))
    );
  }
};

const getRecalculatedEntries = function* ({
  payload,
}: SagaAction<RecalculatePayload>): SagaIterator {
  try {
    const apiPayload: TimesheetRecalculateRequest = payload;

    const response: RecalculateResponse = yield call(
      processApiRequest,
      Api.Timesheet.recalculate,
      apiPayload
    );
    yield put(BOX_TIMESHEET_SHIFT_MODAL_RECALCULATE_SUCCESS(response));
  } catch (error) {
    yield put(
      BOX_TIMESHEET_SHIFT_MODAL_RECALCULATE_FAILURE(formatError(error))
    );
  }
};

const getListOfHigherDuties = function* ({
  payload,
}: SagaAction<HigherDutiesPayload>): SagaIterator {
  try {
    const response: HigherDutiesResponse = yield call(
      processApiRequest,
      Api.EmployeeDashboard.getHigherDuties,
      payload
    );
    yield put(BOX_TIMESHEET_SHIFT_MODAL_GET_HIGHER_DUTIES_SUCCESS(response));
  } catch (error) {
    yield put(
      BOX_TIMESHEET_SHIFT_MODAL_GET_HIGHER_DUTIES_FAILURE(formatError(error))
    );
  }
};

export const getListOfAllowances = function* ({
  payload,
}: SagaAction<GetAllowancesPayload>): SagaIterator {
  try {
    const apiPayload: AllowancesRequest = payload;

    const response: AllowancesResponse = yield call(
      processApiRequest,
      Api.EmployeeDashboard.getAllowancesList,
      apiPayload
    );
    yield put(BOX_TIMESHEET_SHIFT_MODAL_GET_ALLOWANCES_SUCCESS(response));
  } catch (error) {
    yield put(
      BOX_TIMESHEET_SHIFT_MODAL_GET_ALLOWANCES_FAILURE(formatError(error))
    );
  }
};

export const addAllowances = function* ({
  payload: { id, allowance, quantity },
}: SagaAction<AllowancePayload>): SagaIterator {
  try {
    const apiPayload: TimesheetAllowanceRequest = {
      id,
      allowances: [
        {
          allowance,
          quantity,
        },
      ],
    };

    const response: TimesheetAllowanceResponse = yield call(
      processApiRequest,
      Api.Timesheet.allowance,
      apiPayload
    );
    yield put(BOX_TIMESHEET_SHIFT_MODAL_ADD_ALLOWANCE_SUCCESS(response));
  } catch (error) {
    yield put(
      BOX_TIMESHEET_SHIFT_MODAL_ADD_ALLOWANCE_FAILURE(formatError(error))
    );
  }
};

export const deleteAllowance = function* ({
  payload,
}: SagaAction<DeleteAllowancePayload>): SagaIterator {
  try {
    const response: TimesheetDeleteAllowanceResponse = yield call(
      processApiRequest,
      Api.Timesheet.deleteAllowance,
      payload
    );
    yield put(BOX_TIMESHEET_SHIFT_MODAL_DELETE_ALLOWANCE_SUCCESS(response));
  } catch (error) {
    yield put(
      BOX_TIMESHEET_SHIFT_MODAL_DELETE_ALLOWANCE_FAILURE(formatError(error))
    );
  }
};

const setPropsOnCancelOverlap = function* ({
  payload,
}: SagaAction<boolean>): SagaIterator {
  const inProgress = yield select(contextInProgress);
  // execute only if deleting from the context
  if (inProgress) {
    if (!payload) {
      yield put(BOX_ROSTER_CONTEXTUAL_MENU_SET_INPROGRESS(false));
    } else {
      yield call(deleteTimesheetFromContext);
    }
  }
};

export const watchTimesheetModal = function* (): SagaIterator {
  yield takeLatest(BOX_TIMESHEET_SHIFT_MODAL_GET_BY_ID, getTimesheetById);
  yield takeLatest(
    BOX_TIMESHEET_SHIFT_MODAL_CREATE_SHIFT,
    createTimesheet
  );
  yield takeLatest(
    BOX_TIMESHEET_SHIFT_MODAL_CREATE_SELF_ASSIGNED_SHIFT,
    createSelfAssignedShift
  );
  yield takeLatest(BOX_TIMESHEET_SHIFT_MODAL_UPDATE, updateTimesheet as any); // TODO double-check
  yield takeLatest(BOX_TIMESHEET_SHIFT_MODAL_DELETE_SHIFT, deleteTimesheet);
  yield takeLatest(
    BOX_TIMESHEET_SHIFT_MODAL_APPROVE_TIMESHEET,
    approveTimesheet
  );
  yield takeLatest(
    BOX_TIMESHEET_SHIFT_MODAL_GET_LIST_OF_SHIFTS,
    getListOfShifts
  );
  yield takeLatest(
    BOX_TIMESHEET_SHIFT_MODAL_RECALCULATE,
    getRecalculatedEntries
  );
  yield takeLatest(
    BOX_TIMESHEET_SHIFT_MODAL_GET_HIGHER_DUTIES,
    getListOfHigherDuties
  );
  yield takeLatest(
    BOX_TIMESHEET_SHIFT_MODAL_GET_ALLOWANCES,
    getListOfAllowances
  );
  yield takeLatest(BOX_TIMESHEET_SHIFT_MODAL_ADD_ALLOWANCE, addAllowances);
  yield takeLatest(BOX_TIMESHEET_SHIFT_MODAL_DELETE_ALLOWANCE, deleteAllowance);
  yield takeLatest(
    BOX_TIMESHEET_SHIFT_MODAL_DELETE_SHIFT_CONTEXT,
    deleteTimesheetFromContext
  );
  yield takeLatest(
    BOX_TIMESHEET_SHIFT_MODAL_OVERLAP_CONFIRMATION_CLOSE,
    setPropsOnCancelOverlap
  );
};
