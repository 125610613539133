import { createAction } from 'lib/store-utils';
import { FormattedErrors, UpdateCurrentUserPayload, UserProfileFields } from 'type';
import {
  AddRolePayload,
  UpdateUserProfilePayload,
  CustomFileFailurePayload,
  CustomFileRequestPayload,
  EditApproversPayload,
  AwardsModalPayload,
  AssignAwardPayload, UpdateAwardPayload, AssignedAward
} from './types';

export const BOX_USER_PROFILE_REQUEST = createAction<string>('User Profile / UserProfile / Get profile page');
export const BOX_USER_PROFILE_SUCCESS = createAction<any>('User Profile / UserProfile / Get profile page success');
export const BOX_USER_PROFILE_FAILURE = createAction<FormattedErrors>('User Profile / UserProfile / Get profile page failure');

export const BOX_USER_PROFILE_UPLOAD_REMOVE = createAction<string>('User Profile / Remove image');
export const BOX_USER_PROFILE_UPLOAD_APPEND = createAction<string>('User Profile / Add image');

export const BOX_USER_PROFILE_UPLOAD_REQUEST = createAction<Partial<any>>('User Profile / Upload file');
export const BOX_USER_PROFILE_UPLOAD_SUCCESS = createAction<any>('User Profile / Upload file success');
export const BOX_USER_PROFILE_UPLOAD_FAILURE = createAction<FormattedErrors>('User Profile / Upload file failed');

export const BOX_USER_PROFILE_UPDATE_REQUEST = createAction<Partial<UserProfileFields>>('User Profile / Update profile settings');
export const BOX_USER_PROFILE_UPDATE_SUCCESS = createAction<UserProfileFields>('User Profile / Update profile settings success');
export const BOX_USER_PROFILE_UPDATE_FAILURE = createAction<FormattedErrors>('User Profile / Update profile settings failed');

export const BOX_USER_PROFILE_REMOVE_ERRORS = createAction('User Profile / Remove error messages');

export const BOX_USER_PROFILE_CUSTOM_MODAL_OPEN = createAction('User Profile / Open custom fields modal');
export const BOX_USER_PROFILE_CUSTOM_MODAL_CLOSE = createAction('User Profile / Close custom fields modal');

export const BOX_USER_PROFILE_UPLOAD_MODAL_OPEN = createAction('User Profile / Open upload avatar modal');
export const BOX_USER_PROFILE_UPLOAD_MODAL_CLOSE = createAction('User Profile / Close upload avatar modal');

export const BOX_USER_PROFILE_DETAILS_MODAL_OPEN = createAction('User Profile / Open user details modal');
export const BOX_USER_PROFILE_DETAILS_MODAL_CLOSE = createAction('User Profile / Close user details modal');

export const BOX_USER_PROFILE_PROFILE_MODAL_OPEN = createAction('User Profile / Open profile details modal');
export const BOX_USER_PROFILE_PROFILE_MODAL_CLOSE = createAction('User Profile / Close profile details modal');

export const BOX_USER_PROFILE_ROLES_MODAL_OPEN = createAction('User Profile / Open roles modal');
export const BOX_USER_PROFILE_ROLES_MODAL_CLOSE = createAction('User Profile / Close roles modal');

export const BOX_USER_PROFILE_PAYMENT_MODAL_OPEN = createAction('User Profile / Open payment details modal');
export const BOX_USER_PROFILE_PAYMENT_MODAL_CLOSE = createAction('User Profile / Close payment details modal');

export const BOX_USER_PROFILE_RULES_MODAL_OPEN = createAction('User Profile / Open rules modal');
export const BOX_USER_PROFILE_RULES_MODAL_CLOSE = createAction('User Profile / Close rules modal');

export const BOX_USER_PROFILE_ADD_ROLE_REQUEST = createAction<AddRolePayload>('User Profile / Add role request');
export const BOX_USER_PROFILE_ADD_ROLE_UPDATE_USER_PROFILE = createAction<UpdateUserProfilePayload>('User Profile / add role / update user profile');
export const BOX_USER_PROFILE_ADD_ROLE_SUCCESS = createAction('User Profile / Add role success');
export const BOX_USER_PROFILE_ADD_ROLE_FAILURE = createAction<FormattedErrors>('User Profile / Add role failure');

export const BOX_USER_PROFILE_EDIT_APPROVALS_MODAL_OPEN = createAction<string>('User Profile / edit approvals modal open');
export const BOX_USER_PROFILE_EDIT_APPROVALS_MODAL_CLOSE = createAction('User Profile / edit approvals modal close');
export const BOX_USER_PROFILE_EDIT_APPROVALS_REQUEST = createAction<EditApproversPayload>('User Profile / edit approvals request');
export const BOX_USER_PROFILE_EDIT_APPROVALS_SUCCESS = createAction<EditApproversPayload>('User Profile / edit approvals success');
export const BOX_USER_PROFILE_EDIT_APPROVALS_FAILURE = createAction<FormattedErrors>('User Profile / edit approvals failure');

export const BOX_USER_PROFILE_GET = createAction<string>('User Profile / Get user profile');

export const BOX_USER_PROFILE_CUSTOM_FILE_REQUEST = createAction<CustomFileRequestPayload>('User Profile / Upload file for custom field');
export const BOX_USER_PROFILE_CUSTOM_FILE_SUCCESS = createAction<CustomFileRequestPayload>('User Profile / Upload file for custom field');
export const BOX_USER_PROFILE_CUSTOM_FILE_FAILURE = createAction<CustomFileFailurePayload>('User Profile / Upload file for custom field failure');

export const BOX_USER_PROFILE_PIN_OPEN_MODAL = createAction('User Profile / Open Pin modal');
export const BOX_USER_PROFILE_PIN_CLOSE_MODAL = createAction('User Profile / Close Pin modal');

export const BOX_USER_PROFILE_ENTITLEMENTS_REQUEST = createAction<string>('User Profile / Get entitlements');
export const BOX_USER_PROFILE_ENTITLEMENTS_SUCCESS = createAction<any>('User Profile / Get entitlements success');
export const BOX_USER_PROFILE_ENTITLEMENTS_FAILURE = createAction<FormattedErrors>('User Profile / Get entitlements failure');

export const BOX_USER_PROFILE_SAVE_CURRENT_USER_SETTINGS = createAction<UpdateCurrentUserPayload>('User Profile / Save Current user');

export const BOX_USER_PROFILE_AWARDS_REQUEST = createAction<{userId: string}>('User Profile / Get awards');
export const BOX_USER_PROFILE_AWARDS_SUCCESS = createAction<any>('User Profile / Get awards success');
export const BOX_USER_PROFILE_AWARDS_FAILURE = createAction<FormattedErrors>('User Profile / Get awards failure');

export const BOX_USER_PROFILE_OPEN_AWARDS_MODAL = createAction<AwardsModalPayload | null>('User Profile/Awards modal opened');
export const BOX_USER_PROFILE_CLOSE_AWARDS_MODAL = createAction('User Profile/Awards modal closed');

export const BOX_USER_PROFILE_AWARDS_SAVE = createAction<AssignAwardPayload>('Awards / Save awards');
export const BOX_USER_PROFILE_AWARDS_SAVE_SUCCESS = createAction<any>('Awards / Save awards');
export const BOX_USER_PROFILE_AWARDS_SAVE_FAILURE = createAction<FormattedErrors>('Awards / Save awards');

export const BOX_USER_PROFILE_AWARDS_MODALS_CLEAR_ERRORS = createAction('Award Modals / Clear save/delete/edit awards errors');

export const BOX_USER_PROFILE_AWARDS_DELETE_MODAL_OPEN = createAction<string>('Awards / Open delete confirmation modal');
export const BOX_USER_PROFILE_AWARDS_DELETE_MODAL_CLOSE = createAction('Awards / Close delete confirmation modal');

export const BOX_USER_PROFILE_AWARDS_DELETE_REQUEST = createAction<string>('Awards / Delete award');
export const BOX_USER_PROFILE_AWARDS_DELETE_SUCCESS = createAction<string>('Awards / Delete award success');
export const BOX_USER_PROFILE_AWARDS_DELETE_FAILURE = createAction<FormattedErrors>('Awards / Delete award failure');

export const BOX_USER_PROFILE_AWARDS_UPDATE_AWARD = createAction<UpdateAwardPayload>('Awards / Update award');
export const BOX_USER_PROFILE_AWARDS_UPDATE_AWARD_SUCCESS = createAction<AssignedAward>('Awards / Update award success');
export const BOX_USER_PROFILE_AWARDS_UPDATE_AWARD_FAILURE = createAction<FormattedErrors>('Awards / Update award failure');
