import React from 'react';
import { useSelectorWithProps } from 'hooks';
import { getRosteredShift } from 'state/RosteredShifts';
import { useAreaName, useRosterDayCard, useUserName } from './hooks';
import {
  ShiftCardBreaksDuration,
  ShiftCardIcons,
  ShiftCardViewProps,
} from './views';
import { ShiftCardViewRefactored } from './views/ShiftCardViewRefactored';

type Props = Pick<Required<ShiftCardViewProps>, 'shape'> & {
  rosteredShiftId: string;
};

export const SiteRosterDayCard = React.memo(function SiteRosterDayCard({
  rosteredShiftId,
  ...restProps
}: Props) {
  const rosteredShift = useSelectorWithProps(getRosteredShift, rosteredShiftId);
  const { viewProps, icons, breaks } = useRosterDayCard(rosteredShift);
  const userName = useUserName(rosteredShift);
  const areaName = useAreaName(rosteredShift);

  return (
    <ShiftCardViewRefactored
      {...viewProps}
      data-shift-id={rosteredShiftId}
      icons={<ShiftCardIcons icons={icons} />}
      breaks={<ShiftCardBreaksDuration breaks={breaks} />}
      title={userName}
      spotTitle={areaName}
      {...restProps}
    />
  );
});
