import React, { ReactNode, useEffect } from 'react';
import { connect } from 'react-redux';
import { Duration, Moment } from 'moment';
import { StoreState } from 'state/types';
import {
  getPreferencesTimeInterval,
  timeFormatSelector
} from 'state/Account';
import {
  dayInMinutesSelector,
  oneMinuteInPxSelector
} from 'state/Roster/RosterDayView';
import { IsResizable } from '../../types';
import { ResizableWrapperView } from './ResizableWrapperView';
import {
  onResizeEndCreator,
  resizeHandlerCreator,
  useMarkers,
  usePosition,
  useResizing
} from './hooks';
import './ResizableWrapper.scss';

type OwnProps = {
  id: string;
  day: Moment; // current day in site timezone
  start: Duration;
  end: Duration;
  children: ReactNode;
  onResizeEnd: (payload: {
    id: string;
    start: Duration;
    end: Duration;
  }) => void;
  isResizable: IsResizable;
  clientX?: number;
};

type StateProps = {
  timeFormat: string;
  resizeStep: number;
  oneMinuteInPx: number;
  dayInMinutes: number;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

function ResizableWrapper({
  id,
  start,
  end,
  children,
  isResizable,
  timeFormat,
  onResizeEnd,
  clientX = NaN,
  resizeStep,
  oneMinuteInPx,
  dayInMinutes,
  day
}: Props) {
  const wrapper = React.useRef<HTMLDivElement>(null);

  const [startTimeMins, setStartTimeMins] = React.useState(start.asMinutes());
  const [endTimeMins, setEndTimeMins] = React.useState(end.asMinutes());

  useEffect(() => {
    setStartTimeMins(start.asMinutes());
    setEndTimeMins(end.asMinutes());
  }, [start, end]);

  const position = usePosition({
    startTimeMins,
    endTimeMins,
    oneMinuteInPx
  });

  const { isResizing, startResizeRight, startResizeLeft } = useResizing({
    wrapper,
    position,
    setLeft: resizeHandlerCreator({
      value: startTimeMins,
      set: setStartTimeMins,
      oppositeValue: endTimeMins,
      resizeStep,
      getWidthMins: newValue => endTimeMins - newValue,
      oneMinuteInPx,
      dayInMinutes
    }),
    setRight: resizeHandlerCreator({
      value: endTimeMins,
      set: setEndTimeMins,
      oppositeValue: startTimeMins,
      resizeStep,
      getWidthMins: newValue => newValue - startTimeMins,
      oneMinuteInPx,
      dayInMinutes
    }),
    onResizeEnd: onResizeEndCreator({
      startTimeMins,
      endTimeMins,
      id,
      resizeStep,
      onResizeEnd
    }),
    isResizing: !isNaN(clientX)
  });

  const markers = useMarkers({
    startTimeMins,
    endTimeMins,
    timeFormat,
    day
  });

  return (
    <ResizableWrapperView
      ref={wrapper}
      left={position.left}
      width={position.width}
      onResizeLeft={startResizeLeft}
      onResizeRight={startResizeRight}
      isResizable={isResizable}
      isResizing={isResizing}
      markerLeft={markers.left}
      markerRight={markers.right}
    >
      {children}
    </ResizableWrapperView>
  );
}

const mapStateToProps = (state: StoreState): StateProps => ({
  timeFormat: timeFormatSelector(state),
  resizeStep: getPreferencesTimeInterval(state),
  oneMinuteInPx: oneMinuteInPxSelector(state),
  dayInMinutes: dayInMinutesSelector(state)
});

// const mapDispatchToProps: DispatchProps = {};

export default connect(mapStateToProps)(ResizableWrapper);
