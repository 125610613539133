import React, { useState } from 'react';
import {
  Box,
  Button,
  Popover,
  Typography,
} from 'extended-oxygen-elements';
import {
  createStyles,
  makeStyles,
} from '@mui/styles';
import moment, { Moment } from 'moment';
import { useDispatch } from 'react-redux';
import { DateType, TimesheetBreak } from 'type';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
  useSelectorWithProps,
  useTimesheetsNewQuery
} from 'hooks';
import { BreakIcon, FormattedDuration } from 'element';
import { SERVER_DATE_TIME_FORMAT, SERVER_DAY_FORMAT, TIMESHEET_MAX_BREAKS_NUMBER } from 'lib/config';
import { updateDatesOfBreaksForMidnightShifts } from 'lib/helpers';
import { BOX_TIMESHEETS_WEEKLY_TIMESHEET_CREATE } from 'state/TimesheetsWeeklyPage';
import { userRolesBySiteSelectorAsOptions } from 'state/UsersCollection';
import {
  useIsEditableView,
} from 'page/TimesheetsWeekly/hooks';
import {
  AddPlaceholder,
  Breaks,
  Controls,
  TimeField,
  UserRolesMenu,
} from './components';

type Props = {
  userId: string;
  day: DateType;
};

type Option = {
  label: string;
  value: string;
};

const usePopoverStyles = makeStyles(() =>
  createStyles({
    paper: {
      marginTop: 0,
    },
  })
);

const useFormStyles = makeStyles((theme) =>
  createStyles({
    divider: {
      marginRight: theme.spacing(1 / 4),
      marginLeft: theme.spacing(1 / 4),
    },
    button: {
      marginTop: 10,
      width: '100%',
    },
  })
);

export function EmptyTile(props: Props) {
  const isEditable = useIsEditableView(props);
  const params = useTimesheetsNewQuery();

  if (!isEditable) {
    return null;
  }

  return <PlaceholderWithForm {...props} siteId={params.query.site_id} />;
}

type FormProps = {
  siteId: string
};

export function PlaceholderWithForm(props: Props & FormProps) {
  const popupState = usePopupState();
  const popoverClasses = usePopoverStyles();
  const formStyles = useFormStyles();
  const dispatch = useDispatch();
  const userRoles = useSelectorWithProps(
    userRolesBySiteSelectorAsOptions,
    props.userId,
    props.siteId
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [roleId, setRoleId] = useState<string | undefined>(
    getIdByKey(userRoles[0], 'role')
  );
  const [areaId, setAreaId] = useState<string | undefined>(
    getIdByKey(userRoles[0], 'area')
  );
  const [start, setStart] = useState<null | Moment>(null);
  const [end, setEnd] = useState<null | Moment>(null);
  const [breaks, setBreak] = useState<TimesheetBreak[]>([]);

  const addBreak = () => {
    if (!start || !end) {
      return false;
    }
    const breakItem: any = [
      {
        paid: false,
        start: moment(props.day, SERVER_DAY_FORMAT).set({
          hours: 12,
          minutes: 0,
        }),
        duration: 0,
      },
    ];
    setBreak((prev) => [...prev, ...breakItem]);
  };

  const handleAnchorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(popupState.anchorEl);
  };

  const handleCloseRolesMenu = () => {
    setAnchorEl(null);
  };

  const handleRoleSelection = (
    event: React.MouseEvent<HTMLLIElement>,
    value: Option
  ) => {
    // Capture new values
    const newRoleId = getIdByKey(value, 'role');
    const newAreaId = getIdByKey(value, 'area');

    // Set new values
    setRoleId(newRoleId);
    setAreaId(newAreaId);

    // Call createTimesheetRequest in the next effect cycle using a useEffect hook
    setTimeout(() => {
      createTimesheetRequest(newRoleId, newAreaId);
    }, 0);
}
    const onStartChange = (value: Moment | null) => {
      setStart(setTime(props.day, value));
    };

  const onEndChange = (value: Moment | null) => {
    const endTime = setTime(props.day, value);
    if (start && endTime) {
      if (start.isSameOrAfter(endTime)) {
        endTime.add(1, 'day');
      }
    }
    setEnd(endTime);
  };

  const onChangeBreaks = (
    value: number | null | Moment | boolean,
    key: string,
    index: number
  ) => {
    setBreak(
      breaks.map((item, i) => (i === index ? { ...item, [key]: value } : item))
    );
  };

  const correctedBreaks = (start: Moment) => {
    return updateDatesOfBreaksForMidnightShifts(
      breaks.filter((item) => !!item.start),
      start
    ).map(({ start: breakStart, ...restBreak }) => ({
      ...restBreak,
      start: breakStart.format(SERVER_DATE_TIME_FORMAT)
    }));
  };

  const createTimesheetRequest = (roleId: string | undefined, areaId: string | undefined) => {
    if (roleId && areaId && start && end) {
      const payload = {
        user_id: props.userId,
        role_id: roleId,
        area_id: areaId,
        breaks: correctedBreaks(start),
        start: start.format(SERVER_DATE_TIME_FORMAT),
        end: end.format(SERVER_DATE_TIME_FORMAT),
        site_id: props.siteId,
        notes: null,
      };
      dispatch(BOX_TIMESHEETS_WEEKLY_TIMESHEET_CREATE.request(payload));
      popupState.close();
    }
  };

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (userRoles.length > 1) {
      setAnchorEl(popupState.anchorEl);
    } else {
      createTimesheetRequest(getIdByKey(userRoles[0], 'role'), getIdByKey(userRoles[0], 'area'));
    }
  };

  const handleClickCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    popupState.close();
  };

  const removeBreakItem = (index: number) => {
    const breaksClone = [...breaks];
    breaksClone.splice(index, 1);
    setBreak(breaksClone);
  };

  const durationInMinutes = end && start ? end.diff(start, 'minutes') : 0;

  return (
    <>
      <AddPlaceholder {...bindTrigger(popupState)} data-testid={'add-timesheet-placeholder'} />
      <Popover
        {...bindPopover(popupState)}
        classes={popoverClasses}
        keepMounted={false}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <form onSubmit={onSubmit} autoComplete={'off'} data-testid={'empty-tile'}>
          <Box p={1}>
            <Box textAlign="center">
              <Typography variant="h6">
                <FormattedDuration>{durationInMinutes}</FormattedDuration>
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" className={'focus-input'}>
              <TimeField
                value={start}
                name={'start'}
                onChange={onStartChange}
                placeholder={'8:00'}
                submitOnEnter={true}
                autoFocus={!breaks.length}
                testid={'start-field-wrapper'}
              />
              <span className={formStyles.divider}>-</span>
              <TimeField
                value={end}
                name={'end'}
                onChange={onEndChange}
                placeholder={'16:00'}
                submitOnEnter={true}
                testid={'end-field-wrapper'}
              />
            </Box>
            <Breaks
              onChangeBreak={onChangeBreaks}
              breaks={breaks}
              removeBreakItem={removeBreakItem}
              canRemoveBreak={true}
            />
            <Box>
              {breaks.length < TIMESHEET_MAX_BREAKS_NUMBER && (
                <Button
                  size="small"
                  onClick={addBreak}
                  startIcon={<BreakIcon />}
                  className={formStyles.button}
                  disabled={!start && !end}
                  data-testid={'add-break-btn'}
                >
                  Add break
                </Button>
              )}
            </Box>
            {/* TODO: make visible on mobile */}
            <div
              style={{ visibility: 'hidden', maxHeight: 0, overflow: 'hidden' }}
            >
              <Controls
                roles={userRoles}
                handleAnchorClick={handleAnchorClick}
                handleClickCancel={handleClickCancel}
              />
            </div>
          </Box>
        </form>
        <UserRolesMenu
          roles={userRoles}
          anchorEl={anchorEl}
          handleCloseRolesMenu={handleCloseRolesMenu}
          handleRoleSelection={handleRoleSelection}
        />
      </Popover>
    </>
  );
}

function setTime(day: DateType, value: Moment | null) {
  return !value
    ? null
    : moment(day, SERVER_DAY_FORMAT).set({
        hours: value.hours(),
        minutes: value.minutes(),
      });
}

function getIdByKey(option: Option | undefined, key: string) {
  if (!option) {
    return undefined;
  }
  const params = option.value.split('__');
  return key === 'area' ? params[0] : params[1];
}
