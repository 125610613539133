import React from 'react';
import { isAppMarket } from 'helpers';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from 'extended-oxygen-elements';
import { PageDialog, PageDialogCancel, PageDialogSubmit } from 'element';

type Props = {
  isLoading: boolean;
  isOpened: boolean;
  onClose: () => void;
  onApprove: () => void;
  message?: string;
  title?: string;
};

export const PastConfirmationModal = ({
  isLoading,
  isOpened,
  onApprove,
  onClose,
  message,
  title,
}: Props) => (
  <PageDialog maxWidth={'xs'} open={isOpened} id="past-confirmation-modal">
    <DialogTitle>
      {title
        ? title
        : `Do you wish to insert copied ${
            isAppMarket('uk') ? 'rotas' : 'rosters'
          } into the current week?`}
    </DialogTitle>
    <DialogContent>
      <Typography variant={'body1'}>
        {message
          ? message
          : `There might be some conflicts in case of shifts overlap. If you wish to
          proceed system will unassign already existing overlapping shifts. In case
          unassign is not possible conflicting shifts will not be created.`}
      </Typography>
    </DialogContent>
    <DialogActions>
      <PageDialogCancel onClick={onClose}>Cancel</PageDialogCancel>
      <PageDialogSubmit
        onClick={onApprove}
        disabled={isLoading}
        loading={isLoading}
        id="approve-pasting-btn"
      >
        Confirm
      </PageDialogSubmit>
    </DialogActions>
  </PageDialog>
);
