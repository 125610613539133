import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import { BOX_SHIFT_TRADES_CONFIRMATION_MODAL_CLOSE } from 'state/EmployeeDashboard/ShiftTrades';
import { ConfirmShiftModalProps } from 'state/EmployeeDashboard/ShiftTrades/types';
import { replaceErrorText } from 'lib/helpers';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from 'extended-oxygen-elements';
import { PageDialog, PageDialogCancel, PageDialogSubmit } from 'element';

type OwnProps = {
  confirmationModal: ConfirmShiftModalProps;
  confirmOverlap: (ignoreErrors: boolean) => void;
};

type DispatchProps = {
  closeModal: (isUpdating: boolean) => void;
};

type Props = OwnProps & DispatchProps;

class TradeConfirmationModal extends Component<Props> {
  render() {
    return (
      <PageDialog
        maxWidth={'xs'}
        open={this.props.confirmationModal.isOpened}
        id={'shift-swap-confirm-modal'}
      >
        <DialogTitle>Do you want to approve this?</DialogTitle>
        <DialogContent>
          {replaceErrorText(this.props.confirmationModal.errors).map(
            (error: string, index: number) => (
              <Typography variant={'body1'} key={index}>
                {error}
              </Typography>
            )
          )}
        </DialogContent>
        <DialogActions>
          <PageDialogCancel data-testid="swap-confirm-cancel-btn" onClick={() => this.props.closeModal(false)}>
            No
          </PageDialogCancel>
          <PageDialogSubmit onClick={this.onClickHandler} data-testid="swap-confirm-submit-btn">
            Yes, approve it
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  }

  onClickHandler = () => {
    this.props.confirmOverlap(true);
    this.props.closeModal(true);
  };
}

const mapStateToProps = (state: StoreState) => ({
  confirmationModal: state.employeeDashboard.shiftTrades.confirmationModal,
});

const mapDispatchProps: DispatchProps = {
  closeModal: BOX_SHIFT_TRADES_CONFIRMATION_MODAL_CLOSE,
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(TradeConfirmationModal);
