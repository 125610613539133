import React from 'react';

type ContextValue = {
  id: string;
  openDialog: (timesheetId: string) => void;
  closeDialog: () => void;
};

export default function makeEditContext() {
  const Context = React.createContext({} as ContextValue);

  type ProviderProps = {
    children: React.ReactNode;
  };

  function Provider({ children }: ProviderProps) {
    const [id, setId] = React.useState('');

    const openDialog = (timesheetId: string) => {
      setId(timesheetId);
    };
    const closeDialog = () => {
      setId('');
    };

    return (
      <Context.Provider value={{ id, openDialog, closeDialog }}>
        {children}
      </Context.Provider>
    );
  }

  function useContext() {
    return React.useContext(Context);
  }

  return { Provider, useContext };
}
