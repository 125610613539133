import { createAction } from 'lib/store-utils';
import { SetIdsPayload, ToggleSelectedIdPayload } from './types';

export const BOX_BULK_SELECT_ALL_VISIBLE = createAction<boolean>('Bulk / select all visible');
export const BOX_BULK_SELECT_ALL_AVAILABLE = createAction<boolean>('Bulk / select all available');
export const BOX_BULK_TOGGLE_SELECTED_ID = createAction<ToggleSelectedIdPayload>('Bulk / toggle selected id');
export const BOX_BULK_CLEAR_SELECTED_IDS = createAction('Bulk / clear selected ids');
export const BOX_BULK_CLEAR_SELECTED_IF_ALL_ITEMS_CHANGED = createAction('Bulk / clear selected ids if all items changed');

export const BOX_BULK_CLEAR_STATE = createAction('Bulk / clear state');
export const BOX_BULK_SET_ALL_IDS = createAction<SetIdsPayload>('Bulk / set all ids');
export const BOX_BULK_SET_PAGE_IDS = createAction<SetIdsPayload>('Bulk / set page ids');
export const BOX_BULK_SET_ALL_VISIBLE = createAction<boolean>('Bulk / set all visible');
export const BOX_BULK_SET_SELECTED_IDS = createAction<string[]>('Bulk / update selected ids');
