import { createReducer } from 'lib/store-utils';
import { UserApprovalsReducerState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';

export const userApprovals = createReducer<UserApprovalsReducerState>(
  {},
  getDefaultState()
);

userApprovals.on(
  actions.BOX_USER_APPROVALS_MODAL_GET_APPROVALS_REQUEST,
  (state): UserApprovalsReducerState => ({
    ...state,
    approvers: {
      ...state.approvers,
      isFetching: true
    }
  })
);

userApprovals.on(
  actions.BOX_USER_APPROVALS_MODAL_GET_APPROVALS_SUCCESS,
  (state, { userIds, approvers }): UserApprovalsReducerState => ({
    ...state,
    approvers: {
      ...state.approvers,
      isFetching: false,
      isFetched: true,
      userIds,
      approvers
    }
  })
);

userApprovals.on(
  actions.BOX_USER_APPROVALS_MODAL_GET_APPROVALS_FAILURE,
  (state, errors): UserApprovalsReducerState => ({
    ...state,
    approvers: {
      ...state.approvers,
      isFetching: false,
      isFetched: false,
      errors
    }
  })
);
