import { Moment } from 'moment';
import { useFormatTime } from './hooks';

export const usePlaceholderCard = (props: {
  from: Moment;
  shape: 'full' | 'top' | 'bottom';
}) => {
  const from = useFormatTime(props.from);

  return {
    title: '',
    to: '',
    colorId: NaN,
    coloredBg: false,
    icons: null,
    breaks: null,
    isLocationShown: false,
    from,
    shape: props.shape,
  };
};
