import React, { Children, forwardRef, ReactElement, ReactNode } from 'react';
import { getClass } from '../../_lib/helper';
import { DropdownBaseItemProps } from '../type';

export type DropdownBaseBodyProps = {
  id: string;
  toggleMenu: () => void;
  children: ReactNode;
  parentId?: string;
  isWidthAuto?: boolean;
};

export const DropdownBaseBody = forwardRef<
  HTMLUListElement,
  DropdownBaseBodyProps
>(({ id, toggleMenu, parentId, children, isWidthAuto }, ref) => (
  <ul
    id={id}
    className={getClass('oxygen-dropdown-base__menu', '', {
      'is-width-auto': isWidthAuto,
    })}
    data-testid={`oxygen-dropdown-base-body-${parentId || 'default'}`}
    ref={ref}
    role="menu"
  >
    {Children.map(
      children as ReactElement<DropdownBaseItemProps>[],
      (child: ReactElement<DropdownBaseItemProps>) => {
        return React.cloneElement(child, {
          toggleMenu: toggleMenu,
        });
      }
    )}
  </ul>
));
