import React from 'react';
import { connect } from 'react-redux';
import { FetchWrapper } from 'element';
import { MANAGER_DASHBOARD_WHOS_WORKING } from 'state/FetchPageData/constants';
import {
  BOX_WHOS_WORKING_DATA_REQUEST,
} from 'state/ManagerDashboard/WhosWorking';
import {
  ManagerDashboardHeader,
  ManagerDashboardLayout
} from '../../components';
import { WorkingFilters, WorkingList } from './components';

type OwnProps = {};

type StateProps = {};

type DispatchProps = {
  fetchData: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const WhosWorking = (props: Props) => (
  <ManagerDashboardLayout header={<ManagerDashboardHeader />}>
    <FetchWrapper {...props} pageId={MANAGER_DASHBOARD_WHOS_WORKING}>
      <WorkingFilters />
      <WorkingList />
    </FetchWrapper>
  </ManagerDashboardLayout>
);

const mapDispatchToProps: DispatchProps = {
  fetchData: BOX_WHOS_WORKING_DATA_REQUEST
};

export default connect(
  null,
  mapDispatchToProps
)(WhosWorking);
