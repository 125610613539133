import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { DashboardShift, Omit, SagaAction, WorkingPeriod } from 'type';
import { Api } from 'lib/Api';
import { formatError, getErrorStatus } from 'state/helpers';
import { getRotaUserListRequest } from 'state/UsersCollection';
import * as fetchPageData from 'state/FetchPageData';
import {
  getFetchFlags,
  MANAGER_DASHBOARD_WHOS_WORKING,
} from 'state/FetchPageData';
import {
  BOX_MANAGER_DASHBOARD_CLEAR_ERRORS,
  managerDashboardFiltersSelector,
} from '../../ManagerDashboard';
import { ManagerDashboardFilters } from '../../ManagerDashboard/types';
import { getSelectedPeriod } from '../selectors';
import * as action from '../actions';
import * as actions from '../actions';
import {
  ManagerDashboardFilteredRequest,
  ManagerDashboardGetWhosWorkingListResponse,
  ManagerDashboardGetWhosWorkingRequest,
  PunchClockStartBreakRequest,
} from 'lib/Api/type';
import { processApiRequest } from '../../../ProcessApiRequest/sagas';
import { shiftPeriod, timeSheetPeriod } from '../helpers';

export const fetchWhosWorkingList = function* (
  payload: Omit<
    ManagerDashboardGetWhosWorkingRequest,
    keyof ManagerDashboardFilteredRequest
  >
) {
  yield put(BOX_MANAGER_DASHBOARD_CLEAR_ERRORS());

  const dashboardFilters: ManagerDashboardFilters = yield select(
    managerDashboardFiltersSelector
  );

  return yield call(processApiRequest, Api.ManagerDashboard.getWhosWorking, {
    ...payload,
    ...dashboardFilters,
  });
};

export const getWhosWorkingListRequest = function* () {
  const period: WorkingPeriod = yield select(getSelectedPeriod);

  const results: ManagerDashboardGetWhosWorkingListResponse = yield call(
    fetchWhosWorkingList,
    {
      period,
    }
  );
  yield put(action.BOX_WHOS_WORKING_GET_LIST_SUCCESS(results));
};

export const getData = function* () {
  try {
    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_REQUEST(
        fetchPageData.MANAGER_DASHBOARD_WHOS_WORKING
      )
    );

    yield all([call(getRotaUserListRequest), call(getWhosWorkingListRequest)]);

    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_SUCCESS(
        fetchPageData.MANAGER_DASHBOARD_WHOS_WORKING
      )
    );
  } catch (error) {
    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_FAILURE(
        fetchPageData.MANAGER_DASHBOARD_WHOS_WORKING
      )
    );
  }
};

export const togglePeriod = function* ({
  payload: period = 'tomorrow',
}: SagaAction<WorkingPeriod>): SagaIterator {
  try {
    const { isFetched, isFetching }: ReturnType<typeof getFetchFlags> =
      yield select(getFetchFlags, MANAGER_DASHBOARD_WHOS_WORKING);

    // do not fetch any data doesnt related to rosters pages
    if (!isFetched) {
      return;
    }

    const workingShifts: ManagerDashboardGetWhosWorkingListResponse =
      yield call(fetchWhosWorkingList, { period });
    yield put(
      action.BOX_WHOS_WORKING_TOGGLE_PERIOD_TYPE_SUCCESS({
        period,
        workingShifts,
      })
    );
  } catch (error) {
    yield put(
      action.BOX_WHOS_WORKING_TOGGLE_PERIOD_TYPE_FAILURE(formatError(error))
    );
  }
};

export const clockOn = function* ({
  payload: id,
}: SagaAction<WorkingPeriod>): SagaIterator {
  try {
    yield call(processApiRequest, Api.PunchClock.clockOn, {
      rostered_shift_id: id,
    });

    yield put(action.BOX_WHOS_WORKING_CLOCK_ON_SUCCESS(id));
  } catch (error) {
    yield put(action.BOX_WHOS_WORKING_CLOCK_ON_FAILURE(formatError(error)));
  }
};

export const clockOff = function* ({
  payload: id,
}: SagaAction<WorkingPeriod>): SagaIterator {
  try {
    yield call(processApiRequest, Api.PunchClock.clockOff, {
      timesheet_id: id,
    });
    yield put(action.BOX_WHOS_WORKING_CLOCK_OFF_SUCCESS(id));
  } catch (error) {
    yield put(action.BOX_WHOS_WORKING_CLOCK_OFF_FAILURE(formatError(error)));
  }
};

export const startBreak = function* ({
  payload,
}: SagaAction<PunchClockStartBreakRequest>): SagaIterator {
  try {
    let shift = yield call(
      processApiRequest,
      Api.PunchClock.startBreak,
      payload
    );
    yield put(action.BOX_WHOS_WORKING_START_BREAK_SUCCESS(shift));
  } catch (error) {
    yield put(
      action.BOX_WHOS_WORKING_BREAKS_LIMIT_CONFIRMATION_OPEN(formatError(error))
    );
  }
};

export const stopBreak = function* ({
  payload: id,
}: SagaAction<string>): SagaIterator {
  try {
    const shift = yield call(processApiRequest, Api.PunchClock.stopBreak, {
      timesheet_id: id,
    });

    yield put(action.BOX_WHOS_WORKING_STOP_BREAK_SUCCESS(shift));
  } catch (error) {
    yield put(action.BOX_WHOS_WORKING_STOP_BREAK_FAILURE(formatError(error)));
  }
};

export const applyFilters = function* ({
  payload: period,
}: SagaAction<WorkingPeriod>): SagaIterator {
  try {
    const workingShifts: ManagerDashboardGetWhosWorkingListResponse =
      yield call(fetchWhosWorkingList, { period });
    yield put(
      action.BOX_WHOS_WORKING_APPLY_FORM_FILTERS_SUCCESS({
        period,
        workingShifts,
      })
    );
  } catch (error) {
    yield put(
      action.BOX_WHOS_WORKING_APPLY_FORM_FILTERS_FAILURE(formatError(error))
    );
  }
};

export const approve = function* ({
  payload,
}: SagaAction<string>): SagaIterator {
  try {
    yield call(processApiRequest, Api.PunchClock.approve, payload, {
      is_approved: true,
    });
    yield put(action.BOX_WHOS_WORKING_APPROVE_SUCCESS(payload));
  } catch (error) {
    const errorStatus = getErrorStatus(error);

    if (errorStatus === 422 || errorStatus === 423) {
      yield put(
        action.BOX_WHOS_WORKING_BREAKS_LIMIT_CONFIRMATION_OPEN(
          formatError(error)
        )
      );
    } else {
      yield put(action.BOX_WHOS_WORKING_APPROVE_FAILURE(formatError(error)));
    }
  }
};

export const updateRosteredShift = function* ({
  payload,
}: SagaAction<DashboardShift>): SagaIterator {
  try {
    const period: WorkingPeriod = yield select(getSelectedPeriod);

    if (shiftPeriod(payload).indexOf(period) !== -1 && payload.user_id) {
      yield put(actions.BOX_WHOS_WORKING_UPDATE_SHIFT(payload));
    } else {
      yield put(actions.BOX_WHOS_WORKING_SHIFT_DELETED(payload));
    }

    if (payload.timesheet_id) {
      yield put(actions.BOX_WHOS_WORKING_SHIFT_DELETED(payload));
    }
  } catch (e) {
    yield put(
      actions.BOX_WHOS_WORKING_SOCKET_UPDATE_DATA_FAILED(formatError(e))
    );
  }
};

export const updateTimesheet = function* ({
  payload,
}: SagaAction<DashboardShift>): SagaIterator {
  try {
    const period: WorkingPeriod = yield select(getSelectedPeriod);

    if (period === timeSheetPeriod(payload)) {
      yield put(actions.BOX_WHOS_WORKING_UPDATE_SHIFT(payload));
    }
    if (period === 'now' && payload.end !== null) {
      yield put(actions.BOX_WHOS_WORKING_TIMESHEET_DELETED(payload));
    }
  } catch (e) {
    yield put(
      actions.BOX_WHOS_WORKING_SOCKET_UPDATE_DATA_FAILED(formatError(e))
    );
  }
};

export const watchWhosWorking = function* (): SagaIterator {
  yield takeLatest(actions.BOX_WHOS_WORKING_DATA_REQUEST, getData);
  yield takeLatest(
    actions.BOX_WHOS_WORKING_TOGGLE_PERIOD_TYPE_REQUEST,
    togglePeriod
  );
  yield takeLatest(
    actions.BOX_WHOS_WORKING_APPLY_FORM_FILTERS_REQUEST,
    applyFilters
  );
  yield takeLatest(actions.BOX_WHOS_WORKING_CLOCK_ON_REQUEST, clockOn as any); // TODO double-check
  yield takeLatest(actions.BOX_WHOS_WORKING_CLOCK_OFF_REQUEST, clockOff as any); // TODO double-check
  yield takeLatest(actions.BOX_WHOS_WORKING_START_BREAK_REQUEST, startBreak);
  yield takeLatest(actions.BOX_WHOS_WORKING_STOP_BREAK_REQUEST, stopBreak);
  yield takeLatest(actions.BOX_WHOS_WORKING_APPROVE_REQUEST, approve);

  yield takeLatest(actions.BOX_WHOS_WORKING_SHIFT_CHANGED, updateRosteredShift);
  yield takeLatest(actions.BOX_WHOS_WORKING_TIMESHEET_CHANGED, updateTimesheet);
};
