import React from 'react';
import { Card, Filter, withLayoutAware } from 'elmo-elements';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import moment from 'moment';
import { Props, State } from './type';
import { getOverview } from 'state/ManagerDashboard/Overview';
import { getDateTimeFormatted } from 'lib/helpers';
import { HorizontalBar } from 'element';
import { getDateFormat, getTimeFormat } from 'state/Account';
import { currentUserMainTimezoneSelector } from 'state/Auth';
import { isAppMarket } from '../../../../../../helpers';

export class HoursComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const today = this.props.overview.today;

    this.state = {
      isDay: true,
      series: [
        { y: today.planned, name: 'Planned', color: '#8bc2fe' },
        {
          y: today.rostered,
          name: isAppMarket('uk') ? 'Scheduled' : 'Rostered',
          color: '#236fc8',
        },
        {
          y: today.timesheet,
          name: 'Timesheet',
          color: '#063f79',
          inprogress: !today.is_complete,
          className: !today.is_complete ? 'chart-incomplete' : '',
        },
      ],
      title: "Today's Hours",
      subTitle: getDateTimeFormatted(
        props.dateFormat,
        props.timeFormat,
        moment().tz(this.props.timezone),
        false
      ),
    };
  }

  onStatusChange = (status: string) => {
    const { overview, dateFormat, timeFormat } = this.props;

    this.setState({
      isDay: status === 'today',
      series: [
        {
          y:
            status === 'today' ? overview.today.planned : overview.week.planned,
          name: 'Planned',
          color: '#8bc2fe',
        },
        {
          y:
            status === 'today'
              ? overview.today.rostered
              : overview.week.rostered,
          name: isAppMarket('uk') ? 'Scheduled' : 'Rostered',
          color: '#236fc8',
        },
        {
          y:
            status === 'today'
              ? overview.today.timesheet
              : overview.week.timesheet,
          name: 'Timesheet',
          color: '#063f79',
          inprogress:
            status === 'today'
              ? !overview.today.is_complete
              : !overview.week.is_complete,
          className:
            status === 'today'
              ? !overview.today.is_complete
                ? 'chart-incomplete'
                : ''
              : !overview.week.is_complete
              ? 'chart-incomplete'
              : '',
        },
      ],
      title: status === 'today' ? "Today's Hours" : 'This week Hours',
      subTitle:
        status === 'today'
          ? getDateTimeFormatted(
              dateFormat,
              timeFormat,
              moment().tz(this.props.timezone),
              false
            )
          : this.weekDays,
    });
  };

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.overview !== prevProps.overview) {
      this.setState({
        ...this.state,
        subTitle: getDateTimeFormatted(
          this.props.dateFormat,
          this.props.timeFormat,
          moment().tz(this.props.timezone),
          false
        ),
      });
      this.onStatusChange(this.state.isDay ? 'today' : 'week');
    }
  }

  render() {
    let statuses = [
      { colour: 'info' as const, value: 'today', label: 'Today' },
      { colour: 'success' as const, value: 'week', label: 'This week' },
    ];

    return (
      <Card
        id={'widget-hours'}
        heading={
          <>
            <p>{this.state.title}</p>
            <span>{this.state.subTitle}</span>
          </>
        }
        option={
          <Filter
            selectedValue={'today'}
            onStatusChange={this.onStatusChange}
            statuses={statuses}
          />
        }
      >
        <HorizontalBar id="overview-hours-chart" data={this.state.series} />
      </Card>
    );
  }

  get weekDays() {
    const { dateFormat, timeFormat } = this.props;
    const start = getDateTimeFormatted(
      dateFormat,
      timeFormat,
      moment().startOf('isoWeek'),
      false
    );
    const end = getDateTimeFormatted(
      dateFormat,
      timeFormat,
      moment().endOf('isoWeek'),
      false
    );
    return `${start} - ${end}`;
  }
}

const mapStateToProps = (state: StoreState) => ({
  overview: getOverview(state),
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
  timezone: currentUserMainTimezoneSelector(state),
});

export const Hours = connect(
  mapStateToProps,
  null
)(withLayoutAware(HoursComponent));
