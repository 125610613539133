import { createReducer } from 'lib/store-utils';
import { ShiftOffersReducerState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';
import * as moduleActions from '../actions';
import { omit } from 'lodash';

export const shiftOffers = createReducer<ShiftOffersReducerState>(
  {},
  getDefaultState()
);

shiftOffers.on(
  moduleActions.BOX_SHIFT_OFFERS_UPDATE_ONE,
  (state, changedOffer): ShiftOffersReducerState => ({
    ...state,
    shiftOffers: {
      ...state.shiftOffers,
      [changedOffer.id]: changedOffer
    }
  })
);

shiftOffers.on(actions.BOX_SHIFT_OFFERS_GET_PAGE_DATA_REQUEST, getDefaultState);

shiftOffers.on(
  actions.BOX_SHIFT_OFFERS_GET_OFFERS_LIST_SUCCESS,
  (state, payload): ShiftOffersReducerState => ({
    ...state,
    shiftOffers: payload
  })
);

shiftOffers.on(
  actions.BOX_SHIFT_OFFERS_OMIT_SHIFT_OFFER,
  (state, { id }): ShiftOffersReducerState => ({
    ...state,
    shiftOffers: omit(state.shiftOffers, id)
  })
);

shiftOffers.on(
  actions.BOX_SHIFT_OFFERS_TOGGLE_FILTER_TYPE,
  (state, selectedFilter): ShiftOffersReducerState => ({
    ...state,
    selectedFilter
  })
);

shiftOffers.on(
  actions.BOX_SHIFT_OFFERS_CHANGE_ORDER,
  (state, sort): ShiftOffersReducerState => ({
    ...state,
    sort
  })
);

shiftOffers.on(
  actions.BOX_SHIFT_OFFERS_CHANGE_PAGE,
  (state, currentPage): ShiftOffersReducerState => ({
    ...state,
    pager: {
      ...state.pager,
      currentPage
    }
  })
);

shiftOffers.on(
  actions.BOX_SHIFT_OFFERS_CHANGE_PAGE_SIZE,
  (state, pageSize): ShiftOffersReducerState => ({
    ...state,
    pager: {
      ...state.pager,
      pageSize,
      currentPage: 1
    }
  })
);

shiftOffers.on(
  actions.BOX_SHIFT_OFFERS_DELETE_OFFER_FROM_LIST,
  (state, shiftOfferId): ShiftOffersReducerState => ({
    ...state,
    shiftOffers: omit(state.shiftOffers, shiftOfferId)
  })
);

// shiftOffers.on(actions., (state, payload): ShiftOffersReducerState => ({...state}));
