import TimesheetsDaily from 'page/TimesheetsDaily';
import TimesheetsWeekly from 'page/TimesheetsWeekly';
import withHeading from 'element/withHeading';

export const timesheets = {
  path: '/app/timesheets',
  permission: 'roster.timesheet.view' as 'roster.timesheet.view',
  channel: 'manager_dashboard',
  component: withHeading(TimesheetsDaily, {
    title: 'Timesheets',
  }),
};

export const timesheetsWeekly = {
  path: '/app/timesheets-weekly',
  permission: 'roster.timesheet.view' as 'roster.timesheet.view',
  channel: 'manager_dashboard',
  component: withHeading(TimesheetsWeekly, {
    title: 'Timesheets - Weekly',
  }),
};
