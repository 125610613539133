import React from 'react';
import './HeaderTabs.scss';
import { HeaderTabsProps } from '../type';
import { getClass } from 'elmo-elements/_lib/helper';

function HeaderTabs({ tabs }: HeaderTabsProps) {
  return (
    <div className="elmo-tabs" id="elmo-tab">
      <nav className="elmo-tabs__nav" id="elmo-tab-nav">
        <ul>
          {Array.isArray(tabs) &&
            tabs.map((item, index) => (
              <li
                className={getClass('elmo-tabs__item', '', {
                  active: item.active,
                })}
                key={index}
              >
                {/* eslint-disable-next-line */}
                <a onClick={item.onClick}>{item.label}</a>
              </li>
            ))}
        </ul>
      </nav>
    </div>
  );
}

export default HeaderTabs;
