import React from 'react';
import { useFeatureFlagContext } from './FeatureFlagContext';

type Props = {
  children: React.ReactNode;
};

export function Off({ children }: Props) {
  const { isEnabled } = useFeatureFlagContext();

  if (isEnabled) {
    return null;
  }

  return <>{children}</>;
}

export default Off;
