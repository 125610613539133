import { styled } from 'extended-oxygen-elements';
import { useResizeObserver } from 'hooks';
import { SIDE_MENU_WIDTH } from 'lib/config';
import React from 'react';
import { WithChildren } from 'type';
import { DESKTOP_BREAKPOINT } from './config';
import { useLayoutContext } from './layout-context';

import { FeatureFlag } from 'element/feature-flags-components';

type StateProps = {
  isOpened: boolean;
};

const Content = styled('div', {
  shouldForwardProp: (propName) => propName !== 'top',
})<{ top: number }>(({ theme, top }) => ({
  position: 'fixed',
  top,
  right: 0,
  left: 0,
  zIndex: theme.zIndex.appBar - 1,
  [theme.breakpoints.up(DESKTOP_BREAKPOINT)]: {
    left: SIDE_MENU_WIDTH,
  },
}));

const Shape = styled('div', {
  shouldForwardProp: (propName) => propName !== 'height',
})<{ height: number }>(({ height }) => ({
  height,
}));

export default function LayoutHeader({ children }: WithChildren) {
  const { appBarHeight, headerHeight, setHeaderHeight } = useLayoutContext();
  const headerRefCallback = useResizeObserver((headerNode) => {
    setHeaderHeight(headerNode.offsetHeight);
  });

  const showNewNavigation: boolean = true;

  return (
    <>
      {showNewNavigation ? (
        /* New navigation layout */
        <div ref={headerRefCallback} className="layout-header">
          {children}
        </div>
      ) : (
        /* Old navigation layout */
        <Content
          ref={headerRefCallback}
          top={appBarHeight}
          data-testid="layout-header"
        >
          {children}
        </Content>
      )}
      <Shape height={headerHeight} />
    </>
  );
}
