import { RosteredShiftsReducerState } from './types';
import moment from 'moment';

export const selectedDayFormat = 'YYYY-MM-DD';
export const defaultState: RosteredShiftsReducerState = {
  undoList: {},
  undoIsUpdating: false,
  day: moment().format(selectedDayFormat),
  errors: [],
  dropInProgress: false,
  bulkCreateInProgress: false,
  dropError: [],
  droppedShift: {},
  dropCanceled: false,
  draggableShift: null,
  stretchingShift: null,
  overlapModal: {
    isOpened: false,
    errors: [],
    isUpdating: false,
    ignoreErrors: false
  }
};
