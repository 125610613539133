import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import {
  CreateShiftItemPayload,
  EditModalPayload,
  EditModalProps,
  EditShiftItemPayload,
} from 'state/Roster/EditTemplate/types';
import {
  activeUsersAsSelectOptionsArraySelectorBySiteTemplate,
  getEditModal,
  getTemplateId,
  getTemplateSiteId,
  getUsersBySite,
} from 'state/Roster/EditTemplate/selectors';
import {
  BOX_EDIT_TEMPLATE_CLEAR_MODAL_ERRORS,
  BOX_EDIT_TEMPLATE_CLOSE_EDIT_MODAL,
  BOX_EDIT_TEMPLATE_CREATE_SHIFT_ITEM,
  BOX_EDIT_TEMPLATE_DELETE_SHIFT_ITEM,
  BOX_EDIT_TEMPLATE_EDIT_SHIFT_ITEM,
} from 'state/Roster/EditTemplate/actions';
import { SelectPropsOption } from 'elmo-elements/Select';
import {
  activeAreasBySiteIdSelector,
  getAreas,
  getRoles,
  getSites,
} from 'state/AccountTree';
import {
  AccountTreeArea,
  AccountTreeRole,
  AccountTreeSite,
  DefaultBreakItem,
  LanguagePreferences,
  PreferencesTimeFormat,
  RosteredShift,
  ShiftTemplateItem,
  StringMap,
  UserFields,
} from 'type';
import { getLangPreferences, getTimeFormat } from 'state/Account';
import ErrorBox from 'element/ErrorBox';
import { ModalActions } from './components/ModalActions';
import {
  getOptionsForCurrentUser,
  getOptionsForUnassignedRoster,
  getSelectedAreaRole,
  getShiftDuration,
  convertDecimalToFormattedTime,
} from 'lib/helpers';
import moment from 'moment';
import { convertTemplatedHrsToDate } from './helpers';
import {
  getClipboard,
  getLastAction,
} from 'state/Roster/ContextualMenu/selectors';
import { ContextualMenuAction } from '../../../../../../state/Roster/ContextualMenu/types';
import { EditFormUpdated } from './components/EditFormUpdated';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';
import {
  PageDialog,
  PageDialogCancel,
  PageDialogCloseIconButton,
  PageDialogSubmit,
} from 'element';

type StateProps = {
  modal: EditModalProps;
  usersDropDown: SelectPropsOption[];
  areasBySideId: any;
  roles: StringMap<AccountTreeRole>;
  areas: StringMap<AccountTreeArea>;
  users: StringMap<UserFields>;
  siteId: string;
  timeFormat: PreferencesTimeFormat;
  langPreferences: LanguagePreferences;
  templateId: string;
  action: ContextualMenuAction;
  clipboard: RosteredShift | ShiftTemplateItem | null;
  sites: StringMap<AccountTreeSite>;
};

type DispatchProps = {
  close: () => void;
  createItem: (payload: CreateShiftItemPayload) => void;
  editItem: (payload: EditShiftItemPayload) => void;
  clearErrors: () => void;
  deleteShift: (id: string) => void;
};

export type Props = StateProps & DispatchProps;

type State = {
  payload: EditModalPayload;
};

export class EditShiftTemplateModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      payload: props.modal.payload,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (prevProps.modal.payload !== this.props.modal.payload) {
      this.setState({
        payload: this.props.modal.payload,
      });
    }
  }

  setPayload = (payload: EditModalPayload) => {
    this.setState({
      payload,
    });
  };

  isValid = () => {
    const { payload } = this.props.modal;
    const { area_id, role_id } = this.state.payload;
    if (
      this.state.payload.area_id === null ||
      this.state.payload.role_id === null
    ) {
      return false;
    }

    if (!this.validateAreaRole()) {
      return false;
    }

    if (payload === this.state.payload && payload.id) {
      return payload.paste_edit === true;
    }

    return (
      payload !== this.state.payload ||
      (area_id !== null && role_id !== null && !payload.id)
    );
  };

  submitModal = () => {
    const { payload } = this.state;
    const requestPayload = {
      templateId: this.props.templateId,
      ...payload,
      ignore_errors: false,
    };
    if (payload.id) {
      this.props.editItem({
        ...requestPayload,
        id: payload.id,
      });
    } else {
      this.props.createItem(requestPayload);
    }
  };

  render() {
    const { isOpened, errors, isUpdating, isDeleting } = this.props.modal;
    const { payload } = this.state;
    const {
      usersDropDown,
      areas,
      areasBySideId,
      roles,
      users,
      siteId,
      timeFormat,
      langPreferences,
    } = this.props;
    return (
      <PageDialog
        translate={'no'}
        transitionDuration={0}
        sx={{
          maxWidth: '600px',
          margin: '0 auto',
          transition: 'none',
        }}
        className={'shift-modal'}
        open={isOpened}
        id={
          payload.id ? 'edit-shift-template-modal' : 'add-shift-template-modal'
        }
      >
        <DialogTitle>
          <PageDialogCloseIconButton onClose={this.props.close} />
          {(payload.id ? 'Edit shift ' : 'Add shift ') + this.getShowTotalHrs()}
        </DialogTitle>
        <DialogContent>
          {errors.length > 0 && (
            <ErrorBox
              errors={errors}
              className={'error-box'}
              clearErrors={this.props.clearErrors}
            />
          )}
          <ModalActions
            shift={payload}
            onDeleteShiftClick={this.onDeleteClickHandler}
            isDeleting={isDeleting}
          />
          <EditFormUpdated
            availableUsers={usersDropDown}
            payload={payload}
            areas={areas}
            areasBySideId={areasBySideId}
            roles={roles}
            users={users}
            siteId={siteId}
            timeFormat={timeFormat}
            setPayload={this.setPayload}
            langPreferences={langPreferences}
            areaRoleOptions={this.getAreaRoleOptions()}
          />
        </DialogContent>
        <DialogActions sx={{ boxShadow: '0 0 6px 2px #ccc', zIndex: 3 }}>
          <PageDialogCancel onClick={this.props.close} fullWidth={false}>
            Cancel
          </PageDialogCancel>
          <PageDialogSubmit
            id="save-shift-btn"
            onClick={this.submitModal}
            disabled={isUpdating || !this.isValid()}
            loading={isUpdating}
            fullWidth={false}
          >
            Save
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  }

  getAreaRoleOptions = () => {
    const { areas, roles, users, areasBySideId, siteId } = this.props;
    const {
      payload: { user_id },
    } = this.state;
    const areasBySite = areasBySideId[siteId] || [];
    return user_id !== null
      ? getOptionsForCurrentUser(areasBySite, areas, roles, users[user_id])
      : getOptionsForUnassignedRoster(areasBySite, roles);
  };

  validateAreaRole = () => {
    const value = getSelectedAreaRole(
      this.getAreaRoleOptions(),
      this.state.payload
    );
    if (value.hasOwnProperty('length') && (value as any).length === 1) {
      return (value as any)[0].value !== '__';
    }
    return (value as any).value !== '__';
  };

  onDeleteClickHandler = () => {
    const { deleteShift } = this.props;
    const {
      payload: { id },
    } = this.state;
    deleteShift(id as string);
  };

  get convertToMomentFormat() {
    const { payload } = this.state;
    const breaks = [];
    let start = convertTemplatedHrsToDate(payload.start);
    let end = convertTemplatedHrsToDate(payload.end);
    if (end.isSameOrBefore(start)) {
      end.add(1, 'day');
    }
    for (let breakItem of payload.breaks) {
      breaks.push({
        start: moment(start).add(breakItem.start_diff, 'seconds'),
        duration: breakItem.duration,
        paid: breakItem.paid,
      });
    }
    return {
      start,
      end,
      breaks,
    };
  }

  getShowTotalHrs = () => {
    const params = this.convertToMomentFormat;
    const timeDetails = getShiftDuration(
      params.start,
      params.end,
      params.breaks,
      'Europe/Kiev'
    );
    return (
      '(' +
      convertDecimalToFormattedTime(timeDetails.total_hrs.toString(), true) +
      ')'
    );
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  modal: getEditModal(state),
  usersDropDown: activeUsersAsSelectOptionsArraySelectorBySiteTemplate(state),
  areasBySideId: activeAreasBySiteIdSelector(state),
  sites: getSites(state),
  areas: getAreas(state),
  roles: getRoles(state),
  users: getUsersBySite(state),
  siteId: getTemplateSiteId(state),
  timeFormat: getTimeFormat(state),
  langPreferences: getLangPreferences(state),
  templateId: getTemplateId(state),
  action: getLastAction(state),
  clipboard: getClipboard(state),
});

const mapDispatchToProps: DispatchProps = {
  close: BOX_EDIT_TEMPLATE_CLOSE_EDIT_MODAL,
  createItem: BOX_EDIT_TEMPLATE_CREATE_SHIFT_ITEM,
  clearErrors: BOX_EDIT_TEMPLATE_CLEAR_MODAL_ERRORS,
  editItem: BOX_EDIT_TEMPLATE_EDIT_SHIFT_ITEM,
  deleteShift: BOX_EDIT_TEMPLATE_DELETE_SHIFT_ITEM,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditShiftTemplateModal);
