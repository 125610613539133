import React from 'react';
import { TooltipProps } from './type';
import { getClass } from '../_lib/helper';
import { PopoverBase } from '../_lib/component';
import './Tooltip.scss';

const TooltipDefaultProps = {
  tabIndex: -1,
};

function Tooltip({
  title,
  placement,
  id,
  className,
  children,
  tabIndex,
}: TooltipProps) {
  const popoverContentId = `${id}-content`;
  return (
    <PopoverBase
      id={id}
      data-testid={`elmo-tooltip-${id || 'default'}`}
      className={getClass('elmo-tooltip', className)}
      position={placement}
      tabIndex={tabIndex}
      ariaDescribedby={popoverContentId}
    >
      <PopoverBase.Target>
        {children}
        {id && (
          <div
            id={`hide-${id}`}
            role="tooltip"
            aria-label={title}
            style={{ display: 'none' }}
          />
        )}
      </PopoverBase.Target>
      <PopoverBase.Content
        className="elmo-tooltip__content"
        id={popoverContentId}
      >
        {title}
      </PopoverBase.Content>
    </PopoverBase>
  );
}

Tooltip.defaultProps = TooltipDefaultProps;

export default Tooltip;
