import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@mui/styles';

type Props = {
  children: React.ReactNode;
  visible?: boolean;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'relative',
      '&::after': {
        content: '""',
        borderRadius: theme.shape.borderRadiusLarge,
        boxShadow: theme.shadows[4],
        pointerEvents: 'none',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        opacity: 0,
        transition: theme.transitions.create('opacity', {
          duration: theme.transitions.duration.short,
        }),
      },
    },
    visible: {
      '&::after': {
        opacity: 1,
      },
    },
  })
);

export function ShadowWrapper({ children, visible = false }: Props) {
  const classes = useStyles();

  return (
    <div className={clsx([classes.root, visible && classes.visible])}>
      {children}
    </div>
  );
}
