import { bindAll } from 'lodash';

interface Handler<Result, Args extends [Result, ...any]> {
  setNext(handler: Handler<Result, Args>): Handler<Result, Args>;

  handle(...args: Args): Result;
}

class ChainHandler<Result, Args extends [Result, ...any]>
  implements Handler<Result, Args>
{
  private nextHandler: Handler<Result, Args> | undefined;

  constructor() {
    bindAll(this, ['handle']);
  }

  setNext(handler: Handler<Result, Args>): Handler<Result, Args> {
    this.nextHandler = handler;
    return handler;
  }

  handle(...args: Args): Result {
    if (this.nextHandler) {
      return this.nextHandler.handle(...args);
    }

    const [result] = args;
    return result;
  }
}

export default ChainHandler;
