import { SortUser, UserFields } from 'type/models';
import { UserFilters, UserFiltersForm } from './types';
import { StringMap } from '../../../type';
import { filter, values } from 'lodash';

export const getFilterForm = (
  { activity: { active, archived }, ...rest }: UserFilters,
  sort: SortUser
): UserFiltersForm => ({
  active,
  archived,
  ...rest,
  sort: {
    ...sort
  }
});

export const getFiltersFromForm = ({
  active,
  archived,
  role,
  area,
  userGroup,
  tag
}: UserFiltersForm): UserFilters => ({
  activity: {
    active,
    archived
  },
  role: [...role],
  area: [...area],
  userGroup: [...userGroup],
  tag: [...tag]
});

export const returnAllIfEmptyFilters = (
  allUsers: StringMap<UserFields>,
  filterIds: string[],
  predicate: (user: UserFields) => boolean
): UserFields[] => {
  if (!filterIds.length) {
    return values(allUsers);
  } else {
    return filter(allUsers, predicate);
  }
};
