import React, { ReactNode } from 'react';
import DescriptionListLabel from './DescriptionListLabel';
import { getClass } from '../../_lib/helper';

export type DescriptionListItemProps = {
  /** Label value */
  label: string | ReactNode;
  /** Chidren */
  children?: ReactNode;
  /** Whether have a Divider after dd element */
  hasDivider?: boolean;
};

function DescriptionListItem({
  children,
  label,
  hasDivider,
}: DescriptionListItemProps): any {
  return (
    <>
      <DescriptionListLabel hasDivider={hasDivider}>
        {label}
      </DescriptionListLabel>
      <dd
        className={getClass('elmo-dl__dd', {}, { 'has-divider': !!hasDivider })}
      >
        {children ? children : '-'}
      </dd>
    </>
  );
}

export default DescriptionListItem;
