import { RosteredShift, SagaAction } from '../../../../type';
import {
  ConfirmForceModalRosteredShiftPayload,
  UpdateRosteredShiftTimeForcePayload,
  UpdateShiftTimePayload,
} from '../types';
import { SagaIterator } from 'redux-saga';
import {
  all,
  call,
  CallEffect,
  fork,
  put,
  PutEffect,
  race,
  select,
  take,
} from 'redux-saga/effects';
import {
  BOX_ROSTER_DAY_VIEW_CONFIRM_FORCE_MODAL_CLOSE,
  BOX_ROSTER_DAY_VIEW_CONFIRM_FORCE_MODAL_OPEN,
  BOX_ROSTER_DAY_VIEW_CONFIRM_FORCE_MODAL_SUBMIT,
  BOX_ROSTER_DAY_VIEW_ERROR_MODAL_OPEN,
  BOX_ROSTER_DAY_VIEW_SITE_VIEW_ROW_CLEAR,
} from '../actions';
import * as isLoading from '../../../IsLoading';
import {
  BOX_ROSTERED_SHIFTS_ADD,
  BOX_ROSTERED_SHIFTS_ADD_ONE,
  getRosteredShift,
  getSiteId,
} from '../../../RosteredShifts';
import {
  RosteredShiftUpdateRequest,
  RosteredShiftUpdateResponse,
} from 'lib/Api/type';
import { Api } from 'lib/Api';
import {
  BOX_ROSTER_GET_OFFERS,
  BOX_ROSTER_GET_UNPUBLISHED,
} from '../../Roster/actions';
import { BOX_ROSTERED_SHIFTS_UNDO_LIST } from '../../../RosteredShifts/actions';
import { syncEventForShift } from '../../EventModal/sagas';
import { pick } from 'lodash';
import { processApiRequest } from '../../../ProcessApiRequest/sagas';
import { getNeedReAccept } from '../../ReAcceptConfirmModal/sagas';
import { formatError, getErrorStatus } from '../../../helpers';
import { BOX_SUMMARY_GET_DATA_ON_ROSTER_UPDATES } from '../../Summary/actions';
import { getChannelIsActive } from '../../../Sockets';

export const fetchDataAfterRosteredShiftUpdates = function* ({
  event_id,
}: {
  event_id: string | null;
}) {
  const siteId: string = yield select(getSiteId);
  const channelIsActive = yield select(getChannelIsActive, 'roster_week');

  const payload = {
    site_id: siteId,
  };

  const actions: (PutEffect<any> | CallEffect)[] = [
    put(BOX_SUMMARY_GET_DATA_ON_ROSTER_UPDATES()),
  ];

  if (!channelIsActive) {
    actions.push(put(BOX_ROSTER_GET_OFFERS(payload)));
    actions.push(put(BOX_ROSTER_GET_UNPUBLISHED(payload)));
    actions.push(put(BOX_ROSTERED_SHIFTS_UNDO_LIST(payload)));
    if (event_id) {
      actions.push(call(syncEventForShift, { id: event_id }));
    }
  }

  yield all(actions);
};

export const updateRosterTime = function*({
  payload: { id, start, end }
}: SagaAction<UpdateShiftTimePayload>): SagaIterator {
  yield put(isLoading.BOX_IS_LOADING_GLOBAL_ON());
  const rosteredShift: RosteredShift = yield select(getRosteredShift, id);
  const updatedRosteredShift: RosteredShift = {
    ...rosteredShift,
    start,
    end
  };

  yield put(BOX_ROSTERED_SHIFTS_ADD_ONE(updatedRosteredShift));

  const needReAccept = yield call(getNeedReAccept, {
    rosteredShiftId: rosteredShift.id,
    ignoreErrors: false
  });

  const payload: RosteredShiftUpdateRequest = {
    ...pick(rosteredShift, [
      'id',
      'user_id',
      'role_id',
      'area_id',
      'template_id',
      'breaks',
      'event_id',
      'tags',
      'notes'
    ]),
    start,
    end,
    need_re_accept: needReAccept
  };

  try {
    const data: RosteredShiftUpdateResponse = yield call(
      processApiRequest,
      Api.RosteredShift.updateRosteredShift,
      payload
    );

    const { event_id } = rosteredShift;
    yield all([
      put(BOX_ROSTERED_SHIFTS_ADD(data)),
      call(fetchDataAfterRosteredShiftUpdates, { event_id }),
      put(BOX_ROSTER_DAY_VIEW_SITE_VIEW_ROW_CLEAR())
    ]);
  } catch (error) {
    const errors = formatError(error);

    yield put(BOX_ROSTERED_SHIFTS_ADD_ONE(rosteredShift));

    if (getErrorStatus(error) === Api.RosteredShift.OVERLAP_ERROR_STATUS) {
      yield fork(confirmForceUpdateRosteredShift, { errors, payload });
    } else {
      yield put(BOX_ROSTER_DAY_VIEW_ERROR_MODAL_OPEN(errors));
    }
  }
  yield put(isLoading.BOX_IS_LOADING_GLOBAL_OFF());
};

export function* confirmForceUpdateRosteredShift({
  payload,
  errors
}: ConfirmForceModalRosteredShiftPayload): SagaIterator {
  yield put(BOX_ROSTER_DAY_VIEW_CONFIRM_FORCE_MODAL_OPEN({ errors }));

  const { confirm } = yield race({
    confirm: take(BOX_ROSTER_DAY_VIEW_CONFIRM_FORCE_MODAL_SUBMIT),
    close: take(BOX_ROSTER_DAY_VIEW_CONFIRM_FORCE_MODAL_CLOSE)
  });

  if (confirm) {
    yield call(updateRosterTimeForce, payload);
  }
}

export function* updateRosterTimeForce(
  payload: UpdateRosteredShiftTimeForcePayload
): SagaIterator {
  yield put(isLoading.BOX_IS_LOADING_ON('ROSTER_CONFIRM_OVERLAP_MODAL'));

  try {
    const data: RosteredShiftUpdateResponse = yield call(
      processApiRequest,
      Api.RosteredShift.updateRosteredShift,
      {
        ...payload,
        ignore_errors: true
      }
    );

    const { event_id = null } = payload;
    yield all([
      put(BOX_ROSTERED_SHIFTS_ADD(data)),
      call(fetchDataAfterRosteredShiftUpdates, { event_id }),
      put(BOX_ROSTER_DAY_VIEW_SITE_VIEW_ROW_CLEAR())
    ]);
  } catch (error) {
    yield put(BOX_ROSTER_DAY_VIEW_ERROR_MODAL_OPEN(formatError(error)));
  }

  yield put(BOX_ROSTER_DAY_VIEW_CONFIRM_FORCE_MODAL_CLOSE());
  yield put(
    isLoading.BOX_IS_LOADING_OFF('ROSTER_CONFIRM_OVERLAP_MODAL')
  );
}
