import React, { Component, createRef } from 'react';
import { getClass } from '../_lib/helper';
import {
  FormContainerBreakpoints,
  FormContainerProps,
  FormContainerState,
  TypeOfColumns,
} from './type';
import './FormContainer.scss';

class FormContainer extends Component<FormContainerProps, FormContainerState> {
  static defaultProps = {
    maxColumns: 3,
  };
  private formContainerRef = createRef<HTMLDivElement>();
  private formContainerObserver = new ResizeObserver(() => {
    this.updateMaxColumns();
  });

  constructor(props: FormContainerProps) {
    super(props);
    const { maxColumns } = this.props;
    this.state = {
      maxColumns: maxColumns,
    };
  }

  componentDidMount() {
    const formContainerRef = this.formContainerRef;
    if (formContainerRef && formContainerRef.current) {
      this.formContainerObserver.observe(formContainerRef.current);
      this.updateMaxColumns();
    }
  }

  componentWillUnmount() {
    this.formContainerObserver.disconnect();
  }

  updateMaxColumns = () => {
    const { maxColumns } = this.props;
    const formContainerRef = this.formContainerRef;
    if (formContainerRef && formContainerRef.current) {
      const width = formContainerRef.current.clientWidth;
      let columns: TypeOfColumns = 1;
      if (width < FormContainerBreakpoints['2']) {
        // minimum columns is 2 because width:100% is expected
        // for less than 2 columns unless maxColumns props set 1
        columns = 2;
      } else if (
        width >= FormContainerBreakpoints['2'] &&
        width < FormContainerBreakpoints['3']
      ) {
        columns = 2;
      } else {
        columns = 3;
      }
      if (columns > maxColumns) {
        columns = maxColumns;
      }
      this.setState({
        maxColumns: columns,
      });
    }
  };

  render() {
    const { children, className, id } = this.props;
    const { maxColumns } = this.state;

    return (
      <div
        id={id}
        data-testid={`elmo-form-container-${id || 'default'}`}
        ref={this.formContainerRef}
        className={getClass('elmo-form-container', className, {
          [`max-columns-${maxColumns}`]: maxColumns,
        })}
      >
        <div className="elmo-form-container__inner">{children}</div>
      </div>
    );
  }
}

export default FormContainer;
