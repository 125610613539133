import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  CalendarIcon,
  CalendarTodayOutlinedIcon,
  DomainOutlinedIcon,
  PersonIcon,
} from 'element';
import { BOX_ROSTER_BULK_DELETION_MODE_DEACTIVATE } from 'state/Roster/BulkActions/actions';
import { connect } from 'react-redux';
import { ToggleView } from '../ToggleView';
import { privateRoutes as routes } from 'routes';
import { BOX_ROSTERED_SHIFTS_RESET_DAY_TO_CURRENT } from 'state/RosteredShifts/actions';
import { BOX_USER_PROFILE_SAVE_CURRENT_USER_SETTINGS } from 'state/Users/UserProfile';
import {
  LanguagePreferences,
  UpdateCurrentUserPayload,
  UserFieldsRosterViewSettings,
} from 'type';
import { StoreState } from 'state/types';
import { getLangPreferences } from 'state/Account';
import { capitalize } from 'lib/helpers';
import {
  BOX_ROSTER_FILTERS_RESET_SHOW_PEOPLE,
  BOX_ROSTER_FILTERS_SUBMIT_FILTER_FORM,
} from 'state/Roster/RosterFilters';

type OwnProps = {
  paths: {
    userView: string;
    siteView: string;
  };
  pageType: 'week' | 'day';
  hidePageTypeSwitcher: boolean;
};

type DispatchProps = {
  deactivateBulkDeleteMode: () => void;
  deactivateShowPeopleFilter: () => void;
  submitFilters: () => void;
  resetDayToCurrent: () => void;
  saveUserRosterViewSettings: (params: UpdateCurrentUserPayload) => void;
};

type StateProps = {
  languagePreferences: LanguagePreferences;
};

type Props = RouteComponentProps & OwnProps & DispatchProps & StateProps;
type State = {};

export class RosterToggleViewComponent extends Component<Props, State> {
  render() {
    const {
      paths: { siteView, userView },
      location: { pathname },
      pageType,
      hidePageTypeSwitcher,
      languagePreferences,
    } = this.props;

    const areaViewLabel = capitalize(languagePreferences.area.singular);
    const employeeViewLabel = capitalize(languagePreferences.employee.singular);

    const viewTypeButtons = [
      {
        icon: <PersonIcon />,
        onClick: this.switchPageView(userView, 'user'),
        isActive: pathname === userView,
        ariaLabel: `${employeeViewLabel} view`,
        label: employeeViewLabel,
      },
      {
        icon: <DomainOutlinedIcon />,
        onClick: this.switchPageView(siteView, 'site'),
        isActive: pathname === siteView,
        ariaLabel: `${areaViewLabel} view`,
        label: areaViewLabel,
      },
    ];

    const pageTypeButtons = [
      {
        icon: <CalendarTodayOutlinedIcon />,
        onClick: this.switchPageType(routes.roster.routes.rosterWeekView.path),
        label: 'Week',
        isActive: pageType === 'week',
        ariaLabel: 'Week view',
      },
      {
        icon: <CalendarIcon />,
        onClick: this.switchPageType(routes.roster.routes.rosterDayView.path),
        label: 'Day',
        isActive: pageType === 'day',
        ariaLabel: 'Day view',
      },
    ];

    return (
      <React.Fragment>
        {!hidePageTypeSwitcher && (
          <ToggleView
            isTextButton={true}
            buttons={pageTypeButtons}
            className={'mb-4 mr-1'}
            id={'toggle-roster'}
          />
        )}
        <ToggleView
          buttons={viewTypeButtons}
          className={'mb-4'}
          id={'Switcher'}
        />
      </React.Fragment>
    );
  }

  switchPageType = (url: string) => () => {
    const { history, resetDayToCurrent, location, pageType } = this.props;
    const viewType =
      location.pathname.indexOf('site-view') !== -1 ? 'site' : 'user';
    const viewTypeChunk = viewType === 'site' ? '/site-view' : '/user-view';
    history.push(url + viewTypeChunk + location.search);
    resetDayToCurrent();
    this.saveSettings({
      page_type: url.indexOf('week-view') !== -1 ? 'week' : 'day',
      view_type: viewType,
    });
  };

  switchPageView = (path: string, type: 'user' | 'site') => () => {
    const {
      history,
      location,
      deactivateBulkDeleteMode,
      deactivateShowPeopleFilter,
      submitFilters,
      pageType,
    } = this.props;
    history.push(path + location.search);
    deactivateBulkDeleteMode();
    if (type === 'site') {
      deactivateShowPeopleFilter();
      submitFilters();
    }
    this.saveSettings({
      page_type: pageType,
      view_type: type,
    });
  };

  saveSettings = (params: UserFieldsRosterViewSettings) => {
    this.props.saveUserRosterViewSettings({
      preferences: {
        roster_view_settings: params,
      },
    });
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  languagePreferences: getLangPreferences(state),
});

const mapToDispatchProps: DispatchProps = {
  deactivateBulkDeleteMode: BOX_ROSTER_BULK_DELETION_MODE_DEACTIVATE,
  deactivateShowPeopleFilter: BOX_ROSTER_FILTERS_RESET_SHOW_PEOPLE,
  submitFilters: BOX_ROSTER_FILTERS_SUBMIT_FILTER_FORM,
  resetDayToCurrent: BOX_ROSTERED_SHIFTS_RESET_DAY_TO_CURRENT,
  saveUserRosterViewSettings: BOX_USER_PROFILE_SAVE_CURRENT_USER_SETTINGS,
};

export const RosterToggleView = connect(
  mapStateToProps,
  mapToDispatchProps
)(withRouter(RosterToggleViewComponent));
