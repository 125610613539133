import React from 'react';
import { NativeTableElement } from '../type';
import { getClass } from '../../_lib/helper';
import './NativeTableBody.scss';

type NativeTableBodyProps = NativeTableElement & {};

function NativeTableBody({ children, className }: NativeTableBodyProps) {
  return (
    <tbody className={getClass('elmo-nativetable-body', className)}>
      {children}
    </tbody>
  );
}

export default NativeTableBody;
