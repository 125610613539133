import React, { ReactNode } from 'react';

export type FormItemIconProps = {
  icon?: ReactNode;
};

function FormItemIcon({ icon }: FormItemIconProps) {
  if (icon) {
    return <div className="elmo-formitem__icon">{icon}</div>;
  }
  return null;
}

export default FormItemIcon;
