import React from 'react';
import {
  MyTimesheetErrorBox,
  MyTimesheetFilters,
  MyTimesheetList,
  MyTimesheetModals,
  MyTimesheetPager,
  MyTimesheetTotal
} from './components';
import './MyTimesheet.scss';

const MyTimesheetView = () => {
  return (
    <>
      <MyTimesheetErrorBox />
      <MyTimesheetFilters />
      <MyTimesheetList />
      <div className="my-timesheet-view__pager-wrapper">
        <MyTimesheetTotal />
        <MyTimesheetPager />
      </div>
      <MyTimesheetModals />
    </>
  );
};

export default MyTimesheetView;
