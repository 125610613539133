import React from 'react';
import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { ISO8601DateTime } from 'type';
import { timeFormatSelector } from 'state/Account';

type FormattedTimeProps = {
  children: Moment | ISO8601DateTime;
};

export function FormattedTime({ children: time }: FormattedTimeProps) {
  const timeFormat = useSelector(timeFormatSelector);

  return <>{moment.parseZone(time).format(timeFormat)}</>;
}
