import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { every, identity, negate, omit } from 'lodash';
import { Event, SagaAction } from 'type';
import { SERVER_DAY_FORMAT } from 'lib/config';
import {
  Api,
  AutoGenerateTimesheetsPayload,
  EventDeleteRequest,
} from 'lib/Api';
import { formatError, getErrorStatus } from 'state/helpers';
import * as fetchPageData from 'state/FetchPageData';
import { getRotaUserListRequest } from 'state/UsersCollection';
import {
  BOX_ROSTERED_SHIFTS_DELETE,
  getFrom,
  getSiteId,
  getTo,
  selectedDaySelector,
} from 'state/RosteredShifts';
import {
  BOX_TIMESHEET_UPDATE_ONE,
  getTimesheetListRequest,
} from 'state/TimesheetsCollection';
import { getTimeOffsListRequest } from 'state/TimeOffs';
import {
  DeleteRosteredShiftPayload,
  GetOffersPayload,
  GetUnpublishedRostersListPayload,
  RangeInputOpenPayload,
  RefactoredPublishRostersPayload,
  RosterGetPageDataPayload,
  RosterOffersModalPayload,
  RosterOffersPayload,
} from './types';
import {
  BOX_ROSTER_CLOSE_APPLY_CHANGES_MODAL,
  BOX_ROSTER_DELETE_EVENT_BY_ID,
  BOX_ROSTER_DELETE_SHIFT,
  BOX_ROSTER_DELETE_SHIFT_FAILUE,
  BOX_ROSTER_DELETE_SHIFT_SUCCESS,
  BOX_ROSTER_GET_INITIAL_PAGE_DATA_REQUEST,
  BOX_ROSTER_GET_OFFERS,
  BOX_ROSTER_GET_OFFERS_SUCCESS,
  BOX_ROSTER_GET_PAGE_DATA_REQUEST,
  BOX_ROSTER_GET_UNPUBLISHED,
  BOX_ROSTER_GET_UNPUBLISHED_FAILURE,
  BOX_ROSTER_GET_UNPUBLISHED_SUCCESS,
  BOX_ROSTER_GLOBAL_APPROVE_TIMESHEET,
  BOX_ROSTER_GLOBAL_APPROVE_TIMESHEET_ALL,
  BOX_ROSTER_GLOBAL_APPROVE_TIMESHEET_ALL_SUCCESS,
  BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN,
  BOX_ROSTER_OFFERS_CHANGES,
  BOX_ROSTER_OFFERS_CHANGES_SUCCESS,
  BOX_ROSTER_PUBLISH_CHANGES_REFACTORED,
  BOX_ROSTER_PUBLISH_CHANGES_SUCCESS,
  BOX_ROSTER_RANGE_INPUT_OPEN,
  BOX_ROSTER_TIMESHEETS_AUTOGENERATE_FAILURE,
  BOX_ROSTER_TIMESHEETS_AUTOGENERATE_REQUEST,
  BOX_ROSTER_TIMESHEETS_AUTOGENERATE_SUCCESS,
  BOX_ROSTER_TIMESHEETS_CSV_FAILURE,
  BOX_ROSTER_TIMESHEETS_CSV_REQUEST,
  BOX_ROSTER_TIMESHEETS_CSV_SUCCESS,
  BOX_ROSTER_UPDATE_ROSTER_DATA_REQUEST,
} from './actions';
import { getEventsRequest } from '../../Events/sagas';
import {
  BOX_ROSTERED_SHIFTS_SET_DAY,
  BOX_ROSTERED_SHIFTS_UNDO_LIST,
} from '../../RosteredShifts/actions';
import {
  BOX_ROSTER_DAY_VIEW_EVENTS_ROW_CLEAR,
  BOX_ROSTER_DAY_VIEW_SITE_VIEW_ROW_CLEAR,
  BOX_ROSTER_DAY_VIEW_UNASSIGNED_ROW_CLEAR,
} from '../RosterDayView/actions';
import { BOX_ROSTER_SHIFT_MODAL_CLEAR_ERRORS } from '../RosterShiftModal/actions';
import {
  ApproveTimesheetAllModalPayload,
  ApproveTimesheetAllPayload,
  ApproveTimesheetPayload,
  GenerateCSVReportPayload,
} from 'lib/Api/type';
import { Moment } from 'moment';
import { getSummaryData } from '../Summary/sagas';
import {
  processApiRequest,
  processApiRequestWithConfirm,
} from '../../ProcessApiRequest/sagas';
import { hasPermissionSelector } from '../../Auth/selectors';
import { BOX_SUMMARY_GET_DATA_ON_ROSTER_UPDATES } from '../Summary/actions';
import { getChannelIsActive } from '../../Sockets';
import { getClipboard } from '../ContextualMenu/selectors';
import {
  BOX_ROSTER_CONTEXTUAL_MENU_SET_ACTION,
  BOX_ROSTER_CONTEXTUAL_MENU_SET_CLIPBOARD,
} from '../ContextualMenu/actions';
import { getPastingStatusRequest } from '../RosterCopyPast/sagas';
import { getRosteredShiftsListRequest } from '../../RosteredShifts/requestSagas';
import { BOX_EVENTS_DELETE_ONE } from '../../Events/actions';
import { reFetchEventRelatedData } from '../EventModal/sagas';
import { getEventByIdSelector } from 'state/Events/selectors';
import * as isLoading from 'state/IsLoading';
import { getIsBulkDeleteOpened } from '../BulkActions';
import { getIsCovidModeEnabled } from '../CovidTracing';
import {
  BOX_SHIFT_TIME_RANGE_INPUT_OPENED,
  getIsLoading as getRangeInputIsLoading,
} from '../RangeInput/ShiftTimeRangeInput';
import { getIsApprovalModeEnabled } from './selectors';

export const getRostersRelatedData = function* (payload: {
  site_id: string;
  from: Moment;
  to: Moment;
}): SagaIterator {
  const rosterWeekChannel = yield select(getChannelIsActive, 'roster_week');

  if (!rosterWeekChannel) {
    // update rostered shifts
    yield call(getRosteredShiftsListRequest, {
      site_id: payload.site_id,
      from: payload.from,
      to: payload.to,
    });

    yield call(getTimesheetListRequest, {
      site_id: payload.site_id,
      from: payload.from,
      to: payload.to,
    });

    // again get a list of unpublished rosters
    yield put(
      BOX_ROSTER_GET_UNPUBLISHED({
        site_id: payload.site_id,
      })
    );

    // again get a list of avalable for offer rostered shifts
    yield put(
      BOX_ROSTER_GET_OFFERS({
        site_id: payload.site_id,
      })
    );

    // update undo list
    yield put(
      BOX_ROSTERED_SHIFTS_UNDO_LIST({
        site_id: payload.site_id,
      })
    );

    yield call(getEventsRequest, payload);
  }

  yield put(BOX_SUMMARY_GET_DATA_ON_ROSTER_UPDATES());
};

export const setDefaultDay = function* (from: Moment, to: Moment) {
  const selectedDay: ReturnType<typeof selectedDaySelector> = yield select(
    selectedDaySelector
  );

  if (selectedDay.isBefore(from)) {
    yield put(BOX_ROSTERED_SHIFTS_SET_DAY(from.format(SERVER_DAY_FORMAT)));
  }
  if (selectedDay.isAfter(to)) {
    yield put(BOX_ROSTERED_SHIFTS_SET_DAY(to.format(SERVER_DAY_FORMAT)));
  }
};

const getInitialPageData = function* ({
  payload,
}: SagaAction<RosterGetPageDataPayload>): SagaIterator {
  try {
    yield put(fetchPageData.BOX_FETCH_PAGE_DATA_REQUEST(fetchPageData.ROSTERS));

    yield all([
      call(getPastingStatusRequest, payload),
      call(getRosteredShiftsListRequest, payload),
      call(getTimesheetListRequest, payload),
      call(getTimeOffsListRequest, payload),
      call(getEventsRequest, payload),
      call(getRotaUserListRequest),
    ]);

    // yield call(getSummaryData);

    yield put(
      BOX_ROSTER_GET_UNPUBLISHED({
        site_id: payload.site_id,
      })
    );

    yield put(
      BOX_ROSTER_GET_OFFERS({
        site_id: payload.site_id,
      })
    );

    yield put(BOX_ROSTER_DAY_VIEW_SITE_VIEW_ROW_CLEAR());
    yield put(BOX_ROSTER_DAY_VIEW_EVENTS_ROW_CLEAR());
    yield put(BOX_ROSTER_DAY_VIEW_UNASSIGNED_ROW_CLEAR());

    yield call(setDefaultDay, payload.from, payload.to);

    yield put(fetchPageData.BOX_FETCH_PAGE_DATA_SUCCESS(fetchPageData.ROSTERS));
  } catch (error) {
    yield put(fetchPageData.BOX_FETCH_PAGE_DATA_FAILURE(fetchPageData.ROSTERS));
  }
};

export const updateRosterData = function* (): SagaIterator {
  yield put(isLoading.BOX_IS_LOADING_GLOBAL_ON());

  try {
    const site_id: ReturnType<typeof getSiteId> = yield select(getSiteId);
    const from: ReturnType<typeof getFrom> = yield select(getFrom);
    const to: ReturnType<typeof getTo> = yield select(getTo);

    const requestPayload = {
      site_id,
      from,
      to,
    };

    const actionPayload = {
      site_id,
    };

    yield all([
      call(getRosteredShiftsListRequest, requestPayload),
      call(getTimesheetListRequest, requestPayload),
      call(getTimeOffsListRequest, requestPayload),
      call(getEventsRequest, requestPayload),
      call(getSummaryData),
      put(BOX_ROSTER_GET_UNPUBLISHED(actionPayload)),
      put(BOX_ROSTER_GET_OFFERS(actionPayload)),
    ]);
  } catch (error) {
    yield put(BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN(formatError(error)));
  }
  yield put(isLoading.BOX_IS_LOADING_GLOBAL_OFF());
};

const getPageData = function* ({
  payload,
}: SagaAction<RosterGetPageDataPayload>): SagaIterator {
  try {
    const siteId = yield select(getSiteId);
    yield put(fetchPageData.BOX_FETCH_PAGE_DATA_REQUEST(fetchPageData.ROSTERS));

    // update Rostered shifts
    yield all([
      call(getPastingStatusRequest, payload),
      call(getRosteredShiftsListRequest, payload),
      call(getTimesheetListRequest, payload),
      call(getTimeOffsListRequest, payload),
      call(getEventsRequest, payload),
      call(getRotaUserListRequest),
    ]);

    yield call(getSummaryData);

    yield put(
      BOX_ROSTER_GET_UNPUBLISHED({
        site_id: payload.site_id,
      })
    );

    yield put(
      BOX_ROSTER_GET_OFFERS({
        site_id: payload.site_id,
      })
    );

    yield put(BOX_ROSTER_DAY_VIEW_SITE_VIEW_ROW_CLEAR());
    yield put(BOX_ROSTER_DAY_VIEW_EVENTS_ROW_CLEAR());
    yield put(BOX_ROSTER_DAY_VIEW_UNASSIGNED_ROW_CLEAR());

    yield put(fetchPageData.BOX_FETCH_PAGE_DATA_SUCCESS(fetchPageData.ROSTERS));
    if (siteId !== payload.site_id) {
      yield put(BOX_ROSTER_CONTEXTUAL_MENU_SET_CLIPBOARD(null));
      yield put(BOX_ROSTER_CONTEXTUAL_MENU_SET_ACTION('none'));
    }
  } catch (error) {
    yield put(fetchPageData.BOX_FETCH_PAGE_DATA_FAILURE(fetchPageData.ROSTERS));
  }
};

const refactoredPublishRostersChanges = function* ({
  payload,
}: SagaAction<RefactoredPublishRostersPayload>): SagaIterator {
  try {
    const from = yield select(getFrom);
    const to = yield select(getTo);
    yield call(
      processApiRequestWithConfirm,
      Api.Roster.refactoredPublish,
      payload
    );
    yield put(BOX_ROSTER_PUBLISH_CHANGES_SUCCESS());
    yield put(BOX_ROSTER_CLOSE_APPLY_CHANGES_MODAL());

    yield call(getRostersRelatedData, {
      from,
      to,
      site_id: payload.site_id,
    });

    yield put(BOX_ROSTER_DAY_VIEW_SITE_VIEW_ROW_CLEAR());
    yield put(BOX_ROSTER_DAY_VIEW_EVENTS_ROW_CLEAR());
    yield put(BOX_ROSTER_DAY_VIEW_UNASSIGNED_ROW_CLEAR());
  } catch (error) {
    yield put(BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN(formatError(error)));
  }
};

const offerShifts = function* ({
  payload,
}: SagaAction<RosterOffersModalPayload>): SagaIterator {
  try {
    const offersPayload: RosterOffersPayload = omit(
      payload,
      'from',
      'to',
      'closeModal'
    );
    yield call(processApiRequest, Api.Roster.offer, offersPayload);
    yield put(BOX_ROSTER_OFFERS_CHANGES_SUCCESS());

    // close modal on success if needed
    if (payload.closeModal) {
      yield put(BOX_ROSTER_CLOSE_APPLY_CHANGES_MODAL());
    }

    yield call(getRostersRelatedData, payload);

    yield put(BOX_ROSTER_DAY_VIEW_SITE_VIEW_ROW_CLEAR());
    yield put(BOX_ROSTER_DAY_VIEW_EVENTS_ROW_CLEAR());
    yield put(BOX_ROSTER_DAY_VIEW_UNASSIGNED_ROW_CLEAR());
  } catch (error) {
    yield put(BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN(formatError(error)));
  }
};

const getUnpublishedRosters = function* ({
  payload,
}: SagaAction<GetUnpublishedRostersListPayload>): SagaIterator {
  try {
    const canEditRosteredShifts: boolean = yield select(
      hasPermissionSelector,
      'roster.rosteredshift.edit'
    );

    // TODO types
    const response: any = canEditRosteredShifts
      ? yield call(processApiRequest, Api.Roster.list, payload)
      : [];

    yield put(BOX_ROSTER_GET_UNPUBLISHED_SUCCESS(response));
  } catch (error) {
    yield put(BOX_ROSTER_GET_UNPUBLISHED_FAILURE(formatError(error)));
  }
};

const getShiftOffers = function* ({
  payload,
}: SagaAction<GetOffersPayload>): SagaIterator {
  try {
    const canEditRosteredShifts: boolean = yield select(
      hasPermissionSelector,
      'roster.rosteredshift.edit'
    );

    // TODO: add types
    const response: any = canEditRosteredShifts
      ? yield call(processApiRequest, Api.Roster.shiftsForOffer, payload)
      : {};
    yield put(BOX_ROSTER_GET_OFFERS_SUCCESS(response));
  } catch (error) {
    yield put(BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN(formatError(error)));
  }
};

const approveTimesheet = function* ({
  payload,
}: SagaAction<ApproveTimesheetPayload>): SagaIterator {
  try {
    const response: any = yield call(
      processApiRequest,
      Api.Timesheet.approve,
      payload
    );
    yield put(BOX_TIMESHEET_UPDATE_ONE(response));
    yield put(BOX_SUMMARY_GET_DATA_ON_ROSTER_UPDATES());
  } catch (error) {
    yield put(BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN(formatError(error)));
  }
};

export const approveAllTimesheets = function* ({
  payload,
}: SagaAction<ApproveTimesheetAllModalPayload>): SagaIterator {
  try {
    const isChannelActive = yield select(getChannelIsActive, 'roster_week');
    const approvePayload: ApproveTimesheetAllPayload = omit(
      payload,
      'to',
      'from'
    );
    yield call(processApiRequest, Api.Timesheet.approveAll, approvePayload);
    yield put(BOX_ROSTER_CLOSE_APPLY_CHANGES_MODAL());
    yield put(BOX_ROSTER_GLOBAL_APPROVE_TIMESHEET_ALL_SUCCESS());
    if (!isChannelActive) {
      yield call(getTimesheetListRequest, {
        site_id: payload.site_id,
        from: payload.from,
        to: payload.to,
      });
    }
    yield put(BOX_SUMMARY_GET_DATA_ON_ROSTER_UPDATES());
  } catch (error) {
    yield put(BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN(formatError(error)));
  }
};

const clearErrors = function* (): SagaIterator {
  yield put(BOX_ROSTER_SHIFT_MODAL_CLEAR_ERRORS());
};

export const deleteRosteredShift = function* ({
  payload,
}: SagaAction<DeleteRosteredShiftPayload>): SagaIterator {
  try {
    const canEditRosteredShifts: boolean = yield select(
      hasPermissionSelector,
      'roster.rosteredshift.edit'
    );
    if (!canEditRosteredShifts) {
      return false;
    }

    const { updateRelatedData, ...apiPayload } = payload;
    const deletedShiftId = payload.id;

    yield call(processApiRequest, Api.RosteredShift.delete, {
      ...apiPayload,
      auto_publish: true,
    });
    yield put(
      BOX_ROSTERED_SHIFTS_DELETE({
        id: deletedShiftId,
      })
    );
    yield put(BOX_ROSTER_DELETE_SHIFT_SUCCESS());
    const clipboard = yield select(getClipboard);
    if (clipboard && clipboard.id === deletedShiftId) {
      yield put(BOX_ROSTER_CONTEXTUAL_MENU_SET_CLIPBOARD(null));
      yield put(BOX_ROSTER_CONTEXTUAL_MENU_SET_ACTION('none'));
    }
  } catch (error) {
    yield put(BOX_ROSTER_DELETE_SHIFT_FAILUE(formatError(error)));
  }
};

export const deleteEventById = function* ({
  payload,
}: SagaAction<EventDeleteRequest>): SagaIterator {
  try {
    const event: Event = yield select(getEventByIdSelector, payload.id);
    yield call(processApiRequest, Api.Events.delete, payload.id);
    yield put(BOX_EVENTS_DELETE_ONE(payload.id));
    yield put(BOX_ROSTER_DELETE_SHIFT_SUCCESS());
    yield call(reFetchEventRelatedData, event);
  } catch (error) {
    yield put(BOX_ROSTER_DELETE_SHIFT_FAILUE(formatError(error)));
  }
};

const openShiftRangeInput = function* ({
  payload,
}: SagaAction<RangeInputOpenPayload>): SagaIterator {
  const isBulkDeleteOpened = yield select(getIsBulkDeleteOpened);
  const isCovidModeEnabled = yield select(getIsCovidModeEnabled);
  const isApprovalModeEnabled = yield select(getIsApprovalModeEnabled);
  const isRangeInputLoading = yield select(getRangeInputIsLoading);

  if (
    every(
      [
        isBulkDeleteOpened,
        isCovidModeEnabled,
        isApprovalModeEnabled,
        isRangeInputLoading,
      ],
      negate(identity as any)
    )
  ) {
    yield put(BOX_SHIFT_TIME_RANGE_INPUT_OPENED(payload));
  }
};

export const generateReport = function* ({
  payload,
}: SagaAction<GenerateCSVReportPayload>): SagaIterator {
  try {
    const response = yield call(
      processApiRequestWithConfirm,
      Api.Timesheet.export,
      payload
    );
    yield put(BOX_ROSTER_TIMESHEETS_CSV_SUCCESS(response));
  } catch (e) {
    yield put(BOX_ROSTER_TIMESHEETS_CSV_FAILURE(formatError(e)));
  }
};

export const generateTimesheets = function* ({
  payload,
}: SagaAction<AutoGenerateTimesheetsPayload>): SagaIterator {
  try {
    const response = yield call(
      processApiRequestWithConfirm,
      Api.Timesheet.generate,
      payload
    );
    yield put(BOX_ROSTER_TIMESHEETS_AUTOGENERATE_SUCCESS(!!response));
  } catch (e) {
    yield put(BOX_ROSTER_TIMESHEETS_AUTOGENERATE_FAILURE(formatError(e)));
  }
};

export const watchRoster = function* (): SagaIterator {
  yield takeLatest(
    BOX_ROSTER_GET_INITIAL_PAGE_DATA_REQUEST,
    getInitialPageData
  );
  yield takeLatest(BOX_ROSTER_GET_PAGE_DATA_REQUEST, getPageData);
  yield takeLatest(BOX_ROSTER_UPDATE_ROSTER_DATA_REQUEST, updateRosterData);
  yield takeLatest(
    BOX_ROSTER_PUBLISH_CHANGES_REFACTORED,
    refactoredPublishRostersChanges
  );
  yield takeLatest(BOX_ROSTER_GET_UNPUBLISHED, getUnpublishedRosters);
  yield takeLatest(BOX_ROSTER_GLOBAL_APPROVE_TIMESHEET, approveTimesheet);
  yield takeLatest(
    BOX_ROSTER_GLOBAL_APPROVE_TIMESHEET_ALL,
    approveAllTimesheets
  );
  yield takeLatest(BOX_ROSTER_GET_OFFERS, getShiftOffers);
  yield takeLatest(BOX_ROSTER_OFFERS_CHANGES, offerShifts);
  yield takeLatest(BOX_ROSTER_CLOSE_APPLY_CHANGES_MODAL, clearErrors);
  yield takeLatest(BOX_ROSTER_DELETE_SHIFT, deleteRosteredShift);
  yield takeLatest(BOX_ROSTER_DELETE_EVENT_BY_ID, deleteEventById);
  yield takeLatest(BOX_ROSTER_RANGE_INPUT_OPEN, openShiftRangeInput);
  yield takeLatest(BOX_ROSTER_TIMESHEETS_CSV_REQUEST, generateReport);
  yield takeLatest(
    BOX_ROSTER_TIMESHEETS_AUTOGENERATE_REQUEST,
    generateTimesheets
  );
};
