import { SagaIterator } from 'redux-saga';
import { SagaAction } from 'type';
import AuthService from 'lib/AuthService';
import { LoginToAccountPayload } from '../../types';
import { call } from 'redux-saga/effects';

export const accountLogin = function*({
  payload: { id }
}: SagaAction<LoginToAccountPayload>): SagaIterator {
  yield call(AuthService.setAccountId, id);
  window.location.href = '/';
};

export const adminLogin = function*(): SagaIterator {
  yield call(AuthService.clearAccountId);
  window.location.href = '/';
};
