import React from 'react';
import LoaderHolder from './LoaderHolder';
import { LoaderHolderProps } from '../type';

function CardImageStyle(props: LoaderHolderProps) {
  return (
    <LoaderHolder viewBoxHeight={140} viewBoxWidth={400} {...props}>
      <rect x="0" y="0" rx="0" ry="0" width="400" height="140" />
    </LoaderHolder>
  );
}

export default CardImageStyle;
