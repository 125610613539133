import React from 'react';
import { Box, Paper } from 'extended-oxygen-elements';

type Props = {
  children: React.ReactNode;
};

export function SummaryWrapper({ children }: Props) {
  return (
    <Paper data-testid={'summary-wrapper'}>
      <Box p={2} display="flex" flexDirection="column">
        {children}
      </Box>
    </Paper>
  );
}
