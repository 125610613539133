import React, { Component } from 'react';
import { FormItem, Input } from 'elmo-elements';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import { UserProfileFields } from 'type/models';
import {
  BOX_USER_PROFILE_PIN_CLOSE_MODAL,
  BOX_USER_PROFILE_PIN_OPEN_MODAL,
  BOX_USER_PROFILE_UPDATE_REQUEST,
} from 'state/Users/UserProfile/actions';
import {
  ErrorBox,
  PageDialog,
  PageDialogCancel,
  PageDialogSubmit,
} from 'element';
import { StringMap } from 'type';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';

type State = {
  errors: StringMap<string>;
  pin: string;
  confirmationPin: string;
  isValid: boolean;
};

type StateProps = {
  userProfile: UserProfileFields;
  errors: string[];
  isOpened: boolean;
  isUpdating: boolean;
};

type DispatchProps = {
  closeModal: () => void;
  openModal: () => void;
  clearErrors: () => void;
  updateProfile: (params: any) => void;
};

type Props = StateProps & DispatchProps;

class PinCodeModalComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errors: {
        pin: '',
        confirmationPin: '',
      },
      pin: '',
      confirmationPin: '',
      isValid: false,
    };
  }

  render() {
    return (
      <PageDialog
        maxWidth={'xs'}
        open={this.props.isOpened}
        id={'pincode-modal'}
        onClose={this.props.closeModal}
      >
        <DialogTitle>Pin code</DialogTitle>
        <DialogContent>{this.showPinCodeForm()}</DialogContent>
        <DialogActions>
          <PageDialogCancel onClick={this.props.closeModal} fullWidth={false}>
            Cancel
          </PageDialogCancel>
          <PageDialogSubmit
            disabled={!this.state.isValid}
            onClick={this.updateUserPin}
            loading={this.props.isUpdating}
            fullWidth={false}
          >
            Save
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  }

  updateUserPin = () => {
    this.props.updateProfile({
      id: this.props.userProfile.id,
      pin: this.state.pin,
      pin_confirmation: this.state.confirmationPin,
      message: 'Pin updated',
    });
  };

  showPinCodeForm = () => {
    return (
      <div>
        <ErrorBox
          className="mb-4"
          errors={this.props.errors}
          clearErrors={this.props.clearErrors}
          watchFormErrors={true}
        />

        <FormItem label="Username">
          <Input
            htmlType="text"
            name="username"
            value={this.props.userProfile.username}
            isReadOnly={true}
            ariaLabel="Username"
          />
        </FormItem>

        <FormItem
          label="Pin"
          message={this.state.errors.pin}
          status={this.state.errors.pin ? 'error' : undefined}
        >
          <Input
            htmlType="password"
            name="pin"
            value={this.state.pin}
            onChange={this.onChangeInput}
            placeholder={'4 digit number'}
            ariaLabel="Pin"
          />
        </FormItem>

        <FormItem
          label="Confirm Pin"
          message={this.state.errors.confirmationPin}
          status={this.state.errors.confirmationPin ? 'error' : undefined}
        >
          <Input
            htmlType="password"
            name="confirmationPin"
            value={this.state.confirmationPin}
            onChange={this.onChangeInput}
            placeholder={'4 digit number'}
            ariaLabel="Confirm Pin"
          />
        </FormItem>
      </div>
    );
  };

  onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    this.setState(
      {
        ...this.state,
        [name]: value,
      },
      () => {
        const { isValid, errors } = this.validate(
          this.state.pin,
          this.state.confirmationPin
        );

        this.setState({
          ...this.state,
          isValid: isValid,
          errors: errors,
        });
      }
    );
  };

  private readonly validate = (
    pin: string,
    confirmationPin: string
  ): { isValid: boolean; errors: StringMap<string> } => {
    const errors: StringMap<string> = {};

    if (pin.length === 0 || confirmationPin.length === 0) {
      return { isValid: false, errors: {} };
    }

    if (pin.length > 0 && (pin.length !== 4 || !/^\d+$/.test(pin))) {
      errors['pin'] = 'The pin must be 4 digits.';
    }

    if (pin !== confirmationPin) {
      errors['confirmationPin'] = 'The pin confirmation and pin must match.';
    }

    return {
      isValid: !Object.keys(errors).length,
      errors,
    };
  };
}

const mapStateToProps = ({
  userProfile: {
    userProfile,
    errors,
    pinModal: { isOpened },
    isUpdating,
  },
}: StoreState): StateProps => ({
  userProfile,
  errors,
  isOpened,
  isUpdating,
});

export const PinCodeModal = connect(mapStateToProps, {
  openModal: BOX_USER_PROFILE_PIN_OPEN_MODAL,
  closeModal: BOX_USER_PROFILE_PIN_CLOSE_MODAL,
  clearErrors: BOX_USER_PROFILE_PIN_CLOSE_MODAL,
  updateProfile: BOX_USER_PROFILE_UPDATE_REQUEST,
})(PinCodeModalComponent);
