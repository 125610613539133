import React from 'react';
import { DialogProps } from '../type';
import Modal from '../../Modal';
import Button from '../../Button';
import { HelpOutlineIcon } from '../../icons';

function ConfirmDialog({
  id,
  title,
  content,
  onCancel,
  onOk,
  okLabel,
  cancelLabel,
  ariaLabel,
  withoutIcon,
}: DialogProps) {
  return (
    <Modal
      id={id}
      {...(withoutIcon ? {} : { icon: <HelpOutlineIcon /> })}
      type="dialog"
      isOpened={true}
      closeModal={onCancel}
      title={title}
      ariaLabel={ariaLabel}
      primaryButton={
        <Button type="primary" isBlock={true} onClick={onOk}>
          {okLabel}
        </Button>
      }
      closeLabel={cancelLabel}
    >
      {content}
    </Modal>
  );
}

ConfirmDialog.defaultProps = {
  okLabel: 'Ok',
  cancelLabel: 'Cancel',
};

export default ConfirmDialog;
