import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { SagaAction, UpdateCurrentUserPayload } from 'type';
import { privateRoutes } from 'routes';
import { Api } from 'lib/Api';
import { formatError } from 'state/helpers';
import browserHistory from 'lib/browserHistory';
import { UserUpdateRequest, UserUpdateResponse } from 'lib/Api/type';
import {
  processApiRequest,
  processApiRequestWithConfirm,
} from 'state/ProcessApiRequest';
import { BOX_TOAST_NOTIFIER_MESSAGE_SHOW } from 'state/ToastNotifier';
import {
  BOX_USERS_COLLECTION_SET_OR_DELETE_ONE,
  getUserListRequest,
} from 'state/UsersCollection';
import { getUserGroupsRequest } from 'state/UserGroups/sagas';
import {
  AddRolePayload,
  AssignAwardPayload,
  EditApproversPayload,
  UpdateAwardPayload,
} from './types';
import {
  BOX_USER_PROFILE_ADD_ROLE_FAILURE,
  BOX_USER_PROFILE_ADD_ROLE_REQUEST,
  BOX_USER_PROFILE_ADD_ROLE_SUCCESS,
  BOX_USER_PROFILE_ADD_ROLE_UPDATE_USER_PROFILE,
  BOX_USER_PROFILE_AWARDS_DELETE_FAILURE,
  BOX_USER_PROFILE_AWARDS_DELETE_MODAL_CLOSE,
  BOX_USER_PROFILE_AWARDS_DELETE_REQUEST,
  BOX_USER_PROFILE_AWARDS_DELETE_SUCCESS,
  BOX_USER_PROFILE_AWARDS_FAILURE,
  BOX_USER_PROFILE_AWARDS_REQUEST,
  BOX_USER_PROFILE_AWARDS_SAVE,
  BOX_USER_PROFILE_AWARDS_SAVE_FAILURE,
  BOX_USER_PROFILE_AWARDS_SAVE_SUCCESS,
  BOX_USER_PROFILE_AWARDS_SUCCESS,
  BOX_USER_PROFILE_AWARDS_UPDATE_AWARD,
  BOX_USER_PROFILE_AWARDS_UPDATE_AWARD_FAILURE,
  BOX_USER_PROFILE_AWARDS_UPDATE_AWARD_SUCCESS,
  BOX_USER_PROFILE_CUSTOM_FILE_FAILURE,
  BOX_USER_PROFILE_CUSTOM_FILE_REQUEST,
  BOX_USER_PROFILE_CUSTOM_FILE_SUCCESS,
  BOX_USER_PROFILE_EDIT_APPROVALS_FAILURE,
  BOX_USER_PROFILE_EDIT_APPROVALS_REQUEST,
  BOX_USER_PROFILE_EDIT_APPROVALS_SUCCESS,
  BOX_USER_PROFILE_ENTITLEMENTS_FAILURE,
  BOX_USER_PROFILE_ENTITLEMENTS_REQUEST,
  BOX_USER_PROFILE_ENTITLEMENTS_SUCCESS,
  BOX_USER_PROFILE_FAILURE,
  BOX_USER_PROFILE_GET,
  BOX_USER_PROFILE_REQUEST,
  BOX_USER_PROFILE_SAVE_CURRENT_USER_SETTINGS,
  BOX_USER_PROFILE_SUCCESS,
  BOX_USER_PROFILE_UPDATE_FAILURE,
  BOX_USER_PROFILE_UPDATE_REQUEST,
  BOX_USER_PROFILE_UPDATE_SUCCESS,
  BOX_USER_PROFILE_UPLOAD_FAILURE,
  BOX_USER_PROFILE_UPLOAD_REQUEST,
  BOX_USER_PROFILE_UPLOAD_SUCCESS,
} from './actions';
import { getEditApprovalsUserId, getUserId } from './selectors';
import { editUserRequest } from '../sagas';
import { BOX_AUTH_UPDATE_CURRENT_USER_DATA } from 'state/Auth/actions';

const getUserProfileData = function* ({ payload }: any): SagaIterator {
  try {
    const { userId } = payload;
    const response: any = yield all([
      call(processApiRequest, Api.User.get, userId),
      call(getUserListRequest),
      call(getUserGroupsRequest),
    ]);
    yield put(BOX_USER_PROFILE_SUCCESS(response[0]));
  } catch (error) {
    yield put(BOX_USER_PROFILE_FAILURE(formatError(error)));
  }
};

const getUserData = function* (): SagaIterator {
  try {
    const id: string = yield select(getUserId);
    const response: any = yield call(processApiRequest, Api.User.get, id);
    yield put(BOX_USER_PROFILE_SUCCESS(response));
  } catch (error) {
    yield put(BOX_USER_PROFILE_FAILURE(formatError(error)));
  }
};

const getUserEntitlements = function* ({ payload }: any): SagaIterator {
  try {
    const response: any = yield call(
      processApiRequest,
      Api.User.entitlements,
      payload.userId
    );
    yield put(BOX_USER_PROFILE_ENTITLEMENTS_SUCCESS(response));
  } catch (error) {
    yield put(BOX_USER_PROFILE_ENTITLEMENTS_FAILURE(formatError(error)));
  }
};

const updateProfile = function* (
  action: SagaAction<Partial<UserUpdateRequest>>
): SagaIterator {
  try {
    const { message, ...rest } = action.payload;
    const profile = yield call(editUserRequest, rest);

    yield put(BOX_USER_PROFILE_UPDATE_SUCCESS(profile));
    if (message) {
      yield put(BOX_TOAST_NOTIFIER_MESSAGE_SHOW(message));
    }
  } catch (error) {
    yield put(BOX_USER_PROFILE_UPDATE_FAILURE(formatError(error)));
  }
};

const uploadFile = function* (
  action: SagaAction<{
    id: string;
    files: any;
  }>
): SagaIterator {
  try {
    const result: any = yield call(processApiRequest, Api.User.file, {
      id: action.payload.id,
      file: action.payload.files[0],
    });
    yield put(
      BOX_USER_PROFILE_UPLOAD_SUCCESS({
        key: 'avatar',
        filename: result.filename,
        all_files: true,
      })
    );
  } catch (error) {
    yield put(BOX_USER_PROFILE_UPLOAD_FAILURE(formatError(error)));
  }
};

const addUserRole = function* ({
  payload,
}: SagaAction<AddRolePayload>): SagaIterator {
  try {
    const { user_roles } = payload;
    const id: string = yield select(getUserId);
    const user: UserUpdateResponse = yield call(editUserRequest, {
      id,
      user_roles,
    });

    if (!user) {
      browserHistory.push(privateRoutes.users.routes.users.path);
    } else {
      yield put(BOX_USER_PROFILE_ADD_ROLE_UPDATE_USER_PROFILE(user));
    }

    yield put(
      BOX_USERS_COLLECTION_SET_OR_DELETE_ONE({
        id,
        user,
      })
    );

    yield put(BOX_USER_PROFILE_ADD_ROLE_SUCCESS());
    yield put(BOX_TOAST_NOTIFIER_MESSAGE_SHOW('Positions updated'));
  } catch (error) {
    yield put(BOX_USER_PROFILE_ADD_ROLE_FAILURE(formatError(error)));
  }
};

const customFieldFile = function* ({ payload }: SagaAction<any>): SagaIterator {
  try {
    const response: any = yield call(
      processApiRequest,
      Api.File.upload,
      payload.data
    );
    yield put(
      BOX_USER_PROFILE_CUSTOM_FILE_SUCCESS({
        name: payload.name,
        data: response,
        original_filename: payload.original_filename,
      })
    );
  } catch (error) {
    yield put(
      BOX_USER_PROFILE_CUSTOM_FILE_FAILURE({
        name: payload.name,
        data: formatError(error),
      })
    );
  }
};

const editApprovals = function* ({
  payload,
}: SagaAction<EditApproversPayload>): SagaIterator {
  try {
    const { managers } = payload;
    const id: ReturnType<typeof getEditApprovalsUserId> = yield select(
      getEditApprovalsUserId
    );

    const user: UserUpdateResponse = yield call(editUserRequest, {
      id,
      managers,
    });

    if (!user) {
      browserHistory.push(privateRoutes.users.routes.users.path);
    }

    yield put(
      BOX_USERS_COLLECTION_SET_OR_DELETE_ONE({
        id,
        user,
      })
    );
    yield put(BOX_USER_PROFILE_EDIT_APPROVALS_SUCCESS(payload));
    yield put(BOX_TOAST_NOTIFIER_MESSAGE_SHOW('Approvers updated'));
  } catch (error) {
    yield put(BOX_USER_PROFILE_EDIT_APPROVALS_FAILURE(formatError(error)));
  }
};

const saveCurrentUserSettings = function* (
  action: SagaAction<UpdateCurrentUserPayload>
): SagaIterator {
  try {
    const { payload } = action;
    const settings = yield call(
      processApiRequest,
      Api.User.updateCurrent,
      payload
    );
    yield put(BOX_AUTH_UPDATE_CURRENT_USER_DATA(settings));
  } catch (error) {
    console.log(formatError(error));
  }
};

const getAwardsData = function* ({
  payload,
}: SagaAction<{ userId: string }>): SagaIterator {
  try {
    const data = yield all([
      call(processApiRequest, Api.Awards.getGlobalAwardsList),
      call(processApiRequest, Api.Awards.getAssignedAwards, payload.userId),
    ]);
    yield put(BOX_USER_PROFILE_AWARDS_SUCCESS(data));
  } catch (error) {
    yield put(BOX_USER_PROFILE_AWARDS_FAILURE(formatError(error)));
  }
};

const saveAward = function* ({
  payload,
}: SagaAction<AssignAwardPayload>): SagaIterator {
  try {
    const data = yield call(
      processApiRequestWithConfirm,
      Api.Awards.assignAward,
      payload
    );
    yield put(BOX_USER_PROFILE_AWARDS_SAVE_SUCCESS(data));
  } catch (error) {
    yield put(BOX_USER_PROFILE_AWARDS_SAVE_FAILURE(formatError(error)));
  }
};

const updateAward = function* ({
  payload,
}: SagaAction<UpdateAwardPayload>): SagaIterator {
  try {
    const data = yield call(
      processApiRequestWithConfirm,
      Api.Awards.updateAward,
      payload
    );
    yield put(BOX_USER_PROFILE_AWARDS_UPDATE_AWARD_SUCCESS(data));
  } catch (error) {
    yield put(BOX_USER_PROFILE_AWARDS_UPDATE_AWARD_FAILURE(formatError(error)));
  }
};

const deleteAward = function* ({ payload }: SagaAction<string>): SagaIterator {
  try {
    yield call(processApiRequestWithConfirm, Api.Awards.deleteAward, payload);
    yield put(BOX_USER_PROFILE_AWARDS_DELETE_SUCCESS(payload));
    yield put(BOX_USER_PROFILE_AWARDS_DELETE_MODAL_CLOSE());
  } catch (error) {
    yield put(BOX_USER_PROFILE_AWARDS_DELETE_FAILURE(formatError(error)));
  }
};

export const watchUserProfile = function* (): SagaIterator {
  yield takeLatest(BOX_USER_PROFILE_REQUEST, getUserProfileData);
  yield takeLatest(BOX_USER_PROFILE_UPDATE_REQUEST as any, updateProfile); // TODO double-check
  yield takeLatest(BOX_USER_PROFILE_UPLOAD_REQUEST, uploadFile as any); // TODO double-check
  yield takeLatest(BOX_USER_PROFILE_ADD_ROLE_REQUEST, addUserRole);
  yield takeLatest(BOX_USER_PROFILE_GET, getUserData);
  yield takeLatest(BOX_USER_PROFILE_EDIT_APPROVALS_REQUEST, editApprovals);
  yield takeLatest(BOX_USER_PROFILE_CUSTOM_FILE_REQUEST, customFieldFile);
  yield takeLatest(BOX_USER_PROFILE_ENTITLEMENTS_REQUEST, getUserEntitlements);
  yield takeLatest(
    BOX_USER_PROFILE_SAVE_CURRENT_USER_SETTINGS,
    saveCurrentUserSettings
  );
  yield takeLatest(BOX_USER_PROFILE_AWARDS_REQUEST, getAwardsData);
  yield takeLatest(BOX_USER_PROFILE_AWARDS_SAVE, saveAward);
  yield takeLatest(BOX_USER_PROFILE_AWARDS_DELETE_REQUEST, deleteAward);
  yield takeLatest(BOX_USER_PROFILE_AWARDS_UPDATE_AWARD, updateAward);
};
