import { cloneDeep } from 'lodash';
import moment from 'moment';
import { PAGE_SIZE } from 'lib/config';
import { UnavailabilityReducerState } from './types';
import { filterTypes } from './constants';

export const getDefaultState = (): UnavailabilityReducerState => ({
  isUpdating: false,
  errors: [],
  timeOffsList: [],
  isCurrentlyUpdating: null,
  pager: {
    currentPage: 1,
    pageSize: PAGE_SIZE[0],
    total: 0
  },
  selectedFilter: 'pending',
  filtersForm: cloneDeep(filterTypes.pending),
  filters: cloneDeep(filterTypes.pending),
  createModal: {
    isOpened: false,
    isUpdating: false,
    errors: []
  },
  editModal: {
    isOpened: false,
    isUpdating: false,
    errors: [],
    timeOffId: ''
  },
  deleteModal: {
    isOpened: false,
    isUpdating: false,
    timeOffId: ''
  },
  confirmApprovalModal: {
    isOpened: false,
    isUpdating: false,
    timeOffId: '',
    message: []
  },
  confirmCreationModal: {
    isOpened: false,
    isUpdating: false,
    message: [],
    payload: {
      start: moment(),
      end: moment(),
      status: 'pending',
      until: null,
      repeat: 'once',
      description: '',
      duration: 'all_day',
      site_id: '',
      user_id: ''
    }
  },
  confirmEditModal: {
    isOpened: false,
    isUpdating: false,
    message: [],
    payload: {
      start: moment(),
      end: moment(),
      status: 'pending',
      until: null,
      repeat: 'once',
      description: '',
      duration: 'all_day',
      site_id: '',
      user_id: '',
      timeOffId: ''
    }
  },
  applicableUsers: {
    isFetched: false,
    isFetching: false,
    userIds: [],
    errors: []
  }
});
