import { AnyAction } from 'redux';
import { SafeDictionary } from 'ts-essentials';
import { ActionCreator, Reducer, ReduxActionsHandler } from './types';

export const createReducer = <S>(h: {}, defaultState: S): Reducer<S> => {
  const handlers: SafeDictionary<ReduxActionsHandler<S, any>[]> = {};

  const reducer = Object.assign(reduce, {
    on,
  });

  function on(
    actionCreator: ActionCreator<any>,
    handler: ReduxActionsHandler<S, any>
  ) {
    const actionType = actionCreator.getType();
    const actionHandlers = handlers[actionType] || [];
    actionHandlers.push(handler);
    handlers[actionType] = actionHandlers;

    return reducer;
  }

  function reduce(state = defaultState, action: AnyAction) {
    if (!action || typeof action.type !== 'string') {
      return state;
    }

    const actionHandlers = handlers[action.type];
    if (actionHandlers) {
      return actionHandlers.reduce(
        (prevState, actionHandler) => actionHandler(prevState, action.payload),
        state
      );
    }

    return state;
  }

  return reducer;
};
