import React from 'react';
import { Moment } from 'moment';
import { WorkOffOutlinedIcon, CalendarIcon } from 'element';
import { getTimeFormattedShortenedWithoutZeros } from 'lib/helpers';
import { useSelector } from 'react-redux';
import { timeFormatSelector } from 'state/Account';
import { createStyles, makeStyles } from '@mui/styles';
import { ServerRosterRepeatableTimeOff, TimeOffType } from 'type';
import { TIME_FORMAT } from 'lib/config';
import { shortenTimeFormat } from '../../../helpers';

const useTimeDetailsStyles = makeStyles((theme) =>
  createStyles({
    root: {
      fontSize: 12,
      fontWeight: 500,
      letterSpacing: 0.5,
      '& svg': {
        marginRight: 6,
      },
    },
  })
);
export const PartDayText = () => {
  const styles = useTimeDetailsStyles();
  return (
    <div className={styles.root}>
      <span>Half day</span>
    </div>
  );
};
