import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { privateRoutes } from 'routes';
import { FetchWrapper } from 'element';
import { StoreState } from 'state/types';
import { getCanViewShiftSwaps } from 'state/Account';
import { MANAGER_DASHBOARD_SHIFT_SWAPS } from 'state/FetchPageData/constants';
import { BOX_SHIFT_SWAPS_DATA_REQUEST } from 'state/ManagerDashboard/ShiftSwaps';
import {
  ManagerDashboardHeader,
  ManagerDashboardLayout,
} from '../../components';
import './style.scss';
import { SwapsList } from './components';

type OwnProps = {};

type StateProps = {
  canViewShiftSwaps: boolean;
};

type DispatchProps = {
  fetchData: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const ShiftSwaps = ({ canViewShiftSwaps, ...fetchWrapperProps }: Props) => {
  if (!canViewShiftSwaps) {
    return <Redirect to={privateRoutes.roster.path} />;
  }

  return (
    <ManagerDashboardLayout header={<ManagerDashboardHeader />}>
      <FetchWrapper
        {...fetchWrapperProps}
        pageId={MANAGER_DASHBOARD_SHIFT_SWAPS}
      >
        <SwapsList />
      </FetchWrapper>
    </ManagerDashboardLayout>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  canViewShiftSwaps: getCanViewShiftSwaps(state),
});

const mapDispatchToProps: DispatchProps = {
  fetchData: BOX_SHIFT_SWAPS_DATA_REQUEST,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShiftSwaps);
