import { createSelector } from 'reselect';
import { Pager, UserFields } from '../../../../../type/models';
import { UsersWithPagerSelector } from '../../../../../type';
import { StoreState } from '../../../../types';
import { dataIdsCombiner, withPagerCombiner } from '../../../../combiners';
import { getPager } from '../index';
import { foundOrFilteredUsersSelector } from './internal';

export const usersWithPagerSelector = createSelector<
  StoreState,
  UserFields[],
  Pager,
  UsersWithPagerSelector
>(foundOrFilteredUsersSelector, getPager, withPagerCombiner);

export const userIdsSelector = createSelector(
  foundOrFilteredUsersSelector,
  usersWithPagerSelector,
  dataIdsCombiner
);

export const getStoredPage = (state: StoreState) => state.user.storedPage;
export const getStoredPageSize = (state: StoreState) =>
  state.user.storedPageSize;
