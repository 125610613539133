import { AddOutlinedIcon } from 'element';
import {
  Button,
  buttonClasses,
  Paper,
  Tooltip,
} from 'extended-oxygen-elements';
import { capitalize } from 'lib/helpers';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLangPreferences } from 'state/Account';
import DialogAddArea, { useAssignableAreas } from './DialogAddArea';

type Props = {
  site_id: string;
};

export default function TileAreaCreate({ site_id }: Props) {
  const langPreferences = useSelector(getLangPreferences);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const hasAssignableAreas = !!useAssignableAreas(site_id).length;

  const handleAddAreaBtnClick = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Tooltip
        title={
          !hasAssignableAreas && (
            <>
              There are no {langPreferences.area.plural} that can be added to
              this {langPreferences.site.singular}
            </>
          )
        }
      >
        <div>
          <Paper
            variant="elevation"
            elevation={0}
            component={Button}
            onClick={handleAddAreaBtnClick}
            sx={(theme) => ({
              width: '100%',
              height: '100%',
              minHeight: '220px',
              color: theme.palette.primary.main,
              [`&.${buttonClasses.disabled}`]: {
                color: theme.palette.action.disabled,
              },
            })}
            disabled={!hasAssignableAreas}
          >
            <div>
              <AddOutlinedIcon /> Add{' '}
              {capitalize(langPreferences.area.singular)}
            </div>
          </Paper>
        </div>
      </Tooltip>
      <DialogAddArea
        site_id={site_id}
        open={isDialogOpen}
        onClose={handleDialogClose}
      />
    </>
  );
}
