import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';

type Props = {
  eventName: string;
};

const useTitleStyles = makeStyles((theme) => createStyles({
  title: {
    fontWeight: 400,
    fontSize: '11px',
    lineHeight: '14px',
    color: 'rgba(0, 0, 0, 0.67)',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    margin: 0,
    minHeight: '18px',
    maxWidth: '95%',
    flex: '1 1 0%',
    minWidth: 0
  }
}));

export const ShiftCardEventName = ({eventName}: Props) => {
  const styles = useTitleStyles();
  return (
    <div
      className="shift-card-view__title"
      data-testid="shift-event-name"
    >
      <h3 className={styles.title}>
        {eventName}
      </h3>
    </div>
  );
}
