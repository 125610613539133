import moment from 'moment';
import { Minutes } from 'type';
import { useTimeField } from 'hooks';
import { getDayBase, getUpdatedValue } from '../formatMoment';
import { ClearableTimeInputProps } from './type';

export function useTimeFieldMoment({
  value,
  initialDayValue,
  onChange,
  name,
}: Pick<
  ClearableTimeInputProps,
  'value' | 'onChange' | 'name' | 'initialDayValue'
>) {
  const minutes = value
    ? value.diff(value.clone().startOf('day'), 'minutes')
    : null;

  const getNewMomentValue = (newValueMinutes: Minutes | null) => {
    if (newValueMinutes === null) {
      return null;
    }

    return getUpdatedValue(
      getDayBase(value, initialDayValue),
      moment().startOf('day').add(newValueMinutes, 'minutes')
    );
  };

  const handleValueChange = (newValueMinutes: Minutes | null) => {
    onChange(getNewMomentValue(newValueMinutes), name);
  };

  return useTimeField({
    value: minutes,
    onValueChange: handleValueChange,
  });
}
