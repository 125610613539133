import React, { DragEvent, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Moment } from 'moment';
import { RosteredShift } from 'type';
import { StoreState } from 'state/types';
import { getIsApprovalModeEnabled } from 'state/Roster/Roster';
import {
  BOX_ROSTER_DAY_VIEW_DRAG_AND_DROP_STARTED,
  DragAndDropStartedPayload,
  oneMinuteInPxSelector,
} from 'state/Roster/RosterDayView';
import './style.scss';
import * as helpers from './helpers';

type OwnProps = {
  day: Moment;
  rosteredShift: Pick<RosteredShift, 'id' | 'start'>;
  children: ReactNode;
};

type StateProps = {
  oneMinuteInPx: number;
  isApprovalModeEnabled: boolean;
};

type DispatchProps = {
  shiftDragStarted: (payload: DragAndDropStartedPayload) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

let clonedTile: HTMLDivElement;
let ghostPlaceholder: HTMLDivElement;
let tileOffsetX: number;
let tileOffsetY: number;

document.addEventListener('dragover', event => {
  if (clonedTile && clonedTile.parentElement) {
    clonedTile.style.top = `${event.clientY - tileOffsetY}px`;
    clonedTile.style.left = `${event.clientX - tileOffsetX}px`;
  }
});

export const DayDraggableWrapperComponent = ({
  day,
  rosteredShift,
  oneMinuteInPx,
  shiftDragStarted,
  isApprovalModeEnabled,
  children
}: Props) => {

  const handleDragEnd = (event: Event) => {
    if (event.currentTarget) {
      event.currentTarget.removeEventListener('dragend', handleDragEnd);
    }

    helpers.removeNode(clonedTile);
    helpers.removeNode(ghostPlaceholder);
  };

  const handleDragStart = (event: DragEvent<HTMLDivElement>) => {
    event.currentTarget.addEventListener('dragend', handleDragEnd);

    event.stopPropagation();
    const tileBounds = helpers.getClickBounds(event);

    tileOffsetX = tileBounds.offsetX;
    tileOffsetY = tileBounds.offsetY;

    clonedTile = helpers.createClonedTile(event, tileBounds);
    document.body.appendChild(clonedTile);

    ghostPlaceholder = helpers.createGhostPlaceholder();
    document.body.appendChild(ghostPlaceholder);

    event.dataTransfer.effectAllowed = 'move';
    if (event.dataTransfer.setDragImage) {
      // IE11 fix
      event.dataTransfer.setDragImage(ghostPlaceholder, 0, 0);
    }

    const negativeTileOffsetX = helpers.getMidnightShiftNegativeOffset({
      day,
      oneMinuteInPx,
      rosteredShiftStart: rosteredShift.start
    });

    shiftDragStarted({
      offset: tileBounds.offsetX + negativeTileOffsetX,
      shiftId: rosteredShift.id
    });
  };

  return (
    <div
      draggable={!isApprovalModeEnabled}
      onDragStart={handleDragStart}
      className="day-draggable-wrapper"
    >
      {children}
    </div>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  oneMinuteInPx: oneMinuteInPxSelector(state),
  isApprovalModeEnabled: getIsApprovalModeEnabled(state)
});

const mapDispatchToProps: DispatchProps = {
  shiftDragStarted: BOX_ROSTER_DAY_VIEW_DRAG_AND_DROP_STARTED
};

export const DayDraggableWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(DayDraggableWrapperComponent);
