import React, { Component, ComponentType } from 'react';
import { LayoutContextProps, withLayoutAware } from 'elmo-elements';

type WrapperProps = LayoutContextProps;

export const withClosingBulkActionsAndSearchBeforeMount = <WrappedProps extends object>(
  WrappedComponent: ComponentType<WrappedProps>
): ComponentType<WrappedProps> => {
  class WithBulkActions extends Component<WrapperProps & WrappedProps> {
    render() {
      const {
        isContentScrolling,
        isSidePanelOverlay,
        isBulkActionOpen,
        isBulkActionDisabled,
        isSearchOpen,
        isModalOpen,
        toggleSidePanelOverlay,
        toggleBulkActionOpen,
        toggleBulkActionDisabled,
        toggleSearchOpen,
        toggleModalOpen,
        ...wrappedComponentProps
      } = this.props;

      return <WrappedComponent {...wrappedComponentProps as WrappedProps} />;
    }

    componentDidMount(): void {
      if (this.props.isBulkActionOpen) {
        this.props.toggleBulkActionOpen();
      }

      if (this.props.isSearchOpen) {
        this.props.toggleSearchOpen();
      }
    }
  }

  return withLayoutAware(WithBulkActions);
};
