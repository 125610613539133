import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import { getLangPreferences } from 'state/Account';
import {
  BOX_SHIFT_TIME_RANGE_INPUT_CREATE_REQUEST,
  daySelector,
  getIsLoading,
  siteUserRolesSelector
} from 'state/Roster/RangeInput/ShiftTimeRangeInput';
import { DispatchProps, StateProps } from './types';
import { UserShiftTimeRangeInputComponent } from './view';

const mapStateToProps = (state: StoreState): StateProps => ({
  userRoles: siteUserRolesSelector(state),
  day: daySelector(state),
  isLoading: getIsLoading(state),
  langPreferences: getLangPreferences(state)
});

const mapDispatchToProps: DispatchProps = {
  roleSelected: BOX_SHIFT_TIME_RANGE_INPUT_CREATE_REQUEST
};

export const UserShiftTimeRangeInput = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserShiftTimeRangeInputComponent);
