import _ from 'lodash';
import {
  BreakFieldGroupBreakErrors,
  BreakFieldGroupBreakErrorsCollection,
  BreaksFieldGroupBreak,
} from './types';
import { getBreakStartMoment } from './utils';
import { DateTimeType } from 'type';

type BreaksFieldGroupErrors = Partial<{
  breaks: BreakFieldGroupBreakErrorsCollection;
}>;

export const breaksFieldGroupValidator = (
  formFields: {
    breaks: BreaksFieldGroupBreak[];
  },
  dependencies: { start: DateTimeType; end: DateTimeType }
): BreaksFieldGroupErrors => {
  const errors: BreaksFieldGroupErrors = {};

  const breaks = formFields.breaks.reduce((accumulator, formBreak) => {
    let breakErrors: BreakFieldGroupBreakErrors = {};

    if (!formBreak.start) {
      breakErrors.start = 'Break start is required';
    } else {
      const breakStartMoment = getBreakStartMoment(
        formBreak.start,
        dependencies
      );

      if (breakStartMoment.isBefore(dependencies.start)) {
        breakErrors.start = 'Break start must be after timesheet start';
      } else if (breakStartMoment.isAfter(dependencies.end)) {
        breakErrors.start = 'Break start must be before timesheet end';
      }
    }

    if (!formBreak.duration) {
      breakErrors.duration = 'Break duration is required';
    } else if (formBreak.start) {
      const breakStartMoment = getBreakStartMoment(
        formBreak.start,
        dependencies
      );
      const breakEndMoment = breakStartMoment
        .clone()
        .add(formBreak.duration, 'minutes');

      if (breakEndMoment.isAfter(dependencies.end)) {
        breakErrors.duration =
          'Break must be finished before timesheet is finished';
      }
    }

    if (Object.keys(breakErrors).length) {
      accumulator[formBreak.id] = breakErrors;
    }

    return accumulator;
  }, {} as BreakFieldGroupBreakErrorsCollection);

  if (!_.isEmpty(breaks)) {
    errors.breaks = breaks;
  }

  return errors;
};

export default breaksFieldGroupValidator;
