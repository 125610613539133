import React from 'react';
import { useSelectorWithProps } from 'hooks';
import { getTimesheet } from 'state/TimesheetsCollection';
import { useAreaName, useLinkedRole } from './hooks';
import { ShiftCardBreaksDuration, ShiftCardIcons } from './views';
import { useTimesheetDayCard } from './hooks/useTimesheetDayCard';
import { ShiftCardViewRefactored } from './views/ShiftCardViewRefactored';

type Props = {
  timesheetId: string;
};

export const UserTimesheetDayCard = React.memo(function UserTimesheetDayCard({
  timesheetId,
}: Props) {
  const timesheet = useSelectorWithProps(getTimesheet, timesheetId);
  const { viewProps, icons, breaks } = useTimesheetDayCard(timesheet);
  const linkedRole = useLinkedRole(timesheet);
  const areaName = useAreaName(timesheet);

  return (
    <ShiftCardViewRefactored
      {...viewProps}
      data-timesheet-id={timesheetId}
      icons={<ShiftCardIcons icons={icons} />}
      breaks={<ShiftCardBreaksDuration breaks={breaks} />}
      title={linkedRole}
      spotTitle={areaName}
    />
  );
});
