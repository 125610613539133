import { styled } from 'extended-oxygen-elements';
import React from 'react';
import { useLoadingsTableContext } from './loadings-table-context';

export type LoadingsTableRowProps = {
  children: React.ReactNode;
};

export default LoadingsTableRow;

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const Child = styled('div', {
  shouldForwardProp: (propName) => propName !== 'flex',
})<{ flex: string }>(({ flex }) => ({
  flex,
}));

export function LoadingsTableRow({ children }: LoadingsTableRowProps) {
  const flexes = useLoadingsTableContext();

  return (
    <Root>
      {React.Children.map(children, (child, childIndex) => (
        <Child key={childIndex} flex={flexes[childIndex]}>
          {child}
        </Child>
      ))}
    </Root>
  );
}
