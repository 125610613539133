import React from 'react';
import { MultipleTimeOffsCard } from './MultipleTimeOffsCard';
import { SingleTimeOffCard } from './SingleTimeOffCard';
import { LeaveCard } from './LeaveCard';

type Props = {
  leaveId: string | null;
  timeOffIds: string[] | null;
  day: string;
};

export const UnavailabilityCard = ({ leaveId, timeOffIds, day }: Props) => {

  if ( leaveId ) {
    return <LeaveCard id={leaveId} day={day} />
  }

  if ( timeOffIds ) {
    return timeOffIds.length > 1
      ? <MultipleTimeOffsCard ids={timeOffIds} day={day} />
      : <SingleTimeOffCard id={timeOffIds[0]} day={day} />;
  }

  return null;

};