import { RosterDayViewReducerState } from './types';

export const getDefaultState = (): RosterDayViewReducerState => ({
  errorModal: {
    isOpened: false,
    errors: []
  },
  confirmModal: {
    isOpened: false,
    errors: []
  },
  createdShiftData: {
    shift: null,
    clientX: NaN,
    pairIndex: NaN,
    type: 'rosteredShift'
  },
  siteViewAdditionalRows: {},
  eventsAdditionalRows: 0,
  unassignedAdditionalRows: 0,
  dragAndDrop: {
    offset: 0,
    shiftId: ''
  }
});
