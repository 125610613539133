import Echo from 'laravel-echo';
import { Dictionary, SafeDictionary } from 'ts-essentials';
import io from 'socket.io-client';
import AuthService from './AuthService';

type Handler = () => void;
type ClientEvent = 'connect' | 'disconnect' | 'reconnectError';

type Channel = {
  notification(callBack: (notification: any) => void): Channel;
  listen(eventName: string, handler: (data: any) => void): Channel;
};

export interface WS {
  on(event: ClientEvent, handler: Handler): void;

  private(channelName: string): Channel;

  leave(channelName: string): void;
}

class SocketConnection implements WS {
  private echo: Echo;
  private handlers: SafeDictionary<Handler, ClientEvent> = {};

  constructor() {
    this.echo = new Echo({
      host: process.env.REACT_APP_SOCKET_URL,
      broadcaster: 'socket.io',
      client: io,
      auth: {
        headers: AuthService.getAuthHeaders(),
      },
      withCredentials: false,
    });

    this.echo.connector.socket.on('connect', () => {
      this.handlers.connect?.();
    });
    this.echo.connector.socket.on('disconnect', () => {
      this.handlers.disconnect?.();
    });

    this.echo.connector.socket.on(
      'reconnecting',
      (numberOfAttempts: number) => {
        if (numberOfAttempts === 5) {
          this.handlers.reconnectError?.();
          this.echo.disconnect();
        }
      }
    );
  }

  on(event: ClientEvent, handler: Handler) {
    this.handlers[event] = handler;
  }

  private(channelName: string) {
    const channel = this.echo.private(channelName);
    return {
      ...channel,
      notification: channel.notification,
      listen(event: string, handler: Function) {
        channel.listen(
          event,
          ({ socket, ...restData }: Dictionary<any> & { socket: null }) => {
            handler(restData);
          }
        );

        return this;
      },
    };
  }

  leave(channelName: string) {
    this.echo.leave(channelName);
  }
}

const socketConnection = new SocketConnection();

export default socketConnection;
