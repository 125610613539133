import { SagaIterator } from 'redux-saga';
import { call, put, race, select, take } from 'redux-saga/effects';
import {
  BOX_RE_ACCEPT_CONFIRM_MODAL_CANCEL,
  BOX_RE_ACCEPT_CONFIRM_MODAL_OPEN,
  BOX_RE_ACCEPT_CONFIRM_MODAL_SUBMIT
} from './actions';
import { getRosteredShiftsAcceptShifts } from '../../Account';
import { RosteredShift } from '../../../type/models';
import { getRosteredShift } from '../../RosteredShifts';
import { BOX_ROSTER_SHIFT_MODAL_SET_REACCEPT_VALUE, getReAcceptValue } from '../RosterShiftModal';

const reAcceptConfirmationModal = function*(): SagaIterator {
  yield put(BOX_RE_ACCEPT_CONFIRM_MODAL_OPEN());

  return yield race({
    submit: take(BOX_RE_ACCEPT_CONFIRM_MODAL_SUBMIT),
    close: take(BOX_RE_ACCEPT_CONFIRM_MODAL_CANCEL)
  });
};

export const getNeedReAccept = function*({
  rosteredShiftId,
  ignoreErrors,
  userId
}: {
  rosteredShiftId: string;
  ignoreErrors: boolean | undefined;
  userId?: null | string;
}): SagaIterator {
  let needReAccept: boolean = false;

  const rosteredShift: RosteredShift = yield select(
    getRosteredShift,
    rosteredShiftId
  );

  if (!rosteredShift) {
    return needReAccept;
  }

  const { is_accepted } = rosteredShift;

  const isUserChangedOrUnassigned = rosteredShift.user_id === null || (userId && userId !== rosteredShift.user_id);

  if (is_accepted) {
    const acceptShifts: boolean = yield select(getRosteredShiftsAcceptShifts);

    if ( acceptShifts && ignoreErrors ) {
      return yield select(getReAcceptValue);
    }

    if ( acceptShifts && !ignoreErrors && userId === null ) {
      return needReAccept;
    }

    if ( acceptShifts && !ignoreErrors && !isUserChangedOrUnassigned ) {
      const { submit } = yield call(reAcceptConfirmationModal);

      yield put(BOX_ROSTER_SHIFT_MODAL_SET_REACCEPT_VALUE(!!submit));

      if (submit) {
        needReAccept = true;
      }
    }
  }

  return needReAccept;
};
