import React from 'react';
import { ShiftOvertimeRuleWithDay } from 'state/Account';
import { usePayConditionsListContext } from '../pay-conditions-list-context';
import EditWeekendLoading from './weekendLoadingsRow/EditWeekendLoading';
import WeekendLoading from './weekendLoadingsRow/WeekendLoading';

type PayConditionRowProps = {
  overtimeRule: ShiftOvertimeRuleWithDay;
};

export default WeekendLoadingsRow;

function WeekendLoadingsRow({ overtimeRule }: PayConditionRowProps) {
  const { getIsEditing } = usePayConditionsListContext();

  if (getIsEditing(overtimeRule.id)) {
    return <EditWeekendLoading overtimeRule={overtimeRule} />;
  }

  return <WeekendLoading overtimeRule={overtimeRule} />;
}
