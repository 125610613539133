import { combineReducers, ReducersMapObject } from 'redux';
import { ShiftOffersReducerState } from './ShiftOffers/types';
import { ShiftOfferReducerState } from './ShiftOffer/types';
import { ShiftOffersUndoReducerState } from './ShiftOffersUndo/types';
import * as reducers from './reducers';

export type AllShiftOffersReducerState = {
  shiftOffers: ShiftOffersReducerState;
  shiftOffer: ShiftOfferReducerState;
  shiftOffersUndo: ShiftOffersUndoReducerState;
};

export const shiftOffers = combineReducers(reducers as ReducersMapObject<
  AllShiftOffersReducerState
>);
