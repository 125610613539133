import { AddIcon, FetchContainer } from 'element';
import { Box, Button, Dialog } from 'extended-oxygen-elements';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLangPreferences } from 'state/Account';
import {
  BOX_PAYMENT_ASSIGNED_USERS_DIALOG_OPEN,
  getFetchingErrors,
  getIsFetching,
} from 'state/PaymentAssignedUsersDialog';
import ViewAndAssignEmployeesDialogContent from './viewAndAssignEmployeesSection/ViewAndAssignEmployeesDialogContent';
import ViewAndAssignEmployeesDialogError from './viewAndAssignEmployeesSection/ViewAndAssignEmployeesDialogError';
import ViewAndAssignEmployeesDialogSkeleton from './viewAndAssignEmployeesSection/ViewAndAssignEmployeesDialogSkeleton';

export type ViewAndAssignEmployeesSectionProps = {
  disabled: boolean;
};

export default ViewAndAssignEmployeesSection;

export function ViewAndAssignEmployeesSection({
  disabled,
}: ViewAndAssignEmployeesSectionProps) {
  const langPreferences = useSelector(getLangPreferences);

  const [isAssignDialogOpen, setIsAssignDialogOpen] = React.useState(false);

  const handleViewAndAssignBtnClick = () => {
    setIsAssignDialogOpen(true);
  };

  const closeDialog = () => {
    setIsAssignDialogOpen(false);
  };

  const dialogOpenRequest = React.useMemo(
    () => BOX_PAYMENT_ASSIGNED_USERS_DIALOG_OPEN.request(),
    []
  );

  return (
    <Box display="flex" justifyContent="end">
      <Button
        startIcon={<AddIcon />}
        variant="contained"
        size="medium"
        disabled={disabled}
        onClick={handleViewAndAssignBtnClick}
      >
        View & assign {langPreferences.employee.plural}
      </Button>
      <Dialog open={isAssignDialogOpen} onClose={closeDialog}>
        <FetchContainer
          action={dialogOpenRequest}
          loadingSelector={getIsFetching}
          skeletonComponent={ViewAndAssignEmployeesDialogSkeleton}
          errorsSelector={getFetchingErrors}
          errorsComponent={ViewAndAssignEmployeesDialogError}
        >
          <ViewAndAssignEmployeesDialogContent onClose={closeDialog} />
        </FetchContainer>
      </Dialog>
    </Box>
  );
}
