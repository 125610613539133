import React, { SyntheticEvent } from 'react';
import './CheckboxInput.scss';

export type CheckboxInputProps = {
  ariaLabel?: string;
  id: string;
  name?: string;
  isChecked: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  value?: string | number;
  tabIndex?: number;
  onChange: (e: SyntheticEvent<HTMLInputElement>) => void;
};

function CheckboxInput({
  id,
  name,
  value,
  ariaLabel,
  isChecked,
  isDisabled,
  isRequired,
  onChange,
  tabIndex,
}: CheckboxInputProps) {
  return (
    <span aria-label={ariaLabel} className="elmo-checkbox__element">
      <input
        type="checkbox"
        id={'elmo-checkbox-' + id}
        name={name}
        aria-checked={isChecked}
        checked={isChecked}
        aria-labelledby={id}
        disabled={isDisabled}
        aria-disabled={isDisabled}
        onChange={onChange}
        value={value}
        required={isRequired}
        aria-label={ariaLabel}
        data-testid={`elmo-checkbox-input-${id || 'default'}`}
        tabIndex={!!tabIndex ? tabIndex : 0}
      />
      <span className="elmo-checkbox__icon" />
    </span>
  );
}

export default CheckboxInput;
