import { AlertErrorBoxLayout } from 'element';
import { useActions, useUpdateStatusEffect } from 'hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  BOX_SETTINGS_LAYOUT_ERRORS_CLEARED,
  BOX_SETTINGS_LAYOUT_PAGE_CLOSED,
  getPageErrors,
  getPageLoading,
  getPageStatus,
} from 'state/SettingsLayout';
import SettingsLayoutComponent, {
  SettingsLayoutComponentProps,
} from './SettingsLayoutComponent';

type Props = Omit<SettingsLayoutComponentProps, 'loading'> & {
  onSuccess?: () => void;
};

export default SettingsLayout;

export function SettingsLayout({ onSuccess, children, ...restProps }: Props) {
  const [pageClosed, errorsCleared] = useActions([
    BOX_SETTINGS_LAYOUT_PAGE_CLOSED,
    BOX_SETTINGS_LAYOUT_ERRORS_CLEARED,
  ]);
  const errors = useSelector(getPageErrors);
  const loading = useSelector(getPageLoading);

  React.useEffect(
    () => () => {
      pageClosed();
    },
    [pageClosed]
  );

  useUpdateStatusEffect(getPageStatus, {
    success: onSuccess,
  });

  const handleErrorsClose = () => {
    errorsCleared();
  };

  return (
    <SettingsLayoutComponent {...restProps} loading={loading}>
      <AlertErrorBoxLayout
        BoxProps={{
          mb: 2,
        }}
        errors={errors}
        onClose={handleErrorsClose}
      />

      {children}
    </SettingsLayoutComponent>
  );
}
