import React from 'react';
import { Data, DropDownProps } from '../type';
import CoverageCheckboxGroup from './CoverageCheckboxGroup';
import CoverageExtraGroup from './CoverageExtraGroup';
import { Divider } from 'elmo-elements';

export type DataProps = {
  handleOnChangeSelect: (e: DropDownProps, name: string) => void;
  handleOnChangeCheckbox: (e: any) => void;
  data: Data;
};

const CoverageViewGroup = ({
  data,
  handleOnChangeCheckbox,
  handleOnChangeSelect,
}: DataProps) => {
  return (
    <>
      <Divider />
      <br />
      <h3>View</h3>
      <CoverageExtraGroup
        data={data}
        handleOnChangeSelect={handleOnChangeSelect}
      />
      <CoverageCheckboxGroup
        data={data}
        handleOnChangeCheckbox={handleOnChangeCheckbox}
      />
    </>
  );
};

export default CoverageViewGroup;
