import React from 'react';
import { Popover } from 'elmo-elements';
import DayDetailsCard from '../DayDetailsCard';

type Props = {
  children: React.ReactNode;
  data: any;
  position: 'top-end' | 'bottom-end';
}

export const CalendarPopover = ({ children, data, position }: Props) => {
  return (
    <Popover
      isInline={true}
      isAutoWidth={false}
      isNoPadding={true}
      position={position}
      mode={'hover'}
      className={'popover-fix day-popover'}
    >
      <Popover.Target>
        {children}
      </Popover.Target>
      <Popover.Content>
        <DayDetailsCard data={data} />
      </Popover.Content>
    </Popover>
  );
};