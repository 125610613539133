import { useSelectorWithProps } from 'hooks';
import { userMaxRowsSelector } from 'state/TimesheetsWeeklyPage';
import useTimesheetsNewQueryStatus from './useTimesheetsNewQueryStatus';

type Props = {
  userId: string;
};

export function useMaxRowsNumber({ userId }: Props) {
  const status = useTimesheetsNewQueryStatus();

  return useSelectorWithProps(userMaxRowsSelector, { userId, status });
}

export default useMaxRowsNumber;
