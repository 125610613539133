import { createSelector } from 'reselect';
import { StoreState } from '../types';
import { StringMap, UserGroup } from '../../type';
import { SelectPropsOption } from 'elmo-elements/Select';
import { mapValues, values } from 'lodash';

export const getUserGroups = (state: StoreState): StringMap<UserGroup> => state.userGroups.userGroups;
export const getIsFetching = (state: StoreState): boolean => state.userGroups.isFetching;
export const getIsLoading = (state: StoreState): boolean => state.userGroups.isLoading;
export const getIsPermissionsSaved = (state: StoreState): boolean => state.userGroups.isPermissionsSaved;
export const getUserGroupsErrors = (state: StoreState) => state.userGroups.errors;
export const getGroup = (state: StoreState) => state.userGroups.group;
export const getAddModal = (state: StoreState) => state.userGroups.addModal;
export const getUpdateModal = (state: StoreState) => state.userGroups.updateModal;
export const getDeleteModal = (state: StoreState) => state.userGroups.deleteModal;
export const getNameError = (state: StoreState) => state.userGroups.nameError;

export const userGroupsAsSelectOptionsSelector = createSelector<
  StoreState,
  StringMap<UserGroup>,
  StringMap<SelectPropsOption>
>(getUserGroups, userGroups =>
  mapValues(
    userGroups,
    (value: UserGroup): SelectPropsOption => ({
      label: value.title,
      value: value.id
    })
  )
);

export const userGroupsAsSelectOptionsArraySelector = createSelector<
  StoreState,
  StringMap<SelectPropsOption>,
  SelectPropsOption[]
>(userGroupsAsSelectOptionsSelector, values);
