import React, { Component } from 'react';
import {
  DayDetails,
  EmployeeUnavailabilityProps,
  NotesModalProps,
  OpenModalProps,
  TimeOffRosteredShift,
} from 'state/EmployeeDashboard/Unavailability/types';
import { Heading, Paragraph, Button } from 'elmo-elements';
import {
  WorkOffOutlinedIcon,
  WorkOutlineIcon,
  CheckIcon,
  CancelOutlinedIcon,
  InfoOutlinedIcon,
  MoreHorizOutlinedIcon,
  SimpleBadge,
} from 'element';
import { getDateTimeFormatted, getTimeFormatted } from 'lib/helpers';
import './DayDetailsCard.scss';
import {
  divideByType,
  getLeaveCardLabel,
  isCasualContractor,
  showOriginalStatus,
  userCanSubmitUnavailability,
} from '../../../../helpers';
import {
  BOX_EMPLOYEE_GET_UNAVAILABILITY_BY_ID,
  BOX_EMPLOYEE_UNAVAILABILITY_MODAL_OPEN,
  BOX_EMPLOYEE_UNAVAILABILITY_NOTES_MODAL_TOGGLE,
} from 'state/EmployeeDashboard/Unavailability';
import { connect } from 'react-redux';
import { getEmployeeType, hasPermissionSelector } from 'state/Auth';
import { StoreState } from 'state/types';
import { getDateFormat, getTimeFormat } from 'state/Account';
import { PreferencesDateFormat, PreferencesTimeFormat } from 'type/models';
import { DSTIcon } from 'element/DSTIcon';
import { isAppMarket } from 'helpers';
import { QuestionAnswerOutlinedIcon } from 'element/icons';
import { IconButton } from 'extended-oxygen-elements';

type StateProps = {
  canEditUnavailability: boolean;
  employeeType: string;
  dateFormat: PreferencesDateFormat;
  timeFormat: PreferencesTimeFormat;
};

type DispatchProps = {
  openModal: (props: OpenModalProps) => void;
  getById: (id: string) => void;
  toggleNotesModal: (props: NotesModalProps) => void;
};

export type Props = {
  data: DayDetails;
  setNotes?: (id: string | null, notes: string | null) => void;
} & DispatchProps &
  StateProps;

export class DayDetailsCard extends Component<Props> {
  render() {
    return this.getEntityCard();
  }

  getEntityCard = () => {
    const { details } = this.props.data;
    const dayData = divideByType(details);
    if (details === null) {
      return null;
    }
    return (
      <div className={'popover-wrapper'}>
        {this.showTimeOffs(dayData.timeOffs)}
        {this.showShifts(dayData.shift)}
      </div>
    );
  };

  showTimeOffs = (data: EmployeeUnavailabilityProps[]) => {
    return data.map((d: EmployeeUnavailabilityProps, key: number) => (
      <div className="day-popover__wrapper" key={key}>
        <div className="day-popover__icon">
          <WorkOffOutlinedIcon />
        </div>
        <div className="day-popover__content">
          {this.getTimeOffTitle(d)}
          {this.getStatusBadge(d)}
          {this.getOriginalStatus(d)}
          {this.getDate(d)}
          {d.type === 'leave' ? '' : this.getTime(d)}
        </div>
      </div>
    ));
  };

  showShifts = (data: TimeOffRosteredShift[]) => {
    return data.map((d: TimeOffRosteredShift, key: number) => (
      <div className="day-popover__wrapper" key={key}>
        <div className="day-popover__icon">
          <WorkOutlineIcon />
        </div>
        <div className="day-popover__content">
          {this.getRosteredShiftTitle()}
          {this.getStatusBadge(d)}
          {this.getDate(d)}
          {this.getTime(d)}
        </div>
      </div>
    ));
  };

  rosteredShiftLabel = () => {
    return isAppMarket('uk') ? 'Scheduled' : 'Rostered';
  };

  leaveTitle = (d: EmployeeUnavailabilityProps) => {
    const { data } = this.props;
    const { title, subTitle } = getLeaveCardLabel(d, data);
    return (
      <>
        {title}
        <span className="popover-day__subtitle">{subTitle}</span>
      </>
    );
  };

  getRosteredShiftTitle = () => {
    return (
      <Heading isBold={true} size={'sm'} className={'mb-3 popover-day__title'}>
        {this.rosteredShiftLabel()}
      </Heading>
    );
  };

  getTimeOffTitle = (timeOff: EmployeeUnavailabilityProps) => {
    const { canEditUnavailability, employeeType } = this.props;
    const title =
      timeOff.type === 'leave' ? this.leaveTitle(timeOff) : 'Unavailable';
    const icon =
      canEditUnavailability && isCasualContractor(employeeType) ? (
        <MoreHorizOutlinedIcon />
      ) : (
        <span />
      );
    return (
      <Heading isBold={true} size={'sm'} className={'mb-3 popover-day__title'}>
        {title}
        {timeOff.type === 'leave' ? (
          <IconButton
            className={'pin-r'}
            onClick={() => {
              this.props.toggleNotesModal({
                id: timeOff.id,
                notes: timeOff.notes,
                isOpened: true,
              });
            }}
          >
            <QuestionAnswerOutlinedIcon
              color={'primary'}
              fontSize={'inherit'}
            />
          </IconButton>
        ) : (
          <Button
            isText={true}
            icon={icon}
            className={'pin-r'}
            onClick={(e) => this.openModal(timeOff, timeOff.type)}
          />
        )}
      </Heading>
    );
  };

  canCreateEditTimeOffs = () => {
    const { day, month, year } = this.props.data;
    const { canEditUnavailability, employeeType } = this.props;
    return userCanSubmitUnavailability({
      day: day,
      month: month,
      year: year,
      canEditUnavailability: canEditUnavailability,
      employeeType: employeeType,
    });
  };

  openModal = (d: EmployeeUnavailabilityProps, timeOffType: string) => {
    const type = this.canCreateEditTimeOffs() ? 'edit' : 'view';
    if (timeOffType === 'leave') {
      return;
    }
    this.props.openModal({ type: type });
    this.props.getById(d.id);
  };

  getStatusBadge = (d: EmployeeUnavailabilityProps | TimeOffRosteredShift) => {
    const status = d.status;
    if (status === 'approved') {
      return this.getApprovedBadge();
    }
    if (status === 'declined') {
      return this.getDeclinedBadge();
    }
    if (status === 'pending') {
      return this.getPendingBadge();
    }
    return undefined;
  };

  getOriginalStatus = (d: EmployeeUnavailabilityProps) => {
    return showOriginalStatus(d.original_status, d.type) ? (
      <Paragraph size={'xs'} color={'gray'} className={'mt-3'}>
        {d.original_status}
      </Paragraph>
    ) : null;
  };

  getApprovedBadge = () => {
    return (
      <SimpleBadge type={'success'} icon={<CheckIcon />} label={'APPROVED'} />
    );
  };

  getDeclinedBadge = () => {
    return (
      <SimpleBadge
        type={'danger'}
        icon={<CancelOutlinedIcon />}
        label={'DECLINED'}
      />
    );
  };

  getPendingBadge = () => {
    return (
      <SimpleBadge
        type={'warning'}
        icon={<InfoOutlinedIcon />}
        label={'PENDING'}
      />
    );
  };

  getDate = (d: EmployeeUnavailabilityProps | TimeOffRosteredShift) => {
    const { start, end, is_dst_intersect } = d;
    const { dateFormat, timeFormat } = this.props;
    const date =
      +start.format('DD') !== +end.format('DD')
        ? `${getDateTimeFormatted(
            dateFormat,
            timeFormat,
            start
          )} - ${getDateTimeFormatted(dateFormat, timeFormat, end)}`
        : getDateTimeFormatted(dateFormat, timeFormat, start);
    return (
      <Paragraph
        size={'xs'}
        color={'gray'}
        className={'date'}
        isTruncate={true}
      >
        {date} <DSTIcon isDST={is_dst_intersect} />
      </Paragraph>
    );
  };

  getTime = (d: EmployeeUnavailabilityProps | TimeOffRosteredShift) => {
    const { start, end } = d;
    const { timeFormat } = this.props;
    return (
      <Paragraph size={'sm'} isTruncate={true}>
        {getTimeFormatted(timeFormat, start)} -{' '}
        {getTimeFormatted(timeFormat, end)}
      </Paragraph>
    );
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  canEditUnavailability: hasPermissionSelector(
    state,
    'employeedashboard.submitunavailability'
  ),
  employeeType: getEmployeeType(state),
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
});

const mapToDispatchProps: DispatchProps = {
  openModal: BOX_EMPLOYEE_UNAVAILABILITY_MODAL_OPEN,
  getById: BOX_EMPLOYEE_GET_UNAVAILABILITY_BY_ID,
  toggleNotesModal: BOX_EMPLOYEE_UNAVAILABILITY_NOTES_MODAL_TOGGLE,
};

export default connect(mapStateToProps, mapToDispatchProps)(DayDetailsCard);
