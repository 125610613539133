import React, { Component, KeyboardEvent } from 'react';
import { getClass, uniqueId } from '../_lib/helper';
import { PopoverBase } from '../_lib/component';
import { DropdownBaseProps } from './type';
import {
  DropdownBaseBody,
  DropdownBaseDivider,
  DropdownBaseItem,
  DropdownBaseLabel,
} from './components';
import { DropdownBaseKeyConstants } from './constants';
import './DropdownBase.scss';

type DropdownState = {
  isOpen: boolean;
};

export class DropdownBase extends Component<DropdownBaseProps, DropdownState> {
  static Item = DropdownBaseItem;
  static Divider = DropdownBaseDivider;
  static Label = DropdownBaseLabel;
  static defaultProps: Pick<
    DropdownBaseProps,
    'position' | 'isOpen' | 'isFocusedOnOpen'
  > = {
    position: 'bottom-start',
    isOpen: false,
    isFocusedOnOpen: false,
  };

  protected container: any;
  protected bodyId: string;
  dropdownBodyRef = React.createRef<HTMLUListElement>();

  constructor(props: DropdownBaseProps) {
    super(props);
    // generate a unique ID for the component
    this.bodyId = uniqueId('elmo-dropdown');
    this.state = {
      isOpen: !!props.isOpen,
    };
  }

  componentDidMount() {
    const { isFocusedOnOpen } = this.props;
    const { isOpen } = this.state;

    if (isOpen && isFocusedOnOpen) {
      this.focusOnFirstItem();
    }
  }

  /**
   * Toggles the state of the menu
   */
  toggleMenu = () => {
    const { isDisabled } = this.props;

    if (isDisabled) {
      return;
    }

    this.setState(({ isOpen }) => ({ isOpen: !isOpen }), this.afterStateChange);
  };

  /**
   * Sets the menu's open state
   * @param isOpen
   */
  setMenuIsOpen = (isOpen: boolean) => {
    this.setState({ isOpen }, this.afterStateChange);
  };

  afterStateChange = () => {
    const { onToggle, onOpen, onClose, isFocusedOnOpen } = this.props;
    const { isOpen } = this.state;

    if (isOpen && isFocusedOnOpen) {
      this.focusOnFirstItem();
    }
    if (onToggle) {
      onToggle();
    }
    if (isOpen && onOpen) {
      onOpen();
    }
    if (!isOpen && onClose) {
      onClose();
    }
  };

  /**
   * Focus on the first item in the menu
   */
  focusOnFirstItem = () => {
    const menuItems = this.getMenuItems();
    const firstActive = menuItems.find(this.isElementEnabled);

    if (firstActive) {
      if ((firstActive as any).setActive) {
        (firstActive as any).setActive();
      } else {
        firstActive.focus({
          preventScroll: true,
        });
      }
    }
  };

  private isElementEnabled = (element: HTMLElement) => {
    return element.getAttribute('aria-disabled') !== 'true';
  };

  /**
   * When keyup events occur within the component
   * @param event
   */
  handleKeyboardEventsInside = (event: KeyboardEvent<HTMLDivElement>) => {
    const { isOpen } = this.state;
    // When the menu is closed, open the menu, then focus on the first one
    if (isOpen) {
      const callbacks = {
        [DropdownBaseKeyConstants.escapeKey]: this.closeDropdown,
        [DropdownBaseKeyConstants.enterKey]: this.focusOnFirstItem,
        [DropdownBaseKeyConstants.arrowDownKey]: this.focusOnNext,
        [DropdownBaseKeyConstants.arrowUpKey]: this.focusOnPrev,
      };

      const callbackToBeCalled = callbacks[event.key];

      if (callbackToBeCalled) {
        callbackToBeCalled(event);
      }
    }
  };

  private closeDropdown = () => {
    this.setMenuIsOpen(false);
  };

  private focusOn =
    (direction: 'next' | 'prev') => (event: KeyboardEvent<HTMLDivElement>) => {
      event.preventDefault(); // prevent page from scrolling;
      const menuItems = this.getMenuItems();
      const currentIndex: number = menuItems.indexOf(
        event.target as HTMLLIElement
      );

      const nextIndexToFocusOn: number = this.getNextActiveIndex(
        menuItems,
        currentIndex,
        direction
      );

      const nextElement = menuItems[nextIndexToFocusOn];

      if (nextElement) {
        nextElement.focus();
      }
    };

  private focusOnNext = this.focusOn('next');
  private focusOnPrev = this.focusOn('prev');

  private directionStep = {
    next: 1,
    prev: -1,
  };

  private getNextActiveIndex = (
    menuItems: HTMLElement[],
    currentIndex: number,
    direction: 'next' | 'prev'
  ): number => {
    const nextIndex = currentIndex + this.directionStep[direction];
    const nextElement = menuItems[nextIndex];

    if (!nextElement) {
      return -1;
    }

    if (this.isElementEnabled(nextElement)) {
      return nextIndex;
    }

    return this.getNextActiveIndex(menuItems, nextIndex, direction);
  };

  /**
   * Get items in the menu and returns as an array of elements
   */
  getMenuItems(): HTMLLIElement[] {
    const { current } = this.dropdownBodyRef;
    if (current) {
      return [].slice.call(current.querySelectorAll<HTMLLIElement>('li'));
    }

    return [];
  }

  render() {
    const {
      children,
      id,
      testId,
      className,
      position,
      isInline,
      isWidthAuto,
      renderTrigger,
    } = this.props;
    const { isOpen } = this.state;

    return (
      <div onKeyDown={this.handleKeyboardEventsInside}>
        <PopoverBase
          id={id}
          testId={testId || `oxygen-dropdown-base-${id || 'default'}`}
          className={getClass('oxygen-dropdown-base', className)}
          position={position}
          isOpen={isOpen}
          setIsOpen={this.setMenuIsOpen}
          isInline={isInline}
          mode="click"
          tabIndex={-1}
        >
          <PopoverBase.Target>
            {renderTrigger({
              toggleMenu: this.toggleMenu,
              isOpen,
            })}
          </PopoverBase.Target>
          <PopoverBase.Content
            className={className + ' elmo-elements'}
            role="presentation"
          >
            <DropdownBaseBody
              id={this.bodyId}
              toggleMenu={this.toggleMenu}
              children={children}
              parentId={id}
              ref={this.dropdownBodyRef}
              isWidthAuto={isWidthAuto}
            />
          </PopoverBase.Content>
        </PopoverBase>
      </div>
    );
  }
}
