import React from 'react';
import { SagaAction } from 'type';
import { useDispatch } from 'react-redux';

export type DispatchBeforeHandlerProps = {
  action: SagaAction<any>;
  children: React.ReactNode;
};

function DispatchBeforeHandler({ action, children }: DispatchBeforeHandlerProps) {
  const dispatch = useDispatch();

  const [dispatchedAction, setDispatchedAction] =
    React.useState<null | SagaAction<any>>(null);

  React.useEffect(() => {
    dispatch(action);
    setDispatchedAction(action);
  }, [dispatch, action]);

  if (action !== dispatchedAction) {
    return null;
  }

  return <>{children}</>;
}

export default DispatchBeforeHandler;
