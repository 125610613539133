import { Box, BoxProps } from 'extended-oxygen-elements';
import React from 'react';
import AlertError, { AlertErrorProps } from './AlertError';

export type AlertErrorBoxProps = AlertErrorProps & {
  scrollParentRef: React.RefObject<HTMLElement>;
  BoxProps?: BoxProps;
  scrollOffset?: number;
};

export default AlertErrorBox;

export function AlertErrorBox({
  scrollParentRef,
  BoxProps = {},
  scrollOffset = 0,
  ...alertErrorProps
}: AlertErrorBoxProps) {
  const boxRef = React.useRef<HTMLDivElement>(null);

  const { errors } = alertErrorProps;
  React.useEffect(() => {
    const { current: boxNode } = boxRef;
    const { current: scrollParentNode } = scrollParentRef;

    if (errors.length && boxNode && scrollParentNode) {
      const boxRect = boxNode.getBoundingClientRect();
      const scrollParentRect = scrollParentNode.getBoundingClientRect();

      scrollParentNode.scrollTo({
        top: boxRect.top - scrollParentRect.top - scrollOffset,
        behavior: 'smooth',
      });
    }
  }, [errors, scrollOffset, scrollParentRef]);

  if (!errors.length) {
    return null;
  }

  return (
    <Box {...BoxProps} ref={boxRef}>
      <AlertError {...alertErrorProps} />
    </Box>
  );
}
