import axios from 'axios';
import Cookies from 'js-cookie';

const HR_API_URL = process.env.REACT_APP_BREATHE_HR_URL as string;
const RTA_API_URL = process.env.REACT_APP_API_URL as string;

const getHrNavigationMenuData: any = async (cancelToken: any) => {
  try {
    const res = await axios.get(
      `${HR_API_URL}/internal_api/v2/navigation_menu`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get('breathe_id_token')}`,
          cancelToken: cancelToken,
        },
      }
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

const getHrHeaderSearchData: any = async (cancelToken: any, query: string) => {
  try {
    const res = await axios.get(
      `${HR_API_URL}/internal_api/v2/employees/search?query=${query}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get('breathe_id_token')}`,
          cancelToken: cancelToken,
        },
      }
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

const getRtaMenuData: any = async (cancelToken: any) => {
  try {
    const res = await axios.get(`${RTA_API_URL}/api/menu`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('breathe_id_token')}`,
        cancelToken: cancelToken,
      },
    });
    return res.data.data;
  } catch (err) {
    return err;
  }
};

export { getHrNavigationMenuData, getHrHeaderSearchData, getRtaMenuData };
