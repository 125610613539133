import {
  breaksFieldGroupValidator,
  dateRangeFieldGroupUtils,
  dateRangeFieldGroupValidator,
  notesFieldValidator,
  roleFieldValidator,
} from 'element';
import { EditDialogFormFields } from './createFormFields';

export const validate = (formFields: EditDialogFormFields) => ({
  ...roleFieldValidator(formFields),
  ...dateRangeFieldGroupValidator(formFields),
  ...(dateRangeFieldGroupUtils.isValidDateRangeGuard(formFields) &&
    breaksFieldGroupValidator(
      formFields,
      dateRangeFieldGroupUtils.getDateRange(formFields)
    )),
  ...notesFieldValidator(formFields),
});

export default validate;
