import { StoreState } from 'state/types';
import * as isLoadingModule from 'state/IsLoading';
import { ConfirmationReducerState } from './types';

const getState = (state: StoreState): ConfirmationReducerState =>
  state.errorsProcessing.confirmation;

export const getIsOpened = (state: StoreState) => getState(state).isOpened;
export const getMessages = (state: StoreState) => getState(state).messages;

export const getIsLoading = (state: StoreState) =>
  isLoadingModule.getIsLoading(state, isLoadingModule.CONFIRMATION_MODAL);
