import { createReducer } from 'lib/store-utils';
import * as actions from './actions';
import { getDefaultState } from './state';
import { EditTemplateReducerState } from './types';
import _, { omit } from 'lodash';

export const editTemplate = createReducer<EditTemplateReducerState>({}, getDefaultState());

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_SET_TEMPLATE_ID,
  (state, id): EditTemplateReducerState => ({
    ...state,
    templateId: id,
    title: id === '' ? '' : state.title
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_GET_DATA_SUCCESS,
  (state, data): EditTemplateReducerState => ({
    ...state,
    templateId: data.id,
    siteId: data.site_id,
    shifts: data.templated_rostered_shifts,
    title: data.title
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_GET_DATA_FAILURE,
  (state, errors): EditTemplateReducerState => ({
    ...state,
    errors
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_OPEN_EDIT_MODAL,
  (state, params): EditTemplateReducerState => ({
    ...state,
    editModal: {
      ...state.editModal,
      isOpened: true,
      payload: {
        ...state.editModal.payload,
        ...params
      }
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_CLOSE_EDIT_MODAL,
  (state): EditTemplateReducerState => ({
    ...state,
    editModal: {
      ...getDefaultState().editModal,
      isOpened: false
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_CREATE_SHIFT_ITEM,
  (state): EditTemplateReducerState => ({
    ...state,
    editModal: {
      ...state.editModal,
      isUpdating: true,
      errors: []
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_SHIFT_ITEM_ADD,
  (state, items): EditTemplateReducerState => ({
    ...state,
    shifts: {
      ...state.shifts,
      ...items
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_CREATE_SHIFT_ITEM_SUCCESS,
  (state): EditTemplateReducerState => ({
    ...state,
    editModal: {
      ...state.editModal,
      isUpdating: false
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_CREATE_SHIFT_ITEM_FAILURE,
  (state, errors): EditTemplateReducerState => ({
    ...state,
    editModal: {
      ...state.editModal,
      isUpdating: false,
      errors: errors
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_CLEAR_MODAL_ERRORS,
  (state): EditTemplateReducerState => ({
    ...state,
    editModal: {
      ...state.editModal,
      errors: []
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_EDIT_SHIFT_ITEM,
  (state): EditTemplateReducerState => ({
    ...state,
    editModal: {
      ...state.editModal,
      isUpdating: true,
      errors: []
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_EDIT_SHIFT_ITEM_SUCCESS,
  (state, items): EditTemplateReducerState => ({
    ...state,
    editModal: {
      ...state.editModal,
      isUpdating: false
    },
    shifts: {
      ...state.shifts,
      ...items
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_EDIT_SHIFT_ITEM_FAILURE,
  (state, errors): EditTemplateReducerState => ({
    ...state,
    editModal: {
      ...state.editModal,
      isUpdating: false,
      errors: errors
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_DELETE_SHIFT_ITEM,
  (state): EditTemplateReducerState => ({
    ...state,
    editModal: {
      ...state.editModal,
      isUpdating: true,
      isDeleting: true,
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_DELETE_SHIFT_ITEM_SUCCESS,
  (state, id): EditTemplateReducerState => ({
    ...state,
    editModal: {
      ...state.editModal,
      isOpened: false,
      isUpdating: false,
      isDeleting: false,
    },
    shifts: {
      ..._.omit(state.shifts, id)
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_DELETE_SHIFT_ITEM_FAILURE,
  (state, errors): EditTemplateReducerState => ({
    ...state,
    errors: errors
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_OPEN_DELETE_MODAL,
  (state): EditTemplateReducerState => ({
    ...state,
   confirmDeletionModal: {
      ...state.confirmDeletionModal,
     isOpened: true
   }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_CLOSE_DELETE_MODAL,
  (state): EditTemplateReducerState => ({
    ...state,
   confirmDeletionModal: {
      ...state.confirmDeletionModal,
     isOpened: false,
     isUpdating: false
   }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_CONFIRM_DELETE_MODAL,
  (state): EditTemplateReducerState => ({
    ...state,
   confirmDeletionModal: {
      ...state.confirmDeletionModal,
     isUpdating: true
   }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_DELETE_SHIFT_ITEM_FAILURE_CONFIRMATION,
  (state, errors): EditTemplateReducerState => ({
    ...state,
   confirmDeletionModal: {
      ...state.confirmDeletionModal,
     errors
   }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_CLEAR_DELETE_MODAL_ERRORS,
  (state): EditTemplateReducerState => ({
    ...state,
   confirmDeletionModal: {
      ...state.confirmDeletionModal,
     errors: []
   }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_DELETE_SHIFTS,
  (state, ids): EditTemplateReducerState => ({
    ...state,
    shifts: omit(state.shifts, ids)
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_SET_PREVIOUS_PAGE,
  (state, value): EditTemplateReducerState => ({
    ...state,
    prevPage: value
  })
);

/* Delete template */
editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_DELETE_SUCCESS,
  (state): EditTemplateReducerState => ({
    ...state,
    confirmDeletionTemplateModal: {
      ...state.confirmDeletionTemplateModal,
      isOpened: false,
      isUpdating: false
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_DELETE_FAILURE,
  (state, errors): EditTemplateReducerState => ({
    ...state,
    confirmDeletionTemplateModal: {
      ...state.confirmDeletionTemplateModal,
      isUpdating: false,
      errors: errors
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_OPEN_DELETE_TEMPLATE_MODAL,
  (state): EditTemplateReducerState => ({
    ...state,
    confirmDeletionTemplateModal: {
      ...state.confirmDeletionTemplateModal,
      isOpened: true
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_CLOSE_DELETE_TEMPLATE_MODAL,
  (state): EditTemplateReducerState => ({
    ...state,
    confirmDeletionTemplateModal: {
      ...state.confirmDeletionTemplateModal,
      isOpened: false,
      isUpdating: false
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_CONFIRM_DELETE_TEMPLATE_MODAL,
  (state): EditTemplateReducerState => ({
    ...state,
    confirmDeletionTemplateModal: {
      ...state.confirmDeletionTemplateModal,
      isUpdating: true
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_DELETE_CLEAR_ERRORS,
  (state): EditTemplateReducerState => ({
    ...state,
    confirmDeletionTemplateModal: {
      ...state.confirmDeletionTemplateModal,
      errors: []
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_SET_DRAGGABLE_SHIFT,
  (state, params): EditTemplateReducerState => ({
    ...state,
    draggableShift: params
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_SET_DROP_INPROGRESS,
  (state, value): EditTemplateReducerState => ({
    ...state,
    dropInProgress: value
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_DRAG_N_DROP,
  (state, payload): EditTemplateReducerState => {
    return {
      ...state,
      dropInProgress: true,
      dropCanceled: false,
      droppedShift: payload,
      dropError: []
    };
  }
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_DRAG_N_DROP_SUCCESS,
  (state, params): EditTemplateReducerState => {
    let shifts = omit(state.shifts, params.removeId);
    return {
      ...state,
      dropInProgress: false,
      dropError: [],
      shifts: {
        ...shifts,
        ...params.updateShifts
      }
    }
  }
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_DRAG_N_DROP_FAILURE,
  (state, error): EditTemplateReducerState => ({
    ...state,
    dropInProgress: false,
    dropError: error,
    dropCanceled: false
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_SET_DROP_CANCELED,
  (state, value): EditTemplateReducerState => {
    return {
      ...state,
      dropCanceled: value
    }
  }
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_SET_DROP_INPROGRESS,
  (state, value): EditTemplateReducerState => {
    return {
      ...state,
      dropInProgress: value
    }
  }
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_OPEN_UPDATE_TEMPLATE_MODAL,
  (state): EditTemplateReducerState => ({
    ...state,
    confirmUpdateTemplateModal: {
      ...state.confirmUpdateTemplateModal,
      isOpened: true
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_CLOSE_UPDATE_TEMPLATE_MODAL,
  (state): EditTemplateReducerState => ({
    ...state,
    confirmUpdateTemplateModal: {
      ...state.confirmUpdateTemplateModal,
      isOpened: false,
      isUpdating: false,
      errors: []
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_CONFIRM_UPDATE_TEMPLATE_MODAL,
  (state): EditTemplateReducerState => ({
    ...state,
    confirmUpdateTemplateModal: {
      ...state.confirmUpdateTemplateModal,
      isUpdating: true
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_UPDATE_SUCCESS,
  (state, title): EditTemplateReducerState => ({
    ...state,
    title,
    confirmUpdateTemplateModal: {
      ...state.confirmUpdateTemplateModal,
      isUpdating: false,
      isOpened: false,
      errors: []
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_UPDATE_FAILURE,
  (state, errors): EditTemplateReducerState => ({
    ...state,
    confirmUpdateTemplateModal: {
      ...state.confirmUpdateTemplateModal,
      isUpdating: false,
      errors
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_OPEN_SAVE_AS_TEMPLATE_MODAL,
  (state): EditTemplateReducerState => ({
    ...state,
    confirmSaveAsTemplateModal: {
      ...state.confirmSaveAsTemplateModal,
      isOpened: true
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_CLOSE_SAVE_AS_TEMPLATE_MODAL,
  (state): EditTemplateReducerState => ({
    ...state,
    confirmSaveAsTemplateModal: {
      ...state.confirmSaveAsTemplateModal,
      isOpened: false,
      isUpdating: false,
      errors: []
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_CONFIRM_SAVE_AS_TEMPLATE_MODAL,
  (state): EditTemplateReducerState => ({
    ...state,
    confirmSaveAsTemplateModal: {
      ...state.confirmSaveAsTemplateModal,
      isUpdating: true
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_SAVE_AS_SUCCESS,
  (state, template): EditTemplateReducerState => ({
    ...state,
    templateId: template.id,
    siteId: template.site_id,
    shifts: template.templated_rostered_shifts,
    title: template.title,
    confirmSaveAsTemplateModal: {
      ...state.confirmSaveAsTemplateModal,
      isUpdating: false,
      isOpened: false,
      errors: []
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_SAVE_AS_FAILURE,
  (state, errors): EditTemplateReducerState => ({
    ...state,
    confirmSaveAsTemplateModal: {
      ...state.confirmSaveAsTemplateModal,
      isUpdating: false,
      isOpened: true,
      errors
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_MODAL_PASTE_DATA,
  (state, data): EditTemplateReducerState => ({
    ...state,
    initialOwner: data!.user_id!,
    editModal: {
      ...state.editModal,
      payload: data
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_SAVE_AS_CLEAR_ERRORS,
  (state): EditTemplateReducerState => ({
    ...state,
    confirmSaveAsTemplateModal: {
      ...state.confirmSaveAsTemplateModal,
      errors: []
    }
  })
);

editTemplate.on(
  actions.BOX_EDIT_TEMPLATE_UPDATE_CLEAR_ERRORS,
  (state): EditTemplateReducerState => ({
    ...state,
    confirmUpdateTemplateModal: {
      ...state.confirmUpdateTemplateModal,
      errors: []
    }
  })
);
