import { ShiftCardViewProps } from '../../views';
import { useSelectorWithProps } from '../../../../../../hooks';
import {
  getTimesheetEnd,
  getTimesheetRosteredShift,
  getTimesheetRosteredShiftId,
  getTimesheetStart,
} from '../../../../../../state/TimesheetsCollection';
import { useMemo } from 'react';

type Props = {
  id: string;
};
export const useTimeColor = ({
  id,
}: Props): ShiftCardViewProps['timeColor'] => {
  // logic was copied from previous implementation
  // TODO double check if we have a scenario when timesheet has rostered shift and doesn't have rostered shift id
  const timesheetStart = useSelectorWithProps(getTimesheetStart, id);
  const timesheetEnd = useSelectorWithProps(getTimesheetEnd, id);
  const connectedRosteredShiftId = useSelectorWithProps(
    getTimesheetRosteredShiftId,
    id
  );
  const connectedRosteredShift = useSelectorWithProps(
    getTimesheetRosteredShift,
    id
  );

  return useMemo(() => {
    if (connectedRosteredShiftId && connectedRosteredShift) {
      if (
        timesheetStart.isAfter(connectedRosteredShift.end) ||
        timesheetStart.isBefore(connectedRosteredShift.start)
      ) {
        return 'error';
      }

      if (
        !timesheetEnd ||
        (timesheetEnd && timesheetEnd.isAfter(connectedRosteredShift.end)) ||
        timesheetEnd.isBefore(connectedRosteredShift.start)
      ) {
        return 'error';
      }
    }

    return 'default';
  }, [
    timesheetStart.format(),
    timesheetEnd?.format(),
    connectedRosteredShiftId,
    connectedRosteredShift,
  ]);
};
