import React from 'react';
import { CallMadeOutlinedIcon } from 'element';
import { BadgeType } from 'elmo-elements';
import { useSelectorWithProps } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getCanViewShiftOffers } from 'state/Account';
import { hasPermissionSelector } from 'state/Auth';
import {
  BOX_ROSTER_OPEN_APPLY_CHANGES_MODAL,
  shiftsForOfferNumberSelector,
} from 'state/Roster/Roster';

export default useBtnOfferRosteredShifts;

export function useBtnOfferRosteredShifts() {
  const dispatch = useDispatch();
  const canViewShiftOffers = useSelector(getCanViewShiftOffers);
  const canEditRosteredShifts = useSelectorWithProps(
    hasPermissionSelector,
    'roster.rosteredshift.edit'
  );
  const shiftsForOfferNumber = useSelector(shiftsForOfferNumberSelector);

  return (
    canEditRosteredShifts &&
    canViewShiftOffers && {
      id: 'offer-shifts',
      icon: <CallMadeOutlinedIcon />,
      onClick: () => {
        dispatch(BOX_ROSTER_OPEN_APPLY_CHANGES_MODAL('offers'));
      },
      label: 'Offer shifts',
      tooltip: 'Offer shifts',
      badge:
        shiftsForOfferNumber > 0
          ? {
              children: shiftsForOfferNumber,
              type: 'warning' as BadgeType,
            }
          : undefined,
      isDisabled: shiftsForOfferNumber === 0,
    }
  );
}
