import { AccountsReducerState } from './types';
import { PAGE_SIZE } from 'lib/config';

export const getDefaultState = (): AccountsReducerState => ({
  isUpdating: false,
  accounts: [],
  pager: {
    currentPage: 1,
    pageSize: PAGE_SIZE[0],
  },
  errors: [],
  search: {
    query: '',
  },
});
