const makeRequestWaiter = <T>(func: () => Promise<T>) => {
  let responsePromise: Promise<T> | null = null,
    requestCounter = 0;

  function afterResponse() {
    requestCounter--;

    if (!requestCounter) {
      responsePromise = null;
    }
  }

  return async (): Promise<T> => {
    requestCounter++;

    try {
      if (!responsePromise) {
        responsePromise = func();
      }

      const response = await responsePromise;
      afterResponse();
      return response;
    } catch (error) {
      afterResponse();
      throw error;
    }
  };
};

export default makeRequestWaiter;
