import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { hasPermissionSelector } from 'state/Auth';
import Api, { RosteredShiftGetListResponse } from 'lib/Api';
import { processApiRequest } from 'state/ProcessApiRequest';
import { FetchRosteredShiftsListPayload } from './types';

export const fetchRosteredShiftsList = function* (
  payload: FetchRosteredShiftsListPayload
): SagaIterator<RosteredShiftGetListResponse> {
  const canViewRosteredShifts: ReturnType<typeof hasPermissionSelector> =
    yield select(hasPermissionSelector, 'roster.rosteredshift.view');

  return canViewRosteredShifts
    ? yield call(processApiRequest, Api.RosteredShift.getList, payload)
    : {};
};
