import React from 'react';
import { connect } from 'react-redux';
import { Pager } from 'element';
import { StoreState } from 'state/types';
import { EmployeeTimesheetsWithPagerSelector } from '../../../../../../state/EmployeeDashboard/MyTimesheet/types';
import { employeeTimesheetsWithPagerSelector } from '../../../../../../state/EmployeeDashboard/MyTimesheet/selectors';
import {
  getIsLoading
} from '../../../../../../state/IsLoading';
import {
  BOX_MY_TIMESHEET_CHANGE_PAGE_REQUEST,
  BOX_MY_TIMESHEET_CHANGE_PAGE_SIZE_REQUEST
} from '../../../../../../state/EmployeeDashboard/MyTimesheet/actions';

type StateProps = {
  data: EmployeeTimesheetsWithPagerSelector;
  isUpdating: boolean;
};
type DispatchProps = {
  changePage: (pageNumber: number) => void;
  changePageSize: (pageSize: number) => void;
};
type Props = StateProps & DispatchProps;
const MyTimesheetPagerComponent = (props: Props) => <Pager {...props} />;

const mapStateToProps = (state: StoreState): StateProps => ({
  data: employeeTimesheetsWithPagerSelector(state),
  isUpdating: getIsLoading(state, 'EMPLOYEE_DASHBOARD_MY_TIMESHEET')
});

const mapDispatchToProps: DispatchProps = {
  changePage: BOX_MY_TIMESHEET_CHANGE_PAGE_REQUEST,
  changePageSize: BOX_MY_TIMESHEET_CHANGE_PAGE_SIZE_REQUEST
};

export const MyTimesheetPager = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyTimesheetPagerComponent);
