import React from 'react';
import { useSelectorWithProps } from 'hooks';
import { shownUserIdsSelector } from 'state/TimesheetsWeeklyPage';
import { useTimesheetsNewQueryStatus } from 'page/TimesheetsWeekly/hooks';
import { UserRow } from './components/UserRow';

export function UserRows() {
  const status = useTimesheetsNewQueryStatus();
  const userIds = useSelectorWithProps(shownUserIdsSelector, { status });

  return (
    <>
      {userIds.map((userId) => (
        <UserRow key={userId} userId={userId} />
      ))}
    </>
  );
}
