import React from 'react';
import { Layout } from 'element';
import FeatureWrapper from './FeatureWrapper';

type PayLayoutProps = {
  header: React.ReactNode;
  children: React.ReactNode;
};

export function PayLayout({ header, children }: PayLayoutProps) {
  return (
    <FeatureWrapper>
      <Layout.Header>
        {header}
      </Layout.Header>

      <Layout.Content>
        {children}
      </Layout.Content>
    </FeatureWrapper>
  );
}

export default PayLayout;
