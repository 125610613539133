import { SummaryReducerState } from './types';

export const getDefaultState = (): SummaryReducerState => ({
  isLoading: false,
  summary: {
    days: {},
    total: {
      rostered_shift_cost: 0.0,
      timesheet_cost: 0.0,
      rostered_shift_user_count: 0,
      rostered_shift_count: 0,
      rostered_shift_duration: 0,
      timesheet_user_count: 0,
      timesheet_count: 0,
      timesheet_duration: 0,
      rostered_shift_percent: 0,
      timesheet_percent: 0,
      timesheet_rostered_shift_percent_diff: 0
    }
  },
  errors: [],
  hasErrors: false,
  status: 'hidden'
});
