import React from 'react';
import { connect } from 'react-redux';
import { FetchWrapper } from 'element';
import { MANAGER_DASHBOARD_OVERVIEW } from 'state/FetchPageData/constants';
import {
  BOX_OVERVIEW_DATA_REQUEST,
} from 'state/ManagerDashboard/Overview';
import {
  ManagerDashboardHeader,
  ManagerDashboardLayout
} from '../../components';
import View from './view';

type OwnProps = {};

type StateProps = {};

type DispatchProps = {
  fetchData: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const Overview = (props: Props) => (
  <ManagerDashboardLayout header={<ManagerDashboardHeader />}>
    <FetchWrapper {...props} pageId={MANAGER_DASHBOARD_OVERVIEW}>
      <View />
    </FetchWrapper>
  </ManagerDashboardLayout>
);

const mapDispatchToProps: DispatchProps = {
  fetchData: BOX_OVERVIEW_DATA_REQUEST
};

export default connect(
  null,
  mapDispatchToProps
)(Overview);
