import { useSelector } from 'react-redux';
import { getAreas, getRoles } from 'state/AccountTree';
import { getIsBulkDeleteOpened } from 'state/Roster/BulkActions';
import { getIsApprovalModeEnabled } from 'state/Roster/Roster';
import { getIsCovidModeEnabled } from 'state/Roster/CovidTracing';
import { hasPermissionSelector } from 'state/Auth';
import { getUserListResponse } from 'state/UsersCollection';
import { useSelectorWithProps } from '../../../../../../../hooks';

type CanShowModalProps = {
  type: 'rostered_shift' | 'timesheet',
  userId: string | null,
  areaId: string | null | undefined,
  roleId: string | null | undefined
};

export const useCanShowModal = ({
  type, userId, areaId, roleId
}: CanShowModalProps) => {
  const areas = useSelector(getAreas);
  const roles = useSelector(getRoles);
  const isBulkDelete = useSelector(getIsBulkDeleteOpened);
  const isApproveMode = useSelector(getIsApprovalModeEnabled);
  const isCovidMode = useSelector(getIsCovidModeEnabled);
  const canEditTimesheet = useSelectorWithProps(hasPermissionSelector, 'roster.timesheet.edit');
  const canEditRosteredShift = useSelectorWithProps(hasPermissionSelector, 'roster.rosteredshift.edit');
  const users = useSelector(getUserListResponse);

  if ( type === 'rostered_shift' && !canEditRosteredShift ) {
    return false;
  }

  if ( type === 'timesheet' && !canEditTimesheet ) {
    return false;
  }

  if ( (window as any).inlineIsLoading || isApproveMode || isBulkDelete || isCovidMode ) {
    return false;
  }

  if ( userId !== null && users[userId] ) {
    return users[userId].is_active;
  }

  if ( areaId && roleId ) {
    if ( areas[areaId] && roles[roleId] ) {
      return !areas[areaId].archived && !roles[roleId].archived;
    }
  }

  return true;
};