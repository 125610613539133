import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FetchWrapper } from 'element';
import { EMPLOYEE_DASHBOARD_SHIFT_TRADES_SWAP_PAGE } from 'state/FetchPageData/constants';
import { EmployeeDashboardLayout } from '../../../../components';
import View from './view';
import {
  BOX_SHIFT_TRADES_CLEAR_OUTDATED_STATE,
  BOX_SHIFT_TRADES_DELETE_SHIFT,
  BOX_SHIFT_TRADES_GET_SHIFT_PROPOSALS,
  BOX_SHIFT_TRADES_SET_CURRENT_SHIFT_ID,
} from 'state/EmployeeDashboard/ShiftTrades';
import EmployeeDashboardShiftSwapHeader from './components/ShiftSwapHeader';
import { StoreState } from 'state/types';
import {
  getCurrentShiftId,
  shiftTradeIsDeleting,
} from 'state/EmployeeDashboard/ShiftTrades/selectors';
import { RouteComponentProps, withRouter } from 'react-router';
import { privateRoutes } from 'routes';

type OwnProps = {};

type StateProps = {
  currentShiftId: string | null;
  isDeleting: boolean;
};

type DispatchProps = {
  fetchData: () => void;
  setCurrentShiftID: (id: string) => void;
  deleteShiftTrade: (id: string) => void;
  clearState: () => void;
};

type TParams = {
  shiftTradeId: string;
};

type Props = OwnProps &
  StateProps &
  DispatchProps &
  RouteComponentProps<TParams>;

class ShiftSwap extends Component<Props> {
  componentDidMount(): void {
    if (this.props.currentShiftId === null) {
      const {
        match: { params }
      } = this.props;
      if (params && params.shiftTradeId) {
        this.props.setCurrentShiftID(params.shiftTradeId);
      }
    }
  }

  render() {
    const { fetchData, isDeleting, clearState } = this.props;
    return (
      <EmployeeDashboardLayout
        header={
          <EmployeeDashboardShiftSwapHeader
            clickBackBtn={this.backToList}
            clickRemoveBtn={this.removeShift}
            deleteDisabled={isDeleting}
          />
        }
      >
        <FetchWrapper
          fetchData={fetchData}
          clearState={clearState}
          pageId={EMPLOYEE_DASHBOARD_SHIFT_TRADES_SWAP_PAGE}
        >
          <View />
        </FetchWrapper>
      </EmployeeDashboardLayout>
    );
  }

  backToList = () => {
    this.props.history.push(
      privateRoutes.employeeDashboard.routes.trades.routes.shiftTrades.path
    );
  };

  removeShift = () => {
    if (this.props.currentShiftId !== null) {
      this.props.deleteShiftTrade(this.props.currentShiftId);
    }
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  currentShiftId: getCurrentShiftId(state),
  isDeleting: shiftTradeIsDeleting(state)
});

const mapDispatchToProps: DispatchProps = {
  fetchData: BOX_SHIFT_TRADES_GET_SHIFT_PROPOSALS,
  setCurrentShiftID: BOX_SHIFT_TRADES_SET_CURRENT_SHIFT_ID,
  deleteShiftTrade: BOX_SHIFT_TRADES_DELETE_SHIFT,
  clearState: BOX_SHIFT_TRADES_CLEAR_OUTDATED_STATE
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ShiftSwap)
);
