import React, { Component } from 'react';
import {
  ContentCopyOutlinedIcon,
  ContentCutOutlinedIcon,
  DeleteOutlinedIcon,
  EditIcon,
  PasteIcon
} from 'element';
import { StoreState } from '../../../../state/types';
import { connect } from 'react-redux';
import {
  BOX_ROSTER_CONTEXTUAL_MENU_CLOSE,
  BOX_ROSTER_CONTEXTUAL_MENU_COPY_ROSTERED_SHIFT,
  BOX_ROSTER_CONTEXTUAL_MENU_COPY_SHIFT_ITEM,
  BOX_ROSTER_CONTEXTUAL_MENU_CUT_ROSTERED_SHIFT,
  BOX_ROSTER_CONTEXTUAL_MENU_CUT_SHIFT_ITEM,
  BOX_ROSTER_CONTEXTUAL_MENU_OPEN,
  BOX_ROSTER_CONTEXTUAL_MENU_PASTE_AND_OPEN,
  BOX_ROSTER_CONTEXTUAL_MENU_PASTE_ITEM_AND_OPEN,
  BOX_ROSTER_CONTEXTUAL_MENU_PASTE_ROSTERED_SHIFT,
  BOX_ROSTER_CONTEXTUAL_MENU_PASTE_SHIFT_ITEM,
  BOX_ROSTER_CONTEXTUAL_MENU_REPLACE_IN_MODAL,
  BOX_ROSTER_CONTEXTUAL_MENU_REPLACE_ITEM_IN_MODAL,
} from 'state/Roster/ContextualMenu/actions';
import {
  contextInProgress,
  getClipboard,
  getMenuId,
} from 'state/Roster/ContextualMenu/selectors';
import {
  BOX_ROSTER_SHIFT_MODAL_GET_BY_ID,
  getRosteredShiftModal,
} from 'state/Roster/RosterShiftModal';
import {
  BOX_TIMESHEET_SHIFT_MODAL_DELETE_SHIFT_CONTEXT,
  BOX_TIMESHEET_SHIFT_MODAL_GET_BY_ID,
} from 'state/TimesheetModal';
import { GetRosterByIDPayload } from 'state/Roster/RosterShiftModal/types';
import { GetTimesheetByIDPayload } from 'state/TimesheetModal/types';
import { hasPermissionSelector } from 'state/Auth/selectors';
import { MenuDropDown } from './componenets/MenuDropDown';
import {
  getIsApprovalModeEnabled,
  getRosterErrorModal,
} from 'state/Roster/Roster/selectors';
import { DispatchProps, Props, StateProps } from './types';
import './style.scss';
import { getIsBulkDeleteOpened } from 'state/Roster/BulkActions/selectors';
import { isMobileDevice } from 'lib/helpers';
import { BOX_EDIT_TEMPLATE_OPEN_EDIT_MODAL } from '../../../../state/Roster/EditTemplate/actions';
import { getEditModal } from '../../../../state/Roster/EditTemplate/selectors';

export class ContextualMenu extends Component<Props> {
  card: null | Element = null;

  componentDidMount(): void {
    const { shiftType, canEditTimesheet, canEditRosteredShift, asButton } =
      this.props;
    if (
      canEditTimesheet &&
      shiftType === 'timesheet' &&
      !asButton &&
      !isMobileDevice()
    ) {
      this.addListener();
    }
    if (
      canEditRosteredShift &&
      shiftType === 'rostered_shift' &&
      !asButton &&
      !isMobileDevice()
    ) {
      this.addListener();
    }
    if (
      canEditRosteredShift &&
      shiftType === 'shift_item' &&
      !asButton &&
      !isMobileDevice()
    ) {
      this.addListener();
    }
  }

  addListener = () => {
    setTimeout(() => {
      this.card = document.querySelector('#' + this.props.elementId);
      if (this.card) {
        this.card.addEventListener('contextmenu', this.handleContextMenu, true);
      }
    }, 0);
  };

  componentWillUnmount(): void {
    if (this.card) {
      this.card.removeEventListener(
        'contextmenu',
        this.handleContextMenu,
        true
      );
    }
  }

  removeTargetClassNames = () => {
    const targetEls = document.querySelectorAll('.context-target-action');
    if (targetEls) {
      targetEls.forEach((el) => el.classList.remove('context-target-action'));
    }
  };

  removeCutClassNames = () => {
    const cutEls = document.querySelectorAll('.context-cut-action');
    if (cutEls) {
      cutEls.forEach((el) => el.classList.remove('context-cut-action'));
    }
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    if (
      prevProps.clipboard !== this.props.clipboard &&
      this.props.clipboard === null
    ) {
      this.removeCutClassNames();
    }
    if (
      prevProps.inProgress !== this.props.inProgress &&
      !this.props.inProgress
    ) {
      this.removeTargetClassNames();
    }
    if (this.props.errors.length) {
      setTimeout(() => {
        this.removeCutClassNames();
        this.removeTargetClassNames();
      }, 1000);
    }
    if (
      (prevProps.modalIsOpened !== this.props.modalIsOpened &&
        !this.props.modalIsOpened) ||
      (prevProps.itemModal.isOpened !== this.props.itemModal.isOpened &&
        !this.props.itemModal.isOpened)
    ) {
      this.removeTargetClassNames();
    }
  }

  handleContextMenu = (e: any) => {
    e.preventDefault();
    // e.stopPropagation();
    const { isApproveMode, isBulkMode } = this.props;
    if (isApproveMode || isBulkMode) {
      return false;
    }
    this.onOpenMenu();
  };

  onOpenMenu = () => {
    const { asButton } = this.props;
    if (asButton) {
      this.card = document.querySelector('#' + this.props.elementId);
    }
    this.props.open({
      id: this.props.elementId,
      objectId: this.props.objectId,
      cellData: this.props.currentCellData,
    });
    if (this.card) {
      this.removeTargetClassNames();
      this.card.classList.add('context-target-action');
    }
  };

  cutAction = (e: any) => {
    const { shiftType } = this.props;
    if (this.card) {
      this.removeCutClassNames();
      this.removeTargetClassNames();
      shiftType === 'rostered_shift'
        ? this.props.cutRosteredShift()
        : this.props.cutShiftItem();
      this.card.classList.add('context-cut-action');
    }
  };

  createAction = () => {
    const { shiftType } = this.props;
    this.onCloseAction();
    if (shiftType === 'shift_item') {
      this.props.openShiftItemModal(this.props.currentCellData as any);
      return;
    }
    shiftType === 'rostered_shift'
      ? this.props.openRosteredShiftModal(
          this.props.currentCellData as GetRosterByIDPayload
        )
      : this.props.openTimesheetModal(
          this.props.currentCellData as GetTimesheetByIDPayload
        );
  };

  deleteTimesheet = () => {
    this.props.deleteShift();
    this.removeTargetClassNames();
  };

  cantPaste = () => {
    const { clipboard, objectId, shiftType } = this.props;
    if (!clipboard) {
      return true;
    }
    let type = clipboard.hasOwnProperty('timesheet_id')
      ? 'rostered_shift'
      : 'timesheet';
    if (shiftType === 'shift_item') {
      type = 'shift_item';
    }
    return objectId === clipboard.id || type !== shiftType;
  };

  pasteAction = () => {
    const { asButton, shiftType } = this.props;
    if (!asButton) {
      shiftType === 'rostered_shift'
        ? this.props.pasteRosteredShift()
        : this.props.pasteShiftItem();
    } else {
      this.removeTargetClassNames();
      shiftType === 'rostered_shift'
        ? this.props.replaceShiftDataInModal()
        : this.props.replaceItemDataInModal();
    }
  };

  pasteAndEditAction = () => {
    const { shiftType } = this.props;
    this.removeTargetClassNames();
    shiftType === 'rostered_shift'
      ? this.props.pasteRosteredShiftAndOpenModal()
      : this.props.pasteItemAndOpenModal();
  };

  pasteActions = () => {
    const { asButton } = this.props;
    const btns = [
      {
        onClick: this.pasteAction,
        ariaLabel: 'Paste',
        isDisabled: !this.props.clipboard || this.cantPaste(),
        icon: <PasteIcon />,
        label: 'Paste',
        withDivider: !asButton,
      },
    ];
    if (!asButton) {
      btns.push({
        onClick: this.pasteAndEditAction,
        ariaLabel: 'Paste & edit',
        isDisabled: !this.props.clipboard || this.cantPaste(),
        icon: <PasteIcon />,
        label: 'Paste & edit',
        withDivider: false,
      });
    }
    return btns;
  };

  copyAction = () => {
    const { shiftType } = this.props;
    this.removeTargetClassNames();
    this.removeCutClassNames();
    if (shiftType === 'shift_item') {
      this.props.copyShiftItem();
    } else {
      this.props.copyRosteredShift();
    }
  };

  getRosterMenuItems = () => {
    const { type, objectId } = this.props;
    return type === 'edit'
      ? [
          {
            onClick: this.cutAction,
            ariaLabel: 'Cut',
            isDisabled: !objectId,
            icon: <ContentCutOutlinedIcon />,
            label: 'Cut',
          },
          {
            onClick: this.copyAction,
            ariaLabel: 'Copy',
            isDisabled: !objectId,
            icon: <ContentCopyOutlinedIcon />,
            label: 'Copy',
          },
          ...this.pasteActions(),
        ]
      : [
          {
            onClick: this.createAction,
            ariaLabel: 'Create',
            isDisabled: false,
            icon: <EditIcon />,
            label: 'Create',
          },
          ...this.pasteActions(),
        ];
  };

  getTimesheetMenuItems = (): any[] => {
    const { type } = this.props;
    return type === 'edit'
      ? [
          {
            onClick: this.deleteTimesheet,
            ariaLabel: 'Delete',
            isDisabled: false,
            icon: <DeleteOutlinedIcon />,
            label: 'Delete',
          },
        ]
      : [
          {
            onClick: this.createAction,
            ariaLabel: 'Create',
            isDisabled: false,
            icon: <EditIcon />,
            label: 'Create',
          },
        ];
  };

  getActionsConfig = () => {
    const { shiftType } = this.props;
    if (shiftType === 'shift_item') {
      return this.getRosterMenuItems();
    }
    return shiftType === 'rostered_shift'
      ? this.getRosterMenuItems()
      : this.getTimesheetMenuItems();
  };

  onCloseAction = () => {
    this.removeTargetClassNames();
    this.props.close();
  };

  getDropDownView = () => {
    const { asButton, icon } = this.props;
    return (
      <MenuDropDown
        onCloseAction={this.onCloseAction}
        asButton={asButton}
        icon={icon}
        config={this.getActionsConfig()}
        onOpen={this.onOpenMenu}
      />
    );
  };

  renderDropdown() {
    const { className } = this.props;
    return (
      <div className={`card-contextual-menu ${className ? className : ''}`}>
        {this.getDropDownView()}
      </div>
    );
  }

  render() {
    const {
      id,
      elementId,
      canEditRosteredShift,
      canEditTimesheet,
      asButton,
      modalIsOpened,
      shiftType,
      itemModal,
    } = this.props;
    if (!canEditRosteredShift && shiftType === 'rostered_shift') {
      return null;
    }
    if (!canEditTimesheet && shiftType === 'timesheet') {
      return null;
    }
    if (asButton) {
      return this.getDropDownView();
    }
    return !modalIsOpened && !itemModal.isOpened && id === elementId
      ? this.renderDropdown()
      : null;
  }
}

const mapToStateProps = (state: StoreState): StateProps => ({
  id: getMenuId(state),
  clipboard: getClipboard(state),
  inProgress: contextInProgress(state),
  errors: getRosterErrorModal(state).errors,
  canEditRosteredShift: hasPermissionSelector(state, 'roster.rosteredshift.edit'),
  canEditTimesheet: hasPermissionSelector(state, 'roster.timesheet.edit'),
  modalIsOpened: getRosteredShiftModal(state).isOpened,
  itemModal: getEditModal(state),
  isBulkMode: getIsBulkDeleteOpened(state),
  isApproveMode: getIsApprovalModeEnabled(state)
});

const mapToDispatchProps: DispatchProps = {
  open: BOX_ROSTER_CONTEXTUAL_MENU_OPEN,
  close: BOX_ROSTER_CONTEXTUAL_MENU_CLOSE,
  copyRosteredShift: BOX_ROSTER_CONTEXTUAL_MENU_COPY_ROSTERED_SHIFT,
  copyShiftItem: BOX_ROSTER_CONTEXTUAL_MENU_COPY_SHIFT_ITEM,
  cutRosteredShift: BOX_ROSTER_CONTEXTUAL_MENU_CUT_ROSTERED_SHIFT,
  cutShiftItem: BOX_ROSTER_CONTEXTUAL_MENU_CUT_SHIFT_ITEM,
  pasteRosteredShift: BOX_ROSTER_CONTEXTUAL_MENU_PASTE_ROSTERED_SHIFT,
  pasteShiftItem: BOX_ROSTER_CONTEXTUAL_MENU_PASTE_SHIFT_ITEM,
  openRosteredShiftModal: BOX_ROSTER_SHIFT_MODAL_GET_BY_ID,
  openShiftItemModal: BOX_EDIT_TEMPLATE_OPEN_EDIT_MODAL,
  openTimesheetModal: BOX_TIMESHEET_SHIFT_MODAL_GET_BY_ID,
  pasteRosteredShiftAndOpenModal: BOX_ROSTER_CONTEXTUAL_MENU_PASTE_AND_OPEN,
  pasteItemAndOpenModal: BOX_ROSTER_CONTEXTUAL_MENU_PASTE_ITEM_AND_OPEN,
  deleteShift: BOX_TIMESHEET_SHIFT_MODAL_DELETE_SHIFT_CONTEXT,
  replaceShiftDataInModal: BOX_ROSTER_CONTEXTUAL_MENU_REPLACE_IN_MODAL,
  replaceItemDataInModal: BOX_ROSTER_CONTEXTUAL_MENU_REPLACE_ITEM_IN_MODAL
};

export default connect(
  mapToStateProps,
  mapToDispatchProps
)(ContextualMenu);
