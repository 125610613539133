import { createAction } from 'lib/store-utils';
import { DeleteOrApproveQueuePayload, IdsOfProposal } from './types';

export const BOX_SHIFT_OFFERS_UNDO_ADD_TO_DELETE_QUEUE = createAction<DeleteOrApproveQueuePayload>('Shift offers undo / Add shift offer to delete queue');
export const BOX_SHIFT_OFFERS_UNDO_REMOVE_FROM_DELETE_QUEUE = createAction<DeleteOrApproveQueuePayload>('Shift offers undo / Remove shift offer from delete queue');

export const BOX_SHIFT_OFFERS_UNDO_ADD_TO_DECLINE_PROPOSAL_QUEUE = createAction<IdsOfProposal>('Shift offers undo / Add shift offer to decline proposal queue');
export const BOX_SHIFT_OFFERS_UNDO_REMOVE_FROM_DECLINE_PROPOSAL_QUEUE = createAction<IdsOfProposal>('Shift offers undo / Remove shift offer from decline proposal queue');

export const BOX_SHIFT_OFFERS_UNDO_ADD_TO_APPROVE_PROPOSAL_QUEUE = createAction<DeleteOrApproveQueuePayload>('Shift offers undo / Add shift offer to approve proposal queue');
export const BOX_SHIFT_OFFERS_UNDO_REMOVE_FROM_APPROVE_PROPOSAL_QUEUE = createAction<DeleteOrApproveQueuePayload>('Shift offers undo / Remove shift offer from approve proposal queue');
