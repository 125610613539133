import { createReducer } from 'lib/store-utils';
import { RosterTimeOffsReducerState } from './types';
import { defaultState } from './state';
import * as actions from './actions';
import { omit } from 'lodash';

export const timeOffs = createReducer<RosterTimeOffsReducerState>(
  {},
  defaultState
);

timeOffs.on(
  actions.BOX_TIME_OFFS_GET_LIST_SUCCESS,
  (state, newState): RosterTimeOffsReducerState => newState
);

timeOffs.on(
  actions.BOX_TIME_OFFS_UPDATE_ONE,
  (state, timeOff): RosterTimeOffsReducerState => ({
    ...state,
    timeOffs: {
      ...state.timeOffs,
      [timeOff.id]: timeOff
    }
  })
);

timeOffs.on(
  actions.BOX_TIME_OFFS_UPDATE_MULTIPLE,
  (state, timeoffs): RosterTimeOffsReducerState => ({
    ...state,
    timeOffs: {
      ...state.timeOffs,
      ...timeoffs
    }
  })
);

timeOffs.on(
  actions.BOX_TIME_OFFS_DELETE_ONE,
  (state, { id }): RosterTimeOffsReducerState => ({
    ...state,
    timeOffs: omit(state.timeOffs, id)
  })
);

timeOffs.on(
  actions.BOX_TIME_OFFS_DELETE_MULTIPLE,
  (state, ids): RosterTimeOffsReducerState => ({
    ...state,
    timeOffs: omit(state.timeOffs, ids)
  })
);

timeOffs.on(
  actions.BOX_TIME_OFFS_UPDATE_LEAVE_NOTES,
  (state, payload): RosterTimeOffsReducerState => ({
    ...state,
    isUpdating: true,
    errors: []
  })
);

timeOffs.on(
  actions.BOX_TIME_OFFS_UPDATE_LEAVE_NOTES_SUCCESS,
  (state, response): RosterTimeOffsReducerState => ({
    ...state,
    isUpdating: false,
    timeOffs: {
      ...state.timeOffs,
      [response.id]: response
    }
  })
);

timeOffs.on(
  actions.BOX_TIME_OFFS_UPDATE_LEAVE_NOTES_FAILURE,
  (state, errors): RosterTimeOffsReducerState => ({
    ...state,
    isUpdating: false,
    errors
  })
);
