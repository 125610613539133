import moment, { Moment } from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { getDateFormat, timeFormatSelector } from 'state/Account';
import { ISO8601DateTime } from 'type';

type Props = {
  children: Moment | ISO8601DateTime;
  showTime?: boolean;
  showLocalTime?: boolean;
};

export default FormattedDate;

export function FormattedDate({
  showTime = false,
  showLocalTime = false,
  children,
}: Props) {
  const dateFormat = useSelector(getDateFormat);
  const timeFormat = useSelector(timeFormatSelector);

  const format = showTime ? `${dateFormat}, ${timeFormat}` : dateFormat;
  const parser = showLocalTime ? moment : moment.parseZone;

  return <>{parser(children).format(format)}</>;
}
