import React, { Component } from 'react';
import { getClass } from '../_lib/helper';
import { CheckButtonProps } from './type';
import './CheckButton.scss';

type CheckButtonState = {
  isChecked: boolean;
};

export default class CheckButton extends Component<
  CheckButtonProps,
  CheckButtonState
> {
  static defaultProps = {
    isChecked: false,
    tabIndex: 0,
  };

  state = {
    isChecked: false,
  };

  componentDidMount() {
    const { isChecked } = this.props;
    this.setState({
      isChecked,
    });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const { isChecked } = this.props;
    if (isChecked !== prevProps.isChecked) {
      this.setState({
        isChecked,
      });
    }
  }

  handleToggle = () => {
    const { isDisabled } = this.props;
    if (isDisabled) {
      return;
    }

    this.updateChecked(!this.state.isChecked);
  };

  handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if ( e.key === ' ' ) {
      this.handleToggle();
    }
  }

  updateChecked = (isChecked: boolean) => {
    const { onStatusChange, id, className } = this.props;
    this.setState({
      isChecked,
    });

    if (onStatusChange) {
      const data = { isChecked };
      if (id) {
        Object.assign(data, { id });
      }

      if (className) {
        Object.assign(data, { className });
      }

      onStatusChange(data);
    }
  };

  render() {
    const { id, className, isDisabled, tabIndex, children } = this.props;
    const { isChecked } = this.state;

    return (
      <div
        onClick={this.handleToggle}
        className={getClass('elmo-checkbutton', className, {
          disabled: isDisabled,
          checked: isChecked,
        })}
        data-testid={`elmo-checkbutton-${id || 'default'}`}
        tabIndex={tabIndex}
        role="checkbox"
        aria-checked={isChecked}
        aria-disabled={isDisabled}
        onKeyPress={this.handleKeyPress}
      >
        <span>{children}</span>
      </div>
    );
  }
}
