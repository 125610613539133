import React from 'react';
import { DataTableElement } from '../type';
import { getClass } from '../../_lib/helper';
import { TextAlignProperty } from 'csstype';

type DataTableThProps = DataTableElement & {
  /** Colspan of the cell */
  colSpan?: number;
  /** Width of the cell. % or px */
  width?: string;
  /** Text alignment "center" | "end" | "justify" | "left" | "match-parent" | "right" | "start" */
  textAlign?: TextAlignProperty;
};

/**
 * Header cell component.
 * @param children
 * @param className
 * @param colSpan
 * @param width
 * @constructor
 */
function DataTableTh({
  children,
  className,
  colSpan,
  width,
  textAlign,
}: DataTableThProps) {
  let style: any = {};

  if (width) {
    style['width'] = width;
  }

  if (textAlign) {
    style['textAlign'] = textAlign;
  }

  return (
    <th
      className={getClass('elmo-datatable__th', className)}
      colSpan={colSpan}
      style={style}
    >
      {children}
    </th>
  );
}

export default DataTableTh;
