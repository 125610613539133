import { createAction } from 'lib/store-utils';
import {
  EmployeeRosteredShift,
  FormattedErrors,
  PunchClockTimesheetActive,
  UpdateFormPayload,
} from 'type';
import {
  EmployeeDashboardGetMyRostersListResponse,
  WhoElseWorkingRequest,
} from 'lib/Api';
import {
  ActiveTimesheetFetchedPayload,
  ClockOffPayload,
  ClockOnRequest,
  CreateTradePayload,
  CreateTradeSuccessPayload,
  GetPageDataSuccess,
  ShowConfirmationModalPayload,
  ToggleFilterSuccessPayload,
  TogglePeriodRequestPayload,
  TradeForceSuccessPayload,
  TradeModalPayload,
  WhoElseWorkingItem,
} from './types';

export const BOX_MY_ROSTERS_DATA_REQUEST = createAction(
  'My Rosters/ get data request'
);

export const BOX_MY_ROSTERS_ACTIVE_TIMESHEET_FETCHED =
  createAction<ActiveTimesheetFetchedPayload>(
    'My Rosters / Active timesheet fetched'
  );
export const BOX_MY_ROSTERS_SHIFTS_FETCHED = createAction<GetPageDataSuccess>(
  'My Rosters / shifts fetched'
);
export const BOX_MY_ROSTERS_ERRORS_CLEARED = createAction(
  'My Rosters / errors cleared'
);
export const BOX_MY_ROSTERS_FORM_FILTER_UPDATED =
  createAction<UpdateFormPayload>('My Rosters / form filter updated');
export const BOX_MY_ROSTERS_FORM_FILTERS_RESET = createAction(
  'My Rosters / form filters reset'
);
export const BOX_MY_ROSTERS_TRADE_MODAL_OPENED =
  createAction<TradeModalPayload>('My rosters / Trade modal opened');
export const BOX_MY_ROSTERS_TRADE_MODAL_CLOSED = createAction(
  'My rosters / Trade modal closed'
);
export const BOX_MY_ROSTERS_TRADE_MODAL_ERRORS_CLEARED = createAction(
  'My Rosters / Trade modal / Errors cleared'
);
export const BOX_MY_ROSTERS_CONFIRMATION_MODAL_OPENED =
  createAction<ShowConfirmationModalPayload>(
    'My Rosters / Confirmation modal opened'
  );
export const BOX_MY_ROSTERS_CONFIRMATION_MODAL_CLOSED = createAction(
  'My Rosters / Confirmation modal closed'
);
export const BOX_MY_ROSTERS_BREAKS_LIMIT_CONFIRMATION_MODAL_CLOSED =
  createAction('My Rosters / Breaks limit confirmation modal closed');
export const BOX_MY_ROSTERS_TRADE_NOTE_SET = createAction<string>(
  'My Rosters / Trade note set'
);
export const BOX_MY_ROSTERS_PAGE_LEFT = createAction('My rosters / page left');

export const BOX_MY_ROSTERS_RE_FETCH_DATA_REQUEST = createAction(
  'My Rosters / re-fetch data request'
);
export const BOX_MY_ROSTERS_RE_FETCH_DATA_SUCCESS = createAction(
  'My Rosters / re-fetch data success'
);
export const BOX_MY_ROSTERS_RE_FETCH_DATA_FAILURE =
  createAction<FormattedErrors>('My Rosters / re-fetch data failure');

export const BOX_MY_ROSTERS_TOGGLE_PERIOD_TYPE_REQUEST =
  createAction<TogglePeriodRequestPayload>(
    'My Rosters / Change period request'
  );
export const BOX_MY_ROSTERS_TOGGLE_PERIOD_TYPE_SUCCESS =
  createAction<ToggleFilterSuccessPayload>(
    'My Rosters / Change period success'
  );
export const BOX_MY_ROSTERS_TOGGLE_PERIOD_TYPE_FAILURE =
  createAction<FormattedErrors>('My Rosters / Change period failed');

export const BOX_MY_ROSTERS_CLOCK_ON_REQUEST = createAction<ClockOnRequest>(
  'My Rosters / Clock on request'
);
export const BOX_MY_ROSTERS_CLOCK_ON_SUCCESS =
  createAction<PunchClockTimesheetActive>('My Rosters / Clock on success');
export const BOX_MY_ROSTERS_CLOCK_ON_FAILURE = createAction<FormattedErrors>(
  'My Rosters / Clock on failed'
);

export const BOX_MY_ROSTERS_CLOCK_OFF_REQUEST = createAction<ClockOffPayload>(
  'My Rosters / Clock off request'
);
export const BOX_MY_ROSTERS_CLOCK_OFF_SUCCESS = createAction<string>(
  'My Rosters / Clock off success'
);
export const BOX_MY_ROSTERS_CLOCK_OFF_FAILURE = createAction<FormattedErrors>(
  'My Rosters / Clock off failed'
);

export const BOX_MY_ROSTERS_START_BREAK_REQUEST = createAction<any>(
  'My Rosters / Start break request'
);
export const BOX_MY_ROSTERS_START_BREAK_SUCCESS = createAction<string>(
  'My Rosters / Start break success'
);
export const BOX_MY_ROSTERS_START_BREAK_FAILURE = createAction<FormattedErrors>(
  'My Rosters / Start break failed'
);

export const BOX_MY_ROSTERS_STOP_BREAK_REQUEST = createAction<string>(
  'My Rosters / Stop break request'
);
export const BOX_MY_ROSTERS_STOP_BREAK_SUCCESS = createAction<string>(
  'My Rosters / Stop break success'
);
export const BOX_MY_ROSTERS_STOP_BREAK_FAILURE = createAction<FormattedErrors>(
  'My Rosters / Stop break failed'
);

export const BOX_MY_ROSTERS_APPLY_FORM_FILTERS_REQUEST = createAction(
  'My Rosters / Apply filters request'
);
export const BOX_MY_ROSTERS_APPLY_FORM_FILTERS_SUCCESS = createAction(
  'My Rosters / Apply filters success'
);
export const BOX_MY_ROSTERS_APPLY_FORM_FILTERS_FAILURE =
  createAction<FormattedErrors>('My Rosters / Apply filters failure');

export const BOX_MY_ROSTERS_CHANGE_PAGE_REQUEST = createAction<number>(
  'My Rosters / Change page request'
);
export const BOX_MY_ROSTERS_CHANGE_PAGE_SUCCESS =
  createAction<EmployeeDashboardGetMyRostersListResponse>(
    'My Rosters / Change page success'
  );
export const BOX_MY_ROSTERS_CHANGE_PAGE_FAILURE = createAction<FormattedErrors>(
  'My Rosters / Change page failed'
);

export const BOX_MY_ROSTERS_CHANGE_PAGE_SIZE_REQUEST = createAction<number>(
  'My Rosters / Change page size request'
);
export const BOX_MY_ROSTERS_CHANGE_PAGE_SIZE_SUCCESS =
  createAction<EmployeeDashboardGetMyRostersListResponse>(
    'Events / Change page size success'
  );
export const BOX_MY_ROSTERS_CHANGE_PAGE_SIZE_FAILURE =
  createAction<FormattedErrors>('My Rosters / Change page size failed');

export const BOX_MY_ROSTERS_TRADE_REQUEST = createAction<CreateTradePayload>(
  'My Rosters / Trade request'
);
export const BOX_MY_ROSTERS_TRADE_SUCCESS =
  createAction<CreateTradeSuccessPayload>('My Rosters / Trade success');
export const BOX_MY_ROSTERS_TRADE_FAILURE = createAction<FormattedErrors>(
  'My Rosters / Trade failed'
);

export const BOX_MY_ROSTERS_TRADE_FORCE_REQUEST = createAction(
  'My Rosters / trade force request'
);
export const BOX_MY_ROSTERS_TRADE_FORCE_SUCCESS =
  createAction<TradeForceSuccessPayload>('My Rosters / trade force success');
export const BOX_MY_ROSTERS_TRADE_FORCE_FAILURE = createAction<FormattedErrors>(
  'My Rosters / trade force failure'
);

export const BOX_MY_ROSTERS_DECLINE_SHIFT_REQUEST = createAction<string>(
  'My Rosters / Decline shift request'
);
export const BOX_MY_ROSTERS_DECLINE_SHIFT_SUCCESS = createAction<string>(
  'My Rosters / Decline shift success'
);
export const BOX_MY_ROSTERS_DECLINE_SHIFT_FAILURE =
  createAction<FormattedErrors>('My Rosters / Decline shift failed');

export const BOX_MY_ROSTERS_WHO_ELSE_WORKING_REQUEST =
  createAction<WhoElseWorkingRequest>('My Rosters / Who else working request');
export const BOX_MY_ROSTERS_WHO_ELSE_WORKING_SUCCESS = createAction<
  WhoElseWorkingItem[]
>('My Rosters / Who else working success');
export const BOX_MY_ROSTERS_WHO_ELSE_WORKING_FAILURE =
  createAction<FormattedErrors>('My Rosters / Who else working failed');

// disabled functionality
export const BOX_MY_ROSTERS_ACCEPT_SHIFT_REQUEST = createAction<string>(
  'My Rosters / Accept shift request'
);
export const BOX_MY_ROSTERS_ACCEPT_SHIFT_SUCCESS =
  createAction<EmployeeRosteredShift>('My Rosters / Accept shift success');
export const BOX_MY_ROSTERS_ACCEPT_SHIFT_FAILURE =
  createAction<FormattedErrors>('My Rosters / Accept shift failed');
