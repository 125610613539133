import { createSelector } from 'reselect';
import { getRosteredShifts } from 'state/RosteredShifts';
import { userListSelector } from 'state/UsersCollection';
import {
  DraggableShiftRoleSelector,
} from '../../types';
import { getDragAndDropShiftId } from '../simpleSelectors';

export const draggableShiftAreaIdRoleIdSelector = createSelector(
  getDragAndDropShiftId,
  getRosteredShifts,
  (shiftId, rosteredShifts): DraggableShiftRoleSelector => {
    const draggableShift = rosteredShifts[shiftId];

    if (draggableShift) {
      const { area_id, role_id } = draggableShift;
      return { area_id, role_id };
    }

    return { area_id: '', role_id: '' };
  }
);

export const isSiteRowDroppableSelectorCreator = (rowData: {
  roleId: string;
  areaId: string;
}) =>
  createSelector(
    getDragAndDropShiftId,
    getRosteredShifts,
    userListSelector,
    (shiftId, rosteredShifts, userList): boolean => {
      const draggableShift = rosteredShifts[shiftId];
      if (!draggableShift) {
        return false;
      }

      const { user_id } = draggableShift;

      if (!user_id) {
        return true;
      }

      const user = userList[user_id];

      if (!user) {
        return false;
      }

      return user.user_roles.some(
        userRole =>
          userRole.area_id === rowData.areaId &&
          userRole.role_id === rowData.roleId
      );
    }
  );
