import React, { ReactNode } from 'react';

type PopoverContentProps = {
  /** The content of this popover */
  children: ReactNode;
  /** Id of the component */
  id?: string;
  /** Class name for styling */
  className?: string;
  /** role is for WCAG accessibility compliance, defaults to "tooltip" if omitted, (optional) */
  role?: string;
  /** ariaRoledescription is for WCAG accessibility compliance, defaults to "popover" if omitted, (optional) */
  ariaRoledescription?: string;
  /** tabIndex is for WCAG accessibility compliance (optional).
   * This prop was added primarily for use within the Dropdown component, if you don’t want the screen reader
   * to sound "dropdown" `ariaRoledescription` then use the value "-1", so unless you have a special reason to use it,
   * you can ignore it.
   */
  tabIndex?: number;
};

const PopoverContentDefaultProps = {
  role: 'tooltip',
};

function PopoverContent({
  children,
  id,
  className,
  role,
  ariaRoledescription,
  tabIndex,
}: PopoverContentProps) {
  return (
    <div
      id={id}
      className={className}
      role={id ? role : undefined}
      aria-roledescription={ariaRoledescription}
      tabIndex={tabIndex}
    >
      {children}
    </div>
  );
}

PopoverContent.displayName = 'PopoverContent';
PopoverContent.defaultProps = PopoverContentDefaultProps;

export default PopoverContent;
