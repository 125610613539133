import { PrintRostersReducerState } from './types';

export const getDefaultState = (): PrintRostersReducerState => ({
  isPrinting: false,
  isModalOpened: false,
  options: {
    assigned: true,
    unassigned: true,
    unpublished: true,
    events: true,
  },
});
