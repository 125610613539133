import React, { memo } from 'react';
import { RosterCell } from '../../../../../../../../../../components';

export const UnavailabilityCell = memo(function UnavailabilityCell() {
  return (
    <RosterCell
      label={''}
      labelOnHover={true}
      fontWeight={'normal'}
    />
  );
});