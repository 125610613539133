import React, { Component } from 'react';
import { connect } from 'react-redux';
import ErrorBox from 'element/ErrorBox';
import { StoreState } from 'state/types';
import { FormattedErrors, RosteredShift } from 'type/models';
import { StringMap } from 'type';
import {
  BOX_ROSTER_BULK_DELETION_MODAL_CLEAR_ERRORS,
  BOX_ROSTER_BULK_DELETION_MODAL_CLOSE,
  BOX_ROSTER_BULK_DELETION_SEND_SHIFTS_REQUEST,
  BOX_ROSTER_BULK_DELETION_SEND_TEMPLATED_SHIFTS_REQUEST,
} from 'state/Roster/BulkActions/actions';
import {
  BulkDeletePayload,
  DeleteShiftItem,
} from 'state/Roster/BulkActions/types';
import {
  bulkDeleteIdsArraySelector,
  getBulkDeleteRosteredShiftsIdsArray,
} from 'state/Roster/BulkActions/selectors';
import { replaceErrorText } from 'lib/helpers';
import { getRosteredShifts } from 'state/RosteredShiftsCollection';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';
import { PageDialog, PageDialogCancel, PageDialogSubmit } from 'element';
import { Typography } from 'oxygen-elements';

type OwnProps = {
  editTemplate?: boolean;
};

type DispatchProps = {
  closeModal: () => void;
  confirmDeletion: (params: BulkDeletePayload) => void;
  confirmItemsDeletion: (params: BulkDeletePayload) => void;
  clearErrors: () => void;
};

type StateProps = {
  isOpened: boolean;
  isConfirmation: boolean;
  selectedShifts: DeleteShiftItem[];
  isDeleting: boolean;
  errors: FormattedErrors;
  shifts: StringMap<RosteredShift>;
  allSelectedIds: string[];
  rosteredShiftsIds: string[];
  title: string;
};

type Props = OwnProps & DispatchProps & StateProps;

type State = {
  msg: string;
  btnText: string;
  title: string;
};

export class ConfirmBulkDeletionModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getDefaultContent();
  }

  render() {
    const {
      errors,
      isDeleting,
      isOpened,
      clearErrors,
      closeModal,
      isConfirmation,
      editTemplate,
    } = this.props;
    return (
      <PageDialog
        maxWidth={'xs'}
        open={isOpened}
        id={'confirm-bulk-delete-modal'}
        onClose={closeModal}
      >
        <DialogTitle>{this.state.title}</DialogTitle>
        <DialogContent>
          {errors.length > 0 && !isConfirmation && !isDeleting && (
            <ErrorBox errors={errors} clearErrors={clearErrors} />
          )}
          {!isConfirmation && this.state.msg}
          {!isConfirmation && !editTemplate && (
            <Typography variant={'body2'} sx={{ paddingTop: '10px' }}>
              Once shift is deleted using bulk delete option, user will not be
              able to Undo this action (i.e deletion will be permanent and
              published automatically)
            </Typography>
          )}
          {isConfirmation && this.showConfirmationMsg()}
        </DialogContent>
        <DialogActions>
          <PageDialogCancel onClick={closeModal}>Cancel</PageDialogCancel>
          <PageDialogSubmit
            onClick={this.confirmDeletion}
            loading={isDeleting}
            disabled={isDeleting}
          >
            {this.state.btnText}
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  }

  confirmDeletion = () => {
    const { isConfirmation, editTemplate } = this.props;
    editTemplate
      ? this.props.confirmItemsDeletion({
          ids: this.props.rosteredShiftsIds,
          ignore_errors: isConfirmation,
        })
      : this.props.confirmDeletion({
          ids: this.props.rosteredShiftsIds,
          ignore_errors: isConfirmation,
        });
  };

  getDefaultContent = () => {
    const { selectedShifts } = this.props;
    const shiftsText = selectedShifts.length > 1 ? 'shifts' : 'shift';
    return {
      msg: `You are about to delete ${selectedShifts.length} ${shiftsText}. 
        Once deleted, ${shiftsText} will be permanently removed.`,
      btnText: 'Delete',
      title: `Delete ${shiftsText}?`,
    };
  };

  getConfirmationTexts = () => {
    const { title } = this.props;
    return {
      title: title.length ? title : 'Do you want to approve this?',
      btnText: 'Yes, approve it',
    };
  };

  setModalContent = (isConfirm = false) => {
    this.setState({
      ...this.state,
      ...(isConfirm ? this.getConfirmationTexts() : this.getDefaultContent()),
    });
  };

  showConfirmationMsg = () => {
    return replaceErrorText(this.props.errors).map((s: string, i: number) => (
      <Typography variant={'body1'} key={i} sx={{ padding: '10px 0' }}>
        {s}
      </Typography>
    ));
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (
      prevProps.isConfirmation !== this.props.isConfirmation &&
      this.props.isConfirmation
    ) {
      this.setModalContent(true);
    }
    if (prevProps.isOpened !== this.props.isOpened && this.props.isOpened) {
      this.setModalContent();
    }
  }
}

const mapToStateProps = (state: StoreState): StateProps => ({
  isOpened: state.rosterBulkActions.bulkDelete.confirmDeletionModal.isOpened,
  isConfirmation:
    state.rosterBulkActions.bulkDelete.confirmDeletionModal.isConfirmation,
  selectedShifts: state.rosterBulkActions.bulkDelete.shifts,
  isDeleting:
    state.rosterBulkActions.bulkDelete.confirmDeletionModal.isDeleting,
  errors: state.rosterBulkActions.bulkDelete.confirmDeletionModal.errors,
  title: state.rosterBulkActions.bulkDelete.confirmDeletionModal.title,
  shifts: getRosteredShifts(state),
  allSelectedIds: bulkDeleteIdsArraySelector(state),
  rosteredShiftsIds: getBulkDeleteRosteredShiftsIdsArray(state),
});

const mapToDispatchProps: DispatchProps = {
  closeModal: BOX_ROSTER_BULK_DELETION_MODAL_CLOSE,
  confirmDeletion: BOX_ROSTER_BULK_DELETION_SEND_SHIFTS_REQUEST,
  confirmItemsDeletion: BOX_ROSTER_BULK_DELETION_SEND_TEMPLATED_SHIFTS_REQUEST,
  clearErrors: BOX_ROSTER_BULK_DELETION_MODAL_CLEAR_ERRORS,
};

export default connect(
  mapToStateProps,
  mapToDispatchProps
)(ConfirmBulkDeletionModal);
