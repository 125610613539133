import React from 'react';

type Props = {
  dayIndex: number;
};

export const CopyShift = ({ dayIndex }: Props)  => {
  return (
    <div className={'resizable'} data-day-num={dayIndex}>
      <div className={'resizer resizer-l'} />
      <div className={'resizer resizer-r'} />
    </div>
  );
};