import React, { Component, ReactText } from 'react';
import {
  Col,
  Divider,
  FormContainer,
  FormItem,
  Input,
  Radio,
  RadioButtons,
  Row,
} from 'elmo-elements';
import { TimeInput } from 'element';
import moment, { Moment } from 'moment';
import { GroupOptions } from 'element/shiftModals/components/GroupOptions';
import { PreferencesTimeFormat } from 'type/models';

type OwnProps = {
  onChangeDay: (day: ReactText) => void;
  onChangeTime: (value: Moment, name: string) => void;
  onFocusHandler: (target: string) => void;
  expanded: boolean;
  timeFormat: PreferencesTimeFormat;
  start: Moment;
  end: Moment;
  day: ReactText;
};

export type Props = OwnProps;

export class DayTimeGroupComponent extends Component<Props> {
  render() {
    return (
      <GroupOptions
        placeholder={this.getPlaceholder()}
        options={this.getOptions()}
        expanded={this.props.expanded}
        target={'breakGroup'}
      />
    );
  }

  getOptions = () => {
    const { start, end } = this.props;

    return (
      <div className={`rostered-shift-modal__hidden-options rows-container`}>
        <Divider />
        <br />
        <FormContainer maxColumns={1}>
          <FormItem label={'Day'}>
            <RadioButtons
              id="week-days"
              direction={'horizontal'}
              type={'solid'}
              selected={this.props.day}
              className={'mb-4'}
              onChange={this.props.onChangeDay}
            >
              <Radio value={1}>M</Radio>
              <Radio value={2}>T</Radio>
              <Radio value={3}>W</Radio>
              <Radio value={4}>T</Radio>
              <Radio value={5}>F</Radio>
              <Radio value={6}>S</Radio>
              <Radio value={7}>S</Radio>
            </RadioButtons>
          </FormItem>
          <FormItem>
            <Row>
              <Col span={12}>
                <FormItem label={'Start'}>
                  <TimeInput
                    id={'start'}
                    name={'start'}
                    value={start}
                    onChange={(e) => this.props.onChangeTime(e, 'start')}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label={'End'}>
                  <TimeInput
                    id={'end'}
                    name={'end'}
                    value={end}
                    onChange={(e) => this.props.onChangeTime(e, 'end')}
                  />
                </FormItem>
              </Col>
            </Row>
          </FormItem>
        </FormContainer>
      </div>
    );
  };

  getPlaceholder = () => {
    return (
      <FormContainer maxColumns={1}>
        <FormItem label={'Day and time'} className={'group-placeholder'}>
          <Input
            onChange={() => void 0}
            onFocus={this.handleFocus}
            value={this.placeholderValue}
            ariaLabel="Day and time"
            tabIndex={0}
            name={'day_and_time'}
          />
        </FormItem>
      </FormContainer>
    );
  };

  focusOnDatePicker = () => {
    setTimeout(() => {
      const input: HTMLInputElement | null =
        document.querySelector('[name="start"]');
      if (input) {
        input.focus();
      }
    }, 200);
  };

  handleFocus = () => {
    this.props.onFocusHandler('dateGroup');
    this.focusOnDatePicker();
  };

  get placeholderValue() {
    const { start, end, day, timeFormat } = this.props;
    const format = timeFormat === '12' ? 'hh:mm a' : 'HH:mm';
    const startTime = moment(start).format(format);
    const endTime = moment(end).format(format);
    return `Day number: ${day}, ${startTime} - ${endTime}`;
  }
}

export const DayTimeGroup = DayTimeGroupComponent;
