import { createReducer } from 'lib/store-utils';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import * as createTimesheetDialog from 'state/CreateTimesheetDialog';
import * as timesheetsWeeklyPage from 'state/TimesheetsWeeklyPage';
import { Dictionary } from 'ts-essentials';
import { RosterTimesheetListedWithPayEntries } from 'type';
import * as actions from './actions';
import { TimesheetResponseReducerState } from './types';

export const timesheetResponse = createReducer<TimesheetResponseReducerState>(
  {},
  {
    payload: {
      site_id: '',
      from: moment(),
      to: moment(),
    },
    withPayEntries: {},
  }
);

const handleTimesheetUpdate = (
  state: TimesheetResponseReducerState,
  timesheet: RosterTimesheetListedWithPayEntries
): TimesheetResponseReducerState => ({
  ...state,
  withPayEntries: {
    ...state.withPayEntries,
    [timesheet.id]: timesheet,
  },
});

const handleTimesheetDelete = (
  state: TimesheetResponseReducerState,
  { id }: { id: string }
): TimesheetResponseReducerState => {
  const { [id]: deletedTimesheet, ...withPayEntries } = state.withPayEntries;

  return {
    ...state,
    withPayEntries,
  };
};

const handleTimesheetsUpdate = (
  state: TimesheetResponseReducerState,
  {
    payload,
    timesheets,
  }: {
    payload: { site_id: string; from: Moment; to: Moment };
    timesheets: Dictionary<RosterTimesheetListedWithPayEntries>;
  }
): TimesheetResponseReducerState => ({
  payload,
  withPayEntries: timesheets,
});

timesheetResponse
  .on(actions.BOX_TIMESHEET_RESPONSE_TIMESHEET_CHANGED, handleTimesheetUpdate)
  .on(actions.BOX_TIMESHEET_RESPONSE_TIMESHEET_DELETED, handleTimesheetDelete)
  .on(
    timesheetsWeeklyPage.BOX_TIMESHEETS_WEEKLY_PAGE_DATA_GET.success,
    handleTimesheetsUpdate
  )
  .on(
    timesheetsWeeklyPage.BOX_TIMESHEETS_WEEKLY_PAGE_DATA_UPDATE.success,
    handleTimesheetsUpdate
  )
  .on(
    timesheetsWeeklyPage.BOX_TIMESHEETS_WEEKLY_TIMESHEET_DELETE.success,
    handleTimesheetDelete
  )
  .on(
    timesheetsWeeklyPage.BOX_TIMESHEETS_WEEKLY_TIMESHEET_APPROVE.success,
    handleTimesheetUpdate
  )
  .on(
    timesheetsWeeklyPage.BOX_TIMESHEETS_WEEKLY_TIMESHEET_UNAPPROVE.success,
    handleTimesheetUpdate
  )
  .on(
    timesheetsWeeklyPage.BOX_TIMESHEETS_WEEKLY_TIMESHEET_CREATE.success,
    handleTimesheetUpdate
  )
  .on(
    timesheetsWeeklyPage.BOX_TIMESHEETS_WEEKLY_TIMESHEET_STOP.success,
    (state, { id, timesheet }): TimesheetResponseReducerState =>
      !!timesheet
        ? handleTimesheetUpdate(state, timesheet)
        : handleTimesheetDelete(state, { id })
  )
  .on(
    timesheetsWeeklyPage.BOX_TIMESHEETS_WEEKLY_TIMESHEET_UPDATE.success,
    handleTimesheetUpdate
  )
  .on(
    timesheetsWeeklyPage.BOX_TIMESHEETS_WEEKLY_TIMESHEETS_DELETE.success,
    (state, { deletedIds }): TimesheetResponseReducerState => {
      return {
        ...state,
        withPayEntries: _.pickBy(
          state.withPayEntries,
          ({ id }) => !deletedIds.includes(id)
        ),
      };
    }
  )
  .on(
    timesheetsWeeklyPage.BOX_TIMESHEETS_WEEKLY_TIMESHEETS_APPROVE.success,
    (state, withPayEntries): TimesheetResponseReducerState => ({
      ...state,
      withPayEntries: {
        ...state.withPayEntries,
        ...withPayEntries,
      },
    })
  )
  .on(
    createTimesheetDialog.BOX_CREATE_TIMESHEET_DIALOG_SUBMIT.success,
    handleTimesheetUpdate
  );
