import React from 'react';
import clsx from 'clsx';
import { Button } from 'elmo-elements';
import { ButtonProps } from 'elmo-elements/Button/type';
import './style.scss';

type OwnProps = {
  isActive?: boolean;
  disableElevation?: boolean;
  bordered?: boolean;
};

type Props = ButtonProps & OwnProps;

export const SwitcherButton = ({
  isActive,
  disableElevation = false,
  bordered = false,
  ...restProps
}: Props) => (
  <Button
    {...restProps}
    className={clsx(restProps.className, 'rta-switcher-button', {
      active: isActive,
      disableElevation,
      bordered
    })}
  />
);
