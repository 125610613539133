import { TextField, TextFieldProps } from 'extended-oxygen-elements';
import {
  useClearableDurationField,
  UseClearableDurationFieldProps,
} from 'hooks';
import React from 'react';
import { Merge } from 'ts-essentials';

export type DurationFieldProps = Merge<
  TextFieldProps,
  UseClearableDurationFieldProps
>;

export const DurationField = React.forwardRef(function DurationField(
  props: DurationFieldProps,
  ref: React.Ref<HTMLDivElement>
) {
  const textFieldTimeProps = useClearableDurationField(props);

  return <TextField {...textFieldTimeProps} ref={ref} />;
});

export default DurationField;
