import React from 'react';
import { NativeTable, Pagination, Heading } from 'elmo-elements';
import { ColumnsProps } from '../../type';
import { getCurrencyFormatted, getDateTimeFormatted, getFormattedNumber } from 'lib/helpers';
import {
  getCurrencyCode,
  getCurrencyPlacement,
  getDateFormat, getNumberFormat, getTimeFormat
} from 'state/Account/selectors';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import { ReportPagination } from 'state/Report/state';
import {
  getCurrentPageRows,
  getGroups,
  getReportPagination,
  getReportTotals,
  getRows
} from 'state/Report/selectors';
import _ from 'lodash';
import ReportTableHeader from '../ReportTableHeader';
import {
  PreferencesCurrencyCode, PreferencesCurrencyPlacement,
  PreferencesDateFormat,
  PreferencesNumberFormat,
  PreferencesTimeFormat
} from 'type/models';

type CellData = any;

type Props = {
  rows: any[];
  pageRows: any[];
  visibleColumns: ColumnsProps[];
  groups: any[];
  pagination: ReportPagination;
  dateFormat: PreferencesDateFormat;
  timeFormat: PreferencesTimeFormat;
  numberFormat: PreferencesNumberFormat;
  currencyCode: PreferencesCurrencyCode;
  currencyPlacement: PreferencesCurrencyPlacement;
};

type OwnProps = {
  changePage: (page: number, start: number, end: number) => void;
  changePageSize: (size: number) => void;
  report: any;
  openModal: () => void;
};

export class CoverageGroupedByRows extends React.Component<Props & OwnProps, {}> {

  showCellData = (key: string, data: CellData) => {
    const outputString = (v: string) => v.length ? v : '-';
    const {numberFormat, currencyCode, currencyPlacement} = this.props;
    if ( this.isMoney(key) ) {
      return getCurrencyFormatted(numberFormat, currencyCode, currencyPlacement, data[key]);
    }
    if ( key === 'date' ) {
      return data[key];
    } else {
      return typeof data[key] === 'string' ? outputString(data[key]) : getFormattedNumber(numberFormat, +data[key]);
    }
  };

  render() {

    const {
      pageRows,
      visibleColumns,
      groups,
      report
    } = this.props;

    const filtered: any = _.groupBy(pageRows, row => row.group_id);

    return (
      <>
        <div className={'report-scroll-container'}>
        {
          Object.keys(filtered).map((k: any, i: number) => (
            <NativeTable key={`table-${k}-${i}`} isFullWidth={true} theme={'striped'}>
              <ReportTableHeader
                report={report}
                selectedColumns={visibleColumns}
                openModal={this.props.openModal}
                subHeading={groups[filtered[k][0].group_id].name}
              />
              <NativeTable.Body>
                {filtered[k].map((currentReport: any, trIndex: number) => (
                  <NativeTable.Tr key={`row-${trIndex}`}>
                    {visibleColumns.map((column: ColumnsProps, key: number) => (
                      <NativeTable.Td key={`${trIndex}-${key + 1}`}>
                        {
                          trIndex === 0
                            ? this.showCellData(column.name, currentReport)
                            : null
                        }
                        {
                          trIndex > 0 && column.name === 'date' && report.extra.group_by === 'date'
                            ? this.showCellData(column.name, currentReport)
                            : null
                        }
                        {
                          trIndex > 0 && column.name !== 'date'
                            ? this.showCellData(column.name, currentReport)
                            : null
                        }
                      </NativeTable.Td>
                    ))}
                  </NativeTable.Tr>
                ))
                }
              </NativeTable.Body>
            </NativeTable>
          ))
        }
        </div>
        <Pagination
          pageSize={this.props.pagination.pageSize}
          pageSizeOptions={this.props.pagination.pageSizeOptions}
          currentPage={this.props.pagination.currentPage}
          onPageChange={this.props.changePage}
          onPageSizeChange={this.props.changePageSize}
          totalResults={this.props.pagination.totalResults}
        />
      </>
    );
  }

  isMoney = (name: string) => {
    const { visibleColumns } = this.props;
    for ( let column of visibleColumns ) {
      if ( column.name === name && column.is_money ) {
        return true;
      }
    }
  }

}

const mapStateToProps = (state: StoreState) => ({
  pageRows: getCurrentPageRows(state),
  totals: getReportTotals(state),
  pagination: getReportPagination(state),
  groups: getGroups(state),
  rows: getRows(state),
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
  numberFormat: getNumberFormat(state),
  currencyCode: getCurrencyCode(state),
  currencyPlacement: getCurrencyPlacement(state)
});

export default connect(
  mapStateToProps
)(CoverageGroupedByRows);
