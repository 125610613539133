import { MyTimesheetReducerState } from './types';
import { PAGE_SIZE } from 'lib/config';
import { getPredefinedFilters } from './helpers';

export const getDefaultState = (): MyTimesheetReducerState => ({
  data: [],
  pager: {
    currentPage: 1,
    pageSize: PAGE_SIZE[0],
    total: 0,
  },
  totalDuration: 0,
  selectedFilter: 'lastSevenDays',
  filters: getPredefinedFilters('lastSevenDays'),
  filtersForm: getPredefinedFilters('lastSevenDays'),
  filtersModalIsOpened: false,
  errors: [],
  currentlyCreated: null,
});
