import React from 'react';
import Checkbox from '../../Checkbox';
import './ListTableHeaderWrapper.scss';
import ListTableHeader from './ListTableHeader';
import ListTableHeaderSelectAll from './ListTableHeaderSelectAll';
import { withListTableContext } from '../ListTableContextConsumer';

export type ListTableHeaderWrapperProps = {
  /** @Ignore From LayoutContext */
  isBulkActionOpen?: boolean;
  /** @Ignore From DataTableContext */
  selectAllIsChecked?: boolean;
  /** @Ignore From DataTableContext */
  selectAllIsIndeterminate?: boolean;
  /** @Ignore From DataTableContext */
  showSelectAllAvailableHeader?: boolean;
  /** @Ignore From DataTableContext */
  onSelectAllToggle?: (isSelect: boolean) => void;
  /** @Ignore From DataTableContext */
  isItemSelected?: (data: any) => boolean;
  /** @Ignore From DataTableContext */
  isItemDisabled?: (data: any) => boolean;
  /** @Ignore From DataTableContext */
  setContextState?: (state: any) => void;
  /** @Ignore From DataTableContext */
  onSelectAllAvailableToggle?: (isSelect: boolean) => void;
  /** Id of the ListTable */
  id?: string;
};

class ListTableHeaderWrapper extends React.Component<ListTableHeaderWrapperProps> {
  onSelectAllAvailable = (isSelect: boolean) => () => {
    const { setContextState, onSelectAllAvailableToggle } = this.props;

    if (!isSelect && setContextState) {
      setContextState({
        showSelectAllAvailableHeader: false,
        selectAllIsChecked: false,
      });
    }

    if (onSelectAllAvailableToggle) {
      onSelectAllAvailableToggle(isSelect);
    }
  };

  onSelectAllClick = () => {
    const { onSelectAllToggle, selectAllIsChecked, setContextState } =
      this.props;

    const nextSelectAllIsChecked = !selectAllIsChecked;

    if (setContextState) {
      setContextState({
        selectAllIsChecked: nextSelectAllIsChecked,
        selectAllIsIndeterminate: false,
        showSelectAllAvailableHeader: nextSelectAllIsChecked,
      });
    }

    if (onSelectAllToggle) {
      onSelectAllToggle(nextSelectAllIsChecked);
    }
  };

  render() {
    const {
      isBulkActionOpen,
      selectAllIsChecked,
      selectAllIsIndeterminate,
      showSelectAllAvailableHeader,
    } = this.props;

    const renderListTableHeaderWrapper = (
      <div className="elmo-listtable__header-wrapper" role="rowgroup">
        {isBulkActionOpen && (
          <div className="elmo-listtable__row-checkbox">
            <Checkbox
              id="selectAll"
              onChange={this.onSelectAllClick}
              isChecked={selectAllIsChecked}
              isIndeterminate={selectAllIsIndeterminate}
              ariaLabel="Select all"
            />
          </div>
        )}
        {!showSelectAllAvailableHeader && <ListTableHeader />}
        {showSelectAllAvailableHeader && (
          <ListTableHeaderSelectAll
            onSelectAllAvailable={this.onSelectAllAvailable}
          />
        )}
      </div>
    );
    return renderListTableHeaderWrapper;
  }
}

export { ListTableHeaderWrapper as ListTableHeaderWrapperNoContext };
export default withListTableContext(ListTableHeaderWrapper);
