import { createReducer } from 'lib/store-utils';
import { ReAcceptConfirmModalReducerState } from './types';
import * as actions from './actions';
import { getDefaultState } from './state';

export const reAcceptConfirmModal = createReducer<
  ReAcceptConfirmModalReducerState
>({}, getDefaultState());

reAcceptConfirmModal.on(
  actions.BOX_RE_ACCEPT_CONFIRM_MODAL_OPEN,
  (state): ReAcceptConfirmModalReducerState => ({
    ...state,
    isOpened: true
  })
);

reAcceptConfirmModal.on(
  actions.BOX_RE_ACCEPT_CONFIRM_MODAL_SUBMIT,
  (state): ReAcceptConfirmModalReducerState => ({
    ...state,
    isOpened: false
  })
);

reAcceptConfirmModal.on(
  actions.BOX_RE_ACCEPT_CONFIRM_MODAL_CANCEL,
  (state): ReAcceptConfirmModalReducerState => ({
    ...state,
    isOpened: false
  })
);
