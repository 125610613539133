import React from 'react';
import { connect } from 'react-redux';
import { FormattedErrors } from 'type';
import { ErrorBox } from 'element';
import { StoreState } from 'state/types';
import {
  BOX_MANAGER_DASHBOARD_CLEAR_ERRORS,
  getErrors
} from 'state/ManagerDashboard/ManagerDashboard';

type OwnProps = {};

type StateProps = {
  errors: FormattedErrors;
};

type DispatchProps = {
  clearErrors: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const EmployeeDashboardErrorsComponent = (props: Props) => (
  <ErrorBox {...props} />
);

const mapStateToProps = (state: StoreState): StateProps => ({
  errors: getErrors(state)
});

const mapDispatchToProps: DispatchProps = {
  clearErrors: BOX_MANAGER_DASHBOARD_CLEAR_ERRORS
};

export const EmployeeDashboardErrors = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeDashboardErrorsComponent);
