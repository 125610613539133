export function usePosition(props: {
  startTimeMins: number;
  endTimeMins: number;
  oneMinuteInPx: number;
}) {
  const startTimePx = props.oneMinuteInPx * props.startTimeMins;
  const endTimePx = props.oneMinuteInPx * props.endTimeMins;

  const width = endTimePx - startTimePx;

  return { left: startTimePx, right: endTimePx, width };
}
