import React from 'react';

export function useRefCallback<El extends HTMLElement>(
  onNewNodeRendered: (node: El) => void,
  onCleanup: (node: El) => void
) {
  const ref = React.useRef<El | null>(null);

  return React.useCallback((node: El | null) => {
    if (ref.current) {
      onCleanup(ref.current);
    }

    if (node) {
      onNewNodeRendered(node);
    }

    ref.current = node;
  }, []);
}

export default useRefCallback;
