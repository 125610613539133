import React from 'react';
import { LayoutContextProps, withLayoutAware } from 'elmo-elements';
import {
  FilterListOutlinedIcon,
  Header,
  withClosingBulkActionsAndSearchBeforeMount,
} from 'element';
import { StoreState } from 'state/types';
import { WithManagerDashboardMenu } from '../WithManagerDashboardMenu';
import { connect } from 'react-redux';
import {
  BOX_MANAGER_DASHBOARD_FILTERS_MODAL_OPEN,
  getSearch,
} from 'state/ManagerDashboard/ManagerDashboard';
import { WithNotificationsListButton } from 'element/NotificationsList/components/WithNotificationsListButton';
import { HeaderButtonProp } from 'element/Header/type';

type OwnProps = {
  noFiltersBtn?: boolean;
};

type StateProps = {
  searchQuery: string;
};

type DispatchProps = {
  updateQuery: (query: string) => void;
  openFilters: () => void;
};

type Props = OwnProps & StateProps & DispatchProps & LayoutContextProps;

const ManagerDashboardHeaderComponent = (props: Props) => {
  const headerBtns: Array<HeaderButtonProp> = [];
  if (typeof props.noFiltersBtn === 'undefined' || props.noFiltersBtn) {
    headerBtns.push({
      icon: <FilterListOutlinedIcon />,
      label: 'Filters',
      onClick: props.openFilters,
      tooltip: 'Filter',
    });
  }
  return (
    <WithManagerDashboardMenu>
      {(menu) => (
        <WithNotificationsListButton type={'manager'}>
          {({ notificationButton }) => (
            <Header
              title="Manager Dashboard"
              tabs={menu}
              buttons={[...headerBtns, notificationButton]}
              isSearchOpen={props.isSearchOpen}
            >
              {/*<Search {...props} />*/}
            </Header>
          )}
        </WithNotificationsListButton>
      )}
    </WithManagerDashboardMenu>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  searchQuery: getSearch(state).query,
});

const mapDispatchToProps: DispatchProps = {
  updateQuery: () => ({ type: '' }),
  openFilters: BOX_MANAGER_DASHBOARD_FILTERS_MODAL_OPEN,
};

export const ManagerDashboardHeader =
  withClosingBulkActionsAndSearchBeforeMount(
    withLayoutAware(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(ManagerDashboardHeaderComponent)
    )
  );
