import { createAction } from 'lib/store-utils';
import { FormattedErrors, Report, SortReports } from 'type';
import { ReportDeleteRequest, ReportScheduleRequest, ReportsGetAllResponse } from 'lib/Api/type';

export const BOX_REPORTS_GET_PAGE_DATA_REQUEST = createAction('Reports / Get reports related data');

export const BOX_REPORTS_GET_REPORTS_REQUEST = createAction('Reports / Get all reports');
export const BOX_REPORTS_GET_REPORTS_SUCCESS = createAction<ReportsGetAllResponse>('Reports / Get all reports success');
export const BOX_REPORTS_GET_REPORTS_FAILURE = createAction<FormattedErrors>('Reports / Get all reports failed');

export const BOX_REPORTS_CHANGE_PAGE = createAction<number>('Reports / Change page');
export const BOX_REPORTS_CHANGE_PAGE_SIZE = createAction<number>('Reports / Change page size');

export const BOX_REPORTS_SAVE_MODAL_OPEN = createAction<Report>('Reports / Open save as modal');
export const BOX_REPORTS_SAVE_MODAL_CLOSE = createAction('Reports / Close save as modal');

export const BOX_REPORTS_SAVE_REPORT_REQUEST = createAction<{ report: Report, hasRedirect: boolean }>('Reports / save report request');
export const BOX_REPORTS_SAVE_REPORT_SUCCESS = createAction<Report>('Reports / save report success');
export const BOX_REPORTS_SAVE_REPORT_FAILURE = createAction<FormattedErrors>('Reports / save report failure');

export const BOX_REPORTS_SCHEDULE_MODAL_OPEN = createAction<Report>('Reports / Open schedule modal');
export const BOX_REPORTS_SCHEDULE_MODAL_CLOSE = createAction('Reports / Close schedule modal');

export const BOX_REPORTS_SCHEDULE_UPDATE_REQUEST = createAction<ReportScheduleRequest>('Reports / Update schedule request');
export const BOX_REPORTS_SCHEDULE_UPDATE_SUCCESS = createAction<ReportScheduleRequest>('Reports / Update schedule success');
export const BOX_REPORTS_SCHEDULE_UPDATE_FAILURE = createAction<FormattedErrors>('Reports / Update schedule failure');

export const BOX_REPORTS_CREATE_MODAL_OPEN = createAction('Reports / Open create report modal');
export const BOX_REPORTS_CREATE_MODAL_CLOSE = createAction('Reports / Close create report modal');
export const BOX_REPORTS_CREATE_REDIRECT_MODAL_CLOSE = createAction('Reports / Close and redirect if report generated');

export const BOX_REPORTS_DELETE_REQUEST = createAction<ReportDeleteRequest>('Reports / Delete report request');
export const BOX_REPORTS_DELETE_SUCCESS = createAction<ReportDeleteRequest>('Reports / Delete report success');
export const BOX_REPORTS_DELETE_FAILURE = createAction<{errors: FormattedErrors, id: string}>('Reports / Delete report failure');

export const BOX_REPORT_CHANGE_ORDER = createAction<SortReports>('Reports / Change reports order');

export const BOX_REPORTS_SET_SEARCH_TERM = createAction<string>('Reports / Set search request');

export const BOX_REPORTS_ADD_REPORT_IN_DELETE_QUEUE = createAction<string>('Reports / Add report in queue for deleting');
export const BOX_RESTORE_DELETED_REPORT = createAction<string>('Reports / Restore report and remove it from queue for deleting');

export const BOX_REPORTS_CHANGE_SAVE_AS_TITLE = createAction<string>('Reports / Change title');

export const BOX_REPORTS_CLEAR_ERRORS = createAction('Reports / Clear errors');
