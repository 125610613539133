import React from 'react';
import moment, { Moment } from 'moment';
import {
  Button,
  Col,
  Divider,
  FormContainer,
  FormItem,
  Row,
} from 'elmo-elements';
import {
  AccountTreeSite,
  Event,
  PreferencesDateFormat,
  PreferencesTimeFormat,
  StringMap,
} from 'type';
import { DateInputMoment, DSTIcon, TimeInput } from 'element';
import { disabledFutureDate, disabledPastDate } from './helpers';

type StartEndPeriod = {
  start: Moment;
  end: Moment;
};

type PartialEvent = Pick<
  Event,
  'start' | 'end' | 'is_dst_intersect' | 'site_id'
>;

type Props = {
  onChangePeriod: (payload: StartEndPeriod) => void;
  className?: string;
  params: PartialEvent;
  disableFutureDates?: boolean;
  dateFormat: PreferencesDateFormat;
  timeFormat: PreferencesTimeFormat;
  sites: StringMap<AccountTreeSite>;
};

const EventPeriod = (props: Props) => {
  const setTodayInSelectedTimezone = (date: Moment, tz: string) => {
    return moment.tz(tz).set({
      hours: +date.format('HH'),
      minutes: +date.format('mm'),
    });
  };

  const changePeriod = (newPeriod: StartEndPeriod) => {
    if (!start || !end) {
      return false;
    }
    props.onChangePeriod(validateNewPeriod(newPeriod));
  };

  const validateNewPeriod = (newPeriod: StartEndPeriod): StartEndPeriod => {
    const { start, end } = newPeriod;

    // It's necessary to use the same offset to compare two dates correctly
    if (
      start
        .clone()
        .utcOffset(0, true)
        .isSameOrAfter(end.clone().utcOffset(0, true))
    ) {
      return {
        start,
        end: start.clone().startOf('day').add(1, 'day'),
      };
    }

    return newPeriod;
  };

  // update new value helpers
  const mergeDateAndTime = (date: Moment, time: Moment): Moment => {
    const hours = time.hours();
    const minutes = time.minutes();

    return date.clone().startOf('day').set({
      hours,
      minutes,
    });
  };

  // change date event handlers
  const changeDate = (selectedDate: Moment, name: string) => {
    const { params } = props;
    const { start, end } = params;

    const initialDateTime =
      params[name as keyof Pick<PartialEvent, 'start' | 'end'>];
    const updatedDateTime = mergeDateAndTime(selectedDate, initialDateTime);

    changePeriod({
      start,
      end,
      [name]: updatedDateTime,
    });
  };

  const onChangeStartDate = (selectedDate: Moment, name: string) => {
    keepPeriod(selectedDate, name);
  };

  const onChangeDate = (...args: [Moment, string]) => {
    changeDate(...args);
  };

  const clickOnToday = () => {
    const { start, end, site_id } = props.params;
    const { sites } = props;
    const timezone = sites[site_id].timezone_id;
    changePeriod({
      start: setTodayInSelectedTimezone(start, timezone),
      end: setTodayInSelectedTimezone(end, timezone),
    });
  };

  // event handlers
  const onAllDayClick = () => {
    let { start } = props.params;
    start = moment(start).startOf('D');

    const end = moment(start).endOf('D');

    changePeriod({ start, end });
  };

  const keepPeriod = (selectedDate: Moment, name: string) => {
    const { params } = props;
    const { start, end } = params;
    const currentDiff = end.clone().diff(start, 'minutes');

    const initialDateTime =
      params[name as keyof Pick<PartialEvent, 'start' | 'end'>];
    const updatedDateTime = mergeDateAndTime(selectedDate, initialDateTime);

    changePeriod({
      start,
      end: updatedDateTime.clone().add(currentDiff, 'minutes'),
      [name]: updatedDateTime,
    });
  };

  const onStartTimeChange = (updatedTime: Moment) => {
    changeStartTime(updatedTime);
  };

  const onEndTimeChange = (updatedTime: Moment) => {
    changeEndTime(updatedTime);
  };

  // change start time event handlers
  const changeStartTime = (updatedStartTime: Moment) => {
    const { start, end } = props.params;
    const updatedStart = mergeDateAndTime(start, updatedStartTime);
    changePeriod({
      start: updatedStart,
      end,
    });
  };

  // change end time event handlers
  const changeEndTime = (updatedEndTime: Moment) => {
    const { start, end } = props.params;

    const updatedEnd = mergeDateAndTime(end, updatedEndTime);
    changePeriod({
      start,
      end: updatedEnd,
    });
  };

  const { start, end, is_dst_intersect } = props.params;

  return (
    <div className={`rostered-shift-modal__hidden-options rows-container`}>
      <Divider />
      <br />
      <div className="shift-modal-inner spacing-bottom-large">
        <Row role="presentation">
          <Col isAuto={true} span={12} role="presentation" className={'pl-4'}>
            <FormItem
              label={'Start date'}
              labelAddon={<DSTIcon isDST={is_dst_intersect} />}
            >
              <DateInputMoment
                id="event-edit-form-input-start"
                onChange={onChangeStartDate}
                name={'start'}
                value={start}
                disabledDate={(current) => disabledFutureDate(end, current)}
                isClearable={false}
                isReadOnly={true}
                ariaLabel="Start date"
                clickOnTodayButton={clickOnToday}
              />
            </FormItem>
          </Col>
          <Col isAuto={true} span={12} role="presentation">
            <FormItem label={'Start time'}>
              <TimeInput
                onChange={onStartTimeChange}
                name={'start'}
                value={start}
                id="event-edit-form-input-start-time"
                label="Start time"
              />
            </FormItem>
          </Col>
        </Row>
      </div>
      <div className="shift-modal-inner spacing-bottom-large">
        <Row role="presentation">
          <Col isAuto={true} span={12} role="presentation" className={'pl-4'}>
            <FormItem className={'period_end_date'} label={'End date'}>
              <DateInputMoment
                id="event-edit-form-input-end"
                onChange={onChangeDate}
                name={'end'}
                value={end}
                disabledDate={(current) => disabledPastDate(start, current)}
                isClearable={false}
                isReadOnly={true}
                ariaLabel="End date"
                clickOnTodayButton={clickOnToday}
              />
            </FormItem>
          </Col>
          <Col isAuto={true} span={12} role="presentation">
            <FormItem label={'End time'}>
              <TimeInput
                onChange={onEndTimeChange}
                name={'end'}
                value={end}
                id="event-edit-form-input-end-time"
                label="End time"
              />
            </FormItem>
          </Col>
        </Row>
      </div>
      <div className="shift-modal-inner spacing-bottom-large">
        <Row role="presentation">
          <Col isAuto={true} span={12} role="presentation" className={'pl-4'}>
            <FormItem>
              <Button
                type="primary"
                onClick={onAllDayClick}
                id="event-edit-form-btn-all-day"
              >
                All Day
              </Button>
            </FormItem>
          </Col>
        </Row>
      </div>
      <Divider />
      <br />
    </div>
  );
};

export default EventPeriod;
