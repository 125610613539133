import React, { useState, useEffect, useCallback, useRef } from 'react';
import { SideNav, DeepThemeProvider } from '@breatheHR/breathe-design-system';
import './side-nav-menu.scss';
import {
  getHrNavigationMenuData,
  getRtaMenuData,
} from '../../../../lib/Api/directApi';
import _ from 'lodash';
import axios from 'axios';
import { useRoute } from 'hooks';
import { BOX_MAIN_MENU_TIMECLOCK_ITEM_CONFIRMATION_OPENED } from '../../../../state/MainMenuHelpItem';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

export function SideNavMenu() {
  const [selectedMenuItem, setSelectedMenuItem] = useState('');
  const [hrSideNavData, setHrSideNavData] = useState<any>();
  const [loadingHrSideNavData, setLoadingHrSideNavData] = useState(true);
  const [rtaSideNavData, setRtaSideNavData] = useState<any>();
  const [loadingRtaSideNavData, setLoadingRtaSideNavData] = useState(true);
  const sideNavMenuRef = useRef<any>(null);
  const [urlObject, setUrlObject] = useState<any>();
  const [sideNavMobileOpen, setSideNavMobileOpen] = useState(false);

  const route = useRoute();
  const dispatch = useDispatch();

  const location = useLocation();

  const bottomMenuItemsOrder = ['configure', 'help', 'refer_friend'];

  // get HR menu data
  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    const fetchHrSideNavData = async () => {
      setLoadingHrSideNavData(true);
      try {
        const res = await getHrNavigationMenuData(cancelToken);
        if (res) {
          setHrSideNavData(res);
        }
      } catch (err: any) {
        throw new Error(err.message);
      }
      setLoadingHrSideNavData(false);
    };
    fetchHrSideNavData();
    return () => {
      cancelToken.cancel('Request cancelled');
    };
  }, []);

  // get RTA menu data
  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    const fetchRtaSideNavData = async () => {
      setLoadingRtaSideNavData(true);
      try {
        const res = await getRtaMenuData(cancelToken);
        if (res) {
          setRtaSideNavData(res);
        }
      } catch (err: any) {
        throw new Error(err.message);
      }
      setLoadingRtaSideNavData(false);
    };
    fetchRtaSideNavData();
    return () => {
      cancelToken.cancel('Request cancelled');
    };
  }, []);

  // close menu when clicking outside it
  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (
        sideNavMenuRef.current &&
        !sideNavMenuRef.current.contains(e.target)
      ) {
        setSelectedMenuItem('');
        setSideNavMobileOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);
    };
  });

  // handle subnav click
  const handleSubNavClick = (e: any) => {
    if (
      e.currentTarget.id === selectedMenuItem ||
      e.currentTarget.id === 'menu__submenu-back'
    ) {
      e.preventDefault();
      setSelectedMenuItem('');
    } else {
      e.preventDefault();
      setSelectedMenuItem(e.currentTarget.id);
    }
  };

  // get current url data
  useEffect(() => {
    const currentPort = window.location.port;
    const currentHref = window.location.href;
    const currentPath = window.location.pathname;
    const currentProtocol = window.location.protocol;
    const search = window.location.search;
    // make currentBasicUrl with no port, no protocol, no path, no search
    const currentBasicUrl = currentHref
      .replace(`${currentPort ? `:${currentPort}` : ''}`, '')
      .replace(currentPath, '')
      .replace(search, '');

    setUrlObject({
      currentPort,
      currentHref,
      currentPath,
      currentProtocol,
      search,
      currentBasicUrl,
    });
  }, [location.pathname]);

  // merge HR and RTA menu data and append click handlers if needed
  const mergeSideNavItems = useCallback((existingItems, itemsToBeMerged) => {
    for (const itemToBeMerged of itemsToBeMerged) {
      const matchingExistingItem = existingItems.find((item: any) => {
        return item.key === itemToBeMerged.key;
      });
      if (matchingExistingItem) {
        // initialise sub_items if it doesn't exist
        if (!matchingExistingItem.sub_items) {
          matchingExistingItem.sub_items = [];
        }
        // recursively merge sub_items
        mergeSideNavItems(
          matchingExistingItem.sub_items,
          itemToBeMerged.sub_items
        );
      } else {
        // if there is no item with matching key then add the item
        existingItems.push(itemToBeMerged);
      }
    }

    return existingItems;
  }, []);

  const sortSubItems = (items: any[], sortOrder: string[]) => {
    return items.sort((a, b) => {
      return sortOrder.indexOf(a.key) - sortOrder.indexOf(b.key);
    });
  };

  const appendClickHandlersToLinks = (items: any, currentBasicUrl: any) => {
    items.forEach((item: any) => {
      if (item.sub_items) {
        appendClickHandlersToLinks(item.sub_items, currentBasicUrl);
      }
      // if link is timeclock, open timeclock modal
      else if (item.key === 'timeclock') {
        item.onClick = (e: any) => {
          e.preventDefault();
          dispatch(BOX_MAIN_MENU_TIMECLOCK_ITEM_CONFIRMATION_OPENED());
          setSelectedMenuItem('');
          setSideNavMobileOpen(false);
        };
      }
      // if link is internal, use react router
      else if (item.link?.includes(currentBasicUrl)) {
        // amend link to remove protocol and currentBasicUrl (leaving just the path)
        const amendedLink = item.link.replace(currentBasicUrl, '');
        // attach onClick to the item so that it uses react router
        item.onClick = (e: any) => {
          e.preventDefault();
          route.goTo(amendedLink);
          setSelectedMenuItem('');
          setSideNavMobileOpen(false);
        };
      }
    });
  };

  // If the time and attendance link doesn't have any sub_items, remove it
  const removeTimeAndAttendanceIfEmpty = (items: any) => {
    return items.map((item: any) => {
      if (item.sub_items) {
        item.sub_items = item.sub_items.filter((subItems: any) => {
          if (subItems.key === 'time_attendance') {
            if (!subItems.sub_items) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        });
      }
      return item;
    });
  };

  let mergedSideNavTopData;

  if (
    hrSideNavData?.main_menu &&
    rtaSideNavData?.main_menu &&
    urlObject.currentBasicUrl
  ) {
    mergedSideNavTopData = mergeSideNavItems(
      _.cloneDeep(hrSideNavData).main_menu,
      _.cloneDeep(rtaSideNavData).main_menu
    );
    mergedSideNavTopData = removeTimeAndAttendanceIfEmpty(
      _.cloneDeep(mergedSideNavTopData)
    );
    appendClickHandlersToLinks(mergedSideNavTopData, urlObject.currentBasicUrl);
  }

  let mergedSideNavBottomData;

  if (
    hrSideNavData?.bottom_menu &&
    rtaSideNavData?.bottom_menu &&
    urlObject.currentBasicUrl
  ) {
    mergedSideNavBottomData = mergeSideNavItems(
      _.cloneDeep(hrSideNavData).bottom_menu,
      _.cloneDeep(rtaSideNavData).bottom_menu
    );

    const bottomSection = mergedSideNavBottomData.find(
      (item: any) => item.key === 'bottom_section'
    );

    if (bottomSection?.sub_items?.length > 0) {
      sortSubItems(bottomSection.sub_items, bottomMenuItemsOrder);
    }

    appendClickHandlersToLinks(
      mergedSideNavBottomData,
      urlObject.currentBasicUrl
    );
  }

  const handleSideNavToggle = (toggle: any) => {
    setSideNavMobileOpen(toggle);
  };

  return (
    <DeepThemeProvider mode="">
      <div className="side-nav-container" ref={sideNavMenuRef}>
        <SideNav
          topMenus={mergedSideNavTopData}
          bottomMenus={mergedSideNavBottomData}
          selectedMenuItem={selectedMenuItem}
          handleSubNavClick={handleSubNavClick}
          activeMenuItem={`${urlObject?.currentBasicUrl}${urlObject?.currentPath}`}
          logoImage={hrSideNavData?.logo?.image}
          logoLink={hrSideNavData?.logo?.link}
          sideNavMobileOpen={sideNavMobileOpen}
          handleSideNavToggle={handleSideNavToggle}
        />
      </div>
    </DeepThemeProvider>
  );
}
export default SideNavMenu;
