import { createAction } from 'lib/store-utils';
import { FormattedErrors, GeneratedReport, Report, ReportSchedule } from '../../type';

export const BOX_REPORT_GET_REPORT_REQUEST = createAction<string>('Get report request');
export const BOX_REPORT_GET_REPORT_SUCCESS = createAction<Report>('Get report success');
export const BOX_REPORT_GET_REPORT_FAILURE = createAction<FormattedErrors>('Get report failure');

export const BOX_REPORT_GENERATE_REPORT_REQUEST = createAction<Report>('Generate new report request');
export const BOX_REPORT_GENERATE_REPORT_SUCCESS = createAction<{ generated: GeneratedReport, report: Report }>('Generate new report success');
export const BOX_REPORT_GENERATE_REPORT_FAILURE = createAction<FormattedErrors>('Generate new report failure');

export const BOX_REPORT_GENERATE_EXIST_REPORT_REQUEST = createAction<string>('Generate exist report request');
export const BOX_REPORT_GENERATE_EXIST_REPORT_SUCCESS = createAction<GeneratedReport>('Generate exist report success');
export const BOX_REPORT_GENERATE_EXIST_REPORT_FAILURE = createAction<string[]>('Generate exist report failure');

export const BOX_REPORT_FIELDS_REQUEST = createAction('Get reports fields');
export const BOX_REPORT_FIELDS_SUCCESS = createAction<any>('Reports fields success');
export const BOX_REPORT_FIELDS_FAILURE = createAction<FormattedErrors>('Reports fields failure');

export const BOX_REPORT_UPDATE_REPORT_REQUEST = createAction<Report>('update report request');
export const BOX_REPORT_UPDATE_REPORT_SUCCESS = createAction<Report>('update report success');
export const BOX_REPORT_UPDATE_REPORT_FAILURE = createAction<FormattedErrors>('update report failure');

export const BOX_REPORT_SAVE_AS_REPORT_RESTORE = createAction('Save as report success');

export const BOX_REPORT_SAVE_AS_REPORT_SUCCESS_REDIRECT = createAction('Save as report success');

export const BOX_REPORT_SET_DEFAULT_STATE = createAction('Set default state for report');

export const BOX_REPORT_CLEAR_ERRORS = createAction('Remove errors');

export const BOX_REPORT_SCHEDULE_MODAL_OPEN = createAction('Open schedule modal');
export const BOX_REPORT_SCHEDULE_MODAL_CLOSE = createAction('Close schedule modal');

export const BOX_REPORT_SCHEDULE_UPDATE_REQUEST = createAction<ReportSchedule>('Update schedule request');
export const BOX_REPORT_SCHEDULE_UPDATE_SUCCESS = createAction<ReportSchedule>('Update schedule success');
export const BOX_REPORT_SCHEDULE_UPDATE_FAILURE = createAction<FormattedErrors>('Update schedule failure');

export const BOX_REPORT_SET_PAGE_SIZE = createAction<number>('Set page size');
export const BOX_REPORT_SET_CURRENT_PAGE = createAction<{
  page: number,
  startItem: number,
  endItem: number
}>('Set report current page');

export const BOX_REPORT_SET_ORDER_FIELD = createAction<string>('Set report order field');
export const BOX_REPORT_SET_ORDER_DIRECTION = createAction<'asc' | 'desc'>('Set report order direction');
