import React from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';
import { PageDialog, PageDialogCancel, PageDialogSubmit } from 'element';

type Props = {
  isOpened: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export const ConfirmationModal = ({ isOpened, onCancel, onConfirm }: Props) => {
  return (
    <PageDialog maxWidth={'xs'} open={isOpened}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        Upon confirmation the timezone will be applied to all account
      </DialogContent>
      <DialogActions>
        <PageDialogCancel onClick={onCancel}>Cancel</PageDialogCancel>
        <PageDialogSubmit onClick={onConfirm}>Yes, confirm</PageDialogSubmit>
      </DialogActions>
    </PageDialog>
  );
};
