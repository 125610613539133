import { createReducer } from 'lib/store-utils';
import * as actions from './actions';
import { NotificationsReducerState } from './type';
import { defaultState } from './state';

export const notifications = createReducer<NotificationsReducerState>({}, defaultState);

notifications.on(actions.BOX_NOTIFICATIONS_REQUEST, state => ({
  ...state,
  isLoading: true,
  isFetching: true,
  errors: []
}));

notifications.on(actions.BOX_NOTIFICATIONS_SUCCESS, (state, payload) => ({
  ...state,
  isLoading: false,
  isFetching: false,
  isFetched: true,
  notifications: payload,
  errors: []
}));

notifications.on(actions.BOX_NOTIFICATIONS_FAILURE, (state, payload) => ({
  ...state,
  isLoading: false,
  isFetching: false,
  isFetched: true,
  errors: payload
}));

notifications.on(actions.BOX_UPDATE_NOTIFICATIONS_REQUEST, state => ({
  ...state,
  isLoading: true,
  errors: []
}));

notifications.on(actions.BOX_UPDATE_NOTIFICATIONS_SUCCESS, state => ({
  ...state,
  isLoading: false,
  isFetched: true,
  errors: []
}));

notifications.on(actions.BOX_UPDATE_NOTIFICATIONS_FAILURE, (state, payload) => ({
  ...state,
  isLoading: false,
  isFetched: false,
  errors: payload
}));

notifications.on(actions.BOX_UPDATE_NOTIFICATIONS_EMAIL, (state, payload) => ({
  ...state,
  notifications: {
    ...state.notifications,
    enable_email: payload
  }
}));

notifications.on(actions.BOX_UPDATE_NOTIFICATIONS_SMS, (state, payload) => ({
  ...state,
  notifications: {
    ...state.notifications,
    enable_sms: payload
  }
}));

notifications.on(actions.BOX_UPDATE_NOTIFICATIONS_NOTIFICATIONS, (state, payload) => ({
  ...state,
  notifications: {
    ...state.notifications,
    show_notifications: payload
  }
}));

notifications.on(actions.BOX_CHANGE_NOTIFICATIONS, (state, payload) => ({
  ...state,
  notifications: {
    ...state.notifications,
    notifications_groups: {
      ...state.notifications.notifications_groups,
      [payload.group]: {
        ...state.notifications.notifications_groups[payload.group],
        [payload.id]: {
          ...state.notifications.notifications_groups[payload.group][payload.id],
          [payload.type]: payload.value
        }
      }
    }
  }
}));

notifications.on(actions.BOX_NOTIFICATIONS_CLEAR_ERRORS, state => ({
  ...state,
  errors: []
}));
