import React, { useState } from 'react';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '../../../../extended-oxygen-elements';
import { SettingsLayout } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import {
  BOX_ACCOUNT_PREFERENCES_SETTINGS_UPDATE,
  BOX_ACCOUNT_TIMEZONES,
  getAccountTimezone,
  getTimezoneList,
} from '../../../../state/Account';
import { ConfirmationModal } from './ConfirmationModal';

export const AccountPreferencesView = () => {
  const handleSuccess = () => false;
  const timezonesList = useSelector(getTimezoneList);
  const accountTimezone = useSelector(getAccountTimezone);

  const [selectedTimezone, setTimezone] = useState<string>(accountTimezone);
  const [isOpened, setIsOpened] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = () => {
    setIsOpened(true);
  };

  const onConfirm = () => {
    setIsOpened(false);
    dispatch(
      BOX_ACCOUNT_PREFERENCES_SETTINGS_UPDATE.request({
        timezone: selectedTimezone,
      })
    );
  };

  const onCancel = () => {
    setIsOpened(false);
  };

  return (
    <>
      <SettingsLayout
        title={`Account Preferences`}
        onSubmit={onSubmit}
        onSuccess={handleSuccess}
      >
        <Box maxWidth={550}>
          <Typography variant="h4" gutterBottom>
            Timezone settings
          </Typography>

          <FormControl>
            <Select
              name="timezone"
              margin="none"
              value={selectedTimezone}
              onChange={(e: any) => setTimezone(e.target.value)}
              MenuProps={{
                disableAutoFocusItem: false,
                variant: 'selectedMenu',
              }}
            >
              {timezonesList.map((snapRosterOption) => (
                <MenuItem value={snapRosterOption} key={snapRosterOption}>
                  {snapRosterOption}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </SettingsLayout>
      <ConfirmationModal
        isOpened={isOpened}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </>
  );
};
