import { SimpleOrPayloadActionCreator } from './types';
import { createId } from './utils';

export const createAction: SimpleOrPayloadActionCreator = (
  description: string
) => {
  const type = `[${createId()}] ${description}`;

  const actionCreator = (payload: any) => ({
    type,
    payload,
  });

  actionCreator.getType = () => type;
  actionCreator.toString = () => type;

  return actionCreator as any;
};

export default createAction;
