import React, {
  Children,
  cloneElement,
  Component,
  ReactChild,
  ReactElement,
} from 'react';
import Radio, { RadioProps } from '../Radio';
import { DIRECTION_HORIZONTAL, DIRECTION_VERTICAL } from '../_lib/const';
import { getClass, isElementOfType, uniqueId } from '../_lib/helper';
import { RadioGroupProps } from './type';
import PropTypes from 'prop-types';
import './RadioGroup.scss';

export const RadioGroupPropTypes = {
  selected: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  direction: PropTypes.oneOf([DIRECTION_VERTICAL, DIRECTION_HORIZONTAL]),
  ariaLabelledBy: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
};

type RadioGroupState = {
  selectedValue: string | number | null;
};

class RadioGroup extends Component<RadioGroupProps, RadioGroupState> {
  static propTypes = RadioGroupPropTypes;
  protected radioInputName: string = uniqueId('elmo-radio-group');

  constructor(props: RadioGroupProps) {
    super(props);

    this.state = {
      selectedValue: props.selected,
    };
  }

  componentDidUpdate(prevProps: RadioGroupProps) {
    if (this.props.selected !== prevProps.selected) {
      this.setState({
        selectedValue: this.props.selected,
      });
    }
  }

  onRadioButtonClick = (value: string | number) => {
    this.setState({
      selectedValue: value,
    });

    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  isSelected(value: string | number): boolean {
    return this.state.selectedValue === value;
  }

  renderRadioButtons() {
    const { id, disabled } = this.props;

    return Children.map(
      this.props.children as ReactElement<any>,
      (child: ReactChild, index: number) => {
        if (!isElementOfType(child, Radio)) {
          return null;
        }

        const radioChild = child as unknown as ReactElement<RadioProps>;
        let childId: string | undefined;
        if (id) {
          childId = `${id}-${index}`;
        }
        return cloneElement(radioChild, {
          onChange: this.onRadioButtonClick,
          checked: this.isSelected(radioChild.props.value),
          disabled: disabled || radioChild.props.disabled,
          id: childId,
          name: this.radioInputName,
        });
      }
    );
  }

  render() {
    const { className, id, ariaLabelledBy, direction } = this.props;

    let classes = getClass(
      'elmo-radio-group',
      [className, 'row flex-inline no-gutters'],
      {
        col: direction === DIRECTION_VERTICAL,
      }
    );

    return (
      <div
        id={id}
        className={classes}
        role="radiogroup"
        aria-labelledby={ariaLabelledBy}
        data-testid={`elmo-radio-group-${id || 'default'}`}
      >
        {this.renderRadioButtons()}
      </div>
    );
  }
}

export default RadioGroup;
