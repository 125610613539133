import moment, { Moment } from 'moment';
import { FormData } from './types';

export const getDefaultDates = (): Pick<
  FormData,
  'start_date' | 'start_time' | 'end_date' | 'end_time' | 'until'
> => {
  return {
    start_date: moment(),
    start_time: moment(),
    end_date: moment(),
    end_time: moment(),
    until: moment()
  };
};

export const createUntilDate = (endDate: Moment, until: Moment = moment()) =>
  until.isBefore(endDate) ? moment(endDate) : until;
