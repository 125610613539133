import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@mui/styles';
import { DefaultDivProps } from 'type';
import { AddIcon } from 'element';

type Props = DefaultDivProps;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.grey[100],
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2),
      cursor: 'pointer',
      opacity: 0,
      transition: theme.transitions.create(['opacity'], {
        duration: theme.transitions.duration.shortest,
      }),
      '&:hover': {
        opacity: 1,
      },
      // TODO maybe move to a parent component:
      height: '100%',
    },
    icon: {
      paddingRight: theme.spacing(1),
    },
    textWrapper: {
      textAlign: 'center',
      width:  '100%'
    },
  })
);

export const AddPlaceholder = (props: Props) => {
  const classes = useStyles();

  return (
    <div {...props} className={clsx(classes.root, props.className)}>
      <AddIcon className={classes.icon} />

      <div className={classes.textWrapper}>
        <div>Add</div>

        <div>Timesheet</div>
      </div>
    </div>
  );
};
