import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Api } from 'lib/Api';
import { Permission, StringMap } from 'type';
import { processApiRequest } from 'state/ProcessApiRequest';
import { formatError } from '../helpers';
import * as actions from './actions';

export const getPermissionsRequest = function* (): SagaIterator {
  const permissions: StringMap<Permission> = yield call(
    processApiRequest,
    Api.Permissions.all
  );
  yield put(actions.BOX_PERMISSIONS_SUCCESS(permissions));
};

const getPermissions = function*(): SagaIterator {
  try {
    yield call(getPermissionsRequest);
  } catch (error) {
    yield put(actions.BOX_PERMISSIONS_FAILURE(formatError(error)));
  }
};

export const watchPermissions = function*(): SagaIterator {
  yield takeLatest(actions.BOX_PERMISSIONS_REQUEST, getPermissions);
};
