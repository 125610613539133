import { AlertErrorBoxDialog, TimeField } from 'element';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'extended-oxygen-elements';
import { useSelectorWithProps, useUpdateEffect } from 'hooks';
import { SERVER_DATE_TIME_FORMAT } from 'lib/config';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { timeFormatSelector } from 'state/Account';
import { getTimesheet } from 'state/TimesheetResponse';
import {
  BOX_TIMESHEETS_WEEKLY_DIALOG_ERRORS_CLOSED,
  BOX_TIMESHEETS_WEEKLY_TIMESHEET_STOP,
  getDialogErrors,
  getDialogRequestStatus,
  getDialogUpdating,
} from 'state/TimesheetsWeeklyPage';
import { Minutes } from 'type';

type StopDialogProps = {
  open: boolean;
  onClose: () => void;
  timesheetId: string;
};

export function StopDialog({ open, onClose, timesheetId }: StopDialogProps) {
  const [endTime, setEndTime] = React.useState<Minutes | null>(null);
  const dispatch = useDispatch();
  const timeFormat = useSelector(timeFormatSelector);
  const loading = useSelector(getDialogUpdating);
  const errors = useSelector(getDialogErrors);
  const { id, start } = useSelectorWithProps(getTimesheet, timesheetId);

  const requestStatus = useSelector(getDialogRequestStatus);

  const closeDialog = () => {
    if (loading) {
      return;
    }

    onClose();
  };

  useUpdateEffect(() => {
    if (requestStatus === 'success') {
      closeDialog();
    }
  }, [requestStatus]);

  const getEndTime = () => {
    if (endTime === null) {
      return null;
    }

    const endTimeMoment = moment
      .parseZone(start)
      .startOf('day')
      .add(endTime, 'minutes');

    if (endTimeMoment.isAfter(start, 'minutes')) {
      return endTimeMoment.format(SERVER_DATE_TIME_FORMAT);
    }

    return endTimeMoment.add(1, 'day').format(SERVER_DATE_TIME_FORMAT);
  };

  const handleConfirmButtonCLick = () => {
    if (loading) {
      return;
    }

    dispatch(
      BOX_TIMESHEETS_WEEKLY_TIMESHEET_STOP.request({
        id,
        end_time: getEndTime(),
      })
    );
  };

  const handleErrorsClose = () => {
    dispatch(BOX_TIMESHEETS_WEEKLY_DIALOG_ERRORS_CLOSED());
  };

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>Stop the timesheet?</DialogTitle>

      <DialogContent>
        <AlertErrorBoxDialog
          BoxProps={{
            mb: 2,
          }}
          errors={errors}
          onClose={handleErrorsClose}
        />

        <DialogContentText>
          Timesheet will be stopped with selected end time if specified
        </DialogContentText>

        <TimeField
          value={endTime}
          onValueChange={setEndTime}
          label="Timesheet end time"
          placeholder={moment({ hours: 18 }).format(timeFormat)}
          autoFocus
        />
      </DialogContent>

      <DialogActions>
        <Button disabled={loading} onClick={closeDialog}>
          Cancel
        </Button>

        <Button
          variant="contained"
          loading={loading}
          disableRipple={loading}
          onClick={handleConfirmButtonCLick}
        >
          Stop
        </Button>
      </DialogActions>
    </Dialog>
  );
}
