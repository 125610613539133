import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'extended-oxygen-elements';
import {
  BOX_ROSTER_APPROVE_MODE_TOGGLE,
  getIsApprovalModeEnabled,
} from 'state/Roster/Roster';

export const ApprovalModeAlert = () => {
  const dispatch = useDispatch();
  const isApprovalModeEnabled = useSelector(getIsApprovalModeEnabled);

  const handleAlertClose = React.useCallback(() => {
    dispatch(BOX_ROSTER_APPROVE_MODE_TOGGLE());
  }, [dispatch]);

  if (!isApprovalModeEnabled) {
    return null;
  }

  return (
    <Alert severity="info" className="mb-4" onClose={handleAlertClose}>
      Approval mode on
    </Alert>
  );
};
