import React from 'react';
import { TableChartOutlinedIcon } from 'element';
import { useSelectorWithProps } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { hasPermissionSelector } from 'state/Auth';
import { getFlag } from 'state/Flags';
import {
  BOX_ROSTER_OPEN_APPLY_CHANGES_MODAL,
  getIsApprovalModeEnabled,
} from 'state/Roster/Roster';
import { getTimesheetsFilter } from 'state/Roster/RosterFilters';

export default useBtnExportTimesheets;

export function useBtnExportTimesheets() {
  const dispatch = useDispatch();
  const canEditRosteredShifts = useSelectorWithProps(
    hasPermissionSelector,
    'roster.rosteredshift.edit'
  );
  const canApproveTimesheets = useSelectorWithProps(
    hasPermissionSelector,
    'roster.timesheet.edit.approve'
  );
  const isTimesheetsFilterEnabled = useSelector(getTimesheetsFilter);
  const isApprovalModeEnabled = useSelector(getIsApprovalModeEnabled);
  const isTimesheetExportFlagEnabled = useSelector(getFlag.timesheetExport);

  return (
    canEditRosteredShifts &&
    canApproveTimesheets &&
    isTimesheetExportFlagEnabled &&
    isTimesheetsFilterEnabled && {
      id: 'export-timesheets-btn',
      icon: <TableChartOutlinedIcon color="inherit" />,
      onClick: () => {
        dispatch(BOX_ROSTER_OPEN_APPLY_CHANGES_MODAL('csv'));
      },
      label: 'Open export timesheets modal',
      isDisabled: isApprovalModeEnabled,
      tooltip: 'Open export timesheets modal',
    }
  );
}
