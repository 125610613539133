import { useDeleteDialogContext } from 'contexts';
import {
  DeleteOutlinedIcon,
  EditOutlinedIcon,
  FormattedDuration,
} from 'element';
import { IconButton } from 'extended-oxygen-elements';
import { useSelectorWithProps } from 'hooks';
import React from 'react';
import { useDispatch } from 'react-redux';
import { BOX_PAY_CONDITIONS_PAGE_DIALOG_OPENED } from 'state/PayConditionsPage';
import { getPayElement } from 'state/PayElementsCollection';
import { ShiftOvertimeRule } from 'type';
import LoadingsTableRow from '../../components/LoadingsTableRow';
import LoadingsTableCell from '../../components/LoadingsTableCell';
import { usePayConditionsListContext } from '../../pay-conditions-list-context';

type PayConditionProps = {
  overtimeRule: ShiftOvertimeRule;
};

export default function PayCondition({ overtimeRule }: PayConditionProps) {
  const dispatch = useDispatch();
  const payElement = useSelectorWithProps(
    getPayElement,
    overtimeRule.pay_element_id
  );
  const { openEdit, isEditOpen } = usePayConditionsListContext();
  const { openDialog } = useDeleteDialogContext();

  const handleEditBtnClick = () => {
    openEdit(overtimeRule.id);
  };

  const handleDeleteBtnClick = () => {
    dispatch(BOX_PAY_CONDITIONS_PAGE_DIALOG_OPENED());
    openDialog(overtimeRule.id);
  };

  return (
    <LoadingsTableRow data-testid={'pay-condition-row'}>
      <LoadingsTableCell data-testid={'pay-condition-from'}>
        <FormattedDuration>{overtimeRule.from_minutes}</FormattedDuration>
      </LoadingsTableCell>

      <LoadingsTableCell data-testid={'pay-condition-to'}>
        <FormattedDuration>{overtimeRule.to_minutes}</FormattedDuration>
      </LoadingsTableCell>

      <LoadingsTableCell data-testid={'pay-condition-element-name'}>
        {payElement.name}
      </LoadingsTableCell>

      <LoadingsTableCell padding="checkbox">
        {!isEditOpen && (
          <IconButton
            onClick={handleEditBtnClick}
            data-testid={'pay-condition-edit-btn'}
          >
            <EditOutlinedIcon />
          </IconButton>
        )}
      </LoadingsTableCell>

      <LoadingsTableCell padding="checkbox">
        {!isEditOpen && (
          <IconButton
            size="small"
            disabled={payElement.is_default}
            onClick={handleDeleteBtnClick}
            data-testid={'pay-condition-delete-btn'}
          >
            <DeleteOutlinedIcon />
          </IconButton>
        )}
      </LoadingsTableCell>
    </LoadingsTableRow>
  );
}
