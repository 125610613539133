import { Dictionary, SafeDictionary } from 'ts-essentials';
import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import _ from 'lodash';
import {
  DateType,
  RosteredShift,
  RosterTimesheetListedWithPayEntries,
  TimesheetsNewStatus,
} from 'type';
import {
  getIsTimesheetApproved,
  getIsTimesheetInProgress,
  getIsTimesheetPending,
  isTimesheetPunchInViolated,
  isTimesheetPunchOutViolated,
} from 'lib/helpers';
import { usersBySiteIdSelector } from 'state/UsersCollection';
import { getRosteredShifts } from 'state/RosteredShiftsCollection';
import {
  timesheetSettingsPunchInViolationMinutesSelector,
  timesheetSettingsPunchOutViolationMinutesSelector,
} from 'state/Account';
import {
  getFrom,
  getSiteId,
  getTimesheets,
  getTimesheetsArray,
  getTo,
} from 'state/TimesheetResponse';
import { formatDay, isArchivedWithData } from './helpers';

export const punchInViolatedTimesheetsSelector = createSelector(
  getTimesheets,
  getRosteredShifts,
  timesheetSettingsPunchInViolationMinutesSelector,
  (timesheets, rosteredShifts: SafeDictionary<RosteredShift>, punchInMinutes) =>
    _.pickBy(timesheets, (timesheet) => {
      const relatedShift = rosteredShifts[timesheet.rostered_shift_id || ''];

      return isTimesheetPunchInViolated(
        timesheet,
        relatedShift,
        punchInMinutes
      );
    })
);

export const punchOutViolatedTimesheetsSelector = createSelector(
  getTimesheets,
  getRosteredShifts,
  timesheetSettingsPunchOutViolationMinutesSelector,
  (
    timesheets,
    rosteredShifts: SafeDictionary<RosteredShift>,
    punchOutMinutes
  ) =>
    _.pickBy(timesheets, (timesheet) => {
      const relatedShift = rosteredShifts[timesheet.rostered_shift_id || ''];

      return isTimesheetPunchOutViolated(
        timesheet,
        relatedShift,
        punchOutMinutes
      );
    })
);

export const violatedTimesheetsSelector = createSelector(
  punchInViolatedTimesheetsSelector,
  punchOutViolatedTimesheetsSelector,
  (punchInViolatedTimesheets, punchOutViolatedTimesheets) => ({
    ...punchInViolatedTimesheets,
    ...punchOutViolatedTimesheets,
  })
);

export const filteredTimesheetsSelector = createSelector(
  getTimesheetsArray,
  violatedTimesheetsSelector,
  (state: any, props: { status: TimesheetsNewStatus }) => props.status,
  (timesheets, violatedTimesheets, status) => {
    const filtersByStatus: Dictionary<
      () => RosterTimesheetListedWithPayEntries[],
      TimesheetsNewStatus
    > = {
      approved: () => timesheets.filter(getIsTimesheetApproved),
      pending: () => timesheets.filter(getIsTimesheetPending),
      in_progress: () => timesheets.filter(getIsTimesheetInProgress),
      violated: () => Object.values(violatedTimesheets),
      all: () => timesheets,
    };

    return filtersByStatus[status]();
  }
);

const filteredTimesheetsByUserIdSelector = createSelector(
  filteredTimesheetsSelector,
  (filteredTimesheets) =>
    _.groupBy(filteredTimesheets, ({ user_id }) => user_id)
);

/**
 * Active user ids + archived user ids that have timesheets
 * */
export const shownUserIdsSelector = createSelector(
  usersBySiteIdSelector,
  getSiteId,
  filteredTimesheetsByUserIdSelector,
  (usersBySiteId, siteId, filteredTimesheetsByUserId) => {
    const siteUsers = usersBySiteId[siteId] || [];

    return _.reduce(
      siteUsers,
      (userIds, user) => {
        if (
          user.is_active ||
          isArchivedWithData(user, filteredTimesheetsByUserId)
        ) {
          userIds.push(user.id);
        }

        return userIds;
      },
      [] as string[]
    );
  }
);

const filteredUserTimesheetsSelector = createCachedSelector(
  filteredTimesheetsByUserIdSelector,
  (state: any, props: { userId: string }) => props.userId,
  (filteredTimesheetsGroupedByUserId, userId) =>
    filteredTimesheetsGroupedByUserId[userId] || []
)((state, props) => props.userId);

export const daysSelector = createSelector(getFrom, getTo, (from, to) =>
  _.times(to.diff(from, 'days') + 1, (dayNum) =>
    formatDay(from.clone().add(dayNum, 'day'))
  )
);

const userTimesheetsGridSelector = createCachedSelector(
  filteredUserTimesheetsSelector,
  daysSelector,
  (timesheets, days) => {
    const grid = days.reduce<Dictionary<string[]>>((accumulator, day) => {
      accumulator[day] = [];
      return accumulator;
    }, {});

    timesheets.forEach((timesheet) => {
      const startFormatted = formatDay(timesheet.start);

      if (grid[startFormatted]) {
        grid[startFormatted].push(timesheet.id);
      }
    });

    return grid;
  }
)((state, props) => props.userId);

const userTimesheetsGridDaySelector = createCachedSelector(
  userTimesheetsGridSelector,
  (state: any, props: { day: DateType }) => props.day,
  (grid, day) => grid[day]
)((state, props) => [props.userId, props.day].join());

export const userTimesheetsGridDayRowSelector = createCachedSelector(
  userTimesheetsGridDaySelector,
  (state: any, props: { rowIndex: number }) => props.rowIndex,
  (gridDayRows, rowIndex) => gridDayRows[rowIndex] || undefined
)((state, props) => [props.userId, props.day, props.rowIndex].join());

export const userMaxRowsSelector = createCachedSelector(
  userTimesheetsGridSelector,
  (grid) => {
    const rowsQuantity = Object.values(grid).map(
      (dayShifts) => dayShifts.length
    );
    return _.max(rowsQuantity) || 1;
  }
)((state, props) => props.userId);

export const filteredUserTimesheetsTotalDurationSelector = createCachedSelector(
  filteredUserTimesheetsSelector,
  (filteredUserTimesheets) =>
    _.sumBy(filteredUserTimesheets, ({ duration }) => duration)
)((state, props) => props.userId);
