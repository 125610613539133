import { createReducer } from 'lib/store-utils';
import { NotificationsListItem, NotificationsListReducerState } from './types';
import { defaultState } from './state';
import * as actions from './actions';
import _ from 'lodash';
import moment from 'moment';
import { StringMap } from 'type';

export const notificationsList = createReducer<NotificationsListReducerState>(
  {},
  defaultState
);

notificationsList.on(
  actions.BOX_NOTIFICATIONS_LIST_OPEN_MODAL,
  (state, type): NotificationsListReducerState => ({
    ...state,
    [type]: {
      ...state[type],
      isOpened: true
    }
  })
);

notificationsList.on(
  actions.BOX_NOTIFICATIONS_LIST_CLOSE_MODAL,
  (state, type): NotificationsListReducerState => ({
    ...state,
    [type]: {
      ...state[type],
      isOpened: false
    }
  })
);

notificationsList.on(
  actions.BOX_NOTIFICATIONS_LIST_GET_REQUEST,
  (state): NotificationsListReducerState => ({
    ...state,
    isFetching: true
  })
);

notificationsList.on(
  actions.BOX_NOTIFICATIONS_LIST_GET_REQUEST_SUCCESS,
  (state, data): NotificationsListReducerState => ({
    ...state,
    isFetching: false,
    [data.type]: {
      ...state[data.type],
      data: {
        ...state[data.type].data,
        ...data.data
      },
      meta: data.meta
    }
  })
);

notificationsList.on(
  actions.BOX_NOTIFICATIONS_LIST_GET_REQUEST_FAILURE,
  (state, errors): NotificationsListReducerState => ({
    ...state,
    isFetching: false,
    errors: errors
  })
);

notificationsList.on(
  actions.BOX_NOTIFICATIONS_LIST_MARK_READ_FAILURE,
  (state, errors): NotificationsListReducerState => ({
    ...state,
    isFetching: false,
    errors: errors
  })
);

notificationsList.on(
  actions.BOX_NOTIFICATIONS_LIST_CLEAR_ERRORS,
  (state): NotificationsListReducerState => ({
    ...state,
    errors: []
  })
);

notificationsList.on(
  actions.BOX_NOTIFICATIONS_LIST_MARK_READ_SUCCESS,
  (state, data): NotificationsListReducerState => ({
      ...state,
    [data.type]: {
      ...state[data.type],
      data: {
        ...state[data.type].data,
        [data.id]: {
          ...state[data.type].data[data.id],
          read_at: data.read_at
        }
      },
      meta: {
        ...state[data.type].meta,
        unread_count: state[data.type].meta.unread_count -= 1
      }
    }
  })
);

notificationsList.on(
  actions.BOX_NOTIFICATIONS_LIST_DELETE_ITEM,
  (state, data): NotificationsListReducerState => ({
    ...state,
    isDeleting: true
  })
);

notificationsList.on(
  actions.BOX_NOTIFICATIONS_LIST_DELETE_ITEM_SUCCESS,
  (state, data): NotificationsListReducerState => ({
    ...state,
    isDeleting: false,
    [data.type]: {
      ...state[data.type],
      data: _.omit(state[data.type].data, [data.id])
    }
  })
);

notificationsList.on(
  actions.BOX_NOTIFICATIONS_LIST_DELETE_ITEM_FAILURE,
  (state, errors): NotificationsListReducerState => ({
    ...state,
    errors: errors,
    isDeleting: false
  })
);

notificationsList.on(
  actions.BOX_NOTIFICATIONS_LIST_APPEND_ONE,
  (state, item): NotificationsListReducerState => {
    let count = state[item.notification_type].meta.unread_count;
    let total = state[item.notification_type].meta.total;
    let last_page = state[item.notification_type].meta.last_page;
    const obj: StringMap<NotificationsListItem> = state[item.notification_type].data;
    const list = Object.keys(obj);

    if ( list.length >= 20 ) {
      const keyToRemove = list[list.length - 1];
      delete obj[keyToRemove];
    }

    if (total + 1 > 20) {
      last_page += 1;
    }
    return {
      ...state,
      [item.notification_type]: {
        ...state[item.notification_type],
        data: {
          [item.id]: item,
          ...obj
        },
        meta: {
          ...state[item.notification_type].meta,
          unread_count: count < 0 ? 1 : ++count,
          total: ++total,
          last_page
        }
      }
    }
  }
);

notificationsList.on(
  actions.BOX_NOTIFICATIONS_LIST_READ_ALL,
  (state): NotificationsListReducerState => ({
    ...state,
    isUpdating: true
  })
);

notificationsList.on(
  actions.BOX_NOTIFICATIONS_LIST_READ_ALL_SUCCESS,
  (state, type): NotificationsListReducerState => {
    Object.entries(state[type].data).forEach(([key, value]) => {
      value.read_at = moment().format('YYYY-MM-DD HH:mm');
    });
    return {
    ...state,
      [type]: {
        ...state[type],
        data: state[type].data,
        meta: {
          ...state[type].meta,
          unread_count: 0
        }
      }
    }
});

notificationsList.on(
  actions.BOX_NOTIFICATIONS_LIST_DELETE_ALL_SUCCESS,
  (state, type): NotificationsListReducerState => ({
    ...state,
    [type]: {
      ...state[type],
      ...defaultState[type]
    }
  })
);

notificationsList.on(
  actions.BOX_NOTIFICATIONS_LIST_DELETE_ALL,
  (state): NotificationsListReducerState => ({
    ...state,
    isUpdating: true
  })
);

notificationsList.on(
  actions.BOX_NOTIFICATIONS_LIST_BULK_SUCCESS,
  (state): NotificationsListReducerState => ({
    ...state,
    isUpdating: false
  })
);

notificationsList.on(
  actions.BOX_NOTIFICATIONS_LIST_BULK_FAILURE,
  (state, errors): NotificationsListReducerState => ({
    ...state,
    isUpdating: false,
    errors: errors
  })
);
