import React from 'react';
import { LoadingOverlay, MessageBlock } from 'elmo-elements';
import { Props, State } from './type';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import {
  BOX_WHOS_WORKING_BREAKS_LIMIT_CONFIRMATION_CLOSE,
  BOX_WHOS_WORKING_CLEAR_ERRORS,
  getCustomPeriod,
  getIsCustomFilter,
  getIsShiftsFetching,
  getModal,
  getSelectedPeriod,
  getWhosWorkingErrors,
  getWhosWorkingShiftsFilter,
} from 'state/ManagerDashboard/WhosWorking';
import { userListSelector } from 'state/UsersCollection';
import {
  WorkingNowCard,
  StartingSoonCard,
  JustFinishedCard,
  TomorrowCard,
} from './components';
import { getAreas, getRoles, getSites } from 'state/AccountTree';
import { DashboardShift } from 'type/models/';
import RosteredShiftModal from 'element/shiftModals/RosteredShiftModal/RosteredShiftModal';
import './styles.scss';
import { ErrorsModal, ErrorBox } from 'element';

export class WorkingListComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      siteId: '',
    };
  }

  render() {
    const { modal } = this.props;
    return (
      <>
        <ErrorBox
          errors={this.props.errors}
          clearErrors={this.props.clearErrors}
        />

        <LoadingOverlay isLoading={this.props.isFetching} showSpinner={true}>
          {!this.props.workingShifts.length ? (
            <div className="no-shifts-message">
              <MessageBlock>
                <MessageBlock.Header>
                  No shifts were found matching your filter.
                </MessageBlock.Header>
              </MessageBlock>
            </div>
          ) : (
            <div className="working-cards">
              {this.props.workingShifts.map(
                (shift: DashboardShift, key: number) => {
                  return this.renderCard(shift, key);
                }
              )}
              <RosteredShiftModal
                siteId={this.state.siteId}
                displayUsersAndPositionsFields={true}
                deleteShiftAdditionalPayload={{
                  auto_publish: true,
                }}
                defaultValues={{
                  notify_user: true,
                }}
                disabledState={{
                  notify_user: true,
                }}
              />
            </div>
          )}

          <ErrorsModal
            isOpened={modal.isOpen}
            onClose={this.props.closeModal}
            children={modal.errors.join(', ')}
          />
        </LoadingOverlay>
      </>
    );
  }

  renderCard = (shift: DashboardShift, key: number) => {
    const { period, isCustomFilters, customPeriod } = this.props;

    let type = !isCustomFilters ? period : customPeriod;

    switch (type) {
      case 'now':
        return <WorkingNowCard shift={shift} key={`now-${key}`} />;
      case 'soon':
        return <StartingSoonCard shift={shift} key={`soon-${key}`} />;
      case 'just-finished':
        return <JustFinishedCard shift={shift} key={`finished-${key}`} />;
      case 'tomorrow':
        return (
          <TomorrowCard
            key={`tomorrow-${key}`}
            onChangeSiteID={(id: string) => {
              this.setState({
                siteId: id,
              });
            }}
            shift={shift}
          />
        );
      default:
        return '';
    }
  };
}

const mapStateToProps = (state: StoreState) => ({
  isFetching: getIsShiftsFetching(state),
  workingShifts: getWhosWorkingShiftsFilter(state),
  userList: userListSelector(state),
  areas: getAreas(state),
  roles: getRoles(state),
  sites: getSites(state),
  period: getSelectedPeriod(state),
  isCustomFilters: getIsCustomFilter(state),
  customPeriod: getCustomPeriod(state),
  errors: getWhosWorkingErrors(state),
  modal: getModal(state),
});

export const WorkingList = connect(mapStateToProps, {
  clearErrors: BOX_WHOS_WORKING_CLEAR_ERRORS,
  closeModal: BOX_WHOS_WORKING_BREAKS_LIMIT_CONFIRMATION_CLOSE,
})(WorkingListComponent);
