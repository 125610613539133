import moment from 'moment';
import { ShiftTemplateItem } from 'type';
import { SERVER_TIME_S_FORMAT } from 'lib/config';
import {
  useColorId,
  useElementId,
  useFormatTime,
  useHandleClickContextMenu,
  useIsBulkSelected,
} from '../hooks';
import { useHandleClickDeleteIcon } from './useHandleClickDeleteIcon';
import { useHandleClickAddButton } from './useHandleClickAddButton';
import { useHandleClick } from './useHandleClick';

export const useTemplateCard = (templateItem: ShiftTemplateItem) => {
  const colorId = useColorId(templateItem);
  const isSelected = useIsBulkSelected(templateItem);
  const from = useFormatTime(moment(templateItem.start, SERVER_TIME_S_FORMAT));
  const to = useFormatTime(moment(templateItem.end, SERVER_TIME_S_FORMAT));
  const onClickAddButton = useHandleClickAddButton(templateItem);
  const onClickDeleteIcon = useHandleClickDeleteIcon(templateItem);
  const onClick = useHandleClick(templateItem);
  const onClickContextualMenu = useHandleClickContextMenu();
  const id = useElementId(templateItem);

  const viewProps = {
    from,
    to,
    colorId,
    coloredBg: false,
    onClick,
    onClickDeleteIcon,
    onClickAddButton,
    isSelected,
    isHighlighted: false,
    isLocationShown: true,
    'data-shift-id': templateItem.id,
    id,
  };

  return {
    viewProps,
    breaks: templateItem.breaks,
    onClickContextualMenu: onClick ? onClickContextualMenu : () => false,
  };
};
