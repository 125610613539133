import { createSelector } from 'reselect';
import { areasBySiteIdSelector, getAreas, getRoles } from 'state/AccountTree';
import {
  rosteredShiftsFilteredSelector,
  timesheetsFilteredSelector,
  rosteredShiftFilterSelector,
  timesheetsFilterSelector,
  filteredAreasSelector,
} from 'state/Roster/RosterFilters';
import {
  buildRosterRowsWithTimeOffs,
  getTimesheetsWithoutRosters,
} from '../../../helpers';
import { weekNavParamsPayloadSelector } from '../../weekNavSelectors';
import { getKey, groupByRolePair, isNotEmptyRow } from './helpers';
import { GroupedAreaData } from '../../../types';
import _ from 'lodash';
import { getPrintOptions } from '../../../../Print';

const shiftsFilteredByMainFilterSelector = createSelector(
  rosteredShiftsFilteredSelector,
  rosteredShiftFilterSelector,
  (shifts, isVisible) => (isVisible ? shifts : [])
);

const timesheetsFilteredByMainFilterSelector = createSelector(
  timesheetsFilteredSelector,
  timesheetsFilterSelector,
  (shifts, isVisible) => (isVisible ? shifts : [])
);

const groupRostersByAreasWithArchivedSelector = createSelector(
  filteredAreasSelector,
  shiftsFilteredByMainFilterSelector,
  timesheetsFilteredByMainFilterSelector,
  weekNavParamsPayloadSelector,
  getAreas,
  getRoles,
  (
    areas,
    rosters,
    timesheets,
    getWeekNavParamsPayload,
    selectedAreas,
    selectedRoles
  ): GroupedAreaData[] => {
    const rosterByRole = groupByRolePair(rosters);
    const timesheetByRole = groupByRolePair(timesheets);

    const result: GroupedAreaData[] = areas.map((area) => {
      const rolePairKey = getKey(area);
      const roleRosters = rosterByRole[rolePairKey] || [];
      const roleTimesheets = timesheetByRole[rolePairKey] || [];

      const filteredTimesheets = getTimesheetsWithoutRosters(
        roleTimesheets,
        roleRosters
      );

      const rosterData = buildRosterRowsWithTimeOffs(
        [...roleRosters, ...filteredTimesheets],
        rosters,
        timesheets,
        getWeekNavParamsPayload,
        {}
      );

      return {
        role_id: area.role_id,
        area_id: area.area_id,
        rosters: rosterData.rows,
        rowsNumber: rosterData.rowsNumber,
        areaName: selectedAreas[area.area_id].name,
        roleName: selectedRoles[area.role_id]
          ? selectedRoles[area.role_id].name
          : 'No name',
      };
    });

    return _.sortBy(result, ['areaName', 'roleName']);
  }
);

export const groupRostersByAreas = createSelector(
  groupRostersByAreasWithArchivedSelector,
  getAreas,
  getRoles,
  (rosterData, areas, roles): GroupedAreaData[] => {
    return rosterData.filter(({ role_id, area_id, rosters }) => {
      const isAreaArchived = areas[area_id]?.archived || false;
      const isRoleArchived = roles[role_id]?.archived || false;
      const isEmpty = !isNotEmptyRow(rosters);
      const isArchived = isAreaArchived || isRoleArchived;
      return !(isArchived && isEmpty);
    });
  }
);

const groupPrintableRostersByAreasWithArchivedSelector = createSelector(
  filteredAreasSelector,
  shiftsFilteredByMainFilterSelector,
  timesheetsFilteredByMainFilterSelector,
  weekNavParamsPayloadSelector,
  getAreas,
  getRoles,
  getPrintOptions,
  (
    areas,
    rosters,
    timesheets,
    getWeekNavParamsPayload,
    selectedAreas,
    selectedRoles,
    printOptions
  ): GroupedAreaData[] => {
    const filteredRostersByPrintOptionsUnpublished = printOptions.unpublished
      ? rosters
      : rosters.filter((roster) => roster.is_published);

    const filteredRostersByPrintOptionsUnassigned = printOptions.unassigned
      ? filteredRostersByPrintOptionsUnpublished
      : filteredRostersByPrintOptionsUnpublished.filter(
          (roster) => roster.user_id
        );

    const filteredRostersByPrintOptions = printOptions.unassigned
      ? filteredRostersByPrintOptionsUnassigned
      : filteredRostersByPrintOptionsUnassigned.filter(
          (roster) => roster.user_id
        );

    const rosterByRole = groupByRolePair(filteredRostersByPrintOptions);
    const timesheetByRole = groupByRolePair(timesheets);

    const result: GroupedAreaData[] = areas.map((area) => {
      const rolePairKey = getKey(area);
      const roleRosters = rosterByRole[rolePairKey] || [];
      const roleTimesheets = timesheetByRole[rolePairKey] || [];

      const filteredTimesheets = getTimesheetsWithoutRosters(
        roleTimesheets,
        roleRosters
      );

      const rosterData = buildRosterRowsWithTimeOffs(
        [...roleRosters, ...filteredTimesheets],
        filteredRostersByPrintOptions,
        timesheets,
        getWeekNavParamsPayload,
        {}
      );

      return {
        role_id: area.role_id,
        area_id: area.area_id,
        rosters: rosterData.rows,
        rowsNumber: rosterData.rowsNumber,
        areaName: selectedAreas[area.area_id].name,
        roleName: selectedRoles[area.role_id]
          ? selectedRoles[area.role_id].name
          : 'No name',
      };
    });

    return _.sortBy(result, ['areaName', 'roleName']);
  }
);

export const groupPrintableRostersByAreas = createSelector(
  groupPrintableRostersByAreasWithArchivedSelector,
  getAreas,
  getRoles,
  (rosterData, areas, roles): GroupedAreaData[] => {
    return rosterData.filter(({ role_id, area_id, rosters }) => {
      const isAreaArchived = areas[area_id]?.archived || false;
      const isRoleArchived = roles[role_id]?.archived || false;
      const isEmpty = !isNotEmptyRow(rosters);
      const isArchived = isAreaArchived || isRoleArchived;
      return !(isArchived && isEmpty);
    });
  }
);
