import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { filtersSelector } from 'state/Roster/RosterFilters';
import { RosteredShiftRow } from './RosteredShiftRow';
import { TimesheetRow } from './TimesheetRow';
import { UnavailabilityRow } from './UnavailabilityRow';

type Props = { siteRowIndex: number; pairRowIndex: number };

export const SitePairRow = memo(function PairRow(props: Props) {
  const filters = useSelector(filtersSelector);
  const { pairRowIndex } = props;
  return (
    <>
      {filters.unavailability && !filters.roster && !filters.timesheets && pairRowIndex === 0 && <UnavailabilityRow />}
      {filters.roster && <RosteredShiftRow {...props} />}
      {filters.timesheets && <TimesheetRow {...props} />}
    </>
  );
});
