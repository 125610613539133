import React, { Component } from 'react';
import { RosterEventsLegend, RosterRow } from '../../../../../../components';
import { connect } from 'react-redux';
import { DayEventsTimeline } from './components/DayEventsTimeline';
import { BOX_ROSTER_DAY_VIEW_EVENTS_ROW_ADD } from 'state/Roster/RosterDayView';
import { StoreState } from 'state/types';
import { hasPermissionSelector } from 'state/Auth';

type OwnProps = {};

type StateProps = {
  canEditEvent: boolean;
};

type DispatchProps = {
  addRow: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

type State = {
  isCollapsed: boolean;
};

class DayForecastsAndEventsRowComponent extends Component<Props, State> {
  state = {
    isCollapsed: true,
  };

  toggleCollapsable = () => {
    this.setState({
      isCollapsed: !this.state.isCollapsed,
    });
  };

  render() {
    return (
      <RosterRow isCollapsed={this.state.isCollapsed}>
        {{
          sticky: (
            <RosterEventsLegend
              onClick={this.onLegendClickCreator()}
              toggleRow={this.toggleCollapsable}
              isCollapsable={true}
            />
          ),
          content: <DayEventsTimeline />,
        }}
      </RosterRow>
    );
  }

  private onLegendClickCreator = () => {
    const { canEditEvent } = this.props;

    if (!canEditEvent) {
      return;
    }

    return () => {
      this.props.addRow();
    };
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  canEditEvent: hasPermissionSelector(state, 'roster.events.edit'),
});

const mapDispatchToProps: DispatchProps = {
  addRow: BOX_ROSTER_DAY_VIEW_EVENTS_ROW_ADD,
};

export const DayForecastsAndEventsRow = connect(
  mapStateToProps,
  mapDispatchToProps
)(DayForecastsAndEventsRowComponent);
