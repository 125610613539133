import { some } from 'lodash';
import { makeRoleFilter } from './makeRoleFilter';

type User = {
  user_roles: { area_id: string; role_id: string; site_id: string }[];
};

export const userRoleFilter = makeRoleFilter<User>({
  flatFilter: ({ areas, roles }, users, currentSiteId) => {
    if (!areas && !roles) {
      return users;
    }

    const includesArea = (userRole: {
      area_id: string;
      site_id: string;
    }): boolean =>
      !areas
        ? true
        : areas.includes(userRole.area_id) &&
          userRole.site_id === currentSiteId;
    const includesRole = (userRole: {
      role_id: string;
      site_id: string;
    }): boolean =>
      !roles
        ? true
        : roles.includes(userRole.role_id) &&
          userRole.site_id === currentSiteId;

    return users.filter((user) =>
      user.user_roles.some(
        (userRole) => includesArea(userRole) && includesRole(userRole)
      )
    );
  },
  treeFilter: ({ roleTriples }, users, currentSiteId) => {
    if (!roleTriples) {
      return users;
    }

    return users.filter((user) =>
      user.user_roles.some((userRole) =>
        some(
          roleTriples,
          (roleTriple) =>
            userRole.area_id === roleTriple.areaId &&
            userRole.role_id === roleTriple.roleId &&
            userRole.site_id === currentSiteId
        )
      )
    );
  },
});
