import { createSelector } from 'reselect';
import { difference, filter, mapValues, values } from 'lodash';
import {
  NotFirstAndEmptyPageSelector,
  Pager,
  ShiftOffer,
  ShiftOffersWithPagerSelector,
  SortShiftOffers,
  StringMap
} from 'type';
import { StoreState } from '../../../../types';
import {
  notFirstAndEmptyPageCombiner,
  withoutCombiner,
  withPagerCombiner
} from '../../../../combiners';
import { ShiftOffersFilterTypes } from '../types';
import { orderBy } from 'natural-orderby';
import {
  declineProposalQueueByShiftOfferIdSelector,
  getApproveQueue,
  getDeleteQueue
} from '../../ShiftOffersUndo';

export const getIsUpdating = (state: StoreState): boolean =>
  state.managerDashboard.shiftOffers.shiftOffers.isUpdating;
const getShiftOffers = (state: StoreState): StringMap<ShiftOffer> =>
  state.managerDashboard.shiftOffers.shiftOffers.shiftOffers;
export const getSelectedFilter = (state: StoreState): ShiftOffersFilterTypes =>
  state.managerDashboard.shiftOffers.shiftOffers.selectedFilter;
export const getSort = (state: StoreState): SortShiftOffers =>
  state.managerDashboard.shiftOffers.shiftOffers.sort;
const getPager = (state: StoreState): Pager =>
  state.managerDashboard.shiftOffers.shiftOffers.pager;

export const shiftOffersWithoutDeleteQueueSelector = createSelector<
  StoreState,
  StringMap<ShiftOffer>,
  string[],
  StringMap<ShiftOffer>
>(getShiftOffers, getDeleteQueue, withoutCombiner);

export const shiftOffersWithoutApproveQueueSelector = createSelector<
  StoreState,
  StringMap<ShiftOffer>,
  string[],
  StringMap<ShiftOffer>
>(shiftOffersWithoutDeleteQueueSelector, getApproveQueue, withoutCombiner);

export const shiftOffersWithoutDeclineProposalQueueSelector = createSelector<
  StoreState,
  StringMap<ShiftOffer>,
  StringMap<string[]>,
  StringMap<ShiftOffer>
>(
  shiftOffersWithoutApproveQueueSelector,
  declineProposalQueueByShiftOfferIdSelector,
  (shiftOffers, declineProposalQueueByShiftOfferId) => {
    return mapValues(shiftOffers, shiftOffer => {
      const declinedProposalIds: string[] | undefined =
        declineProposalQueueByShiftOfferId[shiftOffer.id];

      if (!declinedProposalIds) {
        return shiftOffer;
      }

      return {
        ...shiftOffer,
        proposal_ids: difference(shiftOffer.proposal_ids, declinedProposalIds)
      };
    });
  }
);

export const filteredShiftOffersSelector = createSelector<
  StoreState,
  StringMap<ShiftOffer>,
  ShiftOffersFilterTypes,
  ShiftOffer[]
>(
  shiftOffersWithoutDeclineProposalQueueSelector,
  getSelectedFilter,
  (shiftOffers, selectedFilter) => {
    if (selectedFilter === 'all') {
      return values(shiftOffers);
    }

    return filter(
      shiftOffers,
      shiftOffer => shiftOffer.proposal_ids.length > 0
    );
  }
);

export const sortedShiftOffersSelector = createSelector<
  StoreState,
  ShiftOffer[],
  SortShiftOffers,
  ShiftOffer[]
>(filteredShiftOffersSelector, getSort, (shiftOffers, { direction, column }) =>
  orderBy(shiftOffers, shift => shift[column].unix(), direction)
);

export const shiftOffersWithPagerSelector = createSelector<
  StoreState,
  ShiftOffer[],
  Pager,
  ShiftOffersWithPagerSelector
>(sortedShiftOffersSelector, getPager, withPagerCombiner);

export const notFirstAndEmptyPageSelector = createSelector<
  StoreState,
  ShiftOffersWithPagerSelector,
  NotFirstAndEmptyPageSelector
>(shiftOffersWithPagerSelector, notFirstAndEmptyPageCombiner);
