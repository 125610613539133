import { Divider as MuiDivider } from 'extended-oxygen-elements';
import React from 'react';
import Spacing from './Spacing';

export type DividerProps = {};

export default Divider;

export function Divider(props: DividerProps) {
  return (
    <Spacing>
      <MuiDivider />
    </Spacing>
  );
}
