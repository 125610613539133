import { IArrayHandler, BaseItem } from './types';
import ItemHandler from './ItemHandler';

export default class ArrayHandler<
  ValueType extends BaseItem,
  ChangeHandler extends (updatedValues: ValueType[]) => void
> implements IArrayHandler<ValueType, ChangeHandler>
{
  constructor(
    private readonly values: ValueType[],
    private readonly onChange: ChangeHandler
  ) {}

  makeItemHandler(id: string): ItemHandler<ValueType, ChangeHandler> {
    return new ItemHandler(this, this.getItem(id));
  }

  addItem(newValue: ValueType) {
    this.onChange([...this.values, newValue]);
  }

  makeAddItem(creator: () => ValueType) {
    return () => {
      this.addItem(creator());
    };
  }

  updateItem = (updatedValue: ValueType) => {
    this.onChange(
      this.values.map((value) => {
        if (value.id === updatedValue.id) {
          return updatedValue;
        }

        return value;
      })
    );
  }

  removeItem(id: string) {
    this.onChange(this.values.filter((value) => value.id !== id));
  }

  private getItem(id: string): ValueType | undefined {
    return this.values.find((value) => value.id === id);
  }
}
