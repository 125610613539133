import { useRouteQuery } from 'hooks';
import React from 'react';

export type QueryItemProviderProps = {
  name: string;
  default: string;
  validator: (value: string | undefined) => boolean;
  children: React.ReactNode;
};

export default QueryItemProvider;

export function QueryItemProvider({ name, default: defaultValue, validator, children }: QueryItemProviderProps) {
  const { query, replaceQueryItems } = useRouteQuery();

  const isValid = validator(query[name]);

  React.useEffect(() => {
    if (!isValid) {
      replaceQueryItems({
        [name]: defaultValue
      });
    }
  }, [isValid, name, defaultValue]);

  if (!isValid) {
    return null;
  }

  return <>{children}</>;
}
