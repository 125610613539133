import React from 'react';
import { useSelectorWithProps } from 'hooks';
import { getTemplateShift } from 'state/Roster/EditTemplate';
import { useAreaName, useLinkedRole, useTemplateCard } from './hooks';
import { TemplateContextualMenu } from './contextualMenus';
import { ShiftCardBreaksDuration, ShiftCardContextMenuIcon } from './views';
import { ShiftCardViewRefactored } from './views/ShiftCardViewRefactored';

type Props = {
  templateShiftId: string;
};

export const UserTemplateCard = React.memo(function UserTemplateCard(
  props: Props
) {
  const templateShift = useSelectorWithProps(
    getTemplateShift,
    props.templateShiftId
  );
  const { viewProps, onClickContextualMenu, breaks } =
    useTemplateCard(templateShift);
  const linkedRole = useLinkedRole(templateShift);
  const areaName = useAreaName(templateShift);

  return (
    <ShiftCardViewRefactored
      {...viewProps}
      beforeContent={
        <TemplateContextualMenu
          elementId={viewProps.id}
          templateShiftId={props.templateShiftId}
        />
      }
      icons={<ShiftCardContextMenuIcon onClick={onClickContextualMenu} />}
      breaks={<ShiftCardBreaksDuration breaks={breaks} />}
      title={linkedRole}
      spotTitle={areaName}
    />
  );
});
