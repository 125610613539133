import { OverviewReducerState } from './types';

export const getDefaultState = (): OverviewReducerState => ({
  isLoading: false,
  overview: {
    today: {
      planned: 0,
      rostered: 0,
      timesheet: 0,
      is_complete: false
    },
    week: {
      planned: 0,
      rostered: 0,
      timesheet: 0,
      is_complete: false
    },
    staff: {
      today: 0,
      week: 0
    }
  }
});
