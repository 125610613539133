import moment from 'moment';
import {
  CopyPasteJob,
  DateType,
  RosterTimesheetListedWithPayEntries,
  ServerEvent,
  ServerRosteredShift,
  SummaryData,
} from 'type';
import { WS } from '../SocketConnection';
import BaseChannel, { ChannelHandler } from './BaseChannel';

type Params = {
  site_id: string;
  day: DateType;
};

function getChannelId({ site_id, day }: Params) {
  const dateMoment = moment.parseZone(day);
  const year = dateMoment.isoWeekYear();
  const weekNumber = dateMoment.isoWeek();
  const week = String(weekNumber).padStart(2, '0');

  return `roster.${site_id}_${year}_${week}`;
}

export default class RosterWeekChannel extends BaseChannel {
  constructor(ws: WS, params: Params) {
    super(ws, getChannelId(params));
  }

  onRosteredShiftChanged(handler: ChannelHandler<ServerRosteredShift>) {
    return this.on('.rostered_shift.changed', handler);
  }

  onRosteredShiftDeleted(handler: ChannelHandler<any>) {
    return this.on('.rostered_shift.deleted', handler);
  }

  onTimeOffRosteredShiftChanged(handler: ChannelHandler<any>) {
    return this.on('.time_off.rostered_shift.changed', handler);
  }

  onTimeOffRosteredShiftDeleted(handler: ChannelHandler<any>) {
    return this.on('.time_off.rostered_shift.deleted', handler);
  }

  onTimesheetChanged(
    handler: ChannelHandler<RosterTimesheetListedWithPayEntries>
  ) {
    return this.on('.timesheet.changed', handler);
  }

  onTimesheetDeleted(handler: ChannelHandler<{ id: string }>) {
    return this.on('.timesheet.deleted', handler);
  }

  onSummaryChanged(handler: ChannelHandler<SummaryData>) {
    return this.on('.summary.changed', handler);
  }

  onForecastEventChanged(handler: ChannelHandler<ServerEvent>) {
    return this.on('.forecast_event.changed', handler);
  }

  onForecastEventDeleted(handler: ChannelHandler<{ id: string }>) {
    return this.on('.forecast_event.deleted', handler);
  }

  onJobStatusChanged(handler: ChannelHandler<CopyPasteJob>) {
    return this.on('.job-status.changed', handler);
  }
}
