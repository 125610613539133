import React, { Component, KeyboardEvent } from 'react';
import { getClass } from '../../_lib/helper';
import { DropdownBaseItemProps } from '../type';
import { DropdownBaseKeyConstants } from '../constants';

export class DropdownBaseItem extends Component<DropdownBaseItemProps> {
  /**
   * When a key is pressed
   * @param e
   */
  handleKeyboardEvent = (e: KeyboardEvent) => {
    // ignore all key presses on the item except the Enter key
    if (e.key !== DropdownBaseKeyConstants.enterKey || !this.props.onClick) {
      return;
    }

    this.handleClick();
  };

  handleClick = () => {
    const { value, onClick, isDisabled, toggleMenu } = this.props;
    if (!onClick || isDisabled) {
      return;
    }

    onClick(value);
    if (toggleMenu) {
      toggleMenu();
    }
  };

  render() {
    const {
      isActive,
      isDisabled,
      ariaLabel,
      children,
      type,
      dividerType,
      className,
    } = this.props;

    return (
      <li
        className={getClass('oxygen-dropdown-base__menu-item', className, {
          active: isActive,
          disabled: isDisabled,
          danger: type === 'danger',
          'has-deafult-divider': dividerType === 'default',
          'has-short-divider': dividerType === 'short',
        })}
        onClick={this.handleClick}
        tabIndex={isDisabled ? -1 : 0}
        onKeyDown={this.handleKeyboardEvent}
        role="menuitem"
        aria-label={ariaLabel}
        aria-disabled={isDisabled}
      >
        {children}
      </li>
    );
  }
}
