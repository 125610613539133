import { createReducer } from 'lib/store-utils';
import _ from 'lodash';
import { IsLoadingReducerState } from './types';
import { defaultState } from './state';
import * as actions from './actions';

export const isLoading = createReducer({}, defaultState);

isLoading
  .on(
    actions.BOX_IS_LOADING_GLOBAL_ON,
    (state): IsLoadingReducerState => ({
      ...state,
      global: state.global + 1,
    })
  )
  .on(
    actions.BOX_IS_LOADING_GLOBAL_OFF,
    (state): IsLoadingReducerState => ({
      ...state,
      global: state.global - 1,
    })
  )
  .on(
    actions.BOX_IS_LOADING_ON,
    (state, pageId): IsLoadingReducerState => ({
      ...state,
      pages: [...state.pages, pageId],
    })
  )
  .on(
    actions.BOX_IS_LOADING_OFF,
    (state, pageId): IsLoadingReducerState => ({
      ...state,
      pages: _.without(state.pages, pageId),
    })
  );
