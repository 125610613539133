import React from 'react';
import { removeDragOverClassName } from '../../../../helpers';

type Props = {
  handleOnDrop: (e: any, action: 'move' | 'copy') => void;
  handleDragOver: any;
  handleDragEnd: any;
  rowId: string;
  dragAndDropAttrs: any;
}

export const CopyMovePlaceholder = ({
  handleOnDrop, handleDragOver, handleDragEnd, rowId, dragAndDropAttrs
}: Props) => {
  const showCopyCell = () => {
    return (
      <div
        className={'copy-cell'}
        {...dragAndDropAttrs}
        onDrop={e => handleOnDrop(e, 'copy')}
        onDragOver={handleDragOver}
        id={`cl-roster-${rowId}`}
        onDragEnd={handleDragEnd}
        onDragLeave={e => removeDragOverClassName(e)}
      />
    );
  };

  const showMoveCell = () => {
    return (
      <div
        {...dragAndDropAttrs}
        className={'move-cell'}
        onDrop={e => handleOnDrop(e, 'move')}
        onDragOver={handleDragOver}
        id={`pl-roster-${rowId}`}
        onDragEnd={handleDragEnd}
        onDragLeave={e => removeDragOverClassName(e)}
      />
    );
  };

  return (
    <div className={'delayed-placeholder'}>
      {showCopyCell()}
      {showMoveCell()}
    </div>
  )
};