import { Duration, Moment } from 'moment';
import { IsResizable, OnResizeEndPayload } from '../../../types';

export const getOnResizeEnd = (props: {
  day: Moment;
  onResizeEnd?: (payload: OnResizeEndPayload) => void;
  initialStartEnd: {
    start: Moment;
    end: Moment;
  };
  isResizable: IsResizable;
}): ((payload: { id: string; start: Duration; end: Duration }) => void) => {
  return (payload: { id: string; start: Duration; end: Duration }) => {
    if (props.onResizeEnd) {
      const start = props.day
        .clone()
        .startOf('day')
        .add(payload.start);

      const end = props.day
        .clone()
        .startOf('day')
        .add(payload.end);

      props.onResizeEnd({
        id: payload.id,
        start: props.isResizable.left ? start : props.initialStartEnd.start,
        end: props.isResizable.right ? end : props.initialStartEnd.end
      });
    }
  };
};
