import { createReducer } from 'lib/store-utils';
import { ConfirmationReducerState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';

export const confirmation = createReducer<ConfirmationReducerState>(
  {},
  getDefaultState()
);

const closeHandler = (state: ConfirmationReducerState): ConfirmationReducerState => ({
  ...state,
  isOpened: false
});

confirmation
  .on(
    actions.BOX_CONFIRMATION_OPEN_MODAL,
    (state, payload): ConfirmationReducerState => ({
      ...state,
      ...payload,
      isOpened: true
    })
  )
  .on(actions.BOX_CONFIRMATION_CLOSE_MODAL, closeHandler)
  .on(actions.BOX_CONFIRMATION_CONFIRM_MODAL_SUCCESS, closeHandler);
