import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Moment } from 'moment';
import {
  AccountTreeArea,
  AccountTreeRole,
  RosterTimeOff,
  StringMap,
} from 'type';
import { StoreState } from 'state/types';
import { getAreas, getRoles } from 'state/AccountTree';
import { timeFormatSelector } from 'state/Account';
import {
  RosterUnavailabilityCell,
  WithRosterUnavailabilityData,
} from '../../../../../../../../components';
import { shortenTimeFormat } from '../../../../../../../../helpers';
import { SERVER_DAY_FORMAT, SERVER_TIME_FORMAT, TIME_FORMAT } from 'lib/config';
import { getTimeFormattedShortenedWithoutZeros } from 'lib/helpers';
import { isAppMarket } from '../../../../../../../../../../helpers';
import { DayViewLeaveCard } from 'page/Roster/components/UnavailabilityCard/DayViewLeaveCard';

type OwnProps = {
  data: RosterTimeOff;
  day: Moment;
};

type StateProps = {
  roles: StringMap<AccountTreeRole>;
  areas: StringMap<AccountTreeArea>;
  timeFormat: string;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

class DayUserUnavailabilityCellComponent extends Component<Props> {
  private readonly limits = {
    start: this.props.day.clone().startOf('day'),
    end: this.props.day.clone().hours(23).minutes(59),
  };

  render() {
    const { data } = this.props;

    if (data.type === 'rostered_shift') {
      return (
        <WithRosterUnavailabilityData
          area_id={data.area_id}
          role_id={data.role_id}
        >
          {({ title, areaName, noPermissions }) => (
            <RosterUnavailabilityCell
              type={data.type}
              title={title}
              areaName={areaName}
              duration={{
                from: this.formatTime(data.start),
                to: this.formatTime(data.end),
              }}
              noPermissions={noPermissions}
            />
          )}
        </WithRosterUnavailabilityData>
      );
    }

    if (data.type === 'unavailability') {
      return (
        <RosterUnavailabilityCell
          type={data.type}
          duration={this.leaveDuration}
          options={this.getOptions()}
          timeFormat={this.props.timeFormat}
        />
      );
    }

    return (
      <DayViewLeaveCard id={data.id} day={this.props.day.clone().format('x')} />
    );
  }

  private get leaveDuration(): undefined | { from: string; to: string } {
    const { data } = this.props;

    const from = this.limitByTime(data.start);
    const to = this.limitByTime(data.end);

    if (from.isSame(this.limits.start) && to.isSame(this.limits.end)) {
      return;
    }

    return {
      from: this.formatTime(from),
      to: this.formatTime(to),
    };
  }

  private limitByTime = (value: Moment) => {
    if (value.isBefore(this.limits.start)) {
      return this.limits.start.clone();
    } else if (value.isAfter(this.limits.end)) {
      return this.limits.end.clone();
    } else {
      // is between
      return value;
    }
  };

  getOptions = () => {
    const { options, start, end } = this.props.data;
    const { day } = this.props;
    const settings = {
      half_start: false,
      half_end: false,
    };
    if (options && isAppMarket('au')) {
      if (
        end.format(SERVER_TIME_FORMAT) === '23:59' &&
        end.format(SERVER_DAY_FORMAT) === day.format(SERVER_DAY_FORMAT)
      ) {
        settings.half_end = options.half_end;
      }
      if (
        start.format(SERVER_TIME_FORMAT) === '00:00' &&
        start.format(SERVER_DAY_FORMAT) === day.format(SERVER_DAY_FORMAT)
      ) {
        settings.half_start = options.half_start;
      }
    }
    return settings;
  };

  private formatTime(time: Moment) {
    const { timeFormat } = this.props;
    if (timeFormat === TIME_FORMAT.hours_12) {
      const format = 'h:mm a';
      return getTimeFormattedShortenedWithoutZeros(time.format(format));
    }
    return shortenTimeFormat(time.format(timeFormat));
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  roles: getRoles(state),
  areas: getAreas(state),
  timeFormat: timeFormatSelector(state),
});

const mapDispatchToProps: DispatchProps = {};

export const DayUserUnavailabilityCell = connect(
  mapStateToProps,
  mapDispatchToProps
)(DayUserUnavailabilityCellComponent);
