import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { FilterListIcon, SwitcherButton } from 'element';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { BOX_ROSTER_BULK_DELETION_MODE_DEACTIVATE } from 'state/Roster/BulkActions';
import {
  BOX_ROSTER_FILTERS_CLEAR_FILTER_FORM,
  BOX_ROSTER_FILTERS_RESET_CUSTOM_FILTERS,
  BOX_ROSTER_FILTERS_SUBMIT_FILTER_FORM,
  filtersFormFiltersByTypeSelector,
  isFiltersChangedSelector,
  isFiltersFormDisabledSelector,
  RosterFiltersByType,
} from 'state/Roster/RosterFilters';
import { BOX_SUMMARY_GET_DATA } from 'state/Roster/Summary';
import { StoreState } from 'state/types';
import { SafeDictionary } from 'ts-essentials';
import { BOX_COVID_TRACING_MODE_UNSET_SHIFT } from 'state/Roster/CovidTracing';
import { CustomFiltersModal, TimesheetButton } from './components';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
    },
    checkboxSelect: {
      flexGrow: 1,
      border: '1px solid #c4c7d0!important',
      minWidth: 155,
      marginBottom: 0,
      marginRight: -1,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      '& .react-select__control': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      '& .react-select__control--is-focused': {
        boxShadow: 'none',
      },
    },
    switcherButton: {
      boxShadow: 'none!important',
      borderTopLeftRadius: '0!important',
      borderBottomLeftRadius: '0!important',
    },
    switcherRoundedButton: {
      boxShadow: 'none!important',
      marginLeft: '3%',
    },
  })
);

export type OwnProps = {
  className?: string;
  classes?: SafeDictionary<string, 'root' | 'checkboxSelect'>;
};

export type StateProps = {
  isCustomFiltersDisabled: boolean;
  isCustomFiltersChanged: boolean;
  filtersByTypeFromForm: RosterFiltersByType;
};

export type DispatchProps = {
  updateCustomFilters: () => void;
  clearCustomFilters: () => void;
  resetCustomFilters: () => void;
  getSummaryData: () => void;
  deactivateBulkDeleteMode: () => void;
  unsetCovidShift: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

export const RosterViewFiltersComponent = ({
  deactivateBulkDeleteMode,
  updateCustomFilters,
  resetCustomFilters,
  getSummaryData,
  clearCustomFilters,
  isCustomFiltersDisabled,
  isCustomFiltersChanged,
  filtersByTypeFromForm,
  className,
  classes: propsClasses,
  unsetCovidShift,
}: Props) => {
  const classes = useStyles();
  const [isCustomModalOpened, setIsCustomModalOpened] = useState(false);

  const afterFiltersUpdate = () => {
    deactivateBulkDeleteMode();
    getSummaryData();
    unsetCovidShift();
  };

  const openModal = () => {
    setIsCustomModalOpened(true);
  };

  const closeModal = () => {
    setIsCustomModalOpened(false);
    clearCustomFilters();
  };

  const handleSubmit = () => {
    setIsCustomModalOpened(false);
    updateCustomFilters();
    afterFiltersUpdate();
  };

  const handleReset = () => {
    setIsCustomModalOpened(false);
    resetCustomFilters();
    afterFiltersUpdate();
  };

  // added class names "roster-filters" related to cypress, can be removed when data-testid will be used
  return (
    <>
      <div
        data-testid={'roster-filters'}
        className={clsx(
          'roster-filters',
          classes.root,
          propsClasses?.root,
          className
        )}
      >
        <TimesheetButton afterUpdate={afterFiltersUpdate} />
        <SwitcherButton
          bordered
          className={classes.switcherRoundedButton}
          icon={<FilterListIcon />}
          isActive={isCustomFiltersChanged}
          onClick={openModal}
        />
      </div>

      <CustomFiltersModal
        isOpened={isCustomModalOpened}
        onClose={closeModal}
        onSubmit={handleSubmit}
        onReset={handleReset}
        isResetDisabled={!isCustomFiltersChanged}
        isSubmitDisabled={isCustomFiltersDisabled}
      />
    </>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  isCustomFiltersDisabled: isFiltersFormDisabledSelector(state),
  isCustomFiltersChanged: isFiltersChangedSelector(state),
  filtersByTypeFromForm: filtersFormFiltersByTypeSelector(state),
});

const mapDispatchToProps: DispatchProps = {
  clearCustomFilters: BOX_ROSTER_FILTERS_CLEAR_FILTER_FORM,
  updateCustomFilters: BOX_ROSTER_FILTERS_SUBMIT_FILTER_FORM,
  resetCustomFilters: BOX_ROSTER_FILTERS_RESET_CUSTOM_FILTERS,
  deactivateBulkDeleteMode: BOX_ROSTER_BULK_DELETION_MODE_DEACTIVATE,
  getSummaryData: BOX_SUMMARY_GET_DATA,
  unsetCovidShift: BOX_COVID_TRACING_MODE_UNSET_SHIFT,
};

export const RosterViewFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(RosterViewFiltersComponent);
