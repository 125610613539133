import React from 'react';
import { useSelector } from 'react-redux';
import { every, identity, negate } from 'lodash';
import { getIsApprovalModeEnabled } from 'state/Roster/Roster';
import { getIsCovidModeEnabled } from 'state/Roster/CovidTracing';
import { getIsBulkDeleteOpened } from 'state/Roster/BulkActions';
import { useHandleClickContextMenu as useCardHandleClickContextMenu } from '../hooks';

const useHandleClickContextMenu = ():
  | React.MouseEventHandler<HTMLElement>
  | undefined => {
  const isCovidModeEnabled = useSelector(getIsCovidModeEnabled);
  const isApprovalModeEnabled = useSelector(getIsApprovalModeEnabled);
  const isBulkDeleteOpened = useSelector(getIsBulkDeleteOpened);
  const handleClick = useCardHandleClickContextMenu();

  const isClickable = every(
    [isCovidModeEnabled, isApprovalModeEnabled, isBulkDeleteOpened],
    negate(identity as any)
  );

  if (isClickable) {
    return handleClick;
  }
};

export default useHandleClickContextMenu;
