import React from 'react';
import { CheckedIcon, UncheckedIcon } from './card-icons';
import { IconButtonProps } from 'extended-oxygen-elements';

type SelectIconProps = IconButtonProps & {
  selected: boolean;
};

export function SelectIcon({ selected, ...restProps }: SelectIconProps) {
  const Component = selected ? CheckedIcon : UncheckedIcon;

  return <Component {...restProps} />;
}
