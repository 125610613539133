import React, { Component } from 'react';
import { compact } from 'lodash';
import {
  DeleteOutlineIcon,
  ElmoSaveIcon,
  EventIcon,
  Header,
  MoreHorizIcon,
  SaveAltIcon,
  SaveAsIcon,
  TuneOutlinedIcon,
} from 'element';
import { ReportExportFormat, ReportExportSubFormat } from 'type';

type Props = {
  title: string;
  moveBack: () => void;
  openModal: () => void;
  openEditModal?: () => void;
  updateReport?: () => void;
  openScheduleModal: () => void;
  disableSaveButton?: boolean;
  isEdit: boolean;
  deleteReport: () => void;
  exportFile: (payload: {
    format: ReportExportFormat;
    csv_format?: ReportExportSubFormat;
  }) => void;
  changeTitle: (title: string) => void;
  showPaySauceExport: boolean;
};

export class ReportHeader extends Component<Props> {
  render() {
    const { props } = this;
    return (
      <div className={'view-report-header'}>
        <Header
          title={props.title}
          backButton={{
            title: '',
            onClick: () => props.moveBack()
          }}
          buttons={this.getAllButtons()}
        />
      </div>
    );
  }

  getAllButtons = () => {
    const { props } = this;
    return [
      {
        label: 'Edit Report',
        icon: <TuneOutlinedIcon />,
        onClick: () => {
          if (props.openEditModal) {
            props.openEditModal();
          }
        }
      },
      {
        label: 'Save report',
        icon: <ElmoSaveIcon />,
        onClick: () => {
          if (props.isEdit && props.updateReport) {
            if (!props.disableSaveButton) {
              props.updateReport();
            }
          } else {
            props.openModal();
            props.changeTitle('Save');
          }
        },
        isDisabled: props.disableSaveButton
      },
      {
        icon: <MoreHorizIcon />,
        label: 'More',
        children: this.getButtons(),
        onClick: (e: any) => {
          e.preventDefault();
        }
      }
    ];
  };

  getButtons = () => {
    const { props } = this;
    return compact([
      {
        label: 'Save as',
        icon: <SaveAsIcon />,
        hasDivider: true,
        onClick: () => {
          props.openModal();
          props.changeTitle('Save as');
        }
      },
      {
        label: 'Report schedule',
        icon: <EventIcon />,
        isDisabled: !props.isEdit,
        hasDivider: true,
        onClick: () => {
          props.openScheduleModal();
        }
      },
      {
        label: 'Export to CSV',
        icon: <SaveAltIcon />,
        onClick: () => {
          props.exportFile({
            format: 'csv'
          });
        }
      },
      props.showPaySauceExport && {
        label: 'Export to PaySauce Format',
        icon: <SaveAltIcon />,
        onClick: () => {
          props.exportFile({
            format: 'csv',
            csv_format: 'pay_sauce'
          });
        }
      },
      {
        label: 'Export to PDF',
        icon: <SaveAltIcon />,
        onClick: () => {
          props.exportFile({
            format: 'pdf'
          });
        },
        hasDivider: true
      },
      {
        label: 'Delete report',
        icon: <DeleteOutlineIcon />,
        isDisabled: !props.isEdit,
        onClick: () => {
          props.deleteReport();
        }
      }
    ]);
  };
}

export default ReportHeader;
