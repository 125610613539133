import { createReducer } from 'lib/store-utils';
import { RosterReducerState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';

export const roster = createReducer<RosterReducerState>({}, getDefaultState());

roster.on(actions.BOX_ROSTER_GET_PAGE_DATA_REQUEST, getDefaultState);

roster.on(
  actions.BOX_ROSTER_PUBLISH_CHANGES_REFACTORED,
  (state): RosterReducerState => ({
    ...state,
    isPublishing: true,
    errors: [],
  })
);

roster.on(
  actions.BOX_ROSTER_PUBLISH_CHANGES_SUCCESS,
  (state): RosterReducerState => ({
    ...state,
    isPublishing: false,
  })
);

roster.on(
  actions.BOX_ROSTER_PUBLISH_CHANGES_FAILURE,
  (state, errors): RosterReducerState => ({
    ...state,
    isPublishing: false,
    errors: errors,
  })
);

roster.on(
  actions.BOX_ROSTER_GET_UNPUBLISHED,
  (state): RosterReducerState => ({
    ...state,
    isLoading: true,
    errors: [],
  })
);

roster.on(
  actions.BOX_ROSTER_GET_UNPUBLISHED_SUCCESS,
  (state, response): RosterReducerState => ({
    ...state,
    isLoading: false,
    unpublishedRosters: response,
  })
);

roster.on(
  actions.BOX_ROSTER_GET_UNPUBLISHED_FAILURE,
  (state, errors): RosterReducerState => ({
    ...state,
    isLoading: false,
    errors: errors,
  })
);

roster.on(
  actions.BOX_ROSTER_OPEN_APPLY_CHANGES_MODAL,
  (state, openedModal): RosterReducerState => ({
    ...state,
    openedModal,
  })
);

roster.on(
  actions.BOX_ROSTER_CLOSE_APPLY_CHANGES_MODAL,
  (state): RosterReducerState => ({
    ...state,
    openedModal: null,
    downloadCsvModal: {
      info: '',
      downLoadLink: '',
      message: '',
    },
    generateSuccessMsg: null,
    errors: [],
  })
);

roster.on(
  actions.BOX_ROSTER_APPROVE_MODE_TOGGLE,
  (state): RosterReducerState => ({
    ...state,
    isApproveMode: !state.isApproveMode,
  })
);

roster.on(
  actions.BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN,
  (state, errors): RosterReducerState => ({
    ...state,
    isApproving: false,
    isPublishing: false,
    isOffering: false,
    errorModal: {
      isOpened: true,
      errors: errors,
    },
  })
);

roster.on(
  actions.BOX_ROSTER_GLOBAL_ERROR_MODAL_CLOSE,
  (state): RosterReducerState => ({
    ...state,
    isApproving: false,
    isPublishing: false,
    isOffering: false,
    errorModal: {
      isOpened: false,
      errors: [],
    },
  })
);

roster.on(
  actions.BOX_ROSTER_GET_OFFERS,
  (state): RosterReducerState => ({
    ...state,
    isLoading: true,
    errors: [],
  })
);

roster.on(
  actions.BOX_ROSTER_GET_OFFERS_SUCCESS,
  (state, response): RosterReducerState => ({
    ...state,
    isLoading: false,
    availableOffers: response,
  })
);

roster.on(
  actions.BOX_ROSTER_GET_OFFERS_FAILURE,
  (state, errors): RosterReducerState => ({
    ...state,
    isLoading: false,
    errors: errors,
  })
);

roster.on(
  actions.BOX_ROSTER_OFFERS_CHANGES,
  (state): RosterReducerState => ({
    ...state,
    isOffering: true,
    errors: [],
  })
);

roster.on(
  actions.BOX_ROSTER_OFFERS_CHANGES_SUCCESS,
  (state): RosterReducerState => ({
    ...state,
    isOffering: false,
  })
);

roster.on(
  actions.BOX_ROSTER_OFFERS_CHANGES_FAILURE,
  (state, errors): RosterReducerState => ({
    ...state,
    isOffering: false,
    errors: errors,
  })
);

roster.on(
  actions.BOX_ROSTER_GLOBAL_APPROVE_TIMESHEET_ALL,
  (state): RosterReducerState => ({
    ...state,
    isApproving: true,
  })
);

roster.on(
  actions.BOX_ROSTER_GLOBAL_APPROVE_TIMESHEET_ALL_SUCCESS,
  (state): RosterReducerState => ({
    ...state,
    isApproving: false,
  })
);

roster.on(
  actions.BOX_ROSTER_OVERLAP_CONFIRMATION_OPEN,
  (state, errors): RosterReducerState => ({
    ...state,
    overlapModal: {
      ...state.overlapModal,
      content: errors,
      isOpened: true,
    },
  })
);

roster.on(
  actions.BOX_ROSTER_OVERLAP_CONFIRMATION_CLOSE,
  (state, value): RosterReducerState => ({
    ...state,
    isPublishing: value,
    overlapModal: {
      ...state.overlapModal,
      content: [],
      isOpened: false,
    },
  })
);

roster.on(
  actions.BOX_ROSTER_OPEN_DELETE_CONFIRMATION_MODAL,
  (state, data): RosterReducerState => ({
    ...state,
    confirmDeletionModal: {
      ...state.confirmDeletionModal,
      isOpened: true,
      id: data.id,
      type: data.type,
      errors: [],
    },
  })
);

roster.on(
  actions.BOX_ROSTER_DELETE_EVENT_BY_ID,
  (state): RosterReducerState => ({
    ...state,
    confirmDeletionModal: {
      ...state.confirmDeletionModal,
      isDeleting: true,
    },
  })
);

roster.on(
  actions.BOX_ROSTER_DELETE_SHIFT,
  (state): RosterReducerState => ({
    ...state,
    confirmDeletionModal: {
      ...state.confirmDeletionModal,
      isDeleting: true,
    },
  })
);

roster.on(
  actions.BOX_ROSTER_DELETE_SHIFT_SUCCESS,
  (state): RosterReducerState => ({
    ...state,
    confirmDeletionModal: {
      ...state.confirmDeletionModal,
      isDeleting: false,
      id: '',
      isOpened: false,
    },
  })
);

roster.on(
  actions.BOX_ROSTER_DELETE_SHIFT_FAILUE,
  (state, errors): RosterReducerState => ({
    ...state,
    confirmDeletionModal: {
      ...state.confirmDeletionModal,
      isDeleting: false,
      errors: errors,
    },
  })
);

roster.on(
  actions.BOX_ROSTER_DELETE_CLEAR_ERRORS,
  (state): RosterReducerState => ({
    ...state,
    confirmDeletionModal: {
      ...state.confirmDeletionModal,
      errors: [],
    },
    errors: [],
  })
);

roster.on(
  actions.BOX_ROSTER_CLOSE_DELETE_CONFIRMATION_MODAL,
  (state): RosterReducerState => ({
    ...state,
    confirmDeletionModal: {
      ...state.confirmDeletionModal,
      isOpened: false,
      type: 'rostered_shift',
      id: '',
    },
  })
);

roster.on(
  actions.BOX_ROSTER_TIMESHEETS_CSV_REQUEST,
  (state, data): RosterReducerState => ({
    ...state,
    isGenerating: true,
    downloadCsvModal: {
      info: '',
      downLoadLink: '',
      message: '',
    },
    errors: [],
  })
);

roster.on(
  actions.BOX_ROSTER_TIMESHEETS_CSV_SUCCESS,
  (state, response): RosterReducerState => ({
    ...state,
    downloadCsvModal: {
      ...response,
      message:
        !response.info && !response.downLoadLink
          ? 'There are no timesheets for selected period'
          : '',
    },
    isGenerating: false,
  })
);

roster.on(
  actions.BOX_ROSTER_TIMESHEETS_CSV_FAILURE,
  (state, errors): RosterReducerState => ({
    ...state,
    errors: errors,
    isGenerating: false,
  })
);

roster.on(
  actions.BOX_ROSTER_TIMESHEETS_AUTOGENERATE_REQUEST,
  (state, data): RosterReducerState => ({
    ...state,
    isGenerating: true,
    errors: [],
    generateSuccessMsg: null,
  })
);

roster.on(
  actions.BOX_ROSTER_TIMESHEETS_AUTOGENERATE_SUCCESS,
  (state, isGenerated): RosterReducerState => ({
    ...state,
    isGenerating: false,
    generateSuccessMsg: !isGenerated
      ? null
      : 'Timesheets successfully generated',
  })
);

roster.on(
  actions.BOX_ROSTER_TIMESHEETS_AUTOGENERATE_FAILURE,
  (state, errors): RosterReducerState => ({
    ...state,
    errors: errors,
    isGenerating: false,
    generateSuccessMsg: null,
  })
);
