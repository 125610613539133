import { CloseIcon, WarningAmberOutlinedIcon } from 'element';
import {
  Avatar,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from 'extended-oxygen-elements';
import { useActions, useSelectorWithProps } from 'hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLangPreferences } from 'state/Account';
import {
  BOX_ACCOUNT_TREE_UPDATE_AREA_STATUS,
  getAreaName,
} from 'state/AccountTree';
import {
  PageDialog,
  PageDialogCancel,
  PageDialogErrors,
  PageDialogSubmit,
  usePageDialogStatusEffect,
} from '../../../../../element/page-dialog-components';

type Props = {
  area_id: string;
  open: boolean;
  closeDialog: () => void;
};

export default function DialogArchiveArea({
  closeDialog,
  area_id,
  open,
}: Props) {
  const updateAreaStatusRequest = useActions(
    BOX_ACCOUNT_TREE_UPDATE_AREA_STATUS.request
  );
  const langPreferences = useSelector(getLangPreferences);
  const name = useSelectorWithProps(getAreaName, area_id);

  const title = `Archive ${langPreferences.area.singular}`;

  usePageDialogStatusEffect({
    success: closeDialog,
  });

  const handleSubmit = () => {
    updateAreaStatusRequest({
      area_id,
      archived: true,
    });
  };

  return (
    <PageDialog open={open} maxWidth="xs">
      <DialogTitle
        endAdornment={
          <IconButton onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        }
      >
        <Avatar size="large" color="warning" light aria-label={title}>
          <WarningAmberOutlinedIcon />
        </Avatar>
      </DialogTitle>
      <DialogContent>
        <PageDialogErrors />
        <Typography variant="h5" gutterBottom>
          {title}?
        </Typography>
        <Typography>
          This will archive {langPreferences.area.singular} {name}, please
          confirm that you want to archive this {langPreferences.area.singular}.
        </Typography>
      </DialogContent>
      <DialogActions>
        <PageDialogCancel
          variant="outlined"
          size="medium"
          onClick={closeDialog}
          fullWidth
        >
          Cancel
        </PageDialogCancel>
        <PageDialogSubmit fullWidth variant="contained" onClick={handleSubmit}>
          Archive
        </PageDialogSubmit>
      </DialogActions>
    </PageDialog>
  );
}
