import { createSelector } from 'reselect';
import { StoreState } from 'state/types';
import { groupRostersByAreas } from '../../RosterWeekView';
import { GroupedAreaData } from '../../RosterWeekView/types';

const getSiteRowIndexProp = (
  state: StoreState,
  props: { siteRowIndex: number }
) => props.siteRowIndex;
const getPairRowIndexProp = (
  state: StoreState,
  props: { pairRowIndex: number }
) => props.pairRowIndex;
const getWeekDayIndexProp = (
  state: StoreState,
  props: { weekDayIndex: number }
) => props.weekDayIndex;

export const makeSiteDataItemSelector = () =>
  createSelector(
    groupRostersByAreas,
    getSiteRowIndexProp,
    (groupedData, siteRowIndex) => groupedData[siteRowIndex]
  );

export const makeItemRosterSiteRowNumberSelector = () =>
  createSelector(
    makeSiteDataItemSelector(),
    (dataItem) => dataItem.rowsNumber
  );

export const siteRowsNumberSelector = createSelector(
  groupRostersByAreas,
  (groupedData) => groupedData.length
);

export const makeItemAreaIdRoleIdSelector = () =>
  createSelector(makeSiteDataItemSelector(), (dataItem): {
    area_id: string,
    role_id: string
  } => {
    return {
      area_id: dataItem.area_id,
      role_id: dataItem.role_id
    }
  });

export const makeSiteItemRostersSelector = () =>
  createSelector(makeSiteDataItemSelector(), (dataItem) => dataItem.rosters);

export const makeSiteRostersPairSelector = () =>
  createSelector(
    makeSiteItemRostersSelector(),
    getPairRowIndexProp,
    getWeekDayIndexProp,
    (itemRosters, pairRowIndex, weekDayIndex) =>
      itemRosters[pairRowIndex][weekDayIndex]
  );

export const makeSitePairRosteredShiftIdSelector = () =>
  createSelector(
    makeSiteRostersPairSelector(),
    (rostersPair) => rostersPair.rosteredShiftId
  );

export const makeSitePairTimesheetIdSelector = () =>
  createSelector(
    makeSiteRostersPairSelector(),
    (rostersPair) => rostersPair.timesheetId
  );

export const areaRoleByIdRosterDataSelector = (state: StoreState, areaId: string, roleId: string): GroupedAreaData | undefined  => {
  const data = groupRostersByAreas(state);
  return data.filter(d => d.area_id === areaId && d.role_id === roleId)[0];
};