import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ListTable } from 'elmo-elements';
import { ListTableAction } from 'elmo-elements/ListTable/type';
import { DeleteOutlineIcon, EventIcon, SaveAltIcon } from 'element';
import {
  Report,
  ReportExportFormat,
  ReportExportSubFormat,
  ReportType,
  ReportTypesSelector,
  SortReports,
} from 'type';
import { onSortCreator } from 'lib/helpers';
import Api from 'lib/Api';
import {
  BOX_REPORT_CHANGE_ORDER,
  BOX_REPORTS_ADD_REPORT_IN_DELETE_QUEUE,
  BOX_REPORTS_SCHEDULE_MODAL_OPEN,
} from 'state/Reports';
import SchedulePopover from './components/SchedulePopover';
import { StoreState } from 'state/types';
import Schedule from './components/Schedule';
import { defaultState } from 'state/Report/state';
import {
  getUsePaySauceFormat,
  reportTypesSelector,
} from 'state/Account/selectors';
import { privateRoutes } from 'routes';
import { compact } from 'lodash';

type OwnProps = {
  reports: Report[];
};

type StateProps = {
  sort: SortReports;
  isScheduleOpened: boolean;
  reportTypes: ReportTypesSelector;
  usePaySauceFormat: boolean;
};

type DispatchProps = {
  openScheduleModal: (report: Report) => void;
  deleteReport: (id: string) => void;
  changeOrder: (sort: SortReports) => void;
};

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps;

const COLUMNS: { [key in SortReports['column']]: number } = {
  name: 0,
  type: 2
};

export class ReportsListTable extends Component<Props> {
  render() {
    const { reports } = this.props;

    return (
      <>
        <ListTable
          data={reports}
          actions={this.getActions}
          onSort={this.onSort}
          sortColumn={COLUMNS[this.props.sort.column]}
          sortDirection={this.props.sort.direction}
          onRowClick={this.onRowClick}
        >
          <ListTable.Column
            label="Report title"
            sortable={true}
            property={'name'}
          />
          <ListTable.Column label="Status" render={this.renderStatus} />
          <ListTable.Column
            label="Type"
            render={this.renderType}
            sortable={true}
            property={'type'}
          />
        </ListTable>
        {this.props.isScheduleOpened && (
          <div className={'schedule-modal'}>
            <Schedule />
          </div>
        )}
      </>
    );
  }

  onSort = onSortCreator(this.props.changeOrder, COLUMNS);

  getActions = (report: Report): ListTableAction[] => {
    const { usePaySauceFormat } = this.props;

    return compact([
      {
        icon: <EventIcon />,
        label: 'Report schedule',
        onClick: () => this.reportSchedule(report.id),
        withDivider: true
      },
      {
        icon: <SaveAltIcon />,
        label: 'Export to CSV',
        onClick: () =>
          this.exportTo({
            reportId: report.id,
            format: 'csv'
          })
      },
      report.type === 'timesheet' &&
        usePaySauceFormat && {
          icon: <SaveAltIcon />,
          label: 'Export to PaySauce Format',
          onClick: () =>
            this.exportTo({
              reportId: report.id,
              format: 'csv',
              csv_format: 'pay_sauce'
            })
        },
      {
        icon: <SaveAltIcon />,
        label: 'Export to PDF',
        onClick: () =>
          this.exportTo({
            reportId: report.id,
            format: 'pdf'
          }),
        withDivider: true
      },
      {
        icon: <DeleteOutlineIcon />,
        label: 'Delete report',
        onClick: () => this.deleteReport(report.id, report.type)
      }
    ]);
  };

  renderStatus = (report: Report) => {
    const { schedule } = report;
    return schedule && schedule.is_enabled ? (
      <SchedulePopover schedule={schedule} reportId={report.id} />
    ) : (
      <></>
    );
  };

  renderType = (report: Report) => this.props.reportTypes[report.type].label;

  reportSchedule = (reportId: string) => {
    let report: any = defaultState;
    for (let reportItem of this.props.reports) {
      if (reportItem.id === reportId) {
        report = reportItem;
        break;
      }
    }
    this.props.openScheduleModal(report);
  };

  exportTo = (params: {
    reportId: string;
    format: ReportExportFormat;
    csv_format?: ReportExportSubFormat;
  }) => {
    window.location.href = Api.Reports.generateFileDownloadUrl(params);
  };

  deleteReport = (reportId: string, reportType: ReportType) => {
    this.props.deleteReport(reportId);
  };

  onRowClick = (report: Report) => {
    this.props.history.push(
      privateRoutes.reports.routes.report.path(report.id)
    );
  };
}

const mapDispatchToProps: DispatchProps = {
  openScheduleModal: BOX_REPORTS_SCHEDULE_MODAL_OPEN,
  deleteReport: BOX_REPORTS_ADD_REPORT_IN_DELETE_QUEUE,
  changeOrder: BOX_REPORT_CHANGE_ORDER
};

const mapStateToProps = (state: StoreState): StateProps => ({
  sort: state.reports.sort,
  isScheduleOpened: state.reports.scheduleModal.isOpened,
  reportTypes: reportTypesSelector(state),
  usePaySauceFormat: getUsePaySauceFormat(state)
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReportsListTable)
);
