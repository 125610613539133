import { SHIFT_DURATION_LIMIT_MIN } from 'lib/config';
import { formatDuration } from 'lib/helpers';
import { Moment } from 'moment';
import { DateRangeErrors } from '../types';

export const dateRangeValidator = ({
  startMoment,
  endMoment,
}: {
  startMoment: Moment;
  endMoment: Moment;
}): DateRangeErrors => {
  let errors: DateRangeErrors = {};

  if (endMoment.isSameOrBefore(startMoment)) {
    errors.end = 'Timesheet end must be after timesheet start';
  } else if (
    endMoment.diff(startMoment, 'minutes') > SHIFT_DURATION_LIMIT_MIN
  ) {
    errors.end = `Timesheet must be shorter than ${formatDuration(
      SHIFT_DURATION_LIMIT_MIN
    )}`;
  }

  return errors;
};

export default dateRangeValidator;
