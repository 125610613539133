import React from 'react';
import { useUpdateEffect } from 'hooks';
import { useSelector } from 'react-redux';
import {
  getPayElementFormRequestStatus,
  getPayElementFormUpdating,
} from 'state/PayElementsPage';
import { usePayElementsListContext } from '../../pay-elements-list-context';
import FormComponent, { FormComponentProps, FormComponentFormPayload } from './FormComponent';

type Props = Pick<FormComponentProps, 'onSubmit' | 'initialState'>;
export type PayElementFormPayload = FormComponentFormPayload;

export default function PayElementForm(props: Props) {
  const loading = useSelector(getPayElementFormUpdating);
  const requestStatus = useSelector(getPayElementFormRequestStatus);
  const { close } = usePayElementsListContext();

  useUpdateEffect(() => {
    if (requestStatus === 'success' || requestStatus === 'cancel') {
      close();
    }
  }, [requestStatus]);

  const handleClose = () => {
    close();
  };

  return <FormComponent {...props} loading={loading} onClose={handleClose} />;
}
