import React, { MouseEvent, useState } from 'react';
import './styles.scss';
import { EventNotesIcon } from 'element/icons';
import { DialogTitle } from 'oxygen-elements';
import { PageDialog, PageDialogCancel } from 'element';
import { DialogContent, DialogActions } from 'extended-oxygen-elements';

type Props = {
  notes: string | null;
  styles?: {};
  customLabel?: boolean;
  id?: string;
  onClick?: () => void;
};

export const NotesIcon = ({
  notes,
  styles,
  customLabel = false,
  id = 'notes-icon',
  onClick,
}: Props) => {
  const [isOpened, toggleModal] = useState(false);
  const label = customLabel ? 'Shift note' : 'Note';

  const preventNavigationOnListItem = (list: null | Element) => {
    if (list) {
      setTimeout(() => {
        const btns = document.querySelectorAll('#notes-modal button');
        if (btns) {
          btns.forEach((btn) => {
            btn.addEventListener('click', (e) => {
              e.preventDefault();
              e.stopPropagation();
              toggleModal(false);
            });
          });
        }
      });
    }
  };

  const showNotesModal = (e: MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    const list = e.currentTarget.closest('.elmo-listtable__row-wrapper');
    if (notes) {
      toggleModal(true);
      preventNavigationOnListItem(list);
      if (onClick) {
        onClick();
      }
    }
  };

  const closeModal = () => toggleModal(false);

  const modal = () => (
    <PageDialog
      id={'notes-modal'}
      maxWidth="xs"
      open={isOpened}
      onClose={closeModal}
    >
      <DialogTitle>Notes</DialogTitle>
      <DialogContent>{notes}</DialogContent>
      <DialogActions>
        <PageDialogCancel onClick={closeModal}>Close</PageDialogCancel>
      </DialogActions>
    </PageDialog>
  );

  return (
    <div
      data-test-id={id}
      style={styles}
      className={'notes-icon-wrapper' + (!notes ? ' disabled' : '')}
    >
      <span onClick={showNotesModal} className={'notes-icon'}>
        <EventNotesIcon /> <span>{!notes ? `${label}s` : `+1 ${label}`}</span>
      </span>
      {modal()}
    </div>
  );
};
