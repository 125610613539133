import { createStyles, makeStyles } from '@mui/styles';
import { FormattedTime, ReportProblemOutlinedIcon } from 'element';
import { IconButton, IconButtonProps, Tooltip } from 'extended-oxygen-elements';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
  useSelectorWithProps,
} from 'hooks';
import _ from 'lodash';
import { marketLabels } from 'marketLabels';
import { Box, Popover, Typography } from 'extended-oxygen-elements';
import {
  useIsTimesheetPunchInViolated,
  useIsTimesheetPunchOutViolated,
} from 'page/TimesheetsWeekly/hooks';
import React from 'react';
import { getRosteredShift } from 'state/RosteredShiftsCollection';
import { RosterTimesheetListedWithPayEntries } from 'type';

type Props = IconButtonProps & {
  timesheet: RosterTimesheetListedWithPayEntries;
};

const allViolationMessages = {
  in: `Clock on-${marketLabels.roster} violation`,
  out: `Clock off-${marketLabels.roster} violation`,
};

const usePopoverStyles = makeStyles(() =>
  createStyles({
    paper: {
      marginTop: 0,
    },
  })
);

export function ViolationIconButton(props: Props) {
  const { timesheet, ...restProps } = props;
  const popoverClasses = usePopoverStyles();
  const popupState = usePopupState();
  const punchInViolated = useIsTimesheetPunchInViolated(timesheet);
  const punchOutViolated = useIsTimesheetPunchOutViolated(timesheet);

  const violationMessages = _.compact([
    punchInViolated && allViolationMessages.in,
    punchOutViolated && allViolationMessages.out,
  ]);

  const relatedRosteredShift = useSelectorWithProps(
    getRosteredShift,
    timesheet.rostered_shift_id!
  );

  return (
    <>
      <Tooltip
        title={
          <>
            {violationMessages.map((violationMessage) => (
              <div key={violationMessage}>{violationMessage}</div>
            ))}
          </>
        }
        placement="top"
      >
        <IconButton
          {...bindTrigger({
            ...popupState,
            open: (e) => {
              popupState.open(e);
            },
          })}
          {...restProps}
          data-testid={'card-violation-icon'}
        >
          <ReportProblemOutlinedIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>

      <Popover classes={popoverClasses} {...bindPopover(popupState)}>
        <Box p={2}>
          <Typography variant="h6">Shift violation</Typography>

          <div>
            <Typography component="span" variant="body2">
              Start:{' '}
            </Typography>

            <Tooltip title={punchInViolated && allViolationMessages.in}>
              <Typography
                component="span"
                variant="body2"
                color={punchInViolated ? 'error' : undefined}
              >
                <FormattedTime>{relatedRosteredShift.start}</FormattedTime>
                {punchInViolated && '*'}
              </Typography>
            </Tooltip>
          </div>

          <div>
            <Typography component="span" variant="body2">
              Finish:{' '}
            </Typography>

            <Tooltip title={punchOutViolated && allViolationMessages.out}>
              <Typography
                component="span"
                variant="body2"
                color={punchOutViolated ? 'error' : undefined}
              >
                <FormattedTime>{relatedRosteredShift.end}</FormattedTime>
                {punchOutViolated && '*'}
              </Typography>
            </Tooltip>
          </div>
        </Box>
      </Popover>
    </>
  );
}
