import React from 'react';
import { Box, Typography } from 'extended-oxygen-elements';
import { IconButton, Popover } from 'extended-oxygen-elements';
import { bindPopover, bindTrigger, usePopupState } from 'hooks';
import { CoronavirusOutlinedIcon, HelpOutlineOutlinedIcon } from 'element';

type Props = {
  size?: 'medium' | 'large',
  className?: string;
};

export const HelpIcon = ({ size = 'medium', className = '' }: Props) => {
  const popupState = usePopupState({
    popupId: 'CovidTracingHelpPopover',
  });

  return (
    <>
      <IconButton data-test-id={'covid-help-icon-button'} size={size} {...bindTrigger(popupState)} className={className}>
        <HelpOutlineOutlinedIcon fontSize="inherit" />
      </IconButton>

      <Popover
        {...bindPopover(popupState)}
        placement="bottom-end"
      >
        <Box display="flex" p={4} pl={2} maxWidth={425}>
          <CoronavirusOutlinedIcon />

          <Box pl={2}>
            <Box mb={2}>
              <Typography variant="h4">Close contact tracing</Typography>
            </Box>

            <Typography>
              If someone in your team tests positive for COVID-19,
              you can easily trace who they were working with and create a useful report.
            </Typography>
          </Box>
        </Box>
      </Popover>
    </>
  );
};
