import { createReducer } from 'lib/store-utils';
import { FormattedErrors } from 'type';
import { TimesheetsAwardsReducerState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';

export const TimesheetsAwards = createReducer<TimesheetsAwardsReducerState>(
  {},
  getDefaultState()
);

const requestHandler = (
  state: TimesheetsAwardsReducerState
): TimesheetsAwardsReducerState => ({
  ...state,
  errors: []
});

const failureHandler = (
  state: TimesheetsAwardsReducerState,
  errors: FormattedErrors
): TimesheetsAwardsReducerState => ({
  ...state,
  errors
});

TimesheetsAwards.on(
  actions.BOX_TIMESHEETS_AWARDS_MODAL_OPENED,
  (state, currentTimesheet): TimesheetsAwardsReducerState => ({
    ...getDefaultState(),
    isAwardsModalOpened: true,
    currentTimesheet,
    selectedHigherDutyId: currentTimesheet.higher_duty
      ? currentTimesheet.higher_duty.id
      : ''
  })
);

TimesheetsAwards.on(
  actions.BOX_TIMESHEETS_AWARDS_MODAL_REQUEST,
  requestHandler
);

TimesheetsAwards.on(
  actions.BOX_TIMESHEETS_AWARDS_MODAL_SUCCESS,
  (state, { higherDuties }): TimesheetsAwardsReducerState => ({
    ...state,
    higherDuties
  })
);

TimesheetsAwards.on(
  actions.BOX_TIMESHEETS_AWARDS_MODAL_CLOSED,
  (state): TimesheetsAwardsReducerState => ({
    ...state,
    isAwardsModalOpened: false
  })
);

TimesheetsAwards.on(
  actions.BOX_TIMESHEETS_AWARDS_MODAL_RECALCULATE_REQUEST,
  (state): TimesheetsAwardsReducerState => ({
    ...requestHandler(state),
    isCurrentTimesheetChanged: false
  })
);

TimesheetsAwards.on(
  actions.BOX_TIMESHEETS_AWARDS_MODAL_RECALCULATE_SUCCESS,
  (state, partialTimesheet): TimesheetsAwardsReducerState => ({
    ...state,
    currentTimesheet: {
      ...state.currentTimesheet,
      ...partialTimesheet
    },
    isCurrentTimesheetChanged: true
  })
);

TimesheetsAwards.on(
  actions.BOX_TIMESHEETS_AWARDS_MODAL_RECALCULATE_FAILURE,
  failureHandler
);

TimesheetsAwards.on(
  actions.BOX_TIMESHEETS_AWARDS_MODAL_ERRORS_CLEARED,
  (state): TimesheetsAwardsReducerState => ({
    ...state,
    errors: []
  })
);

TimesheetsAwards.on(
  actions.BOX_TIMESHEETS_AWARDS_MODAL_SELECTED_HIGHER_DUTY_ID_CHANGED,
  (state, selectedHigherDutyId): TimesheetsAwardsReducerState => ({
    ...state,
    selectedHigherDutyId
  })
);

TimesheetsAwards.on(
  actions.BOX_TIMESHEETS_AWARDS_MODAL_UPDATE_REQUEST,
  requestHandler
);

TimesheetsAwards.on(
  actions.BOX_TIMESHEETS_AWARDS_MODAL_UPDATE_SUCCESS,
  (state): TimesheetsAwardsReducerState => ({
    ...state,
    isAwardsModalOpened: false
  })
);

TimesheetsAwards.on(
  actions.BOX_TIMESHEETS_AWARDS_MODAL_UPDATE_FAILURE,
  failureHandler
);
