import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  BOX_ROSTER_CONTEXTUAL_MENU_CLOSE,
  BOX_ROSTER_CONTEXTUAL_MENU_CLOSE_AND_SET_PROPS,
  BOX_ROSTER_CONTEXTUAL_MENU_COPY_ROSTERED_SHIFT,
  BOX_ROSTER_CONTEXTUAL_MENU_COPY_SHIFT_ITEM,
  BOX_ROSTER_CONTEXTUAL_MENU_CUT_ROSTERED_SHIFT,
  BOX_ROSTER_CONTEXTUAL_MENU_CUT_SHIFT_ITEM,
  BOX_ROSTER_CONTEXTUAL_MENU_PASTE_AND_OPEN,
  BOX_ROSTER_CONTEXTUAL_MENU_PASTE_ITEM_AND_OPEN,
  BOX_ROSTER_CONTEXTUAL_MENU_PASTE_ROSTERED_SHIFT,
  BOX_ROSTER_CONTEXTUAL_MENU_PASTE_SHIFT_ITEM,
  BOX_ROSTER_CONTEXTUAL_MENU_REPLACE_IN_MODAL,
  BOX_ROSTER_CONTEXTUAL_MENU_REPLACE_ITEM_IN_MODAL,
  BOX_ROSTER_CONTEXTUAL_MENU_SET_INPROGRESS,
} from './actions';
import {
  getAllSelectors,
  getClipboardMsg,
  getCombinedTemplateData,
  getCombinedValidationData,
  getLastAction,
  getObjectId,
} from './selectors';
import { getRosteredShift } from '../../RosteredShifts';
import {
  AccountTreeArea,
  AccountTreeRole,
  RosteredShift,
  ShiftTemplateItem,
} from '../../../type/models';
import moment, { Moment } from 'moment';
import { RosteredShiftCreateRequest, UserListResponse } from 'lib/Api/type';
import {
  BOX_ROSTERED_SHIFT_CREATE,
  BOX_ROSTERED_SHIFT_UPDATE,
} from '../../RosteredShifts/actions';
import { BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN } from '../Roster/actions';
import { GetRosterByIDPayload } from '../RosterShiftModal/types';
import {
  BOX_ROSTER_SHIFT_MODAL_GET_BY_ID,
  BOX_ROSTER_SHIFT_MODAL_PASTE_DATA,
} from '../RosterShiftModal';

import { BOX_TOAST_NOTIFIER_MESSAGE_SHOW } from '../../ToastNotifier/actions';
import { getDateFormat, timeFormatSelector } from 'state/Account/selectors';
import {
  getDateTimeFormatted,
  getOptionsForCurrentUser,
  updateRosterShiftBreaksOnDateChange,
} from 'lib/helpers';
import { StringMap } from '../../../type/common';
import { getShiftItemById, getTemplateId } from '../EditTemplate/selectors';
import {
  BOX_EDIT_TEMPLATE_CREATE_SHIFT_ITEM,
  BOX_EDIT_TEMPLATE_EDIT_SHIFT_ITEM,
  BOX_EDIT_TEMPLATE_MODAL_PASTE_DATA,
  BOX_EDIT_TEMPLATE_OPEN_EDIT_MODAL,
} from '../EditTemplate/actions';
import { isSiteView } from '../../../helpers';

const getShiftDate = function* (data: RosteredShift): SagaIterator {
  const time = yield select(timeFormatSelector);
  const date = yield select(getDateFormat);
  const start = getDateTimeFormatted(date, time, data.start, true);
  const end = getDateTimeFormatted(date, time, data.end, true);
  return `${start} - ${end}`;
};

let validationData: {
  users: UserListResponse,
  areas: StringMap<AccountTreeArea>,
  roles: StringMap<AccountTreeRole>,
  areasBySiteId: StringMap<AccountTreeArea[]>,
  siteId: string
} = {
  users: {},
  areas: {},
  roles: {},
  areasBySiteId: {},
  siteId: ''
};

const putState = function*(
  action: 'copy' | 'cut', data: RosteredShift | null
): SagaIterator {
  yield put(BOX_ROSTER_CONTEXTUAL_MENU_CLOSE_AND_SET_PROPS({
    clipboard: data,
    action: action
  }));
};

const copyRosteredShift = function*(): SagaIterator {
  const id = yield select(getObjectId);
  if ( id ) {
    const data = yield select(getRosteredShift, id);
    yield call(putState, 'copy', data);
    const shiftDate = yield call(getShiftDate, data);
    yield put(BOX_TOAST_NOTIFIER_MESSAGE_SHOW(`Copied: ${shiftDate}`));
  }
};

const copyShiftItem = function*(): SagaIterator {
  const id = yield select(getObjectId);
  if ( id ) {
    const data = yield select(getShiftItemById, id);
    yield call(putState, 'copy', data);
    const msg = yield select(getClipboardMsg);
    yield put(BOX_TOAST_NOTIFIER_MESSAGE_SHOW(msg));
  }
};

const cutRosteredShift = function*(): SagaIterator {
  const id = yield select(getObjectId);
  if ( id ) {
    const data = yield select(getRosteredShift, id);
    yield call(putState, 'cut', data);
    const shiftDate = yield call(getShiftDate, data);
    yield put(BOX_TOAST_NOTIFIER_MESSAGE_SHOW(`Cut: ${shiftDate}`));
  }
};

const cutShiftItem = function*(): SagaIterator {
  const id = yield select(getObjectId);
  if ( id ) {
    const data = yield select(getShiftItemById, id);
    yield call(putState, 'cut', data);
    const msg = yield select(getClipboardMsg);
    yield put(BOX_TOAST_NOTIFIER_MESSAGE_SHOW(msg));
  }
};

const updateDate = (date: Moment, day: Moment) => {
  return moment.parseZone(date).set({
    date: +moment.parseZone(day).format('D'),
    year: +moment.parseZone(day).format('YYYY'),
    month: (+moment.parseZone(day).format('M') - 1)
  });
};

const getView = () => {
  return !isSiteView() ? 'user' : 'site';
};

const isUserHasRole = (users: UserListResponse, userId: string, areaId: string, roleId: string) => {
  const { user_roles } = users[userId];
  let hasRole = false;
  user_roles.forEach((r => {
    if ( r.area_id === areaId && r.role_id === roleId ) {
      hasRole = true;
    }
  }));
  return hasRole;
};

export const userIdToPaste = (
  users: UserListResponse,
  spot: Partial<RosteredShift> | Partial<ShiftTemplateItem>,
  userId: string | null,
  areaId: string,
  roleId: string
) => {
  if ( !userId ) {
    return null;
  }
  const hasRole = isUserHasRole(users, userId, areaId!, roleId!);
  return hasRole ? userId : null;
}

const getAreaRoleUserFromPayload = (
  params: {
    area_id: string,
    role_id: string,
    spot: Partial<RosteredShift> | Partial<ShiftTemplateItem>,
    user_id: string | null
  }) => {
  let roleId = '';
  let areaId = '';
  const { users } = validationData;
  const { area_id, role_id, user_id, spot } = params;
  let userId = typeof spot.user_id !== 'undefined' &&  spot.user_id !== '' ? spot.user_id : user_id;
  if ( getView() === 'user' ) {
    areaId = area_id ? area_id : spot.area_id!;
    roleId = role_id ? role_id : spot.role_id!;
  } else {
    areaId = spot.area_id ? spot.area_id : area_id;
    roleId = spot.role_id ? spot.role_id : role_id;
    userId = userIdToPaste(users, spot, user_id, areaId, roleId);
  }
  return {
    roleId,
    areaId,
    userId
  }
}

export const updatePastedPayload = (
  clipboard: RosteredShift,
  spot: Partial<RosteredShift>,
  inModal: boolean = false
): RosteredShiftCreateRequest => {
  const { id, start, end, breaks, area_id, role_id, user_id, event_id, tags, timesheet_id, notes, ...rest } = clipboard;
  const spotStartDateStr = spot.start!.format('YYYY-MM-DD');
  const spotEndDateStr = spot.end ? spot.end.format('YYYY-MM-DD') : spot.start!.format('YYYY-MM-DD');

  let startDate = updateDate(start, spot.start!);
  let endDate = inModal ? updateDate(end, spot.start!) : updateDate(end, spot.end ? spot.end : spot.start!);

  if ( spotStartDateStr === spotEndDateStr ) {
    endDate = updateDate(end, spot.end!);
  }

  if ( spotStartDateStr !== spotEndDateStr ) {
    endDate = updateDate(end, spot.start!)
  }

  const f = 'YYYY-MM-DD HH:mm';
  const params = getAreaRoleUserFromPayload({ area_id, role_id, spot, user_id });

  return {
    start: startDate,
    end: startDate.format(f) >= endDate.format(f) ? moment(endDate).add(1, 'day') : endDate,
    area_id: params.areaId,
    role_id: params.roleId,
    user_id: params.userId,
    breaks: updateRosterShiftBreaksOnDateChange(startDate, breaks!),
    event_id: event_id,
    template_id: rest.template_id!,
    tags: tags,
    notes: notes
  };
};

export const updatePastedShiftItemPayload = (
  clipboard: ShiftTemplateItem,
  spot: Partial<ShiftTemplateItem>
): ShiftTemplateItem => {
  const { area_id, role_id, user_id, day, ...rest } = clipboard;
  const params = getAreaRoleUserFromPayload({ area_id, role_id, spot, user_id });
  return {
    ...rest,
    area_id: params.areaId,
    role_id: params.roleId,
    user_id: params.userId,
    day: spot.day!
  };
};

const validateByAvailableRoles = (shiftToPaste: RosteredShift, userId: string) => {
  const { users, areas, roles, areasBySiteId, siteId } = validationData;
  const shiftAreaRole = `${shiftToPaste.area_id}__${shiftToPaste.role_id}`;
  if ( users[userId] ) {
    const userOptions = getOptionsForCurrentUser(areasBySiteId[siteId], areas, roles, users[userId]);
    for (let option of userOptions) {
      if (option.value === shiftAreaRole) {
        return true;
      }
    }
  }
  return false;
};

const validateByAvailableAreaRoles = (
  shiftToPaste: RosteredShift,
  cellData: Partial<RosteredShift> | Partial<ShiftTemplateItem>
) => {
  const { users, areas, roles, areasBySiteId, siteId } = validationData;
  const cellAreaRole = `${cellData.area_id}__${cellData.role_id}`;
  if ( typeof cellData.user_id === 'undefined' || !cellData.area_id || !cellData.role_id ) {
    return false;
  }
  if ( shiftToPaste.user_id === null ) {
    return true;
  }
  if ( areasBySiteId && siteId && users && areasBySiteId[siteId] ) {
    const userOptions = getOptionsForCurrentUser(areasBySiteId[siteId], areas, roles, users[shiftToPaste.user_id]);
    for (let option of userOptions) {
      if (option.value === cellAreaRole) {
        return true;
      }
    }
  }
  return false;
};

const validateByUser = (shiftToPaste: RosteredShift, spot: Partial<RosteredShift>) => {
  const userID = spot.user_id === 'null' ? null : spot.user_id;
  return !userID || (shiftToPaste.user_id === userID) ? true : validateByAvailableRoles(shiftToPaste, userID);
}

const validateByAreaRole = (shiftToPaste: RosteredShift, cellData: Partial<RosteredShift>) => {
  return (!cellData.area_id || !cellData.role_id)
    ? false
    : validateByAvailableAreaRoles(shiftToPaste, cellData);
};

const validateShift = (shiftToPaste: RosteredShift, spot: Partial<RosteredShift>) => {
  return getView() === 'user'
    ? validateByUser(shiftToPaste, spot)
    : validateByAreaRole(shiftToPaste, spot);
};

export const getStateData = function*(isTemplate: boolean = false): SagaIterator {
  validationData = yield select(!isTemplate ? getCombinedValidationData : getCombinedTemplateData);
  return yield select(getAllSelectors);
}

export const pasteRosteredShift = function*(): SagaIterator {
  const { shiftData, cellData, objectId, overlapPayload } = yield call(getStateData);
  const action = yield select(getLastAction);

  if ( shiftData ) {
    const data = cellData === null ? yield select(getRosteredShift, objectId) : cellData;

    if ( !validateShift(shiftData, data) ) {
      yield put(BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN(['User doesn\'t have assigned role!']));
      yield put(BOX_ROSTER_CONTEXTUAL_MENU_CLOSE());
      return false;
    }

    const payload = updatePastedPayload(shiftData, data);

    yield put(BOX_ROSTER_CONTEXTUAL_MENU_SET_INPROGRESS(true));

    if ( action === 'cut' ) {
      yield put(BOX_ROSTERED_SHIFT_UPDATE({
        ...payload,
        id: shiftData.id,
        need_re_accept: true,
        ignore_errors: overlapPayload.ignoreErrors
      }));
    } else {
      yield put(BOX_ROSTERED_SHIFT_CREATE({
        ...payload,
        ignore_errors: overlapPayload.ignoreErrors,
        notes: null
      }))
    }

    yield put(BOX_ROSTER_CONTEXTUAL_MENU_CLOSE());
  }

};

export const pasteShiftItem = function*(): SagaIterator {
  const { shiftData, cellData, objectId } = yield call(getStateData, true);
  const action = yield select(getLastAction);
  const templateId = yield select(getTemplateId);

  if ( shiftData ) {
    const data = cellData === null ? yield select(getShiftItemById, objectId) : cellData;
    if ( !validateShift(shiftData, data) ) {
      yield put(BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN(['User doesn\'t have assigned role!']));
      yield put(BOX_ROSTER_CONTEXTUAL_MENU_CLOSE());
      return false;
    }

    const payload = updatePastedShiftItemPayload(shiftData, data);

    yield put(BOX_ROSTER_CONTEXTUAL_MENU_SET_INPROGRESS(true));

    if ( action === 'cut' ) {
      yield put(BOX_EDIT_TEMPLATE_EDIT_SHIFT_ITEM({
        ...payload,
        id: shiftData.id,
        ignore_errors: false,
        templateId: templateId,
        is_pasted: true
      }));
    } else {
      yield put(BOX_EDIT_TEMPLATE_CREATE_SHIFT_ITEM({
        ...payload,
        templateId: templateId,
        ignore_errors: false,
        is_pasted: true
      }));
    }

    yield put(BOX_ROSTER_CONTEXTUAL_MENU_CLOSE());
  }

};

const pasteAndOpenModal = function*(): SagaIterator {
  const { shiftData, cellData, objectId } = yield call(getStateData);
  const action = yield select(getLastAction);

  if ( shiftData ) {
    const data = cellData === null ? yield select(getRosteredShift, objectId) : cellData;
    const payload = updatePastedPayload(shiftData, data);

    yield put(BOX_ROSTER_SHIFT_MODAL_GET_BY_ID({
      ...payload,
      id: 'paste_edit',
      menu_action: {
        id: shiftData.id,
        action: action
      },
      notes: action === 'copy' ? null : payload.notes
    } as GetRosterByIDPayload));

    yield put(BOX_ROSTER_CONTEXTUAL_MENU_CLOSE());
  }
};

const pasteItemAndOpenModal = function*(): SagaIterator {
  const { shiftData, cellData, objectId } = yield call(getStateData, true);
  const action = yield select(getLastAction);

  if ( shiftData ) {
    const data = cellData === null ? yield select(getShiftItemById, objectId) : cellData;
    const payload = updatePastedShiftItemPayload(shiftData, data);

    yield put(BOX_EDIT_TEMPLATE_OPEN_EDIT_MODAL({
      ...payload,
      id: action === 'cut' ? payload.id : null,
      paste_edit: true
    }));

    yield put(BOX_ROSTER_CONTEXTUAL_MENU_CLOSE());
  }
};

const updateDataInsideModal = function*(): SagaIterator {
  const { shiftData, cellData, objectId } = yield call(getStateData);
  const action = yield select(getLastAction);
  if ( shiftData ) {
    const data = cellData === null ? yield select(getRosteredShift, objectId) : cellData;
    const payload = updatePastedPayload(shiftData, data, true);
    yield put(BOX_ROSTER_SHIFT_MODAL_PASTE_DATA({
      ...payload,
      id: action === 'cut' ? shiftData.id : objectId,
      breaks: payload.breaks!,
      is_pasted: true,
      notes: action === 'copy' ? null : payload.notes
    }));
    yield put(BOX_ROSTER_CONTEXTUAL_MENU_CLOSE());
  }
};

const updateItemInsideModal = function*(): SagaIterator {
  const { shiftData, cellData, objectId } = yield call(getStateData, true);
  const action = yield select(getLastAction);
  if ( shiftData ) {
    const data = cellData === null ? yield select(getShiftItemById, objectId) : cellData;
    const payload = updatePastedShiftItemPayload(shiftData, data);
    yield put(BOX_EDIT_TEMPLATE_MODAL_PASTE_DATA({
      ...payload,
      id: action === 'cut' ? shiftData.id : null,
      is_pasted: true,
      is_modal: true,
      paste_edit: true
    }));
    yield put(BOX_ROSTER_CONTEXTUAL_MENU_CLOSE());
  }
};

export const watchRosterContextualMenu = function*(): SagaIterator {
  yield takeLatest(BOX_ROSTER_CONTEXTUAL_MENU_COPY_ROSTERED_SHIFT, copyRosteredShift);
  yield takeLatest(BOX_ROSTER_CONTEXTUAL_MENU_CUT_ROSTERED_SHIFT, cutRosteredShift);
  yield takeLatest(BOX_ROSTER_CONTEXTUAL_MENU_PASTE_ROSTERED_SHIFT, pasteRosteredShift);
  yield takeLatest(BOX_ROSTER_CONTEXTUAL_MENU_PASTE_AND_OPEN, pasteAndOpenModal);
  yield takeLatest(BOX_ROSTER_CONTEXTUAL_MENU_REPLACE_IN_MODAL, updateDataInsideModal);

  yield takeLatest(BOX_ROSTER_CONTEXTUAL_MENU_COPY_SHIFT_ITEM, copyShiftItem);
  yield takeLatest(BOX_ROSTER_CONTEXTUAL_MENU_CUT_SHIFT_ITEM, cutShiftItem);
  yield takeLatest(BOX_ROSTER_CONTEXTUAL_MENU_PASTE_SHIFT_ITEM, pasteShiftItem);
  yield takeLatest(BOX_ROSTER_CONTEXTUAL_MENU_PASTE_ITEM_AND_OPEN, pasteItemAndOpenModal);
  yield takeLatest(BOX_ROSTER_CONTEXTUAL_MENU_REPLACE_ITEM_IN_MODAL, updateItemInsideModal);
};
