import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { WithChildren } from 'type';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  })
);

export default InlineInputWrapper;

export function InlineInputWrapper({ children }: WithChildren) {
  const classes = useStyles();

  return (
    <>
      {' '}
      <div className={classes.root}>{children}</div>
      {' '}
    </>
  );
}
