import { DataTableContextProps } from './type';
import React from 'react';
import LayoutContext, { LayoutContextProps } from '../LayoutContext';
import DataTableContext from './DataTableContext';

export type DataTableConsumerProps = Partial<
  DataTableContextProps & LayoutContextProps
>;

/**
 * Passes the DataTable and Layout Context to the component that this wraps.
 * @param WrappedComponent
 */
export const withDataTableContext = <P extends DataTableConsumerProps>(
  WrappedComponent: React.ComponentType<P>
): React.ComponentClass<any> => {
  return class extends React.Component<P> {
    static displayName: string = WrappedComponent.displayName
      ? WrappedComponent.displayName
      : '';

    render() {
      return (
        <LayoutContext.Consumer>
          {(layoutProps: any) => (
            <DataTableContext.Consumer>
              {(dataTableProps: any) => (
                <WrappedComponent
                  {...layoutProps}
                  {...dataTableProps}
                  {...this.props}
                />
              )}
            </DataTableContext.Consumer>
          )}
        </LayoutContext.Consumer>
      );
    }
  };
};
