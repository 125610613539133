import React from 'react';
import { Select, FormContainer, FormItem } from 'elmo-elements';
import { Data, DropDownProps } from '../type';
import {
  groupByColumns,
  columnTypes,
  timeIntervals,
} from 'state/Report/reportModalValues';
import { getSelectedValue } from '../helpers';

export type DataProps = {
  handleOnChangeSelect: (e: DropDownProps, name: string) => void;
  data: Data;
};

const CoverageExtraGroup = ({ data, handleOnChangeSelect }: DataProps) => {
  return (
    <FormContainer maxColumns={3}>
      <FormItem label={'Group by'} isFluid={false}>
        <Select
          name={'group_by'}
          options={groupByColumns}
          onChange={(e) => handleOnChangeSelect(e, 'group_by')}
          defaultValue={getSelectedValue(groupByColumns, data.group_by)}
          value={getSelectedValue(groupByColumns, data.group_by)}
          ariaLabel="Group by"
        />
      </FormItem>
      <FormItem label={'Time interval'} isFluid={false}>
        <Select
          name={'interval'}
          options={timeIntervals}
          onChange={(e) => handleOnChangeSelect(e, 'interval')}
          defaultValue={getSelectedValue(timeIntervals, data.interval)}
          value={getSelectedValue(timeIntervals, data.interval)}
          ariaLabel="Time interval"
        />
      </FormItem>
      <FormItem label={'Type'} isFluid={false}>
        <Select
          name={'datatype'}
          options={columnTypes}
          onChange={(e) => handleOnChangeSelect(e, 'datatype')}
          defaultValue={getSelectedValue(columnTypes, data.datatype)}
          value={getSelectedValue(columnTypes, data.datatype)}
          ariaLabel="Type"
        />
      </FormItem>
    </FormContainer>
  );
};

export default CoverageExtraGroup;
