import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Moment } from 'moment';
import { timeFormatSelector } from 'state/Account';
import { formatTime } from '../../helpers';

export const useFormatTime = (time: Moment | null) => {
  const timeFormat = useSelector(timeFormatSelector);

  return useMemo(() => {
    if (!time) {
      return '';
    }
    return formatTime(time, timeFormat);
  }, [time?.format(), timeFormat]);
};
