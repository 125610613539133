import { Dictionary } from 'ts-essentials';
import _ from 'lodash';

type WithId = {
  id: string;
};

type Props<PassedItem extends WithId, CollectedItem extends WithId> = {
  handler: (collection: Dictionary<CollectedItem>) => void;
  itemHandler?: (passedItem: PassedItem) => CollectedItem;
};

type Options = {
  collectionLength: number;
  collectionDelay: number;
};

const defaultOptions: Options = {
  collectionLength: 100,
  collectionDelay: 2 * 1000,
};

export default makeDelayedHandler;

export function makeDelayedHandler<P extends WithId, C extends WithId = P>(
  { handler, itemHandler = _.identity }: Props<P, C>,
  options: Partial<Options> = {}
) {
  const { collectionLength, collectionDelay } = _.defaults(
    options,
    defaultOptions
  );
  let timeout: NodeJS.Timeout;
  let collection: Dictionary<C> = {};

  const getDelay = () => {
    if (Object.keys(collection).length < collectionLength) {
      return collectionDelay;
    }

    return 0;
  };

  return (item: P) => {
    clearTimeout(timeout);

    const collectedItem = itemHandler(item);
    collection[collectedItem.id] = collectedItem;

    timeout = setTimeout(() => {
      handler(collection);
      collection = {};
    }, getDelay());
  };
}
