import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import { isDstSpringForwardSelector } from 'state/Roster/RosterDayView';

type OwnProps = {
  children: ReactNode;
};

type StateProps = {
  isDstSpringForward: boolean;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

const IsDstSpringForwardComponent = ({ isDstSpringForward, children }: Props) =>
  isDstSpringForward ? <>{children}</> : null;

const mapStateToProps = (state: StoreState): StateProps => ({
  isDstSpringForward: isDstSpringForwardSelector(state)
});

// const mapDispatchToProps: DispatchProps = {};

export const IsDstSpringForward = connect(mapStateToProps)(
  IsDstSpringForwardComponent
);
