import { Dialog, DialogProps } from 'extended-oxygen-elements';
import { useActions } from 'hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  BOX_ACCOUNT_TREE_DIALOG_OPENED,
  getDialogUpdating,
} from 'state/AccountTree';

export type PageDialogProps = Omit<DialogProps, 'loading'>;

export function PageDialog(props: PageDialogProps) {
  const dialogOpened = useActions(BOX_ACCOUNT_TREE_DIALOG_OPENED);
  const loading = useSelector(getDialogUpdating);

  const { open } = props;
  React.useEffect(() => {
    if (open) {
      dialogOpened();
    }
  }, [open, dialogOpened]);

  return <Dialog {...props} loading={loading} />;
}
