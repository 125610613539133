import React from 'react';
import { connect } from 'react-redux';
import { Moment } from 'moment';
import classNames from 'clsx';
import { StoreState } from 'state/types';
import { fromBySiteTimezoneSelector } from 'state/RosteredShifts';
import {
  oneMinuteInPxSelector,
  timelineWidthSelector,
} from 'state/Roster/RosterDayView';
import './ShiftBreak.scss';

type OwnProps = {
  start: Moment;
  duration: number; // minutes
  paid: boolean;
  shiftStart: Moment;
};

type StateProps = {
  from: Moment;
  oneMinuteInPx: number;
  timelineWidth: number;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

const ShiftBreakComponent = (props: Props) => {
  const { paid } = props;
  const { startPx, endPx } = getLeftRightCoordsPxCropped(props);

  const breakIcon = {
    backgroundImage: `url(${cupIcon()})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  };

  const diff = props.start.diff(props.shiftStart, 'minutes');
  if ( props.duration < 15 || diff < 95 ) {
    return null;
  }

  return (
    <div
      className={
        classNames('shift-break', {
          'shift-break--paid': paid,
          'shift-break--cup': true
        })}
      style={{
        left: startPx,
        width: endPx - startPx,
        ...breakIcon
      }}
    />
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  from: fromBySiteTimezoneSelector(state),
  timelineWidth: timelineWidthSelector(state),
  oneMinuteInPx: oneMinuteInPxSelector(state)
});

// const mapDispatchToProps: DispatchProps = {};

export const ShiftBreak = connect(mapStateToProps)(ShiftBreakComponent);

function getLeftRightCoordsPxCropped(props: Props) {
  const { startPx, endPx } = getLeftRightCoordsPx(props);

  return {
    startPx: getCropCoordinatePx(props.timelineWidth, startPx),
    endPx: getCropCoordinatePx(props.timelineWidth, endPx)
  };
}

function getLeftRightCoordsPx(props: Props) {
  const { start, duration, from, oneMinuteInPx } = props;

  const startMins = start.diff(from, 'minutes');

  const startPx = oneMinuteInPx * startMins;
  const widthPx = oneMinuteInPx * duration;
  const endPx = startPx + widthPx;

  return { startPx, endPx };
}

function getCropCoordinatePx(timelineWidth: number, coordPx: number) {
  let croppedCoord;
  if (coordPx < 0) {
    croppedCoord = 0;
  } else if (coordPx > timelineWidth) {
    croppedCoord = timelineWidth;
  } else {
    croppedCoord = coordPx;
  }

  return croppedCoord;
}

function cupIcon() {
  const svg = window.btoa(
    `<svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M1.66668 0H12.3333C13.0733 0 13.6667 0.593333 13.6667
         1.33333V3.33333C13.6667 4.07333 13.0733 4.66667 12.3333 4.66667H11V6.66667C11 
         8.14 9.80668 9.33333 8.33334 9.33333H4.33334C2.86001 9.33333 1.66668 8.14 1.66668 
         6.66667V0ZM9.66668 6.66667V1.33333H3.00001V6.66667C3.00001 7.4 3.60001 8 4.33334 
         8H8.33334C9.06668 8 9.66668 7.4 9.66668 6.66667ZM11 3.33333V1.33333H12.3333V3.33333H11ZM0.333344 
         10.6667H12.3333V12H0.333344V10.6667Z" 
        fill="black" 
        fill-opacity="0.45"
      />
    </svg>`);
  return `data:image/svg+xml;base64,${svg}`;
}
