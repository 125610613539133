import { FlatAreaRoleModalReducerState } from './types';

export const getDefaultState = (): FlatAreaRoleModalReducerState => ({
  isOpened: false,
  isResetButtonEnabled: false,
  selectedAreaIds: [],
  selectedRoleIds: [],
  siteId: null,
  archivedAreaIdsToBeShown: [],
  archivedRoleIdsToBeShown: []
});
