import {
  breaksFieldGroupUtils,
  dateRangeFieldGroupUtils,
} from 'element';
import { CreateFormFields } from './createFormFields';

export default makePayload;

export function makePayload({
  role: { role_id, area_id },
  notes,
  breaks,
  ...dateRangeFields
}: CreateFormFields) {
  if (!dateRangeFieldGroupUtils.isValidDateRangeGuard(dateRangeFields)) {
    return;
  }
  const dateRange = dateRangeFieldGroupUtils.getDateRange(dateRangeFields);
  const { start, end } = dateRange;

  return {
    role_id,
    area_id,
    start,
    end,
    notes,
    breaks: breaksFieldGroupUtils.getValidBreaks(breaks, dateRange),
  };
}
