import { useSelectorWithProps } from 'hooks';
import { userTimesheetsGridDayRowSelector } from 'state/TimesheetsWeeklyPage';
import useTimesheetsNewQueryStatus from './useTimesheetsNewQueryStatus';

type Props = {
  userId: string;
  rowIndex: number;
  day: string;
};

export function useUserTimesheetsGridDayRow(props: Props) {
  const status = useTimesheetsNewQueryStatus();

  return useSelectorWithProps(userTimesheetsGridDayRowSelector, {...props, status});
}

export default useUserTimesheetsGridDayRow;
