import { AlertErrorBoxLayout, FormattedDate } from 'element';
import {
  DataTable as Table,
  DescriptionList,
  Divider,
  Heading,
} from 'elmo-elements';
import { isAppMarket } from 'helpers';
import { getPreferenceLabel } from 'lib/helpers';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLangPreferences } from 'state/Account';
import {
  BOX_AUDIT_TRAIL_CLEAR_ERRORS,
  BOX_AUDIT_TRAIL_PAGE_UPDATE,
  getAuditDataSelector,
  getAuditErrors,
  getAuditPagerSelector,
} from 'state/AuditTrail';
import { Shift } from 'type';
import PopoverWrapper from './components/PopoverWrapper';

const columns = [
  {
    title: 'Action',
    sortable: false,
  },
  {
    title: 'User',
    sortable: false,
  },
  {
    title: 'Details',
    sortable: false,
  },
  {
    title: 'Date',
    sortable: false,
  },
  {
    width: '50px',
  },
];

export const AuditTrailView = () => {
  const auditTrail = useSelector(getAuditDataSelector);
  const errors = useSelector(getAuditErrors);
  const pager = useSelector(getAuditPagerSelector);
  const langPreferences = useSelector(getLangPreferences);

  const dispatch = useDispatch();

  const formatActionLabel = (action: string) => {
    if (isAppMarket('uk') && action === 'rosteredshift') {
      return 'shift';
    }
    if (isAppMarket('uk') && action === 'roster') {
      return 'rota';
    }

    return action;
  };

  const onPageChange = (currentPage: number) => {
    dispatch(
      BOX_AUDIT_TRAIL_PAGE_UPDATE({
        ...pager,
        currentPage,
      })
    );
  };

  const onPageSizeChange = (pageSize: number) => {
    dispatch(
      BOX_AUDIT_TRAIL_PAGE_UPDATE({
        pageSize,
        currentPage: 1,
      })
    );
  };

  const getShiftData = (shift: Shift) => {
    return (
      <>
        <DescriptionList.Item label={`ID`}>{shift.id}</DescriptionList.Item>
        <Divider /> <br />
        {shift.shift_data ? (
          <>
            <DescriptionList.Item label={`Shift ID`}>
              {shift.shift_data.id}
            </DescriptionList.Item>
            <DescriptionList.Item
              label={getPreferenceLabel(
                langPreferences,
                'site',
                'singular',
                '',
                true
              )}
            >
              {shift.shift_data.site_name}
            </DescriptionList.Item>
            <DescriptionList.Item
              label={getPreferenceLabel(
                langPreferences,
                'area',
                'singular',
                '',
                true
              )}
            >
              {shift.shift_data.area_name}
            </DescriptionList.Item>
            <DescriptionList.Item
              label={getPreferenceLabel(
                langPreferences,
                'role',
                'singular',
                '',
                true
              )}
            >
              {shift.shift_data.role_name}
            </DescriptionList.Item>
            <DescriptionList.Item label="User">
              {shift.shift_data.user_name}
            </DescriptionList.Item>
            <DescriptionList.Item label="Date">
              <div>
                <FormattedDate showTime showLocalTime>
                  {shift.shift_data.start}
                </FormattedDate>{' '}
                –
              </div>
              <FormattedDate showTime showLocalTime>
                {shift.shift_data.end}
              </FormattedDate>
            </DescriptionList.Item>
            <DescriptionList.Item label="Updated by">
              {shift.shift_data.updatedBy ? shift.shift_data.updatedBy : '-'}
            </DescriptionList.Item>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  const renderId = (shift: Shift) => {
    return <DescriptionList.Item label="ID">{shift.id}</DescriptionList.Item>;
  };

  const renderPopoverContent = (shift: Shift) => {
    const { action } = shift;
    const actionTypes = ['swap', 'offer', 'rosteredshift', 'timesheet'];

    return (
      <>
        {actionTypes.indexOf(action) !== -1 ? getShiftData(shift) : ''}
        {actionTypes.indexOf(action) === -1 ? renderId(shift) : ''}
      </>
    );
  };

  return (
    <>
      <AlertErrorBoxLayout
        errors={errors}
        onClose={() => dispatch(BOX_AUDIT_TRAIL_CLEAR_ERRORS())}
        BoxProps={{
          mb: 2,
        }}
      />

      {auditTrail.data.length ? (
        <Table id="audit-trail-table" isFullWidth={true} isResponsive={true}>
          <Table.Header columns={columns} />
          <Table.Body>
            {auditTrail.data.map((audit: Shift, key: number) => (
              <Table.Tr key={key}>
                <Table.Td className="capitalize">
                  {formatActionLabel(audit.action)}
                </Table.Td>
                <Table.Td className="audit-username">
                  {audit.user_name}
                </Table.Td>
                <Table.Td>{audit.description}</Table.Td>
                <Table.Td>
                  <FormattedDate showTime showLocalTime>
                    {audit.created_at}
                  </FormattedDate>
                </Table.Td>
                <Table.Td>
                  <PopoverWrapper>
                    <DescriptionList style="align-vertical">
                      {renderPopoverContent(audit)}
                    </DescriptionList>
                  </PopoverWrapper>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Body>
          <Table.Pagination
            pageSize={pager.pageSize}
            currentPage={pager.currentPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            totalResults={auditTrail.total}
          />
        </Table>
      ) : (
        <Heading>Your search did not match any records.</Heading>
      )}
    </>
  );
};

export default AuditTrailView;
