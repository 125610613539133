import moment, { Moment } from 'moment-timezone';
import {
  DayDetails,
  EmployeeUnavailabilityProps,
  TimeOffRosteredShift,
} from 'state/EmployeeDashboard/Unavailability/types';
import _ from 'lodash';
import { isAppMarket } from 'helpers';
import { SERVER_DAY_FORMAT, SERVER_TIME_FORMAT } from 'lib/config';
import { getLeaveSubtypeLabel } from 'lib/helpers';

type MixedTimeOffs = EmployeeUnavailabilityProps[] & TimeOffRosteredShift[];

export const daysInMonth = (currentDate: Moment) => {
  return currentDate.daysInMonth();
};

export const getFirstDayOfMonth = (currentDate: Moment) => {
  const dayNum = +moment(currentDate).startOf('month').format('E');
  return dayNum - 1;
};

export const getDateOfMonth = (date: Moment, iDay: number = 1) => {
  return +moment(date).set('date', iDay).format('D');
};

export const getDateOfPrevMonth = (currentDate: Moment, currentDay: number) => {
  const daysInPrevMonth = daysInMonth(currentDate);
  return {
    year: +currentDate.format('Y'),
    month: +currentDate.format('M'),
    day: getDateOfMonth(currentDate, daysInPrevMonth - currentDay),
  };
};

export const getDateOfNextMonth = (day: number, currentDate: Moment) => {
  return {
    year: +currentDate.format('Y'),
    month: +currentDate.format('M'),
    day: getDateOfMonth(currentDate, day),
  };
};

const getCalendarArray = (rows: number, days: number) => {
  let calendar: any[] = new Array(days);
  for (let i = 0; i < rows; i++) {
    calendar[i] = new Array(7);
  }
  return calendar;
};

const pushCardDetails = (
  details: MixedTimeOffs | null,
  dataItem: EmployeeUnavailabilityProps | TimeOffRosteredShift,
  date: Moment
) => {
  if (details === null) {
    details = [];
  }
  if (dataItem.type === 'leave' && isAppMarket('au')) {
    const getTimeOffOptions = () => {
      const options = {
        half_start: false,
        half_end: false,
      };
      if (dataItem.options && !Array.isArray(dataItem.options)) {
        const { half_end, half_start } = dataItem.options;
        if (
          dataItem.end.format(SERVER_TIME_FORMAT) === '23:59' &&
          dataItem.end.format(SERVER_DAY_FORMAT) ===
            date.format(SERVER_DAY_FORMAT)
        ) {
          options.half_end = half_end;
        }
        if (
          dataItem.start.format(SERVER_TIME_FORMAT) === '00:00' &&
          dataItem.start.format(SERVER_DAY_FORMAT) ===
            date.format(SERVER_DAY_FORMAT)
        ) {
          options.half_start = half_start;
        }
      }
      return options;
    };
    details.push({
      ...(dataItem as any),
      options: getTimeOffOptions(),
    });
  } else {
    details.push(dataItem as any);
  }
  return details;
};

const getData = (
  dataItem: EmployeeUnavailabilityProps | TimeOffRosteredShift,
  result: null | MixedTimeOffs,
  date: string
) => {
  const f = 'D-M-Y';
  const day = +moment(date, f).format('x');
  const start = +moment(moment(dataItem.start).format(f), f).format('x');
  const end = +moment(moment(dataItem.end).format(f), f).format('x');
  const isStartDate = start === day && end !== day;
  const isEndDate = day >= start && day <= end;
  const isInRange = isStartDate || isEndDate;
  if (isInRange && dataItem.type !== 'rostered_shift') {
    result = pushCardDetails(result, dataItem, moment(date, f));
  }
  if (dataItem.type === 'rostered_shift') {
    const endsOnMidnight = moment(dataItem.end).format('HH:mm') === '00:00';
    const isStartDayOfShift = !isStartDate && isEndDate && !endsOnMidnight;
    if (isStartDayOfShift || (isStartDate && isEndDate)) {
      result = pushCardDetails(result, dataItem, moment(date, f));
    }
  }
  return result;
};

export const getUnavailabilityByDate = (
  date: string,
  data: EmployeeUnavailabilityProps[],
  shifts: TimeOffRosteredShift[]
): MixedTimeOffs | null => {
  let result: null | MixedTimeOffs = null;
  for (let dataItem of data) {
    result = getData(dataItem, result, date);
  }
  for (let shift of shifts) {
    result = getData(shift, result, date);
  }
  return result;
};

export const isToday = (
  day: number,
  currentDate: Moment,
  tz: string = 'Australia/Sydney'
) => {
  const formattedDate = moment
    .tz(tz)
    .set({
      date: day,
    })
    .format('Y-MM-D');
  return formattedDate === currentDate.clone().format('Y-MM-D');
};

export const buildCalendar = (
  currentDate: Moment,
  unavailabilities: EmployeeUnavailabilityProps[],
  shifts: TimeOffRosteredShift[],
  timezone: string
) => {
  const rows = 6;
  const days = 7;
  const calendar = getCalendarArray(rows, days);
  const firstDayIndex = getFirstDayOfMonth(currentDate);
  const prevMonthDate = moment(currentDate).subtract(1, 'month');
  const nextMonthDate = moment(currentDate).add(1, 'month');

  let futureDay = 1;
  let date = 1;

  for (let i = 0; i < rows; i += 1) {
    for (let j = 0; j < days; j += 1) {
      if (i === 0 && j < firstDayIndex) {
        // fill with prev dates
        const prevDates = getDateOfPrevMonth(
          prevMonthDate,
          firstDayIndex - 1 - j
        );
        calendar[i][j] = { ...prevDates, period: 'previous', isToday: false };
      } else if (date > daysInMonth(currentDate)) {
        // fill with future dates
        const nextDates = getDateOfNextMonth(futureDay++, nextMonthDate);
        calendar[i][j] = { ...nextDates, period: 'future', isToday: false };
      } else {
        // fill current month dates
        const m = currentDate.format('M');
        const y = currentDate.format('Y');
        calendar[i][j] = {
          period: 'current',
          details: getUnavailabilityByDate(
            `${date}-${m}-${y}`,
            unavailabilities,
            shifts
          ),
          isToday: isToday(date, currentDate, timezone),
          month: m,
          year: y,
          day: date,
        };
        date++;
      }
    }
  }

  // do not show week of next month if there is no at least one day of current month
  if (calendar[rows - 1][0].period === 'future') {
    delete calendar[rows - 1];
  }

  return calendar;
};

export const divideByType = (details: null | MixedTimeOffs) => {
  const shifts: TimeOffRosteredShift[] = [];
  const timeOffs: EmployeeUnavailabilityProps[] = [];

  type StatusesPriority = {
    [key: string]: number;
  };

  const statuses: StatusesPriority = {
    declined: 0,
    pending: 1,
    approved: 2,
  };
  if (details !== null && details) {
    details.map((d: EmployeeUnavailabilityProps | TimeOffRosteredShift) => {
      if (d.type === 'rostered_shift') {
        shifts.push(d as TimeOffRosteredShift);
      } else {
        (d as EmployeeUnavailabilityProps).order_id = statuses[d.status];
        timeOffs.push(d as EmployeeUnavailabilityProps);
      }
    });
  }
  return {
    shift: shifts,
    timeOffs: _.orderBy(timeOffs, ['order_id'], ['asc']),
  };
};

export const isCasualContractor = (type: string) => {
  return type !== 'Full time' && type !== 'Part time';
};

export const showOriginalStatus = (status: string, type: string) => {
  const statuses = ['Approved', 'Processing', 'Processed'];
  return type === 'leave' && statuses.indexOf(status) !== -1;
};

export const userCanSubmitUnavailability = (params: {
  day: number;
  month: number;
  year: number;
  canEditUnavailability: boolean;
  employeeType: string;
}) => {
  const { day, employeeType, canEditUnavailability, year, month } = params;
  const date = moment(`${year}-${month}-${day}`, 'Y-M-DD')
    .startOf('day')
    .format('x');
  const current = moment().startOf('day').format('x');
  return date >= current && canEditUnavailability;
};

type StatusKeys = {
  [key: string]: {
    [key: string]: boolean;
  };
};

export const getDefaultStatuses = () => {
  let statuses: StatusKeys = {
    unavailability: {
      approved: false,
      pending: false,
      declined: false,
    },
    leave: {
      approved: false,
      pending: false,
      declined: false,
    },
  };

  return statuses;
};

export const getStatusClassName = (statuses: StatusKeys) => {
  const { unavailability, leave } = statuses;
  if (unavailability.declined || leave.declined) {
    return unavailability.declined ? 'declined' : 'leave-declined';
  }
  if (unavailability.pending || leave.pending) {
    return unavailability.pending ? 'pending' : 'leave-pending';
  }
  if (unavailability.approved || leave.approved) {
    return unavailability.approved ? 'approved' : 'leave-approved';
  }
  return 'work';
};

const halfDay = (label: 'AM' | 'PM') => {
  return `Half day ${isAppMarket('au') ? '' : label}`;
};

export const getLeaveCardLabel = (
  timeOff: EmployeeUnavailabilityProps,
  { day, month, year }: DayDetails
) => {
  const { start, end, options } = timeOff;
  const date = moment.tz(timeOff.site.timezone_id).set({
    date: +day,
    month: +month - 1,
    year: +year,
  });

  const isFirstDay = start.isSame(date, 'day');
  const isLastDay = end.isSame(date, 'day');

  const label = {
    title: getLeaveSubtypeLabel(timeOff.subtype, true),
    subTitle: 'All day',
  };

  if (options && !Array.isArray(options)) {
    const { half_start, half_end } = options;
    if (isFirstDay && half_start) {
      label.subTitle = halfDay('PM');
      return label;
    }
    if (isLastDay && half_end) {
      label.subTitle = halfDay('AM');
      return label;
    }
  }

  return label;
};
