import React from 'react';
import { Merge } from 'ts-essentials';
import { TextField, TextFieldProps } from 'extended-oxygen-elements';
import { Minutes } from 'type';
import { useTimeField } from 'hooks';

type TimeFieldProps = Merge<TextFieldProps, {
  value: Minutes | null;
  onValueChange: (newValue: Minutes | null) => void;
}>;

export const TimeField = React.forwardRef(function TimeField(
  props: TimeFieldProps,
  ref: React.Ref<HTMLDivElement>
) {
  const { value, onValueChange, ...restProps } = props;
  const textFieldTimeProps = useTimeField(props);

  return <TextField {...restProps} {...textFieldTimeProps} ref={ref} />;
});

export default TimeField;
