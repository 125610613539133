import { createReducer } from 'lib/store-utils';
import { ReportsReducerState, getDefaultState } from './state';
import * as actions from './actions';
import { without, omit } from 'lodash';

export const reports = createReducer<ReportsReducerState>(
  {},
  getDefaultState()
);

reports.on(
  actions.BOX_REPORTS_GET_REPORTS_REQUEST,
  (): ReportsReducerState => ({
    ...getDefaultState(),
    isFetching: true
  })
);

reports.on(
  actions.BOX_REPORTS_GET_REPORTS_SUCCESS,
  (state, fetchedReports): ReportsReducerState => {
    return {
      ...state,
      isFetching: false,
      reports: fetchedReports
    };
  }
);

reports.on(
  actions.BOX_REPORTS_GET_REPORTS_FAILURE,
  (state, errors): ReportsReducerState => ({
    ...state,
    isFetching: false,
    errors
  })
);

reports.on(
  actions.BOX_REPORTS_CHANGE_PAGE,
  (state, currentPage): ReportsReducerState => ({
    ...state,
    pager: {
      ...state.pager,
      currentPage
    }
  })
);

reports.on(actions.BOX_REPORTS_CHANGE_PAGE_SIZE, (state, pageSize) => ({
  ...state,
  pager: {
    ...state.pager,
    pageSize,
    currentPage: 1
  }
}));

reports.on(
  actions.BOX_REPORTS_SAVE_MODAL_OPEN,
  (state, report): ReportsReducerState => ({
    ...state,
    saveAsModal: {
      ...state.saveAsModal,
      errors: [],
      isOpened: true,
      report
    }
  })
);

reports.on(
  actions.BOX_REPORTS_SAVE_MODAL_CLOSE,
  (state): ReportsReducerState => ({
    ...state,
    saveAsModal: {
      ...state.saveAsModal,
      isOpened: false,
      title: 'Save report as'
    }
  })
);

reports.on(
  actions.BOX_REPORTS_SAVE_REPORT_REQUEST,
  (state): ReportsReducerState => ({
    ...state,
    isLoading: true,
    saveAsModal: {
      ...state.saveAsModal,
      errors: []
    },
    errors: []
  })
);

reports.on(
  actions.BOX_REPORTS_SAVE_REPORT_SUCCESS,
  (state, updatedReport): ReportsReducerState => ({
    ...state,
    isLoading: false,
    reports: {
      ...state.reports,
      [updatedReport.id]: updatedReport
    },
    saveAsModal: {
      ...state.saveAsModal,
      isOpened: false
    }
  })
);

reports.on(
  actions.BOX_REPORTS_SAVE_REPORT_FAILURE,
  (state, errors): ReportsReducerState => ({
    ...state,
    isLoading: false,
    saveAsModal: {
      ...state.saveAsModal,
      errors
    }
  })
);

reports.on(
  actions.BOX_REPORTS_SCHEDULE_MODAL_OPEN,
  (state, report): ReportsReducerState => ({
    ...state,
    scheduleModal: {
      ...state.scheduleModal,
      errors: [],
      isOpened: true,
      report
    }
  })
);

reports.on(
  actions.BOX_REPORTS_SCHEDULE_MODAL_CLOSE,
  (state): ReportsReducerState => ({
    ...state,
    scheduleModal: {
      ...state.scheduleModal,
      isOpened: false
    }
  })
);

reports.on(
  actions.BOX_REPORTS_CREATE_MODAL_OPEN,
  (state): ReportsReducerState => ({
    ...state,
    createModal: {
      isOpened: true,
      redirectOnSuccess: false
    },
    errors: []
  })
);

reports.on(
  actions.BOX_REPORTS_CREATE_MODAL_CLOSE,
  (state): ReportsReducerState => ({
    ...state,
    createModal: {
      isOpened: false,
      redirectOnSuccess: false
    },
    errors: []
  })
);

reports.on(
  actions.BOX_REPORTS_CREATE_REDIRECT_MODAL_CLOSE,
  (state): ReportsReducerState => ({
    ...state,
    createModal: {
      isOpened: false,
      redirectOnSuccess: true
    },
    errors: []
  })
);

reports.on(
  actions.BOX_REPORTS_SCHEDULE_UPDATE_REQUEST,
  (state): ReportsReducerState => ({
    ...state,
    isLoading: true,
    scheduleModal: {
      ...state.scheduleModal,
      errors: []
    }
  })
);
reports.on(
  actions.BOX_REPORTS_SCHEDULE_UPDATE_SUCCESS,
  (state, { id, schedule }): ReportsReducerState => ({
    ...state,
    isLoading: false,
    reports: {
      ...state.reports,
      [id]: {
        ...state.reports[id],
        schedule
      }
    },
    scheduleModal: {
      ...state.scheduleModal,
      isOpened: false
    }
  })
);
reports.on(
  actions.BOX_REPORTS_SCHEDULE_UPDATE_FAILURE,
  (state, errors): ReportsReducerState => ({
    ...state,
    isLoading: false,
    scheduleModal: {
      ...state.scheduleModal,
      errors
    }
  })
);

reports.on(
  actions.BOX_REPORTS_DELETE_SUCCESS,
  (state, { id }): ReportsReducerState => ({
    ...state,
    reports: omit(state.reports, id),
    deleteQueue: without(state.deleteQueue, id)
  })
);

reports.on(
  actions.BOX_REPORTS_CLEAR_ERRORS,
  (state): ReportsReducerState => ({
    ...state,
    errors: []
  })
);

reports.on(
  actions.BOX_REPORTS_DELETE_FAILURE,
  (state, failureParams: { errors: any, id: string }): ReportsReducerState => ({
    ...state,
    reports: omit(state.reports, failureParams.id),
    deleteQueue: [],
    errors: failureParams.errors
  })
);

reports.on(
  actions.BOX_REPORT_CHANGE_ORDER,
  (state, sort): ReportsReducerState => ({
    ...state,
    sort
  })
);

reports.on(
  actions.BOX_REPORTS_SET_SEARCH_TERM,
  (state, searchTerm): ReportsReducerState => ({
    ...state,
    searchTerm: searchTerm,
    pager: {
      ...state.pager,
      currentPage: 1
    }
  })
);

reports.on(
  actions.BOX_REPORTS_ADD_REPORT_IN_DELETE_QUEUE,
  (state, id): ReportsReducerState => ({
    ...state,
    deleteQueue: [...state.deleteQueue, id]
  })
);

reports.on(
  actions.BOX_RESTORE_DELETED_REPORT,
  (state, id): ReportsReducerState => {
    return {
      ...state,
      deleteQueue: without(state.deleteQueue, id)
    };
  }
);

reports.on(
  actions.BOX_REPORTS_CHANGE_SAVE_AS_TITLE,
  (state, title): ReportsReducerState => ({
    ...state,
    saveAsModal: {
      ...state.saveAsModal,
      title: title
    }
  })
);
