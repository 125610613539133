import { call } from 'redux-saga/effects';
import { ActionCreatorPayload, SagaAction } from 'type';
import { BOX_EMPLOYEE_DASHBOARD_SHIFT_CHANGED } from 'state/EmployeeDashboard/EmployeeDashboard';
import { showOutDatedNotification } from './outdated-notification-handlers';
import {
  composeHandlers,
  pageNotFetchedHandler,
  shiftNotCreatedHandler,
  shiftNotInRangeHandler,
} from './handlers';

type ActionPayload = ActionCreatorPayload<
  typeof BOX_EMPLOYEE_DASHBOARD_SHIFT_CHANGED
>;

const composedHandler = composeHandlers<ActionPayload>(
  pageNotFetchedHandler,
  shiftNotCreatedHandler,
  shiftNotInRangeHandler
)(showOutDatedNotification);

export default function* ({ payload }: SagaAction<ActionPayload>) {
  yield call(composedHandler, payload);
}
