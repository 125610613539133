import { Dictionary } from 'ts-essentials';
import { Moment } from 'moment';
import { EmployeeShiftPeriodShort } from '../../../types';

type PeriodRange = {
  from: Moment;
  to: Moment | null;
};

export const rangeFromPeriodMakers: Dictionary<(currentTime: Moment) => PeriodRange,
  EmployeeShiftPeriodShort> = {
  last_30_days: (currentTime) => ({
    from: currentTime.clone().startOf('day').subtract(30, 'days'),
    to: currentTime.clone().endOf('day').subtract(1, 'day'),
  }),
  last_7_days: (currentTime) => ({
    from: currentTime.clone().startOf('day').subtract(7, 'days'),
    to: currentTime.clone().endOf('day').subtract(1, 'day'),
  }),
  today: (currentTime) => ({
    from: currentTime.clone().startOf('day'),
    to: currentTime.clone().endOf('day'),
  }),
  next_7_days: (currentTime) => ({
    from: currentTime.clone().startOf('day').add(1, 'day'),
    to: currentTime.clone().endOf('day').add(7, 'days'),
  }),
  next_30_days: (currentTime) => ({
    from: currentTime.clone().startOf('day').add(1, 'day'),
    to: currentTime.clone().endOf('day').add(30, 'days'),
  }),
  all_future: (currentTime) => ({
    from: currentTime.clone(),
    to: null,
  }),
};

export const rangeFromFromMaker = (from: Moment, to: Moment | null): PeriodRange => ({
  from,
  to,
});
