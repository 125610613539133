import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import {
  BOX_ROSTER_DAY_VIEW_ERROR_MODAL_CLOSE,
  getErrorModal,
} from 'state/Roster/RosterDayView';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from 'extended-oxygen-elements';
import { PageDialog, PageDialogSubmit } from 'element';

type OwnProps = {};

type StateProps = {
  errors: string[];
  isOpened: boolean;
};

type DispatchProps = {
  closeModal: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const DayErrorModalComponent = ({ errors, isOpened, closeModal }: Props) => {
  return (
    <PageDialog maxWidth={'xs'} open={isOpened} id="day-view-error-modal">
      <DialogTitle>Error:</DialogTitle>
      <DialogContent>
        {errors.map((error, errorIndex) => (
          <Typography variant={'body1'} key={errorIndex}>
            {error}
          </Typography>
        ))}
      </DialogContent>
      <DialogActions>
        <PageDialogSubmit onClick={closeModal}>OK</PageDialogSubmit>
      </DialogActions>
    </PageDialog>
  );
};

const mapStateToProps = (state: StoreState): StateProps => getErrorModal(state);

const mapDispatchToProps: DispatchProps = {
  closeModal: BOX_ROSTER_DAY_VIEW_ERROR_MODAL_CLOSE,
};

export const DayErrorModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(DayErrorModalComponent);
