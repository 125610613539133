import { FormattedErrors } from '../../type';
import { Profile } from 'type/models/preferences';

export interface PreferencesReducerState {
  isFetched: boolean;
  isFetching: boolean;
  isLoading: boolean;
  isUpdating: boolean;
  showNotification: boolean;
  profile: Profile;
  errors: FormattedErrors;
}

export const defaultState: PreferencesReducerState = {
  isFetched: false,
  isFetching: false,
  isLoading: false,
  isUpdating: false,
  showNotification: false,
  profile: {
    manager: {},
    employee: {},
  },
  errors: [],
};
