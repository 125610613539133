import clsx from 'clsx';
import { useTheme } from 'extended-oxygen-elements';
import { useSelectorWithProps } from 'hooks';
import React from 'react';
import { getGlobalIsLoading } from 'state/IsLoading';
import { CenteredCircularProgress } from '../CenteredCircularProgress';
import './GlobalLoadingOverlay.scss';

export function GlobalLoadingOverlay() {
  const isLoading = useSelectorWithProps(getGlobalIsLoading);
  const zIndex = useTheme().zIndex.globalOverlay;

  return (
    <div
      className={clsx('global-loading-overlay', {
        'is-loading': isLoading,
      })}
      style={{ zIndex }}
    >
      <CenteredCircularProgress />
    </div>
  );
}
