import { Timesheet } from 'type';
import { useSelectorWithProps } from 'hooks/useSelectorWithProps';
import { userSafeSelector } from 'state/UsersCollection';
import { useSelector } from 'react-redux';
import { getLangPreferences } from 'state/Account';
import { capitalize } from 'lib/helpers';

export const useTimesheetUserName = ({ user_id }: Timesheet) => {
  const user = useSelectorWithProps(userSafeSelector, user_id);
  const langPreferences = useSelector(getLangPreferences);

  return (
    user?.prefered_or_full_name ||
    `Unknown ${capitalize(langPreferences.employee.singular)}`
  );
}
