import { call, put } from 'redux-saga/effects';
import { Api } from 'lib/Api';
import { processApiRequestWithConfirm } from 'state/ProcessApiRequest';
import { CreateShiftItemPayload, CreateShiftItemResponse } from './types';
import * as actions from './actions';


export const createShiftItemRequest = function*(
  payload: CreateShiftItemPayload
) {
  const response: CreateShiftItemResponse = yield call(
    processApiRequestWithConfirm,
    Api.EditTemplate.createShiftItem,
    payload
  );

  yield put(actions.BOX_EDIT_TEMPLATE_SHIFT_ITEM_ADD(response));
};
