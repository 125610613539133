import { StoreState } from '../../types';
import { EventModalProps, PublishedShiftModalProps } from './types';
import { Event, FormattedErrors, RosteredShift } from 'type/models';
import { StringMap } from '../../../type';

export const getIsUpdating = (state: StoreState): boolean =>
  state.rosterShiftModal.isUpdating;
export const getIsLoadingShifts = (state: StoreState): boolean =>
  state.eventModal.eventModal.isLoadingShifts;

export const getEventModal = (state: StoreState): EventModalProps => state.eventModal.eventModal;
export const getEventModalEvent = (state: StoreState): Event => state.eventModal.currentEvent;
export const getEventModalErrors = (state: StoreState): FormattedErrors => state.eventModal.eventModal.errors;

export const getEventModalShifts = (state: StoreState): StringMap<RosteredShift> => state.eventModal.shifts;

export const getEventModalPublishedShiftsModal = (state: StoreState): PublishedShiftModalProps =>
  state.eventModal.publishedShiftsModal;