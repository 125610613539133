// TODO think about switching to the oxygen theme
// TODO change naming to match the purpose
export const blackAlpha70 = 'rgba(0, 0, 0, 0.67)';

export const blueAlpha15 = 'rgba(9,30,66,.15)';
export const blueAlpha25 = 'rgba(9,30,66,.25)';
export const blueAlpha30 = 'rgba(9,30,66,.31)';

const shadow1 = `0 1px 1px ${blueAlpha25}`;
const shadow2 = `0 0 1px ${blueAlpha30}`;
const shadow3 = `0 8px 12px ${blueAlpha15}`;

export const shadow100 = [shadow1, shadow2].join();
export const shadow200 = [shadow3, shadow2].join();

export const red = '#B11040';
export const orange = '#FD9C09';
export const purple = '#5262EF';
export const blue50 = '#CAE9FF';
export const blue100 = '#B1E0FF';
export const green50 = '#B6DFC3';
export const green100 = '#8DDBA6';
export const green200 = '#37C597';
