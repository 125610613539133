import {
  Skeleton,
  SkeletonProps,
  styled,
  useTheme,
} from 'extended-oxygen-elements';
import React from 'react';

export type SkeletonIconButtonProps = Omit<SkeletonProps, 'variant'> & {
  size?: 'small' | 'medium' | 'large';
};

export default SkeletonIconButton;

const StyledSkeleton = styled(Skeleton)<SkeletonIconButtonProps>(
  ({ theme, size = 'medium' }) => {
    const { iconButton, svgIcon } = theme.componentsConfig;

    const sizePx = svgIcon.size[size] + 2 * iconButton[size].padding;

    return {
      width: sizePx,
      height: sizePx,
      flexShrink: 0
    };
  }
);

export function SkeletonIconButton(props: SkeletonIconButtonProps) {
  return <StyledSkeleton {...props} variant="circular" />;
}
