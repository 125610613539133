import React from 'react';
import { Box } from 'extended-oxygen-elements';
import { DeleteSweepOutlinedIcon } from 'element';
import { HeaderButtonProp } from 'element/Header';
import { isAppMarket } from 'helpers';
import { getDateTimeFormatted } from 'lib/helpers';
import ClipboardIcon from 'page/Roster/components/ContextualMenu/componenets/ClipboardIcon';
import { useDispatch, useSelector } from 'react-redux';
import { getDateFormat, getTimeFormat } from 'state/Account';
import { getSites } from 'state/AccountTree';
import { hasPermissionSelector } from 'state/Auth';
import { BOX_ROSTER_BULK_DELETION_MODE_ACTIVATE } from 'state/Roster/BulkActions/actions';
import {
  getClipboard,
  getLastAction,
} from 'state/Roster/ContextualMenu/selectors';
import { getIsApprovalModeEnabled } from 'state/Roster/Roster/selectors';
import { getCopiedRoster } from 'state/Roster/RosterCopyPast/selectors';
import { getSiteId } from 'state/RosteredShiftsCollection';
import './style.scss';
import { HeaderInner } from './HeaderInner';
import { useSelectorWithProps } from 'hooks';
import { useBulkDeleteContext } from 'contexts';

export const WeekHeader = () => {
  const siteId = useSelector(getSiteId);
  const sites = useSelector(getSites);
  const isApproveMode = useSelector(getIsApprovalModeEnabled);
  const canEditRosteredShifts = useSelectorWithProps(
    hasPermissionSelector,
    'roster.rosteredshift.edit'
  );
  const dateFormat = useSelector(getDateFormat);
  const timeFormat = useSelector(getTimeFormat);
  const clipboard = useSelector(getClipboard);
  const action = useSelector(getLastAction);
  const copiedRoster = useSelector(getCopiedRoster);
  const dispatch = useDispatch();
  const bulkDeleteContext = useBulkDeleteContext();

  if (!sites[siteId]) {
    return null;
  }

  const getDate = () => {
    if (clipboard) {
      const { start, end } = clipboard;
      const startDate = getDateTimeFormatted(
        dateFormat,
        timeFormat,
        start,
        true
      );
      const endDate = getDateTimeFormatted(dateFormat, timeFormat, end, true);
      return `${startDate} - ${endDate}`;
    }
    return '';
  };

  const getWeekDates = () => {
    if (copiedRoster.range) {
      const { from, to } = copiedRoster.range;
      const startDate = getDateTimeFormatted(dateFormat, timeFormat, from);
      const endDate = getDateTimeFormatted(dateFormat, timeFormat, to);
      return `${startDate} - ${endDate}`;
    }
    return '';
  };

  const getClipboardMsg = () => {
    const lastAction = action === 'copy' ? 'Copied' : 'Cut';
    const shiftMessage = `${lastAction}: ${getDate()}`;
    const copyWeekMessage = `Copied ${
      isAppMarket('uk') ? 'scheduled' : 'roster'
    } week: ${getWeekDates()}`;
    const messages = [];

    if (clipboard) {
      messages.push(shiftMessage);
    }

    if (copiedRoster.range) {
      messages.push(copyWeekMessage);
    }

    return messages.join(', ');
  };

  const getClipboardButton = (className = { className: '' }) => {
    return {
      icon: <ClipboardIcon />,
      label: getClipboardMsg(),
      tooltip: getClipboardMsg(),
      onClick: (): void => void 0,
      ...className,
    };
  };

  const getBulkDeleteButton = (className = { className: '' }) => {
    return {
      id: 'bulk-delete-btn',
      icon: (
        <Box component="span" color="error.dark">
          <DeleteSweepOutlinedIcon color="inherit" />
        </Box>
      ),
      onClick: () => {
        bulkDeleteContext.enable();
        dispatch(BOX_ROSTER_BULK_DELETION_MODE_ACTIVATE());
      },
      label: 'Open bulk delete mode',
      isDisabled: isApproveMode,
      tooltip: 'Open bulk delete mode',
      ...className,
    };
  };

  const getButtonsConfig = (): HeaderButtonProp[] => {
    const headerButtons: HeaderButtonProp[] = [];

    if (clipboard || copiedRoster.range) {
      headerButtons.push(getClipboardButton());
    }

    if (canEditRosteredShifts) {
      headerButtons.push(getBulkDeleteButton());
    }

    return headerButtons;
  };

  return (
    <div
      className="rosters-header-wrapper-improved"
      data-testid="roster-header"
    >
      <HeaderInner buttonsConfig={getButtonsConfig()} />
    </div>
  );
};
