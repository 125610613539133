import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@mui/styles';
import { useSelectorWithProps } from 'hooks';
import { getUserIsActiveSafe } from 'state/UsersCollection';
import { useMaxRowsNumber } from 'page/TimesheetsWeekly/hooks';
import { EmployeeCard, TimesheetRow } from './components';

type UserRowProps = {
  userId: string;
};

const useStyles = makeStyles((theme) => {
  const leftColWidth = 185;
  return createStyles({
    root: {
      padding: `${theme.spacing(1 / 4)} 0`,
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      minWidth: '1235px',
    },
    archived: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
    rowWrapper: {
      display: 'flex',
    },
    left: {
      width: leftColWidth,
    },
    right: {
      width: `calc(100% - ${leftColWidth}px)`,
    },
    row: {
      display: 'flex',
      width: '100%',
      padding: `${theme.spacing(1 / 4)} 0`,
    },
  });
});

export function UserRow(props: UserRowProps) {
  const rosterRowsNumber = useMaxRowsNumber(props);
  const { userId } = props;
  const isUserActive = useSelectorWithProps(getUserIsActiveSafe, userId);
  const classes = useStyles();

  return (
    <div
      className={clsx([classes.root, !isUserActive && classes.archived])}
      data-testid="user-row"
    >
      {_.times(rosterRowsNumber, (rowIndex) => (
        <div key={rowIndex} className={classes.rowWrapper}>
          <div className={classes.left}>
            {rowIndex === 0 && <EmployeeCard userId={userId} />}
          </div>

          <div className={classes.right}>
            <TimesheetRow
              userId={userId}
              key={rowIndex}
              rowIndex={rowIndex}
              className={classes.row}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
