import { createReducer } from 'lib/store-utils';
import { BulkReducerState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';

export const bulk = createReducer<BulkReducerState>({}, getDefaultState());

bulk.on(
  actions.BOX_BULK_SET_ALL_IDS,
  (state, { ids }): BulkReducerState => ({
    ...state,
    allIds: ids
  })
);

bulk.on(
  actions.BOX_BULK_SET_PAGE_IDS,
  (state, { ids }): BulkReducerState => ({
    ...state,
    pageIds: ids
  })
);

bulk.on(actions.BOX_BULK_CLEAR_STATE, getDefaultState);

bulk.on(
  actions.BOX_BULK_SET_SELECTED_IDS,
  (state, selectedIds): BulkReducerState => ({
    ...state,
    selectedIds
  })
);

bulk.on(
  actions.BOX_BULK_SET_ALL_VISIBLE,
  (state, isAllSelected): BulkReducerState => ({
    ...state,
    isAllSelected
  })
);
