import React, { ReactNode } from 'react';
import './RosterNavWrapper.scss';
import { LegendPlaceholder } from '../';

type Props = {
  children: ReactNode;
};

export const RosterNavWrapper = ({ children }: Props) => {
  return (
    <div className="roster-nav-wrapper">
      <LegendPlaceholder />
      {children}
    </div>
  );
};
