import { ErrorsHandler } from 'state/Errors';
import { LoadingHandler } from 'state/Loading';
import * as actions from './actions';

export const getErrors = ErrorsHandler()
  .clearErrors(actions.BOX_MY_ROSTERS_DATA_REQUEST)
  .clearErrors(actions.BOX_MY_ROSTERS_ERRORS_CLEARED)
  //
  .clearErrors(actions.BOX_MY_ROSTERS_RE_FETCH_DATA_REQUEST)
  .saveErrors(actions.BOX_MY_ROSTERS_RE_FETCH_DATA_FAILURE)
  //
  .clearErrors(actions.BOX_MY_ROSTERS_TOGGLE_PERIOD_TYPE_REQUEST)
  .saveErrors(actions.BOX_MY_ROSTERS_TOGGLE_PERIOD_TYPE_FAILURE)
  //
  .clearErrors(actions.BOX_MY_ROSTERS_CLOCK_ON_REQUEST)
  .saveErrors(actions.BOX_MY_ROSTERS_CLOCK_ON_FAILURE)
  //
  .clearErrors(actions.BOX_MY_ROSTERS_CLOCK_OFF_REQUEST)
  .saveErrors(actions.BOX_MY_ROSTERS_CLOCK_OFF_FAILURE)
  //
  .clearErrors(actions.BOX_MY_ROSTERS_START_BREAK_REQUEST)
  // .saveErrors(MyRosters.BOX_MY_ROSTERS_START_BREAK_FAILURE)
  //
  .clearErrors(actions.BOX_MY_ROSTERS_STOP_BREAK_REQUEST)
  .saveErrors(actions.BOX_MY_ROSTERS_STOP_BREAK_FAILURE)
  //
  .clearErrors(actions.BOX_MY_ROSTERS_APPLY_FORM_FILTERS_REQUEST)
  .saveErrors(actions.BOX_MY_ROSTERS_APPLY_FORM_FILTERS_FAILURE)
  //
  .clearErrors(actions.BOX_MY_ROSTERS_CHANGE_PAGE_REQUEST)
  .saveErrors(actions.BOX_MY_ROSTERS_CHANGE_PAGE_FAILURE)
  //
  .clearErrors(actions.BOX_MY_ROSTERS_CHANGE_PAGE_SIZE_REQUEST)
  .saveErrors(actions.BOX_MY_ROSTERS_CHANGE_PAGE_SIZE_FAILURE)
  //
  .clearErrors(actions.BOX_MY_ROSTERS_ACCEPT_SHIFT_REQUEST)
  .saveErrors(actions.BOX_MY_ROSTERS_ACCEPT_SHIFT_FAILURE)
  //
  .clearErrors(actions.BOX_MY_ROSTERS_DECLINE_SHIFT_REQUEST)
  .saveErrors(actions.BOX_MY_ROSTERS_DECLINE_SHIFT_FAILURE)
  //
  .makeSelector();

export const getLoading = LoadingHandler()
  .loadingOn(actions.BOX_MY_ROSTERS_RE_FETCH_DATA_REQUEST)
  .loadingOff(actions.BOX_MY_ROSTERS_RE_FETCH_DATA_SUCCESS)
  .loadingOff(actions.BOX_MY_ROSTERS_RE_FETCH_DATA_FAILURE)
  //
  .loadingOn(actions.BOX_MY_ROSTERS_TOGGLE_PERIOD_TYPE_REQUEST)
  .loadingOff(actions.BOX_MY_ROSTERS_TOGGLE_PERIOD_TYPE_SUCCESS)
  .loadingOff(actions.BOX_MY_ROSTERS_TOGGLE_PERIOD_TYPE_FAILURE)
  //
  .loadingOn(actions.BOX_MY_ROSTERS_CLOCK_ON_REQUEST)
  .loadingOff(actions.BOX_MY_ROSTERS_CLOCK_ON_SUCCESS)
  .loadingOff(actions.BOX_MY_ROSTERS_CLOCK_ON_FAILURE)
  //
  .loadingOn(actions.BOX_MY_ROSTERS_CLOCK_OFF_REQUEST)
  .loadingOff(actions.BOX_MY_ROSTERS_CLOCK_OFF_SUCCESS)
  .loadingOff(actions.BOX_MY_ROSTERS_CLOCK_OFF_FAILURE)
  //
  .loadingOn(actions.BOX_MY_ROSTERS_START_BREAK_REQUEST)
  .loadingOff(actions.BOX_MY_ROSTERS_START_BREAK_SUCCESS)
  .loadingOff(actions.BOX_MY_ROSTERS_START_BREAK_FAILURE)
  //
  .loadingOn(actions.BOX_MY_ROSTERS_STOP_BREAK_REQUEST)
  .loadingOff(actions.BOX_MY_ROSTERS_STOP_BREAK_SUCCESS)
  .loadingOff(actions.BOX_MY_ROSTERS_STOP_BREAK_FAILURE)
  //
  .loadingOn(actions.BOX_MY_ROSTERS_APPLY_FORM_FILTERS_REQUEST)
  .loadingOff(actions.BOX_MY_ROSTERS_APPLY_FORM_FILTERS_SUCCESS)
  .loadingOff(actions.BOX_MY_ROSTERS_APPLY_FORM_FILTERS_FAILURE)
  //
  .loadingOn(actions.BOX_MY_ROSTERS_CHANGE_PAGE_REQUEST)
  .loadingOff(actions.BOX_MY_ROSTERS_CHANGE_PAGE_SUCCESS)
  .loadingOff(actions.BOX_MY_ROSTERS_CHANGE_PAGE_FAILURE)
  //
  .loadingOn(actions.BOX_MY_ROSTERS_CHANGE_PAGE_SIZE_REQUEST)
  .loadingOff(actions.BOX_MY_ROSTERS_CHANGE_PAGE_SIZE_SUCCESS)
  .loadingOff(actions.BOX_MY_ROSTERS_CHANGE_PAGE_SIZE_FAILURE)
  //
  .loadingOn(actions.BOX_MY_ROSTERS_ACCEPT_SHIFT_REQUEST)
  .loadingOff(actions.BOX_MY_ROSTERS_ACCEPT_SHIFT_SUCCESS)
  .loadingOff(actions.BOX_MY_ROSTERS_ACCEPT_SHIFT_FAILURE)
  //
  .loadingOn(actions.BOX_MY_ROSTERS_DECLINE_SHIFT_REQUEST)
  .loadingOff(actions.BOX_MY_ROSTERS_DECLINE_SHIFT_SUCCESS)
  .loadingOff(actions.BOX_MY_ROSTERS_DECLINE_SHIFT_FAILURE)
  //
  .makeSelector();
