import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
} from 'extended-oxygen-elements';
import { useSetCheckbox } from 'hooks';
import React from 'react';
import { FieldGroupProps } from 'type';
import { HelperTextWrapper } from 'page/Settings/components';
import {
  ShiftOffersFieldGroupErrors,
  ShiftOffersFieldGroupValue,
} from './types';

type Props = FieldGroupProps<
  ShiftOffersFieldGroupValue,
  ShiftOffersFieldGroupErrors
>;

export default ShiftOffersFieldGroup;

export function ShiftOffersFieldGroup({ values, onChange }: Props) {
  const { makeSetCheckbox } = useSetCheckbox(onChange);

  return (
    <>
      <Typography variant="h4" component="h2">
        Unassigned Shift offers
      </Typography>

      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.shift_offers}
              onChange={makeSetCheckbox('shift_offers')}
            />
          }
          label="Enable shift offers"
        />

        <HelperTextWrapper>
          <FormHelperText fontSize="medium">
            Allow people to express their interest in taking unassigned shift
            offers.
          </FormHelperText>
        </HelperTextWrapper>
      </FormControl>

      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.shift_offer_responses_must_be_approved}
              onChange={makeSetCheckbox(
                'shift_offer_responses_must_be_approved'
              )}
            />
          }
          label="Approve shift offers"
        />

        <HelperTextWrapper>
          <FormHelperText fontSize="medium">
            Managers are required to approve any shift offer requests.
          </FormHelperText>
        </HelperTextWrapper>
      </FormControl>
    </>
  );
}
