import React, { Component } from 'react';
import { CheckIcon as SelectedIcon } from '../../icons';
import './SelectListOption.scss';
import { SelectListOptionProps } from '../type';
import { getClass } from '../../_lib/helper';

class SelectListOption extends Component<SelectListOptionProps> {
  /**
   * Called when the option is selected/deselected
   */
  handleToggle = () => {
    const { onToggle, isSelected, selectListHandleToggle, isDisabled } =
      this.props;
    if (isDisabled || !onToggle) {
      return;
    }

    onToggle();
    if (selectListHandleToggle) {
      selectListHandleToggle(!isSelected);
    }
  };

  renderIcon() {
    const { icon, isSelected } = this.props;

    if (isSelected) {
      return (
        <span className="elmo-select-list__selected">
          {icon ? icon : <SelectedIcon />}
        </span>
      );
    } else if (isSelected === undefined) {
      return icon ? icon : null;
    }
  }

  render() {
    const { children, isDisabled } = this.props;

    return (
      <div
        className={getClass(
          'elmo-select-list__option',
          {},
          {
            disabled: isDisabled,
          }
        )}
        onClick={this.handleToggle}
        role="listitem"
        tabIndex={0}
      >
        <span className="elmo-select-list__item">{children}</span>
        {this.renderIcon()}
      </div>
    );
  }
}

export default SelectListOption;
