import {
  ArchiveOutlinedIcon,
  MoreHorizOutlinedIcon,
  PersonIcon,
} from 'element';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuDivider,
  MenuItem,
  Tooltip,
} from 'extended-oxygen-elements';
import {
  bindMenu,
  bindTrigger,
  usePopupState,
  useSelectorWithProps,
} from 'hooks';
import { capitalize } from 'lib/helpers';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLangPreferences } from 'state/Account';
import { getArea, getSite } from 'state/AccountTree';
import { getAssignmentAreaUsersCount } from 'state/Assignments';
import DialogArchiveArea from './DialogArchiveArea';
import DialogAssignedUsers from './DialogAssignedUsers';
import DialogUnarchiveArea from './DialogUnarchiveArea';

type Props = {
  area_id: string;
};

type DialogName = 'assignedUsers' | 'archiveArea' | 'unarchiveArea';

export default function AreaBtnMoreOptions({ area_id }: Props) {
  const langPreferences = useSelector(getLangPreferences);
  const area = useSelectorWithProps(getArea, area_id);
  const { site_id } = area;
  const site = useSelectorWithProps(getSite, site_id);
  const usersCount = useSelectorWithProps(getAssignmentAreaUsersCount, {
    area_id,
    site_id,
  });

  const menuPopupState = usePopupState();
  const [openDialogName, setOpenDialogName] = React.useState<DialogName | null>(
    null
  );

  const closeDialog = () => {
    setOpenDialogName(null);
  };

  const makeHandleMenuItemClick = (dialogName: DialogName) => () => {
    setOpenDialogName(dialogName);
    menuPopupState.close();
  };

  const ArchiveItem = () => {
    return usersCount ? (
      <Tooltip
        title={`${capitalize(
          langPreferences.area.singular
        )} can’t be archived when there are assigned users`}
        disableInteractive
      >
        <li>
          <MenuItem
            component="div"
            onClick={makeHandleMenuItemClick('archiveArea')}
            disabled={usersCount > 0}
          >
            <ListItemIcon>
              <ArchiveOutlinedIcon />
            </ListItemIcon>

            <ListItemText>Archive {langPreferences.area.singular}</ListItemText>
          </MenuItem>
        </li>
      </Tooltip>
    ) : (
      <li>
        <MenuItem
          component="div"
          onClick={makeHandleMenuItemClick('archiveArea')}
          disabled={usersCount > 0}
        >
          <ListItemIcon>
            <ArchiveOutlinedIcon />
          </ListItemIcon>

          <ListItemText>Archive {langPreferences.area.singular}</ListItemText>
        </MenuItem>
      </li>
    );
  };

  return (
    <>
      <IconButton
        {...bindTrigger(menuPopupState)}
        aria-label="More options"
        disabled={site.archived}
      >
        <MoreHorizOutlinedIcon />
      </IconButton>
      <Menu {...bindMenu(menuPopupState)}>
        <Tooltip
          title={
            !usersCount &&
            `There are no ${langPreferences.employee.plural} assigned to this ${langPreferences.area.singular}`
          }
          disableInteractive
        >
          <li>
            <MenuItem
              component="div"
              onClick={makeHandleMenuItemClick('assignedUsers')}
              disabled={site.archived || area.archived || !usersCount}
            >
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText>
                {capitalize(langPreferences.employee.plural)} assigned to{' '}
                {langPreferences.area.singular}
              </ListItemText>
            </MenuItem>
          </li>
        </Tooltip>
        <MenuDivider />
        {area.archived ? (
          <li>
            <MenuItem
              component="div"
              onClick={makeHandleMenuItemClick('unarchiveArea')}
            >
              <ListItemIcon>
                <ArchiveOutlinedIcon />
              </ListItemIcon>

              <ListItemText>
                Unarchive {langPreferences.area.singular}
              </ListItemText>
            </MenuItem>
          </li>
        ) : (
          <ArchiveItem />
        )}
      </Menu>
      <DialogAssignedUsers
        area_id={area_id}
        open={openDialogName === 'assignedUsers'}
        onClose={closeDialog}
      />
      <DialogArchiveArea
        area_id={area_id}
        open={openDialogName === 'archiveArea'}
        closeDialog={closeDialog}
      />
      <DialogUnarchiveArea
        area_id={area_id}
        open={openDialogName === 'unarchiveArea'}
        closeDialog={closeDialog}
      />
    </>
  );
}
