import React from 'react';
import { Card, Col, Paragraph, Row, Text } from 'elmo-elements';
import { StoreState } from 'state/types';
import {
  LanguagePreferences,
  PreferencesDateFormat,
  PreferencesTimeFormat,
  UserProfileFields,
} from 'type/models';
import { connect } from 'react-redux';
import { getDateTimeFormatted, getPreferenceLabel } from 'lib/helpers';
import {
  userGroupsAsSelectOptionsArraySelector,
  userGroupsAsSelectOptionsSelector,
} from 'state/UserGroups/selectors';
import ProfileDetailsModal from './components/ProfileDetailsModal';
import {
  BOX_USER_PROFILE_PROFILE_MODAL_CLOSE,
  BOX_USER_PROFILE_PROFILE_MODAL_OPEN,
} from 'state/Users/UserProfile/actions';
import { EditButton } from '../EditButton';
import {
  getDateFormat,
  getLangPreferences,
  getTimeFormat,
} from 'state/Account';

type StateProps = {
  userProfile: UserProfileFields;
  userGroups: any;
  userGroupsOptions: any;
  isOpened: boolean;
  langPreferences: LanguagePreferences;
  dateFormat: PreferencesDateFormat;
  timeFormat: PreferencesTimeFormat;
};

type DispatchProps = {
  openModal: () => void;
  closeModal: () => void;
};

type State = {
  ratingChanged: boolean;
};

type Props = StateProps & DispatchProps;

export class ProfileDetailsComponent extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      ratingChanged: true
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if ( this.props !== prevProps ) {
      this.setState(
        {
          ratingChanged: false
        },
        () => {
          this.setState({
            ratingChanged: true
          });
        });
    }
  }

  render() {
    const {
      prefered_name,
      payroll_number,
      user_group_id,
      tags,
      rating,
      abbreviation
    } = this.props.userProfile;

    const { langPreferences } = this.props;

    return (
      <Card
        isFullHeight={true}
        heading="Profile details"
        option={this.props.userProfile.is_active && <EditButton onClick={this.props.openModal} ariaLabel="Edit"/>}
        role="grid"
      >
        <Row justifyContent={'between'}>
          <Col xs={12} span={24}>
            <Text size={'sm'}>Known As</Text>
            <br/>
            <Paragraph className="long-text">
              {this.getFieldValue(prefered_name)}
            </Paragraph>
            <br/>
            <Text size={'sm'}>Permissions</Text>
            <Paragraph>{this.getUserGroupById(user_group_id)}</Paragraph>
            <br/>
          </Col>
          <br/>
          <Col xs={12} span={24}>
            <Text size={'sm'}>{getPreferenceLabel(langPreferences, 'employee', 'singular', '', true)} number</Text>
            <Paragraph>{this.getFieldValue(payroll_number)}</Paragraph>
          </Col>
        </Row>
        <ProfileDetailsModal
          isOpened={this.props.isOpened}
          closeModal={this.props.closeModal}
          userGroupsDropDown={this.props.userGroupsOptions}
        />
      </Card>
    );
  }

  getFieldValue = (field: string | null, isDate = false) => {
    const { dateFormat, timeFormat } = this.props;
    if ( isDate && field !== null ) {
      return getDateTimeFormatted(dateFormat, timeFormat, field);
    }
    return field && field !== null && field !== '' ? field : '-';
  };

  getUserGroupById = (id: number) => {
    if ( id !== 0 && this.props.userGroups[id] ) {
      return this.props.userGroups[id].label;
    }
    return '-';
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  userProfile: state.userProfile.userProfile,
  userGroups: userGroupsAsSelectOptionsSelector(state),
  userGroupsOptions: userGroupsAsSelectOptionsArraySelector(state),
  isOpened: state.userProfile.profileModal.isOpened,
  langPreferences: getLangPreferences(state),
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state)
});

export const ProfileDetails = connect(
  mapStateToProps,
  {
    openModal: BOX_USER_PROFILE_PROFILE_MODAL_OPEN,
    closeModal: BOX_USER_PROFILE_PROFILE_MODAL_CLOSE
  }
)(ProfileDetailsComponent);
