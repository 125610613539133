import { SagaIterator } from 'redux-saga';
import { put, select, takeLatest } from 'redux-saga/effects';
import { flatMap, intersection, uniq } from 'lodash';
import { SagaAction } from 'type';
import { getAccountTree } from 'state/AccountTree';
import { ChangeSelectedPayload } from './types';
import * as actions from './actions';
import { getSelectedRoleIds } from './selectors';

const changeAreas = function*({
  payload: selectedAreaIds
}: SagaAction<ChangeSelectedPayload>): SagaIterator {
  if (!selectedAreaIds.length) {
    yield put(
      actions.BOX_SITE_FLAT_AREA_ROLE_MODAL_UPDATE_SELECTED({
        selectedAreaIds
      })
    );

    return;
  }

  const accountTree: ReturnType<typeof getAccountTree> = yield select(
    getAccountTree
  );
  const roleIdsOfSelectedAreas = uniq(
    flatMap(selectedAreaIds, areaId => accountTree.areas[areaId].role_ids)
  );

  const selectedRoleIds: ReturnType<typeof getSelectedRoleIds> = yield select(
    getSelectedRoleIds
  );

  yield put(
    actions.BOX_SITE_FLAT_AREA_ROLE_MODAL_UPDATE_SELECTED({
      selectedAreaIds,
      selectedRoleIds: intersection(roleIdsOfSelectedAreas, selectedRoleIds)
    })
  );
};

export const watchFlatAreaRoleModal = function*(): SagaIterator {
  yield takeLatest(
    actions.BOX_SITE_FLAT_AREA_ROLE_MODAL_CHANGE_AREAS,
    changeAreas
  );
};
