import { Api, BulkRosteredShiftsRequest } from 'lib/Api';
import {
  GetEventByIdRequest,
  RosteredShiftCreateRequest,
  RosteredShiftCreateResponse,
  RosteredShiftUpdateResponse,
} from 'lib/Api/type';
import browserHistory from 'lib/browserHistory';
import { SERVER_DAY_FORMAT } from 'lib/config';
import routeQuery from 'lib/routeQuery';
import moment from 'moment';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { formatError, getErrorStatus, relocateToShift } from 'state/helpers';
import {
  BOX_ROSTERED_SHIFTS_ADD,
  BOX_ROSTERED_SHIFTS_ADD_ONE,
  BOX_ROSTERED_SHIFTS_DELETE,
  getFrom,
  getRosteredShifts,
  getRosteredShiftsListRequest,
  getSiteId,
  getTo,
} from 'state/RosteredShifts';
import { triggerDeleteCutShift } from 'state/RosteredShifts/sagas';
import { RosteredShift, RosterPageQuery, SagaAction, ShiftBreak } from 'type';
import { RepeatShiftItem } from '../../../element/shiftModals/RosteredShiftModal/types';
import { hasPermissionSelector } from '../../Auth';
import { getEventsRequest } from '../../Events/sagas';
import { processApiRequest } from '../../ProcessApiRequest';
import { BOX_ROSTERED_SHIFTS_UNDO_LIST } from '../../RosteredShifts/actions';
import { getChannelIsActive } from '../../Sockets';
import { BOX_ROSTER_CONTEXTUAL_MENU_CLOSE } from '../ContextualMenu/actions';
import { syncEventForShift } from '../EventModal/sagas';
import { getNeedReAccept } from '../ReAcceptConfirmModal/sagas';
import {
  BOX_ROSTER_CLOSE_APPLY_CHANGES_MODAL,
  BOX_ROSTER_GET_OFFERS,
  BOX_ROSTER_GET_UNPUBLISHED,
} from '../Roster/actions';
import { BOX_SUMMARY_GET_DATA_ON_ROSTER_UPDATES } from '../Summary';
import {
  BOX_ROSTER_SHIFT_MODAL_ACCEPT_SHIFT,
  BOX_ROSTER_SHIFT_MODAL_ACCEPT_SHIFT_FAILURE,
  BOX_ROSTER_SHIFT_MODAL_ACCEPT_SHIFT_SUCCESS,
  BOX_ROSTER_SHIFT_MODAL_CLOSE,
  BOX_ROSTER_SHIFT_MODAL_CREATE_SHIFT,
  BOX_ROSTER_SHIFT_MODAL_CREATE_SHIFT_FAILURE,
  BOX_ROSTER_SHIFT_MODAL_CREATE_SHIFT_SUCCESS,
  BOX_ROSTER_SHIFT_MODAL_DELETE_SHIFT,
  BOX_ROSTER_SHIFT_MODAL_DELETE_SHIFT_FAILURE,
  BOX_ROSTER_SHIFT_MODAL_DELETE_SHIFT_SUCCESS,
  BOX_ROSTER_SHIFT_MODAL_GET_BY_ID,
  BOX_ROSTER_SHIFT_MODAL_GET_BY_ID_FAILURE,
  BOX_ROSTER_SHIFT_MODAL_GET_BY_ID_SUCCESS,
  BOX_ROSTER_SHIFT_MODAL_GET_EVENTS,
  BOX_ROSTER_SHIFT_MODAL_GET_EVENTS_FAILURE,
  BOX_ROSTER_SHIFT_MODAL_GET_EVENTS_SUCCESS,
  BOX_ROSTER_SHIFT_MODAL_GET_SHIFT_USERS,
  BOX_ROSTER_SHIFT_MODAL_GET_SHIFT_USERS_FAILURE,
  BOX_ROSTER_SHIFT_MODAL_GET_SHIFT_USERS_SUCCESS,
  BOX_ROSTER_SHIFT_MODAL_NOTIFY,
  BOX_ROSTER_SHIFT_MODAL_NOTIFY_FAILURE,
  BOX_ROSTER_SHIFT_MODAL_NOTIFY_SUCCESS,
  BOX_ROSTER_SHIFT_MODAL_OFFER_SHIFT,
  BOX_ROSTER_SHIFT_MODAL_OFFER_SHIFT_FAILURE,
  BOX_ROSTER_SHIFT_MODAL_OFFER_SHIFT_SUCCESS,
  BOX_ROSTER_SHIFT_MODAL_OPEN_WITH_LOADER,
  BOX_ROSTER_SHIFT_MODAL_OVERLAP_CONFIRMATION_OPEN,
  BOX_ROSTER_SHIFT_MODAL_SET_SHIFT,
  BOX_ROSTER_SHIFT_MODAL_UNDO_SHIFT,
  BOX_ROSTER_SHIFT_MODAL_UNDO_SHIFT_FAILURE,
  BOX_ROSTER_SHIFT_MODAL_UNDO_SHIFT_SUCCESS,
  BOX_ROSTER_SHIFT_MODAL_UPDATE,
  BOX_ROSTER_SHIFT_MODAL_UPDATE_SHIFT_FAILURE,
  BOX_ROSTER_SHIFT_MODAL_UPDATE_SHIFT_SUCCESS,
} from './actions';
import { getRosterEventsLists } from './selectors';
import {
  DeleteRosteredShiftPayload,
  GetRosterByIDPayload,
  GetShiftUsersPayload,
  UpdatePayload,
} from './types';

const getRosteredShiftById = function* (
  action: SagaAction<GetRosterByIDPayload>
): SagaIterator {
  const canEditRosteredShift = yield select(
    hasPermissionSelector,
    'roster.rosteredshift.edit'
  );

  if (!canEditRosteredShift) {
    return;
  }

  yield put(BOX_ROSTER_SHIFT_MODAL_OPEN_WITH_LOADER());
  const { payload } = action;
  if (payload.id === '' || payload.id === 'paste_edit') {
    const isCutAction =
      payload.menu_action && payload.menu_action.action === 'cut';
    const id =
      payload.menu_action && payload.menu_action.id
        ? payload.menu_action.id
        : '';
    yield put(
      BOX_ROSTER_SHIFT_MODAL_SET_SHIFT({
        ...payload,
        end: payload.end.isSameOrBefore(payload.start)
          ? payload.end.clone().add(1, 'day')
          : payload.end,
        id: isCutAction ? id : '',
      })
    );
  } else {
    try {
      const rosterResponse: RosteredShift = yield call(
        processApiRequest,
        Api.RosteredShift.getById,
        payload
      );
      yield put(BOX_ROSTER_SHIFT_MODAL_GET_BY_ID_SUCCESS(rosterResponse));
    } catch (error) {
      yield put(BOX_ROSTER_SHIFT_MODAL_GET_BY_ID_FAILURE(formatError(error)));
    }
  }
};

const getRelatedToShiftData = function* (
  siteId: string,
  eventId?: null | string
): SagaIterator {
  const isChannelActive = yield select(getChannelIsActive, 'roster_week');
  if (!isChannelActive) {
    yield put(
      BOX_ROSTER_GET_UNPUBLISHED({
        site_id: siteId,
      })
    );

    yield put(
      BOX_ROSTER_GET_OFFERS({
        site_id: siteId,
      })
    );

    yield put(
      BOX_ROSTERED_SHIFTS_UNDO_LIST({
        site_id: siteId,
      })
    );

    if (eventId && eventId !== null) {
      const eventParams: GetEventByIdRequest = {
        id: eventId,
      };
      yield call(syncEventForShift, eventParams);
    }
  }

  yield put(BOX_SUMMARY_GET_DATA_ON_ROSTER_UPDATES());
};

const getUpdatedDate = (
  dayInfo: RepeatShiftItem,
  shift: RosteredShiftCreateRequest
) => {
  const { start, end, breaks } = shift;
  const eqFormat = 'YYYY-MM-DD';
  const startDate = start.clone().set({
    isoWeekday: dayInfo.isoDayNumber,
  });

  const endDate = end.clone().set({
    date:
      start.format(eqFormat) === end.format(eqFormat)
        ? +startDate.clone().format('DD')
        : +startDate.clone().add(1, 'day').format('DD'),
    month:
      start.format(eqFormat) === end.format(eqFormat)
        ? +startDate.clone().format('MM') - 1
        : +startDate.clone().add(1, 'day').format('MM') - 1,
    year:
      start.format(eqFormat) === end.format(eqFormat)
        ? +startDate.clone().format('YYYY')
        : +startDate.clone().add(1, 'day').format('YYYY'),
  });

  const updatedBreaks = breaks!.map((shiftBreak: ShiftBreak) => {
    return {
      ...shiftBreak,
      start:
        start.format(eqFormat) === shiftBreak.start.format(eqFormat)
          ? shiftBreak.start.clone().set({
              date: +startDate.format('DD'),
              month: +startDate.format('MM') - 1,
              year: +startDate.format('YYYY'),
            })
          : shiftBreak.start.clone().set({
              date: +endDate.format('DD'),
              month: +endDate.format('MM') - 1,
              year: +endDate.format('YYYY'),
            }),
    };
  });

  return {
    start: startDate,
    end: endDate,
    breaks: updatedBreaks,
  };
};

const createRosteredShift = function* (
  action: SagaAction<RosteredShiftCreateRequest>
): SagaIterator {
  const { OVERLAP_ERROR_STATUS } = Api.RosteredShift;
  try {
    const siteId = yield select(getSiteId);
    const shifts: RosteredShiftCreateRequest[] = [];
    const { hasDefaultBreaks, repeatShift, ignore_errors, ...rest } =
      action.payload;

    if (repeatShift && repeatShift.length > 1) {
      for (let shiftItem of repeatShift) {
        shifts.push({
          ...rest,
          ...getUpdatedDate(shiftItem, rest),
          notes: shiftItem.isDisabled ? rest.notes : null,
        });
      }
    }

    const createPayload: BulkRosteredShiftsRequest = {
      shifts: shifts.length ? shifts : [rest],
      ignore_errors: ignore_errors,
    };

    const response: RosteredShiftCreateResponse = yield call(
      processApiRequest,
      Api.RosteredShift.bulkCreate,
      createPayload
    );

    if (relocateToShift(action.payload.start)) {
      const newPageQuery: RosterPageQuery = {
        site_id: siteId,
        day: action.payload.start.format(SERVER_DAY_FORMAT),
      };

      browserHistory.push({
        search: routeQuery.stringify(newPageQuery),
      });
    } else {
      yield put(BOX_ROSTERED_SHIFTS_ADD(response));
    }

    yield put(BOX_ROSTER_SHIFT_MODAL_CREATE_SHIFT_SUCCESS());
    yield call(getRelatedToShiftData, siteId, action.payload.event_id);

    yield put(BOX_ROSTER_SHIFT_MODAL_CLOSE());

  } catch (error) {
    const errorStatus = getErrorStatus(error);
    if (errorStatus === OVERLAP_ERROR_STATUS) {
      yield put(
        BOX_ROSTER_SHIFT_MODAL_OVERLAP_CONFIRMATION_OPEN(
          formatError(error)[0].split('\n')
        )
      );
    } else {
      yield put(
        BOX_ROSTER_SHIFT_MODAL_CREATE_SHIFT_FAILURE(formatError(error))
      );
    }
  }
};

const updateRosteredShift = function* (
  action: SagaAction<UpdatePayload>
): SagaIterator {
  const { OVERLAP_ERROR_STATUS } = Api.RosteredShift;
  try {
    const siteId = yield select(getSiteId);
    const shifts = yield select(getRosteredShifts);
    const needReAccept: boolean = yield call(getNeedReAccept, {
      rosteredShiftId: action.payload.id,
      ignoreErrors: action.payload.ignore_errors,
      userId: action.payload.user_id,
    });

    const { updateRelatedData } = action.payload;
    const response: RosteredShiftUpdateResponse = yield call(
      processApiRequest,
      Api.RosteredShift.updateRosteredShift,
      {
        ...action.payload,
        need_re_accept: needReAccept,
      }
    );

    if (
      response[action.payload.id] &&
      !response[action.payload.id].user_id &&
      shifts[action.payload.id]
    ) {
      if (
        action.payload.event_id &&
        action.payload.event_id !== shifts[action.payload.id].event_id
      ) {
        const eventsList = yield select(getRosterEventsLists);
        const event = eventsList[action.payload.event_id] || null;
      }
    }

    yield put(BOX_ROSTER_SHIFT_MODAL_UPDATE_SHIFT_SUCCESS());
    if (relocateToShift(action.payload.start)) {
      const newPageQuery: RosterPageQuery = {
        site_id: siteId,
        day: action.payload.start.format(SERVER_DAY_FORMAT),
      };

      browserHistory.push({
        search: routeQuery.stringify(newPageQuery),
      });
    } else {
      yield put(BOX_ROSTERED_SHIFTS_ADD(response));
    }

    yield call(triggerDeleteCutShift);
    if (updateRelatedData) {
      yield call(getRelatedToShiftData, siteId, action.payload.event_id);
    }
    yield put(BOX_ROSTER_SHIFT_MODAL_CLOSE());
  } catch (error) {
    const errorStatus = getErrorStatus(error);
    if (errorStatus === OVERLAP_ERROR_STATUS) {
      yield put(
        BOX_ROSTER_SHIFT_MODAL_OVERLAP_CONFIRMATION_OPEN(
          formatError(error)[0].split('\n')
        )
      );
    } else {
      yield put(
        BOX_ROSTER_SHIFT_MODAL_UPDATE_SHIFT_FAILURE(formatError(error))
      );
    }
  }
};

const deleteRosteredShift = function* ({
  payload,
}: SagaAction<DeleteRosteredShiftPayload>): SagaIterator {
  try {
    const siteId = yield select(getSiteId);
    const { updateRelatedData, ...apiPayload } = payload;
    const deletePayload = {
      id: payload.id,
    };
    yield call(processApiRequest, Api.RosteredShift.delete, {
      ...apiPayload,
      auto_publish: true,
    });
    yield put(BOX_ROSTERED_SHIFTS_DELETE(deletePayload));
    yield put(BOX_ROSTER_SHIFT_MODAL_DELETE_SHIFT_SUCCESS());

    if (updateRelatedData) {
      // --- TODO: check connected event ---
      const from = yield select(getFrom);
      const to = yield select(getTo);

      yield call(getEventsRequest, {
        site_id: siteId,
        from: moment(from).startOf('isoWeek'),
        to: moment(to).endOf('isoWeek'),
      });

      // -------------------------------

      yield call(getRelatedToShiftData, siteId);
    }

    yield put(BOX_ROSTER_SHIFT_MODAL_CLOSE());
  } catch (error) {
    yield put(BOX_ROSTER_SHIFT_MODAL_DELETE_SHIFT_FAILURE(formatError(error)));
  }
};

const getShiftUsersList = function* (action: SagaAction<GetShiftUsersPayload>) {
  try {
    const response: any = yield call(
      processApiRequest,
      Api.RosteredShift.usersList,
      action.payload
    );
    yield put(BOX_ROSTER_SHIFT_MODAL_GET_SHIFT_USERS_SUCCESS(response));
  } catch (error) {
    yield put(
      BOX_ROSTER_SHIFT_MODAL_GET_SHIFT_USERS_FAILURE(formatError(error))
    );
  }
};

const getEventsList = function* (action: SagaAction<any>) {
  try {
    const response: any = yield call(
      processApiRequest,
      Api.RosteredShift.eventsList,
      action.payload
    );
    yield put(BOX_ROSTER_SHIFT_MODAL_GET_EVENTS_SUCCESS(response));
  } catch (error) {
    yield put(BOX_ROSTER_SHIFT_MODAL_GET_EVENTS_FAILURE(formatError(error)));
  }
};

const undoRosteredShift = function* (action: SagaAction<any>) {
  try {
    yield call(processApiRequest, Api.RosteredShift.undo, action.payload);

    const isChannelActive = yield select(getChannelIsActive, 'roster_week');
    if (!isChannelActive) {
      const from = yield select(getFrom);
      const to = yield select(getTo);

      yield call(getRosteredShiftsListRequest, {
        site_id: action.payload.site_id,
        to: moment(to).endOf('isoWeek'),
        from: moment(from).startOf('isoWeek'),
      });
    }

    yield call(getRelatedToShiftData, action.payload.site_id);

    if (action.payload.closeModal) {
      yield put(BOX_ROSTER_CLOSE_APPLY_CHANGES_MODAL());
    }

    yield put(BOX_ROSTER_SHIFT_MODAL_UNDO_SHIFT_SUCCESS());
    yield put(BOX_ROSTER_SHIFT_MODAL_CLOSE());
  } catch (error) {
    yield put(BOX_ROSTER_SHIFT_MODAL_UNDO_SHIFT_FAILURE(formatError(error)));
  }
};

const acceptShift = function* ({ payload }: SagaAction<string>): SagaIterator {
  try {
    const response: RosteredShift = yield call(
      processApiRequest,
      Api.RosteredShift.acceptShift,
      payload
    );
    yield put(BOX_ROSTER_SHIFT_MODAL_ACCEPT_SHIFT_SUCCESS());
    yield put(BOX_ROSTERED_SHIFTS_ADD_ONE(response));
    yield put(BOX_ROSTER_SHIFT_MODAL_CLOSE());
  } catch (error) {
    yield put(BOX_ROSTER_SHIFT_MODAL_ACCEPT_SHIFT_FAILURE(formatError(error)));
  }
};

const offerShift = function* ({ payload }: SagaAction<string>): SagaIterator {
  try {
    const response: RosteredShift = yield call(
      processApiRequest,
      Api.RosteredShift.offerShift,
      payload
    );
    yield put(BOX_ROSTER_SHIFT_MODAL_OFFER_SHIFT_SUCCESS());
    yield put(BOX_ROSTERED_SHIFTS_ADD_ONE(response));
    yield put(BOX_ROSTER_SHIFT_MODAL_CLOSE());
  } catch (error) {
    yield put(BOX_ROSTER_SHIFT_MODAL_OFFER_SHIFT_FAILURE(formatError(error)));
  }
};

const notifyUser = function* ({ payload }: SagaAction<string>): SagaIterator {
  try {
    yield call(processApiRequest, Api.RosteredShift.notifyUser, payload);
    yield put(BOX_ROSTER_SHIFT_MODAL_NOTIFY_SUCCESS());
  } catch (error) {
    yield put(BOX_ROSTER_SHIFT_MODAL_NOTIFY_FAILURE(formatError(error)));
  }
};

const triggerContextClose = function* (): SagaIterator {
  yield put(BOX_ROSTER_CONTEXTUAL_MENU_CLOSE());
};

export const watchRosterShiftModal = function* (): SagaIterator {
  yield takeLatest(BOX_ROSTER_SHIFT_MODAL_GET_BY_ID, getRosteredShiftById);
  yield takeLatest(BOX_ROSTER_SHIFT_MODAL_CREATE_SHIFT, createRosteredShift);
  yield takeLatest(BOX_ROSTER_SHIFT_MODAL_UPDATE, updateRosteredShift);
  yield takeLatest(BOX_ROSTER_SHIFT_MODAL_DELETE_SHIFT, deleteRosteredShift);
  yield takeLatest(BOX_ROSTER_SHIFT_MODAL_GET_SHIFT_USERS, getShiftUsersList);
  yield takeLatest(BOX_ROSTER_SHIFT_MODAL_UNDO_SHIFT, undoRosteredShift);
  yield takeLatest(BOX_ROSTER_SHIFT_MODAL_GET_EVENTS, getEventsList);
  yield takeLatest(BOX_ROSTER_SHIFT_MODAL_ACCEPT_SHIFT, acceptShift);
  yield takeLatest(BOX_ROSTER_SHIFT_MODAL_NOTIFY, notifyUser);
  yield takeLatest(BOX_ROSTER_SHIFT_MODAL_OFFER_SHIFT, offerShift);
  yield takeLatest(BOX_ROSTER_SHIFT_MODAL_CLOSE, triggerContextClose);
};
