import _ from 'lodash';
import { BOX_TIME_OFFS_DELETE_MULTIPLE } from 'state/TimeOffs/actions';
import { Dictionary } from 'ts-essentials';
import { Listener } from './types';
import makeDelayedHandler from './utils/makeDelayedHandler';

type TimeOffRosteredShiftDeletedPayload = { id: string };

export const timeOffRosteredShiftDeleted: Listener = (channel, emit) => {
  const handler = (
    collection: Dictionary<TimeOffRosteredShiftDeletedPayload>
  ) => {
    const ids = _.map(collection, ({ id }) => id);
    emit(BOX_TIME_OFFS_DELETE_MULTIPLE(ids));
  };

  channel.onTimeOffRosteredShiftDeleted(
    makeDelayedHandler(
      {
        handler,
      },
      {
        collectionLength: 20,
      }
    )
  );
};
