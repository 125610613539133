import { PAGE_SIZE } from 'lib/config';
import { EventsFilters, EventsReducerState } from './types';
import { cloneDeep } from 'lodash';

export const defaultFilters: EventsFilters = {
  statuses: null,
  start: null,
  end: null,
  sort: {
    column: 'title',
    direction: 'asc',
  },
};

export const getDefaultState = (): EventsReducerState => ({
  isFetched: false,
  isFetching: false,
  events: [],
  errors: [],
  pager: {
    currentPage: 1,
    pageSize: PAGE_SIZE[2],
    total: 0
  },
  period: 'all',
  filters: cloneDeep(defaultFilters),
  filtersForm: cloneDeep(defaultFilters),
  mapModal: {
    isOpen: false,
    marker: {
      lat: 0,
      lng: 0,
    }
  },
  showUpdateNotification: false,
  editedEventId: null,
});
