import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import {
  groupRostersByUsersSelector
} from 'state/Roster/RosterWeekView';
import { DispatchProps, OwnProps, StateProps } from './types';
import { userListSelector } from 'state/UsersCollection';
import {
  filtersByTypeSelector
} from 'state/Roster/RosterFilters';
import {
  getSelectedByUser
} from 'state/Roster/BulkActions/selectors';
import WithArrowsNav from '../../components/WithArrowsNav';
import {
  BOX_SHIFT_TIME_RANGE_INPUT_CLOSED,
  getIsLoading
} from 'state/Roster/RangeInput/ShiftTimeRangeInput';
import { WeekViewUserViewUnassignedRow } from './WeekViewUserViewCashed/WeekViewUserViewUnassignedRow';
import { WeekViewUserViewUserRow } from './WeekViewUserViewCashed/WeekViewUserViewUserRow/UserRows';
import { userRowsNumberSelector } from '../../../../../../state/Roster/RosterWeekViewUserView';
import { times } from 'lodash';

type Props = OwnProps & StateProps & DispatchProps;

class View extends React.Component<Props, {}> {
  render() {
    const {
      isLoading
    } = this.props;
    return (
      <div className={'week-view-container'}>
        <WithArrowsNav
          renderIndex={0}
          closeInput={this.props.closeInput}
          inlineIsLoading={isLoading}
        />
        <WeekViewUserViewUnassignedRow />
        {times(this.props.userRowsNumber, (rowIndex) => (
          <WeekViewUserViewUserRow key={rowIndex} userRowIndex={rowIndex} />
        ))}
      </div>
    );
  };

}

const mapStateToProps = (state: StoreState): StateProps => ({
  users: userListSelector(state),
  filters: filtersByTypeSelector(state),
  selectedByUser: getSelectedByUser(state),
  isLoading: getIsLoading(state),
  groupedRosters: groupRostersByUsersSelector(state),
  userRowsNumber: userRowsNumberSelector(state)
});

const mapToDispatchProps: DispatchProps = {
  closeInput: BOX_SHIFT_TIME_RANGE_INPUT_CLOSED
};

export default connect(mapStateToProps, mapToDispatchProps)(View);