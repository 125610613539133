import * as account from 'state/Account';
import { ErrorsHandler } from 'state/Errors';
import { LoadingHandler } from 'state/Loading';
import { StatusHandler } from 'state/Status';
import * as actions from './actions';

export const getPageLoading = LoadingHandler()
  .handleAsyncLoading(account.BOX_ACCOUNT_ROSTERED_SHIFT_SETTINGS_UPDATE)
  .handleAsyncLoading(account.BOX_ACCOUNT_TIMESHEET_SETTINGS_UPDATE)
  .handleAsyncLoading(account.BOX_ACCOUNT_TIMECLOCK_SETTINGS_UPDATE)
  .handleAsyncLoading(account.BOX_ACCOUNT_PREFERENCES_SETTINGS_UPDATE)
  .makeSelector();

export const getPageErrors = ErrorsHandler()
  .clearErrors(actions.BOX_SETTINGS_LAYOUT_PAGE_CLOSED)
  .clearErrors(actions.BOX_SETTINGS_LAYOUT_ERRORS_CLEARED)
  .handleAsyncErrors(account.BOX_ACCOUNT_ROSTERED_SHIFT_SETTINGS_UPDATE)
  .handleAsyncErrors(
    account.BOX_ACCOUNT_ROSTERED_SHIFT_SETTINGS_DEFAULT_BREAKS_RESET
  )
  .handleAsyncErrors(account.BOX_ACCOUNT_TIMESHEET_SETTINGS_UPDATE)
  .handleAsyncErrors(account.BOX_ACCOUNT_TIMECLOCK_SETTINGS_UPDATE)
  .handleAsyncErrors(account.BOX_ACCOUNT_PREFERENCES_SETTINGS_UPDATE)
  .makeSelector();

export const getPageStatus = StatusHandler()
  .handleAsyncStatus(account.BOX_ACCOUNT_ROSTERED_SHIFT_SETTINGS_UPDATE)
  .handleAsyncStatus(account.BOX_ACCOUNT_TIMESHEET_SETTINGS_UPDATE)
  .handleAsyncStatus(account.BOX_ACCOUNT_TIMECLOCK_SETTINGS_UPDATE)
  .handleAsyncStatus(account.BOX_ACCOUNT_PREFERENCES_SETTINGS_UPDATE)
  .makeSelector();
