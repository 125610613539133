import { createSelector } from 'reselect';
import { filter, map, reduce, uniq } from 'lodash';
import { SelectPropsOption } from 'elmo-elements/Select';
import { StringMap, UserFields, UserRole } from 'type';
import { StoreState } from 'state/types';
import { groupById } from 'state/helpers';
import { getItemSafeCreator } from 'state/combiners';
import {
  activeAreasSelector,
  activeRolesSelector,
  getAreas,
  getRoles,
} from 'state/AccountTree';
import { serverUserFieldsToUserFieldsCombiner } from './combiners';
import { getUserName } from '../../lib/helpers';

// TODO make this selector private
export const getUserListResponse = (state: StoreState) =>
  state.usersCollection.userListResponse;

export const userListSelector = createSelector(
  getUserListResponse,
  serverUserFieldsToUserFieldsCombiner
);

export const userSelector = createSelector(
  userListSelector,
  (state: StoreState, userId: string) => userId,
  (userList, userId) => userList[userId]
);
export const userSafeSelector = getItemSafeCreator(userListSelector);

export const getUserIsActiveSafe = (state: StoreState, id: string) =>
  userSafeSelector(state, id)?.is_active;

export const getUserIsArchived = (state: StoreState, id: string | null) => {
  if (!id) {
    return false;
  }
  return !userSafeSelector(state, id)?.is_active;
};

export const activeUsersSelector = createSelector<
  StoreState,
  StringMap<UserFields>,
  UserFields[]
>(userListSelector, (users) => filter(users, (user) => user.is_active));

export const usersBySiteIdSelector = createSelector<
  StoreState,
  StringMap<UserFields>,
  StringMap<UserFields[]>
>(userListSelector, (userList) =>
  reduce(
    userList,
    (accumulator: StringMap<UserFields[]>, user: UserFields) => {
      const userSiteIds: string[] = uniq(
        user.user_roles.map((userRole: UserRole) => userRole.site_id)
      );

      return reduce(
        userSiteIds,
        (byUserAccumulator: StringMap<UserFields[]>, siteId: string) =>
          groupById(siteId, user, byUserAccumulator),
        accumulator
      );
    },
    {}
  )
);

export const usersAsSelectOptionsArraySelector = createSelector(
  userListSelector,
  (users) =>
    map(users, (user: any): any => ({
      label: getUserName(user),
      value: user.id,
      is_active: user.is_active,
      user_roles: user.user_roles,
    }))
);

export const activeUsersAsSelectOptionsArraySelector = createSelector<
  StoreState,
  UserFields[],
  SelectPropsOption[]
>(activeUsersSelector, (users) =>
  map(users, (user) => ({
    label: user.prefered_or_full_name,
    value: user.id,
  }))
);

export const userIdsSelector = createSelector<
  StoreState,
  StringMap<UserFields>,
  string[]
>(userListSelector, (list) => Object.keys(list));

// Activity filters:
export const activeUserIdsSelector = createSelector<
  StoreState,
  StringMap<UserFields>,
  string[]
>(userListSelector, (users) => {
  const field: keyof UserFields = 'is_active';
  return filter(users, field).map((user) => user.id);
});

export const archivedUserIdsSelector = createSelector<
  StoreState,
  StringMap<UserFields>,
  string[]
>(userListSelector, (users) => {
  const field: keyof UserFields = 'is_active';
  return filter(users, [field, false]).map((user) => user.id);
});

export const userRolesBySiteSelectorAsOptions = (
  state: StoreState,
  userId: string,
  siteId: string
): {
  label: string;
  value: string;
}[] => {
  const list = userListSelector(state);
  const roles = getRoles(state);
  const areas = getAreas(state);
  const user = list[userId];
  const { user_roles } = user;
  const filteredRoles = user_roles.filter((r) => r.site_id === siteId);
  const options: {
    label: string;
    value: string;
  }[] = [];
  filteredRoles.forEach((value) => {
    const area = areas[value.area_id];
    const role = roles[value.role_id];
    if (area && !area.archived && role && !role.archived) {
      options.push({
        label: `${value.area_name} - ${value.role_name}`,
        value: `${value.area_id}__${value.role_id}`,
      });
    }
  });
  return options;
};

export const userRolesBySiteSelector = createSelector(
  (state: StoreState, props: { user_id: string }) =>
    userSafeSelector(state, props.user_id),
  activeAreasSelector,
  activeRolesSelector,
  (state: StoreState, props: { site_id: string }) => props.site_id,
  (user, activeAreas, activeRoles, siteId) => {
    if (!user) {
      return [];
    }

    return user.user_roles.filter((userRole) => {
      const roleArea = activeAreas[userRole.area_id];
      const roleRole = activeRoles[userRole.role_id];
      return (
        userRole.site_id === siteId &&
        roleArea &&
        !roleArea.archived &&
        roleRole &&
        !roleRole.archived
      );
    });
  }
);
