import { useTheme } from 'extended-oxygen-elements';
import { capitalize } from 'lib/helpers';

export function useFormControlSpacing(margin: 'normal' | 'dense' | 'none') {
  const theme = useTheme();

  const margins = {
    ...theme.componentsConfig.formControl,
    marginNone: { marginTop: 0, marginBottom: 0 },
  };

  const { marginTop, marginBottom } =
    margins[`margin${capitalize(margin)}` as const];

  return {
    mt: marginTop + 'px',
    mb: marginBottom + 'px',
  };
}
