import { createSelector } from 'reselect';
import {
  BackendPager,
  FormattedErrors,
  StringMap,
  TimeOff,
  TimeOffsWithPagerSelector
} from 'type';
import { StoreState } from 'state/types';
import {
  SelectedFilter,
  UnavailabilityConfirmApprovalModal,
  UnavailabilityConfirmCreationModal,
  UnavailabilityConfirmEditModal,
  UnavailabilityCreateModal,
  UnavailabilityDeleteModal,
  UnavailabilityEditModal,
  UnavailabilityFilters
} from './types';
import { keyBy, some } from 'lodash';
import { usersSelectOptionsCombiner } from 'state/combiners';
import { userListSelector } from 'state/UsersCollection';

export const getIsUpdating = (state: StoreState): boolean =>
  state.managerDashboard.unavailability.isUpdating;
export const getErrors = (state: StoreState): FormattedErrors =>
  state.managerDashboard.unavailability.errors;
export const getSelectedFilter = (state: StoreState): SelectedFilter =>
  state.managerDashboard.unavailability.selectedFilter;
export const getFiltersForm = (state: StoreState): UnavailabilityFilters =>
  state.managerDashboard.unavailability.filtersForm;
export const getTimeOffsList = (state: StoreState): TimeOff[] =>
  state.managerDashboard.unavailability.timeOffsList;
export const getPager = (state: StoreState): BackendPager =>
  state.managerDashboard.unavailability.pager;
export const getFilters = (state: StoreState): UnavailabilityFilters =>
  state.managerDashboard.unavailability.filters;
export const getCreateModal = (state: StoreState): UnavailabilityCreateModal =>
  state.managerDashboard.unavailability.createModal;
export const getEditModal = (state: StoreState): UnavailabilityEditModal =>
  state.managerDashboard.unavailability.editModal;
export const getDeleteModal = (state: StoreState): UnavailabilityDeleteModal =>
  state.managerDashboard.unavailability.deleteModal;
export const getApproveModal = (
  state: StoreState
): UnavailabilityConfirmApprovalModal =>
  state.managerDashboard.unavailability.confirmApprovalModal;
export const getConfirmCreationModal = (
  state: StoreState
): UnavailabilityConfirmCreationModal =>
  state.managerDashboard.unavailability.confirmCreationModal;
export const getConfirmEditModal = (
  state: StoreState
): UnavailabilityConfirmEditModal =>
  state.managerDashboard.unavailability.confirmEditModal;
export const getIsCurrentlyUpdating = (
  state: StoreState
): string | null =>
  state.managerDashboard.unavailability.isCurrentlyUpdating;
export const getApplicableUsers = (state: StoreState) =>
  state.managerDashboard.unavailability.applicableUsers;
const getApplicableUsersUserIds = (state: StoreState) =>
  state.managerDashboard.unavailability.applicableUsers.userIds;

export const timeOffsCollectionSelector = createSelector<
  StoreState,
  TimeOff[],
  StringMap<TimeOff>
>(getTimeOffsList, timeOffsList => keyBy(timeOffsList, 'id'));

export const getTimeOffById = createSelector<
  StoreState,
  StringMap<TimeOff>,
  string | null,
  boolean
>(timeOffsCollectionSelector, getIsCurrentlyUpdating,  (list: StringMap<TimeOff>, id: string | null) => {
  if ( id !== null && id !== 'undefined' ) {
    return typeof list[id] !== 'undefined';
  }
  return false;
});

export const timeOffsWithPagerSelector = createSelector<
  StoreState,
  TimeOff[],
  BackendPager,
  TimeOffsWithPagerSelector
>(getTimeOffsList, getPager, (page, pager) => ({
  page,
  ...pager
}));

export const isFiltersFormDisabledSelector = createSelector<
  StoreState,
  UnavailabilityFilters,
  boolean
>(getFiltersForm, ({ pending, approved, declined }) => {
  const filtersByType: boolean[] = [pending, approved, declined];

  return !some(filtersByType);
});

export const applicableUsersSelectOptionsSelector = createSelector(
  getApplicableUsersUserIds,
  userListSelector,
  usersSelectOptionsCombiner
);
