import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import View, { Props as ViewProps } from './view';
import {
  BOX_MY_ROSTERS_FORM_FILTER_UPDATED,
  getFiltersForm,
} from 'state/EmployeeDashboard/MyRosters';
import { getSites } from 'state/AccountTree';
import { getLangPreferences } from 'state/Account';

type StateProps = Pick<ViewProps, 'formData' | 'sites' | 'langPreferences'>;

type DispatchProps = Pick<ViewProps, 'onChange'>;

type Props = StateProps & DispatchProps;

const CustomFiltersComponent = (props: Props) => <View {...props} />;

const mapStateToProps = (state: StoreState): StateProps => ({
  formData: getFiltersForm(state),
  sites: getSites(state),
  langPreferences: getLangPreferences(state)
});

const mapDispatchToProps: DispatchProps = {
  onChange: BOX_MY_ROSTERS_FORM_FILTER_UPDATED
};

export const CustomFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomFiltersComponent);
