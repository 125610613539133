import { Box, useTheme } from 'extended-oxygen-elements';
import { PayHeader, PayLayout } from 'page/Settings/page/Pay/components';
import React from 'react';
import FetchPageData from './FetchPageData';
import PageErrors from './PageErrors';
import PayElementsList from './PayElementsList';

function PayElements() {
  const theme = useTheme();

  return (
    <PayLayout header={<PayHeader activeTab="elements" />}>
      <FetchPageData>
        <Box maxWidth={theme.breakpoints.values.md}>
          <PageErrors />

          <PayElementsList />
        </Box>
      </FetchPageData>
    </PayLayout>
  );
}

export default PayElements;
