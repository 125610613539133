import React from 'react';
import { Map, Marker } from 'element';
import { AccountTreeSite } from 'type';
import { Circle } from 'element/mapComponents/Circle';
import { isAppMarket } from 'helpers';

type Props = {
  location: AccountTreeSite;
  isDisabled: boolean;
  findAddressByCoords: (id: string, options: any) => void;
};

const MapWrapper = ({ location, isDisabled, findAddressByCoords }: Props) => {
  const ukCoords = {
    lat: 53.826,
    lng: -2.422
  };
  const auCoords = {
    lat: -33.865,
    lng: 151.209
  };
  const defaultCoords = isAppMarket('uk') ? ukCoords : auCoords;
  const circleProps = {
    center: {
      lat: location.address_lat || defaultCoords.lat,
      lng: location.address_lng || defaultCoords.lng
    },
    radius: location.address_radius
  };

  const mapClick = (e: any) => {
    findAddressByCoords(location.id, {
      lat: e.latLng.lat(),
      lng: e.latLng.lng()
    });
  };

  return (
    <div className={'geofencing-map-wrapper'}>
      {
        !isDisabled
          ? <Map center={{
            lat: location.address_lat || defaultCoords.lat,
            lng: location.address_lng || defaultCoords.lng
          }} zoom={18} style={{ height: '386px'}} onClick={mapClick}>
            {
              location.formatted_address && <Marker position={{
                lat: location.address_lat || 0,
                lng: location.address_lng || 0
              }} />
            }
            {
              location.formatted_address && location.address_radius && <Circle {...circleProps} />
            }
          </Map>
          : null
      }
    </div>
  );
};

export default MapWrapper;