import { ShiftTradesReducerState } from './types';
import { PAGE_SIZE } from 'lib/config';
import moment from 'moment';

export const getDefaultState = (): ShiftTradesReducerState => ({
  isFetching: false,
  isOutdated: {
    list: {
      value: false,
      notificationId: ''
    },
    shiftSwap: {
      value: false,
      notificationId: ''
    }
  },
  changedShiftTradeId: null,
  selectedFilter: 'available',
  shifts: [],
  isTabChangedFromCustomFilters: false,
  isAccepting: '',
  errors: [],
  isDeleting: false,
  currentShiftId: null,
  offeredShiftToSwap: null,
  isDeclining: '',
  isSelecting: '',
  confirmationModal: {
    isOpened: false,
    isUpdating: false,
    errors: []
  },
  pager: {
    pageSize: PAGE_SIZE[0],
    currentPage: 1
  },
  swapModal: {
    isOpened: false,
    errors: [],
    isLoading: false,
    isUpdating: false,
    proposals: [],
    tradeId: null
  },
  filters: {
    from: moment(),
    to: null,
    types: null,
    site: null,
    sort: {
      column: 'start',
      direction: 'asc'
    },
    selectedFilter: 'available'
  },
  filtersForm: {
    from: moment(),
    to: null,
    types: null,
    site: null,
    sort: {
      column: 'start',
      direction: 'asc'
    },
    selectedFilter: 'available'
  }
});
