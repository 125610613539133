import React from 'react';
import { compact } from 'lodash';
import { Dictionary } from 'ts-essentials';
import {
  AssignmentIndOutlinedIcon,
  CallMadeOutlinedIcon,
  CallReceivedOutlinedIcon,
  ErrorOutlineIcon,
  HelpOutlineIcon,
  NotesOutlinedIcon,
  SpeakerNotesOffOutlinedIcon,
  SwapHorizOutlinedIcon,
} from 'element';
import { RosteredShift, RosteredShiftStatus } from 'type';
import { orange, purple, red } from '../../constants';
import { CardIconsPropsIcon } from '../../views';

const statusConfig: Dictionary<CardIconsPropsIcon | null, RosteredShiftStatus> =
  {
    'offer awaiting approval': {
      title: 'Offer awaiting approval',
      color: orange,
      Icon: CallReceivedOutlinedIcon,
    },
    'swap awaiting approval': {
      title: 'Swap offer awaiting approval',
      color: orange,
      Icon: SwapHorizOutlinedIcon,
    },
    offer: {
      title: 'Offered',
      color: purple,
      Icon: CallMadeOutlinedIcon,
    },
    swap: {
      title: 'Swap offered',
      color: purple,
      Icon: SwapHorizOutlinedIcon,
    },
    deleted: null,
    none: null,
  };

type Props = Pick<
  RosteredShift,
  'status' | 'is_overlap_error' | 'is_accepted' | 'user_id' | 'notify_user' | 'notes'
>;

const useIcons = ({
  status,
  is_overlap_error,
  is_accepted,
  user_id,
  notify_user,
  notes
}: Props) => {
  const icon = statusConfig[status];

  return React.useMemo(
    () =>
      compact<CardIconsPropsIcon>([
        icon,
        is_overlap_error && {
          title: 'Shift overlaps',
          color: red,
          Icon: ErrorOutlineIcon,
        },
        !is_accepted &&
          user_id && {
            title: 'Awaiting acceptance',
            color: orange,
            Icon: AssignmentIndOutlinedIcon,
          },
        !notify_user && {
          title: 'Not notified',
          color: red,
          Icon: SpeakerNotesOffOutlinedIcon,
        },
        !user_id && {
          title: 'Unassigned',
          color: orange,
          Icon: HelpOutlineIcon,
        },
        notes && {
          title: 'Notes',
          color: red,
          Icon: NotesOutlinedIcon
        }
      ]),
    [icon, is_overlap_error, is_accepted, user_id, notify_user, notes]
  );
};

export default useIcons;
