import React from 'react';
import {
  bindHoverTrigger,
  useHoverPopupState,
  useSelectorWithProps,
} from 'hooks';
import { timeOffById } from 'state/TimeOffs';
import { IsAppMarket, WorkOffOutlinedIcon, NotesIconButton } from 'element';
import { createStyles, makeStyles } from '@mui/styles';
import { TimeOffCardPopperAdd } from './components';
import useHandleClickAddButton from './hooks/useHandleClickAddButton';
import moment from 'moment-timezone';
import { SERVER_DAY_FORMAT } from 'lib/config';
import { useSelector } from 'react-redux';
import { getSiteId } from 'state/RosteredShiftsCollection';
import { getSites } from 'state/AccountTree';
import { isMobileDevice, getLeaveSubtypeLabel } from 'lib/helpers';
import clsx from 'clsx';

type Props = {
  id: string;
  day: string;
};

const useTimeOffStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: 'inherit',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      padding: '5px 8px',
    },
    btn: {},
    icon: {
      position: 'absolute',
      top: 5,
      right: 6,
      fontSize: 18,
    },
    notes: {
      position: 'absolute',
      bottom: 0,
      right: 0,
    },
    content: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 0.5,
    },
    info: {
      fontSize: 12,
      fontWeight: 500,
      letterSpacing: 0.5,
    },
  })
);

export const LeaveCard = ({ id, day }: Props) => {
  const classes = useTimeOffStyles();
  const timeOff = useSelectorWithProps(timeOffById, id);
  const onClickAddBtn = useHandleClickAddButton(timeOff, day);
  const siteId = useSelector(getSiteId);
  const sites = useSelector(getSites);
  const timeZoneId = sites[siteId].timezone_id;
  const hoverPopupState = useHoverPopupState<HTMLDivElement>();
  const formattedDate = moment(+day)
    .tz(timeZoneId)
    .format(SERVER_DAY_FORMAT);

  const HalfDay = (props: { part: 'AM' | 'PM' }) => (
    <div className={classes.content}>
      Half day <IsAppMarket market={'uk'}>{props.part}</IsAppMarket>
    </div>
  );

  const partDayLine = () => {
    const { options, start, end } = timeOff;
    const isFirstDay = start.format(SERVER_DAY_FORMAT) === formattedDate;
    const isLastDay = end.format(SERVER_DAY_FORMAT) === formattedDate;
    if (options) {
      const { half_end, half_start } = options!;
      if (isFirstDay && half_start) {
        return <HalfDay part={'PM'} />;
      }
      if (isLastDay && half_end) {
        return <HalfDay part={'AM'} />;
      }
    }
    return null;
  };

  return (
    <div
      onDoubleClick={(e) => e.stopPropagation()}
      data-testid={`leave-card leave-card-${id}`}
      className={clsx(classes.root, 'leave-card')}
      {...bindHoverTrigger(hoverPopupState)}
      onClick={isMobileDevice() ? hoverPopupState.open : () => false}
    >
      <div className={classes.content}>
        <WorkOffOutlinedIcon className={classes.icon} />
        {onClickAddBtn && (
          <NotesIconButton
            className={classes.notes}
            notes={timeOff.notes}
            id={id}
            label={getLeaveSubtypeLabel(timeOff.subtype, false)}
            onClick={(e) => {
              e.stopPropagation();
              hoverPopupState.close();
            }}
          />
        )}
        <div className={classes.info}>
          {getLeaveSubtypeLabel(timeOff.subtype, true)}
        </div>
        <div>{partDayLine()}</div>
      </div>
      <TimeOffCardPopperAdd
        onClick={onClickAddBtn}
        className={classes.btn}
        isVisible={hoverPopupState.isOpened}
      />
    </div>
  );
};
