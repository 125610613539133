import React, { Component } from 'react';
import { Input } from 'elmo-elements';
import { DurationProps } from './types';

import './DurationInput.scss';
import { formatStringDurationToMinutes } from 'lib/helpers';

type State = {
  value: string;
  isActive: boolean;
};

// TODO remove this component and replace with DurationInputMinutes
export class DurationInput extends Component<DurationProps, State> {
  constructor(props: DurationProps) {
    super(props);
    this.state = {
      value: this.convertValue(this.props.value),
      isActive: false
    };
  }

  componentDidMount(): void {
    const input = document.querySelector('.duration-input-wrapper input');
    if (input !== null) {
      input.setAttribute('autocomplete', 'off');
    }
  }

  componentDidUpdate(prevProps: Readonly<DurationProps>) {
    if (
      !this.state.isActive &&
      !isNaN(this.props.value) &&
      prevProps.value !== this.props.value
    ) {
      this.setState({
        value: this.convertValue(this.props.value)
      });
    }
  }

  render() {
    const { value } = this.state;
    const { name, id, ariaLabel, tabIndex } = this.props;
    return (
      <div className={'duration-input-wrapper'}>
        <div className={'state-input'}>
          <Input
            id={id ? id : 'duration-input'}
            onChange={this.handleOnChange}
            onBlur={this.handleOnBlur}
            value={value}
            name={name}
            ariaLabel={ariaLabel}
            tabIndex={tabIndex}
          />
        </div>
      </div>
    );
  }

  handleOnBlur = () => {
    this.setState({
      value: this.convertStringValue(this.state.value.toString().replace(/-/i, '')),
      isActive: false
    });
  };

  handleOnChange = (e: any) => {
    const {
      target: { value }
    } = e;

    this.setState({
      ...this.state,
      value: value,
      isActive: true
    });

    if (this.props.onChangeHandler) {
      this.props.onChangeHandler(e);
    }
  };

  convertValue = (value: number) => {
    return this.convertStringValue(value.toString().replace(/-/i, ''));
  };

  convertStringValue = formatStringDurationToMinutes;
}

export default DurationInput;
