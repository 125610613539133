import React, { Component } from 'react';
import './style.scss';
import SummaryButton from './components/SummaryButton';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import {
  capitalize,
  formatMinutesAsHours,
  getCurrencyFormatted,
} from 'lib/helpers';
import { SummaryDay } from 'type';
import {
  getSummary,
  getSummaryLoading,
  getSummaryStatus,
} from 'state/Roster/Summary/selectors';
import {
  BOX_SUMMARY_REMOVE_ERRORS,
  BOX_SUMMARY_SET_STATUS,
} from 'state/Roster/Summary/actions';
import { DispatchProps, OwnProps, StateProps } from './types';
import { hasPermissionSelector } from 'state/Auth';
import {
  getCurrencyCode,
  getCurrencyPlacement,
  getLangPreferences,
  getNumberFormat,
} from 'state/Account';
import DayInfo from './components/DayInfo';
import { RosterSummary } from '../../../RosterDayView/components/RosterSummary';
import { RosterRow } from '../../../../components/RosterRow';
import Legend from './components/Legend';
import { SummaryData } from './components/SummaryData';
import SummaryInfo from './components/SummaryData/components/SummaryInfo';
import SummaryDays from './components/SummaryDays';
import { filtersByTypeSelector } from 'state/Roster/RosterFilters';
import { getIsCovidModeEnabled } from 'state/Roster/CovidTracing';
import { isAppMarket } from '../../../../../../helpers';

type Props = OwnProps & StateProps & DispatchProps;

const elements: { [index: string]: string } = {
  collapseBtn: '.collapse-panel button',
  scrollableBlock: '.roster-view-scrollable-wrapper__content-wrapper',
  days: '.summary-desktop .summary-days',
  daysContent: '.summary-desktop .summary-days__content',
  rostersContent: '.roster-row__content-wrapper.roster-map',
  rostersScroll: '.roster-view-scrollable-wrapper__content-wrapper',
};

export class WeekSummary extends Component<Props> {
  render() {
    const { isCovidModeEnabled } = this.props;

    if (isCovidModeEnabled) {
      return null;
    }

    return (
      <RosterSummary>
        <div className="summary-desktop">{this.getContent()}</div>
      </RosterSummary>
    );
  }

  getContent = () => {
    const { status, isLoading } = this.props;

    return (
      <div className="week-summary">
        <div
          className={getClasses('week-summary__wrap', {
            'week-summary__wrap--hidden': status === 'hidden',
          })}
        >
          <SummaryButton />
          {status !== 'hidden' && !isLoading && (
            <div className="week-summary__data">
              {this.getTotal()}
              <SummaryDays>{this.getDaysContent()}</SummaryDays>
            </div>
          )}
        </div>
      </div>
    );
  };

  getTotal = () => {
    const {
      rosterPermission,
      timesheetPermission,
      filters: { timesheets, roster },
    } = this.props;
    return (
      <SummaryData>
        <div className="week-summary__data-summary">
          {rosterPermission &&
            roster &&
            this.getSummaryLegendInfo('rostered_shift')}
          {timesheetPermission &&
            timesheets &&
            this.getSummaryLegendInfo('timesheet')}
        </div>
      </SummaryData>
    );
  };

  getDaysContent = () => {
    const {
      summary: { days },
      rosterPermission,
      timesheetPermission,
      filters: { timesheets, roster },
    } = this.props;
    return (
      <>
        {roster && (
          <div className="week-summary__days-section" id="shifts-days">
            {Object.keys(days).map((d: string, index: number) => (
              <div className="week-summary__day" key={index}>
                {rosterPermission &&
                  this.getSummaryInfo('rostered_shift', days[d])}
              </div>
            ))}
          </div>
        )}
        {timesheets && (
          <div className="week-summary__days-section" id="timesheets-days">
            {Object.keys(days).map((d: string, index: number) => (
              <div className="week-summary__day" key={index}>
                {timesheetPermission &&
                  this.getSummaryInfo('timesheet', days[d])}
              </div>
            ))}
          </div>
        )}
      </>
    );
  };

  getSummaryInfo = (type: string, dayInfo: SummaryDay) => {
    const userCount = `${type}_user_count`;
    const shiftCount = `${type}_count`;
    const day: { [key: string]: number } = dayInfo;

    let usersHighlight = false;
    let shiftsHighlight = false;

    if (type === 'timesheet') {
      if (day[`rostered_shift_user_count`] < day[`timesheet_user_count`]) {
        usersHighlight = true;
      }

      if (day[`rostered_shift_count`] < day[`timesheet_count`]) {
        shiftsHighlight = true;
      }
    }
    return (
      <DayInfo
        shiftCount={day[shiftCount]}
        duration={this.getDuration(day[`${type}_duration`])}
        userCount={day[userCount]}
        currency={this.getCurrency(day[`${type}_cost`], type)}
        usersHighlight={usersHighlight}
        shiftsHighlight={shiftsHighlight}
      />
    );
  };

  getSummaryLegendInfo = (type: 'rostered_shift' | 'timesheet') => {
    const {
      summary: { total },
      langPreferences,
    } = this.props;

    const rosterText = isAppMarket('uk')
      ? 'Rota'
      : capitalize(langPreferences.roster.singular);
    const title = type === 'timesheet' ? 'Timesheet' : rosterText;

    const day: { [key: string]: number } = total;

    const data = {
      type,
      shiftCount: day[`${type}_count`],
      userCount: day[`${type}_user_count`],
      duration: this.getDuration(day[`${type}_duration`]),
      currency: this.getCurrency(day[`${type}_cost`], type),
      title,
      className:
        type === 'timesheet'
          ? 'summary-info__timesheet'
          : 'summary-info__shift',
    };

    return <SummaryInfo {...data} />;
  };

  getCurrency = (value: number, type: string) => {
    const { rosterCostPermission, timesheetCostPermission } = this.props;
    const { numberFormat, currencyCode, currencyPlacement } = this.props;
    if (type === 'rostered_shift' && !rosterCostPermission) {
      return null;
    }
    if (type === 'timesheet' && !timesheetCostPermission) {
      return null;
    }
    return (
      getCurrencyFormatted(
        numberFormat,
        currencyCode,
        currencyPlacement,
        value,
        true
      ) + ' '
    );
  };

  getDuration = (value: number = 66) => {
    return formatMinutesAsHours(value) + 'h';
  };

  shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return this.props !== nextProps;
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps !== this.props) {
      this.calculateWidth();
    }
  }

  componentDidMount(): void {
    const btn = this.getElement('collapseBtn');
    const scrollableBlock = this.getElement('rostersScroll');
    if (btn !== null && scrollableBlock !== null) {
      btn.addEventListener('click', this.animateWidth, true);
      scrollableBlock.addEventListener('scroll', this.calculateWidth, true);
    }
    window.addEventListener('resize', this.calculateWidth, true);
    this.calculateWidth();
  }

  componentWillUnmount(): void {
    const btn = this.getElement('collapseBtn') as HTMLBodyElement;
    const scrollableBlock = this.getElement('rostersScroll') as HTMLBodyElement;
    if (scrollableBlock !== null && btn !== null) {
      window.removeEventListener('resize', this.calculateWidth);
      btn.removeEventListener('click', this.animateWidth);
      scrollableBlock.removeEventListener('scroll', this.calculateWidth);
    }

    this.props.setStatus('hidden');
  }

  calculateWidth = () => {
    const days = this.getElement('days') as HTMLBodyElement;
    const content = this.getElement('daysContent') as HTMLBodyElement;

    if (days !== null) {
      days.setAttribute('style', `width: ${this.getWidth()}px`);
      content.setAttribute(
        'style',
        `left: -${this.getScrollbarWidth()}px; width: ${this.getWidth()}px`
      );
    }
  };

  getWidth = () => {
    let width = 0;
    const content = this.getElement('rostersContent');
    if (content !== null) {
      width = (content as HTMLBodyElement).offsetWidth;
    }
    return width;
  };

  getScrollbarWidth = () => {
    const element = this.getElement('rostersScroll');
    if (element !== null) {
      return (element as HTMLBodyElement).scrollLeft;
    }
    return 0;
  };

  animateWidth = () => {
    const interval = setInterval(() => {
      this.calculateWidth();
    });
    setTimeout(() => {
      clearInterval(interval);
    }, 1000);
  };

  getElement(element: string) {
    return document.querySelector(elements[element]);
  }
}

export function getClasses(
  className: string,
  classNames: { [key: string]: boolean | undefined }
) {
  const classes = Object.keys(classNames).map((key) => {
    if (classNames[key]) {
      return key;
    }
  });
  return `${className} ${classes.join(' ')}`;
}

const mapStateToProps = (state: StoreState): StateProps => ({
  summary: getSummary(state),
  timesheetPermission: hasPermissionSelector(state, 'roster.timesheet.view'),
  rosterPermission: hasPermissionSelector(state, 'roster.rosteredshift.view'),
  timesheetCostPermission: hasPermissionSelector(
    state,
    'roster.timesheet.view.labourcostings'
  ),
  timesheetPercentPermission: hasPermissionSelector(
    state,
    'roster.timesheet.view.labourpercentages'
  ),
  rosterCostPermission: hasPermissionSelector(
    state,
    'roster.rosteredshift.view.labourcostings'
  ),
  rosterPercentPermission: hasPermissionSelector(
    state,
    'roster.rosteredshift.view.labourpercentages'
  ),
  eventPermission: hasPermissionSelector(state, 'roster.events.view'),
  numberFormat: getNumberFormat(state),
  currencyCode: getCurrencyCode(state),
  currencyPlacement: getCurrencyPlacement(state),
  status: getSummaryStatus(state),
  langPreferences: getLangPreferences(state),
  filters: filtersByTypeSelector(state),
  isCovidModeEnabled: getIsCovidModeEnabled(state),
  isLoading: getSummaryLoading(state),
});

const mapToDispatchProps: DispatchProps = {
  removeErrors: BOX_SUMMARY_REMOVE_ERRORS,
  setStatus: BOX_SUMMARY_SET_STATUS,
};

export default connect(mapStateToProps, mapToDispatchProps)(WeekSummary);
