import React, { Component } from 'react';
import { find } from 'lodash';
import { Select as ElmoSelect } from 'elmo-elements';
import { SelectPropsOption } from 'elmo-elements/Select';
import {
  OnChangeClearableSelectEvent,
  SingleClearableSelectProps,
  SingleSelectProps
} from '../types';
import { isGroupedOptions, ungroupOptions } from './helpers';

type Props = SingleClearableSelectProps | SingleSelectProps;

export class SingleSelect extends Component<Props> {
  render() {
    return (
      <ElmoSelect
        id={this.props.id}
        isClearable={this.props.isClearable}
        isMulti={this.props.isMulti}
        value={this.getValue}
        options={this.props.options}
        onChange={this.onChange}
        className={this.props.className}
        placeholder={this.props.placeholder}
        isDisabled={this.props.isDisabled}
        ariaLabel={this.props.ariaLabel}
      />
    );
  }

  private get getValue(): SelectPropsOption | null {
    const { options: groupedOrSimpleOptions } = this.props;

    let options: SelectPropsOption[];
    if (isGroupedOptions(groupedOrSimpleOptions)) {
      options = ungroupOptions(groupedOrSimpleOptions);
    } else {
      options = groupedOrSimpleOptions;
    }

    const foundValue = find(options, ['value', this.props.value]);
    return foundValue ? foundValue : null;
  }

  private onChange = (option: SelectPropsOption | null) => {
    const { name } = this.props;
    const onChange = this.props.onChange as (
      event: OnChangeClearableSelectEvent
    ) => void;

    onChange({
      name,
      value: option && option.value
    });
  };
}
