import { defaultMemoize } from 'reselect';
import { filteredByIdCombinerCreator } from 'state/combiners';
import { makeRoleFilter } from './makeRoleFilter';

export type Shift = { area_id: string; role_id: string };

const filterByAreaId = defaultMemoize(filteredByIdCombinerCreator('area_id'));
const filterByRoleId = defaultMemoize(filteredByIdCombinerCreator('role_id'));

export const shiftRoleFilter = makeRoleFilter<Shift>({
  flatFilter: ({ areas, roles }, shifts) => {
    const filteredByAreaId = filterByAreaId(shifts, areas) as Shift[];
    return filterByRoleId(filteredByAreaId, roles) as Shift[]
  },
  treeFilter: ({ roleTriples }, shifts) => {
    if (!roleTriples) {
      return shifts;
    }

    return shifts.filter((rosteredShift) =>
      roleTriples.some(
        (roleTriple) =>
          roleTriple.areaId === rosteredShift.area_id &&
          roleTriple.roleId === rosteredShift.role_id
      )
    );
  },
});
