import React, { ReactNode } from 'react';
import './SelectListOptionHeader.scss';

export type SelectListOptionHeaderProps = {
  ariaLevel?: number;
  children: ReactNode;
};

function SelectListOptionHeader({
  ariaLevel = 1,
  children,
}: SelectListOptionHeaderProps) {
  return (
    <div
      className="elmo-select-list__option-header"
      role="heading"
      aria-level={ariaLevel}
    >
      {children}
    </div>
  );
}

export default SelectListOptionHeader;
