import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import {
  activeAreasWithSiteNameAsSelectOptionsSelector,
  activeRolesAsSelectOptionsArraySelector
} from 'state/AccountTree/selectors';
import { BOX_USERS_UPDATE_FILTER_FORM, getUserFiltersForm } from 'state/Users/Users';
import { userGroupsAsSelectOptionsArraySelector } from 'state/UserGroups/selectors';
import { getLangPreferences } from 'state/Account/selectors';
import View, { Props as ViewProps } from './view';

type StateProps = Pick<
  ViewProps,
  | 'formData'
  | 'roles'
  | 'areasWithSiteName'
  | 'userGroups'
  | 'langPreferences'
>;

type DispatchProps = Pick<ViewProps, 'onChange'>;

type Props = StateProps & DispatchProps;

const CustomFiltersComponent = (props: Props) => <View {...props} />;

const mapStateToProps = (state: StoreState): StateProps => ({
  formData: getUserFiltersForm(state),
  roles: activeRolesAsSelectOptionsArraySelector(state),
  areasWithSiteName: activeAreasWithSiteNameAsSelectOptionsSelector(state),
  userGroups: userGroupsAsSelectOptionsArraySelector(state),
  langPreferences: getLangPreferences(state),
});

const mapDispatchToProps: DispatchProps = {
  onChange: BOX_USERS_UPDATE_FILTER_FORM
};

export const CustomFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomFiltersComponent);
