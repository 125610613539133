import { Link, FormItem } from 'elmo-elements';
import React, { useEffect, useState } from 'react';
import { useSelectorWithProps } from 'hooks';
import {
  BOX_ROSTER_CLOSE_APPLY_CHANGES_MODAL,
  BOX_ROSTER_DELETE_CLEAR_ERRORS,
  BOX_ROSTER_TIMESHEETS_CSV_REQUEST,
  getErrors,
  getExportResponse,
  getIsGenerating,
  getIsModalOpen,
} from 'state/Roster/Roster';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { SERVER_DAY_FORMAT } from 'lib/config';
import { getFrom, getSiteId, getTo } from 'state/RosteredShiftsCollection';
import {
  DateInputMoment,
  ErrorBox,
  PageDialog,
  PageDialogCancel,
  PageDialogSubmit,
} from 'element';
import { Box, Alert, DialogContent } from 'extended-oxygen-elements';
import { DialogActions, DialogTitle } from 'extended-oxygen-elements';
import { Typography } from 'oxygen-elements';

const DownloadCSVModal = () => {
  const isOpened = useSelectorWithProps(getIsModalOpen, 'csv');
  const from = useSelector(getFrom);
  const to = useSelector(getTo);
  const siteId = useSelector(getSiteId);
  const dispatch = useDispatch();
  const response = useSelector(getExportResponse);
  const isGenerating = useSelector(getIsGenerating);
  const [start, setStart] = useState<Moment>(from.clone());
  const [end, setEnd] = useState<Moment>(to.clone());
  const errors = useSelector(getErrors);

  const closeModal = () => {
    dispatch(BOX_ROSTER_CLOSE_APPLY_CHANGES_MODAL());
  };

  const onDateRangeChange = (value: Moment | null, name: string) => {
    if (name === 'start') {
      setStart(moment(value));
    } else {
      setEnd(moment(value));
    }
    return;
  };

  const generateCSV = () => {
    dispatch(
      BOX_ROSTER_TIMESHEETS_CSV_REQUEST({
        site_id: siteId,
        period: {
          from: start.format(SERVER_DAY_FORMAT),
          to: end.format(SERVER_DAY_FORMAT),
        },
      })
    );
  };

  const showNoReportMsg = () => {
    const { message } = response;
    return <Alert severity={'warning'}>{message}</Alert>;
  };

  const showReportInfo = () => {
    const { info, downLoadLink } = response;
    return (
      <>
        {info && (
          <Alert severity={'warning'} style={{ margin: '15px 0' }}>
            {info}
          </Alert>
        )}
        {downLoadLink && (
          <Typography variant={'body1'}>
            <Link newWindow={true} url={downLoadLink}>
              Download report
            </Link>
          </Typography>
        )}
      </>
    );
  };

  const disabledDatesTo = (current?: Date): boolean => {
    if (current && start !== null) {
      return moment(current) < start;
    }

    return false;
  };

  const disabledDatesFrom = (current?: Date): boolean => {
    if (current && end !== null) {
      return moment(current) > end;
    }

    return false;
  };

  useEffect(() => {
    setStart(from);
    setEnd(to);
  }, [isOpened]);

  return (
    <PageDialog
      maxWidth={'xs'}
      open={isOpened}
      id={'download-timesheets-modal'}
      onClose={closeModal}
    >
      <DialogTitle>Timesheets report</DialogTitle>
      <DialogContent>
        {errors.length > 0 && (
          <ErrorBox
            errors={errors}
            clearErrors={() => dispatch(BOX_ROSTER_DELETE_CLEAR_ERRORS())}
          />
        )}
        <Typography variant={'body1'} sx={{ paddingBottom: '15px' }}>
          Please specify period for exported timesheets
        </Typography>
        <FormItem label={'From'}>
          <DateInputMoment
            name={'start'}
            onChange={(e: Moment | null) => onDateRangeChange(e, 'start')}
            value={start}
            placeholder={SERVER_DAY_FORMAT}
            disabledDate={disabledDatesFrom}
            isInline={false}
            isReadOnly={true}
            className={'bg-white'}
            ariaLabel="Date to"
          />
        </FormItem>
        <FormItem label={'To'}>
          <DateInputMoment
            name={'end'}
            onChange={(e: Moment | null) => onDateRangeChange(e, 'end')}
            value={end}
            placeholder={SERVER_DAY_FORMAT}
            disabledDate={disabledDatesTo}
            isInline={false}
            isReadOnly={true}
            className={'bg-white'}
            ariaLabel="Date to"
          />
        </FormItem>
        <Box marginTop={4}>
          {response.message && !isGenerating
            ? showNoReportMsg()
            : showReportInfo()}
        </Box>
      </DialogContent>
      <DialogActions>
        <PageDialogCancel onClick={closeModal}>Cancel</PageDialogCancel>
        <PageDialogSubmit
          disabled={!start || !end || isGenerating}
          onClick={generateCSV}
          loading={isGenerating}
        >
          Generate
        </PageDialogSubmit>
      </DialogActions>
    </PageDialog>
  );
};

export default DownloadCSVModal;
