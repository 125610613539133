import { createReducer } from 'lib/store-utils';
import { RosterShiftModalReducerState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';

export const rosterShiftModal = createReducer<RosterShiftModalReducerState>(
  {},
  getDefaultState()
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_OPEN,
  (state): RosterShiftModalReducerState => ({
    ...state,
    rosteredShiftModal: {
      ...state.rosteredShiftModal,
      isOpened: true,
      isLoading: false
    }
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_OPEN_WITH_LOADER,
  (state): RosterShiftModalReducerState => ({
    ...state,
    rosteredShiftModal: {
      ...state.rosteredShiftModal,
      isOpened: true,
      isLoading: true
    }
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_CLOSE,
  (state): RosterShiftModalReducerState => ({
    ...state,
    rosteredShiftModal: {
      ...state.rosteredShiftModal,
      isOpened: false,
      isUpdating: false,
      errors: []
    },
    currentRosteredShift: getDefaultState().currentRosteredShift,
    initialOwner: null
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_UPDATE_SHIFT_PROPS,
  (state, current): RosterShiftModalReducerState => ({
    ...state,
    currentRosteredShift: current
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_SET_SHIFT,
  (state, shiftParams): RosterShiftModalReducerState => ({
    ...state,
    currentRosteredShift: {
      ...state.currentRosteredShift,
      ...shiftParams,
      is_pasted: !!shiftParams.menu_action
    },
    rosteredShiftModal: {
      ...state.rosteredShiftModal,
      isLoading: false
    }
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_GET_BY_ID_SUCCESS,
  (state, response): RosterShiftModalReducerState => {
    return {
      ...state,
      currentRosteredShift: response,
      rosteredShiftModal: {
        ...state.rosteredShiftModal,
        isLoading: false
      },
      initialOwner: response.user_id
    };
  }
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_PASTE_DATA,
  (state, data): RosterShiftModalReducerState => ({
    ...state,
    currentRosteredShift: {
      ...state.currentRosteredShift,
      ...data
    },
    initialOwner: data.user_id!
})
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_GET_BY_ID_FAILURE,
  (state, errors): RosterShiftModalReducerState => {
    return {
      ...state,
      rosteredShiftModal: {
        ...state.rosteredShiftModal,
        isLoading: false,
        errors: errors
      }
    };
  }
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_CREATE_SHIFT,
  (state): RosterShiftModalReducerState => ({
    ...state,
    rosteredShiftModal: {
      ...state.rosteredShiftModal,
      isUpdating: true,
      errors: []
    }
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_CREATE_SHIFT_SUCCESS,
  (state): RosterShiftModalReducerState => {
    return {
      ...state,
      rosteredShiftModal: {
        ...state.rosteredShiftModal,
        isUpdating: false
      }
    };
  }
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_CREATE_SHIFT_FAILURE,
  (state, errors): RosterShiftModalReducerState => ({
    ...state,
    rosteredShiftModal: {
      ...state.rosteredShiftModal,
      isUpdating: false,
      errors: errors
    }
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_OVERLAP_CONFIRMATION_OPEN,
  (state, errors): RosterShiftModalReducerState => ({
    ...state,
    rosteredShiftModal: {
      ...state.rosteredShiftModal,
      isUpdating: false
    },
    overlapShiftModal: {
      ...state.overlapShiftModal,
      errors: errors,
      isOpened: true
    }
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_OVERLAP_CONFIRMATION_CLOSE,
  (state, isUpdating): RosterShiftModalReducerState => ({
    ...state,
    rosteredShiftModal: {
      ...state.rosteredShiftModal,
      isUpdating: isUpdating
    },
    overlapShiftModal: {
      ...state.overlapShiftModal,
      isOpened: false,
      errors: []
    }
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_CLEAR_ERRORS,
  (state): RosterShiftModalReducerState => ({
    ...state,
    errors: [],
    rosteredShiftModal: {
      ...state.rosteredShiftModal,
      errors: []
    },
    overlapShiftModal: {
      ...state.overlapShiftModal,
      errors: []
    }
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_UPDATE,
  (state): RosterShiftModalReducerState => ({
    ...state,
    rosteredShiftModal: {
      ...state.rosteredShiftModal,
      isUpdating: true,
      errors: []
    }
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_UPDATE_SHIFT_SUCCESS,
  (state): RosterShiftModalReducerState => {
    return {
      ...state,
      rosteredShiftModal: {
        ...state.rosteredShiftModal,
        isUpdating: false
      }
    }
  }
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_UPDATE_SHIFT_FAILURE,
  (state, errors): RosterShiftModalReducerState => ({
    ...state,
    rosteredShiftModal: {
      ...state.rosteredShiftModal,
      isUpdating: false,
      errors: errors
    }
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_DELETE_SHIFT,
  (state): RosterShiftModalReducerState => ({
    ...state,
    rosteredShiftModal: {
      ...state.rosteredShiftModal,
      isUpdating: true
    }
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_DELETE_SHIFT_SUCCESS,
  (state): RosterShiftModalReducerState => ({
    ...state,
    rosteredShiftModal: {
      ...state.rosteredShiftModal,
      isUpdating: false,
      errors: []
    }
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_DELETE_SHIFT_FAILURE,
  (state, errors): RosterShiftModalReducerState => ({
    ...state,
    rosteredShiftModal: {
      ...state.rosteredShiftModal,
      isUpdating: false,
      errors: errors
    }
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_GET_SHIFT_USERS,
  (state): RosterShiftModalReducerState => ({
    ...state,
    usersLoader: true
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_GET_SHIFT_USERS_FAILURE,
  (state, errors): RosterShiftModalReducerState => ({
    ...state,
    usersLoader: false,
    errors: errors
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_GET_SHIFT_USERS_SUCCESS,
  (state, list): RosterShiftModalReducerState => ({
    ...state,
    usersList: list,
    usersLoader: false
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_UNDO_SHIFT,
  (state): RosterShiftModalReducerState => ({
    ...state,
    rosteredShiftModal: {
      ...state.rosteredShiftModal,
      isUpdating: true
    }
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_UNDO_SHIFT_SUCCESS,
  (state): RosterShiftModalReducerState => ({
    ...state,
    rosteredShiftModal: {
      ...state.rosteredShiftModal,
      isUpdating: false
    }
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_UNDO_SHIFT_FAILURE,
  (state, errors): RosterShiftModalReducerState => ({
    ...state,
    rosteredShiftModal: {
      ...state.rosteredShiftModal,
      isUpdating: false,
      errors: errors
    }
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_GET_EVENTS_SUCCESS,
  (state, list): RosterShiftModalReducerState => ({
    ...state,
    eventsList: list
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_ACCEPT_SHIFT,
  (state, id): RosterShiftModalReducerState => ({
    ...state,
    rosteredShiftModal: {
      ...state.rosteredShiftModal,
      isUpdating: true,
      errors: []
    }
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_ACCEPT_SHIFT_SUCCESS,
  (state): RosterShiftModalReducerState => ({
    ...state,
    rosteredShiftModal: {
      ...state.rosteredShiftModal,
      isUpdating: false,
      errors: []
    }
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_ACCEPT_SHIFT_FAILURE,
  (state, errors): RosterShiftModalReducerState => ({
    ...state,
    rosteredShiftModal: {
      ...state.rosteredShiftModal,
      isUpdating: false,
      errors: errors
    }
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_NOTIFY,
  (state, id): RosterShiftModalReducerState => ({
    ...state,
    rosteredShiftModal: {
      ...state.rosteredShiftModal,
      isUpdating: true,
      errors: []
    }
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_NOTIFY_SUCCESS,
  (state): RosterShiftModalReducerState => ({
    ...state,
    rosteredShiftModal: {
      ...state.rosteredShiftModal,
      isUpdating: false,
      errors: []
    }
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_NOTIFY_FAILURE,
  (state, errors): RosterShiftModalReducerState => ({
    ...state,
    rosteredShiftModal: {
      ...state.rosteredShiftModal,
      isUpdating: false,
      errors: errors
    }
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_OFFER_SHIFT,
  (state, id): RosterShiftModalReducerState => ({
    ...state,
    rosteredShiftModal: {
      ...state.rosteredShiftModal,
      isUpdating: true,
      errors: []
    }
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_OFFER_SHIFT_SUCCESS,
  (state): RosterShiftModalReducerState => ({
    ...state,
    rosteredShiftModal: {
      ...state.rosteredShiftModal,
      isUpdating: false,
      errors: []
    }
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_OFFER_SHIFT_FAILURE,
  (state, errors): RosterShiftModalReducerState => ({
    ...state,
    rosteredShiftModal: {
      ...state.rosteredShiftModal,
      isUpdating: false,
      errors: errors
    }
  })
);

rosterShiftModal.on(
  actions.BOX_ROSTER_SHIFT_MODAL_SET_REACCEPT_VALUE,
  (state, value): RosterShiftModalReducerState => ({
    ...state,
    currentRosteredShift: {
      ...state.currentRosteredShift,
      need_re_accept: value
    }
  })
);
