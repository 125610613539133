import { Dictionary, SafeDictionary } from 'ts-essentials';
import {
  chunk,
  compact,
  filter,
  find,
  map,
  mapValues,
  omit,
  reduce,
  uniq,
} from 'lodash';
import { orderBy } from 'natural-orderby';
import moment, { Moment } from 'moment';
import {
  AccountTreeArea,
  AccountTreeRole,
  AccountTreeSite,
  DataIdsSelector,
  NotFirstAndEmptyPageSelector,
  Omit,
  Pager,
  PermissionName,
  RoleTriple,
  SelectPropsOption,
  ServerUserFields,
  Sort,
  SortDirection,
  StringMap,
  UserFields,
  UserRole,
  WithPagerSelector,
} from 'type';
import { getUserName, hasPermission } from 'lib/helpers';
import {
  checkNullFilter,
  compareValueWithSearchQuery,
  groupById,
} from './helpers';
import { StoreState } from './types';

export const hasPermissionCombinerCreator =
  (permission: PermissionName) => (currentUserPermissions: PermissionName[]) =>
    hasPermission(currentUserPermissions, permission);

export const filteredByIdCombinerCreator = <T>(field: keyof T) =>
  checkNullFilter<T>((collection, ids) =>
    collection.filter((shift: T) => ids.includes(String(shift[field])))
  );

export const getItemsSafeCreator = <Item>(
  getCollection: (state: StoreState) => Dictionary<Item>
) => getCollection as (state: StoreState) => SafeDictionary<Item>;

export const getItemSafeCreator =
  <Item>(getCollection: (state: StoreState) => Dictionary<Item>) =>
  (state: StoreState, id: number | string | null | undefined) =>
    (getCollection(state) as SafeDictionary<Item>)[id as string];

export const idsCombiner = <T extends { id: string }>(
  allData: T[] | StringMap<T>
): string[] => map(allData, 'id');

export const asSelectOptionCombiner = <T extends { id: string; name: string }>(
  value: T
): SelectPropsOption => ({
  label: value.name,
  value: value.id,
});

export const asSelectOptionsCombiner = <T extends { id: string; name: string }>(
  data: StringMap<T>
): StringMap<SelectPropsOption> => mapValues(data, asSelectOptionCombiner);

export const pageCombiner = <T>(allData: T[], pager: Pager): T[] => {
  const { currentPage, pageSize } = pager;
  const pageIndex = currentPage - 1;
  const chunks = chunk<T>(allData, pageSize);

  return chunks.length && chunks.length > pageIndex ? chunks[pageIndex] : [];
};
export const withPagerCombiner = <T>(
  allData: T[],
  pager: Pager
): WithPagerSelector<T> => {
  const { currentPage, pageSize } = pager;

  return {
    total: allData.length,
    page: pageCombiner(allData, pager),
    currentPage,
    pageSize,
  };
};

export const dataIdsCombiner = <T extends { id: string }>(
  allData: T[],
  pager: WithPagerSelector<T>
): DataIdsSelector => ({
  pageIds: map(pager.page, ({ id }) => id),
  allIds: map(allData, ({ id }) => id),
});

export const getInstanceCombiner = <T>(
  ids: string[],
  allData: StringMap<T>
): T[] => ids.map((id) => allData[id]);

export const arraySortedCombiner = <T, S extends Sort<any>>(
  allData: T[],
  sort: S
): T[] =>
  orderBy(allData, (item: T) => item[sort.column as keyof T], sort.direction);

type UserRoleSortFields = keyof Pick<
  UserRole,
  'site_id' | 'area_id' | 'role_id'
>;
export const sortUserByRoleCombiner = (
  users: UserFields[],
  column: UserRoleSortFields,
  direction: SortDirection,
  areas: StringMap<AccountTreeArea>,
  roles: StringMap<AccountTreeRole>,
  sites: StringMap<AccountTreeSite>
) => {
  const config: {
    [key in UserRoleSortFields]: {
      collection: StringMap<{ name: string }>;
      backupFieldName: keyof Pick<
        UserRole,
        'site_name' | 'area_name' | 'role_name'
      >;
    };
  } = {
    site_id: {
      collection: sites,
      backupFieldName: 'site_name',
    },
    area_id: {
      collection: areas,
      backupFieldName: 'area_name',
    },
    role_id: {
      collection: roles,
      backupFieldName: 'role_name',
    },
  };

  return orderBy(
    users,
    [
      (user: UserFields) => {
        const mainRole: UserRole | undefined = user.main_role;

        if (!mainRole) {
          return '';
        }

        const id: string = user.main_role[column];

        const siteIrAreaOrRole: { name: string } | undefined =
          config[column].collection[id];

        if (!siteIrAreaOrRole) {
          const { backupFieldName } = config[column];

          return mainRole[backupFieldName];
        }

        return siteIrAreaOrRole.name;
      },
    ],
    [direction]
  );
};

export const searchCombinerCreator =
  <T>(field: keyof T) =>
  (items: StringMap<T>, searchQuery: string) =>
    filter(items, (item) =>
      compareValueWithSearchQuery(item[field] as unknown as string, searchQuery)
    );

export const searchCombinerForUserCreator =
  <T>(field1: keyof T, field2: keyof T) =>
  (items: StringMap<T>, searchQuery: string) =>
    filter(items, (item: any) => {
      const resultsByFullName = compareValueWithSearchQuery(
        item[field1] as unknown as string,
        searchQuery
      );
      const resultsByPrefered = compareValueWithSearchQuery(
        item[field2] as unknown as string,
        searchQuery
      );
      return resultsByFullName || resultsByPrefered;
    });

export const withoutCombiner = <T>(
  data: StringMap<T>,
  idsToExclude: string[]
): StringMap<T> => (!idsToExclude.length ? data : omit(data, idsToExclude));

export const notFirstAndEmptyPageCombiner = <
  P extends Omit<WithPagerSelector<any>, 'page'>
>({
  currentPage,
  pageSize,
  total,
}: P): NotFirstAndEmptyPageSelector => {
  const lastPageNumber: number = Math.ceil(total / pageSize);

  return {
    lastPageNumber,
    notFirstAndEmptyPage: currentPage > 1 && lastPageNumber < currentPage,
  };
};

export const groupedByFieldCombinerCreator =
  <T>(field: keyof T) =>
  (collection: StringMap<T> | T[]): StringMap<T[]> => {
    return reduce<StringMap<T>, StringMap<T[]>>(
      collection as StringMap<T>,
      (accumulator: StringMap<T[]>, item: T) => {
        const key = item[field];

        return groupById(String(key), item, accumulator);
      },
      {}
    );
  };

export const currentSiteTimezoneCombiner = (
  siteId: string,
  sites: StringMap<AccountTreeSite>
): string => {
  const site: AccountTreeSite | undefined = sites[siteId];

  if (site) {
    const { timezone_id } = site;

    return timezone_id;
  }

  return moment.tz.guess();
};

export const bySiteTimezoneCombinerCreator =
  (keepLocalTime: boolean = false) =>
  (time: Moment, timezone: string): Moment => {
    if (keepLocalTime) {
      // moment(time).tz(timezone, keepLocalTime); not working correctly on a DST day
      const format = 'YYYY-MM-DD HH:mm:ss:SSS';
      return moment.tz(time.format(format), format, timezone);
    } else {
      return moment.tz(time.format(), timezone);
    }
  };

export const usersSelectOptionsCombiner = (
  ...args: [string[], StringMap<UserFields>]
): SelectPropsOption[] =>
  map(
    compact(getInstanceCombiner(...args)),
    ({ id, prefered_or_full_name }: UserFields): SelectPropsOption => ({
      label: prefered_or_full_name,
      value: id,
    })
  );

export const flattenAreaIdsRoleIdsCombiner = (
  roleTriples: RoleTriple[] | null
): {
  areas: string[] | null;
  roles: string[] | null;
} => {
  if (!roleTriples) {
    return {
      areas: null,
      roles: null,
    };
  }

  type AreaIdsRoleIds = {
    areas: string[];
    roles: string[];
  };

  const areaIdsRoleIdsWithDuplicates = roleTriples.reduce(
    (accumulator: AreaIdsRoleIds, roleTriple) => {
      const { areaId, roleId } = roleTriple;

      accumulator.areas.push(areaId);
      accumulator.roles.push(roleId);

      return accumulator;
    },
    {
      areas: [],
      roles: [],
    }
  );

  return {
    areas: uniq(areaIdsRoleIdsWithDuplicates.areas),
    roles: uniq(areaIdsRoleIdsWithDuplicates.roles),
  };
};

export const serverUserFieldsToUserFields = ({
  user_group_id,
  user_roles,
  avatar: avatarFileName,
  avatar_url,
  ...rest
}: ServerUserFields): UserFields => {
  const fullName = `${rest.first_name} ${rest.last_name}`;
  const preferedOrFullName = getUserName(rest);

  return {
    ...rest,
    user_group_id: user_group_id + '',
    full_name: fullName,
    prefered_or_full_name: preferedOrFullName,
    user_roles,
    main_role: find(user_roles, ['is_main', true])!,
    secondary_roles: filter(user_roles, ['is_main', false]),
    avatar: {
      label: fullName,
      src: avatar_url !== null ? avatar_url : undefined,
    },
  };
};
