import { updateRosterTimeOff } from 'lib/Api/helpers';
import { BOX_TIME_OFFS_UPDATE_MULTIPLE } from 'state/TimeOffs/actions';
import { Dictionary } from 'ts-essentials';
import { RosterRepeatableTimeOff, ServerRosterRepeatableTimeOff } from 'type';
import { Listener } from './types';
import makeDelayedHandler from './utils/makeDelayedHandler';

type TimeOffRosteredShiftChangedPayload = ServerRosterRepeatableTimeOff;
export const timeOffRosteredShiftChanged: Listener = (channel, emit) => {
  const itemHandler = (timeOff: TimeOffRosteredShiftChangedPayload) =>
    updateRosterTimeOff(timeOff);

  const handler = (timeOffs: Dictionary<RosterRepeatableTimeOff>) => {
    emit(BOX_TIME_OFFS_UPDATE_MULTIPLE(timeOffs));
  };

  channel.onTimeOffRosteredShiftChanged(
    makeDelayedHandler({
      itemHandler,
      handler,
    })
  );
};
