import { createSelector } from 'reselect';
import moment, { Moment } from 'moment';
import _ from 'lodash';
import { orderBy } from 'natural-orderby';
import {
  Pager,
  ShiftTrade,
  ShiftTradeProposal,
  ShiftTradesList,
  ShiftTradesWithPageSelector,
  ShiftTradeType
} from 'type';
import { StoreState } from 'state/types';
import { withPagerCombiner } from 'state/combiners';
import { ShiftTradesCustomFilter, SortList } from '../types';
import {
  getCurrentOfferedShift,
  getPager,
  getShiftTradesCustomFilter,
  getShiftTradesCustomFormFilter,
  getShiftTradesList
} from './simpleSelectors';

export const filterByType = (
  shifts: ShiftTradesList,
  types: ShiftTradeType[] | null
): ShiftTradesList => {
  return types !== null
    ? _.filter(
      shifts,
      shift => types !== null && types.indexOf(shift.type) !== -1
    )
    : shifts;
};

export const filterBySite = (
  shifts: ShiftTradesList,
  site: string | null
): ShiftTradesList => {
  return site !== null && site !== ''
    ? _.filter(
        shifts,
        shift => shift.rostered_shift.area.site.id === site || shift.rostered_shift.area.site_id === site
      )
    : shifts;
};

const filterOnlyByFrom = (
  from: Moment,
  shifts: ShiftTradesList
): ShiftTradesList => {
  const p = moment(from)
    .startOf('day')
    .format('YYYY-MM-DD');
  return _.filter(shifts, (shift: ShiftTrade) => {
    const { start, end } = shift.rostered_shift;
    const sS = moment.parseZone(start).format('YYYY-MM-DD');
    const eE = moment.parseZone(end).format('YYYY-MM-DD');
    return sS >= p || eE >= p;
  });
};

const filterOnlyByTo = (
  to: Moment,
  shifts: ShiftTradesList
): ShiftTradesList => {
  const p = moment(to)
    .endOf('day')
    .format('YYYY-MM-DD');
  return _.filter(shifts, (shift: ShiftTrade) => {
    const { start, end } = shift.rostered_shift;
    const sS = moment.parseZone(start).format('YYYY-MM-DD');
    const eE = moment.parseZone(end).format('YYYY-MM-DD');
    return sS <= p || eE <= p;
  });
};

const filterByRange = (
  from: Moment,
  to: Moment,
  shifts: ShiftTradesList
): ShiftTradesList => {
  const sP = moment(from)
    .startOf('day')
    .format('YYYY-MM-DD');
  const eP = moment(to)
    .endOf('day')
    .format('YYYY-MM-DD');
  return _.filter(shifts, (shift: ShiftTrade) => {
    const { start, end } = shift.rostered_shift;
    const sS = moment.parseZone(start).format('YYYY-MM-DD');
    const eE = moment.parseZone(end).format('YYYY-MM-DD');
    return (
      (sS >= sP && sS <= eP) || (sS >= sP && eE <= eP) || (eE >= sP && eE <= eP)
    );
  });
};

export const filterByDateRange = (
  shifts: ShiftTradesList,
  from: Moment | null,
  to: Moment | null
): ShiftTradesList => {
  if (from !== null && to === null) {
    return filterOnlyByFrom(from, shifts);
  }
  if (from === null && to !== null) {
    return filterOnlyByTo(to, shifts);
  }
  if (from !== null && to !== null) {
    return filterByRange(from, to, shifts);
  }
  return shifts;
};

export const sortData = (shifts: ShiftTradesList, sortOptions: SortList) => {
  const { column, direction } = sortOptions;
  return column === 'role'
    ? orderBy(shifts, [v => v.rostered_shift.role.name], [direction])
    : orderBy(
      shifts,
      [
        v =>
          moment(v.rostered_shift.start)
            .parseZone()
            .format('x')
      ],
      [direction]
    );
};

const getFilteredShiftTrades = (state: StoreState): ShiftTradesList => {
  const shifts = getShiftTradesList(state);
  const filters = getShiftTradesCustomFilter(state);
  const filteredByType: ShiftTradesList = filterByType(shifts, filters.types);
  const filteredBySite: ShiftTradesList = filterBySite(
    filteredByType,
    filters.site
  );
  const filteredByDate: ShiftTradesList = filterByDateRange(
    filteredBySite,
    filters.from,
    filters.to
  );
  return sortData(filteredByDate, filters.sort);
};

export const shiftTradesWithPagerSelector = createSelector<
  StoreState,
  ShiftTradesList,
  Pager,
  ShiftTradesWithPageSelector
  >(getFilteredShiftTrades, getPager, withPagerCombiner);

export const getIsFiltersFormDisabled = createSelector<
  StoreState,
  ShiftTradesCustomFilter,
  boolean
  >(
  getShiftTradesCustomFormFilter,
  ({ types }) => types !== null && types.length === 0
);

export const getTheirShiftSelector = (state: StoreState): ShiftTrade => {
  const selectedShiftId = state.employeeDashboard.shiftTrades.swapModal.tradeId;
  const trades = getFilteredShiftTrades(state);
  return _.filter(trades, trade => trade.id === selectedShiftId)[0];
};

export const getSelectedProposal = (
  state: StoreState
): ShiftTradeProposal | null => {
  const selectedShift: ShiftTrade | null = getCurrentOfferedShift(state);
  if (
    selectedShift !== null &&
    selectedShift.proposals &&
    selectedShift.proposals !== null
  ) {
    return _.filter(
      selectedShift.proposals,
      p => p.id === selectedShift.shift_trade_proposal_id
    )[0];
  }
  return null;
};
