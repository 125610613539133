export const durationOptions = () => {
  return [{
    label: 'All day',
    value: 'all_day'
  }, {
    label: 'A few hours',
    value: 'few_hours'
  }, {
    label: 'Multiple days',
    value: 'multiple_days'
  }];
};

export const getSelectedDuration = (value: string) => {
  const options = durationOptions();
  for (let option of options) {
    if ( option.value === value ) {
      return option;
    }
  }
  return options[0];
};

export const recurringOptions = () => {
  return [{
    label: 'Once',
    value: 'once'
  }, {
    label: 'Every day',
    value: 'every_day'
  }, {
    label: 'Every week',
    value: 'every_week'
  }, {
    label: 'Fortnightly',
    value: 'fortnightly'
  }, {
    label: 'Monthly',
    value: 'monthly'
  }];
};

export const getSelectedRecurring = (value: string) => {
  const options = recurringOptions();
  for (let option of options) {
    if ( option.value === value ) {
      return option;
    }
  }
  return options[0];
};