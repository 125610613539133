import React from 'react';
import { FormattedCurrency } from 'element';
import { CardProps } from './types';
import {
  ShiftCardEvent,
  ShiftCardEventBtnDelete,
  ShiftCardEventPopperAdd,
  ShiftCardEventRow,
  ShiftCardEventRowTitle,
} from './components';
import useEventCard from './useEventCard';

const ForecastTypeCard = ({ event, hasAddButton }: CardProps) => {
  const {
    isEditBlocked,
    isEventEditable,
    handleClick,
    handleDoubleClick,
    handleDeleteBtnClick,
    handleAddBtnClick,
  } = useEventCard(event);

  return (
    <ShiftCardEvent
      data-testid="roster-event-card roster-event-card--forecast"
      data-test-eventid={event.id}
      color="green"
      notClickable={!isEventEditable}
      transparent={isEditBlocked}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
    >
      <ShiftCardEventRowTitle
        title={event.name}
        secondaryAction={
          isEventEditable && (
            <ShiftCardEventBtnDelete onClick={handleDeleteBtnClick} />
          )
        }
      />

      <ShiftCardEventRow>
        (F) <FormattedCurrency>{event.value}</FormattedCurrency>
      </ShiftCardEventRow>

      <ShiftCardEventRow weight="bold">
        (A) <FormattedCurrency>{event.actual_value}</FormattedCurrency>
      </ShiftCardEventRow>

      {isEventEditable && hasAddButton && (
        <ShiftCardEventPopperAdd onClick={handleAddBtnClick} />
      )}
    </ShiftCardEvent>
  );
};

export default ForecastTypeCard;
