import styled, { css } from '../../_lib/style';
import { mediaBreakpointUp } from '../../_lib/helper';

type StyledContainerProps = {
  isFluid?: boolean;
  /** Size of Container */
  size?: string;
};

const StyledContainerBreakpoints = css`
  ${mediaBreakpointUp.xs`
    max-width: 570px;
  `}
  ${mediaBreakpointUp.sm`
    max-width: 810px;
  `}
  ${mediaBreakpointUp.md`
    max-width: 1000px;
  `}
  ${mediaBreakpointUp.lg`
    max-width: 1250px;
  `}
  ${mediaBreakpointUp.xl`
    max-width: 1410px;
  `}
  ${mediaBreakpointUp.xxl`
    max-width: 1850px;
  `}
`;

const getStyledContainerSize = (size: string) => {
  switch (size) {
    case 'lg':
      return css`
        max-width: 1200px;
        margin-left: 0;
        padding-left: 0;
        padding-right: 0;
      `;
    default:
      return;
  }
};

const containerStyle = (props: StyledContainerProps) => {
  if (!props.isFluid && !props.size) {
    return StyledContainerBreakpoints;
  }
  if (!!props.size) {
    return getStyledContainerSize(props.size);
  }
};

const StyledContainer = styled.div<StyledContainerProps>`
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  ${containerStyle}
`;

StyledContainer.displayName = 'StyledContainer';

export default StyledContainer;
