import React from 'react';
import { Col, Row, Text } from 'elmo-elements';
import { DispatchProps, Props, StateProps } from './type';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import { BOX_WHOS_WORKING_CLOCK_ON_REQUEST } from 'state/ManagerDashboard/WhosWorking';
import { TimerOutlinedIcon } from 'element';
import {
  getDatesDiff,
  getPreferenceLabel,
  getTimeFormatted,
  getUserName,
} from 'lib/helpers';
import { DashboardShift, UserFields } from 'type/models';
import { userListSelector } from 'state/UsersCollection';
import moment from 'moment';
import {
  getDateFormat,
  getLangPreferences,
  getRosteredShiftsAcceptShifts,
  getTimeFormat,
  getTimeToStartShift,
} from 'state/Account';
import { getAreas, getRoles, getSites } from 'state/AccountTree';
import { getCurrentTime } from 'state/CurrentTime';
import { DSTIcon } from 'element/DSTIcon';
import { NotesIcon } from 'element/NotesIcon';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Avatar,
} from 'oxygen-elements';

type ActionsCardActionProp = {
  onClick: () => void;
  label: string;
  icon: React.ReactNode;
  id: string;
};

export class StartingSoonCardComponent extends React.Component<Props> {
  render() {
    const { shift, timeFormat, currentTime, userList } = this.props;
    const userFrom: UserFields = userList[shift.user_id];

    return (
      <div className="working-soon-card" data-testid={shift.id}>
        <Card className="who-is-working-card-item" role="row">
          <CardHeader
            className="card-header"
            avatar={
              <Avatar
                src={userFrom.avatar.src}
                alt={getUserName(userFrom)}
                size={'small'}
                light
              />
            }
            title={getUserName(userFrom)}
            titleTypographyProps={{
              fontSize: 16,
              fontWeight: 500,
            }}
          />
          <CardContent role="grid" className="card-content">
            <Row>
              <Col span={24 / 4}>
                <Text size={'xs'} color={'gray'}>
                  {this.positionAreLocation}
                </Text>
                <div data-testid="site-area-role">
                  {this.getLocation(shift)}
                </div>
              </Col>
              <Col span={24 / 4}>
                <Text size={'xs'} color={'gray'}>
                  Start - finish
                </Text>
                <div data-testid="start-finish">
                  {getTimeFormatted(timeFormat, shift.start)} -{' '}
                  {getTimeFormatted(timeFormat, shift.end)}
                  {shift.is_dst_intersect && (
                    <>
                      {' '}
                      <DSTIcon isDST={shift.is_dst_intersect} />
                    </>
                  )}
                </div>
              </Col>
              <Col span={24 / 4}>
                <Text size={'xs'} color={'gray'}>
                  Starts in
                </Text>
                <div data-testid="starts-in">
                  {getDatesDiff(shift.start, currentTime.clone())}
                </div>
              </Col>
              {shift.event_name && (
                <Col span={24 / 4}>
                  <Text size={'xs'} color={'gray'}>
                    Event name:
                  </Text>
                  <div data-testid={'event-name'}>{shift.event_name}</div>
                </Col>
              )}
            </Row>
            <Row>
              <Col span={24 / 4}>
                <NotesIcon notes={shift.notes} />
              </Col>
            </Row>
          </CardContent>
          {this.getActions(shift) && (
            <CardActions className="card-actions">
              {this.getActions(shift)!.map((action: any) => (
                <Button
                  id={action.id}
                  key={`btn-${action.label}`}
                  size="large"
                  onClick={action.onClick}
                >
                  {action.icon} {action.label}
                </Button>
              ))}
            </CardActions>
          )}
        </Card>
      </div>
    );
  }

  getActions = (shift: DashboardShift): ActionsCardActionProp[] | undefined => {
    if (this.needAccept(shift)) {
      if (this.canClockOn(shift)) {
        return [
          {
            label: 'Clock on',
            id: 'clock-on',
            onClick: () => {
              this.props.clockOn(shift.id);
            },
            icon: <TimerOutlinedIcon />,
          },
        ];
      } else {
        return undefined;
      }
    } else {
      return [
        {
          label: 'Shift not accepted',
          id: 'shift-not-accepted',
          onClick: () => {
            //
          },
          icon: <></>,
        },
      ];
    }
  };

  needAccept = (shift: DashboardShift) => {
    if (this.props.isAcceptShifts) {
      return shift.is_accepted;
    } else {
      return true;
    }
  };

  canClockOn = (shift: DashboardShift) => {
    const { currentTime, timeToStartShiftMins } = this.props;

    const currentTimeInShiftTimezone = currentTime
      .clone()
      .tz(shift.timezone_id);

    // difference between start time and current time
    const diffMs = currentTimeInShiftTimezone.diff(shift.start);
    const diffDuration = moment.duration(diffMs);
    const diffAsMinutes = diffDuration.asMinutes();

    return Math.abs(diffAsMinutes) <= timeToStartShiftMins;
  };

  getLocation = (roster: DashboardShift) => {
    const { roles, areas, sites } = this.props;
    let response = '';

    if (roster.role_id && roles[roster.role_id]) {
      response = roles[roster.role_id].name;
    }

    if (roster.area_id && areas[roster.area_id]) {
      response += ` - ${areas[roster.area_id].name}`;
    }

    if (roster.site_id && sites[roster.site_id]) {
      response += `, ${sites[roster.site_id].name}`;
    }
    return response;
  };

  get positionAreLocation() {
    const { langPreferences } = this.props;
    const position = getPreferenceLabel(
      langPreferences,
      'role',
      'singular',
      '',
      true
    );
    const area = getPreferenceLabel(
      langPreferences,
      'area',
      'singular',
      '',
      false
    );
    const location = getPreferenceLabel(
      langPreferences,
      'site',
      'singular',
      '',
      false
    );
    return `${position} - ${area}, ${location}`;
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  userList: userListSelector(state),
  areas: getAreas(state),
  roles: getRoles(state),
  sites: getSites(state),
  timeToStartShiftMins: getTimeToStartShift(state),
  isAcceptShifts: getRosteredShiftsAcceptShifts(state),
  langPreferences: getLangPreferences(state),
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
  currentTime: getCurrentTime(state),
});

const mapDispatchToProps: DispatchProps = {
  clockOn: BOX_WHOS_WORKING_CLOCK_ON_REQUEST,
};

export const StartingSoonCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(StartingSoonCardComponent);
