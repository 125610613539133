import React, { SyntheticEvent, useState } from 'react';
import { FormItem, Checkbox } from 'elmo-elements';
import {
  CheckboxesCollection,
  SortOutlinedIcon,
  SwitcherButton,
  ViewStreamOutlinedIcon,
} from 'element';
import {
  LanguagePreferences,
  SelectPropsOption,
  UpdateFormPayload,
} from 'type';
import { assertUnreachable } from 'helpers/helpers';
import { capitalize, keyMirror } from 'lib/helpers';
import {
  RosterFilters,
  RosterFiltersRoleFilterType,
  UpdateFilterFormPayload,
} from 'state/Roster/RosterFilters';
import { RosterFlatRoleTable, RosterRoleTripleTable } from './components';
import { useSelector } from 'react-redux';
import { getIsCovidModeEnabled } from '../../../../../../../../state/Roster/CovidTracing';
import { UsersWithShiftsGroup } from '../../../UsersWithShiftsGroup';
import { Button } from 'oxygen-elements';

type OptionConfig = {
  options: SelectPropsOption[];
  isVisible: boolean;
};

export type Props = {
  formData: RosterFilters;
  onChange: (payload: UpdateFormPayload) => void;
  onResetBtnClick: () => void;
  isResetDisabled: boolean;
  events: OptionConfig;
  langPreferences: LanguagePreferences;
  canViewEvents: boolean;
  canViewTimesheets: boolean;
  canViewRosteredShifts: boolean;
  selectedFilterType: RosterFiltersRoleFilterType;
  changeFilterForm: (payload: UpdateFilterFormPayload) => void;
};

const FilterByRole = ({
  selectedFilterType,
}: {
  selectedFilterType: RosterFiltersRoleFilterType;
}) => {
  switch (selectedFilterType) {
    case 'tree':
      return <RosterRoleTripleTable />;
    case 'flat':
      return <RosterFlatRoleTable />;
    default:
      assertUnreachable(selectedFilterType);
  }

  return null;
};

const CustomFiltersView = ({
  formData,
  events,
  langPreferences,
  onChange,
  onResetBtnClick,
  isResetDisabled,
  canViewEvents,
  canViewTimesheets,
  canViewRosteredShifts,
  selectedFilterType,
  changeFilterForm,
}: Props) => {
  const [inputNames] = useState(keyMirror(formData));
  const isCovidMode = useSelector(getIsCovidModeEnabled);
  const onChangeCheckbox = ({
    currentTarget: { name, checked },
  }: SyntheticEvent<HTMLInputElement>) => {
    onChange({
      name,
      value: checked,
    });
  };

  const onRadioChange = (value: 'all' | 'with_shifts' | 'without_shifts') =>
    onChange({ name: 'showPeople', value });

  const handleChangeFilterType =
    (roleFilterType: RosterFiltersRoleFilterType) => () => {
      changeFilterForm({ roleFilterType });
    };

  return (
    <form>
      <FormItem isDivider={true}>
        {canViewTimesheets && (
          <Checkbox
            id={inputNames.timesheets}
            name={inputNames.timesheets}
            isChecked={formData.timesheets}
            onChange={onChangeCheckbox}
            label="Timesheets"
          />
        )}
      </FormItem>

      <SwitcherButton
        id="roster-custom-filters-flat-role-filter"
        icon={<ViewStreamOutlinedIcon />}
        isActive={selectedFilterType === 'flat'}
        onClick={handleChangeFilterType('flat')}
      />
      <SwitcherButton
        id="roster-custom-filters-tree-role-filter"
        icon={<SortOutlinedIcon />}
        isActive={selectedFilterType === 'tree'}
        onClick={handleChangeFilterType('tree')}
      />

      <FormItem isDivider={true}>
        <FilterByRole selectedFilterType={selectedFilterType} />
      </FormItem>

      <UsersWithShiftsGroup
        onChange={onRadioChange}
        selected={formData.showPeople}
      />

      {events.isVisible && canViewEvents && (
        <CheckboxesCollection
          formItem={{
            isDivider: true,
            label: capitalize(langPreferences.event.plural),
          }}
          options={events.options}
          name={inputNames.events}
          value={formData.events}
          onChange={onChange}
        />
      )}

      {!isCovidMode && (
        <FormItem>
          <Checkbox
            id={'archived-employees-checkbox'}
            isChecked={formData.showArchivedUsers}
            name={'showArchivedUsers'}
            onChange={onChangeCheckbox}
            label={`Left / Deleted employees`}
          />
        </FormItem>
      )}

      <Button
        disabled={isResetDisabled}
        onClick={onResetBtnClick}
        id="roster-custom-filters-btn-reset"
        variant={'outlined'}
      >
        Reset to defaults
      </Button>
    </form>
  );
};
export default CustomFiltersView;
