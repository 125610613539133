import React, { Children, Component, ReactElement } from 'react';
import {
  DataTableAction,
  DataTableColumn,
  DataTableDropdownDivider,
  DataTableElement,
} from '../type';
import { getClass } from '../../_lib/helper';
import DataTableTd from './DataTableTd';
import Checkbox from '../../Checkbox';
import DataTableActions from './DataTableActions';
import { withDataTableContext } from '../DataTableContextConsumer';

type DataTableTrProps = DataTableElement & {
  /** @ignore The index of this row in the table body. Set by the body component */
  rowIndex?: number;
  /** True when the row data is selected */
  isSelected?: boolean;
  /** True when the row is disabled */
  isDisabled?: boolean;
  /** Array of actions/divider shown in the actions dropdown */
  actions?: (DataTableAction | DataTableDropdownDivider)[];
  /** Called when the item is selected/deselected */
  onItemToggle?: () => void;
  /** @ignore From DataTable Context */
  isAllAvailableSelected?: boolean;
  /** @ignore From DataTable Context */
  hasRowActions?: boolean;
  /** @ignore From DataTable Context */
  countSelectedItems?: number;
  /** @ignore From DataTable Context */
  isSelectAllChecked?: boolean;
  /** @ignore From DataTable Context */
  setContextState?: (state: any) => void;
  /** @Ignore From DataTable Context */
  updateSelectAllCheckboxState: (isSelected?: boolean) => void;
  /** @ignore From Layout Context */
  isBulkActionOpen?: boolean;
  /** @ignore From DataTable Context */
  headerColumns: DataTableColumn[];
  /** @ignore From DataTable Context */
  isResponsive?: boolean;
};

/**
 * The Row component of the DataTable
 */
class DataTableTr extends Component<DataTableTrProps> {
  static displayName = 'DataTableTr';
  componentDidMount() {
    /**
     * Check if the row has actions. If it does, then set hasRowActions in the DataTable context to true.
     * This makes the Header set the colspan of its last column to 2 if the Actions column is shown.
     */
    const { actions, hasRowActions, setContextState } = this.props;

    if (actions && !hasRowActions && setContextState) {
      setContextState({
        hasRowActions: true,
      });
    }
  }

  handleItemToggle = () => {
    const { onItemToggle, isSelected, updateSelectAllCheckboxState } =
      this.props;

    const nextIsSelected = !isSelected;
    updateSelectAllCheckboxState(nextIsSelected);

    if (onItemToggle) {
      onItemToggle();
    }
  };

  renderColumns() {
    const { children, headerColumns } = this.props;

    if (!headerColumns || headerColumns.length === 0) {
      return null;
    }

    return Children.map(
      children as React.ReactElement<any>[],
      (child: ReactElement<any>, index: number) => {
        // check if it is in the list of visible elements
        if (headerColumns[index] && !headerColumns[index].isHidden) {
          return child;
        }

        return null;
      }
    );
  }

  shouldShowActions = () => {
    const { headerColumns } = this.props;

    return (
      headerColumns.filter((c: DataTableColumn) => {
        return !c.isHidden;
      }).length > 0
    );
  };

  render() {
    const {
      className,
      isBulkActionOpen,
      rowIndex,
      isSelected,
      isDisabled,
      actions,
      onItemToggle,
      isAllAvailableSelected,
      isResponsive,
    } = this.props;
    const showCheckbox =
      isBulkActionOpen && onItemToggle && rowIndex !== undefined;
    return (
      <tr className={getClass('elmo-datatable__tr', className)}>
        {showCheckbox && (
          <DataTableTd className="elmo-datatable__row-checkbox">
            <Checkbox
              id={'datatable-' + rowIndex}
              isChecked={isSelected || isAllAvailableSelected}
              isDisabled={isDisabled}
              onChange={this.handleItemToggle}
              ariaLabel="Select all"
            />
          </DataTableTd>
        )}
        {this.renderColumns()}
        {!isBulkActionOpen && actions && this.shouldShowActions() && (
          <DataTableActions actions={actions} isInline={!isResponsive} />
        )}
      </tr>
    );
  }
}

export default withDataTableContext(DataTableTr);
