import React from 'react';
import { Select, FormContainer, FormItem, Divider } from 'elmo-elements';
import { Data, DropDownProps } from '../type';
import { getSelectedGroupedValues, getSelectedValues } from '../helpers';
import { getPreferenceLabel } from 'lib/helpers';
import { useSelector } from 'react-redux';
import { getLangPreferences } from '../../../../../state/Account';

export type DataProps = {
  handleOnChangeSelect2: (e: DropDownProps[], name: string) => void;
  data: Data;
  rolesAsSelectOptionsArray: any;
  sitesAsSelectOptionsArray: any;
  usersAsSelectOptionsArray: any;
  areasAsSelectOptionsArray: any;
  toggleArchived: () => void;
  showArchived: boolean;
  handleOnChangeSites: (e: DropDownProps[]) => void;
};

const LocationAreaRoleUserFilters = ({
  data,
  handleOnChangeSites,
  sitesAsSelectOptionsArray,
  rolesAsSelectOptionsArray,
  usersAsSelectOptionsArray,
  handleOnChangeSelect2,
  areasAsSelectOptionsArray,
  toggleArchived,
  showArchived,
}: DataProps) => {
  const langPreferences = useSelector(getLangPreferences);
  const getSelectedOptions = (selected: string[], key: string) => {
    const getArray = () => {
      switch (key) {
        case 'areas':
          return areasAsSelectOptionsArray;
        case 'roles':
          return rolesAsSelectOptionsArray;
        case 'users':
          return usersAsSelectOptionsArray;
        case 'sites':
          return sitesAsSelectOptionsArray;
        default:
          break;
      }
      return [];
    };
    const params = getArray();
    if (key === 'areas') {
      return getSelectedGroupedValues(params, selected);
    }
    return getSelectedValues(params, selected);
  };
  return (
    <>
      <Divider />
      <br />
      <h3>Data</h3>
      <FormContainer maxColumns={3}>
        <FormItem
          label={getPreferenceLabel(
            langPreferences,
            'site',
            'plural',
            '',
            true
          )}
        >
          <Select
            options={sitesAsSelectOptionsArray}
            isMulti={true}
            isCreatable={false}
            hideSelectedOptions={true}
            onChange={(e) => handleOnChangeSites(e)}
            defaultValue={getSelectedOptions(data.sites, 'sites')}
            ariaLabel={getPreferenceLabel(
              langPreferences,
              'site',
              'plural',
              '',
              true
            )}
          />
        </FormItem>
        <FormItem
          label={getPreferenceLabel(
            langPreferences,
            'area',
            'plural',
            '',
            true
          )}
        >
          <Select
            options={areasAsSelectOptionsArray}
            isMulti={true}
            isCreatable={false}
            hideSelectedOptions={true}
            onChange={(e) => handleOnChangeSelect2(e, 'areas')}
            defaultValue={getSelectedOptions(data.areas, 'areas')}
            value={getSelectedOptions(data.areas, 'areas')}
            ariaLabel={getPreferenceLabel(
              langPreferences,
              'area',
              'plural',
              '',
              true
            )}
          />
        </FormItem>
        <FormItem
          label={'Users'}
          labelAddon={
            <span
              style={{ cursor: 'pointer' }}
              onClick={toggleArchived}
              className={
                showArchived ? 'text-color-primary' : 'text-color-gray'
              }
            >
              Show ex-employees
            </span>
          }
        >
          <Select
            options={usersAsSelectOptionsArray}
            isMulti={true}
            hideSelectedOptions={true}
            isCreatable={false}
            onChange={(e) => handleOnChangeSelect2(e, 'users')}
            defaultValue={getSelectedOptions(data.users, 'users')}
            value={getSelectedOptions(data.users, 'users')}
            ariaLabel="Users"
          />
        </FormItem>
        <FormItem
          label={getPreferenceLabel(
            langPreferences,
            'role',
            'plural',
            '',
            true
          )}
        >
          <Select
            hideSelectedOptions={true}
            options={rolesAsSelectOptionsArray}
            isMulti={true}
            isCreatable={false}
            onChange={(e) => handleOnChangeSelect2(e, 'roles')}
            defaultValue={getSelectedOptions(data.roles, 'roles')}
            value={getSelectedOptions(data.roles, 'roles')}
            ariaLabel={getPreferenceLabel(
              langPreferences,
              'role',
              'plural',
              '',
              true
            )}
          />
        </FormItem>
      </FormContainer>
    </>
  );
};

export default LocationAreaRoleUserFilters;
