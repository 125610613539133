import { styled } from 'extended-oxygen-elements';
import React from 'react';
import LoadingsTableCell, { LoadingTableCellProps } from './LoadingsTableCell';

export type LoadingTableThProps = LoadingTableCellProps;

export const LoadingsTableCellHeading = styled(LoadingsTableCell)(
  ({ theme }) => ({
    ...(theme.typography.caption as React.CSSProperties),
    color: theme.palette.text.secondary,
  })
);

export default LoadingsTableCellHeading;
