import {
  GetApiPayloadPayload,
  MyTimesheetFilters,
  MyTimesheetPredefinedFilter,
} from './types';
import { EmployeeDashboardGetMyTimesheetPayload } from 'lib/Api';
import { cloneDeep } from 'lodash';
import moment, { Moment } from 'moment';

export const predefinedFilters: {
  [key in MyTimesheetPredefinedFilter]: MyTimesheetFilters;
} = {
  lastSevenDays: {
    period: {
      period: 'last_7_days',
      from: null,
      to: null,
    },
    siteIds: [],
    status: null,
    sort: {
      column: 'start',
      direction: 'asc',
    },
  },
  currentRoster: {
    period: {
      period: 'current_roster',
      from: null,
      to: null,
    },
    siteIds: [],
    status: null,
    sort: {
      column: 'start',
      direction: 'asc',
    },
  },
  lastRoster: {
    period: {
      period: 'last_roster',
      from: null,
      to: null,
    },
    siteIds: [],
    status: null,
    sort: {
      column: 'start',
      direction: 'asc',
    },
  },
  lastSixMonths: {
    period: {
      period: 'last_6_months',
      from: null,
      to: null,
    },
    siteIds: [],
    status: null,
    sort: {
      column: 'start',
      direction: 'asc',
    },
  },
};

export const getPredefinedFilters = (
  predefinedFilter: MyTimesheetPredefinedFilter
): MyTimesheetFilters => cloneDeep(predefinedFilters[predefinedFilter]);

export const getApiPayload = ({
  page,
  pageSize,
  filtersForm: {
    sort: { direction, column },
    period: { from, to, period },
    siteIds,
    status,
  },
}: GetApiPayloadPayload): EmployeeDashboardGetMyTimesheetPayload => ({
  page,
  per_page: pageSize,
  order_by: column,
  order_dir: direction,
  period: {
    period,
    from,
    to,
  },
  site_ids: siteIds.length ? siteIds : null,
  status,
});

export const createTimesheetHash = (
  {
    user_id,
    start,
    end
  }: Partial<{
    user_id: string;
    start: string | Moment;
    end: string | Moment | null;
  }>
): string => {
  if (user_id && start && end) {
    const formattedStart = moment.parseZone(start).format('L');
    const formattedEnd = moment.parseZone(end).format('L');

    return [user_id, formattedStart, formattedEnd].join('_');
  }

  return '';
};
