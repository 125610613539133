import React, { memo } from 'react';
import {
  makeItemUserIdSelector,
  makePairRosteredShiftIdSelector,
  makePairLeaveIdSelector,
  makePairTimeoffIdsSelector,
} from 'state/Roster/RosterWeekViewUserView';
import { useMakeSelector, useSelectorWithProps } from 'hooks';
import { WeekCellBlock } from '../../../../../../../components/WeekCellBlock/RosterWeekCellBlock';
import { RosterCell } from '../../../../../../../../../components';
import { useSelector } from 'react-redux';
import { hasPermissionSelector } from 'state/Auth';

type Props = {
  userRowIndex: number;
  pairRowIndex: number;
  weekDayIndex: number;
};

export const RosteredShiftCell = memo(function RosteredShiftCell(props: Props) {
  const pairRosteredShiftIdSelector = useMakeSelector(
    makePairRosteredShiftIdSelector
  );

  const pairLeaveIdSelector = useMakeSelector(makePairLeaveIdSelector);

  const pairTimeOffIdsSelector = useMakeSelector(makePairTimeoffIdsSelector);

  const leaveId = useSelectorWithProps(pairLeaveIdSelector, props);

  const timeOffIds = useSelectorWithProps(pairTimeOffIdsSelector, props);

  const rosteredShiftId = useSelectorWithProps(
    pairRosteredShiftIdSelector,
    props
  );

  const itemUserIdSelector = useMakeSelector(makeItemUserIdSelector);
  const itemUserId = useSelectorWithProps(itemUserIdSelector, props);

  const isLabel = !timeOffIds && !leaveId;
  const canViewRosteredShift = useSelector((state) =>
    hasPermissionSelector(state, 'roster.rosteredshift.view')
  );

  if (!canViewRosteredShift && isLabel) {
    return (
      <RosterCell type={'top'} fontWeight={'normal'}>
        &nbsp;
      </RosterCell>
    );
  }

  return (
    <RosterCell
      type={'top'}
      label={isLabel ? '+ Add Shifts' : undefined}
      labelOnHover={isLabel ? true : undefined}
      fontWeight={'normal'}
    >
      <WeekCellBlock
        rowNumber={props.pairRowIndex}
        weekDayIndex={props.weekDayIndex}
        menuType={'create'}
        shiftType={'rostered_shift'}
        userId={itemUserId}
        areaId={undefined}
        roleId={undefined}
        rosteredShiftId={rosteredShiftId}
        viewType={'user'}
        leaveId={leaveId}
        timeOffIds={timeOffIds}
      />
    </RosterCell>
  );
});
