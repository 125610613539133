import { ShiftOvertimeRuleRequestEntityDay } from 'lib/Api';
import React from 'react';
import { useDispatch } from 'react-redux';
import { BOX_ACCOUNT_PAY_CONDITIONS_RULE_UPDATE } from 'state/Account';
import { ShiftOvertimeRule } from 'type';
import PayConditionForm, {
  PayConditionFormProps,
} from '../components/PayConditionForm';

type EditPayConditionProps = {
  overtimeRule: ShiftOvertimeRule;
  day: ShiftOvertimeRuleRequestEntityDay;
};

function EditPayCondition({ overtimeRule, day }: EditPayConditionProps) {
  const dispatch = useDispatch();

  const handleSubmit: PayConditionFormProps['onSubmit'] = (payload) => {
    dispatch(
      BOX_ACCOUNT_PAY_CONDITIONS_RULE_UPDATE.request({
        ...payload,
        id: overtimeRule.id,
        day,
      })
    );
  };

  return (
    <PayConditionForm onSubmit={handleSubmit} initialState={overtimeRule} />
  );
}

export default EditPayCondition;
