import React from 'react';
import { connect } from 'react-redux';
import { FormattedErrors } from 'type';
import { StoreState } from 'state/types';
import {
  BOX_EMPLOYEE_UNAVAILABILITY_CLEAR_ERRORS,
  DayDetails,
  getErrors,
} from 'state/EmployeeDashboard/Unavailability';
import { ErrorBox } from 'element';
import UnavailabilityCalendar from './components/UnavailabilityCalendar';
import DayDetailsCard from './components/UnavailabilityCalendar/components/DayDetailsCard';
import UnavailabilityModal from './components/UnavailabilityModal';
import { NotesDialog } from "./components/UnavailabilityCalendar/components/NotesDialog";

type StateProps = {
  dayDetails: DayDetails | null;
  errors: FormattedErrors;
};

type DispatchProps = {
  clearErrors: () => void;
};

const UnavailabilityView = ({
  dayDetails,
  errors,
  clearErrors
}: StateProps & DispatchProps) => {
  return (
    <>
      <ErrorBox errors={errors} clearErrors={clearErrors} />
      <UnavailabilityCalendar />
      <div className="day-details__output-wrapper">
        {dayDetails !== null && <DayDetailsCard data={dayDetails} />}
      </div>
      <UnavailabilityModal />
      <NotesDialog />
    </>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  dayDetails: state.employeeDashboard.employeeUnavailability.dayDetails,
  errors: getErrors(state)
});

const mapToDispatchProps: DispatchProps = {
  clearErrors: BOX_EMPLOYEE_UNAVAILABILITY_CLEAR_ERRORS,
};

export default connect(mapStateToProps, mapToDispatchProps)(UnavailabilityView);
