import { Button } from 'extended-oxygen-elements';
import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
  BOX_ROSTER_FILTERS_TOGGLE_TIMESHEETS_FILTER,
  getTimesheetsFilter,
} from 'state/Roster/RosterFilters';
import { hasPermissionSelector } from 'state/Auth';
import { useSelectorWithProps } from 'hooks';
import clsx from 'clsx';

type Props = {
  afterUpdate: () => void;
};

const useBtnStyles = makeStyles((theme) =>
  createStyles({
    root: {
      borderRadius: '3px',
      backgroundColor: '#fff',
      borderColor: '#c4c7d0',
      color: '#000',
      maxWidth: '134px',
      width: '100%',
      '&:hover': {
        backgroundColor: '#f6f6f6',
        borderColor: '#c4c7d0',
      },
    },
    activeFilter: {
      backgroundColor: '#e6f1ff',
      borderRadius: '3px',
      borderColor: 'rgba(0, 119, 200)',
      color: '#19579f',
      fontWeight: 500,
      '&:hover': {
        borderColor: 'rgba(0, 119, 200)',
      },
    },
  })
);
export const TimesheetButton = (props: Props) => {
  const classes = useBtnStyles();
  const canViewTimesheets = useSelectorWithProps(
    hasPermissionSelector,
    'roster.timesheet.view'
  );
  const isSelected = useSelector(getTimesheetsFilter);
  const dispatch = useDispatch();

  const handleFiltersChange = () => {
    dispatch(BOX_ROSTER_FILTERS_TOGGLE_TIMESHEETS_FILTER(!isSelected));
    props.afterUpdate();
  };

  if (!canViewTimesheets) {
    return null;
  }

  return (
    <Button
      data-testid="timesheet-quick-filter-btn"
      variant="outlined"
      onClick={handleFiltersChange}
      className={clsx(classes.root, [isSelected && classes.activeFilter])}
    >
      Timesheets
    </Button>
  );
};
