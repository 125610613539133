import { UserGroupsReducerState } from './types';

export const defaultState: UserGroupsReducerState = {
  isFetched: false,
  isLoading: false,
  isUpdating: false,
  isFetching: false,
  isPermissionsSaved: false,
  userGroups: {},
  group: {
    title: '',
    id: '',
    template: '',
    receiver_id: '',
    template_updated: false
  },
  addModal: {
    isOpened: false,
    isUpdating: false,
    errors: []
  },
  updateModal: {
    isOpened: false,
    isUpdating: false,
    errors: []
  },
  deleteModal: {
    isOpened: false,
    isUpdating: false,
    errors: []
  },
  errors: [],
  nameError: false
};
