import React from 'react';
import { Box, TextField } from 'oxygen-elements';
import { SearchIcon } from 'element';
import { useBulkSearchContext } from 'contexts';
import { Button } from 'extended-oxygen-elements';
import { createStyles, makeStyles } from '@mui/styles';

type SearchProps = {
  searchQuery: string;
  updateQuery: (query: string) => void;
  onCloseSearch?: () => void;
};

const useSearchBarStyles = makeStyles((theme) =>
  createStyles({
    root: {
      background: 'white',
      width: '100%',
      alignSelf: 'flex-start',
      marginTop: '-5px',
      [theme.breakpoints.up(600)]: {
        padding: '10px 0 15px',
        marginTop: '0',
      },
    },
    inner: {
      background: 'white',
      width: '100%',
      alignSelf: 'flex-start',
      alignItems: 'center',
      padding: '0 16px',
      marginTop: '-5px',
      [theme.breakpoints.up(600)]: {
        padding: '0 40px',
        marginTop: '0',
      },
    },
    button: {
      marginTop: '8px',
    },
  })
);

export const Search = ({
  updateQuery,
  searchQuery,
  onCloseSearch,
}: SearchProps) => {
  const styles = useSearchBarStyles();
  const bulkSearchContext = useBulkSearchContext();

  const handleCloseClick = () => {
    bulkSearchContext.disable();
    updateQuery('');
    if (onCloseSearch) {
      onCloseSearch();
    }
  };

  const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      updateQuery((e.target as any).value);
    }
  };

  return (
    <Box className={styles.root}>
      <Box className={styles.inner} display="flex">
        <TextField
          id="search-bar"
          className="text"
          onInput={(e: any) => {
            updateQuery(e.target.value);
          }}
          onKeyDown={handleSearchKeyDown}
          label="Search"
          variant="outlined"
          placeholder="Search..."
          size="small"
          sx={{ marginRight: '20px' }}
          InputProps={{
            endAdornment: <SearchIcon style={{ fill: 'gray' }} />,
          }}
          value={searchQuery}
        />
        <Button
          onClick={handleCloseClick}
          color={'grey'}
          className={styles.button}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};
