import React from 'react';
import { CustomFilterSummaryItem } from '../type';
import './CustomFilterSummary.scss';
import Button from '../../Button';
import { CancelIcon, FilterListIcon, SaveAltIcon } from '../../icons';

export type CustomFilterSummaryProps = {
  /** The summary items to show */
  items: CustomFilterSummaryItem[];
  /** Callback for when Save is clicked */
  onSaveClick?: () => void;
  /** Callback for when Clear is clicked */
  onClearClick?: () => void;
};

/**
 * Component for rendering the selected custom filters
 * @param items
 * @param onSaveClick
 * @param onClearClick
 * @constructor
 */
function CustomFilterSummary({
  items,
  onSaveClick,
  onClearClick,
}: CustomFilterSummaryProps) {
  return (
    <div className="elmo-filter__summary">
      <div className="elmo-filter__summary__title">
        <FilterListIcon />
      </div>
      {items.map((item: CustomFilterSummaryItem, index: number) => {
        return (
          <div className="elmo-filter__summary__item" key={index}>
            {item.icon && item.icon} {item.title && <b>{item.title}</b>}
            {item.label && item.label}
          </div>
        );
      })}
      <div className="elmo-filter__summary__item">
        {onSaveClick && (
          <Button
            icon={<SaveAltIcon />}
            className="elmo-filter__summary__button"
            onClick={onSaveClick}
            isUppercase={false}
            type="primary"
            isText={true}
          >
            Save
          </Button>
        )}
        {onClearClick && (
          <Button
            icon={<CancelIcon />}
            className="elmo-filter__summary__button"
            onClick={onClearClick}
            isUppercase={false}
          >
            Clear
          </Button>
        )}
      </div>
    </div>
  );
}

export default CustomFilterSummary;
