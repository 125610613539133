import { createAction } from 'lib/store-utils';
import { CreatePasswordPayload, ResetPasswordPayload } from './types';
import { FormattedErrors } from '../../type/models';

export const BOX_RESET_PASSWORD_REQUEST = createAction<ResetPasswordPayload>('Reset password / request');
export const BOX_RESET_PASSWORD_SUCCESS = createAction('Reset password / success');
export const BOX_RESET_PASSWORD_FAILURE = createAction<FormattedErrors>('Reset password / failure');

export const BOX_RESET_CREATE_PASSWORD_REQUEST = createAction<CreatePasswordPayload>('Reset password / create request');
export const BOX_RESET_CREATE_PASSWORD_SUCCESS = createAction('Reset password / create success');
export const BOX_RESET_CREATE_PASSWORD_FAILURE = createAction<FormattedErrors>('Reset password / create failed');

export const BOX_RESET_PASSWORD_CLEAR_ERRORS = createAction('Reset password / clear errors');
export const BOX_RESET_PASSWORD_CLEAR_MESSAGE = createAction('Reset password / clear message');
