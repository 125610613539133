import React from 'react';
import { ConnectActionCreator } from 'lib/store-utils';
import useConnectAction from './useConnectAction';

export default useConnectActionMemoized;

export function useConnectActionMemoized<P>(
  action: ConnectActionCreator<P>,
  makePayload: () => P,
  dependencies: ReadonlyArray<any>
) {
  const payload = React.useMemo(makePayload, dependencies);

  return useConnectAction(action, payload);
}
