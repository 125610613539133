import { RosteredShift } from 'type';
import { useColorId, useElementId, useFormatTime } from '../hooks';
import useIcons from './useIcons';
import useEventName from './useEventName';

function useRosterCard(rosteredShift: RosteredShift) {
  const colorId = useColorId(rosteredShift);
  const from = useFormatTime(rosteredShift.start);
  const to = useFormatTime(rosteredShift.end);
  const breaks = rosteredShift.breaks;
  const icons = useIcons(rosteredShift);
  const eventName = useEventName(rosteredShift);
  const coloredBg = !rosteredShift.is_published;
  const id = useElementId(rosteredShift);

  return {
    colorId,
    from,
    to,
    breaks,
    icons,
    eventName,
    coloredBg,
    id,
  };
}

export { default as useHandleClick } from './useHandleClick';
export { default as useHandleClickDeleteIcon } from './useHandleClickDeleteIcon';
export default useRosterCard;
