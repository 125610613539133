import { TimesheetsReducerState } from './types';

export const getDefaultState = (): TimesheetsReducerState => ({
  errors: [],
  selectedFilter: 'all',
  shifts: {},
  showNewForm: false,
  deleteModal: {
    isOpened: false,
    isUpdating: false,
    errors: []
  },
  confirmationModal: {
    isOpened: false,
    errors: []
  },
  approvalModal: {
    isOpened: false
  },
  newShift: {
    isLoading: false,
    isOpened: false,
    shift: null,
    errors: [],
    rosteredShifts: {},
    isLoadingShifts: false
  },

});
