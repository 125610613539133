import { createReducer } from 'lib/store-utils';
import { FormattedErrors } from 'type';
import { AllowancesModalReducerState } from '../types';
import { getDefaultState } from '../state';
import * as actions from '../actions';

export const TimesheetsAllowances = createReducer<
  AllowancesModalReducerState
>({}, getDefaultState());

const requestHandler = (
  state: AllowancesModalReducerState
): AllowancesModalReducerState => ({
  ...state,
  errors: []
});

const failureHandler = (
  state: AllowancesModalReducerState,
  errors: FormattedErrors
): AllowancesModalReducerState => ({
  ...state,
  errors
});

TimesheetsAllowances.on(
  actions.BOX_TIMESHEETS_ALLOWANCES_MODAL_OPENED,
  (state, { id }): AllowancesModalReducerState => ({
    ...getDefaultState(),
    isOpened: true,
    currentTimesheetId: id
  })
);

TimesheetsAllowances.on(
  actions.BOX_TIMESHEETS_ALLOWANCES_MODAL_REQUEST,
  requestHandler
);

TimesheetsAllowances.on(
  actions.BOX_TIMESHEETS_ALLOWANCES_MODAL_SUCCESS,
  (state, { allowances }): AllowancesModalReducerState => ({
    ...state,
    allowances
  })
);

TimesheetsAllowances.on(
  actions.BOX_TIMESHEETS_ALLOWANCES_MODAL_ADDED_ALLOWANCE,
  (state, { allowanceId }): AllowancesModalReducerState => ({
    ...state,
    formData: [
      ...state.formData,
      {
        allowance: allowanceId,
        quantity: 0
      }
    ]
  })
);

TimesheetsAllowances.on(
  actions.BOX_TIMESHEETS_ALLOWANCES_MODAL_UPDATED_ALLOWANCE_QUANTITY,
  (state, { allowanceId, quantity }): AllowancesModalReducerState => ({
    ...state,
    formData: state.formData.map(allowanceToBeAdded => {
      if (allowanceToBeAdded.allowance === allowanceId) {
        return {
          ...allowanceToBeAdded,
          quantity
        };
      }

      return allowanceToBeAdded;
    })
  })
);

TimesheetsAllowances.on(
  actions.BOX_TIMESHEETS_ALLOWANCES_MODAL_DELETED_ALLOWANCE,
  (state, { allowanceId }): AllowancesModalReducerState => ({
    ...state,
    formData: state.formData.filter(
      allowanceToBeAdded => allowanceToBeAdded.allowance !== allowanceId
    )
  })
);

TimesheetsAllowances.on(
  actions.BOX_TIMESHEETS_ALLOWANCES_MODAL_UPDATE_TIMESHEET_REQUEST,
  requestHandler
);

TimesheetsAllowances.on(
  actions.BOX_TIMESHEETS_ALLOWANCES_MODAL_UPDATE_TIMESHEET_SUCCESS,
  (state): AllowancesModalReducerState => ({
    ...state,
    isOpened: false
  })
);

TimesheetsAllowances.on(
  actions.BOX_TIMESHEETS_ALLOWANCES_MODAL_UPDATE_TIMESHEET_FAILURE,
  failureHandler
);

TimesheetsAllowances.on(
  actions.BOX_TIMESHEETS_ALLOWANCES_MODAL_DELETE_ALLOWANCE_REQUEST,
  requestHandler
);

TimesheetsAllowances.on(
  actions.BOX_TIMESHEETS_ALLOWANCES_MODAL_DELETE_ALLOWANCE_FAILURE,
  failureHandler
);

TimesheetsAllowances.on(
  actions.BOX_TIMESHEETS_ALLOWANCES_MODAL_ERRORS_CLEARED,
  (state): AllowancesModalReducerState => ({
    ...state,
    errors: []
  })
);

TimesheetsAllowances.on(
  actions.BOX_TIMESHEETS_ALLOWANCES_MODAL_CLOSED,
  (state): AllowancesModalReducerState => ({
    ...state,
    isOpened: false
  })
);
