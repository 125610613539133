import {
  DialogActions,
  DialogContent,
  DialogContentText,
} from 'extended-oxygen-elements';
import { useDeleteDialogContext } from 'contexts';
import { deleteTimesheet } from 'messages';
import { Button, Dialog, DialogTitle } from 'extended-oxygen-elements';
import React from 'react';
import { useDispatch } from 'react-redux';
import { BOX_TIMESHEETS_WEEKLY_TIMESHEET_DELETE } from 'state/TimesheetsWeeklyPage';

export default DeleteDialog;

export function DeleteDialog() {
  const dispatch = useDispatch();
  const { id: timesheetId, closeDialog } = useDeleteDialogContext();

  const handleConfirmButtonCLick = () => {
    dispatch(BOX_TIMESHEETS_WEEKLY_TIMESHEET_DELETE.request({ timesheetId }));
    closeDialog();
  };

  return (
    <Dialog open={!!timesheetId} onClose={closeDialog}>
      <DialogTitle>{deleteTimesheet.title}</DialogTitle>

      <DialogContent>
        <DialogContentText>{deleteTimesheet.description}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={closeDialog}
          data-testid={'timesheet-delete-cancel'}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          onClick={handleConfirmButtonCLick}
          data-testid={'timesheet-delete-confirmed'}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
