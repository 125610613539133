import React from 'react';
import { DeleteOutlinedIcon, Header } from 'element';
import { connect } from 'react-redux';
import { StoreState } from '../../../../../../../../state/types';
import { getIsOutdatedValue } from '../../../../../../../../state/EmployeeDashboard/ShiftTrades/selectors';

type OwnProps = {
  clickBackBtn: () => void;
  clickRemoveBtn: () => void;
  deleteDisabled: boolean;
};

type StateProps = {
  isOutDated: boolean;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

const ShiftSwapHeaderComponent = ({
  deleteDisabled,
  clickRemoveBtn,
  clickBackBtn,
  isOutDated
}: Props) => (
  <Header
    title="Shift swap"
    backButton={{
      title: 'Swift swap',
      onClick: clickBackBtn
    }}
    buttons={[
      {
        icon: <DeleteOutlinedIcon />,
        label: 'Delete shift trade',
        onClick: clickRemoveBtn,
        isDisabled: isOutDated || deleteDisabled
      }
    ]}
  />
);

const mapStateToProps = (state: StoreState): StateProps => ({
  isOutDated: getIsOutdatedValue(state, 'shiftSwap')
});

const mapDispatchToProps: DispatchProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShiftSwapHeaderComponent);
