import { createAction } from 'lib/store-utils';
import { FormattedErrors } from 'type/models';
import {
  DayDetails,
  EmployeeUnavailabilityProps,
  EmployeeSpreadOfHoursResponse,
  EmployeeUnavailabilityResponse,
  EmployeeUnavailabilityRequestPayload,
  OpenModalProps,
  TimeOffRosteredShift,
  NotesModalProps
} from './types';

export const BOX_EMPLOYEE_GET_UNAVAILABILITY = createAction('Employee dashboard / get request');
export const BOX_EMPLOYEE_GET_UNAVAILABILITY_SUCCESS = createAction<EmployeeUnavailabilityResponse>('Employee dashboard / get request');
export const BOX_EMPLOYEE_GET_UNAVAILABILITY_FAILURE = createAction<FormattedErrors>('Employee dashboard / get request');

export const BOX_EMPLOYEE_UNAVAILABILITY_CLEAR_ERRORS = createAction('Employee dashboard / remove errors');

export const BOX_EMPLOYEE_SET_DAY_DETAILS_CARD = createAction<DayDetails | null>('Employee dashboard / set day details');

export const BOX_EMPLOYEE_UNAVAILABILITY_MODAL_OPEN = createAction<OpenModalProps>('Employee dashboard / Unavailability modal open');
export const BOX_EMPLOYEE_UNAVAILABILITY_MODAL_CLOSE = createAction('Employee dashboard / Unavailability modal close');
export const BOX_EMPLOYEE_UNAVAILABILITY_NOTES_MODAL_TOGGLE = createAction<NotesModalProps>('Employee dashboard / Unavailability notes modal toggle');

export const BOX_EMPLOYEE_UNAVAILABILITY_NEXT_MONTH = createAction('Employee dashboard / To the next month');
export const BOX_EMPLOYEE_UNAVAILABILITY_PREV_MONTH = createAction('Employee dashboard / To the prev month');
export const BOX_EMPLOYEE_UNAVAILABILITY_RESET_MONTH = createAction('Employee dashboard / To the current month');

export const BOX_EMPLOYEE_UNAVAILABILITY_GET_DATA_ON_DATE_CHANGED = createAction('Employee dashboard / get data');

export const BOX_EMPLOYEE_SET_SPREAD_OF_HOURS = createAction<EmployeeSpreadOfHoursResponse[]>('Employee dashboard / set spread of hours');

export const BOX_EMPLOYEE_GET_UNAVAILABILITY_BY_ID = createAction<string>('Employee dashboard / get unavailability by ID');
export const BOX_EMPLOYEE_GET_UNAVAILABILITY_BY_ID_SUCCESS = createAction<EmployeeUnavailabilityProps>('Employee dashboard / get unavailability by ID success');
export const BOX_EMPLOYEE_GET_UNAVAILABILITY_BY_ID_FAILURE = createAction<FormattedErrors>('Employee dashboard / get unavailability by ID failure');

export const BOX_EMPLOYEE_CREATE_UNAVAILABILITY = createAction<EmployeeUnavailabilityRequestPayload>('Employee dashboard / create request');
export const BOX_EMPLOYEE_CREATE_UNAVAILABILITY_SUCCESS = createAction<EmployeeUnavailabilityProps>('Employee dashboard / create request success');
export const BOX_EMPLOYEE_CREATE_UNAVAILABILITY_FAILURE = createAction<FormattedErrors>('Employee dashboard / create request failure');

export const BOX_EMPLOYEE_UPDATE_UNAVAILABILITY = createAction<EmployeeUnavailabilityRequestPayload>('Employee dashboard / update request');
export const BOX_EMPLOYEE_UPDATE_UNAVAILABILITY_SUCCESS = createAction<EmployeeUnavailabilityProps>('Employee dashboard / update request success');
export const BOX_EMPLOYEE_UPDATE_UNAVAILABILITY_FAILURE = createAction<FormattedErrors>('Employee dashboard / update request failure');

export const BOX_EMPLOYEE_UPDATE_TIMEOFF_ROSTERED_SHIFT = createAction<TimeOffRosteredShift>('Employee dashboard / update/add rostered shift in calendar');
export const BOX_EMPLOYEE_UPDATE_ADD_UNAVAILABILITY_SUCCESS = createAction<EmployeeUnavailabilityProps>('Employee dashboard / update update/add timeoff in calendar');

export const BOX_EMPLOYEE_REMOVE_ROSTERED_SHIFT = createAction<string>('Employee dashboard / remove Rostered shift from calendar');

export const BOX_EMPLOYEE_DELETE_UNAVAILABILITY = createAction<string>('Employee dashboard / delete request');
export const BOX_EMPLOYEE_DELETE_UNAVAILABILITY_SUCCESS = createAction<string>('Employee dashboard / delete request success');
export const BOX_EMPLOYEE_DELETE_UNAVAILABILITY_FAILURE = createAction<FormattedErrors>('Employee dashboard / delete request failure');
