import React from 'react';
import { connect } from 'react-redux';
import { FetchWrapper } from 'element';

import {
  EmployeeDashboardHeader,
  EmployeeDashboardLayout,
} from '../../components';
import { EventsFilters, EventsList } from './components';
import { EMPLOYEE_DASHBOARD_EVENTS } from 'state/FetchPageData/constants';
import { BOX_EMPLOYEE_DASHBOARD_EVENTS_DATA_REQUEST } from 'state/EmployeeDashboard/Events';
import { Redirect } from 'react-router';
import { privateRoutes } from '../../../../routes';
import { StoreState } from '../../../../state/types';
import { getCanViewForecastsAndEvents } from '../../../../state/Account';

type OwnProps = {};

type StateProps = {
  canViewForecastsAndEvents: boolean;
};

type DispatchProps = {
  fetchData: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const Events = (props: Props) => {
  if (!props.canViewForecastsAndEvents) {
    return <Redirect to={privateRoutes.employeeDashboard.path} />;
  }
  return <EmployeeDashboardLayout header={
    <EmployeeDashboardHeader />
  }>
    <FetchWrapper {...props} pageId={EMPLOYEE_DASHBOARD_EVENTS}>
      <EventsFilters />
      <EventsList />
    </FetchWrapper>
  </EmployeeDashboardLayout>
}

const mapStateToProps = (state: StoreState): StateProps => ({
  canViewForecastsAndEvents: getCanViewForecastsAndEvents(state)
});

const mapDispatchToProps: DispatchProps = {
  fetchData: BOX_EMPLOYEE_DASHBOARD_EVENTS_DATA_REQUEST
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Events);
