import React from 'react';
import classNames from 'clsx';
import { RosterNavWrapper } from '../RosterNavWrapper';
import { DayWrapper } from './DayWrapper';
import './RosterWeekNavigation.scss';

export type Props = {
  days: RosterWeekNavigationDay[];
};

export type RosterWeekNavigationDay = {
  title: string;
  current: boolean;
  path?: string;
  onClick?: () => void;
};

export const RosterWeekNavigation = ({ days }: Props) => (
  <RosterNavWrapper>
    <div className="roster-week-navigation">
      <div className="roster-week-navigation__days">
        {days.map(
          ({ current, title, path, onClick }: RosterWeekNavigationDay, dayIndex) => (
            <DayWrapper
              key={dayIndex}
              path={path}
              className="roster-week-navigation__day"
              onClick={onClick}
            >
              <span
                className={classNames('roster-week-navigation__label', {
                  current
                })}
              >
                {title}
              </span>
            </DayWrapper>
          )
        )}
      </div>
    </div>
  </RosterNavWrapper>
);
