import ChainHandler from 'lib/ChainHandler';
import { HandlerArgs, HandlerResult } from './types';
import { isTimesheetInProgress } from '../helpers';

class ShiftInProgressHandler extends ChainHandler<HandlerResult, HandlerArgs> {
  handle(...args: HandlerArgs): HandlerResult {
    const [previousState, ...restArgs] = args;
    const { timesheetInProgress } = previousState;
    const { timesheet } = restArgs[0];

    const updatedState =
      !isTimesheetInProgress(timesheet) &&
      timesheetInProgress?.timesheet_id === timesheet.id &&
      ({
        ...previousState,
        timesheetInProgress: null,
      } as typeof previousState);

    return super.handle(updatedState || previousState, ...restArgs);
  }
}

export default ShiftInProgressHandler;
