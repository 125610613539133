import { useDialogContentRef } from 'extended-oxygen-elements';
import React from 'react';
import AlertErrorBox, { AlertErrorBoxProps } from './AlertErrorBox';

export type AlertErrorBoxDialogProps = Omit<AlertErrorBoxProps,
  'scrollParentRef' | 'scrollOffset'>;

export default AlertErrorBoxDialog;

export function AlertErrorBoxDialog(props: AlertErrorBoxDialogProps) {
  const dialogContentRef = useDialogContentRef();

  return <AlertErrorBox {...props} scrollParentRef={dialogContentRef} />;
}
