import { createAction } from 'lib/store-utils';
import { Notifications } from 'type';
import { ChangeNotificationPayload } from './type';

export const BOX_NOTIFICATIONS_REQUEST = createAction('Get notifications request');
export const BOX_NOTIFICATIONS_SUCCESS = createAction<Notifications>('Get notifications success');
export const BOX_NOTIFICATIONS_FAILURE = createAction<string[]>('Get notifications failed');

export const BOX_UPDATE_NOTIFICATIONS_REQUEST = createAction('Update notifications request');
export const BOX_UPDATE_NOTIFICATIONS_SUCCESS = createAction('Update notifications success');
export const BOX_UPDATE_NOTIFICATIONS_FAILURE = createAction<string[]>('Update notifications failed');

export const BOX_UPDATE_NOTIFICATIONS_EMAIL = createAction<boolean>('Update notifications settings');
export const BOX_UPDATE_NOTIFICATIONS_SMS = createAction<boolean>('Update notifications settings');
export const BOX_UPDATE_NOTIFICATIONS_NOTIFICATIONS = createAction<boolean>('Update notifications settings');

export const BOX_CHANGE_NOTIFICATIONS = createAction<ChangeNotificationPayload>('Change notification');
export const BOX_NOTIFICATIONS_CLEAR_ERRORS = createAction('Settings notifications / clear errors');
