import React, { useEffect, useState } from 'react';
import { Grid, FormControlLabel } from 'oxygen-elements';
import { DateInputMoment } from 'element';
import moment, { Moment } from 'moment';
import { SERVER_DAY_FORMAT } from 'lib/config';
import { useSelector } from 'react-redux';
import { getFrom, getTo } from 'state/RosteredShiftsCollection';
import { Typography } from '@mui/material';

type Props = {
  setTemplateOptions: (start: string, end: string) => void;
};

export const TemplateRangePicker = ({ setTemplateOptions }: Props) => {
  const from = useSelector(getFrom);
  const to = useSelector(getTo);
  const [start, setStart] = useState<Moment>(from.clone());
  const [end, setEnd] = useState<Moment>(to.clone());
  const MIN_DAYS_IN_TEMPLATE = 6;
  const MAX_DAYS_IN_TEMPLATE = 30;

  useEffect(() => {
    setTemplateOptions(
      start.format(SERVER_DAY_FORMAT),
      end.format(SERVER_DAY_FORMAT)
    );
  }, [start, end]);

  const onDateRangeChange = (value: Moment | null, name: string) => {
    if (name === 'start') {
      const diff = moment(end).diff(moment(start), 'days');
      setStart(moment(value));
      setEnd(moment(value).startOf('day').add(diff, 'days'));
    } else {
      setEnd(moment(value));
    }
  };

  const disabledDatesTo = (current?: Date): boolean => {
    if (current && start !== null) {
      const diff = moment(current).diff(start, 'days');
      return (
        moment(current) < start ||
        diff > MAX_DAYS_IN_TEMPLATE ||
        diff < MIN_DAYS_IN_TEMPLATE
      );
    }
    return false;
  };

  const disabledDatesFrom = (current?: Date): boolean => {
    if (current && end !== null) {
      const diff = moment(current).diff(end, 'days');
      return diff > MAX_DAYS_IN_TEMPLATE;
    }
    return false;
  };

  const getDiff = () => {
    return moment(end).diff(start, 'days') + 1;
  };

  return (
    <>
      <Grid container spacing={2} marginTop={'0'} marginLeft={'-10px'}>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <DateInputMoment
                name={'start'}
                onChange={(e: Moment | null) => onDateRangeChange(e, 'start')}
                value={start}
                placeholder={SERVER_DAY_FORMAT}
                disabledDate={disabledDatesFrom}
                isInline={false}
                isReadOnly={true}
                className={'bg-white'}
                ariaLabel="Date to"
                isClearable={false}
              />
            }
            label={'From'}
            labelPlacement={'top'}
            sx={{ alignItems: 'flex-start' }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <DateInputMoment
                name={'end'}
                onChange={(e: Moment | null) => onDateRangeChange(e, 'end')}
                value={end}
                placeholder={SERVER_DAY_FORMAT}
                disabledDate={disabledDatesTo}
                isInline={false}
                isReadOnly={true}
                className={'bg-white'}
                ariaLabel="Date to"
                isClearable={false}
              />
            }
            labelPlacement={'top'}
            label={'To'}
            sx={{ alignItems: 'flex-start' }}
          />
        </Grid>
      </Grid>
      <Typography
        variant={'body2'}
        sx={{ paddingLeft: '7px', fontSize: '12px', letterSpacing: '0.75px' }}
      >
        The selected template will be applied for{' '}
        <span style={{ fontWeight: 500 }}>{getDiff()} days</span>
      </Typography>
    </>
  );
};
