import React from 'react';
import Search from '../../Search';
import './SelectListSearch.scss';

export type SelectListSearchProps = {
  onChange: (search: string) => void;
};

function SelectListSearch({ onChange }: SelectListSearchProps) {
  return (
    <div className="elmo-select-list__search">
      <Search isVisible={true} onChange={onChange} />
    </div>
  );
}

export default SelectListSearch;
