import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as isLoading from 'state/IsLoading';

export function useRoute() {
  const { push } = useHistory();
  const dispatch = useDispatch();

  const goTo = (path: string) => {
    push(path);
  };

  const redirect = (url: string) => {
    dispatch(isLoading.BOX_IS_LOADING_GLOBAL_ON());
    window.location.href = url;
  };

  const openInNewTab = (url: string) => window.open(url, '_blank');

  return {
    goTo,
    redirect,
    openInNewTab
  };
}

export default useRoute;
