import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedErrors } from 'type';
import { ErrorBox } from 'element';
import { StoreState } from 'state/types';
import { getErrors } from '../../../../../../state/EmployeeDashboard/MyTimesheet/selectors';
import { BOX_MY_TIMESHEET_SET_ERRORS } from '../../../../../../state/EmployeeDashboard/MyTimesheet/actions';

type OwnProps = {};

type StateProps = {
  errors: FormattedErrors;
};

type DispatchProps = {
  setErrors: (errors: FormattedErrors) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

class MyTimesheetErrorBoxComponent extends Component<Props> {
  render() {
    const { setErrors, ...restProps } = this.props;
    return <ErrorBox {...restProps} clearErrors={this.clearErrors} />;
  }

  private clearErrors = () => {
    this.props.setErrors([]);
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  errors: getErrors(state)
});

const mapDispatchToProps: DispatchProps = {
  setErrors: BOX_MY_TIMESHEET_SET_ERRORS
};

export const MyTimesheetErrorBox = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyTimesheetErrorBoxComponent);
