import moment, { Moment } from 'moment';
import { DateTimeType } from 'type';
import { SERVER_DATE_TIME_FORMAT } from 'lib/config';

export const dateTimeHelper = {
  parse: (value: DateTimeType): Moment =>
    moment(value, SERVER_DATE_TIME_FORMAT),
  stringify: (value: Moment): DateTimeType =>
    value.format(SERVER_DATE_TIME_FORMAT),
};

export default dateTimeHelper;
