import React from 'react';
import { useSelectorWithProps } from 'hooks';
import { getTimesheet } from 'state/TimesheetsCollection';
import { useAreaName, useTimesheetCard, useTimesheetUserName } from './hooks';
import { TimesheetContextualMenu } from './contextualMenus';
import {
  ShiftCardBreaksDuration,
  ShiftCardContextMenuIcon,
  ShiftCardIcons,
} from './views';
import { ShiftCardViewRefactored } from './views/ShiftCardViewRefactored';

type Props = {
  timesheetId: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
};

export const SiteTimesheetCard = React.memo(function UserTimesheetCard(
  props: Props
) {
  const timesheet = useSelectorWithProps(getTimesheet, props.timesheetId);
  const { viewProps, icons, breaks, onClickContextMenu } =
    useTimesheetCard(timesheet);
  const userName = useTimesheetUserName(timesheet);
  const areaName = useAreaName(timesheet);

  return (
    <ShiftCardViewRefactored
      {...viewProps}
      data-timesheet-id={props.timesheetId}
      beforeContent={
        <TimesheetContextualMenu
          elementId={viewProps.id}
          timesheetId={props.timesheetId}
        />
      }
      icons={
        <>
          <ShiftCardIcons icons={icons} />
          {onClickContextMenu && (
            <ShiftCardContextMenuIcon onClick={onClickContextMenu} />
          )}
        </>
      }
      breaks={<ShiftCardBreaksDuration breaks={breaks} />}
      title={userName}
      spotTitle={areaName}
    />
  );
});
