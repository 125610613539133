import { createCachedSelector } from 're-reselect';
import { StoreState } from 'state/types';
import { getItemSafeCreator } from 'state/combiners';
import { TimesheetResponseReducerState } from './types';

const getState = (state: StoreState): TimesheetResponseReducerState =>
  state.timesheetResponse;
const getPayload = (state: StoreState) => getState(state).payload;
export const getFrom = (state: StoreState) => getPayload(state).from;
export const getTo = (state: StoreState) => getPayload(state).to;
export const getSiteId = (state: StoreState) => getPayload(state).site_id;
export const getTimesheets = (state: StoreState) =>
  getState(state).withPayEntries;
export const getTimesheet = (state: StoreState, id: string) =>
  getTimesheets(state)[id];
export const getTimesheetSafe = getItemSafeCreator(getTimesheets);

export const getTimesheetsArray = (state: StoreState) =>
  Object.values(getTimesheets(state));

export const timesheetCostSelector = createCachedSelector(
  getTimesheet,
  ({ pay_entries }) =>
    pay_entries.reduce(
      (timesheetCost, { rate, quantity }) =>
        timesheetCost + parseFloat(rate) * parseFloat(quantity),
      0
    )
)((state, timesheetId) => timesheetId);
