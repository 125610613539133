import React, { useEffect, useState } from 'react';
import { isAppMarket } from '../../../../helpers';
import { marketLabels } from 'marketLabels';
import { useDispatch, useSelector } from 'react-redux';
import {
  BOX_ROSTER_CLOSE_APPLY_CHANGES_MODAL,
  BOX_ROSTER_PUBLISH_CHANGES_REFACTORED,
  getIsModalOpen,
  getUnpublishedRosters,
  RefactoredPublishRostersPayload,
} from 'state/Roster/Roster';
import { useSelectorWithProps } from 'hooks';
import { Dialog } from 'extended-oxygen-elements';
import _ from 'lodash';
import { getSiteId } from 'state/RosteredShiftsCollection';
import {
  getRostersToPublish,
  prepareDefaultState,
  showSelectedNumber,
  getSelectedAreasNumber,
} from './helpers';
import { PublishArea, PublishWeekStateItem } from './types';
import { hasPermissionSelector } from 'state/Auth';
import { DialogContent, DialogTitle, Typography } from 'oxygen-elements';
import PublishDialogActions from './PublishDialogActions';
import UnpublishedList from './UnpublishedList';
import { getLangPreferences } from 'state/Account';
import { capitalize } from 'lib/helpers';

const PublishModal = () => {
  const unpublishedRosters = useSelector(getUnpublishedRosters);
  const siteId = useSelector(getSiteId);
  const isOpened = useSelectorWithProps(getIsModalOpen, 'publish');
  const langPreferences = useSelector(getLangPreferences);

  const canEditRosteredShifts = useSelectorWithProps(
    hasPermissionSelector,
    'roster.rosteredshift.edit'
  );
  const dispatch = useDispatch();

  const [weeks, setWeek] = useState(prepareDefaultState(unpublishedRosters));
  const [actionsNum, setAction] = useState(0);
  const [isDisabled, setDisabled] = useState(false);

  useEffect(() => {
    setWeek(prepareDefaultState(unpublishedRosters));
  }, [unpublishedRosters.total, isOpened]);

  useEffect(() => {
    validateButton();
  }, [actionsNum, isOpened]);

  const setWeekOpenedState = (weekId: string) => {
    setWeek((prevState) => ({
      ...prevState,
      [weekId]: {
        ...prevState[weekId],
        isOpened: !prevState[weekId].isOpened,
      },
    }));
  };

  const setWeekCheckedState = (weekId: string) => {
    const areas: PublishArea = {};

    for (let id in weeks[weekId].areas) {
      areas[id] = !weeks[weekId].isChecked;
    }

    setWeek((prevState) => ({
      ...prevState,
      [weekId]: {
        ...prevState[weekId],
        isChecked: !prevState[weekId].isChecked,
        areas,
        selectedAreasNumber: getSelectedAreasNumber(areas),
        showSelectedNumber: showSelectedNumber(areas),
      },
    }));

    setAction(actionsNum + 1);
  };

  const setWeekAreaCheckedState = (weekId: string, areaId: string) => {
    const areasNumber = Object.keys((weeks as any)[weekId].areas).length;
    const newValue = !(weeks as any)[weekId].areas[areaId];

    const uncheckedAreasId: string[] = !newValue ? [areaId] : [];
    _.map((weeks as any)[weekId].areas, (isChecked, id) => {
      if (!isChecked && id !== areaId) {
        uncheckedAreasId.push(id);
      }
    });

    setWeek((prevState) => {
      const newStateWeek: PublishWeekStateItem = {
        ...prevState[weekId],
        areas: {
          ...prevState[weekId].areas,
          [areaId]: newValue,
        },
        isChecked:
          uncheckedAreasId.length === 0 ||
          uncheckedAreasId.length < areasNumber,
      };
      return {
        ...prevState,
        [weekId]: {
          ...newStateWeek,
          showSelectedNumber: showSelectedNumber(newStateWeek.areas),
          selectedAreasNumber: getSelectedAreasNumber(newStateWeek.areas),
        },
      };
    });

    setAction(actionsNum + 1);
  };

  const validateButton = () => {
    let valid = false;
    for (let weekId in weeks) {
      const week = weeks[weekId];
      for (let areaId in week.areas) {
        if (week.areas[areaId] === true) {
          valid = true;
        }
      }
    }
    setDisabled(!valid);
  };

  const closeModal = () => {
    dispatch(BOX_ROSTER_CLOSE_APPLY_CHANGES_MODAL());
  };

  const publishChanges = () => {
    const rosters = getRostersToPublish(weeks);
    const payload: RefactoredPublishRostersPayload = {
      site_id: siteId,
      ignore_errors: false,
    };
    if (rosters) {
      payload['rosters'] = rosters;
    }
    dispatch(BOX_ROSTER_PUBLISH_CHANGES_REFACTORED(payload));
  };

  if (!isOpened || !canEditRosteredShifts) {
    return null;
  }

  return (
    <Dialog
      maxWidth="xs"
      id={'publish-rostered-shifts'}
      open={isOpened}
      onClose={closeModal}
    >
      <DialogTitle>
        {isAppMarket('uk') ? 'Share rota' : 'Share roster'}
      </DialogTitle>

      <DialogContent>
        <Typography>
          Share a {marketLabels.roster} with your employees to communicate new
          shifts, updates to existing shifts or offer unassigned shifts.{' '}
          {capitalize(langPreferences.employee.plural)} will get a notification.
        </Typography>
        <br />

        <UnpublishedList
          weeks={weeks}
          unpublishedRosters={unpublishedRosters}
          setWeekOpenedState={setWeekOpenedState}
          setWeekCheckedState={setWeekCheckedState}
          setWeekAreaCheckedState={setWeekAreaCheckedState}
        />
      </DialogContent>

      <PublishDialogActions
        closeModal={closeModal}
        publishChanges={publishChanges}
        isDisabled={isDisabled}
      />
    </Dialog>
  );
};

export default PublishModal;
