import { createReducer } from 'lib/store-utils';
import * as actions from './actions';

import { AccountsReducerState } from './types';
import { getDefaultState } from './state';

export const accounts = createReducer<AccountsReducerState>({}, getDefaultState());

accounts.on(actions.BOX_ACCOUNTS_REQUEST, getDefaultState);

accounts.on(actions.BOX_ACCOUNTS_GET_ACCOUNTS_LIST_SUCCESS, (state, payload) => ({
  ...state,
  accounts: payload
}));

accounts.on(actions.BOX_ACCOUNTS_UPDATE_SEARCH_QUERY, (state, query) => ({
  ...state,
  search: {
    ...state.search,
    query
  }
}));
