import React from 'react';

export type FormItemMessageProps = {
  message: string | undefined;
};

function FormItemMessage({ message }: FormItemMessageProps) {
  return <div className="elmo-formitem__control__help">{message}</div>;
}

export default FormItemMessage;
