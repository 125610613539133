import React, { useEffect, useState } from 'react';
import classNames from 'clsx';
import { ExpandMoreOutlinedIcon, ExpandLessOutlinedIcon } from 'element';
import './style.scss';
import { connect } from 'react-redux';
import {
  getSummaryLoading,
  getSummaryStatus,
} from 'state/Roster/Summary/selectors';
import { SummaryStatus } from 'state/Roster/Summary/types';
import { StoreState } from 'state/types';
import {
  BOX_SUMMARY_GET_DATA,
  BOX_SUMMARY_SET_STATUS,
} from 'state/Roster/Summary';
import { Paragraph } from 'elmo-elements';
import { CircularProgress } from 'extended-oxygen-elements';

type StateProps = {
  status: SummaryStatus;
  isLoading: boolean;
};

type DispatchProps = {
  setStatus: (status: SummaryStatus) => void;
  getSummaryData: () => void;
};

type Props = StateProps & DispatchProps;

export function SummaryButton(props: Props) {
  const { status, isLoading } = props;

  const [prevStatus, setPrevStatus] = useState(status);

  useEffect(() => {
    setPrevStatus(status);
  }, [status]);

  const showArrow = () => (
    <div className="summary-button__icon">
      {status === 'visible' || status === 'minimized' ? (
        <ExpandMoreOutlinedIcon />
      ) : (
        <ExpandLessOutlinedIcon />
      )}
    </div>
  );

  const showPreloader = () => (
    <CircularProgress
      color="inherit"
      size={20}
      sx={{ left: '-6px', position: 'relative' }}
    />
  );

  return (
    <div className={classNames('summary-button')}>
      <div className="summary-button__content-wrapper">
        <div
          id="summary-button"
          className="summary-button__label"
          onClick={() => {
            updateStatus(props, prevStatus);
          }}
        >
          <Paragraph>Weekly Summary</Paragraph>
          {isLoading ? showPreloader() : showArrow()}
        </div>
      </div>
    </div>
  );
}

function updateStatus(props: Props, prevStatus: SummaryStatus) {
  let status: SummaryStatus = 'hidden';

  if (prevStatus === 'hidden') {
    status = 'minimized';
  }

  props.setStatus(status);

  if (status === 'minimized') {
    props.getSummaryData();
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  status: getSummaryStatus(state),
  isLoading: getSummaryLoading(state),
});

const mapDispatchToProps: DispatchProps = {
  setStatus: BOX_SUMMARY_SET_STATUS,
  getSummaryData: BOX_SUMMARY_GET_DATA,
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryButton);
