import React from 'react';
import { connect } from 'react-redux';
import { ShiftOffersWithPagerSelector } from 'type';
import { Pager } from 'element';
import { StoreState } from 'state/types';
import {
  shiftOffersWithPagerSelector,
  BOX_SHIFT_OFFERS_CHANGE_PAGE,
  BOX_SHIFT_OFFERS_CHANGE_PAGE_SIZE,
  getIsUpdating
} from 'state/ManagerDashboard/ShiftOffers/ShiftOffers';

type StateProps = {
  data: ShiftOffersWithPagerSelector;
  isUpdating: boolean;
};

type DispatchProps = {
  changePage: (pageNumber: number) => void;
  changePageSize: (pageSize: number) => void;
};

type Props = StateProps & DispatchProps;

const ShiftOffersPagerComponent = (props: Props) => <Pager {...props} />;

const mapStateToProps = (state: StoreState): StateProps => ({
  data: shiftOffersWithPagerSelector(state),
  isUpdating: getIsUpdating(state)
});

const mapDispatchToProps: DispatchProps = {
  changePage: BOX_SHIFT_OFFERS_CHANGE_PAGE,
  changePageSize: BOX_SHIFT_OFFERS_CHANGE_PAGE_SIZE
};

export const ShiftOffersPager = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShiftOffersPagerComponent);
