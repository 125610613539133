import { createReducer } from 'lib/store-utils';
import moment from 'moment';
import * as actions from './actions';
import { TimesheetsCollectionReducerState } from './types';

export const timesheetsCollection =
  createReducer<TimesheetsCollectionReducerState>(
    {},
    {
      payload: {
        site_id: '',
        from: moment(),
        to: moment(),
      },
      timesheets: {},
    }
  );

timesheetsCollection
  .on(
    actions.BOX_TIMESHEET_GET_LIST_SUCCESS,
    (state, { payload, timesheets }): TimesheetsCollectionReducerState => ({
      payload,
      timesheets,
    })
  )
  .on(
    actions.BOX_TIMESHEET_UPDATE_ONE,
    (state, timesheet): TimesheetsCollectionReducerState => ({
      ...state,
      timesheets: {
        ...state.timesheets,
        [timesheet.id]: timesheet,
      },
    })
  )
  .on(
    actions.BOX_TIMESHEET_DELETE,
    (state, { id }): TimesheetsCollectionReducerState => {
      const { [id]: deletedTimesheet, ...timesheets } = state.timesheets;

      return {
        ...state,
        timesheets,
      };
    }
  );
