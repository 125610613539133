import React from 'react';
import { CustomFilters } from './components';
import { Box, Drawer, Button, Typography, IconButton } from 'oxygen-elements';
import { CloseOutlinedIcon } from 'element';

type Props = {
  isOpened: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onReset: () => void;
  isSubmitDisabled: boolean;
  isResetDisabled: boolean;
};

export const CustomFiltersModal = ({
  isOpened,
  onClose,
  onSubmit,
  onReset,
  isSubmitDisabled,
  isResetDisabled,
}: Props) => {
  return (
    <Drawer
      open={isOpened}
      onClose={onClose}
      anchor={'right'}
      id={'roster-custom-filters-modal'}
    >
      <Box sx={{ overflow: 'hidden' }}>
        <Box
          padding={3}
          sx={{ height: 'calc(100vh - 80px)', overflowY: 'auto' }}
        >
          <Typography
            variant={'h3'}
            paddingBottom={3}
            sx={{ position: 'relative' }}
          >
            Filter
            <IconButton
              data-testid={'close-filters-btn'}
              onClick={onClose}
              sx={{ position: 'absolute', right: '-7px', top: '-7px' }}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Typography>
          <CustomFilters
            onResetBtnClick={onReset}
            isResetDisabled={isResetDisabled}
          />
        </Box>
        <Box
          paddingLeft={3}
          paddingRight={3}
          paddingTop={2}
          paddingBottom={2}
          sx={{
            background: 'white',
            position: 'absolute',
            bottom: '0',
            left: '0',
            right: '0',
            boxShadow: '0 0 6px 2px #ccc',
            zIndex: 3,
          }}
        >
          <Button
            disabled={isSubmitDisabled}
            variant={'contained'}
            onClick={onSubmit}
            fullWidth={false}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};
