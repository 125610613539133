import React, { Component, ReactNode } from 'react';
import classNames from 'clsx';
import { Button } from 'extended-oxygen-elements';
import {
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
  PlaylistAddIcon,
} from 'element';
import './RosterEventsLegend.scss';
import { getCurrencyFormatted } from 'lib/helpers';
import {
  getCurrencyCode,
  getCurrencyPlacement,
  getNumberFormat,
} from 'state/Account';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import {
  PreferencesCurrencyCode,
  PreferencesCurrencyPlacement,
  PreferencesNumberFormat,
} from 'type/models';

export type Duration = {
  value: number;
  title: string;
  isVisible: boolean;
};

type StateProps = {
  numberFormat: PreferencesNumberFormat;
  currencyCode: PreferencesCurrencyCode;
  currencyPlacement: PreferencesCurrencyPlacement;
};

type Props = {
  type?: 'single-row' | 'multi-row' | 'always-single-row';
  icon: ReactNode;
  label: string;
  durations: Duration[];
  onClick?: () => void;
  isDisabled?: boolean;
  toggleRow?: () => void;
} & StateProps;

type State = {
  isOpened: boolean;
  toggleArrow: boolean;
};

const fixLabelClassName = (durations: Duration[]) => {
  let labelFixClassname = '';
  let visible = 0;
  durations.forEach(({ isVisible }) => {
    if (isVisible) {
      visible += 1;
    }
  });
  if (visible === 0) {
    labelFixClassname = 'elmo-roster-legend--no-label';
  }
  return labelFixClassname;
};

export class RosterEventsLegendComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpened: false,
      toggleArrow: false,
    };
  }

  toggleFullInfo = (e: any) => {
    this.setState({
      isOpened: !this.state.isOpened,
    });
  };

  toggleArrow = () => {
    this.setState({
      ...this.state,
      toggleArrow: !this.state.toggleArrow,
    });
    if (typeof this.props.toggleRow !== 'undefined') {
      this.props.toggleRow();
    }
  };

  render() {
    const {
      durations,
      type,
      icon,
      onClick,
      toggleRow,
      label,
      isDisabled,
      numberFormat,
      currencyCode,
      currencyPlacement,
    } = this.props;
    const { isOpened, toggleArrow } = this.state;
    let className = 'elmo-roster-legend__desktop-card-placeholder';
    className += isOpened ? ' is-opened' : '';
    className += isDisabled ? ' is-disabled' : '';

    return (
      <div
        className={classNames(
          'elmo-roster-legend elmo-roster-legend--events elmo-roster-legend--events__collapsable',
          `elmo-roster-legend--${type ? type : 'single-row'}`,
          fixLabelClassName(durations)
        )}
      >
        <div
          className="elmo-roster-legend__mobile-card-placeholder"
          onClick={this.toggleFullInfo}
        >
          <div className="elmo-roster-legend__icon-wrapper">{icon}</div>
        </div>

        <div className={className} onClick={this.toggleFullInfo}>
          <div className="elmo-roster-legend__icon-wrapper">{icon}</div>
          <div className="elmo-roster-legend__content-wrapper">
            <div className="elmo-roster-legend__label" title={label}>
              {label}
            </div>
            {durations.map((data, key) => (
              <div
                className="elmo-roster-legend__duration event-duration"
                key={key}
                style={{
                  display: data.isVisible ? 'flex' : 'none',
                }}
              >
                <div className="elmo-roster-legend__duration-value">
                  {data.title}:{' '}
                  {getCurrencyFormatted(
                    numberFormat,
                    currencyCode,
                    currencyPlacement,
                    data.value
                  ).replace(/ /i, '')}
                </div>
              </div>
            ))}
          </div>
          {!!toggleRow && (
            <Button
              className={'toggle-panel-button'}
              onClick={this.toggleArrow}
              aria-label={'Expand'}
              color={'inherit'}
            >
              {!toggleArrow ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )}
            </Button>
          )}
          {!!toggleRow && !!onClick && (
            <Button
              className={'add-row-button'}
              onClick={onClick}
              aria-label={'Add row'}
              size={'small'}
            >
              <PlaylistAddIcon />
            </Button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  numberFormat: getNumberFormat(state),
  currencyCode: getCurrencyCode(state),
  currencyPlacement: getCurrencyPlacement(state),
});

export const RosterEventsLegend = connect(mapStateToProps)(
  RosterEventsLegendComponent
);
