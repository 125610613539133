import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedErrors } from 'type';
import { StoreState } from 'state/types';
import { CommonError } from '../CommonError';

export type FetchErrorsHandlerProps = {
  errorsSelector: (state: StoreState) => FormattedErrors;
  children: React.ReactNode;
  errorsComponent?: React.ElementType<{ errors: FormattedErrors }>;
};

export default FetchErrorsHandler;

export function FetchErrorsHandler({
  errorsSelector,
  children,
  errorsComponent: ErrorsComponent = CommonError,
}: FetchErrorsHandlerProps) {
  const errors = useSelector(errorsSelector);

  if (errors.length) {
    return <ErrorsComponent errors={errors} />;
  }

  return <>{children}</>;
}


