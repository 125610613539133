import React from 'react';
import _ from 'lodash';
import { Box } from 'extended-oxygen-elements';
import { Button } from 'extended-oxygen-elements';
import { useBulkApproveContext } from 'contexts';

type Props = {
  onApproveBtnClick: () => void;
};

export function ApproveActions({ onApproveBtnClick }: Props) {
  const bulkContext = useBulkApproveContext();
  const selectedQuantity = bulkContext.selectedIds.length;
  const isBtnDisabled = !selectedQuantity;

  const btnLabel = _.compact([
    'Approve timesheets',
    !!selectedQuantity && `(${selectedQuantity})`,
  ]).join(' ');

  return (
    <>
      <Button
        size="medium"
        disabled={isBtnDisabled}
        onClick={onApproveBtnClick}
        data-testid="bulk-approve-confirm-btn"
      >
        {btnLabel}
      </Button>

      <Box ml="auto">
        <Button
          onClick={bulkContext.disable}
          data-testid={'bulk-approve-mode-cancel-btn'}
        >
          Cancel
        </Button>
      </Box>
    </>
  );
}
