import { Sticky } from 'element';
import { over } from 'lodash';
import { Moment } from 'moment';
import React, { Component, createRef, ReactNode, UIEvent } from 'react';
import { connect } from 'react-redux';
import { getIsBulkDeleteOpened } from 'state/Roster/BulkActions/selectors';
import { currentTimeBySiteTimezoneSelector } from 'state/Roster/Roster/selectors';
import { StoreState } from 'state/types';
import { scrollableViewZIndex } from '../../constants';
import WithThemeBackground from '../WithThemeBackground';
import './RosterViewScrollableWrapper.scss';

type OnMountCallbackParams = { content: HTMLDivElement };

type OnMountCallback = (params: OnMountCallbackParams) => void;

type OwnProps = {
  children: ReactNode;
  header: ReactNode;
  onMount?: OnMountCallback[];
  scrollableAddonSelector?: string;
};

type StateProps = {
  currentTime: Moment;
  bulkDelete: boolean;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

class RosterViewScrollableWrapperComponent extends Component<Props> {
  private scrollableHeaderRef = createRef<HTMLDivElement>();
  private scrollableContentRef = createRef<HTMLDivElement>();

  render() {
    const { children, header, bulkDelete } = this.props;

    return (
      <WithThemeBackground>
        {(backgroundColor) => (
          <div
            className={`roster-view-scrollable-wrapper ${
              bulkDelete ? 'bulk-mode' : ''
            }`}
            style={{
              backgroundColor,
            }}
          >
            <Sticky
              style={{
                zIndex: scrollableViewZIndex,
              }}
            >
              <div
                className="roster-view-scrollable-wrapper__header-wrapper"
                ref={this.scrollableHeaderRef}
              >
                <div className="roster-view-scrollable-wrapper__content-inner">
                  {header}
                </div>
              </div>
            </Sticky>

            <div
              className="roster-view-scrollable-wrapper__content-wrapper"
              ref={this.scrollableContentRef}
              onScroll={this.onScroll}
            >
              <div className="roster-view-scrollable-wrapper__content-inner">
                {children}
              </div>
            </div>
          </div>
        )}
      </WithThemeBackground>
    );
  }

  componentDidMount(): void {
    const content: HTMLDivElement | null = this.scrollableContentRef.current;

    if (!content) {
      return;
    }

    const { onMount = [] } = this.props;

    const callBack: OnMountCallback = over(onMount);

    callBack({ content });
  }

  private onScroll = (event: UIEvent<HTMLDivElement>) => {
    const { scrollableAddonSelector } = this.props;
    const header: HTMLDivElement | null = this.scrollableHeaderRef.current;

    if (header) {
      header.scrollLeft = event.currentTarget.scrollLeft;
    }
    if (scrollableAddonSelector) {
      const scrollableAddonElement: HTMLDivElement | null =
        document.querySelector(scrollableAddonSelector);
      if (scrollableAddonElement) {
        scrollableAddonElement.style.left = `-${event.currentTarget.scrollLeft}px`;
      }
    }
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  currentTime: currentTimeBySiteTimezoneSelector(state),
  bulkDelete: getIsBulkDeleteOpened(state),
});

// const mapDispatchToProps: DispatchProps = {};

export const RosterViewScrollableWrapper = connect(mapStateToProps)(
  RosterViewScrollableWrapperComponent
);
