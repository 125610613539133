import React from 'react';
import { Alert, AlertProps } from 'extended-oxygen-elements';
import { FormattedErrors } from 'type';

export type AlertErrorProps = Omit<AlertProps, 'children'> & {
  errors: FormattedErrors;
};

export default AlertError;

export function AlertError({
  errors,
  severity = 'error',
  ...restProps
}: AlertErrorProps) {
  return (
    <Alert {...restProps} severity={severity}>
      {errors.map((error, errorIndex) => (
        <div key={errorIndex}>{error}</div>
      ))}
    </Alert>
  );
}
