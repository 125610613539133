import { createReducer } from 'lib/store-utils';
import {
    BOX_TIMEZONES_REQUEST,
    BOX_TIMEZONES_SUCCESS
} from './actions';
import { defaultState, MiscReducerState } from './state';

export const misc = createReducer<MiscReducerState>({}, defaultState);

misc.on(BOX_TIMEZONES_REQUEST, state => ({
  ...state,
  isLoading: true,
  errors: []
}));

misc.on(BOX_TIMEZONES_SUCCESS, (state, payload) => ({
  ...state,
  isLoading: false,
  isFetched: true,
  timezones: payload,
  errors: []
}));
