import { ReactNode } from 'react';

export type RadioProps = {
  /** Is checked by default */
  checked?: boolean;
  /** radio button value */
  value: string | number;
  /** disable state, false by default */
  disabled?: boolean;
  /** id attribute */
  id?: string;
  /** name attribute */
  name?: string;
  /** onChange method */
  onChange?: (val: string | number) => void;
  /** class name attribute */
  className?: string;
  /** to show tooltip */
  tooltip?: string;
};

export type RadioLabelProps = {
  children?: ReactNode;
};

export const RadioKeyConstants = {
  enterKey: 'Enter',
};
