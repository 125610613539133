import React, { ReactNode } from 'react';
import { isAppMarket } from 'helpers/helpers';
import _ from 'lodash';

type IsAppMarketArgs = Parameters<typeof isAppMarket>;

type Props = {
  market: IsAppMarketArgs[0];
  children: ReactNode | ((isMarket: boolean) => ReactNode);
  negate?: boolean;
};

export const IsAppMarket = ({ market, children, negate }: Props) => {
  const isRequestedAppMarket = isAppMarket(market, negate);

  if (_.isFunction(children)) {
    return <>{children(isRequestedAppMarket)}</>;
  }

  if (isRequestedAppMarket) {
    return <>{children}</>;
  }

  return null;
};
