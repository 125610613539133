import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import { Notification, Heading, Paragraph } from 'elmo-elements';
import {
  BOX_TOAST_NOTIFIER_CLOSE,
  BOX_TOAST_NOTIFIER_UNDO
} from '../../state/ToastNotifier';
import { ToastNotification } from '../../state/ToastNotifier/types';
import './ToastNotifier.scss';

type StateProps = {
  notifications: ToastNotification[];
};

type DispatchProps = {
  undo: (id: string) => void;
  onClose: (id: string) => void;
};

type Props = {
  visibleNumber: number;
} & StateProps & DispatchProps;

export class ToastNotifierComponent extends Component<Props> {

  static defaultProps = {
    visibleNumber: 3
  };

  getZIndex = (index: number) => {
    const { visibleNumber } = this.props;
    return ((index + 1) > visibleNumber) ? -3000 : (3000 - index);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    if (this.props !== prevProps) {
      const list = document.querySelectorAll(`.toast-notification`);
      list.forEach((item: Element, index: number) => {
        if ( index === 0 ) {
          (item as any).style.bottom = '0px';
          (item as any).style.zIndex = 3000;
        } else {
          const elBefore = document.querySelector(`.toast-notification[data-index="${index - 1}"]`);
          if ( elBefore !== null ) {
            const inner = elBefore.querySelector('.elmo-notification__item');
            const bottom = +(elBefore as any).style.bottom.replace(/px/, '') || 0;
            if ( inner ) {
              const { height } = inner.getBoundingClientRect();
              (item as any).style.bottom = `${bottom + height + 15}px`;
              (item as any).style.zIndex = this.getZIndex(index);
            }
          }
        }
      });
    }
  }

  render() {
    let { notifications, visibleNumber } = this.props;
    return (
      <>
        {notifications.map((notification, index) => {
          const { isShown, type, showUndo, message, title, id, undoText } = notification;
          const msg = title
            ? <><Heading size={'sm'}>{title}</Heading><Paragraph size={'sm'}>{message}</Paragraph></>
            : message;
          return (
            <div
              id={id}
              data-index={index}
              className={'toast-notification'}
              key={id}
            >
              <Notification
                key={id}
                type={type}
                message={msg}
                isActive={isShown && index < visibleNumber}
                onClose={this.getOnClose(id)}
                onUndo={this.getOnUndo(id, showUndo)}
                undoBtnText={undoText}
              />
            </div>
          );
        })}
      </>
    );
  }

  private getOnClose = (id: any) => () => {
    this.props.onClose(id);
  }

  private getOnUndo = (id: any, showUndo?: boolean) => {
    if (!showUndo) {
      return;
    }

    return () => {
      this.props.undo(id);
    };
  }
}

const mapStateToProps = ({
  toastNotifier: { notifications }
}: StoreState): StateProps => ({
  notifications
});

const mapDispatchToProps: DispatchProps = {
  onClose: BOX_TOAST_NOTIFIER_CLOSE,
  undo: BOX_TOAST_NOTIFIER_UNDO
};

export const ToastNotifier = connect(
  mapStateToProps,
  mapDispatchToProps
)(ToastNotifierComponent);