import { Checkbox } from 'extended-oxygen-elements';
import React from 'react';
import { useModalStyles } from './hooks/publishModalStyles';
import { ListItemButton } from '@mui/material';
import { ListItemText } from 'oxygen-elements';

type Props = {
  areaId: string;
  areaName: string;
  setSelectedState: (weekId: string, areaId: string) => void;
  weekId: string;
  unpublishedChangesNumber: string;
  isChecked: boolean;
};

export default function PublishAreaItem({
  areaId,
  areaName,
  setSelectedState,
  weekId,
  unpublishedChangesNumber,
  isChecked,
}: Props) {
  const classes = useModalStyles();
  return (
    <ListItemButton
      className={classes.subListItem}
      data-test-id={'publish-list-area-label'}
      onClick={(e) => setSelectedState(weekId, areaId)}
    >
      <Checkbox
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => {
          e.stopPropagation();
          setSelectedState(weekId, areaId);
        }}
        checked={isChecked}
        data-test-id={'publish-list-area-checkbox'}
      />
      <ListItemText disableTypography className={classes.areaTitle}>
        Share {unpublishedChangesNumber} for {areaName}
      </ListItemText>
    </ListItemButton>
  );
}
