import React, { Component, ReactChild } from 'react';
import { connect } from 'react-redux';
import { Filter } from 'elmo-elements';
import { StoreState } from 'state/types';

import { CustomFilters } from './components/CustomFilters';
import { EventsPeriod } from 'type';
import {
  BOX_EVENTS_TOGGLE_PERIOD_TYPE_REQUEST,
  BOX_EVENTS_UPDATE_FILTERS_REQUEST,
  getSelectedPeriod,
  isFiltersFormDisabledSelector
} from 'state/ManagerDashboard/Events';

type StateProps = {
  selectedFilter: EventsPeriod;
  isFiltersFormDisabled: boolean;
};

type DispatchProps = {
  updateCustomFilters: () => void;
  togglePeriodFilter: (period: EventsPeriod) => void;
};

type Props = StateProps & DispatchProps;

export class EventsFiltersComponent extends Component<Props> {
  statuses: {
    label: string;
    value: EventsPeriod;
  }[] = [
    {
      label: 'Today',
      value: 'today'
    },
    {
      label: 'Tomorrow',
      value: 'tomorrow'
    },
    {
      label: 'All',
      value: 'all'
    }
  ];

  render() {
    const {
      updateCustomFilters,
      selectedFilter,
      isFiltersFormDisabled
    } = this.props;

    return (
      <Filter
        id="manager-dashboard-events-filters"
        onStatusChange={this.onStatusChange}
        statuses={this.statuses}
        renderCustomFilters={this.renderCustomFilters}
        onApplyCustomFilters={updateCustomFilters}
        selectedValue={selectedFilter}
        isApplyBtnDisabled={isFiltersFormDisabled}
      />
    );
  }

  onStatusChange = (period: string) => {
    this.props.togglePeriodFilter(period as EventsPeriod);
  };

  renderCustomFilters = (): ReactChild => <CustomFilters />;
}

const mapStateToProps = (state: StoreState): StateProps => ({
  selectedFilter: getSelectedPeriod(state),
  isFiltersFormDisabled: isFiltersFormDisabledSelector(state)
});

const mapDispatchToProps: DispatchProps = {
  updateCustomFilters: BOX_EVENTS_UPDATE_FILTERS_REQUEST,
  togglePeriodFilter: BOX_EVENTS_TOGGLE_PERIOD_TYPE_REQUEST
};
export const EventsFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(EventsFiltersComponent);
