import { Header } from 'element';
import { useSelectorWithProps } from 'hooks';
import { useHeaderPrimaryButton } from 'page/Roster/hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import { getSiteSafe } from 'state/AccountTree';
import { getSiteId } from 'state/RosteredShiftsCollection';
import Navigation from './Navigation';
import useHeaderButtons from './useHeaderButtons';

export default DayHeader;

export function DayHeader() {
  const siteId = useSelector(getSiteId);
  const site = useSelectorWithProps(getSiteSafe, siteId);

  const headerButtons = useHeaderButtons();
  const headerPrimaryButton = useHeaderPrimaryButton();

  if (!site) {
    return null;
  }

  return (
    <div className="rosters-header-wrapper-improved">
      <Header
        title={<Navigation />}
        buttons={headerButtons}
        primaryButton={headerPrimaryButton}
      />
    </div>
  );
}
