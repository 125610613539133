import { StoreState } from '../types';
import { ReportOrder, ReportPagination } from './state';
import { ReportType } from '../../type/models';
import { getGroupColumnParams } from './helpers';
import _ from 'lodash';
import { CustomisedFields } from './types';
import moment from 'moment';

export const getReportId = (state: StoreState): string =>
  state.report.report.id;
export const getReportPagination = (state: StoreState): ReportPagination =>
  state.report.pagination;
export const getRows = (state: StoreState): any[] =>
  state.report.generatedReport.rows;
export const getFields = (state: StoreState): CustomisedFields =>
  state.report.generatedReport.fields;
export const getReportTotals = (state: StoreState): any =>
  state.report.generatedReport.totals;
export const getGroups = (state: StoreState): any =>
  state.report.generatedReport.groups;
export const getReportType = (state: StoreState): ReportType =>
  state.report.report.type;
export const getOrder = (state: StoreState): ReportOrder => state.report.order;
export const getReportGroupBy = (state: StoreState): any =>
  state.report.report.extra.group_by;

const orderRows = (state: StoreState) => {
  const groups = getGroups(state);
  const order = getOrder(state);
  const type = getReportType(state);
  if (groups.length) {
    const groupBY = getReportGroupBy(state);
    if (groupBY) {
      const { name } = getGroupColumnParams(type, groupBY);
      if (name !== order.by) {
        return orderInGroup(state);
      }
    }
  }
  return orderUngrouped(state);
};

const orderByDate = (rows: any[], by: string, direction: 'asc' | 'desc') => {
  const result = rows.map((row) => ({
    ...row,
    start: moment(row.start, 'DD-MM-YYYY'),
    end: moment(row.end, 'DD-MM-YYYY'),
  }));
  const ordered = _.orderBy(result, [by], [direction]);
  return ordered.map((row) => ({
    ...row,
    start: row.start.format('DD-MM-YYYY'),
    end: row.end.format('DD-MM-YYYY'),
  }));
};

function orderUngrouped(state: StoreState) {
  const order = getOrder(state);
  const rows = getRows(state);
  const type = getReportType(state);
  const direction = order.dir !== 'asc' ? 'desc' : 'asc';
  if (type === 'timesheet' && (order.by === 'start' || order.by === 'end')) {
    return orderByDate(rows, order.by, direction);
  }
  return _.orderBy(rows, [order.by], [direction]);
}

function orderInGroup(state: StoreState) {
  const order = getOrder(state);
  const direction = order.dir !== 'asc' ? 'desc' : 'asc';
  const groups = getGroups(state);
  const rows = getRows(state);
  const type = getReportType(state);

  let groupedRows: any = {};
  let orderedRows: any[] = [];

  for (let group of groups) {
    groupedRows[group.id] = [];
    for (let row of rows) {
      if (group.id === row.group_id) {
        groupedRows[group.id].push(row);
      }
    }
    if (type === 'timesheet' && (order.by === 'start' || order.by === 'end')) {
      groupedRows[group.id] = orderByDate(
        groupedRows[group.id],
        order.by,
        direction
      );
    } else {
      groupedRows[group.id] = _.orderBy(
        groupedRows[group.id],
        [order.by],
        [direction]
      );
    }

    orderedRows = orderedRows.concat(groupedRows[group.id]);
  }

  return orderedRows;
}

export const getCurrentPageRows = (state: StoreState): any[] => {
  const rows = orderRows(state);
  const pagination = getReportPagination(state);
  return rows.slice(pagination.startItem - 1, pagination.endItem);
};

export const getCurrentPageCommonGroupedRows = (state: StoreState): object => {
  const rows = orderRows(state);
  const pagination = getReportPagination(state);
  return {
    byPage: rows.slice(pagination.startItem - 1, pagination.endItem),
    allRows: rows,
  };
};
