import { Moment } from 'moment';

type Range = {
  from: Moment;
  to: Moment;
};

export const getDayRange = (day: Moment): Range => ({
  from: day.clone().startOf('day'),
  to: day.clone().endOf('day')
});

export const getWeekRage = (day: Moment): Range => ({
  from: day.clone().startOf('isoWeek'),
  to: day.clone().endOf('isoWeek')
});
