import { createReducer } from 'lib/store-utils';
import { ToastNotifierReducerState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';

export const toastNotifier = createReducer<ToastNotifierReducerState>(
  {},
  getDefaultState()
);

toastNotifier.on(
  actions.BOX_TOAST_NOTIFIER_MESSAGE_ADD,
  (state, partialNotification): ToastNotifierReducerState => ({
    notifications: [
      ...state.notifications,
      {
        ...partialNotification,
        isShown: false
      }
    ]
  })
);

toastNotifier.on(
  actions.BOX_TOAST_NOTIFIER_MESSAGE_OPEN,
  (state, id): ToastNotifierReducerState => ({
    notifications: state.notifications.map(notification => {
      if (notification.id === id) {
        return {
          ...notification,
          isShown: true
        };
      }

      return notification;
    })
  })
);

toastNotifier.on(
  actions.BOX_TOAST_NOTIFIER_MESSAGE_HIDE,
  (state, id): ToastNotifierReducerState => ({
    notifications: state.notifications.map(notification => {
      if (notification.id === id) {
        return {
          ...notification,
          isShown: false
        };
      }

      return notification;
    })
  })
);

toastNotifier.on(
  actions.BOX_TOAST_NOTIFIER_MESSAGE_REMOVE,
  (state, id): ToastNotifierReducerState => ({
    notifications: state.notifications.filter(
      notification => notification.id !== id
    )
  })
);
