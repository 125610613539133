import React, { memo } from 'react';
import { times } from 'lodash';
import { useMakeSelector, useSelectorWithProps } from 'hooks';
import {
  makeItemRosterRowNumberSelector,
  makeItemUserIdSelector,
} from 'state/Roster/RosterWeekViewUserView';
import UserRowWithLegend from '../../../../../components/UserRowWithLegend';
import { PairRow } from '../PairRow';

type Props = { userRowIndex: number };

export const WeekViewUserViewUserRow = memo(function WeekViewUserViewUserRow(
  props: Props
) {
  const itemRosterRowNumberSelector = useMakeSelector(
    makeItemRosterRowNumberSelector
  );
  const itemUserIdSelector = useMakeSelector(makeItemUserIdSelector);

  const rosterRowsNumber = useSelectorWithProps(
    itemRosterRowNumberSelector,
    props
  );

  const itemUserId = useSelectorWithProps(itemUserIdSelector, props);

  return (
    <UserRowWithLegend
      userId={itemUserId}
      legendType={'multi-row'}
      bulkSelect={'user'}
      rowIndex={props.userRowIndex}
    >
      {times(rosterRowsNumber, (pairRowIndex) => (
        <PairRow
          key={pairRowIndex}
          userRowIndex={props.userRowIndex}
          pairRowIndex={pairRowIndex}
        />
      ))}
    </UserRowWithLegend>
  );
});