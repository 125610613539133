import React from 'react';
import useRefCallback from './useRefCallback';

export function useResizeObserver<El extends HTMLElement>(
  onResize: (target: El) => void
) {
  const observer = React.useMemo(
    () =>
      new ResizeObserver(([{ target }]) => {
        onResize(target as El);
      }),
    []
  );

  return useRefCallback<El>(
    (node) => {
      observer.observe(node);
    },
    (node) => {
      observer.unobserve(node);
    }
  );
}

export default useResizeObserver;
