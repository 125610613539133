import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';

type Props = {
  title: string;
  spotTitle?: string;
};

const useTitleStyles = makeStyles((theme) => createStyles({
  title: {
    position: 'absolute',
    right: 0,
    left: 0,
  },
  h3: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    color: 'rgba(0, 0, 0, 0.87)',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    margin: 0,
    minHeight: '14px',
    maxWidth: '95%',
    flex: '1 1 0%',
    minWidth: 0
  }
}));

export const ShiftCardTitle = ({ title, spotTitle }: Props) => {
  const  styles = useTitleStyles();
  return (
    <div className={clsx('shift-card-view__title', styles.title)}>
      <h3 className={styles.h3}>
        {title} - {spotTitle}
      </h3>
    </div>
  );
};
