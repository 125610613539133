import React from 'react';
import ToggleContext from './ToggleContext';
import { ToggleOffProps } from '../type';

function ToggleOff({ children }: ToggleOffProps) {
  return (
    <ToggleContext.Consumer>
      {({ isOn }: any) => (isOn ? null : children)}
    </ToggleContext.Consumer>
  );
}

export default ToggleOff;
