import moment from 'moment';
import { Account, FormattedErrors } from 'type';
import { TimezonesList } from './types';

export interface AccountReducerState {
  isUpdating: boolean;
  account: Account;
  errors: FormattedErrors;
  timezonesList: TimezonesList;
  isApplyingCustomBreaks: boolean;
}

export const defaultState: AccountReducerState = {
  isUpdating: false,
  isApplyingCustomBreaks: false,
  account: {
    id: '',
    name: '',
    title: '',
    roster_period_cycles_start_date: '',
    logo: '',
    logo_url: '',
    logo_mobile: '',
    logo_mobile_url: '',
    abn: NaN,
    external_id: null,
    roster_period_cycles_start_date_editable: true,
    is_active: false,
    files_uploaded: false,
    subscription: null,
    preferences: {
      timezone: '',
      date_format: 'DD-MM-YYYY',
      time_format: '12',
      currency_placement: 'before',
      number_format: '1,234.56',
      time_intervals: '5',
      currency_code: 'USD',
      default_roster_view: 'user',
      default_pay_element_name: 'DefaultPayElementNotDefined',
      default_pay_element_payslip_name: 'Default pay element is not defined',
      pay_sauce_format: false,
    },
    language_preferences: {
      site: {
        plural: '',
        singular: '',
      },
      area: {
        plural: '',
        singular: '',
      },
      role: {
        plural: '',
        singular: '',
      },
      roster: {
        plural: '',
        singular: '',
      },
      employee: {
        plural: '',
        singular: '',
      },
      event: {
        plural: '',
        singular: '',
      },
    },
    employee_settings: {
      employee_portal: false,
      edit_profile: false,
      sorting: 'alphabetical',
      custom_fields: [
        {
          label: 'string',
          type: 'date',
          options: 'string',
          mandatory: true,
          editable: true,
        },
      ],
    },
    rostered_shift_settings: {
      unavailability_module: false,
      shift_swaps: false,
      shift_offers: false,
      autopublish_changes: false,
      users_swap_hours: 1,
      shift_offer_responses_must_be_approved: false,
      shift_swap_responses_must_be_approved: false,
      unavailability_must_be_approved: false,
      accept_shifts: false,
      accept_shifts_limit: 1,
      allow_assign_timesheet_within: 0,
      view_shifts_breaks: false,
      apply_default_breaks: false,
      default_breaks_rules: [],
      shift_who_else_working: false,
    },
    timesheet_settings: {
      manually_add_timesheets: false,
      snap_roster: '0',
      punch_in_violation: '0',
      punch_out_violation: '0',
      auto_approve_shifts: 'none',
      time_tracking: false,
      request_gps: false,
      punchclock_diff: 1,
      processing_timesheet_adjustment: false,
      mobile_allow_start_within: 1,
      mobile_punchclock: false,
      mobile_geotracking: false,
      auto_breaks: false,
      breaks_from_shift: false,
      breaks_from_rules: false,
      default_breaks_rules: [],
    },
    punchclock_settings: {
      allow_start_within: '0',
      self_assigned_shifts: false,
      only_managers_can_activate: false,
    },
    event_settings: {
      enable_events: false,
      show_employee_associated_to: false,
      show_event_name_boxes: false,
      show_value: false,
      show_event_notes: false,
      show_event_start: false,
      show_event_finish: false,
      show_event_item: false,
      show_event_address: false,
      show_event_address_name: false,
      show_event_report_to: false,
    },
    created_at: '',
    updated_at: '',
    is_trial: false,
    trial_ends_at: moment().format(),
    sbm_id: null,
    app_switcher: {
      using_payroll: false,
      using_rta: false,
    },
    payment_settings: {
      is_weekend_loadings_enabled: false,
      shift_overtime_rules: {
        everyday: [],
        sunday: [],
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
      },
    },
  },
  timezonesList: [],
  errors: [],
};
