import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedCurrency } from 'element';
import { getTimesheetsWeekSummaryTotal } from 'state/TimesheetsWeekSummary';
import {
  SummaryHeader,
  SummaryLabel,
  SummaryValue,
  SummaryWrapper,
} from './summary-view-components';

export function SummaryView() {
  const { timesheet_cost } = useSelector(getTimesheetsWeekSummaryTotal);

  return (
    <SummaryWrapper>
      <SummaryHeader>Summary</SummaryHeader>

      <SummaryLabel>Timesheet cost</SummaryLabel>

      <SummaryValue>
        <FormattedCurrency>{timesheet_cost}</FormattedCurrency>
      </SummaryValue>
    </SummaryWrapper>
  );
}
