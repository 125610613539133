import React, { Component } from 'react';

export type DateRangeInputProps = {
  placeholder?: string;
  label?: string;
  ariaLabel?: string;
  value?: Date | null;
  onChange: (value: Date | null, name?: string) => void;
};

/**
 * This component is only used for collecting props for the "Start" and "End" Datepicker components, which is
 * why it does not render anything.
 */
class DateRangeInput extends Component<DateRangeInputProps> {
  static displayName = 'DateRangeInput';

  render() {
    return <></>;
  }
}

export default DateRangeInput;
