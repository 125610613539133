import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Route, StringMap } from 'type';
import { privateRoutes } from 'routes';
import { StoreState } from 'state/types';
import { getCanViewShiftOffers } from 'state/Account';
import { NestedRoutes } from 'element/routes';

type OwnProps = {
  routes: StringMap<Route>;
};

type StateProps = {
  canViewShiftOffers: boolean;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

export const ShiftOffers = ({ routes, canViewShiftOffers }: Props) => {
  if (canViewShiftOffers) {
    return <NestedRoutes routes={routes}/>;
  }

  return <Redirect to={privateRoutes.roster.path} />;
};

const mapStateToProps = (state: StoreState): StateProps => ({
  canViewShiftOffers: getCanViewShiftOffers(state)
});

// const mapDispatchToProps: DispatchProps = {};

export default connect(
  mapStateToProps
)(ShiftOffers);
