import React from 'react';
import { DefaultDivProps } from 'type';
import { useSelectorWithProps } from 'hooks';
import { FormattedCurrency, FormattedDuration, FormattedTime } from 'element';
import { getTimesheet, timesheetCostSelector } from 'state/TimesheetResponse';
import { useBulkSelect, useTimesheetStatus } from './hooks';
import {
  BreaksDuration,
  SelectIcon,
  StatusIcon,
  TimesheetCardHeader,
  TimesheetCardHeaderIcon,
  TimesheetCardHeaderText,
  TimesheetCardText,
  TimesheetCardWrapper,
} from './components';
import { hasPermissionSelector } from 'state/Auth';

type Props = DefaultDivProps & {
  timesheetId: string;
};

export const TimesheetCard = React.forwardRef<HTMLDivElement, Props>(
  function TimesheetCard({ timesheetId, ...restProps }, ref) {
    const timesheet = useSelectorWithProps(getTimesheet, timesheetId);
    const timesheetStatus = useTimesheetStatus(timesheet);
    const { selectable, selected, select } = useBulkSelect(timesheet);
    const canViewCost = useSelectorWithProps(
      hasPermissionSelector,
      'roster.timesheet.view.labourcostings'
    );
    const timesheetCost = useSelectorWithProps(
      timesheetCostSelector,
      timesheetId
    );

    return (
      <TimesheetCardWrapper
        {...restProps}
        status={timesheetStatus}
        interactive={selectable}
        highlighted={selectable && selected}
        ref={ref}
        onClick={select}
        data-testid={`timesheet-${timesheetId}-${timesheetStatus}`}
      >
        <TimesheetCardHeader>
          {selectable && (
            <TimesheetCardHeaderIcon edge="start">
              <SelectIcon size="small" selected={selected} />
            </TimesheetCardHeaderIcon>
          )}

          <TimesheetCardHeaderText data-testid={'timesheet-duration'}>
            {timesheet.duration ? (
              <FormattedDuration>{timesheet.duration}</FormattedDuration>
            ) : (
              '...'
            )}
          </TimesheetCardHeaderText>

          <TimesheetCardHeaderIcon edge="end">
            <StatusIcon size="small" timesheet={timesheet} data-testid={'timesheet-status-icon'} />
          </TimesheetCardHeaderIcon>
        </TimesheetCardHeader>

        <TimesheetCardText data-testid={'timesheet-time'}>
          <FormattedTime>{timesheet.start}</FormattedTime> -{' '}
          {timesheet.end ? (
            <FormattedTime>{timesheet.end}</FormattedTime>
          ) : (
            '...'
          )}
        </TimesheetCardText>

        <TimesheetCardText data-testid={'timesheet-breaks'}>
          <BreaksDuration breaks={timesheet.breaks} />
        </TimesheetCardText>

        {
          canViewCost && <TimesheetCardText color="textPrimary" data-testid={'timesheet-cost'}>
            <FormattedCurrency>{timesheetCost}</FormattedCurrency>
          </TimesheetCardText>
        }
      </TimesheetCardWrapper>
    );
  }
);
