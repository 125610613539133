import { createAction, createAsyncAction } from 'lib/store-utils';
import {
  Account,
  DefaultBreakItem,
  FormattedErrors,
  Preferences,
  PunchclockSettings,
  RosteredShiftSettings,
  TimesheetSettings,
} from 'type';
import {
  CreatePayConditionPayload,
  DeletePayConditionPayload,
  ResetDefaultBreaksPayload,
  TimesheetDefaultBreaksResetPayload,
  UpdateAccountGeoFencingPayload,
  UpdateAccountPayload,
  UpdatePayConditionPayload,
  WeekLoadingsChangePayload,
  TimezonesList,
} from './types';

export const BOX_ACCOUNT_FETCHED = createAction<Account>(
  'Get account settings success'
);

export const BOX_ACCOUNT_UPDATE_REQUEST = createAction<UpdateAccountPayload>(
  'account / update / request'
);
export const BOX_ACCOUNT_UPDATE_SUCCESS = createAction<Account>(
  'account / update / success'
);
export const BOX_ACCOUNT_UPDATE_CANCEL = createAction(
  'account / update / cancel'
);
export const BOX_ACCOUNT_UPDATE_FAILURE = createAction<FormattedErrors>(
  'account / update / failed'
);

export const BOX_ACCOUNT_CLEAR_ERRORS = createAction('Account remove errors');
export const BOX_ACCOUNT_UPLOAD_REMOVE = createAction<string>('Remove image');
export const BOX_ACCOUNT_UPLOAD_APPEND = createAction<string>('Add image');

export const BOX_ACCOUNT_GEOFENCING_UPDATE_REQUEST =
  createAction<UpdateAccountGeoFencingPayload>(
    'Account / geo fencing update request'
  );

export const BOX_ACCOUNT_ROSTERED_SHIFT_SETTINGS_UPDATE = createAsyncAction<
  RosteredShiftSettings,
  Account,
  FormattedErrors
>('Account / rostered shifts settings update');
export const BOX_ACCOUNT_ROSTERED_SHIFT_SETTINGS_DEFAULT_BREAKS_RESET =
  createAsyncAction<ResetDefaultBreaksPayload, Account, FormattedErrors>(
    'Account / rostered shifts settings / default breaks / reset'
  );
export const BOX_ACCOUNT_TIMESHEET_SETTINGS_UPDATE = createAsyncAction<
  TimesheetSettings,
  Account,
  FormattedErrors
>('Account / timesheet settings update');

export const BOX_ACCOUNT_PREFERENCES_SETTINGS_UPDATE = createAsyncAction<
  Partial<Preferences>,
  Account,
  FormattedErrors
>('Account / account preferences settings update');

export const BOX_ACCOUNT_TIMEZONES = createAsyncAction<
  undefined,
  TimezonesList,
  FormattedErrors
>('Account / account timezones list');

export const BOX_ACCOUNT_TIMECLOCK_SETTINGS_UPDATE = createAsyncAction<
  PunchclockSettings,
  Account,
  FormattedErrors
>('Account / timeclock settings update');

export const BOX_ACCOUNT_TIMESHEET_DEFAULT_BREAKS_RESET = createAsyncAction<
  TimesheetDefaultBreaksResetPayload,
  Account,
  FormattedErrors
>('Account / timesheet settings / default breaks / reset');

export const BOX_ACCOUNT_PAY_CONDITIONS_WEEKEND_LOADINGS_TOGGLE =
  createAsyncAction<WeekLoadingsChangePayload, Account, FormattedErrors>(
    'Account / Weekend loadings toggle'
  );
export const BOX_ACCOUNT_PAY_CONDITIONS_RULE_CREATE = createAsyncAction<
  CreatePayConditionPayload,
  Account,
  FormattedErrors
>('Account / Pay condition create');
export const BOX_ACCOUNT_PAY_CONDITIONS_RULE_UPDATE = createAsyncAction<
  UpdatePayConditionPayload,
  Account,
  FormattedErrors
>('Account / Pay condition update');
export const BOX_ACCOUNT_PAY_CONDITIONS_RULE_DELETE = createAsyncAction<
  DeletePayConditionPayload,
  Account,
  FormattedErrors
>('Account / Pay condition delete');

export const BOX_ACCOUNT_ROSTERED_SHIFT_APPLY_CUSTOMISED_BREAKS =
  createAsyncAction<DefaultBreakItem[], Account, FormattedErrors>(
    'Account / rostered shift settings / default breaks / apply customised'
  );

export const BOX_ACCOUNT_TIMESHEET_APPLY_CUSTOMISED_BREAKS = createAsyncAction<
  DefaultBreakItem[],
  Account,
  FormattedErrors
>('Account / timesheet settings / default breaks / apply customised');
