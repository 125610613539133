import { createSelector } from 'reselect';
import _ from 'lodash';
import { StoreState } from 'state/types';
import * as isLoadingModule from 'state/IsLoading';
import { getFrom, getTo } from 'state/RosteredShifts/selectors';
import {
  CopyPayload,
  ListOfTemplatesModal,
  RosterCopyPastReducerState
} from './type';

const getState = (state: StoreState): RosterCopyPastReducerState =>
  state.rosterCopyPast.rosterCopyPast;

export const getCopiedRoster = (state: StoreState): CopyPayload =>
  getState(state);
export const copyRosterSiteId = (state: StoreState) => getState(state).site_id;
export const copyRosterRange = (state: StoreState) => getState(state).range;
export const getCopyRosterConfirmationModal = (state: StoreState) =>
  getState(state).modal;
export const getCreateRosterTemplateModal = (state: StoreState) =>
  getState(state).createRosterTemplateModal;
export const getIsPastingInProgress = (state: StoreState): boolean =>
  !!getState(state).jobs.length;
export const getTemplatesModal = (state: StoreState): ListOfTemplatesModal =>
  getState(state).templatesModal;

export const getIsLoading = (state: StoreState) =>
  isLoadingModule.getIsLoading(
    state,
    isLoadingModule.ROSTER_COPY_PASTE_CONFIRMATION_MODAL
  );

export const getCopyRosterData = createSelector(
  copyRosterSiteId,
  copyRosterRange,
  (siteId, range) => ({
    site_id: siteId,
    range
  })
);

export const isSelectedCurrentWeek = createSelector(
  getFrom,
  getTo,
  copyRosterRange,
  (from, to, range) =>
    !!(range && (_.isMatch(from, range.from) && _.isMatch(to, range.to)))
);
