import React from 'react';
import { ErrorOutlineIcon } from 'element';
import './style.scss';

export const WarningIcon = () => {
  return (
    <span className={'warning-msg'} data-testid="user-profile-custom-fields-warning-icon">
      <ErrorOutlineIcon />
    </span>
  )
};
