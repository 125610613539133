import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { EmptyActionCreator, SimpleActionCreator } from 'lib/store-utils';

type Action = EmptyActionCreator | SimpleActionCreator<any>;

export function useActions<
  M extends Action | Action[]
>(actions: M, deps?: any[]): M {
  const dispatch = useDispatch();

  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map((a) => bindActionCreators(a, dispatch));
      }

      return bindActionCreators(actions as Action, dispatch)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps ? [dispatch, ...deps] : [dispatch]
  ) as M;
}

export default useActions;
