import { defaultBreakRulesValidator } from 'page/Settings/components';
import {
  DefaultBreaksFieldGroupErrors,
  DefaultBreaksFieldGroupValue,
} from './types';

export default defaultBreaksFieldGroupValidator;

export function defaultBreaksFieldGroupValidator(
  values: DefaultBreaksFieldGroupValue
) {
  const errors: DefaultBreaksFieldGroupErrors = {};

  if (!values.apply_default_breaks) {
    return errors;
  }

  const defaultBreaksRules = defaultBreakRulesValidator(
    values.default_breaks_rules
  );

  if (defaultBreaksRules) {
    errors.default_breaks_rules = defaultBreaksRules;
  }

  return errors;
}

