import React from 'react';
import { DataTableElement } from '../type';
import { getClass } from '../../_lib/helper';

type DataTableWrapperProps = DataTableElement & {
  /** Should the table take up 100% of the parent container */
  isFullWidth?: boolean;
  isResponsive?: boolean;
};

/**
 * Table component of the DataTable
 * @param children
 * @param isResponsive
 * @param className
 * @param isFullWidth
 * @constructor
 */
function DataTableWrapper({
  children,
  className,
  isFullWidth,
}: DataTableWrapperProps) {
  const props = {
    className: getClass('elmo-datatable-table', className, {
      'full-width': isFullWidth,
    }),
  };

  return <table {...props}>{children}</table>;
}

export default DataTableWrapper;
