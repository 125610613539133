import React from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from 'extended-oxygen-elements';
import { PageDialog, PageDialogCancel, PageDialogSubmit } from 'element';

type Props = {
  isOpened: boolean;
  onClose: () => void;
  onApprove: () => void;
};

export const DeleteConfirmationModal = ({
  isOpened,
  onApprove,
  onClose,
}: Props) => (
  <PageDialog maxWidth={'xs'} open={isOpened} id="delete-confirmation-modal">
    <DialogTitle>Confirmation</DialogTitle>
    <DialogContent>
      <Typography variant={'body1'}>
        The selected template will be permanently removed. Are you sure?
      </Typography>
    </DialogContent>
    <DialogActions>
      <PageDialogCancel onClick={onClose}>Cancel</PageDialogCancel>
      <PageDialogSubmit onClick={onApprove} id="delete-template-btn">
        Yes, confirm it
      </PageDialogSubmit>
    </DialogActions>
  </PageDialog>
);
