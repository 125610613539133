import { WS } from 'lib/SocketConnection';
import { eventChannel } from 'redux-saga';
import { select } from 'redux-saga/effects';
import { BOX_ACCOUNT_UPDATE_SUCCESS, getAccountId } from 'state/Account';
import {
  BOX_AUTH_GET_CURRENT_USER_SUCCESS,
  getCurrentUserId,
} from 'state/Auth';
import { BOX_FLAGS_UPDATED, FlagsUpdatedPayload } from 'state/Flags';
import { BOX_NOTIFICATIONS_LIST_APPEND_ONE } from 'state/NotificationsList';
import { NotificationsListItem } from 'state/NotificationsList/types';
import { BOX_TOAST_NOTIFIER_MESSAGE_SHOW } from 'state/ToastNotifier';
import { BOX_USERS_COLLECTION_USER_CHANGED } from 'state/UsersCollection';
import { Account, ServerUserCurrent, ServerUserFields } from 'type';

export function* makeGlobalChannel(ws: WS) {
  const accountId: ReturnType<typeof getAccountId> = yield select(getAccountId);
  const userId: ReturnType<typeof getCurrentUserId> = yield select(
    getCurrentUserId
  );

  return eventChannel((emit) => {
    const accountChannelId = `account.${accountId}`;
    const usersChannelId = `users.${userId}`;
    const featureChannelId = `feature_service`;

    ws.private(accountChannelId).listen(
      '.account.changed',
      (account: Account) => {
        emit(BOX_ACCOUNT_UPDATE_SUCCESS(account));
      }
    );

    ws.private(usersChannelId)
      .listen('.user.current', (user: ServerUserCurrent) => {
        emit(BOX_AUTH_GET_CURRENT_USER_SUCCESS(user));
      })
      .listen('.user.changed', (user: ServerUserFields) => {
        emit(BOX_USERS_COLLECTION_USER_CHANGED(user));
      })
      .notification((notification: NotificationsListItem) => {
        emit(
          BOX_TOAST_NOTIFIER_MESSAGE_SHOW({
            title: notification.title,
            message: notification.body,
            type: 'dark',
          })
        );
        emit(BOX_NOTIFICATIONS_LIST_APPEND_ONE(notification));
      });

    ws.private(featureChannelId).listen(
      '.flag.changed',
      (flags: FlagsUpdatedPayload) => {
        emit(BOX_FLAGS_UPDATED(flags));
      }
    );

    return () => {
      ws.leave(accountChannelId);
      ws.leave(usersChannelId);
      ws.leave(featureChannelId);
    };
  });
}
