import React from 'react';
import { BOX_SOCKETS_GLOBAL_CHANNELS } from 'state/Sockets';
import { useConnectAction } from 'hooks';

export default SocketGlobalConnection;

export function SocketGlobalConnection() {
  useConnectAction(BOX_SOCKETS_GLOBAL_CHANNELS, undefined);

  return null;
}
