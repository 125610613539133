import * as React from 'react';
import {
  Dropdown,
  Heading,
  ListTable,
  Paragraph
} from 'elmo-elements';
import {
  CheckCircleOutlinedIcon,
  HighlightOffOutlinedIcon,
} from 'element';
import { PreferencesNotification } from 'type/models/preferences';
import { StringMap } from 'type';
import { isHrService } from '../../../../helpers';

type Props = {
  notifications: StringMap<PreferencesNotification>,
  onChange: Function,
  group: string;
};

class NotificationsTable extends React.Component <Props> {

  constructor(props: Props) {
    super(props);
  }

  renderNotification = (notification: any) => {
    return (
      <>
        <Heading size="sm" isBold={true}>{notification.label}</Heading>
        <Paragraph size="sm">{notification.tooltip}</Paragraph>
      </>
    );
  }

  renderDropDown = (id: string, type: string, isDisable: boolean) => {
    const {onChange, group} = this.props;
    return (
      <Dropdown
        text={this.renderDropDownOption(id, type)}
        className="dropdown"
        isDisabled={isDisable}
        isBordered={true}
        isFullWidth={true}
      >
        <Dropdown.Item
          ariaLabel="Do not notify"
          value={null}
          onClick={() => {
            onChange({group, id, type, value: 'off'});
          }}
          isDisabled={false}
        >
          {this.getIcon('off')}
          {this.renderValue('off')}
        </Dropdown.Item>

        <Dropdown.Item
          ariaLabel="Notify"
          value={null}
          onClick={() => {
            onChange({group, id, type, value: 'notify'});
          }}
          isDisabled={false}
        >
          {this.getIcon('notify')}
          {this.renderValue('notify')}
        </Dropdown.Item>

      </Dropdown>
    );
  }

  render() {
    const {notifications} = this.props;

    return (
      <ListTable
        ariaLabel="Notifications"
        className="notification-table"
      >
        <ListTable.Header>
          <ListTable.Column
            label={'Notification'}
          />
          {this.showEmail && <ListTable.Column
                label="Email"
            /> }
          {(this.showSMS && !isHrService('bravo')) && <ListTable.Column
                label="SMS"
            />}
        </ListTable.Header>
        <ListTable.Body>
          {
            Object.keys(notifications).map((id: string, index: number) => {
              return (
                  <ListTable.Tr
                    key={index}
                  >
                    <ListTable.Td>
                      {this.renderNotification(notifications[id])}
                    </ListTable.Td>
                    {notifications[id].email ? <ListTable.Td>
                      {this.renderDropDown(id, 'email', !notifications[id].email_editable)}
                    </ListTable.Td> : <></>}
                      {(notifications[id].sms && !isHrService('bravo')) ? <ListTable.Td>
                        {this.renderDropDown(id, 'sms', !notifications[id].sms_editable)}
                      </ListTable.Td> : <></>}
                  </ListTable.Tr>
              );
            })
          }
        </ListTable.Body>
      </ListTable>
    );
  }

  renderDropDownOption = (id: string, type: string) => {
    const value = type === 'sms' ? this.props.notifications[id].sms : this.props.notifications[id].email;
    return (
      <> {this.getIcon(value)}  {this.renderValue(value)}
      </>
    );
  }

  renderValue = (value: string) => {
    switch (value) {
      case 'notify':
        return 'Notify';
      case 'off':
        return 'Do not notify';
      case 'user_input':
        return 'User Preferences';
      default:
        return 'Notify';
    }
  }

  getIcon = (type: 'notify' | 'off') => {
    if (type === 'notify') {
      return <div className="mr-4 icon notify"><CheckCircleOutlinedIcon className="text-color-success"/></div>;
    } else {
      return <div className="mr-4 icon notNotify"><HighlightOffOutlinedIcon className="text-color-danger"/></div>;
    }
  }

  get showEmail() {
    const {notifications} = this.props;
    const event = notifications[Object.keys(notifications)[0]];
    return event &&  typeof event.email !== 'undefined';
  }

  get showSMS() {
    const {notifications} = this.props;
    const event = notifications[Object.keys(notifications)[0]];
    return event &&  typeof event.sms !== 'undefined';
  }
}

export default NotificationsTable;
