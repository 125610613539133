import { createStyles, makeStyles } from '@mui/styles';
import { useLayoutContext } from 'element';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from 'extended-oxygen-elements';
import React from 'react';
import { useRosterMenu } from './useRosterMenu';

const useStyles = makeStyles((theme) =>
  createStyles({
    item: {
      color: theme.palette.primary.contrastText,
      borderRadius: 0,
      padding: theme.spacing(1.5, 2),
      margin: 0,
      '&:hover': {
        color: theme.palette.primary.contrastText,
      },
    },
    itemSelected: {
      backgroundColor: `${theme.palette.action.selected} !important`,
    },
    itemIcon: {
      color: theme.palette.primary.contrastText,
      minWidth: theme.componentsConfig.svgIcon.size.medium + theme.gap(2),
    },
  })
);

export default function RosterMenu() {
  const { setMobileOpen } = useLayoutContext();
  const classes = useStyles();
  const menuItems = useRosterMenu();

  const makeHandleListItemClick =
    (handler: React.MouseEventHandler<HTMLDivElement> | undefined) =>
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (handler) {
        handler(event);
        setMobileOpen(false);
      }
    };

  return (
    <List data-testid="roster-menu">
      {menuItems.map(
        ({ handleClick, icon: Icon, isSelected, title, id, testId }) => (
          <ListItem
            key={id}
            button
            onClick={makeHandleListItemClick(handleClick)}
            selected={isSelected}
            classes={{
              root: classes.item,
              selected: classes.itemSelected,
            }}
            data-testid={testId}
          >
            <ListItemIcon className={classes.itemIcon}>
              <Icon color="inherit" />
            </ListItemIcon>

            <ListItemText primary={title} />
          </ListItem>
        )
      )}
    </List>
  );
}
