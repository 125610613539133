import React from 'react';
import { DividerProps } from './type';
import './Divider.scss';

function Divider({ id, role, ariaOrientation }: DividerProps) {
  return (
    <div
      id={id}
      data-testid={`elmo-divider-${id || 'default'}`}
      className="elmo-divider"
      role={role}
      aria-orientation={ariaOrientation}
    />
  );
}

Divider.defaultProps = {
  role: 'separator',
  ariaOrientation: 'horizontal',
};

export default Divider;
