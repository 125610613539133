import {
  updateEmployeeTimeOffRosteredShift,
  updateEmployeeUnavailability,
} from 'lib/Api/helpers';
import { EmployeeDashboardChannel } from 'lib/socket-channels';
import { WS } from 'lib/SocketConnection';
import { eventChannel } from 'redux-saga';
import { select } from 'redux-saga/effects';
import { getCurrentUserId } from 'state/Auth';
import {
  BOX_EMPLOYEE_DASHBOARD_SHIFT_CHANGED,
  BOX_EMPLOYEE_DASHBOARD_SHIFT_DELETED,
  BOX_EMPLOYEE_DASHBOARD_TIMESHEET_CHANGED,
  BOX_EMPLOYEE_DASHBOARD_TIMESHEET_DELETED,
} from 'state/EmployeeDashboard/EmployeeDashboard';
import { BOX_EMPLOYEE_DASHBOARD_EVENTS_UPDATE } from 'state/EmployeeDashboard/Events';
import {
  BOX_SHIFT_TRADES_TRADE_CHANGED,
  BOX_SHIFT_TRADES_TRADE_DELETED,
} from 'state/EmployeeDashboard/ShiftTrades';
import {
  BOX_EMPLOYEE_DELETE_UNAVAILABILITY_SUCCESS,
  BOX_EMPLOYEE_UPDATE_ADD_UNAVAILABILITY_SUCCESS,
  BOX_EMPLOYEE_UPDATE_TIMEOFF_ROSTERED_SHIFT,
} from 'state/EmployeeDashboard/Unavailability';

export function* makeEmployeeChannel(ws: WS) {
  const user_id = yield select(getCurrentUserId);

  return eventChannel((emit) => {
    const employeeDashboardChannel = new EmployeeDashboardChannel(ws, {
      user_id,
    });

    employeeDashboardChannel
      .onShiftTradeShiftOfferEmployeeChanged((data) => {
        emit(BOX_SHIFT_TRADES_TRADE_CHANGED(data));
      })
      .onShiftTradeShiftOfferEmployeeDeleted((data) => {
        emit(BOX_SHIFT_TRADES_TRADE_DELETED(data));
      })
      .onShiftTradeShiftSwapEmployeeChanged((data) => {
        emit(BOX_SHIFT_TRADES_TRADE_CHANGED(data));
      })
      .onShiftTradeShiftSwapEmployeeDeleted((data) => {
        emit(BOX_SHIFT_TRADES_TRADE_DELETED(data));
      })
      .onRosteredShiftChanged((data) => {
        emit(BOX_EMPLOYEE_DASHBOARD_SHIFT_CHANGED(data));
      })
      .onRosteredShiftDeleted((data) => {
        emit(BOX_EMPLOYEE_DASHBOARD_SHIFT_DELETED(data));
      })
      .onTimesheetChanged((data) => {
        emit(BOX_EMPLOYEE_DASHBOARD_TIMESHEET_CHANGED(data));
      })
      .onTimesheetDeleted((data) => {
        emit(BOX_EMPLOYEE_DASHBOARD_TIMESHEET_DELETED(data));
      })
      .onTimeOffChanged((data) => {
        emit(
          BOX_EMPLOYEE_UPDATE_ADD_UNAVAILABILITY_SUCCESS(
            updateEmployeeUnavailability(data)
          )
        );
      })
      .onTimeOffDeleted((data) => {
        emit(BOX_EMPLOYEE_DELETE_UNAVAILABILITY_SUCCESS(data.id));
      })
      .onTimeOffRosteredShiftChanged((data) => {
        emit(
          BOX_EMPLOYEE_UPDATE_TIMEOFF_ROSTERED_SHIFT(
            updateEmployeeTimeOffRosteredShift(data)
          )
        );
      })
      .onTimeOffRosteredShiftDeleted((data) => {
        // emit(BOX_EMPLOYEE_REMOVE_ROSTERED_SHIFT())
      })
      .onForecastEventChanged(() => {
        emit(BOX_EMPLOYEE_DASHBOARD_EVENTS_UPDATE());
      })
      .onForecastEventDeleted(() => {
        emit(BOX_EMPLOYEE_DASHBOARD_EVENTS_UPDATE());
      });

    return () => {
      employeeDashboardChannel.leave();
    };
  });
}
