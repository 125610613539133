import React from 'react';
import './CheckboxLabel.scss';

export type CheckboxLabelProps = {
  id: string;
  label: string;
};

function CheckboxLabel({ id, label }: CheckboxLabelProps) {
  return (
    <label
      id={id && 'elmo-checkbox-label-' + id}
      htmlFor={id && 'elmo-checkbox-' + id}
      className="elmo-checkbox__label"
      data-testid={`elmo-checkbox-label-${id || 'default'}`}
    >
      {label}
    </label>
  );
}

export default CheckboxLabel;
