import React, { Component } from 'react';
import { FormItem } from 'elmo-elements';
import { EventsStatus, LanguagePreferences, UpdateFormPayload } from 'type';
import { capitalize, keyMirror } from 'lib/helpers';
import {
  CheckboxesCollection,
  FromToFilters,
  SortDirectionRadioButtons,
  SortFieldRadioButtons,
} from 'element';
import {
  EventsFilters,
  SortEventsList,
} from 'state/ManagerDashboard/Events/types';

export type Props = {
  formData: EventsFilters;
  onChange: (payload: UpdateFormPayload) => void;
  langPreferences: LanguagePreferences;
};

export default class CustomFiltersView extends Component<Props> {
  readonly inputNames = keyMirror(this.props.formData);

  readonly statuses: {
    label: string;
    value: EventsStatus;
  }[] = [
    {
      label: 'On Now',
      value: 'on_now'
    },
    {
      label: 'Upcoming',
      value: 'upcoming'
    },
    {
      label: 'Finished',
      value: 'finished'
    }
  ];

  render() {
    const {
      inputNames,
      props: { formData }
    } = this;

    return (
      <form className="custom-filters-form" autoComplete={'off'}>
        <CheckboxesCollection
          formItem={{
            label: 'Status',
            isDivider: true,
            id: 'event-custom-filters-status'
          }}
          name={inputNames.statuses}
          value={formData.statuses}
          onChange={this.props.onChange}
          options={this.statuses}
          hideAllCheckbox={true}
        />

        <FromToFilters
          from={{
            name: inputNames.start,
            value: formData.start
          }}
          to={{ name: inputNames.end, value: formData.end }}
          onChange={this.props.onChange}
        />

        <FormItem label="Sort by" id="events-custom-filters-sort-fields">
          <SortFieldRadioButtons
            selected={formData.sort.column}
            onChange={this.onChangeSortField}
            sortFields={this.sortFields}
          />
        </FormItem>

        <FormItem id="events-custom-filters-sort-direction">
          <SortDirectionRadioButtons
            selected={formData.sort.direction}
            onChange={this.onChangeSortDirection}
          />
        </FormItem>
      </form>
    );
  }

  get sortFields(): {
    label: string;
    value: SortEventsList['column'];
  }[] {
    const { event, site } = this.props.langPreferences;

    return [
      {
        label: capitalize(event.singular),
        value: 'title'
      },
      {
        label: 'Status',
        value: 'status'
      },
      {
        label: capitalize(site.singular),
        value: 'location'
      },
      {
        label: 'Start date',
        value: 'start'
      },
      {
        label: 'End date',
        value: 'end'
      }
    ];
  }

  onChangeSortField = (column: string | number) => {
    this.props.onChange({
      name: this.inputNames.sort,
      value: {
        column,
        direction: this.props.formData.sort.direction
      }
    });
  };

  onChangeSortDirection = (direction: string | number) => {
    this.props.onChange({
      name: this.inputNames.sort,
      value: {
        column: this.props.formData.sort.column,
        direction
      }
    });
  };
}
