import { Parameter } from 'type';
import {
  getIsTimesheetApproved,
  getIsTimesheetInProgress,
  getIsTimesheetPending,
  typedCond,
} from 'lib/helpers';
import { useIsTimesheetViolated } from 'page/TimesheetsWeekly/hooks';

type UseTimesheetStatusProps = Parameter<
  typeof useGetIsTimesheetPendingViolated
> &
  Parameter<typeof getIsTimesheetApproved> &
  Parameter<typeof getIsTimesheetPending> &
  Parameter<typeof getIsTimesheetInProgress>;

const statuses = {
  inProgress: 'inProgress',
  pendingViolated: 'pendingViolated',
  pending: 'pending',
  approved: 'approved',
} as const;

export function useTimesheetStatus(timesheet: UseTimesheetStatusProps) {
  const getIsTimesheetPendingViolated =
    useGetIsTimesheetPendingViolated(timesheet);

  const getStatus = typedCond([
    [getIsTimesheetInProgress, () => statuses.inProgress],
    [getIsTimesheetPendingViolated, () => statuses.pendingViolated],
    [getIsTimesheetPending, () => statuses.pending],
    [getIsTimesheetApproved, () => statuses.approved],
  ]);

  return getStatus(timesheet);
}

type UseIsTimesheetPendingViolated = Parameter<typeof getIsTimesheetPending> &
  Parameter<typeof useIsTimesheetViolated>;

function useGetIsTimesheetPendingViolated(
  timesheet: UseIsTimesheetPendingViolated
) {
  const pending = getIsTimesheetPending(timesheet);
  const violated = useIsTimesheetViolated(timesheet);

  return () => pending && violated;
}

export default useTimesheetStatus;
