import * as React from 'react';
import {
  Col,
  Row,
  Toggle
} from 'elmo-elements';

type Props = {
  title: string;
  name: string;
  isOn: boolean;
  onChange: Function
};

class ToggleOption extends React.Component <Props> {

  render() {
    const {title, isOn, onChange, name} = this.props;
    return (
      <>
        <Row isNoGutters={true} role="presentation">
          <Col span={10} md={8} role="presentation">
            {title}
          </Col>
          <Col span={12} md={8} role="presentation">
            <Toggle
              isOn={isOn}
              onClick={(e: boolean) => {
                onChange(e);
              }}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default ToggleOption;
