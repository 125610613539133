import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  LayoutAwareListTable,
  Link,
  ListTable,
  LoadingOverlay,
  Paragraph,
  Text,
} from 'elmo-elements';
import { ListTableAction } from 'elmo-elements/ListTable/type';
import {
  ArchiveOutlinedIcon,
  EmailOutlinedIcon,
  MainUserRole,
  PersonOutlineIcon,
  StatusBadge,
  SupervisedUserCircleOutlinedIcon,
  UserLogIcon,
  WithBulkActions,
  withClosingBulkActionsAndSearchBeforeMount,
} from 'element';
import {
  AccountTreeArea,
  AccountTreeRole,
  AccountTreeSite,
  DataIdsSelector,
  LanguagePreferences,
  SortUser,
  SortUserFields,
  StringMap,
  UserFields,
  UserGroup,
  UserProfileFields,
  UsersWithPagerSelector,
} from 'type';
import { getPreferenceLabel, onSortCreator } from 'lib/helpers';
import { privateRoutes } from 'routes';
import { StoreState } from 'state/types';
import {
  BOX_USERS_CHANGE_ORDER,
  BOX_USERS_CHANGE_PAGE,
  BOX_USERS_CHANGE_PAGE_SIZE,
  BOX_USERS_EDIT_APPROVALS_MODAL_OPEN,
  BOX_USERS_ROLE_MODAL_OPEN,
  BOX_USERS_SAVE_PAGE_NUMBER,
  BOX_USERS_SEND_WELCOME_NOTIFICATIONS_REQUEST,
  getSort,
  userIdsSelector,
  usersWithPagerSelector,
} from 'state/Users/Users';
import { getUserGroups } from 'state/UserGroups';
import { getAccountTree } from 'state/AccountTree';
import { getLangPreferences } from 'state/Account';
import { NotFoundMessage } from './components/NotFoundMessage';
import { PAGE_SIZE } from 'lib/config';
import WithAccountPreferences from 'element/WithAccountPreferences';
import { getIsLoading } from '../../../../../../state/IsLoading';
import { SendWelcomeNotificationPayload } from '../../../../../../state/Users/Users/types';
import { isHrService } from 'helpers/helpers';
import {
  BOX_USER_PROFILE_UPDATE_REQUEST,
  getIsUpdating,
} from '../../../../../../state/Users/UserProfile';

// TODO: hotfix for mobile user list
import './style.scss';
import qs from 'qs';
import browserHistory from '../../../../../../lib/browserHistory';

type StateProps = {
  pager: UsersWithPagerSelector;
  ids: DataIdsSelector;
  isUpdating: boolean;
  sites: StringMap<AccountTreeSite>;
  areas: StringMap<AccountTreeArea>;
  roles: StringMap<AccountTreeRole>;
  userGroups: StringMap<UserGroup>;
  sort: SortUser;
  langPreferences: LanguagePreferences;
  isUserUpdating: boolean;
};
type DispatchProps = {
  changePage: (pageNumber: number) => void;
  changePageSize: (pageSize: number) => void;
  openAddRoleModal: (userId: string) => void;
  openEditApproversModal: (userId: string) => void;
  changeOrder: (payload: SortUser) => void;
  sendWelcomeNotification: (payload: SendWelcomeNotificationPayload) => void;
  updateUserStatus: (payload: Partial<UserProfileFields>) => void;
  saveCurrentPageNumber: () => void;
};
type Props = StateProps & DispatchProps & RouteComponentProps;

const COLUMNS: { [key in SortUserFields]: number | undefined } = {
  full_name: 0,
  user_group_id: 4,
  area_id: undefined,
  site_id: undefined,
  role_id: undefined,
};

export class UsersListTableComponent extends Component<Props> {
  render() {
    const { pager, sort, ids } = this.props;
    return (
      <LoadingOverlay
        isLoading={this.props.isUpdating || this.props.isUserUpdating}
      >
        {pager.total ? (
          <WithAccountPreferences>
            <WithBulkActions {...ids}>
              {({
                selectedIds,
                selectAllAvailable,
                selectAllVisible,
                toggleItem,
                clearSelected,
              }) => (
                <LayoutAwareListTable
                  className="userList"
                  data={pager.page}
                  onItemToggle={toggleItem}
                  onSelectAllToggle={selectAllVisible}
                  onSelectAllAvailableToggle={selectAllAvailable}
                  countItemsAvailable={pager.total}
                  countSelectable={selectedIds.length}
                  countSelectedItems={selectedIds.length}
                  isAllAvailableSelected={selectedIds.length === pager.total}
                  isItemSelected={this.isItemSelected(selectedIds)}
                  actions={this.getActions}
                  onRowClick={this.onRowClick}
                  avatarType="avatar"
                  sortColumn={COLUMNS[sort.column]}
                  sortDirection={sort.direction}
                  onSort={this.onSort}
                >
                  <ListTable.Column
                    label="Name"
                    render={this.renderFullName}
                    sortable={true}
                  />

                  <ListTable.Column label="Status" render={this.renderStatus} />

                  <ListTable.Column
                    label={this.getPositionLabel()}
                    render={this.renderPositionData}
                  />

                  <ListTable.Column
                    label="Contact"
                    render={this.renderContact}
                  />

                  <ListTable.Column
                    label="Permissions"
                    render={this.renderUserGroup}
                    sortable={true}
                  />

                  <ListTable.Pagination
                    pageSize={pager.pageSize}
                    totalResults={pager.total}
                    currentPage={pager.currentPage}
                    pageSizeOptions={PAGE_SIZE}
                    onPageChange={this.changePage(clearSelected)}
                    onPageSizeChange={this.changePageSize(clearSelected)}
                  />
                </LayoutAwareListTable>
              )}
            </WithBulkActions>
          </WithAccountPreferences>
        ) : (
          <NotFoundMessage />
        )}
      </LoadingOverlay>
    );
  }

  getPositionLabel = () => {
    return (
      getPreferenceLabel(
        this.props.langPreferences,
        'role',
        'singular',
        '',
        true
      ) + ' (Primary)'
    );
  };

  private get labels() {
    return {
      role: getPreferenceLabel(
        this.props.langPreferences,
        'role',
        'singular',
        ''
      ),
    };
  }

  private renderFullName = (user: UserFields) => (
    <div>
      <div data-testid="user_name" className="long-text">
        {user.full_name}
      </div>
      {!!user.prefered_name && (
        <div>
          <Text className="long-text" size="sm">
            {user.prefered_name}
          </Text>
        </div>
      )}
      {!!user.payroll_number && <div>{user.payroll_number}</div>}
    </div>
  );

  private renderPositionData = (user: UserFields) => {
    const { areas, sites, roles } = this.props;
    const props = { areas, sites, roles, user };

    return <MainUserRole {...props} />;
  };

  private renderStatus = (user: UserFields) => <StatusBadge user={user} />;

  private renderContact = (user: UserFields) => {
    return (
      <>
        <Paragraph>{user.mobile}</Paragraph>
        <Link url={`mailto:${user.email}`}>
          <Paragraph isTruncate={true}>{user.email}</Paragraph>
        </Link>
      </>
    );
  };

  private renderUserGroup = (user: UserFields) => {
    return (
      <div data-testid="user_group">
        {this.props.userGroups[user.user_group_id].title}
      </div>
    );
  };

  private getActions = (data: any): ListTableAction[] => {
    const actions = [
      {
        icon: <SupervisedUserCircleOutlinedIcon />,
        label: 'Edit approvers',
        onClick: this.onClickEditApprovers,
      },
      {
        icon: <PersonOutlineIcon />,
        label: `Add ${this.labels.role}`,
        onClick: this.onClickAddRole,
      },
    ];

    if (!isHrService('bravo')) {
      actions.push({
        icon: <EmailOutlinedIcon />,
        label: `Send welcome email`,
        onClick: this.onClickSendWelcomeNotification,
      });
    }

    return [...actions, ...this.appendChangeUserStatusBtn(data)];
  };

  appendChangeUserStatusBtn = (data: any) => {
    return isHrService('standalone')
      ? [
          {
            icon: data.is_active ? <ArchiveOutlinedIcon /> : <UserLogIcon />,
            label: data.is_active ? `Ex-employee` : `Activate`,
            onClick: this.onClickChangeUserStatus,
          },
        ]
      : [];
  };

  private isItemSelected =
    (selectedIds: string[]) =>
    (user: UserFields): boolean => {
      return selectedIds.includes(user.id);
    };

  private onClickEditApprovers = (index: number) => {
    const user: UserFields = this.props.pager.page[index];
    this.props.openEditApproversModal(user.id);
  };

  private onClickAddRole = (index: number) => {
    const user: UserFields = this.props.pager.page[index];
    this.props.openAddRoleModal(user.id);
  };

  private onRowClick = (user: UserFields) => {
    this.props.saveCurrentPageNumber();
    this.props.history.push(
      privateRoutes.users.routes.user.routes.profile.path(user.id)
    );
  };

  private onSort = onSortCreator(this.props.changeOrder, COLUMNS);

  private updatePageNumberInURL = (pageNumber: number) => {
    const url = new URL(window.location.href);
    url.searchParams.set('pageNum', `${pageNumber}`);
    window.history.pushState(null, '', url.toString());
  };

  private updatePageSizeInURL = (pageSize: number) => {
    const url = new URL(window.location.href);
    url.searchParams.set('pageSize', `${pageSize}`);
    window.history.pushState(null, '', url.toString());
  };

  private changePage = (beforeChange: () => void) => (pageNumber: number) => {
    const { changePage } = this.props;
    beforeChange();
    changePage(pageNumber);
    this.updatePageNumberInURL(pageNumber);
  };

  private changePageSize = (beforeChange: () => void) => (pageSize: number) => {
    const {
      changePage,
      changePageSize,
      pager: { currentPage },
    } = this.props;
    beforeChange();
    changePageSize(pageSize);
    changePage(1);
    this.updatePageSizeInURL(pageSize);
    this.updatePageNumberInURL(currentPage);
  };

  private onClickSendWelcomeNotification = (index: number) => {
    const user: UserFields = this.props.pager.page[index];
    this.props.sendWelcomeNotification({ userId: user.id });
  };

  private onClickChangeUserStatus = (index: number) => {
    const user: UserFields = this.props.pager.page[index];
    this.props.updateUserStatus({
      id: user.id,
      is_active: !user.is_active,
    });
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  pager: usersWithPagerSelector(state),
  isUpdating: getIsLoading(state, 'USERS'),
  ids: userIdsSelector(state),
  ...getAccountTree(state),
  userGroups: getUserGroups(state),
  sort: getSort(state),
  langPreferences: getLangPreferences(state),
  isUserUpdating: getIsUpdating(state),
});
const mapDispatchToProps: DispatchProps = {
  changePage: BOX_USERS_CHANGE_PAGE,
  changePageSize: BOX_USERS_CHANGE_PAGE_SIZE,
  openAddRoleModal: BOX_USERS_ROLE_MODAL_OPEN,
  openEditApproversModal: BOX_USERS_EDIT_APPROVALS_MODAL_OPEN,
  changeOrder: BOX_USERS_CHANGE_ORDER,
  sendWelcomeNotification: BOX_USERS_SEND_WELCOME_NOTIFICATIONS_REQUEST,
  updateUserStatus: BOX_USER_PROFILE_UPDATE_REQUEST,
  saveCurrentPageNumber: BOX_USERS_SAVE_PAGE_NUMBER,
};

export const UsersListTable = withClosingBulkActionsAndSearchBeforeMount(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(UsersListTableComponent)
  )
);
