import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Account, LanguagePreferences } from 'type';
import { StoreState } from 'state/types';
import { getLangPreferences } from 'state/Account';

type StateProps = {
  langPreferences: LanguagePreferences;
  account: Account;
};

type Props = {
  children: React.ReactNode;
} & StateProps;

type State = {
  reRender: boolean;
};

class WithAccountPreferencesComponent extends Component<Props, State> {
  state = {
    reRender: false
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.account !== this.props.account) {
      this.setState(
        {
          reRender: true
        },
        () => {
          this.setState({
            reRender: false
          });
        }
      );
    }
  }

  render() {
    return <>{!this.state.reRender ? this.props.children : null}</>;
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  langPreferences: getLangPreferences(state),
  account: state.account.account
});

export const WithAccountPreferences = connect(mapStateToProps)(
  WithAccountPreferencesComponent
);
export default WithAccountPreferences;
