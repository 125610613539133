import React from 'react';
import SaveAs from '../../components/SaveAs';
import ReportsView from './view';
import DeleteReportNotification from '../../components/DeleteReportNotification';

class AllReports extends React.Component {

  render() {
    return (
      <>
        <ReportsView />
        <SaveAs />
        <DeleteReportNotification />
      </>
    );
  }

}

export default AllReports;