import React, { Component } from 'react';
import { PageDialog, PageDialogCancel, PageDialogSubmit } from 'element';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from 'extended-oxygen-elements';

export type Props = {
  isUpdating: boolean;
  isOpened: boolean;
  timeOffId: string;
  onSubmit: (payload: { timeOffId: string }) => void;
  onClose: () => void;
};

class UnavailabilityDeleteModal extends Component<Props> {
  render() {
    const { isOpened, isUpdating, onClose } = this.props;
    return (
      <PageDialog
        maxWidth={'xs'}
        open={isOpened}
        id="delete-unavailability-request-modal-dialog"
      >
        <DialogTitle>Delete unavailability?</DialogTitle>
        <DialogContent>
          <Typography variant={'body1'}>
            Are you sure you want to delete the unavailability request?
          </Typography>
        </DialogContent>
        <DialogActions>
          <PageDialogCancel onClick={onClose}>Cancel</PageDialogCancel>
          <PageDialogSubmit
            onClick={this.onSubmit}
            loading={isUpdating}
            disabled={isUpdating}
          >
            Yes, delete
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  }

  private onSubmit = () => {
    const { timeOffId } = this.props;

    this.props.onSubmit({ timeOffId });
  };
}

export default UnavailabilityDeleteModal;
