import React from 'react';
import { CreateDefaultBreakRule } from './CreateDefaultBreakRule';
import { Button } from 'extended-oxygen-elements';
import { DefaultBreakItem } from 'type';
import { Typography } from '../../../extended-oxygen-elements';

type Props = {
  pushBreakIntoSettings: (item: DefaultBreakItem) => void;
  defaultBreaks: any[];
  handleCustomiseBreaks: () => void;
  customiseBreaks: boolean;
  applyBreaks: () => void;
  isLoading: boolean;
};

export const CustomiseBreaksForm = ({
  pushBreakIntoSettings,
  defaultBreaks,
  handleCustomiseBreaks,
  customiseBreaks,
  applyBreaks,
  isLoading,
}: Props) => {
  return (
    <>
      {defaultBreaks.length === 0 && (
        <Typography variant={'caption'}>No breaks...</Typography>
      )}

      <CreateDefaultBreakRule
        pushBreak={pushBreakIntoSettings}
        allBreaks={defaultBreaks}
      />

      <Button
        onClick={applyBreaks}
        variant={'contained'}
        disabled={defaultBreaks.length === 0 || isLoading}
        loading={isLoading}
      >
        Apply & Save breaks
      </Button>
    </>
  );
};
