import React, { memo } from 'react';
import { times } from 'lodash';
import { useSelector } from 'react-redux';
import { weekDaysNumberSelector } from 'state/Roster/RosterWeekViewUserView';
import { RosteredShiftCell } from './RosteredShiftCell';

type Props = { userRowIndex: number; pairRowIndex: number };

export const RosteredShiftRow = memo(function RosteredShiftRow(props: Props) {
  const weekDaysNumber = useSelector(weekDaysNumberSelector);
  return (
    <div className={`week-rosters-grid__group week-rosters-grid__group--roster cell-height-v2`}>
      {times(weekDaysNumber, (weekDayIndex) => (
        <div
          className="week-rosters-grid__group-day-block"
          key={weekDayIndex}
        >
          <RosteredShiftCell
            key={weekDayIndex}
            {...props}
            weekDayIndex={weekDayIndex}
          />
        </div>
      ))}
    </div>
  );
});
