import React, { Component } from 'react';
import moment, { Moment } from 'moment';
import { difference } from 'lodash';
import {
  Checkbox,
  Col,
  Divider,
  FormItem,
  Heading,
  Radio,
  RadioButtons,
  Row,
  Select,
  Tooltip,
} from 'elmo-elements';
import { SelectPropsOption } from 'elmo-elements/Select';
import { CommonAttributes } from 'elmo-elements/CheckButton/type';
import { MonthDays, ReportScheduleDay } from 'type';
import { keyMirror } from 'lib/helpers';
import {
  DateInput,
  ErrorBox,
  IsAppMarket,
  PageDialog,
  PageDialogCancel,
  PageDialogSubmit,
  TimeInput,
} from 'element';
import { DATE_OPTIONS, FORMAT_OPTIONS, scheduleOptions } from './config';
import { Errors, FormData, Props, State } from './types';
import { getFormSubmitPayload, getState } from './transformData';
import { ReportScheduleCheckButtons } from './components';
import {
  Alert,
  DialogContent,
  DialogActions,
  DialogTitle,
} from 'extended-oxygen-elements';

class ReportScheduleModal extends Component<Props, State> {
  state = getState(this.props);
  names = keyMirror(this.state.data);
  halfColProps = {
    span: 24 / 2,
  };

  render() {
    const { data } = this.state;
    const { names } = this;
    const { type, usePaySauceFormat } = this.props;

    return (
      <PageDialog
        maxWidth={'sm'}
        open={this.props.isOpened}
        id="schedule-report-modal"
        onClose={this.onCloseModal}
      >
        <DialogTitle>Report schedule</DialogTitle>
        <DialogContent>
          <form>
            <ErrorBox errors={this.props.errors} />

            <FormItem>
              <Checkbox
                label="Enable report schedule"
                id="report_schedule_is_enabled"
                name={names.is_enabled}
                isChecked={data.is_enabled}
                onChange={this.onChangeCheckbox}
              />
            </FormItem>

            <Row role="presentation">
              <Col xs={24 / 2} role="presentation">
                <FormItem label="Schedule">
                  <Select
                    isDisabled={!data.is_enabled}
                    options={scheduleOptions}
                    onChange={this.onChangeSelect(names.schedule)}
                    value={scheduleOptions.find(
                      (option) => option.value === data.schedule
                    )}
                    ariaLabel="Schedule"
                  />
                </FormItem>
              </Col>
            </Row>

            {data.schedule === 'daily' && (
              <FormItem label="Report daily on">
                <ReportScheduleCheckButtons
                  checked={data.daily_day}
                  onStatusChange={this.onCheckDailyButton}
                  isDisabled={!data.is_enabled}
                />
              </FormItem>
            )}

            {data.schedule === 'weekly' && (
              <FormItem label="Report weekly on">
                <ReportScheduleCheckButtons
                  checked={data.weekly_day}
                  onStatusChange={this.onClickCheckboxButton(names.weekly_day)}
                  isDisabled={!data.is_enabled}
                />
              </FormItem>
            )}

            {data.schedule === 'fortnightly' && (
              <FormItem label="Report fortnightly on">
                <ReportScheduleCheckButtons
                  checked={data.fortnightly_day}
                  onStatusChange={this.onClickCheckboxButton(
                    names.fortnightly_day
                  )}
                  isDisabled={!data.is_enabled}
                />
              </FormItem>
            )}

            {data.schedule === 'monthly' && (
              <FormItem label="Report monthly on">
                <Select
                  options={DATE_OPTIONS}
                  value={DATE_OPTIONS.filter((option) =>
                    data.monthly_day.includes(option.value as MonthDays)
                  )}
                  isMulti={true}
                  onChange={this.onChangeMultiSelect(names.monthly_day)}
                  isDisabled={!data.is_enabled}
                  ariaLabel="Report monthly on"
                />
              </FormItem>
            )}

            <Divider />

            {this.renderPaySauceAlert()}

            <Row role="presentation">
              <Col {...this.halfColProps} role="presentation">
                <IsAppMarket market={'uk'} negate={true}>
                  {(isMarket) => {
                    if (isMarket) {
                      return <FormItem.Label label="Report time" />;
                    } else {
                      return (
                        <Tooltip title={this.props.timezone}>
                          <FormItem.Label label="Report time" />
                        </Tooltip>
                      );
                    }
                  }}
                </IsAppMarket>
                <FormItem>
                  <TimeInput
                    value={data.time}
                    name={names.time}
                    onChange={this.onChangeTimepicker}
                    disabled={!data.is_enabled}
                    id="report-time"
                    label="Report time"
                  />
                </FormItem>
              </Col>
              <Col {...this.halfColProps} role="presentation">
                <FormItem label="Report type">
                  <RadioButtons
                    id="report_type"
                    selected={data.format}
                    type="outlined"
                    onChange={this.onChangeRadio(names.format)}
                    disabled={!data.is_enabled}
                  >
                    {FORMAT_OPTIONS.map((format, typeIndex) => (
                      <Radio value={format.value} key={typeIndex}>
                        {format.label}
                      </Radio>
                    ))}
                  </RadioButtons>
                </FormItem>
              </Col>
            </Row>

            {type === 'timesheet' &&
            usePaySauceFormat &&
            data.format === 'csv' ? (
              <FormItem label="Sub Type">
                <Checkbox
                  label="PaySauce Format"
                  id="sub_format"
                  name="sub_format"
                  isChecked={data.sub_format === 'pay_sauce'}
                  onChange={this.onChangeSubFormat}
                  isDisabled={!data.is_enabled}
                />
              </FormItem>
            ) : (
              <></>
            )}

            <Heading size="sm" type="heading">
              From
            </Heading>
            <Row role="presentation">
              <Col {...this.halfColProps} role="presentation">
                <FormItem>
                  <DateInput
                    name={names.from}
                    value={data.from.toDate()}
                    onChange={this.onChangeDatepicker}
                    isClearable={false}
                    disabledDate={this.disableFromDate}
                    isDisabled={!data.is_enabled}
                    isInline={false}
                    ariaLabel="From date"
                  />
                </FormItem>

                <Heading size="sm" type="heading">
                  To
                </Heading>

                <FormItem>
                  <DateInput
                    name={names.to}
                    value={data.to !== null ? data.to.toDate() : data.to}
                    onChange={this.onChangeDatepicker}
                    isClearable={false}
                    disabledDate={this.disableToDate}
                    isDisabled={!data.is_enabled || data.ongoing}
                    isInline={false}
                    ariaLabel="To date"
                  />
                </FormItem>
              </Col>

              <Col {...this.halfColProps} role="presentation">
                <FormItem>
                  <Checkbox
                    label="Ongoing"
                    id="report_schedule_duration_ongoing"
                    name={names.ongoing}
                    isChecked={data.ongoing}
                    onChange={this.onChangeCheckbox}
                    isDisabled={!data.is_enabled}
                  />
                </FormItem>
              </Col>
            </Row>

            <FormItem label="Recipients">
              <Select
                id="recipients"
                options={this.props.usersOptions}
                value={this.props.usersOptions.filter((userOption) =>
                  data.recipients.includes(userOption.value)
                )}
                isMulti={true}
                onChange={this.onChangeMultiSelect(names.recipients)}
                isDisabled={!data.is_enabled}
                ariaLabel="Recipients"
              />
            </FormItem>
          </form>
        </DialogContent>
        <DialogActions sx={{ boxShadow: '0 0 6px 2px #ccc', zIndex: 3 }}>
          <PageDialogCancel onClick={this.onCloseModal} fullWidth={false}>
            Cancel
          </PageDialogCancel>
          <PageDialogSubmit
            onClick={this.onSubmit}
            loading={this.props.isLoading}
            disabled={!this.state.isValid || this.props.isLoading}
            id={'schedule-report-btn'}
            fullWidth={false}
          >
            Save
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  }

  renderPaySauceAlert = () => {
    const { usePaySauceFormat } = this.props;
    const { sub_format } = this.state.data;
    return !usePaySauceFormat && sub_format === 'pay_sauce' ? (
      <Alert
        color="warning"
        severity="warning"
        className="mt-4 mb-4"
        id="paySauce-alert"
      >
        Scheduled report was created for the PaySauce format
      </Alert>
    ) : (
      <></>
    );
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    const wasOpened =
      this.props.isOpened && prevProps.isOpened !== this.props.isOpened;
    if (wasOpened) {
      this.setState(getState(this.props));
    }
  }

  private onChangeCheckbox = (
    event: React.SyntheticEvent<HTMLInputElement>
  ) => {
    const { name, checked } = event.currentTarget;

    this.setState(
      {
        data: {
          ...this.state.data,
          [name]: checked,
        },
      },
      this.validate
    );
  };

  private onChangeSubFormat = (
    event: React.SyntheticEvent<HTMLInputElement>
  ) => {
    const { name, checked } = event.currentTarget;

    this.setState(
      {
        data: {
          ...this.state.data,
          format: 'csv',
          [name]: checked ? 'pay_sauce' : null,
        },
      },
      this.validate
    );
  };

  private onCheckDailyButton = (payload: CommonAttributes) => {
    this.setState(
      {
        data: {
          ...this.state.data,
          schedule: 'weekly',
          weekly_day: difference(this.state.data.daily_day, [
            payload.id,
          ]) as ReportScheduleDay[],
        },
      },
      this.validate
    );
  };

  private onClickCheckboxButton =
    (name: keyof Pick<FormData, 'weekly_day' | 'fortnightly_day'>) =>
    ({ isChecked, id }: CommonAttributes) => {
      let dailyDay;
      const value = id! as ReportScheduleDay;

      if (isChecked) {
        dailyDay = [...this.state.data[name], value];
      } else {
        dailyDay = this.state.data[name].filter((day) => day !== value);
      }

      this.setState(
        {
          data: {
            ...this.state.data,
            [name]: dailyDay,
          },
        },
        this.validate
      );
    };

  private onChangeSelect =
    (name: keyof FormData) => (value: { label: string; value: string }) => {
      this.setState(
        {
          data: {
            ...this.state.data,
            [name]: value.value,
          },
        },
        this.validate
      );
    };

  private onChangeMultiSelect =
    (name: keyof FormData) => (values: SelectPropsOption[]) => {
      this.setState(
        {
          data: {
            ...this.state.data,
            [name]: values.map((value) => value.value),
          },
        },
        this.validate
      );
    };

  private onChangeRadio =
    (name: keyof FormData) => (value: string | number) => {
      this.setState(
        {
          data: {
            ...this.state.data,
            [name]: value,
            sub_format: null,
          },
        },
        this.validate
      );
    };

  private onChangeTimepicker = (value: Moment) => {
    this.setState(
      {
        data: {
          ...this.state.data,
          time: value,
        },
      },
      this.validate
    );
  };

  private onChangeDatepicker = (value: Date | null, name?: string) => {
    if (value && name) {
      this.setState(
        {
          data: {
            ...this.state.data,
            [name]: moment(value),
          },
        },
        this.validate
      );
    }
  };

  private disableFromDate = (current?: Date): boolean => {
    const { ongoing, to } = this.state.data;

    if (ongoing) {
      return false;
    }

    if (current && to) {
      return !moment(current).isBefore(to);
    }

    return true;
  };

  private disableToDate = (current?: Date): boolean => {
    if (current) {
      return !moment(current).isAfter(this.state.data.from);
    }
    return true;
  };

  private onSubmit = () => {
    const { usePaySauceFormat } = this.props;
    if (this.state.isValid) {
      this.props.onFormSubmit(
        getFormSubmitPayload(this.state, usePaySauceFormat)
      );
    }
  };

  private validate = () => {
    const { data } = this.state;
    const errors: Errors = {};

    if (data.is_enabled) {
      if (data.schedule === 'weekly' && !data.weekly_day.length) {
        errors.weekly_day = 'Please select a day.';
      } else if (
        data.schedule === 'fortnightly' &&
        !data.fortnightly_day.length
      ) {
        errors.fortnightly_day = 'Please select a day.';
      } else if (data.schedule === 'monthly' && !data.monthly_day.length) {
        errors.monthly_day = 'Please select a day.';
      }

      if (!data.ongoing) {
        if (data.to) {
          if (moment(data.from).isAfter(data.to)) {
            errors.from = "Can't be after end date";
          }

          if (moment(data.to).isBefore(data.from)) {
            errors.to = "Can't be before start date";
          }
        } else {
          errors.to = "Can't be blank";
        }
      }

      if (!data.recipients.length) {
        errors.recipients = "Can't be blank";
      }
    }

    this.setState({
      isValid: !Object.keys(errors).length,
      errors,
    });
  };

  private onCloseModal = () => {
    if (!this.props.isLoading) {
      this.props.onCloseModal();
    }
  };
}

export default ReportScheduleModal;
