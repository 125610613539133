import { Api } from 'lib/Api';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { formatError } from 'state/helpers';
import { processApiRequest } from 'state/ProcessApiRequest';
import { Notifications } from 'type/models';
import * as actions from './actions';
import { getNotifications } from './selectors';

export const getData = function* (): SagaIterator {
  try {
    const [notifications]: [Notifications] = yield all([
      call(processApiRequest, Api.Notifications.getAll),
    ]);
    yield put(actions.BOX_NOTIFICATIONS_SUCCESS(notifications));
  } catch (error) {
    yield put(actions.BOX_NOTIFICATIONS_FAILURE(formatError(error)));
  }
};

export const updateNotifications = function* (): SagaIterator {
  try {
    const notifications = yield select(getNotifications);
    yield call(processApiRequest, Api.Notifications.update, notifications);
    yield put(actions.BOX_UPDATE_NOTIFICATIONS_SUCCESS());
  } catch (error) {
    yield put(actions.BOX_UPDATE_NOTIFICATIONS_FAILURE(formatError(error)));
  }
};

export const watchNotificationsSettings = function* (): SagaIterator {
  yield takeLatest(actions.BOX_NOTIFICATIONS_REQUEST, getData);
  yield takeLatest(
    actions.BOX_UPDATE_NOTIFICATIONS_REQUEST,
    updateNotifications
  );
};
