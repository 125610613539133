import { ShiftTradesFilter } from 'state/EmployeeDashboard/ShiftTrades/types';
import { getShiftDuration, getTimeFormatted, getUserName } from 'lib/helpers';
import { PreferencesTimeFormat } from 'type/models';
import { ShiftTradeRosteredShift, ShiftTradeUser } from '../../../../type';
import { convertDecimalToFormattedTime } from 'lib/helpers';

export const colProps = {
  xs: 24 / 2,
  sm: 24 / 3,
  className: 'mb-1 mb-xs-2 mb-md-4',
};

export const getPlaceholderText = (selectedFilter: ShiftTradesFilter) => {
  return selectedFilter === 'custom'
    ? 'No shift trades matching your filter criteria'
    : 'No shift trades yet';
};

export const getShiftTradeDuration = (
  timeFormat: PreferencesTimeFormat,
  shift: ShiftTradeRosteredShift,
  withHrs: boolean = true
) => {
  const duration = getShiftDuration(
    shift.start,
    shift.end,
    shift.breaks,
    shift.area.site.timezone_id
  );
  const totalHrs =
    '(' +
    convertDecimalToFormattedTime(duration.total_hrs.toString(), true) +
    ')';
  return `${getTimeFormatted(timeFormat, shift.start)} - ${getTimeFormatted(
    timeFormat,
    shift.end
  )} ${withHrs ? totalHrs : ''}`;
};

export const getPositionAreaLocation = (shift: ShiftTradeRosteredShift) => {
  return `${shift.role.name} - ${shift.area.name}, ${shift.area.site.name}`;
};

export const getFullName = (user: ShiftTradeUser) => {
  return user === null ? 'Unassigned' : getUserName(user);
};

export const getTimezone = (shift: ShiftTradeRosteredShift) => {
  return shift.area.site.timezone_id;
};
