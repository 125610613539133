import React, { ReactNode } from 'react';
import { Avatar } from 'oxygen-elements';
import Button from 'elmo-elements/Button';
import { EditOutlinedIcon } from 'elmo-elements/icons';
import { Heading } from 'elmo-elements/Typography';
import DescriptionList from 'elmo-elements/DescriptionList';
import './style.scss';

type InfoType = {
  icon: ReactNode | string;
  data: ReactNode | boolean | string;
};

type Props = {
  id?: string;
  information?: InfoType[];
  fullName: string;
  avatar: string;
  editButton?: boolean;
  onAvatarClick?: () => void;
};

export const CardDetails = ({
  id,
  fullName,
  avatar,
  onAvatarClick,
  information,
  editButton,
}: Props) => {
  const handleAvatarClick = () => {
    if (onAvatarClick) {
      onAvatarClick();
    }
  };

  const getFieldValue = (value: ReactNode | boolean | string) => {
    return value ? value : '-';
  };

  return (
    <div className="card-details-wrapper">
      <div className="card-details-wrapper__avatar">
        <Avatar
          light
          alt={fullName}
          size={'large'}
          src={avatar}
          sx={{ width: '110px', height: '110px' }}
        />
        {editButton && (
          <Button
            icon={<EditOutlinedIcon />}
            isCircular={true}
            onClick={handleAvatarClick}
            ariaLabel="Edit"
          />
        )}
      </div>
      <div className="card-details-wrapper__profile-data">
        <Heading isBold={true} htmlTag={'h2'}>
          {fullName}
        </Heading>
        {information && (
          <DescriptionList style="align-icons">
            {information.map((info: InfoType, key: number) => (
              <DescriptionList.Item label={info.icon} key={key}>
                {getFieldValue(info.data)}
              </DescriptionList.Item>
            ))}
          </DescriptionList>
        )}
      </div>
    </div>
  );
};
