import { put, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions';
import { SagaIterator } from 'redux-saga';
import Api from '../../../lib/Api';
import { apiCall, apiWithConfirmCall } from '../../ProcessApiRequest';
import { formatError } from 'state/helpers';
import { ApiWithConfirmReturnType, SagaActionFromCreator } from '../../../type';
import { privateRoutes } from '../../../routes';

export const updateAccountPreferences = function* ({
  payload,
}: SagaActionFromCreator<
  typeof actions.BOX_ACCOUNT_PREFERENCES_SETTINGS_UPDATE.request
>): SagaIterator {
  try {
    const response: ApiWithConfirmReturnType<typeof Api.Account.update> =
      yield apiWithConfirmCall(Api.Account.update, {
        preferences: payload,
      });
    if (response) {
      yield put(
        actions.BOX_ACCOUNT_PREFERENCES_SETTINGS_UPDATE.success(response)
      );
      window.location.href = privateRoutes.settings.routes.menu.path;
    }
  } catch (errors) {
    yield put(
      actions.BOX_ACCOUNT_PREFERENCES_SETTINGS_UPDATE.failure(
        formatError(errors)
      )
    );
  }
};

export const getAccountTimezones = function* (): SagaIterator {
  try {
    const response: any = yield apiCall(Api.Account.timezones);
    yield put(actions.BOX_ACCOUNT_TIMEZONES.success(response));
  } catch (errors) {
    yield put(actions.BOX_ACCOUNT_TIMEZONES.failure(formatError(errors)));
  }
};

export default function* () {
  yield takeLatest(
    actions.BOX_ACCOUNT_PREFERENCES_SETTINGS_UPDATE.request,
    updateAccountPreferences
  );
  yield takeLatest(actions.BOX_ACCOUNT_TIMEZONES.request, getAccountTimezones);
}
