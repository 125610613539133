import { createReducer } from 'lib/store-utils';
import { MyTimesheetReducerState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';
import { cloneDeep } from 'lodash';
import { BOX_MY_TIMESHEET_UPDATE_FILTER_FORM_FIELD_PERIOD } from './actions';

export const myTimesheet = createReducer<MyTimesheetReducerState>(
  {},
  getDefaultState()
);

myTimesheet.on(
  actions.BOX_MY_TIMESHEET_FETCH_PAGE_DATA_REQUEST,
  getDefaultState
);

myTimesheet.on(
  actions.BOX_MY_TIMESHEET_FETCH_MY_TIMESHEET_SUCCESS,
  (state, dataWithPager): MyTimesheetReducerState => ({
    ...state,
    ...dataWithPager
  })
);

myTimesheet.on(
  actions.BOX_MY_TIMESHEET_SET_SELECTED_FILTER,
  (state, selectedFilter): MyTimesheetReducerState => ({
    ...state,
    selectedFilter
  })
);

myTimesheet.on(
  actions.BOX_MY_TIMESHEET_CUSTOM_FILTERS_MODAL_OPEN,
  (state): MyTimesheetReducerState => ({
    ...state,
    filtersModalIsOpened: true
  })
);

myTimesheet.on(
  actions.BOX_MY_TIMESHEET_CUSTOM_FILTERS_MODAL_CLOSE,
  (state): MyTimesheetReducerState => ({
    ...state,
    filtersModalIsOpened: false
  })
);

myTimesheet.on(
  actions.BOX_MY_TIMESHEET_UPDATE_CUSTOM_FILTERS,
  (state): MyTimesheetReducerState => ({
    ...state,
    filters: cloneDeep(state.filtersForm)
  })
);

myTimesheet.on(
  actions.BOX_MY_TIMESHEET_UPDATE_FILTER_FORM,
  (state, filtersForm): MyTimesheetReducerState => ({
    ...state,
    filtersForm
  })
);

myTimesheet.on(
  actions.BOX_MY_TIMESHEET_UPDATE_FILTER_FORM_FIELD,
  (state, { name, value }): MyTimesheetReducerState => ({
    ...state,
    filtersForm: {
      ...state.filtersForm,
      [name]: value
    }
  })
);

myTimesheet.on(
  actions.BOX_MY_TIMESHEET_UPDATE_FILTER_FORM_FIELD_PERIOD,
  (state, { name, value }): MyTimesheetReducerState => ({
    ...state,
    filtersForm: {
      ...state.filtersForm,
      period: {
        ...state.filtersForm.period,
        [name]: value
      }
    }
  })
);

myTimesheet.on(
  actions.BOX_MY_TIMESHEET_CLEAR_FILTER_FORM,
  (state): MyTimesheetReducerState => ({
    ...state,
    filtersForm: cloneDeep(state.filters)
  })
);

myTimesheet.on(
  actions.BOX_MY_TIMESHEET_SET_ERRORS,
  (state, errors): MyTimesheetReducerState => ({
    ...state,
    errors
  })
);

myTimesheet.on(
  actions.BOX_MY_TIMESHEET_SET_HASH,
  (state, hash): MyTimesheetReducerState => ({
    ...state,
    currentlyCreated: hash
  })
);

myTimesheet.on(
  actions.BOX_MY_TIMESHEET_UNSET_HASH,
  (state, hash): MyTimesheetReducerState => ({
    ...state,
    currentlyCreated: null
  })
);
