import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ShiftTemplateItem } from 'type';
import { getIsBulkDeleteOpened } from 'state/Roster/BulkActions';
import { BOX_EDIT_TEMPLATE_OPEN_EDIT_MODAL } from 'state/Roster/EditTemplate/actions';
import { useSelectorWithProps } from '../../../../../../hooks';
import { getUserIsArchived } from '../../../../../../state/UsersCollection';

export const useHandleClickAddButton = ({
  day,
  user_id,
  area_id,
  role_id,
}: ShiftTemplateItem): React.MouseEventHandler<HTMLElement> | undefined => {
  const isBulkDelete = useSelector(getIsBulkDeleteOpened);
  const isUserArchived = useSelectorWithProps(getUserIsArchived, user_id);
  const canAddShift = !isBulkDelete && !isUserArchived;
  const dispatch = useDispatch();

  const handleClickAddButton = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      dispatch(
        BOX_EDIT_TEMPLATE_OPEN_EDIT_MODAL({
          id: '',
          day,
          user_id,
          area_id,
          role_id,
          end: '00:00',
          start: '00:00',
          breaks: [],
          tags: [],
          notes: null,
        })
      );
    },
    [dispatch, day, user_id, area_id, role_id, isUserArchived]
  );

  if (canAddShift) {
    return handleClickAddButton;
  }
};
