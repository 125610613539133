import { createSelector } from 'reselect';
import { Event } from 'type';
import { checkNullFilter } from 'state/helpers';
import { eventsArraySelector } from 'state/Events';
import { getFiltersEvents } from './simpleSelectors';

export const filteredEventsSelector = createSelector(
  eventsArraySelector,
  getFiltersEvents,
  checkNullFilter(
    (events: Event[], filtersEvents: string[]): Event[] =>
      events.filter((event: Event): boolean => filtersEvents.includes(event.id))
  )
);
