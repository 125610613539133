import * as React from 'react';
import {
  FormItem,
  withLayoutAware
} from 'elmo-elements';
import CKEditor from 'ckeditor4-react';
import { connect } from 'react-redux';
import {
  BOX_NOTIFICATION_UPLOAD_REQUEST
} from 'state/Settings/Notification';
import { StoreState } from 'state/types';

type Props = {
  title: string;
  type: string;
  content: string;
  variables: string[];
  onChange: Function;
  uploadImage: Function;
  newFile: string;
  className?: string;
};

type State = {
  variables: string[],
  isLoading: boolean,
};

class Ckeditor extends React.Component <Props, State> {

  editor: any = {
    id: ''
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      variables: props.variables,
      isLoading: false
    };
  }

  getEmailEditorConfig = () => {
    return {
      toolbar: [
        {name: 'custom-bar', items: ['placeholder']},
        {name: 'basicstyles', items: ['Bold', 'Italic']},
        {name: 'links', items: ['Link', 'Unlink']},
        {name: 'paragraph', items: ['NumberedList', 'BulletedList']},
        {name: 'insert', items: ['UploadImage']},
        {name: 'paragraph', items: ['Blockquote']},
        {name: 'insert', items: ['Table']}
      ],
      enterMode: 2,
      tabSpaces: 0,
      basicEntities: false,
      entities: false,
      height: 180,
      image2_disableResizer: true,
      allowedContent: true
    };
  }

  getSMSEditorConfig = () => {
    return {
      toolbarGroups: [
        {name: 'custom-bar'},
      ],
      enterMode: 2,
      tabSpaces: 0,
      basicEntities: false,
      entities: false,
      height: 180
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { props } = this;
    if ( prevProps !== this.props ) {
      this.setState({
        variables: props.variables
      });

      if (this.props.type === 'email' && prevProps.newFile !== props.newFile) {
        this.editor.fire('saveSnapshot');
        this.editor.insertHtml(`<img style="display: inline" src="${props.newFile}"/>`);
        this.editor.fire('saveSnapshot');
        this.editor.focus();
        this.setState({
          isLoading: false
        });
      }
    }
  }

  addDropDown(e: any, variables: string[]) {
    let editor = e.editor,
      config = editor.config;
    editor.ui.addRichCombo('placeholder', {
      label: 'Placeholder',
      title: 'Placeholder',
      toolbar: 'custom-bar',
      panel: {
        css: ['/4.4.0/standard/skins/moono/editor.css?t=E3OD'].concat(config.contentsCss),
        multiSelect: false,
        attributes: {'aria-label': 'Placeholder'}
      },
      init: function () {
        variables.forEach((option: string) => {
          let _option = `{{${option}}}`;
          this.add(_option, _option);
        });
      },

      onClick: function (value: string) {
        editor.fire('saveSnapshot');
        editor.insertHtml(value);
        editor.fire('saveSnapshot');
        editor.focus();
      }
    });
  }

  addImageUploader = (e: any) => {
    let editor = e.editor;

    editor.ui.addButton('UploadImage', {
      label: 'Insert Image',
      command: 'openDialog',
      toolbar: 'insert',
      icon: 'Image'
    });

    editor.addCommand('openDialog', {
      exec: () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/x-png,image/gif,image/jpeg';
        input.addEventListener('change', this.onInputImageChange);
        input.click();
      }
    });

  }

  onInputImageChange = (e: any) => {
    const [ file ] = e.target.files || e.dataTransfer.files;
    const formData = new FormData();
    formData.append('data', file);
    this.props.uploadImage(formData);

    this.setState({
      isLoading: true
    });
  }

  render() {
    const {type, title, content, onChange} = this.props;

    return (
      <FormItem label={title}>
        <div className={this.props.className}>
          <CKEditor
            config={
              type === 'email' ? this.getEmailEditorConfig() : this.getSMSEditorConfig()
            }
            data={content}
            onPluginsLoaded={(e: any) => {
              this.editor = e.editor;
              this.addDropDown(e, this.state.variables);
              this.addImageUploader(e);
            }}
            onChange={(e: any) => {
              onChange(e.editor.getData(''));
            }}
          />
        </div>
      </FormItem>
    );
  }
}

const mapStateToProps = (state: StoreState) => ({
  newFile: state.notification.editModal.uploadedFile,
});

export default connect(
  mapStateToProps,
  {
    uploadImage: BOX_NOTIFICATION_UPLOAD_REQUEST
  })(withLayoutAware(Ckeditor));
