import React, { Component } from 'react';
import { Dropdown, Button } from 'elmo-elements';

type Props = {
  onCloseAction: () => void;
  asButton?: boolean;
  icon?: React.ReactNode;
  config: any[];
  onOpen: () => void;
};

type State = {
  isOpened: boolean;
};

export class MenuDropDown extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      isOpened: false
    }
  };

  render() {
    const { onCloseAction, asButton, icon } = this.props;
    const { isOpened } = this.state;
    return (
      <>
        {
          !isOpened && asButton
            ? <Button
                icon={icon}
                onClick={e => this.setMenuVisibility(true)}
                isText={true}
              />
            : <Dropdown
                onClose={onCloseAction}
                position={'bottom-end'}
                ariaLabel="Contextual menu"
                isOpen={this.getIsOpened()}
                isInline={asButton}
                icon={icon}
                onToggle={() => this.setMenuVisibility(false, true)}
              >
                {this.dropDownItems()}
              </Dropdown>
        }
      </>
    )
  };

  getIsOpened = () => {
    const { asButton } = this.props;
    if ( !asButton ) {
      return true;
    }
    return this.state.isOpened;
  }

  setMenuVisibility = (value: boolean, toggle?: boolean) => {
    const { asButton } = this.props;
    if ( value && asButton ) {
      this.props.onOpen();
    }
    if ( asButton ) {
      this.setState({
        isOpened: toggle ? false : value
      });
    }
  };

  dropDownItems = () => {
    return this.props.config.map((action: any, index: number) => (
      <React.Fragment key={'dropdown-item-' + index}>
        <Dropdown.Item
          ariaLabel={action.ariaLabel}
          onClick={() => {
            action.onClick();
            this.setMenuVisibility(false);
          }}
          value={index}
          isDisabled={action.isDisabled}
        >
          {action.icon} {action.label}
        </Dropdown.Item>
        {action.withDivider && <Dropdown.Divider type="short" />}
      </React.Fragment>
    ));
  };

}