import React from 'react';
import { connect } from 'react-redux';
import { LoadingOverlay } from 'elmo-elements';
import { ShiftOfferProposal as ShiftOfferProposalModel } from 'type';
import { StoreState } from 'state/types';
import { getIsLoading } from 'state/IsLoading';
import {
  getIsOutdatedValue,
  shiftOfferProposalsArraySelector,
} from 'state/ManagerDashboard/ShiftOffers/ShiftOffer';
import { ShiftOfferEmptyMessage, ShiftOfferProposal } from './components';

type OwnProps = {};

type StateProps = {
  proposals: ShiftOfferProposalModel[];
  isUpdating: boolean;
  isOutDated: boolean;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

const ShiftOfferProposalsComponent = ({
  proposals,
  isUpdating,
  isOutDated,
}: Props) => (
  <LoadingOverlay isLoading={isOutDated} showSpinner={false}>
    <LoadingOverlay isLoading={isUpdating}>
      {proposals.length ? (
        proposals.map((proposal) => (
          <ShiftOfferProposal key={proposal.id} proposal={proposal} />
        ))
      ) : (
        <ShiftOfferEmptyMessage />
      )}
    </LoadingOverlay>
  </LoadingOverlay>
);

const mapStateToProps = (state: StoreState): StateProps => ({
  proposals: shiftOfferProposalsArraySelector(state),
  isUpdating: getIsLoading(state, 'MANAGER_DASHBOARD_SHIFT_OFFER'),
  isOutDated: getIsOutdatedValue(state),
});

export const ShiftOfferProposals = connect(mapStateToProps)(
  ShiftOfferProposalsComponent
);
