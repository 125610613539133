import { useDeleteDialogContext } from 'contexts';
import { AlertErrorBoxDialog } from 'element';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'extended-oxygen-elements';
import { useUpdateStatusEffect } from 'hooks';
import { ShiftOvertimeRuleRequestEntityDay } from 'lib/Api';
import {
  deleteConfirmLabel,
  deleteOneDescription,
  deleteTitle,
} from 'messages';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BOX_ACCOUNT_PAY_CONDITIONS_RULE_DELETE } from 'state/Account';
import {
  BOX_PAY_CONDITIONS_PAGE_DIALOG_ERRORS_CLOSED,
  getConditionsModalRequestStatus,
  getConditionsModalUpdating,
  getModalErrors,
} from 'state/PayConditionsPage';

type Props = {
  day: ShiftOvertimeRuleRequestEntityDay;
};

export default function DeleteConfirmationDialog({ day }: Props) {
  const { closeDialog, id: idToBeDeleted } = useDeleteDialogContext();
  const dispatch = useDispatch();
  const loading = useSelector(getConditionsModalUpdating);
  const errors = useSelector(getModalErrors);

  useUpdateStatusEffect(getConditionsModalRequestStatus, {
    success: closeDialog,
    cancel: closeDialog,
  });

  const closeDeleteDialog = () => {
    if (loading) {
      return;
    }

    closeDialog();
  };

  const handleErrorsClose = () => {
    dispatch(BOX_PAY_CONDITIONS_PAGE_DIALOG_ERRORS_CLOSED());
  };

  const handleConfirmButtonCLick = () => {
    if (loading) {
      return;
    }

    dispatch(
      BOX_ACCOUNT_PAY_CONDITIONS_RULE_DELETE.request({ id: idToBeDeleted, day })
    );
  };

  return (
    <Dialog open={!!idToBeDeleted} onClose={closeDeleteDialog}>
      <DialogTitle>{deleteTitle('Pay condition')}</DialogTitle>

      <DialogContent>
        <AlertErrorBoxDialog
          BoxProps={{
            mb: 2,
          }}
          errors={errors}
          onClose={handleErrorsClose}
        />

        <DialogContentText>
          {deleteOneDescription('Pay condition')}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button disabled={loading} onClick={closeDeleteDialog}>
          Cancel
        </Button>

        <Button
          variant="contained"
          loading={loading}
          disableRipple={loading}
          onClick={handleConfirmButtonCLick}
        >
          {deleteConfirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
