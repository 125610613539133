import React, { Component } from 'react';
import { Button, Paragraph, Search, Text } from 'elmo-elements';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import {
  Event,
  PreferencesDateFormat,
  PreferencesTimeFormat,
  RosteredShift,
  ServerUserFields,
} from 'type/models';
import { getSiteId } from 'state/RosteredShifts';
import { HighlightOffOutlinedIcon } from 'element/icons';
import { StringMap } from 'type';
import { getUserListResponse } from 'state/UsersCollection';
import {
  getDateTimeFormatted,
  getTimeFormatted,
  getUserName,
} from 'lib/helpers';
import classNames from 'clsx';
import './style.scss';
import _ from 'lodash';
import { getDateFormat, getTimeFormat } from 'state/Account';
import { Avatar, DialogActions, DialogTitle } from 'oxygen-elements';
import {
  PageDialog,
  PageDialogBackIconButton,
  PageDialogCancel,
  PageDialogCloseIconButton,
} from 'element';
import { DialogContent } from 'extended-oxygen-elements';

type OwnProp = {
  isOpened: boolean;
  closeModal: () => void;
  shifts: StringMap<RosteredShift>;
  siteId: string;
  event: Event;
  users: StringMap<ServerUserFields>;
  assignShift: (id: string) => void;
  showSearch: boolean;
  dateFormat: PreferencesDateFormat;
  timeFormat: PreferencesTimeFormat;
};

type Props = OwnProp;

type State = {
  searchTerm: string;
};

export class UsersModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchTerm: '',
    };
  }

  closeModal = () => {
    this.setState(
      (prevState) => ({
        ...prevState,
        searchTerm: '',
      }),
      () => {
        this.props.closeModal();
      }
    );
  };

  render() {
    return (
      <PageDialog
        id={'event-users-modal'}
        maxWidth={'sm'}
        open={this.props.isOpened}
        className={'event-modal'}
      >
        <DialogActions
          className={'shift-modal-header'}
          sx={{ boxShadow: '0 0 6px 2px #ccc', zIndex: 3, padding: 0 }}
        >
          <DialogTitle>
            <PageDialogBackIconButton onClose={this.closeModal} />
            Users
          </DialogTitle>
        </DialogActions>
        <DialogContent className={'shift-modal-content'}>
          {this.showSearch()}
          {this.renderUsersList()}
        </DialogContent>
        <DialogActions sx={{ boxShadow: '0 0 6px 2px #ccc', zIndex: 3 }}>
          <PageDialogCancel
            onClick={this.closeModal}
            fullWidth={false}
            size={'large'}
          >
            Cancel
          </PageDialogCancel>
        </DialogActions>
      </PageDialog>
    );
  }

  showSearch = () => {
    return (
      <div className={'users-modal-search'}>
        <Search
          isVisible={this.props.showSearch}
          onChange={this.searchUser}
          value={this.state.searchTerm}
          onSubmit={this.onSearchSubmit}
        />
      </div>
    );
  };

  onSearchSubmit = () => false;

  searchUser = (searchTerm: string) => {
    this.setState({
      ...this.state,
      searchTerm,
    });
  };

  renderUsersList = () => {
    const { shifts } = this.props;

    const userShifts = Object.values(shifts).filter((shift: RosteredShift) => {
      const { users } = this.props;
      const user = users[shift.user_id as string];
      const name = user ? getUserName(user).toLowerCase() : 'unassigned';
      return name.indexOf(_.trim(this.state.searchTerm.toLowerCase())) !== -1;
    });

    return (
      <>
        <div className="list-users">
          <div className="list-users__group--items">
            {!userShifts.length && (
              <div data-testid="users-submodal-no-users-message">
                No shifts available
              </div>
            )}
            {userShifts.map((shift) => {
              return this.getUser(shift);
            })}
          </div>
        </div>
      </>
    );
  };

  getUser = (shift: RosteredShift) => {
    const { users, dateFormat, timeFormat } = this.props;
    const user = users[shift.user_id as string];
    const selectedShifts = this.props.event.rostered_shift_ids;
    const isAssigned = selectedShifts.indexOf(shift.id) !== -1;

    return (
      <div
        data-testid={shift.id}
        onClick={() => {
          if (!isAssigned) {
            this.assignShift(shift.id);
          }
        }}
        className={classNames('list-users__group--item', {
          selected: isAssigned,
        })}
        key={shift.id}
      >
        <span className={'user-status ' + this.getStatus(shift)} />
        {user ? (
          <Avatar
            light
            size={'small'}
            alt={getUserName(user)}
            src={user.avatar_url as string}
          />
        ) : (
          <Avatar
            light
            size={'small'}
            alt={user ? getUserName(user) : 'Unassigned'}
          />
        )}

        <div className="list-users__group--user-info">
          <div data-testid="users-submodal-username">
            <Text>{user ? getUserName(user) : 'Unassigned'}</Text>
          </div>
          <div data-testid="users-submodal-shift-time">
            <Paragraph size={'sm'}>
              {getDateTimeFormatted(dateFormat, timeFormat, shift.start)},{' '}
              {getTimeFormatted(timeFormat, shift.start)} -{' '}
              {getTimeFormatted(timeFormat, shift.end)}
            </Paragraph>
          </div>
        </div>
        {selectedShifts.indexOf(shift.id) !== -1 && (
          <div data-testid="users-submodal-btn-remove-user">
            <Button
              isText={true}
              icon={<HighlightOffOutlinedIcon />}
              ariaLabel="Remove"
              onClick={(e) => {
                this.assignShift(shift.id);
              }}
            />
          </div>
        )}
      </div>
    );
  };

  assignShift = (id: string) => {
    this.props.assignShift(id);
  };

  getStatus = (shift: RosteredShift) => {
    return shift.event_id === null || shift.event_id === this.props.event.id
      ? 'green'
      : 'red';
  };
}

const mapStateToProps = (state: StoreState) => ({
  siteId: getSiteId(state),
  users: getUserListResponse(state),
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
});

export default connect(mapStateToProps, {})(UsersModal);
