import React from 'react';
import { FormItem, Checkbox } from 'elmo-elements';
import { Data } from '../type';

export type DataProps = {
  handleOnChangeCheckbox: (e: any) => void;
  data: Data;
};

const OnlyShowDetailsCheckbox = ({
  handleOnChangeCheckbox,
  data,
}: DataProps) => {
  return (
    <FormItem isInline={true}>
      <Checkbox
        name={'details'}
        id={'show-details'}
        label={'Show details'}
        isChecked={data.details}
        onChange={handleOnChangeCheckbox}
      />
    </FormItem>
  );
};

export default OnlyShowDetailsCheckbox;
