import React from 'react';
import { Card, Col, Paragraph, Row, Text } from 'elmo-elements';
import CustomFieldsModal from './components/CustomFieldsModal';
import { StoreState } from 'state/types';
import {
  CustomField,
  EmployeeSettings,
  PreferencesDateFormat,
  PreferencesTimeFormat,
  UserProfileFields,
} from 'type/models';
import { connect } from 'react-redux';
import { getDateTimeFormatted } from 'lib/helpers';
import _ from 'lodash';
import {
  BOX_USER_PROFILE_CUSTOM_MODAL_CLOSE,
  BOX_USER_PROFILE_CUSTOM_MODAL_OPEN,
} from 'state/Users/UserProfile/actions';
import { EditButton } from '../EditButton';
import { getDateFormat, getTimeFormat } from 'state/Account';
import { WarningIcon } from './components/WarningIcon';

type State = {
  isOpened: boolean;
  isValid: boolean;
};

type StateProps = {
  userProfile: UserProfileFields;
  employee_settings: EmployeeSettings;
  isOpened: boolean;
  custom_fields: any;
  dateFormat: PreferencesDateFormat;
  timeFormat: PreferencesTimeFormat;
};
type DispatchProps = {
  openModal: () => void;
  closeModal: () => void;
};

type Props = StateProps & DispatchProps;

export class CustomFieldsComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpened: false,
      isValid: this.validateFields()
    };
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (this.props !== prevProps) {
      this.setState({
        isValid: this.validateFields()
      });
    }
  }

  render() {
    const { custom_fields } = this.props.employee_settings;
    return (
      <Card
        isFullHeight={true}
        heading={
          <>
            Custom fields
            {!this.state.isValid && <WarningIcon />}
          </>
        }
        option={this.props.userProfile.is_active && <EditButton onClick={this.props.openModal} ariaLabel="Edit" />}
      >
        {this.renderCustomFields()}
        <CustomFieldsModal
          closeModal={this.props.closeModal}
          isOpened={this.props.isOpened}
          customFields={custom_fields}
          userCustomFields={this.props.custom_fields}
        />
      </Card>
    );
  }

  renderCustomFields = () => {
    const { custom_fields } = this.props.employee_settings;
    return (
      <Row role="presentation">
        {_.map(custom_fields, (field: CustomField, key: number) => (
          <React.Fragment key={key}>
            <Col xs={12} span={24} role="presentation">
              <Text isTruncate={true} size={'sm'}>
                {field.label}
              </Text>
              <Paragraph isTruncate={true} className={'field-value'}>
                {this.getFieldValue(field)}
              </Paragraph>
            </Col>
            {key > 0 && (key + 1) % 2 === 0 ? (
              <Col span={24} role="presentation">
                <br />
              </Col>
            ) : null}
          </React.Fragment>
        ))}
      </Row>
    );
  };

  getFieldValue = (field: any) => {
    const { custom_fields } = this.props.userProfile;
    const { dateFormat, timeFormat } = this.props;
    const keys = Object.keys(custom_fields);
    let value = '-';
    for (let key of keys) {
      if (key === field.name) {
        const v: any = custom_fields[key as any];
        if (field.type === 'date' && v) {
          return getDateTimeFormatted(dateFormat, timeFormat, v, false, true);
        }
        if (v.constructor === Array) {
          return v.join(', ');
        }
        if (field.type === 'file' && v !== null) {
          return (
            <a href={v.filepath} download={v.original_filename} target="_blank" rel="noreferrer">
              {v.original_filename}
            </a>
          );
        }
        return v || '-';
      }
    }
    return value;
  };

  validateFields = () => {
    const { custom_fields } = this.props.employee_settings;
    const customFieldsValues: any = this.props.userProfile.custom_fields;
    let isValid = true;
    _.map(custom_fields, (field: any) => {
      if (field.mandatory) {
        const f = customFieldsValues[field.name];
        if (!f || (f && f.length === 0)) {
          isValid = false;
        }
      }
    });
    return isValid;
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  userProfile: state.userProfile.userProfile,
  isOpened: state.userProfile.customFieldsModal.isOpened,
  custom_fields: state.userProfile.customFieldsModal.custom_fields,
  employee_settings: state.account.account.employee_settings,
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state)
});

export const CustomFields = connect(
  mapStateToProps,
  {
    openModal: BOX_USER_PROFILE_CUSTOM_MODAL_OPEN,
    closeModal: BOX_USER_PROFILE_CUSTOM_MODAL_CLOSE
  }
)(CustomFieldsComponent);
