import React from 'react';
import { Menu, MenuItem } from 'extended-oxygen-elements';
import { createStyles, makeStyles } from '@mui/styles';

type Option = {
  label: string;
  value: string;
};

type Props = {
  anchorEl: null | Element;
  handleCloseRolesMenu: () => void;
  handleRoleSelection: (event: React.MouseEvent<HTMLLIElement>, value: Option) => void;
  roles: {
    label: string,
    value: string
  }[]
};

const usePopoverStyles = makeStyles(() =>
  createStyles({
    paper: {
      marginTop: 0,
    }
  })
);

export const UserRolesMenu = ({
  anchorEl, handleCloseRolesMenu, roles, handleRoleSelection
}: Props) => {
  const popoverClasses = usePopoverStyles();
  return (
    <Menu
      open={!!anchorEl}
      anchorEl={anchorEl}
      keepMounted={false}
      onClose={handleCloseRolesMenu}
      classes={popoverClasses}
    >
      {
        roles.map((option, index) => (
          <MenuItem
            key={`${option.value}-${index}`}
            onClick={event => handleRoleSelection(event, option)}
            data-testid={`role-item-${index}`}
          >
            {option.label}
          </MenuItem>
        ))
      }
    </Menu>
  );
};
