import { SagaAction } from '../../../../type';
import { SiteViewAddRowPayload } from '../types';
import { put, select } from 'redux-saga/effects';
import { getSiteViewAdditionalRowsByPayload } from '../selectors/simpleSelectors';
import { BOX_ROSTER_DAY_VIEW_SITE_VIEW_ROW_SET } from '../actions';

export const siteViewAddRow = function*({
  payload
}: SagaAction<SiteViewAddRowPayload>) {
  const currentQuantity = yield select(
    getSiteViewAdditionalRowsByPayload,
    payload
  );

  yield put(
    BOX_ROSTER_DAY_VIEW_SITE_VIEW_ROW_SET({
      ...payload,
      rowsQuantity: currentQuantity + 1
    })
  );
};
