import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { DeletePayload, SagaAction, StringMap, UserGroup } from 'type';
import { Api } from 'lib/Api';
import { formatError } from 'state/helpers';
import { processApiRequest } from 'state/ProcessApiRequest';
import { getCurrentUserRequest } from 'state/Auth/sagas';
import { UpdateUserGroupPermissionsPayload } from './types';
import * as actions from './actions';

export const getUserGroupsRequest = function* (): SagaIterator {
  const groups: StringMap<UserGroup> = yield call(
    processApiRequest,
    Api.UserGroups.all
  );
  yield put(actions.BOX_USER_GROUPS_SUCCESS(groups));
};

export const getUserGroups = function* (): SagaIterator {
  try {
    yield call(getUserGroupsRequest);
  } catch (error) {
    yield put(actions.BOX_USER_GROUPS_FAILURE(formatError(error)));
  }
};

export const addUserGroup = function* (
  action: SagaAction<UserGroup>
): SagaIterator {
  try {
    const userGroup = yield call(processApiRequest, Api.UserGroups.add, {
      template: action.payload.template,
      title: action.payload.title,
    });
    yield put(actions.BOX_ADD_USER_GROUP_SUCCESS(userGroup));
  } catch (error) {
    yield put(actions.BOX_ADD_USER_GROUP_FAILURE(formatError(error)));
  }
};

export const updateUserGroup = function* (action: any): SagaIterator {
  try {
    let data = {
      title: action.payload.title,
      template: action.payload.template,
    };
    if (!action.payload.template_updated) {
      delete data.template;
    }

    const group = yield call(
      processApiRequest,
      Api.UserGroups.update,
      data,
      action.payload.id
    );
    yield put(actions.BOX_UPDATE_USER_GROUP_SUCCESS());

    if (action.payload.template_updated) {
      yield put(actions.BOX_UPDATE_USER_GROUP_DATA(group));
    }
  } catch (error) {
    yield put(actions.BOX_UPDATE_USER_GROUP_FAILURE(formatError(error)));
  }
};

export const updateUserGroupPermissions = function* (
  { payload: userGroups }: SagaAction<UpdateUserGroupPermissionsPayload>
): SagaIterator {
  try {
    yield all(
      userGroups.map((group: UserGroup) =>
        call(
          processApiRequest,
          Api.UserGroups.updatePermissions,
          {
            permissions: group.permission_ids,
          },
          group.id
        )
      )
    );

    yield call(getCurrentUserRequest); // update current user permissions;
    yield put(actions.BOX_UPDATE_USER_GROUP_PERMISSIONS_SUCCESS());
  } catch (error) {
    yield put(actions.BOX_USER_GROUPS_FAILURE(formatError(error)));
  }
};

export const deleteUserGroup = function* (
  action: SagaAction<DeletePayload>
): SagaIterator {
  try {
    yield call(processApiRequest, Api.UserGroups.delete, action.payload);
    yield put(actions.BOX_DELETE_USER_GROUP_SUCCESS());
  } catch (error) {
    yield put(actions.BOX_DELETE_USER_GROUP_FAILURE(formatError(error)));
  }
};

export const watchUserGroups = function* (): SagaIterator {
  yield takeLatest(actions.BOX_USER_GROUPS_REQUEST, getUserGroups);
  yield takeLatest(actions.BOX_ADD_USER_GROUP_REQUEST, addUserGroup);
  yield takeLatest(actions.BOX_UPDATE_USER_GROUP_REQUEST, updateUserGroup);
  yield takeLatest(
    actions.BOX_UPDATE_USER_GROUP_PERMISSIONS_REQUEST,
    updateUserGroupPermissions
  );
  yield takeLatest(actions.BOX_DELETE_USER_GROUP_REQUEST, deleteUserGroup);
};
