import React from 'react';
import LoaderHolder from './LoaderHolder';
import { LoaderHolderProps } from '../type';
import './AvatarStyle.scss';

function AvatarStyle(props: LoaderHolderProps) {
  return (
    <LoaderHolder
      className="elmo-loader-avatar"
      viewBoxHeight={55}
      viewBoxWidth={300}
      {...props}
    >
      <rect x="0" y="10" rx="50" ry="50" width="40" height="40" />
    </LoaderHolder>
  );
}

export default AvatarStyle;
