import React from 'react';
import { FormContainer, FormItem, Checkbox } from 'elmo-elements';
import { Data } from '../type';

export type DataProps = {
  handleOnChangeCheckbox: (e: any) => void;
  data: Data;
};

const CoverageCheckboxGroup = ({ data, handleOnChangeCheckbox }: DataProps) => {
  return (
    <>
      <FormItem isInline={true}>
        <Checkbox
          name={'details'}
          id={'show-details'}
          label={'Show details'}
          isChecked={data.details}
          onChange={handleOnChangeCheckbox}
        />
        {data.datatype !== 'rostered_shift' && (
          <Checkbox
            name={'show_self_assigned'}
            id={'show-self-assigned'}
            label={`Don't show self assigned shifts`}
            isChecked={data.show_self_assigned}
            onChange={handleOnChangeCheckbox}
          />
        )}
      </FormItem>
    </>
  );
};

export default CoverageCheckboxGroup;
