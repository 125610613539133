import { ContextualMenuReducerState } from './types';

export const getDefaultState = (): ContextualMenuReducerState => ({
  id: null,
  objectId: null,
  isOpened: false,
  clipboard: null,
  action: 'none',
  cellData: {},
  inProgress: false,
  ignoreError: false
});