import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  TextField,
  TextFieldProps,
  Typography,
  FormHelperText,
} from 'extended-oxygen-elements';

import { useSetCheckbox } from 'hooks';
import useSetFormField from 'hooks/form-hooks/useSetFormField';
import { keyMirror } from 'lib/helpers';
import marketLabels from 'marketLabels';
import { HelperTextWrapper } from 'page/Settings/components';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLangPreferences } from 'state/Account';
import {
  FieldGroupProps,
  MapTupleToOption,
  PunchInViolation,
  PunchInViolationTuple,
  PunchOutViolation,
  PunchOutViolationTuple,
  SnapRoster,
  SnapRosterTuple,
  TimesheetSettings,
} from 'type';
import IconButtonClockInViolation from './IconButtonClockInViolation';
import IconButtonClockOutViolation from './IconButtonClockOutViolation';
import IconButtonSnapToRoster from './IconButtonSnapToRoster';
import { InlineInputWrapper } from '../../../components';

type AddingTimesheetsFieldGroupValue = Pick<
  TimesheetSettings,
  | 'manually_add_timesheets'
  | 'snap_roster'
  | 'punch_in_violation'
  | 'punch_out_violation'
>;
type AddingTimesheetsFieldGroupErrors = {};

type Props = FieldGroupProps<
  AddingTimesheetsFieldGroupValue,
  AddingTimesheetsFieldGroupErrors
>;

const snapRosterOptions: MapTupleToOption<SnapRosterTuple> = [
  {
    label: 'Don’t adjust',
    value: '0',
  },
  {
    label: '1 minute',
    value: '1',
  },
  {
    label: '2 minutes',
    value: '2',
  },
  {
    label: '3 minutes',
    value: '3',
  },
  {
    label: '4 minutes',
    value: '4',
  },
  {
    label: '5 minutes',
    value: '5',
  },
  {
    label: '10 minutes',
    value: '10',
  },
  {
    label: '15 minutes',
    value: '15',
  },
  {
    label: '30 minutes',
    value: '30',
  },
];

const punchInViolationOptions: MapTupleToOption<PunchInViolationTuple> = [
  {
    label: 'Disable',
    value: '0',
  },
  {
    label: '1 minute',
    value: '1',
  },
  {
    label: '2 minutes',
    value: '2',
  },
  {
    label: '3 minutes',
    value: '3',
  },
  {
    label: '4 minutes',
    value: '4',
  },
  {
    label: '5 minutes',
    value: '5',
  },
  {
    label: '10 minutes',
    value: '10',
  },
  {
    label: '15 minutes',
    value: '15',
  },
  {
    label: '30 minutes',
    value: '30',
  },
];

const punchOutViolationOptions: MapTupleToOption<PunchOutViolationTuple> = [
  {
    label: 'Disable',
    value: '0',
  },
  {
    label: '1 minute',
    value: '1',
  },
  {
    label: '2 minutes',
    value: '2',
  },
  {
    label: '3 minutes',
    value: '3',
  },
  {
    label: '4 minutes',
    value: '4',
  },
  {
    label: '5 minutes',
    value: '5',
  },
  {
    label: '10 minutes',
    value: '10',
  },
  {
    label: '15 minutes',
    value: '15',
  },
  {
    label: '30 minutes',
    value: '30',
  },
];

export default function AddingTimesheetsFieldGroup({
  values,
  onChange,
}: Props) {
  const langPreferences = useSelector(getLangPreferences);
  const { makeSetCheckbox } = useSetCheckbox(onChange);
  const { setFormField } = useSetFormField(onChange);

  const valuesNames = keyMirror(values);

  const handleSnapRosterChange: TextFieldProps['onChange'] = (event) => {
    setFormField('snap_roster', event.target.value as SnapRoster);
  };

  const handlePunchInViolationChange: TextFieldProps['onChange'] = (event) => {
    setFormField('punch_in_violation', event.target.value as PunchInViolation);
  };

  const handlePunchOutViolationChange: TextFieldProps['onChange'] = (event) => {
    setFormField(
      'punch_out_violation',
      event.target.value as PunchOutViolation
    );
  };

  return (
    <>
      <Typography variant="h4" component="h2">
        Adding timesheets
      </Typography>
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.manually_add_timesheets}
              onChange={makeSetCheckbox('manually_add_timesheets')}
            />
          }
          label="Enable manual timesheets"
        />
        <HelperTextWrapper>
          <FormHelperText fontSize="medium">
            Allow employees to manually add timesheets.
          </FormHelperText>
        </HelperTextWrapper>
      </FormControl>

      <Box maxWidth={650}>
        <Typography variant="h6" component="h3" mt="20px">
          Snap to {marketLabels.roster} <IconButtonSnapToRoster />
        </Typography>
        <Typography variant="body1" component="h6">
          Timesheets adjust to shift start time and end time within:
          <InlineInputWrapper>
            <TextField
              select
              margin="none"
              value={values.snap_roster}
              onChange={handleSnapRosterChange}
              inputProps={{
                'aria-labelledby': valuesNames.snap_roster,
              }}
            >
              {snapRosterOptions.map((snapRosterOption) => (
                <MenuItem
                  value={snapRosterOption.value}
                  key={snapRosterOption.value}
                >
                  {snapRosterOption.label}
                </MenuItem>
              ))}
            </TextField>
          </InlineInputWrapper>
        </Typography>

        <Typography variant="h6" component="h3" mt="20px">
          Clock on violation <IconButtonClockInViolation />
        </Typography>
        <Typography variant="body1" component="h6">
          Flag as violation if clock on is outside of the scheduled start time
          by:
          <InlineInputWrapper>
            <TextField
              select
              margin="none"
              value={values.punch_in_violation}
              onChange={handlePunchInViolationChange}
              inputProps={{
                'aria-labelledby': valuesNames.punch_in_violation,
              }}
            >
              {punchInViolationOptions.map((punchInViolationOption) => (
                <MenuItem
                  value={punchInViolationOption.value}
                  key={punchInViolationOption.value}
                >
                  {punchInViolationOption.label}
                </MenuItem>
              ))}
            </TextField>
          </InlineInputWrapper>
        </Typography>

        <Typography variant="h6" component="h3" mt="20px">
          Clock off violation <IconButtonClockOutViolation />
        </Typography>
        <Typography variant="body1" component="h6">
          Flag as violation if clock off is outside of the scheduled end time
          by:
          <InlineInputWrapper>
            <TextField
              select
              margin="none"
              value={values.punch_out_violation}
              onChange={handlePunchOutViolationChange}
              inputProps={{
                'aria-labelledby': valuesNames.punch_out_violation,
              }}
            >
              {punchOutViolationOptions.map((punchOutViolationOption) => (
                <MenuItem
                  value={punchOutViolationOption.value}
                  key={punchOutViolationOption.value}
                >
                  {punchOutViolationOption.label}
                </MenuItem>
              ))}
            </TextField>
          </InlineInputWrapper>
        </Typography>
      </Box>
    </>
  );
}
