import { PredefinedFilters, UnavailabilityFilters } from './types';

const pending: UnavailabilityFilters = {
  pending: true,
  approved: false,
  declined: false,
  from: null,
  to: null,
  sort: {
    column: 'name',
    direction: 'asc'
  }
};

export const filterTypes: {
  readonly [key in PredefinedFilters]: Readonly<UnavailabilityFilters>
} = {
  pending,
  approved: {
    ...pending,
    pending: false,
    approved: true,
    sort: {
      ...pending.sort
    }
  },
  declined: {
    ...pending,
    pending: false,
    declined: true,
    sort: {
      ...pending.sort
    }
  },
  all: {
    ...pending,
    approved: true,
    declined: true,
    sort: {
      ...pending.sort
    }
  }
};
