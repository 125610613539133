import moment, { Moment } from 'moment';
import { createAction, createReducer } from 'lib/store-utils';
import { eventChannel, SagaIterator } from 'redux-saga';
import { call, put, select, take } from 'redux-saga/effects';
import { StoreState } from '../types';

export type CurrentTimeReducerState = {
  currentTime: Moment;
};

export const BOX_CURRENT_TIME_INCREMENT_MINS = createAction(
  'Current time / increment minutes'
);

export const getCurrentTime = (state: StoreState): Moment =>
  state.currentTime.currentTime;

const defaultState = {
  currentTime: moment()
};

export const currentTime = createReducer<CurrentTimeReducerState>(
  {},
  defaultState
);

currentTime.on(
  BOX_CURRENT_TIME_INCREMENT_MINS,
  (state): CurrentTimeReducerState => ({
    currentTime: state.currentTime
      .clone()
      .startOf('minutes')
      .add(1, 'minute')
  })
);

const clockTimer = () =>
  eventChannel(emitter => {
    const iv = setInterval(() => {
      emitter(moment());
    }, 1000);
    return () => {
      clearInterval(iv);
    };
  });

export const initTimer = function*(): SagaIterator {
  const channel = yield call(clockTimer);

  while (true) {
    const now: Moment = yield take(channel);
    const stateTime = yield select(getCurrentTime);

    if (now.minutes() !== stateTime.minutes()) {
      yield put(BOX_CURRENT_TIME_INCREMENT_MINS());
    }
  }
};
