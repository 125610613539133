import { createReducer } from 'lib/store-utils';
import { ShiftTimeRangeInputLegendReducerState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';

export const shiftTimeRangeInputLegend = createReducer<
  ShiftTimeRangeInputLegendReducerState
>({}, getDefaultState());

shiftTimeRangeInputLegend
  .on(
    actions.BOX_SHIFT_TIME_RANGE_INPUT_LEGEND_OPENED,
    (state): ShiftTimeRangeInputLegendReducerState => ({
      ...state,
      isOpened: true
    })
  )
  .on(actions.BOX_SHIFT_TIME_RANGE_INPUT_LEGEND_CLOSED, getDefaultState);
