import React, { Component } from 'react';
import Button from '../../Button';
import { ViewColumnOutlinedIcon as OptionsIcon } from '../../icons';
import { withDataTableContext } from '../DataTableContextConsumer';
import './DataTableCustomiseButton.scss';

export type DataTableCustomiseButtonProps = {
  /** @ignore Comes from DataTable Context. */
  setContextState?: (state: any) => void;

  /** @ignore Comes from Layout context */
  isBulkActionOpen?: boolean;
};

/**
 * The Customise Columns button opens the Customise Columns Modal
 */
class DataTableCustomiseButton extends Component<DataTableCustomiseButtonProps> {
  onOptionsClick = () => {
    const { setContextState } = this.props;

    if (setContextState) {
      setContextState({
        isCustomiseModalOpen: true,
      });
    }
  };

  render() {
    const { isBulkActionOpen } = this.props;
    if (isBulkActionOpen) {
      return null;
    }

    return (
      <Button
        className="elmo-datatable__options-button"
        onClick={this.onOptionsClick}
        isText={true}
        icon={<OptionsIcon />}
        ariaLabel="Options"
      />
    );
  }
}

export default withDataTableContext(DataTableCustomiseButton);
