import { createReducer } from 'lib/store-utils';
import { ReportReducerState, defaultState } from './state';
import * as actions from './actions';

export const report = createReducer<ReportReducerState>({}, defaultState);

report.on(
  actions.BOX_REPORT_GET_REPORT_REQUEST,
  (state): ReportReducerState => ({
    ...state,
    isFetching: true,
    isUpdated: false,
    isSavedSuccess: false,
    errors: []
  })
);

report.on(
  actions.BOX_REPORT_GET_REPORT_SUCCESS,
  (state, fetchedReport): ReportReducerState => ({
    ...state,
    isFetching: false,
    report: fetchedReport,
    order: {
      ...state.order,
      by: fetchedReport.extra && fetchedReport.extra.order_by ? fetchedReport.extra.order_by : '',
      dir: fetchedReport.extra && fetchedReport.extra.order_dir ? fetchedReport.extra.order_dir : 'asc'
    },
    isSavedSuccess: false,
    isUpdated: false,
    errors: []
  })
);

report.on(
  actions.BOX_REPORT_GET_REPORT_FAILURE,
  (state, errors): ReportReducerState => ({
    ...state,
    isFetching: false,
    isUpdated: false,
    isSavedSuccess: false,
    errors
  })
);

report.on(
  actions.BOX_REPORT_GENERATE_REPORT_REQUEST,
  (state): ReportReducerState => ({
    ...state,
    isFetching: true,
    report: {
      ...state.report
    },
    isUpdated: true,
    isSavedSuccess: false,
    errors: []
  })
);

report.on(
  actions.BOX_REPORT_UPDATE_REPORT_REQUEST,
  (state): ReportReducerState => ({
    ...state,
    isLoading: true,
    isUpdated: true,
    isSavedSuccess: false,
    errors: []
  })
);

report.on(
  actions.BOX_REPORT_UPDATE_REPORT_SUCCESS,
  (state, payload): ReportReducerState => {
    return {
      ...state,
      isLoading: false,
      isUpdated: false,
      isSavedSuccess: true,
      report: {
        ...payload
      },
      errors: []
    };
  }
);

report.on(
  actions.BOX_REPORT_UPDATE_REPORT_FAILURE,
  (state, errors): ReportReducerState => ({
    ...state,
    isLoading: false,
    isUpdated: true,
    isSavedSuccess: false,
    errors: errors
  })
);

report.on(
  actions.BOX_REPORT_GENERATE_REPORT_SUCCESS,
  (state, params): ReportReducerState => ({
    ...state,
    isFetching: false,
    isUpdated: true,
    generatedReport: params.generated,
    order: {
      ...state.order,
      by: params.report.extra && params.report.extra.order_by ? params.report.extra.order_by : '',
      dir: params.report.extra && params.report.extra.order_dir ? params.report.extra.order_dir : 'asc'
    },
    pagination: {
      ...state.pagination,
      totalResults: params.generated.rows.length,
      currentPage: 1,
      startItem: 1,
      pageSize: state.pagination.pageSize,
    },
    isSavedSuccess: false,
    report: {
      ...state.report,
      ...params.report
    },
    fields: params.report.fields
  })
);

report.on(
  actions.BOX_REPORT_SAVE_AS_REPORT_SUCCESS_REDIRECT,
  (state): ReportReducerState => ({
    ...state,
    isSavedAsSuccess: true
  })
);

report.on(
  actions.BOX_REPORT_SAVE_AS_REPORT_RESTORE,
  (state): ReportReducerState => ({
    ...state,
    isSavedAsSuccess: false
  })
);

report.on(
  actions.BOX_REPORT_GENERATE_EXIST_REPORT_REQUEST,
  (state): ReportReducerState => ({
    ...state,
    generatedReport: {
      ...state.generatedReport,
      isFetched: false
    },
    isSavedSuccess: false,
    isFetching: true
  })
);

report.on(
  actions.BOX_REPORT_GENERATE_EXIST_REPORT_SUCCESS,
  (state, generatedReport): ReportReducerState => ({
    ...state,
    isFetching: false,
    generatedReport: {
      ...generatedReport,
      isFetched: true
    },
    pagination: {
      ...state.pagination,
      totalResults: generatedReport.rows.length
    },
    isSavedSuccess: false,
    fields: generatedReport.fields,
    report: {
      ...state.report
    }
  })
);

report.on(
  actions.BOX_REPORT_GENERATE_REPORT_FAILURE,
  (state, errors): ReportReducerState => ({
    ...state,
    isUpdated: false,
    generatedReport: {
      ...state.generatedReport,
      isFetched: true
    },
    isSavedSuccess: false,
    isFetching: false,
    errors
  })
);

report.on(
  actions.BOX_REPORT_FIELDS_SUCCESS,
  (state, fetchedFields): ReportReducerState => ({
    ...state,
    isFetching: false,
    fields: fetchedFields,
    isSavedSuccess: false
  })
);

report.on(
  actions.BOX_REPORT_CLEAR_ERRORS,
  (state): ReportReducerState => ({
    ...state,
    errors: []
  })
);

report.on(
  actions.BOX_REPORT_SET_DEFAULT_STATE,
  (state): ReportReducerState => ({
    ...defaultState
  })
);

report.on(
  actions.BOX_REPORT_SCHEDULE_MODAL_OPEN,
  (state): ReportReducerState => ({
    ...state,
    scheduleModal: {
      ...state.scheduleModal,
      isOpened: true,
      errors: []
    }
  })
);
report.on(
  actions.BOX_REPORT_SCHEDULE_MODAL_CLOSE,
  (state): ReportReducerState => ({
    ...state,
    scheduleModal: {
      ...state.scheduleModal,
      isOpened: false
    }
  })
);
report.on(
  actions.BOX_REPORT_SCHEDULE_UPDATE_REQUEST,
  (state): ReportReducerState => ({
    ...state,
    scheduleModal: {
      ...state.scheduleModal,
      isLoading: true,
      errors: []
    }
  })
);

report.on(
  actions.BOX_REPORT_SCHEDULE_UPDATE_SUCCESS,
  (state, schedule): ReportReducerState => ({
    ...state,
    report: {
      ...state.report,
      schedule
    },
    scheduleModal: {
      ...state.scheduleModal,
      isLoading: false,
      isOpened: false
    }
  })
);

report.on(
  actions.BOX_REPORT_SCHEDULE_UPDATE_FAILURE,
  (state, errors): ReportReducerState => ({
    ...state,
    scheduleModal: {
      ...state.scheduleModal,
      isLoading: false,
      errors
    }
  })
);

report.on(
  actions.BOX_REPORT_SET_CURRENT_PAGE,
  (state, pagination) => ({
    ...state,
    pagination: {
      ...state.pagination,
      currentPage: pagination.page,
      startItem: pagination.startItem,
      endItem: pagination.endItem
    }
  })
);

report.on(
  actions.BOX_REPORT_SET_PAGE_SIZE,
  (state, size) => ({
    ...state,
    pagination: {
      ...state.pagination,
      currentPage: 1,
      startItem: 1,
      endItem: size > state.pagination.totalResults ? state.pagination.totalResults : size,
      pageSize: size
    }
  })
);

report.on(
  actions.BOX_REPORT_SET_ORDER_FIELD,
  (state, field) => ({
    ...state,
    order: {
      ...state.order,
      by: field
    }
  })
);

report.on(
  actions.BOX_REPORT_SET_ORDER_DIRECTION,
  (state, direction) => ({
    ...state,
    order: {
      ...state.order,
      dir: direction
    }
  })
);
