import { DateTimeType, MarkNonNullable, Minutes } from 'type';
import { SERVER_DATE_TIME_FORMAT } from 'lib/config';
import { BreaksFieldGroupBreak } from '../types';
import getBreakStartMoment from './getBreakStartMoment';

type DateRange = { start: DateTimeType; end: DateTimeType };

export function getValidBreaks(
  breaks: BreaksFieldGroupBreak[],
  dateRange: DateRange
) {
  return breaks
    .filter(filterValidBreakPredicate)
    .map(makeValidBreakCallback(dateRange));
}

export function filterValidBreakPredicate<
  B extends { start: Minutes | null; duration: Minutes | null },
  FB extends MarkNonNullable<B, 'start' | 'duration'>
>(formBreak: B | FB): formBreak is FB {
  return formBreak.start !== null && formBreak.duration !== null;
}

function makeValidBreakCallback<B extends { id: string; start: number }>(
  dateRange: DateRange
) {
  return ({ id, start: breakStart, ...restBreak }: B) => ({
    ...restBreak,
    start: getBreakStartMoment(breakStart, dateRange).format(SERVER_DATE_TIME_FORMAT),
    active: null,
  });
}
