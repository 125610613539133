import moment from 'moment';
import { DateTimeType, DateType } from 'type';
import {
  DateRangeFieldGroupValue,
  RequiredDateRangeFieldGroupValue,
} from '../types';
import dateFieldValueHelper from './dateFieldValueHelper';
import dateTimeHelper from './dateTimeHelper';

export const getDateValue = (
  dateFieldValue: DateType,
  timeFieldValue: number
): DateTimeType => {
  const dateValueMoment = dateFieldValueHelper
    .parse(dateFieldValue)
    .add(timeFieldValue, 'minutes');

  return dateTimeHelper.stringify(dateValueMoment);
};

export const getDateValueSafe = (
  dateFieldValue: DateType | null,
  timeFieldValue: number | null
): DateTimeType | null => {
  if (dateFieldValue === null || timeFieldValue === null) {
    return null;
  }

  return getDateValue(dateFieldValue, timeFieldValue);
};

export const getDateRange = ({
  startDate,
  startTime,
  endDate,
  endTime,
}: RequiredDateRangeFieldGroupValue) => ({
  start: getDateValue(startDate, startTime),
  end: getDateValue(endDate, endTime),
});

export const getDateRangeSafe = ({
  startDate,
  startTime,
  endDate,
  endTime,
}: DateRangeFieldGroupValue) => ({
  start: getDateValueSafe(startDate, startTime),
  end: getDateValueSafe(endDate, endTime),
});

export const dateRangeToMoment = (dateRange: {
  start: DateTimeType | null;
  end: DateTimeType | null;
}) => ({
  startMoment: moment(dateRange.start),
  endMoment: moment(dateRange.end),
});

export const getDateRangeMoment = (formFields: DateRangeFieldGroupValue) => {
  return dateRangeToMoment(getDateRangeSafe(formFields));
};

export const isValidDateRangeGuard = (
  dateRange: DateRangeFieldGroupValue
): dateRange is RequiredDateRangeFieldGroupValue => {
  const { startMoment, endMoment } = getDateRangeMoment(dateRange);

  return endMoment.isAfter(startMoment);
};
