import React from 'react';
import { FormItemProps } from '../type';

function FormItemLabel({ icon, label, labelAddon }: Partial<FormItemProps>) {
  if (icon || label || labelAddon) {
    return (
      <div className="elmo-formitem__label">
        <label>
          {icon && icon} {label && label}
        </label>
        {labelAddon && (
          <span className="elmo-formitem__label-optional">{labelAddon}</span>
        )}
      </div>
    );
  }
  return null;
}

export default FormItemLabel;
