import React from 'react';
import { useDispatch } from 'react-redux';
import { BOX_PAY_ELEMENTS_PAGE_DIALOG_OPENED } from 'state/PayElementsPage';

type ContextValue = {
  idToBeDeleted: string;
  isConfirmOpen: boolean;
  openConfirm: (id: string) => void;
  closeConfirm: () => void;
};

const Context = React.createContext<ContextValue>({
  idToBeDeleted: '',
  isConfirmOpen: false,
  openConfirm: () => {},
  closeConfirm: () => {},
});

type Props = {
  children: React.ReactNode;
};

export function DeleteContextProvider({ children }: Props) {
  const dispatch = useDispatch();
  const [idToBeDeleted, setIdToBeDeleted] = React.useState('');

  const openConfirm = (id: string) => {
    dispatch(BOX_PAY_ELEMENTS_PAGE_DIALOG_OPENED());
    setIdToBeDeleted(id);
  };

  const closeConfirm = () => {
    setIdToBeDeleted('');
  };

  return (
    <Context.Provider
      value={{
        idToBeDeleted,
        isConfirmOpen: !!idToBeDeleted,
        openConfirm,
        closeConfirm,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useDeleteContext() {
  return React.useContext(Context);
}
