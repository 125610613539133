import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getNotDeletedShifts } from 'state/Roster/BulkActions/selectors';

type Props = {
  id: string;
};
export const useIsBulkHighlighted = ({ id }: Props) => {
  const notDeletedIds = useSelector(getNotDeletedShifts);

  return useMemo(
    () => notDeletedIds.includes(id),
    [notDeletedIds, id]
  );
};
