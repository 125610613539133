import React, { Component } from 'react';
import { FormItem, Input, Radio, RadioButtons, Select } from 'elmo-elements';
import {
  AccountTreeArea,
  AccountTreeSite,
  SelectPropsOption,
  UserProfileFields,
  UserRole,
} from 'type/models';
import { StringMap } from 'type';
import {
  DateInputMoment,
  ErrorBox,
  PageDialog,
  PageDialogCancel,
  PageDialogSubmit,
} from 'element';
import { map, uniqBy } from 'lodash';
import { getRoleName, scrollToErrors } from 'lib/helpers';
import moment, { Moment } from 'moment';
import { SERVER_DAY_FORMAT } from 'lib/config';
import { fieldsToUpdate } from '../../../../helpers';
import { isAppMarket, isHrService } from 'helpers';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';

type Props = {
  closeModal: () => void;
  isOpened: boolean;
  userProfile: UserProfileFields;
  sites: StringMap<AccountTreeSite>;
  updateProfile: (params: any) => void;
  errors: string[];
  removeErrors: () => void;
  roles: UserRole[];
  areas: StringMap<AccountTreeArea>;
};

type State = {
  isUpdating: boolean;
  payroll_site_id: string | null;
  payroll_area_id: string | null;
  isEdited: boolean;
  profile: Partial<UserProfileFields>;
};

export class PaymentModalWithCostCentre extends Component<Props, State> {
  editable: boolean = isHrService('standalone');
  constructor(props: Props) {
    super(props);
    const {
      payroll_site_id,
      payroll_area_id,
      rate,
      roster_period,
      employment_type,
      shift_worker,
      start_date,
      end_date,
    } = props.userProfile;
    this.state = {
      isUpdating: false,
      payroll_site_id: payroll_site_id,
      payroll_area_id: payroll_area_id,
      isEdited: false,
      profile: {
        rate,
        roster_period,
        employment_type,
        shift_worker,
        start_date,
        end_date,
      },
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const {
      payroll_site_id,
      payroll_area_id,
      rate,
      roster_period,
      employment_type,
      shift_worker,
      start_date,
      end_date,
    } = this.props.userProfile;
    if (prevProps !== this.props) {
      if (!this.props.isOpened) {
        this.setState({
          ...this.state,
          payroll_site_id: payroll_site_id,
          payroll_area_id: payroll_area_id,
          profile: {
            rate,
            roster_period,
            employment_type,
            shift_worker,
            start_date,
            end_date,
          },
          isUpdating: false,
          isEdited: false,
        });
      }
      if (this.props.errors.length > 0) {
        scrollToErrors(this.props.errors);
        this.setState({
          ...this.state,
          isUpdating: false,
        });
      }
    }
  }

  render() {
    return (
      <PageDialog
        maxWidth={'xs'}
        open={this.props.isOpened}
        id={'payment-modal'}
        onClose={this.props.closeModal}
      >
        <DialogTitle>Payment details</DialogTitle>
        <DialogContent>
          {this.props.errors.length > 0 && (
            <ErrorBox
              watchFormErrors={true}
              errors={this.props.errors}
              clearErrors={this.props.removeErrors}
              dontWatchGlobalErrors={true}
            />
          )}
          <FormItem label={'Cost centre'}>
            <Select
              name={'payroll_site_id'}
              options={this.getCostOptions()}
              onChange={this.handleOnChangeSelect}
              value={this.getSelectedValue()}
              defaultValue={this.getSelectedValue()}
              ariaLabel="Cost centre"
            />
          </FormItem>
          {this.editable && this.editableFields()}
        </DialogContent>
        <DialogActions>
          <PageDialogCancel onClick={this.props.closeModal} fullWidth={false}>
            Cancel
          </PageDialogCancel>
          <PageDialogSubmit
            onClick={this.savePaymentDetails}
            loading={this.state.isUpdating}
            disabled={!this.state.isEdited}
            fullWidth={false}
          >
            Save
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  }

  editableFields = () => {
    return (
      <>
        <FormItem label={'Employment type'}>
          <Select
            name={'employment_type'}
            options={this.typeOptions}
            onChange={(e) =>
              this.handleChangeCustomValues('employment_type', e)
            }
            value={this.getSelected('employment_type', this.typeOptions)}
            defaultValue={this.getSelected('employment_type', this.typeOptions)}
            ariaLabel="Employment type"
          />
        </FormItem>
        <FormItem label={isAppMarket('uk') ? 'Rota period' : 'Roster period'}>
          <Select
            name={'roster_period'}
            options={this.periodOptions}
            onChange={(e) => this.handleChangeCustomValues('roster_period', e)}
            value={this.getSelected('roster_period', this.periodOptions)}
            defaultValue={this.getSelected('roster_period', this.periodOptions)}
            ariaLabel="Roster period"
          />
        </FormItem>
        <FormItem label={'Employment start date'}>
          <DateInputMoment
            name={'start_date'}
            value={this.getUserDate('start_date')}
            onChange={(e: Moment | null) => this.changeDate(e, 'start_date')}
            isReadOnly={true}
          />
        </FormItem>
        <FormItem label={'Employment end date'}>
          <DateInputMoment
            name={'end_date'}
            value={this.getUserDate('end_date')}
            onChange={(e: Moment | null) => this.changeDate(e, 'end_date')}
            isReadOnly={true}
          />
        </FormItem>
        <FormItem label={'Base rate'}>
          <Input
            name={'rate'}
            value={this.state.profile.rate}
            htmlType={'number'}
            onChange={(e) =>
              this.handleChangeCustomValues('rate', undefined, +e.target.value)
            }
          />
        </FormItem>
        <FormItem label={'Shift worker'}>
          <RadioButtons
            selected={this.state.profile.shift_worker ? 1 : 0}
            type={'outlined'}
            onChange={(e) =>
              this.handleChangeCustomValues('shift_worker', undefined, !!e)
            }
          >
            <Radio value={1}>Yes</Radio>
            <Radio value={0}>No</Radio>
          </RadioButtons>
        </FormItem>
      </>
    );
  };

  getUserDate = (name: 'start_date' | 'end_date') => {
    const { profile } = this.state;
    const date = (profile as any)[name];
    return date ? moment.parseZone(date) : null;
  };

  changeDate = (e: any, name: string) => {
    this.setState({
      ...this.state,
      isEdited: true,
      profile: {
        ...this.state.profile,
        [name]: e ? e.format(SERVER_DAY_FORMAT) : null,
      },
    });
  };

  handleOnChangeSelect = (e: any) => {
    if (e.type === 'area') {
      this.setState({
        ...this.state,
        payroll_area_id: e.value === 'null' ? null : e.value,
        payroll_site_id: null,
        isEdited: true,
      });
    } else {
      this.setState({
        ...this.state,
        payroll_site_id: e.value === 'null' ? null : e.value,
        payroll_area_id: null,
        isEdited: true,
      });
    }
  };

  handleChangeCustomValues = (
    name: string,
    selected: SelectPropsOption | undefined,
    value: string | number | boolean = -1
  ) => {
    if (name === 'rate' && value < 0) {
      value = 0;
    }
    this.setState({
      ...this.state,
      isEdited: true,
      profile: {
        ...this.state.profile,
        [name]: selected ? selected.value : value,
      },
    });
  };

  getSelectedValue = () => {
    const { sites } = this.props;
    const { payroll_site_id, payroll_area_id } = this.state;

    if (payroll_area_id !== null) {
      const area: AccountTreeArea | undefined =
        this.props.areas[payroll_area_id];
      if (area) {
        return {
          label: `${sites[area.site_id].name} - ${area.name}`,
          value: payroll_area_id,
        };
      }
    }

    if (payroll_site_id !== null) {
      const site: AccountTreeSite | undefined =
        this.props.sites[payroll_site_id];
      if (site) {
        return {
          label: site.name,
          value: payroll_site_id,
        };
      }
    }
    return {
      label: 'Site worked',
      value: 'null',
    };
  };

  getPayrollData = () => {
    const { payroll_area_id, payroll_site_id } = this.state;
    if (!payroll_site_id && !payroll_area_id) {
      return {
        payroll_site_id: null,
        payroll_area_id: null,
      };
    }
    if (payroll_area_id) {
      return {
        payroll_site_id: null,
        payroll_area_id,
      };
    }
    if (payroll_site_id !== null) {
      return {
        payroll_site_id,
        payroll_area_id: null,
      };
    }
    return {};
  };

  updateProfile = () => {
    const { id } = this.props.userProfile;
    this.props.updateProfile({
      id: id,
      ...fieldsToUpdate(this.state.profile, this.props.userProfile),
      ...this.getPayrollData(),
      message: 'Payment details updated',
    });
  };

  savePaymentDetails = () => {
    this.setState({
      ...this.state,
      isUpdating: true,
    });
    this.updateProfile();
  };

  get typeOptions(): SelectPropsOption[] {
    return [
      {
        label: 'Casual',
        value: 'Casual',
      },
      {
        label: 'Contractor',
        value: 'Contractor',
      },
      {
        label: 'Full time',
        value: 'Full time',
      },
      {
        label: 'Part time',
        value: 'Part time',
      },
    ];
  }

  get periodOptions(): SelectPropsOption[] {
    return [
      {
        label: '4 weekly',
        value: '4-weekly',
      },
      {
        label: 'Weekly',
        value: 'Weekly',
      },
      {
        label: 'Fortnightly',
        value: 'Fortnightly',
      },
    ];
  }

  getSelected = (key: string, options: SelectPropsOption[]) => {
    const value = (this.state.profile as any)[key];
    for (let option of options) {
      if (option.value === value) {
        return option;
      }
    }
    return options[0];
  };

  getCostOptions = () => {
    const { sites, roles, areas } = this.props;
    const rolesWithUniqSites = uniqBy(roles, ({ site_id }) => site_id);

    const costRoles = roles.map((role) => ({
      value: areas[role.area_id].id,
      label: `${getRoleName(role, sites, 'site')} - ${getRoleName(
        role,
        sites,
        'area'
      )}`,
      type: 'area',
    }));

    return [
      {
        label: 'Site worked',
        value: 'null',
      },
      ...map(rolesWithUniqSites, (role: UserRole) => ({
        value: role.site_id,
        label: getRoleName(role, sites, 'site'),
        type: 'site',
      })),
      ...uniqBy(costRoles, ({ value }) => value),
    ];
  };
}

export default PaymentModalWithCostCentre;
