import { createReducer } from 'lib/store-utils';
import * as actions from './actions';
import { PaymentAssignedUsersDialogReducerState } from './types';

export const paymentAssignedUsersDialog =
  createReducer<PaymentAssignedUsersDialogReducerState>(
    {},
    {
      user_ids: [],
    }
  );

const updateHandler = (
  state: PaymentAssignedUsersDialogReducerState,
  { user_ids }: { user_ids: string[] }
): PaymentAssignedUsersDialogReducerState => ({
  ...state,
  user_ids,
});

paymentAssignedUsersDialog
  .on(actions.BOX_PAYMENT_ASSIGNED_USERS_DIALOG_OPEN.success, updateHandler)
  .on(
    actions.BOX_PAYMENT_ASSIGNED_USERS_DIALOG_USERS_CHANGE.success,
    updateHandler
  );
