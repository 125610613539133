import { updateEvent, updateTimesheet } from 'lib/Api/helpers';
import { RosterWeekChannel } from 'lib/socket-channels';
import { WS } from 'lib/SocketConnection';
import { eventChannel } from 'redux-saga';
import {
  BOX_EVENTS_DELETE_ONE,
  BOX_EVENTS_UPDATED,
} from 'state/Events/actions';
import { BOX_ROSTER_BULK_REMOVE_SHIFT_ID_BY_SOCKET_EVENT } from 'state/Roster/BulkActions';
import { BOX_ROSTER_PAST_JOB_RECEIVED } from 'state/Roster/RosterCopyPast';
import { BOX_SUMMARY_GET_DATA_SUCCESS } from 'state/Roster/Summary';
import {
  BOX_TIMESHEET_UPDATE_ONE,
  BOX_TIMESHEET_DELETE,
} from 'state/TimesheetsCollection';
import { DateType } from 'type';
import * as listeners from './listeners';
import { getCurrentUserId } from '../../../Auth';
import { select } from 'redux-saga/effects';

type Props = {
  site_id: string;
  day: DateType;
};

export function* makeRosterWeekChannel(ws: WS, props: Props) {
  return yield eventChannel((emit) => {
    const rosterWeekChannel = new RosterWeekChannel(ws, props);

    Object.values(listeners).forEach((listener) => {
      listener(rosterWeekChannel, emit);
    });

    rosterWeekChannel
      .onTimesheetChanged((data) => {
        emit(BOX_TIMESHEET_UPDATE_ONE(updateTimesheet(data) as any));
      })
      .onTimesheetDeleted((data) => {
        emit(BOX_TIMESHEET_DELETE(data));
        emit(BOX_ROSTER_BULK_REMOVE_SHIFT_ID_BY_SOCKET_EVENT(data.id));
      })
      .onSummaryChanged((data) => {
        emit(BOX_SUMMARY_GET_DATA_SUCCESS(data));
      })
      .onForecastEventChanged((data) => {
        emit(BOX_EVENTS_UPDATED(updateEvent(data)));
      })
      .onForecastEventDeleted(({ id }) => {
        emit(BOX_EVENTS_DELETE_ONE(id));
      })
      .onJobStatusChanged((data) => {
        emit(BOX_ROSTER_PAST_JOB_RECEIVED(data));
      });

    return () => {
      rosterWeekChannel.leave();
    };
  });
}
