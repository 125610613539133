import { createSelector } from 'reselect';
import { StoreState } from 'state/types';
import {
  getIsLoading,
  MANAGER_DASHBOARD_TIMESHEETS_AWARDS_MODAL_HIGHER_DUTIES
} from 'state/IsLoading';

const getState = (state: StoreState) =>
  state.TimesheetsAwards;

export const getIsAwardsModalOpened = (state: StoreState) =>
  getState(state).isAwardsModalOpened;
export const getCurrentTimesheet = (state: StoreState) =>
  getState(state).currentTimesheet;
export const getCurrentTimesheetPayEntries = (state: StoreState) =>
  getCurrentTimesheet(state).pay_entries;
const getCurrentTimesheetHigherDuty = (state: StoreState) =>
  getCurrentTimesheet(state).higher_duty;
export const getHigherDuties = (state: StoreState) =>
  getState(state).higherDuties;
export const getErrors = (state: StoreState) => getState(state).errors;
const getIsCurrentTimesheetChanged = (state: StoreState) =>
  getState(state).isCurrentTimesheetChanged;
const getSelectedHigherDutyId = (state: StoreState) =>
  getState(state).selectedHigherDutyId;

export const getIsHigherDutiesLoading = (state: StoreState) =>
  getIsLoading(
    state,
    MANAGER_DASHBOARD_TIMESHEETS_AWARDS_MODAL_HIGHER_DUTIES
  );

export const canHigherDutyBeResetedSelector = createSelector(
  getSelectedHigherDutyId,
  getCurrentTimesheetHigherDuty,
  (selectedHigherDutyId, currentTimesheetHigherDuty) =>
    !!currentTimesheetHigherDuty && !selectedHigherDutyId
);

export const updatePayloadSelector = createSelector(
  getCurrentTimesheet,
  canHigherDutyBeResetedSelector,
  (currentTimesheet, canHigherDutyBeReseted) =>
    canHigherDutyBeReseted
      ? {
          ...currentTimesheet,
          higher_duty: null,
          higher_duty_start: null,
          higher_duty_end: null
        }
      : currentTimesheet
);

export const isSaveBtnDisabledSelector = createSelector(
  canHigherDutyBeResetedSelector,
  getIsCurrentTimesheetChanged,
  getIsHigherDutiesLoading,
  (
    canHigherDutyBeReseted,
    isCurrentTimesheetChanged,
    isHigherDutiesLoading
  ): boolean => {
    if (isHigherDutiesLoading) {
      return true;
    }

    if (canHigherDutyBeReseted) {
      return false;
    }

    if (isCurrentTimesheetChanged) {
      return false;
    }

    return true;
  }
);
