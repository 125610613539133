import React from 'react';

type HoverPopupState<El extends HTMLElement> = {
  isOpened: boolean;
  anchorRef: React.RefObject<El>;
  popupRef: React.RefObject<HTMLDivElement>;
  open: () => void;
  close: () => void;
  handleAnchorMouseLeave: (event: React.MouseEvent) => void;
};

export const useHoverPopupState = <
  El extends HTMLElement
>(): HoverPopupState<El> => {
  const [isOpened, setIsOpened] = React.useState(false);
  const anchorRef = React.useRef<El>(null);
  const popupRef = React.useRef<HTMLDivElement>(null);

  const open = React.useCallback(() => {
    setIsOpened(true);
  }, []);

  const close = React.useCallback(() => {
    setIsOpened(false);
  }, []);

  const handleAnchorMouseLeave = React.useCallback((event: React.MouseEvent) => {
    if (popupRef.current !== event.relatedTarget) {
      close();
    }
  }, [close]);

  return {
    isOpened,
    anchorRef,
    popupRef,
    open,
    close,
    handleAnchorMouseLeave
  };
};

export const bindHoverTrigger = <El extends HTMLElement>({
  anchorRef,
  open,
  handleAnchorMouseLeave
}: HoverPopupState<El>) => {
  return {
    ref: anchorRef,
    onMouseEnter: open,
    onMouseLeave: handleAnchorMouseLeave,
  };
};

export const bindHoverPopover = <El extends HTMLElement>({
  isOpened,
  anchorRef,
  popupRef,
  open,
  close,
}: HoverPopupState<El>) => ({
  open: isOpened,
  ref: popupRef,
  anchorEl: anchorRef.current,
  PaperProps: {
    onMouseEnter: open,
    onMouseLeave: close,
  },
});
