import React from 'react';
import { Col, Row, Text, withLayoutAware } from 'elmo-elements';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import { DispatchProps, Props, StateProps } from './type';
import {
  BOX_SHIFT_SWAPS_APPROVE_REQUEST,
  BOX_SHIFT_SWAPS_DECLINE_REQUEST,
  swapsWithoutUndoSelector,
} from 'state/ManagerDashboard/ShiftSwaps';
import {
  CheckOutlinedIcon,
  CloseOutlinedIcon,
  IsAppMarket,
  NotesIcon,
  WarningOutlinedIcon,
} from 'element';

import { userListSelector } from 'state/UsersCollection';
import { ShiftSwap, UserFields } from 'type/models';
import { SwapShift } from 'type/models/shiftSwap';
import moment from 'moment';
import {
  convertDecimalToFormattedTime,
  getDateTimeFormatted,
  getPreferenceLabel,
  getShiftDuration,
  getTimeFormatted,
  getUserName,
} from 'lib/helpers';
import { getAccountTree } from 'state/AccountTree';
import { EmptyMessage } from './components/EmptyMessage';
import {
  getDateFormat,
  getLangPreferences,
  getTimeFormat,
} from 'state/Account';
import { hasPermissionSelector } from 'state/Auth';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Avatar,
} from 'oxygen-elements';

class SwapsListComponent extends React.Component<Props> {
  render() {
    const { dateFormat, timeFormat, hasAuthorityToApprove } = this.props;
    const swapsKeys = Object.keys(this.props.swaps);
    return (
      <>
        {!swapsKeys.length ? (
          <EmptyMessage />
        ) : (
          swapsKeys.map((key) => {
            const swap = this.props.swaps[key];
            if (!swap) {
              return null;
            }
            const userFrom: UserFields = this.props.userList[swap.from.user_id];
            const userTo: UserFields = this.props.userList[swap.to.user_id];
            return (
              <Card className="shift-swap-item" role="row" key={key}>
                <CardHeader
                  className="card-header"
                  avatar={
                    <Avatar
                      src={userFrom.avatar.src}
                      alt={userFrom.avatar.label}
                      size={'small'}
                      light
                    />
                  }
                  title={getUserName(userFrom)}
                  titleTypographyProps={{
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                />
                <CardContent className="card-content">
                  <Row>
                    <Col span={24 / 4}>
                      <Text size={'xs'} color={'gray'}>
                        {this.positionAreLocation}
                      </Text>
                      <div className={'location-details'}>
                        {this.getLocation(swap.from)}
                      </div>
                      <IsAppMarket market={'uk'} negate={true}>
                        <Text
                          color="gray"
                          size="sm"
                          className={'timezone-details'}
                        >
                          {this.getTimezone(swap.from.site_id)}
                        </Text>
                      </IsAppMarket>
                    </Col>
                    <Col span={24 / 4}>
                      <Text size={'xs'} color={'gray'}>
                        Date
                      </Text>
                      <div className={'date-details'}>
                        {moment.parseZone(swap.from.start).format('ddd')},{' '}
                        {getDateTimeFormatted(
                          dateFormat,
                          timeFormat,
                          swap.from.start
                        )}
                      </div>
                    </Col>
                    <Col span={24 / 4}>
                      <Text size={'xs'} color={'gray'}>
                        Start - finish (duration)
                      </Text>
                      <div className={'duration-details'}>
                        {this.getDuration(swap.from)}
                      </div>
                    </Col>
                    {this.renderWarnings(swap.from)}
                  </Row>
                  <Row>
                    <Col span={24 / 4}>
                      <NotesIcon notes={swap.from.notes} />
                    </Col>
                    <Col span={24 / 2}>
                      <Text size={'xs'} color={'gray'}>
                        Note
                      </Text>
                      <div className={'notes-details'}>
                        {swap.note ? swap.note : '-'}
                      </div>
                    </Col>
                  </Row>
                </CardContent>
                <CardHeader
                  className="card-header"
                  avatar={
                    <Avatar
                      src={userTo.avatar.src}
                      alt={userTo.avatar.label}
                      size={'small'}
                      light
                    />
                  }
                  title={getUserName(userTo)}
                  titleTypographyProps={{
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                />
                <CardContent className="card-content">
                  <Row>
                    <Col span={24 / 4}>
                      <Text size={'xs'} color={'gray'}>
                        {this.positionAreLocation}
                      </Text>
                      <div className={'location-details'}>
                        {this.getLocation(swap.to)}
                      </div>
                      <IsAppMarket market={'uk'} negate={true}>
                        <Text
                          color="gray"
                          size="sm"
                          className={'timezone-details'}
                        >
                          {this.getTimezone(swap.to.site_id)}
                        </Text>
                      </IsAppMarket>
                    </Col>
                    <Col span={24 / 4}>
                      <Text size={'xs'} color={'gray'}>
                        Date
                      </Text>
                      <div className={'date-details'}>
                        {moment.parseZone(swap.to.start).format('ddd')},{' '}
                        {getDateTimeFormatted(
                          dateFormat,
                          timeFormat,
                          swap.to.start
                        )}
                      </div>
                    </Col>
                    <Col span={24 / 4}>
                      <Text size={'xs'} color={'gray'}>
                        Start - finish (duration)
                      </Text>
                      <div className={'duration-details'}>
                        {this.getDuration(swap.to)}
                      </div>
                    </Col>
                    {this.renderWarnings(swap.to)}
                  </Row>
                  <Row>
                    <Col span={24 / 4}>
                      <NotesIcon notes={swap.to.notes} />
                    </Col>
                  </Row>
                </CardContent>
                {this.actions(swap).length && (
                  <CardActions className="card-actions">
                    {this.actions(swap).map((action, key) => (
                      <Button
                        id={action.id}
                        key={`btn-${action.label}`}
                        size="large"
                        onClick={action.onClick}
                        disabled={action.isDisabled}
                      >
                        {action.icon} {action.label}
                      </Button>
                    ))}
                  </CardActions>
                )}
              </Card>
            );
          })
        )}
      </>
    );
  }

  private actions(swap: any): any[] {
    const { hasAuthorityToApprove } = this.props;
    return hasAuthorityToApprove
      ? [
          {
            label: 'Decline',
            onClick: () => {
              this.onClickDecline(swap);
            },
            icon: <CloseOutlinedIcon />,
          },
          {
            label: 'Approve',
            onClick: () => {
              this.onClickApprove(swap);
            },
            isDisabled: swap.from.is_error || swap.to.is_error,
            icon: <CheckOutlinedIcon />,
          },
        ]
      : [];
  }

  private onClickDecline = ({ id }: ShiftSwap) => {
    this.props.declineSwap({ id });
  };

  private onClickApprove = ({ id }: ShiftSwap) => {
    this.props.approveSwap({ id });
  };

  private getDuration = (shift: SwapShift) => {
    const { timeFormat, tree } = this.props;
    const start = getTimeFormatted(timeFormat, shift.start);
    const end = getTimeFormatted(timeFormat, shift.end);
    const duration = getShiftDuration(
      moment(shift.start),
      moment(shift.end),
      [],
      tree.sites[shift.site_id].timezone_id
    );
    const totalHrs = convertDecimalToFormattedTime(
      duration.total_hrs.toString(),
      true
    );
    return `${start} - ${end} (${totalHrs})`;
  };

  private getLocation = (roster: SwapShift) => {
    const { roles, areas, sites } = this.props.tree;
    let response = '';

    if (roster.role_id && roles[roster.role_id]) {
      response = roles[roster.role_id].name;
    }

    if (roster.area_id && areas[roster.area_id]) {
      response += ` - ${areas[roster.area_id].name}`;
    }

    if (roster.site_id && sites[roster.site_id]) {
      response += `, ${sites[roster.site_id].name}`;
    }
    return response;
  };

  private getTimezone = (siteId: string) => {
    const { sites } = this.props.tree;

    return sites[siteId] ? sites[siteId].timezone_id : '';
  };

  private renderWarnings = (shift: SwapShift) => {
    // Max hours per day will be achieved if approved
    const warningContainer = (
      <Col span={24 / 4}>
        <div>
          <WarningOutlinedIcon className={'text-color-warning'} />
          <Text size={'xs'} color={'gray'}>
            Warning
          </Text>
        </div>
        <div className={'warning-message'}>{shift.message[0]}</div>
      </Col>
    );

    return shift.message.length ? warningContainer : '';
  };

  private get positionAreLocation() {
    const { langPreferences } = this.props;
    const position = getPreferenceLabel(
      langPreferences,
      'role',
      'singular',
      '',
      true
    );
    const area = getPreferenceLabel(
      langPreferences,
      'area',
      'singular',
      '',
      false
    );
    const location = getPreferenceLabel(
      langPreferences,
      'site',
      'singular',
      '',
      false
    );
    return `${position} - ${area}, ${location}`;
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  swaps: swapsWithoutUndoSelector(state),
  userList: userListSelector(state),
  langPreferences: getLangPreferences(state),
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
  tree: getAccountTree(state),
  hasAuthorityToApprove: hasPermissionSelector(
    state,
    'managerdashboard.approveshiftswaps'
  ),
});

const mapDispatchToProps: DispatchProps = {
  declineSwap: BOX_SHIFT_SWAPS_DECLINE_REQUEST,
  approveSwap: BOX_SHIFT_SWAPS_APPROVE_REQUEST,
};

export const SwapsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withLayoutAware(SwapsListComponent));
