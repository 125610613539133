import React, { Component } from 'react';
import { debounce } from 'lodash';
import {
  Input
} from 'elmo-elements';
import { Location } from '../../types';
import './style.scss';
import { Event } from 'type/models';

type Place = {label: string, lat: number, lng: number};
type Props = {
  onChange: (location: Location) => void,
  event: Event
  id?: string;
};
declare let google: any;
export default class EventAddress extends Component<Props> {

  state = {
    value: this.props.event.address,
    results: [],
    showResults: false
  }

  render() {
    const {value} = this.state;

    return (
      <div  className="event-address" data-testid={this.props.id}>
       <Input
         id={'s'}
         value={value || ''}
         onChange={this.onInputChange}
         onClick={this.onInputClick}
         name="places-search"
         ariaLabel="Address to navigate"
       />
        {this.state.showResults && this.state.results.length ?  <ul className="event-address__results">
          {this.state.results.map( (place: Place, key) => {
            return <li key={key} onClick={() => {
              this.selectPlace(place.label, place.lat, place.lng)
            }}>{place.label}</li>
          })}

        </ul> : <></>}
      </div>
    );
  }

  onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.currentTarget;

    this.setState(() => ({
      value
    }), this.getResults);
  };

  onInputClick = () => {
    this.setState({showResults: true})
  };

  getResults = debounce(() => {
    const {value} = this.state;
    if (typeof google !== 'undefined') {
      let geocoder = new google.maps.Geocoder();
      const formattedResults: Place[] = [];
      geocoder.geocode({'address': value}, (results: any) => {
        if (typeof results !== 'undefined' && results !== null) {
          results.map((place: any) => {
            formattedResults.push({
              label: place.formatted_address,
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            })
          });
        }
        this.setState({
          results: formattedResults
        });

      });
    }

    this.setState({showResults: true});
    this.props.onChange({lat: 0, lng: 0, name: value});
  }, 500);

  selectPlace = (value: string, lat: number, lng: number) => {
    this.setState({
      value,
      showResults: false
    });

    this.props.onChange({lat, lng, name: value});
  }
}
