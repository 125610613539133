import { Box, Typography } from 'extended-oxygen-elements';
import React from 'react';
import { usePayConditionsListContext } from '../pay-conditions-list-context';

export type NoRulesMessageProps = {
  rulesQuantity: number;
};

export default NoRulesMessage;

export function NoRulesMessage({ rulesQuantity }: NoRulesMessageProps) {
  const { isCreateOpen } = usePayConditionsListContext();

  if (rulesQuantity || isCreateOpen) {
    return null;
  }

  return (
    <Box py={2} pl={2}>
      <Typography>There are no rules specified for current day</Typography>
    </Box>
  );
}
