import { createSelector } from 'reselect';
import { StringMap, Timesheet } from 'type';
import { StoreState } from '../types';
import {
  bySiteTimezoneCombinerCreator,
  groupedByFieldCombinerCreator,
} from '../combiners';
import { nest } from '../helpers';
import { values } from 'lodash';
import { fromToBySiteTimezoneSelectorsCreator } from '../selectors';
import { Moment } from 'moment';
import { getCurrentTime } from '../CurrentTime';

const getState = (state: StoreState) => state.timesheetsCollection;

const getPayload = (state: StoreState) => getState(state).payload;
export const getFrom = (state: StoreState) => getPayload(state).from;
export const getTo = (state: StoreState) => getPayload(state).to;
export const getSiteId = (state: StoreState) => getPayload(state).site_id;
export const getTimesheets = (state: StoreState): StringMap<Timesheet> =>
  getState(state).timesheets;
export const getTimesheet = (state: StoreState, id: string): Timesheet =>
  getState(state).timesheets[id];
export const getTimesheetBreaks = (state: StoreState, id: string) =>
  getTimesheet(state, id).breaks;
export const getTimesheetStart = (state: StoreState, id: string) =>
  getTimesheet(state, id).start;
export const getTimesheetEnd = (state: StoreState, id: string) =>
  getTimesheet(state, id).end;
export const getTimesheetRosteredShiftId = (state: StoreState, id: string) =>
  getTimesheet(state, id).rostered_shift_id;
export const getTimesheetRosteredShift = (state: StoreState, id: string) =>
  getTimesheet(state, id).rostered_shift;
export const getTimesheetUserId = (state: StoreState, id: string) =>
  getTimesheet(state, id).user_id;
export const getTimesheetIsApproved = (state: StoreState, id: string) =>
  getTimesheet(state, id).is_approved;
export const getSiteTimesheetUserId = (state: StoreState, id: string) => {
  if (id && getTimesheet(state, id)) {
    return getTimesheet(state, id).user_id;
  }
  return null;
}

export const {
  currentSiteTimezoneSelector,
  fromBySiteTimezoneSelector,
} = fromToBySiteTimezoneSelectorsCreator(getPayload);

export const timesheetsArraySelector = createSelector<
  StoreState,
  StringMap<Timesheet>,
  Timesheet[]
>(getTimesheets, values);

export const timesheetsByUserIdSelector = createSelector<
  StoreState,
  Timesheet[],
  StringMap<Timesheet[]>
>(timesheetsArraySelector, groupedByFieldCombinerCreator('user_id'));

export const timesheetsByAreaIdByRoleIdSelector = createSelector<
  StoreState,
  Timesheet[],
  StringMap<StringMap<Timesheet[]>>
>(timesheetsArraySelector, (rosteredShifts) =>
  nest(rosteredShifts, ['area_id', 'role_id'])
);

export const timesheetsByAreaIdSelector = createSelector<
  StoreState,
  Timesheet[],
  StringMap<Timesheet[]>
>(timesheetsArraySelector, groupedByFieldCombinerCreator('area_id'));

export const timesheetsByRoleIdSelector = createSelector<
  StoreState,
  Timesheet[],
  StringMap<Timesheet[]>
>(timesheetsArraySelector, groupedByFieldCombinerCreator('role_id'));

export const currentTimeBySiteTimezoneSelector = createSelector<
  StoreState,
  Moment,
  string,
  Moment
>(getCurrentTime, currentSiteTimezoneSelector, bySiteTimezoneCombinerCreator());

export const isTimesheetInProgress = (state: StoreState, id: string) =>
  getTimesheetEnd(state, id) === null;
