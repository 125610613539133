import { createReducer } from 'lib/store-utils';
import { OverviewReducerState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';

export const overview = createReducer<OverviewReducerState>({}, getDefaultState());

overview.on(actions.BOX_EMPLOYEE_OVERVIEW_DATA_REQUEST, getDefaultState);

overview.on(actions.BOX_EMPLOYEE_OVERVIEW_GET_OVERVIEW_SUCCESS, (state, payload) => ({
  ...state,
  overview: payload,
}));

overview.on(actions.BOX_EMPLOYEE_OVERVIEW_GET_SHIFTS_SUCCESS, (state, {data}) => ({
  ...state,
  errors: [],
  shifts: data
}));

overview.on(actions.BOX_EMPLOYEE_OVERVIEW_GET_SHIFTS_FAILURE, (state, errors) => ({
  ...state,
  errors
}));

overview.on(actions.BOX_EMPLOYEE_OVERVIEW_GET_SUCCESS, (state, payload) => ({
  ...state,
  overview: payload
}));
