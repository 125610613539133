import { FormattedErrors, Permission, StringMap } from '../../type';

export interface PermissionsReducerState {
  isFetched: boolean;
  isLoading: boolean;
  isUpdating: boolean;
  permissions: StringMap<Permission>;
  errors: FormattedErrors;
}

export const defaultState: PermissionsReducerState = {
  isFetched: false,
  isLoading: false,
  isUpdating: false,
  permissions: {},
  errors: []
};
