import { createReducer } from 'lib/store-utils';
import { CreateTimesheetDialogReducerState } from './types';
import * as actions from './actions';

export const createTimesheetDialog =
  createReducer<CreateTimesheetDialogReducerState>(
    {},
    {
      params: null,
    }
  );

createTimesheetDialog
  .on(
    actions.BOX_CREATE_TIMESHEET_DIALOG_OPENED,
    (state, params): CreateTimesheetDialogReducerState => ({
      params,
    })
  )
  .on(
    actions.BOX_CREATE_TIMESHEET_DIALOG_CLOSED,
    (): CreateTimesheetDialogReducerState => ({
      params: null,
    })
  );
