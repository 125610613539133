import React, { ReactNode } from 'react';
import { getClass } from '../../_lib/helper';

export type DescriptionListLabelProps = {
  children?: ReactNode;
  hasDivider?: boolean;
};

function DescriptionListLabel({
  hasDivider,
  children,
}: DescriptionListLabelProps): any {
  return (
    <dt
      className={getClass('elmo-dl__dt', {}, { 'has-divider': !!hasDivider })}
    >
      {children}
    </dt>
  );
}

export default DescriptionListLabel;
