import { DashboardShift, WorkingPeriod } from 'type';
import { StoreState } from 'state/types';
import { Modal, WorkingFilters } from '../types';

export const getSelectedPeriod = (state: StoreState): WorkingPeriod =>
  state.managerDashboard.whosWorking.period;
export const getWorkingShiftsSelector = (state: StoreState): DashboardShift[] =>
  state.managerDashboard.whosWorking.workingShifts;
export const getFiltersForm = (state: StoreState): WorkingFilters =>
  state.managerDashboard.whosWorking.filtersForm;
export const getFilters = (state: StoreState): WorkingFilters =>
  state.managerDashboard.whosWorking.filters;
export const getCustomPeriod = (state: StoreState): WorkingPeriod =>
  state.managerDashboard.whosWorking.filters.customPeriod;
export const getCustomFormPeriod = (state: StoreState): WorkingPeriod =>
  state.managerDashboard.whosWorking.filtersForm.customPeriod;
export const getIsCustomFilter = (state: StoreState): boolean =>
  state.managerDashboard.whosWorking.isCustomFilter;
export const getModal = (state: StoreState): Modal =>
  state.managerDashboard.whosWorking.modal;
export const getIsShiftsFetching = (state: StoreState) =>
  state.managerDashboard.whosWorking.isFetching;
export const getWhosWorkingErrors = (state: StoreState) =>
  state.managerDashboard.whosWorking.errors;
