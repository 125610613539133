import { SagaAction, StringMap } from '../../type';
import {
  BulkCreateActionData,
  UndoItem,
  UndoListPayload,
  UndoRostersPayload,
} from './types';
import { SagaIterator } from 'redux-saga';
import Api, { BulkRosteredShiftsRequest } from 'lib/Api';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  BOX_ROSTERED_SHIFT_BULK_ON_HOLD_RESIZER,
  BOX_ROSTERED_SHIFT_CLOSE_OVERLAP_MODAL,
  BOX_ROSTERED_SHIFT_CONFIRM_OVERLAP,
  BOX_ROSTERED_SHIFT_CREATE,
  BOX_ROSTERED_SHIFT_DRAG_N_DROP,
  BOX_ROSTERED_SHIFT_DRAG_N_DROP_FAILURE,
  BOX_ROSTERED_SHIFT_DRAG_N_DROP_SUCCESS,
  BOX_ROSTERED_SHIFT_DRAG_N_DROP_SUCCESS_COPY,
  BOX_ROSTERED_SHIFT_GLOBAL_UNDO_SHIFT,
  BOX_ROSTERED_SHIFT_GLOBAL_UNDO_SHIFT_FAILURE,
  BOX_ROSTERED_SHIFT_GLOBAL_UNDO_SHIFT_SUCCESS,
  BOX_ROSTERED_SHIFT_OPEN_OVERLAP_MODAL,
  BOX_ROSTERED_SHIFT_SET_DRAGGABLE_SHIFT,
  BOX_ROSTERED_SHIFT_SET_DRAGGABLE_SHIFT_BY_ID,
  BOX_ROSTERED_SHIFT_SET_DROP_CANCELED,
  BOX_ROSTERED_SHIFT_SET_DROP_INPROGRESS,
  BOX_ROSTERED_SHIFT_SET_STRETCHING_SHIFT,
  BOX_ROSTERED_SHIFT_SET_STRETCHING_SHIFT_BY_ID,
  BOX_ROSTERED_SHIFT_UPDATE,
  BOX_ROSTERED_SHIFTS_ADD,
  BOX_ROSTERED_SHIFTS_BULK_CREATE,
  BOX_ROSTERED_SHIFTS_BULK_CREATE_FAILURE,
  BOX_ROSTERED_SHIFTS_BULK_CREATE_SUCCESS,
  BOX_ROSTERED_SHIFTS_SET_BULK_PROPS,
  BOX_ROSTERED_SHIFTS_UNDO_LIST,
  BOX_ROSTERED_SHIFTS_UNDO_LIST_FAILURE,
  BOX_ROSTERED_SHIFTS_UNDO_LIST_SUCCESS
} from './actions';
import {
  BOX_ROSTER_CLOSE_APPLY_CHANGES_MODAL,
  BOX_ROSTER_GET_UNPUBLISHED,
  BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN,
} from '../Roster/Roster/actions';
import {
  RosteredShiftCreateRequest,
  RosteredShiftCreateResponse,
  RosteredShiftUpdateRequest,
  RosteredShiftUpdateResponse,
  UndoRequestPayload,
} from 'lib/Api/type';
import { formatError, getErrorStatus } from 'state/helpers';
import { processApiRequest } from 'state/ProcessApiRequest';
import { hasPermissionSelector } from 'state/Auth';
import { getChannelIsActive } from 'state/Sockets';
import {
  getBulkCreateShiftsInProgress, getDraggableShift,
  getDropInProgress,
  getDroppedShiftPayload,
  getOverlapModalProps,
  getRosteredShift, getStretchingShift
} from './selectors';
import { getNeedReAccept } from '../Roster/ReAcceptConfirmModal/sagas';
import {
  BOX_ROSTER_CONTEXTUAL_MENU_BULK_SET_PROPS,
  BOX_ROSTER_CONTEXTUAL_MENU_CLOSE_AND_SET_PROPS,
  BOX_ROSTER_CONTEXTUAL_MENU_SET_INPROGRESS,
} from '../Roster/ContextualMenu/actions';
import {
  contextInProgress,
  getLastAction,
} from '../Roster/ContextualMenu/selectors';
import { pasteRosteredShift } from '../Roster/ContextualMenu/sagas';
import {
  createRosteredShiftRequest,
  getRosteredShiftsListRequest,
} from './requestSagas';
import {
  BOX_IS_LOADING_OFF,
  BOX_IS_LOADING_ON,
  ROSTERED_SHIFTS_BULK_CREATE,
} from '../IsLoading';
import { getAutoPublish } from '../Account';
import { fillShifts } from 'state/Roster/helpers';

const removeDragPlaceholder = () => {
  const placeholder = document.querySelectorAll('.dragging-to-remove');
  if (placeholder) {
    placeholder.forEach((p) => p.remove());
    (window as any).dragPlaceholder = null;
  }
};

export const getUndoList = function*(
  action: SagaAction<UndoListPayload>
): SagaIterator {
  try {
    const payload: UndoListPayload = { site_id: action.payload.site_id };

    const canEditRosteredShifts: boolean = yield select(
      hasPermissionSelector,
      'roster.rosteredshift.edit'
    );
    const undoList: StringMap<UndoItem> = canEditRosteredShifts
      ? yield call(processApiRequest, Api.RosteredShift.undoList, payload)
      : {};
    yield put(BOX_ROSTERED_SHIFTS_UNDO_LIST_SUCCESS(undoList));
  } catch (errors) {
    yield put(BOX_ROSTERED_SHIFTS_UNDO_LIST_FAILURE(formatError(errors)));
  }
};

export const undoShifts = function*(
  action: SagaAction<UndoRostersPayload>
): SagaIterator {
  try {
    const isActiveChannel = yield select(getChannelIsActive, 'roster_week');
    const payload: UndoRequestPayload = {
      site_id: action.payload.site_id,
      rostered_shift_id: action.payload.rostered_shift_id
    };

    yield call(processApiRequest, Api.RosteredShift.undo, payload);

    // remove isUndoUpdating state
    yield put(BOX_ROSTERED_SHIFT_GLOBAL_UNDO_SHIFT_SUCCESS());

    if (!isActiveChannel) {
      // update list of Undo
      yield put(
        BOX_ROSTERED_SHIFTS_UNDO_LIST({
          site_id: payload.site_id
        })
      );

      // update list of unpublished rosters
      yield put(
        BOX_ROSTER_GET_UNPUBLISHED({
          site_id: payload.site_id
        })
      );

      // update Rostered Shifts
      yield call(getRosteredShiftsListRequest, {
        site_id: payload.site_id,
        from: action.payload.from,
        to: action.payload.to
      });
    }

    // close modal if needed
    if (action.payload.closeModal) {
      yield put(BOX_ROSTER_CLOSE_APPLY_CHANGES_MODAL());
    }
  } catch (errors) {
    yield put(
      BOX_ROSTERED_SHIFT_GLOBAL_UNDO_SHIFT_FAILURE(formatError(errors))
    );
  }
};

export const applyDragNDrop = function*(): SagaIterator {
  const { OVERLAP_ERROR_STATUS } = Api.RosteredShift;
  try {
    const shiftPayload = yield select(getDroppedShiftPayload);
    const overlapPayload = yield select(getOverlapModalProps);
    const isAutoPublish = yield select(getAutoPublish);
    const { action, ...rest } = shiftPayload;

    if ( action && action === 'copy') {

      const { id, ignore_errors, ...data } = rest;
      const response: RosteredShiftCreateResponse = yield call(
        processApiRequest,
        Api.RosteredShift.bulkCreate,
        {
          shifts: [{
            ...data,
            template_id: null,
          }],
          ignore_errors: overlapPayload.ignoreErrors
        }
      );
      yield put(BOX_ROSTERED_SHIFT_SET_DROP_INPROGRESS(false));
      yield put(BOX_ROSTERED_SHIFT_DRAG_N_DROP_SUCCESS_COPY(response));

    } else {

      const needReAccept: boolean = yield call(getNeedReAccept, {
        rosteredShiftId: rest.id,
        ignoreErrors: overlapPayload.ignoreErrors,
        userId: rest.user_id
      });

      const response: RosteredShiftUpdateResponse = yield call(
        processApiRequest,
        Api.RosteredShift.updateRosteredShift,
        {
          ...rest,
          need_re_accept: needReAccept,
          ignore_errors: overlapPayload.ignoreErrors
        }
      );
      yield put(BOX_ROSTERED_SHIFT_SET_DROP_INPROGRESS(false));
      yield put(
        BOX_ROSTERED_SHIFT_DRAG_N_DROP_SUCCESS({
          removeId: rest.id,
          updateShifts: response
        })
      );
    }
    removeDragPlaceholder();
  } catch (e) {
    const errorStatus = getErrorStatus(e);
    if (errorStatus === OVERLAP_ERROR_STATUS) {
      yield put(
        BOX_ROSTERED_SHIFT_OPEN_OVERLAP_MODAL(formatError(e)[0].split('\n'))
      );
    } else {
      yield put(BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN(formatError(e)));
      yield put(BOX_ROSTERED_SHIFT_DRAG_N_DROP_FAILURE(formatError(e)));
      removeDragPlaceholder();
    }
  }
};

export const triggerDeleteCutShift = function*(): SagaIterator {
  const lastAction = yield select(getLastAction);
  if (lastAction === 'cut') {
    yield put(BOX_ROSTER_CONTEXTUAL_MENU_CLOSE_AND_SET_PROPS({
      clipboard: null,
      action: 'none'
    }));
  }
};

const createRosteredShift = function*({
  payload
}: SagaAction<RosteredShiftCreateRequest>): SagaIterator {
  try {
    const isAutoPublish = yield select(getAutoPublish);

    yield call(createRosteredShiftRequest, {
      shifts: [payload]
    });

    // disable all pre-loaders
    yield put(BOX_ROSTER_CONTEXTUAL_MENU_SET_INPROGRESS(false));
    yield put(BOX_ROSTERED_SHIFTS_SET_BULK_PROPS({
      drop: false,
      isUpdating: false
    }));
  } catch (error) {
    yield put(BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN(formatError(error)));
    yield put(BOX_ROSTER_CONTEXTUAL_MENU_SET_INPROGRESS(false));
  }
};

const bulkCreateShifts = function*({
  payload
}: SagaAction<BulkRosteredShiftsRequest>): SagaIterator {
  yield put(BOX_IS_LOADING_ON(ROSTERED_SHIFTS_BULK_CREATE));
  try {
    const isAutoPublish = yield select(getAutoPublish);
    yield call(createRosteredShiftRequest, payload);
    yield put(BOX_ROSTERED_SHIFTS_BULK_CREATE_SUCCESS());

  } catch (error) {
    yield put(BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN(formatError(error)));
    yield put(BOX_ROSTERED_SHIFTS_BULK_CREATE_FAILURE(formatError(error)));
  }
  yield put(BOX_IS_LOADING_OFF(ROSTERED_SHIFTS_BULK_CREATE));
};

const updateRosteredShift = function*({
  payload
}: SagaAction<RosteredShiftUpdateRequest>): SagaIterator {
  const { OVERLAP_ERROR_STATUS } = Api.RosteredShift;

  try {
    const response: RosteredShiftCreateResponse = yield call(
      processApiRequest,
      Api.RosteredShift.updateRosteredShift,
      payload
    );

    yield put(BOX_ROSTERED_SHIFTS_ADD(response));

    // disable all pre-loaders
    yield put(BOX_ROSTER_CONTEXTUAL_MENU_BULK_SET_PROPS({
      inProgress: false,
      clipboard: null
    }));
    yield put(BOX_ROSTERED_SHIFTS_SET_BULK_PROPS({
      drop: false,
      isUpdating: false
    }));
  } catch (error) {
    const errorStatus = getErrorStatus(error);
    if (errorStatus === OVERLAP_ERROR_STATUS) {
      yield put(
        BOX_ROSTERED_SHIFT_OPEN_OVERLAP_MODAL(formatError(error)[0].split('\n'))
      );
    } else {
      yield put(BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN(formatError(error)));
      yield put(BOX_ROSTER_CONTEXTUAL_MENU_BULK_SET_PROPS({
        inProgress: false,
        clipboard: null
      }));
    }
  }
};

const getLatestActionsStates = function*(): SagaIterator {
  // detect action which is in progress
  const inProgress = yield select(contextInProgress);
  const dragAndDropInProgress = yield select(getDropInProgress);
  return { inProgress, dragAndDropInProgress };
};

const repeatFunctionWithOverlap = function*(): SagaIterator {
  const { inProgress, dragAndDropInProgress } = yield call(
    getLatestActionsStates
  );

  if (inProgress) {
    yield call(pasteRosteredShift);
  }

  if (dragAndDropInProgress) {
    yield put(BOX_ROSTERED_SHIFT_SET_DROP_INPROGRESS(true));
    yield call(applyDragNDrop);
  }
};

const setPropsOnCancelOverlap = function*() {
  const { inProgress, dragAndDropInProgress } = yield call(
    getLatestActionsStates
  );
  const lastAction = yield select(getLastAction);

  if (inProgress) {
    if (lastAction === 'cut') {
      yield put(BOX_ROSTER_CONTEXTUAL_MENU_CLOSE_AND_SET_PROPS({
        action: 'none',
        clipboard: null
      }));
    }
    yield put(BOX_ROSTER_CONTEXTUAL_MENU_SET_INPROGRESS(false));
  }

  if (dragAndDropInProgress) {
    yield put(BOX_ROSTERED_SHIFT_SET_DROP_CANCELED(true));
    yield put(BOX_ROSTERED_SHIFT_SET_DROP_INPROGRESS(false));
    removeDragPlaceholder();
  }
};

const setDraggableShiftById = function*({
  payload
}: SagaAction<null | { id: string }>): SagaIterator {
  if (payload) {
    const shift = yield select(getRosteredShift, payload.id);
    if (shift) {
      yield put(BOX_ROSTERED_SHIFT_SET_DRAGGABLE_SHIFT(shift));
      (window as any).draggableShift = shift;
    }
  }
}

const setStretchingShiftById = function*({
  payload
}: SagaAction<null | { id: string }>): SagaIterator {
  if (payload) {
    const shift = yield select(getRosteredShift, payload.id);
    if (shift) {
      yield put(BOX_ROSTERED_SHIFT_SET_STRETCHING_SHIFT(shift));
    }
  }
}

const setBulkData = function*({
  payload
}: SagaAction<BulkCreateActionData>): SagaIterator {
  const shift = yield select(getStretchingShift);
  const isBulkCreateInProgress = yield select(getBulkCreateShiftsInProgress);
  if ( shift && !isBulkCreateInProgress ) {
    const shiftsToCreate = fillShifts(shift, payload.cellsArray, payload.resizerLeft);
    if (shiftsToCreate.length) {
      yield put(BOX_ROSTERED_SHIFTS_BULK_CREATE({
        shifts: shiftsToCreate
      }));
    }
  }
};

export const watchRosteredShifts = function*(): SagaIterator {
  yield takeLatest(BOX_ROSTERED_SHIFTS_UNDO_LIST, getUndoList);
  yield takeLatest(BOX_ROSTERED_SHIFT_GLOBAL_UNDO_SHIFT, undoShifts);
  yield takeLatest(BOX_ROSTERED_SHIFT_DRAG_N_DROP, applyDragNDrop);
  yield takeLatest(
    BOX_ROSTERED_SHIFT_CONFIRM_OVERLAP,
    repeatFunctionWithOverlap
  );
  yield takeLatest(
    BOX_ROSTERED_SHIFT_CLOSE_OVERLAP_MODAL,
    setPropsOnCancelOverlap
  );
  yield takeLatest(BOX_ROSTERED_SHIFT_CREATE, createRosteredShift);
  yield takeLatest(BOX_ROSTERED_SHIFT_UPDATE, updateRosteredShift);
  yield takeLatest(BOX_ROSTERED_SHIFTS_BULK_CREATE, bulkCreateShifts);

  yield takeLatest(BOX_ROSTERED_SHIFT_SET_DRAGGABLE_SHIFT_BY_ID, setDraggableShiftById);
  yield takeLatest(BOX_ROSTERED_SHIFT_SET_STRETCHING_SHIFT_BY_ID, setStretchingShiftById);

  yield takeLatest(BOX_ROSTERED_SHIFT_BULK_ON_HOLD_RESIZER, setBulkData);
};
