import * as styledComponents from 'styled-components';
import { defaultTheme } from './theme';

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  withTheme,
} = styledComponents;

export {
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  defaultTheme,
  withTheme,
};

export default styled;
