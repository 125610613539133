import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Typography,
  FormHelperText,
} from 'extended-oxygen-elements';
import { FieldGroupProps, TimesheetSettings } from 'type';
import { useSelector } from 'react-redux';
import { getLangPreferences } from 'state/Account';
import { useSetCheckbox, useSetFormField } from 'hooks';
import { InlineInputWrapper, HelperTextWrapper } from '../../../components';
import { DurationMinutesRequiredField } from 'element';
import marketLabels from 'marketLabels';
import FormControlSpacing from 'element/FormControlSpacing';

type AddingTimesheetsFieldGroupValue = Pick<
  TimesheetSettings,
  'mobile_punchclock' | 'mobile_allow_start_within' | 'mobile_geotracking'
>;
type AddingTimesheetsFieldGroupErrors = {};

type Props = FieldGroupProps<
  AddingTimesheetsFieldGroupValue,
  AddingTimesheetsFieldGroupErrors
>;

export default function MobileTimesheetsFieldGroup({
  onChange,
  values,
}: Props) {
  const langPreferences = useSelector(getLangPreferences);
  const { makeSetCheckbox } = useSetCheckbox(onChange);
  const { makeSetFormField } = useSetFormField(onChange);
  return (
    <>
      <Typography variant="h4" component="h2">
        Breathe People Portal settings
      </Typography>
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              name={'mobile_punchclock'}
              checked={values.mobile_punchclock}
              onChange={makeSetCheckbox('mobile_punchclock')}
            />
          }
          label="Enable clock on"
        />
        <HelperTextWrapper>
          <FormHelperText fontSize="medium">
            Allow people to clock on using the Breathe People Portal mobile app.
          </FormHelperText>
        </HelperTextWrapper>
      </FormControl>
      <FormControlSpacing flexDirection="column">
        <Typography variant="h6" component="h3">
          Clock on window
        </Typography>
        <Typography variant="body1" component="h6">
          Enable clock on within
          <InlineInputWrapper>
            <DurationMinutesRequiredField
              inputProps={{
                size: 4,
              }}
              name={'mobile_allow_start_within'}
              margin="none"
              fullWidth={false}
              formatUnit="minutes"
              defaultUnit="minutes"
              minutesPrefix="m"
              value={values.mobile_allow_start_within}
              onValueChange={makeSetFormField('mobile_allow_start_within')}
            />
          </InlineInputWrapper>
          of a scheduled {marketLabels.rosteredShift}.
        </Typography>
      </FormControlSpacing>
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              name={'mobile_geotracking'}
              checked={values.mobile_geotracking}
              onChange={makeSetCheckbox('mobile_geotracking')}
            />
          }
          label={`Enable geotagging`}
        />
        <HelperTextWrapper>
          <FormHelperText fontSize="medium">
            Location captured when an employee clocks on.
          </FormHelperText>
        </HelperTextWrapper>
      </FormControl>
    </>
  );
}
