export const PRIVATE_LAYOUT = 'PRIVATE_LAYOUT';
export const ROSTERS = 'ROSTERS';
export const REPORTS = 'REPORTS';
export const USERS = 'USERS';
export const ACCOUNTS = 'ACCOUNTS';
export const MANAGER_DASHBOARD_OVERVIEW = 'MANAGER_DASHBOARD_OVERVIEW';
export const MANAGER_DASHBOARD_WHOS_WORKING = 'MANAGER_DASHBOARD_WHOS_WORKING';
export const MANAGER_DASHBOARD_TIMESHEETS = 'MANAGER_DASHBOARD_TIMESHEETS';
export const MANAGER_DASHBOARD_TIMESHEETS_AWARDS_MODAL =
  'MANAGER_DASHBOARD_TIMESHEETS_AWARDS_MODAL';
export const MANAGER_DASHBOARD_TIMESHEETS_ALLOWANCES_MODAL =
  'MANAGER_DASHBOARD_TIMESHEETS_ALLOWANCES_MODAL';
export const MANAGER_DASHBOARD_SHIFT_OFFER = 'MANAGER_DASHBOARD_SHIFT_OFFER';
export const MANAGER_DASHBOARD_SHIFT_OFFERS = 'MANAGER_DASHBOARD_SHIFT_OFFERS';
export const MANAGER_DASHBOARD_SHIFT_SWAPS = 'MANAGER_DASHBOARD_SHIFT_SWAPS';
export const MANAGER_DASHBOARD_UNAVAILABILITY =
  'MANAGER_DASHBOARD_UNAVAILABILITY';
export const MANAGER_DASHBOARD_EVENTS = 'MANAGER_DASHBOARD_EVENTS';
export const EMPLOYEE_DASHBOARD_OVERVIEW = 'EMPLOYEE_DASHBOARD_OVERVIEW';
export const EMPLOYEE_DASHBOARD_MY_TIMESHEET =
  'EMPLOYEE_DASHBOARD_MY_TIMESHEET';
export const EMPLOYEE_DASHBOARD_SHIFT_TRADES =
  'EMPLOYEE_DASHBOARD_SHIFT_TRADES';
export const EMPLOYEE_DASHBOARD_SHIFT_TRADES_SWAP_PAGE =
  'EMPLOYEE_DASHBOARD_SHIFT_TRADES_SWAP_PAGE';
export const EMPLOYEE_DASHBOARD_MY_ROSTERS = 'EMPLOYEE_DASHBOARD_MY_ROSTERS';
export const EMPLOYEE_DASHBOARD_EVENTS = 'EMPLOYEE_DASHBOARD_EVENTS';
export const EMPLOYEE_DASHBOARD_UNAVAILABILITY =
  'EMPLOYEE_DASHBOARD_UNAVAILABILITY';
export const PAY = 'PAY';
export const SETTINGS_TAGS = 'SETTINGS_TAGS';
export const SETTINGS_TAG = 'SETTINGS_TAG';
export const ROSTER_EDIT_TEMPLATE = 'ROSTER_EDIT_TEMPLATE';
export const ACCOUNT_TREE_PAGE_DATA = 'ACCOUNT_TREE_PAGE_DATA';
