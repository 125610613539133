import React from 'react';
import { getClass } from '../_lib/helper';
import { LinkProps } from './type';
import './Link.scss';
import styled, { defaultTheme } from '../_lib/style';

const StyledLink = styled.a<any>`
  ${(props) => {
    return (
      !props.isDisabled &&
      props.theme &&
      props.theme.link &&
      props.theme.link.color &&
      `
        color: ${props.theme.link.color} !important;
      `
    );
  }}
`;

StyledLink.defaultProps = {
  theme: {
    link: defaultTheme.link,
  },
};

function Link({
  isDisabled,
  url,
  className,
  onClick,
  tabIndex,
  newWindow,
  children,
  id,
  role,
  ariaDisabled,
}: LinkProps) {
  return (
    <StyledLink
      id={id}
      href={isDisabled ? '#' : url}
      className={getClass('elmo-link', className, { disabled: isDisabled })}
      tabIndex={tabIndex}
      target={newWindow ? '_blank' : undefined}
      data-testid={`elmo-link-${id || 'default'}`}
      {...(!isDisabled ? { onClick: onClick } : {})}
      role={role}
      aria-disabled={ariaDisabled}
    >
      {children}
    </StyledLink>
  );
}

Link.defaultProps = {
  role: 'link',
  ariaDisabled: false,
};

export default Link;
