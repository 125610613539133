import { DoneAllIcon as SelectAllIcon } from '../../icons';
import React from 'react';
import './SelectAllButton.scss';
import { getClass } from '../../_lib/helper';
import Button from '../../Button';

export type SelectAllButtonProps = {
  /** Handler for when Select All clicked */
  handleSelectAllClick: () => void;
  /** Is Select All active? */
  isActive: boolean;
};

function SelectAllButton({
  handleSelectAllClick,
  isActive,
}: SelectAllButtonProps) {
  return (
    <Button
      className={getClass('elmo-select-list__select-all-btn', '', {
        active: isActive,
      })}
      isText={true} // Button with no border
      onClick={handleSelectAllClick}
      icon={<SelectAllIcon />}
      ariaLabel="Select all"
    />
  );
}

export default SelectAllButton;
