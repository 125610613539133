import React from 'react';
import { MessageBlock } from 'elmo-elements';

type OwnProps = {};

type StateProps = {};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

const EmptyMessageComponent = () => (
  <MessageBlock>
    <MessageBlock.Header>
      There are no upcoming shifts.
    </MessageBlock.Header>
  </MessageBlock>
);

export const EmptyMessage = EmptyMessageComponent;
