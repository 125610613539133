import { EmployeeSiteProps, EmployeeUnavailabilityReducerState } from './types';
import moment from 'moment';

export const getDefaultState = (): EmployeeUnavailabilityReducerState => ({
  isFetching: false,
  errors: [],
  dayDetails: null,
  data: [],
  shifts: [],
  date: moment(),
  spreadOfHours: [],
  notesModal: {
    id: null,
    isOpened: false,
    notes: null,
  },
  modal: {
    isOpened: false,
    isUpdating: false,
    isFetching: false,
    isDeleting: false,
    type: 'create',
    data: {
      description: '',
      duration: 'all_day',
      end: moment(),
      external_id: null,
      id: '',
      options: [],
      repeat: 'once',
      site: {
        id: '',
        timezone_id: '',
        name: '',
      },
      site_id: '',
      start: moment(),
      status: 'pending',
      type: 'unavailability',
      until: null,
      user_id: 'userId',
      original_status: '',
      is_dst_intersect: false,
      notes: null,
      subtype: null,
    },
    errors: [],
  },
});
