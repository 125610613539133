import { getDateTimeFormatted } from 'lib/helpers';
import moment from 'moment';
import {
  Minutes,
  PreferencesDateFormat,
  PreferencesTimeFormat,
  ServerTimesheetBreak,
} from 'type/models';
import { getDateFormat, getTimeFormat } from '../../Account';
import store from '../../store';

export const getPeriodDated = (period?: string) => {
  const dateFormat: PreferencesDateFormat = getDateFormat(store.getState());
  const timeFormat: PreferencesTimeFormat = getTimeFormat(store.getState());
  switch (period) {
    case 'next_7_days':
      return {
        from: getDateTimeFormatted(dateFormat, timeFormat, moment()),
        to: getDateTimeFormatted(
          dateFormat,
          timeFormat,
          moment().add(7, 'days')
        ),
      };
    case 'next_30_days':
      return {
        from: getDateTimeFormatted(dateFormat, timeFormat, moment()),
        to: getDateTimeFormatted(
          dateFormat,
          timeFormat,
          moment().add(30, 'days')
        ),
      };
    case 'all_future':
      return {
        from: getDateTimeFormatted(dateFormat, timeFormat, moment()),
        to: null,
      };
    case 'past_7_days':
      return {
        to: getDateTimeFormatted(dateFormat, timeFormat, moment()),
        from: getDateTimeFormatted(
          dateFormat,
          timeFormat,
          moment().subtract(7, 'days')
        ),
      };
    case 'past_30_days':
      return {
        to: getDateTimeFormatted(dateFormat, timeFormat, moment()),
        from: getDateTimeFormatted(
          dateFormat,
          timeFormat,
          moment().subtract(30, 'days')
        ),
      };
    default:
      return {
        from: getDateTimeFormatted(dateFormat, timeFormat, moment()),
        to: getDateTimeFormatted(
          dateFormat,
          timeFormat,
          moment().add(7, 'days')
        ),
      };
  }
};

export const getIsOnBreak = (
  timesheet: {
    end: string | null;
    breaks: ServerTimesheetBreak[];
  } | null
): boolean =>
  !!timesheet &&
  !timesheet.end &&
  timesheet.breaks.some((timesheetBreak) => timesheetBreak.active);

export const getIsFinished = (
  timesheet: { end: string | null } | null
): boolean => !!timesheet && !!timesheet.end;

export const getVariance = (
  shift: { start: string },
  timesheet: { start: string }
): Minutes => {
  const rosteredShiftStart = moment.parseZone(shift.start);
  const timesheetStart = moment.parseZone(timesheet.start);

  return timesheetStart.diff(rosteredShiftStart, 'minutes');
};

export const isTimesheetInProgress = (timesheet: { end: string | null }) => !timesheet.end;
