import React from 'react';
import { Tooltip } from 'elmo-elements';
import { AccessTimeIcon } from 'element';

type Props = {
  isDST: boolean | undefined;
  offsetLeft?: number;
  offsetTop?: number;
};

export const DSTIcon = ({ isDST, offsetLeft, offsetTop }: Props) => (
  isDST === true
    ? <Tooltip
        className={'dst-tooltip'}
        placement={'top'}
        title={'Clock has been reset due to Daylight Saving Time. Shift duration has been readjusted.'}
      >
        <span style={{
          position: 'relative',
          top: `${offsetTop ? offsetTop : -2}px`,
          left: `${offsetLeft ? offsetLeft : 0}px`
        }}>
          <AccessTimeIcon fontSize="small" />
        </span>
      </Tooltip>
    : null
);
