import React, { Component, MouseEvent } from 'react';
import { connect } from 'react-redux';
import { Report, ReportSchedule, StringMap, UserFields } from 'type';
import { DATE_LABELS, FORMAT_LABELS } from 'lib/config';
import { StoreState } from 'state/types';
import { BOX_REPORTS_SCHEDULE_MODAL_OPEN } from 'state/Reports';
import { SCHEDULE_LABELS, WEEKDAYS_LABELS } from '../../../config';
import { getDateFormat, timeFormatSelector } from 'state/Account/selectors';
import moment from 'moment';
import { userListSelector } from 'state/UsersCollection';
import { ScheduleBadge } from './ScheduleBadge';

type OwnProps = {
  reportId: string;
  schedule: ReportSchedule;
};

type StateProps = {
  userList: StringMap<UserFields>;
  reports: StringMap<Report>;
  timeFormat: string;
  dateFormat: string;
};

type DispatchProps = {
  openScheduleModal: (report: Report) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

export class SchedulePopover extends Component<Props> {
  render() {
    const { schedule } = this.props;
    return (
      <div className="inline-block" onClick={this.preventRedirect}>
        <ScheduleBadge
          renderStatusFrequency={this.renderStatusFrequency}
          schedule={schedule}
          renderFormat={this.renderFormat}
          renderStatusDuration={this.renderStatusDuration}
          onClickEdit={this.onClickEdit}
        />
      </div>
    );
  }

  get renderFormat() {
    const { schedule } = this.props;
    const format = FORMAT_LABELS[schedule.format].label;
    return (
      <span data-testid="report_format">
        {schedule.sub_format === 'pay_sauce' ? `PaySauce ${format}` : format}
      </span>
    );
  }

  get renderStatusFrequency() {
    const { schedule } = this.props.schedule;
    let days: string;

    switch (schedule.period) {
      case 'daily':
      case 'weekly':
      case 'fortnightly':
        days = schedule.day
          .map((dayId) => WEEKDAYS_LABELS[dayId].label)
          .join(', ');
        break;
      case 'monthly':
        days = schedule.day
          .map((dateId) => DATE_LABELS[dateId].label)
          .join(', ');
        break;
      default:
        days = '';
    }

    const frequency = `${SCHEDULE_LABELS[schedule.period].label} on ${days}`;

    return (
      <div className="truncate" title={frequency}>
        {frequency}
      </div>
    );
  }

  get renderStatusDuration() {
    const { from, to } = this.props.schedule;

    const fromStr: string = moment(from).format(this.props.dateFormat);
    const toStr: string = to
      ? moment(to).format(this.props.dateFormat)
      : 'Ongoing';

    return `${fromStr} - ${toStr}`;
  }

  onClickEdit = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const report = this.props.reports[this.props.reportId];
    this.props.openScheduleModal(report);
  };

  private preventRedirect = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };
}

const mapStateToProps = (state: StoreState) => ({
  userList: userListSelector(state),
  reports: state.reports.reports,
  timeFormat: timeFormatSelector(state),
  dateFormat: getDateFormat(state),
});

const mapDispatchToProps: DispatchProps = {
  openScheduleModal: BOX_REPORTS_SCHEDULE_MODAL_OPEN,
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulePopover);
