import React, { memo } from 'react';
import { useMakeSelector, useSelectorWithProps } from 'hooks';
import { makeItemAreaIdRoleIdSelector, makeItemRosterSiteRowNumberSelector } from 'state/Roster/RosterWeekViewSiteView';
import { SiteRowWithLegendRefactored } from '../../../../../../components/SiteRowWithLegendRefactored';
import { times } from 'lodash';
import { SitePairRow } from '../PairRow';

type Props = { siteRowIndex: number };

export const WeekViewSiteViewSiteRow = memo(function WeekViewSiteViewSiteRow(
  props: Props
) {
  const itemRosterRowNumberSelector = useMakeSelector(
    makeItemRosterSiteRowNumberSelector
  );
  const itemAreaIdRoleIdSelector = useMakeSelector(makeItemAreaIdRoleIdSelector);
  const rosterRowsNumber = useSelectorWithProps(
    itemRosterRowNumberSelector,
    props
  );
  const itemAreaIdRoleId = useSelectorWithProps(itemAreaIdRoleIdSelector, props);

  return (
    <SiteRowWithLegendRefactored
      areaId={itemAreaIdRoleId.area_id}
      roleId={itemAreaIdRoleId.role_id}
      legendType={rosterRowsNumber > 1 ? 'multi-row' : 'single-row'}
      bulkSelect={'area'}
      rowIndex={props.siteRowIndex}
    >
      {times(rosterRowsNumber, (pairRowIndex) => (
        <SitePairRow
          key={pairRowIndex}
          siteRowIndex={props.siteRowIndex}
          pairRowIndex={pairRowIndex}
        />
      ))}
    </SiteRowWithLegendRefactored>
  );
})