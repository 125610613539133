import { useLayoutContext } from 'element/Layout';
import React from 'react';
import AlertErrorBox, { AlertErrorBoxProps } from './AlertErrorBox';

export type AlertErrorBoxLayoutProps = Omit<
  AlertErrorBoxProps,
  'scrollParentRef' | 'scrollOffset'
>;

export default AlertErrorBoxLayout;

export function AlertErrorBoxLayout(props: AlertErrorBoxLayoutProps) {
  const documentElementRef = React.useRef(document.documentElement);
  const { appBarHeight, headerHeight } = useLayoutContext();

  return (
    <AlertErrorBox
      {...props}
      scrollParentRef={documentElementRef}
      scrollOffset={appBarHeight + headerHeight}
    />
  );
}
