import React, { useState } from 'react';
import { Checkbox } from '../../../../../../elmo-elements';
import { AccountTreeSite } from '../../../../../../type';

type Props = {
  location: AccountTreeSite;
  isDisabled: boolean;
  isChecked: boolean;
  onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
};

const TrackLocationCheckbox = ({ location, isDisabled, isChecked, onChange }: Props) => {
  return (
    <Checkbox
      name={`track_location_${location.id}`}
      id={`track-location-${location.id}`}
      label={location.name}
      ariaLabel={location.name}
      isChecked={isChecked}
      onChange={onChange}
      isDisabled={isDisabled}
      className={'mb-4'}
    />
  )
}

export default TrackLocationCheckbox;