import { Checkbox, FormControlLabel, Tooltip } from 'extended-oxygen-elements';
import { useSelectorWithProps } from 'hooks';
import { capitalize } from 'lib/helpers';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getLangPreferences } from 'state/Account';
import {
  getSiteArchived,
  getSiteIsBreathe,
  getSiteName,
} from 'state/AccountTree';
import { getAssignmentSiteUsersCount } from 'state/Assignments';
import DialogUpdateSiteStatus from './DialogUpdateSiteStatus';
import GroupAreas from './GroupAreas';
import { getIsArchivedFromURL } from './helpers';

type Props = {
  id: string;
};

export default function SectionSite({ id }: Props) {
  const siteName = useSelectorWithProps(getSiteName, id);
  const isSiteArchived = useSelectorWithProps(getSiteArchived, id);
  const { isArchivable, tooltipLabel } = useArchivable(id);
  const [isOpenedDialog, setIsOpenedDialog] = useState(false);

  const closeDialog = () => {
    setIsOpenedDialog(false);
  };

  const handleChange = () => {
    setIsOpenedDialog(true);
  };

  if (!getIsArchivedFromURL() && isSiteArchived) {
    return null;
  }

  return (
    <article aria-label={siteName}>
      <FormControlLabel
        componentsProps={{
          typography: {
            variant: 'h4',
          },
        }}
        label={siteName}
        control={
          <Tooltip title={tooltipLabel}>
            <span>
              <Checkbox
                checked={!isSiteArchived}
                disabled={!isArchivable}
                onChange={handleChange}
              />
            </span>
          </Tooltip>
        }
      />
      <GroupAreas disabled={isSiteArchived} site_id={id} />
      <DialogUpdateSiteStatus
        closeDialog={closeDialog}
        open={isOpenedDialog}
        site_id={id}
      />
    </article>
  );
}

function useArchivable(site_id: string): {
  isArchivable: boolean;
  tooltipLabel: string | undefined;
} {
  const isSiteBreathe = useSelectorWithProps(getSiteIsBreathe, site_id);
  const isSiteArchived = useSelectorWithProps(getSiteArchived, site_id);
  const siteUsersCount = useSelectorWithProps(getAssignmentSiteUsersCount, {
    site_id,
  });
  const langPreferences = useSelector(getLangPreferences);

  if (siteUsersCount) {
    return {
      isArchivable: false,
      tooltipLabel: `${capitalize(
        langPreferences.site.singular
      )} can not be archived when there are assigned users`,
    };
  }

  return {
    isArchivable: true,
    tooltipLabel: undefined,
  };
}
