import { Moment } from 'moment';
import {
  getTimeFormattedShortened,
  getTimeFormattedShortenedWithoutZeros,
} from 'lib/helpers';
import { TIME_FORMAT } from 'lib/config';

export const formatTime = (time: Moment, timeFormat: string): string => {
  if (timeFormat === TIME_FORMAT.hours_12) {
    const shortFormat = 'h:mm a';
    return getTimeFormattedShortenedWithoutZeros(time.format(shortFormat));
  }
  return getTimeFormattedShortened(time.format(timeFormat));
};
