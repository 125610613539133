import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { StoreState } from '../../../../../../state/types';
import { FormattedErrors } from '../../../../../../type/models';
import {
  getApprovalsErrors,
  getApprovalsIsFetched,
  getApprovalsIsFetching
} from '../../../../../../state/Users/UserApprovalsModal/selectors';
import { Loader } from 'elmo-elements';
import ErrorBox from '../../../../../../element/ErrorBox';

type OwnProps = {
  children: ReactNode;
};

type StateProps = {
  isFetching: boolean;
  isFetched: boolean;
  errors: FormattedErrors;
};

type DispatchProps = {};

export type Props = OwnProps & StateProps & DispatchProps;

export const ApprovalsModalLoaderComponent = ({
  children,
  isFetching,
  isFetched,
  errors
}: Props) => (
  <Loader type="spinner" isLoading={isFetching}>
    {isFetched ? children : <ErrorBox errors={errors} />}
  </Loader>
);

export const mapStateToProps = (state: StoreState): StateProps => ({
  isFetching: getApprovalsIsFetching(state),
  isFetched: getApprovalsIsFetched(state),
  errors: getApprovalsErrors(state)
});

const mapDispatchToProps: DispatchProps = {};

export const ApprovalsModalLoader = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApprovalsModalLoaderComponent);
