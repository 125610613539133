import React from 'react';
import { privateRoutes } from 'routes';
import { RosterToggleView } from '../../../../components/RosterToggleView';

type Props = {
  templateId: string;
};

export const EditTemplateViewSwitcher = ({ templateId }: Props) => {
  const {
    rosterWeekViewEditSiteView,
    rosterWeekViewEditUserView
  } = privateRoutes.roster.routes.rosterEditTemplate.routes;
  return (
    <RosterToggleView
      paths={{
        userView: rosterWeekViewEditUserView.path(templateId),
        siteView: rosterWeekViewEditSiteView.path(templateId)
      }}
      pageType={'week'}
      hidePageTypeSwitcher={true}
    />
  );
};
