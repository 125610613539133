import React from 'react';
import { FormItem, Checkbox } from 'elmo-elements';
import { Data } from '../type';

export type DataProps = {
  handleOnChangeCheckbox: (e: any) => void;
  data: Data;
};

const LateReportCheckboxGroup = ({
  data,
  handleOnChangeCheckbox,
}: DataProps) => {
  return (
    <FormItem isInline={true}>
      <Checkbox
        name={'details'}
        id={'show-details'}
        label={'Show details'}
        isChecked={data.details}
        onChange={handleOnChangeCheckbox}
      />
      <Checkbox
        name={'show_approved_timesheets'}
        id={'show_approved_timesheets'}
        label={'Only show approved timesheets'}
        isChecked={data.show_approved_timesheets}
        onChange={handleOnChangeCheckbox}
      />
    </FormItem>
  );
};

export default LateReportCheckboxGroup;
