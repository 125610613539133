import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios';

import * as _ from 'lodash';

type RequestConfigOrNone = AxiosRequestConfig | undefined | null | false | void;

interface HTTPClientConfig extends AxiosRequestConfig {
  readonly [name: string]: any;

  /**
   * Called before every request.
   * This hook could be useful if you want perform additional work before request,
   * or override request config.
   */
  readonly onBeforeRequest?: (
    this: HTTPClient,
    requestConfig: AxiosRequestConfig
  ) => RequestConfigOrNone | Promise<RequestConfigOrNone> | void;

  /**
   * Called after every request.
   * This hook could be useful if you want perform additional work after request,
   * or override pure server response.
   */
  readonly onAfterRequest?: (
    this: HTTPClient,
    response: AxiosResponse
  ) => AxiosResponse | Promise<AxiosResponse> | void;

  /**
   * Called after onAfterRequest hook.
   * This hook could be useful if you want
   * extract server response in some, specific way.
   */
  readonly onTransformResponse?: (
    this: HTTPClient,
    response: AxiosResponse
  ) => object | Promise<object> | void;

  /**
   * Called on network error.
   * This hook could be useful if you want
   * to catch and do smth with network error object.
   */
  readonly onCatchNetworkError?: (
    this: HTTPClient,
    response: AxiosError
  ) => object | Promise<object> | void;
}

/**
 * HTTP Client created as wrapper of axios library.
 * Can be used to performs post/get/put/delete http methods.
 * It has few hooks:
 * `onBeforeRequest`
 * `onAfterRequest`
 * `onTransformResponse`
 * `onCatchNetworkError`
 * that would be called on every request.
 * This hooks should be passed in constructor.
 * All of the hooks is optional.
 */
class HTTPClient {
  public readonly config: HTTPClientConfig;
  private HttpClient!: AxiosInstance;

  constructor(config: HTTPClientConfig) {
    this.config = config;

    _.bindAll(this, [
      'onBeforeRequest',
      'onAfterRequest',
      'onTransformResponse',
      'onCatchNetworkError'
    ]);

    this.HttpClient = axios.create(this.config);
  }

  /**
   * Override default config for current instance.
   */
  public extendDefaults(config: AxiosRequestConfig) {
    _.merge(this.HttpClient.defaults, config);

    return this;
  }

  /**
   * Performs pure request without calling any hooks.
   */
  public request(requestConfig: AxiosRequestConfig): Promise<any> {
    return this.HttpClient.request(requestConfig);
  }

  /**
   * Performs `get` http method with call of all existing hooks.
   */
  public get(url: string, params?: object, requestConfig?: AxiosRequestConfig) {
    return this.makeRequest({ params, url, ...requestConfig });
  }

  /**
   * Performs `delete` http method with call of all existing hooks.
   */
  public delete(
    url: string,
    params?: object,
    requestConfig?: AxiosRequestConfig
  ) {
    return this.makeRequest({
      method: 'delete',
      data: params,
      url,
      ...requestConfig
    });
  }

  /**
   * Performs `post` http method with call of all existing hooks.
   */
  public post(
    url: string,
    params?: object,
    requestConfig?: AxiosRequestConfig
  ) {
    return this.makeRequest({
      data: params,
      method: 'post',
      url,
      ...requestConfig
    });
  }

  /**
   * Performs `put` http method with call of all existing hooks.
   */
  public put(url: string, params?: object, requestConfig?: AxiosRequestConfig) {
    return this.makeRequest({
      data: params,
      method: 'put',
      url,
      ...requestConfig
    });
  }

  /**
   * Performs request with call of all existing hooks.
   */
  public async makeRequest(requestConfig: AxiosRequestConfig): Promise<any> {
    const configOrNone = await this.onBeforeRequest(requestConfig);

    return this.HttpClient.request({
      ...requestConfig,
      ...configOrNone
    })
      .then(this.onAfterRequest)
      .then(this.onTransformResponse)
      .catch(this.onCatchNetworkError);
  }

  private onBeforeRequest(
    requestConfig: AxiosRequestConfig
  ): RequestConfigOrNone | Promise<RequestConfigOrNone> | void {
    const { onBeforeRequest } = this.config;

    if (_.isFunction(onBeforeRequest)) {
      return onBeforeRequest.call(this, requestConfig);
    }
  }

  private async onAfterRequest(
    response: AxiosResponse
  ): Promise<AxiosResponse> {
    const { onAfterRequest } = this.config;

    if (_.isFunction(onAfterRequest)) {
      const result = await onAfterRequest.call(this, response);

      if (result) {
        return result;
      }
    }

    return response;
  }

  private async onTransformResponse(response: AxiosResponse): Promise<object> {
    const { onTransformResponse } = this.config;

    if (_.isFunction(onTransformResponse)) {
      const result = await onTransformResponse.call(this, response);

      if (result) {
        return result;
      }
    }

    return response;
  }

  private async onCatchNetworkError(
    networkError: AxiosError
  ): Promise<object | void> {
    const { onCatchNetworkError } = this.config;

    if (_.isFunction(onCatchNetworkError)) {
      const result = await onCatchNetworkError.call(this, networkError);

      if (result) {
        return result;
      }
    }

    throw networkError;
  }
}

export default HTTPClient;
