import React from 'react';
import { formatMinutesAsHoursMinutes } from 'lib/helpers';
import { connect } from 'react-redux';
import { StoreState } from '../../../../../../state/types';
import { getTotalDuration } from '../../../../../../state/EmployeeDashboard/MyTimesheet/selectors';
import { Text } from 'elmo-elements';
import './MyTimesheetTotal.scss';

type OwnProps = {};

type StateProps = {
  total: number;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

const MyTimesheetTotalComponent = ({ total }: Props) => {
  return !!total ? (
    <div className="my-timesheet-total">
      <Text color="gray" size="sm" className="my-timesheet-total__label">
        Total hours
      </Text>
      {formatMinutesAsHoursMinutes(total)}
    </div>
  ) : null;
};

const mapStateToProps = (state: StoreState): StateProps => ({
  total: getTotalDuration(state)
});

// const mapDispatchToProps: DispatchProps = {};

export const MyTimesheetTotal = connect(mapStateToProps)(
  MyTimesheetTotalComponent
);
