import React, { Component } from 'react';
import { EventCard } from '../../../../components';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import moment from 'moment';
import { getAccountTree } from 'state/AccountTree';
import { getRosteredShifts, getSiteId } from 'state/RosteredShifts';
import {
  currentTimeBySiteTimezoneSelector,
  getIsApprovalModeEnabled,
} from 'state/Roster/Roster/selectors';
import { getWeekDays } from 'state/Roster/RosterWeekView';
import { OwnProps, StateProps } from './types';
import { getCurrentUserPermissions, hasPermissionSelector } from 'state/Auth';
import {
  getCurrencyCode,
  getCurrencyPlacement,
  getLangPreferences,
  getNumberFormat,
} from 'state/Account';
import { getIsBulkDeleteOpened } from 'state/Roster/BulkActions/selectors';
import { CollapsableEventsRowView } from './CollapsableEventsRowView';
import { Event, EventType } from 'type/models';
import { getEvents } from 'state/Events/selectors';

type Props = OwnProps & StateProps;

export class RosterEventsSingleRowClass extends Component<Props> {
  render() {
    return (
      <CollapsableEventsRowView
        data={this.props.data}
        events={this.props.events}
        canShowModal={this.canShowModal}
        weekDays={this.props.weekDays}
        langPreferences={this.props.langPreferences}
        currencyCode={this.props.currencyCode}
        currencyPlacement={this.props.currencyPlacement}
        getData={this.getEventDataByFilledId}
        emptyCellClick={this.emptyCellClick}
        numberFormat={this.props.numberFormat}
        showEventCard={this.showEventCard}
        currentTimeBySiteTimezone={this.props.currentTimeBySiteTimezone}
      />
    );
  }

  canShowModal = () => {
    const { isApproveMode, canEditEvents, isBulkDelete } = this.props;
    return !isApproveMode && canEditEvents && !isBulkDelete;
  };

  getEventDataByFilledId = (data: any, key: null | undefined | string) => {
    if (typeof key !== 'undefined' && key !== null) {
      return data[key];
    }
    return null;
  };

  existEventClick = (shift: Event) => {
    if (this.canShowModal()) {
      const props = this.eventProps(shift);
      this.props.onClickCell(props);
    }
    return false;
  };

  cellProps = (day: string) => {
    const tz = this.props.sites[this.props.siteId].timezone_id;
    return {
      start: moment(+day)
        .startOf('day')
        .tz(tz),
      end: moment(+day)
        .endOf('day')
        .tz(tz),
      siteId: this.props.siteId,
      type: 'event' as EventType,
    };
  };

  eventProps = (shift: Event) => {
    const tz = this.props.sites[this.props.siteId].timezone_id;
    return {
      id: shift.id,
      start: moment(shift.start).tz(tz).startOf('day'),
      end: moment(shift.end).tz(tz).endOf('day'),
      siteId: this.props.siteId,
      type: shift.type,
    };
  };

  emptyCellClick = (day: string): boolean => {
    const { isApproveMode } = this.props;
    if (isApproveMode) {
      return false;
    }
    this.triggerEmptyEventModal(day);
    return true;
  };

  triggerEmptyEventModal = (day: string) => {
    if (this.canShowModal()) {
      const props = this.cellProps(day);
      const start = props.start.clone().startOf('D');
      const end = props.start.clone().endOf('D');
      this.props.onClickCell({ ...props, start, end });
    }
  };

  showEventCard = (rowNumber: number, dayIndex: number) => {
    const {
      data: { rosters },
      events,
    } = this.props;
    const currentEvent = rosters[rowNumber][dayIndex];
    const event =
      currentEvent && currentEvent.id
        ? this.getEventDataByFilledId(events, currentEvent.id)
        : null;

    if (event) {
      return <EventCard eventId={event.id} hasAddButton={true} />;
    }

    return null;
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  ...getAccountTree(state),
  siteId: getSiteId(state),
  rosteredShifts: getRosteredShifts(state),
  weekDays: getWeekDays(state),
  isApproveMode: getIsApprovalModeEnabled(state),
  events: getEvents(state),
  permissions: getCurrentUserPermissions(state),
  canEditEvents: hasPermissionSelector(state, 'roster.events.edit'),
  numberFormat: getNumberFormat(state),
  currencyCode: getCurrencyCode(state),
  currencyPlacement: getCurrencyPlacement(state),
  isBulkDelete: getIsBulkDeleteOpened(state),
  langPreferences: getLangPreferences(state),
  currentTimeBySiteTimezone: currentTimeBySiteTimezoneSelector(state),
});

export const RosterEventsSingleRow = connect(mapStateToProps)(
  RosterEventsSingleRowClass
);
