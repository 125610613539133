import { Handler } from './types';

type HandlerCreator<P> = (next: Handler<P>) => Handler<P>;

const composeHandlers =
  <P>(...handlers: HandlerCreator<P>[]) =>
    (last: Handler<P>): Handler<P> =>
      handlers.reduceRight(
        (composedHandler, currentHandler) => currentHandler(composedHandler),
        last
      );

export default composeHandlers;
