import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FormItem, SelectList } from 'elmo-elements';
import { LanguagePreferences, SelectPropsOption } from 'type';
import { keyMirror, capitalize } from 'lib/helpers';
import { PageDialog, PageDialogCancel, PageDialogSubmit } from 'element';
import { StoreState } from 'state/types';
import { getLangPreferences } from 'state/Account';
import {
  BOX_SITE_FLAT_AREA_ROLE_MODAL_CHANGE_AREAS,
  BOX_SITE_FLAT_AREA_ROLE_MODAL_CHANGE_ROLES,
  BOX_SITE_FLAT_AREA_ROLE_MODAL_CLOSE,
  BOX_SITE_FLAT_AREA_ROLE_MODAL_SUBMIT,
  ChangeSelectedPayload,
  filteredAreasSelectOptionsSelector,
  filteredRolesSelectOptionsSelector,
  getIsOpened,
  getIsResetButtonEnabled,
  getSelectedAreaIds,
  getSelectedRoleIds,
  SelectedIds,
  SubmitPayload,
} from 'state/FlatAreaRoleModal';
import { DialogTitle } from 'oxygen-elements';
import { DialogContent, Button, DialogActions } from 'extended-oxygen-elements';
import { MultiSelect } from '../../../../breathe-elements';

type OwnProps = {};

type StateProps = {
  isOpened: boolean;
  isResetButtonEnabled: boolean;
  selectedAreaIds: SelectedIds;
  selectedRoleIds: SelectedIds;
  langPreferences: LanguagePreferences;
  filteredAreasSelectOptions: SelectPropsOption[];
  filteredRolesSelectOptions: SelectPropsOption[];
};

type DispatchProps = {
  closeModal: () => void;
  onSubmit: (payload: SubmitPayload) => void;
  onChangeAreaIds: (payload: ChangeSelectedPayload) => void;
  onChangeRoleIds: (payload: ChangeSelectedPayload) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const FlatAreaRoleModalComponent = ({
  isOpened,
  isResetButtonEnabled,
  closeModal,
  langPreferences,
  onSubmit,
  selectedAreaIds,
  selectedRoleIds,
  filteredAreasSelectOptions,
  filteredRolesSelectOptions,
  onChangeAreaIds,
  onChangeRoleIds,
}: Props) => {
  const selectHandlers = {
    areaIds: onChangeAreaIds,
    roleIds: onChangeRoleIds,
  };

  const [selectNames] = useState(keyMirror(selectHandlers));

  const handleSubmit = () => {
    onSubmit({
      selectedAreaIds,
      selectedRoleIds,
    });
  };

  const handleSelectChange = (payload: { name: string; value: string[] }) => {
    const handler = selectHandlers[payload.name as keyof typeof selectHandlers];
    handler(payload.value);
  };

  const handleReset = () => {
    onSubmit({
      selectedRoleIds: [],
      selectedAreaIds: [],
    });
  };

  return (
    <PageDialog
      id="flat-area-role-filter-modal"
      maxWidth="xs"
      open={isOpened}
      onClose={closeModal}
    >
      <DialogTitle>{`${capitalize(
        langPreferences.role.plural
      )} filter`}</DialogTitle>
      <DialogContent>
        <FormItem label={`Filter by ${langPreferences.area.plural}`}>
          <MultiSelect
            id="flat-area-role-filter-modal-areas"
            name={selectNames.areaIds}
            selectedIds={selectedAreaIds}
            ariaLabel="Filter by departments"
            selectOptions={filteredAreasSelectOptions}
            handleSelectChange={handleSelectChange}
          />
        </FormItem>

        <FormItem label={`Filter by ${langPreferences.role.plural}`}>
          <MultiSelect
            id="flat-area-role-filter-modal-roles"
            name={selectNames.roleIds}
            selectedIds={selectedRoleIds}
            ariaLabel="Filter by roles"
            selectOptions={filteredRolesSelectOptions}
            handleSelectChange={handleSelectChange}
          />
        </FormItem>

        <Button
          disabled={!isResetButtonEnabled}
          onClick={handleReset}
          id="flat-area-role-filter-modal-reset-btn"
        >
          Reset
        </Button>
      </DialogContent>
      <DialogActions>
        <PageDialogCancel onClick={closeModal}>Cancel</PageDialogCancel>
        <PageDialogSubmit onClick={handleSubmit}>Ok</PageDialogSubmit>
      </DialogActions>
    </PageDialog>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  isOpened: getIsOpened(state),
  isResetButtonEnabled: getIsResetButtonEnabled(state),
  selectedAreaIds: getSelectedAreaIds(state),
  selectedRoleIds: getSelectedRoleIds(state),
  langPreferences: getLangPreferences(state),
  filteredAreasSelectOptions: filteredAreasSelectOptionsSelector(state),
  filteredRolesSelectOptions: filteredRolesSelectOptionsSelector(state),
});

const mapDispatchToProps: DispatchProps = {
  onSubmit: BOX_SITE_FLAT_AREA_ROLE_MODAL_SUBMIT,
  closeModal: BOX_SITE_FLAT_AREA_ROLE_MODAL_CLOSE,
  onChangeAreaIds: BOX_SITE_FLAT_AREA_ROLE_MODAL_CHANGE_AREAS,
  onChangeRoleIds: BOX_SITE_FLAT_AREA_ROLE_MODAL_CHANGE_ROLES,
};

export const FlatAreaRoleModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(FlatAreaRoleModalComponent);
