import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import { StringMap, UserFields, UserProfileFields } from 'type';
import {
  ApprovalsModal,
  ApprovalsModalProps
} from '../../../../../../../../components/ApprovalsModal';
import {
  getEditApprovalsIsOpened,
  getErrors,
  getIsUpdating,
  getUserProfile
} from '../../../../../../../../../../state/Users/UserProfile/selectors';
import {
  BOX_USER_PROFILE_EDIT_APPROVALS_MODAL_CLOSE,
  BOX_USER_PROFILE_EDIT_APPROVALS_REQUEST
} from '../../../../../../../../../../state/Users/UserProfile/actions';
import { EditApproversPayload } from '../../../../../../../../components/ApprovalsModal/types';
import { userListSelector } from '../../../../../../../../../../state/UsersCollection';

type StateProps = Pick<
  ApprovalsModalProps,
  'isOpened' | 'isUpdating' | 'errors'
> & {
  userProfile: UserProfileFields;
  userList: StringMap<UserFields>;
};

type DispatchProps = Pick<ApprovalsModalProps, 'onCloseModal'> & {
  onFormSubmit: (data: EditApproversPayload) => void;
};

export type Props = StateProps & DispatchProps;

export const UserProfileApprovalsModalComponent = ({
  userProfile,
                                              userList,
  ...restProps
}: Props) => (
  <ApprovalsModal
    {...restProps}
    managers={userList[userProfile.id].managers}
    userIds={[userProfile.id]}
  />
);

export const mapStateToProps = (state: StoreState): StateProps => ({
  userProfile: getUserProfile(state),
  isOpened: getEditApprovalsIsOpened(state),
  isUpdating: getIsUpdating(state),
  errors: getErrors(state),
  userList: userListSelector(state)
});

const mapDispatchToProps: DispatchProps = {
  onCloseModal: BOX_USER_PROFILE_EDIT_APPROVALS_MODAL_CLOSE,
  onFormSubmit: BOX_USER_PROFILE_EDIT_APPROVALS_REQUEST
};
export const UserProfileApprovalsModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileApprovalsModalComponent);
