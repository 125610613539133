import React from 'react';
import { withDataTableContext } from '../DataTableContextConsumer';
import './DataTableSelect.scss';

type DataTableSelectProps = {
  /** @ignore Comes from DataTable Context. */
  countItemsAvailable?: number;
  /** @ignore Comes from DataTable Context. */
  isAllAvailableItemsSelected?: boolean;
  /** @ignore Comes from DataTable Context. */
  countSelectable?: number;
  /** @ignore Comes from DataTable Context. */
  onSelectAllAvailableToggle?: (isSelect: boolean) => void;
  /** @ignore Comes from DataTable Context. */
  setContextState?: (state: any) => void;
};

function DataTableSelectAllMessage({ children }: any) {
  return <div className="elmo-datatable__select-message">{children}</div>;
}

/**
 * Provides Select All Items functionality.
 * Does not need to be created by the user, created in DataTableHeader.
 */
class DataTableSelect extends React.Component<DataTableSelectProps> {
  /**
   * Called when Select All / Cancel clicked
   * @param isSelect
   */
  handleSelectAll = (isSelect: boolean) => () => {
    if (isSelect) {
      this.onSelectAvailable();
    } else {
      this.onCancel();
    }
  };

  /**
   * Called when Select All Available clicked
   */
  onSelectAvailable = () => {
    const { onSelectAllAvailableToggle } = this.props;
    if (onSelectAllAvailableToggle) {
      onSelectAllAvailableToggle(true);
    }
  };

  /**
   * Called when "Cancel" clicked in Select All Available component
   */
  onCancel = () => {
    const { setContextState, onSelectAllAvailableToggle } = this.props;

    if (setContextState) {
      setContextState({
        isSelectAllChecked: false,
        showSelectAllComponent: false,
      });
    }

    if (onSelectAllAvailableToggle) {
      onSelectAllAvailableToggle(false);
    }
  };

  render() {
    const {
      countItemsAvailable,
      isAllAvailableItemsSelected,
      countSelectable,
    } = this.props;

    if (isAllAvailableItemsSelected) {
      return (
        <DataTableSelectAllMessage>
          All {countItemsAvailable} items are selected.&nbsp;
          <span onClick={this.handleSelectAll(false)}>Clear Selection</span>
        </DataTableSelectAllMessage>
      );
    }

    return (
      <DataTableSelectAllMessage>
        All {countSelectable} items selected on this page.&nbsp;
        <span onClick={this.handleSelectAll(true)}>
          Select all {countItemsAvailable} items.
        </span>
      </DataTableSelectAllMessage>
    );
  }
}

export default withDataTableContext(DataTableSelect);
