import React from 'react';
import NativeTableWrapper from './component/NativeTableWrapper';
import NativeTableBody from './component/NativeTableBody';
import NativeTableHeader from './component/NativeTableHeader';
import NativeTableTd from './component/NativeTableTd';
import NativeTableTh from './component/NativeTableTh';
import NativeTableTr from './component/NativeTableTr';
import { NativeTableProps, NativeTableRenderNode } from './type';

function NativeTable({
  children,
  id,
  className,
  data,
  isResponsive,
  columns,
  theme,
  isFullWidth,
  isFirstColumnSticky,
  isHeaderSticky,
  isTableFixed,
}: NativeTableProps) {
  const props = {
    id,
    className,
    isResponsive,
    theme,
    isFullWidth,
    isFirstColumnSticky,
    isHeaderSticky,
    isTableFixed,
  };

  if (typeof children === 'function') {
    return (
      <NativeTableWrapper {...props}>
        {columns && (
          <NativeTableHeader>
            <NativeTableTr>
              {columns.map((column: any, index: number) => (
                <NativeTableTh key={index}>{column}</NativeTableTh>
              ))}
            </NativeTableTr>
          </NativeTableHeader>
        )}
        {data && (
          <NativeTableBody>
            {data.map((item: any, index: number) =>
              (children as NativeTableRenderNode)(item, index)
            )}
          </NativeTableBody>
        )}
      </NativeTableWrapper>
    );
  }

  return <NativeTableWrapper {...props}>{children}</NativeTableWrapper>;
}

// Assign the compound parts
NativeTable.Body = NativeTableBody;
NativeTable.Header = NativeTableHeader;
NativeTable.Td = NativeTableTd;
NativeTable.Th = NativeTableTh;
NativeTable.Tr = NativeTableTr;

export default NativeTable;
