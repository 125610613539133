import { useSelectorWithProps } from 'hooks';
import { filteredUserTimesheetsTotalDurationSelector } from 'state/TimesheetsWeeklyPage';
import useTimesheetsNewQueryStatus from './useTimesheetsNewQueryStatus';

export function useFilteredUserTimesheetsTotalDuration(userId: string) {
  const status = useTimesheetsNewQueryStatus();

  return useSelectorWithProps(filteredUserTimesheetsTotalDurationSelector, {
    userId,
    status,
  });
}

export default useFilteredUserTimesheetsTotalDuration;
