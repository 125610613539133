import { createReducer } from 'lib/store-utils';
import * as accountTreeState from 'state/AccountTree';
import { Assignments } from 'type';
import { AssignmentsReducerState } from './types';

export const assignments = createReducer<AssignmentsReducerState>(
  {},
  {
    assignments: {
      sites: {},
      site_ids: [],
    },
  }
);

const handleAssignmentUpdated = (
  state: AssignmentsReducerState,
  { assignments }: { assignments: Assignments }
): AssignmentsReducerState => ({
  assignments,
});

assignments
  .on(
    accountTreeState.BOX_ACCOUNT_TREE_PAGE_DATA_GET.success,
    handleAssignmentUpdated
  )
  .on(
    accountTreeState.BOX_ACCOUNT_TREE_AREAS_LINK.success,
    handleAssignmentUpdated
  )
  .on(
    accountTreeState.BOX_ACCOUNT_TREE_ROLES_LINK.success,
    handleAssignmentUpdated
  )
  .on(
    accountTreeState.BOX_ACCOUNT_TREE_ROLES_UNLINK.success,
    handleAssignmentUpdated
  );
