import { Typography } from 'extended-oxygen-elements';
import { DurationHoursRequiredField } from 'element';
import FormControlSpacing from 'element/FormControlSpacing';
import { useSetCheckbox, useSetFormField } from 'hooks';
import marketLabels from 'marketLabels';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from 'extended-oxygen-elements';
import React from 'react';
import { FieldGroupProps } from 'type';
import {
  HelperTextWrapper,
  InlineInputWrapper,
} from 'page/Settings/components';
import { ShiftSwapsFieldGroupErrors, ShiftSwapsFieldGroupValue } from './types';

type Props = FieldGroupProps<
  ShiftSwapsFieldGroupValue,
  ShiftSwapsFieldGroupErrors
>;

export default ShiftSwapsFieldGroup;

export function ShiftSwapsFieldGroup({ values, onChange }: Props) {
  const { makeSetCheckbox } = useSetCheckbox(onChange);
  const { makeSetFormField } = useSetFormField(onChange);

  return (
    <>
      <Typography variant="h4" component="h2">
        Shift swaps
      </Typography>

      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.shift_swaps}
              onChange={makeSetCheckbox('shift_swaps')}
            />
          }
          label="Enable shift swaps"
        />

        <HelperTextWrapper>
          <FormHelperText fontSize="medium">
            Allow people to swap shifts before their shift is due to start.
          </FormHelperText>
        </HelperTextWrapper>
      </FormControl>

      <FormControlSpacing flexDirection="column">
        <Typography variant="h6" component="h3">
          Shift swap time threshold
        </Typography>
        <Typography variant="body1" component="h6">
          Allow people to swap a shift up to
          <InlineInputWrapper>
            <DurationHoursRequiredField
              inputProps={{
                size: 4,
              }}
              margin="none"
              fullWidth={false}
              formatUnit="hours"
              defaultUnit="hours"
              hoursPrefix="hrs"
              value={values.users_swap_hours}
              onValueChange={makeSetFormField('users_swap_hours')}
            />
          </InlineInputWrapper>
          before the {marketLabels.rosteredShift} starts.
        </Typography>
      </FormControlSpacing>

      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.shift_swap_responses_must_be_approved}
              onChange={makeSetCheckbox(
                'shift_swap_responses_must_be_approved'
              )}
            />
          }
          label="Approve shift swaps"
        />

        <HelperTextWrapper>
          <FormHelperText fontSize="medium">
            Any shift swap must be approved by the relevant line manager.
          </FormHelperText>
        </HelperTextWrapper>
      </FormControl>
    </>
  );
}
