import { call, select } from 'redux-saga/effects';
import { getHasShift } from '../../selectors';
import { Handler } from './types';

const shiftNotInStateHandler = <P extends { id: string }>(
  next: Handler<P>
): Handler<P> =>
  function* (payload) {
    const { id: shiftId } = payload;
    const hasShift: ReturnType<typeof getHasShift> = yield select(getHasShift, {
      shiftId,
    });

    if (!hasShift) {
      // Do nothing if there is no shift in store state
      return;
    }

    yield call(next, payload);
  };

export default shiftNotInStateHandler;
