import React from 'react';
import TimesheetModal from '../../../../../../element/shiftModals/TimesheetModal';
import { StoreState } from '../../../../../../state/types';
import { connect } from 'react-redux';
import { currentUserMainSiteIdSelector } from '../../../../../../state/Auth';
import { BOX_TIMESHEET_SHIFT_MODAL_CREATE_SELF_ASSIGNED_SHIFT } from '../../../../../../state/TimesheetModal';
import { CreateSelfAssignedShiftPayload } from '../../../../../../state/TimesheetModal/types';

type OwnProps = {};

type StateProps = {
  siteId: string | undefined;
};

type DispatchProps = {
  createTimesheet: (t: CreateSelfAssignedShiftPayload) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const MyTimesheetModalsComponent = ({ siteId, createTimesheet }: Props) => (
  <>
    {!!siteId && (
      <TimesheetModal siteId={siteId} createTimesheet={createTimesheet} isEmployee={true} />
    )}
  </>
);

const mapStateToProps = (state: StoreState): StateProps => ({
  siteId: currentUserMainSiteIdSelector(state)
});

const mapDispatchToProps: DispatchProps = {
  createTimesheet: BOX_TIMESHEET_SHIFT_MODAL_CREATE_SELF_ASSIGNED_SHIFT
};

export const MyTimesheetModals = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyTimesheetModalsComponent);
