import { StoreState } from 'state/types';

const getState = (state: StoreState) => state.payElementsCollection;
export const getPayElements = (state: StoreState) => getState(state).byId;
export const getPayElement = (state: StoreState, id: string) =>
  getPayElements(state)[id];
export const getPayElementIds = (state: StoreState) => getState(state).allIds;

export const getDefaultPayElementId = (state: StoreState) => {
  const payElementIds = getPayElementIds(state);
  const payElements = getPayElements(state);

  return payElementIds.find(
    (payElementId) => payElements[payElementId].is_default
  );
};
