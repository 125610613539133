import React, { Component } from 'react';
import './style.scss';
import SummaryButton from './components/SummaryButton';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import {
  capitalize,
  formatMinutesAsHours,
  getCurrencyFormatted,
} from 'lib/helpers';
import {
  getSummary,
  getSummaryLoading,
  getSummaryStatus,
} from 'state/Roster/Summary/selectors';
import {
  BOX_SUMMARY_REMOVE_ERRORS,
  BOX_SUMMARY_SET_STATUS,
} from 'state/Roster/Summary/actions';
import { DispatchProps, OwnProps, StateProps } from './types';
import { hasPermissionSelector } from 'state/Auth';
import {
  getCurrencyCode,
  getCurrencyPlacement,
  getLangPreferences,
  getNumberFormat,
} from 'state/Account';
import { RosterSummary } from '../../../RosterDayView/components/RosterSummary';
import { SummaryData } from './components/SummaryData';
import SummaryInfo from './components/SummaryData/components/SummaryInfo';
import { filtersByTypeSelector } from 'state/Roster/RosterFilters';
import { fromBySiteTimezoneSelector } from '../../../../../../state/RosteredShifts';
import { SERVER_DAY_FORMAT } from 'lib/config';
import { isAppMarket } from '../../../../../../helpers';

type Props = OwnProps & StateProps & DispatchProps;

export class DaySummary extends Component<Props> {
  render() {
    const {
      filters: { timesheets, roster },
      isLoading,
    } = this.props;

    return <RosterSummary>{this.getContent()}</RosterSummary>;
  }

  getContent = () => {
    const { status, isLoading } = this.props;

    return (
      <div className="day-summary">
        <div
          className={getClasses('day-summary__wrap', {
            'day-summary__wrap--hidden': status === 'hidden',
          })}
        >
          <SummaryButton />
          {status !== 'hidden' && !isLoading && (
            <div className="day-summary__data">{this.getTotal()}</div>
          )}
        </div>
      </div>
    );
  };

  getTotal = () => {
    const {
      rosterPermission,
      timesheetPermission,
      filters: { timesheets, roster },
    } = this.props;
    return (
      <SummaryData>
        <div className="day-summary__data-summary">
          {rosterPermission &&
            roster &&
            this.getSummaryLegendInfo('rostered_shift')}
          {timesheetPermission &&
            timesheets &&
            this.getSummaryLegendInfo('timesheet')}
        </div>
      </SummaryData>
    );
  };

  getSummaryLegendInfo = (type: 'rostered_shift' | 'timesheet') => {
    const {
      summary: { days },
      langPreferences,
      from,
    } = this.props;
    const rosterText = isAppMarket('uk')
      ? 'Rota'
      : capitalize(langPreferences.roster.singular);
    const title = type === 'timesheet' ? 'Timesheet' : rosterText;
    const day: { [key: string]: number } = days[from.format(SERVER_DAY_FORMAT)];

    const data = {
      type,
      shiftCount: day[`${type}_count`],
      userCount: day[`${type}_user_count`],
      duration: this.getDuration(day[`${type}_duration`]),
      currency: this.getCurrency(day[`${type}_cost`], type),
      title,
      className:
        type === 'timesheet'
          ? 'summary-info__timesheet'
          : 'summary-info__shift',
    };

    return <SummaryInfo {...data} />;
  };

  getCurrency = (value: number, type: string) => {
    const { rosterCostPermission, timesheetCostPermission } = this.props;
    const { numberFormat, currencyCode, currencyPlacement } = this.props;
    if (type === 'rostered_shift' && !rosterCostPermission) {
      return null;
    }
    if (type === 'timesheet' && !timesheetCostPermission) {
      return null;
    }
    return (
      getCurrencyFormatted(
        numberFormat,
        currencyCode,
        currencyPlacement,
        value,
        true
      ) + ' '
    );
  };

  getDuration = (value: number = 66) => {
    return formatMinutesAsHours(value) + 'h';
  };

  shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return this.props !== nextProps;
  }

  componentWillUnmount(): void {
    this.props.setStatus('hidden');
  }
}

export function getClasses(
  className: string,
  classNames: { [key: string]: boolean | undefined }
) {
  const classes = Object.keys(classNames).map((key) => {
    if (classNames[key]) {
      return key;
    }
  });
  return `${className} ${classes.join(' ')}`;
}

const mapStateToProps = (state: StoreState): StateProps => ({
  summary: getSummary(state),
  from: fromBySiteTimezoneSelector(state),
  timesheetPermission: hasPermissionSelector(state, 'roster.timesheet.view'),
  rosterPermission: hasPermissionSelector(state, 'roster.rosteredshift.view'),
  timesheetCostPermission: hasPermissionSelector(
    state,
    'roster.timesheet.view.labourcostings'
  ),
  timesheetPercentPermission: hasPermissionSelector(
    state,
    'roster.timesheet.view.labourpercentages'
  ),
  rosterCostPermission: hasPermissionSelector(
    state,
    'roster.rosteredshift.view.labourcostings'
  ),
  rosterPercentPermission: hasPermissionSelector(
    state,
    'roster.rosteredshift.view.labourpercentages'
  ),
  eventPermission: hasPermissionSelector(state, 'roster.events.view'),
  numberFormat: getNumberFormat(state),
  currencyCode: getCurrencyCode(state),
  currencyPlacement: getCurrencyPlacement(state),
  status: getSummaryStatus(state),
  langPreferences: getLangPreferences(state),
  filters: filtersByTypeSelector(state),
  isLoading: getSummaryLoading(state),
});

const mapToDispatchProps: DispatchProps = {
  removeErrors: BOX_SUMMARY_REMOVE_ERRORS,
  setStatus: BOX_SUMMARY_SET_STATUS,
};

export default connect(mapStateToProps, mapToDispatchProps)(DaySummary);
