import React, { Component } from 'react';
import { isAppMarket } from 'helpers';
import { PageDialog, PageDialogCancel, PageDialogSubmit } from 'element';
import { DialogTitle, Typography } from 'oxygen-elements';
import { DialogContent, DialogActions } from 'extended-oxygen-elements';

type Props = {
  isOpened: boolean;
  closeModal: () => void;
  confirmAction: () => void;
};

export class ConfirmationModal extends Component<Props> {
  render() {
    return (
      <PageDialog
        id={'delete-confirmation-modal'}
        maxWidth="xs"
        open={this.props.isOpened}
        onClose={this.props.closeModal}
      >
        <DialogTitle>Confirm?</DialogTitle>
        <DialogContent>
          <Typography variant={'body1'} className={'confirmation-text'}>
            If you delete this {isAppMarket('uk') ? '' : 'roster'} shift a
            related timesheet will become self-assigned
          </Typography>
        </DialogContent>
        <DialogActions>
          <PageDialogCancel onClick={this.props.closeModal}>
            Cancel
          </PageDialogCancel>

          <PageDialogSubmit
            id={'confirm-delete-btn'}
            onClick={this.props.confirmAction}
          >
            Yes, confirm it
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  }
}
