import * as React from 'react';
import moment, { Moment } from 'moment';
import { secondsToHours } from 'lib/helpers';

type Props = {
  date: Moment;
};

type State = {
  time: number,
  date: Moment
}

class Timer extends React.Component <Props, State> {

  interval: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      date: props.date,
      time: moment().diff(props.date) / 1000
    }
    this.start();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.date !== this.props.date) {
      this.setState({
        date: this.props.date,
        time: moment().diff(this.props.date) / 1000,
      })
    }
  }

  componentWillUnmount(): void {
    clearInterval(this.interval);
  }

  renderTimer() {
    return (
      <>
        {this.renderTime()}
      </>
     )
  }

  render() {
    return <>{this.renderTimer()}</>
  }

  private start = () => {
    this.interval = setInterval(() => {
      this.setState({
        time: this.state.time + 1
      })
    }, 1000);
  }

  private renderTime = () => {
    const { time } = this.state;
    return secondsToHours(time);
  }

}

export default Timer;
