import { FormattedErrors, Search } from 'type';
import { StoreState } from 'state/types';
import { ManagerDashboardFilters } from './types';
import { createSelector } from 'reselect';

export const getIsOpened = (state: StoreState): boolean =>
  state.managerDashboard.managerDashboard.isOpened;
export const getIsUpdating = (state: StoreState): boolean =>
  state.managerDashboard.managerDashboard.isUpdating;
export const getErrors = (state: StoreState): FormattedErrors =>
  state.managerDashboard.managerDashboard.errors;
export const getRequestedFilters = (
  state: StoreState
): ManagerDashboardFilters =>
  state.managerDashboard.managerDashboard.requestedFilters;
export const getFetchedFilters = (state: StoreState): ManagerDashboardFilters =>
  state.managerDashboard.managerDashboard.fetchedFilters;
export const getSearch = (state: StoreState): Search =>
  state.managerDashboard.managerDashboard.search;

export const managerDashboardFiltersSelector = createSelector<
  StoreState,
  boolean,
  ManagerDashboardFilters,
  ManagerDashboardFilters,
  ManagerDashboardFilters
>(
  getIsUpdating,
  getRequestedFilters,
  getFetchedFilters,
  (isUpdating, requestedFilters, fetchedFilters) =>
    isUpdating ? requestedFilters : fetchedFilters
);
