import {
  Checkbox,
  Chip,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Typography,
} from 'extended-oxygen-elements';
import _ from 'lodash';
import React from 'react';

export type LinkSelectProps<V = string[]> = {
  value: V;
  onChange: (value: V | ((prevState: V) => V)) => void;
  options: { value: string; label: string }[];
  optionName: { singular: string; plural: string };
};

export default function LinkSelect({
  value: propsValue,
  onChange,
  options,
  optionName,
}: LinkSelectProps) {
  const [isSelectOpen, setIsSelectOpen] = React.useState(false);

  const optionsByValue = _.keyBy(options, (option) => option.value);
  const optionValues = options.map((option) => option.value);
  const value = _.intersection(propsValue, optionValues);

  const isAllOptionsSelected = optionValues.length === value.length;

  const handleSelectOpen = () => {
    setIsSelectOpen(true);
  };

  const handleSelectClose = () => {
    setIsSelectOpen(false);
  };

  const handleSelectChange = ({
    target: { value: selectedValue },
  }: SelectChangeEvent<typeof value>) => {
    if (!Array.isArray(selectedValue)) {
      return;
    }

    onChange(selectedValue);
  };

  const handleSelectAllMenuItemClick = () => {
    onChange(isAllOptionsSelected ? [] : optionValues);
  };

  const makeHandleAreaDelete = (idToBeDeleted: string) => () => {
    onChange((prevState) => _.without(prevState, idToBeDeleted));
  };

  return (
    <>
      <Select
        fullWidth
        open={isSelectOpen}
        onOpen={handleSelectOpen}
        onClose={handleSelectClose}
        multiple
        displayEmpty
        onChange={handleSelectChange}
        value={value}
        renderValue={() => {
          if (isSelectOpen) {
            return '';
          }

          return `Select ${optionName.singular}`;
        }}
      >
        <MenuItem
          selected={isAllOptionsSelected}
          onClick={handleSelectAllMenuItemClick}
        >
          <ListItemText primary={`Select all ${optionName.plural}`} />
          <Switch checked={isAllOptionsSelected} sx={{ my: -0.5 }} />
        </MenuItem>
        {options.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            <Checkbox
              checked={value.includes(option.value)}
              sx={{ my: -0.5 }}
            />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
      {!!value.length && (
        <>
          <Typography variant="body2" color="textSecondary" gutterBottom mt={2}>
            Selected
          </Typography>
          {value.map((optionValue) => {
            const option = optionsByValue[optionValue];

            return (
              <Chip
                key={option.value}
                size="medium"
                color="primary"
                light
                label={option.label}
                onDelete={makeHandleAreaDelete(option.value)}
                sx={{ mr: 1 / 2, mb: 1 / 2 }}
              />
            );
          })}
        </>
      )}
    </>
  );
}
