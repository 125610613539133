import React, { ReactText } from 'react';
import {
  Select,
  FormContainer,
  FormItem,
  RadioButtons,
  Radio,
  Divider,
  Row,
  Col,
} from 'elmo-elements';
import { useSelector } from 'react-redux';
import { Data, DropDownProps } from '../type';
import { include } from 'state/Report/reportModalValues';
import { showDropDownOptions } from 'state/Account/selectors';
import { getSelectedValue } from '../helpers';

export type DataProps = {
  handleOnChangeSelect: (e: DropDownProps, name: string) => void;
  handleOnChangeTimeFormat: (value: ReactText) => void;
  data: Data;
};

const SaleExtraGroup = ({
  data,
  handleOnChangeTimeFormat,
  handleOnChangeSelect,
}: DataProps) => {
  const showOptions = useSelector(showDropDownOptions);

  return (
    <>
      <Divider />
      <br />
      <h3>View</h3>

      <FormContainer maxColumns={3} className="flex-start-align">
        <FormItem label={'Show'} isFluid={false}>
          <Select
            name={'show'}
            options={showOptions}
            onChange={(e) => handleOnChangeSelect(e, 'show')}
            defaultValue={getSelectedValue(showOptions, data.show)}
            value={getSelectedValue(showOptions, data.show)}
            ariaLabel="Show"
          />
        </FormItem>
        <FormItem label={'Includes'} isFluid={false}>
          <Select
            name={'sale_include'}
            options={include}
            onChange={(e) => handleOnChangeSelect(e, 'sale_include')}
            defaultValue={getSelectedValue(include, data.sale_include)}
            value={getSelectedValue(include, data.sale_include)}
            ariaLabel="Includes"
          />
        </FormItem>
        <FormItem isFluid={false}>
          <Row isNoGutters={true} role="presentation" className="row-two-cols">
            <Col span={12} role="presentation" className={'form-item-small'}>
              <FormItem label={'Time format'} isFluid={false} isInline={true}>
                <RadioButtons
                  selected={data.time_format}
                  onChange={handleOnChangeTimeFormat}
                  type={'outlined'}
                  direction={'horizontal'}
                >
                  <Radio value={'HH:mm'}>HH:MM</Radio>
                  <Radio value={'Decimal'}>Decimal</Radio>
                </RadioButtons>
              </FormItem>
            </Col>
          </Row>
        </FormItem>
      </FormContainer>
    </>
  );
};
export default SaleExtraGroup;
