import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import {
  BOX_FETCH_PAGE_DATA_CLEAR_IS_FETCHED,
  getFetchFlags,
  PageId,
} from 'state/FetchPageData';
import { CommonError } from '../CommonError';
import { WithPreload } from './WithPreload';

type OwnProps = {
  fetchData: () => void;
  clearState?: () => void;
  children: ReactNode;
  pageId: PageId;
};

type StateProps = {
  isFetching: boolean;
  isFetched: boolean;
};

type DispatchProps = {
  clearFetchedState: (pageId: PageId) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

class FetchWrapperComponent extends Component<Props> {
  render() {
    const { isFetched, children, ...withPreloadProps } = this.props;

    return (
      <WithPreload {...withPreloadProps}>
        {isFetched ? children : <CommonError />}
      </WithPreload>
    );
  }

  componentWillUnmount(): void {
    const { pageId, clearFetchedState, clearState } = this.props;

    clearFetchedState(pageId);

    if (clearState) {
      clearState();
    }
  }
}

const mapStateToProps = (state: StoreState, { pageId }: OwnProps): StateProps =>
  getFetchFlags(state, pageId);

const mapDispatchToProps: DispatchProps = {
  clearFetchedState: BOX_FETCH_PAGE_DATA_CLEAR_IS_FETCHED,
};

export const FetchWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(FetchWrapperComponent);
