import React, { Component } from 'react';
import { ColumnsProps } from '../../type';
import { NativeTable, Heading, Button } from 'elmo-elements';
import { ViewColumnOutlinedIcon, SortDescIcon, SortAscIcon } from 'element';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import {
  getCurrentPageRows,
  getGroups,
  getReportPagination,
  getReportTotals,
  getRows,
  getOrder, getFields
} from 'state/Report/selectors';
import { BOX_REPORT_SET_ORDER_DIRECTION, BOX_REPORT_SET_ORDER_FIELD } from 'state/Report';
import './ReportTableHeader.scss';
import { CustomisedFields } from 'state/Report/types';

export const isColumnExists = (searchName: string, columns: any[]) => {
  for (let column of columns) {
    if ( column.name === searchName ) {
      return column;
    }
  }
  return false;
};

type OwnProps = {
  selectedColumns: any;
  report: any;
  openModal: () => void;
  subHeading?: string;
  rows: any;
  order: any;
  fields: CustomisedFields;
};

type DispatchProps = {
  setOrderField: (field: string) => void;
  setOrderDirection: (dir: 'asc' | 'desc') => void;
};

type Props = OwnProps & DispatchProps;

type State = {
  currentField: string;
};

export class ReportTableHeader extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      currentField: ''
    };
  }

  render() {
    return this.getReportTableHeader();
  }

  getReportTableHeader = () => {
    const { selectedColumns, subHeading } = this.props;
    return (
      <NativeTable.Header className={'th-middle'}>
        {subHeading && <NativeTable.Tr>
          <NativeTable.Th colspan={selectedColumns.length}>
            <Heading htmlTag={'h4'}>{subHeading}</Heading>
          </NativeTable.Th>
        </NativeTable.Tr>}
        <NativeTable.Tr valign={'middle'}>
          {selectedColumns.map((column: ColumnsProps, rowKey: number) => (
            <NativeTable.Th
              key={`header-${rowKey}`}
              className={
                rowKey === selectedColumns.length - 1
                  ? `elmo-nativetable-th--has-btn-custom`
                  : ''
              }
            >
              {
                rowKey !== selectedColumns.length - 1
                  ? this.getOrderedHeading(column, rowKey)
                  : this.getCustomizeColumnsIcon(column.title)
              }
            </NativeTable.Th>
          ))}
        </NativeTable.Tr>
      </NativeTable.Header>
    );
  }

  getOrderedHeading = (column: ColumnsProps, rowKey: number) => {
    const { order } = this.props;
    const { currentField } = this.state;
    return (
      <>
        {
          (order.by === column.name) || (currentField !== '' && currentField === column.name)
            ? <span className={'cursor-pointer'} onClick={() => this.orderBy(column.name)}>
              {column.title}
              {this.getSortIcon()}
            </span>
            : <span onClick={() => this.setCurrentSortField(column.name)}>
              {column.title}<span style={{visibility: 'hidden'}}>{this.getSortIcon()}</span>
            </span>
        }
      </>
    );
  }

  orderBy = (field: string) => {
    const { order, report: { type } } = this.props;
    if ( type === 'coverage' || type === 'tag' ) {
      return false;
    }
    this.props.setOrderField(field);
    this.props.setOrderDirection(order.dir === 'asc' ? 'desc' : 'asc');
  }

  getSortIcon = () => {
    const { order } = this.props;
    return order.dir === 'asc' ? <SortAscIcon /> : <SortDescIcon />;
  }

  isButtonVisible = () => {
    const { fields  } = this.props;
    const disabledFields = fields.filter(field => !!field.disabled);
    return disabledFields.length !== fields.length;
  }

  getCustomizeColumnsIcon = (title: string) => {
    return (
      <>
        {
          this.isButtonVisible() && <Button
            className="elmo-nativetable__btn-custom"
            onClick={this.openCustomizeColumnsModal}
            icon={<ViewColumnOutlinedIcon />}
            isUppercase={false}
            ariaLabel="Customize columns"
          />
        }
        <span>{title}</span>
      </>
    );
  }

  openCustomizeColumnsModal = () => {
    this.props.openModal();
  }

  setCurrentSortField = (column: any) => {
    const { order, report: { type } } = this.props;
    if ( type === 'coverage' || type === 'tag' ) {
      return false;
    }
    this.props.setOrderField(column);
    this.props.setOrderDirection(order.dir === 'asc' ? 'desc' : 'asc');
    this.setState({
      currentField: column
    });
  }

}

const mapStateToProps = (state: StoreState) => ({
  pageRows: getCurrentPageRows(state),
  totals: getReportTotals(state),
  pagination: getReportPagination(state),
  groups: getGroups(state),
  rows: getRows(state),
  order: getOrder(state),
  fields: getFields(state)
});

export default connect(
  mapStateToProps,
  {
    setOrderField: BOX_REPORT_SET_ORDER_FIELD,
    setOrderDirection: BOX_REPORT_SET_ORDER_DIRECTION
  }
)(ReportTableHeader);
