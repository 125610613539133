import { ReactChild, ReactNode } from 'react';

export type ListTableColumnProps = {
  /** The label for the column */
  label: string | ReactNode;
  /** The property to use for rendering the data row */
  property?: string;
  /** The render prop to use for rendering the data row */
  render?: (rowData: any, rowIndex: number) => ReactChild | string;
  /** True if this column is sortable */
  sortable?: boolean;
  /** Set to True if this column is placeholder, to ensure WCAG compliance */
  isPlaceholder?: boolean;
};

function ListTableColumn({
  label,
  property,
  render,
  sortable,
  isPlaceholder,
}: ListTableColumnProps) {
  return null;
}

ListTableColumn.displayName = 'ListTableColumn';

export default ListTableColumn;
