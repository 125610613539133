import { createAction } from 'lib/store-utils';
import { FormattedErrors, RosteredShift, StringMap } from 'type';
import {
  DeletePayload,
  GetDayPayload,
  GetPageDataPayload,
  GetSitePayload, ShiftErrorsPayload,
  TimesheetsFilterType
} from './types';
import { TimesheetCreateRequest, TimesheetUpdateRequest } from 'lib/Api/type';

export const BOX_TIMESHEETS_GET_PAGE_DATA_REQUEST = createAction<GetPageDataPayload>('Timesheets / Get page data request');

export const BOX_TIMESHEETS_GET_SITE_REQUEST = createAction<GetSitePayload>('Timesheets / Get site request');
export const BOX_TIMESHEETS_GET_SITE_FAILURE = createAction<FormattedErrors>('Timesheets / Get site failure');

export const BOX_TIMESHEETS_GET_DAY_REQUEST = createAction<GetDayPayload>('Timesheets / Get day request');
export const BOX_TIMESHEETS_GET_DAY_FAILURE = createAction<FormattedErrors>('Timesheets / Get day failure');

export const BOX_TIMESHEETS_GET_PREV_DAY_REQUEST = createAction('Timesheets / Get prev day request');
export const BOX_TIMESHEETS_GET_NEXT_DAY_REQUEST = createAction('Timesheets / Get prev day request');
export const BOX_TIMESHEETS_GET_PREV_NEXT_DAY_FAILURE = createAction<FormattedErrors>('Timesheets / Get prev day failure');

export const BOX_TIMESHEETS_ERRORS_CLEARED = createAction('Timesheets / errors cleared');

export const BOX_TIMESHEETS_TOGGLE_FILTER_TYPE = createAction<TimesheetsFilterType>('Timesheets / Toggle filter type');

export const BOX_TIMESHEETS_SHOW_FORM = createAction('Timesheets / show adding new timesheet form');
export const BOX_TIMESHEETS_HIDE_FORM = createAction('Timesheets / hide adding new timesheet form');

export const BOX_TIMESHEETS_CREATE_REQUEST = createAction<TimesheetCreateRequest>('Timesheets / create timesheet request');
export const BOX_TIMESHEETS_CREATE_SUCCESS = createAction('Timesheets / create timesheet success');
export const BOX_TIMESHEETS_CREATE_FAILURE = createAction<FormattedErrors>('Timesheets / create timesheet failed');

export const BOX_TIMESHEETS_UPDATE_REQUEST = createAction<TimesheetUpdateRequest>('Timesheets / update timesheet request');
export const BOX_TIMESHEETS_UPDATE_SUCCESS = createAction<string>('Timesheets / update timesheet success');
export const BOX_TIMESHEETS_UPDATE_FAILURE = createAction<ShiftErrorsPayload>('Timesheets / update timesheet failed');
export const BOX_TIMESHEETS_ERROR_CLEARED = createAction<string>('Timesheets / Cclear shift error');

export const BOX_TIMESHEETS_APPROVE_REQUEST = createAction<string>('Timesheets / Approve request');
export const BOX_TIMESHEETS_APPROVE_CANCEL = createAction<string>('Timesheets / Approve cancel');
export const BOX_TIMESHEETS_APPROVE_SUCCESS = createAction<string>('Timesheets / Approve success');
export const BOX_TIMESHEETS_APPROVE_FAILURE = createAction<ShiftErrorsPayload>('Timesheets / Approve failed');

export const BOX_TIMESHEETS_DELETE_MODAL_OPEN = createAction('Timesheets / Open Delete modal');
export const BOX_TIMESHEETS_DELETE_MODAL_CLOSE = createAction('Timesheets / Close Delete modal');
export const BOX_TIMESHEETS_DELETE_MODAL_SUBMIT = createAction('Timesheets / Submit Delete modal');

export const BOX_TIMESHEETS_DELETE_REQUEST = createAction<DeletePayload>('Timesheets / Delete request');
export const BOX_TIMESHEETS_DELETE_FAILURE = createAction<FormattedErrors>('Timesheets / Delete failed');

export const BOX_TIMESHEETS_CONFIRMATION_MODAL_OPEN = createAction<FormattedErrors>('Timesheets / Open Conformation modal');
export const BOX_TIMESHEETS_CONFORMATION_MODAL_CLOSE = createAction('Timesheets / Close Confirmation modal');
export const BOX_TIMESHEETS_CONFORMATION_MODAL_SUBMIT = createAction('Timesheets / Submit Confirmation modal');

export const BOX_TIMESHEETS_RELOAD_REQUEST = createAction<GetSitePayload>('Timesheets / Get shifts request');

export const BOX_TIMESHEETS_UPDATE_WITH_APPROVAL = createAction<TimesheetUpdateRequest>('Timesheets / Open Approval modal');
export const BOX_TIMESHEETS_APPROVAL_MODAL_OPEN = createAction('Timesheets / Open Approval modal');
export const BOX_TIMESHEETS_APPROVAL_MODAL_CLOSE = createAction('Timesheets / Close Approval modal');
export const BOX_TIMESHEETS_APPROVAL_MODAL_SUBMIT_APPROVED = createAction('Timesheets / Submit approved Approval modal');
export const BOX_TIMESHEETS_APPROVAL_MODAL_SUBMIT_NOT_APPROVED = createAction('Timesheets / Submit Not approved Approval modal');

export const BOX_TIMESHEETS_LOAD_ROSTERED_SHIFTS_REQUEST = createAction<string>('Timesheets / load rostered shifts');
export const BOX_TIMESHEETS_LOAD_ROSTERED_SHIFTS_SUCCESS = createAction<StringMap<RosteredShift>>('Timesheets / load rostered shifts');
export const BOX_TIMESHEETS_LOAD_ROSTERED_SHIFTS_FAILED = createAction<FormattedErrors>('Timesheets / load rostered shifts');
export const BOX_TIMESHEETS_CLEAR_ROSTERED_SHIFTS = createAction('Timesheets / clear rostered shifts');
