import React, { Component } from 'react';
import NotificationItem from './components/NotificationItem';
import { NotificationItemMeta, NotificationListType, NotificationsListItem } from 'state/NotificationsList/types';
import { StringMap } from 'type';
import _ from 'lodash';
import { Paragraph } from 'elmo-elements';

type Props = {
  items: StringMap<NotificationsListItem>;
  meta: NotificationItemMeta;
  loadMore: () => void;
  markAsRead: (id: string) => void;
  deleteItem: (id: string) => void;
  isDeleting: boolean;
  type: NotificationListType;
};

export class NotificationsListView extends Component<Props> {

  render() {
    const { items } = this.props;
    if ( Object.keys(items).length === 0 ) {
      return this.getPlaceholder();
    }
    return (
      <div className="list-items">
        {
          _.map(items, ((n: NotificationsListItem, key: number) => (
            <NotificationItem
              key={key}
              index={key}
              item={n}
              markAsRead={this.props.markAsRead}
              deleteItem={this.props.deleteItem}
              isDeleting={this.props.isDeleting}
              type={this.props.type}
            />
          )))
        }
      </div>
    );
  }

  getPlaceholder = () => {
    return <Paragraph className="list-placeholder">You're all caught up. No new notifications.</Paragraph>;
  }

}

export default NotificationsListView;