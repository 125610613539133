import { createReducer } from 'lib/store-utils';
import { ReloadNotificationReducerState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';

export const reloadNotification = createReducer<ReloadNotificationReducerState>(
  {},
  getDefaultState()
);

reloadNotification.on(
  actions.BOX_ROSTER_PAST_SET_RELOAD_NOTIFICATION_ID,
  (state, reloadNotificationId): ReloadNotificationReducerState => ({
    ...state,
    reloadNotificationId
  })
);
