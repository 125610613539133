import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Text } from 'elmo-elements';
import {
  AssignmentLateOutlinedIcon,
  CheckOutlinedIcon,
  CloseOutlinedIcon,
  SimpleBadge,
  WarningOutlinedIcon,
} from 'element';
import {
  ShiftOfferProposal as ShiftOfferProposalModel,
  StringMap,
  UserFields,
} from 'type';
import { StoreState } from 'state/types';
import {
  ApproveOrDeclineShiftOfferPayload,
  BOX_SHIFT_OFFER_APPROVE_SHIFT_OFFER_PROPOSAL,
  BOX_SHIFT_OFFER_DECLINE_SHIFT_OFFER_PROPOSAL,
  getIsOutdatedValue,
} from 'state/ManagerDashboard/ShiftOffers/ShiftOffer';
import { userListSelector } from 'state/UsersCollection';
import { getUserName } from 'lib/helpers';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Avatar,
} from 'oxygen-elements';

type OwnProps = {
  proposal: ShiftOfferProposalModel;
};

type StateProps = {
  userList: StringMap<UserFields>;
  isOutdated: boolean;
};

type DispatchProps = {
  approveProposal: (payload: ApproveOrDeclineShiftOfferPayload) => void;
  declineProposal: (payload: ApproveOrDeclineShiftOfferPayload) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

class ShiftOfferProposalComponent extends Component<Props> {
  private colProps = {
    xs: 24 / 2,
    md: 24 / 4,
    className: 'mb-1 mb-xs-2 mb-md-4',
  };

  render() {
    const { proposal } = this.props;

    return (
      <Card
        role="row"
        className="shift-offer-proposal-item"
        data-proposal-id={proposal.id}
      >
        <CardHeader
          className="card-header"
          avatar={
            <Avatar
              light
              src={this.user.avatar.src}
              alt={getUserName(this.user)}
              size={'small'}
            />
          }
          title={getUserName(this.user)}
          titleTypographyProps={{
            fontSize: 16,
            fontWeight: 500,
          }}
          action={
            <SimpleBadge
              type={'warning'}
              icon={<AssignmentLateOutlinedIcon />}
              label={'Pending'}
            />
          }
        />
        <CardContent className="card-content">
          <Row>
            <Col {...this.colProps}>
              <Text color="gray" size="sm">
                Prev / next shift (hrs)
              </Text>
              <div>
                {proposal.prev_shift_hours !== null && (
                  <span data-testid="prev-hours">
                    -{proposal.prev_shift_hours}
                  </span>
                )}
                &nbsp;/&nbsp;
                {proposal.next_shift_hours !== null && (
                  <span data-testid="next-hours">
                    +{proposal.next_shift_hours}
                  </span>
                )}
              </div>
            </Col>

            <Col {...this.colProps}>
              <Text color="gray" size="sm">
                Total shift (hrs)
              </Text>
              <div data-testid="total-hours">{proposal.total_shift_hours}</div>
            </Col>

            <Col {...this.colProps}>
              <Text color="gray" size="sm">
                Projected (hrs)
              </Text>
              <div data-testid="projected-hours">
                {proposal.projected_shift_hours}
              </div>
            </Col>

            <Col {...this.colProps}>
              <Text color="gray" size="sm">
                Min hrs roster
              </Text>
              <div data-testid="min-hours">{proposal.min_hours_per_roster}</div>
            </Col>
          </Row>
          {!!proposal.messages.length && (
            <div className={this.colProps.className}>
              <div>
                <Text color="warning">
                  <WarningOutlinedIcon />
                </Text>
                <Text color="gray" size="sm">
                  &nbsp;Warning
                </Text>
              </div>
              <div data-testid="warnings">
                {proposal.messages.map((message, messageIndex) => (
                  <div key={messageIndex}>- {message}</div>
                ))}
              </div>
            </div>
          )}
        </CardContent>
        <CardActions className="card-actions">
          {this.actions.map((action, key) => (
            <Button
              id={action.id}
              key={`btn-${action.label}`}
              size="large"
              onClick={action.onClick}
              disabled={action.isDisabled}
            >
              {action.icon} {action.label}
            </Button>
          ))}
        </CardActions>
      </Card>
    );
  }

  private get actions() {
    const { proposal, isOutdated } = this.props;
    return [
      {
        label: 'Decline',
        onClick: this.declineProposal(proposal.id),
        icon: <CloseOutlinedIcon />,
        isDisabled: isOutdated,
        id: 'decline',
      },
      {
        label: 'Approve',
        onClick: this.approveProposal(proposal.id),
        icon: <CheckOutlinedIcon />,
        isDisabled: isOutdated || proposal.is_error,
        id: 'approve',
      },
    ];
  }

  private get user(): UserFields {
    const {
      proposal: { user_id },
      userList,
    } = this.props;

    return userList[user_id];
  }

  private approveProposal = (proposalId: string) => () => {
    const { approveProposal, isOutdated } = this.props;
    if (!isOutdated) {
      approveProposal({
        proposalId,
      });
    }
  };

  private declineProposal = (proposalId: string) => () => {
    const { declineProposal, isOutdated } = this.props;
    if (!isOutdated) {
      declineProposal({
        proposalId,
      });
    }
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  userList: userListSelector(state),
  isOutdated: getIsOutdatedValue(state),
});

const mapDispatchToProps: DispatchProps = {
  approveProposal: BOX_SHIFT_OFFER_APPROVE_SHIFT_OFFER_PROPOSAL,
  declineProposal: BOX_SHIFT_OFFER_DECLINE_SHIFT_OFFER_PROPOSAL,
};

export const ShiftOfferProposal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShiftOfferProposalComponent);
