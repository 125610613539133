import React, { Component } from 'react';
import { StoreState } from 'state/types';
import {
  userListSelector
} from 'state/UsersCollection';
import { connect } from 'react-redux';
import { UserFields } from 'type/models';
import { StringMap } from 'type';
import { getUserName } from 'lib/helpers';
import { SelectPropsOption } from 'elmo-elements/Select';
import { Select, Text } from 'elmo-elements';
import {
  usersOfSiteOptionsArraySelector
} from 'state/Timesheets/Timesheets/selectors';

type Props = {
  user_id: string | null;
  onUserChange: (id: string) => void,
  userList: StringMap<UserFields>,
  userListOptions:  SelectPropsOption[],
  isEditable: boolean,
};
type State = {};

class ShiftUser extends Component<Props, State> {

  render() {
    const {isEditable} = this.props;
    return <>
      {isEditable ? this.renderDropDown() : this.renderUserName()}
    </>;
  }

  renderUserName = () => {
    const {user_id, userList} = this.props;
    const userFrom: UserFields = userList[user_id as string];
    return getUserName(userFrom);
  }

  renderDropDown = () => {
    const {userListOptions} = this.props;
    return <div style={{minWidth: '150px'}}>
      <Select
        id="shift-user"
        options={userListOptions}
        onChange={this.onChange}
        name="testing name attr"
        ariaLabel="Test aria label"
        placeholder="Select user"
      />
    </div>;
  }

  onChange = (e: {label: string, value: string }) => {
    this.props.onUserChange(e.value);
  }
}

const mapStateToProps = (state: StoreState) => ({
  userList: userListSelector(state),
  userListOptions: usersOfSiteOptionsArraySelector(state),
});

export default connect(mapStateToProps)(
  ShiftUser
);