import React, { ReactNode } from 'react';
import { Dropdown } from 'elmo-elements';
import {
  CustomDropdownLabelType,
  CustomDropdownOptions,
  CustomDropdownOptionType
} from './types';
import { CustomDropdownLabel, CustomDropdownOption } from './components';
import './CustomDropdown.scss';

type Props<Value> = {
  children: ReactNode; // target
  isOpened: boolean;
  options: CustomDropdownOptions<Value>;
  onClose?: () => void;
  onSelect?: (value: Value) => void;
};

export const CustomDropdown = <Value extends any>({
  children,
  isOpened,
  onClose,
  options,
  onSelect
}: Props<Value>) => (
  <div className="custom-dropdown">
    {children}
    <div className="custom-dropdown__dropdown-wrapper">
      {isOpened && (
        <Dropdown isOpen={true} onClose={onClose} isFocusedOnOpen={true}>
          {options.map((option, optionIndex) =>
            !!(option as CustomDropdownOptionType<Value>).value ? (
              <CustomDropdownOption
                {...option as CustomDropdownOptionType<Value>}
                onSelect={onSelect}
                key={optionIndex}
              />
            ) : (
              <CustomDropdownLabel
                {...option as CustomDropdownLabelType}
                key={optionIndex}
              />
            )
          )}
        </Dropdown>
      )}
    </div>
  </div>
);
