import Api from 'lib/Api';
import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { formatError } from 'state/helpers';
import { apiCall, apiWithConfirmCall } from 'state/ProcessApiRequest';
import { getRotaUserListRequest } from 'state/UsersCollection';
import {
  ApiReturnType,
  ApiWithConfirmReturnType,
  SagaActionFromCreator,
} from 'type';
import * as actions from './actions';

const openDialog = function* (): SagaIterator {
  try {
    const [user_ids]: [ApiReturnType<typeof Api.Payment.getAssignedUsers>] =
      yield all([
        apiCall(Api.Payment.getAssignedUsers),
        call(getRotaUserListRequest),
      ]);

    yield put(
      actions.BOX_PAYMENT_ASSIGNED_USERS_DIALOG_OPEN.success({ user_ids })
    );
  } catch (error) {
    yield put(
      actions.BOX_PAYMENT_ASSIGNED_USERS_DIALOG_OPEN.failure(formatError(error))
    );
  }
};

const usersChange = function* ({
  payload,
}: SagaActionFromCreator<
  typeof actions.BOX_PAYMENT_ASSIGNED_USERS_DIALOG_USERS_CHANGE.request
>): SagaIterator {
  try {
    const user_ids: ApiWithConfirmReturnType<
      typeof Api.Payment.putAssignedUsers
    > = yield apiWithConfirmCall(Api.Payment.putAssignedUsers, payload);

    if (user_ids) {
      yield put(
        actions.BOX_PAYMENT_ASSIGNED_USERS_DIALOG_USERS_CHANGE.success({
          user_ids,
        })
      );
    } else {
      yield put(
        actions.BOX_PAYMENT_ASSIGNED_USERS_DIALOG_USERS_CHANGE.cancel()
      );
    }
  } catch (error) {
    yield put(
      actions.BOX_PAYMENT_ASSIGNED_USERS_DIALOG_USERS_CHANGE.failure(
        formatError(error)
      )
    );
  }
};

export const watchPaymentAssignedUsersDialog = function* (): SagaIterator {
  yield takeLatest(
    actions.BOX_PAYMENT_ASSIGNED_USERS_DIALOG_OPEN.request,
    openDialog
  );
  yield takeLatest(
    actions.BOX_PAYMENT_ASSIGNED_USERS_DIALOG_USERS_CHANGE.request,
    usersChange
  );
};
