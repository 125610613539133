import Api from 'lib/Api';
import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { formatError } from 'state/helpers';
import { apiCall, apiWithConfirmCall } from 'state/ProcessApiRequest';
import { fetchRosteredShiftsList } from 'state/RosteredShiftsCollection';
import { fetchTimesheetListWithPayEntities } from 'state/TimesheetResponse';
import { fetchTimesheetsWeekSummary } from 'state/TimesheetsWeekSummary';
import {
  ApiReturnType,
  ApiWithConfirmReturnType,
  SagaActionFromCreator,
  SagaReturnType,
} from 'type';
import * as actions from './actions';
import { fetchRotaUserList } from '../UsersCollection/sagas';

const getPageData = function* ({
  payload,
}: SagaActionFromCreator<
  typeof actions.BOX_TIMESHEETS_WEEKLY_PAGE_DATA_GET.request
>): SagaIterator {
  try {
    const [users, timesheets, rosteredShifts, timesheetsWeekSummary]: [
      SagaReturnType<typeof fetchRotaUserList>,
      SagaReturnType<typeof fetchTimesheetListWithPayEntities>,
      SagaReturnType<typeof fetchRosteredShiftsList>,
      SagaReturnType<typeof fetchTimesheetsWeekSummary>
    ] = yield all([
      call(fetchRotaUserList),
      call(fetchTimesheetListWithPayEntities, payload),
      call(fetchRosteredShiftsList, payload),
      call(fetchTimesheetsWeekSummary, payload),
    ]);

    yield put(
      actions.BOX_TIMESHEETS_WEEKLY_PAGE_DATA_GET.success({
        payload,
        users,
        timesheets,
        rosteredShifts,
        timesheetsWeekSummary,
      })
    );
  } catch (error) {
    yield put(
      actions.BOX_TIMESHEETS_WEEKLY_PAGE_DATA_GET.failure(formatError(error))
    );
  }
};

const updatePageData = function* ({
  payload,
}: SagaActionFromCreator<
  typeof actions.BOX_TIMESHEETS_WEEKLY_PAGE_DATA_UPDATE.request
>): SagaIterator {
  try {
    const [users, timesheets, rosteredShifts]: [
      SagaReturnType<typeof fetchRotaUserList>,
      SagaReturnType<typeof fetchTimesheetListWithPayEntities>,
      SagaReturnType<typeof fetchRosteredShiftsList>
    ] = yield all([
      call(fetchRotaUserList),
      call(fetchTimesheetListWithPayEntities, payload),
      call(fetchRosteredShiftsList, payload),
    ]);

    yield put(
      actions.BOX_TIMESHEETS_WEEKLY_PAGE_DATA_UPDATE.success({
        payload,
        users,
        timesheets,
        rosteredShifts,
      })
    );
  } catch (error) {
    yield put(
      actions.BOX_TIMESHEETS_WEEKLY_PAGE_DATA_UPDATE.failure(formatError(error))
    );
  }
};

const updateSummary = function* ({
  payload,
}: SagaActionFromCreator<
  typeof actions.BOX_TIMESHEETS_WEEKLY_SUMMARY_UPDATE.request
>) {
  try {
    const timesheetsWeekSummary: SagaReturnType<
      typeof fetchTimesheetsWeekSummary
    > = yield call(fetchTimesheetsWeekSummary, payload);

    yield put(
      actions.BOX_TIMESHEETS_WEEKLY_SUMMARY_UPDATE.success({
        payload,
        timesheetsWeekSummary,
      })
    );
  } catch (error) {
    yield put(
      actions.BOX_TIMESHEETS_WEEKLY_SUMMARY_UPDATE.failure(formatError(error))
    );
  }
};

const deleteTimesheet = function* ({
  payload: { timesheetId },
}: SagaActionFromCreator<
  typeof actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_DELETE.request
>): SagaIterator {
  try {
    const response: ApiWithConfirmReturnType<typeof Api.Timesheet.delete> =
      yield apiWithConfirmCall(Api.Timesheet.delete, {
        id: timesheetId,
      });

    if (response) {
      yield put(
        actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_DELETE.success({
          id: timesheetId,
        })
      );
    } else {
      yield put(actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_DELETE.cancel());
    }
  } catch (error) {
    yield put(
      actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_DELETE.failure(formatError(error))
    );
  }
};

const approveTimesheet = function* ({
  payload: { timesheetId },
}: SagaActionFromCreator<
  typeof actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_APPROVE.request
>): SagaIterator {
  try {
    const response: ApiWithConfirmReturnType<
      typeof Api.Timesheet.changeApproveResponse
    > = yield apiWithConfirmCall(Api.Timesheet.changeApproveResponse, {
      id: timesheetId,
      is_approved: true,
    });

    if (response) {
      yield put(
        actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_APPROVE.success(response)
      );
    } else {
      yield put(actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_APPROVE.cancel());
    }
  } catch (error) {
    yield put(
      actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_APPROVE.failure(
        formatError(error)
      )
    );
  }
};

const unapproveTimesheet = function* ({
  payload: { timesheetId },
}: SagaActionFromCreator<
  typeof actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_UNAPPROVE.request
>): SagaIterator {
  try {
    const response: ApiWithConfirmReturnType<
      typeof Api.Timesheet.changeApproveResponse
    > = yield apiWithConfirmCall(Api.Timesheet.changeApproveResponse, {
      id: timesheetId,
      is_approved: false,
    });

    if (response) {
      yield put(
        actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_UNAPPROVE.success(response)
      );
    } else {
      yield put(actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_UNAPPROVE.cancel());
    }
  } catch (error) {
    yield put(
      actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_UNAPPROVE.failure(
        formatError(error)
      )
    );
  }
};

const createTimesheet = function* ({
  payload,
}: SagaActionFromCreator<
  typeof actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_CREATE.request
>): SagaIterator {
  try {
    const response: ApiWithConfirmReturnType<
      typeof Api.Timesheet.createTimesheetResponse
    > = yield apiWithConfirmCall(
      Api.Timesheet.createTimesheetResponse,
      payload
    );
    if (response) {
      yield put(
        actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_CREATE.success(response)
      );
    } else {
      yield put(actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_CREATE.cancel());
    }
  } catch (error) {
    yield put(
      actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_CREATE.failure(formatError(error))
    );
  }
};

const stopTimesheet = function* ({
  payload,
}: SagaActionFromCreator<
  typeof actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_STOP.request
>): SagaIterator {
  try {
    const timesheet: ApiReturnType<typeof Api.PunchClock.forceStopTimesheet> =
      yield apiCall(Api.PunchClock.forceStopTimesheet, payload);

    yield put(
      actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_STOP.success({
        ...payload,
        timesheet,
      })
    );
  } catch (error) {
    yield put(
      actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_STOP.failure(formatError(error))
    );
  }
};

const updateTimesheet = function* ({
  payload,
}: SagaActionFromCreator<
  typeof actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_UPDATE.request
>): SagaIterator {
  try {
    const response: ApiWithConfirmReturnType<
      typeof Api.Timesheet.updateTimesheetResponse
    > = yield apiWithConfirmCall(
      Api.Timesheet.updateTimesheetResponse,
      payload
    );

    if (response) {
      yield put(
        actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_UPDATE.success(response)
      );
    } else {
      yield put(actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_UPDATE.cancel());
    }
  } catch (error) {
    yield put(
      actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_UPDATE.failure(formatError(error))
    );
  }
};

const deleteTimesheets = function* ({
  payload: { selectedIds },
}: SagaActionFromCreator<
  typeof actions.BOX_TIMESHEETS_WEEKLY_TIMESHEETS_DELETE.request
>): SagaIterator {
  try {
    const response: ApiWithConfirmReturnType<typeof Api.Timesheet.bulkDelete> =
      yield apiWithConfirmCall(Api.Timesheet.bulkDelete, {
        ids: selectedIds,
      });

    if (response) {
      const { deleted: deletedIds } = response;

      yield put(
        actions.BOX_TIMESHEETS_WEEKLY_TIMESHEETS_DELETE.success({ deletedIds })
      );
    } else {
      yield put(actions.BOX_TIMESHEETS_WEEKLY_TIMESHEETS_DELETE.cancel());
    }
  } catch (error) {
    yield put(
      actions.BOX_TIMESHEETS_WEEKLY_TIMESHEETS_DELETE.failure(
        formatError(error)
      )
    );
  }
};

const approveTimesheets = function* ({
  payload: { selectedIds },
}: SagaActionFromCreator<
  typeof actions.BOX_TIMESHEETS_WEEKLY_TIMESHEETS_APPROVE.request
>): SagaIterator {
  try {
    const response: ApiWithConfirmReturnType<typeof Api.Timesheet.bulkApprove> =
      yield apiWithConfirmCall(Api.Timesheet.bulkApprove, {
        timesheet_ids: selectedIds,
        with_pay_entries: true,
      });

    if (response) {
      yield put(
        actions.BOX_TIMESHEETS_WEEKLY_TIMESHEETS_APPROVE.success(response)
      );
    } else {
      yield put(actions.BOX_TIMESHEETS_WEEKLY_TIMESHEETS_APPROVE.cancel());
    }
  } catch (error) {
    yield put(
      actions.BOX_TIMESHEETS_WEEKLY_TIMESHEETS_APPROVE.failure(
        formatError(error)
      )
    );
  }
};

const getAllowedToAssignShifts = function* ({
  payload,
}: SagaActionFromCreator<
  typeof actions.BOX_TIMESHEETS_WEEKLY_ALLOWED_TO_ASSIGN_SHIFTS_GET.request
>) {
  try {
    const allowedShifts: ApiReturnType<
      typeof Api.RosteredShift.getListOfAllowedShifts
    > = yield apiCall(Api.RosteredShift.getListOfAllowedShifts, payload);

    yield put(
      actions.BOX_TIMESHEETS_WEEKLY_ALLOWED_TO_ASSIGN_SHIFTS_GET.success(
        allowedShifts
      )
    );
  } catch (error) {
    yield put(
      actions.BOX_TIMESHEETS_WEEKLY_ALLOWED_TO_ASSIGN_SHIFTS_GET.failure(
        formatError(error)
      )
    );
  }
};

const updateAllowedToAssignShifts = function* ({
  payload,
}: SagaActionFromCreator<
  typeof actions.BOX_TIMESHEETS_WEEKLY_ALLOWED_TO_ASSIGN_SHIFTS_UPDATE.request
>) {
  try {
    const allowedShifts: ApiReturnType<
      typeof Api.RosteredShift.getListOfAllowedShifts
    > = yield apiCall(Api.RosteredShift.getListOfAllowedShifts, payload);

    yield put(
      actions.BOX_TIMESHEETS_WEEKLY_ALLOWED_TO_ASSIGN_SHIFTS_UPDATE.success(
        allowedShifts
      )
    );
  } catch (error) {
    yield put(
      actions.BOX_TIMESHEETS_WEEKLY_ALLOWED_TO_ASSIGN_SHIFTS_UPDATE.failure(
        formatError(error)
      )
    );
  }
};

export const watchTimesheetsWeeklyPage = function* (): SagaIterator {
  yield takeLatest(
    actions.BOX_TIMESHEETS_WEEKLY_PAGE_DATA_GET.request,
    getPageData
  );
  yield takeLatest(
    actions.BOX_TIMESHEETS_WEEKLY_PAGE_DATA_UPDATE.request,
    updatePageData
  );
  yield takeLatest(
    actions.BOX_TIMESHEETS_WEEKLY_SUMMARY_UPDATE.request,
    updateSummary
  );
  yield takeLatest(
    actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_DELETE.request,
    deleteTimesheet
  );
  yield takeLatest(
    actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_APPROVE.request,
    approveTimesheet
  );
  yield takeLatest(
    actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_UNAPPROVE.request,
    unapproveTimesheet
  );
  yield takeLatest(
    actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_CREATE.request,
    createTimesheet
  );
  yield takeLatest(
    actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_STOP.request,
    stopTimesheet
  );
  yield takeLatest(
    actions.BOX_TIMESHEETS_WEEKLY_TIMESHEET_UPDATE.request,
    updateTimesheet
  );
  yield takeLatest(
    actions.BOX_TIMESHEETS_WEEKLY_TIMESHEETS_DELETE.request,
    deleteTimesheets
  );
  yield takeLatest(
    actions.BOX_TIMESHEETS_WEEKLY_TIMESHEETS_APPROVE.request,
    approveTimesheets
  );
  yield takeLatest(
    actions.BOX_TIMESHEETS_WEEKLY_ALLOWED_TO_ASSIGN_SHIFTS_GET.request,
    getAllowedToAssignShifts
  );
  yield takeLatest(
    actions.BOX_TIMESHEETS_WEEKLY_ALLOWED_TO_ASSIGN_SHIFTS_UPDATE.request,
    updateAllowedToAssignShifts
  );
};
