import React from 'react';
import {
  getCovidPointedShiftId,
  getIsCovidModeEnabled,
  isUserHasCovidShifts,
} from 'state/Roster/CovidTracing';
import { UserFields } from 'type';
import { RosterUserLegend, RosterRow } from '../../../../components';
import { Avatar } from 'oxygen-elements';
import {
  filtersByTypeSelector,
  rosteredShiftsFilteredSelector,
  timesheetsFilteredSelector,
} from 'state/Roster/RosterFilters';
import { getDurationLabels, getDurationsInfo } from '../../../../helpers';
import { useSelector } from 'react-redux';
import {
  timeOffsByUserIdByDaySelector,
  userByIdRosterDataSelector,
} from 'state/Roster/RosterWeekView';
import { usersBySiteIdSelector } from 'state/UsersCollection';
import { getSiteId } from 'state/RosteredShifts';
import { getSelectedByUser } from 'state/Roster/BulkActions/selectors';
import { getUserName } from '../../../../../../lib/helpers';

type OwnProps = {
  userId: string;
  children: React.ReactNode;
  bulkSelect?: 'user';
  rowIndex?: number;
  legendType: 'single-row' | 'multi-row';
};

type Props = OwnProps;

export const UserRowWithLegend = ({
  children,
  rowIndex,
  userId,
  legendType,
  bulkSelect,
}: Props) => {
  const timeOffs = useSelector(timeOffsByUserIdByDaySelector);
  const filteredRosters = useSelector(rosteredShiftsFilteredSelector);
  const filteredTimesheets = useSelector(timesheetsFilteredSelector);
  const selectedFilter = useSelector(filtersByTypeSelector);
  const users = useSelector(usersBySiteIdSelector);
  const siteId = useSelector(getSiteId);
  const rowData = useSelector((state) =>
    userByIdRosterDataSelector(state, userId)
  );
  const selectedByUser = useSelector(getSelectedByUser);
  const contactedClassName = useCovidMarkedRowClassName(userId);

  const getDurations = () => {
    const duration = getDurationsInfo(
      rowData!.rosters,
      filteredRosters,
      filteredTimesheets,
      timeOffs
    );
    return getDurationLabels(duration, selectedFilter);
  };

  const getUser = () => {
    const usersFromSite: UserFields[] | undefined = users[siteId];
    if (usersFromSite) {
      return usersFromSite.filter((u) => u.id === userId)[0];
    }

    return undefined;
  };

  const getUserLegendData = () => {
    const user = getUser();
    let label = '';
    let src: string | undefined = '';
    if (user) {
      label = getUserName(user);
      src = user.avatar.src;
    }
    return {
      icon: <Avatar size={'small'} src={src} alt={label} light />,
      label: label,
    };
  };

  const getUserLegend = () => {
    const user = getUser();
    const data = getUserLegendData();
    return (
      <RosterUserLegend
        type={legendType}
        label={data.label}
        icon={data.icon}
        durations={getDurations()}
        onClick={() => false}
        isDisabled={!user || !user.is_active}
        bulkSelect={bulkSelect === 'user'}
        userId={userId}
        pageType={'week'}
      />
    );
  };

  return (
    <RosterRow
      isCollapsed={false}
      isSelected={selectedByUser[userId] === true}
      rowIndex={rowIndex}
      className={contactedClassName}
    >
      {{
        sticky: getUserLegend(),
        content: (
          <div className={`week-rosters-grid spec-cell-height`}>{children}</div>
        ),
      }}
    </RosterRow>
  );
};

export default UserRowWithLegend;

function useCovidMarkedRowClassName(userId: string | null) {
  const hasCovidShifts = useSelector(isUserHasCovidShifts(userId));
  const isCovidMode = useSelector(getIsCovidModeEnabled);
  const markedCovidId = useSelector(getCovidPointedShiftId);

  const isSearchContacted = isCovidMode && markedCovidId;

  return isSearchContacted && (!hasCovidShifts || !userId)
    ? 'no-contacted-shifts'
    : '';
}
