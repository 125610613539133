import React from 'react';
import { Datepicker } from 'elmo-elements';
import { DatepickerProps } from 'elmo-elements/Datepicker/type';
import { Omit } from '../../type';
import { getDateFormat } from 'state/Account/selectors';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';

export type DateInputOwnProps = Omit<
  DatepickerProps,
  'name' | 'value' | 'onChange' | 'format' | 'clickOnTodayButton'
> & {
  name: string;
  value: Date | null;
  onChange: (value: Date | null, name: string) => void;
  tabIndex?: number;
  clickOnTodayButton?: () => void;
};

type StateProps = {
  dateFormat: string;
};

type Props = DateInputOwnProps & StateProps & { tabIndex?: number; clickOnTodayButton?: () => void; };

class DateInputComponent extends React.Component<Props> {
  render() {
    const { dateFormat, onChange, clickOnTodayButton, ...rest } = this.props;

    return (
      <Datepicker
        format={dateFormat}
        onChange={this.onChange}
        isInline={true}
        {...rest}
        firstDayOfWeek={1}
        clickOnTodayButton={clickOnTodayButton}
      />
    );
  }

  onChange = (value: Date | null) => {
    this.props.onChange(value, this.props.name);
  };

  applyReadOnlyStateForMobile = () => {
    let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const d = document.querySelectorAll(
      '.elmo-datepicker__input-wrapper input'
    );
    if (d.length) {
      d.forEach((item: any) => {
        if (isMobile) {
          item.addEventListener('focus', (e: any) => {
            e.target.blur();
          });
        }
      });
    }
  };

  componentDidMount(): void {
    this.applyReadOnlyStateForMobile();
    const d = document.querySelectorAll(
      '.elmo-datepicker__input-wrapper input'
    );
    if (d.length) {
      d.forEach((item: any) => {
       item.setAttribute('tabIndex', this.props.tabIndex)
      });
    }
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  dateFormat: getDateFormat(state)
});

export const DateInput = connect(mapStateToProps)(DateInputComponent);
