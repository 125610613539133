import { createConnectAction } from 'lib/store-utils';
import { DateType } from 'type';

export const BOX_SOCKETS_GLOBAL_CHANNELS = createConnectAction('sockets / global channels');
export const BOX_SOCKETS_EMPLOYEE_DASHBOARD = createConnectAction('sockets / employee dashboard');
export const BOX_SOCKETS_MANAGER_DASHBOARD = createConnectAction('sockets / manager dashboard');
export const BOX_SOCKETS_ROSTER = createConnectAction<{ site_id: string; }>('sockets / roster');
export const BOX_SOCKETS_ROSTER_WEEK = createConnectAction<{ site_id: string; day: DateType; }>('sockets / roster week');
export const BOX_SOCKETS_TIMESHEETS_WEEKLY = createConnectAction<{ site_id: string; day: DateType; }>('sockets / timesheets weekly');
export const BOX_SOCKETS_TIMESHEETS_DAILY = createConnectAction<{ site_id: string; day: DateType; }>('sockets / timesheets daily');


