import React, { Component, FormEvent, MouseEvent, ReactFragment } from 'react';
import {
  FormItem,
  Input,
  Button,
  Row,
  Col,
  TextArea,
  Loader,
  Dialog,
} from 'elmo-elements';
import {
  ProfileIcon,
  CalendarTodayOutlinedIcon,
  AutorenewIcon,
  ScheduleOutlinedIcon,
  StatusIcon,
  EditOutlinedIcon,
  CancelIcon,
  TodayOutlinedIcon,
  PageDialog,
  PageDialogCancel,
  PageDialogSubmit,
  PageDialogCloseIconButton,
} from 'element';
import { durationOptions } from '../../config';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import { EmployeeUnavailabilityRequestPayload } from 'state/EmployeeDashboard/Unavailability/types';
import { ErrorBox } from 'element';
import { DateInputMoment } from 'element/DateInputMoment';

import { getState, getSubmitPayload } from './transformData';
import { Props, ViewState, StateProps } from './types';
import { getUserName, keyMirror, capitalize } from 'lib/helpers';
import { AccountTreeSite, UserFields } from 'type/models';
import { TimeInput } from 'element';
import { getSites, siteIdsToSelectOptionsCombiner } from 'state/AccountTree';
import { OnChangeSelectEvent } from 'element/Select/types';
import moment, { Moment } from 'moment';
import { SelectPropsOption } from 'elmo-elements/Select/type';
import {
  currentUserSiteIdsSelector,
  getCurrentUser,
  getCurrentUserId,
} from 'state/Auth';
import { userListSelector } from 'state/UsersCollection';
import { getLangPreferences } from 'state/Account';
import { getCurrentTime } from 'state/CurrentTime';
import { repeatOptions, statusLabels } from './config';
import { createUntilDate, getDefaultDates } from './helpers';
import { disableDatesBeforeCurrent } from 'helpers';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
} from 'extended-oxygen-elements';

export class CreatEditModal extends Component<Props, ViewState> {
  readonly state = {
    ...getState(this.props),
    confirmDelete: false,
  };
  readonly names = keyMirror(this.state.data);

  render() {
    return (
      <>
        <PageDialog
          maxWidth={'xs'}
          open={this.props.modal.isOpened}
          id="unavailability-modal"
          onClose={this.props.closeModal}
        >
          <DialogTitle>
            {this.props.title}
            <PageDialogCloseIconButton onClose={this.props.closeModal} />
          </DialogTitle>
          <DialogContent>
            {this.props.modal.isFetching ? (
              <Loader isLoading={this.props.modal.isFetching} />
            ) : (
              this.showForm()
            )}
          </DialogContent>
          <DialogActions sx={{ boxShadow: '0 0 6px 2px #ccc', zIndex: 3 }}>
            {!this.doNotEdit() && (
              <PageDialogCancel onClick={this.props.closeModal}>
                Cancel
              </PageDialogCancel>
            )}
            <PageDialogSubmit
              onClick={this.onSubmit}
              disabled={
                !this.submitPayload ||
                this.props.modal.isDeleting ||
                this.props.modal.isFetching
              }
              loading={this.props.modal.isUpdating}
              id={'submit-btn'}
              variant={this.doNotEdit() ? 'outlined' : 'contained'}
            >
              {this.props.submitLabel}
            </PageDialogSubmit>
          </DialogActions>
        </PageDialog>
        {this.state.confirmDelete && (
          <PageDialog
            id={'delete-unavailability-confirmation-modal'}
            maxWidth={'xs'}
            open={this.state.confirmDelete}
            onClose={this.cancelDelete}
          >
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent>
              Are you sure you want to delete this unavailability request?
            </DialogContent>
            <DialogActions>
              <PageDialogCancel
                onClick={this.cancelDelete}
                id={'cancel-delete-action-btn'}
              >
                Cancel
              </PageDialogCancel>
              <PageDialogSubmit
                onClick={this.deleteUnavailability}
                id={'confirm-dialog-action-btn'}
              >
                Ok
              </PageDialogSubmit>
            </DialogActions>
          </PageDialog>
        )}
      </>
    );
  }

  cancelDelete = () => {
    this.setState((prevState) => ({
      ...prevState,
      confirmDelete: false,
    }));
  };

  openDelete = () => {};

  openConfirm = () => {
    this.setState((prevState) => ({
      ...prevState,
      confirmDelete: true,
    }));
  };

  doNotEdit = () => {
    const { type } = this.props.modal;
    const { status } = this.props.data;
    return type === 'view' || status === 'approved';
  };

  componentDidMount(): void {
    this.setState(getState(this.props));
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<ViewState>,
    snapshot?: any
  ): void {
    const { modal } = this.props;
    const { isFetching, isOpened } = prevProps.modal;
    const isFetched =
      isFetching !== modal.isFetching && modal.isOpened && !modal.isFetching;
    const isNew = isOpened !== modal.isOpened;
    if (isFetched || isNew) {
      this.setState(getState(this.props));
    }
    if (this.props.modal.errors && this.props.modal.errors.length) {
      this.scrollToErrors();
    }
  }

  scrollToErrors = () => {
    const modal = document.querySelector('#unavailability-modal');
    setTimeout(() => {
      if (modal !== null) {
        const inner = modal.querySelector('.global-errors-alert');
        if (inner !== null) {
          inner.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'end',
          });
        }
      }
    }, 500);
  };

  showForm = () => {
    const {
      state: { data },
      names,
    } = this;
    const isEdit = this.props.modal.type === 'edit';

    return (
      <form onSubmit={this.onSubmit}>
        <ErrorBox
          errors={this.props.modal.errors}
          clearErrors={this.props.clearErrors}
        />

        <FormItem icon={<ProfileIcon />}>
          <Input
            name={'username'}
            value={this.getUserName()}
            isDisabled={true}
            ariaLabel="User name"
          />
        </FormItem>

        <FormItem icon={<CalendarTodayOutlinedIcon />} isDivider={true}>
          <Select
            id="create-edit-modal-user-id-select"
            fullWidth
            name={'duration'}
            onChange={e => {this.onDurationChange(e.target)}}
            value={data.duration}
            aria-label={'Duration'}
            disabled={this.doNotEdit()}
          >
            {durationOptions().map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>)
            )}
          </Select>
        </FormItem>

        {this.durationMarkup}

        <FormItem icon={<AutorenewIcon />}>
          <Select
            id="create-edit-modal-repeat-select"
            fullWidth
            name={names.repeat}
            onChange={e => {this.onSelectChange(e.target)}}
            value={data.repeat}
            aria-label={'Repeat options'}
            disabled={this.doNotEdit()}
          >
            {repeatOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>)
            )}
          </Select>
        </FormItem>

        <FormItem icon={<></>} label="Until" isDivider={true}>
          <DateInputMoment
            id="create-edit-modal-until-input"
            name={'until'}
            value={data.until ? data.until : moment()}
            onChange={this.onChangeDateInput}
            isClearable={false}
            isReadOnly={true}
            disabledDate={this.disabledUntilDates}
            isDisabled={data.repeat === 'once' || this.doNotEdit()}
            ariaLabel="Until"
          />
        </FormItem>

        <FormItem icon={<ScheduleOutlinedIcon />}>
          <Select
            id="create-edit-modal-site-id-select"
            fullWidth
            name={'site_id'}
            onChange={e => {this.onSiteSelectChange(e.target)}}
            value={data.site_id}
            aria-label={'Location'}
            disabled={!data.user_id.length || this.doNotEdit()}
            displayEmpty={true}
            renderValue={() => data.site_name === '' ? capitalize(this.props.langPreferences.site.singular) : data.site_name}
            className={data.site_name ? '' : 'select-placeholder'}
          >
            {this.userSitesOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>)
            )}
          </Select>
        </FormItem>

        <FormItem icon={<StatusIcon />}>
          <Input
            id="create-edit-modal-status"
            name={'status'}
            value={statusLabels[data.status].label}
            isDisabled={true}
            ariaLabel="Status"
          />
        </FormItem>

        <FormItem icon={<EditOutlinedIcon />}>
          <TextArea
            name={'description'}
            value={data.description}
            onChange={this.onInputChange}
            placeholder="Description"
            isDisabled={this.doNotEdit()}
            ariaLabel="Description"
          />
        </FormItem>

        {(isEdit || data.status === 'approved') && (
          <FormItem>
            <Button
              isText={true}
              icon={<CancelIcon />}
              type={'danger'}
              isUppercase={false}
              onClick={this.openConfirm}
              isLoading={this.props.modal.isDeleting}
              isDisabled={this.props.modal.isDeleting}
            >
              Delete request
            </Button>
          </FormItem>
        )}
      </form>
    );
  };

  private deleteUnavailability = () => {
    if (this.props.delete) {
      this.props.delete(this.state.data.id);
    }
    this.cancelDelete();
  };

  private getUserName(): string {
    const { currentUser } = this.props;
    return getUserName(currentUser);
  }

  private get selectedSiteTimezone(): string | undefined {
    const {
      props: { sites },
      state: {
        data: { site_id },
      },
    } = this;

    const site: AccountTreeSite | undefined = sites[site_id];

    if (site) {
      return site.timezone_id;
    }

    return undefined;
  }

  private get currentTimeOfSite(): Moment {
    const {
      props: { currentTime },
    } = this;

    if (this.selectedSiteTimezone) {
      return currentTime.clone().tz(this.selectedSiteTimezone);
    }

    return currentTime;
  }

  private get durationMarkup(): ReactFragment {
    switch (this.state.data.duration) {
      case 'all_day':
        return this.allDayDurationMarkup;
      case 'few_hours':
        return this.fewHoursDurationMarkup;
      case 'multiple_days':
        return this.multipleDaysDurationMarkup;
    }
  }

  private get allDayDurationMarkup(): ReactFragment {
    const {
      names,
      state: { data },
    } = this;
    return (
      <>
        <FormItem
          icon={
            <div className={'mt-5'}>
              <TodayOutlinedIcon />
            </div>
          }
          label="Start date"
        >
          <DateInputMoment
            name={names.start_date}
            value={data.start_date}
            onChange={this.onChangeStartEndDateTogether}
            isClearable={false}
            isReadOnly={true}
            isDisabled={this.doNotEdit()}
            disabledDate={disableDatesBeforeCurrent}
            ariaLabel="Start date"
          />
        </FormItem>

        <FormItem icon={<></>} label="End date" isDivider={true}>
          <DateInputMoment
            name={names.end_date}
            value={data.end_date}
            onChange={this.doNothing}
            isClearable={false}
            isDisabled={true}
            isReadOnly={true}
            disabledDate={disableDatesBeforeCurrent}
            ariaLabel="End date"
          />
        </FormItem>
      </>
    );
  }

  private get fewHoursDurationMarkup(): ReactFragment {
    const {
      names,
      state: { data },
    } = this;
    return (
      <>
        <FormItem
          icon={
            <div className={'mt-5'}>
              <TodayOutlinedIcon />
            </div>
          }
          label="Start date"
        >
          <Row role="presentation">
            <Col span={24 / 2} role="presentation">
              <DateInputMoment
                name={names.start_date}
                value={data.start_date}
                onChange={this.onChangeStartEndDateTogether}
                isClearable={false}
                isReadOnly={true}
                isDisabled={this.doNotEdit()}
                disabledDate={disableDatesBeforeCurrent}
                ariaLabel="Start date"
              />
            </Col>

            <Col span={24 / 2} role="presentation">
              <TimeInput
                name={names.start_time}
                value={
                  data.start_time ? data.start_time : moment('00:00', 'HH:mm')
                }
                onChange={this.onChangeTimeInput}
                disabled={this.doNotEdit()}
                id="start-time"
                label="Start time"
              />
            </Col>
          </Row>
        </FormItem>

        <FormItem icon={<></>} label="End date" isDivider={true}>
          <Row role="presentation">
            <Col span={24 / 2} role="presentation">
              <DateInputMoment
                name={names.end_date}
                value={data.end_date}
                onChange={this.doNothing}
                isClearable={false}
                isReadOnly={true}
                isDisabled={true}
                disabledDate={disableDatesBeforeCurrent}
                ariaLabel="End date"
              />
            </Col>

            <Col span={24 / 2} role="presentation">
              <TimeInput
                name={names.end_time}
                value={data.end_time ? data.end_time : moment('00:00', 'HH:mm')}
                onChange={this.onChangeTimeInput}
                disabled={this.doNotEdit()}
                id="end-time"
                label="End time"
              />
            </Col>
          </Row>
        </FormItem>
      </>
    );
  }

  private get multipleDaysDurationMarkup(): ReactFragment {
    const {
      names,
      state: { data },
    } = this;
    return (
      <>
        <FormItem
          icon={
            <div className={'mt-5'}>
              <TodayOutlinedIcon />
            </div>
          }
          label="Start date"
        >
          <DateInputMoment
            name={names.start_date}
            value={data.start_date}
            onChange={this.onChangeDateInput}
            isClearable={false}
            isReadOnly={true}
            disabledDate={this.disabledStartDates && disableDatesBeforeCurrent}
            isDisabled={this.doNotEdit()}
            ariaLabel="Start date"
          />
        </FormItem>

        <FormItem icon={<></>} label="End date" isDivider={true}>
          <DateInputMoment
            name={names.end_date}
            value={data.end_date}
            isReadOnly={true}
            onChange={this.onChangeEndDateInput}
            isClearable={false}
            disabledDate={this.disabledEndDates}
            isDisabled={this.doNotEdit()}
            ariaLabel="End date"
          />
        </FormItem>
      </>
    );
  }

  private get submitPayload():
    | EmployeeUnavailabilityRequestPayload
    | undefined {
    return getSubmitPayload(this.state.data);
  }

  get userSitesOptions(): SelectPropsOption[] {
    const { currentUserSiteIds, sites } = this.props;

    if (!currentUserSiteIds) {
      return [];
    }

    return siteIdsToSelectOptionsCombiner(currentUserSiteIds, sites);
  }

  private onSubmit = (
    event: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const { type } = this.props.modal;
    if (this.submitPayload) {
      if (type === 'create' && this.props.create) {
        this.props.create(this.submitPayload);
      }
      if (type === 'edit' && this.props.edit) {
        this.props.edit(this.submitPayload);
      }
      if (this.doNotEdit()) {
        this.props.closeModal();
      }
    }
  };

  private getUntilDate = (endDate: Moment): Moment =>
    createUntilDate(endDate, this.state.data.until);

  private doNothing = () => void 0;

  private onSelectChange = ({ name, value }: OnChangeSelectEvent) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  };

  private onDurationChange = ({ name, value }: OnChangeSelectEvent) => {
    const initialDate = this.props.data.start;
    this.setState({
      data: {
        ...this.state.data,
        ...getDefaultDates(initialDate),
        [name]: value,
      },
    });
  };

  private onInputChange = ({
    currentTarget: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  };

  private onChangeDateInput = (value: Moment | null, name: string) => {
    const { end_date } = this.state.data;
    let endDateValue = end_date.clone();
    if (name === 'start_date' && value && value.isAfter(end_date)) {
      endDateValue = value.clone().add(1, 'day');
    }
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
        end_date: endDateValue,
      },
    });
  };

  private onChangeEndDateInput = (value: Moment | null, name: string) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
        until: this.getUntilDate(value!),
      },
    });
  };

  private onChangeTimeInput = (value: Moment, name: string) => {
    const v = !value ? moment('00:00', 'HH:mm') : value;
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  };

  private onChangeStartEndDateTogether = (
    value: Moment | null,
    name: string
  ) => {
    const newDate: Moment = value!;
    this.setState({
      data: {
        ...this.state.data,
        start_date: moment(newDate),
        end_date: moment(newDate),
        until: this.getUntilDate(newDate),
      },
    });
  };

  private disabledStartDates = (current?: Date): boolean => {
    if (current) {
      return this.state.data.end_date.isBefore(
        moment(current).startOf('day').toDate()
      );
    }

    return true;
  };

  private disabledEndDates = (current?: Date): boolean => {
    if (current) {
      return this.state.data.start_date.isAfter(current);
    }

    return true;
  };

  private disabledUntilDates = (currentValue?: Date): boolean => {
    if (this.selectedSiteTimezone) {
      const currentValueMoment = moment(currentValue).tz(
        this.selectedSiteTimezone,
        true
      );

      return currentValueMoment.isBefore(this.currentTimeOfSite);
    }

    const current = moment(currentValue);
    return current.isBefore(this.currentTimeOfSite);
  };

  private onSiteSelectChange = ({ name, value }: OnChangeSelectEvent) => {
    const { sites } = this.props;
    const site = sites[value];

    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
        site_name: site.name
      },
    });
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  modal: state.employeeDashboard.employeeUnavailability.modal,
  data: state.employeeDashboard.employeeUnavailability.modal.data,
  currentUserId: getCurrentUserId(state),
  currentUser: getCurrentUser(state),
  currentUserSiteIds: currentUserSiteIdsSelector(state),
  sites: getSites(state),
  users: userListSelector(state),
  langPreferences: getLangPreferences(state),
  currentTime: getCurrentTime(state),
});

export default connect(mapStateToProps)(CreatEditModal);
