import React, { memo } from 'react';
import { sum } from 'lodash';
import { convertDecimalToFormattedTime } from 'lib/helpers';
import { BreakIcon, NoBreakIcon } from 'element';
import { createStyles, makeStyles } from '@mui/styles';

type Props = {
  breaks: {
    duration: number; // minutes
  }[];
};

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      fontSize: 17,
    },
    duration: {
      fontSize: 12,
      lineHeight: '14px'
    },
  })
);

const useBreaksDuration = ({ breaks }: Props) =>
  React.useMemo(() => {
    const breaksDurations = breaks.map(({ duration }) => duration);
    return sum(breaksDurations);
  }, [breaks]);

const NoBreaks = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <NoBreakIcon fontSize="inherit" className={classes.icon} />
    </div>
  );
};

const HasBreaks = (props: { duration: number }) => {
  const classes = useStyles();
  const formattedDuration = convertDecimalToFormattedTime(
    (props.duration / 60).toString(10)
  );
  return (
    <div className={classes.wrapper}>
      <BreakIcon fontSize="inherit" className={classes.icon} />
      <span className={classes.duration}>{formattedDuration}</span>
    </div>
  );
};

export const ShiftCardBreaksDuration = memo(function ShiftCardBreaksDuration(
  props: Props
) {
  const breaksDuration = useBreaksDuration(props);

  if (breaksDuration === 0) {
    return <NoBreaks />;
  }

  return <HasBreaks duration={breaksDuration} />;
});
