import { PreloadingHandler } from 'element';
import {
  useSelectorWithProps,
  useTimesheetsNewQuery,
  useUpdateEffect,
} from 'hooks';
import { SERVER_DAY_FORMAT } from 'lib/config';
import { useDateRange } from 'page/TimesheetsWeekly/hooks';
import React from 'react';
import { useDispatch } from 'react-redux';
import { hasPermissionSelector } from 'state/Auth';
import {
  BOX_TIMESHEETS_WEEKLY_SUMMARY_UPDATE,
  getSummaryUpdating,
} from 'state/TimesheetsWeeklyPage';
import { SummaryErrors, SummarySkeleton, SummaryView } from './components';

export function Summary() {
  const dispatch = useDispatch();
  const { query } = useTimesheetsNewQuery();
  const {
    dateRange: { from },
  } = useDateRange(query);
  const { site_id, status } = query;
  const canViewCost = useSelectorWithProps(
    hasPermissionSelector,
    'roster.timesheet.view.labourcostings'
  );

  useUpdateEffect(() => {
    dispatch(
      BOX_TIMESHEETS_WEEKLY_SUMMARY_UPDATE.request({ site_id, from, status })
    );
  }, [dispatch, site_id, from.format(SERVER_DAY_FORMAT), status, canViewCost]);

  if (!canViewCost) {
    return null;
  }

  return (
    <PreloadingHandler
      loadingSelector={getSummaryUpdating}
      skeletonComponent={SummarySkeleton}
    >
      <SummaryErrors>
        <SummaryView />
      </SummaryErrors>
    </PreloadingHandler>
  );
}
