import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import {
  filtersByTypeSelector,
  getIsFilterByTypeOpened,
  rosteredShiftsFilteredSelector, RosterFiltersByType,
  timesheetsFilteredSelector
} from 'state/Roster/RosterFilters';
import { RosteredShift, Timesheet } from '../../../../../../type';
import { groupRostersByUsersSelector } from '../../../../../../state/Roster/RosterWeekView';

type OwnProps = {
  children?: React.ReactNode;
  renderIndex: number;
  closeInput: () => void;
  inlineIsLoading?: boolean;
};

type StateProps = {
  isFilterByTypeOpened: boolean;
  groupedRosters: any;
  filters: RosterFiltersByType;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

export class WithArrowsNav extends Component<Props> {
  public currentEl: any = null;
  private rowIndex: number = 0;
  private colIndex: number = 0;
  private savedIndexes: string = '';

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
    const { groupedRosters, filters } = this.props;
    const rosterDiff = JSON.stringify(groupedRosters) !== JSON.stringify(prevProps.groupedRosters);
    const rosterFilterDiff = filters.roster !== prevProps.filters.roster;
    const timesheetFilterDiff = filters.timesheets !== prevProps.filters.timesheets;
    if ( rosterDiff || rosterFilterDiff || timesheetFilterDiff ) {
      this.destroyArrowsMap();
      this.initArrowsMap();
    }
  }

  setCurrentlyNavigatedItem = () => {
    if ( this.currentEl ) {
      this.savedIndexes = `${this.rowIndex}-${this.colIndex}`;
    }
  };

  navigateToNextCell = () => {
    let tempCol = this.colIndex;
    let tempRow = this.rowIndex;
    const temp = document.querySelector(`.roster-map-${this.rowIndex}-${++tempCol}`);
    if ( temp ) {
      // move between cell in row
      this.currentEl = document.querySelector(`.roster-map-${this.rowIndex}-${++this.colIndex}`);
    } else if ( document.querySelector(`.roster-map-${++tempRow}-${0}`) ) {
      // navigate to next row
      this.colIndex = 0;
      this.currentEl = document.querySelector(`.roster-map-${++this.rowIndex}-${this.colIndex}`);
    }
  };

  navigateToPrevCell = () => {
    let tempCol = this.colIndex;
    let tempRow = this.rowIndex;
    const temp = document.querySelector(`.roster-map-${this.rowIndex}-${--tempCol}`);
    if ( temp ) {
      // move between cell in row
      this.currentEl = document.querySelector(`.roster-map-${this.rowIndex}-${--this.colIndex}`);
    } else if ( document.querySelector(`.roster-map-${--tempRow}-${6}`) ) {
      // navigate to prev row
      this.colIndex = 6;
      this.currentEl = document.querySelector(`.roster-map-${--this.rowIndex}-${this.colIndex}`);
    }
  };

  navigateToUpCell = () => {
    let tempRow = this.rowIndex;
    this.currentEl = document.querySelector(`.roster-map-${--tempRow}-${this.colIndex}`)
      ? document.querySelector(`.roster-map-${--this.rowIndex}-${this.colIndex}`)
      : this.currentEl;
  };

  navigateToBottomCell = () => {
    let tempRow = this.rowIndex;
    this.currentEl = document.querySelector(`.roster-map-${++tempRow}-${this.colIndex}`)
      ? document.querySelector(`.roster-map-${++this.rowIndex}-${this.colIndex}`)
      : this.currentEl;
  };

  isRosterCellInFocus = () => {
    return document.activeElement && document.activeElement.classList.contains('elmo-roster-cell');
  };

  setIndexes = (target?: Element) => {
    let cell = document.activeElement;
    if ( target ) {
      cell = target;
    }
    const cutRowColumn = cell ? cell.className.split('roster-map-') : [];
    if ( !cutRowColumn.length || cutRowColumn.length === 1 ) {
      return false;
    }
    const currentElParams = cutRowColumn[1].split('-');
    this.rowIndex = parseInt(currentElParams[0], 10);
    this.colIndex = parseInt(currentElParams[1], 10);
  };

  setFocusOnCurrent = () => {
    // focusing on current element closes react-select dropdown
    const { isFilterByTypeOpened = false } = this.props;

    if ( this.currentEl && !isFilterByTypeOpened ) {
      this.currentEl.focus();
    }
    this.setCurrentlyNavigatedItem();
    if ( !this.props.inlineIsLoading ) {
      this.props.closeInput();
    }
  };

  openRosteredShiftCard = (card: any) => {
    if ( card && card.className.indexOf('--top') !== -1 ) {
      card.click();
    }
  };

  openTimesheetCard = (card: any) => {
    if ( card && card.className.indexOf('--bottom') !== -1 ) {
      card.querySelector('.shift-card__content')!.click();
    }
  };

  openInlineInputOnRosterCell = () => {
    const emptyCell = this.currentEl.querySelector('.roster-empty-cell');
    if ( emptyCell ) {
      emptyCell.click();
      emptyCell.focus();
    }
  };

  openInlineInputOnTimesheetCell = () => {
    const emptyTimesheetCell = this.currentEl.querySelector('.timesheet-empty-cell');
    if ( emptyTimesheetCell ) {
      emptyTimesheetCell.click();
      emptyTimesheetCell.focus();
    }
  };

  openCardOrInput = () => {
    const card = this.currentEl.querySelector('.shift-card');
    // if rostered shift card -> open shift modal
    this.openRosteredShiftCard(card);
    // if timesheet card -> open shift modal
    this.openTimesheetCard(card);
    // if empty rostered shift cell -> open inline input
    this.openInlineInputOnRosterCell();
    // if empty timesheet cell -> open inline input
    this.openInlineInputOnTimesheetCell();
  };

  openEmptyModal = () => {
    if ( this.currentEl ) {
      const dbClick = new MouseEvent('dblclick', {
        bubbles: true,
        cancelable: true,
        view: window
      });
      const emptyCell = this.currentEl.querySelector('.roster-empty-cell');
      if ( emptyCell ) {
        emptyCell.dispatchEvent(dbClick);
      }
    }
  };

  handleKeyDownListener = (e: KeyboardEvent) => {
    if ( e.code === 'Space' && this.isRosterCellInFocus() ) {
      e.preventDefault();
    }
  };

  handleClickListener = (e: MouseEvent) => {

    if ((e.target as Element).closest('.delete-shift-icon')) {
      this.props.closeInput();
      this.savedIndexes = ``;
    }

    // set current element on click
    if ( this.isRosterCellInFocus() ) {

      this.setIndexes();

      if ( isNaN(this.rowIndex) || isNaN(this.colIndex)) {
        return false;
      }

      this.currentEl = document.querySelector(`.roster-map-${this.rowIndex}-${this.colIndex}`);
      this.setFocusOnCurrent();
    }
  };

  handleContextmenuListener = () => {
    this.props.closeInput();
    this.savedIndexes = ``;
  };

  handleListener = (e: KeyboardEvent) => {

    // hide inline input and focus on the empty cell
    if ( e.code === 'Escape' ) {
      this.props.closeInput();
      this.setFocusOnCurrent();
    }

    if ( this.isRosterCellInFocus() ) {

      e.stopPropagation();

      this.setIndexes();

      if ( isNaN(this.rowIndex) || isNaN(this.colIndex)) {
        return false;
      }

      this.currentEl = document.querySelector(`.roster-map-${this.rowIndex}-${this.colIndex}`);

      // define behaviour on specific key code
      switch (e.code) {

        case 'ArrowRight':
          this.navigateToNextCell();
          this.setFocusOnCurrent();
          break;

        case 'ArrowLeft':
          this.navigateToPrevCell();
          this.setFocusOnCurrent();
          break;

        case 'ArrowUp':
          this.navigateToUpCell();
          this.setFocusOnCurrent();
          break;

        case 'ArrowDown':
          this.navigateToBottomCell();
          this.setFocusOnCurrent();
          break;

        case 'Tab':
          this.setFocusOnCurrent();
          break;

        case 'Enter':
          this.openInputOrModal(e);
          break;

        default:
          break;

      }
    }

  }

  openInputOrModal = (e: KeyboardEvent) => {
    e.shiftKey
      ? this.openEmptyModal()
      : this.openCardOrInput();
  }

  setFocusOnLatestFocusedCell = () => {
    const el = document.querySelector(`.roster-map-${this.savedIndexes}`);
    if ( el ) {
      this.currentEl = el;
      this.setFocusOnCurrent();
    }
  }

  initArrowsMap = () => {
    document.addEventListener('keyup', this.handleListener);
    document.addEventListener('keydown', this.handleKeyDownListener);
    document.addEventListener('click', this.handleClickListener);
    document.addEventListener('contextmenu', this.handleContextmenuListener);
    document.addEventListener('drop', this.handleDropCard);

    this.createCellsMap();
    this.setFocusOnLatestFocusedCell();
  };

  handleDropCard = (e: any) => {
    if ( e ) {
      this.setIndexes(e.target.closest('.roster-focusable'));
      this.currentEl = document.querySelector(`.roster-map-${this.rowIndex}-${this.colIndex}`);
      this.setFocusOnCurrent();
    }
  };

  destroyArrowsMap = () => {
    document.removeEventListener('keyup', this.handleListener);
    document.removeEventListener('keydown', this.handleKeyDownListener);
    document.removeEventListener('click', this.handleClickListener);
    document.removeEventListener('contextmenu', this.handleContextmenuListener);
  }

  componentWillUnmount() {
    this.destroyArrowsMap();
  }

  componentDidMount() {
    this.initArrowsMap();
  }

  getRosterMapRows = () => {
    const rows = document.querySelectorAll('.roster-map');
    return rows.length ? rows : [];
  };

  setClassNamesAndAttrs = (cell: any, params: any) => {
    cell.classList.remove('roster-focusable');
    cell.className = cell.className.replace(/roster-map-[0-9]*-[0-9]*/i, '');
    cell.classList.add('roster-focusable', `roster-map-${params.row}-${params.col++}`);
    cell.setAttribute('tabindex', '0');
  };

  getInitialParams = () => {
    return {
      rows: this.getRosterMapRows(),
      row: -1,
      col: 0
    };
  }

  createCellsMap = () => {
    const params = this.getInitialParams();
    params.rows.forEach((item: any) => {
      const cells = item.querySelectorAll('.elmo-roster-cell') || [];
      params.row++;
      params.col = 0;
      cells.forEach((cell: any) => {
        if ( !cell.classList.contains('elmo-roster-cell--full') ) {
          this.setClassNamesAndAttrs(cell, params);
          if ( params.col === 7 && cell !== cells[cells.length - 1] ) {
            params.col = 0;
            params.row++;
          }
        }
      });
    });
  };

  render() {
    return (
      this.props.children ? this.props.children : <div/>
    );
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  isFilterByTypeOpened: getIsFilterByTypeOpened(state),
  groupedRosters: groupRostersByUsersSelector(state),
  filters: filtersByTypeSelector(state)
});

// const mapDispatchToProps: DispatchProps = {};

export default connect(mapStateToProps)(WithArrowsNav);
