import React from 'react';
import { connect } from 'react-redux';
import { ReportsWithPagerSelector } from 'type';
import { Pager } from 'element';
import { StoreState } from 'state/types';
import { reportsWithPagerSelector } from 'state/Reports/selectors';
import {
  BOX_REPORTS_CHANGE_PAGE,
  BOX_REPORTS_CHANGE_PAGE_SIZE
} from 'state/Reports';

type StateProps = {
  data: ReportsWithPagerSelector;
};
type DispatchProps = {
  changePage: (pageNumber: number) => void;
  changePageSize: (pageSize: number) => void;
};
type Props = StateProps & DispatchProps;
const ReportsPager = (props: Props) => <Pager {...props} />;

const mapStateToProps = (state: StoreState): StateProps => ({
  data: reportsWithPagerSelector(state)
});

const mapDispatchToProps: DispatchProps = {
  changePage: BOX_REPORTS_CHANGE_PAGE,
  changePageSize: BOX_REPORTS_CHANGE_PAGE_SIZE
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsPager);
