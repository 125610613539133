import { FormattedErrors } from '../../type';

export interface MiscReducerState {
  isFetched: boolean;
  isLoading: boolean;
  isUpdating: boolean;
  timezones: string[];
  errors: FormattedErrors;
}

export const defaultState: MiscReducerState = {
  isFetched: false,
  isLoading: false,
  isUpdating: false,
  timezones: [],
  errors: []
};
