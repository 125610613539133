import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SelectPropsOption } from 'elmo-elements/Select';
import { Report, ReportSchedule, StringMap, UserFields } from 'type';
import { StoreState } from 'state/types';
import {
  BOX_REPORT_SCHEDULE_MODAL_CLOSE,
  BOX_REPORT_SCHEDULE_UPDATE_REQUEST
} from 'state/Report';
import {
  userListSelector,
  activeUsersAsSelectOptionsArraySelector
} from 'state/UsersCollection';
import ReportScheduleModal from '../../../ReportScheduleModal';
import { getUsePaySauceFormat } from '../../../../../../state/Account';

type StateProps = {
  isOpened: boolean;
  isLoading: boolean;
  errors: string[];
  report: Report;
  timezone: string;
  users: StringMap<UserFields>;
  usersOptions: SelectPropsOption[];
  usePaySauceFormat: boolean;
};
type DispatchProps = {
  onCloseModal: () => void;
  updateSchedule: (schedule: ReportSchedule) => void;
};
type Props = StateProps & DispatchProps;

export class Schedule extends Component<Props> {
  render() {
    const {report, updateSchedule, usePaySauceFormat, ...rest} = this.props;
    return (
      <ReportScheduleModal
        {...rest}
        onFormSubmit={updateSchedule}
        defaultValues={report.schedule}
        type={report.type}
        usePaySauceFormat={usePaySauceFormat}
      />
    );
  }
}

export const mapStateToProps = (state: StoreState): StateProps => {
  const {
    report: {
      report,
      scheduleModal: { isOpened, isLoading, errors }
    },
    account: {
      account: {
        preferences: { timezone }
      }
    }
  } = state;

  return {
    isOpened,
    isLoading,
    errors,
    report,
    timezone,
    users: userListSelector(state),
    usersOptions: activeUsersAsSelectOptionsArraySelector(state),
    usePaySauceFormat: getUsePaySauceFormat(state)
  };
};

const mapDispatchToProps: DispatchProps = {
  onCloseModal: BOX_REPORT_SCHEDULE_MODAL_CLOSE,
  updateSchedule: BOX_REPORT_SCHEDULE_UPDATE_REQUEST
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Schedule);
