import React from 'react';
import LoaderHolder from './LoaderHolder';
import { LoaderHolderProps } from '../type';
import './NarrowContentStyle.scss';

function NarrowContentStyle(props: LoaderHolderProps) {
  return (
    <LoaderHolder
      viewBoxHeight={400}
      className="elmo-loader-narrow-content"
      {...props}
    >
      <rect x="0" y="10" rx="4" ry="4" width="300" height="25" />
      <rect x="0" y="60" rx="3" ry="3" width="385" height="25" />

      <rect x="0" y="140" rx="3" ry="3" width="350" height="25" />
      <rect x="0" y="190" rx="3" ry="3" width="350" height="25" />

      <rect x="0" y="270" rx="4" ry="4" width="300" height="25" />
      <rect x="0" y="320" rx="3" ry="3" width="385" height="25" />
    </LoaderHolder>
  );
}

export default NarrowContentStyle;
