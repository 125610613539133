import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { flatMap, isEqual, xorWith } from 'lodash';
import { AccountTreeArea, SagaAction, RoleTriple } from 'type';
import { areasBySiteIdSelector, getAreaSafe } from 'state/AccountTree';
import {
  ToggleAreaPayload,
  TogglePayload,
  ToggleRolePayload,
  ToggleSitePayload
} from './types';
import {
  BOX_SITE_AREA_ROLE_TOGGLE,
  BOX_SITE_AREA_ROLE_UPDATE_SELECTED_ROLE_TRIPLES
} from './actions';
import { getSelectedRoleTriples, getSiteId } from './selectors';
import { createRoleTriples, mergeRoles } from './helpers';

const toggleSite = function*({
  siteId,
  isChecked
}: ToggleSitePayload): SagaIterator {
  const areasBySiteId: ReturnType<typeof areasBySiteIdSelector> = yield select(
    areasBySiteIdSelector
  );
  const siteAreas = areasBySiteId[siteId] as AccountTreeArea[] | undefined;

  if (!siteAreas) {
    return;
  }

  const statePayload: ReturnType<typeof getSelectedRoleTriples> = yield select(
    getSelectedRoleTriples
  );

  const roleTriples = flatMap(siteAreas, ({ id, role_ids }: AccountTreeArea) =>
    createRoleTriples(siteId, id, role_ids)
  );

  const newSelectedRoleTriples = mergeRoles(
    statePayload,
    roleTriples,
    isChecked
  );

  yield put(
    BOX_SITE_AREA_ROLE_UPDATE_SELECTED_ROLE_TRIPLES(newSelectedRoleTriples)
  );
};

const toggleArea = function*({
  siteId,
  areaId,
  isChecked
}: ToggleAreaPayload): SagaIterator {
  const area: AccountTreeArea | undefined = yield select(getAreaSafe, areaId);

  if (!area) {
    return;
  }

  const statePayload: ReturnType<typeof getSelectedRoleTriples> = yield select(
    getSelectedRoleTriples
  );

  const roleTriples: RoleTriple[] = createRoleTriples(
    siteId,
    areaId,
    area.role_ids
  );

  const newSelectedRoleTriples = mergeRoles(
    statePayload,
    roleTriples,
    isChecked
  );

  yield put(
    BOX_SITE_AREA_ROLE_UPDATE_SELECTED_ROLE_TRIPLES(newSelectedRoleTriples)
  );
};

const toggleRole = function*(role: ToggleRolePayload): SagaIterator {
  const selectedRoles: ReturnType<typeof getSelectedRoleTriples> = yield select(
    getSelectedRoleTriples
  );

  yield put(
    BOX_SITE_AREA_ROLE_UPDATE_SELECTED_ROLE_TRIPLES(
      xorWith(selectedRoles, [role], isEqual)
    )
  );
};

const getSiteAreaRoleIds = (
  ids: string[],
  selectedSiteId: string | null
): {
  siteId: string;
  areaId: string | undefined;
  roleId: string | undefined;
} => {
  if (selectedSiteId) {
    const [areaId, roleId] = ids;

    return {
      siteId: selectedSiteId,
      areaId,
      roleId
    };
  } else {
    const [siteId, areaId, roleId] = ids;

    return {
      siteId,
      areaId,
      roleId
    };
  }
};

const toggle = function*({
  payload: { ids, isChecked }
}: SagaAction<TogglePayload>): SagaIterator {
  const selectedSiteId: ReturnType<typeof getSiteId> = yield select(getSiteId);

  const { siteId, areaId, roleId } = getSiteAreaRoleIds(ids, selectedSiteId);

  if (!areaId) {
    yield call(toggleSite, {
      siteId,
      isChecked
    });
    return;
  }

  if (!roleId) {
    yield call(toggleArea, {
      siteId,
      areaId,
      isChecked
    });
    return;
  }

  yield call(toggleRole, {
    siteId,
    areaId,
    roleId
  });
};

export const watchSiteAreaRoleModal = function*(): SagaIterator {
  yield takeLatest(BOX_SITE_AREA_ROLE_TOGGLE, toggle);
};
