import makeUniqIdProvider from 'lib/makeUniqIdProvider';
import {
  AsyncActionCreator,
  createReducer,
  EmptyActionCreator,
  SimpleActionCreator,
} from 'lib/store-utils';
import _ from 'lodash';
import { StoreState } from 'state/types';

type LoadingReducerState = {
  pages: string[];
};

export const loading = createReducer<LoadingReducerState>({}, { pages: [] });
const getState = (state: StoreState): LoadingReducerState => state.loading;
const getIsLoading = (state: StoreState, id: string) =>
  getState(state).pages.includes(id);

const uniqIdProvider = makeUniqIdProvider();

export function LoadingHandler(optionalId?: string) {
  const id = uniqIdProvider(optionalId);

  const turnLoadingOn = (state: LoadingReducerState): LoadingReducerState => ({
    ...state,
    pages: [...state.pages, id],
  });

  const turnLoadingOff = (state: LoadingReducerState): LoadingReducerState => ({
    ...state,
    pages: _.without(state.pages, id),
  });

  return {
    loadingOn(action: EmptyActionCreator | SimpleActionCreator<any>) {
      loading.on(action, turnLoadingOn);

      return this;
    },

    loadingOff(action: EmptyActionCreator | SimpleActionCreator<any>) {
      loading.on(action, turnLoadingOff);

      return this;
    },

    handleAsyncLoading(action: AsyncActionCreator<any, any, any, any>) {
      return this.loadingOn(action.request)
        .loadingOff(action.success)
        .loadingOff(action.failure)
        .loadingOff(action.cancel);
    },

    makeSelector() {
      return (state: StoreState) => {
        return getIsLoading(state, id);
      };
    },
  };
}
