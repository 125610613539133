import { MenuItem, TextField } from 'extended-oxygen-elements';
import { useSelectorWithProps } from 'hooks';
import {
  capitalize,
  makeRoleOptionLabel,
  makeRoleOptionValue,
  parseRoleOptionValue,
} from 'lib/helpers';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLangPreferences } from 'state/Account';
import { getAreasSafe, getRolesSafe } from 'state/AccountTree';
import { FieldProps } from 'type';

export type RoleFieldRole = {
  area_id: string;
  role_id: string;
};

type RoleFieldProps = FieldProps<RoleFieldRole> & {
  options: RoleFieldRole[];
};

export default RoleField;

export function RoleField({ options, value, error, onChange }: RoleFieldProps) {
  const langPreferences = useSelector(getLangPreferences);
  const selectedOption = useSelectedOption(value);
  const roleOptions = useRoleOptions(options);

  const selectedValue = makeSelectedValue(selectedOption);
  const roleOptionsWithSelected = makeRoleOptionsWithSelected(
    selectedOption,
    roleOptions
  );

  const handleChange = (event: React.ChangeEvent<{ value: string }>) => {
    onChange(parseRoleOptionValue(event.target.value));
  };

  return (
    <TextField
      select
      label={`${capitalize(langPreferences.area.singular)} - ${
        langPreferences.role.singular
      }`}
      value={selectedValue}
      onChange={handleChange}
      error={!!error}
      helperText={error}
      data-testid={'role-field'}
    >
      {roleOptionsWithSelected.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </TextField>
  );
}

function makeSelectedValue(selectedOption: SelectOption | undefined) {
  return selectedOption?.value || '';
}

function makeRoleOptionsWithSelected(
  selectedOption: SelectOption | undefined,
  roleOptions: SelectOption[]
) {
  if (!selectedOption) {
    return roleOptions;
  }

  const hasSelectedRole = !!roleOptions.find(
    (role) => role.value === selectedOption.value
  );

  if (hasSelectedRole) {
    return roleOptions;
  }

  return [selectedOption, ...roleOptions];
}

function useRoleOptions(options: RoleFieldRole[]) {
  const makeRoleOption = useMakeRoleOption();

  return options
    .map(makeRoleOption)
    .filter((role): role is SelectOption => !!role);
}

function useSelectedOption(selectedOption: RoleFieldRole) {
  return useMakeRoleOption()(selectedOption);
}

type SelectOption = { label: string; value: string };

function useMakeRoleOption() {
  const areas = useSelectorWithProps(getAreasSafe);
  const roles = useSelectorWithProps(getRolesSafe);

  return (role: RoleFieldRole): SelectOption | undefined => {
    const treeArea = areas[role.area_id];
    const treeRole = roles[role.role_id];

    if (!(treeArea && treeRole)) {
      return;
    }

    return {
      label: makeRoleOptionLabel(treeArea.name, treeRole.name),
      value: makeRoleOptionValue(role),
    };
  };
}
