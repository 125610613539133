import {
  SelectPropsOption,
  SelectPropsOptionGrouped
} from 'elmo-elements/Select';
import { flatten, map } from 'lodash';

export const isGroupedOptions = (
  optionsOrGroupedOptions: SelectPropsOption[] | SelectPropsOptionGrouped[]
): optionsOrGroupedOptions is SelectPropsOptionGrouped[] => {
  const firstOption = optionsOrGroupedOptions[0];

  if (firstOption) {
    const { options } = firstOption as SelectPropsOptionGrouped;

    return typeof options !== 'undefined';
  }

  return false;
};

export const ungroupOptions = (options: SelectPropsOptionGrouped[]) =>
  flatten(map(options, option => option.options));
