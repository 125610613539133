import React, { Component, ReactNode } from 'react';
import classNames from 'clsx';
import { Button, Text, Tooltip } from 'elmo-elements';
import { PlaylistAddIcon } from 'element';
import './RosterAreaLegend.scss';
import { convertHex } from 'lib/helpers';
import { StoreState } from '../../../../state/types';
import { BOX_ROSTER_BULK_TOGGLE_SELECTION_BY_AREA_ROLE } from 'state/Roster/BulkActions/actions';
import { connect } from 'react-redux';
import { AreaRolePayload } from 'state/Roster/BulkActions/types';
import _ from 'lodash';

type Duration = {
  icon: ReactNode;
  quantity: number;
  time: string;
  isVisible: boolean;
};

type StateProps = {
  bulkDeleteIsActive: boolean;
};

type DispatchProps = {
  toggleSelectionByAreaRole: (params: AreaRolePayload) => void;
};

type Props = {
  type?: 'single-row' | 'multi-row' | 'always-single-row';
  icon: string;
  label: string;
  durations: Duration[];
  onClick?: () => void;
  colour: string;
  isDisabled?: boolean;
  areaId?: string;
  roleId?: string;
  bulkSelect?: boolean;
  pageType: 'week' | 'day';
} & StateProps &
  DispatchProps;

type State = {
  isOpened: boolean;
};

const fixLabelClassName = (durations: Duration[]) => {
  let labelFixClassname = '';
  let visible = 0;
  durations.forEach(({ isVisible }) => {
    if (isVisible) {
      visible += 1;
    }
  });
  if (visible === 0) {
    labelFixClassname = 'elmo-roster-site-legend--no-label';
  }
  if (visible > 1) {
    labelFixClassname = 'elmo-roster-site-legend--all-label';
  }
  return labelFixClassname;
};

export class RosterAreaLegendComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpened: false
    };
  }

  toggleFullInfo = () => {
    const { isDisabled } = this.props;
    if (!isDisabled) {
      this.setState({
        isOpened: !this.state.isOpened
      });
    }
  };

  onClickHandler = () => {
    const { bulkSelect, bulkDeleteIsActive, areaId, roleId } = this.props;
    if (
      bulkSelect &&
      bulkDeleteIsActive &&
      typeof areaId !== 'undefined' &&
      typeof roleId !== 'undefined'
    ) {
      this.props.toggleSelectionByAreaRole({
        area_id: areaId,
        role_id: roleId
      });
    }
  };

  render() {
    const {
      durations,
      type,
      icon,
      onClick,
      label,
      pageType,
      isDisabled
    } = this.props;
    const { isOpened } = this.state;
    return (
      <div
        onClick={this.onClickHandler}
        id={`${icon}-${_.replace(label, new RegExp(" ","g"), '-')}`}
        className={classNames(
          'elmo-roster-site-legend',
          `elmo-roster-site-legend--${type ? type : 'single-row'}`,
          fixLabelClassName(durations),
          {
            'ui-roster-legend': pageType === 'week'
          }
        )}
      >
        <div
          className={`elmo-roster-site-legend__mobile-card-placeholder ${
            isDisabled ? ' is-disabled' : ''
          }`}
          onClick={this.toggleFullInfo}
        >
          <div
            className="elmo-roster-site-legend__icon-wrapper"
            style={{
              background: this.bgColor,
              color: this.color
            }}
          >
            <div
              className="elmo-roster-site-legend__icon"
              style={{ color: this.color }}
            >
              <Text>{icon}</Text>
            </div>
          </div>
          <div
            className="elmo-roster-site-legend__label"
            style={{ color: this.color}}
          >
            <Text isTruncate={true}>{label}</Text>
          </div>
        </div>

        <div
          className={classNames(
            'elmo-roster-site-legend__desktop-card-placeholder',
            {
              'is-opened': isOpened,
              'is-disabled': isDisabled
            }
          )}
          onClick={this.toggleFullInfo}
        >
          <div
            className="elmo-roster-site-legend__icon-wrapper elmo-roster-site-legend__icon-wrapper--desktop"
            style={{
              background: this.bgColor,
              color: this.color
            }}
          >
            <div
              className="elmo-roster-site-legend__icon"
              style={{ color: `${this.color}` }}
            >
              <Text>{icon}</Text>
            </div>
          </div>

          <div className="elmo-roster-site-legend__content-wrapper">
            <Tooltip title={label}>
              <div
                className="elmo-roster-site-legend__label"
                style={{ color: this.color }}
              >
                <Text isTruncate={true}>{label}</Text>
              </div>
            </Tooltip>
            {durations.map(
              ({ time, quantity, icon: durationIcon, isVisible }, key) => (
                <div
                  className="elmo-roster-site-legend__duration"
                  key={key}
                  style={{
                    display: isVisible ? 'flex' : 'none'
                  }}
                >
                  <div>{durationIcon}</div>

                  <div className="elmo-roster-site-legend__duration-quantity">
                    {quantity}
                  </div>

                  <div className="elmo-roster-site-legend__duration-time">
                    {time}
                  </div>
                </div>
              )
            )}
          </div>

          {
            this.props.pageType === 'day' && <Button
              icon={<PlaylistAddIcon />}
              isText={true}
              onClick={onClick}
              ariaLabel="Add row"
              className={'add-empty-roster-row-button'}
              tooltip={{
                title: 'Add row'
              }}
            />
          }

        </div>
      </div>
    );
  }

  get bgColor() {
    const { colour } = this.props;
    return colour ? convertHex(`#000`, 10) : 'transparent';
  }

  get color() {
    return '#000';
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  bulkDeleteIsActive: state.rosterBulkActions.bulkDelete.isOpened
});

const mapToDispatchProps: DispatchProps = {
  toggleSelectionByAreaRole: BOX_ROSTER_BULK_TOGGLE_SELECTION_BY_AREA_ROLE
};

export const RosterAreaLegend = connect(
  mapStateToProps,
  mapToDispatchProps
)(RosterAreaLegendComponent);
