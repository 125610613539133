import React, { Component, ReactText } from 'react';
import { FormContainer, FormItem, Select } from 'elmo-elements';
import { SelectPropsOption } from 'elmo-elements/Select';
import moment, { Moment } from 'moment';
import {
  formatStringDurationToMinutes,
  getAreaPositionLabel,
  getSelectedAreaRole,
} from 'lib/helpers';
import { DayTimeGroup } from '../DayGroup';
import { Props, State, StateShiftTemplateBreak } from './type';
import { convertTemplatedHrsToDate } from '../../helpers';
import { isSiteView } from '../../../../../../../../helpers';
import { BreakGroup } from 'element/shiftModals/components/BreakGroup';
import { isNaN } from 'lodash';
import { ShiftTemplateBreak, UserFields } from 'type';
import { NotesFieldExpandable } from '../../../../../../../../element/NotesFieldExpandable';

export class EditFormComponent extends Component<Props, State> {
  defaultDate = '2010-01-01';

  constructor(props: Props) {
    super(props);
    this.state = {
      shift: {
        ...props.payload,
        start: convertTemplatedHrsToDate(props.payload.start),
        end: convertTemplatedHrsToDate(props.payload.end),
        breaks: this.breaks,
      },
      dateGroup: false,
    };
  }

  componentDidMount() {
    this.bindFocusOutOptions();
    setTimeout(() => {
      this.setDefaultPosition();
    });
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (prevProps.payload !== this.props.payload) {
      this.setState({
        ...this.state,
        shift: {
          ...this.props.payload,
          start: convertTemplatedHrsToDate(this.props.payload.start),
          end: convertTemplatedHrsToDate(this.props.payload.end),
          breaks: this.breaks,
        },
      });
    }
  }

  isUserHasRole = (user: UserFields) => {
    const {
      payload: { area_id, role_id },
    } = this.props;
    let hasRole = false;
    if (user && user.user_roles) {
      user.user_roles.forEach(
        (role: { role_id: string; area_id: string; site_id: string }) => {
          if (role.role_id === role_id && role.area_id === area_id) {
            hasRole = true;
          }
        }
      );
    }
    if (area_id === '' && role_id === '') {
      return true;
    }
    return hasRole;
  };

  render() {
    const { langPreferences } = this.props;
    const { start, end, day, breaks, notes } = this.state.shift;
    return (
      <FormContainer maxColumns={1} id={'edit-shift-template'}>
        {isSiteView() && (
          <FormItem label={'User'}>
            <Select
              id={'users-dropdown'}
              defaultValue={this.defaultUserValue}
              options={[
                ...[this.defaultUserValue],
                ...this.props.availableUsers.filter((userOption) =>
                  this.isUserHasRole(this.props.users[userOption.value])
                ),
              ]}
              name={'user_id'}
              value={this.getSelectedUser()}
              onChange={(v) => this.setDropDown(v, 'user_id')}
            />
          </FormItem>
        )}

        {!isSiteView() && (
          <FormItem label={getAreaPositionLabel(langPreferences)}>
            <Select
              id={'area-role-dropdown'}
              defaultValue={this.selectedAreaRoleValue}
              options={this.props.areaRoleOptions}
              name={'area_role'}
              onChange={this.setAreaRoleDropDown}
              value={this.selectedAreaRoleValue}
              isDisabled={this.props.areaRoleOptions.length === 2}
            />
          </FormItem>
        )}
        <FormItem className={'day-time-group'}>
          <DayTimeGroup
            onChangeDay={this.setDay}
            onChangeTime={this.setTime}
            onFocusHandler={this.onDateFocusHandler}
            expanded={this.state.dateGroup}
            timeFormat={this.props.timeFormat}
            start={start}
            end={end}
            day={day}
          />

          <div className={'breaks-group'}>
            <BreakGroup
              onChangePicker={this.onChangeBreakPicker}
              onChangeDuration={this.onChangeBreakDuration}
              onChangeCheckbox={this.onChangePaidCheckbox}
              addBreak={this.addBreak}
              breaks={breaks}
              timeFormat={this.props.timeFormat}
            />
          </div>
        </FormItem>
        <FormItem className={'day-time-group'}>
          <NotesFieldExpandable
            value={notes}
            setNotesValue={this.setShift}
            tabIndex={0}
          />
        </FormItem>
      </FormContainer>
    );
  }

  bindFocusOutOptions = () => {
    const modal = document.querySelector('#edit-shift-template');
    if (modal !== null) {
      modal.addEventListener('click', (e: any) => {
        const t = e.target;
        const isPlaceholder: HTMLElement = t.closest('.group-placeholder');
        const isParentExpanded: HTMLElement = t.closest(
          '.group-options-wrapper.expanded'
        );
        this.closeGroupOptions(isParentExpanded, isPlaceholder);
      });
    }
  };

  closeGroupOptions = (
    isParentExpanded: HTMLElement,
    isPlaceholder: HTMLElement
  ) => {
    if (isParentExpanded === null && isPlaceholder === null) {
      const { dateGroup } = this.state;
      if (dateGroup === true) {
        this.setState({
          ...this.state,
          dateGroup: false,
        });
      }
    }
  };

  get breaks() {
    const { breaks, start } = this.props.payload;
    const breakItems: StateShiftTemplateBreak[] = [];
    if (breaks.length) {
      for (let breakItem of breaks) {
        breakItems.push({
          ...breakItem,
          start: convertTemplatedHrsToDate(start).add(
            breakItem.start_diff,
            'seconds'
          ),
        });
      }
    }
    return breakItems;
  }

  get defaultUserValue(): SelectPropsOption {
    return {
      label: 'Unassigned',
      value: 'null',
    };
  }

  setShift = (name: string, value: any) => {
    this.setState(
      {
        ...this.state,
        shift: {
          ...this.state.shift,
          [name]: value,
        },
      },
      () => {
        this.setPayload();
      }
    );
  };

  setPayload = () => {
    const { start, end, breaks, area_id, role_id, ...rest } = this.state.shift;
    // re-set area - role
    const option = this.selectedAreaRoleValue;
    let resetAreaRole = false;
    if (option && (option as SelectPropsOption[])[0]) {
      resetAreaRole = (option as SelectPropsOption[])[0].value === '__';
    }
    // ---
    this.props.setPayload({
      ...rest,
      start: moment(start).format('HH:mm'),
      end: moment(end).format('HH:mm'),
      breaks: this.getFormattedBreaks(breaks),
      area_id: resetAreaRole ? null : area_id,
      role_id: resetAreaRole ? null : role_id,
    });
  };

  getValidBreakParams = (breakItem: StateShiftTemplateBreak) => {
    const {
      shift: { start },
    } = this.state;
    const duration = Math.floor(breakItem.duration);
    let breakDiff = moment(breakItem.start).diff(start, 'seconds');
    if (breakDiff < 0) {
      breakDiff = moment(breakItem.start).add(1, 'day').diff(start, 'seconds');
    }
    return {
      duration: isNaN(duration) || duration < 0 ? 0 : duration,
      paid: breakItem.paid,
      start_diff: breakDiff,
    };
  };

  getFormattedBreaks = (
    breaks: StateShiftTemplateBreak[]
  ): ShiftTemplateBreak[] => {
    const breaksFormatted: ShiftTemplateBreak[] = [];
    if (breaks.length) {
      breaks.forEach((breakItem) => {
        breaksFormatted.push(this.getValidBreakParams(breakItem));
      });
    }
    return breaksFormatted;
  };

  setDropDown = (v: SelectPropsOption, name: string) => {
    if (v.value === 'null') {
      this.setShift(name, null);
    } else {
      this.setShift(name, v.value);
    }
  };

  setAreaRoleDropDown = (v: SelectPropsOption) => {
    const ids = v.value.split('__');
    this.setState(
      {
        ...this.state,
        shift: {
          ...this.state.shift,
          area_id: ids[0] === '' ? null : ids[0],
          role_id: ids[1] === '' ? null : ids[1],
        },
      },
      () => {
        this.setPayload();
      }
    );
  };

  getSelectedUser = () => {
    const { availableUsers } = this.props;
    const { user_id } = this.state.shift;
    let value = this.defaultUserValue;
    availableUsers.forEach((user) => {
      if (user.value === user_id) {
        value = user;
      }
    });
    return value;
  };

  setTime = (time: Moment, name: string) => {
    this.setShift(name, time);
  };

  setDay = (day: ReactText) => {
    this.setShift('day', +day);
  };

  get selectedAreaRoleValue() {
    return getSelectedAreaRole(this.props.areaRoleOptions, this.state.shift);
  }

  addBreak = () => {
    const {
      shift: { breaks },
    } = this.state;
    breaks.push({
      start: moment(`${this.defaultDate} 12:00`),
      duration: 0,
      paid: false,
    });

    this.setShift('breaks', breaks);
  };

  onChangeBreakPicker = (e: Moment, name: string, index: number) => {
    const {
      shift: { breaks },
    } = this.state;
    const time = moment(e).format('HH:mm');
    breaks[index].start = moment(`${this.defaultDate} ${time}`);
    this.setShift('breaks', breaks);
  };

  onChangeBreakDuration = (e: any, name: string, index: number) => {
    const {
      shift: { breaks },
    } = this.state;
    const {
      target: { value },
    } = e;
    const duration = formatStringDurationToMinutes(
      value.toString().replace(/-/i, '')
    );
    breaks[index].duration = isNaN(parseFloat(duration))
      ? 0
      : Math.abs(parseFloat(duration));
    this.setShift('breaks', breaks);
  };

  onChangePaidCheckbox = (e: any, index: number) => {
    const {
      shift: { breaks },
    } = this.state;
    const {
      target: { checked },
    } = e;
    breaks[index].paid = checked;
    this.setShift('breaks', breaks);
  };

  onDateFocusHandler = () => {
    setTimeout(() => {
      this.setState({
        ...this.state,
        dateGroup: true,
      });
    }, 200);
  };

  setDefaultPosition = () => {
    const { areaRoleOptions } = this.props;
    if (areaRoleOptions.length === 2) {
      this.setAreaRoleDropDown(areaRoleOptions[1]);
    }
  };
}

export const EditFormUpdated = EditFormComponent;
