import { Moment } from 'moment';

export const calculateEventDaysQuantity = (
  event: { start: Moment; end: Moment },
  range: { from: Moment; to: Moment }
) => {
  const croppedStart = event.start.isBefore(range.from)
    ? range.from.clone()
    : event.start.clone();
  const croppedEnd = event.end.isAfter(range.to)
    ? range.to.clone()
    : event.end.clone().format('HH:mm') === '00:00'
    ? event.end.clone().subtract(1, 'minute')
    : event.end.clone();

  const roundedStart = croppedStart.clone().startOf('day');
  const roundedEnd = croppedEnd
    .clone()
    .startOf('day')
    .add(1, 'day');

  const dayQuantityPrecise = roundedEnd.diff(roundedStart, 'days', true);

  const roundedDayQuantity = Math.ceil(dayQuantityPrecise);

  return roundedDayQuantity <= 0 ? 0 : roundedDayQuantity;
};
