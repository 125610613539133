import { createReducer } from 'lib/store-utils';
import { OverviewReducerState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';

export const overview = createReducer<OverviewReducerState>({}, getDefaultState());

overview.on(actions.BOX_OVERVIEW_DATA_REQUEST, getDefaultState);

overview.on(actions.BOX_OVERVIEW_GET_OVERVIEW_SUCCESS, (state, payload) => ({
  ...state,
  overview: payload,
}));

overview.on(actions.BOX_OVERVIEW_FETCH_DATA_SUCCESS, (state, payload) => ({
  ...state,
  overview: payload,
}));
