import { createSelector } from 'reselect';
import { fill, map } from 'lodash';
import { Moment } from 'moment';
import { Event } from 'type';
import { StoreState } from 'state/types';
import { currentTimeBySiteTimezoneSelector } from '../../Roster/selectors';
import { filteredEventsSelector } from '../../RosterFilters';
import { DayViewEventRow } from '../types';
import { MIN_EVENTS_ROWS_QUANTITY } from '../constants';
import { groupedByRowsCombiner } from '../combiners';
import { fillTolMinLength } from './helpers/eventsAndForecasts';
import { getEventsAdditionalRows } from './simpleSelectors';

export const eventsGroupedByRowsSelector = createSelector<
  StoreState,
  Event[],
  Moment,
  Event[][]
>(
  filteredEventsSelector,
  currentTimeBySiteTimezoneSelector,
  groupedByRowsCombiner
);

export const eventRowsWithAdditionalRowsSelector = createSelector(
  eventsGroupedByRowsSelector,
  getEventsAdditionalRows,
  (eventRows: Event[][], additionalRowsQuantity: number): Event[][] => {
    const additionalRows: Event[][] = fill(Array(additionalRowsQuantity), []);

    return [...eventRows, ...additionalRows];
  }
);

export const eventRowsSelector = createSelector(
  eventRowsWithAdditionalRowsSelector,
  (withAdditionalRows: Event[][]): DayViewEventRow[] => {
    const withAdditionalRowsWithMinLengthCheck: Event[][] = fillTolMinLength(
      withAdditionalRows,
      [],
      MIN_EVENTS_ROWS_QUANTITY
    );

    return map(
      withAdditionalRowsWithMinLengthCheck,
      (events: Event[]): DayViewEventRow => ({ events })
    );
  }
);
