import { createAction } from 'lib/store-utils';
import { Account, LoginToAccountPayload } from './types';

export const BOX_ACCOUNTS_REQUEST = createAction('Accounts / Get accounts request');
export const BOX_ACCOUNTS_GET_ACCOUNTS_LIST_SUCCESS = createAction<Account[]>('Accounts / Get accounts list success');

export const BOX_ACCOUNTS_UPDATE_SEARCH_QUERY = createAction<string>('Accounts / Get accounts search');

export const BOX_ACCOUNTS_LOGIN_REQUEST = createAction<LoginToAccountPayload>('Accounts / Get accounts login request');
export const BOX_ACCOUNTS_LOGIN_FAILURE = createAction<string[]>('Accounts / Get accounts login failure');

export const BOX_ACCOUNTS_ADMIN_LOGIN_REQUEST = createAction('Accounts / Get accounts admin login request');
