import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ShiftTemplateItem } from 'type';
import {
  BOX_ROSTER_BULK_DELETION_MODE_TOGGLE_SELECTED_SHIFT,
  getIsBulkDeleteOpened,
} from 'state/Roster/BulkActions';
import { BOX_EDIT_TEMPLATE_OPEN_EDIT_MODAL } from 'state/Roster/EditTemplate/actions';
import { ShiftCardViewProps } from '../../views';
import { useSelectorWithProps } from 'hooks';
import { getUserIsArchived } from 'state/UsersCollection';

export const useHandleClick = (
  templateShift: ShiftTemplateItem
): ShiftCardViewProps['onClick'] => {
  const isBulkDelete = useSelector(getIsBulkDeleteOpened);
  const dispatch = useDispatch();

  const { id, user_id, area_id, role_id } = templateShift;

  const isUserArchived = useSelectorWithProps(getUserIsArchived, user_id);

  const handleClickOnBulk = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      dispatch(
        BOX_ROSTER_BULK_DELETION_MODE_TOGGLE_SELECTED_SHIFT({
          id,
          type: 'rostered_shift',
          user_id,
          area_id,
          role_id,
        })
      );
    },
    [dispatch, id, user_id, area_id, role_id]
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      const target = event.target as HTMLDivElement;
      if (
        !target.closest('.delete-shift-icon') &&
        !target.classList.contains('oxygen-dropdown-base__menu-item') &&
        !isUserArchived
      ) {
        dispatch(BOX_EDIT_TEMPLATE_OPEN_EDIT_MODAL(templateShift));
      }
    },
    [dispatch, templateShift, isUserArchived]
  );

  if (isBulkDelete) {
    return handleClickOnBulk;
  }

  if (!isUserArchived) {
    return handleClick;
  }
};
