import React, { ReactNode } from 'react';
import Paragraph from '../../Typography/Paragraph';

export type MessageBlockBodyProps = {
  children: ReactNode;
  id?: string;
};

function MessageBlockBody({ children, id }: MessageBlockBodyProps) {
  return (
    <div
      className="elmo-message-block__body"
      data-testid={`elmo-message-block-body-${id || 'default'}`}
    >
      <Paragraph>{children}</Paragraph>
    </div>
  );
}

export default MessageBlockBody;
