import React, { HTMLAttributes, MouseEvent, MouseEventHandler } from 'react';

type Props = HTMLAttributes<HTMLDivElement>;

export class DelayedClickDiv extends React.Component<Props> {
  private timeout: number = 0;

  render() {
    let { children, onClick, ...restProps } = this.props;
    return (
      <div {...restProps} onClick={this.handleClick}>
        {children}
      </div>
    );
  }

  handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.persist();
    const { onClick, onDoubleClick } = this.props;

    if (onClick && onDoubleClick) {
      this.handleDelayedClick(event, onClick);

      return;
    }

    if (onClick) {
      onClick(event);
    }
  };

  handleDelayedClick = (
    event: MouseEvent<HTMLDivElement>,
    handler: MouseEventHandler<HTMLDivElement>
  ) => {
    const {
      nativeEvent: { detail },
    } = event;

    if (detail > 1) {
      window.clearTimeout(this.timeout);
      return;
    }

    this.timeout = window.setTimeout(() => {
      handler(event);
    }, 400);
  };
}
