import { createStyles, makeStyles } from '@mui/styles';
import { HeaderNavigation } from 'element';
import { useRosterQuery } from 'hooks';
import { SERVER_DAY_FORMAT } from 'lib/config';
import { getWeekRage } from 'lib/helpers';
import moment, { Moment } from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { BOX_ROSTERED_SHIFTS_SET_DAY } from 'state/RosteredShifts/actions';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      height: 48,
    },
    button: {
      maxHeight: '46px!important',
    },
    dateRange: {
      height: 'auto',
      padding: '11px 8px',
      display: 'flex',
    },
    dateRangeHeader: {
      paddingTop: 2,
      fontWeight: 400,
      marginBottom: 0,
    },
  })
);

type Props = {
  className?: string;
};

export const CovidNavigation = ({ className }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    query: { day },
    changeQueryItems,
  } = useRosterQuery();
  const currentDayMoment = moment(day);

  const handleNextClicked = () => {
    const dayFormatted = currentDayMoment
      .clone()
      .add(1, 'week')
      .format(SERVER_DAY_FORMAT);

    changeQueryItems({
      day: dayFormatted,
    });

    dispatch(BOX_ROSTERED_SHIFTS_SET_DAY(dayFormatted));
  };

  const handlePrevClicked = () => {
    const dayFormatted = currentDayMoment
      .clone()
      .subtract(1, 'week')
      .format(SERVER_DAY_FORMAT);

    changeQueryItems({
      day: dayFormatted,
    });

    dispatch(BOX_ROSTERED_SHIFTS_SET_DAY(dayFormatted));
  };

  const handleNavigateTo = (updatedDate: Moment | null) => {
    if (updatedDate) {
      const formattedUpdatedDate = updatedDate.format(SERVER_DAY_FORMAT);

      changeQueryItems({
        day: formattedUpdatedDate,
      });

      dispatch(BOX_ROSTERED_SHIFTS_SET_DAY(formattedUpdatedDate));
    }
  };

  return (
    <HeaderNavigation
      onNextClicked={handleNextClicked}
      onPrevClicked={handlePrevClicked}
      dateRange={getWeekRage(currentDayMoment)}
      selectedDate={currentDayMoment}
      navigateTo={handleNavigateTo}
      classes={classes}
      className={className}
    />
  );
};
