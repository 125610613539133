import { call, put, select } from 'redux-saga/effects';
import { Api, ManagerDashboardGetTimeOffsRequest } from 'lib/Api';
import { processApiRequest } from 'state/ProcessApiRequest';
import {
  BOX_MANAGER_DASHBOARD_CLEAR_ERRORS,
  managerDashboardFiltersSelector
} from '../ManagerDashboard';

export const getTimeOffsWithDashboardFilters = function*(
  payload: ManagerDashboardGetTimeOffsRequest
) {
  yield put(BOX_MANAGER_DASHBOARD_CLEAR_ERRORS());

  const dashboardFilters = yield select(managerDashboardFiltersSelector);

  return yield call(
    processApiRequest,
    Api.ManagerDashboard.getTimeOffs,
    payload,
    dashboardFilters
  );
};
