import { NotificationsReducerState } from './type';

export const defaultState: NotificationsReducerState = {
  isFetched: false,
  isFetching: false,
  isLoading: false,
  isUpdating: false,
  notifications: {
    enable_email: true,
    enable_sms: true,
    show_notifications: true,
    notifications_groups: {
      manager: {},
      employee: {},
    }
  },
  errors: []
};
