import { useTimesheetsNewQuery } from 'hooks';
import { inputBaseClasses, MenuItem, TextField } from 'extended-oxygen-elements';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLangPreferences } from 'state/Account';
import { activeSitesAsSelectOptionsArraySelector } from 'state/AccountTree';
import { getSiteId } from 'state/TimesheetResponse';

export function SiteSelect() {
  const siteId = useSelector(getSiteId);
  const langPreferences = useSelector(getLangPreferences);
  const sitesOptions = useSelector(activeSitesAsSelectOptionsArraySelector);
  const { changeQueryItems } = useTimesheetsNewQuery();

  const handleSitesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeQueryItems({ site_id: event.target.value });
  };

  return (
    <TextField
      select
      label={langPreferences.site.singular}
      value={siteId}
      onChange={handleSitesChange}
      margin="none"
      name="site_select"
      sx={(theme) => {
        const { font, padding } = theme.componentsConfig.button.large;
        return {
          [`& .${inputBaseClasses.input}`]: {
            lineHeight: font.lineHeight + 'px',
            paddingTop: padding.topBottom + 'px',
            paddingBottom: padding.topBottom + 'px',
          },
        };
      }}
    >
      {sitesOptions.map((siteOption) => (
        <MenuItem key={siteOption.value} value={siteOption.value}>
          {siteOption.label}
        </MenuItem>
      ))}
    </TextField>
  );
}
