import React from 'react';
import { DialogProps } from '../type';
import Modal from '../../Modal';
import Button from '../../Button';
import { InfoOutlinedIcon } from '../../icons';

function InfoDialog({
  id,
  title,
  content,
  onCancel,
  onOk,
  okLabel,
  cancelLabel,
  withoutIcon,
}: DialogProps) {
  return (
    <Modal
      id={id}
      {...(withoutIcon ? {} : { icon: <InfoOutlinedIcon /> })}
      type="dialog"
      isOpened={true}
      title={title}
      hasCloseFooterButton={false}
      primaryButton={
        <Button type="primary" isBlock={true} onClick={onOk}>
          {okLabel}
        </Button>
      }
    >
      {content}
    </Modal>
  );
}

InfoDialog.defaultProps = {
  okLabel: 'Ok',
};

export default InfoDialog;
