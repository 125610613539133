import { LoadingHandler } from 'state/Loading';
import { StatusHandler } from 'state/Status';
import {
  BOX_ACCOUNT_ROSTERED_SHIFT_APPLY_CUSTOMISED_BREAKS,
  BOX_ACCOUNT_ROSTERED_SHIFT_SETTINGS_DEFAULT_BREAKS_RESET,
  BOX_ACCOUNT_TIMESHEET_APPLY_CUSTOMISED_BREAKS,
  BOX_ACCOUNT_TIMESHEET_DEFAULT_BREAKS_RESET,
  BOX_ACCOUNT_TIMEZONES,
} from './actions';
import { ErrorsHandler } from '../Errors';

export const getDefaultBreaksLoading = LoadingHandler()
  .handleAsyncLoading(BOX_ACCOUNT_ROSTERED_SHIFT_SETTINGS_DEFAULT_BREAKS_RESET)
  .handleAsyncLoading(BOX_ACCOUNT_TIMESHEET_DEFAULT_BREAKS_RESET)
  .makeSelector();

export const getCustomBreaksLoading = LoadingHandler()
  .handleAsyncLoading(BOX_ACCOUNT_ROSTERED_SHIFT_APPLY_CUSTOMISED_BREAKS)
  .handleAsyncLoading(BOX_ACCOUNT_TIMESHEET_APPLY_CUSTOMISED_BREAKS)
  .makeSelector();

export const getCustomBreaksStatus = StatusHandler()
  .handleAsyncStatus(BOX_ACCOUNT_ROSTERED_SHIFT_APPLY_CUSTOMISED_BREAKS)
  .handleAsyncStatus(BOX_ACCOUNT_TIMESHEET_APPLY_CUSTOMISED_BREAKS)
  .makeSelector();

export const getDefaultBreaksStatus = StatusHandler()
  .handleAsyncStatus(BOX_ACCOUNT_ROSTERED_SHIFT_SETTINGS_DEFAULT_BREAKS_RESET)
  .handleAsyncStatus(BOX_ACCOUNT_TIMESHEET_DEFAULT_BREAKS_RESET)
  .makeSelector();

export const getTimezonesListLoading = LoadingHandler()
  .handleAsyncLoading(BOX_ACCOUNT_TIMEZONES)
  .makeSelector();

export const getAccountPreferencesPageErrors = ErrorsHandler()
  .handleAsyncErrors(BOX_ACCOUNT_TIMEZONES)
  .makeSelector();
