import React, { useState } from 'react';

export const Circle = (options: google.maps.CircleOptions) => {
  const [circle, setCircle] = useState<google.maps.Circle>();
  const props = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 1,
    fillColor: "#FF0000",
    fillOpacity: 0.1,
  };
  React.useEffect(() => {
    if (!circle) {
      setCircle(new google.maps.Circle());
    }
    return () => {
      if (circle) {
        circle.setMap(null);
      }
    };
  }, [circle]);


  React.useEffect(() => {
    if (circle) {
      circle.setOptions({
        ...props,
        ...options
      });
    }
  }, [circle, options]);

  return null;
};