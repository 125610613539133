import { DateRangeFieldGroupValue } from '../types';
import { getDateRangeMoment } from './get-date-value-utils';

export const getDateRangeDurationMinutes = (
  formFields: DateRangeFieldGroupValue
) => {
  const { startMoment, endMoment } = getDateRangeMoment(formFields);
  return Math.max(endMoment.diff(startMoment, 'minutes'), 0);
};

export default getDateRangeDurationMinutes;
