import { createStyles, makeStyles } from '@mui/styles';
import {
  CancelOutlinedIcon,
  CheckCircleOutlinedIcon,
  DurationField,
} from 'element';
import { Box, IconButton, MenuItem, TextField } from 'extended-oxygen-elements';
import {
  useFormFields,
  useFormValidator,
  useSetFormField,
  useUpdateStatusEffect,
} from 'hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  getPayConditionFormUpdating,
  getPayConditionsFormRequestStatus,
} from 'state/PayConditionsPage';
import {
  getDefaultPayElementId,
  getPayElementIds,
  getPayElements,
} from 'state/PayElementsCollection';
import { Dictionary } from 'ts-essentials';
import { RequiredNonNullable } from 'type';
import LoadingsTableRow from '../../components/LoadingsTableRow';
import LoadingsTableCell from '../../components/LoadingsTableCell';
import { usePayConditionsListContext } from '../../pay-conditions-list-context';

export type PayConditionFormProps = {
  onSubmit: (formFields: FormPayload) => void;
  initialState?: FormFields;
};

type FormFields = {
  from_minutes: number | null;
  to_minutes: number | null;
  pay_element_id: string;
};

type FormPayload = RequiredNonNullable<FormFields>;
type ErrorsCollection = Partial<Dictionary<string, keyof FormFields>>;

const useStyles = makeStyles(() =>
  createStyles({
    cellAlignTop: {
      verticalAlign: 'top',
    },
  })
);

export default function PayConditionForm({
  onSubmit,
  initialState = { from_minutes: null, to_minutes: null, pay_element_id: '' },
}: PayConditionFormProps) {
  const loading = useSelector(getPayConditionFormUpdating);
  const { close } = usePayConditionsListContext();

  useUpdateStatusEffect(getPayConditionsFormRequestStatus, {
    success: close,
  });

  const classes = useStyles();
  const payElementIds = useSelector(getPayElementIds);
  const payElements = useSelector(getPayElements);
  const defaultPayElementId = useSelector(getDefaultPayElementId);

  const { formFields, setFormFields } = useFormFields(initialState);
  const { setFormField, makeSetFormField } = useSetFormField(setFormFields);
  const { formErrors, submitForm } = useFormValidator(formFields, {
    loading,
    validate,
    makePayload,
    onSubmit,
  });

  const handleFromValueChange = makeSetFormField('from_minutes');
  const handleToValueChange = makeSetFormField('to_minutes');

  const handlePayElementChange = (
    event: React.ChangeEvent<{ value: string }>
  ) => {
    setFormField('pay_element_id', event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      submitForm();
    }
  };

  const handleCancelBtnClick = () => {
    if (loading) {
      return;
    }
    close();
  };

  return (
    <LoadingsTableRow data-testid={'pay-condition-item-row'}>
      <LoadingsTableCell
        className={classes.cellAlignTop}
        data-testid={'pay-condition-item-from'}
      >
        <DurationField
          margin="none"
          variant="standard"
          formatUnit="hours"
          defaultUnit="hours"
          value={formFields.from_minutes}
          onValueChange={handleFromValueChange}
          onKeyDown={handleKeyDown}
          error={!!formErrors.from_minutes}
          helperText={formErrors.from_minutes}
          InputProps={{
            readOnly: loading,
          }}
          autoFocus
        />
      </LoadingsTableCell>

      <LoadingsTableCell
        className={classes.cellAlignTop}
        data-testid={'pay-condition-item-to'}
      >
        <DurationField
          margin="none"
          variant="standard"
          formatUnit="hours"
          defaultUnit="hours"
          value={formFields.to_minutes}
          onValueChange={handleToValueChange}
          onKeyDown={handleKeyDown}
          error={!!formErrors.to_minutes}
          helperText={formErrors.to_minutes}
          InputProps={{
            readOnly: loading,
          }}
        />
      </LoadingsTableCell>

      <LoadingsTableCell
        className={classes.cellAlignTop}
        data-testid={'pay-condition-item-element'}
      >
        <Box width={300}>
          <TextField
            select
            margin="none"
            value={formFields.pay_element_id}
            onChange={handlePayElementChange}
            error={!!formErrors.pay_element_id}
            helperText={formErrors.pay_element_id}
            SelectProps={{
              displayEmpty: true,
            }}
            disabled={formFields.pay_element_id === defaultPayElementId}
          >
            <MenuItem value="" disabled>
              Please select
            </MenuItem>

            {payElementIds.map((payElementId) => {
              const payElement = payElements[payElementId];

              return (
                <MenuItem
                  key={payElement.id}
                  value={payElement.id}
                  disabled={payElement.is_default}
                >
                  {payElement.name}
                </MenuItem>
              );
            })}
          </TextField>
        </Box>
      </LoadingsTableCell>

      <LoadingsTableCell padding="checkbox">
        <IconButton
          disabled={loading}
          onClick={handleCancelBtnClick}
          data-testid={'pay-condition-item-cancel-btn'}
        >
          <CancelOutlinedIcon />
        </IconButton>
      </LoadingsTableCell>

      <LoadingsTableCell padding="checkbox">
        <IconButton
          size="small"
          loading={loading}
          onClick={submitForm}
          color="primary"
          data-testid={'pay-condition-item-submit-btn'}
        >
          <CheckCircleOutlinedIcon />
        </IconButton>
      </LoadingsTableCell>
    </LoadingsTableRow>
  );
}

function validate({
  from_minutes,
  to_minutes,
  pay_element_id,
}: FormFields): ErrorsCollection {
  let errors: ErrorsCollection = {};

  if (!pay_element_id) {
    errors.pay_element_id = 'Field is required';
  }

  if (from_minutes === null) {
    errors.from_minutes = 'Field is required';
  }

  if (to_minutes === null) {
    errors.to_minutes = 'Field is required';
  }

  if (
    to_minutes !== null &&
    from_minutes !== null &&
    from_minutes >= to_minutes
  ) {
    errors.to_minutes = 'Must be greater than From';
  }

  return errors;
}

function makePayload({
  from_minutes,
  to_minutes,
  pay_element_id,
}: FormFields): FormPayload | undefined {
  if (from_minutes === null) {
    return;
  }

  if (to_minutes === null) {
    return;
  }

  if (!pay_element_id) {
    return;
  }

  return {
    from_minutes,
    to_minutes,
    pay_element_id,
  };
}
