import React from 'react';
import _ from 'lodash';

type ContextValue = {
  isEnabled: boolean;
  selectedIds: string[];
  enable: () => void;
  disable: () => void;
  toggleId: (id: string) => void;
};

export default function makeBulkContext() {
  const Context = React.createContext({} as ContextValue);

  type ProviderProps = {
    children: React.ReactNode;
  };

  function Provider({ children }: ProviderProps) {
    const [isEnabled, setIsEnabled] = React.useState(false);
    const [selectedIds, setSelectedIds] = React.useState<string[]>([]);

    const enable = React.useCallback(() => {
      setIsEnabled(true);
      setSelectedIds([]);
    }, []);

    const disable = React.useCallback(() => {
      setIsEnabled(false);
    }, []);

    const toggleId = React.useCallback((id: string) => {
      setSelectedIds((prevSelectedIds) => _.xor(prevSelectedIds, [id]));
    }, []);

    return (
      <Context.Provider
        value={{
          isEnabled,
          selectedIds,
          enable,
          disable,
          toggleId,
        }}
      >
        {children}
      </Context.Provider>
    );
  }

  function useContext() {
    return React.useContext(Context);
  }

  return { Provider, useContext };
}
