import moment from 'moment';
import { TIME_FORMAT } from 'lib/config';
import { formatTimeTo12Hours } from './formatTimeTo12Hours';

export function formatTimeToMinutes(timeString: any): number | never {
  const formattedTimeString = formatTimeTo12Hours(timeString);
  return moment(formattedTimeString, TIME_FORMAT.hours_12).diff(
    moment().startOf('day').add(),
    'minutes'
  );
}
