import React, { useState } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';
import {
  ErrorBox,
  PageDialog,
  PageDialogCancel,
  PageDialogSubmit,
} from 'element';
import { useDispatch, useSelector } from 'react-redux';
import {
  BOX_USERS_ASSIGN_LOCATION_BULK_MODAL_CLEAR_ERRORS,
  BOX_USERS_ASSIGN_LOCATION_BULK_MODAL_CLOSE,
  BOX_USERS_ASSIGN_LOCATION_BULK_MODAL_REQUEST,
  getBulkAssignLocationModal,
} from 'state/Users/Users';
import { activeSitesAsSelectOptionsArraySelector } from 'state/AccountTree';
import { MenuItem, Select } from 'extended-oxygen-elements';
import { FormItem } from 'elmo-elements';
import { getLangPreferences } from 'state/Account';

export const AssignLocationsModal = () => {
  const sitesDropDownOptions = useSelector(
    activeSitesAsSelectOptionsArraySelector
  );
  const langPreferences = useSelector(getLangPreferences);
  const modal = useSelector(getBulkAssignLocationModal);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState('');

  const onSelectChanged = (e: any) => {
    setSelected(e.target.value);
  };

  const clearSelected = () => setSelected('');

  const onClose = () => {
    dispatch(BOX_USERS_ASSIGN_LOCATION_BULK_MODAL_CLOSE());
    clearSelected();
  };

  const onSubmit = () => {
    dispatch(BOX_USERS_ASSIGN_LOCATION_BULK_MODAL_REQUEST(selected));
  };

  const clearErrors = () => {
    dispatch(BOX_USERS_ASSIGN_LOCATION_BULK_MODAL_CLEAR_ERRORS());
  };

  return (
    <PageDialog
      maxWidth={'xs'}
      open={modal.isOpened}
      id="assign-locations-modal"
    >
      <DialogTitle>Assign {langPreferences.site.singular}</DialogTitle>
      <DialogContent>
        {!!modal.errors.length && (
          <ErrorBox errors={modal.errors} clearErrors={clearErrors} />
        )}
        <FormItem label={`Select ${langPreferences.site.singular}`}>
          <Select
            fullWidth
            id="locations-dropdown"
            name={'locations_dropdown'}
            onChange={onSelectChanged}
            value={selected}
            aria-label={`Select ${langPreferences.site.singular}`}
          >
            {sitesDropDownOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormItem>
      </DialogContent>
      <DialogActions>
        <PageDialogCancel fullWidth={false} onClick={onClose}>
          Cancel
        </PageDialogCancel>
        <PageDialogSubmit
          fullWidth={false}
          onClick={onSubmit}
          disabled={!selected || modal.isUpdating}
          loading={modal.isUpdating}
          data-testId={'assign-button'}
        >
          Assign
        </PageDialogSubmit>
      </DialogActions>
    </PageDialog>
  );
};
