import React, { Component } from 'react';
import { ModalProps } from './type';
import LayoutContext from '../LayoutContext';
import ModalWrapper from './component/ModalWrapper';

class Modal extends Component<ModalProps> {
  static defaultProps: Partial<ModalProps> = {
    type: 'medium',
  };

  render() {
    return (
      <LayoutContext.Consumer>
        {({ toggleModalOpen }: any) => {
          return (
            <ModalWrapper {...this.props} toggleModalOpen={toggleModalOpen} />
          );
        }}
      </LayoutContext.Consumer>
    );
  }
}

export default Modal;
