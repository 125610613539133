import { StoreState } from '../../types';
import { RosteredShift } from '../../../type/models';
import { RosteredShiftModalProps } from './types';
import { StringMap, Event } from '../../../type';

export const getIsUpdating = (state: StoreState): boolean =>
  state.rosterShiftModal.isUpdating;
export const getCurrentRosteredShift = (state: StoreState): RosteredShift =>
  state.rosterShiftModal.currentRosteredShift;
export const getRosteredShiftModal = (
  state: StoreState
): RosteredShiftModalProps => state.rosterShiftModal.rosteredShiftModal;
export const getUsersIsLoading = (state: StoreState): boolean =>
  state.rosterShiftModal.usersLoader;
export const getReAcceptValue = (state: StoreState): boolean =>
  !!state.rosterShiftModal.currentRosteredShift.need_re_accept;
export const getInitialOwner = (state: StoreState): null | string =>
  state.rosterShiftModal.initialOwner;
export const getRosterEventsLists = (state: StoreState): StringMap<Event> =>
  state.rosterShiftModal.eventsList;
export const getRosteredUsersList = (state: StoreState) =>
  state.rosterShiftModal.usersList;
