import { AutoApproveShifts, Timesheet } from '../../../type/models';
import moment, { Moment } from 'moment';

type TimesheetPayload = Pick<
  Timesheet,
  'is_approved' | 'is_violated' | 'is_self_assigned' | 'is_approved'
>;
export const doesTimesheetNeedApproval = ({
  timesheet,
  autoApproveTimesheets
}: {
  timesheet: TimesheetPayload;
  autoApproveTimesheets: AutoApproveShifts;
}): boolean => {
  if (!timesheet.is_approved) {
    return false;
  }

  const config: {
    [key in AutoApproveShifts]:
      | boolean
      | ((payload: TimesheetPayload) => boolean)
  } = {
    'all-shifts': false,
    'without-violation': ({ is_violated }) => is_violated,
    'except-self-assigned': ({ is_violated, is_self_assigned }) =>
      is_violated || is_self_assigned,
    none: true
  };

  const resultOrCheckFunction = config[autoApproveTimesheets];

  return typeof resultOrCheckFunction === 'boolean'
    ? resultOrCheckFunction
    : resultOrCheckFunction(timesheet);
};
