import {
  makeDefaultBreakRuleFields,
  SettingsLayout,
} from 'page/Settings/components';
import React from 'react';
import { useActions, useFormFields, useFormValidator, useRoute } from 'hooks';
import {
  BOX_ACCOUNT_TIMECLOCK_SETTINGS_UPDATE,
  getTimeClockSettings,
} from 'state/Account';
import { useSelector } from 'react-redux';
import makeFormFields from './makeFormFields';
import validate from './validate';
import makePayload from './makePayload';
import { privateRoutes } from 'routes';
import TimeClockFieldGroup from './TimeClockFieldGroup';

export default function TimeClockSettings() {
  const settingsUpdate = useActions(
    BOX_ACCOUNT_TIMECLOCK_SETTINGS_UPDATE.request
  );
  const route = useRoute();

  const settings = useSelector(getTimeClockSettings);

  const { formFields, setFormFields } = useFormFields(() =>
    makeFormFields(settings)
  );

  const { formErrors, submitForm } = useFormValidator(formFields, {
    validate,
    makePayload,
    onSubmit: settingsUpdate,
  });

  const handleSuccess = () => {
    route.goTo(privateRoutes.settings.path);
  };

  return (
    <SettingsLayout
      title="Time Clock Settings"
      onSubmit={submitForm}
      onSuccess={handleSuccess}
    >
      <TimeClockFieldGroup
        values={formFields}
        onChange={setFormFields}
        errors={formErrors}
      />
    </SettingsLayout>
  );
}
