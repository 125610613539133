import { createAction } from 'lib/store-utils';
import { FormattedErrors, RosterTimesheetListedWithPayEntries } from 'type';
import { createAsyncAction } from 'lib/store-utils';
import { TimesheetSimpleCreateRequest } from 'lib/Api';
import { CreateTimesheetDialogOpenedPayload } from './types';

export const BOX_CREATE_TIMESHEET_DIALOG_OPENED = createAction<CreateTimesheetDialogOpenedPayload>('Create timesheet dialog / opened');
export const BOX_CREATE_TIMESHEET_DIALOG_CLOSED = createAction('Create timesheet dialog / closed');
export const BOX_CREATE_TIMESHEET_DIALOG_ERRORS_CLOSED = createAction('Create timesheet dialog / errors closed');

export const BOX_CREATE_TIMESHEET_DIALOG_SUBMIT = createAsyncAction<TimesheetSimpleCreateRequest, RosterTimesheetListedWithPayEntries, FormattedErrors>('Create timesheet dialog / submit');
