import React, { Component } from 'react';
import RosterRowGroup from '../../../../components/ShiftCard/components/RosterRowGroup';
import {
  RosterFiltersByType,
  filteredEventsSelector,
} from 'state/Roster/RosterFilters';
import { RosterEventsSingleRow } from '../RosterEventsSingleRow';
import { GetEventByIdPayload } from 'state/Roster/EventModal/types';
import { BOX_EVENT_MODAL_OPEN } from 'state/Roster/EventModal';
import { connect } from 'react-redux';
import { groupEvents } from 'state/Roster/RosterWeekView';
import { StoreState } from 'state/types';
import { appendRowForEvents } from '../../../../helpers';
import { Event } from 'type';
import './CollapsableEventsRow.scss';
import { getIsCovidModeEnabled } from 'state/Roster/CovidTracing/selectors';

type DispatchProps = {
  getEventById: (payload: GetEventByIdPayload) => void;
};

type StateProps = {
  eventsWeek: any;
  events: Event[];
  isCovidMode: boolean;
};

type Props = {
  isBulkDelete: boolean;
  eventsWeek: any;
  filters: RosterFiltersByType;
} & DispatchProps &
  StateProps;

type State = {
  eventsWeek: any;
  isCollapsed: boolean | undefined;
};

export class WeekEventsPanelComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      eventsWeek: {
        rostersRowNumber: 0,
        rosters: [],
      },
      isCollapsed: true,
    };
  }

  initEventsLayout = () => {
    this.setState({
      ...this.state,
      eventsWeek: this.props.eventsWeek,
    });
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (
      prevProps.eventsWeek !== this.props.eventsWeek ||
      prevProps.events !== this.props.events
    ) {
      this.initEventsLayout();
    }
  }

  componentDidMount() {
    this.initEventsLayout();
  }

  appendEmptyEventRow = () => {
    this.setState((prevState) => ({
      ...prevState,
      eventsWeek: appendRowForEvents(prevState.eventsWeek),
    }));
  };

  toggleRow = () => {
    this.setState({
      ...this.state,
      isCollapsed: !this.state.isCollapsed,
    });
  };

  render() {
    const { isBulkDelete, filters, getEventById, isCovidMode } = this.props;
    const { eventsWeek } = this.state;
    return (
      <RosterRowGroup
        type={'events'}
        data={{
          user: null,
          area: null,
        }}
        duration={[]}
        actions={isBulkDelete ? () => false : () => this.appendEmptyEventRow()}
        toggleRow={!isCovidMode ? this.toggleRow : () => false}
        legendType={'multi-row'}
        selectedFilter={filters}
        isCollapsed={!isCovidMode ? this.state.isCollapsed : true}
        pageType={'week'}
      >
        <div className="week-rosters-grid__blocks events">
          <RosterEventsSingleRow
            user_id={null}
            viewType={'user'}
            data={eventsWeek}
            onClickCell={getEventById}
            isUnassigned={true}
          />
        </div>
      </RosterRowGroup>
    );
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  eventsWeek: groupEvents(state),
  events: filteredEventsSelector(state),
  isCovidMode: getIsCovidModeEnabled(state),
});

const mapDispatchToProps: DispatchProps = {
  getEventById: BOX_EVENT_MODAL_OPEN,
};

export const WeekEventsPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(WeekEventsPanelComponent);
