import React from 'react';
import { ListTableContextProps } from './type';
import LayoutContext, { LayoutContextProps } from '../LayoutContext';
import ListTableContext from './ListTableContext';

export type ListTableConsumerProps = Partial<
  ListTableContextProps & LayoutContextProps
>;

export const withListTableContext = <P extends ListTableConsumerProps>(
  WrappedComponent: React.ComponentType<P>
): React.ComponentClass<any> => {
  return class extends React.Component<P> {
    static displayName: string = WrappedComponent.displayName
      ? WrappedComponent.displayName
      : '';

    render() {
      return (
        <LayoutContext.Consumer>
          {(layoutProps: any) => (
            <ListTableContext.Consumer>
              {(listTableProps: any) => (
                <WrappedComponent
                  {...(listTableProps.hasLayout ? layoutProps : {})}
                  {...listTableProps}
                  {...this.props}
                />
              )}
            </ListTableContext.Consumer>
          )}
        </LayoutContext.Consumer>
      );
    }
  };
};
