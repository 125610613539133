import React, { ChangeEvent, useEffect, useState } from 'react';
import { useRef } from 'react';
import { TextField } from 'extended-oxygen-elements';
import { AccountTreeSite } from '../../../../../../type';
import { makeStyles } from '@mui/styles';

type Place = {
  formatted_address: string,
  address_lat: number,
  address_lng: number
};

type Props = {
  location: AccountTreeSite;
  isDisabled: boolean;
  setSettings: (id: string, options: any) => void;
};

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      background: '#ffffff'
    },
    '& .Mui-disabled': {
      background: '#F4F5F6'
    }
  }
}));

const SearchLocationInput = ({ location, isDisabled, setSettings }: Props) => {
  const classes = useStyles();

  const ref = useRef(null)
  const [searchValue, setSearchValue] = useState(
    location.formatted_address
      ? location.formatted_address
      : ''
  );
  const [results, setResults] = useState([] as Place[]);
  const [showAutocomplete, setShowAutocomplete] = useState(false);

  useEffect(() => {
    setSearchValue(location.formatted_address
      ? location.formatted_address
      : ''
    );
  }, [location.formatted_address]);

  const onSearch = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.currentTarget;
    setSearchValue(value);
    if ( value.length ) {
      searchAddress();
    } else {
      setSettings(location.id, {
        formatted_address: null,
        address_lat: null,
        address_lng: null
      });
      setShowAutocomplete(false);
    }
  };

  const onBlur = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTimeout(() => {
      setShowAutocomplete(false);
    }, 1000);
  };

  const selectPlace = (e: any, place: Place) => {
    e.stopPropagation();
    setSettings(location.id, place);
    setShowAutocomplete(false);
  };

  const searchAddress = () => {
    if ( typeof google !== 'undefined' ) {
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode({'address': searchValue}, (results: any) => {
        setResults([]);
        if (typeof results !== 'undefined' && results !== null) {
          const data: Place[] = [];
          results.map((place: any) => {
            data.push({
              formatted_address: place.formatted_address,
              address_lat: place.geometry.location.lat(),
              address_lng: place.geometry.location.lng()
            });
          });
          setResults(data);
          setShowAutocomplete(data.length > 0);
        }
      });
    }
  };

  return (
    <div className={'address-results'}>
      <TextField
        id={`search__${location.id}`}
        type={'input'}
        label={'Address'}
        ref={ref}
        name={`search__${location.id}`}
        value={isDisabled ? '' : searchValue}
        onChange={onSearch}
        onBlur={onBlur}
        disabled={isDisabled}
        placeholder={'Address'}
        autoComplete={'off'}
        className={classes.root}
      />
      {
        showAutocomplete && results.length
          ?  <div className={'address-results__wrapper'}>
              <ul className="address-results__list">
                {
                  results.map((place: Place, key: number) => (
                    <li key={key} onClick={e=> selectPlace(e, place)}>
                      {place.formatted_address}
                    </li>
                  ))
                }
              </ul>
            </div>
          : <></>
      }
    </div>
  )
};

export default SearchLocationInput;
