import React from 'react';
import {
  ShiftOffersFilters,
  ShiftOffersListTable,
  ShiftOffersPager
} from './components';

const ShiftOffersView = () => (
  <>
    <ShiftOffersFilters />

    <ShiftOffersListTable />

    <ShiftOffersPager />
  </>
);

export default ShiftOffersView;
