import Api from 'lib/Api';
import { SagaIterator } from 'redux-saga';
import { put, select, takeLatest } from 'redux-saga/effects';
import { formatError } from 'state/helpers';
import { apiWithConfirmCall } from 'state/ProcessApiRequest';
import {
  ApiWithConfirmReturnType,
  SagaActionFromCreator,
} from 'type';
import * as actions from '../actions';
import { getRosteredShiftSettings } from '../selectors';

function* updateRosteredShiftSettings({
  payload,
}: SagaActionFromCreator<
  typeof actions.BOX_ACCOUNT_ROSTERED_SHIFT_SETTINGS_UPDATE.request
>) {
  try {
    const response: ApiWithConfirmReturnType<typeof Api.Account.update> =
      yield apiWithConfirmCall(Api.Account.update, {
        rostered_shift_settings: payload,
      });

    if (response) {
      yield put(
        actions.BOX_ACCOUNT_ROSTERED_SHIFT_SETTINGS_UPDATE.success(response)
      );
    } else {
      yield put(actions.BOX_ACCOUNT_ROSTERED_SHIFT_SETTINGS_UPDATE.cancel());
    }
  } catch (error) {
    yield put(
      actions.BOX_ACCOUNT_ROSTERED_SHIFT_SETTINGS_UPDATE.failure(
        formatError(error)
      )
    );
  }
}

function* resetDefaultBreaksToDefaults({
  payload: { apply_default_breaks },
}: SagaActionFromCreator<
  typeof actions.BOX_ACCOUNT_ROSTERED_SHIFT_SETTINGS_DEFAULT_BREAKS_RESET.request
>): SagaIterator {
  try {
    const rosteredShiftSettings: ReturnType<typeof getRosteredShiftSettings> =
      yield select(getRosteredShiftSettings);

    const response: ApiWithConfirmReturnType<typeof Api.Account.update> =
      yield apiWithConfirmCall(Api.Account.update, {
        rostered_shift_settings: {
          ...rosteredShiftSettings,
          apply_default_breaks,
          default_breaks_rules: null,
        },
      });

    if (response) {
      yield put(
        actions.BOX_ACCOUNT_ROSTERED_SHIFT_SETTINGS_DEFAULT_BREAKS_RESET.success(
          response
        )
      );
    } else {
      yield put(
        actions.BOX_ACCOUNT_ROSTERED_SHIFT_SETTINGS_DEFAULT_BREAKS_RESET.cancel()
      );
    }
  } catch (error) {
    yield put(
      actions.BOX_ACCOUNT_ROSTERED_SHIFT_SETTINGS_DEFAULT_BREAKS_RESET.failure(
        formatError(error)
      )
    );
  }
}

const updateSaveCustomBreaks = function* ({
  payload,
}: SagaActionFromCreator<
  typeof actions.BOX_ACCOUNT_ROSTERED_SHIFT_APPLY_CUSTOMISED_BREAKS.request
>): SagaIterator {
  try {
    const rosteredShiftSettings: ReturnType<typeof getRosteredShiftSettings> =
      yield select(getRosteredShiftSettings);

    const response: ApiWithConfirmReturnType<typeof Api.Account.update> =
      yield apiWithConfirmCall(Api.Account.update, {
        rostered_shift_settings: {
          ...rosteredShiftSettings,
          apply_default_breaks: true,
          default_breaks_rules: payload,
        },
      });
    if (response) {
      yield put(
        actions.BOX_ACCOUNT_ROSTERED_SHIFT_APPLY_CUSTOMISED_BREAKS.success(
          response
        )
      );
    } else {
      yield put(
        actions.BOX_ACCOUNT_ROSTERED_SHIFT_APPLY_CUSTOMISED_BREAKS.cancel()
      );
    }
  } catch (error) {
    yield put(
      actions.BOX_ACCOUNT_ROSTERED_SHIFT_APPLY_CUSTOMISED_BREAKS.failure(
        formatError(error)
      )
    );
    yield put(
      actions.BOX_ACCOUNT_ROSTERED_SHIFT_SETTINGS_UPDATE.failure(
        formatError(error)
      )
    );
  }
};

export default function* () {
  yield takeLatest(
    actions.BOX_ACCOUNT_ROSTERED_SHIFT_SETTINGS_UPDATE.request,
    updateRosteredShiftSettings
  );
  yield takeLatest(
    actions.BOX_ACCOUNT_ROSTERED_SHIFT_SETTINGS_DEFAULT_BREAKS_RESET.request,
    resetDefaultBreaksToDefaults
  );
  yield takeLatest(
    actions.BOX_ACCOUNT_ROSTERED_SHIFT_APPLY_CUSTOMISED_BREAKS.request,
    updateSaveCustomBreaks
  );
}
