import { ShareOutlinedIcon } from 'element';
import { useSelectorWithProps } from 'hooks';
import marketLabels from 'marketLabels';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hasPermissionSelector } from 'state/Auth';
import {
  BOX_ROSTER_OPEN_APPLY_CHANGES_MODAL,
  getTotalUnpublishedNumber
} from 'state/Roster/Roster';

export default useHeaderPrimaryButton;

export function useHeaderPrimaryButton() {
  const dispatch = useDispatch();
  const canEditRosteredShifts = useSelectorWithProps(
    hasPermissionSelector,
    'roster.rosteredshift.edit'
  );

  const totalNumber = useSelector(getTotalUnpublishedNumber);

  if (!canEditRosteredShifts) {
    return;
  }

  return {
    id: 'share-roster',
    icon: <ShareOutlinedIcon />,
    ariaLabel: 'Share rosters',
    label: `Share ${marketLabels.roster}`,
    tooltip: `Share ${marketLabels.roster}`,
    isDisabled: totalNumber === 0,
    badge:
      totalNumber > 0
        ? {
            type: 'warning' as any,
            children: totalNumber,
          }
        : undefined,
    isText: true,
    onClick: () => {
      if (totalNumber !== 0) {
        dispatch(BOX_ROSTER_OPEN_APPLY_CHANGES_MODAL('publish'));
      }
    },
  };
}
