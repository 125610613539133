import React from 'react';
import { SortAscIcon, SortDescIcon } from '../../icons';
import { getClass } from '../../_lib/helper';
import './DataTableSort.scss';

type DataTableSortProps = {
  /** The current sort direction */
  direction?: string;
  isHidden?: boolean;
};

/**
 * Renders the icon for the current sort direction in the DataTable
 * @param direction
 * @constructor
 */
function DataTableSort({ direction, isHidden }: DataTableSortProps) {
  let icon = null;
  switch (direction) {
    case 'asc': {
      icon = <SortAscIcon />;
      break;
    }

    case 'desc': {
      icon = <SortDescIcon />;
      break;
    }

    default: {
      icon = <div className="sort-spacer" />;
    }
  }

  return (
    <div
      className={getClass('elmo-datatable__sort', '', {
        hidden: isHidden,
      })}
    >
      {icon}
    </div>
  );
}

export default DataTableSort;
