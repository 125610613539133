import React, { Component, ReactChild } from 'react';
import { connect } from 'react-redux';
import { Filter, LoadingOverlay } from 'elmo-elements';
import { StoreState } from 'state/types';
import {
  BOX_MY_TIMESHEET_CLEAR_FILTER_FORM,
  BOX_MY_TIMESHEET_PREDEFINED_FILTER_REQUEST,
  BOX_MY_TIMESHEET_CUSTOM_FILTER_REQUEST
} from 'state/EmployeeDashboard/MyTimesheet/actions';
import {
  MyTimesheetSelectedFilter,
  MyTimesheetPredefinedFilter
} from 'state/EmployeeDashboard/MyTimesheet/types';
import {
  isFiltersFormDisabledSelector,
  getSelectedFilter
} from 'state/EmployeeDashboard/MyTimesheet/selectors';
import { CustomFilters } from './components/CustomFilters';
import { getIsLoading } from '../../../../../../state/IsLoading';
import { LanguagePreferences } from '../../../../../../type/models';
import { getLangPreferences } from '../../../../../../state/Account';
import { isAppMarket } from '../../../../../../helpers';

type StateProps = {
  selectedFilter: MyTimesheetSelectedFilter;
  isUpdating: boolean;
  isFiltersFormDisabled: boolean;
  langPreferences: LanguagePreferences;
};

type DispatchProps = {
  updateCustomFilters: () => void;
  clearCustomFilters: () => void;
  togglePredefinedFilter: (filterType: MyTimesheetPredefinedFilter) => void;
};

type Props = StateProps & DispatchProps;

class MyTimesheetFiltersComponent extends Component<Props> {
  render() {
    const {
      updateCustomFilters,
      selectedFilter,
      isUpdating,
      isFiltersFormDisabled
    } = this.props;

    return (
      <LoadingOverlay isLoading={isUpdating} showSpinner={false}>
        <Filter
          onStatusChange={this.onStatusChange}
          statuses={this.statuses}
          renderCustomFilters={this.renderCustomFilters}
          onApplyCustomFilters={updateCustomFilters}
          onToggleCustomFilters={this.onToggleCustomFilters}
          selectedValue={selectedFilter}
          isApplyBtnDisabled={isFiltersFormDisabled}
        />
      </LoadingOverlay>
    );
  }

  private get statuses(): {
    label: string;
    value: MyTimesheetPredefinedFilter;
  }[] {
    const { roster } = this.props.langPreferences;
    const rosterText = isAppMarket('uk') ? 'rota' : roster.singular;
    return [
      {
        label: 'Last 7 days',
        value: 'lastSevenDays'
      },
      {
        label: `Current ${rosterText}`,
        value: 'currentRoster'
      },
      {
        label: `Last ${rosterText}`,
        value: 'lastRoster'
      },
      {
        label: 'Last 6 months',
        value: 'lastSixMonths'
      }
    ];
  }

  private onStatusChange = (filterType: string) => {
    this.props.togglePredefinedFilter(
      filterType as MyTimesheetPredefinedFilter
    );
  };

  private renderCustomFilters = (): ReactChild => <CustomFilters />;

  private onToggleCustomFilters = (isOpen: boolean) => {
    if (isOpen) {
      this.props.clearCustomFilters();
    }
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  selectedFilter: getSelectedFilter(state),
  isUpdating: getIsLoading(state, 'EMPLOYEE_DASHBOARD_MY_TIMESHEET'),
  isFiltersFormDisabled: isFiltersFormDisabledSelector(state),
  langPreferences: getLangPreferences(state)
});

const mapDispatchToProps: DispatchProps = {
  updateCustomFilters: BOX_MY_TIMESHEET_CUSTOM_FILTER_REQUEST,
  clearCustomFilters: BOX_MY_TIMESHEET_CLEAR_FILTER_FORM,
  togglePredefinedFilter: BOX_MY_TIMESHEET_PREDEFINED_FILTER_REQUEST
};
export const MyTimesheetFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyTimesheetFiltersComponent);
