import React from 'react';
import { UserFields, UserRole } from 'type';
import { UserRoleFormatted } from '../UserRoleFormatted';

type Props = {
  user: UserFields;
};

export const MainUserRole = ({ user }: Props) => {
  const mainRole: UserRole | undefined = user.main_role;

  if (!mainRole) {
    return null;
  }

  return <UserRoleFormatted role={mainRole} />;
};
