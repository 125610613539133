import { IArrayHandler, BaseItem } from './types';

export default class ItemHandler<
  ValueType extends BaseItem,
  ChangeHandler extends (updatedValues: ValueType[]) => void
  > {
  constructor(
    private readonly handler: IArrayHandler<ValueType, ChangeHandler>,
    private readonly value: ValueType | undefined
  ) {}

  changeField<PropName extends keyof ValueType>(
    propName: PropName,
    propValue: ValueType[PropName]
  ) {
    if (this.value) {
      this.handler.updateItem({
        ...this.value,
        [propName]: propValue,
      });
    }
  }

  makeChangeField<PropName extends keyof ValueType>(propName: PropName) {
    return (propValue: ValueType[PropName]) => {
      this.changeField(propName, propValue);
    };
  }

  remove = () => {
    if (this.value) {
      this.handler.removeItem(this.value.id);
    }
  }
}
