import lodashCond from 'lodash/cond';

type CondPair<T, R> = [(val: T) => boolean, (val: T) => R];

type CondFn<R, T = void> = (Target: T) => R | undefined;

/**
 * this cond helper has option to omit the payload
 * also return type will be undefined if none of conditions returned true
 * */
export function typedCond<P1, R1>(pairs: [CondPair<P1, R1>]): CondFn<R1, P1>;
export function typedCond<P1, R1, P2, R2>(
  pairs: [CondPair<P1, R1>, CondPair<P2, R2>]
): CondFn<R1 | R2, P1 & P2>;
export function typedCond<P1, R1, P2, R2, P3, R3>(
  pairs: [CondPair<P1, R1>, CondPair<P2, R2>, CondPair<P3, R3>]
): CondFn<R1 | R2 | R3, P1 & P2 & P3>;
export function typedCond<P1, R1, P2, R2, P3, R3, P4, R4>(
  pairs: [
    CondPair<P1, R1>,
    CondPair<P2, R2>,
    CondPair<P3, R3>,
    CondPair<P4, R4>
  ]
): CondFn<R1 | R2 | R3 | R4, P1 & P2 & P3 & P4>;
export function typedCond(pairs: Array<CondPair<any, any>>): any {
  return lodashCond(pairs);
}
