import React, { Component } from 'react';
import { buildCalendar } from '../../helpers';
import './UnavailabilityCalendar.scss';
import moment, { Moment } from 'moment';
import CalendarCard from './components/CalendarCard';
import CalendarNavigation from './components/CalendarNavigation';
import CalendarHeader from './components/CalendarHeader';
import {
  DayDetails,
  EmployeeUnavailabilityProps,
  TimeOffRosteredShift
} from 'state/EmployeeDashboard/Unavailability/types';
import { connect } from 'react-redux';
import {
  BOX_EMPLOYEE_SET_DAY_DETAILS_CARD,
  BOX_EMPLOYEE_UNAVAILABILITY_GET_DATA_ON_DATE_CHANGED,
  BOX_EMPLOYEE_UNAVAILABILITY_NEXT_MONTH,
  BOX_EMPLOYEE_UNAVAILABILITY_PREV_MONTH,
  BOX_EMPLOYEE_UNAVAILABILITY_RESET_MONTH
} from 'state/EmployeeDashboard/Unavailability';
import {
  getIsFetching, getRosteredAvailabilities,
  timeOffsArraySelector,
  getCalendarDateByMainTimezone
} from 'state/EmployeeDashboard/Unavailability/selectors';
import { StoreState } from 'state/types';
import { LoadingOverlay } from 'elmo-elements';
import { getAccountTimezone } from '../../../../../../state/Account';

type StateProps = {
  data: EmployeeUnavailabilityProps[];
  shifts: TimeOffRosteredShift[];
  todayDate: Moment;
  isFetching: boolean;
  timezone: string;
};

type DispatchProps = {
  setDayDetails: (params: DayDetails | null) => void;
  toNextMonth: () => void;
  toPrevMonth: () => void;
  toCurrentMonth: () => void;
  getDataOnDateChanged: () => void;
};

type Props = DispatchProps & StateProps;

class UnavailabilityCalendar extends Component<Props> {
  render() {
    return (
      <div className="unavailability-calendar">
        <LoadingOverlay isLoading={this.props.isFetching}>
          <CalendarNavigation
            toNextMonth={this.toNextMonth}
            toPrevMonth={this.toPrevMonth}
            toCurrentMonth={this.toCurrentMonth}
            date={this.props.todayDate}
          />
          <CalendarHeader />
          {this.showCalendar()}
        </LoadingOverlay>
      </div>
    );
  }

  toNextMonth = () => {
    this.props.toNextMonth();
    this.props.getDataOnDateChanged();
  }

  toPrevMonth = () => {
    this.props.toPrevMonth();
    this.props.getDataOnDateChanged();
  }

  toCurrentMonth = () => {
    this.props.toCurrentMonth();
    this.props.getDataOnDateChanged();
  }

  showDayDetails = (details: DayDetails) => {
    this.props.setDayDetails(details);
  }

  showCalendar = () => {
    const { todayDate, data, shifts, timezone } = this.props;
    const calendar = buildCalendar(todayDate, data, shifts, timezone);
    return (
      <React.Fragment>
        {calendar.map((row: DayDetails[], rowInd: number) => (
          <div className="unavailability-calendar__row" key={rowInd}>
            {row.map((day: DayDetails, dayIndex: number) => (
              <div className="unavailability-calendar__cell" key={dayIndex} onClick={e => this.showDayDetails(day)}>
                <CalendarCard data={day} />
              </div>
            ))}
          </div>
        ))}
      </React.Fragment>
    );
  }

}

const mapStateToProps = (state: StoreState): StateProps => ({
  data: timeOffsArraySelector(state),
  shifts: getRosteredAvailabilities(state),
  todayDate: getCalendarDateByMainTimezone(state),
  isFetching: getIsFetching(state),
  timezone: getAccountTimezone(state)
});

const mapToDispatchProps: DispatchProps = {
  setDayDetails: BOX_EMPLOYEE_SET_DAY_DETAILS_CARD,
  toNextMonth: BOX_EMPLOYEE_UNAVAILABILITY_NEXT_MONTH,
  toPrevMonth: BOX_EMPLOYEE_UNAVAILABILITY_PREV_MONTH,
  toCurrentMonth: BOX_EMPLOYEE_UNAVAILABILITY_RESET_MONTH,
  getDataOnDateChanged: BOX_EMPLOYEE_UNAVAILABILITY_GET_DATA_ON_DATE_CHANGED
};

export default connect(mapStateToProps, mapToDispatchProps)(UnavailabilityCalendar);