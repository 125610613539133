import React, { forwardRef, MouseEventHandler, ReactNode, Ref } from 'react';
import { IsResizable } from '../../types';

export const ResizableWrapperView = forwardRef(
  (
    props: {
      left: number;
      width: number;
      children: ReactNode;
      isResizable: IsResizable;
      isResizing: boolean;
      onResizeLeft: MouseEventHandler<HTMLDivElement>;
      onResizeRight: MouseEventHandler<HTMLDivElement>;
      markerLeft: string;
      markerRight: string;
    },
    ref: Ref<HTMLDivElement>
  ) => (
    <div
      ref={ref}
      className="resizable-wrapper__wrapper"
      style={{ left: props.left, width: props.width }}
    >
      <div className="resizable-wrapper__content-wrapper">{props.children}</div>

      {(props.isResizable.left || props.isResizable.right) && (
        <>
          {props.isResizable.left && (
            <div
              className="resizable-wrapper__trigger resizable-wrapper__trigger--left"
              onMouseDown={props.isResizable.left && props.onResizeLeft}
              style={{ pointerEvents: props.isResizing ? 'none' : 'auto' }}
            />
          )}

          {props.isResizable.right && (
            <div
              className="resizable-wrapper__trigger resizable-wrapper__trigger--right"
              onMouseDown={props.isResizable.right && props.onResizeRight}
              style={{ pointerEvents: props.isResizing ? 'none' : 'auto' }}
            />
          )}
        </>
      )}

      {props.isResizing && (
        <>
          {props.isResizable.left && (
            <div className="resizable-wrapper__time-marker resizable-wrapper__time-marker--left">
              {props.markerLeft}
            </div>
          )}

          {props.isResizable.right && (
            <div className="resizable-wrapper__time-marker resizable-wrapper__time-marker--right">
              {props.markerRight}
            </div>
          )}
        </>
      )}
    </div>
  )
);
