import React, { ReactNode } from 'react';
import classNames from 'clsx';
import './RosterRow.scss';
import WithThemeBackground from '../WithThemeBackground';

interface Props {
  children: {
    sticky: ReactNode;
    content: ReactNode;
  };
  isDisabled?: boolean;
  isSelected?: boolean;
  isCollapsed?: boolean;
  rowIndex?: number;
  className?: string;
}

const RosterRow = ({
  children: { sticky, content },
  isDisabled,
  isSelected,
  rowIndex,
  isCollapsed,
  className,
}: Props) => (
  <WithThemeBackground>
    {(backgroundColor) => (
      <div
        className={classNames(
          'roster-row',
          {
            'is-disabled': isDisabled,
            'is-bulk-selected': isSelected,
            'is-collapsable': typeof isCollapsed !== 'undefined',
            'is-collapsed': isCollapsed,
          },
          className
        )}
      >
        <div className="roster-row__left-element-wrapper" style={{ backgroundColor }}>
          <div className="roster-row__left-element">{sticky}</div>
        </div>
        <div
          className={`roster-row__content-wrapper ${
            typeof rowIndex === 'number' ? 'roster-map map-row-' + rowIndex : ''
          }`}
        >
          {content}
        </div>
      </div>
    )}
  </WithThemeBackground>
);

export default RosterRow;
