import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { DateType } from 'type';
import { useUserTimesheetsGridDayRow } from 'page/TimesheetsWeekly/hooks';
import { EmptyTile, TimesheetTile } from './components';

type Props = {
  userId: string;
  rowIndex: number;
  day: DateType;
};

const useCellStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      minHeight: '72px',
      width: '100%',
    },
  })
);

export const TimesheetCell = (props: Props) => {
  const styles = useCellStyles();
  const timesheetId = useUserTimesheetsGridDayRow(props);

  return (
    <div className={styles.wrapper} data-testid={`date-cell-${props.day}`}>
      {timesheetId ? (
        <TimesheetTile timesheetId={timesheetId} />
      ) : (
        <EmptyTile {...props} />
      )}
    </div>
  );
};
