import { find, intersection, uniq } from 'lodash';
import { createSelector } from 'reselect';
import { getAccountTimezone } from 'state/Account';
import { getSites } from 'state/AccountTree';
import { serverUserFieldsToUserFields } from 'state/combiners';
import { StoreState } from 'state/types';
import {
  AccountTreeSite,
  PermissionName,
  ServerUserCurrent,
  StringMap,
  UserCurrent,
  UserFieldsRosterViewSettings,
  UserRole,
} from 'type';

const getState = (state: StoreState) => state.auth;
export const getCurrentUser = (state: StoreState): ServerUserCurrent =>
  getState(state).currentUser;
export const getCurrentUserPermissions = (
  state: StoreState
): PermissionName[] => getState(state).currentUser.permissions;
export const getCurrentUserId = (state: StoreState): string =>
  getState(state).currentUser.id;
const getCurrentUserRoles = (state: StoreState): UserRole[] =>
  getState(state).currentUser.user_roles;
export const getEmployeeType = (state: StoreState): string =>
  getState(state).currentUser.employment_type;
export const getUserFieldsRosterViewSettings = (
  state: StoreState
): UserFieldsRosterViewSettings | null => {
  const { preferences } = getState(state).currentUser;
  return preferences ? preferences.roster_view_settings : null;
};
export const getUserSettingsDefaultSiteId = (state: StoreState) =>
  getUserFieldsRosterViewSettings(state)?.default_location;

export const currentUserSelector = createSelector(
  getCurrentUser,
  ({ permissions, ...serverUser }): UserCurrent => ({
    ...serverUserFieldsToUserFields(serverUser),
    permissions,
  })
);

export const hasPermissionSelector = (
  state: StoreState,
  permission: PermissionName
) => getCurrentUserPermissions(state).includes(permission);

export const hasPermissionsSelector = (
  state: StoreState,
  permissions: PermissionName[]
): boolean =>
  intersection(getCurrentUserPermissions(state), permissions).length ===
  permissions.length;

export const currentUserSiteIdsSelector = createSelector(
  getCurrentUserRoles,
  (currentUserRoles: UserRole[]): string[] =>
    uniq(currentUserRoles.map(({ site_id }: UserRole): string => site_id))
);

export const currentUserMainSiteIdSelector = createSelector(
  getCurrentUserRoles,
  (currentUserRoles: UserRole[]): string | undefined => {
    const mainRole: UserRole | undefined = find(
      currentUserRoles,
      ({ is_main }: UserRole) => is_main
    );

    if (mainRole) {
      return mainRole.site_id;
    }
  }
);

export const currentUserMainTimezoneSelector = createSelector(
  currentUserMainSiteIdSelector,
  getAccountTimezone,
  getSites,
  (
    currentUserMainSiteId: string | undefined,
    accountTimezone: string,
    sites: StringMap<AccountTreeSite>
  ): string => {
    if (currentUserMainSiteId) {
      const site: AccountTreeSite | undefined = sites[currentUserMainSiteId];

      if (site) {
        return site.timezone_id;
      }
    }

    return accountTimezone;
  }
);
