import { SagaIterator } from 'redux-saga';
import { put, select, takeEvery } from 'redux-saga/effects';
import { BOX_TOAST_NOTIFIER_CLOSE } from 'state/ToastNotifier';
import * as actions from './actions';
import { getReloadNotificationId } from './selectors';

const hideReloadNotification = function* (): SagaIterator {
  const reloadNotificationId: ReturnType<typeof getReloadNotificationId> =
    yield select(getReloadNotificationId);

  if (reloadNotificationId) {
    yield put(BOX_TOAST_NOTIFIER_CLOSE(reloadNotificationId));
    yield put(actions.BOX_ROSTER_PAST_SET_RELOAD_NOTIFICATION_ID(null));
  }
};

export const watchReloadNotification = function* (): SagaIterator {
  yield takeEvery(
    actions.BOX_ROSTER_PAST_RELOAD_NOTIFICATION_HIDE,
    hideReloadNotification
  );
};
