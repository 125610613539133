import React from 'react';
import { StoreState } from 'state/types';
import {
  BOX_EMPLOYEE_DASHBOARD_EVENTS_CLOSE_DETAILS_MODAL,
  getDetailsModal,
} from 'state/EmployeeDashboard/Events';
import { connect } from 'react-redux';
import { Heading, withLayoutAware } from 'elmo-elements';
import { DetailsModal } from 'state/EmployeeDashboard/Events/types';
import {
  getCurrencyFormatted,
  getDateTimeFormatted,
  getPreferenceLabel,
} from 'lib/helpers';
import {
  getCurrencyCode,
  getCurrencyPlacement,
  getDateFormat,
  getEventSettings,
  getLangPreferences,
  getNumberFormat,
  getTimeFormat,
} from 'state/Account';
import {
  EventSettings,
  LanguagePreferences,
  PreferencesCurrencyCode,
  PreferencesCurrencyPlacement,
  PreferencesDateFormat,
  PreferencesNumberFormat,
  PreferencesTimeFormat,
  ServerUserFields,
} from 'type/models';
import { Map, Marker, PageDialog, PageDialogCancel } from 'element';
import { getUserListResponse } from 'state/UsersCollection';
import { StringMap } from 'type';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';
import { Grid } from '@mui/material';

type Props = {
  closeModal: () => void;
  modal: DetailsModal;
  eventSettings: EventSettings;
  users: StringMap<ServerUserFields>;
  langPreferences: LanguagePreferences;
  dateFormat: PreferencesDateFormat;
  timeFormat: PreferencesTimeFormat;
  numberFormat: PreferencesNumberFormat;
  currencyCode: PreferencesCurrencyCode;
  currencyPlacement: PreferencesCurrencyPlacement;
};

export class EventDetailsModalComponent extends React.Component<Props> {
  render() {
    const { event } = this.props.modal;
    const { lat, lng } = event;
    const {
      eventSettings,
      langPreferences,
      dateFormat,
      timeFormat,
      numberFormat,
      currencyCode,
      currencyPlacement,
    } = this.props;
    return (
      <PageDialog
        maxWidth={'sm'}
        open={this.props.modal.isOpen}
        id="ModalMedium"
        onClose={this.props.closeModal}
      >
        <DialogTitle>{this.title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={{ xs: 2, md: 3 }} role="presentation">
            {eventSettings.show_event_start && (
              <Grid item xs={6}>
                <Heading size={'xs'} color={'gray'}>
                  Start
                </Heading>
                {getDateTimeFormatted(
                  dateFormat,
                  timeFormat,
                  event.start,
                  true
                )}
              </Grid>
            )}
            {eventSettings.show_event_finish && (
              <Grid item xs={6}>
                <Heading size={'xs'} color={'gray'}>
                  Finish
                </Heading>
                {getDateTimeFormatted(dateFormat, timeFormat, event.end, true)}
              </Grid>
            )}

            <Grid item xs={6}>
              <Heading size={'xs'} color={'gray'}>
                {getPreferenceLabel(
                  langPreferences,
                  'event',
                  'singular',
                  '',
                  true
                )}
              </Heading>
              {event.name}
            </Grid>
            {eventSettings.show_event_item && (
              <Grid item xs={6}>
                <Heading size={'xs'} color={'gray'}>
                  {getPreferenceLabel(
                    langPreferences,
                    'event',
                    'singular',
                    '',
                    true
                  )}{' '}
                  Number
                </Heading>
                {event.item ? event.item : '-'}
              </Grid>
            )}

            {eventSettings.show_event_address && (
              <Grid item xs={6}>
                <Heading size={'xs'} color={'gray'}>
                  Address
                </Heading>
                {event.address ? event.address : '-'}
              </Grid>
            )}
            {eventSettings.show_event_address_name && (
              <Grid item xs={6}>
                <Heading size={'xs'} color={'gray'}>
                  Address Name
                </Heading>
                {event.address_name ? event.address_name : '-'}
              </Grid>
            )}
            <Grid item xs={6}>
              <Heading size={'xs'} color={'gray'}>
                Forecast
              </Heading>
              {getCurrencyFormatted(
                numberFormat,
                currencyCode,
                currencyPlacement,
                +event.value
              )}
            </Grid>
            {eventSettings.show_value && (
              <Grid item xs={6}>
                <Heading size={'xs'} color={'gray'}>
                  Actual
                </Heading>
                {getCurrencyFormatted(
                  numberFormat,
                  currencyCode,
                  currencyPlacement,
                  +event.actual_value
                )}
              </Grid>
            )}
          </Grid>

          {eventSettings.show_event_report_to && event.report_to && (
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              role="presentation"
              paddingTop={3}
            >
              <Grid item xs={6}>
                <Heading size={'xs'} color={'gray'}>
                  Report to
                </Heading>
                {event.report_to}
              </Grid>
            </Grid>
          )}

          {eventSettings.show_event_notes && (
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              paddingTop={3}
              paddingBottom={3}
              role="presentation"
            >
              <Grid item xs={6}>
                <Heading size={'xs'} color={'gray'}>
                  Notes
                </Heading>
                {event.description ? event.description : '-'}
              </Grid>
            </Grid>
          )}

          {eventSettings.show_event_address &&
            lat !== 0 &&
            lat !== null &&
            lng !== null && (
              <Map center={{ lat, lng }}>
                <Marker position={{ lat, lng }} />
              </Map>
            )}
        </DialogContent>
        <DialogActions>
          <PageDialogCancel onClick={this.props.closeModal}>
            Close
          </PageDialogCancel>
        </DialogActions>
      </PageDialog>
    );
  }

  get title() {
    const { event } = this.props.modal;
    return event.name;
  }

  renderMultipleOptions = (options: any) => {
    return Array.isArray(options) ? options.join(', ') : options;
  };
}

const mapStateToProps = (state: StoreState) => ({
  modal: getDetailsModal(state),
  eventSettings: getEventSettings(state),
  users: getUserListResponse(state),
  langPreferences: getLangPreferences(state),
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
  numberFormat: getNumberFormat(state),
  currencyCode: getCurrencyCode(state),
  currencyPlacement: getCurrencyPlacement(state),
});

export const EventDetailsModal = connect(mapStateToProps, {
  closeModal: BOX_EMPLOYEE_DASHBOARD_EVENTS_CLOSE_DETAILS_MODAL,
})(withLayoutAware(EventDetailsModalComponent));
