import nanoid from 'nanoid';
import { defaults } from 'lodash';
import { SagaIterator } from 'redux-saga';
import { delay, put, select, takeEvery } from 'redux-saga/effects';
import { SagaAction } from 'type';
import moment from 'moment';
import {
  BOX_TOAST_NOTIFIER_CLOSE,
  BOX_TOAST_NOTIFIER_MESSAGE_ADD,
  BOX_TOAST_NOTIFIER_MESSAGE_HIDE,
  BOX_TOAST_NOTIFIER_MESSAGE_OPEN,
  BOX_TOAST_NOTIFIER_MESSAGE_REMOVE,
  BOX_TOAST_NOTIFIER_MESSAGE_SHOW,
  BOX_TOAST_NOTIFIER_UNDO,
} from './actions';
import {
  ToastNotifierMessageOpenPayload,
  ToastNotifierMessageShowConfigPayload,
  ToastNotifierMessageShowPayload,
} from './types';
import {
  getIsNotificationShown,
  getPrevNotificationTime,
  getTotalNumber,
} from './selectors';

type PayloadWithEndless = ToastNotifierMessageOpenPayload & {
  isEndless: boolean;
};

const getDefaultPayload = (): PayloadWithEndless => ({
  id: nanoid(),
  message: '',
  showUndo: false,
  undoText: 'UNDO',
  type: 'dark',
  availableTo: moment(),
  isEndless: false,
});

const getPayloadFromString = (message: string): PayloadWithEndless => ({
  ...getDefaultPayload(),
  message,
});
const getPayloadFromConfig = (
  config: ToastNotifierMessageShowConfigPayload
): PayloadWithEndless => defaults(config, getDefaultPayload());

const showToastNotifier = function* ({
  payload,
}: SagaAction<ToastNotifierMessageShowPayload>): SagaIterator {
  const { isEndless, ...addPayload } =
    typeof payload === 'string'
      ? getPayloadFromString(payload)
      : getPayloadFromConfig(payload);

  const prevTime = yield select(getPrevNotificationTime);
  const delayTime = addPayload.showUndo ? 7000 : 3000;
  const total = yield select(getTotalNumber);

  // --- delay for each message ---
  addPayload.availableTo = prevTime.add(
    total >= 3 ? delayTime - 1000 : delayTime,
    'milliseconds'
  );
  const diff = +addPayload.availableTo.format('x') - +moment().format('x');
  const delayDiff = diff < delayTime - 1100 ? delayTime : diff;
  // --- END delay for each message ---

  const isNotificationShown = yield select(
    getIsNotificationShown,
    addPayload.id
  );
  if (!isNotificationShown) {
    yield put(BOX_TOAST_NOTIFIER_MESSAGE_ADD(addPayload));
    yield delay(200);
    yield put(BOX_TOAST_NOTIFIER_MESSAGE_OPEN(addPayload.id));
  }
  if (!isEndless) {
    yield delay(delayDiff);
    yield put(BOX_TOAST_NOTIFIER_CLOSE(addPayload.id));
  }
};

const closeToastNotifier = function* ({
  payload: id,
}: SagaAction<string>): SagaIterator {
  yield put(BOX_TOAST_NOTIFIER_MESSAGE_HIDE(id));
  yield delay(100);
  yield put(BOX_TOAST_NOTIFIER_MESSAGE_REMOVE(id));
};

export const watchToastNotifier = function* (): SagaIterator {
  yield takeEvery(BOX_TOAST_NOTIFIER_MESSAGE_SHOW, showToastNotifier);
  yield takeEvery(BOX_TOAST_NOTIFIER_CLOSE, closeToastNotifier);
  yield takeEvery(BOX_TOAST_NOTIFIER_UNDO, closeToastNotifier);
};
