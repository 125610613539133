import React from 'react';
import { defaults } from 'lodash';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@mui/styles';
import { DeleteOutlinedIcon } from 'element';
import { Tooltip, TooltipProps } from 'extended-oxygen-elements';
import { blackAlpha70, shadow100 } from '../constants';

type DefaultProps = {
  color: string;
  title: string;
  placement: Required<TooltipProps>['placement'];
};

type Props = Partial<DefaultProps> & {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: 'transparent',
      border: 'none',
      padding: 0,
      width: 20,
      height: 20,
      display: 'inline-block',
      position: 'absolute',
      right: '0px',
      opacity: 0,
      visibility: 'hidden',
      top: '-4px',
      '&:hover': {
        boxShadow: shadow100,
        borderRadius: 2,
        cursor: 'pointer'
      },
    },
  })
);

const defaultProps: DefaultProps = {
  title: 'Delete shift',
  color: blackAlpha70,
  placement: 'top',
};

export function ShiftCardButtonDelete(actualProps: Props) {
  const { onClick, className, color, ...tooltipProps } = defaults(
    {},
    actualProps,
    defaultProps
  );
  const classes = useStyles();

  return (
    <button
      className={clsx(classes.root, className)}
      onClick={onClick}
      style={{ color }}
    >
      <Tooltip {...tooltipProps}>
        <DeleteOutlinedIcon fontSize="small" />
      </Tooltip>
    </button>
  );
}
