import React from 'react';
import {
  Box,
  BoxProps,
  Typography,
  lighten, Theme
} from 'extended-oxygen-elements';
import {
  createStyles,
  makeStyles,
} from '@mui/styles';
import { Avatar } from 'extended-oxygen-elements';
import { useSelectorWithProps } from 'hooks';
import { FormattedDuration } from 'element';
import { userSelector } from 'state/UsersCollection';
import { useFilteredUserTimesheetsTotalDuration } from 'page/TimesheetsWeekly/hooks';

type Props = BoxProps & {
  userId: string;
};
const useLegendStyles = makeStyles((theme: Theme) =>
  createStyles({
    info: {
      width: 'calc(100% - 60px)',
    },
    hrs: {
      color: lighten(theme.palette.text.primary, 0.5),
    },
  })
);

export function EmployeeCard({ userId, ...boxProps }: Props) {
  const styles = useLegendStyles();
  const user = useSelectorWithProps(userSelector, userId);
  const duration = useFilteredUserTimesheetsTotalDuration(userId);
  return (
    <Box
      display="flex"
      height="100%"
      alignItems="center"
      py={0.5}
      data-testid={`employee-card-${user.id}`}
      {...boxProps}
    >
      <Box mr={1}>
        <Avatar
          alt={user.prefered_or_full_name}
          src={user.avatar.src}
          size="large"
          light
        />
      </Box>
      <Box maxWidth="100%" className={styles.info}>
        <Typography noWrap={true} data-testid={'user-info-name'}>{user.prefered_or_full_name}</Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          className={styles.hrs}
        >
          <FormattedDuration data-testid={'user-info-duration'}>{duration}</FormattedDuration>
        </Typography>
      </Box>
    </Box>
  );
}
