import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import {
  getCreatedShiftData,
  RosterDayViewCreatedShiftData
} from 'state/Roster/RosterDayView';
import { RosterCellType } from '../../../../../../../components';
import { DayCreatedShift } from '../../../../../components';

export type OwnProps = {
  cellType: RosterCellType;
  rowIndex: number;
};

export type StateProps = {
  createdShiftData: RosterDayViewCreatedShiftData;
};

export type DispatchProps = {};

export type Props = OwnProps & StateProps & DispatchProps;

export const DayUserUnassignedCreatedShiftComponent = ({
  createdShiftData: { shift, clientX, pairIndex },
  cellType,
  rowIndex
}: Props) => {
  if (!shift) {
    return null;
  }

  if (pairIndex !== rowIndex) {
    return null;
  }

  if (shift.user_id) {
    return null;
  }

  return (
    <DayCreatedShift shift={shift} cellType={cellType} clientX={clientX} />
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  createdShiftData: getCreatedShiftData(state)
});

const mapDispatchToProps: DispatchProps = {};

export const DayUserUnassignedCreatedShift = connect(
  mapStateToProps,
  mapDispatchToProps
)(DayUserUnassignedCreatedShiftComponent);
