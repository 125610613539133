import React, { memo } from 'react';
import { RosterCell } from 'page/Roster/components';
import { WeekCellBlock } from '../../../../../../components/WeekCellBlock/RosterWeekCellBlock';
import { useMakeSelector, useSelectorWithProps } from '../../../../../../../../../../hooks';
import { makeRosteredShiftIdSelector } from 'state/Roster/RosterWeekViewUserView';

type Props = {
  rowNumber: number;
  weekDayIndex: number;
};

export const UnassignedShiftCell = memo(function UnassignedShiftCell(
  props: Props
) {
  const rosteredShiftIdSelector = useMakeSelector(makeRosteredShiftIdSelector);
  const rosteredShiftId = useSelectorWithProps(rosteredShiftIdSelector, props);
  return (
    <div className="week-rosters-grid__group-day-block">
      <RosterCell
        type={'top'}
        label={'+ Add Shifts'}
        labelOnHover={true}
        fontWeight={'normal'}
      >
        <WeekCellBlock
          rowNumber={props.rowNumber}
          weekDayIndex={props.weekDayIndex}
          menuType={'create'}
          shiftType={'rostered_shift'}
          userId={null}
          areaId={undefined}
          roleId={undefined}
          rosteredShiftId={rosteredShiftId}
          viewType={'user'}
          leaveId={null}
          timeOffIds={null}
        />
      </RosterCell>
    </div>
  );
});
