import React from 'react';
import _ from 'lodash';
import { TextField } from 'extended-oxygen-elements';
import { NOTES_MAX_LENGTH } from 'lib/config';
import { FieldProps } from 'type';

type NotesFieldProps = FieldProps<string | null>;

export default NotesField;

export function NotesField({ value, error, onChange }: NotesFieldProps) {
  const stringValue = value || '';
  const notesLength = stringValue.length;
  const notesLeft = Math.max(NOTES_MAX_LENGTH - notesLength, 0);
  const notesLabel = _.compact(['Notes', notesLength && `(${notesLeft})`]).join(
    ' '
  );

  function handleNotesChange({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement>) {
    onChange(value.substring(0, NOTES_MAX_LENGTH) || null);
  }

  return (
    <TextField
      multiline
      minRows={4}
      label={notesLabel}
      value={stringValue}
      onChange={handleNotesChange}
      error={!!error}
      helperText={error}
      data-testid={'notes-field'}
    />
  );
}
