import React from 'react';
import { Paragraph } from 'elmo-elements';
import './style.scss';
import { StoreState } from 'state/types';
import { getSummaryStatus } from 'state/Roster/Summary/selectors';
import { connect } from 'react-redux';
import { SummaryStatus } from 'state/Roster/Summary/types';
import { getClasses } from '../../index';

type StateProps = {
  status: SummaryStatus
};

type OwnProps = {
  shiftCount: number;
  userCount: number;
  currency: string | null;
  duration: string | null;
  shiftsHighlight?: boolean;
  usersHighlight?: boolean;
};

type Props = OwnProps & StateProps;

export function DayInfo(props: Props) {

  const {
    shiftCount,
    userCount,
    currency,
    duration,
    status,
    usersHighlight,
    shiftsHighlight
  } = props;

  return <div className="day-info">
    <Paragraph id="duration" className={
      getClasses('day-info__value', {'day-info__highlighted': usersHighlight || shiftsHighlight})
    }>
     {duration} {currency && <span>({currency})</span>}
    </Paragraph>
    {status === 'visible' && <>
      <Paragraph
        id="shifts-count"
        className={
          getClasses('day-info__value', {'day-info__highlighted': shiftsHighlight})
        }
      >
        {shiftCount}
      </Paragraph>
      <Paragraph
        id="users-count"
        className={
          getClasses('day-info__value', {'day-info__highlighted': usersHighlight})
        }
      >
        {userCount}
      </Paragraph></>}
  </div>;
}

const mapStateToProps = (state: StoreState): StateProps => ({
  status: getSummaryStatus(state)
});

export default connect(mapStateToProps)(DayInfo)
