import React, { Component } from 'react';
import { confirmationModalTitle } from '../../../../../messages';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';
import { PageDialog, PageDialogCancel, PageDialogSubmit } from 'element';

type Props = {
  isOpened: boolean;
  approveAction: (value: boolean) => void;
  closeModal: () => void;
};

export default class ApproveModal extends Component<Props> {
  render() {
    const { isOpened } = this.props;
    return (
      <PageDialog
        id={'approve-timesheet-actions-modal'}
        className={'approve-timesheet-modal'}
        maxWidth="xs"
        open={isOpened}
        onClose={this.props.closeModal}
      >
        <DialogTitle>{confirmationModalTitle}</DialogTitle>

        <DialogContent>Do you want to Approve this Timesheet?</DialogContent>
        <DialogActions>
          <PageDialogSubmit
            id={'approve-actions-btn'}
            className={'approve-btn'}
            onClick={() => this.props.approveAction(true)}
            sx={{ paddingLeft: '5px', paddingRight: '5px' }}
          >
            Approve
          </PageDialogSubmit>
          <PageDialogSubmit
            id={'unapprove-actions-btn'}
            className={'decline-btn'}
            onClick={() => this.props.approveAction(false)}
          >
            <span style={{ whiteSpace: 'nowrap' }}>Don't approve</span>
          </PageDialogSubmit>
          <PageDialogCancel
            id={'cancel-actions-btn'}
            className={'cancel-btn'}
            onClick={this.props.closeModal}
          >
            Cancel
          </PageDialogCancel>
        </DialogActions>
      </PageDialog>
    );
  }
}
