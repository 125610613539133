import { createSelector } from 'reselect';
import { searchCombinerForUserCreator } from '../../../../combiners';
import { userListSelector } from '../../../../UsersCollection';
import { getSearchQuery } from '../index';

export const foundUsersSelector = createSelector(
  userListSelector,
  getSearchQuery,
  searchCombinerForUserCreator('full_name', 'prefered_name')
);
