import { keys, pickBy } from 'lodash';
import { TimeOffStatus, TimeOff } from 'type';
import { ManagerDashboardGetTimeOffsRequest } from 'lib/Api';
import { CreateTimeOffPayload, UnavailabilityFilters } from './types';

const selectedFiltersByStatus = (
  pending: boolean,
  approved: boolean,
  declined: boolean
): TimeOffStatus[] => {
  const filterValues: { [key in TimeOffStatus]: boolean } = {
    pending,
    approved,
    declined
  };

  return keys(pickBy(filterValues, value => value)) as TimeOffStatus[];
};

export const filtersToPayload = (
  filters: UnavailabilityFilters,
  { page, pageSize }: { page: number; pageSize: number }
): ManagerDashboardGetTimeOffsRequest => {
  const {
    from,
    to,
    pending,
    approved,
    declined,
    sort: { column, direction }
  } = filters;

  const statuses: TimeOffStatus[] = selectedFiltersByStatus(
    pending,
    approved,
    declined
  );

  return {
    type: 'all',
    page,
    per_page: pageSize,
    from,
    to,
    statuses,
    order_by: column,
    order_dir: direction
  };
};

export const createTimeOffHash = (data: CreateTimeOffPayload | TimeOff | Partial<TimeOff>) => {
  if ( !data.start || !data.end || !data.user_id ) {
    return '';
  }
  return `${data.user_id}_${data.start.format('L')}_${data.end.format('L')}`;
};
