import React, { Component } from 'react';
import {
  ShiftTradeProposal,
  ShiftTradeRosteredShift,
  ShiftTradeUser,
} from 'type';
import {
  getCurrentOfferedShift,
  getErrors,
  getIsOutdatedValue,
  getSelectedProposal,
  proposalDecliningId,
  proposalSelectingId,
} from 'state/EmployeeDashboard/ShiftTrades/selectors';
import {
  convertDecimalToFormattedTime,
  getDateTimeFormatted,
  getPreferenceLabel,
  getShiftDuration,
  getTimeFormatted,
  getUserName,
} from 'lib/helpers';
import { Col, Heading, Paragraph, Row, Text } from 'elmo-elements';
import {
  CloseIcon,
  HelpOutlineIcon,
  SwapHorizOutlinedIcon,
  DSTIcon,
  NotesIcon,
  IsAppMarket,
} from 'element';
import {
  BOX_SHIFT_TRADES_DECLINE_PROPOSAL,
  BOX_SHIFT_TRADES_REMOVE_ERRORS,
  BOX_SHIFT_TRADES_RESET_CURRENT_SHIFT,
  BOX_SHIFT_TRADES_SELECT_PROPOSAL,
} from 'state/EmployeeDashboard/ShiftTrades';
import ErrorBox from 'element/ErrorBox';
import { DispatchProps, StateProps } from './types';
import { colProps, getTimezone } from '../../helpers';
import {
  getDateFormat,
  getLangPreferences,
  getTimeFormat,
} from 'state/Account';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from 'oxygen-elements';

type Props = StateProps & DispatchProps;

export class View extends Component<Props> {
  render() {
    return (
      <div className={'shift-swap-proposals-wrapper'}>
        <ErrorBox
          errors={this.props.errors}
          clearErrors={this.props.removeErrors}
        />
        {this.getShiftTradeDuration()}
        {this.getShiftTradePosition()}
        <div>
          {this.props.shift && (
            <NotesIcon
              styles={{ padding: 0 }}
              notes={this.props.shift.rostered_shift.notes}
            />
          )}
        </div>
        <br />
        <br />
        <br />
        {this.showCards()}
      </div>
    );
  }

  getShiftTradeDuration = () => {
    const { shift, dateFormat, timeFormat } = this.props;
    if (shift !== null) {
      const { rostered_shift } = shift;
      const date = getDateTimeFormatted(
        dateFormat,
        timeFormat,
        rostered_shift.start
      );
      const start = getTimeFormatted(timeFormat, rostered_shift.start);
      const end = getTimeFormatted(timeFormat, rostered_shift.end);
      const tz = getTimezone(rostered_shift);
      return (
        <Heading isBold={true}>
          <IsAppMarket market={'uk'} negate={true}>
            {(isMarket) => {
              return (
                <>
                  {date}, {start} - {end}
                  {isMarket && `, ${tz}`}
                  <DSTIcon isDST={shift.rostered_shift.is_dst_intersect} />
                </>
              );
            }}
          </IsAppMarket>
        </Heading>
      );
    }
    return null;
  };

  getShiftTradePosition = () => {
    const { shift } = this.props;
    if (shift !== null) {
      const { rostered_shift } = shift;
      if (rostered_shift && rostered_shift !== null) {
        return `${rostered_shift.role.name} - ${rostered_shift.area.name}, ${rostered_shift.area.site.name}`;
      }
    }
    return null;
  };

  showCards = () => {
    const { shift } = this.props;
    if (shift !== null) {
      const { proposals } = shift;
      if (shift.status === 'selected' || shift.status === 'approved') {
        return this.showSelectedProposal();
      }
      if (proposals && proposals.length) {
        return proposals.map((p: ShiftTradeProposal, index: number) => (
          <div className={'swap-proposal-card'} key={index}>
            {this.showProposalCard(p)}
          </div>
        ));
      }
    }
    return <Paragraph>No shift swaps offered for exchange</Paragraph>;
  };

  showSelectedProposal = () => {
    const { shift, selectedProposal } = this.props;
    if (
      shift &&
      selectedProposal &&
      shift !== null &&
      selectedProposal !== null
    ) {
      return (
        <div className={'swap-proposal-card'}>
          {this.showProposalCard(selectedProposal)}
        </div>
      );
    }
    return null;
  };

  showProposalCard = (p: ShiftTradeProposal) => {
    const { shift, langPreferences, dateFormat, timeFormat } = this.props;
    if (shift !== null) {
      const { status } = shift;
      return (
        <Card className="shift-swap-proposal-item" role="row">
          <CardHeader
            className="card-header"
            avatar={this.getUserAvatar(p.user)}
            title={this.getFullName(p.user)}
            titleTypographyProps={{
              fontSize: 16,
              fontWeight: 500,
            }}
          />
          <CardContent className="card-content">
            <Row>
              <Col {...colProps}>
                <Text color="gray" size="sm">
                  {getPreferenceLabel(
                    langPreferences,
                    'role',
                    'singular',
                    '',
                    true
                  )}
                  - {getPreferenceLabel(langPreferences, 'area', 'singular')},{' '}
                  {getPreferenceLabel(langPreferences, 'site', 'singular')}
                </Text>
                <Paragraph isTruncate={true}>
                  {this.getPositionAreaLocation(p.rostered_shift)}
                </Paragraph>
              </Col>
              <Col {...colProps}>
                <Text color="gray" size="sm">
                  Date
                </Text>
                <div>
                  {getDateTimeFormatted(
                    dateFormat,
                    timeFormat,
                    p.rostered_shift!.start
                  )}
                </div>
              </Col>
              <Col {...colProps}>
                <Text color="gray" size="sm">
                  Start - finish (duration){' '}
                  <DSTIcon isDST={shift.rostered_shift.is_dst_intersect} />
                </Text>
                <div>
                  {this.getProposalShiftTradeDuration(p.rostered_shift)}
                </div>
              </Col>
              <Col {...colProps}>
                <Text color="gray" size="sm">
                  Prev / next shift (hrs)
                </Text>
                <Paragraph isTruncate={true}>
                  {this.getProposalDates(p)}
                </Paragraph>
              </Col>
              {p.rostered_shift && (
                <Col {...colProps}>
                  <NotesIcon notes={p.rostered_shift.notes} />
                </Col>
              )}
            </Row>
            <IsAppMarket market={'uk'} negate={true}>
              <Row>
                <Col {...colProps}>
                  <Text color="gray" size="sm">
                    Timezone
                  </Text>

                  <Paragraph isTruncate={true}>
                    {getTimezone(shift.rostered_shift)}
                  </Paragraph>
                </Col>
              </Row>
            </IsAppMarket>
          </CardContent>
          {status !== 'selected' && status !== 'approved' && (
            <CardActions className="card-actions">
              {this.getActions(p).map((action, key) => (
                <Button
                  id={action.id}
                  key={`btn-${action.label}`}
                  size="large"
                  onClick={action.onClick}
                  disabled={action.isDisabled}
                  loading={action.isLoading}
                >
                  {action.icon} {action.label}
                </Button>
              ))}
            </CardActions>
          )}
        </Card>
      );
    }
    return null;
  };

  getFullName = (user: ShiftTradeUser) => {
    return user === null ? 'Unassigned' : getUserName(user);
  };

  getUserAvatar = (user: ShiftTradeUser) => {
    return user !== null ? (
      <Avatar
        light
        alt={this.getFullName(user)}
        size={'small'}
        src={user.avatar_url}
      />
    ) : (
      <Text color={'warning'}>
        <HelpOutlineIcon />
      </Text>
    );
  };

  getPositionAreaLocation = (shift: ShiftTradeRosteredShift | null) => {
    return shift !== null
      ? `${shift.role.name} - ${shift.area.name}, ${shift.area.site.name}`
      : '-';
  };

  getProposalShiftTradeDuration = (shift: ShiftTradeRosteredShift | null) => {
    const { timeFormat } = this.props;
    if (shift !== null) {
      const duration = getShiftDuration(
        shift.start,
        shift.end,
        shift.breaks,
        shift.area.site.timezone_id
      );
      const totalHrs =
        '(' +
        convertDecimalToFormattedTime(duration.total_hrs.toString(), true) +
        ')';
      return `${getTimeFormatted(timeFormat, shift.start)} - ${getTimeFormatted(
        timeFormat,
        shift.end
      )} ${totalHrs}`;
    }
    return '-';
  };

  getActions = (proposal: ShiftTradeProposal) => {
    const { isDeclining, isSelecting, isOutDated } = this.props;
    return [
      {
        icon: <CloseIcon />,
        label: 'Decline',
        isLoading: isDeclining === proposal.id,
        isDisabled: isOutDated || isDeclining === proposal.id,
        onClick: () => {
          this.props.declineProposal(proposal.id);
        },
      },
      {
        icon: <SwapHorizOutlinedIcon />,
        label: 'Swap',
        isLoading: isSelecting === proposal.id,
        isDisabled: isOutDated || isSelecting === proposal.id,
        id: 'swap-proposal',
        onClick: () => {
          this.props.selectProposal(proposal.id);
        },
      },
    ];
  };

  getProposalDates = (p: ShiftTradeProposal) => {
    const { closestDates } = p;
    const { shift } = this.props;
    const rosteredShift = shift !== null ? shift.rostered_shift : null;
    let prev = '-';
    let next = '-';
    if (closestDates && closestDates.prev !== null && rosteredShift !== null) {
      prev = `${closestDates.prev} hrs`;
    }
    if (closestDates && closestDates.next !== null && rosteredShift !== null) {
      next = `${closestDates.next} hrs`;
    }
    return `${prev} / ${next}`;
  };

  componentWillUnmount(): void {
    this.props.resetCurrentShift();
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  shift: getCurrentOfferedShift(state),
  isSelecting: proposalSelectingId(state),
  isDeclining: proposalDecliningId(state),
  errors: getErrors(state),
  selectedProposal: getSelectedProposal(state),
  langPreferences: getLangPreferences(state),
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
  isOutDated: getIsOutdatedValue(state, 'shiftSwap'),
});

const mapToDispatchProps: DispatchProps = {
  selectProposal: BOX_SHIFT_TRADES_SELECT_PROPOSAL,
  declineProposal: BOX_SHIFT_TRADES_DECLINE_PROPOSAL,
  removeErrors: BOX_SHIFT_TRADES_REMOVE_ERRORS,
  resetCurrentShift: BOX_SHIFT_TRADES_RESET_CURRENT_SHIFT,
};

export default connect(mapStateToProps, mapToDispatchProps)(View);
