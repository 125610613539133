import React from 'react';
import { StoreState } from '../../../state/types';
import { connect } from 'react-redux';
import { getIsOpened } from 'state/Roster/ReAcceptConfirmModal/selectors';
import {
  BOX_RE_ACCEPT_CONFIRM_MODAL_CANCEL,
  BOX_RE_ACCEPT_CONFIRM_MODAL_SUBMIT,
} from 'state/Roster/ReAcceptConfirmModal/actions';
import { confirmationModalTitle } from '../../../messages';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from 'extended-oxygen-elements';
import { PageDialog, PageDialogCancel, PageDialogSubmit } from 'element';

type OwnProps = {};

type StateProps = {
  isOpened: boolean;
};

type DispatchProps = {
  onSubmit: () => void;
  onCancel: () => void;
};

export type Props = OwnProps & StateProps & DispatchProps;

export const ReAcceptConfirmationModalComponent = ({
  isOpened,
  onSubmit,
  onCancel,
}: Props) => (
  <PageDialog maxWidth={'xs'} open={isOpened} id="reaccept-modal">
    <DialogTitle>{confirmationModalTitle}</DialogTitle>
    <DialogContent>
      <Typography variant={'body1'}>
        Would you like the user to accept the shift again?
      </Typography>
    </DialogContent>
    <DialogActions>
      <PageDialogCancel onClick={onCancel}>No</PageDialogCancel>
      <PageDialogSubmit onClick={onSubmit}>Yes</PageDialogSubmit>
    </DialogActions>
  </PageDialog>
);

const mapStateToProps = (state: StoreState): StateProps => ({
  isOpened: getIsOpened(state),
});

const mapDispatchToProps: DispatchProps = {
  onSubmit: BOX_RE_ACCEPT_CONFIRM_MODAL_SUBMIT,
  onCancel: BOX_RE_ACCEPT_CONFIRM_MODAL_CANCEL,
};

export const ReAcceptConfirmationModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReAcceptConfirmationModalComponent);
