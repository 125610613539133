import { put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { ApiWithConfirmReturnType, SagaActionFromCreator } from 'type';
import Api from 'lib/Api';
import { formatError } from 'state/helpers';
import { apiWithConfirmCall } from 'state/ProcessApiRequest';
import * as actions from './actions';

const createTimesheet = function* ({
  payload,
}: SagaActionFromCreator<
  typeof actions.BOX_CREATE_TIMESHEET_DIALOG_SUBMIT.request
>): SagaIterator {
  try {
    const response: ApiWithConfirmReturnType<
      typeof Api.Timesheet.createTimesheetResponse
    > = yield apiWithConfirmCall(
      Api.Timesheet.createTimesheetResponse,
      payload
    );
    if (response) {
      yield put(actions.BOX_CREATE_TIMESHEET_DIALOG_SUBMIT.success(response));
    } else {
      yield put(actions.BOX_CREATE_TIMESHEET_DIALOG_SUBMIT.cancel());
    }
  } catch (error) {
    yield put(
      actions.BOX_CREATE_TIMESHEET_DIALOG_SUBMIT.failure(formatError(error))
    );
  }
};

export const watchCreateTimesheetDialog = function* () {
  yield takeLatest(
    actions.BOX_CREATE_TIMESHEET_DIALOG_SUBMIT.request,
    createTimesheet
  );
};
