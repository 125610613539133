import React from 'react';
import { FetchContainer } from 'element';
import {
  BOX_ACCOUNT_TIMEZONES,
  getAccountPreferencesPageErrors,
  getTimezonesListLoading,
} from 'state/Account';
import { AccountPreferencesView } from './view';

export default function AccountPreferences() {
  const fetchAction = React.useMemo(() => BOX_ACCOUNT_TIMEZONES.request(), []);
  return (
    <FetchContainer
      action={fetchAction}
      loadingSelector={getTimezonesListLoading}
      errorsSelector={getAccountPreferencesPageErrors}
    >
      <AccountPreferencesView />
    </FetchContainer>
  );
}
