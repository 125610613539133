import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Filter } from 'elmo-elements';
import { StoreState } from 'state/types';
import { ShiftOffersFilterTypes } from 'state/ManagerDashboard/ShiftOffers/ShiftOffers/types';
import {
  BOX_SHIFT_OFFERS_TOGGLE_FILTER_TYPE,
  getSelectedFilter
} from 'state/ManagerDashboard/ShiftOffers/ShiftOffers';

type StateProps = {
  selectedFilter: ShiftOffersFilterTypes;
};

type DispatchProps = {
  toggleFilter: (filter: ShiftOffersFilterTypes) => void;
};

type Props = StateProps & DispatchProps;

const filterStatuses: { value: ShiftOffersFilterTypes; label: string }[] = [
  { value: 'all', label: 'All' },
  { value: 'responsesOnly', label: 'Responses Only' }
];

class ShiftOffersFiltersComponent extends Component<Props> {
  render() {
    return (
      <Filter
        id="offers"
        onStatusChange={this.onStatusChange}
        selectedValue={this.props.selectedFilter}
        statuses={filterStatuses}
      />
    );
  }

  private onStatusChange = (status: string) => {
    this.props.toggleFilter(status as ShiftOffersFilterTypes);
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  selectedFilter: getSelectedFilter(state)
});

const mapDispatchToProps: DispatchProps = {
  toggleFilter: BOX_SHIFT_OFFERS_TOGGLE_FILTER_TYPE
};

export const ShiftOffersFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShiftOffersFiltersComponent);
