import React, { Component, ComponentProps } from 'react';
import { connect } from 'react-redux';
import { Duration, Moment } from 'moment';
import { isNull, last, times } from 'lodash';
import { Text } from 'elmo-elements';
import { HelpOutlineIcon } from 'element';
import { RosteredShift } from 'type';
import { StoreState } from 'state/types';
import { fromBySiteTimezoneSelector } from 'state/RosteredShifts';
import {
  CreateShiftStartPayload,
  DragAndDropUserViewFinishedPayload,
  UnassignedRosterData,
  UpdateShiftTimePayload,
} from 'state/Roster/RosterDayView/types';
import { unassignedRosterDataSelector } from 'state/Roster/RosterDayView/selectors';
import { GetRosterByIDPayload } from 'state/Roster/RosterShiftModal/types';
import { BOX_ROSTER_SHIFT_MODAL_GET_BY_ID } from 'state/Roster/RosterShiftModal';
import {
  BOX_ROSTER_DAY_VIEW_CREATE_SHIFT_START,
  BOX_ROSTER_DAY_VIEW_DRAG_AND_DROP_USER_VIEW_FINISHED,
  BOX_ROSTER_DAY_VIEW_UNASSIGNED_ROW_ADD,
  BOX_ROSTER_DAY_VIEW_UPDATE_ROSTERED_SHIFT_TIME_REQUEST,
  MIN_UNASSIGNED_ROWS_QUANTITY,
} from 'state/Roster/RosterDayView';
import {
  RosterCellType,
  RosterRow,
  RosterUserLegend,
} from '../../../../../../components';
import {
  DayUserRosteredShift,
  Timeline,
  WithLegendData,
} from '../../../../components';
import { DayUserUnassignedCreatedShift } from './DayUserUnassignedCreatedShift';
import { calculateStart } from '../../../../helpers';

type OwnProps = {};

type StateProps = {
  unassignedRosterData: UnassignedRosterData;
  from: Moment;
};

type DispatchProps = {
  updateRosteredShiftTime: (payload: UpdateShiftTimePayload) => void;
  getRosterById: (payload: GetRosterByIDPayload) => void;
  addRow: () => void;
  createShift: (payload: CreateShiftStartPayload) => void;
  dragAndDropFinished: (payload: DragAndDropUserViewFinishedPayload) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

export class DayUserUnassignedRowComponent extends Component<Props> {
  render() {
    return (
      <RosterRow>
        {{
          sticky: (
            <WithLegendData duration={this.duration}>
              {({ durations }) => (
                <RosterUserLegend
                  type={this.rows.length > 1 ? 'multi-row' : 'single-row'}
                  icon={
                    <Text color={'warning'}>
                      <HelpOutlineIcon />
                    </Text>
                  }
                  label="Unassigned"
                  durations={durations}
                  onClick={this.onLegendClickCreator()}
                  pageType={'day'}
                />
              )}
            </WithLegendData>
          ),
          content: <Timeline rows={this.rows} />,
        }}
      </RosterRow>
    );
  }

  private get duration() {
    const {
      unassignedRosterData: { duration },
    } = this.props;

    return {
      roster: duration,
    };
  }

  private get minRows(): (RosteredShift | null)[] {
    const { rows } = this.props.unassignedRosterData;

    if (rows.length < MIN_UNASSIGNED_ROWS_QUANTITY) {
      return [
        ...rows,
        ...times(MIN_UNASSIGNED_ROWS_QUANTITY - rows.length, () => null),
      ];
    }

    return rows;
  }

  private get hasEmptyRow() {
    const lastRow = last(this.minRows);
    return isNull(lastRow);
  }

  private get rows(): ComponentProps<typeof Timeline>['rows'] {
    const { minRows } = this;
    const { from } = this.props;
    const type: RosterCellType = 'full';

    return minRows.map(
      (
        rosteredShift,
        rowIndex
      ): ComponentProps<typeof Timeline>['rows'][number] => {
        const onMouseDown = this.onMouseDownCreator(rowIndex);

        const partialTimelineRow: Pick<
          ComponentProps<typeof Timeline>['rows'][number],
          'type' | 'onMouseDown' | 'onDrop' | 'validateDragOver'
        > = {
          type,
          onMouseDown,
          onDrop: this.onDrop,
          validateDragOver: this.validateDragOver,
        };

        if (!rosteredShift) {
          return {
            ...partialTimelineRow,
            content: (
              <DayUserUnassignedCreatedShift
                cellType={type}
                rowIndex={rowIndex}
              />
            ),
          };
        }

        return {
          ...partialTimelineRow,
          isTall: !!rosteredShift.event_id,
          content: (
            <>
              <DayUserRosteredShift
                key={rosteredShift.id}
                rosteredShift={rosteredShift}
                day={from}
                type={type}
                onResizeEnd={this.props.updateRosteredShiftTime}
                isEditable={true}
              />

              <DayUserUnassignedCreatedShift
                cellType={type}
                rowIndex={rowIndex}
              />
            </>
          ),
        };
      }
    );
  }

  private onLegendClickCreator = () => {
    const { hasEmptyRow } = this;

    if (hasEmptyRow) {
      return;
    }

    return this.props.addRow;
  };

  private onMouseDownCreator =
    (pairIndex: number) => (duration: Duration, clientX: number) => {
      const { createShift, from } = this.props;

      createShift({
        shift: {
          start: calculateStart(from, duration),
        },
        clientX,
        type: 'rosteredShift',
        pairIndex,
      });
    };

  private onDrop = (duration: Duration) => {
    if (!this.validateDragOver()) {
      return;
    }

    const { from, dragAndDropFinished } = this.props;

    const newStartTime = calculateStart(from, duration);

    dragAndDropFinished({
      newStartTime,
      userId: null,
    });
  };

  private validateDragOver = () => {
    return true;
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  unassignedRosterData: unassignedRosterDataSelector(state),
  from: fromBySiteTimezoneSelector(state),
});

const mapDispatchToProps: DispatchProps = {
  updateRosteredShiftTime:
    BOX_ROSTER_DAY_VIEW_UPDATE_ROSTERED_SHIFT_TIME_REQUEST,
  getRosterById: BOX_ROSTER_SHIFT_MODAL_GET_BY_ID,
  addRow: BOX_ROSTER_DAY_VIEW_UNASSIGNED_ROW_ADD,
  createShift: BOX_ROSTER_DAY_VIEW_CREATE_SHIFT_START,
  dragAndDropFinished: BOX_ROSTER_DAY_VIEW_DRAG_AND_DROP_USER_VIEW_FINISHED,
};

export const DayUserUnassignedRow = connect(
  mapStateToProps,
  mapDispatchToProps
)(DayUserUnassignedRowComponent);
