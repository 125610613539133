import React from 'react';
import { connect } from 'react-redux';
import {
  getTemplateShiftsArray,
  groupShiftItemsByUsersSelector,
} from 'state/Roster/EditTemplate/selectors';
import { StoreState } from 'state/types';
import {
  appendRowForUserView,
  compareProps,
  getDurationsInfoForTemplate,
} from '../../../../helpers';
import RosterRowGroup from '../../../../components/ShiftCard/components/RosterRowGroup';
import { ShiftTemplateRow } from '../../components/ShiftTemplateRow';
import { userListSelector } from '../../../../../../state/UsersCollection';
import {
  ShiftTemplateItem,
  StringMap,
  UserFields,
} from '../../../../../../type';
import { RosterFilters } from 'state/Roster/RosterFilters';
import {
  getSelectedByUser,
  getIsBulkDeleteOpened,
} from 'state/Roster/BulkActions/selectors';
import { BulkSelectionPropsItem } from '../../../../../../state/Roster/BulkActions/types';
import WithArrowsNav from '../../../RosterWeekView/components/WithArrowsNav';
import { BOX_SHIFT_TIME_RANGE_INPUT_CLOSED } from 'state/Roster/RangeInput/ShiftTimeRangeInput';

type StateProps = {
  shifts: ShiftTemplateItem[];
  shiftsRows: any;
  users: StringMap<UserFields>;
  isBulkDelete: boolean;
  selectedByUser: BulkSelectionPropsItem;
};

type DispatchProps = {
  closeInput: () => void;
};

type Props = StateProps & DispatchProps;

type State = {
  rostersWeek: any;
};

export class EditView extends React.Component<Props, State> {
  renderIndex: number = 0;

  state = {
    rostersWeek: [],
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    const { shiftsRows, users } = this.props;
    if (prevProps.shiftsRows !== shiftsRows || prevProps.users !== users) {
      this.initWeekView();
    }
  }

  componentDidMount() {
    this.initWeekView();
  }

  initWeekView = () => {
    const { shiftsRows } = this.props;
    this.setState({
      ...this.state,
      rostersWeek: shiftsRows,
    });
  };

  appendEmptyRow = (userId: string | null) => {
    this.setState((prevState) => ({
      ...prevState,
      rostersWeek: appendRowForUserView(
        prevState.rostersWeek,
        this.props.users,
        userId
      ),
    }));
  };

  getUsersRows = (): any => {
    const { rostersWeek } = this.state;
    const rosters: any = [];
    for (let i = 0; i < rostersWeek.length - 1; i += 1) {
      rosters.push(rostersWeek[i]);
    }
    return rosters;
  };

  getUnassignedRows = (): any => {
    const { rostersWeek } = this.state;
    const length = rostersWeek.length - 1;
    return rostersWeek[length];
  };

  render() {
    return (
      <WithArrowsNav
        renderIndex={this.renderIndex++}
        closeInput={this.props.closeInput}
      >
        <div style={{ marginTop: '10px' }}>
          {this.unassignedRow()}
          {this.usersRows()}
        </div>
      </WithArrowsNav>
    );
  }

  usersRows = () => {
    const rosteredShifts = this.getUsersRows();
    const { shifts, isBulkDelete, selectedByUser } = this.props;
    return rosteredShifts.map((userData: any, index: number) => (
      <RosterRowGroup
        type={'user'}
        data={{
          user: (this.props.users as any)[userData.user_id],
          area: null,
        }}
        actions={
          isBulkDelete
            ? () => false
            : () => this.appendEmptyRow(userData.user_id)
        }
        key={index}
        legendType={'single-row'}
        duration={getDurationsInfoForTemplate(userData.rosters, shifts)}
        selectedFilter={{ roster: true } as RosterFilters}
        bulkSelect={'user'}
        isSelected={selectedByUser[userData.user_id] === true}
        rowIndex={index + 1}
        pageType={'week'}
      >
        {this.showCells(userData)}
      </RosterRowGroup>
    ));
  };

  unassignedRow = () => {
    const unassigned = this.getUnassignedRows();
    const { shifts, isBulkDelete, selectedByUser } = this.props;
    return (
      unassigned && (
        <RosterRowGroup
          type={'unassigned'}
          data={{
            user: null,
            area: null,
          }}
          duration={getDurationsInfoForTemplate(unassigned.rosters, shifts)}
          actions={
            isBulkDelete
              ? () => false
              : () => this.appendEmptyRow(unassigned.user_id)
          }
          legendType={'single-row'}
          selectedFilter={{ roster: true } as RosterFilters}
          bulkSelect={'user'}
          isSelected={selectedByUser['null'] === true}
          rowIndex={0}
          pageType={'week'}
        >
          <div className="week-rosters-grid__blocks unassigned">
            {unassigned.rosters.map((userData: any, index: number) => (
              <React.Fragment key={index}>
                {this.showUnassignedCells(userData, index)}
              </React.Fragment>
            ))}
          </div>
        </RosterRowGroup>
      )
    );
  };

  showUserCells = (
    rosterData: any,
    userId: string | null,
    rowIndex: number
  ) => {
    return (
      <ShiftTemplateRow
        user_id={userId}
        viewType={'user'}
        data={rosterData}
        onClickRoster={() => false}
        onClickTimesheet={() => false}
        rowIndex={rowIndex}
      />
    );
  };

  showUnassignedCells = (rosterData: any, rowIndex: number) => {
    return (
      <ShiftTemplateRow
        user_id={null}
        viewType={'user'}
        data={rosterData}
        onClickRoster={() => false}
        onClickTimesheet={() => false}
        isUnassigned={true}
        rowIndex={rowIndex}
      />
    );
  };

  showCells = (user: any) => {
    const { users } = this.props;
    const disabled =
      users[user.user_id] && !users[user.user_id].is_active
        ? 'is-disabled'
        : '';
    return (
      <React.Fragment>
        {user.rosters.map((data: any, index: number) => (
          <div
            key={index}
            className={`week-rosters-grid__blocks blocks-with-cells ${disabled}`}
          >
            {this.showUserCells(data, user.user_id, index)}
          </div>
        ))}
      </React.Fragment>
    );
  };

  shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>,
    nextContext: any
  ): boolean {
    return (
      compareProps(nextState, this.state, []) ||
      compareProps(nextProps, this.props, ['weekDays', 'shifts'])
    );
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  shifts: getTemplateShiftsArray(state),
  shiftsRows: groupShiftItemsByUsersSelector(state),
  users: userListSelector(state),
  isBulkDelete: getIsBulkDeleteOpened(state),
  selectedByUser: getSelectedByUser(state),
});

const mapDispatchToProps: DispatchProps = {
  closeInput: BOX_SHIFT_TIME_RANGE_INPUT_CLOSED,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditView);
