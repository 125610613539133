import { UserProfileFields } from '../../../../../../type/models';

export const hasDifference = (
  stateProfile: Partial<UserProfileFields>,
  userProfile: Partial<UserProfileFields>
) => {
  for (let key in stateProfile) {
    if ( (stateProfile as any)[key] !== (userProfile as any)[key] ) {
      return true;
    }
  }
  return false;
};

export const fieldsToUpdate = (
  stateProfile: Partial<UserProfileFields>,
  userProfile: Partial<UserProfileFields>
) => {
  const fields: Partial<UserProfileFields> = {};
  for (let key in stateProfile) {
    if ( (stateProfile as any)[key] !== (userProfile as any)[key] ) {
      (fields as any)[key] = (stateProfile as any)[key];
    }
  }
  return fields;
};