import React, { Component, ReactNode } from 'react';
import Button from '../../Button';
import ModalTitle from './ModalTitle';
import { CloseIcon } from '../../icons';
import clsx from 'clsx';

const ModalHeaderDefaultProps = {
  actions: [],
};

type ModalHeaderProps = {
  hasShadow: boolean;
  /** Modal title icon */
  icon?: ReactNode;
  /** ModalHeader title attribute */
  title?: ReactNode;
  /** ModalHeader titleSize attribute */
  titleSize?: string;
  /** ModalHeader closeModal function, triggered to close modal */
  closeModal?: Function;
  /** ModalHeader primary button */
  primaryButton?: ReactNode;
  /** ModalHeader backButton function, tirggered to close modal */
  backButton?: ReactNode;
};

type ModalHeaderStates = {};

class ModalHeader extends Component<ModalHeaderProps, ModalHeaderStates> {
  static defaultProps = ModalHeaderDefaultProps;

  /**
   * Render modal
   */
  render() {
    const {
      icon,
      title,
      titleSize,
      primaryButton,
      closeModal,
      backButton,
      hasShadow,
    } = this.props;
    return (
      <div
        className={clsx('elmo-modal__header', {
          'elmo-modal__header--has-shadow': hasShadow,
        })}
      >
        {backButton && (
          <div className="elmo-modal__header-back">{backButton}</div>
        )}
        <ModalTitle icon={icon} title={title} titleSize={titleSize} />
        {!backButton && closeModal && (
          <div className="elmo-modal__header-close">
            <Button
              icon={<CloseIcon />}
              isText={true}
              onClick={() => {
                closeModal();
              }}
              className="elmo-modal__header-close-btn"
              ariaLabel="Close"
            />
          </div>
        )}
        {primaryButton && (
          <div className="elmo-modal__header-primary-btn">{primaryButton}</div>
        )}
      </div>
    );
  }
}

export default ModalHeader;
