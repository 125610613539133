import { createSelector } from 'reselect';
import { reduce } from 'lodash';
import { StringMap } from 'type';
import { StoreState } from '../../../types';
import { IdsOfProposal } from './types';

export const getDeleteQueue = (state: StoreState): string[] =>
  state.managerDashboard.shiftOffers.shiftOffersUndo.deleteQueue;

const getDeclineProposalQueue = (state: StoreState): IdsOfProposal[] =>
  state.managerDashboard.shiftOffers.shiftOffersUndo.declineProposalQueue;

export const getApproveQueue = (state: StoreState): string[] =>
  state.managerDashboard.shiftOffers.shiftOffersUndo.approveProposalQueue;

export const declineProposalQueueByShiftOfferIdSelector = createSelector<
  StoreState,
  IdsOfProposal[],
  StringMap<string[]>
>(getDeclineProposalQueue, declineProposalQueue =>
  reduce(
    declineProposalQueue,
    (
      accumulator: StringMap<string[]>,
      { proposalId, shiftOfferId }: IdsOfProposal
    ) => {
      if (accumulator[shiftOfferId]) {
        accumulator[shiftOfferId].push(proposalId);
      } else {
        accumulator[shiftOfferId] = [proposalId];
      }

      return accumulator;
    },
    {}
  )
);
