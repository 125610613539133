import Api, {
  OVERLAP_ERROR_STATUS,
  RosteredShiftGetListResponse,
} from 'lib/Api';
import {
  TimesheetCreateRequest,
  TimesheetUpdateRequest,
  TimesheetUpdateResponse,
} from 'lib/Api/type';
import browserHistory from 'lib/browserHistory';
import { SERVER_DAY_FORMAT } from 'lib/config';
import { getDayRange } from 'lib/helpers';
import routeQuery from 'lib/routeQuery';
import { Moment } from 'moment';
import { SagaIterator } from 'redux-saga';
import {
  all,
  call,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import * as fetchActions from 'state/FetchPageData';
import { formatError, getErrorStatus } from 'state/helpers';
import {
  BOX_IS_LOADING_OFF,
  BOX_IS_LOADING_ON,
  MANAGER_DASHBOARD_TIMESHEETS,
} from 'state/IsLoading';
import {
  apiCall,
  processApiRequest,
  processApiRequestWithConfirm,
} from 'state/ProcessApiRequest';
import {
  BOX_TIMESHEET_DELETE,
  BOX_TIMESHEET_UPDATE_ONE,
  createTimesheetRequest,
  getFrom,
  getSiteId,
  getTimesheet,
  getTimesheetListRequest,
  getTo,
} from 'state/TimesheetsCollection';
import { getRotaUserListRequest } from 'state/UsersCollection';
import {
  Collection,
  DashboardShift,
  SagaAction,
  SagaActionFromCreator,
  Timesheet,
  TimesheetsPageQuery,
} from 'type';
import { hasPermissionSelector } from 'state/Auth';
import {
  BOX_MANAGER_DASHBOARD_TIMESHEET_CHANGED,
  BOX_MANAGER_DASHBOARD_TIMESHEET_DELETED,
} from 'state/ManagerDashboard/ManagerDashboard';
import { DeleteResponse } from 'state/ManagerDashboard/WhosWorking/types';
import * as actions from './actions';
import {
  ConfirmForceModalTimesheetPayload,
  GetDayPayload,
  GetPageDataPayload,
  GetSitePayload,
} from './types';

export const getPageDataRequest = function* (
  payload: GetPageDataPayload
): SagaIterator {
  const { site_id, from } = payload;
  const query: TimesheetsPageQuery = {
    site_id,
    day: from.format(SERVER_DAY_FORMAT),
  };

  browserHistory.replace({
    search: routeQuery.stringify(query),
  });

  yield all([
    call(getRotaUserListRequest),
    call(getTimesheetListRequest, {
      ...payload,
      with_pay_entries: true,
      mode: 'today-shifts',
    }),
  ]);
};

const getPageData = function* ({
  payload,
}: SagaAction<GetPageDataPayload>): SagaIterator {
  yield put(
    fetchActions.BOX_FETCH_PAGE_DATA_REQUEST(
      fetchActions.MANAGER_DASHBOARD_TIMESHEETS
    )
  );

  try {
    yield call(getPageDataRequest, payload);
    yield put(
      fetchActions.BOX_FETCH_PAGE_DATA_SUCCESS(
        fetchActions.MANAGER_DASHBOARD_TIMESHEETS
      )
    );
  } catch (error) {
    yield put(
      fetchActions.BOX_FETCH_PAGE_DATA_FAILURE(
        fetchActions.MANAGER_DASHBOARD_TIMESHEETS
      )
    );
  }
};

const getSite = function* ({
  payload: { site_id },
}: SagaAction<GetSitePayload>): SagaIterator {
  yield put(BOX_IS_LOADING_ON(MANAGER_DASHBOARD_TIMESHEETS));

  const from = yield select(getFrom);
  const to = yield select(getTo);

  const payload = {
    from,
    to,
    site_id,
  };

  try {
    yield call(getPageDataRequest, payload);
  } catch (error) {
    yield put(actions.BOX_TIMESHEETS_GET_SITE_FAILURE(formatError(error)));
  }

  yield put(BOX_IS_LOADING_OFF(MANAGER_DASHBOARD_TIMESHEETS));
};

const getDay = function* ({
  payload: { date },
}: SagaAction<GetDayPayload>): SagaIterator {
  yield put(BOX_IS_LOADING_ON(MANAGER_DASHBOARD_TIMESHEETS));

  const siteId = yield select(getSiteId);
  const { from, to } = getDayRange(date);

  const payload = {
    from,
    to,
    site_id: siteId,
  };

  try {
    yield call(getPageDataRequest, payload);
  } catch (error) {
    yield put(actions.BOX_TIMESHEETS_GET_DAY_FAILURE(formatError(error)));
  }

  yield put(BOX_IS_LOADING_OFF(MANAGER_DASHBOARD_TIMESHEETS));
};

type ClosestDayCreatorType = 'prev' | 'next';
const closestDayAction: Collection<
  ClosestDayCreatorType,
  keyof Pick<Moment, 'add' | 'subtract'>
> = {
  prev: 'subtract',
  next: 'add',
};
const getClosestDayCreator = (type: ClosestDayCreatorType) =>
  function* (): SagaIterator {
    yield put(BOX_IS_LOADING_ON(MANAGER_DASHBOARD_TIMESHEETS));
    const fetchedFrom: ReturnType<typeof getFrom> = yield select(getFrom);
    const siteId = yield select(getSiteId);
    const momentAction = closestDayAction[type];
    const date = fetchedFrom.clone()[momentAction](1, 'day');
    const { from, to } = getDayRange(date);
    const payload = {
      from,
      to,
      site_id: siteId,
    };
    try {
      yield call(getPageDataRequest, payload);
    } catch (error) {
      yield put(
        actions.BOX_TIMESHEETS_GET_PREV_NEXT_DAY_FAILURE(formatError(error))
      );
    }
    yield put(BOX_IS_LOADING_OFF(MANAGER_DASHBOARD_TIMESHEETS));
  };

export const updateTimesheet = function* ({
  payload,
}: SagaAction<TimesheetUpdateRequest>): SagaIterator {
  try {
    const oldTimesheet = yield select(getTimesheet, payload.id);
    const response: TimesheetUpdateResponse = yield call(
      processApiRequest,
      Api.Timesheet.updateTimesheet,
      payload
    );
    yield put(actions.BOX_TIMESHEETS_UPDATE_SUCCESS(response.id));
    yield put(BOX_TIMESHEET_UPDATE_ONE(response));
  } catch (error) {
    const errorStatus = getErrorStatus(error);
    const errors = formatError(error);

    if (errorStatus === OVERLAP_ERROR_STATUS) {
      yield fork(confirmForceUpdateTimesheet, { errors, payload });
    } else {
      yield put(
        actions.BOX_TIMESHEETS_UPDATE_FAILURE({
          id: payload.id,
          errors: formatError(error),
        })
      );
    }
  }
};

export function* confirmApprovalUpdateTimesheet({
  payload,
}: SagaAction<TimesheetUpdateRequest>): SagaIterator {
  yield put(actions.BOX_TIMESHEETS_APPROVAL_MODAL_OPEN());
  const { confirmApproved, confirmNotApproved } = yield race({
    confirmApproved: take(
      actions.BOX_TIMESHEETS_APPROVAL_MODAL_SUBMIT_APPROVED
    ),
    confirmNotApproved: take(
      actions.BOX_TIMESHEETS_APPROVAL_MODAL_SUBMIT_NOT_APPROVED
    ),
    close: take(actions.BOX_TIMESHEETS_APPROVAL_MODAL_CLOSE),
  });
  if (confirmApproved) {
    yield put(
      actions.BOX_TIMESHEETS_UPDATE_REQUEST({
        ...payload,
        is_approved: true,
      })
    );
  }
  if (confirmNotApproved) {
    yield put(
      actions.BOX_TIMESHEETS_UPDATE_REQUEST({
        ...payload,
        is_approved: false,
      })
    );
  }
}

export function* confirmForceUpdateTimesheet({
  payload,
  errors,
}: ConfirmForceModalTimesheetPayload): SagaIterator {
  yield put(actions.BOX_TIMESHEETS_CONFIRMATION_MODAL_OPEN(errors));

  const { confirm } = yield race({
    confirm: take(actions.BOX_TIMESHEETS_CONFORMATION_MODAL_SUBMIT),
    close: take(actions.BOX_TIMESHEETS_CONFORMATION_MODAL_CLOSE),
  });

  if (confirm) {
    yield call(updateTimesheetForce, payload);
  }

  if (close as any) {
    // TODO double-check
    yield put(actions.BOX_TIMESHEETS_UPDATE_SUCCESS(payload.id));
  }
}

export function* updateTimesheetForce(
  payload: TimesheetUpdateRequest
): SagaIterator {
  try {
    const oldTimesheet = yield select(getTimesheet, payload.id);
    const response: TimesheetUpdateResponse = yield call(
      processApiRequest,
      Api.Timesheet.updateTimesheet,
      {
        ...payload,
        ignore_errors: true,
      }
    );
    yield put(actions.BOX_TIMESHEETS_UPDATE_SUCCESS(response.id));
  } catch (error) {
    yield put(
      actions.BOX_TIMESHEETS_UPDATE_FAILURE({
        id: payload.id,
        errors: formatError(error),
      })
    );
  }
}

export const createTimesheet = function* ({
  payload,
}: SagaAction<TimesheetCreateRequest>): SagaIterator {
  try {
    yield call(createTimesheetRequest, payload);
    yield put(actions.BOX_TIMESHEETS_CREATE_SUCCESS());
    const siteId = yield select(getSiteId);
    yield put(
      actions.BOX_TIMESHEETS_GET_SITE_REQUEST({
        site_id: siteId,
      })
    );

  } catch (error) {
    yield put(actions.BOX_TIMESHEETS_CREATE_FAILURE(formatError(error)));
  }
};

function* deleteTimesheet({
  payload: { id },
}: SagaActionFromCreator<
  typeof actions.BOX_TIMESHEETS_DELETE_REQUEST
>): SagaIterator {
  yield put(actions.BOX_TIMESHEETS_DELETE_MODAL_OPEN());

  const { confirm } = yield race({
    confirm: take(actions.BOX_TIMESHEETS_DELETE_MODAL_SUBMIT),
    close: take(actions.BOX_TIMESHEETS_DELETE_MODAL_CLOSE),
  });

  if (!confirm) {
    return;
  }

  try {
    yield apiCall(Api.Timesheet.delete, { id, ignore_errors: true });

    yield put(BOX_TIMESHEET_DELETE({ id }));
    yield put(actions.BOX_TIMESHEETS_DELETE_MODAL_CLOSE());
  } catch (error) {
    yield put(actions.BOX_TIMESHEETS_DELETE_FAILURE(formatError(error)));
  }
}

export const approve = function* ({
  payload: id,
}: SagaAction<string>): SagaIterator {
  try {
    const response = yield call(
      processApiRequestWithConfirm,
      Api.Timesheet.approve,
      { id, is_approved: false }
    );

    if (!response) {
      yield put(actions.BOX_TIMESHEETS_APPROVE_CANCEL(id));
      return;
    }

    yield put(BOX_TIMESHEET_UPDATE_ONE(response));

    yield put(actions.BOX_TIMESHEETS_APPROVE_SUCCESS(id));
  } catch (error) {
    yield put(
      actions.BOX_TIMESHEETS_APPROVE_FAILURE({
        id,
        errors: formatError(error),
      })
    );
  }
};

const triggerManagerDashboardChangeTimesheet = function* ({
  payload,
}: SagaAction<DashboardShift | Timesheet>): SagaIterator {
  yield put(BOX_TIMESHEET_UPDATE_ONE(payload as Timesheet));
};

const triggerManagerDashboardDeleteTimesheet = function* ({
  payload,
}: SagaAction<DeleteResponse>): SagaIterator {
  yield put(BOX_TIMESHEET_DELETE(payload));
};

export const loadRosteredShifts = function* ({
  payload,
}: SagaAction<string>): SagaIterator {
  try {
    const canViewRosteredShifts: boolean = yield select(
      hasPermissionSelector,
      'roster.rosteredshift.view'
    );

    const siteId = yield select(getSiteId);
    const fetchedFrom: ReturnType<typeof getFrom> = yield select(getFrom);
    const { from, to } = getDayRange(fetchedFrom);

    const rosteredShifts: RosteredShiftGetListResponse = canViewRosteredShifts
      ? yield call(processApiRequest, Api.RosteredShift.getList, {
          site_id: siteId,
          from,
          to,
          user_id: payload,
        })
      : {};
    yield put(
      actions.BOX_TIMESHEETS_LOAD_ROSTERED_SHIFTS_SUCCESS(rosteredShifts)
    );
  } catch (error) {
    yield put(
      actions.BOX_TIMESHEETS_LOAD_ROSTERED_SHIFTS_FAILED(formatError(error))
    );
  }
};

export const watchTimesheets = function* (): SagaIterator {
  yield takeLatest(actions.BOX_TIMESHEETS_GET_PAGE_DATA_REQUEST, getPageData);
  yield takeLatest(actions.BOX_TIMESHEETS_GET_SITE_REQUEST, getSite);
  yield takeLatest(actions.BOX_TIMESHEETS_RELOAD_REQUEST, getSite);
  yield takeLatest(actions.BOX_TIMESHEETS_GET_DAY_REQUEST, getDay);
  yield takeLatest(
    actions.BOX_TIMESHEETS_GET_PREV_DAY_REQUEST,
    getClosestDayCreator('prev')
  );
  yield takeLatest(
    actions.BOX_TIMESHEETS_GET_NEXT_DAY_REQUEST,
    getClosestDayCreator('next')
  );
  yield takeEvery(actions.BOX_TIMESHEETS_UPDATE_REQUEST, updateTimesheet);
  yield takeLatest(actions.BOX_TIMESHEETS_CREATE_REQUEST, createTimesheet);
  yield takeLatest(actions.BOX_TIMESHEETS_APPROVE_REQUEST, approve);
  yield takeLatest(actions.BOX_TIMESHEETS_DELETE_REQUEST, deleteTimesheet);
  yield takeLatest(
    actions.BOX_TIMESHEETS_UPDATE_WITH_APPROVAL,
    confirmApprovalUpdateTimesheet
  );

  yield takeLatest(
    BOX_MANAGER_DASHBOARD_TIMESHEET_CHANGED,
    triggerManagerDashboardChangeTimesheet
  );
  yield takeLatest(
    BOX_MANAGER_DASHBOARD_TIMESHEET_DELETED,
    triggerManagerDashboardDeleteTimesheet
  );

  yield takeLatest(
    actions.BOX_TIMESHEETS_LOAD_ROSTERED_SHIFTS_REQUEST,
    loadRosteredShifts
  );
};
