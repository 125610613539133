import { createSelector } from 'reselect';
import _, { filter, map } from 'lodash';
import { SelectPropsOption } from 'elmo-elements/Select';
import { StringMap, Timesheet, UserFields } from 'type';
import { StoreState } from 'state/types';
import {
  currentSiteTimezoneSelector,
  getFrom,
  getSiteId,
  timesheetsArraySelector,
} from 'state/TimesheetsCollection';
import { usersBySiteIdSelector } from 'state/UsersCollection';
import { TimesheetsFilterType } from '../types';
import moment from 'moment';
import { Dictionary } from 'ts-essentials';
import {
  getIsTimesheetApproved,
  getIsTimesheetInProgress,
  getIsTimesheetPending,
} from 'lib/helpers';

const getState = (state: StoreState) => state.Timesheets;
export const getErrors = (state: StoreState) => getState(state).errors;
export const getShifts = (state: StoreState) => getState(state).shifts;
export const getShowNewForm = (state: StoreState) => getState(state).showNewForm;
export const getSelectedFilter = (state: StoreState) =>
  getState(state).selectedFilter;
export const getDeleteModal = (state: StoreState) => getState(state).deleteModal;
export const getConfirmationModal = (state: StoreState) => getState(state).confirmationModal;
export const getApprovalModal = (state: StoreState) => getState(state).approvalModal;
export const getNewShift =  (state: StoreState) => getState(state).newShift;
export const getRosteredShifts = (state: StoreState) => getState(state).newShift.rosteredShifts;
export const getIsLoadingShifts = (state: StoreState) => getState(state).newShift.isLoadingShifts;

export const filteredTimsheetsWithoutDateSelector = createSelector(
  timesheetsArraySelector,
  getSelectedFilter,
  (timesheetsArray, selectedFilter) => {
    const filterPredicates: Dictionary<(timesheet: Timesheet) => boolean, TimesheetsFilterType> = {
      approved: getIsTimesheetApproved,
      pending: getIsTimesheetPending,
      inProgress: getIsTimesheetInProgress,
      all: _.identity
    };

    return timesheetsArray.filter(filterPredicates[selectedFilter]);
  }
);

export const filteredTimsheetsSelector = createSelector(
  filteredTimsheetsWithoutDateSelector,
  getFrom,
  currentSiteTimezoneSelector,
  (timesheetsArray, date, tz) => {
    const from = moment(date).utc(true).tz(tz).startOf('day');
    const to = from.clone().endOf('day');
    return filter(timesheetsArray, ({start, end}: Timesheet) => {
      if (end) {
        return start.isSameOrAfter(from) && start.isBefore(to) ||
          end.isAfter(from) && end.isBefore(to);
      } else {
        return true;
      }
    });
  }
);

export const usersOfSiteSelector = createSelector<
  StoreState,
  string,
  StringMap<UserFields[]>,
  UserFields[]
  >(
  getSiteId,
  usersBySiteIdSelector,
  (siteId, usersBySiteId) =>
    usersBySiteId[siteId] ? usersBySiteId[siteId].filter(user => user.is_active) : []
);

export const usersOfSiteOptionsArraySelector = createSelector<
  StoreState,
  UserFields[],
  SelectPropsOption[]
  >(usersOfSiteSelector, users =>
  map(users, user => ({
    label: user.prefered_or_full_name,
    value: user.id
  }))
);

export const hasActiveUsers = createSelector<
  StoreState,
  UserFields[],
  boolean
  >(usersOfSiteSelector, users => !!users.length);

export const getRosteredShiftsWithoutTimesheets =
  createSelector(getRosteredShifts, (shifts) =>
    Object.values(shifts).filter((shift) => shift.timesheet_id === null))
