import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { cloneDeep } from 'lodash';
import { Loader, NativeTable, Pagination } from 'elmo-elements';
import { FieldObj, Omit } from 'type';
import { privateRoutes } from 'routes';
import { Api, ReportsGenerateFileRequest } from 'lib/Api';
import { StoreState } from 'state/types';
import {
  BOX_REPORT_CLEAR_ERRORS,
  BOX_REPORT_GENERATE_REPORT_REQUEST,
  BOX_REPORT_SCHEDULE_MODAL_OPEN,
  BOX_REPORT_SET_CURRENT_PAGE,
  BOX_REPORT_SET_DEFAULT_STATE,
  BOX_REPORT_SET_PAGE_SIZE,
  BOX_REPORT_UPDATE_REPORT_REQUEST,
} from 'state/Report';
import {
  BOX_REPORTS_ADD_REPORT_IN_DELETE_QUEUE,
  BOX_REPORTS_CHANGE_SAVE_AS_TITLE,
  BOX_REPORTS_CREATE_MODAL_OPEN,
  BOX_REPORTS_SAVE_MODAL_OPEN,
} from 'state/Reports';
import { getGroupColumnParams } from 'state/Report/helpers';
import {
  getDateFormat,
  getNumberFormat,
  getUsePaySauceFormat,
  timeFormatSelector,
} from 'state/Account';
import { ErrorBox, Layout } from 'element';
import CustomizeColumnsModal from '../CustomizeColumnsModal';
import ReportHeader from './components/ReportViewHeader';
import ReportTableHeader from './components/ReportTableHeader';
import CommonUngroupedRows from './components/CommonUngroupedRows';
import CommonGroupedRows from './components/CommonGroupedRow';
import SaleGroupedByRows from './components/SaleReportTable';
import CoverageGroupedByRows from './components/CoverageReportTable';
import EventRows from './components/EventsReportTable';
import Schedule from './components/Schedule';
import './ReportView.scss';
import { DispatchProps, Props, State, StateProps } from './type';

export class View extends Component<Props, State> {
  successMsg: string = 'Report successfully saved.';

  constructor(props: Props) {
    super(props);
    this.state = {
      report: props.report,
      generated: props.generated,
      isCustomColumnsOpened: false,
      fields: cloneDeep(props.generated.fields),
      selectedColumns: this.getMarkedColumns(cloneDeep(props.generated.fields)),
      isLoading: props.isFetching,
      isUpdated: props.isUpdated,
      isScheduleOpened: false,
      currentSortField: '',
      isTracked: false,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    const { isEdit, report, isFetching } = this.props;
    const { isTracked } = this.state;
    if (prevProps !== this.props) {
      this.setState({
        ...this.state,
        generated: this.props.generated,
        fields: cloneDeep(this.props.generated.fields),
        report: this.props.report,
        selectedColumns: this.getMarkedColumns(
          cloneDeep(this.props.generated.fields)
        ),
        isLoading: this.props.isFetching,
        isUpdated: this.props.isUpdated,
        isScheduleOpened: this.props.isScheduleOpened,
        isCustomColumnsOpened: false,
      });
    }
  }

  getNativeView = () => {
    const { generated } = this.state;
    const { groups } = generated;
    return (
      <>
        <div className={'report-scroll-container'}>
          <NativeTable theme={'striped'} isFullWidth={true}>
            <ReportTableHeader
              report={this.state.report}
              selectedColumns={this.state.selectedColumns}
              openModal={this.openCustomizeColumnsModal}
            />
            <NativeTable.Body>
              {groups.length
                ? this.getGroupedByView()
                : this.getUngroupedView()}
            </NativeTable.Body>
          </NativeTable>
        </div>
        <Pagination
          pageSize={this.props.pagination.pageSize}
          pageSizeOptions={this.props.pagination.pageSizeOptions}
          currentPage={this.props.pagination.currentPage}
          onPageChange={this.changePage}
          onPageSizeChange={this.props.setPageSize}
          totalResults={this.props.pagination.totalResults}
        />
      </>
    );
  };

  changePage = (page: number, start: number, end: number) => {
    this.props.setCurrentPage({
      page: page,
      startItem: start,
      endItem: end,
    });
  };

  getSaleView = () => {
    return this.getSaleGroupedView();
  };

  getCoverageView = () => {
    return (
      <CoverageGroupedByRows
        changePage={this.changePage}
        changePageSize={this.props.setPageSize}
        report={this.state.report}
        visibleColumns={this.state.selectedColumns}
        openModal={this.openCustomizeColumnsModal}
      />
    );
  };

  getEventsView = () => {
    return (
      <EventRows
        changePage={this.changePage}
        changePageSize={this.props.setPageSize}
        report={this.state.report}
        visibleColumns={this.state.selectedColumns}
        openModal={this.openCustomizeColumnsModal}
      />
    );
  };

  tableView = () => {
    const { generated } = this.state;
    const { type } = this.props.report;
    const { rows } = generated;
    return (
      <>
        {rows.length === 0 ? (
          <div>Report is empty...</div>
        ) : (
          <>
            {type === 'coverage' && this.getCoverageView()}
            {type === 'event' && this.getEventsView()}
            {type === 'sale' && this.getSaleView()}
            {type !== 'coverage' &&
              type !== 'sale' &&
              type !== 'event' &&
              this.getNativeView()}
            {this.state.isCustomColumnsOpened && (
              <CustomizeColumnsModal
                isModalOpened={this.state.isCustomColumnsOpened || false}
                fields={this.state.fields}
                restoreFields={this.restoreFields}
                toggleCheckbox={this.toggleCheckbox}
                toggleAll={this.toggleAll}
                applyColumns={this.applyColumnsVisibility}
              />
            )}
          </>
        )}
      </>
    );
  };

  restoreFields = () => {
    this.setState({
      fields: cloneDeep(this.props.generated.fields),
      isCustomColumnsOpened: false,
    });
  };

  componentWillUnmount(): void {
    this.props.setReportDefaultState();
  }

  clearErrors = () => {
    this.props.clearErrors();
    this.props.history.push(privateRoutes.reports.path);
  };

  render() {
    return (
      <>
        {this.props.errors && this.props.errors.length ? (
          <Layout.Content>
            <ErrorBox
              errors={this.props.errors}
              clearErrors={this.clearErrors}
            />
          </Layout.Content>
        ) : (
          <>
            <Layout.Header>
              <ReportHeader
                title={this.props.title}
                openModal={this.openSaveAsModal}
                updateReport={this.updateReport}
                moveBack={this.moveBack}
                openEditModal={this.openEditModal}
                isEdit={this.props.isEdit}
                disableSaveButton={this.props.isEdit && !this.state.isUpdated}
                openScheduleModal={this.openScheduleModal}
                deleteReport={this.deleteReport}
                exportFile={this.exportFile}
                changeTitle={this.changeTitle}
                showPaySauceExport={
                  this.props.report.type === 'timesheet' &&
                  this.props.usePaySauceFormat
                }
              />
            </Layout.Header>
            <Layout.Content>
              {!this.state.isLoading ? (
                this.tableView()
              ) : (
                <Loader type={'spinner'} isLoading={this.state.isLoading} />
              )}
              {this.state.isScheduleOpened && <Schedule />}
            </Layout.Content>
          </>
        )}
      </>
    );
  }

  openScheduleModal = () => {
    this.props.openScheduleModal(this.state.report);
  };

  deleteReport = () => {
    const { id, type } = this.state.report;
    this.props.deleteReport(id);
    this.props.history.push(privateRoutes.reports.path);
  };

  exportFile = (params: Omit<ReportsGenerateFileRequest, 'reportId'>) => {
    const { generated_id } = this.state.generated;
    const reportId = generated_id ? generated_id : this.props.report.id;
    window.location.href = Api.Reports.generateFileDownloadUrl({
      reportId,
      ...params,
    });
  };

  openSaveAsModal = () => {
    this.props.openSaveAs(this.state.report);
  };

  moveBack = () => {
    this.props.history.push(privateRoutes.reports.path);
  };

  changeTitle = (title: string) => {
    this.props.changeModalTitle(title);
  };

  updateReport = () => {
    this.props.updateReport(this.state.report);
  };

  openEditModal = () => {
    this.props.openCreateModal(this.state.report);
  };

  toggleCheckbox = (index: number) => {
    const { fields } = this.state;
    if (!fields[index].disabled) {
      fields[index].isChecked = !fields[index].isChecked;
      this.setState({
        ...this.state,
        fields: fields,
      });
    }
  };

  toggleAll = (isSelect: boolean) => {
    const { fields } = this.state;
    const options = fields.map((option: any) => {
      if (!option.disabled) {
        option.isChecked = isSelect;
      }
      return option;
    });

    this.setState({
      ...this.state,
      fields: options,
    });
  };

  applyColumnsVisibility = () => {
    const params = this.state.report;
    params.fields = this.state.fields;
    this.props.generate(params);
    this.setState({
      isLoading: true,
      isCustomColumnsOpened: false,
      isUpdated: true,
    });
  };

  openCustomizeColumnsModal = () => {
    this.setState({
      isCustomColumnsOpened: true,
    });
  };

  private getUngroupedView = () => {
    return (
      <CommonUngroupedRows
        visibleColumns={this.state.selectedColumns}
        showTotalRow={this.props.report.type !== 'roster'}
      />
    );
  };

  private getGroupedByView = () => {
    return (
      <CommonGroupedRows
        visibleColumns={this.state.selectedColumns}
        showTotalRow={true}
      />
    );
  };

  private getSaleGroupedView = () => {
    return (
      <SaleGroupedByRows
        visibleColumns={this.state.selectedColumns}
        changePage={this.changePage}
        changePageSize={this.props.setPageSize}
        report={this.state.report}
        openModal={this.openCustomizeColumnsModal}
      />
    );
  };

  private getMarkedColumns = (fields: FieldObj[] = []) => {
    const columns: any[] = [];
    const { type } = this.props.report;

    const { extra } = this.props.report;

    if (this.props.generated.groups.length && extra && extra.group_by) {
      columns.push(getGroupColumnParams(type, extra.group_by, fields));
      for (let field of fields) {
        if (field.isChecked && field.name !== columns[0].name) {
          columns.push({
            title: field.label,
            name: field.name,
            is_money: field.is_money,
          });
        }
      }
    } else {
      for (let field of fields) {
        if (field.isChecked) {
          columns.push({
            title: field.label,
            name: field.name,
            is_money: field.is_money,
          });
        }
      }
    }

    return columns;
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  report: state.report.report,
  generated: state.report.generatedReport,
  saveAsIsOpened: state.reports.saveAsModal.isOpened,
  isFetching: state.report.isFetching,
  isUpdated: state.report.isUpdated,
  dateFormat: getDateFormat(state),
  timeFormat: timeFormatSelector(state),
  numberFormat: getNumberFormat(state),
  isScheduleOpened: state.report.scheduleModal.isOpened,
  isSavedSuccess: state.report.isSavedSuccess,
  isSavedAsSuccess: state.report.isSavedAsSuccess,
  pagination: state.report.pagination,
  errors: state.report.errors,
  usePaySauceFormat: getUsePaySauceFormat(state)
});

const mapDispatchToProps: DispatchProps = {
  generate: BOX_REPORT_GENERATE_REPORT_REQUEST,
  openSaveAs: BOX_REPORTS_SAVE_MODAL_OPEN,
  openCreateModal: BOX_REPORTS_CREATE_MODAL_OPEN,
  updateReport: BOX_REPORT_UPDATE_REPORT_REQUEST,
  openScheduleModal: BOX_REPORT_SCHEDULE_MODAL_OPEN,
  deleteReport: BOX_REPORTS_ADD_REPORT_IN_DELETE_QUEUE,
  setReportDefaultState: BOX_REPORT_SET_DEFAULT_STATE,
  changeModalTitle: BOX_REPORTS_CHANGE_SAVE_AS_TITLE,
  setPageSize: BOX_REPORT_SET_PAGE_SIZE,
  setCurrentPage: BOX_REPORT_SET_CURRENT_PAGE,
  clearErrors: BOX_REPORT_CLEAR_ERRORS
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(View)
);
