import { createSelector } from 'reselect';
import {
  BackendPager,
  EmployeeRosteredShift,
  MyRostersWithPagerSelector,
} from 'type';
import { EmployeeDashboardGetMyRostersRequest } from 'lib/Api';
import { SERVER_DAY_FORMAT } from 'lib/config';
import { StoreState } from 'state/types';
import { getCurrentTime } from 'state/CurrentTime';
import { currentUserMainTimezoneSelector } from 'state/Auth';
import { CreateTradePayload, MyRostersReducerState } from './types';

const getState = (state: StoreState): MyRostersReducerState =>
  state.employeeDashboard.myRosters;

export const getSelectedPeriod = (state: StoreState) => getState(state).period;
const getTimesheetInProgress = (state: StoreState) =>
  getState(state).timesheetInProgress;
export const getMyRostersSelector = (state: StoreState) =>
  getState(state).workingShifts;
export const getFiltersForm = (state: StoreState) =>
  getState(state).filtersForm;
export const getFilters = (state: StoreState) => getState(state).filters;
export const getFiltersFrom = (state: StoreState) => getFilters(state).from;
export const getFiltersTo = (state: StoreState) => getFilters(state).to;
export const getFiltersSort = (state: StoreState) =>
  getState(state).filters.sort;
export const getIsCustomFilter = (state: StoreState) =>
  getState(state).isCustomFilter;
export const getModals = (state: StoreState) => getState(state).modals;
export const getForceModalIsOpen = (state: StoreState) =>
  getState(state).forceModal.isOpen;
export const getForceModalMessage = (state: StoreState) =>
  getState(state).forceModal.message;

export const getWhoElseWorking = (state: StoreState) =>
  getState(state).whoElseWorking;
export const getWhoElseWorkingIsLoading = (state: StoreState) =>
  getState(state).whoElseWorkingIsLoading;
export const getWhoElseWorkingErrors = (state: StoreState) =>
  getState(state).whoElseWorkingErrors;

export const getHasShift = (
  state: StoreState,
  { shiftId }: { shiftId: string }
) => !!getMyRostersSelector(state).find(({ id }) => id === shiftId);
export const getHasTimesheetInProgress = (state: StoreState) =>
  !!getTimesheetInProgress(state);

export const foreceCreatePayloadSelector = (
  state: StoreState
): CreateTradePayload => {
  const { shiftId, note, type } = state.employeeDashboard.myRosters.modals;

  return {
    note,
    type,
    shift_id: shiftId,
  };
};

export const getPager = (state: StoreState): BackendPager =>
  state.employeeDashboard.myRosters.pager;

export const myRostersWithPagerSelector = createSelector(
  getMyRostersSelector,
  getPager,
  (
    page: EmployeeRosteredShift[],
    pager: BackendPager
  ): MyRostersWithPagerSelector => ({
    page,
    ...pager,
  })
);

export const isFiltersFormDisabledSelector = createSelector(
  getFiltersForm,
  ({ statuses }) => !!statuses && !statuses.length
);

export const payloadSelector = createSelector(
  getPager,
  getFiltersSort,
  getSelectedPeriod,
  getFilters,
  (
    { currentPage, pageSize },
    { column, direction },
    period,
    { statuses, location, from, to }
  ): EmployeeDashboardGetMyRostersRequest => ({
    page: currentPage,
    per_page: pageSize,
    order_by: column,
    order_dir: direction,
    statuses,
    location,
    period: from
      ? {
          period: 'custom',
          from: from.format(SERVER_DAY_FORMAT),
          to: to?.format(SERVER_DAY_FORMAT),
        }
      : {
          period,
        },
  })
);

export const currentTimeInMainTimezoneSelector = createSelector(
  getCurrentTime,
  currentUserMainTimezoneSelector,
  (currentTime, currentUserMainTimezone) =>
    currentTime.clone().tz(currentUserMainTimezone)
);
