import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Moment } from 'moment';
import { Timesheet } from 'type';
import { StoreState } from 'state/types';
import { currentTimeBySiteTimezoneSelector } from 'state/Roster/Roster/selectors';
import { isTimesheetsEditableSelector } from 'state/Roster/RosterDayView';
import { GetTimesheetByIDPayload } from 'state/TimesheetModal/types';
import { RosterCellType } from '../../../../components';
import {
  ResizableWrapper,
  ResizableWrapperOnResizeEndPayload,
  ShiftBreak,
} from '../';
import { UserTimesheetDayCard } from '../../../../components/shiftCards/UserTimesheetDayCard';

type OwnProps = {
  timesheet: Timesheet;
  day: Moment;
  onResizeEnd: (payload: ResizableWrapperOnResizeEndPayload) => void;
  type: RosterCellType;
  onClick: (payload: GetTimesheetByIDPayload) => void;
  isEditable: boolean;
};

type StateProps = {
  currentTime: Moment;
  isTimesheetsEditable: boolean;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

class DayUserTimesheetComponent extends Component<Props> {
  render() {
    const {
      timesheet,
      onResizeEnd,
      currentTime,
      type,
      isEditable,
      day,
      isTimesheetsEditable,
    } = this.props;
    const endTime = timesheet.end ? timesheet.end : currentTime;
    const isFinished = !!timesheet.end;

    return (
      <>
        <ResizableWrapper
          id={timesheet.id}
          start={timesheet.start}
          end={endTime}
          day={day}
          onResizeEnd={onResizeEnd}
          isResizable={isTimesheetsEditable && isEditable && isFinished}
        >
          <UserTimesheetDayCard timesheetId={timesheet.id} />
        </ResizableWrapper>

        {timesheet.breaks.map(
          ({ start: breakStart, duration, active, paid }) => (
            <ShiftBreak
              key={timesheet.id + breakStart.format()}
              start={breakStart}
              duration={
                active ? currentTime.diff(breakStart, 'minutes') : duration
              }
              paid={paid}
              shiftStart={timesheet.start}
            />
          )
        )}
      </>
    );
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  currentTime: currentTimeBySiteTimezoneSelector(state),
  isTimesheetsEditable: isTimesheetsEditableSelector(state),
});

// const mapDispatchToProps: DispatchProps = {};

export const DayUserTimesheet = connect(mapStateToProps)(
  DayUserTimesheetComponent
);
