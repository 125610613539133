export const PopoverEvents: string[] = ['mousedown', 'touchstart', 'keydown'];

export const POPOVER_CONTAINER_ELEMENT_ID = 'PopoverContainer';

export const POPPER_MODIFIERS: any = {
  preventOverflow: {
    /**
     * The amount of space between the boundary and the popup. Used so that it doesn't stick
     * right to the edge of the window and cause a horizontal scroll
     */
    padding: 30,
  },
};
