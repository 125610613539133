import React from 'react';
import LoaderHolder from './LoaderHolder';
import { LoaderHolderProps } from '../type';
import './ShortTextStyle.scss';

function ShortTextStyle(props: LoaderHolderProps) {
  return (
    <LoaderHolder
      className="elmo-loader-text"
      viewBoxHeight={35}
      viewBoxWidth={300}
      {...props}
    >
      <rect x="0" y="10" rx="4" ry="4" width="150" height="28" />
      <rect x="170" y="10" rx="4" ry="4" width="80" height="28" />
    </LoaderHolder>
  );
}

export default ShortTextStyle;
