import { Moment } from 'moment';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { processApiRequest } from 'state/ProcessApiRequest';
import Api from 'lib/Api';
import { ApiReturnType, Parameter, TimesheetsNewStatus } from 'type';
import { SERVER_DAY_FORMAT } from 'lib/config';

const getApiStatus = (status: TimesheetsNewStatus) => {
  if (status === 'all') {
    return;
  }

  return status;
}

export const fetchTimesheetsWeekSummary = function* (payload: {
  site_id: string;
  from: Moment;
  status: TimesheetsNewStatus;
}): SagaIterator<ApiReturnType<typeof Api.Roster.timesheetWeekSummary>> {
  const apiPayload: Parameter<typeof Api.Roster.timesheetWeekSummary> = {
    site_id: payload.site_id,
    date: payload.from.format(SERVER_DAY_FORMAT),
    status: getApiStatus(payload.status)
  };

  return yield call(processApiRequest, Api.Roster.timesheetWeekSummary, apiPayload);
};
