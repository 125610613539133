import React, { Component, createRef } from 'react';
import { NativeTableElement } from '../type';
import { getClass, isScreenDesktop } from '../../_lib/helper';
import './NativeTableWrapper.scss';

type NativeTableWrapperProps = NativeTableElement & {
  isResponsive?: boolean;
  isFullWidth?: boolean;
  isHeaderSticky?: boolean;
  isFirstColumnSticky?: boolean;
  isTableFixed?: boolean;
};

class NativeTableWrapper extends Component<NativeTableWrapperProps> {
  private wrapperRef = createRef<HTMLDivElement>();
  protected layoutHeader: any;
  protected headerObserver: any;

  handleResize = () => {
    if (
      this.wrapperRef &&
      this.wrapperRef.current &&
      this.layoutHeader &&
      isScreenDesktop() === false
    ) {
      const headerHeight = this.layoutHeader.offsetHeight;
      const headColumns: any = this.wrapperRef.current.querySelectorAll(
        '.elmo-nativetable-head th, .elmo-nativetable-head td'
      );
      headColumns.forEach((column: any) => {
        column.style.top = headerHeight + 'px';
      });
    }
  };

  componentDidMount() {
    const { isHeaderSticky } = this.props;
    if (isHeaderSticky) {
      this.layoutHeader = document.querySelector('.elmo-layout__main-header');
      if (this.layoutHeader) {
        this.headerObserver = new ResizeObserver((entries) => {
          this.handleResize();
        });
        this.headerObserver.observe(this.layoutHeader);
      }
    }
  }

  componentWillUnmount() {
    const { isHeaderSticky } = this.props;
    if (isHeaderSticky && this.headerObserver) {
      this.headerObserver.disconnect();
    }
  }

  render() {
    const {
      id,
      theme,
      children,
      className,
      isResponsive,
      isFullWidth,
      isHeaderSticky,
      isFirstColumnSticky,
      isTableFixed,
    } = this.props;
    // TODO: change this theme to a modifer
    const themeClassName = theme ? theme : 'default';
    const classNames = getClass(
      'elmo-nativetable',
      [className, themeClassName],
      {
        'full-width': isFullWidth,
        fixed: isTableFixed,
      }
    );
    const wrapperClassNames = getClass(
      'elmo-nativetable-wrapper',
      {
        'elmo-nativetable-responsive': isResponsive,
      },
      {
        'is-header-sticky': isHeaderSticky,
        'is-first-column-sticky': isFirstColumnSticky,
      }
    );

    return (
      <div className={wrapperClassNames} ref={this.wrapperRef}>
        <table
          id={id}
          data-testid={`elmo-nativetable-${id || 'default'}`}
          className={classNames}
        >
          {children}
        </table>
      </div>
    );
  }
}

export default NativeTableWrapper;
