import React from 'react';
import clsx from 'clsx';
import {
  Grow,
  Paper,
  PaperProps,
  Popper,
  PopperProps,
  Theme,
} from 'extended-oxygen-elements';
import {
  createStyles,
  withStyles,
  WithStyles,
} from '@mui/styles';

type OwnProps = Omit<PopperProps, 'transition'> & {
  PaperProps?: Partial<PaperProps>;
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.modal,
    },
    paper: {},
  });

type StyleProps = WithStyles<typeof styles>;

type Props = OwnProps & StyleProps;

export const PopperBox = withStyles(styles)(
  React.forwardRef(function PopperBox(
    { children, classes, PaperProps = {}, ...restProps }: Props,
    ref: React.Ref<HTMLDivElement>
  ) {
    return (
      <Popper
        {...restProps}
        className={clsx(classes.root, restProps.className)}
        transition
        ref={ref}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper
              {...PaperProps}
              className={clsx(classes.paper, PaperProps.className)}
            >
              {children}
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  })
);

export type PopperBoxProps = React.ComponentProps<typeof PopperBox>;

export default PopperBox;
