import { Theme, useMediaQuery, useTheme } from 'oxygen-elements';
import React from 'react';
import { AtLeastOne, WithChildren } from 'type';

export type HiddenProps = WithChildren<
  AtLeastOne<{
    /** internal prop for implementation Visible component. Use Visible with 'up' prop */
    downBefore: BreakpointWithoutFirst;
    up: BreakpointWithoutFirst;
  }>
>;

export type HiddenPropsBreakpoint = BreakpointWithoutFirst;

type Breakpoint = keyof Theme['breakpoints']['values'];
type BreakpointWithoutFirst = Exclude<Breakpoint, 'xs'>;

export default function Hidden({ children, downBefore, up }: HiddenProps) {
  const theme = useTheme();

  let mediaQuery = theme.breakpoints.down('xs');
  if (downBefore) {
    mediaQuery = theme.breakpoints.down(downBefore);
  } else if (up) {
    mediaQuery = theme.breakpoints.up(up);
  }

  const hidden = useMediaQuery(mediaQuery);

  if (hidden) {
    return null;
  }

  return <>{children}</>;
}
