import { getSelectedAreasNumber } from './getSelectedAreasNumber';
import { PublishArea } from '../types';

export const showSelectedNumber = (areas: PublishArea) => {
  let totalAreasNumber = Object.keys(areas).length;
  const number = getSelectedAreasNumber(areas);
  if (totalAreasNumber === 1) {
    return false;
  }
  return number !== totalAreasNumber && number !== 0;
};
