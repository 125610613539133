import React, { Component } from 'react';
import { Text } from 'elmo-elements';
import { TimeInput } from 'element';
import { Moment } from 'moment';
import { getDateTimeFormatted, getTimeFormatted } from 'lib/helpers';
import { StoreState } from 'state/types';
import { getDateFormat, getTimeFormat } from 'state/Account';
import { connect } from 'react-redux';
import { PreferencesDateFormat, PreferencesTimeFormat } from 'type/models';

type Props = {
  label: string,
  time: Moment | null,
  rosterTime?: Moment,
  timeFormat: PreferencesTimeFormat,
  dateFormat: PreferencesDateFormat,
  onChange: (time: Moment) => void,
  isEditable: boolean,
  isEditing: boolean,
  onClick?: () => void,
  displayDate?: boolean;
  isEmpty?: boolean;
};

class ShiftTime extends Component<Props> {
  render() {
    const {label, rosterTime, isEditing, displayDate, time, timeFormat, dateFormat, isEditable, onClick} = this.props;
    return <div
      className={!isEditing ? 'isEditable' : ''}
      onClick={() => {
        if (isEditable) {
          if (typeof onClick === 'function') {
            onClick();
          }
        }
      }}
    >
      <Text
        size={'xs'}
        color={'gray'}
      >
        {label} {displayDate && <span data-testid={`${label.toLowerCase()}-date`}>
        ({ time ? getDateTimeFormatted(dateFormat, timeFormat, time as Moment) : '-'})
        </span>}
      </Text>
      <div>
        {isEditing ? this.renderTimeEditing() : <>
          {this.props.isEditing ? this.renderTimeEditing() : this.renderTime()}
        </>}
      </div>
      {rosterTime && this.renderRosterTime()}
    </div>;
  }

  renderTimeEditing() {
    const { onChange, time, label, isEmpty } = this.props;
    return <TimeInput
      id={`timesheet-${label.toLocaleLowerCase()}`}
      onChange={onChange}
      name={`start`}
      value={isEmpty ? null : time}
      label="Break time"
      initialDayValue={time ? time : undefined}
    />
  }

  renderTime() {
    const {time, timeFormat, label} = this.props;
    return <strong data-testid={`shift-time-${label.toLocaleLowerCase()}`}>
      {time ? getTimeFormatted(timeFormat, time as Moment) : '-'}
    </strong>
  }

  renderRosterTime = () => {
    const {rosterTime, timeFormat} = this.props;
    return <Text
      size={'xs'}
      color={'gray'}
    >
      R {getTimeFormatted(timeFormat, rosterTime as Moment)}
    </Text>;
  }
}

const mapStateToProps = (state: StoreState) => ({
  timeFormat: getTimeFormat(state),
  dateFormat: getDateFormat(state),
});

export default connect(mapStateToProps)(ShiftTime);
