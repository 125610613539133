import React from 'react';
import classNames from 'clsx';
import { Tooltip } from 'elmo-elements';
import './DayNavigationBadge.scss';

type Props = {
  label: string;
  leftPosition: number;
  color: 'primary' | 'gray-500';
  tooltip?: string;
};

export const DayNavigationBadge = ({
  label,
  leftPosition,
  color,
  tooltip
}: Props) => {
  const renderedLabel = <span>{label}</span>;

  return (
    <div
      className={classNames(
        'day-navigation-badge',
        `day-navigation-badge--${color}`
      )}
      style={{
        left: leftPosition
      }}
    >
      {tooltip ? (
        <Tooltip title={tooltip}>{renderedLabel}</Tooltip>
      ) : (
        renderedLabel
      )}

      <span
        className={classNames(
          'day-navigation-badge__divider',
          `day-navigation-badge__divider--${color}`
        )}
      />
    </div>
  );
};
