import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import {
  getAreaId,
  getDayTimestamp,
  getRoleId,
  getRowIndex,
  getType,
  getUserId
} from 'state/Roster/RangeInput/ShiftTimeRangeInput';
import { Props, StateProps } from './types';
import { SiteShiftTimeRangeInput, UserShiftTimeRangeInput } from './components';

export const RosterShiftTimeRangeInputComponent = (props: Props) => {
  const {
    user_id,
    role_id,
    area_id,
    dayTimestamp,
    type,
    rowIndex,
    inputUserId,
    inputAreaId,
    inputRoleId,
    inputDayTimestamp,
    inputType,
    inputRowIndex
  } = props;

  const matchType = type === inputType;
  const matchRowIndex = rowIndex === inputRowIndex;
  const matchTimestamp = dayTimestamp === inputDayTimestamp;

  if (!matchType || !matchRowIndex || !matchTimestamp) {
    return null;
  }

  if (area_id && role_id) {
    if (area_id === inputAreaId && role_id === inputRoleId) {
      return <SiteShiftTimeRangeInput area_id={area_id} role_id={role_id}/>;
    }
    return null;
  }

  if (typeof user_id !== 'undefined' && inputUserId === user_id) {
    return <UserShiftTimeRangeInput user_id={user_id}/>;
  }

  return null;
};

const mapStateToProps = (state: StoreState): StateProps => ({
  inputUserId: getUserId(state),
  inputAreaId: getAreaId(state),
  inputRoleId: getRoleId(state),
  inputDayTimestamp: getDayTimestamp(state),
  inputType: getType(state),
  inputRowIndex: getRowIndex(state)
});

// const mapDispatchToProps: DispatchProps = {};

export const RosterShiftTimeRangeInput = connect(mapStateToProps)(
  RosterShiftTimeRangeInputComponent
);
