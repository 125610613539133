import React from 'react';
import ContextualMenu from '../../ContextualMenu';
import { useSelector } from 'react-redux';
import { getIsCovidModeEnabled } from '../../../../../state/Roster/CovidTracing/selectors';

type Props = {
  rosteredShiftId: string;
  elementId: string;
};

export const RosterContextualMenu = (props: Props) => {
  const isCovidMode = useSelector(getIsCovidModeEnabled);
  return (
    !isCovidMode
      ? <ContextualMenu
          elementId={props.elementId}
          objectId={props.rosteredShiftId}
          type="edit"
          shiftType="rostered_shift"
          currentCellData={null}
        />
      : null
  );
};
