import React from 'react';
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from 'extended-oxygen-elements';

type Props = {
  buttons: {
    label: string;
    onClick: () => void;
    icon: React.ReactNode;
  }[];
  handleButtonClick: () => void;
  menuState: any;
};
export const AllReportsListDropDown = ({
  buttons,
  handleButtonClick,
  menuState,
}: Props) => {
  return (
    <Menu {...menuState}>
      {buttons.map((option: any, index: number) => (
        <li key={`option-item-${option.label}`}>
          <MenuItem
            component="div"
            onClick={(e: any) => {
              option.onClick();
              handleButtonClick();
            }}
            disabled={option.isDisabled}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            <ListItemText>{option.label}</ListItemText>
          </MenuItem>
        </li>
      ))}
    </Menu>
  );
};
