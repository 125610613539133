export type RadioButtonsDirection = 'vertical' | 'horizontal';
export const RadioButtonsDirections: {
  [key: string]: RadioButtonsDirection;
} = {
  vertical: 'vertical',
  horizontal: 'horizontal',
};

export type RadioButtonsType = 'solid' | 'outlined' | 'grouped';
export const RadioButtonsTypes: { [key: string]: RadioButtonsType } = {
  solid: 'solid',
  outlined: 'outlined',
  grouped: 'grouped',
};

export type RadioButtonsProps = {
  /** The selected value */
  selected: any;
  /** RadioButtons is disabled  */
  disabled?: boolean;
  /** RadioButtons onChange event handler */
  onChange?: (value: string | number) => void;
  /**
   * RadioButtons direction, use one from the following: RadioBarDirection = 'vertical' | 'horizontal'.
   * Defaults to horizontal.
   */
  direction?: RadioButtonsDirection;
  /** ariaLabelled attribute */
  ariaLabelledBy?: string;
  /** id attribute */
  id?: string;
  /** class name attribute */
  className?: string;
  /** Style of RadioButtons, use one from the following: 'solid' | 'outlined' */
  type: RadioButtonsType;
};
