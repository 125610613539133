import { createReducer } from 'lib/store-utils';
import { getDefaultState } from './state';
import * as actions from './actions';
import { without, reject } from 'lodash';
import { ShiftOffersUndoReducerState } from './types';

export const shiftOffersUndo = createReducer<ShiftOffersUndoReducerState>(
  {},
  getDefaultState()
);

shiftOffersUndo.on(
  actions.BOX_SHIFT_OFFERS_UNDO_ADD_TO_DELETE_QUEUE,
  (state, { shiftOfferId }): ShiftOffersUndoReducerState => ({
    ...state,
    deleteQueue: [...state.deleteQueue, shiftOfferId]
  })
);

shiftOffersUndo.on(
  actions.BOX_SHIFT_OFFERS_UNDO_REMOVE_FROM_DELETE_QUEUE,
  (state, { shiftOfferId }): ShiftOffersUndoReducerState => ({
    ...state,
    deleteQueue: without(state.deleteQueue, shiftOfferId)
  })
);

shiftOffersUndo.on(
  actions.BOX_SHIFT_OFFERS_UNDO_ADD_TO_DECLINE_PROPOSAL_QUEUE,
  (state, idsOfProposal): ShiftOffersUndoReducerState => ({
    ...state,
    declineProposalQueue: [...state.declineProposalQueue, idsOfProposal]
  })
);

shiftOffersUndo.on(
  actions.BOX_SHIFT_OFFERS_UNDO_REMOVE_FROM_DECLINE_PROPOSAL_QUEUE,
  (state, idsOfProposal): ShiftOffersUndoReducerState => ({
    ...state,
    declineProposalQueue: reject(state.declineProposalQueue, idsOfProposal)
  })
);

shiftOffersUndo.on(
  actions.BOX_SHIFT_OFFERS_UNDO_ADD_TO_APPROVE_PROPOSAL_QUEUE,
  (state, { shiftOfferId }): ShiftOffersUndoReducerState => ({
    ...state,
    approveProposalQueue: [...state.approveProposalQueue, shiftOfferId]
  })
);

shiftOffersUndo.on(
  actions.BOX_SHIFT_OFFERS_UNDO_REMOVE_FROM_APPROVE_PROPOSAL_QUEUE,
  (state, { shiftOfferId }): ShiftOffersUndoReducerState => ({
    ...state,
    approveProposalQueue: without(state.approveProposalQueue, shiftOfferId)
  })
);
