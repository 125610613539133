import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Header, DeepThemeProvider } from '@breatheHR/breathe-design-system';
import './header-menu.scss';
import {
  getHrNavigationMenuData,
  getHrHeaderSearchData,
} from '../../../../lib/Api/directApi';
import _ from 'lodash';
import axios from 'axios';
import useClickOutside from '../../../../hooks/useClickOutside';

export default function HeaderMenu() {
  const [searchText, setSearchText] = useState('');
  const [headerData, setHeaderData] = useState<any>();
  const [headerSearchData, setHeaderSearchData] = useState<any>([]);
  const [loadingHeaderData, setLoadingHeaderData] = useState(true);
  const [loadingSearchData, setLoadingSearchData] = useState(true);
  const fetchSearchCancelToken = useRef<any>();
  const [setRef] = useClickOutside(null, () => {
    setHeaderSearchData([]);
  });

  useEffect(() => {
    const resultWrapper = document.querySelector('[data-radix-portal]');
    if (resultWrapper) {
      setRef(resultWrapper);
    }
  }, [setRef]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    const fetchHeaderData = async () => {
      setLoadingHeaderData(true);
      try {
        const res = await getHrNavigationMenuData(cancelToken);
        if (res) {
          setHeaderData(res);
        }
      } catch (err: any) {
        throw new Error(err.message);
      }
      setLoadingHeaderData(false);
    };
    fetchHeaderData();
    return () => {
      cancelToken.cancel('Request cancelled');
    };
  }, []);

  const fetchHeaderSearchData = async (searchTextString: any) => {
    setLoadingSearchData(true);
    if (fetchSearchCancelToken.current !== undefined) {
      fetchSearchCancelToken.current.cancel('Request cancelled');
      fetchSearchCancelToken.current = undefined;
    }
    fetchSearchCancelToken.current = axios.CancelToken.source();
    if (searchTextString.length > 0) {
      try {
        const res = await getHrHeaderSearchData(
          fetchSearchCancelToken,
          searchTextString
        );
        if (res && res.length > 0) {
          setHeaderSearchData(res);
        } else {
          setHeaderSearchData([{ name: 'No matches found', link: '#' }]);
        }
      } catch (err: any) {
        throw new Error(err.message);
      }
      setLoadingSearchData(false);
    } else {
      setHeaderSearchData([]);
    }
  };

  const debounceRequest = useCallback(
    _.debounce((searchTextString) => {
      fetchHeaderSearchData(searchTextString);
    }, 500),
    []
  );

  // handle search input on change
  const searchOnChange = (e: any) => {
    setSearchText(e.target.value);
    debounceRequest(e.target.value);
  };

  let amendedIconButtonData: any = [];

  if (headerData?.header_menu?.icon_buttons) {
    amendedIconButtonData = headerData?.header_menu?.icon_buttons.filter(
      (item: any) => item.key !== 'star'
    );
  }

  return (
    <DeepThemeProvider mode="">
      <div className="header-container">
        <Header
          buttonMenuData={headerData?.header_menu?.buttons}
          iconButtonMenuData={amendedIconButtonData}
          peopleSearchEnabled={headerData?.people_search_enabled}
          avatar={{
            avatarImageUrl: headerData?.avatar,
            avatarName: '',
            avatarInitials: '',
            avatarMenuData: headerData?.header_menu?.avatar_menu || [],
          }}
          searchbar={{
            searchText: searchText,
            searchOnChange: searchOnChange,
            searchResultsOpen: headerSearchData.length > 0,
            searchResultsData: headerSearchData,
          }}
        />
      </div>
    </DeepThemeProvider>
  );
}
