import React, { Component } from 'react';
import { addFutureClassName, getTotalsPerDay } from '../../../../helpers';
import { Text } from 'elmo-elements';
import { getCurrencyFormatted, getPreferenceLabel } from 'lib/helpers';
import { compact } from 'lodash';
import { RosterCell } from '../../../../components/RosterCell';
import {
  Event,
  LanguagePreferences,
  PreferencesCurrencyCode,
  PreferencesCurrencyPlacement,
  PreferencesNumberFormat,
} from 'type/models';
import { StringMap } from 'type';
import moment, { Moment } from 'moment';

type Props = {
  data: {
    rosters: any[][];
    rostersRowNumber: number;
  };
  weekDays: any;
  events: StringMap<Event>;
  langPreferences: LanguagePreferences;
  numberFormat: PreferencesNumberFormat;
  currencyCode: PreferencesCurrencyCode;
  currencyPlacement: PreferencesCurrencyPlacement;
  getData: (event: StringMap<Event>, id: string) => any | null;
  showEventCard: (rowNum: number, dayNum: number) => React.ReactNode;
  currentTimeBySiteTimezone: Moment;
};

export class EventsRow extends Component<Props> {
  render() {
    const { rosters } = this.props.data;
    return (
      <React.Fragment>
        {this.showRowsPlaceholder()}
        {rosters && this.showTotalsPerDay()}
      </React.Fragment>
    );
  }

  cellClassName = (dayIndex: number) => {
    const { weekDays, currentTimeBySiteTimezone } = this.props;
    return addFutureClassName(
      currentTimeBySiteTimezone,
      moment(weekDays[dayIndex], 'x')
    );
  };

  showTotalsPerDay = () => {
    const { data, events, numberFormat, currencyCode, currencyPlacement } =
      this.props;
    const totals = getTotalsPerDay(data.rosters, events);
    const getTotal = (value: number) => {
      return getCurrencyFormatted(
        numberFormat,
        currencyCode,
        currencyPlacement,
        value,
        true
      );
    };
    return (
      <tr className="total-info-wrapper">
        {totals.map(
          (day: { value: number; hasEvent: boolean }, dayIndex: number) => (
            <td
              className={
                'week-rosters-grid__group-day-block total-info' +
                this.cellClassName(dayIndex)
              }
              key={dayIndex}
            >
              {day.hasEvent ? (
                <Text size={'xs'} isBold={true} className={'total-info__label'}>
                  <strong>Total</strong> <span>{getTotal(day.value)}</span>
                </Text>
              ) : (
                <Text size={'xs'} isBold={true}>
                  -
                </Text>
              )}
            </td>
          )
        )}
      </tr>
    );
  };

  showRowsPlaceholder = () => {
    const {
      data: { rosters },
    } = this.props;
    return (
      rosters &&
      rosters.map((row: any, rowIndex: number) => {
        return (
          !!compact(row).length && (
            <tr key={'e' + rowIndex}>
              {row.map((cell: any, dayIndex: number) => (
                <React.Fragment key={dayIndex}>
                  {cell.hasOwnProperty('id')
                    ? this.getCardCell(cell.duration, rowIndex, dayIndex)
                    : this.getEmptyCell(rowIndex, dayIndex)}
                </React.Fragment>
              ))}
            </tr>
          )
        );
      })
    );
  };

  getDayCell = (rowIndex: number, dayIndex: number) => {
    const width = 100 / 7;
    return (
      <td
        className="week-rosters-grid__group-day-block"
        style={{ minHeight: '56px' }}
        width={`${width}%`}
      >
        {this.getCellContent(rowIndex, dayIndex)}
      </td>
    );
  };

  getEmptyCell = (rowIndex: number, dayIndex: number) => {
    const {
      data: { rosters },
    } = this.props;
    const dayCell = rosters[rowIndex][dayIndex];
    return !dayCell.hasOwnProperty('isFilledBy')
      ? this.getDayCell(rowIndex, dayIndex)
      : null;
  };

  getCardCell = (duration: number, rowIndex: number, dayIndex: number) => {
    return (
      <td
        className="week-rosters-grid__group-day-block"
        style={{
          minWidth: '5px',
          maxWidth: '130px',
          width: '0',
          margin: '0',
        }}
        colSpan={duration ? duration : undefined}
        key={dayIndex}
      >
        {this.getCellContent(rowIndex, dayIndex)}
      </td>
    );
  };

  getCellContent = (rNum: number, dNum: number) => {
    const {
      data: { rosters },
      events,
      langPreferences,
    } = this.props;
    const current = rosters[rNum][dNum];
    const roster =
      current && current.id
        ? this.props.getData(events, rosters[rNum][dNum].id)
        : null;
    const label = getPreferenceLabel(
      langPreferences,
      'event',
      'singular',
      'Event',
      true
    );
    const cellParams = !roster
      ? {
          label: `+ Add ${label}`,
          fontWeight: 'normal',
          labelOnHover: true,
        }
      : undefined;
    return (
      <RosterCell type={'top'} {...cellParams}>
        <div
          key={dNum}
          style={{ height: '100%', minHeight: this.getMinHeight() }}
        >
          {this.props.showEventCard(rNum, dNum)}
        </div>
      </RosterCell>
    );
  };

  getMinHeight = () => {
    return '56px';
  };
}
