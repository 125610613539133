import { Layout } from 'element';
import { useSelectorWithProps } from 'hooks';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { privateRoutes } from 'routes';
import { hasPermissionSelector } from 'state/Auth/selectors';
import {
  TimesheetsErrorBox,
  TimesheetsFilters,
  TimesheetsHeader,
  TimesheetsList,
  TimesheetsSockets,
} from './components';
import FetchPageData from './FetchPageData';
import QueryProvider from './QueryProvider';

export default TimesheetsDaily;

export function TimesheetsDaily() {
  const hasPermissionToViewTimesheet = useSelectorWithProps(
    hasPermissionSelector,
    'roster.timesheet.view'
  );

  if (!hasPermissionToViewTimesheet) {
    return <Redirect to={privateRoutes.managerDashboard.path} />;
  }

  return (
    <QueryProvider>
      <FetchPageData>
        <Layout.Header>
          <TimesheetsHeader />
        </Layout.Header>
        <Layout.Content>
          <TimesheetsSockets />
          <TimesheetsErrorBox />
          <TimesheetsFilters />
          <TimesheetsList />
        </Layout.Content>
      </FetchPageData>
    </QueryProvider>
  );
}
