import React, { Component, MouseEvent, ReactNode } from 'react';
import classNames from 'clsx';
import { Tooltip } from 'elmo-elements';
import { convertHex } from 'lib/helpers';
import '../../styles.scss';
import { Event, EventSettings, ShiftBreak } from 'type/models';
import ContextualMenu from 'page/Roster/components/ContextualMenu';
import { compareProps } from '../../../../helpers';
import { View } from './view';

type Props = {
  shape?: 'full' | 'top' | 'bottom';
  fullHeight?: boolean;
  colour?: string;
  title: string;
  icons?: ReactNode;
  from: string;
  to: string;
  messages?: number;
  className?: string;
  withoutShadow?: boolean;
  coloredBg?: boolean;
  secondaryColor?: string;
  event?: Event;
  onDoubleClick?: (event: MouseEvent<HTMLDivElement>) => void;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  settings?: EventSettings;
  tooltip?: string;
  deleteOneShift?: () => void;
  canEditShift?: boolean;
  isSelected?: boolean;
  isHighlighted?: boolean;
  onDragStart?: () => void;
  draggable?: boolean;
  id: string;
  isApprovalMode: boolean;
  noContextualMenu?: boolean;
  isBulkDelete: boolean;
  spotTitle?: string;
  colorId: number | null;
  isTemplate?: boolean;
  breaks?: ShiftBreak[];
  onClickAddButton?: () => void;
  isCovidMode?: boolean;
};

export class ShiftCard extends Component<Props> {
  shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<{}>,
    nextContext: any
  ): boolean {
    return compareProps(nextProps, this.props, []);
  }

  render() {
    const { tooltip } = this.props;
    return tooltip ? (
      <Tooltip title={tooltip!} placement={'bottom'}>
        {this.view()}
      </Tooltip>
    ) : (
      this.view()
    );
  }

  view = () => {
    const {
      shape = 'full',
      fullHeight = false,
      title,
      icons,
      from,
      to,
      messages,
      className,
      colour,
      withoutShadow,
      event,
      settings,
      canEditShift,
      isSelected,
      isHighlighted,
      id,
      isApprovalMode,
      spotTitle,
      breaks,
      isCovidMode,
    } = this.props;
    const eventName = event ? event.name : '';

    return (
      <div
        id={`card-${id}`}
        className={classNames(
          'shift-card',
          `shift-card--${shape}`,
          'rostered-shift-card',
          className,
          this.cardColor,
          {
            'shift-card--colored': colour,
            'shift-card--without-shadow': withoutShadow,
            'shift-card--full-height': fullHeight,
            'shift-card--selected': isSelected,
            'shift-card--highlighted': isHighlighted,
          }
        )}
        data-shift-id={id}
        onClick={this.onClick}
      >
        {!this.props.noContextualMenu && !isCovidMode && (
          <ContextualMenu
            elementId={`card-${id}`}
            type={'edit'}
            shiftType={
              this.props.isTemplate === true ? 'shift_item' : 'rostered_shift'
            }
            objectId={id}
            currentCellData={null}
          />
        )}
        <View
          eventName={eventName}
          settings={settings}
          isApprovalMode={isApprovalMode}
          title={title}
          to={to}
          from={from}
          canEditShift={canEditShift}
          messages={messages}
          spotTitle={spotTitle}
          icons={icons}
          breaks={breaks}
        />
      </div>
    );
  };

  onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const { onClick, isApprovalMode } = this.props;
    const { target } = e;
    if (
      !(target as any).closest('.delete-shift-icon') &&
      !(target as any).classList.contains('oxygen-dropdown-base__menu-item') &&
      onClick &&
      !isApprovalMode
    ) {
      onClick(e);
    }
  };

  get bgColor() {
    const { coloredBg } = this.props;
    return coloredBg ? this.locationBgColor : 'transparent';
  }

  get locationBgColor() {
    const { colour, isBulkDelete } = this.props;
    if (colour) {
      return isBulkDelete ? convertHex(`#${colour}`, 60) : `#${colour}`;
    }
    return '';
  }

  get color() {
    return 'rgba(0,0,0,0.87)';
  }

  get cardColor() {
    const { coloredBg, colorId } = this.props;
    let cardClassNames = [`role-color-${colorId}-primary`];
    if (coloredBg) {
      cardClassNames.push('not-published');
    }
    return cardClassNames.concat(' ');
  }
}
