import React from 'react';
import _ from 'lodash';
import { Typography, TypographyProps } from 'extended-oxygen-elements';

export function TimesheetCardText(actualProps: TypographyProps) {
  const props = _.defaults({}, actualProps, {
    variant: 'body2',
    color: 'textSecondary',
    align: 'center'
  });

  return (
    <Typography {...props} />
  );
}

