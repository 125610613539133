import { createSelector } from 'reselect';
import { map } from 'lodash';
import {
  AccountTreeArea,
  AccountTreeSelector,
  AccountTreeSite,
  StringMap
} from 'type';
import { StoreState } from 'state/types';
import {
  getAccountTree,
  areasBySiteIdSelector
} from 'state/AccountTree';
import {
  Option,
  OptionWithoutCheck,
  RoleTriplesSelector,
  SelectedRoleTriples,
  SiteAreaRoleModalReducerState
} from './types';
import {
  createAreaOptionsCreator,
  getCheckedState,
  hasRoleCreator
} from './helpers';
import { flattenAreaIdsRoleIdsCombiner } from '../combiners';
import { archivedOrWithDataFilter } from '../helpers';

const getState = (state: StoreState): SiteAreaRoleModalReducerState =>
  state.siteAreaRoleModal;

export const getIsOpened = (state: StoreState) => getState(state).isOpened;
export const getIsResetButtonEnabled = (state: StoreState) =>
  getState(state).isResetButtonEnabled;
export const getSelectedRoleTriples = (state: StoreState) =>
  getState(state).selectedRoleTriples;
export const getSiteId = (state: StoreState) => getState(state).siteId;
export const getArchivedAreaIdsToBeShown = (state: StoreState) =>
  getState(state).archivedAreaIdsToBeShown;
export const getArchivedRoleIdsToBeShown = (state: StoreState) =>
  getState(state).archivedRoleIdsToBeShown;

export const roleTriplesSelector = createSelector(
  getAccountTree,
  areasBySiteIdSelector,
  getSelectedRoleTriples,
  getSiteId,
  getArchivedAreaIdsToBeShown,
  getArchivedRoleIdsToBeShown,
  (
    accountTree: AccountTreeSelector,
    areasBySiteId: StringMap<AccountTreeArea[]>,
    roleTriples: SelectedRoleTriples,
    selectedSiteId: string | null,
    archivedAreaIdsToBeShown,
    archivedRoleIdsToBeShown
  ): RoleTriplesSelector => {
    const hasRole = hasRoleCreator(roleTriples);

    const flattenSelectedAreaIdsRoleIds = flattenAreaIdsRoleIdsCombiner(
      roleTriples
    );

    if (selectedSiteId) {
      const site: AccountTreeSite = accountTree.sites[selectedSiteId];
      const siteAreas = areasBySiteId[selectedSiteId] || [];

      const filteredSiteAreas = archivedOrWithDataFilter(
        siteAreas,
        archivedAreaIdsToBeShown,
        flattenSelectedAreaIdsRoleIds.areas || []
      );

      const createAreaOptions = createAreaOptionsCreator(
        accountTree.roles,
        hasRole,
        archivedRoleIdsToBeShown,
        flattenSelectedAreaIdsRoleIds.roles || []
      );

      return createAreaOptions(filteredSiteAreas, site);
    }

    return map(
      areasBySiteId,
      (siteAreas: AccountTreeArea[], siteId: string): Option => {
        const site: AccountTreeSite = accountTree.sites[siteId];

        const createAreaOptions = createAreaOptionsCreator(
          accountTree.roles,
          hasRole,
          archivedRoleIdsToBeShown,
          flattenSelectedAreaIdsRoleIds.roles || []
        );

        const filteredSiteAreas = archivedOrWithDataFilter(
          siteAreas,
          archivedAreaIdsToBeShown,
          flattenSelectedAreaIdsRoleIds.areas || []
        );

        const siteOption: OptionWithoutCheck = {
          id: site.id,
          label: site.name,
          options: createAreaOptions(filteredSiteAreas, site)
        };

        return {
          ...siteOption,
          ...getCheckedState({
            options: siteOption.options,
            allItemsQuantity: siteAreas.length
          })
        };
      }
    );
  }
);
