import moment, { Moment } from 'moment';
import { DateType } from 'type';
import { DATE_PICKER_FIELD_FORMAT } from 'lib/config';

export const dateFieldValueHelper = {
  parse: (value: DateType): Moment => moment(value, DATE_PICKER_FIELD_FORMAT),
  stringify: (value: Moment): DateType =>
    value.format(DATE_PICKER_FIELD_FORMAT),
};

export default dateFieldValueHelper;
