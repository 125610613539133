import React from 'react';
import { UserFields } from 'type';
import { SimpleBadge } from '../SimpleBadge';

export const StatusBadge = ({ user }: { user: UserFields }) => {
  if (user.is_active) {
    return <SimpleBadge label={'Current'} type={'info'} />;
  } else {
    return <SimpleBadge label={'Terminated'} type={'grey'} />;
  }
};
