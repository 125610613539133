import { Button, ButtonProps } from 'extended-oxygen-elements';
import React from 'react';
import { useSelector } from 'react-redux';
import { getDialogUpdating } from 'state/AccountTree';

export const PageDialogCancel = ((props: ButtonProps) => {
  const loading = useSelector(getDialogUpdating);
  return (
    <Button
      size="medium"
      variant="outlined"
      fullWidth
      disabled={loading || props.disabled}
      {...props}
    />
  );
}) as typeof Button;
