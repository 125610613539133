import React from 'react';
import { connect } from 'react-redux';
import {
  appendRowForSiteView,
  compareProps,
  getDurationsInfoForTemplate,
} from '../../../../helpers';
import { RosterFilters } from 'state/Roster/RosterFilters';
import RosterRowGroup from '../../../../components/ShiftCard/components/RosterRowGroup';
import { StoreState } from '../../../../../../state/types';
import {
  getTemplateShiftsArray,
  groupShiftItemsByAreas,
} from '../../../../../../state/Roster/EditTemplate/selectors';
import { userListSelector } from '../../../../../../state/UsersCollection';
import {
  AccountTreeRole,
  AccountTreeArea,
  StringMap,
  UserFields,
  ShiftTemplateItem,
} from '../../../../../../type';
import { getRoles, getAreas } from '../../../../../../state/AccountTree';
import { ShiftTemplateRow } from '../../components/ShiftTemplateRow';
import {
  getSelectedByAreaRole,
  getIsBulkDeleteOpened,
} from '../../../../../../state/Roster/BulkActions/selectors';
import { BulkSelectionPropsItem } from '../../../../../../state/Roster/BulkActions/types';
import RosterSiteViewHeading from '../../../../components/RosterSiteViewHeading';
import WithArrowsNav from '../../../RosterWeekView/components/WithArrowsNav';
import { BOX_SHIFT_TIME_RANGE_INPUT_CLOSED } from '../../../../../../state/Roster/RangeInput/ShiftTimeRangeInput';

type StateProps = {
  shifts: ShiftTemplateItem[];
  shiftsRows: any;
  users: StringMap<UserFields>;
  roles: StringMap<AccountTreeRole>;
  areas: StringMap<AccountTreeArea>;
  isBulkDelete: boolean;
  selectedByAreaRole: BulkSelectionPropsItem;
};

type DispatchProps = {
  closeInput: () => void;
};

type Props = StateProps & DispatchProps;

type State = {
  rostersWeek: any;
};

export class EditView extends React.Component<Props, State> {
  title: string = '';
  renderIndex: number = 0;

  state = {
    rostersWeek: [],
  };

  private initWeekView() {
    const { shiftsRows } = this.props;
    this.setState({
      rostersWeek: shiftsRows,
    });
  }

  public componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    const { shiftsRows, users } = this.props;
    if (prevProps.shiftsRows !== shiftsRows || prevProps.users !== users) {
      this.initWeekView();
    }
  }

  public componentDidMount(): void {
    this.initWeekView();
  }

  public appendEmptyRow(areaId: string | null, roleId: string | null) {
    this.setState((prevState) => ({
      ...prevState,
      rostersWeek: appendRowForSiteView(prevState.rostersWeek, areaId, roleId),
    }));
  }

  areaHeading = (data: any, index: number) => {
    const { areas } = this.props;
    let show = false;
    if (index === 0 && areas[data.area_id]) {
      this.title = areas[data.area_id].name;
      show = true;
    } else {
      if (areas[data.area_id] && this.title !== areas[data.area_id].name) {
        this.title = areas[data.area_id].name;
        show = true;
      }
    }
    return <RosterSiteViewHeading title={this.title} show={show} />;
  };

  showContent = () => {
    const { rostersWeek } = this.state;
    const { areas, shifts, roles, isBulkDelete, selectedByAreaRole } =
      this.props;
    return (
      <div style={{ marginTop: '10px' }}>
        {rostersWeek.map((areaData: any, index: number) => (
          <React.Fragment key={index}>
            {this.areaHeading(areaData, index)}
            <RosterRowGroup
              type={'area'}
              data={{
                user: null,
                area: {
                  area_id: areaData.area_id,
                  role_id: areaData.role_id,
                  name: areas[areaData.area_id].name,
                  role: roles[areaData.role_id].name,
                  isDisabled:
                    roles[areaData.role_id].archived ||
                    areas[areaData.area_id].archived,
                },
              }}
              actions={
                isBulkDelete
                  ? () => false
                  : () =>
                      this.appendEmptyRow(areaData.area_id, areaData.role_id)
              }
              key={`row-${index}`}
              legendType={areaData.rowsNumber > 1 ? 'multi-row' : 'single-row'}
              duration={getDurationsInfoForTemplate(areaData.rosters, shifts)}
              selectedFilter={{ roster: true } as RosterFilters}
              bulkSelect={'area'}
              isSelected={
                selectedByAreaRole[`${areaData.area_id}_${areaData.role_id}`]
              }
              rowIndex={index}
              pageType={'week'}
            >
              {this.showCells(areaData)}
            </RosterRowGroup>
          </React.Fragment>
        ))}
      </div>
    );
  };

  public render() {
    return (
      <WithArrowsNav
        renderIndex={this.renderIndex++}
        closeInput={this.props.closeInput}
      >
        {this.showContent()}
      </WithArrowsNav>
    );
  }

  private showAreaCells(
    rosterData: any,
    areaId: string,
    roleId: string,
    rowIndex: number
  ) {
    return (
      <ShiftTemplateRow
        rowIndex={rowIndex}
        data={rosterData}
        area_id={areaId}
        role_id={roleId}
        user_id={null}
        viewType={'site'}
        onClickRoster={() => false}
        onClickTimesheet={() => false}
      />
    );
  }

  private showCells = (area: any) => {
    const { roles, areas } = this.props;
    const getRowState = (data: any) => {
      return roles[data.role_id].archived || areas[data.area_id].archived
        ? 'is-disabled'
        : '';
    };
    return (
      <>
        {area.rosters.map((data: any, index: number) => (
          <div
            key={index}
            className={`week-rosters-grid__blocks ${getRowState(area)}`}
          >
            {this.showAreaCells(data, area.area_id, area.role_id, index)}
          </div>
        ))}
      </>
    );
  };

  shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>,
    nextContext: any
  ): boolean {
    return (
      compareProps(nextState, this.state, []) ||
      compareProps(nextProps, this.props, ['weekDays', 'shifts'])
    );
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  shifts: getTemplateShiftsArray(state),
  shiftsRows: groupShiftItemsByAreas(state),
  users: userListSelector(state),
  roles: getRoles(state),
  areas: getAreas(state),
  isBulkDelete: getIsBulkDeleteOpened(state),
  selectedByAreaRole: getSelectedByAreaRole(state),
});

const mapDispatchToProps: DispatchProps = {
  closeInput: BOX_SHIFT_TIME_RANGE_INPUT_CLOSED,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditView);
