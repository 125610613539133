import { put, select } from 'redux-saga/effects';
import { MIN_UNASSIGNED_ROWS_QUANTITY } from '../constants';
import {
  getUnassignedAdditionalRows,
  unassignedRosterDataSelector
} from '../selectors';
import { BOX_ROSTER_DAY_VIEW_UNASSIGNED_ROW_SET_QUANTITY } from '../actions';
import { calculateAdditionalRowsQuantity } from './helpers';

export const unassignedAddRow = function*() {
  const unassignedRows: ReturnType<
    typeof unassignedRosterDataSelector
  > = yield select(unassignedRosterDataSelector);

  const additionalRowsQuantity: ReturnType<
    typeof getUnassignedAdditionalRows
  > = yield select(getUnassignedAdditionalRows);

  const additionalRows = calculateAdditionalRowsQuantity({
    currentRowsQuantity: unassignedRows.rows.length,
    additionalRowsQuantity,
    minRowsQuantity: MIN_UNASSIGNED_ROWS_QUANTITY
  });

  yield put(
    BOX_ROSTER_DAY_VIEW_UNASSIGNED_ROW_SET_QUANTITY({
      additionalRows
    })
  );
};
