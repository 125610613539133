import { ReactNode } from 'react';
import { ButtonOnClick } from '../Button';

export type NoteType =
  | 'dark'
  | 'info'
  | 'success'
  | 'warning'
  | 'danger'
  | undefined;

export const NoteTypes: { [key: string]: NoteType } = {
  info: 'info',
  warning: 'warning',
  success: 'success',
  danger: 'danger',
  dark: 'dark',
};

export type NotificationProps = {
  /** Show | Hide notification */
  isActive?: boolean;
  /** Notification type */
  type?: NoteType;
  /** Notification content */
  message?: ReactNode | string;
  /** On close callback */
  onClose?: ButtonOnClick | undefined;
  /** On Undo callback, if added, the undo button will appear */
  onUndo?: ButtonOnClick | undefined;
  /** custom text for the Undo button (optional) */
  undoBtnText?: string;
  /** icon */
  icon?: ReactNode;
  /** on mouser over callback */
  onmouserover?: Function;
  /** on mouser out callback */
  onmouseout?: Function;
  /** id attribute */
  id?: string;
};
