import React from 'react';
import {
  Tooltip,
  Theme,
  SvgIconProps,
} from 'extended-oxygen-elements';
import {
  createStyles,
  withStyles,
  WithStyles,
} from '@mui/styles';
import { DefaultDivProps } from 'type';
import clsx from 'clsx';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-block',
      fontSize: 16,
    },
  });

type TooltipProps = Pick<React.ComponentProps<typeof Tooltip>, 'placement'>;
type StyleProps = WithStyles<typeof styles>;
type ShiftCardIconProps = StyleProps &
  TooltipProps &
  DefaultDivProps & {
    title: string;
    color: string;
    icon: React.ReactElement<SvgIconProps>;
  };

export const ShiftCardIcon = withStyles(styles)(function ShiftCardIcon({
  icon,
  color,
  title,
  placement = 'bottom',
  classes,
  className,
  ...restProps
}: ShiftCardIconProps) {
  return (
    <div
      {...restProps}
      className={clsx(classes.root, className)}
      data-icon-text={title}
      style={{ color }}
    >
      <Tooltip title={title} placement={placement}>
        {React.cloneElement(icon, {
          fontSize: 'inherit',
        })}
      </Tooltip>
    </div>
  );
});
