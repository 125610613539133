import React from 'react';
import { Layout } from 'element';
import {
  ShiftOfferErrorBox,
  ShiftOfferHeader,
  ShiftOfferInfo,
  ShiftOfferProposals,
} from './components';
import './view.scss';

const ShiftOfferView = () => (
  <>
    <Layout.Header>
      <ShiftOfferHeader />
    </Layout.Header>

    <Layout.Content>
      <ShiftOfferErrorBox />
      <ShiftOfferInfo className="shift-offer__info" />

      <ShiftOfferProposals />
    </Layout.Content>
  </>
);

export default ShiftOfferView;
