export const calculateAdditionalRowsQuantity = ({
  currentRowsQuantity,
  additionalRowsQuantity,
  minRowsQuantity
}: {
  currentRowsQuantity: number;
  additionalRowsQuantity: number;
  minRowsQuantity: number;
}): number => {
  if (currentRowsQuantity < minRowsQuantity) {
    const actualRowsMinRowsDiff = minRowsQuantity - currentRowsQuantity;

    return actualRowsMinRowsDiff + 1;
  }

  return additionalRowsQuantity + 1;
};
