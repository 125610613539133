import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import {
  BOX_EMPLOYEE_CREATE_UNAVAILABILITY_FAILURE,
  BOX_EMPLOYEE_CREATE_UNAVAILABILITY_SUCCESS,
  BOX_EMPLOYEE_DELETE_UNAVAILABILITY_FAILURE,
  BOX_EMPLOYEE_DELETE_UNAVAILABILITY_SUCCESS,
  BOX_EMPLOYEE_GET_UNAVAILABILITY_BY_ID_FAILURE,
  BOX_EMPLOYEE_GET_UNAVAILABILITY_BY_ID_SUCCESS,
  BOX_EMPLOYEE_GET_UNAVAILABILITY_FAILURE,
  BOX_EMPLOYEE_GET_UNAVAILABILITY_SUCCESS,
  BOX_EMPLOYEE_SET_SPREAD_OF_HOURS,
  BOX_EMPLOYEE_UNAVAILABILITY_MODAL_CLOSE,
  BOX_EMPLOYEE_UPDATE_UNAVAILABILITY_FAILURE,
  BOX_EMPLOYEE_UPDATE_UNAVAILABILITY_SUCCESS,
} from './actions';
import * as fetchPageData from '../../FetchPageData';
import Api from 'lib/Api';
import { SagaAction } from 'type';
import { formatError } from '../../helpers';
import { processApiRequest } from 'state/ProcessApiRequest/sagas';
import {
  EmployeeSpreadOfHoursResponse,
  EmployeeUnavailabilityProps,
  EmployeeUnavailabilityRequestPayload,
  EmployeeUnavailabilityResponse,
} from './types';
import { getCalendarDateByMainTimezone } from './selectors';
import moment from 'moment';

const getSpreadOfHours = function* (): SagaIterator {
  try {
    const date = yield select(getCalendarDateByMainTimezone);
    const payload = {
      start_date: moment(date).startOf('month').format('Y-MM-DD'),
      end_date: moment(date).endOf('month').format('Y-MM-DD'),
    };
    const response: EmployeeSpreadOfHoursResponse[] = yield call(
      processApiRequest,
      Api.EmployeeDashboard.getSpreadOfHours,
      payload
    );
    yield put(BOX_EMPLOYEE_SET_SPREAD_OF_HOURS(response));
  } catch (errors) {
    yield put(BOX_EMPLOYEE_SET_SPREAD_OF_HOURS([]));
  }
};

export const getUnavailability = function*(): SagaIterator {
  try {
    const date = yield select(getCalendarDateByMainTimezone);
    const response: EmployeeUnavailabilityResponse = yield call(
      processApiRequest, Api.EmployeeDashboard.getUnavailabilityList, date.format('Y-MM-DD')
    );
    yield call(getSpreadOfHours);
    yield put(BOX_EMPLOYEE_GET_UNAVAILABILITY_SUCCESS(response));
  } catch (errors) {
    yield put(BOX_EMPLOYEE_GET_UNAVAILABILITY_FAILURE(formatError(errors)));
  }
};

export const getData = function*() {
  try {
    yield put(fetchPageData.BOX_FETCH_PAGE_DATA_REQUEST(fetchPageData.EMPLOYEE_DASHBOARD_UNAVAILABILITY));
    yield call(getUnavailability);
    yield put(fetchPageData.BOX_FETCH_PAGE_DATA_SUCCESS(fetchPageData.EMPLOYEE_DASHBOARD_UNAVAILABILITY));
  } catch (error) {
    yield put(fetchPageData.BOX_FETCH_PAGE_DATA_FAILURE(fetchPageData.EMPLOYEE_DASHBOARD_UNAVAILABILITY));
  }
};

export const getUnavailabilityById = function*({
  payload
}: SagaAction<string>): SagaIterator {
  try {
    const response: EmployeeUnavailabilityProps = yield call(
      processApiRequest, Api.EmployeeDashboard.getUnavailabilityById, payload
    );
    yield put(BOX_EMPLOYEE_GET_UNAVAILABILITY_BY_ID_SUCCESS(response));
  } catch (errors) {
    yield put(BOX_EMPLOYEE_GET_UNAVAILABILITY_BY_ID_FAILURE(formatError(errors)));
  }
};

export const createUnavailability = function*({
  payload
}: SagaAction<EmployeeUnavailabilityRequestPayload>): SagaIterator {
  try {
    const result: EmployeeUnavailabilityProps = yield call(
      processApiRequest, Api.EmployeeDashboard.createUnavailability, payload
    );
    yield put(BOX_EMPLOYEE_UNAVAILABILITY_MODAL_CLOSE());
    yield put(BOX_EMPLOYEE_CREATE_UNAVAILABILITY_SUCCESS(result));
  } catch (errors) {
    yield put(BOX_EMPLOYEE_CREATE_UNAVAILABILITY_FAILURE(formatError(errors)));
  }
};

export const updateUnavailability = function*({
  payload
}: SagaAction<EmployeeUnavailabilityRequestPayload>): SagaIterator {
  try {
    const result: EmployeeUnavailabilityProps = yield call(
      processApiRequest, Api.EmployeeDashboard.updateUnavailability, payload
    );
    yield put(BOX_EMPLOYEE_UNAVAILABILITY_MODAL_CLOSE());
    yield put(BOX_EMPLOYEE_UPDATE_UNAVAILABILITY_SUCCESS(result));
  } catch (errors) {
    yield put(BOX_EMPLOYEE_UPDATE_UNAVAILABILITY_FAILURE(formatError(errors)));
  }
};

export const deleteUnavailability = function*({
  payload
}: SagaAction<string>): SagaIterator {
  try {
    const result: EmployeeUnavailabilityProps = yield call(
      processApiRequest, Api.EmployeeDashboard.deleteUnavailability, payload
    );
    yield put(BOX_EMPLOYEE_UNAVAILABILITY_MODAL_CLOSE());
    yield put(BOX_EMPLOYEE_DELETE_UNAVAILABILITY_SUCCESS(payload));
  } catch (errors) {
    yield put(BOX_EMPLOYEE_DELETE_UNAVAILABILITY_FAILURE(formatError(errors)));
  }
};

export const watchEmployeeUnavailability = function*(): SagaIterator {
  yield takeLatest(actions.BOX_EMPLOYEE_GET_UNAVAILABILITY, getData);
  yield takeLatest(actions.BOX_EMPLOYEE_UNAVAILABILITY_GET_DATA_ON_DATE_CHANGED, getUnavailability);
  yield takeLatest(actions.BOX_EMPLOYEE_GET_UNAVAILABILITY_BY_ID, getUnavailabilityById);
  yield takeLatest(actions.BOX_EMPLOYEE_CREATE_UNAVAILABILITY, createUnavailability);
  yield takeLatest(actions.BOX_EMPLOYEE_UPDATE_UNAVAILABILITY, updateUnavailability);
  yield takeLatest(actions.BOX_EMPLOYEE_DELETE_UNAVAILABILITY, deleteUnavailability);
};
