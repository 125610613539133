import { updateTimesheet } from 'lib/Api/helpers';
import { RosterWeekChannel } from 'lib/socket-channels';
import { WS } from 'lib/SocketConnection';
import moment from 'moment';
import { eventChannel } from 'redux-saga';
import { select } from 'redux-saga/effects';
import { getCurrentUserId } from 'state/Auth';
import {
  BOX_TIMESHEET_DELETE,
  BOX_TIMESHEET_UPDATE_ONE,
  getFrom,
  getTo,
} from 'state/TimesheetsCollection';
import { DateType, ISO8601DateTime, Timesheet } from 'type';

type Props = {
  site_id: string;
  day: DateType;
};

export function* makeTimesheetsDailyChannel(ws: WS, props: Props) {
  const currentUserId: ReturnType<typeof getCurrentUserId> = yield select(
    getCurrentUserId
  );
  const from: ReturnType<typeof getFrom> = yield select(getFrom);
  const to: ReturnType<typeof getTo> = yield select(getTo);

  const isBetween = (date: ISO8601DateTime) =>
    moment.parseZone(date).isBetween(from, to, 'minutes', '[]');

  const isInRange = (timesheet: {
    start: ISO8601DateTime;
    end: ISO8601DateTime | null;
  }) =>
    isBetween(timesheet.start) || (timesheet.end && isBetween(timesheet.end));

  return eventChannel((emit) => {
    const rosterWeekChannel = new RosterWeekChannel(ws, props);

    rosterWeekChannel
      .onTimesheetChanged((timesheet) => {
        if (timesheet.updated_by === currentUserId) {
          return;
        }

        if (!isInRange(timesheet)) {
          return;
        }

        emit(
          BOX_TIMESHEET_UPDATE_ONE(
            updateTimesheet(timesheet) as unknown as Timesheet
          )
        );
      })
      .onTimesheetDeleted((payload) => {
        emit(BOX_TIMESHEET_DELETE(payload));
      });

    return () => {
      rosterWeekChannel.leave();
    };
  });
}
