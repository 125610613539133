import { createAction } from 'lib/store-utils';
import { AuditTrail, Pager } from 'type';
import { AuditFilters } from './state';

export const BOX_AUDIT_TRAIL_REQUEST = createAction('Get audit trail request');
export const BOX_AUDIT_TRAIL_SUCCESS = createAction<AuditTrail>('Get audit trail success');
export const BOX_AUDIT_TRAIL_FAILURE = createAction<string[]>('Get audit trail failed');

export const BOX_AUDIT_TRAIL_FILTERS_MODAL_OPEN = createAction('Audit trail / Open filters modal');
export const BOX_AUDIT_TRAIL_FILTERS_MODAL_CLOSE = createAction('Audit trail / Close filters modal');

export const BOX_AUDIT_TRAIL_FILTERS_UPDATE = createAction<AuditFilters>('Update audit filters');
export const BOX_AUDIT_TRAIL_PAGE_UPDATE = createAction<Pager>('Update audit page');
export const BOX_AUDIT_TRAIL_CLEAR_ERRORS = createAction('Audit trail /  Clear errors');
