import React from 'react';
import _, { each } from 'lodash';
import {
  AccountTreeArea,
  AccountTreeRole,
  Event,
  RosteredShift,
  ServerUserFields,
  ShiftTemplateItem,
  StringMap,
  Timesheet,
  UserFields,
} from 'type';
import {
  formatMinutesAsHours,
  getOptionsForCurrentUser,
  getShiftDuration,
} from 'lib/helpers';
import {
  RosterTimeOffsByUserIdAndDate,
  RosterWeekViewTimeOff,
} from '../../state/Roster/RosterWeekView/types';
import { Moment } from 'moment-timezone';
import { CalendarTodayIcon, TableChartOutlinedIcon } from '../../element';
import { Duration, InfoType } from './types';
import { RosterFiltersByType } from 'state/Roster/RosterFilters/types';

export type RowCellsProps = {
  rosteredShiftId: null | string;
  timesheetId: null | string;
};

type DataType = {
  rosteredShiftId: string | null;
  timesheetId: null | string;
};

const emptyRow = [{}, {}, {}, {}, {}, {}, {}];

export const isSelectedFilter = (filters: string[], current: string) => {
  return _.indexOf(filters, current) !== -1;
};

export const getUnavailableShifts = (data: RosterTimeOffsByUserIdAndDate) => {
  const ids: string[] = [];
  each(data, (timeOffsByDays: StringMap<RosterWeekViewTimeOff[]>) => {
    each(timeOffsByDays, (timeOffsByDay) => {
      each(timeOffsByDay, (timeOff: RosterWeekViewTimeOff) => {
        if (timeOff.type === 'rostered_shift') {
          ids.push(timeOff.id);
        }
      });
    });
  });
  return ids;
};

export const getShiftInfo = (
  key: 'rosteredShiftId' | 'timesheetId',
  data: DataType[][],
  source: any[],
  timeOffs: string[]
) => {
  let totalTimeHours: number = 0;
  const info = {
    quantity: 0,
    time: '',
    type: '',
  };

  const pushTime = (id: string) => {
    const s: any = source.filter((shift) => shift.id === id);
    if (s.length) {
      const start = s[0].start;
      const end = s[0].end;
      const timezoneId = s[0].timezone_id;
      if (end !== null) {
        const p = getShiftDuration(
          start,
          end,
          s[0].breaks,
          timezoneId ? timezoneId : undefined
        );
        totalTimeHours += p.total_hrs;
      }
    }
  };

  const getTotalTime = () => {
    const totalTimeMinutes: number = totalTimeHours * 60;
    return formatMinutesAsHours(totalTimeMinutes);
  };

  data.forEach((d: DataType[]) => {
    d.forEach((t: DataType) => {
      const id = t[key];
      const s = source.filter((ss) => ss.id === id);
      if (id && s[0] && _.indexOf(timeOffs, id) === -1) {
        info.quantity += 1;
        pushTime(id);
      }
    });
  });

  info.time = getTotalTime();
  info.type = key === 'rosteredShiftId' ? 'roster' : 'timesheet';
  return info;
};

export const getShiftTemplateInfo = (
  key: 'rosteredShiftId',
  data: DataType[][],
  source: any[]
) => {
  let totalTimeHours: number = 0;
  const info = {
    quantity: 0,
    type: 'roster',
    time: '',
  };

  const pushTime = (id: string) => {
    const s: any = source.filter((shift) => shift.id === id);
    if (s.length) {
      const p = s[0].duration / 60 / 60;
      totalTimeHours += p;
    }
  };

  const getTotalTime = () => {
    const totalTimeMinutes: number = totalTimeHours * 60;
    return formatMinutesAsHours(totalTimeMinutes);
  };

  data.forEach((d: DataType[]) => {
    d.forEach((t: DataType) => {
      const id = t[key];
      const s = source.filter((ss) => ss.id === id);
      if (id && s[0]) {
        info.quantity += 1;
        pushTime(id);
      }
    });
  });

  info.time = getTotalTime();
  return info;
};

export const compareProps = (
  nextProps: any,
  props: any,
  nonUpdatable: string[]
) => {
  const keys = Object.keys(nextProps);
  for (let i = 0; i < keys.length; i += 1) {
    const nextKey = (nextProps as any)[keys[i]];
    const currentKey = (props as any)[keys[i]];
    const fixedKeys = [
      'groupedRosters',
      'groupedEvents',
      'rostersWeek',
      'eventsWeek',
    ];

    if (
      typeof nextKey === 'string' ||
      typeof nextKey === 'number' ||
      typeof nextKey === 'boolean'
    ) {
      if (nextKey !== currentKey && nonUpdatable.indexOf(keys[i]) === -1) {
        return true;
      }
    }

    if (fixedKeys.indexOf(keys[i]) !== -1) {
      for (let j = 0; j < nextKey.length; j += 1) {
        const nextRow = nextKey[j];
        const currentRow = currentKey[j];
        if (nextRow && currentRow) {
          if (
            nextRow.rostersRowNumber !== currentRow.rostersRowNumber ||
            nextRow.user_id !== currentRow.user_id
          ) {
            return true;
          }

          for (let k = 0; k < nextRow.rosters.length; k += 1) {
            const nextRosters = nextRow.rosters[k];
            const currentRosters = currentRow.rosters[k];

            if (nextRosters && currentRosters) {
              for (let n = 0; n < nextRosters.length; n += 1) {
                if (!_.isEqual(nextRosters[n], currentRosters[n])) {
                  return true;
                }
              }
            }
          }
        }
      }
    }

    if (nonUpdatable.indexOf(keys[i]) === -1) {
      if (!_.isEqual(nextKey, currentKey)) {
        return true;
      }
    }
  }

  return false;
};

export const appendRowForUserView = (
  rostersWeek: any,
  users: StringMap<UserFields>,
  userId: string | null
) => {
  const r = _.cloneDeep(rostersWeek);
  r.forEach((row: any) => {
    if (
      row.user_id === userId &&
      (userId === null || users[row.user_id].is_active)
    ) {
      row.rostersRowNumber += 1;
      row.rosters.push(emptyRow);
    }
  });
  return r;
};

export const appendRowForEvents = (events: any) => {
  const e = _.cloneDeep(events);
  e.rostersRowNumber += 1;
  e.rosters.push(emptyRow);
  return e;
};

export const appendRowForSiteView = (
  rosterWeek: any,
  areaId: string | null,
  roleId: string | null
) => {
  const r = _.cloneDeep(rosterWeek);
  r.forEach((row: any) => {
    if (row.area_id === areaId && row.role_id === roleId) {
      row.rowsNumber += 1;
      row.rosters.push(emptyRow);
    }
  });
  return r;
};

export const getDurationsInfo = (
  data: any,
  r: RosteredShift[],
  t: Timesheet[],
  timeOffs: any
) => {
  const unavailableShifts = getUnavailableShifts(timeOffs);
  return [
    getShiftInfo('rosteredShiftId', data, r, unavailableShifts),
    getShiftInfo('timesheetId', data, t, []),
  ];
};

export const getDurationsInfoForTemplate = (
  data: any,
  r: ShiftTemplateItem[]
) => {
  return [getShiftTemplateInfo('rosteredShiftId', data, r)];
};

export const shortenTimeFormat = (time: string) =>
  time.replace(/ pm/i, 'p').replace(/ am/i, 'a');

export const getTotalsPerDay = (week: any, events: StringMap<Event>) => {
  let totals = [
    { value: 0, hasEvent: false },
    { value: 0, hasEvent: false },
    { value: 0, hasEvent: false },
    { value: 0, hasEvent: false },
    { value: 0, hasEvent: false },
    { value: 0, hasEvent: false },
    { value: 0, hasEvent: false },
  ];
  for (let i = 0; i < week.length; i += 1) {
    for (let j = 0; j < totals.length; j += 1) {
      const event = week[i][j];
      if (event) {
        if (event.hasOwnProperty('id') && events[event.id]) {
          totals[j].value = totals[j].value + events[event.id].day_cost;
          totals[j].hasEvent = true;
        }
        if (event.hasOwnProperty('isFilledBy') && events[event.isFilledBy]) {
          totals[j].value = totals[j].value + events[event.isFilledBy].day_cost;
          totals[j].hasEvent = true;
        }
      }
    }
  }
  return totals;
};

export const swapShifts = (
  stateToModify: RowCellsProps[],
  draggableShift: Partial<ShiftTemplateItem> | Partial<RosteredShift> | null,
  dayIndex: number
) => {
  if (!draggableShift) {
    return stateToModify;
  }
  for (let i = 0; i < stateToModify.length; i += 1) {
    const item = stateToModify[i];
    if (i === dayIndex) {
      item.rosteredShiftId = draggableShift.id!;
      item.timesheetId = item.timesheetId ? item.timesheetId : null;
    }
    if (
      i !== dayIndex &&
      item.rosteredShiftId &&
      item.rosteredShiftId === draggableShift.id
    ) {
      item.rosteredShiftId = null;
    }
  }
  return stateToModify;
};

export const addShift = (
  stateToModify: RowCellsProps[],
  draggableShift: Partial<ShiftTemplateItem> | Partial<RosteredShift> | null,
  dayIndex: number
) => {
  if (!draggableShift) {
    return stateToModify;
  }
  for (let i = 0; i < stateToModify.length; i += 1) {
    const item = stateToModify[i];
    if (i === dayIndex) {
      item.rosteredShiftId = draggableShift.id!;
    }
  }
  return stateToModify;
};

export const disableTooltip = () => {
  const popover = document.querySelector('#PopoverContainer');
  if (popover) {
    popover.setAttribute('style', 'display: none');
  }
};

export const enableTooltip = () => {
  const popover = document.querySelector('#PopoverContainer');
  if (popover) {
    setTimeout(() => {
      popover.setAttribute('style', '');
    }, 500);
  }
};

export const removeHiddenClassName = () => {
  const hiddenCard = document.querySelector('.hidden-card');
  if (hiddenCard) {
    hiddenCard.classList.remove('hidden-card');
  }
};

export const removeDeniedClassName = () => {
  const denied = document.querySelectorAll('.drop-denied');
  if (denied && denied.length) {
    denied.forEach((c) => c.classList.remove('drop-denied'));
  }
};

export const removeAllPlaceholders = () => {
  const splitPlaceholders = document.querySelectorAll(
    '.drag-over-split-placeholder'
  );
  if (splitPlaceholders && splitPlaceholders.length) {
    splitPlaceholders.forEach((c) =>
      c.classList.remove('drag-over-split-placeholder')
    );
  }
};

export const removeDropPlaceholders = () => {
  const cell = document.querySelector('.drag-start-cell');
  if (cell) {
    cell.classList.remove('drag-start-cell');
  }
  const overPlaceholders = document.querySelectorAll('.drag-over-card');
  if (overPlaceholders && overPlaceholders.length) {
    overPlaceholders.forEach((c) => c.classList.remove('drag-over-card'));
  }
  removeAllPlaceholders();
};

export const removeSplitCellClassName = () => {
  const cell = document.querySelector('.drag-over-split-placeholder');
  if (cell) {
    cell.classList.remove('drag-over-split-placeholder');
  }
};

export const handleDragEnd = (dropInProgress: boolean) => {
  removeDropPlaceholders();
  removeDeniedClassName();
  removeSplitCellClassName();
  if (!dropInProgress) {
    removeHiddenClassName();
  }
};

export const dropDenied = (
  currentTarget: HTMLDivElement,
  draggableShift: Partial<ShiftTemplateItem> | Partial<RosteredShift> | null
) => {
  const { children, className } = currentTarget;
  const dragId = children && children[0] ? children[0].getAttribute('id') : '';
  if (
    !draggableShift ||
    className.indexOf('drag-start-cell') !== -1 ||
    className.indexOf('drop-denied') !== -1
  ) {
    return true;
  }
  if (dragId) {
    return dragId.indexOf(draggableShift.id!) !== -1;
  }
  return false;
};

export const getRowId = (
  dayIndex: string,
  props: {
    viewType: 'user' | 'site';
    user_id: null | string;
    area_id: undefined | null | string;
    role_id: undefined | null | string;
    rowIndex: number;
  }
) => {
  const { viewType, rowIndex, user_id, area_id, role_id } = props;
  const rowId = user_id ? user_id : `${area_id}-${role_id}`;
  return `${viewType}-cell-${rowIndex}-${dayIndex}-${rowId}`;
};

export const removeDragOverClassName = (e: React.MouseEvent) => {
  const { currentTarget } = e;
  if (currentTarget && currentTarget.classList) {
    currentTarget.classList.remove('drag-over-card');
    removeDeniedClassName();
  }
};

export const validateDropCell = (
  dataset: DOMStringMap,
  validateByUser: Function,
  validateByAreaRole: Function
) => {
  const { userId, areaId, roleId } = dataset;
  if (userId && !areaId && !roleId) {
    return validateByUser(userId);
  } else {
    return validateByAreaRole(areaId, roleId);
  }
};

export const validateByAvailableRoles = (
  userId: string | null,
  props: {
    roles: StringMap<AccountTreeRole>;
    areas: StringMap<AccountTreeArea>;
    users: StringMap<ServerUserFields> | StringMap<UserFields>;
    draggableShift: null | Partial<ShiftTemplateItem> | Partial<RosteredShift>;
    siteId: string;
    areasBySideId: StringMap<AccountTreeArea[]>;
  }
) => {
  const { users, areas, roles, areasBySideId, siteId, draggableShift } = props;
  const shiftAreaRole = `${draggableShift!.area_id}__${
    draggableShift!.role_id
  }`;

  if (userId !== null && areasBySideId[siteId]) {
    const r = getOptionsForCurrentUser(
      areasBySideId[siteId],
      areas,
      roles,
      users[userId]
    );
    for (let i = 0; i < r.length; i += 1) {
      if (r[i].value === shiftAreaRole) {
        return true;
      }
    }
  }
  return false;
};

export const validateByAvailableAreaRoles = (
  userId: string | null | undefined,
  areaId: string | undefined,
  roleId: string | undefined,
  props: {
    roles: StringMap<AccountTreeRole>;
    areas: StringMap<AccountTreeArea>;
    users: StringMap<ServerUserFields>;
    draggableShift: null | Partial<ShiftTemplateItem> | Partial<RosteredShift>;
    siteId: string;
    areasBySideId: StringMap<AccountTreeArea[]>;
  }
) => {
  const { users, areas, roles, areasBySideId, siteId } = props;
  const cellAreaRole = `${areaId}__${roleId}`;
  if (typeof userId === 'undefined' || !areaId || !roleId) {
    return false;
  }
  const userID = userId === 'null' ? null : userId;
  if (userID === null) {
    return true;
  }
  if (areasBySideId[siteId]) {
    const r = getOptionsForCurrentUser(
      areasBySideId[siteId],
      areas,
      roles,
      users[userID]
    );
    for (let i = 0; i < r.length; i += 1) {
      if (r[i].value === cellAreaRole) {
        return true;
      }
    }
  }
  return false;
};

export const addFutureClassName = (
  currentTimeBySiteTimezone: Moment,
  date: Moment
) => {
  const future = currentTimeBySiteTimezone.isBefore(date, 'day');
  return future ? ' future-cell' : '';
};

export const getVisibilityProp = (
  legendType: string,
  selectedFilter: RosterFiltersByType
) => {
  if (legendType === 'roster' && selectedFilter.roster) {
    return true;
  }
  return legendType === 'timesheet' && selectedFilter.timesheets;
};

export const getDurationLabels = (
  duration: InfoType[],
  selectedFilter: RosterFiltersByType
) => {
  const result: Duration[] = [];
  duration.forEach((d: InfoType) => {
    result.push({
      quantity: d.quantity,
      time: d.time,
      icon:
        d.type === 'roster' ? (
          <CalendarTodayIcon />
        ) : (
          <TableChartOutlinedIcon />
        ),
      isVisible: getVisibilityProp(d.type, selectedFilter),
    });
  });
  return result;
};
