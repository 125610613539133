import React, { ReactNode } from 'react';
import { Layout } from 'element';
import { ManagerDashboardErrors } from './components/ManagerDashboardErrors';

type Props = {
  header: ReactNode;
  children: ReactNode;
};

export const ManagerDashboardLayout = ({ header, children }: Props) => {
  return (
    <>
      <Layout.Header>{header}</Layout.Header>

      <Layout.Content>
        <ManagerDashboardErrors />
        {children}
      </Layout.Content>
    </>
  );
};
