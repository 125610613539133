import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { filtersSelector } from 'state/Roster/RosterFilters';
import { RosteredShiftRow } from './RosteredShiftRow';
import { TimesheetRow } from './TimesheetRow';

type Props = { userRowIndex: number; pairRowIndex: number };

export const PairRow = memo(function PairRow(props: Props) {
  const filters = useSelector(filtersSelector);
  return (
    <>
      {(filters.roster || filters.unavailability) && (
        <RosteredShiftRow {...props} />
      )}
      {filters.timesheets && <TimesheetRow {...props} />}
    </>
  );
});
