import { defaultBreakRulesValidator } from 'page/Settings/components';
import {
  DefaultBreaksFieldGroupErrors,
  DefaultBreaksFieldGroupValue,
} from './types';

export default defaultBreaksFieldGroupValidator;

export function defaultBreaksFieldGroupValidator(
  values: DefaultBreaksFieldGroupValue
) {
  const errors: DefaultBreaksFieldGroupErrors = {};

  if (!values.auto_breaks || !values.breaks_from_rules) {
    return errors;
  }

  const defaultBreakRules = defaultBreakRulesValidator(
    values.default_breaks_rules
  );

  if (defaultBreakRules) {
    errors.default_breaks_rules = defaultBreakRules;
  }

  return errors;
}

