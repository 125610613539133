import { Box, BoxProps, Alert } from 'extended-oxygen-elements';
import React from 'react';

export default InfoBox;

export function InfoBox(boxProps: BoxProps) {
  const [isOpen, setIsOpen] = React.useState(true);

  if (!isOpen) {
    return null;
  }

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Box {...boxProps}>
      <Alert severity="warning" onClose={handleClose}>
        Any change to the ‘Employee accessibility’ settings below will prevent
        your employees from using the mobile app features. Please tick the
        required settings if you would like your employees to access those
        features of the Breathe app.
      </Alert>
    </Box>
  );
}
