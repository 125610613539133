import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { DefaultDivProps } from 'type';
import { TIMESHEETS_CELL_WIDTH_PX } from 'lib/config';
import { daysSelector } from 'state/TimesheetsWeeklyPage';
import { TimesheetCell } from './components/TimesheetCell';

type Props = DefaultDivProps & {
  userId: string;
  rowIndex: number;
};

const useStyles = makeStyles(() => createStyles({
  wrapper: {
    display: 'flex',
    width: 'calc(100% / 7)',
    padding: '0 2px',
    boxSizing: 'border-box',
    minWidth: TIMESHEETS_CELL_WIDTH_PX
  }
}));

export const TimesheetRow = ({ userId, rowIndex, ...restProps }: Props) => {
  const classes = useStyles();
  const days = useSelector(daysSelector);

  return (
    <div {...restProps}>
      {
        days.map((day: string) => {
          return (
            <div
              key={day}
              className={classes.wrapper}
            >
              <TimesheetCell {...{ userId, rowIndex, day }} />
            </div>
          );
        })
      }
    </div>
  );
};
