import React, { useEffect, useState } from 'react';
import SearchLocationInput from '../SearchLocationInput';
import RadiusInput from '../RadiusInput';
import TrackLocationCheckbox from '../TrackLocationCheckbox';
import { Col, Row, Card } from '../../../../../../elmo-elements';
import { AccountTreeSite } from '../../../../../../type';
import MapWrapper from '../Map';

type Props = {
  site: AccountTreeSite;
  isDisabled: boolean;
  setSettings: (id: string, options: any) => void;
  findAddressByCoords: (id: string, options: any) => void;
};

const LocationCard = ({ site, isDisabled, setSettings, findAddressByCoords }: Props) => {

  const [trackIsChecked, setTrackLocation] = useState(site.geo_enabled);
  const onChangeTrackLocation = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    setTrackLocation(checked);
    setSettings(site.id, {
      geo_enabled: checked
    });
  };

  return (
    <Row>
      <Col>
        <TrackLocationCheckbox
          location={site}
          isDisabled={isDisabled}
          onChange={onChangeTrackLocation}
          isChecked={trackIsChecked}
        />
      </Col>
      <Col sm={24} md={6}>
        <SearchLocationInput
          location={site}
          isDisabled={isDisabled || !trackIsChecked}
          setSettings={setSettings}
        />
        <RadiusInput
          location={site}
          setSettings={setSettings}
          isDisabled={isDisabled || !trackIsChecked}
        />
      </Col>
      <Col className={'pt-3'} sm={24} md={14}>
        <MapWrapper
          location={site}
          isDisabled={isDisabled || !trackIsChecked}
          findAddressByCoords={findAddressByCoords}
        />
      </Col>
    </Row>

  );
}

export default LocationCard;