import React from 'react';
import { connect } from 'react-redux';
import { Button, NativeTable, Text } from 'elmo-elements';
import {
  AccountTreeSelector,
  LanguagePreferences
} from 'type';
import { StoreState } from 'state/types';
import { getLangPreferences } from 'state/Account';
import { getAccountTree } from 'state/AccountTree';
import { EditIcon } from 'element';

const { Header, Th, Td, Body, Tr } = NativeTable;

type OwnProps = {
  openFiltersModal: () => void;
  areaIds: string[] | null;
  roleIds: string[] | null;
  id?: string;
};

type StateProps = {
  langPreferences: LanguagePreferences;
  accountTree: AccountTreeSelector;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

const FlatAreaRoleFilterComponent = ({
  openFiltersModal,
  areaIds,
  roleIds,
  langPreferences,
  accountTree,
  id
}: Props) => {
  const handleButtonClick = () => {
    openFiltersModal();
  };

  return (
    <div data-testid={id}>
      <NativeTable isFullWidth={true}>
        <Header>
          <Tr>
            <Th className="elmo-nativetable-th--has-btn-custom">
              <Button
                className="elmo-nativetable__btn-custom"
                icon={<EditIcon />}
                onClick={handleButtonClick}
                ariaLabel="Edit"
              />
              Filtered by {langPreferences.area.plural}:
            </Th>
          </Tr>
        </Header>

        <Body>
          {!areaIds ? (
            <Tr>
              <Td>
                <Text color="gray">All {langPreferences.area.plural}</Text>
              </Td>
            </Tr>
          ) : (
            areaIds.map(areaId => {
              const area = accountTree.areas[areaId];

              return (
                <Tr key={areaId}>
                  <Td>{area.name}</Td>
                </Tr>
              );
            })
          )}
        </Body>
      </NativeTable>

      <NativeTable isFullWidth={true}>
        <Header>
          <Tr>
            <Th>Filtered by {langPreferences.role.plural}:</Th>
          </Tr>
        </Header>

        <Body>
          {!roleIds ? (
            <Tr>
              <Td>
                <Text color="gray">All {langPreferences.role.plural}</Text>
              </Td>
            </Tr>
          ) : (
            roleIds.map(roleId => {
              const role = accountTree.roles[roleId];

              return (
                <Tr key={roleId}>
                  <Td>{role.name}</Td>
                </Tr>
              );
            })
          )}
        </Body>
      </NativeTable>
    </div>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  langPreferences: getLangPreferences(state),
  accountTree: getAccountTree(state)
});

// const mapDispatchToProps: DispatchProps = {};

export const FlatAreaRoleFilter = connect(
  mapStateToProps /*,
  mapDispatchToProps*/
)(FlatAreaRoleFilterComponent);
