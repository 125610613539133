import React from 'react';
import { Box, BoxProps } from 'extended-oxygen-elements';

type Props = Omit<BoxProps, 'width'>;

export default DialogContentNarrow;

export function DialogContentNarrow({ children, ...restProps }: Props) {
  return (
    <Box
      {...restProps}
      width={{
        xs: '100%',
        sm: '90%',
      }}
    >
      {children}
    </Box>
  );
}
