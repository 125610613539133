import { createSelector } from 'reselect';
import { SortUser, UserFields } from '../../../../../type/models';
import { StoreState } from '../../../../types';
import { arraySortedCombiner } from '../../../../combiners';
import { getSearchQuery } from '../index';
import { foundUsersSelector, sortedUsersSelector } from './internal';

export const foundOrFilteredUsersSelector = createSelector<
  StoreState,
  UserFields[],
  UserFields[],
  string,
  UserFields[]
>(
  foundUsersSelector,
  sortedUsersSelector,
  getSearchQuery,
  (foundUsers, sortedUsers, searchQuery) => {
    if (searchQuery.length) {
      const sort: SortUser = {
        column: 'full_name',
        direction: 'asc'
      };

      return arraySortedCombiner(foundUsers, sort);
    }

    return sortedUsers;
  }
);
