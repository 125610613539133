import { pick } from 'lodash';
import moment, { Moment } from 'moment';
import { TimeOff } from 'type';
import { CreateTimeOffPayload } from 'state/ManagerDashboard/Unavailability';
import { FormData, Props, ViewState } from './types';
import { createUntilDate, getDefaultDates } from './helpers';

export const getState = ({ timeOff }: Props): ViewState => {
  if (!timeOff) {
    return {
      data: {
        user_id: '',
        site_id: '',
        duration: 'all_day',
        repeat: 'once',
        status: 'pending',
        description: '',
        user_name: '',
        site_name: '',
        ...getDefaultDates()
      }
    };
  }

  const { start, end, until, description } = timeOff;

  return {
    data: {
      ...pick<TimeOff, keyof TimeOff>(timeOff, [
        'user_id',
        'site_id',
        'duration',
        'repeat',
        'status'
      ]),
      start_date: start,
      start_time: moment({
        hour: start.get('hours'),
        minute: start.get('minutes')
      }),
      end_date: end,
      end_time: moment({
        hour: end.get('hours'),
        minute: end.get('minutes')
      }),
      until: until ? until : createUntilDate(end),
      description: description ? description : '',
      user_name: '',
      site_name: '',
    }
  };
};

const calculateSubmitPayloadTime = ({
  date,
  time
}: {
  date: Moment;
  time: Moment | null;
}) => {
  if (!time || !date.isValid()) {
    return;
  }

  return moment(date).set({
    hours: time.get('hours'),
    minutes: time.get('minutes')
  });
};

export const getSubmitPayload = ({
  start_date,
  start_time,
  end_date,
  end_time,
  until,
  ...rest
}: FormData): CreateTimeOffPayload | undefined => {
  const start = calculateSubmitPayloadTime({
    date: start_date,
    time: start_time
  });
  if (!start) {
    return;
  }

  const end = calculateSubmitPayloadTime({ date: end_date, time: end_time });
  if (!end) {
    return;
  }

  const payload: CreateTimeOffPayload = {
    ...rest,
    start,
    end,
    until: null
  };

  if (payload.repeat !== 'once') {
    payload.until = until;
  }

  if (!payload.user_id.length) {
    return;
  }

  if (!payload.site_id.length) {
    return;
  }

  if (
    payload.duration !== 'all_day' &&
    payload.start.isSameOrAfter(payload.end)
  ) {
    return;
  }

  return payload;
};
