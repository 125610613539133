import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { getIsCovidModeEnabled } from 'state/Roster/CovidTracing/selectors';
import ContextualMenu from '../../../../components/ContextualMenu';

type Props = {
  id: string;
  cellData: any;
  shiftType: 'rostered_shift' | 'timesheet' | 'shift_item';
  menuType: 'create' | 'edit';
};

export const ContextualMenuBlock = memo(function ContextualMenuBlock(
  props: Props
) {
  const {  id, cellData, shiftType, menuType } = props;
  const isCovidMode = useSelector(getIsCovidModeEnabled);

  if ( isCovidMode ) {
    return null;
  }

  return (
    <ContextualMenu
      className={'full-cell-menu'}
      elementId={id}
      type={menuType}
      shiftType={shiftType}
      objectId={null}
      currentCellData={cellData}
    />
  );
})
