import { SagaAction } from '../../type';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { ToggleSelectedIdPayload } from './types';
import {
  getAllIds,
  getIsAllSelected,
  getPageIds,
  pageSelectedIdsSelector,
  selectedIdsSelector
} from './selectors';
import { BOX_BULK_SET_ALL_VISIBLE, BOX_BULK_SET_SELECTED_IDS } from './actions';
import { xor, intersection } from 'lodash';

function* selectAllVisible({
  payload: isSelected
}: SagaAction<boolean>): SagaIterator {
  const visibleUserIds: string[] = yield select(getPageIds);

  yield put(actions.BOX_BULK_SET_ALL_VISIBLE(false));
  yield put(
    actions.BOX_BULK_SET_SELECTED_IDS(isSelected ? visibleUserIds : [])
  );
}

function* selectAllAvailable({
  payload: isSelected
}: SagaAction<boolean>): SagaIterator {
  if (!isSelected) {
    yield call(clearSelected);
    return;
  }

  yield put(actions.BOX_BULK_SET_ALL_VISIBLE(true));
}

function* toggleSelected({
  payload: { id }
}: SagaAction<ToggleSelectedIdPayload>): SagaIterator {
  const pageSelectedIds: string[] = yield select(pageSelectedIdsSelector);

  yield put(BOX_BULK_SET_ALL_VISIBLE(false));
  yield put(BOX_BULK_SET_SELECTED_IDS(xor(pageSelectedIds, [id])));
}

function* updateSelectedIds(): SagaIterator {
  const selectedIds: string[] = yield select(selectedIdsSelector);
  const allIds: string[] = yield select(getAllIds);

  const newSelectedIds = intersection(selectedIds, allIds);

  yield put(BOX_BULK_SET_ALL_VISIBLE(newSelectedIds.length === allIds.length));
  yield put(BOX_BULK_SET_SELECTED_IDS(newSelectedIds));
}

function* clearSelected(): SagaIterator {
  yield put(BOX_BULK_SET_ALL_VISIBLE(false));
  yield put(BOX_BULK_SET_SELECTED_IDS([]));
}

function* clearIfAllItemsChanged(): SagaIterator {
  const isAllSelected: boolean = yield select(getIsAllSelected);

  if (isAllSelected) {
    yield call(clearSelected);
  }
}

export const watchBulk = function*(): SagaIterator {
  yield takeLatest(actions.BOX_BULK_SELECT_ALL_VISIBLE, selectAllVisible);
  yield takeLatest(actions.BOX_BULK_SELECT_ALL_AVAILABLE, selectAllAvailable);
  yield takeLatest(actions.BOX_BULK_TOGGLE_SELECTED_ID, toggleSelected);
  yield takeLatest(actions.BOX_BULK_CLEAR_SELECTED_IDS, clearSelected);
  yield takeLatest(
    actions.BOX_BULK_CLEAR_SELECTED_IF_ALL_ITEMS_CHANGED,
    clearIfAllItemsChanged
  );
  yield takeLatest(actions.BOX_BULK_SET_ALL_IDS, updateSelectedIds);
};
