import { identity, some } from 'lodash';
import { createSelector } from 'reselect';
import { getCurrentUserPermissions } from 'state/Auth';
import { getKeysWithTrueValues } from 'state/helpers';
import { Dictionary } from 'ts-essentials';
import { PermissionName } from 'type';
import {
  customFiltersCombiner,
  filterByTypeBasedOnPermissionsCombiner,
  filtersByTypeCombiner,
  filtersCombiner,
} from '../combiners';
import {
  RosterFiltersRoleFilterType,
  ViewModulesMyPermissionSelector,
} from '../types';
import { getFilters, getFiltersForm } from './simpleSelectors';

const modulePermissionsSelector = createSelector(
  getCurrentUserPermissions,
  (
    currentUserPermissions: PermissionName[]
  ): ViewModulesMyPermissionSelector => ({
    roster: currentUserPermissions.includes('roster.rosteredshift.view'),
    timesheets: currentUserPermissions.includes('roster.timesheet.view'),
  })
);

export const filtersByTypeSelector = createSelector(
  createSelector(getFilters, filtersByTypeCombiner),
  modulePermissionsSelector,
  filterByTypeBasedOnPermissionsCombiner
);

const customFiltersSelector = createSelector(getFilters, customFiltersCombiner);

export const filtersSelector = createSelector(
  filtersByTypeSelector,
  customFiltersSelector,
  filtersCombiner
);

export const rosteredShiftFilterSelector = createSelector(
  filtersSelector,
  (filters) => filters.roster
);
export const timesheetsFilterSelector = createSelector(
  filtersSelector,
  (filters) => filters.timesheets
);

export const filtersFormFiltersByTypeSelector = createSelector(
  createSelector(getFiltersForm, filtersByTypeCombiner),
  modulePermissionsSelector,
  filterByTypeBasedOnPermissionsCombiner
);
export const filtersFormSelector = createSelector(
  filtersFormFiltersByTypeSelector,
  createSelector(getFiltersForm, customFiltersCombiner),
  filtersCombiner
);

export const isFiltersFormDisabledSelector = createSelector(
  filtersFormFiltersByTypeSelector,
  ({ roster, unavailability, timesheets }): boolean => {
    const filtersByType: boolean[] = [roster, unavailability, timesheets];

    return !some(filtersByType);
  }
);

export const isFiltersChangedSelector = createSelector(
  customFiltersSelector,
  ({ tags, events, areas, roles, roleTriples, roleFilterType }): boolean => {
    const isRoleFiltersChanged: Dictionary<
      boolean,
      RosterFiltersRoleFilterType
    > = {
      flat: !!(areas || roles),
      tree: !!roleTriples,
    };

    const isRoleFilterChanged = isRoleFiltersChanged[roleFilterType];

    return some([events, tags, isRoleFilterChanged], identity);
  }
);

export const shiftTypesToBeShownSelector = createSelector(
  filtersByTypeSelector,
  getKeysWithTrueValues
);
