import React from 'react';
import { Heading, Text } from '../../Typography';
import { withDataTableContext } from '../DataTableContextConsumer';
import Link from '../../Link';
import './DataTableEmptyColumnsMessage.scss';

/**
 * @TODO: Replace with empty message block component when it is ready
 */
type DataTableEmptyColumnsMessageProps = {
  /** @ignore Comes from DataTable context */
  setContextState: (state: any) => void;
};

class DataTableEmptyColumnsMessage extends React.Component<DataTableEmptyColumnsMessageProps> {
  onOptionsClick = () => {
    const { setContextState } = this.props;
    setContextState({
      isCustomiseModalOpen: true,
    });
  };

  render() {
    return (
      <div className="elmo-datatable__empty-columns">
        <Heading>The Parthenon has collapsed.</Heading>
        <Text>
          We recommend you{' '}
          <Link url="#" onClick={this.onOptionsClick}>
            redeem yourself
          </Link>{' '}
          Ares before Athena finds out.
        </Text>
      </div>
    );
  }
}

export default withDataTableContext(DataTableEmptyColumnsMessage);
