import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment-timezone';
import { Col, FormContainer, FormItem, Row } from 'elmo-elements';
import { convertDecimalToFormattedTime, getShiftDuration } from 'lib/helpers';
import { DateInputMoment, DSTIcon, TimeInput } from 'element';
import { getSites } from 'state/AccountTree';
import { StoreState } from 'state/types';
import { getDateFormat, timeFormatSelector } from 'state/Account';
import { WithWarningMessage } from './components/WithWarningMessage';
import { AccountTreeSite, RosteredShift, StringMap } from '../../../../type';
import './style.scss';

export type StartEndPeriod = {
  start: Moment;
  end: Moment;
};

type OwnProps = {
  onChangePeriod: (newPeriod: StartEndPeriod, key: 'date' | 'time') => void;
  className?: string;
  params: RosteredShift;
  siteId: string;
};

type StateProps = {
  dateFormat: string;
  timeFormat: string;
  sites: StringMap<AccountTreeSite>;
};

type DispatchProps = {};

export type Props = OwnProps & StateProps & DispatchProps;

type State = {
  isDirty: boolean;
};

export class DateTimeGroupComponent extends Component<Props, State> {
  count: number = 0;
  readonly state: State = {
    isDirty: false,
  };

  render() {
    const { start, end } = this.props.params;

    return (
      <WithWarningMessage
        isDirty={this.state.isDirty}
        params={{ start, end: end! }}
      >
        {({ alert }) => this.getOptions(alert)}
      </WithWarningMessage>
    );
  }

  getOptions = (alert: ReactNode) => {
    const { start, end } = this.props.params;

    return (
      <div className={`rostered-shift-modal__hidden-options rows-container`}>
        <Row role="presentation">
          <Col span={11} className={'ml-3'} role="presentation">
            <FormItem
              label={'Date'}
              labelAddon={
                <DSTIcon
                  isDST={
                    !this.state.isDirty && this.props.params.is_dst_intersect
                  }
                />
              }
            >
              <DateInputMoment
                id={'date-input'}
                onChange={this.onChangeDate}
                name={'start'}
                value={start}
                isClearable={false}
                ariaLabel="Date"
                tabIndex={0}
                clickOnTodayButton={this.clickOnTodayButton}
              />
            </FormItem>
          </Col>
          <Col span={7} className={'ml-3'} role="presentation" />
          <Col span={4} xs={5} role="presentation" />
        </Row>
        <Row role="presentation">
          <Col span={9} className={'ml-3'} role="presentation">
            <FormItem label={'Start time'}>
              <TimeInput
                id={'start-time-input'}
                onChange={this.onStartTimeChange}
                name={'start'}
                value={start}
                label="Start time"
                tabIndex={0}
              />
            </FormItem>
          </Col>
          <Col span={9} role="presentation">
            <FormItem label={'End time'}>
              <TimeInput
                id={'end-time-input'}
                onChange={this.onEndTimeChange}
                name={'end'}
                value={end}
                label="End time"
                tabIndex={0}
              />
            </FormItem>
          </Col>
          <Col span={5} xs={5} role="presentation">
            {this.getShowTotalHrs()}
          </Col>
        </Row>

        {alert}
      </div>
    );
  };

  setTodayInSelectedTimezone = (date: Moment, tz: string) => {
    return moment.tz(tz).set({
      hours: +date.format('HH'),
      minutes: +date.format('mm'),
    });
  };

  clickOnTodayButton = () => {
    const { start, end } = this.props.params;
    const { sites, siteId } = this.props;
    const timezone = sites[siteId].timezone_id;
    this.changePeriod(
      {
        start: this.setTodayInSelectedTimezone(start, timezone),
        end: this.setTodayInSelectedTimezone(end, timezone),
      },
      'date'
    );
  };

  onChangeDate = (selectedDate: Moment) => {
    const { start, end } = this.props.params;

    const updatedStart = this.mergeDateAndTime(selectedDate, start);
    const updatedEnd = this.mergeDateAndTime(selectedDate, end);

    this.changePeriod(
      {
        start: updatedStart,
        end: updatedEnd,
      },
      'date'
    );
  };

  onStartTimeChange = (updatedTime: Moment) => {
    const { start, end } = this.props.params;

    const updatedStart = this.mergeDateAndTime(start, updatedTime);
    this.changePeriod(
      {
        start: updatedStart,
        end,
      },
      'time'
    );
  };

  onEndTimeChange = (updatedTime: Moment) => {
    const { start } = this.props.params;
    const updatedEnd = this.mergeDateAndTime(start, updatedTime);
    this.changePeriod(
      {
        start,
        end: updatedEnd,
      },
      'time'
    );
  };

  // validate & trigger change helpers
  private changePeriod = (newPeriod: StartEndPeriod, key: 'date' | 'time') => {
    this.props.onChangePeriod(this.validateNewPeriod(newPeriod), key);
    this.setIsDirty();
  };

  private setIsDirty = () => {
    if (!this.state.isDirty) {
      this.setState({
        isDirty: true,
      });
    }
  };

  private validateNewPeriod = (newPeriod: StartEndPeriod): StartEndPeriod => {
    const { start, end } = newPeriod;

    if (end.isSameOrBefore(start)) {
      return {
        start: start.clone(),
        end: end.clone().add(1, 'day'),
      };
    }
    return newPeriod;
  };

  // update new value helpers
  private mergeDateAndTime = (date: Moment, time: Moment): Moment => {
    const hours = time.hours();
    const minutes = time.minutes();
    return date.clone().startOf('day').set({
      hours,
      minutes,
    });
  };

  getShowTotalHrs = () => {
    const { start, end, breaks } = this.props.params;

    const timeDetails = getShiftDuration(
      start,
      end,
      breaks,
      this.props.sites[this.props.siteId].timezone_id
    );
    return (
      <div className="date-time-duration padding-top-small">
        {convertDecimalToFormattedTime(timeDetails.total_hrs.toString(), true)}
      </div>
    );
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  timeFormat: timeFormatSelector(state),
  dateFormat: getDateFormat(state),
  sites: getSites(state),
});

export default connect(mapStateToProps)(DateTimeGroupComponent);
