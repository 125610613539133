import { createReducer } from 'lib/store-utils';
import { EmployeeUnavailabilityProps, EmployeeUnavailabilityReducerState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';
import moment from 'moment';
import _ from 'lodash';
import {BOX_EMPLOYEE_UNAVAILABILITY_NOTES_MODAL_TOGGLE} from "./actions";

export const employeeUnavailability = createReducer<EmployeeUnavailabilityReducerState>(
  {},
  getDefaultState()
);

employeeUnavailability.on(
  actions.BOX_EMPLOYEE_UNAVAILABILITY_NOTES_MODAL_TOGGLE,
  (state, props): EmployeeUnavailabilityReducerState => ({
    ...state,
    notesModal: props
}));

employeeUnavailability.on(
  actions.BOX_EMPLOYEE_GET_UNAVAILABILITY_SUCCESS,
  (state, data): EmployeeUnavailabilityReducerState => ({
    ...state,
    isFetching: false,
    data: data.time_offs,
    shifts: data.rostered_shifts,
    errors: []
  }));

employeeUnavailability.on(
  actions.BOX_EMPLOYEE_GET_UNAVAILABILITY_FAILURE,
  (state, errors): EmployeeUnavailabilityReducerState => ({
  ...state,
  isFetching: false,
  errors: errors
}));

employeeUnavailability.on(
  actions.BOX_EMPLOYEE_SET_DAY_DETAILS_CARD,
  (state, props): EmployeeUnavailabilityReducerState => ({
    ...state,
    dayDetails: state.dayDetails !== null ? null : props
  })
);

employeeUnavailability.on(
  actions.BOX_EMPLOYEE_UNAVAILABILITY_MODAL_OPEN,
  (state, props): EmployeeUnavailabilityReducerState => ({
    ...state,
    modal: {
      ...state.modal,
      isOpened: true,
      errors: [],
      type: props.type,
      initialDate: props.initialDate || moment(),
      data: {
        ...state.modal.data,
        start: props.initialDate || state.modal.data.start,
        end: props.initialDate || state.modal.data.start,
        until: props.initialDate ? null : state.modal.data.until
      }
    }
  })
);

employeeUnavailability.on(
  actions.BOX_EMPLOYEE_UNAVAILABILITY_MODAL_CLOSE,
  (state): EmployeeUnavailabilityReducerState => ({
    ...state,
    modal: {
      ...state.modal,
      isOpened: false,
      data: getDefaultState().modal.data,
      type: 'create'
    }
  })
);

employeeUnavailability.on(
  actions.BOX_EMPLOYEE_UNAVAILABILITY_PREV_MONTH,
  (state): EmployeeUnavailabilityReducerState => ({
    ...state,
    data: [],
    shifts: [],
    date: moment(state.date).subtract(1, 'month')
  })
);

employeeUnavailability.on(
  actions.BOX_EMPLOYEE_UNAVAILABILITY_NEXT_MONTH,
  (state): EmployeeUnavailabilityReducerState => ({
    ...state,
    data: [],
    shifts: [],
    date: moment(state.date).add(1, 'month')
  })
);

employeeUnavailability.on(
  actions.BOX_EMPLOYEE_UNAVAILABILITY_RESET_MONTH,
  (state): EmployeeUnavailabilityReducerState => ({
    ...state,
    data: [],
    shifts: [],
    date: moment()
  })
);

employeeUnavailability.on(
  actions.BOX_EMPLOYEE_UNAVAILABILITY_GET_DATA_ON_DATE_CHANGED,
  (state): EmployeeUnavailabilityReducerState => ({
    ...state,
    isFetching: true,
    errors: []
  })
);

employeeUnavailability.on(
  actions.BOX_EMPLOYEE_SET_SPREAD_OF_HOURS,
  (state, result): EmployeeUnavailabilityReducerState => ({
    ...state,
    spreadOfHours: result
  })
);

employeeUnavailability.on(
  actions.BOX_EMPLOYEE_UNAVAILABILITY_CLEAR_ERRORS,
  (state): EmployeeUnavailabilityReducerState => ({
    ...state,
    errors: [],
    modal: {
      ...state.modal,
      errors: []
    }
  })
);

employeeUnavailability.on(
  actions.BOX_EMPLOYEE_GET_UNAVAILABILITY_BY_ID,
  (state, id): EmployeeUnavailabilityReducerState => ({
    ...state,
    modal: {
      ...state.modal,
      isFetching: true
    }
  })
);

employeeUnavailability.on(
  actions.BOX_EMPLOYEE_GET_UNAVAILABILITY_BY_ID_SUCCESS,
  (state, props): EmployeeUnavailabilityReducerState => ({
    ...state,
    modal: {
      ...state.modal,
      isFetching: false,
      data: props
    }
  })
);

employeeUnavailability.on(
  actions.BOX_EMPLOYEE_GET_UNAVAILABILITY_BY_ID_FAILURE,
  (state, errors): EmployeeUnavailabilityReducerState => ({
    ...state,
    modal: {
      ...state.modal,
      isFetching: false,
      errors: errors
    }
  })
);

employeeUnavailability.on(
  actions.BOX_EMPLOYEE_CREATE_UNAVAILABILITY,
  (state, props): EmployeeUnavailabilityReducerState => ({
    ...state,
    modal: {
      ...state.modal,
      isUpdating: true,
      errors: []
    }
  })
);

employeeUnavailability.on(
  actions.BOX_EMPLOYEE_CREATE_UNAVAILABILITY_SUCCESS,
  (state, props): EmployeeUnavailabilityReducerState => ({
    ...state,
    data: [
      ...state.data,
      props
    ],
    modal: {
      ...state.modal,
      isUpdating: false,
      data: props,
      errors: []
    }
  })
);

employeeUnavailability.on(
  actions.BOX_EMPLOYEE_CREATE_UNAVAILABILITY_FAILURE,
  (state, errors): EmployeeUnavailabilityReducerState => ({
    ...state,
    modal: {
      ...state.modal,
      isUpdating: false,
      errors: errors
    }
  })
);

employeeUnavailability.on(
  actions.BOX_EMPLOYEE_UPDATE_UNAVAILABILITY,
  (state, props): EmployeeUnavailabilityReducerState => ({
    ...state,
    isFetching: true,
    modal: {
      ...state.modal,
      isUpdating: true,
      errors: []
    }
  })
);

employeeUnavailability.on(
  actions.BOX_EMPLOYEE_UPDATE_UNAVAILABILITY_SUCCESS,
  (state, props): EmployeeUnavailabilityReducerState => {
    for (let i = 0; i < state.data.length; i += 1) {
      if (state.data[i].id === props.id) {
        state.data[i] = Object.assign(state.data[i], props);
      }
    }
    return {
      ...state,
      data: state.data,
      isFetching: false,
      modal: {
        ...state.modal,
        data: props,
        isUpdating: false,
        errors: []
      }
    }
  }
);

employeeUnavailability.on(
  actions.BOX_EMPLOYEE_UPDATE_ADD_UNAVAILABILITY_SUCCESS,
  (state, props): EmployeeUnavailabilityReducerState => {
    let found = false;
    for (let i = 0; i < state.data.length; i += 1) {
      if (state.data[i].id === props.id) {
        state.data[i] = Object.assign(state.data[i], props);
        found = true;
      }
    }
    if ( !found || state.data.length === 0 ) {
      state.data.push(props);
    }
    return {
      ...state,
      data: state.data
    }
  }
);

employeeUnavailability.on(
  actions.BOX_EMPLOYEE_UPDATE_TIMEOFF_ROSTERED_SHIFT,
  (state, props): EmployeeUnavailabilityReducerState => {
    let found = false;
    if ( state.shifts.length > 0 ) {
      for (let i = 0; i < state.shifts.length; i += 1) {
        if (state.shifts[i].id === props.id) {
          state.shifts[i] = Object.assign(state.shifts[i], props);
          found = true;
        }
      }
    }
    if ( !found || state.shifts.length === 0 ) {
      state.shifts.push(props);
    }
    return {
      ...state,
      shifts: state.shifts
    }
  }
);

employeeUnavailability.on(
  actions.BOX_EMPLOYEE_UPDATE_UNAVAILABILITY_FAILURE,
  (state, errors): EmployeeUnavailabilityReducerState => ({
    ...state,
    isFetching: false,
    modal: {
      ...state.modal,
      isUpdating: false,
      errors: errors
    }
  })
);
employeeUnavailability.on(
  actions.BOX_EMPLOYEE_DELETE_UNAVAILABILITY,
  (state, props): EmployeeUnavailabilityReducerState => ({
    ...state,
    modal: {
      ...state.modal,
      isUpdating: false,
      isDeleting: true
    }
  })
);

employeeUnavailability.on(
  actions.BOX_EMPLOYEE_DELETE_UNAVAILABILITY_SUCCESS,
  (state, id): EmployeeUnavailabilityReducerState => {
    const data = _.filter(state.data, r => r.id !== id);
    return {
      ...state,
      data: data,
      dayDetails: null,
      modal: {
        ...state.modal,
        isUpdating: false,
        errors: [],
        isDeleting: false
      }
    }
  }
);

employeeUnavailability.on(
  actions.BOX_EMPLOYEE_REMOVE_ROSTERED_SHIFT,
  (state, id): EmployeeUnavailabilityReducerState => {
    const shifts = _.filter(state.shifts, r => r.id !== id);
    return {
      ...state,
      shifts: shifts,
      dayDetails: null
    }
  }
);

employeeUnavailability.on(
  actions.BOX_EMPLOYEE_DELETE_UNAVAILABILITY_FAILURE,
  (state, errors): EmployeeUnavailabilityReducerState => ({
    ...state,
    modal: {
      ...state.modal,
      isUpdating: false,
      isDeleting: false,
      errors: errors
    }
  })
);
