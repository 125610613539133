import { Form, Layout } from 'element';
import React from 'react';
import SettingsHeader from './SettingsHeader/index';
import SettingsSaveBtn from './SettingsSaveBtn';

export type SettingsLayoutComponentProps = {
  title: string;
  loading: boolean;
  onSubmit: () => void;
  children: React.ReactNode;
};

export default SettingsLayoutComponent;

export function SettingsLayoutComponent({
  title,
  loading,
  onSubmit,
  children,
}: SettingsLayoutComponentProps) {
  return (
    <>
      <Layout.Header>
        <SettingsHeader>{title}</SettingsHeader>
      </Layout.Header>

      <Layout.Content>
        <Form onSubmit={onSubmit}>
          {children}

          <SettingsSaveBtn loading={loading} />
        </Form>
      </Layout.Content>
    </>
  );
}
