import React, { useState } from 'react';
import './styles.scss';
import { Button, Divider, Text } from 'elmo-elements';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlinedIcon, EditOutlinedIcon } from 'element';
import {
  awardsWithNamesSelector,
  BOX_USER_PROFILE_AWARDS_DELETE_MODAL_OPEN,
  BOX_USER_PROFILE_OPEN_AWARDS_MODAL,
} from '../../../../../../state/Users/UserProfile';
import moment from 'moment';
import { SERVER_DAY_FORMAT } from 'lib/config';
import { AwardItem } from 'type/models/user';
import AwardDetailsView from './components/AwardView/view';
import { currentUserMainTimezoneSelector } from 'state/Auth';

export const View = () => {
  const assignedAwards = useSelector(awardsWithNamesSelector);
  const currentTimeZone = useSelector(currentUserMainTimezoneSelector);
  const dispatch = useDispatch();
  const [expandedIndex, toggleState] = useState(-1);

  const openEditModal = (e: any, award: AwardItem) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      BOX_USER_PROFILE_OPEN_AWARDS_MODAL({
        awardId: award.award ? award.award.id : null,
        effectiveDate: award.effective_date,
        type: 'edit',
        id: award.id,
      })
    );
  };

  const openDeleteConfirmationModal = (e: any, award: AwardItem) => {
    e.stopPropagation();
    dispatch(BOX_USER_PROFILE_AWARDS_DELETE_MODAL_OPEN(award.id));
  };

  const toggleExpand = (index: number) => {
    toggleState(index === expandedIndex ? -1 : index);
  };

  const isCurrentlyActiveAward = (award: AwardItem) => {
    const currenDate = moment.tz(currentTimeZone);
    for (let i = 0; i < assignedAwards.length; i++) {
      if (award.id === assignedAwards[i].id) {
        const nextAward = assignedAwards[i + 1];
        const prevAward = assignedAwards[i - 1];
        const currentAward = assignedAwards[i];
        if (
          currenDate.format('YYYY-MM-DD') >=
          currentAward.effective_date.format('YYYY-MM-DD')
        ) {
          if (!nextAward && !prevAward) {
            return true;
          }
          if (nextAward) {
            if (
              currenDate.format('YYYY-MM-DD') <
              nextAward.effective_date.format('YYYY-MM-DD')
            ) {
              return true;
            }
          } else {
            return true;
          }
        }
      }
    }
    return false;
  };

  if (!assignedAwards.length) {
    return <div>User doesn't have any assigned award</div>;
  }

  return (
    <>
      <div className={'awards-list'}>
        <div className={'accordion-item accordion-header'}>
          <div className={'award-name'}>Award name</div>
          <div className={'award-description'}>Effective date</div>
          <div className={'accordion-item__actions'}>Actions</div>
        </div>
        {assignedAwards.map((award: AwardItem, index: number) => (
          <div
            className={
              'accordion-item' +
              (isCurrentlyActiveAward(award) ? ' current-award' : '')
            }
            key={index}
          >
            <div
              className={'accordion-item__title'}
              onClick={() => toggleExpand(index)}
            >
              <div className={'award-name'}>
                <span>{award.name}</span>
              </div>
              <div className={'award-description'}>
                {moment(award.effective_date).format(SERVER_DAY_FORMAT)}
              </div>
              <div className={'accordion-item__actions'}>
                <Button
                  icon={<EditOutlinedIcon />}
                  isText={true}
                  onClick={(e) => openEditModal(e, award)}
                />
                <Button
                  icon={<DeleteOutlinedIcon />}
                  isText={true}
                  onClick={(e) => openDeleteConfirmationModal(e, award)}
                />
              </div>
            </div>
            <div
              className={
                `accordion-item__content` +
                (expandedIndex === index ? ' is-expanded' : '')
              }
            >
              <AwardDetailsView award={award as any} />
            </div>
          </div>
        ))}
      </div>
      <Divider />
      <Text size={'sm'} color={'gray'}>
        <strong>*</strong> - currently active award
      </Text>
    </>
  );
};
