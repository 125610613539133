import React from 'react';
import clsx from 'clsx';
import { Theme } from 'extended-oxygen-elements';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { DefaultDivProps } from 'type';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      fontSize: theme.typography.body2.fontSize,
      lineHeight: 16 / 14,
      maxWidth: '90%',
      padding: '4px 0 1px',
      marginLeft: 7,
      overflow: 'hidden',
    },
    icon: {
      marginRight: 5,
    },
    title: {
      marginRight: 'auto',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    btnDelete: {},
  });

type StyleProps = WithStyles<typeof styles>;
type DivProps = Omit<DefaultDivProps, 'children'>;
type OwnProps = {
  icon?: React.ReactElement<{ className: string }>;
  title: string;
  secondaryAction?: React.ReactNode;
};
type ShiftCardEventRowTitleProps = StyleProps & DivProps & OwnProps;

export const ShiftCardEventRowTitle = withStyles(styles)(
  function ShiftCardEventRowTitle({
    classes,
    className,
    icon,
    title,
    secondaryAction,
    ...restProps
  }: ShiftCardEventRowTitleProps) {
    return (
      <div {...restProps} className={clsx(classes.root, className)}>
        {!!icon &&
          React.isValidElement(icon) &&
          React.cloneElement(icon, { className: classes.icon })}

        <div className={classes.title}>{title}</div>

        {secondaryAction}
      </div>
    );
  }
);
