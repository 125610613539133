import { Api } from 'lib/Api';
import browserHistory from 'lib/browserHistory';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { privateRoutes } from 'routes';
import { getAccountTreeRequest } from 'state/AccountTree';
import { formatError } from 'state/helpers';
import { apiWithConfirmCall } from 'state/ProcessApiRequest';
import { ApiWithConfirmReturnType, SagaAction } from 'type';
import {
  BOX_ACCOUNT_GEOFENCING_UPDATE_REQUEST,
  BOX_ACCOUNT_UPDATE_CANCEL,
  BOX_ACCOUNT_UPDATE_FAILURE,
  BOX_ACCOUNT_UPDATE_REQUEST,
  BOX_ACCOUNT_UPDATE_SUCCESS,
} from '../actions';
import { getDefaultBreaksRules, getIsDefaultBreaksApplied } from '../selectors';
import { UpdateAccountGeoFencingPayload, UpdateAccountPayload } from '../types';

export const updateAccount = function* (
  action: SagaAction<UpdateAccountPayload>
): SagaIterator {
  try {
    const { isDefaultBreaksEdited, ...rest } = action.payload;
    const isDefaultBreaks = yield select(getIsDefaultBreaksApplied);
    const breaksFromSettings = yield select(getDefaultBreaksRules);
    const response: ApiWithConfirmReturnType<typeof Api.Account.update> =
      yield apiWithConfirmCall(Api.Account.update, rest);

    if (response) {
      yield put(BOX_ACCOUNT_UPDATE_SUCCESS(response));
      browserHistory.push(privateRoutes.settings.routes.menu.path);
    } else {
      yield put(BOX_ACCOUNT_UPDATE_CANCEL());
    }
  } catch (error) {
    yield put(BOX_ACCOUNT_UPDATE_FAILURE(formatError(error)));
  }
};

export const updateGeoFencingSettings = function* (
  action: SagaAction<UpdateAccountGeoFencingPayload>
): SagaIterator {
  try {
    const response: ApiWithConfirmReturnType<typeof Api.Account.update> =
      yield apiWithConfirmCall(Api.Account.update, action.payload as any);

    if (response) {
      yield call(getAccountTreeRequest);
      yield put(BOX_ACCOUNT_UPDATE_SUCCESS(response));
      browserHistory.push(privateRoutes.settings.routes.menu.path);
    } else {
      yield put(BOX_ACCOUNT_UPDATE_CANCEL());
    }
  } catch (error) {
    yield put(BOX_ACCOUNT_UPDATE_FAILURE(formatError(error)));
  }
};

export default function* (): SagaIterator {
  yield takeLatest(BOX_ACCOUNT_UPDATE_REQUEST, updateAccount);
  yield takeLatest(
    BOX_ACCOUNT_GEOFENCING_UPDATE_REQUEST,
    updateGeoFencingSettings
  );
}
