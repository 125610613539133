import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import {
  BOX_USERS_ADD_ROLE_BULK_REQUEST,
  BOX_USERS_ROLE_BULK_MODAL_CLOSE
} from 'state/Users/Users';
import {
  RoleModal,
  RoleModalSubmitPayload
} from '../../../../components';

type StateProps = {
  isOpened: boolean;
  isUpdating: boolean;
  errors: string[];
};

type DispatchProps = {
  onCloseModal: () => void;
  onFormSubmit: (data: RoleModalSubmitPayload) => void;
};

type Props = StateProps & DispatchProps;

class UsersBulkAddRoleComponent extends Component<Props> {
  render() {
    return <RoleModal {...this.props} />;
  }
}

const mapStateToProps = ({
  user: {
    bulkAddRoleModal: { isOpened, isUpdating, errors }
  }
}: StoreState): StateProps => ({
  isOpened,
  isUpdating,
  errors
});

const mapDispatchToProps: DispatchProps = {
  onCloseModal: BOX_USERS_ROLE_BULK_MODAL_CLOSE,
  onFormSubmit: BOX_USERS_ADD_ROLE_BULK_REQUEST
};

export const UsersBulkAddRole = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersBulkAddRoleComponent);
