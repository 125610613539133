import { call } from 'redux-saga/effects';
import { ActionCreatorPayload, SagaAction } from 'type';
import { BOX_EMPLOYEE_DASHBOARD_SHIFT_DELETED } from 'state/EmployeeDashboard/EmployeeDashboard';
import {
  composeHandlers,
  pageNotFetchedHandler,
  shiftNotInStateHandler,
} from './handlers';
import { showOutDatedNotification } from './outdated-notification-handlers';

type ActionPayload = ActionCreatorPayload<
  typeof BOX_EMPLOYEE_DASHBOARD_SHIFT_DELETED
>;

const composedHandler = composeHandlers<ActionPayload>(
  pageNotFetchedHandler,
  shiftNotInStateHandler
)(showOutDatedNotification);

const handleShiftDeletion = function* ({ payload }: SagaAction<ActionPayload>) {
  yield call(composedHandler, payload);
};

export default handleShiftDeletion;
