import { RosterTimesheetListedWithPayEntries } from 'type';
import { useBulkApproveContext, useBulkDeleteContext } from 'contexts';
import { getIsTimesheetApproved } from 'lib/helpers';

type PartialTimesheet = Pick<
  RosterTimesheetListedWithPayEntries,
  'id' | 'end' | 'is_approved'
>;

const emptySelect = {
  selectable: false,
  selected: false,
  select: () => {},
};

export function useBulkSelect(props: PartialTimesheet) {
  const bulkDeleteSelect = useBulkDeleteSelect(props);
  const bulkApproveSelect = useBulkApproveSelect(props);

  return (
    [bulkDeleteSelect, bulkApproveSelect].find(
      ({ selectable }) => selectable
    ) || emptySelect
  );
}

function useBulkDeleteSelect({ id, end }: PartialTimesheet) {
  const { isEnabled, selectedIds, toggleId } = useBulkDeleteContext();

  const selectable = isEnabled && !!end;
  const selected = selectedIds.includes(id);

  const select = () => {
    if (!selectable) {
      return;
    }

    toggleId(id);
  };

  return {
    selectable,
    selected: selectable && selected,
    select,
  };
}

function useBulkApproveSelect(timesheet: PartialTimesheet) {
  const { isEnabled, selectedIds, toggleId } = useBulkApproveContext();
  const isApproved = getIsTimesheetApproved(timesheet);
  const { id, end } = timesheet;

  const selectable = isEnabled && !isApproved && !!end;
  const selected = selectedIds.includes(id);

  const select = () => {
    if (!selectable) {
      return;
    }

    toggleId(id);
  };

  return {
    selectable,
    selected: selectable && selected,
    select,
  };
}

export default useBulkSelect;
