import React from 'react';
import { InputAdornment, TextField, TextFieldProps } from 'extended-oxygen-elements';
import TextFieldProgressAdornment from './TextFieldProgressAdornment';

type Props = Pick<TextFieldProps, 'margin'>;

export default SkeletonTextField;

export function SkeletonTextField(props: Props) {
  return (
    <TextField
      {...props}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end">
            <TextFieldProgressAdornment />
          </InputAdornment>
        ),
      }}
    />
  );
}
