import { createReducer } from 'lib/store-utils';
import { EventsReducerState } from './types';
import { defaultFilters, getDefaultState } from './state';
import * as actions from './actions';
import { cloneDeep } from 'lodash';
import { emptyEvent } from '../../Roster/EventModal/state';

export const events = createReducer<EventsReducerState>({}, getDefaultState());

events.on(actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_DATA_REQUEST, getDefaultState);
events.on(actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_GET_EVENTS_SUCCESS,  (state,  { pager, data }) => ({
  ...state,
  events: data,
  pager
}));

events.on(
  actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_UPDATE_ORDERING_REQUEST,
  (state, { name, value }): EventsReducerState => ({
    ...state,
    isFetching: true,
    filters: {
      ...state.filters,
      [name]: value
    }
  })
);

events.on(
  actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_UPDATE_ORDERING_SUCCESS,
  (state, { data, pager }): EventsReducerState => ({
    ...state,
    isFetching: false,
    events: data,
    pager,
  })
);

events.on(
  actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_CHANGE_PAGE_REQUEST,
  (state): EventsReducerState => ({
    ...state,
    isFetching: true,
  })
);

events.on(
  actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_CHANGE_PAGE_SUCCESS,
  (state, { data, pager }): EventsReducerState => ({
    ...state,
    isFetching: false,
    events: data,
    pager
  })
);

events.on(
  actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_CHANGE_PAGE_SIZE_REQUEST,
  (state): EventsReducerState => ({
    ...state,
    isFetching: true,
  })
);

events.on(
  actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_CHANGE_PAGE_SIZE_SUCCESS,
  (state, { data, pager }): EventsReducerState => ({
    ...state,
    isFetching: false,
    events: data,
    pager
  })
);

events.on(
  actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_TOGGLE_PERIOD_TYPE_REQUEST,
  (state, period): EventsReducerState => ({
    ...state,
    isFetching: true,
    filters: {
      ...cloneDeep(defaultFilters)
    },
    period
  })
);

events.on(
  actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_TOGGLE_PERIOD_TYPE_SUCCESS,
  (state, { data, filters, pager }): EventsReducerState => ({
    ...state,
    isFetching: false,
    filters,
    pager,
    events: data,
  })
);

events.on(
  actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_TOGGLE_PERIOD_TYPE_FAILURE,
  (state, errors): EventsReducerState => ({
    ...state,
    isFetching: false,
    errors
  })
);

events.on(
  actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_UPDATE_FORM_FILTERS,
  (state,  { name, value }): EventsReducerState => ({
    ...state,
    isFetching: false,
    filtersForm: {
      ...state.filtersForm,
      [name]: value
    }
  })
);

events.on(
  actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_UPDATE_FILTERS_REQUEST,
  (state): EventsReducerState => ({
    ...state,
    isFetching: true,
    filters: {
      ...state.filtersForm,
    },
    period: 'all'
  })
);

events.on(
  actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_UPDATE_FILTERS_SUCCESS,
  (state, {data, filters, pager}): EventsReducerState => ({
    ...state,
    isFetching: false,
    filters,
    pager,
    events: data,
  })
);

events.on(
  actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_UPDATE_FILTERS_FAILURE,
  (state, payload): EventsReducerState => ({
    ...state,
    isFetching: false,
    errors: payload
  })
);
events.on(
  actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_RESET_FILTERS_FORM,
  (state): EventsReducerState => ({
    ...state,
    filtersForm: cloneDeep(defaultFilters)
  })
);

events.on(
  actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_OPEN_DETAILS_MODAL,
  (state, event): EventsReducerState => ({
    ...state,
    detailsModal: {
      isOpen: true,
      event
    }
  })
);

events.on(
  actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_CLOSE_DETAILS_MODAL,
  (state): EventsReducerState => ({
    ...state,
    detailsModal: {
      event: emptyEvent,
      isOpen: false,
    }
  })
);

events.on(
  actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_CHANGE_PAGE_SIZE_FAILURE,
  (state, errors): EventsReducerState => ({
    ...state,
    errors
  })
);

events.on(
  actions.BOX_EMPLOYEE_DASHBOARD_EVENTS_UPDATE_ORDERING_FAILED,
  (state, errors): EventsReducerState => ({
    ...state,
    errors
  })
);
