import { AlertErrorBoxLayout } from 'element';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BOX_PAY_CONDITIONS_PAGE_ERRORS_CLOSED,
  getPageErrors,
} from 'state/PayConditionsPage';

function PageErrors() {
  const dispatch = useDispatch();
  const errors = useSelector(getPageErrors);

  const handleErrorsClose = () => {
    dispatch(BOX_PAY_CONDITIONS_PAGE_ERRORS_CLOSED());
  };

  return (
    <AlertErrorBoxLayout
      BoxProps={{
        mb: 2,
      }}
      errors={errors}
      onClose={handleErrorsClose}
    />
  );
}

export default PageErrors;
