import React, { Component } from 'react';
import { FormItem, Radio, RadioGroup } from 'elmo-elements';
import { LanguagePreferences, UpdateFormPayload } from 'type';
import { capitalize, keyMirror } from 'lib/helpers';
import {
  CheckboxesCollection,
  FromToFilters,
  Select,
  SortDirectionRadioButtons,
  SortFieldRadioButtons,
} from 'element';
import {
  MyTimesheetFilters,
  MyTimesheetPeriod,
  MyTimesheetSortFields,
  MyTimesheetStatus,
} from '../../../../../../../../state/EmployeeDashboard/MyTimesheet/types';
import { SelectPropsOption } from 'elmo-elements/Select';
import { isAppMarket } from '../../../../../../../../helpers';

export type Props = {
  formData: MyTimesheetFilters;
  onChange: (payload: UpdateFormPayload) => void;
  onChangePeriod: (payload: UpdateFormPayload) => void;
  langPreferences: LanguagePreferences;
  currentUserSites: SelectPropsOption[];
};

export default class CustomFiltersView extends Component<Props> {
  readonly inputNames = keyMirror(this.props.formData);
  readonly sortFields: {
    label: string;
    value: MyTimesheetSortFields;
  }[] = [
    {
      label: 'Start',
      value: 'start'
    },
    {
      label: 'By hours',
      value: 'duration'
    }
  ];

  readonly statuses: {
    label: string;
    value: MyTimesheetStatus;
  }[] = [
    {
      label: 'Pending',
      value: 'pending'
    },
    {
      label: 'Approved',
      value: 'approved'
    }
  ];

  render() {
    const {
      inputNames,
      props: {
        formData,
        langPreferences,
        onChange,
        currentUserSites,
        onChangePeriod
      }
    } = this;
    return (
      <form>
        <FormItem label="Period" isDivider={true}>
          <RadioGroup
            selected={formData.period.period}
            onChange={this.onChangeRadio}
            direction="vertical"
          >
            {this.periods.map(({ label, value }) => (
              <Radio value={value} key={value}>
                {label}
              </Radio>
            ))}
          </RadioGroup>
        </FormItem>

        <FromToFilters
          label="Custom period"
          from={{ name: 'from', value: formData.period.from }}
          to={{ name: 'to', value: formData.period.to }}
          isDisabled={formData.period.period !== 'custom'}
          onChange={this.onChangeCustomPeriod}
        />

        <FormItem
          label={capitalize(langPreferences.site.singular)}
          isDivider={true}
        >
          <Select
            options={currentUserSites}
            isMulti={true}
            onChange={onChange}
            value={formData.siteIds}
            name={inputNames.siteIds}
            ariaLabel={capitalize(langPreferences.site.singular)}
          />
        </FormItem>

        <CheckboxesCollection
          formItem={{
            isDivider: true,
            label: 'Status'
          }}
          options={this.statuses}
          name={inputNames.status}
          value={formData.status}
          onChange={onChange}
          hideAllCheckbox={true}
        />

        <FormItem label="Sort by">
          <SortFieldRadioButtons
            selected={formData.sort.column}
            onChange={this.onChangeSortField}
            sortFields={this.sortFields}
          />
        </FormItem>

        <FormItem>
          <SortDirectionRadioButtons
            selected={formData.sort.direction}
            onChange={this.onChangeSortDirection}
          />
        </FormItem>
      </form>
    );
  }

  private onChangeCustomPeriod = (e: UpdateFormPayload) => {
    const {value, name} = e;
    this.props.onChangePeriod({
      name,
      value: value !== null ? value.startOf('day') : value
    });
  };

  private get periods(): {
    label: string;
    value: MyTimesheetPeriod;
  }[] {
    const { roster } = this.props.langPreferences;
    const rosterText = isAppMarket('uk') ? 'rota' : roster.singular;

    return [
      {
        label: 'Last 7 days',
        value: 'last_7_days'
      },
      {
        label: `Current ${rosterText}`,
        value: 'current_roster'
      },
      {
        label: `Last ${rosterText}`,
        value: 'last_roster'
      },
      {
        label: 'Last 6 months',
        value: 'last_6_months'
      },
      {
        label: 'Custom period',
        value: 'custom'
      }
    ];
  }

  private onChangeRadio = (value: string | number) => {
    const { formData, onChangePeriod } = this.props;
    const { period } = this.inputNames;

    if (
      formData.period.period === 'custom' &&
      (value as MyTimesheetPeriod) !== 'custom'
    ) {
      onChangePeriod({
        name: 'from',
        value: null
      });
      onChangePeriod({
        name: 'to',
        value: null
      });
    }

    onChangePeriod({
      name: period,
      value
    });
  };

  private onChangeSortField = (column: string | number) => {
    this.props.onChange({
      name: this.inputNames.sort,
      value: {
        column,
        direction: this.props.formData.sort.direction
      }
    });
  };

  private onChangeSortDirection = (direction: string | number) => {
    this.props.onChange({
      name: this.inputNames.sort,
      value: {
        column: this.props.formData.sort.column,
        direction
      }
    });
  };
}
