import { RosterEventsLegend as RosterEventsLegendTile } from './RosterEventsLegend';
import React from 'react';
import { Text } from 'elmo-elements';
import { CalendarIcon } from 'element';
import { WithEventsDurations } from './components/WithEventsDurations';
import { LanguagePreferences } from '../../../../type/models';
import { connect } from 'react-redux';
import { StoreState } from '../../../../state/types';
import { getLangPreferences } from '../../../../state/Account';
import { capitalize } from 'lib/helpers';

type OwnProps = {
  onClick?: () => void;
  toggleRow?: () => void;
  isCollapsable?: boolean;
  pageType?: 'week' | 'day';
};

type StateProps = {
  langPreferences: LanguagePreferences;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

export const RosterEventsLegendComponent = ({
  langPreferences,
  onClick,
  toggleRow,
  isCollapsable,
  pageType,
}: Props) => {
  return (
    <WithEventsDurations>
      {(durations: any) => (
        <RosterEventsLegendTile
          type="multi-row"
          label={`${capitalize(langPreferences.event.plural)} & Forecasts`}
          icon={
            <Text color={'secondary'}>
              <CalendarIcon />
            </Text>
          }
          durations={durations}
          onClick={!pageType || pageType === 'day' ? onClick : undefined}
          toggleRow={isCollapsable ? toggleRow : undefined}
        />
      )}
    </WithEventsDurations>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  langPreferences: getLangPreferences(state),
});

const mapDispatchToProps: DispatchProps = {};

export const RosterEventsLegend = connect(
  mapStateToProps,
  mapDispatchToProps
)(RosterEventsLegendComponent);
