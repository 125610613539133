import moment from 'moment';
import {
  Fields,
  FormattedErrors,
  Pager,
  Report,
  ReportScheduleDay,
  SortReports,
  StringMap,
} from 'type';
import { PAGE_SIZE } from 'lib/config';

export type ReportsReducerState = {
  isFetching: boolean;
  isLoading: boolean;
  errors: FormattedErrors;
  reports: StringMap<Report>;
  fields: StringMap<Fields>;
  searchTerm: string;
  sort: SortReports;
  pager: Pager;
  deleteQueue: string[];
  saveAsModal: {
    isOpened: boolean;
    errors: FormattedErrors;
    report: Report;
    title: string;
  };
  scheduleModal: {
    isOpened: boolean;
    errors: FormattedErrors;
    report: Report;
  };
  createModal: {
    isOpened: boolean;
    redirectOnSuccess: boolean;
  };
};

const today = moment();

const defaultReport: Report = {
  id: '1',
  account_id: '321',
  type: 'rostered_shift',
  name: '',
  period: {
    period: 'custom',
    from: '2019-03-01 07:54:21',
    to: '2019-03-01 07:54:21',
  },
  filters: {
    role_ids: [],
    site_ids: [],
    tag_ids: [],
    user_ids: [],
    area_ids: [],
    tag_type: 'user',
  },
  extra: {
    group_by: 'date',
    order_by: 'area_name',
    order_dir: 'asc',
    details: false,
    show_self_assigned: false,
    time_format: 'decimal',
    show_approved_shifts: false,
    show_approved_timesheets: false,
    detailed_breaks: false,
    show: '',
    date_field_direction: '',
    include: '',
    date_field_start: '',
    interval: '',
    datatype: '',
    shifttype: '',
    date_field_anniversary: false,
    date_field: '',
    archived: '',
    show_inactive_users: true,
  },
  fields: [],
  schedule: {
    is_enabled: false,
    schedule: {
      period: 'daily',
      day: [today.format('E') as ReportScheduleDay],
    },
    time: today,
    format: 'csv',
    from: today,
    to: today,
    recipients: [],
    sub_format: null,
  },
  updated_at: today,
  created_at: today,
};

export const getDefaultState = (): ReportsReducerState => ({
  isFetching: false,
  isLoading: false,
  errors: [],
  reports: {},
  fields: {},
  sort: {
    direction: 'asc',
    column: 'name',
  },
  searchTerm: '',
  deleteQueue: [],
  pager: {
    pageSize: PAGE_SIZE[0],
    currentPage: 1,
  },
  saveAsModal: {
    isOpened: false,
    errors: [],
    report: defaultReport,
    title: 'Save report as',
  },
  scheduleModal: {
    isOpened: false,
    errors: [],
    report: defaultReport,
  },
  createModal: {
    isOpened: false,
    redirectOnSuccess: false,
  },
});
