import { DateType } from 'type';
import { StartEndErrors } from '../types';

export default startEndFieldsValidator;

export function startEndFieldsValidator(formFields: {
  startDate: DateType | null;
  startTime: number | null;
  endDate: DateType | null;
  endTime: number | null;
}): StartEndErrors {
  let errors: StartEndErrors = {};

  if (!formFields.startDate) {
    errors.startDate = 'Timesheet start date is required';
  }

  if (formFields.startTime === null) {
    errors.startTime = 'Timesheet start time is required';
  }

  if (!formFields.endDate) {
    errors.endDate = 'Timesheet end date is required';
  }

  if (formFields.endTime === null) {
    errors.endTime = 'Timesheet end time is required';
  }

  return errors;
}
