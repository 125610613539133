import React from 'react';
import LoaderHolder from './LoaderHolder';
import { LoaderHolderProps } from '../type';
import './MenuStyle.scss';

function MenuStyle(props: LoaderHolderProps) {
  return (
    <LoaderHolder
      viewBoxHeight={350}
      viewBoxWidth={250}
      className="elmo-loader-menu"
      {...props}
    >
      <rect x="60" y="15" rx="4" ry="4" width="150" height="20" />
      <rect x="60" y="45" rx="3" ry="3" width="85" height="20" />
      <circle cx="30" cy="40" r="25" />

      <circle cx="24" cy="125" r="12" />
      <rect x="50" y="115" rx="5" ry="5" width="180" height="20" />
      <circle cx="24" cy="170" r="12" />
      <rect x="50" y="160" rx="5" ry="5" width="180" height="20" />
      <circle cx="24" cy="215" r="12" />
      <rect x="50" y="205" rx="5" ry="5" width="180" height="20" />
      <circle cx="24" cy="260" r="12" />
      <rect x="50" y="250" rx="5" ry="5" width="180" height="20" />
      <circle cx="24" cy="305" r="12" />
      <rect x="50" y="295" rx="5" ry="5" width="180" height="20" />
    </LoaderHolder>
  );
}

export default MenuStyle;
