import { EventType, RosteredShift, SagaAction } from '../../../type';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { Api } from 'lib/Api';
import { formatError } from 'state/helpers';
import { GetEventByIdPayload } from './types';
import * as actions from './actions';
import { Event } from 'type/models';
import { emptyEvent } from './state';
import { RosteredShiftsGetPayload } from '../../RosteredShifts/types';
import moment, { Moment } from 'moment';
import {
  BOX_EVENTS_DELETE_ONE,
  BOX_EVENTS_UPDATED,
} from '../../Events/actions';
import {
  EventCreateRequest,
  EventDeleteRequest,
  EventUpdateRequest,
  GetEventByIdRequest,
  RosteredShiftGetListResponse,
} from 'lib/Api/type';
import { getFrom, getSiteId, getTo } from '../../RosteredShifts';
import { getRosteredShiftsListRequest } from 'state/RosteredShifts';
import {
  processApiRequest,
  processApiRequestWithConfirm,
} from 'state/ProcessApiRequest';
import { BOX_SUMMARY_GET_DATA_ON_ROSTER_UPDATES } from '../Summary/actions';
import { getChannelIsActive } from '../../Sockets';
import {
  BOX_IS_LOADING_OFF,
  BOX_IS_LOADING_ON,
  ROSTER_CREATE_UPDATE_EVENT_MODAL,
} from '../../IsLoading';
import { getEventModalShifts } from './selectors';

const getEventShifts = function* (
  action: SagaAction<RosteredShiftsGetPayload>
): SagaIterator {
  try {
    const { payload } = action;
    const rosteredShifts: RosteredShiftGetListResponse = yield call(
      processApiRequest,
      Api.RosteredShift.getList,
      { ...payload, is_current_published: 1 }
    );
    yield put(actions.BOX_EVENT_MODAL_GET_SHIFTS_SUCCESS(rosteredShifts));
  } catch (error) {
    yield put(actions.BOX_EVENT_MODAL_GET_SHIFTS_FAILURE(formatError(error)));
  }
};

export const getEventById = function*(
  action: SagaAction<GetEventByIdPayload>
): SagaIterator {
  if (action.payload.id === undefined) {
    const event: Event = {
      ...emptyEvent,
      site_id: action.payload.siteId as string,
      start: action.payload.start as Moment,
      end: action.payload.end as Moment,
      type: action.payload.type as EventType || 'event'
    };
    yield put(actions.BOX_EVENT_MODAL_SET_EVENT(event));
  } else {
    try {
      const [eventResponse]: [Event] = yield all([
        call(
          processApiRequest,
          Api.Events.getById,
          action.payload
        )
      ]);

      const {start, end, site_id} = eventResponse;
      yield put(actions.BOX_EVENT_MODAL_GET_SHIFTS_REQUEST({
        from: start,
        to: end,
        site_id
      }));

      yield put(actions.BOX_EVENT_MODAL_GET_BY_ID_SUCCESS(eventResponse));
    } catch (error) {
      yield put(actions.BOX_EVENT_MODAL_GET_BY_ID_FAILURE(formatError(error)));
    }
  }
};

const customFieldFile = function*({
  payload
}: SagaAction<any>): SagaIterator {
  try {
    const response: any = yield call(processApiRequest, Api.File.upload, payload.data);
    yield put(
      actions.BOX_EVENT_MODAL_CUSTOM_FILE_SUCCESS({
        name: payload.name,
        data: response,
        original_filename: payload.original_filename
      })
    );

  } catch (error) {
    yield put(
      actions.BOX_EVENT_MODAL_CUSTOM_FILE_FAILURE({
        name: payload.name,
        data: formatError(error)
      })
    );
  }
};

export const getEventRelatedData = function*(payload: {
  site_id: string,
  from: Moment,
  to: Moment
}) {

  const isChannelActive = yield select(getChannelIsActive, 'roster_week');
  if ( !isChannelActive ) {
    yield call(getRosteredShiftsListRequest, payload);
  }

  yield put(BOX_SUMMARY_GET_DATA_ON_ROSTER_UPDATES());
};

export const createEvent = function*(
  action: SagaAction<EventCreateRequest>
): SagaIterator {
  yield put(BOX_IS_LOADING_ON(ROSTER_CREATE_UPDATE_EVENT_MODAL));
  try {
    const event: Event = yield call(processApiRequestWithConfirm, Api.Events.create, action.payload);

    yield put(actions.BOX_EVENT_MODAL_CREATE_EVENT_SUCCESS());
    yield put(BOX_EVENTS_UPDATED(event));

    const siteId = yield select(getSiteId);
    const from = yield select(getFrom);
    const to = yield select(getTo);

    yield call(getEventRelatedData, {
      site_id: siteId,
      from: moment(from),
      to: moment(to)
    });

    if ( action.payload.rostered_shift_ids.length  ) {
      let unassignedShift: null | RosteredShift = null;
      const shifts = yield select(getEventModalShifts);
      const ids = Object.keys(shifts);
      if ( ids && ids.length ) {
        for (let id of ids) {
          for (let shiftId of action.payload.rostered_shift_ids) {
            if ( shifts[shiftId] && shifts[shiftId].user_id === null ) {
              unassignedShift = shifts[shiftId];
              break;
            }
          }
          if ( unassignedShift ) {
            break;
          }
        }
      }
    }

  } catch (error) {
    yield put(actions.BOX_EVENT_MODAL_CREATE_EVENT_FAILURE(formatError(error)));
  }
  yield put(BOX_IS_LOADING_OFF(ROSTER_CREATE_UPDATE_EVENT_MODAL));
};

export const updateEvent = function*(
  action: SagaAction<EventUpdateRequest>
): SagaIterator {
  yield put(BOX_IS_LOADING_ON(ROSTER_CREATE_UPDATE_EVENT_MODAL));
  try {
    const event: Event = yield call(processApiRequestWithConfirm, Api.Events.update, action.payload);
    yield put(actions.BOX_EVENT_MODAL_UPDATE_EVENT_SUCCESS());
    yield put(BOX_EVENTS_UPDATED(event));

    const siteId = yield select(getSiteId);
    const from = yield select(getFrom);
    const to = yield select(getTo);

    const isDashboard = yield select(getChannelIsActive, 'manager_dashboard');

    if ( siteId && !isDashboard) {
      yield call(getEventRelatedData, {
        site_id: siteId,
        from: moment(from),
        to: moment(to)
      });
    }

  } catch (error) {
    yield put(actions.BOX_EVENT_MODAL_UPDATE_EVENT_FAILURE(formatError(error)));
  }
  yield put(BOX_IS_LOADING_OFF(ROSTER_CREATE_UPDATE_EVENT_MODAL));
};

export const reFetchEventRelatedData = function*(event: Event | null): SagaIterator {
  const siteId =  yield select(getSiteId);
  const from = yield select(getFrom);
  const to = yield select(getTo);

  if ( siteId ) {
    yield call(getEventRelatedData, {
      site_id: siteId,
      from: moment(from),
      to: moment(to)
    });
  }
};

export const deleteEvent = function*(
  action: SagaAction<EventDeleteRequest>
): SagaIterator {
  try {
    const event: Event = yield select(getEventById as any, action.payload.id);  // TODO double-check
    yield call(processApiRequest, Api.Events.delete, action.payload);
    yield put(actions.BOX_EVENT_MODAL_REMOVE_EVENT_SUCCESS());
    const id: any = action.payload;
    yield put(BOX_EVENTS_DELETE_ONE(id));
    yield call(reFetchEventRelatedData, event);
  } catch (error) {
    yield put(actions.BOX_EVENT_MODAL_REMOVE_EVENT_FAILURE(formatError(error)));
  }
};

export const syncEventForShift = function*(
  payload: GetEventByIdRequest
): SagaIterator {
  try {
    const event: Event = yield call(processApiRequest, Api.Events.getById, payload);
    yield put(BOX_EVENTS_UPDATED(event));
  } catch (error) {
    console.log(error);
  }
};

export const watchEventModal = function*(): SagaIterator {
  yield takeLatest(actions.BOX_EVENT_MODAL_OPEN, getEventById);
  yield takeLatest(actions.BOX_EVENT_MODAL_GET_SHIFTS_REQUEST, getEventShifts);
  yield takeLatest(actions.BOX_EVENT_MODAL_CUSTOM_FILE_REQUEST, customFieldFile);
  yield takeLatest(actions.BOX_EVENT_MODAL_CREATE_EVENT, createEvent);
  yield takeLatest(actions.BOX_EVENT_MODAL_UPDATE_EVENT, updateEvent);
  yield takeLatest(actions.BOX_EVENT_MODAL_REMOVE_EVENT_REQUEST as any, deleteEvent); // TODO double-check
};
