import { useMemo } from 'react';
import { Timesheet } from 'type';
import { ShiftCardViewProps } from '../../views';

export const useIsLocationShown = ({
  rostered_shift_id,
  rostered_shift,
}: Timesheet): ShiftCardViewProps['isLocationShown'] =>
  useMemo(
    () => !(rostered_shift_id && rostered_shift),
    [rostered_shift_id, rostered_shift]
  );
