import {
  DateRangeIcon,
  EventIcon,
  HistoryIcon,
  Layout,
  NewHeader,
  NewHeaderTitle,
  NewHeaderWrapper,
  NotificationsOutlinedIcon,
  PayrollIcon,
  PeopleOutlinedIcon,
  PersonPinCircleOutlinedIcon,
  SecurityIcon,
  TimerIcon,
  TableChartOutlinedIcon,
} from 'element';
import { Col, Row } from 'elmo-elements';
import { useSelectorWithProps } from 'hooks';
import { capitalize } from 'lib/helpers';
import _ from 'lodash';
import marketLabels from 'marketLabels';
import React from 'react';
import { privateRoutes } from 'routes';
import { getLangPreferences } from 'state/Account';
import { hasPermissionSelector } from 'state/Auth';
import MenuButton from './MenuButton';
import { MenuItem } from './types';
import { getFlag } from 'state/Flags';
import { BorderHorizontalOutlined } from '@mui/icons-material';

export default function Menu() {
  const hasAuditTrailPermission = useSelectorWithProps(
    hasPermissionSelector,
    'account.settings.audittrail'
  );
  const hasUserGroupsPermission = useSelectorWithProps(
    hasPermissionSelector,
    'account.settings.usergroups'
  );
  const hasNotificationsPermission = useSelectorWithProps(
    hasPermissionSelector,
    'account.settings.notifications'
  );
  const hasAccountTreePermissions = useSelectorWithProps(
    hasPermissionSelector,
    'account.settings.accounttree'
  );
  const isPayElementsFlagEnabled = useSelectorWithProps(getFlag.payElements);
  const langPreferences = useSelectorWithProps(getLangPreferences);

  const menu: MenuItem[][] = [
    _.compact([
      {
        to: privateRoutes.settings.routes.rosterSettings.path,
        label: `${capitalize(marketLabels.roster)} Settings`,
        icon: <DateRangeIcon />,
      },
      {
        to: privateRoutes.settings.routes.timesheet.path,
        label: 'Timesheet',
        icon: <TimerIcon />,
      },
      {
        to: privateRoutes.settings.routes.punchclockSettings.path,
        label: 'Time Clock Settings',
        icon: <SecurityIcon />,
      },
      {
        to: privateRoutes.settings.routes.events.path,
        label: capitalize(langPreferences.event.plural),
        icon: <EventIcon />,
      },
      isPayElementsFlagEnabled && {
        to: privateRoutes.settings.routes.pay.path,
        label: 'Pay Settings',
        icon: <PayrollIcon />,
      },
      {
        to: privateRoutes.settings.routes.accountPreferences.path,
        label: 'Account Preferences',
        icon: <BorderHorizontalOutlined />,
      },
    ]),
    _.compact([
      hasUserGroupsPermission && {
        to: privateRoutes.settings.routes.userGroups.path,
        label: 'Permissions',
        icon: <PeopleOutlinedIcon />,
      },
      hasNotificationsPermission && {
        to: privateRoutes.settings.routes.notifications.path,
        label: 'Notifications',
        icon: <NotificationsOutlinedIcon />,
      },
      hasAuditTrailPermission && {
        to: privateRoutes.settings.routes.auditTrail.path,
        label: 'Audit Trail',
        icon: <HistoryIcon />,
      },
      {
        to: privateRoutes.settings.routes.geoFencing.path,
        label: 'GeoFencing',
        icon: <PersonPinCircleOutlinedIcon />,
      },
      hasAccountTreePermissions && {
        to: privateRoutes.settings.routes.accountTree.path,
        label: 'Account Tree',
        icon: <TableChartOutlinedIcon />,
      },
    ]),
  ];

  return (
    <>
      <Layout.Header>
        <NewHeaderWrapper>
          <NewHeader>
            <NewHeaderTitle>Settings</NewHeaderTitle>
          </NewHeader>
        </NewHeaderWrapper>
      </Layout.Header>

      <Layout.Content>
        <Row role="presentation">
          {menu.map((col, colIndex) => {
            if (!col.length) {
              return <></>;
            }

            return (
              <Col key={colIndex} span={24} sm={12} role="presentation">
                {col.map((menuItem, menuItemIndex) => (
                  <MenuButton {...menuItem} key={menuItemIndex} />
                ))}
              </Col>
            );
          })}
        </Row>
      </Layout.Content>
    </>
  );
}
