import React from 'react';
import { Box, Button } from 'extended-oxygen-elements';
import { createStyles, makeStyles } from '@mui/styles';

type Props = {
  onClose: () => void;
  actions: {
    onClick: () => void;
    label: string;
    isDisabled: boolean;
    icon: JSX.Element;
  }[];
};

const useBulkActionsStyles = makeStyles((theme) =>
  createStyles({
    root: {
      background: 'white',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0',
      [theme.breakpoints.up(600)]: {
        padding: '0 44px 0 38px',
      },
    },
    inner: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.up(600)]: {
        marginLeft: '-5px',
      },
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '5px',
      [theme.breakpoints.down(600)]: {
        paddingLeft: '0',
        paddingRight: '0',
        minWidth: '48px',
        justifyContent: 'center',
        textAlign: 'center',
        marginRight: '15px',
      },
    },
    icon: {
      display: 'inline-block',
      [theme.breakpoints.up(600)]: {
        marginRight: '10px',
      },
    },
    label: {
      display: 'none',
      [theme.breakpoints.up(600)]: {
        display: 'inline',
      },
    },
  })
);

export const BulkActions = ({ onClose, actions }: Props) => {
  const styles = useBulkActionsStyles();
  return (
    <Box
      id="bulk-actions-wrapper"
      data-testid="bulk-actions-wrapper"
      className={styles.root}
    >
      <Box className={styles.inner}>
        {actions.map((action) => (
          <Button
            key={`key-${action.label}`}
            onClick={action.onClick}
            disabled={action.isDisabled}
            className={styles.button}
          >
            <span className={styles.icon}>{action.icon}</span>
            <span className={styles.label}>{action.label}</span>
          </Button>
        ))}
      </Box>
      <Button onClick={onClose} className="bulk-actions-close-btn">
        Cancel
      </Button>
    </Box>
  );
};
