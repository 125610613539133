import { useSelector } from 'react-redux';
import { getIsBulkDeleteOpened } from 'state/Roster/BulkActions';
import { getIsCovidModeEnabled } from 'state/Roster/CovidTracing';
import { ShiftCardViewProps } from '../../views';
import { useHandleClickDeleteIcon as useRosterClickDeleteIcon } from '../useRosterCard';

type Props = {
  id: string;
};
const useHandleClickDeleteIcon = (
  props: Props
): ShiftCardViewProps['onClickDeleteIcon'] => {
  const handleClickDeleteIcon = useRosterClickDeleteIcon(props);

  const isBulkDelete = useSelector(getIsBulkDeleteOpened);
  const isCovidMode = useSelector(getIsCovidModeEnabled);

  const canDeleteShift = !isBulkDelete && !isCovidMode;

  if (canDeleteShift) {
    return handleClickDeleteIcon;
  }
};

export default useHandleClickDeleteIcon;
