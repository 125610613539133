import { createSelector } from 'reselect';
import { intersection, includes } from 'lodash';
import fp from 'lodash/fp';
import { StringMap, UserFields, UserRole } from '../../../../../type';
import { StoreState } from '../../../../types';
import { getInstanceCombiner, idsCombiner } from '../../../../combiners';
import { getKeysWithTrueValues } from '../../../../helpers';
import { UserFiltersActivity } from '../../types';
import { returnAllIfEmptyFilters } from '../../helpers';
import {
  getUserFiltersActivity,
  getUserFiltersArea,
  getUserFiltersRole,
  getUserFiltersTag,
  getUserFiltersUserGroup
} from '../index';
import {
  activeUserIdsSelector,
  archivedUserIdsSelector,
  userListSelector
} from '../../../../UsersCollection';

const filteredUserIdsByActivitySelector = createSelector<
  StoreState,
  string[],
  string[],
  UserFiltersActivity,
  string[]
>(
  activeUserIdsSelector,
  archivedUserIdsSelector,
  getUserFiltersActivity,
  (active, archived, filtersActivity) => {
    type Keys = keyof UserFiltersActivity;
    type Config = { [key in Keys]: string[] };

    const config: Config = {
      active,
      archived
    };

    const activityFilter = fp.flow([
      getKeysWithTrueValues,
      fp.map(key => config[key as Keys]),
      fp.flatten
    ]);

    return activityFilter(filtersActivity);
  }
);

const filteredUserIdsByRoleSelector = createSelector<
  StoreState,
  StringMap<UserFields>,
  string[],
  string[]
>(userListSelector, getUserFiltersRole, (users, filterIds) => {
  const filteredUsers: UserFields[] = returnAllIfEmptyFilters(
    users,
    filterIds,
    (user: UserFields) => {
      const allRoleIdsOfUser = user.user_roles.map(
        (userRole: UserRole) => userRole.role_id
      );

      return !!intersection(filterIds, allRoleIdsOfUser).length;
    }
  );

  return idsCombiner(filteredUsers);
});

const filteredUserIdsByAreaSelector = createSelector<
  StoreState,
  StringMap<UserFields>,
  string[],
  string[]
>(userListSelector, getUserFiltersArea, (users, filterIds) => {
  const filteredUsers: UserFields[] = returnAllIfEmptyFilters(
    users,
    filterIds,
    (user: UserFields) => {
      const allAreaIdsOfUser = user.user_roles.map(role => role.area_id);
      return !!intersection(filterIds, allAreaIdsOfUser).length;
    }
  );

  return idsCombiner(filteredUsers);
});

const filteredUserIdsByUserGroupSelector = createSelector<
  StoreState,
  StringMap<UserFields>,
  string[],
  string[]
>(userListSelector, getUserFiltersUserGroup, (users, filterIds) => {
  const filteredUsers: UserFields[] = returnAllIfEmptyFilters(
    users,
    filterIds,
    (user: UserFields) => includes(filterIds, user.user_group_id)
  );

  return idsCombiner(filteredUsers);
});

const filteredUserIdsByTagSelector = createSelector<
  StoreState,
  StringMap<UserFields>,
  string[],
  string[]
>(userListSelector, getUserFiltersTag, (users, filterIds) => {
  const filteredUsers: UserFields[] = returnAllIfEmptyFilters(
    users,
    filterIds,
    (user: UserFields) => !!intersection(user.tags, filterIds).length
  );

  return idsCombiner(filteredUsers);
});

const filteredUserIdsSelector = createSelector(
  filteredUserIdsByActivitySelector,
  filteredUserIdsByRoleSelector,
  filteredUserIdsByAreaSelector,
  filteredUserIdsByUserGroupSelector,
  filteredUserIdsByTagSelector,
  intersection
);

export const filteredUsersSelector = createSelector<
  StoreState,
  string[],
  StringMap<UserFields>,
  UserFields[]
>(filteredUserIdsSelector, userListSelector, getInstanceCombiner);
