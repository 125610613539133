import { StoreState } from '../types';
import _ from 'lodash';
import moment from 'moment';

export const getIsNotificationShown = (
  state: StoreState,
  notificationId: string
) => _.find(state.toastNotifier.notifications, (n) => n.id === notificationId);

export const getTotalNumber = (state: StoreState) =>
  state.toastNotifier.notifications.length;

export const getPrevNotificationTime = (state: StoreState) => {
  if ( state.toastNotifier.notifications.length ) {
    return state.toastNotifier.notifications[state.toastNotifier.notifications.length - 1].availableTo;
  }
  return moment();
};
