import React from 'react';
import { IconButtonProps } from 'extended-oxygen-elements';
import { CheckBoxOutlinedIcon } from 'element';
import { IconWrapper } from './components';

export function CheckedIcon(props: IconButtonProps) {
  return (
    <IconWrapper {...props}>
      <CheckBoxOutlinedIcon />
    </IconWrapper>
  );
}
