import React from 'react';
import { getClass } from '../_lib/helper';
import { DropdownBase } from '../DropdownBase';
import { DropdownProps } from './type';
import { DropdownButton } from './components';
import './Dropdown.scss';

export const Dropdown = (props: DropdownProps) => (
  <DropdownBase
    {...props}
    className={getClass('elmo-dropdown', props.className, {
      block: props.isButtonBlock,
      'full-width': props.isFullWidth,
      inline: props.isInline,
    })}
    renderTrigger={(triggerProps) => (
      <DropdownButton
        isOpenDropdown={triggerProps.isOpen}
        toggleMenu={triggerProps.toggleMenu}
        id={props.id}
        ariaLabel={props.ariaLabel}
        text={props.text}
        icon={props.icon}
        isDisabled={props.isDisabled}
        isBlock={props.isButtonBlock}
        isFullWidth={props.isFullWidth}
        isBordered={props.isBordered}
        hasTooltip={props.hasTooltip}
      />
    )}
  />
);

Dropdown.Item = DropdownBase.Item;
Dropdown.Divider = DropdownBase.Divider;
Dropdown.Label = DropdownBase.Label;
