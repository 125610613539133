import React from 'react';
import { Card, Col, NativeTable, Paragraph, Row, Text } from 'elmo-elements';
import { StoreState } from 'state/types';
import {
  AccountTreeArea,
  AccountTreeSite,
  FormattedErrors,
  LanguagePreferences,
  UserProfileFields,
  UserRole,
} from 'type/models';
import { connect } from 'react-redux';
import { capitalize, getRoleName } from 'lib/helpers';
import {
  BOX_USER_PROFILE_PAYMENT_MODAL_CLOSE,
  BOX_USER_PROFILE_PAYMENT_MODAL_OPEN,
  BOX_USER_PROFILE_REMOVE_ERRORS,
  BOX_USER_PROFILE_UPDATE_REQUEST,
} from 'state/Users/UserProfile/actions';
import { AccountTreeSelector } from 'type';
import { EditButton } from '../EditButton';
import { getLangPreferences } from '../../../../../../../../state/Account';
import { getAccountTree } from '../../../../../../../../state/AccountTree';
import {
  getErrors,
  getIsOpened,
  getUserProfile,
  userRolesAccessibleByCurrentUserSelector,
} from 'state/Users/UserProfile';
import { FormattedDate, FormattedNumber } from 'element';
import moment from 'moment';
import { map } from 'lodash';
import PaymentModalWithCostCentre from './components/PaymentModalWithCostCentre';

type StateProps = {
  userProfile: UserProfileFields;
  isOpened: boolean;
  errors: FormattedErrors;
  tree: AccountTreeSelector;
  langPreferences: LanguagePreferences;
  availableRoles: UserRole[];
};

type DispatchProps = {
  closeModal: () => void;
  openModal: () => void;
  updateProfile: (params: any) => void;
  removeErrors: () => void;
};

type Props = DispatchProps & StateProps;

export class PaymentDetailsComponent extends React.Component<Props> {
  render() {
    const {
      userProfile,
      updateProfile,
      openModal,
      isOpened,
      errors,
      tree,
      removeErrors,
      closeModal,
      langPreferences,
      availableRoles
    } = this.props;

    const {
      shift_worker,
      employment_type,
      start_date,
      roster_period,
      end_date,
      user_roles,
      rate
    } = userProfile;

    return (
      <Card
        isFullHeight={true}
        heading="Payment details"
        option={this.props.userProfile.is_active && <EditButton onClick={openModal} ariaLabel="Edit" />}
        role="grid"
        id={'payment-details-card'}
      >
        <Row>
          <Col xs={12} span={24}>
            <Text size={'sm'}>Cost centre</Text>
            <Paragraph className={'cost-centre'}>{this.costCentre}</Paragraph>
            <br />
            <Text size={'sm'}>Employment type</Text>
            <Paragraph className={'employment-type'}>
              {employment_type}
            </Paragraph>
            <br />
            <Text size={'sm'}>Employment start date</Text>
            <Paragraph className={'start-date'}>
              {start_date ? (
                <FormattedDate>{moment.parseZone(start_date)}</FormattedDate>
              ) : (
                '-'
              )}
            </Paragraph>
          </Col>
          <Col xs={12} span={24}>
            <Text size={'sm'}>
              {capitalize(langPreferences.roster.singular)} period
            </Text>
            <Paragraph className={'roster-period'}>{roster_period}</Paragraph>
            <br />
            <Text size={'sm'}>Shift worker</Text>
            <Paragraph className={'shift-worker'}>
              {shift_worker ? 'Yes' : 'No'}
            </Paragraph>
            <br />
            <Text size={'sm'}>Employment end date</Text>
            <Paragraph className={'end-date'}>
              {end_date ? (
                <FormattedDate>{moment.parseZone(end_date)}</FormattedDate>
              ) : (
                '-'
              )}
            </Paragraph>
          </Col>
        </Row>
        {!!user_roles && !!user_roles.length && (
          <NativeTable isFullWidth={true}>
            <NativeTable.Header>
              <NativeTable.Tr>
                <NativeTable.Th width={'70%'}>
                  <Text size={'sm'}>
                    {capitalize(langPreferences.role.singular)}
                  </Text>
                </NativeTable.Th>
                <NativeTable.Th width={'30%'} textAlign={'right'}>
                  <Text size={'sm'}>Base rate</Text>
                </NativeTable.Th>
              </NativeTable.Tr>
            </NativeTable.Header>
            <NativeTable.Body>
              {map(user_roles, (userRole: UserRole) => (
                <NativeTable.Tr
                  key={userRole.role_id + userRole.area_id + userRole.site_id}
                >
                  <NativeTable.Td>
                    <Paragraph>
                      {getRoleName(userRole, tree.roles, 'role')}
                      {userRole.is_main && <Text size={'sm'}> (Primary)</Text>}
                    </Paragraph>
                  </NativeTable.Td>
                  <NativeTable.Td textAlign={'right'}>
                    <Paragraph>
                      {userRole.is_main ? (
                        <FormattedNumber>{parseFloat(rate)}</FormattedNumber>
                      ) : (
                        'n/a'
                      )}
                    </Paragraph>
                  </NativeTable.Td>
                </NativeTable.Tr>
              ))}
            </NativeTable.Body>
          </NativeTable>
        )}
        <PaymentModalWithCostCentre
          isOpened={isOpened}
          closeModal={closeModal}
          userProfile={userProfile}
          sites={tree.sites}
          updateProfile={updateProfile}
          errors={errors}
          removeErrors={removeErrors}
          roles={availableRoles}
          areas={tree.areas}
        />
      </Card>
    );
  }

  get costCentre() {
    const {
      userProfile: { payroll_site_id, payroll_area_id },
      tree: { sites, areas }
    } = this.props;

    if (payroll_area_id) {
      const area: AccountTreeArea | undefined = areas[payroll_area_id];
      if (area) {
        return `${sites[area.site_id].name} - ${area.name}`;
      }
      return 'Not enough permissions to view this';
    }

    if (payroll_site_id) {
      const site: AccountTreeSite | undefined = sites[payroll_site_id];

      if (site) {
        return site.name;
      }

      return 'Not enough permissions to view this';
    }

    return 'Site worked';
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  userProfile: getUserProfile(state),
  availableRoles: userRolesAccessibleByCurrentUserSelector(state),
  isOpened: getIsOpened(state, 'paymentModal'),
  tree: getAccountTree(state),
  errors: getErrors(state),
  langPreferences: getLangPreferences(state)
});

const mapDispatchToProps: DispatchProps = {
  closeModal: BOX_USER_PROFILE_PAYMENT_MODAL_CLOSE,
  openModal: BOX_USER_PROFILE_PAYMENT_MODAL_OPEN,
  updateProfile: BOX_USER_PROFILE_UPDATE_REQUEST,
  removeErrors: BOX_USER_PROFILE_REMOVE_ERRORS
};

export const PaymentDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentDetailsComponent);
