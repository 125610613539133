import React, { Children, Component } from 'react';
import { SelectListOptionProps, SelectListProps } from './type';
import SelectListOption from './component/SelectListOption';
import SelectAllButton from './component/SelectAllButton';
import './SelectList.scss';
import SelectListSearch from './component/SelectListSearch';
import SelectListOptionHeader from './component/SelectListOptionHeader';

class SelectList extends Component<SelectListProps> {
  static Option = SelectListOption;
  static OptionHeader = SelectListOptionHeader;

  render() {
    const { id, onSearch, onSelectAllToggle } = this.props;

    return (
      <div
        id={id}
        data-testid={`elmo-select-list-${id || 'default'}`}
        className="elmo-select-list"
      >
        <div className="elmo-select-list__header">
          {onSearch && <SelectListSearch onChange={onSearch} />}
          {onSelectAllToggle && (
            <SelectAllButton
              handleSelectAllClick={this.handleSelectAllClick}
              isActive={!this.isAllSelected}
            />
          )}
        </div>
        <div className="elmo-select-list__content" role="list">
          {this.props.children}
        </div>
      </div>
    );
  }

  private get isAllSelected(): boolean {
    let total: number = 0;
    let countSelected: number = 0;

    Children.forEach(this.props.children, (child: any) => {
      const { isDisabled, isSelected }: SelectListOptionProps = child.props;

      if (!isDisabled) {
        total++;

        if (!!isSelected) {
          countSelected++;
        }
      }
    });

    return countSelected === total;
  }

  private handleSelectAllClick = () => {
    const { onSelectAllToggle } = this.props;

    onSelectAllToggle!(!this.isAllSelected);
  };
}

export default SelectList;
