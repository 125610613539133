import React, { Component } from 'react';
import { OverlapShiftModalProps } from 'state/Roster/RosterShiftModal/types';
import { replaceErrorText } from 'lib/helpers';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';
import { PageDialog, PageDialogCancel, PageDialogSubmit } from 'element';
import { Typography } from 'oxygen-elements';

type OwnProps = {
  overlapModal: OverlapShiftModalProps;
  confirmOverlap: () => void;
  closeModal: (isUpdating: boolean) => void;
};

type Props = OwnProps;

export class ShiftOverlapConfirmationModal extends Component<Props> {
  render() {
    return (
      <PageDialog
        maxWidth="xs"
        open={this.props.overlapModal.isOpened}
        onClose={this.props.closeModal}
        id={'shift-overlap-modal'}
      >
        <DialogTitle>Do you want to approve this?</DialogTitle>

        <DialogContent>
          {replaceErrorText(this.props.overlapModal.errors).map(
            (error: string, index: number) => (
              <Typography
                variant={'body1'}
                className={'confirmation-text'}
                sx={{ padding: '10px 0' }}
                key={index}
              >
                {error}
              </Typography>
            )
          )}
        </DialogContent>
        <DialogActions>
          <PageDialogCancel
            disabled={this.props.overlapModal.isUpdating}
            onClick={() => this.props.closeModal(false)}
          >
            Cancel
          </PageDialogCancel>

          <PageDialogSubmit
            id={'approve-overlap-btn'}
            onClick={this.onClickHandler}
            loading={this.props.overlapModal.isUpdating}
            disabled={this.props.overlapModal.isUpdating}
            tabIndex={0}
          >
            Yes, approve it
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<{}>,
    snapshot?: any
  ) {
    const btn: null | HTMLButtonElement = document.querySelector(
      '#shift-overlap-modal #approve-overlap-btn'
    );
    if (btn && btn.offsetTop) {
      btn.focus();
    }
  }

  onClickHandler = () => {
    this.props.confirmOverlap();
  };
}

export default ShiftOverlapConfirmationModal;
