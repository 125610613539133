import { SagaIterator } from 'redux-saga';
import { put, race, select, take, takeLatest } from 'redux-saga/effects';
import { flatMap, intersection } from 'lodash';
import { SagaAction, UpdateFormPayload } from 'type';
import { getSiteId } from 'state/RosteredShifts';
import { getAreas } from 'state/AccountTree';
import {
  BOX_SITE_AREA_ROLE_MODAL_CLOSE,
  BOX_SITE_AREA_ROLE_MODAL_OPEN,
  BOX_SITE_AREA_ROLE_MODAL_SUBMIT,
  SubmitPayload
} from 'state/SiteAreRoleModal';
import {
  BOX_SITE_FLAT_AREA_ROLE_MODAL_CLOSE,
  BOX_SITE_FLAT_AREA_ROLE_MODAL_OPEN,
  BOX_SITE_FLAT_AREA_ROLE_MODAL_SUBMIT,
  SubmitPayload as FlatAreaRoleModalSubmitPayload
} from 'state/FlatAreaRoleModal';
import * as actions from './actions';
import {
  getFiltersFormAreas,
  getFiltersFormRoles,
  getFiltersFormRoleTriples,
  archivedAreaRoleIdsToBeShownSelector
} from './selectors';
import { convertEmptyArrayToNull, convertNullToEmptyArray } from './helpers/helpers';

// Custom filters with checkboxes
const updateAreaRoleCheckboxes = function*({
  payload: { value: newSelectedAreaIds, name }
}: SagaAction<UpdateFormPayload>): SagaIterator {
  if (name === 'areas') {
    if (!newSelectedAreaIds) {
      yield put(
        actions.BOX_ROSTER_FILTERS_UPDATE_FILTER_FORM_FIELD({
          name,
          value: newSelectedAreaIds
        })
      );
      return;
    }

    const selectedRoleIds: ReturnType<
      typeof getFiltersFormRoles
    > = yield select(getFiltersFormRoles);

    if (selectedRoleIds) {
      const areas: ReturnType<typeof getAreas> = yield select(getAreas);

      const newSelectedAreasRoleIds = flatMap(newSelectedAreaIds, areaId => {
        const area = areas[areaId];

        if (!area) {
          return [];
        }

        return area.role_ids;
      });

      const newSelectedRoleIds = intersection(
        newSelectedAreasRoleIds,
        selectedRoleIds
      );

      yield put(
        actions.BOX_ROSTER_FILTERS_UPDATE_FILTER_FORM_FIELD({
          name,
          value: newSelectedAreaIds
        })
      );

      yield put(
        actions.BOX_ROSTER_FILTERS_UPDATE_FILTER_FORM_FIELD({
          name: 'roles',
          value: newSelectedRoleIds
        })
      );
    } else {
      yield put(
        actions.BOX_ROSTER_FILTERS_UPDATE_FILTER_FORM_FIELD({
          name,
          value: newSelectedAreaIds
        })
      );
    }
  }
};

const changeRoleTripleFilters = function*(): SagaIterator {
  const selectedRoleTriples: ReturnType<
    typeof getFiltersFormRoleTriples
  > = yield select(getFiltersFormRoleTriples);

  const siteId: ReturnType<typeof getSiteId> = yield select(getSiteId);
  const archivedAreaRoleIdsToBeShown: ReturnType<
    typeof archivedAreaRoleIdsToBeShownSelector
  > = yield select(archivedAreaRoleIdsToBeShownSelector);

  yield put(
    BOX_SITE_AREA_ROLE_MODAL_OPEN({
      siteId,
      selectedRoleTriples,
      ...archivedAreaRoleIdsToBeShown
    })
  );

  const { submit } = yield race({
    submit: take(BOX_SITE_AREA_ROLE_MODAL_SUBMIT),
    close: take(BOX_SITE_AREA_ROLE_MODAL_CLOSE)
  });

  if (submit) {
    yield put(
      actions.BOX_ROSTER_FILTERS_UPDATE_FILTER_FORM({
        roleTriples: (submit as SagaAction<SubmitPayload>).payload
      })
    );
  }
};

const changeFlatRoleFilters = function*(): SagaIterator {
  const selectedAreaIds: ReturnType<typeof getFiltersFormAreas> = yield select(
    getFiltersFormAreas
  );
  const selectedRoleIds: ReturnType<typeof getFiltersFormRoles> = yield select(
    getFiltersFormRoles
  );
  const siteId: ReturnType<typeof getSiteId> = yield select(getSiteId);

  const archivedAreaRoleIdsToBeShown: ReturnType<
    typeof archivedAreaRoleIdsToBeShownSelector
    > = yield select(archivedAreaRoleIdsToBeShownSelector);

  yield put(
    BOX_SITE_FLAT_AREA_ROLE_MODAL_OPEN({
      selectedAreaIds: convertNullToEmptyArray(selectedAreaIds),
      selectedRoleIds: convertNullToEmptyArray(selectedRoleIds),
      siteId,
      ...archivedAreaRoleIdsToBeShown
    })
  );

  const { submit } = yield race({
    submit: take(BOX_SITE_FLAT_AREA_ROLE_MODAL_SUBMIT),
    close: take(BOX_SITE_FLAT_AREA_ROLE_MODAL_CLOSE)
  });

  if (submit) {
    const updatedAreaIdsRoleIds = (submit as SagaAction<
      FlatAreaRoleModalSubmitPayload
    >).payload;

    yield put(
      actions.BOX_ROSTER_FILTERS_UPDATE_FILTER_FORM({
        areas: convertEmptyArrayToNull(updatedAreaIdsRoleIds.selectedAreaIds),
        roles: convertEmptyArrayToNull(updatedAreaIdsRoleIds.selectedRoleIds)
      })
    );
  }
};

export const watchRosterFilters = function*(): SagaIterator {
  yield takeLatest(
    actions.BOX_ROSTER_FILTERS_OPEN_AREA_ROLE_TRIPLE_FILTERS,
    changeRoleTripleFilters
  );

  yield takeLatest(
    actions.BOX_ROSTER_FILTERS_OPEN_AREA_ROLE_FLAT_FILTERS,
    changeFlatRoleFilters
  );

  yield takeLatest(
    actions.BOX_ROSTER_FILTERS_UPDATE_AREA_ROLE_CHECKBOXES,
    updateAreaRoleCheckboxes
  );
};
