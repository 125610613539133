import React, { useEffect, useRef, useState } from 'react';
import { Moment } from 'moment';
import {
  CheckIcon,
  CustomDropdown,
  CustomDropdownOptions, CustomDropdownOptionType,
  TimeRangeInput,
  TimeRangeInputComponent,
} from 'element';
import { isSiteView } from '../../../../../../../../helpers';

type ShiftTimeRange = {
  start: Moment;
  end: Moment;
};

export type OnSelect<Value> = (payload: {
  range: ShiftTimeRange;
  value: Value;
}) => void;

export type ShiftTimeRangeInputProps<Value> = {
  options: CustomDropdownOptions<Value>;
  onSelect: OnSelect<Value>;
  day: Moment;
  emptyMessage?: string;
  onIconClick?: () => void;
  id?: string;
};

export const openDropdownDelay = 300;

const getOptionsAndHandler = <Value extends any>(
  {
    options,
    emptyMessage,
    onSelect
  }: Pick<ShiftTimeRangeInputProps<Value>, 'options' | 'emptyMessage' | 'onSelect'>,
  range: ShiftTimeRange | null,
  beforeSelect: () => void
): {
  options: CustomDropdownOptions<Value>;
  handleSelect: (value: Value) => void;
} => {
  if (!options.length && emptyMessage) {
    return {
      options: [
        {
          label: emptyMessage,
          value: (null as unknown) as Value
        }
      ],
      handleSelect: () => {
        beforeSelect();
      }
    };
  }

  return {
    options,
    handleSelect: (value: Value) => {
      beforeSelect();
      onSelect({
        range: range!,
        value
      });
    }
  };
};

const updateMidnightShiftRange = (range: ShiftTimeRange): ShiftTimeRange => {
  const {start, end} = range;

  if (start.isSameOrAfter(end)) {
    return {
      start,
      end: end.clone().add(1, 'day')
    };
  }

  return range;
};

export const ShiftTimeRangeInput = <Value extends any>(props: ShiftTimeRangeInputProps<Value>) => {
  const timeoutRef = useRef(0);

  const [range, setRange] = useState<ShiftTimeRange | null>(null);
  const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false);

  const closeDropdown = () => setIsDropdownOpened(false);

  const {options, handleSelect} = getOptionsAndHandler(props, range, closeDropdown);

  const {day, onIconClick, id} = props;

  const rangeEntered = (updatedRange: ShiftTimeRange) => {

    window.clearTimeout(timeoutRef.current);

    setRange(updateMidnightShiftRange(updatedRange));

    if (!isSiteView() && options.length === 2) {
      const handlers =
        getOptionsAndHandler(props, updateMidnightShiftRange(updatedRange), closeDropdown);
      const value = (options[1] as CustomDropdownOptionType<Value>).value;
      handlers.handleSelect(value);
    } else {
      timeoutRef.current = window.setTimeout(() => {
        setIsDropdownOpened(true);
      }, openDropdownDelay);
    }

  };

  const rolesDropdownClosed = () => {
    setRange(null);
    closeDropdown();
  };

  const timeRangeInputRef = useRef<TimeRangeInputComponent>(null);

  useEffect(() => {
    const {current} = timeRangeInputRef;

    if (current) {
      current.focus();
    }
  }, []);

  const icon = <CheckIcon/>;
  return (
    <div
      onClick={event => {
        event.stopPropagation();
      }}
    >
      <CustomDropdown
        isOpened={isDropdownOpened}
        options={options}
        onClose={rolesDropdownClosed}
        onSelect={handleSelect}
      >
        <TimeRangeInput
          ref={timeRangeInputRef}
          name=""
          value={range}
          onChange={rangeEntered}
          initialDayValue={day}
          placeholder="E.g. 8 - 17"
          icon={!range ? icon : null}
          onIconClick={undefined}
          id={id}
        />
      </CustomDropdown>
    </div>
  );
};
