import { ManagerDashboardReducerState } from './types';

export const getDefaultState = (): ManagerDashboardReducerState => ({
  isUpdating: false,
  isOpened: false,
  errors: [],
  requestedFilters: {
    site_ids: [],
    area_ids: [],
    role_ids: []
  },
  fetchedFilters: {
    site_ids: [],
    area_ids: [],
    role_ids: []
  },
  search: {
    query: ''
  }
});
