import { NUMBER_FORMATS } from 'lib/config';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { getNumberFormat } from 'state/Account';
import { StoreState } from 'state/types';
import { PreferencesNumberFormat } from 'type';

type OwnProps = {
  children: number;
  digits?: number;
};

type Props = OwnProps;

export const FormattedNumber = ({ children, digits = 2 }: Props) => {
  const numberFormat = useSelector(getNumberFormat);
  const locale = NUMBER_FORMATS[numberFormat];
  const num = isNaN(children) ? 0 : children;

  return (
    <>
      {new Intl.NumberFormat(locale, {
        maximumFractionDigits: digits,
        minimumFractionDigits: digits,
      }).format(num)}
    </>
  );
};

export default FormattedNumber;
