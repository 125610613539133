import {
  Avatar,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  Switch,
} from 'extended-oxygen-elements';
import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLangPreferences } from 'state/Account';

const RemoveButton = styled(Button)(({ theme }) => ({
  ...(theme.typography.caption as React.CSSProperties),
  display: 'flex',
}));

export type EmployeeSelectProps = {
  value: string[];
  onChange: (value: string[] | ((prevState: string[]) => string[])) => void;
  options: { value: string; label: string; avatarSrc: string | undefined }[];
};

export default function EmployeeSelect({
  value: propsValue,
  onChange,
  options,
}: EmployeeSelectProps) {
  const langPreferences = useSelector(getLangPreferences);

  const [isSelectOpen, setIsSelectOpen] = React.useState(false);

  const optionsByValue = _.keyBy(options, (option) => option.value);
  const optionValues = options.map((option) => option.value);
  const value = _.intersection(propsValue, optionValues);

  const isAllOptionsSelected = optionValues.length === value.length;

  const handleSelectOpen = () => {
    setIsSelectOpen(true);
  };

  const handleSelectClose = () => {
    setIsSelectOpen(false);
  };

  const handleSelectChange = ({
    target: { value: selectedValue },
  }: SelectChangeEvent<typeof value>) => {
    if (!Array.isArray(selectedValue)) {
      return;
    }

    onChange(selectedValue);
  };

  const handleSelectAllMenuItemClick = () => {
    onChange(isAllOptionsSelected ? [] : optionValues);
  };

  const makeHandleDeleteBtnClick = (idToBeDeleted: string) => () => {
    onChange((prevState) => _.without(prevState, idToBeDeleted));
  };

  return (
    <>
      <Select
        fullWidth
        open={isSelectOpen}
        onOpen={handleSelectOpen}
        onClose={handleSelectClose}
        multiple
        displayEmpty
        onChange={handleSelectChange}
        value={value}
        renderValue={() => {
          if (isSelectOpen) {
            return '';
          }

          return `Select ${langPreferences.employee.singular}`;
        }}
      >
        <MenuItem
          selected={isAllOptionsSelected}
          onClick={handleSelectAllMenuItemClick}
        >
          <ListItemText
            primary={`Select all ${langPreferences.employee.plural}`}
          />
          <Switch checked={isAllOptionsSelected} sx={{ my: -0.5 }} />
        </MenuItem>
        {options.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            <Checkbox
              checked={value.includes(option.value)}
              sx={{ my: -0.5 }}
            />
            <Avatar
              src={option.avatarSrc}
              alt={option.label}
              sx={{ my: -0.5, mr: 1 }}
              light
            />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
      {!!value.length && (
        <List>
          {value.map((optionValue) => {
            const option = optionsByValue[optionValue];

            return (
              <ListItem
                key={option.value}
                secondaryAction={
                  <RemoveButton
                    color="error"
                    size="small"
                    onClick={makeHandleDeleteBtnClick(option.value)}
                  >
                    Remove
                  </RemoveButton>
                }
              >
                <ListItemAvatar>
                  <Avatar
                    src={option.avatarSrc || ''}
                    alt={option.label}
                    light
                  />
                </ListItemAvatar>

                <ListItemText primary={option.label} />
              </ListItem>
            );
          })}
        </List>
      )}
    </>
  );
}
