import React from 'react';
import './PaginationRange.scss';
import { Text } from '../../Typography';

export type PaginationRangeProps = {
  /** The index of the first item shown. Starts from 1 */
  firstResult: number;
  /** The index of the last item shown. */
  lastResult: number;
  /** The total number of items */
  totalResults: number;
};

function PaginationRange({
  firstResult,
  lastResult,
  totalResults,
}: PaginationRangeProps) {
  return (
    <div className="elmo-pagination__range">
      <Text size="sm">
        {firstResult} - {lastResult} of {totalResults} records shown
      </Text>
    </div>
  );
}

export default PaginationRange;
