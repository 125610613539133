import { get } from 'lodash';
import { StoreState } from 'state/types';
import { RosterDayViewReducerState } from '../types';

const getState = (state: StoreState): RosterDayViewReducerState =>
  state.rosterDayView;

export const getErrorModal = (state: StoreState) => getState(state).errorModal;
export const getConfirmModal = (state: StoreState) =>
  getState(state).confirmModal;
export const getCreatedShiftData = (state: StoreState) =>
  getState(state).createdShiftData;
export const getSiteViewAdditionalRows = (state: StoreState) =>
  getState(state).siteViewAdditionalRows;
export const getSiteViewAdditionalRowsByPayload = (
  state: StoreState,
  { area_id, role_id }: { area_id: string; role_id: string }
) => get(getState(state).siteViewAdditionalRows, [area_id, role_id], 0);
export const getEventsAdditionalRows = (state: StoreState) =>
  getState(state).eventsAdditionalRows;
export const getUnassignedAdditionalRows = (state: StoreState) =>
  getState(state).unassignedAdditionalRows;
const getDragAndDrop = (state: StoreState) =>
  getState(state).dragAndDrop;
export const getDragAndDropOffset = (state: StoreState) => getDragAndDrop(state).offset;
export const getDragAndDropShiftId = (state: StoreState) => getDragAndDrop(state).shiftId;
