import React from 'react';
import _ from 'lodash';
import { Typography, TypographyProps } from 'extended-oxygen-elements';

export function TimesheetCardHeaderText(actualProps: TypographyProps) {
  const props = _.defaults({}, actualProps, {
    variant: 'h6',
    align: 'center',
  });
  return <Typography {...props} />;
}

