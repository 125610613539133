import React, { useState } from 'react';
import { FormItem, Radio } from 'elmo-elements';
import { FormControlLabel } from 'extended-oxygen-elements';
import { useLocation } from 'react-router-dom';

type Props = {
  selected: 'all' | 'with_shifts' | 'without_shifts';
  onChange: (value: 'all' | 'with_shifts' | 'without_shifts') => void;
};
export const UsersWithShiftsGroup = ({ selected, onChange }: Props) => {
  const [current, setCurrent] = useState<string>(selected);
  const location = useLocation();

  const handleFromChange = (value: any) => {
    setCurrent(value);
    onChange(value);
  };

  if (location.pathname.indexOf('site-view') !== -1) {
    return null;
  }

  return (
    <div className={'people-shift-group'}>
      <FormItem label={'People'} isDivider={true}>
        <FormControlLabel
          value={'all'}
          control={
            <Radio
              id={'with-without'}
              checked={current === 'all'}
              onChange={handleFromChange}
              value={'all'}
            />
          }
          label={'Show people with/without shifts'}
        />

        <FormControlLabel
          value={'with_shifts'}
          control={
            <Radio
              id={'with'}
              checked={current === 'with_shifts'}
              onChange={handleFromChange}
              value={'with_shifts'}
            />
          }
          label={'Show people with shifts'}
        />

        <FormControlLabel
          value={'without_shifts'}
          control={
            <Radio
              id={'without'}
              checked={current === 'without_shifts'}
              onChange={handleFromChange}
              value={'without_shifts'}
            />
          }
          label={'Show people without shifts'}
        />
      </FormItem>
    </div>
  );
};
