import React, { ChangeEvent } from 'react';
import { flow, isNaN } from 'lodash';
import { Input } from 'elmo-elements';

export type IntegerInputChangeEvent = {
  name: string;
  value: number;
};

type Props = {
  name: string;
  value: number;
  onChange?: (payload: IntegerInputChangeEvent) => void;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  id?: string;
};

export class IntegerInput extends React.Component<Props> {
  render() {
    const { value, isReadOnly, isDisabled, name, id } = this.props;

    return (
      <Input
        id={id}
        name={name}
        htmlType="number"
        value={value}
        onChange={this.onChange}
        isReadOnly={isReadOnly}
        isDisabled={isDisabled}
      />
    );
  }

  private onChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    const { name, onChange } = this.props;
    if (!onChange) {
      return;
    }

    onChange({
      name,
      value: this.updateInputValue(value)
    });
  };

  private updateInputValue = flow([parseNumber, checkNaN, checkNegative]);
}

function parseNumber(quantity: string) {
  return parseInt(quantity, 10);
}

function checkNaN(quantity: number) {
  return isNaN(quantity) ? 0 : quantity;
}

function checkNegative(quantity: number) {
  return quantity < 0 ? 0 : quantity;
}
