import React from 'react';
import Svg from './Svg';
import { LoaderHolderProps } from '../type';
import './LoaderHolder.scss';

export const defaultProps: LoaderHolderProps = {
  animate: true,
  ariaLabel: 'Loading interface...',
  baseUrl: '',
  gradientRatio: 2,
  interval: 0.25,
  preserveAspectRatio: 'none',
  primaryColor: '#f0f0f0',
  primaryOpacity: 1,
  rtl: false,
  secondaryColor: '#e0e0e0',
  secondaryOpacity: 1,
  speed: 2,
  style: {},
  width: '100%',
  viewBoxWidth: 400,
  viewBoxHeight: 130,
};

export function InitialComponent(props: LoaderHolderProps) {
  return (
    <rect
      x="0"
      y="0"
      rx="5"
      ry="5"
      width={props.viewBoxWidth}
      height={props.viewBoxHeight}
    />
  );
}

function LoaderHolder(props: LoaderHolderProps) {
  const mergedProps = { ...defaultProps, ...props };
  const children = props.children ? (
    props.children
  ) : (
    <InitialComponent {...mergedProps} />
  );

  return (
    <Svg className="elmo-loader" {...mergedProps}>
      {children}
    </Svg>
  );
}

export default LoaderHolder;
