import { withHeading } from 'element';
import { capitalize } from 'lib/helpers';
import { marketLabels } from 'marketLabels';
import Roster from 'page/Roster';
import EditTemplate from 'page/Roster/page/EditTemplate';
import EditTemplateSiteView from 'page/Roster/page/EditTemplate/page/EditTemplateSiteView';
import EditTemplateUserView from 'page/Roster/page/EditTemplate/page/EditTemplateUserView';
import RosterDayView from 'page/Roster/page/RosterDayView';
import RosterDayViewSiteView from 'page/Roster/page/RosterDayView/page/RosterDayViewSiteView';
import RosterDayViewUserView from 'page/Roster/page/RosterDayView/page/RosterDayViewUserView';
import RosterWeekView from 'page/Roster/page/RosterWeekView';
import RosterWeekViewSiteView from 'page/Roster/page/RosterWeekView/page/RosterWeekViewSiteView';
import RosterWeekViewUserView from 'page/Roster/page/RosterWeekView/page/RosterWeekViewUserView';

const makeTitle = (partialTitle: string) =>
  `${capitalize(marketLabels.roster)} ${partialTitle}`;

export const roster = {
  path: `/app/${marketLabels.roster}`,
  permission: 'roster' as 'roster',
  component: Roster,
  channel: 'roster',
  routes: {
    rosterWeekView: {
      path: `/app/${marketLabels.roster}/week-view`,
      component: RosterWeekView,
      routes: {
        rosterWeekViewUserView: {
          path: `/app/${marketLabels.roster}/week-view/user-view`,
          component: withHeading(RosterWeekViewUserView, {
            title: makeTitle('week view - user view'),
          }),
        },
        rosterWeekViewSiteView: {
          path: `/app/${marketLabels.roster}/week-view/site-view`,
          component: withHeading(RosterWeekViewSiteView, {
            title: makeTitle('week view - site view'),
          }),
        },
        fallBack: {
          path: `/app/${marketLabels.roster}/week-view`,
          redirect: 'will be updated from component Rosters',
          exact: false,
          preserveQuery: true,
          redirects: {
            user: `/app/${marketLabels.roster}/week-view/user-view`,
            site: `/app/${marketLabels.roster}/week-view/site-view`,
          },
        },
      },
    },
    rosterDayView: {
      path: `/app/${marketLabels.roster}/day-view`,
      component: RosterDayView,
      routes: {
        rosterDayViewUserView: {
          path: `/app/${marketLabels.roster}/day-view/user-view`,
          component: withHeading(RosterDayViewUserView, {
            title: makeTitle('day view - user view'),
          }),
        },
        rosterDayViewSiteView: {
          path: `/app/${marketLabels.roster}/day-view/site-view`,
          component: withHeading(RosterDayViewSiteView, {
            title: makeTitle('day view - site view'),
          }),
        },
        fallBack: {
          path: `/app/${marketLabels.roster}/day-view`,
          redirect: 'will be updated from component Rosters',
          exact: false,
          preserveQuery: true,
          redirects: {
            user: `/app/${marketLabels.roster}/day-view/user-view`,
            site: `/app/${marketLabels.roster}/day-view/site-view`,
          },
        },
      },
    },
    rosterEditTemplate: {
      path: `/app/${marketLabels.roster}/edit-template/:templateId`,
      component: EditTemplate,
      routes: {
        rosterWeekViewEditUserView: {
          path: (templateId = ':templateId') =>
            `/app/${marketLabels.roster}/edit-template/${templateId}/user-view`,
          component: withHeading(EditTemplateUserView, {
            title: makeTitle('week view - edit template'),
          }),
        },
        rosterWeekViewEditSiteView: {
          path: (templateId = ':templateId') =>
            `/app/${marketLabels.roster}/edit-template/${templateId}/site-view`,
          component: withHeading(EditTemplateSiteView, {
            title: makeTitle('week view - edit template'),
          }),
        },
        fallBack: {
          redirects: {
            user: `/app/${marketLabels.roster}/edit-template`,
            site: `/app/${marketLabels.roster}/edit-template`,
          },
          path: `/app/${marketLabels.roster}/edit-template`,
          redirect: 'will be updated from component Rosters',
          exact: false,
        },
      },
    },
    fallBack: {
      path: `/app/${marketLabels.roster}`,
      redirect: 'will be updated from component Rosters',
      exact: false,
      preserveQuery: true,
      redirects: {
        user: `/app/${marketLabels.roster}/week-view/user-view`,
        site: `/app/${marketLabels.roster}/week-view/site-view`,
      },
    },
  },
};
