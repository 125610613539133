import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import { currentTimeWidthSelector, dstOffsetWidthSelector } from 'state/Roster/RosterDayView';
import { IsCurrentDay } from '../IsCurrentDay';
import { IsDstSpringForward } from '../IsDstSpringForward';
import { Divider } from './components/Divider';
import './DayDivider.scss';

type OwnProps = {
  children: ReactNode;
};

type StateProps = {
  currentTimeWidth: number;
  dstOffsetWidth: number;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

export const DayDividerComponent = ({
  children,
  currentTimeWidth,
  dstOffsetWidth
}: Props) => {
  return (
    <div className="day-divider__container">
      {children}

      <IsCurrentDay>
        <Divider width={currentTimeWidth} color="primary" />
      </IsCurrentDay>

      <IsDstSpringForward>
        <Divider width={dstOffsetWidth} color="gray-500" />
      </IsDstSpringForward>
    </div>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  currentTimeWidth: currentTimeWidthSelector(state),
  dstOffsetWidth: dstOffsetWidthSelector(state)
});

const mapDispatchToProps: DispatchProps = {};

export const DayDivider = connect(
  mapStateToProps,
  mapDispatchToProps
)(DayDividerComponent);
