import React, { ReactChild } from 'react';
import Button from '../../Button';
import { FilterListIcon as FilterIcon, CloseIcon } from '../../icons';
import './FilterCustomModal.scss';
import { getClass } from '../../_lib/helper';
import Tooltip from '../../Tooltip';
import {
  Box,
  Button as OxyButton,
  Toolbar,
  Drawer,
  IconButton,
  Typography,
  AppBar,
} from 'oxygen-elements';

export type FilterCustomModalProps = {
  /** The content of the modal */
  children: ReactChild;
  /** Whether the modal is open */
  isOpen: boolean;
  /** Function to toggle the state of the modal */
  toggle: () => void;
  /** Callback when Apply is clicked */
  onApply: () => void;
  /** True if button is active */
  isButtonActive: boolean;
  /** Disable “Apply” button or not */
  isApplyBtnDisabled: boolean;
  /** tooltip title */
  tooltip: string;
};

function FilterCustomModal({
  children,
  isOpen,
  toggle,
  onApply,
  isButtonActive,
  isApplyBtnDisabled,
  tooltip,
}: FilterCustomModalProps) {
  return (
    <div className="elmo-filter__modal">
      <Tooltip title={tooltip} tabIndex={-1} placement="bottom">
        <Button
          onClick={toggle}
          className={getClass(
            'elmo-filter__modal-button',
            {},
            {
              active: isButtonActive,
            }
          )}
          ariaLabel="Filter modal"
        >
          <FilterIcon />
        </Button>
      </Tooltip>
      <Drawer
        id="FilterCustomModal"
        anchor={'right'}
        open={isOpen}
        onClose={toggle}
        title="Filter"
      >
        <Box
          maxWidth={'340px'}
          minWidth={'340px'}
          className={'custom-filters-wrapper'}
          sx={{ overflow: 'hidden' }}
        >
          <Box
            padding={3}
            sx={{ height: 'calc(100vh - 80px)', overflowY: 'auto' }}
          >
            <Typography
              variant={'h3'}
              paddingBottom={3}
              sx={{ position: 'relative' }}
            >
              Filter
              <IconButton
                data-testid={'close-filters-btn'}
                onClick={toggle}
                sx={{ position: 'absolute', right: '-7px', top: '-7px' }}
              >
                <CloseIcon />
              </IconButton>
            </Typography>
            {children}
          </Box>

          <Box
            paddingLeft={3}
            paddingRight={3}
            paddingTop={2}
            paddingBottom={2}
            sx={{
              background: 'white',
              position: 'absolute',
              bottom: '0',
              left: '0',
              right: '0',
              boxShadow: '0 0 6px 2px #ccc',
              zIndex: 3,
            }}
          >
            <OxyButton
              variant={'contained'}
              onClick={onApply}
              disabled={isApplyBtnDisabled}
            >
              Apply
            </OxyButton>
          </Box>
        </Box>
      </Drawer>
    </div>
  );
}

export default FilterCustomModal;
