import React from 'react';
import { connect } from 'react-redux';
import { LoadingOverlay } from 'elmo-elements';
import {
  Timesheet,
} from 'type';
import { StoreState } from 'state/types';
import { getIsLoading, MANAGER_DASHBOARD_TIMESHEETS } from 'state/IsLoading';
import {
  BOX_TIMESHEETS_APPROVAL_MODAL_CLOSE,
  BOX_TIMESHEETS_APPROVAL_MODAL_SUBMIT_APPROVED, BOX_TIMESHEETS_APPROVAL_MODAL_SUBMIT_NOT_APPROVED,
  BOX_TIMESHEETS_CONFORMATION_MODAL_CLOSE,
  BOX_TIMESHEETS_CONFORMATION_MODAL_SUBMIT,
  BOX_TIMESHEETS_DELETE_MODAL_CLOSE,
  BOX_TIMESHEETS_DELETE_MODAL_SUBMIT,
  filteredTimsheetsSelector,
  getApprovalModal,
  getConfirmationModal,
  getDeleteModal,
  getNewShift,
  getShowNewForm
} from 'state/Timesheets/Timesheets';
import { ShiftCard } from './components/ShiftCard';
import { NotFoundMessage } from './components/NotFoundMessage';
import { NewShiftCard } from './components/NewShiftCard';
import DeleteModal from './components/DeleteModal';
import { ApprovalModalType, ConfirmationModal as ConfirmationModalType, DeleteModal as DeleteModalType, NewShift }
  from 'state/Timesheets/Timesheets/types';
import ConfirmationModal from './components/ConfirmationModal';
import ApproveModal from 'element/shiftModals/TimesheetModal/components/ApproveModal';
import './style.scss';

type OwnProps = {
};

type StateProps = {
  timesheets: Timesheet[];
  isLoading: boolean;
  showNewForm: boolean,
  deleteModal: DeleteModalType,
  confirmationModal: ConfirmationModalType,
  approvalModal: ApprovalModalType,
  newShift: NewShift
};

type DispatchProps = {
  deleteModalSubmit: () => void,
  deleteModalClose: () => void,
  confirmationModalClose: () => void,
  confirmationModalSubmit: () => void,
  approvalModalClose: () => void,
  approvalModalSubmitApproved: () => void
  approvalModalSubmitNotApproved: () => void
};

type Props = OwnProps & StateProps & DispatchProps;

class TimesheetsListComponent extends React.Component<Props> {

  render() {
    const {
      isLoading,
      timesheets,
      showNewForm,
      deleteModal,
      confirmationModal,
      approvalModal,
      newShift,
      confirmationModalClose,
      confirmationModalSubmit,
      deleteModalSubmit,
      deleteModalClose
    } = this.props;
    return (
      <LoadingOverlay isLoading={isLoading}>
        {newShift.isOpened && <NewShiftCard/>}
        {newShift.isOpened || timesheets.length || showNewForm ? timesheets.map((timesheet, key) => {
          return <ShiftCard
            key={timesheet.id}
            timesheet={timesheet}
          />
        }) : <NotFoundMessage/>}
        <DeleteModal
          isOpened={deleteModal.isOpened}
          isUpdating={deleteModal.isUpdating}
          onSubmit={deleteModalSubmit}
          onClose={deleteModalClose}
          errors={deleteModal.errors}
        />

        <ConfirmationModal
          isOpened={confirmationModal.isOpened}
          onSubmit={confirmationModalSubmit}
          onClose={confirmationModalClose}
          errors={confirmationModal.errors}
        />

        <ApproveModal
          isOpened={approvalModal.isOpened}
          approveAction={this.approvalModalSubmit}
          closeModal={this.closeApprovalModal}
        />

      </LoadingOverlay>
    );
  }

  approvalModalSubmit = (isApproved: boolean) => {
    if (isApproved) {
      this.props.approvalModalSubmitApproved();
    } else {
      this.props.approvalModalSubmitNotApproved();
    }
  }

  closeApprovalModal = () => {
    this.props.approvalModalClose();
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  timesheets: filteredTimsheetsSelector(state),
  isLoading: getIsLoading(state, MANAGER_DASHBOARD_TIMESHEETS),
  showNewForm: getShowNewForm(state),
  deleteModal: getDeleteModal(state),
  confirmationModal: getConfirmationModal(state),
  approvalModal: getApprovalModal(state),
  newShift: getNewShift(state)
});

const mapDispatchToProps: DispatchProps = {
  deleteModalSubmit: BOX_TIMESHEETS_DELETE_MODAL_SUBMIT,
  deleteModalClose: BOX_TIMESHEETS_DELETE_MODAL_CLOSE,
  confirmationModalClose: BOX_TIMESHEETS_CONFORMATION_MODAL_CLOSE,
  confirmationModalSubmit: BOX_TIMESHEETS_CONFORMATION_MODAL_SUBMIT,
  approvalModalClose: BOX_TIMESHEETS_APPROVAL_MODAL_CLOSE,
  approvalModalSubmitApproved: BOX_TIMESHEETS_APPROVAL_MODAL_SUBMIT_APPROVED,
  approvalModalSubmitNotApproved: BOX_TIMESHEETS_APPROVAL_MODAL_SUBMIT_NOT_APPROVED,
};

export const TimesheetsList = connect(mapStateToProps, mapDispatchToProps)(
  TimesheetsListComponent
);
