import { SagaIterator } from 'redux-saga';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { SagaAction } from 'type';
import { processApiRequest } from 'state/ProcessApiRequest';
import {
  AreaRolePayload,
  BulkDeletePayload,
  BulkDeleteResponse,
} from './types';
import {
  BOX_ROSTER_BULK_DELETION_MODAL_CONFIRMATION,
  BOX_ROSTER_BULK_DELETION_MODE_DEACTIVATE,
  BOX_ROSTER_BULK_DELETION_SEND_SHIFTS_FAILURE,
  BOX_ROSTER_BULK_DELETION_SEND_SHIFTS_REQUEST,
  BOX_ROSTER_BULK_DELETION_SEND_SHIFTS_SUCCESS,
  BOX_ROSTER_BULK_DELETION_SEND_TEMPLATED_SHIFTS_FAILURE,
  BOX_ROSTER_BULK_DELETION_SEND_TEMPLATED_SHIFTS_REQUEST,
  BOX_ROSTER_BULK_DELETION_SEND_TEMPLATED_SHIFTS_SUCCESS,
  BOX_ROSTER_BULK_TOGGLE_SELECTED_AREA_ROLE,
  BOX_ROSTER_BULK_TOGGLE_SELECTED_SHIFTS,
  BOX_ROSTER_BULK_TOGGLE_SELECTED_SHIFTS_AREA_ROLE,
  BOX_ROSTER_BULK_TOGGLE_SELECTED_USER,
  BOX_ROSTER_BULK_TOGGLE_SELECTION_BY_AREA_ROLE,
  BOX_ROSTER_BULK_TOGGLE_SELECTION_BY_USER,
} from './actions';
import { Api, OVERLAP_ERROR_STATUS } from 'lib/Api';
import { formatError, getErrorStatus } from '../../helpers';
import {
  getSelectionByAreaRole,
  getSelectionByUser,
  getTemplatedSelectionByAreaRole,
  getTemplatedSelectionByUser,
} from './selectors';
import { BOX_ROSTERED_SHIFTS_DELETE_SHIFTS } from '../../RosteredShifts/actions';
import { getTemplateId, BOX_EDIT_TEMPLATE_DELETE_SHIFTS } from '../EditTemplate';
import { getConfirmationMsgParams } from '../../../helpers';

const sendShiftsToDelete = function* ({
  payload,
}: SagaAction<BulkDeletePayload>): SagaIterator {
  try {
    const response: BulkDeleteResponse = yield call(
      processApiRequest,
      Api.RosteredShift.bulkDelete,
      payload
    );
    yield put(BOX_ROSTERED_SHIFTS_DELETE_SHIFTS(response.deleted));
    // set success state, highlight and clear after 1 second
    yield put(
      BOX_ROSTER_BULK_DELETION_SEND_SHIFTS_SUCCESS(response.notDeleted)
    );
    yield delay(1000);
    yield put(BOX_ROSTER_BULK_DELETION_MODE_DEACTIVATE());
  } catch (error) {
    const errorStatus = getErrorStatus(error);
    if (errorStatus === OVERLAP_ERROR_STATUS) {
      yield put(
        BOX_ROSTER_BULK_DELETION_MODAL_CONFIRMATION(
          getConfirmationMsgParams(formatError(error), error.name)
        )
      );
    } else {
      yield put(
        BOX_ROSTER_BULK_DELETION_SEND_SHIFTS_FAILURE(formatError(error))
      );
    }
  }
};

const sendTemplatedShiftsToDelete = function*({
 payload
}: SagaAction<BulkDeletePayload>): SagaIterator {
  try {
    const templateId = yield select(getTemplateId);
    yield call(processApiRequest, Api.EditTemplate.bulkDelete, {
      ...payload,
      templateId
    });
    // remove shifts from state
    yield put(BOX_EDIT_TEMPLATE_DELETE_SHIFTS(payload.ids));
    yield put(BOX_ROSTER_BULK_DELETION_SEND_TEMPLATED_SHIFTS_SUCCESS());
    yield delay(1000);
    yield put(BOX_ROSTER_BULK_DELETION_MODE_DEACTIVATE());
  } catch (error) {
    const errorStatus = getErrorStatus(error);
    if (errorStatus === OVERLAP_ERROR_STATUS) {
      yield put(BOX_ROSTER_BULK_DELETION_MODAL_CONFIRMATION(
        getConfirmationMsgParams(formatError(error), error.name)
      ));
    } else {
      yield put(BOX_ROSTER_BULK_DELETION_SEND_TEMPLATED_SHIFTS_FAILURE(formatError(error)));
    }
  }
};

const toggleSelectionByUser = function*({
  payload
}: SagaAction<string | null>): SagaIterator {
  const templateId = yield select(getTemplateId);
  const selectedShifts = !templateId
    ? yield select(getSelectionByUser(payload))
    : yield select(getTemplatedSelectionByUser(payload));
  yield put(BOX_ROSTER_BULK_TOGGLE_SELECTED_USER(payload));
  yield put(BOX_ROSTER_BULK_TOGGLE_SELECTED_SHIFTS({
    shifts: selectedShifts,
    user_id: payload
  }));
};

const toggleSelectionByAreaRole = function*({
  payload
}: SagaAction<AreaRolePayload>): SagaIterator {
  const templateId = yield select(getTemplateId);
  const selectedShifts = !templateId
    ? yield select(getSelectionByAreaRole(payload.area_id, payload.role_id))
    : yield select(getTemplatedSelectionByAreaRole(payload.area_id, payload.role_id));
  yield put(BOX_ROSTER_BULK_TOGGLE_SELECTED_AREA_ROLE(payload));
  yield put(BOX_ROSTER_BULK_TOGGLE_SELECTED_SHIFTS_AREA_ROLE({
    shifts: selectedShifts,
    ...payload
  }));
};

export const watchRosterBulkActions = function*(): SagaIterator {
  yield takeLatest(
    BOX_ROSTER_BULK_DELETION_SEND_SHIFTS_REQUEST,
    sendShiftsToDelete
  );
  yield takeLatest(
    BOX_ROSTER_BULK_DELETION_SEND_TEMPLATED_SHIFTS_REQUEST,
    sendTemplatedShiftsToDelete
  );
  yield takeLatest(
    BOX_ROSTER_BULK_TOGGLE_SELECTION_BY_USER,
    toggleSelectionByUser
  );
  yield takeLatest(
    BOX_ROSTER_BULK_TOGGLE_SELECTION_BY_AREA_ROLE,
    toggleSelectionByAreaRole
  )
};
