import { createAction } from 'lib/store-utils';
import { GetTimeOffsListSuccessPayload, TimeOffsDeleteOnePayload } from './types';
import { FormattedErrors, RosterRepeatableTimeOff } from '../../type/models';
import { StringMap } from 'type';
import { TimeOffUpdateNotes } from '../../lib/Api';

export const BOX_TIME_OFFS_GET_LIST_SUCCESS = createAction<GetTimeOffsListSuccessPayload>('TimeOffs / get list success');

export const BOX_TIME_OFFS_UPDATE_ONE = createAction<RosterRepeatableTimeOff>('TimeOffs / update timeoff');
export const BOX_TIME_OFFS_DELETE_ONE = createAction<TimeOffsDeleteOnePayload>('TimeOffs / delete one');

export const BOX_TIME_OFFS_UPDATE_MULTIPLE = createAction<StringMap<RosterRepeatableTimeOff>>('TimeOffs / update multiple');
export const BOX_TIME_OFFS_DELETE_MULTIPLE = createAction<string[]>('TimeOffs / delete multiple');

export const BOX_TIME_OFFS_UPDATE_LEAVE_NOTES = createAction<TimeOffUpdateNotes>('TimeOffs / update leave notes request');
export const BOX_TIME_OFFS_UPDATE_LEAVE_NOTES_SUCCESS = createAction<any>('TimeOffs / update leave notes success');
export const BOX_TIME_OFFS_UPDATE_LEAVE_NOTES_FAILURE = createAction<FormattedErrors>('TimeOffs / update leave notes failure');
