import React from 'react';
import { connect } from 'react-redux';
import { FormattedErrors } from 'type';
import { ErrorBox } from 'element';
import { StoreState } from 'state/types';
import { BOX_TIMESHEETS_ERRORS_CLEARED, getErrors } from '../../../../state/Timesheets/Timesheets';

type OwnProps = {};

type StateProps = {
  errors: FormattedErrors;
};

type DispatchProps = {
  clearErrors: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const TimesheetsErrorBoxComponent = (props: Props) => <ErrorBox {...props} />;

const mapStateToProps = (state: StoreState): StateProps => ({
  errors: getErrors(state)
});

const mapDispatchToProps: DispatchProps = {
  clearErrors: BOX_TIMESHEETS_ERRORS_CLEARED
};

export const TimesheetsErrorBox = connect(
  mapStateToProps,
  mapDispatchToProps
)(TimesheetsErrorBoxComponent);
