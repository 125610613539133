import React, { memo } from 'react';
import { times } from 'lodash';
import { useSelector } from 'react-redux';
import { weekDaysNumberSelector } from 'state/Roster/RosterWeekViewUserView';
import { UnavailabilityCell } from './UnavailabilityCell';

export const UnavailabilityRow = memo(function UnavailabilityRow() {
  const weekDaysNumber = useSelector(weekDaysNumberSelector);
  return (
    <div className={`week-rosters-grid__blocks`}>
      <div className="week-rosters-grid__group">
        {times(weekDaysNumber, (weekDayIndex) => (
          <div
            className="week-rosters-grid__group-day-block unavailability-block"
            key={weekDayIndex}
          >
            <UnavailabilityCell />
          </div>
        ))}
      </div>
    </div>
  );
});