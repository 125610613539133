import createAction from './createAction';
import { EmptyActionCreator, SimpleActionCreator } from './types';

export type ConnectActionCreator<ConnectPayload> = {
  connected: ConnectPayload extends void
    ? EmptyActionCreator
    : SimpleActionCreator<ConnectPayload>;
  disconnected: EmptyActionCreator;
};

export default createConnectAction;

export function createConnectAction<PC = void>(
  description: string
): ConnectActionCreator<PC> {
  return {
    connected: createAction(description + ' / connected'),
    disconnected: createAction(description + ' / disconnected'),
  } as any;
}
