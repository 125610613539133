import { FormattedErrors, GeneratedReport, Report } from '../../type';
import moment from 'moment';

export type ReportPagination = {
  currentPage: number;
  totalResults: number;
  pageSize: number;
  startItem: number;
  endItem: number;
  pageSizeOptions: number[];
};

export type ReportOrder = {
  by: string;
  dir: 'asc' | 'desc';
};

export type ReportReducerState = {
  isFetching: boolean;
  isLoading: boolean;
  errors: FormattedErrors;
  report: Report;
  generatedReport: GeneratedReport;
  fields: any[];
  isUpdated: boolean;
  isSavedSuccess: boolean;
  isSavedAsSuccess: boolean;
  pagination: ReportPagination;
  order: ReportOrder;
  scheduleModal: {
    isOpened: boolean;
    isLoading: boolean;
    errors: FormattedErrors;
  };
};

const today = moment();

export const defaultState: ReportReducerState = {
  isFetching: false,
  isLoading: false,
  isUpdated: false,
  isSavedSuccess: false,
  isSavedAsSuccess: false,
  fields: [],
  errors: [],
  pagination: {
    currentPage: 1,
    totalResults: 8,
    pageSize: 10,
    startItem: 1,
    endItem: 10,
    pageSizeOptions: [10, 20, 50],
  },
  order: {
    by: 'user_name',
    dir: 'asc',
  },
  report: {
    id: '1',
    account_id: '321',
    type: 'rostered_shift',
    name: '',
    period: {
      period: 'custom',
      from: '2019-03-01 07:54:21',
      to: '2019-03-01 07:54:21',
    },
    filters: {
      role_ids: [],
      site_ids: [],
      tag_ids: [],
      area_ids: [],
      user_ids: [],
      tag_type: 'user',
    },
    extra: {
      group_by: 'date',
      order_by: 'user_name',
      order_dir: 'asc',
      time_format: 'decimal',
      show_approved_shifts: false,
      show_approved_timesheets: false,
      details: false,
      show_self_assigned: false,
      detailed_breaks: false,
      show: '',
      date_field_direction: '',
      include: '',
      date_field_start: '',
      interval: '',
      datatype: '',
      shifttype: '',
      date_field_anniversary: false,
      date_field: '',
      archived: '',
      show_inactive_users: true,
    },
    fields: [],
    schedule: {
      is_enabled: false,
      schedule: {
        period: 'daily',
        day: ['1'],
      },
      time: today,
      format: 'csv',
      from: today,
      to: today,
      recipients: [],
      sub_format: null,
    },
    updated_at: today,
    created_at: today,
  },
  generatedReport: {
    rows: [],
    fields: [],
    totals: {},
    groups: [],
    isFetched: false,
    generated_id: '',
  },
  scheduleModal: {
    isOpened: false,
    isLoading: false,
    errors: [],
  },
};
