import { Box, BoxProps, useTheme } from 'extended-oxygen-elements';
import { useFormControlSpacing } from 'hooks';
import React from 'react';
import { Parameter } from 'type';

export type FormControlSpacingProps = BoxProps & {
  margin?: Parameter<typeof useFormControlSpacing>;
  fullWidth?: boolean;
};

export default FormControlSpacing;

export function FormControlSpacing(actualProps: FormControlSpacingProps) {
  const { margin, fullWidth, children, ...restBoxProps } =
    useDefaultProps(actualProps);

  const spacing = useFormControlSpacing(margin);

  return (
    <Box
      {...spacing}
      display="inline-flex"
      width={fullWidth ? '100%' : undefined}
      {...restBoxProps}
    >
      {children}
    </Box>
  );
}

function useDefaultProps({
  margin,
  fullWidth,
  ...restProps
}: FormControlSpacingProps) {
  const theme = useTheme();
  const themeProps = theme.components?.MuiFormControl?.defaultProps || {};

  return {
    ...restProps,
    margin: margin || themeProps.margin || 'none',
    fullWidth: fullWidth || themeProps.fullWidth || false,
  };
}
