import React from 'react';
import {
  AddIcon,
  NewHeader,
  NewHeaderAction,
  NewHeaderTitle,
  NewHeaderWrapper,
  Search,
  SearchIcon,
} from 'element';
import { Box, Button, IconButton, Tooltip } from 'extended-oxygen-elements';
import { useBulkSearchContext } from 'contexts';
import { useDispatch, useSelector } from 'react-redux';
import { BOX_REPORTS_SET_SEARCH_TERM } from 'state/Reports';
import { getSearchTerm } from 'state/Reports/selectors';
import { AllReportsListDropDown } from '../AllReportsListDropDown';
import { bindMenu, bindTrigger, usePopupState } from 'hooks';

type Props = {
  dropDownButtons: {
    label: string;
    onClick: () => void;
    icon: React.ReactNode;
  }[];
};

export const AllReportsHeader = ({ dropDownButtons }: Props) => {
  const bulkSearchContext = useBulkSearchContext();
  const searchTerm = useSelector(getSearchTerm);
  const isBulkActionOpen = bulkSearchContext.isEnabled || !!searchTerm;
  const dispatch = useDispatch();
  const menuPopupState = usePopupState();

  const makeHandleMenuClick = () => {
    menuPopupState.close();
  };

  const handleSearchChange = (searchWord: string) => {
    dispatch(BOX_REPORTS_SET_SEARCH_TERM(searchWord));
  };

  const handleSearchClick = () => {
    bulkSearchContext.enable();
  };

  return (
    <NewHeaderWrapper>
      <NewHeaderAction visible={isBulkActionOpen} style={{ padding: 0 }}>
        <Search searchQuery={searchTerm} updateQuery={handleSearchChange} />
      </NewHeaderAction>
      <NewHeader hidden={isBulkActionOpen}>
        <NewHeaderTitle>Reports</NewHeaderTitle>
        <Box ml="auto" display="flex" alignItems={'center'}>
          <Tooltip title={'Search'}>
            <IconButton
              aria-label="open search"
              onClick={handleSearchClick}
              sx={{ marginRight: '10px' }}
            >
              <SearchIcon style={{ fill: 'gray' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={'Generate new report'}>
            <Button
              aria-label={'Add'}
              variant={'contained'}
              sx={{
                minWidth: '50px',
                width: '50px',
                height: '50px',
              }}
              {...bindTrigger(menuPopupState)}
            >
              <AddIcon />
            </Button>
          </Tooltip>
          <AllReportsListDropDown
            menuState={bindMenu(menuPopupState)}
            handleButtonClick={makeHandleMenuClick}
            buttons={dropDownButtons}
          />
        </Box>
      </NewHeader>
    </NewHeaderWrapper>
  );
};
