import React from 'react';
import { Merge } from 'ts-essentials';
import { DefaultDivProps } from 'type';
import { createStyles, makeStyles } from '@mui/styles';
import { blackAlpha70, shadow200 } from '../constants';
import clsx from 'clsx';

type Props = Merge<
  DefaultDivProps,
  {
    children: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  }
>;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'absolute',
      left: 0,
      right: 0,
      zIndex: 1,
      backgroundColor: theme.palette.common.white,
      textAlign: 'center',
      boxShadow: shadow200,
      borderRadius: '0 0 2px 2px',
      fontSize: theme.typography.caption.fontSize,
      lineHeight: 18 / 12,
      letterSpacing: '0.5px',
      color: blackAlpha70,
      opacity: 0,
      visibility: 'hidden',
      transition: theme.transitions.create([
        'opacity',
        'visibility',
        'backgroundColor',
      ]),
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
      },
    },
  })
);

export const ShiftCardPopperAdd = React.memo(function PopperAdd({
  children,
  className,
  ...restDivProps
}: Props) {
  const classes = useStyles();

  return (
    <div {...restDivProps} className={clsx(classes.root, className)}>
      {children}
    </div>
  );
});
