import React from 'react';
import { useSelector } from 'react-redux';
import { activeSitesSelector } from 'state/AccountTree';

export default useSiteIdValidator;

export function useSiteIdValidator() {
  const activeSites = useSelector(activeSitesSelector);

  return React.useCallback(
    (siteId: string | undefined | null): siteId is string =>
      !!activeSites[siteId || ''],
    [activeSites]
  );
}
