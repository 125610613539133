import React from 'react';
import PayElement from './payElementRow/PayElement';
import EditPayElement from './payElementRow/EditPayElement';
import { usePayElementsListContext } from './pay-elements-list-context';

type PayElementRowProps = {
  id: string;
};

function PayElementRow({ id }: PayElementRowProps) {
  const { getIsEditing } = usePayElementsListContext();

  if (getIsEditing(id)) {
    return <EditPayElement id={id} />;
  }

  return <PayElement id={id} />;
}

export default PayElementRow;
