import React from 'react';
import { shiftCardViewClass } from '../../views';

export const useHandleClickContextMenu = () =>
  React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    const card = event.currentTarget.closest(`.${shiftCardViewClass}`);

    if (card) {
      const contextMenuEvent = new MouseEvent('contextmenu', {
        bubbles: true,
        cancelable: true,
        button: 2,
      });

      card.dispatchEvent(contextMenuEvent);
    }
  }, []);
