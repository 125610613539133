import { createAction } from 'lib/store-utils';
import { FormattedErrors } from 'type';
import {
  AllowancesModalAddedAllowancePayload,
  AllowancesModalDeleteAllowanceRequestPayload,
  AllowancesModalDeletedAllowancePayload,
  AllowancesModalOpenedPayload,
  AllowancesModalSuccessPayload,
  AllowancesModalUpdatedAllowanceQuantity
} from './types';

export const BOX_TIMESHEETS_ALLOWANCES_MODAL_OPENED = createAction<AllowancesModalOpenedPayload>('Timesheets / Allowances / modal opened');

export const BOX_TIMESHEETS_ALLOWANCES_MODAL_REQUEST = createAction('Timesheets / Allowances / modal request');
export const BOX_TIMESHEETS_ALLOWANCES_MODAL_SUCCESS = createAction<AllowancesModalSuccessPayload>('Timesheets / Allowances / modal success');

export const BOX_TIMESHEETS_ALLOWANCES_MODAL_ADDED_ALLOWANCE = createAction<AllowancesModalAddedAllowancePayload>('Timesheets / Allowances / added allowance');
export const BOX_TIMESHEETS_ALLOWANCES_MODAL_UPDATED_ALLOWANCE_QUANTITY = createAction<AllowancesModalUpdatedAllowanceQuantity>('Timesheets / Allowances / updated allowance quantity');
export const BOX_TIMESHEETS_ALLOWANCES_MODAL_DELETED_ALLOWANCE = createAction<AllowancesModalDeletedAllowancePayload>('Timesheets / Allowances / deleted allowance');

export const BOX_TIMESHEETS_ALLOWANCES_MODAL_UPDATE_TIMESHEET_REQUEST = createAction('Timesheets / Allowances / update timesheet request');
export const BOX_TIMESHEETS_ALLOWANCES_MODAL_UPDATE_TIMESHEET_SUCCESS = createAction('Timesheets / Allowances / update timesheet success');
export const BOX_TIMESHEETS_ALLOWANCES_MODAL_UPDATE_TIMESHEET_FAILURE = createAction<FormattedErrors>('Timesheets / Allowances / update timesheet failure');

export const BOX_TIMESHEETS_ALLOWANCES_MODAL_DELETE_ALLOWANCE_REQUEST = createAction<AllowancesModalDeleteAllowanceRequestPayload>('Timesheets / Allowances / update timesheet request');
export const BOX_TIMESHEETS_ALLOWANCES_MODAL_DELETE_ALLOWANCE_FAILURE = createAction<FormattedErrors>('Timesheets / Allowances / update timesheet failure');

export const BOX_TIMESHEETS_ALLOWANCES_MODAL_ERRORS_CLEARED = createAction('Timesheets / Allowances / errors cleared');
export const BOX_TIMESHEETS_ALLOWANCES_MODAL_CLOSED = createAction('Timesheets / Allowances / modal closed');
