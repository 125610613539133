import React from 'react';
import { isAppMarket } from '../../../../../helpers';

type Props = {
  time: string;
  type: string
};

export const LegendDurationHours = ({ time, type }: Props) => {
  const label = isAppMarket('uk') ? 'Rota hours' : 'Roster hours';
  return (
    <div className="duration">
      <div>
        {type === 'roster' && label}
        {type === 'timesheet' && 'Timesheet hours'}
      </div>
      <div>
        {time}
      </div>
    </div>
  );
}