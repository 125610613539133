import React, { Component } from 'react';

import FormItem from '../../FormItem';
import Datepicker from '../../Datepicker';
import { getClass } from '../../_lib/helper';

export type DateRangeDatepickerProps = {
  className: string;
  placeholder?: string;
  label?: string;
  value?: Date | null;
  onChange: (value: Date | null, name?: string) => void;
  disabledDate?: (value?: Date) => boolean;
  format?: string;
  updateParentDate: (value?: Date | null) => void;
  isClearable?: boolean;
  isDisabled?: boolean;
  isInline?: boolean;
  /**
   * Add `ariaLabel` prop (optional) for WCAG accessibility compliance
   * when DateRange input has no `label` prop or `label` and `ariaLabel` should be different
   */
  ariaLabel?: string;
};

class DateRangeDatepicker extends Component<DateRangeDatepickerProps> {
  componentDidUpdate(prevProps: DateRangeDatepickerProps) {
    const { updateParentDate, value } = this.props;

    if (value !== prevProps.value && updateParentDate) {
      updateParentDate(value);
    }
  }

  render() {
    const {
      className,
      placeholder,
      label,
      value,
      onChange,
      disabledDate,
      format,
      isClearable,
      isDisabled,
      isInline,
      ariaLabel,
    } = this.props;

    return (
      <div className={getClass('elmo-date-range-datepicker', className)}>
        {label && <FormItem.Label label={label} />}
        <Datepicker
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          disabledDate={disabledDate}
          format={format}
          isClearable={isClearable}
          isDisabled={isDisabled}
          isInline={isInline}
          ariaLabel={ariaLabel || label}
        />
      </div>
    );
  }
}

export default DateRangeDatepicker;
