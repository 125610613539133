import React from 'react';
import { connect } from 'react-redux';
import { FetchWrapper } from 'element';
import { MANAGER_DASHBOARD_SHIFT_OFFERS } from 'state/FetchPageData/constants';
import {
  BOX_SHIFT_OFFERS_GET_PAGE_DATA_REQUEST,
} from 'state/ManagerDashboard/ShiftOffers/ShiftOffers';
import {
  ManagerDashboardHeader,
  ManagerDashboardLayout
} from '../../../../components';
import View from './view';

type StateProps = {};

type DispatchProps = {
  fetchData: () => void;
};
type Props = StateProps & DispatchProps;

const ShiftOffers = (props: Props) => {
  return (
    <ManagerDashboardLayout header={<ManagerDashboardHeader />}>
      <FetchWrapper {...props} pageId={MANAGER_DASHBOARD_SHIFT_OFFERS}>
        <View />
      </FetchWrapper>
    </ManagerDashboardLayout>
  );
};

const mapDispatchToProps: DispatchProps = {
  fetchData: BOX_SHIFT_OFFERS_GET_PAGE_DATA_REQUEST
};

export default connect(
  null,
  mapDispatchToProps
)(ShiftOffers);
