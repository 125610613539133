import { styled } from 'extended-oxygen-elements';
import React from 'react';
import { Container, ContainerProps } from '../Container';

type LayoutContentProps = ContainerProps;

const LayoutContentRoot = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  [theme.breakpoints.up('sm')]: {
    paddingTop: theme.spacing(4.5),
    paddingBottom: theme.spacing(4.5),
  },
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(5.5),
    paddingBottom: theme.spacing(5.5),
  },
}));

export default function LayoutContent({
  children,
  ...containerProps
}: LayoutContentProps) {
  return (
    <LayoutContentRoot {...containerProps} data-testid="layout-content">
      {children}
    </LayoutContentRoot>
  );
}
