import React, { Component, ReactNode } from 'react';
import { connect, useSelector } from 'react-redux';
import { StoreState } from '../../../../../../state/types';
import { Duration } from '../../RosterEventsLegend';
import { LanguagePreferences } from '../../../../../../type/models';
import { EventsDurationSelector } from '../../../../../../state/Events/types';
import { capitalize } from 'lib/helpers';
import { map } from 'lodash';
import { getLangPreferences } from '../../../../../../state/Account';
import { eventsDurationSelector } from '../../../../../../state/Events/selectors';

type OwnProps = {
  children: (durations: Duration[]) => ReactNode;
};

type StateProps = {
  langPreferences: LanguagePreferences;
  eventsDuration: EventsDurationSelector;
};

type DispatchProps = {};

type Props = {
  children: any;
};

export const WithEventsDurations = ({ children }: Props) => {
  const langPreferences = useSelector(getLangPreferences);
  const eventsDuration = useSelector(eventsDurationSelector);

  const durations = (): Duration[] => {
    const { event } = langPreferences;

    const labels: { [key in keyof EventsDurationSelector]: string } = {
      total: 'Total',
      forecasts: 'Forecasts',
      events: capitalize(event.plural),
      actual: 'Actual',
    };

    return map(
      eventsDuration,
      (value: number, durationName: string): Duration => ({
        isVisible: true,
        value,
        title: labels[durationName as keyof EventsDurationSelector],
      })
    );
  };

  return children(durations());
};
