import React from 'react';
import { Card, withLayoutAware } from 'elmo-elements';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import { Props, State } from './type';
import { getOverview } from 'state/ManagerDashboard/Overview';
import { VerticalBar } from 'element';

export class StaffOnTimeComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const staff = this.props.overview.staff;

    this.state = {
      series: [
        { y: staff.today, name: 'Today', color: '#fec726' },
        { y: staff.week, name: 'This week', color: '#7ceab3' },
      ],
    };
  }

  componentDidUpdate(prevProps: Props): void {
    if (prevProps.overview !== this.props.overview) {
      const staff = this.props.overview.staff;
      this.setState({
        series: [
          { y: staff.today, name: 'Today', color: '#fec726' },
          { y: staff.week, name: 'This week', color: '#7ceab3' },
        ],
      });
    }
  }

  render() {
    return (
      <Card
        id={'widget-staff-time'}
        heading={
          <>
            <p>% Staff on Time</p>
            <span>&nbsp;</span>
          </>
        }
      >
        <VerticalBar id="staff-on-time" data={this.state.series} />
      </Card>
    );
  }
}

const mapStateToProps = (state: StoreState) => ({
  overview: getOverview(state),
});

export const StaffOnTime = connect(
  mapStateToProps,
  null
)(withLayoutAware(StaffOnTimeComponent));
