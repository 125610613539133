import { filter } from 'lodash';
import { Collection } from 'type';

const DIVIDERS = {
  space: ' ',
  dot: '.',
  comma: ',',
  colon: ':',
  underscore: '_',
  hyphen: '-'
};

type FormatTimeDivider = keyof typeof DIVIDERS;
export type FormatTimeTo12HoursOptions = Partial<Collection<FormatTimeDivider, boolean>>;

const getRegexp = (dividersOptions: FormatTimeTo12HoursOptions) => {
  const allowedDividers = filter(DIVIDERS, (dividerValue, dividerKey) => {
    const fromDividerOptions: boolean | undefined =
      dividersOptions[dividerKey as FormatTimeDivider];

    return typeof fromDividerOptions === 'boolean' ? fromDividerOptions : true;
  });
  const allowedCharactersPattern = `[^0123456789ap${allowedDividers.join()}]`;
  return new RegExp(allowedCharactersPattern, 'g');
};

export function formatTimeTo12Hours(
  time: any,
  dividersOptions: FormatTimeTo12HoursOptions = {}
): string | never {
  if (!time) {
    throwError();
  }

  const timeWithoutSpaces = time
      .replace(/ +/g, ' ') /* remove multiple spaces*/
      .trim() /* remove spaces from start and end */;

  const sanitizedTime = timeWithoutSpaces.replace(
    getRegexp(dividersOptions),
    ''
  ); /* remove not allowed chars */

  /* Find period */
  let period = sanitizedTime.replace(/[^ap]/g, '');

  switch (period) {
    case 'a':
      period = 'am';
      break;
    case 'p':
      period = 'pm';
      break;
    default:
      period = '';
  }

  /* Get hours digits */
  let digits = sanitizedTime.match(/\d+/g);

  if (!digits) {
    throwError();
  }

  if (digits.length === 1) {
    const maybeMilitaryFormatString = timeWithoutSpaces;
    const digitsQuantity = maybeMilitaryFormatString.length;

    if (digitsQuantity === 4) {
      const militaryHours = maybeMilitaryFormatString.substring(0, 2);
      const militaryMinutes = maybeMilitaryFormatString.substring(2);
      digits = [militaryHours, militaryMinutes];
    }
  }

  let hours = parseInt(digits[0], 10);

  if (hours > 24) {
    throwError();
  }

  /* convert hours to periods */
  if (hours > 11 && hours < 24) {
    if (hours !== 12 || period === '') {
      period = 'pm';
    }
    hours -= 12;
  } else if (!period.length) {
    period = 'am';
  }

  if (hours === 0 || hours === 24) {
    hours = 12;
  }

  let minutes = 0;
  if (digits[1]) {
    minutes = parseInt(digits[1], 10);

    if (minutes > 59) {
      throwError();
    }
  }

  return `${minTwoDigits(hours)}:${minTwoDigits(minutes)} ${period}`;
}

function minTwoDigits(n: number): string {
  return (n < 10 ? '0' : '') + n;
}

/*export function convertTime12to24(time12h: any) {
  if (!time12h) {
    return '';
  }

  const [time, period] = time12h.split(' ');

  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (period === 'pm') {
    hours = parseInt(hours, 10) + 12;
  }

  return hours + ':' + minutes;
}
export function convertTime24to12(time24h: any) {
  if (!time24h) {
    return '';
  }

  let period = 'am';
  let [hours, minutes] = time24h.split(':');

  hours = parseInt(hours, 10);
  if (hours === 12) {
    hours -= 12;
  }
  if (hours > 12) {
    hours -= 12;
    period = 'pm';
  }

  return (hours < 10 ? '0' : '') + hours + ':' + minutes + ' ' + period;
}*/

function throwError() {
  throw new Error('invalid time');
}
