import { PAGE_SIZE } from 'lib/config';
import { ShiftOffersReducerState } from './types';

export const getDefaultState = (): ShiftOffersReducerState => ({
  isUpdating: false,
  shiftOffers: {},
  selectedFilter: 'all',
  sort: {
    column: 'shift_start',
    direction: 'asc'
  },
  pager: {
    currentPage: 1,
    pageSize: PAGE_SIZE[0]
  }
});
