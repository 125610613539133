import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { SERVER_DAY_FORMAT, TIMESHEETS_CELL_WIDTH_PX } from 'lib/config';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { daysSelector } from 'state/TimesheetsWeeklyPage';

const isToday = (value: string) => {
  return moment().format(SERVER_DAY_FORMAT) === value;
};

const useDaysStyles = makeStyles(() => createStyles({
  wrapper: {
    display: 'flex'
  },
  dayCell: {
    width: `calc((100% - 185px) / 7)`,
    textAlign: 'center',
    fontSize: '14px',
    minWidth: TIMESHEETS_CELL_WIDTH_PX,
    '& span': {
      letterSpacing: '1px'
    }
  },
  leftCell: {
    width: 185,
    flexShrink: 0
  },
  todayCell: {
    display: 'inline-block',
    width: '80px',
    backgroundColor: '#EBF7FF',
    color: '#0062A3',
    borderRadius: '12px',
    padding: '3px 0'
  }
}));

export const Days = (() => {
  const styles = useDaysStyles();
  const days = useSelector(daysSelector);
  const format = (value: string) => moment(value, SERVER_DAY_FORMAT).format('ddd D');
  return (
    <div className={styles.wrapper}>
      <div className={styles.leftCell} />
      {
        days.map((weekDay, index) => (
          <div key={index} className={styles.dayCell}>
            <span className={isToday(weekDay) ? styles.todayCell : ''}>
             {
               format(weekDay)
             }
            </span>
          </div>
        ))
      }
    </div>
  );
});
