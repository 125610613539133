import { call, select } from 'redux-saga/effects';
import {
  EMPLOYEE_DASHBOARD_MY_ROSTERS,
  isPageFetchedSelector,
} from 'state/FetchPageData';
import { Handler } from './types';

const pageNotFetchedHandler = <P>(next: Handler<P>): Handler<P> =>
  function* (payload) {
    const isFetched: ReturnType<typeof isPageFetchedSelector> = yield select(
      isPageFetchedSelector,
      EMPLOYEE_DASHBOARD_MY_ROSTERS
    );

    if (!isFetched) {
      // Do nothing if the user is not on the My rosters page
      return;
    }

    yield call(next, payload);
  };

export default pageNotFetchedHandler;
