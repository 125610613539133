import React from 'react';
import { ShiftBreak } from '../../../../../../type/models';
import { convertDecimalToFormattedTime } from 'lib/helpers';

export const BreaksIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={'cup-icon-svg'}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66683 2H13.3335C14.0735 2 14.6668 2.59333 14.6668 3.33333V5.33333C14.6668 6.07333
      14.0735 6.66667 13.3335 6.66667H12.0002V8.66667C12.0002 10.14 10.8068 11.3333 9.3335
     11.3333H5.3335C3.86016 11.3333 2.66683 10.14 2.66683 8.66667V2ZM10.6668 8.66667V3.33333H4.00016V8.66667C4.00016
     9.4 4.60016 10 5.3335 10H9.3335C10.0668 10 10.6668 9.4 10.6668 8.66667ZM12.0002
     5.33333V3.33333H13.3335V5.33333H12.0002ZM1.3335 12.6667H13.3335V14H1.3335V12.6667Z"
      fill="black"
      fillOpacity="0.87"
    />
  </svg>
);

export const NoBreaksIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={'no-breaks-icon-svg'}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.60931 1.33325L0.666504 2.27606L3.33317 4.94273V8.94273C3.33317 10.4161 4.5265 11.6094
       5.99984 11.6094H9.99984L11.3332 12.9427H1.99984V14.2761H12.6665L13.6801 15.2897L14.6229
       14.3469L1.60931 1.33325ZM8.66651 10.2761L4.6665 6.27606V8.94273C4.6665 9.67606 5.2665
       10.2761 5.99984 10.2761H8.66651ZM11.3332 8.94273C11.3332 9.02042 11.3264 9.09661 11.3135
       9.17076L12.349 10.2062C12.5516 9.83024 12.6665 9.39997 12.6665 8.94273V6.94273H13.9998C14.7398
       6.94273 15.3332 6.34939 15.3332 5.60939V3.60939C15.3332 2.86939 14.7398 2.27606 13.9998
       2.27606H4.41882L5.75216 3.60939H11.3332V8.94273ZM12.6665 5.60939V3.60939H13.9998V5.60939H12.6665Z"
      fill="black"
      fillOpacity="0.87"
    />
  </svg>
);

type Props = {
  breaks?: ShiftBreak[];
};

export const BreaksWithDuration = ({ breaks }: Props) => {

  const breaksDuration = () => {
    if (breaks && breaks.length) {
      let sum = 0;
      breaks.forEach(b => sum += b.duration);
      const duration = convertDecimalToFormattedTime((sum / 60).toString(10));
      return <span className="breaks-duration">{duration}</span>;
    }
    return '';
  };

  return (
    <div className="breaks-cup">
      {breaks && breaks.length ? <BreaksIcon /> : <NoBreaksIcon />}{breaksDuration()}
    </div>
  );

};
