import * as React from 'react';
import clsx from 'clsx';
import { CloudUploadOutlinedIcon as CloudUploadIcon } from '../../icons';
import { DropAreaProps } from '../type';

export const DropArea: React.SFC<DropAreaProps> = ({
  isDragActive,
  getRootProps,
  getInputProps,
  dropareaText,
  draggingText,
}) => {
  const rootProps = getRootProps ? getRootProps() : null;

  return (
    <div
      {...rootProps}
      className={clsx('elmo-fileupload-droparea', {
        'dropzone--isActive': isDragActive,
      })}
    >
      {getInputProps && <input {...getInputProps()} />}

      <span className="cloudUploadIcon">
        <CloudUploadIcon />
      </span>
      {isDragActive ? <>{draggingText}</> : <>{dropareaText}</>}
    </div>
  );
};
