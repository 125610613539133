import * as React from 'react';
import { connect } from 'react-redux';
import { FetchWrapper, Layout } from 'element';
import { ACCOUNTS } from 'state/FetchPageData/constants';
import { BOX_ACCOUNTS_REQUEST } from 'state/Accounts';
import { AccountsHeader, AccountsListTable } from './components';
import { BulkSearchContextProvider } from 'contexts';

type Props = {
  fetchData: () => void;
};

const Accounts = (props: Props) => (
  <FetchWrapper {...props} pageId={ACCOUNTS}>
    <BulkSearchContextProvider>
      <Layout.Header>
        <AccountsHeader />
      </Layout.Header>
      <Layout.Content>
        <AccountsListTable />
      </Layout.Content>
    </BulkSearchContextProvider>
  </FetchWrapper>
);

const mapDispatchToProps: Props = {
  fetchData: BOX_ACCOUNTS_REQUEST,
};

export default connect(null, mapDispatchToProps)(Accounts);
