import React from 'react';
import { useDispatch } from 'react-redux';
import { BOX_ACCOUNT_PAY_CONDITIONS_RULE_UPDATE } from 'state/Account';
import { ShiftOvertimeRuleWithDay } from 'state/Account/types';
import WeekendLoadingForm, {
  WeekendLoadingFormProps,
} from '../components/WeekendLoadingForm';

type EditWeekendLoadingProps = {
  overtimeRule: ShiftOvertimeRuleWithDay;
};

function EditWeekendLoading({ overtimeRule }: EditWeekendLoadingProps) {
  const dispatch = useDispatch();

  const handleSubmit: WeekendLoadingFormProps['onSubmit'] = (payload) => {
    dispatch(
      BOX_ACCOUNT_PAY_CONDITIONS_RULE_UPDATE.request({
        ...payload,
        id: overtimeRule.id,
      })
    );
  };

  return (
    <WeekendLoadingForm onSubmit={handleSubmit} initialState={overtimeRule} />
  );
}

export default EditWeekendLoading;
