import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Report } from 'type';
import { StoreState } from 'state/types';
import {
  BOX_REPORTS_SAVE_MODAL_CLOSE,
  BOX_REPORTS_SAVE_REPORT_REQUEST
} from 'state/Reports';
import SaveReportModal from './components/SaveReportModal';
import { SaveReportRequest } from '../../../../state/Reports/type';

type StateProps = {
  isOpened: boolean;
  isLoading: boolean;
  errors: string[];
  report: Report;
  title?: string;
};

type DispatchProps = {
  closeModal: () => void;
  saveReport: ({ report, hasRedirect }: SaveReportRequest) => void;
};

type OwnProps = {
  redirectOnSuccess?: boolean;
};

type Props = StateProps & DispatchProps & OwnProps;

class SaveAs extends Component<Props> {
  render() {
    return (
      <SaveReportModal
        title={this.props.title || `Save report as`}
        errors={this.props.errors}
        isOpened={this.props.isOpened}
        isLoading={this.props.isLoading}
        onCloseModal={this.props.closeModal}
        onFormSubmit={this.onSave}
      />
    );
  }

  onSave = (name: string) => {
    this.props.saveReport({
      report: {
        ...this.props.report,
        name
      },
      hasRedirect: !!this.props.redirectOnSuccess
    });
  }
}

const mapStateToProps = ({
  reports: {
    isLoading,
    saveAsModal: { isOpened, errors, report, title }
  }
}: StoreState): StateProps => ({
  isOpened,
  isLoading,
  errors,
  report,
  title
});

const mapDispatchToProps: DispatchProps = {
  closeModal: BOX_REPORTS_SAVE_MODAL_CLOSE,
  saveReport: BOX_REPORTS_SAVE_REPORT_REQUEST
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SaveAs);
