import moment, { Moment } from 'moment';
import { TIME_FORMAT } from 'lib/config';
import { formatTimeTo12Hours, FormatTimeTo12HoursOptions } from 'lib/helpers';
import { getUpdatedValue } from './getUpdatedValue';

export const getDayBase = (
  prevValue: Moment | null,
  initialDayValue: Moment | undefined
): Moment | null => {
  if (prevValue) {
    return prevValue;
  }

  if (initialDayValue) {
    return initialDayValue;
  }

  return null;
};

type FormatMomentParams = {
  userInput: any;
  prevValue: Moment | null;
  initialDayValue: Moment | undefined;
  dividers?: FormatTimeTo12HoursOptions;
};

export const formatMoment = ({
  userInput,
  prevValue,
  initialDayValue,
  dividers
}: FormatMomentParams): Moment | never => {
  const parsedInput: string = formatTimeTo12Hours(userInput, dividers);
  const momentInput = moment(parsedInput, TIME_FORMAT.hours_12);

  const dayBase = getDayBase(prevValue, initialDayValue);

  return getUpdatedValue(dayBase, momentInput);
};
