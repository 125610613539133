import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelectorWithProps } from 'hooks';
import { getTimesheetEnd, getTimesheetStart } from 'state/TimesheetsCollection';
import { BOX_TIMESHEET_SHIFT_MODAL_GET_BY_ID } from 'state/TimesheetModal';

type Props = {
  id: string;
};

export const useHandleSimpleClick = ({ id }: Props) => {
  const dispatch = useDispatch();
  const start = useSelectorWithProps(getTimesheetStart, id);
  const end = useSelectorWithProps(getTimesheetEnd, id);
  return useCallback(() => {
    if (!end) {
      return;
    }

    dispatch(
      BOX_TIMESHEET_SHIFT_MODAL_GET_BY_ID({
        id,
        start,
        end,
      })
    );
  }, [dispatch, id, start.format(), end?.format()]);
};
