import React, { Component } from 'react';
import { Paragraph } from 'elmo-elements';
import { ShiftBreak } from 'type';
import { BreaksWithDuration } from '../BreaksIcons';

type Props = {
  title: string;
  icons?: React.ReactNode;
  from: string;
  to: string;
  messages?: number;
  canEditShift?: boolean;
  isApprovalMode: boolean;
  settings: any;
  eventName?: string;
  spotTitle?: string;
  breaks?: ShiftBreak[];
};

export class View extends Component<Props> {
  get specHeightClassName() {
    return this.toShowEvent ? 'shift-card__row--spec-height' : '';
  }

  render() {
    const { from, to } = this.props;

    const improveClassName = 'shift-card__content-improved-v2';
    return (
      <div
        className={`shift-card__content shift-card__content-improved ${improveClassName}`}
      >
        <div className="shift-card__location" />
        <div className="shift-card__descr">
          <div className={`shift-card__row ${this.specHeightClassName}`}>
            <div className="shift-card__title">
              <div className={'shift-card__time'}>
                {from}-{to}
              </div>
            </div>
          </div>

          {this.content()}
        </div>
      </div>
    );
  }

  get toShowEvent() {
    const { settings, eventName } = this.props;
    return (
      settings &&
      settings.show_event_name_boxes &&
      settings.enable_events &&
      eventName
    );
  }

  showEvent = () => {
    const { eventName } = this.props;
    return (
      this.toShowEvent && (
        <div className={`shift-card__row ${this.specHeightClassName}`}>
          <div className="shift-card__title" data-testid="shift-event-name">
            <Paragraph
              color={'black'}
              size={'xs'}
              isTruncate={true}
              isBold={true}
            >
              {eventName}
            </Paragraph>
          </div>
        </div>
      )
    );
  };

  content = () => {
    const { title, spotTitle, icons, breaks } = this.props;
    return (
      <>
        <div className={`shift-card__row ${this.specHeightClassName}`}>
          <div className="shift-card__title">
            <h3>
              {title} - {spotTitle}
            </h3>
          </div>
        </div>
        {this.showEvent()}
        <div
          className={`shift-card__row justify-content-between align-items-center ${this.specHeightClassName}`}
        >
          <BreaksWithDuration breaks={breaks} />
          <div className="shift-card__icons">{icons}</div>
        </div>
      </>
    );
  };
}
