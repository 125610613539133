import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserFieldsRosterViewSettingsPageType } from 'type';
import { privateRoutes as routes } from 'routes';
import { capitalize } from 'lib/helpers';
import { getLangPreferences } from 'state/Account';
import { BOX_ROSTERED_SHIFTS_RESET_DAY_TO_CURRENT } from 'state/RosteredShifts/actions'; // TODO double-check
import { isSiteView } from 'helpers';
import { useRoute } from 'hooks';
import { currentUserSelector } from 'state/Auth';

export const useRosterMenuItem = () => {
  const dispatch = useDispatch();
  const langPreferences = useSelector(getLangPreferences);
  const currentUser = useSelector(currentUserSelector);
  const router = useRoute();

  const rosterPaths = {
    week: routes.roster.routes.rosterWeekView.path,
    day: routes.roster.routes.rosterDayView.path,
  };

  const currentUserPageType =
    currentUser.preferences?.roster_view_settings?.page_type;

  const getPageType = useCallback(
    (): UserFieldsRosterViewSettingsPageType => currentUserPageType || 'week',
    [currentUserPageType]
  );

  const pageType = getPageType();
  const url = rosterPaths[pageType];

  const handleClick = useCallback(() => {
    router.goTo(url);
    dispatch(BOX_ROSTERED_SHIFTS_RESET_DAY_TO_CURRENT());
  }, [dispatch, router, url, pageType]);

  const getIsSelected = useCallback(
    (pathname: string) => pathname.includes(routes.roster.path),
    []
  );

  return useMemo(
    () => ({
      title: capitalize(langPreferences.roster.singular),
      handleClick,
      url,
      getIsSelected,
    }),
    [langPreferences, url, getIsSelected, handleClick]
  );
};
