export const SIZE_SMALL = 'small';
export const SIZE_MEDIUM = 'medium';
export const SIZE_LARGE = 'large';

export const STATUS_TYPE_WARNING = 'warning';
export const STATUS_TYPE_INFO = 'info';
export const STATUS_TYPE_SUCCESS = 'success';
export const STATUS_TYPE_DANGER = 'danger';

export const DIRECTION_VERTICAL = 'vertical';
export const DIRECTION_HORIZONTAL = 'horizontal';
export type DIRECTION = typeof DIRECTION_VERTICAL | typeof DIRECTION_HORIZONTAL

export const SORT_ASC = 'asc';
export const SORT_DESC = 'desc';
export type SORT = typeof SORT_ASC | typeof SORT_DESC;

export const TOTAL_GRID_COLS: number = 24;

/**
 * Pagination Constants
 */
export const PAGINATION_OPTIONS = [10, 20, 30, 40, 50, 100, 150, 200];
export const PAGINATION_FIRST_PAGE = 1;

export const TIMELINE_DEFAULT_COLOR = '#DBDBDB';
export const TIMELINE_ACTIVE_COLOR = '#058c45';
