import { createAction } from 'lib/store-utils';
import { FormattedErrors, ShiftTemplateItem } from 'type';
import {
  EditModalPayload,
  CreateShiftItemPayload,
  CreateShiftItemResponse,
  EditShiftItemPayload,
  EditShiftItemResponse,
  TemplatedData,
  DragDropSuccessTemplatedShift
} from './types';

export const BOX_EDIT_TEMPLATE_SET_TEMPLATE_ID = createAction<string>('Edit template | set template ID');
export const BOX_EDIT_TEMPLATE_GET_DATA = createAction<string>('Edit template | get template data');
export const BOX_EDIT_TEMPLATE_GET_DATA_SUCCESS = createAction<TemplatedData>('Edit template | get template data');
export const BOX_EDIT_TEMPLATE_GET_DATA_FAILURE = createAction<FormattedErrors>('Edit template | get template data');

export const BOX_EDIT_TEMPLATE_OPEN_EDIT_MODAL = createAction<EditModalPayload>('Edit Template | open edit modal');
export const BOX_EDIT_TEMPLATE_CLOSE_EDIT_MODAL = createAction('Edit Template | close edit modal');

export const BOX_EDIT_TEMPLATE_SHIFT_ITEM_ADD = createAction<CreateShiftItemResponse>('Edit template | shift item | add');

export const BOX_EDIT_TEMPLATE_CREATE_SHIFT_ITEM = createAction<CreateShiftItemPayload>('Edit template | create item request');
export const BOX_EDIT_TEMPLATE_CREATE_SHIFT_ITEM_SUCCESS = createAction('Edit template | create item success');
export const BOX_EDIT_TEMPLATE_CREATE_SHIFT_ITEM_FAILURE = createAction<FormattedErrors>('Edit template | create item failure');

export const BOX_EDIT_TEMPLATE_CLEAR_MODAL_ERRORS = createAction('Edit Template | clear errors');

export const BOX_EDIT_TEMPLATE_EDIT_SHIFT_ITEM = createAction<EditShiftItemPayload>('Edit template | edit item request');
export const BOX_EDIT_TEMPLATE_EDIT_SHIFT_ITEM_SUCCESS = createAction<EditShiftItemResponse>('Edit template | edit item success');
export const BOX_EDIT_TEMPLATE_EDIT_SHIFT_ITEM_FAILURE = createAction<FormattedErrors>('Edit template | edit item failure');

export const BOX_EDIT_TEMPLATE_DELETE_CONFIRM_SHIFT_ITEM = createAction<string>('Edit template | delete item request');
export const BOX_EDIT_TEMPLATE_OPEN_DELETE_MODAL = createAction('Edit Template | open confirmation delete modal');
export const BOX_EDIT_TEMPLATE_CONFIRM_DELETE_MODAL = createAction('Edit Template | open confirmation delete modal');
export const BOX_EDIT_TEMPLATE_CLOSE_DELETE_MODAL = createAction('Edit Template | close confirmation delete modal');

export const BOX_EDIT_TEMPLATE_DELETE_SHIFTS = createAction<string[]>('Edit Template | delete shifts');
export const BOX_EDIT_TEMPLATE_DELETE_SHIFT_ITEM_FAILURE_CONFIRMATION = createAction<FormattedErrors>('Edit template | delete with confirmation  item failure');
export const BOX_EDIT_TEMPLATE_CLEAR_DELETE_MODAL_ERRORS = createAction('Edit Template | clear delete modal errors');

export const BOX_EDIT_TEMPLATE_DELETE_SHIFT_ITEM = createAction<string>('Edit template | delete item request');
export const BOX_EDIT_TEMPLATE_DELETE_SHIFT_ITEM_SUCCESS = createAction<string>('Edit template | delete item success');
export const BOX_EDIT_TEMPLATE_DELETE_SHIFT_ITEM_FAILURE = createAction<FormattedErrors>('Edit template | delete item failure');

export const BOX_EDIT_TEMPLATE_SET_PREVIOUS_PAGE = createAction<string | null>('Edit template | define previous page');

export const BOX_EDIT_TEMPLATE_OPEN_DELETE_TEMPLATE_MODAL = createAction('Edit Template | open confirmation delete template modal');
export const BOX_EDIT_TEMPLATE_CONFIRM_DELETE_TEMPLATE_MODAL = createAction('Edit Template | open confirmation delete template modal');
export const BOX_EDIT_TEMPLATE_CLOSE_DELETE_TEMPLATE_MODAL = createAction('Edit Template | close confirmation delete template modal');

export const BOX_EDIT_TEMPLATE_DELETE_SUCCESS = createAction('Edit template | delete template success');
export const BOX_EDIT_TEMPLATE_DELETE_FAILURE = createAction<FormattedErrors>('Edit template | delete template failure');
export const BOX_EDIT_TEMPLATE_DELETE_CLEAR_ERRORS = createAction('Edit template | delete template clear errors');

export const BOX_EDIT_TEMPLATE_SET_DRAGGABLE_SHIFT = createAction<null | Partial<ShiftTemplateItem>>('Edit template | set draggable shift');

export const BOX_EDIT_TEMPLATE_DRAG_N_DROP = createAction<Partial<ShiftTemplateItem>>('Edit template | drag n drop  apply');
export const BOX_EDIT_TEMPLATE_DRAG_N_DROP_SUCCESS = createAction<DragDropSuccessTemplatedShift>('Edit template | drag n drop apply success');
export const BOX_EDIT_TEMPLATE_DRAG_N_DROP_FAILURE = createAction<FormattedErrors>('Edit template | drag n drop apply failure');

export const BOX_EDIT_TEMPLATE_SET_DROP_INPROGRESS = createAction<boolean>('Edit template | set drop in progress state');
export const BOX_EDIT_TEMPLATE_SET_DROP_CANCELED = createAction<boolean>('Edit template | set drop canceled state');

export const BOX_EDIT_TEMPLATE_OPEN_UPDATE_TEMPLATE_MODAL = createAction('Edit Template | open confirmation update template modal');
export const BOX_EDIT_TEMPLATE_CONFIRM_UPDATE_TEMPLATE_MODAL = createAction<string>('Edit Template | open confirmation update template modal');
export const BOX_EDIT_TEMPLATE_CLOSE_UPDATE_TEMPLATE_MODAL = createAction('Edit Template | close confirmation update template modal');

export const BOX_EDIT_TEMPLATE_UPDATE_SUCCESS = createAction<string>('Edit template | update template success');
export const BOX_EDIT_TEMPLATE_UPDATE_FAILURE = createAction<FormattedErrors>('Edit template | update template failure');
export const BOX_EDIT_TEMPLATE_UPDATE_CLEAR_ERRORS = createAction('Edit template | update template clear errors');

export const BOX_EDIT_TEMPLATE_OPEN_SAVE_AS_TEMPLATE_MODAL = createAction('Edit Template | open confirmation save as template modal');
export const BOX_EDIT_TEMPLATE_CONFIRM_SAVE_AS_TEMPLATE_MODAL = createAction<string>('Edit Template | open confirmation save as template modal');
export const BOX_EDIT_TEMPLATE_CLOSE_SAVE_AS_TEMPLATE_MODAL = createAction('Edit Template | close confirmation save as template modal');

export const BOX_EDIT_TEMPLATE_SAVE_AS_SUCCESS = createAction<TemplatedData>('Edit template | save as template success');
export const BOX_EDIT_TEMPLATE_SAVE_AS_FAILURE = createAction<FormattedErrors>('Edit template | save as template failure');
export const BOX_EDIT_TEMPLATE_SAVE_AS_CLEAR_ERRORS = createAction('Edit template | save as template clear errors');

export const BOX_EDIT_TEMPLATE_MODAL_PASTE_DATA = createAction<ShiftTemplateItem>('Edit template modal / paste item inside modal');
