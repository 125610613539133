import React from 'react';
import { Avatar, Button, Popover } from 'oxygen-elements';
import { bindPopover, bindTrigger, usePopupState } from 'hooks';
import {
  BOX_MY_ROSTERS_WHO_ELSE_WORKING_REQUEST,
  getWhoElseWorking,
  getWhoElseWorkingErrors,
  getWhoElseWorkingIsLoading,
} from 'state/EmployeeDashboard/MyRosters';
import moment from 'moment';
import {
  CenteredCircularProgress,
  CoPresentOutlinedIcon,
  ErrorBox,
} from 'element';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@mui/styles';
import { getTimeFormat } from 'state/Account';
import { getTimeFormatted } from 'lib/helpers';
import { SERVER_DAY_FORMAT } from 'lib/config';

type Props = {
  date: string;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      padding: '10px',
      minWidth: '290px',
      maxWidth: '290px',
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '5px 5px 5px 0',
      borderBottom: '1px solid #ccc',
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    info: {
      paddingLeft: '10px',
      fontSize: '13px',
      lineHeight: '16px',
    },
    time: {
      fontWeight: '500',
    },
    role: {
      color: '#7a7a7a',
      paddingTop: '3px',
    },
    button: {
      padding: '5px 7px',
      marginLeft: '-7px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      fontSize: '13px',
      color: '#19579f',
      whiteSpace: 'nowrap',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    icon: {
      marginRight: '5px',
    },
    placeholder: {
      fontSize: '13px',
    },
  })
);

const anchorSettings: any = {
  vertical: 'bottom',
  horizontal: 'center',
};

const Placeholder = () => {
  const styles = useStyles();
  return (
    <div className={styles.placeholder}>
      Looks like no one else is working with you
    </div>
  );
};

const WhoElseButton = ({ popupState }: any) => {
  const styles = useStyles();
  return (
    <Button
      data-testid={'who-else-button'}
      className={styles.button}
      variant={'text'}
      disableRipple={true}
      {...popupState}
    >
      <CoPresentOutlinedIcon className={styles.icon} />
      Who else is working with me?
    </Button>
  );
};

const WhoElseDetails = ({ details }: any) => {
  const styles = useStyles();
  const timeFormat = useSelector(getTimeFormat);
  return (
    <>
      {details.map((data: any, key: number) => (
        <div key={`desc-${key}`} className={styles.item}>
          <Avatar
            variant={'circular'}
            src={data.avatar}
            alt={`${data.first_name} ${data.last_name}`}
          />
          <div className={styles.info}>
            <div className={styles.time} data-testid={'time-info'}>
              {getTimeFormatted(timeFormat, data.start)} -{' '}
              {getTimeFormatted(timeFormat, data.end)}
            </div>
            <div data-testid={'username'}>
              {data.first_name} {data.last_name}
            </div>
            <div className={styles.role} data-testid={'role-info'}>
              {data.role.name} at {data.area.name}, {data.area.site.name}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export const WhoElseWorking = ({ date }: Props) => {
  const styles = useStyles();
  const popupState = usePopupState();

  const isLoading = useSelector(getWhoElseWorkingIsLoading);
  const whoElseData = useSelector(getWhoElseWorking);
  const errors = useSelector(getWhoElseWorkingErrors);
  const dispatch = useDispatch();

  const handlePopupOpen = (e: any) => {
    popupState.open(e);
    dispatch(
      BOX_MY_ROSTERS_WHO_ELSE_WORKING_REQUEST({
        date: moment(date, SERVER_DAY_FORMAT).format(SERVER_DAY_FORMAT),
      })
    );
  };

  const WhoElseContent = () => (
    <div>
      {whoElseData.length ? (
        <WhoElseDetails details={whoElseData} />
      ) : (
        <Placeholder />
      )}
    </div>
  );

  return (
    <div>
      <WhoElseButton
        date={date}
        popupState={bindTrigger({
          ...popupState,
          open: handlePopupOpen,
        })}
      />
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={anchorSettings}
        data-testid="who-else-working-popover"
      >
        <div className={styles.wrapper}>
          {errors.length > 0 ? (
            <ErrorBox errors={errors} clearErrors={popupState.close} />
          ) : null}
          {isLoading ? <CenteredCircularProgress /> : <WhoElseContent />}
        </div>
      </Popover>
    </div>
  );
};
