import React from 'react';
import { ActionButtonProps, StopButton } from './components';

export function InProgressActions(props: ActionButtonProps) {
  return (
    <>
      <StopButton {...props} />
    </>
  );
}
