import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment, { Duration, Moment } from 'moment';
import { Route, StringMap } from 'type';
import { NestedRoutes } from 'element';
import { StoreState } from 'state/types';
import { currentTimeBySiteTimezoneSelector } from 'state/Roster/Roster/selectors';
import { oneMinuteInPxSelector } from 'state/Roster/RosterDayView';
import {
  ApprovalModeAlert,
  RosterViewControls,
  RosterViewScrollableWrapper
} from 'page/Roster/components';
import { DayConfirmModal, DayErrorModal, DayNavigation } from './components';
import DaySummary from './components/DaySummary';
import PrintRosters from '../../components/PrintRosters';
import { privateRoutes } from 'routes';

export type OwnProps = {
  routes: StringMap<Route>;
};

type StateProps = {
  currentTime: Moment;
  oneMinuteInPx: number;
};

type DispatchProps = {};

export type Props = OwnProps & StateProps & DispatchProps;

class RosterDayViewView extends Component<Props> {
  render() {
    let { routes } = this.props;

    const { rosterDayViewUserView, rosterDayViewSiteView } =
      privateRoutes.roster.routes.rosterDayView.routes;

    return (
      <>
        <PrintRosters />

        <ApprovalModeAlert />

        <RosterViewControls
          paths={{
            userView: rosterDayViewUserView.path,
            siteView: rosterDayViewSiteView.path,
          }}
          pageType="day"
          hidePageTypeSwitcher={false}
        />

        <DaySummary />

        <RosterViewScrollableWrapper
          header={<DayNavigation />}
          onMount={[this.scrollToCurrentTime]}
        >
          <NestedRoutes routes={routes} />
        </RosterViewScrollableWrapper>

        <DayErrorModal />

        <DayConfirmModal />
      </>
    );
  }

  private scrollToCurrentTime = ({ content }: { content: HTMLDivElement }) => {
    const { currentTime, oneMinuteInPx } = this.props;

    const dayStart: Moment = currentTime.clone().startOf('day');
    const dayHours: Moment = currentTime.clone().startOf('hours');
    const dayTimeDiff: number = dayHours.diff(dayStart);
    const dayTimeDuration: Duration = moment.duration(dayTimeDiff);
    const minutes: number = dayTimeDuration.asMinutes();

    content.scrollLeft = minutes * oneMinuteInPx;
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  currentTime: currentTimeBySiteTimezoneSelector(state),
  oneMinuteInPx: oneMinuteInPxSelector(state),
});

// const mapDispatchToProps: DispatchProps = {};

export default connect(mapStateToProps)(RosterDayViewView);
