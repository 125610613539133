import Api from 'lib/Api';
import { SagaIterator } from 'redux-saga';
import { put, select, takeLatest } from 'redux-saga/effects';
import { getTimesheetSettings } from 'state/Account';
import { formatError } from 'state/helpers';
import { apiWithConfirmCall } from 'state/ProcessApiRequest';
import { ApiWithConfirmReturnType, SagaActionFromCreator } from 'type';
import * as actions from '../actions';

function* updateTimeClockSettings({
  payload,
}: SagaActionFromCreator<
  typeof actions.BOX_ACCOUNT_TIMECLOCK_SETTINGS_UPDATE.request
>) {
  try {
    const {
      self_assigned_shifts,
      allow_start_within,
      only_managers_can_activate,
      ...rest
    } = payload;
    const response: ApiWithConfirmReturnType<typeof Api.Account.update> =
      yield apiWithConfirmCall(Api.Account.update, {
        punchclock_settings: {
          self_assigned_shifts,
          allow_start_within,
          only_managers_can_activate,
        },
      });

    if (response) {
      yield put(
        actions.BOX_ACCOUNT_TIMECLOCK_SETTINGS_UPDATE.success(response)
      );
    } else {
      yield put(actions.BOX_ACCOUNT_TIMECLOCK_SETTINGS_UPDATE.cancel());
    }
  } catch (error) {
    yield put(
      actions.BOX_ACCOUNT_TIMECLOCK_SETTINGS_UPDATE.failure(formatError(error))
    );
  }
}

export default function* () {
  yield takeLatest(
    actions.BOX_ACCOUNT_TIMECLOCK_SETTINGS_UPDATE.request,
    updateTimeClockSettings
  );
}
