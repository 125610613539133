import {
  FetchContainer,
  FilterListIcon,
  Layout,
  NewHeader,
  NewHeaderTitle,
  NewHeaderWrapper,
} from 'element';
import { IconButton, Stack } from 'extended-oxygen-elements';
import { capitalize } from 'lib/helpers';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getLangPreferences } from 'state/Account';
import {
  BOX_ACCOUNT_TREE_PAGE_DATA_GET,
  getFetchErrors,
  getPageFetching,
  siteIdsSelector,
} from 'state/AccountTree';
import { SettingsHeaderBackBtn } from '../../components';
import PageSkeleton from './components/PageSkeleton';
import SectionSite from './components/SectionSite';
import { Filters } from './components/Filters';

export default function AccountTree() {
  return (
    <FetchContainer
      action={BOX_ACCOUNT_TREE_PAGE_DATA_GET.request()}
      loadingSelector={getPageFetching}
      errorsSelector={getFetchErrors}
      skeletonComponent={PageSkeleton}
    >
      <AccountTreeView />
    </FetchContainer>
  );
}

export function AccountTreeView() {
  const siteIds = useSelector(siteIdsSelector);
  const langPreferences = useSelector(getLangPreferences);
  const locationTitle = capitalize(langPreferences.site.singular);
  const departmentTitle = capitalize(langPreferences.area.singular);
  const roleTitle = capitalize(langPreferences.role.singular);

  const [isOpened, setIsOpened] = useState(false);

  return (
    <>
      <Layout.Header>
        <NewHeaderWrapper>
          <NewHeader>
            <SettingsHeaderBackBtn />
            <NewHeaderTitle>
              {locationTitle}, {departmentTitle}, {roleTitle} settings
            </NewHeaderTitle>
            <IconButton
              onClick={() => setIsOpened(true)}
              color={'inherit'}
              size={'medium'}
              data-testid={'account-tree-filters-btn'}
              sx={{
                borderRadius: '3px',
                marginLeft: 'auto',
                backgroundColor: '#fff',
                boxShadow: '0 0 3px #ccc',
              }}
            >
              <FilterListIcon />
            </IconButton>
          </NewHeader>
        </NewHeaderWrapper>
      </Layout.Header>
      <Layout.Content>
        <Stack spacing={3}>
          {siteIds.map((site_id) => (
            <SectionSite id={site_id} key={site_id} />
          ))}
        </Stack>
        <Filters isOpened={isOpened} onClose={() => setIsOpened(false)} />
      </Layout.Content>
    </>
  );
}
