import { Api } from 'lib/Api';
import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { formatError } from 'state/helpers';
import { apiCall } from 'state/ProcessApiRequest';
import { getRotaUserListRequest } from 'state/UsersCollection';
import { ApiReturnType, SagaActionFromCreator } from 'type';
import * as actions from './actions';

const getAccountTreePageData = function* (): SagaIterator {
  try {
    const [assignments]: [
      ApiReturnType<typeof Api.AccountTree.getAssignments>
    ] = yield all([
      apiCall(Api.AccountTree.getAssignments),
      call(getRotaUserListRequest),
    ]);
    yield put(actions.BOX_ACCOUNT_TREE_PAGE_DATA_GET.success({ assignments }));
  } catch (error) {
    yield put(
      actions.BOX_ACCOUNT_TREE_PAGE_DATA_GET.failure(formatError(error))
    );
  }
};

const linkRoles = function* ({
  payload,
}: SagaActionFromCreator<
  typeof actions.BOX_ACCOUNT_TREE_ROLES_LINK.request
>): SagaIterator {
  try {
    yield apiCall(Api.AccountTree.linkRoles, payload);

    const [assignments, accountTree]: [
      ApiReturnType<typeof Api.AccountTree.getAssignments>,
      ApiReturnType<typeof Api.AccountTree.getAccountTree>
    ] = yield all([
      apiCall(Api.AccountTree.getAssignments),
      apiCall(Api.AccountTree.getAccountTree),
    ]);

    yield put(
      actions.BOX_ACCOUNT_TREE_ROLES_LINK.success({ assignments, accountTree })
    );
  } catch (error) {
    yield put(actions.BOX_ACCOUNT_TREE_ROLES_LINK.failure(formatError(error)));
  }
};

const unlinkRole = function* ({
  payload,
}: SagaActionFromCreator<
  typeof actions.BOX_ACCOUNT_TREE_ROLES_UNLINK.request
>): SagaIterator {
  try {
    yield apiCall(Api.AccountTree.unlinkRole, payload);

    const [assignments, accountTree]: [
      ApiReturnType<typeof Api.AccountTree.getAssignments>,
      ApiReturnType<typeof Api.AccountTree.getAccountTree>
    ] = yield all([
      apiCall(Api.AccountTree.getAssignments),
      apiCall(Api.AccountTree.getAccountTree),
    ]);

    yield put(
      actions.BOX_ACCOUNT_TREE_ROLES_UNLINK.success({
        assignments,
        accountTree,
      })
    );
  } catch (error) {
    yield put(
      actions.BOX_ACCOUNT_TREE_ROLES_UNLINK.failure(formatError(error))
    );
  }
};

const updateAreaStatus = function* ({
  payload: { area_id, archived },
}: SagaActionFromCreator<
  typeof actions.BOX_ACCOUNT_TREE_UPDATE_AREA_STATUS.request
>): SagaIterator {
  try {
    yield apiCall(Api.AccountTree.updateArea, { id: area_id, archived });

    const [assignments, accountTree]: [
      ApiReturnType<typeof Api.AccountTree.getAssignments>,
      ApiReturnType<typeof Api.AccountTree.getAccountTree>
    ] = yield all([
      apiCall(Api.AccountTree.getAssignments),
      apiCall(Api.AccountTree.getAccountTree),
    ]);

    yield put(
      actions.BOX_ACCOUNT_TREE_UPDATE_AREA_STATUS.success({
        assignments,
        accountTree,
      })
    );
  } catch (error) {
    yield put(
      actions.BOX_ACCOUNT_TREE_UPDATE_AREA_STATUS.failure(formatError(error))
    );
  }
};

const updateSiteStatus = function* ({
  payload: { site_id, archived },
}: SagaActionFromCreator<
  typeof actions.BOX_ACCOUNT_TREE_UPDATE_SITE_STATUS.request
>): SagaIterator {
  try {
    yield apiCall(Api.AccountTree.updateSite, { id: site_id, archived });

    const [assignments, accountTree]: [
      ApiReturnType<typeof Api.AccountTree.getAssignments>,
      ApiReturnType<typeof Api.AccountTree.getAccountTree>
    ] = yield all([
      apiCall(Api.AccountTree.getAssignments),
      apiCall(Api.AccountTree.getAccountTree),
    ]);

    yield put(
      actions.BOX_ACCOUNT_TREE_UPDATE_SITE_STATUS.success({
        assignments,
        accountTree,
      })
    );
  } catch (error) {
    yield put(
      actions.BOX_ACCOUNT_TREE_UPDATE_SITE_STATUS.failure(formatError(error))
    );
  }
};

const linkAreas = function* ({
  payload,
}: SagaActionFromCreator<
  typeof actions.BOX_ACCOUNT_TREE_AREAS_LINK.request
>): SagaIterator {
  try {
    yield apiCall(Api.AccountTree.linkAreas, payload);

    const [assignments, accountTree]: [
      ApiReturnType<typeof Api.AccountTree.getAssignments>,
      ApiReturnType<typeof Api.AccountTree.getAccountTree>
    ] = yield all([
      apiCall(Api.AccountTree.getAssignments),
      apiCall(Api.AccountTree.getAccountTree),
    ]);

    yield put(
      actions.BOX_ACCOUNT_TREE_AREAS_LINK.success({ assignments, accountTree })
    );
  } catch (error) {
    yield put(actions.BOX_ACCOUNT_TREE_AREAS_LINK.failure(formatError(error)));
  }
};

export const watchAccountTree = function* (): SagaIterator {
  yield takeLatest(
    actions.BOX_ACCOUNT_TREE_PAGE_DATA_GET.request,
    getAccountTreePageData
  );
  yield takeLatest(actions.BOX_ACCOUNT_TREE_ROLES_LINK.request, linkRoles);
  yield takeLatest(actions.BOX_ACCOUNT_TREE_ROLES_UNLINK.request, unlinkRole);
  yield takeLatest(
    actions.BOX_ACCOUNT_TREE_UPDATE_AREA_STATUS.request,
    updateAreaStatus
  );
  yield takeLatest(
    actions.BOX_ACCOUNT_TREE_UPDATE_SITE_STATUS.request,
    updateSiteStatus
  );
  yield takeLatest(actions.BOX_ACCOUNT_TREE_AREAS_LINK.request, linkAreas);
};
