import React from 'react';
import { connect } from 'react-redux';
import { Heading, Paragraph, Text } from 'elmo-elements';
import {
  AccountTreeArea,
  AccountTreeRole,
  AccountTreeSite,
  ShiftOfferWithProposals,
  StringMap
} from 'type';
import { StoreState } from 'state/types';
import { getDateFormat, timeFormatSelector } from 'state/Account';
import { getShiftOfferWithProposals } from 'state/ManagerDashboard/ShiftOffers/ShiftOffer';
import { IsAppMarket } from 'element';
import { DSTIcon } from 'element/DSTIcon';
import { NotesIcon } from '../../../../../../../../element/NotesIcon';
import { getAreas, getRoles, getSites } from 'state/AccountTree';

type OwnProps = {
  className?: string;
};

type StateProps = {
  shiftOfferWithProposals: ShiftOfferWithProposals;
  sites: StringMap<AccountTreeSite>;
  areas: StringMap<AccountTreeArea>;
  roles: StringMap<AccountTreeRole>;
  dateFormat: string;
  timeFormat: string;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

const ShiftOfferInfoComponent = ({
  shiftOfferWithProposals: {
    site_id,
    area_id,
    role_id,
    shift_start,
    shift_end,
    timezone,
    is_dst_intersect,
    notes
  },
  sites,
  areas,
  roles,
  dateFormat,
  timeFormat,
  className
}: Props) => (
  <div className={className}>
    <Heading>
      <span data-testid="position">{roles[role_id].name}</span> -{' '}
      <span data-testid="area">{areas[area_id].name}</span>,{' '}
      <span data-testid="location">{sites[site_id].name}</span>
    </Heading>
    <Paragraph id="offer-subtitle">
      <span data-testid="shift-start">{shift_start.format(`ddd, ${dateFormat}, ${timeFormat}`)}</span> -{' '}
      <span data-testid="shift-time-end">{shift_end.format(timeFormat)}</span>{' '}
      <IsAppMarket market={'uk'} negate={true}>
        <Text id="timezone" color="gray" size="sm">
          ({timezone})
        </Text>
      </IsAppMarket>
      <DSTIcon isDST={is_dst_intersect} offsetLeft={2} offsetTop={0} />
    </Paragraph>
    <div>
      <NotesIcon notes={notes} styles={{padding: 0}} />
    </div>
  </div>
);

const mapStateToProps = (state: StoreState): StateProps => {
  return {
    shiftOfferWithProposals: getShiftOfferWithProposals(state),
    sites: getSites(state),
    areas: getAreas(state),
    roles: getRoles(state),
    dateFormat: getDateFormat(state),
    timeFormat: timeFormatSelector(state)
  };
};

export const ShiftOfferInfo = connect(mapStateToProps)(ShiftOfferInfoComponent);
