import makeUniqIdProvider from 'lib/makeUniqIdProvider';
import {
  createReducer,
  EmptyActionCreator,
  SimpleActionCreator,
} from 'lib/store-utils';
import _ from 'lodash';
import { StoreState } from 'state/types';

type DialogReducerState = {
  openDialogs: string[];
};

export const dialog = createReducer<DialogReducerState>(
  {},
  { openDialogs: [] }
);
const getState = (state: StoreState): DialogReducerState => state.dialog;
const getIsDialogOpen = (state: StoreState, id: string) =>
  getState(state).openDialogs.includes(id);

const uniqIdProvider = makeUniqIdProvider();

export function DialogHandler(optionalId?: string) {
  const id = uniqIdProvider(optionalId);

  const onOpen = (state: DialogReducerState): DialogReducerState => ({
    ...state,
    openDialogs: [...state.openDialogs, id],
  });

  const onClose = (state: DialogReducerState): DialogReducerState => ({
    ...state,
    openDialogs: _.without(state.openDialogs, id),
  });

  return {
    open(action: EmptyActionCreator | SimpleActionCreator<any>) {
      dialog.on(action, onOpen);

      return this;
    },
    close(action: EmptyActionCreator | SimpleActionCreator<any>) {
      dialog.on(action, onClose);

      return this;
    },
    makeSelector() {
      return (state: StoreState) => getIsDialogOpen(state, id);
    },
  };
}
