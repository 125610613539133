import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BOX_SHIFT_TIME_RANGE_INPUT_CLOSED } from 'state/Roster/RangeInput/ShiftTimeRangeInput';
import { getIsApprovalModeEnabled } from 'state/Roster/Roster';
import { getIsCovidModeEnabled } from 'state/Roster/CovidTracing';
import { ShiftCardViewProps } from '../../../views';
import {
  useCanEditTimesheet,
  useHandleApprovalClick,
  useHandleCovidModeClick,
  useHandleSimpleClick,
  useHandleClickOnBulk
} from './hooks';
import { getIsBulkDeleteOpened } from 'state/Roster/BulkActions';

type Props = {
  id: string;
};

export const useHandleCardClick = (props: Props) => {
  const dispatch = useDispatch();
  const isBulkDelete = useSelector(getIsBulkDeleteOpened);
  const isCovidMode = useSelector(getIsCovidModeEnabled);
  const isApprovalMode = useSelector(getIsApprovalModeEnabled);
  const canEditTimesheet = useCanEditTimesheet(props);
  const handleApprovalClick = useHandleApprovalClick(props);
  const handleSimpleClick = useHandleSimpleClick(props);
  const handleCovidModeClick = useHandleCovidModeClick(props);
  const handleClickOnBulkDelete = useHandleClickOnBulk(props);

  return useCallback(() => {
    dispatch(BOX_SHIFT_TIME_RANGE_INPUT_CLOSED()); // TODO Do we need this here?

    if (isBulkDelete) {
      handleClickOnBulkDelete();
      return;
    }

    if (isCovidMode) {
      handleCovidModeClick();
      return;
    }

    if (!canEditTimesheet) {
      return;
    }

    if (isApprovalMode) {
      handleApprovalClick();
      return;
    }

    handleSimpleClick();
  }, [
    dispatch,
    canEditTimesheet,
    isApprovalMode,
    handleApprovalClick,
    handleSimpleClick,
    isCovidMode,
    handleCovidModeClick,
    handleClickOnBulkDelete,
    isBulkDelete
  ]);
}

export const useHandleClick = function (
  props: Props
): ShiftCardViewProps['onClick'] {
  const handleClick = useHandleCardClick(props);

  return useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      // TODO refactor
      const isContextMenuClicked = (
        event.target as HTMLDivElement
      ).classList.contains('oxygen-dropdown-base__menu-item');

      if (isContextMenuClicked) {
        return;
      }

      handleClick();
    },
    [handleClick]
  );
};
