import React, { Component } from 'react';
import { connect } from 'react-redux';
import { map, pickBy } from 'lodash';
import { HeaderButtonProp } from 'element/Header/type';
import { ErrorBox, Layout } from 'element';
import {
  ReportsWithPagerSelector,
  ReportType,
  ReportTypesSelector,
} from 'type';
import { StoreState } from 'state/types';
import {
  BOX_REPORTS_CLEAR_ERRORS,
  BOX_REPORTS_CREATE_MODAL_OPEN,
  BOX_REPORTS_SET_SEARCH_TERM,
} from 'state/Reports';
import { reportsWithPagerSelector } from 'state/Reports/selectors';
import ReportModal from '../../components/ReportModal';
import ListTable from './components/ListTable';
import ReportsPager from './components/ReportsPager';
import ReportIcon from './components/ReportIcon';
import { reportTypesSelector } from 'state/Account/selectors';
import { hasPermissionSelector } from 'state/Auth/selectors';
import { BulkSearchContextProvider } from 'contexts/bulk-search-context';
import { AllReportsHeader } from './components/AllReportsHeader';

type StateProps = {
  reports: ReportsWithPagerSelector;
  errors: string[];
  searchTerm: string;
  reportTypes: ReportTypesSelector;
  isEnabledEvents: boolean;
  canViewEvents: boolean;
};

type DispatchProps = {
  openCreateModal: () => void;
  setSearchTerm: (searchTerm: string) => void;
  clearErrors: () => void;
};

type State = {
  reportType: ReportType;
  isSearchOpened: boolean;
  searchTerm: string;
};

export type Props = StateProps & DispatchProps;

export class View extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      reportType: 'rostered_shift' as ReportType,
      isSearchOpened: this.isSearchOpened(),
      searchTerm: props.searchTerm,
    };
  }

  render(): React.ReactNode {
    const { errors, reports } = this.props;

    return (
      <BulkSearchContextProvider>
        <Layout.Header>
          <AllReportsHeader dropDownButtons={this.reportButtons as any} />
        </Layout.Header>

        <Layout.Content>
          <ErrorBox errors={errors} clearErrors={this.props.clearErrors} />

          {reports.page.length > 0 ? (
            <div className={'reports-list-container'}>
              <ListTable reports={reports.page} />
            </div>
          ) : (
            'No results found...'
          )}

          <ReportsPager />

          <ReportModal
            title={this.reportModalTitle}
            type={this.state.reportType}
            isEdit={false}
          />
        </Layout.Content>
      </BulkSearchContextProvider>
    );
  }

  private get reportModalTitle() {
    return this.props.reportTypes[this.state.reportType].modalTitle;
  }

  onClickPrimaryDropdownButton = (reportType: ReportType) => () => {
    this.setState({
      reportType,
    });
    this.props.openCreateModal();
  };

  get filteredReportTypes() {
    const { canViewEvents, isEnabledEvents } = this.props;
    return isEnabledEvents && canViewEvents
      ? this.props.reportTypes
      : pickBy(
          this.props.reportTypes,
          (_, reportType) => (reportType as ReportType) !== 'event'
        );
  }

  private get reportButtons(): HeaderButtonProp[] {
    return map(this.filteredReportTypes, ({ label }, reportType) => {
      return {
        label,
        icon: <ReportIcon reportType={reportType as ReportType} />,
        onClick: this.onClickPrimaryDropdownButton(reportType as ReportType),
      };
    });
  }

  private isSearchOpened = () => {
    return this.props.searchTerm.trim().length > 0;
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  reports: reportsWithPagerSelector(state),
  errors: state.reports.errors,
  searchTerm: state.reports.searchTerm,
  reportTypes: reportTypesSelector(state),
  isEnabledEvents: state.account.account.event_settings.enable_events,
  canViewEvents: hasPermissionSelector(state, 'roster.events.view'),
});

const mapDispatchToProps: DispatchProps = {
  openCreateModal: BOX_REPORTS_CREATE_MODAL_OPEN,
  setSearchTerm: BOX_REPORTS_SET_SEARCH_TERM,
  clearErrors: BOX_REPORTS_CLEAR_ERRORS,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
