import { MoreHorizOutlinedIcon } from 'element';
import { HeaderOptionsDropdown } from 'elmo-elements/CardPopoverBadge/type';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from 'extended-oxygen-elements';
import { bindMenu, bindTrigger, usePopupState } from 'hooks';
import React from 'react';

type Props = {
  disabled: boolean;
  options: HeaderOptionsDropdown | undefined;
};

export default function MoreBtnOptions({ disabled, options }: Props) {
  const menuPopupState = usePopupState();

  const makeHandleMenuClick = (action: () => void) => () => {
    action();
    menuPopupState.close();
  };

  if (!options) {
    return null;
  }

  return (
    <>
      <IconButton
        {...bindTrigger(menuPopupState)}
        aria-label="More options"
        disabled={disabled}
      >
        <MoreHorizOutlinedIcon />
      </IconButton>
      <Menu {...bindMenu(menuPopupState)}>
        {options.dropdownOptions.map((option: any, index: number) => (
          <li key={`option-item-${option.label}`}>
            <MenuItem
              component="div"
              onClick={makeHandleMenuClick(option.onClick)}
              disabled={option.isDisabled}
            >
              <ListItemIcon>{option.icon}</ListItemIcon>
              <ListItemText>{option.label}</ListItemText>
            </MenuItem>
          </li>
        ))}
      </Menu>
    </>
  );
}
