import React from 'react';
import { withHeading } from 'element';
import ManagerDashboard from 'page/ManagerDashboard';
import Events from 'page/ManagerDashboard/page/Events';
import Overview from 'page/ManagerDashboard/page/Overview';
import ShiftSwaps from 'page/ManagerDashboard/page/ShiftSwaps';
import Unavailability from 'page/ManagerDashboard/page/Unavailability';
import WhosWorking from 'page/ManagerDashboard/page/WhosWorking';
import ShiftOffers from 'page/ManagerDashboard/page/ShiftOffers';
import ShiftOffersList from 'page/ManagerDashboard/page/ShiftOffers/page/ShiftOffers';
import ShiftOffer from 'page/ManagerDashboard/page/ShiftOffers/page/ShiftOffer';

const rootPath = '/app/manager-dashboard';
const defaultPagePath = '/app/manager-dashboard/overview';
export const managerDashboard = {
  path: rootPath,
  permission: 'managerdashboard' as 'managerdashboard',
  component: ManagerDashboard,
  channel: 'manager_dashboard',
  routes: {
    whosWorking: {
      path: '/app/manager-dashboard/whos-working',
      component: withHeading(WhosWorking, {
        title: 'Manager dashboard | Who\'s working'
      })
    },
    shiftOffers: {
      path: '/app/manager-dashboard/shift-offers',
      permission: 'managerdashboard.approveshiftoffers' as 'managerdashboard.approveshiftoffers',
      component: ShiftOffers,
      routes: {
        shiftOffer: {
          path: (shiftOfferId = ':shiftOfferId') =>
            `/app/manager-dashboard/shift-offers/${shiftOfferId}`,
          component: withHeading(ShiftOffer, {
            title: 'Manager dashboard | Shift Offer'
          })
        },
        shiftOffers: {
          path: '/app/manager-dashboard/shift-offers',
          component: withHeading(ShiftOffersList, {
            title: 'Manager dashboard | Shift Offers'
          })
        }
      }
    },
    shiftSwaps: {
      path: '/app/manager-dashboard/shift-swaps',
      component: withHeading(ShiftSwaps, {
        title: 'Manager dashboard | Shift Swaps'
      }),
      permission: 'managerdashboard.approveshiftswaps' as 'managerdashboard.approveshiftswaps'
    },
    events: {
      path: '/app/manager-dashboard/events',
      component: withHeading(Events, {
        title: 'Manager dashboard | Events'
      }),
      permission: 'managerdashboard.events.view' as 'managerdashboard.events.view'
    },
    unavailability: {
      path: '/app/manager-dashboard/availability',
      component: withHeading(Unavailability, {
        title: 'Manager dashboard | Availability'
      })
    },
    overview: {
      path: defaultPagePath,
      component: withHeading(Overview, {
        title: 'Manager dashboard | Overview'
      })
    },
    fallBack: {
      path: rootPath,
      redirect: defaultPagePath,
      exact: false
    }
  }
};
