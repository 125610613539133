import { CloseIcon, WarningAmberOutlinedIcon } from 'element';
import {
  Avatar,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from 'extended-oxygen-elements';
import { useActions, useSelectorWithProps } from 'hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLangPreferences } from 'state/Account';
import {
  BOX_ACCOUNT_TREE_ROLES_UNLINK,
  getAreaName,
  getAreaSafe,
  getRoleName,
  getRoleSafe,
} from 'state/AccountTree';
import {
  PageDialog,
  PageDialogCancel,
  PageDialogErrors,
  PageDialogSubmit,
  usePageDialogStatusEffect,
} from '../../../../../element/page-dialog-components';

type Props = {
  open: boolean;
  onClose: () => void;
  role_id: string;
  area_id: string;
  hasEmployees: boolean;
};

export default function DialogUnlinkRole({
  open,
  onClose,
  role_id,
  area_id,
  hasEmployees,
}: Props) {
  const unlinkRolesRequest = useActions(BOX_ACCOUNT_TREE_ROLES_UNLINK.request);
  const langPreferences = useSelector(getLangPreferences);
  const area = useSelectorWithProps(getAreaSafe, area_id);
  const areaName = area ? area.name : '';
  const role = useSelectorWithProps(getRoleSafe, role_id);
  const roleName = role ? role.name : '';

  const title = `Unlink ${langPreferences.role.singular}`;

  usePageDialogStatusEffect({
    success: onClose,
  });

  const closeDialog = () => {
    onClose();
  };

  const handleSubmitBtnClick = () => {
    unlinkRolesRequest({
      area_id,
      role_id,
    });
  };

  return (
    <PageDialog open={open} maxWidth="xs">
      <DialogTitle
        endAdornment={
          <IconButton onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        }
      >
        <Avatar size="large" color="warning" light aria-label={title}>
          <WarningAmberOutlinedIcon />
        </Avatar>
      </DialogTitle>
      <DialogContent>
        <PageDialogErrors />
        <Typography variant="h5" gutterBottom>
          {title}
          {hasEmployees && ` with ${langPreferences.employee.plural} assigned`}?
        </Typography>
        <Typography>
          This will unlink {langPreferences.role.singular} {roleName} from{' '}
          {areaName}, please confirm that you want to unlink this{' '}
          {langPreferences.role.singular}.
        </Typography>
      </DialogContent>
      <DialogActions>
        <PageDialogCancel onClick={closeDialog}>Cancel</PageDialogCancel>
        <PageDialogSubmit onClick={handleSubmitBtnClick}>
          Unlink a {langPreferences.role.singular}
        </PageDialogSubmit>
      </DialogActions>
    </PageDialog>
  );
}
