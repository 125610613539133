import { withinRange } from 'messages';
import {
  OtherRosterSettingsFieldGroupErrors,
  OtherRosterSettingsFieldGroupValue,
} from './types';

export default otherRosterSettingsFieldGroupValidator;

export function otherRosterSettingsFieldGroupValidator(
  values: OtherRosterSettingsFieldGroupValue
) {
  const errors: OtherRosterSettingsFieldGroupErrors = {};

  if (values.allow_assign_timesheet_within > 8) {
    errors.allow_assign_timesheet_within = withinRange(0, 8, 'hours');
  }

  return errors;
}
