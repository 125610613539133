import { Omit, ReportSchedule } from 'type';
import { State } from '../types';

export const getFormSubmitPayload = (state: State, usePaySauceFormat: boolean = false): ReportSchedule => {
  const {
    schedule,
    daily_day,
    weekly_day,
    fortnightly_day,
    monthly_day,
    ongoing,
    to,
    ...rest
  } = state.data;

  const partialSchedule: Omit<ReportSchedule, 'schedule'> = {
    ...rest,
    to: ongoing ? null : to,
    sub_format: usePaySauceFormat ? rest.sub_format : null
  };

  if (schedule === 'daily') {
    return {
      ...partialSchedule,
      schedule: {
        period: schedule,
        day: daily_day
      }
    };
  } else if (schedule === 'weekly') {
    return {
      ...partialSchedule,
      schedule: {
        period: schedule,
        day: weekly_day
      }
    };
  } else if (schedule === 'fortnightly') {
    return {
      ...partialSchedule,
      schedule: {
        period: schedule,
        day: fortnightly_day
      }
    };
  }

  return {
    ...partialSchedule,
    schedule: {
      period: schedule,
      day: monthly_day
    }
  };
};
