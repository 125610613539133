import { createReducer } from 'lib/store-utils';
import { ContextualMenuReducerState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';

export const rosterContextualMenu = createReducer<ContextualMenuReducerState>(
  {},
  getDefaultState()
);

rosterContextualMenu.on(
  actions.BOX_ROSTER_CONTEXTUAL_MENU_OPEN,
  (state, data): ContextualMenuReducerState => ({
    ...state,
    id: data.id,
    objectId: data.objectId,
    cellData: data.cellData
  })
);

rosterContextualMenu.on(
  actions.BOX_ROSTER_CONTEXTUAL_MENU_BULK_SET_PROPS,
  (state, { inProgress, clipboard }): ContextualMenuReducerState => ({
    ...state,
    inProgress: inProgress,
    id: null,
    clipboard: clipboard
  })
)

rosterContextualMenu.on(
  actions.BOX_ROSTER_CONTEXTUAL_MENU_CLOSE,
  (state): ContextualMenuReducerState => ({
    ...state,
    id: null
  })
);

rosterContextualMenu.on(
  actions.BOX_ROSTER_CONTEXTUAL_MENU_CLOSE_AND_SET_PROPS,
  (state, { clipboard, action }): ContextualMenuReducerState => ({
    ...state,
    id: null,
    clipboard: clipboard,
    action: action
  })
);

rosterContextualMenu.on(
  actions.BOX_ROSTER_CONTEXTUAL_MENU_CLOSE_WHEN_DELETED,
  (state, deletedIds): ContextualMenuReducerState => {
    const { clipboard } = state;

    if (clipboard && deletedIds.includes(clipboard.id)) {
      return {
        ...state,
        id: null,
        clipboard: null,
        action: 'none'
      }
    }

    return state;
  }
);

rosterContextualMenu.on(
  actions.BOX_ROSTER_CONTEXTUAL_MENU_SET_CLIPBOARD,
  (state, data): ContextualMenuReducerState => ({
    ...state,
    clipboard: data
  })
);

rosterContextualMenu.on(
  actions.BOX_ROSTER_CONTEXTUAL_MENU_SET_ACTION,
  (state, type): ContextualMenuReducerState => ({
    ...state,
    action: type
  })
);

rosterContextualMenu.on(
  actions.BOX_ROSTER_CONTEXTUAL_MENU_SET_EMPTY_CELL,
  (state, data): ContextualMenuReducerState => ({
    ...state,
    cellData: data
  })
);

rosterContextualMenu.on(
  actions.BOX_ROSTER_CONTEXTUAL_MENU_SET_INPROGRESS,
  (state, value): ContextualMenuReducerState => ({
    ...state,
    inProgress: value,
    id: null
  })
);

rosterContextualMenu.on(
  actions.BOX_ROSTER_CONTEXTUAL_MENU_SET_IGNORE_ERROR,
  (state, value): ContextualMenuReducerState => ({
    ...state,
    ignoreError: value
  })
);
