import { RosterPublishResponse } from 'state/Roster/Roster';
import { PublishArea, PublishWeek } from '../types';
import { getSelectedAreasNumber } from './getSelectedAreasNumber';
import { showSelectedNumber } from './showSelectedNumber';

export const prepareDefaultState = (response: RosterPublishResponse) => {
  const weeksState: PublishWeek = {};

  for (let weekId in response.weeks) {
    const week = response.weeks[weekId];
    const areas: PublishArea = {};

    for (let areaId in week.areas) {
      areas[areaId] = true;
    }

    weeksState[weekId] = {
      isOpened: false,
      isChecked: true,
      showSelectedNumber: showSelectedNumber(areas),
      selectedAreasNumber: getSelectedAreasNumber(areas),
      areas: areas,
    };
  }

  return weeksState;
};
