import { StoreState } from '../../types';
import {
  FormattedErrors,
  MergedAwardsList,
  PermissionName,
  UserProfileFields,
  UserRole
} from '../../../type/models';
import { UserAwardsState, UserProfileReducerState } from './types';
import { createSelector } from 'reselect';
import {
  currentUserSiteIdsSelector,
  getCurrentUserPermissions
} from '../../Auth';
import { filter } from 'lodash';
import _ from 'lodash';

const getState = (state: StoreState) => state.userProfile;
export const getIsOpened = (
  state: StoreState,
  modal: keyof Pick<
    UserProfileReducerState,
    | 'customFieldsModal'
    | 'uploadModal'
    | 'profileModal'
    | 'rolesModal'
    | 'paymentModal'
    | 'pinModal'
  >
): boolean => getState(state)[modal].isOpened;
export const getIsUpdating = (state: StoreState): boolean =>
  getState(state).isUpdating;
export const getErrors = (state: StoreState): FormattedErrors =>
  getState(state).errors;
export const getUserProfile = (state: StoreState): UserProfileFields =>
  getState(state).userProfile;
export const getUsersAwardsState = (state: StoreState): UserAwardsState =>
  getState(state).awards;
export const getUserId = (state: StoreState): string =>
  getUserProfile(state).id;
export const getUserRoles = (state: StoreState): UserRole[] =>
  getUserProfile(state).user_roles;
export const getEditApprovalsUserId = (state: StoreState) =>
  getState(state).editApproversModal.userId;
export const getEditApprovalsIsOpened = (state: StoreState) =>
  getState(state).editApproversModal.isOpened;

export const userRolesAccessibleByCurrentUserSelector = createSelector(
  getUserRoles,
  currentUserSiteIdsSelector,
  getCurrentUserPermissions,
  (
    userRoles: UserRole[],
    currentUserSiteIds: string[],
    currentUserPermissions: PermissionName[]
  ): UserRole[] => {
    if (currentUserPermissions.includes('account.viewallsites')) {
      return userRoles;
    }

    return filter(userRoles, ({ site_id }: UserRole) =>
      currentUserSiteIds.includes(site_id)
    );
  }
);

export const awardsWithNamesSelector = createSelector(
  getUsersAwardsState,
  data => {
    const { assignedAwards, globalAwardsList } = data;
    const result: MergedAwardsList[] = [];
    assignedAwards.forEach(assigned => {
      if ( assigned.award === null ) {
        result.push({
          ...assigned,
          name: 'No award'
        });
      }
      globalAwardsList.forEach(award => {
        if ( assigned.award && assigned.award.id === award.id ) {
          result.push({
            ...award,
            ...assigned
          });
        }
      });
    });
    return _.sortBy(result, 'effective_date');
  }
);

export const awardsListAsOptions = createSelector(
  getUsersAwardsState,
  data => {
    const { globalAwardsList } = data;
    const result: any[] = [{
      label: 'Default',
      value: 'null'
    }];
    globalAwardsList.forEach(award => {
      result.push({
        label: award.name,
        value: award.id
      })
    });
    return result;
  }
);

export const getUsersAwardData = (state: StoreState) =>
  getState(state).awards.modal.payload;

export const getAwardIdToDelete = (state: StoreState) =>
  getState(state).awards.deleteModal.awardId;

export const getAwardDeleteModal = (state: StoreState) =>
  getState(state).awards.deleteModal;