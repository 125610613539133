import { createAction } from 'lib/store-utils';
import {
  FormattedErrors,
  ServerUserFields,
  SortUser,
  UpdateFormPayload,
} from 'type';
import {
  AddRolePayload,
  CreateUserPayload,
  EditApproversPayload,
  SendWelcomeNotificationPayload,
  UserToggleFilterTypePayload,
} from './types';

export const BOX_USERS_GET_USERS_PAGE_DATA_REQUEST = createAction(
  'Users / Get users page data request'
);

export const BOX_USERS_ROLE_MODAL_OPEN = createAction<string>(
  'Users / Open role modal'
);
export const BOX_USERS_ROLE_MODAL_CLOSE = createAction(
  'Users / Close role modal'
);

export const BOX_USERS_ADD_ROLE_REQUEST = createAction<AddRolePayload>(
  'Users / add role request'
);
export const BOX_USERS_ADD_ROLE_SUCCESS = createAction(
  'Users / add role success'
);
export const BOX_USERS_ADD_ROLE_FAILURE = createAction<FormattedErrors>(
  'Users / add role failure'
);

export const BOX_USERS_ROLE_BULK_MODAL_OPEN = createAction(
  'Users / Open bulk role modal'
);
export const BOX_USERS_ROLE_BULK_MODAL_CLOSE = createAction(
  'Users / Close bulk role modal'
);

export const BOX_USERS_ASSIGN_LOCATION_BULK_MODAL_OPEN = createAction(
  'Users / Open bulk assign modal'
);
export const BOX_USERS_ASSIGN_LOCATION_BULK_MODAL_CLOSE = createAction(
  'Users / Close bulk assign modal'
);

export const BOX_USERS_ASSIGN_LOCATION_BULK_MODAL_CLEAR_ERRORS = createAction(
  'Users / Clear errors in bulk assign modal'
);

export const BOX_USERS_ASSIGN_LOCATION_BULK_MODAL_REQUEST =
  createAction<string>('Users / Send request to assign location');

export const BOX_USERS_ASSIGN_LOCATION_BULK_MODAL_SUCCESS = createAction<any>(
  'Users / Send request to assign location success'
);

export const BOX_USERS_ASSIGN_LOCATION_BULK_MODAL_FAILURE =
  createAction<FormattedErrors>(
    'Users / Send request to assign location failure'
  );

export const BOX_USERS_ADD_ROLE_BULK_REQUEST = createAction<AddRolePayload>(
  'Users / add role bulk request'
);
export const BOX_USERS_ADD_ROLE_BULK_SUCCESS = createAction(
  'Users / add role bulk success'
);
export const BOX_USERS_ADD_ROLE_BULK_FAILURE = createAction<FormattedErrors>(
  'Users / add role bulk failure'
);

export const BOX_USERS_CHANGE_PAGE = createAction<number>(
  'Users / Change page'
);
export const BOX_USERS_CHANGE_PAGE_SIZE = createAction<number>(
  'Users / Change page size'
);
export const BOX_USERS_CHANGE_ORDER = createAction<SortUser>(
  'Users / Change users order'
);
export const BOX_USERS_SAVE_PAGE_NUMBER = createAction(
  'Users / Save current page number'
);

// Edit Approvers modals:
export const BOX_USERS_EDIT_APPROVALS_MODAL_OPEN = createAction<string>(
  'Users / edit approvals modal open'
);
export const BOX_USERS_EDIT_APPROVALS_MODAL_CLOSE = createAction(
  'Users / edit approvals modal close'
);
export const BOX_USERS_EDIT_APPROVALS_REQUEST =
  createAction<EditApproversPayload>('Users / edit approvals request');
export const BOX_USERS_EDIT_APPROVALS_SUCCESS =
  createAction<EditApproversPayload>('Users / edit approvals success');
export const BOX_USERS_EDIT_APPROVALS_FAILURE = createAction<FormattedErrors>(
  'Users / edit approvals failure'
);
export const BOX_USERS_EDIT_APPROVALS_BULK_MODAL_OPEN = createAction(
  'Users / edit approvals bulk modal open'
);
export const BOX_USERS_EDIT_APPROVALS_BULK_MODAL_CLOSE = createAction(
  'Users / edit approvals bulk modal close'
);
export const BOX_USERS_EDIT_APPROVALS_BULK_REQUEST =
  createAction<EditApproversPayload>('Users / edit approvals bulk request');
export const BOX_USERS_EDIT_APPROVALS_BULK_SUCCESS = createAction(
  'Users / edit approvals bulk success'
);
export const BOX_USERS_EDIT_APPROVALS_BULK_FAILURE =
  createAction<FormattedErrors>('Users / edit approvals bulk failure');

export const BOX_USERS_TOGGLE_FILTER_TYPE =
  createAction<UserToggleFilterTypePayload>('Users / toggle filter type');
export const BOX_USERS_UPDATE_FILTER_FORM = createAction<UpdateFormPayload>(
  'Users / update filter form'
);
export const BOX_USERS_CLEAR_FILTER_FORM = createAction(
  'Users / clear filter form'
);
export const BOX_USERS_UPDATE_CUSTOM_FILTERS = createAction(
  'Users / Update custom filters'
);

export const BOX_USERS_UPDATE_SEARCH_QUERY = createAction<string>(
  'Users / update search query'
);

// Welcome notifications
export const BOX_USERS_BULK_SEND_WELCOME_NOTIFICATIONS_REQUEST = createAction(
  'Users / bulk send welcome notifications'
);
export const BOX_USERS_SEND_WELCOME_NOTIFICATIONS_REQUEST =
  createAction<SendWelcomeNotificationPayload>(
    'Users / send welcome notifications'
  );
export const BOX_USERS_SEND_WELCOME_NOTIFICATIONS_SUCCESS = createAction(
  'Users / send welcome notifications success'
);
export const BOX_USERS_SEND_WELCOME_NOTIFICATIONS_FAILURE =
  createAction<FormattedErrors>('Users / send welcome notifications failed');

export const BOX_USERS_CREATE_MODAL_TOGGLE = createAction<boolean>(
  'Create user modal / toggle state'
);
export const BOX_USERS_CREATE_MODAL_CLEAR_ERRORS = createAction(
  'Create user modal / remove errors'
);
export const BOX_USERS_CREATE_USER = createAction<CreateUserPayload>(
  'Create user modal / send user request'
);
export const BOX_USERS_CREATE_USER_SUCCESS = createAction<ServerUserFields>(
  'Create user modal / send user success'
);
export const BOX_USERS_CREATE_USER_FAILURE = createAction<FormattedErrors>(
  'Create user modal / send user failure'
);
