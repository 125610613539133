import React from 'react';
import { useDispatch } from 'react-redux';
import { Box } from 'extended-oxygen-elements';
import {
  BOX_TIMESHEETS_WEEKLY_ALLOWED_TO_ASSIGN_SHIFTS_GET,
  BOX_TIMESHEETS_WEEKLY_ALLOWED_TO_ASSIGN_SHIFTS_UPDATE,
  getAllowedToAssignShiftsErrors,
  getAllowedToAssignShiftsFetchErrors,
  getAllowedToAssignShiftsFetching,
} from 'state/TimesheetsWeeklyPage';
import { DateType } from 'type';
import { useFormControlSpacing, useUpdateEffect } from 'hooks';
import {
  AlertError,
  dateRangeFieldGroupUtils,
  FetchContainer,
  FetchErrorsHandler,
  SkeletonTextField,
} from 'element';

type Props = {
  children: React.ReactNode;
  role: {
    area_id: string;
    role_id: string;
  };
  startDate: DateType;
  startTime: number;
  endDate: DateType;
  endTime: number;
  user_id: string;
  site_id: string;
  timesheet_id: string;
};

export default ConnectedShiftFieldWrapper;

export function ConnectedShiftFieldWrapper(props: Props) {
  const payload = usePayload(props);

  const fetchAction = React.useMemo(
    () => BOX_TIMESHEETS_WEEKLY_ALLOWED_TO_ASSIGN_SHIFTS_GET.request(payload),
    []
  );

  const dispatch = useDispatch();
  useUpdateEffect(() => {
    dispatch(
      BOX_TIMESHEETS_WEEKLY_ALLOWED_TO_ASSIGN_SHIFTS_UPDATE.request(payload)
    );
  }, [dispatch, payload]);

  const spacing = useFormControlSpacing('normal');

  return (
    <Box {...spacing}>
      <FetchContainer
        action={fetchAction}
        loadingSelector={getAllowedToAssignShiftsFetching}
        skeletonComponent={FieldSkeleton}
        errorsSelector={getAllowedToAssignShiftsFetchErrors}
        errorsComponent={AlertError}
      >
        <FetchErrorsHandler
          errorsSelector={getAllowedToAssignShiftsErrors}
          errorsComponent={AlertError}
        >
          {props.children}
        </FetchErrorsHandler>
      </FetchContainer>
    </Box>
  );
}

function FieldSkeleton() {
  return <SkeletonTextField margin="none" />;
}

function usePayload({
  role: { area_id, role_id },
  timesheet_id,
  user_id,
  site_id,
  ...dateRangeFields
}: Props) {
  const { start, end } = dateRangeFieldGroupUtils.getDateRange(dateRangeFields);

  return React.useMemo(
    () => ({
      start,
      end,
      area_id,
      role_id,
      timesheet_id,
      user_id,
      site_id,
    }),
    [start, end, area_id, role_id, timesheet_id, user_id, site_id]
  );
}
