import { QueryDayProvider, QueryRosterSiteIdProvider } from 'element';
import React from 'react';

export type RosterQueryWrapperProps = {
  children: React.ReactNode;
};

export default RosterQueryWrapper;

export function RosterQueryWrapper({ children }: RosterQueryWrapperProps) {
  return (
    <QueryRosterSiteIdProvider>
      <QueryDayProvider>{children}</QueryDayProvider>
    </QueryRosterSiteIdProvider>
  );
}
