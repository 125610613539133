import { createReducer } from 'lib/store-utils';
import { getDefaultState } from './state';
import { UserFilters, UserReducerState } from './types';
import * as actions from './actions';
import { filterTypes } from './constants';
import { getFilterForm, getFiltersFromForm } from './helpers';
import qs from 'qs';

export const user = createReducer<UserReducerState>({}, getDefaultState());

user.on(actions.BOX_USERS_GET_USERS_PAGE_DATA_REQUEST, () => {
  const storedPageParams = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  return {
    ...getDefaultState(),
    pager: {
      ...getDefaultState().pager,
      currentPage: storedPageParams.pageNum ? +storedPageParams.pageNum : 1,
      pageSize: storedPageParams.pageSize ? +storedPageParams.pageSize : 10,
    },
  };
});

user.on(
  actions.BOX_USERS_ROLE_MODAL_OPEN,
  (state, userId): UserReducerState => ({
    ...state,
    addRoleModal: {
      ...state.addRoleModal,
      errors: [],
      isOpened: true,
      userId,
    },
  })
);

user.on(
  actions.BOX_USERS_SAVE_PAGE_NUMBER,
  (state): UserReducerState => ({
    ...state,
    storedPage: state.pager.currentPage,
    storedPageSize: state.pager.pageSize,
  })
);

user.on(
  actions.BOX_USERS_ROLE_MODAL_CLOSE,
  (state): UserReducerState => ({
    ...state,
    addRoleModal: {
      ...state.addRoleModal,
      isOpened: false,
    },
  })
);

user.on(
  actions.BOX_USERS_ADD_ROLE_REQUEST,
  (state): UserReducerState => ({
    ...state,
    addRoleModal: {
      ...state.addRoleModal,
      isUpdating: true,
    },
  })
);

user.on(
  actions.BOX_USERS_ADD_ROLE_SUCCESS,
  (state): UserReducerState => ({
    ...state,
    addRoleModal: {
      ...state.addRoleModal,
      isUpdating: false,
      isOpened: false,
    },
  })
);

user.on(
  actions.BOX_USERS_ADD_ROLE_FAILURE,
  (state, errors): UserReducerState => ({
    ...state,
    addRoleModal: {
      ...state.addRoleModal,
      isUpdating: false,
      errors,
    },
  })
);

user.on(
  actions.BOX_USERS_ROLE_BULK_MODAL_OPEN,
  (state): UserReducerState => ({
    ...state,
    bulkAddRoleModal: {
      ...state.bulkAddRoleModal,
      errors: [],
      isOpened: true,
    },
  })
);

user.on(
  actions.BOX_USERS_ASSIGN_LOCATION_BULK_MODAL_OPEN,
  (state): UserReducerState => ({
    ...state,
    bulkAssignLocation: {
      ...state.bulkAssignLocation,
      errors: [],
      isOpened: true,
    },
  })
);

user.on(
  actions.BOX_USERS_ASSIGN_LOCATION_BULK_MODAL_CLOSE,
  (state): UserReducerState => ({
    ...state,
    bulkAssignLocation: {
      ...state.bulkAssignLocation,
      errors: [],
      isOpened: false,
    },
  })
);

user.on(
  actions.BOX_USERS_ROLE_BULK_MODAL_CLOSE,
  (state): UserReducerState => ({
    ...state,
    bulkAddRoleModal: {
      ...state.bulkAddRoleModal,
      isOpened: false,
    },
  })
);

user.on(
  actions.BOX_USERS_ADD_ROLE_BULK_REQUEST,
  (state): UserReducerState => ({
    ...state,
    bulkAddRoleModal: {
      ...state.bulkAddRoleModal,
      isUpdating: true,
    },
  })
);

user.on(
  actions.BOX_USERS_ADD_ROLE_BULK_SUCCESS,
  (state): UserReducerState => ({
    ...state,
    bulkAddRoleModal: {
      ...state.bulkAddRoleModal,
      isUpdating: false,
      isOpened: false,
    },
  })
);

user.on(
  actions.BOX_USERS_ADD_ROLE_BULK_FAILURE,
  (state, errors): UserReducerState => ({
    ...state,
    bulkAddRoleModal: {
      ...state.bulkAddRoleModal,
      errors,
      isUpdating: false,
    },
  })
);

user.on(
  actions.BOX_USERS_CHANGE_PAGE,
  (state, currentPage): UserReducerState => ({
    ...state,
    pager: {
      ...state.pager,
      currentPage,
    },
  })
);

user.on(
  actions.BOX_USERS_CHANGE_PAGE_SIZE,
  (state, pageSize): UserReducerState => ({
    ...state,
    pager: {
      ...state.pager,
      pageSize,
    },
    storedPage: 1,
    storedPageSize: pageSize,
  })
);

user.on(
  actions.BOX_USERS_CHANGE_ORDER,
  (state, sort): UserReducerState => ({
    ...state,
    sort,
    filtersForm: {
      ...state.filtersForm,
      sort: {
        ...sort,
      },
    },
  })
);

user.on(
  actions.BOX_USERS_EDIT_APPROVALS_MODAL_OPEN,
  (state, userId): UserReducerState => ({
    ...state,
    editApproversModal: {
      ...state.editApproversModal,
      errors: [],
      isOpened: true,
      userId,
    },
  })
);

user.on(
  actions.BOX_USERS_EDIT_APPROVALS_MODAL_CLOSE,
  (state): UserReducerState => ({
    ...state,
    editApproversModal: {
      ...state.editApproversModal,
      isOpened: false,
    },
  })
);

user.on(
  actions.BOX_USERS_EDIT_APPROVALS_REQUEST,
  (state): UserReducerState => ({
    ...state,
    editApproversModal: {
      ...state.editApproversModal,
      isUpdating: true,
    },
  })
);

user.on(
  actions.BOX_USERS_EDIT_APPROVALS_SUCCESS,
  (state): UserReducerState => ({
    ...state,
    editApproversModal: {
      ...state.editApproversModal,
      isUpdating: false,
      isOpened: false,
    },
  })
);

user.on(
  actions.BOX_USERS_EDIT_APPROVALS_FAILURE,
  (state, errors): UserReducerState => ({
    ...state,
    editApproversModal: {
      ...state.editApproversModal,
      isUpdating: false,
      errors,
    },
  })
);

user.on(
  actions.BOX_USERS_EDIT_APPROVALS_BULK_MODAL_OPEN,
  (state): UserReducerState => ({
    ...state,
    bulkEditApproversModal: {
      ...state.bulkEditApproversModal,
      errors: [],
      isOpened: true,
    },
  })
);

user.on(
  actions.BOX_USERS_EDIT_APPROVALS_BULK_MODAL_CLOSE,
  (state): UserReducerState => ({
    ...state,
    bulkEditApproversModal: {
      ...state.bulkEditApproversModal,
      isOpened: false,
    },
  })
);

user.on(
  actions.BOX_USERS_EDIT_APPROVALS_BULK_REQUEST,
  (state): UserReducerState => ({
    ...state,
    bulkEditApproversModal: {
      ...state.bulkEditApproversModal,
      isUpdating: true,
    },
  })
);

user.on(
  actions.BOX_USERS_EDIT_APPROVALS_BULK_SUCCESS,
  (state): UserReducerState => ({
    ...state,
    bulkEditApproversModal: {
      ...state.bulkEditApproversModal,
      isUpdating: false,
      isOpened: false,
    },
  })
);

user.on(
  actions.BOX_USERS_EDIT_APPROVALS_BULK_FAILURE,
  (state, errors): UserReducerState => ({
    ...state,
    bulkEditApproversModal: {
      ...state.bulkEditApproversModal,
      isUpdating: false,
      errors,
    },
  })
);

user.on(
  actions.BOX_USERS_TOGGLE_FILTER_TYPE,
  (state, selectedFilter): UserReducerState => {
    const newFiltersState: UserFilters = filterTypes[selectedFilter];

    return {
      ...state,
      selectedFilter,
      filters: newFiltersState,
      filtersForm: getFilterForm(newFiltersState, state.sort),
      search: {
        query: '',
      },
    };
  }
);

user.on(
  actions.BOX_USERS_UPDATE_FILTER_FORM,
  (state, { name, value }): UserReducerState => ({
    ...state,
    filtersForm: {
      ...state.filtersForm,
      [name]: value,
    },
  })
);

user.on(
  actions.BOX_USERS_CLEAR_FILTER_FORM,
  (state): UserReducerState => ({
    ...state,
    filtersForm: getFilterForm(state.filters, state.sort),
  })
);

user.on(actions.BOX_USERS_UPDATE_CUSTOM_FILTERS, (state): UserReducerState => {
  return {
    ...state,
    selectedFilter: 'custom',
    filters: getFiltersFromForm(state.filtersForm),
    sort: {
      ...state.filtersForm.sort,
    },
    search: {
      query: '',
    },
  };
});

user.on(
  actions.BOX_USERS_UPDATE_SEARCH_QUERY,
  (state, query): UserReducerState => ({
    ...state,
    search: {
      ...state.search,
      query,
    },
    pager: {
      currentPage: query ? 1 : state.storedPage,
      pageSize: query ? 10 : state.storedPageSize,
    },
  })
);

user.on(
  actions.BOX_USERS_SEND_WELCOME_NOTIFICATIONS_FAILURE,
  (state, errors): UserReducerState => ({
    ...state,
    errors,
  })
);

user.on(
  actions.BOX_USERS_CREATE_MODAL_TOGGLE,
  (state, value): UserReducerState => ({
    ...state,
    createUserModal: {
      ...state.createUserModal,
      isOpened: value,
      errors: [],
    },
  })
);

user.on(
  actions.BOX_USERS_CREATE_USER,
  (state, params): UserReducerState => ({
    ...state,
    createUserModal: {
      ...state.createUserModal,
      isUpdating: true,
      errors: [],
    },
  })
);

user.on(
  actions.BOX_USERS_CREATE_USER_SUCCESS,
  (state, params): UserReducerState => ({
    ...state,
    createUserModal: {
      ...state.createUserModal,
      isUpdating: false,
      isOpened: false,
    },
  })
);

user.on(
  actions.BOX_USERS_CREATE_USER_FAILURE,
  (state, errors): UserReducerState => ({
    ...state,
    createUserModal: {
      ...state.createUserModal,
      isUpdating: false,
      errors: errors,
    },
  })
);

user.on(
  actions.BOX_USERS_CREATE_MODAL_CLEAR_ERRORS,
  (state, errors): UserReducerState => ({
    ...state,
    createUserModal: {
      ...state.createUserModal,
      errors: [],
    },
  })
);

user.on(
  actions.BOX_USERS_ASSIGN_LOCATION_BULK_MODAL_REQUEST,
  (state, id): UserReducerState => ({
    ...state,
    bulkAssignLocation: {
      ...state.bulkAssignLocation,
      isUpdating: true,
      errors: [],
    },
  })
);

user.on(
  actions.BOX_USERS_ASSIGN_LOCATION_BULK_MODAL_SUCCESS,
  (state, response): UserReducerState => ({
    ...state,
    bulkAssignLocation: {
      ...state.bulkAssignLocation,
      isUpdating: false,
      isOpened: false,
      errors: [],
    },
  })
);

user.on(
  actions.BOX_USERS_ASSIGN_LOCATION_BULK_MODAL_FAILURE,
  (state, errors): UserReducerState => ({
    ...state,
    bulkAssignLocation: {
      ...state.bulkAssignLocation,
      isUpdating: false,
      errors,
    },
  })
);

user.on(
  actions.BOX_USERS_ASSIGN_LOCATION_BULK_MODAL_CLEAR_ERRORS,
  (state): UserReducerState => ({
    ...state,
    bulkAssignLocation: {
      ...state.bulkAssignLocation,
      errors: [],
    },
  })
);
