import { createReducer } from 'lib/store-utils';
import * as actions from './actions';
import { EventModalReducerState } from './types';
import { emptyEvent, getDefaultState } from './state';
import { BOX_EVENT_MODAL_GET_SHIFTS_REQUEST } from './actions';

export const eventModal = createReducer<EventModalReducerState>(
  {},
  getDefaultState()
);

eventModal.on(
  actions.BOX_EVENT_MODAL_OPEN,
  (state): EventModalReducerState => ({
    ...state,
    currentEvent: emptyEvent,
    eventModal: {
      ...state.eventModal,
      isOpened: true,
      isLoading: true,
      errors: []
    }
  })
);

eventModal.on(
  actions.BOX_EVENT_MODAL_CLOSE,
  (state): EventModalReducerState => ({
    ...state,
    currentEvent: emptyEvent,
    eventModal: {
      ...state.eventModal,
      isOpened: false
    },
    errors: []
  })
);

eventModal.on(
  actions.BOX_EVENT_MODAL_GET_BY_ID_SUCCESS,
  (state, currentEvent): EventModalReducerState => ({
    ...state,
    currentEvent,
    eventModal: {
      ...state.eventModal,
      isLoading: false,
      isLoaded: true,
    }
  })
);

eventModal.on(
  actions.BOX_EVENT_MODAL_SET_EVENT,
  (state, currentEvent): EventModalReducerState => ({
    ...state,
    currentEvent,
    eventModal: {
      ...state.eventModal,
      isLoading: false
    }
  })
);

eventModal.on(
  actions.BOX_EVENT_MODAL_ASSIGN_SHIFT,
  (state, id): EventModalReducerState => ({
    ...state,
    currentEvent: {
      ...state.currentEvent,
      rostered_shift_ids: [...state.currentEvent.rostered_shift_ids, id]
    }
  })
);

eventModal.on(
  actions.BOX_EVENT_MODAL_UNASSIGN_SHIFT,
  (state, id): EventModalReducerState => ({
    ...state,
    currentEvent: {
      ...state.currentEvent,
      rostered_shift_ids: state.currentEvent.rostered_shift_ids.filter(shiftId => shiftId !== id)
    }
  })
);

eventModal.on(
  actions.BOX_EVENT_MODAL_CUSTOM_FILE_SUCCESS,
  (state, payload): EventModalReducerState => ({
    ...state,
    currentEvent: {
      ...state.currentEvent,
      custom_fields: {
        ...state.currentEvent.custom_fields,
        [payload.name]: {
          filename: payload.data.filename,
          filepath: payload.data.filepath,
          original_filename: payload.original_filename
        }
      }
    }
  })
);

eventModal.on(
  actions.BOX_EVENT_MODAL_CUSTOM_FILE_FAILURE,
  (state, payload): EventModalReducerState => ({
    ...state,
    currentEvent: {
      ...state.currentEvent,
      custom_fields: {
        ...state.currentEvent.custom_fields,
        [payload.name]: null
      },
    },
    eventModal: {
      ...state.eventModal,
      errors: payload.data
    }

  })
);

eventModal.on(
  actions.BOX_EVENT_MODAL_UPDATE_EVENT,
  (state): EventModalReducerState => ({
    ...state,
    eventModal: {
      ...state.eventModal,
      isUpdating: true
    }
  })
);

eventModal.on(
  actions.BOX_EVENT_MODAL_UPDATE_EVENT_SUCCESS,
  (state): EventModalReducerState => ({
    ...state,
    eventModal: {
      ...state.eventModal,
      isUpdating: false,
      isOpened: false,
      errors: []
    },
    publishedShiftsModal: {
      isOpened: false
    }
  })
);

eventModal.on(
  actions.BOX_EVENT_MODAL_UPDATE_EVENT_FAILURE,
  (state, errors): EventModalReducerState => ({
    ...state,
    eventModal: {
      ...state.eventModal,
      isUpdating: false,
      errors
    }
  })
);

eventModal.on(
  actions.BOX_EVENT_MODAL_CREATE_EVENT,
  (state): EventModalReducerState => ({
    ...state,
    eventModal: {
      ...state.eventModal,
      isUpdating: true
    }
  })
);

eventModal.on(
  actions.BOX_EVENT_MODAL_CREATE_EVENT_SUCCESS,
  (state): EventModalReducerState => ({
    ...state,
    eventModal: {
      ...state.eventModal,
      isUpdating: false,
      isOpened: false,
      errors: []
    },
    publishedShiftsModal: {
      isOpened: false
    }
  })
);

eventModal.on(
  actions.BOX_EVENT_MODAL_CREATE_EVENT_FAILURE,
  (state, errors): EventModalReducerState => ({
    ...state,
    eventModal: {
      ...state.eventModal,
      isUpdating: false,
      errors
    }
  })
);

eventModal.on(
  actions.BOX_EVENT_MODAL_PUBLISHED_SHIFTS_MODAL_OPEN,
  (state): EventModalReducerState => ({
    ...state,
    publishedShiftsModal: {
      isOpened: true
    }
  })
);

eventModal.on(
  actions.BOX_EVENT_MODAL_PUBLISHED_SHIFTS_MODAL_CLOSE,
  (state): EventModalReducerState => ({
    ...state,
    publishedShiftsModal: {
      isOpened: false
    }
  })
);

eventModal.on(
  actions.BOX_EVENT_MODAL_CLEAR_ERRORS,
  (state): EventModalReducerState => ({
    ...state,
    eventModal: {
      ...state.eventModal,
      errors: []
    }
  })
);

eventModal.on(
  actions.BOX_EVENT_MODAL_REMOVE_EVENT_REQUEST,
  (state): EventModalReducerState => ({
    ...state,
    eventModal: {
      ...state.eventModal,
      isUpdating: true,
    }
  })
);

eventModal.on(
  actions.BOX_EVENT_MODAL_REMOVE_EVENT_SUCCESS,
  (state): EventModalReducerState => ({
    ...state,
    eventModal: {
      ...state.eventModal,
      isOpened: false,
      isUpdating: false,
      errors: []
    }
  })
);

eventModal.on(
  actions.BOX_EVENT_MODAL_REMOVE_EVENT_FAILURE,
  (state, errors): EventModalReducerState => ({
    ...state,
    eventModal: {
      ...state.eventModal,
      isUpdating: false,
      errors
    }
  })
);

eventModal.on(
  actions.BOX_EVENT_MODAL_GET_SHIFTS_SUCCESS,
  (state, shifts): EventModalReducerState => ({
    ...state,
    shifts,
    eventModal: {
      ...state.eventModal,
      isLoadingShifts: false
    }
  })
);

eventModal.on(
  actions.BOX_EVENT_MODAL_GET_SHIFTS_REQUEST,
  (state): EventModalReducerState => ({
    ...state,
    eventModal: {
      ...state.eventModal,
      isLoadingShifts: true,
    }
  })
);

eventModal.on(
  actions.BOX_EVENT_MODAL_GET_SHIFTS_FAILURE,
  (state, errors): EventModalReducerState => ({
    ...state,
    eventModal: {
      ...state.eventModal,
      isLoadingShifts: false,
      errors
    }
  })
);
