import React, { memo } from 'react';
import { useMakeSelector, useSelectorWithProps } from 'hooks';
import { RosterCell } from '../../../../../../../../../../components';
import {
  makeItemAreaIdRoleIdSelector,
  makeSitePairRosteredShiftIdSelector
} from 'state/Roster/RosterWeekViewSiteView';
import { getRosteredShiftUserId } from 'state/RosteredShifts';
import { WeekCellBlock } from 'page/Roster/page/RosterWeekView/components/WeekCellBlock/RosterWeekCellBlock';

type Props = {
  siteRowIndex: number;
  pairRowIndex: number;
  weekDayIndex: number;
};

export const RosteredShiftCell = memo(function RosteredShiftCell(props: Props) {
  const pairRosteredShiftIdSelector = useMakeSelector(
    makeSitePairRosteredShiftIdSelector
  );

  const rosteredShiftId = useSelectorWithProps(
    pairRosteredShiftIdSelector,
    props
  );

  const itemAreaIdRoleIdSelector = useMakeSelector(makeItemAreaIdRoleIdSelector);
  const itemAreaIdRoleId = useSelectorWithProps(itemAreaIdRoleIdSelector, props);
  const userId = useSelectorWithProps(getRosteredShiftUserId, rosteredShiftId);

  return (
    <RosterCell
      type={'top'}
      label={'+ Add Shifts'}
      labelOnHover={true}
      fontWeight={'normal'}
    >
      <WeekCellBlock
        rowNumber={props.pairRowIndex}
        weekDayIndex={props.weekDayIndex}
        menuType={'create'}
        shiftType={'rostered_shift'}
        userId={userId}
        areaId={itemAreaIdRoleId.area_id}
        roleId={itemAreaIdRoleId.role_id}
        rosteredShiftId={rosteredShiftId}
        viewType={'site'}
        leaveId={null}
        timeOffIds={null}
      />
    </RosterCell>
  );
});
