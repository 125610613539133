import {
  AlertErrorBoxDialog,
  BreaksFieldGroup,
  CloseIcon,
  DateRangeFieldGroup,
  dateRangeFieldGroupUtils,
  DefaultTimesheetBreaksAlert,
  DialogContentNarrow,
  NotesField,
  RoleField,
  ShiftSummaryBlock,
} from 'element';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from 'extended-oxygen-elements';
import {
  useActions,
  useFormFields,
  useFormValidator,
  useSelectorWithProps,
  useSetFormField,
  useTimesheetsNewQuery,
  useUpdateStatusEffect,
} from 'hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  BOX_CREATE_TIMESHEET_DIALOG_CLOSED,
  BOX_CREATE_TIMESHEET_DIALOG_ERRORS_CLOSED,
  BOX_CREATE_TIMESHEET_DIALOG_SUBMIT,
  CreateTimesheetDialogParams,
  getDialogErrors,
  getDialogRequestStatus,
  getDialogUpdating,
} from 'state/CreateTimesheetDialog';
import { userRolesBySiteSelector, userSelector } from 'state/UsersCollection';
import createFormFields from './createFormFields';
import makePayload from './makePayload';
import validate from './validate';

type Props = {
  params: CreateTimesheetDialogParams;
};

export default CreateDialogForm;

export function CreateDialogForm({ params: { user_id, date } }: Props) {
  const [dialogSubmit, dialogClosed, dialogErrorsClosed] = useActions([
    BOX_CREATE_TIMESHEET_DIALOG_SUBMIT.request,
    BOX_CREATE_TIMESHEET_DIALOG_CLOSED,
    BOX_CREATE_TIMESHEET_DIALOG_ERRORS_CLOSED,
  ]);

  const {
    query: { site_id },
  } = useTimesheetsNewQuery();
  const loading = useSelector(getDialogUpdating);
  const errors = useSelector(getDialogErrors);

  const roleOptions = useSelectorWithProps(userRolesBySiteSelector, {
    user_id,
    site_id,
  });
  const user = useSelectorWithProps(userSelector, user_id);

  const { formFields, setFormFields } = useFormFields(() =>
    createFormFields({ date, roleOptions })
  );
  const { makeSetFormField } = useSetFormField(setFormFields);

  const { formErrors, submitForm } = useFormValidator(formFields, {
    loading,
    validate,
    makePayload,
    onSubmit: (payload) => {
      dialogSubmit({
        ...payload,
        user_id,
      });
    },
  });

  useUpdateStatusEffect(getDialogRequestStatus, {
    success: dialogClosed,
    cancel: dialogClosed,
  });

  return (
    <>
      <DialogTitle
        endAdornment={
          <IconButton disabled={loading} onClick={dialogClosed}>
            <CloseIcon />
          </IconButton>
        }
      >
        Create timesheet - {user.prefered_or_full_name}
      </DialogTitle>

      <DialogContent data-testid="timesheet-modal-content">
        <AlertErrorBoxDialog
          BoxProps={{
            mb: 2,
          }}
          errors={errors}
          onClose={dialogErrorsClosed}
          data-testid="timesheet-modal-error"
        />

        <DialogContentNarrow>
          <RoleField
            options={roleOptions}
            value={formFields.role}
            error={formErrors.role}
            onChange={makeSetFormField('role')}
          />

          <Divider />

          <DateRangeFieldGroup
            values={formFields}
            onChange={setFormFields}
            errors={formErrors}
          />

          <Divider />

          <BreaksFieldGroup
            value={formFields.breaks}
            error={formErrors.breaks}
            onChange={makeSetFormField('breaks')}
          />
        </DialogContentNarrow>

        {!formFields.breaks.length && <DefaultTimesheetBreaksAlert />}

        <Divider />

        <NotesField
          value={formFields.notes}
          error={formErrors.notes}
          onChange={makeSetFormField('notes')}
        />

        <ShiftSummaryBlock
          breaks={formFields.breaks}
          duration={dateRangeFieldGroupUtils.getDateRangeDurationMinutes(
            formFields
          )}
        />
      </DialogContent>

      <DialogActions>
        <Button
          disabled={loading}
          onClick={dialogClosed}
          data-testid="timesheet-modal-cancel"
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          loading={loading}
          onClick={submitForm}
          data-testid="timesheet-modal-submit"
        >
          Save Timesheet
        </Button>
      </DialogActions>
    </>
  );
}
