import React from 'react';
import { PrintWeekHeader } from '../PrintWeekHeader';
import { WithEventsDurations } from '../../../RosterEventsLegend/components/WithEventsDurations';
import { EventsView } from './EventsView';
import { getCurrencyFormatted, getPreferenceLabel } from 'lib/helpers';
import { useSelector } from 'react-redux';
import {
  getCurrencyCode,
  getCurrencyPlacement,
  getLangPreferences,
  getNumberFormat,
} from 'state/Account';

export const EventsTable = () => {
  const numberFormat = useSelector(getNumberFormat);
  const currencyCode = useSelector(getCurrencyCode);
  const currencyPlacement = useSelector(getCurrencyPlacement);
  const langPreferences = useSelector(getLangPreferences);

  const renderEventsLegend = (durations: any) => {
    return (
      <>
        <div className={'roleName'} style={{ paddingBottom: '5px' }}>
          {getPreferenceLabel(
            langPreferences,
            'event',
            'singular',
            'Events',
            true
          )}{' '}
          & Forecasts
        </div>

        {durations.map((data: any, key: number) => (
          <div
            className="elmo-roster-legend__duration event-duration"
            key={key}
            style={{
              display: data.isVisible ? 'flex' : 'none',
              marginLeft: 0,
            }}
          >
            <div
              className="elmo-roster-legend__duration-value"
              style={{ marginLeft: 0 }}
            >
              {data.title}:{' '}
              {getCurrencyFormatted(
                numberFormat,
                currencyCode,
                currencyPlacement,
                data.value
              ).replace(/ /i, '')}
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <table
      style={{ width: '100%' }}
      cellSpacing="0"
      className="area-table unassigned-table events-table"
      data-testid={'printable-events-table'}
    >
      <PrintWeekHeader showDays={true} />
      <tbody>
        <tr>
          <td
            className="firstCell border-cell"
            key={'cell-legend'}
            style={{ maxWidth: '125px' }}
          >
            <div className="firstCellContent">
              {
                <WithEventsDurations>
                  {(durations: any) => renderEventsLegend(durations)}
                </WithEventsDurations>
              }
            </div>
          </td>
          <td className="border-cell" key={'cell-data'}>
            <table>
              <tbody>
                <EventsView />
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
