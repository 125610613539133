import { SagaIterator } from 'redux-saga';
import {
  all,
  call,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { EventsPeriod, SagaAction, Event } from 'type';
import { outDatedDataMessage } from 'messages';
import { Api } from 'lib/Api';
import {
  GetEventsRequest,
  ManagerDashboardGetEventsResponse,
} from 'lib/Api/type';
import { formatError } from 'state/helpers';
import { getRotaUserListRequest } from 'state/UsersCollection';
import * as fetchPageData from 'state/FetchPageData';
import {
  BOX_EVENT_MODAL_CLOSE,
  BOX_EVENT_MODAL_CREATE_EVENT_SUCCESS,
  BOX_EVENT_MODAL_OPEN,
  BOX_EVENT_MODAL_REMOVE_EVENT_SUCCESS,
  BOX_EVENT_MODAL_UPDATE_EVENT_SUCCESS,
} from 'state/Roster/EventModal';
import { processApiRequest } from 'state/ProcessApiRequest';
import { showOutDatedNotification } from 'state/ToastNotifier';
import {
  BOX_MANAGER_DASHBOARD_CLEAR_ERRORS,
  managerDashboardFiltersSelector,
} from '../../ManagerDashboard';
import { ManagerDashboardFilters } from '../../ManagerDashboard/types';
import * as actions from '../actions';
import {
  getFilters,
  getPager,
  getSelectedPeriod,
  getUpdatedId,
  payloadSelector,
} from '../selectors';
import { filtersToPayload } from '../helpers';

export const fetchEvents = function* (payload: GetEventsRequest) {
  yield put(BOX_MANAGER_DASHBOARD_CLEAR_ERRORS());

  const dashboardFilters: ManagerDashboardFilters = yield select(
    managerDashboardFiltersSelector
  );

  return yield call(
    processApiRequest,
    Api.ManagerDashboard.getEvents,
    payload,
    dashboardFilters
  );
};

export const getEventsListRequest = function* (
  payload?: GetEventsRequest
): SagaIterator {
  const defaultPayload: GetEventsRequest = yield select(payloadSelector);

  const data: ManagerDashboardGetEventsResponse = yield call(fetchEvents, {
    ...defaultPayload,
    ...payload,
  });

  yield put(actions.BOX_EVENTS_GET_EVENTS_SUCCESS(data));
};

const getData = function* (): SagaIterator {
  yield put(
    fetchPageData.BOX_FETCH_PAGE_DATA_REQUEST(
      fetchPageData.MANAGER_DASHBOARD_EVENTS
    )
  );

  try {
    const filters: ReturnType<typeof getFilters> = yield select(getFilters);
    const { pageSize }: ReturnType<typeof getPager> = yield select(getPager);
    const period: ReturnType<typeof getSelectedPeriod> = yield select(
      getSelectedPeriod
    );

    const payload = filtersToPayload(filters, period, { page: 1, pageSize });

    yield all([
      call(getEventsListRequest, payload),
      call(getRotaUserListRequest),
    ]);

    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_SUCCESS(
        fetchPageData.MANAGER_DASHBOARD_EVENTS
      )
    );
  } catch (error) {
    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_FAILURE(
        fetchPageData.MANAGER_DASHBOARD_EVENTS
      )
    );
  }
};

const changePage = function* ({
  payload: page = 1,
}: SagaAction<number>): SagaIterator {
  try {
    const filters: ReturnType<typeof getFilters> = yield select(getFilters);
    const period: ReturnType<typeof getSelectedPeriod> = yield select(
      getSelectedPeriod
    );
    const { pageSize }: ReturnType<typeof getPager> = yield select(getPager);
    const allFilters = filtersToPayload(filters, period, { page: 1, pageSize });
    const defaultPayload: ReturnType<typeof payloadSelector> = yield select(
      payloadSelector
    );

    const response: ManagerDashboardGetEventsResponse = yield call(
      fetchEvents,
      {
        ...defaultPayload,
        ...allFilters,
        page,
      }
    );
    yield put(actions.BOX_EVENTS_CHANGE_PAGE_SUCCESS(response));
  } catch (error) {
    console.log(error);
    yield put(actions.BOX_EVENTS_CHANGE_PAGE_FAILURE(formatError(error)));
  }
};
const changePageSize = function* ({
  payload: pageSize,
}: SagaAction<number>): SagaIterator {
  try {
    const filters: ReturnType<typeof getFilters> = yield select(getFilters);
    const period: ReturnType<typeof getSelectedPeriod> = yield select(
      getSelectedPeriod
    );
    const payload = filtersToPayload(filters, period, { page: 1, pageSize });
    const defaultPayload: ReturnType<typeof payloadSelector> = yield select(
      payloadSelector
    );

    const response: ManagerDashboardGetEventsResponse = yield call(
      fetchEvents,
      {
        ...defaultPayload,
        ...payload,
        page: 1,
        per_page: pageSize,
      }
    );
    yield put(actions.BOX_EVENTS_CHANGE_PAGE_SIZE_SUCCESS(response));
  } catch (error) {
    yield put(actions.BOX_EVENTS_CHANGE_PAGE_SIZE_FAILURE(formatError(error)));
  }
};
const updateOrdering = function* (): SagaIterator {
  try {
    const filters: ReturnType<typeof getFilters> = yield select(getFilters);
    const { pageSize }: ReturnType<typeof getPager> = yield select(getPager);
    const period: ReturnType<typeof getSelectedPeriod> = yield select(
      getSelectedPeriod
    );
    const payload = filtersToPayload(filters, period, { page: 1, pageSize });

    const response: ManagerDashboardGetEventsResponse = yield call(
      fetchEvents,
      payload
    );

    yield put(
      actions.BOX_EVENTS_UPDATE_ORDERING_SUCCESS({
        filters,
        ...response,
      })
    );
  } catch (error) {
    console.log(error); // TODO
  }
};

const togglePeriod = function* (): SagaIterator {
  try {
    const filters: ReturnType<typeof getFilters> = yield select(getFilters);
    const { pageSize }: ReturnType<typeof getPager> = yield select(getPager);
    const period: ReturnType<typeof getSelectedPeriod> = yield select(
      getSelectedPeriod
    );
    const payload = filtersToPayload(filters, period, { page: 1, pageSize });

    const response: ManagerDashboardGetEventsResponse = yield call(
      fetchEvents,
      payload
    );
    yield put(actions.BOX_EVENTS_RESET_FILTERS_FORM());
    yield put(
      actions.BOX_EVENTS_TOGGLE_PERIOD_TYPE_SUCCESS({
        filters,
        ...response,
      })
    );
  } catch (error) {
    console.log(error); // TODO
  }
};

const changeFilters = function* (): SagaIterator {
  try {
    const filters: ReturnType<typeof getFilters> = yield select(getFilters);
    const { pageSize }: ReturnType<typeof getPager> = yield select(getPager);
    const period: ReturnType<typeof getSelectedPeriod> = yield select(
      getSelectedPeriod
    );
    const payload = filtersToPayload(filters, period, { page: 1, pageSize });

    const response: ManagerDashboardGetEventsResponse = yield call(
      fetchEvents,
      payload
    );

    yield put(
      actions.BOX_EVENTS_UPDATE_FILTERS_SUCCESS({
        filters,
        ...response,
      })
    );
  } catch (error) {
    yield put(actions.BOX_EVENTS_UPDATE_FILTERS_FAILURE(formatError(error)));
  }
};

const createEventsActions = {
  eventsModal: {
    closeModal: BOX_EVENT_MODAL_CLOSE,
    createSuccess: BOX_EVENT_MODAL_CREATE_EVENT_SUCCESS,
    deleteSuccess: BOX_EVENT_MODAL_REMOVE_EVENT_SUCCESS,
    updateSuccess: BOX_EVENT_MODAL_UPDATE_EVENT_SUCCESS,
  },
};

const openEventEditModal = function* ({
  payload,
}: SagaAction<any>): SagaIterator {
  const act = createEventsActions.eventsModal;

  yield put(BOX_EVENT_MODAL_OPEN(payload));

  const { createSuccess, deleteSuccess, updateSuccess } = yield race({
    close: take(act.closeModal),
    createSuccess: take(act.createSuccess),
    deleteSuccess: take(act.deleteSuccess),
    updateSuccess: take(act.updateSuccess),
  });

  yield put(actions.BOX_MANAGER_EVENT_SET_EDITED_ID(payload.id));

  if (deleteSuccess || createSuccess || updateSuccess) {
    yield call(togglePeriod);
  }
};

const isFetched = (
  params: ReturnType<typeof fetchPageData.getFetchFlags>
): boolean => {
  return params.isFetched && !params.isFetching;
};

const showNotification = function* (): SagaIterator {
  const notificationId = 'managerEventId';
  yield put(actions.BOX_MANAGER_EVENTS_TOGGLE_NOTIFICATION());
  const { undo, hide } = yield call(showOutDatedNotification, {
    notificationId,
    message: outDatedDataMessage,
    undoText: 'Reload',
  });

  if (undo) {
    const period: EventsPeriod = yield select(getSelectedPeriod);
    yield put(actions.BOX_EVENTS_TOGGLE_PERIOD_TYPE_REQUEST(period));
    yield put(actions.BOX_MANAGER_EVENTS_TOGGLE_NOTIFICATION());
  }

  if (hide) {
    yield put(actions.BOX_MANAGER_EVENTS_TOGGLE_NOTIFICATION());
  }
};

const showOutOfDateNotification = function* ({
  payload,
}: SagaAction<Event>): SagaIterator {
  const eventFlag: ReturnType<typeof fetchPageData.getFetchFlags> =
    yield select(fetchPageData.getFetchFlags, 'MANAGER_DASHBOARD_EVENTS');

  const editedId = yield select(getUpdatedId);

  if (isFetched(eventFlag)) {
    if (payload.id !== editedId) {
      yield call(showNotification);
      yield put(actions.BOX_MANAGER_EVENT_DELETE_EDITED_ID());
    }
  }
};

const showDeleteNotification = function* ({
  payload: deletedId,
}: SagaAction<string>): SagaIterator {
  const eventFlag: ReturnType<typeof fetchPageData.getFetchFlags> =
    yield select(fetchPageData.getFetchFlags, 'MANAGER_DASHBOARD_EVENTS');

  const editedId = yield select(getUpdatedId);

  if (isFetched(eventFlag) && deletedId !== editedId) {
    yield call(showNotification);
    yield put(actions.BOX_MANAGER_EVENT_DELETE_EDITED_ID());
  }
};

export const watchManagerEvents = function* (): SagaIterator {
  yield takeLatest(actions.BOX_EVENTS_DATA_REQUEST, getData);
  yield takeLatest(actions.BOX_EVENTS_CHANGE_PAGE_REQUEST, changePage);
  yield takeLatest(actions.BOX_EVENTS_CHANGE_PAGE_SIZE_REQUEST, changePageSize);
  yield takeLatest(actions.BOX_EVENTS_TOGGLE_PERIOD_TYPE_REQUEST, togglePeriod);
  yield takeLatest(actions.BOX_EVENTS_UPDATE_FILTERS_REQUEST, changeFilters);
  yield takeLatest(actions.BOX_EVENTS_UPDATE_ORDERING_REQUEST, updateOrdering);
  yield takeLatest(actions.BOX_EVENTS_OPEN_EVENTS_MODAL, openEventEditModal);
  yield takeLatest(
    actions.BOX_MANAGER_EVENTS_SHOW_UPDATE_NOTIFICATION,
    showOutOfDateNotification
  );
  yield takeLatest(actions.BOX_MANAGER_EVENTS_DELETED, showDeleteNotification);
};
