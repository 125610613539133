import React, { ReactNode } from 'react';
import { Dropdown } from '../Dropdown';
import { DropdownOption } from './type';

export type CardHeaderOptionsDropdownProps = {
  icon: ReactNode;
  dropdownOptions: DropdownOption[];
  /** ariaLabel is for WCAG accessibility compliance, (optional). */
  ariaLabel?: string;
  id?: string;
  isDisabled?: boolean;
};

function CardHeaderOptionsDropdown({
  icon,
  dropdownOptions,
  ariaLabel,
  id,
  isDisabled,
}: CardHeaderOptionsDropdownProps) {
  const dropdownChildren: any = [];

  dropdownOptions.forEach((option: DropdownOption, index: number) => {
    dropdownChildren.push(
      <Dropdown.Item
        value={null}
        onClick={option.onClick}
        isDisabled={option.isDisabled}
        key={index}
      >
        {option.icon} {option.label}
      </Dropdown.Item>
    );
    if (option.withDivider) {
      dropdownChildren.push(
        <Dropdown.Divider type="short" key={index + 100} />
      );
    }
  });

  return (
    <Dropdown
      isDisabled={isDisabled}
      icon={icon}
      position={'bottom-end'}
      ariaLabel={ariaLabel}
      id={id}
    >
      {dropdownChildren}
    </Dropdown>
  );
}

export default CardHeaderOptionsDropdown;
