import React from 'react';
import { useSelectorWithProps } from 'hooks';
import { hasPermissionSelector } from 'state/Auth';
import {
  ActionButtonProps,
  ApproveButton,
  CreateButton,
  DeleteButton,
  EditButton,
} from './components';

export function PendingActions(props: ActionButtonProps) {
  const approvePermission = useSelectorWithProps(
    hasPermissionSelector,
    'roster.timesheet.edit.approve'
  );

  return (
    <>
      <DeleteButton {...props} />

      <EditButton {...props} />

      {approvePermission && <ApproveButton {...props} />}

      <CreateButton {...props} />
    </>
  );
}
