import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'elmo-elements';
import { UserProfileFields } from 'type';
import { IsHrService, Layout } from 'element';
import { getUserName } from 'lib/helpers';
import { StoreState } from 'state/types';
import { getUserProfile } from 'state/Users/UserProfile';
import { PinCodeModal, UserHeader } from '../../components';
import {
  Approvals,
  CustomFields,
  PaymentDetails,
  Position,
  ProfileDetails,
  RosterDetails,
  UserDetails,
} from './components';
import { isHrService } from '../../../../../../helpers';
import './style.scss';

export type OwnProps = {};

export type StateProps = {
  userProfile: UserProfileFields;
};

export type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

export const View = ({userProfile}: Props) => (
  <>
    <Layout.Header>
      <UserHeader title={getUserName(userProfile)}/>
    </Layout.Header>
    <Layout.Content>
      <Row wrap={'wrap'} role="presentation">
        <Col
          className={'mb-4'}
          span={24}
          md={12}
          xxl={8}
          xxxl={6}
          isAuto={true}
          role="presentation"
        >
          <UserDetails/>
        </Col>
        <Col
          className={'mb-4'}
          span={24}
          md={12}
          xxl={8}
          xxxl={6}
          isAuto={true}
          role="presentation"
        >
          <ProfileDetails/>
        </Col>
        {!isHrService('bravo') && <Col
          className={'mb-4'}
          span={24}
          md={12}
          xxl={8}
          xxxl={6}
          isAuto={true}
          role="presentation"
        >
          <PaymentDetails/>
        </Col>}

        <IsHrService serviceName={['hrCore', 'standalone']}>
          <Col
            className={'mb-4'}
            span={24}
            md={12}
            xxl={8}
            xxxl={6}
            isAuto={true}
            role="presentation"
          >
            <RosterDetails/>
          </Col>
        </IsHrService>

        {!isHrService('bravo') && <Col
          className={'mb-4'}
          span={24}
          md={12}
          xxl={8}
          xxxl={6}
          isAuto={true}
          role="presentation"
        >
          <CustomFields/>
        </Col>
        }
        <Col
          className={'mb-4'}
          span={24}
          md={12}
          xxl={8}
          xxxl={6}
          isAuto={true}
          role="presentation"
        >
          <Approvals/>
        </Col>
        <Col
          className={'mb-4'}
          span={24}
          md={12}
          xxl={8}
          xxxl={6}
          isAuto={true}
          role="presentation"
        >
          <Position/>
        </Col>
      </Row>
      <PinCodeModal/>
    </Layout.Content>
  </>
);

const mapStateToProps = (state: StoreState): StateProps => ({
  userProfile: getUserProfile(state)
});

export default connect(mapStateToProps)(View);
