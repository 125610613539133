import { fork } from 'redux-saga/effects';
import socketConnection from 'lib/SocketConnection';
import { makeChannelListener, takeLatestConnect } from 'lib/store-utils';
import { BOX_GLOBAL_ALERT_OPEN } from 'state/GlobalAlert';
import * as actions from './actions';
import * as channels from './channels';

const initWSHandlers = makeChannelListener((emit) => {
  socketConnection.on('reconnectError', () => {
    emit(
      BOX_GLOBAL_ALERT_OPEN(
        'To receive real-time updates like notifications or' +
          ' rosters posted by other managers, please refresh your browser.'
      )
    );
  });
});

export const watchSockets = function* () {
  yield fork(initWSHandlers);

  yield takeLatestConnect(
    actions.BOX_SOCKETS_GLOBAL_CHANNELS,
    channels.makeGlobalChannel,
    socketConnection
  );
  yield takeLatestConnect(
    actions.BOX_SOCKETS_EMPLOYEE_DASHBOARD,
    channels.makeEmployeeChannel,
    socketConnection
  );
  yield takeLatestConnect(
    actions.BOX_SOCKETS_MANAGER_DASHBOARD,
    channels.makeManagerChannel,
    socketConnection
  );
  yield takeLatestConnect(
    actions.BOX_SOCKETS_ROSTER,
    channels.makeRosterChannel,
    socketConnection
  );
  yield takeLatestConnect(
    actions.BOX_SOCKETS_ROSTER_WEEK,
    channels.makeRosterWeekChannel,
    socketConnection
  );
  yield takeLatestConnect(
    actions.BOX_SOCKETS_TIMESHEETS_WEEKLY,
    channels.makeTimesheetsWeeklyChannel,
    socketConnection
  );
  yield takeLatestConnect(
    actions.BOX_SOCKETS_TIMESHEETS_DAILY,
    channels.makeTimesheetsDailyChannel,
    socketConnection
  )
};
