import React, { Component, ReactChild } from 'react';
import { connect } from 'react-redux';
import { Filter } from 'elmo-elements';
import { StoreState } from 'state/types';

import { CustomFilters } from './components/CustomFilters';

import {
  BOX_WHOS_WORKING_APPLY_FORM_FILTERS_REQUEST,
  BOX_WHOS_WORKING_RESET_FORM_FILTERS,
  BOX_WHOS_WORKING_TOGGLE_PERIOD_TYPE_REQUEST, getCustomFormPeriod,
  getSelectedPeriod
} from 'state/ManagerDashboard/WhosWorking';
import { WorkingPeriod } from 'type';

type StateProps = {
  selectedFilter: WorkingPeriod;
  customPeriod: WorkingPeriod
};

type DispatchProps = {
  updateCustomFilters: (period: WorkingPeriod) => void;
  clearCustomFilters: () => void;
  togglePeriodFilter: (period: WorkingPeriod) => void;
};

type Props = StateProps & DispatchProps;

export class WorkingFiltersComponent extends Component<Props> {
  statuses: {
    label: string;
    value: WorkingPeriod;
  }[] = [
    {
      label: 'Working now',
      value: 'now'
    },
    {
      label: 'Starting soon',
      value: 'soon'
    },
    {
      label: 'Just finished',
      value: 'just-finished'
    },
    {
      label: 'Tomorrow',
      value: 'tomorrow'
    }
  ];

  render() {
    const { updateCustomFilters, selectedFilter } = this.props;

    return (
      <div className="custom-filters-wrapper">
        <Filter
          id="manager-dashboard-whos-working-filters"
          onStatusChange={this.onStatusChange}
          statuses={this.statuses}
          renderCustomFilters={this.renderCustomFilters}
          onApplyCustomFilters={() => {
            updateCustomFilters(this.props.customPeriod);
          }}
          selectedValue={selectedFilter}
        />
      </div>
    );
  }

  onStatusChange = (period: string) => {
    this.props.togglePeriodFilter(period as WorkingPeriod);
  }

  renderCustomFilters = (): ReactChild => <CustomFilters />;

}

const mapStateToProps = (state: StoreState): StateProps => ({
  selectedFilter: getSelectedPeriod(state),
  customPeriod: getCustomFormPeriod(state)
});

const mapDispatchToProps: DispatchProps = {
   updateCustomFilters: BOX_WHOS_WORKING_APPLY_FORM_FILTERS_REQUEST,
   clearCustomFilters: BOX_WHOS_WORKING_RESET_FORM_FILTERS,
   togglePeriodFilter: BOX_WHOS_WORKING_TOGGLE_PERIOD_TYPE_REQUEST
};
export const WorkingFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkingFiltersComponent);
