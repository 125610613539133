import React from 'react';
import { FieldGroupProps, TimesheetSettings } from 'type';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Typography,
} from 'extended-oxygen-elements';
import { useSelector } from 'react-redux';
import { getLangPreferences } from 'state/Account';
import { useSetCheckbox, useSetFormField } from 'hooks';
import { capitalize, keyMirror } from 'lib/helpers';
import { InlineInputWrapper } from '../../../components';
import { DurationField, DurationMinutesRequiredField } from 'element';
import marketLabels from 'marketLabels';
import FormControlSpacing from 'element/FormControlSpacing';

type AddingTimesheetsFieldGroupValue = Pick<
  TimesheetSettings,
  'time_tracking' | 'punchclock_diff'
>;
type AddingTimesheetsFieldGroupErrors = {};

type Props = FieldGroupProps<
  AddingTimesheetsFieldGroupValue,
  AddingTimesheetsFieldGroupErrors
>;

export default function EnablingTimesheetsFieldGroup({
  onChange,
  values,
}: Props) {
  const langPreferences = useSelector(getLangPreferences);
  const { makeSetCheckbox } = useSetCheckbox(onChange);
  const { makeSetFormField } = useSetFormField(onChange);

  return (
    <>
      <Typography variant="h4" component="h2">
        Clock in via desktop
      </Typography>
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.time_tracking}
              onChange={makeSetCheckbox('time_tracking')}
            />
          }
          label={`Enable clock on within the ${capitalize(
            langPreferences.employee.plural
          )} dashboard`}
        />
      </FormControl>
      <FormControlSpacing flexDirection="column">
        <Typography variant="h6" component="h3">
          Enable clock on
        </Typography>
        <Typography variant="body1" component="h6">
          Enable clock on within
          <InlineInputWrapper>
            <DurationMinutesRequiredField
              inputProps={{
                size: 4,
              }}
              name={'punchclock_diff'}
              margin="none"
              fullWidth={false}
              formatUnit="minutes"
              defaultUnit="minutes"
              minutesPrefix="m"
              value={values.punchclock_diff}
              onValueChange={makeSetFormField('punchclock_diff')}
            />
          </InlineInputWrapper>
          of {marketLabels.rosteredShift}.
        </Typography>
      </FormControlSpacing>
    </>
  );
}
