import ChainHandler from 'lib/ChainHandler';
import { getIsFinished, getIsOnBreak } from '../helpers';
import { HandlerArgs, HandlerResult } from './types';

class ShiftsArrayHandler extends ChainHandler<HandlerResult, HandlerArgs> {
  handle(...args: HandlerArgs): HandlerResult {
    const [state, ...restArgs] = args;
    const [payload] = restArgs;
    const { timesheet, rostered_shift } = payload;

    const updatedState: typeof state = {
      ...state,
      workingShifts: state.workingShifts.map((shift) => {
        if (shift.id === rostered_shift?.id) {
          const is_on_break = getIsOnBreak(timesheet);
          const is_finished = getIsFinished(timesheet);

          return {
            ...shift,
            ...rostered_shift,
            timesheet,
            shift_id: rostered_shift.id,
            is_on_break,
            is_finished,
          };
        }

        return shift;
      }),
    };

    return super.handle(updatedState, ...restArgs);
  }
}

export default ShiftsArrayHandler;
