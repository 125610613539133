import { PAGE_SIZE } from 'lib/config';
import { EventsFilters, EventsReducerState } from './types';
import { cloneDeep } from 'lodash';
import { emptyEvent } from '../../Roster/EventModal/state';

export const defaultFilters: EventsFilters = {
  statuses: null,
  start: null,
  end: null,
  sort: {
    column: 'title',
    direction: 'asc',
  },
};

export const getDefaultState = (): EventsReducerState => ({
  isFetching: false,
  events: [],
  errors: [],
  pager: {
    currentPage: 1,
    pageSize: PAGE_SIZE[0],
    total: 0
  },
  period: 'all',
  filters: cloneDeep(defaultFilters),
  filtersForm: cloneDeep(defaultFilters),
  detailsModal: {
    isOpen: false,
    event: emptyEvent
  }
});
