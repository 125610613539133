import { SiteAreaRoleModalReducerState } from './types';

export const getDefaultState = (): SiteAreaRoleModalReducerState => ({
  isOpened: false,
  isResetButtonEnabled: false,
  selectedRoleTriples: null,
  siteId: null,
  archivedAreaIdsToBeShown: [],
  archivedRoleIdsToBeShown: []
});
