import { useSelector } from 'react-redux';
import {
  punchInViolatedTimesheetsSelector,
  punchOutViolatedTimesheetsSelector,
  violatedTimesheetsSelector,
} from 'state/TimesheetsWeeklyPage';

type Props = {
  id: string;
};

export function useIsTimesheetPunchInViolated({ id }: Props) {
  const punchInViolatedTimesheets = useSelector(
    punchInViolatedTimesheetsSelector
  );

  return !!punchInViolatedTimesheets[id];
}

export function useIsTimesheetPunchOutViolated({ id }: Props) {
  const punchOutViolatedTimesheets = useSelector(
    punchOutViolatedTimesheetsSelector
  );

  return !!punchOutViolatedTimesheets[id];
}

export function useIsTimesheetViolated({ id }: Props) {
  const violatedTimesheets = useSelector(violatedTimesheetsSelector);

  return !!violatedTimesheets[id]
}

export function useGetIsTimesheetViolated(props: Props) {
  const isTimesheetViolated = useIsTimesheetViolated(props);

  return () => isTimesheetViolated;
}
