import React, { Component } from 'react';
import { Button, Heading } from 'elmo-elements';
import { ChevronLeftIcon, ChevronRightIcon } from 'element';
import moment, { Moment } from 'moment';
import './CalendarNavigation.scss';

type Props = {
  toNextMonth: () => void;
  toPrevMonth: () => void;
  toCurrentMonth: () => void;
  date: Moment;
};

export default class CalendarNavigation extends Component<Props> {

  render() {
    const { date } = this.props;
    return (
      <div className="calendar-navigation">
        <div className="calendar-navigation__controls">
          <Button
            icon={<ChevronLeftIcon />}
            className="to-prev"
            isText={true}
            onClick={this.props.toPrevMonth}
            ariaLabel="Previous"
          />
          <div className="calendar-navigation__date">
            <Heading isBold={true} className="pr-2 month" size={'lg'}>{date.format('MMM')}</Heading>
            <Heading isBold={true} size={'lg'} className="year">{date.format('Y')}</Heading>
          </div>
          <Button
            icon={<ChevronRightIcon />}
            isText={true}
            className="to-next"
            onClick={this.props.toNextMonth}
            ariaLabel="Next"
          />
        </div>
        <Button onClick={this.props.toCurrentMonth} className="today-btn">Today</Button>
      </div>
    );
  }

}
