import { CloseIcon, DialogSubTitle } from 'element';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from 'extended-oxygen-elements';
import { useSelectorWithProps } from 'hooks';
import { capitalize } from 'lib/helpers';
import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLangPreferences } from 'state/Account';
import { getArea, getRole, getSiteName } from 'state/AccountTree';
import { getAssignmentArea, getAssignmentRole } from 'state/Assignments';
import { userSelector } from 'state/UsersCollection';

type Props = {
  area_id: string;
  open: boolean;
  onClose: () => void;
};

export default function DialogAssignedUsers({ area_id, open, onClose }: Props) {
  const langPreferences = useSelector(getLangPreferences);
  const { name: areaName, site_id } = useSelectorWithProps(getArea, area_id);
  const siteName = useSelectorWithProps(getSiteName, site_id);
  const { users_count, role_ids } = useSelectorWithProps(getAssignmentArea, {
    site_id,
    area_id,
  });

  const lastRoleId = _.last(role_ids);

  const closeDialog = () => {
    onClose();
  };

  return (
    <Dialog maxWidth="xs" open={open} onClose={closeDialog}>
      <DialogTitle
        endAdornment={
          <IconButton onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        }
      >
        {capitalize(langPreferences.employee.plural)} assigned
      </DialogTitle>

      <DialogContent>
        <DialogSubTitle>
          {users_count} {langPreferences.employee.plural} assigned to {areaName}{' '}
          in {siteName}.
        </DialogSubTitle>

        {role_ids.map((role_id) => (
          <Box mb={role_id !== lastRoleId ? 3 : 0} key={role_id}>
            <AssignmentRole
              site_id={site_id}
              area_id={area_id}
              role_id={role_id}
            />
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          size="medium"
          onClick={closeDialog}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type AssignmentRoleProps = {
  site_id: string;
  area_id: string;
  role_id: string;
};

function AssignmentRole(props: AssignmentRoleProps) {
  const accountTreeRole = useSelectorWithProps(getRole, props.role_id);
  const assignmentRole = useSelectorWithProps(getAssignmentRole, props);

  return (
    <>
      <Typography variant="h6">
        {accountTreeRole.name} ({assignmentRole.users_count})
      </Typography>
      <List aria-label={accountTreeRole.name}>
        {assignmentRole.users.map((userId) => (
          <AssignmentUser id={userId} key={userId} />
        ))}
      </List>
    </>
  );
}

type AssignmentUserProps = {
  id: string;
};

function AssignmentUser({ id }: AssignmentUserProps) {
  const user = useSelectorWithProps(userSelector, id);

  return (
    <ListItem aria-label={user.prefered_or_full_name}>
      <ListItemAvatar>
        <Avatar light src={user.avatar.src} alt={user.prefered_or_full_name} />
      </ListItemAvatar>
      <ListItemText>{user.prefered_or_full_name}</ListItemText>
    </ListItem>
  );
}
