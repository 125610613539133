import React from 'react';
import clsx from 'clsx';
import { PartialBy } from 'type';
import { ShiftCardPopperAdd } from '../../views';
import useShiftCardEventStyles from './useShiftCardEventStyles';

type ShiftCardPopperAddProps = React.ComponentProps<typeof ShiftCardPopperAdd>;
type ShiftCardEventPopperAddProps = PartialBy<
  ShiftCardPopperAddProps,
  'children'
>;

export function ShiftCardEventPopperAdd({
  children = '+ Add another event',
  className,
  ...restProps
}: ShiftCardEventPopperAddProps) {
  const eventCardClasses = useShiftCardEventStyles();

  return (
    <ShiftCardPopperAdd
      {...restProps}
      className={clsx(eventCardClasses.btnAdd, className)}
    >
      {children}
    </ShiftCardPopperAdd>
  );
}
