import React from 'react';
import classNames from 'clsx';
import './RosterCell.scss';
import { svgTextToBase64 } from './helpers';

export type Type = 'full' | 'top' | 'bottom';

type Props = {
  type?: Type;
  children?: React.ReactNode;
  hasMinWidth?: boolean;
  isTall?: boolean;
  label?: string;
  labelOnHover?: boolean;
  fontWeight?: string | number;
};

function RosterCell({
  type = 'full',
  children,
  hasMinWidth = false,
  isTall = false,
  label,
  labelOnHover = false,
  fontWeight = 600
}: Props) {
  const img = svgTextToBase64(label, fontWeight);
  return (
    <div
      className={classNames('elmo-roster-cell', `elmo-roster-cell--${type}`, {
        'elmo-roster-cell--has-min-width': hasMinWidth,
        'elmo-roster-cell--is-tall': isTall,
        'elmo-roster-cell--placeholder': labelOnHover
      })}
      style={
        label
          ? {
              backgroundImage: `url('${img}')`,
              backgroundRepeat: 'no-repeat'
            }
          : undefined
      }
    >
      {children}
    </div>
  );
}

export default RosterCell;
