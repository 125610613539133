import React from 'react';

interface MapProps extends google.maps.MapOptions {
  style?: React.CSSProperties;
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  children?: React.ReactNode;
}

const MapComponent = ({
  onClick,
  onIdle,
  children,
  style,
  ...options
}: MapProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();
  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new google.maps.Map(ref.current));
    }
  }, [ref]);

  React.useEffect(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  React.useEffect(() => {
    if (map) {
      ['click', 'idle'].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener('click', onClick);
      }

      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div ref={ref} style={style} />

      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

export const Map = ({ style: propsStyle, ...restProps }: MapProps) => {
  const style: React.CSSProperties = {
    // defaults:
    height: 300,
    ...propsStyle,
  };

  const props: MapProps = {
    // defaults:
    zoom: 4,
    ...restProps,
    style,
  };

  return <MapComponent {...props} />
};
