import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { Api } from 'lib/Api';
import { formatError } from '../helpers';
import {
  BOX_REPORT_GENERATE_EXIST_REPORT_REQUEST,
  BOX_REPORT_GENERATE_EXIST_REPORT_SUCCESS,
  BOX_REPORT_GENERATE_REPORT_FAILURE,
  BOX_REPORT_GENERATE_REPORT_REQUEST,
  BOX_REPORT_GENERATE_REPORT_SUCCESS,
  BOX_REPORT_GET_REPORT_FAILURE,
  BOX_REPORT_GET_REPORT_REQUEST,
  BOX_REPORT_GET_REPORT_SUCCESS,
  BOX_REPORT_SCHEDULE_UPDATE_FAILURE,
  BOX_REPORT_SCHEDULE_UPDATE_REQUEST,
  BOX_REPORT_SCHEDULE_UPDATE_SUCCESS,
  BOX_REPORT_UPDATE_REPORT_FAILURE,
  BOX_REPORT_UPDATE_REPORT_REQUEST,
  BOX_REPORT_UPDATE_REPORT_SUCCESS,
} from './actions';
import {
  GeneratedReport,
  Report,
  ReportSchedule,
  SagaAction,
} from '../../type';
import { BOX_REPORTS_CREATE_REDIRECT_MODAL_CLOSE } from '../Reports';
import { getReportId, getReportType } from './selectors';
import { ReportRequest } from 'lib/Api/type';
import { processApiRequest } from '../ProcessApiRequest';

const getReport = function* (action: SagaAction<ReportRequest>): SagaIterator {
  try {
    const report: Report = yield call(
      processApiRequest,
      Api.Reports.get,
      action.payload
    );
    yield put(BOX_REPORT_GET_REPORT_SUCCESS(report));
  } catch (error) {
    yield put(BOX_REPORT_GET_REPORT_FAILURE(formatError(error)));
  }
};

const generateReport = function*(action: SagaAction<Report>) {
  try {
    const generatedReport: GeneratedReport = yield call(
      processApiRequest,
      Api.Reports.generate,
      action.payload
    );
    yield put(
      BOX_REPORT_GENERATE_REPORT_SUCCESS({
        generated: generatedReport,
        report: action.payload
      })
    );
    yield put(BOX_REPORTS_CREATE_REDIRECT_MODAL_CLOSE());
  } catch (error) {
    yield put(BOX_REPORT_GENERATE_REPORT_FAILURE(formatError(error)));
  }
};

const generateExistReport = function*(
  action: SagaAction<ReportRequest>
) {
  try {
    const generatedReport: GeneratedReport = yield call(
      processApiRequest,
      Api.Reports.generateExistReport,
      action.payload
    );
    yield put(BOX_REPORT_GENERATE_EXIST_REPORT_SUCCESS(generatedReport));
  } catch (error) {
    yield put(BOX_REPORT_GENERATE_REPORT_FAILURE(formatError(error)));
  }
};

const updateReport = function*(action: SagaAction<Report>) {
  try {
    yield call(processApiRequest, Api.Reports.update, action.payload);
    yield put(BOX_REPORT_UPDATE_REPORT_SUCCESS(action.payload));
  } catch (error) {
    yield put(BOX_REPORT_UPDATE_REPORT_FAILURE(formatError(error)));
  }
};

const updateSchedule = function*({
  payload: schedule
}: SagaAction<ReportSchedule>) {
  try {
    const id: string = yield select(getReportId);
    const reportType = yield select(getReportType);
    yield call(processApiRequest, Api.Reports.putSchedule, {
      id,
      schedule
    });
    yield put(BOX_REPORT_SCHEDULE_UPDATE_SUCCESS(schedule));
  } catch (error) {
    yield put(BOX_REPORT_SCHEDULE_UPDATE_FAILURE(formatError(error)));
  }
};

export const watchReport = function*(): SagaIterator {
  yield takeLatest(BOX_REPORT_GET_REPORT_REQUEST as any, getReport); // TODO double-check
  yield takeLatest(BOX_REPORT_GENERATE_REPORT_REQUEST, generateReport);
  yield takeLatest(
    BOX_REPORT_GENERATE_EXIST_REPORT_REQUEST as any,  // TODO double-check
    generateExistReport
  );
  yield takeLatest(BOX_REPORT_UPDATE_REPORT_REQUEST, updateReport);
  yield takeLatest(BOX_REPORT_SCHEDULE_UPDATE_REQUEST, updateSchedule);
};
