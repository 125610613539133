import { AlertErrorBoxDialog, CloseIcon, EditOutlinedIcon } from 'element';
import {
  Avatar,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from 'extended-oxygen-elements';
import { useActions, useUpdateStatusEffect } from 'hooks';
import { capitalize } from 'lib/helpers';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLangPreferences } from 'state/Account';
import {
  BOX_PAYMENT_ASSIGNED_USERS_DIALOG_ERRORS_CLOSED,
  BOX_PAYMENT_ASSIGNED_USERS_DIALOG_USERS_CHANGE,
  getErrors,
  getIsLoading,
  getPaymentAssignedUserIds,
  getStatus,
} from 'state/PaymentAssignedUsersDialog';
import { activeUsersSelector } from 'state/UsersCollection';
import EmployeeSelect, { EmployeeSelectProps } from './EmployeeSelect';

type Props = {
  onClose: () => void;
};

export default function ViewAndAssignEmployeesDialogContent({
  onClose,
}: Props) {
  const [usersChangeRequest, errorsClosed] = useActions([
    BOX_PAYMENT_ASSIGNED_USERS_DIALOG_USERS_CHANGE.request,
    BOX_PAYMENT_ASSIGNED_USERS_DIALOG_ERRORS_CLOSED,
  ]);
  const langPreferences = useSelector(getLangPreferences);
  const activeUsers = useSelector(activeUsersSelector);
  const paymentAssignedUserIds = useSelector(getPaymentAssignedUserIds);
  const isLoading = useSelector(getIsLoading);
  const errors = useSelector(getErrors);

  useUpdateStatusEffect(getStatus, {
    success: () => {
      onClose();
    },
  });

  const [isDirty, setIsDirty] = React.useState(false);
  const [userIds, setUserIds] = React.useState<string[]>(
    paymentAssignedUserIds
  );

  const options = activeUsers.map((activeUser) => ({
    label: activeUser.full_name,
    value: activeUser.id,
    avatarSrc: activeUser.avatar.src,
  }));

  const closeDialog = () => {
    onClose();
  };

  const handleEmployeeSelectChange: EmployeeSelectProps['onChange'] = (
    selectedUserIds
  ) => {
    setUserIds(selectedUserIds);
    setIsDirty(true);
  };

  const handleSaveBtnClick = () => {
    usersChangeRequest({
      user_ids: userIds,
    });
  };

  const handleErrorClose = () => {
    errorsClosed();
  };

  const title = `View & assign ${langPreferences.employee.plural}`;

  return (
    <>
      <DialogTitle
        endAdornment={
          <IconButton onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        }
      >
        <Avatar color="primary" light size="large" aria-label={title}>
          <EditOutlinedIcon />
        </Avatar>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
        <Typography color="textSecondary" mb={3}>
          Select from the dropdown new and unassigned{' '}
          {langPreferences.employee.plural}.{' '}
          {capitalize(langPreferences.employee.plural)} removed below, will be
          available in the dropdown again.
        </Typography>
        <AlertErrorBoxDialog
          errors={errors}
          BoxProps={{ mb: 2 }}
          onClose={handleErrorClose}
        />
        <EmployeeSelect
          value={userIds}
          onChange={handleEmployeeSelectChange}
          options={options}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeDialog}
          variant="outlined"
          fullWidth
          size="medium"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          fullWidth
          size="medium"
          disabled={!isDirty}
          loading={isLoading}
          onClick={handleSaveBtnClick}
        >
          Save changes
        </Button>
      </DialogActions>
    </>
  );
}
