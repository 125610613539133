import React, { Component } from 'react';
import { connect } from 'react-redux';
import ErrorBox from 'element/ErrorBox';
import { StoreState } from 'state/types';
import {
  BOX_ROSTER_CLOSE_DELETE_CONFIRMATION_MODAL,
  BOX_ROSTER_DELETE_CLEAR_ERRORS,
  BOX_ROSTER_DELETE_EVENT_BY_ID,
  BOX_ROSTER_DELETE_SHIFT,
} from 'state/Roster/Roster/actions';
import { RosteredShiftsDeletePayload } from 'state/RosteredShifts/types';
import { FormattedErrors, RosteredShift } from 'type/models';
import { StringMap } from '../../../../type';
import { EventDeleteRequest } from 'lib/Api/type';
import { isAppMarket } from '../../../../helpers';
import { getRosteredShifts } from 'state/RosteredShiftsCollection';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';
import { PageDialog, PageDialogCancel, PageDialogSubmit } from 'element';
import { Typography } from 'oxygen-elements';

type OwnProps = {};

type DispatchProps = {
  closeModal: () => void;
  confirmDeletion: (params: RosteredShiftsDeletePayload) => void;
  clearErrors: () => void;
  confirmEventDeletion: (id: EventDeleteRequest) => void;
};

type StateProps = {
  isOpened: boolean;
  id: string;
  isDeleting: boolean;
  errors: FormattedErrors;
  shifts: StringMap<RosteredShift>;
  type: 'event' | 'rostered_shift' | 'timesheet';
};

type Props = OwnProps & DispatchProps & StateProps;

type State = {
  msg: string;
  btnText: string;
  title: string;
  additionalConfirmation: boolean;
};

export class ConfirmDeletionModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getDefaultContent();
  }

  render() {
    const { errors, isDeleting, isOpened, clearErrors, closeModal } =
      this.props;
    return (
      <>
        <PageDialog
          maxWidth={'xs'}
          open={isOpened}
          id={'сonfirm-delete-modal'}
          onClose={closeModal}
        >
          <DialogTitle>{this.state.title}</DialogTitle>
          <DialogContent>
            {errors.length > 0 && (
              <ErrorBox errors={errors} clearErrors={clearErrors} />
            )}
            <Typography variant={'body1'}>{this.state.msg}</Typography>
          </DialogContent>
          <DialogActions>
            <PageDialogCancel onClick={closeModal}>Cancel</PageDialogCancel>
            <PageDialogSubmit
              onClick={this.triggerDeletion}
              loading={isDeleting}
              disabled={isDeleting}
            >
              {this.state.btnText}
            </PageDialogSubmit>
          </DialogActions>
        </PageDialog>
      </>
    );
  }

  triggerDeletion = () => {
    const { shifts, id } = this.props;
    const { additionalConfirmation } = this.state;
    if (
      shifts[id] &&
      shifts[id].timesheet_id !== null &&
      !additionalConfirmation
    ) {
      this.setState({
        ...this.state,
        msg: `If you delete this 
        ${
          isAppMarket('uk') ? '' : 'roster'
        } shift a related timesheet will become self-assigned`,
        btnText: 'Yes, confirm it',
        title: 'Confirm?',
        additionalConfirmation: true,
      });
    } else {
      this.confirmDeletion();
    }
  };

  confirmDeletion = () => {
    const { type } = this.props;
    if (type === 'event') {
      this.props.confirmEventDeletion({
        id: this.props.id,
      });
    } else {
      this.props.confirmDeletion({
        id: this.props.id,
      });
    }
  };

  getDefaultContent = () => {
    const { type } = this.props;
    const label = type === 'event' ? 'event' : 'shift';
    return {
      msg: `You are about to delete 1 ${label}. Once deleted, this ${label} will be permanently removed.`,
      btnText: 'Delete',
      title: `Remove the ${label}?`,
      additionalConfirmation: false,
    };
  };

  setModalContent = () => {
    this.setState({
      ...this.state,
      ...this.getDefaultContent(),
    });
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.isOpened !== this.props.isOpened && this.props.isOpened) {
      this.setModalContent();
    }
  }
}

const mapToStateProps = (state: StoreState): StateProps => ({
  isOpened: state.roster.confirmDeletionModal.isOpened,
  id: state.roster.confirmDeletionModal.id,
  isDeleting: state.roster.confirmDeletionModal.isDeleting,
  errors: state.roster.confirmDeletionModal.errors,
  shifts: getRosteredShifts(state),
  type: state.roster.confirmDeletionModal.type,
});

const mapToDispatchProps: DispatchProps = {
  closeModal: BOX_ROSTER_CLOSE_DELETE_CONFIRMATION_MODAL,
  confirmDeletion: BOX_ROSTER_DELETE_SHIFT,
  clearErrors: BOX_ROSTER_DELETE_CLEAR_ERRORS,
  confirmEventDeletion: BOX_ROSTER_DELETE_EVENT_BY_ID,
};

export default connect(
  mapToStateProps,
  mapToDispatchProps
)(ConfirmDeletionModal);
