import { Dictionary } from 'ts-essentials';
import { RosterFilters, RosterFiltersRoleFilterType } from '../../types';

type Filters = Pick<
  RosterFilters,
  'roleFilterType' | 'areas' | 'roles' | 'roleTriples'
>;

type FlatFilters = Pick<Filters, 'areas' | 'roles'>;
type TreeFilters = Pick<Filters, 'roleTriples'>;

export const makeRoleFilter = <Item>(predicates: {
  flatFilter: (ids: FlatFilters, items: Item[], siteId: string) => Item[];
  treeFilter: (filters: TreeFilters, items: Item[], siteId: string) => Item[];
}) => {
  type FilterFn = (
    filter: FlatFilters & TreeFilters,
    items: Item[],
    siteId: string
  ) => Item[];

  return <OuterItem extends Item>(
    filters: Filters,
    items: OuterItem[],
    siteId: string
  ): OuterItem[] => {
    const config: Dictionary<FilterFn, RosterFiltersRoleFilterType> = {
      flat: predicates.flatFilter,
      tree: predicates.treeFilter,
    };
    const filter = config[filters.roleFilterType];
    return filter(filters, items, siteId) as OuterItem[];
  };
};
