import { AddOutlinedIcon } from 'element';
import { Button, Tooltip } from 'extended-oxygen-elements';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLangPreferences } from 'state/Account';
import DialogAddRole, { useAssignableRoles } from './DialogAddRole';

type Props = {
  area_id: string;
};

export default function AreaBtnAddRole({ area_id }: Props) {
  const langPreferences = useSelector(getLangPreferences);
  const hasAssignableRoles = !!useAssignableRoles(area_id).length;

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const handleAddRoleBtnClick = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Tooltip
        title={
          !hasAssignableRoles && (
            <>
              There are no {langPreferences.role.plural} that can be added to
              this {langPreferences.area.singular}
            </>
          )
        }
      >
        <span>
          <Button
            startIcon={<AddOutlinedIcon />}
            disabled={!hasAssignableRoles}
            onClick={handleAddRoleBtnClick}
          >
            Add {langPreferences.role.singular}
          </Button>
        </span>
      </Tooltip>
      <DialogAddRole
        open={isDialogOpen}
        onClose={handleDialogClose}
        area_id={area_id}
      />
    </>
  );
}
