import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Report, ReportTypesSelector } from 'type';
import { StoreState } from 'state/types';
import { reportTypesSelector } from 'state/Account/selectors';
import ReportModal from '../ReportModal';

type StateProps = {
  isOpened: boolean;
  isLoading: boolean;
  errors: string[];
  report: Report;
  reportTypes: ReportTypesSelector;
};

type Props = StateProps;

class EditReport extends Component<Props> {
  render() {
    return (
      <ReportModal
        title={this.title}
        type={this.props.report.type}
        isEdit={true}
      />
    );
  }

  private get title() {
    return this.props.reportTypes[this.props.report.type].modalTitle;
  }
}

const mapStateToProps = (state: StoreState): StateProps => {
  const {
    reports: {
      isLoading,
      saveAsModal: {
        isOpened,
        errors
      }
    },
    report: { report }
  } = state;

  return {
    isOpened,
    isLoading,
    errors,
    report,
    reportTypes: reportTypesSelector(state)
  };
};

export default connect(
  mapStateToProps
)(EditReport);
