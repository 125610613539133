import React from 'react';
import { useSelector } from 'react-redux';
import { UsersListExpander } from '../UsersListExpander';
import { getSiteId } from 'state/RosteredShifts';
import { PreselectedAreaRole } from '../../types';
import { getUserListResponse } from 'state/UsersCollection';
import {
  PageDialog,
  PageDialogBackIconButton,
  PageDialogCancel,
} from 'element';
import { DialogActions, DialogTitle, Slide } from 'oxygen-elements';
import { DialogContent } from 'extended-oxygen-elements';
import { getRosteredUsersList } from 'state/Roster/RosterShiftModal';
import { TransitionProps } from '@mui/material/transitions';

type Props = {
  isOpened: boolean;
  closeModal: () => void;
  user_id: string | null;
  setUser: (userId: string | null) => void;
  preselectedAreaRole: PreselectedAreaRole;
  isActive: boolean;
  type: 'timesheet' | 'rostered_shift';
  owner: string | null;
};

export const UserModal = ({
  isOpened,
  isActive,
  user_id,
  type,
  owner,
  closeModal,
  preselectedAreaRole,
  setUser,
}: Props) => {
  const usersList = useSelector(getRosteredUsersList);
  const users = useSelector(getUserListResponse);

  return (
    <PageDialog
      id={'users-selector-modal'}
      maxWidth={'sm'}
      open={isOpened}
      className={'rostered-shift-modal'}
    >
      <DialogActions
        className={'shift-modal-header'}
        sx={{ boxShadow: '0 0 6px 2px #ccc', zIndex: 3, padding: 0 }}
      >
        <DialogTitle>
          <PageDialogBackIconButton onClose={closeModal} />
          Users
        </DialogTitle>
      </DialogActions>
      <DialogContent>
        <UsersListExpander
          availableUsers={usersList}
          users={users}
          selectedUser={user_id}
          setUser={setUser}
          preselectedAreaRole={preselectedAreaRole}
          isOpened={isActive}
          type={type}
          owner={owner}
        />
      </DialogContent>
      <DialogActions sx={{ boxShadow: '0 0 6px 2px #ccc', zIndex: 3 }}>
        <PageDialogCancel onClick={closeModal} fullWidth={false} size={'large'}>
          Cancel
        </PageDialogCancel>
      </DialogActions>
    </PageDialog>
  );
};
