import React from 'react';
import LoaderHolder from './LoaderHolder';
import { LoaderHolderProps } from '../type';
import './TextStyle.scss';

function TextStyle(props: LoaderHolderProps) {
  return (
    <LoaderHolder
      className="elmo-loader-text"
      viewBoxHeight={35}
      viewBoxWidth={500}
      {...props}
    >
      <rect x="0" y="10" rx="4" ry="4" width="150" height="25" />
      <rect x="160" y="10" rx="4" ry="4" width="290" height="25" />
    </LoaderHolder>
  );
}

export default TextStyle;
