import React from 'react';
import { Header } from 'element';
import {
  EmployeeDashboardSearchPayload,
  WithEmployeeDashboardMenu,
  WithEmployeeDashboardSearch,
} from '../';
import { WithNotificationsListButton } from 'element/NotificationsList/components/WithNotificationsListButton';
import { withClosingBulkActionsAndSearchBeforeMount } from '../../../../element/withClosingBulkActionsAndSearchBeforeMount';

const EmployeeDashboardHeaderComponent = () => (
  <WithEmployeeDashboardMenu>
    {(menu) => (
      <WithEmployeeDashboardSearch>
        {({
          employeeSearch,
          isSearchOpen,
          searchButton,
        }: EmployeeDashboardSearchPayload) => (
          <WithNotificationsListButton type={'employee'}>
            {({ notificationButton }) => (
              <Header
                title="Employee Dashboard"
                tabs={menu}
                buttons={[/*searchButton, */ notificationButton]}
                isSearchOpen={isSearchOpen}
              >
                {/*{employeeSearch}*/}
              </Header>
            )}
          </WithNotificationsListButton>
        )}
      </WithEmployeeDashboardSearch>
    )}
  </WithEmployeeDashboardMenu>
);

export const EmployeeDashboardHeader = withClosingBulkActionsAndSearchBeforeMount(
  EmployeeDashboardHeaderComponent
);
