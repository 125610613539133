import { RosterBulkActionsReducerState } from './types';

export const getDefaultState = (): RosterBulkActionsReducerState => ({
  bulkDelete: {
    isOpened: false,
    shifts: [],
    notDeleted: [],
    selectionBy: {
      users: {},
      areas: {}
    },
    confirmDeletionModal: {
      isOpened: false,
      isDeleting: false,
      isConfirmation: false,
      title: '',
      errors: []
    }
  }
});