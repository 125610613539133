import { createReducer } from 'lib/store-utils';
import { resetPasswordSuccess } from 'messages';
import { getDefaultState } from './state';
import { ResetReducerState } from './types';
import * as actions from './actions';

export const reset = createReducer<ResetReducerState>({}, getDefaultState());

reset.on(actions.BOX_RESET_PASSWORD_REQUEST, (state) => ({
  ...state,
  is_processing: true,
  errors: []
}));

reset.on(actions.BOX_RESET_PASSWORD_SUCCESS, (state) => ({
  ...state,
  is_processing: false,
  message: resetPasswordSuccess
}));

reset.on(actions.BOX_RESET_PASSWORD_FAILURE, (state, errors) => ({
  ...state,
  is_processing: false,
  errors
}));

reset.on(actions.BOX_RESET_CREATE_PASSWORD_REQUEST, (state) => ({
  ...state,
  is_processing: true,
}));

reset.on(actions.BOX_RESET_CREATE_PASSWORD_SUCCESS, (state) => ({
  ...state,
  is_processing: false,
}));

reset.on(actions.BOX_RESET_CREATE_PASSWORD_FAILURE, (state, errors) => ({
  ...state,
  is_processing: false,
  errors
}));

reset.on(actions.BOX_RESET_PASSWORD_CLEAR_ERRORS, (state) => ({
  ...state,
  errors: []
}));
reset.on(actions.BOX_RESET_PASSWORD_CLEAR_MESSAGE, (state) => ({
  ...state,
  message: ''
}));
