import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Box, useTheme } from 'extended-oxygen-elements';
import {
  BOX_GLOBAL_ALERT_CLOSE,
  getAlertMessage,
  isAlertOpened,
} from 'state/GlobalAlert';

export default GlobalAlert;

export function GlobalAlert() {
  const dispatch = useDispatch();
  const isOpen = useSelector(isAlertOpened);
  const message = useSelector(getAlertMessage);
  const theme = useTheme();

  if (!isOpen) {
    return null;
  }

  const handleClose = () => {
    dispatch(BOX_GLOBAL_ALERT_CLOSE());
  };

  return (
    <Box
      position="fixed"
      top={0}
      right={0}
      left={0}
      zIndex={theme.zIndex.globalOverlay + 1}
    >
      <Alert
        color="warning"
        onClose={handleClose}
        sx={{ borderRadius: 0 }}
        severity="warning"
      >
        {message}
      </Alert>
    </Box>
  );
}
