import * as React from 'react';
import { Redirect, Switch, useLocation } from 'react-router';
import { DefaultRoute, RedirectRoute, Route, StringMap } from 'type';
import PrivateRoute from '../PrivateRoute';
import withCatchErrors from './withCatchErrors';

const isRedirectRoute = (
  route: RedirectRoute | DefaultRoute
): route is RedirectRoute => !!(route as RedirectRoute).redirect;

export const NestedRoutes = withCatchErrors(
  ({ routes }: { routes?: StringMap<Route> }) => {
    if (!routes) {
      return null;
    }

    return (
      <Switch>
        {Object.keys(routes).map((key: string) => {
          const route: Route = routes[key];
          if (isRedirectRoute(route)) {
            return <RedirectRouteComponent {...route} key={key} />;
          } else {
            const {
              path,
              routes: nestedRoutes,
              component: Component = NestedRoutes,
              routeComponent: RouteComponent = PrivateRoute,
              permission,
            } = route;

            return (
              <RouteComponent
                key={key}
                path={typeof path === 'function' ? path() : path}
                permission={permission}
                render={(props: any) => (
                  <Component {...props} routes={nestedRoutes} />
                )}
              />
            );
          }
        })}
      </Switch>
    );
  }
);

function RedirectRouteComponent({
  redirect,
  path,
  exact = true,
  preserveQuery = false,
}: RedirectRoute) {
  const { search } = useLocation();

  return (
    <Redirect
      exact={exact}
      path={path}
      to={{
        pathname: redirect,
        search: preserveQuery ? search : undefined,
      }}
    />
  );
}
