import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Route, StringMap } from 'type';
import { privateRoutes } from 'routes';
import { StoreState } from 'state/types';
import { NestedRoutes } from 'element/routes';
import { getCanViewShiftOffers, getCanViewShiftSwaps } from 'state/Account';
import './style.scss';

type OwnProps = {
  routes: StringMap<Route>;
};

type DispatchProps = {};

type StateProps = {
  canSeeOffers: boolean;
  canSeeSwaps: boolean;
};

type Props = OwnProps & DispatchProps & StateProps & RouteComponentProps;

class ShiftTrades extends Component<Props> {
  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    if (!this.props.canSeeSwaps && !this.props.canSeeOffers) {
      this.props.history.push(privateRoutes.employeeDashboard.path);
    }
  }

  componentDidMount(): void {
    if (!this.props.canSeeSwaps && !this.props.canSeeOffers) {
      this.props.history.push(privateRoutes.employeeDashboard.path);
    }
  }

  render() {
    return <NestedRoutes routes={this.props.routes} />;
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  canSeeOffers: getCanViewShiftOffers(state),
  canSeeSwaps: getCanViewShiftSwaps(state),
});

export default connect(mapStateToProps)(ShiftTrades);
