import React, { Component, ChangeEvent } from 'react';
import { FormItem, Input } from 'elmo-elements';
import {
  ErrorBox,
  PageDialog,
  PageDialogCancel,
  PageDialogSubmit,
} from 'element';
import { StringMap } from 'type';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';

type OwnProps = {
  title: string;
  isOpened: boolean;
  isLoading: boolean;
  onCloseModal: () => void;
  onFormSubmit: (data: any) => void;
  errors: string[];
};
type Props = OwnProps;

type FormData = {
  report_title: string;
};
type State = {
  data: FormData;
  errors: StringMap<string>;
  isValid: boolean;
};
const getState = (props: Props): State => ({
  data: {
    report_title: '',
  },
  errors: {},
  isValid: false,
});

class SaveReportModal extends Component<Props, State> {
  state = getState(this.props);

  render() {
    const { data, errors, isValid } = this.state;

    return (
      <PageDialog
        maxWidth={'xs'}
        open={this.props.isOpened}
        id="save-report-modal"
        onClose={this.onCloseModal}
      >
        <DialogTitle>{this.props.title}</DialogTitle>
        <DialogContent>
          <form>
            <ErrorBox errors={this.props.errors} />

            <FormItem
              label="Report title"
              message={errors.report_title}
              status={errors.report_title ? 'error' : undefined}
            >
              <Input
                name="report_title"
                value={data.report_title}
                onChange={this.onChangeInput}
                onEnterPress={this.onSubmit}
                ariaLabel="Report title"
              />
            </FormItem>
          </form>
        </DialogContent>
        <DialogActions>
          <PageDialogCancel onClick={this.onCloseModal} fullWidth={false}>
            Cancel
          </PageDialogCancel>
          <PageDialogSubmit
            onClick={this.onSubmit}
            loading={this.props.isLoading}
            disabled={!isValid || this.props.isLoading}
            id={'save-report-btn'}
            fullWidth={false}
          >
            Save
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (this.props.isOpened && prevProps.isOpened !== this.props.isOpened) {
      this.setState(getState(this.props));
      if (this.props.isOpened === true) {
        setTimeout(() => {
          const input = document.querySelector('[name="report_title"]');
          if (input !== null) {
            (input as HTMLInputElement).focus();
          }
        }, 1000);
      }
    }
  }

  private onSubmit = (e: any) => {
    e.preventDefault();
    this.props.onFormSubmit(this.state.data.report_title);
  };

  private validate = (
    data: FormData
  ): { isValid: boolean; errors: StringMap<string> } => {
    const errors: StringMap<string> = {};

    if (!data.report_title.length) {
      errors.report_title = "Can't be blank.";
    }

    return {
      isValid: !Object.keys(errors).length,
      errors,
    };
  };

  private onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    this.setState(
      {
        data: {
          ...this.state.data,
          [name]: value,
        },
      },
      () => {
        this.setState({
          ...this.validate(this.state.data),
        });
      }
    );
  };

  private onCloseModal = () => {
    if (!this.props.isLoading) {
      this.props.onCloseModal();
    }
  };
}

export default SaveReportModal;
