import EmployeeDashboard from 'page/EmployeeDashboard';
import Overview from 'page/EmployeeDashboard/page/Overview';
import MyRosters from 'page/EmployeeDashboard/page/MyRosters';
import MyTimesheet from 'page/EmployeeDashboard/page/MyTimesheet';
import ShiftTrades from 'page/EmployeeDashboard/page/ShiftTrades';
import ShiftSwap from 'page/EmployeeDashboard/page/ShiftTrades/page/ShiftSwap';
import ShiftTradesList from 'page/EmployeeDashboard/page/ShiftTrades/page/ShiftTradesList';
import Events from 'page/EmployeeDashboard/page/Events';
import Unavailability from 'page/EmployeeDashboard/page/Unavailability';
import { withHeading } from 'element';

const rootPath = '/app/employee-dashboard';
const defaultPagePath = '/app/employee-dashboard/my-rosters';
export const employeeDashboard = {
  path: rootPath,
  permission: 'employeedashboard' as 'employeedashboard',
  component: EmployeeDashboard,
  channel: 'employee_dashboard',
  routes: {
    events: {
      path: '/app/employee-dashboard/events',
      component: withHeading(Events, {
        title: 'Employee dashboard | Events'
      }),
      permission: 'employeedashboard.viewevents' as 'employeedashboard.viewevents',
    },
    rosters: {
      path: defaultPagePath,
      component: withHeading(MyRosters, {
        title: 'Employee dashboard | My Rosters'
      }),
    },
    trades: {
      permission: 'employeedashboard.viewshifttrades' as 'employeedashboard.viewshifttrades',
      path: '/app/employee-dashboard/shift-trades',
      component: ShiftTrades,
      routes: {
        shiftSwap: {
          path: (shiftTradeId = ':shiftTradeId') =>
            `/app/employee-dashboard/shift-trades/${shiftTradeId}/swap`,
          component: withHeading(ShiftSwap, {
            title: 'Employee dashboard | Shift Swap'
          })
        },
        shiftTrades: {
          path: '/app/employee-dashboard/shift-trades',
          component: withHeading(ShiftTradesList, {
            title: 'Employee dashboard | Shift Trades'
          })
        }
      }
    },
    unavailability: {
      path: '/app/employee-dashboard/availability',
      component: withHeading(Unavailability, {
        title: 'Employee dashboard | Availability'
      }),
    },
    timesheets: {
      path: '/app/employee-dashboard/my-timesheet',
      component: withHeading(MyTimesheet, {
        title: 'Employee dashboard | My Timesheet'
      }),
      permission: 'employeedashboard.viewowntimesheet' as 'employeedashboard.viewowntimesheet'
    },
    overview: {
      path: '/app/employee-dashboard/overview',
      component: withHeading(Overview, {
        title: 'Employee dashboard | Overview'
      })
    },
    fallBack: {
      path: rootPath,
      redirect: defaultPagePath,
      exact: false
    }
  }
};
