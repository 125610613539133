import { DialogContent, TextField } from 'extended-oxygen-elements';
import React, { useEffect, useState } from 'react';
import { Button, FormContainer, FormItem, Select } from 'elmo-elements';
import { useDispatch, useSelector } from 'react-redux';
import {
  awardsListAsOptions,
  BOX_USER_PROFILE_AWARDS_MODALS_CLEAR_ERRORS,
  BOX_USER_PROFILE_AWARDS_SAVE,
  BOX_USER_PROFILE_AWARDS_UPDATE_AWARD,
  BOX_USER_PROFILE_CLOSE_AWARDS_MODAL,
  getUserId,
  getUsersAwardData,
  getUsersAwardsState,
} from 'state/Users/UserProfile';
import { SERVER_DAY_FORMAT } from 'lib/config';
import {
  ErrorBox,
  PageDialog,
  PageDialogCancel,
  PageDialogSubmit,
} from 'element';
import { PickersDay, StaticDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { DialogActions, DialogTitle } from 'extended-oxygen-elements';

export const AwardModal = () => {
  const awardsState = useSelector(getUsersAwardsState);
  const options = useSelector(awardsListAsOptions);
  const payload = useSelector(getUsersAwardData);
  const userId = useSelector(getUserId);
  const dispatch = useDispatch();
  const { modal } = awardsState;

  const [selectedAwardId, changeAwardId] = useState(payload.awardId);
  const [selectedDate, changeDate] = useState(payload.effectiveDate);

  useEffect(() => {
    changeAwardId(payload.awardId);
    changeDate(payload.effectiveDate);
  }, [modal.isOpened, payload.awardId]);

  const onCloseModal = () => {
    dispatch(BOX_USER_PROFILE_CLOSE_AWARDS_MODAL());
  };

  const clearAwardModalErrors = () => {
    dispatch(BOX_USER_PROFILE_AWARDS_MODALS_CLEAR_ERRORS());
  };

  const submitModal = () => {
    const payload = {
      user_id: userId,
      award_id:
        selectedAwardId === 'null' || !selectedAwardId ? null : selectedAwardId,
      effective_date: selectedDate.format(SERVER_DAY_FORMAT),
      classification: null,
    };

    if (modal.payload.type === 'edit') {
      dispatch(
        BOX_USER_PROFILE_AWARDS_UPDATE_AWARD({
          ...payload,
          id: modal.payload.id,
        })
      );
    } else {
      dispatch(BOX_USER_PROFILE_AWARDS_SAVE(payload));
    }
  };

  const selectedValue = () => {
    let selected = {
      value: selectedAwardId,
      label: 'Default',
    };
    const value = options.filter((option) => option.value === payload.awardId);
    return value.length ? value[0] : selected;
  };

  const saveButton = () => {
    const {
      modal: { isSaving },
    } = awardsState;
    return <Button type={'primary'}>Save</Button>;
  };

  return (
    <PageDialog
      maxWidth={'xs'}
      open={modal.isOpened}
      id={'award-modal'}
      onClose={onCloseModal}
    >
      <DialogTitle>
        {payload.type === 'create' ? 'Assign new award' : 'Edit award'}
      </DialogTitle>
      <DialogContent>
        <ErrorBox
          errors={modal.errors}
          dontWatchGlobalErrors={true}
          clearErrors={clearAwardModalErrors}
        />
        <FormContainer>
          <FormItem label={'Award'}>
            <Select
              options={options}
              defaultValue={selectedValue()}
              onChange={(option) => changeAwardId(option.value)}
            />
          </FormItem>
          <FormItem label={'Effective date'}>
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              value={selectedDate}
              onChange={(date) => {
                if (date?.isValid()) {
                  changeDate(date);
                }
              }}
              renderInput={(params) => <TextField {...params} />}
              renderDay={(day, selectedDays, pickersDayProps) => {
                const formattedDay = moment(pickersDayProps.day).format(
                  'MMM D, YYYY'
                );
                return (
                  <PickersDay {...pickersDayProps} aria-label={formattedDay} />
                );
              }}
            />
          </FormItem>
        </FormContainer>
      </DialogContent>
      <DialogActions>
        <PageDialogCancel onClick={onCloseModal} fullWidth={false}>
          Cancel
        </PageDialogCancel>
        <PageDialogSubmit
          onClick={submitModal}
          loading={awardsState.modal.isSaving}
          disabled={awardsState.modal.isSaving}
          fullWidth={false}
        >
          Save
        </PageDialogSubmit>
      </DialogActions>
    </PageDialog>
  );
};
