import * as React from 'react';
import moment, { Moment } from 'moment';
import { Paragraph } from 'elmo-elements';

type Props = {
  date: Moment
};

type State = {
  date: Moment,
  time: number,
  isStart: boolean;
}

export class Timer extends React.Component <Props, State> {

  interval: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      date: props.date,
      isStart: (props.date.diff(moment()) / 1000) <= 86400,
      time: props.date.diff(moment()) / 1000
    }

    if (this.state.isStart) {
      this.start();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.date !== this.props.date) {
      this.setState({
        date: this.props.date,
        time: this.props.date.diff(moment()) / 1000,
      })
    }
  }

  componentWillUnmount(): void {
    clearInterval(this.interval);
  }

  renderTimer() {
    return (
      <>
        <Paragraph className="mt-2" size="sm">Starts in {this.renderTime()}</Paragraph>
      </>
     )
  }

  render() {
    return <>{this.state.isStart ? this.renderTimer() : <></>}</>
  }

  private start = () => {
    this.interval = setInterval(() => {
      this.setState({
        time: this.state.time - 1
      })
    }, 1000);
  }

  private renderTime = () => {
    const { time } = this.state;
    const momentTime = moment.utc(time * 1000);

    return `${momentTime.format('HH')}h ${momentTime.format('mm')}m`;
  }

}

export default Timer;
