import React, { Component } from 'react';
import { SingleSelectProps, MultiSelectProps, SingleClearableSelectProps } from './types';
import { SingleSelect, MultiSelect } from './components';

type Props = SingleClearableSelectProps | SingleSelectProps | MultiSelectProps;

export class Select extends Component<Props> {
  render() {
    if (this.props.isMulti) {
      return <MultiSelect {...this.props} />;
    } else {
      return <SingleSelect {...this.props} />;
    }
  }
}
