import React, { Component } from 'react';
import { Button, Row, Col, Divider } from 'elmo-elements';
import { DeleteOutlinedIcon, MoreHorizOutlinedIcon } from 'element';
import ContextualMenu from '../../../../../../components/ContextualMenu';
import { EditModalPayload } from '../../../../../../../../state/Roster/EditTemplate/types';
import { ShiftTemplateItem } from '../../../../../../../../type';

export type Props = {
  shift: EditModalPayload;
  onDeleteShiftClick: () => void;
  isDeleting: boolean;
};

export class ModalActions extends Component<Props> {
  render() {
    const { shift, isDeleting, onDeleteShiftClick } = this.props;
    return (
      <div
        className={'mb-4 rostered-shift-modal__actions'}
        style={{ marginBottom: '15px' }}
      >
        <Row role="presentation">
          <Col isAuto={true} className={'text-right'} role="presentation">
            {this.isAvailableAction() && (
              <Button
                id={'delete-tab-btn'}
                isText={true}
                icon={<DeleteOutlinedIcon />}
                onClick={onDeleteShiftClick}
                isDisabled={isDeleting || shift.id === null || shift.id === ''}
                className={'rostered-shift-modal__actions--delete'}
                ariaLabel="Remove"
                tabIndex={0}
              />
            )}
          </Col>
          {
            <div className={'mode-options'}>
              <ContextualMenu
                asButton={true}
                objectId={shift.id}
                elementId={`card-${shift.id}`}
                currentCellData={shift as ShiftTemplateItem}
                type={'edit'}
                shiftType={'shift_item'}
                icon={<MoreHorizOutlinedIcon />}
              />
            </div>
          }
        </Row>
        <Divider />
      </div>
    );
  }

  isAvailableAction = () => {
    const {
      shift: { id },
    } = this.props;
    return id !== null && id !== '';
  };
}
