import { Duration } from 'lib/helpers';
import React from 'react';
import { Merge } from 'ts-essentials';
import { Hours } from 'type';
import DurationField, { DurationFieldProps } from './DurationField';

export type DurationHoursFieldProps = Merge<
  DurationFieldProps,
  { value: Hours | null; onValueChange: (value: Hours | null) => void }
>;

export default DurationHoursField;

export function DurationHoursField(props: DurationHoursFieldProps) {
  const { value, onValueChange, ...durationFieldProps } = props;
  const durationFieldValue =
    value === null ? null : new Duration({ hours: value }).asMinutes;

  const handleValueChange: DurationFieldProps['onValueChange'] = (minutes) => {
    if (minutes === null) {
      onValueChange(null);
      return;
    }

    onValueChange(new Duration({ minutes }).hours);
  };

  return (
    <DurationField
      {...durationFieldProps}
      value={durationFieldValue}
      onValueChange={handleValueChange}
    />
  );
}
