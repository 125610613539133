import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { PickersDay, StaticDatePicker } from '@mui/x-date-pickers';
import clsx from 'clsx';
import { ChevronLeftIcon, ChevronRightIcon } from 'element';
import { Button } from 'elmo-elements';
import { Popover, TextField, Theme } from 'extended-oxygen-elements';
import { bindPopover, bindTrigger, usePopupState } from 'hooks';
import { SHORTENED_DAY_FORMAT } from 'lib/config';
import moment, { Moment } from 'moment';
import React from 'react';
import './style.scss';

type OwmProps = {
  onNextClicked: () => void;
  onPrevClicked: () => void;
  selectedDate: Moment;
  navigateTo: (e: Moment) => void;
  dateRange: {
    from: Moment;
    to?: Moment;
  };
  className?: string;
};

const styles = () =>
  createStyles({
    root: {},
    container: {},
    button: {},
    dateRange: {},
    dateRangeHeader: {},
    popoverTarget: {
      height: '42px!important',
    },
  });

type Props = OwmProps & WithStyles<typeof styles>;

export function HeaderNavigationComponent(props: Props) {
  const {
    onNextClicked,
    onPrevClicked,
    selectedDate,
    navigateTo,
    className,
    classes,
    dateRange: { from, to },
  } = props;
  const popupState = usePopupState();

  const handleDateRangeValueChange = (selectedDay: Moment | null) => {
    if (selectedDay?.isValid()) {
      popupState.close();
      navigateTo(selectedDay);
    }
  };

  return (
    <div className={clsx('header-navigation', classes.root, className)}>
      <div className={clsx('header-navigation__container', classes.container)}>
        <Button
          id="header-navigation-left"
          className={clsx(
            'header-navigation__left',
            'roster-header-nav-prev',
            classes.button
          )}
          onClick={onPrevClicked}
        >
          <ChevronLeftIcon />
        </Button>
        <div
          id="header-date-range"
          className={clsx('header-navigation__date-range', classes.dateRange)}
          {...bindTrigger(popupState)}
        >
          <h1
            className={clsx(
              'header-navigation__range-content',
              classes.dateRangeHeader
            )}
          >
            {from.format(SHORTENED_DAY_FORMAT)}
            {!!to && ` - ${to.format(SHORTENED_DAY_FORMAT)}`}
          </h1>
          <div className="header-navigation__range-icon">
            <ChevronRightIcon />
          </div>
        </div>
        <Button
          id="header-navigation-right"
          className={clsx(
            'header-navigation__right',
            'roster-header-nav-next',
            classes.button
          )}
          onClick={onNextClicked}
        >
          <ChevronRightIcon />
        </Button>
      </div>

      <Popover {...bindPopover(popupState)} data-testid="date-picker">
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          value={selectedDate}
          onAccept={handleDateRangeValueChange}
          onChange={(e) => true}
          renderInput={(params) => <TextField {...params} />}
          renderDay={(day, selectedDays, pickersDayProps) => {
            const formattedDay = moment(pickersDayProps.day).format(
              'MMM D, YYYY'
            );
            return (
              <PickersDay {...pickersDayProps} aria-label={formattedDay} />
            );
          }}
        />
      </Popover>
    </div>
  );
}

export const HeaderNavigation = withStyles(styles)(HeaderNavigationComponent);
