import { RosteredShift } from '../../../../../../../../../type';
import { getDateTimeFormatted, getTimeFormatted } from 'lib/helpers';
import { useSelector } from 'react-redux';
import {
  getDateFormat,
  getTimeFormat,
} from '../../../../../../../../../state/Account';

export default function useRosteredShiftOptions(
  rosteredShifts: RosteredShift[]
) {
  const defaultOption = {
    label: 'Available shifts',
    value: '',
  };
  const dateFormat = useSelector(getDateFormat);
  const timeFormat = useSelector(getTimeFormat);
  const getLabel = (shift: RosteredShift) => {
    const { start, end } = shift;
    const shiftStartDate = getDateTimeFormatted(
      dateFormat,
      timeFormat,
      start,
      true
    );
    const shiftEndTime = getTimeFormatted(timeFormat, end);
    return `${shiftStartDate} - ${shiftEndTime}`;
  };

  return [
    defaultOption,
    ...rosteredShifts.map((shift) => {
      return {
        label: getLabel(shift),
        value: shift.id,
      };
    }),
  ];
}
