import marketLabels from 'marketLabels';
import { capitalize } from 'lib/helpers';

import AuditTrail from 'page/Settings/page/AuditTrail';
import Events from 'page/Settings/page/Events';
import Menu from 'page/Settings/page/Menu';
import Notifications from 'page/Settings/page/Notifications/page/List';
import Notification from 'page/Settings/page/Notifications/page/Notification';
import TimeClockSettingsPage from 'page/Settings/page/TimeClockSettings';
import RosterSettings from 'page/Settings/page/RosterSettings';
import TimesheetSettings from 'page/Settings/page/TimesheetSettings';
import UserGroups from 'page/Settings/page/UserGroups';
import GeoFencing from 'page/Settings/page/GeoFencing';
import PayElements from 'page/Settings/page/Pay/pages/PayElements';
import PaymentConditions from '../../page/Settings/page/Pay/pages/PaymentConditions';
import AccountTree from '../../page/Settings/page/AccountTree';

import { withHeading } from 'element';
import AccountPreferences from 'page/Settings/page/AccountPreferences';

export const settings = {
  path: '/app/settings',
  permission: 'account.settings' as 'account.settings',
  routes: {
    rosterSettings: {
      path: `/app/settings/${marketLabels.roster}-settings`,
      component: withHeading(RosterSettings, {
        title: `Settings | ${capitalize(marketLabels.roster)} Settings`,
      }),
    },
    timesheet: {
      path: '/app/settings/timesheet',
      component: withHeading(TimesheetSettings, {
        title: 'Settings | Timesheet Settings',
      }),
    },
    punchclockSettings: {
      path: '/app/settings/timeclock-settings',
      component: withHeading(TimeClockSettingsPage, {
        title: 'Settings | Time Clock Settings',
      }),
    },
    events: {
      path: '/app/settings/events',
      component: withHeading(Events, {
        title: 'Settings | Events',
      }),
    },
    userGroups: {
      path: '/app/settings/user-groups',
      permission:
        'account.settings.usergroups' as 'account.settings.usergroups',
      component: withHeading(UserGroups, {
        title: 'Settings | Permissions',
      }),
    },
    notifications: {
      path: '/app/settings/notifications',
      permission:
        'account.settings.notifications' as 'account.settings.notifications',
      routes: {
        notification: {
          path: (
            notificationId: string = ':notificationId',
            groupId: string = ':groupId'
          ) => {
            return `/app/settings/notifications/${groupId}/${notificationId}`;
          },
          component: withHeading(Notification, {
            title: 'Settings | Notification details',
          }),
        },
        notifications: {
          path: '/app/settings/notifications',
          component: withHeading(Notifications, {
            title: 'Settings | Notifications list',
          }),
        },
      },
    },
    auditTrail: {
      path: '/app/settings/auditTrail',
      component: withHeading(AuditTrail, {
        title: 'Settings | Audit Trail',
      }),
      permission:
        'account.settings.audittrail' as 'account.settings.audittrail',
    },
    geoFencing: {
      path: '/app/settings/geofencing',
      component: withHeading(GeoFencing, {
        title: 'Settings | Geo Fencing',
      }),
    },
    pay: {
      path: '/app/settings/pay',
      routes: {
        paymentConditions: {
          path: '/app/settings/pay/payment-conditions',
          component: withHeading(PaymentConditions, {
            title: 'Settings | Payment Conditions',
          }),
        },
        payElements: {
          path: '/app/settings/pay',
          component: withHeading(PayElements, {
            title: 'Settings | Pay Elements',
          }),
          exact: false,
        },
      },
    },
    accountTree: {
      path: '/app/settings/account-tree',
      component: withHeading(AccountTree, {
        title: 'Settings | Account Tree',
      }),
    },
    accountPreferences: {
      path: '/app/settings/account-preferences',
      component: withHeading(AccountPreferences, {
        title: 'Settings | Account Preferences',
      }),
    },
    menu: {
      path: '/app/settings',
      component: withHeading(Menu, {
        title: 'Settings',
      }),
      exact: false,
    },
  },
};
