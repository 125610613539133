import { createAction } from 'lib/store-utils';
import { Timesheet } from 'type';
import {
  GetTimesheetsListSuccessPayload,
  TimesheetDeletePayload,
} from './types';

export const BOX_TIMESHEET_GET_LIST_SUCCESS =
  createAction<GetTimesheetsListSuccessPayload>(
    'Timesheet / Timesheet get list success'
  );
export const BOX_TIMESHEET_UPDATE_ONE = createAction<Timesheet>(
  'Timesheet / update shift'
);
export const BOX_TIMESHEET_DELETE = createAction<TimesheetDeletePayload>(
  'Timesheet / delete timesheet'
);
