import { useConnectActionMemoized, useTimesheetsNewQuery } from 'hooks';
import { BOX_SOCKETS_TIMESHEETS_WEEKLY } from 'state/Sockets';
import React from 'react';

export function SocketConnection() {
  const {
    query: { day, site_id },
  } = useTimesheetsNewQuery();

  useConnectActionMemoized(BOX_SOCKETS_TIMESHEETS_WEEKLY, () => ({ site_id, day }), [day, site_id]);

  return null;
}
