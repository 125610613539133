import React, { useState } from 'react';

const props = {
  strokeColor: "#FF0000",
  strokeOpacity: 0.8,
  strokeWeight: 1,
  fillColor: "#FF0000",
  fillOpacity: 0.1,
};

interface MapProps extends google.maps.MapOptions {
  style?: React.CSSProperties;
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  children?: React.ReactNode;
  fencing?: Partial<google.maps.CircleOptions>;
}

const MapComponent = ({
  onClick,
  onIdle,
  children,
  style,
  fencing,
  ...options
}: MapProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();
  const [circle, setCircle] = useState<google.maps.Circle>();

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new google.maps.Map(ref.current));
    }
  }, [ref]);

  React.useEffect(() => {
    if (map) {
      map.setOptions(options);
    }
    return () => {
      if (circle) {
        circle.setMap(null);
      }
    };
  }, [map, options, fencing, circle]);

  React.useEffect(() => {
    if (!circle) {
      setCircle(new google.maps.Circle());
    }
    return () => {
      if (circle) {
        circle.setMap(null);
      }
    };
  }, [circle]);

  React.useEffect(() => {
    if (circle) {
      circle.setOptions({
        ...props,
        ...fencing,
        map
      });
    }
  }, [circle, fencing]);

  React.useEffect(() => {
    if (map) {
      ['click', 'idle'].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener('click', onClick);
      }

      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div ref={ref} style={style} />

      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

export const MapWithFencing = ({ style: propsStyle, ...restProps }: MapProps) => {
  const style: React.CSSProperties = {
    // defaults:
    height: 300,
    ...propsStyle,
  };

  const props: MapProps = {
    // defaults:
    zoom: 4,
    ...restProps,
    style,
  };

  return <MapComponent {...props} />
};
