import _ from 'lodash';
import { BOX_ROSTER_BULK_REMOVE_SHIFT_IDS_BY_SOCKET_EVENT } from 'state/Roster/BulkActions';
import { BOX_ROSTER_CONTEXTUAL_MENU_CLOSE_WHEN_DELETED } from 'state/Roster/ContextualMenu/actions';
import { BOX_ROSTERED_SHIFTS_DELETE_SHIFTS } from 'state/RosteredShifts/actions';
import { Dictionary } from 'ts-essentials';
import { Listener } from './types';
import makeDelayedHandler from './utils/makeDelayedHandler';

type DeletedPayload = { id: string };

export const rosteredShiftDeleted: Listener = (channel, emit) => {
  const handler = (collection: Dictionary<DeletedPayload>) => {
    const ids = _.map(collection, ({ id }) => id);

    emit(BOX_ROSTERED_SHIFTS_DELETE_SHIFTS(ids));
    emit(BOX_ROSTER_BULK_REMOVE_SHIFT_IDS_BY_SOCKET_EVENT(ids));
    emit(BOX_ROSTER_CONTEXTUAL_MENU_CLOSE_WHEN_DELETED(ids));
  };

  channel.onRosteredShiftDeleted(
    makeDelayedHandler(
      {
        handler,
      },
      {
        collectionLength: 20,
      }
    )
  );
};
