import Api from 'lib/Api';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { formatError } from 'state/helpers';
import * as actions from './actions';
import { processApiRequest } from 'state/ProcessApiRequest';
import { ApiReturnType } from 'type';

const getPageData = function* (): SagaIterator {
  try {
    const payElements: ApiReturnType<typeof Api.PayElements.getAll> =
      yield call(processApiRequest, Api.PayElements.getAll);

    yield put(actions.BOX_PAY_CONDITIONS_PAGE_DATA_GET.success({ payElements }));
  } catch (error) {
    yield put(actions.BOX_PAY_CONDITIONS_PAGE_DATA_GET.failure(formatError(error)));
  }
};

export const watchPayConditionsPage = function* (): SagaIterator {
  yield takeLatest(actions.BOX_PAY_CONDITIONS_PAGE_DATA_GET.request, getPageData);
};
