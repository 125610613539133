import { map } from 'lodash';
import moment, { Moment } from 'moment';
import {
  DefaultBreakItem,
  Env,
  FormattedErrors,
  HrService,
  Market,
  SelectPropsOption,
  ServerUserCurrent,
  ShiftBreak,
  StringMap,
} from 'type';
import { defaultRules } from '../state/helpers';
import qs from 'qs';

export const assertUnreachable = (x: never): never => {
  throw new Error(x);
};

export const isSameOrIncludedCreator =
  <V extends string>(comparableValue: any) =>
  (valueOrValues: V | V[], negate: boolean = false): boolean => {
    const isSameOrIncluded = Array.isArray(valueOrValues)
      ? valueOrValues.includes(comparableValue)
      : comparableValue === valueOrValues;

    return negate ? !isSameOrIncluded : isSameOrIncluded;
  };

export const isAppMarket = isSameOrIncludedCreator<Market>(
  process.env.REACT_APP_MARKET
);
export const isEnv = isSameOrIncludedCreator<Env>(process.env.REACT_APP_ENV);

// TODO remove this helper as it is no longer required in this branch
export const isHrService = (serviceName: HrService | HrService[]): boolean => {
  if (Array.isArray(serviceName) && process.env.REACT_APP_HR_SERVICE) {
    return serviceName.includes(process.env.REACT_APP_HR_SERVICE as HrService);
  }
  return process.env.REACT_APP_HR_SERVICE === serviceName;
};

export const createSelectPropsOptions = <T extends { label: string }>(
  collection: StringMap<T>
): SelectPropsOption[] =>
  map(collection, (item, value) => ({
    ...item,
    value,
  }));

export type IntercomSettings = {
  hide_default_launcher: boolean;
  app_id: string | undefined;
  name: string;
  email: string;
  user_id: string | null;
  created_at: number;
  user_hash?: string;
};

export const triggerIntercom = (user: ServerUserCurrent | null) => {
  const userHasPermission = (user: ServerUserCurrent | null) => {
    return (
      user && user.permissions && user.permissions.includes('managerdashboard')
    );
  };
  if (
    user &&
    typeof (window as any).Intercom === 'function' &&
    userHasPermission(user)
  ) {
    let settings: IntercomSettings = {
      hide_default_launcher: false,
      app_id: process.env.REACT_APP_INTERCOM_ID,
      name: user.prefered_name
        ? user.prefered_name
        : `${user.first_name} ${user.last_name}`,
      email: user.email,
      user_id: user.external_id,
      created_at: +moment(user.created_at).format('X'),
    };
    if (user.intercom_user_hash) {
      settings.user_hash = user.intercom_user_hash;
    }
    (window as any).Intercom('boot', settings);
  }
};

export const triggerTour = () => {
  const parsedSearch = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  if (parsedSearch.product_tour_id && (window as any).Intercom) {
    (window as any).Intercom('startTour', parsedSearch.product_tour_id);
  }
};

export const getRosteringPayload = (start: Moment, end: Moment) => {
  return {
    categoryName: 'Rostering',
    shiftDate: start.toDate().toISOString(),
    shiftLength: end.clone().diff(start, 'minutes'),
  };
};

export type editedBreaksPayload = {
  defaultBreakType: string[];
  updatedDefaultBreakValue: string[];
};

export const isSiteView = () =>
  window.location.href.indexOf('site-view') !== -1;

export const getConfirmationMsgParams = (
  error: FormattedErrors,
  name: string
) => {
  const confirmationWithoutTitle = error[0].replace(name + ': ', '');
  return {
    errors: confirmationWithoutTitle.split('\n'),
    title: name,
  };
};

export const disableDatesBeforeCurrent = (currentValue?: Date): boolean => {
  if (currentValue) {
    return moment(currentValue).startOf('day') < moment().startOf('day');
  }
  return false;
};
