import React from 'react';
import clsx from 'clsx';
import { ShiftCardButtonDelete } from '../../views';
import useShiftCardEventStyles from './useShiftCardEventStyles';
import { createStyles, makeStyles } from '@mui/styles';
import { shadow100 } from '../../constants';

type ShiftCardButtonDeleteProps = React.ComponentProps<
  typeof ShiftCardButtonDelete
>;
type ShiftCardEventBtnDeleteProps = ShiftCardButtonDeleteProps;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: 'transparent',
      border: 'none',
      padding: 0,
      width: 20,
      height: 20,
      display: 'inline-block',
      position: 'absolute',
      right: '0',
      opacity: 0,
      visibility: 'hidden',
      top: 0,
      '&:hover': {
        background: '#fff',
        boxShadow: shadow100,
        borderRadius: 2,
        cursor: 'pointer',
      },
    },
  })
);

export function ShiftCardEventBtnDelete({
  className,
  ...restProps
}: ShiftCardEventBtnDeleteProps) {
  const eventCardClasses = useShiftCardEventStyles();
  const styles = useStyles();
  return (
    <ShiftCardButtonDelete
      {...restProps}
      className={clsx(eventCardClasses.btnDelete, styles.root)}
    />
  );
}
