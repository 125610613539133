import { breaksFieldGroupUtils, dateRangeFieldGroupUtils } from 'element';
import { EditDialogFormFields } from './createFormFields';

export default makePayload;

export function makePayload({
  role: { role_id, area_id },
  notes,
  breaks,
  rostered_shift_id,
  ...dateRangeFields
}: EditDialogFormFields) {
  if (!dateRangeFieldGroupUtils.isValidDateRangeGuard(dateRangeFields)) {
    return;
  }

  const dateRange = dateRangeFieldGroupUtils.getDateRange(dateRangeFields);
  const { start, end } = dateRange;

  return {
    role_id,
    area_id,
    rostered_shift_id,
    start,
    end,
    notes,
    breaks: breaksFieldGroupUtils.getValidBreaks(breaks, dateRange),
  };
}
