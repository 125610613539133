import React, { FocusEvent, ReactElement } from 'react';
import {
  closestParentById,
  debounce,
  getClass,
  isElementOfType,
} from '../../helper';
import PopoverTarget from './component/PopoverTarget';
import PopoverContent from './component/PopoverContent';
import { Manager, Popper, Reference } from 'react-popper';
import { PopoverBaseProps } from './type';
import ReactDOM from 'react-dom';
import './PopoverBase.scss';
import { POPOVER_CONTAINER_ELEMENT_ID, PopoverEvents } from './constants';
import { PreventOverflowModifier } from '@popperjs/core/lib/modifiers/preventOverflow';

type PopoverBaseState = {
  isOpen: boolean;
};

const PopoverDefaultProps = {
  isInline: false,
  tabIndex: 0,
  isSupportedFocusAndBlur: false,
};

export class PopoverBase extends React.Component<
  PopoverBaseProps,
  PopoverBaseState
> {
  static Content = PopoverContent;
  static Target = PopoverTarget;

  /** The container element for this component */
  protected container: any;

  /**
   * The element for the Popover Content. Used to check whether the user clicked outside of this
   * and close the popover, as the Popover Content can be attached to the <body>
   */
  protected contentNode: any;

  readonly state = {
    isOpen: false,
  };

  static defaultProps = PopoverDefaultProps;
  protected handleScrollListener: any;

  get isControlled(): boolean {
    return this.props.isOpen !== undefined;
  }

  get isOpen(): boolean {
    if (this.isControlled) {
      return this.props.isOpen!;
    }

    return this.state.isOpen;
  }

  setIsOpen(isOpen: boolean) {
    // if the open state is controlled by the parent component, call setIsOpen
    if (this.isControlled) {
      const { setIsOpen } = this.props;

      if (setIsOpen) {
        setIsOpen(isOpen);
      }
    } else {
      // otherwise set the internal state
      this.setState({
        isOpen,
      });
    }
  }

  componentDidMount() {
    const { mode } = this.props;

    // find the DOM node
    this.createPopoverContainer();

    if (mode === 'click') {
      this.addUserEvents();
    }
  }

  componentWillUnmount() {
    const { mode } = this.props;
    if (mode === 'click') {
      this.removeUserEvents();
    }
  }

  createPopoverContainer = () => {
    if (this.hasContainerElement()) {
      return;
    }

    this.createContainerElement();
  };

  hasContainerElement = () => {
    return this.getDocument().getElementById(POPOVER_CONTAINER_ELEMENT_ID);
  };

  /**
   * Create the PopoverContainer as the first element in the <body>
   */
  createContainerElement = () => {
    const popoverContainer = this.getDocument().createElement('div');
    popoverContainer.id = POPOVER_CONTAINER_ELEMENT_ID;
    popoverContainer.setAttribute('class', 'elmo-elements');
    this.getDocument().body.insertBefore(
      popoverContainer,
      this.getDocument().body.firstChild
    );
  };

  addUserEvents = () => {
    const { isInline } = this.props;

    // This is not required for "hover" mode as the popover will close itself once the
    // user moves the mouse out of the Popover
    PopoverEvents.forEach((eventName: string) => {
      this.getDocument().addEventListener(
        eventName,
        this.handleActionOutsideComponent
      );
    });

    if (!isInline) {
      // This is not required for "hover" mode. The popover will close itself once
      // the page is scrolled, because when the page is scrolled, the mouse is moved outside of the Popover.
      this.handleScrollListener = debounce(this.handleScroll, 100);
      // if the popover is attached to the html body element, then close it if scrolled.
      this.getWindow().addEventListener(
        'scroll',
        this.handleScrollListener,
        true
      );
    }
  };

  /**
   * Removes interaction events
   */
  removeUserEvents = () => {
    const { isInline } = this.props;

    PopoverEvents.forEach((eventName: string) => {
      this.getDocument().removeEventListener(
        eventName,
        this.handleActionOutsideComponent
      );
    });

    if (!isInline) {
      this.getWindow().removeEventListener(
        'scroll',
        this.handleScrollListener,
        true
      );
    }
  };

  handleScroll = (e: any) => {
    if (
      (!this.isOpen && this.isControlled) ||
      closestParentById(e.target as Element, 'PopoverContainer')
    ) {
      return;
    }

    this.setIsOpen(false);
  };

  /**
   * Close the popup when the user clicks/touches outside the component
   */
  handleActionOutsideComponent = (e: Event) => {
    if (this.isOpen && this.isActionOutsideComponent(e)) {
      this.setIsOpen(false);
    }
  };

  isActionInside = (container: any, element: any): boolean =>
    !!(container && container.contains && container.contains(element));

  /**
   * Returns true if an action occurred outside the component.
   * Used to close the popover if the user clicks outside the component.
   * @param e
   */
  isActionOutsideComponent = (e: Event) => {
    const contentNode = this.contentNode;
    const actionInsideContentNode = this.isActionInside(contentNode, e.target);

    const container = this.getContainer();
    const actionInsideContainer = this.isActionInside(container, e.target);

    const actionInsideComponent =
      actionInsideContainer || actionInsideContentNode;

    return !actionInsideComponent;
  };

  /**
   * Returns the component's container DOM element
   */
  getContainer() {
    if (this.container) {
      return this.container;
    }
    this.container = ReactDOM.findDOMNode(this);
    return ReactDOM.findDOMNode(this);
  }

  getSubComponents() {
    const { children } = this.props;

    let subComponents: any = {};

    React.Children.forEach(children, (child) => {
      if (!React.isValidElement(child)) {
        return;
      }

      if (isElementOfType(child, PopoverTarget)) {
        subComponents.PopperTarget = child;
      } else if (isElementOfType(child, PopoverContent)) {
        subComponents.PopperContent = child;
      }
    });

    return subComponents;
  }

  toggle = () => {
    if (this.isControlled) {
      return;
    }

    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  };

  open = () => {
    if (this.isControlled) {
      return;
    }

    this.setState({ isOpen: true });
  };

  onMouseLeave = () => {
    if (this.isControlled) {
      return;
    }

    this.setState({ isOpen: false });
  };

  onBlur = (event: FocusEvent<HTMLDivElement>) => {
    if (this.isControlled) {
      return;
    }

    const { contentNode } = this;

    const contains: boolean =
      contentNode &&
      (contentNode as HTMLDivElement).contains(event.relatedTarget as Node);

    if (!contains) {
      this.setState({ isOpen: false });
    }
  };

  /**
   * Passed to popperjs to access the popper content element.
   * @param node
   */
  setPopperContentNode = (node: any) => {
    this.contentNode = node;
  };

  getDocument = () => {
    return document;
  };

  getWindow = () => {
    return window;
  };

  renderContent(popperContent: ReactElement) {
    const { position, isInline, boundariesElement } = this.props;

    const preventOverflow: Partial<PreventOverflowModifier> = {
      name: 'preventOverflow',
      options: {
        padding: 5,
        boundary: boundariesElement,
      },
    };

    const popperClassNames = getClass(
      'elmo-popover-base__content',
      {},
      {
        'is-on-body': !isInline,
      }
    );

    const popper = (
      <Popper
        placement={position ? position : 'bottom-start'}
        modifiers={[preventOverflow]}
        innerRef={this.setPopperContentNode}
      >
        {({ ref, style, placement }) => {
          return (
            <div
              ref={ref}
              style={style}
              data-placement={placement}
              className={popperClassNames}
            >
              {popperContent}
            </div>
          );
        }}
      </Popper>
    );

    if (!isInline) {
      return ReactDOM.createPortal(
        popper,
        this.getDocument().getElementById(
          POPOVER_CONTAINER_ELEMENT_ID
        ) as Element
      );
    }

    return popper;
  }

  render() {
    const {
      isOpen,
      props: {
        id,
        className,
        mode,
        testId,
        ariaDescribedby,
        tabIndex,
        isSupportedFocusAndBlur,
      },
    } = this;

    const subComponents = this.getSubComponents();

    return (
      <div
        id={id}
        className={className}
        {...(mode !== 'click' && {
          onMouseEnter: this.open,
          onMouseLeave: this.onMouseLeave,
          onFocus: isSupportedFocusAndBlur ? this.open : undefined,
          onBlur: isSupportedFocusAndBlur ? this.onBlur : undefined,
        })}
        data-testid={testId}
        aria-describedby={isOpen ? ariaDescribedby : undefined}
        tabIndex={tabIndex}
      >
        <Manager>
          <Reference>
            {({ ref }) => (
              <div
                ref={ref}
                className="elmo-popover-base__target"
                {...(mode === 'click' && { onClick: this.toggle })}
              >
                {subComponents.PopperTarget}
              </div>
            )}
          </Reference>
          {isOpen && this.renderContent(subComponents.PopperContent)}
        </Manager>
      </div>
    );
  }
}
