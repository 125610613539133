import { cloneDeep } from 'lodash';
import { PAGE_SIZE } from 'lib/config';
import { MyRostersFilters, MyRostersReducerState } from './types';

export const defaultFilters: MyRostersFilters = {
  from: null,
  to: null,
  statuses: [],
  location: null,
  sort: {
    column: 'start',
    direction: 'asc',
  },
};

export const getDefaultState = (): MyRostersReducerState => ({
  timesheetInProgress: null,
  workingShifts: [],
  period: 'today',
  filtersForm: cloneDeep(defaultFilters),
  filters: cloneDeep(defaultFilters),
  isCustomFilter: false,
  pager: {
    currentPage: 1,
    pageSize: PAGE_SIZE[2],
    total: 0,
  },
  modals: {
    isTradeOpen: false,
    isUpdating: false,
    type: 'offer',
    shiftId: '',
    note: '',
    breakLimit: false,
    errors: [],
  },
  forceModal: {
    isOpen: false,
    message: '',
  },
  whoElseWorking: [],
  whoElseWorkingIsLoading: false,
  whoElseWorkingErrors: [],
});
