import { flatMap, map, pickBy } from 'lodash';
import {
  AccountTreeArea,
  AccountTreeSite,
  SelectPropsOption,
  SelectPropsOptionGrouped,
  StringMap,
} from 'type';

import { groupedByFieldCombinerCreator } from '../combiners';
import { isAppMarket } from 'helpers/helpers';

export const activeCombiner = <T extends { archived: boolean }>(
  data: StringMap<T>
): StringMap<T> => pickBy(data, ['archived', false]);

export const activeAreasBySiteIdCombiner = (
  areas: StringMap<AccountTreeArea> | AccountTreeArea[]
): StringMap<AccountTreeArea[]> => {
  return groupedByFieldCombinerCreator<AccountTreeArea>('site_id')(
    areas as StringMap<AccountTreeArea>
  );
};

export const areasGroupedAsSelectOptionsCombiner = (
  areasBySiteId: StringMap<AccountTreeArea[]>,
  sites: StringMap<AccountTreeSite>
): SelectPropsOptionGrouped[] =>
  map(
    areasBySiteId,
    (areas: AccountTreeArea[], siteId: string): SelectPropsOptionGrouped => {
      const siteName: string = sites[siteId].name;
      return {
        label: siteName,
        options: map(areas, (area: AccountTreeArea) => ({
          value: area.id,
          label: `${area.name}, ${siteName}`,
        })),
      };
    }
  );

export const siteIdsToSelectOptionsCombiner = (
  ids: string[],
  sitesCollection: StringMap<AccountTreeSite>
): SelectPropsOption[] => {
  const format = {
    withTimezone: (site: AccountTreeSite) =>
      `${site.name} (${site.timezone_id})`,
    withoutTimezone: (site: AccountTreeSite) => site.name,
  };

  const showTimezone = isAppMarket('uk', true);

  const labelFormatter = showTimezone
    ? format.withTimezone
    : format.withoutTimezone;

  return flatMap(ids, (id): SelectPropsOption | SelectPropsOption[] => {
    const site: AccountTreeSite | undefined = sitesCollection[id];

    if (!site) {
      return [];
    }

    return {
      label: labelFormatter(site),
      value: site.id,
    };
  });
};
