import { createAction } from 'lib/store-utils';
import { FormattedErrors } from 'type';
import {
  ApproveTimesheetAllModalPayload,
  ApproveTimesheetPayload,
  AutoGenerateTimesheetsPayload,
  EventDeleteRequest,
  GenerateCSVReportPayload,
  TimesheetsCSVResponse,
} from 'lib/Api';
import {
  DeleteRosteredShiftPayload,
  GetOffersPayload,
  GetUnpublishedRostersListPayload,
  PublishRostersPayload,
  RangeInputOpenPayload,
  RefactoredPublishRostersPayload,
  RosterDeleteConfirmationPayload,
  RosterGetPageDataPayload,
  RosterOffersModalPayload,
  RosterOpenModal,
} from './types';

export const BOX_ROSTER_GET_PAGE_DATA_REQUEST =
  createAction<RosterGetPageDataPayload>('Roster / get page data request');
export const BOX_ROSTER_GET_INITIAL_PAGE_DATA_REQUEST =
  createAction<RosterGetPageDataPayload>(
    'Roster / get initial page data request'
  );
export const BOX_ROSTER_UPDATE_ROSTER_DATA_REQUEST = createAction(
  'Roster / update roster data request'
);

export const BOX_ROSTER_PUBLISH_CHANGES_REFACTORED =
  createAction<RefactoredPublishRostersPayload>('Roster / publish rosters');
export const BOX_ROSTER_PUBLISH_CHANGES_SUCCESS = createAction(
  'Roster / publish rosters success'
);
export const BOX_ROSTER_PUBLISH_CHANGES_FAILURE = createAction<FormattedErrors>(
  'Roster / publish rosters failure'
);

export const BOX_ROSTER_OVERLAP_CONFIRMATION_OPEN =
  createAction<FormattedErrors>('Roster / open confirmation modal');
export const BOX_ROSTER_OVERLAP_CONFIRMATION_CLOSE = createAction<boolean>(
  'Roster / close confirmation modal'
);

export const BOX_ROSTER_GET_UNPUBLISHED =
  createAction<GetUnpublishedRostersListPayload>(
    'Roster / list of unpublished rosters'
  );
export const BOX_ROSTER_GET_UNPUBLISHED_SUCCESS = createAction<any>(
  'Roster / list of unpublished rosters success'
);
export const BOX_ROSTER_GET_UNPUBLISHED_FAILURE = createAction<FormattedErrors>(
  'Roster / list of unpublished rosters failure'
);

export const BOX_ROSTER_OPEN_APPLY_CHANGES_MODAL =
  createAction<RosterOpenModal>('Roster / open one of modals');
export const BOX_ROSTER_CLOSE_APPLY_CHANGES_MODAL = createAction(
  'Roster / close modals'
);

export const BOX_ROSTER_APPROVE_MODE_TOGGLE = createAction(
  'Roster / toggle rosters approve mode'
);
export const BOX_ROSTER_GLOBAL_APPROVE_TIMESHEET =
  createAction<ApproveTimesheetPayload>(
    'Roster / approve timesheet in approval mode'
  );
export const BOX_ROSTER_GLOBAL_APPROVE_TIMESHEET_ALL =
  createAction<ApproveTimesheetAllModalPayload>(
    'Roster / approve all timesheets in approval mode'
  );
export const BOX_ROSTER_GLOBAL_APPROVE_TIMESHEET_ALL_SUCCESS = createAction(
  'Roster / approve all timesheets in approval mode success'
);

export const BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN = createAction<FormattedErrors>(
  'Roster / open modal with errors'
);
export const BOX_ROSTER_GLOBAL_ERROR_MODAL_CLOSE = createAction(
  'Roster / close modal with errors'
);

export const BOX_ROSTER_OFFERS_CHANGES = createAction<RosterOffersModalPayload>(
  'Roster / shift offers request'
);
export const BOX_ROSTER_OFFERS_CHANGES_SUCCESS = createAction(
  'Roster / shift offers request success'
);
export const BOX_ROSTER_OFFERS_CHANGES_FAILURE = createAction<FormattedErrors>(
  'Roster / shift offers request failure'
);

export const BOX_ROSTER_GET_OFFERS = createAction<GetOffersPayload>(
  'Roster / list of available offers'
);
export const BOX_ROSTER_GET_OFFERS_SUCCESS = createAction<any>(
  'Roster / list of available offers success'
);
export const BOX_ROSTER_GET_OFFERS_FAILURE = createAction<FormattedErrors>(
  'Roster / list of available offers failure'
);

export const BOX_ROSTER_OPEN_DELETE_CONFIRMATION_MODAL =
  createAction<RosterDeleteConfirmationPayload>(
    'Roster / open delete confirmation modal'
  );
export const BOX_ROSTER_CLOSE_DELETE_CONFIRMATION_MODAL = createAction(
  'Roster / close delete confirmation modal'
);

export const BOX_ROSTER_DELETE_SHIFT = createAction<DeleteRosteredShiftPayload>(
  'Roster / delete shift request'
);
export const BOX_ROSTER_DELETE_SHIFT_SUCCESS = createAction(
  'Roster / delete shift success'
);
export const BOX_ROSTER_DELETE_SHIFT_FAILUE = createAction<FormattedErrors>(
  'Roster / delete shift failure'
);
export const BOX_ROSTER_DELETE_CLEAR_ERRORS = createAction(
  'Roster / clear errors in delete confirmation modal'
);

export const BOX_ROSTER_DELETE_EVENT_BY_ID = createAction<EventDeleteRequest>(
  'Roster / delete event by id'
);
export const BOX_ROSTER_DELETE_EVENT_BY_ID_SUCCESS = createAction(
  'Roster / delete event by id success'
);
export const BOX_ROSTER_DELETE_EVENT_BY_ID_FAILURE =
  createAction<FormattedErrors>('Roster / delete event by id failure');

export const BOX_ROSTER_RANGE_INPUT_OPEN = createAction<RangeInputOpenPayload>(
  'Roster / open range input'
);

export const BOX_ROSTER_TIMESHEETS_CSV_REQUEST =
  createAction<GenerateCSVReportPayload>('Roster / generate report request');
export const BOX_ROSTER_TIMESHEETS_CSV_SUCCESS =
  createAction<TimesheetsCSVResponse>('Roster / generate report success');
export const BOX_ROSTER_TIMESHEETS_CSV_FAILURE = createAction<FormattedErrors>(
  'Roster / generate report failure'
);

export const BOX_ROSTER_TIMESHEETS_AUTOGENERATE_REQUEST =
  createAction<AutoGenerateTimesheetsPayload>(
    'Roster / autogenerate timesheets request'
  );
export const BOX_ROSTER_TIMESHEETS_AUTOGENERATE_SUCCESS = createAction<boolean>(
  'Roster / autogenerate timesheets success'
);
export const BOX_ROSTER_TIMESHEETS_AUTOGENERATE_FAILURE =
  createAction<FormattedErrors>('Roster / autogenerate timesheets failure');
