import { Button, ButtonProps } from 'extended-oxygen-elements';
import React from 'react';
import { useSelector } from 'react-redux';
import { getDialogUpdating } from 'state/AccountTree';

export const PageDialogSubmit = ((props: ButtonProps) => {
  const loading = useSelector(getDialogUpdating);
  return (
    <Button
      size="medium"
      variant="contained"
      fullWidth
      loading={loading || props.loading}
      {...props}
    />
  );
}) as typeof Button;
