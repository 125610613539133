import { AlertErrorBoxLayout, WithPreload } from 'element';
import { withLayoutAware } from 'elmo-elements';
import { getPreferenceLabel } from 'lib/helpers';
import { SettingsLayoutComponent } from 'page/Settings/components';
import * as React from 'react';
import { connect } from 'react-redux';
import { getLangPreferences } from 'state/Account';
import {
  BOX_CHANGE_NOTIFICATIONS,
  BOX_NOTIFICATIONS_CLEAR_ERRORS,
  BOX_NOTIFICATIONS_REQUEST,
  BOX_UPDATE_NOTIFICATIONS_EMAIL,
  BOX_UPDATE_NOTIFICATIONS_NOTIFICATIONS,
  BOX_UPDATE_NOTIFICATIONS_REQUEST,
  BOX_UPDATE_NOTIFICATIONS_SMS,
  ChangeNotificationPayload,
  getIsNotificationsFetched,
  getIsNotificationsFetching,
  getIsNotificationsLoading,
  getNotifications,
  getNotificationsErrors,
} from 'state/Settings/Notifications';
import { StoreState } from 'state/types';
import { LanguagePreferences, Notifications as NotificationsProps } from 'type';
import { SectionHeading } from '../../components';
import { NotificationsTable, ToggleOption } from './components';

export type Props = {
  getNotifications: () => void;
  notifications: NotificationsProps;
  saveNotifications: () => void;
  errors: [];
  isLoading: boolean;
  clearErrors: () => void;
  isFetching: boolean;
  isFetched: boolean;
  enableEmail: (params: boolean) => void;
  enableSMS: (params: boolean) => void;
  enableNotifications: (params: boolean) => void;
  updateNotifications: (data: ChangeNotificationPayload) => void;
  langPreferences: LanguagePreferences;
};

export class Notifications extends React.Component<Props> {
  onChange = (e: ChangeNotificationPayload) => {
    this.props.updateNotifications(e);
  };

  componentWillUnmount(): void {
    this.props.clearErrors();
  }

  render() {
    const { notifications, langPreferences } = this.props;
    return (
      <WithPreload
        isFetching={this.props.isFetching}
        fetchData={this.getNotifications}
      >
        <SettingsLayoutComponent
          loading={this.props.isLoading}
          onSubmit={this.saveNotifications}
          title="Notifications"
        >
          <div className="notifications">
            <AlertErrorBoxLayout
              errors={this.props.errors}
              onClose={this.props.clearErrors}
              BoxProps={{
                mb: 2,
              }}
            />

            <SectionHeading title="General Settings" />

            <ToggleOption
              name="enable_email"
              title="Enable email notifications"
              isOn={notifications.enable_email}
              onChange={this.props.enableEmail}
            />

            <ToggleOption
              name="show_notifications"
              title="Show user notification settings"
              isOn={notifications.show_notifications}
              onChange={this.props.enableNotifications}
            />

            <SectionHeading title="Manager notifications" />

            <NotificationsTable
              notifications={notifications.notifications_groups.manager}
              onChange={this.onChange}
              disableEmail={!notifications.enable_email}
              disableSMS={!notifications.enable_sms}
              group="manager"
            />

            <SectionHeading
              title={`${getPreferenceLabel(
                langPreferences,
                'employee',
                'singular',
                '',
                true
              )} notifications`}
              className="mt-5"
            />
            <NotificationsTable
              notifications={notifications.notifications_groups.employee}
              onChange={this.onChange}
              disableEmail={!notifications.enable_email}
              disableSMS={!notifications.enable_sms}
              group="employee"
            />
          </div>
        </SettingsLayoutComponent>
      </WithPreload>
    );
  }

  getNotifications = () => {
    this.props.getNotifications();
  };

  saveNotifications = () => {
    this.props.saveNotifications();
  };
}

export const mapStateToProps = (state: StoreState) => ({
  isFetched: getIsNotificationsFetched(state),
  isLoading: getIsNotificationsLoading(state),
  isFetching: getIsNotificationsFetching(state),
  errors: getNotificationsErrors(state),
  notifications: getNotifications(state),
  langPreferences: getLangPreferences(state),
});

export default connect(mapStateToProps, {
  saveNotifications: BOX_UPDATE_NOTIFICATIONS_REQUEST,
  getNotifications: BOX_NOTIFICATIONS_REQUEST,
  enableEmail: BOX_UPDATE_NOTIFICATIONS_EMAIL,
  enableSMS: BOX_UPDATE_NOTIFICATIONS_SMS,
  enableNotifications: BOX_UPDATE_NOTIFICATIONS_NOTIFICATIONS,
  updateNotifications: BOX_CHANGE_NOTIFICATIONS,
  clearErrors: BOX_NOTIFICATIONS_CLEAR_ERRORS,
})(withLayoutAware(Notifications));
