import React, { Component } from 'react';
import { ToggleContextProps, ToggleProps } from './type';
import ToggleContext from './components/ToggleContext';
import ToggleOn from './components/ToggleOn';
import ToggleOff from './components/ToggleOff';
import ToggleButton from './components/ToggleButton';

class Toggle extends Component<ToggleProps, any> {
  static defaultProps = {
    isOn: false,
  };

  static On = ToggleOn;
  static Off = ToggleOff;
  static Button = ToggleButton;

  componentDidMount() {
    const { isOn, id } = this.props;
    this.setState({
      ...this.state,
      isOn,
      id,
    });
  }

  toggle = () =>
    this.setState(
      ({ isOn }: any) => ({ isOn: !isOn }),
      () => !this.props.onClick(this.state.isOn)
    );

  state: ToggleContextProps = { isOn: false, toggle: this.toggle };

  getChildren = () => {
    const { children } = this.props;
    if (children === undefined || children === null) {
      return <ToggleButton />;
    }

    return children;
  };

  render() {
    return (
      <ToggleContext.Provider value={this.state}>
        {this.getChildren()}
      </ToggleContext.Provider>
    );
  }
}

export default Toggle;
