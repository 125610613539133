import { createAction } from 'lib/store-utils';
import { GetEventByIdPayload } from './types';
import { Event, FormattedErrors, RosteredShift } from 'type/models';
import { RosteredShiftsGetPayload } from '../../RosteredShifts/types';
import { StringMap } from 'type';
import { EventCreateRequest, EventUpdateRequest } from 'lib/Api/type';

export const BOX_EVENT_MODAL_OPEN = createAction<GetEventByIdPayload>('Event modal / open');
export const BOX_EVENT_MODAL_CLOSE = createAction('Event modal / close');

export const BOX_EVENT_MODAL_GET_BY_ID_REQUEST = createAction<GetEventByIdPayload>('Event modal / get by id request');
export const BOX_EVENT_MODAL_GET_BY_ID_SUCCESS = createAction<Event>('Event modal / get by id success');
export const BOX_EVENT_MODAL_GET_BY_ID_FAILURE = createAction<FormattedErrors>('Event modal / get by id failure');

export const BOX_EVENT_MODAL_SET_EVENT = createAction<Event>('Event modal / set default event');

export const BOX_EVENT_MODAL_GET_SHIFTS_REQUEST = createAction<RosteredShiftsGetPayload>('Event modal / get shifts request');
export const BOX_EVENT_MODAL_GET_SHIFTS_SUCCESS = createAction<StringMap<RosteredShift>>('Event modal / get shifts success');
export const BOX_EVENT_MODAL_GET_SHIFTS_FAILURE = createAction<FormattedErrors>('Event modal / get shifts failure');

export const BOX_EVENT_MODAL_ASSIGN_SHIFT = createAction<string>('Event modal / assign shift');
export const BOX_EVENT_MODAL_UNASSIGN_SHIFT = createAction<string>('Event modal / unAssign shift');

export const BOX_EVENT_MODAL_CREATE_EVENT = createAction<EventCreateRequest>('Event modal / create request');
export const BOX_EVENT_MODAL_CREATE_EVENT_SUCCESS = createAction('Event modal / create success');
export const BOX_EVENT_MODAL_CREATE_EVENT_FAILURE = createAction<FormattedErrors>('Event modal / create failure');

export const BOX_EVENT_MODAL_UPDATE_EVENT = createAction<EventUpdateRequest>('Event modal / update request');
export const BOX_EVENT_MODAL_UPDATE_EVENT_SUCCESS = createAction('Event modal / update success');
export const BOX_EVENT_MODAL_UPDATE_EVENT_FAILURE = createAction<FormattedErrors>('Event modal / update failure');

export const BOX_EVENT_MODAL_REMOVE_EVENT_REQUEST = createAction<string>('Event modal / remove event request');
export const BOX_EVENT_MODAL_REMOVE_EVENT_SUCCESS = createAction('Event modal / remove event success');
export const BOX_EVENT_MODAL_REMOVE_EVENT_FAILURE = createAction<FormattedErrors>('Event modal / remove event failure');

export const BOX_EVENT_MODAL_CLEAR_ERRORS = createAction('Event modal / clear errors');

export const BOX_EVENT_MODAL_PUBLISHED_SHIFTS_MODAL_OPEN = createAction('Event modal / published shifts modal open');
export const BOX_EVENT_MODAL_PUBLISHED_SHIFTS_MODAL_CLOSE = createAction('Event modal / published shifts modal close');

export const BOX_EVENT_MODAL_CUSTOM_FILE_REQUEST = createAction<{
  name: string,
  data: any,
  original_filename: string
}>('Event modal / Upload file for custom field');

export const BOX_EVENT_MODAL_CUSTOM_FILE_SUCCESS = createAction<{
  name: string,
  data: any,
  original_filename: string
}>('Event modal / Upload file for custom field');

export const BOX_EVENT_MODAL_CUSTOM_FILE_FAILURE = createAction<{
  name: string,
  data: any
}>('Event modal / Upload file for custom field failure');
