import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import {
  BOX_UNAVAILABILITY_CREATE_MODAL_CLOSE,
  BOX_UNAVAILABILITY_CREATE_UNAVAILABILITY_REQUEST,
  getCreateModal
} from 'state/ManagerDashboard/Unavailability';
import {
  UnavailabilityCreateEditModal,
  UnavailabilityCreateEditModalProps
} from '../UnavailabilityCreateEditModal';

type OwnProps = {};

type StateProps = Pick<
  UnavailabilityCreateEditModalProps,
  'isOpened' | 'isUpdating' | 'errors'
>;

type DispatchProps = Pick<
  UnavailabilityCreateEditModalProps,
  'onCloseModal' | 'onFormSubmit'
>;

type Props = OwnProps & StateProps & DispatchProps;

const UnavailabilityCreateComponent = (props: Props) => (
  <UnavailabilityCreateEditModal
    {...props}
    title="Add unavailability"
    submitLabel="Add"
  />
);

const mapStateToProps = (state: StoreState): StateProps => ({
  ...getCreateModal(state)
});

const mapDispatchToProps: DispatchProps = {
  onCloseModal: BOX_UNAVAILABILITY_CREATE_MODAL_CLOSE,
  onFormSubmit: BOX_UNAVAILABILITY_CREATE_UNAVAILABILITY_REQUEST
};

export const UnavailabilityCreate = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnavailabilityCreateComponent);
