import React from 'react';
import { useSelector } from 'react-redux';
import { AlertError } from 'element';
import { getSummaryErrors } from 'state/TimesheetsWeeklyPage';

type Props = {
  children: React.ReactNode;
};

export function SummaryErrors({ children }: Props) {
  const errors = useSelector(getSummaryErrors);

  if (errors.length) {
    return <AlertError errors={errors} />;
  }

  return <>{children}</>;
}
