import React from 'react';
import { useSelectorWithProps } from 'hooks';
import { getTimesheet } from 'state/TimesheetsCollection';
import { useAreaName, useTimesheetUserName } from './hooks';
import { ShiftCardBreaksDuration, ShiftCardIcons } from './views';
import { useTimesheetDayCard } from './hooks/useTimesheetDayCard';
import { ShiftCardViewRefactored } from './views/ShiftCardViewRefactored';

type Props = {
  timesheetId: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
};

export const SiteTimesheetDayCard = React.memo(function SiteTimesheetDayCard(
  props: Props
) {
  const timesheet = useSelectorWithProps(getTimesheet, props.timesheetId);
  const { viewProps, icons, breaks } = useTimesheetDayCard(timesheet);
  const userName = useTimesheetUserName(timesheet);
  const areaName = useAreaName(timesheet);

  return (
    <ShiftCardViewRefactored
      {...viewProps}
      data-timesheet-id={props.timesheetId}
      icons={<ShiftCardIcons icons={icons} />}
      breaks={<ShiftCardBreaksDuration breaks={breaks} />}
      title={userName}
      spotTitle={areaName}
    />
  );
});
