import { Box, Hidden, IconButton } from 'extended-oxygen-elements';
import React from 'react';
import { MenuIcon } from '../icons';
import { DESKTOP_BREAKPOINT } from './config';
import { useLayoutContext } from './layout-context';

function AppBarHamburger() {
  const { setMobileOpen } = useLayoutContext();

  const handleIconButtonClick = () => {
    setMobileOpen((prevState) => !prevState);
  };

  return (
    <Hidden up={DESKTOP_BREAKPOINT}>
      <Box mr={2}>
        <IconButton size="large" edge="start" onClick={handleIconButtonClick}>
          <MenuIcon />
        </IconButton>
      </Box>
    </Hidden>
  );
}

export default AppBarHamburger;
