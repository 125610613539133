import React from 'react';
import { ShiftTradesFilters } from './components/ShiftTradesFilters';
import ShiftTradesList from './components/ShiftTradesList';

const ShiftTradesView = () => {
  return (
    <>
      <ShiftTradesFilters />
      <ShiftTradesList />
    </>
  );
};

export default ShiftTradesView;
