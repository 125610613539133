import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Chip } from 'oxygen-elements';
import { clsx } from 'clsx';

const useBadgeStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: '#fff',
      backgroundColor: '#225990',
      textTransform: 'uppercase',
      fontWeight: 500,
      padding: '2px',
      fontSize: '12px',
      height: '24px',
      '& svg.MuiChip-icon': {
        color: 'inherit',
      },
    },
  })
);

type Props = {
  label: string;
  bgColor?: string;
  color?: string;
  type?: 'info' | 'warning' | 'success' | 'grey' | 'danger' | 'light';
  className?: string;
  icon?: React.ReactElement;
  id?: string;
  role?: string;
  isCircular?: boolean;
  testId?: string;
};

const colors = {
  warning: {
    bg: '#ffc53d',
    text: '#222',
  },
  info: {
    bg: '#19579f',
    text: '#fff',
  },
  success: {
    bg: '#006631',
    text: '#fff',
  },
  grey: {
    bg: '#545454',
    text: '#fff',
  },
  danger: {
    bg: '#b1101e',
    text: '#fff',
  },
  light: {
    bg: '#e6f1ff',
    text: '#222',
  },
};

export const SimpleBadge = ({
  label,
  bgColor,
  color,
  type,
  className,
  icon,
  id,
  role,
  isCircular,
  testId,
}: Props) => {
  const styles = useBadgeStyles();
  if (type) {
    return (
      <Chip
        id={id}
        label={label}
        style={{
          backgroundColor: `${colors[type].bg}`,
          color: `${colors[type].text}`,
          padding: isCircular ? '0' : '2px',
        }}
        className={clsx(styles.root, className, `badge-type-${type}`)}
        icon={icon}
        role={role}
        data-testid={testId}
      />
    );
  }
  return (
    <Chip
      id={id}
      label={label}
      style={{
        backgroundColor: bgColor,
        color: color,
        padding: isCircular ? '0' : '2px',
      }}
      className={clsx(styles.root, className)}
      icon={icon}
      role={role}
      data-testid={testId}
    />
  );
};
