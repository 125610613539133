import moment from 'moment';
import { FinishedTimesheet, Timesheet } from 'type';

export const createEmptyTimesheet = (): Timesheet => ({
  id: '',
  start: moment(),
  end: null,
  breaks: [],
  rostered_shift_id: null,
  role_id: '',
  area_id: '',
  site_id: '',
  is_approved: false,
  is_violated: false,
  is_inviolated: false,
  is_self_assigned: false,
  started_by: null,
  stopped_by: null,
  messages: 0,
  lat: null,
  lng: null,
  clock_off_lat: null,
  clock_off_lng: null,
  total_time: null,
  photos: null,
  allowances_off: null,
  created_at: '',
  updated_at: '',
  rostered_shift: null,
  duration: 0,
  higher_duty: null,
  higher_duty_start: null,
  higher_duty_end: null,
  pay_entries: [],
  user_id: '',
  tags: [],
  notes: null
});

export const createFinishedTimesheet = (): FinishedTimesheet => {
  const emptyTimesheet = createEmptyTimesheet();
  return {
    ...emptyTimesheet,
    end: moment(),
    pay_entries: emptyTimesheet.pay_entries || []
  };
};
