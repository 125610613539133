import { AccountTreeRole } from 'type';

export function getLinkedRoleName(
  role: AccountTreeRole | undefined,
  fallBack: string
) {
  if (role) {
    return role.name;
  }

  return fallBack;
}
