import moment, { Duration } from 'moment';

export function onResizeEndCreator(props: {
  id: string;
  resizeStep: number;
  startTimeMins: number;
  endTimeMins: number;
  onResizeEnd: (
    payload: {
      id: string;
      start: Duration;
      end: Duration;
    }
  ) => void;
}) {
  return () => {
    const res = {
      id: props.id,
      start: moment.duration(props.startTimeMins, 'minutes'),
      end: moment.duration(props.endTimeMins, 'minutes')
    };

    props.onResizeEnd(res);
  };
}

export function resizeHandlerCreator(props: {
  value: number;
  set: (newValue: number) => void;
  oppositeValue: number;
  resizeStep: number;
  getWidthMins: (newValue: number) => number;
  oneMinuteInPx: number;
  dayInMinutes: number;
}) {
  return (sizePx: number) => {
    const newValueMins = sizePx / props.oneMinuteInPx;
    const diffMins = newValueMins - props.value;
    const diffMinsAbs = Math.abs(diffMins);
    const diffMinsAbsRounded = steppedRound(diffMinsAbs, props.resizeStep);
    const newValueRounded =
      diffMins > 0
        ? props.value + diffMinsAbsRounded
        : props.value - diffMinsAbsRounded;

    const newWidth = props.getWidthMins(newValueRounded);

    const ROSTER_MIN_START_TIME_MIN = 0;
    const ROSTER_MAX_END_TIME_MIN = props.dayInMinutes;

    if (
      newValueRounded !== props.value &&
      newValueRounded >= ROSTER_MIN_START_TIME_MIN &&
      newValueRounded <= ROSTER_MAX_END_TIME_MIN &&
      newWidth >= props.resizeStep
    ) {
      props.set(newValueRounded);
    }
  };
}

function steppedRound(value: number, step: number) {
  return Math.round(value / step) * step;
}
