import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { NotFirstAndEmptyPageSelector, Report, SagaAction } from 'type';
import { Api } from 'lib/Api';
import browserHistory from 'lib/browserHistory';
import { formatError } from '../helpers';
import * as actions from './actions';
import { privateRoutes } from 'routes';
import {
  BOX_REPORT_SAVE_AS_REPORT_SUCCESS_REDIRECT,
  BOX_REPORT_UPDATE_REPORT_SUCCESS,
} from '../Report';
import { getReportById, notFirstAndEmptyPageSelector } from './selectors';
import { SaveReportRequest } from './type';
import {
  ReportDeleteRequest,
  ReportScheduleRequest,
  ReportsGetAllResponse,
} from 'lib/Api/type';
import { processApiRequest } from '../ProcessApiRequest/sagas';
import { getRotaUserListRequest } from '../UsersCollection';
import * as fetchActions from '../FetchPageData/actions';
import { isHrService } from '../../helpers';
import _ from 'lodash';

export const getReports = function* (): SagaIterator {
  try {
    let response: ReportsGetAllResponse = yield call(
      processApiRequest,
      Api.Reports.getAll
    );

    // Removing reports with types transfer and payroll
    if (isHrService('bravo')) {
      response = _.pickBy(response, (report) => {
        return report.type !== 'transfer' && report.type !== 'payroll';
      });
    }

    yield put(actions.BOX_REPORTS_GET_REPORTS_SUCCESS(response));
  } catch (error) {
    yield put(actions.BOX_REPORTS_GET_REPORTS_FAILURE(formatError(error)));
  }
};

const getReportsPageData = function* (): SagaIterator {
  yield put(fetchActions.BOX_FETCH_PAGE_DATA_REQUEST('REPORTS'));
  try {
    yield all([
      call(getRotaUserListRequest), // ReportScheduleModal, CreateReportModal/ReportForm, SchedulePopover
      call(getReports),
    ]);
    yield put(fetchActions.BOX_FETCH_PAGE_DATA_SUCCESS('REPORTS'));
  } catch (error) {
    yield put(fetchActions.BOX_FETCH_PAGE_DATA_FAILURE('REPORTS'));
  }
};

export const saveReport = function* (
  action: SagaAction<SaveReportRequest>
): SagaIterator {
  try {
    const report: Report = yield call(
      processApiRequest,
      Api.Reports.save,
      action.payload.report
    );
    yield put(actions.BOX_REPORTS_SAVE_REPORT_SUCCESS(report));
    yield put(BOX_REPORT_UPDATE_REPORT_SUCCESS(report));
    const { id } = report;
    const reportUrl = privateRoutes.reports.routes.report.path(id);
    browserHistory.push(reportUrl);
    yield put(BOX_REPORT_SAVE_AS_REPORT_SUCCESS_REDIRECT());
  } catch (error) {
    yield put(actions.BOX_REPORTS_SAVE_REPORT_FAILURE(formatError(error)));
  }
};

export const updateSchedule = function* (
  action: SagaAction<ReportScheduleRequest>
): SagaIterator {
  try {
    const {
      payload,
    } = action;
    yield call(processApiRequest, Api.Reports.putSchedule, payload);
    yield put(actions.BOX_REPORTS_SCHEDULE_UPDATE_SUCCESS(payload));
  } catch (error) {
    yield put(actions.BOX_REPORTS_SCHEDULE_UPDATE_FAILURE(formatError(error)));
  }
};

export const addReportToDeleteQueue = function* (): SagaIterator {
  const { notFirstAndEmptyPage, lastPageNumber }: NotFirstAndEmptyPageSelector =
    yield select(notFirstAndEmptyPageSelector);

  if (notFirstAndEmptyPage) {
    yield put(actions.BOX_REPORTS_CHANGE_PAGE(lastPageNumber));
  }
};

export const deleteReport = function* (
  action: SagaAction<ReportDeleteRequest>
): SagaIterator {
  try {
    yield call(processApiRequest, Api.Reports.deleteReport, action.payload);
    yield put(actions.BOX_REPORTS_DELETE_SUCCESS(action.payload));

    if (action.payload.redirect) {
      browserHistory.push(action.payload.redirect);
    }
  } catch (error) {
    yield put(
      actions.BOX_REPORTS_DELETE_FAILURE({
        errors: formatError(error),
        id: action.payload.id,
      })
    );
  }
};

export const watchReports = function* (): SagaIterator {
  yield takeLatest(
    actions.BOX_REPORTS_GET_PAGE_DATA_REQUEST,
    getReportsPageData
  );
  yield takeLatest(actions.BOX_REPORTS_GET_REPORTS_REQUEST, getReports);
  yield takeLatest(actions.BOX_REPORTS_SAVE_REPORT_REQUEST, saveReport);
  yield takeLatest(actions.BOX_REPORTS_SCHEDULE_UPDATE_REQUEST, updateSchedule);
  yield takeLatest(
    actions.BOX_REPORTS_ADD_REPORT_IN_DELETE_QUEUE,
    addReportToDeleteQueue
  );
  yield takeLatest(actions.BOX_REPORTS_DELETE_REQUEST, deleteReport);
};
