import { Dictionary } from 'ts-essentials';
import { Env } from 'type';

export const expectedFlags = {
  timesheetExport: 'timesheetExport',
  payElements: 'payElements',
  weekendShiftOvertimeRules: 'weekendShiftOvertimeRules',
  reportDatetime: 'reportDatetime',
  timesheetsReportFilter: 'timesheetsReportFilter',
  massAssignLocation: 'massAssignLocation',
  dateFilterRotaTemplate: 'dateFilterRotaTemplate',
  whatsNew: 'whatsNew', // Upcoming feature ideas by BP2-8097
  customiseBreaks: 'customiseBreaks',
} as const;

type ExpectedFlags = typeof expectedFlags;
export type ExpectedFlagsKeys = keyof ExpectedFlags;
export type ExpectedFlag = ExpectedFlags[ExpectedFlagsKeys];

// used when it's impossible to use FeatureFlags
const devStaticFlags = {
  smartBanner: true,
};
const staticFlagsForAllEnvs: Dictionary<typeof devStaticFlags, Env> = {
  development: devStaticFlags,
  pentest: { smartBanner: false },
  staging: {
    smartBanner: true,
  },
  production: {
    smartBanner: false,
  },
};

export const staticFlags =
  staticFlagsForAllEnvs[process.env.REACT_APP_ENV as Env];
