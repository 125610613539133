import 'react-app-polyfill/stable';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import envValidator from './envValidator';
import 'elmo-elements/_lib/style/_reboot.scss';


envValidator();

ReactDOM.render(<App />, document.getElementById('root'));
