import { withHeading } from 'element';
import Accounts from 'page/Accounts';

export const accounts = {
  path: '/app/accounts',
  permission: 'sa.logindifferentaccount' as 'sa.logindifferentaccount',
  routes: { preferences: {
      path: '/app/accounts',
      component: withHeading(Accounts, {
        title: 'Accounts'
      })
    }
  }
};
