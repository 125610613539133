import { Box } from 'extended-oxygen-elements';
import React from 'react';
import { WithChildren } from 'type';
import { useLayoutContext } from '../Layout';

export function NewHeaderWrapper({
  children,
  style,
}: WithChildren & { style?: {} }) {
  const { background } = useLayoutContext();

  return (
    <Box position="relative" overflow="hidden" bgcolor={background} sx={style}>
      {children}
    </Box>
  );
}
