import { Route } from 'react-router-dom';
import PrivateLayout from 'layout/PrivateLayout';
import * as privateRoutes from './privateRoutes';

const appPath = '/app';
const routes = {
  app: {
    path: appPath,
    component: PrivateLayout,
    routeComponent: Route,
    routes: {
      ...privateRoutes,
      fallBack: {
        path: appPath,
        redirect: privateRoutes.roster.path,
        exact: false
      }
    }
  },
  fallBack: {
    path: '/',
    redirect: appPath,
    exact: false,
  },
};

export { privateRoutes, routes };
