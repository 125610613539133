import * as React from 'react';
import { Loader } from 'elmo-elements';
import { privateRoutes } from 'routes';
import { Header, ScheduleOutlinedIcon } from 'element';
import browserHistory from 'lib/browserHistory';

export type Props = {
  isLoading: boolean;
  subHeading?: string;
  showClock?: boolean;
  showSave?: boolean;
  title: string;
  onClick: () => void;
};

export class NotificationsHeader extends React.Component <Props> {

    getHeader = () => {

      const {showSave, title, subHeading} = this.props;
      return (
        <>
        {showSave ?  <Header
          title={title}
          subHeading={subHeading ? subHeading : ''}
          backButton={this.backButtonConfig}
          primaryButton={this.saveButtonConfig}
        /> :  <Header
          title={title}
          subHeading={subHeading ? subHeading : ''}
          backButton={this.backButtonConfig}
        />}
      </>
      );
    }

    render() {
        const {subHeading, showClock, title} = this.props;

        return (
            <div className="notification-header">
              {showClock ?  <Header
                title={title}
                subHeading={subHeading ? subHeading : ''}
                backButton={this.backButtonConfig}
                buttons={this.buttonsConfig}
              /> : this.getHeader()}
            </div>
        );
    }

    get backButtonConfig() {
        return {
            title: '',
            onClick: this.backButtonClickHandler
        };
    }

    readonly backButtonClickHandler = () => {
      const {showSave} = this.props;
      if (!showSave) {
        browserHistory.push(privateRoutes.settings.routes.menu.path + '/notifications');
      } else {
        browserHistory.push(privateRoutes.settings.routes.menu.path);
      }
    }

    get buttonsConfig() {

        const {onClick} = this.props;

        return [{
            label: 'Modify hours',
            isText: true,
            icon: <ScheduleOutlinedIcon/>,
            onClick: onClick
        }];
    }

    get saveButtonConfig() {

    const {onClick, isLoading} = this.props;

    return {
      label: 'Save',
      isText: true,
      icon:  isLoading ? <Loader type="spinner" isLoading={isLoading} /> : '' ,
      onClick: onClick
    };
  }
}
