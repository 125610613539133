import { styled } from 'extended-oxygen-elements';
import React from 'react';

export type LoadingTableCellProps = RootProps & {
  children: React.ReactNode;
  className?: string;
};

export default LoadingsTableCell;

type RootProps = {
  padding?: 'checkbox' | 'button';
};

const Root = styled('div', {
  shouldForwardProp: (propName) => propName !== 'padding',
})<RootProps>(({ theme, padding }) => ({
  padding: theme.gap(2),
  ...(padding === 'checkbox' && {
    padding: theme.gap(2) - theme.componentsConfig.iconButton.medium.padding,
  }),
  ...(padding === 'button' && {
    padding:
      theme.gap(2) - theme.componentsConfig.button.medium.padding.topBottom,
  }),
}));

export function LoadingsTableCell({
  children,
  padding,
  className,
}: LoadingTableCellProps) {
  return (
    <Root className={className} padding={padding}>
      {children}
    </Root>
  );
}
