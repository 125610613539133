import React, { Component, createRef, ReactNode, ReactText } from 'react';
import { getClass } from 'lib/helpers';
import { Dropdown, Radio, RadioButtons } from 'elmo-elements';
import './toggleView.scss';

export type ToggleButtonType = {
  icon: ReactNode;
  isActive: boolean;
  onClick: Function;
  ariaLabel?: string;
  label?: string;
};

export type ToggleViewProps = {
  buttons: ToggleButtonType[],
  id: string;
  className: string;
  isTextButton?: boolean;
};

export class ToggleView extends Component<ToggleViewProps> {

  protected handleClickListener: any;
  protected handleTouchListener: any;
  protected rootWrapper: any;

  private toggleRef = createRef<HTMLDivElement>();

  render() {
    const { buttons, id, className, isTextButton } = this.props;
    const { selectedButton, selectedKey } = this.getSelectedButtonKey();

    return (
      <div className="toggle-view__wrapper">
        <div
          id={id}
          data-testid={`toggle-view-${id || 'default'}`}
          className={getClass('toggle-view', className)}
          ref={this.toggleRef}
        >
          <div className="toggle-view__inner" role="radiogroup">
            <RadioButtons
              id={id + '-radio-buttons'}
              data-testid={`toggle-view-radio-buttons-${id || 'default'}`}
              selected={selectedKey}
              onChange={this.onHandleChange}
              type="solid"
              className="toggle-view--radio-buttons"
              ariaLabelledBy="ToggleView"
            >
              {buttons &&
              buttons.map((button: any, key: number) => (
                <Radio
                  value={key}
                  key={key}
                  className={this.setButtonClassName(key)}
                >
                  {button.label ? button.label : button.icon}
                </Radio>
              ))}
            </RadioButtons>

            <Dropdown
              id={id + '-dropdown'}
              data-testid={`toggle-view-dropdown-${id || 'default'}`}
              icon={isTextButton ? undefined : selectedButton.icon}
              text={isTextButton ? selectedButton.label : undefined}
              ariaLabel="ToggleView"
              className={`toggle-view--dropdown ${isTextButton ? 'toggle-view--text-placeholder' : ''}`}
            >
              {buttons &&
              buttons.map((button: any, key: number) => (
                <Dropdown.Item
                  ariaLabel={button.ariaLabel}
                  value={key}
                  key={key}
                  onClick={e => this.onHandleClick(e, button)}
                >
                  {button.icon} {button.ariaLabel}
                </Dropdown.Item>
              ))}
            </Dropdown>
          </div>
        </div>
      </div>
    );
  }

  setButtonClassName = (index: number) => {
    if (index === 0) {
      return 'radio-btn-left';
    }
    if (index === this.props.buttons.length - 1) {
      return 'radio-btn-right';
    }
    return '';
  };

  getSelectedButtonKey = () => {
    const { buttons } = this.props;
    let selectedButton: ToggleButtonType = {
      icon: '',
      onClick: () => void 0,
      isActive: true,
      label: ''
    };
    let selectedKey: number = 0;
    buttons.forEach((button: ToggleButtonType, key: number) => {
      if (button.isActive) {
        selectedButton = button;
        selectedKey = key;
      }
    });
    return {
      selectedButton,
      selectedKey,
    };
  };

  onHandleChange = (v: ReactText) => {
    const { buttons } = this.props;
    let key: number;
    if (typeof v === 'number') {
      key = v;
    } else {
      key = parseInt(v, 10);
    }
    this.onHandleClick('', buttons[key]);
  };

  onHandleClick = (e: any, button: ToggleButtonType) => {
    button.onClick();
    this.switchToDefaultState(button);
  };

  switchToDefaultState = (button: ToggleButtonType) => {
    const toggleView = this.toggleRef.current;
    if (toggleView !== null) {
      !button.isActive
        ? toggleView.classList.remove('expanded')
        : toggleView.classList.add('expanded');
    }
  };

  closeExpander = (e: Event, toggleView: HTMLElement) => {
    if (!(e.target as HTMLElement).closest('.toggle-view')) {
      toggleView.classList.remove('expanded');
    }
  };

  // remove expanded className if click/touch outside of the component
  bindMouseEvents = () => {
    this.rootWrapper = document.querySelector('html, body');
    const toggleView = this.toggleRef.current;
    if (this.rootWrapper !== null && toggleView !== null) {
      this.handleClickListener = this.rootWrapper.addEventListener(
        'click',
        (e: Event) => this.closeExpander(e, toggleView)
      );
      this.handleTouchListener = this.rootWrapper.addEventListener(
        'touchstart',
        (e: Event) => this.closeExpander(e, toggleView)
      );
    }
  };

  removeEventListeners = () => {
    if (this.rootWrapper !== null) {
      this.rootWrapper.removeEventListener('click', this.handleClickListener);
      this.rootWrapper.removeEventListener(
        'touchstart',
        this.handleTouchListener
      );
    }
  };

  componentDidMount(): void {
    this.bindMouseEvents();
  }

  componentWillUnmount(): void {
    this.removeEventListeners();
  }
}
