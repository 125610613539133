import React, { Component, createRef, forwardRef, RefObject } from 'react';
import { getClass } from '../_lib/helper';
import { InputComponentProps, InputProps } from './type';
import './Input.scss';

export class Input extends Component<InputComponentProps> {
  static defaultProps: Partial<InputProps> = {
    isReadOnly: false,
    isDisabled: false,
    isHidden: false,
    htmlType: 'text',
    tabIndex: 0,
  };

  private internalInputRef = createRef<HTMLInputElement>();

  componentDidUpdate() {
    if (this.props.autoFocus && this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  private get inputRef(): RefObject<HTMLInputElement> {
    const {
      props: { forwardedRef },
      internalInputRef,
    } = this;

    if (
      forwardedRef &&
      typeof (forwardedRef as RefObject<HTMLInputElement>).current !==
        'undefined'
    ) {
      return forwardedRef as RefObject<HTMLInputElement>;
    }

    return internalInputRef;
  }

  private handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { isDisabled, onChange, onEnterPress } = this.props;
    if (e === undefined) {
      return;
    }

    if (isDisabled) {
      e.stopPropagation();
      return;
    }

    if ((e as any).keyCode === 13 && onEnterPress) {
      onEnterPress(e as any);
    }

    if (onChange) {
      onChange(e);
    }
  };

  private handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    // TODO: Add isDisabled check
    const { onClick } = this.props;
    if (onClick) {
      onClick(e);
    }
  };

  private handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { isDisabled, onEnterPress, onKeyPress } = this.props;
    if (e === undefined) {
      return;
    }

    if (isDisabled) {
      e.stopPropagation();
      return;
    }

    if (e.charCode === 13 && onEnterPress) {
      onEnterPress(e);
    }

    if (onKeyPress) {
      onKeyPress(e);
    }
  };

  private handleMouseDown = (e: React.MouseEvent<HTMLInputElement>) => {
    const { onMouseDown } = this.props;
    if (onMouseDown) {
      onMouseDown(e);
    }
  };

  private handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur(e);
    }
  };

  private handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus(e);
    }
  };

  private renderInput = () => {
    const {
      isDisabled,
      isReadOnly,
      isHidden,
      value,
      status,
      id,
      name,
      htmlType,
      placeholder,
      tabIndex,
      ariaLabel,
      ariaDescribedby,
      ariaInvalid,
      ariaErrorMessageId,
      onKeyDown,
    } = this.props;

    const className: string = getClass('elmo-input', {
      [`elmo-input--on-${status}`]: status,
    });

    const ariaInvalidStatus =
      ariaInvalid || status === 'error' || status === 'warning';

    return (
      <input
        onWheel={(e) => e.currentTarget.blur()}
        id={id}
        data-testid={`elmo-input-${id || 'default'}`}
        className={className}
        onClick={this.handleClick}
        onChange={this.handleOnChange}
        onKeyPress={this.handleOnKeyPress}
        onBlur={this.handleOnBlur}
        onFocus={this.handleOnFocus}
        onKeyDown={onKeyDown}
        disabled={isDisabled}
        readOnly={isReadOnly}
        hidden={isHidden}
        value={value}
        type={htmlType}
        name={name}
        placeholder={placeholder}
        onMouseDown={this.handleMouseDown}
        ref={this.inputRef}
        tabIndex={tabIndex}
        aria-label={ariaLabel}
        aria-describedby={ariaDescribedby}
        aria-invalid={ariaInvalidStatus}
        aria-errormessage={
          ariaInvalidStatus && ariaErrorMessageId
            ? ariaErrorMessageId
            : undefined
        }
      />
    );
  };

  render() {
    const {
      inputAdornmentStart,
      inputAdornmentEnd,
      status,
      isDisabled,
      isReadOnly,
    } = this.props;
    const className: string = getClass('elmo-input__wrapper', {
      [`elmo-input__wrapper--on-${status}`]: status,
      [`elmo-input__wrapper--is-disabled`]: isDisabled,
      [`elmo-input__wrapper--is-read-only`]: isReadOnly,
    });
    const adornmentStartClassName: string = getClass('elmo-input__adornment', {
      [`elmo-input__adornment--start`]: inputAdornmentStart,
    });
    const adornmentEndClassName: string = getClass('elmo-input__adornment', {
      [`elmo-input__adornment--end`]: inputAdornmentEnd,
    });

    return inputAdornmentStart || inputAdornmentEnd ? (
      <div className={className}>
        {inputAdornmentStart && (
          <div className={adornmentStartClassName}>{inputAdornmentStart}</div>
        )}
        {this.renderInput()}
        {inputAdornmentEnd && (
          <div className={adornmentEndClassName}>{inputAdornmentEnd}</div>
        )}
      </div>
    ) : (
      this.renderInput()
    );
  }
}

export default forwardRef<HTMLInputElement, InputProps>((props, ref) => (
  <Input forwardedRef={ref} {...props} />
));
