import React from 'react';
import { NewHeaderAction, NewHeaderWrapper, Header } from 'element';
import WithHeaderButtons from './WithHeaderButtons';
import { useBulkDeleteContext } from 'contexts';
import BulkDeleteActions from 'page/Roster/components/BulkDelete';
import Navigation from './Navigation';
import { useSelector } from 'react-redux';
import { getIsBulkDeleteOpened } from '../../../../../../state/Roster/BulkActions';

type Props = {
  buttonsConfig: any[];
};

export const HeaderInner = ({ buttonsConfig }: Props) => {
  const bulkDeleteContext = useBulkDeleteContext();
  const isBulkDeleteOpened = useSelector(getIsBulkDeleteOpened);

  return (
    <WithHeaderButtons>
      {({ primaryButton, buttons }) => (
        <NewHeaderWrapper style={{ height: '64px', overflow: 'none' }}>
          <NewHeaderAction
            visible={!bulkDeleteContext.isEnabled || !isBulkDeleteOpened}
            style={{ padding: 0 }}
          >
            <Header
              title={<Navigation />}
              buttons={[...buttonsConfig, ...buttons]}
              primaryButton={primaryButton}
            />
          </NewHeaderAction>
          <NewHeaderAction
            visible={bulkDeleteContext.isEnabled && isBulkDeleteOpened}
            style={{
              padding: 0,
              boxShadow: '0 0 4px #ccc',
              background: 'white',
            }}
          >
            <BulkDeleteActions />
          </NewHeaderAction>
        </NewHeaderWrapper>
      )}
    </WithHeaderButtons>
  );
};
