import { Loader, LoadingOverlay } from 'elmo-elements';
import { useActions } from 'hooks';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BOX_AUDIT_TRAIL_FILTERS_MODAL_OPEN,
  BOX_AUDIT_TRAIL_FILTERS_UPDATE,
  BOX_AUDIT_TRAIL_REQUEST,
  getIsAuditFetched,
  getIsAuditFetching,
  getIsSettingsOpened,
} from 'state/AuditTrail';
import AuditTrailView from './AuditTrailView';
import {
  Layout,
  NewHeader,
  NewHeaderTitle,
  NewHeaderWrapper,
  TuneOutlinedIcon,
} from 'element';
import { SettingsHeaderBackBtn } from 'page/Settings/components';
import { IconButton, Box } from '@mui/material';
import AuditForm from './components/AuditForm';
import { getAccountId } from 'state/Account';
import { Tooltip } from '../../../../extended-oxygen-elements';

export default AuditTrail;

export function AuditTrail() {
  const getAuditTrail = useActions(BOX_AUDIT_TRAIL_REQUEST);
  const isFetching = useSelector(getIsAuditFetching);
  const isFetched = useSelector(getIsAuditFetched);
  const accountId = useSelector(getAccountId);
  const dispatch = useDispatch();
  const isOpened = useSelector(getIsSettingsOpened);

  const resetFilters = () => {
    dispatch(BOX_AUDIT_TRAIL_FILTERS_UPDATE({}));
  };

  React.useEffect(() => {
    getAuditTrail();
    return () => {
      resetFilters();
    };
  }, [getAuditTrail]);

  return (
    <Loader isLoading={!isFetched}>
      <Layout.Header>
        <NewHeaderWrapper>
          <NewHeader>
            <SettingsHeaderBackBtn onClick={resetFilters} />

            <NewHeaderTitle>Audit Trail - {accountId}</NewHeaderTitle>

            <Box ml="auto">
              <Tooltip title={'Open audit trail settings'}>
                <IconButton
                  onClick={() => dispatch(BOX_AUDIT_TRAIL_FILTERS_MODAL_OPEN())}
                >
                  <TuneOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </NewHeader>
        </NewHeaderWrapper>
      </Layout.Header>
      <Layout.Content>
        <LoadingOverlay isLoading={isFetching} showSpinner={true}>
          <AuditTrailView />
        </LoadingOverlay>
      </Layout.Content>
      {isOpened && <AuditForm />}
    </Loader>
  );
}
