import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { PickersDay, StaticDatePicker } from '@mui/x-date-pickers';
import { ChevronLeftIcon, ChevronRightIcon } from 'element';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
  useTimesheetsNewQuery,
} from 'hooks';
import { SERVER_DAY_FORMAT, SHORTENED_DAY_FORMAT } from 'lib/config';
import moment, { Moment } from 'moment';
import {
  Button,
  ButtonGroup,
  Popover,
  TextField,
} from 'extended-oxygen-elements';
import { formatDay, useDateRange } from 'page/TimesheetsWeekly/hooks';
import React from 'react';

const styles = () =>
  createStyles({
    buttonGroup: {
      display: 'flex',
    },
    buttonRange: {
      flex: 1,
    },
  });

type StylesProps = WithStyles<typeof styles>;
type Props = StylesProps;

export const DateRangeSelect = withStyles(styles)(function DateRangeSelect({
  classes,
}: Props) {
  const { query, changeQueryItems } = useTimesheetsNewQuery();
  const popupState = usePopupState();

  const { dateRange, getRange } = useDateRange(query);

  const handlePrevRangeClick = () => {
    changeQueryItems({ day: formatDay(getRange('prev')) });
  };

  const handleNextRangeClick = () => {
    changeQueryItems({ day: formatDay(getRange('next')) });
  };

  const handleDateRangeValueChange = (selectedDay: Moment | null) => {
    if (selectedDay?.isValid()) {
      changeQueryItems({ day: selectedDay.format(SERVER_DAY_FORMAT) });
      popupState.close();
    }
  };

  return (
    <>
      <ButtonGroup
        color="grey"
        size="large"
        className={classes.buttonGroup}
        data-testid="date-group"
      >
        <Button
          size="small"
          onClick={handlePrevRangeClick}
          aria-label="go to previous week"
          data-testid="date-prev-week-button"
        >
          <ChevronLeftIcon />
        </Button>

        <Button
          {...bindTrigger(popupState)}
          className={classes.buttonRange}
          aria-label="select week"
          data-testid="date-datepicker-button"
        >
          {dateRange.from.format(SHORTENED_DAY_FORMAT)} -{' '}
          {dateRange.to.format(SHORTENED_DAY_FORMAT)}
        </Button>

        <Button
          size="small"
          onClick={handleNextRangeClick}
          aria-label="go to next week"
          data-testid="date-next-week-button"
        >
          <ChevronRightIcon />
        </Button>
      </ButtonGroup>

      <Popover {...bindPopover(popupState)} data-testid="date-picker">
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          value={moment(query.day)}
          onAccept={handleDateRangeValueChange}
          onChange={(e) => true}
          renderInput={(params) => <TextField {...params} />}
          renderDay={(day, selectedDays, pickersDayProps) => {
            const formattedDay = moment(pickersDayProps.day).format(
              'MMM D, YYYY'
            );
            return (
              <PickersDay {...pickersDayProps} aria-label={formattedDay} />
            );
          }}
        />
      </Popover>
    </>
  );
});

export default DateRangeSelect;
