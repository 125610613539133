import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { find } from 'lodash';
import { PermissionName, ServerUserCurrent } from 'type';
import { privateRoutes } from 'routes';
import { hasPermission } from 'lib/helpers';
import { StoreState } from 'state/types';
import { getCurrentUser, getCurrentUserPermissions } from 'state/Auth';
import { WithoutPermissionsWarning } from './WithoutPermissionsWarning';

export type OwnProps = {
  permission?: PermissionName;
};

type StateProps = {
  currentUserPermissions: PermissionName[];
  user: ServerUserCurrent;
};

type Props = OwnProps & StateProps;

type RedirectPage = { path: string; permission: PermissionName };

class PermissionsRouteComponent extends Component<Props> {

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
    if (this.props.user && (window as any).Appcues) {
      (window as any).Appcues.identify(
        this.props.user.id, {
          firstName: this.props.user.prefered_name
        }
      );
    }
  }

  render() {
    const { currentUserPermissions, permission, ...rest } = this.props;
    if (hasPermission(currentUserPermissions, permission)) {
      return <Route {...rest} />;
    }

    const redirectPage: RedirectPage | undefined = find(
      this.redirectPages,
      ({ permission: redirectPagePermission }: RedirectPage) =>
        hasPermission(currentUserPermissions, redirectPagePermission)
    );

    if (redirectPage) {
      return <Redirect to={redirectPage.path} />;
    }

    return <WithoutPermissionsWarning />;
  }

  private redirectPages: RedirectPage[] = [
    privateRoutes.roster,
    privateRoutes.managerDashboard,
    privateRoutes.employeeDashboard,
    privateRoutes.settings,
    privateRoutes.users,
    privateRoutes.preferences,
    privateRoutes.reports,
    privateRoutes.accounts
  ];
}

const mapStateToProps = (state: StoreState): StateProps => ({
  currentUserPermissions: getCurrentUserPermissions(state),
  user: getCurrentUser(state)
});

export const PermissionsRoute = connect(mapStateToProps)(
  PermissionsRouteComponent
);
