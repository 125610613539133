import React, { Children, ReactElement } from 'react';
import DescriptionListItem, {
  DescriptionListItemProps,
} from './component/DescriptionListItem';
import { getClass } from '../_lib/helper';
import { DescriptionListProps } from './type';
import './DescriptionList.scss';

function DescriptionList({
  id,
  children,
  style,
  className,
}: DescriptionListProps) {
  if (style === 'align-horizontal') {
    children = Children.map(
      children as ReactElement<DescriptionListItemProps>[],
      (child: ReactElement<DescriptionListItemProps>) =>
        React.cloneElement(child, { hasDivider: true })
    );
  }
  return (
    <dl
      id={id}
      data-testid={`elmo-dl-${id || 'default'}`}
      className={getClass('elmo-dl', className, { [`${style}`]: style })}
    >
      {children}
    </dl>
  );
}

DescriptionList.Item = DescriptionListItem;

export default DescriptionList;
