import React from 'react';
import LoaderHolder from './LoaderHolder';
import { LoaderHolderProps } from '../type';
import './ButtonsStyle.scss';

function ButtonsStyle(props: LoaderHolderProps) {
  return (
    <LoaderHolder
      className="elmo-loader-buttons"
      viewBoxHeight={60}
      viewBoxWidth={400}
      {...props}
    >
      <rect x="0" y="10" rx="4" ry="4" width="60" height="50" />
      <rect x="80" y="10" rx="4" ry="4" width="100" height="50" />
      <rect x="200" y="10" rx="4" ry="4" width="60" height="50" />
    </LoaderHolder>
  );
}

export default ButtonsStyle;
