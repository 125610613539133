import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import View, { Props as ViewProps } from './view';
import {
  BOX_UNAVAILABILITY_DELETE_MODAL_CLOSE,
  BOX_UNAVAILABILITY_DELETE_REQUEST,
  getDeleteModal
} from 'state/ManagerDashboard/Unavailability';

type OwnProps = {};

type StateProps = Pick<ViewProps, 'isUpdating' | 'isOpened' | 'timeOffId'>;

type DispatchProps = Pick<ViewProps, 'onClose' | 'onSubmit'>;

type Props = OwnProps & StateProps & DispatchProps;

const UnavailabilityDeleteComponent = (props: Props) => <View {...props} />;

const mapStateToProps = (state: StoreState): StateProps => ({
  ...getDeleteModal(state)
});

const mapDispatchToProps: DispatchProps = {
  onClose: BOX_UNAVAILABILITY_DELETE_MODAL_CLOSE,
  onSubmit: BOX_UNAVAILABILITY_DELETE_REQUEST
};

export const UnavailabilityDelete = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnavailabilityDeleteComponent);
