import { createReducer } from 'lib/store-utils';
import { SiteAreaRoleModalReducerState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';

export const siteAreaRoleModal = createReducer<SiteAreaRoleModalReducerState>(
  {},
  getDefaultState()
);

siteAreaRoleModal.on(
  actions.BOX_SITE_AREA_ROLE_MODAL_OPEN,
  (state, payload): SiteAreaRoleModalReducerState => ({
    ...state,
    isResetButtonEnabled: !!(
      payload.selectedRoleTriples && payload.selectedRoleTriples.length
    ),
    isOpened: true,
    ...payload
  })
);

const closeHandler = (
  state: SiteAreaRoleModalReducerState
): SiteAreaRoleModalReducerState => ({
  ...state,
  isOpened: false
});

siteAreaRoleModal.on(actions.BOX_SITE_AREA_ROLE_MODAL_SUBMIT, closeHandler);
siteAreaRoleModal.on(actions.BOX_SITE_AREA_ROLE_MODAL_CLOSE, closeHandler);

siteAreaRoleModal.on(
  actions.BOX_SITE_AREA_ROLE_UPDATE_SELECTED_ROLE_TRIPLES,
  (state, selectedRoleTriples): SiteAreaRoleModalReducerState => ({
    ...state,
    selectedRoleTriples: selectedRoleTriples.length ? selectedRoleTriples : null
  })
);
