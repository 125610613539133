import React from 'react';
import { MessageBlock } from 'elmo-elements';

type Props = {
  message: string;
};
export const EmptyMessage = ({message}: Props) => (
  <MessageBlock>
    <MessageBlock.Header>
      <div className='placeholder'>
        {message}
      </div>
    </MessageBlock.Header>
  </MessageBlock>
);
