import { createReducer } from 'lib/store-utils';
import * as timesheetsWeeklyPage from 'state/TimesheetsWeeklyPage';
import { Dictionary } from 'ts-essentials';
import { AllowedToAssignShift } from 'type';

type AllowedToAssignShiftsResponseReducerState = {
  shifts: Dictionary<AllowedToAssignShift>;
};

export const allowedToAssignShiftsResponse =
  createReducer<AllowedToAssignShiftsResponseReducerState>(
    {},
    {
      shifts: {},
    }
  );

const updateHandler = (
  state: AllowedToAssignShiftsResponseReducerState,
  shifts: Dictionary<AllowedToAssignShift>
): AllowedToAssignShiftsResponseReducerState => ({ shifts });

allowedToAssignShiftsResponse
  .on(
    timesheetsWeeklyPage.BOX_TIMESHEETS_WEEKLY_ALLOWED_TO_ASSIGN_SHIFTS_GET
      .success,
    updateHandler
  )
  .on(
    timesheetsWeeklyPage.BOX_TIMESHEETS_WEEKLY_ALLOWED_TO_ASSIGN_SHIFTS_UPDATE
      .success,
    updateHandler
  );
