import { createAction } from 'lib/store-utils';
import {
  DeleteResponse,
  EmployeeDashboardShiftChangedPayload,
  EmployeeDashboardTimesheetChangedPayload
} from './types';

export const BOX_EMPLOYEE_DASHBOARD_SHIFT_CHANGED = createAction<EmployeeDashboardShiftChangedPayload>('Employee dashboard / rostered shift changed');
export const BOX_EMPLOYEE_DASHBOARD_SHIFT_DELETED = createAction<DeleteResponse>('Employee dashboard / rostered shift deleted');

export const BOX_EMPLOYEE_DASHBOARD_TIMESHEET_CHANGED = createAction<EmployeeDashboardTimesheetChangedPayload>('Employee dashboard / timesheet changed');
export const BOX_EMPLOYEE_DASHBOARD_TIMESHEET_DELETED = createAction<DeleteResponse>('Employee dashboard / timesheet deleted');
