import {
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
} from 'extended-oxygen-elements';
import { useRoute } from 'hooks';
import { Dialog, DialogTitle } from 'extended-oxygen-elements';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BOX_MAIN_MENU_TIMECLOCK_ITEM_CONFIRMATION_CLOSED,
  getIsTimeClockConfirmationOpen,
} from 'state/MainMenuHelpItem';
import {
  CloseIcon,
  WarningAmberOutlinedIcon,
  PageDialogCancel,
  PageDialogSubmit,
} from '../../element';
import { Avatar, Typography } from '../../extended-oxygen-elements';

export default ActivateTimeClockMenuItemConfirmationDialog;

export function ActivateTimeClockMenuItemConfirmationDialog() {
  const dispatch = useDispatch();
  const isDialogOpen = useSelector(getIsTimeClockConfirmationOpen);
  const route = useRoute();

  const closeDialog = () => {
    dispatch(BOX_MAIN_MENU_TIMECLOCK_ITEM_CONFIRMATION_CLOSED());
  };

  const handleConfirmButtonCLick = () => {
    route.redirect(process.env.REACT_APP_PUNCHCLOCK_URL as string);

    closeDialog();
  };

  return (
    <Dialog maxWidth="xs" open={isDialogOpen} onClose={closeDialog}>
      <DialogTitle
        endAdornment={
          <IconButton onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        }
      >
        <Avatar
          size="large"
          color="warning"
          light
          aria-label={'TimeClock Activation'}
        >
          <WarningAmberOutlinedIcon />
        </Avatar>
      </DialogTitle>

      <DialogContent>
        <Typography variant="h5" gutterBottom>
          TimeClock Activation
        </Typography>
        <DialogContentText>
          Activating the TimeClock will log you out of your current Breathe
          session. Do you want to continue?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <PageDialogCancel
          variant="outlined"
          size="medium"
          onClick={closeDialog}
          fullWidth
        >
          Cancel
        </PageDialogCancel>
        <PageDialogSubmit
          fullWidth
          variant="contained"
          onClick={handleConfirmButtonCLick}
        >
          Continue
        </PageDialogSubmit>
      </DialogActions>
    </Dialog>
  );
}
