import {
  Dialog as OxygenDialog,
  DialogProps as OxygenDialogProps,
} from 'oxygen-elements';
import React from 'react';

export type DialogProps = OxygenDialogProps & {
  loading?: boolean;
};

const Dialog = React.forwardRef<HTMLDivElement, DialogProps>(function Dialog(
  { loading = false, onClose, ...restProps },
  ref
) {
  const handleClose: DialogProps['onClose'] = (...params) => {
    if (!loading) {
      onClose?.(...params);
    }
  };

  return <OxygenDialog {...restProps} onClose={handleClose} ref={ref} />;
});

export default Dialog;
