import { FetchWrapper } from 'element';
import { useSelectorWithProps, useTimesheetsQuery } from 'hooks';
import { SERVER_DAY_FORMAT } from 'lib/config';
import { getDayRange } from 'lib/helpers';
import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getSite } from 'state/AccountTree';
import { MANAGER_DASHBOARD_TIMESHEETS } from 'state/FetchPageData';
import { BOX_TIMESHEETS_GET_PAGE_DATA_REQUEST } from 'state/Timesheets/Timesheets';

type Props = { children: React.ReactNode };

export default FetchPageData;

export function FetchPageData({ children }: Props) {
  const dispatch = useDispatch();
  const payload = useDayPayload();

  const handleFetchData = () => {
    dispatch(BOX_TIMESHEETS_GET_PAGE_DATA_REQUEST(payload));
  };

  return (
    <FetchWrapper
      fetchData={handleFetchData}
      pageId={MANAGER_DASHBOARD_TIMESHEETS}
    >
      {children}
    </FetchWrapper>
  );
}

function useDayPayload() {
  const {
    query: { site_id, day },
  } = useTimesheetsQuery();
  const { timezone_id } = useSelectorWithProps(getSite, site_id);
  const dayMoment = moment(day, SERVER_DAY_FORMAT).tz(timezone_id, true);

  return {
    site_id,
    ...getDayRange(dayMoment),
  };
}
