import { pick } from 'lodash';
import moment from 'moment';
import {
  EmployeeUnavailabilityProps,
  EmployeeUnavailabilityRequestPayload,
} from 'state/EmployeeDashboard/Unavailability/types';
import { FormData, Props, ViewState } from './types';
import { createUntilDate, getDefaultDates } from './helpers';
import { SERVER_DATE_TIME_FORMAT } from 'lib/config';

export const getState = ({ data, currentUserId }: Props): ViewState => {
  if (!data) {
    return {
      data: {
        user_id: currentUserId,
        site_id: '',
        duration: 'all_day',
        repeat: 'once',
        status: 'pending',
        description: '',
        id: '',
        site_name: '',
        ...getDefaultDates(moment()),
      },
      confirmDelete: false,
    };
  }

  const { start, end, until, description } = data;
  return {
    data: {
      ...pick<EmployeeUnavailabilityProps, keyof EmployeeUnavailabilityProps>(
        data,
        ['site_id', 'duration', 'repeat', 'status', 'id']
      ),
      user_id: currentUserId,
      start_date: start,
      start_time: moment({
        hour: start.get('hours'),
        minute: start.get('minutes'),
      }),
      end_date: end,
      end_time: moment({
        hour: end.get('hours'),
        minute: end.get('minutes'),
      }),
      until: until && until !== null ? until : createUntilDate(end),
      description: description ? description : '',
      site_name: '',
    },
    confirmDelete: false,
  };
};

export const getSubmitPayload = ({
  start_date,
  start_time,
  end_date,
  end_time,
  until,
  ...rest
}: FormData): EmployeeUnavailabilityRequestPayload | undefined => {
  const start = moment(start_date).set({
    hours: start_time
      ? start_time.get('hours')
      : moment('00:00', 'HH:mm').get('hours'),
    minutes: start_time
      ? start_time.get('minutes')
      : moment('00:00', 'HH:mm').get('minutes'),
  });

  const end = moment(end_date).set({
    hours: end_time
      ? end_time.get('hours')
      : moment('00:00', 'HH:mm').get('hours'),
    minutes: end_time
      ? end_time.get('minutes')
      : moment('00:00', 'HH:mm').get('minutes'),
  });

  const payload: EmployeeUnavailabilityRequestPayload = {
    ...rest,
    start: moment(start).format(SERVER_DATE_TIME_FORMAT),
    end: moment(end).format(SERVER_DATE_TIME_FORMAT),
    until: null,
  };

  if (payload.repeat !== 'once') {
    payload.until = until
      ? until.format(SERVER_DATE_TIME_FORMAT)
      : moment().format(SERVER_DATE_TIME_FORMAT);
  }

  if (!payload.user_id.length) {
    return;
  }

  if (payload.duration !== 'all_day' && start.isSameOrAfter(end)) {
    return;
  }

  return payload;
};
