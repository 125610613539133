import React, { Component, SyntheticEvent } from 'react';
import { Checkbox, FormItem } from 'elmo-elements';
import { SortUnavailabilityListFields, UpdateFormPayload } from 'type';
import { keyMirror } from 'lib/helpers';
import {
  FromToFilters,
  SortDirectionRadioButtons,
  SortFieldRadioButtons,
} from 'element';
import { UnavailabilityFilters } from 'state/ManagerDashboard/Unavailability';

export type Props = {
  formData: UnavailabilityFilters;
  onChange: (payload: UpdateFormPayload) => void;
};

export default class CustomFiltersView extends Component<Props> {
  readonly inputNames = keyMirror(this.props.formData);
  readonly sortFields: {
    label: string;
    value: SortUnavailabilityListFields;
  }[] = [
    {
      label: 'Name',
      value: 'name'
    },
    {
      label: 'Applied on',
      value: 'applied_on'
    },
    {
      label: 'Start date',
      value: 'start'
    },
    {
      label: 'End date',
      value: 'end'
    },
    {
      label: 'Frequency',
      value: 'frequency'
    }
  ];

  render() {
    const {
      inputNames,
      props: { formData }
    } = this;

    return (
      <form>
        <FormItem label="Status" isDivider={true}>
          <Checkbox
            id={inputNames.pending}
            name={inputNames.pending}
            label="Pending"
            isChecked={formData.pending}
            onChange={this.onChangeCheckbox}
          />

          <Checkbox
            id={inputNames.approved}
            name={inputNames.approved}
            label="Approved"
            isChecked={formData.approved}
            onChange={this.onChangeCheckbox}
          />

          <Checkbox
            id={inputNames.declined}
            name={inputNames.declined}
            label="Declined"
            isChecked={formData.declined}
            onChange={this.onChangeCheckbox}
          />
        </FormItem>

        <FromToFilters
          from={{
            name: inputNames.from,
            value: formData.from
          }}
          to={{
            name: inputNames.to,
            value: formData.to
          }}
          onChange={this.props.onChange}
        />

        <FormItem label="Sort by">
          <SortFieldRadioButtons
            id="sort-by-values"
            selected={formData.sort.column}
            onChange={this.onChangeSortField}
            sortFields={this.sortFields}
          />
        </FormItem>

        <FormItem>
          <SortDirectionRadioButtons
            id="sort-directions"
            selected={formData.sort.direction}
            onChange={this.onChangeSortDirection}
          />
        </FormItem>
      </form>
    );
  }

  private onChangeCheckbox = ({
    currentTarget: { name, checked }
  }: SyntheticEvent<HTMLInputElement>) => {
    this.props.onChange({
      name,
      value: checked
    });
  };

  private onChangeSortField = (column: string | number) => {
    this.props.onChange({
      name: this.inputNames.sort,
      value: {
        column,
        direction: this.props.formData.sort.direction
      }
    });
  };

  private onChangeSortDirection = (direction: string | number) => {
    this.props.onChange({
      name: this.inputNames.sort,
      value: {
        column: this.props.formData.sort.column,
        direction
      }
    });
  };
}
