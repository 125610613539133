import React from 'react';
import { connect } from 'react-redux';
import { LanguagePreferences } from 'type';
import { capitalize } from 'lib/helpers';
import { StoreState } from 'state/types';
import { getLangPreferences } from 'state/Account';
import { confirmationModalTitle } from '../../../../../messages';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from 'extended-oxygen-elements';
import { PageDialog, PageDialogCancel, PageDialogSubmit } from 'element';

type OwnProps = {
  isOpened: boolean;
  onConfirmBtnClick: () => void;
  onCloseBtnClick: () => void;
};

type StateProps = {
  langPreferences: LanguagePreferences;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

const ConfirmationModalComponent = ({
  isOpened,
  onCloseBtnClick,
  langPreferences,
  onConfirmBtnClick,
}: Props) => (
  <PageDialog maxWidth={'xs'} open={isOpened}>
    <DialogTitle>{confirmationModalTitle}</DialogTitle>
    <DialogContent>
      <Typography variant={'body1'}>
        {capitalize(langPreferences.employee.singular)} will be removed from the
        list of available users in this {langPreferences.site.singular}
      </Typography>
    </DialogContent>
    <DialogActions>
      <PageDialogCancel onClick={onCloseBtnClick}>Cancel</PageDialogCancel>
      <PageDialogSubmit onClick={onConfirmBtnClick}>OK</PageDialogSubmit>
    </DialogActions>
  </PageDialog>
);

const mapStateToProps = (state: StoreState): StateProps => ({
  langPreferences: getLangPreferences(state),
});

// const mapDispatchToProps: DispatchProps = {};

export const ConfirmationModal = connect(mapStateToProps)(
  ConfirmationModalComponent
);
