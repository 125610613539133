import React from 'react';
import { useSelector } from 'react-redux';
import { Dialog } from 'extended-oxygen-elements';
import { isStoppedTimesheetWithPayEntriesGuard } from 'lib/guards';
import { useSelectorWithProps, useNonNullableStaticState } from 'hooks';
import { useEditDialogContext } from 'contexts';
import { getDialogUpdating } from 'state/TimesheetsWeeklyPage';
import { getTimesheetSafe } from 'state/TimesheetResponse';
import EditDialogForm from './EditDialogForm';

export function EditDialog() {
  const { id: timesheetId, closeDialog } = useEditDialogContext();
  const timesheet = useNonNullableStaticState(
    useSelectorWithProps(getTimesheetSafe, timesheetId)
  );
  const loading = useSelector(getDialogUpdating);

  return (
    <Dialog
      open={!!timesheetId && isStoppedTimesheetWithPayEntriesGuard(timesheet)}
      onClose={closeDialog}
      loading={loading}
    >
      {isStoppedTimesheetWithPayEntriesGuard(timesheet) && (
        <EditDialogForm timesheet={timesheet} />
      )}
    </Dialog>
  );
}
