import { StoreState } from 'state/types';
import { RosterFiltersReducerState } from '../types';

const getState = (state: StoreState): RosterFiltersReducerState =>
  state.rosterFilters;

export const getIsFilterByTypeOpened = (state: StoreState) =>
  getState(state).isFilterByTypeOpened;

export const getFilters = (state: StoreState) => getState(state).filters;
export const getTimesheetsFilter = (state: StoreState) =>
  getFilters(state).timesheets;
export const getFiltersAreas = (state: StoreState) => getFilters(state).areas;
export const getFiltersRoles = (state: StoreState) => getFilters(state).roles;
export const getFiltersRoleFilterType = (state: StoreState) =>
  getFilters(state).roleFilterType;
export const getFiltersRoleTriples = (state: StoreState) =>
  getFilters(state).roleTriples;
export const getFiltersTags = (state: StoreState) => getFilters(state).tags;
export const getFiltersEvents = (state: StoreState) => getFilters(state).events;

export const getFiltersForm = (state: StoreState) =>
  getState(state).filtersForm;
export const getFiltersFormAreas = (state: StoreState) =>
  getFiltersForm(state).areas;
export const getFiltersFormRoles = (state: StoreState) =>
  getFiltersForm(state).roles;
export const getFiltersFormRoleTriples = (state: StoreState) =>
  getFiltersForm(state).roleTriples;
export const getFiltersFormRoleFilterType = (state: StoreState) =>
  getFiltersForm(state).roleFilterType;
export const getFiltersShowArchivedUsers = (state: StoreState) =>
  getFiltersForm(state).showArchivedUsers;
export const getFiltersShowPeople = (state: StoreState) =>
  getFiltersForm(state).showPeople;
