import React from 'react';
import { useDispatch } from 'react-redux';
import { FetchWrapper } from 'element';
import { EMPLOYEE_DASHBOARD_MY_ROSTERS } from 'state/FetchPageData';
import {
  BOX_MY_ROSTERS_DATA_REQUEST,
  BOX_MY_ROSTERS_PAGE_LEFT,
} from 'state/EmployeeDashboard/MyRosters';
import {
  EmployeeDashboardHeader,
  EmployeeDashboardLayout,
} from '../../components';
import View from './view';

const MyRosters = () => {
  const dispatch = useDispatch();
  const fetchData = () => {
    dispatch(BOX_MY_ROSTERS_DATA_REQUEST());
  };

  const clearState = () => {
    dispatch(BOX_MY_ROSTERS_PAGE_LEFT());
  };

  return (
    <EmployeeDashboardLayout header={<EmployeeDashboardHeader />}>
      <FetchWrapper
        fetchData={fetchData}
        clearState={clearState}
        pageId={EMPLOYEE_DASHBOARD_MY_ROSTERS}
      >
        <View />
      </FetchWrapper>
    </EmployeeDashboardLayout>
  );
};

export default MyRosters;
