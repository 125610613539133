import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BOX_COVID_TRACING_MODE_TOGGLE_SHIFT,
  getCovidPointedShiftId,
} from 'state/Roster/CovidTracing';

type Props = {
  id: string;
};

export const useHandleCovidModeClick = ({ id }: Props) => {
  const dispatch = useDispatch();
  const covidPointedId = useSelector(getCovidPointedShiftId);

  return useCallback(() => {
    if (!covidPointedId || covidPointedId === id) {
      dispatch(
        BOX_COVID_TRACING_MODE_TOGGLE_SHIFT({ id, entity: 'timesheet' })
      );
    }
  }, [dispatch, id, covidPointedId]);
};
