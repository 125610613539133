import React from 'react';

export type FormProps = {
  onSubmit: () => void;
  children: React.ReactNode;
};

export default Form;

export function Form({ onSubmit, children }: FormProps) {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSubmit();
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="submit" hidden />

      {children}
    </form>
  );
}
