import { triggerIntercom } from 'helpers/helpers';
import { Api } from 'lib/Api';
import { onUnauthorizedResponse } from 'lib/api-clients';
import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  getAccountRequest,
} from 'state/Account';
import { getAccountTreeRequest } from 'state/AccountTree';
import * as fetchPageData from 'state/FetchPageData';
import * as isLoading from 'state/IsLoading';
import { processApiRequest } from 'state/ProcessApiRequest';
import { ServerUserCurrent } from 'type';
import {
  BOX_AUTH_GET_CURRENT_USER_SUCCESS,
  BOX_AUTH_GET_GLOBAL_DATA_REQUEST,
  BOX_AUTH_LOGOUT_REQUEST,
} from '../actions';

export const getCurrentUserRequest = function* (): SagaIterator {
  const user: ServerUserCurrent = yield call(
    processApiRequest,
    Api.User.getCurrent
  );

  yield put(BOX_AUTH_GET_CURRENT_USER_SUCCESS(user));
  triggerIntercom(user);
};

const logoutSaga = function* (): SagaIterator {
  yield put(isLoading.BOX_IS_LOADING_GLOBAL_ON());
  try {
    yield call(Api.Sso.users.signOut);
  } catch (error) {}
  yield call(onUnauthorizedResponse);
  yield put(isLoading.BOX_IS_LOADING_GLOBAL_OFF());
};

const getGlobalData = function* (): SagaIterator {
  yield put(
    fetchPageData.BOX_FETCH_PAGE_DATA_REQUEST(fetchPageData.PRIVATE_LAYOUT)
  );
  try {
    yield all([
      call(getAccountRequest),
      call(getAccountTreeRequest),
      call(getCurrentUserRequest),
    ]);

    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_SUCCESS(fetchPageData.PRIVATE_LAYOUT)
    );
  } catch (error) {
    yield put(
      fetchPageData.BOX_FETCH_PAGE_DATA_FAILURE(fetchPageData.PRIVATE_LAYOUT)
    );
  }
};

export const watchAuth = function* () {
  yield takeLatest(BOX_AUTH_LOGOUT_REQUEST, logoutSaga);
  yield takeLatest(BOX_AUTH_GET_GLOBAL_DATA_REQUEST, getGlobalData);
};
