import { createAction } from 'lib/store-utils';
import {
  RosteredShift,
  RosterRepeatableTimeOff,
  StringMap,
  Timesheet,
} from '../../../type';
import { RosterGetPageDataPayload } from '../Roster/types';
import { PrintOptions } from './types';

export const BOX_ROSTERS_PRINT = createAction<boolean>('Rosters / start print');
export const BOX_ROSTERS_PRINT_OPEN = createAction<boolean>(
  'Rosters / open print modal'
);
export const BOX_ROSTERS_SET_PRINT_OPTIONS = createAction<PrintOptions>(
  'Rosters / set selected values to print'
);
