import React from 'react';
import { privateRoutes } from 'routes';
import { FeatureFlag, Redirect } from 'element';

type Props = {
  children: React.ReactNode;
};

function FeatureWrapper({ children }: Props) {
  return (
    <FeatureFlag name="payElements">
      <FeatureFlag.On>{children}</FeatureFlag.On>

      <FeatureFlag.Off>
        <Redirect delayed to={privateRoutes.settings.path} />
      </FeatureFlag.Off>
    </FeatureFlag>
  );
}

export default FeatureWrapper;
