import React from 'react';
import ContextualMenu from '../../ContextualMenu';
import { useSelector } from 'react-redux';
import { isTimesheetInProgress } from 'state/TimesheetsCollection';
import { useSelectorWithProps } from '../../../../../hooks';
import { getIsCovidModeEnabled } from '../../../../../state/Roster/CovidTracing/selectors';

type Props = {
  timesheetId: string;
  elementId: string;
};

export const TimesheetContextualMenu = (props: Props) => {
  const isInProgress = useSelectorWithProps(isTimesheetInProgress, props.timesheetId);
  const isCovidMode = useSelector(getIsCovidModeEnabled);

  return (
    !isInProgress && !isCovidMode
      ? <ContextualMenu
          elementId={props.elementId}
          objectId={props.timesheetId}
          type="edit"
          shiftType="timesheet"
          currentCellData={null}
        />
      : null
  );
};
