import * as React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Heading,
  ListTable,
  LoadingOverlay,
  Paragraph,
  withLayoutAware,
} from 'elmo-elements';
import { EditIcon, Layout, WithPreload } from 'element';
import { NotificationGroup, NotificationMessage, StringMap } from 'type';
import { StoreState } from 'state/types';
import {
  BOX_NOTIFICATION_DESTROY_REQUEST,
  BOX_NOTIFICATION_EDIT_MODAL_OPEN,
  BOX_NOTIFICATION_INIT_EDITOR_REQUEST,
  BOX_NOTIFICATION_REQUEST,
  BOX_NOTIFICATION_SELECT_MESSAGE,
  BOX_NOTIFICATION_TIME_MODAL_OPEN,
  EditableNotificationMessage,
  EditModal as EditModalType,
  getEditModal,
  getGroup,
  getIsFetched,
  getIsFetching,
  getMessageSelector,
  getNotification,
  getNotificationErrors,
  GetNotificationPayload,
  getNotificationsSelector,
  getTimeModal,
  TimeModal,
} from 'state/Settings/Notification';
import { SectionHeading } from '../../components';
import { EditModal, HoursModal, NotificationsHeader } from './components';
import { isHrService } from '../../../../../../helpers';

export type Props = {
  openEditModal: () => void;
  openTimeModal: () => void;
  getNotification: (data: GetNotificationPayload) => void;
  leaveNotification: () => void;
  initPlayer: () => void;
  errors: string[];
  isFetching: boolean;
  isFetched: boolean;
  group: NotificationGroup;
  messages: StringMap<NotificationMessage>;
  clearErrors: () => void;
  selectMessage: (message: EditableNotificationMessage) => void;
  timeModal: TimeModal;
  editModal: EditModalType;
};

type TagProps = Props & {
  match: {
    params: {
      groupId: string;
      notificationId: string;
    };
  };
};

export class NotificationComponent extends React.Component<TagProps> {
  componentWillUnmount() {
    this.props.leaveNotification();
  }

  render() {
    const {group, messages} = this.props;
    const {groupId, notificationId} = this.props.match.params;
    return (
      <WithPreload
        isFetching={!this.props.isFetched}
        fetchData={this.getNotificationData}
      >
        <Layout.Header>
          <NotificationsHeader
            onClick={() => {
              this.props.openTimeModal();
            }}
            isLoading={false}
            showClock={typeof group.hours !== 'undefined'}
            showSave={false}
            title={group.label}
            subHeading={group.tooltip}
          />
        </Layout.Header>
        <Layout.Content>
          <div className="notification-page">
            <SectionHeading title="Messages"/>
            <LoadingOverlay
              isLoading={this.props.isFetching}
              showSpinner={true}
            >
              <ListTable
                ariaLabel="Notifications"
                className="notificationsTable"
              >
                <ListTable.Header>
                  <ListTable.Column label={'Action'}/>
                  <ListTable.Column label={'Email message'}/>
                  {!isHrService('bravo') &&
                  <ListTable.Column label={'SMS message'}/>
                  }
                </ListTable.Header>
                <ListTable.Body>
                  {Object.keys(messages).map((key: string, index: number) => {
                    return (
                      <ListTable.Tr key={index} icon={this.renderControl(key)}>
                        <ListTable.Td>
                          {this.renderAction(messages[key])}
                        </ListTable.Td>
                        <ListTable.Td>
                          {this.renderEmail(messages[key])}
                        </ListTable.Td>
                        {!isHrService('bravo') && <ListTable.Td>
                          {this.renderSMS(messages[key])}
                        </ListTable.Td>
                        }
                      </ListTable.Tr>
                    );
                  })}
                </ListTable.Body>
              </ListTable>
            </LoadingOverlay>

            {this.props.timeModal.isOpened && (
              <HoursModal groupId={groupId} notificationId={notificationId}/>
            )}
            <EditModal groupId={groupId} notificationId={notificationId}/>
          </div>
        </Layout.Content>
      </WithPreload>
    );
  }

  renderAction = (message: any) => {
    return <Heading>{message.subject}</Heading>;
  };

  renderEmail = (message: any) => {
    return (
      <Paragraph>{message.email.replace(/<\/?[^>]+(>|$)/g, '')}</Paragraph>
    );
  };

  renderSMS = (message: any) => {
    return (
      <Paragraph className="notification-sms">
        {message.sms.replace(/<\/?[^>]+(>|$)/g, '')}
      </Paragraph>
    );
  };

  renderControl = (id: string) => {
    const {editable} = this.props.messages[id];

    return editable ? (
      <Button
        className="notification-edit-btn"
        onClick={() => {
          this.onEditClick(id);
        }}
        icon={<EditIcon/>}
      />
    ) : null;
  };

  onEditClick = (id: string) => {
    const {messages} = this.props;
    this.props.selectMessage({
      ...messages[id],
      key: id
    });

    this.props.initPlayer();
    setTimeout(() => {
      this.props.openEditModal();
    }, 600);
  };

  getNotificationData = () => {
    const {groupId, notificationId} = this.props.match.params;
    this.props.getNotification({
      group: groupId,
      id: notificationId
    });
  };
}

export const mapStateToProps = (state: StoreState) => ({
  isFetching: getIsFetching(state),
  isFetched: getIsFetched(state),
  errors: getNotificationErrors(state),
  notification: getNotification(state),
  group: getGroup(state),
  messages: getNotificationsSelector(state),
  message: getMessageSelector(state),
  timeModal: getTimeModal(state),
  editModal: getEditModal(state)
});

export default connect(
  mapStateToProps,
  {
    getNotification: BOX_NOTIFICATION_REQUEST,
    openEditModal: BOX_NOTIFICATION_EDIT_MODAL_OPEN,
    openTimeModal: BOX_NOTIFICATION_TIME_MODAL_OPEN,
    leaveNotification: BOX_NOTIFICATION_DESTROY_REQUEST,
    initPlayer: BOX_NOTIFICATION_INIT_EDITOR_REQUEST,
    selectMessage: BOX_NOTIFICATION_SELECT_MESSAGE
  }
)(withLayoutAware(NotificationComponent));
