import { call } from 'redux-saga/effects';
import moment from 'moment';
import { ISO8601DateTime, SagaReturnType } from 'type';
import { Handler } from '../types';
import makeSelectedRange from './makeSelectedRange';

const shiftNotInRangeHandler = <
  P extends { rostered_shift: { start: ISO8601DateTime } }
>(
  next: Handler<P>
): Handler<P> =>
  function* (payload) {
    const periodRange: SagaReturnType<typeof makeSelectedRange> = yield call(
      makeSelectedRange
    );

    const rosteredShiftStartMoment = moment.parseZone(
      payload.rostered_shift.start
    );
    if (rosteredShiftStartMoment.isBefore(periodRange.from, 'minutes')) {
      return;
    }

    if (
      periodRange.to &&
      rosteredShiftStartMoment.isAfter(periodRange.to, 'minutes')
    ) {
      return;
    }

    return yield call(next, payload);
  };

export default shiftNotInRangeHandler;
