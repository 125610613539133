import React from 'react';
import { connect } from 'react-redux';
import { Moment } from 'moment';
import { LoadingOverlay } from 'elmo-elements';
import { LanguagePreferences, UserFields } from 'type';
import { StoreState } from 'state/types';
import { getLangPreferences } from 'state/Account';
import {
  BOX_SHIFT_TIME_RANGE_INPUT_CREATE_REQUEST,
  CreateShiftPayload,
  daySelector,
  getIsLoading,
  usersSelector,
} from 'state/Roster/RangeInput/ShiftTimeRangeInput';
import { InlineInputWithLegend, OnSelect } from '../InputWithLegend';
import { getUserName } from '../../../../../../lib/helpers';

export type OwnProps = {
  area_id: string;
  role_id: string;
};

export type StateProps = {
  users: UserFields[];
  day: Moment;
  langPreferences: LanguagePreferences;
  isLoading: boolean;
};

export type DispatchProps = {
  userSelected: (selectedRole: CreateShiftPayload) => void;
};

export type Props = OwnProps & StateProps & DispatchProps;

export const SiteShiftTimeRangeInputComponent = ({
  users,
  day,
  userSelected,
  langPreferences,
  area_id,
  role_id,
  isLoading,
}: Props) => {
  const handleSelect: OnSelect<UserFields> = ({
    range,
    value: { id: user_id },
  }) => {
    userSelected({
      range,
      user_id,
      area_id,
      role_id,
    });
  };

  const usersOptions = users.map((user) => ({
    value: user,
    label: getUserName(user),
  }));

  return (
    <LoadingOverlay isLoading={isLoading}>
      <InlineInputWithLegend
        options={
          usersOptions.length
            ? [
                {
                  label: `Select ${langPreferences.employee.singular}`,
                },
                ...usersOptions,
              ]
            : []
        }
        onSelect={handleSelect as any} // TODO double-check
        day={day}
        emptyMessage={`No ${langPreferences.employee.plural} with this ${langPreferences.role.singular}.`}
      />
    </LoadingOverlay>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  users: usersSelector(state),
  day: daySelector(state),
  langPreferences: getLangPreferences(state),
  isLoading: getIsLoading(state),
});

const mapDispatchToProps: DispatchProps = {
  userSelected: BOX_SHIFT_TIME_RANGE_INPUT_CREATE_REQUEST,
};

export const SiteShiftTimeRangeInput = connect(
  mapStateToProps,
  mapDispatchToProps
)(SiteShiftTimeRangeInputComponent);
