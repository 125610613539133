import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { DeleteOutlinedIcon, Header } from 'element';
import { privateRoutes } from 'routes';
import { StoreState } from 'state/types';
import { getIsLoading } from 'state/IsLoading';
import {
  BOX_SHIFT_OFFER_DELETE_SHIFT_OFFER,
  getIsOutdatedValue,
} from 'state/ManagerDashboard/ShiftOffers/ShiftOffer';

type StateProps = {
  isUpdating: boolean;
  isOutdated: boolean;
};

type DispatchProps = {
  deleteShiftOffer: () => void;
};

type Props = StateProps & DispatchProps & RouteComponentProps;

class ShiftOfferHeaderComponent extends Component<Props> {
  render() {
    const { deleteShiftOffer, isOutdated, isUpdating } = this.props;

    return (
      <Header
        title="Shift offer"
        backButton={{
          title: '',
          onClick: this.backButtonClickHandler
        }}
        buttons={[
          {
            icon: <DeleteOutlinedIcon />,
            onClick: deleteShiftOffer,
            label: 'Delete',
            isDisabled: isUpdating || isOutdated
          }
        ]}
      />
    );
  }

  private backButtonClickHandler = () => {
    this.props.history.push(
      privateRoutes.managerDashboard.routes.shiftOffers.routes.shiftOffers.path
    );
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  isUpdating: getIsLoading(state, 'MANAGER_DASHBOARD_SHIFT_OFFER'),
  isOutdated: getIsOutdatedValue(state)
});

const mapDispatchToProps: DispatchProps = {
  deleteShiftOffer: BOX_SHIFT_OFFER_DELETE_SHIFT_OFFER
};

export const ShiftOfferHeader = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ShiftOfferHeaderComponent)
);
