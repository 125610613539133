import React, { ReactNode, useEffect } from 'react';
import { connect } from 'react-redux';
import { BOX_SHIFT_TIME_RANGE_INPUT_CLOSED } from 'state/Roster/RangeInput/ShiftTimeRangeInput';

type OwnProps = {
  children: ReactNode;
};

type StateProps = {};

type DispatchProps = {
  shiftRangeInputClosed: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const WithShiftRangeInputComponent = ({ children, shiftRangeInputClosed }: Props) => {
  useEffect(() => {
    shiftRangeInputClosed();
  }, [shiftRangeInputClosed]);

  return <>{children}</>;
};

// const mapStateToProps = (state: StoreState): StateProps => ({ });

const mapDispatchToProps: DispatchProps = {
  shiftRangeInputClosed: BOX_SHIFT_TIME_RANGE_INPUT_CLOSED,
};

export const WithShiftRangeInput = connect(
  null,
  mapDispatchToProps
)(WithShiftRangeInputComponent);
