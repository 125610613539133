import React from 'react';
import { Tooltip } from 'extended-oxygen-elements';
import { IconButton } from 'extended-oxygen-elements';
import { useDeleteDialogContext } from 'contexts';
import { DeleteOutlinedIcon } from 'element';

type Props = {
  timesheetId: string;
};

export const DeleteButton = ({ timesheetId }: Props) => {
  const { openDialog } = useDeleteDialogContext();

  const handleDeleteButtonClick = () => {
    openDialog(timesheetId);
  };

  return (
    <Tooltip title="Delete timesheet">
      <IconButton
        onClick={handleDeleteButtonClick}
        data-testid={'hover-action-delete'}
      >
        <DeleteOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
};
