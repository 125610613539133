import { Typography } from 'extended-oxygen-elements';
import React from 'react';
import { WithChildren } from 'type';

export default DialogSubTitle;

export function DialogSubTitle({ children }: WithChildren) {
  return (
    <Typography color="textSecondary" component="h3" variant="body1" mb={3}>
      {children}
    </Typography>
  );
}
