import React from 'react';
import { connect } from 'react-redux';
import { MessageBlock } from 'elmo-elements';
import { StoreState } from 'state/types';
import {
  emptyFilterResults,
  emptySearchResults,
} from 'messages';

type StateProps = {
  searchQuery: string;
};
type Props = StateProps;
const NotFoundMessageComponent = ({ searchQuery }: Props) => (
  <MessageBlock>
    <MessageBlock.Header>
      {searchQuery.length
        ? emptySearchResults('user names')
        : emptyFilterResults('users')}
    </MessageBlock.Header>
  </MessageBlock>
);

NotFoundMessageComponent.displayName = 'NotFoundMessageComponent';

const mapStateToProps = ({
  user: {
    search: { query },
  },
}: StoreState): StateProps => ({
  searchQuery: query,
});

export const NotFoundMessage = connect(mapStateToProps)(
  NotFoundMessageComponent
);
