import { Box, Chip } from 'extended-oxygen-elements';
import { useSelectorWithProps } from 'hooks';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BOX_ACCOUNT_TREE_DIALOG_ERRORS_CLEARED,
  getAreaLockedRoleIds,
  getRoles,
} from 'state/AccountTree';
import { getAssignmentArea } from 'state/Assignments';
import DialogUnlinkRole from './DialogUnlinkRole';

type Props = {
  site_id: string;
  area_id: string;
  disabled: boolean;
};

export default function ListAssignedRoles({
  area_id,
  site_id,
  disabled,
}: Props) {
  const roles = useSelector(getRoles);
  const lockedRoleIds = useSelectorWithProps(getAreaLockedRoleIds, area_id);
  const dispatch = useDispatch();
  const { role_ids, roles: assignmentRoles } = useSelectorWithProps(
    getAssignmentArea,
    { area_id, site_id }
  );
  const [isUnlinkRoleDialogOpen, setIsUnlinkRoleDialogOpen] =
    React.useState(false);
  const [roleId, setRoleId] = React.useState<null | string>(null);
  const [hasEmployees, setHasEmployees] = React.useState(false);

  const setUnlinkRoleProps = (roleId: string, count: number) => {
    dispatch(BOX_ACCOUNT_TREE_DIALOG_ERRORS_CLEARED());
    setRoleId(roleId);
    setHasEmployees(!!count);
    setIsUnlinkRoleDialogOpen(true);
  };

  return (
    <>
      <Box>
        {role_ids.map((roleId) => {
          const assignmentRole = assignmentRoles[roleId];
          const role = roles[roleId];
          const label = assignmentRole.users_count
            ? `(${assignmentRole.users_count})`
            : '';
          return (
            <Chip
              key={roleId}
              size="medium"
              color="primary"
              label={`${role.name} ${label}`}
              light
              onDelete={
                !lockedRoleIds.includes(roleId)
                  ? () => setUnlinkRoleProps(roleId, assignmentRole.users_count)
                  : undefined
              }
              sx={{ mr: 1 / 2, mb: 1 / 2 }}
              disabled={disabled}
            />
          );
        })}
      </Box>
      {roleId && (
        <DialogUnlinkRole
          open={isUnlinkRoleDialogOpen}
          onClose={() => setIsUnlinkRoleDialogOpen(false)}
          hasEmployees={hasEmployees}
          role_id={roleId}
          area_id={area_id}
        />
      )}
    </>
  );
}
