import { createSelector } from 'reselect';
import { compact, flatMap, uniq } from 'lodash';
import { StoreState } from 'state/types';
import { archivedOrWithDataFilter } from 'state/helpers';
import { asSelectOptionCombiner } from 'state/combiners';
import { areasBySiteIdSelector, getRoles } from 'state/AccountTree';
import { FlatAreaRoleModalReducerState } from './types';

const getState = (state: StoreState): FlatAreaRoleModalReducerState =>
  state.flatAreaRoleModal;

export const getIsOpened = (state: StoreState) => getState(state).isOpened;
export const getIsResetButtonEnabled = (state: StoreState) =>
  getState(state).isResetButtonEnabled;
export const getSelectedAreaIds = (state: StoreState) =>
  getState(state).selectedAreaIds;
export const getSelectedRoleIds = (state: StoreState) =>
  getState(state).selectedRoleIds;
export const getSiteId = (state: StoreState) => getState(state).siteId;
export const getArchivedAreaIdsToBeShown = (state: StoreState) =>
  getState(state).archivedAreaIdsToBeShown;
export const getArchivedRoleIdsToBeShown = (state: StoreState) =>
  getState(state).archivedRoleIdsToBeShown;

const filteredAreasSelector = createSelector(
  areasBySiteIdSelector,
  getSiteId,
  getSelectedAreaIds,
  getArchivedAreaIdsToBeShown,
  (areasBySiteId, siteId, selectedAreaIds, archivedAreaIdsToBeShown) => {
    const siteAreas = areasBySiteId[siteId!] || [];
    return archivedOrWithDataFilter(
      siteAreas,
      archivedAreaIdsToBeShown,
      selectedAreaIds
    );
  }
);

const filteredRolesSelector = createSelector(
  filteredAreasSelector,
  getSelectedAreaIds,
  getSelectedRoleIds,
  getRoles,
  getArchivedRoleIdsToBeShown,
  (
    filteredAreas,
    selectedAreaIds,
    selectedRoleIds,
    roles,
    archivedRoleIdsToBeShown
  ) => {
    const roleIdsOfSelectedAreas = uniq(
      flatMap(filteredAreas, area =>
        !selectedAreaIds.length || selectedAreaIds.includes(area.id)
          ? area.role_ids
          : []
      )
    );

    const rolesOfSelectedAreas = compact(
      roleIdsOfSelectedAreas.map(roleId => roles[roleId])
    );

    return archivedOrWithDataFilter(
      rolesOfSelectedAreas,
      archivedRoleIdsToBeShown,
      selectedRoleIds
    );
  }
);

export const filteredAreasSelectOptionsSelector = createSelector(
  filteredAreasSelector,
  filteredAreas => filteredAreas.map(asSelectOptionCombiner)
);

export const filteredRolesSelectOptionsSelector = createSelector(
  filteredRolesSelector,
  filteredRoles => filteredRoles.map(asSelectOptionCombiner)
);
