import * as React from 'react';
import {
  Dropdown
} from 'elmo-elements';
import { SelectPropsOption } from 'elmo-elements/Select/type';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import { activeSitesAsSelectOptionsArraySelector } from 'state/AccountTree';
import './style.scss';
import { BOX_USER_PROFILE_SAVE_CURRENT_USER_SETTINGS } from '../../../../state/Users/UserProfile';
import { UpdateCurrentUserPayload } from '../../../../type';
import { LocationOnOutlinedIcon } from 'element';

type StateProps = {
  sitesDropDownOptions: SelectPropsOption[];
};

type OwnProps = {
  onChange: (site: SelectPropsOption) => void,
  siteId: string
};

type DispatchProps = {
  saveUserRosterViewSettings: (params: UpdateCurrentUserPayload) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

export function LocationDropDown(props: Props) {
  const {
    sitesDropDownOptions,
    onChange,
    saveUserRosterViewSettings
  } = props;
  const selectedSite = getSelectedValue(props) as SelectPropsOption;
  const saveDefaultLocation = (siteId: string) => {
    saveUserRosterViewSettings({
      preferences: {
        roster_view_settings: {
          default_location: siteId
        }
      }
    });
  };
  return <div className="locations-dropdown-wrapper">
    <Dropdown
      isWidthAuto={true}
      isFullWidth={true}
      id="locations-dropdown"
      text={
        <>
          <LocationOnOutlinedIcon /> <span className={'locations-dropdown__text'}>{selectedSite.label}</span>
        </>
      }
      ariaLabel={'ariaLabel'}
      isBordered={true}
      isButtonBlock={false}
      isInline={false}
    >
      {sitesDropDownOptions.map((site, key) => {
        return <Dropdown.Item
          key={key}
          ariaLabel={site.label as string}
          value={site.value}
          onClick={() => {
            onChange(site);
            saveDefaultLocation(site.value);
          }}
        >
          {site.label}
        </Dropdown.Item>
      })}
    </Dropdown>
  </div>;
}

function getSelectedValue(props: Props) {
  const { siteId, sitesDropDownOptions } = props;
  let current: SelectPropsOption | {} = {};
  sitesDropDownOptions.forEach((site: SelectPropsOption) => {
    if (site.value === siteId) {
      current = site;
    }
  });
  return current;
}

const mapStateToProps = (state: StoreState): StateProps => ({
  sitesDropDownOptions: activeSitesAsSelectOptionsArraySelector(state)
});

const mapDispatchToProps: DispatchProps = {
  saveUserRosterViewSettings: BOX_USER_PROFILE_SAVE_CURRENT_USER_SETTINGS
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationDropDown);
