import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { StoreState } from '../../state/types';
import {
  BOX_BULK_CLEAR_SELECTED_IDS,
  BOX_BULK_CLEAR_STATE,
  BOX_BULK_SELECT_ALL_AVAILABLE,
  BOX_BULK_SELECT_ALL_VISIBLE,
  BOX_BULK_SET_ALL_IDS,
  BOX_BULK_SET_PAGE_IDS,
  BOX_BULK_TOGGLE_SELECTED_ID
} from '../../state/Bulk/actions';
import { selectedIdsSelector } from '../../state/Bulk/selectors';

type ChildrenArgs = {
  selectedIds: string[];
  clearSelected: () => void;
  toggleItem: (payload: { id: string }) => void;
  selectAllVisible: (isSelected: boolean) => void;
  selectAllAvailable: (isSelected: boolean) => void;
};

type OwnProps = {
  pageIds: string[];
  allIds: string[];
  children: (payload: ChildrenArgs) => ReactNode;
};

type StateProps = Pick<ChildrenArgs, 'selectedIds'>;

type DispatchProps = Pick<
  ChildrenArgs,
  'clearSelected' | 'toggleItem' | 'selectAllVisible' | 'selectAllAvailable'
> & {
  setPageIds: (payload: { ids: string[] }) => void;
  setAllIds: (payload: { ids: string[] }) => void;
  clearState: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

class WithBulkActionsComponent extends Component<Props> {
  render() {
    const {
      children,
      selectedIds,
      clearSelected,
      selectAllAvailable,
      selectAllVisible,
      toggleItem
    } = this.props;

    return children({
      selectedIds,
      clearSelected,
      selectAllAvailable,
      selectAllVisible,
      toggleItem
    });
  }

  componentDidMount(): void {
    const { allIds, pageIds, setPageIds, setAllIds } = this.props;

    setPageIds({
      ids: pageIds
    });

    setAllIds({
      ids: allIds
    });
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    const { allIds, pageIds, setAllIds, setPageIds } = this.props;

    if (allIds !== prevProps.allIds) {
      setAllIds({
        ids: allIds
      });
    }

    if (pageIds !== prevProps.pageIds) {
      setPageIds({
        ids: pageIds
      });
    }
  }

  componentWillUnmount(): void {
    this.props.clearState();
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  selectedIds: selectedIdsSelector(state)
});

const mapDispatchToProps: DispatchProps = {
  setAllIds: BOX_BULK_SET_ALL_IDS,
  setPageIds: BOX_BULK_SET_PAGE_IDS,
  clearState: BOX_BULK_CLEAR_STATE,
  clearSelected: BOX_BULK_CLEAR_SELECTED_IDS,
  selectAllAvailable: BOX_BULK_SELECT_ALL_AVAILABLE,
  selectAllVisible: BOX_BULK_SELECT_ALL_VISIBLE,
  toggleItem: BOX_BULK_TOGGLE_SELECTED_ID
};

export const WithBulkActions = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithBulkActionsComponent);
