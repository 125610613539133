import { DeleteDialogContextProvider } from 'contexts';
import { useTheme } from 'extended-oxygen-elements';
import React from 'react';
import { useSelector } from 'react-redux';
import { weekendShiftOvertimeRulesSelector } from 'state/Account';
import { Dictionary } from 'ts-essentials';
import LoadingsTable from './components/LoadingsTable';
import LoadingsTableCellHeading from './components/LoadingsTableCellHeading';
import LoadingsTableRow from './components/LoadingsTableRow';
import { PayConditionsListContextProvider } from './pay-conditions-list-context';
import AddWeekendLoadingButton from './weekendLoadingsTable/AddWeekendLoadingButton';
import CreateWeekendLoading from './weekendLoadingsTable/CreateWeekendLoading';
import DeleteConfirmationDialog from './weekendLoadingsTable/DeleteConfirmationDialog';
import NoRulesMessage from './weekendLoadingsTable/NoRulesMessage';
import WeekendLoadingsRow from './weekendLoadingsTable/WeekendLoadingsRow';

const flexes: Dictionary<string> = {
  0: '0 0 255px',
  1: '0 0 70px',
  2: '0 0 70px',
  3: 'auto',
  4: '0 0 50px',
  5: '0 0 50px',
};

export default WeekendLoadingsTable;

export function WeekendLoadingsTable() {
  const overtimeRules = useSelector(weekendShiftOvertimeRulesSelector);

  return (
    <PayConditionsListContextProvider>
      <DeleteDialogContextProvider>
        <>
          <LoadingsTable flexes={flexes} minWidth="md" maxWidth="lg">
            <LoadingsTableRow>
              <LoadingsTableCellHeading>Weekend day</LoadingsTableCellHeading>
              <LoadingsTableCellHeading>From</LoadingsTableCellHeading>
              <LoadingsTableCellHeading>To</LoadingsTableCellHeading>
              <LoadingsTableCellHeading>Pay Element</LoadingsTableCellHeading>
            </LoadingsTableRow>

            {overtimeRules.map((overtimeRule) => (
              <React.Fragment key={overtimeRule.id}>
                <WeekendLoadingsRow overtimeRule={overtimeRule} />

                <DeleteConfirmationDialog
                  id={overtimeRule.id}
                  day={overtimeRule.day}
                />
              </React.Fragment>
            ))}

            <CreateWeekendLoading />
          </LoadingsTable>

          <NoRulesMessage rulesQuantity={overtimeRules.length} />
        </>

        <AddWeekendLoadingButton p={2} />
      </DeleteDialogContextProvider>
    </PayConditionsListContextProvider>
  );
}
