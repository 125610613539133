import React from 'react';
import { Button, DialogActions } from 'oxygen-elements';
import { useSelector } from 'react-redux';
import { getIsPublishing } from 'state/Roster/Roster';

export default function PublishDialogActions({
  isDisabled,
  closeModal,
  publishChanges,
}: {
  isDisabled: boolean;
  publishChanges: () => void;
  closeModal: () => void;
}) {
  const isPublishing = useSelector(getIsPublishing);
  return (
    <DialogActions>
      <Button onClick={closeModal} variant="text" id={'publish-changes-cancel'}>
        Cancel
      </Button>
      <Button
        onClick={publishChanges}
        disabled={isPublishing || isDisabled}
        loading={isPublishing}
        variant="contained"
        id={'publish-changes-btn'}
      >
        Share changes
      </Button>
    </DialogActions>
  );
}
