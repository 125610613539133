import { createSelector } from 'reselect';
import { keyBy } from 'lodash';
import { SelectPropsOption } from 'elmo-elements/Select';
import {
  AllowanceItem,
  FinishedTimesheet,
  ServerManuallyAddedTimesheetPayEntry,
  StringMap,
} from 'type';
import { StoreState } from 'state/types';
import * as isLoading from 'state/IsLoading';
import * as timesheetModule from 'state/TimesheetsCollection';
import {
  AllowanceToBeAdded,
  FilledAllowance,
  FormAllowanceTotal,
} from '../types';

const getState = (state: StoreState) => state.TimesheetsAllowances;
export const getIsOpened = (state: StoreState) => getState(state).isOpened;
export const getErrors = (state: StoreState) => getState(state).errors;
export const getAllowances = (state: StoreState) => getState(state).allowances;
export const getFormData = (state: StoreState) => getState(state).formData;
export const getCurrentTimesheetId = (state: StoreState) =>
  getState(state).currentTimesheetId;
export const getCurrentTimesheet = (state: StoreState) =>
  timesheetModule.getTimesheet(
    state,
    getCurrentTimesheetId(state)
  ) as FinishedTimesheet;
const getCurrentTimesheetPayEntries = (state: StoreState) =>
  getCurrentTimesheet(state).pay_entries;
export const getIsLoading = (state: StoreState) =>
  isLoading.getIsLoading(
    state,
    isLoading.MANAGER_DASHBOARD_TIMESHEETS_ALLOWANCES_MODAL
  );

export const allowancesPayloadSelector = createSelector(
  getCurrentTimesheet,
  ({ higher_duty, user_id, start }) => ({
    user_id,
    date: start.clone(),
    higher_duty: higher_duty ? higher_duty.id : null
  })
);

const allowancesByIdSelector = createSelector(
  getAllowances,
  (allowances): StringMap<AllowanceItem> =>
    keyBy(allowances, (allowance: AllowanceItem) => allowance.id)
);

export const savedAllowancesSelector = createSelector(
  allowancesByIdSelector,
  getCurrentTimesheetPayEntries,
  (allowancesById, payEntries): FilledAllowance[] => {
    const manuallyAddedPayEntries = payEntries.filter(
      (payEntry): payEntry is ServerManuallyAddedTimesheetPayEntry =>
        payEntry.manually_added
    );

    return manuallyAddedPayEntries.map(
      (payEntry): FilledAllowance => {
        const allowance: AllowanceItem | undefined =
          allowancesById[payEntry.allowance.id];

        const name = allowance ? allowance.name : payEntry.allowance.name;
        const quantity = parseFloat(payEntry.quantity);
        const rate = parseFloat(payEntry.rate);
        return {
          id: payEntry.id,
          name,
          quantity: quantity,
          cost: (quantity * rate).toFixed(2),
        };
      }
    );
  }
);

export const filledAllowancesSelector = createSelector(
  allowancesByIdSelector,
  getFormData,
  (allowancesById, formData): FilledAllowance[] =>
    formData.map(
      ({ allowance: id, quantity }: AllowanceToBeAdded): FilledAllowance => {
        const { cost, name } = allowancesById[id];
        return {
          id,
          name,
          quantity,
          cost: (cost * quantity).toFixed(2),
        };
      }
    )
);

export const allowancesFormTotalSelector = createSelector(
  savedAllowancesSelector,
  filledAllowancesSelector,
  (savedAllowances, filledAllowances): FormAllowanceTotal => {
    const notRoundedTotal = [...savedAllowances, ...filledAllowances].reduce(
      (
        accumulator: FormAllowanceTotal,
        { cost, quantity }
      ): FormAllowanceTotal => {
        accumulator.cost += +cost;
        accumulator.quantity += quantity;

        return accumulator;
      },
      {
        cost: 0,
        quantity: 0
      }
    );

    return {
      cost: +notRoundedTotal.cost.toFixed(2),
      quantity: +notRoundedTotal.quantity.toFixed(2)
    };
  }
);

export const allowanceOptionsToAddSelector = createSelector(
  getAllowances,
  getFormData,
  (allowances, formData): SelectPropsOption[] => {
    const filledAllowanceIds: string[] = formData.map(
      ({ allowance }) => allowance
    );

    return allowances
      .filter(allowance => !filledAllowanceIds.includes(allowance.id))
      .map(
        (allowance): SelectPropsOption => ({
          label: allowance.name,
          value: allowance.id
        })
      );
  }
);

export const isFormFilledSelector = createSelector(
  getFormData,
  (formData): boolean => !!formData.length
);

export const isFormDisabledSelector = createSelector(
  getFormData,
  isFormFilledSelector,
  (formData, isFormFilled) =>
    isFormFilled &&
    formData.some(allowanceToBeAdded => !allowanceToBeAdded.quantity)
);
