import {
  WithEmployeeDashboardMenu,
  WithEmployeeDashboardSearch,
} from '../../../../components';
import { WithNotificationsListButton } from '../../../../../../element/NotificationsList/components/WithNotificationsListButton';
import React from 'react';
import { Header } from 'element';
import { PrimaryButtonType } from 'element/Header';

type Props = {
  primaryButton: PrimaryButtonType;
};

export const UnavailabilityHeader = ({primaryButton}: Props) => {

  return <WithEmployeeDashboardMenu>
    {menu => (
      <WithEmployeeDashboardSearch>
        {() => (
          <WithNotificationsListButton type={'employee'}>
            {({ notificationButton }) => (
              <Header
                title="Employee Dashboard"
                tabs={menu}
                buttons={[notificationButton]}
                primaryButton={primaryButton}
              />
            )}
          </WithNotificationsListButton>
        )}
      </WithEmployeeDashboardSearch>
    )}
  </WithEmployeeDashboardMenu>
};
