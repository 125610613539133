import makeUniqIdProvider from 'lib/makeUniqIdProvider';
import {
  AsyncActionCreator,
  createReducer,
  EmptyActionCreator,
  SimpleActionCreator,
} from 'lib/store-utils';
import { StoreState } from 'state/types';
import { SafeDictionary } from 'ts-essentials';

export type Status = 'request' | 'success' | 'failure' | 'cancel';

type StatusReducerState = {
  statuses: SafeDictionary<Status>;
};

export const status = createReducer<StatusReducerState>({}, { statuses: {} });
const getState = (state: StoreState): StatusReducerState => state.status;
const getStatus = (state: StoreState, id: string) =>
  getState(state).statuses[id];

const uniqIdProvider = makeUniqIdProvider();

export function StatusHandler(optionalId?: string) {
  const id = uniqIdProvider(optionalId);

  const request = (state: StatusReducerState): StatusReducerState => ({
    ...state,
    statuses: {
      ...state.statuses,
      [id]: 'request',
    },
  });

  const success = (state: StatusReducerState): StatusReducerState => ({
    ...state,
    statuses: {
      ...state.statuses,
      [id]: 'success',
    },
  });

  const failure = (state: StatusReducerState): StatusReducerState => ({
    ...state,
    statuses: {
      ...state.statuses,
      [id]: 'failure',
    },
  });

  const cancel = (state: StatusReducerState): StatusReducerState => ({
    ...state,
    statuses: {
      ...state.statuses,
      [id]: 'cancel',
    },
  });

  return {
    setRequest(action: EmptyActionCreator | SimpleActionCreator<any>) {
      status.on(action, request);

      return this;
    },

    setSuccess(action: EmptyActionCreator | SimpleActionCreator<any>) {
      status.on(action, success);

      return this;
    },

    setFailure(action: EmptyActionCreator | SimpleActionCreator<any>) {
      status.on(action, failure);

      return this;
    },

    setCancel(action: EmptyActionCreator | SimpleActionCreator<any>) {
      status.on(action, cancel);

      return this;
    },

    handleAsyncStatus(asyncAction: AsyncActionCreator<any, any, any, any>) {
      return this.setRequest(asyncAction.request)
        .setSuccess(asyncAction.success)
        .setFailure(asyncAction.failure)
        .setCancel(asyncAction.cancel);
    },

    makeSelector() {
      return (state: StoreState) => getStatus(state, id);
    },
  };
}
