import { RosterHeaderNavigation, RosterHeaderNavigationProps } from 'element';
import { useRosterQuery } from 'hooks';
import { SERVER_DAY_FORMAT } from 'lib/config';
import { getWeekRage } from 'lib/helpers';
import moment, { Moment } from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { BOX_ROSTER_FILTERS_RESET_ALL_FILTERS } from 'state/Roster/RosterFilters';
import { BOX_ROSTERED_SHIFTS_SET_DAY } from 'state/RosteredShifts/actions';

export default Navigation;

export function Navigation() {
  const dispatch = useDispatch();
  const {
    query: { day, site_id },
    changeQueryItems,
  } = useRosterQuery();
  const currentDayMoment = moment(day, SERVER_DAY_FORMAT);

  const changeDate = (updatedDayMoment: Moment) => {
    const updatedDay = updatedDayMoment.format(SERVER_DAY_FORMAT);

    changeQueryItems({
      day: updatedDay,
    });

    dispatch(BOX_ROSTERED_SHIFTS_SET_DAY(updatedDay));
  };

  const handlePrevClicked = () => {
    changeDate(currentDayMoment.clone().subtract(1, 'week'));
  };

  const handleNextClicked = () => {
    changeDate(currentDayMoment.clone().add(1, 'week'));
  };

  const handleDateChange: RosterHeaderNavigationProps['onDateChange'] = ({
    date,
  }) => {
    changeDate(date);
  };

  const handleSiteChange: RosterHeaderNavigationProps['onSiteChange'] = ({
    site_id,
  }) => {
    changeQueryItems({ site_id });
    dispatch(BOX_ROSTER_FILTERS_RESET_ALL_FILTERS());
  };

  return (
    <RosterHeaderNavigation
      selectedDay={currentDayMoment}
      siteId={site_id}
      dateRange={getWeekRage(currentDayMoment)}
      onPrevClicked={handlePrevClicked}
      onNextClicked={handleNextClicked}
      onSiteChange={handleSiteChange}
      onDateChange={handleDateChange}
    />
  );
}
