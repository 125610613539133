import React, { Component } from 'react';
import { StoreState } from 'state/types';
import { connect } from 'react-redux';
import {
  BOX_EVENT_MODAL_PUBLISHED_SHIFTS_MODAL_CLOSE,
  getEventModalPublishedShiftsModal,
} from 'state/Roster/EventModal';
import { PublishedShiftModalProps } from 'state/Roster/EventModal/types';
import { confirmationModalTitle } from '../../../../../messages';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';
import { PageDialog, PageDialogCancel, PageDialogSubmit } from 'element';

type OwnProps = {
  modal: PublishedShiftModalProps;
  saveWithPublished: () => void;
  saveWithoutPublished: () => void;
};

type DispatchProps = {
  closeModal: (isUpdating: boolean) => void;
};

type Props = OwnProps & DispatchProps;

class PublishedShiftsModal extends Component<Props> {
  render() {
    return (
      <PageDialog
        id={'event-publish-modal'}
        maxWidth={'xs'}
        open={this.props.modal.isOpened}
        onClose={this.props.saveWithoutPublished}
      >
        <DialogTitle>{confirmationModalTitle}</DialogTitle>
        <DialogContent>
          How would you like to treat the related shifts?
        </DialogContent>
        <DialogActions>
          <PageDialogCancel onClick={this.props.saveWithoutPublished}>
            Do nothing
          </PageDialogCancel>
          <PageDialogSubmit
            id={'event-publish-shift-btn'}
            onClick={this.props.saveWithPublished}
          >
            Update and publish them
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  }
}

const mapStateToProps = (state: StoreState) => ({
  modal: getEventModalPublishedShiftsModal(state),
});

const mapDispatchProps: DispatchProps = {
  closeModal: BOX_EVENT_MODAL_PUBLISHED_SHIFTS_MODAL_CLOSE,
};

export default connect(mapStateToProps, mapDispatchProps)(PublishedShiftsModal);
