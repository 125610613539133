import React, { MouseEvent } from 'react';
import { Button } from 'elmo-elements';
import { EditOutlinedIcon } from 'element';

type Props = {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  ariaLabel: string;
};
export const EditButton = (props: Props) => (
  <Button isCircular={true} icon={<EditOutlinedIcon />} {...props} />
);
