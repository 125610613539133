import { createReducer } from 'lib/store-utils';
import { defaultState } from './state';
import * as actions from './actions';
import { GlobalAlertReducerState } from './types';

export const globalAlert = createReducer({}, defaultState);

globalAlert.on(
  actions.BOX_GLOBAL_ALERT_OPEN,
  (state, message): GlobalAlertReducerState => ({
    ...state,
    isOpened: true,
    message
  })
);

globalAlert.on(
  actions.BOX_GLOBAL_ALERT_CLOSE,
  (state): GlobalAlertReducerState => ({
    ...state,
    isOpened: false,
    message: ''
  })
);
