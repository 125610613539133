import * as React from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import {
  BOX_EDIT_TEMPLATE_CLEAR_DELETE_MODAL_ERRORS,
  BOX_EDIT_TEMPLATE_CLOSE_DELETE_MODAL,
  BOX_EDIT_TEMPLATE_CONFIRM_DELETE_MODAL,
} from 'state/Roster/EditTemplate/actions';
import { getConfirmationDeleteModal } from 'state/Roster/EditTemplate/selectors';
import { ConfirmModalProps } from 'state/Roster/EditTemplate/types';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';
import {
  PageDialog,
  PageDialogCancel,
  PageDialogSubmit,
  ErrorBox,
} from 'element';
import { Typography } from 'oxygen-elements';

type OwnProps = {};

type DispatchProps = {
  closeModal: () => void;
  confirmDeletion: () => void;
  clearErrors: () => void;
};

type StateProps = {
  modal: ConfirmModalProps;
};

type Props = OwnProps & DispatchProps & StateProps;

export class DeleteModal extends React.Component<Props> {
  render() {
    const {
      modal: { isOpened, isUpdating, errors },
      closeModal,
      confirmDeletion,
      clearErrors,
    } = this.props;
    return (
      <PageDialog
        maxWidth={'xs'}
        open={isOpened}
        id="delete-shift"
        onClose={closeModal}
      >
        <DialogTitle>Delete shift?</DialogTitle>
        <DialogContent>
          {errors.length > 0 && (
            <ErrorBox errors={errors} clearErrors={clearErrors} />
          )}
          <Typography variant={'body1'}>
            You are about to delete 1 shift. Once deleted, this shift will be
            permanently removed.
          </Typography>
        </DialogContent>
        <DialogActions>
          <PageDialogCancel onClick={closeModal}>Cancel</PageDialogCancel>
          <PageDialogSubmit
            id="confirm-button"
            onClick={confirmDeletion}
            loading={isUpdating}
            disabled={isUpdating}
          >
            Delete
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  }
}

const mapToStateProps = (state: StoreState): StateProps => ({
  modal: getConfirmationDeleteModal(state),
});

const mapToDispatchProps: DispatchProps = {
  closeModal: BOX_EDIT_TEMPLATE_CLOSE_DELETE_MODAL,
  confirmDeletion: BOX_EDIT_TEMPLATE_CONFIRM_DELETE_MODAL,
  clearErrors: BOX_EDIT_TEMPLATE_CLEAR_DELETE_MODAL_ERRORS,
};

export default connect(mapToStateProps, mapToDispatchProps)(DeleteModal);
