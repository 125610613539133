import React from 'react';
import ReactDOM from 'react-dom';
import { DialogProps } from './type';
import ConfirmDialog from './component/ConfirmDialog';
import DangerDialog from './component/DangerDialog';
import WarningDialog from './component/WarningDialog';
import InfoDialog from './component/InfoDialog';
import SuccessDialog from './component/SuccessDialog';

function confirm(props: DialogProps) {
  render(ConfirmDialog, props);
}

function danger(props: DialogProps) {
  render(DangerDialog, props);
}

function warning(props: DialogProps) {
  render(WarningDialog, props);
}

function info(props: DialogProps) {
  render(InfoDialog, props);
}

function success(props: DialogProps) {
  render(SuccessDialog, props);
}

function destroy(container: any) {
  const unmountResult = ReactDOM.unmountComponentAtNode(container);
  if (unmountResult && container.parentNode) {
    container.parentNode.removeChild(container);
  }
}

function onOkHandler({ onOk }: any, container: any) {
  destroy(container);
  if (onOk) {
    onOk();
  }
}

function onCancelHandler({ onCancel }: any, container: any) {
  destroy(container);
  if (onCancel) {
    onCancel();
  }
}

function render(DialogComponent: any, props: any) {
  const container = document.createElement('div');
  document.body.appendChild(container);
  const dialogProps = {
    ...props,
    onOk: () => onOkHandler(props, container),
    onCancel: () => onCancelHandler(props, container),
  };

  ReactDOM.render(<DialogComponent {...dialogProps} />, container);
}

function Dialog(props: DialogProps) {}

Dialog.confirm = confirm;
Dialog.danger = danger;
Dialog.warning = warning;
Dialog.info = info;
Dialog.success = success;

export default Dialog;
