import React, { ReactNode } from 'react';
import Heading from '../../Typography/Heading';

export type MessageBlockHeaderProps = {
  children: ReactNode;
  id?: string;
};

function MessageBlockHeader({ children, id }: MessageBlockHeaderProps) {
  return (
    <div
      className="elmo-message-block__header"
      data-testid={`elmo-message-block-header-${id || 'default'}`}
    >
      <Heading type="title" size="sm">
        {children}
      </Heading>
    </div>
  );
}

export default MessageBlockHeader;
