import React from 'react';
import { ChartBarItem } from './type';
import { createStyles, makeStyles } from '@mui/styles';
import { DefaultDivProps } from '../../type';

type Props = {
  data: ChartBarItem[];
} & DefaultDivProps;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      height: '358px',
    },
    item: {
      height: '100%',
      marginRight: '10%',
      marginLeft: '10%',
    },
    title: {
      paddingTop: '10px',
      fontWeight: 500,
      fontSize: '13px',
      letterSpacing: '0.5px',
      textAlign: 'center',
    },
    barWrapper: {
      height: 'calc(100% - 60px)',
      overflow: 'hidden',
      borderRadius: '3px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column-reverse',
    },
    barFilled: {
      width: '50px',
      borderRadius: theme.shape.borderRadius,
      transition: 'height .45s ease-out',
    },
    barInfo: {
      textAlign: 'center',
      fontSize: '18px',
      fontWeight: 500,
    },
  })
);

export const VerticalBar = ({ data, ...rest }: Props) => {
  const classes = useStyles();
  const bar = (data: ChartBarItem) => {
    const resultHeight = data.y > 0 ? `calc(${data.y}% + 60px)` : '5px';
    return (
      <div className={classes.item} data-testid="bar-item">
        <div className={classes.barWrapper} data-testid="bar-wrapper">
          <div
            data-testid="bar-filled"
            className={classes.barFilled}
            style={{
              backgroundColor: data.color,
              height: resultHeight,
            }}
          />
          <span className={classes.barInfo} data-testid="bar-percentage">
            {data.y}%
          </span>
        </div>
        <div className={classes.title} data-testid="bar-name">
          {data.name}
        </div>
      </div>
    );
  };

  return (
    <div {...rest} className={classes.root}>
      {data.map((d: ChartBarItem, i: number) => (
        <React.Fragment key={i}>{bar(d)}</React.Fragment>
      ))}
    </div>
  );
};
