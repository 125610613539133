import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Filter, Row, Tooltip } from 'elmo-elements';
import { MapTupleToOption } from 'type';
import { StoreState } from 'state/types';
import {
  TimesheetsFilterType,
  TimesheetsFilterTypeTuple,
} from 'state/Timesheets/Timesheets/types';
import {
  BOX_TIMESHEETS_HIDE_FORM,
  BOX_TIMESHEETS_SHOW_FORM,
  BOX_TIMESHEETS_TOGGLE_FILTER_TYPE,
  getSelectedFilter,
  hasActiveUsers,
} from 'state/Timesheets/Timesheets';
import { AddOutlinedIcon } from 'element';
import './style.scss';
import { hasPermissionSelector } from 'state/Auth/selectors';

type StateProps = {
  selectedFilter: TimesheetsFilterType;
  canEditTimesheet: boolean;
  hasActiveUsers: boolean;
};

type DispatchProps = {
  toggleFilter: (filter: TimesheetsFilterType) => void;
  newShift: () => void;
  hideForm: () => void;
};

type Props = StateProps & DispatchProps;

const filterStatuses: MapTupleToOption<TimesheetsFilterTypeTuple> = [
  { value: 'all', label: 'All' },
  { value: 'pending', label: 'Pending' },
  { value: 'approved', label: 'Approved' },
  { value: 'inProgress', label: 'In progress' }
];

export class TimesheetsFiltersComponent extends Component<Props> {
  render() {
    return (
      <>
        <Row
          alignItems="stretch"
          justifyContent="center"
          alignContent="stretch"
        >
          <Col span={14}>
            <Filter
              id="todays-shifts"
              onStatusChange={this.onStatusChange}
              selectedValue={this.props.selectedFilter}
              statuses={filterStatuses}
            />
          </Col> <Col span={10} className={'text-right'}>
          {this.props.canEditTimesheet && <>
            {!this.props.hasActiveUsers ?
              <Tooltip title="There are no active users to add new shifts">
              {this.getNewShiftButton(true)}
            </Tooltip> : this.getNewShiftButton(false)}
          </>
          }</Col>
        </Row>
      </>
    );
  }

  private getNewShiftButton = (isDisabled: boolean) =>  <Button
    id="new-shift-form"
    isText={true}
    icon={<AddOutlinedIcon />}
    isDisabled={isDisabled}
    type="primary"
    onClick={() => {
      this.props.newShift();
    }}
  >
    Add timesheet
  </Button>

  private onStatusChange = (status: string) => {
    this.props.hideForm();
    this.props.toggleFilter(status as TimesheetsFilterType);
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  selectedFilter: getSelectedFilter(state),
  canEditTimesheet: hasPermissionSelector(state, 'roster.timesheet.edit'),
  hasActiveUsers: hasActiveUsers(state)
});

const mapDispatchToProps: DispatchProps = {
  toggleFilter: BOX_TIMESHEETS_TOGGLE_FILTER_TYPE,
  newShift: BOX_TIMESHEETS_SHOW_FORM,
  hideForm: BOX_TIMESHEETS_HIDE_FORM,
};

export const TimesheetsFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(TimesheetsFiltersComponent);
