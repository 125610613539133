import { FormattedErrors } from 'type';
import { ChangeTimeOffPayload, UnavailabilityReducerState } from '../types';

export const requestHandler = (
  state: UnavailabilityReducerState
): UnavailabilityReducerState => ({
  ...state,
  isUpdating: true,
  errors: []
});
export const successHandler = (
  state: UnavailabilityReducerState
): UnavailabilityReducerState => ({
  ...state,
  isUpdating: false
});
export const failureHandler = (
  state: UnavailabilityReducerState,
  errors: FormattedErrors
): UnavailabilityReducerState => ({
  ...state,
  isUpdating: false,
  errors
});
type FormModal = keyof Pick<
  UnavailabilityReducerState,
  'createModal' | 'editModal'
>;
type ConfirmModal = keyof Pick<
  UnavailabilityReducerState,
  'deleteModal' | 'confirmApprovalModal' | 'confirmCreationModal' | 'confirmEditModal'
>;
type Modal = FormModal | ConfirmModal;

export const formModalRequestHandlerCreator = (modal: FormModal) => (
  state: UnavailabilityReducerState
): UnavailabilityReducerState => ({
  ...state,
  [modal]: {
    ...state[modal],
    isUpdating: true
  }
});
export const confirmModalRequestHandlerCreator = (modal: ConfirmModal) => (
  state: UnavailabilityReducerState
): UnavailabilityReducerState => ({
  ...state,
  [modal]: {
    ...state[modal],
    isUpdating: true
  }
});
export const modalSuccessHandlerCreator = (modal: Modal) => (
  state: UnavailabilityReducerState
): UnavailabilityReducerState => ({
  ...state,
  [modal]: {
    ...state[modal],
    isUpdating: false,
    isOpened: false
  }
});
export const confirmModalFailureHandlerCreator = (modal: ConfirmModal) => (
  state: UnavailabilityReducerState,
  errors: FormattedErrors
) => ({
  ...state,
  errors,
  [modal]: {
    ...state[modal],
    isUpdating: false,
    isOpened: false
  }
});
export const modalFailureHandlerCreator = (modal: FormModal) => (
  state: UnavailabilityReducerState,
  errors: FormattedErrors
): UnavailabilityReducerState => ({
  ...state,
  [modal]: {
    ...state[modal],
    isUpdating: false,
    errors
  }
});
export const openConfirmModalHandlerCreator = (modal: ConfirmModal) => (
  state: UnavailabilityReducerState,
  { timeOffId }: ChangeTimeOffPayload
): UnavailabilityReducerState => ({
  ...state,
  errors: [],
  [modal]: {
    ...state[modal],
    isOpened: true,
    timeOffId
  }
});

export const closeModalHandlerCreator = (modal: Modal) => (
  state: UnavailabilityReducerState
): UnavailabilityReducerState => ({
  ...state,
  [modal]: {
    ...state[modal],
    isOpened: false
  }
});
