import React, { memo } from 'react';
import { makeItemUserIdSelector, makePairTimesheetIdSelector } from 'state/Roster/RosterWeekViewUserView';
import {
  useMakeSelector,
  useSelectorWithProps,
} from 'hooks';
import { RosterCell } from '../../../../../../../../../components';
import {
  TimesheetWeekCellBlock
} from '../../../../../../../components/WeekCellBlock';

type Props = {
  userRowIndex: number;
  pairRowIndex: number;
  weekDayIndex: number;
};

export const TimesheetCell = memo(function TimesheetCell(props: Props) {
  const pairTimesheetIdSelector = useMakeSelector(makePairTimesheetIdSelector);

  const timesheetId = useSelectorWithProps(pairTimesheetIdSelector, props);
  const itemUserIdSelector = useMakeSelector(makeItemUserIdSelector);
  const itemUserId = useSelectorWithProps(itemUserIdSelector, props);

  return (
    <RosterCell
      type={'bottom'}
      label={'+ Add Timesheet'}
      labelOnHover={true}
      fontWeight={'normal'}
    >
      <TimesheetWeekCellBlock
        rowNumber={props.pairRowIndex}
        weekDayIndex={props.weekDayIndex}
        userId={itemUserId}
        menuType={'create'}
        timesheetId={timesheetId}
        viewType={'user'}
      />
    </RosterCell>
  );
});
