import React, { Component } from 'react';
import { FormItem, Input, Select, FormContainer } from 'elmo-elements';
import { FormattedErrors, UserProfileFields } from 'type/models';
import moment, { Moment } from 'moment';
import { SERVER_DAY_FORMAT } from 'lib/config';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'extended-oxygen-elements';
import {
  PageDialog,
  PageDialogCancel,
  PageDialogSubmit,
  DateInputMoment,
  ErrorBox,
} from 'element';

type Props = {
  isOpened: boolean;
  closeModal: () => void;
  profile: Partial<UserProfileFields>;
  onDropDownChange: (params: any) => void;
  changeInput: (name: string, value: string | null) => void;
  saveProfile: () => void;
  saveDisabled: boolean;
  isLoading: boolean;
  errors: FormattedErrors;
  clearErrors: () => void;
};

export class DetailsModal extends Component<Props, any> {
  render() {
    const { date_of_birth, email, mobile } = this.props.profile;
    const { errors } = this.props;
    return (
      <PageDialog
        maxWidth={'xs'}
        open={this.props.isOpened}
        id={'user-details-modal'}
        onClose={this.props.closeModal}
      >
        <DialogTitle>User details</DialogTitle>
        <DialogContent>
          {errors.length > 0 && (
            <ErrorBox
              errors={this.props.errors}
              clearErrors={this.props.clearErrors}
            />
          )}
          <FormContainer maxColumns={1}>
            <FormItem label={'Gender'}>
              <Select
                name={'gender'}
                options={this.options}
                onChange={this.props.onDropDownChange}
                value={this.selectedValue}
              />
            </FormItem>
            <FormItem label={'Mobile phone'}>
              <Input
                name={'mobile'}
                value={mobile ? mobile : ''}
                htmlType={'tel'}
                onChange={(e) =>
                  this.props.changeInput('mobile', e.target.value)
                }
              />
            </FormItem>
            <FormItem label={'Email'}>
              <Input
                name={'email'}
                value={email ? email : ''}
                htmlType={'email'}
                onChange={(e) =>
                  this.props.changeInput('email', e.target.value)
                }
              />
            </FormItem>
            <FormItem label={'Date of birth'}>
              <DateInputMoment
                name={'dob'}
                value={moment.parseZone(
                  date_of_birth ? date_of_birth : undefined
                )}
                onChange={this.changeDOB}
              />
            </FormItem>
          </FormContainer>
        </DialogContent>
        <DialogActions>
          <PageDialogCancel onClick={this.props.closeModal} fullWidth={false}>
            Cancel
          </PageDialogCancel>
          <PageDialogSubmit
            onClick={this.props.saveProfile}
            disabled={this.props.saveDisabled}
            loading={this.props.isLoading}
            fullWidth={false}
          >
            Save
          </PageDialogSubmit>
        </DialogActions>
      </PageDialog>
    );
  }

  changeDOB = (value: null | Moment) => {
    const dob = value ? moment(value).format(SERVER_DAY_FORMAT) : value;
    this.props.changeInput('date_of_birth', dob);
  };

  get options() {
    return [
      {
        label: 'Male',
        value: 'male',
      },
      {
        label: 'Female',
        value: 'female',
      },
      {
        label: 'Not specified',
        value: 'not specified',
      },
    ];
  }

  get selectedValue() {
    const { gender } = this.props.profile;
    for (let option of this.options) {
      if (option.value === gender) {
        return option;
      }
    }
    return this.options[2];
  }
}
