import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { DefaultDivProps } from 'type';

export type ContainerProps = DefaultDivProps & {
  children: React.ReactNode;
  disableRight?: boolean;
  disableLeft?: boolean;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        paddingRight: theme.spacing(4.5),
        paddingLeft: theme.spacing(4.5),
      },
      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(5.5),
        paddingLeft: theme.spacing(5.5),
      },
    },
    disableRight: {
      paddingRight: 0,
      [theme.breakpoints.up('sm')]: {
        paddingRight: 0,
      },
      [theme.breakpoints.up('md')]: {
        paddingRight: 0,
      },
    },
    disableLeft: {
      paddingLeft: 0,
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 0,
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: 0,
      },
    },
  })
);

export function Container({
  children,
  disableRight = false,
  disableLeft = false,
  className,
  ...restProps
}: ContainerProps) {
  const classes = useStyles();

  return (
    <div
      {...restProps}
      className={clsx([
        classes.root,
        disableRight && classes.disableRight,
        disableLeft && classes.disableLeft,
        className,
      ])}
    >
      {children}
    </div>
  );
}
