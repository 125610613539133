import React, { Component } from 'react';
import { PaginationProps } from '../../Pagination/type';
import Pagination from '../../Pagination';

export type ListTablePaginationProps = {
  /** @var Passed from ListTable. Called to uncheck the Select All checkbox */
  onPageUpdate?: () => void;
} & PaginationProps;

class ListTablePagination extends Component<ListTablePaginationProps> {
  static displayName = 'ListTablePagination';

  onPageChange = (pageNumber: number, startItem: number, endItem: number) => {
    const { onPageChange, onPageUpdate } = this.props;
    if (onPageUpdate) {
      onPageUpdate(); // call ListTable to uncheck Select All checkbox
    }
    onPageChange(pageNumber, startItem, endItem);
  };

  onPageSizeChange = (pageSize: number) => {
    const { onPageSizeChange, onPageUpdate } = this.props;
    if (onPageUpdate) {
      onPageUpdate(); // call ListTable to uncheck Select All checkbox
    }
    onPageSizeChange(pageSize);
  };

  render() {
    return (
      <Pagination
        {...this.props}
        onPageChange={this.onPageChange}
        onPageSizeChange={this.onPageSizeChange}
      />
    );
  }
}

export default ListTablePagination;
