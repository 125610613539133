import React, { Component } from 'react';
import View from './view';
import { StoreState } from 'state/types';
import { BOX_USER_PROFILE_REQUEST } from 'state/Users/UserProfile/actions';
import { connect } from 'react-redux';
import { WithPreload } from 'element';
import { RouteComponentProps, withRouter } from 'react-router';

type StateProps = {
  isFetching: boolean;
};
type DispatchProps = {
  getUserProfile: (id: string) => void;
};
type TParams = {
  userId: string;
};
type Props = StateProps & DispatchProps & RouteComponentProps<TParams>;

class Profile extends Component<Props> {
  render() {
    const { params } = this.props.match;
    return (
      <>
        <WithPreload
          isFetching={this.props.isFetching}
          fetchData={this.props.getUserProfile}
          params={params}
        >
          <View />
        </WithPreload>
      </>
    );
  }
}

const mapStateToProps = ({
  userProfile: { isFetching }
}: StoreState): StateProps => ({
  isFetching
});

const mapDispatchToProps: DispatchProps = {
  getUserProfile: BOX_USER_PROFILE_REQUEST
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Profile)
);
