import { RosterShiftModalReducerState } from './types';
import moment from 'moment';

export const getDefaultState = (): RosterShiftModalReducerState => ({
  isUpdating: false,
  errors: [],
  usersLoader: false,
  rosteredShiftModal: {
    isLoading: false,
    isOpened: false,
    isUpdating: false,
    errors: []
  },
  overlapShiftModal: {
    isOpened: false,
    isUpdating: false,
    errors: []
  },
  usersList: [],
  eventsList: {},
  initialOwner: null,
  currentRosteredShift: {
    id: '',
    version: 0,
    start: moment(),
    end: moment(),
    breaks: [],
    user_id: null,
    role_id: '',
    area_id: '',
    duration: 0,
    author_id: '',
    roster_id: '',
    status: 'none' ,
    action: 'create',
    messages: 0,
    is_active: false,
    is_published: false,
    is_accepted: false,
    is_hidden_end_time: false,
    is_overlap_error: false,
    is_read: false,
    notify_user: false,
    need_reapply: false,
    tags: [],
    event_id: null,
    template_id: null,
    updated_at: moment().format('YYYY-MM-DD HH:mm:ss'),
    created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
    pay_entries: [],
    timesheet_id: null,
    need_re_accept: false,
    is_pasted: false,
    is_dst_intersect: false,
    notes: null
  }
});
