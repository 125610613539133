import { Box, Paper, Typography } from 'extended-oxygen-elements';
import { useSelectorWithProps } from 'hooks';
import React from 'react';
import { getArea } from 'state/AccountTree';
import AreaBtnAddRole from './AreaBtnAddRole';
import AreaBtnMoreOptions from './AreaBtnMoreOptions';
import ListAssignedRoles from './ListAssignedRoles';

type Props = {
  id: string;
  disabled: boolean;
};

export default function TileArea({ id, disabled: propsDisabled }: Props) {
  const area = useSelectorWithProps(getArea, id);
  const disabled = area.archived || propsDisabled;

  return (
    <Paper
      component="section"
      variant="elevation"
      elevation={3}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minHeight: '220px',
        padding: 2,
        opacity: disabled ? 0.5 : 1,
      }}
      aria-label={area.name}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" data-testid={'area-name'}>
          {area.name}
        </Typography>
        <AreaBtnMoreOptions area_id={area.id} />
      </Box>
      <Box flex="1" pt={1} pb={1}>
        <ListAssignedRoles
          area_id={area.id}
          site_id={area.site_id}
          disabled={disabled}
        />
      </Box>
      {!disabled && (
        <Box>
          <AreaBtnAddRole area_id={area.id} />
        </Box>
      )}
    </Paper>
  );
}
