import { createSelector } from 'reselect';
import { Moment } from 'moment';
import { differenceBy, filter } from 'lodash';
import { orderBy } from 'natural-orderby';
import {
  EmployeeSettingsSorting,
  RosteredShift,
  RosterTimeOff,
  StringMap,
  Timesheet,
  UserFields,
} from 'type';
import { withoutAssignedDataCreator } from 'state/helpers';
import { getEmployeeSettingsSorting } from 'state/Account';
import {
  fromBySiteTimezoneSelector,
  rosteredShiftsByUserIdSelector,
} from 'state/RosteredShifts';
import { timeOffsByUserIdSelector } from 'state/TimeOffs/selectors';
import { timesheetsByUserIdSelector } from 'state/TimesheetsCollection/selectors';
import {
  filteredRosteredShiftsByUserIdSelector,
  filteredTimesheetsByUserIdSelector,
  getFilters,
  usersFilteredArraySelector,
} from 'state/Roster/RosterFilters';
import { currentTimeBySiteTimezoneSelector } from 'state/Roster/Roster';
import { RosterDayViewCreatedShiftData, UserRosterData } from '../../types';
import { groupedByRowsCombiner } from '../../combiners';
import { calculateDuration, getHasRosteredShiftsWithEvents } from '../helpers';
import { getCreatedShiftData } from '../simpleSelectors';

const filteredUsersOfSiteSelector = createSelector(
  usersFilteredArraySelector,
  rosteredShiftsByUserIdSelector,
  timesheetsByUserIdSelector,
  timeOffsByUserIdSelector,
  getFilters,
  (
    usersOfSite: UserFields[],
    rosteredShiftsByUserId,
    timesheetsByUserId,
    timeOffsByUserId,
    allFilters
  ): UserFields[] => {
    const isArchived = ({ is_active }: UserFields): boolean => !is_active;
    const archivedUsers: UserFields[] = !allFilters.showArchivedUsers
      ? filter(usersOfSite, (user: UserFields) => isArchived(user))
      : usersOfSite;

    const filteredUsers = differenceBy(
      usersOfSite,
      archivedUsers,
      ({ id }: UserFields) => id
    );

    return filteredUsers.length ? filteredUsers : usersOfSite;
  }
);

const sortedFilteredUsersOfSiteSelector = createSelector(
  filteredUsersOfSiteSelector,
  getEmployeeSettingsSorting,
  (users: UserFields[], sorting: EmployeeSettingsSorting): UserFields[] => {
    const rateSorting = ({ rate }: UserFields): number => {
      const rateNum: number = parseFloat(rate);

      if (isNaN(rateNum)) {
        return 0;
      }

      return rateNum;
    };

    const preferedNameSorting = (user: UserFields): string =>
      user.prefered_or_full_name;

    const sortingIdentifiers =
      sorting === 'rating'
        ? [rateSorting, preferedNameSorting]
        : [preferedNameSorting];

    return orderBy(users, sortingIdentifiers, 'asc');
  }
);

export const rosterDataByUserSelector = createSelector(
  sortedFilteredUsersOfSiteSelector,
  timeOffsByUserIdSelector,
  filteredRosteredShiftsByUserIdSelector,
  getCreatedShiftData,
  filteredTimesheetsByUserIdSelector,
  fromBySiteTimezoneSelector,
  currentTimeBySiteTimezoneSelector,
  getFilters,
  (
    filteredUsersOfSite: UserFields[],
    rosterTimeOffsByUserId: StringMap<RosterTimeOff[]>,
    filteredRosteredShiftsByUserId: StringMap<RosteredShift[]>,
    createdShiftData: RosterDayViewCreatedShiftData,
    filteredTimesheetsByUserId: StringMap<Timesheet[]>,
    from: Moment,
    currentTime: Moment,
    allFilters
  ): UserRosterData[] => {
    // if unavailability/leave -> show only approved
    const filterApprovedTimeoffs = (timeOffs: RosterTimeOff[]) => {
      return filter(timeOffs, (timeOff) => {
        if (timeOff.type === 'unavailability' || timeOff.type === 'leave') {
          return timeOff.status === 'approved';
        }
        return true;
      });
    };

    const config: UserRosterData[] = [];

    for (let i = 0; i < filteredUsersOfSite.length; i += 1) {
      const user = filteredUsersOfSite[i];
      const userId = user.id;

      const unavailability =
        filterApprovedTimeoffs(rosterTimeOffsByUserId[userId]) || [];
      const rosterData = filteredRosteredShiftsByUserId[userId] || [];
      const hasRosteredShiftsWithEvents =
        getHasRosteredShiftsWithEvents(rosterData);

      const timesheetsData = filteredTimesheetsByUserId[userId] || [];
      const timesheetsGroupedByRow = groupedByRowsCombiner(
        timesheetsData,
        currentTime
      );

      const durationRoster = calculateDuration(rosterData, from);
      const durationTimesheet = calculateDuration(timesheetsData, from);

      const userData = {
        user,
        data: {
          unavailability,
          roster: {
            data: rosterData,
            hasShiftsWithEvents: hasRosteredShiftsWithEvents,
          },
          timesheets: timesheetsGroupedByRow,
        },
        duration: {
          roster: durationRoster,
          timesheets: durationTimesheet,
        },
      };

      if (allFilters.showPeople === 'with_shifts' && rosterData.length) {
        config.push(userData);
      }

      if (allFilters.showPeople === 'without_shifts' && !rosterData.length) {
        config.push(userData);
      }

      if (allFilters.showPeople === 'all') {
        config.push(userData);
      }
    }
    return config;
  }
);
