import { BOX_MAIN_MENU_TIMECLOCK_ITEM_CONFIRMATION_OPENED } from '../../state/MainMenuHelpItem';
import { useDispatch } from 'react-redux';
import { useRosterMenuItem } from '../../layout/PrivateLayout/RosterMenu/useRosterMenu/hooks';

export function TimeClock() {
  const dispatch = useDispatch();
  const rosterMenuItem = useRosterMenuItem();
  dispatch(BOX_MAIN_MENU_TIMECLOCK_ITEM_CONFIRMATION_OPENED());
  rosterMenuItem.handleClick();
  return null;
}
export default TimeClock;
