import { createSelector } from 'reselect';
import moment from 'moment';
import { filter } from 'lodash';
import { UserFields, UserRole } from 'type';
import { StoreState } from 'state/types';
import { currentSiteTimezoneCombiner } from 'state/combiners';
import {
  activeUsersSelector,
  userListSelector
} from 'state/UsersCollection';
import * as isLoading from 'state/IsLoading';
import {
  activeAreasBySiteIdSelector,
  activeRolesSelector, activeSitesSelector,
  getSites
} from 'state/AccountTree';
import { ShiftTimeRangeInputReducerState } from '../types';

const getState = (state: StoreState): ShiftTimeRangeInputReducerState =>
  state.rangeInput.shiftTimeRangeInput;

export const getUserId = (state: StoreState) => getState(state).user_id;
export const getDayTimestamp = (state: StoreState) =>
  getState(state).dayTimestamp;
export const getSiteId = (state: StoreState) => getState(state).site_id;
export const getAreaId = (state: StoreState) => getState(state).area_id;
export const getRoleId = (state: StoreState) => getState(state).role_id;
export const getType = (state: StoreState) => getState(state).type;
export const getRowIndex = (state: StoreState) => getState(state).rowIndex;

export const getIsLoading = (state: StoreState) =>
  isLoading.getIsLoading(state, isLoading.ROSTER_SHIFT_INPUT);

export const siteTimezoneSelector = createSelector(
  getSiteId,
  getSites,
  currentSiteTimezoneCombiner
);

export const daySelector = createSelector(
  getDayTimestamp,
  siteTimezoneSelector,
  (dayTimestamp, siteTimezone) => moment(+dayTimestamp).tz(siteTimezone)
);

export const getRolesBySiteSelector = createSelector(
  activeSitesSelector,
  getSiteId,
  activeAreasBySiteIdSelector,
  activeRolesSelector,
  (sites, siteId: string, areas, roles): UserRole[] => {
    return areas[siteId].flatMap((area) => {
      return area.role_ids.flatMap((roleId) => {
        return {
          site_id: siteId,
          area_id: area.id,
          role_id: roleId,
          is_main: false,
          site_name: sites[siteId] ? sites[siteId].name : '',
          area_name: area.name,
          role_name: roles[roleId].name
        }
      });
    });

  }
);

export const userRolesSelector = createSelector(
  getUserId,
  activeAreasBySiteIdSelector,
  getRolesBySiteSelector,
  userListSelector,
  (userId, areas,  roles, userList): UserRole[] => {
    const user: UserFields | undefined = userList[userId as string];

    if (userId === null) {
      return roles;
    }

    if (!user) {
      return [];
    }

    return user.user_roles;
  }
);

export const siteUserRolesSelector = createSelector(
  userRolesSelector,
  getSiteId,
  (userRoles, siteId) => userRoles.filter(({ site_id }) => site_id === siteId)
);

export const usersSelector = createSelector(
  getSiteId,
  getAreaId,
  getRoleId,
  activeUsersSelector,
  (siteId, areaId, roleId, userList) =>
    filter(userList, ({ user_roles }: UserFields) =>
      user_roles.some(
        ({ site_id, area_id, role_id }) =>
          site_id === siteId && area_id === areaId && role_id === roleId
      )
    )
);