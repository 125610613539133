import React from 'react';
import Creatable, {
  default as ReactSelect,
} from 'react-select';
import { SelectProps } from './type';
import { getClass } from '../_lib/helper';
import './Select.scss';

function Select({
  id,
  name,
  className,
  options,
  isMulti,
  isClearable,
  value,
  defaultValue,
  isDisabled,
  isCreatable,
  onBlur,
  onFocus,
  onChange,
  onInputChange,
  onMenuScrollToBottom,
  hideSelectedOptions,
  closeMenuOnSelect,
  placeholder,
  ariaLabel,
  ariaLabelledby,
  filterOption,
  isInline,
}: SelectProps) {
  let returnCloseMenuOnSelect = closeMenuOnSelect
    ? closeMenuOnSelect
    : !isMulti;

  /* istanbul ignore next */
  const menuPortalStyles = {
    menuPortal: (base: any) => ({ ...base, zIndex: 1001 }),
  };

  const portalProps = {
    menuPortalTarget: document.querySelector('body'),
    styles: menuPortalStyles,
  };

  const props = {
    className: 'react-select-container',
    classNamePrefix: 'react-select',
    closeMenuOnSelect: returnCloseMenuOnSelect,
    name,
    options,
    isMulti,
    isClearable,
    value,
    defaultValue,
    onChange,
    onInputChange,
    onMenuScrollToBottom,
    onBlur,
    onFocus,
    isDisabled,
    hideSelectedOptions,
    placeholder,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledby,
    filterOption: filterOption,
    ...(!(isInline || isInline === undefined) ? portalProps : {}),
  };

  return (
    <div
      id={id}
      data-testid={`elmo-select-${id || 'default'}`}
      className={getClass('elmo-select', className)}
    >
      {isCreatable ? (
        <Creatable {...props} />
      ) : (
        <ReactSelect {...props} />
      )}
    </div>
  );
}

Select.defaultProps = {
  isMulti: false,
  isClearable: false,
  hideSelectedOptions: false,
};

export default Select;
