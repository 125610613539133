import React from 'react';
import { connect } from 'react-redux';
import { Button, NativeTable, Text } from 'elmo-elements';
import { EditIcon } from 'element/icons';
import { StoreState } from 'state/types';
import { AccountTreeSelector, LanguagePreferences, RoleTriple } from 'type';
import { capitalize } from 'lib/helpers';
import { getLangPreferences } from 'state/Account';
import { getAccountTree } from 'state/AccountTree';

type OwnProps = {
  selectedRoleTriples: RoleTriple[] | null;
  openFilterByRoleModal: () => void;
  id?: string;
  isSiteNameShown?: boolean; // TODO refactor
};

type StateProps = {
  langPreferences: LanguagePreferences;
  tree: AccountTreeSelector;
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

const { Header, Th, Td, Body, Tr } = NativeTable;

const RoleTripleTableComponent = ({
  openFilterByRoleModal,
  selectedRoleTriples,
  langPreferences,
  tree,
  id,
  isSiteNameShown = true
}: Props) => {
  const handleButtonClick = () => {
    openFilterByRoleModal();
  };

  const renderTripleName = (names: string[]) => {
    if (isSiteNameShown) {
      return names.join('/');
    }

    const [, ...restNames] = names;
    return restNames.join('/');
  };

  return (
    <NativeTable isFullWidth={true} id={id}>
      <Header>
        <Tr>
          <Th className="elmo-nativetable-th--has-btn-custom">
            <Button
              className="elmo-nativetable__btn-custom"
              icon={<EditIcon />}
              onClick={handleButtonClick}
              ariaLabel="Edit"
            />
            Filtered by
          </Th>
        </Tr>
      </Header>

      <Body>
        {!selectedRoleTriples ? (
          <Tr>
            <Td>
              <Text color="gray">
                {renderTripleName([
                  capitalize(langPreferences.site.singular),
                  capitalize(langPreferences.area.singular),
                  capitalize(langPreferences.role.singular)
                ])}
              </Text>
            </Td>
          </Tr>
        ) : (
          selectedRoleTriples.map(({ siteId, areaId, roleId }: RoleTriple) => {
            const site = tree.sites[siteId];
            const area = tree.areas[areaId];
            const role = tree.roles[roleId];

            if (!site || !area || !role) {
              return null;
            }

            return (
              <Tr key={`${siteId}/${areaId}/${roleId}`}>
                <Td>{renderTripleName([site.name, area.name, role.name])}</Td>
              </Tr>
            );
          })
        )}
      </Body>
    </NativeTable>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  langPreferences: getLangPreferences(state),
  tree: getAccountTree(state)
});

// const mapDispatchToProps: DispatchProps = {};

export const RoleTripleTable = connect(mapStateToProps)(
  RoleTripleTableComponent
);
