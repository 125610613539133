import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'elmo-elements';
import { StoreState } from '../../../../state/types';
import {
  BOX_NOTIFICATIONS_LIST_DELETE_ALL,
  BOX_NOTIFICATIONS_LIST_READ_ALL
} from '../../../../state/NotificationsList/actions';
import { NotificationListType } from 'state/NotificationsList/types';
import './BulkActions.scss';

type OwnProps = {
  type: NotificationListType;
  unreadCount: number;
  total: number;
};

type StateProps = {
  isUpdating: boolean;
};

type DispatchProps = {
  readAll: (type: NotificationListType) => void;
  deleteAll: (type: NotificationListType) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

export class BulkActions extends Component<Props> {

  render() {
    const { total } = this.props;
    return total > 0 ? (
      <div className={'notifications-list__bulk-actions'}>
        {this.showReadAll()}
        {this.showDeleteAll()}
      </div>
    ) : null;
  }

  showReadAll = () => {
    const { unreadCount, isUpdating } = this.props;
    return unreadCount > 0
      ? <Button
          id={'read-all'}
          isUppercase={false}
          isText={true}
          isDisabled={isUpdating}
          isLoading={isUpdating}
          onClick={this.readAll}
        >
          Mark all as read
        </Button>
      : null;
  };

  showDeleteAll = () => {
    const { unreadCount, isUpdating } = this.props;
    return unreadCount === 0
      ? <Button
          id={'delete-all'}
          isUppercase={false}
          isText={true}
          isDisabled={isUpdating}
          isLoading={isUpdating}
          onClick={this.deleteAll}
        >
          Delete all
        </Button>
      : null;
  };

  readAll = () => {
    this.props.readAll(this.props.type);
  };

  deleteAll = () => {
    this.props.deleteAll(this.props.type);
  };

}

const mapToDispatchProps = {
  readAll: BOX_NOTIFICATIONS_LIST_READ_ALL,
  deleteAll: BOX_NOTIFICATIONS_LIST_DELETE_ALL
};

const mapStateToProps = (state: StoreState): StateProps => ({
  isUpdating: state.notificationsList.isUpdating
});

export default connect(mapStateToProps, mapToDispatchProps)(BulkActions);