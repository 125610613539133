import moment, { Moment } from 'moment';
import {
  ApiClientResponse,
  AwardsList,
  BackendPager,
  DashboardShift,
  DashboardShiftBreak,
  Event,
  HigherDutiesItem,
  Omit,
  PayEntry,
  Report,
  ReportSchedule,
  RosteredShift,
  RosterRepeatableTimeOff,
  ServerEvent,
  ServerPayEntry,
  ServerReport,
  ServerReportSchedule,
  ServerRosteredShift,
  ServerRosterRepeatableTimeOff,
  ServerShift,
  ServerShiftOffer,
  ServerShiftOfferWithProposals,
  ServerTimeOff,
  ServerTimesheetBreak,
  ServerTimesheetPayEntry,
  ShiftBreak,
  ShiftOffer,
  ShiftOfferWithProposals,
  ShiftTrade,
  ShiftTradeProposal,
  ShiftTradesList,
  TimeOff,
  TimesheetBreak,
} from 'type';
import {
  SERVER_DATE_TIME_FORMAT,
  SERVER_DAY_FORMAT,
  SERVER_TIME_FORMAT,
  SERVER_TIME_S_FORMAT,
} from 'lib/config';
import {
  CheckPastingStatusResponse,
  EmployeeDashboardGetMyTimesheetPayload,
  EventCreateRequest,
  EventCreateServerRequest,
  EventsGetRequest,
  EventsGetServerRequest,
  PayEntriesGetListRequest,
  PayEntriesGetListServerRequest,
  RosterCheckPastingStatusRequest,
  RosterCheckPastingStatusServerRequest,
  RosterDataServerRequest,
  RosteredShiftCreateRequest,
  RosteredShiftCreateServerRequest,
  RosterPasteWeekRequest,
  RosterPasteWeekServerRequest,
  RostersDataRequest,
  TimeOffsCreateTimeOffRequest,
  TimeOffsCreateTimeOffServerRequest,
  WithPagerResponse,
  WithPagerServerResponse,
} from './type';
import {
  EmployeeUnavailabilityProps,
  EmployeeUnavailabilityResponse,
  TimeOffRosteredShift,
} from '../../state/EmployeeDashboard/Unavailability/types';
import _ from 'lodash';
import { AssignedAward } from '../../state/Users/UserProfile/types';

export const updateResponse =
  <P, R>(updateData: (payload: P) => R) =>
  ({ data, warnings }: ApiClientResponse<P>): ApiClientResponse<R> => ({
    data: updateData(data),
    warnings,
  });

const updateReportSchedule = ({
  time,
  from,
  to,
  ...rest
}: ServerReportSchedule): ReportSchedule => ({
  ...rest,
  time: moment.utc(time, SERVER_TIME_S_FORMAT),
  from: moment.utc(from),
  to: to ? moment.utc(to) : null
});

export const updateReportSchedulePayload = ({
  time,
  from,
  to,
  ...rest
}: ReportSchedule): ServerReportSchedule => ({
  ...rest,
  time: time.format(SERVER_TIME_FORMAT),
  from: from.format(SERVER_DAY_FORMAT),
  to: to ? to.format(SERVER_DAY_FORMAT) : null
});

export const updateAwardEffectiveDate = ({ effective_date, ...rest }: AssignedAward) => {
  return {
    ...rest,
    effective_date: moment.parseZone(effective_date)
  }
};

export const updateEffectiveDate = (data: AwardsList) => {
  return data.map(item => {
    item.effective_date = moment.parseZone(item.effective_date);
    return item;
  });
};

export const updateReport = ({
  schedule,
  updated_at,
  created_at,
  ...rest
}: ServerReport): Report => {
  return {
    ...rest,
    updated_at: moment.parseZone(updated_at),
    created_at: moment.parseZone(created_at),
    schedule: schedule ? updateReportSchedule(schedule) : null
  };
};

const updateShiftBreak = <T extends { start: string }>({
  start,
  ...rest
}: T): Omit<T, 'start'> & {
  start: Moment;
} => ({
  ...rest,
  start: moment.parseZone(start)
});

const updateShiftBreakPayload = <T extends { start: Moment }>({
  start,
  ...rest
}: T): Omit<T, 'start'> & {
  start: string;
} => ({
  ...rest,
  start: start.format(SERVER_DATE_TIME_FORMAT)
});

export const updateCreateRosteredShiftPayload = <
  T extends RosteredShiftCreateRequest,
  R extends RosteredShiftCreateServerRequest
>({
  start,
  end,
  breaks,
  ...rest
}: RosteredShiftCreateRequest): RosteredShiftCreateServerRequest => ({
  ...rest,
  start: start.format(SERVER_DATE_TIME_FORMAT),
  end: end.format(SERVER_DATE_TIME_FORMAT),
  breaks: !breaks ? null : breaks.map(updateShiftBreakPayload)
});

export const updateRosteredShift = ({
  start,
  end,
  breaks,
  tags,
  is_active,
  is_published,
  is_accepted,
  is_hidden_end_time,
  is_overlap_error,
  is_read,
  notify_user,
  need_reapply,
  ...rest
}: ServerRosteredShift): RosteredShift => ({
  ...rest,
  start: moment.parseZone(start),
  end: moment.parseZone(end),
  breaks: breaks.map(updateShiftBreak),
  tags: tags ? tags : [],
  is_active: !!is_active,
  is_published: !!is_published,
  is_accepted: !!is_accepted,
  is_hidden_end_time: !!is_hidden_end_time,
  is_overlap_error: !!is_overlap_error,
  is_read: !!is_read,
  notify_user: !!notify_user,
  need_reapply: !!need_reapply
});

export const updateCreateTimesheetPayload = <
  P extends {
    start: Moment;
    end: Moment;
    breaks: TimesheetBreak[] | null;
  }
>({
  start,
  end,
  breaks,
  ...rest
}: P) => ({
  ...rest,
  id: undefined,
  start: start.format(SERVER_DATE_TIME_FORMAT),
  end: end.format(SERVER_DATE_TIME_FORMAT),
  breaks: !breaks ? null : breaks.map(updateShiftBreakPayload)
});

export const updateTimesheetPayload = <
  P extends {
    start: Moment;
    end: Moment;
    breaks: TimesheetBreak[] | null;
    higher_duty?: null | HigherDutiesItem;
    higher_duty_start?: Moment | null;
    higher_duty_end?: Moment | null;
  }
>({
  start,
  end,
  breaks,
  higher_duty,
  higher_duty_start,
  higher_duty_end,
  ...rest
}: P) => {
  const duties =
    typeof higher_duty === 'undefined'
      ? {}
      : {
          higher_duty: !higher_duty ? null : higher_duty.id,
          higher_duty_start: !higher_duty_start
            ? null
            : higher_duty_start.format(SERVER_DATE_TIME_FORMAT),
          higher_duty_end: !higher_duty_end
            ? null
            : higher_duty_end.format(SERVER_DATE_TIME_FORMAT)
        };
  return {
    ...rest,
    start: start.format(SERVER_DATE_TIME_FORMAT),
    end: end.format(SERVER_DATE_TIME_FORMAT),
    breaks: !breaks ? null : breaks.map(updateShiftBreakPayload),
    ...duties
  };
};

export const updateTimesheet = <
  T extends {
    start: string;
    end: string | null;
    breaks: ServerTimesheetBreak[];
    higher_duty_end: string | null;
    higher_duty_start: string | null;
    pay_entries?: ServerTimesheetPayEntry[];
  }
>({
  start,
  end,
  breaks,
  higher_duty_end,
  higher_duty_start,
  pay_entries,
  ...rest
}: T): Omit<
  T,
  | 'start'
  | 'end'
  | 'breaks'
  | 'higher_duty_start'
  | 'higher_duty_end'
  | 'pay_entries'
> & {
  start: Moment;
  end: Moment | null;
  breaks: any;
  higher_duty_end: Moment | null;
  higher_duty_start: Moment | null;
  pay_entries: ServerTimesheetPayEntry[];
} => {
  return {
    ...rest,
    pay_entries: pay_entries ? pay_entries : ([] as ServerTimesheetPayEntry[]),
    start: moment.parseZone(start),
    end: end ? moment.parseZone(end) : null,
    breaks: breaks.map(updateShiftBreak),
    higher_duty_end: higher_duty_end ? moment.parseZone(higher_duty_end) : null,
    higher_duty_start: higher_duty_start
      ? moment.parseZone(higher_duty_start)
      : null
  };
};

export const updateShiftOffer = ({
  shift_start,
  shift_end,
  offer_start,
  ...rest
}: ServerShiftOffer): ShiftOffer => ({
  ...rest,
  shift_start: moment.parseZone(shift_start),
  shift_end: moment.parseZone(shift_end),
  offer_start: moment.parseZone(offer_start)
});

export const updateShiftOfferWithProposals = ({
  shift_start,
  shift_end,
  offer_start,
  ...rest
}: ServerShiftOfferWithProposals): ShiftOfferWithProposals => ({
  ...rest,
  shift_start: moment.parseZone(shift_start),
  shift_end: moment.parseZone(shift_end),
  offer_start: moment.parseZone(offer_start)
});

export const updateTimeOff = ({
  start,
  end,
  created_at,
  updated_at,
  until,
  ...rest
}: ServerTimeOff): TimeOff => ({
  ...rest,
  start: moment.parseZone(start),
  end: moment.parseZone(end),
  created_at: moment.parseZone(created_at),
  updated_at: moment.parseZone(updated_at),
  until: until ? moment.parseZone(until) : null
});

export const updateRosterTimeOff = ({
  start,
  end,
  until,
  ...rest
}: ServerRosterRepeatableTimeOff): RosterRepeatableTimeOff => ({
  ...rest,
  until: until ? moment.parseZone(until) : null,
  start: moment.parseZone(start),
  end: moment.parseZone(end)
});

export const updateCreateTimeOffPayload = ({
  start,
  end,
  until,
  ...restPayload
}: TimeOffsCreateTimeOffRequest): TimeOffsCreateTimeOffServerRequest => {
  return {
    ...restPayload,
    start: start.format(SERVER_DATE_TIME_FORMAT),
    end: end.format(SERVER_DATE_TIME_FORMAT),
    until: until ? until.format(SERVER_DATE_TIME_FORMAT) : until
  };
};

export const makeBackendPager = ({
  total,
  per_page,
  current_page,
}: Pick<
  WithPagerServerResponse<any>,
  'total' | 'per_page' | 'current_page'
>): BackendPager => ({
  total,
  pageSize: Number(per_page),
  currentPage: current_page,
});

export const updateWithPagerResponse = <T, R>(
  updateDataHelper: (initItem: T) => R
) => ({
  data,
  ...rest
}: WithPagerServerResponse<T>): WithPagerResponse<R> => ({
  data: data.map(updateDataHelper),
  pager: makeBackendPager(rest)
});

export const updateWorkingShift = ({
  start,
  end,
  timesheet_start,
  timesheet_end,
  published_at,
  ...rest
}: ServerShift): DashboardShift => ({
  ...rest,
  start: moment.parseZone(start),
  end: moment.parseZone(end),
  timesheet_start: timesheet_start
    ? moment.parseZone(timesheet_start)
    : undefined,
  timesheet_end: timesheet_end ? moment.parseZone(timesheet_end) : undefined,
  published_at: published_at ? moment.parseZone(published_at) : undefined
});

export const updateTimeSheet = ({
  start,
  end,
  timesheet_start,
  timesheet_end,
  published_at,
  id,
  timesheet_id,
  breaks,
  ...rest
}: ServerShift): DashboardShift => ({
  ...rest,
  start: moment.parseZone(start),
  end: end !== null ? moment.parseZone(end) : end,
  timesheet_start: start ? moment.parseZone(start) : undefined,
  timesheet_end: end ? moment.parseZone(end) : undefined,
  published_at: published_at ? moment.parseZone(published_at) : undefined,
  id,
  timesheet_id: id,
  breaks: breaks.map((b: DashboardShiftBreak) => {
    b.start = moment.parseZone(b.start);
    return b;
  })
});

export const updateMyRoster = ({
  start,
  end,
  timesheet_start,
  timesheet_end,
  published_at,
  ...rest
}: ServerShift): DashboardShift => ({
  ...rest,
  start: moment.parseZone(start),
  end: moment.parseZone(end),
  published_at: moment.parseZone(published_at),
  timesheet_start: timesheet_start
    ? moment.parseZone(timesheet_start)
    : undefined,
  timesheet_end: timesheet_end ? moment.parseZone(timesheet_end) : undefined
});

export const updateGetRosterDataPayload = ({
  from,
  to,
  ...rest
}: RostersDataRequest): RosterDataServerRequest => {
  return {
    from: from.format(SERVER_DATE_TIME_FORMAT),
    to: to.format(SERVER_DATE_TIME_FORMAT),
    ...rest
  };
};

export const updateEventsGetPayload = ({
  from,
  to,
  ...rest
}: EventsGetRequest): EventsGetServerRequest => ({
  ...rest,
  from: from.format(SERVER_DATE_TIME_FORMAT),
  to: to.format(SERVER_DATE_TIME_FORMAT)
});

export const updateEvent = ({
  start,
  end,
  title,
  ...rest
}: ServerEvent): Event => ({
  ...rest,
  name: title,
  start: moment.parseZone(start),
  end: moment.parseZone(end)
});

export const updateCreateEventShiftPayload = <
  T extends EventCreateRequest,
  R extends EventCreateServerRequest
>({
  start,
  end,
  name,
  ...rest
}: EventCreateRequest): EventCreateServerRequest => ({
  ...rest,
  start: start.format(SERVER_DATE_TIME_FORMAT),
  end: end.format(SERVER_DATE_TIME_FORMAT),
  title: name
});

export const updateShiftTrades = (trades: ShiftTradesList): ShiftTradesList => {
  trades.forEach((trade: ShiftTrade) => {
    if (trade.rostered_shift !== null) {
      const { breaks } = trade.rostered_shift;
      trade.rostered_shift.start = moment.parseZone(trade.rostered_shift.start);
      trade.rostered_shift.end = moment.parseZone(trade.rostered_shift.end);
      if (breaks) {
        breaks.forEach((b: ShiftBreak) => {
          b.start = moment.parseZone(b.start);
        });
      }
    }
    if (trade.proposals && trade.proposals.length) {
      trade.proposals.forEach((proposal: ShiftTradeProposal) => {
        if (proposal.rostered_shift !== null) {
          proposal.rostered_shift.start = moment.parseZone(
            proposal.rostered_shift.start
          );
          proposal.rostered_shift.end = moment.parseZone(
            proposal.rostered_shift.end
          );
        }
      });
    }
  });
  return trades;
};

export const updateEmployeeDashboardGetMyTimesheetPayload = ({
  period,
  ...rest
}: EmployeeDashboardGetMyTimesheetPayload) => ({
  ...rest,
  period: {
    ...period,
    from: period.from ? period.from.format(SERVER_DATE_TIME_FORMAT) : null,
    to: period.to ? period.to.format(SERVER_DATE_TIME_FORMAT) : null
  }
});

const momentIgnoreTz = (date: string | Moment) => {
  return moment.parseZone(date);
};

export const updateEmployeeTimeOffRosteredShift = (
  shift: TimeOffRosteredShift
) => {
  shift.start = momentIgnoreTz(shift.start);
  shift.end = momentIgnoreTz(shift.end);
  shift.type = 'rostered_shift';
  shift.status = '';
  return shift;
};

export const updateEmployeeUnavailabilities = (
  data: EmployeeUnavailabilityResponse
) => {
  const { time_offs, rostered_shifts } = data;
  time_offs.forEach((prop: EmployeeUnavailabilityProps) => {
    prop.start = momentIgnoreTz(prop.start);
    prop.end = momentIgnoreTz(prop.end);
    prop.until = prop.until !== null ? momentIgnoreTz(prop.until) : null;
  });
  rostered_shifts.forEach((shift: TimeOffRosteredShift) => {
    updateEmployeeTimeOffRosteredShift(shift);
  });
  return data;
};

export const updateEmployeeUnavailability = (
  data: EmployeeUnavailabilityProps
) => {
  data.start = moment(data.start).tz(data.site.timezone_id);
  data.end = moment(data.end).tz(data.site.timezone_id);
  data.until =
    data.until !== null ? moment(data.until).tz(data.site.timezone_id) : null;
  return data;
};

export const updateEmployeeUnavailabilityOnce = (
  data: EmployeeUnavailabilityProps
) => {
  data.start = moment.parseZone(data.start);
  data.end = moment.parseZone(data.end);
  data.until = data.until !== null ? moment.parseZone(data.until) : null;
  return data;
};

export const updatePayEntriesGetListPayload = ({
  period: { period, from, to },
  ...restPayload
}: PayEntriesGetListRequest): PayEntriesGetListServerRequest => ({
  ...restPayload,
  period: {
    period,
    from: from ? from.format(SERVER_DAY_FORMAT) : null,
    to: to ? to.format(SERVER_DAY_FORMAT) : null
  }
});

export const updatePayEntry = ({
  date,
  ...restPayEntry
}: ServerPayEntry): PayEntry => ({
  ...restPayEntry,
  date: moment.parseZone(date)
});

export const isNextDay = (start: Moment, end: Moment) => {
  const startTime = moment.parseZone(start).format('YYYY-MM-DD HH:mm');
  const endTime = moment.parseZone(end).format('YYYY-MM-DD HH:mm');
  return startTime === endTime;
};

export const dateInServerFormat = (
  date: Moment,
  addOneDay: boolean = false
) => {
  if (!addOneDay) {
    return moment(date).format('Y-MM-DD HH:mm');
  }
  return moment(date)
    .add(1, 'day')
    .format('Y-MM-DD HH:mm');
};

export const updatePostRosterPayload = ({
  range: { from, to },
  target,
  ...restPayload
}: RosterPasteWeekRequest): RosterPasteWeekServerRequest => ({
  ...restPayload,
  range: {
    from: from.format(SERVER_DAY_FORMAT),
    to: to.format(SERVER_DAY_FORMAT)
  },
  target: target.format(SERVER_DAY_FORMAT)
});

export const updateCheckPastingStatus = ({
  from,
  to,
  ...restPayload
}: RosterCheckPastingStatusRequest): RosterCheckPastingStatusServerRequest => ({
  ...restPayload,
  from: from.format(SERVER_DAY_FORMAT),
  to: to.format(SERVER_DAY_FORMAT)
});

export const updateCheckPastingResponse = (
  data: CheckPastingStatusResponse
): string[] => {
  return _.map(data.filter(job => job.status === 'processing'), 'task_id');
};
