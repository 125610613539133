import React from 'react';
import { getClass } from '../../_lib/helper';
import './RadioWrapper.scss';

function RadioWrapper({
  children,
  checked,
  disabled,
  onKeyUp,
  id,
  className,
}: any) {
  const radioWrapperClassNames = getClass('elmo-radio', className, {
    checked: checked,
    disabled: disabled,
  });

  return (
    <label
      id={id}
      className={radioWrapperClassNames}
      aria-checked={checked}
      tabIndex={0}
      onKeyUp={onKeyUp}
      data-testid={`elmo-radio-${id || 'default'}`}
    >
      {children}
    </label>
  );
}

export default RadioWrapper;
