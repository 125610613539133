import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Event, SelectPropsOption } from 'type';
import { StoreState } from 'state/types';
import { asSelectOptionCombiner } from 'state/combiners';
import {
  BOX_ROSTER_FILTERS_UPDATE_FILTER_FORM,
  BOX_ROSTER_FILTERS_UPDATE_FILTER_FORM_FIELD,
  filtersFormSelector,
  getFiltersFormRoleFilterType,
  ROSTER_FILTER_WITHOUT,
} from 'state/Roster/RosterFilters';
import { getLangPreferences } from 'state/Account';
import { eventsArraySelector } from 'state/Events';
import { hasPermissionSelector } from 'state/Auth';
import View, { Props as ViewProps } from './view';
import { getIsCovidModeEnabled } from '../../../../../../../../state/Roster/CovidTracing';

type OwnProps = Pick<ViewProps, 'onResetBtnClick' | 'isResetDisabled'>;

type StateProps = Pick<
  ViewProps,
  | 'formData'
  | 'langPreferences'
  | 'canViewTimesheets'
  | 'canViewRosteredShifts'
  | 'selectedFilterType'
> & {
  events: Event[];
  hasEventsPermission: boolean;
  isCovidMode: boolean;
};

type DispatchProps = Pick<ViewProps, 'onChange' | 'changeFilterForm'>;

type Props = OwnProps & StateProps & DispatchProps;

export class CustomFiltersComponent extends Component<Props> {
  render() {
    const { events, isCovidMode, ...viewProps } = this.props;

    return (
      <View
        {...viewProps}
        events={{
          options: this.eventsSelectOptionsWithWithout,
          isVisible: !!this.eventsSelectOptions.length && !isCovidMode
        }}
        canViewEvents={this.props.hasEventsPermission}
      />
    );
  }

  private get eventsSelectOptions(): SelectPropsOption[] {
    return this.props.events.map(asSelectOptionCombiner);
  }

  private get eventsSelectOptionsWithWithout(): SelectPropsOption[] {
    const { event } = this.props.langPreferences;

    return [
      {
        label: `Without ${event.plural}`,
        value: ROSTER_FILTER_WITHOUT
      },
      ...this.eventsSelectOptions
    ];
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  formData: filtersFormSelector(state),
  langPreferences: getLangPreferences(state),
  events: eventsArraySelector(state),
  hasEventsPermission: hasPermissionSelector(state, 'roster.events.view'),
  canViewTimesheets: hasPermissionSelector(state, 'roster.timesheet.view'),
  canViewRosteredShifts: hasPermissionSelector(
    state,
    'roster.rosteredshift.view'
  ),
  selectedFilterType: getFiltersFormRoleFilterType(state),
  isCovidMode: getIsCovidModeEnabled(state)
});

const mapDispatchToProps: DispatchProps = {
  onChange: BOX_ROSTER_FILTERS_UPDATE_FILTER_FORM_FIELD,
  changeFilterForm: BOX_ROSTER_FILTERS_UPDATE_FILTER_FORM
};

export const CustomFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomFiltersComponent);
