import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import {
  getErrors,
  getSelectedFilter,
  getShiftTradesCustomFilter,
} from 'state/EmployeeDashboard/ShiftTrades/selectors';
import { ShiftTradesFilter } from 'state/EmployeeDashboard/ShiftTrades/types';
import AvailableShifts from './components/AvailableShifts';
import OfferedShifts from './components/OfferedShifts';
import AppliedShifts from './components/AppliedShifts';
import { FormattedErrors } from 'type/models';
import ErrorBox from 'element/ErrorBox';
import { BOX_SHIFT_TRADES_REMOVE_ERRORS } from 'state/EmployeeDashboard/ShiftTrades';
import { getIsLoading } from '../../../../../../../../state/IsLoading';
import { LoadingOverlay } from 'elmo-elements';

type DispatchProps = {
  removeErrors: () => void;
};

type StateProps = {
  selectedFilter: ShiftTradesFilter;
  selectedFilterCustom: ShiftTradesFilter;
  errors: FormattedErrors;
  isLoading: boolean;
};

type Props = StateProps & DispatchProps;

class ShiftTradesListComponent extends Component<Props> {
  render() {
    return (
      <LoadingOverlay isLoading={this.props.isLoading}>
        {this.showErrors()}
        {this.isSelectedFilter('available') && <AvailableShifts />}
        {this.isSelectedFilter('offered') && <OfferedShifts />}
        {this.isSelectedFilter('applied') && <AppliedShifts />}
      </LoadingOverlay>
    );
  }

  isSelectedFilter = (key: string) => {
    const { selectedFilter, selectedFilterCustom } = this.props;
    if (selectedFilter === key) {
      return selectedFilter === key;
    }
    if (selectedFilter === 'custom') {
      return selectedFilterCustom === key;
    }
  };

  showErrors = () => {
    const { errors } = this.props;
    return (
      <ErrorBox
        errors={errors}
        clearErrors={this.props.removeErrors}
        className={'global-errors-alert'}
      />
    );
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  selectedFilter: getSelectedFilter(state),
  selectedFilterCustom: getShiftTradesCustomFilter(state).selectedFilter,
  errors: getErrors(state),
  isLoading: getIsLoading(state, 'EMPLOYEE_DASHBOARD_SHIFT_TRADES')
});

const mapToDispatchProps: DispatchProps = {
  removeErrors: BOX_SHIFT_TRADES_REMOVE_ERRORS
};

export default connect(
  mapStateToProps,
  mapToDispatchProps
)(ShiftTradesListComponent);
