import { createReducer } from 'lib/store-utils';
import * as actions from './actions';
import { FetchPageDataReducerState, PageId } from './types';
import { getDefaultState } from './state';

export const fetchPageData = createReducer<FetchPageDataReducerState>(
  {},
  getDefaultState()
);

const fetchingHandler = (
  state: FetchPageDataReducerState,
  pageId: PageId
): FetchPageDataReducerState => ({
  pages: {
    ...state.pages,
    [pageId]: {
      isFetching: true,
      isFetched: false
    }
  }
});

const fetchedHandler = (
  state: FetchPageDataReducerState,
  pageId: PageId
): FetchPageDataReducerState => ({
  pages: {
    ...state.pages,
    [pageId]: {
      isFetching: false,
      isFetched: true
    }
  }
});

const notFetchedHandler = (
  state: FetchPageDataReducerState,
  pageId: PageId
): FetchPageDataReducerState => ({
  pages: {
    ...state.pages,
    [pageId]: {
      isFetching: false,
      isFetched: false
    }
  }
});

fetchPageData.on(actions.BOX_FETCH_PAGE_DATA_REQUEST, fetchingHandler);
fetchPageData.on(actions.BOX_FETCH_PAGE_DATA_SUCCESS, fetchedHandler);
fetchPageData.on(actions.BOX_FETCH_PAGE_DATA_FAILURE, notFetchedHandler);

fetchPageData.on(
  actions.BOX_FETCH_PAGE_DATA_CLEAR_IS_FETCHED,
  notFetchedHandler
);
