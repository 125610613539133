import { createSelector } from 'reselect';
import { StoreState } from 'state/types';
import { userRosterDataSelector } from '../../RosterWeekView';

const getUserRowIndexProp = (
  state: StoreState,
  props: { userRowIndex: number }
) => props.userRowIndex;
const getPairRowIndexProp = (
  state: StoreState,
  props: { pairRowIndex: number }
) => props.pairRowIndex;
const getWeekDayIndexProp = (
  state: StoreState,
  props: { weekDayIndex: number }
) => props.weekDayIndex;

export const userRowsNumberSelector = createSelector(
  userRosterDataSelector,
  (groupedData) => groupedData.length
);

export const makeDataItemSelector = () =>
  createSelector(
    userRosterDataSelector,
    getUserRowIndexProp,
    (groupedData, userRowIndex) => groupedData[userRowIndex]
  );
export const makeItemUserIdSelector = () =>
  createSelector(makeDataItemSelector(), (dataItem) => dataItem.user_id);

export const makeItemRosterRowNumberSelector = () =>
  createSelector(
    makeDataItemSelector(),
    (dataItem) => dataItem.rostersRowNumber
  );
export const makeItemRostersSelector = () =>
  createSelector(makeDataItemSelector(), (dataItem) => dataItem.rosters);

export const makeRostersPairSelector = () =>
  createSelector(
    makeItemRostersSelector(),
    getPairRowIndexProp,
    getWeekDayIndexProp,
    (itemRosters, pairRowIndex, weekDayIndex) =>
      itemRosters[pairRowIndex][weekDayIndex]
  );

export const makePairRosteredShiftIdSelector = () =>
  createSelector(
    makeRostersPairSelector(),
    (rostersPair) => rostersPair.rosteredShiftId
  );

export const makePairLeaveIdSelector = () =>
  createSelector(
    makeRostersPairSelector(),
    (rostersPair) => rostersPair.leaveId
  );

export const makePairTimeoffIdsSelector = () =>
  createSelector(
    makeRostersPairSelector(),
    (rostersPair) => rostersPair.timeOffIds
  );

export const makePairTimesheetIdSelector = () =>
  createSelector(
    makeRostersPairSelector(),
    (rosterPair) => rosterPair.timesheetId
  );
