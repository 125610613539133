import { useMemo } from 'react';
import { compact } from 'lodash';
import {
  AssignmentLateOutlinedIcon,
  DoneOutlinedIcon,
  ReportOffOutlinedIcon,
  ReportOutlinedIcon,
  TimerOutlinedIcon,
  NotesOutlinedIcon
} from 'element';
import { Timesheet } from 'type';
import { CardIconsPropsIcon } from '../../views';

export const useIcons = ({
  is_approved,
  end,
  is_violated,
  is_inviolated,
  notes
}: Timesheet) => {
  const isInProgress = !end;

  return useMemo(
    () =>
      compact<CardIconsPropsIcon>([
        !is_approved && {
          title: 'Pending approval',
          color: '#FD9C09',
          Icon: AssignmentLateOutlinedIcon,
        },
        isInProgress && {
          title: 'Ongoing',
          color: '#5262EF',
          Icon: TimerOutlinedIcon,
        },
        is_violated &&
          !is_inviolated && {
            title: 'Violation',
            color: '#B11040',
            Icon: ReportOutlinedIcon,
          },
        is_violated &&
          is_inviolated &&
          is_approved && {
            title: 'Violation removed',
            color: '#37C597',
            Icon: ReportOffOutlinedIcon,
          },
        is_approved && {
          title: 'Approved',
          color: '#37C597',
          Icon: DoneOutlinedIcon,
        },
        notes && {
          title: 'Notes',
          color: '#B11040',
          Icon: NotesOutlinedIcon
        }
      ]),
    [is_approved, isInProgress, is_violated, is_inviolated, notes]
  );
};
