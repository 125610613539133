import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  BOX_COVID_TRACING_MODE_DOWNLOAD_REPORT,
  BOX_COVID_TRACING_MODE_DOWNLOAD_REPORT_FAILURE,
  BOX_COVID_TRACING_MODE_DOWNLOAD_REPORT_SUCCESS,
  BOX_COVID_TRACING_MODE_ENABLE,
  BOX_COVID_TRACING_MODE_SET_SHIFT_FAILURE,
  BOX_COVID_TRACING_MODE_SET_SHIFT_SUCCESS,
  BOX_COVID_TRACING_MODE_TOGGLE_SHIFT,
} from './actions';
import { RosteredShift, SagaAction, Timesheet } from '../../../type';
import { CovidShiftData } from './types';
import { formatError } from '../../helpers';
import {
  getContactedShifts,
  getCovidPeriod,
  getCovidShiftData,
} from './selectors';
import { Api } from 'lib/Api';
import { BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN } from '../Roster';
import { filtersSelector, RosterFilters } from '../RosterFilters';
import { getRosteredShift } from '../../RosteredShifts';
import { getTimesheet } from 'state/TimesheetsCollection/selectors';
import { SERVER_DAY_FORMAT } from 'lib/config';
import { processApiRequest } from '../../ProcessApiRequest';

export const getViewType = (filters: RosterFilters) => {
  const { roster, timesheets } = filters;
  if (roster && timesheets) {
    return 'all';
  }
  return filters.roster ? 'rostered_shift' : 'timesheet';
};

export const getShiftData = function*(data: CovidShiftData) {
  const { id, entity } = data;
  return entity === 'timesheet'
    ? yield select(getTimesheet, id)
    : yield select(getRosteredShift, id);
};

export const getPeriodData = (period: any, shift: RosteredShift | Timesheet) => {
  const { start } = shift;
  return {
    from: start.clone().startOf('day').subtract(+period - 1, 'days').format(SERVER_DAY_FORMAT),
    to: start.clone().startOf('day').format(SERVER_DAY_FORMAT)
  };
};

export const getFilteredData = (filters: RosterFilters) => {
  return {
    view_type: getViewType(filters),
    area_ids: filters.areas ? filters.areas : [],
    role_ids: filters.roles ? filters.roles : [],
  };
};

const getRequestData = function*() {
  const covidData = yield select(getCovidShiftData);
  if ( covidData ) {
    const shift = yield call(getShiftData, covidData);
    const filters = yield select(filtersSelector);
    const period = yield select(getCovidPeriod);

    if (shift) {
      return {
        ...covidData,
        period: getPeriodData(period, shift),
        filters: getFilteredData(filters)
      };
    }
  }
  return null;
};

const setShiftTracing = function*({
  payload
}: SagaAction<CovidShiftData>): SagaIterator {
  try {
    const data = yield call(getRequestData);
    if ( data ) {
      const response = yield call(processApiRequest, Api.CovidTracing.getShifts, data);
      yield put(BOX_COVID_TRACING_MODE_SET_SHIFT_SUCCESS(response));
    }
  } catch (e) {
    yield put(BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN(formatError(e)));
    yield put(BOX_COVID_TRACING_MODE_SET_SHIFT_FAILURE(formatError(e)));
  }
};

const downloadReport = function*({
  payload
}: SagaAction<'csv' | 'pdf' | 'json'>): SagaIterator {
  try {
    const { rostered_shift_ids, timesheet_ids, ...rest } = yield select(getContactedShifts);
    const response = yield call(processApiRequest, Api.CovidTracing.printReport, {
      format: payload,
      rostered_shift_ids,
      timesheet_ids
    });
    yield put(BOX_COVID_TRACING_MODE_DOWNLOAD_REPORT_SUCCESS(response));
  } catch (e) {
    yield put(BOX_ROSTER_GLOBAL_ERROR_MODAL_OPEN(formatError(e)));
    yield put(BOX_COVID_TRACING_MODE_DOWNLOAD_REPORT_FAILURE(formatError(e)));
  }
}

export const watchCovidTracing = function*(): SagaIterator {
  yield takeLatest(BOX_COVID_TRACING_MODE_TOGGLE_SHIFT, setShiftTracing);
  yield takeLatest(BOX_COVID_TRACING_MODE_DOWNLOAD_REPORT, downloadReport);
};
