import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { SagaAction } from 'type';
import Api from 'lib/Api';
import {
  AllowancesRequest,
  AllowancesResponse,
  TimesheetAllowanceRequest,
  TimesheetAllowanceResponse,
  TimesheetDeleteAllowanceRequest,
  TimesheetDeleteAllowanceResponse,
} from 'lib/Api/type';
import { formatError } from 'state/helpers';
import { processApiRequest } from 'state/ProcessApiRequest';
import * as fetchActions from 'state/FetchPageData';
import * as isLoading from 'state/IsLoading';
import { BOX_TIMESHEET_UPDATE_ONE } from 'state/TimesheetsCollection';
import { AllowancesModalDeleteAllowanceRequestPayload } from '../types';
import * as actions from '../actions';
import {
  allowancesPayloadSelector,
  getCurrentTimesheet,
  getCurrentTimesheetId,
  getFormData,
} from '../selectors';

const fetchModalData = function* (): SagaIterator {
  yield put(
    fetchActions.BOX_FETCH_PAGE_DATA_REQUEST(
      fetchActions.MANAGER_DASHBOARD_TIMESHEETS_ALLOWANCES_MODAL
    )
  );

  try {
    const payload: ReturnType<typeof allowancesPayloadSelector> = yield select(
      allowancesPayloadSelector
    );

    const apiPayload: AllowancesRequest = payload;

    const allowances: AllowancesResponse = yield call(
      processApiRequest,
      Api.EmployeeDashboard.getAllowancesList,
      apiPayload
    );

    yield put(
      actions.BOX_TIMESHEETS_ALLOWANCES_MODAL_SUCCESS({
        allowances,
      })
    );
    yield put(
      fetchActions.BOX_FETCH_PAGE_DATA_SUCCESS(
        fetchActions.MANAGER_DASHBOARD_TIMESHEETS_ALLOWANCES_MODAL
      )
    );
  } catch (error) {
    yield put(
      fetchActions.BOX_FETCH_PAGE_DATA_FAILURE(
        fetchActions.MANAGER_DASHBOARD_TIMESHEETS_ALLOWANCES_MODAL
      )
    );
  }
};

const updateTimesheet = function*(): SagaIterator {
  yield put(
    isLoading.BOX_IS_LOADING_ON(
      isLoading.MANAGER_DASHBOARD_TIMESHEETS_ALLOWANCES_MODAL
    )
  );

  const id: ReturnType<typeof getCurrentTimesheetId> = yield select(
    getCurrentTimesheetId
  );
  const allowances: ReturnType<typeof getFormData> = yield select(getFormData);

  try {
    const apiPayload: TimesheetAllowanceRequest = {
      id,
      allowances
    };

    const response: TimesheetAllowanceResponse = yield call(
      processApiRequest,
      Api.Timesheet.allowance,
      apiPayload
    );

    yield put(BOX_TIMESHEET_UPDATE_ONE(response));
    yield put(
      actions.BOX_TIMESHEETS_ALLOWANCES_MODAL_UPDATE_TIMESHEET_SUCCESS()
    );
  } catch (errors) {
    yield put(
      actions.BOX_TIMESHEETS_ALLOWANCES_MODAL_UPDATE_TIMESHEET_FAILURE(
        formatError(errors)
      )
    );
  }

  yield put(
    isLoading.BOX_IS_LOADING_OFF(
      isLoading.MANAGER_DASHBOARD_TIMESHEETS_ALLOWANCES_MODAL
    )
  );
};

const deleteAllowance = function*({
  payload: { allowanceId }
}: SagaAction<AllowancesModalDeleteAllowanceRequestPayload>): SagaIterator {
  yield put(
    isLoading.BOX_IS_LOADING_ON(
      isLoading.MANAGER_DASHBOARD_TIMESHEETS_ALLOWANCES_MODAL
    )
  );

  const currentTimesheet: ReturnType<typeof getCurrentTimesheet> = yield select(
    getCurrentTimesheet
  );

  try {
    const apiPayload: TimesheetDeleteAllowanceRequest = {
      id: currentTimesheet.id,
      allowance_id: allowanceId
    };

    const updatedTimesheet: TimesheetDeleteAllowanceResponse = yield call(
      processApiRequest,
      Api.Timesheet.deleteAllowance,
      apiPayload
    );

    yield put(BOX_TIMESHEET_UPDATE_ONE(updatedTimesheet));
  } catch (errors) {
    yield put(
      actions.BOX_TIMESHEETS_ALLOWANCES_MODAL_DELETE_ALLOWANCE_FAILURE(
        formatError(errors)
      )
    );
  }

  yield put(
    isLoading.BOX_IS_LOADING_OFF(
      isLoading.MANAGER_DASHBOARD_TIMESHEETS_ALLOWANCES_MODAL
    )
  );
};

export const watchTimesheetsAllowances = function*(): SagaIterator {
  yield takeLatest(
    actions.BOX_TIMESHEETS_ALLOWANCES_MODAL_REQUEST,
    fetchModalData
  );
  yield takeLatest(
    actions.BOX_TIMESHEETS_ALLOWANCES_MODAL_UPDATE_TIMESHEET_REQUEST,
    updateTimesheet
  );
  yield takeLatest(
    actions.BOX_TIMESHEETS_ALLOWANCES_MODAL_DELETE_ALLOWANCE_REQUEST,
    deleteAllowance
  );
};
