import { Moment } from 'moment';

export const getIsTimesheetInProgress = (timesheet: {
  end: string | Moment | null;
}) => !timesheet.end;

const getIsTimesheetCompleted = (timesheet: { end: string | Moment | null }) =>
  !getIsTimesheetInProgress(timesheet);

export const getIsTimesheetApproved = (timesheet: {
  end: string | Moment | null;
  is_approved: boolean;
}) => getIsTimesheetCompleted(timesheet) && timesheet.is_approved;

export const getIsTimesheetPending = (timesheet: {
  end: string | Moment | null;
  is_approved: boolean;
}) => getIsTimesheetCompleted(timesheet) && !timesheet.is_approved;


