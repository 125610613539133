import React, { Component } from 'react';
import { NativeTable } from 'elmo-elements';
import { ColumnsProps } from '../../type';
import {
  getFormattedNumber,
  getDateTimeFormatted,
  getCurrencyFormatted,
} from 'lib/helpers';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import {
  getReportTotals,
  getReportPagination,
  getGroups,
  getRows,
  getReportType,
  getCurrentPageCommonGroupedRows,
} from 'state/Report/selectors';
import { ReportPagination } from 'state/Report/state';
import {
  PreferencesCurrencyCode,
  PreferencesCurrencyPlacement,
  PreferencesDateFormat,
  PreferencesNumberFormat,
  PreferencesTimeFormat,
  ReportType,
} from 'type/models';
import {
  getCurrencyCode,
  getCurrencyPlacement,
  getDateFormat,
  getNumberFormat,
  getTimeFormat,
} from 'state/Account';
import { withFeatureFlags } from 'flags';
import { ExpectedFlag } from '../../../../../../feature-flags';

type CellData = any;

type Props = {
  pageRows: any;
  rows: any[];
  visibleColumns: ColumnsProps[];
  groups: any;
  pagination: ReportPagination;
  showTotalRow: boolean;
  totals: any;
  type: ReportType;
  dateFormat: PreferencesDateFormat;
  timeFormat: PreferencesTimeFormat;
  numberFormat: PreferencesNumberFormat;
  currencyCode: PreferencesCurrencyCode;
  currencyPlacement: PreferencesCurrencyPlacement;
} & {
  flags: {
    reportDatetime: ExpectedFlag;
  };
};

export class CommonGroupedRows extends Component<Props> {
  render() {
    let showGroupCell = true;
    const {
      pageRows,
      groups,
      visibleColumns,
      type,
      pagination: { startItem },
    } = this.props;

    const { allRows, byPage } = pageRows;

    const showSubtotal = (index: number, colspan: number, report: any) => {
      showGroupCell = true;
      if (type === 'roster' || type === 'tag') {
        return null;
      }
      return this.getSubtotalRow(index, visibleColumns.length, report, groups);
    };

    return (
      <>
        {byPage.map((report: any, trIndex: number) => (
          <React.Fragment key={`fragment-${trIndex}`}>
            <NativeTable.Tr key={`row-${trIndex}`}>
              {visibleColumns.map((column: ColumnsProps, key: number) => (
                <NativeTable.Td
                  key={`${trIndex}-${key + 1}`}
                  className={`cell-name-${column.name}`}
                >
                  {key === 0 && showGroupCell
                    ? this.showCellData(column.name, report[column.name])
                    : null}
                  {key > 0
                    ? this.showCellData(column.name, report[column.name])
                    : (showGroupCell = false)}
                </NativeTable.Td>
              ))}
            </NativeTable.Tr>
            {startItem + trIndex <= allRows.length - 1 &&
            allRows[startItem + trIndex].group_id !== report.group_id
              ? showSubtotal(startItem + trIndex, visibleColumns.length, report)
              : null}
            {startItem + trIndex === allRows.length
              ? showSubtotal(startItem + trIndex, visibleColumns.length, report)
              : null}
          </React.Fragment>
        ))}
        {this.showTotal() && this.getTotalHoursView()}
      </>
    );
  }

  isMoney = (name: string) => {
    const { visibleColumns } = this.props;
    for (let column of visibleColumns) {
      if (column.name === name && column.is_money) {
        return true;
      }
    }
  };

  showCellData = (name: string, data: CellData) => {
    const {
      numberFormat,
      currencyCode,
      currencyPlacement,
      timeFormat,
      dateFormat,
      type,
    } = this.props;
    if (this.isMoney(name)) {
      return getCurrencyFormatted(
        numberFormat,
        currencyCode,
        currencyPlacement,
        data
      );
    }
    const outputString = (v: string) => (v.length ? v : '-');
    let val =
      typeof data === 'string'
        ? outputString(data)
        : getFormattedNumber(numberFormat, +data);

    if (this.props.flags.reportDatetime) {
      return val;
    }

    if (type === 'timesheet') {
      return name === 'created_at' ||
        name === 'updated_at' ||
        name === 'date_of_birth'
        ? getDateTimeFormatted(dateFormat, timeFormat, data)
        : val;
    }

    return name === 'start' ||
      name === 'end' ||
      name === 'created_at' ||
      name === 'updated_at' ||
      name === 'date_of_birth'
      ? getDateTimeFormatted(dateFormat, timeFormat, data)
      : val;
  };

  getGroupSubtotal = (groupId: number, groups: any[]) => {
    const { numberFormat, currencyCode, currencyPlacement } = this.props;
    for (let group of groups) {
      if (group.id === groupId) {
        return isNaN(group.totals.hours)
          ? group.totals.hours
          : getFormattedNumber(numberFormat, +group.totals.hours);
      }
    }
    return 0;
  };

  getSubtotalRow = (
    trIndex: number,
    colspan: number,
    report: any,
    groups: any
  ) => {
    return (
      <NativeTable.Tr key={`subtotal-row-${trIndex + 1}`}>
        <NativeTable.Td
          key={`subtotal-cell-${trIndex + 1.5}`}
          className={'total-hours'}
          colspan={colspan + 1}
          textAlign={'right'}
        >
          Sub total {this.getGroupSubtotal(report.group_id, groups)}
        </NativeTable.Td>
      </NativeTable.Tr>
    );
  };

  private showTotal = () => {
    const { showTotalRow, pagination, type } = this.props;
    if (type === 'roster') {
      return false;
    }
    return showTotalRow && pagination.totalResults <= pagination.endItem;
  };

  private getTotalHoursView = () => {
    const { visibleColumns, numberFormat } = this.props;
    const { hours } = this.props.totals;
    const colspan = visibleColumns.length;
    return (
      <NativeTable.Tr>
        <NativeTable.Td
          className={'total-hours'}
          colspan={colspan}
          textAlign={'right'}
        >
          Total hours:{' '}
          {isNaN(hours) ? hours : getFormattedNumber(numberFormat, +hours)}
        </NativeTable.Td>
      </NativeTable.Tr>
    );
  };
}

const mapToStateProps = (state: StoreState) => ({
  pageRows: getCurrentPageCommonGroupedRows(state),
  totals: getReportTotals(state),
  pagination: getReportPagination(state),
  groups: getGroups(state),
  rows: getRows(state),
  type: getReportType(state),
  dateFormat: getDateFormat(state),
  timeFormat: getTimeFormat(state),
  numberFormat: getNumberFormat(state),
  currencyCode: getCurrencyCode(state),
  currencyPlacement: getCurrencyPlacement(state),
});

export default connect(mapToStateProps)(withFeatureFlags(CommonGroupedRows));
