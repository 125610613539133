import React from 'react';
import classNames from 'clsx';
import { createStyles, makeStyles } from '@mui/styles';

type Props = {
  transparent?: boolean
};

const useLocationStyles = makeStyles((theme) => createStyles({
  line: {
    width: '4px'
  },
  transparent: {
    background: 'transparent !important'
  }
}));

const ShiftCardLocation = ({ transparent = false }: Props) => {
  const styles = useLocationStyles();
  return (
    <div
      className={classNames('shift-card-view__location', styles.line, {
        [styles.transparent]: transparent
      })}
    />
  );
};

export default ShiftCardLocation;
