import React from 'react';
import {
  bindHoverPopover,
  bindHoverTrigger,
  useHoverPopupState,
  useSelectorWithProps,
} from 'hooks';
import { timeOffsArrayById } from 'state/TimeOffs';
import { useSelector } from 'react-redux';
import { getTimeFormat } from 'state/Account';
import { WorkOffOutlinedIcon, PopperBox, CalendarIcon } from 'element';
import { getTimeFormatted, isMobileDevice } from 'lib/helpers';
import { createStyles, makeStyles } from '@mui/styles';
import {
  RosterRepeatableTimeOff,
  ServerRosterRepeatableTimeOff,
  TimeOffType,
} from 'type';
import { TimeOffCardPopperAdd } from './components';
import useHandleClickAddButton from './hooks/useHandleClickAddButton';
import { Moment } from 'moment';
import clsx from 'clsx';

type Props = {
  ids: string[];
  day: string;
};

const useTimeOffStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: 'inherit',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    info: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 12,
      fontWeight: 500,
      '& svg': {
        marginRight: 6,
      },
    },
    tooltip: {
      fontSize: 12,
      lineHeight: '20px',
      letterSpacing: 0.5,
      color: '#fff',
      backgroundColor: '#000',
      padding: '4px 16px',
      borderRadius: 6,
      position: 'relative',
      minWidth: '140px',
      textAlign: 'center',
    },
    arrow: {
      display: 'block',
      position: 'absolute',
      left: '50%',
      bottom: '-6px',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderTop: '6px solid #000',
      zIndex: 1,
    },
    tooltipItem: {
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      textAlign: 'center',
      lineHeight: '20px',
      justifyContent: 'center',
      '& svg': {
        fontSize: 16,
        marginLeft: 5,
      },
    },
  })
);

type TimeOffsDetailsProps = {
  timeOffs: RosterRepeatableTimeOff[];
};

type MultipleType = {
  type: TimeOffType | ServerRosterRepeatableTimeOff['type'];
};

const TimeOffsDetails = ({ timeOffs }: TimeOffsDetailsProps) => {
  const styles = useTimeOffStyles();
  const format = useSelector(getTimeFormat);
  const formatTime = (time: Moment) => getTimeFormatted(format, time);
  const Icon = ({ type }: MultipleType) =>
    type === 'rostered_shift' ? <CalendarIcon /> : <span />;
  return (
    <>
      {timeOffs.map(({ start, end, id, type }, index) => (
        <div
          className={styles.tooltipItem}
          data-testid={`time-off-time time-off-time-${id}`}
          key={index}
        >
          {formatTime(start)} - {formatTime(end)}
          <Icon type={type} />
        </div>
      ))}
    </>
  );
};

export const MultipleTimeOffsCard = ({ ids, day }: Props) => {
  const timeOffs = useSelectorWithProps(timeOffsArrayById, ids);
  const styles = useTimeOffStyles();
  const hoverPopupState = useHoverPopupState<HTMLDivElement>();
  const onClickAddBtn = useHandleClickAddButton(timeOffs[0], day);
  return (
    <>
      <div
        onDoubleClick={(e) => e.stopPropagation()}
        data-testid={`multiple-time-offs`}
        className={clsx(styles.root, 'multiple-time-offs')}
        {...bindHoverTrigger(hoverPopupState)}
        onClick={isMobileDevice() ? hoverPopupState.open : () => false}
      >
        <div className={styles.info}>
          <WorkOffOutlinedIcon />+ {timeOffs.length} more
        </div>
        <TimeOffCardPopperAdd
          onClick={onClickAddBtn}
          isVisible={hoverPopupState.isOpened}
        />
      </div>
      <PopperBox
        {...bindHoverPopover(hoverPopupState)}
        disablePortal={true}
        placement="top"
      >
        <div
          data-testid={'multiple-time-offs-tooltip'}
          className={styles.tooltip}
        >
          <TimeOffsDetails timeOffs={timeOffs} />
          <div className={styles.arrow} />
        </div>
      </PopperBox>
    </>
  );
};
