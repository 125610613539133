import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from 'state/types';
import {
  BOX_UNAVAILABILITY_CONFIRM_CREATION_MODAL_CLOSE,
  BOX_UNAVAILABILITY_FORCE_CREATE_UNAVAILABILITY_REQUEST,
  getConfirmCreationModal
} from 'state/ManagerDashboard/Unavailability';
import {
  ConfirmationModalProps,
  UnavailabilityConfirmationModal
} from '../UnavailabilityConfirmationModal';

type OwnProps = {};

type StateProps = Pick<ConfirmationModalProps, 'isUpdating' | 'isOpened' | 'message'>;

type DispatchProps = Pick<ConfirmationModalProps, 'onClose' | 'onSubmit'>;

type Props = OwnProps & StateProps & DispatchProps;

const UnavailabilityConfirmCreationComponent = ({ message, ...rest }: Props) => (
  <UnavailabilityConfirmationModal
    {...rest}
    message={message}
  />
);

const mapStateToProps = (state: StoreState): StateProps => ({
  ...getConfirmCreationModal(state)
});

const mapDispatchToProps: DispatchProps = {
  onClose: BOX_UNAVAILABILITY_CONFIRM_CREATION_MODAL_CLOSE,
  onSubmit: BOX_UNAVAILABILITY_FORCE_CREATE_UNAVAILABILITY_REQUEST
};

export const UnavailabilityConfirmCreation = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnavailabilityConfirmCreationComponent);
