import React from 'react';
import { Dictionary } from 'ts-essentials';
import {
  TableCell,
  TableRow,
} from 'extended-oxygen-elements';
import {
  createStyles,
  makeStyles,
} from '@mui/styles';
import { IconButton } from 'extended-oxygen-elements';
import { CancelOutlinedIcon, CheckCircleOutlinedIcon } from 'element';
import TableCellInput from './TableCellInput';
import TableCellNumberInput from './TableCellNumberInput';

export type FormComponentProps = {
  loading: boolean;
  onSubmit: (formFields: FormComponentFormPayload) => void;
  onClose: () => void;
  initialState?: FormFields;
};

type FormFields = {
  name: string;
  multiplier: number | null;
  export_code: string | null;
};

export type FormComponentFormPayload = {
  name: string;
  multiplier: number;
  export_code: string | null;
};

type ErrorsCollection = Partial<Dictionary<string, keyof FormFields>>;
type SetFormField = <FieldName extends keyof FormFields>(
  fieldName: FieldName,
  fieldValue: FormFields[FieldName]
) => void;

const useStyles = makeStyles(() =>
  createStyles({
    cellAlignTop: {
      verticalAlign: 'top',
    },
  })
);

export default function FormComponent({
  loading,
  onSubmit,
  onClose,
  initialState = { name: '', multiplier: null, export_code: '' },
}: FormComponentProps) {
  const classes = useStyles();

  const [formFields, setFormFields] = React.useState<FormFields>(initialState);
  const setFormField: SetFormField = (fieldName, fieldValue) => {
    setFormFields((prevState) => ({ ...prevState, [fieldName]: fieldValue }));
  };

  const [errors, setErrors] = React.useState<ErrorsCollection>({});

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormField('name', event.target.value);
  };

  const handleMultiplierChange = (newValue: number | null) => {
    setFormField('multiplier', newValue);
  };

  const handleExportCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormField('export_code', event.target.value);
  };

  const submitForm = () => {
    if (loading) {
      return;
    }

    const validationErrors = validate(formFields);

    setErrors(validationErrors);
    if (Object.keys(validationErrors).length) {
      return;
    }

    const payload = validatePayload(formFields);

    if (!payload) {
      return;
    }

    onSubmit(payload);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      submitForm();
    }
  };

  const handleCancelBtnClick = () => {
    onClose();
  };

  return (
    <TableRow data-testid={'pay-element-item-row'}>
      <TableCell className={classes.cellAlignTop} data-testid={'pay-element-item-name'}>
        <TableCellInput
          value={formFields.name}
          onChange={handleNameChange}
          onKeyDown={handleKeyDown}
          error={!!errors.name}
          helperText={errors.name}
          readOnly={loading}
          autoFocus
        />
      </TableCell>

      <TableCell className={classes.cellAlignTop} data-testid={'pay-element-item-multiplier'}>
        <TableCellNumberInput
          value={formFields.multiplier}
          onValueChange={handleMultiplierChange}
          onKeyDown={handleKeyDown}
          error={!!errors.multiplier}
          helperText={errors.multiplier}
          readOnly={loading}
          precision={2}
          step={0.25}
        />
      </TableCell>

      <TableCell className={classes.cellAlignTop} data-testid={'pay-element-item-code'}>
        <TableCellInput
          value={formFields.export_code}
          onChange={handleExportCodeChange}
          onKeyDown={handleKeyDown}
          error={!!errors.export_code}
          helperText={errors.export_code}
          readOnly={loading}
        />
      </TableCell>

      <TableCell padding="checkbox">
        <IconButton
          disabled={loading}
          onClick={handleCancelBtnClick}
          data-testid={'pay-element-item-cancel-btn'}
        >
          <CancelOutlinedIcon />
        </IconButton>
      </TableCell>

      <TableCell padding="checkbox">
        <IconButton
          size="small"
          loading={loading}
          onClick={submitForm}
          color="primary"
          data-testid={'pay-element-item-submit-btn'}
        >
          <CheckCircleOutlinedIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

function validate({ name, multiplier }: FormFields): ErrorsCollection {
  let errors: ErrorsCollection = {};

  if (!name.length) {
    errors.name = 'This field is required.';
  }

  if (multiplier === null) {
    errors.multiplier = 'This field is required.';
  }

  if (multiplier !== null && multiplier <= 0) {
    errors.multiplier = 'Must be greater then 0.';
  }

  return errors;
}

function validatePayload({
  name,
  multiplier,
  export_code,
}: FormFields): FormComponentFormPayload | undefined {
  if (multiplier === null) {
    return;
  }

  return {
    name,
    multiplier,
    export_code: export_code || null,
  };
}
