import React from 'react';
import './style.scss';
import SummaryTabs from './components/SummaryTabs';

type Props = {
  children: React.ReactNode;
};

export function SummaryData({children}: Props) {
  return <div className="summary-data">
    <SummaryTabs />
    <div className="summary-data__content">
      {children}
    </div>
  </div>
}
