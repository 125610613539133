import {
  DialogActions,
  DialogContent,
  DialogContentText,
} from 'extended-oxygen-elements';
import { AlertErrorBoxDialog } from 'element';
import { useUpdateEffect } from 'hooks';
import {
  deleteConfirmLabel,
  deleteOneDescription,
  deleteTitle,
} from 'messages';
import { Button, Dialog, DialogTitle } from 'extended-oxygen-elements';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BOX_PAY_ELEMENTS_PAY_ELEMENT_DELETE } from 'state/PayElementsCollection';
import {
  BOX_PAY_ELEMENTS_PAGE_DIALOG_ERRORS_CLOSED,
  getModalErrors,
  getModalRequestStatus,
  getModalUpdating,
} from 'state/PayElementsPage';
import { useDeleteContext } from './delete-context';

export default function DeleteConfirmationDialog() {
  const { closeConfirm, idToBeDeleted, isConfirmOpen } = useDeleteContext();
  const dispatch = useDispatch();
  const loading = useSelector(getModalUpdating);
  const errors = useSelector(getModalErrors);
  const requestStatus = useSelector(getModalRequestStatus);
  useUpdateEffect(() => {
    if (requestStatus === 'success' || requestStatus === 'cancel') {
      closeConfirm();
    }
  }, [requestStatus]);

  const closeDialog = () => {
    if (loading) {
      return;
    }

    closeConfirm();
  };

  const handleErrorsClose = () => {
    dispatch(BOX_PAY_ELEMENTS_PAGE_DIALOG_ERRORS_CLOSED());
  };

  const handleConfirmButtonCLick = () => {
    if (loading) {
      return;
    }

    dispatch(
      BOX_PAY_ELEMENTS_PAY_ELEMENT_DELETE.request({ id: idToBeDeleted })
    );
  };

  return (
    <Dialog open={isConfirmOpen} onClose={closeDialog}>
      <DialogTitle>{deleteTitle('Pay element')}</DialogTitle>

      <DialogContent>
        <AlertErrorBoxDialog
          BoxProps={{
            mb: 2,
          }}
          errors={errors}
          onClose={handleErrorsClose}
        />

        <DialogContentText>
          {deleteOneDescription('Pay element')}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button disabled={loading} onClick={closeDialog}>
          Cancel
        </Button>

        <Button
          variant="contained"
          loading={loading}
          disableRipple={loading}
          onClick={handleConfirmButtonCLick}
        >
          {deleteConfirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
