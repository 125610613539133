export const svgTextToBase64 = (label: string | undefined, fontWeight: string | number = 600) => {
  const fonts = `-apple-system,BlinkMacSystemFont,Segoe UI,
                Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,Helvetica,
                Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol`;

  return 'data:image/svg+xml;base64,' +
    window.btoa(
    `
      <svg xmlns="http://www.w3.org/2000/svg">
          <style>
            text {
              letter-spacing: 0.2px; 
              font-size: 14px; 
              font-weight: ${fontWeight}; 
              font-family: ${fonts};
            }
          </style>
          <text dominant-baseline="central" text-anchor="middle" dx="50%" dy="50%" fill="#aaa">${label}</text>
        </svg>
    `
  );
};