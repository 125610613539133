import { RosterWeekChannel } from 'lib/socket-channels';
import { WS } from 'lib/SocketConnection';
import { eventChannel } from 'redux-saga';
import { select } from 'redux-saga/effects';
import { getCurrentUserId } from 'state/Auth';
import * as timesheetResponse from 'state/TimesheetResponse';
import { DateType } from 'type';

type Props = {
  site_id: string;
  day: DateType;
};

export function* makeTimesheetsWeeklyChannel(ws: WS, props: Props) {
  const currentUserId: ReturnType<typeof getCurrentUserId> = yield select(
    getCurrentUserId
  );

  return eventChannel((emit) => {
    const rosterWeekChannel = new RosterWeekChannel(ws, props);

    rosterWeekChannel
      .onTimesheetChanged((timesheet) => {
        if (timesheet.updated_by === currentUserId) {
          return;
        }

        emit(
          timesheetResponse.BOX_TIMESHEET_RESPONSE_TIMESHEET_CHANGED(timesheet)
        );
      })
      .onTimesheetDeleted((payload) => {
        emit(
          timesheetResponse.BOX_TIMESHEET_RESPONSE_TIMESHEET_DELETED(payload)
        );
      });

    return () => {
      rosterWeekChannel.leave();
    };
  });
}
