import React from 'react';
import { Dictionary } from 'ts-essentials';
import { RosterEvent } from 'type';
import { useSelectorWithProps } from 'hooks';
import { getEvent } from 'state/Events';
import { CardProps, RosterEventUpdated } from './types';
import EventTypeCard from './EventTypeCard';
import ForecastTypeCard from './ForecastTypeCard';

type Props = {
  eventId: string;
  hasAddButton: boolean;
};

const typeCards: Dictionary<
  React.ComponentType<CardProps>,
  RosterEvent['type']
> = {
  event: EventTypeCard,
  forecast: ForecastTypeCard,
};

export const EventCard = React.memo(function EventCard(props: Props) {
  const event = useSelectorWithProps(
    getEvent,
    props.eventId
  ) as unknown as RosterEventUpdated;

  const TypeCard = typeCards[event.type];

  return <TypeCard event={event} hasAddButton={props.hasAddButton} />;
});
