import React from 'react';
import clsx from 'clsx';
import { Theme } from 'extended-oxygen-elements';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { Container } from '../Container';

type OwnProps = {
  children: React.ReactNode;
  hidden?: boolean;
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      transition: theme.transitions.create('opacity'),
      display: 'flex',
      alignItems: 'center',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
      },
    },
    hidden: {
      pointerEvents: 'none',
      opacity: 0,
    },
  });

type StyleProps = WithStyles<typeof styles>;

type Props = OwnProps & StyleProps;

export const NewHeader = withStyles(styles)(function NewHeader({
  children,
  classes,
  hidden = false,
}: Props) {
  return (
    <Container className={clsx([classes.root, hidden && classes.hidden])}>
      {children}
    </Container>
  );
});
