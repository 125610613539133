import { BREAK_DURATION_LIMIT_MIN } from 'lib/config';
import { settingsRosteredShiftsInvalidBreak } from 'messages';
import { Dictionary, SafeDictionary } from 'ts-essentials';
import { DefaultBreakItemCondition } from 'type';
import { DefaultBreaksFieldGroupBreak } from './types';

const validators: Dictionary<
  (breakRule: DefaultBreaksFieldGroupBreak) => string | undefined,
  DefaultBreakItemCondition
> = {
  '<=': (breakRule) => {
    if (breakRule.break_duration >= breakRule.shift_duration) {
      return settingsRosteredShiftsInvalidBreak(breakRule.shift_duration);
    }
    return;
  },
  '>': (breakRule: DefaultBreaksFieldGroupBreak) => {
    if (breakRule.break_duration >= BREAK_DURATION_LIMIT_MIN) {
      return settingsRosteredShiftsInvalidBreak(BREAK_DURATION_LIMIT_MIN);
    }

    return;
  },
};

export function defaultBreakRulesValidator(
  rules: DefaultBreaksFieldGroupBreak[]
): SafeDictionary<string> | undefined {
  const errorsById = rules.reduce((accumulator, defaultBreaksRule) => {
    const error = validators[defaultBreaksRule.condition](defaultBreaksRule);

    if (error) {
      accumulator[defaultBreaksRule.id] = error;
    }

    return accumulator;
  }, {} as SafeDictionary<string>);

  if (Object.keys(errorsById).length) {
    return errorsById;
  }
}
