import React from 'react';
import { useLayoutContext } from './layout-context';

function AppBarShape() {
  const { appBarHeight } = useLayoutContext();

  return <div style={{ minHeight: appBarHeight }} />;
}

export default AppBarShape;
