import { createReducer } from 'lib/store-utils';
import { AuthReducerState } from './types';
import { getDefaultState } from './state';
import * as actions from './actions';

export const auth = createReducer<AuthReducerState>({}, getDefaultState());

auth.on(
  actions.BOX_AUTH_GET_CURRENT_USER_SUCCESS,
  (state, currentUser): AuthReducerState => ({
    ...state,
    currentUser
  })
);

auth.on(
  actions.BOX_AUTH_UPDATE_CURRENT_USER_DATA,
  (state, data): AuthReducerState => ({
    ...state,
    currentUser: {
      ...state.currentUser,
      ...data
    }
  })
);
