import React from 'react';
import { MouseButton } from 'lib/consntants';

type IsResizing = boolean;
type StartResize = (
  event: React.SyntheticEvent<HTMLDivElement, MouseEvent>
) => void;

export function useResizableEventListeners(
  onResizeStart: () => void,
  onResize: (deltaPx: number) => void,
  onResizeEnd?: () => void,
  initialIsResizing: boolean = false
): [IsResizing, StartResize] {
  const [isResizing, setIsResizing] = React.useState(initialIsResizing);

  function mouseDownHandler(
    event: React.SyntheticEvent<HTMLDivElement, MouseEvent>
  ) {
    event.preventDefault(); // required to not triggering drag and drop while resizing
    setIsResizing(true);
    onResizeStart();
  }

  React.useEffect(
    () => {
      function mouseMoveHandler(event: MouseEvent) {
        if (isResizing) {
          onResize(event.clientX);
        }
      }

      function mouseUpHandler(event: MouseEvent) {
        if (event.button !== MouseButton.Main) {
          return;
        }

        if (isResizing) {
          setIsResizing(false);

          if (onResizeEnd) {
            onResizeEnd();
          }
        }
      }

      window.addEventListener('mouseup', mouseUpHandler);
      window.addEventListener('mousemove', mouseMoveHandler);

      return () => {
        window.removeEventListener('mouseup', mouseUpHandler);
        window.removeEventListener('mousemove', mouseMoveHandler);
      };
    },
    [isResizing, onResize, onResizeEnd]
  );

  return [isResizing, mouseDownHandler];
}
