import React, { MouseEvent } from 'react';
import { EventIcon, KeyboardArrowDownIcon } from 'element';
import { Chip, Popover } from 'oxygen-elements';
import { makeStyles, createStyles } from '@mui/styles';
import { bindPopover, bindTrigger, usePopupState } from 'hooks';
import { DescriptionList, Link } from 'elmo-elements';
import moment from 'moment';
import { ReportSchedule } from 'type';
import { useSelector } from 'react-redux';
import { userListSelector } from 'state/UsersCollection';
import { timeFormatSelector } from 'state/Account';

const useChipStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: '#fff',
      backgroundColor: theme.palette.primary.dark,
      textTransform: 'uppercase',
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      maxWidth: '123px',
      padding: '2px',
      fontSize: '12px',
      height: '24px',
      cursor: 'pointer',
      '& svg.MuiChip-icon': {
        color: '#fff',
      },
      '& svg.MuiChip-deleteIcon': {
        color: '#fff',
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    content: {
      maxWidth: '240px',
      width: '100%',
      padding: '24px 24px 0',
    },
    link: {
      display: 'inline-block',
      marginBottom: '24px',
      marginLeft: '24px',
    },
  })
);

type Props = {
  renderStatusFrequency: React.ReactNode;
  schedule: ReportSchedule;
  renderStatusDuration: React.ReactNode;
  renderFormat: React.ReactNode;
  onClickEdit: (event: MouseEvent<HTMLAnchorElement>) => void;
};

export const ScheduleBadge = ({
  renderStatusFrequency,
  schedule,
  renderStatusDuration,
  renderFormat,
  onClickEdit,
}: Props) => {
  const styles = useChipStyles();
  const popupState = usePopupState();
  const userList = useSelector(userListSelector);
  const timeFormat = useSelector(timeFormatSelector);

  return (
    <>
      <Chip
        data-testid="schedule-badge"
        label="Scheduled"
        icon={<EventIcon />}
        deleteIcon={<KeyboardArrowDownIcon />}
        onDelete={popupState.open}
        className={styles.root}
        {...bindTrigger(popupState)}
      />
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        data-testid="schedule-popover"
      >
        <DescriptionList style="align-vertical" className={styles.content}>
          <DescriptionList.Item label="Frequency">
            {renderStatusFrequency}
          </DescriptionList.Item>

          <DescriptionList.Item label="Report time">
            {moment(schedule.time).format(timeFormat)}
          </DescriptionList.Item>

          <DescriptionList.Item label="Duration">
            {renderStatusDuration}
          </DescriptionList.Item>

          <DescriptionList.Item label="Format">
            {renderFormat}
          </DescriptionList.Item>

          <DescriptionList.Item label="Recipients">
            {schedule.recipients.map((recipientId) => {
              return (
                userList[recipientId] && (
                  <div key={recipientId} style={{ wordBreak: 'break-all' }}>
                    {userList[recipientId].email}
                  </div>
                )
              );
            })}
          </DescriptionList.Item>
        </DescriptionList>
        <Link
          url=""
          onClick={(e) => {
            popupState.close();
            onClickEdit(e);
          }}
          className={styles.link}
        >
          Edit
        </Link>
      </Popover>
    </>
  );
};
