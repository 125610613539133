import { makeDefaultBreakRuleFields } from 'page/Settings/components';
import { RosteredShiftSettings } from 'type';

export default function makeFormFields(settings: RosteredShiftSettings) {
  return {
    ...settings,
    default_breaks_rules: makeDefaultBreakRuleFields(
      settings.default_breaks_rules
    ),
  };
}

export type FormFields = ReturnType<typeof makeFormFields>;
