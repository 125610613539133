import { formatDuration, FormatDurationOptions } from 'lib/helpers';
import React from 'react';

type FormattedDurationProps = FormatDurationOptions & {
  children: number;
};

export default FormattedDuration;

export function FormattedDuration({
  children,
  ...options
}: FormattedDurationProps) {
  return <>{formatDuration(children, options)}</>;
}
