import { createReducer } from 'lib/store-utils';
import { EventsReducerState } from './types';
import { defaultState } from './state';
import * as actions from './actions/actions';
import _ from 'lodash';

export const events = createReducer<EventsReducerState>({}, defaultState);

events.on(actions.BOX_EVENTS_GET_SUCCESS, (state, newState): EventsReducerState => (newState));
events.on(actions.BOX_EVENTS_UPDATE_ADD_ONE, (state, event): EventsReducerState => ({
  ...state,
  events: {
    ...state.events,
    [event.id]: event
  }
}));
events.on(actions.BOX_EVENTS_DELETE_ONE, (state, id): EventsReducerState => ({
  ...state,
  events: _.omit(state.events, id)
}));
