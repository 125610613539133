import React, { useState } from 'react';
import { SettingsOutlinedIcon } from '../../../element';
import {
  Button,
  Checkbox,
  FormControlLabel,
} from '../../../extended-oxygen-elements';
import { DiscardBreaksCustomisationDialog } from './DiscardBreaksCustomisationDialog';
import { HelperTextWrapper } from 'page/Settings/components';
import { FormHelperText } from 'extended-oxygen-elements';

type Props = {
  handleCustomiseBreaks: () => void;
  isEnabled: boolean;
  modeEnabled: boolean;
};

export const EnableBreaksCustomisationCheckbox = ({
  handleCustomiseBreaks,
  isEnabled,
  modeEnabled,
}: Props) => {
  const [isOpened, setIsOpened] = useState(false);

  const onClose = () => {
    setIsOpened(false);
  };

  const handleDiscardChanges = () => {
    if (modeEnabled) {
      setIsOpened(true);
    } else {
      handleCustomiseBreaks();
    }
  };

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={modeEnabled}
            onChange={handleDiscardChanges}
            disabled={!isEnabled}
          />
        }
        label="Enable break customisation"
      />
      <HelperTextWrapper>
        <FormHelperText fontSize="medium" disabled={!isEnabled}>
          Use your own break durations based on shift duration.
        </FormHelperText>
      </HelperTextWrapper>
      <DiscardBreaksCustomisationDialog
        isOpened={isOpened}
        handleSubmit={() => {
          handleCustomiseBreaks();
          onClose();
        }}
        onClose={onClose}
      />
    </>
  );
};
