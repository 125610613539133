import { useSelector } from 'react-redux';
import { getLinkedRoleLabel } from 'lib/helpers';
import { unlinkedRoleLabelSelector } from 'state/Account';
import { getAreas, getRoles } from 'state/AccountTree';

type Props = {
  area_id: string;
  role_id: string;
};
export const useLinkedRole = ({ area_id, role_id }: Props) => {
  const unlinkedRoleLabel = useSelector(unlinkedRoleLabelSelector);
  const areas = useSelector(getAreas);
  const roles = useSelector(getRoles);

  return getLinkedRoleLabel({
    roles,
    areas,
    role_id,
    area_id,
    fallBack: unlinkedRoleLabel,
  });
};
