import React from 'react';
import { useDispatch } from 'react-redux';
import { BOX_ACCOUNT_PAY_CONDITIONS_RULE_CREATE } from 'state/Account';
import { usePayConditionsListContext } from '../pay-conditions-list-context';
import WeekendLoadingForm, {
  WeekendLoadingFormProps,
} from './components/WeekendLoadingForm';

export default function CreateWeekendLoading() {
  const dispatch = useDispatch();
  const { isCreateOpen, close } = usePayConditionsListContext();

  React.useEffect(() => {
    close();
  }, [close]);

  const handleSubmit: WeekendLoadingFormProps['onSubmit'] = (payload) => {
    dispatch(BOX_ACCOUNT_PAY_CONDITIONS_RULE_CREATE.request(payload));
  };

  return <>{isCreateOpen && <WeekendLoadingForm onSubmit={handleSubmit} />}</>;
}
