import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FetchWrapper, NestedRoutes } from 'element';
import { ROSTER_EDIT_TEMPLATE } from 'state/FetchPageData/constants';
import { RouteComponentProps, withRouter } from 'react-router';
import { BOX_ROSTER_BULK_DELETION_MODE_DEACTIVATE } from 'state/Roster/BulkActions/actions';
import {
  BOX_EDIT_TEMPLATE_GET_DATA, BOX_EDIT_TEMPLATE_SET_PREVIOUS_PAGE,
  BOX_EDIT_TEMPLATE_SET_TEMPLATE_ID
} from '../../../../state/Roster/EditTemplate/actions';
import { getTemplateId } from '../../../../state/Roster/EditTemplate/selectors';
import { StoreState } from 'state/types';
import { EditTemplateViewSwitcher } from './components/EditTemplateViewSwitcher';
import EditShiftTemplateModal from './components/EditShiftTemplateModal';
import { StringMap, Route } from '../../../../type';
import DeleteModal from './components/DeleteModal';
import DeleteTemplateModal from './components/DeleteTemplateModal';
import { FullScreenLoadingOverlay } from '../../../../element/FullScreenLoadingOverlay';
import UpdateTemplateModal from './components/UpdateTemplateModal';
import ErrorModal from '../../components/ErrorModal';
import SaveAsTemplateModal from './components/SaveAsTemplateModal';
import { contextInProgress } from '../../../../state/Roster/ContextualMenu/selectors';
import './styles.scss';

type TParam = {
  templateId: string;
};

type OwnProps = {
  routes: StringMap<Route>;
};

type StateProps = {
  templateId: string | null;
  dropInProgress: boolean;
  contextMenuPasteInProgress: boolean;
};

type DispatchProps = {
  setEditTemplate: (id: string) => void;
  getEditTemplatesData: (id: string) => void;
  deactivateBulkDeleteMode: () => void;
  setPrevPage: (params: null | string) => void;
};

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps<TParam>;

export class EditTemplate extends Component<Props> {

  componentDidMount() {
    const { match: { params } } = this.props;
    this.props.setEditTemplate(params.templateId);
  }

  componentDidUpdate() {
    const { match: { params } } = this.props;
    this.props.setEditTemplate(params.templateId);
  }

  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<{}>, nextContext: any): boolean {
    const { dropInProgress, contextMenuPasteInProgress, match } = this.props;
    return nextProps.match.params.templateId !== match.params.templateId
      || dropInProgress !== nextProps.dropInProgress
      || contextMenuPasteInProgress !== nextProps.contextMenuPasteInProgress;
  }

  render() {
    const { match: { params }, dropInProgress, contextMenuPasteInProgress } = this.props;
    return (
      <FetchWrapper
        fetchData={() => this.props.getEditTemplatesData(params.templateId)}
        pageId={ROSTER_EDIT_TEMPLATE}
      >
        <div className="edit-template-roster-view-controls">
          <EditTemplateViewSwitcher templateId={params.templateId} />
        </div>

        <EditShiftTemplateModal />
        <DeleteModal />

        <NestedRoutes routes={this.props.routes} />
        <DeleteTemplateModal />
        <UpdateTemplateModal />
        <SaveAsTemplateModal />
        <ErrorModal />
        {(dropInProgress || contextMenuPasteInProgress) && <FullScreenLoadingOverlay />}
      </FetchWrapper>
    );
  }

  componentWillUnmount(): void {
    this.props.setEditTemplate('');
    this.props.deactivateBulkDeleteMode();
    this.props.setPrevPage(null);
  }

}

const mapDispatchToProps: DispatchProps = {
  getEditTemplatesData: BOX_EDIT_TEMPLATE_GET_DATA,
  deactivateBulkDeleteMode: BOX_ROSTER_BULK_DELETION_MODE_DEACTIVATE,
  setEditTemplate: BOX_EDIT_TEMPLATE_SET_TEMPLATE_ID,
  setPrevPage: BOX_EDIT_TEMPLATE_SET_PREVIOUS_PAGE
};

const mapToStateProps = (state: StoreState): StateProps => ({
  templateId: getTemplateId(state),
  dropInProgress: state.editTemplate.dropInProgress,
  contextMenuPasteInProgress: contextInProgress(state)
});

export default withRouter(
  connect(
    mapToStateProps,
    mapDispatchToProps
  )(EditTemplate)
);
