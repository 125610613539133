import { createSelector } from 'reselect';
import { orderBy } from 'natural-orderby';
import {
  AccountTreeArea,
  AccountTreeRole,
  AccountTreeSite,
  SortUser,
  UserFields,
  UserGroup,
  StringMap
} from '../../../../../type';
import { StoreState } from '../../../../types';
import { sortUserByRoleCombiner } from '../../../../combiners';
import { getUserGroups } from '../../../../UserGroups/selectors';
import { getAreas, getRoles, getSites } from '../../../../AccountTree/selectors';
import { getSort } from '../index';
import { filteredUsersSelector } from './internal';

export const sortedUsersSelector = createSelector<
  StoreState,
  UserFields[],
  SortUser,
  StringMap<UserGroup>,
  StringMap<AccountTreeArea>,
  StringMap<AccountTreeRole>,
  StringMap<AccountTreeSite>,
  UserFields[]
  >(
  filteredUsersSelector,
  getSort,
  getUserGroups,
  getAreas,
  getRoles,
  getSites,
  (users, { column, direction }, userGroups, ...accountTree) => {
    if (column === 'full_name') {
      return orderBy(users, [column], [direction]);
    }
    if (column === 'user_group_id') {
      return orderBy(
        users,
        [(user: UserFields) => userGroups[user.user_group_id].title],
        [direction]
      );
    } else {
      return sortUserByRoleCombiner(users, column, direction, ...accountTree);
    }
  }
);
